export const TOGGLE_DRAWER = '@webapp/signature/TOGGLE_DRAWER';
export const RECORDS_FINISHED = '@webapp/signature/RECORDS_FINISHED';
export const READ_SIGNATURE = '@webapp/signature/READ_SIGNATURE';
export const RECORDS_FINISHED_FAILURE =
  '@webapp/signature/RECORDS_FINISHED_FAILURE';
export const RECORDS_FINISHED_SUCCESS =
  '@webapp/signature/RECORDS_FINISHED_SUCCESS';
export const FETCH_SIGNATURE_SUCCESS =
  '@webapp/signature/FETCH_SIGNATURE_SUCCESS';
export const FETCH_SIGNATURE_FAILURE =
  '@webapp/signature/FETCH_SIGNATURE_FAILURE';
export const GET_SIGNATURE_THUMBPRINT =
  '@webapp/signature/GET_SIGNATURE_THUMBPRINT';
export const CLEAR_SIGNED_PDF = '@webapp/signature/CLEAR_SIGNED_PDF';
export const CLEAR_SIGNED_PDF_ERRORS =
  '@webapp/signature/CLEAR_SIGNED_PDF_ERRORS';
export const CHANGE_SIGNATURE_STEPPER =
  '@webapp/signature/CHANGE_SIGNATURE_STEPPER';
export const GET_LIST_PSC = '@webapp/signature/GET_LIST_PSC';
export const GET_LIST_PSC_SUCCESS = '@webapp/signature/GET_LIST_PSC_SUCCESS';
export const GET_LIST_PSC_FAILURE = '@webapp/signature/GET_LIST_PSC_FAILURE';
export const GET_ACCESS_TOKEN = '@webapp/signature/GET_ACCESS_TOKEN';
export const GET_ACCESS_TOKEN_SUCCESS =
  '@webapp/signature/GET_ACCESS_TOKEN_SUCCESS';
export const GET_ACCESS_TOKEN_FAILURE =
  '@webapp/signature/GET_ACCESS_TOKEN_FAILURE';
export const SET_PROVIDER = '@webapp/signature/SET_PROVIDER';
export const SET_LIFETIME = '@webapp/signature/SET_LIFETIME';
export const FETCH_SIGNATURE_CLOUD = '@webapp/signature/FETCH_SIGNATURE_CLOUD';

export const SIGNED_REDIRECT_USER_INFOS = 'signedRedirectUserInfos';
