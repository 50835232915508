import { withFormik, FormikErrors } from 'formik';

import { AddedFlow, AvailableFlow, ReturnFlow } from 'state/marketing/emailFlow/patient/types';
import { Professional } from 'state/marketing/emailFlow/professional/types';
import validationSchema from './validationSchema';

export type FormValues = {
  addedFlows: AddedFlow[];
  availableFlows: AvailableFlow[];
  selectedReturnFlow: ReturnFlow;
};

type FormProps = {
  selectedReturnFlow: ReturnFlow;
  selectedProfessional: Professional;
  errors?: FormikErrors<FormValues>;
  addedFlows: AddedFlow[];
  availableFlows: AvailableFlow[];
  values: FormValues;
};

export default (Component: JSX.Element) => withFormik<FormProps, FormValues>({
  enableReinitialize: true,
  validationSchema,
  mapPropsToValues: ({
    selectedReturnFlow,
    availableFlows,
    addedFlows,
    selectedProfessional,
  }) => ({
    selectedReturnFlow,
    availableFlows,
    addedFlows,
    selectedProfessional,
  }),
  handleSubmit: (_, { setStatus }) => setStatus('submit'),
})(Component);
