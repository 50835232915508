import React from 'react';

import { useConference } from 'features/video-conference/state/conferenceContext';
import Participant from '../Participant';
import useParticipants from 'features/video-conference/hooks/useParticipants';
import useMainParticipant from 'features/video-conference/hooks/useMainParticipant';
import useScreenShareParticipant from 'features/video-conference/hooks/useScreenShareParticipant';
import { isMobile } from 'features/video-conference/utils';

const ParticipantVideo = () => {
  const { room } = useConference();
  const participants = useParticipants();
  const mainParticipant = useMainParticipant();
  const screenShareParticipant = useScreenShareParticipant();

  if (!room) return null;

  const { localParticipant } = room;

  return (
    <>
      <Participant participant={localParticipant} isLocalParticipant />
      {participants.map((participant) => {
        const hideParticipant =
          (participant === mainParticipant &&
            participant !== screenShareParticipant) ||
          isMobile;

        return (
          <Participant
            key={participant.sid}
            participant={participant}
            hideParticipant={hideParticipant}
          />
        );
      })}
    </>
  );
};

export default ParticipantVideo;
