import React from 'react';

import { Chip } from './styles';

interface Props {
  icon: React.ReactElement;
  content: string;
  customClassName?: string;
  dataTestId?: string;
}

export default function ChipDark({
  icon,
  content,
  customClassName,
  dataTestId,
}: Props): JSX.Element {
  return (
    <Chip
      size="small"
      icon={icon}
      label={content}
      className={customClassName}
      data-testid={dataTestId}
    />
  );
}
