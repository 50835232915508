// Internal
import { EditorState } from 'draft-js';

import { reducerMerge } from 'shared/utils/reducer';
import { editorOptionsText } from 'ui/new/editor/constants';
import * as types from './types';
import { cardsName } from './constants';

export const initialState = {
  isFetching: false,
  campaigns: [],
  errors: [],
  procedures: {
    isFetching: false,
    procedures: [],
    errors: [],
  },
  campaignDetails: {
    isFetching: false,
    campaign: undefined,
    errors: [],
  },
  campaignCreate: {
    isFetching: false,
    campaign: undefined,
    errors: [],
  },
  campaignUpdate: {
    isFetching: false,
    campaign: undefined,
    errors: [],
    notifications: [],
  },
  campaignDelete: {
    isFetching: false,
    campaign: undefined,
    errors: [],
    notifications: [],
  },
  userProfile: {
    isFetching: false,
    userProfiles: [],
    errors: [],
  },
  patientCount: {
    isFetching: false,
    count: 0,
    errors: [],
  },
  patientTags: {
    isFetching: false,
    patientTags: [],
    errors: [],
  },
  selected: new Set(),
  activeCard: cardsName.audience,
  editor: {
    editorState: EditorState.createEmpty(),
    optionsFormatText: editorOptionsText,
  },
  sendTestEmail: {
    isFetching: false,
    campaign: undefined,
    errors: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_DETAILS_CLEAR.ACTION:
      return {
        ...state,
        campaignDetails: {
          ...action.payload.campaignDetails,
        },
      };
    case types.FETCH_LIST.ACTION:
    case types.FETCH_LIST.SUCCESS:
    case types.FETCH_LIST.FAILURE:
    case types.FETCH_DETAILS.ACTION:
    case types.FETCH_DETAILS.SUCCESS:
    case types.FETCH_DETAILS.FAILURE:
    case types.FETCH_CREATE.ACTION:
    case types.FETCH_CREATE.SUCCESS:
    case types.FETCH_CREATE.FAILURE:
    case types.FETCH_UPDATE.ACTION:
    case types.FETCH_UPDATE.SUCCESS:
    case types.FETCH_UPDATE.FAILURE:
    case types.CLEAR_ERRORS_UPDATE_CAMPAIGN.ACTION:
    case types.FETCH_DELETE.ACTION:
    case types.FETCH_DELETE.SUCCESS:
    case types.FETCH_DELETE.FAILURE:
    case types.CLEAR_ERRORS_CAMPAIGN.ACTION:
    case types.SET_SELECTED.ACTION:
    case types.DUPLICATE_CAMPAIGN.ACTION:
    case types.EDIT_CAMPAIGN.SET_ACTIVE_CARD:
    case types.EDITOR_TEXT.UPDATE_EDITOR_STATE:
    case types.EDITOR_TEXT.FORMAT_TEXT:
    case types.FETCH_USER_PROFILES.ACTION:
    case types.FETCH_USER_PROFILES.SUCCESS:
    case types.FETCH_USER_PROFILES.FAILURE:
    case types.FETCH_PATIENT_COUNT.ACTION:
    case types.FETCH_PATIENT_COUNT.SUCCESS:
    case types.FETCH_PATIENT_COUNT.FAILURE:
    case types.FETCH_PROCEDURES.ACTION:
    case types.FETCH_PROCEDURES.SUCCESS:
    case types.FETCH_PROCEDURES.FAILURE:
    case types.FETCH_PATIENT_TAGS.ACTION:
    case types.FETCH_PATIENT_TAGS.SUCCESS:
    case types.FETCH_PATIENT_TAGS.FAILURE:
    case types.FETCH_SEND_TEST_EMAIL.SUCCESS:
    case types.FETCH_SEND_TEST_EMAIL.FAILURE:
      return reducerMerge({}, state, action.payload);
    default:
      return state;
  }
};
