/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from 'react';
import { useFormikContext } from 'formik';

import { Select } from '@iclinic/design-system';
import { SelectProps } from '@iclinic/design-system/dist/components/Select';
import { GuideSADTForm, Procedure } from 'features/tissInvoicing/types';
import getErrorText from './getErrorText';
import * as Styles from './styles';

type Identifiers = keyof Procedure;

interface SelectFieldProcedureSADTProps extends SelectProps {
  index: number;
  identifier: Identifiers;
  onChangeHandler?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}

function SelectFieldProcedureSADT({
  index,
  identifier,
  onChangeHandler,
  children,
  ...restOfProps
}: PropsWithChildren<SelectFieldProcedureSADTProps>) {
  const classes = Styles.useStyles();

  const { handleChange, errors, touched, handleBlur } = useFormikContext<
    GuideSADTForm
  >();

  const onChangeValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    handleChange(e);
    if (onChangeHandler) onChangeHandler(e);
  };

  const validateField = () =>
    getErrorText(`procedures[${index}].${identifier}`, touched, errors);

  return (
    <Select
      helperText={validateField()}
      error={!!validateField()}
      id={`procedures.${index}.${identifier}`}
      name={`procedures.${index}.${identifier}`}
      onChange={onChangeValue}
      onBlur={handleBlur}
      classes={{ root: classes.select }}
      {...restOfProps}
    >
      {children}
    </Select>
  );
}

export default SelectFieldProcedureSADT;
