import { IStoreState } from 'state/rootReducer';

export const getSelectedScreenState = (state: IStoreState) =>
  state.records.summaryIA.selectedScreen;

export const getSidebarIsopenState = (state: IStoreState) =>
  state.records.summaryIA.sidebarIsOpen;

export const getSummaryDataState = (state: IStoreState) =>
  state.records.summaryIA.summary;

export const getIsWaiting = (state: IStoreState) =>
  state.records.summaryIA.isWaiting;
