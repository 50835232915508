import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { BillingInfo, EditPaymentState, EditPaymentStep } from '../types';

export const initialState: EditPaymentState = {
  canEdit: false,
  hasDebit: false,
  notMonthly: false,
  dialogOpened: false,
  alreadyChanged: false,
  status: RequestStatus.Ready,
  step: EditPaymentStep.INITIAL,
  billingInfo: {
    paymentDay: 2,
    lastChangeDay: '',
    daysUntilNextChange: '',
  },
};

const nameStore = '@webapp/subscription/editPaymentDate';

export const verifyPaymentDateEdit = createAction<void>(
  `${nameStore}/verifyPaymentDateEdit`,
);

export const changePaymentDate = createAction<number>(
  `${nameStore}/changePaymentDate`,
);

const editPaymentDateSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    isFetching: (state) => ({
      ...state,
      status: RequestStatus.Pending,
    }),
    setBillingInfo: (state, action: PayloadAction<BillingInfo>) => ({
      ...state,
      billingInfo: action.payload,
    }),
    toggleDialog: (state) => ({
      ...state,
      dialogOpened: !state.dialogOpened,
    }),
    verifyEditPaymentSuccess: (state) => ({
      ...state,
      canEdit: true,
    }),
    verifyEditPaymentWithUnpaidMonth: (state) => ({
      ...state,
      canEdit: true,
      hasDebit: true,
    }),
    verifyEditPaymentWithNotMonthly: (state) => ({
      ...state,
      canEdit: true,
      notMonthly: true,
    }),
    verifyEditPaymentAlreadyChanged: (state) => ({
      ...state,
      canEdit: true,
      alreadyChanged: true,
    }),
    verifyEditPaymentFail: (state) => ({
      ...state,
      canEdit: false,
    }),
    changePaymentDateSuccess: (state, action: PayloadAction<number>) => ({
      ...state,
      status: RequestStatus.Success,
      step: EditPaymentStep.SUCCEDED,
      billingInfo: {
        ...state.billingInfo,
        paymentDay: action.payload,
      },
    }),
    changePaymentDateFail: (state) => ({
      ...state,
      status: RequestStatus.Error,
    }),
    refreshStatus: (state) => ({
      ...state,
      status: RequestStatus.Ready,
    }),
  },
});

export const {
  isFetching,
  setBillingInfo,
  toggleDialog,
  verifyEditPaymentSuccess,
  verifyEditPaymentWithUnpaidMonth,
  verifyEditPaymentWithNotMonthly,
  verifyEditPaymentAlreadyChanged,
  verifyEditPaymentFail,
  changePaymentDateSuccess,
  changePaymentDateFail,
  refreshStatus,
} = editPaymentDateSlice.actions;

const { reducer } = editPaymentDateSlice;

export default reducer;
