import moment from 'moment';
import jwtDecode from 'jwt-decode';

import { captureException } from 'shared/utils/handlerErrors';
import { capitalizeFirstLetter } from 'shared/utils/strings';
import { JWTBody, DecodedToken } from './types';

export const composePhysicianName = (
  physicianName: string,
  title: string | null,
) => {
  if (!title) return physicianName;
  return `${title} ${physicianName}`;
};

export const getFirstPhoneNumber = (
  phoneNumbers: string[] | null,
): string | null => {
  if (!phoneNumbers) return '';

  return phoneNumbers.length > 0 ? phoneNumbers[0] : null;
};

export const formatAddress = (
  street: string | null,
  streetNumber: string | null,
  complement: string | null,
) => {
  if (!street) return '';
  if (!streetNumber && complement) return `${street} - ${complement}`;
  if (streetNumber && !complement) return `${street}, ${streetNumber}`;
  if (!streetNumber && !complement) return `${street}`;

  return `${street}, ${streetNumber} - ${complement}`;
};

export const formatEventDay = (date: string): string => {
  if (!date) return '';

  moment.locale('pt-br');
  const momentDate = moment(date);

  const weekDay = capitalizeFirstLetter(
    momentDate.format('dddd').split('-').shift(),
  );
  const dayNumber = momentDate.format('D');
  const month = capitalizeFirstLetter(momentDate.format('MMMM'));

  return `${weekDay}, ${dayNumber} de ${month}`;
};

export const formatEventTime = (time: string): string => {
  if (!time) return '';

  moment.locale('pt-br');
  return moment(time, 'HH:mm:ss').format('HH:mm');
};

export const decodeCheckoutToken = (token: string): DecodedToken | null => {
  try {
    const body: JWTBody = jwtDecode(token);

    return {
      billId: body.bill_id,
      eventId: body.event_id,
      physicianId: body.physician_id,
    };
  } catch (err) {
    captureException(err);
    return null;
  }
};

export const getCheckoutTokenUrl = (): string | null => {
  const { searchParams } = new URL(window.location.href);
  return searchParams.get('token');
};

export const pixTimer = (expirationDate: string) => {
  const countDownDate = new Date(expirationDate.replace(' ', 'T')).getTime();

  const now = new Date().getTime();
  const distance = countDownDate - now;

  const minutesCount = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const secondsCount = Math.floor((distance % (1000 * 60)) / 1000);
  const minutes = minutesCount < 10 ? `0${minutesCount}` : minutesCount;
  const seconds = secondsCount < 10 ? `0${secondsCount}` : secondsCount;

  const expiredTime = minutesCount === 0 && secondsCount === 0;

  if (expiredTime) {
    return 'Gerar novo QRCode';
  }

  return `${minutes} : ${seconds}`;
};
