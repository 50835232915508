import uuid from 'uuid';
import moment from 'moment';

import {
  PhysicianPicture,
  PhysicianPatch,
  SellerRequestBody,
  FetchSellerRawData,
} from 'features/onlinePayment/services/config/types';
import { splitBase64 } from 'shared/utils/file';
import { SellerForm, SellerProfiles } from '../types';
import { onlyNumbers } from 'shared/utils/formatters';
import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';
import { CBO_MEDICO_CLINICO } from '../constants';
import { CBO } from 'features/tuss/cbo/types';

export function normalizePicture(fileBase64: string): PhysicianPicture {
  const [contentType, base64] = splitBase64(fileBase64);
  const extension = contentType.split('/')[1];

  return {
    'content-type': contentType,
    name: `${uuid.v4()}.${extension}`,
    file: base64,
  };
}

export function normalizeCBO(cbo?: number | CBO, cboList?: CBO[]) {
  if (typeof cbo === 'number') {
    return cboList?.find((item) => item.cbo === cbo) || CBO_MEDICO_CLINICO;
  }
  return cbo || CBO_MEDICO_CLINICO;
}

export function normalizePhysician(
  seller: SellerForm,
  cboList?: CBO[],
): PhysicianPatch {
  let picture;

  if (seller.picture) {
    picture = normalizePicture(seller.picture);
  }

  return {
    cpf: seller.cpf,
    office_phone: seller.phone,
    picture,
    cbo: normalizeCBO(seller.cbo, cboList).cbo,
    council_name: seller.councilName,
    council_number: seller.councilNumber,
    council_region: seller.councilRegion,
  };
}

export function normalizeSeller(
  seller: SellerForm,
  cboList?: CBO[],
): SellerRequestBody {
  const {
    type,
    cpf,
    birthdate,
    name,
    email,
    phone,
    physicianId,
    zipCode,
    street,
    number,
    neighborhood,
    city,
    state,
    complement,
    businessName,
    businessDescription,
    businessOpeningDate,
    businessCnpj,
    cbo,
    councilName,
    councilNumber,
    councilRegion,
  } = seller;

  let businessFields;
  if (type === SellerType.PJ) {
    businessFields = {
      business_name: businessName,
      business_description: businessDescription,
      business_opening_date: moment(businessOpeningDate).format('YYYY-MM-DD'),
      business_cnpj: businessCnpj,
    };
  }

  return {
    name,
    cpf,
    birthdate: moment(birthdate).format('YYYY-MM-DD'),
    type,
    physician_id: physicianId,
    email,
    phone,
    cbo_term: normalizeCBO(cbo, cboList).term.trim(),
    council_name: councilName,
    council_number: councilNumber,
    council_region: councilRegion,
    address: {
      zip_code: onlyNumbers(zipCode),
      street,
      number,
      neighborhood,
      city,
      state,
      complement: complement.trim() || undefined,
    },
    ...businessFields,
  };
}

export const normalizeFetchSellersByPhysicianId = (
  data: FetchSellerRawData,
): SellerProfiles | null => {
  if (!data.results?.length) return null;
  const profiles: SellerProfiles = {};

  data.results.forEach((seller) => {
    if (seller.type === SellerType.PF) {
      profiles.individual = seller;
    } else {
      profiles.business = seller;
    }
  });
  return profiles;
};
