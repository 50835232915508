import React, { ReactNode, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialIcons, Box } from '@iclinic/design-system';

import * as S from './ShareUrl.styles';
import { getShortUrls } from 'features/referrals/state/selectors';
import { saveInterestIndication, webShareData } from 'features/referrals/state';
import { socialMediaMessage } from 'features/referrals/constants/urls';

const { Check, ExitToApp } = MaterialIcons;

type CopyWrapperProps = {
  children: ReactNode;
  isShareable: boolean;
  copyUrl: string;
  onCopy: () => void;
};

const CopyWrapper = ({
  children,
  isShareable,
  copyUrl,
  onCopy,
}: CopyWrapperProps) =>
  isShareable ? (
    <Box>{children}</Box>
  ) : (
    <CopyToClipboard text={copyUrl} onCopy={onCopy}>
      {children}
    </CopyToClipboard>
  );

type ShareUrlProps = {
  isShareable: boolean;
  dataGa?: string;
};

const ShareUrl = ({ isShareable, dataGa }: ShareUrlProps) => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const shortUrls = useSelector(getShortUrls);
  const timeToCopyAlertExpire = 3000;

  const copyUrl = shortUrls?.copy || '';
  const desktopButtonText = copied ? 'Convite copiado' : 'Copiar convite';

  const buttonText = isShareable ? 'Compartilhar convite' : desktopButtonText;
  const dataTracking = isShareable ? 'webshare' : 'copyandpaste';

  useEffect(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, timeToCopyAlertExpire);

    return () => clearTimeout(timer);
  }, [copied]);

  const handleCopyClick = () => {
    if (isShareable) {
      const text = `${socialMediaMessage}${shortUrls?.webshare || ''}`;

      dispatch(webShareData({ text }));
      return;
    }
    dispatch(saveInterestIndication({ channel: 'copy_link' }));
  };

  return (
    <S.ShareLinkWrapper>
      <CopyWrapper
        copyUrl={copyUrl}
        onCopy={() => setCopied(true)}
        isShareable={isShareable}
      >
        <S.InputButton
          type="button"
          data-sp={`referrals_${dataTracking}_input_click`}
          data-ga={dataGa || `referrals_${dataTracking}_input_click`}
        >
          <S.TextField
            id="copy-link"
            name="copy-link"
            fullWidth
            value={copyUrl}
            hiddenLabel
            InputProps={{
              readOnly: true,
            }}
            onClick={handleCopyClick}
          />
        </S.InputButton>
      </CopyWrapper>

      <CopyWrapper
        isShareable={isShareable}
        copyUrl={copyUrl}
        onCopy={() => setCopied(true)}
      >
        <S.CopyButton
          copied={copied}
          fullWidth
          size="large"
          startIcon={isShareable && <ExitToApp />}
          endIcon={!isShareable && copied && <Check />}
          onClick={handleCopyClick}
          data-ga={dataGa || `referrals_${dataTracking}_click`}
          data-sp={`referrals_${dataTracking}_click`}
        >
          {buttonText}
        </S.CopyButton>
      </CopyWrapper>
    </S.ShareLinkWrapper>
  );
};

export default ShareUrl;
