// External
import React from 'react';
import { Field } from 'redux-form';
import { EditorState } from 'draft-js';

// Internal
import { requiredEmpty } from 'shared/utils/formValidation';
import { STATUS } from 'ui/new/actionable-card/constants';
import ActionableCard from 'ui/new/actionable-card';
import {
  EmailFlowComponent,
  EditorOptionsText,
  EditorTextEditor,
  CurrentInfoEditor,
} from 'state/marketing/emailFlow/emailFlowManagement/emailFlowTypes';
import {
  emailFlowOffset,
} from 'state/marketing/emailFlow/emailFlowManagement/constants';
import { Input } from 'ui/new/form';
import Button from 'ui/new/button';
import { BUTTON } from 'ui/new/button/constants';
import { FlowType, ScheduleOffsetUnit } from 'features/marketing/constants';
import TextEditor from './mail/editor/TextEditor';

import style from './EditEmailFlowComponents.scss';


type EditEmailFlowComponentsProps = {
  component: EmailFlowComponent;
  titleCard: string;
  activeCard: string;
  setActiveCard: (activeComponentsCard: string) => void;
  setComponent: (component: EmailFlowComponent) => void;
  flowType: FlowType;
  updateEditor: (editorState: EditorState) => void;
  openModalEditorLink: () => void,
  openModalEditorButton: () => void,
  openModalEditorImage: () => void,
  onAutomatedTagChosen: (automatedTag: string) => void,
  editorOptionsText: EditorOptionsText[];
  editor: EditorTextEditor;
  currentInfo: CurrentInfoEditor;
  deleteComponent: (componentId: string, physicianId: number, emailFlowId: string) => void,
  physicianId: number,
  emailFlowId: string,
  emailFlowName: string,
  saveComponent: (physicianId: number) => void,
  saveEditStateComponent: () => void,
  isValidCard: STATUS,
  openModalSendEmailTest: (physicianId: number) => void,
};

const EditEmailFlowComponents = ({
  flowType,
  component,
  titleCard,
  activeCard,
  setActiveCard,
  setComponent,
  updateEditor,
  openModalEditorLink,
  openModalEditorButton,
  openModalEditorImage,
  onAutomatedTagChosen,
  editorOptionsText,
  editor,
  currentInfo,
  deleteComponent,
  physicianId,
  emailFlowId,
  saveComponent,
  saveEditStateComponent,
  isValidCard,
  openModalSendEmailTest,
}: EditEmailFlowComponentsProps): JSX.Element => {
  const collapsed = React.useMemo(() => activeCard !== component.id,
    [activeCard, component.id]);

  const onHeaderClick = React.useCallback(() => {
    setActiveCard(collapsed ? component.id : '');
    if (collapsed) {
      setComponent(component);
    } else {
      saveEditStateComponent();
    }
  }, [collapsed, setActiveCard, setComponent, component, saveEditStateComponent]);

  return (
    <div className={style.sender}>
      <ActionableCard
        title={titleCard}
        collapsed={collapsed}
        onHeaderClick={onHeaderClick}
        status={isValidCard}
      >
        <ActionableCard.Body classes={style}>
          <div className={style.container}>
            <div className={style['row-fields']}>
              <div className={style.container__basisFlexPeriod}>
                <span className={style.titleField}>Enviar este e-mail</span>
                <div className={style['date-fields-container']}>
                  <Field
                    type="text"
                    name="currentComponent.offset.value"
                    label="value"
                    component={Input}
                    mask="9999"
                    validate={[requiredEmpty]}
                  />
                  <Field component="select" name="currentComponent.offset.unit" type="text" className={style.periodUnit}>
                    <option value={ScheduleOffsetUnit.day}>Dias</option>
                    <option value={ScheduleOffsetUnit.month}>Meses</option>
                  </Field>
                </div>
                <span className={style.titleOffsetUnit}>{emailFlowOffset[flowType]}</span>
              </div>
            </div>
            <div className={style['row-subject']}>
              <div className={style.container__basisAll}>
                <p className={style.titleField}>Assunto do e-mail</p>
                <Field
                  type="text"
                  name="currentComponent.subject"
                  label="value"
                  component={Input}
                  validate={[requiredEmpty]}
                />
              </div>
            </div>
            <div className={style['row-mobile-block']}>
              <div className={style.container__basisAll}>
                {
                  !collapsed && (
                    <TextEditor
                      editor={editor}
                      editorOptionsText={editorOptionsText}
                      updateTextEditor={updateEditor}
                      openModalEditorLink={openModalEditorLink}
                      openModalEditorButton={openModalEditorButton}
                      openModalEditorImage={openModalEditorImage}
                      currentInfo={currentInfo}
                      onAutomatedTagChosen={onAutomatedTagChosen}
                    />
                  )
                }
              </div>
            </div>
          </div>
        </ActionableCard.Body>
        <ActionableCard.Footer>
          <div className={style.footerCard}>
            <Button
              type={BUTTON.Error}
              onClick={() => deleteComponent(component.id, physicianId, emailFlowId)}
            >
              Excluir
            </Button>
            <Button
              type={BUTTON.Light}
              onClick={() => openModalSendEmailTest(physicianId)}
            >
              ENVIAR E-MAIL DE TESTE
            </Button>
            <Button type={BUTTON.Success} onClick={() => saveComponent(physicianId)}>Salvar</Button>
          </div>
        </ActionableCard.Footer>
      </ActionableCard>
    </div>
  );
};

export default EditEmailFlowComponents;
