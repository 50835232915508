import React from 'react';

import { SnackbarProps } from 'features/tissInvoicing/types';
import { Body } from '@iclinic/design-system';

export const REOPEN_MESSAGE: SnackbarProps = {
  message: 'Lote reaberto com sucesso',
  severity: 'success',
};

const ReOpenLot = () => (
  <Body variant="xs">
    Essa ação limpa todas as configurações desse lote e você terá que
    configura-lo novamente. Deseja reabrir esse lote?
  </Body>
);

export default ReOpenLot;
