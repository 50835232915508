import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { captureException } from '@sentry/browser';
import { Response } from '@types';

import { RequestStatus } from 'shared/constants/State';
import * as api from '../services';
import { ReferralsParticipant } from '../services/types';
import { parseIndicationUrl } from '../utils/urls';
import shortUrl from 'services/iclinic/shortUrl';
import { Award, AwardsReponse, ShortUrlResponses } from '../types';
import * as actions from '.';
import { getAwards } from './selectors';
import { dispatchWebShare } from 'shared/utils/webShare';
import { Channels } from '../constants/urls';
import { intercomEventName } from '../constants';
import {
  dispatchIntercomIndication,
  dispatchIntercomPromoter,
  createCookieByDay,
} from '../utils';
import { getCookie } from 'shared/utils/cookie';
import { checkPromoterCookieVariable } from 'features/referrals/constants/checkIsPromoter';

type PayloadSaveInterestIndication = ReturnType<
  typeof actions.saveInterestIndication
>;
type PayloadRedeemAwardReferrals = ReturnType<
  typeof actions.triggerReferralRedeemAwards
>;

type PayloadWebShareData = ReturnType<typeof actions.webShareData>;

type WebShareData = {
  title: string;
  url?: string;
  text?: string;
};

export function* fetchReferralsParticipantWorker() {
  try {
    const { data: participant }: Response<ReferralsParticipant> = yield call(
      api.getReferralsParticipant,
    );

    const { referral_code, promo_code } = participant;

    const indicationData = {
      referralCode: referral_code,
      promoCode: promo_code,
    };

    const {
      copy: { data: copyUrlResponse },
      share: { data: webShareUrlResponse },
      whatsapp: { data: whatsappUrlResponse },
    }: ShortUrlResponses = yield all({
      copy: call(
        shortUrl,
        parseIndicationUrl({ ...indicationData, channel: Channels.COPY }),
      ),
      share: call(
        shortUrl,
        parseIndicationUrl({ ...indicationData, channel: Channels.SHARE }),
      ),
      whatsapp: call(
        shortUrl,
        parseIndicationUrl({ ...indicationData, channel: Channels.WHATSAPP }),
      ),
    });

    yield put(
      actions.fetchReferralsParticipantSuccess({
        copy: copyUrlResponse.short_url,
        webshare: webShareUrlResponse.short_url,
        whatsapp: whatsappUrlResponse.short_url,
      }),
    );
  } catch {
    yield put(actions.fetchReferralsParticipantFailure());
  }
}

export function* saveInterestIndicationWorker({
  payload,
}: PayloadSaveInterestIndication) {
  try {
    yield call(api.getSaveInterestingIndication, payload.channel);

    yield put(
      actions.updateSaveInterestIndicationStatus(RequestStatus.Success),
    );
  } catch (err) {
    yield put(actions.updateSaveInterestIndicationStatus(RequestStatus.Error));
    yield call(captureException, err);
  }
}

export function* webShareDataWorker({ payload }: PayloadWebShareData) {
  try {
    const webShareData: WebShareData = {
      title: 'Venha para iClinic',
      ...payload,
    };
    yield call(dispatchWebShare, webShareData);
    yield put(actions.saveInterestIndication({ channel: 'copy_link' }));
  } catch (err) {
    yield call(captureException, err);
  }
}

export function* fetchReferralAwardsWorker() {
  try {
    const {
      data: { objects: awards },
    }: Response<AwardsReponse> = yield call(api.getReferralAwards);

    yield put(actions.fetchReferralAwardsSuccess(awards));
  } catch {
    yield put(actions.fetchReferralAwardsFailure());
  }
}

export function* redeemAwardReferralsWorker({
  payload: redeemAwardData,
}: PayloadRedeemAwardReferrals) {
  try {
    yield put(actions.updateAwardRedeemStatus(RequestStatus.Pending));

    const { data: award }: Response<Award> = yield call(
      api.postReferralsRedeemAward,
      redeemAwardData,
    );

    const awards: Award[] = yield select(getAwards);

    const updatedAwards = awards.map((awardItem) =>
      award.id === awardItem.id ? award : awardItem,
    );

    yield put(actions.updateReferralAwards(updatedAwards));
    yield put(actions.updateAwardRedeemStatus(RequestStatus.Success));
  } catch {
    yield put(actions.updateAwardRedeemStatus(RequestStatus.Error));
  }
}

export function* trackIndicationToIntercomWorker() {
  try {
    yield call(api.getCheckInterestingIndication, intercomEventName);

    const intercomLoadingTime = 10000;

    yield call(setTimeout, dispatchIntercomIndication, intercomLoadingTime);
  } catch (err) {
    yield call(captureException, err);
  }
}

function* dispatchIntercomPromoterIfHasCookie() {
  if (!getCookie(checkPromoterCookieVariable)) {
    yield call(api.checkContactIsAPromoter);

    createCookieByDay(checkPromoterCookieVariable, 60);

    const intercomLoadingTime = 10000;

    yield call(setTimeout, dispatchIntercomPromoter, intercomLoadingTime);
  } else {
    createCookieByDay(checkPromoterCookieVariable, 1);
  }
}

export function* checkContactIsAPromoterWorker() {
  try {
    yield call(dispatchIntercomPromoterIfHasCookie);
  } catch (err) {
    createCookieByDay(checkPromoterCookieVariable, 1);

    yield call(captureException, err);
  }
}

export default function* referralsSagas() {
  yield takeLatest(
    actions.fetchReferralsParticipant,
    fetchReferralsParticipantWorker,
  );
  yield takeLatest(
    actions.saveInterestIndication,
    saveInterestIndicationWorker,
  );
  yield takeLatest(actions.fetchReferralAwards, fetchReferralAwardsWorker);
  yield takeLatest(
    actions.triggerReferralRedeemAwards,
    redeemAwardReferralsWorker,
  );
  yield takeLatest(actions.webShareData, webShareDataWorker);
  yield takeLatest(
    actions.trackIndicationToIntercom,
    trackIndicationToIntercomWorker,
  );
  yield takeLatest(
    actions.checkContactIsAPromoter,
    checkContactIsAPromoterWorker,
  );
}
