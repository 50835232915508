import { Box as BoxUI, MaterialUICore, tokens } from '@iclinic/design-system';
import { CenterBox } from 'features/tissInvoicing/components/ui/Flexbox';

const { styled } = MaterialUICore;

export const Container = styled(BoxUI)({
  padding: `0 ${tokens.spacing.sm}`,
  margin: `0 ${tokens.spacing.sm} ${tokens.spacing.sm}`,
});

export const Content = styled(BoxUI)({
  background: tokens.color.neutral[0],
  border: `1px solid ${tokens.color.neutral[50]}`,
  borderRadius: tokens.border.radius.md,
  padding: tokens.spacing.lg,
  marginTop: tokens.spacing.lg,
});

export const LoaderContainer = styled(CenterBox)({
  flexDirection: 'column',
  padding: tokens.spacing.base,
  minHeight: '860px',
});
