// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import style from './style/Upsell.scss';

const Upsell = ({ children, title, isEmpty }) => {
  const modalIntro = isEmpty
    ? 'Você não possui recursos adicionais para este profissional.'
    : 'Deseja contratar recursos adicionais para este profissional?';

  return (
    <div className={style.selectProduct} id="container-select-products">
      <p className={style.selectProduct__title}>{title}</p>
      <p className={style.selectProduct__title_bottom}>{modalIntro}</p>
      {children}
    </div>
  );
};

Upsell.defaultProps = {
  title: '',
  isEmpty: false,
};

Upsell.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  isEmpty: PropTypes.bool,
};

export default Upsell;
