/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  Button as DSButton,
  ButtonProps,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color } = tokens;

type ButtonStatusProps = {
  redeemed: boolean;
};

export const Button = styled(
  ({ redeemed, ...rest }: ButtonStatusProps & ButtonProps) => (
    <DSButton {...rest} />
  ),
)({
  '&.MuiButton-contained.Mui-disabled': {
    backgroundColor: (props: ButtonStatusProps) =>
      props.redeemed ? color.primary.main : color.neutral[50],
    color: (props: ButtonStatusProps) =>
      props.redeemed ? color.neutral[0] : color.neutral[300],
    opacity: (props: ButtonStatusProps) => (props.redeemed ? 0.32 : 1),
  },
});
