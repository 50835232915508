// External
import { connect } from 'react-redux';

// Internal
import * as selectors from 'state/campaign/selectors';
import SegmentCampaignAudience from '../components/audiences/SegmentCampaignAudience';

const mapStateToProps = (state) => ({
  availableItems: selectors.getAvailableAddFilters(state),
});

export default connect(mapStateToProps)(SegmentCampaignAudience);
