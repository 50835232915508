import { createSelector } from 'reselect';

import { AddressData, PersonVUCData, SampleBox } from '../../services/types';
import { UserInfo } from './types';
import { RequestStatus } from 'shared/constants/State';
import { IStoreState } from 'state/rootReducer';
import { ZipCodeRawData } from 'services/iclinic/easyZipCode/types';

export const getBoxStatus = ({ freeSamples }: IStoreState) =>
  freeSamples.requestFreeSamples.boxStatus;

export const isFetchingBox = ({ freeSamples }: IStoreState): boolean =>
  freeSamples.requestFreeSamples.boxStatus === RequestStatus.Pending;

export const getBox = ({ freeSamples }: IStoreState): SampleBox | undefined =>
  freeSamples.requestFreeSamples.box;

export const getAddress = ({
  freeSamples,
}: IStoreState): AddressData | undefined =>
  freeSamples.requestFreeSamples.address;

export const getSubmitRequestStatus = ({
  freeSamples,
}: IStoreState): RequestStatus | undefined =>
  freeSamples.requestFreeSamples.submitStatus;

export const isSubmitingRequest = ({ freeSamples }: IStoreState): boolean =>
  freeSamples.requestFreeSamples.submitStatus === RequestStatus.Pending;

export const getZipCode = ({
  freeSamples,
}: IStoreState): ZipCodeRawData | undefined =>
  freeSamples.requestFreeSamples.zipCode?.data;

export const getZipCodeStatus = ({
  freeSamples,
}: IStoreState): RequestStatus | undefined =>
  freeSamples.requestFreeSamples.zipCode?.status;

export const getZipCodeAvailability = ({
  freeSamples,
}: IStoreState): boolean | undefined =>
  freeSamples.requestFreeSamples.zipCode?.available;

export const getZipCodeSelector = createSelector(
  [getZipCode, getZipCodeStatus],
  (zipCodeInfo, status) => ({
    zipCodeInfo,
    status,
  }),
);

export const getCanJoinQueue = ({
  freeSamples,
}: IStoreState): boolean | undefined =>
  freeSamples.requestFreeSamples.canJoinQueue;

export const getRequestId = ({
  freeSamples,
}: IStoreState): number | undefined => freeSamples.requestFreeSamples.requestId;

export const getUserInfo = ({ userInfo }: IStoreState): UserInfo => ({
  profileId: userInfo.profileId,
  subscriptionId: userInfo.userData.subscriptionId,
  isSubscriber: userInfo.userData.isSubscriber,
});

export const getPersonVUCData = ({
  freeSamples,
}: IStoreState): PersonVUCData | undefined =>
  freeSamples.requestFreeSamples.personVUC?.data;

export const getStatusPersonVUC = ({
  freeSamples,
}: IStoreState): RequestStatus | undefined =>
  freeSamples.requestFreeSamples.personVUC?.status;
