import { Autocomplete, TextField } from '@iclinic/design-system';
import { useFormikContext } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { debounce } from 'shared/hooks';
import { getExecutorsOptions } from 'features/tissInvoicing/state/autocomplete/selectors';
import { AppointmentGuideFormValues } from 'features/tissInvoicing/types/appointmentGuide';
import { actions as autocompleteActions } from 'features/tissInvoicing/state/autocomplete';
import { PhysicianAutocompleteEntry } from 'features/tissInvoicing/types';

export default ({
  label = '12 - Nome do profissional executante',
  required = false,
}: {
  label?: string;
  required?: boolean;
}) => {
  const dispatch = useDispatch();

  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext<AppointmentGuideFormValues>();

  const executors = useSelector(getExecutorsOptions);

  const renderOption = (option: PhysicianAutocompleteEntry) => {
    if (typeof option === 'string') return option;

    return option?.name ?? '';
  };

  const renderOptionFn = (option: PhysicianAutocompleteEntry) =>
    `${option?.name}`;

  const handleChange = (value: PhysicianAutocompleteEntry) => {
    setFieldValue('professional_executant_name', value?.profile?.name ?? '');
    setFieldValue('professional_executant_council', value?.council_name ?? '');
    setFieldValue(
      'professional_executant_council_number',
      value?.council_number ?? '',
    );
    setFieldValue(
      'professional_executant_council_state',
      value?.council_region ?? '',
    );
    setFieldValue('professional_executant_cbo', value?.cbo_code ?? '');
  };

  const handleFetchExecutorsOptions = debounce((inputValue: string): void => {
    dispatch(autocompleteActions.fetchExecutorsOptions({ name: inputValue }));
  }, 500);

  const handleInputChange = (_: any, value: string) => {
    if (!value || value === '') {
      handleFetchExecutorsOptions('');
      return;
    }
    if (value.length >= 3) {
      handleFetchExecutorsOptions(value);
    }
  };

  const handleOpen = () => {
    dispatch(autocompleteActions.fetchExecutorsOptions({ name: '' }));
  };

  return (
    <Autocomplete
      id="professional_executant_name"
      freeSolo
      fullWidth
      options={executors}
      value={values.professional_executant_name}
      clearOnBlur
      onBlur={handleBlur}
      onChange={(_, value: any) => handleChange(value)}
      getOptionLabel={renderOption}
      renderOption={renderOptionFn}
      onInputChange={handleInputChange}
      onOpen={handleOpen}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name="professional_executant_name"
          data-ga="professional_executant_name"
          required={required}
          error={!!errors.professional_executant_name}
          helperText={
            touched.professional_executant_name &&
            errors.professional_executant_name
          }
        />
      )}
    />
  );
};
