/* eslint-disable max-len */
import React from 'react';

export default {
  accessibility: (
    <g>
      <path d="M12 2c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm9 7h-6v13h-2v-6h-2v6h-2v-13h-6v-2h18v2z" />
    </g>
  ),
  'account-balance': (
    <g>
      <path d="M4 10v7h3v-7h-3zm6 0v7h3v-7h-3zm-8 12h19v-3h-19v3zm14-12v7h3v-7h-3zm-4.5-9l-9.5 5v2h19v-2l-9.5-5z" />
    </g>
  ),
  'account-balance-wallet': (
    <g>
      <path d="M21 18v1c0 1.1-.9 2-2 2h-14c-1.11 0-2-.9-2-2v-14c0-1.1.89-2 2-2h14c1.1 0 2 .9 2 2v1h-9c-1.11 0-2 .9-2 2v8c0 1.1.89 2 2 2h9zm-9-2h10v-8h-10v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    </g>
  ),
  'account-box': (
    <g>
      <path d="M3 5v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2h-14c-1.11 0-2 .9-2 2zm12 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1h-12v-1z" />
    </g>
  ),
  'account-child': (
    <g>
      <path d="M16.5 12c1.38 0 2.49-1.12 2.49-2.5s-1.11-2.5-2.49-2.5c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5zm-7.5-1c1.66 0 2.99-1.34 2.99-3s-1.33-3-2.99-3c-1.66 0-3 1.34-3 3s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75v2.25h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zm-7.5-1c-2.33 0-7 1.17-7 3.5v2.5h7v-2.25c0-.85.33-2.34 2.37-3.47-.87-.18-1.71-.28-2.37-.28z" />
    </g>
  ),
  'account-circle': (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
    </g>
  ),
  'add-box': (
    <g>
      <path d="M19 3h-14c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-2 10h-4v4h-2v-4h-4v-2h4v-4h2v4h4v2z" />
    </g>
  ),
  'add-circle-outline': (
    <g>
      <path d="M13 7h-2v4h-4v2h4v4h2v-4h4v-2h-4v-4zm-1-5c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    </g>
  ),
  'add-circle': (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm5 11h-4v4h-2v-4h-4v-2h4v-4h2v4h4v2z" />
    </g>
  ),
  add: (
    <g>
      <path d="M19 13h-6v6h-2v-6h-6v-2h6v-6h2v6h6v2z" />
    </g>
  ),
  'add-shopping-cart': (
    <g>
      <path d="M11 9h2v-3h3v-2h-3v-3h-2v3h-3v2h3v3zm-4 9c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm10 0c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm-9.83-3.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.86-7.01-1.74-.96h-.01l-1.1 2-2.76 5h-7.02l-.13-.27-2.24-4.73-.95-2-.94-2h-3.27v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2h-11.58c-.13 0-.25-.11-.25-.25z" />
    </g>
  ),
  'alarm-add': (
    <g>
      <path d="M7.88 3.39l-1.28-1.53-4.6 3.85 1.29 1.53 4.59-3.85zm14.12 2.33l-4.6-3.86-1.29 1.53 4.6 3.86 1.29-1.53zm-10-1.72c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7zm1-11h-2v3h-3v2h3v3h2v-3h3v-2h-3v-3z" />
    </g>
  ),
  'alarm-off': (
    <g>
      <path d="M12 6c3.87 0 7 3.13 7 7 0 .84-.16 1.65-.43 2.4l1.52 1.52c.58-1.19.91-2.51.91-3.92 0-4.97-4.03-9-9-9-1.41 0-2.73.33-3.92.91l1.52 1.52c.75-.27 1.56-.43 2.4-.43zm10-.28l-4.6-3.86-1.29 1.53 4.6 3.86 1.29-1.53zm-19.08-3.43l-1.27 1.28 1.33 1.33-1.11.93 1.42 1.42 1.11-.94.8.8c-1.37 1.58-2.2 3.64-2.2 5.89 0 4.97 4.02 9 9 9 2.25 0 4.31-.83 5.89-2.2l2.2 2.2 1.27-1.27-17.47-17.46-.97-.98zm13.55 16.1c-1.21 1-2.77 1.61-4.47 1.61-3.87 0-7-3.13-7-7 0-1.7.61-3.26 1.61-4.47l9.86 9.86zm-8.45-15.11l-1.42-1.42-.86.71 1.42 1.42.86-.71z" />
    </g>
  ),
  'alarm-on': (
    <g>
      <path d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86 1.29-1.53zm-14.12-2.33l-1.28-1.53-4.6 3.85 1.29 1.53 4.59-3.85zm4.12.61c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7zm-1.46-5.47l-2.13-2.13-1.06 1.06 3.18 3.18 6-6-1.06-1.06-4.93 4.95z" />
    </g>
  ),
  alarm: (
    <g>
      <path d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86 1.29-1.53zm-14.12-2.33l-1.28-1.53-4.6 3.85 1.29 1.53 4.59-3.85zm4.62 4.61h-1.5v6l4.75 2.85.75-1.23-4-2.37v-5.25zm-.5-4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
    </g>
  ),
  android: (
    <g>
      <path d="M6 18c0 .55.45 1 1 1h1v3.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-3.5h2v3.5c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-3.5h1c.55 0 1-.45 1-1v-10h-12v10zm-2.5-10c-.83 0-1.5.67-1.5 1.5v7c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-7c0-.83-.67-1.5-1.5-1.5zm17 0c-.83 0-1.5.67-1.5 1.5v7c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-7c0-.83-.67-1.5-1.5-1.5zm-4.97-5.84l1.3-1.3c.2-.2.2-.51 0-.71-.2-.2-.51-.2-.71 0l-1.48 1.48c-.79-.4-1.69-.63-2.64-.63-.96 0-1.86.23-2.66.63l-1.49-1.48c-.2-.2-.51-.2-.71 0-.2.2-.2.51 0 .71l1.31 1.31c-1.48 1.09-2.45 2.84-2.45 4.83h12c0-1.99-.97-3.75-2.47-4.84zm-5.53 2.84h-1v-1h1v1zm5 0h-1v-1h1v1z" />
    </g>
  ),
  announcement: (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 18 4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-7 9h-2v-6h2v6zm0 4h-2v-2h2v2z" />
    </g>
  ),
  apps: (
    <g>
      <path d="M4 8h4v-4h-4v4zm6 12h4v-4h-4v4zm-6 0h4v-4h-4v4zm0-6h4v-4h-4v4zm6 0h4v-4h-4v4zm6-10v4h4v-4h-4zm-6 4h4v-4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
    </g>
  ),
  archive: (
    <g>
      <path d="M20.54 5.23l-1.39-1.68c-.27-.34-.68-.55-1.15-.55h-12c-.47 0-.88.21-1.16.55l-1.38 1.68c-.29.34-.46.79-.46 1.27v12.5c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-12.5c0-.48-.17-.93-.46-1.27zm-8.54 12.27l-5.5-5.5h3.5v-2h4v2h3.5l-5.5 5.5zm-6.88-12.5l.81-1h12l.94 1h-13.75z" />
    </g>
  ),
  'arrow-back': (
    <g>
      <path d="M20 11h-12.17l5.59-5.59-1.42-1.41-8 8 8 8 1.41-1.41-5.58-5.59h12.17v-2z" />
    </g>
  ),
  'arrow-drop-down-circle': (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 12l-4-4h8l-4 4z" />
    </g>
  ),
  'arrow-drop-down': (
    <g>
      <path d="M7 10l5 5 5-5z" />
    </g>
  ),
  'arrow-drop-up': (
    <g>
      <path d="M7 14l5-5 5 5z" />
    </g>
  ),
  'arrow-forward': (
    <g>
      <path d="M12 4l-1.41 1.41 5.58 5.59h-12.17v2h12.17l-5.58 5.59 1.41 1.41 8-8z" />
    </g>
  ),
  'aspect-ratio': (
    <g>
      <path d="M19 12h-2v3h-3v2h5v-5zm-12-3h3v-2h-5v5h2v-3zm14-6h-18c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16.01h-18v-14.02h18v14.02z" />
    </g>
  ),
  assessment: (
    <g>
      <path d="M19 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-10 14h-2v-7h2v7zm4 0h-2v-10h2v10zm4 0h-2v-4h2v4z" />
    </g>
  ),
  'assignment-ind': (
    <g>
      <path d="M19 3h-4.18c-.42-1.16-1.52-2-2.82-2-1.3 0-2.4.84-2.82 2h-4.18c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12h-12v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1.4z" />
    </g>
  ),
  'assignment-late': (
    <g>
      <path d="M19 3h-4.18c-.42-1.16-1.52-2-2.82-2-1.3 0-2.4.84-2.82 2h-4.18c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-6 15h-2v-2h2v2zm0-4h-2v-6h2v6zm-1-9c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
    </g>
  ),
  assignment: (
    <g>
      <path d="M19 3h-4.18c-.42-1.16-1.52-2-2.82-2-1.3 0-2.4.84-2.82 2h-4.18c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14h-7v-2h7v2zm3-4h-10v-2h10v2zm0-4h-10v-2h10v2z" />
    </g>
  ),
  'assignment-returned': (
    <g>
      <path d="M19 3h-4.18c-.42-1.16-1.52-2-2.82-2-1.3 0-2.4.84-2.82 2h-4.18c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 15l-5-5h3v-4h4v4h3l-5 5z" />
    </g>
  ),
  'assignment-return': (
    <g>
      <path d="M19 3h-4.18c-.42-1.16-1.52-2-2.82-2-1.3 0-2.4.84-2.82 2h-4.18c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm4 12h-4v3l-5-5 5-5v3h4v4z" />
    </g>
  ),
  'assignment-turned-in': (
    <g>
      <path d="M19 3h-4.18c-.42-1.16-1.52-2-2.82-2-1.3 0-2.4.84-2.82 2h-4.18c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41 2.59 2.58 6.59-6.59 1.41 1.42-8 8z" />
    </g>
  ),
  attachment: (
    <g>
      <path d="M7.5 18c-3.04 0-5.5-2.46-5.5-5.5s2.46-5.5 5.5-5.5h10.5c2.21 0 4 1.79 4 4s-1.79 4-4 4h-8.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5h7.5v1.5h-7.5c-.55 0-1 .45-1 1s.45 1 1 1h8.5c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5h-10.5c-2.21 0-4 1.79-4 4s1.79 4 4 4h9.5v1.5h-9.5z" />
    </g>
  ),
  autorenew: (
    <g>
      <path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26l1.46-1.46c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74l-1.46 1.46c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z" />
    </g>
  ),
  backspace: (
    <g>
      <path d="M22 3h-15c-.69 0-1.23.35-1.59.88l-5.41 8.12 5.41 8.11c.36.53.9.89 1.59.89h15c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-3 12.59l-1.41 1.41-3.59-3.59-3.59 3.59-1.41-1.41 3.59-3.59-3.59-3.59 1.41-1.41 3.59 3.59 3.59-3.59 1.41 1.41-3.59 3.59 3.59 3.59z" />
    </g>
  ),
  backup: (
    <g>
      <path d="M19.35 10.04c-.68-3.45-3.71-6.04-7.35-6.04-2.89 0-5.4 1.64-6.65 4.04-3.01.32-5.35 2.87-5.35 5.96 0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zm-5.35 2.96v4h-4v-4h-3l5-5 5 5h-3z" />
    </g>
  ),
  block: (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm-8 10c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69l-11.21 11.21c-1.06-1.35-1.69-3.05-1.69-4.9zm8 8c-1.85 0-3.55-.63-4.9-1.69l11.21-11.21c1.06 1.35 1.69 3.05 1.69 4.9 0 4.42-3.58 8-8 8z" />
    </g>
  ),
  'bookmark-outline': (
    <g>
      <path d="M17 3h-10c-1.1 0-1.99.9-1.99 2l-.01 16 7-3 7 3v-16c0-1.1-.9-2-2-2zm0 15l-5-2.18-5 2.18v-13h10v13z" />
    </g>
  ),
  bookmark: (
    <g>
      <path d="M17 3h-10c-1.1 0-1.99.9-1.99 2l-.01 16 7-3 7 3v-16c0-1.1-.9-2-2-2z" />
    </g>
  ),
  book: (
    <g>
      <path d="M18 2h-12c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-12 2h5v8l-2.5-1.5-2.5 1.5v-8z" />
    </g>
  ),
  'bug-report': (
    <g>
      <path d="M20 8h-2.81c-.45-.78-1.07-1.45-1.82-1.96l1.63-1.63-1.41-1.41-2.17 2.17c-.46-.11-.93-.17-1.42-.17-.49 0-.96.06-1.41.17l-2.18-2.17-1.41 1.41 1.62 1.63c-.74.51-1.36 1.18-1.81 1.96h-2.81v2h2.09c-.05.33-.09.66-.09 1v1h-2v2h2v1c0 .34.04.67.09 1h-2.09v2h2.81c1.04 1.79 2.97 3 5.19 3s4.15-1.21 5.19-3h2.81v-2h-2.09c.05-.33.09-.66.09-1v-1h2v-2h-2v-1c0-.34-.04-.67-.09-1h2.09v-2zm-6 8h-4v-2h4v2zm0-4h-4v-2h4v2z" />
    </g>
  ),
  cached: (
    <g>
      <path d="M19 8l-4 4h3c0 3.31-2.69 6-6 6-1.01 0-1.97-.25-2.8-.7l-1.46 1.46c1.23.78 2.69 1.24 4.26 1.24 4.42 0 8-3.58 8-8h3l-4-4zm-13 4c0-3.31 2.69-6 6-6 1.01 0 1.97.25 2.8.7l1.46-1.46c-1.23-.78-2.69-1.24-4.26-1.24-4.42 0-8 3.58-8 8h-3l4 4 4-4h-3z" />
    </g>
  ),
  cancel: (
    <g>
      <path d="M12 2c-5.53 0-10 4.47-10 10s4.47 10 10 10 10-4.47 10-10-4.47-10-10-10zm5 13.59l-1.41 1.41-3.59-3.59-3.59 3.59-1.41-1.41 3.59-3.59-3.59-3.59 1.41-1.41 3.59 3.59 3.59-3.59 1.41 1.41-3.59 3.59 3.59 3.59z" />
    </g>
  ),
  'check-box-outline-blank': (
    <g>
      <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
    </g>
  ),
  'check-box': (
    <g>
      <path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </g>
  ),
  check: (
    <g>
      <path d="M9 16.17l-4.17-4.17-1.42 1.41 5.59 5.59 12-12-1.41-1.41z" />
    </g>
  ),
  'chevron-left': (
    <g>
      <path d="M15.41 7.41l-1.41-1.41-6 6 6 6 1.41-1.41-4.58-4.59z" />
    </g>
  ),
  'chevron-right': (
    <g>
      <path d="M10 6l-1.41 1.41 4.58 4.59-4.58 4.59 1.41 1.41 6-6z" />
    </g>
  ),
  class: (
    <g>
      <path d="M18 2h-12c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-12 2h5v8l-2.5-1.5-2.5 1.5v-8z" />
    </g>
  ),
  clear: (
    <g>
      <path d="M19 6.41l-1.41-1.41-5.59 5.59-5.59-5.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59z" />
    </g>
  ),
  close: (
    <g>
      <path d="M19 6.41l-1.41-1.41-5.59 5.59-5.59-5.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59z" />
    </g>
  ),
  'cloud-circle': (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm4.5 14h-8.5c-1.66 0-3-1.34-3-3s1.34-3 3-3l.14.01c.44-1.73 1.99-3.01 3.86-3.01 2.21 0 4 1.79 4 4h.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5z" />
    </g>
  ),
  'cloud-done': (
    <g>
      <path d="M19.35 10.04c-.68-3.45-3.71-6.04-7.35-6.04-2.89 0-5.4 1.64-6.65 4.04-3.01.32-5.35 2.87-5.35 5.96 0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zm-9.35 6.96l-3.5-3.5 1.41-1.41 2.09 2.08 5.18-5.17 1.41 1.41-6.59 6.59z" />
    </g>
  ),
  'cloud-download': (
    <g>
      <path d="M19.35 10.04c-.68-3.45-3.71-6.04-7.35-6.04-2.89 0-5.4 1.64-6.65 4.04-3.01.32-5.35 2.87-5.35 5.96 0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zm-2.35 2.96l-5 5-5-5h3v-4h4v4h3z" />
    </g>
  ),
  'cloud-off': (
    <g>
      <path d="M19.35 10.04c-.68-3.45-3.71-6.04-7.35-6.04-1.48 0-2.85.43-4.01 1.17l1.46 1.46c.76-.4 1.63-.63 2.55-.63 3.04 0 5.5 2.46 5.5 5.5v.5h1.5c1.66 0 3 1.34 3 3 0 1.13-.64 2.11-1.56 2.62l1.45 1.45c1.27-.91 2.11-2.39 2.11-4.07 0-2.64-2.05-4.78-4.65-4.96zm-16.35-4.77l2.75 2.74c-3.19.14-5.75 2.76-5.75 5.99 0 3.31 2.69 6 6 6h11.73l2 2 1.27-1.27-16.73-16.73-1.27 1.27zm4.73 4.73l8 8h-9.73c-2.21 0-4-1.79-4-4s1.79-4 4-4h1.73z" />
    </g>
  ),
  cloud: (
    <g>
      <path d="M19.35 10.04c-.68-3.45-3.71-6.04-7.35-6.04-2.89 0-5.4 1.64-6.65 4.04-3.01.32-5.35 2.87-5.35 5.96 0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96z" />
    </g>
  ),
  'cloud-queue': (
    <g>
      <path d="M19.35 10.04c-.68-3.45-3.71-6.04-7.35-6.04-2.89 0-5.4 1.64-6.65 4.04-3.01.32-5.35 2.87-5.35 5.96 0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zm-.35 7.96h-13c-2.21 0-4-1.79-4-4s1.79-4 4-4h.71c.66-2.31 2.77-4 5.29-4 3.04 0 5.5 2.46 5.5 5.5v.5h1.5c1.66 0 3 1.34 3 3s-1.34 3-3 3z" />
    </g>
  ),
  'cloud-upload': (
    <g>
      <path d="M19.35 10.04c-.68-3.45-3.71-6.04-7.35-6.04-2.89 0-5.4 1.64-6.65 4.04-3.01.32-5.35 2.87-5.35 5.96 0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zm-5.35 2.96v4h-4v-4h-3l5-5 5 5h-3z" />
    </g>
  ),
  'content-copy': (
    <g>
      <path d="M16 1h-12c-1.1 0-2 .9-2 2v14h2v-14h12v-2zm3 4h-11c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-11v-14h11v14z" />
    </g>
  ),
  'content-cut': (
    <g>
      <path d="M9.64 7.64c.23-.5.36-1.05.36-1.64 0-2.21-1.79-4-4-4s-4 1.79-4 4 1.79 4 4 4c.59 0 1.14-.13 1.64-.36l2.36 2.36-2.36 2.36c-.5-.23-1.05-.36-1.64-.36-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4c0-.59-.13-1.14-.36-1.64l2.36-2.36 7 7h3v-1l-12.36-12.36zm-3.64.36c-1.1 0-2-.89-2-2s.9-2 2-2 2 .89 2 2-.9 2-2 2zm0 12c-1.1 0-2-.89-2-2s.9-2 2-2 2 .89 2 2-.9 2-2 2zm6-7.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5.5.22.5.5-.22.5-.5.5zm7-9.5l-6 6 2 2 7-7v-1z" />
    </g>
  ),
  'content-paste': (
    <g>
      <path d="M19 2h-4.18c-.42-1.16-1.52-2-2.82-2-1.3 0-2.4.84-2.82 2h-4.18c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm7 18h-14v-16h2v3h10v-3h2v16z" />
    </g>
  ),
  create: (
    <g>
      <path d="M3 17.25v3.75h3.75l11.06-11.06-3.75-3.75-11.06 11.06zm17.71-10.21c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
    </g>
  ),
  'credit-card': (
    <g>
      <path d="M4 12h16v6h-16z" />
      <path d="M20 4h-16c-1.11 0-1.99.89-1.99 2l-.01 12c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-12c0-1.11-.89-2-2-2zm0 14h-16v-6h16v6zm0-10h-16v-2h16v2z" />
    </g>
  ),
  dashboard: (
    <g>
      <path d="M3 13h8v-10h-8v10zm0 8h8v-6h-8v6zm10 0h8v-10h-8v10zm0-18v6h8v-6h-8z" />
    </g>
  ),
  delete: (
    <g>
      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-12h-12v12zm13-15h-3.5l-1-1h-5l-1 1h-3.5v2h14v-2z" />
    </g>
  ),
  description: (
    <g>
      <path d="M14 2h-8c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.89 2 1.99 2h12.01c1.1 0 2-.9 2-2v-12l-6-6zm2 16h-8v-2h8v2zm0-4h-8v-2h8v2zm-3-5v-5.5l5.5 5.5h-5.5z" />
    </g>
  ),
  'developer-mode-tv': (
    <g>
      <path d="M4 5h16v2h2v-2c0-1.1-.9-2-2-2h-16c-1.1 0-2 .9-2 2v2h2v-2zm3.55 8.83l-2.83-2.83 2.83-2.83-1.41-1.41-4.25 4.24 4.24 4.24 1.42-1.41zm12.45 3.17h-16v-2h-2v2c0 1.1.9 2 2 2h4v2h8v-2h4c1.1 0 1.99-.9 1.99-2v-2h-1.99v2zm2-6.01l-4.24-4.24-1.41 1.41 2.82 2.84-2.83 2.83 1.41 1.41 4.25-4.23v-.02z" />
    </g>
  ),
  dns: (
    <g>
      <path d="M20 13h-16c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1zm-13 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm13-16h-16c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h16c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1zm-13 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
    </g>
  ),
  'done-all': (
    <g>
      <path d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41 6.34-6.34zm4.24-1.41l-10.58 10.58-4.18-4.17-1.41 1.41 5.59 5.59 12-12-1.42-1.41zm-21.83 7.82l5.59 5.59 1.41-1.41-5.58-5.59-1.42 1.41z" />
    </g>
  ),
  done: (
    <g>
      <path d="M9 16.17l-4.17-4.17-1.42 1.41 5.59 5.59 12-12-1.41-1.41z" />
    </g>
  ),
  drafts: (
    <g>
      <path d="M21.99 8c0-.72-.37-1.35-.94-1.7l-9.05-5.3-9.05 5.3c-.57.35-.95.98-.95 1.7v10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2l-.01-10zm-9.99 5l-8.26-5.16 8.26-4.84 8.26 4.84-8.26 5.16z" />
    </g>
  ),
  drawer: (
    <g>
      <path d="M20,4H4C2.8,4,2,4.8,2,6v12c0,1.2,0.8,2,2,2h16c1,0,2-0.8,2-2V6C22,4.8,21,4,20,4z M20,18h-6V6h6V18z" />
    </g>
  ),
  error: (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm1 15h-2v-2h2v2zm0-4h-2v-6h2v6z" />
    </g>
  ),
  event: (
    <g>
      <path d="M17 12h-5v5h5v-5zm-1-11v2h-8v-2h-2v2h-1c-1.11 0-1.99.9-1.99 2l-.01 14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2h-1v-2h-2zm3 18h-14v-11h14v11z" />
    </g>
  ),
  'exit-to-app': (
    <g>
      <path d="M10.09 15.59l1.41 1.41 5-5-5-5-1.41 1.41 2.58 2.59h-9.67v2h9.67l-2.58 2.59zm8.91-12.59h-14c-1.11 0-2 .9-2 2v4h2v-4h14v14h-14v-4h-2v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2z" />
    </g>
  ),
  'expand-less': (
    <g>
      <path d="M12 8l-6 6 1.41 1.41 4.59-4.58 4.59 4.58 1.41-1.41z" />
    </g>
  ),
  'expand-more': (
    <g>
      <path d="M16.59 8.59l-4.59 4.58-4.59-4.58-1.41 1.41 6 6 6-6z" />
    </g>
  ),
  explore: (
    <g>
      <path d="M12 10.9c-.61 0-1.1.49-1.1 1.1s.49 1.1 1.1 1.1c.61 0 1.1-.49 1.1-1.1s-.49-1.1-1.1-1.1zm0-8.9c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm2.19 12.19l-8.19 3.81 3.81-8.19 8.19-3.81-3.81 8.19z" />
    </g>
  ),
  extension: (
    <g>
      <path d="M20.5 11h-1.5v-4c0-1.1-.9-2-2-2h-4v-1.5c0-1.38-1.12-2.5-2.5-2.5s-2.5 1.12-2.5 2.5v1.5h-4c-1.1 0-1.99.9-1.99 2v3.8h1.49c1.49 0 2.7 1.21 2.7 2.7s-1.21 2.7-2.7 2.7h-1.5v3.8c0 1.1.9 2 2 2h3.8v-1.5c0-1.49 1.21-2.7 2.7-2.7 1.49 0 2.7 1.21 2.7 2.7v1.5h3.8c1.1 0 2-.9 2-2v-4h1.5c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5z" />
    </g>
  ),
  'face-unlock': (
    <g>
      <path d="M14.69 17.1c-.74.58-1.7.9-2.69.9s-1.95-.32-2.69-.9c-.22-.17-.53-.13-.7.09-.17.22-.13.53.09.7.91.72 2.09 1.11 3.3 1.11s2.39-.39 3.31-1.1c.22-.17.26-.48.09-.7-.17-.23-.49-.26-.71-.1z" />
      <circle cx="8.5" cy="12.5" r="1" />
      <path d="M12 0c-6.63 0-12 5.37-12 12s5.37 12 12 12 12-5.37 12-12-5.37-12-12-12zm7.96 14.82c-1.09 3.74-4.27 6.46-8.04 6.46-3.78 0-6.96-2.72-8.04-6.47-1.19-.11-2.13-1.18-2.13-2.52 0-1.27.85-2.31 1.97-2.5 2.09-1.46 3.8-3.49 4.09-5.05v-.01c1.35 2.63 6.3 5.19 11.83 5.06l.3-.03c1.28 0 2.31 1.14 2.31 2.54 0 1.38-1.02 2.51-2.29 2.52z" />
      <circle cx="15.5" cy="12.5" r="1" />
    </g>
  ),
  'favorite-outline': (
    <g>
      <path d="M16.5 3c-1.74 0-3.41.81-4.5 2.09-1.09-1.28-2.76-2.09-4.5-2.09-3.08 0-5.5 2.42-5.5 5.5 0 3.78 3.4 6.86 8.55 11.54l1.45 1.31 1.45-1.32c5.15-4.67 8.55-7.75 8.55-11.53 0-3.08-2.42-5.5-5.5-5.5zm-4.4 15.55l-.1.1-.1-.1c-4.76-4.31-7.9-7.16-7.9-10.05 0-2 1.5-3.5 3.5-3.5 1.54 0 3.04.99 3.57 2.36h1.87c.52-1.37 2.02-2.36 3.56-2.36 2 0 3.5 1.5 3.5 3.5 0 2.89-3.14 5.74-7.9 10.05z" />
    </g>
  ),
  favorite: (
    <g>
      <path d="M12 21.35l-1.45-1.32c-5.15-4.67-8.55-7.75-8.55-11.53 0-3.08 2.42-5.5 5.5-5.5 1.74 0 3.41.81 4.5 2.09 1.09-1.28 2.76-2.09 4.5-2.09 3.08 0 5.5 2.42 5.5 5.5 0 3.78-3.4 6.86-8.55 11.54l-1.45 1.31z" />
    </g>
  ),
  'file-download': (
    <g>
      <path d="M19 9h-4v-6h-6v6h-4l7 7 7-7zm-14 9v2h14v-2h-14z" />
    </g>
  ),
  'file-map': (
    <g>
      <path d="M12 6.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm7-5.5h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-6.5 16h-1c-.95-4.09-3.99-5.84-3.99-9 0-2.49 2-4.5 4.49-4.5s4.51 2.01 4.51 4.5c0 3.16-3.06 4.91-4.01 9z" />
    </g>
  ),
  'file-upload': (
    <g>
      <path d="M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2h-14z" />
    </g>
  ),
  'filter-list': (
    <g>
      <path d="M10 18h4v-2h-4v2zm-7-12v2h18v-2h-18zm3 7h12v-2h-12v2z" />
    </g>
  ),
  'find-in-page': (
    <g>
      <path d="M20 19.59v-11.59l-6-6h-8c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.89 2 1.99 2h12.01c.45 0 .85-.15 1.19-.4l-4.43-4.43c-.8.52-1.74.83-2.76.83-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1.02-.31 1.96-.83 2.75l3.83 3.84zm-11-6.59c0 1.66 1.34 3 3 3s3-1.34 3-3-1.34-3-3-3-3 1.34-3 3z" />
    </g>
  ),
  'find-replace': (
    <g>
      <path d="M11 6c1.38 0 2.63.56 3.54 1.46l-2.54 2.54h6v-6l-2.05 2.05c-1.27-1.27-3.02-2.05-4.95-2.05-3.53 0-6.43 2.61-6.92 6h2.02c.46-2.28 2.48-4 4.9-4zm5.64 9.14c.66-.9 1.12-1.97 1.28-3.14h-2.02c-.46 2.28-2.48 4-4.9 4-1.38 0-2.63-.56-3.54-1.46l2.54-2.54h-6v6l2.05-2.05c1.27 1.27 3.02 2.05 4.95 2.05 1.55 0 2.98-.51 4.14-1.36l4.86 4.85 1.49-1.49-4.85-4.86z" />
    </g>
  ),
  flag: (
    <g>
      <path d="M14.4 6l-.4-2h-9v17h2v-7h5.6l.4 2h7v-10z" />
    </g>
  ),
  'flip-to-back': (
    <g>
      <path d="M9 7h-2v2h2v-2zm0 4h-2v2h2v-2zm0-8c-1.11 0-2 .9-2 2h2v-2zm4 12h-2v2h2v-2zm6-12v2h2c0-1.1-.9-2-2-2zm-6 0h-2v2h2v-2zm-4 14v-2h-2c0 1.1.89 2 2 2zm10-4h2v-2h-2v2zm0-4h2v-2h-2v2zm0 8c1.1 0 2-.9 2-2h-2v2zm-14-10h-2v12c0 1.1.89 2 2 2h12v-2h-12v-12zm10-2h2v-2h-2v2zm0 12h2v-2h-2v2z" />
    </g>
  ),
  'flip-to-front': (
    <g>
      <path d="M3 13h2v-2h-2v2zm0 4h2v-2h-2v2zm2 4v-2h-2c0 1.1.89 2 2 2zm-2-12h2v-2h-2v2zm12 12h2v-2h-2v2zm4-18h-10c-1.11 0-2 .9-2 2v10c0 1.1.89 2 2 2h10c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm0 12h-10v-10h10v10zm-8 6h2v-2h-2v2zm-4 0h2v-2h-2v2z" />
    </g>
  ),
  'folder-open': (
    <g>
      <path d="M20 6h-8l-2-2h-6c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm0 12h-16v-10h16v10z" />
    </g>
  ),
  folder: (
    <g>
      <path d="M10 4h-6c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2h-8l-2-2z" />
    </g>
  ),
  'folder-shared': (
    <g>
      <path d="M20 6h-8l-2-2h-6c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm-5 3c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm4 8h-8v-1c0-1.33 2.67-2 4-2s4 .67 4 2v1z" />
    </g>
  ),
  forward: (
    <g>
      <path d="M12 8v-4l8 8-8 8v-4h-8v-8z" />
    </g>
  ),
  'fullscreen-exit': (
    <g>
      <path d="M5 16h3v3h2v-5h-5v2zm3-8h-3v2h5v-5h-2v3zm6 11h2v-3h3v-2h-5v5zm2-11v-3h-2v5h5v-2h-3z" />
    </g>
  ),
  fullscreen: (
    <g>
      <path d="M7 14h-2v5h5v-2h-3v-3zm-2-4h2v-3h3v-2h-5v5zm12 7h-3v2h5v-5h-2v3zm-3-12v2h3v3h2v-5h-5z" />
    </g>
  ),
  gesture: (
    <g>
      <path d="M4.59 6.89c.7-.71 1.4-1.35 1.71-1.22.5.2 0 1.03-.3 1.52-.25.42-2.86 3.89-2.86 6.31 0 1.28.48 2.34 1.34 2.98.75.56 1.74.73 2.64.46 1.07-.31 1.95-1.4 3.06-2.77 1.21-1.49 2.83-3.44 4.08-3.44 1.63 0 1.65 1.01 1.76 1.79-3.78.64-5.38 3.67-5.38 5.37 0 1.7 1.44 3.09 3.21 3.09 1.63 0 4.29-1.33 4.69-6.1h2.46v-2.5h-2.47c-.15-1.65-1.09-4.2-4.03-4.2-2.25 0-4.18 1.91-4.94 2.84-.58.73-2.06 2.48-2.29 2.72-.25.3-.68.84-1.11.84-.45 0-.72-.83-.36-1.92.35-1.09 1.4-2.86 1.85-3.52.78-1.14 1.3-1.92 1.3-3.28 0-2.17-1.64-2.86-2.51-2.86-1.32 0-2.47 1-2.72 1.25-.36.36-.66.66-.88.93l1.75 1.71zm9.29 11.66c-.31 0-.74-.26-.74-.72 0-.6.73-2.2 2.87-2.76-.3 2.69-1.43 3.48-2.13 3.48z" />
    </g>
  ),
  'get-app': (
    <g>
      <path d="M19 9h-4v-6h-6v6h-4l7 7 7-7zm-14 9v2h14v-2h-14z" />
    </g>
  ),
  grade: (
    <g>
      <path d="M12 17.27l6.18 3.73-1.64-7.03 5.46-4.73-7.19-.61-2.81-6.63-2.81 6.63-7.19.61 5.46 4.73-1.64 7.03z" />
    </g>
  ),
  'group-work': (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm-4 15.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5zm1.5-9.5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5zm6.5 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
    </g>
  ),
  help: (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92c-.72.73-1.17 1.33-1.17 2.83h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2h-2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
    </g>
  ),
  'highlight-remove': (
    <g>
      <path d="M14.59 8l-2.59 2.59-2.59-2.59-1.41 1.41 2.59 2.59-2.59 2.59 1.41 1.41 2.59-2.59 2.59 2.59 1.41-1.41-2.59-2.59 2.59-2.59-1.41-1.41zm-2.59-6c-5.53 0-10 4.47-10 10s4.47 10 10 10 10-4.47 10-10-4.47-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    </g>
  ),
  history: (
    <g>
      <path
        opacity=".9"
        d="M13 3c-4.97 0-9 4.03-9 9h-3l3.89 3.89.07.14 4.04-4.03h-3c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42c1.63 1.63 3.87 2.64 6.36 2.64 4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08v-4.25h-1.5z"
      />
    </g>
  ),
  home: (
    <g>
      <path d="M10 20v-6h4v6h5v-8h3l-10-9-10 9h3v8z" />
    </g>
  ),
  https: (
    <g>
      <path d="M18 8h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5v2h-1c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9h-6.2v-2c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
    </g>
  ),
  inbox: (
    <g>
      <path d="M19 3h-14.01c-1.1 0-1.98.9-1.98 2l-.01 14c0 1.1.89 2 1.99 2h14.01c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 12h-4c0 1.66-1.34 3-3 3s-3-1.34-3-3h-4.01v-10h14.01v10zm-3-5h-2v-3h-4v3h-2l4 4 4-4z" />
    </g>
  ),
  'info-outline': (
    <g>
      <path d="M11 17h2v-6h-2v6zm1-15c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-11h2v-2h-2v2z" />
    </g>
  ),
  info: (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm1 15h-2v-6h2v6zm0-8h-2v-2h2v2z" />
    </g>
  ),
  input: (
    <g>
      <path d="M21 3.01h-18c-1.1 0-2 .9-2 2v3.99h2v-4.01h18v14.03h-18v-4.02h-2v4.01c0 1.1.9 1.98 2 1.98h18c1.1 0 2-.88 2-1.98v-14c0-1.11-.9-2-2-2zm-10 12.99l4-4-4-4v3h-10v2h10v3z" />
    </g>
  ),
  'invert-colors': (
    <g>
      <path d="M17.66 7.93l-5.66-5.66-5.66 5.66c-3.12 3.12-3.12 8.19 0 11.31 1.56 1.56 3.61 2.34 5.66 2.34 2.05 0 4.1-.78 5.66-2.34 3.12-3.12 3.12-8.19 0-11.31zm-5.66 11.66c-1.6 0-3.11-.62-4.24-1.76-1.14-1.14-1.76-2.64-1.76-4.24s.62-3.11 1.76-4.24l4.24-4.25v14.49z" />
    </g>
  ),
  'label-outline': (
    <g>
      <path d="M17.63 5.84c-.36-.51-.96-.84-1.63-.84l-11 .01c-1.1 0-2 .89-2 1.99v10c0 1.1.9 1.99 2 1.99l11 .01c.67 0 1.27-.33 1.63-.84l4.37-6.16-4.37-6.16zm-1.63 11.16h-11v-10h11l3.55 5-3.55 5z" />
    </g>
  ),
  label: (
    <g>
      <path d="M17.63 5.84c-.36-.51-.96-.84-1.63-.84l-11 .01c-1.1 0-2 .89-2 1.99v10c0 1.1.9 1.99 2 1.99l11 .01c.67 0 1.27-.33 1.63-.84l4.37-6.16-4.37-6.16z" />
    </g>
  ),
  language: (
    <g>
      <path d="M11.99 2c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zm-6.92-3.96c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zm-7.74 9.96c-.16-.64-.26-1.31-.26-2s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2h-3.38zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8h-2.95c.96-1.66 2.49-2.93 4.33-3.56-.6 1.11-1.06 2.31-1.38 3.56zm3.97 11.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zm2.34-5.96h-4.68c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zm1.77-5.56c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" />
    </g>
  ),
  launch: (
    <g>
      <path d="M19 19h-14v-14h7v-2h-7c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zm-5-16v2h3.59l-9.83 9.83 1.41 1.41 9.83-9.83v3.59h2v-7h-7z" />
    </g>
  ),
  link: (
    <g>
      <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4v-1.9h-4c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9h-4c-1.71 0-3.1-1.39-3.1-3.1zm4.1 1h8v-2h-8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4v1.9h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" />
    </g>
  ),
  list: (
    <g>
      <path d="M3 13h2v-2h-2v2zm0 4h2v-2h-2v2zm0-8h2v-2h-2v2zm4 4h14v-2h-14v2zm0 4h14v-2h-14v2zm0-10v2h14v-2h-14z" />
    </g>
  ),
  'lock-open': (
    <g>
      <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2h-9.1c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm0 12h-12v-10h12v10z" />
    </g>
  ),
  'lock-outline': (
    <g>
      <path d="M18 8h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5v2h-1c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm-6-5.1c1.71 0 3.1 1.39 3.1 3.1v2h-6.1v-2h-.1c0-1.71 1.39-3.1 3.1-3.1zm6 17.1h-12v-10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z" />
    </g>
  ),
  lock: (
    <g>
      <path d="M18 8h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5v2h-1c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9h-6.2v-2c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
    </g>
  ),
  loyalty: (
    <g>
      <path d="M21.41 11.58l-9-9c-.36-.36-.86-.58-1.41-.58h-7c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zm-15.91-4.58c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm11.77 8.27l-4.27 4.27-4.27-4.27c-.45-.46-.73-1.08-.73-1.77 0-1.38 1.12-2.5 2.5-2.5.69 0 1.32.28 1.77.74l.73.72.73-.73c.45-.45 1.08-.73 1.77-.73 1.38 0 2.5 1.12 2.5 2.5 0 .69-.28 1.32-.73 1.77z" />
    </g>
  ),
  mail: (
    <g>
      <path d="M20 4h-16c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm0 4l-8 5-8-5v-2l8 5 8-5v2z" />
    </g>
  ),
  'markunread-mailbox': (
    <g>
      <path d="M20 6h-10v6h-2v-8h6v-4h-8v6h-2c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2z" />
    </g>
  ),
  markunread: (
    <g>
      <path d="M20 4h-16c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm0 4l-8 5-8-5v-2l8 5 8-5v2z" />
    </g>
  ),
  menu: (
    <g>
      <path d="M3 18h18v-2h-18v2zm0-5h18v-2h-18v2zm0-7v2h18v-2h-18z" />
    </g>
  ),
  'more-horiz': (
    <g>
      <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </g>
  ),
  'more-vert': (
    <g>
      <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </g>
  ),
  'note-add': (
    <g>
      <path d="M14 2h-8c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.89 2 1.99 2h12.01c1.1 0 2-.9 2-2v-12l-6-6zm2 14h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2zm-3-7v-5.5l5.5 5.5h-5.5z" />
    </g>
  ),
  'open-in-browser': (
    <g>
      <path d="M19 4h-14c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h4v-2h-4v-10h14v10h-4v2h4c1.1 0 2-.9 2-2v-12c0-1.1-.89-2-2-2zm-7 6l-4 4h3v6h2v-6h3l-4-4z" />
    </g>
  ),
  'open-in-new': (
    <g>
      <path d="M19 19h-14v-14h7v-2h-7c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zm-5-16v2h3.59l-9.83 9.83 1.41 1.41 9.83-9.83v3.59h2v-7h-7z" />
    </g>
  ),
  'open-with': (
    <g>
      <path d="M10 9h4v-3h3l-5-5-5 5h3v3zm-1 1h-3v-3l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3h-3l5 5 5-5h-3v-3z" />
    </g>
  ),
  pageview: (
    <g>
      <path d="M11 8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm8-5h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-1.41 16l-3.83-3.83c-.8.52-1.74.83-2.76.83-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5c0 1.02-.31 1.96-.83 2.75l3.83 3.84-1.41 1.41z" />
    </g>
  ),
  payment: (
    <g>
      <path d="M20 4h-16c-1.11 0-1.99.89-1.99 2l-.01 12c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-12c0-1.11-.89-2-2-2zm0 14h-16v-6h16v6zm0-10h-16v-2h16v2z" />
    </g>
  ),
  'perm-camera-mic': (
    <g>
      <path d="M20 5h-3.17l-1.83-2h-6l-1.83 2h-3.17c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h7v-2.09c-2.83-.48-5-2.94-5-5.91h2c0 2.21 1.79 4 4 4s4-1.79 4-4h2c0 2.97-2.17 5.43-5 5.91v2.09h7c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-6 8c0 1.1-.9 2-2 2s-2-.9-2-2v-4c0-1.1.9-2 2-2s2 .9 2 2v4z" />
    </g>
  ),
  'perm-contact-cal': (
    <g>
      <path d="M19 3h-1v-2h-2v2h-8v-2h-2v2h-1c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-7 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12h-12v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1z" />
    </g>
  ),
  'perm-data-setting': (
    <g>
      <path d="M18.99 11.5c.34 0 .67.03 1 .07l.01-11.57-20 20h11.56c-.04-.33-.07-.66-.07-1 0-4.14 3.36-7.5 7.5-7.5zm3.71 7.99c.02-.16.04-.32.04-.49 0-.17-.01-.33-.04-.49l1.06-.83c.09-.08.12-.21.06-.32l-1-1.73c-.06-.11-.19-.15-.31-.11l-1.24.5c-.26-.2-.54-.37-.85-.49l-.19-1.32c-.01-.12-.12-.21-.24-.21h-2c-.12 0-.23.09-.25.21l-.19 1.32c-.3.13-.59.29-.85.49l-1.24-.5c-.11-.04-.24 0-.31.11l-1 1.73c-.06.11-.04.24.06.32l1.06.83c-.02.16-.03.32-.03.49 0 .17.01.33.03.49l-1.06.83c-.09.08-.12.21-.06.32l1 1.73c.06.11.19.15.31.11l1.24-.5c.26.2.54.37.85.49l.19 1.32c.02.12.12.21.25.21h2c.12 0 .23-.09.25-.21l.19-1.32c.3-.13.59-.29.84-.49l1.25.5c.11.04.24 0 .31-.11l1-1.73c.06-.11.03-.24-.06-.32l-1.07-.83zm-3.71 1.01c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    </g>
  ),
  'perm-device-info': (
    <g>
      <path d="M13 7h-2v2h2v-2zm0 4h-2v6h2v-6zm4-9.99l-10-.01c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-18c0-1.1-.9-1.99-2-1.99zm0 17.99h-10v-14h10v14z" />
    </g>
  ),
  'perm-identity': (
    <g>
      <path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1-2.1-.94-2.1-2.1.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1h-12.2v-1.1c0-.64 3.13-2.1 6.1-2.1m0-10.9c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" />
    </g>
  ),
  'perm-media': (
    <g>
      <path d="M2 6h-2v5h.01l-.01 9c0 1.1.9 2 2 2h18v-2h-18v-14zm20-2h-8l-2-2h-6c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm-15 11l4.5-6 3.5 4.51 2.5-3.01 3.5 4.5h-14z" />
    </g>
  ),
  'perm-phone-msg': (
    <g>
      <path d="M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.58l2.2-2.21c.28-.27.36-.66.25-1.01-.37-1.12-.57-2.32-.57-3.57 0-.55-.45-1-1-1h-3.5c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zm-8-12.5v10l3-3h6v-7h-9z" />
    </g>
  ),
  'perm-scan-wifi': (
    <g>
      <path d="M12 3c-5.05 0-8.85 1.85-12 4.23l12 14.77 12-14.75c-3.15-2.38-6.95-4.25-12-4.25zm1 13h-2v-6h2v6zm-2-8v-2h2v2h-2z" />
    </g>
  ),
  'picture-in-picture': (
    <g>
      <path d="M19 7h-8v6h8v-6zm2-4h-18c-1.1 0-2 .9-2 2v14c0 1.1.9 1.98 2 1.98h18c1.1 0 2-.88 2-1.98v-14c0-1.1-.9-2-2-2zm0 16.01h-18v-14.03h18v14.03z" />
    </g>
  ),
  polymer: (
    <g>
      <path d="M19 4h-4l-7.89 12.63-2.61-4.63 4.5-8h-4l-4.5 8 4.5 8h4l7.89-12.63 2.61 4.63-4.5 8h4l4.5-8z" />
    </g>
  ),
  print: (
    <g>
      <path d="M19 8h-14c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11h-8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9h-12v4h12v-4z" />
    </g>
  ),
  'query-builder': (
    <g>
      <path d="M11.99 2c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10zm.01 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      <path d="M12.5 7h-1.5v6l5.25 3.15.75-1.23-4.5-2.67z" />
    </g>
  ),
  'question-answer': (
    <g>
      <path d="M21 6h-2v9h-13v2c0 .55.45 1 1 1h11l4 4v-15c0-.55-.45-1-1-1zm-4 6v-9c0-.55-.45-1-1-1h-13c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" />
    </g>
  ),
  'radio-button-off': (
    <g>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    </g>
  ),
  'radio-button-on': (
    <g>
      <path d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    </g>
  ),
  receipt: (
    <g>
      <path d="M18 17h-12v-2h12v2zm0-4h-12v-2h12v2zm0-4h-12v-2h12v2zm-15 13l1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5 1.5-1.5 1.5 1.5v-20l-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5-1.5 1.5-1.5-1.5v20z" />
    </g>
  ),
  redeem: (
    <g>
      <path d="M20 6h-2.18c.11-.31.18-.65.18-1 0-1.66-1.34-3-3-3-1.05 0-1.96.54-2.5 1.35l-.5.67-.5-.68c-.54-.8-1.45-1.34-2.5-1.34-1.66 0-3 1.34-3 3 0 .35.07.69.18 1h-2.18c-1.11 0-1.99.89-1.99 2l-.01 11c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-11c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-6 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15h-16v-2h16v2zm0-5h-16v-6h5.08l-2.08 2.83 1.62 1.17 2.38-3.24 1-1.36 1 1.36 2.38 3.24 1.62-1.17-2.08-2.83h5.08v6z" />
    </g>
  ),
  redo: (
    <g>
      <path d="M18.4 10.6c-1.85-1.61-4.25-2.6-6.9-2.6-4.65 0-8.58 3.03-9.96 7.22l2.36.78c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88l-3.62 3.62h9v-9l-3.6 3.6z" />
    </g>
  ),
  refresh: (
    <g>
      <path d="M17.65 6.35c-1.45-1.45-3.44-2.35-5.65-2.35-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78l-3.22 3.22h7v-7l-2.35 2.35z" />
    </g>
  ),
  'remove-circle-outline': (
    <g>
      <path d="M7 11v2h10v-2h-10zm5-9c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    </g>
  ),
  'remove-circle': (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm5 11h-10v-2h10v2z" />
    </g>
  ),
  remove: (
    <g>
      <path d="M19 13h-14v-2h14v2z" />
    </g>
  ),
  reorder: (
    <g>
      <path d="M3,15h18v-2H3V15z M3,19h18v-2H3V19z M3,11h18V9H3V11z M3,5v2h18V5H3z" />
    </g>
  ),
  'reply-all': (
    <g>
      <path d="M7 8v-3l-7 7 7 7v-3l-4-4 4-4zm6 1v-4l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z" />
    </g>
  ),
  reply: (
    <g>
      <path d="M10 9v-4l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z" />
    </g>
  ),
  report: (
    <g>
      <path d="M15.73 3h-7.46l-5.27 5.27v7.46l5.27 5.27h7.46l5.27-5.27v-7.46l-5.27-5.27zm-3.73 14.3c-.72 0-1.3-.58-1.3-1.3 0-.72.58-1.3 1.3-1.3.72 0 1.3.58 1.3 1.3 0 .72-.58 1.3-1.3 1.3zm1-4.3h-2v-6h2v6z" />
    </g>
  ),
  'report-problem': (
    <g>
      <path d="M1 21h22l-11-19-11 19zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
    </g>
  ),
  restore: (
    <g>
      <path d="M13 3c-4.97 0-9 4.03-9 9h-3l3.89 3.89.07.14 4.04-4.03h-3c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42c1.63 1.63 3.87 2.64 6.36 2.64 4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08v-4.25h-1.5z" />
    </g>
  ),
  room: (
    <g>
      <path d="M12 2c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
    </g>
  ),
  'rotation-3d': (
    <g>
      <path d="M7.52 21.48c-3.27-1.54-5.61-4.72-5.97-8.48h-1.5c.51 6.16 5.66 11 11.95 11l.66-.03-3.81-3.81-1.33 1.32zm.89-6.52c-.19 0-.37-.03-.52-.08-.16-.06-.29-.13-.4-.24-.11-.1-.2-.22-.26-.37-.06-.14-.09-.3-.09-.47h-1.3c0 .36.07.68.21.95.14.27.33.5.56.69.24.18.51.32.82.41.3.1.62.15.96.15.37 0 .72-.05 1.03-.15.32-.1.6-.25.83-.44s.42-.43.55-.72c.13-.29.2-.61.2-.97 0-.19-.02-.38-.07-.56-.05-.18-.12-.35-.23-.51-.1-.16-.24-.3-.4-.43-.17-.13-.37-.23-.61-.31.2-.09.37-.2.52-.33.15-.13.27-.27.37-.42.1-.15.17-.3.22-.46.05-.16.07-.32.07-.48 0-.36-.06-.68-.18-.96-.12-.28-.29-.51-.51-.69-.2-.19-.47-.33-.77-.43-.31-.09-.65-.14-1.02-.14-.36 0-.69.05-1 .16-.3.11-.57.26-.79.45-.21.19-.38.41-.51.67-.12.26-.18.54-.18.85h1.3c0-.17.03-.32.09-.45s.14-.25.25-.34c.11-.09.23-.17.38-.22.15-.05.3-.08.48-.08.4 0 .7.1.89.31.19.2.29.49.29.86 0 .18-.03.34-.08.49-.05.15-.14.27-.25.37-.11.1-.25.18-.41.24-.16.06-.36.09-.58.09h-.77v1.03h.77c.22 0 .42.02.6.07s.33.13.45.23c.12.11.22.24.29.4.07.16.1.35.1.57 0 .41-.12.72-.35.93-.23.23-.55.33-.95.33zm8.55-5.92c-.32-.33-.7-.59-1.14-.77-.43-.18-.92-.27-1.46-.27h-2.36v8h2.3c.55 0 1.06-.09 1.51-.27.45-.18.84-.43 1.16-.76.32-.33.57-.73.74-1.19.17-.47.26-.99.26-1.57v-.4c0-.58-.09-1.1-.26-1.57-.18-.47-.43-.87-.75-1.2zm-.39 3.16c0 .42-.05.79-.14 1.13-.1.33-.24.62-.43.85-.19.23-.43.41-.71.53-.29.12-.62.18-.99.18h-.91v-5.77h.97c.72 0 1.27.23 1.64.69.38.46.57 1.12.57 1.99v.4zm-4.57-12.2l-.66.03 3.81 3.81 1.33-1.33c3.27 1.55 5.61 4.72 5.96 8.48h1.5c-.5-6.15-5.65-10.99-11.94-10.99z" />
    </g>
  ),
  save: (
    <g>
      <path d="M17 3h-12c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-12l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10h-10v-4h10v4z" />
    </g>
  ),
  schedule: (
    <g>
      <path fillOpacity=".9" d="M11.99 2c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10zm.01 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      <path fillOpacity=".9" d="M12.5 7h-1.5v6l5.25 3.15.75-1.23-4.5-2.67z" />
    </g>
  ),
  search: (
    <g>
      <path d="M15.5 14h-.79l-.28-.27c.98-1.14 1.57-2.62 1.57-4.23 0-3.59-2.91-6.5-6.5-6.5s-6.5 2.91-6.5 6.5 2.91 6.5 6.5 6.5c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99 1.49-1.49-4.99-5zm-6 0c-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5 4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5z" />
    </g>
  ),
  'select-all': (
    <g>
      <path d="M3 5h2v-2c-1.1 0-2 .9-2 2zm0 8h2v-2h-2v2zm4 8h2v-2h-2v2zm-4-12h2v-2h-2v2zm10-6h-2v2h2v-2zm6 0v2h2c0-1.1-.9-2-2-2zm-14 18v-2h-2c0 1.1.9 2 2 2zm-2-4h2v-2h-2v2zm6-14h-2v2h2v-2zm2 18h2v-2h-2v2zm8-8h2v-2h-2v2zm0 8c1.1 0 2-.9 2-2h-2v2zm0-12h2v-2h-2v2zm0 8h2v-2h-2v2zm-4 4h2v-2h-2v2zm0-16h2v-2h-2v2zm-8 12h10v-10h-10v10zm2-8h6v6h-6v-6z" />
    </g>
  ),
  'send-money': (
    <g>
      <path d="M2 12c0-2.61 1.67-4.83 4-5.65v-2.09c-3.45.89-6 4.01-6 7.74s2.55 6.85 6 7.74v-2.09c-2.33-.82-4-3.04-4-5.65zm22 0l-4-4v3h-7v2h7v3l4-4zm-10 6c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.16.67 4.24 1.76l1.42-1.41c-1.45-1.45-3.45-2.35-5.66-2.35-4.42 0-8 3.58-8 8s3.58 8 8 8c2.21 0 4.21-.9 5.66-2.34l-1.42-1.41c-1.08 1.08-2.58 1.75-4.24 1.75z" />
    </g>
  ),
  send: (
    <g>
      <path d="M2.01 21l20.99-9-20.99-9-.01 7 15 2-15 2z" />
    </g>
  ),
  'settings-applications': (
    <g>
      <path d="M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm7-7h-14c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2v-14c0-1.1-.89-2-2-2zm-1.75 9c0 .23-.02.46-.05.68l1.48 1.16c.13.11.17.3.08.45l-1.4 2.42c-.09.15-.27.21-.43.15l-1.74-.7c-.36.28-.76.51-1.18.69l-.26 1.85c-.03.17-.18.3-.35.3h-2.8c-.17 0-.32-.13-.35-.29l-.26-1.85c-.43-.18-.82-.41-1.18-.69l-1.74.7c-.16.06-.34 0-.43-.15l-1.4-2.42c-.09-.15-.05-.34.08-.45l1.48-1.16c-.03-.23-.05-.46-.05-.69 0-.23.02-.46.05-.68l-1.48-1.16c-.13-.11-.17-.3-.08-.45l1.4-2.42c.09-.15.27-.21.43-.15l1.74.7c.36-.28.76-.51 1.18-.69l.26-1.85c.03-.17.18-.3.35-.3h2.8c.17 0 .32.13.35.29l.26 1.85c.43.18.82.41 1.18.69l1.74-.7c.16-.06.34 0 .43.15l1.4 2.42c.09.15.05.34-.08.45l-1.48 1.16c.03.23.05.46.05.69z" />
    </g>
  ),
  'settings-backup-restore': (
    <g>
      <path d="M14 12c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2zm-2-9c-4.97 0-9 4.03-9 9h-3l4 4 4-4h-3c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.51 0-2.91-.49-4.06-1.3l-1.42 1.44c1.52 1.16 3.42 1.86 5.48 1.86 4.97 0 9-4.03 9-9s-4.03-9-9-9z" />
    </g>
  ),
  'settings-bluetooth': (
    <g>
      <path d="M11 24h2v-2h-2v2zm-4 0h2v-2h-2v2zm8 0h2v-2h-2v2zm2.71-18.29l-5.71-5.71h-1v7.59l-4.59-4.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 4.59-4.59v7.59h1l5.71-5.71-4.3-4.29 4.3-4.29zm-4.71-1.88l1.88 1.88-1.88 1.88v-3.76zm1.88 10.46l-1.88 1.88v-3.76l1.88 1.88z" />
    </g>
  ),
  'settings-cell': (
    <g>
      <path d="M7 24h2v-2h-2v2zm4 0h2v-2h-2v2zm4 0h2v-2h-2v2zm1-23.99l-8-.01c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-16c0-1.1-.9-1.99-2-1.99zm0 15.99h-8v-12h8v12z" />
    </g>
  ),
  'settings-display': (
    <g>
      <path d="M21 3h-18c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16.01h-18v-14.02h18v14.02zm-13-3.01h2.5l1.5 1.5 1.5-1.5h2.5v-2.5l1.5-1.5-1.5-1.5v-2.5h-2.5l-1.5-1.5-1.5 1.5h-2.5v2.5l-1.5 1.5 1.5 1.5v2.5zm4-7c1.66 0 3 1.34 3 3s-1.34 3-3 3v-6z" />
    </g>
  ),
  'settings-ethernet': (
    <g>
      <path d="M7.77 6.76l-1.54-1.28-5.41 6.52 5.41 6.52 1.54-1.28-4.35-5.24 4.35-5.24zm-.77 6.24h2v-2h-2v2zm10-2h-2v2h2v-2zm-6 2h2v-2h-2v2zm6.77-7.52l-1.54 1.28 4.35 5.24-4.35 5.24 1.54 1.28 5.41-6.52-5.41-6.52z" />
    </g>
  ),
  'settings-input-antenna': (
    <g>
      <path d="M12 5c-3.87 0-7 3.13-7 7h2c0-2.76 2.24-5 5-5s5 2.24 5 5h2c0-3.87-3.13-7-7-7zm1 9.29c.88-.39 1.5-1.26 1.5-2.29 0-1.38-1.12-2.5-2.5-2.5s-2.5 1.12-2.5 2.5c0 1.02.62 1.9 1.5 2.29v3.3l-3.41 3.41 1.41 1.41 3-3 3 3 1.41-1.41-3.41-3.41v-3.3zm-1-13.29c-6.07 0-11 4.93-11 11h2c0-4.97 4.03-9 9-9s9 4.03 9 9h2c0-6.07-4.93-11-11-11z" />
    </g>
  ),
  'settings-input-component': (
    <g>
      <path d="M5 2c0-.55-.45-1-1-1s-1 .45-1 1v4h-2v6h6v-6h-2v-4zm4 14c0 1.3.84 2.4 2 2.82v4.18h2v-4.18c1.16-.41 2-1.51 2-2.82v-2h-6v2zm-8 0c0 1.3.84 2.4 2 2.82v4.18h2v-4.18c1.16-.42 2-1.52 2-2.82v-2h-6v2zm20-10v-4c0-.55-.45-1-1-1s-1 .45-1 1v4h-2v6h6v-6h-2zm-8-4c0-.55-.45-1-1-1s-1 .45-1 1v4h-2v6h6v-6h-2v-4zm4 14c0 1.3.84 2.4 2 2.82v4.18h2v-4.18c1.16-.41 2-1.51 2-2.82v-2h-6v2z" />
    </g>
  ),
  'settings-input-composite': (
    <g>
      <path d="M5 2c0-.55-.45-1-1-1s-1 .45-1 1v4h-2v6h6v-6h-2v-4zm4 14c0 1.3.84 2.4 2 2.82v4.18h2v-4.18c1.16-.41 2-1.51 2-2.82v-2h-6v2zm-8 0c0 1.3.84 2.4 2 2.82v4.18h2v-4.18c1.16-.42 2-1.52 2-2.82v-2h-6v2zm20-10v-4c0-.55-.45-1-1-1s-1 .45-1 1v4h-2v6h6v-6h-2zm-8-4c0-.55-.45-1-1-1s-1 .45-1 1v4h-2v6h6v-6h-2v-4zm4 14c0 1.3.84 2.4 2 2.82v4.18h2v-4.18c1.16-.41 2-1.51 2-2.82v-2h-6v2z" />
    </g>
  ),
  'settings-input-hdmi': (
    <g>
      <path d="M18 7v-3c0-1.1-.9-2-2-2h-8c-1.1 0-2 .9-2 2v3h-1v6l3 6v3h8v-3l3-6v-6h-1zm-10-3h8v3h-2v-2h-1v2h-2v-2h-1v2h-2v-3z" />
    </g>
  ),
  'settings-input-svideo': (
    <g>
      <path d="M8 11.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5.67 1.5 1.5 1.5 1.5-.67 1.5-1.5zm7-5c0-.83-.67-1.5-1.5-1.5h-3c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h3c.83 0 1.5-.67 1.5-1.5zm-6.5 8.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm3.5-14c-6.07 0-11 4.93-11 11s4.93 11 11 11 11-4.93 11-11-4.93-11-11-11zm0 20c-4.96 0-9-4.04-9-9s4.04-9 9-9 9 4.04 9 9-4.04 9-9 9zm5.5-11c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm-2 5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z" />
    </g>
  ),
  'settings-overscan': (
    <g>
      <path d="M12.01 5.5l-2.01 2.5h4l-1.99-2.5zm5.99 4.5v4l2.5-1.99-2.5-2.01zm-12 0l-2.5 2.01 2.5 1.99v-4zm8 6h-4l2.01 2.5 1.99-2.5zm7-13h-18c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16.01h-18v-14.02h18v14.02z" />
    </g>
  ),
  settings: (
    <g>
      <path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65c-.03-.24-.24-.42-.49-.42h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zm-7.43 2.52c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z" />
    </g>
  ),
  'settings-phone': (
    <g>
      <path d="M13 9h-2v2h2v-2zm4 0h-2v2h2v-2zm3 6.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.58l2.2-2.21c.28-.27.36-.66.25-1.01-.37-1.12-.57-2.32-.57-3.57 0-.55-.45-1-1-1h-3.5c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zm-1-6.5v2h2v-2h-2z" />
    </g>
  ),
  'settings-power': (
    <g>
      <path d="M7 24h2v-2h-2v2zm4 0h2v-2h-2v2zm2-22h-2v10h2v-10zm3.56 2.44l-1.45 1.45c1.73 1.05 2.89 2.94 2.89 5.11 0 3.31-2.69 6-6 6s-6-2.69-6-6c0-2.17 1.16-4.06 2.88-5.12l-1.44-1.44c-2.08 1.44-3.44 3.84-3.44 6.56 0 4.42 3.58 8 8 8s8-3.58 8-8c0-2.72-1.36-5.12-3.44-6.56zm-1.56 19.56h2v-2h-2v2z" />
    </g>
  ),
  'settings-remote': (
    <g>
      <path d="M15 9h-6c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-12c0-.55-.45-1-1-1zm-3 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm-4.95-8.95l1.41 1.41c.91-.9 2.16-1.46 3.54-1.46s2.63.56 3.54 1.46l1.41-1.41c-1.27-1.27-3.02-2.05-4.95-2.05s-3.68.78-4.95 2.05zm4.95-6.05c-3.04 0-5.79 1.23-7.78 3.22l1.41 1.41c1.63-1.62 3.88-2.63 6.37-2.63s4.74 1.01 6.36 2.64l1.41-1.41c-1.98-2-4.73-3.23-7.77-3.23z" />
    </g>
  ),
  'settings-voice': (
    <g>
      <path d="M7 24h2v-2h-2v2zm5-11c1.66 0 2.99-1.34 2.99-3l.01-6c0-1.66-1.34-3-3-3s-3 1.34-3 3v6c0 1.66 1.34 3 3 3zm-1 11h2v-2h-2v2zm4 0h2v-2h-2v2zm4-14h-1.7c0 3-2.54 5.1-5.3 5.1s-5.3-2.1-5.3-5.1h-1.7c0 3.41 2.72 6.23 6 6.72v3.28h2v-3.28c3.28-.49 6-3.31 6-6.72z" />
    </g>
  ),
  shop: (
    <g>
      <path d="M16 6v-2c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2h-6v13c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-13h-6zm-6-2h4v2h-4v-2zm-1 14v-9l7.5 4-7.5 5z" />
    </g>
  ),
  'shopping-basket': (
    <g>
      <path d="M17.21 9l-4.38-6.56c-.19-.28-.51-.42-.83-.42-.32 0-.64.14-.83.43l-4.38 6.55h-4.79c-.55 0-1 .45-1 1 0 .09.01.18.04.27l2.54 9.27c.23.84 1 1.46 1.92 1.46h13c.92 0 1.69-.62 1.93-1.46l2.54-9.27.03-.27c0-.55-.45-1-1-1h-4.79zm-8.21 0l3-4.4 3 4.4h-6zm3 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
    </g>
  ),
  'shopping-cart': (
    <g>
      <path d="M7 18c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm-6-16v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2h-11.58c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1h-14.79l-.94-2h-3.27zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
    </g>
  ),
  'shop-two': (
    <g>
      <path d="M3 9h-2v11c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2h-16v-11zm15-4v-2c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2h-5v11c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-11h-5zm-6-2h4v2h-4v-2zm0 12v-7l5.5 3-5.5 4z" />
    </g>
  ),
  sort: (
    <g>
      <path d="M3 18h6v-2h-6v2zm0-12v2h18v-2h-18zm0 7h12v-2h-12v2z" />
    </g>
  ),
  'speaker-notes': (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 18 4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-12 12h-2v-2h2v2zm0-3h-2v-2h2v2zm0-3h-2v-2h2v2zm7 6h-5v-2h5v2zm3-3h-8v-2h8v2zm0-3h-8v-2h8v2z" />
    </g>
  ),
  spellcheck: (
    <g>
      <path d="M12.45 16h2.09l-5.11-13h-1.86l-5.11 13h2.09l1.12-3h5.64l1.14 3zm-6.02-5l2.07-5.52 2.07 5.52h-4.14zm15.16.59l-8.09 8.09-3.67-3.68-1.41 1.41 5.09 5.09 9.49-9.5-1.41-1.41z" />
    </g>
  ),
  'star-half': (
    <g>
      <path d="M22 9.74l-7.19-.62L12 2.5 9.19 9.13 2 9.74l5.46 4.73-1.64 7.03L12 17.77l6.18 3.73-1.63-7.03L22 9.74zM12 15.9V6.6l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.9z" />
    </g>
  ),
  'star-outline': (
    <g>
      <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z" />
    </g>
  ),
  star: (
    <g>
      <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
    </g>
  ),
  'star-rate': (
    <g>
      <path d="M12 14.3l3.71 2.7-1.42-4.36 3.71-2.64h-4.55l-1.45-4.5-1.45 4.5h-4.55l3.71 2.64-1.42 4.36z" />
    </g>
  ),
  stars: (
    <g>
      <path d="M11.99 2c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10zm4.24 16l-4.23-2.55-4.23 2.55 1.12-4.81-3.73-3.23 4.92-.42 1.92-4.54 1.92 4.53 4.92.42-3.73 3.23 1.12 4.82z" />
    </g>
  ),
  store: (
    <g>
      <path d="M20 4h-16v2h16v-2zm1 10v-2l-1-5h-16l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4h-6v-4h6v4z" />
    </g>
  ),
  subject: (
    <g>
      <path d="M14 17h-10v2h10v-2zm6-8h-16v2h16v-2zm-16 6h16v-2h-16v2zm0-10v2h16v-2h-16z" />
    </g>
  ),
  'supervisor-account': (
    <g>
      <circle cx="12" cy="13.49" r="1.5" />
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 2.5c1.24 0 2.25 1.01 2.25 2.25s-1.01 2.25-2.25 2.25-2.25-1.01-2.25-2.25 1.01-2.25 2.25-2.25zm5 10.56v2.5c-.45.41-.96.77-1.5 1.05v-.68c0-.34-.17-.65-.46-.92-.65-.62-1.89-1.02-3.04-1.02-.96 0-1.96.28-2.65.73l-.17.12-.21.17c.78.47 1.63.72 2.54.82l1.33.15c.37.04.66.36.66.75 0 .29-.16.53-.4.66-.28.15-.64.09-.95.09-.35 0-.69-.01-1.03-.05-.5-.06-.99-.17-1.46-.33-.49-.16-.97-.38-1.42-.64-.22-.13-.44-.27-.65-.43l-.31-.24c-.04-.02-.28-.18-.28-.23v-4.28c0-1.58 2.63-2.78 5-2.78s5 1.2 5 2.78v1.78z" />
    </g>
  ),
  'swap-horiz': (
    <g>
      <path d="M6.99 11l-3.99 4 3.99 4v-3h7.01v-2h-7.01v-3zm14.01-2l-3.99-4v3h-7.01v2h7.01v3l3.99-4z" />
    </g>
  ),
  'swap-vert-circle': (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm-5.5 7l3.5-3.5 3.5 3.5h-2.5v4h-2v-4h-2.5zm11 6l-3.5 3.5-3.5-3.5h2.5v-4h2v4h2.5z" />
    </g>
  ),
  'swap-vert': (
    <g>
      <path d="M16 17.01v-7.01h-2v7.01h-3l4 3.99 4-3.99h-3zm-7-14.01l-4 3.99h3v7.01h2v-7.01h3l-4-3.99z" />
    </g>
  ),
  'system-update-tv': (
    <g>
      <path d="M12 16.5l4-4h-3v-9h-2v9h-3l4 4zm9-13h-6v1.99h6v14.03h-18v-14.03h6v-1.99h-6c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2z" />
    </g>
  ),
  tab: (
    <g>
      <path d="M21 3h-18c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-18v-14h10v4h8v10z" />
    </g>
  ),
  'tab-unselected': (
    <g>
      <path d="M1 9h2v-2h-2v2zm0 4h2v-2h-2v2zm0-8h2v-2c-1.1 0-2 .9-2 2zm8 16h2v-2h-2v2zm-8-4h2v-2h-2v2zm2 4v-2h-2c0 1.1.9 2 2 2zm18-18h-8v6h10v-4c0-1.1-.9-2-2-2zm0 14h2v-2h-2v2zm-12-12h2v-2h-2v2zm-4 16h2v-2h-2v2zm0-16h2v-2h-2v2zm16 16c1.1 0 2-.9 2-2h-2v2zm0-8h2v-2h-2v2zm-8 8h2v-2h-2v2zm4 0h2v-2h-2v2z" />
    </g>
  ),
  'text-format': (
    <g>
      <path d="M5 17v2h14v-2h-14zm4.5-4.2h5l.9 2.2h2.1l-4.75-11h-1.5l-4.75 11h2.1l.9-2.2zm2.5-6.82l1.87 5.02h-3.74l1.87-5.02z" />
    </g>
  ),
  theaters: (
    <g>
      <path d="M18 3v2h-2v-2h-8v2h-2v-2h-2v18h2v-2h2v2h8v-2h2v2h2v-18h-2zm-10 14h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm10 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2z" />
    </g>
  ),
  'thumb-down': (
    <g>
      <path d="M15 3h-9c-.83 0-1.54.5-1.84 1.22l-3.02 7.05c-.09.23-.14.47-.14.73v1.91l.01.01-.01.08c0 1.1.9 2 2 2h6.31l-.95 4.57-.03.32c0 .41.17.79.44 1.06l1.06 1.05 6.59-6.59c.36-.36.58-.86.58-1.41v-10c0-1.1-.9-2-2-2zm4 0v12h4v-12h-4z" />
    </g>
  ),
  'thumbs-up-down': (
    <g>
      <path d="M12 6c0-.55-.45-1-1-1h-5.18l.66-3.18.02-.23c0-.31-.13-.59-.33-.8l-.79-.79-4.94 4.94c-.27.27-.44.65-.44 1.06v6.5c0 .83.67 1.5 1.5 1.5h6.75c.62 0 1.15-.38 1.38-.91l2.26-5.29c.07-.17.11-.36.11-.55v-1.25zm10.5 4h-6.75c-.62 0-1.15.38-1.38.91l-2.26 5.29c-.07.17-.11.36-.11.55v1.25c0 .55.45 1 1 1h5.18l-.66 3.18-.02.24c0 .31.13.59.33.8l.79.78 4.94-4.94c.27-.27.44-.65.44-1.06v-6.5c0-.83-.67-1.5-1.5-1.5z" />
    </g>
  ),
  'thumb-up': (
    <g>
      <path d="M1 21h4v-12h-4v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06l-1.06-1.05-6.58 6.59c-.37.36-.59.86-.59 1.41v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01.01-.08z" />
    </g>
  ),
  toc: (
    <g>
      <path d="M3 9h14v-2h-14v2zm0 4h14v-2h-14v2zm0 4h14v-2h-14v2zm16 0h2v-2h-2v2zm0-10v2h2v-2h-2zm0 6h2v-2h-2v2z" />
    </g>
  ),
  today: (
    <g>
      <path d="M19 3h-1v-2h-2v2h-8v-2h-2v2h-1c-1.11 0-1.99.9-1.99 2l-.01 14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-11h14v11zm-12-9h5v5h-5z" />
    </g>
  ),
  'track-changes': (
    <g>
      <path d="M19.07 4.93l-1.41 1.41c1.44 1.45 2.34 3.45 2.34 5.66 0 4.42-3.58 8-8 8s-8-3.58-8-8c0-4.08 3.05-7.44 7-7.93v2.02c-2.84.48-5 2.94-5 5.91 0 3.31 2.69 6 6 6s6-2.69 6-6c0-1.66-.67-3.16-1.76-4.24l-1.41 1.41c.72.73 1.17 1.73 1.17 2.83 0 2.21-1.79 4-4 4s-4-1.79-4-4c0-1.86 1.28-3.41 3-3.86v2.14c-.6.35-1 .98-1 1.72 0 1.1.9 2 2 2s2-.9 2-2c0-.74-.4-1.38-1-1.72v-8.28h-1c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10c0-2.76-1.12-5.26-2.93-7.07z" />
    </g>
  ),
  translate: (
    <g>
      <path d="M12.87 15.07l-2.54-2.51.03-.03c1.74-1.94 2.98-4.17 3.71-6.53h2.93v-2h-7v-2h-2v2h-7v1.99h11.17c-.67 1.93-1.73 3.76-3.17 5.36-.93-1.03-1.7-2.16-2.31-3.35h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02 1.42 1.42 5-5 3.11 3.11.76-2.04zm5.63-5.07h-2l-4.5 12h2l1.12-3h4.75l1.13 3h2l-4.5-12zm-2.62 7l1.62-4.33 1.62 4.33h-3.24z" />
    </g>
  ),
  'trending-down': (
    <g>
      <path d="M16 18l2.29-2.29-4.88-4.88-4 4-7.41-7.42 1.41-1.41 6 6 4-4 6.3 6.29 2.29-2.29v6z" />
    </g>
  ),
  'trending-neutral': (
    <g>
      <path d="M22 12l-4-4v3h-15v2h15v3z" />
    </g>
  ),
  'trending-up': (
    <g>
      <path d="M16 6l2.29 2.29-4.88 4.88-4-4-7.41 7.42 1.41 1.41 6-6 4 4 6.3-6.29 2.29 2.29v-6z" />
    </g>
  ),
  'turned-in-not': (
    <g>
      <path d="M17 3h-10c-1.1 0-1.99.9-1.99 2l-.01 16 7-3 7 3v-16c0-1.1-.9-2-2-2zm0 15l-5-2.18-5 2.18v-13h10v13z" />
    </g>
  ),
  'turned-in': (
    <g>
      <path d="M17 3h-10c-1.1 0-1.99.9-1.99 2l-.01 16 7-3 7 3v-16c0-1.1-.9-2-2-2z" />
    </g>
  ),
  undo: (
    <g>
      <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6l-3.6-3.6v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78c-1.39-4.19-5.32-7.22-9.97-7.22z" />
    </g>
  ),
  'unfold-less': (
    <g>
      <path d="M7.41 18.59l1.42 1.41 3.17-3.17 3.17 3.17 1.41-1.41-4.58-4.59-4.59 4.59zm9.18-13.18l-1.42-1.41-3.17 3.17-3.17-3.17-1.42 1.41 4.59 4.59 4.59-4.59z" />
    </g>
  ),
  'unfold-more': (
    <g>
      <path d="M12 5.83l3.17 3.17 1.41-1.41-4.58-4.59-4.59 4.59 1.42 1.41 3.17-3.17zm0 12.34l-3.17-3.17-1.41 1.41 4.58 4.59 4.59-4.59-1.42-1.41-3.17 3.17z" />
    </g>
  ),
  'verified-user': (
    <g>
      <path d="M12 1l-9 4v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12v-6l-9-4zm-2 16l-4-4 1.41-1.41 2.59 2.58 6.59-6.59 1.41 1.42-8 8z" />
    </g>
  ),
  'view-agenda': (
    <g>
      <path d="M20 13h-17c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h17c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1zm0-10h-17c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h17c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1z" />
    </g>
  ),
  'view-array': (
    <g>
      <path d="M4 18h3v-13h-3v13zm14-13v13h3v-13h-3zm-10 13h9v-13h-9v13z" />
    </g>
  ),
  'view-carousel': (
    <g>
      <path d="M7 19h10v-15h-10v15zm-5-2h4v-11h-4v11zm16-11v11h4v-11h-4z" />
    </g>
  ),
  'view-column': (
    <g>
      <path d="M10 18h5v-13h-5v13zm-6 0h5v-13h-5v13zm12-13v13h5v-13h-5z" />
    </g>
  ),
  'view-day': (
    <g>
      <path d="M2 21h19v-3h-19v3zm18-13h-17c-.55 0-1 .45-1 1v6c0 .55.45 1 1 1h17c.55 0 1-.45 1-1v-6c0-.55-.45-1-1-1zm-18-5v3h19v-3h-19z" />
    </g>
  ),
  'view-headline': (
    <g>
      <path d="M4 15h17v-2h-17v2zm0 4h17v-2h-17v2zm0-8h17v-2h-17v2zm0-6v2h17v-2h-17z" />
    </g>
  ),
  'view-list': (
    <g>
      <path d="M4 14h4v-4h-4v4zm0 5h4v-4h-4v4zm0-10h4v-4h-4v4zm5 5h12v-4h-12v4zm0 5h12v-4h-12v4zm0-14v4h12v-4h-12z" />
    </g>
  ),
  'view-module': (
    <g>
      <path d="M4 11h5v-6h-5v6zm0 7h5v-6h-5v6zm6 0h5v-6h-5v6zm6 0h5v-6h-5v6zm-6-7h5v-6h-5v6zm6-6v6h5v-6h-5z" />
    </g>
  ),
  'view-quilt': (
    <g>
      <path d="M10 18h5v-6h-5v6zm-6 0h5v-13h-5v13zm12 0h5v-6h-5v6zm-6-13v6h11v-6h-11z" />
    </g>
  ),
  'view-stream': (
    <g>
      <path d="M4 18h17v-6h-17v6zm0-13v6h17v-6h-17z" />
    </g>
  ),
  'view-week': (
    <g>
      <path d="M6 5h-3c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-12c0-.55-.45-1-1-1zm14 0h-3c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-12c0-.55-.45-1-1-1zm-7 0h-3c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h3c.55 0 1-.45 1-1v-12c0-.55-.45-1-1-1z" />
    </g>
  ),
  'visibility-off': (
    <g>
      <path d="M12 7c2.76 0 5 2.24 5 5 0 .65-.13 1.26-.36 1.83l2.92 2.92c1.51-1.26 2.7-2.89 3.43-4.75-1.73-4.39-6-7.5-11-7.5-1.4 0-2.74.25-3.98.7l2.16 2.16c.57-.23 1.18-.36 1.83-.36zm-10-2.73l2.28 2.28.46.46c-1.66 1.29-2.96 3.01-3.74 4.99 1.73 4.39 6 7.5 11 7.5 1.55 0 3.03-.3 4.38-.84l.42.42 2.93 2.92 1.27-1.27-17.73-17.73-1.27 1.27zm5.53 5.53l1.55 1.55c-.05.21-.08.43-.08.65 0 1.66 1.34 3 3 3 .22 0 .44-.03.65-.08l1.55 1.55c-.67.33-1.41.53-2.2.53-2.76 0-5-2.24-5-5 0-.79.2-1.53.53-2.2zm4.31-.78l3.15 3.15.02-.16c0-1.66-1.34-3-3-3l-.17.01z" />
    </g>
  ),
  visibility: (
    <g>
      <path d="M12 4.5c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
    </g>
  ),
  'wallet-giftcard': (
    <g>
      <path d="M20 6h-2.18c.11-.31.18-.65.18-1 0-1.66-1.34-3-3-3-1.05 0-1.96.54-2.5 1.35l-.5.67-.5-.68c-.54-.8-1.45-1.34-2.5-1.34-1.66 0-3 1.34-3 3 0 .35.07.69.18 1h-2.18c-1.11 0-1.99.89-1.99 2l-.01 11c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-11c0-1.11-.89-2-2-2zm-5-2c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-6 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm11 15h-16v-2h16v2zm0-5h-16v-6h5.08l-2.08 2.83 1.62 1.17 2.38-3.24 1-1.36 1 1.36 2.38 3.24 1.62-1.17-2.08-2.83h5.08v6z" />
    </g>
  ),
  'wallet-membership': (
    <g>
      <path d="M20 2h-16c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h4v5l4-2 4 2v-5h4c1.11 0 2-.89 2-2v-11c0-1.11-.89-2-2-2zm0 13h-16v-2h16v2zm0-5h-16v-6h16v6z" />
    </g>
  ),
  'wallet-travel': (
    <g>
      <path d="M20 6h-3v-2c0-1.11-.89-2-2-2h-6c-1.11 0-2 .89-2 2v2h-3c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-11c0-1.11-.89-2-2-2zm-11-2h6v2h-6v-2zm11 15h-16v-2h16v2zm0-5h-16v-6h3v2h2v-2h6v2h2v-2h3v6z" />
    </g>
  ),
  warning: (
    <g>
      <path d="M1 21h22l-11-19-11 19zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
    </g>
  ),
  work: (
    <g>
      <path d="M24 0v24h-24v-24h24m1-1h-26v26h26v-26z" />
      <path d="M20 6h-4v-2c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2h-4c-1.11 0-1.99.89-1.99 2l-.01 11c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-11c0-1.11-.89-2-2-2zm-6 0h-4v-2h4v2z" />
    </g>
  ),
  album: (
    <g>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 14.5c-2.49 0-4.5-2.01-4.5-4.5S9.51 7.5 12 7.5s4.5 2.01 4.5 4.5-2.01 4.5-4.5 4.5zm0-5.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z" />
    </g>
  ),
  artist: (
    <g>
      <path d="M11 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm7.5-2v5.22c-.31-.14-.64-.22-1-.22-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5 2.5-1.12 2.5-2.5V12h2v-2h-3.5zM11 14c-2.67 0-8 1.34-8 4v2h10.76c-.48-.72-.76-1.58-.76-2.5 0-1.18.46-2.26 1.21-3.06-1.17-.29-2.33-.44-3.21-.44z" />
    </g>
  ),
  'av-timer': (
    <g>
      <path d="M11 17c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1zm0-14v4h2V5.08c3.39.49 6 3.39 6 6.92 0 3.87-3.13 7-7 7s-7-3.13-7-7c0-1.68.59-3.22 1.58-4.42L12 13l1.41-1.41-6.8-6.8v.02C4.42 6.45 3 9.05 3 12c0 4.97 4.02 9 9 9 4.97 0 9-4.03 9-9s-4.03-9-9-9h-1zm7 9c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zM6 12c0 .55.45 1 1 1s1-.45 1-1-.45-1-1-1-1 .45-1 1z" />
    </g>
  ),
  'closed-caption': (
    <g>
      <path d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 7H9.5v-.5h-2v3h2V13H11v1c0 .55-.45 1-1 1H7c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v1zm7 0h-1.5v-.5h-2v3h2V13H18v1c0 .55-.45 1-1 1h-3c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v1z" />
    </g>
  ),
  equalizer: (
    <g>
      <path d="M10 20h4V4h-4v16zm-6 0h4v-8H4v8zM16 9v11h4V9h-4z" />
    </g>
  ),
  explicit: (
    <g>
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-4 6h-4v2h4v2h-4v2h4v2H9V7h6v2z" />
    </g>
  ),
  'fast-forward': (
    <g>
      <path d="M4 18l8.5-6L4 6v12zm9-12v12l8.5-6L13 6z" />
    </g>
  ),
  'fast-rewind': (
    <g>
      <path d="M11 18V6l-8.5 6 8.5 6zm.5-6l8.5 6V6l-8.5 6z" />
    </g>
  ),
  games: (
    <g>
      <path d="M15 7.5V2H9v5.5l3 3 3-3zM7.5 9H2v6h5.5l3-3-3-3zM9 16.5V22h6v-5.5l-3-3-3 3zM16.5 9l-3 3 3 3H22V9h-5.5z" />
    </g>
  ),
  genres: (
    <g>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 6h-3v7c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3c.55 0 1.06.16 1.5.42V6H16v2z" />
    </g>
  ),
  hearing: (
    <g>
      <path d="M17 20c-.29 0-.56-.06-.76-.15-.71-.37-1.21-.88-1.71-2.38-.51-1.56-1.47-2.29-2.39-3-.79-.61-1.61-1.24-2.32-2.53C9.29 10.98 9 9.93 9 9c0-2.8 2.2-5 5-5s5 2.2 5 5h2c0-3.93-3.07-7-7-7S7 5.07 7 9c0 1.26.38 2.65 1.07 3.9.91 1.65 1.98 2.48 2.85 3.15.81.62 1.39 1.07 1.71 2.05.6 1.82 1.37 2.84 2.73 3.55.51.23 1.07.35 1.64.35 2.21 0 4-1.79 4-4h-2c0 1.1-.9 2-2 2zM7.64 2.64L6.22 1.22C4.23 3.21 3 5.96 3 9s1.23 5.79 3.22 7.78l1.41-1.41C6.01 13.74 5 11.49 5 9s1.01-4.74 2.64-6.36zM11.5 9c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5-1.12-2.5-2.5-2.5-2.5 1.12-2.5 2.5z" />
    </g>
  ),
  'high-quality': (
    <g>
      <path d="M19 4H5c-1.11 0-2 .9-2 2v12c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-8 11H9.5v-2h-2v2H6V9h1.5v2.5h2V9H11v6zm7-1c0 .55-.45 1-1 1h-.75v1.5h-1.5V15H14c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h3c.55 0 1 .45 1 1v4zm-3.5-.5h2v-3h-2v3z" />
    </g>
  ),
  ifl: (
    <g>
      <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM8 9.5c-.83 0-1.5-.67-1.5-1.5S7.17 6.5 8 6.5s1.5.67 1.5 1.5S8.83 9.5 8 9.5zm4 4c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4 4c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    </g>
  ),
  'instant-mix': (
    <g>
      <path d="M7 4H5v5h2V4zm12 0h-2v9h2V4zM3 13h2v7h2v-7h2v-2H3v2zm12-6h-2V4h-2v3H9v2h6V7zm-4 13h2v-9h-2v9zm4-5v2h2v3h2v-3h2v-2h-6z" />
    </g>
  ),
  ios: (
    <g>
      <path d="M4 9h2V7H4v2zm0 8h2v-6H4v6zm7-10H9c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h2c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm0 8H9V9h2v6zm9-6V7h-4c-1.1 0-2 .9-2 2v2c0 1.1.9 2 2 2h2v2h-4v2h4c1.1 0 2-.9 2-2v-2c0-1.1-.9-2-2-2h-2V9h4z" />
    </g>
  ),
  loop: (
    <g>
      <path d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46C19.54 15.03 20 13.57 20 12c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8L5.24 7.74C4.46 8.97 4 10.43 4 12c0 4.42 3.58 8 8 8v3l4-4-4-4v3z" />
    </g>
  ),
  mic: (
    <g>
      <path d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z" />
    </g>
  ),
  'mic-none': (
    <g>
      <path d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm-1.2-9.1c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2l-.01 6.2c0 .66-.53 1.2-1.19 1.2-.66 0-1.2-.54-1.2-1.2V4.9zm6.5 6.1c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z" />
    </g>
  ),
  'mic-off': (
    <g>
      <path d="M19 11h-1.7c0 .74-.16 1.43-.43 2.05l1.23 1.23c.56-.98.9-2.09.9-3.28zm-4.02.17c0-.06.02-.11.02-.17V5c0-1.66-1.34-3-3-3S9 3.34 9 5v.18l5.98 5.99zM4.27 3L3 4.27l6.01 6.01V11c0 1.66 1.33 3 2.99 3 .22 0 .44-.03.65-.08l1.66 1.66c-.71.33-1.5.52-2.31.52-2.76 0-5.3-2.1-5.3-5.1H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c.91-.13 1.77-.45 2.54-.9L19.73 21 21 19.73 4.27 3z" />
    </g>
  ),
  movie: (
    <g>
      <path d="M18 4l2 4h-3l-2-4h-2l2 4h-3l-2-4H8l2 4H7L5 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4h-4z" />
    </g>
  ),
  'movie-info': (
    <g>
      <path d="M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM5 19H3v-2h2v2zm0-4H3v-2h2v2zm0-4H3V9h2v2zm0-4H3V5h2v2zm8 10h-2v-6h2v6zm0-8h-2V7h2v2zm8 10h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2z" />
    </g>
  ),
  'my-library-add': (
    <g>
      <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z" />
    </g>
  ),
  'my-library-books': (
    <g>
      <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9H9V9h10v2zm-4 4H9v-2h6v2zm4-8H9V5h10v2z" />
    </g>
  ),
  'my-library-music': (
    <g>
      <path d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-2 5h-3v5.5c0 1.38-1.12 2.5-2.5 2.5S10 13.88 10 12.5s1.12-2.5 2.5-2.5c.57 0 1.08.19 1.5.51V5h4v2zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6z" />
    </g>
  ),
  'new-releases': (
    <g>
      <path d="M23 12l-2.44-2.78.34-3.68-3.61-.82-1.89-3.18L12 3 8.6 1.54 6.71 4.72l-3.61.81.34 3.68L1 12l2.44 2.78-.34 3.69 3.61.82 1.89 3.18L12 21l3.4 1.46 1.89-3.18 3.61-.82-.34-3.68L23 12zm-10 5h-2v-2h2v2zm0-4h-2V7h2v6z" />
    </g>
  ),
  news: (
    <g>
      <path d="M20.33 4.67L18.67 3 17 4.67 15.33 3l-1.67 1.67L12 3l-1.67 1.67L8.67 3 7 4.67 5.33 3 3.67 4.67 2 3v16c0 1.1.9 2 2 2h16c1.1 0 1.99-.9 1.99-2L22 3l-1.67 1.67zM12 19H4v-7h8v7zm8 0h-7v-1h7v1zm0-2h-7v-1h7v1zm0-2h-7v-1h7v1zm0-2h-7v-1h7v1zm0-2H4V8h16v3z" />
    </g>
  ),
  'not-interested': (
    <g>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z" />
    </g>
  ),
  pause: (
    <g>
      <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
    </g>
  ),
  'pause-circle-fill': (
    <g>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 14H9V8h2v8zm4 0h-2V8h2v8z" />
    </g>
  ),
  'pause-circle-outline': (
    <g>
      <path d="M9 16h2V8H9v8zm3-14C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm1-4h2V8h-2v8z" />
    </g>
  ),
  'play-arrow': (
    <g>
      <path d="M8 5v14l11-7z" />
    </g>
  ),
  'play-circle-fill': (
    <g>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z" />
    </g>
  ),
  'play-circle-outline': (
    <g>
      <path d="M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    </g>
  ),
  'playlist-add': (
    <g>
      <path d="M14 10H2v2h12v-2zm0-4H2v2h12V6zm4 8v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zM2 16h8v-2H2v2z" />
    </g>
  ),
  'play-shopping-bag': (
    <g>
      <path d="M16 6V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H2v13c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6h-6zm-6-2h4v2h-4V4zM9 18V9l7.5 4L9 18z" />
    </g>
  ),
  queue: (
    <g>
      <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z" />
    </g>
  ),
  'queue-music': (
    <g>
      <path d="M15 6H3v2h12V6zm0 4H3v2h12v-2zM3 16h8v-2H3v2zM17 6v8.18c-.31-.11-.65-.18-1-.18-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3V8h3V6h-5z" />
    </g>
  ),
  radio: (
    <g>
      <path d="M3.24 6.15C2.51 6.43 2 7.17 2 8v12c0 1.1.89 2 2 2h16c1.11 0 2-.9 2-2V8c0-1.11-.89-2-2-2H8.3l8.26-3.34L15.88 1 3.24 6.15zM7 20c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm13-8h-2v-2h-2v2H4V8h16v4z" />
    </g>
  ),
  'recent-actors': (
    <g>
      <path d="M21 5v14h2V5h-2zm-4 14h2V5h-2v14zM14 5H2c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1zM8 7.75c1.24 0 2.25 1.01 2.25 2.25S9.24 12.25 8 12.25 5.75 11.24 5.75 10 6.76 7.75 8 7.75zM12.5 17h-9v-.75c0-1.5 3-2.25 4.5-2.25s4.5.75 4.5 2.25V17z" />
    </g>
  ),
  repeat: (
    <g>
      <path d="M7 7h10v3l4-4-4-4v3H5v6h2V7zm10 10H7v-3l-4 4 4 4v-3h12v-6h-2v4z" />
    </g>
  ),
  'repeat-one': (
    <g>
      <path d="M7 7h10v3l4-4-4-4v3H5v6h2V7zm10 10H7v-3l-4 4 4 4v-3h12v-6h-2v4zm-4-2V9h-1l-2 1v1h1.5v4H13z" />
    </g>
  ),
  replay: (
    <g>
      <path d="M12 5V1L7 6l5 5V7c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6H4c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z" />
    </g>
  ),
  shuffle: (
    <g>
      <path d="M10.59 9.17L5.41 4 4 5.41l5.17 5.17 1.42-1.41zM14.5 4l2.04 2.04L4 18.59 5.41 20 17.96 7.46 20 9.5V4h-5.5zm.33 9.41l-1.41 1.41 3.13 3.13L14.5 20H20v-5.5l-2.04 2.04-3.13-3.13z" />
    </g>
  ),
  'skip-next': (
    <g>
      <path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z" />
    </g>
  ),
  'skip-previous': (
    <g>
      <path d="M6 6h2v12H6zm3.5 6l8.5 6V6z" />
    </g>
  ),
  snooze: (
    <g>
      <path d="M7.88 3.39L6.6 1.86 2 5.71l1.29 1.53 4.59-3.85zM22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86L22 5.72zM12 4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7zm-3-9h3.63L9 15.2V17h6v-2h-3.63L15 10.8V9H9v2z" />
    </g>
  ),
  stop: (
    <g>
      <path d="M6 6h12v12H6z" />
    </g>
  ),
  subtitles: (
    <g>
      <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM4 12h4v2H4v-2zm10 6H4v-2h10v2zm6 0h-4v-2h4v2zm0-4H10v-2h10v2z" />
    </g>
  ),
  'surround-sound': (
    <g>
      <path d="M20 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7.76 16.24l-1.41 1.41C4.78 16.1 4 14.05 4 12c0-2.05.78-4.1 2.34-5.66l1.41 1.41C6.59 8.93 6 10.46 6 12s.59 3.07 1.76 4.24zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm5.66 1.66l-1.41-1.41C17.41 15.07 18 13.54 18 12s-.59-3.07-1.76-4.24l1.41-1.41C19.22 7.9 20 9.95 20 12c0 2.05-.78 4.1-2.34 5.66zM12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </g>
  ),
  videocam: (
    <g>
      <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z" />
    </g>
  ),
  'videocam-off': (
    <g>
      <path d="M21 6.5l-4 4V7c0-.55-.45-1-1-1H9.82L21 17.18V6.5zM3.27 2L2 3.27 4.73 6H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.21 0 .39-.08.54-.18L19.73 21 21 19.73 3.27 2z" />
    </g>
  ),
  'video-collection': (
    <g>
      <path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8 12.5v-9l6 4.5-6 4.5z" />
    </g>
  ),
  'volume-down': (
    <g>
      <path d="M18.5 12c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM5 9v6h4l5 5V4L9 9H5z" />
    </g>
  ),
  'volume-mute': (
    <g>
      <path d="M7 9v6h4l5 5V4l-5 5H7z" />
    </g>
  ),
  'volume-off': (
    <g>
      <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
    </g>
  ),
  'volume-up': (
    <g>
      <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
    </g>
  ),
  web: (
    <g>
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 14H4v-4h11v4zm0-5H4V9h11v4zm5 5h-4V9h4v9z" />
    </g>
  ),
  business: (
    <g>
      <path d="M12 7v-4h-10v18h20v-14h-10zm-6 12h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm4 12h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2v-2h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z" />
    </g>
  ),
  call: (
    <g>
      <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1v3.49c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
    </g>
  ),
  'call-end': (
    <g>
      <path d="M12 9c-1.6 0-3.15.25-4.6.72v3.1c0 .39-.23.74-.56.9-.98.49-1.87 1.12-2.66 1.85-.18.18-.43.28-.7.28-.28 0-.53-.11-.71-.29l-2.48-2.48c-.18-.17-.29-.42-.29-.7 0-.28.11-.53.29-.71 3.05-2.89 7.17-4.67 11.71-4.67s8.66 1.78 11.71 4.67c.18.18.29.43.29.71 0 .28-.11.53-.29.71l-2.48 2.48c-.18.18-.43.29-.71.29-.27 0-.52-.11-.7-.28-.79-.74-1.69-1.36-2.67-1.85-.33-.16-.56-.5-.56-.9v-3.1c-1.44-.48-2.99-.73-4.59-.73z" />
    </g>
  ),
  'call-made': (
    <g>
      <path d="M9 5v2h6.59l-11.59 11.59 1.41 1.41 11.59-11.59v6.59h2v-10z" />
    </g>
  ),
  'call-merge': (
    <g>
      <path d="M17 20.41l1.41-1.41-3.41-3.41-1.41 1.41 3.41 3.41zm-9.5-12.41h3.5v5.59l-5.41 5.41 1.41 1.41 6-6v-6.41h3.5l-4.5-4.5-4.5 4.5z" />
    </g>
  ),
  'call-missed': (
    <g>
      <path d="M19.59 7l-7.59 7.59-5.59-5.59h4.59v-2h-8v8h2v-4.59l7 7 9-9z" />
    </g>
  ),
  'call-received': (
    <g>
      <path d="M20 5.41l-1.41-1.41-11.59 11.59v-6.59h-2v10h10v-2h-6.59z" />
    </g>
  ),
  'call-split': (
    <g>
      <path d="M14 4l2.29 2.29-2.88 2.88 1.42 1.42 2.88-2.88 2.29 2.29v-6zm-4 0h-6v6l2.29-2.29 4.71 4.7v7.59h2v-8.41l-5.29-5.3z" />
    </g>
  ),
  chat: (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 18 4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-14 7h12v2h-12v-2zm8 5h-8v-2h8v2zm4-6h-12v-2h12v2z" />
    </g>
  ),
  'clear-all': (
    <g>
      <path d="M5 13h14v-2h-14v2zm-2 4h14v-2h-14v2zm4-10v2h14v-2h-14z" />
    </g>
  ),
  comment: (
    <g>
      <path d="M21.99 4c0-1.1-.89-2-1.99-2h-16c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zm-3.99 10h-12v-2h12v2zm0-3h-12v-2h12v2zm0-3h-12v-2h12v2z" />
    </g>
  ),
  contacts: (
    <g>
      <path d="M20 0h-16v2h16v-2zm-16 24h16v-2h-16v2zm16-20h-16c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-8 2.75c1.24 0 2.25 1.01 2.25 2.25s-1.01 2.25-2.25 2.25-2.25-1.01-2.25-2.25 1.01-2.25 2.25-2.25zm5 10.25h-10v-1.5c0-1.67 3.33-2.5 5-2.5s5 .83 5 2.5v1.5z" />
    </g>
  ),
  'dialer-sip': (
    <g>
      <path d="M17 3h-1v5h1v-5zm-2 2h-2v-1h2v-1h-3v3h2v1h-2v1h3v-3zm3-2v5h1v-2h2v-3h-3zm2 2h-1v-1h1v1zm0 10.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.01.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.27-.26.35-.65.24-1-.37-1.12-.57-2.32-.57-3.57 0-.55-.45-1-1-1h-3.5c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1z" />
    </g>
  ),
  dialpad: (
    <g>
      <path d="M12 19c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6-18c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12-8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-6 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </g>
  ),
  'dnd-on': (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9l11.21 11.21c-1.35 1.06-3.05 1.69-4.9 1.69zm6.31-3.1l-11.21-11.21c1.35-1.06 3.05-1.69 4.9-1.69 4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z" />
    </g>
  ),
  email: (
    <g>
      <path d="M20 4h-16c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm0 4l-8 5-8-5v-2l8 5 8-5v2z" />
    </g>
  ),
  forum: (
    <g>
      <path d="M21 6h-2v9h-13v2c0 .55.45 1 1 1h11l4 4v-15c0-.55-.45-1-1-1zm-4 6v-9c0-.55-.45-1-1-1h-13c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" />
    </g>
  ),
  hangout: (
    <g>
      <path d="M11.5 2c-4.69 0-8.5 3.81-8.5 8.5s3.81 8.5 8.5 8.5h.5v3.5c4.86-2.34 8-7.5 8-12 0-4.69-3.81-8.5-8.5-8.5zm-.5 9l-1 2h-1.5l1-2h-1.5v-3h3v3zm4 0l-1 2h-1.5l1-2h-1.5v-3h3v3z" />
    </g>
  ),
  'hangout-video': (
    <g>
      <path d="M20 4h-16c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-2 12l-4-3.2v3.2h-8v-8h8v3.2l4-3.2v8z" />
    </g>
  ),
  'hangout-video-off': (
    <g>
      <path d="M20 4h-12.18l4 4h2.18v2.18l.57.57 3.43-2.75v6.18l3.98 3.98.02-.16v-12c0-1.1-.9-2-2-2zm-17.73-3l-1.27 1.27 2 2c-.59.35-1 .99-1 1.73v12c0 1.1.9 2 2 2h14.73l2 2 1.27-1.27-19.73-19.73zm3.73 7h.73l7.27 7.27v.73h-8v-8z" />
    </g>
  ),
  'import-export': (
    <g>
      <path d="M9 3l-4 3.99h3v7.01h2v-7.01h3l-4-3.99zm7 14.01v-7.01h-2v7.01h-3l4 3.99 4-3.99h-3z" />
    </g>
  ),
  'invert-colors-off': (
    <g>
      <path d="M20.65 20.87l-2.35-2.35-6.3-6.29-3.56-3.57-1.42-1.41-2.75-2.75-1.27 1.27 2.78 2.78c-2.55 3.14-2.36 7.76.56 10.69 1.56 1.56 3.61 2.34 5.66 2.34 1.79 0 3.57-.59 5.03-1.78l2.7 2.7 1.27-1.27-.35-.36zm-8.65-1.28c-1.6 0-3.11-.62-4.24-1.76-1.14-1.14-1.76-2.64-1.76-4.24 0-1.32.43-2.57 1.21-3.6l4.79 4.78v4.82zm0-14.49v4.58l7.25 7.26c1.37-2.96.84-6.57-1.6-9.01l-5.65-5.66-3.7 3.7 1.41 1.41 2.29-2.28z" />
    </g>
  ),
  'invert-colors-on': (
    <g>
      <path d="M17.66 7.93l-5.66-5.66-5.66 5.66c-3.12 3.12-3.12 8.19 0 11.31 1.56 1.56 3.61 2.34 5.66 2.34 2.05 0 4.1-.78 5.66-2.34 3.12-3.12 3.12-8.19 0-11.31zm-5.66 11.66c-1.6 0-3.11-.62-4.24-1.76-1.14-1.14-1.76-2.64-1.76-4.24s.62-3.11 1.76-4.24l4.24-4.25v14.49z" />
    </g>
  ),
  'live-help': (
    <g>
      <path d="M19 2h-14c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h4l3 3 3-3h4c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-6 16h-2v-2h2v2zm2.07-7.75l-.9.92c-.72.73-1.17 1.33-1.17 2.83h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2h-2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
    </g>
  ),
  'location-off': (
    <g>
      <path d="M12 6.5c1.38 0 2.5 1.12 2.5 2.5 0 .74-.33 1.39-.83 1.85l3.63 3.63c.98-1.86 1.7-3.8 1.7-5.48 0-3.87-3.13-7-7-7-1.98 0-3.76.83-5.04 2.15l3.19 3.19c.46-.52 1.11-.84 1.85-.84zm4.37 9.6l-4.63-4.63-.11-.11-8.36-8.36-1.27 1.27 3.18 3.18c-.11.5-.18 1.02-.18 1.55 0 5.25 7 13 7 13s1.67-1.85 3.38-4.35l3.35 3.35 1.27-1.27-3.63-3.63z" />
    </g>
  ),
  'location-on': (
    <g>
      <path d="M12 2c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
    </g>
  ),
  message: (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 18 4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-2 12h-12v-2h12v2zm0-3h-12v-2h12v2zm0-3h-12v-2h12v2z" />
    </g>
  ),
  messenger: (
    <g>
      <path d="M20 2h-16c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2z" />
    </g>
  ),
  'no-sim': (
    <g>
      <path d="M18.99 5c0-1.1-.89-2-1.99-2h-7l-2.34 2.34 11.34 11.34-.01-11.68zm-15.34-1.12l-1.27 1.27 2.62 2.62v11.23c0 1.1.9 2 2 2h10.01c.35 0 .67-.1.96-.26l1.88 1.88 1.27-1.27-17.47-17.47z" />
    </g>
  ),
  phone: (
    <g>
      <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1v3.49c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
    </g>
  ),
  'portable-wifi-off': (
    <g>
      <path d="M17.56 14.24c.28-.69.44-1.45.44-2.24 0-3.31-2.69-6-6-6-.79 0-1.55.16-2.24.44l1.62 1.62c.2-.03.41-.06.62-.06 2.21 0 4 1.79 4 4 0 .21-.02.42-.05.63l1.61 1.61zm-5.56-10.24c4.42 0 8 3.58 8 8 0 1.35-.35 2.62-.95 3.74l1.47 1.47c.94-1.52 1.48-3.3 1.48-5.21 0-5.52-4.48-10-10-10-1.91 0-3.69.55-5.21 1.47l1.46 1.46c1.12-.59 2.4-.93 3.75-.93zm-8.73-1.5l-1.27 1.27 2.1 2.1c-1.31 1.7-2.1 3.82-2.1 6.13 0 3.7 2.01 6.92 4.99 8.65l1-1.73c-2.38-1.39-3.99-3.96-3.99-6.92 0-1.76.57-3.38 1.53-4.69l1.43 1.44c-.6.93-.96 2.05-.96 3.25 0 2.22 1.21 4.15 3 5.19l1-1.74c-1.19-.7-2-1.97-2-3.45 0-.65.17-1.25.44-1.79l1.58 1.58-.02.21c0 1.1.9 2 2 2l.21-.02.01.01 7.51 7.51 1.27-1.27-16.73-16.73-1-1z" />
    </g>
  ),
  'quick-contacts-dialer': (
    <g>
      <path d="M22 3h-20c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h20c1.1 0 1.99-.9 1.99-2l.01-14c0-1.1-.9-2-2-2zm-14 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12h-12v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm3.85-4h1.64l1.51 2-1.99 1.99c-1.31-.98-2.28-2.38-2.73-3.99-.18-.64-.28-1.31-.28-2s.1-1.36.28-2c.45-1.62 1.42-3.01 2.73-3.99l1.99 1.99-1.51 2h-1.64c-.22.63-.35 1.3-.35 2s.13 1.37.35 2z" />
    </g>
  ),
  'quick-contacts-mail': (
    <g>
      <path d="M21 8v-1l-3 2-3-2v1l3 2 3-2zm1-5h-20c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h20c1.1 0 1.99-.9 1.99-2l.01-14c0-1.1-.9-2-2-2zm-14 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12h-12v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm8-6h-8v-6h8v6z" />
    </g>
  ),
  'ring-volume': (
    <g>
      <path d="M23.71 16.67c-3.05-2.89-7.17-4.67-11.71-4.67-4.54 0-8.66 1.78-11.71 4.67-.18.18-.29.43-.29.71 0 .28.11.53.29.71l2.48 2.48c.18.18.43.29.71.29.27 0 .52-.11.7-.28.79-.74 1.69-1.36 2.66-1.85.33-.16.56-.5.56-.9v-3.1c1.45-.48 3-.73 4.6-.73s3.15.25 4.6.72v3.1c0 .39.23.74.56.9.98.49 1.87 1.12 2.66 1.85.18.18.43.28.7.28.28 0 .53-.11.71-.29l2.48-2.48c.18-.18.29-.43.29-.71 0-.27-.11-.52-.29-.7zm-2.55-10.41l-1.41-1.41-3.56 3.55 1.41 1.41s3.45-3.52 3.56-3.55zm-8.16-4.26h-2v5h2v-5zm-6.6 7.81l1.41-1.41-3.55-3.56-1.42 1.42c.11.03 3.56 3.55 3.56 3.55z" />
    </g>
  ),
  'stay-current-landscape': (
    <g>
      <path d="M1.01 7l-.01 10c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2h-18c-1.1 0-1.99.9-1.99 2zm17.99 0v10h-14v-10h14z" />
    </g>
  ),
  'stay-current-portrait': (
    <g>
      <path d="M17 1.01l-10-.01c-1.1 0-1.99.9-1.99 2v18c0 1.1.89 2 1.99 2h10c1.1 0 2-.9 2-2v-18c0-1.1-.9-1.99-2-1.99zm0 17.99h-10v-14h10v14z" />
    </g>
  ),
  'stay-primary-landscape': (
    <g>
      <path d="M1.01 7l-.01 10c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2h-18c-1.1 0-1.99.9-1.99 2zm17.99 0v10h-14v-10h14z" />
    </g>
  ),
  'stay-primary-portrait': (
    <g>
      <path d="M17 1.01l-10-.01c-1.1 0-1.99.9-1.99 2v18c0 1.1.89 2 1.99 2h10c1.1 0 2-.9 2-2v-18c0-1.1-.9-1.99-2-1.99zm0 17.99h-10v-14h10v14z" />
    </g>
  ),
  'swap-calls': (
    <g>
      <path d="M18 4l-4 4h3v7c0 1.1-.9 2-2 2s-2-.9-2-2v-7c0-2.21-1.79-4-4-4s-4 1.79-4 4v7h-3l4 4 4-4h-3v-7c0-1.1.9-2 2-2s2 .9 2 2v7c0 2.21 1.79 4 4 4s4-1.79 4-4v-7h3l-4-4z" />
    </g>
  ),
  textsms: (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 18 4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-11 9h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z" />
    </g>
  ),
  voicemail: (
    <g>
      <path d="M18.5 6c-3.04 0-5.5 2.46-5.5 5.5 0 1.33.47 2.55 1.26 3.5h-4.52c.79-.95 1.26-2.17 1.26-3.5 0-3.04-2.46-5.5-5.5-5.5s-5.5 2.46-5.5 5.5 2.46 5.5 5.5 5.5h13c3.04 0 5.5-2.46 5.5-5.5s-2.46-5.5-5.5-5.5zm-13 9c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5zm13 0c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z" />
    </g>
  ),
  'vpn-key': (
    <g>
      <path d="M12.65 10c-.82-2.33-3.04-4-5.65-4-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4h4.35v4h4v-4h2v-4h-10.35zm-5.65 4c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
    </g>
  ),
  'access-alarm': (
    <g>
      <path d="M22 5.72l-4.6-3.86-1.29 1.53 4.6 3.86 1.29-1.53zm-14.12-2.33l-1.28-1.53-4.6 3.85 1.29 1.53 4.59-3.85zm4.62 4.61h-1.5v6l4.75 2.85.75-1.23-4-2.37v-5.25zm-.5-4c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
    </g>
  ),
  'access-alarms': (
    <g>
      <path d="M22 5.7l-4.6-3.9-1.3 1.5 4.6 3.9 1.3-1.5zm-14.1-2.3l-1.3-1.5-4.6 3.8 1.3 1.5 4.6-3.8zm4.6 4.6h-1.5v6l4.7 2.9.8-1.2-4-2.4v-5.3zm-.5-4c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm0 16c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7z" />
    </g>
  ),
  'access-time': (
    <g>
      <path fillOpacity=".9" d="M11.99 2c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10zm.01 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      <path fillOpacity=".9" d="M12.5 7h-1.5v6l5.25 3.15.75-1.23-4.5-2.67z" />
    </g>
  ),
  'add-alarm': (
    <g>
      <path d="M7.88 3.39l-1.28-1.53-4.6 3.85 1.29 1.53 4.59-3.85zm14.12 2.33l-4.6-3.86-1.29 1.53 4.6 3.86 1.29-1.53zm-10-1.72c-4.97 0-9 4.03-9 9s4.02 9 9 9c4.97 0 9-4.03 9-9s-4.03-9-9-9zm0 16c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7zm1-11h-2v3h-3v2h3v3h2v-3h3v-2h-3v-3z" />
    </g>
  ),
  'airplanemode-off': (
    <g>
      <path d="M13 9v-5.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v3.68l7.83 7.83 3.17.99v-2l-8-5zm-10-3.73l4.99 4.99-5.99 3.74v2l8-2.5v5.5l-2 1.5v1.5l3.5-1 3.5 1v-1.5l-2-1.5v-3.73l5.73 5.73 1.27-1.27-15.73-15.73-1.27 1.27z" />
    </g>
  ),
  'airplanemode-on': (
    <g>
      <path d="M10.18 9" />
      <path d="M21 16v-2l-8-5v-5.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v5.5l-8 5v2l8-2.5v5.5l-2 1.5v1.5l3.5-1 3.5 1v-1.5l-2-1.5v-5.5l8 2.5z" />
    </g>
  ),
  'battery-20': (
    <g>
      <path d="M7 17v3.67c0 .73.6 1.33 1.33 1.33h7.33c.74 0 1.34-.6 1.34-1.33v-3.67h-10z" />
      <path fillOpacity=".3" d="M17 5.33c0-.73-.6-1.33-1.33-1.33h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v11.67h10v-11.67z" />
    </g>
  ),
  'battery-30': (
    <g>
      <path fillOpacity=".3" d="M17 5.33c0-.73-.6-1.33-1.33-1.33h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v9.67h10v-9.67z" />
      <path d="M7 15v5.67c0 .73.6 1.33 1.33 1.33h7.33c.74 0 1.34-.6 1.34-1.33v-5.67h-10z" />
    </g>
  ),
  'battery-50': (
    <g>
      <path fillOpacity=".3" d="M17 5.33c0-.73-.6-1.33-1.33-1.33h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v7.67h10v-7.67z" />
      <path d="M7 13v7.67c0 .73.6 1.33 1.33 1.33h7.33c.74 0 1.34-.6 1.34-1.33v-7.67h-10z" />
    </g>
  ),
  'battery-60': (
    <g>
      <path fillOpacity=".3" d="M17 5.33c0-.73-.6-1.33-1.33-1.33h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v5.67h10v-5.67z" />
      <path d="M7 11v9.67c0 .73.6 1.33 1.33 1.33h7.33c.74 0 1.34-.6 1.34-1.33v-9.67h-10z" />
    </g>
  ),
  'battery-80': (
    <g>
      <path fillOpacity=".3" d="M17 5.33c0-.73-.6-1.33-1.33-1.33h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v3.67h10v-3.67z" />
      <path d="M7 9v11.67c0 .73.6 1.33 1.33 1.33h7.33c.74 0 1.34-.6 1.34-1.33v-11.67h-10z" />
    </g>
  ),
  'battery-90': (
    <g>
      <path fillOpacity=".3" d="M17 5.33c0-.73-.6-1.33-1.33-1.33h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v2.67h10v-2.67z" />
      <path d="M7 8v12.67c0 .73.6 1.33 1.33 1.33h7.33c.74 0 1.34-.6 1.34-1.33v-12.67h-10z" />
    </g>
  ),
  'battery-alert': (
    <g>
      <path d="M15.67 4h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v15.33c0 .74.6 1.34 1.33 1.34h7.33c.74 0 1.34-.6 1.34-1.33v-15.34c0-.73-.6-1.33-1.33-1.33zm-2.67 14h-2v-2h2v2zm0-4h-2v-5h2v5z" />
    </g>
  ),
  'battery-charging-20': (
    <g>
      <path d="M11 20v-3h-4v3.67c0 .73.6 1.33 1.33 1.33h7.33c.74 0 1.34-.6 1.34-1.33v-3.67h-4.4l-1.6 3z" />
      <path fillOpacity=".3" d="M15.67 4h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v11.67h4v-2.5h-2l4-7.5v5.5h2l-2.4 4.5h4.4v-11.67c0-.73-.6-1.33-1.33-1.33z" />
    </g>
  ),
  'battery-charging-30': (
    <g>
      <path fillOpacity=".3" d="M15.67 4h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v9.17h2l4-7.5v5.5h2l-1.07 2h3.07v-9.17c0-.73-.6-1.33-1.33-1.33z" />
      <path d="M11 20v-5.5h-4v6.17c0 .73.6 1.33 1.33 1.33h7.33c.74 0 1.34-.6 1.34-1.33v-6.17h-3.07l-2.93 5.5z" />
    </g>
  ),
  'battery-charging-50': (
    <g>
      <path d="M14.47 13.5l-3.47 6.5v-5.5h-2l.53-1h-2.53v7.17c0 .73.6 1.33 1.33 1.33h7.33c.74 0 1.34-.6 1.34-1.33v-7.17h-2.53z" />
      <path fillOpacity=".3" d="M15.67 4h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v8.17h2.53l3.47-6.5v5.5h2l-.53 1h2.53v-8.17c0-.73-.6-1.33-1.33-1.33z" />
    </g>
  ),
  'battery-charging-60': (
    <g>
      <path fillOpacity=".3" d="M15.67 4h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v5.67h3.87l2.13-4v4h4v-5.67c0-.73-.6-1.33-1.33-1.33z" />
      <path d="M13 12.5h2l-4 7.5v-5.5h-2l1.87-3.5h-3.87v9.67c0 .73.6 1.33 1.33 1.33h7.33c.74 0 1.34-.6 1.34-1.33v-9.67h-4v1.5z" />
    </g>
  ),
  'battery-charging-80': (
    <g>
      <path fillOpacity=".3" d="M15.67 4h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v3.67h4.93l1.07-2v2h4v-3.67c0-.73-.6-1.33-1.33-1.33z" />
      <path d="M13 12.5h2l-4 7.5v-5.5h-2l2.93-5.5h-4.93v11.67c0 .73.6 1.33 1.33 1.33h7.33c.74 0 1.34-.6 1.34-1.33v-11.67h-4v3.5z" />
    </g>
  ),
  'battery-charging-90': (
    <g>
      <path fillOpacity=".3" d="M15.67 4h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v2.67h5.47l.53-1v1h4v-2.67c0-.73-.6-1.33-1.33-1.33z" />
      <path d="M13 12.5h2l-4 7.5v-5.5h-2l3.47-6.5h-5.47v12.67c0 .73.6 1.33 1.33 1.33h7.33c.74 0 1.34-.6 1.34-1.33v-12.67h-4v4.5z" />
    </g>
  ),
  'battery-charging-full': (
    <g>
      <path d="M15.67 4h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v15.33c0 .74.6 1.34 1.33 1.34h7.33c.74 0 1.34-.6 1.34-1.33v-15.34c0-.73-.6-1.33-1.33-1.33zm-4.67 16v-5.5h-2l4-7.5v5.5h2l-4 7.5z" />
    </g>
  ),
  'battery-full': (
    <g>
      <path d="M15.67 4h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v15.33c0 .74.6 1.34 1.33 1.34h7.33c.74 0 1.34-.6 1.34-1.33v-15.34c0-.73-.6-1.33-1.33-1.33z" />
    </g>
  ),
  'battery-std': (
    <g>
      <path d="M15.67 4h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v15.33c0 .74.6 1.34 1.33 1.34h7.33c.74 0 1.34-.6 1.34-1.33v-15.34c0-.73-.6-1.33-1.33-1.33z" />
    </g>
  ),
  'battery-unknown': (
    <g>
      <path d="M15.67 4h-1.67v-2h-4v2h-1.67c-.73 0-1.33.6-1.33 1.33v15.33c0 .74.6 1.34 1.33 1.34h7.33c.74 0 1.34-.6 1.34-1.33v-15.34c0-.73-.6-1.33-1.33-1.33zm-2.72 13.95h-1.9v-1.9h1.9v1.9zm1.35-5.26s-.38.42-.67.71c-.48.48-.83 1.15-.83 1.6h-1.6c0-.83.46-1.52.93-2l.93-.94c.27-.27.44-.65.44-1.06 0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5h-1.5c0-1.66 1.34-3 3-3s3 1.34 3 3c0 .66-.27 1.26-.7 1.69z" />
    </g>
  ),
  bluetooth: (
    <g>
      <path d="M17.71 7.71l-5.71-5.71h-1v7.59l-4.59-4.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 4.59-4.59v7.59h1l5.71-5.71-4.3-4.29 4.3-4.29zm-4.71-1.88l1.88 1.88-1.88 1.88v-3.76zm1.88 10.46l-1.88 1.88v-3.76l1.88 1.88z" />
    </g>
  ),
  'bluetooth-connected': (
    <g>
      <path d="M7 12l-2-2-2 2 2 2 2-2zm10.71-4.29l-5.71-5.71h-1v7.59l-4.59-4.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 4.59-4.59v7.59h1l5.71-5.71-4.3-4.29 4.3-4.29zm-4.71-1.88l1.88 1.88-1.88 1.88v-3.76zm1.88 10.46l-1.88 1.88v-3.76l1.88 1.88zm4.12-6.29l-2 2 2 2 2-2-2-2z" />
    </g>
  ),
  'bluetooth-disabled': (
    <g>
      <path d="M13 5.83l1.88 1.88-1.6 1.6 1.41 1.41 3.02-3.02-5.71-5.7h-1v5.03l2 2v-3.2zm-7.59-1.83l-1.41 1.41 6.59 6.59-5.59 5.59 1.41 1.41 4.59-4.59v7.59h1l4.29-4.29 2.3 2.29 1.41-1.41-14.59-14.59zm7.59 14.17v-3.76l1.88 1.88-1.88 1.88z" />
    </g>
  ),
  'bluetooth-searching': (
    <g>
      <path d="M14.24 12.01l2.32 2.32c.28-.72.44-1.51.44-2.33 0-.82-.16-1.59-.43-2.31l-2.33 2.32zm5.29-5.3l-1.26 1.26c.63 1.21.98 2.57.98 4.02s-.36 2.82-.98 4.02l1.2 1.2c.97-1.54 1.54-3.36 1.54-5.31-.01-1.89-.55-3.67-1.48-5.19zm-3.82 1l-5.71-5.71h-1v7.59l-4.59-4.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 4.59-4.59v7.59h1l5.71-5.71-4.3-4.29 4.3-4.29zm-4.71-1.88l1.88 1.88-1.88 1.88v-3.76zm1.88 10.46l-1.88 1.88v-3.76l1.88 1.88z" />
    </g>
  ),
  'brightness-auto': (
    <g>
      <path d="M10.85 12.65h2.3l-1.15-3.65-1.15 3.65zm9.15-3.96v-4.69h-4.69l-3.31-3.31-3.31 3.31h-4.69v4.69l-3.31 3.31 3.31 3.31v4.69h4.69l3.31 3.31 3.31-3.31h4.69v-4.69l3.31-3.31-3.31-3.31zm-5.7 7.31l-.7-2h-3.2l-.7 2h-1.9l3.2-9h2l3.2 9h-1.9z" />
    </g>
  ),
  'brightness-high': (
    <g>
      <path d="M20 8.69v-4.69h-4.69l-3.31-3.31-3.31 3.31h-4.69v4.69l-3.31 3.31 3.31 3.31v4.69h4.69l3.31 3.31 3.31-3.31h4.69v-4.69l3.31-3.31-3.31-3.31zm-8 9.31c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm0-10c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z" />
    </g>
  ),
  'brightness-low': (
    <g>
      <path d="M20 15.31l3.31-3.31-3.31-3.31v-4.69h-4.69l-3.31-3.31-3.31 3.31h-4.69v4.69l-3.31 3.31 3.31 3.31v4.69h4.69l3.31 3.31 3.31-3.31h4.69v-4.69zm-8 2.69c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z" />
    </g>
  ),
  'brightness-medium': (
    <g>
      <path d="M20 15.31l3.31-3.31-3.31-3.31v-4.69h-4.69l-3.31-3.31-3.31 3.31h-4.69v4.69l-3.31 3.31 3.31 3.31v4.69h4.69l3.31 3.31 3.31-3.31h4.69v-4.69zm-8 2.69v-12c3.31 0 6 2.69 6 6s-2.69 6-6 6z" />
    </g>
  ),
  'data-usage': (
    <g>
      <path d="M13 2.05v3.03c3.39.49 6 3.39 6 6.92 0 .9-.18 1.75-.48 2.54l2.6 1.53c.56-1.24.88-2.62.88-4.07 0-5.18-3.95-9.45-9-9.95zm-1 16.95c-3.87 0-7-3.13-7-7 0-3.53 2.61-6.43 6-6.92v-3.03c-5.06.5-9 4.76-9 9.95 0 5.52 4.47 10 9.99 10 3.31 0 6.24-1.61 8.06-4.09l-2.6-1.53c-1.28 1.6-3.24 2.62-5.45 2.62z" />
    </g>
  ),
  'developer-mode': (
    <g>
      <path d="M7 5h10v2h2v-4c0-1.1-.9-1.99-2-1.99l-10-.01c-1.1 0-2 .9-2 2v4h2v-2zm8.41 11.59l4.59-4.59-4.59-4.59-1.41 1.42 3.17 3.17-3.17 3.17 1.41 1.42zm-5.41-1.42l-3.17-3.17 3.17-3.17-1.41-1.42-4.59 4.59 4.59 4.59 1.41-1.42zm7 3.83h-10v-2h-2v4c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-4h-2v2z" />
    </g>
  ),
  devices: (
    <g>
      <path d="M4 6h18v-2h-18c-1.1 0-2 .9-2 2v11h-2v3h14v-3h-10v-11zm19 2h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-10c0-.55-.45-1-1-1zm-1 9h-4v-7h4v7z" />
    </g>
  ),
  dvr: (
    <g>
      <path d="M21 3h-18c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2l.01-12c0-1.1-.9-2-2-2zm0 14h-18v-12h18v12zm-2-9h-11v2h11v-2zm0 4h-11v2h11v-2zm-12-4h-2v2h2v-2zm0 4h-2v2h2v-2z" />
    </g>
  ),
  'gps-fixed': (
    <g>
      <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94v-2.06h-2v2.06c-4.17.46-7.48 3.77-7.94 7.94h-2.06v2h2.06c.46 4.17 3.77 7.48 7.94 7.94v2.06h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94h2.06v-2h-2.06zm-8.94 8c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
    </g>
  ),
  'gps-not-fixed': (
    <g>
      <path d="M20.94 11c-.46-4.17-3.77-7.48-7.94-7.94v-2.06h-2v2.06c-4.17.46-7.48 3.77-7.94 7.94h-2.06v2h2.06c.46 4.17 3.77 7.48 7.94 7.94v2.06h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94h2.06v-2h-2.06zm-8.94 8c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
    </g>
  ),
  'gps-off': (
    <g>
      <path d="M20.94 11c-.46-4.17-3.77-7.48-7.94-7.94v-2.06h-2v2.06c-1.13.12-2.19.46-3.16.97l1.5 1.5c.82-.34 1.72-.53 2.66-.53 3.87 0 7 3.13 7 7 0 .94-.19 1.84-.52 2.65l1.5 1.5c.5-.96.84-2.02.97-3.15h2.05v-2h-2.06zm-17.94-6.73l2.04 2.04c-1.07 1.31-1.79 2.92-1.98 4.69h-2.06v2h2.06c.46 4.17 3.77 7.48 7.94 7.94v2.06h2v-2.06c1.77-.2 3.38-.91 4.69-1.98l2.04 2.04 1.27-1.27-16.73-16.73-1.27 1.27zm13.27 13.27c-1.18.91-2.66 1.46-4.27 1.46-3.87 0-7-3.13-7-7 0-1.61.55-3.09 1.46-4.27l9.81 9.81z" />
    </g>
  ),
  'location-disabled': (
    <g>
      <path d="M20.94 11c-.46-4.17-3.77-7.48-7.94-7.94v-2.06h-2v2.06c-1.13.12-2.19.46-3.16.97l1.5 1.5c.82-.34 1.72-.53 2.66-.53 3.87 0 7 3.13 7 7 0 .94-.19 1.84-.52 2.65l1.5 1.5c.5-.96.84-2.02.97-3.15h2.05v-2h-2.06zm-17.94-6.73l2.04 2.04c-1.07 1.31-1.79 2.92-1.98 4.69h-2.06v2h2.06c.46 4.17 3.77 7.48 7.94 7.94v2.06h2v-2.06c1.77-.2 3.38-.91 4.69-1.98l2.04 2.04 1.27-1.27-16.73-16.73-1.27 1.27zm13.27 13.27c-1.18.91-2.66 1.46-4.27 1.46-3.87 0-7-3.13-7-7 0-1.61.55-3.09 1.46-4.27l9.81 9.81z" />
    </g>
  ),
  'location-searching': (
    <g>
      <path d="M20.94 11c-.46-4.17-3.77-7.48-7.94-7.94v-2.06h-2v2.06c-4.17.46-7.48 3.77-7.94 7.94h-2.06v2h2.06c.46 4.17 3.77 7.48 7.94 7.94v2.06h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94h2.06v-2h-2.06zm-8.94 8c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
    </g>
  ),
  'multitrack-audio': (
    <g>
      <path d="M7 18h2v-12h-2v12zm4 4h2v-20h-2v20zm-8-8h2v-4h-2v4zm12 4h2v-12h-2v12zm4-8v4h2v-4h-2z" />
    </g>
  ),
  'network-cell': (
    <g>
      <path fillOpacity=".3" d="M2 22h20v-20z" />
      <path d="M17 7l-15 15h15z" />
    </g>
  ),
  'network-wifi': (
    <g>
      <path fillOpacity=".3" d="M12.01 21.49l11.63-14.49c-.45-.34-4.93-4-11.64-4-6.72 0-11.19 3.66-11.64 4l11.63 14.49.01.01.01-.01z" />
      <path d="M3.53 10.95l8.46 10.54.01.01.01-.01 8.46-10.54c-.43-.33-3.66-2.95-8.47-2.95-4.81 0-8.04 2.62-8.47 2.95z" />
    </g>
  ),
  nfc: (
    <g>
      <path d="M20 2h-16c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm0 18h-16v-16h16v16zm-2-14h-5c-1.1 0-2 .9-2 2v2.28c-.6.35-1 .98-1 1.72 0 1.1.9 2 2 2s2-.9 2-2c0-.74-.4-1.38-1-1.72v-2.28h3v8h-8v-8h2v-2h-4v12h12v-12z" />
    </g>
  ),
  'now-wallpaper': (
    <g>
      <path d="M4 4h7v-2h-7c-1.1 0-2 .9-2 2v7h2v-7zm6 9l-4 5h12l-3-4-2.03 2.71-2.97-3.71zm7-4.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5.67 1.5 1.5 1.5 1.5-.67 1.5-1.5zm3-6.5h-7v2h7v7h2v-7c0-1.1-.9-2-2-2zm0 18h-7v2h7c1.1 0 2-.9 2-2v-7h-2v7zm-16-7h-2v7c0 1.1.9 2 2 2h7v-2h-7v-7z" />
    </g>
  ),
  'now-widgets': (
    <g>
      <path d="M13 13v8h8v-8h-8zm-10 8h8v-8h-8v8zm0-18v8h8v-8h-8zm13.66-1.31l-5.66 5.65 5.66 5.66 5.66-5.66-5.66-5.65z" />
    </g>
  ),
  'screen-lock-landscape': (
    <g>
      <path d="M21 5h-18c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm-2 12h-14v-10h14v10zm-9-1h4c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1v-1c0-1.11-.9-2-2-2-1.11 0-2 .9-2 2v1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1zm.8-6c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2v1h-2.4v-1z" />
    </g>
  ),
  'screen-lock-portrait': (
    <g>
      <path d="M10 16h4c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1v-1c0-1.11-.9-2-2-2-1.11 0-2 .9-2 2v1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1zm.8-6c0-.66.54-1.2 1.2-1.2.66 0 1.2.54 1.2 1.2v1h-2.4v-1zm6.2-9h-10c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-18c0-1.1-.9-2-2-2zm0 18h-10v-14h10v14z" />
    </g>
  ),
  'screen-lock-rotation': (
    <g>
      <path d="M23.25 12.77l-2.57-2.57-1.41 1.41 2.22 2.22-5.66 5.66-11.32-11.32 5.66-5.66 2.1 2.1 1.41-1.41-2.45-2.45c-.59-.59-1.54-.59-2.12 0l-6.36 6.36c-.59.59-.59 1.54 0 2.12l12.02 12.02c.59.59 1.54.59 2.12 0l6.36-6.36c.59-.59.59-1.54 0-2.12zm-14.78 7.71c-3.27-1.54-5.61-4.72-5.97-8.48h-1.5c.51 6.16 5.66 11 11.95 11l.66-.03-3.81-3.82-1.33 1.33zm7.53-11.48h5c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1v-.5c0-1.38-1.12-2.5-2.5-2.5s-2.5 1.12-2.5 2.5v.5c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm.8-6.5c0-.94.76-1.7 1.7-1.7s1.7.76 1.7 1.7v.5h-3.4v-.5z" />
    </g>
  ),
  'screen-rotation': (
    <g>
      <path d="M16.48 2.52c3.27 1.55 5.61 4.72 5.97 8.48h1.5c-.51-6.16-5.66-11-11.95-11l-.66.03 3.81 3.81 1.33-1.32zm-6.25-.77c-.59-.59-1.54-.59-2.12 0l-6.36 6.36c-.59.59-.59 1.54 0 2.12l12.02 12.02c.59.59 1.54.59 2.12 0l6.36-6.36c.59-.59.59-1.54 0-2.12l-12.02-12.02zm4.6 19.44l-12.02-12.02 6.36-6.36 12.02 12.02-6.36 6.36zm-7.31.29c-3.27-1.54-5.61-4.72-5.97-8.48h-1.5c.51 6.16 5.66 11 11.95 11l.66-.03-3.81-3.81-1.33 1.32z" />
    </g>
  ),
  'sd-storage': (
    <g>
      <path d="M18 2h-8l-5.98 6-.02 12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-6 6h-2v-4h2v4zm3 0h-2v-4h2v4zm3 0h-2v-4h2v4z" />
    </g>
  ),
  'settings-system-daydream': (
    <g>
      <path d="M9 16h6.5c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5h-.05c-.24-1.69-1.69-3-3.45-3-1.4 0-2.6.83-3.16 2.02h-.16c-1.51.16-2.68 1.43-2.68 2.98 0 1.66 1.34 3 3 3zm12-13h-18c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16.01h-18v-14.02h18v14.02z" />
    </g>
  ),
  'signal-cellular-0-bar': (
    <g>
      <path fillOpacity=".3" d="M2 22h20v-20z" />
    </g>
  ),
  'signal-cellular-1-bar': (
    <g>
      <path fillOpacity=".3" d="M2 22h20v-20z" />
      <path d="M12 12l-10 10h10z" />
    </g>
  ),
  'signal-cellular-2-bar': (
    <g>
      <path fillOpacity=".3" d="M2 22h20v-20z" />
      <path d="M14 10l-12 12h12z" />
    </g>
  ),
  'signal-cellular-3-bar': (
    <g>
      <path fillOpacity=".3" d="M2 22h20v-20z" />
      <path d="M17 7l-15 15h15z" />
    </g>
  ),
  'signal-cellular-4-bar': (
    <g>
      <path d="M2 22h20v-20z" />
    </g>
  ),
  'signal-cellular-connected-no-internet-0-bar': (
    <g>
      <path fillOpacity=".3" d="M22 8v-6l-20 20h16v-14z" />
      <path d="M20 22h2v-2h-2v2zm0-12v8h2v-8h-2z" />
    </g>
  ),
  'signal-cellular-connected-no-internet-1-bar': (
    <g>
      <path fillOpacity=".3" d="M22 8v-6l-20 20h16v-14z" />
      <path d="M20 10v8h2v-8h-2zm-8 12v-10l-10 10h10zm8 0h2v-2h-2v2z" />
    </g>
  ),
  'signal-cellular-connected-no-internet-2-bar': (
    <g>
      <path fillOpacity=".3" d="M22 8v-6l-20 20h16v-14z" />
      <path d="M14 22v-12l-12 12h12zm6-12v8h2v-8h-2zm0 12h2v-2h-2v2z" />
    </g>
  ),
  'signal-cellular-connected-no-internet-3-bar': (
    <g>
      <path fillOpacity=".3" d="M22 8v-6l-20 20h16v-14z" />
      <path d="M17 22v-15l-15 15h15zm3-12v8h2v-8h-2zm0 12h2v-2h-2v2z" />
    </g>
  ),
  'signal-cellular-connected-no-internet-4-bar': (
    <g>
      <path d="M20 18h2v-8h-2v8zm0 4h2v-2h-2v2zm-18 0h16v-14h4v-6l-20 20z" />
    </g>
  ),
  'signal-cellular-no-sim': (
    <g>
      <path d="M18.99 5c0-1.1-.89-2-1.99-2h-7l-2.34 2.34 11.34 11.34-.01-11.68zm-15.34-1.12l-1.27 1.27 2.62 2.62v11.23c0 1.1.9 2 2 2h10.01c.35 0 .67-.1.96-.26l1.88 1.88 1.27-1.27-17.47-17.47z" />
    </g>
  ),
  'signal-cellular-null': (
    <g>
      <path d="M20 6.83v13.17h-13.17l13.17-13.17m2-4.83l-20 20h20v-20z" />
    </g>
  ),
  'signal-cellular-off': (
    <g>
      <path d="M21 1l-8.59 8.59 8.59 8.59v-17.18zm-16.23 3.5l-1.27 1.27 6.36 6.36-8.86 8.87h17.73l2 2 1.27-1.27-17.23-17.23z" />
    </g>
  ),
  'signal-wifi-0-bar': (
    <g>
      <path fillOpacity=".3" d="M12.01 21.49l11.63-14.49c-.45-.34-4.93-4-11.64-4-6.72 0-11.19 3.66-11.64 4l11.63 14.49.01.01.01-.01z" />
    </g>
  ),
  'signal-wifi-1-bar': (
    <g>
      <path fillOpacity=".3" d="M12.01 21.49l11.63-14.49c-.45-.34-4.93-4-11.64-4-6.72 0-11.19 3.66-11.64 4l11.63 14.49.01.01.01-.01z" />
      <path d="M6.67 14.86l5.33 6.63v.01l.01-.01 5.33-6.63c-.28-.21-2.31-1.86-5.34-1.86s-5.06 1.65-5.33 1.86z" />
    </g>
  ),
  'signal-wifi-2-bar': (
    <g>
      <path fillOpacity=".3" d="M12.01 21.49l11.63-14.49c-.45-.34-4.93-4-11.64-4-6.72 0-11.19 3.66-11.64 4l11.63 14.49.01.01.01-.01z" />
      <path d="M4.79 12.52l7.2 8.98h.01l.01-.01 7.2-8.98c-.36-.27-3.11-2.51-7.21-2.51s-6.85 2.24-7.21 2.52z" />
    </g>
  ),
  'signal-wifi-3-bar': (
    <g>
      <path fillOpacity=".3" d="M12.01 21.49l11.63-14.49c-.45-.34-4.93-4-11.64-4-6.72 0-11.19 3.66-11.64 4l11.63 14.49.01.01.01-.01z" />
      <path d="M3.53 10.95l8.46 10.54.01.01.01-.01 8.46-10.54c-.43-.33-3.66-2.95-8.47-2.95-4.81 0-8.04 2.62-8.47 2.95z" />
    </g>
  ),
  'signal-wifi-4-bar': (
    <g>
      <path d="M12.01 21.49l11.63-14.49c-.45-.34-4.93-4-11.64-4-6.72 0-11.19 3.66-11.64 4l11.63 14.49.01.01.01-.01z" />
    </g>
  ),
  'signal-wifi-off': (
    <g>
      <path d="M23.64 7c-.45-.34-4.93-4-11.64-4-1.5 0-2.89.19-4.15.48l10.33 10.32 5.46-6.8zm-6.6 8.22l-13.77-13.78-1.27 1.28 2.05 2.06c-2.14.98-3.46 2.04-3.69 2.22l11.63 14.49.01.01.01-.01 3.9-4.86 3.32 3.32 1.27-1.27-3.46-3.46z" />
    </g>
  ),
  'signal-wifi-statusbar-1-bar': (
    <g>
      <path d="M13 21.99l5.66-7.05c-.22-.16-2.39-1.94-5.66-1.94s-5.44 1.78-5.66 1.95l5.66 7.04z" />
      <path fillOpacity=".3" d="M13.01 21.99l12.57-15.67c-.48-.36-5.32-4.32-12.58-4.32s-12.1 3.96-12.58 4.32l12.57 15.66.01.02.01-.01z" />
    </g>
  ),
  'signal-wifi-statusbar-2-bar': (
    <g>
      <path fillOpacity=".3" d="M13.01 21.99l12.57-15.67c-.48-.36-5.32-4.32-12.58-4.32s-12.1 3.96-12.58 4.32l12.57 15.66.01.02.01-.01z" />
      <path d="M13.01 21.99l7.54-9.4c-.29-.21-3.19-2.59-7.55-2.59-4.36 0-7.26 2.38-7.55 2.59l7.54 9.4h.02z" />
    </g>
  ),
  'signal-wifi-statusbar-3-bar': (
    <g>
      <path d="M13.01 21.99l9.43-11.75c-.37-.27-4-3.24-9.44-3.24-5.44 0-9.07 2.97-9.44 3.24l9.43 11.75h.02z" />
      <path fillOpacity=".3" d="M13.01 21.99l12.57-15.67c-.48-.36-5.32-4.32-12.58-4.32s-12.1 3.96-12.58 4.32l12.57 15.66.01.02.01-.01z" />
    </g>
  ),
  'signal-wifi-statusbar-4-bar': (
    <g>
      <path d="M13.01 21.99l12.57-15.67c-.48-.36-5.32-4.32-12.58-4.32s-12.1 3.96-12.58 4.32l12.57 15.66.01.02.01-.01z" />
    </g>
  ),
  'signal-wifi-statusbar-connected-no-internet-1': (
    <g>
      <path fillOpacity=".3" d="M24.24 8l1.35-1.68c-.49-.36-5.33-4.32-12.59-4.32s-12.1 3.96-12.58 4.32l12.57 15.66.01.02.01-.01 6.99-8.71v-5.28h4.24z" />
      <path d="M7.34 14.95l5.66 7.04v.01-.01l5.66-7.05c-.22-.16-2.39-1.94-5.66-1.94s-5.44 1.78-5.66 1.95zm14.66 7.05h2v-2h-2v2zm0-12v8h2v-8h-2z" />
    </g>
  ),
  'signal-wifi-statusbar-connected-no-internet-2': (
    <g>
      <path fillOpacity=".3" d="M24.24 8l1.35-1.68c-.49-.36-5.33-4.32-12.59-4.32s-12.1 3.96-12.58 4.32l12.57 15.66.01.02.01-.01 6.99-8.71v-5.28h4.24z" />
      <path d="M5.45 12.59l7.54 9.4.01.01.01-.01 6.99-8.71v-1.09c-1.07-.73-3.59-2.19-7-2.19-4.36 0-7.26 2.38-7.55 2.59zm16.55-2.59v8h2v-8h-2zm0 12h2v-2h-2v2z" />
    </g>
  ),
  'signal-wifi-statusbar-connected-no-internet': (
    <g>
      <path fillOpacity=".3" d="M24.24 8l1.35-1.68c-.49-.36-5.33-4.32-12.59-4.32s-12.1 3.96-12.58 4.32l12.57 15.66.01.02.01-.01 6.99-8.71v-5.28h4.24z" />
      <path d="M22 22h2v-2h-2v2zm0-12v8h2v-8h-2z" />
    </g>
  ),
  'signal-wifi-statusbar-connected-no-internet-3': (
    <g>
      <path d="M13.01 21.99l9.43-11.75c-.37-.27-4-3.24-9.44-3.24-5.44 0-9.07 2.97-9.44 3.24l9.43 11.75h.02z" />
      <path fillOpacity=".3" d="M13.01 21.99l12.57-15.67c-.48-.36-5.32-4.32-12.58-4.32s-12.1 3.96-12.58 4.32l12.57 15.66.01.02.01-.01z" />
      <path d="M20 16.27v-8.27h6.64" />
      <path d="M22 22h2v-2h-2v2zm0-12v8h2v-8h-2z" />
    </g>
  ),
  'signal-wifi-statusbar-connected-no-internet-4': (
    <g>
      <path d="M22 22h2v-2h-2v2zm-9-20c-7.26 0-12.1 3.96-12.58 4.32l12.57 15.66.01.02.01-.01 6.99-8.71v-5.28h4.24l1.35-1.68c-.49-.36-5.33-4.32-12.59-4.32zm9 16h2v-8h-2v8z" />
    </g>
  ),
  'signal-wifi-statusbar-not-connected': (
    <g>
      <path
        fillOpacity=".3"
        d="M21 8.5c.85 0 1.64.23 2.34.62l2.24-2.79c-.48-.37-5.32-4.33-12.58-4.33s-12.1 3.96-12.58 4.32l12.57 15.66.01.02.01-.01 4.21-5.24c-.76-.87-1.22-2-1.22-3.25 0-2.76 2.24-5 5-5z"
      />
      <path d="M21 10c-1.93 0-3.5 1.57-3.5 3.5h1.75c0-.97.78-1.75 1.75-1.75s1.75.78 1.75 1.75c0 .48-.2.92-.51 1.24l-1.09 1.1c-.63.63-1.02 1.51-1.02 2.47v.44h1.75c0-1.31.39-1.84 1.03-2.47l.78-.8c.5-.5.82-1.2.82-1.97-.01-1.94-1.58-3.51-3.51-3.51zm-.95 11.95h1.9v-1.9h-1.9v1.9z" />
    </g>
  ),
  'signal-wifi-statusbar-null': (
    <g>
      <path d="M13 4c4.25 0 7.62 1.51 9.68 2.75l-9.68 12.05-9.67-12.05c2.05-1.24 5.42-2.75 9.67-2.75m0-2c-7.26 0-12.1 3.96-12.58 4.32l12.57 15.66.01.02.01-.01 12.57-15.67c-.48-.36-5.32-4.32-12.58-4.32z" />
    </g>
  ),
  storage: (
    <g>
      <path d="M2 20h20v-4h-20v4zm2-3h2v2h-2v-2zm-2-13v4h20v-4h-20zm4 3h-2v-2h2v2zm-4 7h20v-4h-20v4zm2-3h2v2h-2v-2z" />
    </g>
  ),
  'tv-guide': (
    <g>
      <path d="M19 3h-14c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-3 13h-2l-2.25-6h-1.75v6h-2v-6h-3v-2h8.5l1.5 4.5 1.5-4.5h2.5l-3 8z" />
    </g>
  ),
  'tv-options-edit-channels': (
    <g>
      <path d="M1 16h2v-2h-2v2zm17-10h-13v2h13v-2zm-17 6h2v-2h-2v2zm17-2h-13v2h13v-2zm4.59-.41l-7.59 7.58-3.59-3.59-1.41 1.42 5 5 9-9-1.41-1.41zm-21.59-1.59h2v-2h-2v2zm4 8h4v-2h-4v2z" />
    </g>
  ),
  'tv-options-input-settings': (
    <g>
      <path d="M7.17 13.63l1 1.73c.06.11.19.15.31.11l1.24-.5c.26.2.54.37.85.49l.19 1.32c.01.13.12.22.24.22h2c.12 0 .23-.09.25-.21l.19-1.32c.3-.13.59-.29.84-.49l1.25.5c.11.04.24 0 .31-.11l1-1.73c.06-.11.03-.24-.06-.32l-1.06-.83c.02-.16.04-.32.04-.49s-.01-.33-.04-.49l1.06-.83c.09-.08.12-.21.06-.32l-1-1.73c-.06-.11-.19-.15-.31-.11l-1.24.5c-.26-.2-.54-.37-.84-.49l-.19-1.32c-.03-.12-.14-.21-.26-.21h-2c-.12 0-.23.09-.25.21l-.19 1.32c-.3.13-.59.29-.85.49l-1.24-.5c-.11-.04-.24 0-.31.11l-1 1.73c-.06.11-.04.24.06.32l1.06.83c-.02.16-.03.32-.03.49s.01.33.04.49l-1.06.83c-.1.07-.12.21-.06.31zm4.83-3.63c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm9-7h-18c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16.01h-18v-14.02h18v14.02z" />
    </g>
  ),
  'tv-options-parental': (
    <g>
      <path d="M16.5 12c1.38 0 2.49-1.12 2.49-2.5s-1.11-2.5-2.49-2.5c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5zm-7.5-1c1.66 0 2.99-1.34 2.99-3s-1.33-3-2.99-3c-1.66 0-3 1.34-3 3s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75v2.25h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zm-7.5-1c-2.33 0-7 1.17-7 3.5v2.5h7v-2.25c0-.85.33-2.34 2.37-3.47-.87-.18-1.71-.28-2.37-.28z" />
    </g>
  ),
  usb: (
    <g>
      <path d="M15 7v4h1v2h-3v-8h2l-3-4-3 4h2v8h-3v-2.07c.7-.37 1.2-1.08 1.2-1.93 0-1.21-.99-2.2-2.2-2.2-1.21 0-2.2.99-2.2 2.2 0 .85.5 1.56 1.2 1.93v2.07c0 1.11.89 2 2 2h3v3.05c-.71.37-1.2 1.1-1.2 1.95 0 1.22.99 2.2 2.2 2.2 1.21 0 2.2-.98 2.2-2.2 0-.85-.49-1.58-1.2-1.95v-3.05h3c1.11 0 2-.89 2-2v-2h1v-4h-4z" />
    </g>
  ),
  'wifi-lock': (
    <g>
      <path d="M20.5 9.5c.28 0 .55.04.81.08l2.69-3.58c-3.34-2.51-7.5-4-12-4s-8.66 1.49-12 4l12 16 3.5-4.67v-2.83c0-2.76 2.24-5 5-5zm2.5 6.5v-1.5c0-1.38-1.12-2.5-2.5-2.5s-2.5 1.12-2.5 2.5v1.5c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h5c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1zm-1 0h-3v-1.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v1.5z" />
    </g>
  ),
  'wifi-tethering': (
    <g>
      <path d="M12 11c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 2c0-3.31-2.69-6-6-6s-6 2.69-6 6c0 2.22 1.21 4.15 3 5.19l1-1.74c-1.19-.7-2-1.97-2-3.45 0-2.21 1.79-4 4-4s4 1.79 4 4c0 1.48-.81 2.75-2 3.45l1 1.74c1.79-1.04 3-2.97 3-5.19zm-6-10c-5.52 0-10 4.48-10 10 0 3.7 2.01 6.92 4.99 8.65l1-1.73c-2.38-1.39-3.99-3.96-3.99-6.92 0-4.42 3.58-8 8-8s8 3.58 8 8c0 2.96-1.61 5.53-4 6.92l1 1.73c2.99-1.73 5-4.95 5-8.65 0-5.52-4.48-10-10-10z" />
    </g>
  ),
  'attach-file': (
    <g>
      <path d="M16.5 6v11.5c0 2.21-1.79 4-4 4s-4-1.79-4-4v-12.5c0-1.38 1.12-2.5 2.5-2.5s2.5 1.12 2.5 2.5v10.5c0 .55-.45 1-1 1s-1-.45-1-1v-9.5h-1.5v9.5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5v-10.5c0-2.21-1.79-4-4-4s-4 1.79-4 4v12.5c0 3.04 2.46 5.5 5.5 5.5s5.5-2.46 5.5-5.5v-11.5h-1.5z" />
    </g>
  ),
  'attach-money': (
    <g>
      <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81v-2.19h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83v2.17h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" />
    </g>
  ),
  'border-all': (
    <g>
      <path d="M3 3v18h18v-18h-18zm8 16h-6v-6h6v6zm0-8h-6v-6h6v6zm8 8h-6v-6h6v6zm0-8h-6v-6h6v6z" />
    </g>
  ),
  'border-bottom': (
    <g>
      <path d="M9 11h-2v2h2v-2zm4 4h-2v2h2v-2zm-4-12h-2v2h2v-2zm4 8h-2v2h2v-2zm-8-8h-2v2h2v-2zm8 4h-2v2h2v-2zm4 4h-2v2h2v-2zm-4-8h-2v2h2v-2zm4 0h-2v2h2v-2zm2 10h2v-2h-2v2zm0 4h2v-2h-2v2zm-14-10h-2v2h2v-2zm14-4v2h2v-2h-2zm0 6h2v-2h-2v2zm-14 2h-2v2h2v-2zm-2 10h18v-2h-18v2zm2-6h-2v2h2v-2z" />
    </g>
  ),
  'border-clear': (
    <g>
      <path d="M7 5h2v-2h-2v2zm0 8h2v-2h-2v2zm0 8h2v-2h-2v2zm4-4h2v-2h-2v2zm0 4h2v-2h-2v2zm-8 0h2v-2h-2v2zm0-4h2v-2h-2v2zm0-4h2v-2h-2v2zm0-4h2v-2h-2v2zm0-4h2v-2h-2v2zm8 8h2v-2h-2v2zm8 4h2v-2h-2v2zm0-4h2v-2h-2v2zm0 8h2v-2h-2v2zm0-12h2v-2h-2v2zm-8 0h2v-2h-2v2zm8-6v2h2v-2h-2zm-8 2h2v-2h-2v2zm4 16h2v-2h-2v2zm0-8h2v-2h-2v2zm0-8h2v-2h-2v2z" />
    </g>
  ),
  'border-color': (
    <g>
      <path d="M17.75 7l-3.75-3.75-10 10v3.75h3.75l10-10zm2.96-2.96c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.96 1.96 3.75 3.75 1.96-1.96z" />
      <path fillOpacity=".36" d="M0 20h24v4h-24z" />
    </g>
  ),
  'border-horizontal': (
    <g>
      <path d="M3 21h2v-2h-2v2zm2-14h-2v2h2v-2zm-2 10h2v-2h-2v2zm4 4h2v-2h-2v2zm-2-18h-2v2h2v-2zm4 0h-2v2h2v-2zm8 0h-2v2h2v-2zm-4 4h-2v2h2v-2zm0-4h-2v2h2v-2zm6 14h2v-2h-2v2zm-8 4h2v-2h-2v2zm-8-8h18v-2h-18v2zm16-10v2h2v-2h-2zm0 6h2v-2h-2v2zm-8 8h2v-2h-2v2zm4 4h2v-2h-2v2zm4 0h2v-2h-2v2z" />
    </g>
  ),
  'border-inner': (
    <g>
      <path d="M3 21h2v-2h-2v2zm4 0h2v-2h-2v2zm-2-14h-2v2h2v-2zm-2 10h2v-2h-2v2zm6-14h-2v2h2v-2zm-4 0h-2v2h2v-2zm12 0h-2v2h2v-2zm2 6h2v-2h-2v2zm0-6v2h2v-2h-2zm-4 18h2v-2h-2v2zm-2-18h-2v8h-8v2h8v8h2v-8h8v-2h-8v-8zm6 18h2v-2h-2v2zm0-4h2v-2h-2v2z" />
    </g>
  ),
  'border-left': (
    <g>
      <path d="M11 21h2v-2h-2v2zm0-4h2v-2h-2v2zm0-12h2v-2h-2v2zm0 4h2v-2h-2v2zm0 4h2v-2h-2v2zm-4 8h2v-2h-2v2zm0-16h2v-2h-2v2zm0 8h2v-2h-2v2zm-4 8h2v-18h-2v18zm16-12h2v-2h-2v2zm-4 12h2v-2h-2v2zm4-4h2v-2h-2v2zm0-14v2h2v-2h-2zm0 10h2v-2h-2v2zm0 8h2v-2h-2v2zm-4-8h2v-2h-2v2zm0-8h2v-2h-2v2z" />
    </g>
  ),
  'border-outer': (
    <g>
      <path d="M13 7h-2v2h2v-2zm0 4h-2v2h2v-2zm4 0h-2v2h2v-2zm-14-8v18h18v-18h-18zm16 16h-14v-14h14v14zm-6-4h-2v2h2v-2zm-4-4h-2v2h2v-2z" />
    </g>
  ),
  'border-right': (
    <g>
      <path d="M7 21h2v-2h-2v2zm-4-16h2v-2h-2v2zm4 0h2v-2h-2v2zm0 8h2v-2h-2v2zm-4 8h2v-2h-2v2zm8 0h2v-2h-2v2zm-8-8h2v-2h-2v2zm0 4h2v-2h-2v2zm0-8h2v-2h-2v2zm8 8h2v-2h-2v2zm4-4h2v-2h-2v2zm4-10v18h2v-18h-2zm-4 18h2v-2h-2v2zm0-16h2v-2h-2v2zm-4 8h2v-2h-2v2zm0-8h2v-2h-2v2zm0 4h2v-2h-2v2z" />
    </g>
  ),
  'border-style': (
    <g>
      <path d="M15 21h2v-2h-2v2zm4 0h2v-2h-2v2zm-12 0h2v-2h-2v2zm4 0h2v-2h-2v2zm8-4h2v-2h-2v2zm0-4h2v-2h-2v2zm-16-10v18h2v-16h16v-2h-18zm16 6h2v-2h-2v2z" />
    </g>
  ),
  'border-top': (
    <g>
      <path d="M7 21h2v-2h-2v2zm0-8h2v-2h-2v2zm4 0h2v-2h-2v2zm0 8h2v-2h-2v2zm-8-4h2v-2h-2v2zm0 4h2v-2h-2v2zm0-8h2v-2h-2v2zm0-4h2v-2h-2v2zm8 8h2v-2h-2v2zm8-8h2v-2h-2v2zm0 4h2v-2h-2v2zm-16-10v2h18v-2h-18zm16 14h2v-2h-2v2zm-4 4h2v-2h-2v2zm-4-12h2v-2h-2v2zm8 12h2v-2h-2v2zm-4-8h2v-2h-2v2z" />
    </g>
  ),
  'border-vertical': (
    <g>
      <path d="M3 9h2v-2h-2v2zm0-4h2v-2h-2v2zm4 16h2v-2h-2v2zm0-8h2v-2h-2v2zm-4 0h2v-2h-2v2zm0 8h2v-2h-2v2zm0-4h2v-2h-2v2zm4-12h2v-2h-2v2zm12 12h2v-2h-2v2zm-8 4h2v-18h-2v18zm8 0h2v-2h-2v2zm0-8h2v-2h-2v2zm0-10v2h2v-2h-2zm0 6h2v-2h-2v2zm-4-4h2v-2h-2v2zm0 16h2v-2h-2v2zm0-8h2v-2h-2v2z" />
    </g>
  ),
  'format-align-center': (
    <g>
      <path d="M7 15v2h10v-2h-10zm-4 6h18v-2h-18v2zm0-8h18v-2h-18v2zm4-6v2h10v-2h-10zm-4-4v2h18v-2h-18z" />
    </g>
  ),
  'format-align-justify': (
    <g>
      <path d="M3 21h18v-2h-18v2zm0-4h18v-2h-18v2zm0-4h18v-2h-18v2zm0-4h18v-2h-18v2zm0-6v2h18v-2h-18z" />
    </g>
  ),
  'format-align-left': (
    <g>
      <path d="M15 15h-12v2h12v-2zm0-8h-12v2h12v-2zm-12 6h18v-2h-18v2zm0 8h18v-2h-18v2zm0-18v2h18v-2h-18z" />
    </g>
  ),
  'format-align-right': (
    <g>
      <path d="M3 21h18v-2h-18v2zm6-4h12v-2h-12v2zm-6-4h18v-2h-18v2zm6-4h12v-2h-12v2zm-6-6v2h18v-2h-18z" />
    </g>
  ),
  'format-bold': (
    <g>
      <path d="M15.6 10.79c.97-.67 1.65-1.77 1.65-2.79 0-2.26-1.75-4-4-4h-6.25v14h7.04c2.09 0 3.71-1.7 3.71-3.79 0-1.52-.86-2.82-2.15-3.42zm-5.6-4.29h3c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5h-3v-3zm3.5 9h-3.5v-3h3.5c.83 0 1.5.67 1.5 1.5s-.67 1.5-1.5 1.5z" />
    </g>
  ),
  'format-clear': (
    <g>
      <path d="M3.27 5l-1.27 1.27 6.97 6.97-2.47 5.76h3l1.57-3.66 5.66 5.66 1.27-1.27-14.45-14.46-.28-.27zm2.73 0v.18l2.82 2.82h2.4l-.72 1.68 2.1 2.1 1.61-3.78h5.79v-3h-14z" />
    </g>
  ),
  'format-color-fill': (
    <g>
      <path d="M16.56 8.94l-8.94-8.94-1.41 1.41 2.38 2.38-5.15 5.15c-.59.59-.59 1.54 0 2.12l5.5 5.5c.29.29.68.44 1.06.44s.77-.15 1.06-.44l5.5-5.5c.59-.58.59-1.53 0-2.12zm-11.35 1.06l4.79-4.79 4.79 4.79h-9.58zm13.79 1.5s-2 2.17-2 3.5c0 1.1.9 2 2 2s2-.9 2-2c0-1.33-2-3.5-2-3.5z" />
      <path fillOpacity=".36" d="M0 20h24v4h-24z" />
    </g>
  ),
  'format-color-reset': (
    <g>
      <path d="M18 14c0-4-6-10.8-6-10.8s-1.33 1.51-2.73 3.52l8.59 8.59c.09-.42.14-.86.14-1.31zm-.88 3.12l-4.62-4.62-7.23-7.23-1.27 1.28 3.32 3.32c-.77 1.45-1.32 2.92-1.32 4.13 0 3.31 2.69 6 6 6 1.52 0 2.9-.57 3.96-1.5l2.63 2.63 1.27-1.27-2.74-2.74z" />
    </g>
  ),
  'format-color-text': (
    <g>
      <path fillOpacity=".36" d="M0 20h24v4h-24z" />
      <path d="M11 3l-5.5 14h2.25l1.12-3h6.25l1.12 3h2.25l-5.49-14h-2zm-1.38 9l2.38-6.33 2.38 6.33h-4.76z" />
    </g>
  ),
  'format-indent-decrease': (
    <g>
      <path d="M11 17h10v-2h-10v2zm-8-5l4 4v-8l-4 4zm0 9h18v-2h-18v2zm0-18v2h18v-2h-18zm8 6h10v-2h-10v2zm0 4h10v-2h-10v2z" />
    </g>
  ),
  'format-indent-increase': (
    <g>
      <path d="M3 21h18v-2h-18v2zm0-13v8l4-4-4-4zm8 9h10v-2h-10v2zm-8-14v2h18v-2h-18zm8 6h10v-2h-10v2zm0 4h10v-2h-10v2z" />
    </g>
  ),
  'format-italic': (
    <g>
      <path d="M10 4v3h2.21l-3.42 8h-2.79v3h8v-3h-2.21l3.42-8h2.79v-3z" />
    </g>
  ),
  'format-line-spacing': (
    <g>
      <path d="M6 7h2.5l-3.5-3.5-3.5 3.5h2.5v10h-2.5l3.5 3.5 3.5-3.5h-2.5v-10zm4-2v2h12v-2h-12zm0 14h12v-2h-12v2zm0-6h12v-2h-12v2z" />
    </g>
  ),
  'format-list-bulleted': (
    <g>
      <path d="M4 10.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-6c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0 12.17c-.74 0-1.33.6-1.33 1.33s.6 1.33 1.33 1.33 1.33-.6 1.33-1.33-.59-1.33-1.33-1.33zm3 2.33h14v-2h-14v2zm0-6h14v-2h-14v2zm0-8v2h14v-2h-14z" />
    </g>
  ),
  'format-list-numbered': (
    <g>
      <path d="M2 17h2v.5h-1v1h1v.5h-2v1h3v-4h-3v1zm1-9h1v-4h-2v1h1v3zm-1 3h1.8l-1.8 2.1v.9h3v-1h-1.8l1.8-2.1v-.9h-3v1zm5-6v2h14v-2h-14zm0 14h14v-2h-14v2zm0-6h14v-2h-14v2z" />
    </g>
  ),
  'format-paint': (
    <g>
      <path d="M18 4v-1c0-.55-.45-1-1-1h-12c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-1h1v4h-10v11c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-9h8v-8h-3z" />
    </g>
  ),
  'format-quote': (
    <g>
      <path d="M6 17h3l2-4v-6h-6v6h3zm8 0h3l2-4v-6h-6v6h3z" />
    </g>
  ),
  'format-size': (
    <g>
      <path d="M9 4v3h5v12h3v-12h5v-3h-13zm-6 8h3v7h3v-7h3v-3h-9v3z" />
    </g>
  ),
  'format-strikethrough': (
    <g>
      <path d="M10 19h4v-3h-4v3zm-5-15v3h5v3h4v-3h5v-3h-14zm-2 10h18v-2h-18v2z" />
    </g>
  ),
  'format-textdirection-l-to-r': (
    <g>
      <path d="M18 4h-12v2l6.5 6-6.5 6v2h12v-3h-7l5-5-5-5h7z" />
    </g>
  ),
  'format-textdirection-r-to-l': (
    <g>
      <path d="M9 10v5h2v-11h2v11h2v-11h2v-2h-8c-2.21 0-4 1.79-4 4s1.79 4 4 4zm12 8l-4-4v3h-12v2h12v3l4-4z" />
    </g>
  ),
  'format-underline': (
    <g>
      <path d="M12 17c3.31 0 6-2.69 6-6v-8h-2.5v8c0 1.93-1.57 3.5-3.5 3.5s-3.5-1.57-3.5-3.5v-8h-2.5v8c0 3.31 2.69 6 6 6zm-7 2v2h14v-2h-14z" />
    </g>
  ),
  functions: (
    <g>
      <path d="M10 10v5h2v-11h2v11h2v-11h2v-2h-8c-2.21 0-4 1.79-4 4s1.79 4 4 4zm-2 7v-3l-4 4 4 4v-3h12v-2h-12z" />
    </g>
  ),
  'insert-chart': (
    <g>
      <path d="M19 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-10 14h-2v-7h2v7zm4 0h-2v-10h2v10zm4 0h-2v-4h2v4z" />
    </g>
  ),
  'insert-comment': (
    <g>
      <path d="M20 2h-16c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4v-18c0-1.1-.9-2-2-2zm-2 12h-12v-2h12v2zm0-3h-12v-2h12v2zm0-3h-12v-2h12v2z" />
    </g>
  ),
  'insert-drive-file': (
    <g>
      <path d="M6 2c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.89 2 1.99 2h12.01c1.1 0 2-.9 2-2v-12l-6-6h-8zm7 7v-5.5l5.5 5.5h-5.5z" />
    </g>
  ),
  'insert-emoticon': (
    <g>
      <path d="M11.99 2c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10zm.01 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5h-10.22c.8 2.04 2.78 3.5 5.11 3.5z" />
    </g>
  ),
  'insert-invitation': (
    <g>
      <path d="M17 12h-5v5h5v-5zm-1-11v2h-8v-2h-2v2h-1c-1.11 0-1.99.9-1.99 2l-.01 14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2h-1v-2h-2zm3 18h-14v-11h14v11z" />
    </g>
  ),
  'insert-link': (
    <g>
      <path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4v-1.9h-4c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9h-4c-1.71 0-3.1-1.39-3.1-3.1zm4.1 1h8v-2h-8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4v1.9h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z" />
    </g>
  ),
  'insert-photo': (
    <g>
      <path d="M21 19v-14c0-1.1-.9-2-2-2h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-12.5-5.5l2.5 3.01 3.5-4.51 4.5 6h-14l3.5-4.5z" />
    </g>
  ),
  'merge-type': (
    <g>
      <path d="M17 20.41l1.41-1.41-3.41-3.41-1.41 1.41 3.41 3.41zm-9.5-12.41h3.5v5.59l-5.41 5.41 1.41 1.41 6-6v-6.41h3.5l-4.5-4.5-4.5 4.5z" />
    </g>
  ),
  'mode-comment': (
    <g>
      <path d="M21.99 4c0-1.1-.89-2-1.99-2h-16c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18z" />
    </g>
  ),
  'mode-edit': (
    <g>
      <path d="M3 17.25v3.75h3.75l11.06-11.06-3.75-3.75-11.06 11.06zm17.71-10.21c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
    </g>
  ),
  publish: (
    <g>
      <path d="M5 4v2h14v-2h-14zm0 10h4v6h6v-6h4l-7-7-7 7z" />
    </g>
  ),
  'vertical-align-bottom': (
    <g>
      <path d="M16 13h-3v-10h-2v10h-3l4 4 4-4zm-12 6v2h16v-2h-16z" />
    </g>
  ),
  'vertical-align-center': (
    <g>
      <path d="M8 19h3v4h2v-4h3l-4-4-4 4zm8-14h-3v-4h-2v4h-3l4 4 4-4zm-12 6v2h16v-2h-16z" />
    </g>
  ),
  'vertical-align-top': (
    <g>
      <path d="M8 11h3v10h2v-10h3l-4-4-4 4zm-4-8v2h16v-2h-16z" />
    </g>
  ),
  'wrap-text': (
    <g>
      <path d="M4 19h6v-2h-6v2zm16-14h-16v2h16v-2zm-3 6h-13v2h13.25c1.1 0 2 .9 2 2s-.9 2-2 2h-2.25v-2l-3 3 3 3v-2h2c2.21 0 4-1.79 4-4s-1.79-4-4-4z" />
    </g>
  ),
  cast: (
    <g>
      <path d="M21 3h-18c-1.1 0-2 .9-2 2v3h2v-3h18v14h-7v2h7c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-20 15v3h3c0-1.66-1.34-3-3-3zm0-4v2c2.76 0 5 2.24 5 5h2c0-3.87-3.13-7-7-7zm0-4v2c4.97 0 9 4.03 9 9h2c0-6.08-4.93-11-11-11z" />
    </g>
  ),
  'cast-connected': (
    <g>
      <path d="M1 18v3h3c0-1.66-1.34-3-3-3zm0-4v2c2.76 0 5 2.24 5 5h2c0-3.87-3.13-7-7-7zm18-7h-14v1.63c3.96 1.28 7.09 4.41 8.37 8.37h5.63v-10zm-18 3v2c4.97 0 9 4.03 9 9h2c0-6.08-4.93-11-11-11zm20-7h-18c-1.1 0-2 .9-2 2v3h2v-3h18v14h-7v2h7c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2z" />
    </g>
  ),
  chromecast: (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 2c2.96 0 5.54 1.61 6.92 4h-6.92c-1.94 0-3.55 1.38-3.92 3.21l-2.38-4.13c1.46-1.87 3.74-3.08 6.3-3.08zm3 8c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-11 0c0-1.46.4-2.82 1.08-3.99l3.46 6 .01-.01c.69 1.19 1.97 2 3.45 2 .45 0 .88-.09 1.29-.23l-2.39 4.15c-3.9-.54-6.9-3.88-6.9-7.92zm8 8l3.46-6-.02-.01c.35-.59.56-1.26.56-1.99 0-1.2-.54-2.27-1.38-3h4.79c.38.93.59 1.94.59 3 0 4.42-3.58 8-8 8z" />
    </g>
  ),
  computer: (
    <g>
      <path d="M20 18c1.1 0 1.99-.9 1.99-2l.01-10c0-1.1-.9-2-2-2h-16c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h-4v2h24v-2h-4zm-16-12h16v10h-16v-10z" />
    </g>
  ),
  'desktop-mac': (
    <g>
      <path d="M21 2h-18c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h7l-2 3v1h8v-1l-2-3h7c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm0 12h-18v-10h18v10z" />
    </g>
  ),
  'desktop-windows': (
    <g>
      <path d="M21 2h-18c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h7v2h-2v2h8v-2h-2v-2h7c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm0 14h-18v-12h18v12z" />
    </g>
  ),
  dock: (
    <g>
      <path d="M8 23h8v-2h-8v2zm8-21.99l-8-.01c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-14c0-1.1-.9-1.99-2-1.99zm0 13.99h-8v-10h8v10z" />
    </g>
  ),
  gamepad: (
    <g>
      <path d="M15 7.5v-5.5h-6v5.5l3 3 3-3zm-7.5 1.5h-5.5v6h5.5l3-3-3-3zm1.5 7.5v5.5h6v-5.5l-3-3-3 3zm7.5-7.5l-3 3 3 3h5.5v-6h-5.5z" />
    </g>
  ),
  headset: (
    <g>
      <path d="M12 1c-4.97 0-9 4.03-9 9v7c0 1.66 1.34 3 3 3h3v-8h-4v-2c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h3c1.66 0 3-1.34 3-3v-7c0-4.97-4.03-9-9-9z" />
    </g>
  ),
  'headset-mic': (
    <g>
      <path d="M12 1c-4.97 0-9 4.03-9 9v7c0 1.66 1.34 3 3 3h3v-8h-4v-2c0-3.87 3.13-7 7-7s7 3.13 7 7v2h-4v8h4v1h-7v2h6c1.66 0 3-1.34 3-3v-10c0-4.97-4.03-9-9-9z" />
    </g>
  ),
  keyboard: (
    <g>
      <path d="M20 5h-16c-1.1 0-1.99.9-1.99 2l-.01 10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm-9 3h2v2h-2v-2zm0 3h2v2h-2v-2zm-3-3h2v2h-2v-2zm0 3h2v2h-2v-2zm-1 2h-2v-2h2v2zm0-3h-2v-2h2v2zm9 7h-8v-2h8v2zm0-4h-2v-2h2v2zm0-3h-2v-2h2v2zm3 3h-2v-2h2v2zm0-3h-2v-2h2v2z" />
    </g>
  ),
  'keyboard-alt': (
    <g>
      <path d="M15.5 10c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm3.5 7c2.61 0 4.83-1.67 5.65-4h-11.3c.82 2.33 3.04 4 5.65 4zm-.01-16c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10zm.01 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    </g>
  ),
  'keyboard-arrow-down': (
    <g>
      <path d="M7.41 7.84l4.59 4.58 4.59-4.58 1.41 1.41-6 6-6-6z" />
    </g>
  ),
  'keyboard-arrow-left': (
    <g>
      <path d="M15.41 16.09l-4.58-4.59 4.58-4.59-1.41-1.41-6 6 6 6z" />
    </g>
  ),
  'keyboard-arrow-right': (
    <g>
      <path d="M8.59 16.34l4.58-4.59-4.58-4.59 1.41-1.41 6 6-6 6z" />
    </g>
  ),
  'keyboard-arrow-up': (
    <g>
      <path d="M7.41 15.41l4.59-4.58 4.59 4.58 1.41-1.41-6-6-6 6z" />
    </g>
  ),
  'keyboard-backspace': (
    <g>
      <path d="M21 11h-14.17l3.58-3.59-1.41-1.41-6 6 6 6 1.41-1.41-3.58-3.59h14.17z" />
    </g>
  ),
  'keyboard-capslock': (
    <g>
      <path d="M12 8.41l4.59 4.59 1.41-1.41-6-6-6 6 1.41 1.41 4.59-4.59zm-6 9.59h12v-2h-12v2z" />
    </g>
  ),
  'keyboard-control': (
    <g>
      <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </g>
  ),
  'keyboard-hide': (
    <g>
      <path d="M20 3h-16c-1.1 0-1.99.9-1.99 2l-.01 10c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm-9 3h2v2h-2v-2zm0 3h2v2h-2v-2zm-3-3h2v2h-2v-2zm0 3h2v2h-2v-2zm-1 2h-2v-2h2v2zm0-3h-2v-2h2v2zm9 7h-8v-2h8v2zm0-4h-2v-2h2v2zm0-3h-2v-2h2v2zm3 3h-2v-2h2v2zm0-3h-2v-2h2v2zm-7 15l4-4h-8l4 4z" />
    </g>
  ),
  'keyboard-return': (
    <g>
      <path d="M19 7v4h-13.17l3.58-3.59-1.41-1.41-6 6 6 6 1.41-1.41-3.58-3.59h15.17v-6z" />
    </g>
  ),
  'keyboard-tab': (
    <g>
      <path d="M11.59 7.41l3.58 3.59h-14.17v2h14.17l-3.59 3.59 1.42 1.41 6-6-6-6-1.41 1.41zm8.41-1.41v12h2v-12h-2z" />
    </g>
  ),
  'keyboard-voice': (
    <g>
      <path d="M12 15c1.66 0 2.99-1.34 2.99-3l.01-6c0-1.66-1.34-3-3-3s-3 1.34-3 3v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1s-5.3-2.1-5.3-5.1h-1.7c0 3.42 2.72 6.23 6 6.72v3.28h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z" />
    </g>
  ),
  laptop: (
    <g>
      <path d="M20 18c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2h-16c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h-4v2h24v-2h-4zm-16-12h16v10h-16v-10z" />
    </g>
  ),
  'laptop-chromebook': (
    <g>
      <path d="M22 18v-15h-20v15h-2v2h24v-2h-2zm-8 0h-4v-1h4v1zm6-3h-16v-10h16v10z" />
    </g>
  ),
  'laptop-mac': (
    <g>
      <path d="M20 18c1.1 0 1.99-.9 1.99-2l.01-11c0-1.1-.9-2-2-2h-16c-1.1 0-2 .9-2 2v11c0 1.1.9 2 2 2h-4c0 1.1.9 2 2 2h20c1.1 0 2-.9 2-2h-4zm-16-13h16v11h-16v-11zm8 14c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
    </g>
  ),
  'laptop-windows': (
    <g>
      <path d="M20 18v-1c1.1 0 1.99-.9 1.99-2l.01-10c0-1.1-.9-2-2-2h-16c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2v1h-4v2h24v-2h-4zm-16-13h16v10h-16v-10z" />
    </g>
  ),
  memory: (
    <g>
      <path d="M15 9h-6v6h6v-6zm-2 4h-2v-2h2v2zm8-2v-2h-2v-2c0-1.1-.9-2-2-2h-2v-2h-2v2h-2v-2h-2v2h-2c-1.1 0-2 .9-2 2v2h-2v2h2v2h-2v2h2v2c0 1.1.9 2 2 2h2v2h2v-2h2v2h2v-2h2c1.1 0 2-.9 2-2v-2h2v-2h-2v-2h2zm-4 6h-10v-10h10v10z" />
    </g>
  ),
  mouse: (
    <g>
      <path d="M13 1.07v7.93h7c0-4.08-3.05-7.44-7-7.93zm-9 13.93c0 4.42 3.58 8 8 8s8-3.58 8-8v-4h-16v4zm7-13.93c-3.95.49-7 3.85-7 7.93h7v-7.93z" />
    </g>
  ),
  'phone-android': (
    <g>
      <path d="M16 1h-8c-1.66 0-3 1.34-3 3v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3v-16c0-1.66-1.34-3-3-3zm-2 20h-4v-1h4v1zm3.25-3h-10.5v-14h10.5v14z" />
    </g>
  ),
  'phone-iphone': (
    <g>
      <path d="M15.5 1h-8c-1.38 0-2.5 1.12-2.5 2.5v17c0 1.38 1.12 2.5 2.5 2.5h8c1.38 0 2.5-1.12 2.5-2.5v-17c0-1.38-1.12-2.5-2.5-2.5zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4h-9v-14h9v14z" />
    </g>
  ),
  phonelink: (
    <g>
      <path d="M4 6h18v-2h-18c-1.1 0-2 .9-2 2v11h-2v3h14v-3h-10v-11zm19 2h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1v-10c0-.55-.45-1-1-1zm-1 9h-4v-7h4v7z" />
    </g>
  ),
  'phonelink-off': (
    <g>
      <path d="M22 6v-2h-15.18l2 2h13.18zm-20.08-4.35l-1.27 1.27 1.82 1.82c-.29.34-.47.78-.47 1.26v11h-2v3h17.73l2.35 2.35 1.27-1.27-17.46-17.46-1.97-1.97zm2.08 4.62l10.73 10.73h-10.73v-10.73zm19 1.73h-6c-.55 0-1 .45-1 1v4.18l2 2v-5.18h4v7h-2.18l3 3h.18c.55 0 1-.45 1-1v-10c0-.55-.45-1-1-1z" />
    </g>
  ),
  security: (
    <g>
      <path d="M12 1l-9 4v6c0 5.55 3.84 10.74 9 12 5.16-1.26 9-6.45 9-12v-6l-9-4zm0 10.99h7c-.53 4.12-3.28 7.79-7 8.94v-8.93h-7v-5.7l7-3.11v8.8z" />
    </g>
  ),
  'sim-card': (
    <g>
      <path d="M19.99 4c0-1.1-.89-2-1.99-2h-8l-6 6v12c0 1.1.9 2 2 2h12.01c1.1 0 1.99-.9 1.99-2l-.01-16zm-10.99 15h-2v-2h2v2zm8 0h-2v-2h2v2zm-8-4h-2v-4h2v4zm4 4h-2v-4h2v4zm0-6h-2v-2h2v2zm4 2h-2v-4h2v4z" />
    </g>
  ),
  smartphone: (
    <g>
      <path d="M17 1.01l-10-.01c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-18c0-1.1-.9-1.99-2-1.99zm0 17.99h-10v-14h10v14z" />
    </g>
  ),
  speaker: (
    <g>
      <path d="M17 2h-10c-1.1 0-2 .9-2 2v16c0 1.1.9 1.99 2 1.99l10 .01c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-5 2c1.1 0 2 .9 2 2s-.9 2-2 2c-1.11 0-2-.9-2-2s.89-2 2-2zm0 16c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
    </g>
  ),
  tablet: (
    <g>
      <path d="M21 4h-18c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h18c1.1 0 1.99-.9 1.99-2l.01-12c0-1.1-.9-2-2-2zm-2 14h-14v-12h14v12z" />
    </g>
  ),
  'tablet-android': (
    <g>
      <path d="M18 0h-12c-1.66 0-3 1.34-3 3v18c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3v-18c0-1.66-1.34-3-3-3zm-4 22h-4v-1h4v1zm5.25-3h-14.5v-16h14.5v16z" />
    </g>
  ),
  'tablet-mac': (
    <g>
      <path d="M18.5 0h-14c-1.38 0-2.5 1.12-2.5 2.5v19c0 1.38 1.12 2.5 2.5 2.5h14c1.38 0 2.5-1.12 2.5-2.5v-19c0-1.38-1.12-2.5-2.5-2.5zm-7 23c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm7.5-4h-15v-16h15v16z" />
    </g>
  ),
  tv: (
    <g>
      <path d="M21 3h-18c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h5v2h8v-2h5c1.1 0 1.99-.9 1.99-2l.01-12c0-1.1-.9-2-2-2zm0 14h-18v-12h18v12z" />
    </g>
  ),
  watch: (
    <g>
      <path d="M20 12c0-2.54-1.19-4.81-3.04-6.27l-.96-5.73h-8l-.95 5.73c-1.86 1.46-3.05 3.72-3.05 6.27s1.19 4.81 3.05 6.27l.95 5.73h8l.96-5.73c1.85-1.46 3.04-3.73 3.04-6.27zm-14 0c0-3.31 2.69-6 6-6s6 2.69 6 6-2.69 6-6 6-6-2.69-6-6z" />
    </g>
  ),
  'add-to-photos': (
    <g>
      <path d="M4 6h-2v14c0 1.1.9 2 2 2h14v-2h-14v-14zm16-4h-12c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4h-4v-2h4v-4h2v4h4v2z" />
    </g>
  ),
  adjust: (
    <g>
      <path d="M12 2c-5.51 0-10 4.49-10 10s4.49 10 10 10 10-4.49 10-10-4.49-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3-8c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3z" />
    </g>
  ),
  'assistant-photo': (
    <g>
      <path d="M14.4 6l-.4-2h-9v17h2v-7h5.6l.4 2h7v-10z" />
    </g>
  ),
  audiotrack: (
    <g>
      <path d="M12 3v9.28c-.47-.17-.97-.28-1.5-.28-2.49 0-4.5 2.01-4.5 4.5s2.01 4.5 4.5 4.5c2.31 0 4.2-1.75 4.45-4h.05v-11h4v-3h-7z" />
    </g>
  ),
  'blur-circular': (
    <g>
      <path d="M10 9c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm-3-3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3 7c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-3-3c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3-6c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm4 1.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-1.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm3 6c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-4c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-5-7.5c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm2-3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-3.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z" />
    </g>
  ),
  'blur-linear': (
    <g>
      <path d="M5 17.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm4-4.5c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm-6 12h18v-2h-18v2zm2-11.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm0 4c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm4 3.5c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm8-.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm-14-13.5v2h18v-2h-18zm14 5.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm0 4c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm-4-3.5c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1z" />
    </g>
  ),
  'blur-off': (
    <g>
      <path d="M14 7c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm-.2 4.48l.2.02c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5l.02.2c.09.67.61 1.19 1.28 1.28zm.2-7.98c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm-4 0c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm11 7c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm-11-3.5c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm8 8c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-4c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm-4 13.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-11.5-15.23l3.78 3.78-.28-.05c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1c0-.1-.03-.19-.06-.28l2.81 2.81c-.71.11-1.25.73-1.25 1.47 0 .83.67 1.5 1.5 1.5.74 0 1.36-.54 1.47-1.25l2.81 2.81c-.09-.03-.18-.06-.28-.06-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1c0-.1-.03-.19-.06-.28l3.78 3.78 1.28-1.27-16.23-16.23-1.27 1.27zm7.5 11.73c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm11-3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-15-.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm-3-3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm7 11c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-4-3.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm-3-3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5z" />
    </g>
  ),
  'blur-on': (
    <g>
      <path d="M6 13c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-8c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm-3 .5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm3-4.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm15 5.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm-7-3.5c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0-3.5c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm-11 10c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm7 7c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm0-17c.28 0 .5-.22.5-.5s-.22-.5-.5-.5-.5.22-.5.5.22.5.5.5zm0 3.5c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1zm0 5.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm8 .5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-8c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-4c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm3 8.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-7 3.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0 3.5c-.28 0-.5.22-.5.5s.22.5.5.5.5-.22.5-.5-.22-.5-.5-.5zm-4-12c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0 8.5c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm4-4.5c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-4c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5z" />
    </g>
  ),
  'brightness-1': (
    <g>
      <circle cx="12" cy="12" r="10" />
    </g>
  ),
  'brightness-2': (
    <g>
      <path d="M10 2c-1.82 0-3.53.5-5 1.35 2.99 1.73 5 4.95 5 8.65s-2.01 6.92-5 8.65c1.47.85 3.18 1.35 5 1.35 5.52 0 10-4.48 10-10s-4.48-10-10-10z" />
    </g>
  ),
  'brightness-3': (
    <g>
      <path d="M9 2c-1.05 0-2.05.16-3 .46 4.06 1.27 7 5.06 7 9.54 0 4.48-2.94 8.27-7 9.54.95.3 1.95.46 3 .46 5.52 0 10-4.48 10-10s-4.48-10-10-10z" />
    </g>
  ),
  'brightness-4': (
    <g>
      <path d="M20 8.69v-4.69h-4.69l-3.31-3.31-3.31 3.31h-4.69v4.69l-3.31 3.31 3.31 3.31v4.69h4.69l3.31 3.31 3.31-3.31h4.69v-4.69l3.31-3.31-3.31-3.31zm-8 9.31c-.89 0-1.74-.2-2.5-.55 2.06-.95 3.5-3.03 3.5-5.45s-1.44-4.5-3.5-5.45c.76-.35 1.61-.55 2.5-.55 3.31 0 6 2.69 6 6s-2.69 6-6 6z" />
    </g>
  ),
  'brightness-5': (
    <g>
      <path d="M20 15.31l3.31-3.31-3.31-3.31v-4.69h-4.69l-3.31-3.31-3.31 3.31h-4.69v4.69l-3.31 3.31 3.31 3.31v4.69h4.69l3.31 3.31 3.31-3.31h4.69v-4.69zm-8 2.69c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z" />
    </g>
  ),
  'brightness-6': (
    <g>
      <path d="M20 15.31l3.31-3.31-3.31-3.31v-4.69h-4.69l-3.31-3.31-3.31 3.31h-4.69v4.69l-3.31 3.31 3.31 3.31v4.69h4.69l3.31 3.31 3.31-3.31h4.69v-4.69zm-8 2.69v-12c3.31 0 6 2.69 6 6s-2.69 6-6 6z" />
    </g>
  ),
  'brightness-7': (
    <g>
      <path d="M20 8.69v-4.69h-4.69l-3.31-3.31-3.31 3.31h-4.69v4.69l-3.31 3.31 3.31 3.31v4.69h4.69l3.31 3.31 3.31-3.31h4.69v-4.69l3.31-3.31-3.31-3.31zm-8 9.31c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm0-10c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4z" />
    </g>
  ),
  brush: (
    <g>
      <path d="M7 14c-1.66 0-3 1.34-3 3 0 1.31-1.16 2-2 2 .92 1.22 2.49 2 4 2 2.21 0 4-1.79 4-4 0-1.66-1.34-3-3-3zm13.71-9.37l-1.34-1.34c-.39-.39-1.02-.39-1.41 0l-8.96 8.96 2.75 2.75 8.96-8.96c.39-.39.39-1.02 0-1.41z" />
    </g>
  ),
  camera: (
    <g>
      <path d="M9.4 10.5l4.77-8.26c-.7-.15-1.42-.24-2.17-.24-2.4 0-4.6.85-6.32 2.25l3.66 6.35.06-.1zm12.14-1.5c-.92-2.92-3.15-5.26-6-6.34l-3.66 6.34h9.66zm.26 1h-7.49l.29.5 4.76 8.25c1.64-1.78 2.64-4.14 2.64-6.75 0-.69-.07-1.35-.2-2zm-13.26 2l-3.9-6.75c-1.63 1.78-2.64 4.14-2.64 6.75 0 .69.07 1.35.2 2h7.49l-1.15-2zm-6.08 3c.92 2.92 3.15 5.26 6 6.34l3.66-6.34h-9.66zm11.27 0l-3.9 6.76c.7.15 1.42.24 2.17.24 2.4 0 4.6-.85 6.32-2.25l-3.66-6.35-.93 1.6z" />
    </g>
  ),
  'camera-alt': (
    <g>
      <circle cx="12" cy="12" r="3.2" />
      <path d="M9 2l-1.83 2h-3.17c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2h-3.17l-1.83-2h-6zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
    </g>
  ),
  'camera-front': (
    <g>
      <path d="M10 20h-5v2h5v2l3-3-3-3v2zm4 0v2h5v-2h-5zm-2-12c1.1 0 2-.9 2-2s-.9-2-2-2-1.99.9-1.99 2 .89 2 1.99 2zm5-8h-10c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-10 2h10v10.5c0-1.67-3.33-2.5-5-2.5s-5 .83-5 2.5v-10.5z" />
    </g>
  ),
  'camera-rear': (
    <g>
      <path d="M10 20h-5v2h5v2l3-3-3-3v2zm4 0v2h5v-2h-5zm3-20h-10c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-5 6c-1.11 0-2-.9-2-2s.89-2 1.99-2 2 .9 2 2c.01 1.1-.89 2-1.99 2z" />
    </g>
  ),
  'camera-roll': (
    <g>
      <path d="M14 5c0-1.1-.9-2-2-2h-1v-1c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v1h-1c-1.1 0-2 .9-2 2v15c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2h8v-15h-8zm-2 13h-2v-2h2v2zm0-9h-2v-2h2v2zm4 9h-2v-2h2v2zm0-9h-2v-2h2v2zm4 9h-2v-2h2v2zm0-9h-2v-2h2v2z" />
    </g>
  ),
  'center-focus-strong': (
    <g>
      <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm-7 7h-2v4c0 1.1.9 2 2 2h4v-2h-4v-4zm0-10h4v-2h-4c-1.1 0-2 .9-2 2v4h2v-4zm14-2h-4v2h4v4h2v-4c0-1.1-.9-2-2-2zm0 16h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4z" />
    </g>
  ),
  'center-focus-weak': (
    <g>
      <path d="M5 15h-2v4c0 1.1.9 2 2 2h4v-2h-4v-4zm0-10h4v-2h-4c-1.1 0-2 .9-2 2v4h2v-4zm14-2h-4v2h4v4h2v-4c0-1.1-.9-2-2-2zm0 16h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zm-7-11c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
    </g>
  ),
  collections: (
    <g>
      <path d="M22 16v-12c0-1.1-.9-2-2-2h-12c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71 2.97-3.71 4 5h-12l3-4zm-9-6v14c0 1.1.9 2 2 2h14v-2h-14v-14h-2z" />
    </g>
  ),
  colorize: (
    <g>
      <path d="M20.71 5.63l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-3.12 3.12-1.93-1.91-1.41 1.41 1.42 1.42-8.92 8.92v4.75h4.75l8.92-8.92 1.42 1.42 1.41-1.41-1.92-1.92 3.12-3.12c.4-.4.4-1.03.01-1.42zm-13.79 13.37l-1.92-1.92 8.06-8.06 1.92 1.92-8.06 8.06z" />
    </g>
  ),
  'color-lens': (
    <g>
      <path d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5h1.77c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm3-4c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm5 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm3 4c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    </g>
  ),
  compare: (
    <g>
      <path d="M10 3h-5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h5v2h2v-22h-2v2zm0 15h-5l5-6v6zm9-15h-5v2h5v13l-5-6v9h5c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2z" />
    </g>
  ),
  'control-point': (
    <g>
      <path d="M13 7h-2v4h-4v2h4v4h2v-4h4v-2h-4v-4zm-1-5c-5.51 0-10 4.49-10 10s4.49 10 10 10 10-4.49 10-10-4.49-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    </g>
  ),
  'control-point-duplicate': (
    <g>
      <path d="M16 8h-2v3h-3v2h3v3h2v-3h3v-2h-3zm-14 4c0-2.79 1.64-5.2 4.01-6.32v-2.16c-3.49 1.24-6.01 4.57-6.01 8.48s2.52 7.24 6.01 8.48v-2.16c-2.37-1.12-4.01-3.53-4.01-6.32zm13-9c-4.96 0-9 4.04-9 9s4.04 9 9 9 9-4.04 9-9-4.04-9-9-9zm0 16c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z" />
    </g>
  ),
  'crop-16-9': (
    <g>
      <path d="M19 6h-14c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2zm0 10h-14v-8h14v8z" />
    </g>
  ),
  crop: (
    <g>
      <path d="M17 15h2v-8c0-1.1-.9-2-2-2h-8v2h8v8zm-10 2v-16h-2v4h-4v2h4v10c0 1.1.9 2 2 2h10v4h2v-4h4v-2h-16z" />
    </g>
  ),
  'crop-3-2': (
    <g>
      <path d="M19 4h-14c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm0 14h-14v-12h14v12z" />
    </g>
  ),
  'crop-5-4': (
    <g>
      <path d="M19 5h-14c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm0 12h-14v-10h14v10z" />
    </g>
  ),
  'crop-7-5': (
    <g>
      <path d="M19 7h-14c-1.1 0-2 .9-2 2v6c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2zm0 8h-14v-6h14v6z" />
    </g>
  ),
  'crop-din': (
    <g>
      <path d="M19 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14z" />
    </g>
  ),
  'crop-free': (
    <g>
      <path d="M3 5v4h2v-4h4v-2h-4c-1.1 0-2 .9-2 2zm2 10h-2v4c0 1.1.9 2 2 2h4v-2h-4v-4zm14 4h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zm0-16h-4v2h4v4h2v-4c0-1.1-.9-2-2-2z" />
    </g>
  ),
  'crop-landscape': (
    <g>
      <path d="M19 5h-14c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm0 12h-14v-10h14v10z" />
    </g>
  ),
  'crop-original': (
    <g>
      <path d="M19 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14zm-5.04-6.71l-2.75 3.54-1.96-2.36-2.75 3.53h11l-3.54-4.71z" />
    </g>
  ),
  'crop-portrait': (
    <g>
      <path d="M17 3h-10c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-10v-14h10v14z" />
    </g>
  ),
  'crop-square': (
    <g>
      <path d="M18 4h-12c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm0 14h-12v-12h12v12z" />
    </g>
  ),
  dehaze: (
    <g>
      <path d="M2 15.5v2h20v-2h-20zm0-5v2h20v-2h-20zm0-5v2h20v-2h-20z" />
    </g>
  ),
  details: (
    <g>
      <path d="M3 4l9 16 9-16h-18zm3.38 2h11.25l-5.63 10-5.62-10z" />
    </g>
  ),
  edit: (
    <g>
      <path d="M3 17.25v3.75h3.75l11.06-11.06-3.75-3.75-11.06 11.06zm17.71-10.21c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
    </g>
  ),
  exposure: (
    <g>
      <path d="M15 17v2h2v-2h2v-2h-2v-2h-2v2h-2v2h2zm5-15h-16c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-15 3h6v2h-6v-2zm15 15h-16l16-16v16z" />
    </g>
  ),
  'exposure-minus-1': (
    <g>
      <path d="M4 11v2h8v-2h-8zm15 7h-2v-10.62l-3 1.02v-1.7l4.7-1.7h.3v13z" />
    </g>
  ),
  'exposure-minus-2': (
    <g>
      <path d="M15.05 16.29l2.86-3.07c.38-.39.72-.79 1.04-1.18.32-.39.59-.78.82-1.17.23-.39.41-.78.54-1.17s.19-.79.19-1.18c0-.53-.09-1.02-.27-1.46-.18-.44-.44-.81-.78-1.11-.34-.31-.77-.54-1.26-.71-.51-.16-1.08-.24-1.72-.24-.69 0-1.31.11-1.85.32-.54.21-1 .51-1.36.88-.37.37-.65.8-.84 1.3-.18.47-.27.97-.28 1.5h2.14c.01-.31.05-.6.13-.87.09-.29.23-.54.4-.75.18-.21.41-.37.68-.49.27-.12.6-.18.96-.18.31 0 .58.05.81.15.23.1.43.25.59.43.16.18.28.4.37.65.08.25.13.52.13.81 0 .22-.03.43-.08.65-.06.22-.15.45-.29.7-.14.25-.32.53-.56.83-.23.3-.52.65-.88 1.03l-4.17 4.55v1.49h8.63v-1.71h-5.95zm-13.05-5.29v2h8v-2h-8z" />
    </g>
  ),
  'exposure-plus-1': (
    <g>
      <path d="M10 7h-2v4h-4v2h4v4h2v-4h4v-2h-4v-4zm10 11h-2v-10.62l-3 1.02v-1.7l4.7-1.7h.3v13z" />
    </g>
  ),
  'exposure-plus-2': (
    <g>
      <path d="M16.05 16.29l2.86-3.07c.38-.39.72-.79 1.04-1.18.32-.39.59-.78.82-1.17.23-.39.41-.78.54-1.17.13-.39.19-.79.19-1.18 0-.53-.09-1.02-.27-1.46-.18-.44-.44-.81-.78-1.11-.34-.31-.77-.54-1.26-.71-.51-.16-1.08-.24-1.72-.24-.69 0-1.31.11-1.85.32-.54.21-1 .51-1.36.88-.37.37-.65.8-.84 1.3-.18.47-.27.97-.28 1.5h2.14c.01-.31.05-.6.13-.87.09-.29.23-.54.4-.75.18-.21.41-.37.68-.49.27-.12.6-.18.96-.18.31 0 .58.05.81.15.23.1.43.25.59.43.16.18.28.4.37.65.08.25.13.52.13.81 0 .22-.03.43-.08.65-.06.22-.15.45-.29.7-.14.25-.32.53-.56.83-.23.3-.52.65-.88 1.03l-4.17 4.55v1.49h8.63v-1.71h-5.95zm-8.05-9.29h-2v4h-4v2h4v4h2v-4h4v-2h-4v-4z" />
    </g>
  ),
  'exposure-zero': (
    <g>
      <path d="M16.14 12.5c0 1-.1 1.85-.3 2.55-.2.7-.48 1.27-.83 1.7-.36.44-.79.75-1.3.95-.51.2-1.07.3-1.7.3-.62 0-1.18-.1-1.69-.3-.51-.2-.95-.51-1.31-.95-.36-.44-.65-1.01-.85-1.7-.2-.7-.3-1.55-.3-2.55v-2.04c0-1 .1-1.85.3-2.55.2-.7.48-1.26.84-1.69.36-.43.8-.74 1.31-.93.5-.19 1.07-.29 1.69-.29.63 0 1.19.1 1.7.29.51.19.95.5 1.31.93.36.43.64.99.84 1.69.2.7.3 1.54.3 2.55v2.04zm-2.11-2.36c0-.64-.05-1.18-.13-1.62-.09-.44-.22-.79-.4-1.06-.17-.27-.39-.46-.64-.58-.25-.13-.54-.19-.86-.19-.32 0-.61.06-.86.18s-.47.31-.64.58c-.17.27-.31.62-.4 1.06s-.13.98-.13 1.62v2.67c0 .64.05 1.18.14 1.62.09.45.23.81.4 1.09s.39.48.64.61.54.19.87.19c.33 0 .62-.06.87-.19s.46-.33.63-.61c.17-.28.3-.64.39-1.09.09-.45.13-.99.13-1.62v-2.66z" />
    </g>
  ),
  'filter-1': (
    <g>
      <path d="M3 5h-2v16c0 1.1.9 2 2 2h16v-2h-16v-16zm11 10h2v-10h-4v2h2v8zm7-14h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14z" />
    </g>
  ),
  'filter-2': (
    <g>
      <path d="M3 5h-2v16c0 1.1.9 2 2 2h16v-2h-16v-16zm18-4h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14zm-4-4h-4v-2h2c1.1 0 2-.89 2-2v-2c0-1.11-.9-2-2-2h-4v2h4v2h-2c-1.1 0-2 .89-2 2v4h6v-2z" />
    </g>
  ),
  filter: (
    <g>
      <path d="M15.96 10.29l-2.75 3.54-1.96-2.36-2.75 3.53h11l-3.54-4.71zm-12.96-5.29h-2v16c0 1.1.9 2 2 2h16v-2h-16v-16zm18-4h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14z" />
    </g>
  ),
  'filter-3': (
    <g>
      <path d="M21 1h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14zm-18-12h-2v16c0 1.1.9 2 2 2h16v-2h-16v-16zm14 8v-1.5c0-.83-.67-1.5-1.5-1.5.83 0 1.5-.67 1.5-1.5v-1.5c0-1.11-.9-2-2-2h-4v2h4v2h-2v2h2v2h-4v2h4c1.1 0 2-.89 2-2z" />
    </g>
  ),
  'filter-4': (
    <g>
      <path d="M3 5h-2v16c0 1.1.9 2 2 2h16v-2h-16v-16zm12 10h2v-10h-2v4h-2v-4h-2v6h4v4zm6-14h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14z" />
    </g>
  ),
  'filter-5': (
    <g>
      <path d="M21 1h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14zm-18-12h-2v16c0 1.1.9 2 2 2h16v-2h-16v-16zm14 8v-2c0-1.11-.9-2-2-2h-2v-2h4v-2h-6v6h4v2h-4v2h4c1.1 0 2-.89 2-2z" />
    </g>
  ),
  'filter-6': (
    <g>
      <path d="M3 5h-2v16c0 1.1.9 2 2 2h16v-2h-16v-16zm18-4h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14zm-8-2h2c1.1 0 2-.89 2-2v-2c0-1.11-.9-2-2-2h-2v-2h4v-2h-4c-1.1 0-2 .89-2 2v6c0 1.11.9 2 2 2zm0-4h2v2h-2v-2z" />
    </g>
  ),
  'filter-7': (
    <g>
      <path d="M3 5h-2v16c0 1.1.9 2 2 2h16v-2h-16v-16zm18-4h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14zm-8-2l4-8v-2h-6v2h4l-4 8h2z" />
    </g>
  ),
  'filter-8': (
    <g>
      <path d="M3 5h-2v16c0 1.1.9 2 2 2h16v-2h-16v-16zm18-4h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14zm-8-2h2c1.1 0 2-.89 2-2v-1.5c0-.83-.67-1.5-1.5-1.5.83 0 1.5-.67 1.5-1.5v-1.5c0-1.11-.9-2-2-2h-2c-1.1 0-2 .89-2 2v1.5c0 .83.67 1.5 1.5 1.5-.83 0-1.5.67-1.5 1.5v1.5c0 1.11.9 2 2 2zm0-8h2v2h-2v-2zm0 4h2v2h-2v-2z" />
    </g>
  ),
  'filter-9': (
    <g>
      <path d="M3 5h-2v16c0 1.1.9 2 2 2h16v-2h-16v-16zm18-4h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14zm-6-12h-2c-1.1 0-2 .89-2 2v2c0 1.11.9 2 2 2h2v2h-4v2h4c1.1 0 2-.89 2-2v-6c0-1.11-.9-2-2-2zm0 4h-2v-2h2v2z" />
    </g>
  ),
  'filter-9-plus': (
    <g>
      <path d="M3 5h-2v16c0 1.1.9 2 2 2h16v-2h-16v-16zm11 7v-4c0-1.11-.9-2-2-2h-1c-1.1 0-2 .89-2 2v1c0 1.11.9 2 2 2h1v1h-3v2h3c1.1 0 2-.89 2-2zm-3-3v-1h1v1h-1zm10-8h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 8h-2v-2h-2v2h-2v2h2v2h2v-2h2v6h-14v-14h14v6z" />
    </g>
  ),
  'filter-b-and-w': (
    <g>
      <path d="M19 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16l-7-8v8h-7l7-8v-6h7v14z" />
    </g>
  ),
  'filter-center-focus': (
    <g>
      <path d="M5 15h-2v4c0 1.1.9 2 2 2h4v-2h-4v-4zm0-10h4v-2h-4c-1.1 0-2 .9-2 2v4h2v-4zm14-2h-4v2h4v4h2v-4c0-1.1-.9-2-2-2zm0 16h-4v2h4c1.1 0 2-.9 2-2v-4h-2v4zm-7-10c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
    </g>
  ),
  'filter-drama': (
    <g>
      <path d="M19.35 10.04c-.68-3.45-3.71-6.04-7.35-6.04-2.89 0-5.39 1.64-6.64 4.04-3.01.32-5.36 2.86-5.36 5.96 0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zm-.35 7.96h-13c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4h2c0-2.76-1.86-5.08-4.4-5.78 1.01-1.34 2.6-2.22 4.4-2.22 3.03 0 5.5 2.47 5.5 5.5v.5h1.5c1.65 0 3 1.35 3 3s-1.35 3-3 3z" />
    </g>
  ),
  'filter-frames': (
    <g>
      <path d="M20 4h-4l-4-4-4 4h-4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-16v-14h4.52l3.52-3.5 3.48 3.5h4.48v14zm-2-12h-12v10h12" />
    </g>
  ),
  'filter-hdr': (
    <g>
      <path d="M14 6l-3.75 5 2.85 3.8-1.6 1.2c-1.69-2.25-4.5-6-4.5-6l-6 8h22l-9-12z" />
    </g>
  ),
  'filter-none': (
    <g>
      <path d="M3 5h-2v16c0 1.1.9 2 2 2h16v-2h-16v-16zm18-4h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14z" />
    </g>
  ),
  'filter-retrolux': (
    <g>
      <path d="M13 16.43l6-9.43-7-7-7 7 6 9.43v.11c-.6-.35-1.29-.54-2-.54-2.21 0-4 1.79-4 4s1.79 4 4 4c1.85 0 3.41-1.26 3.86-2.96l2.97 2.96 1.42-1.41-4.25-4.25v-1.91zm-2 3.57c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2c.53 0 1.04.21 1.41.58l.59.59v.83z" />
    </g>
  ),
  'filter-tilt-shift': (
    <g>
      <path d="M11 4.07v-2.02c-2.01.2-3.84 1-5.32 2.21l1.42 1.43c1.11-.86 2.44-1.44 3.9-1.62zm7.32.19c-1.48-1.21-3.31-2.01-5.32-2.21v2.02c1.46.18 2.79.76 3.9 1.62l1.42-1.43zm1.61 6.74h2.02c-.2-2.01-1-3.84-2.21-5.32l-1.43 1.42c.86 1.11 1.44 2.44 1.62 3.9zm-14.24-3.9l-1.43-1.42c-1.21 1.48-2.01 3.31-2.21 5.32h2.02c.18-1.46.76-2.79 1.62-3.9zm-1.62 5.9h-2.02c.2 2.01 1 3.84 2.21 5.32l1.43-1.43c-.86-1.1-1.44-2.43-1.62-3.89zm10.93-1c0-1.66-1.34-3-3-3s-3 1.34-3 3 1.34 3 3 3 3-1.34 3-3zm3.31 4.9l1.43 1.43c1.21-1.48 2.01-3.32 2.21-5.32h-2.02c-.18 1.45-.76 2.78-1.62 3.89zm-5.31 3.03v2.02c2.01-.2 3.84-1 5.32-2.21l-1.43-1.43c-1.1.86-2.43 1.44-3.89 1.62zm-7.32-.19c1.48 1.21 3.32 2.01 5.32 2.21v-2.02c-1.46-.18-2.79-.76-3.9-1.62l-1.42 1.43z" />
    </g>
  ),
  'filter-vintage': (
    <g>
      <path d="M18.7 12.4c-.28-.16-.57-.29-.86-.4.29-.11.58-.24.86-.4 1.92-1.11 2.99-3.12 3-5.19-1.79-1.03-4.07-1.11-6 0-.28.16-.54.35-.78.54.05-.31.08-.63.08-.95 0-2.22-1.21-4.15-3-5.19-1.79 1.04-3 2.97-3 5.19 0 .32.03.64.08.95-.24-.2-.5-.39-.78-.55-1.92-1.11-4.2-1.03-6 0 0 2.07 1.07 4.08 3 5.19.28.16.57.29.86.4-.29.11-.58.24-.86.4-1.92 1.11-2.99 3.12-3 5.19 1.79 1.03 4.07 1.11 6 0 .28-.16.54-.35.78-.54-.05.32-.08.64-.08.96 0 2.22 1.21 4.15 3 5.19 1.79-1.04 3-2.97 3-5.19 0-.32-.03-.64-.08-.95.24.2.5.38.78.54 1.92 1.11 4.2 1.03 6 0-.01-2.07-1.08-4.08-3-5.19zm-6.7 3.6c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z" />
    </g>
  ),
  flare: (
    <g>
      <path d="M7 11h-6v2h6v-2zm2.17-3.24l-2.12-2.12-1.41 1.41 2.12 2.12 1.41-1.41zm3.83-6.76h-2v6h2v-6zm5.36 6.05l-1.41-1.41-2.12 2.12 1.41 1.41 2.12-2.12zm-1.36 3.95v2h6v-2h-6zm-5-2c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm2.83 7.24l2.12 2.12 1.41-1.41-2.12-2.12-1.41 1.41zm-9.19.71l1.41 1.41 2.12-2.12-1.41-1.41-2.12 2.12zm5.36 6.05h2v-6h-2v6z" />
    </g>
  ),
  'flash-auto': (
    <g>
      <path d="M3 2v12h3v9l7-12h-4l4-9h-10zm16 0h-2l-3.2 9h1.9l.7-2h3.2l.7 2h1.9l-3.2-9zm-2.15 5.65l1.15-3.65 1.15 3.65h-2.3z" />
    </g>
  ),
  'flash-off': (
    <g>
      <path d="M3.27 3l-1.27 1.27 5 5v3.73h3v9l3.58-6.14 4.15 4.14 1.27-1.27-15.73-15.73zm13.73 7h-4l4-8h-10v2.18l8.46 8.46 1.54-2.64z" />
    </g>
  ),
  'flash-on': (
    <g>
      <path d="M7 2v11h3v9l7-12h-4l4-8z" />
    </g>
  ),
  flip: (
    <g>
      <path d="M15 21h2v-2h-2v2zm4-12h2v-2h-2v2zm-16-4v14c0 1.1.9 2 2 2h4v-2h-4v-14h4v-2h-4c-1.1 0-2 .9-2 2zm16-2v2h2c0-1.1-.9-2-2-2zm-8 20h2v-22h-2v22zm8-6h2v-2h-2v2zm-4-12h2v-2h-2v2zm4 8h2v-2h-2v2zm0 8c1.1 0 2-.9 2-2h-2v2z" />
    </g>
  ),
  gradient: (
    <g>
      <path d="M11 9h2v2h-2zm-2 2h2v2h-2zm4 0h2v2h-2zm2-2h2v2h-2zm-8 0h2v2h-2zm12-6h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-10 15h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm2-7h-2v2h2v2h-2v-2h-2v2h-2v-2h-2v2h-2v-2h-2v2h-2v-2h2v-2h-2v-6h14v6z" />
    </g>
  ),
  grain: (
    <g>
      <path d="M10 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-4-4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12-8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-4 8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm4-4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-4-4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-4-4c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </g>
  ),
  'grid-off': (
    <g>
      <path d="M8 4v1.45l2 2v-3.45h4v4h-3.45l2 2h1.45v1.45l2 2v-3.45h4v4h-3.45l2 2h1.45v1.45l2 2v-15.45c0-1.1-.9-2-2-2h-15.45l2 2h1.45zm8 0h4v4h-4v-4zm-14.73-2.73l-1.27 1.28 2 2v15.45c0 1.1.9 2 2 2h15.46l2 2 1.27-1.27-21.46-21.46zm8.73 11.28l1.45 1.45h-1.45v-1.45zm-6-6l1.45 1.45h-1.45v-1.45zm4 13.45h-4v-4h4v4zm0-6h-4v-4h3.45l.55.55v3.45zm6 6h-4v-4h3.45l.55.54v3.46zm2 0v-1.46l1.46 1.46h-1.46z" />
    </g>
  ),
  'grid-on': (
    <g>
      <path d="M20 2h-16c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-12 18h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4v-4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4v-4h4v4zm6 12h-4v-4h4v4zm0-6h-4v-4h4v4zm0-6h-4v-4h4v4z" />
    </g>
  ),
  'hdr-off': (
    <g>
      <path d="M18 17l-14.73-14.73-1.27 1.28 4 4v3.45h-2v-4h-2v10h2v-4h2v4h2v-7.45l1 1v6.45h4c.67 0 1.26-.33 1.62-.84l6.34 6.34 1.27-1.27-4.23-4.23zm-5-2h-2v-2.45l2 2v.45zm5-2h1l.82 3.27.73.73h1.45l-1.19-4.17c.7-.31 1.19-1.01 1.19-1.83v-2c0-1.1-.9-2-2-2h-4v5.45l2 2v-1.45zm0-4h2v2h-2v-2zm-3 2.45v-2.45c0-1.1-.9-2-2-2h-2.45l4.45 4.45z" />
    </g>
  ),
  'hdr-on': (
    <g>
      <path d="M6 11h-2v-4h-2v10h2v-4h2v4h2v-10h-2v4zm7-4h-4v10h4c1.1 0 2-.9 2-2v-6c0-1.1-.9-2-2-2zm0 8h-2v-6h2v6zm9-4v-2c0-1.1-.9-2-2-2h-4v10h2v-4h1l1 4h2l-1.19-4.17c.7-.31 1.19-1.01 1.19-1.83zm-2 0h-2v-2h2v2z" />
    </g>
  ),
  'hdr-plus-off': (
    <g>
      <path d="M9.5 5h1.5v2.45l2 2v-4.45h3.5c.83 0 1.5.67 1.5 1.5v3c0 .83-.67 1.5-1.5 1.5h-1.95l7.52 7.52c1.22-1.88 1.93-4.11 1.93-6.52 0-6.63-5.37-12-12-12-2.41 0-4.64.71-6.52 1.93l4.02 4.02v-.95zm0 9.5h-2v1h2v-1zm7-8h-2v3h2v-3zm-16-4.45l2.28 2.28c-1.74 2.07-2.78 4.75-2.78 7.67 0 6.63 5.37 12 12 12 2.92 0 5.6-1.04 7.68-2.78l2.28 2.28 1.27-1.27-21.46-21.46-1.27 1.28zm12.5 13.45h.96l2.54 2.55v.95h-1.5v-2h-2v-1.5zm-7-7.95l1.5 1.5v1.95h-1.5v-3.45zm0 5.45h3.5c.83 0 1.5.67 1.5 1.5v1c0 .62-.38 1.15-.91 1.38l.91 2.12h-1.5l-.86-2h-1.14v2h-1.5v-6z" />
    </g>
  ),
  'hdr-plus-on': (
    <g>
      <path d="M12 0c-6.63 0-12 5.37-12 12s5.37 12 12 12 12-5.37 12-12-5.37-12-12-12zm-1 15.5c0 .62-.37 1.15-.91 1.38l.91 2.12h-1.5l-.86-2h-1.14v2h-1.5v-6h3.5c.83 0 1.5.67 1.5 1.5v1zm0-4.5h-1.5v-2.5h-2v2.5h-1.5v-6h1.5v2h2v-2h1.5v6zm2-6h3.5c.83 0 1.5.67 1.5 1.5v3c0 .83-.67 1.5-1.5 1.5h-3.5v-6zm5.5 12h-2v2h-1.5v-2h-2v-1.5h2v-2h1.5v2h2v1.5zm-11-1.5h2v-1h-2v1zm9-9h-2v3h2v-3z" />
    </g>
  ),
  'hdr-strong': (
    <g>
      <path d="M17 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-12 2c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
    </g>
  ),
  'hdr-weak': (
    <g>
      <path d="M5 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm12-2c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z" />
    </g>
  ),
  healing: (
    <g>
      <path d="M17.73 12.02l3.98-3.98c.39-.39.39-1.02 0-1.41l-4.34-4.34c-.39-.39-1.02-.39-1.41 0l-3.98 3.98-3.98-3.98c-.2-.19-.45-.29-.71-.29-.25 0-.51.1-.7.29l-4.34 4.34c-.39.39-.39 1.02 0 1.41l3.98 3.98-3.98 3.98c-.39.39-.39 1.02 0 1.41l4.34 4.34c.39.39 1.02.39 1.41 0l3.98-3.98 3.98 3.98c.2.2.45.29.71.29.26 0 .51-.1.71-.29l4.34-4.34c.39-.39.39-1.02 0-1.41l-3.99-3.98zm-5.73-3.02c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-4.71 1.96l-3.63-3.62 3.63-3.63 3.62 3.62-3.62 3.63zm2.71 2.04c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2 2c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm2-4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2.66 9.34l-3.63-3.62 3.63-3.63 3.62 3.62-3.62 3.63z" />
    </g>
  ),
  image: (
    <g>
      <path d="M21 19v-14c0-1.1-.9-2-2-2h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-12.5-5.5l2.5 3.01 3.5-4.51 4.5 6h-14l3.5-4.5z" />
    </g>
  ),
  'image-aspect-ratio': (
    <g>
      <path d="M16 10h-2v2h2v-2zm0 4h-2v2h2v-2zm-8-4h-2v2h2v-2zm4 0h-2v2h2v-2zm8-6h-16c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm0 14h-16v-12h16v12z" />
    </g>
  ),
  iso: (
    <g>
      <path d="M19 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-13.5 4.5h2v-2h1.5v2h2v1.5h-2v2h-1.5v-2h-2v-1.5zm13.5 11.5h-14l14-14v14zm-2-2v-1.5h-5v1.5h5z" />
    </g>
  ),
  landscape: (
    <g>
      <path d="M14 6l-3.75 5 2.85 3.8-1.6 1.2c-1.69-2.25-4.5-6-4.5-6l-6 8h22l-9-12z" />
    </g>
  ),
  'leak-add': (
    <g>
      <path d="M6 3h-3v3c1.66 0 3-1.34 3-3zm8 0h-2c0 4.97-4.03 9-9 9v2c6.08 0 11-4.93 11-11zm-4 0h-2c0 2.76-2.24 5-5 5v2c3.87 0 7-3.13 7-7zm0 18h2c0-4.97 4.03-9 9-9v-2c-6.07 0-11 4.93-11 11zm8 0h3v-3c-1.66 0-3 1.34-3 3zm-4 0h2c0-2.76 2.24-5 5-5v-2c-3.87 0-7 3.13-7 7z" />
    </g>
  ),
  'leak-remove': (
    <g>
      <path d="M10 3h-2c0 .37-.04.72-.12 1.06l1.59 1.59c.34-.81.53-1.71.53-2.65zm-7 1.27l2.84 2.84c-.81.56-1.78.89-2.84.89v2c1.61 0 3.09-.55 4.27-1.46l1.43 1.43c-1.56 1.27-3.54 2.03-5.7 2.03v2c2.71 0 5.19-.99 7.11-2.62l2.5 2.5c-1.62 1.93-2.61 4.41-2.61 7.12h2c0-2.16.76-4.14 2.03-5.69l1.43 1.43c-.91 1.17-1.46 2.65-1.46 4.26h2c0-1.06.33-2.03.89-2.84l2.84 2.84 1.27-1.27-16.73-16.73-1.27 1.27zm11-1.27h-2c0 1.5-.37 2.91-1.02 4.16l1.46 1.46c.98-1.64 1.56-3.56 1.56-5.62zm5.94 13.12c.34-.08.69-.12 1.06-.12v-2c-.94 0-1.84.19-2.66.52l1.6 1.6zm-4.56-4.56l1.46 1.46c1.25-.65 2.66-1.02 4.16-1.02v-2c-2.06 0-3.98.58-5.62 1.56z" />
    </g>
  ),
  lens: (
    <g>
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z" />
    </g>
  ),
  looks: (
    <g>
      <path d="M12 10c-3.86 0-7 3.14-7 7h2c0-2.76 2.24-5 5-5s5 2.24 5 5h2c0-3.86-3.14-7-7-7zm0-4c-6.07 0-11 4.93-11 11h2c0-4.96 4.04-9 9-9s9 4.04 9 9h2c0-6.07-4.93-11-11-11z" />
    </g>
  ),
  'looks-3': (
    <g>
      <path d="M19.01 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-4 7.5c0 .83-.67 1.5-1.5 1.5.83 0 1.5.67 1.5 1.5v1.5c0 1.11-.9 2-2 2h-4v-2h4v-2h-2v-2h2v-2h-4v-2h4c1.1 0 2 .89 2 2v1.5z" />
    </g>
  ),
  'looks-4': (
    <g>
      <path d="M19 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-4 14h-2v-4h-4v-6h2v4h2v-4h2v10z" />
    </g>
  ),
  'looks-5': (
    <g>
      <path d="M19 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-4 6h-4v2h2c1.1 0 2 .89 2 2v2c0 1.11-.9 2-2 2h-4v-2h4v-2h-4v-6h6v2z" />
    </g>
  ),
  'looks-6': (
    <g>
      <path d="M11 15h2v-2h-2v2zm8-12h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-4 6h-4v2h2c1.1 0 2 .89 2 2v2c0 1.11-.9 2-2 2h-2c-1.1 0-2-.89-2-2v-6c0-1.11.9-2 2-2h4v2z" />
    </g>
  ),
  'looks-one': (
    <g>
      <path d="M19 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-5 14h-2v-8h-2v-2h4v10z" />
    </g>
  ),
  'looks-two': (
    <g>
      <path d="M19 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-4 8c0 1.11-.9 2-2 2h-2v2h4v2h-6v-4c0-1.11.9-2 2-2h2v-2h-4v-2h4c1.1 0 2 .89 2 2v2z" />
    </g>
  ),
  loupe: (
    <g>
      <path d="M13 7h-2v4h-4v2h4v4h2v-4h4v-2h-4v-4zm-1-5c-5.51 0-10 4.49-10 10s4.49 10 10 10h8c1.1 0 2-.9 2-2v-8c0-5.51-4.49-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    </g>
  ),
  'movie-creation': (
    <g>
      <path d="M18 4l2 4h-3l-2-4h-2l2 4h-3l-2-4h-2l2 4h-3l-2-4h-1c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-14h-4z" />
    </g>
  ),
  nature: (
    <g>
      <path d="M13 16.12c3.47-.41 6.17-3.36 6.17-6.95 0-3.87-3.13-7-7-7s-7 3.13-7 7c0 3.47 2.52 6.34 5.83 6.89v3.94h-6v2h14v-2h-6v-3.88z" />
    </g>
  ),
  'nature-people': (
    <g>
      <path d="M22.17 9.17c0-3.87-3.13-7-7-7s-7 3.13-7 7c0 3.47 2.52 6.34 5.83 6.89v3.94h-8v-3h1v-4c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1v4h1v5h16v-2h-3v-3.88c3.47-.41 6.17-3.36 6.17-6.95zm-17.67 1.83c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5z" />
    </g>
  ),
  'navigate-before': (
    <g>
      <path d="M15.41 7.41l-1.41-1.41-6 6 6 6 1.41-1.41-4.58-4.59z" />
    </g>
  ),
  'navigate-next': (
    <g>
      <path d="M10 6l-1.41 1.41 4.58 4.59-4.58 4.59 1.41 1.41 6-6z" />
    </g>
  ),
  palette: (
    <g>
      <path d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5h1.77c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm3-4c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm5 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm3 4c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    </g>
  ),
  panorama: (
    <g>
      <path d="M23 18v-12c0-1.1-.9-2-2-2h-18c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2zm-14.5-5.5l2.5 3.01 3.5-4.51 4.5 6h-14l3.5-4.5z" />
    </g>
  ),
  'panorama-fisheye': (
    <g>
      <path d="M12 2c-5.53 0-10 4.47-10 10s4.47 10 10 10 10-4.47 10-10-4.47-10-10-10zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
    </g>
  ),
  'panorama-horizontal': (
    <g>
      <path d="M20 6.54v10.91c-2.6-.77-5.28-1.16-8-1.16-2.72 0-5.4.39-8 1.16v-10.91c2.6.77 5.28 1.16 8 1.16 2.72.01 5.4-.38 8-1.16m1.43-2.54c-.1 0-.2.02-.31.06-2.94 1.1-6.03 1.64-9.12 1.64-3.09 0-6.18-.55-9.12-1.64-.11-.04-.22-.06-.31-.06-.34 0-.57.23-.57.63v14.75c0 .39.23.62.57.62.1 0 .2-.02.31-.06 2.94-1.1 6.03-1.64 9.12-1.64 3.09 0 6.18.55 9.12 1.64.11.04.21.06.31.06.33 0 .57-.23.57-.63v-14.74c0-.4-.24-.63-.57-.63z" />
    </g>
  ),
  'panorama-vertical': (
    <g>
      <path d="M19.94 21.12c-1.1-2.94-1.64-6.03-1.64-9.12 0-3.09.55-6.18 1.64-9.12.04-.11.06-.22.06-.31 0-.34-.23-.57-.63-.57h-14.74c-.4 0-.63.23-.63.57 0 .1.02.2.06.31 1.1 2.94 1.65 6.03 1.65 9.12 0 3.09-.55 6.18-1.64 9.12-.05.11-.07.22-.07.31 0 .33.23.57.63.57h14.75c.39 0 .63-.24.63-.57-.01-.1-.03-.2-.07-.31zm-13.4-1.12c.77-2.6 1.16-5.28 1.16-8 0-2.72-.39-5.4-1.16-8h10.91c-.77 2.6-1.16 5.28-1.16 8 0 2.72.39 5.4 1.16 8h-10.91z" />
    </g>
  ),
  'panorama-wide-angle': (
    <g>
      <path d="M12 6c2.45 0 4.71.2 7.29.64.47 1.78.71 3.58.71 5.36 0 1.78-.24 3.58-.71 5.36-2.58.44-4.84.64-7.29.64s-4.71-.2-7.29-.64c-.47-1.78-.71-3.58-.71-5.36 0-1.78.24-3.58.71-5.36 2.58-.44 4.84-.64 7.29-.64m0-2c-2.73 0-5.22.24-7.95.72l-.93.16-.25.9c-.58 2.07-.87 4.15-.87 6.22s.29 4.15.87 6.22l.25.89.93.16c2.73.49 5.22.73 7.95.73s5.22-.24 7.95-.72l.93-.16.25-.89c.58-2.08.87-4.16.87-6.23s-.29-4.15-.87-6.22l-.25-.89-.93-.16c-2.73-.49-5.22-.73-7.95-.73z" />
    </g>
  ),
  photo: (
    <g>
      <path d="M21 19v-14c0-1.1-.9-2-2-2h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-12.5-5.5l2.5 3.01 3.5-4.51 4.5 6h-14l3.5-4.5z" />
    </g>
  ),
  'photo-album': (
    <g>
      <path d="M18 2h-12c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-12 2h5v8l-2.5-1.5-2.5 1.5v-8zm0 15l3-3.86 2.14 2.58 3-3.86 3.86 5.14h-12z" />
    </g>
  ),
  'photo-camera': (
    <g>
      <circle cx="12" cy="12" r="3.2" />
      <path d="M9 2l-1.83 2h-3.17c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2h-3.17l-1.83-2h-6zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
    </g>
  ),
  'photo-library': (
    <g>
      <path d="M22 16v-12c0-1.1-.9-2-2-2h-12c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2zm-11-4l2.03 2.71 2.97-3.71 4 5h-12l3-4zm-9-6v14c0 1.1.9 2 2 2h14v-2h-14v-14h-2z" />
    </g>
  ),
  photosphere: (
    <g>
      <path d="M22.07 7.59c-1.7-3.88-5.56-6.59-10.07-6.59s-8.37 2.71-10.07 6.59c-.65.28-1.3.58-1.93.91v7c.63.33 1.28.63 1.93.91 1.7 3.88 5.56 6.59 10.07 6.59s8.37-2.71 10.07-6.59c.65-.28 1.29-.58 1.93-.91v-7c-.63-.33-1.28-.63-1.93-.91zm-10.07-5.09c3.31 0 6.23 1.7 7.93 4.28-2.58-.85-5.25-1.28-7.93-1.28-2.68 0-5.35.43-7.93 1.28 1.7-2.58 4.62-4.28 7.93-4.28zm0 19c-3.31 0-6.23-1.7-7.93-4.28 2.58.85 5.25 1.28 7.93 1.28 2.68 0 5.35-.43 7.93-1.28-1.7 2.58-4.62 4.28-7.93 4.28zm10.5-6.92l-.29.13c-1.08.51-2.21.92-3.35 1.27l-3.86-4.42-3 3.44-4-5-4.38 5.47c-.62-.23-1.23-.47-1.83-.75l-.29-.14v-5.16l.29-.13c6.4-2.99 14.02-2.99 20.42 0l.29.13v5.16z" />
    </g>
  ),
  portrait: (
    <g>
      <path d="M12 12.25c1.24 0 2.25-1.01 2.25-2.25s-1.01-2.25-2.25-2.25-2.25 1.01-2.25 2.25 1.01 2.25 2.25 2.25zm4.5 4c0-1.5-3-2.25-4.5-2.25s-4.5.75-4.5 2.25v.75h9v-.75zm2.5-13.25h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14z" />
    </g>
  ),
  'remove-red-eye': (
    <g>
      <path d="M12 4.5c-5 0-9.27 3.11-11 7.5 1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 12.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />
    </g>
  ),
  'rotate-left': (
    <g>
      <path d="M7.11 8.53l-1.41-1.42c-.9 1.16-1.46 2.5-1.63 3.89h2.02c.14-.87.49-1.72 1.02-2.47zm-1.02 4.47h-2.02c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61v-2.03c-.87-.15-1.71-.49-2.46-1.03l-1.44 1.45zm5.9-14.25v-3.07l-4.55 4.55 4.55 4.45v-3.91c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z" />
    </g>
  ),
  'rotate-right': (
    <g>
      <path d="M15.55 5.55l-4.55-4.55v3.07c-3.94.49-7 3.85-7 7.93s3.05 7.44 7 7.93v-2.02c-2.84-.48-5-2.94-5-5.91s2.16-5.43 5-5.91v3.91l4.55-4.45zm4.38 5.45c-.17-1.39-.72-2.73-1.62-3.89l-1.42 1.42c.54.75.88 1.6 1.02 2.47h2.02zm-6.93 6.9v2.02c1.39-.17 2.74-.71 3.9-1.61l-1.44-1.44c-.75.54-1.59.89-2.46 1.03zm3.89-2.42l1.42 1.41c.9-1.16 1.45-2.5 1.62-3.89h-2.02c-.14.87-.48 1.72-1.02 2.48z" />
    </g>
  ),
  slideshow: (
    <g>
      <path d="M10 8v8l5-4-5-4zm9-5h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-14h14v14z" />
    </g>
  ),
  straighten: (
    <g>
      <path d="M21 6h-18c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2zm0 10h-18v-8h2v4h2v-4h2v4h2v-4h2v4h2v-4h2v4h2v-4h2v8z" />
    </g>
  ),
  style: (
    <g>
      <path d="M2.53 19.65l1.34.56v-9.03l-2.43 5.86c-.41 1.02.08 2.19 1.09 2.61zm19.5-3.7l-4.96-11.97c-.31-.75-1.04-1.21-1.81-1.23-.26 0-.53.04-.79.15l-7.37 3.05c-.75.31-1.21 1.03-1.23 1.8-.01.27.04.54.15.8l4.96 11.97c.31.76 1.05 1.22 1.83 1.23.26 0 .52-.05.77-.15l7.36-3.05c1.02-.42 1.51-1.59 1.09-2.6zm-14.15-7.2c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-2 11c0 1.1.9 2 2 2h1.45l-3.45-8.34v6.34z" />
    </g>
  ),
  'switch-camera': (
    <g>
      <path d="M20 4h-3.17l-1.83-2h-6l-1.83 2h-3.17c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-5 11.5v-2.5h-6v2.5l-3.5-3.5 3.5-3.5v2.5h6v-2.5l3.5 3.5-3.5 3.5z" />
    </g>
  ),
  'switch-video': (
    <g>
      <path d="M18 9.5v-3.5c0-.55-.45-1-1-1h-14c-.55 0-1 .45-1 1v12c0 .55.45 1 1 1h14c.55 0 1-.45 1-1v-3.5l4 4v-13l-4 4zm-5 6v-2.5h-6v2.5l-3.5-3.5 3.5-3.5v2.5h6v-2.5l3.5 3.5-3.5 3.5z" />
    </g>
  ),
  'tag-faces': (
    <g>
      <path d="M11.99 2c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10zm.01 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5h-10.22c.8 2.04 2.78 3.5 5.11 3.5z" />
    </g>
  ),
  texture: (
    <g>
      <path d="M19.51 3.08l-16.43 16.43c.09.34.27.65.51.9.25.24.56.42.9.51l16.44-16.43c-.19-.69-.73-1.23-1.42-1.41zm-7.63-.08l-8.88 8.88v2.83l11.71-11.71h-2.83zm-6.88 0c-1.1 0-2 .9-2 2v2l4-4h-2zm14 18c.55 0 1.05-.22 1.41-.59.37-.36.59-.86.59-1.41v-2l-4 4h2zm-9.71 0h2.83l8.88-8.88v-2.83l-11.71 11.71z" />
    </g>
  ),
  timelapse: (
    <g>
      <path d="M16.24 7.76c-1.17-1.17-2.7-1.76-4.24-1.76v6l-4.24 4.24c2.34 2.34 6.14 2.34 8.49 0 2.34-2.34 2.34-6.14-.01-8.48zm-4.24-5.76c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    </g>
  ),
  'timer-10': (
    <g>
      <path d="M0 7.72v1.68l3-1v9.6h2v-12h-.25l-4.75 1.72zm23.78 6.65c-.14-.28-.35-.53-.63-.74-.28-.21-.61-.39-1.01-.53s-.85-.27-1.35-.38c-.35-.07-.64-.15-.87-.23-.23-.08-.41-.16-.55-.25-.14-.09-.23-.19-.28-.3-.05-.11-.08-.24-.08-.39 0-.14.03-.28.09-.41.06-.13.15-.25.27-.34.12-.1.27-.18.45-.24s.4-.09.64-.09c.25 0 .47.04.66.11.19.07.35.17.48.29.13.12.22.26.29.42.06.16.1.32.1.49h1.95c0-.39-.08-.75-.24-1.09-.16-.34-.39-.63-.69-.88-.3-.25-.66-.44-1.09-.59-.43-.15-.92-.22-1.46-.22-.51 0-.98.07-1.39.21-.41.14-.77.33-1.06.57-.29.24-.51.52-.67.84-.16.32-.23.65-.23 1.01s.08.69.23.96c.15.28.36.52.64.73.27.21.6.38.98.53.38.14.81.26 1.27.36.39.08.71.17.95.26s.43.19.57.29c.13.1.22.22.27.34.05.12.07.25.07.39 0 .32-.13.57-.4.77-.27.2-.66.29-1.17.29-.22 0-.43-.02-.64-.08-.21-.05-.4-.13-.56-.24-.17-.11-.3-.26-.41-.44-.11-.18-.17-.41-.18-.67h-1.89c0 .36.08.71.24 1.05.16.34.39.65.7.93.31.27.69.49 1.15.66.46.17.98.25 1.58.25.53 0 1.01-.06 1.44-.19.43-.13.8-.31 1.11-.54.31-.23.54-.51.71-.83.17-.32.25-.67.25-1.06-.02-.4-.09-.74-.24-1.02zm-9.96-7.32c-.34-.4-.75-.7-1.23-.88-.47-.18-1.01-.27-1.59-.27-.58 0-1.11.09-1.59.27-.48.18-.89.47-1.23.88-.34.41-.6.93-.79 1.59-.18.65-.28 1.45-.28 2.39v1.92c0 .94.09 1.74.28 2.39.19.66.45 1.19.8 1.6.34.41.75.71 1.23.89.48.18 1.01.28 1.59.28.59 0 1.12-.09 1.59-.28.48-.18.88-.48 1.22-.89.34-.41.6-.94.78-1.6.18-.65.28-1.45.28-2.39v-1.92c0-.94-.09-1.74-.28-2.39-.18-.66-.44-1.19-.78-1.59zm-.92 6.17c0 .6-.04 1.11-.12 1.53-.08.42-.2.76-.36 1.02-.16.26-.36.45-.59.57-.23.12-.51.18-.82.18-.3 0-.58-.06-.82-.18s-.44-.31-.6-.57c-.16-.26-.29-.6-.38-1.02-.09-.42-.13-.93-.13-1.53v-2.5c0-.6.04-1.11.13-1.52.09-.41.21-.74.38-1 .16-.25.36-.43.6-.55.24-.11.51-.17.81-.17.31 0 .58.06.81.17.24.11.44.29.6.55.16.25.29.58.37.99.08.41.13.92.13 1.52v2.51z" />
    </g>
  ),
  timer: (
    <g>
      <path d="M15 1h-6v2h6v-2zm-4 13h2v-6h-2v6zm8.03-6.61l1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42c-1.55-1.24-3.5-1.98-5.62-1.98-4.97 0-9 4.03-9 9s4.02 9 9 9 9-4.03 9-9c0-2.12-.74-4.07-1.97-5.61zm-7.03 12.61c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
    </g>
  ),
  'timer-3': (
    <g>
      <path d="M11.61 12.97c-.16-.24-.36-.46-.62-.65-.25-.19-.56-.35-.93-.48.3-.14.57-.3.8-.5.23-.2.42-.41.57-.64.15-.23.27-.46.34-.71.08-.24.11-.49.11-.73 0-.55-.09-1.04-.28-1.46-.18-.42-.44-.77-.78-1.06-.33-.28-.73-.5-1.2-.64-.45-.13-.97-.2-1.53-.2-.55 0-1.06.08-1.52.24-.47.17-.87.4-1.2.69-.33.29-.6.63-.78 1.03-.2.39-.29.83-.29 1.29h1.98c0-.26.05-.49.14-.69.09-.2.22-.38.38-.52.17-.14.36-.25.58-.33.22-.08.46-.12.73-.12.61 0 1.06.16 1.36.47.3.31.44.75.44 1.32 0 .27-.04.52-.12.74-.08.22-.21.41-.38.57-.17.16-.38.28-.63.37-.25.09-.55.13-.89.13h-1.17v1.57h1.18c.34 0 .64.04.91.11.27.08.5.19.69.35.19.16.34.36.44.61.1.24.16.54.16.87 0 .62-.18 1.09-.53 1.42-.35.33-.84.49-1.45.49-.29 0-.56-.04-.8-.13-.24-.08-.44-.2-.61-.36-.17-.16-.3-.34-.39-.56-.09-.22-.14-.46-.14-.72h-1.99c0 .55.11 1.03.32 1.45.21.42.5.77.86 1.05s.77.49 1.24.63.96.21 1.48.21c.57 0 1.09-.08 1.58-.23.49-.15.91-.38 1.26-.68.36-.3.64-.66.84-1.1.2-.43.3-.93.3-1.48 0-.29-.04-.58-.11-.86-.08-.25-.19-.51-.35-.76zm9.26 1.4c-.14-.28-.35-.53-.63-.74-.28-.21-.61-.39-1.01-.53s-.85-.27-1.35-.38c-.35-.07-.64-.15-.87-.23-.23-.08-.41-.16-.55-.25-.14-.09-.23-.19-.28-.3-.05-.11-.08-.24-.08-.39s.03-.28.09-.41c.06-.13.15-.25.27-.34.12-.1.27-.18.45-.24s.4-.09.64-.09c.25 0 .47.04.66.11.19.07.35.17.48.29.13.12.22.26.29.42.06.16.1.32.1.49h1.95c0-.39-.08-.75-.24-1.09-.16-.34-.39-.63-.69-.88-.3-.25-.66-.44-1.09-.59-.43-.15-.92-.22-1.46-.22-.51 0-.98.07-1.39.21-.41.14-.77.33-1.06.57-.29.24-.51.52-.67.84-.16.32-.23.65-.23 1.01s.08.68.23.96c.15.28.37.52.64.73.27.21.6.38.98.53.38.14.81.26 1.27.36.39.08.71.17.95.26s.43.19.57.29c.13.1.22.22.27.34.05.12.07.25.07.39 0 .32-.13.57-.4.77-.27.2-.66.29-1.17.29-.22 0-.43-.02-.64-.08-.21-.05-.4-.13-.56-.24-.17-.11-.3-.26-.41-.44-.11-.18-.17-.41-.18-.67h-1.89c0 .36.08.71.24 1.05.16.34.39.65.7.93.31.27.69.49 1.15.66.46.17.98.25 1.58.25.53 0 1.01-.06 1.44-.19.43-.13.8-.31 1.11-.54.31-.23.54-.51.71-.83.17-.32.25-.67.25-1.06-.02-.4-.09-.74-.24-1.02z" />
    </g>
  ),
  'timer-auto': (
    <g>
      <path d="M12 4c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 10c-2.67 0-8 1.34-8 4v2h16v-2c0-2.67-5.33-4-8-4z" />
    </g>
  ),
  'timer-off': (
    <g>
      <path d="M19.04 4.55l-1.42 1.42c-1.55-1.23-3.5-1.97-5.62-1.97-1.83 0-3.53.55-4.95 1.48l1.46 1.46c1.02-.59 2.22-.94 3.49-.94 3.87 0 7 3.13 7 7 0 1.27-.35 2.47-.94 3.49l1.45 1.45c.94-1.41 1.49-3.11 1.49-4.94 0-2.12-.74-4.07-1.97-5.61l1.42-1.42-1.41-1.42zm-4.04-3.55h-6v2h6v-2zm-4 8.44l2 2v-3.44h-2v1.44zm-7.98-5.44l-1.27 1.27 2.75 2.76c-.95 1.42-1.5 3.13-1.5 4.97 0 4.97 4.02 9 9 9 1.84 0 3.55-.55 4.98-1.5l2.5 2.5 1.27-1.27-7.71-7.71-10.02-10.02zm8.98 16c-3.87 0-7-3.13-7-7 0-1.28.35-2.48.95-3.52l9.56 9.56c-1.03.61-2.23.96-3.51.96z" />
    </g>
  ),
  tonality: (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm-1 17.93c-3.94-.49-7-3.85-7-7.93s3.05-7.44 7-7.93v15.86zm2-15.86c1.03.13 2 .45 2.87.93h-2.87v-.93zm0 2.93h5.24c.25.31.48.65.68 1h-5.92v-1zm0 3h6.74c.08.33.15.66.19 1h-6.93v-1zm0 9.93v-.93h2.87c-.87.48-1.84.8-2.87.93zm5.24-2.93h-5.24v-1h5.92c-.2.35-.43.69-.68 1zm1.5-3h-6.74v-1h6.93c-.04.34-.11.67-.19 1z" />
    </g>
  ),
  transform: (
    <g>
      <path d="M22 18v-2h-14v-12h2l-3-3-3 3h2v2h-4v2h4v8c0 1.1.9 2 2 2h8v2h-2l3 3 3-3h-2v-2h4zm-12-10h6v6h2v-6c0-1.1-.9-2-2-2h-6v2z" />
    </g>
  ),
  tune: (
    <g>
      <path d="M3 17v2h6v-2h-6zm0-12v2h10v-2h-10zm10 16v-2h8v-2h-8v-2h-2v6h2zm-6-12v2h-4v2h4v2h2v-6h-2zm14 4v-2h-10v2h10zm-6-4h2v-2h4v-2h-4v-2h-2v6z" />
    </g>
  ),
  'wb-auto': (
    <g>
      <path d="M6.85 12.65h2.3l-1.15-3.65-1.15 3.65zm15.15-5.65l-1.2 6.29-1.5-6.29h-1.6l-1.49 6.29-1.21-6.29h-.76c-1.47-1.83-3.71-3-6.24-3-4.42 0-8 3.58-8 8s3.58 8 8 8c3.13 0 5.84-1.81 7.15-4.43l.1.43h1.75l1.5-6.1 1.5 6.1h1.75l2.05-9h-1.8zm-11.7 9l-.7-2h-3.2l-.7 2h-1.9l3.2-9h2l3.2 9h-1.9z" />
    </g>
  ),
  'wb-cloudy': (
    <g>
      <path d="M19.36 10.04c-.69-3.45-3.72-6.04-7.36-6.04-2.89 0-5.4 1.64-6.65 4.04-3.01.32-5.35 2.87-5.35 5.96 0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.64-4.96z" />
    </g>
  ),
  'wb-incandescent': (
    <g>
      <path d="M3.55 18.54l1.41 1.41 1.79-1.8-1.41-1.41-1.79 1.8zm7.45 3.91h2v-2.95h-2v2.95zm-7-11.95h-3v2h3v-2zm11-4.19v-4.81h-6v4.81c-1.79 1.04-3 2.97-3 5.19 0 3.31 2.69 6 6 6s6-2.69 6-6c0-2.22-1.21-4.15-3-5.19zm5 4.19v2h3v-2h-3zm-2.76 7.66l1.79 1.8 1.41-1.41-1.8-1.79-1.4 1.4z" />
    </g>
  ),
  'wb-irradescent': (
    <g>
      <path d="M5 14.5h14v-6h-14v6zm6-13.95v2.95h2v-2.95h-2zm8.04 2.5l-1.79 1.79 1.41 1.41 1.8-1.79-1.42-1.41zm-6.04 19.4v-2.95h-2v2.95h2zm7.45-3.91l-1.8-1.79-1.41 1.41 1.79 1.8 1.42-1.42zm-16.9-14.08l1.79 1.79 1.41-1.41-1.79-1.79-1.41 1.41zm1.41 15.49l1.79-1.8-1.41-1.41-1.79 1.79 1.41 1.42z" />
    </g>
  ),
  'wb-sunny': (
    <g>
      <path d="M6.76 4.84l-1.8-1.79-1.41 1.41 1.79 1.79 1.42-1.41zm-2.76 5.66h-3v2h3v-2zm9-9.95h-2v2.95h2v-2.95zm7.45 3.91l-1.41-1.41-1.79 1.79 1.41 1.41 1.79-1.79zm-3.21 13.7l1.79 1.8 1.41-1.41-1.8-1.79-1.4 1.4zm2.76-7.66v2h3v-2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-1 16.95h2v-2.95h-2v2.95zm-7.45-3.91l1.41 1.41 1.79-1.8-1.41-1.41-1.79 1.8z" />
    </g>
  ),
  beenhere: (
    <g>
      <path d="M19 1h-14c-1.1 0-1.99.9-1.99 2l-.01 12.93c0 .69.35 1.3.88 1.66l8.12 5.41 8.11-5.41c.53-.36.88-.97.88-1.66l.01-12.93c0-1.1-.9-2-2-2zm-9 15l-5-5 1.41-1.41 3.59 3.58 7.59-7.59 1.41 1.42-9 9z" />
    </g>
  ),
  directions: (
    <g>
      <path d="M21.71 11.29l-9-9c-.39-.39-1.02-.39-1.41 0l-9 9c-.39.39-.39 1.02 0 1.41l9 9c.39.39 1.02.39 1.41 0l9-9c.39-.38.39-1.01 0-1.41zm-7.71 3.21v-2.5h-4v3h-2v-4c0-.55.45-1 1-1h5v-2.5l3.5 3.5-3.5 3.5z" />
    </g>
  ),
  'directions-bike': (
    <g>
      <path d="M16 4.8c.99 0 1.8-.81 1.8-1.8s-.81-1.8-1.8-1.8c-1 0-1.8.81-1.8 1.8s.8 1.8 1.8 1.8zm3 7.2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 8.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5zm-4.2-10.5h4.2v-1.8h-3.2l-1.93-3.27c-.3-.5-.84-.83-1.46-.83-.47 0-.89.19-1.2.5l-3.7 3.7c-.32.3-.51.73-.51 1.2 0 .63.33 1.16.85 1.47l3.35 2.03v5h1.8v-6.48l-2.25-1.67 2.32-2.33 1.73 2.48zm-9.8 2c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0 8.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z" />
    </g>
  ),
  'directions-bus': (
    <g>
      <path d="M4 16c0 .88.39 1.67 1 2.22v1.78c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h8v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1.78c.61-.55 1-1.34 1-2.22v-10c0-3.5-3.58-4-8-4s-8 .5-8 4v10zm3.5 1c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm9 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm1.5-6h-12v-5h12v5z" />
    </g>
  ),
  'directions-car': (
    <g>
      <path d="M18.92 6.01c-.2-.59-.76-1.01-1.42-1.01h-11c-.66 0-1.21.42-1.42 1.01l-2.08 5.99v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zm-12.42 9.99c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm-12.5-5l1.5-4.5h11l1.5 4.5h-14z" />
    </g>
  ),
  'directions-ferry': (
    <g>
      <path d="M20 21c-1.39 0-2.78-.47-4-1.32-2.44 1.71-5.56 1.71-8 0-1.22.85-2.61 1.32-4 1.32h-2v2h2c1.38 0 2.74-.35 4-.99 2.52 1.29 5.48 1.29 8 0 1.26.65 2.62.99 4 .99h2v-2h-2zm-16.05-2h.05c1.6 0 3.02-.88 4-2 .98 1.12 2.4 2 4 2s3.02-.88 4-2c.98 1.12 2.4 2 4 2h.05l1.89-6.68c.08-.26.06-.54-.06-.78s-.34-.42-.6-.5l-1.28-.42v-4.62c0-1.1-.9-2-2-2h-3v-3h-6v3h-3c-1.1 0-2 .9-2 2v4.62l-1.29.42c-.26.08-.48.26-.6.5s-.15.52-.06.78l1.9 6.68zm2.05-13h12v3.97l-6-1.97-6 1.97v-3.97z" />
    </g>
  ),
  'directions-subway': (
    <g>
      <path d="M12 2c-4.42 0-8 .5-8 4v9.5c0 1.93 1.57 3.5 3.5 3.5l-1.5 1.5v.5h12v-.5l-1.5-1.5c1.93 0 3.5-1.57 3.5-3.5v-9.5c0-3.5-3.58-4-8-4zm-4.5 15c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm3.5-6h-5v-5h5v5zm5.5 6c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm1.5-6h-5v-5h5v5z" />
    </g>
  ),
  'directions-train': (
    <g>
      <path d="M4 15.5c0 1.93 1.57 3.5 3.5 3.5l-1.5 1.5v.5h12v-.5l-1.5-1.5c1.93 0 3.5-1.57 3.5-3.5v-10.5c0-3.5-3.58-4-8-4s-8 .5-8 4v10.5zm8 1.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-7h-12v-5h12v5z" />
    </g>
  ),
  'directions-transit': (
    <g>
      <path d="M12 2c-4.42 0-8 .5-8 4v9.5c0 1.93 1.57 3.5 3.5 3.5l-1.5 1.5v.5h12v-.5l-1.5-1.5c1.93 0 3.5-1.57 3.5-3.5v-9.5c0-3.5-3.58-4-8-4zm-4.5 15c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm3.5-6h-5v-5h5v5zm5.5 6c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm1.5-6h-5v-5h5v5z" />
    </g>
  ),
  'directions-walk': (
    <g>
      <path d="M14 3.8c.99 0 1.8-.81 1.8-1.8 0-1-.81-1.8-1.8-1.8-1 0-1.8.81-1.8 1.8s.8 1.8 1.8 1.8zm.12 6.2h4.88v-1.8h-3.62l-2-3.33c-.3-.5-.84-.83-1.46-.83-.17 0-.34.03-.49.07l-5.43 1.69v5.2h1.8v-3.67l2.11-.66-3.91 15.33h1.8l2.87-8.11 2.33 3.11v5h1.8v-6.41l-2.49-4.54.73-2.87 1.08 1.82z" />
    </g>
  ),
  earth: (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18.2c-1.12 0-2.19-.23-3.17-.64-.62-1.48-.61-2.64 1.12-2.06 0 0 3.92 1.52 8.04.08-1.49 1.61-3.62 2.62-5.99 2.62zm7.1-4.1c-.94.37-2.11 1-3.96 1-1.88 0-3.53-.75-5.58-1.4-1.86-.59-2.55-1.5-3.84-1.5-.66 0-.98.66-1.17 1.22-.47-1.04-.75-2.2-.75-3.42 0-.8.12-1.58.34-2.31 1.26-1.59 3.19-2.57 5.95-.43 0 0 6.23 4.67 9.79 4.97-.18.66-.45 1.29-.78 1.87zm-6.7-8.54c-2.8-2.65-5.1-1.91-6.09-1.46.75-.72 1.63-1.3 2.6-1.7 2.78-.1 5.91.44 7.65 2.91 0 0 2.42 4.18 3.29 2.34.22.74.34 1.53.34 2.34 0 .3-.02.59-.05.88-2.02-.22-4.81-2.54-7.74-5.31z" />
    </g>
  ),
  'explore-nearby': (
    <g>
      <path d="M12 7.2c-2.1 0-3.8 1.7-3.8 3.8 0 3 3.8 6.5 3.8 6.5s3.8-3.5 3.8-6.5c0-2.1-1.7-3.8-3.8-3.8zm0 5.3c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm0-10.5c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
    </g>
  ),
  flight: (
    <g>
      <path d="M10.18 9" />
      <path d="M21 16v-2l-8-5v-5.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v5.5l-8 5v2l8-2.5v5.5l-2 1.5v1.5l3.5-1 3.5 1v-1.5l-2-1.5v-5.5l8 2.5z" />
    </g>
  ),
  hotel: (
    <g>
      <path d="M7 13c1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3 1.34 3 3 3zm12-6h-8v7h-8v-9h-2v15h2v-3h18v3h2v-9c0-2.21-1.79-4-4-4z" />
    </g>
  ),
  layers: (
    <g>
      <path d="M11.99 18.54l-7.37-5.73-1.62 1.26 9 7 9-7-1.63-1.27-7.38 5.74zm.01-2.54l7.36-5.73 1.64-1.27-9-7-9 7 1.63 1.27 7.37 5.73z" />
    </g>
  ),
  'layers-clear': (
    <g>
      <path d="M19.81 14.99l1.19-.92-1.43-1.43-1.19.92 1.43 1.43zm-.45-4.72l1.64-1.27-9-7-2.91 2.27 7.87 7.88 2.4-1.88zm-16.09-9.27l-1.27 1.27 4.22 4.22-3.22 2.51 1.63 1.27 7.37 5.73 2.1-1.63 1.43 1.43-3.53 2.74-7.37-5.73-1.63 1.26 9 7 4.95-3.85 3.78 3.78 1.27-1.27-18.73-18.73z" />
    </g>
  ),
  'local-airport': (
    <g>
      <path d="M21 16v-2l-8-5v-5.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v5.5l-8 5v2l8-2.5v5.5l-2 1.5v1.5l3.5-1 3.5 1v-1.5l-2-1.5v-5.5l8 2.5z" />
    </g>
  ),
  'local-atm': (
    <g>
      <path d="M11 17h2v-1h1c.55 0 1-.45 1-1v-3c0-.55-.45-1-1-1h-3v-1h4v-2h-2v-1h-2v1h-1c-.55 0-1 .45-1 1v3c0 .55.45 1 1 1h3v1h-4v2h2v1zm9-13h-16c-1.11 0-1.99.89-1.99 2l-.01 12c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-12c0-1.11-.89-2-2-2zm0 14h-16v-12h16v12z" />
    </g>
  ),
  'local-attraction': (
    <g>
      <path d="M20 12c0-1.1.9-2 2-2v-4c0-1.1-.9-2-2-2h-16c-1.1 0-1.99.9-1.99 2v4c1.1 0 1.99.9 1.99 2s-.89 2-2 2v4c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-4c-1.1 0-2-.9-2-2zm-4.42 4.8l-3.58-2.3-3.58 2.3 1.08-4.12-3.29-2.69 4.24-.25 1.55-3.94 1.54 3.95 4.24.25-3.29 2.69 1.09 4.11z" />
    </g>
  ),
  'local-bar': (
    <g>
      <path d="M11 13v6h-5v2h12v-2h-5v-6l8-8v-2h-18v2l8 8zm-3.5-6l-2-2h13l-2 2h-9z" />
    </g>
  ),
  'local-cafe': (
    <g>
      <path d="M20 3h-16v10c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-3h2c1.11 0 2-.89 2-2v-3c0-1.11-.89-2-2-2zm0 5h-2v-3h2v3zm-18 13h18v-2h-18v2z" />
    </g>
  ),
  'local-car-wash': (
    <g>
      <path d="M17 5c.83 0 1.5-.67 1.5-1.5 0-1-1.5-2.7-1.5-2.7s-1.5 1.7-1.5 2.7c0 .83.67 1.5 1.5 1.5zm-5 0c.83 0 1.5-.67 1.5-1.5 0-1-1.5-2.7-1.5-2.7s-1.5 1.7-1.5 2.7c0 .83.67 1.5 1.5 1.5zm-5 0c.83 0 1.5-.67 1.5-1.5 0-1-1.5-2.7-1.5-2.7s-1.5 1.7-1.5 2.7c0 .83.67 1.5 1.5 1.5zm11.92 3.01c-.2-.59-.76-1.01-1.42-1.01h-11c-.66 0-1.21.42-1.42 1.01l-2.08 5.99v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zm-12.42 9.99c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm-12.5-5l1.5-4.5h11l1.5 4.5h-14z" />
    </g>
  ),
  'local-convenience-store': (
    <g>
      <path d="M19 7v-3h-14v3h-3v13h8v-4h4v4h8v-13h-3zm-8 3h-2v1h2v1h-3v-3h2v-1h-2v-1h3v3zm5 2h-1v-2h-2v-3h1v2h1v-2h1v5z" />
    </g>
  ),
  'local-drink': (
    <g>
      <path d="M3 2l2.01 18.23c.12 1 .96 1.77 1.99 1.77h10c1.03 0 1.87-.77 1.99-1.77l2.01-18.23h-18zm9 17c-1.66 0-3-1.34-3-3 0-2 3-5.4 3-5.4s3 3.4 3 5.4c0 1.66-1.34 3-3 3zm6.33-11h-12.66l-.44-4h13.53l-.43 4z" />
    </g>
  ),
  'local-florist': (
    <g>
      <path d="M12 22c4.97 0 9-4.03 9-9-4.97 0-9 4.03-9 9zm-6.4-11.75c0 1.38 1.12 2.5 2.5 2.5.53 0 1.01-.16 1.42-.44l-.02.19c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5l-.02-.19c.4.28.89.44 1.42.44 1.38 0 2.5-1.12 2.5-2.5 0-1-.59-1.85-1.43-2.25.84-.4 1.43-1.25 1.43-2.25 0-1.38-1.12-2.5-2.5-2.5-.53 0-1.01.16-1.42.44l.02-.19c0-1.38-1.12-2.5-2.5-2.5s-2.5 1.12-2.5 2.5l.02.19c-.4-.28-.89-.44-1.42-.44-1.38 0-2.5 1.12-2.5 2.5 0 1 .59 1.85 1.43 2.25-.84.4-1.43 1.25-1.43 2.25zm6.4-4.75c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5zm-9 7.5c0 4.97 4.03 9 9 9 0-4.97-4.03-9-9-9z" />
    </g>
  ),
  'local-gas-station': (
    <g>
      <path d="M19.77 7.23l.01-.01-3.72-3.72-1.06 1.06 2.11 2.11c-.94.36-1.61 1.26-1.61 2.33 0 1.38 1.12 2.5 2.5 2.5.36 0 .69-.08 1-.21v7.21c0 .55-.45 1-1 1s-1-.45-1-1v-4.5c0-1.1-.9-2-2-2h-1v-7c0-1.1-.9-2-2-2h-6c-1.1 0-2 .9-2 2v16h10v-7.5h1.5v5c0 1.38 1.12 2.5 2.5 2.5s2.5-1.12 2.5-2.5v-9.5c0-.69-.28-1.32-.73-1.77zm-7.77 2.77h-6v-5h6v5zm6 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
    </g>
  ),
  'local-grocery-store': (
    <g>
      <path d="M7 18c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2zm-6-16v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2h-11.58c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1h-14.79l-.94-2h-3.27zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z" />
    </g>
  ),
  'local-hospital': (
    <g>
      <path d="M19 3h-14c-1.1 0-1.99.9-1.99 2l-.01 14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-1 11h-4v4h-4v-4h-4v-4h4v-4h4v4h4v4z" />
    </g>
  ),
  'local-hotel': (
    <g>
      <path d="M7 13c1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3 1.34 3 3 3zm12-6h-8v7h-8v-9h-2v15h2v-3h18v3h2v-9c0-2.21-1.79-4-4-4z" />
    </g>
  ),
  'local-laundry-service': (
    <g>
      <path d="M9.17 16.83c1.56 1.56 4.1 1.56 5.66 0 1.56-1.56 1.56-4.1 0-5.66l-5.66 5.66zm8.83-14.82l-12-.01c-1.11 0-2 .89-2 2v16c0 1.11.89 2 2 2h12c1.11 0 2-.89 2-2v-16c0-1.11-.89-1.99-2-1.99zm-8 1.99c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-3 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm5 16c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z" />
    </g>
  ),
  'local-library': (
    <g>
      <path d="M12 11.55c-2.36-2.2-5.52-3.55-9-3.55v11c3.48 0 6.64 1.35 9 3.55 2.36-2.19 5.52-3.55 9-3.55v-11c-3.48 0-6.64 1.35-9 3.55zm0-3.55c1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3 1.34 3 3 3z" />
    </g>
  ),
  'local-mall': (
    <g>
      <path d="M19 6h-2c0-2.76-2.24-5-5-5s-5 2.24-5 5h-2c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-7-3c1.66 0 3 1.34 3 3h-6c0-1.66 1.34-3 3-3zm0 10c-2.76 0-5-2.24-5-5h2c0 1.66 1.34 3 3 3s3-1.34 3-3h2c0 2.76-2.24 5-5 5z" />
    </g>
  ),
  'local-movies': (
    <g>
      <path d="M18 3v2h-2v-2h-8v2h-2v-2h-2v18h2v-2h2v2h8v-2h2v2h2v-18h-2zm-10 14h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm10 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2z" />
    </g>
  ),
  'local-offer': (
    <g>
      <path d="M21.41 11.58l-9-9c-.36-.36-.86-.58-1.41-.58h-7c-1.1 0-2 .9-2 2v7c0 .55.22 1.05.59 1.42l9 9c.36.36.86.58 1.41.58.55 0 1.05-.22 1.41-.59l7-7c.37-.36.59-.86.59-1.41 0-.55-.23-1.06-.59-1.42zm-15.91-4.58c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    </g>
  ),
  'local-parking': (
    <g>
      <path d="M13 3h-7v18h4v-6h3c3.31 0 6-2.69 6-6s-2.69-6-6-6zm.2 8h-3.2v-4h3.2c1.1 0 2 .9 2 2s-.9 2-2 2z" />
    </g>
  ),
  'local-pharmacy': (
    <g>
      <path d="M21 5h-2.64l1.14-3.14-2.35-.86-1.46 4h-12.69v2l2 6-2 6v2h18v-2l-2-6 2-6v-2zm-5 9h-3v3h-2v-3h-3v-2h3v-3h2v3h3v2z" />
    </g>
  ),
  'local-phone': (
    <g>
      <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1v3.49c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
    </g>
  ),
  'local-pizza': (
    <g>
      <path d="M12 2c-3.57 0-6.77 1.54-8.99 4l8.99 16 8.99-16c-2.21-2.45-5.42-4-8.99-4zm-5 5c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm5 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
    </g>
  ),
  'local-play': (
    <g>
      <path d="M20 12c0-1.1.9-2 2-2v-4c0-1.1-.9-2-2-2h-16c-1.1 0-1.99.9-1.99 2v4c1.1 0 1.99.9 1.99 2s-.89 2-2 2v4c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-4c-1.1 0-2-.9-2-2zm-4.42 4.8l-3.58-2.3-3.58 2.3 1.08-4.12-3.29-2.69 4.24-.25 1.55-3.94 1.54 3.95 4.24.25-3.29 2.69 1.09 4.11z" />
    </g>
  ),
  'local-post-office': (
    <g>
      <path d="M20 4h-16c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm0 4l-8 5-8-5v-2l8 5 8-5v2z" />
    </g>
  ),
  'local-print-shop': (
    <g>
      <path d="M19 8h-14c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11h-8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9h-12v4h12v-4z" />
    </g>
  ),
  'local-restaurant': (
    <g>
      <path d="M8.1 13.34l2.83-2.83-7.02-7.01c-1.56 1.56-1.56 4.09 0 5.66l4.19 4.18zm6.78-1.81c1.53.71 3.68.21 5.27-1.38 1.91-1.91 2.28-4.65.81-6.12-1.46-1.46-4.2-1.1-6.12.81-1.59 1.59-2.09 3.74-1.38 5.27l-9.76 9.76 1.41 1.41 6.89-6.87 6.88 6.88 1.41-1.41-6.88-6.88 1.47-1.47z" />
    </g>
  ),
  'local-see': (
    <g>
      <circle cx="12" cy="12" r="3.2" />
      <path d="M9 2l-1.83 2h-3.17c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2h-3.17l-1.83-2h-6zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
    </g>
  ),
  'local-shipping': (
    <g>
      <path d="M20 8h-3v-4h-14c-1.1 0-2 .9-2 2v11h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5l-3-4zm-14 10.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm13.5-9l1.96 2.5h-4.46v-2.5h2.5zm-1.5 9c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    </g>
  ),
  'local-taxi': (
    <g>
      <path d="M18.92 6.01c-.2-.59-.76-1.01-1.42-1.01h-2.5v-2h-6v2h-2.5c-.66 0-1.21.42-1.42 1.01l-2.08 5.99v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zm-12.42 9.99c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm-12.5-5l1.5-4.5h11l1.5 4.5h-14z" />
    </g>
  ),
  'location-history': (
    <g>
      <path d="M19 2h-14c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h4l3 3 3-3h4c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-7 3.3c1.49 0 2.7 1.21 2.7 2.7 0 1.49-1.21 2.7-2.7 2.7-1.49 0-2.7-1.21-2.7-2.7 0-1.49 1.21-2.7 2.7-2.7zm6 10.7h-12v-.9c0-2 4-3.1 6-3.1s6 1.1 6 3.1v.9z" />
    </g>
  ),
  map: (
    <g>
      <path d="M20.5 3l-.16.03-5.34 2.07-6-2.1-5.64 1.9c-.21.07-.36.25-.36.48v15.12c0 .28.22.5.5.5l.16-.03 5.34-2.07 6 2.1 5.64-1.9c.21-.07.36-.25.36-.48v-15.12c0-.28-.22-.5-.5-.5zm-5.5 16l-6-2.11v-11.89l6 2.11v11.89z" />
    </g>
  ),
  'my-location': (
    <g>
      <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94v-2.06h-2v2.06c-4.17.46-7.48 3.77-7.94 7.94h-2.06v2h2.06c.46 4.17 3.77 7.48 7.94 7.94v2.06h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94h2.06v-2h-2.06zm-8.94 8c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
    </g>
  ),
  navigation: (
    <g>
      <path d="M12 2l-7.5 18.29.71.71 6.79-3 6.79 3 .71-.71z" />
    </g>
  ),
  'pin-drop': (
    <g>
      <path d="M18 8c0-3.31-2.69-6-6-6s-6 2.69-6 6c0 4.5 6 11 6 11s6-6.5 6-11zm-8 0c0-1.1.9-2 2-2s2 .9 2 2-.89 2-2 2c-1.1 0-2-.9-2-2zm-5 12v2h14v-2h-14z" />
    </g>
  ),
  place: (
    <g>
      <path d="M12 2c-3.87 0-7 3.13-7 7 0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
    </g>
  ),
  'rate-review': (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 18 4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-14 12v-2.47l6.88-6.88c.2-.2.51-.2.71 0l1.77 1.77c.2.2.2.51 0 .71l-6.89 6.87h-2.47zm12 0h-7.5l2-2h5.5v2z" />
    </g>
  ),
  'restaurant-menu': (
    <g>
      <path d="M8.1 13.34l2.83-2.83-7.02-7.01c-1.56 1.56-1.56 4.09 0 5.66l4.19 4.18zm6.78-1.81c1.53.71 3.68.21 5.27-1.38 1.91-1.91 2.28-4.65.81-6.12-1.46-1.46-4.2-1.1-6.12.81-1.59 1.59-2.09 3.74-1.38 5.27l-9.76 9.76 1.41 1.41 6.89-6.87 6.88 6.88 1.41-1.41-6.88-6.88 1.47-1.47z" />
    </g>
  ),
  satellite: (
    <g>
      <path d="M19 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-14 1.99h3c0 1.66-1.34 3.01-3 3.01v-3.01zm0 7.01v-2c2.76 0 5-2.25 5-5.01h2c0 3.87-3.13 7.01-7 7.01zm0 6l3.5-4.5 2.5 3.01 3.5-4.51 4.5 6h-14z" />
    </g>
  ),
  'store-mall-directory': (
    <g>
      <path d="M20 4h-16v2h16v-2zm1 10v-2l-1-5h-16l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4h-6v-4h6v4z" />
    </g>
  ),
  terrain: (
    <g>
      <path d="M14 6l-3.75 5 2.85 3.8-1.6 1.2c-1.69-2.25-4.5-6-4.5-6l-6 8h22l-9-12z" />
    </g>
  ),
  traffic: (
    <g>
      <path d="M20 10h-3v-1.14c1.72-.45 3-2 3-3.86h-3v-1c0-.55-.45-1-1-1h-8c-.55 0-1 .45-1 1v1h-3c0 1.86 1.28 3.41 3 3.86v1.14h-3c0 1.86 1.28 3.41 3 3.86v1.14h-3c0 1.86 1.28 3.41 3 3.86v1.14c0 .55.45 1 1 1h8c.55 0 1-.45 1-1v-1.14c1.72-.45 3-2 3-3.86h-3v-1.14c1.72-.45 3-2 3-3.86zm-8 9c-1.11 0-2-.9-2-2s.89-2 2-2c1.1 0 2 .9 2 2s-.89 2-2 2zm0-5c-1.11 0-2-.9-2-2s.89-2 2-2c1.1 0 2 .9 2 2s-.89 2-2 2zm0-5c-1.11 0-2-.9-2-2 0-1.11.89-2 2-2 1.1 0 2 .89 2 2 0 1.1-.89 2-2 2z" />
    </g>
  ),
  adb: (
    <g>
      <path d="M5 16c0 3.87 3.13 7 7 7s7-3.13 7-7v-4h-14v4zm11.12-11.63l2.1-2.1-.82-.83-2.3 2.31c-.94-.47-1.98-.75-3.1-.75s-2.16.28-3.09.75l-2.31-2.31-.82.83 2.1 2.1c-1.74 1.27-2.88 3.31-2.88 5.63v1h14v-1c0-2.32-1.14-4.36-2.88-5.63zm-7.12 4.63c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm6 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z" />
    </g>
  ),
  'bluetooth-audio': (
    <g>
      <path d="M14.24 12.01l2.32 2.32c.28-.72.44-1.51.44-2.33 0-.82-.16-1.59-.43-2.31l-2.33 2.32zm5.29-5.3l-1.26 1.26c.63 1.21.98 2.57.98 4.02s-.36 2.82-.98 4.02l1.2 1.2c.97-1.54 1.54-3.36 1.54-5.31-.01-1.89-.55-3.67-1.48-5.19zm-3.82 1l-5.71-5.71h-1v7.59l-4.59-4.59-1.41 1.41 5.59 5.59-5.59 5.59 1.41 1.41 4.59-4.59v7.59h1l5.71-5.71-4.3-4.29 4.3-4.29zm-4.71-1.88l1.88 1.88-1.88 1.88v-3.76zm1.88 10.46l-1.88 1.88v-3.76l1.88 1.88z" />
    </g>
  ),
  'disc-full': (
    <g>
      <path d="M20 16h2v-2h-2v2zm0-9v5h2v-5h-2zm-10-3c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
    </g>
  ),
  'dnd-forwardslash': (
    <g>
      <path d="M12 2c-5.5 0-10 4.5-10 10s4.5 10 10 10 10-4.5 10-10-4.5-10-10-10zm-8 10c0-4.4 3.6-8 8-8 1.8 0 3.5.6 4.9 1.7l-11.2 11.2c-1.1-1.4-1.7-3.1-1.7-4.9zm8 8c-1.8 0-3.5-.6-4.9-1.7l11.2-11.2c1.1 1.4 1.7 3.1 1.7 4.9 0 4.4-3.6 8-8 8z" />
    </g>
  ),
  'do-not-disturb': (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9l11.21 11.21c-1.35 1.06-3.05 1.69-4.9 1.69zm6.31-3.1l-11.21-11.21c1.35-1.06 3.05-1.69 4.9-1.69 4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z" />
    </g>
  ),
  'drive-eta': (
    <g>
      <path d="M18.92 5.01c-.2-.59-.76-1.01-1.42-1.01h-11c-.66 0-1.21.42-1.42 1.01l-2.08 5.99v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zm-12.42 9.99c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm-12.5-5l1.5-4.5h11l1.5 4.5h-14z" />
    </g>
  ),
  'event-available': (
    <g>
      <path d="M16.53 11.06l-1.06-1.06-4.88 4.88-2.12-2.12-1.06 1.06 3.18 3.18 5.94-5.94zm2.47-8.06h-1v-2h-2v2h-8v-2h-2v2h-1c-1.11 0-1.99.9-1.99 2l-.01 14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-11h14v11z" />
    </g>
  ),
  'event-busy': (
    <g>
      <path d="M9.31 17l2.44-2.44 2.44 2.44 1.06-1.06-2.44-2.44 2.44-2.44-1.06-1.06-2.44 2.44-2.44-2.44-1.06 1.06 2.44 2.44-2.44 2.44 1.06 1.06zm9.69-14h-1v-2h-2v2h-8v-2h-2v2h-1c-1.11 0-1.99.9-1.99 2l-.01 14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-11h14v11z" />
    </g>
  ),
  'event-note': (
    <g>
      <path d="M17 10h-10v2h10v-2zm2-7h-1v-2h-2v2h-8v-2h-2v2h-1c-1.11 0-1.99.9-1.99 2l-.01 14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-14v-11h14v11zm-5-5h-7v2h7v-2z" />
    </g>
  ),
  'folder-special': (
    <g>
      <path d="M20 6h-8l-2-2h-6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-10c0-1.1-.9-2-2-2zm-6.42 12l-3.58-2.1-3.58 2.1.95-4.07-3.16-2.74 4.16-.36 1.63-3.83 1.63 3.84 4.16.36-3.16 2.74.95 4.06z" />
    </g>
  ),
  mms: (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 18 4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-15 12l3.5-4.5 2.5 3.01 3.5-4.51 4.5 6h-14z" />
    </g>
  ),
  more: (
    <g>
      <path d="M22 3h-15c-.69 0-1.23.35-1.59.88l-5.41 8.12 5.41 8.11c.36.53.97.89 1.66.89h14.93c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-13 10.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm5 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm5 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    </g>
  ),
  'network-locked': (
    <g>
      <path d="M19.5 10c.17 0 .33.03.5.05v-9.05l-19 19h13v-3c0-.89.39-1.68 1-2.23v-.27c0-2.48 2.02-4.5 4.5-4.5zm2.5 6v-1.5c0-1.38-1.12-2.5-2.5-2.5s-2.5 1.12-2.5 2.5v1.5c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h5c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1zm-1 0h-3v-1.5c0-.83.67-1.5 1.5-1.5s1.5.67 1.5 1.5v1.5z" />
    </g>
  ),
  'phone-bluetooth-speaker': (
    <g>
      <path d="M14.71 9.5l2.29-2.29v3.79h.5l2.85-2.85-2.14-2.15 2.15-2.15-2.86-2.85h-.5v3.79l-2.29-2.29-.71.71 2.79 2.79-2.79 2.79.71.71zm3.29-6.59l.94.94-.94.94v-1.88zm0 4.3l.94.94-.94.94v-1.88zm2 8.29c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1-.37-1.12-.57-2.32-.57-3.57 0-.55-.45-1-1-1h-3.5c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1z" />
    </g>
  ),
  'phone-forwarded': (
    <g>
      <path d="M18 11l5-5-5-5v3h-4v4h4v3zm2 4.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1-.37-1.12-.57-2.32-.57-3.57 0-.55-.45-1-1-1h-3.5c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1z" />
    </g>
  ),
  'phone-in-talk': (
    <g>
      <path d="M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1-.37-1.12-.57-2.32-.57-3.57 0-.55-.45-1-1-1h-3.5c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zm-1-3.5h2c0-4.97-4.03-9-9-9v2c3.87 0 7 3.13 7 7zm-4 0h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3z" />
    </g>
  ),
  'phone-locked': (
    <g>
      <path d="M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1-.37-1.12-.57-2.32-.57-3.57 0-.55-.45-1-1-1h-3.5c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zm0-11.5v-.5c0-1.38-1.12-2.5-2.5-2.5s-2.5 1.12-2.5 2.5v.5c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h5c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1zm-.8 0h-3.4v-.5c0-.94.76-1.7 1.7-1.7s1.7.76 1.7 1.7v.5z" />
    </g>
  ),
  'phone-missed': (
    <g>
      <path d="M6.5 5.5l5.5 5.5 7-7-1-1-6 6-4.5-4.5h3.5v-1.5h-6v6h1.5v-3.5zm17.21 11.17c-3.05-2.89-7.17-4.67-11.71-4.67-4.54 0-8.66 1.78-11.71 4.67-.18.18-.29.43-.29.71s.11.53.29.71l2.48 2.48c.18.18.43.29.71.29.27 0 .52-.11.7-.28.79-.74 1.69-1.36 2.66-1.85.33-.16.56-.5.56-.9v-3.1c1.45-.48 3-.73 4.6-.73 1.6 0 3.15.25 4.6.72v3.1c0 .39.23.74.56.9.98.49 1.87 1.12 2.67 1.85.18.18.43.28.7.28.28 0 .53-.11.71-.29l2.48-2.48c.18-.18.29-.43.29-.71s-.12-.52-.3-.7z" />
    </g>
  ),
  'phone-paused': (
    <g>
      <path d="M17 3h-2v7h2v-7zm3 12.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1-.37-1.12-.57-2.32-.57-3.57 0-.55-.45-1-1-1h-3.5c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zm-1-12.5v7h2v-7h-2z" />
    </g>
  ),
  'play-download': (
    <g>
      <path d="M20 6h-4v-2l-2-2h-4l-2 2v2h-4c-1.11 0-1.99.89-1.99 2l-.01 11c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-11c0-1.11-.89-2-2-2zm-10-2h4v2h-4v-2zm2 15l-5-5h3v-4h4v4h3l-5 5z" />
    </g>
  ),
  'play-install': (
    <g>
      <path d="M20 6h-4v-2l-2-2h-4l-2 2v2h-4c-1.11 0-1.99.89-1.99 2l-.01 11c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2v-11c0-1.11-.89-2-2-2zm-10-2h4v2h-4v-2zm.5 13.5l-3.5-3.5 1.41-1.41 2.09 2.09 5.18-5.18 1.41 1.41-6.59 6.59z" />
    </g>
  ),
  'sd-card': (
    <g>
      <path d="M18 2h-8l-5.98 6-.02 12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-6 6h-2v-4h2v4zm3 0h-2v-4h2v4zm3 0h-2v-4h2v4z" />
    </g>
  ),
  'sim-card-alert': (
    <g>
      <path d="M18 2h-8l-5.98 6-.02 12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-5 15h-2v-2h2v2zm0-4h-2v-5h2v5z" />
    </g>
  ),
  sms: (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 18 4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-11 9h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z" />
    </g>
  ),
  'sms-failed': (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 18 4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-7 12h-2v-2h2v2zm0-4h-2v-4h2v4z" />
    </g>
  ),
  sync: (
    <g>
      <path d="M12 4v-3l-4 4 4 4v-3c3.31 0 6 2.69 6 6 0 1.01-.25 1.97-.7 2.8l1.46 1.46c.78-1.23 1.24-2.69 1.24-4.26 0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6 0-1.01.25-1.97.7-2.8l-1.46-1.46c-.78 1.23-1.24 2.69-1.24 4.26 0 4.42 3.58 8 8 8v3l4-4-4-4v3z" />
    </g>
  ),
  'sync-disabled': (
    <g>
      <path d="M10 6.35v-2.09c-.8.21-1.55.54-2.23.96l1.46 1.46c.25-.12.5-.24.77-.33zm-7.14-.94l2.36 2.36c-.77 1.22-1.22 2.67-1.22 4.23 0 2.21.91 4.2 2.36 5.64l-2.36 2.36h6v-6l-2.24 2.24c-1.08-1.09-1.76-2.58-1.76-4.24 0-1 .25-1.94.68-2.77l8.08 8.08c-.25.13-.5.25-.77.34v2.09c.8-.21 1.55-.54 2.23-.96l2.36 2.36 1.27-1.27-15.71-15.73-1.28 1.27zm17.14-1.41h-6v6l2.24-2.24c1.08 1.09 1.76 2.58 1.76 4.24 0 1-.25 1.94-.68 2.77l1.46 1.46c.77-1.22 1.22-2.67 1.22-4.23 0-2.21-.91-4.2-2.36-5.64l2.36-2.36z" />
    </g>
  ),
  'sync-problem': (
    <g>
      <path d="M3 12c0 2.21.91 4.2 2.36 5.64l-2.36 2.36h6v-6l-2.24 2.24c-1.08-1.09-1.76-2.58-1.76-4.24 0-2.61 1.67-4.83 4-5.65v-2.09c-3.45.89-6 4.01-6 7.74zm8 5h2v-2h-2v2zm10-13h-6v6l2.24-2.24c1.08 1.09 1.76 2.58 1.76 4.24 0 2.61-1.67 4.83-4 5.65v2.09c3.45-.89 6-4.01 6-7.74 0-2.21-.91-4.2-2.36-5.64l2.36-2.36zm-10 9h2v-6h-2v6z" />
    </g>
  ),
  'system-update': (
    <g>
      <path d="M17 1.01l-10-.01c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2v-18c0-1.1-.9-1.99-2-1.99zm0 17.99h-10v-14h10v14zm-1-6h-3v-5h-2v5h-3l4 4 4-4z" />
    </g>
  ),
  'tap-and-play': (
    <g>
      <path d="M2 16v2c2.76 0 5 2.24 5 5h2c0-3.87-3.13-7-7-7zm0 4v3h3c0-1.66-1.34-3-3-3zm0-8v2c4.97 0 9 4.03 9 9h2c0-6.08-4.92-11-11-11zm15-10.99l-10-.01c-1.1 0-2 .9-2 2v7.37c.69.16 1.36.37 2 .64v-6.01h10v13h-3.03c.52 1.25.84 2.59.95 4h2.08c1.1 0 2-.9 2-2v-17c0-1.1-.9-1.99-2-1.99z" />
    </g>
  ),
  'time-to-leave': (
    <g>
      <path d="M18.92 5.01c-.2-.59-.76-1.01-1.42-1.01h-11c-.66 0-1.21.42-1.42 1.01l-2.08 5.99v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zm-12.42 9.99c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm-12.5-5l1.5-4.5h11l1.5 4.5h-14z" />
    </g>
  ),
  vibration: (
    <g>
      <path d="M0 15h2v-6h-2v6zm3 2h2v-10h-2v10zm19-8v6h2v-6h-2zm-3 8h2v-10h-2v10zm-2.5-14h-9c-.83 0-1.5.67-1.5 1.5v15c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5v-15c0-.83-.67-1.5-1.5-1.5zm-.5 16h-8v-14h8v14z" />
    </g>
  ),
  'voice-chat': (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 18 4-4h14c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-2 12l-4-3.2v3.2h-8v-8h8v3.2l4-3.2v8z" />
    </g>
  ),
  'vpn-lock': (
    <g>
      <path d="M22 4v-.5c0-1.38-1.12-2.5-2.5-2.5s-2.5 1.12-2.5 2.5v.5c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1h5c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1zm-.8 0h-3.4v-.5c0-.94.76-1.7 1.7-1.7s1.7.76 1.7 1.7v.5zm-2.28 8c.04.33.08.66.08 1 0 2.08-.8 3.97-2.1 5.39-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1h-6v-2h2c.55 0 1-.45 1-1v-2h2c1.1 0 2-.9 2-2v-2.54c-.95-.3-1.95-.46-3-.46-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10c0-.34-.02-.67-.05-1h-2.03zm-8.92 8.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79l4.79 4.79v1c0 1.1.9 2 2 2v1.93z" />
    </g>
  ),
  cake: (
    <g>
      <path d="M12 7c1.1 0 2-.9 2-2 0-.38-.1-.73-.29-1.03l-1.71-2.97-1.71 2.97c-.19.3-.29.65-.29 1.03 0 1.1.9 2 2 2zm9 14v-4c0-1.1-.9-2-2-2h-1v-3c0-1.1-.9-2-2-2h-3v-2h-2v2h-3c-1.1 0-2 .9-2 2v3h-1c-1.1 0-2 .9-2 2v4h-2v2h22v-2h-2z" />
    </g>
  ),
  circles: (
    <g>
      <path d="M16.66 14.98c-.82 2.34-3.04 4.02-5.66 4.02-3.31 0-6-2.69-6-6 0-2.62 1.68-4.84 4.02-5.66l-.02-.34c0-1.01.2-1.98.54-2.87-4.27.7-7.54 4.4-7.54 8.87 0 4.97 4.03 9 9 9 4.47 0 8.17-3.27 8.87-7.54-.89.34-1.86.54-2.87.54l-.34-.02zm.34-13.98c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 9c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z" />
    </g>
  ),
  'circles-add': (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      <path d="M13 11v-3h-2v3h-3v2h3v3h2v-3h3v-2h-3z" />
    </g>
  ),
  'circles-extended': (
    <g>
      <path d="M12 10c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm-6 9c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm6-7.9c-1.05 0-1.9.85-1.9 1.9s.85 1.9 1.9 1.9c1.05 0 1.9-.85 1.9-1.9s-.85-1.9-1.9-1.9zm6 1.9c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
    </g>
  ),
  communities: (
    <g>
      <path d="M9 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm5-3c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2zm-2-7c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
    </g>
  ),
  domain: (
    <g>
      <path d="M12 7v-4h-10v18h20v-14h-10zm-6 12h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm4 12h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm10 12h-8v-2h2v-2h-2v-2h2v-2h-2v-2h8v10zm-2-8h-2v2h2v-2zm0 4h-2v2h2v-2z" />
    </g>
  ),
  group: (
    <g>
      <path d="M16 11c1.66 0 2.99-1.34 2.99-3s-1.33-3-2.99-3c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3s-1.33-3-2.99-3c-1.66 0-3 1.34-3 3s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5v2.5h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45v2.5h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
    </g>
  ),
  'group-add': (
    <g>
      <path d="M8 10h-3v-3h-2v3h-3v2h3v3h2v-3h3v-2zm10 1c1.66 0 2.99-1.34 2.99-3s-1.33-3-2.99-3c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86s-.34 2.04-.9 2.86c.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3s-1.33-3-2.99-3c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16c.83.73 1.38 1.66 1.38 2.84v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zm-6.62-.16c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z" />
    </g>
  ),
  'location-city': (
    <g>
      <path d="M15 11v-6l-3-3-3 3v2h-6v14h18v-10h-6zm-8 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm6 8h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm0-4h-2v-2h2v2zm6 12h-2v-2h2v2zm0-4h-2v-2h2v2z" />
    </g>
  ),
  mood: (
    <g>
      <path d="M11.99 2c-5.52 0-9.99 4.48-9.99 10s4.47 10 9.99 10c5.53 0 10.01-4.48 10.01-10s-4.48-10-10.01-10zm.01 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5h-10.22c.8 2.04 2.78 3.5 5.11 3.5z" />
    </g>
  ),
  notifications: (
    <g>
      <path d="M11.5 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6.5-6v-5.5c0-3.07-2.13-5.64-5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-2.87.68-5 3.25-5 6.32v5.5l-2 2v1h17v-1l-2-2z" />
    </g>
  ),
  'notifications-none': (
    <g>
      <path d="M11.5 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6.5-6v-5.5c0-3.07-2.13-5.64-5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-2.87.68-5 3.25-5 6.32v5.5l-2 2v1h17v-1l-2-2zm-2 1h-9v-6.5c0-2.49 2.01-4.5 4.5-4.5s4.5 2.01 4.5 4.5v6.5z" />
    </g>
  ),
  'notifications-off': (
    <g>
      <path d="M11.5 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6.5-11.5c0-3.07-2.13-5.64-5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-.51.12-.99.32-1.45.56l9.45 9.44v-3.68zm-.27 8.5l2 2 1.27-1.27-16.73-16.73-1.27 1.27 2.92 2.92c-.58.97-.92 2.1-.92 3.31v5.5l-2 2v1h14.73z" />
    </g>
  ),
  'notifications-on': (
    <g>
      <path d="M6.58 3.58l-1.43-1.43c-2.39 1.82-3.97 4.65-4.12 7.85h2c.15-2.65 1.51-4.97 3.55-6.42zm13.39 6.42h2c-.15-3.2-1.73-6.03-4.13-7.85l-1.43 1.43c2.05 1.45 3.41 3.77 3.56 6.42zm-1.97.5c0-3.07-2.13-5.64-5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-2.87.68-5 3.25-5 6.32v5.5l-2 2v1h17v-1l-2-2v-5.5zm-6.5 11.5c.14 0 .27-.01.4-.04.65-.13 1.19-.58 1.44-1.18.1-.24.16-.5.16-.78h-4c0 1.1.9 2 2 2z" />
    </g>
  ),
  'notifications-paused': (
    <g>
      <path d="M11.5 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6.5-6v-5.5c0-3.07-2.13-5.64-5-6.32v-.68c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68c-2.87.68-5 3.25-5 6.32v5.5l-2 2v1h17v-1l-2-2zm-4-6.2l-2.8 3.4h2.8v1.8h-5v-1.8l2.8-3.4h-2.8v-1.8h5v1.8z" />
    </g>
  ),
  pages: (
    <g>
      <path d="M3 5v6h5l-1-4 4 1v-5h-6c-1.1 0-2 .9-2 2zm5 8h-5v6c0 1.1.9 2 2 2h6v-5l-4 1 1-4zm9 4l-4-1v5h6c1.1 0 2-.9 2-2v-6h-5l1 4zm2-14h-6v5l4-1-1 4h5v-6c0-1.1-.9-2-2-2z" />
    </g>
  ),
  'party-mode': (
    <g>
      <path d="M20 4h-3.17l-1.83-2h-6l-1.83 2h-3.17c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm-8 3c1.63 0 3.06.79 3.98 2h-3.98c-1.66 0-3 1.34-3 3 0 .35.07.69.18 1h-2.08c-.06-.32-.1-.66-.1-1 0-2.76 2.24-5 5-5zm0 10c-1.63 0-3.06-.79-3.98-2h3.98c1.66 0 3-1.34 3-3 0-.35-.07-.69-.18-1h2.08c.07.32.1.66.1 1 0 2.76-2.24 5-5 5z" />
    </g>
  ),
  people: (
    <g>
      <path d="M16 11c1.66 0 2.99-1.34 2.99-3s-1.33-3-2.99-3c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3s-1.33-3-2.99-3c-1.66 0-3 1.34-3 3s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5v2.5h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45v2.5h6v-2.5c0-2.33-4.67-3.5-7-3.5z" />
    </g>
  ),
  'people-outline': (
    <g>
      <path d="M16.5 13c-1.2 0-3.07.34-4.5 1-1.43-.67-3.3-1-4.5-1-2.17 0-6.5 1.08-6.5 3.25v2.75h22v-2.75c0-2.17-4.33-3.25-6.5-3.25zm-4 4.5h-10v-1.25c0-.54 2.56-1.75 5-1.75s5 1.21 5 1.75v1.25zm9 0h-7.5v-1.25c0-.46-.2-.86-.52-1.22.88-.3 1.96-.53 3.02-.53 2.44 0 5 1.21 5 1.75v1.25zm-14-5.5c1.93 0 3.5-1.57 3.5-3.5s-1.57-3.5-3.5-3.5-3.5 1.57-3.5 3.5 1.57 3.5 3.5 3.5zm0-5.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm9 5.5c1.93 0 3.5-1.57 3.5-3.5s-1.57-3.5-3.5-3.5-3.5 1.57-3.5 3.5 1.57 3.5 3.5 3.5zm0-5.5c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z" />
    </g>
  ),
  person: (
    <g>
      <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
    </g>
  ),
  'person-add': (
    <g>
      <path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2v-3h-2v3h-3v2h3v3h2v-3h3v-2h-3zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
    </g>
  ),
  'person-outline': (
    <g>
      <path d="M12 5.9c1.16 0 2.1.94 2.1 2.1s-.94 2.1-2.1 2.1-2.1-.94-2.1-2.1.94-2.1 2.1-2.1m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1h-12.2v-1.1c0-.64 3.13-2.1 6.1-2.1m0-10.9c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z" />
    </g>
  ),
  'plus-one': (
    <g>
      <path d="M10 8h-2v4h-4v2h4v4h2v-4h4v-2h-4zm4.5-1.92v1.82l2.5-.5v10.6h2v-13z" />
    </g>
  ),
  poll: (
    <g>
      <path d="M19 3h-14c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm-10 14h-2v-7h2v7zm4 0h-2v-10h2v10zm4 0h-2v-4h2v4z" />
    </g>
  ),
  'post-blogger': (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-4 7v1c0 .55.45 1 1 1s1 .45 1 1v3c0 1.66-1.34 3-3 3h-6c-1.66 0-3-1.34-3-3v-7c0-1.66 1.34-3 3-3h4c1.66 0 3 1.34 3 3v1zm-6.05 1h2.6c.55 0 1-.45 1-1s-.45-1-1-1h-2.6c-.55 0-1 .45-1 1s.45 1 1 1zm4.05 3h-4.05c-.55 0-1 .45-1 1s.45 1 1 1h4.05c.55 0 1-.45 1-1s-.45-1-1-1z" />
    </g>
  ),
  'post-facebook': (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-1 2v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v7h-3v-7h-2v-3h2v-2.5c0-1.93 1.57-3.5 3.5-3.5h2.5z" />
    </g>
  ),
  'post-github': (
    <g>
      <path d="M7.2 6.6h-.1c-.5 1.4-.2 2.3-.1 2.6-.6.7-1 1.6-1 2.6 0 3.8 2.4 4.6 4.6 4.9-.2 0-.6.2-.8.8-.4.2-1.8.7-2.6-.7 0 0-.5-.8-1.3-.9 0 0-.8 0-.1.5 0 0 .6.3.9 1.3 0 0 .5 1.7 3 1.1v3.1h5v-3.5c0-1-.4-1.5-.8-1.8 2.2-.2 4.6-1 4.6-4.8 0-1.1-.4-2-1-2.6.1-.3.4-1.2-.1-2.6 0 0-.8-.3-2.7 1-.8-.2-1.6-.3-2.5-.3-.8 0-1.7.1-2.5.3-1.4-1-2.2-1-2.6-1zm12.8 15.4h-16c-1.1 0-2-.9-2-2v-16c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2z" />
    </g>
  ),
  'post-gplus': (
    <g>
      <path d="M11.2 8.87c0-1.02-.63-3.02-2.08-3.02-.64 0-1.32.44-1.32 1.67 0 1.18.63 2.93 1.97 2.93.06.01 1.43-.01 1.43-1.58zm-.63 4.94l-.31-.01h-.02c-.26 0-1.15.05-1.82.27-.65.24-1.42.72-1.42 1.7 0 1.08 1.04 2.23 2.96 2.23 1.52 0 2.44-1 2.44-1.97 0-.77-.46-1.24-1.83-2.22zm9.43-11.81h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-10.93 17.2c-2.8 0-4.07-1.56-4.07-3.01 0-.45.14-1.59 1.48-2.39.77-.47 1.85-.78 3.14-.91-.19-.25-.34-.55-.34-.99 0-.16.02-.31.05-.46h-.38c-1.97 0-3.15-1.55-3.15-3.04 0-1.73 1.29-3.6 4.11-3.6h4.21l-.34.34-.71.71-.06.06h-.71c.41.42.9 1.12.9 2.16 0 1.4-.74 2.09-1.56 2.73-.16.12-.42.38-.42.72 0 .3.24.5.39.62.13.11.3.22.47.34.81.57 1.92 1.34 1.92 2.88 0 1.77-1.29 3.84-4.93 3.84zm9.93-7.2h-2v2h-1v-2h-2v-1h2v-2h1v2h2v1z" />
    </g>
  ),
  'post-instagram': (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-8 6c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm-7.5 12c-.28 0-.5-.22-.5-.5v-8.5h2.09c-.05.33-.09.66-.09 1 0 3.31 2.69 6 6 6s6-2.69 6-6c0-.34-.04-.67-.09-1h2.09v8.5c0 .28-.22.5-.5.5h-15zm15.5-13.5c0 .28-.22.5-.5.5h-2c-.28 0-.5-.22-.5-.5v-2c0-.28.22-.5.5-.5h2c.28 0 .5.22.5.5v2z" />
    </g>
  ),
  'post-linkedin': (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-12 17h-3v-9h3v9zm-1.5-10.69c-1 0-1.81-.81-1.81-1.81s.81-1.81 1.81-1.81 1.81.81 1.81 1.81-.81 1.81-1.81 1.81zm12.5 10.69h-3v-5.3c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v5.3h-3v-9h3v1.2c.52-.84 1.59-1.4 2.5-1.4 1.93 0 3.5 1.57 3.5 3.5v5.7z" />
    </g>
  ),
  'post-pinterest': (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-7 14.2c-.8 0-1.57-.34-2.12-.92l-.95 3.2-.07.21-.03-.01c-.19.32-.54.52-.93.52-.61 0-1.1-.49-1.1-1.1l.01-.15h-.01l.05-.18 1.85-5.56s-.2-.61-.2-1.47c0-1.72.92-2.23 1.66-2.23.74 0 1.42.27 1.42 1.31 0 1.34-.89 2.03-.89 3 0 .74.6 1.34 1.34 1.34 2.33 0 3.16-1.76 3.16-3.41 0-2.18-1.88-3.95-4.2-3.95-2.32 0-4.2 1.77-4.2 3.95 0 .67.19 1.34.54 1.94.09.16.14.33.14.51 0 .55-.45 1-1 1-.36 0-.69-.19-.87-.5-.53-.9-.82-1.92-.82-2.96.02-3.27 2.8-5.94 6.22-5.94s6.2 2.67 6.2 5.95c0 2.63-1.63 5.45-5.2 5.45z" />
    </g>
  ),
  'post-tumblr': (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-4 9h-3v3.9c0 .73.14 1.1 1.1 1.1h1.9v3s-1.03.1-2.1.1c-2.65 0-3.9-1.62-3.9-3.4v-4.7h-2v-2.8c2.41-.2 2.62-2.04 2.8-3.2h2.2v3h3v3z" />
    </g>
  ),
  'post-twitter': (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-2.29 7.33c-.06 4.62-3.02 7.78-7.42 7.98-1.82.08-3.14-.5-4.28-1.23 1.34.21 3.01-.32 3.9-1.09-1.32-.13-2.1-.8-2.46-1.88.38.07.78.05 1.14-.03-1.19-.4-2.04-1.13-2.08-2.67.33.15.68.29 1.14.32-.9-.5-1.55-2.35-.8-3.57 1.32 1.45 2.91 2.63 5.52 2.79-.65-2.8 3.06-4.32 4.61-2.44.66-.13 1.19-.38 1.7-.65-.21.65-.62 1.1-1.12 1.47.54-.07 1.03-.21 1.44-.41-.25.53-.81 1.01-1.29 1.41z" />
    </g>
  ),
  'post-youtube': (
    <g>
      <path d="M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-1.49 15.5c-.45.15-3.73.5-6.51.5-2.77 0-6.05-.35-6.5-.5-1.17-.39-1.5-2.8-1.5-5.5s.33-5.11 1.5-5.5c.45-.15 3.73-.5 6.5-.5 2.78 0 6.06.36 6.51.51 1.17.39 1.49 2.79 1.49 5.49s-.32 5.11-1.49 5.5zm-8.51-2l5.5-3.5-5.5-3.5v7z" />
    </g>
  ),
  public: (
    <g>
      <path d="M12 2c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79l4.79 4.79v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1h-6v-2h2c.55 0 1-.45 1-1v-2h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z" />
    </g>
  ),
  school: (
    <g>
      <path d="M5 13.18v4l7 3.82 7-3.82v-4l-7 3.82-7-3.82zm7-10.18l-11 6 11 6 9-4.91v6.91h2v-8l-11-6z" />
    </g>
  ),
  share: (
    <g>
      <path d="M18 16.08c-.76 0-1.44.3-1.96.77l-7.13-4.15c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7l-7.05 4.11c-.54-.5-1.25-.81-2.04-.81-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z" />
    </g>
  ),
  whatshot: (
    <g>
      <path d="M13.5.67s.74 2.65.74 4.8c0 2.06-1.35 3.73-3.41 3.73-2.07 0-3.63-1.67-3.63-3.73l.03-.36c-2.02 2.4-3.23 5.51-3.23 8.89 0 4.42 3.58 8 8 8s8-3.58 8-8c0-5.39-2.59-10.2-6.5-13.33zm-1.79 18.33c-1.78 0-3.22-1.4-3.22-3.14 0-1.62 1.05-2.76 2.81-3.12 1.77-.36 3.6-1.21 4.62-2.58.39 1.29.59 2.65.59 4.04 0 2.65-2.15 4.8-4.8 4.8z" />
    </g>
  ),
};
