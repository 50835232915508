import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon } from '@iclinic/iclinic-ui';

const IconButton = ({ icon, onClick, className }) => (
  <span className={className} >
    <Button kind="wrap" onClick={onClick}>
      <Icon name={icon} />
    </Button>
  </span>
);

IconButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default IconButton;
