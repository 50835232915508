import React from 'react';

import { MaterialUICore, Box, Body, Heading } from '@iclinic/design-system';
import withContentLoader from 'shared/hoc/withContentLoader';
import { image } from 'shared/utils/urlGetter';
import { Physician } from 'features/onlinePayment/state/checkout/infos/types';
import { PhysicianInfosContentLoader } from './ContentLoaders';

const { styled } = MaterialUICore;

const PhysicianAvatar = styled('img')(() => ({
  height: '80px',
  width: '80px',
  borderRadius: '50%',
  objectFit: 'cover',
  marginRight: '1em',
}));

type PhysicianInfosProps = {
  physician: Physician | null;
};

function PhysicianInfos({
  physician,
}: PhysicianInfosProps): JSX.Element | null {
  if (!physician) return null;

  return (
    <Box
      display="flex"
      alignItems="center"
      data-testid="checkout-physician-infos"
    >
      <PhysicianAvatar
        src={physician.photo || image('onlinePayment.defaultAvatar')}
        alt={physician.name}
        title={physician.name}
      />
      <Box>
        <Heading variant="md">{physician.name}</Heading>
        <Body variant="xs">{physician.speciality}</Body>
      </Box>
    </Box>
  );
}

export default withContentLoader<PhysicianInfosProps>(
  PhysicianInfosContentLoader,
)(PhysicianInfos);
