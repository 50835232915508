/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled, ButtonBase } = MaterialUICore;
const { border, color, spacing } = tokens;

type OptionButtonProps = {
  selected?: boolean;
};

export const ImageWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',

  '& img': {
    width: 346,
  },
});

export const OptionsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.sm,

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    columnGap: spacing.md,
  },
}));

export const OptionButton = styled(
  ({
    selected,
    ...rest
  }: OptionButtonProps & MaterialUICore.ButtonBaseProps) => (
    <ButtonBase {...rest} />
  ),
)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    borderRadius: border.radius.md,
    width: '100%',
    padding: spacing.sm,
    display: 'flex',
    columnGap: spacing.sm,
    alignItems: 'center',
    transition: 'border .1s ease-in-out',
    border: (props: OptionButtonProps) =>
      props.selected
        ? `2px solid ${color.primary.main}`
        : `1px solid ${color.neutral[100]}`,

    [theme.breakpoints.up('sm')]: {
      padding: `${spacing.lg} ${spacing.sm}`,
      flexDirection: 'column',
      rowGap: spacing.base,
    },
  },

  '&>.MuiSvgIcon-root': {
    transition: 'color .2s ease-in-out',
    color: ({ selected }: OptionButtonProps) =>
      selected ? color.primary.main : color.neutral[300],
  },

  '&>.MuiTypography-root': {
    transition: 'color .2s ease-in-out',
    color: ({ selected }: OptionButtonProps) =>
      selected ? color.neutral[900] : color.neutral[300],
  },
}));
