import { GenericObjectDescription, GenericResponse, StringNull } from '../misc';

export enum LotViewActionKeys {
  FetchConfigOptions = 'fetchConfigOptions',
  FetchGuides = 'fetchGuides',
  CreateLot = 'createLot',
  EditLot = 'patchLotData',
  DownloadXML = 'downloadXML',
}

export enum ExecutantTypeOptions {
  Clinic = 'c',
  Professional = 'p',
}

export interface Lot {
  id: string;
  guides: string[];
  closing_user_id: StringNull;
  guide_type: string;
  guide_quantity: number;
  executant_type: ExecutantTypeOptions | null;
  lot_number: number;
  total_amount: string;
  is_closed: boolean;
  version: string;
  clinic_health_insurance: GenericObjectDescription & { tiss_version: string };
  clinic?: GenericObjectDescription;
  closed_date: string;
  executant_clinic?: GenericObjectDescription;
  hired_executant: {
    cnes: string | null;
    code: string | null;
    code_type: string | null;
    name: string | null;
  };
  executant_name: string;
  executant_code: string;
  executant_code_type: string;
  executant_cnes: string;
  quantity: number;
}

export interface LotConfig {
  clinic_health_insurance_id: string;
  executant_type: ExecutantTypeOptions | null;
  executant_name: string;
  executant_cnes: string | null;
  executant_code: string;
  executant_code_type: string;
  next_lot_code: number | null;
  version: string;
}

interface CreateLotConfigParams {
  insurance_name: string | null;
  insurance_ans_register: string | null;
  insurance_uses_sadt_team: boolean;
}

export interface CreateLotConfig extends LotConfig, CreateLotConfigParams {}

export interface ExecutantProfessional extends GenericObjectDescription {
  label: string;
  type: ExecutantTypeOptions;
}

export interface CreateLotPayloadRequest extends CreateLotConfigParams {
  guide_type: string;
  clinic_health_insurance_id: string;
  executant_type: ExecutantTypeOptions | null;
  executant_name: string;
  executant_cnes: string;
  executant_code: string;
  executant_code_type: string;
  version: string;
}

export interface EditLotPayloadRequest
  extends Partial<
    Omit<CreateLotPayloadRequest, 'guide_type' | 'clinic_health_insurance_id'>
  > {
  id: string;
  guides?: string[];
  hasSelectedAllGuides?: boolean;
  is_closed?: boolean;
  callback?: () => void;
}

export interface NewLotConfigDataResponse {
  clinic_health_insurances: Array<
    GenericObjectDescription & {
      clinic_provider_id: number | null;
      provider: ExecutantTypeOptions | null;
      provider_code: StringNull;
      provider_code_type: StringNull;
      tiss_version: StringNull;
    }
  >;
  physicians: GenericObjectDescription[];
  clinics: GenericObjectDescription[];
  versions: string[];
}

export interface CreateLotResponse {
  id: number;
}

export interface FetchLotListResponse extends GenericResponse {
  results: Lot[];
}

export interface PatchLotData
  extends Omit<
    Lot,
    | 'executant_professional'
    | 'clinic_health_insurance'
    | 'clinic'
    | 'quantity'
    | 'executant_clinic'
  > {
  clinic_health_insurance_id: number;
  clinic_id: number | null;
  executant_clinic_id: number | null;
  executant_professional_id: number | null;
}

export interface GuidesFilterOptions {
  patients: Array<GenericObjectDescription>;
  physicians: Array<GenericObjectDescription>;
}

export interface LotesFilterOptions {
  insurances: Array<GenericObjectDescription>;
  physicians: Array<GenericObjectDescription>;
}

export interface SimplifiedGuides {
  id: string;
  total_amount: string;
}

export interface FecthAvailableSimplifiedGuidesResponse {
  count: number;
  sum: number;
  results: SimplifiedGuides[];
}

export type FetchXMLFilter = 'date' | 'patient';

export interface FetchLotListQuery {
  page?: number;
  insurance_id?: number;
  professional_id?: number;
  date_from?: string;
  date_to?: string;
  is_closed?: boolean;
}

export interface FetchLotListRequestParams {
  page?: number;
  insurance_id?: number;
  professional_id?: number;
  start?: string;
  end?: string;
  is_closed?: boolean;
}
