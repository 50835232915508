// External
import React from 'react';
import { Logo } from '@iclinic/iclinic-ui';

// Styles
import ss from './Footer.scss';

/**
 * Main Application Footer
 */
const Footer = () => (
  <footer className={ss.footer}>
    <Logo width={121} kind="gray" showName className={ss.logo} />

    <a className={ss.link} href="http://suporte.iclinic.com.br/">Central de suporte</a>
    <a className={ss.link} href="#">Abrir chamado</a>
  </footer>
);

export default Footer;
