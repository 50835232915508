// Internal
import * as types from './types';

export const upgradeSuccessRefresh = () => ({
  type: types.UPGRADE_SUCCESS_REFRESH.ACTION,
});

export const upgradeReload = () => ({ type: types.UPGRADE_RELOAD.ACTION });

export const pending = () => ({
  type: types.FETCH_UPGRADE_PENDING.ACTION,
  payload: { isFetching: true },
});

export const openUpgradeDetail = (modal) => ({
  type: types.UPGRADE_DETAIL.OPEN,
  payload: {
    currentModal: modal,
    isOpen: true,
    step: 1,
    errors: [],
  },
  track: {
    label: modal,
    action: 'open',
    category: 'upgrade',
  },
});

export const closeUpgradeDetail = (modal) => ({
  type: types.UPGRADE_DETAIL.CLOSE,
  payload: {
    isOpen: false,
    step: 1,
    errors: [],
  },
  track: {
    label: modal,
    action: 'close',
    category: 'upgrade',
  },
});

export const openProductsView = () => ({
  type: types.PRODUCTS_VIEW.OPEN,
  payload: {
    step: 2,
  },
  track: {
    action: 'step',
    category: 'upgrade',
  },
});

export const openUpgradeModalView = () => {
  return {
    type: types.PRODUCTS_VIEW.OPEN,
    payload: {
      step: 2,
      isOpen: true,
    },
    track: {
      action: 'step',
      category: 'upgrade',
    },
  };
};

export const openSuccessView = () => ({
  type: types.PRODUCTS_VIEW.OPEN,
  payload: {
    isOpen: true,
    step: 3,
  },
  track: {
    action: 'success',
    category: 'upgrade',
  },
});

export const selectProduct = (selectedProductSlug) => ({
  type: types.SELECT_PRODUCT.ACTION,
  payload: {
    selectedProductSlug,
  },
});

export const selectProductSuccess = (selectedProduct) => ({
  type: types.SELECT_PRODUCT.SUCCESS,
  payload: {
    selectedProduct,
  },
  breadcrumb: {
    category: 'upgrade',
    message: `User successfull selected a product: ${
      selectedProduct && selectedProduct.name
    }`,
    level: 'info',
  },
});

export const fetchUpgradeConfirm = (products) => ({
  type: types.FETCH_UPGRADE_CONFIRM.ACTION,
  payload: {
    isFetching: true,
    products,
  },
  breadcrumb: {
    category: 'upgrade',
    message: 'User requested products to upgrade',
    level: 'info',
    data: products,
  },
});

export const fetchUpgradeConfirmSuccess = () => ({
  type: types.FETCH_UPGRADE_CONFIRM.SUCCESS,
  payload: {
    isFetching: false,
    step: 3,
  },
  breadcrumb: {
    category: 'upgrade',
    message: 'User successful upgrade',
    level: 'info',
  },
});

export const fetchUpgradeConfirmFailure = (errors) => ({
  type: types.FETCH_UPGRADE_CONFIRM.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
  track: {
    action: 'failure',
    category: 'upgrade',
  },
  breadcrumb: {
    category: 'upgrade',
    message: 'Upgrade confirm failure',
    level: 'error',
  },
});

export const fetchUpgradeDetail = (payload) => ({
  type: types.FETCH_UPGRADE_DETAIL.ACTION,
  payload,
});

export const fetchUpgradeDetailSuccess = (payload) => ({
  type: types.FETCH_UPGRADE_DETAIL.SUCCESS,
  payload: {
    isFetching: false,
    products: payload.products,
    userInfo: payload.userInfo,
    paymentInfo: payload.paymentInfo,
    isAnnual: payload.isAnnual,
  },
});

export const fetchUpgradeDetailFailure = (errors) => ({
  type: types.FETCH_UPGRADE_DETAIL.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export const fetchUpgradeNotification = (modal, product) => ({
  type: types.FETCH_UPGRADE_NOTIFICATION.ACTION,
  payload: {
    isFetching: true,
    errors: [],
  },
  params: {
    modal,
    product,
  },
});

export const fetchUpgradeNotificationSuccess = () => ({
  type: types.FETCH_UPGRADE_NOTIFICATION.SUCCESS,
  payload: {
    isFetching: false,
    errors: [],
  },
});

export const fetchUpgradeNotificationFailure = (errors) => ({
  type: types.FETCH_UPGRADE_NOTIFICATION.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});
