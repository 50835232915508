import React from 'react';
import VMasker from 'vanilla-masker';

export const unformat = (value: string) =>
  Number(value.replace('R$', '').replace(/\./g, '').replace(',', '.').trim());

export const format = (value: string) =>
  VMasker.toMoney(value, {
    precision: 2,
    separator: ',',
    delimiter: '.',
    unit: 'R$',
  });

const useCurrencyMask = (initialValue: number | undefined = 0) => {
  const [value, setValue] = React.useState(initialValue);
  const ref = React.useRef<HTMLInputElement | null>(null);

  const onChange = React.useCallback(() => {
    if (!ref.current) return ref;

    ref.current.value = format(ref.current.value);
    setValue(unformat(ref.current.value));

    return ref;
  }, [ref]);

  React.useEffect(() => {
    if (!ref.current) return;

    // eslint-disable-next-line consistent-return
    return () => {
      ref.current = null;
    };
  }, [ref]);

  return { ref, onChange, originalValue: value };
};

export default useCurrencyMask;
