import { tokens, MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export const useStyles = makeStyles(() => ({
  container: {
    boxShadow: `inset 0px -1px 0px ${tokens.color.neutral[100]}`,
    padding: `${tokens.spacing.sm} 0`,
  },
  button: {
    outline: 'none !important',
    color: tokens.color.neutral[700],
  },
}));
