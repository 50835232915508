import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const { color } = tokens;

export default makeStyles(() => ({
  root: {
    '& .MuiTooltip-popper': {
      pointerEvents: 'all',
      zIndex: 1,
    },
    '& .MuiTooltip-arrow': {
      color: color.neutral[0],
    },
  },
  whiteTooltip: {
    maxWidth: '100%',
    padding: '0',
    backgroundColor: color.neutral[0],
  },
}));
