// External
import React, { useContext } from 'react';

// Internal
import classnames from 'classnames/bind';
import ThemeContext, { UserThemeStyle } from 'shared/hooks/theme';
import ss from './Switch.scss';
import CHANGE_TAB_RECURRENCE from './constants';

const cx = classnames.bind(ss);

type SwitchProps = {
  classes?: string;
  toggleStatus: (CHANGE_TAB_RECURRENCE: any) => void;
  isMonthly: boolean;
};

export default function Switch(
  {
    toggleStatus,
    isMonthly,
  }: SwitchProps,
): JSX.Element {
  const { isApplied, style } = useContext(ThemeContext);

  return (
    <div className={ss.switch}>
      <button
        type="button"
        className={
          cx(ss.button,
            { [ss.selected]: isMonthly },
            UserThemeStyle(ss, style, isApplied))
        }
        onClick={() => toggleStatus(CHANGE_TAB_RECURRENCE.monthly)}
      >
        MENSAL
      </button>
      <button
        type="button"
        className={
          cx(ss.button,
            { [ss.selected]: !isMonthly },
            UserThemeStyle(ss, style, isApplied))
        }
        onClick={() => toggleStatus(CHANGE_TAB_RECURRENCE.annual)}
      >
        ANUAL
      </button>
    </div>
  );
}
