import React from 'react';
import PropTypes from 'prop-types';

import style from './SelectBox.scss';

const SelectBox = ({ id, value, onChange, children, disabled }) => (
  <div>
    <select id={id} className={style.selectBox} onChange={onChange} value={value} defaultValue={value} disabled={disabled}>
      { children }
    </select>
  </div>
);

SelectBox.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SelectBox;
