import React from 'react';
import { Loader as DSLoader } from '@iclinic/design-system';

import { CenterBox } from '../Flexbox';
import { TableCell, TableRow } from '../Table';

interface LoaderProps {
  isLoading: boolean;
  title: string;
}

const Loader = ({ isLoading, title }: LoaderProps) =>
  isLoading ? (
    <TableRow hover>
      <TableCell scope="row" align="center" colSpan={7}>
        <CenterBox>
          <DSLoader size={20} title={title} />
        </CenterBox>
      </TableCell>
    </TableRow>
  ) : null;

export default Loader;
