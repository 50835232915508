import { Kind, Question } from '../types';

const whoCanQuestion = {
  identifier: 'who-can',
  question: 'Quem pode participar do programa?',
  answer:
    'Apenas os profissionais da área da saúde poderão participar do Programa de Indicações da iClinic.\nOs demais usuários do software iClinic, como secretárias, administradores e outras profissões que não se enquadrem em profissionais da área da saúde não poderão participar do Programa.\nAlém disso, é expressamente vedada a participação de funcionários públicos e de empresas públicas ao programa de indicações, bem como de eventual premiação decorrente de licitações e/ou contrato com a Administração Pública.',
};

const whatDoIWinQuestion = {
  identifier: 'what-do-i-win',
  question: 'O que eu ganho indicando o iClinic?',
  answer:
    'As premiações acontecem a cada indicação, sendo as premiações por 1, 2, 3 ou 4 indicações de R$ 100 por indicação que assinou e na 5 indicação um prêmio de R$ 400 aliado a possibilidade de se tornar um embaixador iClinic.\nAs assinaturas são cumulativas e quanto mais você indicar, mais prêmios pode ganhar.',
};

const whenUseMyCodeQuestion = {
  identifier: 'when-use-my-code',
  question:
    'Quem usa o meu código exclusivo para assinar iClinic também ganha benefícios?',
  answer:
    'O amigo indicado vai ganhar 25% de desconto durante 3 meses no iClinic, independente do plano que escolher.',
};

export const questions: Record<Kind, Question[]> = {
  physician: [
    whoCanQuestion,
    whatDoIWinQuestion,
    whenUseMyCodeQuestion,
    {
      identifier: 'what-is-ambassador',
      question: 'O que significa poder ser um Embaixador iClinic?',
      answer:
        'Se tornando um embaixador iClinic o médico ganha acesso ao Plano Premium gratuitamente pelo tempo que se estende o seu contrato como embaixador. Além disso, o médico Embaixador iClinic passa a fazer parte da nossa marca, podendo participar de ações exclusivas.',
    },
  ],
  receptionist: [whoCanQuestion, whatDoIWinQuestion, whenUseMyCodeQuestion],
};
