import { IStoreState } from 'state/rootReducer';

export const getUserInfo = (state: IStoreState) => state.userInfo;

export const getLastEventTodayBoolean = (
  state: IStoreState,
): boolean | undefined => state.records.feedDataLayer.isLastEventToday;

export const getPhysicianAndClinicId = ({ userInfo }: IStoreState) => ({
  physicianId: userInfo.userData.physicianId,
  clinicId: userInfo.clinicId,
});
