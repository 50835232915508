import { combineReducers } from 'redux';

import personalizationReducer, {
  PersonalizationState,
} from './personalization';

export type ScheduleConfirmationState = {
  personalization: PersonalizationState;
};

const scheduleConfirmationReducer = combineReducers<ScheduleConfirmationState>({
  personalization: personalizationReducer,
});

export default scheduleConfirmationReducer;
