export const CUSTOM_TEXT = {
  total: 'total',
  normal: 'normal',
  promotion: 'promotion',
  total_column: 'total_column',
};

export const PROMOTION_TEXT_VALUES = {
  promotion: 'promotion',
  promotionProduct: 'promotionProduct',
};

export default 'normal';
