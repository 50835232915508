// External
import { CompositeDecorator } from 'draft-js';

// Internal
import { Link, Button, AutomatedTag } from './decorators';
import { linkStrategy, buttonStrategy, automatedTagStrategy } from './strategies';

const decorator = new CompositeDecorator([
  {
    strategy: linkStrategy,
    component: Link,
  },
  {
    strategy: buttonStrategy,
    component: Button,
  },
  {
    strategy: automatedTagStrategy,
    component: AutomatedTag,
  },
]);

export default decorator;
