export type IntercomParams = {
  eventName: string;
  additionalParam?: string | Record<string, string>;
};

interface IntercomSettings {
  app_id?: string | undefined;
  api_base?: string | undefined;
  background_color?: string | undefined;
  email?: string | undefined;
  phone?: string | undefined;
  created_at?: number | undefined;
  name?: string | undefined;
  user_id?: string | undefined;
}

interface IntercomCommandSignature {
  boot: (settings: IntercomSettings) => void;
  shutdown: () => void;
  update: (settings?: IntercomSettings) => void;
  hide: () => void;
  show: () => void;
  showMessages: () => void;
  showNewMessage: (prepopulateMessage?: string) => void;
  onHide: (callback: () => void) => void;
  onShow: (callback: () => void) => void;
  onUnreadCountChange: (callback: (unreadCount: number) => void) => void;
  onActivatorClick: (callback: () => void) => void;
  trackEvent: (tag?: string, metadata?: any) => void;
  getVisitorId: () => string;
  startTour: (tourId: number) => void;
  showArticle: (articleId: number) => void;
  startSurvey: (surveyId: number) => void;
}

type IntercomCommand = keyof IntercomCommandSignature;

export interface IntercomStatic {
  <Command extends IntercomCommand>(
    command: Command,
    ...params: Parameters<IntercomCommandSignature[Command]>
  ): ReturnType<IntercomCommandSignature[Command]>;
  booted: boolean;
}

export const dispatchIntercomEvent = ({
  eventName,
  additionalParam,
}: IntercomParams) => {
  if (!window.Intercom) return;

  if (additionalParam) {
    window.Intercom('trackEvent', eventName, additionalParam);
    return;
  }

  window.Intercom('trackEvent', eventName);
};

export const openIntercomBox = () => {
  window.Intercom('show');
};

export const openIntercomChat = () => {
  window.Intercom('showNewMessage');
};
