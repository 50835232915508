import React from 'react';
import { FormikErrors } from 'formik';

import { Professional } from 'state/marketing/emailFlow/professional/types';
import DropdownFlows from '../components/DropdownFlows';
import DropdownProfessionals from '../components/DropdownProfessionals';
import AddedFlows from '../components/AddedFlows';
import { FormValues } from '../containers/EmailFlowEnhance';

import style from './body.scss';

type BodyProps = {
  professionalsItems?: React.ReactNode,
  availableFlowItems?: React.ReactNode,
  addedFlowsItems?: React.ReactNode,
  selectedProfessional: Professional,
  errors: FormikErrors<FormValues>,
  professionalIsOpen: boolean,
  setProfessionalOpen: () => void,
  availableFlowIsOpen: boolean,
  setAvailableFlowIsOpen: () => void,
};

export default function Body({
  professionalsItems,
  availableFlowItems,
  addedFlowsItems,
  selectedProfessional,
  professionalIsOpen,
  setProfessionalOpen,
  availableFlowIsOpen,
  setAvailableFlowIsOpen,
  errors,
}: BodyProps): JSX.Element {
  return (
    <div className={style.body}>
      <p>
        Adicione seu paciente a fluxos de e-mails, o envio será automático.
        Se ainda não criou ou queira editar o conteúdo do e-mail e as definições
        de envio
        {' '}
        <a href="/marketing/sequencia-padrao/" className={style.body__link}>clique aqui</a>
      </p>
      <DropdownProfessionals
        selectedProfessional={selectedProfessional}
        hasAddedFlows={!!addedFlowsItems}
        isOpen={professionalIsOpen}
        toggleOpen={setProfessionalOpen}
      >
        {professionalsItems}
      </DropdownProfessionals>
      {errors.selectedProfessional
        && <div className={style.error}>{errors.selectedProfessional}</div>}

      {selectedProfessional && (
        <>
          <DropdownFlows
            isOpen={availableFlowIsOpen}
            toggleOpen={setAvailableFlowIsOpen}
          >
            {availableFlowItems}
          </DropdownFlows>
        </>
      )}

      {addedFlowsItems && (
        <AddedFlows>
          {addedFlowsItems}
        </AddedFlows>
      )}
      {errors.returnFlow && <div className={style.error}>{errors.returnFlow}</div>}
    </div>
  );
}
