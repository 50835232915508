import * as React from 'react';

import { getPatientRelativeName } from 'features/records/utils';
import { PatientRelative } from 'state/records/types';
import { RelatedContainer, Body } from './index.styles';

type RelatedModalProps = {
  show: boolean;
  patientRelated: Array<PatientRelative>;
};

export const RelatedModal = ({ show, patientRelated }: RelatedModalProps) => (
  <RelatedContainer style={{ display: show ? 'block' : 'none' }}>
    {patientRelated?.map((item) => (
      <Body
        key={`rc-${item.relatedPatientName}-${item.relatedness}`}
        variant="xxs"
      >
        {getPatientRelativeName(item.relatedness, item.relatedPatientName)}
      </Body>
    ))}
  </RelatedContainer>
);
