// External
import React, { useMemo, useEffect } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import moment from 'moment';

// Internal
import { StoreState } from 'state/rootReducer';
import { getActiveCampaign } from 'state/campaignManager/actions';
import { getCampaigns, getResource } from 'state/campaignManager/selectors';
import { Filters, Image, Text } from 'state/campaignManager/types';
import CampaignTopBar from 'ui/new/campaign-top-bar';

type StateProps = {
  campaignsImageDesktop: Image[];
  campaignsImageTablet: Image[];
  campaignsImageLarge: Image[];
  campaignsImageSmall: Image[];
  campaignsText: Text[];
  campaignsLink: Text[];
  endTrialDate: string | null;
};

type DispatchProps = {
  onGetActiveCampaign: () => void;
};

type Props = DispatchProps & StateProps;

const CampaignTopBarContainer = ({
  campaignsImageDesktop,
  campaignsImageTablet,
  campaignsImageLarge,
  campaignsImageSmall,
  campaignsText,
  campaignsLink,
  endTrialDate,
  onGetActiveCampaign,
}: Props) => {
  useEffect(() => {
    onGetActiveCampaign();
  }, [onGetActiveCampaign]);

  const daysLeft = useMemo(() => {
    if (endTrialDate) {
      const end = moment(endTrialDate, 'YYYY-MM-DD');
      const days = end.diff(moment(new Date()), 'days');
      return (moment.duration(days, 'days').asDays() + 1);
    }
    return 0;
  }, [endTrialDate]);

  return useMemo(() => {
    const checkCampaigns = campaignsImageDesktop?.length > 0
      && campaignsImageTablet?.length > 0
      && campaignsImageLarge?.length > 0
      && campaignsImageSmall?.length > 0;

    if (checkCampaigns) {
      const resourceValueText = getResource(campaignsText);
      const resourceValueLink = getResource(campaignsLink);
      return (
        <div>
          <CampaignTopBar
            isDefault={false}
            imageUrlDesktop={campaignsImageDesktop[0].image}
            imageUrlTablet={campaignsImageTablet[0].image}
            imageUrlLarge={campaignsImageLarge[0].image}
            imageUrlSmall={campaignsImageSmall[0].image}
            altText={resourceValueText}
            link={resourceValueLink}
          />
        </div>
      );
    }
    return <CampaignTopBar isDefault daysLeft={daysLeft} />;
  }, [
    campaignsImageDesktop,
    campaignsImageLarge,
    campaignsImageSmall,
    campaignsImageTablet,
    campaignsLink,
    campaignsText,
    daysLeft,
  ]);
};

const mapStateToProps: MapStateToProps<StateProps, {}, StoreState> = (state : StoreState) => ({
  campaignsImageDesktop: getCampaigns('img-desktop-top-bar', Filters.images)(state),
  campaignsImageTablet: getCampaigns('img-tablet-top-bar', Filters.images)(state),
  campaignsImageLarge: getCampaigns('img-large-top-bar', Filters.images)(state),
  campaignsImageSmall: getCampaigns('img-small-top-bar', Filters.images)(state),
  campaignsText: getCampaigns('alt-text-top-bar', Filters.texts)(state),
  campaignsLink: getCampaigns('link-top-bar', Filters.texts)(state),
  endTrialDate: state.userInfo.userData.endTrialDate,
});

const dispatchProps: DispatchProps = {
  onGetActiveCampaign: getActiveCampaign,
};

export default connect(
  mapStateToProps,
  dispatchProps,
)(CampaignTopBarContainer);
