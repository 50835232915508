import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { FETCH_AUDITREPORT_ERROR } from './errorMessages';

export interface ExportHomeState {
  exportDrawer: boolean;
  newExport: boolean;
  exportId: string | undefined;
  auditReport?: {
    data?: any | null;
    status: RequestStatus;
    error?: string;
  };
}

export const initialState: ExportHomeState = {
  exportDrawer: false,
  newExport: false,
  exportId: undefined,
  auditReport: {
    data: null,
    status: RequestStatus.Pending,
  },
};

const name = 'ExportHome';

export const init = createAction(`${name}/init`);

const HomeSlice = createSlice({
  name,
  initialState,
  reducers: {
    setExportDrawer: (state, action: PayloadAction<boolean>) => ({
      ...state,
      exportDrawer: action.payload,
    }),
    setNewExport: (state, action: PayloadAction<boolean>) => ({
      ...state,
      newExport: action.payload,
    }),
    setExportId: (state, action: PayloadAction<string>) => ({
      ...state,
      exportId: action.payload,
    }),
    sendAuditReport: (state) => ({
      ...state,
      auditReport: {
        status: RequestStatus.Pending,
      },
    }),
    sendAuditReportSuccess: (state) => ({
      ...state,
      auditReport: {
        status: RequestStatus.Success,
      },
    }),
    sendAuditReportFailure: (state) => ({
      ...state,
      auditReport: {
        status: RequestStatus.Error,
        error: FETCH_AUDITREPORT_ERROR,
      },
    }),
  },
});

export default HomeSlice.reducer;

export const {
  setExportDrawer,
  setNewExport,
  setExportId,
  sendAuditReport,
  sendAuditReportSuccess,
  sendAuditReportFailure,
} = HomeSlice.actions;
