// External
import { connect } from 'react-redux';

// Internal
import { searchNameDropdownSeenBy } from 'state/campaign/constants';
import * as selectors from 'state/campaign/selectors';
import FilterTags from '../components/audiences/FilterTags';

const title = 'Pacientes atendidos por';

const mapStateToProps = (state) => {
  const physiciansLabels = selectors.getAllPhysicians(state).map;
  const renderItemLabel = (physicianId) => (physiciansLabels[physicianId] || `desconhecido (${physicianId})`);
  return {
    availableItems: selectors.getAvailableFilterSeenBy(state),
    title,
    renderItemLabel,
    searchName: searchNameDropdownSeenBy,
  };
};

export default connect(mapStateToProps)(FilterTags);
