import { axiomTokens, Body, Box, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing, border, font } = axiomTokens;
export const Container = styled(Box)({
  borderRadius: border.radius.sm,
  padding: spacing['4'],
  paddingTop: 0,
  marginTop: spacing['4'],
});

export const ExpenseDescription = styled(Body)({
  userSelect: 'none',
});

export const ExpenseConfigInformation = styled(Box)({
  fontFamily: font.family.base,
  fontSize: font.size.xxxs,
  width: '280px',
  userSelect: 'none',
});
