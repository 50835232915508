import { Grid, MaterialIcons, MaterialUICore } from '@iclinic/design-system';
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import CollapsibleFormSection from 'features/tissInvoicing/components/ui/CollapsibleFormSection';
import { getDomainTablesOptions } from 'features/tissInvoicing/state/domainTables/selectors';
import ProfessionalExecutantName from 'features/tissInvoicing/containers/components/CommonAutocompletes/ProfessionalExecutantName';
import HiredProviderName from 'features/tissInvoicing/containers/components/CommonAutocompletes/HiredProviderName';
import { getCBO } from 'features/tissInvoicing/state/autocomplete/selectors';
import TextField from 'features/tissInvoicing/components/appointmentGuide/TextField';
import SelectField from 'features/tissInvoicing/components/appointmentGuide/SelectField';

const { CardTravel } = MaterialIcons;
const { MenuItem } = MaterialUICore;
interface HiredSectionProps {}

const HiredSection = (
  _: HiredSectionProps,
  ref: React.Ref<HTMLButtonElement>,
) => {
  const { states, councils } = useSelector(getDomainTablesOptions);
  const professionCodes = useSelector(getCBO);

  return (
    <CollapsibleFormSection
      sectionIcon={<CardTravel />}
      sectionIdentifier="hiredSection"
      sectionTitle="Dados do contratado"
      ref={ref}
    >
      <Grid container item spacing={2}>
        <Grid item xs={12} md={3}>
          <TextField
            identifier="hired_executant_code"
            label="9 - Código na operadora"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <HiredProviderName required />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            identifier="hired_executant_cnes"
            label="11 - Código da CNES"
            required
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={4}>
          <ProfessionalExecutantName required />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectField
            label="13 - Conselho"
            identifier="professional_executant_council"
            required
          >
            {councils.map((council) => (
              <MenuItem key={council.id} value={council.term_short}>
                {council.term_short}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            identifier="professional_executant_council_number"
            label="14 - Número"
            required
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectField
            label="15 - Estado"
            identifier="professional_executant_council_state"
            required
          >
            {states.map((state) => (
              <MenuItem key={state.id} value={state.uf}>
                {state.uf}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} md={2}>
          <SelectField
            label="16 - CBO-S"
            identifier="professional_executant_cbo"
            required
          >
            {professionCodes.map((cbo) => (
              <MenuItem key={cbo.code} value={cbo.code}>
                {cbo.code} - {cbo.term}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
      </Grid>
    </CollapsibleFormSection>
  );
};
export default forwardRef(HiredSection);
