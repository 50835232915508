import { MaterialUICore } from '@iclinic/design-system';

export const useStyles = MaterialUICore.makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 3),
    width: 534,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
