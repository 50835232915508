import React from 'react';

import { SnackbarProps } from 'features/tissInvoicing/types';
import { Body } from '@iclinic/design-system';

export const CLOSE_MESSAGE: SnackbarProps = {
  message: 'Lote fechado com sucesso',
  severity: 'success',
};

const CloseLot = () => (
  <Body variant="xs">
    Essa ação fecha a edição do lote, deseja concluir o fechamento?
  </Body>
);

export default CloseLot;
