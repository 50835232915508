import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Body, Box, Chip } from '@iclinic/design-system';

import { formatDateToString } from 'features/reports/utils';
import { getDateFilterType } from 'features/reports/state/telemedicineReport/selectors';
import { ChipsWrapper } from './Filters.styles';
import { quickFilterOptions } from './constants';
import {
  changeDateFilterType,
  fetchReportsWithNoDate,
  fetchTelemedicineReports,
} from 'features/reports/state/telemedicineReport/reducer';

export const QuickFilters = () => {
  const dispatch = useDispatch();
  const filterType = useSelector(getDateFilterType);
  const [selectedDate, setSelectedDate] = useState('');
  const today = formatDateToString(new Date());

  const isFilterActive = filterType === 'quick';
  const quickFilterData = useMemo(() => quickFilterOptions, []);

  const changeQuickHandler = (date = '') => {
    dispatch(changeDateFilterType('quick'));
    setSelectedDate(date);

    if (!date) {
      dispatch(fetchReportsWithNoDate());
      return;
    }

    dispatch(fetchTelemedicineReports({ fromDate: date, toDate: today }));
  };

  return (
    <Box>
      <Body variant="xxs">Período</Body>
      <ChipsWrapper>
        {quickFilterData.map((filter) => (
          <Chip
            key={filter.label}
            size="medium"
            active={isFilterActive && selectedDate === filter.value}
            label={filter.label}
            clickable
            onClick={() => changeQuickHandler(filter.value)}
          />
        ))}
      </ChipsWrapper>
    </Box>
  );
};
