import React from 'react';

import { Box, MaterialUICore } from '@iclinic/design-system';
import useStyles, * as S from './ControlledCheckbox.styles';

const { FormGroup, Checkbox } = MaterialUICore;

interface ControlledCheckboxProps {
  name?: string;
  label: React.ReactNode;
  checked?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  error?: boolean;
  errorMessage?: string | false;
  onBlur?: () => void;
}

export default function ControlledCheckbox({
  name,
  label,
  checked,
  onChange,
  error,
  errorMessage,
  onBlur,
}: ControlledCheckboxProps): JSX.Element {
  const classes = useStyles();

  return (
    <Box>
      <FormGroup row>
        <S.FormControlLabel
          control={
            <Checkbox
              className={error ? classes.checkboxError : ''}
              name={name}
              checked={checked}
              onChange={onChange}
              color="primary"
              onBlur={onBlur}
            />
          }
          label={label}
        />
      </FormGroup>

      {error && <S.ErrorText variant="xxs">{errorMessage}</S.ErrorText>}
    </Box>
  );
}
