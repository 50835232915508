// Internal
import * as types from './types';

export const setUnsubscribeUser = ({ recipient, unsubscribeToken, type }) => ({
  type: types.SET_UNSUBSCRIBE_USER.ACTION,
  payload: {
    errors: [],
    isFetching: false,
    unsubscribe: {
      recipient,
      unsubscribeToken,
      type,
    },
    feedback: {
      didProvideReason: false,
    },
  },
});

export const fetchUnsubscribe = (reason, otherReason) => ({
  type: types.FETCH_UNSUBSCRIBE.ACTION,
  params: {
    reason,
    otherReason,
  },
  payload: {
    isFetching: true,
    errors: [],
  },
});

export const fetchUnsubscribeFailure = (errors) => ({
  type: types.FETCH_UNSUBSCRIBE.FAILURE,
  payload: {
    errors,
    isFetching: false,
  },
});

export const fetchUnsubscribeSuccess = (params, didProvideReason) => ({
  type: types.FETCH_UNSUBSCRIBE.SUCCESS,
  params,
  payload: {
    errors: [],
    isFetching: false,
    feedback: {
      didProvideReason,
    },
  },
});
