import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  MaterialIcons,
  FloatingActionButton,
} from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';
import { actions } from 'features/tissInvoicing/state/lot';
import { getLotViewState } from 'features/tissInvoicing/state/lot/selectors';
import { CenterBox } from 'features/tissInvoicing/components/ui/Flexbox';
import { LotConfig } from 'features/tissInvoicing/types';
import { getClinicHealth } from 'features/tissInvoicing/state/sadt/selectors';

const { Attachment } = MaterialIcons;

const paramsExistsConfig = (config: LotConfig): boolean =>
  !!(
    config.clinic_health_insurance_id &&
    config.executant_name &&
    config.executant_type &&
    config.executant_code &&
    config.executant_code_type !== '' &&
    config.version
  );

function NoGuidesMessage() {
  const dispatch = useDispatch();
  const { show: clinicHealth } = useSelector(getClinicHealth);

  const {
    config,
    loading: { createLot: loading },
  } = useSelector(getLotViewState);

  return (
    <CenterBox flexDirection="column">
      <CenterBox flexDirection="column" mb={2}>
        <img
          src={image('tissInvoicing.createLotArt')}
          alt="Ilustração adicionar guias ao lote"
        />
        <Body variant="sm">
          Ainda não existem guias adicionadas nesse lote.
        </Body>
      </CenterBox>

      <FloatingActionButton
        name="createLot"
        onClick={() =>
          dispatch(
            actions.createLot({
              ...config,
              insurance_name: clinicHealth.health_insurance_name,
              insurance_ans_register: clinicHealth.health_insurance_register,
              insurance_uses_sadt_team: clinicHealth.uses_sadt_team,
            }),
          )
        }
        disabled={loading || !paramsExistsConfig(config)}
      >
        <Attachment /> &nbsp; Adicionar guias
      </FloatingActionButton>
    </CenterBox>
  );
}

export default NoGuidesMessage;
