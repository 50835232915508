import {
  DocumentProofRawData,
  DocumentStatus,
  SellerRawData,
} from 'features/onlinePayment/services/config/types';
import { SellerStatus } from 'features/onlinePayment/state/constants';

export enum StepStatus {
  Error = 'error',
  Success = 'success',
}

type StatusType = {
  profile: null | StepStatus;
  documents: null | StepStatus;
  bankAccount: null | StepStatus;
  paymentSettings: null | StepStatus;
};

export function getDocumentsStatus(
  status?: SellerStatus,
  documents?: DocumentProofRawData[] | null,
) {
  if (status === SellerStatus.Refused) {
    const documentsHasErros = documents?.some(
      (doc) => doc.status === DocumentStatus.REJECTED,
    );
    if (documentsHasErros) {
      return StepStatus.Error;
    }
    return StepStatus.Success;
  }
  if (status && status !== SellerStatus.Pending) {
    return StepStatus.Success;
  }
  return null;
}

export function getStatuses(
  profile?: SellerRawData | null,
  documents?: DocumentProofRawData[] | null,
) {
  const status: StatusType = {
    profile: null,
    documents: getDocumentsStatus(profile?.status, documents),
    bankAccount: null,
    paymentSettings: null,
  };

  if (profile) {
    status.profile = StepStatus.Success;
  }

  if (profile?.bank_account) {
    status.bankAccount = StepStatus.Success;
  }
  if (profile?.min_installment_value) {
    status.paymentSettings = StepStatus.Success;
  }

  return status;
}
