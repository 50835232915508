import React, { useEffect } from 'react';
import cn from 'classnames';

import { MaterialIcons } from '@iclinic/design-system';
import { useAddBodyClass } from 'shared/hooks';
import Body from './Body';
import Footer from './Footer';
import Header from './Header';
import style from './style.scss';
import { MODAL } from './constants';

type ClassesShapeProps = {
  wrapper?: string;
  modal?: string;
};

type ModalProps = {
  show?: boolean;
  lockOverflow?: boolean;
  fullscreen?: boolean;
  color?: string;
  showCloseIcon?: boolean;
  title?: string;
  width?: number;
  type?: MODAL;
  onKeyDown?: () => void;
  classes?: ClassesShapeProps;
  flexWidth?: boolean;
  children: React.ReactNode;
  onClose: () => void;
};

function Modal({
  show = false,
  lockOverflow = false,
  fullscreen = false,
  color = 'light',
  showCloseIcon = true,
  title = '',
  width = 600,
  type = MODAL.Info,
  onKeyDown = () => null,
  children,
  onClose,
  classes = {},
  flexWidth = false,
}: ModalProps): JSX.Element {
  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      const isEscAndOpenedModal = event.keyCode === 27 && show;

      if (isEscAndOpenedModal) onClose();
    };

    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [onClose, show]);

  useAddBodyClass(style.modal__blockedWebApp, show, lockOverflow);

  return show ? (
    <div className={cn(style.wrapper, classes.wrapper)}>
      <div
        className={cn(
          style.modal,
          classes.modal,
          style[`modal__${color}`],
          { [style.modal__flexWidth]: flexWidth },
          { [style.modal__fullscreen]: fullscreen },
        )}
        style={{ width }}
      >
        {showCloseIcon && (
          <div
            title="Fechar"
            className={style.modal__icon}
            onClick={onClose}
            onKeyDown={onKeyDown}
            role="button"
            tabIndex={0}
          >
            <MaterialIcons.Close className={style.modal__iconClose} />
          </div>
        )}
        {title && <Header type={type}>{title}</Header>}
        {children}
      </div>
    </div>
  ) : (
    <div />
  );
}

Modal.Body = Body;
Modal.Footer = Footer;

export default Modal;
