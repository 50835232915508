import axios, { AxiosResponse } from 'axios';

import { interceptorRequestAuthentication } from 'services/iclinic/interceptors';

export const apiPrescriptions = axios.create({
  baseURL: process.env.ICLINIC_API_URL_V3,
});

export const interceptorResponseTransformData = (response: AxiosResponse) => {
  const responseData = response.data?.data || response.data;
  return { ...response, data: responseData };
};

apiPrescriptions.interceptors.request.use(interceptorRequestAuthentication);
apiPrescriptions.interceptors.response.use(interceptorResponseTransformData);
