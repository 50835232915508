import axios from 'axios';

import {
  interceptorRequestAuthentication,
  interceptorResponseTransformData,
} from 'services/iclinic/interceptors';
import { GetPatientHeaderResponse } from './types';

const apiRecords = axios.create({
  baseURL: process.env.ICLINIC_API_URL,
});

apiRecords.interceptors.request.use(interceptorRequestAuthentication);
apiRecords.interceptors.response.use(interceptorResponseTransformData);

export const getPatientHeader = (patientId: number) =>
  apiRecords.get<GetPatientHeaderResponse>(
    `/patients/patient/${patientId}/header`,
  );
