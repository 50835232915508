// External
import React, { useState } from 'react';

// Internal
import classnames from 'classnames/bind';
import { ItemImg } from 'state/records/attachments/imageComparison/types';
import { SideMenu, SideButton } from 'ui/new/side-menu';
import Loader from 'ui/new/loader';
import ss from './DisplayImage.scss';

const cx = classnames.bind(ss);

type DisplayImageProps = {
  selectedImages: ItemImg,
  setModeCompare: () => void,
  displayCompareMode: boolean,
};

const DisplayImage = ({
  selectedImages,
  setModeCompare,
  displayCompareMode,
}: DisplayImageProps): JSX.Element => {
  const [isOpen, toogleIsOpen] = useState<boolean>(false);
  return (
    <div className={cx(ss['display-image'])}>
      <img
        className={cx(ss.image)}
        src={selectedImages.max_size_image}
        alt={selectedImages.description}
        title={selectedImages.description}
      />
      <div className={cx(ss.menu)}>
        <SideMenu
          active={false}
          onMouseEnter={() => toogleIsOpen(!isOpen)}
        >
          { !displayCompareMode && (
            <Loader color="white" />
          )}
          { displayCompareMode && (
            <SideButton
              idGa="prontuario-comparar_imagens-icone_comparar_imagens"
              onClick={setModeCompare}
              icon="compare"
              active={isOpen}
              label="Comparar"
            />
          )}
        </SideMenu>
      </div>
    </div>
  );
};

export default DisplayImage;
