import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { acceptTerms, setTerms } from 'state/userInfo/actions';
import { SERVICE_TERMS } from 'state/userInfo/constants';
import getTermsURL from '../utils';
import Alert from './Alert';

export default function TermsNotification(): JSX.Element {
  const dispatch = useDispatch();
  const listTerms = useSelector((state) => state.userInfo.listTerms);
  const isFetching = useSelector((state) => state.userInfo.isFetching);

  const termsText = (
    <>
      Consulte as atualizações do
      <strong>
        &nbsp;Termo de uso&nbsp;
      </strong>
      e confirme que você está em conformidade.
    </>
  );

  const policyText = (
    <>
      Consulte as atualizações da
      <strong>
        &nbsp;Política de Privacidade&nbsp;
      </strong>
      e confirme que você está em conformidade.
    </>
  );

  useEffect(() => {
    const storageTerms = JSON.parse(localStorage.getItem(SERVICE_TERMS));
    dispatch(setTerms(storageTerms));
  }, [dispatch]);

  function handlerAcceptTerms(term: any) {
    dispatch(acceptTerms(term));
  }

  return (
    <>
      {
        listTerms.length > 0 && (
          <Alert
            isLoading={isFetching}
            action={() => handlerAcceptTerms(listTerms[0])}
            actionText="Aceitar"
            variant="warning"
            extraActions={[
              {
                action: () => window.open(getTermsURL(listTerms[0].identifier), '_blank'),
                text: listTerms[0].identifier === 'terms' ? 'Revisar termo' : 'Revisar política',
              },
            ]}
            message={listTerms[0].identifier === 'terms' ? termsText : policyText}
          />
        )
      }
    </>
  );
}
