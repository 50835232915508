import axios, { AxiosResponse } from 'axios';

import {
  Clinic,
  ClinicFormValues,
  Professional,
  ProfessionalPayload,
} from './types';
import { interceptorRequestAuthentication } from 'services/iclinic/interceptors';
import { createRequestApi } from './state/requestTool';
import { fetchInfosZipCode } from 'services/iclinic/easyZipCode';
import { onlyNumbers } from 'shared/utils/formatters';
import { ZipCodeRawData } from 'services/iclinic/easyZipCode/types';

const api = axios.create({ baseURL: process.env.ICLINIC_API_URL || '' });
api.interceptors.request.use(interceptorRequestAuthentication);

export const getClinicDetails = (
  clinicId: number,
): Promise<AxiosResponse<Clinic>> => api.get(`/clinics/clinic/${clinicId}`);

export const getProfessionalDetails = (
  physicianId: string,
): Promise<AxiosResponse<Professional>> =>
  api.get(`/accounts/physician/${physicianId}`);

export const updateProfessional = (
  physicianId: string,
  values: ProfessionalPayload,
) => api.patch(`/accounts/physician/${physicianId}/`, values);

export const updateClinic = (clinicId: number, values: ClinicFormValues) =>
  api.patch(`/clinics/clinic/${clinicId}/`, values);

export const {
  reducer: zipCodeReducer,
  takeLatestSaga: getZipCodeSaga,
  useRequestApi: useGetZipCode,
} = createRequestApi<ZipCodeRawData, string>({
  name: 'cfmupdate/zipCode',
  stateSelector: (state) => state.cfmUpdate.zipCode,
  requestFn: (zipCode: string) => fetchInfosZipCode(onlyNumbers(zipCode)),
});
