import {
  FetchSellersRawData,
  FetchBillByEventIdRawData,
  BillRawData,
} from 'features/onlinePayment/services/manage/types';
import { Bill, Seller } from './types';

export const normalizeFetchSellerInfo = (
  data?: FetchSellersRawData,
): Seller | null => {
  if (!data?.results?.[0]) return null;
  const { id, status } = data.results[0];

  return { id, status };
};

export const normalizeBill = (bill: BillRawData): Bill => ({
  id: bill.id,
  status: bill.status,
  amountCharged: Number(bill.amount_charged),
});

export const normalizeFetchBill = (
  data: FetchBillByEventIdRawData,
): Bill | null => {
  const [rawBill] = data.results;

  if (!rawBill) return null;

  return normalizeBill(rawBill);
};
