import React from 'react';
import { MaterialIcons } from '@iclinic/design-system';

import * as S from './PaymentCard.styles';

interface PaymentCardProps {
  cardNumber?: string;
  cardName?: string;
  cardExpirationDate?: string;
  cardCVV?: string;
  isFlipped?: boolean;
}

const PaymentCard = ({
  cardCVV,
  cardExpirationDate,
  cardName,
  cardNumber,
  isFlipped = false,
}: PaymentCardProps) => (
  <S.Card>
    <S.CardContent flipped={isFlipped}>
      <S.CardFront>
        <MaterialIcons.CreditCard color="secondary" />

        <S.CardText variant="xxs" bold active={!!cardNumber}>
          {cardNumber || '---- ---- ---- ----'}
        </S.CardText>

        <S.CardFrontContent>
          <S.CardText variant="xxs" bold active={!!cardName}>
            {cardName || 'Nome no cartão'}
          </S.CardText>

          <S.CardText variant="xxs" bold active={!!cardExpirationDate}>
            {cardExpirationDate || 'MM/AA'}
          </S.CardText>
        </S.CardFrontContent>
      </S.CardFront>

      <S.CardBack>
        <S.CardBackContent>
          <S.CardText variant="xxs" bold active={!!cardCVV}>
            {cardCVV || '000'}
          </S.CardText>
        </S.CardBackContent>
      </S.CardBack>
    </S.CardContent>
  </S.Card>
);

export default PaymentCard;
