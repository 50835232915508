import React from 'react';
import { Button } from '@iclinic/design-system';

import { Container, MessageText } from './ErrorBoundary.styles';

export interface IErrorBoundaryProps {
  isError: boolean;
  message: string;
  isButtonRetryVisible?: boolean;
  buttonText?: string;
  children: JSX.Element;
  onRetry?: () => void;
}

export const ErrorBoundary = ({
  buttonText,
  children,
  isButtonRetryVisible,
  isError,
  message,
  onRetry,
}: IErrorBoundaryProps) => {
  if (isError) {
    return (
      <Container>
        <MessageText variant="xxs">{message}</MessageText>
        {isButtonRetryVisible && (
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={onRetry}
          >
            {buttonText}
          </Button>
        )}
      </Container>
    );
  }
  return children;
};

export default ErrorBoundary;
