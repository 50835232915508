// External
import { reduxForm, submit, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// Internal
import { StoreState } from 'state/rootReducer';
import { ProductUpdate, Unit, Inventory } from 'features/stock/state/types';
import { FORM_STOCK_EDIT } from 'shared/constants/forms';
import { changeModal } from '../state/actions';
import { getFilteredUnitsEdit } from '../state/selectors';
import { ModalType } from '../constants';

const mapStateToProps = (state: StoreState) => {
  const { selectedProduct } = state.stock;
  const aditionalInventoryData = {
    quantity: selectedProduct?.quantity || 0,
    expireInOneMonth: selectedProduct?.expireInOneMonth || 0,
    expired: selectedProduct?.expired || 0,
  };

  return {
    units: getFilteredUnitsEdit(state),
    initialValues: selectedProduct,
    aditionalInventoryData,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  submitForm: () => dispatch(submit(FORM_STOCK_EDIT)),
  deleteForm: () => dispatch(changeModal(ModalType.Delete)),
});

type EnhanceProps = {
  change: InjectedFormProps['change'];
  handleSubmit: InjectedFormProps['handleSubmit'];
  units?: Unit[];
  aditionalInventoryData: Omit<Inventory, 'lot'>;
  submitForm(): Dispatch;
  deleteForm(): Dispatch;
};

type StockFormReduxProps = {
  render(params: EnhanceProps): React.ReactNode;
};

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type MapDispatchToProps = {
  submitForm(): Dispatch;
  deleteForm(): Dispatch;
};

export default (submitAction: (product: ProductUpdate) => void) => {
  const StockFormRedux = ({
    render,
    change,
    handleSubmit,
    submitForm,
    deleteForm,
    aditionalInventoryData,
    units,
  }: StockFormReduxProps &
    MapStateToProps &
    MapDispatchToProps &
    InjectedFormProps) =>
    render({
      change,
      handleSubmit,
      submitForm,
      deleteForm,
      aditionalInventoryData,
      units,
    });

  const Form = reduxForm<{}, StockFormReduxProps>({
    form: FORM_STOCK_EDIT,
    onSubmit: (payload: ProductUpdate, dispatch: Dispatch) => {
      dispatch(submitAction(payload));
      dispatch(changeModal());
    },
  })(StockFormRedux);

  return connect(mapStateToProps, mapDispatchToProps)(Form);
};
