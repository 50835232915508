import { Body, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = tokens;

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${spacing.lg} ${spacing.sm}`,

  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
  },
}));

export const Subtitle = styled(Body)(({ theme }) => ({
  marginBottom: spacing.xs,

  [theme.breakpoints.up('sm')]: {
    marginBottom: spacing.md,
  },
}));
