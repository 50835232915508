import { PromiseResponse } from '@types';

import {
  FetchAppointmentGuideRequestParams,
  GetAppointmentGuideApiResponse,
} from 'features/tissInvoicing/types';
import { iclinicApi, apiTissInvoicing } from '..';
import { TissVersionParams } from 'features/tissInvoicing/types/guide/tissVersion';

export const fetchAppointmentGuideListData = ({
  limit,
  clinic,
  physician_id,
  page,
}: FetchAppointmentGuideRequestParams): PromiseResponse<GetAppointmentGuideApiResponse> =>
  apiTissInvoicing.get('appointment-guide/', {
    params: {
      limit,
      page: page || 1,
      clinic,
      physician_id,
    },
  });

export const fetchGetTissVersionsApiLegacy =
  (): PromiseResponse<TissVersionParams> => iclinicApi.get('tiss/versions');

export const deleteAppointmentguideService = ({
  id,
}: {
  id: string;
}): PromiseResponse<{}> => apiTissInvoicing.delete(`appointment-guide/${id}/`);
