import {
  MaterialUICore,
  axiomTokens,
  Heading as DSHeading,
  FloatingActionButton as DSFloatingActionButton,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, border, shadow, font, spacing } = axiomTokens;

export const Button = styled(DSFloatingActionButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  bottom: '76px',
  right: '20px',
  width: '48px',
  height: '48px',
  padding: spacing[1],
  border: `${border.width.xs} solid ${color.border.subtle}`,
  borderRadius: border.radius.circular,
  boxShadow: shadow.level[1],
  background: color.background[1],

  '&:hover': {
    width: '138px',
    background: color.interactive.accent.default,
    border: border.width.none,
    borderRadius: border.radius.xxl,
    boxShadow: shadow.level[4],

    '& span': {
      display: 'inline-flex',
      margin: `0 ${spacing[1]}`,
    },
  },

  [theme.breakpoints.down(1024)]: {
    display: 'none',
  },
}));

export const Heading = styled(DSHeading)({
  display: 'none',
  fontWeight: font.weight.bold,
  color: color.typeface.onInteractive.accent,
});
