import React from 'react';
import { useDispatch } from 'react-redux';

import {
  Body,
  Button,
  DialogActions,
  DialogContent,
} from '@iclinic/design-system';
import { closeModal } from 'state/billing/subscription/actions';
import { image } from 'shared/utils/urlGetter';
import { gotoAppRoute } from 'shared/utils/appRoutes';
import * as S from './SuccessModal.styles';

const SuccessModal = () => {
  const dispatch = useDispatch();

  return (
    <>
      <S.Header variant="sm">
        Parabéns, você acaba de garantir mais facilidade na sua rotina com
        iClinic!
      </S.Header>

      <DialogContent>
        <S.ImageWrapper>
          <img
            width={350}
            src={image('referrals.wantToIndicate')}
            alt="Venha ganhar mais"
          />
        </S.ImageWrapper>

        <Body variant="xs">
          Participe do nosso programa de indicações e tenha ainda mais
          benefícios! Sua nova indicação garante vantagens especiais, e você
          ganha descontos ou vale-prêmios.
        </Body>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="transparent"
          onClick={() => {
            dispatch(closeModal());
          }}
        >
          Quero começar a usar o iClinic
        </Button>

        <Button
          data-ga="referrals_subscription_success_modal_click"
          onClick={() => {
            gotoAppRoute('/programa-de-indicacoes');
          }}
        >
          Quero ganhar
        </Button>
      </DialogActions>
    </>
  );
};

export default SuccessModal;
