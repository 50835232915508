import { MaterialUICore, tokens, Body as DSBody } from '@iclinic/design-system';

const { styled, List: DSList } = MaterialUICore;

export const List = styled(DSList)({
  padding: `0px ${tokens.spacing.nano}`,
});

export const Body = styled(DSBody)({
  margin: `${tokens.spacing.md} ${tokens.spacing.xs}`,
});

export const DrawerContent = styled('div')({
  overflowY: 'auto',
});

export const PaginationContainer = styled('div')({
  display: 'flex',
  margin: `auto ${tokens.spacing.xs} ${tokens.spacing.base}`,
  justifyContent: 'center',
});
