// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import campaignsModals from 'features/campaign/modals';
import emailFlowsModals from 'features/marketing/modals';

const MODAL_COMPONENTS = {
  ...campaignsModals,
  ...emailFlowsModals,
};

/**
 * ModalRoot is used to render specific modal
 * when the 'open' action is dispatched
 * Based on Dan Abramov aproach explained on Stack Overflow
 *
 * https://stackoverflow.com/questions/35623656/
 * how-can-i-display-a-modal-dialog-in-redux-that-performs-asynchronous-actions/35641680
 */
const ModalRoot = ({ close, modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];
  return (
    <SpecificModal
      onCloseClick={close}
      onHide={close}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...modalProps}
    />
  );
};

ModalRoot.propTypes = {
  close: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  modalProps: PropTypes.any.isRequired,
};

export default ModalRoot;
