import { trackCustomEvent } from 'shared/utils/trackEvents';
import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';

export type UserData = {
  profileId: string | null;
  isSubscriber?: boolean | null;
};

type TrackSignupIClinicPayButtons = {
  userData: UserData;
  buttonName: string;
  buttonLocation?: string | null;
  additionalProperties?: string | null;
  action?: string | null;
};

type TrackSignupIClinicPayForm = {
  userData: UserData;
  flowType: string;
  stepName: string;
  stepNum?: number | null;
  stepValue?: string | null;
};

export const SIGNUP_ICLINICPAY_SCHEMA =
  'iglu:br.com.iclinicpay/signup/jsonschema/1-0-1';
export const USER_CONTEXT_SCHEMA = 'iglu:br.com.iclinic/user/jsonschema/1-0-0';
export const BUTTON_CLICK_SCHEMA =
  'iglu:br.com.iclinic/button_click/jsonschema/1-0-3';

const getUserContextTracker = (userData: UserData) => ({
  schema: USER_CONTEXT_SCHEMA,
  data: {
    user_id: userData.profileId,
    user_is_subscriber: userData.isSubscriber,
  },
});

const getFlowTypeTracker = (flowType: string) => {
  if (flowType === SellerType.PF) return 'physical_person';
  if (flowType === SellerType.PJ) return 'legal_person';

  return flowType;
};

export function trackSignupIClinicPayButtons({
  userData,
  buttonName,
  buttonLocation,
  additionalProperties,
}: TrackSignupIClinicPayButtons) {
  const userContext = getUserContextTracker(userData);

  trackCustomEvent(
    BUTTON_CLICK_SCHEMA,
    {
      button_name: buttonName,
      button_location: buttonLocation || null,
      additional_properties: additionalProperties || null,
    },
    [userContext],
  );
}

export function trackSignupIClinicPayForm({
  userData,
  flowType,
  stepName,
  stepNum,
  stepValue,
}: TrackSignupIClinicPayForm) {
  const userContext = getUserContextTracker(userData);
  const getFlowType = getFlowTypeTracker(flowType);

  trackCustomEvent(
    SIGNUP_ICLINICPAY_SCHEMA,
    {
      type: 'creation',
      flow_type: getFlowType,
      step_name: stepName,
      step_num: stepNum,
      step_value: stepValue,
    },
    [userContext],
  );
}
