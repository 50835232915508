import React from 'react';

import {
  MaterialUICore,
  Heading as HeadingDS,
  Body,
  Box,
  tokens,
} from '@iclinic/design-system';
import withContentLoader from 'shared/hoc/withContentLoader';
import { Event, Bill } from 'features/onlinePayment/state/checkout/infos/types';
import { EventInfosContentLoader } from './ContentLoaders';

const { styled } = MaterialUICore;
const { color } = tokens;

const Value = styled(HeadingDS)(() => ({
  color: color.primary.main,
}));

type EventInfosProps = {
  event: Event | null;
  bill: Bill | null;
};

function EventInfos({ event, bill }: EventInfosProps): JSX.Element | null {
  if (!event || !bill) return null;

  return (
    <>
      <Box
        mb={1}
        display="flex"
        justifyContent="space-between"
        data-testid="checkout-event-infos"
      >
        <Body variant="xs">Paciente:</Body>
        <Body variant="xs" bold>
          {event.patient.name}
        </Body>
      </Box>
      <Box mb={1} display="flex" justifyContent="space-between">
        <Body variant="xs">Dia:</Body>
        <Body variant="xs" bold>
          {event.day}
        </Body>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Body variant="xs">Horário:</Body>
        <Body variant="xs" bold>
          {event.time}
        </Body>
      </Box>
      <Box mt={2} pt={2} borderTop={`1px solid ${color.neutral[100]}`}>
        <Box display="flex" justifyContent="space-between">
          <Body variant="xs">Valor:</Body>
          <Value variant="md">{bill.total}</Value>
        </Box>
      </Box>
    </>
  );
}

export default withContentLoader<EventInfosProps>(EventInfosContentLoader)(
  EventInfos,
);
