import { Heading, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = tokens;

export const Header = styled(Heading)({
  padding: `${spacing.xs} ${spacing.xxl} ${spacing.xs} ${spacing.sm}`,
});

export const ImageWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});
