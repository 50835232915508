import { takeLatest, call, put } from 'redux-saga/effects';

import { sendReceiptEmailRequest } from 'features/onlinePayment/services/checkout';
import { SEND_EMAIL_ACTION } from './constants';
import { SendEmail } from './types';
import { sendEmailFailure, sendEmailSuccess } from './actions';
import handleErrors from './handleErrors';

export function* workerSendEmail({ params }: SendEmail) {
  try {
    yield call(sendReceiptEmailRequest, params);
    yield put(sendEmailSuccess());
  } catch (error) {
    const errorMessage = handleErrors(error);
    yield put(sendEmailFailure({ error: errorMessage }));
  }
}

export default function* successPaymentSagas() {
  yield takeLatest(SEND_EMAIL_ACTION, workerSendEmail);
}
