export const downloadXMLfile = (filename: string, data: BlobPart) => {
  const downloadElement = document.createElement('a');
  const blob = new Blob([`\uFEFF${data}`], { type: 'text/plain' });

  downloadElement.href = window.URL.createObjectURL(blob);
  downloadElement.download = filename;
  downloadElement.dataset.downloadurl = [
    'text/plain',
    downloadElement.download,
    downloadElement.href,
  ].join(':');

  downloadElement.click();
};
