import React from 'react';
import { Box, Body, MaterialIcons, tokens } from '@iclinic/design-system';

import { useWindowHeight } from 'features/video-conference/utils';
import { review } from 'features/video-conference/constants';
import useStyles from '../styles';

const { Star, StarBorder } = MaterialIcons;
const { color } = tokens;

type RatingStarsProps = {
  handlerSendRating: (star: number, shouldSetReviewSent: boolean) => void;
  starRating: number;
};

export default function RatingStars({
  handlerSendRating,
  starRating,
}: RatingStarsProps): JSX.Element {
  const stars = [1, 2, 3, 4, 5];
  const height = useWindowHeight();
  const classes = useStyles({ height });

  return (
    <Box display="flex" my={2} flexDirection="column">
      <Box display="flex">
        {stars.map((star) => (
          <Box
            key={`rating-index-${star}`}
            mr={star !== 5 ? 3 : 0}
            onClick={() => handlerSendRating(star, false)}
          >
            {star <= starRating ? (
              <Star
                style={{ color: color.feedback.warning.main }}
                data-testid={`star-icon-${star}`}
              />
            ) : (
              <StarBorder
                style={{ color: color.neutral[300] }}
                data-testid={`star-border-icon-${star}`}
              />
            )}
          </Box>
        ))}
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Body variant="xs" className={classes.feedbackSubtitle}>
          {review.veryBad}
        </Body>
        <Body variant="xs" className={classes.feedbackSubtitle}>
          {review.veryGood}
        </Body>
      </Box>
    </Box>
  );
}
