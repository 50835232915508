export const PAGINATION = {
  initialPage: 1,
};

// Common
export const FETCH_FAILURE = '@webapp/stock/FETCH_FAILURE';

// Modal
export const CURRENT_MODAL_ACTION = '@webapp/stock/CURRENT_MODAL_ACTION';

// Fetchs

export const FETCH_DETAIL_ACTION = '@webapp/stock/FETCH_DETAIL_ACTION';
export const FETCH_DETAIL_SUCCESS = '@webapp/stock/FETCH_DETAIL_SUCCESS';

export const CREATE_PRODUCT_ACTION = '@webapp/stock/CREATE_PRODUCT_ACTION';

export const UPDATE_PRODUCT_ACTION = '@webapp/stock/UPDATE_PRODUCT_ACTION';

export const DELETE_PRODUCT_ACTION = '@webapp/stock/DELETE_PRODUCT_ACTION';

export const FETCH_ESPECIFIC_STOCK_URL_ACTION =
  '@webapp/stock/FETCH_ESPECIFIC_STOCK_URL_ACTION';
export const FETCH_EXPECIFIC_STOCK_URL_SUCCESS =
  '@webapp/stock/FETCH_EXPECIFIC_STOCK_URL_SUCCESS';

export const CREATE_INVENTORY_ACTION = '@webapp/stock/CREATE_INVENTORY_ACTION';
export const CREATE_INVENTORY_SUCCESS =
  '@webapp/stock/CREATE_INVENTORY_SUCCESS';

export const FETCH_PRODUCTS_BY_NAME_ACTION =
  '@webapp/stock/FETCH_PRODUCTS_BY_NAME_ACTION';
export const FETCH_PRODUCTS_BY_NAME_SUCCESS =
  '@webapp/stock/FETCH_PRODUCTS_BY_NAME_SUCCESS';

export const CREATE_LOT_ACTION = '@webapp/stock/CREATE_LOT_ACTION';
export const CREATE_LOT_SUCCESS = '@webapp/stock/CREATE_LOT_SUCCESS';

// Combo
export const FETCH_STOCK_UNIT_ACTION = '@webapp/stock/FETCH_STOCK_UNIT_ACTION';
export const FETCH_STOCK_UNIT_SUCCESS =
  '@webapp/stock/FETCH_STOCK_UNIT_SUCCESS';

export const GET_PROFILE_AND_CLINIC_ACTION =
  '@webapp/stock/GET_PROFILE_AND_CLINIC_ACTION';

export const FETCH_PRODUCT_STOCK_ACTION =
  '@webapp/stock/FETCH_PRODUCT_STOCK_ACTION';
export const FETCH_PRODUCT_STOCK_SUCCESS =
  '@webapp/stock/FETCH_PRODUCT_STOCK_SUCCESS';

// Solo
export const SELECTED_LIST_ACTION = '@webapp/stock/SELECTED_LIST_ACTION';
export const SELECTED_LIST_SUCCESS = '@webapp/stock/SELECTED_LIST_SUCCESS';

export const SELECTED_ID_ACTION = '@webapp/stock/SELECTED_ID_ACTION';

export const SELECT_ALL_PRODUCTS_ACTION =
  '@webapp/stock/SELECT_ALL_PRODUCTS_ACTION';
export const SELECT_ALL_PRODUCTS_SUCCESS =
  '@webapp/stock/SELECT_ALL_PRODUCTS_SUCCESS';

// Filter
export const FETCH_INVENTORIES_BY_PRODUCT_ID_ACTION =
  '@webapp/stock/FETCH_INVENTORIES_BY_PRODUCT_ID_ACTION';
export const FETCH_INVENTORIES_BY_PRODUCT_ID_SUCCESS =
  '@webapp/stock/FETCH_INVENTORIES_BY_PRODUCT_ID_SUCCESS';

export const FETCH_STOCK_FILTER_ACTION =
  '@webapp/stock/FETCH_STOCK_FILTER_ACTION';
export const SELECTED_PAGE_ACTION = '@webapp/stock/SELECTED_PAGE_ACTION';

// Input Modal
export const CHANGE_PRODUCT_DESCRIPTION_ACTION =
  '@webapp/stock/CHANGE_PRODUCT_DESCRIPTION_ACTION';
export const SET_SELECTED_PRODUCT_ACTION =
  '@webapp/stock/SET_SELECTED_PRODUCT_ACTION';
export const CHANGE_PRODUCT_ACTION = '@webapp/stock/CHANGE_PRODUCT_ACTION';
export const CHANGE_LOT_DESCRIPTION_ACTION =
  '@webapp/stock/CHANGE_LOT_DESCRIPTION_ACTION';
export const SET_SELECTED_LOT_ACTION = '@webapp/stock/SET_SELECTED_LOT_ACTION';
export const CHANGE_LOT_ACTION = '@webapp/stock/CHANGE_LOT_ACTION';
export const CLEAR_MODAL_ACTION = '@webapp/stock/CLEAR_MODAL_ACTION';

// List
export const OPENED_PRODUCT_ID_ACTION =
  '@webapp/stock/OPENED_PRODUCT_ID_ACTION';
export const VIEW_LOTS_BY_PRODUCT_ID_ACTION =
  '@webapp/stock/VIEW_LOTS_BY_PRODUCT_ID_ACTION';
export const FETCH_STOCK_BY_PRODUCT_ID_ACTION =
  '@webapp/stock/FETCH_STOCK_BY_PRODUCT_ID_ACTION';
export const FETCH_STOCK_BY_PRODUCT_ID_SUCCESS =
  '@webapp/stock/FETCH_STOCK_BY_PRODUCT_ID_SUCCESS';

// History Inventories
export const FETCH_INVENTORIES_HISTORY_ACTION =
  '@webapp/stock/FETCH_INVENTORIES_HISTORY_ACTION';
export const FETCH_INVENTORIES_HISTORY_SUCCESS =
  '@webapp/stock/FETCH_INVENTORIES_HISTORY_SUCCESS';

export const FETCH_USER_ACTION = '@webapp/stock/FETCH_USER_ACTION';
export const FETCH_USER_SUCCESS = '@webapp/stock/FETCH_USER_SUCCESS';

export const SET_HISTORY_ACTION = '@webapp/stock/SET_HISTORY_ACTION';
