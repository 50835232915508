import { TwilioError } from 'twilio-video';
import { ApiErrors } from '@types';

export interface Participant {
  birthday?: string;
  city?: string;
  clinic?: string;
  email?: string;
  gender?: string;
  id: number;
  mobilePhone?: string;
  name: string;
  photo?: string;
  specialtyName?: string;
}

export type Schedule = {
  endDate: string;
  startDate: string;
};

export type FileUploaded = {
  id: number;
  fileName: string | undefined;
  thumb: string | undefined;
};

export type MessageType = 'text' | 'media';

export interface Media {
  filename: string;
  filetype: string;
  url: string;
}
export interface Message {
  sid: string;
  author: string;
  identity: string;
  dateCreated: Date;
  type: MessageType;
  body: string;
  file: Media | null;
}

export interface Annotation {
  at: number;
  message: string;
}

export type HandleSetAnnotation = Annotation[] | undefined;

export const MESSAGE_TYPE = {
  TEXT: 'text',
  MEDIA: 'media',
};

export enum RoomTypes {
  p2p = 'peer-to-peer',
  group = 'group-small',
}

export type Callback = (...args: any[]) => void;

export type ErrorCallback = (
  error: TwilioError | Error | ApiErrors,
  isTwilioError?: boolean,
) => void;

declare global {
  interface HTMLMediaElement {
    setSinkId?(sinkId: string): Promise<undefined>;
  }

  interface Error {
    code: undefined;
  }

  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}

export interface TelemedicineFeatureUsage {
  localParticipant: Participant;
  remoteParticipant: Participant;
  conferenceId: number;
  isPhysician: boolean;
  buttonName: string;
  buttonLocation?: string;
  action?: string;
  additionalProperties?: string;
}

export type Thumbnail = 'none' | 'blur' | 'image';

export interface BackgroundSettings {
  type: Thumbnail;
  index?: number;
}

export type CaptionType = 'caption-action' | 'caption-transcript';

export interface CaptionData {
  isCaptionMessage?: boolean;
  captionType: CaptionType;
  isCaptionActive: boolean;
  transcript: string;
}

export type LogError = {
  code: string;
  message: string;
  actionType: string;
};
