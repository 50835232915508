export const checkExpenseFormErrors = (state: any): boolean => {
  const hasProcedure = Boolean(state?.procedure?.id);
  const hasHealthInsurance = Boolean(state?.healthInsurance?.id);

  const hasInvalidExpense =
    !state?.expenses?.length ||
    state?.expenses?.some(
      ({ quantity, value, type }: any) => !quantity || !value || !type.id,
    );

  return !hasProcedure || !hasHealthInsurance || hasInvalidExpense;
};
