// External
import React from 'react';

// Internal
import style from './Steper.scss';

type SteperProps = {
  currentStep: string | null;
  nextStep: string | null;
};

export default function Steper({
  currentStep = '',
  nextStep = '',
}: SteperProps): JSX.Element {
  return (
    <div className={style.container}>
      <div className={style['text-container']}>
        <p className={style['current-step']}>{currentStep}</p>
        <p className={style['next-text']}>Próximo</p>
        <p className={style['next-step']}>{nextStep}</p>
      </div>
    </div>
  );
}
