import { MaterialUICore, Select, tokens } from '@iclinic/design-system';

import {
  CustomTextField,
  FlexContainer,
  inputHeight,
} from 'features/newFinance/components/styles';

const { styled } = MaterialUICore;

type DiscountContainerProps = {
  hasError: boolean;
  theme: MaterialUICore.Theme;
};
export const DiscountContainer = styled(FlexContainer)(
  ({ theme, hasError }: DiscountContainerProps) => ({
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: tokens.spacing.sm,
    marginBottom: hasError ? tokens.spacing.xs : '0',
    '& > span': {
      color: tokens.color.neutral[300],
      fontSize: tokens.font.size.xxs,
      marginRight: tokens.spacing.sm,
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
    },
  }),
);

export const CustomDiscountSelect = styled(Select)(() => ({
  width: '65px',
  height: inputHeight,
  '& > div': {
    color: tokens.color.neutral[700],
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    fontSize: tokens.font.size.xs,
  },
  '& > div > div': {
    padding: '7px 13px !important',
  },
}));

export const CustomDiscountTextField = styled(CustomTextField)(() => ({
  width: '100px',
  '& > div': {
    color: tokens.color.neutral[700],
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  '& p': {
    position: 'absolute',
    bottom: '-15px',
    right: '0',
  },
}));
