import { Dialog, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const CongratulationsModal = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    maxWidth: 670,
    width: '100%',
    zIndex: 1,
  },
}));

export const ImageWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',

  '& img': {
    width: 639,
  },
});

export const ConfettiContainer = styled('div')({
  position: 'fixed',
  right: '50%',
  left: '50%',
  zIndex: 1301,
  top: 0,
});
