// External
import { call, put, all, takeEvery, select, take } from 'redux-saga/effects';
import { Modifier, EditorState, convertFromRaw } from 'draft-js';
import uuid from 'uuid/v1';
import { ActionCreatorFailure, ApiErrors } from '@types';
import { DefineAction } from 'redux-define';

// Internal
import { typeStyleEditor } from 'ui/new/editor/constants';
import iclinic from 'services/iclinic';
import { emitErrors } from 'shared/utils/emitErrors';
import { captureException } from 'shared/utils/handlerErrors';
import history from 'routes/history';
import { routePath } from 'shared/utils/strings';
import { close } from 'state/modal/actions';
import { decorator } from 'ui/new/editor';
import { handlerErrors } from 'state/shared/sagas';
import {
  FETCH_LIST,
  FETCH_UPDATE,
  ACTIVE_EMAIL_FLOW,
  FETCH_PHYSICIANS,
  EDIT_EMAIL_FLOW,
  CANCEL_EMAIL_FLOW,
  FETCH_DETAILS,
  ADD_EMAIL_FLOW,
  FETCH_CREATE,
  DELETE_EMAIL_FLOW,
  FETCH_DELETE,
  DUPLICATE_EMAIL_FLOW,
  EDITOR_TEXT,
  FETCH_DELETE_COMPONENT,
  EDIT_EMAIL_FLOW_COMPONENT,
  FETCH_UPDATE_COMPONENT,
  FETCH_DETAILS_ADD_NEW_COMPONENT,
  FETCH_CREATE_COMPONENT,
  FETCH_DETAILS_UPDATE_COMPONENT,
  FETCH_SEND_TEST_EMAIL,
} from './types';
import {
  fetchListEmailFlow,
  fetchListEmailFlowSuccess,
  fetchListEmailFlowFailure,
  fetchUpdateEmailFlow,
  fetchUpdateEmailFlowSuccess,
  fetchUpdateEmailFlowFailure,
  fetchListPhysiciansSuccess,
  fetchListPhysiciansFailure,
  fetchDetailsEmailFlow,
  fetchDetailsEmailFlowFailure,
  fetchDetailsEmailFlowSuccess,
  fetchCreateEmailFlow,
  fetchCreateEmailFlowSuccess,
  fetchCreateEmailFlowFailure,
  fetchDeleteEmailFlow,
  fetchDeleteEmailFlowSuccess,
  fetchDeleteEmailFlowFailure,
  updateEntityLink,
  addEntityLinkEmptyText,
  addEntityLinkTextSelected,
  addEntityImage,
  fetchDeleteComponentFailure,
  fetchDeleteComponentSuccess,
  fetchUpdateComponent,
  fetchUpdateComponentSuccess,
  fetchUpdateComponentFailure,
  fetchDetailsEmailFlowClear,
  clearCurrentComponent,
  setActiveComponentsCard,
  fetchCreateComponent,
  fetchCreateComponentFailure,
  fetchCreateComponentSuccess,
  editActiveEmailFlow,
  forceSelection,
  setCurrentComponent,
  fetchSendTestEmailSuccess,
  fetchSendTestEmailFailure,
} from './actions';
import {
  Physician,
  EditEmailFlowAction,
  FetchUpdateEmailFlowAction,
  FetchDetailsEmailFlowAction,
  ActiveEmailFlowAction,
  UserProfile,
  FetchCreateEmailFlowAction,
  FetchDeleteEmailFlowAction,
  DuplicateEmailFlowAction,
  DeleteEmailFlowAction,
  RetrievedEmailFlowInfo,
  OnModalEditorLinkSubmitAction,
  OnModalEditorButtonSubmitAction,
  OnAutomatedTagChosenAction,
  OnModalEditorImageSubmitAction,
  FetchDeleteComponentAction,
  SaveEmailFlowComponentAction,
  FetchUpdateComponentAction,
  EmailFlowRoute,
  FetchCreateComponentAction,
  EmailFlowComponent,
  EmailFlow,
  FetchListEmailFlowAction,
  TestEmailInput,
  TestEmailComponentParams,
  CurrentComponentParams,
  FetchSendTestEmailActionReturn,
  Sender,
  ErrorValidateComponent,
} from './emailFlowTypes';
import {
  getAddEmailFlowModalName,
  getEmailFlow,
  getEmailFlowReturnValues,
  getCurrentEntityKey,
  getEditorSelection,
  getCurrentContentEditor,
  getEditorState,
  getCurrentEntityDecoratedMerge,
  getCurrentSelectionText,
  getCurrentEntity,
  getEditorUpdatedComponent,
  getFirstPhysicianSender,
  getCreatedEmailFlow,
  countComponentsEmailFlow,
  getCurrentComponent,
} from './selectors';
import { isInvalidComponent } from './utils';
import {
  getProfessionalSelected,
  getProfessionalSelectedId,
} from '../professional/selectors';
import { Professional } from '../professional/types';

const marketingRoute = '/app/marketing';

export function* clearComponent(id: string, physicianId: number) {
  yield put(setActiveComponentsCard(''));
  yield put(clearCurrentComponent());
  yield put(fetchDetailsEmailFlowClear());
  yield put(
    fetchDetailsEmailFlow({
      id,
      physicianId,
    }),
  );
}

export function* workerListEmailFlows({ params }: FetchListEmailFlowAction) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchListEmailFlows,
      params,
    );
    if (errors) {
      yield put(fetchListEmailFlowFailure(emitErrors(errors)));
    } else {
      const payload = getResponseData();
      yield put(fetchListEmailFlowSuccess(payload));
    }
  } catch (error) {
    yield put(fetchListEmailFlowFailure(emitErrors([error as ApiErrors])));
    captureException(error);
  }
}

export function* workerUpdateEmailFlow({ params }: FetchUpdateEmailFlowAction) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchUpdateEmailFlow,
      params,
    );
    if (errors) {
      yield put(fetchUpdateEmailFlowFailure(emitErrors(errors), params));
    } else {
      const payload = getResponseData();
      yield put(fetchUpdateEmailFlowSuccess(payload));
    }
  } catch (error) {
    yield put(
      fetchUpdateEmailFlowFailure(emitErrors([error as ApiErrors]), params),
    );
    captureException(error);
  }
}

export function* workerUserProfiles() {
  try {
    let fullList: Physician[] = [];
    let url;

    do {
      const { getResponseData, errors } = yield call(
        iclinic.campaign.fetchUserProfiles,
        url,
      );
      if (errors) {
        yield call(
          handlerErrors,
          errors as ApiErrors[],
          fetchListPhysiciansFailure as unknown as ActionCreatorFailure,
        );
        return;
      }

      const payload: UserProfile = getResponseData();
      fullList = fullList.concat(
        payload.objects.map(iclinic.campaign.decodeUserProfile),
      );

      url = payload.meta.next
        ? `/accounts/userprofile/?page=${payload.meta.page + 1}`
        : null;
    } while (url);
    yield put(fetchListPhysiciansSuccess(fullList));
  } catch (error) {
    yield put(fetchListPhysiciansFailure(emitErrors([error as ApiErrors])));
    captureException(error);
  }
}

export function* workerDetailsEmailFlow({
  params: { id, physicianId },
}: FetchDetailsEmailFlowAction) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchEmailFlow,
      {
        id,
        physicianId,
      },
    );
    if (errors) {
      yield put(fetchDetailsEmailFlowFailure(emitErrors(errors)));
    } else {
      const payload = getResponseData();
      yield put(fetchDetailsEmailFlowSuccess(payload));
      yield put(editActiveEmailFlow(payload.active));
    }
  } catch (error) {
    yield put(fetchDetailsEmailFlowFailure(emitErrors([error as ApiErrors])));
    captureException(error);
  }
}

export function* activeEmailFlows({
  payload: { id, active },
}: ActiveEmailFlowAction) {
  const professional: Professional = yield select(getProfessionalSelected);
  yield put(
    fetchDetailsEmailFlow({
      id: String(id),
      physicianId: professional.physician_id,
    }),
  );
  const fetchDetailsAction: DefineAction = yield take([
    FETCH_DETAILS.SUCCESS,
    FETCH_DETAILS.FAILURE,
  ]);
  if (fetchDetailsAction.type === FETCH_DETAILS.SUCCESS) {
    const emailFlowComponentsAmount: number = yield select(
      countComponentsEmailFlow,
    );
    if (!emailFlowComponentsAmount) {
      yield put(
        fetchUpdateComponentFailure(
          emitErrors([
            {
              code: '400917',
              message: '',
            },
          ]),
          emailFlowComponentsAmount,
        ),
      );
      return;
    }
  }
  const { emailFlow } = fetchDetailsAction.payload.emailFlowDetails;
  const changeStatusEmailFlow = {
    ...emailFlow,
    id,
    active,
    physicianId: professional.physician_id,
  };
  yield put(fetchUpdateEmailFlow(changeStatusEmailFlow));
  const action = yield take([FETCH_UPDATE.SUCCESS, FETCH_UPDATE.FAILURE]);
  if (action.type === FETCH_UPDATE.SUCCESS) {
    yield put(fetchListEmailFlow(professional));
  }
}

export function* cancelEmailFlowReturnSaga() {
  yield call([history, history.goBack]);
  yield put(setActiveComponentsCard(''));
  yield put(clearCurrentComponent());
  yield put(fetchDetailsEmailFlowClear());
}

export function* editEmailFlowReturnSaga({
  payload: { id, physicianId, flowType },
}: EditEmailFlowAction) {
  const route = EmailFlowRoute[flowType];
  yield call([history, history.push], {
    pathname: routePath(
      marketingRoute,
      'marketing',
      `${route}/${physicianId}/${id}/`,
    ),
  });
}

export function* workerCreateEmailFlow({ params }: FetchCreateEmailFlowAction) {
  try {
    const physicianId: number = yield select(getProfessionalSelectedId);
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchCreateEmailFlow,
      {
        physicianId,
        ...params,
      },
    );
    if (errors) {
      yield put(fetchCreateEmailFlowFailure(emitErrors(errors), params));
    } else {
      const payload = getResponseData();
      yield put(fetchCreateEmailFlowSuccess(payload, params));
    }
  } catch (error) {
    yield put(
      fetchCreateEmailFlowFailure(emitErrors([error as ApiErrors]), params),
    );
    captureException(error);
  }
}

export function* addEmailFlowSaga() {
  const name: string = yield select(getAddEmailFlowModalName);
  const sender: Sender = yield select(getFirstPhysicianSender);
  const physicianId: number = yield select(getProfessionalSelectedId);
  yield put(
    fetchCreateEmailFlow({
      name,
      active: false,
      sender,
    }),
  );
  const action: DefineAction = yield take([
    FETCH_CREATE.SUCCESS,
    FETCH_CREATE.FAILURE,
  ]);
  if (action.type === FETCH_CREATE.SUCCESS) {
    yield put(close());
    const { id } = yield select(getCreatedEmailFlow);
    yield call([history, history.push], {
      pathname: routePath(
        marketingRoute,
        'marketing',
        `sequencia-personalizada/${physicianId}/${id}/`,
      ),
    });
  }
}

export function* duplicateEmailFlowSaga({
  params: { id },
}: DuplicateEmailFlowAction) {
  const physicianId: number = yield select(getProfessionalSelectedId);
  const name: string = yield select(getAddEmailFlowModalName);
  yield put(fetchDetailsEmailFlow({ id, physicianId }));
  const fetchDetailsAction: DefineAction = yield take([
    FETCH_DETAILS.SUCCESS,
    FETCH_DETAILS.FAILURE,
  ]);
  if (fetchDetailsAction.type === FETCH_DETAILS.SUCCESS) {
    const { sender, components }: RetrievedEmailFlowInfo = yield select(
      getEmailFlow,
    );
    yield put(
      fetchCreateEmailFlow({
        name,
        active: true,
        sender,
        components,
      }),
    );
    const createEmailFlowAction: DefineAction = yield take([
      FETCH_CREATE.SUCCESS,
      FETCH_CREATE.FAILURE,
    ]);
    if (createEmailFlowAction.type === FETCH_CREATE.SUCCESS) {
      yield put(close());
      const { id: createdId } = yield select(getCreatedEmailFlow);
      yield call([history, history.push], {
        pathname: routePath(
          marketingRoute,
          'marketing',
          `sequencia-personalizada/${physicianId}/${createdId}/`,
        ),
      });
    }
  }
}

export function* workerDeleteEmailFlow({ params }: FetchDeleteEmailFlowAction) {
  try {
    const physicianId: number = yield select(getProfessionalSelectedId);
    const emailFlowId = params.id;
    const { errors } = yield call(
      iclinic.campaign.fetchDeleteEmailFlow,
      physicianId,
      emailFlowId,
    );
    if (errors) {
      yield put(fetchDeleteEmailFlowFailure(emitErrors(errors), params));
    } else {
      yield put(fetchDeleteEmailFlowSuccess(params));
    }
  } catch (error) {
    yield put(
      fetchDeleteEmailFlowFailure(emitErrors([error as ApiErrors]), params),
    );
    captureException(error);
  }
}

export function* deleteEmailFlowSaga({
  params: { id },
}: DeleteEmailFlowAction) {
  const professional: Professional = yield select(getProfessionalSelected);
  yield put(fetchDeleteEmailFlow({ id }));
  const action: DefineAction = yield take([
    FETCH_DELETE.SUCCESS,
    FETCH_DELETE.FAILURE,
  ]);
  if (action.type === FETCH_DELETE.SUCCESS) {
    yield put(close());
  }
  yield put(fetchListEmailFlow(professional));
}

export function* editEmailFlowSaga() {
  const emailFlow: EmailFlow = yield select(getEmailFlow);
  const formFields: Record<string, unknown> = yield select(
    getEmailFlowReturnValues,
  );
  const { components } = emailFlow;

  if (formFields.activeEmailFlow && !components?.length) {
    yield put(close());
    yield put(
      fetchUpdateComponentFailure(
        emitErrors([
          {
            code: '400917',
            message: '',
          },
        ]),
        emailFlow,
      ),
    );
    return;
  }

  const messageInvalidComponent: ErrorValidateComponent = yield call(
    isInvalidComponent,
    components,
  );

  if (messageInvalidComponent) {
    const { customMessage, errorsList } = messageInvalidComponent;
    yield put(close());
    yield put(
      fetchUpdateComponentFailure(
        emitErrors(errorsList, customMessage),
        emailFlow,
      ),
    );
    return;
  }

  const updateFields = {
    id: emailFlow.id,
    physicianId: emailFlow.physicianId,
    sender: formFields.sender,
    active: formFields.activeEmailFlow,
  };

  yield put(fetchUpdateEmailFlow(updateFields));
  const action: DefineAction = yield take([
    FETCH_UPDATE.SUCCESS,
    FETCH_UPDATE.FAILURE,
  ]);
  if (action.type === FETCH_UPDATE.SUCCESS) {
    yield call([history, history.goBack]);
    yield call(clearComponent, emailFlow.id, emailFlow.physicianId);
    yield put(close());
  }
}

export function* workerSendTestEmail({
  meta: { physicianId, recipient },
}: FetchSendTestEmailActionReturn) {
  const { id, emailFlowId, clinicId }: CurrentComponentParams = yield select(
    getCurrentComponent,
  );
  const params: TestEmailComponentParams = {
    id: emailFlowId,
    clinicId,
    physicianId,
    componentId: id,
  };
  const testEmailInput: TestEmailInput = { params, recipient };
  try {
    const { errors } = yield call(
      iclinic.campaign.fetchSendTestEmailFlow,
      testEmailInput,
    );
    if (errors) throw errors;
    yield put(fetchSendTestEmailSuccess(testEmailInput));
    yield put(close());
    yield call([history, history.goBack]);
  } catch (error) {
    yield call(handlerErrors, error, fetchSendTestEmailFailure);
  }
}

export function* onModalEditorLinkSubmitSaga({
  payload: { url, textSelected },
}: OnModalEditorLinkSubmitAction) {
  const currentEntityKey: string = yield select(getCurrentEntityKey);
  const currentSelection = yield select(getEditorSelection);
  const currentContent = yield select(getCurrentContentEditor);
  const editorState = yield select(getEditorState);

  if (currentEntityKey) {
    const currentDecoratedMerge = yield select(getCurrentEntityDecoratedMerge);
    const replaceEntityData = currentContent.replaceEntityData(
      currentEntityKey,
      { url },
    );
    const contentUpgrade = Modifier.replaceText(
      replaceEntityData,
      currentDecoratedMerge,
      textSelected,
      undefined,
      currentEntityKey,
    );
    yield put(updateEntityLink(editorState, contentUpgrade));
  } else {
    const contentWithEntity = currentContent.createEntity(
      typeStyleEditor.link,
      typeStyleEditor.mutable,
      {
        url,
      },
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();

    const selectionText: string = yield select(getCurrentSelectionText);
    if (!selectionText) {
      yield put(
        addEntityLinkEmptyText(
          editorState,
          currentSelection,
          currentContent,
          entityKey,
          textSelected,
        ),
      );
    } else {
      yield put(
        addEntityLinkTextSelected(
          editorState,
          currentSelection,
          contentWithEntity,
          entityKey,
        ),
      );
    }
  }
  yield put(close());
}

// NOTE: button uses same 'Link' actions as they are very similar, just different entity key
export function* onModalEditorButtonSubmitSaga({
  payload: { url, textSelected },
}: OnModalEditorButtonSubmitAction) {
  const currentEntityKey: string = yield select(getCurrentEntityKey);
  const currentSelection = yield select(getEditorSelection);
  const currentContent = yield select(getCurrentContentEditor);
  const editorState = yield select(getEditorState);

  if (currentEntityKey) {
    const currentDecoratedMerge = yield select(getCurrentEntityDecoratedMerge);
    const replaceEntityData = currentContent.replaceEntityData(
      currentEntityKey,
      { url },
    );
    const contentUpgrade = Modifier.replaceText(
      replaceEntityData,
      currentDecoratedMerge,
      textSelected,
      undefined,
      currentEntityKey,
    );
    yield put(updateEntityLink(editorState, contentUpgrade));
  } else {
    const contentWithEntity = currentContent.createEntity(
      typeStyleEditor.button,
      typeStyleEditor.mutable,
      {
        url,
      },
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();

    const selectionText: string = yield select(getCurrentSelectionText);
    if (!selectionText) {
      yield put(
        addEntityLinkEmptyText(
          editorState,
          currentSelection,
          currentContent,
          entityKey,
          textSelected,
        ),
      );
    } else {
      yield put(
        addEntityLinkTextSelected(
          editorState,
          currentSelection,
          contentWithEntity,
          entityKey,
        ),
      );
    }
  }
  yield put(close());
}

export function* onAutomatedTagChosenSaga({
  payload: { automatedTag },
}: OnAutomatedTagChosenAction) {
  const currentEntity = yield select(getCurrentEntity);
  const currentEntityKey: string = yield select(getCurrentEntityKey);
  const editorState = yield select(getEditorState);
  const getTypeEntity = currentEntityKey && currentEntity.getType();
  let currentSelection = yield select(getEditorSelection);
  let currentContent = yield select(getCurrentContentEditor);

  if (getTypeEntity === typeStyleEditor.automatedTag) {
    const currentDecoratedMerge = yield select(getCurrentEntityDecoratedMerge);
    const contentWithoutEntity = Modifier.applyEntity(
      currentContent,
      currentSelection,
      null,
    );
    currentContent = Modifier.removeRange(
      contentWithoutEntity,
      currentDecoratedMerge,
      'backward',
    );
    currentSelection = currentSelection.merge({
      anchorOffset: currentDecoratedMerge.getAnchorOffset(),
      focusOffset: currentDecoratedMerge.getAnchorOffset(),
    });

    yield put(updateEntityLink(editorState, currentContent));
  }

  const contentWithEntity = currentContent.createEntity(
    typeStyleEditor.automatedTag,
    'IMMUTABLE',
    { automatedTag },
  );
  const entityKey = contentWithEntity.getLastCreatedEntityKey();

  const selectionText = yield select(getCurrentSelectionText);
  if (!selectionText) {
    yield put(
      addEntityLinkEmptyText(
        editorState,
        currentSelection,
        currentContent,
        entityKey,
        automatedTag,
      ),
    );
  }

  const newEditorState = yield select(getEditorState);
  yield put(forceSelection(newEditorState));
}

export function* onModalEditorImageSubmitSaga({
  payload: { url },
}: OnModalEditorImageSubmitAction) {
  const currentContent = yield select(getCurrentContentEditor);
  const editorState = yield select(getEditorState);

  const contentWithEntity = currentContent.createEntity(
    typeStyleEditor.image,
    'IMMUTABLE',
    { src: url },
  );
  const entityKey = contentWithEntity.getLastCreatedEntityKey();

  yield put(addEntityImage(editorState, contentWithEntity, entityKey));
  yield put(close());
}

export function* successfulDeleteSaga(deletedEmailFlow: EmailFlow) {
  yield put(
    fetchDetailsEmailFlowSuccess({ ...deletedEmailFlow, active: false }),
  );
  yield put(close());
  yield put(setActiveComponentsCard(''));
  yield put(clearCurrentComponent());
}

export function* workerDeleteEmailFlowComponent({
  params,
}: FetchDeleteComponentAction) {
  const deletedComponent = yield select(getEditorUpdatedComponent);
  const deletedEmailFlow = yield select(getEmailFlow);
  const { isNew, id } = deletedComponent;
  const upgradeComponents = deletedEmailFlow.components.filter(
    (item: EmailFlowComponent) => item.id !== id,
  );
  if (isNew) {
    yield put(fetchDeleteComponentSuccess(deletedComponent));
    yield call(successfulDeleteSaga, {
      ...deletedEmailFlow,
      components: upgradeComponents,
    });
    return;
  }
  try {
    const { errors } = yield call(
      iclinic.campaign.fetchDeleteEmailFlowComponent,
      params,
    );
    if (errors) {
      yield put(fetchDeleteComponentFailure(emitErrors(errors), params));
    } else {
      yield call(successfulDeleteSaga, {
        ...deletedEmailFlow,
        components: upgradeComponents,
      });
      const formFields: Record<string, unknown> = yield select(
        getEmailFlowReturnValues,
      );
      const isDeletedAllComponents =
        formFields.activeEmailFlow && !upgradeComponents.length;
      if (isDeletedAllComponents) {
        yield put(fetchUpdateEmailFlow({ ...deletedEmailFlow, active: false }));
        const action: DefineAction = yield take([
          FETCH_UPDATE.SUCCESS,
          FETCH_UPDATE.FAILURE,
        ]);
        if (action.type === FETCH_UPDATE.SUCCESS) {
          yield put(
            fetchDetailsEmailFlow({
              id: deletedEmailFlow.id,
              physicianId: deletedEmailFlow.physicianId,
            }),
          );
        }
      }
    }
  } catch (error) {
    yield put(
      fetchDeleteComponentFailure(emitErrors([error as ApiErrors]), params),
    );
    captureException(error);
  }
}

export function* editEmailFlowComponent({
  params,
}: SaveEmailFlowComponentAction) {
  const component = yield select(getEditorUpdatedComponent);
  const updateFields = {
    ...component,
    physicianId: params.physicianId,
  };
  const { isNew } = component;
  const editor = EditorState.createWithContent(
    convertFromRaw(component.draftJSState),
    decorator,
  ).getCurrentContent();

  if (!editor.hasText()) {
    yield put(
      fetchUpdateComponentFailure(
        emitErrors([
          {
            code: '400912',
            message: 'Defina uma mensagem para o E-mail',
          },
        ]),
        component,
      ),
    );
    return;
  }

  let action: DefineAction;
  if (isNew) {
    yield put(fetchCreateComponent(updateFields));
    action = yield take([
      FETCH_CREATE_COMPONENT.SUCCESS,
      FETCH_CREATE_COMPONENT.FAILURE,
    ]);
  } else {
    yield put(fetchUpdateComponent(updateFields));
    action = yield take([
      FETCH_UPDATE_COMPONENT.SUCCESS,
      FETCH_UPDATE_COMPONENT.FAILURE,
    ]);
  }

  if (
    action.type === FETCH_CREATE_COMPONENT.SUCCESS ||
    action.type === FETCH_UPDATE_COMPONENT.SUCCESS
  ) {
    yield call(clearComponent, component.emailFlowId, params.physicianId);
  }
}

export function* workerUpdateEmailFlowComponent({
  params,
}: FetchUpdateComponentAction) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchUpdateEmailFlowComponent,
      params,
    );
    if (errors) {
      yield put(fetchUpdateComponentFailure(emitErrors(errors), params));
    } else {
      const payload = getResponseData();
      yield put(fetchUpdateComponentSuccess(payload));
    }
  } catch (error) {
    yield put(
      fetchUpdateComponentFailure(emitErrors([error as ApiErrors]), params),
    );
    captureException(error);
  }
}

export function* addNewComponent() {
  const emailFlow: EmailFlow = yield select(getEmailFlow);
  const { components } = emailFlow;
  const id = uuid();

  const newEmailFlow = {
    ...emailFlow,
    components: [
      ...(components as EmailFlowComponent[]),
      {
        body: '',
        draftJSState: {},
        offset: {
          unit: 'd',
          value: 0,
        },
        subject: '',
        isNew: true,
        emailFlowId: emailFlow.id,
        id,
      },
    ],
  } as EmailFlow;
  yield put(fetchDetailsEmailFlowSuccess(newEmailFlow));
  yield put(setActiveComponentsCard(id));
  yield put(
    setCurrentComponent({
      body: '',
      draftJSState: {},
      offset: {
        unit: 'd',
        value: 0,
      },
      subject: '',
      isNew: true,
      emailFlowId: emailFlow.id,
      id,
    } as EmailFlowComponent),
  );
}

export function* workerCreateEmailFlowComponent({
  params,
}: FetchCreateComponentAction) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchCreateEmailFlowComponent,
      params,
    );
    if (errors) {
      yield put(fetchCreateComponentFailure(emitErrors(errors), params));
    } else {
      const payload = getResponseData();
      yield put(fetchCreateComponentSuccess(payload));
    }
  } catch (error) {
    yield put(
      fetchCreateComponentFailure(emitErrors([error as ApiErrors]), params),
    );
    captureException(error);
  }
}

export function* changeComponent() {
  const component: EmailFlowComponent = yield select(getEditorUpdatedComponent);
  const emailFlow: EmailFlow = yield select(getEmailFlow);

  const upgradeComponents = emailFlow.components
    ? emailFlow.components.map((item: EmailFlowComponent) => {
        if (item.id === component.id) {
          return component;
        }
        return item;
      })
    : [];

  yield put(clearCurrentComponent());
  yield put(
    fetchDetailsEmailFlowSuccess({
      ...emailFlow,
      components: upgradeComponents,
    }),
  );
}

export default function* emailFlows() {
  yield all([
    takeEvery(FETCH_LIST.ACTION, workerListEmailFlows),
    takeEvery(FETCH_UPDATE.ACTION, workerUpdateEmailFlow),
    takeEvery(ACTIVE_EMAIL_FLOW.ACTION, activeEmailFlows),
    takeEvery(FETCH_PHYSICIANS.ACTION, workerUserProfiles),
    takeEvery(EDIT_EMAIL_FLOW.ACTION, editEmailFlowReturnSaga),
    takeEvery(CANCEL_EMAIL_FLOW.ACTION, cancelEmailFlowReturnSaga),
    takeEvery(FETCH_DETAILS.ACTION, workerDetailsEmailFlow),
    takeEvery(ADD_EMAIL_FLOW.ACTION, addEmailFlowSaga),
    takeEvery(FETCH_CREATE.ACTION, workerCreateEmailFlow),
    takeEvery(DELETE_EMAIL_FLOW.ACTION, deleteEmailFlowSaga),
    takeEvery(FETCH_DELETE.ACTION, workerDeleteEmailFlow),
    takeEvery(DUPLICATE_EMAIL_FLOW.ACTION, duplicateEmailFlowSaga),
    takeEvery(EDIT_EMAIL_FLOW.SAVE, editEmailFlowSaga),
    takeEvery(EDITOR_TEXT.MODAL_LINK_SUBMIT, onModalEditorLinkSubmitSaga),
    takeEvery(EDITOR_TEXT.MODAL_BUTTON_SUBMIT, onModalEditorButtonSubmitSaga),
    takeEvery(EDITOR_TEXT.AUTOMATED_TAG, onAutomatedTagChosenSaga),
    takeEvery(EDITOR_TEXT.MODAL_IMAGE_SUBMIT, onModalEditorImageSubmitSaga),
    takeEvery(FETCH_DELETE_COMPONENT.ACTION, workerDeleteEmailFlowComponent),
    takeEvery(EDIT_EMAIL_FLOW_COMPONENT.ACTION, editEmailFlowComponent),
    takeEvery(FETCH_UPDATE_COMPONENT.ACTION, workerUpdateEmailFlowComponent),
    takeEvery(FETCH_DETAILS_ADD_NEW_COMPONENT.ACTION, addNewComponent),
    takeEvery(FETCH_CREATE_COMPONENT.ACTION, workerCreateEmailFlowComponent),
    takeEvery(FETCH_DETAILS_UPDATE_COMPONENT.ACTION, changeComponent),
    takeEvery(FETCH_SEND_TEST_EMAIL.ACTION, workerSendTestEmail),
  ]);
}
