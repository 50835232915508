import React, { useCallback } from 'react';
import { useRifm } from 'rifm';
import { useFormikContext } from 'formik';

import {
  Alert,
  TextField,
  Grid,
  InputAdornment,
  Tooltip,
  MaterialIcons,
} from '@iclinic/design-system';
import {
  creditCardNumber,
  limitNumbers,
  monthYear,
} from 'shared/utils/formatters';
import { Bill } from 'features/onlinePayment/state/checkout/infos/types';
import { PaymentForm } from 'features/onlinePayment/state/checkout/payment/types';
import { initialValues } from './form-utils';
import CreditCardFlag from './CreditCardFlag';

const { ErrorOutline, Help } = MaterialIcons;

type FieldsProps = {
  error?: string | null;
  bill: Bill;
};
export default function CreditCardFields({
  error = null,
  bill,
}: FieldsProps): JSX.Element {
  const {
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext<PaymentForm>();

  const getErrorText = useCallback(
    (field: keyof typeof initialValues) =>
      touched[field] && errors[field] ? errors[field] : null,
    [touched, errors],
  );

  const verifyError = useCallback(
    (field: keyof typeof initialValues) => !!errors[field] && !!touched[field],
    [touched, errors],
  );

  const { value: cardNumber, onChange: onChangeCardNumber } = useRifm({
    value: values.cardNumber,
    onChange: (value: string) => setFieldValue('cardNumber', value),
    format: creditCardNumber,
  });

  const { value: cvv, onChange: onChangeCvv } = useRifm({
    value: values.cvv,
    onChange: (value: string) => setFieldValue('cvv', value),
    format: (str) => limitNumbers(str, 4),
  });

  const { value: expiry, onChange: onChangeExpiry } = useRifm({
    value: values.expiry,
    onChange: (value: string) => setFieldValue('expiry', value),
    format: monthYear,
  });

  return (
    <Grid direction="column" spacing={2} container>
      {!!error && (
        <Grid item>
          <Alert severity="error" icon={<ErrorOutline />} message={error} />
        </Grid>
      )}
      <Grid item>
        <TextField
          id="name"
          name="name"
          label="Nome do titular do cartão"
          placeholder="Igual ao cartão"
          onChange={handleChange}
          value={values.name}
          error={verifyError('name')}
          helperText={getErrorText('name')}
          fullWidth
        />
      </Grid>
      <Grid item>
        <TextField
          id="cardNumber"
          type="tel"
          name="cardNumber"
          label="Número do cartão"
          placeholder="0000 0000 0000 0000"
          onChange={onChangeCardNumber}
          value={cardNumber}
          error={verifyError('cardNumber')}
          helperText={getErrorText('cardNumber')}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <CreditCardFlag number={cardNumber} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <Grid container justify="space-between" spacing={2}>
          <Grid item xs={6}>
            <TextField
              id="expiry"
              type="tel"
              name="expiry"
              label="Validade"
              placeholder="MM/AA"
              onChange={onChangeExpiry}
              value={expiry}
              error={verifyError('expiry')}
              helperText={getErrorText('expiry')}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="cvv"
              type="tel"
              name="cvv"
              label="Código de segurança"
              placeholder="CVV"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      placement="top"
                      title="O código de segurança normalmente encontrado no verso do seu cartão"
                    >
                      <Help />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              onChange={onChangeCvv}
              value={cvv}
              error={verifyError('cvv')}
              helperText={getErrorText('cvv')}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          id="installments"
          name="installments"
          label="Número de parcelas"
          onChange={handleChange}
          value={values.installments}
          error={verifyError('installments')}
          helperText={getErrorText('installments')}
          fullWidth
          select
        >
          <option value="">Selecione</option>
          {bill.installments.map((item) => (
            <option key={item.installments} value={item.installments}>
              {item.installments}x de {item.value}
            </option>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
}
