import React, { useMemo } from 'react';

import { Button, MaterialIcons } from '@iclinic/design-system';
import { Medicament } from '../../services/types';
import history from 'routes/history';
import * as S from './CardPharma.styles';

const { Visibility } = MaterialIcons;

interface CardPharmaProps {
  boxId: number;
  name: string;
  logoPharma: string;
  medicaments: Medicament[];
  canRequest: boolean;
  nextRequestDate?: string;
  onDetailsClick?: () => void;
}

const CardPharma = ({
  boxId,
  name,
  logoPharma,
  medicaments,
  canRequest,
  nextRequestDate,
  onDetailsClick,
}: CardPharmaProps) => {
  const medicamentsText = useMemo(() => {
    return medicaments.reduce((fullText, medicament) => {
      if (!fullText) {
        return medicament.name;
      }

      return `${fullText}, ${medicament.name}`;
    }, '');
  }, [medicaments]);

  return (
    <S.Card>
      <S.CardHeader>
        <img src={logoPharma} alt="Logo farmacêutica" />
      </S.CardHeader>
      <S.CardContent>
        <S.CardContentTitle variant="md">{name}</S.CardContentTitle>
        <S.Medicaments variant="sm">{medicamentsText}</S.Medicaments>

        {nextRequestDate && (
          <S.BodyDate variant="xs">
            Próxima solicitação a partir de: {nextRequestDate}
          </S.BodyDate>
        )}

        <S.CardActionsWrapper>
          {canRequest && (
            <Button
              color="secondary"
              onClick={() => {
                history.push(`/amostras-gratis/solicitar/${boxId}`);
              }}
            >
              Solicitar
            </Button>
          )}

          <Button
            color="transparent"
            startIcon={<Visibility />}
            onClick={onDetailsClick}
          >
            Detalhes
          </Button>
        </S.CardActionsWrapper>
      </S.CardContent>
    </S.Card>
  );
};

export default CardPharma;
