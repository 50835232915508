import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';
import { FinancialSellerData } from './types';

export const getFinancialSellerData = ({
  onlinePayment,
}: StoreState): FinancialSellerData | undefined | null =>
  onlinePayment.panelIClinicPay.financialSeller?.data;

export const getFinancialSellerDataStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.panelIClinicPay.financialSeller?.status;

export const getFinancialSellerDataErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.panelIClinicPay.financialSeller?.error;
