import { all, takeLatest, call, put, select } from 'redux-saga/effects';

import history from 'routes/history';
import { handlerErrors } from 'state/shared/sagas';
import {
  fetchPhysicianInfosRequest,
  fetchEventInfosRequest,
  fetchBillInfosRequest,
} from 'features/onlinePayment/services/checkout';
import {
  FetchPhysicianInfosRawData,
  FetchEventInfosRawData,
  FetchBillInfosRawData,
} from 'features/onlinePayment/services/checkout/types';
import {
  BillStatus,
  EventStatus,
  SellerStatus,
} from 'features/onlinePayment/state/constants';
import {
  decodeToken,
  decodeTokenFailure,
  decodeTokenSuccess,
  fetchPhysicianInfos,
  fetchPhysicianInfosFailure,
  fetchPhysicianInfosSuccess,
  fetchEventInfos,
  fetchEventInfosFailure,
  fetchEventInfosSuccess,
  fetchBillInfos,
  fetchBillInfosFailure,
  fetchBillInfosSuccess,
} from 'features/onlinePayment/state/checkout/infos/actions';
import { DecodedToken } from './types';
import { getTokenData } from './selectors';
import {
  normalizeFetchPhysicianInfos,
  normalizeFetchEventInfos,
  normalizeFetchBillInfos,
} from './normalizers';
import { decodeCheckoutToken, getCheckoutTokenUrl } from './utils';
import {
  INIT,
  DECODE_TOKEN_ACTION,
  FETCH_PHYSICIAN_INFOS_ACTION,
  FETCH_EVENT_INFOS_ACTION,
  FETCH_BILL_INFOS_ACTION,
} from './constants';
import { checkoutRoutes } from '../constants';
import { Response } from '@types';

export function* workerInvalidCheckout() {
  yield call([history, history.push], {
    pathname: checkoutRoutes.invalid,
    search: window.location.search,
  });
}

export function* workerSuccessCheckout() {
  yield call([history, history.push], {
    pathname: checkoutRoutes.success,
    search: window.location.search,
  });
}

export function* workerFetchPhysicianInfos() {
  try {
    const { data }: Response<FetchPhysicianInfosRawData> = yield call(
      fetchPhysicianInfosRequest,
    );
    const { physician } = normalizeFetchPhysicianInfos(data);
    yield put(fetchPhysicianInfosSuccess({ physician }));
  } catch (error) {
    yield call(handlerErrors, error.errors, fetchPhysicianInfosFailure);
  }
}

export function* workerFetchEventInfos() {
  try {
    const { data }: Response<FetchEventInfosRawData> = yield call(
      fetchEventInfosRequest,
    );
    const { event, clinic } = normalizeFetchEventInfos(data);
    yield put(fetchEventInfosSuccess({ event, clinic }));

    if (event.status === EventStatus.Canceled) {
      yield call(workerInvalidCheckout);
      return;
    }
  } catch (error) {
    yield call(handlerErrors, error.errors, fetchEventInfosFailure);
  }
}

type WorkerDecodeToken = {
  type: typeof DECODE_TOKEN_ACTION;
  params: {
    token: string;
  };
};

export function* workerDecodeToken({ params: { token } }: WorkerDecodeToken) {
  const tokenData: DecodedToken | null = decodeCheckoutToken(token);

  if (!tokenData) {
    yield put(decodeTokenFailure());
    yield call(workerInvalidCheckout);
    return;
  }

  yield put(decodeTokenSuccess(tokenData));
  yield all([
    put(fetchBillInfos()),
    put(fetchPhysicianInfos()),
    put(fetchEventInfos()),
  ]);
}

export function* workerInit() {
  const token = getCheckoutTokenUrl();

  if (!token) {
    yield call(workerInvalidCheckout);
    return;
  }

  yield put(decodeToken({ token }));
}

export function* workerFetchBillInfos() {
  try {
    const tokenData: DecodedToken | null = yield select(getTokenData);

    if (!tokenData) return;

    const { data }: Response<FetchBillInfosRawData> = yield call(
      fetchBillInfosRequest,
      tokenData.billId,
    );
    const transformedBill = normalizeFetchBillInfos(data);

    yield put(fetchBillInfosSuccess({ bill: transformedBill }));

    if (transformedBill.status === BillStatus.Paid) {
      yield call(workerSuccessCheckout);
      return;
    }

    if (
      transformedBill.seller.sellerStatus !== SellerStatus.Approved ||
      transformedBill.status === BillStatus.Canceled
    ) {
      yield call(workerInvalidCheckout);
      return;
    }
  } catch (error) {
    yield call(handlerErrors, error.errors, fetchBillInfosFailure);
  }
}

export default function* checkoutInfosSagas() {
  yield takeLatest(INIT, workerInit);
  yield takeLatest(DECODE_TOKEN_ACTION, workerDecodeToken);
  yield takeLatest(FETCH_PHYSICIAN_INFOS_ACTION, workerFetchPhysicianInfos);
  yield takeLatest(FETCH_EVENT_INFOS_ACTION, workerFetchEventInfos);
  yield takeLatest(FETCH_BILL_INFOS_ACTION, workerFetchBillInfos);
}
