type SuccessInfo = {
  [plan: string]: {
    planName: string;
    title?: string;
    description: string;
    imagePath: string;
    instructionsUrl: string;
  };
};
export const successInfo: SuccessInfo = {
  plus: {
    planName: 'PLUS',
    description:
      'Agora você está mais perto de reduzir a taxa de faltas dos seus pacientes e aumentar o faturamento do consultório.',
    imagePath: 'upgrade.successPlus',
    instructionsUrl: 'https://suporte.iclinic.com.br/configurar-o-plano-plus',
  },
  pro: {
    planName: 'PRO',
    description:
      'Agora você está mais perto de controlar o que vai receber antes do mês acabar, por meio de uma gestão financeira mais completa e assertiva.',
    imagePath: 'upgrade.successPro',
    instructionsUrl: 'https://suporte.iclinic.com.br/configurar-o-plano-pro',
  },
  premium: {
    planName: 'PREMIUM',
    title:
      'Parabéns, você faz parte do grupo com o plano mais completo da nossa solução!',
    description:
      'Agora você está mais perto de atrair mais pacientes para o seu consultório, oferecendo a melhor experiência de atendimento para o seu paciente.',
    imagePath: 'upgrade.successPremium',
    instructionsUrl:
      'https://suporte.iclinic.com.br/configurar-o-plano-premium',
  },
};
