// External
import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Switch, Route } from 'react-router-dom';

// Internal
import Details from './Details';

const Records = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/:id`} component={Details} />
  </Switch>
);

Records.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default Records;
