import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MaterialIcons, Heading, IconButton } from '@iclinic/design-system';
import { isNotificationsCenterOpen } from 'features/onlinePayment/state/notifications-center/selectors';
import { toggleShowNotificationsCenter } from 'features/onlinePayment/state/notifications-center';
import { Notifications } from 'features/onlinePayment/components/notifications-center';
import * as S from 'features/onlinePayment/components/notifications-center/styles';

const { Close: CloseIcon } = MaterialIcons;

export const NotificationsCenter = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isNotificationsCenterOpen);

  const closeDrawer = () => {
    dispatch(toggleShowNotificationsCenter(false));
  };

  return (
    <S.Drawer anchor="right" open={isOpen} onClose={closeDrawer}>
      <S.DrawerHeader>
        <Heading variant="sm">iClinic Pay</Heading>
        <IconButton
          label="Fechar notificações"
          tooltip="none"
          onClick={closeDrawer}
        >
          <CloseIcon />
        </IconButton>
      </S.DrawerHeader>

      <Notifications />
    </S.Drawer>
  );
};
