import { ApiErrors } from '@types';

export interface ISignatureState {
  isOpenDrawer: boolean;
  isLoading: boolean;
  encodedCert: string;
  thumbprint: string;
  errors: ApiErrors[];
  signedPdf: FileSignatureResponseCompletedState[];
  stepper: Stepper;
  providers: ProvidersPSC[];
  accessToken: string;
  providersUrlSelected: string;
  lifeTime: number;
  userDocument: UserDocument | null;
  providerName: '';
}

export enum Stepper {
  Initial = 'INITIAL',
  Setup = 'SETUP',
  Certificates = 'CERTIFICATES',
  Preview = 'PREVIEW',
  Loading = 'LOADING',
}

export interface FileSignatureRequest {
  s3_file_url: string;
  encoded_cert: string;
  patient_id: string;
  physician_id?: number;
  user_id: string;
  document_type: string;
  document_id: string;
}

export interface FileSignatureCompletedRequest {
  signed_hash: string;
  transfer_id: string;
  document_type: string;
  document_id: string;
}

export interface FileSignatureResponse {
  document_hash: string;
  digest_algorithm: string;
  transfer_id: string;
}

export interface FileSignatureResponseCompleted {
  pdf_s3_url: string;
  signed_pdf_s3_url: string;
}

export interface FileSignatureResponseCompletedState {
  document_id: string;
  document_type: string;
  pdf_url: string;
  signed_pdf_url: string;
}

export interface Attachments {
  file_name: string;
  url: string;
}

export interface AttachmentSendEmail {
  file_name: string;
  url: string;
  document_type: string;
  selected: boolean;
}

export interface FileSignatureAttachment {
  file_name: string;
  url: string;
  document_type: string;
  selected: boolean;
}

export interface UserDocument {
  doc_type: string;
  number: string;
}

export interface ListPSCRequest {
  user_document: UserDocument;
  redirect_uri: string;
}

export interface ProvidersPSC {
  auth_url: string;
  name: string;
}

export interface ListPSCResponse {
  user_document: ListPSCRequest;
  providers: ProvidersPSC[];
}

export interface AccessTokenRequest {
  auth_code: string;
  state: string;
}

export interface AccessTokenResponse {
  access_token: string;
}

export interface FileSignatureCloudRequest {
  s3_file_url: string;
  access_token: string;
  patient_id: string;
  physician_id: number;
  document_type: string;
  document_id: string;
  user_id: string;
}

export type SignatureState = Readonly<ISignatureState>;
