import React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useRifm } from 'rifm';
import {
  Box,
  Grid,
  SectionTitle,
  MaterialIcons,
  TextField,
  Autocomplete,
} from '@iclinic/design-system';

import { ErrorAlert } from 'shared/components';
import { agencyFormatter, bankAccountFormatter } from 'shared/utils/formatters';
import { trackSignupIClinicPayForm } from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { SellerBankAccountForm } from 'features/onlinePayment/state/config/types';
import {
  getBanks,
  getBankAccountError,
} from 'features/onlinePayment/state/config/bankAccount/selectors';
import { ResponsiveCard } from 'features/onlinePayment/components';
import { getUserInfo } from 'features/onlinePayment/state/config/register/selectors';
import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';
import Subtitle from '../Subtitle';
import useStyles from './CardAccountData.styles';
import AccountOwner from './AccountOwner';

const { MonetizationOn } = MaterialIcons;

const documentLabel = {
  [SellerType.PF]: 'CPF',
  [SellerType.PJ]: 'CNPJ',
};

export default function CardAccountData(): JSX.Element {
  const userData = useSelector(getUserInfo);
  const classes = useStyles();

  const [banks, error] = [
    useSelector(getBanks),
    useSelector(getBankAccountError),
  ];

  const { values, errors, touched, setFieldValue } =
    useFormikContext<SellerBankAccountForm>();

  const { value: formattedAgency, onChange: onChangeAgency } = useRifm({
    value: values.agency,
    onChange: (value: string) => setFieldValue('agency', value),
    format: agencyFormatter,
  });

  const { value: formattedAccount, onChange: onChangeAccount } = useRifm({
    value: values.account,
    onChange: (value: string) => setFieldValue('account', value),
    format: bankAccountFormatter,
  });

  const getBankValue = () => {
    const { bank } = values;
    return banks?.find((item) => item.code === bank);
  };

  const checkSetFieldValue = (newValue: any) => {
    if (newValue && newValue.code) {
      return setFieldValue('bank', newValue.code || '');
    }

    return setFieldValue('bank', '');
  };

  const handleTracker = (
    flowType: string,
    stepName: string,
    stepNum: number,
    stepValue: string,
  ) => {
    trackSignupIClinicPayForm({
      userData,
      flowType,
      stepName,
      stepNum,
      stepValue,
    });
  };

  const handleBlurBank = (flowType: string, stepValue: string) => {
    const stepNum = flowType === SellerType.PF ? 18 : 23;
    handleTracker(flowType, 'user_bank', stepNum, stepValue);
  };

  const handleBlurAgency = (flowType: string, stepValue: string) => {
    const stepNum = flowType === SellerType.PF ? 19 : 24;
    handleTracker(flowType, 'user_agency', stepNum, stepValue);
  };

  const handleBlurAccount = (flowType: string, stepValue: string) => {
    const stepNum = flowType === SellerType.PF ? 20 : 25;
    handleTracker(flowType, 'user_account', stepNum, stepValue);
  };

  return (
    <ResponsiveCard variant="outlined">
      <SectionTitle
        icon={<MonetizationOn />}
        title="Dados da conta corrente"
        mb={1}
      />
      <Box mb={3}>
        <Subtitle>
          Informe os dados da conta corrente pela qual enviaremos os pagamentos,
          ela deve estar cadastrada no seu {documentLabel[values.type]}
        </Subtitle>
      </Box>
      {error && (
        <ErrorAlert
          message={<span dangerouslySetInnerHTML={{ __html: error }} />}
          mb={3}
          name="save-bank-error"
          scrollToElement
        />
      )}
      <Grid spacing={3} container>
        <AccountOwner values={values} />
        <Grid item xs={12} sm={6}>
          <Autocomplete
            id="bank"
            options={banks || []}
            value={getBankValue()}
            getOptionLabel={(option) => `${option.code} - ${option.name}`}
            getOptionSelected={(option) => option.code === values.bank}
            renderOption={(option) => (
              <Box display="flex" flexDirection="row" alignItems="right">
                {option.img && <img src={option.img} alt={option.name} />}
                <Box ml={1}>
                  {option.code} - {option.name}
                </Box>
              </Box>
            )}
            onChange={(_, newValue) => {
              checkSetFieldValue(newValue);
            }}
            onBlur={() => handleBlurBank(values.type, values.bank)}
            noOptionsText="Banco não encontrado"
            className={classes.textField}
            /* eslint-disable react/jsx-props-no-spreading */
            renderInput={(params) => (
              <TextField
                {...params}
                label="Banco"
                name="bank"
                value={values.bank}
                error={!!(errors.bank && touched.bank)}
                helperText={touched.bank && errors.bank}
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            id="agency"
            name="agency"
            label="Agência"
            onChange={onChangeAgency}
            onBlur={() => handleBlurAgency(values.type, values.agency)}
            value={formattedAgency}
            error={!!(errors.agency && touched.agency)}
            helperText={touched.agency && errors.agency}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField
            id="account"
            name="account"
            label="Conta"
            onChange={onChangeAccount}
            onBlur={() => handleBlurAccount(values.type, values.account)}
            value={formattedAccount}
            error={!!(errors.account && touched.account)}
            helperText={touched.account && errors.account}
            fullWidth
          />
        </Grid>
      </Grid>
    </ResponsiveCard>
  );
}
