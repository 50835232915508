import { useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import uuid from 'uuid';

import AddExpenseExpenses from 'features/tissInvoicing/containers/expense/AddExpense/expenses';
import { IChangeExpensePayload } from 'features/tissInvoicing/containers/expense/interfaces/ChangeExpensePayload';
import { normalizeExpenses } from 'features/tissInvoicing/containers/expense/normalize';
import { getExpenseAutocompleteState } from 'features/tissInvoicing/state/expense/selectors';
import {
  getGuideDataState,
  getExpensesErrors,
} from 'features/tissInvoicing/state/sadt/selectors';
import { ExpenseItem, GuideSADTForm } from 'features/tissInvoicing/types';
import { patchNormalizeExpenses } from '../../normalize';
import * as Styled from './styles';

export default () => {
  const {
    values: { expenses = [] },
    setFieldValue,
  } = useFormikContext<GuideSADTForm>();
  const { typeOptions = [], unitTypeOptions = [] } = useSelector(
    getExpenseAutocompleteState,
  );
  const { id: guideId } = useSelector(getGuideDataState)!;
  const showErrors = useSelector(getExpensesErrors);
  const [tempExpenses, setTempExpenses] = useState<any[]>([]);
  const isExpenseListLimit = (expenseList: Partial<ExpenseItem>[]) =>
    expenseList.length >= 10;

  useEffect(() => {
    if (
      expenses?.length &&
      typeOptions.length &&
      unitTypeOptions.length &&
      !tempExpenses.length
    ) {
      const normalizedExpenses = normalizeExpenses(
        expenses,
        typeOptions,
        unitTypeOptions,
      );
      setTempExpenses(normalizedExpenses);
    }
  }, [expenses, typeOptions, unitTypeOptions, tempExpenses.length]);

  const handleClickAddExpense = useCallback(() => {
    const newExpense: Partial<ExpenseItem> = {
      id: uuid(),
      name: '',
      quantity: 0,
      value: 0,
      guide: guideId,
    };
    if (!isExpenseListLimit(tempExpenses))
      setTempExpenses([...tempExpenses, newExpense]);
  }, [tempExpenses, guideId]);

  const handleChangeExpense = useCallback(
    (payload: IChangeExpensePayload) => {
      const { position, fieldName, value } = payload || {};
      const tmp = [...tempExpenses];
      switch (fieldName) {
        case 'type':
          tmp[position - 1].tuss_table = value.related_tuss_table.code;
          break;
        case 'unity_type':
          tmp[position - 1].unit_type = value.id;
          break;
        case 'tuss_code':
          tmp[position - 1].tuss_expense = {
            clinic_id: null,
            code: value.title,
            id: value.id,
            term: value.term,
            tuss_table: '',
          };
          break;
        default:
          break;
      }

      tmp[position - 1][fieldName] = value;
      setTempExpenses(tmp);
      setFieldValue('expenses', patchNormalizeExpenses(tmp));
    },
    [tempExpenses, setFieldValue],
  );

  const handleDeleteExpense = useCallback(
    (id: string | number) => {
      const filteredList = tempExpenses.filter((expense) => expense.id !== id);
      setTempExpenses(filteredList);
      setFieldValue('expenses', patchNormalizeExpenses(filteredList));
    },
    [tempExpenses, setFieldValue],
  );

  return (
    <Styled.StyledBox
      display="flex"
      flexDirection="column"
      justifyContent="center"
      p={1}
    >
      <AddExpenseExpenses
        items={tempExpenses}
        onChange={handleChangeExpense}
        onClickDelete={handleDeleteExpense}
        onClickAdd={handleClickAddExpense}
        showErrors={showErrors}
        showAddButton={!isExpenseListLimit(tempExpenses)}
        addButtonPosition="bottom"
        key="guide-expenses"
      />
    </Styled.StyledBox>
  );
};
