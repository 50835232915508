import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Body, Alert, MaterialIcons, Box } from '@iclinic/design-system';

import { CAMPAIGN_SEND_EMAIL_TEST } from 'shared/constants/forms';
import Modal from 'ui/new/modal';
import Button from 'ui/new/button';
import style from './SendEmailTestModal.scss';

const { ErrorOutlineOutlined, EmailRounded } = MaterialIcons;

const SendEmailTestModal = ({ onCloseClick, onSendEmailTest, userInfo }) => (
  <div className={style.modal}>
    <Modal
      show
      title="Realizar teste de envio"
      onClose={onCloseClick}
      classes={style}
      width={440}
    >
      <Modal.Body classes={style}>
        <Box className={style.bodyContent}>
          <Body variant="xs" bold>
            A mensagem será enviada para o e-mail:{' '}
          </Body>

          <Box className={style.emailContainer}>
            <EmailRounded />
            <Body variant="xxs">{userInfo.email}</Body>
          </Box>

          <Alert
            severity="info"
            icon={<ErrorOutlineOutlined />}
            message="Por motivos de segurança não enviamos testes para outros endereços
              de e-mail."
            className={style.alert}
          />
        </Box>
      </Modal.Body>
      <Modal.Footer align="right">
        <Button type="light" onClick={onCloseClick}>
          CANCELAR
        </Button>
        <Button
          type="success"
          onClick={() => onSendEmailTest(userInfo.physicianId, userInfo.email)}
        >
          ENVIAR E-MAIL TESTE
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
);

SendEmailTestModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  onSendEmailTest: PropTypes.func.isRequired,
  userInfo: PropTypes.shape({
    physicianId: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect()(
  reduxForm({ form: CAMPAIGN_SEND_EMAIL_TEST })(SendEmailTestModal),
);
