import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  ballAnimation: {
    height: '30px',
    width: '30px',
    position: 'relative',
    '& span': {
      left: '50%',
      top: '50%',
      animation: `$bounce 600ms ease-in-out infinite alternate`,
      backgroundColor: theme.palette.text.disabled,
      borderRadius: '4px',
      height: '4px',
      width: '4px',
      position: 'absolute',
      transform: 'translate(2px, -2px)',
    },
  },
  '@keyframes bounce': {
    '0%': {
      left: '4px',
    },
    '50%': {
      width: '8px',
    },
    '100%': {
      left: '15px',
    },
  },
}));
