import React from 'react';

import { Body, SectionTitle, MaterialIcons } from '@iclinic/design-system';
import { Medicament } from '../../services/types';
import { image } from 'shared/utils/urlGetter';
import * as S from './CardProducts.styles';
import { InfoText } from './Form.styles';

const { FormatListBulletedIcon } = MaterialIcons;

type CardProductsProps = {
  pharmaName: string;
  boxName: string;
  medicaments: Medicament[];
};

export default function CardProducts({
  pharmaName,
  boxName,
  medicaments,
}: CardProductsProps): JSX.Element {
  return (
    <S.Card data-testid="card-products-box">
      <S.PharmaTitle variant="md">{pharmaName}</S.PharmaTitle>

      <img
        src={image('freeSamples.samplesBox')}
        alt="Ilustração Solicitação"
        width={184}
        height={184}
      />

      <SectionTitle icon={<FormatListBulletedIcon />} title={boxName} />

      <S.MedicamentsList>
        {medicaments.map(({ name, properties_extras }) => (
          <li key={name}>
            <Body variant="xs">
              {name}
              {properties_extras.length
                ? ` - ${properties_extras[0].property_description}`
                : ''}
            </Body>
          </li>
        ))}
      </S.MedicamentsList>

      <S.OptInValidityWrapper>
        <S.StyledReportProblemOutlined />

        <InfoText variant="xxs">
          Entrega disponível para determinadas regiões
        </InfoText>
      </S.OptInValidityWrapper>
    </S.Card>
  );
}
