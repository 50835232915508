import React from 'react';
import { Button, Box } from '@iclinic/design-system';

import useStyles from './styles';

type Props = {
  icon: React.ReactNode;
  label?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  variant: string;
  switchedOn: boolean;
  updateOn: boolean;
  title: string;
  disabled?: boolean;
  innerRef?: React.RefObject<HTMLButtonElement>;
  dataSp?: string;
  dataGa?: string;
};

export default function ToggleButton({
  icon,
  label,
  onClick,
  variant,
  switchedOn,
  updateOn,
  title,
  disabled,
  innerRef,
  dataSp = '',
  dataGa = '',
}: Props) {
  const isErrorVariant = variant === 'error';
  const classes = useStyles({ isErrorVariant });
  const buttonClass = switchedOn ? classes.switchedOn : classes.switchedOff;
  return (
    <Box className={classes.root}>
      <Button
        onClick={onClick}
        className={buttonClass}
        placeholder={title}
        title={title}
        disabled={disabled}
        innerRef={innerRef}
        data-sp={dataSp}
        data-ga={dataGa}
      >
        {updateOn && <span className={classes.updateOn} />}
        <span className={classes.icon}>{icon}</span>
        {label && <span className={classes.buttonLabel}>{label}</span>}
      </Button>
    </Box>
  );
}
