import ONLINE_SCHEDULE_MESSAGES from './pt-BR';

type Params = {
  id: string;
};

const formatMessage = ({ id }: Params): string =>
  ONLINE_SCHEDULE_MESSAGES[id] as string;

export default formatMessage;
