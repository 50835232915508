import React, { Fragment } from 'react';
import Icon from '@material/react-material-icon';
import classnames from 'classnames/bind';

import { CheckBox } from 'ui/new/form';
import { Tooltip } from 'ui/overlay';
import Table from 'ui/new/table';
import Align from 'ui/new/table/constants';
import { ModalType } from 'features/stock/constants';
import { Stock, Inventory, Lot } from 'features/stock/state/types';

import style from './MyStock.scss';

const { Body, Row, Column } = Table;

type TableBodyProps = {
  tableStyles: {
    [className: string]: string;
  };
  stocks: Stock[];
  lots: (Inventory & Lot)[] | null;
  onSelectProduct(id: number): void;
  onViewLots(id: number): void;
  checkSelectedProduct(id: number): boolean;
  fetchProductAndStock(modalType: ModalType, id: number): void;
  openedProductId: number | null;
};

const classes = classnames.bind(style);

export default function TableBody({
  tableStyles: styles,
  stocks,
  lots,
  onSelectProduct,
  onViewLots,
  checkSelectedProduct,
  fetchProductAndStock,
  openedProductId,
}: TableBodyProps): JSX.Element {
  return (
    <Body>
      {stocks.map<JSX.Element>((item: Stock) => (
        <Fragment key={item.id}>
          <Row>
            <Column>
              <CheckBox
                name="cb-product"
                id="selectProduct"
                value={item.id}
                onChange={() => onSelectProduct(item.id)}
                selected={checkSelectedProduct(item.id)}
              />
            </Column>
            <Column align={Align.left} classes={styles.item}>
              <div className={style['product-name']}>
                <span
                  onKeyPress={() =>
                    fetchProductAndStock(ModalType.Edit, item.id)
                  }
                  role="button"
                  tabIndex={0}
                  onClick={() => fetchProductAndStock(ModalType.Edit, item.id)}
                >
                  {item.description}
                </span>
                {item.hasLotControl && item.inventories.length > 0 && (
                  <Tooltip content="Ver lotes">
                    <Icon
                      icon="visibility"
                      className={classes({
                        'view-lot': true,
                        'view-lot-active': openedProductId === item.id,
                      })}
                      onClick={() => onViewLots(item.id)}
                    />
                  </Tooltip>
                )}
              </div>
            </Column>
            <Column align={Align.center} classes={styles.others}>
              {item.code}
            </Column>
            <Column align={Align.center} classes={styles.others}>
              {item.quantity}
            </Column>
            <Column align={Align.center} classes={styles.others}>
              {item.expireInOneMonth.quantity || 0}
            </Column>
            <Column align={Align.center} classes={styles.others}>
              {item.expired.quantity || 0}
            </Column>
            <Column align={Align.center} classes={styles.others}>
              {item.minimum}
            </Column>
          </Row>
          {openedProductId === item.id &&
            !!lots &&
            lots.length > 0 &&
            lots.map<JSX.Element>((lot: Inventory & Lot) => (
              <Row key={lot.id}>
                <Column align={Align.center} />
                <Column align={Align.left}>{lot.description}</Column>
                <Column align={Align.center} />
                <Column align={Align.center}>{lot.quantity}</Column>
                <Column align={Align.center}>
                  {lot.expireInOneMonth || 0}
                </Column>
                <Column align={Align.center}>{lot.expired || 0}</Column>
                <Column align={Align.center} />
              </Row>
            ))}
        </Fragment>
      ))}
    </Body>
  );
}
