import {
  Alert as DSAlert,
  Card as DSCard,
  Container,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing, shadow } = tokens;

export const Root = styled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.xl,
}));

export const Alert = styled(DSAlert)({
  alignSelf: 'center',
});

export const LoaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: spacing.xl,
});

export const Card = styled(DSCard)({
  boxShadow: shadow.level[2],
});
