import { IExpenseItem } from 'features/tissInvoicing/containers/expense/interfaces/ExpenseItem';
import { DateString, GenericObjectDescription, StringNull } from '../misc';
import { Procedure } from './procedure';
import { Session } from './session';

export enum ActionKeys {
  FetchGuide = 'fetchGuide',
  FetchDomainTables = 'fetchDomainTables',
  FetchAutocomplete = 'fetchAutocomplete',
  Save = 'save',
  Print = 'print',
  FetchClinicHealthShow = 'fetchClinicHealthShow',
  FetchClinicHealthHiredExecutant = 'fetchClinicHealthHiredExecutant',
  GetPatient = 'getPatient',
}

interface SharedGuideSADTProps {
  // General data
  insurance: GenericObjectDescription & {
    ans_register: StringNull;
    tiss_version: StringNull;
  };
  guide_number: StringNull;
  main_guide_number: StringNull;
  authorization_date?: DateString;
  authorization_number: StringNull;
  insurance_name: StringNull;
  insurance_ans_register: StringNull;
  authorization_expiration_date: DateString;
  operator_guide_number: StringNull;
  degree_participation: number | null;
  service_type: number | null;
  accident_indication: number | null;
  appointment_type: number | null;
  reason_termination: number | null;
  attendance_regimen: StringNull;
  occupational_health: StringNull;
  observation: StringNull;

  // Beneficiary data
  patient_insurance_code: StringNull;
  patient_insurance_expiry_date: DateString;
  patient_id: number | null;
  patient_civil_name?: StringNull;
  patient_social_name?: StringNull;
  patient_sus_code?: StringNull;
  newborn: StringNull;

  // Solicitant data
  hired_solicitant_name: StringNull;
  hired_solicitant_code_type: StringNull;
  hired_solicitant_code: StringNull;
  professional_solicitant_name: StringNull;
  professional_solicitant_council: string | null;
  special_coverage: string | null;
  professional_solicitant_council_number: StringNull;
  professional_solicitant_council_state: string | null;
  professional_solicitant_cbo: string | null;
  solicitor_service_type?: number | null;
  request_date: DateString;
  clinical_indication: string | null;

  // Sessions data
  sessions: Session[];

  // Expenses data
  expenses: IExpenseItem[];
}

export interface GuideSADT extends SharedGuideSADTProps {
  id: string;

  // General data
  insurance: GenericObjectDescription & {
    ans_register: StringNull;
    tiss_version: StringNull;
  };

  // Procedures data
  procedures: Procedure[];

  // Sessions data
  sessions: Session[];

  // Expenses data
  expenses: IExpenseItem[];

  // Header data
  version_id: StringNull;
  guide_number: string;
  hired_executant: GenericObjectDescription & {
    type: StringNull;
    cnes: StringNull;
  };

  // Misc
  event_id?: number;
  physician?: GenericObjectDescription;
  clinic?: GenericObjectDescription;
  patient_name: StringNull;
  patient_civil_name?: StringNull;
  patient_identifier?: StringNull;
  patient_identifier_base64?: StringNull;
  clinic_id?: number;
  insurance_id?: number;
  physician_id?: number;
  guide_type?: StringNull;
  appointment_date?: DateString;
  date_added?: Date | null;
  total_amount?: string;
  lot?: string;
  special_coverages?: string | null;
}

export interface GuideSADTForm
  extends Omit<SharedGuideSADTProps, 'insurance' | 'patient'> {
  // General data
  version_id: StringNull;

  // Procedures data
  procedures: Procedure[];

  print?: boolean;
}

export interface PatchGuideSADTPayload extends Partial<SharedGuideSADTProps> {
  // Procedures data
  procedures: Array<
    Omit<
      Procedure,
      | 'id'
      | 'page'
      | 'unit_price'
      | 'tuss_procedure_id'
      | 'guide'
      | 'total_value'
    >
  >;
}

export interface GeneratePDFResponse {
  url: string;
}

export * from './autocomplete';
export * from './domain-tables';
export * from './procedure';
export * from './session';
export * from './clinicHealthInsurance';
