import React from 'react';
import { useSelector } from 'react-redux';

import BoxesList from './BoxesList';
import { SampleBox } from 'features/free-samples/services/types';
import { getRequestedBoxesData } from '../../state/sample-boxes/selectors';

interface RequestedTabProps {
  onDetailsClick: (box: SampleBox) => void;
}

const RequestedTab = ({ onDetailsClick }: RequestedTabProps) => {
  const { isFetchingRequested, requestedBoxes } = useSelector(
    getRequestedBoxesData,
  );

  return (
    <BoxesList
      isFetching={isFetchingRequested}
      listBoxes={requestedBoxes}
      onDetailsClick={onDetailsClick}
      emptyMessage="Você não tem boxes solicitadas no momento"
    />
  );
};

export default RequestedTab;
