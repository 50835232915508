// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import { Notification } from '@iclinic/iclinic-ui';
import { ExpireAfter } from './index';

const NotificationExpireMessage = ({
  children,
  onExpire,
  expireAfter,
  title,
  showCloseButton,
  onCloseClick,
  kind,
}) => (
  <Notification>
    <ExpireAfter onExpire={onExpire} delay={expireAfter}>
      <Notification.Message
        title={title}
        showCloseButton={showCloseButton}
        onCloseClick={onCloseClick}
        kind={kind}
      >
        {children}
      </Notification.Message>
    </ExpireAfter>
  </Notification>
);

NotificationExpireMessage.defaultProps = {
  expireAfter: 3000,
  onExpire: () => {},
  title: '',
  showCloseButton: true,
  onCloseClick: () => {},
  kind: 'error',
};

NotificationExpireMessage.propTypes = {
  expireAfter: PropTypes.number,
  onExpire: PropTypes.func,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  showCloseButton: PropTypes.bool,
  onCloseClick: PropTypes.func,
  kind: PropTypes.oneOf(['success', 'warning', 'error', 'info']),
};

export default NotificationExpireMessage;
