import { RequestStatus } from 'shared/constants/State';
import { IStoreState } from 'state/rootReducer';

export const isFetching = ({ referrals }: IStoreState) =>
  referrals.status === RequestStatus.Pending;

export const getShortUrls = ({ referrals }: IStoreState) => referrals.shortUrls;

export const getOffCanvasOpen = ({ referrals }: IStoreState) =>
  referrals.offCanvasOpen;

export const isFetchingAwards = ({ referrals }: IStoreState) =>
  referrals.awards?.status === RequestStatus.Pending;

export const getAwards = ({ referrals }: IStoreState) => referrals.awards?.data;

export const isFetchingRedeemAward = ({ referrals }: IStoreState) =>
  referrals.awards?.redeemStatus === RequestStatus.Pending;

export const getRedeemAwardStatus = ({ referrals }: IStoreState) =>
  referrals.awards?.redeemStatus;

export const getSelectedAward = ({ referrals }: IStoreState) =>
  referrals.awards?.selected;

export const getRewardContragulations = ({ referrals }: IStoreState) =>
  referrals.rewardCongratulations;
