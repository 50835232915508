export const CHECKOUT_ACTION =
  '@webapp/onlinePayment/checkout/payment/CHECKOUT';
export const CHECKOUT_FAILURE =
  '@webapp/onlinePayment/checkout/payment/CHECKOUT_FAILURE';
export const CHECKOUT_SUCCESS =
  '@webapp/onlinePayment/checkout/payment/CHECKOUT_SUCCESS';

export const SYNC_STATUS_TRANSACTION_ACTION =
  '@webapp/onlinePayment/checkout/payment/SYNC_STATUS_TRANSACTION_ACTION';
export const SYNC_STATUS_TRANSACTION_FAILURE =
  '@webapp/onlinePayment/checkout/payment/SYNC_STATUS_TRANSACTION_FAILURE';
export const SYNC_STATUS_TRANSACTION_SUCCESS =
  '@webapp/onlinePayment/checkout/payment/SYNC_STATUS_TRANSACTION_SUCCESS';

// export const CHECKOUT_V2_ACTION =
//   '@webapp/onlinePayment/checkout/payment/CHECKOUT_V2_ACTION';
export const CHECKOUT_V2_FAILURE =
  '@webapp/onlinePayment/checkout/payment/CHECKOUT_V2_FAILURE';
export const CHECKOUT_V2_SUCCESS =
  '@webapp/onlinePayment/checkout/payment/CHECKOUT_V2_SUCCESS';

export const CHECKOUT_PIX =
  '@webapp/onlinePayment/checkout/payment/CHECKOUT_PIX';

export const CHECKOUT_CREDIT_CARD =
  '@webapp/onlinePayment/checkout/payment/CHECKOUT_CREDIT_CARD';

export const PIX_QRCODE_VALIDITY =
  '@webapp/onlinePayment/checkout/payment/PIX_QRCODE_VALIDITY';
