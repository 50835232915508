import { combineReducers } from 'redux';

import checkoutReducer, { CheckoutState } from './checkout';
import detailsReducer, { DetailsState } from './details';

export type MessagePackageState = {
  checkout: CheckoutState;
  details: DetailsState;
};

const messagePackageReducer = combineReducers<MessagePackageState>({
  checkout: checkoutReducer,
  details: detailsReducer,
});

export default messagePackageReducer;
