import { ApiErrors } from '@types';
import * as types from './constants';

export const togglePaymentsControlDrawer = () =>
  ({
    type: types.TOGGLE_TRANSACTIONS_DRAWER,
  } as const);

export const toggleScheduleModal = (isOpen: boolean) =>
  ({
    type: types.TOGGLE_SCHEDULE_MODAL,
    payload: {
      isScheduleModalOpen: !isOpen,
    },
  } as const);

export const togglePatientWaitingList = (isOpen: boolean) =>
  ({
    type: types.TOGGLE_PATIENT_WAITING_LIST,
    payload: {
      isPatientWaitingListOpen: !isOpen,
    },
  } as const);

export const toggleScheduleObservation = (isOpen: boolean) =>
  ({
    type: types.TOGGLE_SCHEDULE_OBSERVATION,
    payload: {
      isScheduleObservationOpen: !isOpen,
    },
  } as const);

export const togglePeriodPrintSchedule = (startDate: string, endDate: string) =>
  ({
    type: types.TOGGLE_PERIOD_PRINT_SCHEDULE,
    payload: {
      startDate,
      endDate,
    },
  } as const);

export const printSchedule = () =>
  ({
    type: types.PRINT_SCHEDULE_ACTION,
    payload: {
      isFetching: true,
    },
  } as const);

export const printScheduleSucess = (pdf: string, isFetching: boolean) =>
  ({
    type: types.PRINT_SCHEDULE_SUCCESS,
    payload: {
      pdf,
      isFetching,
    },
  } as const);

export const printScheduleFailure = (errors: ApiErrors[]) =>
  ({
    type: types.PRINT_SCHEDULE_FAILURE,
    payload: {
      errors,
      isFetching: false,
    },
  } as const);

export const openScheduleSettings = () =>
  ({
    type: types.OPEN_SCHEDULE_SETTINGS,
  } as const);
