// External
import React from 'react';

// Internal
import { AppContent } from 'features/layout';
import { ListEmailFlowCustomContainer } from 'features/marketing';

const EmailFlowCustom = () => (
  <AppContent
    contentTitle=""
    hideSidebar
    hideFooter
  >
    <ListEmailFlowCustomContainer />
  </AppContent>
);

export default EmailFlowCustom;
