import { Heading, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing, border } = tokens;

export const PlanWrapper = styled('li')({
  backgroundColor: color.neutral[0],
  textAlign: 'center',
  borderRadius: border.radius.sm,
  padding: `${spacing.md} ${spacing.base} ${spacing.base}`,
  cursor: 'pointer',

  '&:hover .MuiButton-root': {
    backgroundColor: color.primary.dark,
  },
});

export const PlanTitle = styled(Heading)({
  fontSize: 80,
  lineHeight: '84px',
});

export const PlanValue = styled(Heading)(({ theme }) => ({
  margin: `${spacing.sm} 0`,
  paddingTop: spacing.sm,
  borderTop: `${border.width.hairline} solid ${color.neutral[50]}`,
  whiteSpace: 'nowrap',

  [theme.breakpoints.up('md')]: {
    margin: `${spacing.md} 0`,
    paddingTop: spacing.md,
  },
}));
