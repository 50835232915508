import faker from 'faker';

import { getFixedNumber } from 'features/onlinePayment/utils';
import {
  IPayment,
  PatientProps,
  PaymentProps,
  ProcedureProps,
} from '../types/types';

export const patientDataMock: PatientProps = {
  date: faker.date.future(),
  name: faker.random.words(2),
  phoneNumber: faker.phone.phoneNumber(),
  email: faker.internet.email(),
  healthInsurance: faker.random.word(),
  age: faker.random.number(100),
  lastSchedule: faker.date.past().toDateString(),
  timeUnit: faker.random.word(),
  observations: faker.random.word(),
  picture: faker.random.word(),
  code: faker.random.number(100),
  gender: faker.random.word(),
  age_full_described: faker.random.word(),
  mobile_phone: faker.phone.phoneNumber(),
  last_appointment_date: faker.random.word(),
  home_phone: faker.phone.phoneNumber(),
  birth_date: faker.date.past().toDateString(),
  id: faker.random.number(100),
  scheduleTime: 'Terça-feira, 10 de Janeiro - 09:00 às 09:30',
};

export type ProceduresListType = ProcedureProps[];

export const procedureItemMock: ProcedureProps = {
  id: faker.random.number(100),
  procedure: {
    color: {
      border_color: faker.random.word(),
      background_color: faker.random.word(),
      id: faker.random.number(100),
      text_color: faker.random.word(),
    },
    price: getFixedNumber(faker.random.number(1000)),
    deleted: false,
    procedure_type: faker.random.word(),
    duration: faker.random.number(60),
    id: faker.random.number(),
    name: faker.random.words(3),
  },
  quantity: faker.random.number(),
  price: getFixedNumber(faker.random.number(1000)),
};

export const paymentMock: PaymentProps = {
  paidByOtherPerson: {
    fullName: faker.random.words(2),
    hasPaidByOtherPerson: faker.random.boolean(),
    kinshipCPF: faker.random.word(),
    kinshipDegree: faker.random.word(),
  },
  paymentList: [
    {
      id: faker.random.word(),
      date: faker.date.soon(2),
      value: faker.random.number(),
      paymentType: faker.random.word(),
      bankAccount: faker.random.word(),
      cash: faker.random.word(),
    },
  ],
  discount: {
    discountMethod: 'cash',
    discountValue: faker.random.number(1000),
  },
  values: {
    total: faker.random.number(1000),
    subTotal: faker.random.number(1000),
    discountedValue: faker.random.number(1000),
    remainingAmount: faker.random.number(1000),
  },
  paymentConfig: {
    bankAccountOptions: [
      {
        label: 'mock-conta-1',
        value: 'mock-conta-1',
        is_default: false,
      },
    ],
    defaultBankAccount: '',
    cashOptions: [
      {
        label: 'mock-cash-1',
        value: 'mock-cash-1',
        is_default: false,
      },
    ],
    defaultCash: '',
    defaultPaymentForm: '',
    paymentFormOptions: [
      {
        label: 'mock-forma-1',
        value: 'px',
        is_default: false,
      },
    ],
    creditCardPaymentOptions: [],
    defaultCreditCardInstallment: '1',
  },
};

export const creditCardPaymentWithInstallments: IPayment = {
  paymentType: 'cc',
  value: 200,
  date: faker.date.soon(2),
  bankAccount: faker.random.word(),
  cash: faker.random.word(),
  defaultCreditCardInstallment: '6',
};

export const cashOptionsMock: {
  label: string;
  value: string;
  is_default: boolean;
}[] = [
  {
    label: faker.random.word(),
    value: faker.random.word(),
    is_default: true,
  },
  {
    label: faker.random.word(),
    value: faker.random.word(),
    is_default: false,
  },
  {
    label: faker.random.word(),
    value: faker.random.word(),
    is_default: false,
  },
  {
    label: faker.random.word(),
    value: faker.random.word(),
    is_default: false,
  },
];
