import { tokens, MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export const useStyles = makeStyles(() => ({
  button: {
    outline: 'none',
    border: `1px solid ${tokens.color.neutral[100]}`,
    borderRadius: tokens.border.radius.sm,
    color: tokens.color.neutral[700],
  },
  icon: {
    marginRight: tokens.spacing.nano,
    color: tokens.color.neutral[500],
    fontSize: tokens.font.size.sm,
  },
}));
