import { formatDate } from 'shared/utils/date';
import { FetchNotificationsRawData } from 'services/iclinic/notifications/types';
import { Notifications } from './types';

export function normalizeNotificationsResponseData(
  notifications: FetchNotificationsRawData,
): Notifications {
  const { count, next, previous, results, unread_notifications_count } =
    notifications;

  const transformResults = results.map(
    ({
      id,
      read,
      created_at,
      updated_at,
      groups,
      channel,
      message_type,
      data,
      created_by,
      user,
    }) => ({
      id,
      read,
      createdAt: `${formatDate(created_at)} - ${created_at.slice(11, 16)}`,
      updatedAt: formatDate(updated_at),
      groups,
      channel,
      messageType: message_type,
      data,
      createdBy: created_by,
      user,
    }),
  );

  return {
    count,
    next,
    previous,
    results: transformResults,
    unreadNotificationsCount: unread_notifications_count,
  };
}
