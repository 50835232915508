import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import * as actions from '.';
import * as api from '../../../services/messagePackage/checkout';
import {
  getSelectedPhysicians,
  getSelectedPackage,
  getUserSubscriptionId,
} from './selectors';
import {
  PackagesSlug,
  Physician,
  SubscribeData,
  SubscribeSummaryData,
  TemplateKind,
} from '../../../services/types';

export function* fetchPhysiciansAvailableWorker() {
  try {
    const { data: availablePhysiciansArray } = yield call(
      api.getPhysiciansAvailable,
    );

    yield put(
      actions.fetchPhysiciansAvailableSuccess(availablePhysiciansArray),
    );
  } catch {
    yield put(actions.fetchPhysiciansAvailableFailure());
  }
}

export function* fetchSubscriptionSummaryWorker() {
  try {
    yield put(actions.fetchSubscriptionSummaryStart());

    const subscriptionId: string = yield select(getUserSubscriptionId);
    const selectedPhysicians: Physician[] = yield select(getSelectedPhysicians);
    const selectedPackage: PackagesSlug = yield select(getSelectedPackage);

    const subscribeSummaryData: SubscribeSummaryData = {
      products: {
        [selectedPackage]: selectedPhysicians.length,
      },
      typeHiring: 'upgrade',
    };

    const { data } = yield call(api.getSubscriptionSummary, {
      subscriptionId,
      subscribeSummaryData,
    });

    yield put(actions.fetchSubscriptionSummarySuccess(data.data));
  } catch {
    yield put(actions.fetchSubscriptionSummaryFailure());
  }
}

export function* submitSubscriptionWorker() {
  try {
    yield put(actions.submitSubscriptionStart());
    const selectedPhysicians: Physician[] = yield select(getSelectedPhysicians);
    const selectedPackage: PackagesSlug = yield select(getSelectedPackage);

    const subscriptionUpgradeData = selectedPhysicians.reduce(
      (subscribeData, physician) => ({
        [selectedPackage]: {
          ...subscribeData[selectedPackage],
          [physician.id]: [TemplateKind.CONFIRMATION, TemplateKind.REMINDER],
        },
      }),
      { [selectedPackage]: {} } as SubscribeData,
    );

    yield call(api.postSubscriptionUpgrade, subscriptionUpgradeData);

    yield put(actions.toggleOpenSummary());
    yield put(actions.toggleOpenSuccess());
    yield put(actions.submitSubscriptionSuccess());
  } catch {
    yield put(actions.submitSubscriptionFailure());
  }
}

export default function* messagingSelectPhysiciansSagas() {
  yield all([
    takeLatest(
      actions.fetchPhysiciansAvailable,
      fetchPhysiciansAvailableWorker,
    ),
    takeLatest(
      actions.fetchSubscriptionSummary,
      fetchSubscriptionSummaryWorker,
    ),
    takeLatest(actions.submitSubscription, submitSubscriptionWorker),
  ]);
}
