import { call, put, takeLatest } from 'redux-saga/effects';

import { Response } from '@types';
import { captureException } from 'shared/utils/handlerErrors';
import { fetchSSOToken, fetchSSOTokenFailure, fetchSSOTokenSuccess } from '.';
import * as api from '../../services/iclinic-pay';
import { SSOTokenRawData } from './types';

export function* fetchSSOTokenWorker() {
  try {
    const { data }: Response<SSOTokenRawData> = yield call(api.fetchSSOToken);
    yield put(fetchSSOTokenSuccess(data));
  } catch (error) {
    captureException(error);
    yield put(fetchSSOTokenFailure());
  }
}

export default function* watchSaveWorker() {
  yield takeLatest(fetchSSOToken, fetchSSOTokenWorker);
}
