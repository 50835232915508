// External
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

// Internal
import { Input } from 'ui/new/form';
// Style
import style from './FilterAge.scss';

const FilterAge = ({ name, children }) => (
  <div className={style.filterAge__container}>
    <div className={style.filterAge__text}>
      Pacientes com
      <Field
        component="select"
        name={`${name}.arguments.type`}
        type="text"
        className={style.filterAge__filterType}
      >
        <option value="ge">igual ou mais que</option>
        <option value="gt">mais que</option>
        <option value="le">igual ou menos que</option>
        <option value="lt">menos que</option>
        <option value="eq">exatamente</option>
      </Field>
      <Field
        component={Input}
        name={`${name}.arguments.value`}
        parse={Number}
        type="number"
        min={0}
        max={150}
        className={style.filterAge__age}
      />
      anos
    </div>
    {children}
  </div>
);

FilterAge.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FilterAge;
