import React from 'react';
import {
  MaterialUICore,
  axiomTokens,
  Chip,
  ChipProps,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

type ChipStyledProps = {
  widthCustomized?: string;
} & ChipProps;

export const EachChipContent = styled(
  ({ widthCustomized, ...rest }: ChipStyledProps) => <Chip {...rest} />,
)(() => ({
  margin: 0,

  '&.MuiChip-root': {
    width: ({ widthCustomized }: ChipStyledProps) => widthCustomized,
  },

  '& span': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export const MoreItems = styled('div')(({ theme }) => ({
  fontFamily: axiomTokens.font.family.base,
  fontSize: axiomTokens.font.size.xxxs,
  lineHeight: axiomTokens.font.lineHeight.xxs,
  fontWeight: axiomTokens.font.weight.medium,
  color: axiomTokens.color.typeface.smooth,
  marginLeft: 'auto',
  marginTop: 'auto',

  [theme.breakpoints.down('md')]: {
    fontSize: axiomTokens.font.size.xxs,
  },
}));

export const ContentAllChips = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: axiomTokens.spacing[1],
  overflow: 'hidden',

  [theme.breakpoints.down('md')]: {
    gap: axiomTokens.spacing[2],
  },
}));
