import React from 'react';
import {
  Body,
  Button,
  MaterialUICore,
  MaterialIcons,
  axiomTokens,
} from '@iclinic/design-system';

const { Check, Close } = MaterialIcons;
const {
  Drawer: DSDrawer,
  IconButton,
  styled,
  TableContainer,
  TableBody,
  TableCell,
} = MaterialUICore;
const { spacing, border, color } = axiomTokens;

type HTMLElementProps<T> = React.DetailedHTMLProps<React.HTMLAttributes<T>, T>;

type TabCellProps = {
  isIncluded?: boolean;
} & HTMLElementProps<HTMLDivElement>;

export const Drawer = styled(DSDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    borderLeft: `${border.width.xs} solid ${color.border.subtle}`,
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 480,
    },
    [theme.breakpoints.up('md')]: {
      width: 754,
    },
  },
}));

export const Content = styled('div')(({ theme }) => ({
  padding: spacing[4],
  overflow: 'auto',
  height: '100%',

  [theme.breakpoints.up('sm')]: {
    padding: `${spacing[8]} ${spacing[8]} ${spacing[4]}`,
  },
}));

export const Header = styled('div')(({ theme }) => ({
  marginBottom: spacing[1],
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-between',

  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
    backgroundColor: color.surface.accent.default,
    top: 0,
    left: 0,
    width: '100%',
    padding: `${spacing[3]} ${spacing[4]}`,
    borderBottom: `${border.width.xs} solid ${color.border.subtle}`,
    '& .MuiTypography-root': {
      display: 'none',
    },
  },
}));

export const BackButton = styled(Button)(({ theme }) => ({
  color: color.link.default,
  display: 'inline-flex',

  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

export const CloseIconButton = styled(IconButton)({
  marginLeft: spacing[2],
  marginTop: spacing[2],
  alignSelf: 'flex-start',
});

export const CheckIcon = styled(Check)(() => ({
  color: color.surface.feedback.success.accent,
}));

export const UncheckIcon = styled(Close)(() => ({
  color: color.typeface.smooth,
}));

export const TabContainer = styled(TableContainer)(({ theme }) => ({
  color: color.typeface.smooth,
  marginTop: spacing[14],
  paddingTop: spacing[6],
  [theme.breakpoints.up('sm')]: {
    paddingTop: '0',
  },
  [theme.breakpoints.up('md')]: {
    marginTop: '0',
  },
}));

export const TabBody = styled(TableBody)(() => ({
  '& > .MuiTableRow-root th': {
    padding: `${spacing[2]} 0`,
  },
}));

export const TabCell = styled(TableCell)({
  borderBottom: `1px solid ${color.border.subtle}`,

  '&:not(:first-child)': {
    textAlign: 'center',
  },
});

export const TableCellContentWrapper = styled(
  ({ isIncluded: notIncluded, ...rest }: TabCellProps) => <div {...rest} />,
)({
  display: 'flex',
  alignItems: 'center',
  columnGap: spacing[2],
  color: ({ isIncluded }: TabCellProps) =>
    isIncluded ? color.typeface.strong : color.typeface.onInteractive.disabled,

  '&>.MuiSvgIcon-root,& svg': {
    color: ({ isIncluded }: TabCellProps) =>
      isIncluded ? color.icon.feedback.info : color.icon.disabled,
  },

  '&>.MuiTypography-root': {
    textDecoration: ({ isIncluded }: TabCellProps) =>
      isIncluded ? 'none' : 'line-through',
  },
});

export const FuncinalityName = styled(Body)({
  color: 'inherit',
});

export const iconWrapper = styled('div')({
  height: spacing[6],
  width: spacing[6],

  '& svg': {
    width: '100%',
    height: '100%',
    fill: 'currentColor',
  },
});
