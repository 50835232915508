import React, { useEffect, useState } from 'react';
import { Switch, RouteComponentProps, Route } from 'react-router-dom';

import { App } from 'features/video-conference/pages/index';
import ParticipantAcceptTerms from 'features/video-conference/pages/ParticipantAcceptTerms';
import ErrorDialog from 'features/video-conference/components/ErrorDialog';
import { useHandleErrors } from 'features/video-conference/hooks/useHandleErrors';
import { ConferenceProvider } from 'features/video-conference/state/conferenceContext';
import { ParticipantProvider } from 'features/video-conference/state/participantContext';
import { ChatProvider } from 'features/video-conference/state/chatContext';
import { AnnotationProvider } from 'features/video-conference/state/annotationContext';
import { CaptionProvider } from '../state/captionContext';
import { servicesLabels } from '../constants';
import { getTelemedicineTokenFromUrl } from '../utils';

const VideoConferenceRoutes = ({ match }: RouteComponentProps<{}>) => {
  const { error, setError } = useHandleErrors();
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    if (!firstLoad) {
      setFirstLoad(true);

      const token = getTelemedicineTokenFromUrl();
      global.localStorage.setItem(servicesLabels.CONFERENCE_TOKEN, token || '');
    }
  }, [firstLoad]);

  return (
    <ConferenceProvider onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ParticipantProvider>
        <ChatProvider>
          <AnnotationProvider>
            <CaptionProvider>
              <Switch>
                <Route
                  path={`${match.path}/medico/consulta`}
                  render={() => <App isPhysician />}
                />

                <Route
                  path={`${match.path}/participante/acessar-consulta`}
                  component={ParticipantAcceptTerms}
                />

                <Route
                  path={`${match.path}/participante/consulta`}
                  render={() => <App isPhysician={false} />}
                />
              </Switch>
            </CaptionProvider>
          </AnnotationProvider>
        </ChatProvider>
      </ParticipantProvider>
    </ConferenceProvider>
  );
};

export default VideoConferenceRoutes;
