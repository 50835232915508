// External
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Internal
import style from './Price.scss';
import { PRICE_TEXT, PROMOTION_TEXT_VALUES } from './constants';

export default function Price({
  text,
  promotion,
  total,
  children,
  promotionText,
}) {
  const textPrice = promotion ? promotionText : PRICE_TEXT.normal;
  const totalPricePromotion = promotion ? `R$${total}` : promotion;
  const price = promotion ? `R$${promotion}` : `R$${total}`;

  return (
    <div className={cx(style.price, style[`custom__${text}`])}>
      <span className={cx(style.label, style[`custom__${textPrice}`])}>{totalPricePromotion}</span>
      {children}
      {price}
    </div>
  );
}

Price.defaultProps = {
  text: PRICE_TEXT.normal,
  promotionText: PROMOTION_TEXT_VALUES.promotion,
  promotion: '',
  total: '',
  children: null,
};

Price.propTypes = {
  text: PropTypes.oneOf(Object.values(PRICE_TEXT)),
  promotionText: PropTypes.oneOf(Object.values(PROMOTION_TEXT_VALUES)),
  promotion: PropTypes.string,
  total: PropTypes.string,
  children: PropTypes.node,
};
