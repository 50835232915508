import React from 'react';
import ContentLoader from 'react-content-loader';

import { MaterialUICore } from '@iclinic/design-system';

const { useMediaQuery, useTheme } = MaterialUICore;

const values = {
  backgroundColor: '#ECEFF1',
  foregroundColor: '#F8F9FA',
  speed: 1,
};

const rectsMD = (
  <>
    <rect x="0" y="135" rx="3" ry="3" width="280" height="355" />
    <rect x="310" y="135" rx="3" ry="3" width="280" height="355" />
    <rect x="620" y="135" rx="3" ry="3" width="280" height="355" />
  </>
);

const rectsSM = (
  <>
    <rect x="0" y="135" rx="3" ry="3" width="100%" height="260" />
    <rect x="0" y="425" rx="3" ry="3" width="100%" height="260" />
    <rect x="0" y="715" rx="3" ry="3" width="100%" height="260" />
  </>
);

const LpIclinicPayLoader = (): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const height = matches ? 500 : 970;
  const responsiveRects = matches ? rectsMD : rectsSM;

  return (
    <>
      <ContentLoader
        speed={values.speed}
        width="100%"
        height={height}
        backgroundColor={values.backgroundColor}
        foregroundColor={values.backgroundColor}
        uniqueKey="lp-iclinicpay-loader"
        data-testid="lp-iclinicpay-loader"
      >
        <rect x="0" y="0" rx="3" ry="3" width="298" height="38" />
        <rect x="0" y="56" rx="3" ry="3" width="510" height="45" />
        {responsiveRects}
      </ContentLoader>
    </>
  );
};

export default LpIclinicPayLoader;
