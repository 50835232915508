// External
import React, { useCallback } from 'react';
import { Field, reduxForm, isInvalid } from 'redux-form';
import { connect } from 'react-redux';

// Internal
import Modal from 'ui/new/modal';
import Button from 'ui/new/button';
import { BUTTON } from 'ui/new/button/constants';
import { Input } from 'ui/new/form';
import { FORM_ADD_EMAIL_FLOW_MODAL } from 'shared/constants/forms';
import {
  getAddEmailFlowModalName,
  getAllEmailFlowsNames,
} from 'state/marketing/emailFlow/emailFlowManagement/selectors';
import { StoreState } from 'state/rootReducer';
import { FOOTER } from 'ui/new/modal/constants';
import useWidthUpdater from 'shared/hooks/useWidthUpdater';
import style from './AddEmailFlowModal.scss';

interface StateProps {
  isEmpty: boolean;
  invalid: boolean;
  emailFlowNames: string[];
}

interface DispatchProps {
  handleSubmit: (onAddEmailFlow: Function) => void;
  onCloseClick: () => void;
  onAddEmailFlow: () => void;
}

interface AddEmailFlowModalProps extends StateProps, DispatchProps {}

const AddEmailFlowModal = ({
  handleSubmit,
  onCloseClick,
  onAddEmailFlow,
  isEmpty,
  emailFlowNames,
  invalid,
}: AddEmailFlowModalProps): JSX.Element => {
  const validateDuplicateName = useCallback(
    (value: string): string | undefined => {
      if (emailFlowNames.find((name: string) => value === name)) {
        return 'Nome já existe';
      }

      return undefined;
    },
    [emailFlowNames],
  );

  const isMobile = useWidthUpdater();
  const modalAlignment = isMobile ? FOOTER.split : FOOTER.right;

  return (
    <form onSubmit={handleSubmit(onAddEmailFlow)}>
      <div className={style.modal}>
        <Modal
          title="Nome do fluxo personalizado"
          onClose={onCloseClick}
          show
          classes={style}
          width={440}
        >
          <Modal.Body>
            <p>Defina um nome para o fluxo</p>
            <Field
              component={Input}
              type="text"
              name="name"
              placeholder="Ex: Promoção de aniversário da clínica"
              validate={validateDuplicateName}
            />
          </Modal.Body>
          <Modal.Footer align={modalAlignment}>
            <Button type={BUTTON.Light} onClick={onCloseClick}>
              CANCELAR
            </Button>
            <Button type={BUTTON.Primary} submit disabled={isEmpty || invalid}>
              SALVAR
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </form>
  );
};

const mapStateToProps = (state: StoreState, { name }: { name: string }) => ({
  initialValues: { name },
  isEmpty: !getAddEmailFlowModalName(state),
  emailFlowNames: getAllEmailFlowsNames(state),
  invalid: isInvalid(FORM_ADD_EMAIL_FLOW_MODAL),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_ADD_EMAIL_FLOW_MODAL,
  })(AddEmailFlowModal),
);
