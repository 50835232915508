import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing, color, border } = tokens;

export const Paper = styled('div')(({ theme }) => ({
  marginTop: spacing.md,

  [theme.breakpoints.up('sm')]: {
    background: color.neutral[0],
    border: `1px solid ${color.neutral[100]}`,
    borderRadius: border.radius.sm,
  },
}));
