import { Body } from '@iclinic/design-system';
import React from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';

import {
  CHECK_RECEIVERS_DEFAULT_MESSAGE,
  INVALID_PHONE_MSG,
} from '../../../constants';
import { actions } from '../../../state';
import {
  getIsPhoneNumberValid,
  getPhoneNumber,
} from '../../../state/selectors';
import * as S from './GetInfoPatientContent.styles';

export const GetInfoPatientContent = () => {
  const phoneNumber = useSelector(getPhoneNumber);
  const isPhoneValid = useSelector(getIsPhoneNumberValid);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    dispatch(actions.setPhoneNumber(value));
  };

  const formatedHelperText = isPhoneValid ? INVALID_PHONE_MSG : '';

  return (
    <>
      <Body variant="xs">{CHECK_RECEIVERS_DEFAULT_MESSAGE}</Body>
      <InputMask
        mask="(99) 99999-9999"
        maskChar=""
        value={phoneNumber}
        onChange={handleChange}
      >
        {() => (
          <S.PersonalizedTextField
            type="text"
            label="Número do paciente"
            placeholder="(00) 0000-0000"
            error={isPhoneValid}
            fullWidth
            helperText={formatedHelperText}
          />
        )}
      </InputMask>
    </>
  );
};
