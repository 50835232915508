import { MaterialUICore, TextField, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const inputHeight = '40px';

type FlexContainerProps = {
  orientation?: 'row' | 'column' | undefined;
  theme: MaterialUICore.Theme;
};
export const FlexContainer = styled('div')(
  ({ orientation = 'row', theme }: FlexContainerProps) => ({
    display: 'flex',
    flexDirection: orientation,
    fontFamily: tokens.font.family.base,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  }),
);

export const PostPaymentContainer = styled(FlexContainer)({
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
});

export const CustomTextField = styled(TextField)({
  height: inputHeight,
  '& > div': {
    fontSize: tokens.font.size.xs,
  },
  '& > div > input': {
    padding: '7px 13px !important',
    textAlign: 'right',
  },
});
