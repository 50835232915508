// eslint-disable-next-line max-len
const REGEX_ELO = new RegExp(
  '^(401178|401179|431274|438935|451416|457393|457631|457632|504175|627780|636297|636368|(506699|5067[0-6]\\d|50677[0-8])|(50900\\d|5090[1-9]\\d|509[1-9]\\d{2})|65003[1-3]|(65003[5-9]|65004\\d|65005[0-1])|(65040[5-9]|6504[1-3]\\d)|(65048[5-9]|65049\\d|6505[0-2]\\d|65053[0-8])|(65054[1-9]|6505[5-8]\\d|65059[0-8])|(65070\\d|65071[0-8])|65072[0-7]|(65090[1-9]|65091\\d|650920)|(65165[2-9]|6516[6-7]\\d)|(65500\\d|65501\\d)|(65502[1-9]|6550[3-4]\\d|65505[0-8]))[0-9]{10,12}',
  'gi',
);
const REGEX_DINERS = new RegExp('^3(?:0[0-5]|[68][0-9])[0-9]{11}$');
const REGEX_DISCOVER = new RegExp(
  '^6(?:011|5[0-9]{2}|4[4-9][0-9]{1}|(22(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[01][0-9]|92[0-5]$)[0-9]{10}$))[0-9]{12}$',
);
const REGEX_HIPERCARD = new RegExp('^(38[0-9]{17}|60[0-9]{14})$');
const REGEX_AMEX = new RegExp('^3[47][0-9]{13}$');
const REGEX_AURA = new RegExp('^50[0-9]{14,17}$');
const REGEX_MASTERCARD = new RegExp(
  '^(5[1-5][0-9]{14}|2221[0-9]{12}|222[2-9][0-9]{12}|22[3-9][0-9]{13}|2[3-6][0-9]{14}|27[01][0-9]{13}|2720[0-9]{12})$',
);
const REGEX_VISA = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');

export const REGEX_SECURITY_CODE = (numberLength) =>
  new RegExp(`^[0-9]{${numberLength}}$`);

export const CREDIT_CARD_NAMES = {
  amex: 'Amex',
  aura: 'Aura',
  diners: 'Diners',
  discover: 'Discover',
  elo: 'Elo',
  hipercard: 'Hipercard',
  mastercard: 'Mastercard',
  visa: 'Visa',
};

export const CREDIT_CARD_AVAILABLE = [
  'agiplan',
  'amex',
  'aura',
  'cabal',
  'credsystem',
  'credz',
  'diners',
  'discover',
  'elo',
  'hipercard',
  'jcb',
  'mastercard',
  'sorocred',
  'visa',
];

export const CREDIT_CARD_LIST = [
  { name: CREDIT_CARD_NAMES.amex, regex: REGEX_AMEX },
  { name: CREDIT_CARD_NAMES.aura, regex: REGEX_AURA },
  { name: CREDIT_CARD_NAMES.diners, regex: REGEX_DINERS },
  { name: CREDIT_CARD_NAMES.discover, regex: REGEX_DISCOVER },
  { name: CREDIT_CARD_NAMES.elo, regex: REGEX_ELO },
  { name: CREDIT_CARD_NAMES.hipercard, regex: REGEX_HIPERCARD },
  { name: CREDIT_CARD_NAMES.mastercard, regex: REGEX_MASTERCARD },
  { name: CREDIT_CARD_NAMES.visa, regex: REGEX_VISA },
];
