import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';

type StepsHeaderProps = {
  step?: number;
  totalSteps?: number;
  type: SellerType;
};

export default function StepsHeader({
  type,
  step = 0,
  totalSteps = 2,
}: StepsHeaderProps) {
  const sellerIsPJ = type === SellerType.PJ;

  return (
    <div style={{ margin: 10 }}>
      <Stepper activeStep={step / totalSteps}>
        <Step>
          <StepLabel>
            {sellerIsPJ ? 'Pessoa jurídica' : 'Pessoa física'}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>Conta Bancária</StepLabel>
        </Step>
      </Stepper>
    </div>
  );
}
