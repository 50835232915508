// External
import React from 'react';
import { Switch, RouteComponentProps, Route } from 'react-router-dom';
import {
  EditCampaign,
  Marketing as PageMarketing,
  EmailFlow,
  EmailFlowCustom,
  EditEmailFlowReturn,
  Unsubscribe,
} from 'pages';

import AuthenticatedRoute from '../AuthenticatedRoute';

const Routes = ({ match }: RouteComponentProps<{}>) => (
  <Switch>
    <AuthenticatedRoute
      exact
      path={`${match.path}/campanhas`}
      component={PageMarketing}
    />
    <AuthenticatedRoute
      exact
      path={`${match.path}/campanhas/:campaignId`}
      component={EditCampaign}
    />
    <AuthenticatedRoute
      exact
      path={`${match.path}/sequencia-padrao`}
      component={EmailFlow}
    />
    <AuthenticatedRoute
      exact
      path={`${match.path}/sequencia-personalizada`}
      component={EmailFlowCustom}
    />
    <AuthenticatedRoute
      exact
      path={`${match.path}/sequencia-padrao/:physicianId/:id`}
      component={EditEmailFlowReturn}
    />
    <AuthenticatedRoute
      exact
      path={`${match.path}/sequencia-personalizada/:physicianId/:id`}
      component={EditEmailFlowReturn}
    />
    <Route
      exact
      path={`${match.path}/cancelar-assinatura`}
      component={Unsubscribe}
    />
  </Switch>
);

export default Routes;
