import React from 'react';

import { Loader } from '@iclinic/design-system';
import CardPharma from './CardPharma';
import * as S from './BoxesList.style';
import { SampleBox } from '../../services/types';

interface BoxesListProps {
  isFetching: boolean;
  listBoxes?: SampleBox[];
  onDetailsClick: (box: SampleBox) => void;
  emptyMessage: string;
}

const BoxesList = ({
  isFetching,
  listBoxes,
  onDetailsClick,
  emptyMessage,
}: BoxesListProps) => {
  return (
    <S.Container>
      {isFetching ? (
        <S.Wrapper>
          <Loader data-testid="loader-boxes" />
        </S.Wrapper>
      ) : (
        <>
          {listBoxes?.length ? (
            <S.GridListContainer>
              {listBoxes.map((box) => (
                <CardPharma
                  key={box.id}
                  boxId={box.id}
                  name={box.name}
                  logoPharma={box.pharmaceutical.logo}
                  medicaments={box.medicaments}
                  onDetailsClick={() => onDetailsClick(box)}
                  canRequest={box.can_request}
                  nextRequestDate={box.next_request_date}
                />
              ))}
            </S.GridListContainer>
          ) : (
            <S.Wrapper>
              <S.TextMessage align="center" variant="sm">
                {emptyMessage}
              </S.TextMessage>
            </S.Wrapper>
          )}
        </>
      )}
    </S.Container>
  );
};

export default BoxesList;
