import { MaterialUICore, tokens } from '@iclinic/design-system';

const { Checkbox, FormControlLabel, styled } = MaterialUICore;
const { border, color, spacing } = tokens;

export const CheckboxesWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  border: `${border.width.hairline} solid ${color.neutral[100]}`,
  borderRadius: border.radius.sm,
  padding: `${spacing.base} ${spacing.sm} ${spacing.base} ${spacing.base}`,
  maxHeight: 248,
  overflow: 'auto',
});

export const Label = styled(FormControlLabel)({
  '&.MuiFormControlLabel-root': {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export const Divider = styled('hr')({
  margin: 0,
  backgroundColor: color.neutral[100],

  '&:last-child': {
    display: 'none',
  },
});

export const StyledCheckbox = styled(Checkbox)({
  '&.MuiCheckbox-root': {
    color: color.neutral[300],
  },

  '&.Mui-checked': {
    color: color.primary.main,
  },
});
