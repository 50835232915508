/* eslint-disable jsx-a11y/anchor-is-valid */
// External
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Modal,
  Text,
  Container,
  Alert,
  Link,
  List,
} from '@iclinic/iclinic-ui';

// Internal
import { getCardImageFlag } from 'shared/utils/creditCardValidation';
import { FORM_CREDITCARD } from 'shared/constants/forms';
import {
  getProductsDetail,
  getWarningMessage,
} from 'state/creditcard/selectors';
import * as actions from 'state/creditcard/actions';
import enhanceSubscriptionForm from './EnhanceSubscriptionForm';
import SubscriptionModalForm from '../components/SubscriptionModalForm';
import Product from '../components/product';
import Form from '../components/form';
import { getUrlParams } from 'shared/utils/url';

const EnhanceSubscriptionForm = enhanceSubscriptionForm(
  FORM_CREDITCARD,
  actions.submit,
);
const ErrorItem = ({ data }) => data.message;

class SubscriptionEditContainer extends React.Component {
  componentDidMount() {
    if (getUrlParams('modal') === 'true') {
      this.openDetail();
    }
  }

  openDetail = () => {
    const { fetchDetail, openDetail } = this.props;

    fetchDetail();
    openDetail();
  };

  render() {
    const {
      isFetching,
      isOpen,
      isAccountBlocked,
      finalNumber,
      flag,
      errors,
      closeDetail,
      productsDetailSelector,
      accountWarningMessage,
    } = this.props;

    return (
      <>
        <Container direction="row" alignItems="center">
          {finalNumber ? (
            <>
              <Text kind="highlight">{`Final: ${finalNumber}`}</Text>
              <img
                alt="Bandeira do cartão de crédito"
                src={getCardImageFlag(flag)}
              />
            </>
          ) : (
            <Text kind="highlight">Nenhum cartão encontrado</Text>
          )}
          <Link kind="primary" onClick={this.openDetail}>
            Clique aqui para adicionar
          </Link>
        </Container>
        {isOpen && (
          <EnhanceSubscriptionForm
            form={FORM_CREDITCARD}
            render={({ handleSubmit, submitForm, number }) => (
              <Modal
                title={isAccountBlocked ? 'Conta bloqueada' : 'Novo cartão'}
                show
                onCloseClick={closeDetail}
                width={692}
              >
                <Modal.Body>
                  {!!accountWarningMessage && (
                    <Alert kind="warning">{accountWarningMessage}</Alert>
                  )}
                  {errors && errors.length > 0 && (
                    <Alert kind="danger">
                      <List
                        kind="type-circle"
                        items={errors}
                        renderItem={ErrorItem}
                      />
                    </Alert>
                  )}
                  {productsDetailSelector && (
                    <Form.Row>
                      <Form.Column>
                        <Product.Description
                          productName={productsDetailSelector.productsNames}
                          users={productsDetailSelector.totalUsers}
                          value={productsDetailSelector.totalPriceFormatted}
                          period={productsDetailSelector.period}
                          content={productsDetailSelector.totalMessage}
                        />
                      </Form.Column>
                      <Form.Column>
                        <SubscriptionModalForm
                          handleSubmit={handleSubmit}
                          number={number}
                          cardAdd={false}
                        />
                      </Form.Column>
                    </Form.Row>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    kind="primary"
                    loaderKind="gray-1"
                    loader={isFetching}
                    disabled={isFetching}
                    onClick={submitForm}
                    type="button"
                  >
                    Adicionar
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          />
        )}
      </>
    );
  }
}

SubscriptionEditContainer.defaultProps = {
  isOpen: false,
  isFetching: false,
  errors: [],
  accountWarningMessage: '',
  productsDetailSelector: null,
};

SubscriptionEditContainer.propTypes = {
  isOpen: PropTypes.bool,
  isFetching: PropTypes.bool,
  fetchDetail: PropTypes.func.isRequired,
  openDetail: PropTypes.func.isRequired,
  closeDetail: PropTypes.func.isRequired,
  isAccountBlocked: PropTypes.bool.isRequired,
  finalNumber: PropTypes.string.isRequired,
  flag: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ),
  productsDetailSelector: PropTypes.shape({
    productsNames: PropTypes.string,
    totalUsers: PropTypes.number,
    totalPriceFormatted: PropTypes.string,
    totalMessage: PropTypes.string,
    period: PropTypes.string,
  }),
  accountWarningMessage: PropTypes.string,
};

const mapStateToProps = () => (state) => ({
  productsDetailSelector: getProductsDetail(state),
  accountWarningMessage: getWarningMessage(state),
  ...state.creditcard,
});

export default connect(mapStateToProps, actions)(SubscriptionEditContainer);
