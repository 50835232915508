import React from 'react';
import { Box } from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';
import * as S from './Banner.styles';

export const Banner = () => (
  <S.BannerWrapper>
    <S.ImageWrapper>
      <img src={image('subscription.additionalPlans')} alt="" />
    </S.ImageWrapper>
    <Box>
      <S.Heading id="main-heading" variant="xxl">
        Reduza em até 70% a taxa de faltas dos seus pacientes.
      </S.Heading>
      <S.Subheading id="sub-heading" variant="md">
        Conheça o novo Whatsapp Business do iClinic!
      </S.Subheading>
      <S.Body id="body" variant="sm">
        Agora você pode enviar mensagens de forma automática pelo WhatsApp para
        seus pacientes. E você ainda escolhe como utilizar:
      </S.Body>
      <S.List id="list">
        <li>
          <S.Body variant="sm" bold>
            Lembrete e confirmação de consulta;
          </S.Body>
        </li>
        <li>
          <S.Body variant="sm" bold>
            Envio das suas Prescrições de iClinic RX
          </S.Body>
        </li>
      </S.List>
    </Box>
  </S.BannerWrapper>
);
