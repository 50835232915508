import { trackCustomEvent } from 'shared/utils/trackEvents';

export type UserData = {
  profileId: string | null;
  isSubscriber?: boolean | null;
};

type DonwsellConfirmationTrackData = {
  userData: UserData;
  stepName: string;
  stepNum: number;
  failureReason?: string | null;
};

type DownsellReasonTrackData = {
  userData: UserData;
  stepName: string;
  stepNum: number;
  stepValue?: string | null;
};

export const USER_CONTEXT_SCHEMA = 'iglu:br.com.iclinic/user/jsonschema/1-0-0';
export const DOWNSELL_CONFIRMATION_TRACK_SCHEMA =
  'iglu:br.com.iclinic/downsell_confirmation/jsonschema/1-0-0';
export const DOWNSELL_REASON_TRACK_SCHEMA =
  'iglu:br.com.iclinic/downsell_reason/jsonschema/1-0-0';

const getUserContextTracker = (userData: UserData) => ({
  schema: USER_CONTEXT_SCHEMA,
  data: {
    user_id: userData.profileId,
    user_is_subscriber: userData.isSubscriber,
  },
});

export function trackDownsellConfirmation({
  userData,
  stepName,
  stepNum,
  failureReason = null,
}: DonwsellConfirmationTrackData) {
  const userContext = getUserContextTracker(userData);

  trackCustomEvent(
    DOWNSELL_CONFIRMATION_TRACK_SCHEMA,
    {
      step_name: stepName,
      step_num: stepNum,
      failure_reason: failureReason,
    },
    [userContext],
  );
}

export function trackDownsellReason({
  userData,
  stepName,
  stepNum,
  stepValue = null,
}: DownsellReasonTrackData) {
  const userContext = getUserContextTracker(userData);

  trackCustomEvent(
    DOWNSELL_REASON_TRACK_SCHEMA,
    {
      step_name: stepName,
      step_num: stepNum,
      step_value: stepValue,
    },
    [userContext],
  );
}
