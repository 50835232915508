import React from 'react';

import Feedback from 'features/video-conference/components/TeamComponents/Feedback';
import { waitingParticipant } from 'features/video-conference/constants';

export default function WaitParticipant(): JSX.Element {
  return (
    <Feedback
      title={waitingParticipant.title}
      message={waitingParticipant.message}
      altText={waitingParticipant.altText}
      imagePath={waitingParticipant.imagePath}
      linkText={waitingParticipant.linkText}
      linkUrl={waitingParticipant.linkUrl}
    />
  );
}
