import React from 'react';

import style from './Body.scss';

type BodyProps = {
  children: React.ReactNode
};

function Body({
  children,
}: BodyProps): JSX.Element {
  return (
    <tbody className={style.body}>
      {children}
    </tbody>
  );
}
export default Body;
