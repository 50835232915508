import { captureException } from '@sentry/browser';
import { useCallback, useEffect, useState } from 'react';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';

import { CaptionData } from 'features/video-conference/constants/types';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { createCaptionData } from 'features/video-conference/utils';
import { useTranscript } from '../useTranscript';
import { useCaptionContext } from 'features/video-conference/state/captionContext';
import useMainParticipant from '../useMainParticipant';

export const useCaption = () => {
  const [transcriptActual, setTranscriptActual] = useState('');

  const {
    isRemoteCaptionActive,
    isCaptionActive,
    remoteTranscript,
    setRemoteTranscript,
    setIsRemoteCaptionActive,
    setIsCaptionActive,
  } = useCaptionContext();
  const { addTranscriptItem } = useTranscript();

  const {
    browserSupportsSpeechRecognition,
    transcript,
    interimTranscript,
    listening,
    resetTranscript,
  } = useSpeechRecognition();

  const { room, isMicrophoneEnable } = useConference();

  const mainParticipant = useMainParticipant();

  const startListeningCaptions = useCallback(async () => {
    if (!browserSupportsSpeechRecognition) return;

    await SpeechRecognition.startListening({
      language: 'pt-BR',
      continuous: true,
    });
  }, [browserSupportsSpeechRecognition]);

  const stopListeningCaptions = useCallback(() => {
    if (!browserSupportsSpeechRecognition) return;

    SpeechRecognition.stopListening();
    resetTranscript();
  }, [browserSupportsSpeechRecognition, resetTranscript]);

  const sendCaptionMessageToParticipant = useCallback(
    (data: CaptionData) => {
      if (!room) return;

      const [localDataTrackPublication] = Array.from(
        room.localParticipant.dataTracks.values(),
      );

      localDataTrackPublication?.track?.send(JSON.stringify(data));
    },
    [room],
  );

  useEffect(() => {
    const handleCaptions = async () => {
      if (isMicrophoneEnable) {
        await startListeningCaptions();
        return;
      }

      if (listening) stopListeningCaptions();
    };

    handleCaptions().catch(captureException);
  }, [
    isMicrophoneEnable,
    isRemoteCaptionActive,
    listening,
    startListeningCaptions,
    stopListeningCaptions,
  ]);

  useEffect(() => {
    if (!listening) return;

    const captionData = createCaptionData({
      captionType: 'caption-transcript',
      isCaptionActive: true,
      transcript,
    });

    sendCaptionMessageToParticipant(captionData);
  }, [
    interimTranscript,
    listening,
    mainParticipant,
    sendCaptionMessageToParticipant,
    transcript,
  ]);

  useEffect(() => {
    if (
      mainParticipant &&
      interimTranscript.length === 0 &&
      transcriptActual.length > 0
    ) {
      addTranscriptItem(transcriptActual);
      setTranscriptActual('');
    }
  }, [
    addTranscriptItem,
    interimTranscript.length,
    mainParticipant,
    transcriptActual,
  ]);

  useEffect(() => {
    if (!isCaptionActive) setRemoteTranscript('');
  }, [isCaptionActive, setRemoteTranscript]);

  return {
    sendCaptionMessageToParticipant,
    startListeningCaptions,
    stopListeningCaptions,
    setIsRemoteCaptionActive,
    remoteTranscript,
    setRemoteTranscript,
    isCaptionActive,
    setIsCaptionActive,
  };
};
