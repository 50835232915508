import { createSelector } from 'reselect';

import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';
import { FinancialSellerData } from '../panel/types';
import { Notifications, Notification, OverviewFilters } from './types';

export const getNotificationToken = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.notificationsCenter.accessToken?.data;

export const getNotificationTokenFetchStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.notificationsCenter.accessToken?.fetchStatus;

export const getNotificationTokenErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.notificationsCenter.accessToken?.error;

export const isFetchingNotificationToken = createSelector(
  getNotificationTokenFetchStatus,
  (status) => status === RequestStatus.Pending,
);

export const getNotifications = ({
  onlinePayment,
}: StoreState): Notifications | undefined =>
  onlinePayment.notificationsCenter.notifications?.data;

export const getNotificationsList = ({
  onlinePayment,
}: StoreState): [] | Notification[] =>
  onlinePayment.notificationsCenter.notificationsList;

export const getPageNumber = ({ onlinePayment }: StoreState): number =>
  onlinePayment.notificationsCenter.pageNumber;

export const getUserByNotifications = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.notificationsCenter.notifications?.data?.results[0].user;

export const getUnreadNotificationsCount = ({
  onlinePayment,
}: StoreState): number | undefined =>
  onlinePayment.notificationsCenter.notifications?.data
    ?.unreadNotificationsCount;

export const getUnreadNotifications = ({
  onlinePayment,
}: StoreState): number | undefined =>
  onlinePayment.notificationsCenter.unreadNotifications;

export const getDateNotificationsRead = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.notificationsCenter.dateNotificationsRead;

export const isNotificationsCenterOpen = ({
  onlinePayment,
}: StoreState): boolean =>
  onlinePayment.notificationsCenter.showNotificationsCenter;

export const getNotificationsFetchStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.notificationsCenter.notifications?.fetchStatus;

export const getNotificationsErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.notificationsCenter.notifications?.error;

export const isFetchingNotifications = createSelector(
  getNotificationsFetchStatus,
  (status) => status === RequestStatus.Pending,
);

export const getFinancialSellerFetchStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.notificationsCenter.financialSellerData?.fetchStatus;

export const getFinancialSellerErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.notificationsCenter.financialSellerData?.error;

export const isFetchingFinancialSeller = createSelector(
  getFinancialSellerFetchStatus,
  (status) => status === RequestStatus.Pending,
);

export const getFinancialSellerData = ({
  onlinePayment,
}: StoreState): FinancialSellerData | undefined =>
  onlinePayment.notificationsCenter.financialSellerData?.data;

export const getOverviewFilters = ({
  onlinePayment,
}: StoreState): OverviewFilters => onlinePayment.notificationsCenter.filters;
