import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { Checkbox } from '@iclinic/design-system';

import * as S from './styles';
import { initialValues, validationSchema } from './validationSchema';
import { setPaidByOtherPerson } from 'features/newFinance/state/payment';
import { cpfFormatter } from 'shared/utils/formatters';

const PaidByOtherPerson = (): JSX.Element => {
  const dispatch = useDispatch();
  const formikInstance = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => {},
    validateOnChange: false,
  });
  const [isOpened, setIsOpened] = useState(false);

  const {
    errors,
    touched,
    handleBlur,
    handleChange,
    values,
    validateForm,
  } = formikInstance;

  useEffect(() => {
    const saveForm = () => {
      const payload = {
        ...values,
        hasPaidByOtherPerson: isOpened,
      };
      dispatch(setPaidByOtherPerson(payload));
    };
    validateForm().then(() => {
      if (
        Object.values(values).length === Object.values(touched).length &&
        isEmpty(errors)
      )
        saveForm();
    }).catch((err) => {
      throw new Error(`Erro ao validar formulário - ${String(err.message)}`)
    });
  }, [values, touched, errors, isOpened, validateForm, dispatch]);

  return (
    <S.PaidByOtherContainer>
      <Checkbox
        label="Pago por outra pessoa"
        onChange={(event) => setIsOpened(event.target.checked)}
      />
      <div className={`${isOpened ? 'opened' : 'closed'}`}>
        {isOpened && (
          <div className="paid_by_other_form">
            <div className="form_inline_fields">
              <S.CustomTextField
                name="kinshipDegree"
                label="Parentesco"
                value={formikInstance.values.kinshipDegree}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(touched.kinshipDegree && errors.kinshipDegree)}
                helperText={
                  touched.kinshipDegree && errors.kinshipDegree
                    ? errors.kinshipDegree
                    : null
                }
                placeholder="Digite o grau de parentesco"
              />
              <S.CustomTextField
                name="fullName"
                label="Nome"
                value={values.fullName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!(touched.fullName && errors.fullName)}
                helperText={
                  touched.fullName && errors.fullName ? errors.fullName : null
                }
                placeholder="Digite seu nome completo"
              />
            </div>
            <S.CustomTextField
              name="kinshipCPF"
              label="CPF"
              value={cpfFormatter(values.kinshipCPF)}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.kinshipCPF && errors.kinshipCPF)}
              helperText={
                touched.kinshipCPF && errors.kinshipCPF
                  ? errors.kinshipCPF
                  : null
              }
              placeholder="Digite um CPF válido"
            />
          </div>
        )}
      </div>
    </S.PaidByOtherContainer>
  );
};

export default PaidByOtherPerson;
