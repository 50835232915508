export const weekDays = [
  { en: 'SUNDAY', pt: 'Domingo', index: 0 },
  { en: 'MONDAY', pt: 'Segunda', index: 1 },
  { en: 'TUESDAY', pt: 'Terça', index: 2 },
  { en: 'WEDNESDAY', pt: 'Quarta', index: 3 },
  { en: 'THURSDAY', pt: 'Quinta', index: 4 },
  { en: 'FRIDAY', pt: 'Sexta', index: 5 },
  { en: 'SATURDAY', pt: 'Sábado', index: 6 },
];

export default weekDays;
