import React from 'react';
import { Button, MaterialIcons, Chip } from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';
import * as S from './Trigger.styles';
import { TriggerDescription } from './TriggerDescription';

const { Close } = MaterialIcons;

type TriggerBoxProps = {
  alternative?: boolean;
  onClick: () => void;
  onClose: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

export const TriggerBox = ({
  alternative,
  onClick,
  onClose,
}: TriggerBoxProps) => {
  const buttonText = alternative ? 'Confira mais aqui' : 'Saber mais';

  return (
    <S.BoxTrigger onClick={onClick}>
      <S.Header>
        <Chip
          label="Novidade"
          severity="success"
          variant="outlined"
          size="small"
          active
        />
        <S.CloseWrapper data-testid="close" onClick={onClose}>
          <Close />
        </S.CloseWrapper>
      </S.Header>
      <S.Content>
        <S.ImageWrapper>
          <img src={image('subscription.fakedoorBanner')} alt="" />
        </S.ImageWrapper>

        <TriggerDescription alternative={alternative} />
      </S.Content>

      <Button size="small" variant="outlined">
        {buttonText}
      </Button>
    </S.BoxTrigger>
  );
};
