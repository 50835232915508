import React from 'react';

import { Button, MaterialIcons } from '@iclinic/design-system';
import { gotoAppRoute } from 'shared/utils/appRoutes';

const { ArrowBack } = MaterialIcons;

interface BackButtonProps {
  onClick?: () => void;
}

const BackButton = ({ onClick }: BackButtonProps) => {
  const handleClick = () => {
    if (onClick) onClick();

    gotoAppRoute('/agenda');
  };

  return (
    <Button
      startIcon={<ArrowBack />}
      color="transparent"
      size="large"
      onClick={handleClick}
    >
      Voltar ao iClinic
    </Button>
  );
};

export default BackButton;
