import * as Yup from 'yup';

import { CheckInstallment } from 'features/newFinance/types/interfaces';
import { format } from 'shared/hooks/useCurrencyMask';
import { DEFAULT_INSTALLMENT } from '../../utils/utils';

export type PaymentFormProps = {
  paymentType: string;
  value: number;
  total: number;

  dueDate: string;
  bankAccount: string;
  cash: string;

  defaultCreditCardInstallment: string;

  bankCheckIsInstallment: boolean;
  bankCheckInstallments: Array<CheckInstallment>;
  bankCheckAccount: number;
  bankCheckNumber: string;
  bankCheckValue: number;
  bankCheckDate: Date;
  bankSlipDueDate: string;

  [key: string]: unknown;
};

const initialValuesBankCheckDate = new Date();
initialValuesBankCheckDate.setDate(initialValuesBankCheckDate.getDate() + 1);

export const initialValues: PaymentFormProps = {
  paymentType: 'px',
  value: 0,
  total: 0,
  dueDate: '',
  bankAccount: '',
  cash: '',
  defaultCreditCardInstallment: '1',

  bankCheckIsInstallment: false,
  bankCheckInstallments: [DEFAULT_INSTALLMENT],
  bankCheckAccount: 0,
  bankCheckValue: 0,
  bankCheckDate: initialValuesBankCheckDate,
  bankCheckNumber: '',
  bankSlipDueDate: '',
};

export const requiredFieldError = 'Campo obrigatório';
export const maxValueError = (maxValue: number) =>
  `Valor máximo ${format(maxValue.toString())}`;
export const maxValueErrorMessage = 'Valor excede o valor máximo';

export const validationSchema = (maxValue: number) =>
  Yup.object().shape({
    value: Yup.number()
      .max(maxValue)
      .test(
        'isValidValue',
        maxValueErrorMessage,
        (val) => Number(val) <= maxValue,
      ),
    paymentType: Yup.string().required(requiredFieldError),

    dueDate: Yup.date(),

    bankAccount: Yup.string()
      .when('paymentType', {
        is: 'px',
        then: Yup.string()
          .required(requiredFieldError)
          .test((el) => !!el?.trim()),
      })
      .when('paymentType', {
        is: 'bt',
        then: Yup.string()
          .required(requiredFieldError)
          .test((el) => !!el?.trim()),
      })
      .when('paymentType', {
        is: 'bs',
        then: Yup.string()
          .required(requiredFieldError)
          .test((el) => !!el?.trim()),
      }),

    bankCheckIsInstallment: Yup.boolean().when(['paymentType'], {
      is: (paymentType: string) => paymentType === 'bc',
      then: Yup.boolean().required(),
    }),

    bankCheckInstallments: Yup.array().when(
      ['bankCheckIsInstallment', 'paymentType'],
      {
        is: (bankCheckIsInstallment: boolean, paymentType: string) =>
          bankCheckIsInstallment && paymentType === 'bc',
        then: Yup.array()
          .of(
            Yup.object().shape({
              bankCheckNumber: Yup.string()
                .required(requiredFieldError)
                .matches(/\d+/i),

              bankCheckValue: Yup.number()
                .required(requiredFieldError)
                .min(0, 'O valor do cheque não pode ser negativo')
                .max(maxValue, maxValueError(maxValue)),

              bankCheckDate: Yup.date()
                .required(requiredFieldError)
                .min(new Date(), 'Data inválida.'),
            }),
          )
          .test((array: Array<CheckInstallment>) => array.length >= 1),
      },
    ),

    bankCheckNumber: Yup.string().when(
      ['bankCheckIsInstallment', 'paymentType'],
      {
        is: (bankCheckIsInstallment: boolean, paymentType: string) =>
          !bankCheckIsInstallment && paymentType === 'bc',
        then: Yup.string().required(requiredFieldError).matches(/\d+/i),
      },
    ),

    bankCheckValue: Yup.number().when(
      ['bankCheckIsInstallment', 'paymentType'],
      {
        is: (bankCheckIsInstallment: boolean, paymentType: string) =>
          !bankCheckIsInstallment && paymentType === 'bc',
        then: Yup.number()
          .required(requiredFieldError)
          .min(0, 'O valor do cheque não pode ser negativo')
          .max(maxValue, maxValueError(maxValue)),
      },
    ),

    bankCheckDate: Yup.date().when(['bankCheckIsInstallment', 'paymentType'], {
      is: (bankCheckIsInstallment: boolean, paymentType: string) =>
        !bankCheckIsInstallment && paymentType === 'bc',
      then: Yup.date()
        .required(requiredFieldError)
        .min(new Date(), 'Data inválida.'),
    }),
    bankSlipDueDate: Yup.date(),
  });

export default initialValues;
