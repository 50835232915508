import { useState, useEffect } from 'react';
import { isFirefox } from 'react-device-detect';

import { isIOS } from 'features/video-conference/utils';

interface UseVerifyDevicePermissionProps {
  deviceType: string;
}

export function useVerifyDevicePermission({
  deviceType,
}: UseVerifyDevicePermissionProps) {
  const [hasPermission, setHasPermission] = useState(true);

  const handleChange = (event: any) =>
    setHasPermission(event.currentTarget?.state !== 'denied');

  useEffect(() => {
    const setPermissionBrowserChange = async () => {
      const permission = await navigator.permissions.query({
        name: deviceType as PermissionName,
      });

      permission.addEventListener('change', handleChange);
      return () => permission.removeEventListener('change', handleChange);
    };

    if (!isIOS && !isFirefox) setPermissionBrowserChange();
  }, [deviceType]);

  return { hasPermission };
}
