import React from 'react';
import { axiomTokens } from '@iclinic/design-system';

const SkeletonSvg = () => (
  <svg
    role="img"
    width="216"
    height="88"
    aria-labelledby="loading-aria"
    viewBox="0 0 216 88"
    preserveAspectRatio="none"
  >
    <title id="loading-aria">Carregando...</title>
    <rect
      x="0"
      y="0"
      width="100%"
      height="100%"
      clipPath="url(#clip-path)"
      style={{ fill: "url('#fill')" }}
    />
    <defs>
      <clipPath id="clip-path">
        <rect x="12" y="8" rx="4" ry="4" width="88" height="12" />
        <rect x="12" y="28" rx="4" ry="4" width="140" height="16" />
      </clipPath>
      <linearGradient id="fill">
        <stop
          offset="0.599964"
          stopColor={axiomTokens.color.others.skeleton}
          stopOpacity="1"
        >
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop
          offset="1.59996"
          stopColor={axiomTokens.color.others.skeleton}
          stopOpacity="1"
        >
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop
          offset="2.59996"
          stopColor={axiomTokens.color.others.skeleton}
          stopOpacity="1"
        >
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  </svg>
);

export default SkeletonSvg;
