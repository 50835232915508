/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable react/jsx-key */
import React from 'react';
import { useSelector } from 'react-redux';
import { Container, MaterialUICore } from '@iclinic/design-system';

import { useToggleBodyClass } from 'shared/hooks';
import { Header } from 'features/onlinePayment/components/config';
import {
  getSellerType,
  isSellerCreationDone,
} from 'features/onlinePayment/state/config/register/selectors';
import SellerRegisterProfile from './SellerRegisterProfile';
import SellerRegisterBank from './SellerRegisterBank';
import useStyles from '../../styles';
import HeaderStep from 'features/onlinePayment/components/HeaderStep';

const { useTheme } = MaterialUICore;

const SellerRegister = () => {
  const isSellerDone = useSelector(isSellerCreationDone);
  const sellerType = useSelector(getSellerType);

  const classes = useStyles();
  const theme = useTheme();

  const [step, component] = !isSellerDone
    ? [1, <SellerRegisterProfile />]
    : [2, <SellerRegisterBank />];

  useToggleBodyClass(classes.whiteBody, theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="lg">
      <HeaderStep type={sellerType} step={step} />
      <Header sellerType={sellerType!} step={step} />
      {component}
    </Container>
  );
};

export default SellerRegister;
