import { Box, Card, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = tokens;

export const PageWrapper = styled(Card)(({ theme }) => ({
  margin: `0 ${spacing.sm} ${spacing.md}`,
  padding: `${spacing.sm} ${spacing.sm} ${spacing.md}`,

  [theme.breakpoints.up('md')]: {
    margin: `0 ${spacing.md} ${spacing.md}`,
    padding: `${spacing.lg} ${spacing.xl}`,
  },
}));

export const Container = styled(Box)({
  maxWidth: 1480,
  margin: '0 auto',
});
