import { combineReducers } from 'redux';

import referrals, { ReferralsState } from './referrals';

export type MarketingReferralsState = {
  referrals: ReferralsState;
};

const marketingReferralsReducer = combineReducers<MarketingReferralsState>({
  referrals,
});

export default marketingReferralsReducer;
