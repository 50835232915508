import React from 'react';
import { Body, Button, Heading, MaterialIcons } from '@iclinic/design-system';

import history from 'routes/history';
import * as Styles from './styles';

const { ArrowBack } = MaterialIcons;

const AddExpenseHeader = () => {
  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Styles.Container>
      <Styles.HeaderTexts>
        <Heading variant="lg">Outras Despesas</Heading>
        <Body variant="sm">
          Configure as outras despesas de acordo com procedimento de convênio
          selecionados
        </Body>
      </Styles.HeaderTexts>

      <Button
        variant="text"
        color="primary"
        startIcon={<ArrowBack />}
        onClick={handleGoBack}
      >
        Voltar
      </Button>
    </Styles.Container>
  );
};

export default AddExpenseHeader;
