// External
import React from 'react';

// Internal
import { AppContent } from 'features/layout';
import { ListEmailFlowContainer } from 'features/marketing';

const EmailFlow = () => (
  <AppContent
    contentTitle=""
    hideSidebar
    hideFooter
  >
    <ListEmailFlowContainer />
  </AppContent>
);

export default EmailFlow;
