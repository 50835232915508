import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Body, MaterialIcons } from '@iclinic/design-system';

import {
  TranscriptContent,
  TranscriptTittle,
  IconButton,
} from './DrawerContent.styles';
import { actions } from '../../state';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import { TranscriptionItem } from '../../state/types';
import { getTransactionDrawerState } from '../../state/selector';

const { getMoreTranscription } = actions;

const { FileCopyRounded } = MaterialIcons;

export type TranscriptionWrapperProps = {
  transcription: TranscriptionItem[];
};

export const DrawerContent = ({ transcription }: TranscriptionWrapperProps) => {
  const { isEndOfTranscription } = useSelector(getTransactionDrawerState);
  const dispatch = useDispatch();
  const moreTranscriptionRef = useRef(null);
  const isRefInViewport = useIsInViewport(moreTranscriptionRef);

  const copyTranscriptContent = (transcript: string) => {
    navigator.clipboard.writeText(transcript) as unknown;
  };

  useEffect(() => {
    if (!isRefInViewport || isEndOfTranscription) {
      return;
    }
    dispatch(getMoreTranscription());
  }, [isRefInViewport, isEndOfTranscription, dispatch]);

  return (
    <>
      {transcription.map((transcriptionLine) => (
        <TranscriptContent key={transcriptionLine.delta}>
          <TranscriptTittle>
            <Body variant="xxs">
              {transcriptionLine.isPhysician ? 'Você' : 'Paciente'}
            </Body>
            <IconButton
              size="xs"
              tooltip="left"
              label="Copiar"
              onClick={() =>
                copyTranscriptContent(transcriptionLine.transcript)
              }
            >
              <FileCopyRounded />
            </IconButton>
          </TranscriptTittle>
          <Body variant="xs">{transcriptionLine.transcript}</Body>
        </TranscriptContent>
      ))}
      <div ref={moreTranscriptionRef} data-testid="end-of-transcription" />
    </>
  );
};
