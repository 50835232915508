// External
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Internal
import style from './List.scss';

export default function List({ list }) {
  return (
    <ul className={style.list}>
      {list.map(item => (
        <li key={item.slug} className={classnames({ [style.item]: true, [style.item__additional]: item.additional })}>
          {item.name}
        </li>
      ))}
    </ul>
  );
}

List.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
};
