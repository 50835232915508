import moment from 'moment';

import {
  AppointmentGuideFormValues,
  AppointmentGuideResultValues,
} from 'features/tissInvoicing/types/appointmentGuide';

const formatDate = (date: string) => moment(date).format('YYYY-MM-DD');

const getRestValuesTwo = (values?: AppointmentGuideResultValues) => ({
  insurance_ans_register: values?.insurance_ans_register ?? '',
  insurance_name: values?.insurance_name ?? '',
  insurance_id: values?.insurance_id,
  patient_id: values?.patient_id,
  observation: values?.observation ?? '',
  id: values?.id,
});

const getRestValuesOne = (values?: AppointmentGuideResultValues) => ({
  professional_executant_council_number:
    values?.professional_executant_council_number ?? '',
  professional_executant_council_state:
    values?.professional_executant_council_state ?? '',
  professional_executant_cbo: values?.professional_executant_cbo ?? '',
  accident_indication: values?.accident_indication ?? null,
  special_coverage: values?.special_coverage
    ? String(values.special_coverage)
    : '',
  attendance_regimen: values?.attendance_regimen ?? null,
  occupational_health: values?.occupational_health
    ? String(values.occupational_health)
    : '',
  request_date: values?.date_added ? formatDate(values.date_added) : '',
  appointment_type: values?.appointment_type
    ? String(values.appointment_type)
    : '',
  tuss_table_code: values?.tuss_procedure.tuss_table ?? '',
  tuss_procedure_id: values?.tuss_procedure_id
    ? String(values.tuss_procedure_id)
    : '',
  total_amount: values?.total_amount ? Number(values.total_amount) : null,
  ...getRestValuesTwo(values),
});

export const normalizeAppointmentGuideResponse = (
  values?: AppointmentGuideResultValues,
): AppointmentGuideFormValues => ({
  ...(values?.tuss_procedure.term && {
    tuss_procedure_name: `${values.tuss_procedure.code} - ${values.tuss_procedure.term}`,
  }),
  version_id: values?.version_id ?? '',
  guide_number: values?.guide_number ?? '',
  main_guide_number: values?.main_guide_number ?? '',
  patient_insurance_code: values?.patient_insurance_code ?? '',
  patient_insurance_expiry_date: values?.patient_insurance_expiry_date
    ? formatDate(values.patient_insurance_expiry_date)
    : '',
  newborn: values?.newborn ?? '',
  patient_civil_name: values?.patient_civil_name ?? '',
  patient_social_name: values?.patient_social_name ?? '',
  patient_sus_code: values?.patient_sus_code ?? '',
  hired_executant_code: values?.hired_executant_code ?? '',
  hired_executant_name: values?.hired_executant_name ?? '',
  hired_executant_cnes: values?.hired_executant_cnes ?? '',
  professional_executant_name: values?.professional_executant_name ?? '',
  professional_executant_council: values?.professional_executant_council ?? '',
  ...getRestValuesOne(values),
});

export const appointmentGuideRequest = (data: AppointmentGuideFormValues) => ({
  ...data,
  request_date: formatDate(data.request_date),
  patient_insurance_expiry_date: data?.patient_insurance_expiry_date
    ? formatDate(data.patient_insurance_expiry_date)
    : '',
});
