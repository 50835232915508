import { Profession, SignupSteps } from './state/types';

export const getNextStepFromJobByProfession = (
  isVariant: boolean,
  profession: Profession,
) => {
  if (profession === Profession.Doctor && !isVariant) {
    return SignupSteps.Specialty;
  }

  return SignupSteps.ProfessionCount;
};

export const getPreviousStepFromSpecialtyByProfession = (
  isVariant: boolean = false,
  profession: Profession = Profession.Doctor,
) => {
  if (
    profession === Profession.AnotherHealthProfessional ||
    (profession === Profession.Doctor && isVariant)
  ) {
    return SignupSteps.ProfessionCount;
  }

  return SignupSteps.Job;
};
