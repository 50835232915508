// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import { CheckBox } from 'ui/new/form';
import style from './CheckSelectUsers.scss';

const CheckSelectUsers = ({
  name,
  checked,
  onChange,
  disabled,
}) => (
  <>
    <div className={style.check}>
      <CheckBox
        classes={style}
        id="checkProduct"
        name={name}
        value={name}
        checked={checked}
        onChange={onChange}
        selected={checked}
        disabled={disabled}
      />
      <p className={style.check__label}>{name}</p>
    </div>
  </>
);

CheckSelectUsers.defaultProps = {
  name: '',
  onChange: null,
  checked: false,
  disabled: false,
};

CheckSelectUsers.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckSelectUsers;
