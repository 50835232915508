import React from 'react';

import { useResetAnimation } from 'shared/hooks';
import './BrokenCard.css';

interface Props extends React.SVGProps<SVGSVGElement> {
  title: string;
  resetTime?: number;
}

function BrokenCard({
  title,
  resetTime = 5000,
  ...svgProps
}: Props): JSX.Element {
  const { resetAnimation, handleAnimationEnd } = useResetAnimation(resetTime);

  if (resetAnimation) {
    return <svg {...svgProps} />;
  }

  return (
    <svg
      width="202"
      id="bkrwumrkuj2k1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 202 200.100000"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      {...svgProps}
    >
      <title>{title}</title>
      <g
        onAnimationEnd={handleAnimationEnd}
        id="bkrwumrkuj2k2_to"
        className="bkrwumrkuj2k2_to"
        transform="translate(142.809914,129.399998)"
      >
        <g
          id="bkrwumrkuj2k2"
          transform="translate(-142.809914,-109.399998)"
          opacity="0"
        >
          <path
            id="bkrwumrkuj2k3"
            d="M91.900000,171.100000C91.100000,171.300000,90.500000,170.600000,90.700000,169.800000L128.800000,48.600000C129.500000,46.300000,131.400000,44.600000,133.700000,43.900000L165.100000,35.500000C169.400000,34.400000,173.500000,36.600000,174.500000,40.200000L199.900000,134.900000C200.900000,138.500000,198.400000,142.500000,194.200000,143.700000L91.900000,171.100000Z"
            fill="rgb(248,249,250)"
            stroke="rgb(207,216,220)"
            strokeWidth="2.666667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="bkrwumrkuj2k4"
            d="M194.300000,112.600000L197.900000,126.100000C198.300000,127.700000,197.400000,129.300000,195.800000,129.800000L99.700000,155.500000C97.400000,156.100000,95.400000,154,96.100000,151.700000L101.100000,135.900000C101.400000,134.900000,102.200000,134.200000,103.200000,133.900000L190.700000,110.500000C192.200000,110,193.900000,111,194.300000,112.600000Z"
            fill="none"
            stroke="rgb(207,216,220)"
            strokeWidth="1.333333"
          />
          <path
            id="bkrwumrkuj2k5"
            d="M137.400000,107.100000L112.700000,113.700000C110.400000,114.300000,108.400000,112.200000,109.100000,109.900000L109.100000,109.900000C109.400000,108.900000,110.200000,108.200000,111.200000,107.900000L135.900000,101.300000C137.700000,100.800000,139.500000,101.800000,139.900000,103.300000C140.300000,104.900000,139.300000,106.600000,137.400000,107.100000Z"
            fill="none"
            stroke="rgb(207,216,220)"
            strokeWidth="1.333333"
          />
        </g>
      </g>
      <g id="bkrwumrkuj2k6_to" transform="translate(63.840075,81.000006)">
        <g
          id="bkrwumrkuj2k6"
          transform="translate(-63.840075,-101.000006)"
          opacity="0"
        >
          <path
            id="bkrwumrkuj2k7"
            d="M120.700000,34.300000C121.500000,34.100000,122.100000,34.800000,121.900000,35.600000L83.800000,156.800000C83.100000,159.100000,81.200000,160.800000,78.900000,161.500000L36.900000,172.700000C32.600000,173.800000,28.500000,171.600000,27.500000,168L2.200000,73.300000C1.200000,69.700000,3.700000,65.700000,7.900000,64.500000L120.700000,34.300000Z"
            fill="rgb(248,249,250)"
            stroke="rgb(207,216,220)"
            strokeWidth="2.666667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="bkrwumrkuj2k8"
            d="M24.600000,158.900000L21,145.400000C20.600000,143.800000,21.500000,142.200000,23.100000,141.700000L89.800000,123.800000C92.100000,123.200000,94.100000,125.300000,93.400000,127.600000L88.400000,143.400000C88.100000,144.400000,87.300000,145.100000,86.300000,145.400000L28.300000,161C26.700000,161.500000,25.100000,160.500000,24.600000,158.900000Z"
            fill="none"
            stroke="rgb(207,216,220)"
            strokeWidth="1.333333"
          />
          <path
            id="bkrwumrkuj2k9"
            d="M34.500000,115.900000L93.700000,100C95.300000,99.600000,96.900000,100.500000,97.400000,102.100000L97.400000,102.100000C97.800000,103.700000,96.900000,105.300000,95.300000,105.800000L36,121.700000C34.400000,122.100000,32.800000,121.200000,32.300000,119.600000L32.300000,119.600000C31.900000,118,32.900000,116.300000,34.500000,115.900000Z"
            fill="none"
            stroke="rgb(207,216,220)"
            strokeWidth="1.333333"
          />
          <path
            id="bkrwumrkuj2k10"
            d="M28.200000,75.100000L42.500000,71.300000C46.500000,70.200000,50.600000,72.600000,51.700000,76.600000L54.500000,87.200000C55.600000,91.200000,53.200000,95.300000,49.200000,96.400000L34.900000,100.200000C30.900000,101.300000,26.800000,98.900000,25.700000,94.900000L22.900000,84.300000C21.800000,80.300000,24.200000,76.200000,28.200000,75.100000Z"
            fill="none"
            stroke="rgb(207,216,220)"
            strokeWidth="1.333333"
          />
        </g>
      </g>
      <g
        id="bkrwumrkuj2k11_ts"
        transform="translate(87.049999,179.900002) scale(0,0)"
      >
        <line
          id="bkrwumrkuj2k11"
          x1="88.600000"
          y1="175.800000"
          x2="85.500000"
          y2="184"
          transform="translate(-87.049999,-179.900002)"
          opacity="0"
          fill="none"
          stroke="rgb(207,216,220)"
          strokeWidth="2.666667"
          strokeLinecap="round"
        />
      </g>
      <g
        id="bkrwumrkuj2k12_ts"
        transform="translate(76.618775,175.684524) scale(0,0)"
      >
        <line
          id="bkrwumrkuj2k12"
          x1="80.400000"
          y1="165.500000"
          x2="74.500000"
          y2="180.600000"
          transform="translate(-77.450001,-173.050003)"
          opacity="0"
          fill="none"
          stroke="rgb(207,216,220)"
          strokeWidth="2.666667"
          strokeLinecap="round"
        />
      </g>
      <g
        id="bkrwumrkuj2k13_ts"
        transform="translate(124.549999,25.750000) scale(0,0)"
      >
        <line
          id="bkrwumrkuj2k13"
          x1="125.700000"
          y1="21.400000"
          x2="123.400000"
          y2="30.100000"
          transform="translate(-124.549999,-25.750000)"
          opacity="0"
          fill="none"
          stroke="rgb(207,216,220)"
          strokeWidth="2.666667"
          strokeLinecap="round"
        />
      </g>
      <g
        id="bkrwumrkuj2k14_ts"
        transform="translate(132.149994,34.199999) scale(0,0)"
      >
        <line
          id="bkrwumrkuj2k14"
          x1="132.900000"
          y1="34.800000"
          x2="131.400000"
          y2="39.600000"
          transform="translate(-132.149994,-37.199999)"
          opacity="0"
          fill="none"
          stroke="rgb(207,216,220)"
          strokeWidth="2.666667"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

export default React.memo(BrokenCard);
