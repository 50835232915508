import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { GuideList } from '../containers/appointmentGuideList';
import { CreateAppointmentGuide } from '../containers/appointmentGuide';

const Root = () => (
  <Switch>
    <Route exact path="/tiss/guias-consulta/" component={GuideList} />
    <Route
      path="/tiss/guias-consulta/:id/"
      component={CreateAppointmentGuide}
    />
    <Route
      path="/tiss/guias-consulta/adicionar/"
      component={CreateAppointmentGuide}
    />
    <Redirect to="/404/" />
  </Switch>
);

export default Root;
