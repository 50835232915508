import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Body,
  Button,
  DialogActions,
  DialogContent,
  DialogHeader,
} from '@iclinic/design-system';
import ConfettiExplosion from 'confetti-explosion-react';

import { getRewardContragulations } from '../../state/selectors';
import * as actions from '../../state';
import { image } from 'shared/utils/urlGetter';
import * as S from './RewardsCongratulations.styles';
import { gotoAppRoute } from 'shared/utils/appRoutes';
import { localStorageVariable } from 'features/referrals/constants/rewardsContragulations';

const RewardsCongratulations = () => {
  const dispatch = useDispatch();
  const [isExploding, setIsExploding] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseCongratulations = () => {
    dispatch(actions.updateRewardCongratulations(false));

    setIsOpen(false);
    setIsExploding(false);
  };

  const hasRewards = useSelector(getRewardContragulations);

  if (hasRewards) {
    const { localStorage } = window;

    if (localStorage.getItem(localStorageVariable) !== 'false') {
      setIsOpen(true);
      setIsExploding(true);
      localStorage.setItem(localStorageVariable, 'false');
    }
  }

  return (
    <>
      {isExploding && (
        <S.ConfettiContainer>
          <ConfettiExplosion height={1600} />
        </S.ConfettiContainer>
      )}
      <S.CongratulationsModal
        open={isOpen}
        onClose={handleCloseCongratulations}
        maxWidth="xs"
      >
        <DialogHeader
          title="Parabéns! Você tem um prêmio para resgatar no nosso Programa de Indicações!"
          onClose={handleCloseCongratulations}
        />
        <DialogContent>
          <S.ImageWrapper>
            <img
              src={image('referrals.rewardContragulations')}
              alt="Resgate de prêmio"
            />
          </S.ImageWrapper>
          <Body variant="sm">
            Obrigado por acreditar no iClinic! Estaremos sempre evoluindo para
            melhorar ainda mais a sua jornada e a da pessoa que você indicou.
            Você tem um prêmio para resgatar, como gostaria de prosseguir?
          </Body>
        </DialogContent>
        <DialogActions>
          <>
            <Button color="transparent" onClick={handleCloseCongratulations}>
              Vou resgatar depois
            </Button>
            <Button
              onClick={() => {
                gotoAppRoute('/programa-de-indicacoes');
              }}
            >
              Quero resgatar agora
            </Button>
          </>
        </DialogActions>
      </S.CongratulationsModal>
    </>
  );
};

export default RewardsCongratulations;
