import { MaterialUICore } from '@iclinic/design-system';
import { HEADER_HEIGHT } from 'features/onlinePayment/components/checkout/Header.styles';

const { makeStyles } = MaterialUICore;

export default makeStyles(() => ({
  container: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    display: 'block',
    margin: '0 auto 2.5em auto',
  },
}));
