// External
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from '@material/react-material-icon';

// Internal
import Dropdown from 'ui/new/dropdown';
import { initFilterData } from 'state/campaign/constants';
// Style
import style from './SegmentCampaignAudience.scss';

const iconTitle = () => (
  <div className={style.segmentCampaignAudience__titleField}>
    <Icon icon="add" />
    <span className={style.segmentCampaignAudience__text}>
      Escolha o público-alvo
    </span>
  </div>
);

const SegmentCampaignAudience = ({ availableItems, fields }) => {
  const [isOpen, setToggleMenu] = useState(false);

  const selectItem = React.useCallback(
    (id) => {
      const data = initFilterData[id];
      fields.push({ filter: id, ...data });
    },
    [fields],
  );

  if (!availableItems.length) {
    return null;
  }

  return (
    <div className={style.segmentCampaignAudience__container}>
      <Dropdown
        title={iconTitle()}
        isOpenProp={isOpen}
        setToggleMenuProp={setToggleMenu}
      >
        {availableItems.map((item) => (
          <Dropdown.Item
            onClick={() => selectItem(item.id)}
            selected={item.selected}
            key={item.id}
            kind="exists"
            value={item.id}
          >
            {item.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  );
};

SegmentCampaignAudience.propTypes = {
  fields: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  availableItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool,
    }).isRequired,
  ).isRequired,
};

export default SegmentCampaignAudience;
