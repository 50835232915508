// External
import React, { useMemo, useEffect } from 'react';
import { connect, MapStateToProps } from 'react-redux';

// Internal
import { StoreState } from 'state/rootReducer';
import { getActiveCampaign } from 'state/campaignManager/actions';
import { getCampaigns, getResource } from 'state/campaignManager/selectors';
import { Filters, Image, Text } from 'state/campaignManager/types';
import SubscriptionBanner from 'ui/new/subscription-banner';

type StateProps = {
  campaignsImageDesktop: Image[];
  campaignsImageTablet: Image[];
  campaignsImageLarge: Image[];
  campaignsImageSmall: Image[];
  campaignsText: Text[];
};

type DispatchProps = {
  onGetActiveCampaign: () => void;
};

type Props = DispatchProps & StateProps;

const SubscriptionBannerContainer = ({
  campaignsImageDesktop,
  campaignsImageTablet,
  campaignsImageLarge,
  campaignsImageSmall,
  campaignsText,
  onGetActiveCampaign,
}: Props) => {
  useEffect(() => {
    onGetActiveCampaign();
  }, [onGetActiveCampaign]);

  return useMemo(() => {
    const checkCampaigns =
      campaignsImageDesktop?.length > 0 &&
      campaignsImageTablet?.length > 0 &&
      campaignsImageLarge?.length > 0 &&
      campaignsImageSmall?.length > 0;

    if (checkCampaigns) {
      const resourceValueText = getResource(campaignsText);
      return (
        <div>
          <SubscriptionBanner
            imageUrlDesktop={campaignsImageDesktop[0].image}
            imageUrlTablet={campaignsImageTablet[0].image}
            imageUrlLarge={campaignsImageLarge[0].image}
            imageUrlSmall={campaignsImageSmall[0].image}
            altText={resourceValueText}
          />
        </div>
      );
    }
    return null;
  }, [
    campaignsImageDesktop,
    campaignsImageLarge,
    campaignsImageSmall,
    campaignsImageTablet,
    campaignsText,
  ]);
};

const mapStateToProps: MapStateToProps<StateProps, {}, StoreState> = (
  state: StoreState,
) => ({
  campaignsImageDesktop: getCampaigns(
    'img-desktop-banner-subscription',
    Filters.images,
  )(state),
  campaignsImageTablet: getCampaigns(
    'img-tablet-banner-subscription',
    Filters.images,
  )(state),
  campaignsImageLarge: getCampaigns(
    'img-large-banner-subscription',
    Filters.images,
  )(state),
  campaignsImageSmall: getCampaigns(
    'img-small-banner-subscription',
    Filters.images,
  )(state),
  campaignsText: getCampaigns(
    'alt-text-banner-subscription',
    Filters.texts,
  )(state),
});

const dispatchProps: DispatchProps = {
  onGetActiveCampaign: getActiveCampaign,
};

export default connect(
  mapStateToProps,
  dispatchProps,
)(SubscriptionBannerContainer);
