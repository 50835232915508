import { takeLatest, call, select, put } from 'redux-saga/effects';
import { Response } from '@types';

import { handlerErrors } from 'state/shared/sagas';
import {
  fetchBillByEventIdRequest,
  createBillRequest,
  fetchCheckoutUrlRequest,
  updateBillRequest,
  fetchDefaultSellerFromPhysician,
} from 'features/onlinePayment/services/manage';
import {
  FetchBillByEventIdRawData,
  BillRawData,
  FetchCheckoutUrlRawData,
  FetchSellersRawData,
} from 'features/onlinePayment/services/manage/types';
import { SellerRawData } from 'features/onlinePayment/services/config/types';
import {
  fetchSellerInfoFailure,
  fetchSellerInfoSuccess,
  fetchBillFailure,
  fetchBillSuccess,
  createBillFailure,
  createBillSuccess,
  fetchCheckoutUrlFailure,
  fetchCheckoutUrlSuccess,
  fetchCheckoutUrl,
  updateBillFailure,
  toggleIsGenerating,
  toggleUpdatedBill,
  updateBillSuccess,
} from 'features/onlinePayment/state/manage/actions';
import {
  normalizeFetchSellerInfo,
  normalizeFetchBill,
  normalizeBill,
} from './normalizers';
import {
  getEventId,
  getBill,
  getEventPatientId,
  getEventPatientName,
  getSeller,
  isApprovedSeller,
  getPhysicianId,
  getPhysicianHasSellerApproved,
} from './selectors';
import {
  FETCH_SELLER_INFO_ACTION,
  FETCH_BILL_ACTION,
  CREATE_BILL_ACTION,
  FETCH_CHECKOUT_URL_ACTION,
  UPDATE_BILL,
  CREATE_BILL_ON_SCHEDULE_ACTION,
} from './constants';
import {
  FetchBill,
  UpdateBill,
  Bill,
  Seller,
  CreateBill,
  CreateBillOnSchedule,
} from './types';

export function* workerFetchSellerInfo() {
  try {
    const physicianId: number = yield select(getPhysicianId);
    const hasSellerApproved: boolean = yield select(
      getPhysicianHasSellerApproved,
    );
    if (!hasSellerApproved) return;

    const { data: sellerInfos }: Response<FetchSellersRawData> = yield call(
      fetchDefaultSellerFromPhysician,
      physicianId,
    );

    const seller = normalizeFetchSellerInfo(sellerInfos);
    yield put(fetchSellerInfoSuccess(seller));
  } catch (error) {
    yield call(handlerErrors, error.errors, fetchSellerInfoFailure);
  }
}

export function* workerFetchBill({ params: { eventId } }: FetchBill) {
  try {
    if (!eventId) return;
    const isApproved: boolean = yield select(isApprovedSeller);
    if (!isApproved) return;

    const { data: billInfos }: Response<FetchBillByEventIdRawData> = yield call(
      fetchBillByEventIdRequest,
      eventId,
    );
    const bill = normalizeFetchBill(billInfos);

    yield put(fetchBillSuccess({ bill }));

    if (bill) yield put(fetchCheckoutUrl());
  } catch (error) {
    yield call(handlerErrors, error.errors, fetchBillFailure);
  }
}

export function* workerCreateBill({ payload: { amountCharged } }: CreateBill) {
  try {
    const eventId: number | null = yield select(getEventId);
    const seller: Seller | null = yield select(getSeller);
    const patientId: number = yield select(getEventPatientId);
    const patientName: string = yield select(getEventPatientName);

    if (!eventId || !seller || !amountCharged) return;

    yield put(toggleIsGenerating({ isGenerating: true }));

    const { data: rawBill }: Response<BillRawData> = yield call(
      createBillRequest,
      {
        amountCharged,
        eventId,
        seller: seller.id,
        patientId,
        patientName,
      },
    );
    const bill = normalizeBill(rawBill);

    yield put(createBillSuccess({ bill }));
    yield put(fetchCheckoutUrl());
  } catch (error) {
    yield put(toggleIsGenerating({ isGenerating: false }));
    yield call(handlerErrors, error.errors, createBillFailure);
  }
}

export function* workerCreateBillOnSchedule({
  payload: { amountCharged, eventId, patientId, patientName },
}: CreateBillOnSchedule) {
  try {
    yield call(workerFetchSellerInfo);

    const seller: SellerRawData = yield select(getSeller);
    const { data: rawBill }: Response<BillRawData> = yield call(
      createBillRequest,
      {
        amountCharged,
        eventId,
        seller: seller.id,
        patientId,
        patientName,
      },
    );
    const bill = normalizeBill(rawBill);

    yield put(createBillSuccess({ bill }));
    yield put(fetchCheckoutUrl());
  } catch (error) {
    yield call(handlerErrors, error.errors, createBillFailure);
  }
}

export function* workerFetchCheckoutUrl() {
  try {
    const bill: Bill | null = yield select(getBill);

    if (!bill) return;

    const { data }: Response<FetchCheckoutUrlRawData> = yield call(
      fetchCheckoutUrlRequest,
      bill.id,
    );
    const { checkout_url: url } = data;

    yield put(fetchCheckoutUrlSuccess({ url }));
    yield put(toggleIsGenerating({ isGenerating: false }));
  } catch (error) {
    yield put(toggleIsGenerating({ isGenerating: false }));
    yield call(handlerErrors, error.errors, fetchCheckoutUrlFailure);
  }
}

export function* workerUpdateBill({ payload: { amountCharged } }: UpdateBill) {
  try {
    const bill: Bill | null = yield select(getBill);

    if (!bill) return;

    yield call(updateBillRequest, {
      billId: bill.id,
      amountCharged,
    });

    yield put(updateBillSuccess({ amountCharged }));
    yield put(toggleUpdatedBill({ updatedBill: true }));
  } catch (error) {
    yield put(toggleUpdatedBill({ updatedBill: false }));
    yield call(handlerErrors, error.errors, updateBillFailure);
  }
}

export default function* manageSagas() {
  yield takeLatest(FETCH_SELLER_INFO_ACTION, workerFetchSellerInfo);
  yield takeLatest(FETCH_BILL_ACTION, workerFetchBill);
  yield takeLatest(CREATE_BILL_ACTION, workerCreateBill);
  yield takeLatest(CREATE_BILL_ON_SCHEDULE_ACTION, workerCreateBillOnSchedule);
  yield takeLatest(FETCH_CHECKOUT_URL_ACTION, workerFetchCheckoutUrl);
  yield takeLatest(UPDATE_BILL, workerUpdateBill);
}
