import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[900],
    fontSize: '1.125rem',
  },
}));

export default useStyles;
