import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RequestStatus } from 'shared/constants/State';
import {
  Box,
  Container,
  FloatingActionButton,
  Typography,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';
import {
  sendAuditReport,
  setExportDrawer,
  setNewExport,
} from '../../state/exportHome';
import {
  getAuditReportStatus,
  getExportDrawer,
  getNewExport,
} from 'features/exportClinicData/state/exportHome/selectors';
import ExportDownload from 'features/exportClinicData/components/exportDownload';
import ExportRequest from 'features/exportClinicData/components/exportRequest';
import ExportListTable from 'features/exportClinicData/components/exportListTable';
import useStyles from './index.styles';

const { CloudDownload } = MaterialIcons;
const { Drawer } = MaterialUICore;

export default function ExportClinicDataManager(): JSX.Element {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.userInfo);
  const auditStatus = useSelector(getAuditReportStatus);
  const auditData = {
    application: 'api',
    event_type: 'view-export-page',
    target: userInfo.profileId,
    details: {
      subscription_id: userInfo.userData.physicianId,
      clinic_id: userInfo.clinicId,
    },
  };

  useEffect(() => {
    if (auditStatus === RequestStatus.Pending) {
      dispatch(sendAuditReport(auditData));
    }
  }, [auditStatus, dispatch]);

  const exportDrawer = useSelector(getExportDrawer);
  const newExport = useSelector(getNewExport);

  const openDrawer = () => {
    dispatch(setNewExport(true));
    dispatch(setExportDrawer(true));
  };

  const classes = useStyles();

  return (
    <>
      <Container maxWidth="lg">
        <Typography variant="h3" gutterBottom>
          Exportar dados
        </Typography>
        <Box mt={4} mb={4}>
          <FloatingActionButton
            onClick={() => openDrawer()}
            variant="extended"
            color="primary"
          >
            <CloudDownload className={classes.buttonIcon} />
            Exportar dados
          </FloatingActionButton>
        </Box>
        <ExportListTable />
      </Container>
      <Drawer
        anchor="right"
        open={exportDrawer}
        onClose={() => dispatch(setExportDrawer(false))}
      >
        {newExport ? <ExportRequest /> : <ExportDownload />}
      </Drawer>
    </>
  );
}
