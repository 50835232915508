import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialUICore, MaterialIcons } from '@iclinic/design-system';

import { getCurrentEventAndPatientId } from 'state/records/selectors';
import {
  getLatestDiagnoses as getLatestDiagnosesSelector,
  getLatestDiagnosesError,
} from './state/selectors';
import { actions } from './state';
import * as S from './styles';
import { ErrorBoundary } from '../errorBoundary';
import {
  noDiagnosesText,
  getDiagnosesErrorText,
  supportLinkRedirect,
} from './contants';

const { Collapse, useMediaQuery, useTheme } = MaterialUICore;
const { ExpandMoreRounded, ExpandLessRounded } = MaterialIcons;

const { getLatestDiagnoses } = actions;

export const LatestDiagnoses = () => {
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);
  const { patientId } = useSelector(getCurrentEventAndPatientId);
  const latestDiagnosesError = useSelector(getLatestDiagnosesError);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));
  const diagnosesList = useSelector(getLatestDiagnosesSelector);

  const onFetchLatestDiagnoses = useCallback(() => {
    dispatch(getLatestDiagnoses(patientId));
  }, [dispatch, patientId]);

  useEffect(() => {
    if (!patientId) {
      return;
    }
    onFetchLatestDiagnoses();
  }, [onFetchLatestDiagnoses, patientId]);

  const expandDiagnoses = () => {
    setCollapsed(!collapsed);
  };

  return (
    <ErrorBoundary
      isError={latestDiagnosesError}
      buttonText="Tentar novamente"
      message={getDiagnosesErrorText}
      isButtonRetryVisible
      onRetry={onFetchLatestDiagnoses}
    >
      <S.CardContent>
        <S.CardLabelButton>
          <S.LatestDiagnosesText variant="overline">
            últimos diagnósticos
          </S.LatestDiagnosesText>
          <S.ExpandIconButton
            label=""
            tooltip="bottom"
            size={isMd ? 'xl' : 'md'}
            aria-label={
              collapsed ? 'Recolher diagnósticos' : 'Expandir diagnósticos'
            }
            onClick={expandDiagnoses}
          >
            {collapsed ? <ExpandLessRounded /> : <ExpandMoreRounded />}
          </S.ExpandIconButton>
        </S.CardLabelButton>
        <S.ChipOutContent>
          <Collapse in={collapsed}>
            {diagnosesList.length === 0 ? (
              <S.TextAndLink>
                <S.NoDiagnosesText variant="xxs">
                  {noDiagnosesText}
                </S.NoDiagnosesText>
                <S.RedirectButton variant="text" size="small" color="primary">
                  <S.LinkRedirect href={supportLinkRedirect} target="_blank">
                    Saiba mais
                  </S.LinkRedirect>
                </S.RedirectButton>
              </S.TextAndLink>
            ) : (
              diagnosesList.map((item, index) => (
                <S.ChipInContent
                  key={`last-diagnose-key-${index.toString()}`}
                  size="small"
                  label={item}
                  variant="outlined"
                  severity="default"
                />
              ))
            )}
          </Collapse>
        </S.ChipOutContent>
      </S.CardContent>
    </ErrorBoundary>
  );
};
