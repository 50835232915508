import * as Yup from 'yup';

import { onlyNumbers } from 'shared/utils/formatters';
import { isValidCPF } from 'shared/utils/validation';
import { CBO } from 'features/tuss/cbo/types';

const required = 'Campo obrigatório';

export const professionalValidationSchema = Yup.object().shape({
  cpf: Yup.string()
    .required(required)
    .test('isValidCPF', 'CPF inválido', (value) => {
      return value && isValidCPF(onlyNumbers(value));
    }),
  rqe: Yup.string(),
  council_name: Yup.string().required(required),
  council_region: Yup.string().required(required),
  council_number: Yup.string().required(required),
  cbo: Yup.string().required(required),
});

export const clinicValidationSchema = Yup.object().shape({
  zip_code: Yup.string()
    .required(required)
    .matches(/^\d{5}-\d{3}$/, 'Insira um CEP válido'),
  address: Yup.string().required(required),
  number: Yup.string().required(required),
  complement: Yup.string(),
  neighborhood: Yup.string().required(required),
  city: Yup.string().required(required),
  state: Yup.string().required(required),
});

export const createProfessionalValidation = (cboList: CBO[]) => {
  return professionalValidationSchema.concat(
    Yup.object().shape({
      cbo: Yup.string()
        .required(required)
        .test('check-cbo', 'Escolha uma especialidade da lista', (value) => {
          return !!cboList.find((item) => item.term === value);
        }),
    }),
  );
};
