import { image } from 'shared/utils/urlGetter';
import {
  MaterialUICore,
  tokens,
  Heading as HeadingDS,
  Body as BodyDS,
  Grid as GridDS,
  Paper as PaperDS,
  theme,
} from '@iclinic/design-system';
import {
  Fab as FabComponent,
  FloatingWrapper as FloatingWrapperComponent,
} from 'features/onlinePayment/components/config/Fab';

const { styled, Table: TableDS, TableHead: TableHeadDS } = MaterialUICore;

type GridProps = {
  alignself?: string;
  order?: number;
};

type SwitchInnerTextProps = {
  plan: string;
  selectedplan: string;
};

type SwitchThumbProps = {
  plan: string;
};

type FabProps = {
  visible: boolean;
};

export const Grid = styled(GridDS)(({ alignself, order }: GridProps) => ({
  alignSelf: alignself,
  order,
  [theme.breakpoints.up('md')]: {
    order: 0,
  },
}));

export const Paper = styled(PaperDS)(() => ({
  overflow: 'hidden',
  padding: '0',
}));

export const ImgWrapper = styled('div')(() => ({
  display: 'inline-block',

  '& img': {
    display: 'inline-block',
  },
}));

export const MainTitle = styled(HeadingDS)(() => ({
  fontWeight: 'normal',
  letterSpacing: 'inherit',

  '& strong': {
    fontWeight: 'bold',
  },
}));

export const SectionTitle = styled(HeadingDS)(() => ({
  marginBottom: tokens.spacing.md,
}));

export const PlayVideoButton = styled('button')(() => ({
  background: 'transparent',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  padding: 0,
}));

export const ArrowWrapper = styled('div')(() => ({
  display: 'none',

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const Arrow1 = styled(ArrowWrapper)(() => ({
  background: `url(${image('onlinePayment.arrow_1')}) no-repeat center center`,
  height: 186,
  left: 240,
  marginBottom: tokens.spacing.xl,
  position: 'relative',
  width: 135,
}));

export const Arrow2 = styled(ArrowWrapper)(() => ({
  background: `url(${image('onlinePayment.arrow_2')}) no-repeat center center`,
  bottom: 70,
  height: 100,
  position: 'absolute',
  right: -50,
  width: 100,
}));

export const Arrow3 = styled(ArrowWrapper)(() => ({
  background: `url(${image('onlinePayment.arrow_3')}) no-repeat center center`,
  bottom: 70,
  height: 103,
  left: -90,
  position: 'absolute',
  width: 103,
}));

export const CarouselWrapper = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
  background: `url(${image('onlinePayment.bgScreen')}) no-repeat center center`,
  height: 346,
  justifyContent: 'center',
  position: 'relative',
  width: '100%',

  [theme.breakpoints.up('md')]: {
    backgroundSize: 'cover',
    height: 324,
  },
}));

export const Shape = styled('span')(() => ({
  background: tokens.color.primary.light,
  display: 'none',
  opacity: 0.2,
  position: 'absolute',

  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const Rect1 = styled(Shape)(() => ({
  height: 72,
  left: 40,
  top: -35,
  transform: 'rotate(45deg)',
  width: 72,
  zIndex: -1,
}));

export const Rect2 = styled(Shape)(() => ({
  background: tokens.color.primary.dark,
  height: 35,
  right: 40,
  top: -24,
  transform: 'rotate(28deg)',
  width: 35,
}));

export const Bol1 = styled(Shape)(() => ({
  borderRadius: '50%',
  bottom: -23,
  height: 47,
  right: 32,
  width: 47,
}));

export const Bol2 = styled(Shape)(() => ({
  borderRadius: '50%',
  bottom: -28,
  height: 74,
  left: -36,
  width: 74,
  zIndex: -1,
}));

export const TestimonialText = styled('div')(() => ({
  paddingLeft: tokens.spacing.md,
  paddingBottom: tokens.spacing.base,
  position: 'relative',

  '& i': {
    background: tokens.color.primary.light,
    borderRadius: tokens.border.radius.sm,
    height: '100%',
    left: 0,
    position: 'absolute',
    width: tokens.spacing.base,
  },
}));

export const TestimonialAuthor = styled('div')(() => ({
  marginTop: tokens.spacing.sm,

  '& p': {
    color: tokens.color.neutral[999],
  },
}));

export const CreditCardFlagWrapper = styled(ImgWrapper)(() => ({
  margin: `0 ${tokens.spacing.md}`,
}));

export const SwitchLabel = styled(BodyDS)(
  ({ plan, selectedplan }: SwitchInnerTextProps) => ({
    color:
      plan === selectedplan
        ? tokens.color.primary.main
        : tokens.color.neutral[100],
    fontWeight: plan === selectedplan ? 'bold' : 'normal',
    margin: ` ${tokens.spacing.sm} ${tokens.spacing.md}`,
  }),
);

export const SwitchWrapper = styled('div')(() => ({
  height: 60,
  position: 'relative',
  width: 320,
}));

export const SwitchTrack = styled('div')(() => ({
  background: tokens.color.neutral[50],
  alignItems: 'center',
  borderRadius: 50,
  display: 'flex',
  height: 60,
  justifyContent: 'center',
  width: 320,
}));

export const SwitchThumb = styled('div')(({ plan }: SwitchThumbProps) => ({
  background: tokens.color.primary.main,
  borderRadius: 50,
  height: 56,
  left: 2,
  position: 'absolute',
  top: 2,
  transform: plan === 'regular' ? 'translateX(0)' : 'translateX(158px)',
  width: 158,
}));

export const SwitchTextWrapper = styled(BodyDS)(() => ({
  width: '50%',
  zIndex: 2,
}));

export const SwitchInnerText = styled(SwitchTextWrapper)(
  ({ plan, selectedplan }: SwitchInnerTextProps) => ({
    color:
      plan === selectedplan
        ? tokens.color.neutral[0]
        : tokens.color.neutral[300],
    fontWeight: plan === selectedplan ? 'bold' : 'normal',
  }),
);

export const SwitchButton = styled('button')(() => ({
  background: 'none',
  border: 'none',
  cursor: 'pointer',
  height: '100%',
  left: 0,
  outline: 'none',
  position: 'absolute',
  top: 0,
  width: '100%',
  zIndex: 3,
}));

export const Table = styled(TableDS)(() => ({
  minWidth: '1280px',
}));

export const TableHead = styled(TableHeadDS)(() => ({
  '& th': {
    background: tokens.color.primary.main,
    color: tokens.color.neutral[0],
  },
}));

export const ImgSlide = styled(ImgWrapper)(() => ({
  display: 'block',
  width: 345,
}));

export const Fab = styled(FabComponent)(({ visible }: FabProps) => ({
  bottom: visible ? '' : '-50px',
  transition: 'bottom 0.5s ease',
}));

export const FloatingWrapper = styled(FloatingWrapperComponent)(() => ({}));
