import React from 'react';

import { Button } from '@iclinic/design-system';
import { LocalPhoneIcon, ButtonContainer } from './styles';
import { buttonsTitle } from 'features/video-conference/constants';
import { useConferenceFinish } from 'features/video-conference/hooks/useConferenceFinish';
import { useAnnotationContext } from 'features/video-conference/state/annotationContext';
import { formatNotes } from 'features/video-conference/utils';

export default function EndCallButton() {
  const { finishConference } = useConferenceFinish();
  const { patientNotes, clinicNotes } = useAnnotationContext();
  const sendAnnotation = formatNotes(patientNotes).length > 0;

  const disconnectRoom = () => {
    const body = {
      patientNotes: formatNotes(patientNotes),
      physicianNotes: formatNotes(clinicNotes),
      sendAnnotation,
      sendVideoRecording: false,
      extraAnnotation: '',
    };

    finishConference(body);
  };

  return (
    <ButtonContainer onClick={() => disconnectRoom()}>
      <Button title={buttonsTitle.ENDCALL} placeholder={buttonsTitle.ENDCALL}>
        <LocalPhoneIcon />
      </Button>
    </ButtonContainer>
  );
}
