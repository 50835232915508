import { MaterialIcons } from '@iclinic/design-system';
import { Form, Formik, FormikHelpers, useFormikContext } from 'formik';
import { mapValues, omit } from 'lodash';
import React, { Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  addNewPaymentMethod,
  setCashMock,
} from 'features/newFinance/state/payment';
import { getTotalValuesSelector } from 'features/newFinance/state/selectors';
import DefaultForm from './DefaulForm';
import {
  CustomModal,
  CustomModalContainer,
  CustomModalHeader,
  ModalContainer,
} from './styles';
import {
  PaymentFormProps,
  initialValues,
  validationSchema,
} from './utils/FormValidate';
import { cashOptionsMock } from 'features/newFinance/mocks';

const PixBtBs = lazy(() => import('./PixBtBs'));
const CreditCard = lazy(() => import('./CreditCard'));
const BankCheck = lazy(() => import('./BankCheck'));
const Cash = lazy(() => import('./Cash'));
const DebitCard = lazy(() => import('./DebitCard'));
const BankSlip = lazy(() => import('./BankSlip'));

const PossibleContents: {
  [key: string]: React.LazyExoticComponent<() => JSX.Element>;
} = {
  px: PixBtBs,
  bs: BankSlip,
  bt: PixBtBs,
  cc: CreditCard,
  bc: BankCheck,
  ca: Cash,
  dc: DebitCard,
};

const { Close } = MaterialIcons;

const ContentVariant = () => {
  const { values } = useFormikContext<PaymentFormProps>();

  if (!Object.keys(PossibleContents).includes(values.paymentType)) {
    return (
      <div>
        <p>Forma de pagamento indisponível.</p>
      </div>
    );
  }

  const Variant = PossibleContents[values.paymentType];

  return (
    <Suspense fallback={<div>loading...</div>}>
      <Variant />
    </Suspense>
  );
};

type PaymentsModalProps = {
  handleClose: () => void;
  show: boolean;
};

const PaymentsModal = ({ handleClose, show }: PaymentsModalProps) => {
  const { total, remainingAmount } = useSelector(getTotalValuesSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setCashMock({
        cashOptions: cashOptionsMock,
        defaultCash: cashOptionsMock[0].value,
      }),
    );
  }, [dispatch]);

  const handleSubmit = (
    values: PaymentFormProps,
    formikBag: FormikHelpers<PaymentFormProps>,
  ): void => {
    formikBag
      .validateForm()
      .then((results) => {
        if (
          !Object.keys(results).length &&
          Object.keys(PossibleContents).includes(values.paymentType)
        ) {
          const payload = {
            ...values,
            date: new Date(),
          };

          if (!payload.value) payload.value = remainingAmount ?? total;

          dispatch(addNewPaymentMethod(payload));
          handleClose();
        }
      })
      .catch((err) => {
        throw new Error(`Erro ao validar formulário - ${String(err)}`);
      });
  };

  const initialValue = remainingAmount ?? total;

  return (
    <CustomModal open={show}>
      <Formik
        initialValues={{
          ...initialValues,
          total,
          value: initialValue,
        }}
        validationSchema={validationSchema(initialValue)}
        onSubmit={handleSubmit}
        validate={(value) => {
          const errors = omit(initialValues, Object.keys(value));
          return mapValues(errors, () => 'Campo obrigatório');
        }}
      >
        <CustomModalContainer>
          <Form>
            <ModalContainer>
              <CustomModalHeader>
                <h2>Escolher formas de pagamento</h2>
                <button type="button" onClick={handleClose}>
                  <Close />
                </button>
              </CustomModalHeader>

              <DefaultForm handleClose={handleClose}>
                <ContentVariant />
              </DefaultForm>
            </ModalContainer>
          </Form>
        </CustomModalContainer>
      </Formik>
    </CustomModal>
  );
};

export default PaymentsModal;
