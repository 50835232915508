import {
  Button,
  Loader,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';
import { FormikErrors, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PrintSADTModal from 'features/tissInvoicing/containers/components/ContainerFooterButtons/PrintModal';
import { actions } from 'features/tissInvoicing/state/sadt';
import {
  getLoadingState,
  getPrintState,
} from 'features/tissInvoicing/state/sadt/selectors';
import { GuideSADTForm } from 'features/tissInvoicing/types';
import history from 'routes/history';
import * as Styles from './styles';
import { validateSADTForm } from '../utils/validateSADTForm';
import { validateAppointmentGuide } from '../utils/validateAppointmentGuide';

const { useMediaQuery, useTheme } = MaterialUICore;
const { ArrowBack, Save, Print } = MaterialIcons;

type THandleSubmit = React.MouseEvent<HTMLButtonElement> & { print: boolean };

interface ContainerFooterButtonsProps {
  guideTypeValidator: 'sadt' | 'appointment';
  modalTitle: string;
  sectionRef: React.RefObject<Array<HTMLButtonElement | null>>;
}

type ValidateFnProps<GuideFormProps> = (
  errors: FormikErrors<GuideFormProps>,
  sectionRef: React.RefObject<(HTMLButtonElement | null)[]>,
  callback?: (args: string) => void,
) => void;

const validateFunctions: { [key: string]: ValidateFnProps<GuideSADTForm> } = {
  validateSadt: (errors, sectionRef, callback) =>
    validateSADTForm(errors, sectionRef, (args: string) => callback?.(args)),

  validateAppointmentGuide: () => validateAppointmentGuide(),
};
enum ValidatorKeys {
  sadt = 'validateSadt',
  appointment = 'validateAppointmentGuide',
}

export default function ContainerFooterButtons({
  guideTypeValidator = 'sadt',
  modalTitle,
  sectionRef,
}: ContainerFooterButtonsProps): JSX.Element {
  const classes = Styles.useStyles();
  const dispatch = useDispatch();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const loadingStatus = useSelector(getLoadingState);
  const { pdfURL, openModal } = useSelector(getPrintState);

  const { setFieldValue, validateForm } = useFormikContext<GuideSADTForm>();

  const closeModal = () => dispatch(actions.setPrintModalOpen(false));

  const notifySection = (section: string) => {
    dispatch(actions.setExpensesErrors(section === 'expensesData'));
  };

  const handleSubmit = (event: THandleSubmit): void => {
    const { print } = event;

    setFieldValue('print', print, false);
    validateForm()
      .then((errors) => {
        validateFunctions[ValidatorKeys[guideTypeValidator]](
          errors,
          sectionRef,
          (args: string) => notifySection(args),
        );
      })
      .catch((err) => {
        throw new Error(`Erro ao validar formulário - ${String(err)}`);
      });
  };

  const loading = useMemo(
    () => loadingStatus.print || loadingStatus.save,
    [loadingStatus.print, loadingStatus.save],
  );

  return (
    <Styles.Container>
      <Button
        onClick={() => history.goBack()}
        color="transparent"
        classes={{ root: classes.cancelButton }}
        startIcon={<ArrowBack />}
        disabled={loading}
      >
        Cancelar
      </Button>

      <Styles.Actions>
        <Button
          color={isSmall ? 'primary' : 'secondary'}
          type="submit"
          startIcon={loadingStatus.save ? <Loader size={15} /> : <Save />}
          disabled={loading}
          onClick={(e) => handleSubmit({ ...e, print: false })}
        >
          Salvar
        </Button>

        {!isSmall && (
          <Button
            type="submit"
            startIcon={loadingStatus.print ? <Loader size={15} /> : <Print />}
            disabled={loading}
            onClick={(e) => handleSubmit({ ...e, print: true })}
          >
            Imprimir
          </Button>
        )}
      </Styles.Actions>

      <PrintSADTModal
        isOpen={openModal}
        handleClose={closeModal}
        url={pdfURL}
        title={modalTitle}
      />
    </Styles.Container>
  );
}
