import {
  Box,
  Heading as DSHeading,
  Body as DSBody,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing, line, border } = tokens;

export const BannerWrapper = styled(Box)(({ theme }) => ({
  maxWidth: 1088,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.lg,
  paddingBottom: spacing.md,
  borderBottom: `${border.width.hairline} solid ${color.neutral[50]}`,

  [theme.breakpoints.up('md')]: {
    paddingBottom: spacing.lg,
    flexDirection: 'row',
  },
}));

export const ImageWrapper = styled(Box)({
  width: '100%',
  maxWidth: 420,
  '& > img': {
    margin: '0 auto',
  },
});

export const Heading = styled(DSHeading)({
  color: color.neutral[700],
  maxWidth: 560,
});

export const Subheading = styled(DSHeading)({
  margin: `${spacing.xs} 0 ${spacing.sm}`,
  color: color.primary.main,
});

export const Body = styled(DSBody)(({ theme }) => ({
  lineHeight: line.height.md,
  letterSpacing: '0.44px',

  [theme.breakpoints.up('sm')]: {
    lineHeight: line.height.lg,
  },
}));

export const List = styled('ul')({
  marginLeft: spacing.md,
});
