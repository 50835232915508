// External
import * as React from 'react';
import classnames from 'classnames/bind';

// Internal
import Header from './Header';
import style from './style/index.scss';
import { STATUS } from './constants';
import useCollapseElement from './useCollapseElement';

const classes = classnames.bind(style);

type ActionableCardProps = {
  collapsed?: boolean;
  status?: STATUS;
  children: React.ReactNode;
  onHeaderClick: () => void;
  title: string | React.ReactNode;
};

export default function ActionableCard(
  {
    collapsed = false,
    status = STATUS.Regular,
    children,
    onHeaderClick,
    title,
  }: ActionableCardProps,
):JSX.Element {
  const [cardRef, isAnimating] = useCollapseElement(collapsed, style.headerHeight);
  return (
    <div ref={cardRef} className={classes('actionableCard', { collapsed })}>
      <div>
        <Header onClick={isAnimating ? undefined : onHeaderClick} status={status}>
          {title}
        </Header>
        {children}
      </div>
    </div>
  );
}
