import { theme as themeUI, MaterialUICore } from '@iclinic/design-system';

const { makeStyles, createStyles, createMuiTheme } = MaterialUICore;

type Props = {
  isVideoEnabled: boolean;
  isLocal: boolean;
  isShowingNetworkQuality: boolean;
  left: null | 'unset' | 'calc(50% - 130px)' | 'calc(50% - 205px)';
};

const theme = createMuiTheme(themeUI);
export default makeStyles<{}, Props>(() =>
  createStyles({
    root: {
      left: '8px',
      bottom: '8px',
      position: 'absolute',
      [theme.breakpoints.up(320)]: {
        bottom: ({ isVideoEnabled, isLocal }) =>
          isVideoEnabled ? !isLocal && '24px' : 'calc(50% - 90px)',
        left: ({ left }) => left,
      },
      '& .MuiChip-icon': {
        marginRight: ({ isLocal }) => isLocal,
      },
      '& .MuiChip-label': {
        [theme.breakpoints.up(320)]: {
          display: ({ isLocal, isShowingNetworkQuality, isVideoEnabled }) =>
            isLocal || (isShowingNetworkQuality && isVideoEnabled)
              ? 'none'
              : 'flex',
        },
      },
    },
  }),
);
