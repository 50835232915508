import React from 'react';

import { Button, MaterialUICore } from '@iclinic/design-system';

const { CircularProgress } = MaterialUICore;

interface ButtonWithLoadingProps {
  children: React.ReactNode;
  isLoading?: boolean;
  type?: 'submit' | 'button' | 'reset';
  size?: 'small' | 'medium' | 'large';
  onClick?: () => void;
}

const ButtonWithLoading = ({
  children,
  isLoading,
  type = 'button',
  size = 'medium',
  onClick,
}: ButtonWithLoadingProps) => (
  <Button type={type} size={size} disabled={isLoading} onClick={onClick}>
    {isLoading ? (
      <CircularProgress data-testid="loading-button" size={14} />
    ) : (
      children
    )}
  </Button>
);

export default ButtonWithLoading;
