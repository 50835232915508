// Internal
import {
  SUCCESS, FAILURE, OPEN, CLOSE, CHANGE, SUBMIT,
} from 'shared/constants/actions';
import appTypes from 'shared/utils/actions';

const subscription = appTypes.defineAction('subscription');

export const SET_SUCCESS_URL = subscription.defineAction('EXTRA_PRODUCT', []);
export const EXTRA_PRODUCT = subscription.defineAction('EXTRA_PRODUCT', [CHANGE]);
export const SET_SELECTED_PRODUCT = subscription.defineAction('SET_SELECTED_PRODUCT', []);
export const TAB_RECURRENCE = subscription.defineAction('TAB_RECURRENCE', [CHANGE]);
export const MODAL_CREDIT_CARD = subscription.defineAction('MODAL_CREDIT_CARD', [OPEN, CLOSE]);
export const FETCH_SUBSCRIBE = subscription.defineAction('FETCH_SUBSCRIBE', [SUCCESS, FAILURE]);
export const FETCH_GET_PRODUCTS = subscription.defineAction('FETCH_GET_PRODUCTS', [SUCCESS, FAILURE]);
export const FETCH_GET_PRODUCT = subscription.defineAction('FETCH_GET_PRODUCT', [SUCCESS, FAILURE]);

export const SUBSCRIBE = subscription.defineAction('SUBSCRIBE', [
  SUBMIT,
  SUCCESS,
  FAILURE,
]);
