// External
import React from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import { required } from 'shared/utils/formValidation';

// Internal
import { Column } from 'ui/new/flex';
import { CheckBox } from 'ui/new/form';
import style from './style.scss';

type DeleteProps = {
  handleSubmit: InjectedFormProps['handleSubmit'];
};

export default function Delete({ handleSubmit }: DeleteProps): JSX.Element {
  return (
    <Column className={style.aware} onSubmit={handleSubmit}>
      <span className={style.aware__text}>Estou ciente que:</span>
      <Field
        name="aware"
        id="aware"
        component={CheckBox}
        type="checkbox"
        text="O cadastro do produto não poderá ser recuperado"
        validate={[required]}
      />
    </Column>
  );
}
