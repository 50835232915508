import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Checkbox, Dialog, Snackbar } from '@iclinic/design-system';
import { gotoAppRoute } from 'shared/utils/appRoutes';
import {
  getDeleteMultiplePhysicianData,
  getUserInfo,
} from '../../state/delete-multiple-physician/selectors';
import {
  cleanDeleteMultiplePhysician,
  clearErrorMessage,
  deleteMultiplePhysicians,
  deleteMultiplePhysiciansFailure,
} from '../../state/delete-multiple-physician';
import ButtonWithLoading from '../../components/ButtonWithLoading';
import * as S from './ModalConfirmPhysiciansDelete.styles';
import { trackDownsellConfirmation } from '../../utils/trackDownsellEvents';

const SINGLE_DELECTION_QTD = 1;

const ModalConfirmPhysiciansDelete = () => {
  const dispatch = useDispatch();
  const userData = useSelector(getUserInfo);
  const { key, errorMessage, isDeleting, userIds } = useSelector(
    getDeleteMultiplePhysicianData,
  );
  const [checkConfirm, setCheckconfirm] = useState(false);
  const [openError, setOpenError] = useState(false);

  const handleCheckConfirmChange = () => {
    setCheckconfirm((prevValue) => !prevValue);
  };

  const handleCancelClick = () => {
    trackDownsellConfirmation({
      userData,
      stepName: 'cancel_downsell',
      stepNum: 5,
    });
    dispatch(cleanDeleteMultiplePhysician());
  };

  const handleSubmitDelete = () => {
    if (!checkConfirm) {
      const failureMessage = 'Por favor, confirme estar ciente da exclusão';
      trackDownsellConfirmation({
        userData,
        stepName: 'submit_downsell',
        stepNum: 3,
        failureReason: failureMessage,
      });
      dispatch(deleteMultiplePhysiciansFailure(failureMessage));
      return;
    }

    trackDownsellConfirmation({
      userData,
      stepName: 'submit_downsell',
      stepNum: 3,
    });
    dispatch(deleteMultiplePhysicians());
  };

  const handleCloseError = () => {
    setOpenError(false);
    dispatch(clearErrorMessage());
  };

  const handleBlurTrackCheck = () => {
    trackDownsellConfirmation({
      userData,
      stepName: 'confirm_check',
      stepNum: 2,
    });
  };

  const handleExportDataClick = () => {
    trackDownsellConfirmation({
      userData,
      stepName: 'goto_export_data',
      stepNum: 4,
    });

    gotoAppRoute('configuracoes/exportacao-dados/');
  };

  useEffect(() => {
    if (key) {
      trackDownsellConfirmation({
        userData,
        stepName: 'opened_modal',
        stepNum: 1,
      });
    }
  }, [key, userData]);

  useEffect(() => {
    if (errorMessage) {
      setOpenError(true);
    }
  }, [errorMessage, setOpenError]);

  return (
    <>
      <Dialog open={!!key}>
        <S.Root>
          <S.ModalTitle variant="sm">
            Os prontuários e outros dados relacionados ao profissional serão
            excluídos
          </S.ModalTitle>

          <S.ModalDescription variant="sm">
            Antes de excluir o profissional, recomendamos a exportação dos seus
            dados nas “Configurações da conta” e/ou compartilhamento dos dados
            nas “Configurações de profissionais”
          </S.ModalDescription>

          <Checkbox
            name="confirm-delete"
            checked={checkConfirm}
            onChange={handleCheckConfirmChange}
            color="primary"
            label={
              userIds && userIds?.length > SINGLE_DELECTION_QTD
                ? `Confirmo a exclusão dos ${userIds?.length} profissionais e de seus dados`
                : 'Confirmo a exclusão do profissional e de seus dados'
            }
            onBlur={handleBlurTrackCheck}
          />

          <S.ModalActions>
            <Button color="transparent" onClick={handleCancelClick}>
              Cancelar
            </Button>

            <S.ActionButtonsWrapper>
              <Button color="secondary" onClick={handleExportDataClick}>
                Exportar dados
              </Button>

              <ButtonWithLoading
                isLoading={isDeleting}
                onClick={handleSubmitDelete}
              >
                Excluir
              </ButtonWithLoading>
            </S.ActionButtonsWrapper>
          </S.ModalActions>
        </S.Root>
      </Dialog>

      <Snackbar
        open={openError}
        onClose={handleCloseError}
        severity="error"
        message={errorMessage}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
    </>
  );
};

export default ModalConfirmPhysiciansDelete;
