import React from 'react';
import { Button } from '@iclinic/design-system';

import { ActionButtons, Container } from './ChoiceDeleteOverlay.styles';

type ChoiceDeleteOverlayProps = {
  title: React.ReactNode;
  onActionNo: () => any;
  onActionYes: () => any;
};

const ChoiceDeleteOverlay = ({
  title,
  onActionYes,
  onActionNo,
}: ChoiceDeleteOverlayProps) => {
  const disableBubbling = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Container onClick={disableBubbling}>
      {title}
      <ActionButtons>
        <Button
          variant="contained"
          size="small"
          color="transparent"
          onClick={onActionYes}
        >
          Sim
        </Button>
        <Button
          variant="contained"
          size="small"
          color="transparent"
          onClick={onActionNo}
        >
          Não
        </Button>
      </ActionButtons>
    </Container>
  );
};

export default ChoiceDeleteOverlay;
