import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

export type InstallmentOption = {
  id: string;
  label: string;
  valueOption: string;
  is_default: boolean;
  value: string;
};

export type Installment = {
  id: string;
  order: string;
  dueDate: string;
  paymentStatus: boolean;
  value: string;
  received: boolean;
  dateOfReceiptOfInstallment: string;
};

const fakeCreditCardInstallments = () => {
  const installmentList: Installment[] = [];
  const installmentOptionsList: InstallmentOption[] = [];
  let selectedInstallmentData: Partial<InstallmentOption> = {};

  const createOptionList = (
    totalValueOfInstallment: number,
    maxInstallment: number = 12,
  ): InstallmentOption[] => {
    installmentOptionsList.length = 0;
    let iterator = 1;

    for (iterator; iterator <= maxInstallment; iterator += 1) {
      const installmentValue = (totalValueOfInstallment / iterator)
        .toFixed(2)
        .replace('.', ',');
      const currentInstallment = `${iterator}x R$${installmentValue}`;

      installmentOptionsList.push({
        id: uuidv4(),
        label: currentInstallment,
        valueOption: String(iterator),
        is_default: iterator === 1,
        value: installmentValue,
      });
    }

    return installmentOptionsList;
  };

  const optionList = (): InstallmentOption[] => installmentOptionsList;

  const create = (
    numberOfInstallment: number,
    totalValue: number,
  ): Installment[] => {
    const options = createOptionList(totalValue, 6);
    selectedInstallmentData = options[numberOfInstallment - 1];

    const dueDateIncrement = moment();
    installmentList.length = 0;

    for (let i = 1; i <= numberOfInstallment; i += 1) {
      installmentList.push({
        id: uuidv4(),
        order: `${i}/${numberOfInstallment}`,
        dueDate: dueDateIncrement.format('DD/MM/YYYY'),
        paymentStatus: i === 1,
        value: `R$${selectedInstallmentData.value!}`,
        received: i === 1,
        dateOfReceiptOfInstallment: 'dd/mm/aaaa',
      });

      dueDateIncrement.add(30, 'day');
    }

    return installmentList;
  };

  const list = (): Installment[] => installmentList;

  const getSelectedInstallmenData = (): InstallmentOption | {} =>
    selectedInstallmentData;

  return {
    createOptionList,
    optionList,
    create,
    list,
    getSelectedInstallmenData,
  };
};

export default fakeCreditCardInstallments();
