import React from 'react';
import { Body, MaterialUICore } from '@iclinic/design-system';

import * as S from './Trigger.styles';
import { TriggerProps } from '../../types';
import { ICLINIC_APP_BREAKPOINT } from '../../constants';

const { useMediaQuery } = MaterialUICore;

export const Title = ({ children }: { children: React.ReactNode }) => {
  const isDesktop = useMediaQuery(ICLINIC_APP_BREAKPOINT);

  return isDesktop ? (
    <S.Heading variant="xs"> {children}</S.Heading>
  ) : (
    <Body variant="xxs" align="left">
      {children}
    </Body>
  );
};

export function TriggerDescription({ alternative = false }: TriggerProps) {
  return alternative ? (
    <>
      <Title>
        Quer reduzir a taxa de faltas dos seus pacientes, aumentar fidelização e
        atração de pacientes?
      </Title>
      <S.Description variant="xxs">
        Confira como o Whatsapp Business integrado no iClinic pode te ajudar!
      </S.Description>
    </>
  ) : (
    <Title>
      É interessante para você otimizar tempo e melhorar a experiência do seu
      paciente com o Whatsapp Business integrado no seu consultório?
    </Title>
  );
}
