import * as Yup from 'yup';

import compareVersions from 'features/tissInvoicing/utils/compareVersions';

export const requiredFieldText = 'Campo obrigatório';

export const appointmentSectionSchema = (version_id?: string) => {
  let passeValidationVersionV4: any = {
    attendance_regimen: Yup.string().required(requiredFieldText),
  };

  if (version_id) {
    const isLessThanV4 = compareVersions(version_id).isLessThan(4);

    if (isLessThanV4) {
      passeValidationVersionV4 = {};
    }
  }

  return Yup.object().shape({
    ...passeValidationVersionV4,
    accident_indication: Yup.string().nullable().required(requiredFieldText),
    request_date: Yup.string().required(requiredFieldText),
    tuss_table_code: Yup.string().required(requiredFieldText),
    total_amount: Yup.string().required(requiredFieldText),
    appointment_type: Yup.string().required(requiredFieldText),
    tuss_procedure_id: Yup.string().nullable().required(requiredFieldText),
  });
};
