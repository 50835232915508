import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Heading, Loader } from '@iclinic/design-system';
import AppliedFilters from './AppliedFilters';
import MoreFilters from './MoreFilters';
import OffCanvasBoxDetails from './OffCanvasBoxDetails';
import SearchBarSampleBox from './SearchBarSampleBox';
import {
  fetchAvailableSampleBoxes,
  selectSampleBox,
  fetchRequestedSampleBoxes,
  fetchAvailableActivePrinciples,
  fetchAvailablePharmaceuticals,
} from '../../state/sample-boxes';
import SnackbarMessage from './SnackbarMessage';
import { SampleBox } from '../../services/types';
import * as S from './SampleBoxes.styles';
import TabForTab from './TabForAvailableRequested';
import { getFiltersData } from '../../state/sample-boxes/selectors';
import CardSpecialtyNotAvailable from './CardSpecialtyNotAvailable';

const SampleBoxes = () => {
  const {
    isFetchingPharmaceuticals,
    pharmaceuticals,
    isFetchingActivePrinciples,
    activePrinciples,
  } = useSelector(getFiltersData);

  const isFetching = isFetchingPharmaceuticals || isFetchingActivePrinciples;
  const hasBoxes = pharmaceuticals?.length || activePrinciples?.length;

  const dispatch = useDispatch();
  const [filter, setFilter] = useState<string | undefined>();

  useEffect(() => {
    dispatch(fetchAvailablePharmaceuticals());
    dispatch(fetchAvailableActivePrinciples());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchAvailableSampleBoxes({
        query: filter,
        onlyAvailables: true,
      }),
    );
    dispatch(
      fetchRequestedSampleBoxes({
        query: filter,
        onlyAvailables: false,
      }),
    );
  }, [dispatch, filter]);

  const handleDetailsClick = (box: SampleBox) => {
    dispatch(selectSampleBox(box));
  };

  const handleRemoveFilter = () => {
    setFilter(undefined);
  };

  if (!hasBoxes && !isFetching) {
    return (
      <>
        <S.Root>
          <Heading variant="lg" gutterBottom>
            Amostra de medicamentos
          </Heading>
          <Heading variant="xs">
            Solicite direto com as farmacêuticas e receba no conforto de sua
            clínica
          </Heading>

          <CardSpecialtyNotAvailable />
        </S.Root>
        <SnackbarMessage />
      </>
    );
  }

  if (isFetching) {
    return (
      <S.Root>
        <Heading variant="lg" gutterBottom>
          Amostra de medicamentos
        </Heading>

        <Heading variant="xs">
          Solicite direto com as farmacêuticas e receba no conforto de sua
          clínica
        </Heading>

        <Box display="flex" justifyContent="center" p={2}>
          <Loader data-testid="page-loader" />
        </Box>
      </S.Root>
    );
  }

  return (
    <>
      <S.Root>
        <S.PageTitle variant="lg">Amostra de medicamentos</S.PageTitle>
        <Heading variant="sm">
          Solicite direto com as farmacêuticas e receba no conforto de sua
          clínica
        </Heading>

        <S.FiltersWrapper>
          <SearchBarSampleBox onApplyQueryFilter={setFilter} />

          <MoreFilters onApplyFilter={setFilter} />
        </S.FiltersWrapper>

        {filter && (
          <AppliedFilters filter={filter} onRemoveFilter={handleRemoveFilter} />
        )}

        <TabForTab onDetailsClick={handleDetailsClick} />
      </S.Root>

      <OffCanvasBoxDetails />

      <SnackbarMessage />
    </>
  );
};

export default SampleBoxes;
