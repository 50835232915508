import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blockedMediasText: {
    fontSize: '23px',
    fontWeight: 'bold',
  },
  bodyTextAcceptMedia: {
    margin: '28px 0',
    color: theme.palette.grey[700],
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    width: 400,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
  },
}));

export default useStyles;
