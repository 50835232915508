import React, { useState } from 'react';
import { Button, MaterialIcons } from '@iclinic/design-system';

import { SpaceBetweenBox } from 'features/tissInvoicing/components/ui/Flexbox';
import CurrentGuidesOverview from 'features/tissInvoicing/components/lot/CurrentGuidesOverview';
import Filter from './Filter';
import * as Styles from './styles';

const { PlaylistAddCheck, FilterList } = MaterialIcons;

interface SubheaderProps {
  total?: string;
  guides?: number;
  errors?: number;
}

function Subheader({ total = '0,00', guides = 0, errors = 0 }: SubheaderProps) {
  const classes = Styles.useStyles();

  const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);

  const handleFilterDrawer = () => setOpenFilterDrawer((state) => !state);

  return (
    <SpaceBetweenBox padding={2}>
      <Button
        classes={{ root: classes.button }}
        color="transparent"
        startIcon={<FilterList />}
        onClick={handleFilterDrawer}
      >
        Filtrar
      </Button>

      <SpaceBetweenBox>
        <PlaylistAddCheck classes={{ root: classes.icon }} />
        <CurrentGuidesOverview guides={guides} errors={errors} total={total} />
      </SpaceBetweenBox>

      <Filter open={openFilterDrawer} handleClose={handleFilterDrawer} />
    </SpaceBetweenBox>
  );
}

export default Subheader;
