import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialUICore, Paper, Button, Box } from '@iclinic/design-system';

import { ErrorAlert } from 'shared/components';
import { composeTextFormPayment } from 'features/onlinePayment/utils';
import {
  toggleTransactionDetailOpen,
  fetchAttemptTransactionDetail,
} from 'features/onlinePayment/state/reports/transactions';
import { getAttemptTransactionsListSelector } from 'features/onlinePayment/state/reports/transactions/selectors';
import useStyles from './Table.styles';
import Paginate from '../Paginate';
import NoTransactionsToView from './NoTransactionsToView';
import Loader from './Loader';
import StatusTransactionIcon from './StatusTransactionIcon';

const { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } =
  MaterialUICore;

type AttemptTransactionsTableProps = {
  isShowDetailButton?: boolean;
};

export default function AttemptTransactionsTable({
  isShowDetailButton = true,
}: AttemptTransactionsTableProps): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    fetching,
    attemptTransactionsList,
    attemptTransactionsListFetchError,
  } = useSelector(getAttemptTransactionsListSelector);

  const toggleDrawer = (id: string) => {
    dispatch(toggleTransactionDetailOpen(true));
    dispatch(fetchAttemptTransactionDetail(id));
  };

  if (attemptTransactionsList && attemptTransactionsList.length === 0) {
    return <NoTransactionsToView />;
  }

  return (
    <>
      {fetching && <Loader />}
      {!fetching && attemptTransactionsList && (
        <Paper variant="outlined" className={classes.paper}>
          <TableContainer className={classes.tableContainer}>
            <Table
              aria-label="Tabela de histórico de transações presenciais"
              data-testid="attempt-transactions-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="left">Data</TableCell>
                  <TableCell align="left">Valor</TableCell>
                  <TableCell align="left">Descrição</TableCell>
                  <TableCell align="left">Condição</TableCell>
                  {isShowDetailButton && <TableCell align="center" />}
                </TableRow>
              </TableHead>
              <TableBody>
                {attemptTransactionsList &&
                  attemptTransactionsList.map((transaction) => (
                    <TableRow className={classes.tableRow} key={transaction.id}>
                      <TableCell align="center">
                        <StatusTransactionIcon status={transaction.status} />
                      </TableCell>
                      <TableCell align="left">
                        {transaction.createdAt}
                      </TableCell>
                      <TableCell align="left">{transaction.amount}</TableCell>
                      {transaction.bill?.id ? (
                        <TableCell align="left">
                          {transaction.bill!.patientName}
                        </TableCell>
                      ) : (
                        <TableCell align="left">
                          {transaction.cardHolder}
                        </TableCell>
                      )}
                      <TableCell align="left">
                        {composeTextFormPayment(
                          transaction.type,
                          transaction.installments,
                        )}
                      </TableCell>
                      {isShowDetailButton && (
                        <TableCell align="center">
                          <Button
                            color="transparent"
                            onClick={() => toggleDrawer(transaction.id)}
                            size="small"
                          >
                            Ver mais
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Paginate />
        </Paper>
      )}
      {attemptTransactionsListFetchError && (
        <Box mt={3}>
          <ErrorAlert
            message={
              <span
                dangerouslySetInnerHTML={{
                  __html: attemptTransactionsListFetchError,
                }}
              />
            }
          />
        </Box>
      )}
    </>
  );
}
