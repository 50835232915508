// External
import * as React from 'react';

// Internal
import Icon from '@material/react-material-icon';
import { STATUS } from './constants';
import style from './style/index.scss';

type StatusIconProps = {
  status: STATUS
}

export default function StatusIcon({ status }: StatusIconProps): JSX.Element | null{
  switch (status) {
    case STATUS.Alert:
      return <Icon className={style['statusIcon--alert']} icon="error" />;
    case STATUS.Regular:
      return null;
    case STATUS.Success:
      return <Icon className={style['statusIcon--success']} icon="check_circle" />;
    default:
      console.error('unsupported status:', status);
      return null;
  }
}
