import React from 'react';
import { useSelector } from 'react-redux';

import { Box } from '@iclinic/design-system';
import { Benefit } from '../benefit';
import { BENEFITS } from 'shared/constants/benefits';
import PaymentInfoAdmin from '../PaymentInfoAdmin';
import PaymentInfoNonAdmin from '../PaymentInfoNonAdmin';
import {
  getCurrentModal,
  getPaymentInfo,
  getPeriod,
  getSelectedProduct,
  getSelectedProductPrice,
  getUpdatedProducts,
  getUserInfo,
} from 'state/upgrade/selectors';
import { ProductPrice, ProductToUpgrade } from 'features/upgrade/types';
import Product from './Product';

const ProductsList = () => {
  const currentModal = useSelector(getCurrentModal);
  const productsToUpgrade: ProductToUpgrade[] = useSelector(getUpdatedProducts);
  const userInfo = useSelector(getUserInfo);
  const isAnnual = useSelector(getPeriod);
  const selectedProduct = useSelector(getSelectedProduct);
  const selectedProductPrice: ProductPrice = useSelector(
    getSelectedProductPrice,
  );
  const paymentInfo = useSelector(getPaymentInfo);

  const PaymentInfo = () => {
    return userInfo.isAdmin ? (
      <PaymentInfoAdmin
        isAnnual={isAnnual}
        productName={selectedProduct.name}
        selectedProductPrice={selectedProductPrice}
        totalUsers={userInfo.totalUsers}
        paymentInfo={paymentInfo}
      />
    ) : (
      <PaymentInfoNonAdmin
        productName={selectedProduct.name}
        userName={userInfo.name}
      />
    );
  };

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
      <Benefit.Root>
        {BENEFITS.map((item) => (
          <Benefit.Item
            key={item.slug}
            type="text"
            modal={currentModal}
            slug={item.slug}
          >
            {item.name}
          </Benefit.Item>
        ))}
      </Benefit.Root>
      {productsToUpgrade.map((item) => (
        <Product item={item} key={item.slug} />
      ))}
      {selectedProduct ? (
        <PaymentInfo />
      ) : (
        <div>Nenhum produto selecionado</div>
      )}
    </Box>
  );
};

export default ProductsList;
