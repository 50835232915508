import React from 'react';

import Modal from 'ui/new/modal';
import { FOOTER } from 'ui/new/modal/constants';
import {
  createProduct,
  updateProduct,
  deleteProduct,
} from 'features/stock/state/actions';
import { ModalType, MODAL_TYPE_STYLE } from 'features/stock/constants';
import { getModalTitle } from 'features/stock/modal/utils';
import EnhanceStockOutputInventoryForms, {
  OutputRenderProps,
} from 'features/stock/containers/EnhanceStockOutputInventoryForms';
import EnhanceStockInputProductForms, {
  InputRenderProps,
} from 'features/stock/containers/EnhanceStockInputProductForms';
import enhanceStockAddProductForms from 'features/stock/containers/EnhanceStockAddProductForms';
import enhanceStockEditProductForms from 'features/stock/containers/EnhanceStockEditProductForms';
import enhanceStockDeleteProductForms from 'features/stock/containers/EnhanceStockDeleteProductForms';

import {
  Save,
  Edit as EditFooter,
  Delete as DeleteFooter,
  Input as InputFooter,
  Output as OutputFooter,
} from 'features/stock/modal/footer';
import {
  Add,
  Edit as EditBody,
  Delete as DeleteBody,
  Input as InputBody,
  Output as OutputBody,
} from 'features/stock/modal/body';

const { Body, Footer } = Modal;

const EnhanceStockAddProductForms = enhanceStockAddProductForms(createProduct);
const EnhanceStockEditProductForms = enhanceStockEditProductForms(
  updateProduct,
);
const EnhanceStockDeleteProductForms = enhanceStockDeleteProductForms(
  deleteProduct,
);

type MyStockModalProps = {
  currentModal: ModalType;
  isFetching: boolean;
  onClose(): void;
  totalSelectedItems: number;
};

export default function MyStockModal({
  currentModal,
  isFetching,
  onClose,
  totalSelectedItems = 0,
}: MyStockModalProps): JSX.Element {
  return (
    <Modal
      show
      type={MODAL_TYPE_STYLE[currentModal]}
      title={getModalTitle(totalSelectedItems, currentModal)}
      onClose={onClose}
      width={550}
    >
      <Body>
        {
          {
            [ModalType.Add]: (
              <EnhanceStockAddProductForms
                render={({ change, handleSubmit, units }) => (
                  <Add
                    change={change}
                    units={units}
                    handleSubmit={handleSubmit}
                  />
                )}
              />
            ),
            [ModalType.Edit]: (
              <EnhanceStockEditProductForms
                render={({
                  change,
                  handleSubmit,
                  aditionalInventoryData,
                  units,
                }) => (
                  <EditBody
                    change={change}
                    handleSubmit={handleSubmit}
                    units={units}
                    aditionalInventoryData={aditionalInventoryData}
                  />
                )}
              />
            ),
            [ModalType.Delete]: (
              <EnhanceStockDeleteProductForms
                render={({ handleSubmit }) => (
                  <DeleteBody handleSubmit={handleSubmit} />
                )}
              />
            ),
            [ModalType.Input]: (
              <EnhanceStockInputProductForms
                // eslint-disable-next-line react/jsx-props-no-spreading
                render={(props: InputRenderProps) => <InputBody {...props} />}
              />
            ),
            [ModalType.Output]: (
              <EnhanceStockOutputInventoryForms
                // {...this.props}
                // eslint-disable-next-line react/jsx-props-no-spreading
                render={(props: OutputRenderProps) => <OutputBody {...props} />}
              />
            ),
          }[currentModal]
        }
      </Body>
      <Footer align={FOOTER.right}>
        {
          {
            [ModalType.Add]: (
              <EnhanceStockAddProductForms
                render={({ handleSubmit }) => (
                  <Save
                    cancelForm={onClose}
                    handleSubmit={handleSubmit}
                    isFetching={isFetching}
                  />
                )}
              />
            ),
            [ModalType.Edit]: (
              <EnhanceStockEditProductForms
                render={({ submitForm, deleteForm }) => (
                  <EditFooter
                    submitForm={submitForm}
                    deleteForm={deleteForm}
                    isFetching={isFetching}
                  />
                )}
              />
            ),
            [ModalType.Delete]: (
              <EnhanceStockDeleteProductForms
                render={({ submitForm }) => (
                  <DeleteFooter
                    submitForm={submitForm}
                    cancelForm={onClose}
                    isFetching={isFetching}
                  />
                )}
              />
            ),
            [ModalType.Input]: (
              <EnhanceStockInputProductForms
                render={({ handleSubmit }) => (
                  <InputFooter
                    handleSubmit={handleSubmit}
                    cancelForm={onClose}
                    isFetching={isFetching}
                  />
                )}
              />
            ),
            [ModalType.Output]: (
              <EnhanceStockOutputInventoryForms
                render={({ handleSubmit }) => (
                  <OutputFooter
                    handleSubmit={handleSubmit}
                    cancelForm={onClose}
                    isFetching={isFetching}
                  />
                )}
              />
            ),
          }[currentModal]
        }
      </Footer>
    </Modal>
  );
}
