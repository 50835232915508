import React from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Heading,
  Body,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogActions,
} from '@iclinic/design-system';

import { monetaryFormatter } from 'shared/utils/formatters';
import {
  getPaymentSlipData,
  getPaymentSummaryTotal,
} from '../../../state/selectors';
import { Divider } from '../Common.styles';

interface ModalSecondCopyPaymentSlipProps {
  open: boolean;
  onClose: () => void;
}

export const ModalSecondCopyPaymentSlip = ({
  open,
  onClose,
}: ModalSecondCopyPaymentSlipProps) => {
  const { barCode, link } = useSelector(getPaymentSlipData);
  const total = useSelector(getPaymentSummaryTotal);

  const onClickGenerateSecondCopyHandler = () => {
    if (!link) return;

    window.open(link, '__blank');
  };

  const onCopyBarCodeClickHandler = () => {
    if (!barCode) return;

    navigator.clipboard.writeText(barCode) as unknown;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader title="Gerar 2º via do boleto" onClose={onClose} />

      <DialogContent>
        <div>
          <Heading variant="overline">Linha digitável</Heading>

          <Body variant="xs" bold>
            {barCode}
          </Body>
        </div>

        <Divider />

        <div>
          <Heading variant="overline">Valor</Heading>

          <Body variant="xs" bold>
            R$ {monetaryFormatter(total?.toString().replace('.', ',') ?? '')}
          </Body>
        </div>
      </DialogContent>

      <DialogActions>
        <Button color="transparent" onClick={onClickGenerateSecondCopyHandler}>
          Download PDF
        </Button>

        <Button onClick={onCopyBarCodeClickHandler}>
          Copiar linha digitável
        </Button>
      </DialogActions>
    </Dialog>
  );
};
