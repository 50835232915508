export const CREDIT_CARD_NUMBER = '9999 9999 9999 9999';
export const CREDIT_CARD_VALIDITY = '99/99';
export const CREDIT_CARD_CVV = '9999';
export const CPF_CNPJ = '99999999999999';
export const FULL_DATE = '99/99/9999';
// PHONE: not this doesn't handle 10-digit '(12) 3456-7890', use in conjunction
// with shared/utils/v2/phoneInputMask
export const PHONE = '(99) 99999-9999';
export const HOUR_MINUTE = '99:99';
export const CODE = '9';
