import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Body, MaterialIcons } from '@iclinic/design-system';

import { fetchSSOToken } from 'features/onlinePayment/state/iclinic-pay';
import {
  getNotificationToken,
  getUnreadNotifications,
  getUnreadNotificationsCount,
} from 'features/onlinePayment/state/notifications-center/selectors';
import {
  fetchNotifications,
  fetchNotificationToken,
  toggleShowNotificationsCenter,
} from 'features/onlinePayment/state/notifications-center';
import * as S from './styles';

const { AttachMoney } = MaterialIcons;

const NavIcon = (): JSX.Element => {
  const dispatch = useDispatch();
  const unreadNotificationsCount = useSelector(getUnreadNotificationsCount);
  const unreadNotifications = useSelector(getUnreadNotifications);
  const notificationToken = useSelector(getNotificationToken);

  const handleClick = () => {
    dispatch(toggleShowNotificationsCenter(true));
  };

  useEffect(() => {
    if (!notificationToken) {
      dispatch(fetchNotificationToken());
    } else {
      dispatch(fetchNotifications());
      dispatch(fetchSSOToken());
    }
  }, [notificationToken]);

  return (
    <S.NavIconWrapper
      onClick={handleClick}
      data-ga="pay_notification_real_sign"
    >
      <AttachMoney data-testid="nav-icon" />
      {!!(unreadNotificationsCount && unreadNotifications) && (
        <S.Counter data-testid="nav-icon-counter">
          <Body variant="xxs" inverseTextColor>
            {unreadNotificationsCount}
          </Body>
        </S.Counter>
      )}
    </S.NavIconWrapper>
  );
};

export default NavIcon;
