import { RequestSamplesFormData } from './types';

export const factoryRequestSamplesInitialData = (): RequestSamplesFormData => ({
  ufCrm: '',
  crm: '',
  fullName: '',
  cpf: '',
  phoneNumber: '',
  address: '',
  number: '',
  neighborhood: '',
  zipCode: '',
  city: '',
  state: '',
  complement: '',
  acceptTerms: false,
  isClinicAddress: false,
  acceptTermDataProtection: false,
});
