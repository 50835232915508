import React from 'react';

import { Box, Dialog } from '@iclinic/design-system';

interface PrintModalProps {
  isOpen: boolean;
  url: string;
  handleClose: () => void;
  title: string
}

export default function PrintModal({
  isOpen,
  handleClose,
  url,
  title
}: PrintModalProps): JSX.Element {
  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <Box height={700} width={900}>
        <iframe
          className="document-modal-iframe"
          src={url}
          frameBorder="0"
          title={title}
        />
      </Box>
    </Dialog>
  );
}
