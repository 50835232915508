import React, { memo } from 'react';

import history from 'routes/history';
import { Heading, Button, MaterialIcons, Body } from '@iclinic/design-system';
import { SpaceBetweenBox } from 'features/tissInvoicing/components/ui/Flexbox';
import * as Styles from './styles';

const { ArrowBack } = MaterialIcons;

interface HeaderProps {
  isNewLot: boolean;
  title: string;
}

function Header({ title, isNewLot }: HeaderProps) {
  const classes = Styles.useStyles();

  return (
    <SpaceBetweenBox>
      <Styles.Content>
        <Heading variant="lg">{title}</Heading>

        <Body variant="sm">Configure o lote e adicione as guias desejadas</Body>
      </Styles.Content>

      <Button
        classes={{ root: classes.button }}
        color="transparent"
        startIcon={<ArrowBack />}
        onClick={() => history.push('/faturamento-tiss/lotes')}
      >
        {isNewLot ? 'Cancelar' : 'Voltar'}
      </Button>
    </SpaceBetweenBox>
  );
}

export default memo(Header);
