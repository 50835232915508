import React from 'react';
import { LocalVideoTrack } from 'twilio-video';
import classnames from 'classnames';

import { Box, Chip, MaterialIcons } from '@iclinic/design-system';
import VideoTrack from 'features/video-conference/components/VideoTrack';
import ToggleAudioButton from 'features/video-conference/components/Toolbar/Buttons/MicrophoneButton';
import ToggleVideoButton from 'features/video-conference/components/Toolbar/Buttons/CameraButton';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { DevicePermission } from 'features/video-conference/components/Feedback';
import {
  getMediaErrorMessage,
  isMobile,
} from 'features/video-conference/utils';
import useDevices from 'features/video-conference/hooks/useDevices';
import {
  CAMERA_OFF,
  buttonsTitle,
  READY_TO_JOIN_WARNING,
  READY_TO_JOIN_WARNING_2,
} from 'features/video-conference/constants';
import useStyles from './styles';

interface Props {
  mediaError?: Error;
  minimizedMode?: boolean;
  isReadyToJoin?: boolean;
  isFilterWindow?: boolean;
}

const { AccessTime, Done } = MaterialIcons;

export default function LocalVideoPreview({
  mediaError,
  minimizedMode,
  isReadyToJoin,
  isFilterWindow = false,
}: Props) {
  const classes = useStyles();
  const { localTracks } = useConference();
  const { hasAudioInputDevices, hasVideoInputDevices } = useDevices();

  const mediaErrorMessage = getMediaErrorMessage(
    hasAudioInputDevices,
    hasVideoInputDevices,
    mediaError,
  );

  const videoTrack = localTracks.find(
    (track) => track.kind === 'video',
  ) as LocalVideoTrack;

  if (mediaError)
    return <DevicePermission waitRoom message={mediaErrorMessage} />;

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        {videoTrack ? (
          <VideoTrack track={videoTrack} isLocal />
        ) : (
          <div className={classes.avatarContainer}>{CAMERA_OFF}</div>
        )}
      </div>
      {minimizedMode && (
        <Box display="flex" width="100%" justifyContent="center">
          <Chip
            size="small"
            icon={
              isReadyToJoin ? (
                <Done titleAccess="iconDone" />
              ) : (
                <AccessTime titleAccess="iconAcessTime" />
              )
            }
            label={
              isReadyToJoin
                ? READY_TO_JOIN_WARNING
                : buttonsTitle.WAITING_FOR_PARTICIPANT
            }
            className={classnames(classes.chip, {
              [classes.chipBottomLarge]: isReadyToJoin && !isMobile,
            })}
          />
          {isReadyToJoin && !isMobile && (
            <Chip
              size="small"
              label={READY_TO_JOIN_WARNING_2}
              className={classnames(classes.chip, classes.fontSizeSmaller)}
            />
          )}
        </Box>
      )}
      {!isFilterWindow && (
        <Box className={classes.buttonsContainer}>
          <Box mr={1}>
            <ToggleAudioButton />
          </Box>
          <ToggleVideoButton />
        </Box>
      )}
    </div>
  );
}
