// External
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray, fieldArrayFieldsPropTypes } from 'redux-form';

// Internal
import Dropdown from 'ui/new/dropdown';
import SearchBar from 'ui/new/search';
import Tag from './Tag';
// Style
import style from './TagsContainer.scss';

const TagsArray = ({ fields, renderLabel, availableItems, searchName }) => {
  const [isOpen, setToggleMenu] = useState(false);

  const title = React.useMemo(
    () => [
      <div key="existing" className={style.tagsContainer__text}>
        {fields.map((tag, index) => (
          <Field
            key={tag}
            name={tag}
            component={Tag}
            renderLabel={renderLabel}
            onDelete={() => fields.remove(index)}
          />
        ))}
      </div>,
    ],
    [fields, renderLabel],
  );

  const selectItem = React.useCallback(
    (id) => {
      fields.push(id);
    },
    [fields],
  );

  const searchDropdown = React.useMemo(
    () => (
      <Field
        type="text"
        name={searchName}
        component={SearchBar}
        placeholder="Selecione"
      />
    ),
    [searchName],
  );

  return (
    <div>
      <Dropdown
        title={title}
        renderInternalSearch={searchDropdown}
        isOpenProp={isOpen}
        setToggleMenuProp={setToggleMenu}
      >
        {availableItems.map((item) => (
          <Dropdown.Item
            onClick={() => selectItem(item.id)}
            selected={item.selected}
            key={item.id}
            kind="exists"
            value={item.id}
          >
            {item.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  );
};

const TagsContainer = ({ name, renderLabel, availableItems, searchName }) => (
  <FieldArray
    name={`${name}.arguments`}
    component={TagsArray}
    renderLabel={renderLabel}
    availableItems={availableItems}
    searchName={searchName}
  />
);

TagsArray.propTypes = {
  fields: PropTypes.shape(fieldArrayFieldsPropTypes).isRequired,
  renderLabel: PropTypes.func.isRequired,
  availableItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  searchName: PropTypes.string.isRequired,
};

TagsContainer.propTypes = {
  name: PropTypes.string.isRequired,
  renderLabel: PropTypes.func.isRequired,
  availableItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.number.isRequired,
        PropTypes.string.isRequired,
      ]),
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  searchName: PropTypes.string.isRequired,
};

export default TagsContainer;
