import iClinicApi from 'services/iclinic/iClinicApi';
import {
  DownsellOptionsResponse,
  DownsellReasonData,
  DownsellReasonResponse,
  DownsellReasonUpdateData,
} from '../types';

export const getDownsellOptions = () =>
  iClinicApi.get<DownsellOptionsResponse>(
    '/subscriptions/reason-options-downsell/',
  );

export const postDownsellReason = (data: DownsellReasonData) =>
  iClinicApi.post<DownsellReasonResponse>(
    '/subscriptions/reason-downsell/',
    data,
  );

export const updateDownsellReason = (data: DownsellReasonUpdateData) =>
  iClinicApi.post('/subscriptions/reason-downsell/update-list/', data);
