import iClinicApi from 'services/iclinic/iClinicApi';
import {
  GetSubscriptionSummaryArgs,
  SubscriptionSummaryResponse,
  SubscribeData,
} from '../../types';

export const getPhysiciansAvailable = () =>
  iClinicApi.get(`/messages/templates/physicians/available-to-subscribe/`);

export const getSubscriptionSummary = ({
  subscriptionId,
  subscribeSummaryData,
}: GetSubscriptionSummaryArgs) =>
  iClinicApi.post<SubscriptionSummaryResponse>(
    `/subscriptions/subscription/${subscriptionId}/v2/new-subscription-summary/`,
    subscribeSummaryData,
  );

export const postSubscriptionUpgrade = (subscribeData: SubscribeData) =>
  iClinicApi.post(
    `/subscriptions/subscription/upgrade-messages/`,
    subscribeData,
  );
