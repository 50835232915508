import React from 'react';
import {
  MaterialUICore,
  Body,
  Button,
  Chip as ChipDs,
  axiomTokens,
} from '@iclinic/design-system';

const { Collapse, styled } = MaterialUICore;
const { color, spacing, border, font } = axiomTokens;

type HTMLElementProps<T> = React.DetailedHTMLProps<React.HTMLAttributes<T>, T>;

type CardProps = {
  active?: boolean;
} & HTMLElementProps<HTMLDivElement>;

type PlanContentWrapperProps = {
  showChips: boolean;
};

const Card = styled(({ active, ...rest }: CardProps) => <div {...rest} />)({
  borderRadius: border.radius.md,
  transition: 'all .3s ease-in-out',
  cursor: 'pointer',
  width: '100%',
  outline: (props: CardProps) =>
    props.active
      ? `${border.width.sm} solid ${color.border.activated}`
      : `${border.width.xs} solid ${color.border.subtle}`,
  backgroundColor: (props: CardProps) =>
    props.active
      ? color.surface.selected.default
      : color.surface.subtle.default,
});

export const PlansContainer = styled(Card)(({ theme }) => ({
  padding: spacing[4],
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[2],

  [theme.breakpoints.up('sm')]: {
    rowGap: 0,
    padding: spacing[2],
  },
}));

const PlanContentWrapper = styled(
  ({ showChips, ...rest }: PlanContentWrapperProps) => <div {...rest} />,
)({
  display: 'grid',
  gridTemplateAreas: (props: PlanContentWrapperProps) =>
    props.showChips
      ? `
    "title radio"
    "description description"
    "chips chips"
  `
      : `
    "title radio"
    "description description"
  `,
});

export const PlanContentDescription = styled(PlanContentWrapper)(
  ({ theme }) => ({
    gridTemplateColumns: '1fr auto',
    rowGap: spacing[2],
    paddingRight: spacing[2],

    [theme.breakpoints.up('sm')]: {
      padding: `${spacing[4]} ${spacing[8]}`,
    },
  }),
);

export const TitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: spacing[2],
  gridArea: 'title',
});

export const MostSeller = styled(Body)({
  color: color.inverse.typeface.strong,
  borderRadius: border.radius.md,
  background: `linear-gradient(90.05deg, ${color.highlight.purple[3]} 0.05%, ${color.highlight.purple[1]} 99.95%)`,
  padding: `${spacing[1]} ${spacing[2]}`,
});

export const RadioWrapper = styled('div')({
  gridArea: 'radio',
  display: 'flex',
  alignItems: 'center',

  '& > .MuiRadio-root': {
    padding: 0,
    color: color.border.accent,
  },

  '& > .Mui-checked': {
    color: color.interactive.accent.default,
  },
});

export const ChipsWrapper = styled('div')({
  gridArea: 'chips',
  display: 'flex',
  gap: spacing[2],
  flexWrap: 'wrap',
});

export const Chip = styled(ChipDs)({
  fontSize: '11px',
});

export const Label = styled('label')({
  width: '100%',
  marginBottom: 0,
});

export const AddonsCollapseWrapper = styled(Collapse)(() => ({
  gridArea: 'addons',

  '& .MuiCollapse-wrapperInner': {
    display: 'flex',
    flexDirection: 'column',
    rowGap: spacing[2],
  },
}));

export const ButtonPlanDetails = styled(Button)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const ValidityDiscount = styled(Body)(() => ({
  width: '100%',
  color: color.typeface.smooth,
  fontSize: font.size.xxxs,
}));

export const AddonTitle = styled(Body)(() => ({
  width: '100%',
  fontSize: font.size.xs,
  color: color.typeface.strong,
  padding: `${spacing[5]} ${spacing[2]} ${spacing[3]}`,
  borderTop: `1px solid ${color.surface.subtle.pressed}`,
  marginTop: spacing[2],
}));
