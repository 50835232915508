import React from 'react';

import * as S from './RangeBanner.styles';

const RangeBanner = () => {
  return (
    <S.Section>
      <S.Text variant="sm" align="center">
        Vários médicos já ganharam <span>mais de R$ 1.500</span> compartilhando
        códigos de indicação com outros médicos. Vai perder a oportunidade?
      </S.Text>
    </S.Section>
  );
};

export default RangeBanner;
