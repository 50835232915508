import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogActions,
  Body,
  Button,
} from '@iclinic/design-system';
import history from 'routes/history';
import {
  getCanJoinQueue,
  getRequestId,
} from '../../state/request-samples/selectors';
import { fetchAddToRequestWaitingQueue } from 'features/free-samples/state/request-samples';

const DialogEnterOnWaitingQueue = () => {
  const dispatch = useDispatch();
  const canJoinQueue = useSelector(getCanJoinQueue);
  const requestId = useSelector(getRequestId);
  const [open, setOpen] = useState(false);
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    if (canJoinQueue !== undefined && canJoinQueue) {
      setOpen(true);
    }
  }, [canJoinQueue]);

  const handleClose = () => {
    setOpen(false);

    history.push('/amostras-gratis');
  };

  const handleEnterOnWaitingQueue = () => {
    setAdding(true);

    dispatch(fetchAddToRequestWaitingQueue(requestId as number));
  };

  if (canJoinQueue === undefined) return null;

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick>
      <DialogHeader title="Box indisponível" onClose={handleClose} />

      <DialogContent>
        <Body variant="sm">
          Infelizmente a box não está mais disponível em estoque
        </Body>

        <Body variant="sm">Deseja entrar na lista de espera?</Body>
      </DialogContent>

      <DialogActions>
        <Button color="transparent" disabled={adding}>
          Fechar
        </Button>

        <Button isLoading={adding} onClick={handleEnterOnWaitingQueue}>
          Entrar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogEnterOnWaitingQueue;
