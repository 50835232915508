import React, { useState } from 'react';
import { Box, Button, Switch, Tooltip } from '@iclinic/design-system';

import { useConference } from 'features/video-conference/state/conferenceContext';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { useWaitRoom } from 'features/video-conference/hooks/useWaitRoom';
import DefaultContainer from 'features/video-conference/components/WaitRoom/Container';
import GoToMedicalRecordsButton from 'features/video-conference/components/Toolbar/Buttons/GoToMedicalRecordsButton';
import { Loading } from 'features/video-conference/components/Feedback';
import Alert from 'features/video-conference/components/TeamComponents/Alert';
import DeviceSelectionDialog from 'features/video-conference/components/DeviceSelection';
import LocalVideoPreview from 'features/video-conference/components/WaitRoom/LocalVideoPreview';
import {
  getTelemedicineStorage,
  useWindowHeight,
} from 'features/video-conference/utils';
import {
  buttonsTitle,
  tokenProblem,
  WAIT_ROOM,
  RECORD_WARNING_TOGGLE_DISABLED,
} from 'features/video-conference/constants';
import useStyles from './styles';
import useGlobalStyles from 'features/video-conference/components/styles';
import DialogConfirmPriceRecord from 'features/video-conference/components/DialogConfirmPriceRecord';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';

interface WaitRoomProps {
  mediaError: Error | undefined;
}

export default function WaitRoom({ mediaError }: WaitRoomProps) {
  const {
    isAcquiringLocalTracks,
    isConnecting,
    isPhysician,
    isRecording,
    isRoomCreated,
    conferenceId,
  } = useConference();
  const { physicianInfo, localParticipant, remoteParticipant } =
    useParticipantContext();
  const height = useWindowHeight();
  const {
    isReadyToJoin,
    handleJoin,
    handleToggle,
    handleAcceptPricingModal,
    handleRejectPricingModal,
    isModalPricingOpen,
  } = useWaitRoom({ isWaitRoom: true });
  const classes = useStyles();
  const classesGlobal = useGlobalStyles({ height, isPhysician });

  const [joinError, setJoinError] = useState<boolean>(false);

  const disableButton =
    isAcquiringLocalTracks || isConnecting || !isReadyToJoin;

  const iframeIsMinimized =
    getTelemedicineStorage()?.iframe_status === 'minimized';

  const handleAcceptPricingButton = () => {
    handleAcceptPricingModal();
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'record_telemedicine_price_acepted',
      buttonLocation: 'wait_room',
    });
  };

  const handleToRecordToggle = () => {
    handleToggle();
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'record_telemedicine',
      buttonLocation: 'wait_room',
      action: isRecording ? 'disabled' : 'enabled',
    });
  };

  if (iframeIsMinimized)
    return (
      <Box className={classesGlobal.room}>
        <LocalVideoPreview
          minimizedMode
          isReadyToJoin={isReadyToJoin}
          mediaError={mediaError}
        />
      </Box>
    );

  if (isAcquiringLocalTracks || isConnecting)
    return (
      <Box className={classesGlobal.room}>
        <Loading />
      </Box>
    );

  const waitMessage = isPhysician
    ? buttonsTitle.WAITING_FOR_PARTICIPANT
    : `Aguardando ${physicianInfo?.name || 'participante'}`;

  return (
    <Box className={classesGlobal.waitingRoom}>
      <div className={classesGlobal.ghostDiv} />
      <DefaultContainer title={WAIT_ROOM} isWaitRoom>
        <Box mt={1}>
          <LocalVideoPreview mediaError={mediaError} />
        </Box>
        <Box>
          {joinError && (
            <Alert message={tokenProblem.alertMessage} severity="error" />
          )}
        </Box>
        <Box mt={2}>
          <DeviceSelectionDialog containerSize="medium" location="wait_room" />
        </Box>
        <Box display="flex" justifyContent="start">
          {isPhysician && (
            <Tooltip
              title={isRoomCreated ? RECORD_WARNING_TOGGLE_DISABLED : ''}
            >
              <div>
                <Switch
                  label={buttonsTitle.TO_RECORD}
                  checked={isRecording}
                  onChange={handleToRecordToggle}
                  disabled={isRoomCreated}
                  formControlLabelProps={{
                    classes: {
                      label: classes.switchLabel,
                    },
                  }}
                />
              </div>
            </Tooltip>
          )}
          <Button
            variant="contained"
            fullWidth
            disabled={disableButton}
            title={buttonsTitle.START_CONFERENCE}
            onClick={() => handleJoin().catch(() => setJoinError(true))}
          >
            {isReadyToJoin ? buttonsTitle.START_CONFERENCE : waitMessage}
          </Button>
        </Box>
      </DefaultContainer>
      {isPhysician && (
        <Box
          width="100%"
          display="flex"
          justifyContent="flex-end"
          position="relative"
          mt={2}
        >
          <GoToMedicalRecordsButton />
        </Box>
      )}

      <DialogConfirmPriceRecord
        isOpen={isModalPricingOpen}
        onClose={handleRejectPricingModal}
        onAccept={handleAcceptPricingButton}
        onBack={handleRejectPricingModal}
      />
    </Box>
  );
}
