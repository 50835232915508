import {
  SellerBankAccountRequestBody,
  InstallmentsRequestBody,
} from 'features/onlinePayment/services/config/types';
import { SellerBankAccountForm } from '../types';

export const normalizeSellerBankAccountRequestBody = (
  sellerBankAccountForm: SellerBankAccountForm,
): SellerBankAccountRequestBody => {
  return {
    seller: sellerBankAccountForm.sellerId,
    bank_code: sellerBankAccountForm.bank,
    routing_number: sellerBankAccountForm.agency,
    account_number: sellerBankAccountForm.account,
  };
};

export const normalizeInstallmentsRequestBody = (
  sellerBankAccountForm: SellerBankAccountForm,
): InstallmentsRequestBody => {
  return {
    max_installments: parseInt(sellerBankAccountForm.installments, 10),
    min_installment_value: `${sellerBankAccountForm.minValue}`,
  };
};
