import {
  Button as DSButton,
  Heading,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { IconButton, Popper: DSPopper, makeStyles, styled } = MaterialUICore;
const { border, color, shadow, spacing } = tokens;

export default makeStyles((theme) => ({
  lastColumns: {
    padding: 0,

    [theme.breakpoints.up('md')]: {
      padding: `${spacing.xxxl} 0 0`,
    },
  },
}));

export const Button = styled(DSButton)(({ theme }) => ({
  marginTop: spacing.base,

  [theme.breakpoints.up('sm')]: {
    marginLeft: spacing.lg,
    marginTop: 0,
  },

  '& .MuiButton-label': {
    whiteSpace: 'nowrap',
  },
}));

export const Popper = styled(DSPopper)(({ theme }) => ({
  width: `calc(100% - ${spacing.lg})`,
  top: `${spacing.xs} !important`,

  [theme.breakpoints.up('md')]: {
    width: 'calc(100% - 64px)',
  },
}));

export const PopperConten = styled('div')(() => ({
  position: 'relative',
  backgroundColor: color.neutral[0],
  borderRadius: border.radius.sm,
  boxShadow: shadow.level[5],
  padding: spacing.sm,
}));

export const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: spacing.sm,
  right: spacing.sm,
}));

export const TitleFilters = styled(Heading)(() => ({
  margin: `${spacing.sm} 0`,
}));
