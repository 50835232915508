import iClinicApi from 'services/iclinic/iClinicApi';
import {
  MessageHistoryArgs,
  MessageHistoryResponse,
  MessageStatus,
} from '../types';

export const getMessageHistory = ({
  kind,
  page,
  limit = 10,
  physicianId,
  date,
  status,
}: MessageHistoryArgs) => {
  const params = [`kind=${kind}`, `page=${page}`, `limit=${limit}`];

  if (physicianId) params.push(`physician=${physicianId}`);

  if (date) params.push(`date=${date}`);

  if (status !== undefined && status in MessageStatus)
    params.push(`status=${status}`);

  return iClinicApi.get<MessageHistoryResponse>(
    `/messages/?${params.join('&')}`,
  );
};
