import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import {
  PatientData,
  AppointmentGuideFormValues,
} from 'features/tissInvoicing/types/appointmentGuide';
import { SnackbarProps } from 'features/tissInvoicing/types';
import getSnackbarPayload from 'features/tissInvoicing/utils/getSnackbarPayload';
import { normalizeAppointmentGuideResponse } from './normalizers';

export type AppointmentGuideFormState = {
  event: {
    id?: string;
    tiss_version: string;
  };
  form: {
    data: AppointmentGuideFormValues;
  };
  print: {
    pdfURL: string;
    openModal: boolean;
  };
  loading: {
    save: boolean;
    print: boolean;
  };
  snackbar: SnackbarProps;
  patient?: PatientData;
};

export const appointmentGuideFormInitialState =
  normalizeAppointmentGuideResponse();

export const initialState: AppointmentGuideFormState = {
  event: {
    tiss_version: '',
  },
  form: {
    data: appointmentGuideFormInitialState,
  },
  print: {
    pdfURL: '',
    openModal: false,
  },
  loading: {
    save: false,
    print: false,
  },
  snackbar: getSnackbarPayload(),
  patient: {} as PatientData,
};

const name = 'appointmentGuide';

const fetchPatientData = createAction<{ patientId: number }>(
  `${name}/fetchPatientData`,
);

const fetchAppointmentGuideData = createAction<{ appointmentId: string }>(
  `${name}/fetchAppointmentGuideData`,
);

const saveGuideData = createAction<{ data: AppointmentGuideFormValues }>(
  `${name}/saveGuideData`,
);

const printGuide = createAction<{ id: string }>(`${name}/printGuide`);

const patchGuideData = createAction<{
  data: AppointmentGuideFormValues;
  id: string;
}>(`${name}/patchGuideData`);

const appointmentGuideSlice = createSlice({
  name,
  initialState,
  reducers: {
    setGuideEvent: (
      state,
      action: PayloadAction<{ id: string; tiss_version: string }>,
    ) => ({
      ...state,
      event: action.payload,
    }),
    setPatientData: (state, action: PayloadAction<PatientData>) => ({
      ...state,
      patient: action.payload,
    }),
    setAppointmentGuide: (
      state,
      action: PayloadAction<AppointmentGuideFormValues>,
    ) => ({
      ...state,
      form: {
        data: action.payload,
      },
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: {
        save: action.payload,
        print: false,
      },
    }),
    setGuidePDFUrl: (
      state,
      action: PayloadAction<{ open: boolean; url: string }>,
    ) => ({
      ...state,
      print: {
        openModal: action.payload.open,
        pdfURL: action.payload.url,
      },
    }),
    setSnackbarContent: (state, action) => ({
      ...state,
      snackbar: {
        message: action.payload.message || null,
        severity: action.payload.severity || 'default',
      },
    }),
    setVersion: (state, action: PayloadAction<string>) => ({
      ...state,
      form: {
        data: {
          ...state.form.data,
          version_id: action.payload,
        },
      },
    }),
  },
});

export default appointmentGuideSlice.reducer;
export const actions = {
  ...appointmentGuideSlice.actions,
  fetchPatientData,
  fetchAppointmentGuideData,
  saveGuideData,
  printGuide,
  patchGuideData,
};
