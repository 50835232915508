import React from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import Icon from '@material/react-material-icon';

import { required, number } from 'shared/utils/formValidation';
import { Input, CheckBox } from 'ui/new/form';
import { Row, Column } from 'ui/new/flex';
import { Tooltip } from 'ui/overlay';
import { Unit } from 'features/stock/types';
import UnitsDropdown from 'features/stock/components/UnitsDropdown';
import style from './style.scss';

type AddProps = {
  change: InjectedFormProps['change'];
  units: Unit[];
  handleSubmit: InjectedFormProps['handleSubmit'];
};

export default function Add({
  change,
  units,
  handleSubmit,
}: AddProps): JSX.Element {
  return (
    <Column className={style.body} onSubmit={handleSubmit}>
      <Row className={style.row}>
        <Column className={style.product}>
          <label htmlFor="description">Produto</label>
          <Field
            name="description"
            id="description"
            component={Input}
            validate={[required]}
            maxLength={100}
            autoFocus
          />
        </Column>
        <Column className={style.cod}>
          <label htmlFor="code">Cod. (opcional)</label>
          <Field
            name="code"
            id="code"
            component={Input}
            validate={[number]}
            maxLength={30}
          />
        </Column>
      </Row>
      <Row className={style.row}>
        <Column className={style.minimum}>
          <label htmlFor="minimum" className={style.minStockLabel}>
            Est. mínimo (opcional)
            <Tooltip
              content="Se a quantidade em estoque for menor do que o estoque mínimo, este produto será considerado como estoque baixo"
              align="top"
            >
              <Icon icon="info" className={style.minStockLabel__icon} />
            </Tooltip>
          </label>
          <Field
            name="minimum"
            id="minimum"
            component={Input}
            validate={[number]}
          />
        </Column>
        <Column className={style.unit}>
          <label htmlFor="unitId">Unidade</label>
          <Field
            name="unitId"
            id="unitId"
            component={UnitsDropdown}
            validate={[required]}
            title="Selecione"
            units={units}
            change={change}
          />
        </Column>
      </Row>
      <Row className={style.row}>
        <Field
          name="hasLotControl"
          id="hasLotControl"
          component={CheckBox}
          type="checkbox"
          text="Obrigatório preencher o lote ao efetuar entrada e saída"
        />
      </Row>
      <Column className={style.observation}>
        <label htmlFor="observation">Observações (opcional)</label>
        <Field name="observation" id="observation" component={Input} />
      </Column>
    </Column>
  );
}
