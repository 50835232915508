import React, { useCallback } from 'react';
import { Body, Button, MaterialIcons as Icons } from '@iclinic/design-system';

import * as S from './NotificationPaymentFail.styles';
import { gotoAppRoute } from 'shared/utils/appRoutes';

const APP_SUBSCRIPTION_ROUTE = '/configuracoes/assinatura/';

export const NotificationPaymentFail = () => {
  const { pathname } = window.location;

  const onClickPaymentButton = useCallback(() => {
    if (pathname === APP_SUBSCRIPTION_ROUTE) return;

    gotoAppRoute(APP_SUBSCRIPTION_ROUTE);
  }, [pathname]);

  return (
    <S.Alert
      message={
        <Body variant="xxs">
          Ainda não identificamos seu pagamento. Verifique a fatura ou solicite
          liberação.
        </Body>
      }
      severity="warning"
      icon={<Icons.Warning />}
      action={
        <Button
          variant="text"
          size="small"
          onClick={onClickPaymentButton}
          data-sp="payment_slip_notification_alert_btn_click"
          data-ga="payment_slip_notification_alert_btn_click"
        >
          Ver fatura
        </Button>
      }
    />
  );
};
