import { formatDate } from 'shared/utils/date';
import { formatCurrency } from 'shared/utils/strings';
import {
  PaymentAttemptsBaseRawData,
  TransactionBaseRawData,
  TransactionDetailRawData,
} from 'features/onlinePayment/services/reports/types';
import {
  TransactionBase,
  TransactionDetail,
  AttemptTransactionBase,
  TransactionsFiltersForm,
} from './types';

export function normalizeTransactionsListResponseData(
  transactions: TransactionBaseRawData[],
): TransactionBase[] | null {
  return transactions.map(
    ({
      id,
      created_at,
      updated_at,
      event_id,
      patient_id,
      patient_name,
      status,
      amount_charged,
      installments_number,
      fee_percent,
      payment_type,
      due_date,
      paid_at,
      seller,
    }) => ({
      id,
      createdAt: formatDate(created_at),
      updatedAt: formatDate(updated_at),
      eventId: event_id,
      patientId: patient_id,
      patientName: patient_name,
      status,
      amount: formatCurrency(amount_charged),
      installmentsNumber: installments_number,
      feePercent: fee_percent,
      type: payment_type,
      dueDate: due_date,
      paidAt: paid_at && formatDate(paid_at),
      seller,
    }),
  );
}

export function normalizeTransactionDetailResponseData(
  transaction: TransactionDetailRawData,
): TransactionDetail | null {
  const {
    id,
    created_at,
    updated_at,
    event_id,
    patient_id,
    patient_name,
    status,
    amount_charged,
    installments_number,
    fee_percent,
    due_date,
    paid_at,
    receipt_email,
    seller,
    installments,
    fee,
  } = transaction;

  return {
    id,
    createdAt: formatDate(created_at),
    updatedAt: formatDate(updated_at),
    eventId: event_id,
    patientId: patient_id,
    patientName: patient_name,
    status,
    amount: formatCurrency(amount_charged),
    installmentsNumber: installments_number,
    feePercent: fee_percent,
    dueDate: due_date,
    paidAt: paid_at && formatDate(paid_at),
    receiptEmail: receipt_email,
    seller,
    installments,
    fee,
  };
}

export function normalizeAttemptTransactionsListResponseData(
  transactions: PaymentAttemptsBaseRawData[],
): AttemptTransactionBase[] | null {
  return transactions.map(
    ({
      id,
      created_at,
      updated_at,
      amount,
      installments,
      status,
      external_id,
      fee,
      fee_percent,
      type,
      resource,
      origin,
      card_brand,
      card_holder,
      card_last4_digits,
      contactless,
      bill,
    }) => ({
      id,
      createdAt: formatDate(created_at),
      updatedAt: formatDate(updated_at),
      amount: formatCurrency(amount),
      installments,
      status,
      externalId: external_id,
      fee,
      feePercent: fee_percent,
      type,
      resource,
      origin,
      cardBrand: card_brand,
      cardHolder: card_holder,
      cardLast4Digits: card_last4_digits,
      contactless,
      bill: bill?.id
        ? normalizeTransactionDetailResponseData(bill as any)
        : bill,
    }),
  );
}

export function normalizeAttemptTransactionDetailResponseData(
  transaction: PaymentAttemptsBaseRawData,
): AttemptTransactionBase | null {
  const {
    id,
    created_at,
    updated_at,
    amount,
    installments,
    status,
    external_id,
    fee,
    fee_percent,
    type,
    resource,
    origin,
    card_brand,
    card_holder,
    card_last4_digits,
    contactless,
    bill,
  } = transaction;

  return {
    id,
    createdAt: formatDate(created_at),
    updatedAt: formatDate(updated_at),
    amount: formatCurrency(amount),
    installments,
    status,
    externalId: external_id,
    fee,
    feePercent: fee_percent,
    type,
    resource,
    origin,
    cardBrand: card_brand,
    cardHolder: card_holder,
    cardLast4Digits: card_last4_digits,
    contactless,
    bill,
  };
}

export function normalizeTransactionsFiltersQueryParams(
  filters: TransactionsFiltersForm,
) {
  if (!filters) return '';
  const { startDate, endDate } = filters;

  if (!startDate || !endDate) return '';

  const formattedStartDate = formatDate(startDate, 'DD/MM/YYYY', 'YYYY-MM-DD');
  const formattedEndDate = formatDate(endDate, 'DD/MM/YYYY', 'YYYY-MM-DD');
  return `&created_at_after=${formattedStartDate}&created_at_before=${formattedEndDate}`;
}
