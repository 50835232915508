import { captureException } from 'shared/sentryClient';
import {
  CREDIT_CARD_LIST,
  CREDIT_CARD_NAMES,
  REGEX_SECURITY_CODE,
} from '../constants/creditCard';
import { removeWhitespaces } from './strings';
import { image } from './urlGetter';

export const getCreditCardNameByNumber = (cardNumber: string) => {
  let i = 0;
  for (i; i < CREDIT_CARD_LIST.length; ) {
    const creditcard = CREDIT_CARD_LIST[i];
    if (!creditcard.regex.test(cardNumber)) {
      i += 1;
    } else {
      return creditcard.name;
    }
  }
  return null;
};

export const isCardNumberValid = (cardNumber: string) => {
  // https://en.wikipedia.org/wiki/Luhn_algorithm
  const cardNumberWithoutWhitespace = removeWhitespaces(cardNumber);
  let { length } = cardNumberWithoutWhitespace;
  let parity = 0;
  const luhnOddLookup = [
    [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    [0, 2, 4, 6, 8, 1, 3, 5, 7, 9],
  ];
  let sum = 0;

  while (length) {
    length -= 1;
    sum +=
      luhnOddLookup[parity][
        parseInt(cardNumberWithoutWhitespace.charAt(length), 10)
      ];
    // eslint-disable-next-line no-bitwise
    parity ^= 1;
  }

  return sum % 10 === 0 && sum > 0;
};

export const isSecurityCodeValid = (
  cvv: string,
  { number }: { number: string },
) => {
  const brand = getCreditCardNameByNumber(number);
  const numberLength = brand === CREDIT_CARD_NAMES.amex ? 4 : 3;
  const regex = REGEX_SECURITY_CODE(numberLength);
  return regex.test(cvv);
};

export const isNameValid = (value: string) => !/\d|[^\w\s]/g.test(value);

export const isExpirationDateValid = (monthYear: string) => {
  const [month, year] = monthYear.split('/');

  const parsedMonth = parseInt(month, 10);
  const parsedYear = parseInt(year, 10);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  if (Number.isNaN(parsedMonth) || Number.isNaN(parsedYear)) {
    return false;
  }
  if (parsedYear === currentYear && parsedMonth < currentMonth) {
    return false;
  }
  if (parsedMonth < 1 || parsedMonth > 12) {
    return false;
  }
  if (
    parsedYear < currentYear ||
    (parsedYear === currentYear && parsedMonth < currentMonth)
  ) {
    return false;
  }

  return !(parsedYear < 1000 || parsedYear >= 3000);
};

export const getCardImagePath = (cardNumberString?: string | null) => {
  const defaultCardImagePath = 'card';
  if (cardNumberString) {
    const numberWithoutWhitespace: string = removeWhitespaces(cardNumberString);
    const cardName = getCreditCardNameByNumber(numberWithoutWhitespace);
    return cardName === null ? defaultCardImagePath : cardName.toLowerCase();
  }
  return defaultCardImagePath;
};

export const getCardImage = (number: string, path = 'cards') => {
  try {
    return image(`${path}.${getCardImagePath(number)}`);
  } catch (err) {
    captureException(err);
    return image(`cards.card`);
  }
};

export const getBoletoImage = () => {
  try {
    return image('boleto.logo');
  } catch (err) {
    captureException(err);
    return '';
  }
};

export const getCardImageFlag = (flag: string) => {
  try {
    return image(`cards.${flag}`);
  } catch (err) {
    captureException(err);
    return image('cards.card');
  }
};
