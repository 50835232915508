import { combineReducers } from 'redux';

import addon from './addon/reducer';
import subscription from './subscription/reducer';
import { AddonState } from './addon/types';
import { SubscriptionState } from './subscription/types';

export type BillingState = {
  addon: AddonState;
  subscription: SubscriptionState;
};

const billingReducer = combineReducers<BillingState>({
  addon,
  subscription,
});

export default billingReducer;
