import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@iclinic/design-system';
import { verifyPaymentDateEdit, toggleDialog } from '../state/reducer';
import { getCanEdit } from '../state/selectors';
import { DialogEditPayment } from '../containers/DialogEditPayment';
import { ChangeSnackBar } from './ChangeSnackbar';

export const EditPaymentDate = () => {
  const dispatch = useDispatch();
  const canEdit = useSelector(getCanEdit);

  useEffect(() => {
    dispatch(verifyPaymentDateEdit());
  }, [dispatch]);

  if (!canEdit) return null;

  const onClickHandler = () => {
    dispatch(toggleDialog());
  };

  return (
    <>
      <ChangeSnackBar />
      <Button
        onClick={onClickHandler}
        size="small"
        data-ga="setup_subscription_chage_due_date_click"
        color="secondary"
      >
        Alterar data de vencimento
      </Button>
      <DialogEditPayment />
    </>
  );
};
