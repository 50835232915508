/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { Grid, SectionTitle, MaterialIcons } from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';
import { FILE_EXTENSIONS } from 'shared/utils/file';
import { ErrorAlert } from 'shared/components';
import { trackSignupIClinicPayForm } from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { SellerDocuments } from 'features/onlinePayment/state/config/types';
import {
  SellerStatus,
  SellerType,
} from 'features/onlinePayment/state/constants';
import {
  getUserInfo,
  getDocumentsError,
  isSellerCreationDone,
} from 'features/onlinePayment/state/config/register/selectors';
import { ResponsiveCard } from 'features/onlinePayment/components';
import Subtitle from '../Subtitle';
import Upload, { UploadData } from '../Upload';

const { NoteAdd } = MaterialIcons;

const { JPEG, JPG, PNG, PDF } = FILE_EXTENSIONS;
const documentExtensions = [JPEG, JPG, PNG, PDF];

type Props = {
  type?: SellerType;
  status?: SellerStatus;
};

export default function CardDocuments({ type, status }: Props): JSX.Element {
  const { setFieldValue, values, errors, touched } =
    useFormikContext<SellerDocuments>();
  const userData = useSelector(getUserInfo);
  const [sellerDone, error] = [
    useSelector(isSellerCreationDone),
    useSelector(getDocumentsError),
  ];
  const disabled = sellerDone && status !== SellerStatus.Refused;

  function handleUploadIdentity(file: UploadData) {
    setFieldValue('identity', file.file);
    trackSignupIClinicPayForm({
      userData,
      flowType: type || '',
      stepName: 'user_identity_doc',
      stepNum: type === SellerType.PF ? 15 : 19,
      stepValue: file.file.name,
    });
  }

  function handleUploadCnpj(file: UploadData) {
    setFieldValue('cnpj', file.file);
    trackSignupIClinicPayForm({
      userData,
      flowType: 'legal_person',
      stepName: 'user_proof_professional_activity',
      stepNum: 20,
      stepValue: file.file.name,
    });
  }

  function handleUploadResidence(file: UploadData) {
    setFieldValue('residence', file.file);
    trackSignupIClinicPayForm({
      userData,
      flowType: type || '',
      stepName: 'user_proof_address',
      stepNum: type === SellerType.PF ? 16 : 21,
      stepValue: file.file.name,
    });
  }

  return (
    <ResponsiveCard variant="outlined">
      <SectionTitle icon={<NoteAdd />} title="Documentos" mb={1} />
      <Grid direction="column" spacing={3} container>
        <Grid item>
          <Subtitle>
            Utilize os campos abaixo para enviar as fotos dos seus documentos
          </Subtitle>
        </Grid>
        {error && (
          <Grid item>
            <ErrorAlert
              message={<span dangerouslySetInnerHTML={{ __html: error }} />}
              name="documents-error"
              scrollToElement
            />
          </Grid>
        )}
        <Grid item>
          <Upload
            icon={
              <img
                alt="Documento de identidade com foto"
                src={image('onlinePayment.document')}
                aria-hidden
              />
            }
            placeholder="Anexe um documento de identidade com foto.(jpg,png,pdf)"
            value={values.identity}
            onChange={handleUploadIdentity}
            error={!!(errors.identity && touched.identity)}
            helperText={touched.identity && errors.identity}
            extensions={documentExtensions}
            disabled={disabled}
            name="identity"
          />
        </Grid>
        {type === SellerType.PJ && (
          <Grid item>
            <Upload
              icon={
                <img
                  alt="Cartão do CNPJ ou Contrato social da empresa"
                  src={image('onlinePayment.cnpj')}
                  aria-hidden
                />
              }
              placeholder="Cartão do CNPJ ou Contrato social da empresa.(jpg,png,pdf)"
              onChange={handleUploadCnpj}
              value={values.cnpj}
              error={!!(errors.cnpj && touched.cnpj)}
              helperText={touched.cnpj && errors.cnpj}
              extensions={documentExtensions}
              disabled={disabled}
              name="cnpj"
            />
          </Grid>
        )}
        <Grid item>
          <Upload
            icon={
              <img
                alt="Comprovante de residência"
                src={image('onlinePayment.proofOfAddress')}
                aria-hidden
              />
            }
            placeholder="Anexe um comprovante de residência.(jpg,png,pdf)"
            onChange={handleUploadResidence}
            value={values.residence}
            error={!!(errors.residence && touched.residence)}
            helperText={
              (touched.residence && errors.residence) ||
              'Mesmo endereço preenchido anteriormente'
            }
            extensions={documentExtensions}
            disabled={disabled}
            name="residence"
          />
        </Grid>
      </Grid>
    </ResponsiveCard>
  );
}
