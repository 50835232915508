const LABELS = {
  attest: 'Atestado',
  prescription: 'Prescrições',
  record: 'Prontuário',
  ophthalmic: 'Prescrição de óculos',
  exam_request: 'Exames e procedimentos',
  budget: 'Orçamento',
};

export default LABELS;
