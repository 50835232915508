// External
import React from 'react';
import { ContentState } from 'draft-js';

type LinkProps = {
  entityKey: string,
  contentState: ContentState,
  children: React.ReactNode,
};

const Link = ({
  contentState,
  entityKey,
  children,
}: LinkProps): JSX.Element => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      href={url}
      aria-label={url}
    >
      {children}
    </a>
  );
};

export default Link;
