import { MaterialUICore, tokens, axiomTokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const { color, font } = tokens;

type Props = {
  isErrorVariant: boolean;
};

export default makeStyles<{}, Props>(() => ({
  root: {
    '& .MuiButton-root': {
      backgroundColor: `rgba( 0, 0, 0, ${axiomTokens.opacity.level['6']})`,
      minWidth: '48px',
      height: '48px',
      padding: '0',
      position: 'relative',
      transition: 'border 400ms ease-in-out',
      '&:hover': {
        '& $buttonLabel': {
          bottom: '-1.5em',
          opacity: 1,
        },
      },
    },
  },
  buttonLabel: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    opacity: 0,
    transition: 'opacity 200ms ease-out, bottom 300ms ease-out',
    fontSize: font.size.xxxs,
  },
  updateOn: {
    width: '8px',
    height: '8px',
    position: 'absolute',
    left: '32px',
    top: '7px',
    borderRadius: '50%',
    backgroundColor: color.primary.light,
  },
  switchedOn: {
    color: color.neutral[0],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'transparent',
    '&:hover': {
      borderColor: color.neutral[0],
    },
  },
  switchedOff: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: ({ isErrorVariant }) =>
      isErrorVariant ? color.feedback.error.main : color.primary.light,
    color: ({ isErrorVariant }) =>
      isErrorVariant ? color.feedback.error.main : color.primary.light,
  },
}));
