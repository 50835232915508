import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import {
  Box,
  Container,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';

import {
  CardAccountData,
  CardDocuments,
  CardEmails,
  Header,
  CardAddressEdit,
} from 'features/onlinePayment/components/config';
import ReadOnlyCardProfile from 'features/onlinePayment/components/config/profile/read-only/ReadOnlyCardProfile';
import { getSelectedSellerDocumentProof } from 'features/onlinePayment/state/config/register/selectors';
import { getSellerEditSelector } from 'features/onlinePayment/state/config/edit/selectors';
import {
  initSettings,
  saveSellerEditing,
} from 'features/onlinePayment/state/config/edit';
import { SellerEditForm } from 'features/onlinePayment/state/config/types';
import * as S from 'features/onlinePayment/components/config/Fab';
import SuccessSnackbar from 'features/onlinePayment/components/manage/SuccessSnackbar';
import { SellerStatus } from 'features/onlinePayment/state/constants';
import { ErrorAlert } from 'shared/components';
import { ScrollToErrorFormik, useToggleBodyClass } from 'shared/hooks';
import { validationSellerEdit } from './validationSchema';
import {
  getSellerEditInitialData,
  getSellerEditInitialErrors,
  getSellerEditInitialTouched,
} from './form.normalizer';
import useStyles from '../../styles';
import CardCreditCard from 'features/onlinePayment/components/config/card/CardCreditCard';
import { SELLER_REFUSED_INFO } from 'features/onlinePayment/state/config/errorMessages';

const { Check } = MaterialIcons;
const { useTheme, CircularProgress } = MaterialUICore;

const SellerEdit = () => {
  const dispatch = useDispatch();
  const sellerDocuments = useSelector(getSelectedSellerDocumentProof);
  const [showNotification, setShowNotification] = useState(false);
  const theme = useTheme();
  const classes = useStyles();

  const {
    saving,
    fetching,
    sellerEmailsSettings,
    seller,
    sellerBankAccount,
    settingsSavedSuccessfully,
    bankAccountFetchError,
    emailFetchError,
  } = useSelector(getSellerEditSelector);

  useEffect(() => {
    if (settingsSavedSuccessfully) {
      setShowNotification(true);
    }
  }, [settingsSavedSuccessfully]);

  useEffect(() => {
    if (seller) {
      dispatch(initSettings(seller));
    }
  }, [dispatch, seller]);

  useToggleBodyClass(classes.whiteBody, theme.breakpoints.down('sm'));

  if (fetching || !seller) {
    return null;
  }

  const initialValues = getSellerEditInitialData({
    sellerEmailsSettings,
    seller,
    sellerBankAccount,
  });
  const initialErrors = getSellerEditInitialErrors(sellerDocuments);
  const initialTouched = getSellerEditInitialTouched(initialErrors);

  const hasSellerRefused = seller?.status === SellerStatus.Refused;

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialErrors}
      initialTouched={initialTouched}
      validationSchema={validationSellerEdit}
      enableReinitialize
      onSubmit={(sellerEditForm: SellerEditForm) => {
        dispatch(saveSellerEditing(sellerEditForm));
      }}
    >
      <Form>
        <Container maxWidth="md">
          <Header sellerType={seller?.type!} />
          {hasSellerRefused && (
            <Box maxWidth="md" mb={2}>
              <ErrorAlert
                message={
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: SELLER_REFUSED_INFO,
                    }}
                  />
                }
              />
            </Box>
          )}
          <Box mb={2}>
            <ReadOnlyCardProfile />
          </Box>

          {seller?.status === SellerStatus.Approved && (
            <Box mb={2}>
              <CardAddressEdit address={seller.address} />
            </Box>
          )}

          {(seller?.status === SellerStatus.Refused ||
            seller?.status === SellerStatus.Deleted) && (
            <Box mb={2}>
              <CardDocuments type={seller.type} status={seller.status} />
            </Box>
          )}
          <Box mb={2}>
            {bankAccountFetchError ? (
              <ErrorAlert
                message={
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: bankAccountFetchError,
                    }}
                  />
                }
              />
            ) : (
              seller?.status !== SellerStatus.Submitted && <CardAccountData />
            )}
          </Box>
          <Box mb={2}>
            {seller?.status !== SellerStatus.Submitted && <CardCreditCard />}
          </Box>
          {emailFetchError ? (
            <ErrorAlert
              message={
                <span
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: emailFetchError,
                  }}
                />
              }
            />
          ) : (
            <CardEmails />
          )}
          {showNotification && (
            <SuccessSnackbar
              message="Configuração salva com sucesso"
              onClose={() => setShowNotification(false)}
            />
          )}

          <S.FloatingWrapper>
            <S.Fab type="submit" disabled={saving}>
              {saving ? (
                <CircularProgress size={20} disableShrink />
              ) : (
                <Check />
              )}
              <span>Salvar alterações</span>
            </S.Fab>
          </S.FloatingWrapper>
          <ScrollToErrorFormik />
        </Container>
      </Form>
    </Formik>
  );
};

export default SellerEdit;
