import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPaymentSlipLink } from './state';
import { getLink } from './state/selectors';
import { Button } from '@iclinic/design-system';

const PaymentSlipLink = () => {
  const dispatch = useDispatch();
  const paymentSlipUrl = useSelector(getLink);

  useEffect(() => {
    dispatch(fetchPaymentSlipLink());
  }, [dispatch]);

  if (!paymentSlipUrl) return null;

  const onClickHandler = () => window.open(paymentSlipUrl, '_blank');

  return (
    <Button
      data-ga="setup_subscription_bankslip_click"
      color="secondary"
      onClick={onClickHandler}
    >
      Visualizar 2ª via do boleto
    </Button>
  );
};

export default PaymentSlipLink;
