import * as Yup from 'yup';

export const required = 'Campo obrigatório';
export const validEmail = 'Digite um e-mail válido';
export const acceptenceMissing =
  'É necessário confirmar a leitura dos termos e políticas.';

export const passwordMatch = 'As senha não coencidem';

type Email = {
  password: string;
  confirmPassword: string;
  hasAcceptedTerms: boolean;
};

const validationSchema = Yup.object<Email>({
  password: Yup.string().required(required),
  confirmPassword: Yup.string()

    // eslint-disable-next-line func-names
    .test('confirm-email', passwordMatch, function (value) {
      const { password } = this.parent;
      return value === password;
    })
    .required(required),
  hasAcceptedTerms: Yup.boolean().oneOf([true], acceptenceMissing),
});

export default validationSchema;

export const initialValues = {
  password: '',
  confirmPassword: '',
  hasAcceptedTerms: false,
};

export type InitialValues = typeof initialValues;
