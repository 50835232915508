// External
import React, { useMemo } from 'react';
import { Panel, Title } from '@iclinic/iclinic-ui';

// Internal
import {
  tableBodyEmptyTextCustom,
  tableColumnsCustom,
  tableColumnKeys,
  Align,
} from 'state/marketing/emailFlow/emailFlowManagement/constants';
import Table from 'ui/new/table';

import style from './ListEmailFlow.scss';

type ListEmailFlowCustomProps = {
  children?: React.ReactNode,
  emailFlowInformation: string;
  searchEmailFlow: React.ReactNode;
};

const tableWidthTitle = {
  [tableColumnKeys.title]: style.column__title,
  [tableColumnKeys.actions]: style.columnSize__actions,
  [tableColumnKeys.finishedPatients]: style.columnSize__finishedFlow,
  [tableColumnKeys.activePatients]: style.columnSize__patientsActive,
};

const tableTitleAlign = {
  [tableColumnKeys.title]: Align.left,
  [tableColumnKeys.activePatients]: Align.right,
  [tableColumnKeys.finishedPatients]: Align.right,
  [tableColumnKeys.actions]: Align.left,
};

const ListEmailFlowCustom = ({
  children,
  emailFlowInformation,
  searchEmailFlow,
}: ListEmailFlowCustomProps): JSX.Element => {
  const tableHeaders = useMemo(() => [
    tableColumnsCustom.map(({ key, label }) => (
      <Table.Item
        align={Align[tableTitleAlign[key]]}
        key={key}
        classes={tableWidthTitle[key]}
      >
        {label}
      </Table.Item>
    )),
  ], []);

  const infoAndSearch = (
    <div>
      <p className={style.customEmailFlowInformation}>{emailFlowInformation}</p>
      {searchEmailFlow}
    </div>
  );

  return (
    <div className={style.listEmailFlowContainer}>
      <Panel title={infoAndSearch}>
        <Table>
          <Table.Head>
            <Table.Row>
              {tableHeaders}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {children}
          </Table.Body>
        </Table>
        {!children
          && <Title className={style.tableEmpty}>{tableBodyEmptyTextCustom}</Title>
        }
      </Panel>
    </div>
  );
};

export default ListEmailFlowCustom;
