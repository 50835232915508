import { Grid, MaterialIcons, MaterialUICore } from '@iclinic/design-system';
import React, { forwardRef } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import CollapsibleFormSection from 'features/tissInvoicing/components/ui/CollapsibleFormSection';
import { getAutocomplete } from 'features/tissInvoicing/state/autocomplete/selectors';
import SelectField from 'features/tissInvoicing/components/appointmentGuide/SelectField';
import TextField from 'features/tissInvoicing/components/appointmentGuide/TextField';
import { AppointmentGuideFormValues } from 'features/tissInvoicing/types/appointmentGuide';

const { MenuItem } = MaterialUICore;

const { PlaylistAddCheck } = MaterialIcons;
interface GeneralSectionProps {}

const GeneralSection = (
  _: GeneralSectionProps,
  ref: React.Ref<HTMLButtonElement>,
) => {
  const { setFieldValue } = useFormikContext<AppointmentGuideFormValues>();
  const { ans_registers } = useSelector(getAutocomplete);

  const handleChange = (value: string) => {
    const getAnsRegister = ans_registers.find(
      (item) => item.health_insurance_register === value,
    );

    if (getAnsRegister) {
      setFieldValue('insurance_name', getAnsRegister.health_insurance_name);
      setFieldValue('insurance_id', getAnsRegister.id);
    }
  };

  return (
    <CollapsibleFormSection
      sectionIdentifier="generalSection"
      sectionTitle="Dados Gerais"
      sectionIcon={<PlaylistAddCheck />}
      ref={ref}
    >
      <Grid container item spacing={2}>
        <Grid item xs={12} md={4}>
          <SelectField
            label="1 - Registro ANS"
            identifier="insurance_ans_register"
            handleChangeInput={handleChange}
            required
          >
            {ans_registers.map((ansRegister) => (
              <MenuItem
                value={ansRegister.health_insurance_register}
                key={ansRegister.health_insurance_register}
              >
                {`${ansRegister.health_insurance_register} - ${ansRegister.health_insurance_name}`}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="2 - Número da guia"
            identifier="guide_number"
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="3 - Número da guia na operadora"
            identifier="main_guide_number"
            required
          />
        </Grid>
      </Grid>
    </CollapsibleFormSection>
  );
};
export default forwardRef(GeneralSection);
