import React, { useMemo } from 'react';
import { useRifm } from 'rifm';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  SectionTitle,
  MaterialIcons,
  Grid,
  TextField,
  Link,
} from '@iclinic/design-system';
import { cepFormatter } from 'shared/utils/formatters';
import { RequestSamplesFormData, AutoFillMapData } from './types';
import { SelectStateField } from 'features/free-samples/components';
import {
  useAddressFormErrorTracking,
  useAutoFillFields,
} from 'features/free-samples/hooks';
import { trackRequestSamplesFormStep } from 'features/free-samples/trackRequestSamplesFormUtils';
import {
  getUserInfo,
  getZipCodeSelector,
  getAddress,
} from 'features/free-samples/state/request-samples/selectors';
import {
  fetchValidateAvailabilityByZipCode,
  fetchZipCode,
} from 'features/free-samples/state/request-samples';

interface SectionAddressProps {
  boxId?: string;
}

const { Room } = MaterialIcons;

export default function SectionAddress({
  boxId,
}: SectionAddressProps): JSX.Element {
  const dispatch = useDispatch();
  const { zipCodeInfo } = useSelector(getZipCodeSelector);
  const addressData = useSelector(getAddress);
  const userInfo = useSelector(getUserInfo);

  const {
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext<RequestSamplesFormData>();

  const addressValues = useMemo<AutoFillMapData>(
    () => ({
      zipCode: cepFormatter(addressData?.zip_code || ''),
      address: addressData?.address,
      number: addressData?.number,
      complement: addressData?.complement,
      neighborhood: addressData?.neighborhood,
      state: addressData?.state,
      city: addressData?.city,
    }),
    [addressData],
  );

  const zipCodeValues = useMemo<AutoFillMapData>(
    () => ({
      zipCode: cepFormatter(zipCodeInfo?.zip_code || ''),
      address: zipCodeInfo?.address,
      number: zipCodeInfo?.number,
      complement: zipCodeInfo?.complement,
      neighborhood: zipCodeInfo?.neighborhood,
      state: zipCodeInfo?.state,
      city: zipCodeInfo?.city,
    }),
    [zipCodeInfo],
  );

  useAutoFillFields(userInfo, addressValues);
  useAutoFillFields(userInfo, zipCodeValues);

  const { value: zipCode, onChange: onChangeZipCode } = useRifm({
    value: values.zipCode,
    onChange: (value: string) => {
      setFieldValue('zipCode', value);
      if (value.length === 9) {
        dispatch(fetchZipCode(value));
        dispatch(
          fetchValidateAvailabilityByZipCode({
            zipCode: value,
            boxId: Number(boxId),
          }),
        );
      }
    },
    format: cepFormatter,
  });

  useAddressFormErrorTracking(errors, touched, userInfo);

  return (
    <Box>
      <SectionTitle icon={<Room />} title="Endereço da clínica para entrega" />
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <TextField
            id="zipCode"
            name="zipCode"
            label="CEP *"
            value={zipCode}
            onChange={onChangeZipCode}
            autoComplete="none"
            error={!!(errors.zipCode && touched.zipCode)}
            helperText={touched.zipCode && errors.zipCode}
            fullWidth
            onBlur={() => {
              trackRequestSamplesFormStep({
                userInfo,
                stepName: 'user_address_postal_code',
                stepNum: 6,
              });
            }}
          />
        </Grid>

        <Grid item xs={6} sm={9}>
          <Box display="flex" alignItems="center" height={1}>
            <Link
              href="https://buscacepinter.correios.com.br/"
              target="_blank"
              variant="body2"
              bold
              onClick={() => {
                trackRequestSamplesFormStep({
                  userInfo,
                  stepName: 'link_check_postal_code',
                  stepNum: 6,
                });
              }}
            >
              Não sei meu CEP
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} lg={7}>
          <TextField
            id="address"
            name="address"
            label="Endereço *"
            value={values.address}
            onChange={handleChange}
            error={!!(errors.address && touched.address)}
            helperText={touched.address && errors.address}
            fullWidth
            onBlur={() => {
              trackRequestSamplesFormStep({
                userInfo,
                stepName: 'user_address_name',
                stepNum: 7,
              });
            }}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <TextField
            id="number"
            name="number"
            label="Número *"
            value={values.number}
            onChange={handleChange}
            error={!!(errors.number && touched.number)}
            helperText={touched.number && errors.number}
            fullWidth
            onBlur={() => {
              trackRequestSamplesFormStep({
                userInfo,
                stepName: 'user_address_number',
                stepNum: 8,
              });
            }}
          />
        </Grid>
        <Grid item xs={8} sm={4} lg={3}>
          <TextField
            id="complement"
            name="complement"
            label="Complemento (opcional)"
            value={values.complement}
            onChange={handleChange}
            error={!!(errors.complement && touched.complement)}
            helperText={touched.complement && errors.complement}
            fullWidth
            onBlur={() => {
              trackRequestSamplesFormStep({
                userInfo,
                stepName: 'user_address_complement',
                stepNum: 9,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5} lg={4}>
          <TextField
            id="neighborhood"
            name="neighborhood"
            label="Bairro *"
            value={values.neighborhood}
            onChange={handleChange}
            error={!!(errors.neighborhood && touched.neighborhood)}
            helperText={touched.neighborhood && errors.neighborhood}
            fullWidth
            onBlur={() => {
              trackRequestSamplesFormStep({
                userInfo,
                stepName: 'user_address_neighborhood',
                stepNum: 10,
              });
            }}
          />
        </Grid>
        <Grid item xs={4} sm={2}>
          <SelectStateField
            id="state"
            name="state"
            label="UF *"
            value={values.state}
            error={!!(errors.state && touched.state)}
            helperText={touched.state && errors.state}
            onBlur={() => {
              trackRequestSamplesFormStep({
                userInfo,
                stepName: 'user_address_state',
                stepNum: 11,
              });
            }}
          />
        </Grid>
        <Grid item xs={8} sm={5} lg={6}>
          <TextField
            id="city"
            name="city"
            label="Cidade *"
            value={values.city}
            onChange={handleChange}
            error={!!(errors.city && touched.city)}
            helperText={touched.city && errors.city}
            fullWidth
            onBlur={() => {
              trackRequestSamplesFormStep({
                userInfo,
                stepName: 'user_address_city',
                stepNum: 12,
              });
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
