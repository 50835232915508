import {
  MaterialUICore,
  tokens,
  Box,
  TextField as TextFieldDS,
} from '@iclinic/design-system';

const { styled, IconButton: IconButtonDS } = MaterialUICore;
const { spacing, color } = tokens;

export const ChatInputContainer = styled(Box)(() => ({
  padding: spacing.sm,
}));

export const TextField = styled(TextFieldDS)(() => ({
  '& .MuiInputBase-input': {
    padding: 0,
  },
  '& .MuiFilledInput-multiline': {
    padding: `${spacing.sm} ${spacing.nano}`,
    height: 'auto',
  },
}));

export const IconButton = styled(IconButtonDS)(() => ({
  color: color.neutral[500],
}));

export const Input = styled('input')(() => ({
  display: 'none',
}));
