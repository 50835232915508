// External
import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, isInvalid } from 'redux-form';
import { connect } from 'react-redux';

// Internal
import { FORM_ADD_CAMPAIGN_MODAL } from 'shared/constants/forms';
import Modal from 'ui/new/modal';
import Button from 'ui/new/button';
import { Input } from 'ui/new/form';
import {
  getAddCampaignModalTitle,
  getAllCampaignsTitles,
} from 'state/campaign/selectors';
import style from './AddCampaignModal.scss';

const AddCampaignModal = ({
  handleSubmit,
  onCloseClick,
  onAddCampaign,
  isEmpty,
  campaignTitles,
  invalid,
}) => {
  const validateDuplicateTitle = React.useCallback(
    (value) => {
      if (campaignTitles.find((title) => value === title)) {
        return 'Título já existe';
      }
      return undefined;
    },
    [campaignTitles],
  );

  return (
    <form onSubmit={handleSubmit(onAddCampaign)}>
      <div className={style.modal}>
        <Modal
          title="Nome da campanha"
          onClose={onCloseClick}
          show
          classes={style}
          width={440}
        >
          <Modal.Body>
            <p>Defina um nome para a campanha</p>
            <Field
              component={Input}
              type="text"
              name="title"
              placeholder="Ex: Promoção de aniversário da clínica"
              validate={validateDuplicateTitle}
            />
          </Modal.Body>
          <Modal.Footer align="right">
            <Button type="light" onClick={onCloseClick}>
              CANCELAR
            </Button>
            <Button type="primary" submit disabled={isEmpty || invalid}>
              SALVAR
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </form>
  );
};

AddCampaignModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  onAddCampaign: PropTypes.func.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  campaignTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state, { title }) => ({
  initialValues: { title },
  isEmpty: !getAddCampaignModalTitle(state),
  campaignTitles: getAllCampaignsTitles(state),
  invalid: isInvalid(FORM_ADD_CAMPAIGN_MODAL),
});

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_ADD_CAMPAIGN_MODAL,
  })(AddCampaignModal),
);
