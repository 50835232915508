// Internal
import {
  successViews,
  successAddonViews,
  SuccessViews,
  SuccessAddonViews,
} from '../constants/views';

export const getSuccessViews = (view: SuccessViews) => successViews[view];
export const getSuccessAddonViews = (view: SuccessAddonViews) => successAddonViews[view];
