import { apiTelemedicine } from 'features/video-conference/services/api';

export type FetchRating = {
  conferenceId: number;
  patientId: number;
  physicianId: number;
  score: number;
  scoreComment: string | null;
  sentBy: string;
  dateTime: string;
};

export const sendRating = (data: FetchRating, onError: ErrorCallback) =>
  apiTelemedicine.post('conference/score', data).catch(onError);
