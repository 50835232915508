// External
import React from 'react';
import { Field } from 'redux-form';

// Internal
import { required, number, positive } from 'shared/utils/formValidation';
import { Column } from 'ui/new/flex';
import { Input } from 'ui/new/form';

import FieldInputSearch from 'features/stock/components/FieldInputSearch';
import FieldOutputDropdown from 'features/stock/components/FieldOutputDropdown';
import { OutputRenderProps } from 'features/stock/containers/EnhanceStockOutputInventoryForms';

import style from './style.scss';

export default function Output({
  handleSubmit,
  onChangeProductDescription,
  setLotIsOpen,
  productDescription = '',
  productItems = null,
  lotDescription = '',
  selectedProduct = null,
  lotItems = null,
  lotIsOpen = false,
}: OutputRenderProps): JSX.Element {
  return (
    <div onSubmit={handleSubmit} className={style.body}>
      <Column>
        <Column className={style.search}>
          <label htmlFor="productDescription">Pesquisar Produto</label>
          <Field
            name="productDescription"
            id="productDescription"
            component={FieldInputSearch}
            productItems={productItems}
            productDescription={productDescription}
            selectedProduct={selectedProduct}
            validate={[required]}
            onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
              onChangeProductDescription(target.value)
            }
            autoFocus
          />
        </Column>
        {!!selectedProduct && selectedProduct.hasLotControl && (
          <Column className={style.lot}>
            <label htmlFor="dropdown">Lote</label>
            <Field
              component={FieldOutputDropdown}
              lotDescription={lotDescription}
              lotIsOpen={lotIsOpen}
              setLotIsOpen={setLotIsOpen}
              lotItems={lotItems}
              validate={[required]}
              name="lotId"
            />
          </Column>
        )}
        <Column className={style.output}>
          <label htmlFor="unit">Quant.</label>
          <div className={style.output__row}>
            <div className={style.output__input}>
              <Field
                component={Input}
                name="quantity"
                validate={[required, number, positive]}
                type="number"
                min="1"
              />
            </div>
            <span className={style.output__text}>Unidades</span>
          </div>
        </Column>
        <Column className={style.observation}>
          <label htmlFor="observation">Observações (opcional)</label>
          <Field component={Input} name="observation" />
        </Column>
      </Column>
    </div>
  );
}
