import { all, fork } from 'redux-saga/effects';

import commonSagas from 'features/messaging/state/common/sagas';
import messageHistorySagas from 'features/messaging/state/messageHistory/sagas';
import scheduleConfirmationSagas from 'features/messaging/state/scheduleConfirmation/sagas';
import messagePackageSagas from './messagePackage/sagas';
import scheduleReminderSagas from './scheduleReminder/sagas';

export default function* messagingSagas() {
  yield all([
    fork(commonSagas),
    fork(messageHistorySagas),
    ...scheduleConfirmationSagas,
    ...scheduleReminderSagas,
    ...messagePackageSagas,
  ]);
}
