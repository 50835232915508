import { Response } from '@types';
import { call, put, takeLatest } from 'redux-saga/effects';

import { captureException } from 'shared/utils/handlerErrors';
import {
  ActionKeys,
  AnsRegisterData,
  Autocomplete,
  AutocompleteInput,
  CBOAutocompleteEntry,
  GuideVersionData,
  HiredSolicitantCodeEntry,
  ProcedureAutocompleteEntry,
  PhysicianAutocompleteEntry,
  ClinicAutocompleteEntry,
} from 'features/tissInvoicing/types';
import { PatientParams } from 'features/tissInvoicing/types/sadt/patients';
import {
  fetchAnsRegistersAutocomplete,
  fetchCBOAutocomplete,
  fetchGuideVersionsAutocomplete,
  fetchPatientsAutocomplete,
  fetchProceduresAutocomplete,
  fetchPhysiciansAutocomplete,
  getAutocompleteOptions,
  fetchClinicsAutocomplete,
  fetchAutocompletePatients,
} from 'features/tissInvoicing/services/common';
import { getHiredSolicitantData } from 'features/tissInvoicing/services/sadt';
import { actions } from '.';

type FetchAutocompleteOptionsAction = ReturnType<
  typeof actions.fetchAutocompleteOptions
>;

type FetchHiredSolicitantData = ReturnType<
  typeof actions.fetchHiredSolicitantData
>;

type FetchPatientsOptions = ReturnType<typeof actions.fetchPatientsOptions>;

type FetchExecutorsOptions = ReturnType<typeof actions.fetchExecutorsOptions>;

type FetchProceduresOptions = ReturnType<typeof actions.fetchProceduresOptions>;

type FetchPhysiciansOptions = ReturnType<typeof actions.fetchPhysiciansOptions>;

type FetchClinicsOptions = ReturnType<typeof actions.fetchClinicsOptions>;
type SearchPatients = ReturnType<typeof actions.searchPatients>;

export function* fetchAutocompleteOptionsWorker({
  payload: { eventId },
}: FetchAutocompleteOptionsAction) {
  try {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchAutocomplete,
        value: true,
      }),
    );

    const { data }: Response<Omit<Autocomplete, 'hired_solicitants'>> =
      yield call(getAutocompleteOptions, eventId);

    yield put(actions.setAutocompleteOptions(data));
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchAutocomplete,
        value: false,
      }),
    );
  }
}

export function* fetchHiredSolicitantDataWorker({
  payload: { insuranceId, solicitantId, type, callback },
}: FetchHiredSolicitantData) {
  try {
    const { data }: Response<HiredSolicitantCodeEntry> = yield call(
      getHiredSolicitantData,
      insuranceId.toString(),
      solicitantId,
      type,
    );

    yield put(actions.setHiredSolicitants(data));

    if (callback) callback(data);
  } catch (error) {
    captureException(error);
  }
}

export function* fetchGuideVersionsDataWorker() {
  try {
    const { data }: Response<GuideVersionData[]> = yield call(
      fetchGuideVersionsAutocomplete,
    );

    yield put(actions.setGuideVersionsOptions(data));
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchAutocomplete,
        value: false,
      }),
    );
  }
}

export function* fetchAnsRegistersDataWorker() {
  try {
    const { data }: Response<{ objects: AnsRegisterData[] }> = yield call(
      fetchAnsRegistersAutocomplete,
    );

    yield put(
      actions.setAnsRegistersOptions(
        data.objects.filter((item) => item.health_insurance_register),
      ),
    );
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchAutocomplete,
        value: false,
      }),
    );
  }
}

export function* fetchProceduresDataWorker({
  payload: { tableCode, searchName },
}: FetchProceduresOptions) {
  try {
    const { data }: Response<{ objects: ProcedureAutocompleteEntry[] }> =
      yield call(fetchProceduresAutocomplete, tableCode, searchName);

    yield put(actions.setProceduresOptions(data.objects));
  } catch (error) {
    captureException(error);
  }
}

export function* fetchPatientsDataWorker({
  payload: { name },
}: FetchPatientsOptions) {
  try {
    const { data }: Response<{ objects: AutocompleteInput[] }> = yield call(
      fetchPatientsAutocomplete,
      name,
    );

    yield put(actions.setPatientsOptions(data.objects));
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchAutocomplete,
        value: false,
      }),
    );
  }
}

export function* fetchExecutorsDataWorker({
  payload: { name },
}: FetchExecutorsOptions) {
  try {
    const { data }: Response<{ objects: PhysicianAutocompleteEntry[] }> =
      yield call(fetchPhysiciansAutocomplete, name);

    yield put(actions.setExecutorsOptions(data.objects));
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchAutocomplete,
        value: false,
      }),
    );
  }
}

export function* fetchCBODataWorker() {
  try {
    const { data }: Response<{ objects: CBOAutocompleteEntry[] }> = yield call(
      fetchCBOAutocomplete,
    );

    yield put(actions.setCboOptions(data.objects));
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchAutocomplete,
        value: false,
      }),
    );
  }
}

export function* fetchPhysiciansDataWorker({
  payload: { name },
}: FetchPhysiciansOptions) {
  try {
    const { data }: Response<{ objects: PhysicianAutocompleteEntry[] }> =
      yield call(fetchPhysiciansAutocomplete, name);

    yield put(actions.setPhysiciansOptions(data.objects));
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchAutocomplete,
        value: false,
      }),
    );
  }
}

export function* fetchClinicsDataWorker({
  payload: { name },
}: FetchClinicsOptions) {
  try {
    const { data }: Response<{ objects: ClinicAutocompleteEntry[] }> =
      yield call(fetchClinicsAutocomplete, name);

    yield put(actions.setClinicsOptions(data.objects));
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchAutocomplete,
        value: false,
      }),
    );
  }
}

export function* searchPatientsWorker({ payload: { name } }: SearchPatients) {
  try {
    const { data }: Response<{ objects: PatientParams[] }> = yield call(
      fetchAutocompletePatients,
      name || '',
    );

    yield put(
      actions.setPatientsAutocompleteOptions(
        data.objects.map((o) => ({
          id: o.id,
          civil_name: o?.civil_name || o?.name,
          social_name: o?.civil_name ? o?.name : '',
          sus_code: o.cns,
        })),
      ),
    );
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchAutocomplete,
        value: false,
      }),
    );
  }
}

export default function* watchSadtWorker() {
  yield takeLatest(
    actions.fetchAutocompleteOptions,
    fetchAutocompleteOptionsWorker,
  );
  yield takeLatest(
    actions.fetchHiredSolicitantData,
    fetchHiredSolicitantDataWorker,
  );
  yield takeLatest(
    actions.fetchGuideVersionsOptions,
    fetchGuideVersionsDataWorker,
  );
  yield takeLatest(actions.fetchPatientsOptions, fetchPatientsDataWorker);
  yield takeLatest(
    actions.fetchAnsRegistersOptions,
    fetchAnsRegistersDataWorker,
  );
  yield takeLatest(actions.fetchProceduresOptions, fetchProceduresDataWorker);
  yield takeLatest(actions.fetchExecutorsOptions, fetchExecutorsDataWorker);
  yield takeLatest(actions.fetchCboOptions, fetchCBODataWorker);
  yield takeLatest(actions.fetchPhysiciansOptions, fetchPhysiciansDataWorker);
  yield takeLatest(actions.fetchClinicsOptions, fetchClinicsDataWorker);
  yield takeLatest(actions.searchPatients, searchPatientsWorker);
}
