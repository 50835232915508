import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { actions } from '.';
import { captureException } from 'shared/utils/handlerErrors';
import { getPhysicianAndClinicId } from './selectors';
import { LastEventTodayResponse } from '../types';
import { getIsLastEventToday as getIsLastEventTodayService } from './services';
import { parseGetIsLastEventToday } from '../utils';

const {
  getIsLastEventToday,
  getIsLastEventTodaySuccess,
  getIsLastEventTodayFailed,
} = actions;

export function* workerGetIsLastEventToday(action: PayloadAction<string>) {
  try {
    const { physicianId, clinicId } = yield select(getPhysicianAndClinicId);
    const eventId = action.payload;
    const patient = {
      clinicId,
      physicianId,
      eventId,
    };
    const { getResponseData } = yield call(getIsLastEventTodayService, patient);

    const isLastEventToday: LastEventTodayResponse = getResponseData();

    const isLastEventTodayFormated = parseGetIsLastEventToday(isLastEventToday);

    yield put(getIsLastEventTodaySuccess(isLastEventTodayFormated));
  } catch (error) {
    yield call(captureException, error);
    yield put(getIsLastEventTodayFailed());
  }
}

export default function* isLastEventTodaySagas() {
  yield all([takeLatest(getIsLastEventToday, workerGetIsLastEventToday)]);
}
