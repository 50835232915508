import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { ZipCodeRawData } from 'services/iclinic/easyZipCode/types';
import { RequestSamplesFormData } from '../../containers/RequestSamplesForm/types';
import {
  RequestSamplesResponse,
  SampleBox,
  AddressData,
  ValidateAvailabilityByZipCodeParams,
  PersonVUCData,
  GetPersonVUCParams,
} from '../../services/types';

export interface RequestFreeSamplesState {
  boxStatus?: RequestStatus;
  box?: SampleBox;
  submitStatus?: RequestStatus;
  zipCode?: {
    status?: RequestStatus;
    data?: ZipCodeRawData;
    available?: boolean;
  };
  address?: AddressData;
  canJoinQueue?: boolean;
  requestId?: number;
  personVUC?: {
    status?: RequestStatus;
    data?: PersonVUCData;
  };
}

export const initialState: RequestFreeSamplesState = {
  boxStatus: RequestStatus.Pending,
};

export const nameStore = '@webapp/request-free-samples';

export const fetchSampleBox = createAction<number>(
  `${nameStore}/fetchSampleBox`,
);

export const triggerSubmitRequestSamples = createAction<RequestSamplesFormData>(
  `${nameStore}/triggerSubmitRequestSamples`,
);

export const fetchZipCode = createAction<string>(`${nameStore}/fetchZipCode`);

export const fetchValidateAvailabilityByZipCode = createAction<
  ValidateAvailabilityByZipCodeParams
>(`${nameStore}/fetchValidateAvailabilityByZipCode`);

export const fetchAddToRequestWaitingQueue = createAction<number>(
  `${nameStore}/fetchAddToRequestWaitingQueue`,
);

export const fetchPersonVUCData = createAction<GetPersonVUCParams>(
  `${nameStore}/fetchPersonVUCData`,
);

const RequestFreeSamplesSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchSampleBoxSuccess: (
      state,
      action: PayloadAction<{
        sampleBox: SampleBox;
        address: AddressData;
      }>,
    ): RequestFreeSamplesState => ({
      ...state,
      address: action.payload.address,
      boxStatus: RequestStatus.Success,
      box: action.payload.sampleBox,
    }),
    fetchSampleBoxFailure: (state): RequestFreeSamplesState => ({
      ...state,
      boxStatus: RequestStatus.Error,
    }),
    submitRequestSamples: (state): RequestFreeSamplesState => ({
      ...state,
      submitStatus: RequestStatus.Pending,
    }),
    submitRequestSamplesSuccess: (
      state,
      action: PayloadAction<RequestSamplesResponse>,
    ): RequestFreeSamplesState => ({
      ...state,
      submitStatus: RequestStatus.Success,
      canJoinQueue: action.payload.can_join_queue,
      requestId: action.payload.id,
    }),
    submitRequestSamplesFailure: (state): RequestFreeSamplesState => ({
      ...state,
      submitStatus: RequestStatus.Error,
    }),
    fetchZipCodeSuccess: (
      state,
      action: PayloadAction<ZipCodeRawData>,
    ): RequestFreeSamplesState => ({
      ...state,
      zipCode: {
        ...state.zipCode,
        status: RequestStatus.Success,
        data: action.payload,
      },
    }),
    fetchZipCodeFailure: (state): RequestFreeSamplesState => ({
      ...state,
      zipCode: {
        ...state.zipCode,
        status: RequestStatus.Error,
      },
    }),
    fetchValidateAvailabilityByZipCodeResult: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      zipCode: {
        ...state.zipCode,
        available: action.payload,
      },
    }),
    fetchValidateAvailabilityByZipCodeClean: (state) => ({
      ...state,
      zipCode: {
        ...state.zipCode,
        available: undefined,
      },
    }),
    cleanRequestSamples: () => initialState,
    fetchPersonVUCDataSuccess: (
      state,
      action: PayloadAction<PersonVUCData>,
    ): RequestFreeSamplesState => ({
      ...state,
      personVUC: {
        status: RequestStatus.Success,
        data: action.payload,
      },
    }),
    fetchPersonVUCDataFailure: (state) => ({
      ...state,
      personVUC: {
        ...state.personVUC,
        status: RequestStatus.Error,
      },
    }),
    cleanPersonVUCStatus: (state) => ({
      ...state,
      personVUC: {
        ...state.personVUC,
        status: undefined,
      },
    }),
  },
});

export const {
  fetchSampleBoxSuccess,
  fetchSampleBoxFailure,
  submitRequestSamples,
  submitRequestSamplesSuccess,
  submitRequestSamplesFailure,
  fetchZipCodeSuccess,
  fetchZipCodeFailure,
  fetchValidateAvailabilityByZipCodeResult,
  fetchValidateAvailabilityByZipCodeClean,
  cleanRequestSamples,
  fetchPersonVUCDataSuccess,
  fetchPersonVUCDataFailure,
  cleanPersonVUCStatus,
} = RequestFreeSamplesSlice.actions;

export default RequestFreeSamplesSlice.reducer;
