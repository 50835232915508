import React from 'react';

import {
  Button,
  Dialog,
  Heading,
  MaterialUICore,
} from '@iclinic/design-system';
import * as Styles from './styles';

const { DialogActions, DialogContent, DialogTitle } = MaterialUICore;

interface ButtonProps {
  text: string;
  handler: () => void;
}

interface TwoButtonDialogProps {
  leftButton?: ButtonProps;
  rightButton?: ButtonProps;
  title: string;
  content: JSX.Element;
  isOpen: boolean;
  closeHandler: () => void;
}

export default function TwoButtonDialog({
  leftButton,
  rightButton,
  title,
  content,
  isOpen,
  closeHandler,
}: TwoButtonDialogProps) {
  const classes = Styles.useStyles();

  return (
    <Dialog
      open={isOpen}
      onClose={closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle disableTypography>
        <Heading variant="md">{title}</Heading>
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContent }}>
        {content}
      </DialogContent>

      <DialogActions classes={{ root: classes.dialogActions }}>
        {leftButton && (
          <Button
            onClick={leftButton.handler}
            color="transparent"
            classes={{ root: classes.cancelButton }}
          >
            {leftButton.text}
          </Button>
        )}

        {rightButton && (
          <Button
            onClick={rightButton.handler}
            autoFocus
            classes={{ root: classes.primaryButton }}
          >
            {rightButton.text}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
