import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material/react-material-icon';
import ClassNames from 'classnames/bind';

import Item from './Item';
import style from './style.scss';

const classes = ClassNames.bind(style);

export default function InputSearch({
  placeholder,
  value,
  onChange,
  icon,
  minChars,
  children,
  addMore,
  input,
  open,
  meta: { touched, error, invalid },
  autoFocus,
}) {
  const hasMinCharsToSearch = value.length >= minChars;
  const readyToRenderDropdown = !!addMore || (hasMinCharsToSearch && children);

  return (
    <div className={style['search-form']}>
      <div
        className={classes('input-search', {
          'error-search': touched && invalid,
        })}
      >
        {icon && <Icon className={style.icon} icon={icon} />}
        <input
          autoFocus={autoFocus}
          className={style.content}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...input}
        />
      </div>
      <div className={style['message-text']}>
        <span className={style.error}>{touched && invalid && error}</span>
      </div>
      {readyToRenderDropdown && open && (
        <div className={style['dropdown-search']}>
          {!!addMore && addMore}
          {hasMinCharsToSearch && children}
        </div>
      )}
    </div>
  );
}

InputSearch.propTypes = {
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  minChars: PropTypes.number,
  addMore: PropTypes.node,
  open: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
  autoFocus: PropTypes.bool,
};

InputSearch.defaultProps = {
  onChange: () => null,
  placeholder: undefined,
  value: undefined,
  icon: 'dehaze',
  minChars: 3,
  addMore: null,
  input: null,
  open: false,
  meta: {
    touched: false,
    invalid: false,
    error: null,
  },
  autoFocus: false,
};

InputSearch.Item = Item;
