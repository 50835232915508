import compareVersions from 'features/tissInvoicing/utils/compareVersions';

/**
 * @returns isGreaterThan: ((currentVersion: number, baseVersion?: string) => void)
 * @returns isLessThan: ((currentVersion: number, baseVersion?: string) => void)
 * @param currentVersion - versão a ser comparada
 * @param baseVersion - versão que será comparada
 */
interface IUseCompareVersionReturn {
  isGreaterThan: (currentVersion: number, baseVersion?: string) => boolean;
  isLessThan: (currentVersion: number, baseVersion?: string) => boolean;
}
/**
 * Returns functions to verify if version is greater or less than another.
 *
 * @param {string} base_version
 * @example Use with parameter
 * const {isGreatherThan, isLessThan}=useCompareVersion('2.00.00')
 * isGreatherThan(3)
 * isLessThan(3)
 *
 * @example Use without parameter
 * const { isGreatherThan, isLessThan } = useCompareVersion()
 * isGreaterThan(3, '2.00.00')
 * isLessThan(3, '2.00.00')
 *
 */
const useVersionCompare = (versionToCompare = ''): IUseCompareVersionReturn => {
  if (!versionToCompare) {
    return {
      isGreaterThan: (currentVersion: number, baseVersion: string = '') =>
        compareVersions(baseVersion).isGreaterThan(currentVersion),
      isLessThan: (currentVersion: number, baseVersion: string = '') =>
        compareVersions(baseVersion).isLessThan(currentVersion),
    };
  }
  return compareVersions(versionToCompare);
};

export default useVersionCompare;
