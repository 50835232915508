import * as Yup from 'yup';

export const required = 'Campo obrigatório';
export const validEmail = 'Digite um e-mail válido';
export const invalidPassword = 'A senha não possui os requisitos necessários';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email(validEmail).required(required),
  password: Yup.string().required(required),
  captcha: Yup.string().nullable(),
});

export const initialValues = {
  email: '',
  password: '',
  captcha: '',
};

export type InitialValues = typeof initialValues;

export default LoginSchema;

export const resetPasswordRequestSchema = Yup.object({
  email: Yup.string().email(validEmail).required(required),
});

export const newPasswordSchema = Yup.object({
  password: Yup.string().required(required),
});

export const validatePassword = (value: string) => {
  const eightChars = Yup.string().required().min(8).isValidSync(value);
  const atLeastOneChar = Yup.string()
    .required()
    .matches(/[A-Za-z]+/g)
    .isValidSync(value);
  const atLeastOneUpperChar = Yup.string()
    .required()
    .matches(/[A-Z]+/g)
    .isValidSync(value);
  const atLeastOneLowerChar = Yup.string()
    .required()
    .matches(/[a-z]+/g)
    .isValidSync(value);
  const atLeastOneNumber = Yup.string()
    .required()
    .matches(/[0-9]+/g)
    .isValidSync(value);
  const atLeastOneSpecialChar = Yup.string()
    .required()
    .matches(/[^A-Za-z0-9]+/g)
    .isValidSync(value);

  return {
    eightChars,
    atLeastOneChar,
    atLeastOneUpperChar,
    atLeastOneLowerChar,
    atLeastOneNumber,
    atLeastOneSpecialChar,
  };
};

export const isValidPassword = ({
  eightChars,
  atLeastOneUpperChar,
  atLeastOneLowerChar,
  atLeastOneNumber,
  atLeastOneSpecialChar,
}: ReturnType<typeof validatePassword>): boolean =>
  eightChars &&
  atLeastOneUpperChar &&
  atLeastOneLowerChar &&
  atLeastOneNumber &&
  atLeastOneSpecialChar;

export const newPasswordInitialValues = {
  password: '',
};

export const passwordValidation = (value: string) => {
  const requirements = validatePassword(value);

  return { ...requirements, isValidPassword: isValidPassword(requirements) };
};
