import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Body,
  Heading,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';
import DOMPurify from 'dompurify';

import {
  staticFunctionalities,
  ignoredFunctionalities,
} from '../../constants/functionalities';
import { toggleOffCanvasOpenClose } from '../../state/plans';
import {
  isOffCanvasOpen,
  getBaseProducts,
  getSelectedPlan,
  getFunctionalities,
} from '../../state/plans/selectors';
import { Product } from '../../services/types';
import { getVerificationIcon } from '../../utils/index';
import * as S from './OffCanvasPlansComparationTable.styles';

const { useTheme, useMediaQuery, Table, TableRow, TableHead } = MaterialUICore;
const { Check, Close, ArrowBack } = MaterialIcons;

const getIsIncluded = (
  isMobile: boolean,
  products: string[],
  planSlug: string = '',
) => {
  if (!isMobile) return true;

  return products.includes(planSlug);
};

const OffCanvasPlansComparationTable = () => {
  const plans = useSelector(getBaseProducts);

  const functionalities = useSelector(getFunctionalities);
  const functionalitiesList = functionalities
    .map((functionality) => ({
      ...functionality,
      ...staticFunctionalities.find(
        (staticFunctionality) =>
          staticFunctionality.slug === functionality.slug,
      ),
    }))
    .filter(
      (functionality) => !ignoredFunctionalities.includes(functionality.slug),
    );

  const dispatch = useDispatch();
  const selectedPlan = useSelector(getSelectedPlan);

  const isOpen = useSelector(isOffCanvasOpen);
  const handleCloseOffCanvas = () => {
    dispatch(toggleOffCanvasOpenClose());
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <S.Drawer
      open={isOpen}
      onClose={handleCloseOffCanvas}
      anchor="right"
      BackdropProps={{ invisible: true }}
      elevation={0}
      data-testid="comparation-offcanvas"
    >
      <S.Content>
        <S.Header>
          <Heading variant="lg" gutterBottom>
            Compare os planos
          </Heading>

          <S.BackButton
            aria-label="Botão voltar"
            color="transparent"
            size="large"
            startIcon={<ArrowBack />}
            onClick={handleCloseOffCanvas}
          >
            Voltar
          </S.BackButton>

          <S.CloseIconButton
            aria-label="Botão fechar"
            size="small"
            onClick={handleCloseOffCanvas}
          >
            <Close />
          </S.CloseIconButton>
        </S.Header>

        <S.TabContainer>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <S.TabCell>
                  {isMobile && selectedPlan
                    ? selectedPlan.name
                    : 'Funcionalidades'}
                </S.TabCell>

                {!isMobile &&
                  plans.map((plan: Product) => (
                    <S.TabCell key={plan.id}>{plan.name}</S.TabCell>
                  ))}
              </TableRow>
            </TableHead>
            <S.TabBody>
              {functionalitiesList.map(
                ({
                  id,
                  iconName: staticIcon,
                  name,
                  products,
                  slug,
                  icon: dynamicIcon,
                }) => (
                  <TableRow key={id}>
                    <S.TabCell component="th" scope="row">
                      <S.TableCellContentWrapper
                        isIncluded={getIsIncluded(
                          isMobile,
                          products,
                          selectedPlan?.slug,
                        )}
                      >
                        {dynamicIcon ? (
                          <S.iconWrapper
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(dynamicIcon),
                            }}
                          />
                        ) : (
                          staticIcon || <Check data-testid="static-check" />
                        )}

                        <S.FuncinalityName variant="xxs">
                          {name}
                        </S.FuncinalityName>
                      </S.TableCellContentWrapper>
                    </S.TabCell>

                    {!isMobile &&
                      plans.map((plan: Product) => (
                        <S.TabCell key={plan.id} scope="row">
                          <Body variant="xxs">
                            {getVerificationIcon(products, plan.slug, slug)}
                          </Body>
                        </S.TabCell>
                      ))}
                  </TableRow>
                ),
              )}
            </S.TabBody>
          </Table>
        </S.TabContainer>
      </S.Content>
    </S.Drawer>
  );
};

export default OffCanvasPlansComparationTable;
