import { MaterialUICore, Select } from '@iclinic/design-system';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  DateFilterOptions,
  MessageStatus,
  Physician,
} from '../../../services/types';
import * as S from './FiltersHistory.styles';
import { getPartialUserInfo } from 'features/messaging/state/common/selectors';

const { MenuItem } = MaterialUICore;

const statusOptionLabels = {
  [MessageStatus.SENT]: 'Enviado',
  [MessageStatus.CONFIRMED]: 'Confirmado',
  [MessageStatus.ERROR]: 'Falha no envio',
  [MessageStatus.NUMBER_INVALID]: 'Telefone inválido',
  [MessageStatus.CANCELED]: 'Cancelado',
};

export type Filters = {
  physicianId: string;
  date: string;
  status: string;
};

interface FiltersHistoryProps {
  physicians?: Physician[];
  filters: Filters;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  statusOptions: MessageStatus[];
}

export default function FiltersHistory({
  physicians,
  filters,
  setFilters,
  statusOptions,
}: FiltersHistoryProps) {
  const { userKind, isAdmin } = useSelector(getPartialUserInfo);

  const onChangeFilterHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;

    setFilters((current) => ({
      ...current,
      [name]: value,
    }));
  };

  return (
    <S.ContentList>
      {(userKind === 'r' || isAdmin) && physicians?.length ? (
        <S.SelectContent>
          <Select
            id="physicianId"
            name="physicianId"
            label="Profissionais de saúde"
            value={filters.physicianId}
            onChange={onChangeFilterHandler}
            SelectProps={{
              displayEmpty: true,
            }}
          >
            <MenuItem value="">Todos</MenuItem>
            {physicians.map(({ id, profile_name }) => (
              <MenuItem key={profile_name} value={id}>
                {profile_name}
              </MenuItem>
            ))}
          </Select>
        </S.SelectContent>
      ) : null}

      <S.SelectContent>
        <Select
          id="date"
          name="date"
          label="Data de envio"
          value={filters.date}
          onChange={onChangeFilterHandler}
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">Todos</MenuItem>
          <MenuItem value={DateFilterOptions.LAST_WEEK}>Essa semana</MenuItem>
          <MenuItem value={DateFilterOptions.LAST_15_DAYS}>
            Últimos 15 dias
          </MenuItem>
          <MenuItem value={DateFilterOptions.THIS_MONTH}>Esse mês</MenuItem>
          <MenuItem value={DateFilterOptions.LAST_90_DAYS}>
            Últimos 90 dias
          </MenuItem>
        </Select>
      </S.SelectContent>

      <S.SelectContent>
        <Select
          id="status"
          name="status"
          label="Status"
          value={filters.status}
          onChange={onChangeFilterHandler}
          SelectProps={{
            displayEmpty: true,
          }}
        >
          <MenuItem value="">Todos</MenuItem>

          {statusOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {statusOptionLabels[option]}
            </MenuItem>
          ))}
        </Select>
      </S.SelectContent>
    </S.ContentList>
  );
}
