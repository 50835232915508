import React from 'react';
import { useSelector } from 'react-redux';
import { MaterialUICore, Body, Paper } from '@iclinic/design-system';

import { formatSecondsToHours } from 'features/reports/utils';
import { getItems } from '../../state/telemedicineReport/selectors';
import * as S from './TelemedicineTable.styles';
import { TableCellParams } from './types';

const { TableContainer, TableHead, TableRow, TableBody } = MaterialUICore;

const TableCell = ({
  bold = false,
  align = 'left',
  children,
}: TableCellParams) => (
  <S.TableCell align={align}>
    <Body variant="xs" bold={bold}>
      {children}
    </Body>
  </S.TableCell>
);

export const TelemedicineTable = () => {
  const rows = useSelector(getItems);

  if (rows.length === 0)
    return <S.Alert severity="warning" message="Nenhum registro encontrado" />;

  return (
    <Paper elevation={0}>
      <TableContainer>
        <S.TableWrapper>
          <S.Table>
            <TableHead>
              <TableRow>
                <TableCell bold>Data</TableCell>
                <TableCell bold>Paciente</TableCell>
                <TableCell bold>Profissional</TableCell>
                <TableCell bold>Tempo da consulta</TableCell>
                <TableCell bold align="center">
                  Gravado
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.conferenceId}>
                  <TableCell>
                    {new Date(row.startDate).toLocaleDateString('pt-BR')}
                  </TableCell>
                  <TableCell>
                    <S.Link href={`/pacientes/${row.patientId}`}>
                      {row.patientName}
                    </S.Link>
                  </TableCell>
                  <TableCell>{row.physicianName}</TableCell>
                  <TableCell>
                    {formatSecondsToHours(row.durationSeconds)}
                  </TableCell>
                  <TableCell align="center">
                    {row.isRecord ? <S.IconCheck /> : <S.IconClear />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </S.Table>
        </S.TableWrapper>
      </TableContainer>
    </Paper>
  );
};
