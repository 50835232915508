import React from 'react';

import { image } from 'shared/utils/urlGetter';
import { browserSuggestion } from 'features/video-conference/constants';
import { isMobile } from 'features/video-conference/utils';
import { MainWrapper, MessageWrapper, ImageWrapper, InfoText } from './styles';

export default function BrowserSuggestion(): JSX.Element {
  const {
    title,
    wordInContrast,
    titleContinue,
    message,
    imagePath,
    altText,
  } = browserSuggestion;

  return (
    <MainWrapper isMobile={isMobile}>
      {!isMobile && (
        <ImageWrapper>
          <img src={image(imagePath)} alt={altText} />
        </ImageWrapper>
      )}

      <MessageWrapper>
        <InfoText variant="xxs">
          {title}
          <strong>
            {' '}
            <a
              href="https://www.google.com/intl/pt-BR/chrome/"
              target="_blank"
              rel="noreferrer"
            >
              {wordInContrast}
            </a>{' '}
          </strong>
          {titleContinue}
        </InfoText>
        <InfoText variant="xxs">{message}</InfoText>
      </MessageWrapper>
    </MainWrapper>
  );
}
