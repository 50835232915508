import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  OnlineTransactionsTable,
  AttemptTransactionsTable,
  Tabs,
} from 'features/onlinePayment/components/reports/transactions';
import {
  selectTransactionType,
  showWaitingPayments,
  selectedPageNumber,
} from 'features/onlinePayment/state/reports/transactions';
import { getIsAttemptTransactions } from 'features/onlinePayment/state/reports/transactions/selectors';
import { TransactionType } from 'features/onlinePayment/state/constants';

type TransactionsListProps = {
  isShowDetailButton?: boolean;
};

const TransactionsList = ({
  isShowDetailButton = true,
}: TransactionsListProps) => {
  const dispatch = useDispatch();
  const isAttemptTransactions = useSelector(getIsAttemptTransactions);

  const renderTable = isAttemptTransactions ? (
    <AttemptTransactionsTable isShowDetailButton={isShowDetailButton} />
  ) : (
    <OnlineTransactionsTable isShowDetailButton={isShowDetailButton} />
  );

  useEffect(() => {
    dispatch(selectTransactionType(TransactionType.Online));
    dispatch(showWaitingPayments(false));
    dispatch(selectedPageNumber(1));
  }, [dispatch]);

  return (
    <>
      <Tabs />
      {renderTable}
    </>
  );
};

export default TransactionsList;
