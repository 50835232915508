import React from 'react';
import { useSelector } from 'react-redux';
import { MaterialIcons, MaterialUICore } from '@iclinic/design-system';

import Loader from 'features/tissInvoicing/components/ui/Loader';
import { Table, TableRow } from 'features/tissInvoicing/components/ui/Table';
import { getExpenseListState } from 'features/tissInvoicing/state/expense/selectors';
import { addExpenseHref } from '../constants';
import { NewExpenseButton } from '../styles';
import Columns from './Columns';
import Rows from './Rows';
import * as Styled from './styles';

const { Add } = MaterialIcons;

const { TableBody, TableHead } = MaterialUICore;
interface TableContentProps {
  infiniteScrollRef: (node: unknown) => void;
}

function TableContent({ infiniteScrollRef }: TableContentProps) {
  const { expenses, loading } = useSelector(getExpenseListState);

  return (
    <Styled.TableContainer>
      <NewExpenseButton
        variant="contained"
        startIcon={<Add />}
        href={addExpenseHref}
      >
        Nova Despesa
      </NewExpenseButton>
      <Table aria-label="Lista de Outras despesas" stickyHeader>
        <TableHead>
          <TableRow>
            <Columns />
          </TableRow>
        </TableHead>
        <TableBody>
          {expenses.map((row, i) => {
            const isLastElement = expenses.length === +i + 1;
            return (
              <TableRow
                key={row.id}
                hover
                stripped={+true}
                ref={isLastElement ? infiniteScrollRef : undefined}
              >
                <Rows rowData={row} />
              </TableRow>
            );
          })}
          <Loader
            isLoading={loading}
            title="Carregando lista de outras despesas"
          />
        </TableBody>
      </Table>
    </Styled.TableContainer>
  );
}

export default TableContent;
