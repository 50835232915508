import React from 'react';

import Button from 'ui/new/button';
import { SIZE, BUTTON } from 'ui/new/button/constants';
import style from './ActionButton.scss';

type ActionButtonProps = {
  onClick: () => void;
  children: React.ReactNode;
};

const ActionButton = ({
  children,
  onClick = () => null,
}: ActionButtonProps): JSX.Element => (
  <div className={style['action-button']}>
    <Button type={BUTTON.Link} size={SIZE.Small} onClick={onClick}>
      {children}
    </Button>
  </div>
);

export default ActionButton;
