import React from 'react';
import { InjectedFormProps } from 'redux-form';

import Button from 'ui/new/button';
import { BUTTON, COLOR } from 'ui/new/button/constants';
import { saveAndContinue, saveAndCloseModal } from 'features/stock/constants';

type SaveProps = {
  handleSubmit: InjectedFormProps['handleSubmit'];
  cancelForm(): void;
  isFetching: boolean;
};

export default function Save({
  cancelForm,
  handleSubmit,
  isFetching,
}: SaveProps) {
  return (
    <>
      <Button
        type={BUTTON.Light}
        onClick={cancelForm}
        loading={isFetching}
        disabled={isFetching}
      >
        Cancelar
      </Button>
      <Button
        type={BUTTON.Light}
        onClick={handleSubmit(saveAndContinue)}
        loading={isFetching}
        disabled={isFetching}
      >
        Salvar e Efetuar Outra
      </Button>
      <Button
        type={BUTTON.Primary}
        onClick={handleSubmit(saveAndCloseModal)}
        loading={isFetching}
        color={COLOR.White}
        disabled={isFetching}
      >
        Salvar
      </Button>
    </>
  );
}
