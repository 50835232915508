import { MaterialUICore } from '@iclinic/design-system';

export const useStyles = MaterialUICore.makeStyles((theme) => ({
  inputAdornment: {
    '&.MuiInputAdornment-filled.MuiInputAdornment-positionStart': {
      marginTop: 0,
    },
  },
  inputLabel: {
    marginLeft: theme.spacing(5.5),
  },
}));
