import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, MaterialIcons } from '@iclinic/design-system';

import { getCreditCard } from 'state/creditcard/selectors';
import { clearSaveFeedback } from 'state/creditcard/actions';
import { unauthorize as logoutManual } from 'features/new-auth/state/actions';
import { SuccessSnackbar } from './SuccessSnackbar';

const { CheckCircle, ErrorOutline } = MaterialIcons;

const UpdatePaymentMessages = () => {
  const dispatch = useDispatch();
  const { saveFeedback } = useSelector(getCreditCard);

  if (!saveFeedback) {
    return null;
  }

  const { message, success, title, isBlocked } = saveFeedback;

  const handleActionClick = () => {
    if (success) {
      dispatch(logoutManual());
    } else {
      dispatch(clearSaveFeedback());
    }
  };

  return isBlocked ? (
    <Alert
      severity={success ? 'success' : 'error'}
      icon={success ? <CheckCircle /> : <ErrorOutline />}
      title={title}
      message={message}
      action={
        <Button size="large" color="transparent" onClick={handleActionClick}>
          {success ? 'Recarregar sistema' : 'Fechar'}
        </Button>
      }
    />
  ) : (
    <SuccessSnackbar />
  );
};

export default UpdatePaymentMessages;
