import { OrderedSet } from 'immutable';
import {
  EditorState,
  ContentBlock,
  DraftEntityType,
  DraftEntityMutability,
  RawDraftContentState,
} from 'draft-js';
import { ApiErrors } from '@types';

import { FlowType, ScheduleOffsetUnit } from 'features/marketing/constants';
import * as types from './types';
import { Professional } from '../professional/types';

export type EmailFlowError = {
  code: number;
  message: string;
  id: string;
};

export type EmailFlowComponent = {
  id: string;
  subject?: string;
  body?: string;
  draftJSState?: RawDraftContentState;
  emailFlowId: string;
  offset?: ScheduleOffset;
  position: number;
  clinicId: number;
  isNew?: boolean;
};

export interface SerializedComponentError extends ApiErrors {
  id: string;
}

export type Sender = {
  email: string;
  name: string;
};

export type EmailFlowPhysician = {
  id: string;
  physicianId: number;
};

export type EmailFlow = {
  id: string;
  name: string;
  stats?: {
    activePatients: number;
    finishedPatients: number;
  };
  active: boolean;
  flowType: FlowType.custom | FlowType.return;
  physicianId: number;
  components?: EmailFlowComponent[];
  sender: Sender;
};

export type EmailFlowTarget = {
  target: {
    id: number;
    value: number | string;
    name: string;
    checked: boolean;
  };
};

export type Physician = {
  id: number;
  name: string;
  email: string;
  kind: string;
};

export type PhysicianIdSelected = {
  PhysicianIdSelected: number;
};

export type UserProfile = {
  meta: {
    next: string | null;
    page: number;
  };
  objects: Physician[];
};

export type EmailFlowCreateInput = {
  name: string;
  active: boolean;
  sender?: Sender;
  components?: EmailFlowComponentCreateInput[];
};

export type EmailFlowDeleteInput = {
  id: string;
};

export type EmailFlowDuplicateInput = {
  id: string;
};

export type RetrievedEmailFlowInfo = {
  sender: Sender;
  components: EmailFlowComponentCreateInput[];
};

export type ScheduleOffset = {
  value: number;
  unit: ScheduleOffsetUnit;
};

export type EmailFlowComponentCreateInput = {
  subject: string;
  body: string;
  draftJSState: Object;
  emailFlowId: string;
  offset: ScheduleOffset;
  position: number;
};

export interface TestEmailComponentParams {
  id: string;
  clinicId: number;
  physicianId: number;
  componentId: string;
}

export type InitialFormData = {
  emailFlow: EmailFlow;
  currentComponent: EmailFlowComponent;
  activeEmailFlow: boolean;
  sender: Sender;
};

export type ModalEditor = {
  textSelected: string;
  url: string;
};

export type CheckEntityRangeValid = {
  offset: number;
  length: number;
  key: number;
  keySelection: number;
};

export type EditorOptionsText = {
  id: number;
  name: string;
  selected: boolean;
};

export interface EditEmailFlowAction {
  type: typeof types.EDIT_EMAIL_FLOW.ACTION;
  payload: {
    id: string;
    physicianId: number;
    flowType: FlowType.custom | FlowType.return;
  };
}

export type EditorTextEditor = {
  editorState: EditorState;
};

export type ComponentDelete = {
  componentId: string;
  physicianId: number;
  emailFlowId: string;
};

type EmailFlowComponentProps = {
  id: string;
  body: string;
  draftJSState: any;
  emailFlowId: string;
  offset: {
    unit: string;
    value: number;
  };
  subject: string;
};

export interface EmailFlowComponentCreate extends EmailFlowComponentProps {
  clinicId: number;
  isNew: boolean;
  position: number;
}

export interface EmailFlowComponentUpdate extends EmailFlowComponentProps {
  physicianId: number;
}

export type PhysicianId = {
  physicianId: number;
};

export type ErrorValidateComponent = {
  errorsList: ApiErrors[];
  customMessage?: string;
};

export enum EmailFlowRoute {
  r = 'sequencia-padrao',
  c = 'sequencia-personalizada',
}

// in @types/draftJS don't have export this interface DraftEntityInstance,
// so we copy this interface here, and in this interface the getDate() was defined how any
interface DraftEntityInstance {
  getType(): DraftEntityType;
  getMutability(): DraftEntityMutability;
  getData(): any;
}

interface EntityDecoratedText {
  start: number;
  end: number;
  text: string;
}

// the props entityData was any because in interface
// DraftEntityInstance defined to @types/draftJS is any
export type CurrentInfoEditor = {
  block: ContentBlock;
  blockType: string;
  inlineStyles: OrderedSet<string>;
  entity: DraftEntityInstance;
  entityType: string;
  entityData: any;
  selectionText: string;
  entityDecoratedText: EntityDecoratedText;
};

export interface CancelEmailFlowAction {
  type: typeof types.CANCEL_EMAIL_FLOW.ACTION;
}
export interface ActiveEmailFlowAction {
  type: typeof types.ACTIVE_EMAIL_FLOW.ACTION;
  payload: { id: React.ReactText; active: boolean };
}

export interface EditActiveEmailFlowAction {
  type: typeof types.EDIT_ACTIVE_EMAIL_FLOW.ACTION;
  payload: {
    activeEmailFlow: boolean;
  };
}

export interface FetchUpdateEmailFlowAction {
  type: typeof types.FETCH_UPDATE.ACTION;
  params: EmailFlow;
  payload: {
    emailFlowUpdate: {
      isFetching: boolean;
      emailFlow: EmailFlow;
    };
  };
}
export interface FetchUpdateEmailFlowFailureAction {
  type: typeof types.FETCH_UPDATE.FAILURE;
  params: EmailFlow;
  payload: {
    emailFlowUpdate: {
      isFetching: boolean;
      emailFlow: EmailFlow;
      errors: ApiErrors[];
    };
  };
}
export interface FetchUpdateEmailFlowSuccessAction {
  type: typeof types.FETCH_UPDATE.SUCCESS;
  params: EmailFlow;
  payload: {
    emailFlowUpdate: {
      isFetching: boolean;
      emailFlow: EmailFlow;
    };
  };
}
export interface FetchListEmailFlowAction {
  type: typeof types.FETCH_LIST.ACTION;
  params: Professional;
  payload: {
    isFetching: boolean;
  };
}
export interface FetchListEmailFlowSuccessAction {
  type: typeof types.FETCH_LIST.SUCCESS;
  payload: {
    isFetching: boolean;
    emailFlows: EmailFlow[];
  };
}
export interface FetchListEmailFlowFailureAction {
  type: typeof types.FETCH_LIST.FAILURE;
  payload: {
    isFetching: boolean;
    errors: ApiErrors[];
  };
}
export interface FetchListPhysiciansAction {
  type: typeof types.FETCH_PHYSICIANS.ACTION;
  payload: {
    physician: {
      isFetching: boolean;
    };
  };
}
export interface FetchListPhysiciansSuccessAction {
  type: typeof types.FETCH_PHYSICIANS.SUCCESS;
  payload: {
    physician: {
      isFetching: boolean;
      physicians: Physician[];
    };
  };
}
export interface FetchListPhysiciansFailureAction {
  type: typeof types.FETCH_PHYSICIANS.FAILURE;
  payload: {
    physician: {
      isFetching: boolean;
      errors: ApiErrors[];
    };
  };
}

export interface FetchDetailsEmailFlowAction {
  type: typeof types.FETCH_DETAILS.ACTION;
  params: EmailFlowPhysician;
  payload: {
    emailFlowDetails: {
      isFetching: boolean;
    };
  };
}
export interface FetchDetailsEmailFlowFailureAction {
  type: typeof types.FETCH_DETAILS.FAILURE;
  payload: {
    emailFlowDetails: {
      isFetching: boolean;
      errors: ApiErrors[];
    };
  };
}
export interface FetchDetailsEmailFlowSuccessAction {
  type: typeof types.FETCH_DETAILS.SUCCESS;
  payload: {
    emailFlowDetails: {
      isFetching: boolean;
      emailFlow: EmailFlow;
    };
  };
}

export interface FetchCreateEmailFlowAction {
  type: typeof types.FETCH_CREATE.ACTION;
  params: EmailFlowCreateInput;
  payload: {
    emailFlowCreate: {
      isFetching: boolean;
    };
  };
}

export interface FetchCreateEmailFlowFailureAction {
  type: typeof types.FETCH_CREATE.FAILURE;
  params: EmailFlowCreateInput;
  payload: {
    emailFlowCreate: {
      isFetching: boolean;
      errors: ApiErrors[];
    };
  };
}
export interface FetchCreateEmailFlowSuccessAction {
  type: typeof types.FETCH_CREATE.SUCCESS;
  params: EmailFlowCreateInput;
  payload: {
    emailFlowCreate: {
      isFetching: boolean;
      emailFlow: EmailFlow;
      errors: ApiErrors[];
    };
  };
}

export interface FetchDeleteEmailFlowAction {
  type: typeof types.FETCH_DELETE.ACTION;
  params: EmailFlowDeleteInput;
  payload: {
    emailFlowDelete: {
      isFetching: boolean;
    };
  };
}

export interface FetchDeleteEmailFlowFailureAction {
  type: typeof types.FETCH_DELETE.FAILURE;
  params: EmailFlowDeleteInput;
  payload: {
    emailFlowDelete: {
      isFetching: boolean;
      errors: ApiErrors[];
    };
  };
}
export interface FetchDeleteEmailFlowSuccessAction {
  type: typeof types.FETCH_DELETE.SUCCESS;
  params: EmailFlowDeleteInput;
  payload: {
    emailFlowDelete: {
      isFetching: boolean;
      errors: ApiErrors[];
    };
  };
}

export interface FetchDetailsEmailFlowClearAction {
  type: typeof types.FETCH_DETAILS_CLEAR.ACTION;
  payload: {
    emailFlowDetails: {
      isFetching: boolean;
      emailFlow: undefined;
      errors: [];
    };
  };
}

export interface DuplicateEmailFlowAction {
  type: typeof types.DUPLICATE_EMAIL_FLOW.ACTION;
  params: EmailFlowDuplicateInput;
}

export interface DeleteEmailFlowAction {
  type: typeof types.DELETE_EMAIL_FLOW.ACTION;
  params: EmailFlowDeleteInput;
}

export interface AddEmailFlowAction {
  type: typeof types.ADD_EMAIL_FLOW.ACTION;
}

export interface FetchDetailsAddEmailFlowAction {
  type: typeof types.FETCH_DETAILS_ADD_NEW_COMPONENT.ACTION;
}

export interface SaveEmailFlowAction {
  type: typeof types.EDIT_EMAIL_FLOW.SAVE;
}

export interface SetActiveCardAction {
  type: typeof types.EDIT_EMAIL_FLOW.SET_ACTIVE_CARD;
  payload: {
    activeComponentsCard: string;
  };
}

export interface SetCurrentComponentAction {
  type: typeof types.EDIT_EMAIL_FLOW.SET_CURRENT_COMPONENT;
  payload: {
    currentComponent: EmailFlowComponent;
  };
}

export interface ClearCurrentComponentAction {
  type: typeof types.EDIT_EMAIL_FLOW.SET_CURRENT_COMPONENT;
  payload: {
    currentComponent: undefined;
  };
}

export interface TestEmailInput {
  params: TestEmailComponentParams;
  recipient: string;
}

export interface CurrentComponentParams {
  id: string;
  emailFlowId: string;
  clinicId: number;
}

export interface SendTestEmailParams {
  physicianId: number;
  recipient: string;
}

export interface FetchSendTestEmailActionReturn {
  type: typeof types.FETCH_SEND_TEST_EMAIL;
  meta: SendTestEmailParams;
  payload: {
    sendTestEmail: {
      isFetching: boolean;
      errors: ApiErrors[];
    };
  };
}

export interface FetchSendTestEmailSuccessActionReturn {
  type: typeof types.FETCH_SEND_TEST_EMAIL.SUCCESS;
  meta: TestEmailInput;
  payload: {
    sendTestEmail: {
      isFetching: boolean;
      errors: ApiErrors[];
    };
  };
}

export interface FetchSendTestEmailFailureActionReturn {
  type: typeof types.FETCH_SEND_TEST_EMAIL.FAILURE;
  meta: TestEmailInput;
  payload: {
    sendTestEmail: {
      isFetching: boolean;
      errors: ApiErrors[];
    };
  };
}

export interface UpdateTextEditorAction {
  type: typeof types.EDITOR_TEXT.UPDATE_EDITOR_STATE;
  payload: {
    editor: {
      editorState: EditorState;
    };
  };
}

export interface OptionsTextFormatAction {
  type: typeof types.EDITOR_TEXT.FORMAT_TEXT;
  payload: {
    editor: {
      optionsFormatText: string;
    };
  };
}

export interface OnModalEditorLinkSubmitAction {
  type: typeof types.EDITOR_TEXT.MODAL_LINK_SUBMIT;
  payload: {
    textSelected: string;
    url: string;
  };
}

export interface OnModalEditorButtonSubmitAction {
  type: typeof types.EDITOR_TEXT.MODAL_LINK_SUBMIT;
  payload: {
    textSelected: string;
    url: string;
  };
}

export interface OnModalEditorImageSubmitAction {
  type: typeof types.EDITOR_TEXT.MODAL_IMAGE_SUBMIT;
  payload: {
    url: string;
  };
}

export interface OnAutomatedTagChosenAction {
  type: typeof types.EDITOR_TEXT.AUTOMATED_TAG;
  payload: {
    automatedTag: string;
  };
}

export interface FetchDeleteComponentAction {
  type: typeof types.FETCH_DELETE_COMPONENT.ACTION;
  params: ComponentDelete;
  payload: {
    componentDelete: {
      isFetching: boolean;
      component: ComponentDelete;
    };
  };
}
export interface FetchDeleteComponentFailureAction {
  type: typeof types.FETCH_DELETE_COMPONENT.FAILURE;
  params: ComponentDelete;
  payload: {
    componentDelete: {
      isFetching: boolean;
      errors: ApiErrors[];
      component: ComponentDelete;
    };
  };
}
export interface FetchDeleteComponentSuccessAction {
  type: typeof types.FETCH_DELETE_COMPONENT.SUCCESS;
  params: ComponentDelete;
  payload: {
    componentDelete: {
      isFetching: boolean;
      component: ComponentDelete;
    };
  };
}

export interface FetchUpdateComponentAction {
  type: typeof types.FETCH_UPDATE_COMPONENT.ACTION;
  params: EmailFlowComponentUpdate;
  payload: {
    componentUpdate: {
      isFetching: boolean;
      component: EmailFlowComponentUpdate;
    };
  };
}
export interface FetchUpdateComponentFailureAction {
  type: typeof types.FETCH_UPDATE_COMPONENT.FAILURE;
  params: EmailFlowComponentUpdate;
  payload: {
    componentUpdate: {
      isFetching: boolean;
      errors: ApiErrors[];
      component: EmailFlowComponentUpdate;
    };
  };
}
export interface FetchUpdateComponentSuccessAction {
  type: typeof types.FETCH_UPDATE_COMPONENT.SUCCESS;
  params: EmailFlowComponentUpdate;
  payload: {
    componentUpdate: {
      isFetching: boolean;
      component: EmailFlowComponentUpdate;
    };
  };
}

export interface SaveEmailFlowComponentAction {
  params: {
    physicianId: number;
  };
  type: typeof types.EDIT_EMAIL_FLOW_COMPONENT.ACTION;
}

export interface FetchCreateComponentFailureAction {
  type: typeof types.FETCH_CREATE_COMPONENT.FAILURE;
  params: EmailFlowComponentCreate;
  payload: {
    componentCreate: {
      isFetching: boolean;
      errors: ApiErrors[];
      component: EmailFlowComponentCreate;
    };
  };
}
export interface FetchCreateComponentSuccessAction {
  type: typeof types.FETCH_CREATE_COMPONENT.SUCCESS;
  payload: {
    componentCreate: {
      isFetching: boolean;
      component: EmailFlowComponentCreate;
    };
  };
}

export interface FetchCreateComponentAction {
  type: typeof types.FETCH_CREATE_COMPONENT.ACTION;
  params: EmailFlowComponentCreate;
  payload: {
    componentCreate: {
      isFetching: boolean;
      component: EmailFlowComponentCreate;
    };
  };
}

export interface FetchDetailsUpdateEmailFlowAction {
  type: typeof types.FETCH_DETAILS_UPDATE_COMPONENT.ACTION;
}

export interface ClearErrorsComponentAction {
  type: typeof types.CLEAR_ERRORS_COMPONENT.ACTION;
  payload: {
    componentUpdate: {
      isFetching: boolean;
      errors: [];
    };
    componentCreate: {
      isFetching: boolean;
      errors: [];
    };
    componentDelete: {
      isFetching: boolean;
      errors: [];
    };
  };
}

export type EmailFlowActionsReturn =
  | FetchListEmailFlowFailureAction
  | FetchListEmailFlowSuccessAction
  | FetchListEmailFlowAction
  | FetchUpdateEmailFlowSuccessAction
  | FetchUpdateEmailFlowFailureAction
  | EditEmailFlowAction
  | ActiveEmailFlowAction
  | FetchUpdateEmailFlowAction
  | FetchListPhysiciansAction
  | FetchListPhysiciansSuccessAction
  | FetchListPhysiciansFailureAction
  | CancelEmailFlowAction
  | FetchDetailsEmailFlowAction
  | FetchDetailsEmailFlowFailureAction
  | FetchDetailsEmailFlowSuccessAction
  | EditActiveEmailFlowAction
  | FetchCreateEmailFlowAction
  | FetchCreateEmailFlowSuccessAction
  | FetchCreateEmailFlowFailureAction
  | FetchDeleteEmailFlowAction
  | FetchDeleteEmailFlowSuccessAction
  | FetchDeleteEmailFlowFailureAction
  | DeleteEmailFlowAction
  | AddEmailFlowAction
  | DuplicateEmailFlowAction
  | SaveEmailFlowAction
  | SetActiveCardAction
  | OnAutomatedTagChosenAction
  | OnModalEditorImageSubmitAction
  | OnModalEditorButtonSubmitAction
  | OnModalEditorLinkSubmitAction
  | OptionsTextFormatAction
  | UpdateTextEditorAction
  | FetchDeleteComponentSuccessAction
  | FetchDeleteComponentFailureAction
  | FetchDeleteComponentAction
  | FetchUpdateComponentSuccessAction
  | FetchUpdateComponentFailureAction
  | FetchUpdateComponentAction
  | SaveEmailFlowComponentAction
  | FetchDetailsEmailFlowClearAction;

export type UserInfo = {
  physicianId: number;
  email: string;
};
