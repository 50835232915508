import { stateToHTML as draftJSExportHTML } from 'draft-js-export-html';

import { optionsAutomatedTags, optionsToExportClass } from 'ui/new/editor/constants';

const setTagContents = (element, contents) => {
  const { children } = element;
  const tagElement = element;

  if (children.length === 0) {
    tagElement.innerHTML = contents;
    return;
  }

  // Fix nested structure, remove all text until the deepest fist element:
  // original: <tag>text<strong>other<em>X</em>test</strong>zzz</tag>
  // result..: <tag><strong><em>${contents}</em></strong></tag>

  while (element.firstChild && element.firstChild.nodeType !== Node.ELEMENT_NODE) {
    element.removeChild(element.firstChild);
  }

  setTagContents(element.firstChild, contents);

  while (element.lastChild && element.lastChild !== element.firstChild) {
    element.removeChild(element.lastChild);
  }
};

const setButtonContent = (element) => {
  const buttonElement = element;
  const url = buttonElement.getAttributeNode('url').value;
  buttonElement.removeAttribute('url');
  buttonElement.innerHTML = `<a href='${url}'>${element.innerText}</a>`;
};

const automatedTagTranslator = (text) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(`<body>${text}</body>`, 'text/html');
  const automatedTags = doc.getElementsByClassName(optionsToExportClass.automatedTag);
  const buttons = doc.getElementsByClassName(optionsToExportClass.button);

  Array.from(automatedTags).forEach((element) => {
    const automatedTag = optionsAutomatedTags[element.innerText];
    if (automatedTag) {
      setTagContents(element, `{{${automatedTag}}}`);
    }
  });

  Array.from(buttons).forEach((element) => {
    setButtonContent(element);
  });

  const serializer = new XMLSerializer();
  const body = serializer.serializeToString(doc.getElementsByTagName('body')[0]);
  return body.replace(/^<body[^>]*>/, '').replace(/<\/body>$/, '');
};

// eslint-disable-next-line import/prefer-default-export
export const stateToHTML = (currentContentEditor, optionsExport) => (
  automatedTagTranslator(draftJSExportHTML(currentContentEditor, optionsExport))
);
