// Internal
import * as types from './constants';
import { SubscriptionState } from './types';
import { SubscriptionActions } from './actions';

/** Constants */
export const initialState: SubscriptionState = {
  isMonthly: true,
  selectedUsersWithProduct: null,
  isFetching: false,
  product: ['plus'],
  productName: ['Plus'],
  selectedAddons: [],
  selectedBasePlan: [],
  selectedExtra: [],
  products: {
    base: null,
    extra: null,
    addons: null,
  },
  errors: [],
  isOpenModal: false,
  physicians: [],
  step: 1,
  currentAddon: '',
  checkout: {
    number: '',
    owner: '',
    expirationDate: '',
    cvv: '',
    flag: '',
    cpfCnpj: '',
  },
  isFlipped: false,
  identityNumber: null,
  summary: {
    total: null,
    newSubscription: [],
  },
  steps: null,
  progressStep: null,
  direction: null,
};

const subscription = (
  state = initialState,
  action: SubscriptionActions,
): SubscriptionState => {
  switch (action.type) {
    case types.FETCH_CONTRACTS.ACTION:
      return {
        ...state,
        isMonthly: action.payload.isMonthly,
      };
    case types.FETCH_CHECKOUT.ACTION:
    case types.FETCH_SUBSCRIPTION.ACTION:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      };
    case types.FETCH_SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        products: action.payload.products,
      };
    case types.SET_SUBSCRIPTION.SUCCESS:
      return {
        ...state,
        product: action.payload.product,
        productName: action.payload.productName,
      };
    case types.CLOSE_MODAL.SUCCESS:
    case types.OPEN_MODAL.SUCCESS:
      return {
        ...state,
        isOpenModal: action.payload.isOpenModal,
      };
    case types.CLOSE_MODAL.ACTION:
      return {
        ...state,
        selectedUsersWithProduct: null,
        checkout: {
          number: '',
          owner: '',
          expirationDate: '',
          cvv: '',
          flag: '',
          cpfCnpj: '',
        },
      };
    case types.LIST_PHYSICIANS.SUCCESS:
      return {
        ...state,
        physicians: action.payload.physicians,
      };
    case types.SET_STEP.ACTION:
      return {
        ...state,
        step: action.payload.step,
        checkout: {
          number: '',
          owner: '',
          expirationDate: '',
          cvv: '',
          flag: '',
          cpfCnpj: '',
        },
      };
    case types.SELECT_PHYSICIAN.SUCCESS:
      return {
        ...state,
        physicians: action.payload.physicians,
        selectedUsersWithProduct: {
          ...state.selectedUsersWithProduct,
          [action.payload.currentAddon]: action.payload.selectedPhysiciansIds,
        },
      };
    case types.SET_SELECTED_ADDONS.ACTION:
      return {
        ...state,
        selectedAddons: action.payload.selectedAddons,
        selectedBasePlan: action.payload.selectedBasePlan,
        selectedExtra: action.payload.selectedExtra,
      };
    case types.SET_CURRENT_ADDON.ACTION:
      return {
        ...state,
        currentAddon: action.payload.currentAddon,
      };
    case types.FETCH_SUBSCRIPTION.FAILURE:
    case types.CLEAR_ERRORS_SUBSCRIPTIONS.ACTION:
      return {
        ...state,
        errors: action.payload.errors,
      };
    case types.UPDATE_CREDIT_CARD.ACTION:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          [action.payload.name]: action.payload.value,
        },
        hasError: action.payload.hasError,
      };
    case types.FLIP_CARD.ACTION:
      return {
        ...state,
        isFlipped: action.payload.isFlipped,
      };
    case types.FETCH_CHECKOUT.SUCCESS:
      return {
        ...state,
        step: action.payload.step,
        isFetching: action.payload.isFetching,
      };
    case types.GET_SUMMARY.FAILURE:
    case types.FETCH_CHECKOUT.FAILURE:
      return {
        ...state,
        errors: action.payload.errors,
        isFetching: action.payload.isFetching,
      };
    case types.UNION_PRODUCT_TO_USER.ACTION:
      return {
        ...state,
        selectedUsersWithProduct: {
          ...state.selectedUsersWithProduct,
          [action.payload.product]: action.payload.selectedPhysiciansIds,
        },
      };
    case types.UNION_PRODUCT_TO_SINGLE_USER.ACTION:
      return {
        ...state,
        selectedUsersWithProduct: action.payload.products,
      };
    case types.GET_SUMMARY.SUCCESS:
      return {
        ...state,
        summary: action.payload.summary,
        isFetching: action.payload.isFetching,
      };
    case types.CREATE_STEPS_FLOW.ACTION:
      return {
        ...state,
        errors: action.payload.errors,
        isFetching: action.payload.isFetching,
        steps: action.payload.steps,
        progressStep: 1,
      };
    case types.CONTROL_STEPS_FLOW.ACTION:
      return {
        ...state,
        errors: action.payload.errors,
        isFetching: action.payload.isFetching,
        progressStep: action.payload.progressStep,
      };
    default:
      return state;
  }
};

export default subscription;
