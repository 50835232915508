// External
import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import qs from 'qs';
import PropTypes from 'prop-types';

// Internal
import { MARKETING_UNSUBSCRIBE } from 'shared/constants/forms';
import * as actions from 'state/marketing/unsubscribe/actions';
import * as selectors from 'state/marketing/unsubscribe/selectors';
import CardUnsubscribeMessage from '../components/CardUnsubscribeMessage';
import FeedBackUnsubscribe from '../components/FeedBackUnsubscribe';
import style from './Unsubscribe.scss';

const Unsubscribe = ({
  feedback,
  feedbackSent,
  params,
  setUnsubscribeUser,
  fetchUnsubscribe,
}) => {
  const { recipient, unsubscribeToken, type } = qs.parse(params);

  React.useEffect(() => {
    setUnsubscribeUser({ recipient, unsubscribeToken, type });
    fetchUnsubscribe('', '');
  }, [setUnsubscribeUser, recipient, unsubscribeToken, fetchUnsubscribe, type]);

  return (
    <div className={style.container}>
      <CardUnsubscribeMessage
        didProvideReason={feedbackSent.didProvideReason}
        hasErrors={feedbackSent.hasErrors}
      />
      {!feedbackSent.didProvideReason && !feedbackSent.hasErrors && (
        <FeedBackUnsubscribe
          reason={feedback.reason}
          otherReason={feedback.otherReason}
          fetchUnsubscribe={fetchUnsubscribe}
        />
      )}
    </div>
  );
};

Unsubscribe.defaultProps = {
  feedback: PropTypes.shape({
    reason: undefined,
    otherReason: undefined,
  }),
};

Unsubscribe.propTypes = {
  feedback: PropTypes.shape({
    reason: PropTypes.string,
    otherReason: PropTypes.string,
  }),
  feedbackSent: PropTypes.shape({
    didProvideReason: PropTypes.bool.isRequired,
    hasErrors: PropTypes.bool.isRequired,
  }).isRequired,
  params: PropTypes.string.isRequired,
  setUnsubscribeUser: PropTypes.func.isRequired,
  fetchUnsubscribe: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  feedback: selectors.getFeedBackFormValues(state),
  feedbackSent: selectors.getFeedBackSent(state),
  params: global.location.search.slice(1),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({ form: MARKETING_UNSUBSCRIBE, enableReinitialize: true })(
    Unsubscribe,
  ),
);
