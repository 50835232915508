import React from 'react';
import { useDispatch } from 'react-redux';

import {
  Body,
  Button,
  DialogActions,
  DialogContent,
} from '@iclinic/design-system';
import { toggleDialog } from '../state/reducer';
import { openIntercomChat } from 'shared/utils/intercom';

export const DialogUnpaid = () => {
  const dispatch = useDispatch();

  return (
    <>
      <DialogContent>
        <Body variant="xs">
          Verificamos um pagamento em aberto em sua assinatura, o que não nos
          permite efetuar a mudança da sua data de vencimento por esse canal.
        </Body>
        <Body variant="xs">
          Se o pagamento já foi realizado, por favor, entre em contato com
          nossos especialistas clicando no botão abaixo. Eles poderão auxiliá-lo
          com mais agilidade.
        </Body>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="transparent"
          onClick={() => dispatch(toggleDialog())}
        >
          Cancelar
        </Button>
        <Button onClick={openIntercomChat} variant="contained">
          Quero falar com um especialista
        </Button>
      </DialogActions>
    </>
  );
};
