import React from 'react';
import { Participant } from 'twilio-video';

import { MaterialIcons } from '@iclinic/design-system';
import { MICROPHONE_OFF } from 'features/video-conference/constants';
import DarkChip from 'features/video-conference/components/TeamComponents/DarkChip';
import { useParticipantNetworkQualityLevel } from 'features/video-conference/hooks/useParticipantNetworkQualityLevel';
import { getMicrophoneFeedbackLeftSize } from 'features/video-conference/utils';
import useStyles from './styles';

const { MicOff } = MaterialIcons;

interface Props {
  isVideoEnabled: boolean;
  isLocal: boolean;
  participant: Participant;
}

export default function MicrophoneOff({
  isVideoEnabled,
  isLocal,
  participant,
}: Props): JSX.Element {
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  const isShowingNetworkQuality =
    networkQualityLevel !== null && networkQualityLevel < 4;

  const classes = useStyles({
    isVideoEnabled,
    isLocal,
    isShowingNetworkQuality,
    left: getMicrophoneFeedbackLeftSize(
      isLocal,
      isShowingNetworkQuality,
      isVideoEnabled,
    ),
  });

  return (
    <DarkChip
      icon={<MicOff />}
      content={MICROPHONE_OFF}
      customClassName={classes.root}
    />
  );
}
