import {
  Box as BoxUI,
  Button as ButtonUI,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const FlexBoxUI = styled(BoxUI)({
  display: 'flex',
  flexDirection: 'column',
});

export const Container = styled(FlexBoxUI)({
  padding: `0 ${tokens.spacing.sm}`,
  margin: `0 ${tokens.spacing.sm} ${tokens.spacing.sm}`,
});

export const Header = styled(FlexBoxUI)({
  rowGap: tokens.spacing.base,
  marginBottom: tokens.spacing.sm,
});

export const Content = styled(FlexBoxUI)({
  padding: tokens.spacing.sm,
  background: tokens.color.neutral[0],
  border: `1px solid ${tokens.color.neutral[50]}`,
  borderRadius: tokens.border.radius.md,
});

export const NewExpenseButton = styled(ButtonUI)({
  outline: 'none',
  textDecoration: 'none',
  '& svg': {
    fill: tokens.color.neutral[0],
  },
  '&:hover, &:focus': {
    color: tokens.color.neutral[0],
    outline: 'none',
    textDecoration: 'none',
  },
});
