import { Body, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { font, line } = tokens;

export const Root = styled('div')(() => ({
  gridArea: 'terms',
  display: 'flex',
  flexDirection: 'column',
}));

export const Label = styled(Body)(() => ({
  fontSize: font.size.xxs,

  '& a': {
    fontSize: font.size.xxs,
    lineHeight: line.height.sm,
  },
}));
