import React from 'react';

import { formatCurrency } from 'features/tissInvoicing/utils/formatCurrency';
import * as Styles from './styles';

interface CurrentGuidesOverviewProps {
  guides: number;
  // eslint-disable-next-line react/no-unused-prop-types
  errors?: number;
  total: string;
}

function CurrentGuidesOverview({ guides, total }: CurrentGuidesOverviewProps) {
  return (
    <Styles.Body variant="xs">
      {`${guides} guias / Total: ${formatCurrency(total)}`}
    </Styles.Body>
  );
}

export default CurrentGuidesOverview;
