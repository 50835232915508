import {
  createPayloadAction,
  createSimpleAction,
  createFailureAction,
  createParamsAction,
} from 'shared/utils/actions';
import {
  SET_EVENT,
  FETCH_SELLER_INFO_ACTION,
  FETCH_SELLER_INFO_FAILURE,
  FETCH_SELLER_INFO_SUCCESS,
  FETCH_BILL_ACTION,
  FETCH_BILL_FAILURE,
  FETCH_BILL_SUCCESS,
  CREATE_BILL_ACTION,
  CREATE_BILL_FAILURE,
  CREATE_BILL_SUCCESS,
  FETCH_CHECKOUT_URL_ACTION,
  FETCH_CHECKOUT_URL_FAILURE,
  FETCH_CHECKOUT_URL_SUCCESS,
  UPDATE_BILL,
  UPDATE_BILL_FAILURE,
  TOGGLE_IS_GENERATING,
  TOGGLE_UPDATED_BILL,
  TOGGLE_CHARGEBACK_MODAL,
  UPDATE_BILL_SUCCESS,
  CREATE_BILL_ON_SCHEDULE_ACTION,
} from './constants';
import {
  Bill,
  SetEvent,
  FetchSellerInfo,
  FetchSellerInfoFailure,
  FetchSellerInfoSuccess,
  FetchBill,
  FetchBillFailure,
  FetchBillSuccess,
  CreateBill,
  CreateBillFailure,
  CreateBillSuccess,
  FetchCheckoutUrl,
  FetchCheckoutUrlFailure,
  FetchCheckoutUrlSuccess,
  UpdateBill,
  UpdateBillFailure,
  ToggleIsGenerating,
  ToggleUpdatedBill,
  Seller,
  ToggleChargeBackModal,
  Event,
  BillForm,
  UpdateBillSuccess,
  CreateBillOnSchedule,
  BillOnSchedule,
} from './types';

export const setEvent = (event: Event) =>
  createPayloadAction<SetEvent>(SET_EVENT)({ event });

export const fetchSellerInfo = createSimpleAction<FetchSellerInfo>(
  FETCH_SELLER_INFO_ACTION,
);

export const fetchSellerInfoFailure = createFailureAction<
  FetchSellerInfoFailure
>(FETCH_SELLER_INFO_FAILURE);

export const fetchSellerInfoSuccess = (seller: Seller | null) =>
  createPayloadAction<FetchSellerInfoSuccess>(FETCH_SELLER_INFO_SUCCESS)({
    seller,
  });

export const fetchBill = createParamsAction<FetchBill>(FETCH_BILL_ACTION);

export const fetchBillFailure = createFailureAction<FetchBillFailure>(
  FETCH_BILL_FAILURE,
);

export const fetchBillSuccess = ({ bill }: { bill: Bill | null }) =>
  createPayloadAction<FetchBillSuccess>(FETCH_BILL_SUCCESS)({
    bill,
  });

export const createBill = ({ amountCharged }: BillForm) =>
  createPayloadAction<CreateBill>(CREATE_BILL_ACTION)({
    amountCharged,
  });

export const createBillOnSchedule = ({
  amountCharged,
  eventId,
  sellerId,
  patientId,
  patientName,
}: BillOnSchedule) =>
  createPayloadAction<CreateBillOnSchedule>(
    CREATE_BILL_ON_SCHEDULE_ACTION,
  )({
    amountCharged,
    eventId,
    sellerId,
    patientId,
    patientName,
  });

export const createBillFailure = createFailureAction<CreateBillFailure>(
  CREATE_BILL_FAILURE,
);

export const createBillSuccess = ({ bill }: { bill: Bill }) =>
  createPayloadAction<CreateBillSuccess>(CREATE_BILL_SUCCESS)({
    bill,
  });

export const fetchCheckoutUrl = createSimpleAction<FetchCheckoutUrl>(
  FETCH_CHECKOUT_URL_ACTION,
);

export const fetchCheckoutUrlFailure = createFailureAction<
  FetchCheckoutUrlFailure
>(FETCH_CHECKOUT_URL_FAILURE);

export const fetchCheckoutUrlSuccess = ({ url }: { url: string }) =>
  createPayloadAction<FetchCheckoutUrlSuccess>(FETCH_CHECKOUT_URL_SUCCESS)({
    url,
  });

export const updateBill = ({ amountCharged }: BillForm) =>
  createPayloadAction<UpdateBill>(UPDATE_BILL)({
    amountCharged,
  });

export const updateBillFailure = createFailureAction<UpdateBillFailure>(
  UPDATE_BILL_FAILURE,
);

export const updateBillSuccess = ({ amountCharged }: BillForm) =>
  createPayloadAction<UpdateBillSuccess>(UPDATE_BILL_SUCCESS)({
    amountCharged,
  });

export const toggleIsGenerating = ({
  isGenerating,
}: {
  isGenerating: boolean;
}) =>
  createPayloadAction<ToggleIsGenerating>(TOGGLE_IS_GENERATING)({
    isGenerating,
  });

export const toggleUpdatedBill = ({ updatedBill }: { updatedBill: boolean }) =>
  createPayloadAction<ToggleUpdatedBill>(TOGGLE_UPDATED_BILL)({
    updatedBill,
  });

export const toggleChargeBackModal = createSimpleAction<ToggleChargeBackModal>(
  TOGGLE_CHARGEBACK_MODAL,
);
