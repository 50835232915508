// External
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Internal
import Modal from 'ui/new/modal';
import Button from 'ui/new/button';
import * as selectors from 'state/campaign/selectors';
import style from './SendEmailNowModal.scss';

const SendEmailNowModal = ({ onCloseClick, onSendEmailNow, patientCount }) => {
  const isFetching = useSelector((state) => selectors.getEditCampaignsIsFetching(state));
  const onClickSave = useCallback(() => {
    onSendEmailNow();
  }, [onSendEmailNow]);

  const patientCountInfo = patientCount === 1 ? `${patientCount} paciente` : `${patientCount} pacientes`;
  const messageSendEmailNow = `O e-mail será enviado para ${patientCountInfo} imediatamente.`;

  return (
    <div className={style.modal}>
      <Modal show title="Enviar e-mail agora" onClose={onCloseClick} classes={style} width={400}>
        <Modal.Body>
          <p className={style.sendEmailConfirm}>
            {messageSendEmailNow}
          </p>
        </Modal.Body>
        <Modal.Footer align="right">
          <Button type="light" onClick={onCloseClick}>CANCELAR</Button>
          <Button disabled={isFetching} type="success" onClick={onClickSave}>ENVIAR E-MAIL AGORA</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

SendEmailNowModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  onSendEmailNow: PropTypes.func.isRequired,
  patientCount: PropTypes.number.isRequired,
};

export default SendEmailNowModal;
