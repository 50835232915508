// External
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { reset } from 'redux-form';
// Internal

import { captureException } from 'shared/sentryClient';
import { FORM_CREDITCARD } from 'shared/constants/forms';
import { emitErrors } from 'shared/utils/emitErrors';
import iclinic from 'services/iclinic';
import { normalizeAddCreditcardPayload } from './utils';
import * as actions from './actions';
import * as types from './types';
import {
  FAILED_PAYMENT_UPDATE_MESSAGE,
  SUCCESS_CHANGE_CARD,
  SUCCESS_PAYMENT_UPDATE_MESSAGE,
} from './constants';
import { IStoreState } from 'state/rootReducer';
import { resetTurnstile } from 'shared/utils/turnstile';

export const getCreditcard = (state: IStoreState) => state.creditcard;
export const getTurnstileToken = (state: IStoreState) =>
  state.creditcard.turnstileToken;

type CreditCardWorkerParams = {
  type: string;
  payload: types.CreditCardPayload;
};

export function* workerCreditcardSubmit({
  payload: creditcardPayload,
}: CreditCardWorkerParams) {
  try {
    yield put(actions.fetchCreate());
    const normalizedCreditcardPayload =
      normalizeAddCreditcardPayload(creditcardPayload);

    const turnstileToken: string = yield select(getTurnstileToken);

    const { errors, getResponseData } = yield call(
      iclinic.creditcard.fetchCreate,
      { card: { ...normalizedCreditcardPayload, turnstileToken } },
    );
    const { isAccountBlocked } = yield select(getCreditcard);
    if (errors) {
      resetTurnstile();
      yield put(actions.fetchCreateFailure(emitErrors(errors)));
    } else {
      const payload = getResponseData();

      const {
        paymentAttempt,
        paymentAccepted: paymentAcceptedResponse,
        flag,
        finalNumber,
        isAccountBlocked: isAccountBlockedResponse,
      } = payload;

      const paymentAccepted =
        !!paymentAcceptedResponse && paymentAcceptedResponse !== 'Exempt';

      const blockedFeedback =
        paymentAttempt && paymentAccepted
          ? SUCCESS_PAYMENT_UPDATE_MESSAGE
          : FAILED_PAYMENT_UPDATE_MESSAGE;

      const saveFeedback = isAccountBlocked
        ? blockedFeedback
        : SUCCESS_CHANGE_CARD;

      yield put(
        actions.setPaymentData(
          flag,
          finalNumber,
          paymentAttempt,
          isAccountBlockedResponse,
        ),
      );
      yield put(actions.fetchCreateSuccess(saveFeedback));
      yield put(reset(FORM_CREDITCARD));
    }
  } catch (error) {
    yield call(captureException, error);
  }
}

export function* workerGetProductsDetail() {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.creditcard.fetchDetail,
    );
    if (errors) {
      yield put(actions.fetchDetailFailure());
    } else {
      const payload = getResponseData();
      yield put(actions.fetchDetailSuccess(payload));
    }
  } catch (error) {
    yield call(captureException, error);
  }
}

export default function* creditcardSagas() {
  yield takeLatest(types.FETCH_DETAIL.ACTION, workerGetProductsDetail);
  yield takeLatest(types.SUBMIT.ACTION, workerCreditcardSubmit);
}
