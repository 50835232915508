import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme) => ({
  content: {
    width: '500px',
    padding: theme.spacing(3),
  },
  title: {
    padding: theme.spacing(1.5, 2, 1.5, 3),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  line: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    justifyContent: 'space-between',
  },
}));

export default useStyles;
