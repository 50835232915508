import { PromiseResponse } from '@types';

import { SellerType } from 'features/onlinePayment/state/constants';
import { apiOnlinePayment } from '..';
import { FinancialSellerRawData } from './types';

export const getFinancialSellerData = (
  date_start: string,
  date_end: string,
  seller_type?: SellerType,
): PromiseResponse<FinancialSellerRawData> =>
  apiOnlinePayment.get(`/sellers/get-financial-dashboard-data`, {
    params: {
      date_start,
      date_end,
      seller_type,
    },
  });
