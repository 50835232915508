/* eslint-disable import/prefer-default-export */

import { PromiseEnvelope } from '@types';
import { apiV2, apiExportManager } from 'services/iclinic/api';
import { ClinicPhysicians, SendExportRequestRawData } from './types';

export function sendExportRequest(data: SendExportRequestRawData) {
  return apiExportManager.post(`/export/`, data);
}

export function getClinicPhysicians(
  subscriptionId: string,
  clinicId: string,
): PromiseEnvelope<ClinicPhysicians> {
  return apiV2.get(
    `/subscriptions/subscription/${subscriptionId}/clinics-with-physicians/?deleted=true&clinic=${clinicId}`,
  );
}
