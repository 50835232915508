import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router';

import { init } from 'features/onlinePayment/state/config/register';
import { listCBO } from 'features/tuss/cbo/state';
import LpIclinicPay from '../../lp-iclinic-pay/LpIclinicPay';
import SellerRegister from './register/SellerRegister';
import SellerEdit from './edit/SellerEdit';
import ConfigSuccessPage from './success/ConfigSuccessPage';
import SellerProfile from './profile/SellerProfile';

const Seller = ({ match }: RouteComponentProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(init());
    dispatch(listCBO());
  }, [dispatch]);

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={LpIclinicPay} />
      <Route exact path={`${match.path}/cadastro`} component={SellerRegister} />
      <Route exact path={`${match.path}/edit`} component={SellerEdit} />
      <Route
        exact
        path={`${match.path}/sucesso`}
        component={ConfigSuccessPage}
      />
      <Route exact path={`${match.path}/profile`} component={SellerProfile} />
    </Switch>
  );
};

export default Seller;
