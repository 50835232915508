import { Autocomplete, TextField } from '@iclinic/design-system';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as autocompleteActions } from 'features/tissInvoicing/state/autocomplete';
import {
  getAutocomplete,
  getProviderOptions,
} from 'features/tissInvoicing/state/autocomplete/selectors';
import {
  HiredProviderAutocompleteEntry,
  HiredSolicitantCodeEntry,
} from 'features/tissInvoicing/types';
import { debounce } from 'shared/hooks';
import { AppointmentGuideResultValues } from 'features/tissInvoicing/types/appointmentGuide';

export default ({
  label = '10 - Contratado executante',
  required = false,
}: {
  label?: string;
  required?: boolean;
}) => {
  const dispatch = useDispatch();

  const { values, errors, handleBlur, setFieldValue } =
    useFormikContext<AppointmentGuideResultValues>();

  const providerOptions = useSelector(getProviderOptions);
  const { ans_registers } = useSelector(getAutocomplete);

  const fetchPhysiciansAndClinicsOptions = useCallback(
    (name: string) => {
      dispatch(
        autocompleteActions.fetchPhysiciansOptions({
          name,
        }),
      );
      dispatch(autocompleteActions.fetchClinicsOptions({ name }));
    },
    [dispatch],
  );

  const handleFetchHiredOptions = debounce((name: string) => {
    fetchPhysiciansAndClinicsOptions(name);
  }, 500);

  const renderOption = (option: HiredProviderAutocompleteEntry) => {
    if (typeof option === 'string') return option;

    return option?.name ?? '';
  };

  const renderOptionFn = (option: HiredProviderAutocompleteEntry) =>
    `${option?.name}`;

  const handleChange = (value: HiredProviderAutocompleteEntry) => {
    const insuranceId = ans_registers.find(
      (a) => a.health_insurance_register === values.insurance_ans_register,
    )?.id;

    setFieldValue('hired_executant_name', value?.name ?? '');
    setFieldValue('hired_executant_cnes', value?.cnes ?? '');

    if (insuranceId && value?.id && value?.slug) {
      dispatch(
        autocompleteActions.fetchHiredSolicitantData({
          insuranceId,
          solicitantId: value.id,
          type: value.slug,
          callback: ({ code }: HiredSolicitantCodeEntry) =>
            setFieldValue('hired_executant_code', code ?? ''),
        }),
      );
    }
  };

  const handleInputChange = (_: React.ChangeEvent<{}>, value: string) => {
    if (value) {
      if (value === '') {
        handleFetchHiredOptions('');
        return;
      }

      if (value.length >= 3) {
        handleFetchHiredOptions(value);
      }
    }
  };

  const handleOpen = () => {
    fetchPhysiciansAndClinicsOptions('');
  };

  return (
    <Autocomplete
      id="hired_executant_name"
      fullWidth
      freeSolo
      clearOnBlur
      value={values.hired_executant_name}
      onBlur={handleBlur}
      options={providerOptions}
      groupBy={(option) => option.type!}
      onChange={(_, value: any) => handleChange(value)}
      getOptionLabel={renderOption}
      renderOption={renderOptionFn}
      onInputChange={handleInputChange}
      onOpen={handleOpen}
      renderInput={(params) => (
        <TextField
          label={label}
          name="hired_executant_name"
          data-ga="hired_executant_name"
          error={!!errors.hired_executant_name}
          required={required}
          {...params}
        />
      )}
    />
  );
};
