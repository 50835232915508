import { createSelector } from 'reselect';

import { StoreState } from 'state/rootReducer';
import {
  ExecutantTypeOptions,
  Lot,
  SnackbarProps,
} from 'features/tissInvoicing/types';

export const getSnackbarState = ({
  tissInvoicing,
}: StoreState): SnackbarProps => tissInvoicing.lot.snackbar;

export const getLotDataState = ({
  tissInvoicing,
}: StoreState): { loading: boolean; lot: Lot } => tissInvoicing.lot.lotData;

export const getAddGuidesState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.lot.addGuides;

export const getAvailableGuides = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.lot.addGuides.availableGuides;

export const getGuides = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.lot.listGuides;

export const getLotViewState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.lot.lotView;

export const getLotListState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.lot.listLot;

export const getDownloadXMLState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.lot.downloadXML;

export const getLotConfigOptionsPhysicians = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.lot.lotView?.configOptions?.physicians;

export const getLotConfigOptionsClinics = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.lot.lotView?.configOptions?.clinics;

export const getHiredOptions = createSelector(
  getLotConfigOptionsPhysicians,
  getLotConfigOptionsClinics,
  (physicians, clinics) => [
    ...physicians.map((p) => ({
      id: p.id,
      name: p.name,
      label: 'Profissionais',
      type: ExecutantTypeOptions.Professional,
    })),
    ...clinics.map((c) => ({
      id: c.id,
      name: c.name,
      label: 'Clínicas',
      type: ExecutantTypeOptions.Clinic,
    })),
  ],
);
