import React from 'react';
import { MaterialUICore, Body } from '@iclinic/design-system';

import { ColumnProps } from 'features/tissInvoicing/types/types.common';

const { TableCell } = MaterialUICore;

interface IColumnsProps {
  columns: ColumnProps[];
}
const Columns: React.FC<IColumnsProps> = ({ columns }) => (
  <>
    {columns.map((c) => (
      <TableCell align={c.align} key={c.name} size={c.size}>
        <Body variant="xs">{c.name}</Body>
      </TableCell>
    ))}
  </>
);

export default Columns;
