import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import {
  Award,
  AwardSelected,
  RedeemAwardData,
  SaveInterestParams,
  ShortUrls,
  WebShareDataParams,
} from '../types';

export interface ReferralsState {
  status: RequestStatus;
  shortUrls?: ShortUrls;
  offCanvasOpen: boolean;
  rewardCongratulations: boolean;
  saveInterestStatus?: RequestStatus;
  awards?: {
    status?: RequestStatus;
    data?: Award[];
    redeemStatus?: RequestStatus;
    selected?: AwardSelected;
  };
}

export const initialState: ReferralsState = {
  status: RequestStatus.Pending,
  offCanvasOpen: false,
  rewardCongratulations: false,
  awards: {
    status: RequestStatus.Pending,
  },
};

const nameStore = '@webapp/referrals';

export const fetchReferralsParticipant = createAction(
  `${nameStore}/fetchReferralsParticipant`,
);

export const saveInterestIndication = createAction<SaveInterestParams>(
  `${nameStore}/saveInterestIndication`,
);

export const fetchReferralAwards = createAction(
  `${nameStore}/fetchReferralAwards`,
);

export const triggerReferralRedeemAwards = createAction<RedeemAwardData>(
  `${nameStore}/triggerReferralRedeemAwards`,
);

export const webShareData = createAction<WebShareDataParams>(
  `${nameStore}/webShareUrlData`,
);

export const trackIndicationToIntercom = createAction(
  `${nameStore}/trackIndicationToIntercom`,
);

export const checkContactIsAPromoter = createAction(
  `${nameStore}/checkContactIsAPromoter`,
);

const ReferralsSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchReferralsParticipantSuccess: (
      state,
      action: PayloadAction<ShortUrls>,
    ) => ({
      ...state,
      status: RequestStatus.Success,
      shortUrls: action.payload,
    }),
    fetchReferralsParticipantFailure: (state) => ({
      ...state,
      status: RequestStatus.Error,
    }),
    toggleOffCanvasOpenClose: (state) => ({
      ...state,
      offCanvasOpen: !state.offCanvasOpen,
    }),
    updateSaveInterestIndicationStatus: (
      state,
      action: PayloadAction<RequestStatus>,
    ) => ({
      ...state,
      saveInterestStatus: action.payload,
    }),
    fetchReferralAwardsSuccess: (state, action: PayloadAction<Award[]>) => ({
      ...state,
      awards: {
        ...state.awards,
        status: RequestStatus.Success,
        data: action.payload,
      },
    }),
    fetchReferralAwardsFailure: (state) => ({
      ...state,
      awards: {
        ...state.awards,
        status: RequestStatus.Error,
      },
    }),
    updateAwardRedeemStatus: (state, action: PayloadAction<RequestStatus>) => ({
      ...state,
      awards: {
        ...state.awards,
        redeemStatus: action.payload,
      },
    }),
    updateAwardSelected: (
      state,
      action: PayloadAction<AwardSelected | undefined>,
    ) => ({
      ...state,
      awards: {
        ...state.awards,
        selected: action.payload,
      },
    }),
    updateReferralAwards: (state, action: PayloadAction<Award[]>) => ({
      ...state,
      awards: {
        ...state.awards,
        data: action.payload,
      },
    }),
    updateRewardCongratulations: (state, action: PayloadAction<boolean>) => ({
      ...state,
      rewardCongratulations: action.payload,
    }),
  },
});

export default ReferralsSlice.reducer;

export const {
  fetchReferralsParticipantSuccess,
  fetchReferralsParticipantFailure,
  toggleOffCanvasOpenClose,
  updateSaveInterestIndicationStatus,
  fetchReferralAwardsSuccess,
  fetchReferralAwardsFailure,
  updateAwardRedeemStatus,
  updateAwardSelected,
  updateReferralAwards,
  updateRewardCongratulations,
} = ReferralsSlice.actions;
