/* eslint-disable import/prefer-default-export */

import { PromiseEnvelope } from '@types';
import { apiExportManager } from 'services/iclinic/api';
import { ExportListRawData } from './types';

export function getExportList(
  pageNumber: number,
): PromiseEnvelope<ExportListRawData> {
  return apiExportManager.get(`/export/?page=${pageNumber}`);
}
