import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ApiErrors } from '@types';

import {
  fetchPatientInfo,
  fetchPhysicianInfo,
} from 'features/video-conference/services';
import { useConference } from 'features/video-conference/state/conferenceContext';
import {
  Participant,
  Schedule,
} from 'features/video-conference/constants/types';

interface ParticipantContextData {
  remoteParticipant: Participant;
  localParticipant: Participant;
  patientInfo: Participant;
  physicianInfo: Participant;
  scheduleInfo: Schedule;
  isFetchingInfo: boolean;
  getParticipantsInfo: () => Promise<{
    patient: Participant;
    physician: Participant;
  }>;
}
interface ParticipantProviderProps {
  children: React.ReactNode;
}

export const ParticipantContext = createContext<ParticipantContextData>(
  {} as ParticipantContextData,
);

export const ParticipantProvider = ({ children }: ParticipantProviderProps) => {
  const { isPhysician, onError } = useConference();
  const [remoteParticipant, setRemoteParticipant] = useState<Participant>(
    null!!,
  );
  const [localParticipant, setLocalParticipant] = useState<Participant>(null!!);
  const [patientInfo, setPatientInfo] = useState<Participant>(null!!);
  const [physicianInfo, setPhysicianInfo] = useState<Participant>(null!!);
  const [scheduleInfo, setScheduleInfo] = useState<Schedule>(null!!);
  const [isFetchingInfo, setIsFetchingInfo] = useState<boolean>(true);
  const [errors, setErrors] = useState<ApiErrors[]>([]);

  const getParticipantsInfo = useCallback(async () => {
    const { getResponseData: getPatientData, errors: patientErrors } =
      await fetchPatientInfo();

    if (patientErrors) {
      setErrors(patientErrors);
    }
    const { patient, schedule } = getPatientData();

    const { getResponseData: getPhysicianData, errors: physicianError } =
      await fetchPhysicianInfo();

    if (physicianError) {
      setErrors(physicianError);
    }

    const { physician } = getPhysicianData();

    setRemoteParticipant(isPhysician ? patient : physician);
    setLocalParticipant(isPhysician ? physician : patient);
    setPatientInfo(patient);
    setPhysicianInfo(physician);
    setScheduleInfo(schedule);
    setIsFetchingInfo(false);

    return {
      patient,
      physician,
    };
  }, [isPhysician]);

  useEffect(() => {
    getParticipantsInfo() as unknown;
  }, [getParticipantsInfo, isPhysician]);

  useEffect(() => {
    if (errors) errors.forEach((error) => onError(error));
  }, [errors, onError]);

  const contextValue = useMemo(
    () => ({
      scheduleInfo,
      remoteParticipant,
      localParticipant,
      patientInfo,
      physicianInfo,
      isFetchingInfo,
      getParticipantsInfo,
    }),
    [
      isFetchingInfo,
      localParticipant,
      patientInfo,
      physicianInfo,
      remoteParticipant,
      scheduleInfo,
      getParticipantsInfo,
    ],
  );

  return (
    <ParticipantContext.Provider value={contextValue}>
      {children}
    </ParticipantContext.Provider>
  );
};

export function useParticipantContext(): ParticipantContextData {
  const context = useContext(ParticipantContext);

  if (!context) {
    throw new Error(
      'useParticipantContext must be used within an ParticipantProvider',
    );
  }
  return context;
}
