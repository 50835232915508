export const TOGGLE_TRANSACTIONS_DRAWER =
  '@webapp/schedule/TOGGLE_TRANSACTIONS_DRAWER';
export const TOGGLE_SCHEDULE_MODAL = '@webapp/schedule/TOGGLE_SCHEDULE_MODAL';
export const TOGGLE_PATIENT_WAITING_LIST =
  '@webapp/schedule/TOGGLE_PATIENT_WAITING_LIST';
export const TOGGLE_SCHEDULE_OBSERVATION =
  '@webapp/schedule/TOGGLE_SCHEDULE_OBSERVATION';
export const TOGGLE_PERIOD_PRINT_SCHEDULE =
  '@webapp/schedule/TOGGLE_PERIOD_PRINT_SCHEDULE';
export const PRINT_SCHEDULE_ACTION = '@webapp/schedule/PRINT_SCHEDULE_ACTION';
export const PRINT_SCHEDULE_SUCCESS = '@webapp/schedule/PRINT_SCHEDULE_SUCCESS';
export const PRINT_SCHEDULE_FAILURE = '@webapp/schedule/PRINT_SCHEDULE_FAILURE';
export const OPEN_SCHEDULE_SETTINGS = '@webapp/schedule/OPEN_SCHEDULE_SETTINGS';
