import {
  MaterialUICore,
  tokens,
  Box,
  Heading as DSHeading,
  Alert as DSAlert,
  Body,
} from '@iclinic/design-system';

import { ICLINIC_APP_BREAKPOINT } from '../../constants';

const { styled } = MaterialUICore;
const { color, spacing, border } = tokens;
const breakpoint = `@media ${ICLINIC_APP_BREAKPOINT}`;

export const BoxTrigger = styled(Box)({
  width: `calc(100% - ${spacing.xl})`,
  margin: `0 auto ${spacing.base}`,
  backgroundColor: color.secondary.main,
  textAlign: 'center',
  borderRadius: border.radius.sm,
  padding: spacing.base,
  cursor: 'pointer',

  '&:hover .MuiButton-root': {
    border: `1px solid ${color.primary.dark}`,
    color: color.primary.dark,
  },

  [breakpoint]: {
    padding: `${spacing.base} ${spacing.xs} ${spacing.xs}`,
    width: `calc(100% - ${spacing.sm})`,
  },
});

export const CloseWrapper = styled(Box)({
  padding: spacing.base,

  '&:hover': {
    color: color.primary.dark,
  },
});

export const Header = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const Content = styled('div')({
  display: 'flex',
  alignItems: 'center',
  margin: `0 auto ${spacing.sm}`,
  gap: spacing.base,
  maxWidth: 400,

  [breakpoint]: {
    flexDirection: 'column',
    margin: `0 0 ${spacing.xs}`,
  },
});

export const ImageWrapper = styled(Box)({
  width: '100%',

  [breakpoint]: {
    maxWidth: 182,
  },
});

export const Heading = styled(DSHeading)({
  letterSpacing: 0,
});

export const Alert = styled(DSAlert)({
  width: `calc(100% - ${spacing.lg})`,
  margin: `${spacing.sm} auto`,
  cursor: 'pointer',

  '& .MuiSnackbarContent-action': {
    marginTop: 0,
  },
});

export const Description = styled(Body)({
  display: 'none',

  [breakpoint]: {
    display: 'block',
  },
});
