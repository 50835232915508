// External
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

// Internal
import style from './Item.scss';

const classes = classnames.bind(style);

const Item = ({ children, onClick, selected }) => (
  <div className={classes('item', { isSelected: selected })} onClick={onClick} role="button" tabIndex={0}>
    {children}
  </div>
);

Item.defaultProps = {
  onClick: null,
  selected: false,
};

Item.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

export default Item;
