// External
import React from 'react';
import style from './ListEmailFlow.scss';

interface StateProps {
  children: JSX.Element[];
  searchEmailFlow: JSX.Element;
}

const ListEmailFlowMobile = (
  { children, searchEmailFlow }: StateProps,
): JSX.Element => (
  <div className={style.listEmailFlowMobile}>
    {searchEmailFlow}
    {children}
  </div>
);

export default ListEmailFlowMobile;
