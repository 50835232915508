import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const { color, font, line } = tokens;

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  switchLabel: {
    color: color.neutral[50],
    fontSize: font.size.xxs,
    fontWeight: font.weight.regular,
    lineHeight: line.height.sm,
  },
  warningLabel: {
    color: color.feedback.error.main,
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
}));

export default useStyles;
