import { ActionCreator, ApiErrors } from '@types';

import { TOGGLE_MODAL, NOTIFY_INTENT, initialState } from './constants';

export type RootState = Readonly<typeof initialState>;
export type RootActions = ActionCreator<string, Partial<RootState>>;

export const toggleModal = (): RootActions => <const>{
  type: TOGGLE_MODAL.ACTION,
  payload: {},
};

export const notifyIntent = (): RootActions => <const>{
  type: NOTIFY_INTENT.ACTION,
  payload: {},
};

export const notifyIntentSuccess = (): RootActions => <const>{
  type: NOTIFY_INTENT.SUCCESS,
  payload: {},
};

export const notifyIntentFailure = (errors: ApiErrors[]): RootActions => <const>{
  type: NOTIFY_INTENT.FAILURE,
  payload: {
    errors,
  },
};
