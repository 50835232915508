// External
import React, { useEffect, useCallback, useMemo } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

// Internal
import { StoreState } from 'state/rootReducer';
import { FORM_STOCK_INPUT as form } from 'shared/constants/forms';
import { useDebounce } from 'shared/hooks';
import InputSearch from 'ui/new/input-search';
import { StockProduct, SelectedLot } from 'features/stock/state/types';
import {
  createLot,
  changeProductDescription,
  fetchProductsByName,
  createInventory,
  changeLotDescription,
  changeProduct,
  changeLot,
} from 'features/stock/state/actions';
import {
  getLots,
  showAddMore as showAddMoreSelector,
  lotIsOpen as lotIsOpenSelector,
} from '../state/selectors';
import { StockKind, MIN_CHARS_TO_SEARCH } from '../constants';
import style from './EnhanceStockInputProductForms.scss';

export type InputRenderProps = {
  lotItems: JSX.Element | null;
  lotAddMore: JSX.Element | null;
  productItems: JSX.Element | null;
  showAddMore: boolean;
  onChangeProductDescription(value: string): void;
  onChangeLotDescription(value: string): void;
  selectedProduct: any; // todo: refactor, stock architecture problem
  productDescription: string;
  lotDescription: string;
  lotIsOpen: boolean;
  selectedLot: SelectedLot | null;
  createdLot: SelectedLot | null;
} & InjectedFormProps;

type EnhanceStockInputProductFormsProps = {
  render(params: InputRenderProps): JSX.Element;
};

function EnhanceStockInputProductForms({
  render,
  handleSubmit,
}: EnhanceStockInputProductFormsProps & InjectedFormProps): JSX.Element {
  const dispatch = useDispatch();

  const stock = useSelector((state: StoreState) => state.stock);
  const lots = useSelector<StoreState>(getLots);
  const showAddMore = useSelector<StoreState>(showAddMoreSelector);
  const lotIsOpen = useSelector<StoreState>(lotIsOpenSelector);

  const {
    productDescription,
    lotDescription,
    selectedProduct,
    selectedLot,
    products: stockProducts,
    lot: createdLot,
  } = stock;

  const debouncedProductDescription = useDebounce(productDescription, 500);

  const lotItems = useMemo(
    () =>
      lots.length > 0 &&
      lots.map((lot) => (
        <InputSearch.Item
          id={lot.id}
          key={lot.id}
          selected={lot.description === lotDescription}
          onClick={() => dispatch(changeLot(lot))}
        >
          {lot.description}
        </InputSearch.Item>
      )),
    [dispatch, lots, lotDescription],
  );

  const productItems = useMemo(
    () =>
      stockProducts.length > 0 &&
      stockProducts.map((product: StockProduct) => (
        <InputSearch.Item
          id={product.id}
          key={product.id}
          selected={product.description === productDescription}
          onClick={() => dispatch(changeProduct(product))}
        >
          {product.description}
        </InputSearch.Item>
      )),
    [dispatch, stockProducts, productDescription],
  );

  const onChangeProductDescription = useCallback(
    (value: string): void => {
      dispatch(changeProductDescription(value));
    },
    [dispatch],
  );

  const onChangeLotDescription = useCallback(
    (value: string): void => {
      dispatch(changeLotDescription(value));
    },
    [dispatch],
  );

  useEffect((): void => {
    dispatch(changeProductDescription(debouncedProductDescription));

    if (debouncedProductDescription.length >= MIN_CHARS_TO_SEARCH) {
      dispatch(
        fetchProductsByName({ description: debouncedProductDescription }),
      );
    }
  }, [dispatch, debouncedProductDescription]);

  const lotAddMore = useMemo(
    (): JSX.Element => (
      <InputSearch.Item
        id="add-more"
        className={style.addMore}
        onClick={() => dispatch(createLot({ description: lotDescription }))}
      >
        Adicionar&nbsp;
        <strong>{lotDescription}</strong>
        ...
      </InputSearch.Item>
    ),
    [dispatch, lotDescription],
  );

  return render({
    // redux form
    handleSubmit,
    lotItems,
    lotAddMore,
    productItems,
    showAddMore,
    lotIsOpen,
    createdLot,
    // products
    onChangeProductDescription,
    productDescription,
    selectedProduct,
    // lots
    onChangeLotDescription,
    lotDescription,
    selectedLot,
  });
}

export default reduxForm({
  form,
  touchOnBlur: false,
  onSubmitSuccess: (
    { closeModal, observation, productId, quantity, lotId, expirationDate },
    dispatch,
  ) => {
    const payload = {
      closeModal,
      observation,
      productId,
      quantity,
      lotId,
      expirationDate,
      kind: StockKind.Input,
    };

    dispatch(createInventory(payload));
  },
})(EnhanceStockInputProductForms);
