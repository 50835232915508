// External
import React, { useState } from 'react';
import Editor from 'draft-js-plugins-editor';
import Icon from '@material/react-material-icon';
import Immutable from 'immutable';
// External
import { EditorState } from 'draft-js';

// Internal
import { typeStyleEditor, optionsAutomatedTags } from 'ui/new/editor/constants';
import { CurrentInfoEditor } from 'state/marketing/emailFlow/emailFlowManagement/emailFlowTypes';
import Button from 'ui/new/button';
import Dropdown from 'ui/new/dropdown';
import { image } from 'shared/utils/urlGetter';
import { Tooltip } from 'ui/overlay';
import AutomatedTagsContainer from 'ui/new/editor/AutomatedTagsContainer';
import useDraftJSCallbacks from 'ui/new/editor/useDraftJSCallbacks';
import createDraftJSPlugin from 'ui/new/editor/createDraftJSPlugin';
import { blockStyleFn, blockRender, styleMap } from 'ui/new/editor';

import style from './TextEditor.scss';

interface TextEditorProps {
  editor: any;
  updateTextEditor:any;
  openModalEditorLink: (editor: EditorState) => void;
  openModalEditorButton: (editor: EditorState) => void;
  openModalEditorImage: (editor: EditorState) => void;
  editorOptionsText: EditorOptionsText[];
  currentInfo: CurrentInfoEditor;
  onAutomatedTagChosen: (tag: string) => void;
}

type EditorOptionsText = {
  id: number;
  name: string;
  selected: boolean;
};

// disabled warn draftJS Immutable
Immutable.Iterable.noLengthWarning = true;

const getActiveEditorButtonStyle = (active: boolean) => (active ? 'active' : 'light');
const optionsAutomatedTagsKeys = Object.keys(optionsAutomatedTags);

const imageButton = {
  active: image('campaign.editorButtonActive'),
  light: image('campaign.editorButton'),
};

const iconsDropDownFormatText = () => (
  <Icon className={style.iconFont} icon="format_size" />
);
const { plugins, AlignmentTool } = createDraftJSPlugin();

const TextEditor = ({
  editor,
  updateTextEditor,
  openModalEditorLink,
  openModalEditorButton,
  openModalEditorImage,
  editorOptionsText,
  currentInfo,
  onAutomatedTagChosen,
}: TextEditorProps): JSX.Element => {
  const [isOpen, setToggleMenu] = useState(false);

  const { editorState } = editor;
  const {
    formatTextBold,
    formatTextItalic,
    handleKeyCommand,
    formatTextTitle,
    formatTextNormal,
    formatAlignCenter,
    formatAlignLeft,
    openFormatLink,
    openFormatButton,
    openFormatImage,
    keyBindingFn,
  } = useDraftJSCallbacks(
    editorState,
    updateTextEditor,
    openModalEditorLink,
    openModalEditorButton,
    openModalEditorImage,
    currentInfo,
  );

  const editorElement = React.useRef(null);
  const selectItem = React.useCallback((id: number) => {
    if (id === typeStyleEditor.normalID) {
      formatTextNormal();
    } else {
      formatTextTitle();
    }
  }, [formatTextNormal, formatTextTitle]);

  const handleFocus = React.useCallback(() => {
    if (editorElement.current) {
      editorElement.current.focus();
    }
  }, [editorElement]);

  const activeInlineStyle = (type: string) => getActiveEditorButtonStyle(
    currentInfo.inlineStyles.has(type),
  );
  const activeBlockStyle = (type: string) => getActiveEditorButtonStyle(
    currentInfo.blockType === type,
  );
  const activeEntityStyle = (type: string) => getActiveEditorButtonStyle(
    currentInfo.entityType === type,
  );

  return (
    <div>
      <div className={style.toolBarStyle}>
        <div className={style.dropdownFormatFont}>
          <Dropdown
            title={iconsDropDownFormatText()}
            isOpenProp={isOpen}
            setToggleMenuProp={setToggleMenu}
          >
            {editorOptionsText.map((item: EditorOptionsText) => (
              <Dropdown.Item onClick={() => selectItem(item.id)} selected={item.selected} key={item.id} kind="exists">
                {item.selected && <Icon icon="done" className={style.toolBarStyle__dropDownIcon} />}
                {item.name}
              </Dropdown.Item>
            ))}
          </Dropdown>
        </div>
        <Button type={activeInlineStyle(typeStyleEditor.bold)} onClick={formatTextBold}><Icon icon="format_bold" /></Button>
        <Button type={activeInlineStyle(typeStyleEditor.italic)} onClick={formatTextItalic}><Icon icon="format_italic" /></Button>
        <Button type={activeBlockStyle(typeStyleEditor.alignLeft)} onClick={formatAlignLeft}><Icon icon="format_align_left" /></Button>
        <Button type={activeBlockStyle(typeStyleEditor.alignCenter)} onClick={formatAlignCenter}><Icon icon="format_align_center" /></Button>
        <Button type={activeEntityStyle(typeStyleEditor.image)} onClick={openFormatImage}><Icon icon="image" /></Button>
        <Button type={activeEntityStyle(typeStyleEditor.link)} onClick={openFormatLink}><Icon icon="link" /></Button>
        <Button type="light" onClick={openFormatButton}><img className={style.iconButton} alt="" src={imageButton[activeEntityStyle(typeStyleEditor.button)]} /></Button>
      </div>

      <div
        className={style.editorContainer}
        title="corpo do e-mail"
        onClick={handleFocus}
        onKeyPress={handleFocus}
        role="presentation"
      >
        <Editor
          ref={editorElement}
          customStyleMap={styleMap}
          editorState={editorState}
          onChange={updateTextEditor}
          keyBindingFn={keyBindingFn}
          handleKeyCommand={handleKeyCommand}
          blockRenderMap={blockRender}
          blockStyleFn={blockStyleFn}
          plugins={plugins}
        />
        <AlignmentTool />
      </div>
      <div className={style.automatedTags}>
        <div className={style.automatedTagsTooltip}>
          Tags automatizadas
          <Tooltip
            content="Clique nas tags para inseri-las no texto a ser enviado. Elas serão substituídas automaticamente pelos dados reais no momento do envio."
            align="top"
          >
            <Icon icon="info" className={style.infoIcon} />
          </Tooltip>
        </div>
        <AutomatedTagsContainer
          currentAutomatedTag={(currentInfo.entityData || {}).automatedTag}
          onAutomatedTagChosen={onAutomatedTagChosen}
          tags={optionsAutomatedTagsKeys}
        />
      </div>
    </div>
  );
};

export default TextEditor;
