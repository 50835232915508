import { Button, MaterialIcons } from '@iclinic/design-system';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import uuid from 'uuid/v1';

import { actions } from 'features/tissInvoicing/state/expense';
import {
  getExpenseAutocompleteState,
  getExpenseCreationLoading,
  getExpensesDetails,
} from 'features/tissInvoicing/state/expense/selectors';
import { normalizeExpenses } from 'features/tissInvoicing/containers/expense/normalize';
import { IAutocompleteOption } from '../interfaces/AutocompleteOption';
import { IChangeExpensePayload } from '../interfaces/ChangeExpensePayload';
import { IExpenseItem } from '../interfaces/ExpenseItem';
import ExpenseError from './error';
import Expenses from './expenses';
import Header from './header';
import HeaderInputs from './headerInputs';
import ExpenseLoader from './loader';
import * as Styles from './styles';
import { checkExpenseFormErrors } from './utils/checkErrors';
import { fetchListOfHealthInsuranceAndTables } from './utils/loadData';

const { Save } = MaterialIcons;

const AddExpense = ({
  match: { params },
}: RouteComponentProps<{ id?: string }>) => {
  const { id: expenseId } = params;

  const dispatch = useDispatch();
  const loading = useSelector(getExpenseCreationLoading);
  const { typeOptions = [], unitTypeOptions = [] } = useSelector(
    getExpenseAutocompleteState,
  );
  const {
    expenses: expenseItems,
    healthInsurance,
    procedure,
  } = useSelector(getExpensesDetails);

  const [expenses, setExpenses] = useState<IExpenseItem[]>([]);
  const [headerInputs, setHeaderInputs] = useState<{
    procedure?: IAutocompleteOption;
    healthInsurance?: IAutocompleteOption;
  }>({
    procedure: { id: null, title: '' },
    healthInsurance: { id: null, title: '' },
  });
  const [showErrors, setShowErrors] = useState(false);

  useEffect(() => {
    fetchListOfHealthInsuranceAndTables(dispatch, expenseId);
  }, [dispatch, expenseId]);

  useEffect(() => {
    if (expenseItems?.length && !expenses.length) {
      const normalizedExpenses = normalizeExpenses(
        expenseItems,
        typeOptions,
        unitTypeOptions,
      );
      setExpenses(normalizedExpenses);
      setHeaderInputs({ procedure, healthInsurance });
    }
  }, [
    expenseItems,
    typeOptions,
    unitTypeOptions,
    expenses,
    procedure,
    healthInsurance,
  ]);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (loading) {
        return;
      }

      setShowErrors(true);

      const payload = {
        ...headerInputs,
        expenses,
        id: expenseId,
      };

      const hasErrors = checkExpenseFormErrors(payload);

      if (hasErrors) {
        return;
      }
      dispatch(actions.createExpenseFetch(payload));
    },

    [loading, headerInputs, expenses, expenseId, dispatch],
  );

  const handleClickAddExpense = () => {
    const newExpense: IExpenseItem = {
      id: uuid(),
      name: '',
      quantity: 0,
      value: 0,
      unity_type: {
        id: null,
        title: ' ',
      },
    };

    setExpenses([...expenses, newExpense]);
  };

  const handleClickDeleteExpense = useCallback(
    (id: number | string) => {
      setExpenses(expenses.filter((item) => item.id !== id));
    },
    [expenses],
  );

  const handleChangeHeaderInputs = useCallback(
    (name: string, value: any) => {
      setHeaderInputs({ ...headerInputs, [name]: value });
    },
    [headerInputs],
  );

  const handleChangeExpense = useCallback(
    (payload: IChangeExpensePayload) => {
      const { position, fieldName, value } = payload;
      const tmp: any[] = [...expenses];
      switch (fieldName) {
        case 'type':
          tmp[position - 1].tuss_table = value.related_tuss_table.code;
          break;
        case 'unity_type':
          tmp[position - 1].unit_type = value.id;
          break;
        default:
          break;
      }
      tmp[position - 1][fieldName] = value;
      setExpenses(tmp);
    },
    [expenses],
  );

  useEffect(() => {
    if (!expenseId && !expenses.length) {
      handleClickAddExpense();
    }
  });

  return (
    <Styles.Container>
      <ExpenseError />

      <Header />

      <form onSubmit={handleSubmit}>
        <HeaderInputs
          onChange={handleChangeHeaderInputs}
          procedure={headerInputs?.procedure}
          healthInsurance={headerInputs?.healthInsurance}
          showErrors={showErrors}
        />

        {loading && <ExpenseLoader status={expenseId ? 'edit' : 'create'} />}

        <Expenses
          items={expenses}
          onClickAdd={handleClickAddExpense}
          onClickDelete={handleClickDeleteExpense}
          onChange={handleChangeExpense}
          showErrors={showErrors}
        />

        <Button
          data-testid="submitNewExpense"
          type="submit"
          color="primary"
          startIcon={<Save />}
        >
          Salvar
        </Button>
      </form>
    </Styles.Container>
  );
};

export default AddExpense;
