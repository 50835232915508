import { MaterialUICore, Select, tokens } from '@iclinic/design-system';

import { FlexContainer } from 'features/newFinance/components/styles';

const { styled } = MaterialUICore;
export const PersonalInfo = styled(FlexContainer)(({ theme }) => ({
  alignItems: 'center',
  color: tokens.color.neutral[700],
  '& span': {
    lineHeight: tokens.line.height.sm,
    fontSize: tokens.font.size.xxxs,
  },
  '& .personal_name': {
    fontSize: tokens.font.size.sm,
    color: tokens.color.neutral[900],
    lineHeight: tokens.line.height.lg,
    marginLeft: tokens.spacing.md,
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& div:first-child': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '& .personal_name': {
      margin: `0 0 ${tokens.spacing.sm}`,
      marginBottom: tokens.spacing.sm,

      textAlign: 'center',
    },
  },
}));

export const LeftSubContainer = styled(FlexContainer)(({ theme }) => ({
  margin: `0 ${tokens.spacing.md}`,
  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    margin: 0,
    '& span': {
      textAlign: 'center',
    },
  },
}));

export const RightSubContainer = styled(FlexContainer)(({ theme }) => ({
  borderLeft: `${tokens.border.width.hairline} solid ${tokens.color.neutral[300]}`,
  paddingLeft: tokens.spacing.md,
  justifyContent: 'center',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    position: 'relative',
    paddingLeft: 0,
    borderLeft: 'none',
    alignItems: 'center',
    marginTop: tokens.spacing.sm,
    paddingTop: tokens.spacing.sm,
    '&:before': {
      position: 'absolute',
      content: "''",
      top: '0',
      height: tokens.border.width.hairline,
      width: '30px',
      background: tokens.color.neutral['300'],
    },
  },
}));

type ImageIconProps = {
  imageUrl: string | undefined;
  theme: MaterialUICore.Theme;
};

export const ImageIcon = styled(FlexContainer)(
  ({ imageUrl = '', theme }: ImageIconProps) => ({
    width: '75px',
    height: '75px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundImage: imageUrl && `url(${imageUrl})`,
    backgroundColor: tokens.color.neutral[500],
    color: tokens.color.neutral[0],
    fontSize: tokens.font.size.xl,
    fontFamily: 'Roboto',
    marginRight: tokens.spacing.md,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  }),
);

export const DateInfo = styled('span')(({ theme }) => ({
  color: tokens.color.neutral[900],
  fontSize: `${tokens.font.size.sm} !important`,
  fontWeight: tokens.font.weight.medium,
  lineHeight: tokens.line.height.md,
  marginLeft: '95px',
  '&.only_mobile': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'block',
    width: '100%',
    marginLeft: 0,
    textAlign: 'center',
    '&.only_mobile': {
      display: 'block',
      margin: `${tokens.spacing.md} 0 ${tokens.spacing.sm}`,
    },
    '&.only_desktop': {
      display: 'none',
    },
  },
  '& > svg': {
    width: '14px',
    height: '14px',
  },
}));

export const ScheduleDetailSelect = styled(Select)(() => ({
  width: '151px',
  height: '30px',
  marginLeft: '30px',
  padding: '0px !important',
  borderRadius: tokens.border.radius.sm,
  fontFamily: 'Roboto',
  '& > div > div': {
    padding: '7px 13px !important',
    display: 'flex',
    alignItems: 'center',
    fontSize: tokens.font.size.xxs,
    color: tokens.color.neutral[700],
    fontWeight: tokens.font.weight.medium,
    gap: '3px',
  },
}));
