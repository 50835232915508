import { useEffect } from 'react';
import { FormikErrors, FormikTouched } from 'formik';

import { trackRequestSamplesFormStep } from '../trackRequestSamplesFormUtils';
import { RequestSamplesFormData } from '../containers/RequestSamplesForm/types';
import { UserInfo } from '../state/request-samples/types';

export const useTermsFormErrorTracking = (
  errors: FormikErrors<RequestSamplesFormData>,
  touched: FormikTouched<RequestSamplesFormData>,
  userInfo: UserInfo,
) => {
  useEffect(() => {
    if (errors.acceptTermDataProtection && touched.acceptTermDataProtection) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'accept_data_consent_terms',
        stepNum: 14,
        failureReason: errors.acceptTermDataProtection,
      });
    }
  }, [
    errors.acceptTermDataProtection,
    touched.acceptTermDataProtection,
    userInfo,
  ]);

  useEffect(() => {
    if (errors.acceptTerms && touched.acceptTerms) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'accept_adhesion',
        stepNum: 15,
        failureReason: errors.acceptTerms,
      });
    }
  }, [errors.acceptTerms, touched.acceptTerms, userInfo]);
};
