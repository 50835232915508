import React from 'react';
import Dropdown from 'ui/new/dropdown';

import { Professional } from 'state/marketing/emailFlow/professional/types';

interface Props {
  onChange: (professional: Professional) => void,
  professional: Professional,
}

export default function DropdownProfessionalItems({
  onChange,
  professional,
}: Props): JSX.Element {
  return (
    <Dropdown.Item
      key={`${professional.physician_id}-professional`}
      id={professional.physician_id}
      onClick={() => onChange(professional)}
    >
      {professional.name}
    </Dropdown.Item>
  );
}
