import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = tokens;

export const TermsWrapper = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  marginBottom: spacing.md,
  textAlign: 'left',

  '& .MuiFormControlLabel-root': {
    marginRight: 0,
    marginLeft: 0,
  },
}));
