export const VIEWSIZE = {
  Medium: 730,
  Large: 1208,
};

export const ALIGN_FOOTER = {
  left: 'left',
  right: 'right',
  center: 'center',
  split: 'split',
};
