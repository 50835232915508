/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  MaterialUICore,
  tokens,
  Button as ButtonDS,
} from '@iclinic/design-system';
import { ButtonProps } from '@iclinic/design-system/dist/components/Button/types';

const { styled } = MaterialUICore;
const { spacing, shadow } = tokens;

export const OuterContainer = styled('div')(() => ({
  minHeight: 0,
  flex: 1,
  position: 'relative',
}));

export const InnerScrollContainer = styled('div')(() => ({
  height: '100%',
  overflowY: 'auto',
  padding: `0 ${spacing.md} 0`,
}));

export const MessageListContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  paddingBottom: spacing.sm,
}));

type Props = {
  isShowingButton: boolean;
};

export const Button = styled(
  ({ isShowingButton, ...rest }: Props & ButtonProps) => <ButtonDS {...rest} />,
)({
  position: 'absolute',
  bottom: ({ isShowingButton }: Props) =>
    isShowingButton ? spacing.md : spacing.sm,
  zIndex: 100,
  padding: `${spacing.base} ${spacing.sm}`,
  visibility: ({ isShowingButton }: Props) =>
    isShowingButton ? 'hidden' : 'visible',
  opacity: ({ isShowingButton }: Props) => (isShowingButton ? 1 : 0),
  boxShadow: shadow.level[2],
  transition: 'all 0.5s ease',
});
