import { Button, DialogContent } from '@iclinic/design-system';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../../state';
import {
  getIsSignatureModalOpen,
  getPhoneNumber,
  getScreenStep,
} from '../../state/selectors';
import { isPhoneValid } from '../../utils';
import { SnackbarComponent } from '../SnackbarComponent';
import { GenerateLinkContent } from './GenerateLinkContent';
import { GetInfoPatientContent } from './GetInfoPatientContent';
import { SendToPatientContent } from './SendToPatientContent';
import * as S from './ElectronicSignatureModal.styles';
import { ElectronicSignatureStep } from '../../enuns';

export const ElectronicSignatureModal = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const isSignatureModalOpen = useSelector(getIsSignatureModalOpen);
  const screenStep = useSelector(getScreenStep);
  const phoneNumber = useSelector(getPhoneNumber);
  const dispatch = useDispatch();

  const onOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleClose = () => {
    dispatch(actions.closeElectronicSignatureModal());
  };

  const handleSubmit = () => {
    const isValid = isPhoneValid(phoneNumber);
    if (!isValid) {
      dispatch(actions.setPhoneNumberError(true));
      return;
    }

    dispatch(actions.getSignatureLink());
  };

  const showActions = () => (
    <S.DialogActionsContainer>
      <S.ButtonContainer>
        <Button
          color="transparent"
          onClick={handleClose}
          data-ga="medical_record_electronic_signature_cancel"
          data-sp="medical_record_electronic_signature_cancel"
        >
          Cancelar
        </Button>
      </S.ButtonContainer>
      <S.ButtonContainerConfirm>
        <Button
          variant="contained"
          onClick={handleSubmit}
          data-ga="medical_record_electronic_signature_confirm"
          data-sp="medical_record_electronic_signature_confirm"
        >
          Confirmar
        </Button>
      </S.ButtonContainerConfirm>
    </S.DialogActionsContainer>
  );

  return (
    <>
      <SnackbarComponent
        message="Link copiado com sucesso"
        openSnackbar={openSnackbar}
        onClose={onCloseSnackbar}
      />
      <S.DialogContainer
        open={!!isSignatureModalOpen}
        keepMounted
        onClose={handleClose}
        disableBackdropClick
      >
        <S.DialogHeaderContent
          title="Assinatura eletrônica"
          onClose={handleClose}
        />
        <DialogContent>
          {screenStep === ElectronicSignatureStep.GetInfoPatient && (
            <GetInfoPatientContent />
          )}
          {screenStep === ElectronicSignatureStep.GenerateLink && (
            <GenerateLinkContent />
          )}
          {screenStep === ElectronicSignatureStep.SendToPatient && (
            <SendToPatientContent onOpenSnackbar={onOpenSnackbar} />
          )}
        </DialogContent>
        {screenStep === ElectronicSignatureStep.GetInfoPatient && showActions()}
      </S.DialogContainer>
    </>
  );
};
