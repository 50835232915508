export const products = {
  video: 'videoteca',
  onlinescheduling: 'ao',
  telemedicine: 'telemedicine',
  electronicsignature: 'electronic-signature',
};

export const VIEWSTEPS = {
  info: 1,
  user: 2,
  resume: 3,
  success: 4,
  adminSuccess: 5,
};

export const FLOW = {
  upgrade: 'upgrade',
  upgradePerUser: 'upgradePerUser',
  upsell: 'upsell',
  onlineScheduling: 'online-scheduling',
};
