export const extractTokenInfo = (token: string) => {
  const index = token.search('-');

  if (index === -1) return null;

  const uidb36 = token.substring(0, index);
  const passToken = token.substring(index + 1);

  return { uidb36, passToken };
};

export const buildAutoLoginURL = (authToken: string, uid: string) =>
  `/usuarios/auto-login/?auth_token=${authToken}&uid=${uid}`;
