import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MaterialUICore,
  Select,
  Autocomplete,
  TextField,
} from '@iclinic/design-system';

import { ExecutantProfessional, LotConfig } from 'features/tissInvoicing/types';
import { actions } from 'features/tissInvoicing/state/lot';
import { actions as actionsSadt } from 'features/tissInvoicing/state/sadt';
import {
  getHiredOptions,
  getLotDataState,
  getLotViewState,
} from 'features/tissInvoicing/state/lot/selectors';
import { SpaceBetweenBox } from 'features/tissInvoicing/components/ui/Flexbox';
import {
  getClinicHealth,
  getLoadingState,
} from 'features/tissInvoicing/state/sadt/selectors';
import { listTypeCodeExecutant } from 'features/tissInvoicing/utils/listTypeCodeExecutant';
import * as Styles from './styles';

const { MenuItem } = MaterialUICore;

interface LotConfigOptionsProps {
  lotId: string;
  isNewLot: boolean;
}

function LotConfigOptions({ lotId, isNewLot }: LotConfigOptionsProps) {
  const classes = Styles.useStyles();
  const dispatch = useDispatch();

  const { configOptions, config } = useSelector(getLotViewState);
  const hiredOptions = useSelector(getHiredOptions);
  const { fetchClinicHealthHiredExecutant: loadingHiredExecutant } =
    useSelector(getLoadingState);
  const {
    lot: { is_closed: isClosed, guides, ...restLot },
  } = useSelector(getLotDataState);
  const { show, hiredExecutant } = useSelector(getClinicHealth);

  const handleUpdateLot = useCallback(
    (configPayload: LotConfig) => {
      dispatch(actions.setLotConfig(configPayload));

      if (!isNewLot)
        dispatch(
          actions.patchLotData({
            id: lotId,
            guides,
            executant_cnes: configPayload.executant_cnes || '',
            executant_code: configPayload.executant_code,
            executant_code_type: configPayload.executant_code_type,
            executant_name: configPayload.executant_name,
            executant_type: configPayload.executant_type,
          }),
        );
    },
    [dispatch, guides, isNewLot, lotId],
  );

  const handleChange = useCallback(
    ({
      target: { value, name },
    }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const configPayload = {
        ...config,
        [name]: value,
      };

      if (name === 'clinic_health_insurance_id') {
        dispatch(actionsSadt.fetchClinicHealthShow({ insurance_id: +value }));

        dispatch(
          actionsSadt.getClinicHealthHiredExecutant({
            insurance_id: +value,
            physician_id: 0,
          }),
        );

        const healthInsurance = configOptions.clinic_health_insurances.find(
          (option) => option.id === Number(value),
        );

        if (!config.version && healthInsurance?.tiss_version)
          configPayload.version = healthInsurance?.tiss_version;

        if (healthInsurance?.provider) {
          configPayload.executant_type = healthInsurance.provider;
        }
      }

      handleUpdateLot(configPayload);
    },
    [configOptions, dispatch, config, handleUpdateLot],
  );

  const getExecutantProfessionalName = () =>
    hiredOptions.find(
      (item) =>
        item.name.toLocaleLowerCase() ===
        config.executant_name?.toLocaleLowerCase(),
    ) || null;

  const handleExecutantChange = (option: ExecutantProfessional) => {
    const { name, type } = option || {};

    if (name && type)
      handleUpdateLot({
        ...config,
        executant_type: type,
        executant_name: name,
      });
  };

  const disableInputs: boolean = useMemo(
    () => !isNewLot && isClosed,
    [isNewLot, isClosed],
  );

  const textField = useCallback(
    (params) => (
      <TextField
        {...params}
        label="Contratado executante"
        name="executant_name"
        data-ga="executant_name"
      />
    ),
    [],
  );

  useEffect(() => {
    if (show?.next_lot_code && show.next_lot_code !== config.next_lot_code) {
      dispatch(
        actions.setLotConfig({
          ...config,
          next_lot_code: show.next_lot_code,
        }),
      );
    }
  }, [show, dispatch, config]);

  useEffect(() => {
    if (loadingHiredExecutant) {
      dispatch(
        actions.setLotConfig({
          ...config,
          executant_name: String(hiredExecutant?.name),
          executant_cnes: hiredExecutant?.cnes,
        }),
      );
    }
  }, [dispatch, hiredExecutant, loadingHiredExecutant, config]);

  useEffect(() => {
    if (
      isClosed &&
      !isNewLot &&
      restLot?.executant_name !== config.executant_name
    ) {
      dispatch(
        actions.setLotConfig({
          ...config,
          executant_name: restLot.executant_name,
          executant_cnes: restLot.executant_cnes,
          executant_code: restLot.executant_code,
          executant_code_type: restLot.executant_code_type,
          next_lot_code: restLot.lot_number,
        }),
      );
    }
  }, [dispatch, config, restLot, isClosed, isNewLot]);

  return (
    <SpaceBetweenBox classes={{ root: classes.header }}>
      <Select
        label="Convênio"
        id="clinic_health_insurance_id"
        name="clinic_health_insurance_id"
        value={config.clinic_health_insurance_id}
        disabled={!isNewLot || disableInputs}
        onChange={handleChange}
      >
        {configOptions.clinic_health_insurances.map((healthInsurance) => (
          <MenuItem value={healthInsurance.id} key={healthInsurance.id}>
            {healthInsurance.name}
          </MenuItem>
        ))}
      </Select>

      <Autocomplete
        fullWidth
        id="executant_name"
        classes={{ root: classes.autocomplete }}
        disabled={disableInputs}
        value={getExecutantProfessionalName()}
        options={hiredOptions}
        groupBy={({ label }) => label}
        onChange={(_, option) =>
          handleExecutantChange(option as ExecutantProfessional)
        }
        getOptionLabel={({ name }) => name}
        renderOption={({ name }) => name}
        renderInput={textField}
      />

      <TextField
        label="Código do contratado"
        inputProps={{ 'aria-label': 'Código do contratado' }}
        fullWidth
        value={config.executant_code}
        onChange={handleChange}
        disabled={disableInputs}
        name="executant_code"
      />

      <Select
        label="Tipo do Código do contratado"
        id="executant_code_type"
        name="executant_code_type"
        value={config.executant_code_type}
        disabled={disableInputs}
        onChange={handleChange}
      >
        {listTypeCodeExecutant().map((item) => (
          <MenuItem value={item.code} key={item.name}>
            {item.code} - {item.name}
          </MenuItem>
        ))}
      </Select>

      <TextField
        label="Número do lote"
        inputProps={{ 'aria-label': 'Número do lote' }}
        fullWidth
        value={config.next_lot_code}
        disabled
        name="next_lot_code"
        onChange={handleChange}
      />

      <Select
        label="Versão"
        id="version"
        name="version"
        value={config.version}
        disabled={!isNewLot || disableInputs}
        onChange={handleChange}
      >
        {configOptions.versions.map((version) => (
          <MenuItem value={version} key={version}>
            {version}
          </MenuItem>
        ))}
      </Select>
    </SpaceBetweenBox>
  );
}

export default LotConfigOptions;
