import React from 'react';
import ContentLoader from 'react-content-loader';

import { MaterialUICore } from '@iclinic/design-system';

const { useMediaQuery, useTheme } = MaterialUICore;

const values = {
  backgroundColor: '#ECEFF1',
  foregroundColor: '#F8F9FA',
  speed: 1,
};

const Loader = (): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const height = matches ? 600 : 480;

  return (
    <ContentLoader
      speed={values.speed}
      width="100%"
      height={height}
      backgroundColor={values.backgroundColor}
      foregroundColor={values.backgroundColor}
      uniqueKey="transactions-list-loader"
    >
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="480" />
    </ContentLoader>
  );
};

export default Loader;
