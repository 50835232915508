import { MaterialUICore, tokens, axiomTokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const { spacing, border } = tokens;

export default makeStyles((theme) => ({
  root: {
    position: 'absolute',
    zIndex: 1,
    top: '0',
    left: '0',
    padding: spacing.sm,
    backgroundColor: `rgba( 0, 0, 0, ${axiomTokens.opacity.level['6']})`,
    borderRadius: border.radius.sm,
    [theme.breakpoints.down(320)]: {
      display: 'none',
    },
  },
  hidden: {
    display: 'none',
  },
}));
