import { SubscriptionFormData } from 'features/checkout/state/plans/types';

export const initialFormData: SubscriptionFormData = {
  cardCVV: '',
  cardExpirationDate: '',
  cardNumber: '',
  cardOwnerName: '',
  cpfCnpj: '',
  turnstileToken: '',
};
