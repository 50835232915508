import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Title, SkeletonContainer } from './Loading.styles';
import Skeleton from '../Skeleton';
import { getSummaryIA } from 'features/records/services';
import { getSummaryDataState, getIsWaiting } from '../../state/selector';
import { actions } from '../../state';
import { notAllowGeneretaNewSummary } from 'state/records/actions';

const { updateSummary, changeScreen, triggerIsWaiting } = actions;

const Loading = () => {
  const currentPatientId = useSelector(
    (state) => state.records.root.patient.id,
  );
  const { patientId } = useSelector(getSummaryDataState);
  const isWaiting = useSelector(getIsWaiting);
  const dispatch = useDispatch();
  const isFetching = useRef(false);

  const fetchSummaryData = async () => {
    if (isWaiting) {
      const response = await getSummaryIA(currentPatientId);
      const waitingText = 'NOT YET GENERATED';
      const summaryResponse = response.data.response;
      const requestId = response.data.request_id;
      const statusRequestLLM = response.data.status;

      if (!statusRequestLLM || summaryResponse.toUpperCase() === waitingText) {
        dispatch(updateSummary({ patientId }));
        dispatch(triggerIsWaiting(true));
        return false;
      }

      dispatch(
        updateSummary({
          patientId: currentPatientId,
          markdown: summaryResponse,
          requestId,
        }),
      );
      dispatch(triggerIsWaiting(false));
      dispatch(notAllowGeneretaNewSummary());
      return true;
    }
    return true;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isFetching.current) return;
      isFetching.current = true;

      fetchSummaryData()
        .then((finalize) => {
          if (finalize) {
            clearInterval(interval);
            dispatch(changeScreen('summary'));
          }
        })
        .catch(() => {
          clearInterval(interval);
          dispatch(changeScreen('integrationError'));
        })
        .finally(() => {
          isFetching.current = false;
        });
    }, 1000);

    return () => clearInterval(interval);
  }, [currentPatientId]);

  return (
    <Container data-testid="loading-component">
      <Title>Analisando o histórico do prontuário</Title>
      <SkeletonContainer>
        <Skeleton />
      </SkeletonContainer>
      <SkeletonContainer>
        <Skeleton />
      </SkeletonContainer>
      <SkeletonContainer>
        <Skeleton />
      </SkeletonContainer>
      <SkeletonContainer>
        <Skeleton />
      </SkeletonContainer>
      <SkeletonContainer>
        <Skeleton />
      </SkeletonContainer>
      <SkeletonContainer>
        <Skeleton />
      </SkeletonContainer>
      <SkeletonContainer>
        <Skeleton />
      </SkeletonContainer>
      <SkeletonContainer>
        <Skeleton />
      </SkeletonContainer>
      <SkeletonContainer>
        <Skeleton />
      </SkeletonContainer>
    </Container>
  );
};

export default Loading;
