import { MaterialUICore, TextField, tokens } from '@iclinic/design-system';

const { styled, RadioGroup: DSRadioGroup } = MaterialUICore;
const { spacing } = tokens;

export const FieldsWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: spacing.md,
  rowGap: spacing.md,
}));

export const RadioGroup = styled(DSRadioGroup)({
  '& > label': {
    marginBottom: 0,
  },
});

export const CommentField = styled(TextField)({
  '& .MuiInputBase-root': {
    height: 'initial',
  },
  '& .MuiFilledInput-inputMultiline': {
    padding: '0 !important',
    height: '128px !important',
  },
});

export const FormButtonWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));
