import { MaterialUICore, theme } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const flexBox = {
  spaceBetween: 'space-between',
};

const useStyles = makeStyles(() => ({
  drawerPaper: {
    height: '100%',
    justifyContent: flexBox.spaceBetween,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    height: '100%',
    maxWidth: '520px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  bodyText: {
    paddingTop: '8px',
    color: theme.palette.grey[500],
  },
  mainContent: {
    height: '100%',
    margin: '0 24px 10px 24px',
    overflow: 'auto',
    '& .MuiTypography-body1': {
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '0.44px',
    },
    '& .MuiTypography-body2': {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.44px',
    },
    '& .MuiTypography-h3': {
      fontSize: '27px',
      lineHeight: '34px',
    },
  },
  permission: {
    '& .Mui-checked': {
      color: '#448AFF',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  grayButton: {
    margin: '0 24px',
    color: theme.palette.grey[300],
    '& .MuiTypography-body1': {
      fontSize: '16px',
      lineHeight: '32px',
      letterSpacing: '0.44px',
      margin: '10px',
      display: 'flex',
    },
    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px',
      position: 'relative',
      top: '6px',
      marginRight: '5px',
    },
  },
  footer: {
    height: '81px',
    justifyContent: flexBox.spaceBetween,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  buttonProgress: {
    color: theme.palette.grey[300],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  footerButton: {
    height: 48,
    margin: '16px 32px 16px 16px',
    fontSize: '16px',
    letterSpacing: '0.3px',
    lineHeight: '24px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '16px',
      justifyContent: 'center',
    },
    backgroundColor: theme.palette.primary.main,
  },
  successImage: {
    width: '208px',
    margin: '0 auto',
  },
  title: {
    marginTop: '32px',
  },
  gridContainer: {
    marginTop: '40px',
  },
  gridItem: {
    margin: 0,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: flexBox.spaceBetween,
    maxHeight: '40px',
  },
  checkboxLabel: {
    color: theme.palette.grey[700],
    '& .MuiSvgIcon-root': {
      width: '18px',
      height: '18px',
    },
  },
  backButton: {
    margin: '8px 24px',
    width: '94px',
    color: theme.palette.grey[300],
    marginBottom: 24,
    '& .MuiTypography-overline': {
      fontSize: '12px',
      lineHeight: '32px',
      letterSpacing: '0.44px',
    },
  },
  closeButton: {
    color: theme.palette.grey[300],
    margin: '16px',
    maxWidth: '140px',
    '& .MuiTypography-body1': {
      fontSize: '14px',
      lineHeight: '32px',
      letterSpacing: '0.44px',
    },
  },
  userEmail: {
    marginTop: '32px',
    width: '340px',
  },
}));

export default useStyles;
