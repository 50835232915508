import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles(() => ({
  tabs: {
    '& button': {
      fontSize: '1.125rem',
    },
    '& .MuiTabs-indicator': {
      height: '1px',
    },
  },
  tabsHeader: {
    padding: '12px 28px 0',
    borderBottom: '0',
  },
  subTabHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '35px',
    padding: '25px 8px 25px',
  },
  subTabButton: {
    '& .MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      fontSize: '14px',
      lineHeight: '16px',
      color: tokens.color.neutral[700],
    },
    '&.selected .MuiButton-label': {
      color: tokens.color.primary.main,
    },
  },
}));

export default useStyles;
