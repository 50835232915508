import React, { useState, useEffect } from 'react';

import {
  getCardImage,
  getCreditCardNameByNumber,
} from 'shared/utils/creditCardValidation';
import { Tooltip } from '@iclinic/design-system';

type CreditCardFlagProps = {
  number: string;
};

export default function CreditCardFlag({
  number,
}: CreditCardFlagProps): JSX.Element | null {
  const [tooltipMessage, setTooltipMessage] = useState(
    'Bandeira do cartão de crédito',
  );

  useEffect(() => {
    setTooltipMessage(
      getCreditCardNameByNumber(number) || 'Bandeira do cartão de crédito',
    );
  }, [number]);

  return (
    <Tooltip placement="top" title={tooltipMessage}>
      <img
        alt={tooltipMessage}
        src={getCardImage(number)}
        data-testid="credit-card-flag"
      />
    </Tooltip>
  );
}
