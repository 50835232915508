import { Response } from '@types';
import { put, retry, takeLatest } from 'redux-saga/effects';

import { ActionKeys, DomainTables } from 'features/tissInvoicing/types';
import { captureException } from 'shared/utils/handlerErrors';
import { actions } from '.';
import { RETRY_DELAY_MS, RETRY_TIMES } from '../constants';
import { getDomainTables } from 'features/tissInvoicing/services/common';

export function* fetchDomainTablesWorker() {
  try {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchDomainTables,
        value: true,
      }),
    );

    const { data }: Response<DomainTables> = yield retry(
      RETRY_TIMES,
      RETRY_DELAY_MS,
      getDomainTables,
    );

    yield put(actions.setDomainTables(data));
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLoadingStatus({
        key: ActionKeys.FetchDomainTables,
        value: false,
      }),
    );
  }
}

export default function* watchSadtWorker() {
  yield takeLatest(actions.fetchDomainTables, fetchDomainTablesWorker);
}
