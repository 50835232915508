import {
  Box,
  Card,
  Heading,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Wrapper = styled(Box)(({ theme }) => ({
  maxWidth: 434,

  [theme.breakpoints.down(1200)]: {
    maxWidth: 'unset',
    width: '100%',
  },
}));

export const UpgradeCallCard = styled(Card)(() => ({
  padding: tokens.spacing.xs,
}));

export const ImageWrapper = styled(Box)(() => ({
  margin: `${tokens.spacing.base} 0`,
  backgroundColor: tokens.color.secondary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  cursor: 'pointer',
}));

export const ChipsWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down(1200)]: {
    overflow: 'auto',
  },
}));

export const UpgradeCallHeading = styled(Heading)(() => ({
  borderTop: `${tokens.border.width.hairline} solid ${tokens.color.primary.light}`,
  paddingTop: tokens.spacing.sm,
  marginTop: tokens.spacing.sm,
  letterSpacing: 0,
  '& strong': {
    color: tokens.color.feedback.warning.main,
  },
}));

export const Conditions = styled(Box)(() => ({
  borderTop: `${tokens.border.width.hairline} solid ${tokens.color.neutral[50]}`,
  marginTop: tokens.spacing.md,
  padding: `${tokens.spacing.xs} 0`,
}));
