import { useSelector } from 'react-redux';

import { getUserInfo } from '../state/selectors';
import { getCurrentEventAndPatientId } from 'state/records/selectors';
import { trackCustomEvent } from 'shared/utils/trackEvents';
import { TrackHighlightsInfoEvents } from '../types';

export const HIGHLIGHTS_INFO_CARDS_SCHEMA =
  'iglu:br.com.iclinic/highlights_info_cards/jsonschema/2-0-0';
export const USER_CONTEXT_SCHEMA = 'iglu:br.com.iclinic/user/jsonschema/1-0-0';
export const EVENT_CONTEXT_SCHEMA =
  'iglu:br.com.iclinic/events/jsonschema/1-0-0';
export const SUBSCRIPTION_CONTEXT_SCHEMA =
  'iglu:br.com.iclinic/subscription/jsonschema/1-0-0';

export function useTrackHighlightsInfoEvents() {
  const userInfo = useSelector(getUserInfo);
  const { eventId, patientId } = useSelector(getCurrentEventAndPatientId);

  const userContext = {
    schema: USER_CONTEXT_SCHEMA,
    data: {
      user_id: userInfo.profileId,
      user_is_subscriber: userInfo.isSubscriber,
    },
  };

  const subscriptionContext = {
    schema: SUBSCRIPTION_CONTEXT_SCHEMA,
    data: {
      user_id: userInfo.profileId,
      subscription_id: userInfo.subscriptionId,
    },
  };

  const eventContext = {
    schema: EVENT_CONTEXT_SCHEMA,
    data: {
      clinic_id: userInfo.clinicId,
      patient_id: patientId,
      event_id: eventId || null,
    },
  };

  const trackEvents = ({
    cardInteraction,
    cardType,
    cardName,
  }: TrackHighlightsInfoEvents) => {
    trackCustomEvent(
      HIGHLIGHTS_INFO_CARDS_SCHEMA,
      {
        card_interaction: cardInteraction,
        card_type: cardType,
        card_name: cardName,
      },
      [userContext, subscriptionContext, eventContext],
    );
  };

  return { trackEvents };
}
