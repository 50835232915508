import React from 'react';
import { Box, Button } from '@iclinic/design-system';

import {
  Container,
  Image,
  HeadingContent,
  BodyContent,
  BoxContent,
  ButtonContent,
} from './index.styles';
import images from 'shared/paths/images';

export interface IPrescriptionError {
  onRetry?: () => void;
}

const PrescriptionError = ({ onRetry }: IPrescriptionError): JSX.Element => {
  const refreshPage = () => {
    if (onRetry) {
      onRetry();
    } else {
      window.location.reload();
    }
  };

  return (
    <Container>
      <BoxContent>
        <Image
          className="image"
          src={images.records.prescriptionError}
          alt="rosto suporte"
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          marginLeft="24px"
          height="240px"
        >
          <HeadingContent variant="sm">
            Estamos com problemas técnicos em nossa prescrição
          </HeadingContent>
          <BodyContent variant="xs">
            Por favor tente novamente mais tarde. Estamos trabalhando para
            normalizar nosso sistema.
          </BodyContent>
          <ButtonContent>
            <Button
              fullWidth={false}
              color="secondary"
              variant="contained"
              size="small"
              onClick={refreshPage}
              data-ga="prescription-error_btn-tentar-novamente"
            >
              Tentar novamente
            </Button>
          </ButtonContent>
        </Box>
      </BoxContent>
    </Container>
  );
};

export default PrescriptionError;
