import { PreviewTemplateData, TemplateMessage } from '../../../services/types';
import {
  PreviewTemplateActionData,
  SingleScheduleTemplateMessage,
} from '../../types';

export const normalizeSingleScheduleTemplateMessage = (
  singleScheduleTemplateMessage: SingleScheduleTemplateMessage,
): TemplateMessage => ({
  content: singleScheduleTemplateMessage.content,
  is_active: singleScheduleTemplateMessage.isActive,
  schedules: [
    {
      id: singleScheduleTemplateMessage.existTemplateId,
      days_advance: singleScheduleTemplateMessage.daysAdvance,
      time_send: singleScheduleTemplateMessage.timeSend,
    },
  ],
  schedules_deleted: [],
  contact_phone: singleScheduleTemplateMessage.contactPhone,
});

export const normalizePreviewTemplateMessageData = (
  templateActionData: PreviewTemplateActionData,
): PreviewTemplateData => ({
  content: templateActionData.content,
  contact_phone: templateActionData.contactPhone,
});
