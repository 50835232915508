import {
  Autocomplete,
  createFilterOptions,
  TextField,
} from '@iclinic/design-system';
import { useFormikContext } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  getAutocomplete,
  getCboOptions,
} from 'features/tissInvoicing/state/autocomplete/selectors';
import {
  GuideSADTForm,
  PhysicianAutocompleteEntry,
} from 'features/tissInvoicing/types';
import { getOptionLabelFn, renderOptionFn } from '../utils/utils';
import { FilterOptionsState } from 'features/tissInvoicing/types/types.common';

export default ({
  label = '15 - Nome do profissional solicitante',
}: {
  label?: string;
}) => {
  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext<GuideSADTForm>();

  const physicianFilter = createFilterOptions<PhysicianAutocompleteEntry>();
  const { physicians } = useSelector(getAutocomplete);
  const cboOptions = useSelector(getCboOptions);

  const getCBOByCode = (code: string | null) => {
    const cboData = cboOptions.find((cbo) => cbo.code === code);
    return cboData?.code || null;
  };

  const handleChange = (option: PhysicianAutocompleteEntry) => {
    setFieldValue('professional_solicitant_name', option?.name || option || '');
    setFieldValue(
      'professional_solicitant_council',
      option?.council_name || '',
    );
    setFieldValue(
      'professional_solicitant_council_number',
      option?.council_number || '',
    );
    setFieldValue(
      'professional_solicitant_council_state',
      option?.council_region || '',
    );
    setFieldValue('professional_solicitant_cbo', getCBOByCode(option?.cbo));
  };

  const filterOptions = (
    options: PhysicianAutocompleteEntry[],
    params: FilterOptionsState<PhysicianAutocompleteEntry>,
  ) => {
    const filtered = physicianFilter(options, params);

    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        name: params.inputValue,
        type: '',
        council_name: '',
        council_number: 0,
        council_region: '',
        cbo: '0',
      });
    }

    return filtered;
  };

  return (
    <Autocomplete
      id="professional_solicitant_name"
      freeSolo
      fullWidth
      options={physicians}
      value={values.professional_solicitant_name}
      clearOnBlur
      onBlur={handleBlur}
      onChange={(_, value) => handleChange(value as PhysicianAutocompleteEntry)}
      getOptionLabel={(option: PhysicianAutocompleteEntry) =>
        getOptionLabelFn(option)
      }
      renderOption={(option: PhysicianAutocompleteEntry) =>
        renderOptionFn(option)
      }
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
           
          {...params}
          label={label}
          name="professional_solicitant_name"
          data-ga="professional_solicitant_name"
          error={
            !!(
              errors.professional_solicitant_name &&
              touched.professional_solicitant_name
            )
          }
          helperText={
            touched.professional_solicitant_name &&
            errors.professional_solicitant_name
          }
        />
      )}
    />
  );
};
