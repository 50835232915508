import { searchLocaleStartsWith } from 'shared/utils/strings';
import { captureException } from 'shared/utils/handlerErrors';
import { formatDate } from 'shared/utils/date';
import qs from 'qs';
import moment from 'moment';
import {
  ProductDetail,
  Stock,
  History,
  Inventory,
  Lot,
  Unit,
} from 'features/stock/state/types';
import { StockTabs } from 'features/stock/constants';
import { PAGINATION } from './constants';

export const getProductAndStockData = (
  product: ProductDetail,
  stock: Stock,
) => ({
  id: product.id,
  description: product.description,
  code: product.code,
  minimum: product.minimum,
  unitId: product.unit.id,
  hasLotControl: product.hasLotControl,
  observation: product.observation,
  quantity: stock.quantity,
  expireInOneMonth: stock.expireInOneMonth.quantity || 0,
  expired: stock.expired.quantity || 0,
});

export const filterLotsDescription = (inventoryList, lotDescription) => {
  const filteredValues = inventoryList.reduce((accumulator, inventory) => {
    const hasLot = !!inventory.lot.id;
    const matchDescription =
      hasLot &&
      searchLocaleStartsWith(inventory.lot.description, lotDescription);

    return matchDescription
      ? Object.assign(accumulator, {
          [inventory.lot.id]: {
            ...inventory.lot,
            expirationDate: !accumulator[inventory.lot.id]
              ? inventory.expirationDate
              : accumulator[inventory.lot.id].expirationDate,
          },
        })
      : accumulator;
  }, {});

  return Object.values(filteredValues);
};

type ReduceInventories = {
  [key: string]: Inventory & Lot;
};
export const filterLotQuantitiesByProduct = (
  productStock: Stock[] | null,
): (Omit<Inventory, 'lot'> & Lot)[] => {
  if (!productStock || productStock.length === 0) return [];

  try {
    const [{ inventories }] = productStock;

    const result = inventories.reduce<ReduceInventories>(
      (res: ReduceInventories, inventory: Inventory) => {
        const { lot, quantity, expired, expireInOneMonth } = inventory;

        if (!lot) return res;

        return {
          ...res,
          [lot.id]: {
            ...lot,
            quantity: quantity + (res[lot.id] ? res[lot.id].quantity : 0),
            expired: expired + (res[lot.id] ? res[lot.id].expired : 0),
            expireInOneMonth:
              expireInOneMonth +
              (res[lot.id] ? res[lot.id].expireInOneMonth : 0),
          },
        };
      },
      {},
    );
    return Object.values(result);
  } catch (err) {
    captureException(err);
    return [];
  }
};

export const filterLotsByProductId = (inventoryList, selectedProduct) => {
  if (!selectedProduct) return [];

  const lots = inventoryList
    .filter(({ product: { id } }) => id === selectedProduct.id)
    .reduce((res, { lot, ...inventory }) => {
      if (!lot.id) return res;

      const quantity =
        inventory.quantity + (res[lot.id] ? res[lot.id].quantity : 0);
      const expirationDate =
        res[lot.id] && res[lot.id].expirationDate
          ? res[lot.id].expirationDate
          : inventory.expirationDate && formatDate(inventory.expirationDate);

      const description = `${lot.description} - quant. ${quantity} - val. ${expirationDate}`;

      return {
        ...res,
        [lot.id]: {
          ...lot,
          description,
          expirationDate,
          quantity,
        },
      };
    }, {});

  return Object.values(lots);
};

export const filterUnits = (
  unitSearch: string | null,
  units: Unit[],
): Unit[] => {
  if (!unitSearch) return units;

  return units.filter((unit: Unit) =>
    searchLocaleStartsWith(unit.description, unitSearch),
  );
};

export const normalizeInventoriesHistory = (
  clinicId: string | null,
  payload: History,
) => ({
  ...(payload.dateRange && { date__range: payload.dateRange }),
  ...(payload.period !== 'all' &&
    !payload.dateRange && { period: payload.period }),
  ...(payload.productId && { product_id: payload.productId }),
  ...(payload.addedBy && { added_by: payload.addedBy }),
  clinic_id: clinicId,
  kind: payload.kind,
});

export const normalizeListHistory = (payload) => {
  const { count, results } = payload;
  const listResults = results.map((list) => {
    const date = moment(list.date).format('DD/MM/YYYY - HH:mm');
    return {
      ...list,
      lot: list.lot.description || '---',
      product: list.product.description || list.product.id,
      profile: list.profile.name || list.profile.id,
      observation: list.observation || '---',
      date,
    };
  });
  return {
    count,
    results: listResults,
  };
};

export const checkFilter = (filter: StockTabs) =>
  filter === StockTabs.All ? '/' : `/${filter}/`;

export const normalizeStockSearch = (
  filter: StockTabs,
  search: string | null,
  page: number | null,
  stockId: number | null = null,
  pageSize: number = 20,
): string => {
  const params = search ? { q: search } : { q: search, page };
  const queryStringParams = qs.stringify(
    { ...params, page_size: pageSize },
    { allowDots: true, skipNulls: true },
  );

  if (stockId) {
    return `/stocks/${stockId}`;
  }

  return `/stocks${checkFilter(filter)}?${queryStringParams}`;
};

export const checkPagination = (search, count, page) =>
  search && count > 0 ? PAGINATION.initialPage : page;

export const normalizeUserSearch = (search, clinicId) => ({
  q: search,
  clinic_id: clinicId,
  page_size: 5,
});
