// External
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

// Internal
import { requiredEmpty, url } from 'shared/utils/formValidation';
import {
  getEditorImageModalInitialValues,
} from 'state/marketing/emailFlow/emailFlowManagement/selectors';
import { EMAIL_FLOW_EDITOR_IMAGE_MODAL } from 'shared/constants/forms';
import { StoreState } from 'state/rootReducer';
import Modal from 'ui/new/modal';
import Button from 'ui/new/button';
import { Input } from 'ui/new/form';
import style from './EditorImageModal.scss';

interface DispatchProps {
  handleSubmit: (onClickSave: Function) => void;
  onCloseClick: () => void;
  onAddURL: (inputUrl: string) => void;
}

interface ModalImage {
  inputUrl: string;
}

interface EditorImageModalProps extends DispatchProps {}

const EditorImageModal = ({
  handleSubmit,
  onCloseClick,
  onAddURL,
}: EditorImageModalProps) => {
  const onClickSave = React.useCallback(({ inputUrl }: ModalImage) => {
    onAddURL(inputUrl);
  }, [onAddURL]);

  return (
    <form onSubmit={handleSubmit(onClickSave)}>
      <div className={style.modal}>
        <Modal show title="Editar link na imagem" onClose={onCloseClick} classes={style} width={500}>
          <Modal.Body>
            <p>Endereço do link na imagem</p>
            <Field
              type="text"
              name="inputUrl"
              component={Input}
              placeholder="http://www.site.com.br"
              validate={[requiredEmpty, url]}
            />
          </Modal.Body>
          <Modal.Footer align="right">
            <Button type="light" onClick={onCloseClick}>CANCELAR</Button>
            <Button type="success" submit>SALVAR</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </form>
  );
};

const mapStateToProps = (state: StoreState) => ({
  initialValues: getEditorImageModalInitialValues(state),
});

export default connect(mapStateToProps)(
  reduxForm({ form: EMAIL_FLOW_EDITOR_IMAGE_MODAL })(EditorImageModal),
);
