import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  Button,
  Loader,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';
import useWidthUpdater from 'shared/hooks/useWidthUpdater';
import { getAwards, isFetchingAwards } from '../../state/selectors';
import { TierStatus } from '../../types';
import CustomTooltip from './CustomTooltip';
import SvgStar from './SvgStar';
import * as S from './IndicationsTier.styles';
import { getTiers, getTierConnectionBGColor } from '../../utils';
import SvgStarOutline from './SvgStarOutline';

interface IndicationsTierProps {
  onMobileAwardsClick?: () => void;
}

const { useTheme } = MaterialUICore;
const { ArrowForward } = MaterialIcons;

const IndicationsTier = ({ onMobileAwardsClick }: IndicationsTierProps) => {
  const isFetching = useSelector(isFetchingAwards);
  const awards = useSelector(getAwards);
  const theme = useTheme();
  const mobileWidth = theme.breakpoints.values.sm;
  const isMobile = useWidthUpdater(mobileWidth);

  const tiers = useMemo(() => {
    return getTiers(awards);
  }, [awards]);

  return (
    <S.Root>
      <S.Content>
        <S.Header variant="sm" inverseTextColor>
          Suas indicações realizadas:
        </S.Header>

        {isFetching ? (
          <S.LoaderWrapper>
            <Loader data-testid="reward-redemption-loader" color="secondary" />
          </S.LoaderWrapper>
        ) : (
          <>
            <S.TierWrapper>
              {tiers.map(
                (
                  { highlighted, message, status, tier, tooltipActive },
                  index,
                ) => (
                  <React.Fragment key={tier.toString()}>
                    {index !== 0 && (
                      <S.TierConnection
                        isLast={index + 1 === tiers.length}
                        bgColor={getTierConnectionBGColor(
                          tiers[index - 1]?.highlighted,
                          status,
                        )}
                      />
                    )}

                    {index + 1 !== tiers.length ? (
                      <CustomTooltip
                        active={tooltipActive}
                        tooltipText={message}
                      >
                        <S.TierNumber
                          variant="sm"
                          active={status !== TierStatus.INITIAL && !highlighted}
                          highlighted={highlighted}
                        >
                          {tier.toString()}
                        </S.TierNumber>
                      </CustomTooltip>
                    ) : (
                      <CustomTooltip
                        active={tooltipActive}
                        tooltipText={message}
                      >
                        <S.TierStarWrapper>
                          {highlighted ? <SvgStarOutline /> : <SvgStar />}

                          <S.TierStarNumber variant="sm">
                            {tier.toString()}
                          </S.TierStarNumber>
                        </S.TierStarWrapper>
                      </CustomTooltip>
                    )}
                  </React.Fragment>
                ),
              )}
            </S.TierWrapper>

            {isMobile && !!awards?.length && (
              <Button
                color="secondary"
                startIcon={<ArrowForward />}
                size="small"
                onClick={onMobileAwardsClick}
              >
                Consulte seus prêmios
              </Button>
            )}
          </>
        )}
      </S.Content>
    </S.Root>
  );
};

export default IndicationsTier;
