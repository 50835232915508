import iClinicApi from 'services/iclinic/iClinicApi';
import { TemplateMessage } from '../types';

export const getReminderMessageTemplate = (physicianId: number) =>
  iClinicApi.get(`/messages/templates/event_reminder/${physicianId}/`);

export const putReminderMessageTemplate = (
  physicianId: number,
  templateMessage: TemplateMessage,
) =>
  iClinicApi.put(
    `/messages/templates/event_reminder/${physicianId}/`,
    templateMessage,
  );
