import React from 'react';

import { Box } from '@iclinic/design-system';
import { useWindowHeight } from 'features/video-conference/utils';
import Drawer from '../TeamComponents/Drawer';
import useStyles from '../styles';
import { useAnnotationContext } from 'features/video-conference/state/annotationContext';
import { buttonsTitle } from 'features/video-conference/constants';
import Tabs from './Tabs';
import NotesPanel from './NotesPanel';

export default function Annotation() {
  const {
    isAnnotationWindowOpen,
    setIsAnnotationWindowOpen,
  } = useAnnotationContext();
  const height = useWindowHeight();
  const classes = useStyles({ height });

  return (
    <Box className={classes.iframeHidden}>
      <Drawer
        open={isAnnotationWindowOpen}
        onClose={() => setIsAnnotationWindowOpen(!isAnnotationWindowOpen)}
        title={buttonsTitle.ANNOTATIONS}
      >
        <Tabs />
        <NotesPanel />
      </Drawer>
    </Box>
  );
}
