import { PromiseResponse } from '@types';
import iClinicApi from 'services/iclinic/iClinicApi';
import { RedeemReferralsAward, ReferralsAwardResponse } from './types';

export const getReferralsAvailableAwards = () =>
  iClinicApi.get<PromiseResponse<ReferralsAwardResponse>>(
    `/referrals/award/?status=wc`,
  );

export const getReferralsAwardsExtract = () =>
  iClinicApi.get<PromiseResponse<ReferralsAwardResponse>>(
    `/referrals/award/?status__in=wp,py&order_by=pay_date`,
  );

export const reedemReferralsAward = (
  awardId: number,
  data: RedeemReferralsAward,
) =>
  iClinicApi.post<PromiseResponse<any>>(
    `/referrals/award/${awardId}/redeem/`,
    data,
  );
