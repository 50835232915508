// External dependencies
import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Routes
import onlineScheduling from './Routes';

const Root = () => (
  <Switch>
    <Route path="/configuracoes" component={onlineScheduling} />
  </Switch>
);

export default Root;
