import { MaterialUICore, axiomTokens, Button } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const SpeechAreaContainer = styled('div')(() => ({
  margin: `0 ${axiomTokens.spacing[4]}`,
  padding: axiomTokens.spacing[4],
  border: `${axiomTokens.border.width.xs} solid ${axiomTokens.color.border.subtle}`,
  borderRadius: axiomTokens.border.radius.sm,
}));

export const Header = styled('div')(() => ({
  marginBottom: axiomTokens.spacing[4],
}));

export const Description = styled('span')(() => ({
  fontFamily: axiomTokens.font.family.base,
  fontSize: axiomTokens.font.size.xs,
  fontWeight: axiomTokens.font.weight.medium,
  color: axiomTokens.color.typeface.pure,
}));

export const ButtonsWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const DeleteButton = styled(Button)(() => ({
  backgroundColor: axiomTokens.color.interactive.alert.accent.default,
  padding: '0',
  minWidth: '32px',

  '&:hover': {
    backgroundColor: axiomTokens.color.interactive.alert.accent.hovered,
  },
}));

export const TranscriptionWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  gap: axiomTokens.spacing[2],
  marginTop: axiomTokens.spacing[4],
}));

export const Transcription = styled('span')(() => ({
  width: '100%',
  fontFamily: axiomTokens.font.family.base,
  fontSize: axiomTokens.font.size.xs,
  color: axiomTokens.color.typeface.pure,
  borderTop: `${axiomTokens.border.width.xs} solid ${axiomTokens.color.border.subtle}`,
  paddingTop: axiomTokens.spacing[2],
}));
