import { Body, MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { Checkbox, styled } = MaterialUICore;
const { spacing, color, border } = axiomTokens;

export const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: spacing[8],
  rowGap: spacing[6],
});

export const Card = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: color.background[1],
  border: `1px solid ${color.border.subtle}`,
  borderRadius: border.radius.sm,
  padding: spacing[6],
});

export const TitleAndSubtitleWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[1],
});

export const Subtitle = styled(Body)({
  color: color.typeface.smooth,
});

export const CheckboxContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: spacing['3'],
  marginBottom: spacing['6'],
});

export const ChipContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: spacing['2'],
  marginTop: spacing['3'],
  marginBottom: spacing['6'],
});

export const ButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row-reverse',
});

export const CheckboxListItem = styled(Checkbox)({
  color: color.border.accent,

  '& .MuiCheckbox-colorSecondary.Mui-checked': {
    color: color.interactive.accent.default,
  },
});

export const SelectPhysiciansWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  border: `1px solid ${color.border.subtle}`,
  borderRadius: border.radius.sm,

  [theme.breakpoints.up('md')]: {
    width: 600,
  },
}));

export const SearchFieldWrapper = styled('div')({
  width: '100%',
  padding: `${spacing[4]} ${spacing[4]} 0`,
});

export const ListWrapper = styled('div')({
  maxHeight: 320,
  overflowY: 'auto',
  width: '100%',
});
