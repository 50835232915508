import React from 'react';
import Icon from '@material/react-material-icon';

// Style
import classnames from 'classnames/bind';
import ss from './ListItem.scss';
import COLOR from './constants';

const cx = classnames.bind(ss);

type ListItemProps = {
  icon?: string;
  classes?: string;
  color?: COLOR;
  title: string;
};

export default function ListItem({
  icon = '',
  classes = '',
  color = COLOR.Primary,
  title,
}: ListItemProps) {
  return (
    <li
      className={cx(ss.item, classes)}
    >
      <Icon
        className={cx(ss.icon, color)}
        icon={icon}
      />
      {title}
    </li>
  );
}
