import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  Box,
  Button,
  CircularProgress,
  MaterialUICore,
} from '@iclinic/design-system';

import { CardWrapper } from '../../../../components';
import { fetchMessagePackageDetails } from '../../../../state/messagePackage/details';
import {
  isFetchingDetails,
  getConsumptions,
} from '../../../../state/messagePackage/details/selectors';
import { getPhysiciansAvailable } from '../../../../state/messagePackage/checkout/selectors';
import { Consumption } from './Consumption';
import { ConsumptionExceeded } from './ConsumptionExceeded';
import { PaymentEstimate } from './PaymentEstimate';
import {
  getPhysicians,
  isUserAdmin,
} from 'features/messaging/state/common/selectors';
import * as S from './OverviewTabContent.styles';
import history from 'routes/history';

const { MenuItem } = MaterialUICore;

const OverviewTabContent = () => {
  const dispatch = useDispatch();
  const isAdmin = useSelector(isUserAdmin);
  const isFetchingAllDetails = useSelector(isFetchingDetails);
  const consumptions = useSelector(getConsumptions);
  const subscriberPhysicians = useSelector(getPhysicians);
  const hasMultipleSubscriberPhysicians =
    subscriberPhysicians?.length && subscriberPhysicians.length > 1;
  const nonSubscriberPhysicians = useSelector(getPhysiciansAvailable);
  const hasNonSubscriberPhysicians = !nonSubscriberPhysicians?.length;
  const [selectedPhysician, setSelectedPhysician] = useState(() => {
    if (subscriberPhysicians?.length && subscriberPhysicians.length === 1) {
      return subscriberPhysicians[0].id.toString();
    }

    return '';
  });

  useEffect(() => {
    dispatch(fetchMessagePackageDetails(selectedPhysician || undefined));
  }, [dispatch, selectedPhysician]);

  const onChangePhysician = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      setSelectedPhysician(value);
    },
    [],
  );

  const onAddClickHandler = useCallback(() => {
    history.push('/relacionamento/pacote-de-mensagem');
  }, []);

  const showAddProfessionalsButton = isAdmin;

  return (
    <CardWrapper>
      <S.TopActionsWrapper>
        {hasMultipleSubscriberPhysicians ? (
          <S.SelectPhysician
            name="physicianId"
            value={selectedPhysician}
            onChange={onChangePhysician}
            label="Profissionais de saúde"
            SelectProps={{
              displayEmpty: true,
            }}
          >
            <MenuItem value="">Todos</MenuItem>
            {subscriberPhysicians.map(({ id, profile_name }) => (
              <MenuItem key={id.toString()} value={id}>
                {profile_name}
              </MenuItem>
            ))}
          </S.SelectPhysician>
        ) : null}

        {showAddProfessionalsButton && (
          <Box justifySelf="end">
            <Button
              disabled={hasNonSubscriberPhysicians}
              variant="outlined"
              size="large"
              onClick={onAddClickHandler}
            >
              Adicionar profissionais
            </Button>
          </Box>
        )}
      </S.TopActionsWrapper>

      {isFetchingAllDetails ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <S.ConsumptionsWrapper>
            {consumptions?.consumption_package ? (
              <Consumption
                consumptionPackage={consumptions?.consumption_package}
              />
            ) : null}

            {consumptions?.consumption_exceeded &&
            consumptions.consumption_exceeded.total_exceeded > 0 ? (
              <ConsumptionExceeded
                consumptionExceeded={consumptions.consumption_exceeded}
              />
            ) : null}
          </S.ConsumptionsWrapper>

          <PaymentEstimate />

          <Body variant="xxs">
            <em>
              *Ao fim do pacote contratado, será cobrado o envio excedente
              unitário por <strong>R$0,31</strong>
            </em>
          </Body>
        </>
      )}
    </CardWrapper>
  );
};

export { OverviewTabContent };
