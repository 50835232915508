import * as validation from 'shared/utils/validation';
import shortUrl from 'services/iclinic/shortUrl';

export default {
  validation,
  shortUrl,
};

const hasNonFeatureProperties = (key: string) =>
  ['$$typeof', 'type', 'compare', 'WrappedComponent'].includes(key);

export const deepMapKeys = (obj: object) => {
  if (Array.isArray(obj)) {
    return {};
  }

  const keys = Object.keys(obj);
  let finalObj = {};

  keys.forEach((key) => {
    if (hasNonFeatureProperties(key) || typeof obj !== 'object') {
      finalObj = {};
    } else {
      Object.assign(finalObj, {
        [key]: deepMapKeys(obj[key as keyof typeof obj]),
      });
    }
  });
  return finalObj;
};
