import * as Yup from 'yup';

export const required = 'Campo obrigatório';
export const validEmail = 'Digite um e-mail válido';

type Email = {
  password: string;
};

const validationSchema = Yup.object<Email>({
  password: Yup.string().required(required),
});

export default validationSchema;

export const initialValues = {
  password: '',
};

export type InitialValues = typeof initialValues;
