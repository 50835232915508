import { axiomTokens, MaterialUICore, TextArea } from '@iclinic/design-system';
import { CreateCSSProperties } from '@material-ui/styles';

import Modal from 'ui/new/modal';

const { styled } = MaterialUICore;

const justifyContentSpaceType = 'space-between';

const flexRowJustified: CreateCSSProperties = {
  display: 'flex',
  justifyContent: justifyContentSpaceType,
};

const { font, spacing, color, border } = axiomTokens;

export const PaymentInfoContainer = styled('section')(() => ({
  width: '100%',
  padding: '0 23px',
  display: 'flex',
  flexDirection: 'column',
  fontFamily: font.family.base,
  color: color.typeface.strong,
  fontSize: font.size.xs,
  marginTop: spacing[8],
  '& > .payment-info-header': {
    ...flexRowJustified,
  },
  '& > .payment-info-header > .payment-info-title': {
    fontSize: font.size.sm,
    fontWeight: font.weight.bold,
    marginBottom: spacing[8],
  },
  '& > .payment-method-title': {
    fontWeight: font.weight.bold,
    marginBottom: spacing[2],
  },
  '& > .payment-method-info': {
    marginBottom: spacing[6],
    color: color.typeface.smooth,
  },
}));

export const PaymentInfoObservationsArea = styled(TextArea)({
  '& textarea::placeholder': {
    color: color.typeface.smooth,
  },
  '& > textarea': {
    height: '150px',
  },
});

export const AddPaymentMethodContainer = styled(Modal)(({ theme }) => ({
  fontFamily: font.family.base,
  backgroundColor: color.branding[100],
  display: 'flex',
  flexDirection: 'column',

  gap: spacing[3],
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
}));

export const PaymentList = styled('ul')({});

export const PaymentItem = styled('li')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  padding: '13px 14px',
  width: '100%',
  gap: 10,
  fontFamily: font.family.base,
  fontWeight: font.weight.regular,
  fontSize: font.size.sm,
  backgroundColor: color.background[2],
  borderRadius: border.radius.md,
  marginBottom: spacing[6],
});

export const DetailsButtonWrapper = styled('div')({
  fontSize: font.size.xs,
  display: 'flex',
  width: '100%',
  justifyContent: justifyContentSpaceType,
  paddingLeft: '5%',
});

export const ReceivedDate = styled('div')({
  fontSize: font.size.xs,
  display: 'flex',
  width: '100%',
  justifyContent: 'flex-end',
});

export const PaymentItemInfo = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: justifyContentSpaceType,

  '& > span.payment-item-description': {
    width: 623,
    marginLeft: spacing[4],
  },
  '& > span.payment-item-value': {
    marginRight: 22,
    textAlign: 'right',
    minWidth: 129,
  },
});

export const ImgIcon = styled('img')({
  width: font.size.md,
  height: font.size.md,
});
