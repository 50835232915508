/* eslint-disable react/jsx-props-no-spreading */
// External
import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

// Internal
import { FORM_LIST_CAMPAIGNS } from 'shared/constants/forms';
import NotificationExpireMessage from 'shared/components/NotificationExpireMessage';
import {
  CONFIRM_DELETE_MODAL,
  CONFIRM_DELETE_SELECTED_MODAL,
  ADD_CAMPAIGN_MODAL,
} from 'features/campaign/modals';
import { open, close } from 'state/modal/actions';
import * as actions from 'state/campaign/actions';
import * as selectors from 'state/campaign/selectors';
import {
  selectAllId,
  searchName,
  pageWidthMobile,
} from 'state/campaign/constants';
import SearchBar from 'ui/new/search';
import ListCampaignsComponent from '../components/ListCampaigns';
import ListCampaignsMobile from '../components/ListCampaignsMobile';
import ListCampaignsItemComponent from '../components/ListCampaignsItem';
import ListCampaignsItemMobileComponent from '../components/ListCampaignsItemMobile';
import style from './ListCampaigns.scss';

const ListCampaigns = ({
  campaigns,
  fetchListCampaigns,
  deleteCampaign,
  selected,
  isAllSelected,
  setSelected,
  addSelected,
  removeSelected,
  deleteSelectedCampaigns,
  editCampaign,
  duplicateCampaign,
  setActiveCard,
  activeCard,
  errors,
  clearErrorsCampaign,
  notifications,
}) => {
  const [pageWidth, setWidth] = React.useState(window.innerWidth);
  const isMobile = pageWidth < pageWidthMobile;

  React.useEffect(() => {
    const updatePageWidth = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', updatePageWidth);
    return () => window.removeEventListener('resize', updatePageWidth);
  }, [setWidth]);

  React.useEffect(() => {
    fetchListCampaigns();
  }, [fetchListCampaigns]);

  const setListSelected = React.useCallback(
    (items, id, checked) => {
      if (id === selectAllId) {
        if (checked) {
          setSelected(new Set(campaigns.map((item) => item.id)));
        } else {
          setSelected(new Set());
        }
      } else if (checked) {
        addSelected(items, id);
      } else {
        removeSelected(items, id);
      }
    },
    [addSelected, campaigns, removeSelected, setSelected],
  );

  const onChangeCheckBox = React.useCallback(
    ({ target }) => {
      setListSelected(selected, target.value, target.checked);
    },
    [selected, setListSelected],
  );

  const searchCampaign = React.useMemo(
    () => (
      <Field
        type="text"
        name={searchName}
        component={SearchBar}
        placeholder="Pesquise por nome da campanha"
        key={searchName}
      />
    ),
    [],
  );
  const propsListCampaign = {
    campaignsSelected: selected,
    onChangeCheckBox,
    isAllSelected,
    deleteSelectedCampaigns,
  };
  const propsListCampaignsItem = (id) => ({
    onChangeCheckBox,
    isSelected: selected.has(id),
  });
  const propsListCampaignsMobile = { activeCard, setActiveCard };
  const ListComponent = isMobile ? ListCampaignsMobile : ListCampaignsComponent;
  const ItemComponent = isMobile
    ? ListCampaignsItemMobileComponent
    : ListCampaignsItemComponent;
  const otherPropsItems = (id) =>
    isMobile ? propsListCampaignsMobile : propsListCampaignsItem(id);
  const otherPropsList = isMobile ? {} : propsListCampaign;

  const notification = React.useMemo(() => {
    let result = [];
    const createExpireMessage = (item) => (
      <NotificationExpireMessage
        kind={item.kind || 'error'}
        title="Campanhas de e-mail"
        showCloseButton={false}
        key={item.id}
        onExpire={clearErrorsCampaign}
      >
        {item.message}
      </NotificationExpireMessage>
    );

    result = notifications
      .concat(errors)
      .map((item) => createExpireMessage(item));

    return result;
  }, [clearErrorsCampaign, errors, notifications]);

  return (
    <div className={style.container}>
      {notification}
      <ListComponent searchCampaign={searchCampaign} {...otherPropsList}>
        {campaigns.map((item) => (
          <ItemComponent
            campaign={item}
            deleteCampaign={deleteCampaign}
            key={item.id}
            editCampaign={editCampaign}
            duplicateCampaign={duplicateCampaign}
            {...otherPropsItems(item.id)}
          />
        ))}
      </ListComponent>
    </div>
  );
};

const mapStateToProps = (state) => {
  const campaigns = selectors.getCampaignListForDisplay(state);
  const selected = selectors.getSelectedCampaigns(state);
  const isAllSelected = selectors.getAllSelected(state);
  const activeCard = selectors.getActiveCard(state);
  const errors = selectors.getErrorsCampaign(state);
  const notifications = selectors.getCampaignNotifications(state);
  return {
    campaigns,
    selected,
    isAllSelected,
    activeCard,
    errors,
    notifications,
  };
};

ListCampaigns.defaultProps = {
  campaigns: [],
  errors: [],
  notifications: [],
};

ListCampaigns.propTypes = {
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      sent: PropTypes.string.isRequired,
      opened: PropTypes.string.isRequired,
      clicked: PropTypes.string.isRequired,
    }),
  ),
  fetchListCampaigns: PropTypes.func.isRequired,
  deleteCampaign: PropTypes.func.isRequired,
  selected: PropTypes.shape({
    has: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  addSelected: PropTypes.func.isRequired,
  removeSelected: PropTypes.func.isRequired,
  deleteSelectedCampaigns: PropTypes.func.isRequired,
  editCampaign: PropTypes.func.isRequired,
  duplicateCampaign: PropTypes.func.isRequired,
  setActiveCard: PropTypes.func.isRequired,
  activeCard: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.number,
      message: PropTypes.string,
    }),
  ),
  clearErrorsCampaign: PropTypes.func.isRequired,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.number,
      message: PropTypes.string,
      kind: PropTypes.string,
    }),
  ),
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(actions, dispatch),
  deleteCampaign: ({ id, title }) =>
    dispatch(
      open(CONFIRM_DELETE_MODAL, {
        title,
        onCloseClick: () => {
          dispatch(close());
        },
        onDeleteCampaign: () => {
          dispatch(actions.deleteCampaign(id));
        },
      }),
    ),
  deleteSelectedCampaigns: (campaigns) =>
    dispatch(
      open(CONFIRM_DELETE_SELECTED_MODAL, {
        count: campaigns.size,
        onCloseClick: () => {
          dispatch(close());
        },
        onDeleteSelectedCampaign: () => {
          dispatch(actions.deleteSelectedCampaigns(campaigns));
        },
      }),
    ),
  editCampaign: ({ id, status }) => dispatch(actions.editCampaign(id, status)),
  duplicateCampaign: ({ id, title }) =>
    dispatch(
      open(ADD_CAMPAIGN_MODAL, {
        title: `Cópia de ${title}`,
        onCloseClick: () => {
          dispatch(close());
        },
        onAddCampaign: () => {
          dispatch(actions.duplicateCampaign(id));
        },
      }),
    ),
});

export default reduxForm({
  form: FORM_LIST_CAMPAIGNS,
})(connect(mapStateToProps, mapDispatchToProps)(ListCampaigns));
