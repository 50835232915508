// External
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

// Internal
import style from './Item.scss';

const classes = classnames.bind(style);

export default function Item({
  children,
  type,
  modal,
  slug,
}) {
  return (
    <li
      className={classes('item', {
        text: type === 'text',
        icon: type === 'icon',
        selected: modal === slug,
      })}
    >
      {children}
    </li>
  );
}

Item.defaultProps = {
  type: 'text',
};

Item.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['text', 'icon']),
  modal: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};
