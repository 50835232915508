import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, MaterialIcons, Snackbar } from '@iclinic/design-system';

import { actions } from 'features/tissInvoicing/state/expense';
import { hasExpenseError } from 'features/tissInvoicing/state/expense/selectors';

const { ErrorOutlineOutlined } = MaterialIcons;

const ExpenseError = () => {
  const hasError = useSelector(hasExpenseError);

  const dispatch = useDispatch();

  const hide = useCallback(() => dispatch(actions.hideError()), [dispatch]);

  return (
    <Snackbar
      open={hasError}
      onClose={hide}
      severity="error"
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert
        severity="error"
        icon={<ErrorOutlineOutlined />}
        title="Tivemos um erro."
        message="Tente novamente."
        action={
          <Button variant="text" color="default" size="large" onClick={hide}>
            Fechar
          </Button>
        }
      />
    </Snackbar>
  );
};

export default ExpenseError;
