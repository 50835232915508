import {
  Body as DSBody,
  axiomTokens,
  Radio,
  Button,
  MaterialUICore,
  Chip as DSChip,
  DialogContent,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { font, border, color, spacing } = axiomTokens;

const boxSizingValue = 'border-box';

const colorButton = {
  0: '#E00060',
  1: '#B20047',
};

export const BodyDescription = styled(DSBody)(({ theme }) => ({
  letterSpacing: '0.72px',
  textAlign: 'left',
  marginTop: spacing[5],
  marginBottom: spacing[5],
  whiteSpace: 'pre-line',

  [theme.breakpoints.down('sm')]: {
    marginTop: spacing[2],
  },
}));

export const Body = styled(DSBody)(({ theme }) => ({
  paddingTop: spacing[5],

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const StyledLink = styled('a')({
  textDecoration: 'none',
  fontSize: font.size.xxs,
  fontWeight: 'bold',
  color: color.link.default,
  margin: spacing[1],
});

export const TrialOfferText = styled(DSBody)(({ theme }) => ({
  display: 'flex',
  marginTop: spacing[8],
  padding: `2px ${spacing[8]}`,
  background: color.surface.accent.default,
  boxSizing: boxSizingValue,
  borderRadius: border.radius.sm,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const BoldSpan = styled('span')({
  fontWeight: 'bold',
  fontSize: font.size.xxs,
});

export const StyledDialogContent = styled(DialogContent)({
  padding: '20px 32px',
  overflowY: 'hidden',
});

export const CardContainer = styled('div')(({ theme }) => ({
  marginTop: spacing[2],
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  boxSizing: boxSizingValue,
  gap: spacing[6],

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: spacing[1],
  },
}));

export const Card = styled('div')(({ theme }) => ({
  padding: `${spacing[4]} ${0} ${spacing[4]} ${spacing[4]}`,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: border.radius.sm,
  border: `${border.width.xs} solid ${color.border.subtle}`,
  cursor: 'pointer',
  width: '100%',
  boxSizing: boxSizingValue,
  position: 'relative',

  '&:has(input:checked)': {
    borderColor: color.border.activated,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${spacing[2]} ${spacing[2]}`,
    borderRadius: border.radius.sm,
  },
}));

export const RecommendedChip = styled(DSChip)(({ theme }) => ({
  position: 'absolute',
  top: '-0.8rem',
  left: '-0.5rem',
  backgroundColor: color.brand.highlight.purple[3],
  color: color.highlight.purple[3],
  padding: `${spacing[1]} ${spacing[2]}`,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const CardHeader = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  boxSizing: boxSizingValue,
});

export const SimpleRadio = styled(Radio)({
  padding: 0,
  margin: 0,
  marginLeft: spacing[2],
});

export const PriceContainer = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  gap: spacing[1],
  marginTop: spacing[2],
  marginBottom: spacing[2],
});

export const Chip = styled(DSChip)(({ theme }) => ({
  backgroundColor: color.brand.highlight.teal[3],
  marginLeft: 'auto',
  marginRight: spacing[4],

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const BodyContainer = styled('div')({
  marginLeft: spacing[4],
});

export const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: spacing[2],
  width: '100%',
  gap: spacing[3],

  [theme.breakpoints.down('xs')]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
  },
}));

export const SubscribeButton = styled(Button)({
  background: `linear-gradient(135deg, ${colorButton[0]} 0%, transparent) ${colorButton[1]}`,
  '&:hover': {
    background: `linear-gradient(135deg, ${colorButton[0]} 0%, ${colorButton[1]} 100%)`,
  },
});
