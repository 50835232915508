import { FLOW } from './constants';

export const normalizeSummary = (selectedProduct, selectedTotalUser, selectedtypeHiring) => ({
  products: [selectedProduct],
  totalUsers: selectedTotalUser,
  typeHiring: selectedtypeHiring,
});

export const normalizeSubscription = (selectedProduct, selectedUserIds) => ({
  products: [selectedProduct],
  users: selectedUserIds,
});


export const normalizeCheck = (array, property) => {
  const result = array.reduce((acc, current) => {
    if (current.checked) {
      return [...acc, current[property]];
    }
    return acc;
  }, []);

  return result;
};

export const normalizephysiciansData = (physiciansData, selectedAddon) => (
  (physiciansData.physicians.length === 1) && (selectedAddon === FLOW.onlineScheduling)
    ? { ...physiciansData, step: 3, totalUsers: 1 }
    : { ...physiciansData, step: 1, totalUsers: 0 }
);
