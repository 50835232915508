import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InitialLastEventToday } from '../types';
import { getActionTypes } from 'shared/utils/getActionTypes';

export const initialState: InitialLastEventToday = {
  isLastEventToday: undefined,
  isGetLastEventTodayLoading: false,
  isGetLastEventTodayError: false,
};

const storeName = '@webapp/feedDataLayer';

const feedDataLayerSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    getIsLastEventToday: (state, action: PayloadAction<string>) => ({
      ...state,
      isGetLastEventTodayLoading: true,
      eventId: action.payload,
    }),
    getIsLastEventTodaySuccess: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isGetLastEventTodayLoading: false,
      isGetLastEventTodayError: false,
      isLastEventToday: action.payload,
    }),
    getIsLastEventTodayFailed: (state) => ({
      ...state,
      isGetLastEventTodayLoading: false,
      isGetLastEventTodayError: true,
      isLastEventToday: false,
    }),
  },
});

export const actions = {
  ...feedDataLayerSlice.actions,
};

export const actionTypes = getActionTypes(actions, storeName);

export default feedDataLayerSlice.reducer;
