import React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useRifm } from 'rifm';
import {
  Grid,
  SectionTitle,
  MaterialIcons,
  TextField,
  DatePicker,
} from '@iclinic/design-system';

import { isValidDate } from 'shared/utils/date';
import { cpfFormatter } from 'shared/utils/formatters';
import CBOAutocomplete from 'features/tuss/cbo/components/CBOAutocomplete';
import { listCBOSelector } from 'features/tuss/cbo/state/selectors';
import { trackSignupIClinicPayForm } from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { SellerForm } from 'features/onlinePayment/state/config/types';
import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';
import {
  getUserInfo,
  isSellerAlreadyCreated,
} from 'features/onlinePayment/state/config/register/selectors';
import { ResponsiveCard } from 'features/onlinePayment/components';
import Subtitle from '../Subtitle';

const { PersonPinRounded } = MaterialIcons;

export default function CardClinicOwner(): JSX.Element | null {
  const disabled = useSelector(isSellerAlreadyCreated);
  const userData = useSelector(getUserInfo);
  const { objects: cboList } = useSelector(listCBOSelector);

  const { handleChange, values, errors, touched, setFieldValue } =
    useFormikContext<SellerForm>();

  const { value: cpf, onChange: onChangeCpf } = useRifm({
    value: values.cpf,
    onChange: (value: string) => setFieldValue('cpf', value),
    format: cpfFormatter,
  });

  const handleChangeBirthdate = (date: Date | null) => {
    setFieldValue('birthdate', date);
    if (!date) return;
    if (!isValidDate(date)) return;

    trackSignupIClinicPayForm({
      userData,
      flowType: 'legal_person',
      stepName: 'user_birth',
      stepNum: 18,
      stepValue: date.toISOString(),
    });
  };

  const handleChangeCBO = (stepValue: string) => {
    trackSignupIClinicPayForm({
      userData,
      flowType: 'legal_person',
      stepName: 'user_specialty',
      stepNum: 16,
      stepValue,
    });
  };

  const handleBlur = (stepName: string, stepNum: number, stepValue: string) => {
    trackSignupIClinicPayForm({
      userData,
      flowType: 'legal_person',
      stepName,
      stepNum,
      stepValue,
    });
  };

  if (values.type === SellerType.PF) return null;

  return (
    <ResponsiveCard variant="outlined">
      <SectionTitle
        icon={<PersonPinRounded />}
        title="Dono da clínica"
        mb={1}
      />
      <Grid direction="column" spacing={3} container>
        <Grid item>
          <Subtitle>
            Preencha com os dados referente ao dono da clínica
          </Subtitle>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            id="name"
            name="name"
            label="Nome do dono da clínica"
            placeholder="Igual ao documento"
            onChange={handleChange}
            onBlur={() => handleBlur('user_clinic_owner_name', 15, values.name)}
            value={values.name}
            error={!!(errors.name && touched.name)}
            helperText={touched.name && errors.name}
            disabled={disabled}
            fullWidth
          />
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={6}>
            <CBOAutocomplete
              nameField="cbo"
              optionsList={cboList}
              onChangeCBO={handleChangeCBO}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="cpf"
              name="cpf"
              label="CPF"
              placeholder="Somente números"
              onChange={onChangeCpf}
              onBlur={() => handleBlur('user_cpf', 17, '')}
              value={cpf}
              error={!!(errors.cpf && touched.cpf)}
              helperText={
                (touched.cpf && errors.cpf) ||
                'Esse dado não é mostrado aos pacientes'
              }
              disabled={disabled}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={4}>
            <DatePicker
              id="birthdate"
              name="birthdate"
              label="Data de Nascimento"
              onChange={handleChangeBirthdate}
              value={values.birthdate}
              error={!!(errors.birthdate && touched.birthdate)}
              helperText={touched.birthdate && errors.birthdate}
              disabled={disabled}
              allowKeyboard
            />
          </Grid>
        </Grid>
      </Grid>
    </ResponsiveCard>
  );
}
