import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { changeBillingDay, verifyEditPaymentPossibility } from '../services';
import { getAxiosErrorCode } from '../utils';
import {
  verifyEditPaymentSuccess,
  verifyPaymentDateEdit,
  verifyEditPaymentWithUnpaidMonth,
  verifyEditPaymentFail,
  verifyEditPaymentWithNotMonthly,
  changePaymentDate,
  changePaymentDateSuccess,
  changePaymentDateFail,
  isFetching,
  verifyEditPaymentAlreadyChanged,
} from './reducer';

const actionByErrorCode: Record<string, ActionCreatorWithoutPayload<string>> = {
  '400903': verifyEditPaymentWithNotMonthly,
  '400905': verifyEditPaymentWithUnpaidMonth,
  '400908': verifyEditPaymentAlreadyChanged,
};

const callActionByErrorCode = (code: string) => actionByErrorCode[code]();

export function* verifyPaymentDateEditWorker() {
  try {
    yield call(verifyEditPaymentPossibility);
    yield put(verifyEditPaymentSuccess());
  } catch (error) {
    const errorCode = getAxiosErrorCode(error as AxiosError);

    if (actionByErrorCode[errorCode]) {
      yield put(callActionByErrorCode(errorCode));
    } else {
      yield put(verifyEditPaymentFail());
    }
  }
}

export function* changePaymentDateWorker({ payload }: { payload: number }) {
  try {
    yield put(isFetching());
    yield call(changeBillingDay, { billingDay: payload });
    yield put(changePaymentDateSuccess(payload));
  } catch (err) {
    yield put(changePaymentDateFail());
  }
}

export default function* editPaymentDateSagas() {
  yield all([
    takeLatest(verifyPaymentDateEdit, verifyPaymentDateEditWorker),
    takeLatest(changePaymentDate, changePaymentDateWorker),
  ]);
}
