import { SSOTokenRawData } from 'features/onlinePayment/state/iclinic-pay/types';

export function createLink(ssoToken: SSOTokenRawData, iclinicPayUrl: string) {
  const { base_url, access_token, refresh_token } = ssoToken;
  const path = `/#/auth/sso?access_token=${access_token}&refresh_token=${refresh_token}&redirect=${iclinicPayUrl}&go_back=${process.env.ICLINIC_APP_URL}`;

  try {
    return new URL(path, base_url).toString();
  } catch (error) {
    return undefined;
  }
}

export const ICLINIC_PAY_URLS = {
  GESTAO_FINANCEIRA: '/pages/gestao-financeira/dashboard/resumo',
  RELATORIO_RECEBIVES: '/pages/particular/recebiveis/calendar',
  ANTECIPACAO_RECEBIVES: '/pages/antecipacoes/antecipacao-transacao',
  SIMULADOR_VENDAS: '/pages/simulador-vendas',
  CADASTRO_CONVENIO: '/pages/planos-de-saude/meus-convenios/list',
  ANTECIPAR_CONVENIO: '/pages/antecipacoes/antecipacao-fatura',
};
