import { SellerType } from 'features/onlinePayment/state/constants';

export const installmentsXfee: Record<string, string> = {
  '1': '2,10%',
  '2': '2,82%',
  '3': '2,82%',
  '4': '2,82%',
  '5': '2,82%',
  '6': '2,82%',
  '7': '3,27%',
  '8': '3,27%',
  '9': '3,27%',
  '10': '3,27%',
  '11': '3,27%',
  '12': '3,27%',
};

export { SellerType };
