import appTypes from 'shared/utils/actions';
import { SUCCESS, FAILURE } from 'shared/constants/actions';
import {
  FlowType,
  ScheduleOffsetUnit,
} from 'features/marketing/constants';
import { ApiErrors } from '@types';
import {
  AvailableFlow,
  AddedFlow,
  ReturnFlow,
  PatientId,
} from './types';

const id: PatientId = '';
const patientIds: PatientId[] = [];
const errors: ApiErrors[] = [];
const availableFlows: AvailableFlow[] = [];
const addedFlows: AddedFlow[] = [];
const returnTypeFlows: ReturnFlow[] = [];
const selectedReturnFlow: ReturnFlow = {
  id: '',
  name: '',
  offset: {
    value: 0,
    unit: ScheduleOffsetUnit.day,
  },
  flowType: FlowType.return,
};

export const initialState = {
  isFetching: false,
  id,
  patientIds,
  selectedReturnFlow,
  errors,
  availableFlows,
  addedFlows,
  returnTypeFlows,
};

const emailFlow = appTypes.defineAction('emailFlow/patient');

export const SAVE_PATIENT_FLOWS = emailFlow.defineAction('SAVE_PATIENT_FLOWS', [SUCCESS, FAILURE]);
export const FETCH_PATIENT_SUMMARY = emailFlow.defineAction('FETCH_PATIENT_SUMMARY', [SUCCESS, FAILURE]);
export const ADD_PATIENT_FLOW = emailFlow.defineAction('ADD_PATIENT_FLOW');
export const REMOVE_PATIENT_FLOW = emailFlow.defineAction('REMOVE_PATIENT_FLOW');
export const SET_SELECTED_RETURN_FLOW = emailFlow.defineAction('SET_SELECTED_RETURN_FLOW');
export const RESET_STATE = emailFlow.defineAction('RESET_STATE');
export const SET_PATIENT = emailFlow.defineAction('SET_PATIENT');
export const REMOVE_PATIENT = emailFlow.defineAction('REMOVE_PATIENT', [SUCCESS, FAILURE]);
