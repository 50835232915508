// External
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material/react-material-icon';

// Internal
import { Panel, Title } from '@iclinic/iclinic-ui';
import {
  tableColumns,
  tableBodyEmptyText,
  selectAllId,
  tableTitleAlign,
  tableColumnKeys,
} from 'state/campaign/constants';
import Table from 'ui/new/table';
import CheckBox from 'ui/new/form/CheckBox';
import Button from 'ui/new/button';
import style from './ListCampaigns.scss';

const titleItemsSelected = (items) =>
  items > 1 ? `${items} itens selecionados` : `${items} item selecionado`;

export const tableStyleColumn = {
  [tableColumnKeys.clicked]: style.tableClicked,
  [tableColumnKeys.sent]: style.tableSentAndOpened,
  [tableColumnKeys.opened]: style.tableSentAndOpened,
};

const ListCampaigns = ({
  children,
  searchCampaign,
  campaignsSelected,
  isAllSelected,
  onChangeCheckBox,
  deleteSelectedCampaigns,
}) => {
  const onClickDeleteAll = React.useCallback(() => {
    deleteSelectedCampaigns(campaignsSelected);
  }, [campaignsSelected, deleteSelectedCampaigns]);

  const tableHeaders = React.useMemo(
    () => [
      <Table.Item align="left" key="check">
        <CheckBox
          selected={isAllSelected}
          id="selectAllCampaigns"
          name={selectAllId}
          value={selectAllId}
          onChange={onChangeCheckBox}
        />
      </Table.Item>,
      tableColumns.map(({ key, label }) => (
        <Table.Item
          align={tableTitleAlign[key]}
          key={key}
          classes={tableStyleColumn[key]}
        >
          {label}
        </Table.Item>
      )),
    ],
    [isAllSelected, onChangeCheckBox],
  );

  return (
    <Panel title={searchCampaign}>
      {campaignsSelected.size > 0 && (
        <div className={style.selectedItemsTable}>
          <Title>{titleItemsSelected(campaignsSelected.size)}</Title>
          <Button type="link" onClick={onClickDeleteAll}>
            <Icon icon="delete" />
            Excluir permanentemente
          </Button>
        </div>
      )}
      <div className={style.tableContainer}>
        <Table>
          <Table.Head>
            <Table.Row>{tableHeaders}</Table.Row>
          </Table.Head>
          <Table.Body>{children}</Table.Body>
        </Table>
      </div>
      {children && children.length === 0 && (
        <Title className={style.tableEmpty}>{tableBodyEmptyText}</Title>
      )}
    </Panel>
  );
};

ListCampaigns.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  searchCampaign: PropTypes.node.isRequired,
  campaignsSelected: PropTypes.shape({
    has: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
  onChangeCheckBox: PropTypes.func.isRequired,
  isAllSelected: PropTypes.bool.isRequired,
  deleteSelectedCampaigns: PropTypes.func.isRequired,
};

export default ListCampaigns;
