// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import Dropdown from 'ui/new/dropdown';

const FieldOutputDropdown = ({
  lotDescription,
  lotItems,
  name,
  lotIsOpen,
  setLotIsOpen,
  meta: { touched, error, invalid },
}) => (
  <Dropdown
    title={lotDescription}
    icon={false}
    name={name}
    placeholder="Selecione ou digite um lote"
    isOpenProp={lotIsOpen}
    setToggleMenuProp={setLotIsOpen}
    touched={touched}
    error={error}
    invalid={invalid}
  >
    {lotItems}
  </Dropdown>
);

FieldOutputDropdown.propTypes = {
  name: PropTypes.string,
  setLotIsOpen: PropTypes.func.isRequired,
  lotIsOpen: PropTypes.bool,
  lotItems: PropTypes.node,
  lotDescription: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
};

FieldOutputDropdown.defaultProps = {
  name: null,
  lotIsOpen: false,
  lotItems: null,
  lotDescription: '',
  meta: {
    touched: false,
    invalid: false,
    error: null,
  },
};

FieldOutputDropdown.defaultProps = {
  name: null,
  lotIsOpen: false,
  lotItems: null,
  lotDescription: '',
  meta: {
    touched: false,
    invalid: false,
    error: null,
  },
};

export default FieldOutputDropdown;
