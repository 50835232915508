import AddCampaignModal from './containers/AddCampaignModal';
import ConfirmDeleteModal from './containers/ConfirmDeleteModal';
import ConfirmDeleteSelectedModal from './containers/ConfirmDeleteSelectedModal';
import EditorLinkModal from './containers/editor/modal/EditorLinkModal';
import EditorButtonModal from './containers/editor/modal/EditorButtonModal';
import EditorImageModal from './containers/editor/modal/EditorImageModal';
import SendEmailTestModal from './containers/SendEmailTestModal';
import ScheduleEmailModal from './components/finalize/ScheduleEmailModal';
import SendEmailNowModal from './components/finalize/SendEmailNowModal';

export const ADD_CAMPAIGN_MODAL = 'modal/campaign/ADD_CAMPAIGN_MODAL';
export const CONFIRM_DELETE_MODAL = 'modal/campaign/CONFIRM_DELETE_MODAL';
export const CONFIRM_DELETE_SELECTED_MODAL = 'modal/campaign/CONFIRM_DELETE_SELECTED_MODAL';
export const EDITOR_LINK_MODAL = 'modal/campaign/EDITOR_LINK_MODAL';
export const EDITOR_BUTTON_MODAL = 'modal/campaign/EDITOR_BUTTON_MODAL';
export const EDITOR_IMAGE_MODAL = 'modal/campaign/EDITOR_IMAGE_MODAL';
export const SEND_EMAIL_TEST_MODAL = 'modal/campaign/SEND_EMAIL_TEST_MODAL';
export const SCHEDULE_EMAIL_MODAL = 'modal/campaign/SCHEDULE_EMAIL_MODAL';
export const SEND_EMAIL_NOW_MODAL = 'modal/campaign/SEND_EMAIL_NOW_MODAL';

export default {
  [ADD_CAMPAIGN_MODAL]: AddCampaignModal,
  [CONFIRM_DELETE_MODAL]: ConfirmDeleteModal,
  [CONFIRM_DELETE_SELECTED_MODAL]: ConfirmDeleteSelectedModal,
  [EDITOR_LINK_MODAL]: EditorLinkModal,
  [EDITOR_BUTTON_MODAL]: EditorButtonModal,
  [EDITOR_IMAGE_MODAL]: EditorImageModal,
  [SEND_EMAIL_TEST_MODAL]: SendEmailTestModal,
  [SCHEDULE_EMAIL_MODAL]: ScheduleEmailModal,
  [SEND_EMAIL_NOW_MODAL]: SendEmailNowModal,
};
