import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';

import { getActionTypes } from 'shared/utils/getActionTypes';
import { PatientHeaderState, PatientHeader } from './types';

const sliceName = 'cardHeader';
const storeName = `@webapp/${sliceName}`;

export const initialState: PatientHeaderState = {
  patientHeader: {
    age: '',
    insuranceName: '',
    firstAppointment: '',
    patientRelated: [],
    name: '',
    civilName: '',
    urlImage: '',
    email: '',
    mobilePhone: '',
    homePhone: '',
  },
  isLoading: false,
  hasError: false,
};

const cardHeaderSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    setPatientHeader: (state, action: PayloadAction<PatientHeader>) => ({
      ...state,
      patientHeader: action.payload,
    }),
    setIsLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload,
    }),
    setHasError: (state, action: PayloadAction<boolean>) => ({
      ...state,
      hasError: action.payload,
    }),
  },
});

export const actions = {
  ...cardHeaderSlice.actions,
  getCardPatient: createAction<number>(`${storeName}/getCardPatient`),
  togglePatientHeaderModal: createAction(
    `${storeName}/togglePatientHeaderModal`,
  ),
};

export const actionTypes = getActionTypes(actions, storeName);

export default cardHeaderSlice.reducer;
