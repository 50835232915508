import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { GuideList } from '../containers/guide-list';

const Root = () => (
  <Switch>
    <Route path="/faturamento-tiss/guias" component={GuideList} />
    <Redirect to="/404/" />
  </Switch>
);

export default Root;
