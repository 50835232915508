import { PromiseResponse } from '@types';
import iClinicApi from 'services/iclinic/iClinicApi';
import {
  AvailableFilterResponse,
  GetSampleBoxesParams,
  SampleBoxesResponse,
} from '../types';

export const fetchSampleBoxes = ({
  activePrinciples,
  pharma,
  query,
  onlyAvailables,
}: GetSampleBoxesParams) => {
  const params = [];
  if (activePrinciples) {
    params.push(`medicaments__active_principle__name__in=${activePrinciples}`);
  }

  if (pharma) {
    params.push(`pharmaceutical__name=${pharma}`);
  }

  if (query) {
    params.push(`q=${query}`);
  }

  params.push(onlyAvailables ? 'can_request=true' : 'already_requested=true');

  return iClinicApi.get<PromiseResponse<SampleBoxesResponse>>(
    `/free-samples/box/?${params.join('&')}`,
  );
};

export const getAvailablePharmaceuticals = () =>
  iClinicApi.get<PromiseResponse<AvailableFilterResponse>>(
    '/free-samples/box/pharmaceuticals-boxes-availables/',
  );

export const getAvailableActivePrinciples = () =>
  iClinicApi.get<PromiseResponse<AvailableFilterResponse>>(
    '/free-samples/box/actives-principles-boxes-availables/',
  );

export const getSuggestions = (query: string) =>
  iClinicApi.get(`/free-samples/box/autocomplete/?q=${query}`);
