import { axiomTokens, Box, Body, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { color } = axiomTokens;

type TableRowProps = {
  isodd: boolean;
};

type TableCellProps = {
  customwidth?: string;
};

export const HeaderTopBar = styled(Box)({
  backgroundColor: `${axiomTokens.color.branding['150']}`,
  padding: `${axiomTokens.spacing['2']} ${axiomTokens.spacing['4']}`,
});

export const HeaderTable = styled(Box)({
  backgroundColor: color.background[2],
  padding: `${axiomTokens.spacing['2']} ${axiomTokens.spacing['4']}`,
});

export const TableCell = styled(Body)({
  width: (props: TableCellProps) =>
    props.customwidth ? `${props.customwidth}%` : '100%',
});

export const TableRow = styled(Box)({
  backgroundColor: (props: TableRowProps) =>
    props.isodd ? color.background[2] : color.background[1],
  padding: `${axiomTokens.spacing['2']} ${axiomTokens.spacing['4']}`,
});
