import { Reducer } from 'redux';

import {
  SAVE_PATIENT_FLOWS,
  FETCH_PATIENT_SUMMARY,
  ADD_PATIENT_FLOW,
  REMOVE_PATIENT_FLOW,
  REMOVE_PATIENT,
  SET_SELECTED_RETURN_FLOW,
  RESET_STATE,
  SET_PATIENT,
  initialState,
} from './constants';
import {
  onAddFlow,
  onAddReturnFlow,
  removeFlowFromAddedFlows,
  addInternalIdIntoAddedFlows,
  setSelectedReturnFlowByAddedFlow,
  hasReturnFlowInAddedFlows,
} from './utils';
import { PatientState, EmailFlowActions } from './actions';

const patientReducer: Reducer<PatientState> = (
  state: PatientState = initialState,
  action: EmailFlowActions,
) => {
  switch (action.type) {
    case SAVE_PATIENT_FLOWS.ACTION:
    case FETCH_PATIENT_SUMMARY.ACTION:
    case SAVE_PATIENT_FLOWS.SUCCESS:
    case REMOVE_PATIENT.SUCCESS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      };
    case FETCH_PATIENT_SUMMARY.FAILURE:
    case SAVE_PATIENT_FLOWS.FAILURE:
    case REMOVE_PATIENT.FAILURE:
      return {
        ...state,
        isFetching: action.payload.isFetching,
        errors: action.payload.errors,
      };
    case FETCH_PATIENT_SUMMARY.SUCCESS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
        availableFlows: action.payload.availableFlows,
        addedFlows: addInternalIdIntoAddedFlows(action.payload.addedFlows),
        selectedReturnFlow: !hasReturnFlowInAddedFlows(action.payload.addedFlows)
          ? state.selectedReturnFlow
          : setSelectedReturnFlowByAddedFlow(action.payload.addedFlows),
      };
    case ADD_PATIENT_FLOW.ACTION:
      return {
        ...state,
        addedFlows: onAddFlow(state.addedFlows, action.meta.flow),
      };
    case REMOVE_PATIENT_FLOW.ACTION:
      return {
        ...state,
        addedFlows: removeFlowFromAddedFlows(state.addedFlows, action.meta.flow),
      };
    case SET_SELECTED_RETURN_FLOW.ACTION:
      return {
        ...state,
        selectedReturnFlow: action.payload.selectedReturnFlow,
        addedFlows: onAddReturnFlow(
          action.payload.selectedReturnFlow,
          state.selectedReturnFlow,
          state.addedFlows,
        ),
      };
    case RESET_STATE.ACTION:
      return action.payload;
    case REMOVE_PATIENT.ACTION:
      return {
        ...state,
        patientIds: action.payload.patientIds,
      };
    case SET_PATIENT.ACTION:
      return {
        ...state,
        id: action.payload.id,
      };
    default:
      return state;
  }
};

export default patientReducer;
