import React from 'react';

import { Box } from '@iclinic/design-system';
import useStyles from './Loader.styles';

export default function Loader(): JSX.Element {
  const classes = useStyles();

  return (
    <Box data-testid="online-payment-loader" className={classes.ballAnimation}>
      <span />
    </Box>
  );
}
