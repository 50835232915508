import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { LotList, AddGuidesToLot, CreateLot } from '../containers/lot';

const Root = () => (
  <Switch>
    <Route path="/faturamento-tiss/lotes/novo/sadt" component={CreateLot} />
    <Route
      path="/faturamento-tiss/lotes/guias/:lotId"
      component={AddGuidesToLot}
    />
    <Route path="/faturamento-tiss/lotes/:lotId" component={CreateLot} />
    <Route path="/faturamento-tiss/lotes" component={LotList} />
    <Redirect to="/404/" />
  </Switch>
);

export default Root;
