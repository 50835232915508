import React from 'react';

import style from './Text.scss'

const TissText = () => (
  <div className={style.tissInfo}>
    <p>
    Com o Faturamento TISS de iClinic você consegue emitir guias TISS com mais facilidade, além de gerar e imprimir guias SADT sem burocracias.
    </p>
    <p>
      Esta funcionalidade te permite: 
      <ul>
        <li>Configurar dados do convênio, prestador e procedimento para autopreenchimento;</li>
        <li>
        Gerar e imprimir as guias;
        </li>
        <li>Fechar lotes para enviar às operadoras de saúde</li>
      </ul>
    </p>
    <p>
      O Faturamento TISS é 100% integrado ao sistema iClinic e você ganha
      mais facilidade no preenchimento de guias dos seus pacientes, além de melhorar sua comunicação com operadoras de saúde, economizando tempo e evitando erros e glosas.
    </p>
  </div>
)

export default TissText;
