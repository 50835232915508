import { SellerBankAccountRawData } from 'features/onlinePayment/services/config/types';
import { SellerEditForm } from '../types';

export function bankAccountHasChanged(
  current: SellerBankAccountRawData,
  newone: SellerEditForm,
) {
  return (
    current.account_number !== newone.account ||
    current.bank_code !== newone.bank ||
    current.routing_number !== newone.agency
  );
}
