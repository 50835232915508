import React from 'react';
import { connect } from 'react-redux';

import TermsNotification from '../components/TermsNotification';
import styles from './TopNotification.scss';

const TopNotification = (): JSX.Element => (
  <div className={styles['notification-container']}>
    <TermsNotification />
  </div>
);

const mapStateToProps = (state) => ({
  userData: state.userInfo.userData,
});

export default connect(mapStateToProps)(TopNotification);
