import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DatePickerWrapper, PeriodDatePicker } from './Filters.styles';
import {
  changeDateFilterType,
  fetchTelemedicineReports,
} from 'features/reports/state/telemedicineReport/reducer';
import { formatDateToString } from 'features/reports/utils';
import { getDateFilterType } from 'features/reports/state/telemedicineReport/selectors';

export const PeriodFilter = () => {
  const dispatch = useDispatch();
  const filterType = useSelector(getDateFilterType);

  const [fromDate, setDateFrom] = useState<Date | null>();
  const [toDate, setDateTo] = useState<Date | null>();
  const [toDateOpen, setToDateOpen] = useState(false);

  useEffect(() => {
    const thereAreDates = fromDate && toDate;

    if (thereAreDates && fromDate <= toDate) {
      dispatch(changeDateFilterType('detailed'));
      const filterParams = {
        fromDate: formatDateToString(fromDate),
        toDate: formatDateToString(toDate),
      };
      dispatch(fetchTelemedicineReports(filterParams));
    }
  }, [dispatch, fromDate, toDate]);

  useEffect(() => {
    if (filterType === 'quick') {
      setDateFrom(undefined);
      setDateTo(undefined);
    }
  }, [filterType]);

  const getDateFromMaxDate = () => toDate || new Date();

  return (
    <DatePickerWrapper>
      <PeriodDatePicker
        id="fromdate"
        name="fromdate"
        label="De"
        value={fromDate}
        maxDate={getDateFromMaxDate()}
        onChange={(date) => {
          if (!toDate) setToDateOpen(true);
          setDateFrom(date);
        }}
      />
      <PeriodDatePicker
        id="todate"
        name="todate"
        label="Até"
        open={toDateOpen}
        onOpen={() => setToDateOpen(true)}
        onClose={() => setToDateOpen(false)}
        disabled={!fromDate}
        minDate={fromDate}
        minDateMessage=""
        value={toDate}
        onChange={(date) => setDateTo(date)}
      />
    </DatePickerWrapper>
  );
};
