import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MaterialUICore, Box, Grid } from '@iclinic/design-system';
import {
  getPageNumber,
  getTransactionsListPagination,
} from 'features/onlinePayment/state/reports/transactions/selectors';
import { selectedPageNumber } from 'features/onlinePayment/state/reports/transactions';
import useStyles from './Paginate.styles';

const { Pagination } = MaterialUICore;

export default function Paginate(): JSX.Element | null {
  const classes = useStyles();

  const dispatch = useDispatch();
  const pageNumber = useSelector(getPageNumber);
  const transactionsPagination = useSelector(getTransactionsListPagination);

  const handlePageChange = (_event: object, page: number) => {
    dispatch(selectedPageNumber(page));
  };

  const totalOfPages = (totalPages: number): number => {
    return Math.ceil(totalPages / 10);
  };

  if (!transactionsPagination) return null;

  const { count } = transactionsPagination;

  return (
    <>
      {count > 10 && (
        <Box mt={2} mb={4}>
          <Grid container justify="center">
            <Pagination
              data-testid="pagination-table"
              className={classes.pagination}
              color="standard"
              count={totalOfPages(count)}
              page={pageNumber}
              shape="rounded"
              onChange={handlePageChange}
            />
          </Grid>
        </Box>
      )}
    </>
  );
}
