import { PreviewTemplateData, TemplateMessage } from '../../../services/types';
import {
  MultiplesScheduleTemplateMessage,
  PreviewTemplateActionData,
} from '../../types';

export const normalizeScheduleTemplateMessage = (
  scheduleTemplateMessage: MultiplesScheduleTemplateMessage,
): TemplateMessage => ({
  content: scheduleTemplateMessage.content,
  is_active: scheduleTemplateMessage.isActive,
  schedules: scheduleTemplateMessage.schedules.map(
    ({ id, daysAdvance, timeSend }) => ({
      id,
      days_advance: daysAdvance,
      time_send: timeSend,
    }),
  ),

  schedules_deleted: scheduleTemplateMessage.schedulesDeleted,
  contact_phone: scheduleTemplateMessage.contactPhone,
});

export const normalizePreviewTemplateMessageData = (
  templateActionData: PreviewTemplateActionData,
): PreviewTemplateData => ({
  content: templateActionData.content || '',
  contact_phone: templateActionData.contactPhone,
});
