import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const ProcedureContainer = styled('section')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  fontSize: tokens.font.size.sm,
  fontFamily: tokens.font.family.base,
  '& > .procedure_list_title': {
    fontSize: tokens.font.size.sm,
    fontWeight: tokens.font.weight.bold,
    marginBottom: tokens.spacing.lg,
  },
});

export const ProcedureList = styled('ul')(({ theme }) => ({
  marginBottom: tokens.spacing.sm,
  '& > li': {
    display: 'grid',
    gridTemplateColumns: 'auto 15% 15%',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'auto auto auto',
    },
    [theme.breakpoints.up('sm')]: {
      '& > span:first-child': {
        flexGrow: 1,
      },
    },
    '& > span:nth-child(2)': {
      width: '54px',
      textAlign: 'center',
    },
    '& > span:last-child': {
      textAlign: 'right',
    },
  },
}));

export const TotalBox = styled('div')(({ theme }) => ({
  fontWeight: tokens.font.weight.bold,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  color: tokens.color.neutral[900],
  fontSize: tokens.font.size.sm,
  gap: '8px',
  marginTop: tokens.spacing.sm,
  [theme.breakpoints.down('sm')]: {
    gap: '42px',
  },
  '& > span:last-child': {
    textAlign: 'right',
  },
}));

export const RemainingAmount = styled(TotalBox)({
  fontWeight: tokens.font.weight.regular,
  color: tokens.color.feedback.error.main,
  fontSize: tokens.font.size.xs,
  alignItems: 'center',
  marginBottom: tokens.spacing.xs,
  '& svg': {
    fontSize: tokens.font.size.xs,
  },
});

export const SubTotalBox = styled(TotalBox)({
  color: tokens.color.neutral[700],
});
