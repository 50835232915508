import { Heading, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, spacing } = tokens;

export const MobileCard = styled('div')(({ theme }) => ({
  height: 64,
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alingItems: 'center',
  padding: `${spacing.base} ${spacing.sm}`,
  gap: spacing.sm,
  position: 'fixed',
  backgroundColor: color.neutral[0],
  border: `${border.width.hairline} solid ${color.neutral[100]}`,
  bottom: 0,
  left: 0,
  transform: 'translateY(-60px)',
  transition: 'all 5s ease-in',
  zIndex: 1,

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

export const DescriptionTitle = styled(Heading)({
  color: color.primary.main,
});
