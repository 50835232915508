import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import history from 'routes/history';
import { actions } from 'features/tissInvoicing/state/lot';
import { Heading, Button, MaterialIcons } from '@iclinic/design-system';
import { SpaceBetweenBox } from 'features/tissInvoicing/components/ui/Flexbox';
import NotAddedGuidesModal from './NotAddedGuidesModal';
import * as Styles from './styles';

const { ArrowBack } = MaterialIcons;

interface HeaderProps {
  lot_id?: string | number;
  lot_number?: number;
  shouldShowModal: boolean;
}

function Header({ lot_id, lot_number, shouldShowModal }: HeaderProps) {
  const dispatch = useDispatch();
  const classes = Styles.useStyles();

  const [notAddedGuidesModal, setNotAddedGuidesModal] = useState<boolean>(
    false,
  );

  const previousPage = `/faturamento-tiss/lotes/${lot_id}`;

  const handleCloseModal = () => setNotAddedGuidesModal(false);

  const handleReturn = () => {
    if (shouldShowModal) {
      setNotAddedGuidesModal(true);
      return;
    }

    history.push(previousPage);
    dispatch(actions.resetAddGuidesState());
  };

  return (
    <SpaceBetweenBox classes={{ root: classes.container }}>
      <Heading variant="lg">
        {`Adicionar guias ao lote${lot_number ? ` N.º ${lot_number}` : ''}`}
      </Heading>
      <Button
        classes={{ root: classes.button }}
        color="transparent"
        startIcon={<ArrowBack />}
        onClick={handleReturn}
      >
        Voltar
      </Button>

      <NotAddedGuidesModal
        isOpen={notAddedGuidesModal}
        handleClose={handleCloseModal}
        redirectUrl={previousPage}
      />
    </SpaceBetweenBox>
  );
}

export default Header;
