import React from 'react';
import { Participant } from 'twilio-video';

import { useParticipantNetworkQualityLevel } from 'features/video-conference/hooks/useParticipantNetworkQualityLevel';
import DarkChip from 'features/video-conference/components/TeamComponents/DarkChip';
import useStyles from './styles';
import { MaterialIcons } from '@iclinic/design-system';
import { isMobile } from 'features/video-conference/utils';
import {
  CONNECTION_FEEDBACK_WARNING,
  CONNECTION_FEEDBACK_WARNING_MOBILE,
  networkQuality,
} from 'features/video-conference/constants';

const {
  SignalCellular0Bar,
  SignalCellular1Bar,
  SignalCellular2Bar,
} = MaterialIcons;

export default function NetworkQualityLevel({
  participant,
}: {
  participant: Participant;
}) {
  const classes = useStyles();
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);
  if (networkQualityLevel === null || networkQualityLevel > 3) return null;

  const getIcon = () => {
    switch (networkQualityLevel) {
      case 0:
        return (
          <SignalCellular0Bar
            className={classes.icon}
            titleAccess={networkQuality.BROKEN}
          />
        );
      case 1:
        return (
          <SignalCellular1Bar
            className={classes.icon}
            titleAccess={networkQuality.BAD}
          />
        );
      case 2:
      case 3:
        return (
          <SignalCellular2Bar
            className={classes.icon}
            titleAccess={networkQuality.AVERAGE}
          />
        );
      default:
        return null;
    }
  };

  const icon = <>{getIcon()}</>;
  const content = isMobile
    ? CONNECTION_FEEDBACK_WARNING_MOBILE
    : CONNECTION_FEEDBACK_WARNING;

  return (
    <DarkChip icon={icon} content={content} customClassName={classes.root} />
  );
}
