import React from 'react';

import { ReturnFlow } from 'state/marketing/emailFlow/patient/types';

import DropdownReturnFlows from './DropdownReturnFlows';
import style from './ReturnItem.scss';

type ReturnProps = {
  children: React.ReactNode,
  isOpen: boolean,
  toggleOpen: (isOpen: boolean) => void,
  selectedReturnFlow: ReturnFlow,
};

export default function Return({
  isOpen = false,
  selectedReturnFlow,
  toggleOpen,
  children,
}: ReturnProps): JSX.Element {
  return (
    <div className={style.returnItem}>
      Retorno previsto para
      <DropdownReturnFlows
        isOpen={isOpen}
        toggleOpen={toggleOpen}
        selectedReturnFlow={selectedReturnFlow}
      >
        {children}
      </DropdownReturnFlows>
    </div>
  );
}
