import { createParamsAction, createSimpleAction } from 'shared/utils/actions';
import {
  SEND_EMAIL_ACTION,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_RESET,
} from './constants';
import {
  SendEmailFailure,
  SendEmailReset,
  SendEmailSuccess,
  SendEmail,
} from './types';

export const sendEmail = createParamsAction<SendEmail>(SEND_EMAIL_ACTION);

export const sendEmailSuccess = createSimpleAction<SendEmailSuccess>(
  SEND_EMAIL_SUCCESS,
);

export const sendEmailFailure = createParamsAction<SendEmailFailure>(
  SEND_EMAIL_FAILURE,
);

export const sendEmailReset = createSimpleAction<SendEmailReset>(
  SEND_EMAIL_RESET,
);
