import {
  MaterialUICore,
  axiomTokens,
  IconButton as IconButtonDS,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { spacing } = axiomTokens;

export const TranscriptTittle = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: spacing[1],
  '&:hover > button': {
    visibility: 'visible',
  },
});

export const TranscriptContent = styled('div')({
  padding: `${spacing[1]} 0px`,
  marginBottom: spacing[3],
  '&:last-child': {
    marginBottom: '0px',
  },
});

export const IconButton = styled(IconButtonDS)({
  visibility: 'hidden',
});
