import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import ss from './FieldError.scss';

const cx = classnames.bind(ss);

const FieldError = ({ children, classes }) => (
  <div className={cx(ss.wrapper, classes)}>{children}</div>
);

FieldError.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
};

FieldError.defaultProps = {
  classes: '',
};

export default FieldError;
