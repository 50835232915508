import { createSelector } from 'reselect';

import { RequestStatus } from 'shared/constants/State';
import { IStoreState } from 'state/rootReducer';

export const isFetchingDownsellOptions = ({ settingsUser }: IStoreState) =>
  settingsUser.downsellReason.fetchStatus === RequestStatus.Pending;

export const getDownsellOptions = ({ settingsUser }: IStoreState) =>
  settingsUser.downsellReason.downsellOptions;

export const getDownsellReasons = ({ settingsUser }: IStoreState) =>
  settingsUser.downsellReason.downsellReasons;

export const getDownsellOptionsData = createSelector(
  [isFetchingDownsellOptions, getDownsellOptions],
  (isFetching, downsellOptions) => ({
    isFetchingDownsellOptions: isFetching,
    downsellOptions,
  }),
);

export const isSubmitingDownsell = ({ settingsUser }: IStoreState) =>
  settingsUser.downsellReason.submitStatus === RequestStatus.Pending;
