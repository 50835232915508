import {
  fetchRoomJoin,
  fetchRoomStart,
} from 'features/video-conference/services';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useConferenceFinish } from 'features/video-conference/hooks/useConferenceFinish';
import { servicesLabels } from 'features/video-conference/constants';
import { useAnnotationContext } from 'features/video-conference/state/annotationContext';

interface UseConferenceTokensProps {
  isPhysician: boolean;
}

export function useConferenceTokens({ isPhysician }: UseConferenceTokensProps) {
  const { setConferenceId, roomType, onError } = useConference();
  const { handleSetAnnotations } = useAnnotationContext();
  const { handleErrorConferenceFinished } = useConferenceFinish();

  const { searchParams } = new URL(window.location.href);
  const conferenceToken = searchParams.get(servicesLabels.CONFERENCE_TOKEN);

  const getAccessToken = async () => {
    try {
      const { getResponseData, errors } = isPhysician
        ? await fetchRoomStart(roomType, true)
        : await fetchRoomJoin({ validateCode: false });

      if (errors) {
        handleErrorConferenceFinished(errors);
        errors.forEach((error) => onError(error));
        return null;
      }

      const {
        conferenceAccessToken: token,
        conferenceId,
        patientNotes,
        physicianNotes,
      } = getResponseData();

      if (isPhysician) handleSetAnnotations(patientNotes, physicianNotes);

      setConferenceId(conferenceId);
      return token;
    } catch (error) {
      onError(error as any);
      return null;
    }
  };

  return {
    conferenceToken,
    getAccessToken,
  };
}
