import { PromiseResponse } from '@types';

import iClinicApi from 'services/iclinic/iClinicApi';
import {
  FetchTelemedicineReportsParams,
  PhysicianFetchParams,
  PhysiciansResponse,
} from 'features/reports/state/telemedicineReport/types';
import { apiTelemedicine } from '../telemedicineApi';

export const fetchTelemedicineReport = (
  filterProps: FetchTelemedicineReportsParams,
) => apiTelemedicine.get('conference/report', { params: filterProps });

export const fetchClinicPhysicians = ({
  clinicId,
  physicianName,
}: PhysicianFetchParams): PromiseResponse<PhysiciansResponse> =>
  iClinicApi.get('accounts/physician', {
    params: {
      limit: 15,
      profile__clinics: clinicId,
      profile__name__icontains: physicianName,
    },
  });
