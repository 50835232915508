import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActionKeys, DomainTables } from 'features/tissInvoicing/types';

export type DomainTablesState = {
  domainTables: DomainTables;
  loading: {
    fetchDomainTables: boolean;
  };
};

const name = 'domainTables';

export const initialState: DomainTablesState = {
  domainTables: {
    councils: [{ id: 0, term: 'Carregando', code: '', term_short: '' }],
    states: [{ id: 0, term: 'Carregando', code: '', uf: '' }],
    reasons_termination: [{ id: 0, term: 'Carregando', code: '' }],
    service_types: [{ id: 0, term: 'Carregando', code: '' }],
    appointment_types: [{ id: 0, term: 'Carregando', code: '' }],
    degrees_participation: [{ id: 0, term: 'Carregando', code: '' }],
    access_ways: [{ id: 0, term: 'Carregando', code: '' }],
    techniques_used: [{ id: 0, term: 'Carregando', code: '' }],
    service_character: [{ id: 0, term: 'Carregando', code: '' }],
    profession_code: [{ id: 0, term: 'Carregando', code: '' }],
    accident_indication: [{ id: 0, term: 'Carregando', code: '' }],
    special_coverages: [{ id: 0, term: 'Carregando', code: '1' }],
    attendance_regimen: [{ id: 0, term: 'Carregando', code: '' }],
    occupational_health: [{ id: 0, term: 'Carregando', code: '' }],
  },
  loading: {
    fetchDomainTables: true,
  },
};
const fetchDomainTables = createAction(`${name}/fetchDomainTables`);

const domainTablesSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLoadingStatus: (
      state,
      action: PayloadAction<{ key: ActionKeys; value: boolean }>,
    ) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.payload.key]: action.payload.value,
      },
    }),
    resetDomainTables: () => initialState,
    setDomainTables: (state, action: PayloadAction<DomainTables>) => ({
      ...state,
      domainTables: action.payload,
    }),
  },
});

export default domainTablesSlice.reducer;
export const actions = {
  ...domainTablesSlice.actions,
  fetchDomainTables,
};
