import React from 'react';
import ContentLoader from 'react-content-loader';
import { Box, MaterialIcons, tokens, Tooltip } from '@iclinic/design-system';

import * as S from './styles';

const { Info } = MaterialIcons;
const { color, spacing } = tokens;

type OverviewItemProps = {
  isFetching: boolean;
  text: string;
  icon: React.ReactNode;
  tooltipText?: string;
  value?: number | string;
};

const OverviewItem = ({
  text,
  isFetching,
  value = 0,
  tooltipText,
  icon: OverviewItemIcon,
}: OverviewItemProps) => (
  <Box display="flex" alignItems="center" mb={2}>
    <S.IconWrapperLighter>{OverviewItemIcon}</S.IconWrapperLighter>

    <S.Text variant="xxs" mr={spacing.nano}>
      {text}
    </S.Text>

    {tooltipText && (
      <Tooltip title={tooltipText} placement="top">
        <Info color="primary" fontSize="small" />
      </Tooltip>
    )}

    <S.OverviewItemValue variant="xs">
      {!isFetching ? (
        value
      ) : (
        <ContentLoader
          speed={1}
          width={120}
          height={16}
          backgroundColor={color.neutral[50]}
          foregroundColor={color.neutral[25]}
        >
          <rect x="1" y="0" rx="0" ry="2" width={120} height={16} />
        </ContentLoader>
      )}
    </S.OverviewItemValue>
  </Box>
);

export default OverviewItem;
