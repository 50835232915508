import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';

export interface DeleteMultiplePhysicianState {
  key?: string;
  userIds?: string[];
  status?: RequestStatus;
  errorMessage?: string;
}

export const initialState: DeleteMultiplePhysicianState = {};

export const nameStore = '@webapp/delete-multiple-physician';

export const deleteMultiplePhysicians = createAction(
  `${nameStore}/deleteMultiplePhysicians`,
);

const DeleteMultiplePhysicianSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    triggerDeleteMultiplePhysician: (
      state,
      action: PayloadAction<{ key: string; selectedPhysicians: string[] }>,
    ) => ({
      ...state,
      key: action.payload.key,
      userIds: action.payload.selectedPhysicians,
    }),
    cleanDeleteMultiplePhysician: () => ({
      ...initialState,
    }),
    deleteMultiplePhysiciansStart: (state) => ({
      ...state,
      errorMessage: undefined,
      status: RequestStatus.Pending,
    }),
    deleteMultiplePhysiciansSuccess: (state) => ({
      ...state,
      status: RequestStatus.Success,
    }),
    deleteMultiplePhysiciansFailure: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      status: RequestStatus.Error,
      errorMessage: action.payload,
    }),
    clearErrorMessage: (state) => ({
      ...state,
      errorMessage: undefined,
    }),
  },
});

export const {
  cleanDeleteMultiplePhysician,
  clearErrorMessage,
  deleteMultiplePhysiciansFailure,
  deleteMultiplePhysiciansStart,
  deleteMultiplePhysiciansSuccess,
  triggerDeleteMultiplePhysician,
} = DeleteMultiplePhysicianSlice.actions;

export default DeleteMultiplePhysicianSlice.reducer;
