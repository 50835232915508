import appTypes from 'shared/utils/actions';
import { SUCCESS, FAILURE } from 'shared/constants/actions';
import { ApiErrors } from '@types';

const errors: ApiErrors[] = [];

export const initialState = {
  modalVisibility: false,
  errors,
};

const emailFlow = appTypes.defineAction('emailFlow/root');

export const TOGGLE_MODAL = emailFlow.defineAction('TOGGLE_MODAL');
export const NOTIFY_INTENT = emailFlow.defineAction('NOTIFY_INTENT', [SUCCESS, FAILURE]);
