import React from 'react';

import { CheckBox } from 'ui/new/form';
import Table from 'ui/new/table';

const { Head, Row, Item } = Table;

type TableHeadProps = {
  tableStyles: {
    [className: string]: string;
  };
  onSelectAllProducts(event: React.ChangeEvent<HTMLInputElement>): void;
  totalSelected: number | null;
  selectedList: number[];
};

export default function TableHead({
  tableStyles: styles,
  onSelectAllProducts,
  totalSelected,
  selectedList,
}: TableHeadProps): JSX.Element {
  return (
    <Head>
      <Row>
        <Item classes={styles.check}>
          <CheckBox
            name="cb-table-header"
            id="selectAllProducts"
            value="1"
            onChange={onSelectAllProducts}
            selected={totalSelected === selectedList.length}
          />
        </Item>
        <Item classes={styles.name}>Produto</Item>
        <Item classes={styles.cod}>Cód.</Item>
        <Item classes={styles.qtd}>Quant.</Item>
        <Item classes={styles.selflife}>Vence em 30 dias</Item>
        <Item classes={styles.expired}>Vencido</Item>
        <Item classes={styles.minimum}>Estoque Mínimo</Item>
      </Row>
    </Head>
  );
}
