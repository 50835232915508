// External
import React from 'react';

// Internal
import { AppContent } from 'features/layout';
import Subscription from 'features/subscription/containers/Subscription';

export default () => (
  <AppContent hideSidebar hideFooter>
    <Subscription />
  </AppContent>
);
