import {
  Heading,
  MaterialIcons,
  MaterialUICore,
  theme,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing, color, shadow } = tokens;
const { ReportProblemOutlined } = MaterialIcons;

export const Card = styled('div')(() => ({
  gridArea: 'card',
  display: 'flex',
  flexDirection: 'column',
  padding: spacing.sm,
  height: '100%',
  border: `1px solid ${color.neutral[50]}`,
  borderRadius: 4,
  boxShadow: shadow.level[1],
  backgroundColor: color.neutral[0],

  [theme.breakpoints.up('sm')]: {
    padding: spacing.lg,
  },

  [theme.breakpoints.only('sm')]: {
    maxWidth: 320,
  },

  '& > img': {
    alignSelf: 'center',
    marginBottom: spacing.md,
  },
}));

export const PharmaTitle = styled(Heading)({
  alignSelf: 'center',
  marginBottom: spacing.md,
});

export const MedicamentsList = styled('ul')({
  paddingLeft: spacing.lg,
  marginBottom: spacing.md,
});

export const OptInValidityWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: spacing.md,

  '& > .MuiSvgIcon-root': {
    marginRight: spacing.sm,
    color: color.neutral[500],
  },
}));

export const StyledReportProblemOutlined = styled(ReportProblemOutlined)({
  color: `${color.feedback.warning.dark} !important`,
});
