export function calculateTotalValueProcedure(
  unitValue: number,
  quantity: number,
  factor: number,
) {
  return new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format((unitValue * quantity * factor) / 100);
}

export function getProcedureTotalValue(
  reduction_factor: string,
  quantity: number,
  unit_price: string,
  price?: string,
) {
  if (reduction_factor.length !== 4) {
    return null;
  }

  if (quantity < 1) {
    return null;
  }

  const unitValue = price
    ? Number(price.replace('.', ''))
    : Number(unit_price?.replace(',', ''));

  const factor = Number(reduction_factor?.replace(',', '.'));

  if (unitValue === 0) {
    return '0,00';
  }

  return calculateTotalValueProcedure(unitValue, quantity, factor);
}
