// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import style from './Summary.scss';

export default function Summary({ children }) {
  return (
    <div className={style.summary}>
      {children}
    </div>
  );
}

Summary.propTypes = {
  children: PropTypes.node.isRequired,
};
