import { LocalAudioTrack } from 'twilio-video';
import { useCallback } from 'react';

import useIsTrackEnabled from 'features/video-conference/hooks/useIsTrackEnabled/index';
import { useConference } from 'features/video-conference/state/conferenceContext';

export default function useLocalAudioToggle() {
  const { localTracks } = useConference();
  const audioTrack = localTracks.find(
    (track) => track.kind === 'audio',
  ) as LocalAudioTrack;
  const isAudioEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      return audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    }

    return () => {};
  }, [audioTrack]);

  return { isAudioEnabled, toggleAudioEnabled };
}
