import { Body, Heading, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing, font, line } = tokens;

export const Section = styled('section')({
  backgroundColor: color.secondary.main,
});

export const BannerDescriptions = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.xs,
  justifyContent: 'center',

  [theme.breakpoints.up('md')]: {
    paddingBottom: spacing.lg,
  },
}));

export const BannerDescriptionTitle = styled(Heading)({
  color: color.primary.main,
});

export const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  columnGap: spacing.xxxl,
  padding: `${spacing.md} ${spacing.sm}`,

  [theme.breakpoints.up('md')]: {
    maxWidth: 960,
    margin: '0 auto',
    gridTemplateColumns: 'repeat(2, 1fr)',
    padding: `${spacing.xxl} ${spacing.md}`,
  },
}));

export const BannerTitle = styled(Heading)({
  color: color.neutral[700],
  maxWidth: 400,
});

export const DescriptionText = styled(Body)({
  fontSize: font.size.xs,
  lineHeight: line.height.md,
});

export const LoaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const BannerDescriptionContent = styled('div')({
  marginTop: spacing.xs,
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.xs,
  justifyContent: 'center',
});
