import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  title: {
    padding: '25px 24px 5px',
    color: theme.palette.grey[900],
  },
  actions: {
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: '12px 24px',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between',
    },
  },
  actionConfirm: {
    margin: '4px 0',
    [theme.breakpoints.up('md')]: {
      order: 2,
    },
  },
  actionCancel: {
    marginTop: '8px',
  },
  itemAction: {
    [theme.breakpoints.up('md')]: {
      margin: '0',
    },
  },
}));
