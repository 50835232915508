import React from 'react';
import { withRouter } from 'react-router-dom';

/**
 * Wraps a custom link component with 'withRouter'
 * Use active prop to mark link as visited
 */
export const routerLink = (Component) => (
  withRouter(({
    math,
    history,
    location,
    to,
    exact,
    active,
    ...otherProps
  }) => (
    <Component
      href={to}
      onClick={(event) => {
        event.preventDefault();
        history.push(to);
      }}
      active={exact ? location.pathname === to : location.pathname.includes(to)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...otherProps}
    />
  ))
);

export default routerLink;
