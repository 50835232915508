import { appointmentSectionSchema } from './appointmentSection/validateSchema';
import { generalSectionSchema } from './generalSection/validationSchema';
import { headerSectionSchema } from './headerSection/validateSchema';
import { hiredSectionSchema } from './hiredSection/validateSchema';
import { patientSectionSchema } from './patientSection/validateSchema';

export const validationAppointmentGuideData = (version_id: string) =>
  headerSectionSchema
    .concat(generalSectionSchema)
    .concat(patientSectionSchema)
    .concat(hiredSectionSchema)
    .concat(appointmentSectionSchema(version_id));

export const formKeys = {
  headerSectionData: Object.keys(headerSectionSchema.fields),
  generalSectionData: Object.keys(generalSectionSchema.fields),
  patientSectionData: Object.keys(patientSectionSchema.fields),
  hiredSectionData: Object.keys(hiredSectionSchema.fields),
  appointmentSectionData: Object.keys(appointmentSectionSchema().fields),
};

export enum FormKeysEnum {
  headerSectionData = 1,
  generalSectionData = 2,
  patientSectionData = 3,
  hiredSectionData = 4,
  appointmentSectionData = 5,
}
