import MODAL from './types';

export const open = (type, props) => ({
  type: MODAL.OPEN,
  modalType: type,
  modalProps: props,
});

export const close = () => ({
  type: MODAL.CLOSE,
});
