import {
  Dialog,
  DialogActions,
  DialogHeader,
  MaterialUICore,
  axiomTokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const DialogHeaderContent = styled(DialogHeader)({
  color: axiomTokens.color.typeface.smooth,
  fontSize: axiomTokens.font.size.sm,
});

export const DialogContainer = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    rowGap: axiomTokens.spacing[5],
  },

  '& .MuiPaper-root': {
    width: '640px',
    minHeight: '208px',

    [theme.breakpoints.down(768)]: {
      minWidth: '320px',
      minHeight: '300px',
    },
  },
}));

export const DialogActionsContainer = styled(DialogActions)(({ theme }) => ({
  [theme.breakpoints.down(768)]: {
    flexDirection: 'column',

    '&.MuiDialogActions-root': {
      alignItems: 'stretch',
    },
  },
}));

export const ButtonContainer = styled('div')(({ theme }) => ({
  '& .MuiButtonBase-root': {
    [theme.breakpoints.down(768)]: {
      flex: 1,
      width: '100%',
    },
  },
}));

export const ButtonContainerConfirm = styled(ButtonContainer)(({ theme }) => ({
  [theme.breakpoints.down(768)]: {
    order: -1,
  },
  '& .MuiButtonBase-root': {
    [theme.breakpoints.down(768)]: {},
  },
}));
