import { weekDays } from 'shared/constants/weekDays';

export const getDateMiddleHour = (start: string, end: string) => ({
  startTime: start.split(':').map((time) => +time),
  endTime: end.split(':').map((time) => +time),
});

export const convertTime = (hour: number[]): number => {
  return parseFloat(hour.join('.'));
};

export const calcIntervalTime = (
  startTime: number[],
  endTime: number[],
  interval: number,
  range: string[],
) => {
  const start = startTime;
  const end = endTime;

  const hour = start[0] < 10 ? `0${start[0]}` : start[0];
  const minutes = start[1] < 10 ? `0${start[1]}` : start[1];

  range.push(`${hour}:${minutes}`);

  start[1] += interval;
  if (start[1] > 59) {
    start[0] += 1;
    start[1] %= 60;
  }
  const startTimeValue = convertTime(start);
  const endTimeValue = convertTime(end);

  if (startTimeValue <= endTimeValue) {
    calcIntervalTime(start, end, interval, range);
  }
  return range;
};

export const timeRange = (start: string, end: string, interval: number) => {
  const { startTime, endTime } = getDateMiddleHour(start, end);
  const range = calcIntervalTime(startTime, endTime, interval, []);
  return range.map((_, i) => ({ id: range[i], name: range[i] }));
};

export const translateWeekDay = (
  weekday,
  defaultLang = 'en',
  defaultTranslateLang = 'pt',
) => {
  if (weekday && typeof weekday === 'string') {
    const day = weekDays.find((w) => w[defaultLang] === weekday.toUpperCase());
    return day[defaultTranslateLang];
  }
  return false;
};
