import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import DROPDOWN_TYPE from './constants';
import style from './style.scss';

const classes = classNames.bind(style);

const Item = ({ id, selected, kind, children, onClick }) => (
  <div
    className={classes('dropdown-item', {
      selected,
      add: kind === DROPDOWN_TYPE.Add,
      exists: kind === DROPDOWN_TYPE.Exists,
      disabled: kind === DROPDOWN_TYPE.Disabled,
    })}
    onClick={onClick}
    onKeyDown={onClick}
    role="button"
    tabIndex={0}
    id={id}
  >
    {children}
  </div>
);

Item.defaultProps = {
  selected: false,
  kind: DROPDOWN_TYPE.Exists,
  onClick: () => null,
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  kind: PropTypes.oneOf(Object.values(DROPDOWN_TYPE)),
  children: PropTypes.node.isRequired,
};

export default Item;
