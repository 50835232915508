import React from 'react';
import ContentLoader from 'react-content-loader';

const values = {
  backgroundColor: '#ECEFF1',
  foregroundColor: '#F8F9FA',
  speed: 1,
};

export const AmountChargedInputContentLoader = () => (
  <ContentLoader
    speed={values.speed}
    width={140}
    height={60}
    viewBox="0 0 140 60"
    backgroundColor={values.backgroundColor}
    foregroundColor={values.foregroundColor}
    data-testid="amount-charged-input-content-loader"
    uniqueKey="amount-charged-input"
  >
    <rect x="3" y="2" rx="2" ry="2" width="132" height="56" />
  </ContentLoader>
);

export const ActionContentLoader = () => (
  <ContentLoader
    speed={values.speed}
    width={140}
    height={39}
    viewBox="0 0 130 39"
    backgroundColor={values.backgroundColor}
    foregroundColor={values.foregroundColor}
    data-testid="action-content-loader"
    uniqueKey="action"
  >
    <rect x="7" y="2" rx="4" ry="4" width="128" height="32" />
  </ContentLoader>
);
