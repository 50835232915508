import { tokens, MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export const useStyles = makeStyles(() => ({
  primaryButton: {
    padding: tokens.spacing.sm,
    outline: 'none',
    textDecoration: 'none',
    '&:hover, &:focus': {
      color: tokens.color.neutral[0],
      outline: 'none',
      textDecoration: 'none',
    },
  },
  cancelButton: {
    padding: tokens.spacing.sm,
    color: tokens.color.neutral[700],
  },
  dialogContent: {
    padding: `${tokens.spacing.sm} ${tokens.spacing.md}`,
  },
  dialogActions: {
    padding: `${tokens.spacing.sm}`,
    width: '100%',
    justifyContent: 'space-between',
  },
}));
