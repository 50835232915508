import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  Button,
  Dialog,
  DialogHeader,
  DialogActions,
  DialogContent,
} from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';
import { gotoAppRoute } from 'shared/utils/appRoutes';
import {
  updateCurrentSubscriptionStep,
  updateSubscriptionStepMapper,
} from '../../state/plans';
import {
  getCurrentSubscriptionStep,
  getSuccessUrl,
  getUpdatePermissionUrl,
} from '../../state/plans/selectors';
import { StepTypes } from '../../state/plans/types';
import * as S from './ModalSuccess.styles';

const ModalSuccess = () => {
  const dispatch = useDispatch();
  const updatePermissionUrl = useSelector(getUpdatePermissionUrl);
  const successUrl = useSelector(getSuccessUrl);
  const currentStep = useSelector(getCurrentSubscriptionStep);
  const isOpen = currentStep === StepTypes.SUCCESS;

  const handleCloseModal = () => {
    dispatch(updateSubscriptionStepMapper());
    dispatch(updateCurrentSubscriptionStep());
    gotoAppRoute(`${updatePermissionUrl}${successUrl}`);
  };

  const handleGotoReferrals = () => {
    gotoAppRoute(`${updatePermissionUrl}/programa-de-indicacoes`);
  };

  return (
    <Dialog open={isOpen} disableEscapeKeyDown>
      <DialogHeader
        title="Parabéns, você acaba de garantir mais facilidade na sua rotina com iClinic!"
        onClose={handleCloseModal}
      />

      <DialogContent>
        <S.ImageWrapper>
          <img
            width={350}
            src={image('referrals.wantToIndicate')}
            alt="Venha ganhar mais"
          />
        </S.ImageWrapper>

        <Body variant="xs">
          Participe do nosso programa de indicações e tenha ainda mais
          benefícios! Sua nova indicação garante vantagens especiais, e você
          ganha descontos ou vale-prêmios.
        </Body>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          color="transparent"
          onClick={handleCloseModal}
        >
          Quero começar a usar o iClinic
        </Button>

        <Button
          data-ga="referrals_subscription_success_modal_click"
          onClick={handleGotoReferrals}
        >
          Quero ganhar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalSuccess;
