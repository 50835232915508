import React from 'react';

import {
  MaterialUICore,
  Body,
  InputAdornment,
  MaterialIcons,
  Select,
} from '@iclinic/design-system';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { selectDevices } from 'features/video-conference/constants';
import useDevices from 'features/video-conference/hooks/useDevices';
import useStyles from '../styles';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';
import { useParticipantContext } from 'features/video-conference/state/participantContext';

const { MenuItem } = MaterialUICore;
const { VolumeUp } = MaterialIcons;

interface AudioOutputListProps {
  location?: string;
}

export default function AudioOutputList({ location }: AudioOutputListProps) {
  const classes = useStyles({});
  const { audioOutputDevices } = useDevices();
  const {
    activeSinkId,
    setActiveSinkId,
    isPhysician,
    conferenceId,
  } = useConference();
  const { localParticipant, remoteParticipant } = useParticipantContext();

  const dispatchEventTracking = (value: string) => {
    setActiveSinkId(value);
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'telemedicine_settings_update_device',
      buttonLocation: location,
      additionalProperties: 'audio_output',
    });
  };

  return (
    <div>
      {audioOutputDevices.length > 0 ? (
        <Select
          onChange={(e) => dispatchEventTracking(e.target.value as string)}
          value={activeSinkId}
          data-testid="select"
          InputProps={{
            id: 'audio-input-list',
            startAdornment: (
              <InputAdornment position="start">
                <VolumeUp className={classes.iconColor} />
              </InputAdornment>
            ),
          }}
          label={selectDevices.AUDIO_OUTPUT}
        >
          {audioOutputDevices.map((device) => (
            <MenuItem value={device.deviceId} key={device.deviceId}>
              {device.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Body variant="xs" bold>
          {selectDevices.OUTPUT_DEFAULT}
        </Body>
      )}
    </div>
  );
}
