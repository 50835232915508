import { call, put, select, takeLatest } from 'redux-saga/effects';
import { AxiosError } from 'axios';

import { Response } from '@types';
import history from 'routes/history';
import * as actions from '.';
import { UserInfo } from './types';
import { getUserInfo, getZipCodeAvailability } from './selectors';
import { normalizeRequestSamples } from './normalizer';
import * as services from '../../services';
import { getErrorCode } from '../../utils';
import {
  MESSAGES_BY_CODE,
  SUCCESS,
  SUCCESS_WAITING_AVAILABILITY,
  SUCCESS_WAITING_LOGISTICS,
  UNKNOWN_ERROR,
} from '../../constants';
import {
  RequestSamplesResponse,
  SampleBoxesResponse,
  AddressData,
  PersonVUCData,
} from '../../services/types';
import { updateResultMessage } from '../sample-boxes';
import { trackRequestSamplesFormStep } from '../../trackRequestSamplesFormUtils';
import { fetchInfosZipCode } from 'services/iclinic/easyZipCode';
import { ZipCodeRawData } from 'services/iclinic/easyZipCode/types';
import { onlyNumbers } from 'shared/utils/formatters';

type FetchSampleBoxPayload = ReturnType<typeof actions.fetchSampleBox>;
type SubmitRequestSamplesPayload = ReturnType<
  typeof actions.triggerSubmitRequestSamples
>;
type FetchZipCodePayload = ReturnType<typeof actions.fetchZipCode>;
type FetchValidateAvailabilityByZipCodePayload = ReturnType<
  typeof actions.fetchValidateAvailabilityByZipCode
>;
type FetchAddToRequestWaitingQueuePayload = ReturnType<
  typeof actions.fetchAddToRequestWaitingQueue
>;
type FetchPersonVUCDataPayload = ReturnType<typeof actions.fetchPersonVUCData>;

const pathFreeSamples = '/amostras-gratis/';

export function* fetchSampleBoxWorker({ payload }: FetchSampleBoxPayload) {
  try {
    const addressData: Response<AddressData> = yield call(
      services.validateCanRequestFreeSamples,
      payload,
    );

    const { data }: Response<SampleBoxesResponse> = yield call(
      services.getSampleBox,
      payload,
    );

    const [sampleBox] = data.objects;
    yield put(
      actions.fetchSampleBoxSuccess({ sampleBox, address: addressData.data }),
    );
  } catch (error) {
    const errorCode = getErrorCode(error as AxiosError);
    const message = MESSAGES_BY_CODE[errorCode] || UNKNOWN_ERROR;

    yield put(actions.fetchSampleBoxFailure());
    yield put(updateResultMessage(message));
    history.push(pathFreeSamples);
  }
}

export function* submitRequestSamplesWorker({
  payload,
}: SubmitRequestSamplesPayload) {
  const userInfo: UserInfo = yield select(getUserInfo);
  const availableByZipCode: boolean = yield select(getZipCodeAvailability);

  try {
    yield put(actions.submitRequestSamples());

    const normalizedRequestSamplesData = normalizeRequestSamples(payload);

    const { data }: Response<RequestSamplesResponse> = yield call(
      services.submitRequestFreeSamples,
      normalizedRequestSamplesData,
    );

    trackRequestSamplesFormStep({
      userInfo,
      stepName: 'success',
      stepNum: 17,
    });

    yield put(actions.submitRequestSamplesSuccess(data));

    if (!data.can_join_queue) {
      const message = availableByZipCode ? SUCCESS : SUCCESS_WAITING_LOGISTICS;
      yield put(updateResultMessage(message));
      yield put(actions.cleanRequestSamples());
      history.push(pathFreeSamples);
    }
  } catch (error) {
    trackRequestSamplesFormStep({
      userInfo,
      stepName: 'error',
      stepNum: 17,
    });

    const errorCode = getErrorCode(error as AxiosError);
    const message = MESSAGES_BY_CODE[errorCode] || UNKNOWN_ERROR;

    yield put(actions.submitRequestSamplesFailure());
    yield put(updateResultMessage(message));
    yield put(actions.cleanRequestSamples());
    history.push(pathFreeSamples);
  }
}

export function* fetchZipCodeWorker({ payload: zipCode }: FetchZipCodePayload) {
  try {
    const normalizeCep = onlyNumbers(zipCode);
    const { data }: Response<ZipCodeRawData> = yield call(
      fetchInfosZipCode,
      normalizeCep,
    );
    yield put(actions.fetchZipCodeSuccess(data));
  } catch (error) {
    yield put(actions.fetchZipCodeFailure());
  }
}

export function* fetchValidateAvailabilityByZipCodeWorker({
  payload: { zipCode, boxId },
}: FetchValidateAvailabilityByZipCodePayload) {
  try {
    const normalizeZipCode = onlyNumbers(zipCode);
    yield call(services.validateAvailabilityByZipCode, {
      zipCode: normalizeZipCode,
      boxId,
    });

    yield put(actions.fetchValidateAvailabilityByZipCodeResult(true));
  } catch (error) {
    yield put(actions.fetchValidateAvailabilityByZipCodeResult(false));
  }
}

export function* fetchAddToRequestWaitingQueueWorker({
  payload: requestId,
}: FetchAddToRequestWaitingQueuePayload) {
  try {
    yield call(services.getAddToRequestWaitingQueue, requestId);
  } finally {
    yield put(actions.cleanRequestSamples());
    yield put(updateResultMessage(SUCCESS_WAITING_AVAILABILITY));
    history.push(pathFreeSamples);
  }
}

export function* fetchPersonVUCDataWorker({
  payload,
}: FetchPersonVUCDataPayload) {
  try {
    const { data }: Response<PersonVUCData> = yield call(
      services.getPersonVUCData,
      payload,
    );

    yield put(actions.fetchPersonVUCDataSuccess(data));
  } catch (error) {
    yield put(actions.fetchPersonVUCDataFailure());
  }
}

export default function* requestFreeSamplesSagas() {
  yield takeLatest(actions.fetchSampleBox, fetchSampleBoxWorker);
  yield takeLatest(
    actions.triggerSubmitRequestSamples,
    submitRequestSamplesWorker,
  );
  yield takeLatest(actions.fetchZipCode, fetchZipCodeWorker);
  yield takeLatest(
    actions.fetchValidateAvailabilityByZipCode,
    fetchValidateAvailabilityByZipCodeWorker,
  );
  yield takeLatest(
    actions.fetchAddToRequestWaitingQueue,
    fetchAddToRequestWaitingQueueWorker,
  );
  yield takeLatest(actions.fetchPersonVUCData, fetchPersonVUCDataWorker);
}
