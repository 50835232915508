// Internal
import { STEPS, CREATE_STEPS_CONTROL } from 'shared/constants/Billing';
import { ActionCreator, ApiErrors, ActionPayload, Action } from '@types';
import {
  SubscriptionPartialState,
  SubscriptionState,
  ProductSuccess,
  ListPhysicians,
  ProductSelected,
  Products,
} from './types';
import { normalizeCheck } from './utils';
import * as types from './constants';

export type SubscriptionAction = ActionCreator<
  typeof types,
  SubscriptionPartialState
>;

export type SetStep = ActionPayload<
  'step' | 'direction',
  typeof types.SET_STEP,
  SubscriptionState
>;
export const setStep = (step: number, direction: string | null): SetStep => ({
  type: types.SET_STEP.ACTION,
  payload: {
    step,
    direction,
  },
});

export type OpenModal = ActionPayload<
  'isFetching',
  typeof types.OPEN_MODAL,
  SubscriptionState
>;
export const openModal = (): OpenModal => ({
  type: types.OPEN_MODAL.ACTION,
  payload: {
    isFetching: false,
  },
});

export type CloseModal = ActionPayload<
  'isFetching',
  typeof types.CLOSE_MODAL,
  SubscriptionState
>;
export const closeModal = (): CloseModal => ({
  type: types.CLOSE_MODAL.ACTION,
  payload: {
    isFetching: false,
  },
});

export type CloseModalSuccess = ActionPayload<
  'isOpenModal' | 'track',
  typeof types.CLOSE_MODAL.SUCCESS,
  SubscriptionState
>;
export const closeModalSuccess = (): CloseModalSuccess => ({
  type: types.CLOSE_MODAL.SUCCESS,
  payload: {
    isOpenModal: false,
  },
  track: {
    action: 'close',
    category: 'subscription',
  },
});

export type OpenModalSuccess = ActionPayload<
  'isFetching' | 'isOpenModal' | 'track',
  typeof types.OPEN_MODAL.SUCCESS,
  SubscriptionState
>;
export const openModalSuccess = (): OpenModalSuccess => ({
  type: types.OPEN_MODAL.SUCCESS,
  payload: {
    isOpenModal: true,
    isFetching: false,
  },
  track: {
    action: 'open',
    category: 'subscription',
  },
});

export type ListPhysicians = ActionPayload<
  'isFetching',
  typeof types.LIST_PHYSICIANS.ACTION,
  SubscriptionState
>;
export const listPhysicians = (): ListPhysicians => ({
  type: types.LIST_PHYSICIANS.ACTION,
  payload: {
    isFetching: true,
  },
});

export type ListPhysiciansSuccess = ActionPayload<
  'isFetching' | 'physicians',
  typeof types.LIST_PHYSICIANS.SUCCESS,
  SubscriptionState
>;
export const listPhysiciansSuccess = (
  physicians: ListPhysicians[],
): ListPhysiciansSuccess => ({
  type: types.LIST_PHYSICIANS.SUCCESS,
  payload: {
    isFetching: false,
    physicians,
  },
});

export type GetContract = ActionPayload<
  'isMonthly',
  typeof types.FETCH_CONTRACTS.ACTION,
  SubscriptionState
>;
export const getContract = (isMonthly: boolean): GetContract => ({
  type: types.FETCH_CONTRACTS.ACTION,
  payload: {
    isMonthly,
  },
});

export type SetSubscriptionProduct = ActionPayload<
  'product' | 'productName',
  typeof types.SET_SUBSCRIPTION.ACTION,
  SubscriptionState
>;
export const setSubscriptionProduct = (
  product: ProductSelected[],
  productName: ProductSelected[],
): SetSubscriptionProduct => ({
  type: types.SET_SUBSCRIPTION.ACTION,
  payload: {
    product,
    productName,
  },
});

export type SelectProductSuccess = ActionPayload<
  'product' | 'productName',
  typeof types.SET_SUBSCRIPTION.SUCCESS,
  SubscriptionState
>;
export const selectProductSuccess = (
  product: ProductSelected[],
  productName: ProductSelected[],
): SelectProductSuccess => ({
  type: types.SET_SUBSCRIPTION.SUCCESS,
  payload: {
    product,
    productName,
  },
});

export type SelectProductFailure = ActionPayload<
  'errors',
  typeof types.SET_SUBSCRIPTION.FAILURE,
  SubscriptionState
>;
export const selectProductFailure = (
  errors: ApiErrors[],
): SelectProductFailure => ({
  type: types.SET_SUBSCRIPTION.FAILURE,
  payload: {
    errors,
  },
});

export type FetchSubscription = ActionPayload<
  'isFetching',
  typeof types.FETCH_SUBSCRIPTION.ACTION,
  SubscriptionState
>;
export const fetchSubscription = (): FetchSubscription => ({
  type: types.FETCH_SUBSCRIPTION.ACTION,
  payload: {
    isFetching: true,
  },
});

export type FetchSubscriptionSuccess = ActionPayload<
  'isFetching' | 'products',
  typeof types.FETCH_SUBSCRIPTION.SUCCESS,
  SubscriptionState
>;
export const fetchSubscriptionSuccess = (
  product: ProductSuccess,
): FetchSubscriptionSuccess => ({
  type: types.FETCH_SUBSCRIPTION.SUCCESS,
  payload: {
    isFetching: false,
    products: product,
  },
});

export type FetchSubscriptionFailure = ActionPayload<
  'isFetching' | 'errors',
  typeof types.FETCH_SUBSCRIPTION.FAILURE,
  SubscriptionState
>;
export const fetchSubscriptionFailure = (
  errors: ApiErrors[],
): FetchSubscriptionFailure => ({
  type: types.FETCH_SUBSCRIPTION.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export type SelectPhysician = ActionPayload<
  'physicianId',
  typeof types.SELECT_PHYSICIAN.ACTION,
  SubscriptionState
>;
export const selectPhysician = (physicianId: number): SelectPhysician => ({
  type: types.SELECT_PHYSICIAN.ACTION,
  payload: {
    physicianId,
  },
});

export type SelectPhysicianSuccess = ActionPayload<
  'physicians' | 'currentAddon' | 'selectedPhysiciansIds',
  typeof types.SELECT_PHYSICIAN.SUCCESS,
  SubscriptionState
>;
export const selectPhysicianSuccess = (
  physicians: ListPhysicians[],
  currentAddon: string,
): SelectPhysicianSuccess => ({
  type: types.SELECT_PHYSICIAN.SUCCESS,
  payload: {
    physicians,
    currentAddon,
    selectedPhysiciansIds: normalizeCheck(physicians, 'profile_id', false),
  },
});

export type SelectPhysicianFailure = ActionPayload<
  'errors',
  typeof types.SELECT_PHYSICIAN.FAILURE,
  SubscriptionState
>;
export const selectPhysicianFailure = (
  errors: ApiErrors[],
): SelectPhysicianFailure => ({
  type: types.SELECT_PHYSICIAN.FAILURE,
  payload: {
    errors,
  },
});

export type VerifySelectedAddons = ActionPayload<
  'isFetching',
  typeof types.VERIFY_SELECTED_ADDONS.ACTION,
  SubscriptionState
>;
export const verifySelectedAddons = (): VerifySelectedAddons => ({
  type: types.VERIFY_SELECTED_ADDONS.ACTION,
  payload: {
    isFetching: false,
  },
});

export type SetSelectedAddons = ActionPayload<
  'selectedAddons' | 'selectedBasePlan' | 'selectedExtra',
  typeof types.SET_SELECTED_ADDONS.ACTION,
  SubscriptionState
>;
export const setSelectedAddons = (
  selectedAddons: string[],
  selectedBasePlan: string[],
  selectedExtra: string[],
): SetSelectedAddons => ({
  type: types.SET_SELECTED_ADDONS.ACTION,
  payload: {
    selectedAddons,
    selectedBasePlan,
    selectedExtra,
  },
});

export type SetCurrentAddon = ActionPayload<
  'currentAddon',
  typeof types.SET_CURRENT_ADDON.ACTION,
  SubscriptionState
>;
export const setCurrentAddon = (currentAddon: string): SetCurrentAddon => ({
  type: types.SET_CURRENT_ADDON.ACTION,
  payload: {
    currentAddon,
  },
});

export type VerificationNextStep = ActionPayload<
  'isFetching',
  typeof types.VERIFICATION_NEXT_STEP.ACTION,
  SubscriptionState
>;
export const verificationNextStep = (): VerificationNextStep => ({
  type: types.VERIFICATION_NEXT_STEP.ACTION,
  payload: {
    isFetching: false,
  },
});

export type VerificationBackStep = ActionPayload<
  'isFetching',
  typeof types.VERIFICATION_BACK_STEP.ACTION,
  SubscriptionState
>;
export const verificationBackStep = (): VerificationBackStep => ({
  type: types.VERIFICATION_BACK_STEP.ACTION,
  payload: {
    isFetching: false,
  },
});

export type ClearErrorsSubscription = ActionPayload<
  'errors',
  typeof types.CLEAR_ERRORS_SUBSCRIPTIONS.ACTION,
  SubscriptionState
>;
export const clearErrorsSubscription = (): ClearErrorsSubscription => ({
  type: types.CLEAR_ERRORS_SUBSCRIPTIONS.ACTION,
  payload: {
    errors: [],
  },
});

export type UpdateCredictCard = ActionPayload<
  'name' | 'value',
  typeof types.UPDATE_CREDIT_CARD.ACTION,
  SubscriptionState
>;
export const updateCredictCard = (
  name: string,
  value: string,
): UpdateCredictCard => ({
  type: types.UPDATE_CREDIT_CARD.ACTION,
  payload: {
    name,
    value,
  },
});

export type FlipCard = ActionPayload<
  'isFlipped',
  typeof types.FLIP_CARD.ACTION,
  SubscriptionState
>;
export const flipCard = (isFlipped: boolean): FlipCard => ({
  type: types.FLIP_CARD.ACTION,
  payload: {
    isFlipped,
  },
});

export type FetchCheckout = ActionPayload<
  'isFetching' | 'track',
  typeof types.FETCH_CHECKOUT.ACTION,
  SubscriptionState
>;
export const fetchCheckout = (): FetchCheckout => ({
  type: types.FETCH_CHECKOUT.ACTION,
  payload: {
    isFetching: true,
  },
  track: {
    action: 'submit',
    category: 'subscription',
  },
});

export type FetchCheckoutFailure = ActionPayload<
  'errors' | 'isFetching' | 'track',
  typeof types.FETCH_CHECKOUT.FAILURE,
  SubscriptionState
>;
export const fetchCheckoutFailure = (
  errors: ApiErrors[],
): FetchCheckoutFailure => ({
  type: types.FETCH_CHECKOUT.FAILURE,
  payload: {
    errors,
    isFetching: false,
  },
  track: {
    action: 'failure',
    category: 'subscription',
  },
});

export type FetchCheckoutSuccess = ActionPayload<
  'step' | 'isFetching' | 'track',
  typeof types.FETCH_CHECKOUT.SUCCESS,
  SubscriptionState
>;
export const fetchCheckoutSuccess = (): FetchCheckoutSuccess => ({
  type: types.FETCH_CHECKOUT.SUCCESS,
  payload: {
    step: STEPS.success.value,
    isFetching: false,
  },
  track: {
    action: 'success',
    category: 'subscription',
  },
});

export type GetSummary = ActionPayload<
  'errors' | 'isFetching',
  typeof types.GET_SUMMARY.ACTION,
  SubscriptionState
>;
export const getSummary = (): GetSummary => ({
  type: types.GET_SUMMARY.ACTION,
  payload: {
    isFetching: true,
    errors: [],
  },
});

export type GetSummarySuccess = ActionPayload<
  'summary' | 'isFetching' | 'errors',
  typeof types.GET_SUMMARY.SUCCESS,
  SubscriptionState
>;
export const getSummarySuccess = (summary: any): GetSummarySuccess => ({
  type: types.GET_SUMMARY.SUCCESS,
  payload: {
    summary,
    isFetching: false,
    errors: [],
  },
});

export type GetSummaryFailure = ActionPayload<
  'isFetching' | 'errors',
  typeof types.GET_SUMMARY.FAILURE,
  SubscriptionState
>;
export const getSummaryFailure = (errors: ApiErrors[]): GetSummaryFailure => ({
  type: types.GET_SUMMARY.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export type UnionProductToUser = ActionPayload<
  'product' | 'isFetching' | 'errors' | 'selectedPhysiciansIds',
  typeof types.UNION_PRODUCT_TO_USER.ACTION,
  SubscriptionState
>;
export const unionProductToUser = (
  physicians: ListPhysicians[],
  product: ProductSelected[],
  all: boolean,
): UnionProductToUser => ({
  type: types.UNION_PRODUCT_TO_USER.ACTION,
  payload: {
    product,
    selectedPhysiciansIds: normalizeCheck(physicians, 'profile_id', all),
    isFetching: false,
    errors: [],
  },
});

export type UnionProductToSingleUser = ActionPayload<
  'products' | 'isFetching' | 'errors',
  typeof types.UNION_PRODUCT_TO_SINGLE_USER.ACTION,
  SubscriptionState
>;
export const unionProductToSingleUser = (
  products: Products,
): UnionProductToSingleUser => ({
  type: types.UNION_PRODUCT_TO_SINGLE_USER.ACTION,
  payload: {
    products,
    isFetching: false,
    errors: [],
  },
});

export type Reload = Action<typeof types.RELOAD.ACTION>;
export const reload = (): Reload => ({ type: types.RELOAD.ACTION });

export type CreateStepsFlow = ActionPayload<
  'steps' | 'isFetching' | 'errors',
  typeof types.CREATE_STEPS_FLOW.ACTION,
  SubscriptionState
>;
export const createStepsFlow = (addons: string[]): CreateStepsFlow => ({
  type: types.CREATE_STEPS_FLOW.ACTION,
  payload: {
    steps: CREATE_STEPS_CONTROL.concat(addons).length,
    isFetching: false,
    errors: [],
  },
});

export type ControlStepsFlow = ActionPayload<
  'progressStep' | 'isFetching' | 'errors',
  typeof types.CONTROL_STEPS_FLOW.ACTION,
  SubscriptionState
>;
export const controlStepsFlow = (progressStep: number): ControlStepsFlow => ({
  type: types.CONTROL_STEPS_FLOW.ACTION,
  payload: {
    progressStep,
    isFetching: false,
    errors: [],
  },
});

export const goToIclinicAssist = () => ({
  type: types.GO_TO_ICLINIC_ASSIST.ACTION,
});

export type SubscriptionActions =
  | SetStep
  | OpenModal
  | OpenModalSuccess
  | CloseModal
  | CloseModalSuccess
  | ListPhysicians
  | ListPhysiciansSuccess
  | GetContract
  | SetSubscriptionProduct
  | SelectProductSuccess
  | SelectProductFailure
  | FetchSubscription
  | FetchSubscriptionSuccess
  | FetchSubscriptionFailure
  | SelectPhysician
  | SelectPhysicianFailure
  | SelectPhysicianSuccess
  | VerifySelectedAddons
  | SetCurrentAddon
  | SetSelectedAddons
  | VerificationNextStep
  | VerificationBackStep
  | ClearErrorsSubscription
  | UpdateCredictCard
  | FlipCard
  | FetchCheckout
  | FetchCheckoutFailure
  | GetSummary
  | GetSummaryFailure
  | GetSummarySuccess
  | UnionProductToUser
  | UnionProductToSingleUser
  | Reload
  | CreateStepsFlow
  | ControlStepsFlow;
