import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAutocompleteOption } from 'features/tissInvoicing/containers/expense/interfaces/AutocompleteOption';
import {
  CreateExpenseFetchParams,
  CreateExpenseResponseFaliureWithError,
  CreateExpenseResponseStatus,
  DeleteExpenseRequestParams,
  ExpenseDetail,
  ExpenseListPayload,
  Expenses,
  FetchExpenseAutocompletePayload,
  FetchExpensesRequestParams,
  GetExpenseDetailsIdParam,
} from 'features/tissInvoicing/types';

export type ExpenseState = {
  expenseList: {
    expenses: Expenses[];
    loading: boolean;
    hasMoreData: boolean;
    pageNum: number;
  };
  createExpense: {
    autocompletes: {
      [key: string]: IAutocompleteOption[];
    };
    loading: boolean;
    item?: any;
    error: boolean;
    expenseDetail: ExpenseDetail;
  };
};

const name = 'expense';

export const initialState: ExpenseState = {
  expenseList: {
    expenses: [],
    loading: false,
    hasMoreData: false,
    pageNum: 1,
  },
  createExpense: {
    loading: false,
    error: false,
    autocompletes: {},
    expenseDetail: {
      healthInsurance: {
        id: '',
        title: '',
      },
      procedure: {
        id: '',
        title: '',
      },
      expenses: [],
    },
  },
};

const changeLoadingState = (
  state: ExpenseState,
  action: PayloadAction<CreateExpenseResponseStatus>,
) => ({
  ...state,
  createExpense: {
    ...state.createExpense,
    loading: action.payload.loading,
  },
});

const changeLoadingStateToSuccess = (
  state: ExpenseState,
  action: PayloadAction<boolean>,
) => ({
  ...state,
  createExpense: {
    ...state.createExpense,
    loading: !action.payload,
  },
});

const changeLoadingStateAndSetError = (
  state: ExpenseState,
  action: PayloadAction<CreateExpenseResponseFaliureWithError>,
) => ({
  ...state,
  createExpense: {
    ...state.createExpense,
    loading: action.payload.loading,
    error: action.payload.error,
  },
});

const ExpenseSlice = createSlice({
  name,
  initialState,
  reducers: {
    setExpenseListFetchStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      expenseList: {
        ...state.expenseList,
        loading: action.payload,
      },
    }),
    setExpenseList: (state, action: PayloadAction<ExpenseListPayload>) => ({
      ...state,
      expenseList: {
        loading: action.payload.loading,
        hasMoreData: action.payload.hasMoreData,
        expenses: action.payload?.expenses || [],
        pageNum: state.expenseList.pageNum,
      },
    }),
    deleteExpense: (
      state,
      action: PayloadAction<DeleteExpenseRequestParams>,
    ) => {
      const updatedExpenseList = state.expenseList.expenses.filter(
        (item) => item.id !== action.payload.id,
      );

      return {
        ...state,
        expenseList: {
          ...state.expenseList,
          expenses: updatedExpenseList,
        },
      };
    },
    setExpenseAutocomplete: (state, action: PayloadAction<any>) => {
      const { name: autoCompleteName, items } = action.payload;
      return {
        ...state,
        createExpense: {
          ...state.createExpense,
          autocompletes: {
            ...state.createExpense.autocompletes,
            [autoCompleteName]: items,
          },
        },
      };
    },
    createExpenseStatus: (
      state,
      action: PayloadAction<CreateExpenseResponseStatus>,
    ) => changeLoadingState(state, action),
    createExpenseSuccess: (state, action: PayloadAction<boolean>) =>
      changeLoadingStateToSuccess(state, action),
    createExpenseFailure: (
      state,
      action: PayloadAction<CreateExpenseResponseFaliureWithError>,
    ) => changeLoadingStateAndSetError(state, action),
    getExpenseDetailStatus: (
      state,
      action: PayloadAction<CreateExpenseResponseStatus>,
    ) => changeLoadingState(state, action),
    getExpenseDetailsSuccess: (state, action: PayloadAction<boolean>) =>
      changeLoadingStateToSuccess(state, action),
    getExpenseDetailsFailure: (
      state,
      action: PayloadAction<CreateExpenseResponseFaliureWithError>,
    ) => changeLoadingStateAndSetError(state, action),
    hideError: (state, action: PayloadAction<boolean>) => ({
      ...state,
      createExpense: {
        ...state.createExpense,
        error: action.payload,
      },
    }),
    setExpenseDetails: (state, action) => ({
      ...state,
      createExpense: {
        ...state.createExpense,
        expenseDetail: action.payload.createExpense,
      },
    }),
  },
});

export default ExpenseSlice.reducer;

const fetchExpenseList = createAction<FetchExpensesRequestParams>(
  `${name}/fetchExpenseList`,
);

const fetchExpenseAutocomplete = createAction<FetchExpenseAutocompletePayload>(
  `${name}/fetchExpenseAutocomplete`,
);

const createExpenseFetch = createAction<CreateExpenseFetchParams>(
  `${name}/createExpenseFetch`,
);

const getExpenseDetailsFetch = createAction<GetExpenseDetailsIdParam>(
  `${name}/getExpenseDetailsFetch`,
);

export const actions = {
  ...ExpenseSlice.actions,
  fetchExpenseAutocomplete,
  fetchExpenseList,
  createExpenseFetch,
  getExpenseDetailsFetch,
};
