import React from 'react';
import PropTypes from 'prop-types';

import style from './CardWorkHours.scss';

const CardWorkHours = ({ children }) => (
  <div className={style.card}>
    <div id="cardTogle" className={style.card__divTitle}>
      <p className={style.card__title}>CLÍNICA</p>
      <div className={style.card__divContent}>
        {children}
      </div>
    </div>
  </div>
);

CardWorkHours.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CardWorkHours;
