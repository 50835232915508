import { trackCustomEvent } from 'shared/utils/trackEvents';
import {
  TELEMEDICINE_FEATURE_USAGE_TRACK_SCHEMA,
  VIDEO_CONFERENCE_CONTEXT_SCHEMA,
} from '../constants';
import { Participant, TelemedicineFeatureUsage } from '../constants/types';

const getUserType = (isPhysician: boolean): string =>
  isPhysician ? 'Physician' : 'Guest';

const getVideoConferenceContextTracker = (
  localParticipant: Participant,
  remoteParticipant: Participant,
  conferenceId: number,
  isPhysician: boolean,
) => ({
  schema: VIDEO_CONFERENCE_CONTEXT_SCHEMA,
  data: {
    video_conference_id: conferenceId || 0,
    local_participant_id: localParticipant?.id,
    local_participant_type: getUserType(isPhysician),
    remote_participant_id: remoteParticipant?.id,
    remote_participant_type: getUserType(!isPhysician),
  },
});

export function trackFeatureUsage({
  localParticipant,
  remoteParticipant,
  conferenceId,
  isPhysician,
  buttonName,
  buttonLocation,
  action,
  additionalProperties,
}: TelemedicineFeatureUsage) {
  const videoConferenceContext = getVideoConferenceContextTracker(
    localParticipant,
    remoteParticipant,
    conferenceId,
    isPhysician,
  );

  trackCustomEvent(
    TELEMEDICINE_FEATURE_USAGE_TRACK_SCHEMA,
    {
      button_name: buttonName,
      button_location: buttonLocation || null,
      action: action || null,
      additional_properties: additionalProperties || null,
    },
    [videoConferenceContext],
  );
}
