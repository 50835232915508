import { StoreState } from 'state/rootReducer';

export const getAppointmentGuideState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.appointmentGuide;

export const loadingAppointmentGuideState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.appointmentGuide.loading;

export const getSnackbarState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.appointmentGuide.snackbar;

export const getAppointmentGuideFormDataState = ({
  tissInvoicing,
}: StoreState) => tissInvoicing.appointmentGuide.form.data;

export const prrintAppointmentGuideState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.appointmentGuide.print;

export const getUserData = ({ userInfo }: StoreState) => userInfo.userData;
