import {
  Grid,
  MaterialIcons,
  MaterialUICore,
  Paper,
  SectionTitle,
} from '@iclinic/design-system';
import React, { forwardRef, useState } from 'react';

import { SpaceBetweenBox } from 'features/tissInvoicing/components/ui/Flexbox';
import * as Styles from './styles';

const { Collapse, IconButton } = MaterialUICore;

const { ExpandLess, ExpandMore } = MaterialIcons;

interface CollapsibleFormSectionProps {
  children?: React.ReactNode | React.ReactNode[];
  sectionTitle: string;
  sectionIcon?: React.ReactNode;
  sectionIdentifier: string;
}

const CollapsibleFormSection = (
  {
    children,
    sectionTitle,
    sectionIcon,
    sectionIdentifier,
  }: CollapsibleFormSectionProps,
  ref: React.Ref<HTMLButtonElement>,
) => {
  const classes = Styles.useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [shouldRenderContent, setShouldRenderContent] = useState(isOpen);

  const handleCollapse = () => {
    setIsOpen((state) => !state);

    if (!shouldRenderContent) {
      setShouldRenderContent(true);
      return;
    }

    const timeout = setTimeout(() => {
      setShouldRenderContent(false);
      clearTimeout(timeout);
    }, 100);
  };

  const shouldOutline = isOpen ? undefined : 'outlined';
  return (
    <Paper elevation={1} variant={shouldOutline} className={classes.root}>
      <SpaceBetweenBox>
        <Styles.Title mb={isOpen ? 2 : 0}>
          <SectionTitle
            title={sectionTitle}
            mb={0}
            icon={sectionIcon}
            onClick={handleCollapse}
          />

          <IconButton
            data-testid={sectionIdentifier}
            onClick={handleCollapse}
            data-status={`${isOpen ? 'open' : 'closed'}`}
            ref={ref}
          >
            {isOpen ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Styles.Title>
      </SpaceBetweenBox>

      <Collapse in={isOpen}>
        {shouldRenderContent && (
          <Grid container direction="column" spacing={2}>
            {children}
          </Grid>
        )}
      </Collapse>
    </Paper>
  );
};

export default forwardRef(CollapsibleFormSection);
