import React from 'react';
import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing, border } = axiomTokens;

type CaptionContainerProps = {
  remoteTranscript: string;
};

export const CaptionContainer = styled(
  ({
    remoteTranscript,
    ...rest
  }: CaptionContainerProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >) => <div {...rest} />,
)({
  position: 'absolute',
  zIndex: 1,
  padding: spacing[4],
  background: 'rgba(0, 0, 0, 0.64)',
  borderRadius: border.radius.md,
  bottom: '25px',
  display: ({ remoteTranscript }: CaptionContainerProps) =>
    remoteTranscript ? 'block' : 'none',
});
