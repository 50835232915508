import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

import { Box, MaterialIcons } from '@iclinic/design-system';
import { ScrollToErrorFormik } from 'shared/hooks';
import { SellerBankAccountForm } from 'features/onlinePayment/state/config/types';
import { trackSignupIClinicPayButtons } from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';
import {
  fetchBankAccount,
  fetchBanks,
  saveBankCardInfos,
} from 'features/onlinePayment/state/config/bankAccount';
import { getSellerBankSelector } from 'features/onlinePayment/state/config/bankAccount/selectors';
import { getUserInfo } from 'features/onlinePayment/state/config/register/selectors';
import { CardAccountData } from 'features/onlinePayment/components/config';
import CardCreditCard from 'features/onlinePayment/components/config/card/CardCreditCard';
import SellerRegisterBankLoader from 'features/onlinePayment/components/config/SellerRegisterBankLoader';
import * as S from 'features/onlinePayment/components/config/Fab';
import { validationSellerRegisterBank } from './validationSchema';
import { getBankAccountInitialData } from './form.normalizer';

const { Check } = MaterialIcons;

const SellerRegisterBank = () => {
  const dispatch = useDispatch();

  const userData = useSelector(getUserInfo);
  const {
    fetching,
    seller,
    saving,
    sellerBankAccountForm: data,
    sellerBankAccount,
  } = useSelector(getSellerBankSelector);

  useEffect(() => {
    dispatch(fetchBanks());
    dispatch(fetchBankAccount(seller?.bank_account?.id));
  }, [dispatch, seller]);

  if (fetching || saving) {
    return <SellerRegisterBankLoader />;
  }

  const initialBankAccountValues = getBankAccountInitialData(
    data,
    seller,
    sellerBankAccount,
  );

  const handleSubmit = () => {
    const buttonName =
      initialBankAccountValues.type === SellerType.PF
        ? 'pay_register_second_step_individual_person'
        : 'pay_register_second_step_legal_person';
    trackSignupIClinicPayButtons({
      userData,
      buttonName,
      buttonLocation: 'create_payment',
    });
  };

  return (
    <Formik
      initialValues={initialBankAccountValues}
      validationSchema={validationSellerRegisterBank}
      onSubmit={(sellerBankAccountForm: SellerBankAccountForm) => {
        dispatch(saveBankCardInfos(sellerBankAccountForm));
      }}
    >
      <Form>
        <Box mb={2}>
          <CardAccountData />
        </Box>
        <CardCreditCard renderTerms />
        <S.FloatingWrapper>
          <S.Fab
            type="submit"
            onClick={handleSubmit}
            data-ga="pagamento-online-cadastro-enviar"
          >
            <Check />
            <span>Enviar</span>
          </S.Fab>
        </S.FloatingWrapper>
        <ScrollToErrorFormik />
      </Form>
    </Formik>
  );
};

export default SellerRegisterBank;
