import { StoreState } from 'state/rootReducer';
import { RequestStatus } from 'shared/constants/State';

export const getExportDrawer = ({
  exportClinicData,
}: StoreState): boolean | undefined => exportClinicData.exportHome.exportDrawer;

export const getNewExport = ({
  exportClinicData,
}: StoreState): boolean | undefined => exportClinicData.exportHome.newExport;

export const getExportId = ({
  exportClinicData,
}: StoreState): string | undefined => exportClinicData.exportHome.exportId;

export const getAuditReportStatus = ({
  exportClinicData,
}: StoreState): RequestStatus | undefined =>
  exportClinicData.exportHome.auditReport?.status;
