import React from 'react';

import { Box, Chip, tokens, MaterialIcons } from '@iclinic/design-system';
import { ProfessionalContent } from '../types';
import { ChipsWrapper } from '../UpgradeCall.styles';

const { Check } = MaterialIcons;

type PhysicianContentProps = {
  content: ProfessionalContent;
};

const PhysicianContent = ({ content }: PhysicianContentProps) => {
  return (
    <Box mt={tokens.spacing.base}>
      <Box mb={tokens.spacing.base}>{content.subtitle}</Box>
      <ChipsWrapper>
        {content.list?.map((item, index) => (
          <Box key={index} mb={tokens.spacing.base}>
            <Chip icon={<Check />} severity="success" label={item} />
          </Box>
        ))}
      </ChipsWrapper>
    </Box>
  );
};

export default PhysicianContent;
