import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing } = tokens;

export const Section = styled('section')(({ theme }) => ({
  maxWidth: 848,
  display: 'grid',
  gridTemplateColumns: '1fr',
  padding: spacing.md,

  [theme.breakpoints.up('sm')]: {
    padding: `${spacing.lg} ${spacing.md}`,
    margin: '0 auto',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: spacing.md,
  },
}));

export const Frame = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.sm,

  '& div': {
    display: 'flex',
    columnGap: spacing.sm,

    '& svg': {
      marginTop: spacing.nano,
      color: color.primary.light,
    },
  },
});

export const ImgWrapper = styled('div')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
