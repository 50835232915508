import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Routes from './routes';

const FreeSamples = () => (
  <Switch>
    <Route path="/amostras-gratis" component={Routes} />
    <Redirect to="/404" />
  </Switch>
);

export default FreeSamples;
