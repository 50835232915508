import appTypes from 'shared/utils/actions';
import { SUCCESS, FAILURE } from 'shared/constants/actions';

const settingsOnlineSchedule = appTypes.defineAction('settingsOnlineSchedule');

export const FETCH_SETTINGS_ONLINE_SCHEDULE = settingsOnlineSchedule.defineAction(
  'FETCH_SETTINGS_ONLINE_SCHEDULE',
  [SUCCESS, FAILURE],
);

export const FETCH_PHYSICIANS = settingsOnlineSchedule.defineAction(
  'FETCH_PHYSICIANS',
  [SUCCESS, FAILURE],
);

export const ON_CHANGE_CHECK_INSURANCE = settingsOnlineSchedule.defineAction(
  'ON_CHANGE_CHECK_INSURANCE',
);

export const ON_CHANGE_ALL_CHECK_INSURANCE = settingsOnlineSchedule.defineAction(
  'ON_CHANGE_ALL_CHECK_INSURANCE',
);

export const ON_CHANGE_CHECK_DAY = settingsOnlineSchedule.defineAction(
  'ON_CHANGE_CHECK_DAY',
  [SUCCESS, FAILURE],
);

export const UPDATE_SETTINGS_ONLINE_SCHEDULE = settingsOnlineSchedule.defineAction(
  'UPDATE_SETTINGS_ONLINE_SCHEDULE',
  [SUCCESS, FAILURE],
);

export const ON_CHANGE_SELECT_HOUR = settingsOnlineSchedule.defineAction(
  'ON_CHANGE_SELECT_HOUR',
  [SUCCESS, FAILURE],
);

export const ON_CHANGE_SWITCH_IS_SHOW_SCHEDULE = settingsOnlineSchedule.defineAction(
  'ON_CHANGE_SWITCH_IS_SHOW_SCHEDULE',
);

export const ADD_WORK_HOUR = settingsOnlineSchedule.defineAction(
  'ADD_WORK_HOUR',
  [SUCCESS, FAILURE],
);

export const REMOVE_WORK_HOUR = settingsOnlineSchedule.defineAction(
  'REMOVE_WORK_HOUR',
  [SUCCESS, FAILURE],
);

export const VALIDATE_DAYS_SCHEDULE = settingsOnlineSchedule.defineAction(
  'VALIDATE_DAYS_SCHEDULE',
  [SUCCESS, FAILURE],
);

export const FETCH_SETTINGS_INITIALIZED = settingsOnlineSchedule.defineAction(
  'FETCH_SETTINGS_INITIALIZED',
);

export const UPDATE_INSURANCES_ONLINE_SCHEDULE = settingsOnlineSchedule.defineAction(
  'UPDATE_INSURANCES_ONLINE_SCHEDULE',
  [SUCCESS, FAILURE],
);

export const GET_SCHEDULE_HOURS = settingsOnlineSchedule.defineAction(
  'GET_SCHEDULE_HOURS',
  [SUCCESS, FAILURE],
);

export const NOTIFY_BLOCKED_WEEKDAY = settingsOnlineSchedule.defineAction(
  'NOTIFY_BLOCKED_WEEKDAY',
  [SUCCESS, FAILURE],
);

export const GET_ACTIVE_CLINIC = settingsOnlineSchedule.defineAction(
  'GET_ACTIVE_CLINIC',
  [SUCCESS, FAILURE],
);

export const INVALIDATE_INSURANCES = settingsOnlineSchedule.defineAction(
  'INVALIDATE_INSURANCES',
  [SUCCESS, FAILURE],
);

export const REMOVE_INTEGRATION_ONLINE_SCHEDULE = settingsOnlineSchedule.defineAction(
  'REMOVE_INTEGRATION_ONLINE_SCHEDULE',
  [SUCCESS, FAILURE],
);

export const SET_STEP_INTEGRATION = settingsOnlineSchedule.defineAction(
  'SET_STEP_INTEGRATION',
);

export const SHOW_CREATE_INTEGRATION_MODAL = settingsOnlineSchedule.defineAction(
  'SHOW_CREATE_INTEGRATION_MODAL',
);

export const HIDE_CREATE_INTEGRATION_MODAL = settingsOnlineSchedule.defineAction(
  'HIDE_CREATE_INTEGRATION_MODAL',
);

export const SET_IS_LOADING_INTEGRATION = settingsOnlineSchedule.defineAction(
  'SET_IS_LOADING_INTEGRATION',
);

export const VALIDATE_EMAIL_ALREADY_USED = settingsOnlineSchedule.defineAction(
  'VALIDATE_EMAIL_ALREADY_USED',
);

export const VALIDATE_EMAIL_INTEGRATION = settingsOnlineSchedule.defineAction(
  'VALIDATE_EMAIL_INTEGRATION',
  [SUCCESS, FAILURE],
);

export const VALIDATE_PASSWORD_INTEGRATION = settingsOnlineSchedule.defineAction(
  'VALIDATE_PASSWORD_INTEGRATION',
  [SUCCESS, FAILURE],
);

export const CREATE_PASSWORD_INTEGRATION = settingsOnlineSchedule.defineAction(
  'CREATE_PASSWORD_INTEGRATION',
  [SUCCESS, FAILURE],
);

export const ON_CHANGE_ANTECEDENCE_PERIOD = settingsOnlineSchedule.defineAction(
  'ON_CHANGE_ANTECEDENCE_PERIOD',
);

export interface IInsurances {
  allow_online_scheduling_telemedicine: boolean;
  has_online_schedule_active: boolean;
  id: number;
  insurance: number;
  name: string;
  physician: number;
}
