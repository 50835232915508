// External
import React from 'react';

// Internal
import classnames from 'classnames/bind';
import ss from './FlipCard.scss';
import INITIALS from './constants';

const cx = classnames.bind(ss);

type FlipCardProps = {
  classes?: string;
  number?: string,
  owner?: string,
  flag?: string,
  expirationMonth?: string,
  expirationYear?: string,
  cvv?: string,
  isFlipped?: boolean,
};

export default function FlipCard(
  {
    classes = '',
    number = '∙∙∙∙ ∙∙∙∙ ∙∙∙∙ ∙∙∙∙',
    owner = '',
    isFlipped = false,
    flag = '',
    expirationMonth = '',
    expirationYear = '',
    cvv = '',
  }: FlipCardProps,
): JSX.Element {
  return (
    <div className={cx(ss.baseFlip, classes)}>
      <div className={cx(ss.cover, { [ss.flipped]: isFlipped })}>
        <div className={cx(ss.cardFlip, ss.cardFront)}>
          <img className={ss.cardFlag} alt="Bandeira do cartão de crédito" src={flag} />
          <div className={ss.cardPlaceHolder}>
            <span className={ss.cardNumber}>{number || '∙∙∙∙ ∙∙∙∙ ∙∙∙∙ ∙∙∙∙' }</span>
            <span className={ss.cardName}>{owner || 'Nome no cartão'}</span>
          </div>
          <span className={ss.cardExp}>
            <span>
              {expirationMonth || INITIALS.ExpirationMonth}
              /
              {expirationYear || INITIALS.ExpirationYear}
            </span>
          </span>
        </div>
        <div className={cx(ss.cardFlip, ss.cardBack)}>
          <div className={ss.magnetic} />
          <div className={ss.cvv}>
            <div className={ss.line}>
              <span />
              <span />
              <span />
            </div>
            <span className={ss.code}>{cvv}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
