// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import { Text, Container } from '@iclinic/iclinic-ui';
import { formatNumberToDecimal, pluralize } from 'shared/utils/strings';
import Box from './box';
import style from './PaymentInfo.scss';

export default function PaymentInfoAdmin({
  productName,
  selectedProductPrice,
  totalUsers,
  paymentInfo,
  isAnnual,
}) {
  const users = `${totalUsers} ${pluralize(
    totalUsers,
    'profissional',
    'profissionais',
  )} de saude`;
  const { productValue, period, promotionalValue } = selectedProductPrice;

  const { paymentSlip, card } = paymentInfo;

  const labelProduct = `Novo Plano: ${productName}`;
  const discount =
    period &&
    `Desconto válido por ${period} ${
      isAnnual ? pluralize(period, 'ano') : pluralize(period, 'mês', 'meses')
    }`;

  return (
    <div className={style.payment}>
      <p className={style.payment__product}>{labelProduct}</p>
      <span className={style.divider} />
      <div className={style.payment__content}>
        <Box>
          <Text>{isAnnual ? 'Valor anual' : 'Mensalidade'}</Text>
          <Container direction="row" alignItems="end">
            <Text kind="highlight" size="lg">
              R$
              {formatNumberToDecimal(promotionalValue || productValue)}
            </Text>
            {promotionalValue && (
              <Text className={style.payment__price} dashed>
                R$
                {formatNumberToDecimal(productValue)}
              </Text>
            )}
          </Container>
          {period && <Text size="sm">{discount}</Text>}
        </Box>
        <Box>
          <Text>Usuários</Text>
          <Text kind="highlight">{users}</Text>
        </Box>
        <Box>
          <Text>Pagamento</Text>
          <Text kind="highlight">
            {paymentSlip ? 'Boleto' : `${card.flag} final ${card.number}`}
          </Text>
        </Box>
        <span className={style.divider} />
        <p className={style.payment__footer}>
          Você poderá retornar ao plano anterior a qualquer momento.
        </p>
      </div>
    </div>
  );
}

PaymentInfoAdmin.propTypes = {
  productName: PropTypes.string.isRequired,
  selectedProductPrice: PropTypes.shape({
    productValue: PropTypes.number,
    period: PropTypes.number,
    promotionalValue: PropTypes.number,
  }),
  isAnnual: PropTypes.bool,
  totalUsers: PropTypes.number.isRequired,
  paymentInfo: PropTypes.shape({
    paymentSlip: PropTypes.bool,
    card: PropTypes.shape(),
  }),
};

PaymentInfoAdmin.defaultProps = {
  selectedProductPrice: {},
  paymentInfo: {
    paymentSlip: false,
    card: {
      flag: null,
      number: null,
    },
  },
  isAnnual: false,
};
