import { generalSchema } from 'features/tissInvoicing/containers/sadt/GuideSADT/GeneralSection/validationSchema';
import { beneficiarySchema } from 'features/tissInvoicing/containers/sadt/GuideSADT/BeneficiarySection/validationSchema';
import { solicitorSchema } from 'features/tissInvoicing/containers/sadt/GuideSADT/SolicitorSection/validationSchema';
import { procedureSchema } from 'features/tissInvoicing/containers/sadt/GuideSADT/ProceduresSection/validationSchema';
import { sessionSchema } from 'features/tissInvoicing/containers/sadt/GuideSADT/SessionsSection/validationSchema';
import { expensesSchema } from 'features/tissInvoicing/containers/sadt/GuideSADT/ExpenseSection/validationSchema';
import { headerSectionSchema } from './HeaderSection/validationSchema';

export const validationSadtData = headerSectionSchema
  .concat(generalSchema)
  .concat(beneficiarySchema)
  .concat(solicitorSchema)
  .concat(procedureSchema)
  .concat(sessionSchema)
  .concat(expensesSchema);

export const formKeys = {
  headerData: Object.keys(headerSectionSchema.fields),
  generalData: Object.keys(generalSchema.fields),
  beneficiaryData: Object.keys(beneficiarySchema.fields),
  solicitorData: Object.keys(solicitorSchema.fields),
  proceduresData: Object.keys(procedureSchema.fields),
  sessionsData: Object.keys(sessionSchema.fields),
  expensesData: Object.keys(expensesSchema.fields),
};

export enum FormKeysEnum {
  headerData = 1,
  generalData = 2,
  beneficiaryData = 3,
  solicitorData = 4,
  proceduresData = 5,
  sessionsData = 6,
  expensesData = 7,
}
