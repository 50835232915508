import React from 'react';

import {
  MaterialUICore,
  MaterialIcons,
  Button,
  Box,
  Typography,
} from '@iclinic/design-system';
import useStyles from './InfoDrawer.styles';

const { Drawer } = MaterialUICore;
const { ArrowBack } = MaterialIcons;

type InfoDrawerProps = {
  title: string;
  open: boolean;
  onClose: () => void;
  width: string;
  children: React.ReactNode;
};

const InfoDrawer = ({
  title,
  open,
  onClose,
  width,
  children,
}: InfoDrawerProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className={classes.drawer}
      BackdropProps={{ invisible: true }}
    >
      <Box p={4} width={width}>
        <Box mb={4}>
          <Typography variant="h3">{title}</Typography>
        </Box>
        {children}
      </Box>
      <Box py={2} px={4}>
        <Button
          color="transparent"
          className={classes.backButton}
          startIcon={<ArrowBack color="action" fontSize="small" />}
          onClick={onClose}
        >
          Voltar
        </Button>
      </Box>
    </Drawer>
  );
};

export default InfoDrawer;
