// Internal
import appTypes from 'shared/utils/actions';
import {
  SUCCESS, FAILURE, OPEN, CLOSE,
} from 'shared/constants/actions';

const video = appTypes.defineAction('video');

export const DETAIL = video.defineAction('DETAIL', [OPEN, CLOSE]);
export const FETCH_DETAIL = video.defineAction('FETCH_DETAIL', [SUCCESS, FAILURE]);
export const SET_STEP = video.defineAction('SET_STEP', []);
