import * as React from 'react';
import { connect } from 'react-redux';

import { MaterialUICore, ScheduleButton, Box } from '@iclinic/design-system';
import { StoreState } from 'state/rootReducer';
import * as actions from 'state/schedule/actions';
import USER_KIND from '../constants';

const { makeStyles } = MaterialUICore;

type Props = {
  togglePaymentsControlDrawer: () => void;
  toggleScheduleModal: () => void;
  togglePatientWaitingList: () => void;
  toggleScheduleObservation: () => void;
  printSchedule: () => void;
  openScheduleSettings: () => void;
  userData: {
    userKind: string;
    isAdmin: boolean;
    isClinicAdmin: boolean;
    hasSellerApproved?: boolean;
  };
  isFetching: boolean;
};

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',

    '& > button': {
      marginLeft: '8px',
    },

    '& > button:first-child': {
      marginLeft: '0',
    },
  },
}));

export const Button = ({
  togglePaymentsControlDrawer,
  toggleScheduleModal,
  togglePatientWaitingList,
  toggleScheduleObservation,
  printSchedule,
  openScheduleSettings,
  userData: { userKind, isAdmin, isClinicAdmin, hasSellerApproved },
  isFetching,
}: Props): JSX.Element => {
  const classes = useStyles();

  const showSettingsButton = React.useMemo(() => {
    if (userKind === USER_KIND.PHYSICIAN || isAdmin || isClinicAdmin) {
      return true;
    }

    return false;
  }, [userKind, isAdmin, isClinicAdmin]);

  return (
    <Box className={classes.root}>
      {hasSellerApproved && (
        <ScheduleButton
          onClick={togglePaymentsControlDrawer}
          icon="money"
          text="Controle de pagamento"
          idGa="agenda_controle-pagamento"
        />
      )}
      <ScheduleButton
        onClick={toggleScheduleModal}
        icon="add"
        text="Novo Agendamento"
        idGa="agenda_novo-agendamento"
      />
      <ScheduleButton
        onClick={togglePatientWaitingList}
        icon="clock"
        text="Lista de Espera"
        idGa="agenda_lista-de-espera"
      />
      <ScheduleButton
        onClick={toggleScheduleObservation}
        icon="annotation"
        text="Observações"
        idGa="agenda_observacao"
      />
      <ScheduleButton
        onClick={printSchedule}
        icon="print"
        text="Imprimir Agenda"
        idGa="agenda_imprimir"
        disabled={isFetching}
      />
      {showSettingsButton && (
        <ScheduleButton
          onClick={openScheduleSettings}
          icon="settings"
          text="Agendamento online"
          idGa="agenda_configuracao_online"
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state: StoreState) => ({
  userData: state.userInfo.userData,
  isFetching: state.schedule.isFetching,
});

export default connect(mapStateToProps, actions)(Button);
