import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MaterialUICore,
  MaterialIcons,
  ChipCustom,
  Dialog,
  Button,
  Typography,
  Box,
} from '@iclinic/design-system';

import {
  clearSignPDFErrors,
  toggleDrawer,
  signatureChangeStep,
  fetchSignatureCloud,
} from 'state/records/signature/actions';
import { clearSharedErrors } from 'state/shared/actions';
import { getLoading } from 'state/records/signature/selectors';
import { getCurrentEventAndPatientId } from 'state/records/selectors';
import { getUrlParams } from 'shared/utils/url';
import { IStoreState } from 'state/rootReducer';
import useStyles from './styleSignatureInitial';
import useSignature from '../hooks/useSignature';
import NotificationErrors from '../components/NotificationErrors';
import StepCloud from '../components/StepCloud';
import {
  toggleState,
  SIGNED_RECORD,
  SIGNED_REDIRECT_USER_INFOS,
  CHIPS_SIGNATURE_PROVIDERS,
  Providers,
  STEP_SIGNATURE,
  ProvidersNames,
} from './constants';
import webPKI from '../webpkiConfig';
import { removeUrlParameter, changeUrl } from '../hooks/utils';
import { actions as latestDiagnosesActions } from 'features/records/latestDiagnoses/state';
import { actions as cartPatientActions } from 'features/records/cardPatient/state';

const { Drawer, CircularProgress, SnackbarContent } = MaterialUICore;

const SignatureInitial: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { signatureVerifyNextStep } = useSignature();
  const { CLOSE } = toggleState;
  const [openModalRedirect, setOpenModalRedirect] = useState(false);

  const isOpenDrawer = useSelector<IStoreState>(
    (state) => state.records.signature.isOpenDrawer,
  );
  const lifeTime = useSelector<IStoreState>(
    (state) => state.records.signature.lifeTime,
  );
  const permissions =
    useSelector<IStoreState>((state) => state.userInfo.userData.permissions) ||
    [];
  const providersUrlSelected = useSelector(
    (state) => state.records.signature.providersUrlSelected,
  );
  const providerName = useSelector(
    (state) => state.records.signature.providerName,
  );
  const userDocument = useSelector(
    (state) => state.records.signature.userDocument,
  );
  const hasPermissions = useMemo(
    () => permissions.includes('assinatura-digital'),
    [permissions],
  );
  const isLoading = useSelector((state) => getLoading(state));
  const accessToken = useSelector(
    (state) => state.records.signature.accessToken,
  );
  const { eventId, patientId } = useSelector(getCurrentEventAndPatientId);

  const [isSign, setIsSign] = useState(() => {
    const toggleStatus = localStorage.getItem(SIGNED_RECORD);
    if (toggleStatus) return toggleStatus;
    return Providers.Not;
  });

  const checkStepperFlow =
    isSign === Providers.Local || isSign === Providers.Cloud;

  const onClickToggleDrawer = useCallback(
    (drawerState: boolean) => {
      dispatch(toggleDrawer(drawerState));
    },
    [dispatch],
  );

  const setSignLocalStorage = useCallback((valueSign: string) => {
    localStorage.setItem(SIGNED_RECORD, valueSign);
    setIsSign(valueSign);
  }, []);

  const handleClickOpenModalRedirect = () => {
    setOpenModalRedirect(true);
  };

  const handleClose = () => {
    setOpenModalRedirect(false);
  };

  const handleRedirect = () => {
    window.location.href = `${providersUrlSelected}&lifetime=${String(
      lifeTime,
    )}`;
  };

  const onClickFinalize = useCallback(() => {
    dispatch(clearSignPDFErrors());
    dispatch(clearSharedErrors());
    dispatch(latestDiagnosesActions.getLatestDiagnoses(patientId));
    dispatch(cartPatientActions.getCardPatient(Number(patientId)));
    if (providersUrlSelected && isSign === Providers.Cloud) {
      if (accessToken) {
        dispatch(fetchSignatureCloud());
        return;
      }
      localStorage.setItem(
        SIGNED_REDIRECT_USER_INFOS,
        JSON.stringify({
          lifeTime,
          providerName,
          userDocument,
          eventId,
          patientId,
        }),
      );
      handleClickOpenModalRedirect();
      return;
    }
    signatureVerifyNextStep(isSign === Providers.Local && hasPermissions);
  }, [
    accessToken,
    dispatch,
    eventId,
    hasPermissions,
    isSign,
    lifeTime,
    patientId,
    providerName,
    providersUrlSelected,
    signatureVerifyNextStep,
    userDocument,
  ]);

  const OnBackButton = () => {
    dispatch(clearSignPDFErrors());
    dispatch(clearSharedErrors());
    dispatch(toggleDrawer(CLOSE));
  };
  useEffect(() => {
    const code = getUrlParams('code');
    const state = getUrlParams('state');
    const error = getUrlParams('error');
    if (error) {
      removeUrlParameter(window.location.href, 'error');
      removeUrlParameter(window.location.href, 'error_description');
      const newUrl = removeUrlParameter(window.location.href, 'state');
      changeUrl(newUrl, 'Prontuários - iClinic');
    }
    if (code && state) dispatch(signatureChangeStep(STEP_SIGNATURE.Loading));
  }, [dispatch]);

  return (
    <>
      <Drawer
        data-testid="signature-initial"
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="persistent"
        anchor="right"
        open={!!isOpenDrawer}
        onClose={() => onClickToggleDrawer(CLOSE)}
      >
        <div className={classes.content}>
          <div className={classes.mainContent}>
            <Typography variant="h3">Finalizar atendimento</Typography>
            <Typography variant="body2" className={classes.bodyText}>
              Ao finalizar um atendimento, você não poderá alterá-lo novamente.
              Deseja prosseguir?
            </Typography>
            {hasPermissions && (
              <>
                <div className={classes.permission}>
                  <Typography variant="body1" className={classes.bodyText}>
                    Assinatura digital
                  </Typography>
                  <Typography variant="subtitle1" className={classes.bodyText}>
                    Assinar com:
                  </Typography>
                  <div className={classes.listChips}>
                    {CHIPS_SIGNATURE_PROVIDERS.map(
                      (providers) =>
                        providers.visible && (
                          <ChipCustom
                            key={`${providers.label}-${providers.value}`}
                            label={providers.label}
                            selected={isSign === providers.value}
                            onClick={() => setSignLocalStorage(providers.value)}
                          />
                        ),
                    )}
                  </div>
                  {isSign === Providers.Cloud && <StepCloud />}
                </div>
                <NotificationErrors />
                <SnackbarContent
                  className={`${classes.snackbar} ${classes.displayMobile}`}
                  elevation={0}
                  message={
                    <Box display="flex">
                      <MaterialIcons.Error className={classes.icon} />
                      Assinatura digital está disponível apenas para desktop
                    </Box>
                  }
                />
              </>
            )}
          </div>
          <div className={classes.footerSingle}>
            <Button
              disabled={isLoading}
              className={classes.backButton}
              startIcon={<MaterialIcons.ArrowBack />}
              onClick={OnBackButton}
              color="transparent"
            >
              <Typography variant="overline">Voltar</Typography>
            </Button>
            <Button
              disabled={isLoading}
              className={classes.footerButton}
              variant="contained"
              color="primary"
              onClick={onClickFinalize}
              data-ga="finalizar_atendimento"
            >
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
              {checkStepperFlow && hasPermissions && !webPKI.isSupportedMobile
                ? 'Finalizar e assinar'
                : 'Finalizar'}
            </Button>
          </div>
        </div>
      </Drawer>
      <Dialog open={openModalRedirect} keepMounted>
        <div className={classes.contentModal}>
          <Typography variant="body2" className={classes.bodyText}>
            Você será direcionado para a página do{' '}
            <strong>{ProvidersNames[providerName]}</strong> para continuar a
            <strong> autorização do certificado.</strong>
          </Typography>
        </div>
        <div className={classes.footerModal}>
          <Button
            className={classes.backButton}
            onClick={handleClose}
            color="transparent"
          >
            <Typography variant="overline">Cancelar</Typography>
          </Button>
          <Button
            className={classes.footerButton}
            variant="contained"
            color="primary"
            onClick={handleRedirect}
          >
            Continuar
          </Button>
        </div>
      </Dialog>
    </>
  );
};

export default SignatureInitial;
