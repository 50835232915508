// External
import React from 'react';

// Internal
import classnames from 'classnames/bind';
import ss from './Price.scss';

const cx = classnames.bind(ss);

type PriceProps = {
  title?: string;
  subtitle?:string;
  classes?: string;
  prefix?: string;
  suffix?: string;
  value: string | null;
};

export default function Price(
  {
    title = 'por profissional de saúde',
    subtitle = '',
    classes = '',
    prefix = 'R$',
    suffix = '',
    value,
  }: PriceProps,
): JSX.Element {
  return (
    <div className={cx(ss.price, classes)}>
      <p>
        {prefix}
        <span className={cx(ss.value)}>{value}</span>
        {suffix}
      </p>
      <p className={ss.description}>
        {title}
        <span className={cx(ss.subtitle)}>{subtitle}</span>
      </p>
    </div>
  );
}
