import { call, put, takeLatest } from 'redux-saga/effects';
import { Response } from '@types';

import { captureException } from 'shared/utils/handlerErrors';
import * as api from 'features/onlinePayment/services/panel';
import { FinancialSellerRawData } from 'features/onlinePayment/services/panel/types';
import { normalizeFinancialSellerResponseData } from './normalizers';
import {
  fetchFinancialSellerData,
  fetchFinancialSellerDataSuccess,
  fetchFinancialSellerDataFailure,
} from '.';

export function* fetchFinancialSellerDataWorker({
  payload: { date_start, date_end },
}: any) {
  try {
    const { data }: Response<FinancialSellerRawData> = yield call(
      api.getFinancialSellerData,
      date_start,
      date_end,
    );
    const normalizeFinancialSellerData =
      normalizeFinancialSellerResponseData(data);
    yield put(fetchFinancialSellerDataSuccess(normalizeFinancialSellerData));
  } catch (error) {
    captureException(error);
    yield put(fetchFinancialSellerDataFailure());
  }
}

export default function* panelIClinicPaySagas() {
  yield takeLatest(fetchFinancialSellerData, fetchFinancialSellerDataWorker);
}
