import {
  MaterialUICore,
  tokens,
  Heading,
  Body,
  Card as DSCard,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing, shadow, line, font } = tokens;

export const Card = styled(DSCard)({
  padding: spacing.md,
  margin: `${spacing.lg} auto`,
  boxShadow: shadow.level[4],
  maxWidth: 608,
});

export const TextHeading = styled(Heading)({
  color: color.neutral[999],
  marginBottom: spacing.base,
  fontWeight: font.weight.medium,
});

export const Paragraph = styled(Body)(({ theme }) => ({
  color: color.neutral[700],
  lineHeight: line.height.md,
  marginBottom: spacing.sm,
  fontSize: font.size.xs,

  [theme.breakpoints.up('sm')]: {
    fontSize: font.size.sm,
  },
}));
