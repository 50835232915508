// External
import { createSelector } from 'reselect';

// Internal
import { BENEFITS } from 'shared/constants/benefits';
import { splitPriceDiference } from 'shared/utils/strings';
import { UPGRADE } from 'shared/constants/buttons';
import { getSuccessViews } from 'shared/utils/views';
import { VIEWSTEPS, VIEWSIZE } from 'features/upgrade/constants';
import { selectProduct } from '../shared/selectors';
import { StoreState } from 'state/rootReducer';

export const getUpgradeProducts = (state: StoreState) => state.upgrade.products;
export const getSelectedProduct = (state: StoreState) =>
  state.upgrade.selectedProduct;
export const getPeriod = (state: StoreState) => state.upgrade.isAnnual;
export const getStep = (state: StoreState) => state.upgrade.step;
export const getUserInfo = (state: StoreState) => state.upgrade.userInfo;
export const getPaymentInfo = (state: StoreState) => state.upgrade.paymentInfo;
export const getSelectedProductSlug = (state: StoreState) =>
  state.upgrade.selectedProductSlug;
export const getCurrentModal = (state: StoreState) =>
  state.upgrade.currentModal;
export const getIsFetching = (state: StoreState) => state.upgrade.isFetching;
export const isOpenModal = (state: StoreState) => state.upgrade.isOpen;

export const mapBenefits = (slug) =>
  BENEFITS.map((currentBenefit) => ({
    isChecked: currentBenefit.products.includes(slug),
    slug: currentBenefit.slug,
  }));

export const getSplittedPriceDifference = (product) => {
  if (!product.isCurrent && product.priceDifference !== null) {
    const priceDifference = splitPriceDiference(
      product.priceDifference.monthly,
    );
    return { total: priceDifference.total, cents: priceDifference.cents };
  }
  return null;
};

export const getUpdatedProducts = createSelector(
  [getUpgradeProducts],
  (products) =>
    products.map((product) => ({
      ...product,
      priceDifferenceSplit: getSplittedPriceDifference(product),
      checks: mapBenefits(product.slug),
    })),
);

export const getSelectedProductPrice = createSelector(
  [getSelectedProduct, getPeriod],
  selectProduct,
);

export const getModalPropsByStep = createSelector(getStep, (currentStep) => ({
  width: currentStep === VIEWSTEPS.Info ? VIEWSIZE.Medium : VIEWSIZE.Large,
}));

export const getSelectedProductSuccessInfo = createSelector(
  getSelectedProductSlug,
  (slug) => getSuccessViews(slug),
);

export const getButtonMessage = createSelector(
  [getUserInfo, getPaymentInfo],
  (userInfo, paymentInfo) => {
    if (userInfo === null) {
      return '';
    }
    if (userInfo.isAdmin) {
      if (paymentInfo && paymentInfo.paymentSlip) {
        return UPGRADE.Slip;
      }
      return UPGRADE.Contract;
    }
    return UPGRADE.NonAdmin;
  },
);
