import {
  MaterialUICore,
  MaterialIcons,
  TextField,
  CircularProgress,
  Body,
} from '@iclinic/design-system';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from '../../state';
import {
  List,
  ListContainer,
  ListItem,
  ListItemText,
  ListItemNested,
  SearchContainer,
  LoadingContainer,
  CalculatorDrawerEmptyContainer,
} from './CalculatorDrawerList.styles';
import { CalculatorDrawerHeader } from '../../CalculatorDrawer.styles';
import {
  getIsCalculatorListByCategory,
  getIsCalculatorLoading,
  getCalculatorError,
  getUserInfo,
} from '../../state/selectors';
import { CalculatorItem, CalculatorsItemsByCategory } from '../../state/types';
import { ErrorBoundary } from 'features/records/errorBoundary';
import images from 'shared/paths/images';
import { trackEvent } from '../../utils/trackCalculatorEvents';

const { closeCalculatorDrawer, getCalculators, setCalculatorView } = actions;

const { ExpandLess, ExpandMore, SearchOutlined } = MaterialIcons;

const { Collapse } = MaterialUICore;

export const CalculatorDrawerList = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');

  const { profileId, isSubscriber, subscriptionId } = useSelector(getUserInfo);
  const calculatorListByCategory = useSelector(getIsCalculatorListByCategory);
  const calculatorError = useSelector(getCalculatorError);
  const isCalculatorLoading = useSelector(getIsCalculatorLoading);

  const [categoryOpen, setCategoryOpen] = useState('');

  const onFetchCalculators = useCallback(() => {
    dispatch(getCalculators());
  }, [dispatch]);

  useEffect(() => {
    if (!calculatorListByCategory.length) {
      onFetchCalculators();
    }
  }, [calculatorListByCategory.length, onFetchCalculators]);

  const onDrawerClose = () => {
    dispatch(closeCalculatorDrawer());
  };

  const calculatorsFilteredByCategory = useMemo(
    () =>
      calculatorListByCategory.reduce(
        (
          accumulator: CalculatorsItemsByCategory[],
          currentValue: CalculatorsItemsByCategory,
        ) => {
          const calculatorsFiltered = currentValue.conteudos.filter(
            (calculator) =>
              calculator.titulo
                .toLowerCase()
                .includes(searchText.toLowerCase()),
          );

          if (calculatorsFiltered.length > 0) {
            accumulator.push({
              ...currentValue,
              conteudos: calculatorsFiltered,
            });
          }
          return accumulator;
        },
        [],
      ),
    [calculatorListByCategory, searchText],
  );

  const handleClickCategory = (category: string) => {
    if (categoryOpen === category) {
      setCategoryOpen('');
    } else {
      setCategoryOpen(category);
    }
  };

  const onSelectedCalculator = (calculator: CalculatorItem) => {
    trackEvent(
      'medical_record_medical_calculators_calculator_selection',
      profileId || '',
      subscriptionId || '',
      !!isSubscriber,
      calculator.titulo,
    );
    dispatch(setCalculatorView(calculator));
  };

  const onSearch = (
    e: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <CalculatorDrawerHeader title="Calculadoras" onClose={onDrawerClose} />
      <ErrorBoundary
        isError={calculatorError}
        buttonText="Tentar novamente"
        message="Parece que estamos com problemas para carregar sua solicitação. Estamos trabalhando para a normalização."
        isButtonRetryVisible
        onRetry={onFetchCalculators}
      >
        {isCalculatorLoading ? (
          <LoadingContainer>
            <CircularProgress size="xl" label="Carregando Calculadoras" />
          </LoadingContainer>
        ) : (
          <>
            <SearchContainer>
              <TextField
                InputProps={{
                  startAdornment: <SearchOutlined />,
                  placeholder: 'Busque por palavras-chave',
                  onChange: onSearch,
                }}
                variant="filled"
                label="Pesquisar"
              />
            </SearchContainer>
            <ListContainer>
              <List>
                {!calculatorsFilteredByCategory.length && (
                  <CalculatorDrawerEmptyContainer>
                    <img
                      className="image"
                      src={images.records.calculatorEmpty}
                      alt="Lista vazia de calculadoras"
                    />
                    <Body variant="xs">
                      Poxa! Não encontramos o que você procura. Tente uma nova
                      busca ou verifique se está tudo certo na digitação.
                    </Body>
                  </CalculatorDrawerEmptyContainer>
                )}

                {calculatorsFilteredByCategory.map((calculatorCategory) => (
                  <>
                    <ListItem
                      button
                      disableRipple
                      onClick={() =>
                        handleClickCategory(calculatorCategory.nomeCategoria)
                      }
                      selected={
                        categoryOpen === calculatorCategory.nomeCategoria
                      }
                    >
                      <ListItemText
                        primary={calculatorCategory.nomeCategoria}
                      />
                      {categoryOpen === calculatorCategory.nomeCategoria ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItem>
                    <Collapse
                      in={categoryOpen === calculatorCategory.nomeCategoria}
                    >
                      <List>
                        {calculatorCategory.conteudos.map((calculator) => (
                          <ListItemNested
                            button
                            disableRipple
                            key={calculator.id}
                            onClick={() => onSelectedCalculator(calculator)}
                          >
                            <ListItemText primary={calculator.titulo} />
                          </ListItemNested>
                        ))}
                      </List>
                    </Collapse>
                  </>
                ))}
              </List>
            </ListContainer>
          </>
        )}
      </ErrorBoundary>
    </>
  );
};
