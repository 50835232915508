// External
import { all, call, takeEvery, put, select, take } from 'redux-saga/effects';
import { Modifier } from 'draft-js';

// Internal
import history from 'routes/history';
import notificationMessages from 'shared/constants/notificationMessages';
import iclinic from 'services/iclinic';
import { routePath } from 'shared/utils/strings';
import { emitErrors } from 'shared/utils/emitErrors';
import { close } from 'state/modal/actions';
import { typeStyleEditor } from 'ui/new/editor/constants';
import {
  ADD_CAMPAIGN,
  DELETE_CAMPAIGN,
  DELETE_SELECTED_CAMPAIGN,
  FETCH_LIST,
  FETCH_CREATE,
  FETCH_UPDATE,
  FETCH_DELETE,
  DUPLICATE_CAMPAIGN,
  FETCH_DETAILS,
  EDIT_CAMPAIGN,
  EDITOR_TEXT,
  FETCH_USER_PROFILES,
  FETCH_PATIENT_COUNT,
  FETCH_PROCEDURES,
  FETCH_PATIENT_TAGS,
  CREATE_CAMPAIGN,
} from './types';
import {
  cardsName,
  CampaignStatus,
  AudienceSummary,
  AudienceFilter,
  CampaignSendingNotification,
} from './constants';
import {
  getAddCampaignModalTitle,
  getDetailsCampaigns,
  getEditCampaignValues,
  getEditorUpdatedMail,
  getCurrentEntityKey,
  getEditorSelection,
  getCurrentContentEditor,
  getCurrentEntityDecoratedMerge,
  getCurrentSelectionText,
  getEditorState,
  getCreatedCampaign,
  getCurrentEntity,
  getDateScheduleEmail,
  getInitialMail,
} from './selectors';
import {
  fetchCampaignDetails,
  fetchCreateCampaign,
  fetchListCampaigns,
  fetchDeleteCampaign,
  fetchUpdateCampaign,
  fetchListCampaignsSuccess,
  fetchListCampaignsFailure,
  fetchCampaignDetailsSuccess,
  fetchCampaignDetailsFailure,
  fetchCreateCampaignSuccess,
  fetchCreateCampaignFailure,
  fetchUpdateCampaignSuccess,
  fetchUpdateCampaignFailure,
  fetchDeleteCampaignSuccess,
  fetchDeleteCampaignFailure,
  setSelected,
  setActiveCard,
  updateEntityLink,
  addEntityLinkTextSelected,
  addEntityLinkEmptyText,
  addEntityImage,
  fetchUserProfiles,
  fetchUserProfilesSuccess,
  fetchUserProfilesFailure,
  fetchPatientCountSuccess,
  fetchPatientCountFailure,
  fetchProceduresSuccess,
  fetchProceduresFailure,
  fetchPatientTagsSuccess,
  fetchPatientTagsFailure,
  fetchSendTestEmailFailure,
  fetchSendTestEmailSuccess,
  createCampaign,
  fetchCampaignDetailsClear,
  forceSelection,
} from './actions';

const ROUTER_PATH = '/app/marketing';

export function* workerListCampaigns() {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchListCampaigns,
    );
    if (errors) {
      yield put(fetchListCampaignsFailure(emitErrors(errors)));
    } else {
      const payload = getResponseData();
      const campaigns = payload.map(iclinic.campaign.decodeCampaign);
      yield put(fetchListCampaignsSuccess(campaigns));
    }
  } catch (error) {
    yield put(fetchListCampaignsFailure(emitErrors([error])));
  }
}

export function* workerUserProfiles() {
  try {
    let fullList = [];
    let url;
    do {
      const { getResponseData, errors } = yield call(
        iclinic.campaign.fetchUserProfiles,
        url,
      );
      if (errors) {
        yield put(fetchUserProfilesFailure(emitErrors(errors)));
        return;
      }

      const payload = getResponseData();
      fullList = fullList.concat(
        payload.objects.map(iclinic.campaign.decodeUserProfile),
      );
      url = payload.meta.next ? payload.meta.next.replace('v2/', '') : null;
    } while (url);
    yield put(fetchUserProfilesSuccess(fullList));
  } catch (error) {
    yield put(fetchUserProfilesFailure(emitErrors([error])));
  }
}

export function* workerPatientCount({ params: { clinicId, audiences } }) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchPatientCount,
      clinicId,
      audiences,
    );

    if (errors) {
      yield put(fetchPatientCountFailure(emitErrors(errors)));
    } else {
      const payload = getResponseData();
      const { count } = payload;
      yield put(fetchPatientCountSuccess(count));
    }
  } catch (error) {
    yield put(fetchPatientCountFailure(emitErrors([error])));
  }
}

export function* workerListProceduresLabels() {
  try {
    let fullList = [];
    let url;
    do {
      const { getResponseData, errors } = yield call(
        iclinic.campaign.fetchProcedures,
        url,
      );
      if (errors) {
        yield put(fetchProceduresFailure(emitErrors(errors)));
        return;
      }
      const payload = getResponseData();
      fullList = fullList.concat(
        payload.objects.map(iclinic.campaign.decodeProcedure),
      );
      url = payload.meta.next ? payload.meta.next.replace('v2/', '') : null;
    } while (url);
    yield put(fetchProceduresSuccess(fullList));
  } catch (error) {
    yield put(fetchProceduresFailure(emitErrors([error])));
  }
}

export function* workerPatientTags({ params }) {
  try {
    const { clinicId } = params;
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchPatientTags,
      clinicId,
    );
    if (errors) {
      yield put(fetchPatientTagsFailure(emitErrors(errors), params));
    } else {
      const payload = getResponseData();
      yield put(fetchPatientTagsSuccess(payload, params));
    }
  } catch (error) {
    yield put(fetchPatientTagsFailure(emitErrors([error]), params));
  }
}

export function* workerCampaignDetails({ params }) {
  try {
    const { campaignId } = params;
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchCampaign,
      campaignId,
    );
    if (errors) {
      yield put(fetchCampaignDetailsFailure(emitErrors(errors), params));
    } else {
      const payload = getResponseData();
      const campaign = iclinic.campaign.decodeCampaign(payload);
      yield put(fetchCampaignDetailsSuccess(campaign, params));
    }
  } catch (error) {
    yield put(fetchCampaignDetailsFailure(emitErrors([error]), params));
  }
}

export function* workerCreateCampaign({ params }) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchCreateCampaign,
      params,
    );
    if (errors) {
      yield put(fetchCreateCampaignFailure(emitErrors(errors), params));
    } else {
      const payload = getResponseData();
      const campaign = iclinic.campaign.decodeCampaign(payload);
      yield put(fetchCreateCampaignSuccess(campaign, params));
    }
  } catch (error) {
    yield put(fetchCreateCampaignFailure(emitErrors([error]), params));
  }
}

export function* workerUpdateCampaign({ params }) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchUpdateCampaign,
      params,
    );
    if (errors) {
      yield put(fetchUpdateCampaignFailure(emitErrors(errors), params));
    } else {
      const payload = getResponseData();
      const campaign = iclinic.campaign.decodeCampaign(payload);
      const notification = CampaignSendingNotification[campaign.status];
      const notificationMessage = notificationMessages[notification];
      const notificationPayload = notificationMessage
        ? [notificationMessage]
        : [];
      yield put(
        fetchUpdateCampaignSuccess(campaign, params, notificationPayload),
      );
    }
  } catch (error) {
    yield put(fetchUpdateCampaignFailure(emitErrors([error]), params));
  }
}

export function* workerDeleteCampaign({ params }) {
  try {
    const campaignId = typeof params === 'object' ? params.id : params;
    const { errors } = yield call(
      iclinic.campaign.fetchDeleteCampaign,
      campaignId,
    );
    if (errors) {
      yield put(fetchDeleteCampaignFailure(emitErrors(errors), params));
    } else {
      yield put(fetchDeleteCampaignSuccess(params));
    }
  } catch (error) {
    yield put(fetchDeleteCampaignFailure(emitErrors([error]), params));
  }
}

export function* duplicateCampaignSaga({ params: { campaignId } }) {
  const title = yield select(getAddCampaignModalTitle);
  const status = CampaignStatus.draft;
  const schedule = null;
  yield put(fetchCampaignDetails(campaignId));
  const fetchDetailsAction = yield take([
    FETCH_DETAILS.SUCCESS,
    FETCH_DETAILS.FAILURE,
  ]);
  if (fetchDetailsAction.type === FETCH_DETAILS.SUCCESS) {
    const { audiences, mail } = yield select(getDetailsCampaigns);
    yield put(
      fetchCreateCampaign({
        title,
        status,
        schedule,
        audiences,
        mail,
      }),
    );
    const createCampaignAction = yield take([
      FETCH_CREATE.SUCCESS,
      FETCH_CREATE.FAILURE,
    ]);
    if (createCampaignAction.type === FETCH_CREATE.SUCCESS) {
      const { id } = yield select(getCreatedCampaign);
      yield put(createCampaign(id));
    }
  }
  yield put(close());
}

export function* workerSendTestEmail({ params }) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchSendTestEmail,
      params,
    );
    if (errors) {
      yield put(fetchSendTestEmailFailure(emitErrors(errors), params));
    } else {
      const payload = getResponseData();
      const campaign = iclinic.campaign.decodeCampaign(payload);
      yield put(fetchSendTestEmailSuccess(campaign, params));
    }
  } catch (error) {
    yield put(fetchSendTestEmailFailure(emitErrors([error]), params));
  }
}

export function* createCampaignSaga({ payload: { id } }) {
  const isNew = true;
  yield call([history, history.push], {
    pathname: routePath(ROUTER_PATH, 'marketing', `campanhas/${id}`),
    state: { isNew },
  });
}

export function* editCampaignSaga({ payload: { id, status } }) {
  const isNew = false;
  if (status === CampaignStatus.sent) {
    const error = {
      code: 500504,
    };

    yield put(fetchListCampaignsFailure(emitErrors([error])));
    return;
  }
  yield call([history, history.push], {
    pathname: routePath(ROUTER_PATH, 'marketing', `campanhas/${id}`),
    state: { isNew },
  });
}

export function* cancelEditCampaignSaga() {
  yield call(
    [history, history.push],
    routePath(ROUTER_PATH, 'marketing', 'campanhas'),
  );
  yield put(fetchCampaignDetailsClear());
  yield put(setActiveCard(cardsName.audience));
}

export function* getValidInitialMail() {
  const mail = yield select(getInitialMail);
  if (mail && mail.sender && mail.sender.email) {
    return mail;
  }
  yield put(fetchUserProfiles());
  const action = yield take([
    FETCH_USER_PROFILES.SUCCESS,
    FETCH_USER_PROFILES.FAILURE,
  ]);
  if (action.type === FETCH_USER_PROFILES.SUCCESS) {
    return yield select(getInitialMail);
  }
  return null;
}

export function* addCampaignSaga() {
  const title = yield select(getAddCampaignModalTitle);
  const mail = yield call(getValidInitialMail);
  if (mail) {
    yield put(fetchCreateCampaign({ title, mail }));
    const action = yield take([FETCH_CREATE.SUCCESS, FETCH_CREATE.FAILURE]);
    if (action.type === FETCH_CREATE.SUCCESS) {
      yield put(close());
      const { id } = yield select(getCreatedCampaign);
      yield put(createCampaign(id));
    }
  } else {
    const failureLoadSenders = {
      code: 400909,
    };

    yield put(fetchCreateCampaignFailure(emitErrors([failureLoadSenders])));
  }
}

export function* deleteCampaignSaga({ payload: { id } }) {
  yield put(fetchDeleteCampaign(id));
  const action = yield take([FETCH_DELETE.SUCCESS, FETCH_DELETE.FAILURE]);
  if (action.type === FETCH_DELETE.SUCCESS) {
    yield put(close());
  }
  yield put(fetchListCampaigns());
}

export function* deleteAndWait(id) {
  yield put(fetchDeleteCampaign(id));
  let action;
  do {
    action = yield take([FETCH_DELETE.SUCCESS, FETCH_DELETE.FAILURE]);
  } while (action.params !== id);
}

export function* deleteSelectedCampaignsSaga({ payload: { campaigns } }) {
  const selected = Array.from(campaigns);
  const generators = selected.map((id) => call(deleteAndWait, id));
  yield all(generators);
  yield put(close());
  yield put(setSelected(new Set()));
  yield put(fetchListCampaigns());
}

const isAllAudiences = (formFields) =>
  formFields.audienceSummary === AudienceSummary.all ||
  !formFields.audiences ||
  formFields.audiences.length === 0;

export function* saveEditCampaignSaga() {
  const campaignDetails = yield select(getDetailsCampaigns);
  const formFields = yield select(getEditCampaignValues);
  const mail = yield select(getEditorUpdatedMail);
  const updateFields = {
    id: campaignDetails.id,
    title: formFields.title,
    mail,
    audiences: isAllAudiences(formFields)
      ? [{ filter: AudienceFilter.all }]
      : formFields.audiences.filter(
          ({ filter }) => filter !== AudienceSummary.all,
        ),
  };
  yield put(fetchUpdateCampaign(updateFields));
  const action = yield take([FETCH_UPDATE.SUCCESS, FETCH_UPDATE.FAILURE]);
  if (action.type === FETCH_UPDATE.SUCCESS) {
    yield call(
      [history, history.push],
      routePath(ROUTER_PATH, 'marketing', 'campanhas'),
    );
    yield put(setActiveCard(cardsName.audience));
  }
}

export function* editCampaignSaveAudienceSaga() {
  const campaignDetails = yield select(getDetailsCampaigns);
  const formFields = yield select(getEditCampaignValues);
  const updateFields = {
    id: campaignDetails.id,
    audiences: isAllAudiences(formFields)
      ? [{ filter: AudienceFilter.all }]
      : formFields.audiences.filter(
          ({ filter }) => filter !== AudienceSummary.all,
        ),
  };
  yield put(fetchUpdateCampaign(updateFields));
  const action = yield take([FETCH_UPDATE.SUCCESS, FETCH_UPDATE.FAILURE]);
  if (action.type === FETCH_UPDATE.SUCCESS) {
    yield put(setActiveCard(cardsName.mail));
  }
}

export function* editCampaignSaveMailSaga() {
  const campaignDetails = yield select(getDetailsCampaigns);
  const mail = yield select(getEditorUpdatedMail);
  const updateFields = {
    id: campaignDetails.id,
    mail,
  };
  yield put(fetchUpdateCampaign(updateFields));
  const action = yield take([FETCH_UPDATE.SUCCESS, FETCH_UPDATE.FAILURE]);
  if (action.type === FETCH_UPDATE.SUCCESS) {
    yield put(setActiveCard(cardsName.finalize));
  }
}

export function* editCampaignFinalizeSaga() {
  const campaignDetails = yield select(getDetailsCampaigns);
  const formFields = yield select(getEditCampaignValues);
  const mail = yield select(getEditorUpdatedMail);
  const schedule = yield select(getDateScheduleEmail);
  const updateFields = {
    id: campaignDetails.id,
    title: formFields.title,
    mail,
    status: formFields.status,
    schedule,
  };

  yield put(fetchUpdateCampaign(updateFields));
  const action = yield take([FETCH_UPDATE.SUCCESS, FETCH_UPDATE.FAILURE]);
  if (action.type === FETCH_UPDATE.SUCCESS) {
    yield put(setActiveCard(cardsName.audience));
    yield call(
      [history, history.push],
      routePath(ROUTER_PATH, 'marketing', 'campanhas'),
    );
  }
}

export function* editCampaignScheduleEmailSaga() {
  const campaignDetails = yield select(getDetailsCampaigns);
  const formFields = yield select(getEditCampaignValues);
  const mail = yield select(getEditorUpdatedMail);
  const schedule = yield select(getDateScheduleEmail);
  const updateFields = {
    id: campaignDetails.id,
    title: formFields.title,
    mail,
    status: formFields.status,
    schedule,
  };

  yield put(fetchUpdateCampaign(updateFields));
  const action = yield take([FETCH_UPDATE.SUCCESS, FETCH_UPDATE.FAILURE]);
  if (action.type === FETCH_UPDATE.SUCCESS) {
    yield put(close());
    yield call(
      [history, history.push],
      routePath(ROUTER_PATH, 'marketing', 'campanhas'),
    );
  }
}

export function* editCampaignSendEmailNowSaga() {
  const campaignDetails = yield select(getDetailsCampaigns);
  const formFields = yield select(getEditCampaignValues);
  const mail = yield select(getEditorUpdatedMail);
  const updateFields = {
    id: campaignDetails.id,
    title: formFields.title,
    mail,
    status: CampaignStatus.sent,
  };
  yield put(fetchUpdateCampaign(updateFields));
  const action = yield take([FETCH_UPDATE.SUCCESS, FETCH_UPDATE.FAILURE]);
  if (action.type === FETCH_UPDATE.SUCCESS) {
    yield put(close());
    yield call(
      [history, history.push],
      routePath(ROUTER_PATH, 'marketing', 'campanhas'),
    );
  }
}

export function* editCampaignSendTestEmailSaga({
  payload: {
    sendTest: { email, physicianId },
  },
}) {
  const campaignDetails = yield select(getDetailsCampaigns);
  const formFields = yield select(getEditCampaignValues);
  const mail = yield select(getEditorUpdatedMail);
  const updateFields = {
    id: campaignDetails.id,
    title: formFields.title,
    mail,
  };
  yield put(fetchUpdateCampaign(updateFields));
  const action = yield take([FETCH_UPDATE.SUCCESS, FETCH_UPDATE.FAILURE]);
  if (action.type === FETCH_UPDATE.SUCCESS) {
    yield call(workerSendTestEmail, {
      params: {
        campaignId: campaignDetails.id,
        physicianId,
        recipients: [email],
      },
    });
    yield put(close());
    yield call(
      [history, history.push],
      routePath(ROUTER_PATH, 'marketing', 'campanhas'),
    );
  }
}

export function* onModalEditorLinkSubmitSaga({
  payload: { url, textSelected },
}) {
  const currentEntityKey = yield select(getCurrentEntityKey);
  const currentSelection = yield select(getEditorSelection);
  const currentContent = yield select(getCurrentContentEditor);
  const editorState = yield select(getEditorState);

  if (currentEntityKey) {
    const currentDecoratedMerge = yield select(getCurrentEntityDecoratedMerge);
    const replaceEntityData = currentContent.replaceEntityData(
      currentEntityKey,
      { url },
    );
    const contentUpgrade = Modifier.replaceText(
      replaceEntityData,
      currentDecoratedMerge,
      textSelected,
      null,
      currentEntityKey,
    );
    yield put(updateEntityLink(editorState, contentUpgrade));
  } else {
    const contentWithEntity = currentContent.createEntity(
      typeStyleEditor.link,
      typeStyleEditor.mutable,
      {
        url,
      },
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();

    const selectionText = yield select(getCurrentSelectionText);
    if (!selectionText) {
      yield put(
        addEntityLinkEmptyText(
          editorState,
          currentSelection,
          currentContent,
          entityKey,
          textSelected,
        ),
      );
    } else {
      yield put(
        addEntityLinkTextSelected(
          editorState,
          currentSelection,
          contentWithEntity,
          entityKey,
        ),
      );
    }
  }
  yield put(close());
}

// NOTE: button uses same 'Link' actions as they are very similar, just different entity key
export function* onModalEditorButtonSubmitSaga({
  payload: { url, textSelected },
}) {
  const currentEntityKey = yield select(getCurrentEntityKey);
  const currentSelection = yield select(getEditorSelection);
  const currentContent = yield select(getCurrentContentEditor);
  const editorState = yield select(getEditorState);

  if (currentEntityKey) {
    const currentDecoratedMerge = yield select(getCurrentEntityDecoratedMerge);
    const replaceEntityData = currentContent.replaceEntityData(
      currentEntityKey,
      { url },
    );
    const contentUpgrade = Modifier.replaceText(
      replaceEntityData,
      currentDecoratedMerge,
      textSelected,
      null,
      currentEntityKey,
    );
    yield put(updateEntityLink(editorState, contentUpgrade));
  } else {
    const contentWithEntity = currentContent.createEntity(
      typeStyleEditor.button,
      typeStyleEditor.mutable,
      {
        url,
      },
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();

    const selectionText = yield select(getCurrentSelectionText);
    if (!selectionText) {
      yield put(
        addEntityLinkEmptyText(
          editorState,
          currentSelection,
          currentContent,
          entityKey,
          textSelected,
        ),
      );
    } else {
      yield put(
        addEntityLinkTextSelected(
          editorState,
          currentSelection,
          contentWithEntity,
          entityKey,
        ),
      );
    }
  }
  yield put(close());
}

export function* onAutomatedTagChosenSaga({ payload: { automatedTag } }) {
  const currentEntity = yield select(getCurrentEntity);
  const currentEntityKey = yield select(getCurrentEntityKey);
  const editorState = yield select(getEditorState);
  const getTypeEntity = currentEntityKey && currentEntity.getType();
  let currentSelection = yield select(getEditorSelection);
  let currentContent = yield select(getCurrentContentEditor);

  if (getTypeEntity === typeStyleEditor.automatedTag) {
    const currentDecoratedMerge = yield select(getCurrentEntityDecoratedMerge);
    const contentWithoutEntity = Modifier.applyEntity(
      currentContent,
      currentSelection,
      null,
    );
    currentContent = Modifier.removeRange(
      contentWithoutEntity,
      currentDecoratedMerge,
      'backward',
    );
    currentSelection = currentSelection.merge({
      anchorOffset: currentDecoratedMerge.getAnchorOffset(),
      focusOffset: currentDecoratedMerge.getAnchorOffset(),
    });

    yield put(updateEntityLink(editorState, currentContent));
  }

  const contentWithEntity = currentContent.createEntity(
    typeStyleEditor.automatedTag,
    'IMMUTABLE',
    { automatedTag },
  );
  const entityKey = contentWithEntity.getLastCreatedEntityKey();

  const selectionText = yield select(getCurrentSelectionText);
  if (!selectionText) {
    yield put(
      addEntityLinkEmptyText(
        editorState,
        currentSelection,
        currentContent,
        entityKey,
        automatedTag,
      ),
    );
  }

  const newEditorState = yield select(getEditorState);
  yield put(forceSelection(newEditorState));
}

export function* onModalEditorImageSubmitSaga({ payload: { url } }) {
  const currentContent = yield select(getCurrentContentEditor);
  const editorState = yield select(getEditorState);

  const contentWithEntity = currentContent.createEntity(
    typeStyleEditor.image,
    'IMMUTABLE',
    { src: url, width: 'auto' },
  );
  const entityKey = contentWithEntity.getLastCreatedEntityKey();

  yield put(addEntityImage(editorState, contentWithEntity, entityKey));
  yield put(close());
}

export default function* campaignSagas() {
  yield all([
    takeEvery(FETCH_LIST.ACTION, workerListCampaigns),
    takeEvery(FETCH_DETAILS.ACTION, workerCampaignDetails),
    takeEvery(FETCH_CREATE.ACTION, workerCreateCampaign),
    takeEvery(FETCH_UPDATE.ACTION, workerUpdateCampaign),
    takeEvery(FETCH_DELETE.ACTION, workerDeleteCampaign),
    takeEvery(DUPLICATE_CAMPAIGN.ACTION, duplicateCampaignSaga),
    takeEvery(ADD_CAMPAIGN.ACTION, addCampaignSaga),
    takeEvery(DELETE_CAMPAIGN.ACTION, deleteCampaignSaga),
    takeEvery(DELETE_SELECTED_CAMPAIGN.ACTION, deleteSelectedCampaignsSaga),
    takeEvery(EDIT_CAMPAIGN.SAVE, saveEditCampaignSaga),
    takeEvery(EDIT_CAMPAIGN.CANCEL, cancelEditCampaignSaga),
    takeEvery(EDIT_CAMPAIGN.ACTION, editCampaignSaga),
    takeEvery(EDIT_CAMPAIGN.SAVE_AUDIENCE, editCampaignSaveAudienceSaga),
    takeEvery(EDIT_CAMPAIGN.SAVE_MAIL, editCampaignSaveMailSaga),
    takeEvery(EDIT_CAMPAIGN.FINALIZE, editCampaignFinalizeSaga),
    takeEvery(EDIT_CAMPAIGN.SCHEDULE_EMAIL, editCampaignScheduleEmailSaga),
    takeEvery(EDIT_CAMPAIGN.SEND_EMAIL_NOW, editCampaignSendEmailNowSaga),
    takeEvery(EDIT_CAMPAIGN.SEND_TEST_EMAIL, editCampaignSendTestEmailSaga),
    takeEvery(EDITOR_TEXT.MODAL_LINK_SUBMIT, onModalEditorLinkSubmitSaga),
    takeEvery(EDITOR_TEXT.MODAL_BUTTON_SUBMIT, onModalEditorButtonSubmitSaga),
    takeEvery(EDITOR_TEXT.AUTOMATED_TAG, onAutomatedTagChosenSaga),
    takeEvery(EDITOR_TEXT.MODAL_IMAGE_SUBMIT, onModalEditorImageSubmitSaga),
    takeEvery(FETCH_USER_PROFILES.ACTION, workerUserProfiles),
    takeEvery(FETCH_PATIENT_COUNT.ACTION, workerPatientCount),
    takeEvery(FETCH_PROCEDURES.ACTION, workerListProceduresLabels),
    takeEvery(FETCH_PATIENT_TAGS.ACTION, workerPatientTags),
    takeEvery(CREATE_CAMPAIGN.ACTION, createCampaignSaga),
  ]);
}
