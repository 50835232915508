import { LocalVideoTrack } from 'twilio-video';
import { useCallback, useState } from 'react';

import { useConference } from 'features/video-conference/state/conferenceContext';

export default function useLocalVideoToggle() {
  const {
    room,
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    onError,
  } = useConference();
  const localParticipant = room?.localParticipant;
  const videoTrack = localTracks.find(
    (track) => track.kind === 'video',
  ) as LocalVideoTrack;
  const [isPublishing, setIspublishing] = useState(false);
  const isVideoEnabled = !!videoTrack;

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication = localParticipant?.unpublishTrack(
          videoTrack,
        );
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setIspublishing(true);
        getLocalVideoTrack()
          .then((track: LocalVideoTrack) =>
            localParticipant?.publishTrack(track),
          )
          .catch(onError)
          .finally(() => {
            setIspublishing(false);
          });
      }
    }
  }, [
    isPublishing,
    videoTrack,
    localParticipant,
    removeLocalVideoTrack,
    getLocalVideoTrack,
    onError,
  ]);

  return { isVideoEnabled, toggleVideoEnabled };
}
