// External dependencies
import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Routes
import Marketing from './Routes';

const Root = () => (
  <Switch>
    <Route path="/marketing" component={Marketing} />
  </Switch>
);

export default Root;
