// External
import React, { useEffect, useMemo, useCallback } from 'react';
import { Panel } from '@iclinic/iclinic-ui';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

// Internal
import NotificationExpireMessage from 'shared/components/NotificationExpireMessage';
import { FORM_LIST_EMAIL_FLOW_RETURN } from 'shared/constants/forms';
import { StoreState } from 'state/rootReducer';
import {
  fetchListEmailFlow,
  activeEmailFlow,
  editEmailFlow,
  clearErrorsComponent,
  setActiveEmailFlowCard,
} from 'state/marketing/emailFlow/emailFlowManagement/actions';

import {
  fetchProfessionals,
  setSelectedProfessionalById,
} from 'state/marketing/emailFlow/professional/actions';

import { emailFlowInformation } from 'state/marketing/emailFlow/emailFlowManagement/constants';
import {
  EmailFlow,
  EmailFlowActionsReturn,
  EmailFlowTarget,
  EmailFlowError,
} from 'state/marketing/emailFlow/emailFlowManagement/emailFlowTypes';
import * as selectors from 'state/marketing/emailFlow/emailFlowManagement/selectors';
import * as selectorsProfessional from 'state/marketing/emailFlow/professional/selectors';
import { Professional } from 'state/marketing/emailFlow/professional/types';
import useWidthUpdater from 'shared/hooks/useWidthUpdater';
import ListEmailFlow from '../components/ListEmailFlow';
import ListEmailFlowMobile from '../components/ListEmailFlowMobile';
import ListEmailFlowItem from '../components/ListEmailFlowItem';
import ListEmailFlowItemMobile from '../components/ListEmailFlowItemMobile';
import DisplayPhysicians from '../components/DisplayPhysicians';

import style from './ListEmailFlow.scss';

interface StateProps {
  emailFlowsReturn?: EmailFlow[];
  professionals: Professional[];
  selectedProfessional: Professional | undefined;
  errors: EmailFlowError[];
  activeEmailFlowCard: string | undefined;
}

interface DispatchProps {
  listEmailFlow: (professional: Professional) => EmailFlowActionsReturn;
  listProfessionals: () => void;
  setProfessionalById: (id: number) => void;
  setActiveEmailFlow: (
    id: React.ReactText,
    active: boolean,
  ) => EmailFlowActionsReturn;
  updateEmailFlow: (emailFlow: EmailFlow) => EmailFlowActionsReturn;
  clearErrors: () => void;
  setActiveFlowCard: (id: string) => void;
}

interface ListEmailFlowsProps extends StateProps, DispatchProps {}

const ListEmailFlows = ({
  listEmailFlow,
  listProfessionals,
  setProfessionalById,
  setActiveEmailFlow,
  emailFlowsReturn,
  updateEmailFlow,
  professionals,
  selectedProfessional,
  errors,
  clearErrors,
  activeEmailFlowCard,
  setActiveFlowCard,
}: ListEmailFlowsProps): JSX.Element => {
  const isMobile = useWidthUpdater();
  const ListComponent = isMobile ? ListEmailFlowMobile : ListEmailFlow;
  const ItemComponent = isMobile ? ListEmailFlowItemMobile : ListEmailFlowItem;

  useEffect(() => {
    listProfessionals();
  }, [listProfessionals]);

  useEffect(() => {
    if (selectedProfessional) {
      listEmailFlow(selectedProfessional);
    }
  }, [listEmailFlow, selectedProfessional]);

  const onChange = useCallback(
    (e: EmailFlowTarget) => {
      setProfessionalById(Number(e.target.value));
    },
    [setProfessionalById],
  );

  const titleSelectedPhysicians: JSX.Element = useMemo(
    () => (
      <DisplayPhysicians professionals={professionals} onChange={onChange} />
    ),
    [professionals, onChange],
  );

  const changeStatusEmailFlow = useCallback(
    (e: EmailFlowTarget) => {
      const { checked, value } = e.target;
      setActiveEmailFlow(value, checked);
    },
    [setActiveEmailFlow],
  );

  const notificationErrors: JSX.Element[] = React.useMemo(
    () =>
      errors.map((item) => (
        <NotificationExpireMessage
          kind="error"
          title="Sequência automáticas de e-mails"
          showCloseButton={false}
          key={item.id}
          onExpire={clearErrors}
          expireAfter="3000"
        >
          {item.message}
        </NotificationExpireMessage>
      )),
    [clearErrors, errors],
  );

  return (
    <div className={style['flow-list-panel']}>
      {notificationErrors}
      <Panel title={titleSelectedPhysicians} />
      <div className={style['title-email-flow-return']}>
        <span>Lembretes de retorno</span>
        {isMobile && (
          <div className={style['email-flow-list-text']}>
            {emailFlowInformation.return}
          </div>
        )}
      </div>
      <ListComponent emailFlowInformation={emailFlowInformation.return}>
        {emailFlowsReturn &&
          emailFlowsReturn.map((item: EmailFlow) => (
            <ItemComponent
              emailFlow={item}
              key={item.id}
              changeStatusEmailFlow={changeStatusEmailFlow}
              editEmailFlow={updateEmailFlow}
              activeEmailFlowCard={isMobile ? activeEmailFlowCard : undefined}
              setActiveFlowCard={isMobile ? setActiveFlowCard : undefined}
            />
          ))}
      </ListComponent>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  initialValues: selectorsProfessional.getSelectedProfessional(state),
  emailFlowsReturn: selectors.getEmailFlowListForDisplay(state),
  professionals: selectorsProfessional.getProfessionals(state),
  selectedProfessional: selectorsProfessional.getProfessionalSelected(state),
  errors: selectors.getErrorsComponent(state),
  activeEmailFlowCard: selectors.getActiveEmailFlowCard(state),
});

const mapDispatchToProps: DispatchProps = {
  listEmailFlow: fetchListEmailFlow,
  listProfessionals: fetchProfessionals,
  setProfessionalById: setSelectedProfessionalById,
  setActiveEmailFlow: activeEmailFlow,
  updateEmailFlow: editEmailFlow,
  clearErrors: clearErrorsComponent,
  setActiveFlowCard: setActiveEmailFlowCard,
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: FORM_LIST_EMAIL_FLOW_RETURN,
    enableReinitialize: true,
  })(ListEmailFlows),
);
