import { createSelector } from 'reselect';

import { formatNumberToDecimal, totalUsersMessage } from 'shared/utils/strings';
import { MESSAGE_BLOCKED_ACCOUNT, MESSAGE_PAYMENT_ATTEMPT } from './constants';

const getProductsDetailInfo = (state) => state.creditcard.productsDetail;
export const getCreditCard = (state) => state.creditcard;

export const getProductsDetail = createSelector(
  getProductsDetailInfo,
  (productsDetail) => {
    if (productsDetail) {
      const totalPrice = parseFloat(productsDetail.totalPrice);
      const totalPriceFormatted = formatNumberToDecimal(totalPrice);
      const { totalUsers } = productsDetail;
      const totalMessage = totalUsersMessage(totalPriceFormatted, totalUsers);
      const period = productsDetail.isMonthly ? 'mês' : 'ano';
      return {
        productsNames: productsDetail.productsNames,
        totalUsers,
        totalPrice,
        totalPriceFormatted,
        period,
        totalMessage,
      };
    }
    return productsDetail;
  },
);

export const getWarningMessage = createSelector(getCreditCard, (creditcard) => {
  const { isAccountBlocked, hasPaymentAttempt, finalNumber } = creditcard;
  if (isAccountBlocked) {
    return MESSAGE_BLOCKED_ACCOUNT;
  }

  if (hasPaymentAttempt && finalNumber) {
    return MESSAGE_PAYMENT_ATTEMPT(finalNumber);
  }

  return null;
});
