import React from 'react';

import { MaterialUICore, Body, Button } from '@iclinic/design-system';
import { RECORD_PRICE_WARNING } from 'features/video-conference/constants/index';
import { Dialog, Paper, ActionsWrapper } from './styles';

const { Backdrop, Fade } = MaterialUICore;

type Props = {
  isOpen: boolean;
  onAccept: () => void;
  onBack: () => void;
  onClose: () => void;
};

const DialogConfirmPriceRecord = ({
  isOpen = false,
  onAccept,
  onBack,
  onClose,
}: Props): JSX.Element => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Paper>
          <Body variant="xs">{RECORD_PRICE_WARNING}</Body>
          <ActionsWrapper>
            <Button color="transparent" onClick={onBack}>
              Voltar
            </Button>
            <Button color="primary" size="medium" onClick={onAccept}>
              Aceitar
            </Button>
          </ActionsWrapper>
        </Paper>
      </Fade>
    </Dialog>
  );
};

export default DialogConfirmPriceRecord;
