import React from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  MaterialIcons,
} from '@iclinic/design-system';
import { useStyles } from './styles';
import validationSchema, { initialValues, InitialValues } from './schema';
import BottomButtonContainer from '../BottomButtonContainer';
import { validateEmailIntegration } from 'features/settings/scheduleOnline/state/actions';
import { getSettingsIntegration } from 'features/settings/scheduleOnline/state/selectors';

const { Email } = MaterialIcons;

export const EmailValidation = () => {
  const dispatch = useDispatch();
  const integrationData = useSelector(getSettingsIntegration);
  const { inputAdornment, inputLabel } = useStyles();

  const handleFormSubmit = (values: InitialValues) => {
    dispatch(validateEmailIntegration(values.email));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
  } = formik;

  return (
    <>
      <Typography variant="h4" id="integration-modal-title">
        Ativar agenda online
      </Typography>
      <Box mt={1}>
        <Typography variant="subtitle1">
          Digite o email que deseja utilizar na sua conta do Agendar Consulta.
        </Typography>
      </Box>

      <form onSubmit={handleSubmit}>
        <Box mt={3} mb={3} width={328}>
          <TextField
            name="email"
            type="email"
            fullWidth
            label="E-mail"
            placeholder="Digite seu email"
            error={!!(errors.email && touched.email)}
            helperText={touched.email && errors.email ? errors.email : null}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={inputAdornment}>
                  <Email />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              className: inputLabel,
            }}
          />
        </Box>
        <BottomButtonContainer
          isLoading={integrationData.isLoading}
          leftLabel="Cancelar"
          rightLabel="Avançar"
          submit
        />
      </form>
    </>
  );
};
