import { Heading, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { spacing } = tokens;

export const Root = styled('div')(({ theme }) => ({
  padding: spacing.sm,

  [theme.breakpoints.up('md')]: {
    padding: spacing.lg,
  },
}));

export const PageTitle = styled(Heading)(() => ({
  marginBottom: spacing.base,
}));

export const FiltersWrapper = styled('div')(({ theme }) => ({
  marginTop: spacing.md,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}));
