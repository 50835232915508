/* eslint-disable react/jsx-props-no-spreading */
// External
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import InputMask from 'react-input-mask';

// Internal
import style from './Input.scss';

const cx = classnames.bind(style);

const Input = ({
  name: givenName,
  value,
  onChange,
  mask,
  maskChar,
  placeholder,
  disabled,
  hasError,
  input,
  field,
  classes,
  meta: {
    touched,
    error,
    invalid,
  },
  ...otherProps
}) => {
  let name = givenName;
  if (!name && input && input.name) name = input.name;
  if (!name && field && field.name) name = field.name;
  return (
    <>
      <InputMask
        id={name}
        name={name}
        mask={mask}
        maskChar={maskChar}
        alwaysShowMask={false}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={cx('input', {
          disabled,
          error: hasError || (touched && invalid),
          [classes]: classes,
        })}
        placeholder={placeholder}
        {...input}
        {...field}
        {...otherProps}
      />

      {(hasError || (touched && invalid)) && <span className={style.fieldError}>{error}</span>}
    </>
  );
};

Input.defaultProps = {
  name: '',
  value: '',
  onChange: null,
  placeholder: null,
  disabled: false,
  hasError: false,
  input: null,
  field: null,
  meta: {
    touched: false,
    invalid: false,
    error: null,
  },
  mask: '',
  maskChar: '',
  classes: null,
};

const ValuePropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

Input.propTypes = {
  name: PropTypes.string,
  value: ValuePropType,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  classes: PropTypes.string,
  input: PropTypes.shape({ // redux-form
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: ValuePropType,
  }),
  field: PropTypes.shape({ // formik
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: ValuePropType,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
};

export default Input;
