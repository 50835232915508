// External
import {
  takeLatest,
  select,
  call,
  put,
  all,
} from 'redux-saga/effects';
import printJS from 'print-js';

// Internal
import { emitErrors } from 'shared/utils/emitErrors';
import { handlerErrors } from 'state/shared/sagas';
import iclinic from 'services/iclinic';
import * as actions from 'state/schedule/actions';
import * as constants from 'state/schedule/constants';
import { downloadUrlOpenIntent } from 'state/shared/actions';

export const ticketQueuePdf = (state) => state.schedule.pdf;
export const selectUserInfo = (state) => state.userInfo.userData;
export const getStartDate = (state) => state.schedule.startDate;
export const getEndDate = (state) => state.schedule.endDate;

export function* workerFetchSchedulePdf() {
  const startDate = yield select(getStartDate);
  const endDate = yield select(getEndDate);
  const { physicianId } = yield select(selectUserInfo);
  try {
    const { getResponseData, errors } = yield call(
      iclinic.schedule.fetchSchedulePDF,
      physicianId,
      startDate,
      endDate,
    );
    if (errors) {
      yield put(actions.printScheduleFailure(emitErrors(errors)));
    } else {
      const payload = getResponseData();
      const isFetching = true;
      yield put(actions.printScheduleSucess(payload.process_id, isFetching));
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.printScheduleFailure);
  }
}

export function* workerFetchUrlSchedulePdf() {
  yield call(workerFetchSchedulePdf);
  const ticket = yield select(ticketQueuePdf);
  try {
    const { getResponseData, errors } = yield call(
      iclinic.schedule.getUrlSchedulePDF,
      ticket,
    );
    if (errors) {
      yield put(actions.printScheduleFailure(emitErrors(errors)));
    } else {
      const nativeWebView = window.ReactNativeWebView;
      const type = 'pdf';
      const payload = getResponseData();
      const { url } = payload;
      const isFetching = false;

      yield put(actions.printScheduleSucess(url, isFetching));

      if (nativeWebView) {
        yield put(
          downloadUrlOpenIntent({
            url,
            name: 'pdf-schedule-download',
            extension: type,
          }),
        );
      } else {
        printJS({
          printable: url,
          type,
        });
      }
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.printScheduleFailure);
  }
}

export default function* scheduleSagas() {
  yield all([
    yield takeLatest(constants.PRINT_SCHEDULE_ACTION, workerFetchUrlSchedulePdf),
  ]);
}
