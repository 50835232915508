import { Box as BoxUI, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Container = styled(BoxUI)({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${tokens.spacing.sm}`,
  margin: `0 ${tokens.spacing.sm} ${tokens.spacing.xxxl}`,
  position: 'relative',
});
