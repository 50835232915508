import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Loader,
  MaterialIcons,
  Typography,
} from '@iclinic/design-system';

import { formatDate, extractTime } from 'shared/utils/date';
import { setExportDrawer } from '../../state/exportHome';
import {
  downloadExportFile,
  fetchExportDownload,
} from '../../state/exportDownload';
import useStyles from './index.styles';
import { getExportId } from 'features/exportClinicData/state/exportHome/selectors';
import { getExportedDownload } from 'features/exportClinicData/state/exportDownload/selectors';

const { CloudDownload, CloudQueue } = MaterialIcons;

export default function ExportDownload(): JSX.Element {
  const exportId = useSelector(getExportId);

  const dispatch = useDispatch();
  const exportedDownload = useSelector(getExportedDownload);
  useEffect(() => {
    dispatch(fetchExportDownload(exportId));
  }, []);

  const listResources = (list: string[] | undefined) => {
    const findResource = ['patient', 'record', 'event', 'bill', 'contact'];
    const replaceResource = [
      'pacientes',
      'prontuários',
      'agendamentos',
      'financeiro',
      'contatos',
    ];
    const resources = list.join(', ');
    return findResource.reduce((accumulator, current, index) => {
      const regex = new RegExp(current, 'g');
      return accumulator.replace(regex, replaceResource[index]);
    }, resources);
  };

  const joinPhysciansNames = (physicians: any) =>
    physicians.map((physician: any) => physician.name).join(', ');

  const classes = useStyles();

  return (
    <Box className={classes.mainBox}>
      <Box className={classes.infoBox}>
        <Typography variant="h3" className={classes.drawerTitle}>
          Detalhes da exportação
        </Typography>
        {!exportedDownload && (
          <Box mt={4} mb={4}>
            <Loader title="Carregando informações" />
          </Box>
        )}
        {!!exportedDownload && (
          <Box>
            <Box>
              <Typography variant="body2" className={classes.fieldName}>
                Usuário
              </Typography>
              <Typography variant="body1" className={classes.fieldValue}>
                {exportedDownload?.profile.name}
              </Typography>
            </Box>
            <Box className={classes.dateTimeBox}>
              <Box className={classes.dateBox}>
                <Typography variant="body2" className={classes.fieldName}>
                  Data
                </Typography>
                <Typography variant="body1" className={classes.fieldValue}>
                  {formatDate(exportedDownload?.date_export_start)}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" className={classes.fieldName}>
                  Hora
                </Typography>
                <Typography variant="body1" className={classes.fieldValue}>
                  {extractTime(exportedDownload?.date_export_start)}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="body2" className={classes.fieldName}>
                Dados exportados
              </Typography>
              <Typography variant="body1" className={classes.longFieldValue}>
                {listResources(exportedDownload?.resources)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" className={classes.fieldName}>
                Filtrar pacientes por profissional
              </Typography>
              <Typography variant="body1" className={classes.fieldValue}>
                {exportedDownload?.filter_physicians === true ? 'Sim' : 'Não'}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" className={classes.fieldName}>
                Período selecionado
              </Typography>
              <Typography variant="body1" className={classes.longFieldValue}>
                {!exportedDownload?.date_start
                  ? 'Todo o período'
                  : `${formatDate(exportedDownload?.date_start)} - ${formatDate(
                      exportedDownload?.date_end,
                    )}`}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" className={classes.fieldName}>
                Formato dos arquivos
              </Typography>
              <Typography variant="body1" className={classes.fieldValue}>
                {exportedDownload?.file_type.toUpperCase()}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" className={classes.fieldName}>
                Clínica
              </Typography>
              <Typography variant="body1" className={classes.fieldValue}>
                {exportedDownload?.clinic_name}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" className={classes.fieldName}>
                Profissionais de saúde
              </Typography>
              <Typography variant="body1" className={classes.fieldValue}>
                {joinPhysciansNames(exportedDownload?.physicians)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" className={classes.fieldName}>
                Status
              </Typography>
              {exportedDownload?.status === 'done' ? (
                <Typography variant="body2" className={classes.disabledButton}>
                  <CloudDownload className={classes.buttonIcon} /> Pronto para
                  baixar
                </Typography>
              ) : (
                <Typography variant="body2" className={classes.disabledButton}>
                  <CloudQueue className={classes.buttonIcon} /> Processando
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Box>
      {!!exportedDownload && (
        <Box className={classes.optionBox}>
          <Button
            color="transparent"
            className={classes.backButton}
            startIcon={<MaterialIcons.ArrowBack />}
            onClick={() => dispatch(setExportDrawer(false))}
          >
            <Typography variant="overline">Voltar</Typography>
          </Button>
          {exportedDownload?.status === 'done' ? (
            <Button onClick={() => dispatch(downloadExportFile())}>
              Baixar
            </Button>
          ) : (
            <Button disabled>Baixar</Button>
          )}
        </Box>
      )}
    </Box>
  );
}
