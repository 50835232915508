// Internal
import * as types from './types';

export const openDetail = () => ({
  type: types.DETAIL.OPEN,
  payload: {
    isOpen: true,
  },
  track: {
    label: 'video',
    action: 'open',
    category: 'video',
  },
});

export const closeDetail = () => ({
  type: types.DETAIL.CLOSE,
  payload: {
    isOpen: false,
  },
});

export const fetchDetail = () => ({ type: types.FETCH_DETAIL.ACTION });
export const fetchDetailSuccess = () => ({ type: types.FETCH_DETAIL.SUCCESS });
export const fetchDetailFailure = () => ({ type: types.FETCH_DETAIL.FAILURE });
