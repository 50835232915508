import { Response } from '@types';
import moment from 'moment';
import { call, put, retry, select, takeLatest } from 'redux-saga/effects';

import {
  createLot,
  fetchAvailableSimplifiedGuides,
  fetchGuideFilterPatientOptions,
  fetchGuideFilterPhysicianOptions,
  fetchGuides,
  fetchLotConfigOptions as fetchLotConfigDataReq,
  fetchLotData,
  fetchLotesFilterInsuranceOptions,
  fetchLotListData,
  fetchXMLFile,
  patchLotData,
} from 'features/tissInvoicing/services/lot';
import {
  CreateLotResponse,
  FecthAvailableSimplifiedGuidesResponse,
  FetchGuidesResponse,
  GenericObjectDescription,
  Guide,
  LegacyFetchPatientsResponse,
  Lot,
  LotViewActionKeys,
  NewLotConfigDataResponse,
  PatchLotData,
  FetchLotListResponse,
} from 'features/tissInvoicing/types';
import { downloadXMLfile } from 'features/tissInvoicing/utils/downloadXMLfile';
import { getXMLFileNameFromHeader } from 'features/tissInvoicing/utils/getXMLFileNameFromHeader';
import history from 'routes/history';
import { captureException } from 'shared/utils/handlerErrors';
import { actions, TISSLotState } from '.';
import { RETRY_DELAY_MS, RETRY_TIMES } from '../constants';
import { getGuideState } from '../sadt/selectors';
import {
  getAddGuidesState,
  getAvailableGuides,
  getDownloadXMLState,
  getLotDataState,
} from './selectors';

const dateFormat = 'YYYY-MM-DD';

type PatchLotDataPayload = ReturnType<typeof actions.patchLotData>;
type CreateLotDataPayload = ReturnType<typeof actions.createLot>;
type FetchLotDataPayload = ReturnType<typeof actions.fetchLotData>;
type FetchListLotsPayload = ReturnType<typeof actions.fetchLotList>;
type FetchAvailableGuidesPayload = ReturnType<
  typeof actions.fetchAvailableGuides
>;
type FetchGuidesPayload = ReturnType<typeof actions.fetchGuides>;
type FetchAvailableSimplifiedGuidesPayload = ReturnType<
  typeof actions.fetchAvailableSimplifiedGuides
>;
type FetchLotGuidesPayload = ReturnType<typeof actions.fetchLotGuides>;
type FetchXMLFilePayload = ReturnType<typeof actions.fetchXMLFile>;
type FetchGuideFilterPatientsPayload = ReturnType<
  typeof actions.fetchGuidesFilterPatients
>;

export function* fetchAvailableGuidesWorker({
  payload: { lot_id, insurance_id, page, ordering, guide_type },
}: FetchAvailableGuidesPayload) {
  try {
    yield put(actions.setAvailableGuidesFetchStatus(true));

    const {
      search: { query },
    }: TISSLotState['addGuides'] = yield select(getAddGuidesState);

    const availableGuidesState: Guide[] = yield select(getAvailableGuides);

    const { data }: Response<FetchGuidesResponse> = yield call(fetchGuides, {
      lot_id,
      insurance_id,
      ordering,
      page,
      physician_id: query?.physician_id || undefined,
      patient_id: query?.patient_id || undefined,
      start: query?.date_from
        ? moment(query?.date_from).format(dateFormat)
        : undefined,
      end: query?.date_to
        ? moment(query?.date_to).format(dateFormat)
        : undefined,
      guide_type,
    });

    yield put(
      actions.setAvailableGuides({
        guides: [...availableGuidesState, ...data.results],
        hasMoreData: !!data.next,
      }),
    );
  } catch (error) {
    captureException(error);
  } finally {
    yield put(actions.setAvailableGuidesFetchStatus(false));
  }
}

export function* fetchGuidesWorker({ payload: { page } }: FetchGuidesPayload) {
  try {
    yield put(actions.setGuidesFetchStatus(true));

    const guidesState: Guide[] = yield select(getGuideState);

    const { data }: Response<FetchGuidesResponse> = yield call(fetchGuides, {
      page,
    });

    yield put(
      actions.setGuides({
        guides: [...guidesState, ...data.results],
        hasMoreData: !!data.next,
        loading: false,
      }),
    );
  } catch (error) {
    captureException(error);
  } finally {
    yield put(actions.setGuidesFetchStatus(false));
  }
}

export function* fetchAvailableSimplifiedGuidesWorker({
  payload: { id, insurance_id, guide_type },
}: FetchAvailableSimplifiedGuidesPayload) {
  try {
    const { data }: Response<FecthAvailableSimplifiedGuidesResponse> =
      yield call(fetchAvailableSimplifiedGuides, id, insurance_id, guide_type);

    yield put(actions.setAvailableSimplifiedGuides(data.results));
  } catch (error) {
    captureException(error);
  }
}

export function* fetchLotDataWorker({ payload: { id } }: FetchLotDataPayload) {
  try {
    yield put(actions.setLotDataFetchStatus(true));

    const { data }: Response<Lot> = yield retry(
      RETRY_TIMES,
      RETRY_DELAY_MS,
      fetchLotData,
      id,
    );

    yield put(actions.setLotData(data));
    yield put(
      actions.setLotConfig({
        clinic_health_insurance_id: String(
          data?.clinic_health_insurance?.id || '',
        ),
        executant_name: data.executant_name,
        executant_cnes: data.executant_cnes,
        executant_type: data.executant_type,
        executant_code: data.executant_code,
        executant_code_type: data.executant_code_type,
        next_lot_code: data.lot_number,
        version: data.version,
      }),
    );
  } catch (error) {
    captureException(error);
    history.push(`/faturamento-tiss/lotes`);
  } finally {
    yield put(actions.setLotDataFetchStatus(false));
  }
}

export function* fetchLotListWorker({
  payload: { page, start, end, insurance_id, professional_id },
}: FetchListLotsPayload) {
  try {
    yield put(actions.setLotsListFetchStatus(true));

    const { data }: Response<FetchLotListResponse> = yield retry(
      RETRY_TIMES,
      RETRY_DELAY_MS,
      fetchLotListData,
      {
        page,
        start: start ? moment(start).format(dateFormat) : undefined,
        end: end ? moment(end).format(dateFormat) : undefined,
        insurance_id,
        professional_id,
      },
    );

    yield put(
      actions.setLotList({
        lots: data.results,
        hasMoreData: data.next !== null,
      }),
    );

    if (data.results.length) {
      yield put(actions.setHasLotInLotList());
    }
  } catch (error) {
    captureException(error);
  } finally {
    yield put(actions.setLotsListFetchStatus(false));
  }
}

export function* fetchLotConfigOptionsWorker() {
  try {
    yield put(
      actions.setLotViewLoadingStatus({
        key: LotViewActionKeys.FetchConfigOptions,
        value: true,
      }),
    );

    const { data }: Response<NewLotConfigDataResponse> = yield retry(
      RETRY_TIMES,
      RETRY_DELAY_MS,
      fetchLotConfigDataReq,
    );

    yield put(actions.setLotConfigOptions(data));
  } catch (e) {
    captureException(e);
  } finally {
    yield put(
      actions.setLotViewLoadingStatus({
        key: LotViewActionKeys.FetchConfigOptions,
        value: false,
      }),
    );
  }
}

export function* createLotWorker({
  payload: {
    clinic_health_insurance_id,
    executant_name,
    executant_cnes,
    executant_code,
    executant_code_type,
    executant_type,
    insurance_name,
    insurance_ans_register,
    insurance_uses_sadt_team,
    version,
  },
}: CreateLotDataPayload) {
  try {
    yield put(
      actions.setLotViewLoadingStatus({
        key: LotViewActionKeys.CreateLot,
        value: true,
      }),
    );

    const {
      data: { id },
    }: Response<CreateLotResponse> = yield retry(
      RETRY_TIMES,
      RETRY_DELAY_MS,
      createLot,
      {
        clinic_health_insurance_id,
        version,
        executant_type,
        guide_type: 's',
        executant_name,
        executant_code,
        executant_code_type,
        executant_cnes: executant_cnes || '',
        insurance_name,
        insurance_ans_register,
        insurance_uses_sadt_team,
      },
    );

    history.push(`/faturamento-tiss/lotes/guias/${String(id)}`);
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLotViewLoadingStatus({
        key: LotViewActionKeys.CreateLot,
        value: false,
      }),
    );
  }
}

export function* patchLotDataWorker({
  payload: {
    version,
    id,
    executant_name,
    executant_code,
    executant_code_type,
    executant_cnes,
    executant_type,
    guides,
    is_closed,
    hasSelectedAllGuides,
    callback,
  },
}: PatchLotDataPayload) {
  try {
    yield put(
      actions.setLotViewLoadingStatus({
        key: LotViewActionKeys.EditLot,
        value: true,
      }),
    );

    const { lot }: TISSLotState['lotData'] = yield select(getLotDataState);

    const {
      data: {
        lot_number: lotNumber,
        total_amount: totalAmount,
        guides: updatedGuides,
        ...response
      },
    }: Response<PatchLotData> = yield call(patchLotData, {
      id,
      version,
      guides,
      is_closed,
      executant_name,
      executant_cnes,
      executant_code,
      executant_code_type,
      executant_type,
      hasSelectedAllGuides,
    });

    yield put(
      actions.setLotData({
        ...lot,
        executant_name: response.executant_name,
        executant_cnes: response.executant_cnes,
        executant_code: response.executant_code,
        executant_code_type: response.executant_code_type,
        lot_number: lotNumber,
        guides: updatedGuides,
        total_amount: totalAmount,
      }),
    );

    if (callback) yield call(callback);
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLotViewLoadingStatus({
        key: LotViewActionKeys.EditLot,
        value: false,
      }),
    );
  }
}

export function* fetchLotGuidesWorker({
  payload: { lot_id, page, ordering },
}: FetchLotGuidesPayload) {
  try {
    yield put(
      actions.setLotViewLoadingStatus({
        key: LotViewActionKeys.FetchGuides,
        value: true,
      }),
    );

    const { data }: Response<FetchGuidesResponse> = yield retry(
      RETRY_TIMES,
      RETRY_DELAY_MS,
      fetchGuides,
      {
        lot_id,
        page,
        ordering,
        lot__isnull: false,
      },
    );

    yield put(
      actions.setLotViewGuides({
        guides: data.results,
        hasMoreData: data.next !== null,
      }),
    );
  } catch (error) {
    captureException(error);
  } finally {
    yield put(
      actions.setLotViewLoadingStatus({
        key: LotViewActionKeys.FetchGuides,
        value: false,
      }),
    );
  }
}

export function* fetchGuidesFilterPhysiciansWorker() {
  try {
    const { data }: Response<GenericObjectDescription[]> = yield call(
      fetchGuideFilterPhysicianOptions,
    );

    yield put(
      actions.setFetchGuidesFilterOptions({
        key: 'physicians',
        data,
      }),
    );
  } catch (error) {
    captureException(error);
  }
}

export function* fetchGuidesFilterPatientsWorker({
  payload: { query },
}: FetchGuideFilterPatientsPayload) {
  try {
    yield put(actions.setFetchGuidesFilterPatients(true));
    const { data }: Response<LegacyFetchPatientsResponse> = yield call(
      fetchGuideFilterPatientOptions,
      query,
    );

    const filteredPatients = data.objects.map((patient) => ({
      id: Number(patient?.id),
      name: patient?.name,
    }));

    yield put(
      actions.setFetchGuidesFilterOptions({
        key: 'patients',
        data: filteredPatients,
      }),
    );
  } catch (error) {
    captureException(error);
  } finally {
    yield put(actions.setFetchGuidesFilterPatients(false));
  }
}

export function* fetchXMLFileWorker({
  payload: { id, callback },
}: FetchXMLFilePayload) {
  try {
    yield put(
      actions.setLotViewLoadingStatus({
        key: LotViewActionKeys.DownloadXML,
        value: true,
      }),
    );

    const {
      query: { orderBy },
    }: TISSLotState['downloadXML'] = yield select(getDownloadXMLState);

    const { data, headers }: Response<BlobPart> = yield call(
      fetchXMLFile,
      id,
      orderBy,
    );

    const fileName = getXMLFileNameFromHeader(headers['content-disposition']);

    downloadXMLfile(fileName, data);

    if (callback) callback();
  } catch (error) {
    captureException(error);
    yield put(
      actions.setSnackbarContent({
        message:
          'Houve um erro ao gerar o XML. Caso persista, contate o suporte.',
        severity: 'error',
      }),
    );
  } finally {
    yield put(
      actions.setLotViewLoadingStatus({
        key: LotViewActionKeys.DownloadXML,
        value: false,
      }),
    );
  }
}

export function* fetchLotesFilterPhysiciansWorker() {
  try {
    const { data }: Response<GenericObjectDescription[]> = yield call(
      fetchGuideFilterPhysicianOptions,
    );

    yield put(
      actions.setFetchLotesFilterOptions({
        key: 'physicians',
        data,
      }),
    );
  } catch (error) {
    captureException(error);
  }
}

export function* fetchLotesFilterInsuranceWorker() {
  try {
    const { data }: Response<GenericObjectDescription[]> = yield call(
      fetchLotesFilterInsuranceOptions,
    );

    yield put(
      actions.setFetchLotesFilterOptions({
        key: 'insurances',
        data,
      }),
    );
  } catch (error) {
    captureException(error);
  }
}

export default function* watchLotWorker() {
  yield takeLatest(actions.fetchLotList, fetchLotListWorker);
  yield takeLatest(
    actions.fetchLotesFilterPhysicians,
    fetchLotesFilterPhysiciansWorker,
  );
  yield takeLatest(
    actions.fetchLotesFilterInsurance,
    fetchLotesFilterInsuranceWorker,
  );
  yield takeLatest(actions.fetchLotData, fetchLotDataWorker);
  yield takeLatest(actions.fetchLotConfigOptions, fetchLotConfigOptionsWorker);
  yield takeLatest(actions.fetchLotGuides, fetchLotGuidesWorker);
  yield takeLatest(actions.fetchAvailableGuides, fetchAvailableGuidesWorker);
  yield takeLatest(actions.fetchGuides, fetchGuidesWorker);
  yield takeLatest(
    actions.fetchGuidesFilterPhysicians,
    fetchGuidesFilterPhysiciansWorker,
  );
  yield takeLatest(
    actions.fetchAvailableSimplifiedGuides,
    fetchAvailableSimplifiedGuidesWorker,
  );
  yield takeLatest(
    actions.fetchGuidesFilterPatients,
    fetchGuidesFilterPatientsWorker,
  );

  yield takeLatest(actions.createLot, createLotWorker);

  yield takeLatest(actions.patchLotData, patchLotDataWorker);
  yield takeLatest(actions.fetchXMLFile, fetchXMLFileWorker);
}
