import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import {
  Box,
  TextField,
  Grid,
  MaterialIcons,
  SectionTitle,
  Body,
} from '@iclinic/design-system';

import { ErrorAlert } from 'shared/components';
import useCurrencyMask, { format } from 'shared/hooks/useCurrencyMask';
import { getFixedNumber } from 'features/onlinePayment/utils';
import { trackSignupIClinicPayForm } from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { SellerBankAccountForm } from 'features/onlinePayment/state/config/types';
import {
  SellerType,
  installmentsXfee,
} from 'features/onlinePayment/containers/config/seller/constants';
import { getUserInfo } from 'features/onlinePayment/state/config/register/selectors';
import { getInstallmentsError } from 'features/onlinePayment/state/config/bankAccount/selectors';
import { Subtitle } from 'features/onlinePayment/components/config';
import { ResponsiveCard } from '../..';
import Terms from '../Terms';

const { CreditCard } = MaterialIcons;

type Props = {
  renderTerms?: boolean;
};

const CardCreditCard = ({ renderTerms = false }: Props) => {
  const userData = useSelector(getUserInfo);
  const error = useSelector(getInstallmentsError);

  const { handleChange, setFieldValue, values, errors, touched } =
    useFormikContext<SellerBankAccountForm>();
  const {
    ref,
    onChange: onChangeMinValue,
    originalValue: minValue,
  } = useCurrencyMask(values.minValue);

  const handleTracker = (
    flowType: string,
    stepName: string,
    stepNum: number,
    stepValue: string,
  ) => {
    trackSignupIClinicPayForm({
      userData,
      flowType,
      stepName,
      stepNum,
      stepValue,
    });
  };

  const handleBlurInstallments = (flowType: string, stepValue: string) => {
    const stepNum = flowType === SellerType.PF ? 21 : 26;
    handleTracker(flowType, 'user_installments', stepNum, stepValue);
  };

  const handleBlurMinValue = (flowType: string, stepValue: string) => {
    const stepNum = flowType === SellerType.PF ? 22 : 27;
    handleTracker(flowType, 'user_minimum_value', stepNum, stepValue);
  };

  const handleChangeTerms = (flowType: string, terms: boolean) => {
    setFieldValue('terms', terms);
    trackSignupIClinicPayForm({
      userData,
      flowType,
      stepName: 'user_term',
      stepNum: flowType === SellerType.PF ? 23 : 28,
      stepValue: terms.toString(),
    });
  };

  useEffect(() => {
    setFieldValue('minValue', minValue);
  }, [minValue, setFieldValue]);

  return (
    <ResponsiveCard variant="outlined">
      <SectionTitle icon={<CreditCard />} title="Pagamento com cartão" mb={1} />
      <Box mb={3}>
        <Box mt={1}>
          <Subtitle>
            Configure as parcelas do pagamento online com cartão de crédito.
          </Subtitle>
        </Box>
        <Box mt={1}>
          <Body variant="xs" bold>
            É super importante! Não deixe de definir em quantas parcelas seu
            paciente poderá pagar. Isso tem um efeito super positivo na relação
            com seu cliente.
          </Body>
        </Box>
      </Box>
      {error && (
        <ErrorAlert
          message={<span dangerouslySetInnerHTML={{ __html: error }} />}
          mb={3}
          name="installments-error"
          scrollToElement
        />
      )}
      <Grid sm={12}>
        <Grid direction="column" spacing={3} container>
          <Grid item sm={6}>
            <TextField
              error={!!(errors.installments && touched.installments)}
              helperText={touched.installments && errors.installments}
              label="Parcelas"
              id="installments"
              name="installments"
              value={values.installments}
              onChange={handleChange}
              onBlur={() =>
                handleBlurInstallments(values.type, values.installments)
              }
              select
              fullWidth
            >
              {Object.keys(installmentsXfee).map((item) => (
                <option key={item} value={item}>
                  {item}x - Taxa {installmentsXfee[item]} por transação
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={6}>
            <TextField
              id="minValue"
              name="minValue"
              label="Valor mínimo da parcela"
              error={!!(errors.minValue && touched.minValue)}
              helperText={touched.minValue && errors.minValue}
              placeholder="R$ 0,00"
              defaultValue={format(getFixedNumber(values.minValue))}
              inputRef={ref}
              onChange={onChangeMinValue}
              onBlur={() =>
                handleBlurMinValue(values.type, values.minValue.toString())
              }
              fullWidth
            />
          </Grid>
          {renderTerms && (
            <Terms
              checked={values.terms}
              error={!!(errors.terms && touched.terms)}
              helperText={errors.terms}
              onChange={() => handleChangeTerms(values.type, !values.terms)}
            />
          )}
        </Grid>
      </Grid>
      <Box mt={2}>
        <Box mt={1}>
          <Subtitle>
            *Somente na bandeira American Express o crédito em 1X tem uma taxa
            de 2.56%, para todas as outras bandeiras, o valor da transação é de
            2.10%.
          </Subtitle>
        </Box>
      </Box>
    </ResponsiveCard>
  );
};

export default CardCreditCard;
