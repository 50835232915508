import { Checkout, ListPhysicians } from 'state/billing/subscription/types';
import { getCreditCardNameByNumber } from 'shared/utils/creditCardValidation';
import { removeWhitespaces } from 'shared/utils/strings';
import { PRODUCTS_SUBSCRIPTION_GROUP } from './constants';

export const normalizeProducts = (
  kind = 'slug',
  productRef: string,
  selectedProducts: string[],
) => {
  const defaultPlans = ['premium', 'pro', 'plus', 'starter'];
  const normalizedPlans =
    kind === 'name'
      ? defaultPlans.map((item) => item.charAt(0).toUpperCase() + item.slice(1))
      : defaultPlans;
  const premiumName = kind === 'name' ? 'Premium' : 'premium';
  // if we select a product that is already in the list, it must be removed
  if (
    selectedProducts.indexOf(productRef) !== -1 &&
    normalizedPlans.indexOf(productRef) === -1
  ) {
    selectedProducts.splice(selectedProducts.indexOf(productRef), 1);
  } else if (normalizedPlans.indexOf(productRef) === -1) {
    selectedProducts.push(productRef);
  }

  // if the product is a plan, we must replace him
  const finalProduct = selectedProducts.map((product: string) => {
    if (
      normalizedPlans.indexOf(product) !== -1 &&
      normalizedPlans.indexOf(productRef) !== -1
    ) {
      return productRef;
    }
    return product;
  });

  const premiumIndex = finalProduct.indexOf(premiumName);
  if (premiumIndex !== -1) return [finalProduct[premiumIndex]];

  return finalProduct;
};

export const normalizeCheck = (
  array: any[],
  property: string | number,
  all: boolean,
) =>
  array.reduce((acc, current) => {
    if ((!all && current.checked && current.profile_id !== 0) || all) {
      return [...acc, current[property]];
    }

    return acc;
  }, []);

export const physicianSelected = (physiciansSelect: ListPhysicians) => {
  const physiciansChecked = physiciansSelect.filter(
    (physician) => physician.checked && physician.profile_id !== 0,
  );
  return physiciansSelect.map((physician) => {
    if (physician.profile_id === 0) {
      return {
        ...physician,
        checked: physiciansChecked.length === physiciansSelect.length - 1,
      };
    }
    return physician;
  });
};

export const checkPhysicians = (
  physicianId: number,
  physiciansSelect: ListPhysicians,
) => {
  const physicians = physiciansSelect.map((physician) => {
    if (physician.profile_id === physicianId) {
      return { ...physician, checked: !physician.checked };
    }
    return physician;
  });

  return physicianSelected(physicians);
};

export const checkPhysiciansAll = (physiciansSelect: ListPhysicians) => {
  const physicianDefault = physiciansSelect.find(
    (physician) => physician.profile_id === 0,
  );
  return physiciansSelect.map((physician: any) => ({
    ...physician,
    checked: !physicianDefault.checked,
  }));
};

export const findAddonInProducts = (addons, selectedProducts: string[]) =>
  addons.reduce((items, item) => {
    const result = selectedProducts.find((product) => product === item.slug);
    if (result) {
      items.push(item.slug);
    }
    return items;
  }, []);

export const verifyNextStep = (
  addons: string[],
  physicians: ListPhysicians[],
) => {
  if (addons.length === 0 || physicians.length === 1) {
    return true;
  }
  return false;
};

export const clearCheckPhysicians = (physiciansSelect: ListPhysicians[]) =>
  physiciansSelect.map((physician) => ({ ...physician, checked: false }));

export const getAddonDetail = (addons: [], slug?: string) => {
  if (slug) {
    return addons.find((a) => a.slug === slug);
  }
  return null;
};

export const normalizeCheckout = (
  checkout: Checkout,
  isMonthly: boolean,
  selectedUsersWithProduct: {},
) => {
  const [expirationMonth, expirationYear] = checkout.expirationDate.split('/');
  return {
    isAnnual: !isMonthly,
    cpfCnpj: checkout.cpfCnpj,
    products: selectedUsersWithProduct,
    card: {
      number: removeWhitespaces(checkout.number),
      owner: checkout.owner,
      flag: getCreditCardNameByNumber(checkout.number),
      expirationMonth,
      expirationYear: `20${expirationYear}`,
      cvv: checkout.cvv,
    },
  };
};

export const createPayloadSingleUser = (
  selectedProducts: string[],
  physiciansSelect: ListPhysicians[],
) => {
  const results = selectedProducts.reduce((items, item) => {
    items.push({ [item]: [physiciansSelect[0].profile_id] });
    return items;
  }, []);
  return Object.assign(...results);
};

export const getStaticProductFunctionalitiesBySlug = (slug: string) => {
  const products = PRODUCTS_SUBSCRIPTION_GROUP.filter(
    (staticProduct) => staticProduct.slug === slug,
  );
  return products.length > 0 ? products[0].functionalities : [];
};
