export const formatDateToString = (date: Date) => {
  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const day = date.toLocaleString('default', { day: '2-digit' });

  return `${year}-${month}-${day}`;
};

export const getSevenDaysBefore = () => {
  const date = new Date();
  date.setDate(date.getDate() - 7);
  return formatDateToString(date);
};

export const getThirtyDaysBefore = () => {
  const date = new Date();
  date.setDate(date.getDate() - 30);
  return formatDateToString(date);
};

export const formatDecimalToBaseSixty = (seconds: number) => {
  const surplus = seconds % 60;
  const result = Math.floor(seconds / 60);

  return { result, surplus };
};

export const formatSecondsToHours = (seconds: number) => {
  const { result: minutes } = formatDecimalToBaseSixty(seconds);

  if (minutes >= 60) {
    const { result: hours, surplus: min } = formatDecimalToBaseSixty(minutes);
    const minString = min ? `${min}min` : '';
    return `${hours}h${minString}`;
  }

  return `${minutes}min`;
};
