import React from 'react';
import { useDispatch } from 'react-redux';

import {
  Body,
  Button,
  DialogActions,
  DialogContent,
} from '@iclinic/design-system';
import { toggleDialog } from '../state/reducer';
import { openIntercomChat } from 'shared/utils/intercom';

export const DialogNotMonthly = () => {
  const dispatch = useDispatch();

  return (
    <>
      <DialogContent>
        <Body variant="xs">
          A mudança de data de vencimento para planos anuais necessita de uma
          atenção especial. Você pode solicitá-la diretamente a um de nossos
          especialistas clicando no botão abaixo.
        </Body>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="transparent"
          onClick={() => dispatch(toggleDialog())}
        >
          Cancelar
        </Button>
        <Button onClick={openIntercomChat} variant="contained">
          Quero fazer a solicitação
        </Button>
      </DialogActions>
    </>
  );
};
