import { AxiosError } from 'axios';
import { ApiErrors } from '@types';

import { formatToInteger } from 'shared/utils/strings';
import {
  errorMessages,
  GENERIC_ERROR_MESSAGE,
} from 'shared/constants/errorMessages';
import { Product } from '../services/types';
import {
  exceptionFunctionalities,
  VerificationIcons,
} from '../constants/functionalities';

export const getPeriod = (isAnnual: boolean) =>
  isAnnual ? 'annual' : 'monthly';

export const formatPeriod = (period: string, frequency: number) => {
  const label = period === 'monthly' ? 'mês' : 'ano';
  if (period === 'monthly' && frequency > 1) {
    return 'meses';
  }
  if (period === 'annual' && frequency > 1) {
    return 'anos';
  }
  return label;
};

export const getDecimal = (value: string) => {
  const fullValue = Number(value).toFixed(2);
  return fullValue.split('.')[1];
};

const MONTHS_IN_ONE_YEAR = 12;

export const getAnnualDiscount = (plan: Product) => {
  const valueMonthlyByOneYear = Number(plan.monthly_price) * MONTHS_IN_ONE_YEAR;
  const discountByYear = valueMonthlyByOneYear - Number(plan.annual_price);

  return discountByYear.toFixed(2);
};

interface PlanDetailsData {
  period: string;
  name: string;
  fullValue: string;
  valueInteger: string;
  valueDecimal: string;
  fullDiscountValue?: string;
  discountValueInteger?: string;
  discountValueDecimal?: string;
  frequencyNumber?: number;
  percentageValue?: string;
  dateExpiration?: string;
}

export const getPlanDetails = (
  plan: Product,
  isAnnual: boolean,
): PlanDetailsData => {
  const { name, promotion, monthly_price, annual_price } = plan;

  const period = getPeriod(isAnnual);
  const sufix = 'mês';

  const promotionByRecurrence = promotion?.frequencies[period];
  const price = period === 'annual' ? annual_price : monthly_price;

  const calculatedPricePerMonth = isAnnual
    ? Number(annual_price) / MONTHS_IN_ONE_YEAR
    : Number(monthly_price);

  const priceInteger = Math.floor(calculatedPricePerMonth);
  const priceDecimal = (calculatedPricePerMonth - priceInteger) * 100;

  const planDetails: PlanDetailsData = {
    period: sufix,
    name,
    fullValue: price,
    valueInteger: formatToInteger(priceInteger.toString()),
    valueDecimal: priceDecimal.toFixed(0).padStart(2, '0'),
  };

  if (!promotionByRecurrence) return planDetails;

  return {
    ...planDetails,
    fullDiscountValue: promotionByRecurrence.value,
    discountValueInteger: formatToInteger(promotionByRecurrence.value),
    discountValueDecimal: getDecimal(promotionByRecurrence.value),
    frequencyNumber: promotionByRecurrence.frequencyNumber,
    percentageValue: formatToInteger(promotionByRecurrence.percentage),
    dateExpiration: formatPeriod(period, promotionByRecurrence.frequencyNumber),
  };
};

export const getCheckIconException = (
  planSlug: string,
  functionalitySlug: string,
) => {
  const content = exceptionFunctionalities[planSlug][functionalitySlug];
  if (content) {
    return content?.icon;
  }
  return null;
};

export const getVerificationIcon = (
  productsFuncionalityIsIncluded: string[],
  planSlug: string,
  functionalitySlug: string,
) => {
  const exception = getCheckIconException(planSlug, functionalitySlug);
  if (exception) {
    return exception;
  }
  return productsFuncionalityIsIncluded.includes(planSlug)
    ? VerificationIcons.checked
    : VerificationIcons.unchecked;
};

export const getResponseErrors = (error: AxiosError) =>
  error.response?.data?.errors || [];

export const getErrorsMessage = (errors: ApiErrors[]) =>
  errors.map((error) => errorMessages[error.code] || GENERIC_ERROR_MESSAGE) ||
  [];
