// Extenal
import { createSelector } from 'reselect';
// Internal
import { PDFResponse } from 'state/shared/types';
import { FileSignatureRequest } from 'state/records/signature/types';
import { StoreState } from 'state/rootReducer';
import { escapeString } from 'shared/utils/strings';

const selectUserInfo = (state: StoreState) => state.userInfo;
const patientInfo = (state: any) => state.records.root.patient;
const s3Pdf = (state: any) => state.shared.s3Pdf;
const encodedCert = (state: any) => state.records.signature.encodedCert;
const signedErrors = (state: any) => state.records.signature.errors;
const pdfErrors = (state: any) => state.shared.errors;
const isLoadingSignature = (state) => state.records.signature.isLoading;
const isLoadingPDF = (state) => state.shared.isLoading;

export const getErrors = createSelector(
  [signedErrors, pdfErrors],
  (signatureErrors, pdfsErrors) => [
    ...pdfsErrors,
    ...signatureErrors,
  ],
);

export const getLoading = createSelector(
  [isLoadingSignature, isLoadingPDF],
  (isSignature, isPDF) => isPDF || isSignature,
);

export const getSignatureRequest = createSelector(
  [selectUserInfo, patientInfo, s3Pdf, encodedCert],
  (userInfo, patient, urlPdfs, encodedCertHash) => {
    const { userData: { physicianId }, profileId } = userInfo;
    const { id } = patient;
    return urlPdfs.map((file: PDFResponse): FileSignatureRequest => ({
      s3_file_url: file.url,
      encoded_cert: encodedCertHash,
      patient_id: id,
      physician_id: physicianId,
      user_id: profileId,
      document_type: file.documentType,
      document_id: file.id,
    }));
  },
);

export const getTemplateEmailSignature = createSelector(
  [selectUserInfo, patientInfo],
  (userInfo, patient) => {
    const {
      userData: {
        treatment,
        mobile_phone,
        home_phone,
        physicianId,
        userEmail,
        userName: namePhysician,
      },
    } = userInfo;
    const { id, name } = patient;
    const contact = mobile_phone || home_phone ? `Contato: ${mobile_phone || home_phone}` : '';
    const treatmentLabel = treatment || '';
    const userName = escapeString(namePhysician);
    return {
      patient_id: id,
      physician_id: physicianId,
      email: {
        reply_to: userEmail,
        sender: `${treatmentLabel} ${userName} <${process.env.ICLINIC_EMAIL_SENDER}>`,
        subject: `Documentação do seu atendimento com ${treatmentLabel} ${userName}`,
        body: `
        <html>
          <body>
             <p>Olá ${name}, tudo bem?</p>
             <br />
             <p>Em anexo está a documentação gerada no seu atendimento com ${treatmentLabel} ${userName}, assinada digitalmente.</p>
             <br />
             <p>Um documento com assinatura digital tem a mesma validade jurídica que um impresso e assinado pelo seu médico. Portanto, você pode entregá-lo à farmácias, laboratórios ou outros lugares que for necessário. </p>
             <br />
             <p>Em caso de dúvida, entre em contato com o seu médico.</p>
             <br />
             <p>${contact}</p>
          </body>
        </html>`,
        recipients: [],
        configuration_set: process.env.ICLINIC_EMAIL_CONFIGURATION_SET,
        attachments: [],
      },
    };
  },
);
