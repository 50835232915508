import React from 'react';

import MessageLabel from './MessageLabel';
import TextMessage from './TextMessage';
import { useConference } from 'features/video-conference/state/conferenceContext';
import MessageListScrollContainer from './MessageListScrollContainer';
import {
  Message,
  MESSAGE_TYPE,
} from 'features/video-conference/constants/types';
import { MessageGroup } from './styles';
import MediaMessage from './MediaMessage';

interface MessageListProps {
  messages: Message[];
}

const getFormattedTime = (message?: Message) =>
  message?.dateCreated
    .toLocaleTimeString('pt-br', { hour: 'numeric', minute: 'numeric' })
    .toLowerCase();

export default function MessageList({ messages }: MessageListProps) {
  const { room } = useConference();
  const { localParticipant } = room!;

  return (
    <MessageListScrollContainer messages={messages}>
      {messages.map((message, idx) => {
        const time = getFormattedTime(message)!;
        const previousTime = getFormattedTime(messages[idx - 1]);
        const shouldDisplayMessageLabel =
          time !== previousTime ||
          message.identity !== messages[idx - 1]?.identity;
        const isLocalParticipant =
          localParticipant.identity === message.identity;

        return (
          <MessageGroup
            isLocalParticipant={isLocalParticipant}
            key={message.sid}
          >
            {shouldDisplayMessageLabel && (
              <MessageLabel
                author={message.author}
                isLocalParticipant={isLocalParticipant}
                dateCreated={time}
              />
            )}
            {message.type === MESSAGE_TYPE.TEXT && (
              <TextMessage
                body={message.body}
                isLocalParticipant={isLocalParticipant}
              />
            )}
            {message.type === MESSAGE_TYPE.MEDIA && (
              <MediaMessage
                media={message.file!}
                isLocalParticipant={isLocalParticipant}
              />
            )}
          </MessageGroup>
        );
      })}
    </MessageListScrollContainer>
  );
}
