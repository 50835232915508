import React from 'react';
import { AudioTrack } from 'twilio-video';

import { MaterialIcons } from '@iclinic/design-system';
import { useAudioLevelIndicator } from 'features/video-conference/hooks/useAudioLevelIndicator';
import DarkChip from 'features/video-conference/components/TeamComponents/DarkChip';
import useStyles, { AudioLevelContainer } from './styles';

const { AudioLevelIndicator: AudioLevelIndicatorIcon } = MaterialIcons;

interface AudioLevelProps {
  audioTrack?: AudioTrack;
  isLocal: boolean;
}

let clipId = 0;
// eslint-disable-next-line no-return-assign
const getUniqueClipId = () => (clipId += 1);

function AudioLevelIndicator({ audioTrack, isLocal }: AudioLevelProps) {
  const { SVGRectRef, volume } = useAudioLevelIndicator(audioTrack);
  const clipPathId = `audio-level-clip-${getUniqueClipId()}`;
  const classes = useStyles({ isLocal });

  return (
    <>
      <AudioLevelContainer isLocal={isLocal} volume={volume} />
      <DarkChip
        icon={
          <AudioLevelIndicatorIcon
            SVGRectRef={SVGRectRef}
            clipPathId={clipPathId}
          />
        }
        content=""
        customClassName={classes.root}
      />
    </>
  );
}

export default React.memo(AudioLevelIndicator);
