import axios, { AxiosResponse } from 'axios';

import { interceptorRequestAuthentication } from 'services/iclinic/interceptors';

type CheckAwsIncidentResponse = {
  show_banner: boolean;
};

type MissingRecordsZipFile = {
  url: string;
};

const api = axios.create({ baseURL: process.env.ICLINIC_API_URL || '' });
api.interceptors.request.use(interceptorRequestAuthentication);

export const checkAwsIncident = (): Promise<
  AxiosResponse<CheckAwsIncidentResponse>
> => api.get(`/accounts/physician/check_aws_incident`);

export const missingRecordsZipFiles = (): Promise<
  AxiosResponse<MissingRecordsZipFile>
> => api.get(`/accounts/physician/missing_records_zipfile`);
