import {
  Button as DSButton,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color } = tokens;

export const Button = styled(DSButton)({
  background: `linear-gradient(180deg, ${color.feedback.warning.main} 0%, ${color.feedback.warning.dark} 100%)`,
});
