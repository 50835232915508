// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import style from './Value.scss';

export default function Value({ total, cents }) {
  return (
    <div className={style.value}>
      <div className={style.content}>
        <p className={style.content__coin}>+R$</p>
        <p className={style.content__value}>{total}</p>
        <p className={style.content__period}>{`,${cents} / mês`}</p>
      </div>
      <p className={style.value__footer}>por profissional de saúde</p>
    </div>
  );
}

Value.propTypes = {
  total: PropTypes.string.isRequired,
  cents: PropTypes.string.isRequired,
};
