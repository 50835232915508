import {
  theme as themeUI,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { makeStyles, createMuiTheme } = MaterialUICore;
const { color, spacing, border, font, line } = tokens;

type Props = {
  height?: number;
  isPhysician?: boolean;
  isWaitRoom?: boolean;
};

const theme = createMuiTheme(themeUI);

const useStyles = makeStyles<{}, Props>(() => ({
  smallContainer: {
    maxWidth: '304px',
    justifyContent: 'center',
  },
  mediumContainer: {
    maxWidth: '500px',
  },
  textColor: {
    color: color.neutral[25],
  },
  switchLabel: {
    color: color.neutral[50],
    fontSize: font.size.xxs,
    fontWeight: font.weight.regular,
    lineHeight: line.height.sm,
  },
  linkColor: {
    color: color.secondary.dark,
    '&:hover': {
      color: color.secondary.main,
    },
  },
  feedbackTitleBox: {
    padding: spacing.base,
    [theme.breakpoints.down(200)]: {
      display: 'none',
    },
  },
  feedbackTitle: {
    color: color.neutral[25],
  },
  feedbackSubtitle: {
    color: color.neutral[300],
  },
  feedbackMessage: {
    color: color.neutral[25],
    [theme.breakpoints.down(320)]: {
      display: 'none',
    },
  },
  neutral50: {
    color: color.neutral[50],

    [theme.breakpoints.down(320)]: {
      fontSize: '10px',
    },
  },
  reviewContainer: {
    marginTop: spacing.lg,
    marginBottom: spacing.lg,
    backgroundColor: 'rgba(0, 0, 0, 0.32)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: spacing.lg,
    borderRadius: border.radius.sm,
  },
  waitRoomContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.32)',
    padding: spacing.sm,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: border.radius.md,
    width: '100%',
    maxWidth: '430px',
    marginBottom: spacing.md,
    [theme.breakpoints.up('sm')]: {
      padding: spacing.lg,
    },
  },
  waitingRoom: {
    backgroundColor: color.neutral[900],
    display: 'flex',
    justifyContent: ({ isPhysician }) =>
      isPhysician ? 'space-between' : 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: spacing.sm,
    width: '100%',
    height: '100%',
    minHeight: '100vh',
  },
  ghostDiv: {
    [theme.breakpoints.up('sm')]: {
      height: ({ isPhysician }) => (isPhysician ? '48px' : '0'),
    },
  },
  room: {
    flexGrow: 1,
    backgroundColor: color.neutral[900],
    height: ({ isWaitRoom }) => (isWaitRoom ? 'auto' : '100vh'),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    borderRadius: 0,
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      height: ({ height, isWaitRoom }) => (isWaitRoom ? 'auto' : height),
    },
  },
  iframeHidden: {
    [theme.breakpoints.down(320)]: {
      display: 'none',
    },
  },
}));

export default useStyles;
