import { MaterialUICore, Box as BoxDS } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const QRCodeTimerWrapper = styled(BoxDS)(() => ({
  display: 'inline-block',
  position: 'relative',
}));

export const Overlay = styled(BoxDS)(() => ({
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100%',
}));
