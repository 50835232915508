import moment from 'moment';

import {
  ClinicHealthInsuranceHiredExecutant,
  ClinicHealthInsuranceProfessionalExecutant,
  GuideSADT,
  GuideSADTForm,
  PatchGuideSADTPayload,
} from 'features/tissInvoicing/types';
import { AccessWay, Technique } from '../constants';

const normalizeDate = (dateString?: string | Date | null) => {
  if (typeof dateString === 'string') return new Date(`${dateString}T12:00:00`);

  return null;
};

const formatDate = (date: Date | string | null | undefined) => {
  if (!date) return null;

  return moment(date).format(moment.HTML5_FMT.DATE);
};

const NO_OPTION_SELECTED = 0;

const formatNoOptionSelected = (option?: number | null) =>
  Number(option) === NO_OPTION_SELECTED ? null : option;

const getVersion = (guide: GuideSADT) => {
  if (guide?.version_id) return guide?.version_id;
  return guide?.insurance?.tiss_version || '';
};

const normalizeString = (
  value: string | null | undefined,
  defaultValue: string = '',
) => value || defaultValue;

const normalizeNumber = (
  value: number | null | undefined,
  defaultValue: number = NO_OPTION_SELECTED,
) => value || defaultValue;

export const parseDataClinicHealth = (
  professionalExecutant?: ClinicHealthInsuranceProfessionalExecutant,
  hiredExecutant?: ClinicHealthInsuranceHiredExecutant,
) => {
  let setDataProfessional = {};
  let setDataHired = {};

  if (hiredExecutant) {
    setDataHired = {
      hired_executant_type: hiredExecutant.type,
      hired_executant_name: hiredExecutant.name,
      hired_executant_code: hiredExecutant.code,
      hired_executant_code_type: hiredExecutant.code_type,
      hired_executant_cnes: hiredExecutant.cnes,
    };
  }

  if (professionalExecutant) {
    setDataProfessional = {
      professional_executant_name: professionalExecutant.name,
      professional_executant_council_id: professionalExecutant.council_id,
      professional_executant_council: professionalExecutant.council_name,
      professional_executant_council_state: professionalExecutant.council_state,
      professional_executant_council_number:
        professionalExecutant.council_number,
      professional_executant_cbo: professionalExecutant.cbo_code,
      professional_executant_code: professionalExecutant.code,
      professional_executant_code_type: professionalExecutant.code_type,
    };
  }

  return {
    ...setDataHired,
    ...setDataProfessional,
  };
};

export const normalizeGuideSADTResponse = (
  rawGuideData: GuideSADT,
): GuideSADTForm => ({
  // Dados Gerais
  version_id: getVersion(rawGuideData), // Versão da guia
  guide_number: normalizeString(rawGuideData?.guide_number), // 2 - Número da guia
  main_guide_number: normalizeString(rawGuideData?.main_guide_number), // 3 - Número da guia principal
  authorization_date: normalizeDate(rawGuideData?.authorization_date), // 4 - Data de autorização
  authorization_number: normalizeString(rawGuideData?.authorization_number), // 5 - Senha
  insurance_name: normalizeString(rawGuideData?.insurance_name),
  insurance_ans_register: normalizeString(rawGuideData?.insurance_ans_register),
  authorization_expiration_date: normalizeDate(
    rawGuideData?.authorization_expiration_date,
  ), // 6 - Data de validade
  operator_guide_number: normalizeString(rawGuideData?.operator_guide_number), // 7 - Número da guia atribuido na operadora
  degree_participation: normalizeNumber(rawGuideData?.degree_participation), // 49 - Grau de participação
  service_type: normalizeNumber(rawGuideData?.service_type), // 32 - Tipo de atendimento | Tabela de domínio
  accident_indication: normalizeNumber(rawGuideData?.accident_indication), // 33 - Indicação do acidente | Tabela de domínio
  appointment_type: normalizeNumber(rawGuideData?.appointment_type), // 34 - Tipo de consulta | Tabela de domínio
  reason_termination: normalizeNumber(rawGuideData?.reason_termination), // 35 - Motivo de encerramento | Tabela de domínio
  observation: normalizeString(rawGuideData?.observation), // 58 - Observação/Justificativa
  occupational_health: normalizeString(rawGuideData?.occupational_health),
  attendance_regimen: normalizeString(rawGuideData?.attendance_regimen),

  // Dados do Beneficiario
  patient_id: rawGuideData?.patient_id, // 10 - Nome do beneficiário
  patient_sus_code: normalizeString(rawGuideData?.patient_sus_code),
  patient_civil_name: normalizeString(rawGuideData?.patient_civil_name), // 10 - Nome do beneficiário
  patient_social_name: normalizeString(rawGuideData?.patient_social_name),
  patient_insurance_code: normalizeString(rawGuideData?.patient_insurance_code), // 8 - Número da carteira (Pode vir do back)
  patient_insurance_expiry_date: normalizeDate(
    rawGuideData?.patient_insurance_expiry_date,
  ), // 9 - Data de validade da carteira (Pode vir do back)
  newborn: normalizeString(rawGuideData?.newborn, 'N'), // 12 - Atendimento RN

  // Dados do Solicitante
  hired_solicitant_name: normalizeString(rawGuideData?.hired_solicitant_name), // 14 - Nome do contratado | Vem de autocomplete, salvo como texto
  hired_solicitant_code_type: normalizeString(
    rawGuideData?.hired_solicitant_code_type,
  ), // 13 - Tipo | Campo não existe na documentação da ANS, mas usaremos para geração de lotes
  hired_solicitant_code: normalizeString(rawGuideData?.hired_solicitant_code), // 13 - Código na operadora
  special_coverage: normalizeString(rawGuideData?.special_coverage),
  professional_solicitant_name: normalizeString(
    rawGuideData?.professional_solicitant_name,
  ), // 15 - Nome do profissional solicitante | Vem de autocomplete, salvo como texto
  professional_solicitant_council:
    rawGuideData?.professional_solicitant_council || null, // 16 - Conselho profissional | Tabela de domínio
  professional_solicitant_council_number: normalizeString(
    rawGuideData?.professional_solicitant_council_number,
  ), // 17 - Número no conselho
  professional_solicitant_council_state:
    rawGuideData?.professional_solicitant_council_state || null, // 18 - UF | Tabela de domínio
  professional_solicitant_cbo:
    rawGuideData?.professional_solicitant_cbo || null, // 19 - Especialidade/CBO | Tabela de domínio
  solicitor_service_type: normalizeNumber(
    rawGuideData?.solicitor_service_type,
    1,
  ), // 21 - Caráter do atendimento | Tabela de domínio
  request_date: normalizeDate(rawGuideData?.request_date), // 22 - Data da solicitação
  clinical_indication: normalizeString(rawGuideData?.clinical_indication), // 23 - Indicação clínica

  // Dados de procedimento
  procedures: rawGuideData?.procedures?.map((procedure) => ({
    id: procedure.id,
    procedure_id: procedure.procedure_id, // Descrição | Buscaremos por ID
    tuss_procedure_id: procedure.tuss_procedure_id,
    quantity: normalizeNumber(procedure.quantity, 1), // Quantidade
    access_way: procedure.access_way || AccessWay.Empty, // Via de acesso |  Tabela de dominio
    technique_used: procedure.technique_used || Technique.Empty, // Técnica |  Tabela de dominio
    reduction_factor: procedure.reduction_factor || '1,00', // Fator
    procedure_date: normalizeDate(procedure.procedure_date), // Data | Preenchimento manual
    procedure_start: normalizeString(procedure.procedure_start?.slice(0, -3)), // Hora inicio | Preenchimento manual
    procedure_end: normalizeString(procedure.procedure_end?.slice(0, -3)), // Hora termino | Preenchimento manual
    unit_price: procedure.unit_price?.replace('.', ',') || '0,00', // Valor unitario
  })),

  // Dados de sessão
  sessions: rawGuideData?.sessions?.map((session) => ({
    ...session,
    id: session.id,
    sessionDate: normalizeDate(session.date),
  })),

  expenses: rawGuideData.expenses || [],
});

export const normalizeGuideSADTPatchPayload = (
  sadtForm: GuideSADTForm,
): PatchGuideSADTPayload => ({
  // Dados Gerais
  ...sadtForm,
  authorization_date: formatDate(sadtForm.authorization_date),
  authorization_expiration_date: formatDate(
    sadtForm.authorization_expiration_date,
  ),
  degree_participation: formatNoOptionSelected(sadtForm.degree_participation),
  service_type: formatNoOptionSelected(sadtForm.service_type),
  accident_indication: formatNoOptionSelected(sadtForm.accident_indication),
  appointment_type: formatNoOptionSelected(sadtForm.appointment_type),
  reason_termination: formatNoOptionSelected(sadtForm.reason_termination),

  // Dados de outras despesas
  expenses: sadtForm.expenses?.map((exp) => {
    const { tuss_expense, ...rest } = exp;

    return {
      ...rest,
      tuss_expense_id: tuss_expense?.id,
    };
  }),

  // Dados do Beneficiario
  patient_insurance_expiry_date: formatDate(
    sadtForm.patient_insurance_expiry_date,
  ),
  patient_social_name: sadtForm?.patient_social_name,
  patient_civil_name: sadtForm?.patient_civil_name,
  patient_id: sadtForm?.patient_id,
  patient_sus_code: sadtForm?.patient_sus_code,

  // Dados do Solicitante
  professional_solicitant_council:
    sadtForm?.professional_solicitant_council || null,
  professional_solicitant_council_state:
    sadtForm?.professional_solicitant_council_state || null,
  solicitor_service_type: formatNoOptionSelected(
    sadtForm.solicitor_service_type,
  ),
  request_date: formatDate(sadtForm.request_date),

  // Dados de procedimento
  procedures: sadtForm.procedures.map((procedure) => ({
    ...procedure,
    id: procedure?.id || undefined,
    unit_price: String(procedure.unit_price?.replace(',', '.')),
    reduction_factor: String(procedure.reduction_factor?.replace(',', '.')),
    access_way: formatNoOptionSelected(procedure.access_way),
    technique_used: formatNoOptionSelected(procedure.technique_used),
    procedure_date: formatDate(procedure.procedure_date),
  })),

  // Dados de sessão
  sessions: sadtForm.sessions.map((session) => ({
    ...session,
    id: session?.id || undefined,
    date: formatDate(session.sessionDate),
  })),
});
