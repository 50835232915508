// External
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';

// Internal
import { MARKETING_UNSUBSCRIBE } from 'shared/constants/forms';

const getFeedBack = formValueSelector(MARKETING_UNSUBSCRIBE);
export const getFeedBackFormValues = (state) => getFeedBack(state, 'reason', 'otherReason');

export const getUnsubscribeParams = (state) => state.marketingUnsubscribe.unsubscribe;
export const getFeedBackSent = createSelector(
  (state) => state.marketingUnsubscribe,
  (unsubscribe) => {
    const { didProvideReason } = unsubscribe.feedback;
    return { didProvideReason, hasErrors: unsubscribe.errors.length > 0 };
  },
);
