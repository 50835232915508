import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { ExportListItem, ExportListPagination } from './types';

export interface ListState {
  exportList: {
    data?: ExportListItem[];
    status: RequestStatus;
  };
  pagination?: ExportListPagination | null;
  pageNumber: number;
}

export const initialState: ListState = {
  exportList: {
    data: [],
    status: RequestStatus.Pending,
  },
  pageNumber: 1,
};

const name = 'exportList';

export const init = createAction(`${name}/init`);

const ListSlice = createSlice({
  name,
  initialState,
  reducers: {
    fetchExportList: (state) => ({
      ...state,
      exportList: {
        status: RequestStatus.Pending,
      },
    }),
    fetchExportListSuccess: (
      state,
      action: PayloadAction<ExportListItem[]>,
    ) => ({
      ...state,
      exportList: {
        data: action.payload,
        status: RequestStatus.Success,
      },
    }),
    fetchExportListFailure: (state) => ({
      ...state,
      exportList: {
        status: RequestStatus.Error,
      },
    }),
    exportPagination: (state, action: PayloadAction<ExportListPagination>) => ({
      ...state,
      pagination: action.payload,
    }),
    selectedPageNumber: (state, action: PayloadAction<number>) => ({
      ...state,
      exportList: {
        status: RequestStatus.Pending,
      },
      pageNumber: action.payload,
    }),
  },
});

export default ListSlice.reducer;

export const {
  fetchExportList,
  fetchExportListSuccess,
  fetchExportListFailure,
  exportPagination,
  selectedPageNumber,
} = ListSlice.actions;
