import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const {
  styled,
  ListItem: ListItemMUI,
  List: ListMUI,
  ListItemText: ListItemTextMUI,
} = MaterialUICore;
const { spacing } = axiomTokens;

export const SearchContainer = styled('div')({
  padding: `${spacing[1]} ${spacing[3]}`,
  '& .MuiTextField-root': {
    width: '100%',
    '& .MuiInputLabel-root': {
      paddingLeft: '36px',
    },
  },
});

export const ListContainer = styled('div')({
  height: '100%',
  padding: `${spacing[3]} ${spacing[1]}`,
});

export const List = styled(ListMUI)({
  height: '100%',
  padding: 0,
});

export const ListItem = styled(ListItemMUI)({
  padding: `${spacing[4]} ${spacing[3]}`,

  '&.MuiListItemText-root': {
    margin: 0,
  },

  '&.MuiListItem-root': {
    '&:hover': {
      backgroundColor: axiomTokens.color.surface.subtle.hovered,
    },
    '&.Mui-selected': {
      backgroundColor: axiomTokens.color.surface.subtle.default,
      color: axiomTokens.color.interactive.accent.default,
      '& .MuiTypography-root': {
        color: axiomTokens.color.interactive.accent.default,
      },
    },
  },
});

export const ListItemText = styled(ListItemTextMUI)({
  margin: 0,
});

export const ListItemNested = styled(ListItemMUI)({
  padding: spacing[3],
  paddingLeft: spacing[6],

  '&.MuiListItem-root': {
    '&:active': {
      backgroundColor: axiomTokens.color.surface.selected.default,
      color: axiomTokens.color.interactive.accent.default,
      '& .MuiTypography-root': {
        color: axiomTokens.color.interactive.accent.default,
      },
    },
    '&:hover': {
      backgroundColor: axiomTokens.color.surface.subtle.hovered,
    },
  },
});

export const LoadingContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  width: '100%',
});

export const CalculatorDrawerEmptyContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  padding: spacing[4],
  height: '100%',
  justifyContent: 'center',
  width: '100%',
});
