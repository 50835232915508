import React from 'react';

import { MaterialUICore } from '@iclinic/design-system';
import useStyles from './styles';

const { FormHelperText } = MaterialUICore;

type Props = {
  id?: string;
  helperText?: React.ReactNode;
  error: string | null;
};

export default function UploadHelperText({
  id,
  helperText,
  error,
}: Props): JSX.Element {
  const classes = useStyles();

  return (
    <FormHelperText id={id} className={classes.helperText}>
      {error || helperText}
    </FormHelperText>
  );
}
