// External
import React from 'react';
import { Switch, RouteComponentProps } from 'react-router-dom';

// Internal
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import ScheduleOnlineSetup from 'pages/settings/scheduleOnline/ScheduleOnlineSetup';

const Routes = ({ match }: RouteComponentProps<{}>) => (
  <Switch>
    <AuthenticatedRoute path={`${match.path}/profissionais/marketing/agendamento-online`} component={ScheduleOnlineSetup} />
  </Switch>
);

export default Routes;
