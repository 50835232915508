import { GenericObjectDescription, GenericResponse } from '../misc';

export interface Guide {
  id: string;
  guide_number: number;
  event_id: number;
  date: string;
  patient: GenericObjectDescription;
  physician: GenericObjectDescription;
  procedures_total_amount: string;
  expenses_amount: string;
  total_amount: string;
  errors?: string[];
  lot_id: string;
  lot_number: number;
  quantity: number;
  insurance: GenericObjectDescription;
  guide_type: GuideTypeOptions;
}

export interface FetchGuidesQuery {
  patient_id?: number | string | null;
  physician_id?: number | string | null;
  date_from?: Date | null;
  date_to?: Date | null;
}

export interface FetchGuidesRequestParams {
  insurance_id?: number;
  lot_id?: string;
  lot__isnull?: boolean;
  hasSelectedAllGuides?: boolean;
  page?: number;
  ordering?: string;
  start?: string;
  end?: string;
  patient_id?: number | string;
  physician_id?: number | string;
  professional_id?: number | string;
  guide_type?: GuideTypeOptions;
}

export enum GuideTypeOptions {
  Appointment = 'c',
  SPSADT = 's',
}

export interface FetchGuidesResponse extends GenericResponse {
  results: Guide[];
}
