import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { useRifm } from 'rifm';
import {
  Grid,
  SectionTitle,
  MaterialIcons,
  TextField,
  Box,
} from '@iclinic/design-system';

import { useDidUpdateEffect } from 'shared/hooks';
import BRAZIL_STATES_INITIALS from 'shared/constants/brStates';
import { cepFormatter } from 'shared/utils/formatters';
import { SellerEditForm } from 'features/onlinePayment/state/config/types';
import { fetchZipCode } from 'features/onlinePayment/state/config/register';
import { getZipCodeSelector } from 'features/onlinePayment/state/config/register/selectors';
import { ResponsiveCard } from 'features/onlinePayment/components';
import { Address } from 'features/onlinePayment/services/config/types';

const { Room } = MaterialIcons;

const errorsZipCode = (errors: boolean, zipCode: boolean) => errors || zipCode;

const helperTextZipCode = (
  touched: boolean | undefined,
  errors: string | undefined,
  zipCode: string | undefined,
) => (touched && errors) || zipCode;

export type CardAddressEditProps = {
  address: Address;
};

 
export default function CardAddressEdit({
  address,
}: CardAddressEditProps): JSX.Element {
  const dispatch = useDispatch();
  const { zipCodeInfo } = useSelector(getZipCodeSelector);

  const { handleChange, values, errors, touched, setFieldValue } =
    useFormikContext<SellerEditForm>();

  useEffect(() => {
    const { city, complement, neighborhood, number, state, street, zip_code } =
      address || {};

    setFieldValue('city', city || '');
    setFieldValue('complement', complement || '');
    setFieldValue('neighborhood', neighborhood || '');
    setFieldValue('number', number || '');
    setFieldValue('state', state || '');
    setFieldValue('street', street || '');
    setFieldValue('zipCode', zip_code || '');
  }, [address, setFieldValue]);

  useDidUpdateEffect(() => {
    if (zipCodeInfo && !zipCodeInfo.status) {
      setFieldValue('city', zipCodeInfo.city || '');
      setFieldValue('street', zipCodeInfo.address || '');
      setFieldValue('state', zipCodeInfo.state || '');
      setFieldValue('neighborhood', zipCodeInfo.neighborhood || '');
    }
  }, [dispatch, zipCodeInfo, setFieldValue]);

  const { value: zipCode, onChange: onChangeZipCode } = useRifm({
    value: values.zipCode,
    onChange: (value: string) => {
      setFieldValue('zipCode', value);
      if (value.length === 9) {
        dispatch(fetchZipCode(value));
      }
    },
    format: cepFormatter,
  });

  return (
    <ResponsiveCard variant="outlined">
      <Box mb={2}>
        <SectionTitle icon={<Room />} title="Endereço" mb={1} />
      </Box>
      <Grid direction="column" spacing={3} container>
        <Grid item xs={12} md={2}>
          <TextField
            id="zipCode"
            name="zipCode"
            label="Cep"
            value={zipCode}
            onChange={onChangeZipCode}
            error={errorsZipCode(
              !!(errors.zipCode && touched.zipCode),
              !!zipCodeInfo?.msg,
            )}
            helperText={helperTextZipCode(
              touched.zipCode,
              errors.zipCode,
              zipCodeInfo?.msg,
            )}
            autoComplete="none"
            fullWidth
          />
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={10}>
            <TextField
              id="street"
              name="street"
              label="Endereço"
              value={values.street}
              onChange={handleChange}
              error={!!(errors.street && touched.street)}
              helperText={touched.street && errors.street}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="number"
              name="number"
              label="Nº"
              value={values.number}
              onChange={handleChange}
              error={!!(errors.number && touched.number)}
              helperText={touched.number && errors.number}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={5}>
            <TextField
              id="neighborhood"
              name="neighborhood"
              label="Bairro"
              value={values.neighborhood}
              onChange={handleChange}
              error={!!(errors.neighborhood && touched.neighborhood)}
              helperText={touched.neighborhood && errors.neighborhood}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              id="city"
              name="city"
              label="Cidade"
              value={values.city}
              onChange={handleChange}
              error={!!(errors.city && touched.city)}
              helperText={touched.city && errors.city}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="state"
              name="state"
              label="Estado"
              select
              value={values.state}
              onChange={handleChange}
              error={!!(errors.state && touched.state)}
              helperText={touched.state && errors.state}
              fullWidth
            >
              <option value="">Escolha</option>
              {BRAZIL_STATES_INITIALS.map((state) => (
                <option value={state} key={state}>
                  {state}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={12}>
            <TextField
              id="complement"
              name="complement"
              label="Complemento"
              value={values.complement}
              onChange={handleChange}
              error={!!(errors.complement && touched.complement)}
              helperText={touched.complement && errors.complement}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </ResponsiveCard>
  );
}
