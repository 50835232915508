import { getCreditCardNameByNumber } from 'shared/utils/creditCard';
import { onlyNumbers } from 'shared/utils/formatters';
import { removeWhitespaces } from 'shared/utils/strings';
import { Physician, Product, SubscribeData } from '../../services/types';
import { PhysiciansByAddon, SubscriptionFormData } from './types';

interface NormalizerSubscriptionParams {
  subscriptionFormData: SubscriptionFormData;
  isAnnual: boolean;
  selectedPlan: Product;
  physicians: Physician[];
  physicianByAddon?: PhysiciansByAddon;
}

export function normalizeSubscriptionData({
  isAnnual,
  physicians,
  physicianByAddon,
  selectedPlan,
  subscriptionFormData,
}: NormalizerSubscriptionParams): SubscribeData {
  const [expirationMonth, expirationLastDigitsOfYear] =
    subscriptionFormData.cardExpirationDate.split('/');
  const firstDigitsOfYear = new Date().getFullYear().toString().substring(0, 2);
  const cardNumber = removeWhitespaces(subscriptionFormData.cardNumber);

  return {
    isAnnual,
    cpfCnpj: onlyNumbers(subscriptionFormData.cpfCnpj),
    turnstileToken: subscriptionFormData.turnstileToken,
    card: {
      cvv: subscriptionFormData.cardCVV,
      number: cardNumber,
      owner: subscriptionFormData.cardOwnerName,
      flag: getCreditCardNameByNumber(cardNumber),
      expirationMonth,
      expirationYear: `${firstDigitsOfYear}${expirationLastDigitsOfYear}`,
    },
    products: {
      [selectedPlan.slug]: physicians.map((physician) => physician.profile_id),
      ...physicianByAddon,
    },
  } as SubscribeData;
}

type AddonsSingleUserParams = {
  selectedAddons: Product[];
  physicians: Physician[];
};

export function setAddonsForSingleUser({
  selectedAddons,
  physicians,
}: AddonsSingleUserParams) {
  return selectedAddons.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.slug]: [physicians[0].profile_id],
    }),
    {},
  );
}
