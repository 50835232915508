import { combineReducers } from 'redux';

import register, {
  SellerRegisterState,
  initialState as initialStateRegister,
} from './register';
import edit, {
  SellerEditState,
  initialState as initialStateEdit,
} from './edit';
import bankAccount, {
  BankAccountState,
  initialState as initialStateBankAccount,
} from './bankAccount';

export type ConfigState = {
  register: SellerRegisterState;
  edit: SellerEditState;
  bankAccount: BankAccountState;
};

export const configInitialState = {
  register: initialStateRegister,
  edit: initialStateEdit,
  bankAccount: initialStateBankAccount,
};

const configReducer = combineReducers<ConfigState>({
  register,
  edit,
  bankAccount,
});

export default configReducer;
