// External
import React, { useMemo, useRef } from 'react';
import { connect } from 'react-redux';
import Icon from '@material/react-material-icon';

import NotificationExpireMessage from 'shared/components/NotificationExpireMessage';
// Internal
import { StoreState } from 'state/rootReducer';
import * as actions from 'state/records/attachments/imageComparison/actions';
import {
  getFormatedDate,
  groupImages,
  selectImg,
} from 'state/records/attachments/imageComparison/utils';
import {
  ItemImg,
  ImageList,
} from 'state/records/attachments/imageComparison/types';
import { image as imagefunc } from 'shared/utils/urlGetter';
import Loader from 'ui/new/loader';
import Button from 'ui/new/button';
import Modal from 'ui/new/modal';
import CheckImage from 'ui/new/check-image';
import { ALIGN, COLOR } from 'ui/new/check-image/constants';
import { MODAL, FOOTER } from 'ui/new/modal/constants';
import { BUTTON } from 'ui/new/button/constants';
import ImageCompare from 'ui/new/image-compare/ImageCompare';
import MODE from './constants';
import {
  Group,
  ImgList,
  DisplayImage,
  Title,
  Summary,
  Canvas,
} from '../components';

const mapStateToProps = (state: StoreState) => ({
  isOpenModal: state.records.attachments.imageComparison.isOpenModal,
  images: state.records.attachments.imageComparison.images,
  itemImg: state.records.attachments.imageComparison.itemImg,
  mode: state.records.attachments.imageComparison.mode,
  isFetching: state.records.attachments.imageComparison.isFetching,
  isSavingComparison:
    state.records.attachments.imageComparison.isSavingComparison,
  notification: state.records.attachments.imageComparison.notification,
});

const mapDispatchToProps = actions;

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type MapDispatchToProps = typeof mapDispatchToProps;
type ImageComparisonProps = MapStateToProps & MapDispatchToProps;

// eslint-disable-next-line sonarjs/cognitive-complexity
function ImageComparison({
  // store
  isOpenModal,
  images,
  mode,
  isFetching,
  isSavingComparison,
  notification,
  itemImg,
  // actions
  closeModal,
  selectImage,
  setMode,
  canvasImage,
}: ImageComparisonProps): JSX.Element {
  const canvasRef = useRef(null);
  const getTitleError: string = useMemo(
    () =>
      images.length === 0
        ? 'Seu paciente ainda não possui nenhuma foto.'
        : 'Seu paciente possui apenas uma foto.',
    [images],
  );

  const showCompareIcon = useMemo(() => images.length > 0, [images]);
  const selectedImages = useMemo(
    () => images.filter((img: ItemImg) => img.checked).reverse(),
    [images],
  );
  const imagesList = useMemo(() => groupImages(images), [images]);
  const displayError = useMemo(() => mode !== MODE.view && images.length <= 1, [
    images,
    mode,
  ]);
  const compareImage = useMemo(
    () => mode === MODE.compare && selectedImages.length > 1,
    [mode, selectedImages],
  );
  const selectImages = useMemo(
    () => mode === MODE.select && images.length > 1,
    [images, mode],
  );
  const displayImage = useMemo(() => mode === MODE.view && itemImg, [
    mode,
    itemImg,
  ]);
  const displayBackButton = useMemo(
    () =>
      (!displayError &&
        mode === MODE.select &&
        itemImg &&
        selectedImages.length > 0) ||
      mode === MODE.compare,
    [displayError, mode, itemImg, selectedImages.length],
  );
  const displayBackButtonResponsive = useMemo(
    () => !displayError && mode === MODE.compare,
    [displayError, mode],
  );
  const footerAligment = displayBackButton ? FOOTER.split : FOOTER.right;
  const setStepBack = () => {
    if (mode === MODE.compare) return setMode(MODE.select);
    if (selectedImages.length > 1) selectImg(selectedImages[1].id, images);
    return setMode(MODE.view);
  };

  const containerSummary = useMemo(
    () => (
      <Summary selectedImages={selectedImages}>
        {selectedImages.length === 2 && (
          <Button
            idGa="prontuario-comparar_imagens-imagem_selecionada"
            onClick={() => setMode('compare')}
          >
            COMPARAR IMAGENS
          </Button>
        )}
      </Summary>
    ),
    [selectedImages, setMode],
  );

  function saveImageRecords() {
    const canvas = canvasRef.current;
    canvasImage(canvas, selectedImages, true);
  }

  return (
    <>
      <Modal
        show={isOpenModal}
        fullscreen
        color="dark"
        title=""
        type={MODAL.Success}
        onClose={closeModal}
        lockOverflow
      >
        {displayBackButtonResponsive && (
          <Button classes="mobile" type={BUTTON.Dark} onClick={setStepBack}>
            <Icon icon="arrow_back" />
          </Button>
        )}
        {isFetching && (
          <Group classes="-central">
            <Modal.Body>
              <Loader color="white" />
            </Modal.Body>
          </Group>
        )}
        {!isFetching && displayImage && (
          <Group classes="-central">
            <Modal.Body>
              <DisplayImage
                displayCompareMode={showCompareIcon}
                setModeCompare={() => setMode(MODE.select)}
                selectedImages={itemImg}
              />
            </Modal.Body>
          </Group>
        )}
        {!isFetching && compareImage && (
          <Group classes="-central">
            <Modal.Body>
              <ImageCompare directionH images={selectedImages} />
            </Modal.Body>
          </Group>
        )}
        {!isFetching && selectImages && (
          <Group classes="-start">
            <Modal.Body>
              <ImgList>
                <div>
                  <Title>
                    <p>
                      Você está na galeria de imagens do seu paciente.
                      <br />
                      Para começar a comparação
                      <b> selecione duas imagens </b>e depois clique em
                      comparar.
                    </p>
                  </Title>
                  {imagesList &&
                    imagesList.map((base: ImageList) => (
                      <div key={base.date.toString()}>
                        <Group
                          key={base.date.toString()}
                          title={getFormatedDate(base.date)}
                        >
                          {base.images &&
                            base.images.map((image) => (
                              <CheckImage
                                key={image.id}
                                showSelectIcon
                                align={ALIGN.Left}
                                color={COLOR.White}
                                disabled={
                                  selectedImages.length >= 2 && !image.checked
                                }
                                selected={image.checked}
                                onClickCheck={() => selectImage(image.id)}
                                title={image.description}
                                width="160px"
                                src={image.max_size_image}
                                alt={image.description}
                              />
                            ))}
                        </Group>
                      </div>
                    ))}
                </div>
              </ImgList>
            </Modal.Body>
          </Group>
        )}
        {!isFetching && displayError && (
          <Group classes="-central">
            <Modal.Body>
              <div>
                <img
                  width="350px"
                  src={imagefunc('sadIclinico')}
                  alt="Seu paciente ainda não possui nenhuma foto."
                  title="Seu paciente ainda não possui nenhuma foto."
                />
                <Title classes="-small">
                  <h2>{getTitleError}</h2>
                </Title>
                <p>
                  Adicione duas ou mais imagens para realizar uma
                  <br />
                  comparação, para isso acesse o menabstru “Imagens e
                  <br />
                  Anexos” no prontuário.
                </p>
              </div>
            </Modal.Body>
          </Group>
        )}
        <Canvas canvasRef={canvasRef} />
        <Modal.Footer align={footerAligment}>
          {displayBackButton && (
            <Button
              classes={displayBackButtonResponsive ? '-responsive' : '-back'}
              type={BUTTON.Dark}
              onClick={setStepBack}
            >
              <Icon icon="arrow_back" />
              <span>Voltar</span>
            </Button>
          )}
          {selectImages && containerSummary}
          {mode === MODE.compare && (
            <Button
              idGa="prontuario-comparar_imagens-adicionar_prontuario"
              onClick={saveImageRecords}
              loading={isSavingComparison}
              disabled={isSavingComparison}
            >
              Adicionar ao prontuário
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {notification.show && (
        <NotificationExpireMessage
          kind={notification.kind}
          title={notification.title}
          showCloseButton
          onExpire={() => {}}
          expireAfter={4000}
        >
          {notification.description}
        </NotificationExpireMessage>
      )}
    </>
  );
}

export default connect<MapStateToProps, MapDispatchToProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ImageComparison);
