import {
  theme as themeUI,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';
import { DEFAULT_DRAWER_CONSTRAINTS } from 'features/video-conference/constants';

const { makeStyles, createMuiTheme } = MaterialUICore;
const { color } = tokens;
const drawerWidth = DEFAULT_DRAWER_CONSTRAINTS.width;

type Props = {
  isMobile: boolean;
};

const theme = createMuiTheme(themeUI);

export default makeStyles<{}, Props>(() => ({
  room: {
    display: 'flex',
    backgroundColor: color.neutral[900],
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: ({ isMobile }) =>
      isMobile ? 'flex-start' : 'space-between',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
    [theme.breakpoints.up(320)]: {
      height: ({ isMobile }) => (isMobile ? 'calc(100% - 144px)' : '100%'),
    },
  },
  contentShift: {
    width: ({ isMobile }) =>
      isMobile ? '100%' : `calc(100% - ${drawerWidth}px - 24px)`,
    height: ({ isMobile }) => (isMobile ? '30%' : '100%'),
    overflowY: ({ isMobile }) => (isMobile ? 'auto' : 'hidden'),
  },
}));
