import React, { useCallback, useEffect, useState } from 'react';

import { MaterialIcons } from '@iclinic/design-system';
import { Message } from 'features/video-conference/constants/types';
import {
  OuterContainer,
  InnerScrollContainer,
  MessageListContainer,
  Button,
} from './styles';
import { usePrevious } from 'shared/hooks';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { hasNewChatMessages } from 'features/video-conference/utils';

const { ArrowDownward } = MaterialIcons;

interface OwnProps {
  messages: Message[];
  children: React.ReactNode;
}

export default function MessageListScrollContainer({
  children,
  messages,
}: OwnProps) {
  const { remoteParticipant } = useParticipantContext();
  const chatThreadRef = React.createRef<HTMLDivElement>();

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [messageNotificationCount, setMessageNotificationCount] = useState(0);
  const prevMessages = usePrevious(messages);

  const scrollToBottom = useCallback(() => {
    const innerScrollContainerEl = chatThreadRef.current!;
    innerScrollContainerEl.scrollTop = innerScrollContainerEl!.scrollHeight;
  }, [chatThreadRef]);

  const handleScroll = useCallback(() => {
    const innerScrollContainerEl = chatThreadRef.current!;

    if (!innerScrollContainerEl) return;

    const hasScrolledToBottom =
      Math.abs(
        innerScrollContainerEl.clientHeight +
          innerScrollContainerEl.scrollTop -
          innerScrollContainerEl!.scrollHeight,
      ) < 1;

    setIsScrolledToBottom(hasScrolledToBottom);
    setShowButton((prevState) => (hasScrolledToBottom ? false : prevState));
  }, [chatThreadRef]);

  useEffect(() => {
    const innerScrollContainerEl = chatThreadRef.current!;
    innerScrollContainerEl.addEventListener('scroll', handleScroll);

    return () => {
      innerScrollContainerEl.removeEventListener('scroll', handleScroll);
    };
  }, [chatThreadRef, handleScroll]);

  useEffect(() => {
    if (!prevMessages) return;

    const hasNewRemoteMessages = hasNewChatMessages(
      prevMessages,
      messages,
      remoteParticipant?.name,
    );

    if (!isScrolledToBottom && hasNewRemoteMessages) {
      const numberOfNewMessages = messages.length - prevMessages?.length;
      setShowButton(true);
      setMessageNotificationCount((previousState) =>
        showButton ? previousState + numberOfNewMessages : 1,
      );
      return;
    }

    if (!showButton) scrollToBottom();
  }, [
    isScrolledToBottom,
    messages,
    prevMessages,
    remoteParticipant,
    scrollToBottom,
    showButton,
  ]);

  const handleClick = () => {
    const innerScrollContainerEl = chatThreadRef.current!;
    innerScrollContainerEl.scrollTo({
      top: innerScrollContainerEl.scrollHeight,
      behavior: 'smooth',
    });

    setShowButton(false);
  };

  return (
    <OuterContainer>
      <InnerScrollContainer ref={chatThreadRef}>
        <MessageListContainer>
          {children}
          <Button
            isShowingButton={showButton}
            onClick={handleClick}
            startIcon={<ArrowDownward />}
            color="primary"
            variant="contained"
          >
            {messageNotificationCount}
          </Button>
        </MessageListContainer>
      </InnerScrollContainer>
    </OuterContainer>
  );
}
