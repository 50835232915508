import React, { createContext, useContext, useMemo, useState } from 'react';

import { TranscriptItem } from 'features/video-conference/services/types';

interface CaptionProviderProps {
  children: React.ReactNode;
}

type CaptionContextType = {
  remoteTranscript: string;
  setRemoteTranscript: (value: string) => void;
  isCaptionActive: boolean;
  setIsCaptionActive: (value: boolean) => void;
  isRemoteCaptionActive: boolean;
  setIsRemoteCaptionActive: (value: boolean) => void;
  elapsedTime: number;
  setElapsedTime: React.Dispatch<React.SetStateAction<number>>;
  transcriptList: TranscriptItem[];
  setTranscriptList: React.Dispatch<React.SetStateAction<TranscriptItem[]>>;
};

export const CaptionContext = createContext<CaptionContextType>(null!);

export const CaptionProvider = ({ children }: CaptionProviderProps) => {
  const [remoteTranscript, setRemoteTranscript] = useState<string>('');
  const [isCaptionActive, setIsCaptionActive] = useState<boolean>(false);
  const [isRemoteCaptionActive, setIsRemoteCaptionActive] =
    useState<boolean>(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [transcriptList, setTranscriptList] = useState<TranscriptItem[]>([]);

  const contextValue = useMemo(
    () => ({
      remoteTranscript,
      setRemoteTranscript,
      isCaptionActive,
      setIsCaptionActive,
      isRemoteCaptionActive,
      setIsRemoteCaptionActive,
      elapsedTime,
      setElapsedTime,
      transcriptList,
      setTranscriptList,
    }),
    [
      remoteTranscript,
      isCaptionActive,
      isRemoteCaptionActive,
      elapsedTime,
      setElapsedTime,
      transcriptList,
      setTranscriptList,
    ],
  ) as CaptionContextType;

  return (
    <CaptionContext.Provider value={contextValue}>
      {children}
    </CaptionContext.Provider>
  );
};

export function useCaptionContext(): CaptionContextType {
  const context = useContext(CaptionContext);

  if (!context) {
    throw new Error('useCaptionContext must be used within an CaptionProvider');
  }
  return context;
}
