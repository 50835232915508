/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Body,
  BodyProps,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, spacing } = tokens;
const BREAKPOINT_SM = '@media (min-width: 600px)';

type TooltipProps = {
  active: boolean;
};

export const TooltipText = styled(
  ({ active, ...rest }: TooltipProps & BodyProps) => <Body {...rest} />,
)({
  position: 'absolute',
  visibility: 'hidden',
  opacity: 0,
  width: 108,
  border: `1px solid ${color.neutral[100]}`,
  backgroundColor: ({ active }: TooltipProps) =>
    active ? color.feedback.warning.main : color.neutral[0],
  color: ({ active }: TooltipProps) =>
    active ? color.neutral[900] : color.neutral[500],
  textAlign: 'center',
  borderRadius: border.radius.md,
  padding: `${spacing.sm} ${spacing.base}`,
  zIndex: 1,
  top: `calc(100% + ${spacing.nano})`,
  left: '50%',
  transform: 'translateX(-50%)',
  transition: 'visibility .2s ease-out, opacity .3s ease-in-out',

  '&:after': {
    content: '""',
    position: 'absolute',
    width: 16,
    height: 16,
    backgroundColor: ({ active }: TooltipProps) =>
      active ? color.feedback.warning.main : color.neutral[0],
    left: '50%',
    bottom: `calc(100% - ${spacing.base})`,
    marginLeft: `-${spacing.base}`,
    border: '1px solid transparent',
    borderTopLeftRadius: 2,
    borderLeftColor: color.neutral[100],
    borderTopColor: color.neutral[100],
    transform: 'rotate(45deg)',
  },
  [BREAKPOINT_SM]: {
    width: 180,
  },
});

export const Tooltip = styled('div')({
  position: 'relative',
  display: 'inline-block',

  '&:hover .MuiTypography-root': {
    visibility: 'visible',
    opacity: 1.0,
  },
});
