import React from 'react';
import { Button, Heading, Body, MaterialIcons } from '@iclinic/design-system';

import Table from './Table';
import * as Styles from './styles';

const { Add } = MaterialIcons;

function LotList() {
  const classes = Styles.useStyles();

  return (
    <Styles.Container>
      <Styles.Header>
        <Heading variant="lg">Lotes SP/SADT</Heading>
        <Body variant="sm">
          Filtre abaixo na tabela de lotes fechados para visualizar ou crie um
          novo lote.
        </Body>

        <Button
          classes={{ root: classes.buttonRadius }}
          href="/faturamento-tiss/lotes/novo/sadt"
          startIcon={<Add />}
          size="medium"
        >
          Novo lote
        </Button>
      </Styles.Header>

      <Styles.Content>
        <Table />
      </Styles.Content>
    </Styles.Container>
  );
}

export default LotList;
