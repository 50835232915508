// External
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

// Internal
import ActionableCard from 'ui/new/actionable-card';
import { STATUS } from 'ui/new/actionable-card/constants';
import { cardsName } from 'state/campaign/constants';
import Button from 'ui/new/button';
import { Input } from 'ui/new/form';
// Style
import style from './CampaignMailEditor.scss';

const CampaignMailEditor = ({
  activeCard,
  setActiveCard,
  saveMail,
  children,
  cardStatus,
  senders,
}) => {
  const collapsed = activeCard !== cardsName.mail;
  const onHeaderClick = React.useCallback(
    () => setActiveCard(collapsed ? cardsName.mail : cardsName.none),
    [collapsed, setActiveCard],
  );

  return (
    <ActionableCard
      title="Escrever mensagem"
      status={cardStatus}
      collapsed={collapsed}
      onHeaderClick={onHeaderClick}
    >
      <ActionableCard.Body classes={style}>
        <div className={style.container}>
          <div className={style.row}>
            <div className={style.container__basisMiddleForm}>
              <p className={style.titleField}>Nome do remetente</p>
              <Field
                type="text"
                name="mail.sender.name"
                label="name"
                component="select"
                className={style.container__filterSenderName}
              >
                {senders.map((item) => (
                  <option value={item.name} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </Field>
            </div>
            <div className={style.container__basisMiddleForm}>
              <p className={style.titleField}>E-mail do remetente</p>
              <Field
                type="text"
                name="mail.sender.email"
                label="email"
                component="select"
                className={style.container__filterSenderName}
              >
                {senders.map((item) => (
                  <option value={item.email} key={item.id}>
                    {item.email}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className={style.container__basisAll}>
            <p className={style.titleField}>Assunto do e-mail</p>
            <Field
              type="text"
              name="mail.subject"
              label="subject"
              component={Input}
            />
          </div>
          <div className={style.container__basisAll}>{children}</div>
        </div>
      </ActionableCard.Body>
      <ActionableCard.Footer>
        <Button kind="primary" onClick={saveMail}>
          Salvar
        </Button>
      </ActionableCard.Footer>
    </ActionableCard>
  );
};

CampaignMailEditor.propTypes = {
  activeCard: PropTypes.string.isRequired,
  saveMail: PropTypes.func.isRequired,
  setActiveCard: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  cardStatus: PropTypes.oneOf(Object.values(STATUS)).isRequired,
  senders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default CampaignMailEditor;
