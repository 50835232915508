import {
  MaterialUICore,
  DrawerHeader as DrawerHeaderDS,
  axiomTokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = axiomTokens;

export const TranscriptionWrapper = styled('div')({
  padding: spacing[4],
});

export const DrawerHeader = styled(DrawerHeaderDS)({
  marginTop: '50px',
});
