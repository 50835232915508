import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme) => ({
  dialog: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  title: {
    fontSize: '21px',
  },
  exitButton: {
    color: theme.palette.text.secondary,
  },
  inputWidth: {
    width: '154px',
  },
  mainButton: {
    minWidth: '100px',
  },
  fieldMargin: {
    marginRight: theme.spacing(3),
  },
}));

export default useStyles;
