// Internal
import { reducerMerge } from 'shared/utils/reducer';
import * as types from './types';

export const initialState = {
  updatePermissionsUrl: '',
  currentModal: 'finances',
  isFetching: false,
  isOpen: false,
  step: 1,
  selectedProductSlug: 'pro',
  selectedProduct: null,
  products: [],
  paymentInfo: null,
  userInfo: null,
  isAnnual: false,
  errors: [],
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const upgrade = (state = initialState, action) => {
  switch (action.type) {
    case types.UPGRADE_DETAIL.OPEN:
    case types.UPGRADE_DETAIL.CLOSE:
    case types.DETAILS_VIEW.OPEN:
    case types.PRODUCTS_VIEW.OPEN:
    case types.SUCCESS_VIEW.OPEN:
    case types.SELECT_PRODUCT.ACTION:
    case types.SELECT_PRODUCT.SUCCESS:
    case types.FETCH_UPGRADE_DETAIL.ACTION:
    case types.FETCH_UPGRADE_DETAIL.SUCCESS:
    case types.FETCH_UPGRADE_DETAIL.FAILURE:
    case types.FETCH_UPGRADE_CONFIRM.SUCCESS:
    case types.FETCH_UPGRADE_CONFIRM.FAILURE:
    case types.FETCH_UPGRADE_NOTIFICATION.ACTION:
    case types.FETCH_UPGRADE_NOTIFICATION.SUCCESS:
    case types.FETCH_UPGRADE_NOTIFICATION.FAILURE:
    case types.FETCH_UPGRADE_PENDING.ACTION:
      return reducerMerge({}, state, action.payload);
    default:
      return state;
  }
};

export type UpgradeState = typeof initialState;
export default upgrade;
