import { all, fork, takeLatest, call, put } from 'redux-saga/effects';

import latestDiagnosesSagas from 'features/records/latestDiagnoses/state/sagas';
import feedDataLayer from 'features/feedDataLayer/state/sagas';
import { handlerErrors } from 'state/shared/sagas';
import iclinic from 'services/iclinic';
import memedSagas from './memed/sagas';
// eslint-disable-next-line import/no-cycle
import signatureSagas from './signature/sagas';
import attachmentsSagas from './attachments/sagas';
import highlightsInfoSagas from '../../features/records/highlightsInfo/state/sagas';
import calculatorDrawerSagas from '../../features/records/calculatorDrawer/state/sagas';
import transcriptionDrawerSagas from 'features/records/transcriptionDrawer/state/sagas';
import cardPatientSagas from 'features/records/cardPatient/state/sagas';
import * as types from './types';
import * as actions from './actions';

export function* workerGetEventsBlocksIds({ payload: { eventId, patientId } }) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.records.getResumeRecords,
      patientId,
      eventId,
    );

    if (errors) throw errors;
    const responseEventsResume = getResponseData();

    yield put(
      actions.getEventBlocksIdsSuccess(responseEventsResume, patientId),
    );
  } catch (error) {
    yield call(handlerErrors, error, actions.getEventBlocksIdsFailure);
  }
}

export default function* recordSagas() {
  yield all([
    yield fork(memedSagas),
    yield fork(attachmentsSagas),
    yield fork(signatureSagas),
    yield fork(highlightsInfoSagas),
    yield fork(calculatorDrawerSagas),
    yield fork(transcriptionDrawerSagas),
    yield fork(feedDataLayer),
    yield fork(latestDiagnosesSagas),
    yield fork(cardPatientSagas),
    yield takeLatest(
      types.GET_EVENT_BLOCKS_IDS.ACTION,
      workerGetEventsBlocksIds,
    ),
  ]);
}
