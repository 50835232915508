import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import TransactionsHistoryPage from './history/TransactionsHistoryPage';

export default function ReportTransactions({
  match,
}: RouteComponentProps): JSX.Element {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/transacoes`}
        component={TransactionsHistoryPage}
      />
    </Switch>
  );
}
