import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import uuid from 'uuid/v1';
import {
  convertToRaw,
  EditorState,
  convertFromHTML,
  convertFromRaw,
  ContentState,
  RawDraftEntityRange,
  RawDraftContentBlock,
  getSafeBodyFromHTML,
} from 'draft-js';
import { ApiErrors } from '@types';

import { StoreState } from 'state/rootReducer';
import { FlowType } from 'features/marketing/constants';
import {
  FORM_LIST_EMAIL_FLOW_RETURN,
  FORM_ADD_EMAIL_FLOW_MODAL,
  FORM_EDIT_EMAIL_FLOW_RETURN,
} from 'shared/constants/forms';
import { stateToHTML } from 'state/campaign/utils';
import { decorator, blockRender, optionsExport } from 'ui/new/editor';
import { typeStyleEditor } from 'ui/new/editor/constants';
import { searchEmailFlowName } from './constants';
import {
  EmailFlow,
  Physician,
  CheckEntityRangeValid,
  EmailFlowComponent,
  Sender,
} from './emailFlowTypes';

export const getEmailFlowListReturnForm = formValueSelector(
  FORM_LIST_EMAIL_FLOW_RETURN,
);
const getEmailFlowReturnForm = formValueSelector(FORM_EDIT_EMAIL_FLOW_RETURN);

export const getEmailFlowReturnValues = (state: StoreState) =>
  getEmailFlowReturnForm(
    state,
    'name',
    'clinicId',
    'flowType',
    'physicianId',
    'sender',
    'status',
    'active',
    'currentComponent',
    'activeEmailFlow',
    'selectedProfessional',
  );
export const getEmailFlows = ({ marketing }: StoreState): EmailFlow[] =>
  marketing.emailFlow.automatedEmailFlow.emailFlows;
export const getUserProfile = ({ marketing }: StoreState): Physician[] =>
  marketing.emailFlow.automatedEmailFlow.physician.physicians;
export const getPhysicianSelected = ({ marketing }: StoreState): number =>
  marketing.emailFlow.automatedEmailFlow.physicianIdSelected;
export const getEmailFlow = ({
  marketing,
}: StoreState): EmailFlow | undefined =>
  marketing.emailFlow.automatedEmailFlow.emailFlowDetails.emailFlow;
export const getActiveCard = ({ marketing }: StoreState) =>
  marketing.emailFlow.automatedEmailFlow.activeComponentsCard;
export const getCurrentComponent = ({ marketing }: StoreState) =>
  marketing.emailFlow.automatedEmailFlow.currentComponent;
export const getActiveEmailFlow = ({ marketing }: StoreState) =>
  marketing.emailFlow.automatedEmailFlow.activeEmailFlow;
export const getErrorsUpdateComponent = ({ marketing }: StoreState) =>
  marketing.emailFlow.automatedEmailFlow.componentUpdate.errors;
export const getErrorsCreateComponent = ({ marketing }: StoreState) =>
  marketing.emailFlow.automatedEmailFlow.componentCreate.errors;
export const getErrorsDeleteComponent = ({ marketing }: StoreState) =>
  marketing.emailFlow.automatedEmailFlow.componentDelete.errors;
export const getActiveEmailFlowCard = ({ marketing }: StoreState) =>
  marketing.emailFlow.automatedEmailFlow.activeEmailFlowCard;

export const getErrorsComponent = createSelector(
  getErrorsCreateComponent,
  getErrorsDeleteComponent,
  getErrorsUpdateComponent,
  (createComponent, deleteComponent, updateComponent) => {
    const listErrors = createComponent
      .concat(deleteComponent)
      .concat(updateComponent);
    if (listErrors) {
      return listErrors.map(({ code, message }: ApiErrors) => ({
        code,
        message,
        id: uuid(),
      }));
    }
    return [];
  },
);

export const getFirstPhysicianSender = ({ marketing }: StoreState): Sender => ({
  email: marketing.emailFlow.automatedEmailFlow.physician.physicians[0].email,
  name: marketing.emailFlow.automatedEmailFlow.physician.physicians[0].name,
});

export const getPhysicians = createSelector(getUserProfile, (physicians) => {
  if (!physicians) {
    return [];
  }
  return physicians.filter(({ kind }) => kind === 'p');
});

export const getEmailFlowListForDisplay = createSelector(
  getEmailFlows,
  (emailFlows) => {
    if (!emailFlows) {
      return [];
    }
    return emailFlows.filter(({ flowType }) => flowType === FlowType.return);
  },
);

const getEmailFlowSearchTerms = (state: StoreState): string =>
  (
    getEmailFlowListReturnForm(state, searchEmailFlowName) || ''
  ).toLocaleLowerCase();

export const getEmailFlowCustomListForDisplay = createSelector(
  getEmailFlows,
  getEmailFlowSearchTerms,
  (emailFlows, searchTerms) => {
    if (!emailFlows) {
      return [];
    }

    const filtered = !getEmailFlowSearchTerms
      ? emailFlows
      : emailFlows.filter(
          ({ name }) => name.toLocaleLowerCase().indexOf(searchTerms) >= 0,
        );

    return filtered.filter((ef) => ef.flowType === FlowType.custom);
  },
);

const getAddEmailFlowModal = formValueSelector(FORM_ADD_EMAIL_FLOW_MODAL);
export const getAddEmailFlowModalName = (state: StoreState) =>
  getAddEmailFlowModal(state, 'name') || '';

export const getAllEmailFlowsNames = createSelector(
  (state: StoreState) =>
    state.marketing.emailFlow.automatedEmailFlow.emailFlows,
  (emailFlows) => emailFlows.map((emailFlow) => emailFlow.name),
);

export const getCreatedEmailFlow = (state: StoreState): EmailFlow | undefined =>
  state.marketing.emailFlow.automatedEmailFlow.emailFlowCreate.emailFlow;

export const getEmailFlowDetails = (state: StoreState): EmailFlow | undefined =>
  state.marketing.emailFlow.automatedEmailFlow.emailFlowDetails.emailFlow;
export const countComponentsEmailFlow = createSelector(
  getEmailFlowDetails,
  (emailFlow) => {
    if (!emailFlow || !emailFlow.components) {
      return 0;
    }

    return emailFlow.components.length;
  },
);
export const getInitialFormData = createSelector(
  getEmailFlow,
  getCurrentComponent,
  getActiveEmailFlow,
  getPhysicians,
  (emailFlow, currentComponent, activeEmailFlow, physicians) => {
    if (!emailFlow) {
      return {};
    }

    const { sender } = emailFlow;
    if (physicians && physicians.length) {
      const firstProfile = physicians[0];
      const { email, name } = sender;
      if (!email) {
        sender.email = firstProfile.email;
      }
      if (!name) {
        sender.name = firstProfile.name;
      }
    }

    let currentComponentOverride: EmailFlowComponent = {
      id: '',
      emailFlow,
      emailFlowId: emailFlow.id,
      position: 0,
      clinicId: 0,
    };

    if (!currentComponent) {
      const { components } = emailFlow;
      if (components) {
        currentComponentOverride = { ...components[0] };
      }
    }

    return {
      ...emailFlow,
      currentComponent: currentComponent || currentComponentOverride,
      activeEmailFlow,
      sender,
    };
  },
);

export const getEditor = ({ marketing }: StoreState) =>
  marketing.emailFlow.automatedEmailFlow.editor;
export const getEditorState = (state: StoreState) =>
  getEditor(state).editorState;
export const getEditorSelection = (state: StoreState) =>
  getEditorState(state).getSelection();
export const getEditorSelectionAnchorKey = (state: StoreState) =>
  getEditorSelection(state).getAnchorKey();
export const getCurrentContentEditor = (state: StoreState) =>
  getEditorState(state).getCurrentContent();
export const getEditorStateHTML = (state: StoreState) =>
  stateToHTML(getCurrentContentEditor(state), optionsExport);
export const getCurrentBlock = (state: StoreState) =>
  getCurrentContentEditor(state).getBlockForKey(
    getEditorSelectionAnchorKey(state),
  );
export const getCurrentEntityKey = (state: StoreState) =>
  getCurrentBlock(state).getEntityAt(
    getEditorSelection(state).getStartOffset(),
  );
export const getStartOffset = (state: StoreState) =>
  getEditorSelection(state).getStartOffset();
export const getEndOffset = (state: StoreState) =>
  getEditorSelection(state).getEndOffset();
export const getCurrentInlineStyles = (state: StoreState) =>
  getEditorState(state).getCurrentInlineStyle();

export const getCurrentSelectionText = createSelector(
  (state) => getCurrentBlock(state).getText(),
  getStartOffset,
  getEndOffset,
  (text, startOffset, endOffset) => text.slice(startOffset, endOffset),
);

export const getCurrentEntity = (state: StoreState) => {
  const entityKey = getCurrentEntityKey(state);
  return entityKey && getCurrentContentEditor(state).getEntity(entityKey);
};

const checkEntityRangeValid = ({
  offset,
  length,
  key,
  keySelection,
}: CheckEntityRangeValid) => {
  const isEntityRangeValid =
    offset <= keySelection && offset + length >= keySelection;
  if (isEntityRangeValid) {
    return { offset, length, key };
  }
  return undefined;
};

const EntityRangeEmpty = {
  key: 0,
  offset: 0,
  length: 0,
};

const getCurrentEntityDecoratedText = createSelector(
  getEditorSelection,
  getCurrentContentEditor,
  getCurrentBlock,
  (selection, currentContent, currentBlock) => {
    const block = convertToRaw(currentContent).blocks;
    const keySelection = selection.getStartOffset();

    let lastValidEntityRange = EntityRangeEmpty;
    block.forEach(({ entityRanges }: RawDraftContentBlock) => {
      entityRanges.forEach((entityRange: RawDraftEntityRange) => {
        if (checkEntityRangeValid({ ...entityRange, keySelection })) {
          lastValidEntityRange = entityRange;
        }
      });
    });

    const { offset, length } = lastValidEntityRange;
    const end = offset + length;
    const text = currentBlock.getText().slice(offset, end);

    return { start: offset, end, text };
  },
);

export const getCurrentEntityDecoratedMerge = createSelector(
  getEditorSelection,
  getCurrentEntityDecoratedText,
  (selection, entityDecoratedText) => {
    const { start, end } = entityDecoratedText;
    return selection.merge({ anchorOffset: start, focusOffset: end });
  },
);

export const getEditorLink = createSelector(
  getCurrentEntity,
  getCurrentEntityDecoratedText,
  getCurrentSelectionText,
  (currentEntity, currentDecoratedText, currentSelection) => {
    if (currentEntity) {
      const { url } = currentEntity.getData();
      const { text } = currentDecoratedText;
      return { textSelected: text, inputUrl: url };
    }
    return { textSelected: currentSelection, inputUrl: '' };
  },
);

export const getEditorLinkModalInitialValues = (state: StoreState) => {
  const { textSelected, inputUrl } = getEditorLink(state);
  return { textSelected, inputUrl };
};

export const getEditorButtonModalInitialValues = (state: StoreState) => {
  const { textSelected, inputUrl } = getEditorLink(state);
  return { textSelected, inputUrl };
};

export const getEditorImageModalInitialValues = createSelector(
  getCurrentEntity,
  (currentEntity) => {
    if (currentEntity) {
      const { src } = currentEntity.getData();
      return { inputUrl: src };
    }
    return { inputUrl: '' };
  },
);

export const getEditorOptionsText = createSelector(
  (state: StoreState) => getEditor(state).optionsFormatText,
  getCurrentInlineStyles,
  (editorOptionsText, currentInlineStyles) => {
    let selected = typeStyleEditor.normalID;

    if (currentInlineStyles.has(typeStyleEditor.title)) {
      selected = typeStyleEditor.titleID;
    }
    return editorOptionsText.map((item) => ({
      ...item,
      selected: item.id === selected,
    }));
  },
);

export const getEditorUpdatedComponent = (state: StoreState) => {
  const formFields = getEmailFlowReturnValues(state);
  const body = getEditorStateHTML(state);
  const currentContent = getCurrentContentEditor(state);
  const draftJSState = convertToRaw(currentContent);
  return {
    ...formFields.currentComponent,
    body,
    draftJSState,
  };
};

export const getInitialEditor = createSelector(
  getCurrentComponent,
  (currentComponent) => {
    if (!currentComponent) {
      return EditorState.createEmpty(decorator);
    }

    if (currentComponent) {
      const { draftJSState, body } = currentComponent;

      if (Object.keys(draftJSState).length) {
        const { blocks } = draftJSState;
        if (blocks.length) {
          return EditorState.createWithContent(
            convertFromRaw(draftJSState),
            decorator,
          );
        }
      }
      if (!body) {
        return EditorState.createEmpty(decorator);
      }

      const blocksFromHTML = convertFromHTML(
        body,
        getSafeBodyFromHTML,
        blockRender,
      );
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );

      return EditorState.createWithContent(content, decorator);
    }

    return EditorState.createEmpty(decorator);
  },
);

export const getCurrentInfo = createSelector(
  getCurrentBlock,
  getCurrentInlineStyles,
  getCurrentEntity,
  getCurrentSelectionText,
  getCurrentEntityDecoratedText,
  (block, inlineStyles, entity, selectionText, entityDecoratedText) => ({
    block,
    blockType: block && block.getType(),
    inlineStyles,
    entity,
    entityType: entity && entity.getType(),
    entityData: entity && entity.getData(),
    selectionText,
    entityDecoratedText,
  }),
);

export const getUserInfo = (state: StoreState) => ({
  physicianId: state.userInfo.userData.physicianId,
  email: state.userInfo.userData.userEmail,
});
