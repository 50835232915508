import React from 'react';
import { Field } from 'redux-form';

import { Container } from '@iclinic/iclinic-ui';
import {
  requiredEmpty,
  fullDateEmpty,
  fullHoursEmpty,
} from 'shared/utils/formValidation';
import { FULL_DATE, HOUR_MINUTE } from 'shared/constants/masks';
import { Input } from 'ui/new/form';
import { informationFinalizeSchedule } from 'state/campaign/constants';
import style from './FinalizeCampaign.scss';

const validateScheduleDate = [requiredEmpty, fullDateEmpty];
const validateScheduleTime = [requiredEmpty, fullHoursEmpty];

const DateHourSelector = () => (
  <Container>
    <div className={style['date-time-picker-label']}>
      <p>Enviar no dia</p>
    </div>
    <div className={style['date-time-picker-container']}>
      <Field
        component={Input}
        name="scheduleDate"
        type="text"
        className={style['date-time-picker-date']}
        mask={FULL_DATE}
        placeholder="__/__/____"
        validate={validateScheduleDate}
      />
      <Field
        component={Input}
        name="scheduleTime"
        type="text"
        className={style['date-time-picker-time']}
        mask={HOUR_MINUTE}
        placeholder="00:00"
        validate={validateScheduleTime}
      />
      <span className={style['date-time-picker-info']}>
        *Horário de Brasilia
      </span>
    </div>
    <div className={style['date-time-picker-notice']}>
      <p>
        <strong>Atenção:</strong>
        {informationFinalizeSchedule}
      </p>
    </div>
  </Container>
);

export default DateHourSelector;
