// External
import { ContentBlock, ContentState, CharacterMetadata } from 'draft-js';

// Internal
import { typeStyleEditor } from '../constants';

const automatedTagStrategy = (
  block: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  block.findEntityRanges((character: CharacterMetadata) => {
    const entityKey = character.getEntity();
    return !!(
      entityKey && contentState.getEntity(
        entityKey,
      ).getType() === typeStyleEditor.automatedTag
    );
  }, callback);
};

export default automatedTagStrategy;
