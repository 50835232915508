// External
import React, { useContext } from 'react';

// Internal
import classnames from 'classnames/bind';
import ThemeContext, { UserThemeStyle } from 'shared/hooks/theme';
import ss from './Summary.scss';

const cx = classnames.bind(ss);

type SummaryProps = {
  classes?: string;
  children: React.ReactNode;
};

export default function Summary({
  classes,
  children,
}: SummaryProps): JSX.Element {
  const { isApplied, style } = useContext(ThemeContext);

  return (
    <div className={cx(ss.summary, classes, UserThemeStyle(ss, style, isApplied))}>
      <div className={ss.children}>{children}</div>
    </div>
  );
}
