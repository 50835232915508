// External
import { createSelector } from 'reselect';

// Internal
import { getSuccessAddonViews } from '../../shared/utils/views';
import { products } from './constants';

export const getSelectedProductSlug = (state) => (state.upgradeAddon.currentModal);
export const getPhysicians = (state) => (state.upgradeAddon.physicians);
export const getselectedPhysicians = (state) => (state.upgradeAddon.selectedPhysicians);
export const getSelectedHiring = (state) => (state.upgradeAddon.hiring);
export const getResumeAddon = (state) => (state.upgradeAddon.calculateUpgrade);
export const getproductDetail = (state) => (state.upgradeAddon.productDetail);
export const getAddonSubscription = (state) => (state.upgradeAddon.addons);


export const getIndividualUser = createSelector(
  getPhysicians,
  (physicians) => (physicians.length === 1),
);

export const getSuccessInfo = createSelector(
  [getSelectedHiring, getSelectedProductSlug],
  (hiring, slug) => (hiring === 'upgrade' ? getSuccessAddonViews(products[slug.replace('-', '')]) : getSuccessAddonViews('upgrade')),
);

export const getNamePhysicians = createSelector(
  [getPhysicians, getselectedPhysicians],
  (physicians,
    selectedPhysicians) => (selectedPhysicians.length > 0 ? (physicians.filter((physician) => (
    physician.checked
  )).map((physicianFilter) => (
    physicianFilter.name
  ))).join(',  ') : 'Selecione os profissionais'),
);

export const getpromotionTotalValue = createSelector(
  (getResumeAddon),
  (resume) => resume && resume.promotionTotalValue,
);

export const getTotalValue = createSelector(
  (getResumeAddon),
  (resume) => resume && resume.totalValue,
);

export const getproductValue = createSelector(
  (getproductDetail),
  (productDetail) => {
    if (productDetail && productDetail.promotion && !productDetail.annual) {
      if (productDetail.promotion.frequencies.monthly) {
        return productDetail.promotion.frequencies.monthly.value;
      }
    }
    if (productDetail && productDetail.promotion && productDetail.annual) {
      if (productDetail.promotion.frequencies.annual) {
        return productDetail.promotion.frequencies.annual.value;
      }
    }

    if (productDetail && !productDetail.annual) {
      return productDetail.values.monthly;
    }

    if (productDetail && productDetail.annual) {
      return productDetail.values.annual;
    }
    return null;
  },
);

export const getProductName = createSelector(
  (getproductDetail),
  (product) => (product.name),
);

export const getAnnual = createSelector(
  (getAddonSubscription),
  (addons) => (addons && addons.subscription.annual),
);
