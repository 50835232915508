import React from 'react';

import TabPrescription from './TabPrescription';

const Tabs = (kind) => {
  switch (kind) {
    default:
      return <TabPrescription />;
  }
};

export default Tabs;
