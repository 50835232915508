import React from 'react';
import './Configuring.css';

interface Props extends React.SVGProps<SVGSVGElement> {
  title: string;
}

function Configuring({ title, ...svgProps }: Props): JSX.Element {
  return (
    <svg
      id="configurando"
      width="200"
      height="160"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 200 200"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      {...svgProps}
    >
      <title>{title}</title>
      <defs>
        <linearGradient
          id="configurando-rectangle-182-fill"
          x1="179"
          y1="31"
          x2="177.500000"
          y2="50.837100"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="configurando-rectangle-182-fill-0"
            offset="0%"
            stopColor="rgb(255,255,255)"
          />
          <stop
            id="configurando-rectangle-182-fill-1"
            offset="100%"
            stopColor="rgb(160,207,248)"
          />
        </linearGradient>
        <linearGradient
          id="configurando-rectangle-178-fill"
          x1="179"
          y1="39"
          x2="39.574200"
          y2="170.669000"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="configurando-rectangle-178-fill-0"
            offset="0%"
            stopColor="rgb(255,255,255)"
          />
          <stop
            id="configurando-rectangle-178-fill-1"
            offset="100%"
            stopColor="rgb(160,207,248)"
          />
        </linearGradient>
        <linearGradient
          id="configurando-rect1-fill"
          x1="0"
          y1="0"
          x2="21.764200"
          y2="71.713000"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="configurando-rect1-fill-0"
            offset="0%"
            stopColor="rgb(74,164,242)"
          />
          <stop
            id="configurando-rect1-fill-1"
            offset="100%"
            stopColor="rgb(34,114,204)"
          />
        </linearGradient>
        <linearGradient
          id="configurando-path3-fill"
          x1="62.999700"
          y1="105.797000"
          x2="63.000300"
          y2="122.848000"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="configurando-path3-fill-0"
            offset="0%"
            stopColor="rgb(246,250,254)"
          />
          <stop
            id="configurando-path3-fill-1"
            offset="100%"
            stopColor="rgb(214,239,254)"
          />
        </linearGradient>
        <linearGradient
          id="configurando-path4-fill"
          x1="62.999700"
          y1="105.797000"
          x2="63.000300"
          y2="122.848000"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="configurando-path4-fill-0"
            offset="0%"
            stopColor="rgb(246,250,254)"
          />
          <stop
            id="configurando-path4-fill-1"
            offset="100%"
            stopColor="rgb(214,239,254)"
          />
        </linearGradient>
        <linearGradient
          id="configurando-rectangle-580-fill"
          x1="52.670900"
          y1="80.392900"
          x2="55.196800"
          y2="84.637700"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="configurando-rectangle-580-fill-0"
            offset="0%"
            stopColor="rgb(246,250,254)"
          />
          <stop
            id="configurando-rectangle-580-fill-1"
            offset="100%"
            stopColor="rgb(214,239,254)"
          />
        </linearGradient>
        <linearGradient
          id="configurando-rectangle-581-fill"
          x1="65.582500"
          y1="80.392900"
          x2="68.108400"
          y2="84.637700"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="configurando-rectangle-581-fill-0"
            offset="0%"
            stopColor="rgb(246,250,254)"
          />
          <stop
            id="configurando-rectangle-581-fill-1"
            offset="100%"
            stopColor="rgb(214,239,254)"
          />
        </linearGradient>
        <linearGradient
          id="configurando-union_2-fill"
          x1="74.835000"
          y1="76.083100"
          x2="65.813200"
          y2="92.669800"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="configurando-union_2-fill-0"
            offset="0%"
            stopColor="rgb(255,255,255)"
          />
          <stop
            id="configurando-union_2-fill-1"
            offset="100%"
            stopColor="rgb(160,207,248)"
          />
        </linearGradient>
        <linearGradient
          id="configurando-circle1-fill"
          x1="0"
          y1="0"
          x2="10.882100"
          y2="35.856700"
          spreadMethod="pad"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            id="configurando-circle1-fill-0"
            offset="0%"
            stopColor="rgb(74,164,242)"
          />
          <stop
            id="configurando-circle1-fill-1"
            offset="100%"
            stopColor="rgb(34,114,204)"
          />
        </linearGradient>
      </defs>
      <g id="configurando-configurando">
        <g id="configurando-screen">
          <g id="configurando-monitor">
            <path
              id="configurando-rectangle-182"
              d="M21,39C21,35.228800,21,33.343100,22.171600,32.171600C23.343100,31,25.228800,31,29,31L171,31C174.771000,31,176.657000,31,177.828000,32.171600C179,33.343100,179,35.228800,179,39L179,39L21,39L21,39Z"
              fill="url(#configurando-rectangle-182-fill)"
              stroke="none"
              strokeWidth="1"
            />
            <path
              id="configurando-rectangle-178"
              d="M21,39L179,39L179,143C179,146.771000,179,148.657000,177.828000,149.828000C176.657000,151,174.771000,151,171,151L29,151C25.228800,151,23.343100,151,22.171600,149.828000C21,148.657000,21,146.771000,21,143L21,39Z"
              fill="url(#configurando-rectangle-178-fill)"
              stroke="none"
              strokeWidth="1"
            />
          </g>
          <g id="configurando-bolinhas">
            <circle
              id="configurando-ellipse-58"
              r="2"
              transform="matrix(1 0 0 1 159 35)"
              fill="rgb(179,216,250)"
              stroke="none"
              strokeWidth="1"
            />
            <circle
              id="configurando-ellipse-59"
              r="2"
              transform="matrix(1 0 0 1 165 35)"
              fill="rgb(179,216,250)"
              stroke="none"
              strokeWidth="1"
            />
            <circle
              id="configurando-ellipse-60"
              r="2"
              transform="matrix(1 0 0 1 171 35)"
              fill="rgb(179,216,250)"
              stroke="none"
              strokeWidth="1"
            />
          </g>
        </g>
        <g id="configurando-data1_to" transform="translate(134,74)">
          <g id="configurando-data1_ts" transform="scale(1,1)">
            <rect
              id="configurando-data1"
              width="58"
              height="16"
              rx="1"
              ry="1"
              transform="translate(-29,-8)"
              fill="rgb(124,189,247)"
              stroke="none"
              strokeWidth="1"
            />
          </g>
        </g>
        <g id="configurando-data4_to" transform="translate(118.500000,116)">
          <g id="configurando-data4_ts" transform="scale(1,1)">
            <rect
              id="configurando-data4"
              width="27"
              height="16"
              rx="1"
              ry="1"
              transform="translate(-13.500000,-8)"
              fill="rgb(124,189,247)"
              stroke="none"
              strokeWidth="1"
            />
          </g>
        </g>
        <g id="configurando-data5_to" transform="translate(149.500000,116)">
          <rect
            id="configurando-data5"
            width="27"
            height="16"
            rx="1"
            ry="1"
            transform="translate(-13.500000,-8)"
            fill="rgb(160,207,248)"
            stroke="none"
            strokeWidth="1"
          />
        </g>
        <g id="configurando-data2_to" transform="translate(118.500000,97)">
          <rect
            id="configurando-data2"
            width="27"
            height="16"
            rx="1"
            ry="1"
            transform="translate(-13.500000,-8)"
            fill="rgb(160,207,248)"
            stroke="none"
            strokeWidth="1"
          />
        </g>
        <g id="configurando-data3_to" transform="translate(149.500000,97)">
          <g id="configurando-data3_ts" transform="scale(1,1)">
            <rect
              id="configurando-data3"
              width="27"
              height="16"
              rx="1"
              ry="1"
              transform="translate(-13.500000,-8)"
              fill="rgb(160,207,248)"
              stroke="none"
              strokeWidth="1"
            />
          </g>
        </g>
        <g id="configurando-medico-2_to" transform="translate(44,54)">
          <g id="configurando-medico-2_ts" transform="scale(0.800000,0.800000)">
            <g id="configurando-medico-2" transform="translate(-63,-89)">
              <g id="configurando-g1" clipPath="url(#configurando-clip0)">
                <g id="configurando-g2">
                  <rect
                    id="configurando-rect1"
                    width="68"
                    height="68"
                    rx="34"
                    ry="34"
                    transform="matrix(1 0 0 1 29 55)"
                    fill="url(#configurando-rect1-fill)"
                    stroke="none"
                    strokeWidth="1"
                  />
                  <g id="configurando-corpo">
                    <path
                      id="configurando-roupa"
                      d="M38.161600,112.218000C40.975000,108.122000,45.162500,104.977000,50.110200,102.889000C54.881900,100.875000,59.619100,97.906800,61.974800,93.474500L62.999200,90.944200L62.999600,90.942700L63.596700,92.592700C65.765400,97.517500,70.807800,100.744000,75.889300,102.889000C80.837000,104.977000,85.024600,108.122000,87.837900,112.218000C81.633100,118.853000,72.800800,123,62.999800,123C53.198900,123,44.366500,118.853000,38.161600,112.218000Z"
                      fill="rgb(255,255,255)"
                      stroke="none"
                      strokeWidth="1"
                    />
                    <g id="configurando-intersect">
                      <path
                        id="configurando-path1"
                        d="M61.017600,122.943000L61.667100,106.758000C61.689900,106.190000,62.156400,105.742000,62.724100,105.742000L63.206800,105.742000C63.774500,105.742000,64.241000,106.190000,64.263800,106.758000L64.913500,122.947000C64.280100,122.982000,63.642000,123,62.999800,123C62.334400,123,61.673500,122.981000,61.017600,122.943000Z"
                        fill="rgb(160,207,248)"
                        stroke="none"
                        strokeWidth="1"
                      />
                      <path
                        id="configurando-path2"
                        d="M62.301400,101.118000C61.647700,101.118000,61.150500,101.705000,61.258000,102.350000L61.542900,104.059000C61.627900,104.569000,62.069300,104.943000,62.586400,104.943000L63.344500,104.943000C63.861600,104.943000,64.302900,104.569000,64.387900,104.059000L64.672900,102.350000C64.780300,101.705000,64.283100,101.118000,63.629400,101.118000L62.301400,101.118000Z"
                        fill="rgb(160,207,248)"
                        stroke="none"
                        strokeWidth="1"
                      />
                    </g>
                    <g id="configurando-intersect_2">
                      <path
                        id="configurando-path3"
                        d="M52.341200,121.296000L48.051700,112.862000C47.880800,112.526000,47.930400,112.120000,48.177000,111.835000L50.570600,109.068000C50.895200,108.693000,50.867100,108.129000,50.506800,107.788000L47.837000,105.261000C47.388000,104.836000,47.473000,104.098000,48.020800,103.812000C48.686900,103.463000,49.369600,103.142000,50.067400,102.850000C52.265000,101.930000,54.455400,100.812000,56.413600,99.445600L59.766900,122.848000C57.197700,122.606000,54.711800,122.078000,52.341200,121.296000Z"
                        fill="url(#configurando-path3-fill)"
                        stroke="none"
                        strokeWidth="1"
                      />
                      <path
                        id="configurando-path4"
                        d="M66.232300,122.848000L69.585600,99.445600C71.543800,100.812000,73.734200,101.930000,75.931800,102.850000C76.629600,103.142000,77.312300,103.463000,77.978400,103.812000C78.526200,104.098000,78.611200,104.836000,78.162200,105.261000L75.492400,107.788000C75.132100,108.129000,75.104000,108.693000,75.428600,109.068000L77.822200,111.835000C78.068800,112.120000,78.118400,112.526000,77.947500,112.862000L73.658000,121.296000C71.287400,122.078000,68.801500,122.606000,66.232300,122.848000Z"
                        fill="url(#configurando-path4-fill)"
                        stroke="none"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                  <g id="configurando-cabe-a">
                    <path
                      id="configurando-rectangle-576"
                      d="M51.343300,68.002700C51.343300,64.174900,54.446300,61.071800,58.274200,61.071800L67.969500,61.071800C71.797300,61.071800,74.900400,64.174900,74.900400,68.002700L74.900400,74.120600C74.900400,76.034500,73.348900,77.586100,71.434900,77.586100L54.808700,77.586100C52.894800,77.586100,51.343300,76.034500,51.343300,74.120600L51.343300,68.002700Z"
                      fill="rgb(160,207,248)"
                      stroke="none"
                      strokeWidth="1"
                    />
                    <rect
                      id="configurando-rectangle-187"
                      width="22.681900"
                      height="30.242600"
                      rx="11.341000"
                      ry="11.341000"
                      transform="matrix(1 0 0 1 51.70950000000000 66.34130000000000)"
                      fill="rgb(255,255,255)"
                      stroke="none"
                      strokeWidth="1"
                    />
                    <path
                      id="configurando-rectangle-577"
                      d="M53.285600,70.907500C53.285600,65.475400,57.689200,61.071800,63.121400,61.071800L63.121400,61.071800C68.553500,61.071800,72.957100,65.475400,72.957100,70.907500L72.957100,72.000300L72.957100,72.000300C66.610600,69.649800,59.632100,69.649800,53.285600,72.000300L53.285600,72.000300L53.285600,70.907500Z"
                      fill="rgb(160,207,248)"
                      stroke="none"
                      strokeWidth="1"
                    />
                    <g id="configurando-rostinho">
                      <path
                        id="configurando-rectangle-580"
                        d="M52.670900,77.816200C52.670900,77.337700,53.058800,76.949800,53.537300,76.949800L59.551400,76.949800C60.029900,76.949800,60.417700,77.337700,60.417700,77.816200L60.417700,81.236800C60.417700,82.672300,59.254100,83.835900,57.818600,83.835900L55.270000,83.835900C53.834600,83.835900,52.670900,82.672300,52.670900,81.236800L52.670900,77.816200Z"
                        fill="url(#configurando-rectangle-580-fill)"
                        stroke="none"
                        strokeWidth="1"
                      />
                      <path
                        id="configurando-rectangle-581"
                        d="M65.582500,77.816200C65.582500,77.337700,65.970400,76.949800,66.448900,76.949800L72.463000,76.949800C72.941500,76.949800,73.329400,77.337700,73.329400,77.816200L73.329400,81.236800C73.329400,82.672300,72.165700,83.835900,70.730300,83.835900L68.181600,83.835900C66.746200,83.835900,65.582500,82.672300,65.582500,81.236800L65.582500,77.816200Z"
                        fill="url(#configurando-rectangle-581-fill)"
                        stroke="none"
                        strokeWidth="1"
                      />
                      <rect
                        id="configurando-rectangle-163"
                        width="1.851230"
                        height="3.085370"
                        rx="0.925614"
                        ry="0.925614"
                        transform="matrix(1 0 0 1 55.64550000000000 78.36839999999999)"
                        fill="rgb(124,189,247)"
                        stroke="none"
                        strokeWidth="1"
                      />
                      <rect
                        id="configurando-rectangle-164"
                        width="1.851220"
                        height="3.085380"
                        rx="0.925610"
                        ry="0.925610"
                        transform="matrix(1 0 0 1 68.60450000000000 78.36820000000000)"
                        fill="rgb(124,189,247)"
                        stroke="none"
                        strokeWidth="1"
                      />
                      <path
                        id="configurando-union"
                        d="M59.469400,89.454400C59.413900,89.343400,59.494600,89.212900,59.618600,89.212900L66.483600,89.212900C66.607600,89.212900,66.688300,89.343400,66.632800,89.454400L66.632800,89.454400C65.157100,92.405900,60.945100,92.405900,59.469400,89.454400L59.469400,89.454400Z"
                        fill="rgb(160,207,248)"
                        stroke="none"
                        strokeWidth="1"
                      />
                      <path
                        id="configurando-vector-12"
                        d="M64.289700,83.738900L63.167500,80.491700C63.098200,80.291200,63.063500,80.190900,63.001300,80.191300C62.939200,80.191700,62.905900,80.292400,62.839200,80.493900L61.759600,83.755500C61.576100,84.310000,61.484300,84.587200,61.527600,84.809700C61.576200,85.059400,61.732000,85.275100,61.953700,85.399600C62.151400,85.510600,62.443400,85.510600,63.027500,85.510600C63.618600,85.510600,63.914200,85.510600,64.112900,85.398100C64.335800,85.271900,64.491400,85.053500,64.537900,84.801600C64.579300,84.577100,64.482800,84.297700,64.289700,83.738900Z"
                        fill="rgb(179,216,250)"
                        stroke="none"
                        strokeWidth="1"
                      />
                    </g>
                    <g id="configurando-vector">
                      <path
                        id="configurando-path5"
                        d="M49.157200,79.457000C49.157200,78.540200,50.059700,77.895900,50.926800,78.193700C51.466300,78.379000,51.828700,78.886500,51.828700,79.457000L51.828700,84.708300C51.828700,84.849600,51.783900,84.987200,51.700700,85.101400C51.434000,85.467800,50.887600,85.467800,50.620900,85.101400L49.541000,83.617900C49.291600,83.275300,49.157200,82.862500,49.157200,82.438700L49.157200,79.457000Z"
                        fill="rgb(255,255,255)"
                        stroke="none"
                        strokeWidth="1"
                      />
                      <path
                        id="configurando-path6"
                        d="M76.618500,79.342500C76.618500,78.493400,75.769400,77.906500,74.975100,78.206500C74.502600,78.385000,74.190000,78.837400,74.190000,79.342500L74.190000,84.742700C74.190000,84.861800,74.225000,84.978400,74.290800,85.077700C74.530900,85.440500,75.063400,85.440500,75.303400,85.077700L76.316100,83.547500C76.513300,83.249400,76.618500,82.899800,76.618500,82.542300L76.618500,79.342500Z"
                        fill="rgb(255,255,255)"
                        stroke="none"
                        strokeWidth="1"
                      />
                    </g>
                  </g>
                  <path
                    id="configurando-union_2"
                    d="M59.551100,76.083100L53.537000,76.083100C52.724600,76.083100,52.042800,76.642200,51.855400,77.396600C51.461100,77.475300,51.164100,77.823300,51.164100,78.240700C51.164100,78.639800,51.435800,78.975500,51.804300,79.072900L51.804300,81.236500C51.804300,83.150400,53.355800,84.701900,55.269800,84.701900L57.818400,84.701900C59.732300,84.701900,61.283900,83.150400,61.283900,81.236500L61.283900,79.812500C62.381200,79.364200,63.603200,79.336900,64.715700,79.730600L64.715700,81.236500C64.715700,83.150400,66.267300,84.701900,68.181200,84.701900L70.729800,84.701900C72.643800,84.701900,74.195300,83.150400,74.195300,81.236500L74.195300,79.072700C74.563500,78.975200,74.835000,78.639600,74.835000,78.240700C74.835000,77.823500,74.538200,77.475600,74.144300,77.396700C73.956800,76.642300,73.275000,76.083100,72.462600,76.083100L66.448500,76.083100C65.491500,76.083100,64.715700,76.858900,64.715700,77.815900L64.715700,78.820200C63.594100,78.489600,62.394900,78.512200,61.283900,78.888200L61.283900,77.815900C61.283900,76.858900,60.508100,76.083100,59.551100,76.083100ZM52.670700,77.815900C52.670700,77.337400,53.058500,76.949500,53.537000,76.949500L59.551100,76.949500C60.029600,76.949500,60.417500,77.337400,60.417500,77.815900L60.417500,81.236500C60.417500,82.671900,59.253800,83.835600,57.818400,83.835600L55.269800,83.835600C53.834300,83.835600,52.670700,82.671900,52.670700,81.236500L52.670700,77.815900ZM66.448500,76.949500C65.970000,76.949500,65.582100,77.337400,65.582100,77.815900L65.582100,81.236500C65.582100,82.671900,66.745800,83.835600,68.181200,83.835600L70.729800,83.835600C72.165300,83.835600,73.328900,82.671900,73.328900,81.236500L73.328900,77.815900C73.328900,77.337400,72.941000,76.949500,72.462600,76.949500L66.448500,76.949500Z"
                    clipRule="evenodd"
                    fill="url(#configurando-union_2-fill)"
                    fillRule="evenodd"
                    stroke="none"
                    strokeWidth="1"
                  />
                </g>
                <clipPath id="configurando-clip0">
                  <rect
                    id="configurando-rect2"
                    width="68"
                    height="68"
                    rx="34"
                    ry="34"
                    transform="matrix(1 0 0 1 29 55)"
                    fill="rgb(255,255,255)"
                    stroke="none"
                    strokeWidth="1"
                  />
                </clipPath>
              </g>
            </g>
          </g>
        </g>
        <g id="configurando-check_ts" transform="translate(144,148) scale(1,1)">
          <g id="configurando-check" transform="translate(-144,-148)">
            <g id="configurando-ellipse-79">
              <circle
                id="configurando-circle1"
                r="17"
                transform="matrix(-1 0 0 1 144 148)"
                fill="url(#configurando-circle1-fill)"
                stroke="none"
                strokeWidth="1"
              />
            </g>
            <path
              id="configurando-vector-71-stroke"
              d="M152.673000,143.260000C153.081000,143.632000,153.111000,144.264000,152.740000,144.673000L142.740000,155.673000C142.548000,155.884000,142.274000,156.003000,141.988000,156C141.702000,155.997000,141.432000,155.871000,141.244000,155.655000L134.744000,148.155000C134.383000,147.738000,134.428000,147.106000,134.845000,146.744000C135.262000,146.383000,135.894000,146.428000,136.256000,146.845000L142.018000,153.494000L151.260000,143.327000C151.632000,142.919000,152.264000,142.889000,152.673000,143.260000Z"
              clipRule="evenodd"
              fill="rgb(255,255,255)"
              fillRule="evenodd"
              stroke="none"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default React.memo(Configuring);
