import React, { useState } from 'react';

import { MaterialUICore } from '@iclinic/design-system';
import {
  TABFOR_AVAILABLE,
  TABFOR_REQUESTED,
} from 'features/free-samples/contants/TabForConsultation';
import * as S from './TabForAvailableRequested.style';
import AvailableTab from './AvailableTab';
import RequestedTab from './RequestedTab';
import { SampleBox } from 'features/free-samples/services/types';

const { Tab, Tabs } = MaterialUICore;

interface TabForTabProps {
  onDetailsClick: (box: SampleBox) => void;
}

const TabForTab = ({ onDetailsClick }: TabForTabProps) => {
  const [activeTab, setActiveTab] = useState(TABFOR_AVAILABLE);

  const handleChangeForTab = (_event: React.ChangeEvent<{}>, value: string) => {
    setActiveTab(value);
  };

  return (
    <S.Paper>
      <Tabs
        indicatorColor="primary"
        centered
        value={activeTab}
        onChange={handleChangeForTab}
        textColor="primary"
      >
        <Tab
          label="Disponíveis"
          value={TABFOR_AVAILABLE}
          className={activeTab === TABFOR_AVAILABLE ? 'active' : ''}
        />
        <Tab
          label="Solicitadas"
          value={TABFOR_REQUESTED}
          className={activeTab === TABFOR_REQUESTED ? 'active' : ''}
        />
      </Tabs>
      <div>
        {activeTab === TABFOR_AVAILABLE ? (
          <AvailableTab onDetailsClick={onDetailsClick} />
        ) : (
          <RequestedTab onDetailsClick={onDetailsClick} />
        )}
      </div>
    </S.Paper>
  );
};

export default TabForTab;
