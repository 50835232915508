import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ScreenType, SummaryData, SummaryIAState } from './types';

export const initialState: SummaryIAState = {
  selectedScreen: 'terms',
  sidebarIsOpen: false,
  summary: {
    patientId: null,
    markdown: '',
    requestId: '',
    isFeedbackSent: false,
  },
  isWaiting: true,
};

const name = 'summaryIA';

const summaryIASlice = createSlice({
  name: `@webapp/${name}`,
  initialState,
  reducers: {
    changeScreen: (state, action: PayloadAction<ScreenType>) => ({
      ...state,
      selectedScreen: action.payload,
    }),
    triggerSidebar: (state, action: PayloadAction<boolean>) => ({
      ...state,
      sidebarIsOpen: action.payload,
    }),
    updateSummary: (state, action: PayloadAction<SummaryData>) => ({
      ...state,
      summary: {
        ...state.summary,
        ...action.payload,
      },
    }),
    triggerIsWaiting: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isWaiting: action.payload,
    }),
  },
});

export const actions = {
  ...summaryIASlice.actions,
};

export default summaryIASlice.reducer;
