// External
import React from 'react';

// Internal
import style from './Title.scss';

type HeaderProps = {
  children: React.ReactNode;
};

export default function Container({
  children,
}: HeaderProps): JSX.Element {
  return (
    <h2 className={style['main-title']}>
      { children }
    </h2>
  );
}
