import { combineReducers } from 'redux';

import checkout, { CheckoutState } from './checkout/reducer';
import manage, { ManageState } from './manage/reducer';
import config, { ConfigState } from './config';
import reports, { ReportsState } from './reports/reducer';
import iClinicPay, { IClinicPayState } from './iclinic-pay';
import notificationsCenter, {
  NotificationsCenterState,
} from './notifications-center';
import panelIClinicPay, { PanelIClinicPayState } from './panel';

export type OnlinePaymentState = {
  checkout: CheckoutState;
  manage: ManageState;
  config: ConfigState;
  reports: ReportsState;
  iClinicPay: IClinicPayState;
  notificationsCenter: NotificationsCenterState;
  panelIClinicPay: PanelIClinicPayState;
};

const onlinePaymentReducer = combineReducers<OnlinePaymentState>({
  checkout,
  manage,
  config,
  reports,
  iClinicPay,
  notificationsCenter,
  panelIClinicPay,
});

export default onlinePaymentReducer;
