import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export const useStyles = makeStyles(() => ({
  closeButton: {
    color: axiomTokens.color.typeface.pure,
    fill: axiomTokens.color.typeface.pure,
    fontSize: axiomTokens.font.size.sm,
  },
}));
