import React from 'react';
import { useSelector } from 'react-redux';

import { Grid, Typography, Chip } from '@iclinic/design-system';
import { ErrorAlert } from 'shared/components';
import {
  normalizeTransactionStatus,
  getChipColor,
} from 'features/onlinePayment/utils';
import { TransactionStatus } from 'features/onlinePayment/state/constants';
import { getTransactionDetailSelector } from 'features/onlinePayment/state/reports/transactions/selectors';
import Loader from './Loader';

const OnlineTransactionSideBar = (): JSX.Element => {
  const {
    fetching,
    transactionDetail,
    transactionDetailFetchError,
  } = useSelector(getTransactionDetailSelector);

  const { status, amount, createdAt, installmentsNumber, feePercent } =
    transactionDetail || {};

  return (
    <>
      {fetching && <Loader />}
      {!fetching && transactionDetail && (
        <Grid container alignItems="flex-start" spacing={4}>
          <Grid item xs={12}>
            <Typography variant="body2" gutterBottom>
              Status da transação
            </Typography>
            <Chip
              label={normalizeTransactionStatus(status as TransactionStatus)}
              severity={getChipColor(status as TransactionStatus)}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Valor</Typography>
            <Typography color="textPrimary" variant="body1">
              {amount}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">Data</Typography>
            <Typography color="textPrimary" variant="body1">
              {createdAt}
            </Typography>
          </Grid>
          {feePercent && (
            <Grid item xs={12}>
              <Typography variant="body2">Taxa</Typography>
              <Typography color="textPrimary" variant="body1">
                {feePercent}%
              </Typography>
            </Grid>
          )}
          {feePercent && installmentsNumber && (
            <Grid item xs={12}>
              <Typography variant="body2">Parcelamento</Typography>
              <Typography color="textPrimary" variant="body1">
                {installmentsNumber}x
              </Typography>
            </Grid>
          )}
        </Grid>
      )}
      {transactionDetailFetchError && (
        <ErrorAlert
          message={
            <span
              dangerouslySetInnerHTML={{
                __html: transactionDetailFetchError,
              }}
            />
          }
        />
      )}
    </>
  );
};

export default OnlineTransactionSideBar;
