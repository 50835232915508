import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = tokens;

export const DrawerContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: spacing.sm,
});

export const ThumbnailContent = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  gap: spacing.base,
});

export const ThumbnailContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '&:not(:last-child)': {
    marginTop: spacing.md,
    marginBottom: spacing.md,
  },
});
