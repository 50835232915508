// External
import { all, takeEvery, select, call, put } from 'redux-saga/effects';

// Internal
import { emitErrors } from 'shared/utils/emitErrors';
import iclinic from 'services/iclinic';
import { FETCH_UNSUBSCRIBE } from './types';
import { getUnsubscribeParams } from './selectors';
import { fetchUnsubscribeFailure, fetchUnsubscribeSuccess } from './actions';
import { feedBack } from './constants';

export function* workerUnsubscribe({ params }) {
  try {
    const { recipient, unsubscribeToken, type } = yield select(
      getUnsubscribeParams,
    );
    const { reason, otherReason } = params;
    const { getResponseData, errors } = yield call(
      iclinic.campaign.fetchUnsubscribe,
      {
        recipient,
        unsubscribeToken,
        reason,
        otherReason,
        type,
      },
    );
    if (errors) {
      yield put(fetchUnsubscribeFailure(emitErrors(errors)));
    } else {
      const payload = getResponseData();
      yield put(
        fetchUnsubscribeSuccess(params, payload.reason !== feedBack.default),
      );
    }
  } catch (error) {
    yield put(fetchUnsubscribeFailure(emitErrors([error])));
  }
}

export default function* unsubscribeSagas() {
  yield all([takeEvery(FETCH_UNSUBSCRIBE.ACTION, workerUnsubscribe)]);
}
