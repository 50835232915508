import React from 'react';

import { MaterialUICore } from '@iclinic/design-system';
import { tabsValue, tabsTitle } from 'features/video-conference/constants';
import { useAnnotationContext } from 'features/video-conference/state/annotationContext';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';
import { useConference } from 'features/video-conference/state/conferenceContext';

const { Tabs: UiTabs, Tab } = MaterialUICore;

export default function Tabs() {
  const { localParticipant, remoteParticipant } = useParticipantContext();
  const { conferenceId } = useConference();
  const { activeTab, setActiveTab } = useAnnotationContext();

  const handleChangeTab = (_event: React.ChangeEvent<{}>, value: string) => {
    setActiveTab(value);
    const tabName =
      value === tabsValue.PATIENT_NOTE
        ? 'telemedicine_patient_note'
        : 'telemedicine_clinic_note';
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician: true,
      buttonName: tabName,
    });
  };

  return (
    <UiTabs
      value={activeTab}
      textColor="primary"
      indicatorColor="primary"
      onChange={handleChangeTab}
    >
      <Tab label={tabsTitle.CLINIC_NOTE} value={tabsValue.CLINIC_NOTE} />
      <Tab label={tabsTitle.PATIENT_NOTE} value={tabsValue.PATIENT_NOTE} />
    </UiTabs>
  );
}
