import React from 'react';

import { ApiErrors } from '@types';
import NotificationExpireMessage from 'shared/components/NotificationExpireMessage';

type NotificationsProps = {
  errors: ApiErrors[];
};
export default function Notifications({
  errors,
}: NotificationsProps): JSX.Element {
  return (
    <>
      {errors.map((item: ApiErrors) => (
        <NotificationExpireMessage
          kind="error"
          title="Controle de Estoque"
          showCloseButton={false}
          key={item.code}
        >
          {item.message}
        </NotificationExpireMessage>
      ))}
    </>
  );
}
