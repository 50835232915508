import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const { color } = tokens;

export default makeStyles((theme) => ({
  root: {
    bottom: '24px',
    position: 'absolute',
    backgroundColor: color.feedback.error.light,
    color: color.feedback.error.dark,

    [theme.breakpoints.down(320)]: {
      display: 'none',
    },
  },
  icon: {
    width: '16px',
    height: '16px',
    marginRight: '5px',
    color: color.feedback.error.dark,
  },
}));
