import React from 'react';

const SvgStarOutline = () => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.7897 4.33373C27.7894 1.80507 24.2106 1.80506 23.2103 4.33373L18.2952 16.7592C18.1555 17.1123 17.8278 17.3559 17.4493 17.3878L4.51838 18.4778C1.90084 18.6985 0.810788 21.9383 2.76262 23.6963L12.7593 32.7003C13.0284 32.9426 13.1454 33.3114 13.0653 33.6645L10.0372 47.0122C9.44317 49.6309 12.3247 51.6512 14.584 50.2002L25.4596 43.2156C25.7888 43.0042 26.2112 43.0042 26.5404 43.2156L37.416 50.2002C39.6753 51.6512 42.5568 49.6309 41.9628 47.0122L38.9347 33.6645C38.8546 33.3114 38.9716 32.9426 39.2407 32.7003L49.2374 23.6963C51.1892 21.9383 50.0992 18.6985 47.4816 18.4778L34.5507 17.3878C34.1722 17.3559 33.8445 17.1123 33.7048 16.7592L28.7897 4.33373Z"
      fill="#F8F9FA"
      stroke="#FFC107"
      strokeWidth="2"
    />
  </svg>
);

export default SvgStarOutline;
