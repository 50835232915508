// External
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

// Internal
import style from './CheckBox.scss';

const cn = classnames.bind(style);

const CheckBox = ({
  name,
  id,
  text,
  value,
  selected,
  disabled,
  hasError,
  onChange,
  input,
  meta: {
    touched,
    invalid,
    error,
  },
  classes,
}) => (
  <Fragment>
    <div className={cn(style.wrapper, classes.wrapper)}>
      <input
        type="checkbox"
        name={name}
        id={id}
        value={value}
        className={cn('checkbox', { disabled, error: hasError || (touched && invalid) })}
        checked={selected}
        disabled={disabled}
        tabIndex={0}
        onChange={onChange}
        {...input}
      />
      {text && (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <label
          htmlFor={id}
          className={cn('checkbox__text', { error__message: hasError || (touched && invalid) })}
        >
          {text}
        </label>
      )}
    </div>
    {touched && invalid && <span className={style.error__message}>{error}</span>}
  </Fragment>
);

CheckBox.defaultProps = {
  text: null,
  selected: false,
  disabled: false,
  hasError: false,
  onChange: null,
  input: null,
  meta: {
    touched: false,
    invalid: false,
    error: undefined,
  },
  classes: {},
};

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  text: PropTypes.node,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  input: PropTypes.shape({
    name: PropTypes.string,
    checked: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.bool,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
    error: PropTypes.string,
  }),
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
  }),
};

export default CheckBox;
