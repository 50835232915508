import { useCallback, useEffect, useState } from 'react';
import Video, { LocalTrack, Room } from 'twilio-video';

import {
  handleTwilioErrorLog,
  isMobile,
} from 'features/video-conference/utils';
import { Callback, LogError } from 'features/video-conference/constants/types';
import { fetchSendLogs } from 'features/video-conference/services/sendLogs';

// @ts-ignore
window.TwilioVideo = Video;

export default function useRoom(localTracks: LocalTrack[], onError: Callback) {
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const [logError, setLogError] = useState<LogError | null>(null);

  useEffect(() => {
    if (logError) {
      handleTwilioErrorLog({
        logError,
        fetchSendLogs,
      });
      setLogError(null);
    }
  }, [logError]);

  const handleTelemedicineError = (error: LogError, actionType: string) => {
    if (error) {
      setLogError({
        code: error.code,
        message: error.message,
        actionType,
      });
    }
  };

  const connect = useCallback(
    async (token: string) => {
      setIsConnecting(true);
      try {
        const newRoom = await Video.connect(token, {
          tracks: localTracks,
          networkQuality: { local: 1, remote: 1 },
        });
        setIsConnecting(false);
        setRoom(newRoom);

        const disconnect = () => newRoom.disconnect();

        newRoom.once('reconnecting', (error) => {
          handleTelemedicineError(error, 'reconnecting');
        });

        newRoom.once('disconnected', (_room, error) => {
          setTimeout(() => setRoom(null));

          window.removeEventListener('beforeunload', disconnect);

          if (isMobile) {
            window.removeEventListener('pagehide', disconnect);
          }

          handleTelemedicineError(error, 'disconnected');
        });

        // @ts-ignore
        window.twilioRoom = newRoom;
        window.addEventListener('beforeunload', disconnect);

        if (isMobile) {
          window.addEventListener('pagehide', disconnect);
        }
      } catch (error) {
        setIsConnecting(false);
        onError(error, true);
      }
    },
    [localTracks, onError],
  );

  return { connect, isConnecting, room };
}
