import { Body, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, font, shadow, spacing, line } = tokens;

export const Root = styled('div')(({ theme }) => ({
  padding: `0 ${spacing.sm} ${spacing.sm}`,
  display: 'grid',
  gridTemplateColumns: 'auto',
  gridTemplateAreas: `
      "header"
      "card"
      "form"
      "terms"
      "actions"
    `,
  gap: spacing.md,

  [theme.breakpoints.up('md')]: {
    padding: spacing.lg,
    gridTemplateColumns: 'auto 320px',
    gridTemplateAreas: `
      "header header"
      "form card"
      "terms terms"
      "actions actions"
    `,
  },
}));

export const Header = styled('div')(() => ({
  gridArea: 'header',
  display: 'flex',
  alignItems: 'flex-start',
  columnGap: spacing.sm,
}));

export const TitleWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  rowGap: spacing.base,
}));

export const RequirementsText = styled(Body)(() => ({
  fontSize: font.size.xxxs,
  fontWeight: font.weight.medium,
}));

export const InfoText = styled(Body)({
  color: color.neutral[500],
  lineHeight: line.height.md,
});

export const FormWrapper = styled('div')(({ theme }) => ({
  gridArea: 'form',
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.md,

  [theme.breakpoints.up('md')]: {
    backgroundColor: color.neutral[0],
    padding: `${spacing.sm} ${spacing.lg} ${spacing.lg}`,
    border: `1px solid ${color.neutral[50]}`,
    borderRadius: 4,
    boxShadow: shadow.level[1],
  },
}));
