// External
import React from 'react';
import PropTypes from 'prop-types';

import style from './Box.scss';

export default function Box({ children }) {
  return (
    <div className={style.box}>
      {children}
    </div>
  );
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
};
