// External
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { connect } from 'react-redux';
import { Form, FormikProps } from 'formik';

import {
  addPatientFlow,
  fetchPatientSummary,
  setSelectedReturnFlow,
  savePatientFlows,
  removePatientFlow,
} from 'state/marketing/emailFlow/patient/actions';
import { toggleModal } from 'state/marketing/emailFlow/root/actions';
import {
  fetchProfessionals,
  setSelectedProfessional,
} from 'state/marketing/emailFlow/professional/actions';
import { getReturnTypeFlows, getAvailableFlows } from 'state/marketing/emailFlow/patient/selectors';
import {
  AddedFlow,
  AvailableFlow,
  ReturnFlow,
} from 'state/marketing/emailFlow/patient/types';
import { StoreState } from 'state/rootReducer';
import Modal from 'ui/new/modal';
import Button from 'ui/new/button';
import { FOOTER } from 'ui/new/modal/constants';
import { BUTTON } from 'ui/new/button/constants';
import { Professional } from 'state/marketing/emailFlow/professional/types';
import { FlowType } from '../constants';
import ModalBody from '../modal/body';
import {
  DropdownProfessionalItems,
  DropdownAvailableFlowItems,
  DropdownReturnFlowItems,
  AddedFlowItems,
} from '../components/EmailFlowItems';
import EmailFlowEnhance, { FormValues } from './EmailFlowEnhance';

type StateProps = {
  isFetching: boolean;
  modalVisibility: boolean;
  availableFlows: AvailableFlow[];
  addedFlows: AddedFlow[];
  returnFlows: ReturnFlow[];
  selectedReturnFlow: ReturnFlow;
  professionals: Professional[];
  selectedProfessional: Professional;
};

type DispatchProps = {
  onClose: () => void;
  getPatientSummary: () => void;
  addFlow: (flow: AvailableFlow) => void;
  addReturnFlow: (returnFlow: ReturnFlow) => void;
  saveFlows: () => void;
  changeProfessional: (professional: Professional) => void;
  getProfessionals: () => void;
  removeAddedFlow: (flow: AddedFlow) => void;
};

type EmailFlowProps = StateProps & DispatchProps & FormikProps<FormValues>;

function EmailFlow({
  // store
  isFetching,
  modalVisibility,
  addedFlows,
  availableFlows,
  returnFlows,
  professionals,
  selectedProfessional,
  selectedReturnFlow,
  // actions
  onClose,
  getPatientSummary,
  addFlow,
  saveFlows,
  addReturnFlow,
  changeProfessional,
  getProfessionals,
  removeAddedFlow,
  // formik
  errors,
  status,
}: EmailFlowProps): JSX.Element {
  const [professionalIsOpen, setProfessionalOpen] = useState(false);
  const [availableFlowIsOpen, setAvailableFlowIsOpen] = useState(false);
  const [returnFlowIsOpen, setReturnFlowIsOpen] = useState(false);

  useEffect(() => {
    if (status === 'submit') saveFlows();
  }, [status, saveFlows]);

  useEffect(() => {
    if (modalVisibility && selectedProfessional) getPatientSummary();
  }, [getPatientSummary, selectedProfessional, modalVisibility]);

  useEffect(() => {
    if (modalVisibility) getProfessionals();
  }, [getProfessionals, modalVisibility]);

  // Returns
  const handleClickReturnFlow = useCallback((flow: ReturnFlow) => {
    setReturnFlowIsOpen(false);
    addReturnFlow(flow);
  }, [addReturnFlow]);

  const returnFlowItems = useMemo(() => (
    returnFlows.length > 0 && returnFlows.map((flow: ReturnFlow) => (
      <DropdownReturnFlowItems
        onClick={handleClickReturnFlow}
        flow={flow}
      />
    ))
  ), [returnFlows, handleClickReturnFlow]);

  // Dropdown Professionals
  const handleChangeProfessional = useCallback((professional: Professional) => {
    setProfessionalOpen(false);
    changeProfessional(professional);
  }, [changeProfessional]);

  const professionalItems = useMemo(() => (
    professionals.length > 0 && professionals.map((professional: Professional) => (
      <DropdownProfessionalItems
        onChange={handleChangeProfessional}
        professional={professional}
      />
    ))
  ), [professionals, handleChangeProfessional]);

  // AvailableFlows
  const handleClickAvailableFlow = useCallback((flow: AvailableFlow) => {
    setAvailableFlowIsOpen(false);
    addFlow(flow);
  }, [addFlow]);

  const availableFlowItems = useMemo(() => (
    availableFlows.length > 0 && availableFlows.map((flow: AvailableFlow) => (
      <DropdownAvailableFlowItems
        onClick={handleClickAvailableFlow}
        flow={flow}
      />
    ))
  ), [availableFlows, handleClickAvailableFlow]);

  // AddedFlows
  const handleRemoveAddedFlow = useCallback((flow: AddedFlow) => {
    removeAddedFlow(flow);
  }, [removeAddedFlow]);

  const addedFlowItems = useMemo(() => (
    addedFlows.length > 0 && addedFlows.map((flow: AddedFlow) => (
      (flow.id === 'internal' || flow.flowType === FlowType.custom) && (
        <AddedFlowItems
          professional={selectedProfessional}
          onRemove={handleRemoveAddedFlow}
          flow={flow}
          returnFlowItems={returnFlowItems}
          returnFlowIsOpen={returnFlowIsOpen}
          setReturnFlowIsOpen={setReturnFlowIsOpen}
          selectedReturnFlow={selectedReturnFlow}
        />
      )))
  ), [
    addedFlows,
    selectedProfessional,
    handleRemoveAddedFlow,
    returnFlowIsOpen,
    returnFlowItems,
    selectedReturnFlow,
  ]);

  return (
    <Modal
      title="Adicionar e-mail automático"
      show={modalVisibility}
      onClose={onClose}
      showCloseIcon
    >
      <Form>
        <Modal.Body>
          <ModalBody
            availableFlowItems={availableFlowItems}
            availableFlowIsOpen={availableFlowIsOpen}
            setAvailableFlowIsOpen={setAvailableFlowIsOpen}
            professionalsItems={professionalItems}
            professionalIsOpen={professionalIsOpen}
            setProfessionalOpen={setProfessionalOpen}

            selectedProfessional={selectedProfessional}
            addedFlowsItems={addedFlowItems}
            errors={errors}
          />
        </Modal.Body>
        <Modal.Footer align={FOOTER.right}>
          <Button
            type={BUTTON.Primary}
            loader={isFetching}
            disabled={isFetching}
            submit
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const mapStateToProps = (state: StoreState) => ({
  isFetching: state.marketing.emailFlow.patient.isFetching,
  modalVisibility: state.marketing.emailFlow.root.modalVisibility,
  addedFlows: state.marketing.emailFlow.patient.addedFlows,
  selectedReturnFlow: state.marketing.emailFlow.patient.selectedReturnFlow,
  professionals: state.marketing.emailFlow.professional.professionals,
  selectedProfessional: state.marketing.emailFlow.professional.selectedProfessional,
  availableFlows: getAvailableFlows(state.marketing.emailFlow.patient),
  returnFlows: getReturnTypeFlows(state.marketing.emailFlow.patient),
});

const mapDispatchToProps: DispatchProps = {
  onClose: toggleModal,
  getPatientSummary: fetchPatientSummary,
  addFlow: addPatientFlow,
  addReturnFlow: setSelectedReturnFlow,
  saveFlows: savePatientFlows,
  changeProfessional: setSelectedProfessional,
  getProfessionals: fetchProfessionals,
  removeAddedFlow: removePatientFlow,
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(EmailFlowEnhance(EmailFlow));
