// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import Item from './Item';
import style from './Group.scss';

const Group = ({ children }) => <div className={style.group}>{children}</div>;

Group.propTypes = {
  children: PropTypes.node.isRequired,
};

Group.Item = Item;

export default Group;
