import {
  MaterialUICore,
  tokens,
  theme as themeUI,
} from '@iclinic/design-system';

const { styled, createMuiTheme } = MaterialUICore;
const { spacing, color } = tokens;

const theme = createMuiTheme(themeUI);

export const SpinnerWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const ScheduleInfoWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const HeadingContainer = styled('div')(() => ({
  display: 'flex',
  marginTop: spacing.sm,
}));

export const ScheduleInfoContainer = styled('div')(() => ({
  background: 'rgba(0, 0, 0, 0.16)',
  padding: spacing.sm,
  margin: spacing.md,
  width: `calc(100% + 32px)`,

  [theme.breakpoints.up('sm')]: {
    width: `calc(100% + 64px)`,
  },
}));

export const ScheduleInfoContent = styled('div')(() => ({
  display: 'flex',
  margin: spacing.base,

  '& .MuiSvgIcon-root': {
    color: color.neutral[300],
    marginRight: spacing.base,
  },
}));

export const PhysicianInfoContainer = styled('div')(() => ({
  display: 'flex',

  '& .MuiAvatar-root': {
    marginRight: spacing.base,
  },
}));

export const PhysicianInfoContent = styled('div')(() => ({
  ml: spacing.base,
  display: 'flex',
  flexDirection: 'column',
}));

export const Divider = styled('hr')(() => ({
  borderColor: color.neutral[700],
  margin: `${spacing.sm} 0`,
}));
