import appTypes from 'shared/utils/actions';
import { SUCCESS, FAILURE } from 'shared/constants/actions';
import { ApiErrors } from '@types';

import { Professional } from './types';

const professionals: Professional[] = [];
const errors: ApiErrors[] = [];
const selectedProfessional: Professional | undefined = undefined;

export const initialState = {
  professionals,
  errors,
  selectedProfessional,
  isFetching: false,
};

const emailFlow = appTypes.defineAction('emailFlow/professional');

export const FETCH_PROFESSIONALS = emailFlow.defineAction('FETCH_PROFESSIONALS', [SUCCESS, FAILURE]);
export const SET_SELECTED_PROFESSIONAL = emailFlow.defineAction('SET_SELECTED_PROFESSIONAL');
export const RESET_STATE = emailFlow.defineAction('RESET_STATE');
export const SET_SELECTED_PROFESSIONAL_BY_ID = emailFlow.defineAction('SET_SELECTED_PROFESSIONAL_BY_ID');
