import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getExportList } from 'services/iclinic/exportClinicData/exportList';
import {
  fetchExportList,
  fetchExportListSuccess,
  fetchExportListFailure,
  exportPagination,
  selectedPageNumber,
} from '.';
import { getPageNumber } from './selectors';

export function* fetchExportListWorker() {
  try {
    const pageNumber: number = yield select(getPageNumber);
    const { data } = yield call(getExportList, pageNumber);

    const { count, next, previous } = data;

    const pagination = {
      count,
      next,
      previous,
    };

    yield put(fetchExportListSuccess(data.results));
    yield put(exportPagination(pagination));
  } catch (error) {
    yield put(fetchExportListFailure());
  }
}

export function* paginationChangeWorker() {
  yield call(fetchExportListWorker);
}

export default function* exportListWorker() {
  yield takeLatest(fetchExportList, fetchExportListWorker);
  yield takeLatest(selectedPageNumber, paginationChangeWorker);
}
