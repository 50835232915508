/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  MaterialUICore,
  Paper,
  Button,
  MaterialIcons,
} from '@iclinic/design-system';
import { TransactionType } from 'features/onlinePayment/state/constants';
import {
  selectTransactionType,
  selectedPageNumber,
  showWaitingPayments,
} from 'features/onlinePayment/state/reports/transactions';
import {
  getSelectedTransactionType,
  getIsAttemptTransactions,
} from 'features/onlinePayment/state/reports/transactions/selectors';
import useStyles from './Tabs.styles';

const { DateRange: IconDateRange, Link: IconLink } = MaterialIcons;
const { AppBar, Tabs: MaterialTabs, Tab } = MaterialUICore;

const tabAccessibilityLabel = (id: string) => `form-payment-tab-${id}`;
const tabPanelAccessibilityLabel = (id: string) =>
  `form-payment-tabpanel-${id}`;

function tabAccessibilityProps(id: string) {
  return {
    id: tabAccessibilityLabel(id),
    'data-testid': tabAccessibilityLabel(id),
    'aria-controls': tabPanelAccessibilityLabel(id),
  };
}

export default function Tabs() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedTransactionType = useSelector(getSelectedTransactionType);
  const isAttemptTransactions = useSelector(getIsAttemptTransactions);

  const handleChange = (
    _event: React.ChangeEvent<{}>,
    value: TransactionType,
    waitingPayments: boolean = false,
  ) => {
    dispatch(selectTransactionType(value));
    dispatch(showWaitingPayments(waitingPayments));
    dispatch(selectedPageNumber(1));
  };

  return (
    <Paper variant="outlined" className={classes.tabsHeader}>
      <AppBar position="static" color="transparent" elevation={0}>
        <MaterialTabs
          className={classes.tabs}
          aria-label="Formas de pagamento"
          textColor="primary"
          indicatorColor="primary"
          value={selectedTransactionType}
          onChange={handleChange}
        >
          <Tab
            value={TransactionType.Online}
            label="Pagamento Online"
            {...tabAccessibilityProps('pagamento-online')}
          />
          <Tab
            value={TransactionType.Presential}
            label="Maquininha"
            {...tabAccessibilityProps('maquininha')}
          />
        </MaterialTabs>
      </AppBar>
      {selectedTransactionType === TransactionType.Online && (
        <div className={classes.subTabHeader}>
          <Button
            variant="text"
            className={`${classes.subTabButton} ${
              isAttemptTransactions ? 'selected' : ''
            }`}
            {...tabAccessibilityProps('transacoes-efetuadas')}
            onClick={(event) =>
              handleChange(event, TransactionType.Online, false)
            }
          >
            <IconDateRange /> Transações efetuadas
          </Button>
          <Button
            variant="text"
            className={`${classes.subTabButton} ${
              !isAttemptTransactions ? 'selected' : ''
            }`}
            {...tabAccessibilityProps('aguardando-pagamento')}
            onClick={(event) =>
              handleChange(event, TransactionType.Online, true)
            }
          >
            <IconLink /> Aguardando Pagamento
          </Button>
        </div>
      )}
    </Paper>
  );
}
