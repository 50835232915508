import {
  Autocomplete,
  createFilterOptions,
  TextField,
} from '@iclinic/design-system';
import { useFormikContext } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

import { getCboOptions } from 'features/tissInvoicing/state/autocomplete/selectors';
import {
  GuideSADTForm,
  ProfessionCodeAutocompleteEntry,
} from 'features/tissInvoicing/types';
import { getOptionLabelFn, renderOptionFn } from '../utils/utils';

export default ({ label = '19 - CBO (especialidade)' }: { label?: string }) => {
  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext<GuideSADTForm>();

  const cboFilter = createFilterOptions<ProfessionCodeAutocompleteEntry>();

  const cboOptions = useSelector(getCboOptions);

  const getCBOName = (
    code: string | null,
  ): ProfessionCodeAutocompleteEntry | null =>
    cboOptions.find((cbo) => cbo.code === code) || null;

  const handleChange = (option: ProfessionCodeAutocompleteEntry) =>
    setFieldValue('professional_solicitant_cbo', option?.code || '');

  return (
    <Autocomplete
      id="professional_solicitant_cbo"
      fullWidth
      clearOnBlur
      onBlur={handleBlur}
      options={cboOptions}
      noOptionsText="CBO não encontrado"
      value={getCBOName(values.professional_solicitant_cbo)}
      onChange={(_, value) =>
        handleChange(value as ProfessionCodeAutocompleteEntry)
      }
      getOptionLabel={(option: ProfessionCodeAutocompleteEntry) =>
        getOptionLabelFn(option)
      }
      renderOption={(option: ProfessionCodeAutocompleteEntry) =>
        renderOptionFn(option)
      }
      filterOptions={cboFilter}
      renderInput={(params) => (
        <TextField
           
          {...params}
          label={label}
          name="professional_solicitant_cbo"
          data-ga="professional_solicitant_cbo"
          error={
            !!(
              errors.professional_solicitant_cbo &&
              touched.professional_solicitant_cbo
            )
          }
          helperText={
            touched.professional_solicitant_cbo &&
            errors.professional_solicitant_cbo
          }
        />
      )}
    />
  );
};
