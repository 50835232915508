import { captureException } from '@sentry/browser';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import iclinic from 'services/iclinic';
import { emitErrors } from 'shared/utils/emitErrors';
import { dispatchIntercomEvent } from 'shared/utils/intercom';
import {
  fetchUpgradeDetailFailure,
  fetchUpgradeDetailSuccess,
  openUpgradeModalView,
  selectProduct,
} from 'state/upgrade/actions';
import { PlansToUpgrade } from '../types';
import { shouldOpenChat } from '../utils';
import * as actions from './actions';
import { setLoading } from './reducer';
import { getTotalPayingUsers } from './selectors';

export function* openUpgradeModalWorker({
  payload,
}: {
  payload: PlansToUpgrade;
}) {
  try {
    yield put(setLoading(true));
    const totalPayingUsers: number = yield select(getTotalPayingUsers);
    const isIntercomUpgrade: boolean = yield call(
      shouldOpenChat,
      totalPayingUsers,
      payload,
    );
    if (isIntercomUpgrade) {
      yield call(dispatchIntercomEvent, {
        eventName: 'subscription-upgrade-call',
        additionalParam: {
          planToUpgrade: payload,
        },
      });
    }

    const { getResponseData, errors } = yield call(
      iclinic.upgrade.fetchUpgradeDetail,
    );
    if (errors) {
      yield put(fetchUpgradeDetailFailure(emitErrors(errors)));
    } else {
      const data = getResponseData();
      yield put(fetchUpgradeDetailSuccess(data));
      yield put(selectProduct(payload));
    }
    yield put(openUpgradeModalView());
    yield put(setLoading(false));
  } catch (error) {
    yield call(captureException, error);
    yield put(setLoading(false));
  }
}

export default function* upgradeCallSagas() {
  yield all([takeLatest(actions.openUpgradeModal, openUpgradeModalWorker)]);
}
