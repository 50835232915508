import React from 'react';
import classnames from 'classnames';

import { MaterialIcons, Tooltip } from '@iclinic/design-system';
import { TransactionStatus } from 'features/onlinePayment/state/constants';
import useStyles from './StatusTransactionIcon.styles';
import { normalizeTransactionStatus } from 'features/onlinePayment/utils';

const { AddCircle, Cancel, CheckCircle, RefreshCircle } = MaterialIcons;

type StatusTransactionIconProps = {
  status: TransactionStatus;
};

export default function StatusTransactionIcon({
  status,
}: StatusTransactionIconProps): JSX.Element {
  const {
    statusIcon,
    errorStatusIcon,
    infoStatusIcon,
    successStatusIcon,
    warningStatusIcon,
  } = useStyles();

  const classIconNameMap = {
    [TransactionStatus.Canceled]: classnames(statusIcon, errorStatusIcon),
    [TransactionStatus.ChargeBack]: classnames(statusIcon, errorStatusIcon),
    [TransactionStatus.Created]: classnames(statusIcon, warningStatusIcon),
    [TransactionStatus.Dispute]: classnames(statusIcon, warningStatusIcon),
    [TransactionStatus.Paid]: classnames(statusIcon, successStatusIcon),
    [TransactionStatus.PartialReversed]: classnames(statusIcon, infoStatusIcon),
    [TransactionStatus.Pending]: classnames(statusIcon, warningStatusIcon),
    [TransactionStatus.Processing]: classnames(statusIcon, warningStatusIcon),
    [TransactionStatus.Rejected]: classnames(statusIcon, errorStatusIcon),
    [TransactionStatus.Reversed]: classnames(statusIcon, infoStatusIcon),
    [TransactionStatus.Success]: classnames(statusIcon, successStatusIcon),
  };

  const iconElemMap = {
    [TransactionStatus.Canceled]: (
      <Cancel
        className={classIconNameMap[status]}
        data-testid={`${status}-icon`}
      />
    ),
    [TransactionStatus.ChargeBack]: (
      <RefreshCircle
        className={classIconNameMap[status]}
        data-testid={`${status}-icon`}
      />
    ),
    [TransactionStatus.Created]: (
      <AddCircle
        className={classIconNameMap[status]}
        data-testid={`${status}-icon`}
      />
    ),
    [TransactionStatus.Dispute]: (
      <RefreshCircle
        className={classIconNameMap[status]}
        data-testid={`${status}-icon`}
      />
    ),
    [TransactionStatus.Paid]: (
      <CheckCircle
        className={classIconNameMap[status]}
        data-testid={`${status}-icon`}
      />
    ),
    [TransactionStatus.PartialReversed]: (
      <RefreshCircle
        className={classIconNameMap[status]}
        data-testid={`${status}-icon`}
      />
    ),
    [TransactionStatus.Pending]: (
      <AddCircle
        className={classIconNameMap[status]}
        data-testid={`${status}-icon`}
      />
    ),
    [TransactionStatus.Processing]: (
      <AddCircle
        className={classIconNameMap[status]}
        data-testid={`${status}-icon`}
      />
    ),
    [TransactionStatus.Rejected]: (
      <Cancel
        className={classIconNameMap[status]}
        data-testid={`${status}-icon`}
      />
    ),
    [TransactionStatus.Reversed]: (
      <RefreshCircle
        className={classIconNameMap[status]}
        data-testid={`${status}-icon`}
      />
    ),
    [TransactionStatus.Success]: (
      <CheckCircle
        className={classIconNameMap[status]}
        data-testid={`${status}-icon`}
      />
    ),
  };

  return (
    <Tooltip
      placement="top"
      title={normalizeTransactionStatus(status as TransactionStatus)}
    >
      {iconElemMap[status]}
    </Tooltip>
  );
}
