import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DOMPurify from 'dompurify';

import NotificationExpireMessage from 'shared/components/NotificationExpireMessage';
import { StoreState } from 'state/rootReducer';
import * as actions from 'state/billing/subscription/actions';
import Switch from 'ui/new/switch';
import Card from 'ui/new/card';
import Price from 'ui/new/price';
import Button from 'ui/new/button';
import Discount from 'ui/new/discount';
import { ALIGN, COLOR } from 'ui/new/card/constants';
import Spacer from 'ui/new/spacer';
import { BUTTON } from 'ui/new/button/constants';
import {
  getSelectedProducts,
  getProductDefinition,
  getProductsNames,
} from 'state/billing/subscription/selectors';
import { PRODUCT_SUBSCRIPTION } from 'state/billing/subscription/constants';
import { Product } from 'state/billing/subscription/types';
import SubscriptionFlowModal from '../../subscriptionFlowModal';
import {
  Group,
  Container,
  Title,
  CollapseWrapper,
  Summary,
} from '../components';
import { mdLinkToHTML } from 'shared/utils/md';

const mapStateToProps = (state: StoreState) => ({
  isMonthly: state.billing.subscription.isMonthly,
  isOpenModal: state.billing.subscription.isOpenModal,
  product: state.billing.subscription.product,
  errors: state.billing.subscription.errors,
  addonsProducts: getSelectedProducts(state, PRODUCT_SUBSCRIPTION.addons),
  extraProducts: getSelectedProducts(state, PRODUCT_SUBSCRIPTION.extra),
  baseProducts: getSelectedProducts(state, PRODUCT_SUBSCRIPTION.base),
  formattedNames: getProductsNames(state),
});

const mapDispatchToProps = actions;

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type MapDispatchToProps = typeof mapDispatchToProps;
type SubscriptionProps = MapStateToProps & MapDispatchToProps;

function SubscriptionProducts({
  // store
  isMonthly,
  product,
  openModal,
  isOpenModal,
  errors,
  // actions
  fetchSubscription,
  getContract,
  setSubscriptionProduct,
  clearErrorsSubscription,
  reload,
  // selectors
  formattedNames,
  baseProducts,
  extraProducts,
  addonsProducts,
}: SubscriptionProps): JSX.Element {
  const [warning, setWarning] = useState<string>('');

  useEffect(() => {
    fetchSubscription();
    reload();
  }, [fetchSubscription, reload]);

  const verifyPlan = (slug: string, name: string) => {
    if (product && product?.indexOf('premium') > -1) {
      setWarning(`${name} já está incluso no plano Premium!`)
    } else {
      setSubscriptionProduct(slug, name)
    }
  }

  const notificationErrors = React.useMemo(
    () =>
      errors.map((item) => (
        <NotificationExpireMessage
          kind="error"
          title="Atenção"
          showCloseButton={false}
          key={item.code}
          onExpire={clearErrorsSubscription}
          expireAfter={3000}
        >
          {item.message}
        </NotificationExpireMessage>
      )),
    [clearErrorsSubscription, errors],
  );

  return (
    <Container>
      {notificationErrors}
      {warning && (
        <NotificationExpireMessage
          kind="info"
          title="Atenção"
          showCloseButton={false}
          key={1}
          onExpire={() => setWarning('')}
          expireAfter={3000}
        >
          {warning}
        </NotificationExpireMessage>
      )}
      <Title>Planos e Assinatura</Title>
      <Group title="Escolha como pagar">
        <Switch isMonthly={isMonthly} toggleStatus={getContract} />
      </Group>
      <Group>
        {baseProducts && (
          <Group
            classes="main"
            title="Selecione seu plano"
            description="Escolha o melhor plano para sua clínica e seja mais organizado e produtivo."
          >
            {baseProducts.map((base: Product & StoreState) => {
              const {
                suffixValue,
                productValue,
                productDiscount,
                functionalities,
                percentage,
                frequencyNumber,
                expiration,
              } = getProductDefinition(isMonthly)(base);
              return (
                <Card
                  key={base.id}
                  showSelectIcon
                  align={ALIGN.Center}
                  selected={!!(product && product.includes(base.slug))}
                  classes="vertical"
                  onClickCheck={() =>
                    setSubscriptionProduct(base.slug, base.name)
                  }
                  title={base.name}
                >
                  <Discount
                    prefix={
                      productDiscount && `R$ ${productValue}${suffixValue}`
                    }
                    suffix={percentage && `${percentage}% off`}
                  />
                  <Price
                    suffix={suffixValue}
                    value={productDiscount || productValue}
                    subtitle={
                      frequencyNumber &&
                      `válido por ${frequencyNumber} ${expiration}`
                    }
                    classes="vertical"
                  />
                  <CollapseWrapper functionalities={functionalities} />
                  <Button
                    type={
                      product && product.includes(base.slug)
                        ? BUTTON.PrimaryActive
                        : BUTTON.Primary
                    }
                    block
                    onClick={() => setSubscriptionProduct(base.slug, base.name)}
                  >
                    {product && product.includes(base.slug)
                      ? 'Plano selecionado'
                      : 'Selecionar plano'}
                  </Button>
                </Card>
              );
            })}
          </Group>
        )}
        {extraProducts && Object.keys(extraProducts).length !== 0 && (
          <Group title="Assine Junto" classes="extra-products">
            {extraProducts.map((extra: Product & StoreState) => {
              const {
                suffixValue,
                productValue,
                productDiscount,
                functionalities,
                percentage,
                frequencyNumber,
                expiration,
              } = getProductDefinition(isMonthly)(extra);
              return (
                <Group key={extra.id}>
                  <Spacer icon="add_circle" />
                  <Card
                    showSelectIcon
                    color={COLOR.Warning}
                    align={ALIGN.Center}
                    selected={!!(product && product.includes(extra.slug))}
                    classes="vertical"
                    onClickCheck={() =>
                      setSubscriptionProduct(extra.slug, extra.slug)
                    }
                    title={extra.name}
                  >
                    <Discount
                      prefix={
                        productDiscount && `R$ ${productValue}${suffixValue}`
                      }
                      suffix={percentage && `${percentage}% off`}
                    />
                    <Price
                      suffix={suffixValue}
                      value={productDiscount || productValue}
                      subtitle={
                        frequencyNumber &&
                        `válido por ${frequencyNumber} ${expiration}`
                      }
                      classes="vertical"
                    />
                    <CollapseWrapper functionalities={functionalities} />
                    <Button
                      type={
                        product && product.includes(extra.slug)
                          ? BUTTON.WarningActive
                          : BUTTON.Warning
                      }
                      block
                      onClick={() =>
                        setSubscriptionProduct(extra.slug, extra.name)
                      }
                    >
                      {product && product.includes(extra.slug)
                        ? 'Plano selecionado'
                        : 'Selecionar plano'}
                    </Button>
                  </Card>
                </Group>
              );
            })}
          </Group>
        )}
      </Group>
      <Group>
        {addonsProducts && Object.keys(addonsProducts).length !== 0 && (
          <Group
            classes="main"
            title="Recursos adicionais"
            description="Selecione o recurso adicional e pague apenas para os profissionais de saúde que forem utilizar."
          >
            {addonsProducts.map((addon: Product & StoreState) => {
              const {
                suffixValue,
                productValue,
                productDiscount,
                percentage,
                frequencyNumber,
                expiration,
              } = getProductDefinition(isMonthly)(addon);
              const sanitizedDescription = DOMPurify.sanitize(
                addon.description,
                { USE_PROFILES: { html: false } },
              );
              return (
                <Card
                  key={addon.id}
                  showSelectIcon
                  align={ALIGN.Left}
                  selected={!!(product && product.includes(addon.slug))}
                  classes="horizontal"
                  onClickCheck={() => 
                    verifyPlan(addon.slug, addon.name)
                  }
                  title={addon.name}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: mdLinkToHTML(sanitizedDescription, '_blank'),
                    }}
                  />
                  <Discount
                    prefix={
                      productDiscount && `R$ ${productValue}${suffixValue}`
                    }
                    suffix={percentage && `${percentage}% off`}
                    legend={
                      frequencyNumber &&
                      `válido por ${frequencyNumber} ${expiration}`
                    }
                    classes="horizontal"
                  />
                  <Group classes="flex-end">
                    <Price
                      suffix={suffixValue}
                      value={productDiscount || productValue}
                      classes="horizontal"
                    />
                    <Button
                      type={
                        product && product.includes(addon.slug)
                          ? BUTTON.PrimaryActive
                          : BUTTON.Primary
                      }
                      block
                      onClick={() =>
                        verifyPlan(addon.slug, addon.name)
                      }
                    >
                      Seleciona
                      {product && product.includes(addon.slug) ? 'do' : 'r'}
                    </Button>
                  </Group>
                </Card>
              );
            })}
          </Group>
        )}
      </Group>
      <Summary>
        <span>
          Selecionado:
          <strong>{formattedNames}</strong>
        </span>
        <Button type={BUTTON.Primary} onClick={openModal} block>
          Prosseguir Assinatura
        </Button>
      </Summary>
      <SubscriptionFlowModal isOpenModal={isOpenModal} />
    </Container>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionProducts);
