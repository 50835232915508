import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const { border, color, spacing } = tokens;

export default makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    fontWeight: 'bold',
  },

  recordIcon: {
    width: '14px',
    height: '14px',
    borderRadius: border.radius.circular,
    backgroundColor: color.feedback.error.main,
    marginRight: spacing.nano,
    boxShadow: '0px 0px 0px 1px white inset',
  },

  hiddenLabel: {
    display: 'none',
  },
}));
