import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  finalizeMedicalRecord,
  getThumbprint,
  finalizeMedicalRecordFailure,
  finalizeMedicalRecordSuccess,
  signatureChangeStep,
  toggleDrawer,
} from 'state/records/signature/actions';
import { CertificateModel, ExceptionModel } from 'web-pki';
import { StoreState } from 'state/rootReducer';
import { getUrlParams } from 'shared/utils/url';
import SUBSCRIPTION_PERMISSIONS from 'shared/constants/subscriptionPermissions';
import { STEP_SIGNATURE, toggleState } from '../containers/constants';
import webPKI from '../webpkiConfig';
import { removeUrlParameter, changeUrl } from './utils';

type WebPKIRenderProps = {
  onChangeSelectCertificate: (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => void;
  getCertificateDisplayName: (certificate: CertificateModel) => string;
  reloadCertificates: () => void;
  readCertificate: () => void;
  handleOpen: () => void;
  handleClose: () => void;
  message: string;
  isOpen: boolean;
  actionLabel: string;
  handleAction: () => void;
  selectedCertificate: CertificateModel['thumbprint'] | null;
};

interface Message {
  message: string;
  actionLabel: string;
  action: WebPKIActions;
}

interface ListCertificates {
  showSignatureControls: boolean;
  certificates: CertificateModel[];
}

enum WebPKIActions {
  CLOSE = 0,
  INSTALL = 1,
  OPEN = 2,
}

export default function useSignature() {
  const permissions =
    useSelector<StoreState>((state) => state.userInfo.userData.permissions) ||
    [];
  const thumbprint = useSelector<StoreState>(
    (state) => state.records.signature.thumbprint,
  );
  const hasPermissions = useMemo(
    () => permissions.includes(SUBSCRIPTION_PERMISSIONS.AssinaturaDigital),
    [permissions],
  );
  const { OPEN } = toggleState;

  const dispatch = useDispatch();
  const [webpkiInstall, setWebpkiInstall] = useState(false);
  const [pkiMessage, setMessage] = useState<Message>({} as Message);
  const [listCertificates, setCertificates] = useState<ListCertificates>({
    showSignatureControls: false,
    certificates: [],
  } as ListCertificates);
  const onWebPkiFail = useCallback(
    ({ message }: ExceptionModel) => {
      const error = { code: '101', message };
      dispatch(finalizeMedicalRecordFailure([error]));
      setMessage({
        message: `A extensão de certificação WebPKI apresentou o seguinte problema: ${message}`,
        actionLabel: 'OK',
        action: WebPKIActions.CLOSE,
      });
    },
    [dispatch],
  );

  const checkValidationCertificate = (certificates: CertificateModel[]) => {
    const validationCertificates = certificates.map(
      (certificate: CertificateModel) => {
        const expired = new Date() > certificate.validityEnd;
        return { ...certificate, expired };
      },
    );
    setCertificates({
      certificates: validationCertificates,
      showSignatureControls: true,
    });
  };

  const onWebPkiCheckRedirect = useCallback((checkWebPki: boolean) => {
    const checkWebpkiInstallRedirect = getUrlParams('webpkiInstall');
    if (checkWebpkiInstallRedirect) {
      if (checkWebPki) {
        dispatch(signatureChangeStep(STEP_SIGNATURE.Certificates));
      } else {
        dispatch(signatureChangeStep(STEP_SIGNATURE.Setup));
      }
      dispatch(toggleDrawer(OPEN));
      setTimeout(() => {
        const newUrl = removeUrlParameter(
          window.location.href,
          'webpkiInstall',
        );
        changeUrl(newUrl, 'Prontuários - iClinic');
      }, 3000);
    }
  }, []);

  const onWebPkiReady = useCallback(() => {
    setWebpkiInstall(true);
    webPKI.listCertificates().success((certificates) => {
      setCertificates({
        certificates,
        showSignatureControls: true,
      });
      const firstCertificate =
        certificates.length > 0 && certificates[0].thumbprint;
      if (firstCertificate) dispatch(getThumbprint(firstCertificate));
      checkValidationCertificate(certificates);
      onWebPkiCheckRedirect(true);
    });
  }, []);

  const onWebPkiNotInstalled = useCallback(() => {
    setWebpkiInstall(false);
    onWebPkiCheckRedirect(false);
  }, []);

  const onChangeSelectCertificate = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const { target } = event;
    dispatch(getThumbprint(target.value as string));
  };

  const onReadCertificateCompleted = (encodedCert: string) => {
    dispatch(finalizeMedicalRecord(webpkiInstall, encodedCert));
  };
  const detailCertificateSelected = () =>
    listCertificates.certificates.find(
      (certificate) => certificate.thumbprint === thumbprint,
    );

  const readCertificate = () => {
    if (thumbprint) {
      const certificateSelected = detailCertificateSelected();
      if (certificateSelected?.expired) {
        const error = {
          code: '126',
          message: 'O certificado selecionado está vencido.',
        };
        dispatch(finalizeMedicalRecordFailure([error]));
        return;
      }
      webPKI
        .readCertificate({ thumbprint })
        .success(onReadCertificateCompleted);
    } else {
      const error = {
        code: '127',
        message: 'É necessário selecionar um certificado!',
      };
      dispatch(finalizeMedicalRecordFailure([error]));
    }
  };

  const signatureVerifyNextStep = (isSign: boolean) => {
    if (isSign && !webPKI.isSupportedMobile) {
      const checkWebpkiInstall = !webpkiInstall;
      const checkCertificatesInstall =
        listCertificates.certificates?.length === 0;

      if (checkWebpkiInstall || checkCertificatesInstall) {
        dispatch(signatureChangeStep(STEP_SIGNATURE.Setup));
        return;
      }

      dispatch(signatureChangeStep(STEP_SIGNATURE.Certificates));
    } else {
      dispatch(finalizeMedicalRecordSuccess());
    }
  };

  useEffect(() => {
    if (!hasPermissions) return;
    if (webPKI.isSupportedMobile) return;

    webPKI.init({
      defaultFail: onWebPkiFail,
      ready: onWebPkiReady,
      notInstalled: onWebPkiNotInstalled,
    });
  }, [hasPermissions, onWebPkiFail, onWebPkiNotInstalled, onWebPkiReady]);

  return {
    pkiMessage,
    listCertificates,
    onChangeSelectCertificate,
    readCertificate,
    webpkiInstall,
    signatureVerifyNextStep,
  };
}
