/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'ui/new/modal';
import * as actions from 'state/upgrade/actions';
import {
  getModalPropsByStep,
  getSelectedProductSuccessInfo,
} from 'state/upgrade/selectors';
import { ErrorNotifications } from '../components';
import { VIEWSTEPS, VIEWPOSITION } from '../constants';
import style from './style.scss';
import ProductsList from '../components/products/ProductsList';
import ProductButton from '../components/products/ProductButton';
import { SuccessModal } from '../components/success/SuccessModal';
import { InfoModal } from '../components/info/InfoModal';

const hasCustomClass = (isNewLayout) => {
  const classes = {
    newLayout: {
      body: style.__body,
      footerBtn: style.__footerbtn,
    },
    default: {
      body: {},
      footer: {},
    },
    getCustomClass(_isNewLayout = false) {
      return _isNewLayout ? this.newLayout : this.default;
    },
  };
  return classes.getCustomClass(isNewLayout);
};
class UpgradeContainer extends React.PureComponent {
  componentDidMount() {
    const { upgradeSuccessRefresh } = this.props;
    upgradeSuccessRefresh();
  }

  render() {
    const {
      // store
      step,
      isOpen,
      currentModal,
      errors,
      // actions
      closeUpgradeDetail,
      // selector
      modalStepProps,
    } = this.props;

    const isInfoStep = step === VIEWSTEPS.Info;
    const isProductsStep = step === VIEWSTEPS.Products;

    const { body } = hasCustomClass(isInfoStep);

    if (isInfoStep) {
      return (
        <>
          <ErrorNotifications errors={errors} />

          <InfoModal />
        </>
      );
    }

    if (isProductsStep) {
      return (
        <>
          <ErrorNotifications errors={errors} />

          <Modal
            show={isOpen}
            onClose={() => closeUpgradeDetail(currentModal)}
            width={modalStepProps.width}
            flexWidth={false}
          >
            <Modal.Body classes={{ body }}>
              <ProductsList />
            </Modal.Body>

            <Modal.Footer align={VIEWPOSITION.Right}>
              <ProductButton />
            </Modal.Footer>
          </Modal>
        </>
      );
    }

    return (
      <>
        <ErrorNotifications errors={errors} />

        <SuccessModal />
      </>
    );
  }
}

UpgradeContainer.propTypes = {
  step: PropTypes.number,
  isOpen: PropTypes.bool,
  currentModal: PropTypes.string,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func.isRequired,
      code: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }).isRequired,
  ),

  // actions
  closeUpgradeDetail: PropTypes.func.isRequired,
  upgradeSuccessRefresh: PropTypes.func.isRequired,
  modalStepProps: PropTypes.shape(),
};

UpgradeContainer.defaultProps = {
  errors: { message: null, code: null },
  modalStepProps: null,
  isOpen: false,
  step: 1,
  currentModal: 'finances',
};

const mapStateToProps = (state) => ({
  successInfo: getSelectedProductSuccessInfo(state),
  modalStepProps: getModalPropsByStep(state),
  ...state.upgrade,
});

export default connect(mapStateToProps, actions)(UpgradeContainer);
