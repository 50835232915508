import { Box, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const UpgradeCallWrapper = styled(Box)(({ theme }) => ({
  gridArea: 'upgrade-call',

  [theme.breakpoints.down(1200)]: {
    display: 'none',
  },
}));
