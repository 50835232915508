import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export const HEADER_HEIGHT = 56;

export default makeStyles((theme) => ({
  header: {
    paddingTop: tokens.spacing.xl,
    paddingBottom: tokens.spacing.xl,
  },
  container: {
    height: '100%',
  },
  body: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
  },
  clinicHeader: {
    display: 'flex',
    alignItems: 'center',
  },
  divider: {
    margin: `0 ${tokens.spacing.lg}`,
    width: '32px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: tokens.color.neutral[0],
    fontSize: tokens.font.size.lg,
    fontWeight: 100,
    position: 'relative',
    bottom: '3px',
  },
}));
