import { useFormikContext } from 'formik';
import React from 'react';
import { TextField } from '@iclinic/design-system';

import { GuideSADTForm } from 'features/tissInvoicing/types';

type Identifiers = keyof GuideSADTForm;

interface TextFieldSADTProps {
  label: string;
  identifier: Identifiers;
  required?: boolean;
  disabled?: boolean;
  maxLength?: number;
}

function TextFieldSADT({
  label,
  identifier,
  required = false,
  disabled = false,
  maxLength,
}: TextFieldSADTProps) {
  const { handleChange, values, errors, touched, handleBlur } =
    useFormikContext<GuideSADTForm>();

  const inputProps = {
    maxLength: maxLength || undefined,
  };

  return (
    <TextField
      label={label}
      id={identifier}
      name={identifier}
      type="text"
      value={values[identifier]}
      onChange={handleChange}
      error={!!(errors[identifier] && touched[identifier])}
      helperText={touched[identifier] && errors[identifier]}
      onBlur={handleBlur}
      required={required}
      disabled={disabled}
      inputProps={inputProps}
      fullWidth
    />
  );
}

export default TextFieldSADT;
