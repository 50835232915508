import * as Yup from 'yup';

import { isValidFullDate } from 'shared/utils/validation';
import { AccessWay, Technique } from 'features/tissInvoicing/state/constants';
import { Procedure } from 'features/tissInvoicing/types';

const onlyNumbers = 'Somente números';
const requiredFieldText = 'Campo obrigatório';

export const procedureSchema = Yup.object().shape({
  procedures: Yup.array().of(
    Yup.object().shape({
      procedure_id: Yup.number().min(1, 'Este campo é obrigatório'),
      quantity: Yup.number()
        .required(requiredFieldText)
        .min(1, 'Não pode ser 0'),
      access_way: Yup.number().nullable(),
      technique_used: Yup.number().nullable(),
      reduction_factor: Yup.string()
        .required(requiredFieldText)
        .min(4, 'Tem duas casas decimais')
        .matches(/[0-9]?[0-9]?(\.[0-9][0-9]?)?/, onlyNumbers),
      procedure_date: Yup.mixed()
        .nullable()
        .required(requiredFieldText)
        .test('isValidFullDate', 'Data inválida', (value) => {
          return !value || isValidFullDate(value);
        }),
      procedure_start: Yup.string()
        .required(requiredFieldText)
        .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Formato HH:MM'),
      procedure_end: Yup.string()
        .required(requiredFieldText)
        .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, 'Formato HH:MM'),
    }),
  ),
});

export const emptyProcedure: Procedure = {
  procedure_id: 0,
  tuss_procedure_id: null,
  quantity: 1,
  access_way: AccessWay.Empty,
  technique_used: Technique.Empty,
  reduction_factor: '1,00',
  procedure_date: null,
  procedure_start: '',
  procedure_end: '',
  unit_price: '0.00',
};

export const proceduresSectionValues = {
  procedures: [emptyProcedure],
};
