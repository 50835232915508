import Root from './List';
import Item from './Item';
import Value from './Value';
import Content from './Content';

Root.Content = Content;
Root.Item = Item;
Root.Value = Value;

export default Root;

export const SelectableList = {
  Root,
  Content,
  Item,
  Value,
};
