// External
import { put, call, takeLatest, select } from 'redux-saga/effects';

// Internal
import { emitErrors } from 'shared/utils/emitErrors';
import { captureException } from 'shared/utils/handlerErrors';
import { removeQueryStringFromUrl } from 'shared/utils/strings';
import { getUrlParams } from 'shared/utils/url';
import iclinic from 'services/iclinic';
import * as actions from './actions';
import * as types from './types';
import { getPlanFromSlug } from 'shared/constants/benefits';
import { dispatchGaEvent } from 'shared/utils/googleAnalytics';
import { extractUtmsValueFromCookies } from './utils';

const getSharedInfo = (state) => state.shared;
const getUpgradeInfo = (state) => state.upgrade;
const getProductBySlug = (products, slug) =>
  products.find((product) => product.slug === slug);
const getCurrentProduct = (products) =>
  products.find((product) => product.isCurrent === true);
const getMostBasicPlanOcurrence = (slug, products) =>
  products.find(
    (product) => product.slug === getPlanFromSlug(slug).products[0],
  );
const getFirstProduct = (products) => products[0];
export const getProductToSelect = (slug, products) => {
  const planFromSlug = getPlanFromSlug(slug);
  return planFromSlug
    ? getMostBasicPlanOcurrence(slug, products)
    : getFirstProduct(products);
};

export function* workerGetUpgradeDetail({ payload: { currentModal } }) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.upgrade.fetchUpgradeDetail,
    );
    if (errors) {
      yield put(actions.fetchUpgradeDetailFailure(emitErrors(errors)));
    } else {
      const payload = getResponseData();
      const selectedProduct = getProductToSelect(
        currentModal,
        payload.products,
      );
      yield put(actions.fetchUpgradeDetailSuccess(payload));
      yield put(actions.selectProduct(selectedProduct.slug));
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerSelectProduct() {
  try {
    const { selectedProductSlug, products } = yield select(getUpgradeInfo);
    const selectedProduct = getProductBySlug(products, selectedProductSlug);
    yield put(actions.selectProductSuccess(selectedProduct));
  } catch (error) {
    captureException(error);
  }
}

export function* workerUpgradeNotification({ params }) {
  try {
    const { errors } = yield call(
      iclinic.upgrade.fetchUpgradeNotification,
      params,
    );
    if (errors) {
      yield put(actions.fetchUpgradeNotificationFailure(emitErrors(errors)));
    } else {
      yield put(actions.fetchUpgradeNotificationSuccess());
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerUpgradeConfirm({ payload }) {
  try {
    yield put(actions.pending());

    const utms = extractUtmsValueFromCookies();
    const { errors } = yield call(iclinic.upgrade.fetchUpgradeConfirm, {
      ...payload,
      utms,
    });

    if (errors) {
      yield put(actions.fetchUpgradeConfirmFailure(emitErrors(errors)));
    } else {
      yield put(actions.upgradeReload());
      yield call(dispatchGaEvent, 'upgrade_modal_btn_signup_click');
    }
  } catch (error) {
    yield call(captureException, error);
  }
}

export function* workerUpgradeSuccessRefresh() {
  try {
    if (getUrlParams('upgrade-success')) {
      const { getResponseData, errors } = yield call(
        iclinic.upgrade.fetchUpgradeDetail,
      );
      if (errors) {
        yield put(actions.fetchUpgradeDetailFailure(emitErrors(errors)));
      } else {
        const payload = getResponseData();
        yield put(actions.fetchUpgradeDetailSuccess(payload));
        const currentProduct = getCurrentProduct(payload.products);
        yield put(actions.selectProduct(currentProduct.slug));
        yield put(actions.openSuccessView());
        removeQueryStringFromUrl();
      }
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerUpgradeReload() {
  try {
    const { updatePermissionsUrl } = yield select(getSharedInfo);
    const url = new URL(window.location.href);
    yield call(
      [window.location, 'replace'],
      `${updatePermissionsUrl}${url.pathname}?upgrade-success=true`,
    );
  } catch (error) {
    captureException(error);
  }
}

export default function* upgradeSagas() {
  yield takeLatest(types.UPGRADE_DETAIL.OPEN, workerGetUpgradeDetail);
  yield takeLatest(types.SELECT_PRODUCT.ACTION, workerSelectProduct);
  yield takeLatest(types.FETCH_UPGRADE_CONFIRM.ACTION, workerUpgradeConfirm);
  yield takeLatest(
    types.FETCH_UPGRADE_NOTIFICATION.ACTION,
    workerUpgradeNotification,
  );
  yield takeLatest(types.UPGRADE_RELOAD.ACTION, workerUpgradeReload);
  yield takeLatest(
    types.UPGRADE_SUCCESS_REFRESH.ACTION,
    workerUpgradeSuccessRefresh,
  );
}
