import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, MaterialIcons, Body } from '@iclinic/design-system';

import { SnackbarProps } from 'features/tissInvoicing/types';
import { actions } from 'features/tissInvoicing/state/lot';
import {
  getLotDataState,
  getLotViewState,
} from 'features/tissInvoicing/state/lot/selectors';
import CurrentGuidesOverview from 'features/tissInvoicing/components/lot/CurrentGuidesOverview';
import TwoButtonDialog from 'features/tissInvoicing/components/ui/TwoButtonDialog';
import * as Styles from './styles';

const { Attachment, Delete } = MaterialIcons;

export const DELETE_MESSAGE: SnackbarProps = {
  message: 'Guias deletadas com sucesso',
  severity: 'success',
};

function Subheader() {
  const classes = Styles.useStyles();
  const dispatch = useDispatch();

  const [isCloseLotDeletDialogOpen, setIsCloseLotDeletDialogOpen] =
    useState(false);

  const { deletedGuides } = useSelector(getLotViewState);
  const { lot } = useSelector(getLotDataState);

  const updateLotGuides = () => {
    const newGuideList = lot.guides.filter(
      (guide) => !deletedGuides.includes(guide),
    );

    dispatch(
      actions.patchLotData({
        id: lot?.id,
        guides: newGuideList,
        callback: () => {
          dispatch(actions.handleDeletedGuides(deletedGuides));
          dispatch(actions.setSnackbarContent(DELETE_MESSAGE));
        },
      }),
    );
  };

  return (
    <Styles.Container
      justifyContent={lot?.is_closed ? 'flex-end' : 'space-between'}
    >
      {!lot?.is_closed && deletedGuides.length > 0 && (
        <Button
          classes={{ root: classes.deleteButton }}
          color="transparent"
          startIcon={<Delete />}
          onClick={() => setIsCloseLotDeletDialogOpen(true)}
        >
          Deletar
        </Button>
      )}
      {!lot?.is_closed && deletedGuides.length === 0 && (
        <Button
          classes={{ root: classes.button }}
          href={`/faturamento-tiss/lotes/guias/${lot?.id}`}
          color="secondary"
          startIcon={<Attachment />}
        >
          Adicionar guias
        </Button>
      )}

      <CurrentGuidesOverview
        guides={lot?.guides?.length}
        total={lot?.total_amount}
      />

      <TwoButtonDialog
        title="Deseja deletar essa(s) guia(s)?"
        content={
          <Body variant="xs">Essa ação retira essas guias desse lote.</Body>
        }
        isOpen={isCloseLotDeletDialogOpen}
        closeHandler={() => setIsCloseLotDeletDialogOpen(false)}
        leftButton={{
          text: 'Cancelar',
          handler: () => setIsCloseLotDeletDialogOpen(false),
        }}
        rightButton={{
          text: 'Deletar guia(s)',
          handler: () => {
            updateLotGuides();
            setIsCloseLotDeletDialogOpen(false);
          },
        }}
      />
    </Styles.Container>
  );
}

export default Subheader;
