import { call, put, takeLatest } from 'redux-saga/effects';
import { Response } from '@types';

import * as api from '../services';
import { ModalType, PaymentSlipData } from './types';
import * as actions from '.';

export function* fetchPaymentSlipDataWorker() {
  try {
    const { data }: Response<PaymentSlipData> = yield call(
      api.getPaymentSlipData,
    );

    yield put(actions.fetchPaymentSlipDataSuccess(data));
  } catch {
    yield put(actions.fetchPaymentSlipDataFailed());
  }
}

export function* requestPaymentSlipReleaseTrustWorker() {
  try {
    yield put(actions.requestPaymentSlipReleaseTrustStart());

    yield call(api.postPaymentReleaseTrust);

    yield put(actions.requestPaymentSlipReleaseTrustSuccess());
    yield put(actions.toggleOpenModal(ModalType.SUCCESS));
  } catch {
    yield put(actions.requestPaymentSlipReleaseTrustFailed());
  }
}

export default function* paymentSlipDataSagas() {
  yield takeLatest(actions.fetchPaymentSlipData, fetchPaymentSlipDataWorker);
  yield takeLatest(
    actions.requestPaymentSlipReleaseTrust,
    requestPaymentSlipReleaseTrustWorker,
  );
}
