import ReactDOM from 'react-dom';
import { Store } from 'redux';

import * as SentryClient from 'shared/sentryClient';
import history from 'routes/history';
import createRender from './render';
import createRenderWithProps from './renderWithProps';
import { on, off } from './pubsub';
import features from './features';
import { types, getMappedActions } from './actions';
import utils from './utils';

const createMigrationModule = (store: Store) => ({
  features,
  types,
  on,
  off,
  actions: getMappedActions(store),
  render: createRender(store, ReactDOM.render, history),
  renderWithProps: createRenderWithProps(store, ReactDOM.render),
  unmountComponentAtNode: ReactDOM.unmountComponentAtNode,
  dispatch: store.dispatch,
  utils,
  Sentry: SentryClient,
});

export default createMigrationModule;
