import { createSelector } from 'reselect';
import { FlowType } from 'features/marketing/constants';
import {
  PatientId,
  AddedFlow,
  AvailableFlow,
} from './types';
import { getInternalReturnFlow } from './utils';
import { PatientState } from './actions';

export const getAddedFlows = ({ addedFlows }: PatientState): AddedFlow[] => addedFlows;

export const getAllAvailableFlows = ({
  availableFlows,
}: PatientState): AvailableFlow[] => availableFlows;

export const getModalVisibility = ({ modalVisibility }: PatientState): boolean => modalVisibility;

export const getPatientId = ({ id }: PatientState): PatientId => id;

export const getPatientListIds = ({ patientIds }: PatientState): PatientId[] => patientIds;

export const getAvailableFlows = createSelector(
  [getAllAvailableFlows],
  (availableFlows: AvailableFlow[]): AvailableFlow[] => {
    const flows = availableFlows.filter((flow: AvailableFlow) => (
      flow.flowType === FlowType.custom
    ));

    const internalReturnFlow = getInternalReturnFlow();

    return [...flows, internalReturnFlow];
  },
);

export const getReturnTypeFlows = createSelector(
  getAllAvailableFlows, (availableFlows: AvailableFlow[]) => {
    if (!availableFlows) return [];

    return availableFlows.filter((flow: AvailableFlow) => flow.flowType === FlowType.return);
  },
);
