// External
import * as React from 'react';
import Icon from '@material/react-material-icon';

// Internal
import Button from 'ui/new/button';
import { BUTTON, SIZE } from 'ui/new/button/constants';
import { Tooltip } from 'ui/overlay';

type ActionButtonProps = {
  icon: string;
  onClick: (target: any) => void;
  altText: string;
  type?: BUTTON;
  target: any;
  size?: SIZE;
};

export default function ActionButton({
  icon,
  onClick,
  altText,
  type = BUTTON.Link,
  target = null,
  size = SIZE.ActionButton,
}: ActionButtonProps) {
  const callback = React.useCallback(() => {
    onClick(target);
  }, [onClick, target]);
  return (
    <Tooltip content={altText} align="top">
      <Button type={type} onClick={callback} size={size}>
        <Icon icon={icon} />
      </Button>
    </Tooltip>
  );
}
