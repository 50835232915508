import {
  AdditionalPlans,
  BannerTrigger,
  ModalAlertTrigger,
  ModalBannerTrigger,
} from '../containers';
import { Trigger } from '../components';

const migrations = {
  AdditionalPlans,
  BannerTrigger,
  ModalAlertTrigger,
  ModalBannerTrigger,
  AdditionalPlansTrigger: Trigger,
};

export default migrations;
