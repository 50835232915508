import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import uuid from 'uuid';

import { actions } from '.';
import { getHighlightInfoSelected, getPhysicianAndClinicId } from './selectors';
import { captureException } from 'shared/utils/handlerErrors';
import {
  saveHighlightsInfo as saveHighlightsInfoService,
  getHighlightsInfos as getHighlightsInfosService,
  getHighlightsInfoSearchDb,
} from './services';
import {
  parseHighlightsInfoToApi,
  parseHighlightsInfo,
  filterMedications,
} from '../utils';
import { getCurrentEventAndPatientId } from 'state/records/selectors';
import { HighlightsInfoTextCard, HighlightsInfoListCard } from '../types';
import {
  GetHighlightsInfosResponse,
  SearchDbHighlightsInfoListRequest,
  SearchDbHighlightsInfoListResponse,
} from './services/types';

const {
  getHighlightsInfo,
  getHighlightsInfoFailed,
  getHighlightsInfoSuccess,
  saveHighlightsInfo,
  saveHighlightsInfoFailed,
  saveHighlightsInfoSuccess,
  searchDbHighlightsInfo,
  searchDbHighlightsInfoFailed,
  searchDbHighlightsInfoSuccess,
} = actions;

export function* workerSaveHighlightsInfo() {
  const { eventId, patientId } = yield select(getCurrentEventAndPatientId);
  const { physicianId, clinicId } = yield select(getPhysicianAndClinicId);

  const highlightsInfoCard: HighlightsInfoTextCard | HighlightsInfoListCard =
    yield select(getHighlightInfoSelected);

  const basePayload = {
    patient_id: patientId,
    clinic_id: clinicId,
    physician_id: physicianId,
    event_id: eventId,
    request_uuid: uuid.v4(),
  };

  const parsedHighlightsInfo = parseHighlightsInfoToApi({
    ...highlightsInfoCard,
  });

  const payload = {
    ...parsedHighlightsInfo,
    ...basePayload,
  };

  try {
    yield call(saveHighlightsInfoService, payload);
    yield put(saveHighlightsInfoSuccess());
  } catch (error) {
    yield call(captureException, error);
    yield put(saveHighlightsInfoFailed());
  }
}

export function* workerGetHighlightsInfo(action: PayloadAction<string>) {
  try {
    const patientId = action.payload;

    const { getResponseData } = yield call(
      getHighlightsInfosService,
      patientId,
    );

    const highlightsInfos: GetHighlightsInfosResponse = getResponseData();

    const highlightsInfosFormated = highlightsInfos.cards.map(
      (highlightsInfo) => parseHighlightsInfo(highlightsInfo),
    );

    yield put(getHighlightsInfoSuccess(highlightsInfosFormated));
  } catch (error) {
    yield call(captureException, error);
    yield put(getHighlightsInfoFailed());
  }
}

export function* workerSearchDbHighlightsInfo(
  action: PayloadAction<SearchDbHighlightsInfoListRequest>,
) {
  try {
    const highlightsInfoSearchDbParams = action.payload;

    const highlightsInfoCard: HighlightsInfoListCard = yield select(
      getHighlightInfoSelected,
    );

    const { getResponseData } = yield call(
      getHighlightsInfoSearchDb,
      highlightsInfoSearchDbParams,
    );

    const highlightsInfoSearchDb: SearchDbHighlightsInfoListResponse =
      getResponseData();

    const medicationFiltered = filterMedications(
      highlightsInfoSearchDb,
      highlightsInfoCard,
    );

    yield put(searchDbHighlightsInfoSuccess(medicationFiltered));
  } catch (error) {
    yield call(captureException, error);
    yield put(searchDbHighlightsInfoFailed());
  }
}

export default function* highlightsInfoSagas() {
  yield all([
    takeLatest(getHighlightsInfo, workerGetHighlightsInfo),
    takeLatest(saveHighlightsInfo, workerSaveHighlightsInfo),
    takeLatest(searchDbHighlightsInfo, workerSearchDbHighlightsInfo),
  ]);
}
