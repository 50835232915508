import {
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
  TwilioError,
} from 'twilio-video';
import { useEffect } from 'react';

import { Callback } from 'features/video-conference/constants/types';
import { useRestartAudioVideoTrack } from 'features/video-conference/hooks/useRestartAudioVideoTrack';

export default function useHandleTrackPublicationFailed(
  room: Room | null,
  onError: Callback,
  localTracks: (LocalAudioTrack | LocalVideoTrack)[],
) {
  const { handleRestartTracks } = useRestartAudioVideoTrack(localTracks);

  useEffect(() => {
    const handleError = (error: TwilioError) => {
      handleRestartTracks();
      onError(error, true);
    };

    if (room) {
      room.localParticipant.on('trackPublicationFailed', handleError);
      return () => {
        room.localParticipant.off('trackPublicationFailed', handleError);
      };
    }

    return () => null;
  }, [room, onError, handleRestartTracks]);
}
