import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  bar: {
    minHeight: '72px',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    margin: theme.spacing(2, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      height: 'auto',
      flexWrap: 'wrap',
    },
  },
  title: {
    lineHeight: '1rem',
  },
  barIcon: {
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    marginRight: '1em',
  },
  generating: {
    color: theme.palette.text.hint,
  },
  wrapperAction: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  chargeBackIcon: {
    marginRight: '3px',
  },
}));
