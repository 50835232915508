import React from 'react';

import * as Styles from './styles';

type ExpenseConfigProps = {
  children: React.ReactNode;
};
export default ({ children }: ExpenseConfigProps): JSX.Element => (
  <Styles.Container display="flex" flexDirection="column">
    <Styles.ExpenseDescription variant="sm" role="dialog">
      Adicione custos e despesas usadas, ou crie uma nova despesa.
    </Styles.ExpenseDescription>
    {children}
    <Styles.ExpenseConfigInformation>
      Para que uma despesa seja vinculada automaticamente com um procedimento,
      cadastre-a em <a href="/faturamento-tiss/despesas">Outras despesas</a>
    </Styles.ExpenseConfigInformation>
  </Styles.Container>
);
