import { PromiseResponse } from '@types';

import { PatientParams } from 'features/tissInvoicing/types/sadt/patients';
import {
  AnsRegisterData,
  Autocomplete,
  AutocompleteInput,
  DomainTables,
  GuideVersionData,
  ProcedureAutocompleteEntry,
  PhysicianAutocompleteEntry,
  ClinicAutocompleteEntry,
} from 'features/tissInvoicing/types';
import { apiLegacy, apiTissInvoicing, iclinicApi } from '..';

export function getDomainTables(): PromiseResponse<DomainTables> {
  return apiTissInvoicing(`/domains/`);
}

export function getAutocompleteOptions(
  eventId: string,
): PromiseResponse<Omit<Autocomplete, 'hired_solicitants'>> {
  return iclinicApi(`/events/event/${eventId}/tiss-autocomplete/`);
}

export const fetchGuideVersionsAutocomplete = (): PromiseResponse<
  GuideVersionData[]
> => apiTissInvoicing(`/mirror/version/`);

export const fetchAnsRegistersAutocomplete = (): PromiseResponse<
  AnsRegisterData[]
> => iclinicApi(`/clinics/clinichealthinsurance/`);

export const fetchPatientsAutocomplete = (
  name?: string,
): PromiseResponse<AutocompleteInput[]> =>
  apiLegacy(`/pacientes/busca.json`, { params: { q: name } });

export const fetchProceduresAutocomplete = (
  tableCode: string,
  searchName?: string,
): PromiseResponse<ProcedureAutocompleteEntry[]> =>
  iclinicApi(`/tuss/procedure/`, {
    params: {
      table_code: tableCode,
      filtering: 'search_name__icontains',
      q: searchName,
    },
  });

export const fetchPhysiciansAutocomplete = (
  name?: string,
): PromiseResponse<PhysicianAutocompleteEntry[]> =>
  iclinicApi(`/accounts/physician/`, {
    params: { filtering: 'profile__name__icontains', q: name },
  });

export const fetchCBOAutocomplete = (): PromiseResponse<AnsRegisterData[]> =>
  iclinicApi(`/tuss/cbo/`, {
    params: { limit: 0 },
  });

export const fetchClinicsAutocomplete = (
  name?: string,
): PromiseResponse<ClinicAutocompleteEntry[]> =>
  iclinicApi(`/clinics/clinic/`, {
    params: { filtering: 'name__icontains', q: name },
  });

export const fetchAutocompletePatients = (
  name?: string,
): PromiseResponse<PatientParams[]> =>
  iclinicApi(`/patients/patient/`, {
    params: {
      filtering: 'name__icontains',
      q: name,
    },
  });

export const showPatientService = (
  patient_id: number,
): PromiseResponse<PatientParams> =>
  iclinicApi(`/patients/patient/${patient_id}`);
