import { useLayoutEffect } from 'react';

export default function useAddBodyClass(style, isToggle, lock) {
  useLayoutEffect(() => {
    const classListBody = document.body.classList;
    const handleShow = () => {
      if (lock) {
        classListBody[isToggle ? 'add' : 'remove'](style);
      }
    };

    handleShow();

    return () => {
      handleShow();
    };
  }, [style, isToggle, lock]);

  return isToggle;
}
