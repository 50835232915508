import * as Yup from 'yup';

import { isValidFullDate } from 'shared/utils/validation';

export const validationTransactionsFilters = Yup.object().shape({
  startDate: Yup.date()
    .nullable()
    .transform((val) =>
      val instanceof Date && !Number.isNaN(Number(val)) ? val : null,
    )
    .required('Preencha uma data válida')
    .test('isValidFullDate', 'Data inválida', (value) => {
      return value && isValidFullDate(value);
    }),
  endDate: Yup.date()
    .nullable()
    .transform((val) =>
      val instanceof Date && !Number.isNaN(Number(val)) ? val : null,
    )
    .required('Preencha uma data válida')
    .min(Yup.ref('startDate'), 'A data fim não pode ser anterior à data início')
    .test('isValidFullDate', 'Data inválida', (value) => {
      return value && isValidFullDate(value);
    }),
});
