import React from 'react';
import { useDispatch } from 'react-redux';

import { Box, Typography, Dialog, Button } from '@iclinic/design-system';
import {
  UserData,
  trackSignupIClinicPayButtons,
} from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { chooseSeller } from 'features/onlinePayment/state/config/register';
import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';

type ModalProps = {
  userData: UserData;
  open: boolean;
  onClose: () => void;
};

export default function ModalPfPj({
  userData,
  open,
  onClose,
}: ModalProps): JSX.Element {
  const dispatch = useDispatch();

  function handleClick(type: SellerType) {
    dispatch(chooseSeller({ type }));
    const normalizerButtonName =
      type === SellerType.PJ
        ? 'pay_register_legal_person'
        : 'pay_register_individual_person';
    trackSignupIClinicPayButtons({
      userData,
      buttonName: normalizerButtonName,
      buttonLocation: 'onboarding_create_payment',
      additionalProperties: 'LP',
    });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box px={3} py={2} display="flex" flexDirection="column">
        <Box mb={2}>
          <Typography id="alert-dialog-title" variant="h4">
            Como deseja registrar seus pagamentos?
          </Typography>
        </Box>
        <Box mb={4}>
          <Typography id="alert-dialog-description" variant="body2">
            Caso mude de ideia, basta cadastrar outro perfil ou alterar seus
            dados.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={() => handleClick(SellerType.PJ)}
              autoFocus
            >
              Pessoa Jurídica
            </Button>
          </Box>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => handleClick(SellerType.PF)}
          >
            Pessoa Física
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
