import MODAL from './types';

const initialState = {
  modalType: null,
  modalProps: {},
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case MODAL.OPEN:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
      };
    case MODAL.CLOSE:
      return initialState;
    default:
      return state;
  }
};

export default modal;
