import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Body } from '@iclinic/design-system';
import { formatDate } from 'shared/utils/date';
import { statusContent } from '../../constants';
import { getAwards } from '../../state/selectors';
import * as actions from '../../state';
import ButtonStatus from './ButtonStatus';
import * as S from './MobileAwardsList.styles';

interface MobileAwardsListProps {
  onRedeemAwardClick: () => void;
}

const HeaderCell = ({ header }: { header: string }) => (
  <Body variant="xs" align="center" bold>
    {header}
  </Body>
);

const TextCell = ({ text }: { text: string }) => (
  <Body variant="xs" align="center">
    {text}
  </Body>
);

const MobileAwardsList = ({ onRedeemAwardClick }: MobileAwardsListProps) => {
  const dispatch = useDispatch();
  const awards = useSelector(getAwards);

  return (
    <S.List>
      {awards &&
        awards.map(({ id, created_at, indicated, status, pay_date }, index) => (
          <S.ListItem key={created_at}>
            <S.ListItemRow isHeader>
              <S.ListItemCell>
                <HeaderCell header="Nº" />
              </S.ListItemCell>

              <S.ListItemHeaderDivider />

              <S.ListItemCell>
                <HeaderCell header="Indicação" />
              </S.ListItemCell>
            </S.ListItemRow>

            <S.ListItemRow>
              <S.ListItemCell>
                <TextCell text={(index + 1).toString()} />
              </S.ListItemCell>

              <S.ListItemCell>
                <TextCell text={indicated} />
              </S.ListItemCell>
            </S.ListItemRow>

            <S.ListItemRow isHeader>
              <S.ListItemCell>
                <HeaderCell header="Status" />
              </S.ListItemCell>

              <S.ListItemHeaderDivider />

              <S.ListItemCell>
                <HeaderCell header="Data de resgate" />
              </S.ListItemCell>
            </S.ListItemRow>

            <S.ListItemRow>
              <S.ListItemCell>
                <ButtonStatus
                  disabled={status !== 'Aguardando Escolha'}
                  redeemed={status !== 'Aguardando Pagamento'}
                  onClick={() => {
                    dispatch(actions.updateAwardSelected({ id, indicated }));
                    onRedeemAwardClick();
                  }}
                >
                  {statusContent.get(status)?.label}
                </ButtonStatus>
              </S.ListItemCell>

              <S.ListItemCell>
                <TextCell text={pay_date ? formatDate(pay_date) : '-'} />
              </S.ListItemCell>
            </S.ListItemRow>
          </S.ListItem>
        ))}
    </S.List>
  );
};

export default MobileAwardsList;
