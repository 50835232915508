import React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { Box, SectionTitle, MaterialIcons } from '@iclinic/design-system';
import { ResponsiveCard } from 'features/onlinePayment/components';
import { getSellerSaveError } from 'features/onlinePayment/state/config/register/selectors';
import { ErrorAlert } from 'shared/components';
import { SellerForm } from 'features/onlinePayment/state/config/types';
import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';
import Subtitle from '../Subtitle';
import CardProfilePF from './CardProfilePF';
import CardProfilePJ from './CardProfilePJ';

const { Person } = MaterialIcons;

export default function CardProfile(): JSX.Element {
  const error = useSelector(getSellerSaveError);
  const { values } = useFormikContext<SellerForm>();

  return (
    <ResponsiveCard variant="outlined">
      <SectionTitle icon={<Person />} title="Perfil" mb={1} />
      <Box mb={3}>
        <Subtitle>
          Preencha para criar seu perfil médico no Pagamento Online
        </Subtitle>
      </Box>
      {error && (
        <ErrorAlert
          message={<span dangerouslySetInnerHTML={{ __html: error }} />}
          mb={3}
          name="save-seller-error"
          scrollToElement
        />
      )}
      {values.type === SellerType.PF ? <CardProfilePF /> : <CardProfilePJ />}
    </ResponsiveCard>
  );
}
