import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, spacing } = tokens;

export const ShareOptionWrapper = styled('li')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: spacing.sm,
  borderBottom: `${border.width.hairline} solid ${color.secondary.light}`,

  '& ~ li': {
    paddingTop: spacing.sm,
  },
});

export const ChipWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: spacing.sm,
});
