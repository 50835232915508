// External
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import qs from 'qs';

// Internal
import { FORM_STOCK_ADD, FORM_STOCK_EDIT } from 'shared/constants/forms';
import { StoreState } from 'state/rootReducer';
import { SelectedLot } from 'features/stock/state/types';
import {
  filterLotsDescription,
  filterLotQuantitiesByProduct,
  filterLotsByProductId,
  filterUnits,
  normalizeInventoriesHistory,
} from './utils';

const selector = formValueSelector(FORM_STOCK_ADD);
const selectorEdit = formValueSelector(FORM_STOCK_EDIT);

const getInventories = (state: StoreState) => state.stock.inventories;
const getProductDescription = (state: StoreState) =>
  state.stock.productDescription;
const getLotDescription = (state: StoreState) => state.stock.lotDescription;
const getSelectedLot = (state: StoreState) => state.stock.selectedLot;
const getCreatedLot = (state: StoreState) => state.stock.lot;
const getSelectedProduct = (state: StoreState) => state.stock.selectedProduct;
const getProductStock = (state: StoreState) => state.stock.productStock;
const getUnits = (state: StoreState) => state.stock.units;
const getUnitSearch = (state: StoreState) => selector(state, 'unit_search');
const getUnitSearchEdit = (state: StoreState) =>
  selectorEdit(state, 'unit_search');

export const getInventoriesParams = (state: StoreState) => state.stock.history;
export const getPageNumber = (state: StoreState) => state.stock.page;
export const getProducts = (state: StoreState) => state.stock.products;
export const getUsers = (state: StoreState) => state.stock.users;
export const getInventoriesHistory = (state: StoreState) =>
  state.stock.inventoriesHistory;

export const getClinicId = ({ userInfo }: StoreState) => userInfo.clinicId;
export const getProfileId = ({ userInfo }: StoreState) => userInfo.profileId;

export const getLots = createSelector(
  [getInventories, getLotDescription],
  (inventories, lotDescription) => {
    const filterLotsInput = inventories.filter(
      (inventory) => inventory.kind === 0,
    );
    return filterLotsDescription(filterLotsInput, lotDescription);
  },
);

const getShowAddMore = (
  lotDescription: string,
  selectedLot: SelectedLot | null,
  createdLot: SelectedLot | null,
) => {
  if (!lotDescription.length) return false;
  if (selectedLot && lotDescription === selectedLot.description) {
    return false;
  }
  return !createdLot;
};

export const showAddMore = createSelector(
  [getLotDescription, getSelectedLot, getCreatedLot],
  (lotDescription, selectedLot, createdLot) =>
    getShowAddMore(lotDescription, selectedLot, createdLot),
);

export const lotIsOpen = createSelector(
  [
    getSelectedLot,
    getLotDescription,
    getCreatedLot,
    getInventories,
    getProductDescription,
  ],
  (
    selectedLot,
    lotDescription,
    createdLot,
    inventories,
    productDescription,
  ): boolean => {
    const lots = filterLotsDescription(inventories, productDescription);
    const showAddMoreSelector = getShowAddMore(
      lotDescription,
      selectedLot,
      createdLot,
    );

    if (showAddMoreSelector) return true;
    return !!(lots.length > 0 && !selectedLot);
  },
);

export const getLotsFromOpenedProduct = createSelector(
  [getProductStock],
  (productStock) => filterLotQuantitiesByProduct(productStock),
);

export const getLotsBySelectedProductId = createSelector(
  [getInventories, getSelectedProduct],
  (inventories, selectedProduct) =>
    filterLotsByProductId(inventories, selectedProduct),
);

export const getFilteredUnits = createSelector(
  [getUnits, getUnitSearch],
  (units, unitSearch) => filterUnits(unitSearch, units),
);

export const getFilteredUnitsEdit = createSelector(
  [getUnits, getUnitSearchEdit],
  (units, unitSearch) => filterUnits(unitSearch, units),
);

export const getInventoryKind = createSelector(
  [getInventoriesParams],
  (history) => history.kind,
);

export const getInventoryKindName = createSelector(
  [getInventoriesParams],
  (history) => (!history.kind ? 'Entrada' : 'Saída'),
);

export const getInventoryMessage = createSelector(
  [getInventoriesParams, getInventoriesHistory],
  (historyParams, inventories) =>
    historyParams.period === 'all' && inventories.length === 0
      ? 'Ainda não foi efetuada nenhuma entrada no estoque.'
      : 'Nenhum produto encontrado',
);

export const getFilterParamsUrl = createSelector(
  [getInventoriesParams, getClinicId],
  (historyParams, clinicId) => {
    const params = normalizeInventoriesHistory(clinicId, historyParams);
    return qs.stringify(params, { allowDots: true, skipNulls: true });
  },
);
