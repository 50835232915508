import React from 'react';
import classnames from 'classnames';

import { Box, Heading, Body } from '@iclinic/design-system';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useChatContext } from 'features/video-conference/state/chatContext';
import { useAnnotationContext } from 'features/video-conference/state/annotationContext';
import { calculateAge, isMobile } from 'features/video-conference/utils';
import { Participant } from 'features/video-conference/constants/types';
import useStyles from './styles';

interface Props {
  participant: Participant;
  isSmallContainer?: boolean;
}

export default function RemoteParticipantInfo({
  participant,
  isSmallContainer = false,
}: Props): JSX.Element {
  const { isPhysician, isBackgroundSelectionOpen } = useConference();
  const { isChatWindowOpen } = useChatContext();
  const { isAnnotationWindowOpen } = useAnnotationContext();
  const classes = useStyles();

  const ageInfo = participant.birthday
    ? `${calculateAge(participant.birthday)} anos`
    : '';

  const isDrawerOpen =
    isChatWindowOpen || isAnnotationWindowOpen || isBackgroundSelectionOpen;

  return (
    <Box
      className={classnames(classes.root, {
        [classes.hidden]: isMobile && isDrawerOpen,
      })}
    >
      <Heading variant="sm" inverseTextColor>
        {participant.name}
      </Heading>
      <Body
        variant="xxs"
        inverseTextColor
        className={classnames({ [classes.hidden]: isSmallContainer })}
      >
        {isPhysician ? ageInfo : participant.specialtyName}
      </Body>
    </Box>
  );
}
