import { getOnlyNumbers } from 'shared/utils/strings';

export const hasFullAddress = (address, number) => !!address && !!number;

export const hasRequiredLength = (value) =>
  !!value && getOnlyNumbers(value).length === 11;

export const getName = ({ name, civil_name }) => civil_name || name;

export const dataTransformer = (data) => {
  const { city, address, number, cpf, mobile_phone: mobilePhone } = data;

  const props = {};

  const phone = hasRequiredLength(mobilePhone)
    ? getOnlyNumbers(mobilePhone)
    : null;

  const formatAddress = hasFullAddress(address, number)
    ? `${address}, ${number}`
    : null;

  const formatCpf = hasRequiredLength(cpf) ? getOnlyNumbers(cpf) : null;

  if (phone) props.telefone = phone;
  if (formatAddress) props.endereco = formatAddress;
  if (city) props.cidade = city;
  if (formatCpf) props.cpf = formatCpf;

  return {
    nome: getName(data),
    idExterno: data.id,
    ...props,
  };
};

export const buildMemedSetPatient = (data) => {
  const transformedData = dataTransformer(data);

  return Object.keys(transformedData).reduce((result, key) => {
    if (!transformedData[key]) return result;
    return { ...result, [key]: transformedData[key] };
  }, {});
};
