import React from 'react';

import { YOU } from 'features/video-conference/constants';
import { MessageLabelContainer, MessageTime, MessageUser } from './styles';

interface MessageLabelProps {
  author: string;
  dateCreated: string;
  isLocalParticipant: boolean;
}

export default function MessageLabel({
  author,
  dateCreated,
  isLocalParticipant,
}: MessageLabelProps) {
  return (
    <MessageLabelContainer>
      <MessageUser variant="overline">
        {isLocalParticipant ? YOU : author}
      </MessageUser>
      <MessageTime variant="overline">{dateCreated}</MessageTime>
    </MessageLabelContainer>
  );
}
