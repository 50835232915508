import {
  MaterialUICore,
  axiomTokens,
  Card,
  MaterialIcons,
} from '@iclinic/design-system';

const { EditRounded } = MaterialIcons;

const { styled } = MaterialUICore;

export const CardContainer = styled(Card)(({ theme }) => ({
  flexShrink: 0,
  flexGrow: 1,
  flexBasis: '216px',
  minWidth: '216px',
  height: '88px',
  cursor: 'pointer',
  backgroundColor: axiomTokens.color.surface.subtle.default,
  border: `${axiomTokens.border.width.xs} solid ${axiomTokens.color.border.subtle}`,
  borderRadius: axiomTokens.border.radius.sm,
  boxShadow: 'none',

  '&:hover': {
    backgroundColor: axiomTokens.color.surface.subtle.hovered,
    boxShadow: axiomTokens.shadow.level[2],

    '& svg': {
      visibility: 'visible',
    },
  },
  '&:active': {
    backgroundColor: axiomTokens.color.surface.subtle.pressed,
    boxShadow: 'none',
  },

  [theme.breakpoints.down('md')]: {
    width: '224px',
    height: '96px',
  },
}));

export const Container = styled('div')({
  display: 'flex',
  padding: `${axiomTokens.spacing[2]} 0 ${axiomTokens.spacing[2]} 0`,
  gap: axiomTokens.spacing[2],
});

export const HeadingText = styled('div')(({ theme }) => ({
  fontFamily: axiomTokens.font.family.base,
  fontSize: axiomTokens.font.size.xxxs,
  fontWeight: axiomTokens.font.weight.medium,
  lineHeight: axiomTokens.font.lineHeight.md,
  letterSpacing: axiomTokens.font.letterSpacing.sm,
  color: axiomTokens.color.typeface.pure,

  [theme.breakpoints.down('md')]: {
    fontSize: axiomTokens.font.size.xxs,
    lineHeight: axiomTokens.font.size.xxs,
  },
}));

const BodyTextGeneral = styled('div')(({ theme }) => ({
  fontFamily: axiomTokens.font.family.base,
  fontWeight: axiomTokens.font.weight.regular,
  fontSize: axiomTokens.font.size.xxs,
  lineHeight: axiomTokens.font.lineHeight.md,
  letterSpacing: axiomTokens.font.letterSpacing.sm,

  [theme.breakpoints.down('md')]: {
    fontSize: axiomTokens.font.size.xxs,
  },
}));

export const BodyTextEmpty = styled(BodyTextGeneral)({
  color: axiomTokens.color.typeface.smooth,
});

export const BodyTextFilled = styled(BodyTextGeneral)({
  color: axiomTokens.color.typeface.pure,
  maxHeight: '40px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre-wrap',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',
});

export const EditRoundedStyled = styled(EditRounded)({
  color: axiomTokens.color.icon.default,
  width: axiomTokens.font.size.xs,
  height: axiomTokens.font.size.xs,
});

export const ContainerHeaderAndIcon = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  marginBottom: axiomTokens.spacing[1],
});

export const ContainerEdit = styled('div')({
  visibility: 'hidden',
  lineHeight: 0,
});

export const WrapperContent = styled('div')({
  margin: `${axiomTokens.spacing[2]} ${axiomTokens.spacing[3]}`,
});
