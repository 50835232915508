import { Body, MaterialUICore, tokens } from '@iclinic/design-system';

const {
  FormControlLabel: MuiFormControlLabel,
  makeStyles,
  styled,
} = MaterialUICore;
const { color, font } = tokens;

export default makeStyles(() => ({
  checkboxError: {
    color: color.feedback.error.main,
    borderColor: color.feedback.error.main,
  },
}));

export const FormControlLabel = styled(MuiFormControlLabel)({
  marginBottom: 0,
});

export const ErrorText = styled(Body)({
  color: color.feedback.error.main,
  fontSize: font.size.xxxs,
});
