import React from 'react';

import { Box } from '@iclinic/design-system';
import ChatInput from './ChatInput';
import { useWindowHeight } from 'features/video-conference/utils';
import { useChatContext } from 'features/video-conference/state/chatContext';
import ChatDrawer from '../TeamComponents/Drawer';
import MessageList from './MessageList';
import useStyles from '../styles';
import {
  buttonsTitle,
  FILE_PATIENT_WARNING_1,
  FILE_PATIENT_WARNING_2,
  FILE_PATIENT_WARNING_3,
  FILE_PHYSICIAN_WARNING_1,
  FILE_PHYSICIAN_WARNING_2,
  FILE_PHYSICIAN_WARNING_3,
  FILE_PHYSICIAN_WARNING_4,
} from 'features/video-conference/constants';
import Alert from '../TeamComponents/Alert';
import { useConference } from 'features/video-conference/state/conferenceContext';

export default function Chat() {
  const { isChatWindowOpen, setIsChatWindowOpen, messages } = useChatContext();
  const { isPhysician } = useConference();
  const height = useWindowHeight();
  const classes = useStyles({ height });

  const message = isPhysician ? (
    <span>
      {FILE_PHYSICIAN_WARNING_1}
      <b>{FILE_PHYSICIAN_WARNING_2}</b>
      {FILE_PHYSICIAN_WARNING_3}
      {FILE_PHYSICIAN_WARNING_4}
    </span>
  ) : (
    <span>
      {FILE_PATIENT_WARNING_1}
      <b>{FILE_PATIENT_WARNING_2}</b>
      {FILE_PATIENT_WARNING_3}
    </span>
  );

  return (
    <Box className={classes.iframeHidden}>
      <ChatDrawer
        open={isChatWindowOpen}
        onClose={() => setIsChatWindowOpen(!isChatWindowOpen)}
        title={buttonsTitle.CHAT}
      >
        <Box m={2}>
          <Alert message={message} />
        </Box>
        <MessageList messages={messages} />
        <ChatInput isChatWindowOpen={isChatWindowOpen} />
      </ChatDrawer>
    </Box>
  );
}
