// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import { Icon } from '@iclinic/iclinic-ui';
import { Tooltip } from 'ui/overlay';
import style from './Description.scss';

export default function Description({
  productName,
  users,
  value,
  period,
  content,
}) {
  return (
    <div className={style.description}>
      <p className={style.description__title}>{productName}</p>
      <p className={style.description__content}>{`${users} profissiona${
        users > 1 ? 'is' : 'l'
      } de saúde`}</p>
      <div className={style.price}>
        <p className={style.price__value}>{`R$ ${value}/${period}`}</p>
        <Tooltip content={content} align="top">
          <Icon className={style.price__info} name="info" size={15} />
        </Tooltip>
      </div>
    </div>
  );
}

Description.propTypes = {
  users: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
};
