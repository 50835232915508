// External
import React from 'react';
import Icon from '@material/react-material-icon';
// Internal
import classnames from 'classnames/bind';

import { COLOR, ALIGN } from './constants';
import styles from './CheckImage.scss';

const cx = classnames.bind(styles);

type CheckImageProps = {
  classes?: string;
  color?: COLOR;
  align?: ALIGN;
  selected?: boolean;
  tabIndex?: number;
  height?: string;
  disabled?: boolean;
  src?: string;
  title?: string;
  alt?: string;
  width: string;
  showSelectIcon: boolean;
  onClickCheck: () => void;
};

export default function CheckImage({
  classes = '',
  color = COLOR.White,
  align = ALIGN.Left,
  height = 'auto',
  disabled = false,
  tabIndex = 0,
  title,
  src,
  alt,
  width,
  selected,
  showSelectIcon,
  onClickCheck,
}: CheckImageProps): JSX.Element {
  return (
    <div
      className={cx(styles['check-image'], [
        { [styles['-disabled']]: disabled },
        classes,
        align,
      ])}
      role="button"
      onClick={onClickCheck}
      onKeyPress={onClickCheck}
      tabIndex={tabIndex}
    >
      {showSelectIcon && (
        <button
          type="button"
          className={cx(styles.container, color)}
          aria-label="Selecionar imagem"
        >
          {selected && <Icon className={styles.icon} icon="check_circle" />}
        </button>
      )}
      <img
        className={styles.img}
        src={src}
        alt={alt}
        title={title}
        width={width}
        height={height}
      />
    </div>
  );
}
