// External
import React from 'react';
import {
  RichUtils,
  getDefaultKeyBinding,
  KeyBindingUtil,
  EditorState,
  Modifier,
} from 'draft-js';

// Internal
import { typeStyleEditor } from './constants';

type EntityData = {
  automatedTag: string,
};

type InlineStyles = {
  has: (value: string) => boolean;
};

type EntityDecoratedText = {
  text: string;
};

type CurrentInfo = {
  inlineStyles: InlineStyles;
  blockType: string;
  entityType: string;
  entityData: EntityData;
  selectionText: string;
  entityDecoratedText: EntityDecoratedText;
};

const useDraftJSStyles = (
  editorState: EditorState,
  updateTextEditor: any,
  openModalEditorLink: (editor: EditorState) => void,
  openModalEditorButton: (editor: EditorState) => void,
  openModalEditorImage: (editor: EditorState) => void,
  currentInfo: CurrentInfo,
) => React.useMemo(() => {
  const {
    entityType: currentEntityType,
  } = currentInfo;

  const openModalCurrentEntity = () => {
    switch (currentEntityType) {
      case typeStyleEditor.button:
        openModalEditorButton(editorState);
        break;
      case typeStyleEditor.link:
        openModalEditorLink(editorState);
        break;
      case typeStyleEditor.image:
        openModalEditorImage(editorState);
        break;
      default:
        break;
    }
  };

  const openFormatLink = () => {
    if (currentEntityType) {
      openModalCurrentEntity();
      return;
    }

    openModalEditorLink(editorState);
  };

  const openFormatButton = () => {
    if (currentEntityType) {
      openModalCurrentEntity();
      return;
    }

    openModalEditorButton(editorState);
  };

  const openFormatImage = () => {
    if (currentEntityType) {
      openModalCurrentEntity();
      return;
    }

    openModalEditorImage(editorState);
  };

  const createStyleToggler = (styleName: string) => () => {
    updateTextEditor(RichUtils.toggleInlineStyle(editorState, styleName));
  };

  const createBlock = (blockType: string) => () => {
    updateTextEditor(RichUtils.toggleBlockType(editorState, blockType));
  };

  const blockRemoveHeader = () => {
    const content = Modifier.removeInlineStyle(editorState.getCurrentContent(), editorState.getSelection(), 'HEADER');
    updateTextEditor(EditorState.push(editorState, content, 'change-inline-style'));
  };

  const handleKeyCommand = (
    command: string,
    editor: EditorState,
  ) => {
    switch (command) {
      case typeStyleEditor.link:
        openFormatLink();
        return 'handled';
      case typeStyleEditor.button:
        openFormatButton();
        return 'handled';
      default:
        break;
    }

    const newState = RichUtils.handleKeyCommand(editor, command);
    if (newState) {
      updateTextEditor(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const keyBindingFn = (event: React.KeyboardEvent<{}>) => {
    if (KeyBindingUtil.hasCommandModifier(event)) {
      switch (event.keyCode) {
        case typeStyleEditor.linkCommand:
          return typeStyleEditor.link;
        case typeStyleEditor.buttonCommand:
          return typeStyleEditor.button;
        default:
          break;
      }
    }

    return getDefaultKeyBinding(event);
  };

  return {
    formatTextBold: createStyleToggler(typeStyleEditor.bold),
    formatTextItalic: createStyleToggler(typeStyleEditor.italic),
    formatTextTitle: createStyleToggler(typeStyleEditor.title),
    formatTextNormal: blockRemoveHeader,
    formatAlignCenter: createBlock(typeStyleEditor.alignCenter),
    formatAlignLeft: createBlock(typeStyleEditor.alignLeft),
    handleKeyCommand,
    keyBindingFn,
    openFormatLink,
    openFormatButton,
    openFormatImage,
  };
}, [
  currentInfo,
  openModalEditorButton,
  editorState,
  openModalEditorLink,
  openModalEditorImage,
  updateTextEditor,
]);

export default useDraftJSStyles;
