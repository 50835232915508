import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  buttonIcon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  disabledButton: {
    color: theme.palette.text.secondary,
  },
  downloadRow: {
    '& td': {
      cursor: 'pointer',
    },
  },
  paper: {
    paddingLeft: 0,
    paddingRight: 0,
    marginBottom: theme.spacing(4),
  },
  sectionTitle: {
    paddingLeft: theme.spacing(4),
  },
  table: {
    '& tr:nth-of-type(even)': {
      backgroundColor: theme.palette.background.default,
    },
    '& tr th': {
      padding: theme.spacing(3, 4),
    },
    '& tr td': {
      padding: theme.spacing(3, 4),
    },
    '& tr td a': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'right',
      textDecoration: 'none',
    },
    '& tr td a svg': {
      marginRight: theme.spacing(1),
    },
  },
  oddTextColor: {
    color: theme.palette.text.secondary,
  },
  upperText: {
    textTransform: 'uppercase',
  },
}));
