import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getPaymentSlipData,
  getPaymentSummaryAccountBlocked,
} from '../../state/selectors';
import { fetchPaymentSlipData } from '../../state';
import { CardRequestRelease } from './CardRequestRelease';
import { CardPlanResume } from './CardPlanResume';
import * as S from './SubscriptionPlan.styles';

export const SubscriptionPlan = () => {
  const dispatch = useDispatch();
  const { canRequestReleaseTrust, isExpired } = useSelector(getPaymentSlipData);
  const isAccountBlocked = useSelector(getPaymentSummaryAccountBlocked);

  useEffect(() => {
    dispatch(fetchPaymentSlipData());
  }, [dispatch]);

  return (
    <S.Container>
      <S.ContainerTitle variant="xs">Seu plano</S.ContainerTitle>

      <S.CardsWrapper>
        <CardPlanResume />

        {canRequestReleaseTrust && isExpired && isAccountBlocked && (
          <CardRequestRelease />
        )}
      </S.CardsWrapper>
    </S.Container>
  );
};
