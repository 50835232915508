import React from 'react';
import { useSelector } from 'react-redux';

import { StoreState } from 'state/rootReducer';
import { useLastEventToday } from 'features/feedDataLayer';
import { STEP_SIGNATURE } from './constants';
import SignatureInitial from './SignatureInitial';
import SignaturePreviewDownload from './SignaturePreviewDownload';
import SignatureSelectCertificates from './SignatureSelectCertificates';
import SignatureSetup from './SignatureSetup';
import SignatureLoader from './SignatureLoader';

const SignatureStepper: React.FC = () => {
  const stepper: STEP_SIGNATURE = useSelector<StoreState>(
    (state) => state.records.signature.stepper,
  );
  useLastEventToday();

  const signatureStep = {
    [STEP_SIGNATURE.Initial]: <SignatureInitial />,
    [STEP_SIGNATURE.Setup]: <SignatureSetup />,
    [STEP_SIGNATURE.Certificates]: <SignatureSelectCertificates />,
    [STEP_SIGNATURE.Preview]: <SignaturePreviewDownload />,
    [STEP_SIGNATURE.Loading]: <SignatureLoader />,
  };

  return signatureStep[stepper];
};

export default SignatureStepper;
