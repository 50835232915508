import { MaterialUICore, TextField, tokens } from '@iclinic/design-system'

const { styled } = MaterialUICore;

export const PaidByOtherContainer = styled('div')(({theme}) => ({
  paddingLeft: '95px',
  width: '100%',
  '& .form_inline_fields': {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: tokens.spacing.sm,
    marginBottom: tokens.spacing.sm
  },
  '& > .closed': {
    height: 0,
    opacity: 0,
    transition: '.2s all ease-in-out',
    '& > .paid_by_other_form': {
      display: 'none'
    }
  },
  '& > .opened': {
    height: '150px',
    opacity: 1,
    transition: 'height .3s, opacity .2s ease-in-out',
    '& > .paid_by_other_form': {
      display: 'block'
    }
  },

  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0
  }
}))

export const CustomTextField = styled(TextField)(() => ({
  width: '100%'
}))
