import { call, put, takeLatest, all, fork } from 'redux-saga/effects';

import { sendPasswordResetEmail, getAuthToken } from 'services/iclinic/auth';
import { handlerErrors } from 'state/shared/sagas';
import * as actions from '.';

type SubmitAction = ReturnType<typeof actions.submit>;

export function* submitWorker({ payload: email }: SubmitAction) {
  try {
    const token = yield call(getAuthToken);
    yield call(sendPasswordResetEmail, email, token);
    yield put(actions.success());
  } catch (error) {
    yield put(actions.error(error.message));
    yield call(handlerErrors, error, actions.error);
  }
}

export function* watchResetPasswordFlow() {
  yield takeLatest(actions.submit, submitWorker);
}

export default function* resetPasswordSagas() {
  yield all([fork(watchResetPasswordFlow)]);
}
