// External
import { createSelector } from 'reselect';

// Internal
import { selectProduct } from 'state/shared/selectors';
import { getSuccessAddonViews } from 'shared/utils/views';
import { StoreState } from 'state/rootReducer';
import {
  TEXT_IS_NOT_SUBSCRIBER,
  TEXT_IS_SUBSCRIBER,
  TEXT_AGREE,
  TEXT_NON_ADMIN,
  VIEWMODE,
} from '../constants';

export const getSelectedProduct = (state: StoreState) => state.billing.addon.product;

export const getSelectedProductSlug = (state: StoreState) => (
  state.billing.addon.product
    ? state.billing.addon.product.slug
    : state.billing.addon.modal
);

export const getPeriod = (state: StoreState) => state.billing.addon.isAnnual;

export const getIsSubscriber = (state: StoreState) => (
  state.billing.addon.userInfo
  && state.billing.addon.userInfo.isSubscriber
);

export const getUserIsAdmin = (state: StoreState) => (
  state.billing.addon.userInfo
  && state.billing.addon.userInfo.isAdmin
);

export const getIsPaymentSlip = (state: StoreState) => (
  state.billing.addon.paymentInfo
  && state.billing.addon.paymentInfo.paymentSlip
);

export const getSelectedProductPrice = createSelector(
  [getSelectedProduct, getPeriod],
  selectProduct,
);

export type SelectedProductPrice = ReturnType<typeof getSelectedProductPrice>;

export const getSuccessInfo = createSelector(
  getSelectedProductSlug,
  (slug) => getSuccessAddonViews(slug),
);

export const getButtonMessageInfo = createSelector(
  [getIsSubscriber, getIsPaymentSlip, getUserIsAdmin],
  (isSubscriber, isPaymentSlip, isAdmin) => {
    if (isPaymentSlip) {
      return TEXT_AGREE;
    }
    if (isAdmin) {
      return isSubscriber ? TEXT_IS_SUBSCRIBER : TEXT_IS_NOT_SUBSCRIBER;
    }
    return TEXT_NON_ADMIN;
  },
);

export const getViewMode = createSelector(
  [getUserIsAdmin, getIsPaymentSlip, getIsSubscriber],
  (isAdmin, isPaymentSlip, isSubscriber) => {
    const isTrial = !isSubscriber;
    if (isPaymentSlip) {
      return VIEWMODE.paymentSlip;
    }
    if (isAdmin) {
      return isTrial ? VIEWMODE.addonTrial : VIEWMODE.productInfo;
    }
    return VIEWMODE.nonAdmin;
  },
);
