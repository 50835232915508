import React from 'react';
import { Field } from 'redux-form';
import classnames from 'classnames/bind';

import { ReduxFormInput, ReduxFormMeta } from '@types';
import { StoreState } from 'state/rootReducer';
import {
  required,
  number,
  fullDate,
  positive,
} from 'shared/utils/formValidation';
import { FULL_DATE } from 'shared/constants/masks';
import { Input as InputField } from 'ui/new/form';
import { Row, Column } from 'ui/new/flex';
import InputSearch from 'ui/new/input-search';
import { InputRenderProps } from 'features/stock/containers/EnhanceStockInputProductForms';
import { Lot, Product } from 'features/stock/types';
import style from './Input.scss';

const classes = classnames.bind(style);

type Input = {
  selectedProduct?: Product | null;
  lotItems?: React.ReactNode;
  productItems?: React.ReactNode | boolean;
  lotAddMore?: React.ReactNode;
  input?: ReduxFormInput;
  meta?: ReduxFormMeta;
  name: string;
  autoFocus: boolean;
  handleSubmit: () => void;
  onChangeProductDescription: (
    description: string | number | undefined,
  ) => void;
  onChangeLotDescription: (description: string | number | undefined) => void;
  productDescription: string;
  lotDescription: string;
  selectedLot: Lot;
  showAddMore: React.ReactNode;
  lotIsOpen: boolean;
  createdLot: Lot;
};

type FieldInputSearchProductProps = {
  autoFocus: boolean;
  name: string;
  meta: ReduxFormMeta;
  input: ReduxFormInput;
} & Pick<
  InputRenderProps,
  'selectedProduct' | 'productItems' | 'productDescription'
>;

export function FieldInputSearchProduct({
  selectedProduct = null,
  productItems = null,
  productDescription,
  autoFocus,
  name,
  input,
  meta,
}: FieldInputSearchProductProps): JSX.Element {
  return (
    <InputSearch
      autoFocus={autoFocus}
      value={productDescription}
      open={productItems && !selectedProduct}
      name={name}
      placeholder="Digite 3 ou mais letras para pesquisar"
      input={input}
      meta={meta}
    >
      {productItems}
    </InputSearch>
  );
}

type FieldInputSearchLotProps = {
  name: string;
  meta: ReduxFormMeta;
  input: ReduxFormInput;
} & Pick<
  InputRenderProps,
  'lotItems' | 'lotAddMore' | 'lotDescription' | 'lotIsOpen' | 'showAddMore'
>;

export function FieldInputSearchLot({
  lotItems = null,
  lotAddMore = null,
  lotDescription,
  lotIsOpen,
  showAddMore,
  name,
  input,
  meta,
}: FieldInputSearchLotProps): JSX.Element {
  return (
    <InputSearch
      value={lotDescription}
      name={name}
      icon={null}
      addMore={showAddMore && lotAddMore}
      placeholder="Selecione ou digite um lote"
      open={lotIsOpen}
      input={input}
      meta={meta}
    >
      {lotItems}
    </InputSearch>
  );
}

const selectedProductIsRequired = (_, __, { stock }: StoreState) => {
  if (!stock) return undefined;

  return !stock.selectedProduct ? 'Selecione um produto' : undefined;
};

const selectedLotIsRequired = (_, __, { stock }: StoreState) => {
  if (!stock) return undefined;

  return !stock.selectedLot
    ? 'Adicione ou selecione um lote existente'
    : undefined;
};

export default function Input({
  selectedProduct = null,
  lotItems = null,
  productItems = null,
  lotAddMore = null,
  handleSubmit,
  onChangeProductDescription,
  onChangeLotDescription,
  productDescription,
  lotDescription,
  selectedLot,
  showAddMore,
  lotIsOpen,
  createdLot,
}: InputRenderProps): JSX.Element {
  return (
    <Column className={classes('body')} onSubmit={handleSubmit}>
      <Column className={classes('product', 'row')}>
        <label htmlFor="productDescription">Pesquisar produto</label>
        <Field
          name="productDescription"
          component={FieldInputSearchProduct}
          productItems={productItems}
          productDescription={productDescription}
          selectedProduct={selectedProduct}
          validate={selectedProductIsRequired}
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
            onChangeProductDescription(target.value)
          }
          autoFocus
        />
      </Column>
      <Row className={classes('row')}>
        <Column className={classes('qtd')}>
          <label htmlFor="code">Quant.</label>
          <Field
            name="quantity"
            id="quantity"
            component={InputField}
            validate={[required, number, positive]}
            type="number"
            min="1"
          />
        </Column>
      </Row>
      {!!selectedProduct && selectedProduct.hasLotControl && (
        <Row className={classes('row')}>
          <Column className={classes('lot')}>
            <label htmlFor="lotDescription">Lote</label>
            <Field
              name="lotDescription"
              component={FieldInputSearchLot}
              lotItems={lotItems}
              lotDescription={lotDescription}
              lotIsOpen={lotIsOpen}
              showAddMore={showAddMore}
              lotAddMore={lotAddMore}
              validate={selectedLotIsRequired}
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                onChangeLotDescription(target.value)
              }
            />
          </Column>
          <Column className={classes('shelf_life')}>
            <label htmlFor="expirationDate">Validade</label>
            <Field
              name="expirationDate"
              placeholder="__/__/____"
              mask={FULL_DATE}
              id="expirationDate"
              component={InputField}
              validate={[required, fullDate]}
              disabled={!!selectedLot && !createdLot}
            />
          </Column>
        </Row>
      )}
      <Column className={classes('observation')}>
        <label htmlFor="observation">Observações (opcional)</label>
        <Field name="observation" id="observation" component={InputField} />
      </Column>
    </Column>
  );
}
