import React, { useMemo } from 'react';

import FlipCard from 'ui/new/flip-card';
import { getCardImage } from 'shared/utils/creditCardValidation';
import { Checkout } from 'state/billing/subscription/types';
import SubscriptionForm from 'features/billing/subscriptionFlowModal/containers/SubscriptionForm';
import style from './SubscriptionCardWrapper.scss';

type WrapperProps = {
  iconPath: string;
  checkout: Checkout;
  isFlipped: boolean;
};

export default function SubscriptionCardWrapper({
  iconPath = 'cards',
  checkout,
  isFlipped,
}: WrapperProps): JSX.Element {
  const { number, owner, expirationDate, cvv } = checkout;
  const [expirationMonth, expirationYear] = expirationDate.split('/');
  const imagePath = useMemo(() => getCardImage(number, iconPath), [
    number,
    iconPath,
  ]);
  return (
    <div className={style.container}>
      <div className={style.container__form}>
        <SubscriptionForm />
      </div>
      <div className={style.container__card}>
        <FlipCard
          number={number}
          owner={owner}
          flag={imagePath}
          expirationMonth={expirationMonth}
          expirationYear={expirationYear}
          cvv={cvv}
          isFlipped={isFlipped}
        />
      </div>
    </div>
  );
}
