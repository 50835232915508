import { IAutocompleteOption } from 'features/tissInvoicing/containers/expense/interfaces/AutocompleteOption';
import { ExpenseApiResponse } from 'features/tissInvoicing/types';
import { IExpenseItem, TTypeOptions } from './interfaces/ExpenseItem';

type TNormalizeParams = (
  A: ExpenseApiResponse[] | IExpenseItem[],
  R: TTypeOptions[],
  G: IAutocompleteOption[],
) => any;

export const normalizeExpenses: TNormalizeParams = (
  expenses,
  typeOptions,
  unitTypeOptions,
) =>
  expenses.map((exp) => ({
    ...exp,
    type: typeOptions.find((type) => String(type.id) === exp.type?.toString()),
    tuss_code: {
      id: exp.tuss_expense?.code,
      title: exp.tuss_expense?.code,
      term: exp.tuss_expense?.term,
    },
    unity_type: unitTypeOptions.find(
      (unitType) => unitType.id === exp.unit_type,
    ),
  }));
