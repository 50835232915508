import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Header = styled('div')({
  display: 'flex',
  columnGap: axiomTokens.spacing['4'],
  paddingBottom: axiomTokens.spacing['4'],
  justifyContent: 'flex-end',
});

export const Block = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const Select = styled('select')({
  width: axiomTokens.spacing['72'],
  height: axiomTokens.spacing['8'],
  paddingLeft: axiomTokens.spacing['2'],
  paddingRight: axiomTokens.spacing['2'],
  marginLeft: axiomTokens.spacing['2'],
});
