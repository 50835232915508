import React from 'react';

export const TEXT_MULTICLINIC = (clinicName, physicianName) => (
  <>
    Exibir a clínica <strong>{clinicName}</strong> no agendamento online de{' '}
    <strong>{physicianName}</strong>
  </>
);
export const TEXT_ONE_CLINIC = (physicianName) => (
  <>
    Exibir a agenda de <strong>{physicianName}</strong> no seu Perfil Online.
  </>
);

export enum insuranceAttendanceType {
  Presential = 'has_online_schedule_active',
  Telemedicine = 'allow_online_scheduling_telemedicine',
}
