import React from 'react';
import { MaterialIcons } from '@iclinic/design-system';

import { buttonsTitle } from 'features/video-conference/constants';
import ToggleButton from 'features/video-conference/components/TeamComponents/ToggleButton';
import { setTelemedicineOptions } from 'features/video-conference/utils';
import { useChatContext } from 'features/video-conference/state/chatContext';
import { useAnnotationContext } from 'features/video-conference/state/annotationContext';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useParticipantContext } from 'features/video-conference/state/participantContext';

const { ContentPaste } = MaterialIcons;

export default function GoToMedicalRecordsButton() {
  const { isPhysician, conferenceId, setIsBackgroundSelectionOpen } =
    useConference();
  const { localParticipant, remoteParticipant } = useParticipantContext();
  const { setIsChatWindowOpen } = useChatContext();
  const { setIsAnnotationWindowOpen } = useAnnotationContext();

  const handleClick = () => {
    setIsChatWindowOpen(false);
    setIsAnnotationWindowOpen(false);
    setIsBackgroundSelectionOpen(false);
    setTelemedicineOptions('iframe_status', 'minimize');
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'minimize_telemedicine_iframe',
      buttonLocation: 'toolbar',
    });
  };

  return (
    <ToggleButton
      onClick={handleClick}
      switchedOn
      variant="info"
      updateOn={false}
      title={buttonsTitle.MEDICAL_RECORDS}
      label={buttonsTitle.MEDICAL_RECORDS}
      icon={<ContentPaste />}
      aria-controls={buttonsTitle.MEDICAL_RECORDS}
      dataGa="minimize_telemedicine_iframe"
      dataSp="minimize_telemedicine_iframe"
    />
  );
}
