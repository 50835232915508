// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import style from './Total.scss';

export default function Total({ price, text }) {
  return (
    <div className={style.total}>
      <span className={style.total__label}>{text}</span>
      <span className={style.total__price}>{price}</span>
    </div>
  );
}

Total.defaultProps = {
  price: undefined,
  text: '',
};

Total.propTypes = {
  price: PropTypes.string,
  text: PropTypes.string,
};
