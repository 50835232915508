import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing } = tokens;

export const CameraOffContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& .MuiAvatar-root': {
    backgroundColor: color.primary.dark,
    textTransform: 'uppercase',
    color: color.neutral[25],
    width: '80px',
    height: '80px',
    fontSize: '28px',
    fontWeight: 'bold',
  },
  '& .MuiTypography-root': {
    marginTop: spacing.base,
    color: color.neutral[25],
    textTransform: 'capitalize',
    [theme.breakpoints.down(320)]: {
      display: 'none',
    },
  },
}));
