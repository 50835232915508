import { createSelector } from 'reselect';

import { StoreState } from 'state/rootReducer';
import { getProfessionCodes } from '../domainTables/selectors';
import {
  HiredSolicitantAutocompleteEntry,
  HiredSolicitantSlug,
  PhysicianAutocompleteEntry,
  ProcedureAutocompleteEntry,
  ProfessionCodeAutocompleteEntry,
  HiredProviderAutocompleteEntry,
} from 'features/tissInvoicing/types';

export const blankProcedureOption = {
  id: 0,
  name: '‎‎‎‏‏‎ ‎',
  price: '0.00',
  tuss_procedure_id: null,
};

export const getProcedures = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.autocomplete.autocomplete?.procedures;

export const getPhysicians = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.autocomplete.autocomplete?.physicians;

export const getExecutors = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.autocomplete.autocomplete?.executors;

export const getClinics = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.autocomplete.autocomplete?.clinics;

export const getCBO = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.autocomplete.autocomplete?.profession_codes;

export const getAutocomplete = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.autocomplete.autocomplete;

export const getExecutorsOptions = createSelector(
  getExecutors,
  (executors): PhysicianAutocompleteEntry[] => [
    ...executors.map((e) => ({
      ...e,
      name: e.profile?.name,
    })),
  ],
);

export const getHiredOptions = createSelector(
  getPhysicians,
  getClinics,
  (physicians, clinics): HiredSolicitantAutocompleteEntry[] => [
    ...physicians.map((p) => ({
      ...p,
      type: 'Profissionais',
      slug: HiredSolicitantSlug.Physician,
    })),
    ...clinics.map((c) => ({
      ...c,
      type: 'Clínicas',
      slug: HiredSolicitantSlug.Clinic,
    })),
  ],
);

export const getProcedureOptions = createSelector(
  getProcedures,
  (procedures): ProcedureAutocompleteEntry[] => [
    { ...blankProcedureOption },
    ...procedures,
  ],
);

export const getCboOptions = createSelector(
  getProfessionCodes,
  (profession_code): ProfessionCodeAutocompleteEntry[] => [
    ...profession_code.map((p) => ({
      ...p,
      name: `${p.code} - ${p.term}`,
      type: 'cbo',
    })),
  ],
);

export const getProviderOptions = createSelector(
  getPhysicians,
  getClinics,
  (physicians, clinics): HiredProviderAutocompleteEntry[] => [
    ...physicians.map((p) => ({
      ...p,
      type: 'Profissionais',
      slug: HiredSolicitantSlug.Physician,
      name: p.profile?.name,
      cnes: p.cnes,
    })),
    ...clinics.map((c) => ({
      ...c,
      type: 'Clínicas',
      slug: HiredSolicitantSlug.Clinic,
      cnes: c.cnes,
    })),
  ],
);
