import { AxiosResponse } from 'axios';
import { PromiseEnvelope, DataEnvelope } from '@types';

export const decodeError = ({ timestamp, ...rest }: { timestamp: string }) => ({
  timestamp: new Date(timestamp),
  ...rest,
});

export const decodePromiseEnvelope = async <RawDataType, DecodedDataType>(
  envelopedPromise: PromiseEnvelope<RawDataType>,
  decoder: (raw: RawDataType) => DecodedDataType,
): Promise<DataEnvelope<DecodedDataType>> => {
  const { getResponseData, errors } = await envelopedPromise;
  return {
    errors,
    getResponseData: (): DecodedDataType => decoder(getResponseData()),
  };
};

export const hasEnvelopedData = (response: AxiosResponse) =>
  response.data && response.data.data;
