// Internal
import omit from 'lodash.omit';

// External
import { captureException } from 'shared/utils/handlerErrors';
import { getCreditCardNameByNumber } from 'shared/utils/creditCard';
import { removeWhitespaces } from 'shared/utils/strings';
import { PRODUCTS_SUBSCRIPTION_GROUP } from './constants';

export const getSelectedSlugs = (
  hasExtraProduct,
  extraProductSlug,
  selectedProduct,
) =>
  hasExtraProduct
    ? [selectedProduct.slug, ...[extraProductSlug]]
    : [selectedProduct.slug];
export const getStaticProductFunctionalitiesBySlug = (slug) =>
  PRODUCTS_SUBSCRIPTION_GROUP.filter(
    (staticProduct) => (staticProduct.slug === slug)[0].functionalities,
  );

export const replaceFunctionalitiesToConstants = (products) => {
  try {
    return products.map((product) => {
      const functionalities = getStaticProductFunctionalitiesBySlug(
        product.slug,
      );

      return {
        ...product,
        functionalities,
      };
    });
  } catch (error) {
    captureException(error);
    return products;
  }
};

export const normalizeSubscribePayload = (
  subscribePayload,
  isMonthly,
  selectedProducts,
) => {
  const [
    expirationMonth,
    expirationYear,
  ] = subscribePayload.expirationDate.split('/');
  const { cpfCnpj, number, ...cardProps } = subscribePayload;
  const normalizedCardProps = omit(cardProps, 'expirationDate');
  const creditCardNumberWithoutWhitespace = removeWhitespaces(number);
  const flagName = getCreditCardNameByNumber(creditCardNumberWithoutWhitespace);
  const flag = flagName.toLowerCase();
  return {
    isAnnual: !isMonthly,
    products: selectedProducts,
    cpfCnpj,
    card: {
      number: creditCardNumberWithoutWhitespace,
      expirationMonth,
      expirationYear,
      flag,
      ...normalizedCardProps,
    },
  };
};
