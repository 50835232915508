import { createSelector } from '@reduxjs/toolkit';

import { IStoreState } from 'state/rootReducer';
import { cepFormatter } from 'shared/utils/formatters';
import { RequestStatus } from 'shared/constants/State';

export const requestStatusSelector = (state: IStoreState) =>
  state.cfmUpdate.main.status;
export const professionalSelector = (state: IStoreState) =>
  state.cfmUpdate.main.professional;
export const cboSelector = (state: IStoreState) => state.cbo.listCBO.objects;
export const clinicSelector = (state: IStoreState) =>
  state.cfmUpdate.main.clinic;

export const professionalFormSelector = createSelector(
  professionalSelector,
  cboSelector,
  (professional, cboList) => {
    const cpf = professional?.profile?.cpf || '';
    const rqe = professional?.rqe || '';
    const council_name = professional?.council_name || '';
    const council_number = professional?.council_number || '';
    const council_region = professional?.council_region || '';

    const foundCbo = cboList.find((value) => professional?.cbo === value.cbo);
    const cbo = foundCbo?.term || '';

    return { cpf, rqe, cbo, council_name, council_number, council_region };
  },
);

export const cboInputSelector = createSelector(cboSelector, (cboList) => [
  { active: false, term: '', id: 0, cbo: 0 },
  ...cboList,
]);

export const clinicFormSelector = createSelector(clinicSelector, (clinic) => {
  return {
    zip_code: cepFormatter(clinic?.zip_code || ''),
    address: clinic?.address || '',
    number: clinic?.number?.toString() || '',
    complement: clinic?.complement || '',
    city: clinic?.city || '',
    state: clinic?.state || '',
    neighborhood: clinic?.neighborhood || '',
  };
});

export const stepSelector = (state: IStoreState) => state.cfmUpdate.main.step;

export const requestStatusDerivedSelector = createSelector(
  requestStatusSelector,
  (status) => {
    return {
      requestStatus: status,
      isPending: status === RequestStatus.Pending,
      hasError: status === RequestStatus.Error,
    };
  },
);
