import React from 'react';
import { MaterialIcons, Tooltip } from '@iclinic/design-system';

import {
  buttonsTitle,
  UNSUPPORTED_BROWSER_VERSION_SHARE_SCREEN,
} from 'features/video-conference/constants';
import ToggleButton from 'features/video-conference/components/TeamComponents/ToggleButton';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';

const { ScreenShareOutlined, ScreenShare } = MaterialIcons;

export default function ScreenShareButton() {
  const { isPhysician, conferenceId } = useConference();
  const { localParticipant, remoteParticipant } = useParticipantContext();
  const { toggleScreenShare, isSharingScreen } = useConference();
  const isScreenShareSupported =
    navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;

  const handleToggleScreenShare = () => {
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'telemedicine_screen_share',
      buttonLocation: 'toolbar',
      action: isSharingScreen ? 'disabled' : 'enabled',
    });
    toggleScreenShare();
  };

  const screenShareIcon = isSharingScreen ? (
    <ScreenShare />
  ) : (
    <ScreenShareOutlined />
  );
  const screenShareLabel = isSharingScreen
    ? buttonsTitle.SCREEN_SHARED
    : buttonsTitle.SCREEN_SHARE;

  return (
    <Tooltip
      title={
        !isScreenShareSupported ? UNSUPPORTED_BROWSER_VERSION_SHARE_SCREEN : ''
      }
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <div>
        <ToggleButton
          onClick={handleToggleScreenShare}
          switchedOn={!isSharingScreen}
          variant="info"
          updateOn={false}
          title={screenShareLabel}
          label={screenShareLabel}
          icon={screenShareIcon}
          disabled={!isScreenShareSupported}
          aria-controls="chat"
          dataGa="telemedicine_screen_share"
          dataSp="telemedicine_screen_share"
        />
      </div>
    </Tooltip>
  );
}
