import { IntegrationStep } from 'shared/constants/settings';
import * as types from './types';

export const removeIntegration = (physicianId) => ({
  type: types.REMOVE_INTEGRATION_ONLINE_SCHEDULE.ACTION,
  payload: {
    physicianId,
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const removeIntegrationSuccess = () => ({
  type: types.REMOVE_INTEGRATION_ONLINE_SCHEDULE.SUCCESS,
  payload: {
    error: false,
    notification: {
      show: true,
      kind: 'success',
      title: 'Sucesso!',
      description: 'Integração removida com sucesso!',
    },
  },
});

export const setStepIntegration = (step) => ({
  type: types.SET_STEP_INTEGRATION.ACTION,
  payload: {
    step,
  },
});

export const showCreateIntegrationModal = () => ({
  type: types.SHOW_CREATE_INTEGRATION_MODAL.ACTION,
});

export const hideCreateIntegrationModal = (isIntegrated) => ({
  type: types.HIDE_CREATE_INTEGRATION_MODAL.ACTION,
  payload: {
    isIntegrated,
  },
});

export const setIsLoadingIntegration = (isLoading) => ({
  type: types.SET_IS_LOADING_INTEGRATION.ACTION,
  payload: {
    integration: {
      isLoading,
    },
  },
});

export const validateEmailIntegration = (email) => ({
  type: types.VALIDATE_EMAIL_INTEGRATION.ACTION,
  payload: {
    email,
  },
});

export const validateEmailIntegrationSuccess = (nextStep, email) => ({
  type: types.VALIDATE_EMAIL_INTEGRATION.SUCCESS,
  payload: {
    integration: {
      step: nextStep,
      email,
    },
  },
});

export const validateEmailIntegrationFailure = () => ({
  type: types.VALIDATE_EMAIL_INTEGRATION.FAILURE,
  payload: {
    integration: {
      step: IntegrationStep.DefaultError,
      email: '',
    },
  },
});

export const validateEmailAlreadyIntegrated = () => ({
  type: types.VALIDATE_EMAIL_ALREADY_USED.ACTION,
  payload: {
    integration: {
      step: IntegrationStep.EmailAlreadyUsed,
    },
  },
});

export const validatePasswordIntegration = (email, password) => ({
  type: types.VALIDATE_PASSWORD_INTEGRATION.ACTION,
  payload: {
    email,
    password,
  },
});

export const validatePasswordIntegrationSuccess = (token, profile_slug) => ({
  type: types.VALIDATE_PASSWORD_INTEGRATION.SUCCESS,
  payload: {
    integration: {
      invalidPassword: false,
      step: IntegrationStep.IntegrationSuccess,
      token,
      profile_slug,
    },
  },
});

export const validatePasswordIntegrationFailure = () => ({
  type: types.VALIDATE_PASSWORD_INTEGRATION.FAILURE,
  payload: {
    integration: {
      invalidPassword: true,
    },
  },
});

export const createPasswordIntegration = (
  email,
  password,
  hasAcceptedTerms,
) => ({
  type: types.CREATE_PASSWORD_INTEGRATION.ACTION,
  payload: {
    email,
    password,
    hasAcceptedTerms,
  },
});

export const createPasswordIntegrationSuccess = (token) => ({
  type: types.CREATE_PASSWORD_INTEGRATION.SUCCESS,
  payload: {
    integration: {
      step: IntegrationStep.CreationSuccess,
      token,
    },
  },
});

export const fetchSettings = (physicianId) => ({
  type: types.FETCH_SETTINGS_ONLINE_SCHEDULE.ACTION,
  payload: {
    physicianId,
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const updateSettingsAction = () => ({
  type: types.UPDATE_SETTINGS_ONLINE_SCHEDULE.ACTION,
  payload: {
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const updateSettingsSuccess = () => ({
  type: types.UPDATE_SETTINGS_ONLINE_SCHEDULE.SUCCESS,
  payload: {
    error: false,
    notification: {
      show: true,
      kind: 'success',
      title: 'Sucesso!',
      description: 'Configuração salva com sucesso!',
    },
  },
});

export const updateSettingsFailure = (errorMessage = '') => ({
  type: types.UPDATE_SETTINGS_ONLINE_SCHEDULE.FAILURE,
  payload: {
    error: true,
    notification: {
      show: true,
      kind: 'error',
      title: 'Atenção',
      description: errorMessage,
    },
  },
});

export const notifyBlockedWeekDay = () => ({
  type: types.NOTIFY_BLOCKED_WEEKDAY.ACTION,
  payload: {
    error: false,
    notification: {
      show: true,
      kind: 'warning',
      title: 'Atenção',
      description: 'Essa dia está bloqueado na sua agenda.',
    },
  },
});

export const getScheduleWorkHours = () => ({
  type: types.GET_SCHEDULE_HOURS.ACTION,
});

export const getScheduleWorkHoursSuccess = (scheduleWorkHours) => ({
  type: types.GET_SCHEDULE_HOURS.SUCCESS,
  payload: {
    scheduleWorkHours,
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const getScheduleWorkHoursFailure = (errorMessage) => ({
  type: types.GET_SCHEDULE_HOURS.FAILURE,
  payload: {
    error: true,
    notification: {
      show: true,
      kind: 'error',
      title: 'Atenção',
      description: errorMessage,
    },
  },
});

export const fetchSettingsSucces = (settings) => ({
  type: types.FETCH_SETTINGS_ONLINE_SCHEDULE.SUCCESS,
  payload: {
    settings,
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const fetchSettingsFailure = (errorMessage = '') => ({
  type: types.FETCH_SETTINGS_ONLINE_SCHEDULE.FAILURE,
  payload: {
    settings: {},
    error: true,
    notification: {
      show: true,
      kind: 'error',
      title: 'Atenção',
      description: errorMessage,
    },
  },
});

export const onChangeCheckInsurance = (insuranceId, insuranceAttendance) => ({
  type: types.ON_CHANGE_CHECK_INSURANCE.ACTION,
  payload: {
    insuranceId,
    insuranceAttendance,
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const onChangeAllCheckInsurances = (insuranceAttendance) => ({
  type: types.ON_CHANGE_ALL_CHECK_INSURANCE.ACTION,
  payload: {
    insuranceAttendance,
  },
});

export const onChangeCheckDay = (weekDay, index) => ({
  type: types.ON_CHANGE_CHECK_DAY.ACTION,
  payload: {
    weekDay,
    index,
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const onChangeCheckDaySuccess = (servicePeriods) => ({
  type: types.ON_CHANGE_CHECK_DAY.SUCCESS,
  payload: {
    settings: {
      settings: {
        service_periods: servicePeriods,
      },
    },
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const onChangeSelectHour = (index, id, value, weekDay) => ({
  type: types.ON_CHANGE_SELECT_HOUR.ACTION,
  payload: {
    index,
    id,
    value,
    weekDay,
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const onChangeSelectHourSucces = (servicePeriods) => ({
  type: types.ON_CHANGE_SELECT_HOUR.SUCCESS,
  payload: {
    settings: {
      settings: {
        service_periods: servicePeriods,
      },
    },
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const addHourSuccess = (servicePeriods) => ({
  type: types.ADD_WORK_HOUR.SUCCESS,
  payload: {
    settings: {
      settings: {
        service_periods: servicePeriods,
      },
    },
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const removeHourSuccess = (servicePeriods) => ({
  type: types.REMOVE_WORK_HOUR.SUCCESS,
  payload: {
    settings: {
      settings: {
        service_periods: servicePeriods,
      },
    },
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const validateDaysScheduleSuccess = (servicePeriods) => ({
  type: types.VALIDATE_DAYS_SCHEDULE.SUCCESS,
  payload: {
    settings: {
      settings: {
        service_periods: servicePeriods,
      },
    },
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const validateDaysScheduleFailure = (errorMessage = '') => ({
  type: types.VALIDATE_DAYS_SCHEDULE.FAILURE,
  payload: {
    settings: {
      settings: {
        service_periods: {},
      },
    },
    error: false,
    notification: {
      show: true,
      kind: 'error',
      title: 'Atenção',
      description: errorMessage,
    },
  },
});

export const onChangeAntecedencePeriod = (antecedence_period) => ({
  type: types.ON_CHANGE_ANTECEDENCE_PERIOD.ACTION,
  payload: {
    settings: {
      settings: {
        antecedence_period,
      },
    },
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const onChangeSwitchIsShowOnlineSchedule = () => ({
  type: types.ON_CHANGE_SWITCH_IS_SHOW_SCHEDULE.ACTION,
  payload: {
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const addHour = (weekDay) => ({
  type: types.ADD_WORK_HOUR.ACTION,
  payload: {
    weekDay,
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const removeHour = (weekDay, index) => ({
  type: types.REMOVE_WORK_HOUR.ACTION,
  payload: {
    weekDay,
    index,
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const fetchPhysicians = () => ({
  type: types.FETCH_PHYSICIANS.ACTION,
});

export const updateInsurancesOnlineSchedule = () => ({
  type: types.UPDATE_INSURANCES_ONLINE_SCHEDULE.ACTION,
});

export const updateInsurancesOnlineScheduleSuccess = (response) => ({
  type: types.UPDATE_INSURANCES_ONLINE_SCHEDULE.SUCCESS,
  payload: {
    response,
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const updateInsurancesOnlineScheduleFailure = (errorMessage = '') => ({
  type: types.UPDATE_INSURANCES_ONLINE_SCHEDULE.FAILURE,
  payload: {
    error: true,
    notification: {
      show: true,
      kind: 'error',
      title: 'Atenção',
      description: errorMessage,
    },
  },
});

export const invalidateInsurances = (errorMessage = '') => ({
  type: types.INVALIDATE_INSURANCES.ACTION,
  payload: {
    error: false,
    notification: {
      show: true,
      kind: 'warning',
      title: 'Atenção',
      description: errorMessage,
    },
  },
});

export const fetchPhysiciansSuccess = (physicians) => ({
  type: types.FETCH_PHYSICIANS.SUCCESS,
  payload: {
    physicians,
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export const fetchPhysiciansFailure = (errorMessage = '') => ({
  type: types.FETCH_PHYSICIANS.FAILURE,
  payload: {
    physicians: [],
    error: true,
    notification: {
      show: true,
      kind: 'error',
      title: 'Atenção',
      description: errorMessage,
    },
  },
});

export const fetchSettingsInitialized = () => ({
  type: types.FETCH_SETTINGS_INITIALIZED.ACTION,
});

export const getActiveIclinic = (clinicId) => ({
  type: types.GET_ACTIVE_CLINIC.ACTION,
  payload: {
    clinicId,
    error: false,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});
