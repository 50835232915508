import React, { PropsWithChildren } from 'react';
import { TwilioError } from 'twilio-video';

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogHeader,
  Button,
  Body,
} from '@iclinic/design-system';
import { ApiErrors } from '@types';
import { image } from 'shared/utils/urlGetter';
import {
  cameraBlockedIcon,
  DEVICE_PERMISSION_DENY_MESSAGE,
  DEVICE_PERMISSION_DENY_MESSAGE_2,
  DEVICE_PERMISSION_DENY_TITLE,
} from 'features/video-conference/constants';
import { ImageIcon } from './styles';

interface ErrorDialogProps {
  dismissError: Function;
  error: TwilioError | Error | ApiErrors | null;
}

function ErrorDialog({
  dismissError,
  error,
}: PropsWithChildren<ErrorDialogProps>) {
  const { message } = error || {};

  return (
    <Dialog
      open={
        error !== null &&
        (message === 'Permission denied' ||
          message ===
            'The request is not allowed by the user agent or the platform in the current context.')
      }
      fullWidth
      maxWidth="xs"
    >
      <DialogHeader
        title={DEVICE_PERMISSION_DENY_TITLE}
        onClose={() => dismissError()}
      />
      <DialogContent>
        <Body variant="xs">
          {DEVICE_PERMISSION_DENY_MESSAGE}
          <ImageIcon
            src={image(cameraBlockedIcon.imagePath)}
            alt={cameraBlockedIcon.altText}
          />
          {DEVICE_PERMISSION_DENY_MESSAGE_2}
        </Body>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dismissError()} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
