/* eslint-disable jsx-a11y/label-has-for */
// External
import React from 'react';
import { Container, Item } from '@iclinic/iclinic-ui';
import classNames from 'classnames/bind';
import InputMask from 'react-input-mask';

// Internal
import style from '../InputLabel.scss';
import FieldError from '../FieldError';
import Size from './constants/Size';

type fieldProps = {
  value?: string | number;
  onChange?: () => void;
  onBlur?: () => void;
  name: string;
};

type formProps = {
  touched?: {};
  errors?: {};
};


type InputEnhanceProps = {
  type?: string;
  mask?: string;
  maskChar?: string;
  required?: boolean;
  placeholder?: string;
  append?: React.ReactNode;
  label?: string;
  size: Size ;
  id: string;
  field: fieldProps;
  form: formProps;
};
const classes = classNames.bind(style);

export default function InputLabel({
  type = 'text',
  mask = '',
  maskChar = '',
  placeholder = '',
  append,
  label,
  size,
  id,
  field,
  form: {
    errors,
    touched,
  },
  ...props
}: InputEnhanceProps): JSX.Element {
  const hasError = errors[field.name] && touched[field.name];
  return (
    <div className={classes('group')}>
      <label htmlFor={label}>{label}</label>
      <Container direction="row">
        <Item grow="1">
          <InputMask
            id={id}
            mask={mask}
            maskChar={maskChar}
            alwaysShowMask={false}
            type={type}
            className={classes(style[size], { error: errors[field.name] })}
            placeholder={placeholder}
            {...field}
            {...props}
          />
        </Item>
        {append && (
          <Item grow="1">
            {append}
          </Item>
        )}
      </Container>
      {hasError && <FieldError classes="small">{errors[field.name]}</FieldError>}
    </div>
  );
}
