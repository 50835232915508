import { apiPrescriptions } from './api';
import { IClinicPrescription } from './utils';
import { RecordIds } from './types';

const basePath = '/prescriptions/';

export const fetchPrescriptionRefreshToken = () =>
  apiPrescriptions.patch(`${basePath}settings/afya/refresh-token/`);

export const createPrescription = (record: RecordIds) => {
  const { eventId, physicianId, patientId, clinicId } = record;

  const bodyRequest = {
    event: {
      id: eventId,
    },
    physician: {
      id: physicianId,
    },
    patient: {
      id: patientId,
    },
    clinic: {
      id: clinicId,
    },
  };

  return apiPrescriptions.post(basePath, bodyRequest);
};

export const updatePrescription = (
  prescriptionId: number,
  iclinicPrescription: IClinicPrescription,
) =>
  apiPrescriptions.patch(`${basePath}${prescriptionId}/`, iclinicPrescription);
