import React from 'react';
import { useSelector } from 'react-redux';

import BoxesList from './BoxesList';
import { getAvailableBoxesData } from '../../state/sample-boxes/selectors';
import { SampleBox } from 'features/free-samples/services/types';

interface AvailableTabProps {
  onDetailsClick: (box: SampleBox) => void;
}

const AvailableTab = ({ onDetailsClick }: AvailableTabProps) => {
  const { isFetchingAvailable, availableBoxes } = useSelector(
    getAvailableBoxesData,
  );

  return (
    <BoxesList
      isFetching={isFetchingAvailable}
      listBoxes={availableBoxes}
      onDetailsClick={onDetailsClick}
      emptyMessage="Você já solicitou as boxes disponíveis para sua especialidade"
    />
  );
};

export default AvailableTab;
