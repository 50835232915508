import React from 'react';
import { useSelector } from 'react-redux';
import { Loader, MaterialUICore } from '@iclinic/design-system';

import ShareUrlAlternate from '../ShareUrl/ShareUrl';
import * as S from './Banner.styles';
import { getShortUrls, isFetching } from 'features/referrals/state/selectors';
import { canShare } from 'shared/utils/webShare';
import { ShareOptions } from './ShareOptions';

const { useTheme, useMediaQuery } = MaterialUICore;

const Banner = () => {
  const isFetchingShortUrl = useSelector(isFetching);
  const shortUrls = useSelector(getShortUrls);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const whatsappUrl = shortUrls?.whatsapp || '';
  const isShareable = isMobile && canShare({ url: whatsappUrl });

  const textIntro = isShareable ? 'Compartilhe' : 'Copie';

  return (
    <S.Section>
      <S.ContentWrapper>
        <S.BannerTitle variant="xl">
          Ganhe prêmios para cada médico que assinar o iClinic pelo seu convite
        </S.BannerTitle>

        <S.BannerDescriptionContent>
          <S.DescriptionText variant="sm">
            {textIntro} o seu convite exclusivo pelo botão abaixo e compartilhe
            com seus amigos. Você ganha até <strong>R$400</strong> e sua
            indicação ganha <strong>descontos especiais na assinatura!</strong>
          </S.DescriptionText>
          {isFetchingShortUrl ? (
            <S.LoaderWrapper>
              <Loader data-testid="share-loader" />
            </S.LoaderWrapper>
          ) : (
            <>
              <ShareUrlAlternate isShareable={isShareable} />
              <S.DescriptionText variant="sm">
                ou, se preferir, envie diretamente via:
              </S.DescriptionText>
              <ShareOptions isMobile={isMobile} isShareable={isShareable} />
            </>
          )}
        </S.BannerDescriptionContent>
      </S.ContentWrapper>
    </S.Section>
  );
};

export default Banner;
