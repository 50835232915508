import * as Yup from 'yup';

import { isValidFullDate } from 'shared/utils/validation';

export const requiredFieldText = 'Campo obrigatório';

export const beneficiarySchema = Yup.object().shape({
  patient_civil_name: Yup.string().nullable(),
  patient_social_name: Yup.string(),
  patient_sus_code: Yup.string().nullable(),
  newborn: Yup.string().required(requiredFieldText),
  patient_insurance_code: Yup.string().nullable().required(requiredFieldText),
  patient_insurance_expiry_date: Yup.mixed()
    .nullable()
    .test(
      'isValidFullDate',
      'Data inválida',
      (value) => !value || isValidFullDate(value),
    ),
});

export const beneficiarySectionValues = {
  patient_civil_name: null,
  patient_social_name: null,
  patient_sus_code: null,
  patient_id: 0,
  patient_insurance_code: null,
  patient_insurance_expiry_date: null,
  newborn: 'N',
};
