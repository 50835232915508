import {
  MaterialUICore,
  tokens,
  Box as BoxDS,
  Grid as GridDS,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing, border, font } = tokens;

export const GridContainer = styled(GridDS)(() => ({
  height: '100%',
}));

export const PixNumberWrapper = styled(BoxDS)(() => ({
  width: '100%',
  height: '56px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: `2px dashed ${color.primary.main}`,
  borderRadius: `${border.radius.md}`,
  padding: spacing.sm,
  position: 'relative',
}));

export const PixNumber = styled('input')(() => ({
  border: 'none',
  color: color.neutral[500],
  fontSize: font.size.sm,
  fontWeight: font.weight.bold,
  outline: 'none',
  paddingRight: spacing.sm,
  width: '100%',
}));
