import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  Button,
  Chip,
  CircularProgress,
  Heading,
} from '@iclinic/design-system';

import { fetchPaymentSlipData } from '../../../state';
import {
  getPaymentSlipData,
  getPaymentSummaryData,
  isFetchingPaymentSlip,
} from '../../../state/selectors';
import { OutlinedCard, Divider } from '../Common.styles';
import * as S from './CardPlanResume.styles';
import { formatDate } from 'shared/utils/date';
import { useToggle } from 'shared/hooks';
import { ModalSecondCopyPaymentSlip } from './ModalSecondCopyPaymentSlip';

export const CardPlanResume = () => {
  const dispatch = useDispatch();
  const isFetching = useSelector(isFetchingPaymentSlip);
  const { expirationDate, isExpired, link } = useSelector(getPaymentSlipData);
  const { productsNames, dueDate } = useSelector(getPaymentSummaryData);
  const [open, toggle] = useToggle(false);

  const getExpiredText = () => {
    if (!isExpired && dueDate)
      return `Próximo vencimento: ${formatDate(dueDate)}`;

    if (expirationDate) return `Vencimento: ${formatDate(expirationDate)}`;

    return 'Próximo vencimento: -';
  };

  useEffect(() => {
    dispatch(fetchPaymentSlipData());
  }, [dispatch]);

  return (
    <>
      <OutlinedCard>
        <div>
          <Heading variant="xs">{productsNames}</Heading>

          {!isFetching && (
            <S.LineWrapper>
              <Body variant="xs">{getExpiredText()}</Body>

              {isExpired ? (
                <Chip label="Vencido" severity="error" variant="default" />
              ) : null}
            </S.LineWrapper>
          )}
        </div>

        {isFetching && (
          <div>
            <CircularProgress data-testid="circular-loading" />
          </div>
        )}

        {!isFetching && link && (
          <S.LineWrapper>
            <Button
              variant="outlined"
              onClick={toggle}
              data-sp="payment_slip_second_copy_btn_click"
              data-ga="payment_slip_second_copy_btn_click"
            >
              Gerar 2º via do boleto
            </Button>
          </S.LineWrapper>
        )}

        <Divider />

        <div>
          <Heading variant="overline">Forma de pagamento</Heading>

          <Body variant="xs" bold>
            Boleto
          </Body>
        </div>
      </OutlinedCard>

      <ModalSecondCopyPaymentSlip open={open} onClose={toggle} />
    </>
  );
};
