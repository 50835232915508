import { MaterialUICore, tokens, axiomTokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const { opacity } = axiomTokens;

export default makeStyles((theme) => ({
  container: {
    position: 'relative',
    overflow: 'hidden',
    aspectRatio: '1',
    height: '100%',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      height: '208px',
    },
  },
  innerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  avatarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#263238',
    color: '#90A4AE',
    height: '100%',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    bottom: tokens.spacing.base,
    position: 'absolute',
    width: '100%',
    marginBottom: tokens.spacing.base,
    [theme.breakpoints.down(320)]: {
      display: 'none',
    },
  },
  chip: {
    bottom: tokens.spacing.base,
    position: 'absolute',
    backgroundColor: `rgba( 0, 0, 0, ${opacity.level['6']})`,
    color: tokens.color.neutral[0],
    zIndex: 1,
    border: 'none',
    '& .MuiChip-icon': {
      color: tokens.color.neutral[0],
      marginRight: tokens.spacing.nano,
      [theme.breakpoints.down(200)]: {
        marginRight: 0,
      },
    },
    '& .MuiChip-label': {
      [theme.breakpoints.down(200)]: {
        display: 'none',
      },
    },
  },

  chipBottomLarge: {
    bottom: tokens.spacing.xl,
  },

  fontSizeSmaller: {
    fontSize: tokens.font.size.xxxs,
  },
}));
