import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  PhysicianRawData,
  SellerRawData,
} from 'features/onlinePayment/services/config/types';
import { ZipCodeRawData } from 'services/iclinic/easyZipCode/types';
import { RequestStatus } from 'shared/constants/State';
import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';
import { SellerStatus } from '../../constants';
import {
  DocumentsPayload,
  SellerForm,
  SellerProfiles,
  ChooseSellerPayload,
  DocumentProofs,
} from '../types';
import {
  DOCUMENTS_ERROR,
  SELLER_FETCH_ERROR,
  UPDATE_DEFAULT_SELLER_ERROR,
} from '../errorMessages';

export interface SellerRegisterState {
  sellerForm?: SellerForm;
  sellerProfiles?: {
    data?: SellerProfiles | null;
    fetchStatus?: RequestStatus;
    error?: string;
  };
  seller?: {
    data?: SellerRawData | null;
    type?: SellerType;
    saveStatus?: RequestStatus;
    error?: string;
  };
  physician?: {
    fetchStatus?: RequestStatus;
    saveStatus?: RequestStatus;
    data?: PhysicianRawData;
  };
  documents?: {
    documentProofs?: DocumentProofs;
    status?: RequestStatus;
    error?: string;
  };
  zipCode?: {
    data?: ZipCodeRawData;
    fetchStatus?: RequestStatus;
  };
}

export const initialState: SellerRegisterState = {
  physician: { fetchStatus: RequestStatus.Pending },
  sellerProfiles: {
    fetchStatus: RequestStatus.Pending,
  },
};

const name = 'sellerRegister';

export const init = createAction(`${name}/init`);
export const createSeller = createAction<SellerForm>(`${name}/createSeller`);
export const updatePhysician = createAction<SellerForm>(
  `${name}/updatePhysician`,
);
export const saveDocuments = createAction<DocumentsPayload>(
  `${name}/saveDocuments`,
);
export const fetchZipCode = createAction<string>(`${name}/fetchZipCode`);
export const fetchSeller = createAction(`${name}/fetchSeller`);
export const updateDefaultSeller = createAction<string>(
  `${name}/updateDefaultSeller`,
);
export const sendInteractionToHubspot = createAction<string>(
  `${name}/sendInteractionToHubspot`,
);

const SellerRegisterSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      createSeller,
      (state): SellerRegisterState => ({
        ...state,
        seller: {
          ...state.seller,
          saveStatus: RequestStatus.Pending,
        },
      }),
    );
    builder.addCase(
      updatePhysician,
      (state): SellerRegisterState => ({
        ...state,
        physician: {
          ...state.physician,
          saveStatus: RequestStatus.Pending,
        },
      }),
    );
    builder.addCase(
      saveDocuments,
      (state): SellerRegisterState => ({
        ...state,
        documents: { status: RequestStatus.Pending },
      }),
    );
  },
  reducers: {
    save: (state, action: PayloadAction<SellerForm>): SellerRegisterState => ({
      ...state,
      sellerForm: action.payload,
    }),
    createSellerSuccess: (
      state,
      action: PayloadAction<SellerRawData>,
    ): SellerRegisterState => ({
      ...state,
      seller: {
        ...state.seller,
        data: action.payload,
        saveStatus: RequestStatus.Success,
        error: undefined,
      },
    }),
    createSellerFailure: (
      state,
      action: PayloadAction<string>,
    ): SellerRegisterState => ({
      ...state,
      seller: {
        ...state.seller,
        saveStatus: RequestStatus.Error,
        error: action.payload,
      },
    }),
    chooseSeller: (
      state,
      action: PayloadAction<ChooseSellerPayload | null>,
    ): SellerRegisterState => ({
      ...state,
      seller: {
        data: action.payload?.seller,
        type: action.payload?.type,
      },
    }),
    fetchSellerSuccess: (
      state,
      action: PayloadAction<SellerProfiles | null>,
    ): SellerRegisterState => ({
      ...state,
      sellerProfiles: {
        data: action.payload,
        fetchStatus: RequestStatus.Success,
      },
    }),
    fetchSellerDocumentProofSuccess: (
      state,
      action: PayloadAction<DocumentProofs>,
    ): SellerRegisterState => ({
      ...state,
      documents: {
        ...state.documents,
        documentProofs: action.payload,
      },
    }),
    fetchSellerFailure: (state): SellerRegisterState => ({
      ...state,
      sellerProfiles: {
        fetchStatus: RequestStatus.Error,
        error: SELLER_FETCH_ERROR,
      },
    }),
    fetchPhysicianSuccess: (
      state,
      action: PayloadAction<PhysicianRawData>,
    ): SellerRegisterState => ({
      ...state,
      physician: {
        data: action.payload,
        fetchStatus: RequestStatus.Success,
      },
    }),
    fetchPhysicianFailure: (state): SellerRegisterState => ({
      ...state,
      physician: {
        fetchStatus: RequestStatus.Error,
      },
    }),
    updatePhysicianSuccess: (state): SellerRegisterState => ({
      ...state,
      physician: {
        ...state.physician,
        saveStatus: RequestStatus.Success,
      },
    }),
    updatePhysicianFailure: (state): SellerRegisterState => ({
      ...state,
      physician: {
        ...state.physician,
        saveStatus: RequestStatus.Error,
      },
    }),
    submittedSuccess: (state): SellerRegisterState => {
      if (!state.seller?.data) {
        return state;
      }
      return {
        ...state,
        seller: {
          ...state.seller,
          data: {
            ...state.seller.data,
            status: SellerStatus.Submitted,
          },
        },
      };
    },
    sendDocumentsSuccess: (state): SellerRegisterState => ({
      ...state,
      documents: {
        status: RequestStatus.Success,
      },
    }),
    sendDocumentsFailure: (state): SellerRegisterState => ({
      ...state,
      documents: {
        status: RequestStatus.Error,
        error: DOCUMENTS_ERROR,
      },
    }),
    fetchZipCodeSuccess: (
      state,
      action: PayloadAction<ZipCodeRawData>,
    ): SellerRegisterState => ({
      ...state,
      zipCode: {
        fetchStatus: RequestStatus.Success,
        data: action.payload,
      },
    }),
    fetchZipCodeFailure: (state): SellerRegisterState => ({
      ...state,
      zipCode: {
        fetchStatus: RequestStatus.Error,
      },
    }),
    updateDefaultSellerSuccess: (
      state,
      action: PayloadAction<SellerProfiles | null>,
      // eslint-disable-next-line sonarjs/no-identical-functions
    ): SellerRegisterState => ({
      ...state,
      sellerProfiles: {
        data: action.payload,
        fetchStatus: RequestStatus.Success,
      },
    }),
    updateDefaultSellerFailure: (state): SellerRegisterState => ({
      ...state,
      sellerProfiles: {
        ...state.sellerProfiles,
        fetchStatus: RequestStatus.Error,
        error: UPDATE_DEFAULT_SELLER_ERROR,
      },
    }),
  },
});

export default SellerRegisterSlice.reducer;

export const {
  save,
  createSellerSuccess,
  createSellerFailure,
  fetchSellerFailure,
  fetchPhysicianSuccess,
  fetchPhysicianFailure,
  fetchSellerSuccess,
  fetchSellerDocumentProofSuccess,
  updatePhysicianSuccess,
  updatePhysicianFailure,
  sendDocumentsSuccess,
  sendDocumentsFailure,
  submittedSuccess,
  fetchZipCodeSuccess,
  fetchZipCodeFailure,
  chooseSeller,
  updateDefaultSellerSuccess,
  updateDefaultSellerFailure,
} = SellerRegisterSlice.actions;
