// types
export const INITIALIZE = '@webapp/addon/INITIALIZE';
export const RELOAD = '@webapp/addon/RELOAD';
export const DETAIL = '@webapp/addon/DETAIL';
export const DETAIL_OPEN = '@webapp/addon/DETAIL_OPEN';
export const DETAIL_CLOSE = '@webapp/addon/DETAIL_CLOSE';
export const INSTRUCTION_CLOSE = '@webapp/addon/INSTRUCTION_CLOSE';
export const SET_STEP = '@webapp/addon/SET_STEP';
export const SET_STEP_SUCCESS = '@webapp/addon/SET_STEP_SUCCESS';
export const FETCH_DETAIL = '@webapp/addon/FETCH_DETAIL';
export const FETCH_DETAIL_SUCCESS = '@webapp/addon/FETCH_DETAIL_SUCCESS';
export const FETCH_DETAIL_FAILURE = '@webapp/addon/FETCH_DETAIL_FAILURE';
export const FETCH_CREATE = '@webapp/addon/FETCH_CREATE';
export const FETCH_CREATE_SUCCESS = '@webapp/addon/FETCH_CREATE_SUCCESS';
export const FETCH_CREATE_FAILURE = '@webapp/addon/FETCH_CREATE_FAILURE';
export const FETCH_FEATURE_USER = '@webapp/addon/FETCH_FEATURE_USER';
export const FETCH_NOTIFICATION = '@webapp/addon/FETCH_NOTIFICATION';
export const FETCH_NOTIFICATION_SUCCESS = '@webapp/addon/FETCH_NOTIFICATION_SUCCESS';
export const FETCH_NOTIFICATION_FAILURE = '@webapp/addon/FETCH_NOTIFICATION_FAILURE';
export const CLEAR_ERRORS = '@webapp/addon/CLEAR_ERRORS';
export const CONFIRM = '@webapp/addon/CONFIRM';
