import {
  MaterialUICore,
  tokens,
  MaterialIcons,
  Box,
  Alert as DSAlert,
  Link as DSLink,
} from '@iclinic/design-system';

const { styled, TableCell: DSTableCell, Table: DSTable } = MaterialUICore;
const { Check, ClearRounded } = MaterialIcons;

const { border, color, spacing, font } = tokens;

export const TableCell = styled(DSTableCell)({
  borderBottom: `${border.width.hairline} solid ${color.neutral[50]}`,
  padding: `${spacing.sm} ${spacing.base}`,
});

export const IconCheck = styled(Check)({
  color: color.feedback.success.main,
});

export const IconClear = styled(ClearRounded)({
  color: color.feedback.error.main,
});

export const PaginationWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  padding: `${spacing.sm} 0 ${spacing.md}`,
  gridArea: 'pagination',
});

export const TableWrapper = styled(Box)({
  overflowX: 'auto',
});

export const Table = styled(DSTable)({
  minWidth: 800,
});

export const Alert = styled(DSAlert)({
  maxWidth: `calc(100% - ${spacing.xxl})`,
  margin: '0 auto',
});

export const Link = styled(DSLink)({
  textDecoration: 'none',
  fontSize: font.size.xs,
});
