import React from 'react';
import ContentLoader from 'react-content-loader';

import { MaterialUICore } from '@iclinic/design-system';
import { ResponsiveCard } from '../..';

const { useMediaQuery, useTheme } = MaterialUICore;

const values = {
  backgroundColor: '#ECEFF1',
  foregroundColor: '#F8F9FA',
  speed: 1,
};

const CardProfileLoader = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <ResponsiveCard variant="outlined">
      <ContentLoader
        speed={values.speed}
        width="100%"
        height={matches ? 441 : 500}
        backgroundColor={values.backgroundColor}
        foregroundColor={values.foregroundColor}
        uniqueKey="profile-loader"
      >
        <rect x="0" y="0" rx="2" ry="2" width="100" height="40" />
        <rect x="0" y="48" rx="2" ry="2" width="500" height="16" />
        <rect x="0" y="88" rx="2" ry="2" width="100%" height="96" />

        <rect x="0" y="208" rx="2" ry="2" width="679" height="56" />
        <rect
          x="0"
          y="288"
          rx="2"
          ry="2"
          width={matches ? '388' : '100%'}
          height="56"
        />
        {matches ? (
          <>
            <rect x="412" y="288" rx="2" ry="2" width="265" height="56" />
            <rect x="0" y="368" rx="2" ry="2" width="388" height="56" />
          </>
        ) : (
          <>
            <rect x="0" y="368" rx="2" ry="2" width="100%" height="56" />
            <rect x="0" y="448" rx="2" ry="2" width="100%" height="56" />
          </>
        )}
      </ContentLoader>
    </ResponsiveCard>
  );
};

export default CardProfileLoader;
