import { CircularProgress } from '@iclinic/design-system';
import React from 'react';

import * as S from './GenerateLinkContent.styles';

export const GenerateLinkContent = () => (
  <S.GenerateLink>
    <CircularProgress size="xl" label="Gerando link..." />
  </S.GenerateLink>
);
