import { sendRating as sendRatingService } from 'features/video-conference/services/rating';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useParticipantContext } from 'features/video-conference/state/participantContext';

type RatingProps = {
  starRating: number;
  ratingObservation: string | null;
};

export function useRating() {
  const { isPhysician, conferenceId, onError } = useConference();
  const { patientInfo, physicianInfo } = useParticipantContext();

  const sendRating = (rating: RatingProps) => {
    const { id: patientId } = patientInfo;
    const { id: physicianId } = physicianInfo;
    const sentBy = isPhysician ? 'physician' : 'patient';
    const body = {
      conferenceId,
      patientId,
      physicianId,
      score: rating.starRating,
      scoreComment: rating?.ratingObservation,
      sentBy,
      dateTime: new Date().toISOString(),
    };
    sendRatingService(body, onError);
  };

  return { sendRating };
}
