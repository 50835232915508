import { dispatchIntercomEvent } from 'shared/utils/intercom';
import { intercomEventName, promoterIntercomEventName } from '../constants';

export const dispatchIntercomIndication = () => {
  dispatchIntercomEvent({
    eventName: intercomEventName,
  });
};

export const dispatchIntercomPromoter = () => {
  dispatchIntercomEvent({
    eventName: promoterIntercomEventName,
  });
};
