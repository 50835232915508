import React from 'react';
import { useSelector } from 'react-redux';

import { MaterialIcons } from '@iclinic/design-system';
import { RequestStatus } from 'shared/constants/State';
import { FreeTextNotification } from 'features/onlinePayment/state/notifications-center/types';
import {
  getSSOToken,
  getSSOTokenStatus,
} from 'features/onlinePayment/state/iclinic-pay/selectors';
import * as S from '../styles';
import { createLink } from 'features/onlinePayment/containers/iclinic-pay/iClinicPayIntegration';

const { ArrowForward } = MaterialIcons;

type FreeTextProps = {
  notification: FreeTextNotification;
};

const FreeText = ({ notification }: FreeTextProps): JSX.Element => {
  const [ssoToken, ssoTokenStatus] = [
    useSelector(getSSOToken),
    useSelector(getSSOTokenStatus),
  ];

  const {
    id,
    data: { title, message, cta_label, cta_link, use_iclinic_pay_sso },
  } = notification;

  const hrefUrl =
    use_iclinic_pay_sso && ssoToken && cta_link
      ? createLink(ssoToken, cta_link)
      : cta_link;

  return (
    <S.NotificationWrapper pb={2} key={id}>
      <S.Title variant="xxs" bold>
        {title}
      </S.Title>
      <S.Text variant="xxs">{message}</S.Text>
      {cta_link && (
        <S.LinkButtonSecondary
          href={hrefUrl}
          target="_blank"
          variant="contained"
          color="secondary"
          size="small"
          isLoading={ssoTokenStatus === RequestStatus.Pending}
          startIcon={<ArrowForward />}
        >
          {cta_label}
        </S.LinkButtonSecondary>
      )}
    </S.NotificationWrapper>
  );
};

export default FreeText;
