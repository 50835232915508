import React, { memo, useEffect } from 'react';
import { useFormikContext } from 'formik';
import {
  Grid,
  DatePicker,
  MaterialUICore,
  MaterialIcons,
  Body,
} from '@iclinic/design-system';

import { GuideSADTForm, Session } from 'features/tissInvoicing/types';
import * as Styles from './styles';
import { getErrorText } from 'features/tissInvoicing/utils/Inputs';

const { IconButton, useTheme, useMediaQuery } = MaterialUICore;
const { Delete } = MaterialIcons;

interface SessionContainerProps {
  index: number;
  session: Session;
  removeSession: (index: number) => void;
}

function SessionContainer({
  index,
  session,
  removeSession,
}: SessionContainerProps): JSX.Element {
  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext<GuideSADTForm>();

  const theme = useTheme();
  const isNotSmall = useMediaQuery(theme.breakpoints.up('sm'));

  const handleChangeSessiondate = (date: Date | null) => {
    setFieldValue(`sessions.${index}.sessionDate`, date);
  };

  const getWeekDay = (order: number) => {
    if (values.sessions[order].sessionDate) {
      const days = [
        'Domingo',
        'Segunda-Feira',
        'Terça-Feira',
        'Quarta-Feira',
        'Quinta-Feira',
        'Sexta-Feira',
        'Sabado',
      ];
      const d = new Date(values.sessions[order].sessionDate ?? '');
      return days[d.getDay()];
    }
    return '';
  };

  useEffect(() => {});

  const weekDay = getWeekDay(index);

  return (
    <Styles.Container data-testid={`session_section_${index}`}>
      <Grid container item direction="column" spacing={2}>
        <Grid container item spacing={2} alignItems="center">
          <Grid item xs={2} sm={2} md={4} lg={4}>
            <Body variant="sm">{index + 1}</Body>
          </Grid>

          {isNotSmall && (
            <Grid item sm={3} md={3} lg={3}>
              <Body variant="sm">{weekDay}</Body>
            </Grid>
          )}

          <Grid item xs={8} sm={5} md={3} lg={3}>
            <DatePicker
              label="Data *"
              placeholder="DD/MM/AAAA"
              id={`sessions.${index}.sessionDate`}
              name={`sessions.${index}.sessionDate`}
              value={session.sessionDate}
              helperText={getErrorText(
                `sessions[${index}].sessionDate`,
                touched,
                errors,
              )}
              error={
                !!getErrorText(
                  `sessions[${index}].sessionDate`,
                  touched,
                  errors,
                )
              }
              onChange={handleChangeSessiondate}
              onBlur={handleBlur}
              allowKeyboard
              fullWidth
            />
          </Grid>

          <Styles.TrashButton item xs={2} sm={2} md={2} lg={2}>
            <IconButton onClick={() => removeSession(index)}>
              <Delete data-testid={`delete_session_${index}`} />
            </IconButton>
          </Styles.TrashButton>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}
export default memo(SessionContainer);
