// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import style from './InlineGroup.scss';

export default function InlineGroup({ children }) {
  return <div className={style.inline}>{children}</div>;
}

InlineGroup.propTypes = {
  children: PropTypes.node.isRequired,
};
