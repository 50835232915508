import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { TextField } from '@iclinic/design-system';
import { useRifm } from 'rifm';

import { SubscriptionFormData } from 'features/checkout/state/plans/types';
import {
  cnpjFormatter,
  cpfFormatter,
  creditCardNumber,
  monthYear,
  onlyNumbers,
} from 'shared/utils/formatters';
import { getFlipCard } from '../../state/plans/selectors';
import { toggleFlipCard } from '../../state/plans';
import { PaymentCard } from '../PaymentCard';
import * as S from './PaymentForm.styles';

function PaymentForm() {
  const dispatch = useDispatch();
  const isFlipped = useSelector(getFlipCard);
  const { setFieldValue, values, errors, touched, handleBlur, handleChange } =
    useFormikContext<SubscriptionFormData>();

  const { value: cardNumber, onChange: onChangeCard } = useRifm({
    value: values.cardNumber,
    onChange: (value: string) => setFieldValue('cardNumber', value),
    format: creditCardNumber,
  });

  const { value: cardExpirationDate, onChange: onChangeExpiration } = useRifm({
    value: values.cardExpirationDate,
    onChange: (value: string) => setFieldValue('cardExpirationDate', value),
    format: monthYear,
  });

  const { value: cpfCnpj, onChange: onChangeCpfCnpj } = useRifm({
    value: values.cpfCnpj,
    onChange: (value: string) => {
      setFieldValue('cpfCnpj', value);
    },

    format: (value: string) => {
      const cpfNumbers = onlyNumbers(value);
      return cpfNumbers.length <= 11
        ? cpfFormatter(value)
        : cnpjFormatter(value);
    },
  });

  const onToggleFlipCard = () => {
    dispatch(toggleFlipCard());
  };

  const onBlurCVVHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    handleBlur(event);
    onToggleFlipCard();
  };

  return (
    <S.Root>
      <S.FieldsWrapper>
        <S.TextFieldFull
          id="cardNumber"
          name="cardNumber"
          label="Número do cartão"
          placeholder="0000 0000 0000 0000"
          value={cardNumber}
          onChange={onChangeCard}
          onBlur={handleBlur}
          error={!!(errors.cardNumber && touched.cardNumber)}
          helperText={touched.cardNumber && errors.cardNumber}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]',
          }}
        />

        <S.TextFieldFull
          id="cardOwnerName"
          name="cardOwnerName"
          label="Nome do titular do cartão"
          type="text"
          value={values.cardOwnerName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!(errors.cardOwnerName && touched.cardOwnerName)}
          helperText={touched.cardOwnerName && errors.cardOwnerName}
          inputProps={{
            maxLength: 25,
          }}
        />

        <S.TextFieldDate
          id="cardExpirationDate"
          name="cardExpirationDate"
          label="Validade"
          placeholder="MM/AA"
          value={cardExpirationDate}
          onBlur={handleBlur}
          onChange={onChangeExpiration}
          error={!!(errors.cardExpirationDate && touched.cardExpirationDate)}
          helperText={touched.cardExpirationDate && errors.cardExpirationDate}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]',
          }}
        />

        <TextField
          id="cardCVV"
          name="cardCVV"
          label="CVV"
          onBlur={onBlurCVVHandler}
          value={values.cardCVV}
          onChange={handleChange}
          error={!!(errors.cardCVV && touched.cardCVV)}
          helperText={touched.cardCVV && errors.cardCVV}
          onFocus={onToggleFlipCard}
          inputProps={{
            maxLength: 4,
            inputMode: 'numeric',
            pattern: '[0-9]',
          }}
        />

        <S.TextFieldFull
          id="cpfCnpj"
          name="cpfCnpj"
          label="CPF ou CNPJ do titular do cartão"
          value={cpfCnpj}
          onBlur={handleBlur}
          onChange={onChangeCpfCnpj}
          error={!!(errors.cpfCnpj && touched.cpfCnpj)}
          helperText={touched.cpfCnpj && errors.cpfCnpj}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]',
          }}
        />
      </S.FieldsWrapper>

      <S.PaymentCardWrapper>
        <PaymentCard
          isFlipped={isFlipped}
          cardCVV={values.cardCVV}
          cardExpirationDate={cardExpirationDate}
          cardName={values.cardOwnerName}
          cardNumber={cardNumber}
        />
      </S.PaymentCardWrapper>
    </S.Root>
  );
}

export default PaymentForm;
