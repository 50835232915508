import { UserInfo } from '@types';
import { RootState } from './actions';

export const getModalVisibility = ({ modalVisibility }: RootState): boolean => modalVisibility;

export const getClinicId = (
  { userInfo }: { userInfo: UserInfo },
) => userInfo.clinicId;

export const getSubscriptionId = (
  { userInfo }: { userInfo: UserInfo },
) => userInfo.userData.subscriptionId;
