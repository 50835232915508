import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { MaterialIcons, Tooltip } from '@iclinic/design-system';

import { useConference } from 'features/video-conference/state/conferenceContext';
import { isMobile } from 'features/video-conference/utils';
import { RECORD_TOOLTIP } from 'features/video-conference/constants';
import DarkChip from 'features/video-conference/components/TeamComponents/DarkChip';
import useStyles from './styles';
import { useCaptionContext } from 'features/video-conference/state/captionContext';

const { TimerRounded } = MaterialIcons;

export default function Timer(): JSX.Element {
  const classes = useStyles();
  const { isRecording, startedRoomTime } = useConference();
  const { elapsedTime, setElapsedTime } = useCaptionContext();
  const date = moment(startedRoomTime);
  const startDateRef = useRef(date);

  useEffect((): (() => void) => {
    const intervalId = setInterval((): void => {
      setElapsedTime(moment().diff(startDateRef.current));
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [setElapsedTime]);

  const recordIcon = (
    <Tooltip title={RECORD_TOOLTIP}>
      <div className={classes.root}>
        <div className={classes.recordIcon} />
        <p
          className={classnames({
            [classes.hiddenLabel]: isMobile,
          })}
        >
          REC
        </p>
      </div>
    </Tooltip>
  );

  return (
    <DarkChip
      icon={isRecording ? recordIcon : <TimerRounded />}
      content={moment.utc(elapsedTime).format('HH:mm:ss')}
      dataTestId="timer-content"
    />
  );
}
