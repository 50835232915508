import * as Yup from 'yup';

export const required = 'Campo obrigatório';
export const validEmail = 'Digite um e-mail válido';

type Email = {
  email: string;
};

const validationSchema = Yup.object<Email>({
  email: Yup.string().email(validEmail).required(required),
});

export default validationSchema;

export const initialValues = {
  email: '',
};

export type InitialValues = typeof initialValues;
