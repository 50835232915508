import React from 'react';
import { MaterialIcons } from '@iclinic/design-system';

import { buttonsTitle } from 'features/video-conference/constants';
import ToggleButton from 'features/video-conference/components/TeamComponents/ToggleButton';
import { useChatContext } from 'features/video-conference/state/chatContext';
import { useAnnotationContext } from 'features/video-conference/state/annotationContext';
import { useConference } from 'features/video-conference/state/conferenceContext';

const { MovieFilter } = MaterialIcons;

export default function FiltersButton() {
  const { setIsChatWindowOpen } = useChatContext();
  const { setIsAnnotationWindowOpen } = useAnnotationContext();
  const { setIsBackgroundSelectionOpen, isBackgroundSelectionOpen } =
    useConference();

  const handleOnClick = () => {
    setIsBackgroundSelectionOpen(true);
    setIsChatWindowOpen(false);
    setIsAnnotationWindowOpen(false);
  };

  return (
    <ToggleButton
      onClick={handleOnClick}
      switchedOn={!isBackgroundSelectionOpen}
      variant="info"
      updateOn={false}
      title={buttonsTitle.APPLY_FILTERS}
      label={buttonsTitle.APPLY_FILTERS}
      icon={<MovieFilter />}
      aria-controls="filters"
      dataGa="telemedicine_apply_video_effects"
      dataSp="telemedicine_apply_video_effects"
    />
  );
}
