import React from 'react';

import * as S from './CustomTooltip.styles';

interface CustomTooltipProps {
  children: React.ReactNode;
  tooltipText?: string;
  active: boolean;
}

const CustomTooltip = ({
  children,
  tooltipText,
  active,
}: CustomTooltipProps) => {
  return (
    <S.Tooltip>
      {children}

      {tooltipText && (
        <S.TooltipText active={active} variant="xxs">
          {tooltipText}
        </S.TooltipText>
      )}
    </S.Tooltip>
  );
};

export default CustomTooltip;
