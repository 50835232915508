// External
import React from 'react';
import { Text } from '@iclinic/iclinic-ui';

// Internal
import { Row } from 'ui/new/flex';
import style from './Header.scss';

type HeaderProps = {
  title: string;
  children: React.ReactNode;
};

const Header = ({ title, children = null }: HeaderProps): JSX.Element => (
  <Row className={style['header-group']} data-testid="stock-header">
    <Text kind="highlight" size="lg" className={style.title}>
      {title}
    </Text>
    <Row className={style.buttons}>{children}</Row>
  </Row>
);

export default Header;
