import { all, fork } from 'redux-saga/effects';

import patientSagas from './patient/sagas';
import emailFlowReturnSagas from './emailFlowManagement/sagas';
import professionalSagas from './professional/sagas';
import rootSagas from './root/sagas';

export default function* emailFlowSagas() {
  yield all([
    fork(rootSagas),
    fork(patientSagas),
    fork(professionalSagas),
    fork(emailFlowReturnSagas),
  ]);
}
