import { Buffer } from 'buffer';

import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef } from 'react';
import { MaterialIcons } from '@iclinic/design-system';

import { CalculatorDrawerHeader } from '../../CalculatorDrawer.styles';
import { actions } from '../../state';
import { getIsCalculatorSelected } from '../../state/selectors';
import { IframeCalculator } from './CalculatorDrawerSelected.styles';

const { ArrowBack } = MaterialIcons;

const { closeCalculatorDrawer, setListView } = actions;

export const CalculatorDrawerSelected = () => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const dispatch = useDispatch();
  const calculatorViewSelected = useSelector(getIsCalculatorSelected);

  useEffect(() => {
    const requestPartnerToken = ({ data }: { data: { action: string } }) => {
      if (data.action === 'request-partner-token') {
        const token = Buffer.from(
          `${process.env.ICLINIC_WHITEBOOK_USERNAME!}:${process.env
            .ICLINIC_WHITEBOOK_PARTNER_TOKEN!}`,
        ).toString('base64');
        iframeRef.current?.contentWindow?.postMessage(
          {
            action: 'set-partner-token',
            value: token,
          },
          '*',
        );
      }
    };

    window.addEventListener('message', requestPartnerToken);
    return () => {
      window.removeEventListener('message', requestPartnerToken);
    };
  }, []);

  const onDrawerClose = () => {
    dispatch(closeCalculatorDrawer());
  };

  const onNavBack = () => {
    dispatch(setListView());
  };

  return (
    <>
      <CalculatorDrawerHeader
        title={calculatorViewSelected?.titulo || ''}
        onClose={onDrawerClose}
        leadElement={<ArrowBack />}
        onClickLeadElement={onNavBack}
      />
      <IframeCalculator
        src={calculatorViewSelected?.url}
        title={calculatorViewSelected?.titulo}
        ref={iframeRef}
      />
    </>
  );
};
