/* eslint-disable @typescript-eslint/no-use-before-define */
import mergeWith from 'lodash.mergewith';
import isEqual from 'lodash.isequal';
import isPlainObject from 'lodash.isplainobject';

// Array, Set and others which are classes, not simple `{...}`
const isComplexObject = (obj) => obj && !isPlainObject(obj);

const isSameShallowObject = (a, b) => {
  if (a === b) {
    return true;
  }
  if (!a || !b) {
    return false;
  }

  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);

  if (aKeys.length !== bKeys.length) {
    return false;
  }

  const keyChanged = (key) => {
    const aValue = a[key];
    const bValue = b[key];
    return aValue !== bValue;
  };

  if (aKeys.some(keyChanged)) {
    return false;
  }

  return true;
};

export const reducerMerge = (mergeObject = {}, state, payload) => {
  const result = mergeWith(mergeObject, state, payload, customizer);
  if (isSameShallowObject(result, state)) {
    return state;
  }
  return result;
};

export const customizer = (objValue, srcValue) => {
  if (typeof objValue !== typeof srcValue
    || isComplexObject(objValue)
    || isComplexObject(srcValue)
  ) {
    return srcValue;
  }

  if (!srcValue) {
    return srcValue;
  }

  if (isEqual(objValue, srcValue)) {
    return objValue;
  }

  // should be recursive to keep it stable
  // eslint-disable-next-line no-use-before-define
  const merged = reducerMerge({}, objValue, srcValue);
  if (isSameShallowObject(merged, srcValue)) {
    // if it's  the same as the payload (payload is super set), do not copy
    return srcValue;
  }

  return merged;
};

export const reduceObjectNumbers = object => object.reduce(
  (acc, current) => [...acc, current.id], [],
);
