import React from 'react';

import { MaterialIcons, MaterialUICore } from '@iclinic/design-system';
import * as S from './Question.styles';

const { NavigateNext } = MaterialIcons;
const { Collapse, ListItem, ListItemText } = MaterialUICore;

interface QuestionProps {
  name: string;
  question: string;
  answer: string;
  expanded: boolean;
  onClick: (name: string) => void;
}

const Question = ({
  name,
  question,
  answer,
  expanded,
  onClick,
}: QuestionProps) => {
  const handleQuestionClick = () => {
    onClick(name);
  };

  return (
    <S.Root expanded={expanded}>
      <ListItem button disableRipple onClick={handleQuestionClick}>
        <ListItemText>
          <S.QuestionText variant="sm" bold>
            {question}
          </S.QuestionText>
        </ListItemText>

        <S.IconWrapper>
          <NavigateNext />
        </S.IconWrapper>
      </ListItem>

      <Collapse in={expanded}>
        <S.AnswerText variant="xs">{answer}</S.AnswerText>
      </Collapse>
    </S.Root>
  );
};

export default Question;
