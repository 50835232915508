import {
  SellerStatus,
  SellerType,
} from 'features/onlinePayment/state/constants';

export type PhysicianPicture = {
  'content-type': string;
  name: string;
  file: string;
};

export type PhysicianPatch = {
  cbo?: number;
  council_name?: string;
  council_number?: string;
  council_region?: string;
  cpf?: string;
  office_phone?: string;
  picture?: PhysicianPicture;
};

export interface PhysicianRawData {
  cbo?: number;
  council_name?: string;
  council_number?: string;
  council_region?: string;
  id: number;
  profile: {
    id: number;
    email: string;
    name: string;
    thumb?: string;
    cpf?: string;
    office_phone?: string;
  };
}

export type FetchSellerRawData = {
  results: SellerRawData[];
};

export interface Address {
  zip_code: string;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  complement?: string;
}

interface BaseSellerIndividual {
  name: string;
  cpf: string;
  birthdate: string;
}

interface BaseSellerBusiness {
  business_name: string;
  business_description: string;
  business_opening_date: string | Date;
  business_cnpj: string;
}

interface BaseSeller
  extends Partial<BaseSellerBusiness>,
    Partial<BaseSellerIndividual> {
  type: SellerType;
  email: string;
  phone: string;
  physician_id: number;
  address: Address;
  cbo_term: string;
  council_name: string;
  council_number: string;
  council_region: string;
}

export interface SellerRequestBody extends BaseSeller {}

export interface SellerRawData extends BaseSeller {
  id: string;
  external_id?: string;
  max_installments: number;
  min_installment_value: string;
  status?: SellerStatus;
  bank_account?: {
    id: string;
    external_id: string;
  };
  is_default: boolean;
  financial_services_id?: string;
}

export type SendDocumentRequestBody = {
  identity: string;
  residence: string;
  cnpj?: string;
};

export type UploadDocumentRawData = {
  key: string;
  url: string;
};

export interface BanksRawData {
  code: string;
  name: string;
  img?: string;
}

export type SellerBankAccountRequestBody = {
  seller: string;
  bank_code: string;
  routing_number: string;
  account_number: string;
};

export type SellerBankAccountRawData = {
  id: string;
  routing_number: string;
  account_number: string;
  external_id: string | null;
  created_at: string;
  updated_at: string;
  bank_code: string;
  kind: string;
  is_default: boolean;
  seller: string;
};

export type InstallmentsRequestBody = {
  max_installments: number;
  min_installment_value: string;
};

export type EmailSettingPaymentConfirmation = {
  emails: string[];
  enabled: boolean;
};

export type EmailSettingWeeklySummary = {
  emails: string[];
  enabled: boolean;
};

export type EmailsSettingsRawData = {
  payment_confirmation: EmailSettingPaymentConfirmation | null;
  weekly_summary: EmailSettingWeeklySummary | null;
};

export enum DocumentStatus {
  UPLOADED = 'uploaded',
  PENDING = 'pending',
  PENDING_EXTERNAL_ID = 'pending_external_id',
  REVIEWING = 'reviewing',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  ACTION_REQUIRED = 'action_required',
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
  DELETED = 'deleted',
}

export enum DocumentCategory {
  IDENTITY = 'identity',
  ACTIVITY = 'activity',
  RESIDENCE = 'residence',
  CNPJ = 'cnpj',
}

export type DocumentProofRawData = {
  id: string;
  seller: string;
  file_key: string;
  file_category: DocumentCategory;
  status: DocumentStatus;
  financial_services_id: string;
  external_id: string;
  created_at: string;
  updated_at: string;
};

export type UpdateDefaultSellerData = {
  id: string;
  type: SellerType;
  physician_id: string;
  is_default: boolean;
};

export type SendSellerHubspot = {
  message: string;
};
