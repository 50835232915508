import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const { spacing } = tokens;

const translateUp = 'translateY(-16px)';
const translate0 = 'translateY(0)';

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    padding: '16px',
    bottom: '0',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down(320)]: {
      display: 'none',
    },
  },
  largeHeight: {
    height: '100%',
  },
  largeDiv: {
    width: '168px',
  },
  smallDiv: {
    width: '56px',
  },
  buttonsContainer: {
    '& .MuiBox-root > *': {
      margin: `${spacing.base} ${spacing.nano}`,
    },
  },
  iframeHidden: {
    marginTop: '5em',
    [theme.breakpoints.down(320)]: {
      display: 'none',
    },
  },
  mobileToolbar: {
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-end',
  },
  mainTools: {
    '& > *': {
      margin: `0 ${spacing.base}`,
    },
    width: '100%',
    justifyContent: 'center',
    display: 'flex',
  },
  secondaryTools: {
    display: 'none',
    '& > *': {
      margin: `0 ${spacing.base}`,
    },
    width: '100%',
    justifyContent: 'center',
  },
  drawerOpen: {
    display: 'flex',
    webkitAnimation:
      '$slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
    animation: '$slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both',
  },
  '@webkit-keyframes slide-top': {
    '0%': {
      webkitTransform: translate0,
      transform: translate0,
    },
    '100%': {
      webkitTransform: translateUp,
      transform: translateUp,
    },
  },
  '@keyframes slide-top': {
    '0%': {
      webkitTransform: translate0,
      transform: translate0,
    },
    '100%': {
      webkitTransform: translateUp,
      transform: translateUp,
    },
  },
}));
