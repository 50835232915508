import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  btnGenerateBill: {
    '& .MuiButton-startIcon': {
      marginRight: theme.spacing(0.5),
    },
  },
}));
