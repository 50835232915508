import { Physician } from 'features/exportClinicData/state/exportList/types';

export const joinPhysiciansNames = (physicians: Physician[]): string => {
  const visibleNamesLimit = 2;

  const physiciansNames = physicians.map(
    (physician: Physician) => physician.name,
  );
  const visibleNames = physiciansNames.slice(0, visibleNamesLimit);
  const hiddenNamesCount =
    physiciansNames.length > visibleNamesLimit
      ? `, + ${physiciansNames.length - visibleNamesLimit}`
      : '';

  return visibleNames.join(', ') + hiddenNamesCount;
};
