import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = tokens;

export const Root = styled('div')(({ theme }) => ({
  gridArea: 'actions',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  rowGap: spacing.sm,

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItem: 'center',
    justifyContent: 'space-between',
  },

  '& button': {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
}));
