import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, MaterialIcons as Icons } from '@iclinic/design-system';
import { captureException } from '@sentry/browser';

import {
  Container,
  StyledDrawer,
  Header,
  Description,
  Wrapper,
} from './SpeechToTextContainer.styles';
import SpeechArea from '../components/SpeechArea';
import ResumeArea from '../components/ResumeArea';
import {
  getAnamnesisResume,
  postTranscriptionToResume,
} from 'features/records/services';
import {
  getIsOpen,
  getPatientId,
  getTranscript,
  getLastRequestId,
} from '../state/selector';
import { actions } from '../state';

const { updateResumeMarkdown, triggerIsOpen, updateLastRequestId } = actions;

const SpeechToTextContainer = () => {
  const dispatch = useDispatch();
  const patientId = useSelector(getPatientId);
  const pollingRef = useRef<NodeJS.Timeout | null>(null);
  const transcript = useSelector(getTranscript);
  const lastRequestId = useSelector(getLastRequestId);
  const isOpen = useSelector(getIsOpen);
  const [isLoading, setIsLoading] = useState(false);
  const transcriptIsEmpty = transcript.length === 0;

  const startPolling = () => {
    pollingRef.current = setInterval(() => {
      const poll = async () => {
        try {
          const response = await getAnamnesisResume(patientId);
          if (
            response.data.status &&
            response.data.request_id !== lastRequestId
          ) {
            dispatch(updateResumeMarkdown(response.data.response));
            dispatch(updateLastRequestId(response.data.request_id));
            setIsLoading(false);
            clearInterval(pollingRef.current as NodeJS.Timeout);
          }
        } catch (error) {
          setIsLoading(false);
          clearInterval(pollingRef.current as NodeJS.Timeout);
          captureException(error);
        }
      };

      poll().catch((error) => {
        setIsLoading(false);
        clearInterval(pollingRef.current as NodeJS.Timeout);
        captureException(error);
      });
    }, 1000);
  };

  const handleResume = async () => {
    try {
      setIsLoading(true);
      await postTranscriptionToResume(patientId, transcript);
      startPolling();
    } catch (error) {
      setIsLoading(false);
      captureException(error);
    }
  };

  useEffect(
    () => () => {
      if (pollingRef.current) {
        clearInterval(pollingRef.current);
      }
    },
    [],
  );

  return (
    <Container>
      <StyledDrawer
        anchor="right"
        disableEnforceFocus
        disableAutoFocus
        disablePortal
        hideBackdrop
        disableScrollLock
        open={isOpen}
      >
        <Header>
          <h2>Anamnese</h2>
          <IconButton
            label="Fechar"
            tooltip="none"
            onClick={() => dispatch(triggerIsOpen())}
          >
            <Icons.Close />
          </IconButton>
        </Header>
        <Description>Serviço de transcrição e resumo da anamnese.</Description>
        <Wrapper>
          <SpeechArea />
          <ResumeArea
            isLoading={isLoading}
            onClickResume={handleResume}
            isDisabled={isLoading || transcriptIsEmpty}
          />
        </Wrapper>
      </StyledDrawer>
    </Container>
  );
};

export default SpeechToTextContainer;
