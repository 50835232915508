import React from 'react';
import ContentLoader from 'react-content-loader';

const values = {
  backgroundColor: '#ECEFF1',
  foregroundColor: '#F8F9FA',
  speed: 1,
};

const MyIclinicPayLoader = (): JSX.Element => {
  return (
    <>
      <ContentLoader
        speed={values.speed}
        width="100%"
        height={614}
        backgroundColor={values.backgroundColor}
        foregroundColor={values.foregroundColor}
        data-testid="payment-type-options-content-loader"
        uniqueKey="payment-type-options"
      >
        <rect x="0" y="0" rx="3" ry="3" width="298" height="38" />
        <rect x="0" y="56" rx="3" ry="3" width="510" height="45" />

        <rect x="1" y="119" rx="0" ry="2" width="100%" height="153" />
        <rect x="1" y="284" rx="0" ry="2" width="100%" height="153" />
        <rect x="1" y="449" rx="0" ry="2" width="100%" height="153" />
      </ContentLoader>
    </>
  );
};

export default MyIclinicPayLoader;
