import { StoreState } from 'state/rootReducer';

export const getItems = ({ reports }: StoreState) =>
  reports.telemedicineReport.items;

export const getPhysicians = ({ reports }: StoreState) => [
  { name: 'Todos' },
  ...reports.telemedicineReport.physicians.items,
];

export const getIsFetchingPhysicians = ({ reports }: StoreState) =>
  reports.telemedicineReport.physicians.isFetching;

export const getClinicId = ({ userInfo }: StoreState) => userInfo.clinicId;

export const getUserSubscriptionPlan = ({ userInfo }: StoreState) =>
  userInfo.userData.subscriptionPlan;

export const getDateFilterType = ({ reports }: StoreState) =>
  reports.telemedicineReport.dateType;

export const getFilter = ({ reports }: StoreState) =>
  reports.telemedicineReport.filter;

export const getPageCount = ({ reports }: StoreState) =>
  reports.telemedicineReport.pageCount;

export const getPageNumber = ({ reports }: StoreState) =>
  reports.telemedicineReport.filter.pageNumber;

export const getStatus = ({ reports }: StoreState) =>
  reports.telemedicineReport.status;
