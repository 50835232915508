// External
import { connect } from 'react-redux';

// Internal
import { close } from 'state/modal/actions';
import ModalRoot from '../components/ModalRoot';

const mapStateToProps = (state) => state.modal;

export default connect(mapStateToProps, { close })(ModalRoot);
