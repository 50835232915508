import {
  MaterialUICore,
  DialogContent as DialogContentDS,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const DialogContent = styled(DialogContentDS)({
  width: '600px',
  minHeight: '400px',
});

export const ListSection = styled('div')({
  margin: '0px 20px 20px 20px',
});
