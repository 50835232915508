import React from 'react';
import {
  MaterialUICore,
  MaterialIcons,
  Heading,
  Body,
  Dialog,
  Box,
  IconButton,
} from '@iclinic/design-system';

import useStyles from './styles';

const { DialogContent, DialogTitle } = MaterialUICore;
const { Close } = MaterialIcons;

const shortcutsList = [
  {
    label: 'Criar coluna',
    shortcut: 'CTRL + SHIFT + ENTER',
  },
  {
    label: 'Criar linha',
    shortcut: 'CTRL + ENTER',
  },
  {
    label: 'Navegação entre linhas',
    shortcut: 'SETA PARA CIMA/BAIXO',
  },
  {
    label: 'Avançar célula',
    shortcut: 'TAB',
  },
  {
    label: 'Voltar célula',
    shortcut: 'SHIFT + TAB',
  },
  {
    label: 'Ir para linha de baixo',
    shortcut: 'ENTER',
  },
];

type ShortcutModalProps = {
  open: boolean;
  onClose: () => void;
};

const ShortcutModal = ({ open, onClose }: ShortcutModalProps) => {
  const { title, content, line } = useStyles();

  const getShortcuts = () => shortcutsList.map(({ label, shortcut }) => (
      <Box key={label} className={line}>
        <Body variant="xs">{label}</Body>
        <Heading variant="overline">{shortcut}</Heading>
      </Box>
    ));

  return (
    <Dialog
      aria-labelledby="integration-modal-title"
      aria-describedby="integration-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <DialogTitle disableTypography className={title}>
        <Heading variant="xs">Conheça os atalhos disponíveis</Heading>
        <IconButton onClick={onClose} label="Fechar" tooltip="bottom" size="md">
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={content}>{getShortcuts()}</DialogContent>
    </Dialog>
  );
};

export default ShortcutModal;
