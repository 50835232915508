import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { Box, Heading, Body, TextArea, Button } from '@iclinic/design-system';

import { useConference } from 'features/video-conference/state/conferenceContext';
import {
  useWindowHeight,
  setTelemedicineStatus,
} from 'features/video-conference/utils';
import { useRating } from 'features/video-conference/hooks/useRating';
import { image } from 'shared/utils/urlGetter';
import {
  callEnded,
  review,
  buttonsTitle,
  THANK_YOU,
  servicesLabels,
} from 'features/video-conference/constants';
import RatingStars from './ratingStars';
import useStyles from '../styles';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';
import { useParticipantContext } from 'features/video-conference/state/participantContext';

export default function Rating(): JSX.Element {
  const { isPhysician, conferenceId, onError } = useConference();
  const { sendRating } = useRating();
  const { localParticipant, remoteParticipant } = useParticipantContext();
  const [reviewSent, setReviewSent] = useState(false);
  const [starRating, setStarRating] = useState(0);
  const [ratingObservation, setRatignObservation] = useState('');
  const height = useWindowHeight();
  const classes = useStyles({ height });
  let featureTrackName = 'telemedicine_rating_star';

  const dispatchEventTracking = () => {
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: featureTrackName,
    });
  };

  const handleSendRating = (star: number, shouldSetReviewSent: boolean) => {
    setStarRating(star);
    try {
      sendRating({ starRating: star, ratingObservation });
      if (shouldSetReviewSent) {
        setReviewSent(true);
        featureTrackName = 'telemedicine_send_review';
      }
      dispatchEventTracking();
    } catch (error: any) {
      onError(error);
    }
  };

  const inputLabel =
    starRating === 5
      ? review.whatIsYourFavoritePart
      : review.whatCanWeDoToImprove;

  useEffect(() => {
    global.localStorage.removeItem(servicesLabels.CONFERENCE_TOKEN);
  });

  return (
    <Box className={classnames(classes.room, classes.iframeHidden)}>
      <Heading variant="xl" className={classes.feedbackTitle}>
        {reviewSent ? THANK_YOU : callEnded.title}
      </Heading>
      <Box className={classes.reviewContainer}>
        <Heading variant="md" className={classes.feedbackTitle}>
          {reviewSent
            ? review.yourReviewHasBeenSuccessfullySent
            : review.howWasYourExperience}
        </Heading>
        <Body variant="xs" className={classes.feedbackSubtitle}>
          {review.yourOpinionHelpToImproveOurTool}
        </Body>
        {reviewSent ? (
          <Box
            mt={5}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img src={image(review.imagePath)} alt={review.altText} />
          </Box>
        ) : (
          <>
            <RatingStars
              handlerSendRating={handleSendRating}
              starRating={starRating}
            />
            {starRating !== 0 && (
              <>
                <TextArea
                  onChange={(e) => setRatignObservation(e.currentTarget.value)}
                  value={ratingObservation}
                  label={inputLabel}
                  placeholder={review.writeHere}
                  fullWidth
                />
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={2}
                  width="100%"
                >
                  <Button onClick={() => handleSendRating(starRating, true)}>
                    {buttonsTitle.SEND}
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      {isPhysician && (
        <Box mt={4}>
          <Button
            onClick={() => setTelemedicineStatus('closed')}
            variant="contained"
            color="secondary"
          >
            {review.backToMedicalRecord}
          </Button>
        </Box>
      )}
    </Box>
  );
}
