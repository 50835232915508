import { MessageFreeSamplesResult } from '../state/types';

export const SUCCESS: MessageFreeSamplesResult = {
  title: 'Sua solicitação foi enviada',
  content:
    'Em breve sua box com as amostras chegará no endereço indicado por você.',
  severity: 'success',
};

export const ALREADY_REQUESTED: MessageFreeSamplesResult = {
  title: 'Espere mais um pouco',
  content:
    'Recentemente você fez a solicitação desta box de amostras. Verifique a aba de solicitadas',
  severity: 'warning',
};

export const MISSED_SPECIALTY: MessageFreeSamplesResult = {
  title: 'Especialidade não atendida',
  content:
    'Infelizmente a solicitação de amostras ainda não está disponível para a sua especialidade.',
  severity: 'warning',
};

export const UNKNOWN_ERROR: MessageFreeSamplesResult = {
  title: 'Algo não está certo',
  content: 'Por favor, contate o suporte ou tente novamente mais tarde.',
  severity: 'error',
};

export const ERROR_WAITLIST: MessageFreeSamplesResult = {
  title: 'Região não atendida',
  content:
    'Infelizmente nossa distribuição não atende a sua região, continue o preenchimento e entre para lista de espera.',
  severity: 'warning',
};

export const SUCCESS_WAITING_AVAILABILITY: MessageFreeSamplesResult = {
  title: '',
  content:
    'Registramos seu contato e avisaremos quando houverem novas amostras disponíveis',
  severity: 'success',
};

export const SUCCESS_WAITING_LOGISTICS: MessageFreeSamplesResult = {
  title: '',
  content: 'Registramos seu contato e avisaremos quando ampliarmos nossa rede',
  severity: 'success',
};

export const DEFAULT_ERROR_CODE = 'UnknownError';

export const MESSAGES_BY_CODE: Record<string, MessageFreeSamplesResult> = {
  [DEFAULT_ERROR_CODE]: UNKNOWN_ERROR,
  401901: UNKNOWN_ERROR,
  400902: ALREADY_REQUESTED,
  401903: MISSED_SPECIALTY,
  401904: ALREADY_REQUESTED,
  400905: SUCCESS_WAITING_LOGISTICS,
};
