import React from 'react';
import { useSelector } from 'react-redux';

import { Body, Container } from '@iclinic/design-system';
import { image } from 'shared/utils/urlGetter';
import useStyles from './Header.styles';
import { getClinic } from 'features/onlinePayment/state/checkout/infos/selectors';

export default function Header(): JSX.Element {
  const classes = useStyles();

  const [clinic] = [useSelector(getClinic)];

  return (
    <header data-testid="checkout-header" className={classes.header}>
      <Container maxWidth="lg" className={classes.container}>
        <div className={classes.body}>
          <img
            src={image('onlinePayment.logoCheckout')}
            alt="iClinic Pay"
            title="iClinic Pay"
          />
          {clinic?.name && (
            <div className={classes.clinicHeader}>
              <span className={classes.divider}>|</span>
              <Body variant="xs" bold inverseTextColor>
                {clinic.name}
              </Body>
            </div>
          )}
        </div>
      </Container>
    </header>
  );
}
