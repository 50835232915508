import { PromiseResponse } from '@types';

import {
  CreateLotPayloadRequest,
  CreateLotResponse,
  EditLotPayloadRequest,
  FetchGuidesRequestParams,
  FetchGuidesResponse,
  FecthAvailableSimplifiedGuidesResponse,
  FetchXMLFilter,
  GenericObjectDescription,
  LegacyFetchPatientsResponse,
  Lot,
  NewLotConfigDataResponse,
  FetchLotListRequestParams,
  FetchLotListResponse,
  GuideTypeOptions,
} from 'features/tissInvoicing/types';
import { apiTissInvoicing, apiLegacy } from '..';

export const fetchLotListData = ({
  page = 1,
  insurance_id,
  start,
  end,
  professional_id,
  is_closed = true,
}: FetchLotListRequestParams): PromiseResponse<FetchLotListResponse> =>
  apiTissInvoicing.get(`/lot`, {
    params: {
      page,
      insurance_id,
      start,
      end,
      professional_id,
      is_closed,
    },
  });

export const fetchLotData = (id: string): PromiseResponse<Lot> =>
  apiTissInvoicing.get(`/lot/${id}`);

export const fetchGuides = ({
  lot_id,
  lot__isnull,
  insurance_id,
  start,
  end,
  patient_id,
  physician_id,
  page = 1,
  ordering,
  guide_type,
}: FetchGuidesRequestParams): Promise<FetchGuidesResponse> =>
  apiTissInvoicing.get(`/guide`, {
    params: {
      simple: true,
      insurance_id,
      lot_id,
      lot_id_header: lot_id,
      lot__isnull,
      start,
      end,
      patient_id,
      physician_id,
      page,
      ordering,
      guide_type,
    },
  });

export function fetchAvailableSimplifiedGuides(
  id: string,
  insurance_id: number,
  guide_type?: GuideTypeOptions,
): Promise<FecthAvailableSimplifiedGuidesResponse> {
  return apiTissInvoicing.get('/guide/values', {
    params: {
      lot_id_header: id,
      insurance_id,
      guide_type,
    },
  });
}

export function fetchLotConfigOptions(): PromiseResponse<NewLotConfigDataResponse> {
  return apiTissInvoicing.get('/lot/new/');
}

export function fetchGuideFilterPatientOptions(
  query: string,
): PromiseResponse<LegacyFetchPatientsResponse> {
  return apiLegacy.get(`pacientes/busca.json?limit=20&q=${query}`);
}

export function fetchGuideFilterPhysicianOptions(): PromiseResponse<
  GenericObjectDescription[]
> {
  return apiTissInvoicing.get('/mirror/physician/');
}

export function fetchLotesFilterInsuranceOptions(): PromiseResponse<
  GenericObjectDescription[]
> {
  return apiTissInvoicing.get('/mirror/clinic-insurance/');
}

export function createLot(
  lot: CreateLotPayloadRequest,
): PromiseResponse<CreateLotResponse> {
  return apiTissInvoicing.post('/lot/', lot);
}

export const patchLotData = ({
  id,
  guides,
  is_closed,
  executant_type,
  executant_name,
  executant_code,
  executant_code_type,
  executant_cnes,
  version,
}: EditLotPayloadRequest): PromiseResponse<Lot> =>
  apiTissInvoicing.patch(`/lot/${id}/`, {
    guides,
    is_closed,
    executant_type,
    executant_name,
    executant_cnes,
    executant_code,
    executant_code_type,
    version,
  });

export const fetchXMLFile = (
  id: string,
  orderBy: FetchXMLFilter,
): PromiseResponse<BlobPart> =>
  apiTissInvoicing.get(`/lot/${id}/xml`, {
    params: { ordering: orderBy },
  });
