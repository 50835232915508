import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Response } from '@types';

import history from 'routes/history';
import { RequestStatus } from 'shared/constants/State';
import * as actions from '.';
import * as api from '../../services/common';
import {
  Feature,
  FeaturesResponse,
  PermissionsMenu,
  Physician,
} from '../../services/types';
import { MenuSlug } from '../types';
import { getFeature, isUserAdmin, isUserClinicAdmin } from './selectors';

type PayloadFetchPhysicians = ReturnType<typeof actions.fetchPhysicians>;
type PayloadFetchMenuPermissions = ReturnType<
  typeof actions.fetchMenuPermissions
>;
type PayloadUpdateReadAlert = ReturnType<typeof actions.updateReadAlert>;

export function* fetchPhysiciansWorker({ payload }: PayloadFetchPhysicians) {
  try {
    yield put(actions.fetchPhysiciansStart());

    const { data: physicianArray } = yield call(api.getPhysicians, payload);

    yield put(actions.fetchPhysiciansSuccess(physicianArray));
  } catch {
    yield put(actions.fetchPhysiciansFailure());
  }
}

export function* fetchMenuPermissionsAndRedirectWorker({
  payload: noRedirect,
}: PayloadFetchMenuPermissions) {
  try {
    yield put(actions.fetchMenuPermissionsStart());

    const isAdmin: boolean = yield select(isUserAdmin);
    const isClinicAdmin: boolean = yield select(isUserClinicAdmin);

    const { data: permissionsMenu }: Response<PermissionsMenu> = yield call(
      api.getMenuPermissions,
    );

    const { confirmation_event, event_reminder, message_package } =
      permissionsMenu;

    const showMessagesMenu =
      confirmation_event.customization ||
      confirmation_event.history ||
      event_reminder.customization ||
      event_reminder.history;

    const availableMenus = [
      ...(showMessagesMenu ? [MenuSlug.MESSAGES] : []),
      ...(confirmation_event.customization || confirmation_event.history
        ? [MenuSlug.CONFIRMATION]
        : []),
      ...(event_reminder.customization || event_reminder.history
        ? [MenuSlug.REMINDER]
        : []),
      ...(message_package ? [MenuSlug.PACKAGE] : []),
    ];

    yield put(
      actions.fetchMenuPermissionsSuccess({
        availableMenus,
        permissionsMenu,
      }),
    );

    const routesByMenu: Record<string, string> = {
      [MenuSlug.CONFIRMATION]: '/relacionamento/confirmacao-de-agenda',
      [MenuSlug.REMINDER]: '/relacionamento/lembrete-de-agenda',
      [MenuSlug.PACKAGE]: '/relacionamento/pacote-de-mensagem/detalhes',
    };

    let redirectTo = '/404';
    let hasSubscribers = false;

    if (isAdmin || isClinicAdmin) {
      const { data: subscriberPhysicians }: Response<Physician[]> = yield call(
        api.getPhysicians,
        {
          returnAll: true,
        },
      );

      hasSubscribers = !!subscriberPhysicians.length;
    }

    if (hasSubscribers) {
      redirectTo = routesByMenu[MenuSlug.PACKAGE];
    } else if (isAdmin) {
      redirectTo = '/relacionamento/pacote-de-mensagem';
    } else if (showMessagesMenu) {
      const secondMenuOption = availableMenus[1];
      redirectTo = routesByMenu[secondMenuOption];
    }

    if (!noRedirect) {
      history.push(redirectTo);
    }
  } catch {
    yield put(actions.fetchMenuPermissionsFailure());
  }
}

export function* fetchVisitedMessagingFeatureWorker() {
  try {
    const { data }: Response<FeaturesResponse> = yield call(
      api.getVisitedMessagingFeature,
    );

    const [feature] = data.objects;

    yield put(actions.updateVisitedMessagingFeature(feature));
  } catch {
    yield put(actions.updateVisitedMessagingFeature());
  }
}

export function* submitVisitedMessagingFeatureWorker() {
  try {
    const feature: Feature = yield select(getFeature);

    yield call(api.postVisitedMessagingFeature, feature.id);

    yield put(
      actions.updateVisitedMessagingFeature({
        ...feature,
        state: true,
      }),
    );
  } catch {
    yield put(actions.updateVisitedMessagingFeature());
  }
}

export function* updateReadAlertWorker({ payload }: PayloadUpdateReadAlert) {
  try {
    yield put(actions.updateReadAlertFetcherStatus(RequestStatus.Pending));

    yield call(api.postReadAlertTemplateUpdate, payload);

    yield put(actions.updateReadAlertFetcherStatus(RequestStatus.Success));
  } catch {
    yield put(actions.updateReadAlertFetcherStatus(RequestStatus.Error));
  }
}

export default function* messagingCommonSagas() {
  yield takeLatest(actions.fetchPhysicians, fetchPhysiciansWorker);
  yield takeLatest(
    actions.fetchMenuPermissions,
    fetchMenuPermissionsAndRedirectWorker,
  );
  yield takeLatest(
    actions.fetchVisitedMessagingFeature,
    fetchVisitedMessagingFeatureWorker,
  );
  yield takeLatest(
    actions.submitVisitedMessagingFeature,
    submitVisitedMessagingFeatureWorker,
  );
  yield takeLatest(actions.updateReadAlert, updateReadAlertWorker);
}
