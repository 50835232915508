import React, { memo } from 'react';

import { image } from 'shared/utils/urlGetter';
import {
  formatDate,
  formatTime,
} from 'features/tissInvoicing/utils/formatDateTime';
import { formatCurrency } from 'features/tissInvoicing/utils/formatCurrency';
import { Guide } from 'features/tissInvoicing/types';
import { Body, MaterialUICore } from '@iclinic/design-system';
import * as Styles from './styles';

const { TableCell, IconButton } = MaterialUICore;

interface RowsProps {
  rowData: Guide;
  isLotClosed: boolean;
}

const Rows = ({ rowData, isLotClosed }: RowsProps) => {
  const classes = Styles.useStyles();

  return (
    <>
      <TableCell>
        <Body variant="sm">{rowData.guide_number}</Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{formatDate(rowData.date)}</Body>
        <Body variant="sm" classes={{ root: classes.neutral }}>
          {formatTime(rowData.date)}
        </Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{rowData.patient.name}</Body>
        <Body variant="sm" classes={{ root: classes.neutral }}>
          {rowData.patient.id}
        </Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{rowData.physician.name}</Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{rowData.quantity}</Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{formatCurrency(rowData.total_amount)}</Body>
      </TableCell>
      {!isLotClosed && (
        <TableCell align="center" size="small">
          <IconButton
            href={`/faturamento-tiss/sadt/${rowData?.event_id}`}
            classes={{ root: classes.editButton }}
          >
            <img alt="Editar Guia" src={image('tissInvoicing.editNote')} />
          </IconButton>
        </TableCell>
      )}
    </>
  );
};

export default memo(Rows);
