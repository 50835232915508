import React from 'react';

import { translateWeekDay } from 'shared/utils/settingsOnlineSchedule';
import { Grid } from '@iclinic/design-system';
import useStyles from './styles';

export type WeekDay = {
  start_time: string;
  end_time: string;
};

type WorkHoursProps = {
  weekDay: WeekDay | string | boolean;
  renderStartTime: React.ReactNode;
  renderEndTime: React.ReactNode;
  renderAside: React.ReactNode;
  renderAsideAdd: React.ReactNode;
  checkDay: React.ReactNode;
};

const WorkHours = ({
  checkDay,
  weekDay,
  renderStartTime,
  renderEndTime,
  renderAside,
  renderAsideAdd,
}: WorkHoursProps) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item className={classes.checkDay}>
        {checkDay}
      </Grid>
      <Grid item className={classes.weekDay}>
        {translateWeekDay(weekDay)}
      </Grid>
      <Grid item className={classes.flexCenter}>
        {renderStartTime}
      </Grid>
      <Grid item className={classes.flexCenter}>
        às
      </Grid>
      <Grid item className={classes.flexCenter}>
        {renderEndTime}
      </Grid>
      <Grid item className={classes.flexCenter}>
        {renderAside}
      </Grid>
      <Grid item className={classes.flexCenter}>
        {renderAsideAdd}
      </Grid>
    </Grid>
  );
};

export default WorkHours;
