export const CLOSE = 'CLOSE';
export const OPEN = 'OPEN';
export const SUBMIT = 'SUBMIT';
export const REQUEST = 'REQUEST';
export const PENDING = 'PENDING';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const CHANGE = 'CHANGE';
export const ON = 'ON';
export const OFF = 'OFF';
