import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  MaterialIcons,
  MaterialUICore,
  Body,
  Grid,
  Box,
  Button,
} from '@iclinic/design-system';
import { ErrorAlert } from 'shared/components';
import { selectPaymentType } from 'features/onlinePayment/state/checkout/infos/actions';
import { checkoutPix } from 'features/onlinePayment/state/checkout/payment/actions';
import {
  getCheckoutV2Selector,
  getIsPixQRCodeValid,
} from 'features/onlinePayment/state/checkout/payment/selectors';
import { getIsPixTimerRunning } from 'features/onlinePayment/state/checkout/infos/selectors';
import PixQRCodeTimer from './PixQRCodeTimer';
import * as S from './Pix.styles';

const { CircularProgress } = MaterialUICore;
const { FileCopyOutlined, ArrowBack } = MaterialIcons;

export default function Pix(): JSX.Element | null {
  const dispatch = useDispatch();
  const {
    checkoutV2Data,
    checkoutV2FetchStatus,
    checkoutV2Error,
  } = useSelector(getCheckoutV2Selector);
  const isPixQRCodeValid = useSelector(getIsPixQRCodeValid);
  const isPixTimerRunning = useSelector(getIsPixTimerRunning);

  useEffect(() => {
    if (!isPixTimerRunning) {
      dispatch(checkoutPix());
    }
  }, [dispatch]);

  if (checkoutV2FetchStatus) {
    return (
      <S.GridContainer
        container
        direction="column"
        alignItems="center"
        justify="center"
      >
        <>
          <CircularProgress data-testid="pix-loading" size={70} />
          <Body variant="sm" bold>
            Processando...
          </Body>
          <Body variant="xs">Enviando dados de forma segura</Body>
        </>
      </S.GridContainer>
    );
  }

  if (checkoutV2Error) {
    return (
      <ErrorAlert
        message={<span dangerouslySetInnerHTML={{ __html: checkoutV2Error }} />}
        mt={3}
      />
    );
  }

  const handleCopyCode = () => {
    navigator.clipboard.writeText(checkoutV2Data?.pixQrCodeEmv || '');
  };

  const resetPaymentTypeSelected = () => {
    dispatch(
      selectPaymentType({
        paymentType: null,
      }),
    );
  };

  return (
    <S.GridContainer container direction="column" justify="space-between">
      <Grid item>
        <Box textAlign="center" alignItems="center">
          {checkoutV2Data?.pixExpirationDate && (
            <PixQRCodeTimer
              expirationDate={checkoutV2Data.pixExpirationDate}
              qrCode={checkoutV2Data?.pixQrCodeEmv || ''}
            />
          )}
          <Body variant="xs" bold>
            Leia o QrCode ou copie o código abaixo para realizar o pagamento via
            PIX em qualquer aplicativo habilitado
          </Body>
        </Box>

        <S.PixNumberWrapper mt={2}>
          <S.PixNumber
            type="text"
            value={
              isPixQRCodeValid
                ? checkoutV2Data?.pixQrCodeEmv || ''
                : '000000000000000000000000000000000000...'
            }
            readOnly
          />
          {isPixQRCodeValid && (
            <Button variant="text" onClick={handleCopyCode}>
              <FileCopyOutlined />
            </Button>
          )}
        </S.PixNumberWrapper>
      </Grid>

      <Grid item>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
        >
          <Button
            onClick={() => resetPaymentTypeSelected()}
            variant="text"
            startIcon={<ArrowBack />}
          >
            Voltar
          </Button>
          <Button variant="contained" size="large" onClick={handleCopyCode}>
            compartilhar código
          </Button>
        </Box>
      </Grid>
    </S.GridContainer>
  );
}
