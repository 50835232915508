import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme) => ({
  subTitle: {
    color: theme.palette.grey[700],
    fontSize: `1.125rem`,
  },
}));

export default useStyles;
