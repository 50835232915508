// External
import React from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';

// Internal
import AuthenticatedRoute from 'routes/AuthenticatedRoute';
import MyStock from './containers/MyStock';
import History from './containers/History';

const StockRoutes = ({ match }: RouteComponentProps) => (
  <Switch>
    <AuthenticatedRoute exact path={`${match.path}/`} component={MyStock} />
    <AuthenticatedRoute
      exact
      path={`${match.path}/historico`}
      component={History}
    />
  </Switch>
);

export default () => (
  <Switch>
    <Route path="/estoque" component={StockRoutes} />
  </Switch>
);
