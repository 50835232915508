export const getXMLFileNameFromHeader = (headerValue: string) => {
  if (typeof headerValue !== 'string') {
    return '';
  }

  const index = headerValue.indexOf('filename=');

  return headerValue
    .substring(index, headerValue.length)
    .replace('filename=', '')
    .replace(/"/g, '');
};
