import { PayloadAction } from '@reduxjs/toolkit';
import { all, takeLatest, call, put } from 'redux-saga/effects';

import { captureException } from 'shared/utils/handlerErrors';
import { actions } from '.';
import { getPatientHeader as getPatientHeaderService } from './services';
import { patientHeaderServiceResponseAdapter } from 'state/records/utils';

export function* workerGetPatientHeader({
  payload: patientId,
}: PayloadAction<number>) {
  try {
    yield put(actions.setHasError(false));
    yield put(actions.setIsLoading(true));

    const { data, errors } = yield call(getPatientHeaderService, patientId);

    if (errors) throw errors;

    const patientSummary = patientHeaderServiceResponseAdapter(data);
    yield put(actions.setPatientHeader(patientSummary));
  } catch (error) {
    captureException(error);
    yield put(actions.setHasError(true));
  }
  yield put(actions.setIsLoading(false));
}

export default function* cardPatientSagas() {
  yield all([takeLatest(actions.getCardPatient, workerGetPatientHeader)]);
}
