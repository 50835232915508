import { AppointmentGuideDTO } from 'features/tissInvoicing/types';
import { AppointmentGuideResultValues } from '../types/appointmentGuide';

export function createAppointmentGuideListDTO(
  resultsDTO: AppointmentGuideResultValues[],
): AppointmentGuideDTO[] {
  const appointmentGuideList: AppointmentGuideDTO[] = resultsDTO.map((r) => ({
    id: `${r.id}`,
    appointmentDate: r.date_added,
    billedIn: r?.billed_in ?? '',
    completed: !!r?.completed,
    insuranceName: r.insurance_name,
    patientName: r.patient_civil_name,
    version: r.version_id,
  }));

  return appointmentGuideList;
}
