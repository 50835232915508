import { acceptTermsPatientTelemedicine } from 'features/video-conference/services';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { SERVICE_TERMS_OBJECT } from 'features/video-conference/constants';
import { useConference } from 'features/video-conference/state/conferenceContext';
import history from 'routes/history';

export function useAcceptTerms() {
  const { getParticipantsInfo } = useParticipantContext();
  const { onError } = useConference();

  const acceptTerms = async () => {
    const { patient, physician } = await getParticipantsInfo();
    const { id: patientId, name: patientName, email: patientEmail } = patient;
    const { name: physicianName, email: physicianEmail } = physician;

    const body = {
      terms: SERVICE_TERMS_OBJECT,
      patientData: {
        id: patientId,
        name: patientName,
        email: patientEmail || '',
      },
      physicianData: {
        name: physicianName,
        email: physicianEmail || '',
      },
      terms_link: '',
    };

    acceptTermsPatientTelemedicine(body).catch(onError);
  };

  const onSubmit = () => {
    const { search } = history.location;

    history.push({
      pathname: '/teleconsulta/participante/consulta/',
      search,
    });
  };

  return { onSubmit, acceptTerms };
}
