import * as types from './types';

export const fetchSdk = () => ({
  type: types.FETCH_SDK.ACTION,
  payload: { fetching: true },
});
export const fetchSdkSuccess = () => ({
  type: types.FETCH_SDK.SUCCESS,
  payload: {
    completed: true,
    fetching: false,
  },
});
export const fetchSdkdFailure = () => ({
  type: types.FETCH_SDK.FAILURE,
  payload: {
    completed: false,
    fetching: false,
  },
});

export const sdkSettingsSuccess = () => ({
  type: types.SET_SDK_SETTINGS.SUCCESS,
});
export const sdkSettingsFailure = () => ({
  type: types.SET_SDK_SETTINGS.FAILURE,
});

export const setFeaturesSuccess = () => ({ type: types.SET_FEATURES.SUCCESS });
export const setFeaturesFailure = () => ({ type: types.SET_FEATURES.FAILURE });

export const setPatientSuccess = () => ({ type: types.SET_PATIENT.SUCCESS });
export const setPatientFailure = () => ({ type: types.SET_PATIENT.FAILURE });

export const prescriptionCreatedSuccess = (id) => ({
  type: types.PRESCRIPTION_CREATED.SUCCESS,
  payload: {
    hasSaved: false,
    prescription: {
      id,
    },
  },
});

export const prescriptionCreatedFailure = () => ({
  type: types.PRESCRIPTION_CREATED.FAILURE,
});

export const prescriptionFinishedSuccess = (memedId) => ({
  type: types.PRESCRIPTION_FINISHED.SUCCESS,
  payload: {
    hasSaved: true,
    prescription: { memedId },
  },
});

export const prescriptionFinishedFailure = () => ({
  type: types.PRESCRIPTION_FINISHED.FAILURE,
});

export const updatePrescriptionSuccess = () => ({
  type: types.PRESCRIPTION_UPDATE.SUCCESS,
  payload: {
    hasSaved: true,
  },
});
export const updatePrescriptionFailure = () => ({
  type: types.PRESCRIPTION_UPDATE.FAILURE,
});

export const prescriptionAddItemsSuccess = () => ({
  type: types.PRESCRIPTION_ADD_ITEMS.SUCCESS,
  payload: {
    hasSaved: false,
  },
});

export const prescriptionAddItemsFailure = () => ({
  type: types.PRESCRIPTION_ADD_ITEMS.FAILURE,
});

export const prescriptionAddItemSuccess = () => ({
  type: types.PRESCRIPTION_ADD_ITEM.SUCCESS,
  payload: {
    hasSaved: false,
  },
});

export const prescriptionAddItemFailure = () => ({
  type: types.PRESCRIPTION_ADD_ITEM.FAILURE,
});

export const prescriptionReuse = (drugs) => ({
  type: types.PRESCRIPTION_REUSE.ACTION,
  payload: {
    drugs,
  },
});

export const prescriptionClearSuccess = () => ({
  type: types.PRESCRIPTION_CLEAR.SUCCESS,
});

export const prescriptionClearFailure = () => ({
  type: types.PRESCRIPTION_CLEAR.FAILURE,
});

export const prescriptionRemoved = (prescriptionMemedId) => ({
  type: types.PRESCRIPTION_REMOVED.REQUEST,
  payload: { prescriptionMemedId },
});

export const prescriptionRemovedSuccess = (prescriptionMemedId) => ({
  type: types.PRESCRIPTION_REMOVED.SUCCESS,
  payload: { prescriptionMemedId },
});

export const prescriptionRemovedFailure = (prescriptionMemedId) => ({
  type: types.PRESCRIPTION_REMOVED.FAILURE,
  payload: { prescriptionMemedId },
});
