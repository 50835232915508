/* eslint-disable react/jsx-props-no-spreading */
import React, { PropsWithChildren } from 'react';
import { useFormikContext } from 'formik';

import { TextField } from '@iclinic/design-system';
import { GuideSADTForm, Procedure } from 'features/tissInvoicing/types';
import getErrorText from './getErrorText';

type Identifiers = keyof Procedure;

interface TextFieldProcedureSADTProps {
  label: string;
  value: string;
  index: number;
  identifier: Identifiers;
  placeholder?: string;
  disabled?: boolean;
  onChangeHandler?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

function TextFieldProcedureSADT({
  index,
  identifier,
  onChangeHandler,
  children,
  ...restOfProps
}: PropsWithChildren<TextFieldProcedureSADTProps>) {
  const { errors, touched, handleBlur } = useFormikContext<GuideSADTForm>();

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChangeHandler) onChangeHandler(e);
  };

  const validateField = () =>
    getErrorText(`procedures[${index}].${identifier}`, touched, errors);

  return (
    <TextField
      helperText={validateField()}
      error={!!validateField()}
      id={`procedures.${index}.${identifier}`}
      name={`procedures.${index}.${identifier}`}
      onChange={onChangeValue}
      onBlur={handleBlur}
      fullWidth
      {...restOfProps}
    >
      {children}
    </TextField>
  );
}

export default TextFieldProcedureSADT;
