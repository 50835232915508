import React from 'react';

import { Typography, Button } from '@iclinic/design-system';
import useStyles from '../containers/styleSignatureSetup';

type StepButtonsProps = {
  activeStep: number;
  setActiveStep: (step: number) => void;
};

export default function StepButtons({
  activeStep,
  setActiveStep,
}: StepButtonsProps): JSX.Element {
  const classes = useStyles();
  const checkFirstStep = activeStep === 0;
  const checkFinalStep = activeStep === 2;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <>
      {checkFirstStep && (
        <Button
          className={classes.buttonNext}
          onClick={handleNext}
          data-ga="assinatura_digital-setup-avancar_1etapa"
        >
          <Typography variant="body1">Avançar</Typography>
        </Button>
      )}
      {checkFinalStep && (
        <Button
          onClick={handleBack}
          className={classes.buttonBack}
          color="transparent"
        >
          <Typography variant="body1">Voltar</Typography>
        </Button>
      )}

      {!checkFirstStep && !checkFinalStep && (
        <>
          <Button
            onClick={handleBack}
            className={classes.buttonBack}
            color="transparent"
          >
            <Typography variant="body1">Voltar</Typography>
          </Button>
          <Button
            className={classes.buttonNext}
            onClick={handleNext}
            data-ga="assinatura_digital-setup-avancar_2etapa"
          >
            <Typography variant="body1">Avançar</Typography>
          </Button>
        </>
      )}
    </>
  );
}
