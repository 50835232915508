/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Notification } from '@iclinic/iclinic-ui';
import { Heading } from '@iclinic/design-system';

import Modal from 'ui/new/modal';
import Button from 'ui/new/button';
import Dropdown from 'ui/new/dropdown';
import Link from 'ui/button/Link';
import {
  getSuccessInfo,
  getNamePhysicians,
  getpromotionTotalValue,
  getTotalValue,
  getproductValue,
  getProductName,
  getAnnual,
  getIndividualUser,
} from 'state/upgradeAddon/selectors';
import { ExpireAfter } from 'shared/components';
import * as actions from 'state/upgradeAddon/actions';
import { image } from 'shared/utils/urlGetter';
// modal contents
import SummaryAddon from './SummaryAddon';
// components
import ViewInfo from '../components/ViewInfo';
import SelectUsers from '../../billing/components/selectUsers/SelectUsers';
import CheckSelectUsers from '../../billing/components/checkSelectUsers/CheckSelectUsers';
import Upsell from '../components/upsell/Upsell';
import CheckSelectAddon from '../components/upsell/CheckSelectAddon';
import Message from '../components/Message';
// modal constants
import { VIEWSTEPS, ALIGN_FOOTER, TITLE_MODAL, FLOW } from '../constants';
import { BUTTON } from 'ui/new/button/constants';
import { FOOTER_DIRECTION } from 'ui/new/modal/constants';
import { ICLINIC_ASSIST_SLUG } from 'state/subscription/constants';
import { productViews } from 'shared/constants/views';
import { ModalInfo } from '../components/ModalInfo';
import { gotoAppRoute } from 'shared/utils/appRoutes';

class UpgradeAddon extends PureComponent {
  componentDidMount() {
    const { initialize } = this.props;
    initialize();
  }

  render() {
    const {
      // store
      isOpen,
      step,
      currentModal,
      errors,
      physicians,
      addons,
      hiring,
      userData,
      totalUsers,
      selectedProducts,
      isFetching,
      selectPhysician,
      // actions
      detailClose,
      closeInstruction,
      setStep,
      selectProducts,
      fetchCreate,
      fetchNotification,
      // selectors
      successInfo,
      namePhysicians,
      productValue,
      totalValue,
      promotionTotalValue,
      annual,
      individualUser,
      goToIclinicAssist,
    } = this.props;

    const { products } = addons;

    const adminMessage = image('adminMessage');

    const adminMessageSucess = image('adminMessageSucess');

    const { isAdmin, userName, userEmail } = userData;

    const successImage = image('successImage');

    const titleFlow =
      hiring === FLOW.upsell
        ? 'Parabéns, você adicionou um novo profissional.'
        : '';

    const upgradeFlow = step === VIEWSTEPS.info && hiring === FLOW.upgrade;

    const upgradeFlowOnlineSchedule =
      step === VIEWSTEPS.info &&
      hiring === FLOW.upgrade &&
      currentModal === FLOW.onlineScheduling;

    const setStepBack = (currentStep, stepModal) => () =>
      setStep(currentStep - 1, stepModal);

    const handleIclinicAssist = () => {
      goToIclinicAssist();
      detailClose(currentModal);
    };
    const iClinicAssist =
      selectedProducts?.includes(ICLINIC_ASSIST_SLUG) &&
      step === VIEWSTEPS.success;

    if (upgradeFlow && currentModal === 'message-whatsapp') {
      return (
        <ModalInfo
          isOpen={isOpen}
          currentModal={currentModal}
          onClose={() => detailClose()}
          onConfirmClick={() => gotoAppRoute('/relacionamento')}
          customActions={
            !isAdmin ? (
              <Heading variant="overline" align="center">
                Contate o administrador da conta e adicione essa funcionalidade
                no seu plano
              </Heading>
            ) : undefined
          }
        />
      );
    }

    return (
      <>
        <Notification>
          {errors.map((error) => (
            <ExpireAfter key={error.code} delay={3000}>
              <Notification.Message title="Atenção" showCloseButton={false}>
                {error.message}
              </Notification.Message>
            </ExpireAfter>
          ))}
        </Notification>
        <Modal
          title={TITLE_MODAL.key(step, hiring)}
          show={isOpen}
          onClose={() => detailClose(currentModal)}
        >
          <Modal.Body>
            {upgradeFlow &&
              isAdmin &&
              currentModal !== FLOW.onlineScheduling && (
                <ViewInfo slug={currentModal} />
              )}
            {(step === VIEWSTEPS.user && hiring === FLOW.upgrade) ||
            upgradeFlowOnlineSchedule ? (
              <SelectUsers
                placeholder={namePhysicians}
                totalUsers={totalUsers}
                productValue={productValue}
                productName={successInfo && successInfo.productName}
                promotionTotalValue={promotionTotalValue}
                totalValue={totalValue}
              >
                {physicians.map((item) => (
                  <Dropdown.Item
                    key={item.id}
                    onClick={() => !item.hasAddon && selectPhysician(item.id)}
                    kind="exists"
                    value={item.id}
                  >
                    <CheckSelectUsers
                      name={item.name}
                      checked={item.checked || item.hasAddon}
                      onChange={() => selectPhysician(item.id)}
                      disabled={item.hasAddon}
                    />
                  </Dropdown.Item>
                ))}
              </SelectUsers>
            ) : (
              step === VIEWSTEPS.info &&
              isAdmin &&
              (hiring === FLOW.upsell || hiring === FLOW.upgradePerUser) && (
                <Upsell isEmpty={products.length === 0} title={titleFlow}>
                  {products.map((product) => (
                    <CheckSelectAddon
                      key={product.id}
                      name={product.name}
                      onChange={() => selectProducts(product.id)}
                      checked={product.checked || product.current}
                      disabled={product.current}
                      promotionPrice={product.price.promotionPrice}
                      description={product.description}
                      price={product.price.fullPrice}
                      subscription={annual}
                    />
                  ))}
                </Upsell>
              )
            )}
            {step === VIEWSTEPS.resume && <SummaryAddon />}
            {step === VIEWSTEPS.success && successInfo && (
              <Message
                img={successImage}
                alt={userName}
                title="Parabéns, você contratou recursos adicionais iClinic."
                message={`O comprovante da sua assinatura será enviado no email: ${userEmail} junto com outras informações essenciais para o uso da funcionalidade.`}
              />
            )}
            {step === VIEWSTEPS.info && !isAdmin && (
              <Message
                img={adminMessage}
                alt={userName}
                title={`Olá ${userName}`}
                message={
                  'Para adquirir novos planos ou serviços adicionais, notifique o administrador da sua conta clicando em “Enviar solicitação"'
                }
              />
            )}
            {step === VIEWSTEPS.adminSuccess && (
              <Message
                img={adminMessageSucess}
                alt={userName}
                title="Solicitação enviada com sucesso."
                message={`Oi ${userName}, sua solicitação foi enviada para o administrador da clínica, agora é só aguardar a aprovação dele.`}
              />
            )}
          </Modal.Body>
          <Modal.Footer
            align={
              (step === VIEWSTEPS.info &&
                hiring === FLOW.upgrade &&
                currentModal !== FLOW.onlineScheduling) ||
              iClinicAssist
                ? ALIGN_FOOTER.center
                : ALIGN_FOOTER.split
            }
            direction={
              iClinicAssist ? FOOTER_DIRECTION.column : FOOTER_DIRECTION.row
            }
          >
            {upgradeFlow &&
              isAdmin &&
              currentModal !== FLOW.onlineScheduling && (
                <Button
                  onClick={() => setStep(VIEWSTEPS.user, currentModal)}
                  testId={`know-more-${currentModal}`}
                  idGa={productViews[currentModal]?.trackId}
                  idSnowplow={productViews[currentModal]?.trackId}
                >
                  QUERO ASSINAR
                </Button>
              )}
            {step === VIEWSTEPS.info &&
              hiring === FLOW.upgradePerUser &&
              isAdmin && (
                <>
                  <Link
                    kind="primary"
                    href="#"
                    onClick={() => detailClose(currentModal)}
                  >
                    Voltar
                  </Link>
                  <Button
                    disabled={selectedProducts.length === 0}
                    onClick={() => setStep(VIEWSTEPS.resume, currentModal)}
                    testId="addon-add-user"
                  >
                    PROSSEGUIR
                  </Button>
                </>
              )}
            {step === VIEWSTEPS.info && hiring === FLOW.upsell && isAdmin && (
              <>
                <Link
                  kind="primary"
                  href="#"
                  onClick={() => detailClose(currentModal)}
                >
                  Voltar
                </Link>
                <Button
                  onClick={() => setStep(VIEWSTEPS.resume, currentModal)}
                  testId="addon-add-user"
                >
                  PROSSEGUIR
                </Button>
              </>
            )}
            {(step === VIEWSTEPS.user || upgradeFlowOnlineSchedule) && (
              <>
                <Link
                  kind="primary"
                  href="#"
                  onClick={setStepBack(VIEWSTEPS.user, currentModal)}
                >
                  Voltar
                </Link>
                <Button
                  disabled={!totalUsers}
                  onClick={() => setStep(VIEWSTEPS.resume, currentModal)}
                  testId="addon-add-user"
                >
                  PROSSEGUIR
                </Button>
              </>
            )}
            {step === VIEWSTEPS.resume && hiring === FLOW.upgrade && (
              <>
                <Link
                  kind="primary"
                  href="#"
                  onClick={
                    individualUser
                      ? () => detailClose(currentModal)
                      : setStepBack(
                          hiring === FLOW.upgrade
                            ? VIEWSTEPS.resume
                            : VIEWSTEPS.user,
                          currentModal,
                        )
                  }
                >
                  {individualUser ? 'Voltar ao iClinic' : 'Voltar'}
                </Link>
                <Button
                  loading={isFetching}
                  onClick={() => fetchCreate()}
                  testId="confirm-add-user"
                >
                  CONFIRMAR ASSINATURA
                </Button>
              </>
            )}
            {step === VIEWSTEPS.resume &&
            hiring === FLOW.upsell &&
            selectedProducts.length > 0 ? (
              <>
                <Link
                  kind="primary"
                  href="#"
                  onClick={setStepBack(
                    hiring === FLOW.upgrade ? VIEWSTEPS.resume : VIEWSTEPS.user,
                    currentModal,
                  )}
                >
                  Voltar
                </Link>
                <Button
                  loading={isFetching}
                  onClick={() => fetchCreate()}
                  testId="confirm-add-user"
                >
                  CONFIRMAR ASSINATURA
                </Button>
              </>
            ) : (
              step === VIEWSTEPS.resume &&
              hiring === FLOW.upsell &&
              selectedProducts.length === 0 && (
                <Link
                  kind="primary"
                  href="#"
                  onClick={() => detailClose(currentModal)}
                >
                  Voltar ao iClinic
                </Link>
              )
            )}
            {step === VIEWSTEPS.resume && hiring === FLOW.upgradePerUser && (
              <>
                <Link
                  kind="primary"
                  href="#"
                  onClick={setStepBack(
                    hiring === FLOW.upgrade ? VIEWSTEPS.resume : VIEWSTEPS.user,
                    currentModal,
                  )}
                >
                  Voltar
                </Link>
                <Button
                  loading={isFetching}
                  onClick={() => fetchCreate()}
                  testId="confirm-add-user"
                >
                  CONFIRMAR ASSINATURA
                </Button>
              </>
            )}
            {step === VIEWSTEPS.success && successInfo && (
              <>
                {iClinicAssist ? (
                  <Button onClick={handleIclinicAssist}>
                    Envie seus dados à assistência
                  </Button>
                ) : (
                  <Link
                    kind="primary"
                    href="#"
                    onClick={() => detailClose(currentModal)}
                  >
                    Voltar ao iClinic
                  </Link>
                )}
                <Button
                  type={iClinicAssist ? BUTTON.Light : BUTTON.Primary}
                  onClick={closeInstruction}
                >
                  VER INSTRUÇÕES
                </Button>
              </>
            )}
            {!isAdmin && step !== VIEWSTEPS.adminSuccess && (
              <Button onClick={() => fetchNotification(currentModal)}>
                Enviar solicitação
              </Button>
            )}
            {step === VIEWSTEPS.adminSuccess && (
              <Link
                kind="primary"
                href="#"
                onClick={() => detailClose(currentModal)}
              >
                Voltar ao iClinic
              </Link>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.upgradeAddon,
  ...state.userInfo,
  successInfo: getSuccessInfo(state),
  namePhysicians: getNamePhysicians(state),
  productValue: getproductValue(state),
  promotionTotalValue: getpromotionTotalValue(state),
  totalValue: getTotalValue(state),
  productName: getProductName(state),
  annual: getAnnual(state),
  individualUser: getIndividualUser(state),
});

UpgradeAddon.defaultProps = {
  errors: { message: null, code: null },
  successInfo: {
    title: null,
    productName: null,
    imageUrl: null,
    alt: null,
  },
  userInfo: null,
  closeInstruction: undefined,
  totalUsers: 0,
  promotionTotalValue: 0,
  totalValue: 0,
  productValue: '',
  namePhysicians: null,
  userData: null,
  annual: false,
  hiring: '',
  individualUser: false,
};

UpgradeAddon.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  currentModal: PropTypes.string.isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      map: PropTypes.func.isRequired,
      code: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }).isRequired,
  ),
  userInfo: PropTypes.shape({
    email: PropTypes.string,
  }),
  successInfo: PropTypes.shape({
    title: PropTypes.string,
    productName: PropTypes.string,
    imageUrl: PropTypes.string,
    alt: PropTypes.string,
  }),
  physicians: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  totalUsers: PropTypes.number,
  promotionTotalValue: PropTypes.number,
  totalValue: PropTypes.number,
  productValue: PropTypes.string,
  namePhysicians: PropTypes.string,
  detailClose: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  selectPhysician: PropTypes.func.isRequired,
  selectProducts: PropTypes.func.isRequired,
  annual: PropTypes.bool,
  selectedProducts: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string]))
    .isRequired,
  hiring: PropTypes.string,
  addons: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    products: PropTypes.array.isRequired,
  }).isRequired,
  fetchCreate: PropTypes.func.isRequired,
  fetchNotification: PropTypes.func.isRequired,
  closeInstruction: PropTypes.func,
  userData: PropTypes.shape({
    isAdmin: PropTypes.bool,
    userName: PropTypes.string,
    userEmail: PropTypes.string,
  }),
  initialize: PropTypes.func.isRequired,
  individualUser: PropTypes.bool,
  goToIclinicAssist: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, actions)(UpgradeAddon);
