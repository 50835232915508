import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  MaterialUICore,
  MaterialIcons,
  Heading,
  Body,
  DatePicker,
  Select,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@iclinic/design-system';

import {
  FetchGuidesQuery,
  GenericObjectDescription,
  GuideTypeOptions,
} from 'features/tissInvoicing/types';
import { isValidFullDate } from 'shared/utils/validation';
import { actions } from 'features/tissInvoicing/state/lot';
import {
  getAddGuidesState,
  getLotDataState,
} from 'features/tissInvoicing/state/lot/selectors';
import { SpaceBetweenBox } from 'features/tissInvoicing/components/ui/Flexbox';
import * as Styles from './styles';

const { Drawer, MenuItem } = MaterialUICore;
const { Close } = MaterialIcons;

export const schema = Yup.object().shape({
  patient: Yup.number(),
  physician: Yup.number(),
  date_from: Yup.date()
    .nullable()
    .test(
      'isValidFullDate',
      'Data inválida',
      (value) => !value || isValidFullDate(value),
    ),
  date_to: Yup.date()
    .nullable()
    .min(Yup.ref('date_from'), 'Essa data não pode ser antes que a anterior')
    .test(
      'isValidFullDate',
      'Data inválida',
      (value) => !value || isValidFullDate(value),
    ),
});

interface FilterProps {
  open: boolean;
  handleClose: () => void;
}

function Filter({ open, handleClose }: FilterProps) {
  const dispatch = useDispatch();
  const classes = Styles.useStyles();

  const [hasTyped, setHastyped] = useState(false);

  const { search, hasMoreData, hasSelectedAllGuides } =
    useSelector(getAddGuidesState);
  const { lot } = useSelector(getLotDataState);
  const { query, filterOptions, loadingPatients } = search;

  const handleApply = (values: FetchGuidesQuery) => {
    dispatch(actions.setAvailableGuides({ guides: [], hasMoreData }));
    dispatch(actions.setAvailableGuidesQuery(values));
    dispatch(
      actions.fetchAvailableGuides({
        hasSelectedAllGuides,
        lot_id: lot?.id,
        page: 1,
        insurance_id: Number(lot?.clinic_health_insurance?.id),
        ordering: 'lot_id,date',
        guide_type: GuideTypeOptions.SPSADT,
      }),
    );
    handleClose();
  };

  const handleCleanFilters = () => {
    handleApply({
      patient_id: 0,
      physician_id: 0,
      date_from: null,
      date_to: null,
    });
  };

  const getPatientbyId = (id?: string | number | null) =>
    filterOptions.patients.find((patient) => patient.id === Number(id)) || null;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        classes: { root: classes.paper },
      }}
    >
      <SpaceBetweenBox
        classes={{ root: classes.title }}
        data-testid="filter-modal"
      >
        <Heading variant="lg">Filtrar</Heading>

        <Button
          classes={{ root: classes.button }}
          startIcon={<Close />}
          onClick={handleClose}
          color="transparent"
        >
          Fechar
        </Button>
      </SpaceBetweenBox>

      <Formik
        initialValues={query}
        validationSchema={schema}
        enableReinitialize
        onSubmit={handleApply}
      >
        {}
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <Styles.Form onSubmit={handleSubmit}>
            <SpaceBetweenBox flexDirection="column">
              <Styles.Section>
                <Heading variant="md">Data</Heading>

                <SpaceBetweenBox classes={{ root: classes.date }}>
                  <Body variant="sm">Período</Body>

                  <DatePicker
                    label="De"
                    id="date_from"
                    name="date_from"
                    onChange={(date) => setFieldValue('date_from', date)}
                    value={values.date_from}
                    allowKeyboard
                    placeholder="DD/MM/AAAA"
                    error={!!(errors.date_from && touched.date_from)}
                    helperText={touched.date_from && errors.date_from}
                    onBlur={handleBlur}
                    style={{ maxWidth: '210px' }}
                  />

                  <DatePicker
                    label="Até"
                    id="date_to"
                    name="date_to"
                    onChange={(date) => {
                      setFieldValue('date_to', date);
                    }}
                    value={values.date_to}
                    allowKeyboard
                    placeholder="DD/MM/AAAA"
                    error={!!(errors.date_to && touched.date_to)}
                    helperText={touched.date_to && errors.date_to}
                    onBlur={handleBlur}
                    style={{ maxWidth: '210px' }}
                  />
                </SpaceBetweenBox>
              </Styles.Section>

              <Styles.Section marginTop={6}>
                <Heading variant="md">Paciente</Heading>

                <Autocomplete
                  id="patient_id"
                  fullWidth
                  clearOnBlur
                  onBlur={handleBlur}
                  options={filterOptions.patients}
                  noOptionsText={
                    hasTyped && !loadingPatients
                      ? 'Paciente não encontrado'
                      : 'Digite 3 letras para buscar...'
                  }
                  loading={loadingPatients}
                  loadingText={<CircularProgress label="Buscando pacientes" />}
                  value={getPatientbyId(values.patient_id)}
                  onInputChange={(_, value) => {
                    if (value.length >= 3) {
                      setHastyped(!!value);
                      dispatch(
                        actions.fetchGuidesFilterPatients({ query: value }),
                      );
                    }
                  }}
                  onChange={(_, option) =>
                    setFieldValue(
                      'patient_id',
                      (option as GenericObjectDescription)?.id || null,
                    )
                  }
                  getOptionLabel={(option) => option?.name}
                  renderOption={(option) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Paciente"
                      placeholder="Digite 3 letras para buscar..."
                      name="patient_id"
                      data-ga="patient_id"
                    />
                  )}
                />
              </Styles.Section>

              <Styles.Section marginTop={6}>
                <Heading variant="md">Profissional</Heading>
                <Select
                  label="Profissional"
                  id="physician_id"
                  name="physician_id"
                  value={values.physician_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value={0}>Todos os prestadores</MenuItem>
                  {filterOptions.physicians.map((physician) => (
                    <MenuItem value={physician.id} key={physician.id}>
                      {physician.name}
                    </MenuItem>
                  ))}
                </Select>
              </Styles.Section>
            </SpaceBetweenBox>

            <SpaceBetweenBox width="100%" paddingBottom={8}>
              <Button
                onClick={() => {
                  resetForm();
                  handleCleanFilters();
                }}
                color="transparent"
              >
                Limpar filtros
              </Button>

              <Button type="submit" color="secondary">
                Ver resultado
              </Button>
            </SpaceBetweenBox>
          </Styles.Form>
        )}
      </Formik>
    </Drawer>
  );
}

export default Filter;
