import { Reducer } from 'redux';

import {
  ActionType,
  ApiErrors,
  FetchState,
  IActionCreatorFailure,
} from '@types';
// keep sorted
import * as actions from './actions';
import {
  SET_EVENT,
  FETCH_SELLER_INFO_FAILURE,
  FETCH_SELLER_INFO_SUCCESS,
  FETCH_BILL_FAILURE,
  FETCH_BILL_SUCCESS,
  CREATE_BILL_ACTION,
  CREATE_BILL_FAILURE,
  CREATE_BILL_SUCCESS,
  FETCH_CHECKOUT_URL_ACTION,
  FETCH_CHECKOUT_URL_FAILURE,
  FETCH_CHECKOUT_URL_SUCCESS,
  UPDATE_BILL_FAILURE,
  TOGGLE_IS_GENERATING,
  TOGGLE_UPDATED_BILL,
  TOGGLE_CHARGEBACK_MODAL,
  UPDATE_BILL_SUCCESS,
} from './constants';
import { Bill, Event, Seller } from './types';
import { BILL_FETCH_ERROR, BILL_SAVE_ERROR } from './errorMessages';

const emptyFetchState: FetchState = { isFetching: false };
const fetchingState: FetchState = { isFetching: true };
const fetchErrorState = <T extends string>(
  action: IActionCreatorFailure<T>,
): FetchState => ({
  errors: action.payload.errors,
  isFetching: false,
});

export type ManageState = {
  fetchSellerInfo: FetchState;
  fetchBill: FetchState;
  createBill: FetchState;
  fetchCheckoutUrl: FetchState;
  updateBill: FetchState;
  event: Event | null;
  seller: Seller | null;
  bill: Bill | null;
  url: string | null;
  isGenerating: boolean;
  updatedBill: boolean;
  showChargeBackModal: boolean;
};

export const initialState: ManageState = {
  fetchSellerInfo: fetchingState,
  fetchBill: fetchingState,
  createBill: emptyFetchState,
  fetchCheckoutUrl: emptyFetchState,
  updateBill: emptyFetchState,
  event: null,
  seller: null,
  bill: null,
  url: null,
  isGenerating: false,
  updatedBill: false,
  showChargeBackModal: false,
};

const onlinePaymentManageReducer: Reducer<ManageState> = (
  state = initialState,
  action: ActionType<typeof actions>,
): ManageState => {
  switch (action.type) {
    case SET_EVENT:
      return {
        ...initialState,
        fetchSellerInfo: state.fetchSellerInfo,
        seller: state.seller,
        event: action.payload.event,
      };

    case FETCH_SELLER_INFO_FAILURE:
      return { ...state, fetchSellerInfo: fetchErrorState(action) };
    case FETCH_SELLER_INFO_SUCCESS:
      return {
        ...state,
        fetchSellerInfo: emptyFetchState,
        seller: action.payload.seller,
      };

    case FETCH_BILL_FAILURE:
      return {
        ...state,
        fetchBill: {
          errors: [{ message: BILL_FETCH_ERROR } as ApiErrors],
          isFetching: false,
        },
      };
    case FETCH_BILL_SUCCESS:
      return {
        ...state,
        fetchBill: emptyFetchState,
        bill: action.payload.bill,
      };

    case CREATE_BILL_ACTION:
      return { ...state, createBill: fetchingState };
    case CREATE_BILL_FAILURE:
      return {
        ...state,
        createBill: {
          errors: [{ message: BILL_SAVE_ERROR } as ApiErrors],
          isFetching: false,
        },
      };
    case CREATE_BILL_SUCCESS:
      return {
        ...state,
        createBill: emptyFetchState,
        bill: action.payload.bill,
      };

    case FETCH_CHECKOUT_URL_ACTION:
      return { ...state, fetchCheckoutUrl: fetchingState };
    case FETCH_CHECKOUT_URL_FAILURE:
      return { ...state, fetchCheckoutUrl: fetchErrorState(action) };
    case FETCH_CHECKOUT_URL_SUCCESS:
      return {
        ...state,
        fetchCheckoutUrl: emptyFetchState,
        url: action.payload.url,
      };

    case UPDATE_BILL_FAILURE:
      return { ...state, updateBill: fetchErrorState(action) };

    case UPDATE_BILL_SUCCESS:
      return {
        ...state,
        bill: {
          ...(state.bill as Bill),
          amountCharged: action.payload.amountCharged,
        },
      };

    case TOGGLE_IS_GENERATING:
      return { ...state, isGenerating: action.payload.isGenerating };

    case TOGGLE_UPDATED_BILL:
      return { ...state, updatedBill: action.payload.updatedBill };

    case TOGGLE_CHARGEBACK_MODAL:
      return {
        ...state,
        showChargeBackModal: !state.showChargeBackModal,
      };

    default:
      return state;
  }
};

export default onlinePaymentManageReducer;
