import {
  MaterialUICore,
  tokens,
  Avatar as DSAvatar,
  Heading,
  Box as DSBox,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  backgroundColor: tokens.color.neutral[0],
  marginTop: tokens.spacing.sm,
  borderRadius: tokens.border.radius.sm,
  border: `${tokens.border.width.hairline} solid`,
  borderColor: tokens.color.neutral[100],
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const PatientContent = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: `${tokens.spacing.md} ${tokens.spacing.sm} ${tokens.spacing.md} ${tokens.spacing.sm}`,
  [theme.breakpoints.down('sm')]: {
    padding: `${tokens.spacing.xs} ${tokens.spacing.sm} 0px ${tokens.spacing.sm}`,
    flexDirection: 'column',
  },
}));

export const Avatar = styled(DSAvatar)(({ theme }) => ({
  marginRight: tokens.spacing.sm,
  [theme.breakpoints.down('sm')]: {
    margin: `0 auto`,
    marginBottom: tokens.spacing.xs,
  },
}));

export const InfoContent = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const Title = styled(Heading)(({ theme }) => ({
  marginBottom: tokens.spacing.base,
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

export const PatiendData = styled(DSBox)(({ theme }) => ({
  marginRight: tokens.spacing.md,
  display: 'inline-block',
  width: '40%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: tokens.spacing.xs,
  },
}));
