import React from 'react';
import { Body, MaterialIcons } from '@iclinic/design-system';

import { CenterBox } from 'features/tissInvoicing/components/ui/Flexbox';
import { image } from 'shared/utils/urlGetter';
import { addExpenseHref } from '../constants';
import { NewExpenseButton } from '../styles';

const { Add } = MaterialIcons;

export default function EmptyExpenses() {
  return (
    <CenterBox>
      <img
        src={image('tissInvoicing.listLotArt')}
        alt="Ainda não existem despesas."
      />
      <Body variant="xs">Ainda não existem despesas.</Body>
      <NewExpenseButton
        variant="contained"
        startIcon={<Add />}
        href={addExpenseHref}
      >
        Nova Despesa
      </NewExpenseButton>
    </CenterBox>
  );
}
