// External
import React, { useEffect, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Dispatch } from 'redux';

// Internal
import { Panel } from '@iclinic/iclinic-ui';
import NotificationExpireMessage from 'shared/components/NotificationExpireMessage';
import { FORM_LIST_EMAIL_FLOW_RETURN } from 'shared/constants/forms';
import { StoreState } from 'state/rootReducer';
import {
  fetchListEmailFlow,
  fetchListPhysicians,
  activeEmailFlow,
  editEmailFlow,
  addEmailFlow,
  deleteEmailFlow,
  duplicateEmailFlow,
  clearErrorsComponent,
  setActiveEmailFlowCard,
} from 'state/marketing/emailFlow/emailFlowManagement/actions';
import {
  fetchProfessionals,
  setSelectedProfessionalById,
} from 'state/marketing/emailFlow/professional/actions';
import {
  emailFlowInformation,
  searchEmailFlowName,
} from 'state/marketing/emailFlow/emailFlowManagement/constants';
import {
  EmailFlow,
  EmailFlowActionsReturn,
  EmailFlowTarget,
  EmailFlowError,
} from 'state/marketing/emailFlow/emailFlowManagement/emailFlowTypes';
import * as selectors from 'state/marketing/emailFlow/emailFlowManagement/selectors';
import * as selectorsProfessional from 'state/marketing/emailFlow/professional/selectors';
import SearchBar from 'ui/new/search';
import Button from 'ui/new/button';
import {
  ADD_EMAIL_FLOW_MODAL,
  CONFIRM_DELETE_MODAL,
} from 'features/marketing/modals';
import { open, close } from 'state/modal/actions';
import ModalRoot from 'features/modal/containers/ModalRoot';
import { Professional } from 'state/marketing/emailFlow/professional/types';
import { BUTTON } from 'ui/new/button/constants';
import useWidthUpdater from 'shared/hooks/useWidthUpdater';
import ListEmailFlowCustom from '../components/ListEmailFlowCustom';
import ListEmailFlowMobile from '../components/ListEmailFlowMobile';
import ListEmailFlowItem from '../components/ListEmailFlowItem';
import ListEmailFlowItemMobile from '../components/ListEmailFlowItemMobile';
import DisplayPhysicians from '../components/DisplayPhysicians';
import style from './ListEmailFlow.scss';

interface StateProps {
  emailFlowsCustom?: EmailFlow[];
  professionals: Professional[];
  selectedProfessional: Professional | undefined;
  errors: EmailFlowError[];
  activeEmailFlowCard: string;
}

export interface DeleteModalParams {
  id: string;
  name: string;
}
export interface DuplicateParams extends DeleteModalParams {}

interface DispatchProps {
  listEmailFlow: (professional: Professional) => EmailFlowActionsReturn;
  listProfessionals: () => void;
  setActiveEmailFlow: (
    id: React.ReactText,
    active: boolean,
  ) => EmailFlowActionsReturn;
  updateEmailFlow: (emailFlow: EmailFlow) => EmailFlowActionsReturn;
  setProfessionalById: (id: number) => void;
  openAddEmailFlowModal: () => void;
  onDuplicateEmailFlow: ({ id, name }: DuplicateParams) => void;
  openConfirmDeleteModal: ({ id, name }: DeleteModalParams) => void;
  listPhysicians: () => void;
  clearErrors: () => void;
  setActiveFlowCard: (id: string) => void;
}

interface ListEmailFlowsCustomProps extends StateProps, DispatchProps {}

const ListEmailFlowsCustom = ({
  listEmailFlow,
  listProfessionals,
  setProfessionalById,
  setActiveEmailFlow,
  emailFlowsCustom,
  updateEmailFlow,
  professionals,
  openAddEmailFlowModal,
  onDuplicateEmailFlow,
  openConfirmDeleteModal,
  listPhysicians,
  selectedProfessional,
  errors,
  clearErrors,
  activeEmailFlowCard,
  setActiveFlowCard,
}: ListEmailFlowsCustomProps): JSX.Element => {
  const isMobile = useWidthUpdater();
  const ListComponent = isMobile ? ListEmailFlowMobile : ListEmailFlowCustom;
  const ItemComponent = isMobile ? ListEmailFlowItemMobile : ListEmailFlowItem;

  useEffect(() => {
    listProfessionals();
    listPhysicians();
  }, [listProfessionals, listPhysicians]);

  useEffect(() => {
    if (selectedProfessional) {
      listEmailFlow(selectedProfessional);
    }
  }, [listEmailFlow, selectedProfessional]);

  const onChange = useCallback(
    (e: EmailFlowTarget) => {
      setProfessionalById(Number(e.target.value));
    },
    [setProfessionalById],
  );

  const titleSelectedPhysicians: JSX.Element = useMemo(
    () => (
      <DisplayPhysicians professionals={professionals} onChange={onChange} />
    ),
    [professionals, onChange],
  );

  const changeStatusEmailFlow = useCallback(
    (e: EmailFlowTarget) => {
      const { checked, value } = e.target;
      setActiveEmailFlow(value, checked);
    },
    [setActiveEmailFlow],
  );

  const searchEmailFlow: JSX.Element = useMemo(
    () => (
      <Field
        type="text"
        name={searchEmailFlowName}
        component={SearchBar}
        placeholder="Pesquise por nome ou código do produto"
        key={searchEmailFlowName}
      />
    ),
    [],
  );

  const notificationErrors: JSX.Element[] = useMemo(
    () =>
      errors.map((item) => (
        <NotificationExpireMessage
          kind="error"
          title="Sequência automáticas de e-mails"
          showCloseButton={false}
          key={item.id}
          onExpire={clearErrors}
          expireAfter="3000"
        >
          {item.message}
        </NotificationExpireMessage>
      )),
    [clearErrors, errors],
  );

  const returnFlowInformation = (
    <div className={style['email-flow-list-text']}>
      {emailFlowInformation.return}
    </div>
  );

  return (
    <div className={style['flow-list-panel']}>
      {notificationErrors}
      <Panel title={titleSelectedPhysicians} />
      <div className={style['title-email-flow-custom']}>
        <span>Sequência de e-mails personalizados</span>
        {isMobile ? returnFlowInformation : undefined}
        <div className={style['create-button']}>
          <Button onClick={openAddEmailFlowModal} type={BUTTON.Primary} block>
            CRIAR E-MAILS PERSONALIZADOS
          </Button>
        </div>
      </div>
      <ListComponent
        emailFlowInformation={emailFlowInformation.return}
        searchEmailFlow={searchEmailFlow}
      >
        {emailFlowsCustom &&
          emailFlowsCustom.map((item) => (
            <ItemComponent
              emailFlow={item}
              key={item.id}
              changeStatusEmailFlow={changeStatusEmailFlow}
              editEmailFlow={updateEmailFlow}
              duplicateEmailFlow={onDuplicateEmailFlow}
              deleteEmailFlow={openConfirmDeleteModal}
              activeEmailFlowCard={isMobile ? activeEmailFlowCard : undefined}
              setActiveFlowCard={isMobile ? setActiveFlowCard : undefined}
            />
          ))}
      </ListComponent>
      <ModalRoot />
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  initialValues: selectorsProfessional.getSelectedProfessional(state),
  emailFlowsCustom: selectors.getEmailFlowCustomListForDisplay(state),
  professionals: selectorsProfessional.getProfessionals(state),
  selectedProfessional: selectorsProfessional.getProfessionalSelected(state),
  errors: selectors.getErrorsComponent(state),
  activeEmailFlowCard: selectors.getActiveEmailFlowCard(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  listEmailFlow: (professional: Professional) =>
    dispatch(fetchListEmailFlow(professional)),
  listPhysicians: () => dispatch(fetchListPhysicians()),
  listProfessionals: () => dispatch(fetchProfessionals()),
  setProfessionalById: (id: number) =>
    dispatch(setSelectedProfessionalById(id)),
  setActiveEmailFlow: (id: React.ReactText, active: boolean) =>
    dispatch(activeEmailFlow(id, active)),
  updateEmailFlow: (emailFlow: EmailFlow) => dispatch(editEmailFlow(emailFlow)),
  openAddEmailFlowModal: () =>
    dispatch(
      open(ADD_EMAIL_FLOW_MODAL, {
        onCloseClick: () => {
          dispatch(close());
        },
        onAddEmailFlow: () => {
          dispatch(addEmailFlow());
        },
      }),
    ),
  onDuplicateEmailFlow: ({ id, name }) =>
    dispatch(
      open(ADD_EMAIL_FLOW_MODAL, {
        name: `Cópia de ${name}`,
        onCloseClick: () => {
          dispatch(close());
        },
        onAddEmailFlow: () => {
          dispatch(duplicateEmailFlow(id));
        },
      }),
    ),
  openConfirmDeleteModal: ({ id, name }) =>
    dispatch(
      open(CONFIRM_DELETE_MODAL, {
        name,
        onCloseClick: () => {
          dispatch(close());
        },
        onDeleteEmailFlow: () => {
          dispatch(deleteEmailFlow(id));
        },
      }),
    ),
  clearErrors: () => {
    dispatch(clearErrorsComponent());
  },
  setActiveFlowCard: (activeEmailFlowCard: string) => {
    dispatch(setActiveEmailFlowCard(activeEmailFlowCard));
  },
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: FORM_LIST_EMAIL_FLOW_RETURN,
    enableReinitialize: true,
  })(ListEmailFlowsCustom),
);
