import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { FinancialSellerData } from '../panel/types';
import {
  NOTIFICATIONS_FETCH_ERROR,
  OVERVIEW_FETCH_ERROR,
} from './errorMessages';
import { Notifications, Notification, OverviewFilters } from './types';

export interface NotificationsCenterState {
  dateNotificationsRead?: string;
  filters: OverviewFilters;
  notificationsList: [] | Notification[];
  pageNumber: number;
  showNotificationsCenter: boolean;
  unreadNotifications: number;
  accessToken?: {
    fetchStatus?: RequestStatus;
    data?: string;
    error?: string;
  };
  notifications?: {
    fetchStatus?: RequestStatus;
    data?: Notifications;
    error?: string;
  };
  financialSellerData?: {
    fetchStatus?: RequestStatus;
    data?: FinancialSellerData;
    error?: string;
  };
}

export const initialState: NotificationsCenterState = {
  notificationsList: [],
  pageNumber: 1,
  showNotificationsCenter: false,
  unreadNotifications: 0,
  filters: {
    startDate: new Date(),
    endDate: new Date(),
  },
};
const name = 'notificationsCenter';

const NotificationsCenterSlice = createSlice({
  name,
  initialState,
  reducers: {
    fetchFinancialSellerData: (state) => ({
      ...state,
      financialSellerData: { fetchStatus: RequestStatus.Pending },
    }),
    fetchFinancialSellerDataFailure: (state) => ({
      ...state,
      financialSellerData: {
        fetchStatus: RequestStatus.Error,
        error: OVERVIEW_FETCH_ERROR,
      },
    }),
    fetchFinancialSellerDataSuccess: (
      state,
      action: PayloadAction<FinancialSellerData>,
    ) => ({
      ...state,
      financialSellerData: {
        fetchStatus: RequestStatus.Success,
        data: action.payload,
      },
    }),
    fetchNotifications: (state) => ({
      ...state,
      notifications: { fetchStatus: RequestStatus.Pending },
    }),
    fetchNotificationsFailure: (state) => ({
      ...state,
      notifications: {
        fetchStatus: RequestStatus.Error,
        error: NOTIFICATIONS_FETCH_ERROR,
      },
    }),
    fetchNotificationsSuccess: (
      state,
      action: PayloadAction<Notifications>,
    ) => ({
      ...state,
      notifications: {
        fetchStatus: RequestStatus.Success,
        data: action.payload,
      },
    }),
    fetchNotificationToken: (state) => ({
      ...state,
      accessToken: { fetchStatus: RequestStatus.Pending },
    }),
    fetchNotificationTokenFailure: (state) => ({
      ...state,
      accessToken: {
        fetchStatus: RequestStatus.Error,
        error: NOTIFICATIONS_FETCH_ERROR,
      },
    }),
    fetchNotificationTokenSuccess: (state, action: PayloadAction<string>) => ({
      ...state,
      accessToken: { fetchStatus: RequestStatus.Success, data: action.payload },
    }),
    setUnreadNotificationsCount: (state, action: PayloadAction<number>) => ({
      ...state,
      unreadNotifications: action.payload,
    }),
    setDateReadNotifications: (state, action: PayloadAction<string>) => ({
      ...state,
      dateNotificationsRead: action.payload,
    }),
    setNotificationsList: (state, action: PayloadAction<Notification[]>) => ({
      ...state,
      notificationsList: action.payload,
    }),
    setOverviewFilters: (state, action: PayloadAction<OverviewFilters>) => ({
      ...state,
      filters: action.payload,
    }),
    setPageNumber: (state, action: PayloadAction<number>) => ({
      ...state,
      pageNumber: action.payload,
    }),
    setNotificationsFetching: (state) => ({
      ...state,
      notifications: { fetchStatus: RequestStatus.Pending },
    }),
    toggleShowNotificationsCenter: (state, action: PayloadAction<boolean>) => ({
      ...state,
      showNotificationsCenter: action.payload,
    }),
  },
});

export default NotificationsCenterSlice.reducer;

export const {
  fetchFinancialSellerData,
  fetchFinancialSellerDataFailure,
  fetchFinancialSellerDataSuccess,
  fetchNotifications,
  fetchNotificationsFailure,
  fetchNotificationsSuccess,
  fetchNotificationToken,
  fetchNotificationTokenFailure,
  fetchNotificationTokenSuccess,
  setUnreadNotificationsCount,
  setDateReadNotifications,
  setNotificationsList,
  setOverviewFilters,
  setPageNumber,
  setNotificationsFetching,
  toggleShowNotificationsCenter,
} = NotificationsCenterSlice.actions;
