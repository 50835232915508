import { PayloadAction, createSlice, createAction } from '@reduxjs/toolkit';

import { TranscriptionDrawerState, TranscriptionItem } from './types';

export const initialState: TranscriptionDrawerState = {
  isTranscriptionModalOpen: false,
  currentPage: 0,
  transcription: [],
  isEndOfTranscription: false,
  conferenceId: 0,
  hasError: false,
  isLoading: true,
};

const name = 'transcriptionDrawer';

const transcriptionDrawerSlice = createSlice({
  name: `@webapp/${name}`,
  initialState,
  reducers: {
    openTranscriptionDrawer: (state, action: PayloadAction<number>) => ({
      ...state,
      isTranscriptionModalOpen: true,
      conferenceId: action.payload,
    }),
    closeTranscriptionDrawer: () => ({
      ...initialState,
    }),
    setTranscription: (state, action: PayloadAction<TranscriptionItem[]>) => ({
      ...state,
      transcription: [...state.transcription, ...action.payload],
      currentPage: state.currentPage + 1,
      isEndOfTranscription: false,
      isLoading: false,
    }),
    setEndOfTranscription: (state) => ({
      ...state,
      isEndOfTranscription: true,
      isLoading: false,
    }),
    setError: (state) => ({
      ...state,
      hasError: true,
    }),
  },
});

const getMoreTranscription = createAction(`${name}/getMoreTranscription`);

export const actions = {
  ...transcriptionDrawerSlice.actions,
  getMoreTranscription,
};

export default transcriptionDrawerSlice.reducer;
