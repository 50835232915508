import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = axiomTokens;

export const ContainerChips = styled('div')(() => ({
  paddingBottom: spacing[2],
  paddingTop: spacing[2],

  '& > div': {
    marginRight: spacing[1],
    marginBottom: spacing[1],

    '&:last-child': {
      marginRight: 0,
    },
  },
}));

export const CardListContainer = styled('div')(() => ({
  '& .MuiInputBase-root': {
    paddingTop: 0,
  },
}));
