import { hasPromotionOnPeriod } from 'shared/utils/productPromotion';

const selectProduct = (selectedProduct, isAnnual) => {
  const isMonthly = !isAnnual;
  const frequency = isAnnual ? 'annual' : 'monthly';
  const hasPromotion = hasPromotionOnPeriod(selectedProduct, isMonthly);
  if (!selectedProduct) {
    return {
      promotionalValue: null,
      productValue: null,
      period: null,
    };
  }
  return {
    promotionalValue: hasPromotion
      ? parseFloat(selectedProduct.promotion.frequencies[frequency].value)
      : null,
    productValue: parseFloat(selectedProduct.values[frequency]),
    period: hasPromotion
      ? selectedProduct.promotion.frequencies[frequency].frequencyNumber
      : null,
  };
};

export { selectProduct };
export default selectProduct;
