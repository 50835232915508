import { Box as BoxUI, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled, makeStyles } = MaterialUICore;

export const Content = styled(BoxUI)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: tokens.spacing.base,
  marginBottom: tokens.spacing.sm,
});

export const useStyles = makeStyles(() => ({
  button: {
    color: tokens.color.neutral[700],
  },
}));
