export const DOCUMENTS_ERROR =
  'Não conseguimos salvar seus documentos :(<br/>Por favor, tente novamente! Caso o erro persista, entre em contato com nosso suporte pelo chat dentro do sistema.';

export const SELLER_FETCH_ERROR =
  'Não conseguimos carregar os dados do seu cadastro :(<br/>Por favor, tente novamente! Caso o erro persista, entre em contato com nosso suporte pelo chat dentro do sistema.';

export const UPDATE_DEFAULT_SELLER_ERROR =
  'Não conseguimos atualizar sua conta padrão :(<br/>Por favor, tente novamente! Caso o erro persista, entre em contato com nosso suporte pelo chat dentro do sistema.';

export const SELLER_SAVE_ERROR =
  'Não conseguimos realizar seu cadastro :(<br/>Por favor, tente novamente! Caso o erro persista, entre em contato com nosso suporte pelo chat dentro do sistema.';

export const SELLER_CNPJ_SAVE_ERROR =
  'O valor informado para o campo CNPJ já está em uso, entre em contato com nosso suporte pelo chat dentro do sistema.';

export const SELLER_CPF_SAVE_ERROR =
  'Já existe um cadastro com o mesmo CPF :(<br/>Por favor, entre em contato com nosso suporte pelo chat dentro do sistema.';

export const INSTALLMENTS_ERROR =
  'Não conseguimos salvar suas configurações :(<br/>Por favor, tente novamente! Caso o erro persista, entre em contato com nosso suporte pelo chat dentro do sistema.';

export const BANK_ACCOUNT_ERROR =
  'Não conseguimos salvar suas configurações :(<br/>Por favor, tente novamente! Caso o erro persista, entre em contato com nosso suporte pelo chat dentro do sistema.';

export const EMAIL_FETCH_ERROR =
  'Não conseguimos carregar as configurações de e-mail :(<br/>Por favor, tente novamente! Caso o erro persista, entre em contato com nosso suporte pelo chat dentro do sistema.';

export const EMAIL_SAVE_ERROR =
  'Não conseguimos salvar as configurações de e-mail :(<br/>Por favor, tente novamente! Caso o erro persista, entre em contato com nosso suporte pelo chat dentro do sistema.';

export const ADDRESS_UPDATE_ERROR =
  'Não conseguimos salvar as configurações de endereço :(<br/>Por favor, tente novamente! Caso o erro persista, entre em contato com nosso suporte pelo chat dentro do sistema.';

export const BANK_ACCOUNT_FETCH_ERROR =
  'Não conseguimos carregar os dados de sua conta :(<br/>Por favor, tente novamente! Caso o erro persista, entre em contato com nosso suporte pelo chat dentro do sistema.';

export const SELLER_REFUSED_INFO =
  'O seu cadastro apresenta informações inconsistentes.';
