import { all, takeLatest, call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { getLatestDiagnoses as getLatestDiagnosesService } from './services';
import { actions } from '.';
import { captureException } from 'shared/utils/handlerErrors';

const {
  getLatestDiagnoses,
  getLatestDiagnosesFailure,
  getLatestDiagnosesSuccess,
} = actions;

export function* workerGetLatestDiagnoses(action: PayloadAction<string>) {
  try {
    const patientId = action.payload;

    const { data, errors } = yield call(getLatestDiagnosesService, patientId);

    if (errors) throw errors;

    yield put(getLatestDiagnosesSuccess(data));
  } catch (error) {
    yield put(getLatestDiagnosesFailure());
    captureException(error);
  }
}

export default function* latestDiagnosesSagas() {
  yield all([takeLatest(getLatestDiagnoses, workerGetLatestDiagnoses)]);
}
