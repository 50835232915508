// External
import React from 'react';
import { Panel, Title } from '@iclinic/iclinic-ui';

// Internal
import {
  tableBodyEmptyText,
  tableColumns,
  tableColumnKeys,
  Align,
} from 'state/marketing/emailFlow/emailFlowManagement/constants';
import Table from 'ui/new/table';

import style from './ListEmailFlow.scss';

type ListEmailFlowProps = {
  children?: React.ReactNode,
  emailFlowInformation: string;
};

const tableWidthTitle = {
  [tableColumnKeys.title]: style.column__title,
  [tableColumnKeys.actions]: style.columnSize__actions,
  [tableColumnKeys.finishedPatients]: style.columnSize__finishedFlow,
  [tableColumnKeys.activePatients]: style.columnSize__patientsActive,
};

const tableTitleAlign = {
  [tableColumnKeys.title]: Align.left,
  [tableColumnKeys.activePatients]: Align.right,
  [tableColumnKeys.finishedPatients]: Align.right,
  [tableColumnKeys.actions]: Align.left,
};

const ListEmailFlow = ({
  children,
  emailFlowInformation,
}: ListEmailFlowProps): JSX.Element => {
  const tableHeaders = React.useMemo(() => [
    tableColumns.map(({ key, label }) => (
      <Table.Item
        align={Align[tableTitleAlign[key]]}
        key={key}
        classes={tableWidthTitle[key]}
      >
        {label}
      </Table.Item>
    )),
  ], []);

  const titleInformation = <span className={style.titleInformation}>{emailFlowInformation}</span>;

  return (
    <div className={style.listEmailFlowContainer}>
      <Panel title={titleInformation}>
        <Table>
          <Table.Head>
            <Table.Row>
              {tableHeaders}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {children}
          </Table.Body>
        </Table>
        {!children
          && <Title className={style.tableEmpty}>{tableBodyEmptyText}</Title>
        }
      </Panel>
    </div>
  );
};

export default ListEmailFlow;
