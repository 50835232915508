import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  MaterialUICore,
  MaterialIcons,
  Button,
  Box,
  Typography,
} from '@iclinic/design-system';
import {
  isTransactionDetailOpen,
  getIsAttemptTransactions,
} from 'features/onlinePayment/state/reports/transactions/selectors';
import { toggleTransactionDetailOpen } from 'features/onlinePayment/state/reports/transactions';
import useStyles from './TransactionDetail.styles';
import {
  OnlineTransactionSideBar,
  AttemptTransactionSideBar,
} from 'features/onlinePayment/components/reports/transactions';

const { Drawer } = MaterialUICore;
const { ArrowBack } = MaterialIcons;

const TransactionDetail = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isOpen = useSelector(isTransactionDetailOpen);
  const isAttemptTransactions = useSelector(getIsAttemptTransactions);

  const renderSideBar = isAttemptTransactions ? (
    <AttemptTransactionSideBar />
  ) : (
    <OnlineTransactionSideBar />
  );

  const closeDrawer = () => {
    dispatch(toggleTransactionDetailOpen(false));
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={closeDrawer}
      className={classes.drawer}
      BackdropProps={{ invisible: true }}
    >
      <Box p={4} className={classes.drawerContent}>
        <Box mb={4}>
          <Typography variant="h3">Detalhes da transação</Typography>
        </Box>
        {renderSideBar}
      </Box>
      <Box py={2} px={4}>
        <Button
          color="transparent"
          className={classes.backButton}
          startIcon={<ArrowBack color="action" fontSize="small" />}
          onClick={closeDrawer}
        >
          Voltar
        </Button>
      </Box>
    </Drawer>
  );
};

export default TransactionDetail;
