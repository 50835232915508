import { useCallback, useEffect, useState } from 'react';

import { useConference } from 'features/video-conference/state/conferenceContext';
import { useConferenceFinish } from 'features/video-conference/hooks/useConferenceFinish';
import { useConferenceTokens } from 'features/video-conference/hooks/useConferenceTokens';
import { useSound } from 'features/video-conference/hooks/useSound';
import { RoomTypes } from 'features/video-conference/constants/types';
import { setTelemedicineStatus } from 'features/video-conference/utils';
import {
  fetchCheckRoomStatus,
  fetchRoomConnect,
} from 'features/video-conference/services';
import sounds from 'shared/paths/sounds';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { useAcceptTerms } from '../useAcceptTerms';

interface WaitRoomProps {
  isWaitRoom?: boolean;
}

export function useWaitRoom({ isWaitRoom = true }: WaitRoomProps) {
  const { acceptTerms } = useAcceptTerms();
  const {
    connect,
    isPhysician,
    conferenceFinished,
    isRecording,
    setIsRecording,
    setRoomType,
    setIsRoomCreated,
    onError,
    setStartedRoomTime,
    isRoomCreated,
    setIsConnecting,
  } = useConference();
  const { getAccessToken } = useConferenceTokens({ isPhysician });
  const { handleErrorConferenceFinished } = useConferenceFinish();
  const {
    play: playNotificationSound,
    pause: pauseNotificationSound,
    playing: isPlayingNotificationSound,
  } = useSound(sounds.telemedicine.calling);
  const { isFetchingInfo } = useParticipantContext();

  const [isReadyToJoin, setIsReadyToJoin] = useState<boolean>(false);
  const [checkRoomLoading, setCheckRoomLoading] = useState<boolean>(false);

  const [isModalPricingOpen, setIsModalPricingOpen] = useState<boolean>(false);
  const [inLoopSound, setInLoopSound] = useState(true);
  const [timeoutErrorCount, setTimeoutErrorCount] = useState<number>(0);

  useEffect(() => {
    if (!isFetchingInfo && isWaitRoom) {
      fetchRoomConnect();
    }
  }, [isFetchingInfo, isWaitRoom]);

  useEffect(() => {
    if (
      !isPhysician &&
      isWaitRoom &&
      isReadyToJoin &&
      inLoopSound &&
      !isPlayingNotificationSound
    )
      playNotificationSound();
  }, [
    inLoopSound,
    isPlayingNotificationSound,
    playNotificationSound,
    isReadyToJoin,
    isPhysician,
    isWaitRoom,
  ]);

  const checkRoom = useCallback(() => {
    if (checkRoomLoading) return;
    setCheckRoomLoading(true);
    fetchCheckRoomStatus()
      .then(({ getResponseData, errors }) => {
        setCheckRoomLoading(false);
        if (errors) {
          handleErrorConferenceFinished(errors);
          errors.forEach((error) => onError(error));
          setTimeoutErrorCount((prevState) => prevState + 1);
          return;
        }

        const {
          patientConnected,
          created: roomCreated,
          type: roomType,
        } = getResponseData();
        if (isPhysician && patientConnected) {
          setIsReadyToJoin(true);
        }

        if (roomCreated) {
          if (!isPhysician) {
            setIsReadyToJoin(true);
          }
          setIsRoomCreated(true);
        }

        if (roomType === RoomTypes.group) {
          setRoomType(RoomTypes.group);
          setIsRecording(true);
        }
      })
      .catch((error) => {
        setCheckRoomLoading(false);
        setTimeoutErrorCount((prevState) => prevState + 1);
        onError(error);
      });
  }, [
    checkRoomLoading,
    handleErrorConferenceFinished,
    isPhysician,
    onError,
    setIsReadyToJoin,
    setIsRecording,
    setIsRoomCreated,
    setRoomType,
  ]);

  useEffect(() => {
    let interval: undefined | ReturnType<typeof setInterval>;

    if (!isFetchingInfo) {
      interval = setInterval(() => {
        if ((isRoomCreated && isReadyToJoin) || conferenceFinished) {
          if (interval) clearInterval(interval);
          interval = undefined;
          return;
        }
        checkRoom();
      }, 5000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [
    checkRoom,
    conferenceFinished,
    isReadyToJoin,
    isRoomCreated,
    isFetchingInfo,
  ]);

  const handleJoin = async () => {
    try {
      setIsConnecting(true);
      const token = await getAccessToken();
      if (!token) {
        throw new Error('Failed to get access token');
      }

      if (!isPhysician) {
        await acceptTerms();
      }

      const { getResponseData } = await fetchCheckRoomStatus();
      const { createdAt } = getResponseData();

      setStartedRoomTime(createdAt);
      setInLoopSound(false);
      pauseNotificationSound();

      connect(token)
        .then(() => {
          setTelemedicineStatus('started');
          setTimeoutErrorCount(0);
          setIsConnecting(false);
        })
        .catch((error) => {
          setIsConnecting(false);
          onError(error);
          return Promise.reject(error);
        });

      return await Promise.resolve();
    } catch (error) {
      setIsConnecting(false);
      return Promise.reject(error);
    }
  };

  const handleToggle = () => {
    if (!isRecording) {
      setIsModalPricingOpen(true);
      return;
    }

    setIsRecording(false);
    setRoomType(RoomTypes.p2p);
  };

  const handleRejectPricingModal = () => {
    setIsRecording(false);
    setIsModalPricingOpen(false);
  };

  const handleAcceptPricingModal = () => {
    setIsRecording(true);
    setRoomType(RoomTypes.group);
    setIsModalPricingOpen(false);
  };

  return {
    isReadyToJoin,
    handleJoin,
    handleToggle,
    handleRejectPricingModal,
    handleAcceptPricingModal,
    isModalPricingOpen,
    inLoopSound,
    timeoutErrorCount,
  };
}
