import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid } from '@iclinic/design-system';
import {
  getPageNumber,
  getExportListPagination,
} from 'features/exportClinicData/state/exportList/selectors';
import { selectedPageNumber } from 'features/exportClinicData/state/exportList';
import { StyledPagination } from './Paginate.styles';

export default function Paginate(): JSX.Element | null {
  const dispatch = useDispatch();
  const pageNumber = useSelector(getPageNumber);
  const exportPagination = useSelector(getExportListPagination);

  const itensPerPage = 20;

  const handlePageChange = (_event: object, page: number) => {
    dispatch(selectedPageNumber(page));
  };

  const totalOfPages = (totalPages: number): number => {
    return Math.ceil(totalPages / itensPerPage);
  };

  if (!exportPagination) return null;

  const { count } = exportPagination;

  return (
    <>
      {count > itensPerPage && (
        <Box mt={2} mb={4}>
          <Grid container justify="center">
            <StyledPagination
              data-testid="pagination-table"
              color="standard"
              count={totalOfPages(count)}
              page={pageNumber}
              shape="rounded"
              onChange={handlePageChange}
            />
          </Grid>
        </Box>
      )}
    </>
  );
}
