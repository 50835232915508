import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialIcons, Button, Loader } from '@iclinic/design-system';

import { SnackbarProps } from 'features/tissInvoicing/types';
import { actions } from 'features/tissInvoicing/state/lot';
import {
  getLotDataState,
  getLotViewState,
} from 'features/tissInvoicing/state/lot/selectors';
import TwoButtonDialog from 'features/tissInvoicing/components/ui/TwoButtonDialog';
import CloseLot, { CLOSE_MESSAGE } from './CloseLot';
import ReOpenLot, { REOPEN_MESSAGE } from './ReOpenLot';
import { DOWNLOAD_XML_MESSAGE } from './DownloadXML';
import * as Styles from './styles';

const { LockOutlined, LockOpen, GetApp } = MaterialIcons;

interface ModalProps {
  title: string;
  content: JSX.Element;
  leftButton?: {
    text: string;
    handler: () => void;
  };
  rightButton?: {
    text: string;
    handler: () => void;
  };
}

interface FooterButtonsProps {
  isClosed: boolean;
  id: string;
}

function FooterButtons({ isClosed, id }: FooterButtonsProps) {
  const dispatch = useDispatch();

  const {
    lot: { guides, lot_number },
  } = useSelector(getLotDataState);
  const { loading, config } = useSelector(getLotViewState);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalProps, setModalProps] = useState<ModalProps>({
    title: '',
    content: <span />,
  });

  const handleCloseModal = () => setIsModalOpen(false);

  const handleModals = (content: ModalProps) => {
    setModalProps(content);
    setIsModalOpen(true);
  };

  const handleEditLot = (is_closed: boolean, content: SnackbarProps) => {
    const rest = {
      executant_cnes: config?.executant_cnes || '',
      executant_code: config.executant_code,
      executant_code_type: config.executant_code_type,
      executant_name: config.executant_name,
    };

    dispatch(
      actions.patchLotData({
        id,
        is_closed,
        ...rest,
        callback: () => {
          dispatch(
            actions.setLotConfig({
              ...config,
              ...rest,
            }),
          );

          dispatch(actions.setLotsClosed(is_closed));

          dispatch(actions.setSnackbarContent(content));

          handleCloseModal();
        },
      }),
    );
  };

  const handleDownloadXMLFile = () => {
    dispatch(
      actions.fetchXMLFile({
        id,
        lot_number,
        callback: () => {
          dispatch(actions.setSnackbarContent(DOWNLOAD_XML_MESSAGE));

          handleCloseModal();
        },
      }),
    );
  };

  const CLOSE_LOT = {
    title: 'Deseja fechar o lote?',
    content: <CloseLot />,
    leftButton: {
      text: 'Cancelar',
      handler: handleCloseModal,
    },
    rightButton: {
      text: 'Fechar lote',
      handler: () => handleEditLot(true, CLOSE_MESSAGE),
    },
  };

  const REOPEN_LOT = {
    title: 'Deseja reabrir o lote?',
    content: <ReOpenLot />,
    leftButton: {
      text: 'Cancelar',
      handler: handleCloseModal,
    },
    rightButton: {
      text: 'Reabrir lote',
      handler: () => handleEditLot(false, REOPEN_MESSAGE),
    },
  };

  return (
    <Styles.Container>
      {isClosed ? (
        <>
          <Button
            size="small"
            color="primary"
            disabled={loading.downloadXML || loading.patchLotData}
            startIcon={loading.downloadXML ? <Loader size={15} /> : <GetApp />}
            onClick={handleDownloadXMLFile}
          >
            Arquivo XML
          </Button>

          <Button
            size="small"
            color="secondary"
            disabled={loading.patchLotData || loading.downloadXML}
            startIcon={
              loading.patchLotData ? <Loader size={15} /> : <LockOpen />
            }
            onClick={() => handleModals(REOPEN_LOT)}
          >
            Reabrir lote
          </Button>
        </>
      ) : (
        <Button
          size="small"
          disabled={loading.patchLotData || guides?.length === 0}
          startIcon={
            loading.patchLotData ? <Loader size={15} /> : <LockOutlined />
          }
          onClick={() => handleModals(CLOSE_LOT)}
        >
          Fechar lote
        </Button>
      )}

      <TwoButtonDialog
        title={modalProps.title}
        content={modalProps.content}
        isOpen={isModalOpen}
        closeHandler={() => setIsModalOpen(false)}
        leftButton={modalProps.leftButton}
        rightButton={modalProps.rightButton}
      />
    </Styles.Container>
  );
}

export { CLOSE_MESSAGE, REOPEN_MESSAGE, DOWNLOAD_XML_MESSAGE };
export default FooterButtons;
