// External
import { reduxForm, InjectedFormProps } from 'redux-form';

import { connect } from 'react-redux';
import { StoreState } from 'state/rootReducer';
import { FORM_STOCK_ADD } from 'shared/constants/forms';
import { ProductCreate } from 'features/stock/state/types';
import { Unit } from 'features/stock/types';
import { getFilteredUnits } from '../state/selectors';

const mapStateToProps = (state: StoreState) => ({
  units: getFilteredUnits(state),
});

type MapStateToProps = ReturnType<typeof mapStateToProps>;

type EnhanceProps = {
  change: InjectedFormProps['change'];
  handleSubmit: InjectedFormProps['handleSubmit'];
  units: Unit[];
};

type StockFormReduxProps = {
  render(params: EnhanceProps): React.ReactNode;
};

export default (submitAction: (product: ProductCreate) => void) => {
  const StockFormRedux = ({
    render,
    change,
    handleSubmit,
    units,
  }: StockFormReduxProps & MapStateToProps & InjectedFormProps) =>
    render({
      change,
      handleSubmit,
      units,
    });

  const Form = reduxForm<{}, StockFormReduxProps>({
    form: FORM_STOCK_ADD,
    touchOnBlur: false,
    onSubmitSuccess: (payload: ProductCreate, dispatch): void => {
      dispatch(submitAction(payload));
    },
  })(StockFormRedux);

  return connect(mapStateToProps, null)(Form);
};
