import React from 'react';

import {
  Body,
  MaterialUICore,
  tokens,
  theme as themeUI,
} from '@iclinic/design-system';

const { styled, createMuiTheme } = MaterialUICore;
const { color, spacing, line, border } = tokens;

interface MainWrapperProps {
  isMobile: boolean;
}

const theme = createMuiTheme(themeUI);

export const MainWrapper = styled(
  ({
    isMobile,
    ...rest
  }: MainWrapperProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >) => <div {...rest} />,
)({
  alignSelf: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.32)',
  borderRadius: border.radius.md,
  display: 'flex',
  height: '112px',
  marginBottom: ({ isMobile }: MainWrapperProps) =>
    isMobile ? spacing.base : spacing.md,
  maxWidth: '430px',
  overflow: 'hidden',
  width: '100%',

  [theme.breakpoints.down(320)]: {
    height: 'auto',
  },
});

export const ImageWrapper = styled('div')(() => ({
  flex: 'none',
  flexGrow: 0,
  width: '144px',
  height: '112px',
}));

export const MessageWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: spacing.sm,
  width: '100%',
}));

export const InfoText = styled(Body)({
  color: color.neutral[25],
  fontFamily: 'roboto, sans-serif',
  letterSpacing: '0.44px',
  lineHeight: line.height.sm,

  '& > strong > a': {
    color: color.neutral[25],
  },
});
