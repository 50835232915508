import { createSelector } from 'reselect';

import { StoreState } from 'state/rootReducer';
import { TransactionStatus } from 'features/onlinePayment/state/constants';
import { CheckoutV2Data } from './types';

export const getSyncTransaction = ({ onlinePayment }: StoreState) =>
  onlinePayment.checkout.payment.syncTransaction;

export const getStatusTransaction = ({
  onlinePayment,
}: StoreState): TransactionStatus | undefined =>
  onlinePayment.checkout.payment.syncTransaction.status;

export const getIsPixQRCodeValid = ({ onlinePayment }: StoreState) =>
  onlinePayment.checkout.payment.isPixQRCodeValid;

export const getCheckoutV2Data = ({
  onlinePayment,
}: StoreState): CheckoutV2Data | undefined =>
  onlinePayment.checkout.payment.checkoutV2?.data;

export const getCheckoutV2Error = ({
  onlinePayment,
}: StoreState): string | null | undefined =>
  onlinePayment.checkout.payment.checkoutV2?.error;

export const getCheckoutV2FetchStatus = ({
  onlinePayment,
}: StoreState): boolean | undefined =>
  onlinePayment.checkout.payment.checkoutV2?.isFetching;

export const getCheckoutV2Selector = createSelector(
  [getCheckoutV2Data, getCheckoutV2FetchStatus, getCheckoutV2Error],
  (checkoutV2Data, checkoutV2FetchStatus, checkoutV2Error) => ({
    checkoutV2Data,
    checkoutV2FetchStatus,
    checkoutV2Error,
  }),
);

export const isFetchingCheckoutV2 = createSelector(
  getCheckoutV2FetchStatus,
  (checkoutV2FetchStatus) => checkoutV2FetchStatus === true,
);
