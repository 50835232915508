import { useEffect } from 'react';
import { FormikErrors, FormikTouched } from 'formik';

import { trackRequestSamplesFormStep } from '../trackRequestSamplesFormUtils';
import { RequestSamplesFormData } from '../containers/RequestSamplesForm/types';
import { UserInfo } from '../state/request-samples/types';

export const usePhysicianFormErrorTracking = (
  errors: FormikErrors<RequestSamplesFormData>,
  touched: FormikTouched<RequestSamplesFormData>,
  userInfo: UserInfo,
) => {
  useEffect(() => {
    if (errors.ufCrm && touched.ufCrm) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'user_crm_state',
        stepNum: 1,
        failureReason: errors.ufCrm,
      });
    }
  }, [errors.ufCrm, touched.ufCrm, userInfo]);

  useEffect(() => {
    if (errors.crm && touched.crm) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'user_crm_number',
        stepNum: 2,
        failureReason: errors.crm,
      });
    }
  }, [errors.crm, touched.crm, userInfo]);

  useEffect(() => {
    if (errors.fullName && touched.fullName) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'user_full_name',
        stepNum: 3,
        failureReason: errors.fullName,
      });
    }
  }, [errors.fullName, touched.fullName, userInfo]);

  useEffect(() => {
    if (errors.cpf && touched.cpf) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'user_cpf',
        stepNum: 4,
        failureReason: errors.cpf,
      });
    }
  }, [errors.cpf, touched.cpf, userInfo]);

  useEffect(() => {
    if (errors.phoneNumber && touched.phoneNumber) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'user_phone',
        stepNum: 5,
        failureReason: errors.phoneNumber,
      });
    }
  }, [errors.phoneNumber, touched.phoneNumber, userInfo]);
};
