// Internal
import range from './helper';

export const getTotalPage = (
  totalCount: number,
  itensPerPage: number,
) => Math.ceil(totalCount / itensPerPage);

export const getRangeOfPages = (actualPage: number, totalCount: number, itensPerPage: number) => {
  const totalPages = getTotalPage(totalCount, itensPerPage);

  const previousPageNumber = actualPage > 1 ? actualPage - 1 : actualPage;
  const nextPageNumber = actualPage < totalPages ? actualPage + 1 : actualPage;

  return range(previousPageNumber, nextPageNumber);
};

export const getNextPage = (totalCount: number, itensPerPage: number, actualPage: number) => {
  const totalPages = Math.ceil(totalCount / itensPerPage);
  return actualPage < totalPages ? actualPage + 1 : totalPages;
};

export const getPreviousPage = (actualPage: number) => (actualPage > 1 ? actualPage - 1 : 1);
