import iClinicApi from 'services/iclinic/iClinicApi';
import {
  Consumptions,
  ContractorsResponse,
  EventStatus,
  PaginationParams,
  PaymentSummary,
} from '../../types';

export const getPaymentSummary = (physicianId?: string) =>
  iClinicApi.get<PaymentSummary>(
    `/subscriptions/subscription/summary-messages/${
      physicianId ? `${physicianId}/` : ''
    }`,
  );

export const getConsumptionsDetails = (physicianId?: string) => {
  const haveID = physicianId ? `${physicianId}/` : '';
  return iClinicApi.get<Consumptions>(
    `/messages/consumption-package/${haveID}`,
  );
};

export const getContractorTemplates = ({
  page,
  limit = 10,
}: PaginationParams) =>
  iClinicApi.get<ContractorsResponse>(
    `/messages/templates/contractors/?page=${page}&limit=${limit}`,
  );

export const setContractorTemplateEvents = ({
  physicianId,
  ...contractorChanges
}: EventStatus) =>
  iClinicApi.put(
    `/messages/templates/contractors/${physicianId}/`,
    contractorChanges,
  );
