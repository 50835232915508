import { Box as BoxUI, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled, makeStyles } = MaterialUICore;

export const Container = styled(BoxUI)({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${tokens.spacing.sm}`,
  margin: `0 ${tokens.spacing.sm} ${tokens.spacing.sm}`,
});

export const Header = styled(BoxUI)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: tokens.spacing.base,
  marginBottom: tokens.spacing.sm,
});

export const Content = styled(BoxUI)({
  display: 'flex',
  flexDirection: 'column',
  padding: tokens.spacing.sm,
  background: tokens.color.neutral[0],
  border: `1px solid ${tokens.color.neutral[50]}`,
  borderRadius: tokens.border.radius.md,
});

export const useStyles = makeStyles(() => ({
  buttonRadius: {
    width: '150px',
    padding: tokens.spacing.xs,
    marginTop: tokens.spacing.base,
    boxShadow:
      '0px 15px 25px rgba(41, 98, 255, 0.25), 0px 3.35045px 5.58408px rgba(41, 98, 255, 0.149027), 0px 0.997515px 1.66253px rgba(41, 98, 255, 0.100973)',
    '&:hover, &:focus': {
      color: tokens.color.neutral[0],
      outline: 'none',
      textDecoration: 'none',
    },
  },
}));
