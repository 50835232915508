import * as Yup from 'yup';
import { AddedFlow, ReturnFlow } from 'state/marketing/emailFlow/patient/types';
import { hasReturnFlowInAddedFlows } from 'state/marketing/emailFlow/patient/utils';

export default Yup.object().shape({
  returnFlow: Yup
    .array(Yup.object())
    .when(['addedFlows', 'selectedReturnFlow'], {
      is: (addedFlows: AddedFlow[], selectedReturnFlow: ReturnFlow) => (
        !selectedReturnFlow.id && hasReturnFlowInAddedFlows(addedFlows)
      ),
      then: Yup
        .array(Yup.object())
        .required('Por favor, selecione um retorno ao paciente'),
    }),
  selectedProfessional: Yup
    .object().required('Por favor, selecione um professional de saúde'),
});
