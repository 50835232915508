import { AxiosError } from 'axios';
import { ApiErrors } from '@types';

import { DEFAULT_ERROR_MESSAGE } from './constants';

type EditPaymentError = {
  message: ApiErrors;
};

export const getAxiosErrorCode = (error: AxiosError<EditPaymentError>) => {
  if (!error.isAxiosError) return '';
  return error.response?.data?.message?.code || DEFAULT_ERROR_MESSAGE;
};

export const generateAvailableDates = () => {
  const startingDay = 2;
  const endDay = 20;

  const generateArrayByLength = (length: number) =>
    Array.from(Array(length).keys());

  return generateArrayByLength(endDay - 1).map((item) => ({
    value: item + startingDay,
    label: (item + startingDay).toString(),
  }));
};

export const formatDateToDayMonthPresentation = (date: string) => {
  if (!date || typeof date !== 'string') return '';
  const [, month, day] = date.split('-');
  return `${day}/${month}`;
};

export const getDefaultPaymentDay = (currentPaymentDay: number) => {
  const recommendedPaymentDay = 6;
  const limitDay = 10;
  return currentPaymentDay > limitDay
    ? recommendedPaymentDay
    : currentPaymentDay;
};
