import { MaterialUICore, axiomTokens, Button } from '@iclinic/design-system';

const { styled, Drawer } = MaterialUICore;

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '506px',
    backgroundColor: axiomTokens.color.background[2],
    display: 'grid',
    gridTemplateRows: '64px minmax(0px, 85vh) 82px',
  },

  [theme.breakpoints.down('md')]: {
    '& .MuiPaper-root': {
      width: '100%',
      height: '91.94vh',
    },

    '& .MuiDrawer-paper': {
      bottom: '0px',
      top: 'initial',
    },
  },
}));

export const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${axiomTokens.spacing[4]} ${axiomTokens.spacing[6]}`,

  '& h2': {
    fontWeight: axiomTokens.font.weight.bold,
    fontSize: axiomTokens.font.size.lg,
    lineHeight: axiomTokens.font.lineHeight.lg,
    color: axiomTokens.color.typeface.pure,
    margin: '0px',
  },

  '& a': {
    color: axiomTokens.color.interactive.accent.default,
    fontWeight: axiomTokens.font.weight.bold,
    fontSize: axiomTokens.font.size.xs,
    lineHeight: axiomTokens.font.lineHeight.xs,
    letterSpacing: axiomTokens.font.letterSpacing.sm,
    marginTop: axiomTokens.spacing[2],
  },

  [theme.breakpoints.down('md')]: {
    '& h2': {
      fontSize: axiomTokens.font.size.sm,
      lineHeight: axiomTokens.font.lineHeight.sm,
    },

    '& a': {
      marginTop: '0',
    },
  },
}));

export const Content = styled('div')(() => ({
  margin: `0 ${axiomTokens.spacing[6]} ${axiomTokens.spacing[4]}`,
  padding: axiomTokens.spacing[4],
  backgroundColor: axiomTokens.color.surface.subtle.default,
  border: `${axiomTokens.border.width.xs} solid ${axiomTokens.color.border.subtle}`,
  boxShadow:
    '0px 2px 6px -4px rgba(0, 0, 0, 0.04), 0px 32px 40px -16px rgba(0, 0, 0, 0.04)',
  borderRadius: axiomTokens.border.radius.sm,
}));

export const BackArea = styled('div')(({ theme }) => ({
  padding: `${axiomTokens.spacing[4]} ${axiomTokens.spacing[6]} ${axiomTokens.spacing[4]}`,
  width: '100%',
  borderTop: `${axiomTokens.border.width.xs} solid ${axiomTokens.color.others.divider}`,

  [theme.breakpoints.down('md')]: {
    padding: `0 ${axiomTokens.spacing[6]}`,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

export const BackButton = styled(Button)(() => ({
  color: axiomTokens.color.typeface.pure,

  '&:hover': {
    backgroundColor: axiomTokens.color.interactive.subtle.hovered,
  },
}));
