// External
import { EditorState } from 'draft-js';

// Internal
import { editorOptionsText } from 'ui/new/editor/constants';
import * as types from './types';
import { EmailFlowActionsReturn } from './emailFlowTypes';

export const initialState = {
  isFetching: false,
  emailFlows: [],
  errors: [],
  physicianIdSelected: 0,
  activeComponentsCard: undefined,
  emailFlowUpdate: {
    isFetching: false,
    emailFlow: undefined,
    errors: [],
  },
  emailFlowDetails: {
    isFetching: false,
    emailFlow: undefined,
    errors: [],
  },
  emailFlowCreate: {
    isFetching: false,
    emailFlow: undefined,
    errors: [],
  },
  emailFlowDelete: {
    isFetching: false,
    errors: [],
  },
  physician: {
    isFetching: false,
    physicians: [],
    errors: [],
  },
  currentComponent: undefined,
  editor: {
    editorState: EditorState.createEmpty(),
    optionsFormatText: editorOptionsText,
  },
  componentDelete: {
    isFetching: false,
    component: undefined,
    errors: [],
  },
  componentUpdate: {
    isFetching: false,
    component: undefined,
    errors: [],
  },
  componentCreate: {
    isFetching: false,
    component: undefined,
    errors: [],
  },
  activeEmailFlow: false,
  activeEmailFlowCard: undefined,
  sendTestEmail: {
    isFetching: false,
    errors: [],
  },
};

export type AutomatedEmailFlowState = typeof initialState;

const automatedEmails = (
  state = initialState,
  action: EmailFlowActionsReturn,
): AutomatedEmailFlowState => {
  switch (action.type) {
    case types.FETCH_LIST.ACTION:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      };
    case types.FETCH_LIST.SUCCESS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
        emailFlows: action.payload.emailFlows,
        errors: action.payload.errors,
      };
    case types.FETCH_LIST.FAILURE:
      return {
        ...state,
        isFetching: action.payload.isFetching,
        errors: action.payload.errors,
      };
    case types.FETCH_CREATE.ACTION:
      return {
        ...state,
        emailFlowCreate: {
          isFetching: action.payload.emailFlowCreate.isFetching,
          errors: state.emailFlowCreate.errors,
          emailFlow: action.payload.emailFlowCreate.emailFlow,
        },
      };
    case types.FETCH_CREATE.SUCCESS:
      return {
        ...state,
        emailFlowCreate: {
          isFetching: action.payload.emailFlowCreate.isFetching,
          emailFlow: action.payload.emailFlowCreate.emailFlow,
          errors: state.emailFlowCreate.errors,
        },
      };
    case types.FETCH_CREATE.FAILURE:
      return {
        ...state,
        emailFlowCreate: {
          isFetching: action.payload.emailFlowCreate.isFetching,
          errors: action.payload.emailFlowCreate.errors,
          emailFlow: action.payload.emailFlowCreate.emailFlow,
        },
      };
    case types.FETCH_UPDATE.ACTION:
      return {
        ...state,
        emailFlowUpdate: {
          isFetching: action.payload.emailFlowUpdate.isFetching,
          errors: action.payload.emailFlowUpdate.errors,
          emailFlow: action.payload.emailFlowUpdate.emailFlow,
        },
      };
    case types.FETCH_UPDATE.SUCCESS:
      return {
        ...state,
        emailFlowUpdate: {
          isFetching: action.payload.emailFlowUpdate.isFetching,
          emailFlow: action.payload.emailFlowUpdate.emailFlow,
          errors: action.payload.emailFlowUpdate.errors,
        },
      };
    case types.FETCH_UPDATE.FAILURE:
      return {
        ...state,
        emailFlowUpdate: {
          isFetching: action.payload.emailFlowUpdate.isFetching,
          errors: action.payload.emailFlowUpdate.errors,
          emailFlow: action.payload.emailFlowUpdate.emailFlow,
        },
      };
    case types.FETCH_PHYSICIANS.ACTION:
      return {
        ...state,
        physician: {
          isFetching: action.payload.physician.isFetching,
          errors: action.payload.physician.errors,
          physicians: action.payload.physician.physicians,
        },
      };
    case types.FETCH_PHYSICIANS.SUCCESS:
      return {
        ...state,
        physician: {
          isFetching: action.payload.physician.isFetching,
          physicians: action.payload.physician.physicians,
          errors: action.payload.physician.errors,
        },
      };
    case types.FETCH_PHYSICIANS.FAILURE:
      return {
        ...state,
        physician: {
          isFetching: action.payload.physician.isFetching,
          errors: action.payload.physician.errors,
          physicians: action.payload.physician.physicians,
        },
      };
    case types.FETCH_DETAILS.ACTION:
      return {
        ...state,
        emailFlowDetails: {
          isFetching: action.payload.emailFlowDetails.isFetching,
          errors: action.payload.emailFlowDetails.errors,
          emailFlow: action.payload.emailFlowDetails.emailFlow,
        },
      };
    case types.FETCH_DETAILS.SUCCESS:
      return {
        ...state,
        emailFlowDetails: {
          isFetching: action.payload.emailFlowDetails.isFetching,
          emailFlow: action.payload.emailFlowDetails.emailFlow,
          errors: action.payload.emailFlowDetails.errors,
        },
      };
    case types.FETCH_DETAILS.FAILURE:
      return {
        ...state,
        emailFlowDetails: {
          isFetching: action.payload.emailFlowDetails.isFetching,
          errors: action.payload.emailFlowDetails.errors,
          emailFlow: action.payload.emailFlowDetails.emailFlow,
        },
      };
    case types.EDIT_EMAIL_FLOW.SET_ACTIVE_CARD:
      return {
        ...state,
        activeComponentsCard: action.payload.activeComponentsCard,
      };
    case types.EDIT_EMAIL_FLOW.SET_CURRENT_COMPONENT:
      return {
        ...state,
        currentComponent: action.payload.currentComponent,
      };
    case types.EDIT_ACTIVE_EMAIL_FLOW.ACTION:
      return {
        ...state,
        activeEmailFlow: action.payload.activeEmailFlow,
      };
    case types.FETCH_SEND_TEST_EMAIL.ACTION:
      return {
        ...state,
        sendTestEmail: {
          isFetching: true,
          errors: [],
        },
      };
    case types.FETCH_SEND_TEST_EMAIL.SUCCESS:
      return {
        ...state,
        sendTestEmail: {
          isFetching: false,
          errors: [],
        },
      };
    case types.FETCH_SEND_TEST_EMAIL.FAILURE:
      return {
        ...state,
        sendTestEmail: {
          isFetching: false,
          errors: action.payload.sendTestEmail.errors,
        },
      };
    case types.EDITOR_TEXT.UPDATE_EDITOR_STATE:
      return {
        ...state,
        editor: {
          editorState: action.payload.editor.editorState,
          optionsFormatText: state.editor.optionsFormatText,
        },
      };
    case types.EDITOR_TEXT.FORMAT_TEXT:
      return {
        ...state,
        editor: {
          editorState: state.editor.editorState,
          optionsFormatText: action.payload.editor.optionsFormatText,
        },
      };
    case types.FETCH_CREATE_COMPONENT.ACTION:
      return {
        ...state,
        componentCreate: {
          isFetching: action.payload.componentCreate.isFetching,
          errors: state.componentCreate.errors,
          component: action.payload.componentCreate.component,
        },
      };
    case types.FETCH_CREATE_COMPONENT.SUCCESS:
      return {
        ...state,
        componentCreate: {
          isFetching: action.payload.componentCreate.isFetching,
          component: action.payload.componentCreate.component,
          errors: state.componentCreate.errors,
        },
      };
    case types.FETCH_CREATE_COMPONENT.FAILURE:
      return {
        ...state,
        componentCreate: {
          isFetching: action.payload.componentCreate.isFetching,
          errors: action.payload.componentCreate.errors,
          component: action.payload.componentCreate.component,
        },
      };
    case types.FETCH_UPDATE_COMPONENT.ACTION:
      return {
        ...state,
        componentUpdate: {
          isFetching: action.payload.componentUpdate.isFetching,
          errors: state.componentUpdate.errors,
          component: action.payload.componentUpdate.component,
        },
      };
    case types.FETCH_UPDATE_COMPONENT.SUCCESS:
      return {
        ...state,
        componentUpdate: {
          isFetching: action.payload.componentUpdate.isFetching,
          component: action.payload.componentUpdate.component,
          errors: state.componentUpdate.errors,
        },
      };
    case types.FETCH_UPDATE_COMPONENT.FAILURE:
      return {
        ...state,
        componentUpdate: {
          isFetching: action.payload.componentUpdate.isFetching,
          errors: action.payload.componentUpdate.errors,
          component: action.payload.componentUpdate.component,
        },
      };
    case types.FETCH_DELETE_COMPONENT.ACTION:
      return {
        ...state,
        componentDelete: {
          isFetching: action.payload.componentDelete.isFetching,
          errors: state.componentDelete.errors,
          component: action.payload.componentDelete.component,
        },
      };
    case types.FETCH_DELETE_COMPONENT.SUCCESS:
      return {
        ...state,
        componentDelete: {
          isFetching: action.payload.componentDelete.isFetching,
          component: action.payload.componentDelete.component,
          errors: state.componentDelete.errors,
        },
      };
    case types.FETCH_DELETE_COMPONENT.FAILURE:
      return {
        ...state,
        componentDelete: {
          isFetching: action.payload.componentDelete.isFetching,
          errors: action.payload.componentDelete.errors,
          component: action.payload.componentDelete.component,
        },
      };
    case types.FETCH_DETAILS_CLEAR.ACTION:
      return {
        ...state,
        emailFlowDetails: {
          isFetching: action.payload.emailFlowDetails.isFetching,
          emailFlow: action.payload.emailFlowDetails.emailFlow,
          errors: state.emailFlowDetails.errors,
        },
      };
    case types.CLEAR_ERRORS_COMPONENT.ACTION:
      return {
        ...state,
        componentUpdate: {
          isFetching: action.payload.componentUpdate.isFetching,
          errors: action.payload.componentUpdate.errors,
          component: state.componentUpdate.component,
        },
        componentCreate: {
          isFetching: action.payload.componentCreate.isFetching,
          errors: action.payload.componentCreate.errors,
          component: state.componentCreate.component,
        },
        componentDelete: {
          isFetching: action.payload.componentDelete.isFetching,
          errors: action.payload.componentDelete.errors,
          component: state.componentDelete.component,
        },
      };
    case types.SET_ACTIVE_EMAIL_FLOW_CARD:
      return {
        ...state,
        activeEmailFlowCard: action.payload.activeEmailFlowCard,
      };
    default:
      return state;
  }
};

export default automatedEmails;
