export const TEXT_IS_SUBSCRIBER = 'CONFIRMAR ASSINATURA';
export const TEXT_NON_ADMIN = 'ENVIAR E-MAIL PARA ADMINISTRADOR DA CONTA';
export const TEXT_IS_NOT_SUBSCRIBER = 'CONHECER';
export const TEXT_AGREE = 'ENTENDI';
export enum VIEWMODE {
  paymentSlip = 1,
  nonAdmin = 2,
  productInfo = 3,
  addonTrial = 4,
}
export const URL_INFO_MONTHLY =
  'https://suporte.iclinic.com.br/como-e-cobranca-mensal-do-iclinic';
export const URL_INFO_ANNUAL =
  'https://suporte.iclinic.com.br/como-e-a-cobranca-anual-do-iclinic';
export const URL_ICLINIC_ASSIST =
  'https://ag9hf7cb1ls.typeform.com/to/VwVAe7TX';
