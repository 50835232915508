import { Plan } from './types';

export const plansList: Plan[] = [
  {
    messagesAmount: 50,
    value: '27,90',
  },
  {
    messagesAmount: 100,
    value: '55,90',
  },
  {
    messagesAmount: 150,
    value: '107,90',
  },
];

export const ICLINIC_APP_BREAKPOINT = '(min-width:1023px)';

export const FAKEDOOR_STORAGE_ITEM = 'iclinic.additional.params';
