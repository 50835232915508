import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export const useStyles = makeStyles(() => ({
  helpWrap: {
    display: 'flex',
  },
  helpIcon: {
    color: tokens.color.neutral[500],
    fontSize: '20px',
    marginRight: tokens.spacing.base,
  },
  checkbox: {
    margin: '-9px 0',
  },
  checkboxLabel: {
    marginBottom: 0,
  },
}));
