import React, { useEffect, useState } from 'react';
import { Alert, MaterialIcons, Snackbar } from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';

import { RequestStatus } from 'shared/constants/State';
import {
  getSubmitConfirmationTemplateMessageStatus,
  getSubmitConfirmationErrorMessage,
} from '../../../state/scheduleConfirmation/personalization/selectors';
import { cleanSubmitMessageConfirmation } from 'features/messaging/state/scheduleConfirmation/personalization';

const { Warning, CheckCircleOutline } = MaterialIcons;

const SnackbarMessages = () => {
  const status = useSelector(getSubmitConfirmationTemplateMessageStatus);
  const message = useSelector(getSubmitConfirmationErrorMessage);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (message) {
      setOpen(true);
    }

    return () => {
      if (message) {
        dispatch(cleanSubmitMessageConfirmation());
      }
    };
  }, [message, dispatch]);

  const handleCloseMessage = () => {
    setOpen(false);
    dispatch(cleanSubmitMessageConfirmation());
  };

  if (!message) return null;

  const hasSubmitError = status === RequestStatus.Error;

  return (
    <Snackbar
      open={open}
      onClose={handleCloseMessage}
      severity={hasSubmitError ? 'error' : 'success'}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert
        severity={hasSubmitError ? 'error' : 'success'}
        icon={hasSubmitError ? <Warning /> : <CheckCircleOutline />}
        message={message}
      />
    </Snackbar>
  );
};

export default SnackbarMessages;
