import { Body, Heading, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, shadow, spacing, font } = tokens;

export const Card = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  border: `${border.width.hairline} solid ${color.neutral[50]}`,
  backgroundColor: color.neutral[0],
  borderRadius: border.radius.sm,

  '&:hover': {
    boxShadow: shadow.level[4],
  },
});

export const CardHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: color.primary.main,
  height: 56,
  padding: `${spacing.sm} 0`,
  borderTopLeftRadius: border.radius.sm,
  borderTopRightRadius: border.radius.sm,

  [theme.breakpoints.up('sm')]: {
    height: 80,
    padding: `${spacing.md} 0`,
  },
}));

export const CardContent = styled('div')(({ theme }) => ({
  padding: spacing.sm,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexGrow: 1,

  [theme.breakpoints.up('sm')]: {
    padding: spacing.md,
  },
}));

export const CardContentTitle = styled(Heading)({
  marginBottom: spacing.sm,
});

export const Medicaments = styled(Body)(({ theme }) => ({
  height: 72,
  display: '-webkit-box',
  '-webkit-line-clamp': 3,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: spacing.sm,
  fontSize: font.size.xs,

  [theme.breakpoints.up('sm')]: {
    fontSize: font.size.sm,
  },
}));

export const CardActionsWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row-reverse',
});

export const BodyDate = styled(Body)(({ theme }) => ({
  marginBottom: spacing.sm,
  fontSize: font.size.xxs,

  [theme.breakpoints.up('sm')]: {
    fontSize: font.size.xs,
  },
}));
