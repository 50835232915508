import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { Response } from '@types';

import * as actions from '.';
import * as api from '../../../services';
import { getErrorCode, replaceCharactersByTags } from '../../../utils';
import { messagesByCode, successMessage } from '../../../constants';
import {
  normalizePreviewTemplateMessageData,
  normalizeScheduleTemplateMessage,
} from './normalizer';
import {
  PreviewMessage,
  TemplateKind,
} from 'features/messaging/services/types';

type PayloadFetchReminderTemplateMessage = ReturnType<
  typeof actions.fetchReminderTemplateMessage
>;
type PayloadSubmitReminderTemplateMessage = ReturnType<
  typeof actions.submitReminderTemplateMessage
>;
type PayloadFetchReminderPreviewMessage = ReturnType<
  typeof actions.fetchReminderPreviewMessage
>;

export function* fetchReminderTemplateMessageWorker({
  payload: physicianId,
}: PayloadFetchReminderTemplateMessage) {
  try {
    yield put(actions.fetchReminderTemplateMessageStart());

    const { data: templateMessage } = yield call(
      api.getReminderMessageTemplate,
      physicianId,
    );

    yield put(actions.fetchReminderTemplateMessageSuccess(templateMessage));
  } catch {
    yield put(actions.fetchReminderTemplateMessageFailure());
  }
}

export function* submitReminderTemplateMessageWorker({
  payload,
}: PayloadSubmitReminderTemplateMessage) {
  try {
    yield put(actions.submitReminderTemplateMessageStart());
    const { physicianId, multiplesScheduleTemplateMessage } = payload;

    const templateMessage = normalizeScheduleTemplateMessage(
      multiplesScheduleTemplateMessage,
    );

    const { data: updatedTemplate } = yield call(
      api.putReminderMessageTemplate,
      physicianId,
      templateMessage,
    );

    yield put(actions.fetchReminderTemplateMessageSuccess(updatedTemplate));
    yield put(actions.submitReminderTemplateMessageSuccess(successMessage));
  } catch (error) {
    const errorCode = getErrorCode(error as AxiosError);
    const errorMessage = messagesByCode[errorCode];

    yield put(actions.submitReminderTemplateMessageFailure(errorMessage));
  }
}

export function* fetchReminderPreviewMessageWorker({
  payload: { physicianId, template },
}: PayloadFetchReminderPreviewMessage) {
  try {
    yield put(actions.fetchReminderPreviewMessageStart());

    const templateMessage = normalizePreviewTemplateMessageData(template);

    const { data }: Response<PreviewMessage> = yield call(
      api.getPreviewMessage,
      {
        kind: TemplateKind.REMINDER,
        physicianId,
        template: templateMessage,
      },
    );

    const replacedMessage = replaceCharactersByTags(data.message);

    yield put(actions.fetchReminderPreviewMessageSuccess(replacedMessage));
  } catch {
    yield put(actions.fetchReminderPreviewMessageFailure());
  }
}

export default function* personalizationSagas() {
  yield takeLatest(
    actions.fetchReminderTemplateMessage,
    fetchReminderTemplateMessageWorker,
  );
  yield takeLatest(
    actions.submitReminderTemplateMessage,
    submitReminderTemplateMessageWorker,
  );
  yield takeLatest(
    actions.fetchReminderPreviewMessage,
    fetchReminderPreviewMessageWorker,
  );
}
