import { ItemImg } from 'state/records/attachments/imageComparison/types';

export const getImages = (itemImg: ItemImg) => (
  new Promise((resolve, reject) => {
    if (!itemImg) reject();
    const image = new Image();
    image.crossOrigin = '';
    image.src = `${itemImg.max_size_image}?${Math.round(new Date().getTime() / 1000)}`;
    image.onload = function load() {
      resolve(image);
    };
  })
);

export const mergeImage = async (canvas, imgs: ItemImg[]) => {
  const image1 = await getImages(imgs[0]);
  const image2 = await getImages(imgs[1]);
  if (!image1 || !image2) throw new Error('Error load image');
  /* eslint-disable no-param-reassign */
  canvas.width = image1.width + image2.width;
  canvas.height = image1.height > image2.height ? image1.height : image2.height;
  canvas.getContext('2d').drawImage(image1, 0, 0);
  canvas.getContext('2d').drawImage(image2, image1.width + 1, 0);
};
