import { GuideSADTForm } from 'features/tissInvoicing/types';
import { StoreState } from 'state/rootReducer';

export const blankSessionOption = {
  sessionOrder: null,
  sessionDay: null,
  sessionDate: '',
};

export const getGuideDataState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.sadt.guide;

export const getEventState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.sadt.event;

export const getGuideState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.lot.listGuides.availableGuides;

export const getLoadingState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.sadt.loading;

export const getSnackbarProps = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.sadt.snackbar;

export const getSadtFormInitalData = ({
  tissInvoicing,
}: StoreState): GuideSADTForm => tissInvoicing.sadt.form.data;

export const getPrintState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.sadt.print;

export const getExpensesErrors = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.sadt.form.expensesErrors;

export const getClinicHealth = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.sadt.clinicHealth;

export const getPatientState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.sadt.patient;
