import axios from 'axios';

import { PatientParameters, LastEventToday } from '../types';
import {
  interceptorRequestAuthentication,
  interceptorResponseTransformData,
  interceptorResponseErrorsPromise,
} from 'services/iclinic/interceptors';

const apiRecords = axios.create({
  baseURL: process.env.ICLINIC_API_URL,
});

apiRecords.interceptors.request.use(interceptorRequestAuthentication);
apiRecords.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrorsPromise,
);

export const getIsLastEventToday = (
  patient: PatientParameters,
): Promise<{ isLastEventToday: LastEventToday }> =>
  apiRecords.get(
    `/events/event/is-last-event-today/${patient.clinicId}/${patient.physicianId}/${patient.eventId}/`,
  );
