import { MaterialUICore, axiomTokens, Button } from '@iclinic/design-system';

const { styled } = MaterialUICore;
type Theme = MaterialUICore.Theme;

export const Container = styled('div')({
  position: 'relative',
});

interface WrapperProps {
  theme: Theme;
  serviceInitialized: boolean;
}

export const Wrapper = styled('div')(
  ({ theme, serviceInitialized }: WrapperProps) => ({
    position: 'fixed',
    zIndex: 1,
    bottom: axiomTokens.spacing[5],
    right: '92px',
    boxShadow: axiomTokens.shadow.level[6],

    [theme.breakpoints.down('md')]: {
      bottom: '80px',
      right: serviceInitialized ? '92px' : '20px',
    },
  }),
);

export const ButtonCTA = styled(Button)(() => ({
  backgroundColor: axiomTokens.color.branding[140],
  color: axiomTokens.color.branding[50],

  '&:hover': {
    backgroundColor: axiomTokens.color.interactive.accent.hovered,
    color: axiomTokens.color.branding[160],
    '& path': {
      fill: axiomTokens.color.branding[160],
    },
  },

  '& span': {
    marginRight: '0px',
  },
}));
