import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  labelCheckbox: {
    color: theme.palette.text.secondary,
    fontSize: '0.875rem',
  },
  addIcon: {
    color: theme.palette.grey[500],
  },
}));
