// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import Button from 'ui/new/button';
// Styles
import style from './EditTitle.scss';

const EditTitle = ({ onClickSave, onClickCancel, invalid, isNew }) => (
  <div className={style.header}>
    <span>{isNew ? 'Criar campanha' : 'Editar campanha'}</span>
    <div className={style.header__actions}>
      <Button onClick={onClickCancel} type="light">
        CANCELAR
      </Button>
      <Button onClick={onClickSave} disabled={invalid} type="success">
        SALVAR E SAIR
      </Button>
    </div>
  </div>
);

EditTitle.propTypes = {
  invalid: PropTypes.bool.isRequired,
  isNew: PropTypes.bool.isRequired,
  onClickCancel: PropTypes.func.isRequired,
  onClickSave: PropTypes.func.isRequired,
};

export default EditTitle;
