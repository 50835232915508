import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@iclinic/design-system';

import {
  Avatar,
  Content,
  Title,
  InfoContent,
  PatientContent,
  PatiendData,
} from './CardPatient.styles';
import InfoRow from './components/InfoRow';
import ActionButtons from './components/ActionButtons';
import { getCurrentEventAndPatientId } from 'state/records/selectors';
import { actions } from './state';
import {
  getHasError,
  getPatientHeader as getPatientHeaderSelector,
} from './state/selectors';
import { getEveryFirstLetter } from 'features/records/utils';
import { PatientRelatives } from './components/PatientRelatives';
import { ErrorBoundary } from '../errorBoundary';

export const CardPatient = () => {
  const dispatch = useDispatch();
  const { patientId } = useSelector(getCurrentEventAndPatientId);
  const {
    age,
    insuranceName,
    firstAppointment,
    patientRelated,
    name,
    civilName,
    urlImage,
  } = useSelector(getPatientHeaderSelector);

  const hasError = useSelector(getHasError);

  useEffect(() => {
    if (!patientId) {
      return;
    }
    dispatch(actions.getCardPatient(Number(patientId)));
  }, [patientId, dispatch]);

  const onRetry = useCallback(() => {
    dispatch(actions.getCardPatient(Number(patientId)));
  }, [patientId, dispatch]);

  const currentName = name || civilName;
  const currenInsuranceName = insuranceName || 'Não cadastrado';
  const currentFirstAppointment = firstAppointment || 'Sem registro';

  const hasRelative = patientRelated?.length > 0;

  return (
    <ErrorBoundary
      isError={hasError}
      onRetry={onRetry}
      message="Não conseguimos carregar o card do paciente. Estamos trabalhando para a normalização."
      isButtonRetryVisible
      buttonText="Tentar novamente"
    >
      <Content>
        <PatientContent>
          <Avatar alt={currentName} src={urlImage} size="lg">
            {getEveryFirstLetter(currentName).slice(0, 2).toUpperCase()}
          </Avatar>
          <Box width="100%">
            <Title variant="sm">{currentName}</Title>
            <InfoContent>
              <PatiendData>
                <InfoRow label="Idade" value={age} />
                <InfoRow label="Convênio" value={currenInsuranceName} />
                <InfoRow
                  label="Primeira consulta"
                  value={currentFirstAppointment}
                />
              </PatiendData>
              {hasRelative && (
                <PatientRelatives patientRelated={patientRelated} />
              )}
            </InfoContent>
          </Box>
        </PatientContent>
        <ActionButtons />
      </Content>
    </ErrorBoundary>
  );
};
