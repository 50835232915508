// Internal
import { reducerMerge } from 'shared/utils/reducer';
import * as types from './types';

export const initialState = {
  errors: [],
  isFetching: false,
  unsubscribe: {
    recipient: undefined,
    unsubscribeToken: undefined,
    type: undefined,
  },
  feedback: {
    didProvideReason: false,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_UNSUBSCRIBE_USER.ACTION:
    case types.FETCH_UNSUBSCRIBE.ACTION:
    case types.FETCH_UNSUBSCRIBE.SUCCESS:
    case types.FETCH_UNSUBSCRIBE.FAILURE:
      return reducerMerge({}, state, action.payload);
    default:
      return state;
  }
};
