import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TextTemplate, TextTemplateState } from './types';
import { getActionTypes } from 'shared/utils/getActionTypes';

export const initialState: TextTemplateState = {
  title: '',
  text: '',
  isModalSaveTemplateOpen: false,
  isDrawerUseTemplateOpen: false,
  templateList: [],
  uniqueId: 0,
  pageCount: 1,
  currentPage: 1,
};

const storeName = '@webapp/textTemplate';

const textTemplateSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    openTextTemplateModal: (state, action: PayloadAction<string>) => ({
      ...state,
      isModalSaveTemplateOpen: true,
      text: action.payload,
    }),
    closeTextTemplateModal: (state) => ({
      ...state,
      isModalSaveTemplateOpen: false,
    }),
    saveTextTemplateSuccess: (state) => ({
      ...state,
      isModalSaveTemplateOpen: false,
      text: '',
      title: '',
    }),
    saveTextTemplateFailed: (state) => ({
      ...state,
      isModalSaveTemplateOpen: false,
    }),
    openDrawerUseTextTemplate: (state, action: PayloadAction<number>) => ({
      ...state,
      uniqueId: action.payload,
    }),
    openDrawerUseTemplateSuccess: (
      state,
      action: PayloadAction<{
        templateList: TextTemplate[];
        pageCount: number;
      }>,
    ) => ({
      ...state,
      templateList: action.payload.templateList,
      pageCount: action.payload.pageCount,
      isDrawerUseTemplateOpen: true,
    }),
    closeDrawerUseTextTemplate: (state) => ({
      ...state,
      isDrawerUseTemplateOpen: false,
      uniqueId: 0,
    }),
    deleteTextTemplateSuccess: (
      state,
      { payload: textTemplateId }: PayloadAction<number>,
    ) => ({
      ...state,
      templateList: state.templateList?.filter(
        (item) => item.id !== textTemplateId,
      ),
    }),
    setTextTemplateChosen: (
      state,
      action: PayloadAction<TextTemplateState>,
    ) => ({
      ...state,
      uniqueId: action.payload.uniqueId,
      text: action.payload.text,
    }),
    setCurrentPage: (state, action: PayloadAction<number>) => ({
      ...state,
      currentPage: action.payload,
    }),
    setCurrentPageSuccess: (state, action: PayloadAction<TextTemplate[]>) => ({
      ...state,
      templateList: action.payload,
    }),
  },
});

export const actions = {
  ...textTemplateSlice.actions,
  openDrawerUseTemplateFailed: createAction(
    `${storeName}/openDrawerUseTemplateFailed`,
  ),
  setCurrentPageFailed: createAction(`${storeName}/setCurrentPageFailed`),
  saveTextTemplate: createAction<string>(`${storeName}/saveTextTemplate`),
  deleteTextTemplate: createAction<number>(`${storeName}/deleteTextTemeplate`),
  deleteTextTemplateFailed: createAction(
    `${storeName}/deleteTextTemeplateFailed`,
  ),
};

export const actionTypes = getActionTypes(actions, textTemplateSlice.name);

export default textTemplateSlice.reducer;
