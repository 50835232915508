import React from 'react';
import { MaterialIcons } from '@iclinic/design-system';

import ToggleButton from 'features/video-conference/components/TeamComponents/ToggleButton';
import { buttonsTitle } from 'features/video-conference/constants';
import { useCaptionContext } from 'features/video-conference/state/captionContext';

const { ClosedCaptionRounded, ClosedCaptionDisabledRounded } = MaterialIcons;

interface Props {
  withLabel?: boolean;
}

export default function CaptionButton({ withLabel = false }: Props) {
  const { isCaptionActive, setIsCaptionActive } = useCaptionContext();

  const title = isCaptionActive
    ? buttonsTitle.CAPTION_DISABLED
    : buttonsTitle.CAPTION_ENABLED;

  return (
    <ToggleButton
      onClick={() => setIsCaptionActive(!isCaptionActive)}
      switchedOn={!isCaptionActive}
      variant="info"
      updateOn={false}
      label={withLabel ? 'Legendas' : ''}
      title={title}
      icon={
        isCaptionActive ? (
          <ClosedCaptionRounded />
        ) : (
          <ClosedCaptionDisabledRounded />
        )
      }
      dataGa="telemedicine_subtitle"
      dataSp="telemedicine_subtitle"
    />
  );
}
