// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import Button from 'ui/new/button';
// Styles
import style from './ListTitle.scss';

const ListTitle = ({ onOpenModal }) => (
  <div className={style.header}>
    <span>Campanhas de e-mail</span>
    <div className={style.header__actions}>
      <Button onClick={onOpenModal} type="primary">
        CRIAR CAMPANHA DE E-MAIL
      </Button>
    </div>
  </div>
);

ListTitle.propTypes = {
  onOpenModal: PropTypes.func.isRequired,
};

export default ListTitle;
