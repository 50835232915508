import React from 'react';

import { Body, Box, tokens } from '@iclinic/design-system';
import { UpgradeCallContent, UserKinds } from './types';

export const upgradeCallContent: Map<string, UpgradeCallContent> = new Map([
  [
    UserKinds.physician,
    {
      starter: {
        title:
          'Quer saber como poupar tempo da sua secretária e reduzir o não comparecimento de pacientes?',
        imagePath: 'upgrade.plus',
        planToUpgrade: 'plus',
        subtitle: (
          <Body variant="sm">
            Veja as vantagens do nosso <strong>plano Plus</strong> e atenda mais
            pacientes:
          </Body>
        ),
        list: [
          'Lembre e Confirme Consulta por Whatsapp ou SMS;',
          'Assine o prontuário e exames digitalmente;',
          'Faturamento TISS mais prático e rápido;',
          'Chat interno para todos os profissionais do consultório.',
        ],
      },
      plus: {
        title:
          'Já pensou em ter mais controle do que vai receber antes do final do mês?',
        imagePath: 'upgrade.pro',
        planToUpgrade: 'pro',
        subtitle: (
          <Body variant="sm">
            Veja as vantagens do nosso <strong>plano Pro</strong> e ganhe mais
            organização e <strong>previsibilidade dos seus ganhos:</strong>
          </Body>
        ),
        list: [
          'Controle Financeiro;',
          'Relatórios Financeiros;',
          'Repasses;',
          'Controle de estoque.',
        ],
      },
      pro: {
        title: 'Buscando aumentar o volume de pacientes?',
        imagePath: 'upgrade.premium',
        planToUpgrade: 'premium',
        subtitle: (
          <Body variant="sm">
            Veja as vantagens do nosso <strong>plano Premium!</strong> Crie uma
            relação de <strong>fidelização</strong> com seus pacientes, e{' '}
            <strong>atenda mais, de onde estiver:</strong>
          </Body>
        ),
        list: [
          'Teleconsultas ilimitadas;',
          'E-mail Marketing personalizado;',
          'E-mail Marketing de Retorno automático e customizável.',
        ],
      },
    },
  ],
  [
    UserKinds.receptionist,
    {
      starter: {
        title: 'Já pensou em economizar seu tempo com a marcação de consultas?',
        imagePath: 'upgrade.plus',
        planToUpgrade: 'plus',
        innerComponent: (
          <>
            <Body variant="sm">
              Conheça a nossa solução de{' '}
              <strong>Lembrete e Confirmação de consulta por Whatsapp!</strong>
            </Body>
            <Box mt={tokens.spacing.md}>
              <Body variant="sm">
                Com 1 clique você envia lembretes para os pacientes e ganha mais
                tempo para focar em outras demandas do consultório.
              </Body>
            </Box>
            <Box mt={tokens.spacing.md}>
              <Body variant="sm">
                Gostou? Converse com o seu médico e apresente o nosso{' '}
                <strong>plano Plus.</strong>
              </Body>
            </Box>
          </>
        ),
      },
      plus: {
        title: 'Quer economizar tempo em tarefas cansativas do dia a dia?',
        imagePath: 'upgrade.pro',
        planToUpgrade: 'pro',
        innerComponent: (
          <>
            <Body variant="sm">
              Conheça a nossa solução de <strong>Controle Financeiro!</strong>
            </Body>
            <Box mt={tokens.spacing.md}>
              <Body variant="sm">
                Se você faz o controle de{' '}
                <strong>despesas e recebimentos</strong> do seu consultório,
                centralize toda a organização financeiro no iClinic, sem
                precisar se entender com diversas planilhas ou ter trabalho
                dobrado.
              </Body>
            </Box>
            <Box mt={tokens.spacing.md}>
              <Body variant="sm">
                Gostou? Converse com o seu médico e apresente o nosso{' '}
                <strong>plano Pro.</strong>
              </Body>
            </Box>
          </>
        ),
      },
      pro: {
        title:
          'Já pensou em conseguir trazer mais pacientes e fidelizar os antigos para o seu médico?',
        imagePath: 'upgrade.premium',
        planToUpgrade: 'premium',
        innerComponent: (
          <>
            <Body variant="sm">
              Conheça a nossa solução de{' '}
              <strong>Campanhas de E-mails Personalizáveis!</strong>
            </Body>
            <Box mt={tokens.spacing.md}>
              <Body variant="sm">
                Você automatiza e-mails de retorno de pacientes, orientações
                para consultas e exames e otimiza o seu trabalho, fazendo com
                que eles retornem mais e gerem mais ganhos.
              </Body>
            </Box>
            <Box mt={tokens.spacing.md}>
              <Body variant="sm">
                Gostou? Converse com o seu médico e apresente o nosso{' '}
                <strong>plano Premium</strong>
              </Body>
            </Box>
          </>
        ),
      },
    },
  ],
]);
