import {
  Dialog as DSDialog,
  DialogContent as DSDialogContent,
  DialogActions as DSDialogActions,
  MaterialUICore,
  axiomTokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, spacing } = axiomTokens;

export const Dialog = styled(DSDialog)(() => ({
  '& .MuiDialog-paper': {
    width: '688px',
    height: '488px',
  },
}));

export const DialogContent = styled(DSDialogContent)(() => ({
  paddingTop: spacing[1],
}));

export const DialogActions = styled(DSDialogActions)(({ theme }) => ({
  padding: spacing[3],
  borderTop: `${border.width.xs} solid ${color.border.subtle}`,
  justifyContent: 'space-between',

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    gap: 0,
  },
}));
