import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.grey[50]}`,
    borderRadius: '8px',
    '& .MuiListItemIcon-root': {
      marginRight: theme.spacing(1),
      minWidth: 0,
      '& > .MuiSvgIcon-root': {
        fontSize: 18,
      },
    },
    '& .MuiListItemText-root span': {
      fontSize: theme.typography.fontSize,
      fontWeight: 500,
    },
  },
}));
