import { Autocomplete, TextField } from '@iclinic/design-system';
import { useFormikContext } from 'formik';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { debounce } from 'shared/hooks';
import { getExpenseAutocompleteState } from 'features/tissInvoicing/state/expense/selectors';
import { AppointmentGuideFormValues } from 'features/tissInvoicing/types/appointmentGuide';
import { actions as autocompleteActions } from 'features/tissInvoicing/state/expense';
import { IAutocompleteOption } from '../../expense/interfaces/AutocompleteOption';

const getEndpointTiss = {
  endpoint: '/domains/tuss-procedure',
  name: 'procedureOptions',
  isTissApi: true,
};

export default ({
  label = '21 - Código do procedimento',
  required = false,
}: {
  label?: string;
  required?: boolean;
}) => {
  const dispatch = useDispatch();

  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext<AppointmentGuideFormValues>();

  const { procedureOptions = [] } = useSelector(getExpenseAutocompleteState);

  const renderOption = (option: IAutocompleteOption) => {
    if (typeof option === 'string') return option;

    return option?.title || '';
  };

  const renderOptionFn = (option: IAutocompleteOption) => `${option?.title}`;

  const handleFetchProcedures = useCallback(
    (value?: string) => {
      dispatch(
        autocompleteActions.fetchExpenseAutocomplete({
          ...getEndpointTiss,
          params: {
            tuss_table: values.tuss_table_code,
            representation: value ?? '',
          },
        }),
      );
    },
    [values.tuss_table_code, dispatch],
  );

  const handleChange = (value: IAutocompleteOption) => {
    setFieldValue('tuss_procedure_id', value.id);
    setFieldValue('tuss_procedure_name', value.title);
  };

  const handleFetchProceduresOptions = debounce((inputValue: string): void => {
    handleFetchProcedures(inputValue);
  }, 500);

  const handleInputChange = (event: any) => {
    if (event) {
      const inputValue = event.target.value;

      if ((!inputValue || inputValue === '') && values.tuss_table_code) {
        handleFetchProceduresOptions('');
        return;
      }

      if (inputValue.length >= 3 && values.tuss_table_code) {
        handleFetchProceduresOptions(inputValue);
      }
    }
  };

  const handleOpen = () => {
    if (values.tuss_table_code) {
      handleFetchProcedures();
    }
  };

  return (
    <Autocomplete
      id="tuss_procedure_id"
      freeSolo
      fullWidth
      options={procedureOptions}
      getOptionLabel={renderOption}
      renderOption={renderOptionFn}
      value={values?.tuss_procedure_name ?? ''}
      clearOnBlur
      onBlur={handleBlur}
      onChange={(_, value: any) => handleChange(value)}
      onInputChange={handleInputChange}
      onOpen={handleOpen}
      noOptionsText="Nenhum resultado encontrado"
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name="tuss_procedure_id"
          data-ga="tuss_procedure_id"
          required={required}
          error={!!errors.tuss_procedure_id}
          helperText={touched.tuss_procedure_id && errors.tuss_procedure_id}
        />
      )}
    />
  );
};
