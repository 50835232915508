import { useState, useCallback, useRef } from 'react';

interface Props {
  hasMoreData: boolean;
  loading: boolean;
}

export default function useInfiniteScroll({ hasMoreData, loading }: Props) {
  const [pageNum, setPageNum] = useState(1);

  const observer = useRef() as {
    current: IntersectionObserver;
  };

  const infiniteScrollRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreData) {
          setPageNum((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, observer, hasMoreData],
  );

  return { pageNum, infiniteScrollRef };
}
