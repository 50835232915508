import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Body,
  Grid,
  Loader,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';
import { getFiltersData } from '../../state/sample-boxes/selectors';
import { AvailableFilter } from '../../services/types';
import useStyles, * as S from './MoreFilters.styles';

const { FilterList, Close } = MaterialIcons;
const { ListItem } = MaterialUICore;

type MoreFiltersProps = {
  onApplyFilter: (filter: string) => void;
};

const MAX_COLUMN = 3;
const MULTIPLIER = 2;

const MoreFilters = ({ onApplyFilter }: MoreFiltersProps) => {
  const { lastColumns } = useStyles();
  const [
    anchorForFilters,
    setAnchorForFilters,
  ] = useState<HTMLButtonElement | null>(null);
  const {
    isFetchingPharmaceuticals,
    pharmaceuticals,
    isFetchingActivePrinciples,
    activePrinciples,
  } = useSelector(getFiltersData);

  const handleMoreFiltersClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorForFilters(event.currentTarget);
  };

  const handleCloseFilters = () => {
    setAnchorForFilters(null);
  };

  const principleColumns = useMemo(() => {
    if (!activePrinciples) return [];

    const maxSizeOfColumn = Math.ceil(activePrinciples.length / MAX_COLUMN);

    const column0 = activePrinciples.slice(0, maxSizeOfColumn);
    const column1 = activePrinciples.slice(
      maxSizeOfColumn,
      maxSizeOfColumn * MULTIPLIER,
    );
    const column2 = activePrinciples.slice(maxSizeOfColumn * MULTIPLIER);

    return [column0, column1, column2];
  }, [activePrinciples]);

  const renderItems = (list: AvailableFilter[]) => (
    <>
      {list.map(({ id, name }) => (
        <ListItem
          key={id}
          button
          onClick={() => {
            onApplyFilter(name);
            handleCloseFilters();
          }}
        >
          <Body variant="xs">{name}</Body>
        </ListItem>
      ))}
    </>
  );

  return (
    <>
      <S.Button
        startIcon={<FilterList />}
        color="transparent"
        onClick={handleMoreFiltersClick}
      >
        Mais filtros
      </S.Button>

      <S.Popper
        open={!!anchorForFilters}
        anchorEl={anchorForFilters}
        placement="bottom-end"
        modifiers={{
          flip: {
            enabled: false,
          },
        }}
      >
        <S.PopperConten data-testid="more-filters">
          <S.CloseButton size="small" onClick={handleCloseFilters}>
            <Close />
          </S.CloseButton>

          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <S.TitleFilters variant="xs">Farmacêuticas</S.TitleFilters>

              {isFetchingPharmaceuticals && <Loader />}

              {pharmaceuticals && renderItems(pharmaceuticals)}
            </Grid>

            <Grid item xs={12} sm={6} md={9}>
              <Grid container>
                <Grid item xs={12} sm={12} md={4}>
                  <S.TitleFilters variant="xs">Princípio ativo</S.TitleFilters>

                  {isFetchingActivePrinciples && <Loader />}

                  {activePrinciples && renderItems(principleColumns[0])}
                </Grid>

                <Grid item xs={12} sm={12} md={4} className={lastColumns}>
                  {activePrinciples && renderItems(principleColumns[1])}
                </Grid>

                <Grid item xs={12} sm={12} md={4} className={lastColumns}>
                  {activePrinciples && renderItems(principleColumns[2])}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </S.PopperConten>
      </S.Popper>
    </>
  );
};

export default MoreFilters;
