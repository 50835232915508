import React from 'react';
import { Field, InjectedFormProps } from 'redux-form';

import { required, number } from 'shared/utils/formValidation';
import { Input, CheckBox } from 'ui/new/form';
import { Row, Column } from 'ui/new/flex';
import { Unit, Inventory } from 'features/stock/types';
import UnitsDropdown from '../../components/UnitsDropdown';
import style from './style.scss';

type EditProps = {
  change: InjectedFormProps['change'];
  units?: Unit[];
  handleSubmit: InjectedFormProps['handleSubmit'];
  aditionalInventoryData: Omit<Inventory, 'lot'>;
};

export default function Edit({
  change,
  units = [],
  handleSubmit,
  aditionalInventoryData,
}: EditProps): JSX.Element {
  return (
    <Column className={style.body} onSubmit={handleSubmit}>
      <Row className={style.row}>
        <Column className={style.product}>
          <label htmlFor="description">Produto</label>
          <Field
            name="description"
            id="description"
            component={Input}
            validate={[required]}
            maxLength={100}
            autoFocus
          />
        </Column>
        <Column className={style.cod}>
          <label htmlFor="code">Cod. (opcional)</label>
          <Field
            name="code"
            id="code"
            component={Input}
            validate={[number]}
            maxLength={30}
          />
        </Column>
      </Row>
      <Row className={style.row}>
        <Column className={style.minimum}>
          <label htmlFor="minimum">Est. mínimo (opcional)</label>
          <Field
            name="minimum"
            id="minimum"
            component={Input}
            validate={[number]}
          />
        </Column>
        <Column className={style.unit}>
          <label htmlFor="unitId">Unidade</label>
          <Field
            name="unitId"
            id="unitId"
            component={UnitsDropdown}
            validate={[required]}
            title="Selecione"
            units={units}
            change={change}
          />
        </Column>
      </Row>
      <Row className={style.row}>
        <Field
          name="hasLotControl"
          id="hasLotControl"
          component={CheckBox}
          type="checkbox"
          text="Obrigatório preencher o lote ao efetuar entrada e saída"
        />
      </Row>
      <Column className={style.observation}>
        <label htmlFor="observation">Observações (opcional)</label>
        <Field name="observation" id="observation" component={Input} />
      </Column>
      {!!aditionalInventoryData && (
        <>
          <Row className={style.aditional}>
            <div className={style.aditional__label}>Quantidade em estoque:</div>
            <div className={style.aditional__units}>
              {aditionalInventoryData.quantity} unidades
            </div>
          </Row>
          <Row>
            <div className={style.aditional__label}>Vence em 30 dias:</div>
            <div className={style.aditional__units}>
              {aditionalInventoryData.expireInOneMonth} unidades
            </div>
          </Row>
          <Row>
            <div className={style.aditional__label}>Vencido:</div>
            <div className={style.aditional__units}>
              {aditionalInventoryData.expired} unidades
            </div>
          </Row>
        </>
      )}
    </Column>
  );
}
