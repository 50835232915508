import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export const useStyles = makeStyles((theme) => ({
  select: {
    '& .MuiSelect-root': {
      [theme.breakpoints.down('md')]: {
        maxWidth: '600px',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '300px',
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: '150px',
      },
    },
  },
}));
