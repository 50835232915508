import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormikContext, Form } from 'formik';
import { useRifm } from 'rifm';

import {
  TextField,
  Box,
  Button,
  MaterialUICore,
  MaterialIcons,
  InputAdornment,
} from '@iclinic/design-system';
import { clinicValidationSchema } from '../validation';
import StateSelect from './StateSelect';
import useStyles from './dialogStyles.style';
import { close, submitClinic } from '../state';
import { ClinicFormValues } from '../types';
import {
  requestStatusDerivedSelector,
  clinicFormSelector,
} from '../state/selectors';
import { cepFormatter } from 'shared/utils/formatters';
import { useGetZipCodeAddress } from './hooks';
import ErrorMessage from './ErrorMessage';

const { CircularProgress } = MaterialUICore;
const { SearchOutlined } = MaterialIcons;

const submitButtonContent = (isLoading: boolean) =>
  isLoading ? <CircularProgress size={14} /> : 'Atualizar';

const zipCodeAdornment = (isLoading: boolean) => ({
  endAdornment: (
    <InputAdornment position="end">
      {isLoading ? <CircularProgress size={14} /> : <SearchOutlined />}
    </InputAdornment>
  ),
});

const errorMessage = (hasError: boolean) => {
  return hasError && <ErrorMessage />;
};

const ClinicForm = () => {
  const classes = useStyles();

  const {
    errors,
    touched,
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ClinicFormValues>();

  const dispatch = useDispatch();
  const { isPending, hasError } = useSelector(requestStatusDerivedSelector);
  const { getAddress, zipCodeIsLoading } = useGetZipCodeAddress();

  const { value: zipCode, onChange: handleChangeZipCode } = useRifm({
    value: values.zip_code,
    onChange: (value: string) => {
      setFieldValue('zip_code', value);

      if (value.length === 9 && value !== values.zip_code) {
        getAddress(value);
      }
    },
    format: cepFormatter,
  });

  useEffect(() => {
    if (values.zip_code.length === 9) {
      setFieldTouched('zip_code', true);
    }
  }, [values.zip_code, setFieldTouched]);

  const formDisabled = isPending || zipCodeIsLoading;

  return (
    <Form>
      <TextField
        label="CEP"
        id="zip_code"
        name="zip_code"
        type="text"
        placeholder="Ex: 14096-700"
        autoComplete="zip_code"
        margin="normal"
        error={!!(touched.zip_code && errors.zip_code)}
        helperText={touched.zip_code && errors.zip_code}
        onChange={handleChangeZipCode}
        onBlur={handleBlur}
        value={zipCode}
        disabled={formDisabled}
        InputProps={zipCodeAdornment(zipCodeIsLoading)}
        data-ga="cfmupdate-zip_code"
      />

      <Box display="flex" justifyContent="space-between">
        <TextField
          label="Endereço"
          id="address"
          name="address"
          type="text"
          placeholder="Ex: Rua Professora Maria Aparecida"
          autoComplete="address"
          margin="normal"
          error={!!(touched.address && errors.address)}
          helperText={touched.address && errors.address}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.address}
          disabled={formDisabled}
          data-ga="cfmupdate-address"
          fullWidth
          className={classes.fieldMargin}
        />

        <TextField
          label="Número"
          id="number"
          name="number"
          type="text"
          placeholder="Ex: Rua Professora Maria Aparecida"
          autoComplete="number"
          margin="normal"
          error={!!(touched.number && errors.number)}
          helperText={touched.number && errors.number}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.number}
          disabled={formDisabled}
          data-ga="cfmupdate-number"
        />
      </Box>

      <Box display="flex" justifyContent="space-between">
        <TextField
          label="Complemento"
          id="complement"
          name="complement"
          type="text"
          placeholder="Ex: Apartamento 5"
          autoComplete="complement"
          margin="normal"
          error={!!(touched.complement && errors.complement)}
          helperText={touched.complement && errors.complement}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.complement}
          disabled={formDisabled}
          data-ga="cfmupdate-complement"
          fullWidth
          className={classes.fieldMargin}
        />

        <TextField
          label="Bairro"
          id="neighborhood"
          name="neighborhood"
          type="text"
          placeholder="Ex: Apartamento 5"
          autoComplete="neighborhood"
          margin="normal"
          error={!!(touched.neighborhood && errors.neighborhood)}
          helperText={touched.neighborhood && errors.neighborhood}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.neighborhood}
          disabled={formDisabled}
          data-ga="cfmupdate-neighborhood"
          fullWidth
        />
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <TextField
          label="Cidade"
          id="city"
          name="city"
          type="text"
          placeholder="Ex: Ribeirão Preto"
          autoComplete="city"
          margin="normal"
          error={!!(touched.city && errors.city)}
          helperText={touched.city && errors.city}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.city}
          disabled={formDisabled}
          data-ga="cfmupdate-city"
          fullWidth
          className={classes.fieldMargin}
        />

        <StateSelect
          name="state"
          label="Estado"
          error={!!(touched.state && errors.state)}
          handleChange={handleChange}
          value={values.state}
          disabled={formDisabled}
          helperText={touched.state && errors.state}
        />
      </Box>

      {errorMessage(hasError)}

      <Box display="flex" justifyContent="space-between">
        <Button
          color="transparent"
          className={classes.exitButton}
          onClick={() => dispatch(close())}
        >
          Sair
        </Button>
        <Button
          type="submit"
          disabled={formDisabled}
          className={classes.mainButton}
        >
          {submitButtonContent(formDisabled)}
        </Button>
      </Box>
    </Form>
  );
};

const ClinicFormContainer = () => {
  const dispatch = useDispatch();
  const clinic = useSelector(clinicFormSelector);

  return (
    <Formik
      enableReinitialize
      initialValues={clinic}
      onSubmit={(values) => {
        dispatch(submitClinic(values));
      }}
      validationSchema={clinicValidationSchema}
    >
      <ClinicForm />
    </Formik>
  );
};

export default ClinicFormContainer;
