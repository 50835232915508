import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Loader, MaterialIcons } from '@iclinic/design-system';

import {
  createLink,
  ICLINIC_PAY_URLS,
} from 'features/onlinePayment/containers/iclinic-pay/iClinicPayIntegration';
import {
  getSSOToken,
  getSSOTokenStatus,
} from 'features/onlinePayment/state/iclinic-pay/selectors';
import { setPageNumber } from 'features/onlinePayment/state/notifications-center';
import {
  getNotificationsErrorMessage,
  getNotificationsList,
  getNotificationTokenErrorMessage,
  getPageNumber,
  isFetchingNotifications,
} from 'features/onlinePayment/state/notifications-center/selectors';
import { ErrorAlert } from 'shared/components';
import { RequestStatus } from 'shared/constants/State';
import { useIsInViewport } from 'shared/hooks/useIsInViewport';
import MarkAsRead from './MarkAsRead';
import Notification from './Notification';
import Overview from './Overview';
import * as S from './styles';

const { ArrowForward } = MaterialIcons;

const Notifications = () => {
  const dispatch = useDispatch();
  const refTargetScroll = useRef(null);
  const isElementInViewport = useIsInViewport(refTargetScroll);

  const pageNumber: number = useSelector(getPageNumber);

  const tokenErrorMsg = useSelector(getNotificationTokenErrorMessage);
  const [isFetching, listErrorMsg, notificationsList] = [
    useSelector(isFetchingNotifications),
    useSelector(getNotificationsErrorMessage),
    useSelector(getNotificationsList),
  ];
  const [ssoToken, ssoTokenStatus] = [
    useSelector(getSSOToken),
    useSelector(getSSOTokenStatus),
  ];

  useEffect(() => {
    if (!isElementInViewport) return;
    dispatch(setPageNumber(pageNumber + 1));
  }, [isElementInViewport]);

  return (
    <>
      <Overview />

      {listErrorMsg || tokenErrorMsg ? (
        <Box p={3}>
          <ErrorAlert
            message={
              <span
                dangerouslySetInnerHTML={{
                  __html: (listErrorMsg || tokenErrorMsg) as string,
                }}
              />
            }
          />
        </Box>
      ) : (
        <>
          {!!notificationsList.length && (
            <>
              <MarkAsRead />
              {notificationsList.map((notification, i) => (
                <Notification
                  key={`${notification.id}-${new Date(i).getTime()}`}
                  notificationType={notification.messageType}
                  notificationData={notification}
                />
              ))}

              <S.TargetScroll ref={refTargetScroll}>Scroll</S.TargetScroll>
            </>
          )}

          {isFetching && (
            <Box display="flex" justifyContent="center">
              <Loader size={20} title="Carregando notificações..." />
            </Box>
          )}

          {!isFetching && !notificationsList.length && (
            <Box pt={3} pb={2} my="auto" display="flex" justifyContent="center">
              <S.Title variant="xs" bold>
                Você não tem notificações para visualizar.
              </S.Title>
            </Box>
          )}

          {ssoTokenStatus !== RequestStatus.Pending && ssoToken && (
            <S.Footer>
              <S.LinkButton
                href={createLink(ssoToken, ICLINIC_PAY_URLS.GESTAO_FINANCEIRA)}
                target="_blank"
                variant="contained"
                color="primary"
                size="small"
                startIcon={<ArrowForward />}
                fullWidth
              >
                Ir para gestão financeira
              </S.LinkButton>
            </S.Footer>
          )}
        </>
      )}
    </>
  );
};

export default Notifications;
