import React, { useEffect, useRef, useState } from 'react';

import useStyles from './styles';
import { IVideoTrack } from 'features/video-conference/utils';
import useMediaStreamTrack from 'features/video-conference/hooks/useMediaStreamTrack';
import useVideoTrackDimensions from 'features/video-conference/hooks/useVideoTrackDimensions';

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
}

export default function VideoTrack({ track, isLocal }: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);
  const [wasInterrupted, setWasInterrupted] = useState<boolean>(false);
  const mediaStreamTrack = useMediaStreamTrack(track);
  const dimensions = useVideoTrackDimensions(track);
  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);
  const isFrontFacing =
    mediaStreamTrack?.getSettings().facingMode !== 'environment';

  const shouldRotate = isLocal && isFrontFacing;
  const isContain = (isPortrait && !isLocal) || track.name.includes('screen');

  const classes = useStyles({ shouldRotate, isContain });

  useEffect(() => {
    const videoElement = ref.current;
    videoElement.muted = true;
    track.attach(videoElement);

    const onPause = () => {
      setWasInterrupted(true);
    };

    const onPlay = () => {
      if (wasInterrupted) {
        track.detach(videoElement);
        track.attach(videoElement);
        setWasInterrupted(false);
      }
    };    

    videoElement.addEventListener('pause', onPause);
    videoElement.addEventListener('play', onPlay);

    return () => {
      track.detach(videoElement);
      videoElement.removeEventListener('pause', onPause);
      videoElement.removeEventListener('play', onPlay);
      videoElement.srcObject = null;
    };
  }, [track, wasInterrupted]);

  return (
    <video
      data-testid="video-element"
      className={classes.videoPlayer}
      ref={ref}
    >
      <track kind="captions" />
    </video>
  );
}
