import { createSelector } from 'reselect';

import {
  BanksRawData,
  SellerBankAccountRawData,
} from 'features/onlinePayment/services/config/types';
import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';
import { SellerBankAccountForm } from '../types';
import { getSeller } from '../register/selectors';

export const getSellerBankAccountErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.config.bankAccount.sellerBankAccount?.error;

export const getSellerBankAccountFetchStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.config.bankAccount.sellerBankAccount?.fetchStatus;

export const isFetchingBankAccount = createSelector(
  getSellerBankAccountFetchStatus,
  (sellerBankAccountStatus) =>
    sellerBankAccountStatus === RequestStatus.Pending,
);

export const getSellerBankAccountFetchError = createSelector(
  getSellerBankAccountFetchStatus,
  getSellerBankAccountErrorMessage,
  (status, message) => {
    return status === RequestStatus.Error ? message : null;
  },
);

export const getInstallmentsStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.config.bankAccount.installments?.status;

export const getInstallmentsErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.config.bankAccount.installments?.error;

export const getInstallmentsError = createSelector(
  getInstallmentsStatus,
  getInstallmentsErrorMessage,
  (status, message) => {
    return status === RequestStatus.Error ? message : null;
  },
);

export const getSellerBankAccountSaveStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined => {
  return onlinePayment.config.bankAccount.sellerBankAccount?.saveStatus;
};

export const getSellerBankAccountForm = ({
  onlinePayment,
}: StoreState): SellerBankAccountForm | undefined =>
  onlinePayment.config.bankAccount.sellerBankAccountForm;

export const getSellerBankAccount = ({
  onlinePayment,
}: StoreState): SellerBankAccountRawData | undefined =>
  onlinePayment.config.bankAccount.sellerBankAccount?.data;

export const getBanks = ({
  onlinePayment,
}: StoreState): BanksRawData[] | undefined =>
  onlinePayment.config.bankAccount.banks?.data;

export const getBanksStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.config.bankAccount.banks?.status;

export const isFetchingBanks = createSelector(
  getBanksStatus,
  (banksFetchStatus) => banksFetchStatus === RequestStatus.Pending,
);

export const getBankAccountError = createSelector(
  getSellerBankAccountSaveStatus,
  getSellerBankAccountErrorMessage,
  (status, message) => {
    return status === RequestStatus.Error ? message : null;
  },
);

export const isSavingBankAccount = createSelector(
  getSellerBankAccountSaveStatus,
  getInstallmentsStatus,
  (sellerBankAccountStatus, installmentsStatus) =>
    sellerBankAccountStatus === RequestStatus.Pending ||
    installmentsStatus === RequestStatus.Pending,
);

export const getSellerBankSelector = createSelector(
  [
    isFetchingBanks,
    isFetchingBankAccount,
    getSeller,
    isSavingBankAccount,
    getSellerBankAccountForm,
    getSellerBankAccount,
  ],
  (
    isFetching,
    isFetchingBA,
    seller,
    saving,
    sellerBankAccountForm,
    sellerBankAccount,
  ) => ({
    fetching: isFetching || isFetchingBA,
    seller,
    saving,
    sellerBankAccountForm,
    sellerBankAccount,
  }),
);
