import React from 'react';
import {
  Link,
  Grid,
  MaterialIcons,
  Body,
  MaterialUICore,
  Checkbox,
} from '@iclinic/design-system';

import { useStyles } from './styles';

const { FormControl, FormHelperText } = MaterialUICore;
const { Help } = MaterialIcons;

type Props = {
  checked: boolean;
  error: boolean;
  helperText?: string;
  onChange:
    | ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void)
    | undefined;
};

export default function Terms({ checked, error, helperText, onChange }: Props) {
  const classes = useStyles();

  return (
    <>
      <Grid item>
        <FormControl error={error}>
          <Checkbox
            className={classes.checkbox}
            name="terms"
            checked={checked}
            onChange={onChange}
            formControlLabelProps={{
              className: classes.checkboxLabel,
            }}
            label={
              <Body variant="xs">
                Li e aceito os{' '}
                <Link
                  href="https://suporte.iclinic.com.br/pt-br/termos-e-condições-gerais-de-uso-da-plataforma-de-pagamento-online"
                  target="_blank"
                  variant="body2"
                  bold
                >
                  termos de uso
                </Link>
              </Body>
            }
          />
          {error && <FormHelperText error>{helperText}</FormHelperText>}
        </FormControl>
      </Grid>
      <Grid alignItems="center" wrap="nowrap" item container>
        <Grid item className={classes.helpWrap}>
          <Help className={classes.helpIcon} />
        </Grid>
        <Grid item>
          <Body variant="xs">
            Tire suas dúvidas com nossos especialistas pelo chat ou pelo{' '}
            <Link
              href="https://suporte.iclinic.com.br/pt-br/perguntas-frequentes-pagamento"
              target="_blank"
              variant="body2"
              bold
            >
              FAQ
            </Link>
          </Body>
        </Grid>
      </Grid>
    </>
  );
}
