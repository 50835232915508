import { PromiseEnvelope } from '@types';
import { RoomTypes } from 'features/video-conference/constants/types';
import {
  FetchCheckRoomStatusRawData,
  FetchConnectRoomData,
  FetchFinishConferenceBody,
  FetchEnterRoomRawData,
  FetchUpdateAnnotationsBody,
  FetchRoomJoinBody,
} from '../types';
import { apiTelemedicine } from '../api';

export const fetchRoomStart = (
  type: RoomTypes,
  isBeta = false,
): PromiseEnvelope<FetchEnterRoomRawData> =>
  apiTelemedicine.post(`conference/room/start?isBeta=${isBeta}`, { type });

export const fetchRoomConnect = (): PromiseEnvelope<FetchConnectRoomData> =>
  apiTelemedicine.patch('conference/room/connect');

export const fetchRoomJoin = (
  body: FetchRoomJoinBody,
): PromiseEnvelope<FetchEnterRoomRawData> =>
  apiTelemedicine.post('conference/room/join', body);

export const fetchCheckRoomStatus = (): PromiseEnvelope<FetchCheckRoomStatusRawData> =>
  apiTelemedicine.get('conference/room');

export const fetchFinishConference = (
  body: FetchFinishConferenceBody,
): PromiseEnvelope<void> => apiTelemedicine.patch('conference/finish', body);

export const fetchUpdateAnnotations = (
  body: FetchUpdateAnnotationsBody,
): PromiseEnvelope<void> => apiTelemedicine.post('conference/annotation', body);
