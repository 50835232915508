import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  Box,
  CircularProgress,
  Heading,
  MaterialUICore,
} from '@iclinic/design-system';

import { cleanPhysiciansData, fetchPhysicians } from '../../state/common';
import {
  getPartialUserInfo,
  getPermissionsMenu,
  isFetchingPhysicians,
} from '../../state/common/selectors';
import { PersonalizationTabPanelReminder } from './PersonalizationTabPanel/PersonalizationTabPanel';
import ShippingHistory from '../ScheduleConfirmation/ShippingHistory/ShippingHistory';
import { MessageStatus, TemplateKind } from 'features/messaging/services/types';
import * as S from './ScheduleReminder.styles';

const { Tab, Tabs } = MaterialUICore;

enum TabOptions {
  PERSONALIZATION = 'personalization',
  HISTORY = 'history',
}

const ScheduleReminder = () => {
  const dispatch = useDispatch();
  const isFetchingPhysiciansData = useSelector(isFetchingPhysicians);
  const { userKind, isAdmin } = useSelector(getPartialUserInfo);
  const permissionsMenu = useSelector(getPermissionsMenu);
  const [tab, setTab] = useState(() => {
    if (permissionsMenu?.event_reminder.customization) {
      return TabOptions.PERSONALIZATION;
    }

    return TabOptions.HISTORY;
  });

  const onTabChange = useCallback(
    (_: React.ChangeEvent<{}>, newValue: TabOptions) => {
      setTab(newValue);
    },
    [],
  );

  useEffect(() => {
    dispatch(cleanPhysiciansData());

    if (userKind === 'r' || isAdmin) {
      dispatch(fetchPhysicians({ returnAll: true }));
    }
  }, [dispatch, userKind, isAdmin]);

  return (
    <S.Root>
      <div>
        <Heading variant="md">Lembrete de agenda</Heading>

        <Body variant="xs">
          Auxilie seus pacientes a se lembrarem do agendamento e reduza os
          números de faltas no dia do agendamento.
        </Body>
      </div>

      {isFetchingPhysiciansData ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress data-testid="loader-physicians" />
        </Box>
      ) : (
        <S.TabsContent>
          <Tabs
            value={tab}
            textColor="primary"
            indicatorColor="primary"
            onChange={onTabChange}
          >
            {permissionsMenu?.event_reminder.customization ? (
              <Tab label="Personalização" value={TabOptions.PERSONALIZATION} />
            ) : null}

            <Tab label="Histórico de envios" value={TabOptions.HISTORY} />
          </Tabs>

          <div hidden={tab !== TabOptions.PERSONALIZATION}>
            <PersonalizationTabPanelReminder />
          </div>

          <div hidden={tab !== TabOptions.HISTORY}>
            <ShippingHistory
              kind={TemplateKind.REMINDER}
              statusOptions={[
                MessageStatus.SENT,
                MessageStatus.ERROR,
                MessageStatus.NUMBER_INVALID,
              ]}
            />
          </div>
        </S.TabsContent>
      )}
    </S.Root>
  );
};

export { ScheduleReminder };
