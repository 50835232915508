import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ActionKeys,
  AnsRegisterData,
  Autocomplete,
  AutocompleteInput,
  CBOAutocompleteEntry,
  GuideVersionData,
  HiredSolicitantCodeEntry,
  HiredSolicitantSlug,
  ProcedureAutocompleteEntry,
  PhysicianAutocompleteEntry,
  ClinicAutocompleteEntry,
  PatientAutocompleteEntry,
} from 'features/tissInvoicing/types';

export type AutocompleteState = {
  autocomplete: Autocomplete;
  loading: {
    fetchAutocomplete: boolean;
  };
};

const name = 'autocomplete';

export const initialState: AutocompleteState = {
  autocomplete: {
    physicians: [],
    executors: [],
    clinics: [],
    procedures: [],
    hired_solicitants: [],
    versions: [],
    guide_versions: [],
    ans_registers: [],
    patients: [],
    patients_options: [],
    profession_codes: [],
  },

  loading: {
    fetchAutocomplete: true,
  },
};

const fetchPhysiciansOptions = createAction<{ name?: string }>(
  `${name}/fetchPhysiciansOptions`,
);

const fetchClinicsOptions = createAction<{ name?: string }>(
  `${name}/fetchClinicsOptions`,
);

const searchPatients = createAction<{ name?: string }>(
  `${name}/searchPatients`,
);

const fetchAutocompleteOptions = createAction<{ eventId: string }>(
  `${name}/fetchAutocompleteOptions`,
);

const fetchHiredSolicitantData = createAction<{
  insuranceId: number;
  solicitantId: number;
  type: HiredSolicitantSlug;
  callback?: ({ code_type, code }: HiredSolicitantCodeEntry) => void;
}>(`${name}/fetchHiredSolicitantData`);

const fetchGuideVersionsOptions = createAction(
  `${name}/fetchGuideVersionsOptions`,
);

const fetchAnsRegistersOptions = createAction(
  `${name}/fetchAnsRegistersOptions`,
);

const fetchPatientsOptions = createAction<{ name?: string }>(
  `${name}/fetchPatientsOptions`,
);

const fetchProceduresOptions = createAction<{
  tableCode: string;
  searchName?: string;
}>(`${name}/fetchProceduresOptions`);

const fetchExecutorsOptions = createAction<{ name?: string }>(
  `${name}/fetchExecutorsOptions`,
);

const fetchCboOptions = createAction(`${name}/fetchCboOptions`);

const autocompleteSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLoadingStatus: (
      state,
      action: PayloadAction<{ key: ActionKeys; value: boolean }>,
    ) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.payload.key]: action.payload.value,
      },
    }),
    setAutocompleteOptions: (
      state,
      action: PayloadAction<Omit<Autocomplete, 'hired_solicitants'>>,
    ) => ({
      ...state,
      autocomplete: { ...state.autocomplete, ...action.payload },
    }),
    setHiredSolicitants: (
      state,
      action: PayloadAction<HiredSolicitantCodeEntry>,
    ) => ({
      ...state,
      autocomplete: {
        ...state.autocomplete,
        hired_solicitants: [
          ...state.autocomplete.hired_solicitants,
          action.payload,
        ],
      },
    }),
    setGuideVersionsOptions: (
      state,
      action: PayloadAction<GuideVersionData[]>,
    ) => ({
      ...state,
      autocomplete: {
        ...state.autocomplete,
        guide_versions: action.payload,
      },
    }),
    setAnsRegistersOptions: (
      state,
      action: PayloadAction<AnsRegisterData[]>,
    ) => ({
      ...state,
      autocomplete: {
        ...state.autocomplete,
        ans_registers: action.payload,
      },
    }),
    setPatientsOptions: (
      state,
      action: PayloadAction<AutocompleteInput[]>,
    ) => ({
      ...state,
      autocomplete: {
        ...state.autocomplete,
        patients: action.payload,
      },
    }),
    setProceduresOptions: (
      state,
      action: PayloadAction<ProcedureAutocompleteEntry[]>,
    ) => ({
      ...state,
      autocomplete: {
        ...state.autocomplete,
        procedures: action.payload,
      },
    }),
    setExecutorsOptions: (
      state,
      action: PayloadAction<PhysicianAutocompleteEntry[]>,
    ) => ({
      ...state,
      autocomplete: {
        ...state.autocomplete,
        executors: action.payload,
      },
    }),
    setCboOptions: (state, action: PayloadAction<CBOAutocompleteEntry[]>) => ({
      ...state,
      autocomplete: {
        ...state.autocomplete,
        profession_codes: action.payload,
      },
    }),
    setPhysiciansOptions: (
      state,
      action: PayloadAction<PhysicianAutocompleteEntry[]>,
    ) => ({
      ...state,
      autocomplete: {
        ...state.autocomplete,
        physicians: action.payload,
      },
    }),
    setPatientsAutocompleteOptions: (
      state,
      action: PayloadAction<PatientAutocompleteEntry[]>,
    ) => ({
      ...state,
      autocomplete: {
        ...state.autocomplete,
        patients_options: action.payload,
      },
    }),
    setClinicsOptions: (
      state,
      action: PayloadAction<ClinicAutocompleteEntry[]>,
    ) => ({
      ...state,
      autocomplete: {
        ...state.autocomplete,
        clinics: action.payload,
      },
    }),
  },
});

export default autocompleteSlice.reducer;
export const actions = {
  ...autocompleteSlice.actions,
  fetchAutocompleteOptions,
  fetchHiredSolicitantData,
  fetchGuideVersionsOptions,
  fetchAnsRegistersOptions,
  fetchPatientsOptions,
  fetchProceduresOptions,
  fetchExecutorsOptions,
  fetchCboOptions,
  fetchPhysiciansOptions,
  fetchClinicsOptions,
  searchPatients,
};
