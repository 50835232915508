import React, { memo } from 'react';
import { Body, MaterialUICore, MaterialIcons } from '@iclinic/design-system';

import { formatDate } from 'features/tissInvoicing/utils/formatDateTime';
import { formatCurrency } from 'features/tissInvoicing/utils/formatCurrency';
import { Guide } from 'features/tissInvoicing/types';
import { EditButton } from './styles';

const { PlaylistAddCheck } = MaterialIcons;
const { TableCell } = MaterialUICore;

interface RowsProps {
  rowData: Guide;
}

const Rows = ({ rowData }: RowsProps) => (
  <>
    <TableCell>
      <Body variant="sm">{rowData.guide_number}</Body>
    </TableCell>
    <TableCell>
      <Body variant="sm">{rowData.lot_number}</Body>
    </TableCell>
    <TableCell>
      <Body variant="sm">{formatDate(rowData.date)}</Body>
    </TableCell>
    <TableCell>
      <Body variant="sm">{rowData.insurance.name}</Body>
    </TableCell>
    <TableCell>
      <Body variant="sm">{rowData.patient.name}</Body>
    </TableCell>
    <TableCell>
      <Body variant="sm">{rowData.physician.name}</Body>
    </TableCell>
    <TableCell>
      <Body variant="sm">{rowData.quantity}</Body>
    </TableCell>
    <TableCell>
      <Body variant="sm">
        {formatCurrency(rowData.procedures_total_amount)}
      </Body>
    </TableCell>
    <TableCell>
      <Body variant="sm">
        {rowData.expenses_amount ? formatCurrency(rowData.expenses_amount) : ''}
      </Body>
    </TableCell>
    <TableCell>
      <Body variant="sm">{formatCurrency(rowData.total_amount)}</Body>
    </TableCell>
    <TableCell align="center" size="small">
      <EditButton href={`/faturamento-tiss/sadt/${String(rowData.event_id)}`}>
        <PlaylistAddCheck />
      </EditButton>
    </TableCell>
  </>
);

export default memo(Rows);
