import ButtonGroup from './ButtonGroup';
import Description from './Description';
import Extra from './Extra';
import Total from './Total';

export default {
  ButtonGroup,
  Description,
  Extra,
  Total,
};
