import { createSelector } from 'reselect';

import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';
import { ExportListItem, ExportListPagination } from './types';

export const getExportedList = ({
  exportClinicData,
}: StoreState): ExportListItem[] | undefined =>
  exportClinicData.exportList.exportList?.data;

export const getExportListPagination = ({
  exportClinicData,
}: StoreState): ExportListPagination | null | undefined =>
  exportClinicData.exportList.pagination;

export const getPageNumber = ({ exportClinicData }: StoreState): number =>
  exportClinicData.exportList.pageNumber;

export const getExportedListStatus = ({
  exportClinicData,
}: StoreState): RequestStatus | undefined =>
  exportClinicData.exportList.exportList?.status;

export const isFetchingExportedList = createSelector(
  getExportedListStatus,
  (exportListFetchStatus) => exportListFetchStatus === RequestStatus.Pending,
);
