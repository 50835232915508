// External
import * as React from 'react';
import cn from 'classnames';

// Internal
import style from './style/index.scss';

type BodyProps = {
  children: React.ReactNode;
  classes: {
    body?: string;
    'body-card'?: string;
  };
};

export default function Body({
  children,
  classes = {},
}: BodyProps) {
  return (
    <div className={cn(style.body, (classes.body || classes['body-card']))}>
      {children}
    </div>
  );
}
