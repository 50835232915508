import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getOnlineScheduleSettings,
  getIsShowOnlineSchedule,
} from 'features/settings/scheduleOnline/state/selectors';
// @ts-ignore
import { Notification } from '@iclinic/iclinic-ui';
import { ExpireAfter } from 'shared/components';
import * as actions from 'features/settings/scheduleOnline/state/actions';
import PageTitle from 'ui/page/PageTitle';
import Divider from 'ui/divider/Divider';
import {
  Switch,
  SelectBox,
  Card,
  CardWorkHours,
  SubTitle,
  FormActions,
  ManageIntegrationSection,
  InsuranceSection,
  WorkHoursSection,
  AntecedenceSection,
} from '../components';
import style from './ScheduleOnlineSetup.scss';
import formatMessage from '../content';
import { StoreState } from 'state/rootReducer';
import { Button } from '@iclinic/design-system';

class ScheduleOnlineSetup extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    valueSelectPhysician: '',
  };

  componentDidMount = () => {
    const { fetchPhysicians, fetchSettingsInitialized } = this.props;
    fetchPhysicians();
    fetchSettingsInitialized();
  };

  onChangeSelectPhysician = (event) => {
    const { value } = event.target;
    const { fetchSettings } = this.props;

    fetchSettings(value);
    this.setState({ valueSelectPhysician: value });
  };

  changeShowOnlineSchedule = () => {
    const { onChangeSwitchIsShowOnlineSchedule } = this.props;

    onChangeSwitchIsShowOnlineSchedule();
  };

  updateSettings = () => {
    const { updateSettingsAction } = this.props;

    updateSettingsAction();
  };

  render() {
    const { valueSelectPhysician } = this.state;

    const {
      // store
      settings,
      physicians,
      // selectors
      onlineScheduleSettings,
      isShowOnlineSchedule,
      userData,
    } = this.props;

    const currentUser = String(userData.physicianId);
    const selectedConfigUserId = String(
      valueSelectPhysician ||
        (physicians.length > 0 ? physicians[0].id : currentUser),
    );

    const isCurrentUserSelected = currentUser === selectedConfigUserId;
    const selectedConfigUserName = physicians.find((item: any) => {
      return String(item.id) === selectedConfigUserId;
    })?.name;

    const {
      has_online_schedule_active: hasOnlineScheduleActive,
    } = isShowOnlineSchedule;

    const { contentLabelSwitch, error, notification } = onlineScheduleSettings;

    return (
      <div
        data-testid="schedule-online-setup"
        className={style.scheduleOnlineSetup}
      >
        {notification.show && (
          <Notification>
            <ExpireAfter delay={3000}>
              <Notification.Message
                title={notification.title}
                showCloseButton={false}
                kind={notification.kind}
              >
                {notification.description}
              </Notification.Message>
            </ExpireAfter>
          </Notification>
        )}
        <PageTitle>
          {formatMessage({ id: '@online-scheduling:page-title' })}
        </PageTitle>
        <Card>
          <SubTitle
            label={formatMessage({
              id: '@online-scheduling:physician-select-label',
            })}
          />
          <div className={style.card__divContentSelect}>
            <SelectBox
              onChange={this.onChangeSelectPhysician}
              value={valueSelectPhysician}
            >
              {physicians.map((item) => (
                <option value={item.id} key={item.id}>
                  {item.name}
                </option>
              ))}
            </SelectBox>
          </div>
        </Card>
        {settings && !error && (
          <CardWorkHours>
            <div className={style.card__divContentSwitch}>
              <Switch
                value={hasOnlineScheduleActive}
                onChange={this.changeShowOnlineSchedule}
                label={<SubTitle label={contentLabelSwitch} />}
              />
            </div>
            {hasOnlineScheduleActive && (
              <div>
                <ManageIntegrationSection
                  isCurrentUser={isCurrentUserSelected}
                  selectedConfigUserName={selectedConfigUserName}
                />
                <Divider />
                <AntecedenceSection />
                <Divider />
                <WorkHoursSection />
                <Divider />
                <InsuranceSection />
              </div>
            )}
          </CardWorkHours>
        )}
        <FormActions>
          <Button
            type="submit"
            id="configuracoes_profissionais-agendamento_online_salvar"
            data-ga="configuracoes_profissionais-agendamento_online_salvar"
            onClick={this.updateSettings}
          >
            Salvar
          </Button>
        </FormActions>
      </div>
    );
  }
}

ScheduleOnlineSetup.propTypes = {
  fetchPhysicians: PropTypes.func.isRequired,
  fetchSettingsInitialized: PropTypes.func.isRequired,
  fetchSettings: PropTypes.func.isRequired,
  onChangeSwitchIsShowOnlineSchedule: PropTypes.func.isRequired,
  updateSettingsAction: PropTypes.func.isRequired,
  onlineScheduleSettings: PropTypes.shape({
    hasScheduleIntegration: PropTypes.bool,
    notification: PropTypes.shape({
      show: PropTypes.bool,
      kind: PropTypes.number,
      title: PropTypes.string,
      description: PropTypes.string,
    }).isRequired,
    insurances: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        name: PropTypes.string,
        has_online_schedule_active: PropTypes.bool,
      }),
    ).isRequired,
    weekdays: PropTypes.arrayOf(PropTypes.string),
    error: PropTypes.bool,
    contentLabelSwitch: PropTypes.string,
    servicePeriods: PropTypes.shape(PropTypes.array).isRequired,
  }).isRequired,
  settings: PropTypes.bool.isRequired,
  physicians: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
  ).isRequired,
  isShowOnlineSchedule: PropTypes.shape({
    has_online_schedule_active: PropTypes.bool,
  }).isRequired,
  settingsScheduleHours: PropTypes.shape({
    lunchStart: PropTypes.string,
    lunchEnd: PropTypes.string,
    startService: PropTypes.string,
    endService: PropTypes.string,
  }).isRequired,
  userData: PropTypes.shape({
    subscriptionId: PropTypes.number,
    permissions: PropTypes.arrayOf(PropTypes.string),
    physicianId: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = (state: StoreState) => ({
  userData: state.userInfo.userData,
  settings: state.onlineSchedule.settings,
  physicians: state.onlineSchedule.physicians,
  onlineScheduleSettings: getOnlineScheduleSettings(state),
  isShowOnlineSchedule: getIsShowOnlineSchedule(state),
});

const mapDispatchToProps = {
  ...actions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScheduleOnlineSetup);
