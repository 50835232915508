import React, { useState, useEffect } from 'react';
import { LocalAudioTrack, Participant, RemoteAudioTrack } from 'twilio-video';
import classnames from 'classnames';

import { Box } from '@iclinic/design-system';
import RemoteParticipatInfo from 'features/video-conference/components/Feedback/RemoteParticipatInfo';
import AudioLevelIndicator from 'features/video-conference/components/Feedback/AudioLevelIndicator';
import FeedbackMicOff from 'features/video-conference/components/Feedback/MicrophoneOff';
import usePublications from 'features/video-conference/hooks/usePublication';
import useTrack from 'features/video-conference/hooks/useTrack';
import useIsTrackEnabled from 'features/video-conference/hooks/useIsTrackEnabled';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useChatContext } from 'features/video-conference/state/chatContext';
import { isMobile } from 'features/video-conference/utils';
import useStyles from './styles';

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
}

export default function ParticipantInfo({
  participant,
  children,
  isLocalParticipant,
  hideParticipant,
}: ParticipantInfoProps) {
  const [open, setOpen] = useState(false);
  const publications = usePublications(participant);
  const { isChatWindowOpen } = useChatContext();
  const { physicianInfo, patientInfo } = useParticipantContext();
  const { isPhysician } = useConference();
  const remoteParticipant = isPhysician ? patientInfo : physicianInfo;
  const classes = useStyles();

  useEffect(() => {
    setOpen(isChatWindowOpen);
  }, [isChatWindowOpen]);

  const audioPublication = publications.find((p) => p.kind === 'audio');
  const videoPublication = publications.find(
    (p) => !p.trackName.includes('screen') && p.kind === 'video',
  );

  const isVideoEnabled = Boolean(videoPublication);

  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;

  const isAudioEnabled = useIsTrackEnabled(audioTrack);

  return (
    <Box
      className={classnames(classes.container, {
        [classes.hideParticipant]: hideParticipant,
        [classes.chatWindowOpen]: open && !isMobile,
        [classes.remoteContainer]: !isLocalParticipant,
      })}
      data-testid="participant-container"
    >
      {isAudioEnabled && isVideoEnabled && (
        <AudioLevelIndicator audioTrack={audioTrack} isLocal />
      )}

      {!isAudioEnabled && isVideoEnabled && (
        <FeedbackMicOff
          isVideoEnabled={isVideoEnabled}
          isLocal
          participant={participant}
        />
      )}

      {!isLocalParticipant && isVideoEnabled && (
        <RemoteParticipatInfo
          participant={remoteParticipant}
          isSmallContainer
        />
      )}

      {children}
    </Box>
  );
}
