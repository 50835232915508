import React from 'react';

import { MessageBubble, MessageText, TextMessageContainer } from './styles';

interface TextMessageProps {
  body: string;
  isLocalParticipant: boolean;
}

export default function TextMessage({
  body,
  isLocalParticipant,
}: TextMessageProps) {
  return (
    <TextMessageContainer>
      <MessageBubble
        isLocalParticipant={isLocalParticipant}
        data-testid="messageBubble"
      >
        <MessageText variant="xs">{body}</MessageText>
      </MessageBubble>
    </TextMessageContainer>
  );
}
