import { MaterialIcons } from '@iclinic/design-system';
import React from 'react';
import { Severity } from '@iclinic/design-system/src/components/SnackBar/types';

import { Snackbar } from './SnackbarComponent.styles';

const { CheckCircle } = MaterialIcons;

type SnackbarProps = {
  message: string;
  openSnackbar: boolean;
  onClose?: () => void;
  severity?: Severity;
};
export const SnackbarComponent = ({
  message,
  openSnackbar,
  onClose,
  severity = 'success',
}: SnackbarProps): JSX.Element => (
  <Snackbar
    open={openSnackbar}
    autoHideDuration={4000}
    severity={severity}
    onClose={onClose}
    anchorOrigin={{
      horizontal: 'center',
      vertical: 'bottom',
    }}
    message={
      <>
        <CheckCircle />
        {message}
      </>
    }
  />
);
