// External
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// Internal
import { StoreState } from 'state/rootReducer';
import * as actions from 'state/billing/subscription/actions';

// internals
import ListDetail from 'ui/new/list-detail';
import Payment from 'ui/new/payment';
import Text from 'ui/new/text';
import { formatNumberToDecimal } from 'shared/utils/strings';

// components
import Summary from 'features/billing/components/summary/Summary';
import Header from 'features/billing/components/summary/Header';
import Footer from 'features/billing/components/summary/Footer';
import Total from 'features/billing/components/summary/Total';

const mapStateToProps = (state: StoreState) => ({
  selectedUsersWithProduct: state.billing.subscription,
  physicians: state.billing.subscription.physicians,
  step: state.billing.subscription.step,
  summaryCalculate: state.billing.subscription.summary,
});

const mapDispatchToProps = actions;

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type MapDispatchToProps = typeof mapDispatchToProps;
type SubscriptionProps = MapStateToProps & MapDispatchToProps;

function SubscriptionSummary({
  // actions
  getSummary,
  // store
  summaryCalculate,
}: SubscriptionProps): JSX.Element {
  useEffect(() => {
    getSummary();
  }, [getSummary]);

  return (
    <Summary>
      <Header>
        <ListDetail title="Nova assinatura iClinic">
          {
            summaryCalculate.newSubscription.map(item => (
              <ListDetail.Row key={Math.random()}>
                <Text>{item.text}</Text>
                {item.totalValue && item.text && (
                  <Payment.Price
                    promotion={item.promotionValue && formatNumberToDecimal(item.promotionValue)}
                    total={formatNumberToDecimal(item.totalValue)}
                  />
                )}
              </ListDetail.Row>
            ))
          }
        </ListDetail>
      </Header>
      <Footer>
        {summaryCalculate.total && (
          <Total price={`R$ ${summaryCalculate.total}`} text="Total: " />
        )}
      </Footer>
    </Summary>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionSummary);
