import React from 'react';

const TelemedicineText = () => (
  <div>
    <p>
      Você está preparando o seu atendimento médico para a realidade da era digital?
      A tecnologia está cada vez mais presente na área da saúde, e suas inovações permitem
      que você ajude seus pacientes mesmo quando eles estão longe do consultório.
      O que acha de ter uma funcionalidade de videoconferência totalmente
      integrada ao seu prontuário eletrônico, onde as principais informações
      da consulta aparecem em tempo real?
      <br />
      <strong>
        A Telemedicina já é realidade no iClinic. Conheça ela agora mesmo:
      </strong>
      <br/>
      <br/>
      <a href="https://suporte.iclinic.com.br/pt-br/teleconsulta" target='blank'>Saiba mais clicando aqui</a>
    </p>
  </div>
);

export default TelemedicineText;
