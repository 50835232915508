import { PayloadAction, createSlice, createAction } from '@reduxjs/toolkit';

import { getActionTypes } from 'shared/utils/getActionTypes';
import { EletronicSignatureState } from '../types';
import { ElectronicSignatureStep } from '../enuns';

export const initialState: EletronicSignatureState = {
  isSignatureModalOpen: false,
  screenStep: ElectronicSignatureStep.GetInfoPatient,
  pdfDownloadURL: '',
  phoneNumber: {
    value: '',
    hasError: false,
  },

  attestId: undefined,
};

const storeName = '@webapp/electronicSignature';
const name = 'electronicSignature';

const getSignatureLink = createAction(`${name}/getSignatureLink`);

const electronicSignatureSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    openElectronicSignatureModal: (
      state,
      action: PayloadAction<Partial<EletronicSignatureState>>,
    ) => ({
      ...state,
      isSignatureModalOpen: true,
      screenStep: ElectronicSignatureStep.GetInfoPatient,
      pdfDownloadURL: action.payload.pdfDownloadURL,
      clinicId: action.payload.clinicId,
      attestId: action.payload.attestId,
    }),
    closeElectronicSignatureModal: (state) => ({
      ...state,
      isSignatureModalOpen: false,
      pdfDownloadURL: '',
      signatureLink: '',
    }),
    setScreenStep: (state, action: PayloadAction<ElectronicSignatureStep>) => ({
      ...state,
      screenStep: action.payload,
    }),
    setSignatureLink: (state, action: PayloadAction<string>) => ({
      ...state,
      signatureLink: action.payload,
    }),
    setPhoneNumberError: (state, action: PayloadAction<boolean>) => ({
      ...state,
      phoneNumber: { ...state.phoneNumber!, hasError: action.payload },
    }),
    setPhoneNumber: (state, action: PayloadAction<string>) => ({
      ...state,
      phoneNumber: {
        ...state.phoneNumber,
        value: action.payload,
        hasError: false,
      },
    }),
  },
});

export const actions = {
  getSignatureLink,
  ...electronicSignatureSlice.actions,
};

export const actionTypes = getActionTypes(actions, storeName);

export default electronicSignatureSlice.reducer;
