import React from 'react';

import { MaterialUICore, Heading } from '@iclinic/design-system';
import { Participant } from 'features/video-conference/constants/types';
import { getInitials } from 'features/video-conference/utils/index';
import { CameraOffContainer } from './styles';

const { Avatar } = MaterialUICore;

interface Props {
  participant: Participant;
}

export default function CameraOff({ participant }: Props): JSX.Element {
  const { name } = participant;

  return (
    <CameraOffContainer>
      <Avatar>{getInitials(name)}</Avatar>
      <Heading variant="md">{name}</Heading>
    </CameraOffContainer>
  );
}
