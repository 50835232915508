// External
import React from 'react';
import { isInvalid } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';

// Internal
import { FORM_EDIT_CAMPAIGN } from 'shared/constants/forms';
import { AppContent } from 'features/layout';
import { EditTitle, EditCampaignContainer } from 'features/campaign';
import * as actions from 'state/campaign/actions';

const EditCampaign = ({
  saveEditedCampaign,
  cancelEditCampaign,
  match,
  invalid,
  location,
}) => {
  const isNew = (location.state && location.state.isNew) || false;
  const title = React.useMemo(
    () => (
      <EditTitle
        isNew={isNew}
        invalid={invalid}
        onClickSave={saveEditedCampaign}
        onClickCancel={cancelEditCampaign}
      />
    ),
    [saveEditedCampaign, invalid, cancelEditCampaign, isNew],
  );
  return (
    <AppContent contentTitle={title} hideSidebar hideFooter>
      <EditCampaignContainer match={match} />
    </AppContent>
  );
};

EditCampaign.propTypes = {
  saveEditedCampaign: PropTypes.func.isRequired,
  cancelEditCampaign: PropTypes.func.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  invalid: PropTypes.bool.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

const mapStateToProps = (state) => ({
  invalid: isInvalid(FORM_EDIT_CAMPAIGN)(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditCampaign);
