import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from '@iclinic/design-system';

import { monetaryFormatter } from 'shared/utils/formatters';
import {
  getCurrentSubscriptionStep,
  getPhysicians,
  getRecurrence,
} from '../../state/plans/selectors';
import {
  changeSubscriptionStep,
  updateCurrentSubscriptionStep,
  updateSelectedPhysicians,
  updateSubscriptionStepMapper,
} from '../../state/plans';
import { Product } from '../../services/types';
import * as S from './ModalSelectPhysicians.styles';
import { titleModalSelectPhysiciansByAddonSlug } from 'features/checkout/constants';

interface ModalSelectPhysiciansProps {
  addon: Product;
}

const ModalSelectPhysicians = ({ addon }: ModalSelectPhysiciansProps) => {
  const dispatch = useDispatch();
  const isAnnual = useSelector(getRecurrence);
  const currentStep = useSelector(getCurrentSubscriptionStep);
  const isOpen = currentStep === addon.slug;
  const physicians = useSelector(getPhysicians);
  const [selectedPhysicianIds, setSelectedPhysicianIds] = useState<number[]>(
    [],
  );
  const isAllPhysiciansSelected =
    selectedPhysicianIds.length === physicians.length;

  const handleProceedToPayment = () => {
    dispatch(
      updateSelectedPhysicians({
        [addon.slug]: [...selectedPhysicianIds],
      }),
    );
    dispatch(changeSubscriptionStep());
    setSelectedPhysicianIds([]);
  };

  const handleClose = () => {
    dispatch(updateSelectedPhysicians());
    dispatch(updateSubscriptionStepMapper());
    dispatch(updateCurrentSubscriptionStep());
    setSelectedPhysicianIds([]);
  };

  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    profileId: number,
  ) => {
    const { checked } = event.target;

    if (checked) {
      setSelectedPhysicianIds((previous) => [...previous, profileId]);
    } else {
      setSelectedPhysicianIds((previous) =>
        previous.filter((item) => item !== profileId),
      );
    }
  };

  const handleSelectAll = () => {
    if (isAllPhysiciansSelected) {
      setSelectedPhysicianIds([]);
    } else {
      setSelectedPhysicianIds(
        physicians.map((physician) => physician.profile_id),
      );
    }
  };
  
  const title = titleModalSelectPhysiciansByAddonSlug[addon.slug](isAnnual);

  return (
    <Dialog open={isOpen}>
      <DialogHeader title={title} onClose={handleClose} />
      <DialogContent>
        <Body variant="sm">
          Este recurso adicional acrescentará um valor de R${' '}
          {monetaryFormatter(
            isAnnual ? addon.annual_price : addon.monthly_price,
          )}{' '}
          por usuário selecionado à sua fatura. Para quais usuários você
          gostaria de adicionar este pacote de {addon.name}?
        </Body>

        <S.CheckboxesWrapper>
          <S.Label
            label={<Body variant="xs">Selecionar todos</Body>}
            control={
              <S.StyledCheckbox
                color="primary"
                name="select-all"
                checked={isAllPhysiciansSelected}
                onChange={handleSelectAll}
              />
            }
          />

          <S.Divider />

          {physicians.map((physician) => (
            <React.Fragment key={physician.name}>
              <S.Label
                label={<Body variant="xs">{physician.name}</Body>}
                control={
                  <S.StyledCheckbox
                    color="primary"
                    name={physician.name}
                    checked={selectedPhysicianIds.includes(
                      physician.profile_id,
                    )}
                    onChange={(event) =>
                      handleSelect(event, physician.profile_id)
                    }
                  />
                }
              />

              <S.Divider />
            </React.Fragment>
          ))}
        </S.CheckboxesWrapper>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="transparent">
          Cancelar
        </Button>

        <Button
          disabled={selectedPhysicianIds.length === 0}
          onClick={handleProceedToPayment}
        >
          Prosseguir com o pagamento
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalSelectPhysicians;
