import React from 'react';
import PropTypes from 'prop-types';

// Style
import style from './ListDetail.scss';

export default function ListDetail({ title, children }) {
  return (
    <div className={style.detail}>
      <p className={style.detail__title}>{title}</p>
      {children}
    </div>
  );
}

ListDetail.defaultProps = {
  title: '',
};

ListDetail.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};
