import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {
  commands,
  events,
  SavedPrescriptionEventPayload,
} from '@afyadigital/rx-engine';

import {
  createPrescription,
  fetchPrescriptionRefreshToken,
  updatePrescription,
} from '../services';
import { handlerErrors } from 'state/shared/sagas';
import * as actions from './index';
import { convertAfyaToiClinicPrescription } from '../utils';
import { captureException } from 'shared/utils/handlerErrors';
import { RecordIds } from '../types';
import { IStoreState } from 'state/rootReducer';
import { TrackActivityClickEnum, trackUserActivityClickType } from './index';

export function* tokenWorker() {
  try {
    yield put(actions.pending());

    const {
      data: { token },
    } = yield call(fetchPrescriptionRefreshToken);

    yield put(actions.success(token));
  } catch (error) {
    yield put(actions.error(error.message));
    yield call(handlerErrors, error, actions.error);
    captureException(error);
  }
}

export function* refreshTokenWorker() {
  try {
    yield put(actions.pending());

    const { data: token } = yield call(fetchPrescriptionRefreshToken);

    yield call([commands, 'updateToken', token]);

    yield put(actions.success(token));
  } catch (error) {
    yield put(actions.error(error.message));
    yield call(handlerErrors, error, actions.error);
    captureException(error);
  }
}

export function* prescriptionRevokedTokenWatcher() {
  const channel = eventChannel((emit) => {
    const listener = () => {
      emit(null);
    };

    events.listen('app:revokedToken', listener);

    return () => events.remove('app:revokedToken', listener);
  });

  yield takeLatest(channel, refreshTokenWorker);
}

export const getRecordIds = (state: IStoreState): RecordIds => ({
  eventId: state.records.root.entity.today_event.id,
  patientId: state.records.root.patient.id,
  physicianId: state.records.root.physician.id,
  clinicId: state.records.root.physician.clinicId,
});

export function eventChannelPrescriptionSaved() {
  return eventChannel((emit) => {
    const createAfyaPrescription = (AfyaRx: SavedPrescriptionEventPayload) => {
      emit(AfyaRx);
    };
    events.listen('prescription:saved', createAfyaPrescription);
    return () => events.remove('prescription:saved', createAfyaPrescription);
  });
}

export function* prescriptionSavedForkedCall(
  payload: SavedPrescriptionEventPayload,
) {
  const record: RecordIds = yield select(getRecordIds);

  const { data } = yield call(createPrescription, record);
  const iclinicPrescription = convertAfyaToiClinicPrescription(payload);
  yield call(updatePrescription, data.id, iclinicPrescription);
}

export function* prescriptionSavedWatcher() {
  const callChannel = yield call(eventChannelPrescriptionSaved);

  while (true) {
    try {
      const payload = yield take(callChannel);
      yield fork(prescriptionSavedForkedCall, payload);
    } catch (error) {
      yield call(captureException, error);
    }
  }
}

export function* watchPrescriptionFlow() {
  yield takeLatest(actions.getToken, tokenWorker);
}

type TrackUserActivityClickWatcherProps = { payload: TrackActivityClickEnum };
export function* trackUserActivityClickWatcher() {
  while (true) {
    try {
      const { payload: trackActivity } = (yield take(
        trackUserActivityClickType,
      )) as TrackUserActivityClickWatcherProps;
      yield call(commands.trackUserActivity, { click: trackActivity });
    } catch (error) {
      yield call(captureException, error);
    }
  }
}

export default function* prescriptionSagas() {
  yield all([
    fork(watchPrescriptionFlow),
    fork(prescriptionSavedWatcher),
    fork(prescriptionRevokedTokenWatcher),
    fork(trackUserActivityClickWatcher),
  ]);
}
