import { call, select, put, takeLatest } from 'redux-saga/effects';

import {
  getExportDownload,
  getSignedUrl,
} from 'services/iclinic/exportClinicData/exportDownload';
import { FetchExportDownloadRawData } from 'services/iclinic/exportClinicData/exportDownload/types';
import { getExportId } from 'features/exportClinicData/state/exportHome/selectors';
import {
  fetchExportDownload,
  fetchExportDownloadSuccess,
  fetchExportDownloadFailure,
  downloadExportFile,
  downloadExportFileFailure,
} from '.';

export function* fetchExportDownloadWorker({ payload: id }) {
  try {
    const { getResponseData, error } = yield call(getExportDownload, id);

    if (error) throw error;

    const requestedExport: FetchExportDownloadRawData = getResponseData();

    yield put(fetchExportDownloadSuccess(requestedExport));
  } catch (error) {
    yield put(fetchExportDownloadFailure());
  }
}

export function* fetchGetSignedUrlWorker() {
  try {
    const exportId: string = yield select(getExportId);

    const { getResponseData, error } = yield call(getSignedUrl, exportId);

    if (error) throw error;

    const signedUrlDownload = getResponseData();
    window.location = signedUrlDownload.url;
  } catch (error) {
    yield put(downloadExportFileFailure());
  }
}

export default function* exportDownloadWorker() {
  yield takeLatest(fetchExportDownload, fetchExportDownloadWorker);
  yield takeLatest(downloadExportFile, fetchGetSignedUrlWorker);
}
