import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isempty from 'lodash.isempty';

import { TitleCard, SubTitle, SelectHours, IconButton, LunchTime } from '..';
import WorkHours, { WeekDay } from './WorkHours';
import { timeRange } from 'shared/utils/settingsOnlineSchedule';
import style from '../../containers/ScheduleOnlineSetup.scss';
import formatMessage from '../../content';
import { MaterialUICore } from '@iclinic/design-system';
import {
  getOnlineScheduleSettings,
  getSettingsScheduleHours,
} from 'features/settings/scheduleOnline/state/selectors';
import {
  onChangeCheckDay,
  onChangeSelectHour,
  removeHour,
  addHour,
} from 'features/settings/scheduleOnline/state/actions';
import useStyles from './styles';

const { Checkbox } = MaterialUICore;

export default function WorkHoursSection() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleChangeCheckDay = (weekday: string, indexService: number) =>
    dispatch(onChangeCheckDay(weekday, indexService));
  const handleRemoveHour = (weekday: string, index: number) => {
    dispatch(removeHour(weekday, index));
  };
  const handleAddHour = (weekday: string) => {
    dispatch(addHour(weekday));
  };

  const onlineScheduleSettings = useSelector(getOnlineScheduleSettings);
  const { servicePeriods, weekdays } = onlineScheduleSettings;
  const settingsScheduleHours = useSelector(getSettingsScheduleHours);
  const {
    lunchStart,
    lunchEnd,
    startService,
    endService,
  } = settingsScheduleHours;

  const options = !isempty(settingsScheduleHours)
    ? timeRange(startService, endService, 30)
    : [];

  const formatHour = (hour: string) => hour.substring(0, 5);
  const onChangeHour = (index: number, weekDay: string) => (
    e: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const { value, id } = e.target;

    dispatch(onChangeSelectHour(index, id, value, weekDay));
  };

  return (
    <div id="hours">
      <TitleCard
        label={formatMessage({ id: '@online-scheduling:work-hours-label' })}
      />
      <SubTitle
        label={formatMessage({ id: '@online-scheduling:work-hours-text' })}
      />
      <div className={classes.flexContainer}>
        {weekdays.map((weekday, index) => {
          const weekDayLower = weekday.toLowerCase();
          if (servicePeriods[weekDayLower].length === 0) {
            return (
              <WorkHours
                key={weekDayLower}
                weekDay={weekday}
                checkDay={
                  <Checkbox
                    name={weekday}
                    onChange={() => handleChangeCheckDay(weekday, index)}
                    checked={false}
                  />
                }
                renderStartTime={
                  <SelectHours
                    id="start_time"
                    value=""
                    onChange={onChangeHour(index, weekday)}
                    options={options}
                    disabled
                  />
                }
                renderEndTime={
                  <SelectHours
                    id="end_time"
                    value=""
                    onChange={onChangeHour(index, weekday)}
                    options={options}
                    disabled
                  />
                }
                renderAside={
                  <IconButton
                    icon="add"
                    className={style.card__deleteIcon}
                    onClick={() => removeHour(weekday, index)}
                  />
                }
                renderAsideAdd={
                  <IconButton
                    icon="add-circle"
                    className={style.card__addIcon}
                    onClick={() => addHour(weekday)}
                  />
                }
              />
            );
          }
          /* eslint-disable react/no-array-index-key */
          return servicePeriods[
            weekDayLower
          ].map((workHour: WeekDay, indexService: number) => (
            <WorkHours
              key={`${weekday}-${indexService}`}
              weekDay={indexService === 0 && weekday}
              checkDay={
                indexService === 0 && (
                  <Checkbox
                    name={weekday}
                    onChange={() => handleChangeCheckDay(weekday, indexService)}
                    color="primary"
                    checked
                  />
                )
              }
              renderStartTime={
                <SelectHours
                  id="start_time"
                  value={formatHour(workHour.start_time)}
                  onChange={onChangeHour(indexService, weekday)}
                  options={options}
                  disabled={false}
                />
              }
              renderEndTime={
                <SelectHours
                  id="end_time"
                  value={formatHour(workHour.end_time)}
                  onChange={onChangeHour(indexService, weekday)}
                  options={options}
                  disabled={false}
                />
              }
              renderAside={
                <IconButton
                  icon="add"
                  className={style.card__deleteIcon}
                  onClick={() => handleRemoveHour(weekday, indexService)}
                />
              }
              renderAsideAdd={
                indexService === 0 && (
                  <IconButton
                    icon="add-circle"
                    className={style.card__addIcon}
                    onClick={() => handleAddHour(weekday)}
                  />
                )
              }
            />
          ));
        })}
        <LunchTime startTime={lunchStart} endTime={lunchEnd} />
      </div>
    </div>
  );
}
