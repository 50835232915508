import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  MaterialUICore,
  MaterialIcons,
  Button,
  Typography,
} from '@iclinic/design-system';
import {
  clearSignPDFErrors,
  toggleDrawer,
  finalizeMedicalRecordSuccess,
} from 'state/records/signature/actions';
import { clearSharedErrors } from 'state/shared/actions';
import { getLoading } from 'state/records/signature/selectors';
import { getCurrentEventAndPatientId } from 'state/records/selectors';
import { getEventBlocksIds } from 'state/records/actions';
import NotificationErrors from '../components/NotificationErrors';
import { toggleState } from './constants';
import useSignature from '../hooks/useSignature';
import useStyles from './styleSignatureSelectCertificates';

const {
  Drawer,
  FormControl,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
} = MaterialUICore;

const SignatureSelectCertificates: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { CLOSE } = toggleState;
  const { listCertificates, readCertificate, onChangeSelectCertificate } =
    useSignature();
  const isLoading = useSelector(getLoading);
  const isOpenDrawer = useSelector(
    (state) => state.records.signature.isOpenDrawer,
  );
  const thumbprint = useSelector((state) => state.records.signature.thumbprint);
  const { eventId, patientId } = useSelector(getCurrentEventAndPatientId);

  const onClickToggleDrawer = useCallback(
    (drawerState: boolean) => {
      dispatch(toggleDrawer(drawerState));
    },
    [dispatch],
  );

  const onBackButton = () => {
    dispatch(clearSignPDFErrors());
    dispatch(clearSharedErrors());
    dispatch(toggleDrawer(CLOSE));
  };

  const onClickFinalize = useCallback(() => {
    dispatch(clearSignPDFErrors());
    dispatch(clearSharedErrors());
    readCertificate();
  }, [dispatch, readCertificate]);

  useEffect(() => {
    dispatch(getEventBlocksIds(eventId, patientId));
  }, [dispatch, eventId, patientId]);

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="persistent"
      anchor="right"
      open={isOpenDrawer}
      onClose={() => onClickToggleDrawer(CLOSE)}
      data-testid="signature-select-certificates"
    >
      <div className={classes.content}>
        <Button
          disabled={isLoading}
          className={classes.backButton}
          startIcon={<MaterialIcons.ArrowBack />}
          onClick={onBackButton}
          color="transparent"
        >
          <Typography variant="overline">Voltar</Typography>
        </Button>
        <div className={classes.mainContent}>
          <Typography variant="h3" className={classes.title}>
            Assinatura Digital
          </Typography>
          <div className={classes.certificates}>
            <Typography variant="body2" className={classes.bodyText}>
              Escolha seu certificado
            </Typography>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="certificates"
                name="listcertificates"
                value={thumbprint}
                onChange={onChangeSelectCertificate}
                className={classes.formControl}
              >
                {listCertificates.certificates?.map((certificate) => (
                  <FormControlLabel
                    className={
                      certificate.expired
                        ? classes.radioLabelDisabled
                        : classes.radioLabel
                    }
                    value={certificate.thumbprint}
                    control={
                      <Radio color="primary" className={classes.radio} />
                    }
                    label={certificate.subjectName}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <NotificationErrors />
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <Button
          disabled={isLoading}
          className={classes.closeButton}
          onClick={() => dispatch(finalizeMedicalRecordSuccess())}
          data-ga="assinatura_digital-assinatura-prosseguir_sem_assinar"
          color="transparent"
        >
          <Typography variant="body1">Prosseguir sem assinar</Typography>
        </Button>
        <Button
          disabled={isLoading}
          className={classes.footerButton}
          variant="contained"
          color="primary"
          type="submit"
          data-ga="assinatura_digital-assinatura-continuar"
          onClick={onClickFinalize}
        >
          {isLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          Continuar
        </Button>
      </div>
    </Drawer>
  );
};

export default SignatureSelectCertificates;
