import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { MaterialIcons, MaterialUICore } from '@iclinic/design-system';

import { ActionContent, ButtonBox, IconButton } from './index.styles';
import { actions } from '../../state';
import { toggleModal } from 'state/marketing/emailFlow/root/actions';
import { getUserInfo } from 'state/records/selectors';
import { getPatientHeader } from '../../state/selectors';
import SUBSCRIPTION_PERMISSIONS from 'shared/constants/subscriptionPermissions';

const { useTheme, useMediaQuery } = MaterialUICore;
const { FolderSharedRounded, PhoneInTalkRounded, EmailRounded } = MaterialIcons;

const ActionButtons = () => {
  const dispatch = useDispatch();
  const { permissions } = useSelector(getUserInfo);
  const { mobilePhone, homePhone } = useSelector(getPatientHeader);

  const theme = useTheme();
  const isLayoutMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonSize = isLayoutMobile ? 'xl' : 'md';

  const hasEmailFlow = permissions?.includes(
    SUBSCRIPTION_PERMISSIONS.EmailFlow,
  );
  const callPhone = mobilePhone || homePhone;

  const redirectPhone = () => {
    window.open(`tel: ${callPhone}`, '_blank');
  };

  return (
    <ActionContent>
      <ButtonBox>
        <IconButton
          label="Visualizar Cadastro"
          tooltip="left"
          size={buttonSize}
          data-ga="medical_record_view_patient_personal_data"
          data-sp="medical_record_view_patient_personal_data"
          onClick={() => dispatch(actions.togglePatientHeaderModal())}
        >
          <FolderSharedRounded />
        </IconButton>
        {callPhone && isMobile && (
          <IconButton
            label="Ligar para o paciente"
            tooltip="left"
            size={buttonSize}
            data-ga="medical_record_call_patient"
            data-sp="medical_record_call_patient"
            onClick={redirectPhone}
          >
            <PhoneInTalkRounded />
          </IconButton>
        )}
        {hasEmailFlow && (
          <IconButton
            label="Inserir e-mail automático "
            tooltip="left"
            size={buttonSize}
            data-ga="medical_record_add_email_flow"
            data-sp="medical_record_add_email_flow"
            onClick={() => dispatch(toggleModal())}
          >
            <EmailRounded />
          </IconButton>
        )}
      </ButtonBox>
    </ActionContent>
  );
};

export default ActionButtons;
