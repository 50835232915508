import React from 'react';
import PropTypes from 'prop-types';

import SelectableList from '../selectableList';

export default function Benefit({ children }) {
  return (
    <SelectableList>
      <SelectableList.Content title="Benefícios">
        {children}
      </SelectableList.Content>
    </SelectableList>
  );
}

Benefit.propTypes = {
  children: PropTypes.node.isRequired,
};
