import React, { useState } from 'react';
import { Button } from '@iclinic/design-system';

import ShortcutModal from '../ShortcutModal';

const ShortcutButton = (): JSX.Element => {
  const [isShown, setIsShown] = useState(false);

  const handleOnClose = () => setIsShown(false);
  const handleOnClick = () => setIsShown(true);

  return (
    <>
      <Button onClick={handleOnClick} size="small" color="transparent">
        Ver atalhos
      </Button>
      <ShortcutModal open={isShown} onClose={handleOnClose} />
    </>
  );
};

export default ShortcutButton;
