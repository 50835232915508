import { Box as BoxUI, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const FlexColumnBox = styled(BoxUI)({
  display: 'flex',
  flexDirection: 'column',
});

export const CenterBox = styled(BoxUI)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '560px',
});

export const SpaceBetweenBox = styled(BoxUI)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
