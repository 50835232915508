/* eslint-disable sonarjs/no-identical-functions */
// External
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Internals
import { formatNumberToDecimal } from 'shared/utils/strings';
import ListDetail from 'ui/new/list-detail';
import Payment from 'ui/new/payment';
import Text from 'ui/new/text';
import * as actions from 'state/upgradeAddon/actions';
import Total from 'features/billing/components/summary/Total';
import { URL_INFO_MONTHLY } from 'state/billing/constants';
import { CUSTOM_TEXT } from '../../billing/components/summary/constants';

// components
import Summary from '../../billing/components/summary/Summary';
import Header from '../../billing/components/summary/Header';
import Footer from '../../billing/components/summary/Footer';

class SummaryAddon extends PureComponent {
  componentWillMount = () => {
    const { fetchSummary } = this.props;
    fetchSummary();
  };

  render() {
    const {
      // store
      summary,
      isFetching,
    } = this.props;
    return (
      <>
        {!isFetching && summary ? (
          <Summary>
            <Header>
              <ListDetail title="Antiga assinatura iClinic">
                {summary.currentSubscription.map((subscription) => (
                  <ListDetail.Row key={Math.random()}>
                    <Text>{subscription.text}</Text>
                    <Payment.Price
                      text={CUSTOM_TEXT.normal}
                      promotion={
                        subscription.promotionValue
                        && formatNumberToDecimal(subscription.promotionValue)
                      }
                      total={
                        subscription.totalValue
                        && formatNumberToDecimal(subscription.totalValue)
                      }
                    />
                  </ListDetail.Row>
                ))}
              </ListDetail>

              <ListDetail title="Nova assinatura iClinic">
                {summary.newSubscription.map((subscription) => (
                  <ListDetail.Row key={Math.random()}>
                    <Text>{subscription.text}</Text>
                    <Payment.Price
                      text={CUSTOM_TEXT.normal}
                      promotion={
                        subscription.promotionValue
                        && formatNumberToDecimal(subscription.promotionValue)
                      }
                      total={
                        subscription.totalValue
                        && formatNumberToDecimal(subscription.totalValue)
                      }
                    />
                  </ListDetail.Row>
                ))}
              </ListDetail>
            </Header>

            <Footer>
              <Total price={`R$${formatNumberToDecimal(summary.total)}`} text="Total: " />
              <Payment
                title="Dados de pagamento"
                payment={summary.paymentInfo}
                moreInfoUrl={URL_INFO_MONTHLY}
              />
            </Footer>
          </Summary>
        ) : (
          <div />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.upgradeAddon,
});

SummaryAddon.defaultProps = {
  summary: null,
};

SummaryAddon.propTypes = {
  summary: PropTypes.shape({
    newSubscription: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
      }),
    ),
    currentSubscription: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
      }),
    ),
    total: PropTypes.string.isRequired,
    paymentInfo: PropTypes.shape({
      paymentSlip: PropTypes.bool.isRequired,
      card: PropTypes.shape({
        flag: PropTypes.string,
        number: PropTypes.string,
      }),
    }),
  }),
  isFetching: PropTypes.bool.isRequired,
  fetchSummary: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  actions,
)(SummaryAddon);
