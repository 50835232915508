import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { captureException } from '@sentry/browser';

import { actions } from '.';
import { getElectronicSignatureState, getUserInfo } from './selectors';
import {
  EletronicSignatureState,
  GetSignatureLinkRequestBody,
  GetSignatureLinkResponse,
} from '../types';
import {
  simpleGet,
  getSignatureLink as getSignatureLinkService,
} from './services';
import { patientInfo } from 'state/records/selectors';
import { ElectronicSignatureStep } from '../enuns';

const {
  getSignatureLink,
  setScreenStep,
  setSignatureLink,
  openElectronicSignatureModal: openHighlightsInfoModal,
  setPhoneNumber,
} = actions;

export function* workerOpenElectronicSignatureModal() {
  const { mobile_phone } = yield select(patientInfo);
  yield put(setPhoneNumber(mobile_phone));
}

export function* workerGetSignatureLink() {
  yield put(setScreenStep(ElectronicSignatureStep.GenerateLink));
  const { pdfDownloadURL, clinicId, attestId }: EletronicSignatureState =
    yield select(getElectronicSignatureState);
  const { physicianId, userName } = yield select(getUserInfo);
  const { id: patientId, name: patientName } = yield select(patientInfo);
  try {
    const { data: getPDFFileData } = yield call(simpleGet, pdfDownloadURL!);
    const { data: signatureLink }: GetSignatureLinkResponse = yield call(
      getSignatureLinkService,
      {
        document: getPDFFileData.url,
        document_name: `${physicianId}-${userName}-${attestId!}.pdf`,
        patient_id: patientId,
        patient_name: patientName,
        clinic_id: clinicId,
        attest_id: attestId,
        physician_id: physicianId,
      } as GetSignatureLinkRequestBody,
    );
    yield put(setSignatureLink(signatureLink.access_link));
    yield put(setScreenStep(ElectronicSignatureStep.SendToPatient));
  } catch (error) {
    yield call(captureException, error);
    yield put(setScreenStep(ElectronicSignatureStep.GetInfoPatient));
  }
}

export default function* electronicSignatureSagas() {
  yield all([
    takeLatest(getSignatureLink, workerGetSignatureLink),
    takeLatest(openHighlightsInfoModal, workerOpenElectronicSignatureModal),
  ]);
}
