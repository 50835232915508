// External
import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@material/react-material-icon';

// Style
import style from './Tag.scss';

const Tag = ({ input: { value }, onDelete, renderLabel }) => (
  <div className={style.tag__roundBox}>
    <span className={style.tag__title}>
      {renderLabel(value)}
    </span>
    <button type="button" className={style.tag__closeButton} onClick={onDelete}>
      <Icon className={style.tag__icon} icon="cancel" />
    </button>
  </div>
);

Tag.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  renderLabel: PropTypes.func.isRequired,
};

export default Tag;
