import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { tabsValue } from 'features/video-conference/constants';
import {
  Annotation,
  HandleSetAnnotation,
} from 'features/video-conference/constants/types';
import { formatNotes } from 'features/video-conference/utils';
import { fetchUpdateAnnotations } from 'features/video-conference/services';
import { debounce } from 'shared/hooks/';

interface AnnotationProviderProps {
  children: React.ReactNode;
}

type AnnotationContextType = {
  isAnnotationWindowOpen: boolean;
  setIsAnnotationWindowOpen: (isAnnotationWindowOpen: boolean) => void;
  activeTab: string;
  setActiveTab: (tab: string) => void;
  clinicNotes: Annotation | null;
  setClinicNotes: (notes: Annotation) => void;
  patientNotes: Annotation | null;
  setPatientNotes: (notes: Annotation) => void;
  handleSaveNotes: (notes: Annotation) => void;
  handleFetchUpdateAnnotation: (notes: Annotation) => void;
  handleSetAnnotations: (
    patientNotes: HandleSetAnnotation,
    physicianNotes: HandleSetAnnotation,
  ) => void;
};

export const AnnotationContext = createContext<AnnotationContextType>(null!);

export const AnnotationProvider = ({ children }: AnnotationProviderProps) => {
  const [isAnnotationWindowOpen, setIsAnnotationWindowOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(tabsValue.CLINIC_NOTE);
  const [patientNotes, setPatientNotes] = useState<Annotation | null>(null);
  const [clinicNotes, setClinicNotes] = useState<Annotation | null>(null);

  const transformAnnotationListToObject = (annotations: Annotation[]) => {
    const noteMessage = annotations
      .map((annotation) => annotation.message)
      .join('');

    return {
      at: annotations[0].at,
      message: noteMessage,
    } as Annotation;
  };

  const handleSetAnnotations = useCallback(
    (
      participantNotes: HandleSetAnnotation,
      physicianNotes: HandleSetAnnotation,
    ) => {
      if (participantNotes && participantNotes.length > 0)
        setPatientNotes(transformAnnotationListToObject(participantNotes));

      if (physicianNotes && physicianNotes.length > 0)
        setClinicNotes(transformAnnotationListToObject(physicianNotes));
    },
    [],
  );

  const handleFetchUpdateAnnotation = useCallback(
    debounce((notes: Annotation) => {
      if (activeTab === tabsValue.CLINIC_NOTE)
        fetchUpdateAnnotations({ physicianNotes: formatNotes(notes) });

      if (activeTab === tabsValue.PATIENT_NOTE)
        fetchUpdateAnnotations({ patientNotes: formatNotes(notes) });
    }, 1500),
    [activeTab],
  );

  const handleSaveNotes = useCallback(
    async (notes: Annotation) => {
      if (activeTab === tabsValue.CLINIC_NOTE) {
        setClinicNotes(notes);
        await fetchUpdateAnnotations({
          physicianNotes: formatNotes(notes),
        });
      }

      if (activeTab === tabsValue.PATIENT_NOTE) {
        setPatientNotes(notes);
        await fetchUpdateAnnotations({ patientNotes: formatNotes(notes) });
      }
    },
    [activeTab],
  );

  const contextValue = useMemo(
    () => ({
      isAnnotationWindowOpen,
      setIsAnnotationWindowOpen,
      activeTab,
      setActiveTab,
      patientNotes,
      setPatientNotes,
      clinicNotes,
      setClinicNotes,
      handleSaveNotes,
      handleFetchUpdateAnnotation,
      handleSetAnnotations,
    }),
    [
      activeTab,
      clinicNotes,
      handleFetchUpdateAnnotation,
      handleSaveNotes,
      handleSetAnnotations,
      isAnnotationWindowOpen,
      patientNotes,
    ],
  );

  return (
    <AnnotationContext.Provider value={contextValue}>
      {children}
    </AnnotationContext.Provider>
  );
};

export function useAnnotationContext(): AnnotationContextType {
  const context = useContext(AnnotationContext);

  if (!context) {
    throw new Error(
      'useAnnotationContext must be used within an AnnotationProvider',
    );
  }
  return context;
}
