import moment from 'moment';

import { Channels } from '../constants/urls';

interface ReferralUrlParams {
  referralCode: string;
  promoCode: string;
  channel: Channels;
}

export const parseIndicationUrl = ({
  referralCode,
  promoCode,
  channel,
}: ReferralUrlParams) => {
  moment.locale('pt-br');
  const currentDate = moment(new Date()).format('MMMYY');
  const refSource = channel === Channels.COPY ? 'copy' : channel;

  return `https://lps.iclinic.com.br/teste-gratis/?referralCode=${referralCode}&refSource=${refSource}&utm_medium=mkt_email&utm_source=iclinic&utm_campaign=${channel}_mkt_${currentDate}&utm_term=referral_link__naodefinido&hs_marketing_medium=mkt_email&hs_marketing_source=iclinic&promo=${promoCode}`;
};
