import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import {
  ClinicPhysicians,
  ExportRequest,
  ExportClinic,
  ExportPhysician,
  ExportResource,
} from './types';
import {
  FETCH_EXPORTREQUEST_ERROR,
  FETCH_CLINICPHYSICIANS_ERROR,
} from './errorMessages';

export interface ExportRequestState {
  duplicatedRequest?: boolean;
  newRequest?: {
    data?: ExportRequest | null;
    status: RequestStatus | null;
    error?: string;
  };
  clinicPhysicians?: {
    data?: ClinicPhysicians[] | null;
    status: RequestStatus;
    error?: string;
  };
  selectedSubscriptionId?: number | null;
  selectedClinic?: ExportClinic | null;
  selectedAllPhysicians?: boolean;
  selectedPhysicians?: ExportPhysician[] | null;
  selectedResources?: ExportResource[];
  selectedAllResources?: boolean;
  selectedPeriod?: string;
  selectedStartPeriod?: string | null;
  selectedEndPeriod?: string | null;
  selectedFormat?: string;
  selectedFilterPatientsByPhysicians?: boolean;
}

export const initialState: ExportRequestState = {
  duplicatedRequest: false,
  newRequest: {
    data: null,
    status: null,
  },
  clinicPhysicians: {
    data: null,
    status: RequestStatus.Pending,
  },
  selectedSubscriptionId: null,
  selectedClinic: null,
  selectedPhysicians: null,
  selectedResources: [
    {
      name: 'patient',
      label: 'Pacientes',
      checked: false,
    },
    {
      name: 'record',
      label: 'Prontuários',
      checked: false,
    },
    {
      name: 'event',
      label: 'Agendamentos',
      checked: false,
    },
    {
      name: 'bill',
      label: 'Financeiro',
      checked: false,
    },
    {
      name: 'contact',
      label: 'Contatos',
      checked: false,
    },
  ],
  selectedAllResources: false,
  selectedAllPhysicians: false,
  selectedPeriod: 'all',
  selectedStartPeriod: null,
  selectedEndPeriod: null,
  selectedFormat: 'csv',
  selectedFilterPatientsByPhysicians: false,
};

const name = 'ExportRequest';

export const init = createAction(`${name}/init`);

const RequestSlice = createSlice({
  name,
  initialState,
  reducers: {
    sendNewExportRequest: (state) => ({
      ...state,
      newRequest: {
        status: RequestStatus.Pending,
      },
    }),
    sendNewExportRequestSuccess: (state) => ({
      ...state,
      newRequest: {
        status: RequestStatus.Success,
      },
    }),
    sendNewExportRequestFailure: (state) => ({
      ...state,
      newRequest: {
        status: RequestStatus.Error,
        error: FETCH_EXPORTREQUEST_ERROR,
      },
    }),
    setDuplicatedExport: (state, action: PayloadAction<boolean>) => ({
      ...state,
      duplicatedRequest: action.payload,
    }),
    fetchClinicPhysicians: (state) => ({
      ...state,
      clinicPhysicians: {
        status: RequestStatus.Pending,
      },
    }),
    fetchClinicPhysiciansSuccess: (
      state,
      action: PayloadAction<ClinicPhysicians[]>,
    ) => ({
      ...state,
      clinicPhysicians: {
        data: action.payload,
        status: RequestStatus.Success,
      },
    }),
    fetchClinicPhysiciansFailure: (state) => ({
      ...state,
      clinicPhysicians: {
        status: RequestStatus.Error,
        error: FETCH_CLINICPHYSICIANS_ERROR,
      },
    }),
    changeSelectedClinic: (state, action: PayloadAction<ExportClinic>) => ({
      ...state,
      selectedClinic: action.payload,
    }),
    changeSelectedPhysicians: (
      state,
      action: PayloadAction<ExportPhysician[]>,
    ) => ({
      ...state,
      selectedPhysicians: action.payload,
    }),
    togglePhysicianCheckbox: (
      state,
      action: PayloadAction<ExportPhysician[] | undefined>,
    ): ExportRequestState => ({
      ...state,
      selectedPhysicians: action.payload,
    }),
    changeSelectedAllPhysicians: (state, action: PayloadAction<boolean>) => ({
      ...state,
      selectedAllPhysicians: action.payload,
    }),
    changeSelectedSubscriptionId: (state, action: PayloadAction<number>) => ({
      ...state,
      selectedSubscriptionId: action.payload,
    }),
    changeSelectedResources: (
      state,
      action: PayloadAction<ExportResource[]>,
    ) => ({
      ...state,
      selectedResources: action.payload,
    }),
    changeSelectedAllResources: (state, action: PayloadAction<boolean>) => ({
      ...state,
      selectedAllResources: action.payload,
    }),
    toggleResourceCheckbox: (
      state,
      action: PayloadAction<ExportResource[] | undefined>,
    ): ExportRequestState => ({
      ...state,
      selectedResources: action.payload,
    }),
    changeSelectedPeriod: (state, action: PayloadAction<string>) => ({
      ...state,
      selectedPeriod: action.payload,
    }),
    changeSelectedStartPeriod: (state, action: PayloadAction<string>) => ({
      ...state,
      selectedStartPeriod: action.payload,
    }),
    changeSelectedEndPeriod: (state, action: PayloadAction<string>) => ({
      ...state,
      selectedEndPeriod: action.payload,
    }),
    changeSelectedFormat: (state, action: PayloadAction<string>) => ({
      ...state,
      selectedFormat: action.payload,
    }),
    changeSelectedFilterPatientsByPhysicians: (
      state,
      action: PayloadAction<boolean>,
    ) => ({
      ...state,
      selectedFilterPatientsByPhysicians: action.payload,
    }),
  },
});

export default RequestSlice.reducer;

export const {
  setDuplicatedExport,
  sendNewExportRequest,
  sendNewExportRequestSuccess,
  sendNewExportRequestFailure,
  fetchClinicPhysicians,
  fetchClinicPhysiciansSuccess,
  fetchClinicPhysiciansFailure,
  changeSelectedClinic,
  changeSelectedPhysicians,
  togglePhysicianCheckbox,
  changeSelectedAllPhysicians,
  changeSelectedSubscriptionId,
  changeSelectedResources,
  changeSelectedAllResources,
  toggleResourceCheckbox,
  changeSelectedPeriod,
  changeSelectedStartPeriod,
  changeSelectedEndPeriod,
  changeSelectedFormat,
  changeSelectedFilterPatientsByPhysicians,
} = RequestSlice.actions;
