import React from 'react';

import Filters from 'features/stock/components/Filters';
import { StockTabs } from 'features/stock/constants';

const { Item } = Filters;

type TabsProps = {
  selectedFilter: StockTabs | null;
  onChangeTab(tab: StockTabs): void;
};

export default function Tabs({
  selectedFilter,
  onChangeTab,
}: TabsProps): JSX.Element {
  return (
    <Filters>
      <Item
        selected={selectedFilter === StockTabs.All}
        onClick={() => onChangeTab(StockTabs.All)}
      >
        Todos
      </Item>
      <Item
        selected={selectedFilter === StockTabs.LowStock}
        onClick={() => onChangeTab(StockTabs.LowStock)}
      >
        Estoque Baixo
      </Item>
      <Item
        selected={selectedFilter === StockTabs.ExpiredInOneMonth}
        onClick={() => onChangeTab(StockTabs.ExpiredInOneMonth)}
      >
        Vence em 30 dias
      </Item>
      <Item
        selected={selectedFilter === StockTabs.Expired}
        onClick={() => onChangeTab(StockTabs.Expired)}
      >
        Produtos Vencidos
      </Item>
    </Filters>
  );
}
