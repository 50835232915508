import React from 'react';
import { useSelector } from 'react-redux';

import {
  Body,
  Button,
  DialogActions,
  DialogContent,
} from '@iclinic/design-system';
import { image } from 'shared/utils/urlGetter';
import { ImageWrapper } from './Dialog.styles';
import { getPaymentDay } from '../state/selectors';

export const DialogSuccess = () => {
  const paymentDay = useSelector(getPaymentDay);

  const onClickHandler = () => window.location.reload();

  return (
    <>
      <DialogContent>
        <ImageWrapper>
          <img
            width={160}
            src={image('subscription.changeDateSuccess')}
            alt="Venha ganhar mais"
          />
        </ImageWrapper>
        <Body variant="xs">
          A partir de agora, a data de vencimento do pagamento de sua assinatura
          passa a ser <strong>dia {paymentDay} de cada mês.</strong>
        </Body>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClickHandler} variant="contained">
          Finalizar
        </Button>
      </DialogActions>
    </>
  );
};
