import { Action, Dispatch } from 'redux';

import { actions } from 'features/tissInvoicing/state/expense';
import { FetchExpenseAutocompletePayload } from 'features/tissInvoicing/types';

const fetchExpenseDetails = (
  dispatch: Dispatch<Action<any>>,
  expenseId: string,
) => {
  if (!expenseId) {
    return;
  }

  dispatch(actions.getExpenseDetailsFetch({ id: expenseId }));
};

const fetchListOfTables = (dispatch: Dispatch<Action<any>>) => {
  const payload: FetchExpenseAutocompletePayload = {
    endpoint: '/domains/expensetype/',
    isTissApi: true,
    name: 'typeOptions',
    params: {},
  };
  const payloadUnits: FetchExpenseAutocompletePayload = {
    endpoint: '/domains/unit/',
    isTissApi: true,
    name: 'unitTypeOptions',
    params: {},
  };

  dispatch(actions.fetchExpenseAutocomplete(payload));
  dispatch(actions.fetchExpenseAutocomplete(payloadUnits));
};

const fetchListOfHealthInsurances = (dispatch: Dispatch<Action<any>>) => {
  const payload: FetchExpenseAutocompletePayload = {
    endpoint: '/clinics/clinichealthinsurance/',
    name: 'healthInsuranceOptions',
    isTissApi: false,
    params: {},
  };

  dispatch(actions.fetchExpenseAutocomplete(payload));
};

export const fetchListOfHealthInsuranceAndTables = (
  dispatcher: Dispatch<Action<any>>,
  expenseId?: string,
) => {
  fetchListOfHealthInsurances(dispatcher);
  fetchListOfTables(dispatcher);
  if (expenseId) {
    fetchExpenseDetails(dispatcher, expenseId);
  }
};
