import axios from 'axios';

import {
  interceptorRequestAuthenticationHeaders,
  interceptorResponseErrorsPromise,
  interceptorResponseTransformData,
} from 'services/iclinic/interceptors';
import {
  GetSignatureLinkRequestBody,
  GetSignatureLinkResponse,
  GetSignatureStatusResponse,
} from '../types';
import { jsonToFormData } from '../utils';

export const electronicSignatureApi = axios.create({
  baseURL: process.env.ICLINIC_PEP_API_URL,
});

electronicSignatureApi.interceptors.request.use(
  interceptorRequestAuthenticationHeaders,
);
electronicSignatureApi.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrorsPromise,
);

const axiosGet = axios.create();
axiosGet.interceptors.request.use(interceptorRequestAuthenticationHeaders);

export const simpleGet = (pdfFileUrl: string) => axiosGet.get(pdfFileUrl);

export const getSignatureLink = (body: GetSignatureLinkRequestBody) =>
  electronicSignatureApi.post<GetSignatureLinkResponse>(
    '/api/v1/signature/create_from_url/',
    jsonToFormData(body),
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

export const getSignatureStatus = (attestId: number) =>
  electronicSignatureApi.get<GetSignatureStatusResponse>(
    `api/v1/signature?attest_id=${attestId}`,
  );
