import iClinicApi from 'services/iclinic/iClinicApi';
import {
  GetPhysiciansArgs,
  PreviewMessageParams,
  ReadAlertArgs,
} from '../types';

export const getPhysicians = ({
  byTemplateKind,
  returnAll,
}: GetPhysiciansArgs = {}) => {
  const params = [];

  if (byTemplateKind) {
    params.push(`kind_template=${byTemplateKind}`);
  }

  if (returnAll) {
    params.push(`return_all=true`);
  }

  return iClinicApi.get(`/messages/templates/physicians/?${params.join('&')}`);
};

export const getPreviewMessage = ({
  kind,
  physicianId,
  template,
}: PreviewMessageParams) =>
  iClinicApi.post(
    `/messages/templates/${kind}/${physicianId}/preview/`,
    template,
  );

export const getMenuPermissions = () => iClinicApi.get('/messages/menus/');

export const getVisitedMessagingFeature = () =>
  iClinicApi.get('/features/featureuser/?name=message-whatsapp');

export const postVisitedMessagingFeature = (featureId: number) =>
  iClinicApi.patch(`/features/featureuser/${featureId}/`, { state: true });

export const postReadAlertTemplateUpdate = ({
  kind,
  physicianId,
}: ReadAlertArgs) =>
  iClinicApi.post(
    `/messages/templates/${kind}/${physicianId}/disable_warning/`,
  );
