import React, { useMemo, useState } from 'react';

import { FILE_EXTENSIONS, splitBase64 } from 'shared/utils/file';
import { MaterialIcons } from '@iclinic/design-system';
import useStyles from './styles';

const { PictureAsPdf, InsertDriveFile } = MaterialIcons;

type Props = {
  value: string;
};

function isImage(contentType: string | null) {
  return (
    contentType === FILE_EXTENSIONS.JPEG ||
    contentType === FILE_EXTENSIONS.JPG ||
    contentType === FILE_EXTENSIONS.PNG
  );
}

export default function Thumb({ value }: Props) {
  const [loadFail, setLoadFail] = useState<boolean>();
  const classes = useStyles();
  const contentType = useMemo(() => {
    try {
      const [dataType] = splitBase64(value);
      return dataType.replace('data:', '');
    } catch (error) {
      return null;
    }
  }, [value]);

  if (contentType === FILE_EXTENSIONS.PDF) {
    return <PictureAsPdf className={classes.iconThumb} />;
  }

  if (!loadFail && (isImage(contentType) || !contentType)) {
    return (
      <img
        src={value}
        alt="Miniatura da imagem"
        onError={() => setLoadFail(true)}
        aria-hidden
      />
    );
  }

  return <InsertDriveFile className={classes.iconThumb} />;
}
