import {
  MaterialUICore,
  tokens,
  Body,
  Heading,
  Button as DSButton,
  Paper as DSPaper,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { color, spacing } = tokens;

export const Paper = styled(DSPaper)(() => ({
  padding: spacing.sm,

  '& svg': {
    color: color.neutral[500],
    fontSize: '16px',
  },
}));

export const Title = styled(Body)(() => ({
  color: color.primary.main,
  marginBottom: spacing.sm,
}));

export const Text = styled(Body)(() => ({
  margin: `0 ${spacing.nano}`,
}));

export const WrapIcon = styled('div')(() => ({
  '& svg': {
    color: color.primary.main,
  },
}));

export const Amount = styled(Heading)(() => ({
  marginBottom: spacing.sm,
}));

export const LinkButton = styled(DSButton)(() => ({
  marginBottom: spacing.nano,

  '&:focus': {
    outline: 'none',
    textDecoration: 'none',
    color: tokens.color.neutral[0],
  },

  '&:hover': {
    color: tokens.color.neutral[0],
  },
}));
