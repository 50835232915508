import React from 'react';

import { Alert, Box, MaterialIcons } from '@iclinic/design-system';

const { ErrorOutlineOutlined } = MaterialIcons;

const ErrorMessage = () => {
  return (
    <Box mb={3}>
      <Alert
        severity="error"
        icon={<ErrorOutlineOutlined />}
        message="Infelizmente estamos com um problema técnico. Se possível, tente atualizar seus dados mais tarde."
      />
    </Box>
  );
};

export default ErrorMessage;
