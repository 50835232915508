import React, { useEffect, useState } from 'react';
import {
  Body,
  Button,
  CircularProgress,
  Heading,
  MaterialUICore,
  MaterialIcons as Icons,
  TextField,
} from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { UserInfo } from '@types';

import history from 'routes/history';
import { getPhysiciansData } from '../../../state/messagePackage/checkout/selectors';
import { Physician } from '../../../services/types';
import {
  fetchPhysiciansAvailable,
  setSelectedPhysicians,
} from 'features/messaging/state/messagePackage/checkout';
import * as S from './SelectionOfProfessionals.styles';
import { getUserData } from 'state/userInfo/actions';

const { List, ListItem, ListItemText, ListItemIcon } = MaterialUICore;

const SelectionOfProfessionals = () => {
  const dispatch = useDispatch();
  const { isFetchingPhysicians, physicians } = useSelector(getPhysiciansData);
  const { userInfo } = useSelector(
    (state) => getUserData(state).payload.userData,
  ) as {
    userInfo: UserInfo;
  };

  const [checkedPhysicians, setCheckedPhysicians] = useState<Physician[]>([]);
  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fetchPhysiciansAvailable());
  }, [dispatch]);

  useEffect(() => {
    const activeProfile = physicians.find(
      (physician) => physician.id === userInfo.userData.physicianId,
    );
    const isCurrentProfileMedical = userInfo.userData.userKind === 'p';
    if (isCurrentProfileMedical) {
      setCheckedPhysicians(activeProfile ? [activeProfile] : []);
    }
  }, [physicians, userInfo.userData.physicianId, userInfo.userData.userKind]);

  const onSelectAllPhysiciansClick = () => {
    setCheckedPhysicians((previous) => {
      if (previous.length === physicians.length) {
        return [];
      }

      return physicians;
    });
  };

  const onPhysicianClick = (physician: Physician) => {
    setCheckedPhysicians((previousState) => {
      const hasPhysician = previousState.find(
        (item) => item.id === physician.id,
      );

      if (hasPhysician) {
        return previousState.filter((item) => item.id !== physician.id);
      }

      return [...previousState, physician];
    });
  };

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setSearch(value);
  };

  const onSubmitSelections = () => {
    dispatch(setSelectedPhysicians(checkedPhysicians));

    history.push('/relacionamento/pacote-de-mensagem/escolher-pacote');
  };

  return (
    <S.Root>
      <S.TitleAndSubtitleWrapper>
        <Heading variant="md"> Pacote de mensagem </Heading>

        <Body variant="xs">
          Encontre o plano de mensageria perfeito para você, comparando opções
          que se adequem às suas necessidades e orçamento.
        </Body>
      </S.TitleAndSubtitleWrapper>

      <S.Card>
        <S.TitleAndSubtitleWrapper>
          <Heading variant="sm">Seleção de profissionais</Heading>

          <S.Subtitle variant="xs">
            Selecione os profissionais que terão acesso à funcionalidade
          </S.Subtitle>
        </S.TitleAndSubtitleWrapper>

        <S.ChipContainer>
          {isFetchingPhysicians ? (
            <CircularProgress />
          ) : (
            <S.SelectPhysiciansWrapper>
              <S.SearchFieldWrapper>
                <TextField
                  fullWidth
                  value={search}
                  onChange={onChangeSearch}
                  label="Buscar por profissional"
                  InputProps={{
                    endAdornment: <Icons.SearchOutlined />,
                  }}
                />
              </S.SearchFieldWrapper>

              <S.ListWrapper>
                <List>
                  <ListItem button onClick={onSelectAllPhysiciansClick} dense>
                    <ListItemIcon>
                      <S.CheckboxListItem
                        edge="start"
                        size="small"
                        color="primary"
                        disableRipple
                        checked={checkedPhysicians.length === physicians.length}
                      />
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <Body variant="xxs">
                          Selecionar todos os profissionais
                        </Body>
                      }
                    />
                  </ListItem>

                  {physicians
                    .filter((physician) =>
                      physician.profile_name
                        .toLowerCase()
                        .includes(search.toLowerCase()),
                    )
                    .map((physician) => (
                      <ListItem
                        button
                        key={physician.profile_name}
                        onClick={() => onPhysicianClick(physician)}
                        dense
                      >
                        <ListItemIcon>
                          <S.CheckboxListItem
                            edge="start"
                            size="small"
                            color="primary"
                            disableRipple
                            checked={
                              !!checkedPhysicians.find(
                                (item) => item.id === physician.id,
                              )
                            }
                          />
                        </ListItemIcon>

                        <ListItemText
                          primary={
                            <Body variant="xxs">{physician.profile_name}</Body>
                          }
                        />
                      </ListItem>
                    ))}
                </List>
              </S.ListWrapper>
            </S.SelectPhysiciansWrapper>
          )}
        </S.ChipContainer>

        <S.ButtonContainer>
          <Button
            disabled={!checkedPhysicians.length}
            variant="contained"
            onClick={onSubmitSelections}
            data-ga="relationship_physician-selection-page_next"
            data-sp="relationship_physician-selection-page_next"
          >
            Próximo
          </Button>
        </S.ButtonContainer>
      </S.Card>
    </S.Root>
  );
};

export { SelectionOfProfessionals };
