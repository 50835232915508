// External
import React from 'react';

// Internal
import styles from './Summary.scss';

type HeaderProps = {
  children: React.ReactNode;
  selectedImages?: [],
};

export default function Header({
  selectedImages = [],
  children,
}: HeaderProps): JSX.Element {
  return (
    <div className={styles['images-summary']}>
      <span className={styles.resume}>
        { selectedImages.length }
        /2 selecionadas
      </span>
      <div className={styles.children}>
        {children}
      </div>
    </div>
  );
}
