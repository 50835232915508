import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFixedNumber } from 'features/onlinePayment/utils';
import { updateValues } from '../state/payment';
import {
  closePostPaymentScheduleModal,
  resetPostPaymentScheduleState,
} from '../state/reducer';
import {
  getScheduleProceduresSelector,
  getTotalValuesSelector,
} from '../state/selectors';
import { ModalBody, ModalFooter, ModalHeader } from './ModalContent';
import { PostPaymentContainer } from './styles';

export default () => {
  const procedures = useSelector(getScheduleProceduresSelector);
  const dispatch = useDispatch();

  const { remainingAmount } = useSelector(getTotalValuesSelector);
  const proceduresTotalPrice = procedures
    .map((procedure) => +procedure.price * procedure.quantity)
    .reduce((curr, acc) => +curr + +acc, 0);

  useEffect(() => {
    dispatch(
      updateValues({
        total: getFixedNumber(proceduresTotalPrice),
        subTotal: getFixedNumber(proceduresTotalPrice),
      }),
    );
  }, [dispatch, proceduresTotalPrice]);

  const handleClosePaymentModal = () => {
    dispatch(resetPostPaymentScheduleState());
    dispatch(closePostPaymentScheduleModal());
  };
  return (
    <PostPaymentContainer orientation="column" className="new_payment_flow">
      <ModalHeader />
      <ModalBody />
      <ModalFooter
        confirmTitle="Finalizar recebimento"
        handleCancel={handleClosePaymentModal}
        handleConfirm={handleClosePaymentModal}
        disabled={{
          confirm: !!(remainingAmount ?? true),
          cancel: false,
        }}
      />
    </PostPaymentContainer>
  );
};
