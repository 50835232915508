import {
  createSimpleAction,
  createFailureAction,
  createPayloadAction,
  createParamsAction,
} from 'shared/utils/actions';
import {
  INIT,
  DECODE_TOKEN_ACTION,
  DECODE_TOKEN_FAILURE,
  DECODE_TOKEN_SUCCESS,
  FETCH_PHYSICIAN_INFOS_ACTION,
  FETCH_PHYSICIAN_INFOS_FAILURE,
  FETCH_PHYSICIAN_INFOS_SUCCESS,
  FETCH_EVENT_INFOS_ACTION,
  FETCH_EVENT_INFOS_FAILURE,
  FETCH_EVENT_INFOS_SUCCESS,
  FETCH_BILL_INFOS_ACTION,
  FETCH_BILL_INFOS_FAILURE,
  FETCH_BILL_INFOS_SUCCESS,
  SELECT_PAYMENT_TYPE,
  PIX_TIMER_RUNNING,
} from './constants';
import {
  Physician,
  Clinic,
  Event,
  DecodedToken,
  Init,
  Bill,
  DecodeToken,
  DecodeTokenFailure,
  DecodeTokenSuccess,
  FetchPhysicianInfos,
  FetchPhysicianInfosFailure,
  FetchPhysicianInfosSuccess,
  FetchEventInfos,
  FetchEventInfosFailure,
  FetchEventInfosSuccess,
  FetchBillInfos,
  FetchBillInfosFailure,
  FetchBillInfosSuccess,
  SelectPaymentType,
  PixTimerRunning,
} from './types';

export const init = createSimpleAction<Init>(INIT);

export const decodeToken = createParamsAction<DecodeToken>(DECODE_TOKEN_ACTION);

export const decodeTokenFailure = createSimpleAction<DecodeTokenFailure>(
  DECODE_TOKEN_FAILURE,
);

export const decodeTokenSuccess = (tokenData: DecodedToken) =>
  createPayloadAction<DecodeTokenSuccess>(DECODE_TOKEN_SUCCESS)({ tokenData });

export const fetchPhysicianInfos = createSimpleAction<FetchPhysicianInfos>(
  FETCH_PHYSICIAN_INFOS_ACTION,
);

export const fetchPhysicianInfosFailure = createFailureAction<
  FetchPhysicianInfosFailure
>(FETCH_PHYSICIAN_INFOS_FAILURE);

export const fetchPhysicianInfosSuccess = ({
  physician,
}: {
  physician: Physician;
}) =>
  createPayloadAction<FetchPhysicianInfosSuccess>(
    FETCH_PHYSICIAN_INFOS_SUCCESS,
  )({ physician });

export const fetchEventInfos = createSimpleAction<FetchEventInfos>(
  FETCH_EVENT_INFOS_ACTION,
);

export const fetchEventInfosFailure = createFailureAction<
  FetchEventInfosFailure
>(FETCH_EVENT_INFOS_FAILURE);

export const fetchEventInfosSuccess = ({
  event,
  clinic,
}: {
  event: Event;
  clinic: Clinic;
}) =>
  createPayloadAction<FetchEventInfosSuccess>(FETCH_EVENT_INFOS_SUCCESS)({
    event,
    clinic,
  });

export const fetchBillInfos = createSimpleAction<FetchBillInfos>(
  FETCH_BILL_INFOS_ACTION,
);

export const fetchBillInfosFailure = createFailureAction<FetchBillInfosFailure>(
  FETCH_BILL_INFOS_FAILURE,
);

export const fetchBillInfosSuccess = ({ bill }: { bill: Bill }) =>
  createPayloadAction<FetchBillInfosSuccess>(FETCH_BILL_INFOS_SUCCESS)({
    bill,
  });

export const selectPaymentType = ({
  paymentType,
}: {
  paymentType: string | null;
}) =>
  createPayloadAction<SelectPaymentType>(SELECT_PAYMENT_TYPE)({
    paymentType,
  });

export const pixTimerRunning = (isPixTimerRunning: boolean) =>
  createPayloadAction<PixTimerRunning>(PIX_TIMER_RUNNING)({
    isPixTimerRunning,
  });
