import { RequestStatus } from 'shared/constants/State';

export enum EditPaymentStep {
  INITIAL = 'initial',
  SUCCEDED = 'succeded',
}

export type BillingInfo = {
  paymentDay: number;
  lastChangeDay: string;
  daysUntilNextChange: string;
};

export type EditPaymentState = {
  canEdit: boolean;
  hasDebit: boolean;
  notMonthly: boolean;
  dialogOpened: boolean;
  billingInfo: BillingInfo;
  status: RequestStatus;
  step: EditPaymentStep;
  alreadyChanged: boolean;
};
