// External
import React from 'react';

import styles from './ListImage.scss';

type ImagesListProps = {
  children: React.ReactNode;
};

const ImagesListContainer = ({
  children,
}: ImagesListProps): JSX.Element => (
  <div className={styles['list-images']}>
    { children }
  </div>
);

export default ImagesListContainer;
