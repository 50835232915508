import { reducerMerge } from 'shared/utils/reducer';
import * as types from './types';

/** Constants */
export const initialState = {
  isFetching: false,
  isOpen: false,
  productsDetail: null,
  hasPaymentAttempt: false,
  isAccountBlocked: false,
  finalNumber: null,
  flag: 'card',
  saveFeedback: null,
  errors: [],
  turnstileToken: null,
};

const creditcard = (state = initialState, action = {}) => {
  const { type, payload } = action;

  switch (type) {
    case types.DETAIL.OPEN:
    case types.DETAIL.CLOSE:
    case types.FETCH_DETAIL.ACTION:
    case types.FETCH_DETAIL.SUCCESS:
    case types.FETCH_DETAIL.FAILURE:
    case types.FETCH_CREATE.ACTION:
    case types.FETCH_CREATE.SUCCESS:
    case types.FETCH_CREATE.FAILURE:
    case types.SET_PAYMENT_DATA.ACTION:
    case types.CLEAR_FEEDBACK.ACTION:
    case types.SET_TURNSTILE_TOKEN.ACTION:
      return reducerMerge({}, state, payload);
    default:
      return state;
  }
};

export default creditcard;
