import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  statusIcon: {
    height: '19px',
    width: '19px',
  },
  warningStatusIcon: {
    color: theme.palette.warning.main,
  },
  errorStatusIcon: {
    color: theme.palette.error.main,
  },
  successStatusIcon: {
    color: theme.palette.success.main,
  },
  infoStatusIcon: {
    color: theme.palette.primary.dark,
  },
}));
