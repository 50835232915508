import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

// Internal
import { Input } from 'ui/new/form';
import style from './EditCampaign.scss';

const EditCampaign = ({ children, campaignTitles, currentTitle }) => {
  const validateDuplicateTitle = React.useCallback((value) => (
    value !== currentTitle && campaignTitles.find((title) => value === title) && 'Título já existente'
  ), [campaignTitles, currentTitle]);

  return (
    <div className={style.container}>
      <p className={style.titleField}>Nome da campanha</p>
      <Field
        type="text"
        name="title"
        label="title"
        component={Input}
        validate={validateDuplicateTitle}
      />
      {children}
    </div>
  );
};

EditCampaign.defaultProps = {
  campaignTitles: [],
  currentTitle: '',
};

EditCampaign.propTypes = {
  children: PropTypes.node.isRequired,
  campaignTitles: PropTypes.arrayOf(
    PropTypes.string,
  ),
  currentTitle: PropTypes.string,
};

export default EditCampaign;
