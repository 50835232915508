import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MaterialIcons } from '@iclinic/design-system';
import {
  getCurrentModal,
  getPeriod,
  getSelectedProductSlug,
} from 'state/upgrade/selectors';
import { SelectableList } from '../selectableList';
import { Check, ProductToUpgrade } from 'features/upgrade/types';
import { selectProduct } from 'state/upgrade/actions';

const { Check: CheckIcon } = MaterialIcons;

type ProductProps = {
  item: ProductToUpgrade;
};

const Product = ({ item }: ProductProps) => {
  const currentModal = useSelector(getCurrentModal);
  const selectedProductSlug = useSelector(getSelectedProductSlug);
  const isAnnual = useSelector(getPeriod);
  const dispatch = useDispatch();

  const onClickSelectedProduct = () =>
    !item.isCurrent && dispatch(selectProduct(item.slug));

  return (
    <SelectableList.Root
      onClick={onClickSelectedProduct}
      isSelected={item.slug === selectedProductSlug}
      name={item.slug}
    >
      <SelectableList.Content
        key={item.id}
        title={item.name}
        isSelectable={!item.isCurrent}
        actualSelectedProduct={item.slug === selectedProductSlug}
      >
        {item.checks.map((product: Check) => (
          <SelectableList.Item
            key={product.slug}
            type="icon"
            modal={currentModal}
            slug={product.slug}
          >
            {product.isChecked && <CheckIcon />}
          </SelectableList.Item>
        ))}
      </SelectableList.Content>
      {!isAnnual && item.priceDifferenceSplit && (
        <SelectableList.Value
          total={item.priceDifferenceSplit.total}
          cents={item.priceDifferenceSplit.cents}
        />
      )}
    </SelectableList.Root>
  );
};

export default Product;
