import { Grid, MaterialUICore } from '@iclinic/design-system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { HeaderTitle } from './styles';
import { getAutocomplete } from 'features/tissInvoicing/state/autocomplete/selectors';
import SelectField from 'features/tissInvoicing/components/appointmentGuide/SelectField';
import { actions as apointmentGuideActions } from 'features/tissInvoicing/state/appointmentGuide';

const { MenuItem } = MaterialUICore;
const HeaderSection = () => {
  const dispatch = useDispatch();
  const { guide_versions } = useSelector(getAutocomplete);

  const handleChange = (event: string) => {
    dispatch(apointmentGuideActions.setVersion(event));
  };

  return (
    <Grid container item spacing={2} justifyContent="space-between">
      <Grid item xs={12} md={9}>
        <HeaderTitle data-testid="header_title">Guia de consulta</HeaderTitle>
      </Grid>
      <Grid item xs={12} md={3}>
        <SelectField
          label="Versão da guia"
          identifier="version_id"
          handleChangeInput={handleChange}
        >
          {guide_versions.map((version) => (
            <MenuItem key={version.version} value={version.version}>
              {version.version}
            </MenuItem>
          ))}
        </SelectField>
      </Grid>
    </Grid>
  );
};

export default HeaderSection;
