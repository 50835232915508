import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  MaterialUICore,
  MaterialIcons,
  Heading,
  Body,
  DatePicker,
  Select,
  Autocomplete,
  TextField,
} from '@iclinic/design-system';

import {
  GenericObjectDescription,
  FetchLotListQuery,
} from 'features/tissInvoicing/types';
import { isValidFullDate } from 'shared/utils/validation';
import { actions } from 'features/tissInvoicing/state/lot';
import { getLotListState } from 'features/tissInvoicing/state/lot/selectors';
import { SpaceBetweenBox } from 'features/tissInvoicing/components/ui/Flexbox';
import * as Styles from './styles';

const { Drawer, MenuItem } = MaterialUICore;
const { Close } = MaterialIcons;

export const schema = Yup.object().shape({
  patient: Yup.number(),
  physician: Yup.number(),
  date_from: Yup.date()
    .nullable()
    .test(
      'isValidFullDate',
      'Data inválida',
      (value) => !value || isValidFullDate(value),
    ),
  date_to: Yup.date()
    .nullable()
    .min(Yup.ref('date_from'), 'Essa data não pode ser antes que a anterior')
    .test(
      'isValidFullDate',
      'Data inválida',
      (value) => !value || isValidFullDate(value),
    ),
});

interface FilterProps {
  open: boolean;
  handleClose: () => void;
}

function Filter({ open, handleClose }: FilterProps) {
  const dispatch = useDispatch();
  const classes = Styles.useStyles();

  const [hasTyped] = useState(false);

  const { search, loadingInsurances, hasMoreData } =
    useSelector(getLotListState);
  const { query, filterOptions } = search;

  const handleApply = (values: FetchLotListQuery) => {
    dispatch(actions.setAvailableLotes({ lotes: [], hasMoreData }));
    dispatch(actions.setLotsQuery(values));

    dispatch(
      actions.fetchLotList({
        page: 1,
        start: values.date_from,
        end: values.date_to,
        professional_id: values.professional_id ? values.professional_id : 0,
        insurance_id: values.insurance_id ? values.insurance_id : 0,
      }),
    );
    handleClose();
  };

  const handleCleanFilters = () => {
    handleApply({
      professional_id: 0,
      insurance_id: 0,
      date_from: '',
      date_to: '',
    });
  };

  const getInsurancebyId = (id?: string | number | null) =>
    filterOptions.insurances.find((insurance) => insurance.id === Number(id)) ||
    null;

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{
        classes: { root: classes.paper },
      }}
    >
      <SpaceBetweenBox
        classes={{ root: classes.title }}
        data-testid="filter-modal"
      >
        <Heading variant="lg">Filtrar</Heading>

        <Button
          classes={{ root: classes.button }}
          startIcon={<Close />}
          onClick={handleClose}
          color="transparent"
        >
          Fechar
        </Button>
      </SpaceBetweenBox>

      <Formik
        initialValues={query}
        validationSchema={schema}
        enableReinitialize
        onSubmit={handleApply}
      >
        {}
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          resetForm,
        }) => (
          <Styles.Form onSubmit={handleSubmit}>
            <SpaceBetweenBox flexDirection="column">
              <Styles.Section>
                <Heading variant="md">Data</Heading>

                <SpaceBetweenBox classes={{ root: classes.date }}>
                  <Body variant="sm">Período</Body>

                  <DatePicker
                    label="De"
                    id="date_from"
                    name="date_from"
                    onChange={(date) => setFieldValue('date_from', date)}
                    value={values.date_from}
                    allowKeyboard
                    placeholder="DD/MM/AAAA"
                    error={!!(errors.date_from && touched.date_from)}
                    helperText={touched.date_from && errors.date_from}
                    onBlur={handleBlur}
                    style={{ maxWidth: '210px' }}
                  />

                  <DatePicker
                    label="Até"
                    id="date_to"
                    name="date_to"
                    onChange={(date) => {
                      setFieldValue('date_to', date);
                    }}
                    value={values.date_to}
                    allowKeyboard
                    placeholder="DD/MM/AAAA"
                    error={!!(errors.date_to && touched.date_to)}
                    helperText={touched.date_to && errors.date_to}
                    onBlur={handleBlur}
                    style={{ maxWidth: '210px' }}
                  />
                </SpaceBetweenBox>
              </Styles.Section>

              <Styles.Section marginTop={6}>
                <Heading variant="md">Convênio</Heading>
                <Autocomplete
                  id="insurance_id"
                  fullWidth
                  clearOnBlur
                  onBlur={handleBlur}
                  options={filterOptions.insurances}
                  noOptionsText={
                    hasTyped && !loadingInsurances
                      ? 'Convênio não encontrado'
                      : 'Digite 3 letras para buscar...'
                  }
                  loading={loadingInsurances}
                  value={getInsurancebyId(values.insurance_id)}
                  onChange={(_, option) => {
                    setFieldValue(
                      'insurance_id',
                      (option as GenericObjectDescription)?.id || '',
                    );
                  }}
                  getOptionLabel={(option) => option?.name}
                  renderOption={(option) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Filtrar"
                      placeholder="Digite 3 letras para buscar..."
                      name="insurance_id"
                      data-ga="insurance_id"
                    />
                  )}
                />
              </Styles.Section>

              <Styles.Section marginTop={6}>
                <Heading variant="md">Profissional</Heading>
                <Select
                  label="Filtrar"
                  id="professional_id"
                  name="professional_id"
                  value={values.professional_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value={0}>Todos os prestadores</MenuItem>
                  {filterOptions.physicians.map((physician) => (
                    <MenuItem value={physician.id} key={physician.id}>
                      {physician.name}
                    </MenuItem>
                  ))}
                </Select>
              </Styles.Section>
            </SpaceBetweenBox>

            <SpaceBetweenBox width="100%" paddingBottom={8}>
              <Button
                onClick={() => {
                  resetForm();
                  handleCleanFilters();
                }}
                color="transparent"
              >
                Limpar filtros
              </Button>

              <Button type="submit" color="secondary">
                Ver resultado
              </Button>
            </SpaceBetweenBox>
          </Styles.Form>
        )}
      </Formik>
    </Drawer>
  );
}

export default Filter;
