import React from 'react';
import { MaterialIcons } from '@iclinic/design-system';

const { Contacts, AssignmentTurnedInRounded, Forum } = MaterialIcons;

type AddonContent = {
  description: (value?: string, recurrence?: string) => React.ReactNode;
  icon: React.ReactElement;
};

type AddonContentByPlan = {
  [key: string]: {
    [key: string]: AddonContent;
  };
};

type TitleModalByAddonSlug = {
  [key: string]: (isAnnual: boolean) => string;
};

const telemedicine: AddonContent = {
  description: (value?: string, recurrence?: string) =>
    <>
      Atenda remotamente com <strong>Teleconsultas</strong> integradas ao prontuário por R$ {value}/{recurrence} - 10 sessões mensais
    </>,
  icon: <Contacts />,
};

const electronicSignature: AddonContent = {
  description: (value?: string, recurrence?: string) => 
    <>
      Colete <strong>assinaturas eletrônicas</strong> do paciente de forma ilimitada e integrada por R$ {value}/{recurrence}
    </>,
  icon: <AssignmentTurnedInRounded />,
};

const messageBasic: AddonContent = {
  description: (value?: string, recurrence?: string) => 
    <>
      Reduza faltas com <strong>confirmações automáticas</strong> via Whatsapp por R$ {value}/{recurrence} - 85 envios mensais
    </>,
  icon: <Forum />,
};

export const addonsContentByPlan: AddonContentByPlan = {
  premium: {
    telemedicine: {
      description: () => 'Teleconsultas ilimitadas neste plano',
      icon: <Contacts />,
    },
    'electronic-signature': electronicSignature,
    'message-basic': messageBasic,
  },
  pro: { telemedicine, 'electronic-signature': electronicSignature, 'message-basic': messageBasic },
  plus: { telemedicine, 'electronic-signature': electronicSignature, 'message-basic': messageBasic },
  starter: { telemedicine, 'electronic-signature': electronicSignature, 'message-basic': messageBasic },
};

export const titleModalSelectPhysiciansByAddonSlug: TitleModalByAddonSlug = {
  telemedicine: () =>
    `Você está adicionando um pacote de 10 teleconsultas mensais na sua assinatura.`,
  'electronic-signature': () =>
    `Você está adicionando o pacote de assinatura eletrônica.`,
  'message-basic': () =>
    `Você está adicionando o pacote de 85 mensagens mensais de confirmação automática na sua assinatura.`,
};
