// External
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Internal
import { AppContent } from 'features/layout';
import { ListTitle, ListCampaigns } from 'features/campaign';
import { ADD_CAMPAIGN_MODAL } from 'features/campaign/modals';
import ModalRoot from 'features/modal/containers/ModalRoot';
import { open, close } from 'state/modal/actions';
import { addCampaign } from 'state/campaign/actions';

const Marketing = ({ openAddCampaignModal }) => (
  <AppContent
    contentTitle={<ListTitle onOpenModal={openAddCampaignModal} />}
    hideSidebar
    hideFooter
  >
    <ListCampaigns />
    <ModalRoot />
  </AppContent>
);

Marketing.propTypes = {
  openAddCampaignModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  modal: state.modal,
});

const mapDispatchToProps = (dispatch) => ({
  openAddCampaignModal: () =>
    dispatch(
      open(ADD_CAMPAIGN_MODAL, {
        onCloseClick: () => {
          dispatch(close());
        },
        onAddCampaign: () => {
          dispatch(addCampaign());
        },
      }),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Marketing);
