import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import ENTER_KEY_CODE from 'shared/constants/keysCode';
import Item from './Item';
import DROPDOWN_TYPE from './constants';
import style from './style.scss';

const classes = classNames.bind(style);

const Dropdown = ({
  title,
  children,
  isOpenProp,
  setToggleMenuProp,
  renderInternalSearch: RenderInternalSearch,
  touched,
  error,
  invalid,
}) => {
  const ref = useRef(null);

  const handleClick = useCallback(
    ({ target }) =>
      !ref.current.contains(target) &&
      isOpenProp &&
      setToggleMenuProp(!isOpenProp),
    [isOpenProp, setToggleMenuProp],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  return (
    <div className={style['dropdown-form']} ref={ref}>
      <div
        onClick={() => setToggleMenuProp(!isOpenProp)}
        onKeyPress={(e) =>
          e.keyCode === ENTER_KEY_CODE ? setToggleMenuProp(!isOpenProp) : null
        }
        role="button"
        tabIndex={0}
        className={classes('toggler', { error: touched && invalid })}
      >
        <span className={style.title}>{title}</span>
      </div>
      {isOpenProp && (
        <div className={style.menu}>
          {RenderInternalSearch && (
            <div className={style.search}>{RenderInternalSearch}</div>
          )}
          {children || (
            <Item id="0" kind={DROPDOWN_TYPE.Disabled}>
              Não há opções disponíveis
            </Item>
          )}
        </div>
      )}
      {touched && invalid && <span className={style.message}>{error}</span>}
    </div>
  );
};

Dropdown.defaultProps = {
  renderInternalSearch: null,
  touched: false,
  invalid: false,
  error: null,
  isOpenProp: false,
  setToggleMenuProp: () => null,
  children: null,
};

Dropdown.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node,
  renderInternalSearch: PropTypes.node,
  isOpenProp: PropTypes.bool,
  setToggleMenuProp: PropTypes.func,
  touched: PropTypes.bool,
  invalid: PropTypes.bool,
  error: PropTypes.string,
};

Dropdown.Item = Item;

export default Dropdown;
