import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';
import { ClinicPhysicians, ExportPhysician, ExportResource } from './types';

export const getSelectedResources = ({
  exportClinicData,
}: StoreState): ExportResource[] | undefined =>
  exportClinicData.exportRequest?.selectedResources;

export const getSelectedAllResources = ({
  exportClinicData,
}: StoreState): boolean | undefined =>
  exportClinicData.exportRequest?.selectedAllResources;

export const getSelectedFormat = ({
  exportClinicData,
}: StoreState): string | undefined =>
  exportClinicData.exportRequest?.selectedFormat;

export const getClinicPhysicians = ({
  exportClinicData,
}: StoreState): ClinicPhysicians[] | null | undefined =>
  exportClinicData.exportRequest?.clinicPhysicians?.data;

export const getClinicPhysiciansStatus = ({
  exportClinicData,
}: StoreState): RequestStatus | undefined =>
  exportClinicData.exportRequest?.clinicPhysicians?.status;

export const getSelectedSubscriptionId = ({
  exportClinicData,
}: StoreState): number | null | undefined =>
  exportClinicData.exportRequest?.selectedSubscriptionId;

export const getSelectedPhysicians = ({
  exportClinicData,
}: StoreState): ExportPhysician[] | null | undefined =>
  exportClinicData.exportRequest?.selectedPhysicians;

export const getSelectedAllPhysicians = ({
  exportClinicData,
}: StoreState): boolean | undefined =>
  exportClinicData.exportRequest?.selectedAllPhysicians;

export const getSelectedPeriod = ({
  exportClinicData,
}: StoreState): string | undefined =>
  exportClinicData.exportRequest?.selectedPeriod;

export const getSelectedStartPeriod = ({
  exportClinicData,
}: StoreState): string | null | undefined =>
  exportClinicData.exportRequest?.selectedStartPeriod;

export const getSelectedEndPeriod = ({
  exportClinicData,
}: StoreState): string | null | undefined =>
  exportClinicData.exportRequest?.selectedEndPeriod;

export const getDuplicatedRequest = ({
  exportClinicData,
}: StoreState): boolean | undefined =>
  exportClinicData.exportRequest?.duplicatedRequest;

export const getNewRequestStatus = ({
  exportClinicData,
}: StoreState): RequestStatus | null | undefined =>
  exportClinicData.exportRequest?.newRequest?.status;

export const getSelectedFilterPatientsByPhysicians = ({
  exportClinicData,
}: StoreState): boolean | undefined =>
  exportClinicData.exportRequest?.selectedFilterPatientsByPhysicians;
