import React, { useState } from 'react';

import Loader from 'ui/new/loader';
import {
  MaterialUICore,
  theme,
  MaterialIcons,
  Button,
  Box,
  Typography,
} from '@iclinic/design-system';

const { makeStyles, Collapse, SnackbarContent, IconButton } = MaterialUICore;

type AlertProps = {
  actionText?: string;
  action(): void;
  closeButton?: boolean;
  message: string;
  extraActions?: extraButtonProps[];
  variant: 'success' | 'info' | 'warning' | 'error';
  isLoading: boolean;
};

type extraButtonProps = {
  action(): void;
  text: string;
};

const variantIcon = {
  success: MaterialIcons.CheckCircle,
  info: MaterialIcons.Info,
  warning: MaterialIcons.Warning,
  error: MaterialIcons.Error,
};

const bgColor = {
  success: theme.palette.success.light,
  info: theme.palette.secondary.light,
  warning: theme.palette.warning.light,
  error: theme.palette.error.light,
};

const iconColor = {
  success: theme.palette.success.main,
  info: theme.palette.secondary.main,
  warning: theme.palette.warning.main,
  error: theme.palette.error.main,
};

const buttonColor = {
  success: theme.palette.success.dark,
  info: '#2979FF',
  warning: '#1565C0',
  error: theme.palette.error.dark,
};

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: (props: AlertProps) => bgColor[props.variant],
  },
  icon: {
    color: (props: AlertProps) => iconColor[props.variant],
    fontSize: 20,
    marginRight: '10px',
  },
  message: {
    color: theme.palette.text.secondary,
    display: 'flex',
    fontSize: 14,
    '& .MuiTypography-root': {
      fontSize: 14,
    },
  },
  button: {
    color: (props: AlertProps) => buttonColor[props.variant],
    fontSize: 14,
    textTransform: 'none',
  },
  extraButtons: {
    color: theme.palette.grey[700],
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'none',
  },
  closeButton: {
    color: theme.palette.text.secondary,
    '& .MuiSvgIcon-root': {
      fontSize: 20,
    },
  },
  loader: {
    margin: '-15px 20px 0',
  },
}));

function Alert({
  action,
  extraActions = [],
  actionText,
  closeButton,
  message,
  variant,
  isLoading = false,
}: AlertProps): JSX.Element {
  const classes = useStyles({ variant });
  const Icon = variantIcon[variant];
  const [open, setOpen] = useState(true);

  return (
    <Collapse in={open}>
      <SnackbarContent
        className={classes.root}
        aria-describedby="client-snackbar"
        elevation={0}
        message={
          <Typography className={classes.message} component="p">
            <Icon className={classes.icon} />
            <Typography component="span">{message}</Typography>
          </Typography>
        }
        action={
          !closeButton ? (
            <>
              {extraActions.length &&
                extraActions.map((extra) => (
                  <Box>
                    <Button
                      className={classes.extraButtons}
                      onClick={extra.action}
                      key={extra.text}
                      color="transparent"
                    >
                      {extra.text}
                    </Button>
                  </Box>
                ))}

              {isLoading ? (
                <div className={classes.loader}>
                  <Loader color="darkgrey" />
                </div>
              ) : (
                <Box ml={2}>
                  <Button
                    className={classes.button}
                    onClick={action}
                    color="transparent"
                  >
                    {actionText}
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <IconButton
              aria-label="close"
              color="inherit"
              className={classes.closeButton}
              size="small"
              onClick={() => setOpen(false)}
            >
              <MaterialIcons.Close />
            </IconButton>
          )
        }
      />
    </Collapse>
  );
}

export default Alert;
