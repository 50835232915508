import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  paper: {
    padding: '10px 0 0',
  },
  tableContainer: {
    padding: '0 16px',
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
    '& td:nth-of-type(even)': {
      color: theme.palette.text.secondary,
    },
    '& td:nth-of-type(odd)': {
      color: theme.palette.text.hint,
    },
  },
  chipIcon: {
    color: theme.palette.grey[500],
    height: '15px',
    width: '15px',
  },
  fileIcon: {
    color: theme.palette.grey[500],
  },
}));
