export const feedBack = {
  default: 'default',
  noMore: 'noMore',
  neverSubscribed: 'neverSubscribed',
  inappropriate: 'inappropriate',
  other: 'other',
};

export const optionsFeedBack = [
  { key: feedBack.noMore, label: 'Eu não quero mais receber esses e-mails' },
  { key: feedBack.neverSubscribed, label: 'Nunca me inscrevi para essa lista' },
  { key: feedBack.inappropriate, label: 'Os e-mails são inapropriados' },
  { key: feedBack.other, label: 'Outros' },
];
