import { Form as Formik } from 'formik';

import { tokens, MaterialUICore, Box as BoxUI } from '@iclinic/design-system';

const { makeStyles, styled } = MaterialUICore;

export const useStyles = makeStyles(() => ({
  title: {
    boxShadow: `inset 0px -1px 0px ${tokens.color.neutral[100]}`,
    padding: `${tokens.spacing.sm} ${tokens.spacing.lg}`,
  },
  paper: {
    marginTop: '50px',
  },
  date: {
    columnGap: tokens.spacing.xs,
  },
  button: {
    color: tokens.color.neutral[700],
  },
}));

export const Section = styled(BoxUI)({
  width: '100%',
  height: '100px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});

export const Form = styled(Formik)({
  height: '100%',
  padding: tokens.spacing.lg,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});
