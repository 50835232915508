import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  Body,
  DialogActions,
  Button,
} from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';

import { image } from 'shared/utils/urlGetter';
import { successInfo } from './constants';
import {
  getUserEmail,
  getUserSubscriptionPlan,
} from 'features/upgrade/state/selectors';
import { getCurrentModal } from 'state/upgrade/selectors';
import { closeUpgradeDetail } from 'state/upgrade/actions';

export const SuccessModal = () => {
  const currentModal = useSelector(getCurrentModal);
  const subscriptionPlan = useSelector(getUserSubscriptionPlan);
  const userEmail = useSelector(getUserEmail);
  const dispatch = useDispatch();

  const successContent = successInfo[subscriptionPlan || 'plus'];
  const defaultTitle = 'Parabéns, seus novos benefícios foram liberados!';

  const { description, imagePath, instructionsUrl, planName, title } =
    successContent;
  const modalTitle = title || defaultTitle;

  const onCloseHandler = () => {
    dispatch(closeUpgradeDetail(currentModal));
  };

  const onClickInstructionsHandler = () => {
    window.open(instructionsUrl, '_blank');
  };

  return (
    <Dialog open onClose={onCloseHandler}>
      <DialogHeader title={modalTitle} onClose={onCloseHandler} />
      <DialogContent>
        <img src={image(imagePath)} alt="" />
        <Body variant="sm">{description}</Body>
        <Body variant="sm">
          Os demais usuários também já podem aproveitar as novas ferramentas do
          plano {planName}, basta clicar em “ativar recursos” na barra de avisos
          do sistema.
        </Body>
        <Body bold variant="sm">
          Enviaremos um e-mail de confirmação para {userEmail} com todas as
          informações.
        </Body>
        <Body variant="sm">
          Confira as instruções de utilização do plano {planName}.
        </Body>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onCloseHandler}>
          Voltar para o iClinic
        </Button>
        <Button
          data-ga="upgrade_success_modal_instructions_click"
          onClick={onClickInstructionsHandler}
        >
          Ver instruções
        </Button>
      </DialogActions>
    </Dialog>
  );
};
