import React, { useEffect, useRef } from 'react';

/**
 * This custom hook acts like the old `componentDidUpdate` + `shouldComponentUpdate`, the effect does not trigger in the Mount cycle
 *
 * @param effect React.EffectCallback
 * @param deps React.DependencyList
 */
export default function useDidUpdateEffect(
  effect: React.EffectCallback,
  deps: React.DependencyList,
) {
  const firstRender = useRef(true);

  useEffect(() => {
    if (!firstRender.current) {
      return effect();
    }
    firstRender.current = false;
    return undefined;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
