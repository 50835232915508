import React, { useEffect, useState } from 'react';
import { Box } from '@iclinic/design-system';

import { useConference } from 'features/video-conference/state/conferenceContext';
import useRoomState from 'features/video-conference/hooks/useRoomState';
import { useConferenceFinish } from 'features/video-conference/hooks/useConferenceFinish';
import Rating from 'features/video-conference/components/Rating';
import { useWindowHeight } from 'features/video-conference/utils';
import useStyles from 'features/video-conference/components/styles';
import WaitRoom from './WaitRoom';
import Room from './Room';
import { fetchCheckRoomStatus } from '../services';
import { useTranscript } from '../hooks/useTranscript';

interface Props {
  isPhysician: boolean;
}

export function App({ isPhysician }: Props) {
  const {
    getAudioAndVideoTracks,
    setIsPhysician,
    conferenceFinished,
  } = useConference();
  const roomState = useRoomState();
  const { handleErrorConferenceFinished } = useConferenceFinish();
  useTranscript();

  const [mediaError, setMediaError] = useState<Error>();
  const height = useWindowHeight();
  const isWaitRoom = roomState === 'disconnected' && !conferenceFinished;
  const classes = useStyles({ height, isWaitRoom });

  useEffect(() => {
    fetchCheckRoomStatus().then(({ errors }) => {
      handleErrorConferenceFinished(errors);
    });
  }, [handleErrorConferenceFinished]);

  useEffect(() => {
    setIsPhysician(isPhysician);
  }, [isPhysician, setIsPhysician]);

  useEffect(() => {
    if (!mediaError && !conferenceFinished)
      getAudioAndVideoTracks().catch((error) => setMediaError(error));
  }, [conferenceFinished, getAudioAndVideoTracks, mediaError]);

  const renderContent = () => {
    if (conferenceFinished) return <Rating />;
    if (isWaitRoom) return <WaitRoom mediaError={mediaError} />;
    return <Room />;
  };

  return <Box className={classes.room}>{renderContent()}</Box>;
}
