/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setOverviewFilters } from 'features/onlinePayment/state/notifications-center';
import { getOverviewFilters } from 'features/onlinePayment/state/notifications-center/selectors';
import { SellerType } from 'features/onlinePayment/state/constants';
import * as S from './styles';

const tabAccessibilityLabel = (index: number) => `notifications-tab-${index}`;
const tabPanelAccessibilityLabel = (index: number) =>
  `notifications-tabpanel-${index}`;

function tabAccessibilityProps(index: number) {
  return {
    id: tabAccessibilityLabel(index),
    'aria-controls': tabPanelAccessibilityLabel(index),
  };
}

const Tabs = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getOverviewFilters);

  const handleChange = (_: React.ChangeEvent<{}>, sellerType?: SellerType) => {
    dispatch(setOverviewFilters({ ...filters, sellerType }));
  };

  return (
    <S.Tabs
      value={filters.sellerType || null}
      aria-label="Notificações"
      textColor="primary"
      indicatorColor="primary"
      onChange={handleChange}
    >
      <S.Tab value={null} label="Todos" {...tabAccessibilityProps(0)} />
      <S.Tab
        value={SellerType.PF}
        label="Conta PF"
        {...tabAccessibilityProps(1)}
      />
      <S.Tab
        value={SellerType.PJ}
        label="Conta PJ"
        {...tabAccessibilityProps(2)}
      />
    </S.Tabs>
  );
};

export default Tabs;
