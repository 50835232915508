import { Alert as DSAlert, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Alert = styled(DSAlert)({
  '& .MuiSnackbarContent-message': {
    alignItems: 'center',
  },

  '& .MuiSnackbarContent-action': {
    marginTop: 'unset',
  },
});
