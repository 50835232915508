import { all, fork } from 'redux-saga/effects';

import checkoutSagas from './checkout/sagas';
import manageSagas from './manage/sagas';
import configSagas from './config/sagas';
import transactionsSagas from './reports/transactions/sagas';
import iClinicPaySagas from './iclinic-pay/sagas';
import NotificationsCenterSagas from './notifications-center/sagas';
import panelIClinicPaySagas from './panel/sagas';

export default function* onlinePaymentSagas() {
  yield all([
    fork(checkoutSagas),
    fork(manageSagas),
    fork(configSagas),
    fork(transactionsSagas),
    fork(iClinicPaySagas),
    fork(NotificationsCenterSagas),
    fork(panelIClinicPaySagas),
  ]);
}
