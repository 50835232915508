import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing } = tokens;

export const Section = styled('section')(({ theme }) => ({
  background: color.secondary.main,
  padding: `${spacing.xl} ${spacing.sm}`,

  [theme.breakpoints.up('sm')]: {
    padding: `${spacing.xxxl} ${spacing.md}`,
  },
}));

export const SectionContent = styled('div')(({ theme }) => ({
  margin: '0 auto',
  maxWidth: 848,
  display: 'grid',
  gridTemplateColumns: '1fr',
  rowGap: spacing.sm,
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

export const PointsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.base,

  [theme.breakpoints.up('sm')]: {
    rowGap: spacing.sm,
  },
}));

export const Point = styled('div')({
  display: 'flex',
  columnGap: spacing.sm,

  '& svg': {
    color: color.primary.light,
    marginTop: spacing.nano,
  },
});
