import React from 'react';

import { MaterialIcons } from '@iclinic/design-system';
import { IndicationAwardInfo, Kind } from '../types';

const { MonetizationOn, GroupAdd, CardGiftcard } = MaterialIcons;

export const indicationAwards: Record<Kind, IndicationAwardInfo[]> = {
  physician: [
    {
      icon: <MonetizationOn />,
      text: (
        <>
          <strong>R$ 100</strong> nas indicações 1, 2, 3 e 4;
        </>
      ),
    },
    {
      icon: <MonetizationOn />,
      text: (
        <>
          <strong>R$ 400</strong> a cada 5 indicações;
        </>
      ),
    },
    {
      icon: <CardGiftcard />,
      text: (
        <>
          A 6º indicação reinicia o ciclo de premiação, como indicação 1, e
          assim sucessivamente.{' '}
          <strong>Enquanto você indicar, você vai ganhar!</strong>
        </>
      ),
    },
    {
      icon: <GroupAdd />,
      text: (
        <>
          Você pode se tornar um Embaixador iClinic e ter{' '}
          <strong>ainda mais benefícios!</strong>
        </>
      ),
    },
  ],
  receptionist: [
    {
      icon: <MonetizationOn />,
      text: (
        <>
          <strong>R$ 100</strong> em vale presente no cartão virtual;
        </>
      ),
    },
    {
      icon: <MonetizationOn />,
      text: (
        <>
          <strong>R$ 400</strong> a cada 5 indicações;
        </>
      ),
    },
    {
      icon: <CardGiftcard />,
      text: (
        <>
          A 6º indicação reinicia o ciclo de premiação, como indicação 1, e
          assim sucessivamente.{' '}
          <strong>Enquanto você indicar, você vai ganhar!</strong>
        </>
      ),
    },
  ],
};
