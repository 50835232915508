import React from 'react';

// Style
import classnames from 'classnames/bind';
import ss from './ListItem.scss';

const cx = classnames.bind(ss);

type ListProps = {
  classes?: string;
  children: React.ReactNode;
};

export default function List({
  classes = '',
  children,
}: ListProps) {
  return (
    <ul className={cx(ss.list, classes)}>
      {children}
    </ul>
  );
}
