import {
  marketingText,
  telemedicineText,
  electronicSignatureText,
  tissText,
} from 'shared/components/presentations';

const ASSETS_PATH = process.env.ASSETS_PATH!;
const successImage = `${ASSETS_PATH}images/common/upgrade/upgrade_success.gif`;

type ProductViewInfo = {
  title: string;
  text: string | React.ReactElement;
  imageUrl?: string;
  youtubeUrl?: string;
  alt: string;
  trackId: string;
};

export const productViews: Record<string, ProductViewInfo> = {
  finances: {
    title: 'Controle as finanças da sua clínica de forma prática e segura',
    text: 'Tome o controle da sua clínica! Chega de surpresas com convênios e contas a pagar. Centralize sua organização financeira sem gastar mais tempo com isso.',
    imageUrl: `${ASSETS_PATH}images/common/upgrade/finances.gif`,
    alt: 'Controle financeiro',
    trackId: 'upgrade_feature_modal_finances_click',
  },
  reports: {
    title: 'Entenda melhor a sua clínica com os relatórios automáticos',
    text:
      'Mais informações, sem trabalho extra. Saiba quais convênios e procedimentos são mais lucrativos e o número de faltas por pacientes. ' +
      'Conheça melhor suas receitas e despesas em apenas alguns cliques.',
    imageUrl: `${ASSETS_PATH}images/common/upgrade/reports.png`,
    alt: 'Relatórios automáticos',
    trackId: 'upgrade_feature_modal_reports_click',
  },
  chat: {
    title: 'Comunicação fácil e centralizada na clínica',
    text: 'Com o chat interno o médico e a secretária trocam recados rapidamente sem perder informações. Comunicação em tempo real para a clínica e sem perder o foco no trabalho.',
    imageUrl: `${ASSETS_PATH}images/common/upgrade/chat.gif`,
    alt: 'Chat interno',
    trackId: 'upgrade_feature_modal_chat_click',
  },
  birthdays: {
    title: 'Fidelize seus pacientes com o e-mail automático de aniversariantes',
    text:
      'Enviar uma mensagem de aniversário para seus pacientes cria uma relação humanizada e faz com que eles sempre se lembrem da sua clínica. ' +
      'O melhor é que o iClinic faz isso automaticamente para você!',
    imageUrl: `${ASSETS_PATH}images/common/upgrade/birthday.gif`,
    alt: 'E-mail de aniversariante',
    trackId: 'upgrade_feature_modal_birthdays_click',
  },
  sms: {
    title: 'Reduza as faltas em até 30% com a confirmação de consultas por SMS',
    text: 'Sua agenda atualizada automaticamente de acordo com a resposta do paciente! Evite janelas em seu horário, economize tempo e dinheiro com ligações desnecessárias.',
    imageUrl: `${ASSETS_PATH}images/common/upgrade/sms.gif`,
    alt: 'SMS com resposta',
    trackId: 'upgrade_feature_modal_sms_click',
  },
  multiprocedures: {
    title: 'Agende todos os procedimentos necessários em um mesmo horário',
    text: 'Organize sua agenda e economize tempo! Cadastre todos os procedimentos que serão realizados com o paciente em um mesmo agendamento.',
    imageUrl: `${ASSETS_PATH}images/common/upgrade/multiprocedures.png`,
    alt: 'Multiprocedimentos',
    trackId: 'upgrade_feature_modal_multiprocedures_click',
  },
  logo: {
    title:
      'Mais profissionalismo e facilidade com o logo da clínica em seus documentos',
    text: 'Chega de gráficas e papel timbrado! Imprima ou envie seus documentos com o logo do consultório direto do iClinic. Mais divulgação para a clínica e mais praticidade pra você.',
    imageUrl: `${ASSETS_PATH}images/common/upgrade/logo.png`,
    alt: 'Logo na impressão',
    trackId: 'upgrade_feature_modal_logo_click',
  },
  physicianDay: {
    title: 'Dia do Médico iClinic',
    text: `Você está a um passo de se destacar ainda mais no mercado!
      Clique agora no botão abaixo e garanta já o seu desconto.
    `,
    imageUrl: `${ASSETS_PATH}images/common/upgrade/physician-day.png`,
    alt: 'Dia do Médico iClinic',
    trackId: 'upgrade_feature_modal_physicianDay_click',
  },
  marketing: {
    title: 'Fique mais próximo do seu paciente',
    text: marketingText(),
    imageUrl: `${ASSETS_PATH}images/common/upgrade/video.png`,
    youtubeUrl: '-dI-aX6fPs8',
    alt: 'iClinic Marketing',
    trackId: 'upgrade_feature_modal_marketing_click',
  },
  telemedicine: {
    title: 'Cuide de seus pacientes mesmo à distância',
    text: telemedicineText(),
    imageUrl: `${ASSETS_PATH}images/common/upgrade/teleconsulta.jpg`,
    youtubeUrl: '_-bdo17MZlg',
    alt: 'Teleconsulta',
    trackId: 'upgrade_feature_modal_telemedicine_click',
  },
  stock: {
    title: 'Ganhe mais inteligência na sua gestão',
    text:
      'Conheça o Controle de Estoque iClinic, a ferramenta que te ajuda a conquistar mais praticidade no seu dia a dia e aumentar a eficiência de sua clínica.\n' +
      'Localize e organize todos os produtos e lotes do estoque em um só lugar, e reduza custos todos os meses.',
    youtubeUrl: 'EdNqjVdxlrc',
    alt: 'Controle de estoque',
    trackId: 'upgrade_feature_modal_stock_click',
  },
  'financeiro-tiss': {
    title: 'Ganhe MAIS agilidade no faturamento dos seus atendimentos',
    text: tissText(),
    imageUrl: `${ASSETS_PATH}images/common/upgrade/tiss.gif`,
    alt: 'Faturamento TISS',
    trackId: 'upgrade_feature_modal_tiss_click',
  },
  survey: {
    title: 'Aumente sua reputação entre os pacientes',
    text:
      'Com a Pesquisa de Satisfação iClinic, você consegue saber como os pacientes avaliam a experiência do seu atendimento e o consultório como um todo.\n' +
      'Ganhe dados reais e privilegiados para mensurar quais expectativas estão sendo ou não atendidas, e estimule o retorno e a recomendação dos seus pacientes. Esta ferramenta está disponível a partir do plano PLUS, faça seu upgrade e aproveite!',
    imageUrl: `${ASSETS_PATH}images/common/upgrade/survey.gif`,
    alt: 'Pesquisa de Satisfação',
    trackId: 'upgrade_feature_modal_survey_click',
  },
  'electronic-signature': {
    title: 'Praticidade para seu paciente e você mais seguro',
    text: electronicSignatureText(),
    imageUrl: `${ASSETS_PATH}images/records/electronic-signature-addon.gif`,
    alt: 'Pesquisa de Satisfação',
    trackId: 'medical_record_certificate_buy_patient_signature',
  },
  'message-whatsapp': {
    title: 'Reduza as faltas e converta mais agendamentos',
    text: 'Aumente o número de consultas no seu consultório com a nova ferramenta de Mensageria Afya iClinic. Envie mensagens automáticas de lembrete e confirmação de consultas para os seus pacientes, reduzindo as faltas e cancelamentos de última hora.',
    imageUrl: `${ASSETS_PATH}images/common/upgrade/message-whatsapp.gif`,
    alt: 'Relacionamento',
    trackId: 'upgrade_feature_modal_message_whatsapp_click',
  },
};

export type ProductViews =
  | 'physicianDay'
  | 'logo'
  | 'multiprocedures'
  | 'sms'
  | 'birthdays'
  | 'chat'
  | 'reports'
  | 'finances'
  | 'stock'
  | 'financeiro-tiss'
  | 'survey';

/**
 * @deprecated
 * should be moved to normalized slug views
 */

const marketingTitle =
  'Parabéns, agora você tem a versão mais completa do iClinic!';
const telemedicineTitle = 'Parabéns, agora você possui a Teleconsulta!';
const instructionsUrlPro =
  'https://suporte.iclinic.com.br/configurar-o-plano-pro';

/**
 * @deprecated
 * needs refactor to normalize properties
 * parameters should be product slug
 */
export const successAddonViews = {
  upgrade: {
    instructionsUrl: 'https://suporte.iclinic.com.br/recursos-adicionais',
  },
  telemedicine: {
    title: telemedicineTitle,
    productName: 'a Teleconsulta iClinic',
    alt: 'Parabéns, agora você possui a Teleconsulta iClinic!',
    instructionsUrl: 'https://suporte.iclinic.com.br/teleconsulta-iclinic',
    imageUrl: successImage,
  },
  'electronic-signature': {
    productName: 'ASSINATURA ELETRONICA',
    title: 'Parabéns, agora você possui assinatura eletrônica!',
    alt: 'Agora você possui a assinatura eletrônica!',
    instructionsUrl:
      'https://suporte.iclinic.com.br/pt-br/como-assinar-documentos-paciente',
    imageUrl: successImage,
  },
};

export type SuccessAddonViews = 'upgrade';

export const successViews = {
  premium: {
    productName: 'PREMIUM',
    title:
      'Parabéns, agora você tem mais ferramentas para auxiliar a gestão da sua clínica!',
    alt: 'Agora você é PREMIUM!',
    instructionsUrl:
      'https://suporte.iclinic.com.br/configurar-o-plano-premium',
    imageUrl: successImage,
  },
  plus: {
    productName: 'PLUS',
    title:
      'Parabéns, agora você tem mais ferramentas para auxiliar a gestão da sua clínica!',
    alt: 'Agora você é PLUS!',
    instructionsUrl: 'https://suporte.iclinic.com.br/configurar-o-plano-plus',
    imageUrl: successImage,
  },
  pro: {
    productName: 'PRO',
    title: marketingTitle,
    alt: 'Agora você é PRO!',
    instructionsUrl: instructionsUrlPro,
    imageUrl: successImage,
  },
  telemedicine: {
    productName: 'TELECONSULTA',
    title: marketingTitle,
    alt: 'Agora você possui a Teleconsulta!',
    instructionsUrl: instructionsUrlPro,
    imageUrl: successImage,
  },
};

export type SuccessViews = 'premium' | 'plus' | 'pro' | 'telemedicine';
