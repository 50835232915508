// External
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Container, Text } from '@iclinic/iclinic-ui';
// Selectors
import { FORM_SUBSCRIBE } from 'shared/constants/forms';
import {
  getProductPrice,
  getProductListPrice,
} from 'state/subscription/selectors';
// Internal
import { PRODUCT_SELECTOR } from 'state/subscription/constants';
import * as actions from 'state/subscription/actions';
import SubscriptionModal from '../components/SubscriptionModal';
import enhanceSubscriptionForm from './EnhanceSubscriptionForm';
import Card from '../components/card';

const EnhanceSubscriptionForm = enhanceSubscriptionForm(
  FORM_SUBSCRIBE,
  actions.subscribeSubmit,
);

class SubscriptionContainer extends React.Component {
  componentDidMount() {
    const { fetchGetProducts, fetchGetProduct } = this.props;

    fetchGetProducts();
    fetchGetProduct('online-scheduling');
  }

  changeExtraProduct = (e) => {
    const hasExtraProduct = e.target.checked;
    const { changeExtraProduct, subscribeDetail } = this.props;
    const { extraProductSlug } = subscribeDetail;

    changeExtraProduct(hasExtraProduct, extraProductSlug);
  };

  openProductDetail = (product) => {
    const { setSelectedProduct, openModalCreditCard } = this.props;

    setSelectedProduct(product);
    openModalCreditCard();
  };

  render() {
    const {
      // store
      errorsProducts,
      totalUsers,
      products,
      isFetching: isFetchingProducts,
      subscribeDetail: {
        hasExtraProduct,
        extraProduct,
        isOpen,
        errorsDetails,
        isFetching,
        isMonthly,
      },
      // actions
      changeTabRecurrence,
      closeModalCreditCard,
      // selectors
      extraProductPrice,
      selectedProductPrice,
      // props
      titleClassName,
      containerFlexClassName,
    } = this.props;

    return (
      <div>
        <Text kind="highlight" size="lg" className={titleClassName}>
          Assinatura
        </Text>
        <div style={{ marginBottom: '20px' }} />
        {errorsProducts.length > 0 && !isFetchingProducts ? (
          <p>Erro ao carregar produtos</p>
        ) : (
          <Container direction="row" className={containerFlexClassName}>
            {products.map((product) => {
              const {
                productValue,
                productDiscount,
                frequencyNumber,
              } = getProductListPrice()(product);

              return (
                <Card
                  key={product.slug}
                  product={product}
                  openProductDetail={this.openProductDetail}
                >
                  <Card.Header
                    name={product.name}
                    value={productValue}
                    frequencyNumber={frequencyNumber}
                    discount={productDiscount}
                  />
                </Card>
              );
            })}
          </Container>
        )}
        {isOpen && (
          <EnhanceSubscriptionForm
            render={({
              handleSubmit,
              pristine,
              submitting,
              submitForm,
              number,
            }) => (
              <SubscriptionModal
                closeModalCreditCard={closeModalCreditCard}
                errorsDetails={errorsDetails}
                title="Assinar"
                isMonthly={isMonthly}
                hasExtraProduct={hasExtraProduct}
                extraProduct={extraProduct}
                extraProductPrice={extraProductPrice}
                selectedProductPrice={selectedProductPrice}
                changeTabRecurrence={changeTabRecurrence}
                changeExtraProduct={this.changeExtraProduct}
                totalUsers={totalUsers}
                isFetching={isFetching}
                submitForm={submitForm}
              >
                <SubscriptionModal.Form
                  pristine={pristine}
                  handleSubmit={handleSubmit}
                  submitting={submitting}
                  number={number}
                />
              </SubscriptionModal>
            )}
          />
        )}
      </div>
    );
  }
}

SubscriptionContainer.defaultProps = {
  errorsProducts: [],
  isFetching: false,
};

SubscriptionContainer.propTypes = {
  fetchGetProducts: PropTypes.func.isRequired,
  fetchGetProduct: PropTypes.func.isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  openModalCreditCard: PropTypes.func.isRequired,
  changeExtraProduct: PropTypes.func.isRequired,
  errorsProducts: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ),
  totalUsers: PropTypes.number.isRequired,
  changeTabRecurrence: PropTypes.func.isRequired,
  closeModalCreditCard: PropTypes.func.isRequired,
  titleClassName: PropTypes.string.isRequired,
  containerFlexClassName: PropTypes.string.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  isFetching: PropTypes.bool,
  subscribeDetail: PropTypes.shape({
    extraProductSlug: PropTypes.string,
    hasExtraProduct: PropTypes.bool,
    isFetching: PropTypes.bool,
    isMonthly: PropTypes.bool,
    isOpen: PropTypes.bool,
    extraProduct: PropTypes.instanceOf(Object).isRequired,
    errorsDetails: PropTypes.arrayOf(
      PropTypes.shape({
        message: PropTypes.string,
      }),
    ),
  }).isRequired,
  extraProductPrice: PropTypes.shape({
    productValueWithPromotion: PropTypes.number,
  }).isRequired,
  selectedProductPrice: PropTypes.shape({
    productName: PropTypes.string,
    productValueWithPromotion: PropTypes.number,
  }).isRequired,
};

const mapStateToProps = () => (state) => ({
  selectedProductPrice: getProductPrice(PRODUCT_SELECTOR.selected)(state),
  extraProductPrice: getProductPrice(PRODUCT_SELECTOR.extraProduct)(state),
  ...state.subscription,
});

export default connect(mapStateToProps, actions)(SubscriptionContainer);
