// External
import React from 'react';
import { ContentState } from 'draft-js';

// Internal
import style from './decorator.scss';

type ButtonProps = {
  entityKey: string,
  contentState: ContentState,
  children: React.ReactNode,
};

const Button = ({
  contentState,
  entityKey,
  children,
}: ButtonProps): JSX.Element => {
  const { url } = contentState.getEntity(entityKey).getData();
  return (
    <a
      className={style.button}
      href={url}
      aria-label={url}
    >
      {children}
    </a>
  );
};

export default Button;
