export enum Channels {
  COPY = 'copypaste',
  SHARE = 'webshare',
  WHATSAPP = 'whatsapp',
}

export const socialMediaMessage = `Olá, tudo bem?\n
Vim te convidar para conhecer a iClinic, sistema de gestão clínica e prontuário eletrônico.
Utilizando meu convite exclusivo, você ganha *25% de desconto nos primeiros 3 meses* de sua assinatura.\n
Se estiver com dúvidas, não se preocupe. Você tem 5 dias grátis para testar as ferramentas do sistema e uma equipe de especialistas disponível em horário comercial que podem te ajudar com todas as suas questões, tudo isso sem nenhum compromisso.
Clique no link abaixo e aproveite!\n\n`;
