import { Box, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = tokens;

export const TelemedicineReportWrapper = styled(Box)({
  padding: `0 ${spacing.md}`,
  marginBottom: spacing.md,
});

export const FiltersWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `0 ${spacing.md} ${spacing.md}`,
  gap: spacing.lg,
  flexWrap: 'wrap',
});

export const ContentWrapper = styled(Box)({
  display: 'grid',
  padding: `${spacing.md} ${spacing.md} ${spacing.md} 0`,
  gridTemplateColumns: '1fr auto',
  gridTemplateAreas: `
    "table upgrade-call"
    "pagination ."
  `,
});

export const TableWrapper = styled(Box)({
  overflowX: 'auto',
  gridArea: 'table',
});

export const LoaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 150,

  [theme.breakpoints.up(1200)]: {
    height: 500,
  },
}));

export const PeriodFiltersWrapper = styled(Box)({
  display: 'flex',
  gap: spacing.base,
  flexWrap: 'wrap',
});
