import axios from 'axios';

import { interceptorResponseTransformData } from 'services/iclinic/interceptors';
import { GetCalculatorsByCategoryResponse } from './types';

const apiWhitebook = axios.create({
  baseURL: process.env.ICLINIC_WHITEBOOK_API,
  auth: {
    username: process.env.ICLINIC_WHITEBOOK_USERNAME!,
    password: process.env.ICLINIC_WHITEBOOK_PARTNER_TOKEN!,
  },
});

apiWhitebook.interceptors.response.use(interceptorResponseTransformData);

export const getCalculators = (): Promise<GetCalculatorsByCategoryResponse> =>
  apiWhitebook.get(`/parceiro/calculadora`);

export const fetchCalculator = (calculatorId: number) =>
  apiWhitebook.get(`parceiro/conteudo/${calculatorId}`);
