import { PromiseResponse } from '@types';

import {
  CreateExpenseBodyParams,
  CreateExpenseResponse,
  ExpenseApiResponse,
  FetchExpenseAutocompletePayload,
  FetchExpenseDetailResponse,
  UpdateExpenseResponse,
} from 'features/tissInvoicing/types';
import { apiTissInvoicing, iclinicApi } from '..';

export const fetchExpenseListData = ({
  page = 1,
}): PromiseResponse<ExpenseApiResponse> =>
  apiTissInvoicing.get(`/expense/`, {
    params: {
      page,
    },
  });

export const deleteExpense = (id: string): PromiseResponse<void> =>
  apiTissInvoicing.delete(`/expense/${id}`);

export const createExpense = (
  body: CreateExpenseBodyParams,
): PromiseResponse<CreateExpenseResponse> =>
  apiTissInvoicing.post(`/expense/`, body);

export const updateExpense = (
  id: string,
  body: CreateExpenseBodyParams,
): PromiseResponse<UpdateExpenseResponse> =>
  apiTissInvoicing.put(`/expense/${id}/`, body);

export const fetchExpenseDetails = (
  id: string,
): PromiseResponse<FetchExpenseDetailResponse> =>
  apiTissInvoicing.get(`/expense/${id}`);

export const fetchExpenseAutocomplete = (
  payload: Omit<FetchExpenseAutocompletePayload, 'name'>,
): PromiseResponse<any> => {
  const { endpoint, isTissApi, params } = payload;

  const apiService = isTissApi ? apiTissInvoicing : iclinicApi;

  return apiService.get(endpoint, { params });
};
