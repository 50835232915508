import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  MaterialUICore,
  MaterialIcons,
  Box,
} from '@iclinic/design-system';

import {
  OffCanvasPlansComparationTable,
  MobileFooterSummary,
  ModalPaymentForm,
  PaymentSummary,
  Toggle,
  PlanSelect,
  ModalSelectPhysicians,
  ModalSuccess,
} from '../../components';
import * as S from './styles';
import {
  fetchCheckoutData,
  toggleAnnualOrMonthlyPaymentRecurrence,
  toggleOffCanvasOpenClose,
  triggerVerifyInitialStep,
} from '../../state/plans';
import {
  getBaseProducts,
  isFetchingPlans,
  getMostSeller,
  isOffCanvasOpen,
  getSelectedAddons,
  getRecurrence,
  getSelectedPlan,
} from '../../state/plans/selectors';
import { Product } from '../../services/types';

const { PlaylistAddCheck } = MaterialIcons;

const { CircularProgress, useTheme, useMediaQuery } = MaterialUICore;

export default function Plans(): JSX.Element {
  const dispatch = useDispatch();
  const isFetching = useSelector(isFetchingPlans);
  const plans = useSelector(getBaseProducts);
  const selectedAddons = useSelector(getSelectedAddons);
  const selectedPlan = useSelector(getSelectedPlan);
  const mostSeller = useSelector(getMostSeller);
  const isAnnual = useSelector(getRecurrence);
  const isCanvasOpen = useSelector(isOffCanvasOpen);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(fetchCheckoutData());
  }, [dispatch]);

  const toggleRecurrence = () => {
    dispatch(toggleAnnualOrMonthlyPaymentRecurrence());
  };

  const handleClick = () => {
    dispatch(toggleOffCanvasOpenClose());
  };

  const handlePaymentClick = () => {
    dispatch(triggerVerifyInitialStep());
  };

  if (isFetching)
    return (
      <Box display="flex" justifyContent="center" py={3}>
        <CircularProgress data-testid="loading-plans" />
      </Box>
    );

  return (
    <S.Root>
      <S.ToggleHeading variant="md">
        {isMobile
          ? 'Qual plano você gostaria de assinar?'
          : 'Selecione o plano que melhor lhe atende'}
      </S.ToggleHeading>

      <S.ToggleContainer>
        <Toggle
          leftLabel="Mensal"
          rightLabel="Anual"
          value={isAnnual}
          onChange={toggleRecurrence}
        />

        <S.ComparationButton
          startIcon={
            <PlaylistAddCheck
              className={isCanvasOpen ? 'visible' : ''}
              fontSize="small"
            />
          }
          variant="text"
          color="transparent"
          onClick={handleClick}
        >
          Comparar funcionalidades dos planos
        </S.ComparationButton>
      </S.ToggleContainer>

      <S.FormContainer>
        {plans &&
          plans.map((plan: Product) => (
            <PlanSelect
              key={plan.id}
              plan={plan}
              isAnnual={isAnnual}
              mostSeller={mostSeller}
            />
          ))}
      </S.FormContainer>

      <S.SummaryWrapper>
        <PaymentSummary isAnnual={isAnnual} showSinglePrice />

        <Button
          fullWidth
          size="large"
          onClick={handlePaymentClick}
          disabled={!selectedPlan}
          data-ga="checkout_payment_modal_open_click"
        >
          Ir para pagamento
        </Button>
      </S.SummaryWrapper>

      <MobileFooterSummary
        isAnnual={isAnnual}
        onPaymentClick={handlePaymentClick}
      />

      <OffCanvasPlansComparationTable />

      {selectedAddons.map((addon) => (
        <ModalSelectPhysicians key={addon.slug} addon={addon} />
      ))}

      <ModalPaymentForm />
      <ModalSuccess />
    </S.Root>
  );
}
