import React from 'react';

import { Box } from '@iclinic/design-system';
import CardAccountDataLoader from './accountData/CardAccountDataLoader';
import CardCreditCardLoader from './card/CardCreditCardLoader';

const SellerRegisterBankLoader = () => {
  return (
    <>
      <Box mb={2}>
        <CardAccountDataLoader />
      </Box>
      <CardCreditCardLoader />
    </>
  );
};

export default SellerRegisterBankLoader;
