// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import style from './Content.scss';

export default function Content({
  title,
  children,
  isSelectable,
  actualSelectedProduct,
}) {
  return (
    <div className={style.content}>
      <p className={style.content__title}>
        {isSelectable && <span className={actualSelectedProduct ? `${style.radio} ${style.selected}` : style.radio} />}
        {title}
      </p>
      <ul className={style.content__itens}>{children}</ul>
    </div>
  );
}

Content.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isSelectable: PropTypes.bool,
  actualSelectedProduct: PropTypes.bool,
};

Content.defaultProps = {
  actualSelectedProduct: false,
  isSelectable: false,
};
