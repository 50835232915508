import React from 'react';

import Feedback from 'features/video-conference/components/TeamComponents/Feedback';
import { reconnectingParticipant } from 'features/video-conference/constants';
import { useConference } from 'features/video-conference/state/conferenceContext';

export default function ReconnectingParticipant(): JSX.Element {
  const {
    title,
    message,
    altText,
    linkText,
    linkUrl,
  } = reconnectingParticipant;
  const { reconnectingBlob } = useConference();

  return (
    <Feedback
      title={title}
      message={message}
      altText={altText}
      imagePath={URL.createObjectURL(reconnectingBlob)}
      linkText={linkText}
      linkUrl={linkUrl}
      isReconnectingFeedback
    />
  );
}
