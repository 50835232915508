import {
  MaterialIcons,
  Button,
  Tooltip,
  ChipProps,
} from '@iclinic/design-system';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Severity } from '@iclinic/design-system/src/components/SnackBar/types';

import { actions } from '../../state';
import { getSignatureStatus as getSignatureStatusService } from '../../state/services';
import {
  ElectronicSignatureStatusEnum,
  ElectronicSignatureStatusItem,
} from '../../types';
import {
  StatusChip,
  StatusContainer,
  StatusPendingIcon,
  StatusSuccessIcon,
} from './ElectronicSignatureStatus.styles';
import {
  getClinicId,
  getElectronicSignatureState,
} from '../../state/selectors';
import { SnackbarComponent } from '../SnackbarComponent';

const { openElectronicSignatureModal } = actions;
const { ContentCopyRounded } = MaterialIcons;

interface ElectronicSignatureStatusProps {
  propsFromApp: {
    attestId: number;
    pdfDownloadURL: string;
  };
}

export const ElectronicSignatureStatus = ({
  propsFromApp,
}: ElectronicSignatureStatusProps) => {
  const { attestId, pdfDownloadURL } = propsFromApp;
  const dispatch = useDispatch();

  const clinicId = useSelector(getClinicId);
  const { signatureLink, attestId: attestIdSignatureLink } = useSelector(
    getElectronicSignatureState,
  );

  const [statusItem, setStatusItem] = useState<ElectronicSignatureStatusItem>();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState<Severity>('success');

  const [isLoading, setIsLoading] = useState(false);

  const labelText =
    statusItem?.status === ElectronicSignatureStatusEnum.DONE
      ? 'Assinatura concluída'
      : 'Assinatura pendente';

  const statusText =
    severity === 'success'
      ? 'Link copiado com sucesso.'
      : 'O status da assinatura permanece pendente.';

  const onSendToSignature = () => {
    dispatch(
      openElectronicSignatureModal({
        attestId,
        clinicId: Number(clinicId),
        pdfDownloadURL: `${pdfDownloadURL}?p=${attestId}`,
      }),
    );
  };

  const onOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const onCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const onGetSignatureStatus = useCallback(
    (onClickAction?: boolean) => {
      setIsLoading(true);
      getSignatureStatusService(attestId).then((response) => {
        setIsLoading(false);
        if (response.data) {
          const status = response.data[0];
          setStatusItem(status);

          if (
            onClickAction &&
            status.status === ElectronicSignatureStatusEnum.PENDING
          ) {
            setSeverity('info');
            onOpenSnackbar();
          }
        }
      });
    },
    [attestId],
  );

  useEffect(() => {
    onGetSignatureStatus();
  }, [onGetSignatureStatus]);

  useEffect(() => {
    if (signatureLink && attestIdSignatureLink === attestId) {
      onGetSignatureStatus();
    }
  }, [attestId, attestIdSignatureLink, onGetSignatureStatus, signatureLink]);

  const onCopyLink = () => {
    navigator.clipboard.writeText(statusItem?.access_link || '').finally(() => {
      setSeverity('success');
      onOpenSnackbar();
    });
  };

  const statusDoneProps = {
    icon: <StatusSuccessIcon />,
  };

  const statusPendingProps = {
    onClick: () => {
      onGetSignatureStatus(true);
    },
    icon: <StatusPendingIcon />,
    disabled: isLoading,
  };

  const statusChipProps: ChipProps =
    statusItem?.status === ElectronicSignatureStatusEnum.DONE
      ? statusDoneProps
      : statusPendingProps;

  return (
    <StatusContainer>
      {!statusItem ? (
        <Button
          color="secondary"
          onClick={onSendToSignature}
          variant="contained"
          size="small"
          className="button-status"
        >
          Enviar para que paciente assine
        </Button>
      ) : (
        <>
          <Tooltip
            title="Clique aqui e veja se há atualização do status da assinatura"
            placement="top"
          >
            <StatusChip
              label={labelText}
              variant="outlined"
              status={statusItem?.status}
              data-testid="status-chip"
              {...statusChipProps}
            />
          </Tooltip>
          {statusItem?.status === ElectronicSignatureStatusEnum.PENDING && (
            <>
              <Button
                color="transparent"
                onClick={onCopyLink}
                variant="contained"
                size="small"
                className="button-status"
                startIcon={<ContentCopyRounded />}
              >
                Copiar link
              </Button>
              <SnackbarComponent
                message={statusText}
                severity={severity}
                openSnackbar={openSnackbar}
                onClose={onCloseSnackbar}
              />
            </>
          )}
        </>
      )}
    </StatusContainer>
  );
};
