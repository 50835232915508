import { call, put, takeLatest } from 'redux-saga/effects';
import { AxiosError } from 'axios';
import { Response } from '@types';

import * as actions from '.';
import * as api from '../../../services';
import { getErrorCode, replaceCharactersByTags } from '../../../utils';
import { messagesByCode, successMessage } from '../../../constants';
import {
  normalizePreviewTemplateMessageData,
  normalizeSingleScheduleTemplateMessage,
} from './normalizer';
import {
  PreviewMessage,
  TemplateKind,
} from 'features/messaging/services/types';

type PayloadFetchConfirmationTemplateMessage = ReturnType<
  typeof actions.fetchConfirmationTemplateMessage
>;
type PayloadSubmitConfirmationTemplateMessage = ReturnType<
  typeof actions.submitConfirmationTemplateMessage
>;
type PayloadFetchConfirmationPreviewMessage = ReturnType<
  typeof actions.fetchConfirmationPreviewMessage
>;

export function* fetchConfirmationTemplateMessageWorker({
  payload: physicianId,
}: PayloadFetchConfirmationTemplateMessage) {
  try {
    yield put(actions.fetchConfirmationTemplateMessageStart());

    const { data: templateMessage } = yield call(
      api.getConfirmationMessageTemplate,
      physicianId,
    );

    yield put(actions.fetchConfirmationTemplateMessageSuccess(templateMessage));
  } catch {
    yield put(actions.fetchConfirmationTemplateMessageFailure());
  }
}

export function* submitConfirmationTemplateMessageWorker({
  payload,
}: PayloadSubmitConfirmationTemplateMessage) {
  try {
    yield put(actions.submitConfirmationTemplateMessageStart());
    const { physicianId, singleScheduleTemplateMessage } = payload;

    const templateMessage = normalizeSingleScheduleTemplateMessage(
      singleScheduleTemplateMessage,
    );

    const { data: updatedTemplate } = yield call(
      api.putConfirmationMessageTemplate,
      physicianId,
      templateMessage,
    );

    yield put(actions.fetchConfirmationTemplateMessageSuccess(updatedTemplate));
    yield put(actions.submitConfirmationTemplateMessageSuccess(successMessage));
  } catch (error) {
    const errorCode = getErrorCode(error as AxiosError);
    const errorMessage = messagesByCode[errorCode];

    yield put(actions.submitConfirmationTemplateMessageFailure(errorMessage));
  }
}

export function* fetchConfirmationPreviewMessageWorker({
  payload: { physicianId, template },
}: PayloadFetchConfirmationPreviewMessage) {
  try {
    yield put(actions.fetchConfirmationPreviewMessageStart());

    const templateMessage = normalizePreviewTemplateMessageData(template);

    const { data }: Response<PreviewMessage> = yield call(
      api.getPreviewMessage,
      {
        kind: TemplateKind.CONFIRMATION,
        physicianId,
        template: templateMessage,
      },
    );

    const replacedMessage = replaceCharactersByTags(data.message);

    yield put(actions.fetchConfirmationPreviewMessageSuccess(replacedMessage));
  } catch {
    yield put(actions.fetchConfirmationPreviewMessageFailure());
  }
}

export default function* personalizationSagas() {
  yield takeLatest(
    actions.fetchConfirmationTemplateMessage,
    fetchConfirmationTemplateMessageWorker,
  );
  yield takeLatest(
    actions.submitConfirmationTemplateMessage,
    submitConfirmationTemplateMessageWorker,
  );
  yield takeLatest(
    actions.fetchConfirmationPreviewMessage,
    fetchConfirmationPreviewMessageWorker,
  );
}
