import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { useGetZipCode } from '../services';
import { ClinicFormValues } from '../types';

const keys = ['city', 'state', 'address', 'neighborhood'];

export function useGetZipCodeAddress() {
  const { setFieldValue } = useFormikContext<ClinicFormValues>();

  const api = useGetZipCode({ lazy: true });
  const data = api.data as Record<string, string> | null;

  useEffect(() => {
    if (data) {
      keys.forEach((key) => {
        if (data[key]) setFieldValue(key, data[key]);
      });
    }
  }, [data, setFieldValue]);

  return { getAddress: api.request, zipCodeIsLoading: api.isLoading };
}
