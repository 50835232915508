import { combineReducers } from 'redux';

import requestFreeSamples, { RequestFreeSamplesState } from './request-samples';
import sampleBoxes, { SampleBoxesState } from './sample-boxes';

export type FreeSamplesState = {
  requestFreeSamples: RequestFreeSamplesState;
  sampleBoxes: SampleBoxesState;
};

const freeSamplesReducer = combineReducers<FreeSamplesState>({
  requestFreeSamples,
  sampleBoxes,
});

export default freeSamplesReducer;
