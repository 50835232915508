// External
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

// Internal
import { requiredEmpty, url } from 'shared/utils/formValidation';
import { CAMPAIGN_EDITOR_LINK_MODAL } from 'shared/constants/forms';
import * as selectors from 'state/campaign/selectors';
import * as actions from 'state/campaign/actions';
import Modal from 'ui/new/modal';
import Button from 'ui/new/button';
import { Input } from 'ui/new/form';
import style from './EditorModal.scss';

const EditorLinkModal = ({ handleSubmit, onCloseClick, onAddURL }) => {
  const onClickSave = React.useCallback(
    ({ textSelected, inputUrl }) => {
      onAddURL(textSelected, inputUrl);
    },
    [onAddURL],
  );

  return (
    <form onSubmit={handleSubmit(onClickSave)}>
      <div className={style.modal}>
        <Modal
          show
          title="Editar Link"
          onClose={onCloseClick}
          classes={style}
          width={500}
        >
          <Modal.Body>
            <p>Nome a ser exibido</p>
            <Field
              type="text"
              name="textSelected"
              component={Input}
              validate={[requiredEmpty]}
            />
            <p className={style.titleField}>Endereço do link</p>
            <Field
              type="text"
              name="inputUrl"
              component={Input}
              placeholder="http://www.site.com.br"
              validate={[requiredEmpty, url]}
            />
          </Modal.Body>
          <Modal.Footer align="right">
            <Button type="light" onClick={onCloseClick}>
              CANCELAR
            </Button>
            <Button type="success" submit>
              SALVAR
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </form>
  );
};

EditorLinkModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  onAddURL: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  initialValues: selectors.getEditorLinkModalInitialValues(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: CAMPAIGN_EDITOR_LINK_MODAL })(EditorLinkModal));
