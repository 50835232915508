// External
import React, { useContext } from 'react';
import Icon from '@material/react-material-icon';

// Internal
import classnames from 'classnames/bind';
import ThemeContext, { UserThemeStyle } from 'shared/hooks/theme';
import { COLOR } from './constants';
import ss from './Spacer.scss';

const cx = classnames.bind(ss);

type SpacerProps = {
  classes?: string;
  icon?: string;
  color?: string;
};

export default function Spacer({
  classes = '',
  icon = '',
  color = COLOR.Primary,
}: SpacerProps): JSX.Element {
  const { isApplied, style } = useContext(ThemeContext);

  return (
    <div className={cx(ss.spacer, classes, UserThemeStyle(ss, style, isApplied))}>
      <span className={ss.line} />
      <Icon
        className={cx(ss.icon, color)}
        icon={icon}
      />
      <span className={ss.line} />
    </div>
  );
}
