import React, { useState } from 'react';

import { IPayment } from 'features/newFinance/types/types';
import { formatCurrency } from 'shared/utils/strings';
import {
  CustomCheckBox,
  DetailsHeader as InstallmentsDetailsHeader,
  DetailsItem as InstallmentsDetailsItem,
} from '../components/commonStyles';
import { formatDate } from 'shared/utils/date';

type BankCheckInfoProps = {
  payment: IPayment;
};

export default function BankCheckInfo({ payment }: BankCheckInfoProps) {
  const { bankCheckIsInstallment, bankCheckInstallments } = payment;
  const [bankCheckInstallmentsCopy, setBankCheckInstallmentsCopy] = useState(
    bankCheckInstallments,
  );

  const handleCheck = (index: number) => {
    const arrayModifier = [...bankCheckInstallmentsCopy];
    const installmentItem = { ...arrayModifier[index] };

    installmentItem.paid = !installmentItem.paid;
    arrayModifier[index] = installmentItem;
    setBankCheckInstallmentsCopy(arrayModifier);
  };
  return (
    <>
      <InstallmentsDetailsHeader>
        <span>Parcelas</span>
        <span>Vencimento</span>
        <span>Status</span>
        <span>Valor</span>
        <span>Recebido</span>
        <span>Data recebimento</span>
      </InstallmentsDetailsHeader>
      {bankCheckIsInstallment &&
        bankCheckInstallmentsCopy.map((installment, idx) => (
          <InstallmentsDetailsItem key={installment.bankCheckNumber}>
            <span>{`${+idx + 1}/${bankCheckInstallmentsCopy.length}`}</span>
            <span>{formatDate(installment.bankCheckDate.toISOString())}</span>
            <span className={installment.paid ? 'paid' : ''}>
              {installment.paid ? 'Recebido' : 'À receber'}
            </span>
            <span>{formatCurrency(installment.bankCheckValue)}</span>
            <span>
              <CustomCheckBox
                checked={installment.paid}
                onClick={() => handleCheck(idx)}
              />
            </span>
            <span>
              {installment.paidAt
                ? formatDate(installment.paidAt.toISOString())
                : 'dd/mm/aaaa'}
            </span>
          </InstallmentsDetailsItem>
        ))}
    </>
  );
}
