import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const { spacing, color } = axiomTokens;
export const useStyles = makeStyles(() => ({
  primaryButton: {
    padding: spacing['4'],
    outline: 'none',
    textDecoration: 'none',
    '&:hover, &:focus': {
      color: color.surface.subtle.default,
      outline: 'none',
      textDecoration: 'none',
    },
  },
  cancelButton: {
    padding: spacing['4'],
    color: color.typeface.pure,
  },
  dialogContent: {
    padding: `${spacing['4']} ${spacing['8']}`,
  },
  dialogActions: {
    padding: `${spacing['4']}`,
    width: '100%',
    justifyContent: 'space-between',
  },
}));
