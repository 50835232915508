import {
  Box,
  DatePicker,
  MaterialUICore,
  Select,
  tokens,
} from '@iclinic/design-system';

const { styled, MenuItem } = MaterialUICore;
const { spacing } = tokens;

export const CustomItem = styled('div')({
  display: 'block',
  padding: `0 ${spacing.sm}`,
});

export const PeriodSelect = styled(Select)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    maxWidth: 400,
  },
}));

export const PeriodDatePicker = styled(DatePicker)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: 152,
  },
}));

export const DatePickerWrapper = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: spacing.base,
});

export const HiddenMenuItem = styled(MenuItem)({
  display: 'none',
});

export const ChipsWrapper = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: spacing.base,
  marginTop: spacing.base,
});

export const AutocompleteWrapper = styled(Box)(({ theme }) => ({
  width: '100%',

  [theme.breakpoints.up('sm')]: {
    width: 224,
  },
}));
