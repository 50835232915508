import { Box, tokens, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Container = styled(Box)({
  display: 'flex',
  rowGap: tokens.spacing.base,
  marginBottom: tokens.spacing.sm,
});

export const HeaderTexts = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: tokens.spacing.base,
  marginBottom: tokens.spacing.sm,
  flex: 1,
});
