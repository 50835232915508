import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  MaterialUICore,
  Heading,
  Body,
} from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';
import { useToggleBodyClass } from 'shared/hooks';
import { RequestStatus } from 'shared/constants/State';
import { trackSignupIClinicPayButtons } from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { fetchSeller } from 'features/onlinePayment/state/config/register';
import { fetchSSOToken } from 'features/onlinePayment/state/iclinic-pay';
import { SellerStatus } from 'features/onlinePayment/state/constants';
import {
  getSSOToken,
  getSSOTokenStatus,
} from 'features/onlinePayment/state/iclinic-pay/selectors';
import {
  getUserInfo,
  getSellerProfiles,
} from 'features/onlinePayment/state/config/register/selectors';
import { createLink, ICLINIC_PAY_URLS } from './iClinicPayIntegration';
import styles from '../config/styles';
import * as S from '../../components/iclinic-pay/styles';
import MyIclinicPayLoader from './MyIclinicPayLoader';
import { ErrorAlert } from 'shared/components';
import { SELLER_FETCH_ERROR } from 'features/onlinePayment/state/config/errorMessages';

const { useTheme } = MaterialUICore;

export default function MyIclinicPay() {
  const theme = useTheme();
  const configClasses = styles();
  const dispatch = useDispatch();
  const userData = useSelector(getUserInfo);
  const [ssoToken, ssoTokenStatus] = [
    useSelector(getSSOToken),
    useSelector(getSSOTokenStatus),
  ];

  const profiles = useSelector(getSellerProfiles);
  const hasSellerProfilesApproved =
    profiles?.individual?.status === SellerStatus.Approved ||
    profiles?.business?.status === SellerStatus.Approved;

  useToggleBodyClass(configClasses.whiteBody, theme.breakpoints.down('sm'));

  useEffect(() => {
    dispatch(fetchSeller());
    dispatch(fetchSSOToken());
  }, [dispatch]);

  const handleClick = (buttonName: string) => {
    trackSignupIClinicPayButtons({
      userData,
      buttonName,
      buttonLocation: 'catalog_payment',
      additionalProperties: 'SSO',
    });
  };

  if (ssoTokenStatus === RequestStatus.Pending) {
    return (
      <Container maxWidth="md">
        <MyIclinicPayLoader />
      </Container>
    );
  }

  if (ssoTokenStatus === RequestStatus.Error || !ssoToken) {
    return (
      <Container maxWidth="md">
        <ErrorAlert
          message={
            <span
              dangerouslySetInnerHTML={{
                __html: SELLER_FETCH_ERROR,
              }}
            />
          }
        />
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <S.Header>
        <Heading variant="md">Catálogo de produtos</Heading>
        <Body variant="sm">
          Acesse todas soluções financeiras em um só lugar.
        </Body>
      </S.Header>

      <S.Card variant="outlined">
        <S.ImgWrapper>
          <img
            alt="Imagem Histórico de transações"
            src={image('onlinePayment.financialDashboard')}
          />
        </S.ImgWrapper>
        <S.Content>
          <Heading variant="md">Histórico de transações</Heading>
          <Body variant="sm">
            Acompanhe todas as transações realizadas na sua clínica e tenha
            controle dos seus pagamentos.
          </Body>
        </S.Content>
        <S.Button
          href="/pagamento/transacoes"
          target="_blank"
          onClick={() => handleClick('pay_sso_transaction_history')}
        >
          Ver transações
        </S.Button>
      </S.Card>

      <S.Divider />

      <S.Card variant="outlined">
        <S.ImgWrapper>
          <img
            alt="Imagem Cadastro de convênio"
            src={image('onlinePayment.paperAndPencil')}
          />
        </S.ImgWrapper>
        <S.Content>
          <Heading variant="md">Cadastro de convênio</Heading>
          <Body variant="sm">
            Sincronize todos os convênios parceiros em uma solução integrada e
            antecipe seus recebíveis.
          </Body>
        </S.Content>
        <S.Button
          href={createLink(ssoToken, ICLINIC_PAY_URLS.CADASTRO_CONVENIO)}
          target="_blank"
          onClick={() => handleClick('pay_sso_register_agreement')}
        >
          Cadastrar o convênio
        </S.Button>
      </S.Card>

      <S.Divider />

      {hasSellerProfilesApproved && (
        <>
          <S.Divider />

          <S.Card variant="outlined">
            <S.ImgWrapper>
              <img
                alt="Imagem Antecipação de transações"
                src={image('onlinePayment.dashboardCheck')}
              />
            </S.ImgWrapper>
            <S.Content>
              <Heading variant="md">Antecipação de transações</Heading>
              <Body variant="sm">
                Antecipe com apenas alguns cliques os seus pagamentos parcelados
                no crédito.
              </Body>
            </S.Content>
            <S.Button
              href={createLink(
                ssoToken,
                ICLINIC_PAY_URLS.ANTECIPACAO_RECEBIVES,
              )}
              target="_blank"
              onClick={() => handleClick('pay_sso_antecipate_credit')}
            >
              Antecipar recebíveis
            </S.Button>
          </S.Card>
        </>
      )}

      <S.Divider />

      <S.Card variant="outlined">
        <S.ImgWrapper>
          <img
            alt="Imagem Simulador de venda"
            src={image('onlinePayment.walletCheck')}
          />
        </S.ImgWrapper>
        <S.Content>
          <Heading variant="md">Simulador de venda</Heading>
          <Body variant="sm">
            Saiba o valor correto que você deve cobrar aos seus pacientes.
          </Body>
        </S.Content>
        <S.Button
          href={createLink(ssoToken, ICLINIC_PAY_URLS.SIMULADOR_VENDAS)}
          target="_blank"
          onClick={() => handleClick('pay_sso_simulator')}
        >
          Simular consulta
        </S.Button>
      </S.Card>

      {hasSellerProfilesApproved && (
        <>
          <S.Divider />

          <S.Card variant="outlined">
            <S.ImgWrapper>
              <img
                alt="Imagem Relatórios de recebíveis"
                src={image('onlinePayment.moneySlide')}
              />
            </S.ImgWrapper>
            <S.Content>
              <Heading variant="md">Relatórios de recebíveis</Heading>
              <Body variant="sm">
                Saiba quando e quanto você receberá em um só lugar. Tenha
                controle sobre os seus atendimentos.
              </Body>
            </S.Content>
            <S.Button
              href={createLink(ssoToken, ICLINIC_PAY_URLS.RELATORIO_RECEBIVES)}
              target="_blank"
              onClick={() => handleClick('pay_sso_receive_report')}
            >
              Acessar relatórios
            </S.Button>
          </S.Card>
        </>
      )}

      <S.Divider />

      <S.Card variant="outlined">
        <S.ImgWrapper>
          <img
            alt="Imagem Gestão Financeira"
            src={image('onlinePayment.released')}
          />
        </S.ImgWrapper>
        <S.Content>
          <Heading variant="md">Gestão Financeira</Heading>
          <Body variant="sm">
            Acompanhe seus recebíveis, créditos e antecipações em um só lugar.
          </Body>
        </S.Content>
        <S.Button
          href={createLink(ssoToken, ICLINIC_PAY_URLS.GESTAO_FINANCEIRA)}
          target="_blank"
          onClick={() => handleClick('pay_sso_financial_management')}
        >
          Ver detalhes
        </S.Button>
      </S.Card>
    </Container>
  );
}
