import {
  EditorState,
  convertFromRaw,
  ContentState,
} from 'draft-js';

import { decorator } from 'ui/new/editor';
import { ScheduleOffsetUnit } from 'features/marketing/constants';
import { ScheduleOffset, ErrorValidateComponent, EmailFlowComponent } from './emailFlowTypes';

export const descEmail = ({ value, unit }: ScheduleOffset): string => {
  if (value === 1) {
    return unit === ScheduleOffsetUnit.day ? `E-mail - ${value} dia` : `E-mail - ${value} mês`;
  }
  return unit === ScheduleOffsetUnit.day ? `E-mail - ${value} dias` : `E-mail - ${value} meses`;
};

export const isValidTextEditor = (draftJSState: any) => {
  let editorComponent: ContentState = EditorState.createEmpty(decorator).getCurrentContent();
  if (draftJSState) {
    if (Object.keys(draftJSState).length) {
      const { blocks } = draftJSState;
      if (blocks.length) {
        editorComponent = EditorState
          .createWithContent(convertFromRaw(draftJSState), decorator)
          .getCurrentContent();
      }
    }

    if (editorComponent && editorComponent.hasText()) {
      return true;
    }
  }
  return false;
};

export const isInvalidComponent = (
  components: EmailFlowComponent[],
): ErrorValidateComponent | undefined => {
  for (let i = 0; i < components.length; i += 1) {
    const componentEmailFlow = components[i];
    const { offset, subject, draftJSState } = componentEmailFlow;
    if (!subject) {
      return {
        errorsList: [{
          code: '400911',
          message: '',
        }],
        customMessage: `Defina um assunto para o "${descEmail(offset)}".`,
      };
    }
    if (offset && !offset.value) {
      return {
        errorsList: [{
          code: '400913',
          message: '',
        }],
        customMessage: `Defina um intervalo para envio do "${descEmail(offset)}".`,
      };
    }
    if (!isValidTextEditor(draftJSState)) {
      return {
        errorsList: [{
          code: '400912',
          message: '',
        }],
        customMessage: `Defina uma mensagem para o "${descEmail(offset)}".`,
      };
    }
  }
  return undefined;
};
