import { PromiseResponse } from '@types';

import {
  ClinicHealthInsuranceHiredExecutant,
  ClinicHealthInsuranceProfessionalExecutant,
  ClinicHealthInsuranceprops,
  GeneratePDFResponse,
  GuideSADT,
  HiredSolicitantCodeEntry,
  HiredSolicitantSlug,
  PatchGuideSADTPayload,
} from 'features/tissInvoicing/types';
import { apiTissInvoicing, iclinicApi } from '..';
import { EventParams } from 'features/tissInvoicing/types/sadt/event';

export function getGuideData(eventId: string): PromiseResponse<GuideSADT> {
  return apiTissInvoicing(`/event/${eventId}/guide/`);
}

export function getHiredSolicitantData(
  insuranceId: string,
  solicitantId: number,
  type: HiredSolicitantSlug,
): PromiseResponse<HiredSolicitantCodeEntry> {
  return iclinicApi(
    `clinics/clinichealthinsurance/${insuranceId}/tiss-hired-executant-info/${type}/${solicitantId}`,
  );
}

export function patchGuideSADT(
  guideId: string,
  payload: PatchGuideSADTPayload,
): PromiseResponse<GuideSADT> {
  return apiTissInvoicing.patch(`/guide/${guideId}/`, payload);
}

export const getGuidePDFUrl = (
  eventId: string,
): PromiseResponse<GeneratePDFResponse> =>
  apiTissInvoicing.get(`/guide/event/${eventId}/pdf`);

export const getClinichHealthInsuranceProfessionalExecutant = (
  insurance_id: number,
  physician_id: number,
): PromiseResponse<ClinicHealthInsuranceProfessionalExecutant> =>
  iclinicApi.get(
    `clinics/clinichealthinsurance/${insurance_id}/professional-executant/${physician_id}`,
  );

export const getClinichHealthInsuranceHiredExecutant = (
  insurance_id: number,
  physician_id: number,
): PromiseResponse<ClinicHealthInsuranceHiredExecutant> =>
  iclinicApi.get(
    `clinics/clinichealthinsurance/${insurance_id}/hired-executant/${physician_id}`,
  );

export const getClinicHealthShowData = (
  insurance_id: number,
): PromiseResponse<ClinicHealthInsuranceprops> =>
  iclinicApi.get(`/clinics/clinichealthinsurance/${insurance_id}`);

export function getEventApi(eventId: string): PromiseResponse<EventParams> {
  return iclinicApi.get(`events/event/${eventId}`);
}
