import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@iclinic/design-system';
import images from 'shared/paths/images';
import { useStyles } from './styles';
import BottomButtonContainer from '../BottomButtonContainer';
import { getSettingsIntegration } from 'features/settings/scheduleOnline/state/selectors';

export const CreateSuccess = () => {
  const integrationData = useSelector(getSettingsIntegration);
  const goToProfile = () => {
    window.open(
      `${process.env.ICLINIC_MKT_APP_BASE_URL}/new/complete-seu-perfil?token=${integrationData.token}`,
      '_blank',
    );
  };

  const { image } = useStyles();
  return (
    <>
      <Box mb={2}>
        <img
          className={image}
          src={images.settings.integrationSuccess}
          alt="rosto feliz"
        />
      </Box>
      <Box mb={1}>
        <Typography align="center" bold>
          Sucesso, agora falta pouco!
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography align="center" variant="body2">
          Sua agenda iClinic já ativa no seu perfil do Agendar Consulta. Clique
          Preencher perfil e finalize seu cadastro.
        </Typography>
      </Box>
      <BottomButtonContainer
        isIntegrated
        leftLabel="Sair"
        rightLabel="Preencher perfil"
        rightOnClickHandler={goToProfile}
      />
    </>
  );
};
