import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export const useStyles = makeStyles(() => ({
  header: {
    columnGap: tokens.spacing.sm,
  },
  autocomplete: {
    '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
      display: 'none !important',
    },
  },
}));
