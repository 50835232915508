// External
import { reduxForm, submit, InjectedFormProps } from 'redux-form';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// Internal
import { FORM_STOCK_DELETE } from 'shared/constants/forms';
import { changeModal } from 'features/stock/state/actions';

const mapDispatchToProps = {
  submitForm: () => submit(FORM_STOCK_DELETE),
};

type EnhanceProps = {
  change: InjectedFormProps['change'];
  handleSubmit: InjectedFormProps['handleSubmit'];
  submitForm(): Dispatch;
};

type StockFormReduxProps = {
  render(params: EnhanceProps): React.ReactNode;
};

type MapDispatchToProps = {
  submitForm(): Dispatch;
};
export default (submitAction: () => void) => {
  const StockFormRedux = ({
    render,
    change,
    handleSubmit,
    submitForm,
  }: StockFormReduxProps & MapDispatchToProps & InjectedFormProps) =>
    render({
      change,
      handleSubmit,
      submitForm,
    });

  const Form = reduxForm({
    form: FORM_STOCK_DELETE,
    onSubmit: (_, dispatch) => {
      dispatch(submitAction());
      dispatch(changeModal());
    },
  })(StockFormRedux);

  return connect(null, mapDispatchToProps)(Form);
};
