import React from 'react';

import Discount from './components/Discount';
import PaymentInfo from './components/PaymentInfo';
import ProceduresList from './components/ProcedureList';

export default (): JSX.Element => (
  <>
    <ProceduresList>
      <Discount />
    </ProceduresList>
    <PaymentInfo />
  </>
);
