// External
import * as React from 'react';

// Internal
import style from './Text.scss';

type TextProps = {
  children: React.ReactNode,
};

function Text({ children }: TextProps) {
  return (
    <div className={style.text}>
      {children}
    </div>
  );
}

export default Text;
