import { reducerMerge } from 'shared/utils/reducer';
import * as types from './types';

/** Constants */
export const initialState = {
  isOpen: false,
  trialEnabled: false,
};

const video = (state = initialState, action) => {
  switch (action.type) {
    case types.DETAIL.OPEN:
    case types.DETAIL.CLOSE:
    case types.FETCH_DETAIL.ACTION:
    case types.FETCH_DETAIL.SUCCESS:
    case types.FETCH_DETAIL.FAILURE:
      return reducerMerge({}, state, action.payload);
    default:
      return state;
  }
};

export default video;
