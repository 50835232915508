import React from 'react';

const MarketingText = () => (
  <div>
    <p>
      Conheça o
      {' '}
      <strong>iClinic Marketing</strong>
      {' '}
      a ferramenta que
      {' '}
      <strong>aproxima você de seu paciente</strong>
      {' '}
      mesmo quando você não está por perto.
    </p>
    <p>
      Aumente o
      {' '}
      <strong>retorno dos pacientes</strong>
      , faça
      {' '}
      <strong>orientações pré e pós-operatórias</strong>
      {' '}
      à distância e
      {' '}
      <strong>realize mais procedimentos</strong>
      {' '}
      todos os meses!
    </p>
  </div>
);

export default MarketingText;
