import * as Yup from 'yup';
import moment from 'moment';

import { PaymentForm } from 'features/onlinePayment/state/checkout/payment/types';
import { isCardNumberValid } from 'shared/utils/creditCardValidation';

// eslint-disable-next-line no-template-curly-in-string
const maxMessage = 'O campo aceita o máximo de ${max} caracteres';

const expiryMinDateValidation = (date: string) =>
  moment(date, 'MM/YY').isSameOrAfter(new Date(), 'month');

const expiryFormatValidation = (value: string) =>
  /^(0[1-9]|1[0-2])\/\d{2}$/.test(value);

const cvvFormatValidation = (value: string) => /^\d{3,4}$/.test(value);

export const validationSchema = Yup.object().shape({
  name: Yup.string().max(26, maxMessage).required('Nome é obrigatório'),
  cardNumber: Yup.string()
    .required('Número do cartão é obrigatório')
    .test('cardNumberFormat', `Número de cartão inválido.`, isCardNumberValid),
  expiry: Yup.string()
    .required('Validade é obrigatório')
    .test('expiryFormat', 'Data inválida', expiryFormatValidation)
    .test(
      'expiryMinDate',
      'Data de Validade Expirada',
      expiryMinDateValidation,
    ),
  cvv: Yup.string()
    .required('CVV é obrigatório')
    .test('cvvFormat', 'CVV inválido', cvvFormatValidation),
  installments: Yup.string().required('Parcelas é obrigatório'),
});

export const initialValues: PaymentForm = {
  name: '',
  cardNumber: '',
  expiry: '',
  cvv: '',
  installments: '',
};
