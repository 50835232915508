import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';

import { CBO, CBOState, FetchListCBORequest } from '../types';

const nameStore = '@webapp/tuss/cbo';

export const initialState: CBOState = {
  listCBO: {
    meta: {},
    objects: [],
  },
};

const slice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    submitListCBO: (state, action: PayloadAction<FetchListCBORequest>) => {
      return {
        ...state,
        listCBO: action.payload,
      };
    },
    submitNewCBOTerm: (state, action: PayloadAction<CBO>) => {
      return {
        ...state,
        listCBO: {
          ...state.listCBO,
          objects: [...state.listCBO.objects, action.payload],
        },
      };
    },
  },
});

export default slice.reducer;

export const listCBO = createAction<void>(`${nameStore}/listCBO`);
export const createCBO = createAction<string>(`${nameStore}/createCBO`);

export const { submitListCBO, submitNewCBOTerm } = slice.actions;
