import { takeLatest, call, put, select, delay } from 'redux-saga/effects';

import history from 'routes/history';
import {
  PaymentType,
  TransactionStatus,
} from 'features/onlinePayment/state/constants';
import { checkoutRoutes } from 'features/onlinePayment/state/checkout/constants';
import {
  checkoutRequestV2,
  syncStatusTransactionRequest,
} from 'features/onlinePayment/services/checkout';
import {
  SyncStatusBodyRawData,
  CheckoutV2RawData,
} from 'features/onlinePayment/services/checkout/types';
import {
  getBill,
  getPaymentType,
} from 'features/onlinePayment/state/checkout/infos/selectors';
import {
  checkoutSuccess,
  syncStatusTransaction,
  syncStatusTransactionFailure,
  syncStatusTransactionSuccess,
  checkoutV2Success,
  checkoutV2Failure,
} from './actions';
import {
  SYNC_STATUS_TRANSACTION_ACTION,
  SYNC_STATUS_TRANSACTION_SUCCESS,
  CHECKOUT_PIX,
  CHECKOUT_CREDIT_CARD,
} from './constants';
import {
  normalizeCheckoutRequestBodyV2,
  normalizeCheckoutV2Response,
} from './normalizers';
import {
  getCheckoutV2Data,
  getIsPixQRCodeValid,
  getStatusTransaction,
} from './selectors';
import { CheckoutCreditCard, CheckoutV2Data } from './types';
import { Response } from '@types';
import { Bill } from '../infos/types';

export function* workerSuccessCheckout() {
  yield call([history, history.push], {
    pathname: checkoutRoutes.success,
    search: window.location.search,
  });
}

export function* workerSyncStatusTransaction() {
  try {
    const bill: Bill | null = yield select(getBill);
    if (bill == null) return;

    const checkouV2Data: CheckoutV2Data = yield select(getCheckoutV2Data);
    const requestBody = {
      seller_id: bill.seller.sellerIdFinancialServices,
      organization_id: bill.seller.organizationId,
      transaction_id: checkouV2Data.financialServicesId,
    };
    const { data }: Response<SyncStatusBodyRawData> = yield call(
      syncStatusTransactionRequest,
      requestBody,
    );
    yield put(syncStatusTransactionSuccess(data.status));
  } catch (error) {
    yield put(syncStatusTransactionFailure());
  }
}

export function* workerSyncStatusTransactionSuccess() {
  const paymentType: PaymentType = yield select(getPaymentType);
  const isPixQRCodeValid: boolean = yield select(getIsPixQRCodeValid);
  if (paymentType !== PaymentType.Pix || !isPixQRCodeValid) {
    return;
  }

  const statusTransaction: TransactionStatus = yield select(
    getStatusTransaction,
  );

  if (statusTransaction === TransactionStatus.Processing) {
    yield delay(10000);
    yield put(syncStatusTransaction());
    return;
  }

  if (statusTransaction === TransactionStatus.Success) {
    yield call(workerSuccessCheckout);
  }
}

export function* workerPixCheckout() {
  try {
    const bill: Bill | null = yield select(getBill);
    const paymentType: PaymentType = yield select(getPaymentType);

    if (bill == null) return;

    const requestData = {
      bill: bill.id,
      payment_type: paymentType,
    };

    const { data }: Response<CheckoutV2RawData> = yield call(
      checkoutRequestV2,
      requestData,
    );

    const normalizeCheckoutV2Data = normalizeCheckoutV2Response(data);
    yield put(checkoutV2Success(normalizeCheckoutV2Data));
    yield put(syncStatusTransaction());
  } catch (error) {
    yield put(checkoutV2Failure({ error: error.message }));
  }
}

export function* workerCreditCardCheckout({ params }: CheckoutCreditCard) {
  try {
    const bill: Bill | null = yield select(getBill);
    const { paymentForm } = params;
    if (bill == null) return;

    const requestData = normalizeCheckoutRequestBodyV2(paymentForm, bill);
    const { data }: Response<CheckoutV2RawData> = yield call(
      checkoutRequestV2,
      requestData,
    );
    const normalizeCheckoutV2Data = normalizeCheckoutV2Response(data);
    yield put(checkoutSuccess(data.external_id));
    yield put(checkoutV2Success(normalizeCheckoutV2Data));
    yield call(workerSuccessCheckout);
  } catch (error) {
    yield put(checkoutV2Failure({ error: error.message }));
  }
}

export default function* checkoutPaymentSagas() {
  yield takeLatest(CHECKOUT_PIX, workerPixCheckout);
  yield takeLatest(CHECKOUT_CREDIT_CARD, workerCreditCardCheckout);
  yield takeLatest(SYNC_STATUS_TRANSACTION_ACTION, workerSyncStatusTransaction);
  yield takeLatest(
    SYNC_STATUS_TRANSACTION_SUCCESS,
    workerSyncStatusTransactionSuccess,
  );
}
