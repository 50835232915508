import { AxiosError } from 'axios';

import { ApiErrors } from '@types';
import { DEFAULT_ERROR_CODE } from './constants';

interface FreeSamplesError {
  errors: ApiErrors[];
}

export const getErrorCode = (e: AxiosError<FreeSamplesError>): string =>
  e?.response?.data?.errors?.[0]?.code || e.message || DEFAULT_ERROR_CODE;

export function validateBrPhone(value: string) {
  let isValid = false;
  isValid = /^\([1-9]{2}\) [2-9][0-9]{3,4}-[0-9]{4}$/.test(value);

  if (value !== undefined && isValid) {
    const validators = [
      (phoneNumber: string) => !/^(\d)\1+$/.test(phoneNumber),
      (phoneNumber: string) => !/^(.)\1*$/.test(phoneNumber),
    ];

    const phoneNumber = value.replace(/\W/g, '');
    isValid = validators.every((validator) => validator(phoneNumber));
  }

  return isValid;
}

export const getNamesFromArray = (list: { name: string }[]): string =>
  list.reduce((accumulated, current) => {
    if (!accumulated) return current.name;

    return `${accumulated}, ${current.name}`;
  }, '');
