// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import { CHANGE_TAB_RECURRENCE } from 'state/subscription/constants';
import style from './ButtonGroup.scss';

const selectedStyle = (isMonthly) => (isMonthly ? style.selected : style.default);

export default function ButtonGroup({ isMonthly, toggleStatus }) {
  return (
    <div className={style.group}>
      <button type="button" className={selectedStyle(isMonthly)} onClick={() => toggleStatus(CHANGE_TAB_RECURRENCE.monthly)}>
        MENSAL
      </button>
      <button type="button" className={selectedStyle(!isMonthly)} onClick={() => toggleStatus(CHANGE_TAB_RECURRENCE.annual)}>
        ANUAL
      </button>
    </div>
  );
}

ButtonGroup.propTypes = {
  isMonthly: PropTypes.bool.isRequired,
  toggleStatus: PropTypes.func.isRequired,
};
