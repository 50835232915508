import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchUpgradeConfirm,
  fetchUpgradeNotification,
} from 'state/upgrade/actions';
import {
  getCurrentModal,
  getIsFetching,
  getPaymentInfo,
  getSelectedProduct,
  getUserInfo,
} from 'state/upgrade/selectors';
import { Button } from '@iclinic/design-system';
import { getButtonMessage } from 'features/upgrade/utils';

const ProductButton = () => {
  const dispatch = useDispatch();
  const currentModal = useSelector(getCurrentModal);
  const paymentInfo = useSelector(getPaymentInfo);
  const isFetching = useSelector(getIsFetching);
  const selectedProduct = useSelector(getSelectedProduct);
  const userInfo = useSelector(getUserInfo);

  const buttonText = getButtonMessage(userInfo, paymentInfo);

  const upgradeConfirm = () => {
    const { slug } = selectedProduct;
    if (userInfo.isAdmin) {
      dispatch(fetchUpgradeConfirm([slug]));
    } else {
      dispatch(fetchUpgradeNotification(currentModal, slug));
    }
  };

  const trackId = 'upgrade_modal_btn_signup_click';

  return (
    <Button
      isLoading={isFetching}
      size="small"
      disabled={isFetching}
      data-ga={trackId}
      data-sp={trackId}
      onClick={upgradeConfirm}
      data-testid={`upgrade-${currentModal}`}
    >
      {buttonText}
    </Button>
  );
};

export default ProductButton;
