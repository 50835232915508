import {
  MaterialUICore,
  axiomTokens,
  IconButton,
} from '@iclinic/design-system';
import Markdown from 'markdown-to-jsx';

const { styled, makeStyles } = MaterialUICore;
type Theme = MaterialUICore.Theme;

export default makeStyles(() => ({
  '@keyframes fade-in-expand': {
    from: {
      maxHeight: '0',
      opacity: '0',
    },
    to: {
      maxHeight: '55px',
      opacity: '1',
    },
  },

  '@keyframes fade-out-collapse': {
    from: {
      maxHeight: '55px',
      opacity: '1',
    },
    to: {
      maxHeight: '0',
      opacity: '0',
      padding: '0',
      border: 'none',
    },
  },

  showFeedbackSuccess: {
    animation: `$fade-in-expand 0.5s ease forwards, $fade-out-collapse 0.5s ease 3.5s forwards`,
  },
}));

export const Container = styled('div')(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  overflow: 'hidden',
}));

export const MarkdownContainer = styled('div')(() => ({
  height: '100%',
  overflowY: 'auto',
  borderBottom: `${axiomTokens.border.width.xs} solid ${axiomTokens.color.others.divider}`,
  padding: `0 ${axiomTokens.spacing[4]} ${axiomTokens.spacing[2]} 0`,

  '&::-webkit-scrollbar': {
    width: axiomTokens.spacing[2],
  },

  '&::-webkit-scrollbar-thumb': {
    background: axiomTokens.color.others.divider,
    borderRadius: axiomTokens.border.radius.lg,
  },

  '&::-webkit-scrollbar-track': {
    background: axiomTokens.color.others.skeleton,
    width: axiomTokens.spacing[2],
    borderRadius: axiomTokens.border.radius.lg,
  },
}));

export const StyledMarkdown = styled(Markdown)(() => ({
  '& strong': {
    fontSize: axiomTokens.font.size.xxs,
    letterSpacing: axiomTokens.font.letterSpacing.sm,
  },

  '& li': {
    marginLeft: axiomTokens.spacing[5],
  },

  '& ul': {
    listStyleType: 'disc',
  },

  '& h1,h2,h3,h4,h5,h6': {
    fontWeight: axiomTokens.font.weight.bold,
    letterSpacing: axiomTokens.font.letterSpacing.sm,
    color: axiomTokens.color.typeface.pure,
    margin: '0',
    marginBottom: axiomTokens.spacing[2],
  },

  '& h1,h2,h3': {
    fontSize: axiomTokens.font.size.sm,
  },

  '& h4,h5,h6': {
    fontSize: axiomTokens.font.size.xxs,
  },

  fontWeight: axiomTokens.font.weight.regular,
  fontSize: axiomTokens.font.size.xxs,
  letterSpacing: axiomTokens.font.letterSpacing.sm,
  color: axiomTokens.color.typeface.pure,
}));

export const Info = styled('div')(() => ({
  width: '100%',
  backgroundColor: axiomTokens.color.surface.feedback.info.subtle,
  padding: `${axiomTokens.spacing[3]} ${axiomTokens.spacing[4]}`,
  borderRadius: axiomTokens.border.radius.sm,
  fontSize: axiomTokens.font.size.xs,
  fontWeight: axiomTokens.font.weight.regular,
  color: axiomTokens.color.typeface.pure,
  margin: `${axiomTokens.spacing[2]} 0`,
}));

export const FeedbackContainer = styled('div')(() => ({
  borderTop: `${axiomTokens.border.width.xs} solid ${axiomTokens.color.others.divider}`,
  paddingTop: axiomTokens.spacing[2],
}));

export const FeedbackActions = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '32px 32px auto',
  gridTemplateRows: 'auto auto',
  gap: axiomTokens.spacing[2],

  '& .MuiIconButton-root.Mui-disabled': {
    opacity: '1',
    '& path': {
      fill: axiomTokens.color.icon.activated,
      fillOpacity: 'unset',
    },
  },
}));

export const FeedbackText = styled('span')(() => ({
  gridColumn: '1/4',
  fontWeight: axiomTokens.font.weight.regular,
  fontSize: axiomTokens.font.size.xxs,
  letterSpacing: axiomTokens.font.letterSpacing.sm,
}));

interface StyledIconButtonProps {
  theme: Theme;
  isVisible: boolean;
}

export const StyledIconButton = styled(IconButton)(
  ({ isVisible }: StyledIconButtonProps) => ({
    visibility: isVisible ? 'visible' : 'hidden',
    opacity: isVisible ? '1' : '0',
    transition: 'opacity 0.5s, visibility 0.5s',
  }),
);

export const FeedbackSuccess = styled('div')(() => ({
  width: '100%',
  padding: `${axiomTokens.spacing[3]} ${axiomTokens.spacing[4]}`,
  backgroundColor: axiomTokens.color.surface.feedback.success.subtle,
  borderRadius: axiomTokens.border.radius.sm,
  fontSize: axiomTokens.font.size.xs,
  fontWeight: axiomTokens.font.weight.regular,
  color: axiomTokens.color.typeface.pure,
  maxHeight: '55px',
  opacity: '1',
  overflow: 'hidden',
  textWrap: 'nowrap',
}));
