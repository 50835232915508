import React from 'react';

import images from 'shared/paths/images';
import {
  Body,
  Container,
  HeadContainer,
  Heading,
} from './KnowTheMedicalRecords.styles';

export const KnowTheMedicalRecords = (): JSX.Element => (
  <Container>
    <HeadContainer>
      <Heading variant="md">Conheça o prontuário</Heading>
      <Body variant="xs">
        Clique no paciente acima e atenda seu primeiro paciente no prontuário
        iClinic
      </Body>
    </HeadContainer>
    <img src={images.schedule.knowedThePep} alt="Médica apontando para cima" />
  </Container>
);

export default KnowTheMedicalRecords;
