import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  HighlightsInfoState,
  SearchDbMedications,
  HighlightsInfoListCardItems,
  HighlightsInfoType,
} from '../types';
import { getActionTypes } from 'shared/utils/getActionTypes';
import { SearchDbHighlightsInfoListRequest } from './services/types';

export const initialState: HighlightsInfoState = {
  isModalHighlightsInfoOpen: false,
  highlightInfoSelected: undefined,
  isSavingError: false,
  isSavingLoading: false,
  isGetHighlightError: false,
  isGetHighlightLoading: false,
  isSearchDbLoading: false,
  isSearchDbError: false,
  isSearchDbSuccess: false,
  isShouldRefreshHighlightInfo: true,
  highlightInfos: [],
  searchDb: [],
};

const storeName = '@webapp/highlightsInfo';

const highlightsInfoSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    getHighlightsInfo: (state, action: PayloadAction<string>) => ({
      ...state,
      isGetHighlightLoading: true,
      isShouldRefreshHighlightInfo: false,
      patientId: action.payload,
    }),
    getHighlightsInfoSuccess: (
      state,
      action: PayloadAction<HighlightsInfoType[]>,
    ) => ({
      ...state,
      isGetHighlightLoading: false,
      isGetHighlightError: false,
      highlightInfos: action.payload,
    }),
    getHighlightsInfoFailed: (state) => ({
      ...state,
      isShouldRefreshHighlightInfo: false,
      isGetHighlightLoading: false,
      isGetHighlightError: true,
      highlightInfos: [],
    }),
    setHighlightsInfoContent: (state, action: PayloadAction<string>) => ({
      ...state,
      highlightInfoSelected: {
        ...state.highlightInfoSelected!,
        content: action.payload,
      },
    }),
    setHighlightsInfoItems: (
      state,
      action: PayloadAction<HighlightsInfoListCardItems[]>,
    ) => ({
      ...state,
      highlightInfoSelected: {
        ...state.highlightInfoSelected!,
        items: action.payload,
        isDenied: undefined,
      },
    }),
    setHighlightsInfoDenied: (state, action: PayloadAction<boolean>) => ({
      ...state,
      highlightInfoSelected: {
        ...state.highlightInfoSelected!,
        isDenied: action.payload,
      },
    }),
    saveHighlightsInfo: (state) => ({
      ...state,
      isSavingLoading: true,
      isSavingError: false,
    }),
    saveHighlightsInfoSuccess: (state) => ({
      ...state,
      isSavingLoading: false,
      isSavingError: false,
      isShouldRefreshHighlightInfo: true,
    }),
    saveHighlightsInfoFailed: (state) => ({
      ...state,
      isSavingLoading: false,
      isSavingError: true,
    }),
    searchDbHighlightsInfo: (
      state,
      action: PayloadAction<SearchDbHighlightsInfoListRequest>,
    ) => ({
      ...state,
      isSearchDbLoading: true,
      isSearchDbError: false,
      isSearchDbSuccess: false,
      searchText: action.payload.q,
    }),
    searchDbHighlightsInfoSuccess: (
      state,
      action: PayloadAction<SearchDbMedications[]>,
    ) => ({
      ...state,
      isSearchDbLoading: false,
      isSearchDbError: false,
      isSearchDbSuccess: true,
      searchDb: action.payload,
    }),
    searchDbHighlightsInfoFailed: (state) => ({
      ...state,
      isSearchDbLoading: false,
      isSearchDbError: true,
      isSearchDbSuccess: false,
    }),
    clearSearchDb: (state) => ({
      ...state,
      searchDb: [],
    }),
    openHighlightsInfoModal: (
      state,
      action: PayloadAction<HighlightsInfoType>,
    ) => ({
      ...state,
      isShouldRefreshHighlightInfo: false,
      isModalHighlightsInfoOpen: true,
      highlightInfoSelected: action.payload,
      searchDb: [],
    }),
    closeHighlightsInfoModal: (state) => ({
      ...state,
      isModalHighlightsInfoOpen: false,
      highlightInfoSelected: {} as HighlightsInfoType,
      searchDb: [],
    }),
    addHighlightInfoSelected: (
      state,
      action: PayloadAction<HighlightsInfoType>,
    ) => ({
      ...state,
      highlightInfoSelected: action.payload,
      searchDb: [],
    }),
  },
});

export const actions = {
  ...highlightsInfoSlice.actions,
};

export const actionTypes = getActionTypes(actions, storeName);

export default highlightsInfoSlice.reducer;
