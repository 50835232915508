import React from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

import { formatNumberToDecimal } from 'shared/utils/strings';
import {
  CUSTOM_TEXT,
  PROMOTION_TEXT_VALUES,
} from 'features/billing/components/summary/constants';
import ListDetail from 'ui/new/list-detail';
import Payment from 'ui/new/payment';
import Text from 'ui/new/text';
import { CheckBox } from 'ui/new/form';
import style from './style/CheckSelectAddon.scss';
import { mdLinkToHTML } from 'shared/utils/md';

const CheckSelectProduct = ({
  name,
  checked,
  onChange,
  description,
  price,
  promotionPrice,
  disabled,
  subscription,
}) => {
  const sanitizedDescription = DOMPurify.sanitize(description, {
    USE_PROFILES: { html: false },
  });
  return (
    <div className={style.select}>
      <div className={style.select__wrapper}>
        <div className={style.select__wrapper__check}>
          <CheckBox
            id="checkProduct"
            name={name}
            value={name}
            checked={checked}
            onChange={onChange}
            selected={checked}
            disabled={disabled}
          />
          <p className={style.select__wrapper__label}>{name}</p>
        </div>
        <ListDetail.Row>
          <div className={style.select__wrapper__description}>
            <Text size="small">
              <span
                dangerouslySetInnerHTML={{
                  __html: mdLinkToHTML(sanitizedDescription, '_blank'),
                }}
              />
            </Text>
          </div>
          <div className={style.select__wrapper__price}>
            <Payment.Price
              text={CUSTOM_TEXT.total_column}
              promotion={
                promotionPrice && formatNumberToDecimal(promotionPrice)
              }
              total={price && formatNumberToDecimal(price)}
              promotionText={PROMOTION_TEXT_VALUES.promotionProduct}
            >
              +
            </Payment.Price>
            <span className={style}>{subscription ? 'anual' : 'mensal'}</span>
          </div>
        </ListDetail.Row>
      </div>
    </div>
  );
};

CheckSelectProduct.defaultProps = {
  promotionPrice: '',
  subscription: false,
  checked: false,
  disabled: false,
};

CheckSelectProduct.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  promotionPrice: PropTypes.string,
  subscription: PropTypes.bool,
};

export default CheckSelectProduct;
