// External
import React from 'react';
import { Container, Item } from '@iclinic/iclinic-ui';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import InputMask from 'react-input-mask';
// Internal
import style from './InputLabel.scss';
import FieldError from './FieldError';

export default function InputLabel({
  id,
  label,
  type,
  size,
  placeholder,
  input,
  mask,
  maskChar,
  append,
  meta: {
    touched,
    error,
  },
}) {
  const hasError = touched && error;
  const classes = classNames.bind(style);
  return (
    <div className={classes('group', { 'group-error': hasError })}>
      <label htmlFor={name}>{label}</label>
      <Container direction="row">
        <Item grow="1">
          <InputMask id={id} mask={mask} maskChar={maskChar} alwaysShowMask={false} type={type} className={style[size]} placeholder={placeholder} {...input} />
        </Item>
        {append && (
          <Item grow="1">
            {append}
          </Item>
        )}
      </Container>
      {hasError && <FieldError>{error}</FieldError>}
    </div>
  );
}

InputLabel.propTypes = {
  type: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  append: PropTypes.node,
};

InputLabel.defaultProps = {
  size: null,
  type: 'text',
  required: true,
  mask: '',
  maskChar: '',
  placeholder: '',
};
