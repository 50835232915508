import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DrawerCard, DrawerHeader, Pagination } from '@iclinic/design-system';

import { getTemplate } from 'state/records/textTemplate/selectors';
import { actions } from 'state/records/textTemplate';
import { TextTemplate } from 'state/records/textTemplate/types';
import CustomListItem from './components/CustomListItem';
import {
  List,
  Body,
  DrawerContent,
  PaginationContainer,
} from './RichTextOpenTemplateDrawer.styles';

const { closeDrawerUseTextTemplate, setCurrentPage } = actions;

const RichTextOpenTemplateDrawer = () => {
  const dispatch = useDispatch();
  const {
    isDrawerUseTemplateOpen: isOpen,
    templateList,
    uniqueId,
    pageCount,
  } = useSelector(getTemplate);

  const closeDrawer = () => dispatch(closeDrawerUseTextTemplate());

  const handlePaginationChange = (
    _e: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    dispatch(setCurrentPage(value));
  };

  return (
    <DrawerCard open={isOpen} onClose={closeDrawer}>
      <DrawerHeader title="Usar Modelo" onClose={closeDrawer} />
      {templateList?.length === 0 && (
        <Body variant="sm">Você não possui nenhum modelo salvo.</Body>
      )}
      <DrawerContent>
        <List>
          {templateList?.map((item: TextTemplate) => (
            <CustomListItem
              key={`records-template-li-${item.id}`}
              uniqueId={uniqueId}
              textTemplateId={item.id || 0}
              title={item.title}
              text={item.text}
            />
          ))}
        </List>
      </DrawerContent>
      {pageCount > 1 && (
        <PaginationContainer>
          <Pagination
            shape="rounded"
            count={pageCount}
            onChange={handlePaginationChange}
          />
        </PaginationContainer>
      )}
    </DrawerCard>
  );
};

export default RichTextOpenTemplateDrawer;
