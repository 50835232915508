// External
import React from 'react';
import Icon from '@material/react-material-icon';

// Internal
import classnames from 'classnames/bind';
import ss from './CollapseButton.scss';

const cx = classnames.bind(ss);

type CollapseButtonProps = {
  classes?: string;
  title: string;
  open: boolean;
  onClick: () => void;
  children: React.ReactNode;
};

export default function CollapseButton(
  {
    classes = '',
    onClick,
    title,
    open,
    children,
  }: CollapseButtonProps,
): JSX.Element {
  return (
    <div className={cx(ss.collapse, classes)}>
      <button
        className={ss.buttonColapse}
        type="button"
        onClick={onClick}
      >
        {title}
        <Icon
          className={
            cx(ss.icon, {
              open,
              closed: !open,
            })
          }
          icon="keyboard_arrow_down"
        />
      </button>
      <div
        className={
          cx(ss.expandable, {
            open,
            closed: !open,
          })
        }
      >
        <div className={ss.children}>
          {children}
        </div>
      </div>
    </div>
  );
}
