import { Box, MaterialIcons, tokens } from '@iclinic/design-system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader from 'react-content-loader';

import {
  createLink,
  ICLINIC_PAY_URLS,
} from 'features/onlinePayment/containers/iclinic-pay/iClinicPayIntegration';
import {
  getFinancialSellerData,
  getFinancialSellerErrorMessage,
  getOverviewFilters,
  isFetchingFinancialSeller,
} from 'features/onlinePayment/state/notifications-center/selectors';
import { fetchFinancialSellerData } from 'features/onlinePayment/state/notifications-center';
import { ErrorAlert } from 'shared/components';
import { RequestStatus } from 'shared/constants/State';
import { currencyFormatter } from 'shared/utils/formatters';
import FiltersByPeriod from './FiltersByPeriod';
import * as S from './styles';
import {
  getSSOToken,
  getSSOTokenStatus,
} from 'features/onlinePayment/state/iclinic-pay/selectors';
import Tabs from './Tabs';
import { OVERVIEW_FETCH_ERROR } from 'features/onlinePayment/state/notifications-center/errorMessages';
import OverviewItem from './OverviewItem';

const { color } = tokens;

const { AccessTime, ListAlt, MonetizationOn, LocalAtm, DateRange } =
  MaterialIcons;

const Overview = () => {
  const dispatch = useDispatch();

  const [isFetching, fetchError, financialSeller] = [
    useSelector(isFetchingFinancialSeller),
    useSelector(getFinancialSellerErrorMessage),
    useSelector(getFinancialSellerData),
  ];

  const [ssoToken, ssoTokenStatus] = [
    useSelector(getSSOToken),
    useSelector(getSSOTokenStatus),
  ];
  const filters = useSelector(getOverviewFilters);

  const [isOverviewOpened, setOverviewOpened] = useState(false);

  useEffect(() => {
    dispatch(fetchFinancialSellerData());
  }, [filters, dispatch]);

  return (
    <>
      <Tabs />
      {fetchError || (ssoTokenStatus !== RequestStatus.Pending && !ssoToken) ? (
        <Box p={3}>
          <ErrorAlert
            message={
              <span
                dangerouslySetInnerHTML={{
                  __html: fetchError || OVERVIEW_FETCH_ERROR,
                }}
              />
            }
          />
        </Box>
      ) : (
        <S.OverviewContainer>
          <S.OverviewTabHeader
            variant="text"
            color="primary"
            disableRipple
            onClick={() => setOverviewOpened(!isOverviewOpened)}
          >
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              <Box display="flex" alignItems="center">
                <S.IconWrapperLighter>
                  <AccessTime fontSize="small" />
                </S.IconWrapperLighter>
                <S.Text variant="xxs" bold>
                  Você vai receber hoje
                </S.Text>
              </Box>

              <S.HeadingPrice variant="md">
                {!isFetching ? (
                  currencyFormatter(financialSeller?.totalReceivablesValue)
                ) : (
                  <ContentLoader
                    speed={1}
                    width={175}
                    height={24}
                    backgroundColor={color.neutral[50]}
                    foregroundColor={color.neutral[25]}
                  >
                    <rect x="1" y="0" rx="0" ry="2" width={175} height={24} />
                  </ContentLoader>
                )}
              </S.HeadingPrice>
            </Box>
            <S.OverviewToggleIcon opened={isOverviewOpened} />
          </S.OverviewTabHeader>
          <S.OverviewContent opened={isOverviewOpened}>
            <Box mb={2}>
              <FiltersByPeriod />
            </Box>

            <OverviewItem
              text="Número de transações"
              value={financialSeller?.countTransactionsApproved}
              isFetching={isFetching}
              icon={<ListAlt fontSize="small" />}
            />

            <OverviewItem
              text="Ticket médio"
              tooltipText="O ticket médio é o somatório de faturas + transações de pagamento, dividido pela quantidade de transações/faturas."
              value={currencyFormatter(
                financialSeller?.averageTicketTransactionsApproved,
              )}
              isFetching={isFetching}
              icon={<LocalAtm fontSize="small" />}
            />

            <OverviewItem
              text="Faturamento"
              tooltipText="O faturamento é a soma de todos os valores obtidos com os atendimentos em um determinado período."
              value={currencyFormatter(
                financialSeller?.totalAmountTransactionsApproved,
              )}
              isFetching={isFetching}
              icon={<MonetizationOn fontSize="small" />}
            />

            <OverviewItem
              text="Valor Antecipável"
              value={currencyFormatter(financialSeller?.valueToAntecipate)}
              isFetching={isFetching}
              icon={<DateRange fontSize="small" />}
            />

            {ssoTokenStatus !== RequestStatus.Pending && ssoToken && (
              <S.AntecipateLink
                href={createLink(
                  ssoToken,
                  ICLINIC_PAY_URLS.ANTECIPACAO_RECEBIVES,
                )}
                target="_blank"
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                disabled={isFetching || !financialSeller?.valueToAntecipate}
              >
                Antecipar agora
              </S.AntecipateLink>
            )}
          </S.OverviewContent>
        </S.OverviewContainer>
      )}
    </>
  );
};

export default Overview;
