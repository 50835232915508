import { createSelector } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { IStoreState, StoreState } from 'state/rootReducer';

export const isFetchingPhysicians = ({ messaging }: StoreState) =>
  messaging.messagePackage.checkout.fetcherPhysicianStatus ===
  RequestStatus.Pending;

export const getPhysiciansAvailable = ({ messaging }: StoreState) =>
  messaging.messagePackage.checkout.physicians;

export const getPhysiciansData = createSelector(
  [isFetchingPhysicians, getPhysiciansAvailable],
  (isFetching, physicians) => ({
    isFetchingPhysicians: isFetching,
    physicians,
  }),
);

export const getSelectedPhysicians = ({ messaging }: StoreState) =>
  messaging.messagePackage.checkout.selectedPhysicians || [];

export const getSelectedPackage = ({ messaging }: StoreState) =>
  messaging.messagePackage.checkout.selectedPackage;

export const isFetchingSubscriptionSummary = ({ messaging }: StoreState) =>
  messaging.messagePackage.checkout.fetcherSubscriptionSummary ===
  RequestStatus.Pending;

export const getSubscriptionSummary = ({ messaging }: StoreState) =>
  messaging.messagePackage.checkout.subscriptionSummary;

export const getOpenSummary = ({ messaging }: StoreState) =>
  messaging.messagePackage.checkout.openSummary;

export const getUserSubscriptionId = ({ userInfo }: StoreState) =>
  userInfo.userData.subscriptionId;

export const isSubmittingSubscription = ({ messaging }: StoreState) =>
  messaging.messagePackage.checkout.submitSubscriptionStatus ===
  RequestStatus.Pending;

export const getOpenSuccess = ({ messaging }: StoreState) =>
  messaging.messagePackage.checkout.openSuccess;

export const getUpdatePermissionUrl = ({ shared }: IStoreState) =>
  shared.updatePermissionsUrl;
