export const tableColumnKeys = {
  title: 'title',
  actions: 'actions',
  status: 'status',
  sent: 'sent',
  opened: 'opened',
  clicked: 'clicked',
};

export const tableColumns = [
  { key: tableColumnKeys.title, label: 'NOME DA CAMPANHA' },
  { key: tableColumnKeys.actions, label: '' },
  { key: tableColumnKeys.status, label: 'STATUS' },
  { key: tableColumnKeys.sent, label: 'ENVIADO' },
  { key: tableColumnKeys.opened, label: 'ABERTURA' },
  { key: tableColumnKeys.clicked, label: 'CLIQUES' },
];

export const tableTitleAlign = {
  [tableColumnKeys.title]: 'left',
  [tableColumnKeys.actions]: 'left',
  [tableColumnKeys.status]: 'left',
  [tableColumnKeys.sent]: 'right',
  [tableColumnKeys.opened]: 'right',
  [tableColumnKeys.clicked]: 'right',
};

export const mobileLinesKeys = {
  status: 'status',
  sent: 'sent',
  opened: 'opened',
  clicked: 'clicked',
};

export const pageWidthMobile = 466;

export const mobileLines = [
  { key: mobileLinesKeys.status, label: 'Status' },
  { key: mobileLinesKeys.sent, label: 'Público' },
  { key: mobileLinesKeys.opened, label: 'Abertura' },
  { key: mobileLinesKeys.clicked, label: 'Cliques' },
];

export const campaignStatusForDisplay = {
  sent: 'Enviado',
  draft: 'Rascunho',
  scheduled: 'Agendado',
};

export const CampaignStatus = {
  draft: 'draft',
  scheduled: 'scheduled',
  sent: 'sent',
};

export const status = [
  { key: CampaignStatus.draft, label: 'Salvar rascunho' },
  { key: CampaignStatus.scheduled, label: 'Agendar' },
  { key: CampaignStatus.sent, label: 'Enviar agora' },
];

export const campaignFinalizeAction = {
  [CampaignStatus.draft]: 'SALVAR E ENVIAR MAIS TARDE',
  [CampaignStatus.scheduled]: 'AGENDAR E-MAIL',
  [CampaignStatus.sent]: 'ENVIAR E-MAIL AGORA',
};

export const AudienceSummary = {
  all: 'all',
  segmented: 'segmented',
};

export const AudienceFilter = {
  age: 'age',
  all: 'all',
  cid: 'cid',
  gender: 'gender',
  seenBy: 'seenBy',
  patientTag: 'patientTag',
  procedure: 'procedure',
};

export const CampaignSendingNotification = {
  scheduled: 'SCHEDULE_EMAIL_SUCCESS',
  sent: 'SEND_EMAIL_NOW_SUCCESS',
};

export const searchName = 'search-terms';
export const searchNameDropdownCID = 'search-dropdown-cid';
export const searchNameDropdownSeenBy = 'search-dropdown-seenBy';
export const searchNameDropdownPatientTag = 'search-dropdown-patientTag';
export const searchNameDropdownProcedure = 'search-dropdown-procedure';

export const tableBodyEmptyText = 'nenhum resultado encontrado';

export const selectAllId = 'All';

export const cardsName = {
  none: '',
  audience: 'audience',
  finalize: 'finalize',
  mail: 'mail',
};

export const cidLabels = {};

export const sortedAddFilters = [
  [AudienceFilter.seenBy, 'Pacientes atendidos por'],
  [AudienceFilter.patientTag, 'Pacientes marcados com tag de prontuário'],
  [AudienceFilter.procedure, 'Pacientes atendidos com determinado procedimento'],
  [AudienceFilter.gender, 'Pacientes do gênero'],
  [AudienceFilter.age, 'Faixa de idade'],
];

export const initFilterData = {
  [AudienceFilter.age]: { arguments: { value: 1, type: 'ge' } },
  [AudienceFilter.gender]: { arguments: 'm' },
};

export const informationFinalizeSchedule = ' O público alvo será atualizado no momento do envio da mensagem.';
