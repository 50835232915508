import * as Yup from 'yup';

import { required } from 'features/new-auth/validationSchema';
import {
  isCardNumberValid,
  isExpirationDateValid,
  isNameValid,
  isSecurityCodeValid,
} from 'shared/utils/creditCardValidation';
import { isValidDocument } from 'shared/utils/validation';
import { onlyNumbers } from 'shared/utils/formatters';

export const validationForm = Yup.object({
  cardOwnerName: Yup.string()
    .required(required)
    .test('cardOwnerName', 'Nome inválido', (value) =>
      isNameValid(value || ''),
    ),
  cardNumber: Yup.string()
    .required(required)
    .test('cardNumber', 'Número de cartão inválido.', (value) =>
      isCardNumberValid(value || ''),
    ),
  cardExpirationDate: Yup.string()
    .required(required)
    .test('cardExpirationDate', 'Data inválida', (value) =>
      isExpirationDateValid(value || ''),
    ),
  cardCVV: Yup.string()
    .required(required)
    .test({
      name: 'cardCVV',
      message: 'CVV inválido',
      test(value) {
        return isSecurityCodeValid(value || '', this.parent.cardNumber);
      },
    }),

  cpfCnpj: Yup.string()
    .required(required)
    .test({
      name: 'cpfCnpj',
      message: 'CPF ou CNPJ inválido',
      test(value) {
        return isValidDocument(onlyNumbers(value || ''));
      },
    }),
});
