// Internal
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// External
import style from './style.scss';
import { FOOTER, FOOTER_DIRECTION } from './constants';

const Footer = ({ children, align, direction }) => (
  <div
    className={cx(
      style.footer,
      style[`align__${align}`],
      style[`footer-direction__${direction}`],
    )}
  >
    {children}
  </div>
);

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  align: PropTypes.oneOf(Object.values(FOOTER)),
  direction: PropTypes.oneOf(Object.values(FOOTER_DIRECTION)),
};

Footer.defaultProps = {
  align: FOOTER.center,
  direction: FOOTER_DIRECTION.row,
};

export default Footer;
