import { pluralize } from 'shared/utils/strings';
import { Product } from '../services/types';
import { GetSummaryDataParams, ParsedAddon } from './types';

const BY_USER_TEXT = 'por usuário';

export const getOnlyPriceOfAddonsByRecurrence = (addons: ParsedAddon[]) =>
  addons.map((addon) => Number(addon.price));

export const sumAllAddonsPrice = (addonsValue: number[]) =>
  addonsValue.reduce(
    (accumulated, currentValue) => accumulated + currentValue,
    0,
  );

export const getAddonPriceByRecurrence = (addon: Product, isAnnual: boolean) =>
  isAnnual ? Number(addon.annual_price) : Number(addon.monthly_price);

export const getAppendTextByCount = (count: number) =>
  count > 0
    ? `para ${count} ${pluralize(count, 'usuário', 'usuários')}`
    : BY_USER_TEXT;

export const multiplyByCountIfBiggerThanZero = (
  value: number,
  count: number,
) => {
  if (count > 0) return value * count;
  return value;
};

export const parseAddonBySelectedPhysician = (
  isAnnual: boolean,
  addon: Product,
  physiciansIds?: number[],
  showSinglePrice?: boolean,
): ParsedAddon => {
  const value = getAddonPriceByRecurrence(addon, isAnnual);

  const physiciansByAddonCount = physiciansIds?.length || 0;
  const price = showSinglePrice
    ? value
    : multiplyByCountIfBiggerThanZero(value, physiciansByAddonCount);

  return {
    slug: addon.slug,
    name: addon.name,
    price: price.toFixed(2),
    textToAppend: showSinglePrice
      ? BY_USER_TEXT
      : getAppendTextByCount(physiciansByAddonCount),
  };
};

export const getPlanPriceByRecurrenceIfExists = (
  isAnnual: boolean,
  plan?: Product,
) => {
  if (!plan) return 0;

  return isAnnual ? Number(plan.annual_price) : Number(plan.monthly_price);
};

export const getPercentageInPromotionIfExists = (
  isAnnual: boolean,
  plan?: Product,
) => {
  const frequency = isAnnual
    ? plan?.promotion?.frequencies.annual
    : plan?.promotion?.frequencies.monthly;

  if (!frequency) return 0;

  return Number(frequency.percentage);
};

export const getDiscountValue = (
  value: number,
  discountPercentage?: number,
) => {
  if (!discountPercentage) return undefined;

  return (discountPercentage * value) / 100;
};

export const getTotalValue = (
  planValue: number,
  sumOfAddonsPrice: number,
  discountValue?: number,
) => {
  const subtotal = planValue + sumOfAddonsPrice;

  if (discountValue) {
    return subtotal - discountValue;
  }

  return subtotal;
};

export const getSummaryData = ({
  isAnnual,
  plan,
  addons,
  physicians,
  physicianByAddon,
  showSinglePrice = false,
}: GetSummaryDataParams) => {
  const physiciansCount = physicians?.length || 0;

  const planPrice = getPlanPriceByRecurrenceIfExists(isAnnual, plan);
  const planPriceFinal = showSinglePrice
    ? planPrice
    : multiplyByCountIfBiggerThanZero(planPrice, physiciansCount);

  const percentageValue = getPercentageInPromotionIfExists(isAnnual, plan);
  const discountValue = getDiscountValue(planPriceFinal, percentageValue);

  const parcedAddons = addons.map((addon) => {
    const physiciansInAddon = physicianByAddon && physicianByAddon[addon.slug];
    return parseAddonBySelectedPhysician(
      isAnnual,
      addon,
      physiciansInAddon,
      showSinglePrice,
    );
  });

  const addonsPrice = getOnlyPriceOfAddonsByRecurrence(parcedAddons);
  const sumOfAddonsPrice = sumAllAddonsPrice(addonsPrice);

  const totalValue = getTotalValue(
    planPriceFinal,
    sumOfAddonsPrice,
    discountValue,
  );

  return {
    percentageValue,
    planValue: planPriceFinal.toFixed(2),
    planTextToAppend: showSinglePrice
      ? BY_USER_TEXT
      : getAppendTextByCount(physiciansCount),
    discountValue: discountValue && discountValue.toFixed(2),
    totalValue: totalValue.toFixed(2),
    addons: parcedAddons,
  };
};
