import InlineGroup from './InlineGroup';
import Column from './Column';
import Title from './Title';
import Row from './Row';

export default {
  Group: InlineGroup,
  Column,
  Title,
  Row,
};
