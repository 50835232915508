import { Heading, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = tokens;

export const Section = styled('section')(({ theme }) => ({
  padding: `${spacing.md} ${spacing.sm}`,

  [theme.breakpoints.up('sm')]: {
    padding: `${spacing.lg} ${spacing.md}`,
  },
}));

export const SectionContent = styled('div')({
  margin: '0 auto',
  maxWidth: 848,
});

export const SectionTitle = styled(Heading)(({ theme }) => ({
  marginBottom: spacing.md,

  [theme.breakpoints.up('sm')]: {
    marginBottom: spacing.xxl,
  },
}));
