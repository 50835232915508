import React from 'react';
import { useSelector } from 'react-redux';

import { MaterialUICore, Grid, Typography } from '@iclinic/design-system';
import { phoneFormatter, cpfFormatter } from 'shared/utils/formatters';
import useStyles from './ReadOnlyCardProfile.styles';
import { getSellerConfigSelector } from 'features/onlinePayment/state/config/register/selectors';

const { Avatar } = MaterialUICore;

export default function ReadOnlyCardProfilePF(): JSX.Element | null {
  const classes = useStyles();
  const { seller, physician } = useSelector(getSellerConfigSelector);

  return (
    <Grid direction="column" spacing={3} container>
      <Grid spacing={3} item container>
        <Grid item>
          <Avatar
            classes={{
              root: classes.avatar,
              fallback: classes.fallback,
            }}
            src={physician?.profile?.thumb}
          />
        </Grid>
        <Grid item xs={12} sm="auto">
          <Typography variant="body2" className={classes.hint}>
            Nome
          </Typography>
          <Typography variant="body1">{seller?.name}</Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="body2" className={classes.hint}>
          Email
        </Typography>
        <Typography variant="body1">{seller?.email}</Typography>
      </Grid>
      <Grid spacing={3} item container>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" className={classes.hint}>
            Telefone
          </Typography>
          <Typography variant="body1">
            {phoneFormatter(seller?.phone || '')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" className={classes.hint}>
            CPF
          </Typography>
          <Typography variant="body1">
            {cpfFormatter(seller?.cpf || '')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
