// External
import { connect } from 'react-redux';

// Internal
import { searchNameDropdownPatientTag } from 'state/campaign/constants';
import * as selectors from 'state/campaign/selectors';
import FilterTags from '../components/audiences/FilterTags';

const title = 'Pacientes marcados com tag de prontuário';

const mapStateToProps = (state) => {
  const patientTags = selectors.getAllPatientTags(state).map;
  const renderItemLabel = (patientTag) => (patientTags[patientTag] || `desconhecido (${patientTag})`);
  return {
    availableItems: selectors.getAvailableFilterPatientTag(state),
    title,
    renderItemLabel,
    searchName: searchNameDropdownPatientTag,
  };
};

export default connect(mapStateToProps)(FilterTags);
