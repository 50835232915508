import {
  MaterialUICore,
  tokens,
  Box,
  Dialog as DialogDS,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing, border, shadow } = tokens;

export const Dialog = styled(DialogDS)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const ActionsWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  margin: `${spacing.nano} 0 0`,
}));

export const Paper = styled(Box)(() => ({
  backgroundColor: color.neutral[50],
  borderRadius: border.radius.sm,
  boxShadow: shadow.level[5],
  padding: spacing.sm,
  width: '300px',
}));
