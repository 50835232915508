import {
  MaterialUICore,
  axiomTokens,
  Heading as DSHeading,
  Body as DSBody,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const MessageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const Heading = styled(DSHeading)({
  color: axiomTokens.color.brand.typeface.strong,
  marginBottom: axiomTokens.spacing[1],
});

export const Body = styled(DSBody)({
  color: axiomTokens.color.brand.typeface.pure,
});

export const ImageContainer = styled('img')({
  height: '84px',
  width: '264px',
  flexShrink: 0,
  overflow: 'visible',
  objectFit: 'cover',
});

export const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  background: axiomTokens.color.brand.background[1],
  borderRadius: axiomTokens.border.radius.sm,
  padding: `${axiomTokens.spacing[2]} ${axiomTokens.spacing[5]}`,
  margin: `${axiomTokens.spacing[3]} 0px`,
  overflow: 'hidden',
});
