// External
import React from 'react';
import { Field } from 'redux-form';

// Internal
import {
  emailFlowInformation,
} from 'state/marketing/emailFlow/emailFlowManagement/constants';
import {
  EmailFlowTarget,
} from 'state/marketing/emailFlow/emailFlowManagement/emailFlowTypes';
import { Professional } from 'state/marketing/emailFlow/professional/types';

import style from './DisplayPhysicians.scss';

type DisplayPhysiciansProps = {
  professionals?: Professional[],
  onChange: (e: EmailFlowTarget) => void;
};

const DisplayPhysicians = ({
  professionals,
  onChange,
}: DisplayPhysiciansProps): JSX.Element => (
  <div className={style.containerPhysicians}>
    <span className={style.containerPhysicians__title}>
      {emailFlowInformation.physicians}
    </span>
    <div className={style.containerPhysicians__dropdown}>
      <Field
        type="text"
        name="physician_id"
        label="physician_id"
        component="select"
        className={style.containerPhysicians__filterPhysician}
        onChange={onChange}
      >
        {professionals && professionals.map((item: Professional) => (
          <option
            value={item.physician_id}
            key={item.physician_id}
          >
            {item.name}
          </option>
        ))}
      </Field>
    </div>
  </div>
);

export default DisplayPhysicians;
