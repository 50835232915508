import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import {
  Physician,
  SubscriptionSummary,
  PackagesSlug,
} from '../../../services/types';

export interface CheckoutState {
  fetcherPhysicianStatus: RequestStatus;
  physicians: Physician[];
  selectedPhysicians?: Physician[];
  selectedPackage?: PackagesSlug;
  fetcherSubscriptionSummary?: RequestStatus;
  subscriptionSummary?: SubscriptionSummary;
  openSummary: boolean;
  submitSubscriptionStatus?: RequestStatus;
  openSuccess: boolean;
}

export const initialState: CheckoutState = {
  fetcherPhysicianStatus: RequestStatus.Pending,
  physicians: [],
  openSummary: false,
  openSuccess: false,
};

export const nameStore = '@webapp/messaging/messagePackage/checkout';

export const fetchPhysiciansAvailable = createAction(
  `${nameStore}/fetchPhysiciansAvailable`,
);

export const fetchSubscriptionSummary = createAction(
  `${nameStore}/fetchSubscriptionSummary`,
);

export const submitSubscription = createAction(
  `${nameStore}/submitSubscription`,
);

const CheckoutSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchPhysiciansAvailableSuccess: (
      state,
      action: PayloadAction<Physician[]>,
    ) => ({
      ...state,
      fetcherPhysicianStatus: RequestStatus.Success,
      physicians: action.payload,
    }),
    fetchPhysiciansAvailableFailure: (state) => ({
      ...state,
      fetcherPhysicianStatus: RequestStatus.Error,
    }),
    setSelectedPhysicians: (state, action: PayloadAction<Physician[]>) => ({
      ...state,
      selectedPhysicians: action.payload,
    }),
    setSelectedPackage: (state, action: PayloadAction<PackagesSlug>) => ({
      ...state,
      selectedPackage: action.payload,
    }),
    fetchSubscriptionSummaryStart: (state) => ({
      ...state,
      fetcherSubscriptionSummary: RequestStatus.Pending,
    }),
    fetchSubscriptionSummarySuccess: (
      state,
      action: PayloadAction<SubscriptionSummary>,
    ) => ({
      ...state,
      fetcherSubscriptionSummary: RequestStatus.Success,
      subscriptionSummary: action.payload,
    }),
    fetchSubscriptionSummaryFailure: (state) => ({
      ...state,
      fetcherSubscriptionSummary: RequestStatus.Error,
    }),
    toggleOpenSummary: (state) => ({
      ...state,
      openSummary: !state.openSummary,
    }),
    toggleOpenSuccess: (state) => ({
      ...state,
      openSuccess: !state.openSuccess,
    }),
    submitSubscriptionStart: (state) => ({
      ...state,
      submitSubscriptionStatus: RequestStatus.Pending,
    }),
    submitSubscriptionSuccess: (state) => ({
      ...state,
      submitSubscriptionStatus: RequestStatus.Success,
    }),
    submitSubscriptionFailure: (state) => ({
      ...state,
      submitSubscriptionStatus: RequestStatus.Error,
    }),
  },
});

export const {
  fetchPhysiciansAvailableFailure,
  fetchPhysiciansAvailableSuccess,
  setSelectedPhysicians,
  setSelectedPackage,
  fetchSubscriptionSummaryStart,
  fetchSubscriptionSummarySuccess,
  fetchSubscriptionSummaryFailure,
  toggleOpenSummary,
  toggleOpenSuccess,
  submitSubscriptionStart,
  submitSubscriptionSuccess,
  submitSubscriptionFailure,
} = CheckoutSlice.actions;

export default CheckoutSlice.reducer;
