import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

import { Box, TextField, Button, MaterialUICore } from '@iclinic/design-system';
import { getEmailIntegration } from 'features/onlinePayment/state/checkout/success/selectors';
import { sendEmail } from 'features/onlinePayment/state/checkout/success/actions';
import useStyles from './EmailForm.styles';
import validationSchema from './validationSchema';

const { CircularProgress } = MaterialUICore;

type TypeForm = { email: string };

const initialValues = { email: '' };

export default function EmailForm() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const emailIntegration = useSelector(getEmailIntegration);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(form: TypeForm) => {
        dispatch(sendEmail({ receipt_email: form.email }));
      }}
    >
      {({ values, errors, handleChange, touched }) => (
        <Form>
          <Box display="flex" mt={1}>
            <Box flexGrow={1} mr={1}>
              <TextField
                id="receipt-email"
                type="text"
                label="E-mail"
                name="email"
                InputProps={{ className: classes.email }}
                value={values.email}
                onChange={handleChange}
                error={!!(errors.email && touched.email)}
                helperText={touched.email && errors.email ? errors.email : null}
                fullWidth
              />
            </Box>
            <Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={emailIntegration.isLoading}
              >
                {emailIntegration.isLoading ? (
                  <CircularProgress size={14} />
                ) : (
                  'Enviar'
                )}
              </Button>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
