import { Heading, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing } = tokens;

export const Section = styled('section')(({ theme }) => ({
  background: `linear-gradient(270deg, ${color.feedback.info.main} 0%, ${color.primary.main} 100%)`,
  padding: `${spacing.lg} ${spacing.sm}`,

  [theme.breakpoints.up('sm')]: {
    padding: `${spacing.xl} ${spacing.md}`,
  },
}));

export const Text = styled(Heading)({
  maxWidth: 720,
  margin: '0 auto',
  color: color.neutral[0],

  '& > span': {
    color: color.feedback.warning.main,
  },
});
