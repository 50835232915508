import { Reducer } from 'redux';

import { ActionType } from '@types';
import * as actions from './actions';
import {
  SEND_EMAIL_ACTION,
  SEND_EMAIL_FAILURE,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_RESET,
} from './constants';

export type EmailIntegration = {
  isLoading: boolean;
  done?: boolean;
  error?: string;
};

const emptyState: EmailIntegration = { isLoading: false };
const loadingState: EmailIntegration = { isLoading: true };

export type SuccessPaymentState = {
  emailIntegration: EmailIntegration;
};

export const initialState: SuccessPaymentState = {
  emailIntegration: emptyState,
};

const successPaymentReducer: Reducer<SuccessPaymentState> = (
  state = initialState,
  action: ActionType<typeof actions>,
): SuccessPaymentState => {
  switch (action.type) {
    case SEND_EMAIL_ACTION:
      return {
        ...state,
        emailIntegration: loadingState,
      };

    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        emailIntegration: {
          ...emptyState,
          done: true,
        },
      };

    case SEND_EMAIL_FAILURE:
      return {
        ...state,
        emailIntegration: {
          ...emptyState,
          error: action.params.error,
        },
      };

    case SEND_EMAIL_RESET:
      return initialState;

    default:
      return state;
  }
};

export default successPaymentReducer;
