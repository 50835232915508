import { MaterialUICore, tokens } from '@iclinic/design-system';
import { DEFAULT_DRAWER_CONSTRAINTS } from 'features/video-conference/constants';

const { makeStyles } = MaterialUICore;
const { spacing, border, shadow } = tokens;

export default makeStyles(() => ({
  drawerPaper: {
    width: DEFAULT_DRAWER_CONSTRAINTS.width,
    height: 'calc(100% - 120px)',
    marginTop: spacing.md,
    marginRight: spacing.md,
    borderRadius: border.radius.md,
    boxShadow: shadow.level[1],
  },
  bottomDrawer: {
    '& .MuiPaper-root': {
      height: '70%',
      maxWidth: '424px',
      margin: '0 auto',
      borderRadius: 0,
      borderTopLeftRadius: border.radius.md,
      borderTopRightRadius: border.radius.md,
    },
  },
  alert: {
    height: '100%',
  },
}));
