// External
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Internal
import { getCardImageFlag, getBoletoImage } from 'shared/utils/creditCard';
import { capitalizeFirstLetter } from 'shared/utils/strings';
import Text from 'ui/new/text';
// Style
import style from './Payment.scss';

export default function Payment({ title, payment, moreInfoUrl }) {
  const { paymentSlip } = payment;
  const src = paymentSlip
    ? getBoletoImage()
    : getCardImageFlag(payment.card.flag);
  const sizeImage = paymentSlip ? 'boleto' : 'card';
  const paymentMethodImage = (
    <img
      className={cx(style.footer, style[`image__${sizeImage}`])}
      alt="Forma de pagamento"
      src={src}
    />
  );
  const paymentMethod = paymentSlip
    ? 'Boleto'
    : `${capitalizeFirstLetter(payment.card.flag)} ****${payment.card.number}`;
  return (
    <div className={style.payment}>
      <p className={style.payment__title}>{title}</p>
      <div className={style.payment__detail}>
        {paymentMethodImage}
        <Text>{paymentMethod}</Text>
      </div>
      <p className={style.payment__info}>
        * Valor proporcional poderá ser aplicado
        <a target="_blank" rel="noreferrer noopener" href={moreInfoUrl}>
          {' '}
          saiba mais
        </a>
      </p>
    </div>
  );
}

Payment.defaultProps = {
  moreInfoUrl: '',
};

Payment.propTypes = {
  title: PropTypes.string.isRequired,
  moreInfoUrl: PropTypes.string,
  payment: PropTypes.shape({
    paymentSlip: PropTypes.bool.isRequired,
    card: PropTypes.shape({
      flag: PropTypes.string,
      number: PropTypes.string,
    }),
  }).isRequired,
};
