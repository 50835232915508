import React, { useState } from 'react';
import { Form as FormikForm, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import {
  IconButton,
  Heading,
  MaterialIcons,
  Alert,
  Button,
  MaterialUICore,
} from '@iclinic/design-system';
import { RequestStatus } from 'shared/constants/State';
import history from 'routes/history';
import {
  cleanPersonVUCStatus,
  triggerSubmitRequestSamples,
} from '../../state/request-samples';
import { trackRequestSamplesFormStep } from '../../trackRequestSamplesFormUtils';
import {
  getAddress,
  getBox,
  getStatusPersonVUC,
  getUserInfo,
} from '../../state/request-samples/selectors';
import CardProducts from './CardProducts';
import SectionAddress from './SectionAddress';
import SectionPhysician from './SectionPhisician';
import SectionTermsAndSubmit from './SectionTermsAndSubmit';
import { factoryRequestSamplesInitialData } from './form.normalizer';
import { validationRequestSamples } from './validationSchema';
import { RequestSamplesFormData } from './types';
import * as S from './Form.styles';
import ActionButtons from './ActionButtons';
import SnackbarZipCodeNotAvailable from './SnackbarZipCodeNotAvailable';
import DialogEnterOnWaitingQueue from './DialogEnterOnWaitingQueue';

const { ArrowBack, ReportProblemOutlined } = MaterialIcons;
const { Collapse } = MaterialUICore;

type FormProps = {
  boxId?: string;
};

export default function Form({ boxId }: FormProps): JSX.Element {
  const userInfo = useSelector(getUserInfo);
  const boxData = useSelector(getBox);
  const addressData = useSelector(getAddress);
  const statusPersonVUC = useSelector(getStatusPersonVUC);
  const [isOpen, setIsOpen] = useState(!!addressData);

  const dispatch = useDispatch();

  const onSubmitForm = (requestSamplesForm: RequestSamplesFormData) => {
    trackRequestSamplesFormStep({
      userInfo,
      stepName: 'submit',
      stepNum: 16,
    });
    dispatch(
      triggerSubmitRequestSamples({
        ...requestSamplesForm,
        box: Number(boxId),
      }),
    );
  };

  const isOpenConfirmData = statusPersonVUC === RequestStatus.Success;

  return (
    <>
      <Formik
        initialValues={factoryRequestSamplesInitialData()}
        validationSchema={validationRequestSamples}
        onSubmit={onSubmitForm}
      >
        <FormikForm>
          <S.Root>
            <S.Header>
              <IconButton
                label="Voltar"
                tooltip="bottom"
                size="lg"
                onClick={() => history.push('/amostras-gratis')}
              >
                <ArrowBack />
              </IconButton>

              <S.TitleWrapper>
                <Heading variant="lg">Solicitação de amostras grátis</Heading>

                <S.InfoText variant="xs">
                  Os dados solicitados são necessários para emissão de nota
                  fiscal e envio das amostras
                </S.InfoText>
              </S.TitleWrapper>
            </S.Header>

            {boxData && (
              <CardProducts
                pharmaName={boxData.pharmaceutical.name}
                boxName={boxData.name}
                medicaments={boxData.medicaments}
              />
            )}

            <S.FormWrapper>
              <S.RequirementsText variant="xxs">
                * Preenchimento obrigátorio
              </S.RequirementsText>

              <Collapse in={isOpen}>
                <Alert
                  severity="warning"
                  icon={<ReportProblemOutlined />}
                  message="
                  Por favor, confira suas informações, elas foram
                  preenchidas com os dados da última visita a esta página."
                  action={
                    <Button
                      variant="text"
                      size="large"
                      color="primary"
                      onClick={() => setIsOpen(false)}
                    >
                      Fechar
                    </Button>
                  }
                />
              </Collapse>

              <Collapse in={isOpenConfirmData}>
                <Alert
                  severity="warning"
                  icon={<ReportProblemOutlined />}
                  message="Por favor verifique se todos os dados estão
                  corretos, alguns dados podem ter sido preenchidos
                  automaticamente."
                  action={
                    <Button
                      variant="text"
                      size="large"
                      color="primary"
                      onClick={() => dispatch(cleanPersonVUCStatus())}
                    >
                      Fechar
                    </Button>
                  }
                />
              </Collapse>

              <SectionAddress boxId={boxId} />
              <SectionPhysician />
            </S.FormWrapper>

            <SectionTermsAndSubmit
              termDataProtectionUrl={boxData?.url_term_data_protection || ''}
              termSampleUrl={boxData?.url_term_sample || ''}
            />

            <ActionButtons />
          </S.Root>
        </FormikForm>
      </Formik>

      <SnackbarZipCodeNotAvailable />

      <DialogEnterOnWaitingQueue />
    </>
  );
}
