import React, { useEffect } from 'react';
import { render, unmount, EngineConfig } from '@afyadigital/rx-engine';
import { useSelector, useDispatch } from 'react-redux';
import { Box, MaterialUICore } from '@iclinic/design-system';

import { RequestStatus } from 'shared/constants/State';
import { getToken } from '../state/index';
import useStyles from './AfyaPrescriptionContainer.styles';
import { getFullAddress } from '../utils';
import { getCurrentEventAndPatientId } from 'state/records/selectors';
import { patientSelector, prescriptionSelector } from '../state/selectors';
import PrescriptionError from '../components/PrescriptionError';

const { Backdrop, CircularProgress } = MaterialUICore;

const TARGET_ID = 'rx-engine-container';

type Patient = {
  id: number;
  name: string;
  civil_name: string | null;
  cpf: string;
  mobile_phone: string | null;
  email: string | null;
};

export default function AfyaPrescriptionContainer(): JSX.Element {
  const patient = useSelector(patientSelector);

  const { token, status } = useSelector(prescriptionSelector);
  const { eventId } = useSelector(getCurrentEventAndPatientId);
  const classes = useStyles();

  const dispatch = useDispatch();

  const onGetToken = () => dispatch(getToken());

  useEffect(() => {
    dispatch(getToken());
  }, [dispatch]);

  useEffect(() => {
    if ((patient as Patient).id && token) {
      const { mobile_phone, email, id, name, civil_name, cpf } =
        patient as Patient;

      const config: EngineConfig = {
        target: `#${TARGET_ID}`,
        auth: { token },
        initialValue: {
          appointment: {
            id: eventId.toString(),
          },
          patient: {
            id: id.toString(),
            name,
            ...(civil_name && { civilName: civil_name }),
            ...(mobile_phone && { phone: mobile_phone }),
            ...(email && { email }),
            address: getFullAddress(patient),
            cpf,
          },
        },
      };

      render(
        config,
        process.env.ICLINIC_RX_DIGITAL_PRESCRIPTION_ENV || 'production',
      );
    }

    return () => unmount();
  }, [patient, token, eventId]);

  if (status === RequestStatus.Error) {
    return <PrescriptionError onRetry={onGetToken} />;
  }

  return (
    <Box height={1000}>
      {status === RequestStatus.Pending && (
        <Backdrop
          className={classes.backdrop}
          open
          data-testid="prescription-loading"
        >
          <CircularProgress className={classes.spinner} />
        </Backdrop>
      )}

      {token && (
        <Box
          height={1000}
          data-testid={TARGET_ID}
          id={TARGET_ID}
        >{`<!-- loading container -->`}</Box>
      )}
    </Box>
  );
}
