import {
  CheckoutRequestBodyV2,
  CheckoutV2RawData,
} from 'features/onlinePayment/services/checkout/types';
import {
  CheckoutV2Data,
  PaymentForm,
} from 'features/onlinePayment/state/checkout/payment/types';
import { Bill } from '../infos/types';

export const normalizeCheckoutRequestBodyV2 = (
  paymentForm: PaymentForm,
  bill: Bill,
): CheckoutRequestBodyV2 => {
  const { id: billId } = bill;
  const [month, year] = paymentForm.expiry.split('/');

  return {
    bill: billId,
    installments: Number(paymentForm.installments),
    card_number: paymentForm.cardNumber.replace(/\s/g, ''),
    card_expiration_month: Number(month),
    card_expiration_year: Number(year),
    card_security_code: paymentForm.cvv,
    card_holder_name: paymentForm.name,
  };
};

export function normalizeCheckoutV2Response(
  checkoutV2Data: CheckoutV2RawData,
): CheckoutV2Data {
  const {
    bill,
    installments,
    card_holder_name,
    card_number,
    card_expiration_month,
    card_expiration_year,
    card_security_code,
    payment_type,
    external_id,
    financial_services_id,
    attempt_id,
    pix_qr_code_emv,
    pix_expiration_date,
  } = checkoutV2Data;

  return {
    bill,
    installments,
    cardolderName: card_holder_name,
    cardNumber: card_number,
    cardExpirationMonth: card_expiration_month,
    cardExpirationYear: card_expiration_year,
    cardSecurityCode: card_security_code,
    paymentType: payment_type,
    externalId: external_id,
    financialServicesId: financial_services_id,
    attemptId: attempt_id,
    pixQrCodeEmv: pix_qr_code_emv,
    pixExpirationDate: pix_expiration_date,
  };
}
