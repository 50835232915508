import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  iconProfileImage: {
    fontSize: '72px',
    color: theme.palette.grey[100],
  },
}));
