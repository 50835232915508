import { RequestSamplesFormData } from 'features/free-samples/containers/RequestSamplesForm/types';
import { AddressData } from 'features/free-samples/services/types';
import { onlyNumbers } from 'shared/utils/formatters';

export function normalizeRequestSamples(
  formData: RequestSamplesFormData,
): AddressData {
  const phone = onlyNumbers(formData.phoneNumber);
  const phoneArea = phone.substr(0, 2);
  const phoneNumber = phone.substr(2);

  return {
    full_name: formData.fullName,
    crm: formData.crm,
    uf_crm: formData.ufCrm,
    address: formData.address,
    number: formData.number,
    neighborhood: formData.neighborhood,
    zip_code: onlyNumbers(formData.zipCode),
    complement: formData.complement,
    city: formData.city,
    state: formData.state,
    phone_area: Number(phoneArea),
    phone_number: Number(phoneNumber),
    cpf: onlyNumbers(formData.cpf),
    is_clinic_address: formData.isClinicAddress,
    accept_terms: formData.acceptTerms,
    accept_term_data_protection: formData.acceptTermDataProtection,
    box: formData.box,
  };
}
