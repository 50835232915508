import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { DashboardPage, SendSurveyPage } from '../pages';

const SurveyRoutes = () => (
  <Switch>
    <Route
      exact
      path="/pesquisa-de-satisfacao/envio-de-pesquisa"
      component={SendSurveyPage}
    />
    <Route exact path="/pesquisa-de-satisfacao" component={DashboardPage} />
    <Redirect to="/404" />
  </Switch>
);

export default SurveyRoutes;
