import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import MyIclinicPay from './MyIclinicPay';

export default function IclinicPay({
  match,
}: RouteComponentProps): JSX.Element {
  return (
    <Switch>
      <Route
        exact
        path={`${match.path}/meu-iclinic-pay`}
        component={MyIclinicPay}
      />
    </Switch>
  );
}
