import { combineReducers } from 'redux';

import telemedicineReport from './telemedicineReport/reducer';
import { TelemedicineReportedState } from './telemedicineReport/types';

export type ReportsState = {
  telemedicineReport: TelemedicineReportedState;
};

const reportsReducer = combineReducers<ReportsState>({
  telemedicineReport,
});

export default reportsReducer;
