// External
import * as React from 'react';

// Internal
import style from './style/index.scss';
import { STATUS } from './constants';
import StatusIcon from './StatusIcon';

type HeaderProps = {
  children: React.ReactNode,
  onClick?: () => void,
  status?: STATUS
}

export default function Header({ children, onClick, status = STATUS.Regular }: HeaderProps): JSX.Element{
  return (
    <div className={style.header__container}>
      <h3 className={style.header__heading}>
        <button className={style.header__button} onClick={onClick}>
          {children}
          <span className={style.header__statusIcon}>
            <StatusIcon status={status} />
          </span>
        </button>
      </h3>
    </div>
  )
}
