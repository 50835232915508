import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Body,
  Button,
  Heading,
  MaterialIcons,
  SectionTitle,
} from '@iclinic/design-system';
import history from 'routes/history';
import { image } from 'shared/utils/urlGetter';
import {
  getSelectedBox,
  isOffCanvasOpen,
} from '../../state/sample-boxes/selectors';
import { unselectSampleBox } from '../../state/sample-boxes';
import MedicamentsDetail from './MedicamentsDetail';
import * as S from './OffCanvasBoxDetails.styles';

const { ArrowBack, FormatListBulletedIcon } = MaterialIcons;

const OffCanvasBoxDetails = () => {
  const dispatch = useDispatch();

  const isOpen = useSelector(isOffCanvasOpen);
  const sampleBox = useSelector(getSelectedBox);

  const handleCloseOffCanvas = () => {
    dispatch(unselectSampleBox());
  };

  const handleRequestClick = () => {
    const boxId = sampleBox?.id;

    dispatch(unselectSampleBox());

    if (boxId) history.push(`/amostras-gratis/solicitar/${boxId}`);
  };

  return (
    <S.Drawer open={isOpen} anchor="right">
      <S.BackIconButton onClick={handleCloseOffCanvas}>
        <ArrowBack />
      </S.BackIconButton>

      <S.Content>
        <S.Header>
          <img
            src={image('freeSamples.boxDetail')}
            alt="Ilustração da caixa de medicamentos"
          />
          <div>
            <Heading variant="lg" gutterBottom>
              Conteúdo da box
            </Heading>
            <Body variant="sm">O que você receberá da farmacêutica</Body>
          </div>
        </S.Header>

        <S.Divider />

        <SectionTitle
          mb={3}
          icon={<FormatListBulletedIcon />}
          title={sampleBox?.name}
        />

        <MedicamentsDetail medicaments={sampleBox?.medicaments} />
      </S.Content>

      <S.FoorterWrapper>
        <Button
          startIcon={<ArrowBack />}
          color="transparent"
          onClick={handleCloseOffCanvas}
        >
          Voltar
        </Button>

        {sampleBox?.can_request && (
          <Button color="secondary" onClick={handleRequestClick}>
            Solicitar
          </Button>
        )}
      </S.FoorterWrapper>
    </S.Drawer>
  );
};

export default OffCanvasBoxDetails;
