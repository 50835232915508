// External
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

// Internal
import style from './List.scss';

const classes = classnames.bind(style);

export default function List({ children, onClick, isSelected, name }) {
  return (
    <div
      className={classes('list', {
        selected: isSelected,
      })}
      onClick={onClick}
      onKeyUp={onClick}
      role="button"
      aria-label={name}
      tabIndex={0}
    >
      {children}
    </div>
  );
}

List.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
  name: PropTypes.string,
};

List.defaultProps = {
  onClick: () => {},
  isSelected: false,
  name: '',
};
