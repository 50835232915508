import iClinicApi from 'services/iclinic/iClinicApi';
import {
  CanRequestFreeSamplesSuccess,
  RequestSamplesResponse,
  AddressData,
  SampleBox,
  ValidateAvailabilityByZipCodeParams,
  GetPersonVUCParams,
} from '../types';

export const validateCanRequestFreeSamples = (boxId?: number) =>
  iClinicApi.get<CanRequestFreeSamplesSuccess>(
    `/free-samples/${boxId}/can-request-sample`,
  );

export const submitRequestFreeSamples = (data: AddressData) =>
  iClinicApi.post<RequestSamplesResponse>('/free-samples/add/', data);

export const getSampleBox = (boxId: number) =>
  iClinicApi.get<SampleBox>(`/free-samples/box/?id=${boxId}`);

export const validateAvailabilityByZipCode = ({
  zipCode,
  boxId,
}: ValidateAvailabilityByZipCodeParams) =>
  iClinicApi.get(
    `/free-samples/zip-code-valid-sample/?zip_code=${zipCode}&box=${boxId}`,
  );

export const getAddToRequestWaitingQueue = (requestId: number) =>
  iClinicApi.get(`/free-samples/${requestId}/add-request-waiting/`);

export const getPersonVUCData = (params: GetPersonVUCParams = {}) => {
  const queryParams = Object.entries(params).map(
    ([param, value]) => `${param}=${value}`,
  );

  return iClinicApi.get(`/free-samples/get-person/?${queryParams.join('&')}`);
};
