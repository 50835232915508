import React, { useCallback, useRef } from 'react';
import { MaterialIcons } from '@iclinic/design-system';

import useLocalVideoToggle from 'features/video-conference/hooks/useLocalVideoToggle';
import ToggleButton from 'features/video-conference/components/TeamComponents/ToggleButton';
import { buttonsTitle, CAMERA } from 'features/video-conference/constants';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useVerifyDevicePermission } from 'features/video-conference/hooks/useVerifyDevicePermission';

const { VideocamOutlined, VideocamOffOutlined } = MaterialIcons;

interface Props {
  withLabel?: boolean;
}

export default function CameraButton({ withLabel = false }: Props) {
  const { isPhysician, conferenceId } = useConference();
  const { localParticipant, remoteParticipant } = useParticipantContext();
  const { isVideoEnabled, toggleVideoEnabled } = useLocalVideoToggle();
  const { hasPermission } = useVerifyDevicePermission({
    deviceType: 'camera',
  });

  const lastClickTimeRef = useRef(0);
  const title = isVideoEnabled
    ? buttonsTitle.VIDEO_DISABLED
    : buttonsTitle.VIDEO_ENABLED;

  const dispatchTrackEvent = useCallback(() => {
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'telemedicine_camera',
      buttonLocation: withLabel ? 'toolbar' : 'wait_room',
      action: isVideoEnabled ? 'disabled' : 'enabled',
    });
  }, [
    isPhysician,
    conferenceId,
    remoteParticipant,
    isVideoEnabled,
    localParticipant,
    withLabel,
  ]);

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
      dispatchTrackEvent();
    }
  }, [toggleVideoEnabled, dispatchTrackEvent]);

  return (
    <ToggleButton
      onClick={toggleVideo}
      switchedOn={isVideoEnabled && hasPermission}
      variant="error"
      updateOn={false}
      label={withLabel ? CAMERA : ''}
      title={title}
      icon={
        isVideoEnabled && hasPermission ? (
          <VideocamOutlined />
        ) : (
          <VideocamOffOutlined />
        )
      }
      dataGa="telemedicine_camera"
      dataSp="telemedicine_camera"
    />
  );
}
