// Internal
import appTypes from 'shared/utils/actions';
import { SUCCESS, FAILURE, OPEN, CLOSE } from 'shared/constants/actions';

const creditcard = appTypes.defineAction('creditcard');

export const SUBMIT = creditcard.defineAction('SUBMIT', []);
export const SET_TURNSTILE_TOKEN = creditcard.defineAction(
  'SET_TURNSTILE_TOKEN',
  [],
);
export const DETAIL = creditcard.defineAction('DETAIL', [OPEN, CLOSE]);
export const FETCH_DETAIL = creditcard.defineAction('FETCH_DETAIL', [
  SUCCESS,
  FAILURE,
]);
export const FETCH_CREATE = creditcard.defineAction('FETCH_CREATE', [
  SUCCESS,
  FAILURE,
]);
export const SET_IS_ACCOUNT_BLOCKED = creditcard.defineAction(
  'SET_IS_ACCOUNT_BLOCKED',
  [],
);
export const SET_PAYMENT_DATA = creditcard.defineAction('SET_PAYMENT_DATA', []);
export const CLEAR_FEEDBACK = creditcard.defineAction('CLEAR_FEEDBACK', []);

export type CreditCardPayload = {
  owner: string;
  number: string;
  cvv: string;
  expirationDate: string;
  turnstileToken: string;
};

export type UpdateMessage = {
  success: boolean;
  title: string;
  message?: string;
  isBlocked: boolean;
};
