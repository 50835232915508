// External
import 'draft-js-alignment-plugin/lib/plugin.css';
import { composeDecorators } from 'draft-js-plugins-editor';
import createImagePlugin from 'draft-js-image-plugin';
import createAlignmentPlugin from 'draft-js-alignment-plugin';
import createFocusPlugin from 'draft-js-focus-plugin';
import createBlockDndPlugin from 'draft-js-drag-n-drop-plugin';
import createResizablePlugin from 'draft-js-resizeable-plugin';
import imageStyles from './imageStyles.scss';

const createDraftJSPlugin = () => {
  const focusPlugin = createFocusPlugin();
  const resizablePlugin = createResizablePlugin();
  const blockDndPlugin = createBlockDndPlugin();
  const alignmentPlugin = createAlignmentPlugin();
  const { AlignmentTool } = alignmentPlugin;

  const decorator = composeDecorators(
    resizablePlugin.decorator,
    alignmentPlugin.decorator,
    focusPlugin.decorator,
    blockDndPlugin.decorator,
  );

  const imagePlugin = createImagePlugin({
    decorator,
    theme: { image: imageStyles.image },
  });

  const plugins = [
    blockDndPlugin,
    focusPlugin,
    alignmentPlugin,
    resizablePlugin,
    imagePlugin,
  ];
  return {
    plugins,
    AlignmentTool,
  };
};

export default createDraftJSPlugin;
