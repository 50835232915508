import {
  MaterialUICore,
  tokens,
  Body as BodyDS,
  Box as BoxDS,
  Paper as PaperDS,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing, border } = tokens;

export const ImgWrapper = styled('div')(() => ({
  textAlign: 'center',
  width: '55px',

  '& img': {
    display: 'inline-block',
  },
}));

type AnimatedCardProps = {
  show?: boolean;
  mobile?: boolean;
};

export const AnimatedCard = styled(PaperDS)(
  ({ show, mobile }: AnimatedCardProps) => ({
    display: show ? 'block' : 'none',
    height: mobile ? 'auto' : '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  }),
);

export const ComingSoonText = styled(BodyDS)(() => ({
  color: color.neutral[300],
}));

export const FormPayment = styled(BoxDS)(() => ({
  border: `1px solid ${color.primary.main}`,
  borderRadius: border.radius.md,
  cursor: 'pointer',
  padding: spacing.sm,

  '&:hover': {
    backgroundColor: color.secondary.main,
  },

  '& svg, & p': {
    display: 'inline-block',
    verticalAlign: 'middle',
  },

  '& p': {
    marginLeft: spacing.xs,
  },
}));
