// External
import { combineReducers } from 'redux';

// Internal Reducers
import imageComparison from './imageComparison/reducer';

const attachments = combineReducers({
  imageComparison,
});

export default attachments;
