import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { Button, MaterialIcons, MaterialUICore } from '@iclinic/design-system';
import SuccessSnackbar from '../SuccessSnackbar';
import useStyles from './styles';
import { getWhatsAppLink } from 'features/onlinePayment/utils';

const { WhatsApp, FileCopyOutlined, Send } = MaterialIcons;
const { Menu, MenuItem, ListItemIcon, ListItemText } = MaterialUICore;

type Props = {
  text: string;
  phone?: string;
};

export default function CheckoutLinkShare({ text, phone }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [copied, setCopied] = useState(false);
  const classes = useStyles();

  const linkWhatsappBill = getWhatsAppLink(text, phone);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleClickWhatsApp() {
    window.open(linkWhatsappBill, '_blank');
  }

  return (
    <>
      <Button
        aria-controls="checkout-link-menu"
        aria-haspopup="true"
        color="transparent"
        size="small"
        startIcon={<Send />}
        onClick={handleClick}
      >
        Enviar link
      </Button>
      <Menu
        id="checkout-link-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        classes={classes}
        elevation={16}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        disableEnforceFocus
      >
        <MenuItem
          onClick={handleClickWhatsApp}
          data-ga="pagamento-online-enviar-cobranca-whatsapp"
        >
          <ListItemIcon>
            <WhatsApp />
          </ListItemIcon>
          <ListItemText primary="Enviar por WhatsApp" />
        </MenuItem>
        <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
          <MenuItem data-ga="pagamento-online-copiar-link">
            <ListItemIcon>
              <FileCopyOutlined />
            </ListItemIcon>
            <ListItemText primary="Copiar link" />
          </MenuItem>
        </CopyToClipboard>
      </Menu>
      {copied && (
        <SuccessSnackbar
          message="Link copiado"
          onClose={() => setCopied(false)}
        />
      )}
    </>
  );
}
