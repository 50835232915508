// External
import React from 'react';
import { Route, Switch } from 'react-router-dom';

// async load
import {
  LoginV2,
  PasswordResetRequest,
  NewPassword,
  StepSignup,
  PasswordExpirationMessageExpired,
} from 'features/new-auth/containers';

const RootRoutes = () => (
  <Switch>
    <Route path="/" exact component={LoginV2} />
    <Route path="/usuarios/login" exact component={LoginV2} />
    <Route
      path="/new/usuarios/recuperar-senha"
      exact
      component={PasswordResetRequest}
    />
    <Route path="/new/usuarios/reset/:token" exact component={NewPassword} />
    <Route
      path="new/usuarios/setup"
      component={() => {
        window.location.href = `/new/cadastro/${window.location.search}`;
        return null;
      }}
    />
    <Route
      path="/new/usuarios/senha-expirada"
      component={PasswordExpirationMessageExpired}
    />
    <Route path="/new/cadastro" component={StepSignup} />
  </Switch>
);

export default () => <Route component={RootRoutes} />;
