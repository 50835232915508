import React from 'react';

import {
  theme as themeUI,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { makeStyles, createStyles, createMuiTheme, styled } = MaterialUICore;
const { color, border, spacing } = tokens;

type Props = {
  isLocal: boolean;
};

type AudioLevelContainerProps = {
  volume: number;
} & Props;

export const AudioLevelContainer = styled(
  ({
    isLocal,
    volume,
    ...rest
  }: AudioLevelContainerProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >) => <div {...rest} />,
)({
  width: '100%',
  height: '100%',
  position: 'absolute',
  boxShadow: ({ volume, isLocal }: AudioLevelContainerProps) =>
    isLocal && volume > 1
      ? `0 0 0 ${spacing.nano} ${color.primary.main}`
      : 'none',
  borderRadius: ({ volume, isLocal }: AudioLevelContainerProps) =>
    isLocal && volume > 1 ? border.radius.md : 'none',
});

const theme = createMuiTheme(themeUI);
export default makeStyles<{}, Props>(() =>
  createStyles({
    root: {
      left: spacing.base,
      bottom: spacing.base,
      position: 'absolute',
      [theme.breakpoints.up(320)]: {
        left: ({ isLocal }) => !isLocal && 'unset',
        right: ({ isLocal }) => !isLocal && spacing.sm,
        bottom: ({ isLocal }) => !isLocal && spacing.sm,
      },

      '& .MuiChip-icon': {
        width: '24px',
        height: '24px',
      },
    },
  }),
);
