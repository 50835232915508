import React, { DetailedHTMLProps, LiHTMLAttributes } from 'react';
import { useDispatch } from 'react-redux';

import { getFixedNumber } from 'features/onlinePayment/utils';
import useCurrencyMask, { format } from 'shared/hooks/useCurrencyMask';
import { updateScheduleProcedureById } from 'features/newFinance/state/procedures';
import { CustomTextField } from 'features/newFinance/components/styles';
import { ProcedureProps } from 'features/newFinance/types/types';

interface ProcedureItemProps
  extends DetailedHTMLProps<LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
  item: ProcedureProps;
}
export default ({ item, ...props }: ProcedureItemProps): JSX.Element => {
  const { procedure, quantity, price } = item;
  const dispatch = useDispatch();

  const {
    ref,
    onChange: onChangeValue,
    originalValue,
  } = useCurrencyMask(+price);

  const handleBlurProcedureValue = () => {
    dispatch(
      updateScheduleProcedureById({
        ...item,
        price: getFixedNumber(originalValue),
      }),
    );
  };

  return (
    <li {...props}>
      <span>{procedure.name}</span>
      <span>{quantity}</span>
      <CustomTextField
        inputRef={ref}
        defaultValue={format(getFixedNumber(originalValue))}
        onChange={onChangeValue}
        onBlur={handleBlurProcedureValue}
        size="small"
        type="text"
      />
    </li>
  );
};
