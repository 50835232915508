import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Alert, Button, MaterialIcons, Snackbar } from '@iclinic/design-system';
import { getMessage } from '../../state/sample-boxes/selectors';
import { clearResultMessage } from '../../state/sample-boxes';

const { CheckCircleOutline, Warning, ErrorOutlineOutlined } = MaterialIcons;

const iconBySeverity = {
  success: <CheckCircleOutline />,
  warning: <Warning />,
  error: <ErrorOutlineOutlined />,
};

const SnackbarMessage = () => {
  const dispatch = useDispatch();
  const message = useSelector(getMessage);

  const handleCloseMessage = () => dispatch(clearResultMessage());

  if (!message) return null;

  return (
    <Snackbar
      open={!!message}
      onClose={handleCloseMessage}
      severity={message.severity}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert
        severity={message.severity}
        icon={iconBySeverity[message.severity]}
        title={message.title}
        message={message.content}
        action={
          <Button color="transparent" size="large" onClick={handleCloseMessage}>
            Fechar
          </Button>
        }
      />
    </Snackbar>
  );
};

export default SnackbarMessage;
