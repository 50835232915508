import { takeLatest, all, call, put } from 'redux-saga/effects';

import * as actions from './index';
import { fetchListCBO, fetchCreateTermCBO } from '../services';
import { captureException } from 'shared/utils/handlerErrors';

export function* workerListCBO() {
  try {
    const { errors, getResponseData } = yield call(fetchListCBO);

    if (errors) throw errors;

    const listCBO = getResponseData();

    yield put(actions.submitListCBO(listCBO));
  } catch (error) {
    yield call(captureException, error);
  }
}

export function* workerCreateTermCBO({ payload: term }: { payload: string }) {
  try {
    const { errors, getResponseData } = yield call(fetchCreateTermCBO, term);

    if (errors) throw errors;

    const newCBO = getResponseData();
    yield put(actions.submitNewCBOTerm(newCBO));
  } catch (error) {
    yield call(captureException, error);
  }
}

export default function* signupSagas() {
  yield all([
    takeLatest(actions.listCBO, workerListCBO),
    takeLatest(actions.createCBO, workerCreateTermCBO),
  ]);
}
