import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = axiomTokens;

export const LineWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: spacing[4],
});
