// Internal
import { ActionType } from '@types';
import { reducerMerge } from 'shared/utils/reducer';
import { StockTabs } from 'features/stock/constants';
import * as actions from './actions';
import * as types from './constants';
import { StockState } from './types';

export const initialState: StockState = {
  currentModal: null,
  isFetching: false,
  products: [],
  inventories: [],
  units: [],
  stocks: [],
  selectedStock: null,
  totalCount: null,
  selectedList: [],
  productDescription: '',
  lotDescription: '',
  selectedProduct: null,
  selectedLot: null,
  selectedProductId: null,
  lot: null,
  openedProductId: null,
  productStock: null,
  errors: [],
  selectedFilter: StockTabs.All,
  totalSelected: null,
  history: {
    kind: 0,
    addedBy: '',
    period: '',
    productId: 0,
    dateRange: '',
  },
  page: 1,
  inventoriesHistory: [],
  users: [],
};

type StockActions = ActionType<typeof actions>;

const stock = (state = initialState, action: StockActions): StockState => {
  switch (action.type) {
    case types.FETCH_FAILURE:
    case types.FETCH_DETAIL_ACTION:
    case types.FETCH_DETAIL_SUCCESS:
    case types.FETCH_ESPECIFIC_STOCK_URL_ACTION:
    case types.FETCH_EXPECIFIC_STOCK_URL_SUCCESS:
    case types.FETCH_STOCK_UNIT_ACTION:
    case types.GET_PROFILE_AND_CLINIC_ACTION:
    case types.FETCH_STOCK_UNIT_SUCCESS:
    case types.FETCH_USER_SUCCESS:
    case types.FETCH_PRODUCTS_BY_NAME_ACTION:
    case types.FETCH_PRODUCTS_BY_NAME_SUCCESS:
    case types.FETCH_INVENTORIES_BY_PRODUCT_ID_ACTION:
    case types.FETCH_INVENTORIES_BY_PRODUCT_ID_SUCCESS:
    case types.CREATE_PRODUCT_ACTION:
    case types.UPDATE_PRODUCT_ACTION:
    case types.DELETE_PRODUCT_ACTION:
    case types.FETCH_PRODUCT_STOCK_ACTION:
    case types.FETCH_PRODUCT_STOCK_SUCCESS:
    case types.SELECTED_LIST_SUCCESS:
    case types.CREATE_LOT_ACTION:
    case types.CREATE_LOT_SUCCESS:
    case types.CREATE_INVENTORY_ACTION:
    case types.CREATE_INVENTORY_SUCCESS:
    case types.CHANGE_PRODUCT_DESCRIPTION_ACTION:
    case types.SET_SELECTED_PRODUCT_ACTION:
    case types.CHANGE_LOT_DESCRIPTION_ACTION:
    case types.SET_SELECTED_LOT_ACTION:
    case types.CLEAR_MODAL_ACTION:
    case types.CURRENT_MODAL_ACTION:
    case types.OPENED_PRODUCT_ID_ACTION:
    case types.FETCH_STOCK_FILTER_ACTION:
    case types.FETCH_STOCK_BY_PRODUCT_ID_ACTION:
    case types.FETCH_STOCK_BY_PRODUCT_ID_SUCCESS:
    case types.SELECT_ALL_PRODUCTS_SUCCESS:
    case types.FETCH_INVENTORIES_HISTORY_SUCCESS:
    case types.SELECTED_PAGE_ACTION:
    case types.SET_HISTORY_ACTION:
      return reducerMerge({}, state, action.payload);
    case types.FETCH_INVENTORIES_HISTORY_ACTION:
      return {
        ...state,
        history: {
          ...state.history,
          [action.payload.name]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default stock;
