import React from 'react';
import './SuccessPayment.css';

interface Props extends React.SVGProps<SVGSVGElement> {
  title: string;
}

function SuccessPayment({ title, ...svgProps }: Props): JSX.Element {
  return (
    <svg
      id="spuwghhwgse91"
      width="160"
      height="160"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 160 160"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      {...svgProps}
    >
      <title>{title}</title>
      <g
        id="spuwghhwgse92_tr"
        transform="translate(76.056000,76.253502) rotate(-20)"
      >
        <g id="spuwghhwgse92_ts" transform="scale(0,0)">
          <g
            id="spuwghhwgse92"
            transform="translate(-76.056000,-76.253502)"
            opacity="0"
          >
            <path
              id="spuwghhwgse93"
              d="M29,40.694500C29,34.788100,33.788100,30,39.694500,30L69.639000,30L69.639000,71.708500L31.138900,71.708500C29.957600,71.708500,29,70.750900,29,69.569600L29,40.694500Z"
              fill="rgb(187,222,251)"
              stroke="none"
              strokeWidth="1"
            />
            <path
              id="spuwghhwgse94"
              d="M39.160200,30C64.826600,30,85.681200,30,104.397000,30C123.112000,30,123.112000,51.923700,123.112000,51.923700L123.112000,120.368000C123.112000,121.550000,122.154000,122.507000,120.973000,122.507000L49.319900,122.507000C48.138600,122.507000,47.181000,121.550000,47.181000,120.368000L47.181000,51.923700C47.181000,51.923700,47.181000,30,39.160200,30Z"
              fill="rgb(227,242,253)"
              stroke="none"
              strokeWidth="1"
            />
          </g>
        </g>
      </g>
      <g
        id="spuwghhwgse95_tr"
        transform="translate(66.416698,63.833401) rotate(-7)"
      >
        <path
          id="spuwghhwgse95"
          d="M69.591700,68.691400C69.591700,67.926700,69.351600,67.297000,68.871300,66.802200C68.391100,66.298400,67.590600,65.848600,66.470000,65.452700C65.349500,65.047900,64.477900,64.674500,63.855300,64.332700C61.783200,63.208100,60.747100,61.543800,60.747100,59.339700C60.747100,57.846300,61.196200,56.618300,62.094400,55.655600C62.992700,54.693000,64.211100,54.121800,65.749700,53.941800L65.749700,51L67.884100,51L67.884100,53.968800C69.431600,54.193700,70.627800,54.859500,71.472700,55.966000C72.317600,57.063600,72.740000,58.494000,72.740000,60.257300L69.511600,60.257300C69.511600,59.123800,69.258200,58.233100,68.751200,57.585400C68.253200,56.928600,67.572800,56.600300,66.710200,56.600300C65.856400,56.600300,65.189400,56.834200,64.709100,57.302000C64.228900,57.769800,63.988800,58.440000,63.988800,59.312700C63.988800,60.095400,64.224400,60.725100,64.695800,61.201900C65.176000,61.669700,65.985400,62.115100,67.123700,62.537900C68.262100,62.960700,69.155900,63.352100,69.805100,63.711900C70.454400,64.071800,71.001300,64.485600,71.446000,64.953400C71.890700,65.412200,72.233100,65.943000,72.473200,66.545800C72.713300,67.148600,72.833400,67.854800,72.833400,68.664400C72.833400,70.184800,72.370900,71.417400,71.446000,72.362000C70.530000,73.306600,69.249300,73.864400,67.604000,74.035300L67.604000,76.666800L65.482900,76.666800L65.482900,74.048800C63.721900,73.850900,62.365700,73.212100,61.414100,72.132600C60.471400,71.053000,60,69.622600,60,67.841300L63.241700,67.841300C63.241700,68.974800,63.521800,69.852000,64.082100,70.472700C64.651300,71.093500,65.451700,71.403900,66.483400,71.403900C67.497300,71.403900,68.266500,71.156500,68.791300,70.661700C69.324900,70.166800,69.591700,69.510100,69.591700,68.691400Z"
          transform="translate(-66.416698,-63.833401)"
          opacity="0"
          fill="rgb(144,202,249)"
          stroke="none"
          strokeWidth="1"
        />
      </g>
      <g id="spuwghhwgse96">
        <g
          id="spuwghhwgse97_ts"
          transform="translate(96.896000,54.763750) scale(0,0)"
        >
          <path
            id="spuwghhwgse97"
            d="M79.250000,62.763800C79.250000,62.173100,79.728773,61.694300,80.319339,61.694300L113.470061,61.694300C114.061027,61.694300,114.540000,62.173100,114.540000,62.763800C114.540000,63.354400,114.061027,63.833200,113.470061,63.833200L80.319339,63.833200C79.728773,63.833200,79.250000,63.354400,79.250000,62.763800Z"
            transform="translate(-96.896000,-62.763750)"
            opacity="0"
            clipRule="evenodd"
            fill="rgb(144,202,249)"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
        </g>
        <g
          id="spuwghhwgse98_ts"
          transform="translate(96.896000,62.763750) scale(0,0)"
        >
          <path
            id="spuwghhwgse98"
            d="M79.250000,62.763800C79.250000,62.173100,79.728773,61.694300,80.319339,61.694300L113.470061,61.694300C114.061027,61.694300,114.540000,62.173100,114.540000,62.763800C114.540000,63.354400,114.061027,63.833200,113.470061,63.833200L80.319339,63.833200C79.728773,63.833200,79.250000,63.354400,79.250000,62.763800Z"
            transform="translate(-96.896000,-62.763750)"
            opacity="0"
            clipRule="evenodd"
            fill="rgb(144,202,249)"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
        </g>
        <g
          id="spuwghhwgse99_ts"
          transform="translate(96.896000,70.763750) scale(0,0)"
        >
          <path
            id="spuwghhwgse99"
            d="M79.250000,62.763800C79.250000,62.173100,79.728773,61.694300,80.319339,61.694300L113.470061,61.694300C114.061027,61.694300,114.540000,62.173100,114.540000,62.763800C114.540000,63.354400,114.061027,63.833200,113.470061,63.833200L80.319339,63.833200C79.728773,63.833200,79.250000,63.354400,79.250000,62.763800Z"
            transform="translate(-96.896000,-62.763750)"
            opacity="0"
            clipRule="evenodd"
            fill="rgb(144,202,249)"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
        </g>
        <g
          id="spuwghhwgse910_ts"
          transform="translate(96.896000,78.763750) scale(0,0)"
        >
          <path
            id="spuwghhwgse910"
            d="M79.250000,62.763800C79.250000,62.173100,79.728773,61.694300,80.319339,61.694300L113.470061,61.694300C114.061027,61.694300,114.540000,62.173100,114.540000,62.763800C114.540000,63.354400,114.061027,63.833200,113.470061,63.833200L80.319339,63.833200C79.728773,63.833200,79.250000,63.354400,79.250000,62.763800Z"
            transform="translate(-96.896000,-62.763750)"
            opacity="0"
            clipRule="evenodd"
            fill="rgb(144,202,249)"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
        </g>
        <g
          id="spuwghhwgse911_ts"
          transform="translate(96.896000,86.763750) scale(0,0)"
        >
          <path
            id="spuwghhwgse911"
            d="M79.250000,62.763800C79.250000,62.173100,79.728773,61.694300,80.319339,61.694300L113.470061,61.694300C114.061027,61.694300,114.540000,62.173100,114.540000,62.763800C114.540000,63.354400,114.061027,63.833200,113.470061,63.833200L80.319339,63.833200C79.728773,63.833200,79.250000,63.354400,79.250000,62.763800Z"
            transform="translate(-96.896000,-62.763750)"
            opacity="0"
            clipRule="evenodd"
            fill="rgb(144,202,249)"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
        </g>
        <g
          id="spuwghhwgse912_ts"
          transform="translate(96.896000,93.763750) scale(0,0)"
        >
          <path
            id="spuwghhwgse912"
            d="M79.250000,62.763800C79.250000,62.173100,79.728773,61.694300,80.319339,61.694300L113.470061,61.694300C114.061027,61.694300,114.540000,62.173100,114.540000,62.763800C114.540000,63.354400,114.061027,63.833200,113.470061,63.833200L80.319339,63.833200C79.728773,63.833200,79.250000,63.354400,79.250000,62.763800Z"
            transform="translate(-96.896000,-62.763750)"
            opacity="0"
            clipRule="evenodd"
            fill="rgb(144,202,249)"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
        </g>
        <g
          id="spuwghhwgse913_ts"
          transform="translate(96.896000,100.763750) scale(0,0)"
        >
          <path
            id="spuwghhwgse913"
            d="M79.250000,62.763800C79.250000,62.173100,79.563783,61.694300,79.950834,61.694300L101.677473,61.694300C102.064786,61.694300,102.378700,62.173100,102.378700,62.763800C102.378700,63.354400,102.064786,63.833200,101.677473,63.833200L79.950834,63.833200C79.563783,63.833200,79.250000,63.354400,79.250000,62.763800Z"
            transform="translate(-96.896000,-62.763750)"
            opacity="0"
            clipRule="evenodd"
            fill="rgb(144,202,249)"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
          />
        </g>
      </g>
      <g id="spuwghhwgse914">
        <g id="spuwghhwgse915">
          <g
            id="spuwghhwgse916_ts"
            transform="translate(102.487003,123.487000) scale(0,0)"
          >
            <circle
              id="spuwghhwgse916"
              r="12.487400"
              transform="translate(-0.000004,0)"
              opacity="0"
              fill="rgb(140,215,161)"
              stroke="none"
              strokeWidth="1"
            />
          </g>
          <g
            id="spuwghhwgse917_ts"
            transform="translate(102.378700,123.618500) scale(0,0)"
          >
            <path
              id="spuwghhwgse917"
              d="M99.088400,127.871000L100.532000,129.315000L110.481000,119.366000L109.037000,117.922000L100.573000,126.387000L95.720400,121.535000L94.276400,122.979000L99.128500,127.831000L99.088400,127.871000Z"
              transform="translate(-102.378700,-123.618500)"
              opacity="0"
              clipRule="evenodd"
              fill="rgb(255,255,255)"
              fillRule="evenodd"
              stroke="none"
              strokeWidth="1"
            />
          </g>
        </g>
        <g
          id="spuwghhwgse918_ts"
          transform="translate(122.454502,113.745003) scale(0,0)"
        >
          <line
            id="spuwghhwgse918"
            x1="125.540000"
            y1="111.941000"
            x2="119.369000"
            y2="115.549000"
            transform="translate(-122.454502,-113.745003)"
            opacity="0"
            fill="none"
            stroke="rgb(148,105,240)"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
        <g
          id="spuwghhwgse919_ts"
          transform="translate(121.328999,124.806499) scale(0,0)"
        >
          <line
            id="spuwghhwgse919"
            x1="123.015000"
            y1="125.353000"
            x2="119.643000"
            y2="124.260000"
            transform="translate(-121.328999,-124.806499)"
            opacity="0"
            fill="none"
            stroke="rgb(105,191,240)"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
        <g
          id="spuwghhwgse920_ts"
          transform="translate(112.536503,105.635502) scale(0,0)"
        >
          <line
            id="spuwghhwgse920"
            x1="113.786000"
            y1="102.287000"
            x2="111.287000"
            y2="108.984000"
            transform="translate(-112.536503,-105.635502)"
            opacity="0"
            fill="none"
            stroke="rgb(140,215,161)"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
      </g>
      <g
        id="spuwghhwgse921_ts"
        transform="translate(122.500000,23.500000) scale(1,1)"
      >
        <circle
          id="spuwghhwgse921"
          r="4.500000"
          transform="translate(0,0)"
          opacity="0"
          fill="none"
          stroke="rgb(148,105,240)"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <g id="spuwghhwgse922_ts" transform="translate(46,99) scale(1,1)">
        <circle
          id="spuwghhwgse922"
          r="3"
          transform="translate(0,0)"
          opacity="0"
          fill="none"
          stroke="rgb(105,191,240)"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}

export default React.memo(SuccessPayment);
