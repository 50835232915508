/* eslint-disable sonarjs/no-duplicate-string */
export const CHANGE_TAB_RECURRENCE = {
  monthly: true,
  annual: false,
};

export const PERIOD_SELECT = {
  monthly: 'monthly',
  annual: 'annual',
};

export const PRODUCT_SELECTOR = {
  selected: 'product',
  extraProduct: 'extraProduct',
};

export const PRODUCT_SUBSCRIPTION = {
  base: 'base',
  addons: 'addons',
  extra: 'extra',
};

export const ICLINIC_ASSIST_SLUG = 'iclinic-assist';

export const PRODUCTS_SUBSCRIPTION_GROUP = [
  {
    slug: 'starter',
    functionalities: [
      { name: 'Agenda', slug: 'schedule' },
      { name: 'Cadastro de pacientes', slug: 'cadastro-paciente' },
      { name: 'Prontuário eletrônico', slug: 'prontuario-eletronico' },
      { name: 'Prescrição eletrônica', slug: 'prescricao-eletronica' },
      { name: 'Faturamento TISS', slug: 'feturamento-tiss' },
      { name: 'Envio de lembretes', slug: 'lembretes' },
      { name: 'R$ 0,10 por SMS', additional: true, slug: 'sms' },
      { name: 'Relatórios', slug: 'relatorios' },
      { name: 'Agendamento online', slug: 'online-scheduling' },
      { name: 'Multiprocedimentos', additional: true, slug: 'multiprocedure' },
    ],
  },
  {
    slug: 'plus',
    functionalities: [
      { name: 'Agenda', slug: 'schedule' },
      { name: 'Cadastro de pacientes', slug: 'cadastro-paciente' },
      { name: 'Prontuário eletrônico', slug: 'prontuario-eletronico' },
      { name: 'Prescrição eletrônica', slug: 'prescricao-eletronica' },
      { name: 'Faturamento TISS', slug: 'faturamento-tiss' },
      { name: 'Envio de lembretes', slug: 'envio-lembretes' },
      { name: 'Relatórios', additional: true, slug: 'relatorios' },
      { name: 'Logo em documentos', additional: true, slug: 'logo' },
      { name: 'Assinatura Digital', slug: 'assinatura-digital' },
      { name: 'R$ 0,09 por SMS', additional: true, slug: 'sms' },
      {
        name: '100 SMS gratuitos por mês',
        additional: true,
        slug: 'sms-gratis',
      },
      {
        name: 'E-mail aniversariantes',
        additional: true,
        slug: 'email-aniversariante',
      },
      { name: 'Multiprocedimentos', additional: true, slug: 'multiprocedure' },
      { name: 'Agendamento online', slug: 'online-scheduling' },
      { name: 'Chat interno', additional: true, slug: 'chat' },
      {
        name: 'Lembrete de consulta por Whatsapp',
        slug: 'whatsapp-appointment-reminder',
      },
      {
        name: 'Confirmação de consulta por Whatsapp',
        slug: 'whatsapp-appointment-confirmation',
      },
      { name: 'SMS com resposta', additional: true, slug: 'sms-resposta' },
      { name: 'Assinatura digital', slug: 'assinatura-digital' },
    ],
  },
  {
    slug: 'pro',
    functionalities: [
      { name: 'Agenda', slug: 'schedule' },
      { name: 'Cadastro de pacientes', slug: 'cadastro-paciente' },
      { name: 'Prontuário eletrônico', slug: 'prontuario-eletronico' },
      { name: 'Prescrição eletrônica', slug: 'prescricao-eletronica' },
      { name: 'Faturamento TISS', slug: 'faturamento-tiss' },
      { name: 'Envio de lembretes', slug: 'envio-lembretes' },
      { name: 'Controle financeiro', slug: 'controle-financeiro' },
      { name: 'Repasse financeiro', slug: 'repasse-financeiro' },
      { name: 'Relatórios', slug: 'relatorios' },
      { name: 'Relatórios financeiros', slug: 'finances-report' },
      { name: 'Controle de estoque', slug: 'stock' },
      { name: 'Logo em documentos', slug: 'logo' },
      { name: 'R$ 0,09 por SMS', slug: 'sms' },
      {
        name: '100 SMS gratuitos por mês',
        additional: true,
        slug: 'sms-gratis',
      },
      { name: 'SMS com resposta', additional: true, slug: 'sms-resposta' },
      { name: 'Chat interno', additional: true, slug: 'chat' },
      {
        name: 'E-mail aniversariantes',
        additional: true,
        slug: 'email-aniversariante',
      },
      { name: 'Multiprocedimentos', additional: true, slug: 'multiprocedure' },
      {
        name: 'Lembrete de consulta por Whatsapp',
        slug: 'whatsapp-appointment-reminder',
      },
      {
        name: 'Confirmação de consulta por Whatsapp',
        slug: 'whatsapp-appointment-confirmation',
      },
      { name: 'Agendamento online', slug: 'online-scheduling' },
      { name: 'Assinatura digital', slug: 'assinatura-digital' },
    ],
  },
  {
    slug: 'premium',
    functionalities: [
      { name: 'Agenda', slug: 'schedule' },
      { name: 'Cadastro de pacientes', slug: 'cadastro-paciente' },
      { name: 'Prontuário eletrônico', slug: 'prontuario-eletronico' },
      { name: 'Prescrição eletrônica', slug: 'prescricao-eletronica' },
      { name: 'Faturamento TISS', slug: 'faturamento-tiss' },
      { name: 'Envio de lembretes', slug: 'envio-lembretes' },
      { name: 'Controle financeiro', slug: 'controle-financeiro' },
      { name: 'Repasse financeiro', slug: 'repasse-financeiro' },
      { name: 'Relatórios', slug: 'relatorios' },
      { name: 'Relatórios financeiros', slug: 'finances-report' },
      { name: 'Controle de estoque', slug: 'stock' },
      { name: 'Logo em documentos', slug: 'logo' },
      { name: 'Assinatura Digital', slug: 'assinatura-digital' },
      { name: 'R$ 0,09 por SMS', slug: 'sms' },
      {
        name: '100 SMS gratuitos por mês',
        additional: true,
        slug: 'sms-gratis',
      },
      { name: 'SMS com resposta', additional: true, slug: 'sms-resposta' },
      { name: 'Chat interno', additional: true, slug: 'chat' },
      {
        name: 'E-mail aniversariantes',
        additional: true,
        slug: 'email-aniversariante',
      },
      { name: 'Multiprocedimentos', additional: true, slug: 'multiprocedure' },
      {
        name: 'Lembrete de consulta por Whatsapp',
        slug: 'whatsapp-appointment-reminder',
      },
      {
        name: 'Confirmação de consulta por Whatsapp',
        slug: 'whatsapp-appointment-confirmation',
      },
      { name: 'Agendamento online', slug: 'online-scheduling' },
      { name: 'Fluxos de retorno', slug: 'fluxos-retorno' },
      { name: 'Fluxos para plano de cuidado', slug: 'fluxos-cuidados' },
      { name: 'Campanhas de e-mail', slug: 'campanha-email' },
      { name: 'Assinatura digital', slug: 'assinatura-digital' },
      { name: 'Whitebook', slug: 'whitebook' },
    ],
  },
];
