import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '../iconButton/IconButton';
import SubTitle from '../subTitle/SubTitle';
import style from './LunchTime.scss';

const LunchTime = ({ startTime, endTime }) => (
  <div className={style.lunchItem}>
    <div className={style.lunchItem__iconContent}>
      <IconButton icon="info" className={style.lunchItem__icon} />
    </div>
    <div className={style.lunchItem__itemContent}>
      <SubTitle
        label={
          startTime
            ? `O horário de almoço está configurado das ${startTime} ás ${endTime}. Altere nas`
            : 'Você não tem horário de almoço cadastrado. Altere nas'
        }
      />
      <a
        href="/configuracoes/profissionais/agenda/"
        className={style.lunchItem__link}
      >
        <SubTitle label=" configurações da agenda iClinic" />
      </a>
    </div>
  </div>
);

LunchTime.propTypes = {
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
};

export default LunchTime;
