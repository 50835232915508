import { Response } from '@types';
import { call, put, takeLatest } from 'redux-saga/effects';

import { captureException } from 'shared/utils/handlerErrors';
import { actions } from '.';
import {
  fetchAppointmentGuideData,
  fetchPatientData,
  patchAppointmentGuideData,
  printAppointmentGuide,
  saveGuideData,
} from 'features/tissInvoicing/services/appointmentGuide';
import {
  AppointmentGuideResultValues,
  PatientData,
} from 'features/tissInvoicing/types/appointmentGuide';
import getSnackbarPayload from 'features/tissInvoicing/utils/getSnackbarPayload';
import {
  appointmentGuideRequest,
  normalizeAppointmentGuideResponse,
} from './normalizers';
import { changeUrl } from 'features/tissInvoicing/utils/changeUrl';

type FetchPatientOptions = ReturnType<typeof actions.fetchPatientData>;
type SaveGuideData = ReturnType<typeof actions.saveGuideData>;
type PrintGuide = ReturnType<typeof actions.printGuide>;
type PatchGuideData = ReturnType<typeof actions.patchGuideData>;
type FetchAppointmentGuideData = ReturnType<
  typeof actions.fetchAppointmentGuideData
>;

export function* fetchPatientDataWorker({
  payload: { patientId },
}: FetchPatientOptions) {
  try {
    const { data }: Response<PatientData> = yield call(
      fetchPatientData,
      patientId,
    );

    yield put(actions.setPatientData(data));
  } catch (error) {
    captureException(error);
  }
}

export function* saveGuideDataWorker({ payload: { data } }: SaveGuideData) {
  try {
    yield put(actions.setLoading(true));

    const result: Response<AppointmentGuideResultValues> = yield call(
      saveGuideData,
      appointmentGuideRequest({
        ...data,
        physician_id: data?.physician_id,
      }),
    );

    yield put(
      actions.setSnackbarContent(
        getSnackbarPayload('Guia salva com sucesso', 'success'),
      ),
    );

    const normalizedResult = normalizeAppointmentGuideResponse(result.data);

    yield put(actions.setAppointmentGuide(normalizedResult));

    changeUrl(`tiss/guias-consulta/${normalizedResult.id}/`);
  } catch (error) {
    yield put(
      actions.setSnackbarContent(
        getSnackbarPayload('Houve um problema ao salvar a guia', 'error'),
      ),
    );
    captureException(error);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* patchGuideWorker({ payload: { data, id } }: PatchGuideData) {
  try {
    yield put(actions.setLoading(true));

    const result: Response<AppointmentGuideResultValues> = yield call(
      patchAppointmentGuideData,
      id,
      appointmentGuideRequest(data),
    );

    yield put(
      actions.setSnackbarContent(
        getSnackbarPayload('Guia atualizada com sucesso', 'success'),
      ),
    );

    const normalizedResult = normalizeAppointmentGuideResponse(result.data);

    yield put(actions.setAppointmentGuide(normalizedResult));
  } catch (error) {
    yield put(
      actions.setSnackbarContent(
        getSnackbarPayload('Houve um problema ao atualizar a guia', 'error'),
      ),
    );
    captureException(error);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* printGuideWorker({ payload: { id } }: PrintGuide) {
  try {
    yield put(actions.setLoading(true));

    const result: Response<{
      url: string;
      isBase64Encoded: boolean;
    }> = yield call(printAppointmentGuide, id);

    yield put(actions.setGuidePDFUrl({ url: result.data.url, open: true }));
  } catch (error) {
    yield put(
      actions.setSnackbarContent(
        getSnackbarPayload('Houve um problema ao imprimir a guia', 'error'),
      ),
    );
    captureException(error);
  } finally {
    yield put(actions.setLoading(false));
  }
}

export function* fetchAppointmentGuideWorker({
  payload: { appointmentId },
}: FetchAppointmentGuideData) {
  try {
    const result: Response<AppointmentGuideResultValues> = yield call(
      fetchAppointmentGuideData,
      appointmentId,
    );

    const normalizedResult = normalizeAppointmentGuideResponse(result.data);

    yield put(actions.setAppointmentGuide(normalizedResult));
  } catch (error) {
    yield put(
      actions.setSnackbarContent(
        getSnackbarPayload('Houve um problema ao carregar guia', 'error'),
      ),
    );
    captureException(error);
  }
}

export default function* watchSadtWorker() {
  yield takeLatest(actions.fetchPatientData, fetchPatientDataWorker);
  yield takeLatest(actions.saveGuideData, saveGuideDataWorker);
  yield takeLatest(
    actions.fetchAppointmentGuideData,
    fetchAppointmentGuideWorker,
  );
  yield takeLatest(actions.printGuide, printGuideWorker);
  yield takeLatest(actions.patchGuideData, patchGuideWorker);
}
