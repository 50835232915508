import {
  createPayloadAction,
  createParamsAction,
  createSimpleAction,
} from 'shared/utils/actions';
import { TransactionStatus } from 'features/onlinePayment/state/constants';
import {
  CHECKOUT_ACTION,
  CHECKOUT_FAILURE,
  CHECKOUT_SUCCESS,
  SYNC_STATUS_TRANSACTION_ACTION,
  SYNC_STATUS_TRANSACTION_FAILURE,
  SYNC_STATUS_TRANSACTION_SUCCESS,
  CHECKOUT_PIX,
  CHECKOUT_CREDIT_CARD,
  CHECKOUT_V2_FAILURE,
  CHECKOUT_V2_SUCCESS,
  PIX_QRCODE_VALIDITY,
} from './constants';
import {
  SyncStatusTransaction,
  SyncStatusTransactionFailure,
  SyncStatusTransactionSuccess,
  CheckoutPix,
  CheckoutCreditCard,
  CheckoutV2Failure,
  CheckoutV2Success,
  CheckoutV2Data,
  PixQRCodeValidity,
} from './types';

export const checkout = createParamsAction<Checkout>(CHECKOUT_ACTION);

export const checkoutFailure = createParamsAction<CheckoutFailure>(
  CHECKOUT_FAILURE,
);

export const checkoutSuccess = (orderNumber: string) =>
  createPayloadAction<CheckoutSuccess>(CHECKOUT_SUCCESS)({
    orderNumber,
  });

export const checkoutCreditCard = createParamsAction<CheckoutCreditCard>(
  CHECKOUT_CREDIT_CARD,
);
export const checkoutPix = createSimpleAction<CheckoutPix>(CHECKOUT_PIX);

export const checkoutV2Failure = createParamsAction<CheckoutV2Failure>(
  CHECKOUT_V2_FAILURE,
);

export const checkoutV2Success = (checkoutV2: CheckoutV2Data) =>
  createPayloadAction<CheckoutV2Success>(CHECKOUT_V2_SUCCESS)({
    checkoutV2,
  });

export const pixQRCodeValidity = (isPixQRCodeValid: boolean) =>
  createPayloadAction<PixQRCodeValidity>(PIX_QRCODE_VALIDITY)({
    isPixQRCodeValid,
  });

export const syncStatusTransaction = createSimpleAction<SyncStatusTransaction>(
  SYNC_STATUS_TRANSACTION_ACTION,
);

export const syncStatusTransactionFailure = createSimpleAction<
  SyncStatusTransactionFailure
>(SYNC_STATUS_TRANSACTION_FAILURE);

export const syncStatusTransactionSuccess = (status: TransactionStatus) =>
  createPayloadAction<SyncStatusTransactionSuccess>(
    SYNC_STATUS_TRANSACTION_SUCCESS,
  )({
    status,
  });
