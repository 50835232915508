// Internal
import appTypes from 'shared/utils/actions';
import { SUCCESS, FAILURE, OPEN, CLOSE } from 'shared/constants/actions';

const upgrade = appTypes.defineAction('upgrade');

export const UPGRADE_SUCCESS_REFRESH = upgrade.defineAction(
  'UPGRADE_SUCCESS_REFRESH',
  [],
);
export const UPGRADE_RELOAD = upgrade.defineAction('UPGRADE_RELOAD', []);
export const UPGRADE_DETAIL = upgrade.defineAction('UPGRADE_DETAIL', [
  OPEN,
  CLOSE,
]);
export const DETAILS_VIEW = upgrade.defineAction('DETAILS_VIEW', [OPEN, CLOSE]);
export const PRODUCTS_VIEW = upgrade.defineAction('PRODUCTS_VIEW', [
  OPEN,
  CLOSE,
]);
export const SUCCESS_VIEW = upgrade.defineAction('SUCCESS_VIEW', [OPEN, CLOSE]);
export const SELECT_PRODUCT = upgrade.defineAction('SELECT_PRODUCT', [SUCCESS]);
export const FETCH_UPGRADE_CONFIRM = upgrade.defineAction(
  'FETCH_UPGRADE_CONFIRM',
  [SUCCESS, FAILURE],
);
export const FETCH_UPGRADE_DETAIL = upgrade.defineAction(
  'FETCH_UPGRADE_DETAIL',
  [SUCCESS, FAILURE],
);
export const FETCH_UPGRADE_NOTIFICATION = upgrade.defineAction(
  'FETCH_UPGRADE_NOTIFICATION',
  [SUCCESS, FAILURE],
);
export const FETCH_UPGRADE_PENDING = upgrade.defineAction(
  'FETCH_UPGRADE_PENDING',
  [],
);
