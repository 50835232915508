// External
import {
  put, call, takeLatest, select,
} from 'redux-saga/effects';

// Internal
import iclinic from 'services/iclinic';
import { emitErrors } from 'shared/utils/emitErrors';
import { captureException } from 'shared/utils/handlerErrors';
import { removeQueryStringFromUrl } from 'shared/utils/strings';
import { USER_ID_KEY } from 'shared/constants/auth';
import { openDetail as openVideo } from 'state/video/actions';
import { TRIAL_VIDEO_ADDON } from 'shared/constants/features';
import { getUrlParams } from 'shared/utils/url';
import * as actions from './actions';
import * as types from './constants';
import { getSuccessInfo, getViewMode } from './selectors';
import { VIEWMODE } from '../constants';
import { StoreState } from '../../rootReducer';
import { Product } from './types';

// @ts-ignore
const getSharedInfo = (state: StoreState) => state.shared;
const getSelectedProduct = (state: StoreState) => state.billing.addon.product;
const getSelectedModal = (state: StoreState) => state.billing.addon.modal;

export function* workerGetDetail() {
  try {
    const selectedAddon = yield select(getSelectedModal);
    const { getResponseData, errors } = yield call(
      iclinic.product.fetchProductDetail,
      selectedAddon,
    );

    if (errors) {
      yield put(actions.fetchDetailFailure(emitErrors(errors)));
    } else {
      const payload = getResponseData();
      yield put(actions.fetchDetailSucess(payload));
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerNotification() {
  try {
    const { modal } = yield select(getSelectedModal);
    const { errors } = yield call(iclinic.upgrade.fetchUpgradeNotification, { modal, slug: modal });
    if (errors) {
      yield put(actions.fetchNotificationFailure(emitErrors(errors)));
    } else {
      yield put(actions.fetchNotificationSuccess());
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerCreate() {
  try {
    const product: Product = yield select(getSelectedProduct);
    const { slug } = product;
    const { errors } = yield call(iclinic.upgrade.fetchUpgradeConfirm, [slug]);
    if (errors) {
      yield put(actions.fetchCreateFailure(emitErrors(errors)));
    } else {
      yield put(actions.reload());
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerFeatureUser() {
  try {
    const { slug }: Product = yield select(getSelectedProduct);
    const userId = localStorage.getItem(USER_ID_KEY);
    const payload = {
      name: TRIAL_VIDEO_ADDON,
      state: true,
      user_id: userId,
    };
    yield call(iclinic.feature.fetchFeatureUser, payload);
    yield put(actions.closeDetail(slug));
    yield put(openVideo());
  } catch (error) {
    captureException(error);
  }
}

export function* workerInitialize() {
  try {
    const productSlug = getUrlParams('addon-success');
    if (productSlug) {
      const { getResponseData, errors } = yield call(
        iclinic.product.fetchProductDetail,
        productSlug,
      );
      const modal = yield select(getSelectedModal);
      if (errors) {
        yield put(actions.fetchDetailFailure(emitErrors(errors)));
      } else {
        const payload = getResponseData();
        yield put(actions.fetchDetailSucess(payload));
        yield put(actions.setStepSuccess(modal));
        removeQueryStringFromUrl();
      }
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerReload() {
  try {
    const { updatePermissionsUrl } = yield select(getSharedInfo);
    const url = new URL(window.location.href);
    window.location.href = `${updatePermissionsUrl}${url.pathname}?addon-success=true`;
  } catch (error) {
    captureException(error);
  }
}

export function* workerCloseInstruction() {
  try {
    const { slug }: Product = yield select(getSelectedProduct);
    const { instructionsUrl } = yield select(getSuccessInfo);
    window.open(instructionsUrl, '_blank');
    yield put(actions.closeDetail(slug));
  } catch (error) {
    captureException(error);
  }
}

export function* workerAddonConfirm() {
  try {
    const { slug }: Product = yield select(getSelectedProduct);
    const viewMode = yield select(getViewMode);
    switch (viewMode) {
      case VIEWMODE.paymentSlip:
        yield put(actions.closeDetail(slug));
        break;
      case VIEWMODE.nonAdmin:
        yield put(actions.fetchNotification());
        break;
      case VIEWMODE.productInfo:
        yield put(actions.fetchCreate());
        break;
      case VIEWMODE.addonTrial:
        yield put(actions.fetchFeatureUser());
        break;
      default:
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerCloseDetail() {
  try {
    yield put(actions.clearErrors());
  } catch (error) {
    captureException(error);
  }
}

export default function* addonSagas() {
  yield takeLatest(types.DETAIL_CLOSE, workerCloseDetail);
  yield takeLatest(types.FETCH_FEATURE_USER, workerFeatureUser);
  yield takeLatest(types.DETAIL_OPEN, workerGetDetail);
  yield takeLatest(types.INSTRUCTION_CLOSE, workerCloseInstruction);
  yield takeLatest(types.FETCH_CREATE, workerCreate);
  yield takeLatest(types.FETCH_NOTIFICATION, workerNotification);
  yield takeLatest(types.RELOAD, workerReload);
  yield takeLatest(types.INITIALIZE, workerInitialize);
  yield takeLatest(types.CONFIRM, workerAddonConfirm);
}
