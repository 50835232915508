import React, { useState } from 'react';
import { Button } from '@iclinic/design-system';

import { PatientParents, ButtonContainer } from './index.styles';
import InfoRow from '../InfoRow';
import { RelatedModal } from '../RelatedModal';
import { PatientRelative } from 'state/records/types';
import { getPatientRelativeName } from 'features/records/utils';

type PatientRelativesProps = {
  patientRelated: Array<PatientRelative>;
};

export const PatientRelatives = ({ patientRelated }: PatientRelativesProps) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const showButton = patientRelated?.length > 2;

  return (
    <PatientParents>
      <InfoRow label="Familiares" />
      {patientRelated?.slice(0, 2).map((item) => (
        <InfoRow
          key={`${item.relatedPatientName}-${item.relatedness}`}
          value={getPatientRelativeName(
            item.relatedness,
            item.relatedPatientName,
          )}
        />
      ))}
      {showButton && (
        <ButtonContainer textAlign="center">
          <Button
            size="small"
            variant="text"
            onClick={toggleModal}
            onBlur={toggleModal}
          >
            Exibir mais
          </Button>
          <RelatedModal show={showModal} patientRelated={patientRelated} />
        </ButtonContainer>
      )}
    </PatientParents>
  );
};
