/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable sonarjs/prefer-single-boolean-return */
// External
import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
import {
  convertToRaw,
  EditorState,
  convertFromHTML,
  convertFromRaw,
  ContentState,
  getSafeBodyFromHTML,
} from 'draft-js';
import moment from 'moment';
import uuid from 'uuid/v1';

// Internal
import {
  FORM_LIST_CAMPAIGNS,
  FORM_ADD_CAMPAIGN_MODAL,
  FORM_EDIT_CAMPAIGN,
} from 'shared/constants/forms';
import { typeStyleEditor } from 'ui/new/editor/constants';
import { decorator, blockRender, optionsExport } from 'ui/new/editor';
import { STATUS } from 'ui/new/actionable-card/constants';
import {
  searchName,
  searchNameDropdownCID,
  searchNameDropdownSeenBy,
  searchNameDropdownPatientTag,
  searchNameDropdownProcedure,
  AudienceSummary,
  AudienceFilter,
  sortedAddFilters,
  CampaignStatus,
} from './constants';
import { stateToHTML } from './utils';

// import cids from './cids';
// we're disabling the real cids database at this moment given the server has no efficient endpoint
// to filter recipients using CID. Until it's fixed,
// let's keep the structure, but avoid importing the 2Mb DB
const cids = {
  ordered: [],
  lookupTable: {},
  normalizeAndSplitWords: () => [],
};

const asPercentage = (value, total) =>
  total > 0 ? `${Math.round((100 * value) / total)}%` : '-';

const ifSent = (status, ifTrue, ifFalse) =>
  status === 'sent' ? ifTrue : ifFalse;

const getCampaignListForm = formValueSelector(FORM_LIST_CAMPAIGNS);
const getCampaignSearchTerms = (state) =>
  (getCampaignListForm(state, searchName) || '').toLocaleLowerCase();

export const getSelectedCampaigns = (state) => state.campaign.selected;

const getEditCampaign = formValueSelector(FORM_EDIT_CAMPAIGN);
export const getEditCampaignValues = (state) =>
  getEditCampaign(
    state,
    'title',
    'audienceSummary',
    'audiences',
    'mail',
    'status',
    'scheduleDate',
    'scheduleTime',
  );
export const getDetailsCampaigns = (state) =>
  state.campaign.campaignDetails.campaign;
export const getDetailsCampaignsIsFetching = (state) =>
  state.campaign.campaignDetails.isFetching;
export const getCreatedCampaign = (state) =>
  state.campaign.campaignCreate.campaign;
export const getFilterCIDSearchState = (state) =>
  (getEditCampaign(state, searchNameDropdownCID) || '').toLocaleLowerCase();
export const getFilterSeenBySearchState = (state) =>
  (getEditCampaign(state, searchNameDropdownSeenBy) || '').toLocaleLowerCase();
export const getFilterPatientTagSearchState = (state) =>
  (
    getEditCampaign(state, searchNameDropdownPatientTag) || ''
  ).toLocaleLowerCase();
export const getFilterProcedureSearchState = (state) =>
  (
    getEditCampaign(state, searchNameDropdownProcedure) || ''
  ).toLocaleLowerCase();
export const getPatientCount = (state) => state.campaign.patientCount.count;
export const getProcedures = (state) => state.campaign.procedures.procedures;
export const getPatientTags = (state) => state.campaign.patientTags.patientTags;
export const getCurrentTitle = (state) => {
  const campaignDetails = getDetailsCampaigns(state);
  return campaignDetails ? campaignDetails.title : '';
};

export const getErrorsEditCampaign = (state) =>
  state.campaign.campaignUpdate.errors;
export const getEditCampaignsIsFetching = (state) =>
  state.campaign.campaignUpdate.isFetching;
export const getErrorsCreateCampaign = (state) =>
  state.campaign.campaignCreate.errors;
export const getErrorsDeleteCampaign = (state) =>
  state.campaign.campaignDelete.errors;
export const getNotificationsDelete = (state) =>
  state.campaign.campaignDelete.notifications;
export const getNotificationsUpdate = (state) =>
  state.campaign.campaignUpdate.notifications;

export const getCampaignNotifications = createSelector(
  getNotificationsDelete,
  getNotificationsUpdate,
  (notificationsDelete, notificationsUpdate) => {
    if (notificationsDelete || notificationsUpdate) {
      return notificationsDelete
        .concat(notificationsUpdate)
        .map(({ code, kind, message }) => ({
          code,
          kind,
          message,
          id: uuid(),
        }));
    }
    return [];
  },
);

export const getErrorsListCampaign = (state) => state.campaign.errors;

export const getErrorsCampaign = createSelector(
  getErrorsCreateCampaign,
  getErrorsDeleteCampaign,
  getErrorsListCampaign,
  (createCampaign, deleteCampaign, listCampaigns) => {
    const listErrors = listCampaigns
      .concat(deleteCampaign)
      .concat(createCampaign);
    return listErrors.map(({ code, message }) => ({
      code,
      message,
      id: uuid(),
    }));
  },
);

export const getListErrorsEditCampaign = createSelector(
  getErrorsEditCampaign,
  (editCampaign) =>
    editCampaign.map(({ code, message }) => ({ code, message, id: uuid() })),
);

export const getClinicId = createSelector(getDetailsCampaigns, (campaign) => {
  if (!campaign) {
    return undefined;
  }

  return campaign.clinicId;
});

const getOrderedAndMap = (value) => {
  const map = {};
  const ordered = [];

  value.forEach(({ id, name }) => {
    ordered.push([id, name]);
    map[id] = name;
  });

  return { ordered, map };
};

export const getAllPatientTags = createSelector(getPatientTags, (patientTags) =>
  getOrderedAndMap(patientTags),
);

export const getUserProfiles = (state) =>
  state.campaign.userProfile.userProfiles;
export const getAllPhysicians = createSelector(getUserProfiles, (physicians) =>
  getOrderedAndMap(physicians.filter(({ kind }) => kind === 'p')),
);

export const getAllCampaignsTitles = createSelector(
  (state) => state.campaign.campaigns,
  (campaigns) => campaigns.map((campaign) => campaign.title),
);

const allAudiences = [{ filter: AudienceFilter.all }];

export const getAudiences = (state) => {
  const { audiences, audienceSummary } = getEditCampaignValues(state);
  if (audienceSummary === AudienceSummary.all) {
    return allAudiences;
  }
  if (audienceSummary === AudienceSummary.segmented) {
    return audiences.filter(({ filter }) => filter !== AudienceSummary.all);
  }

  return audiences;
};

export const getAudienceSummary = createSelector(
  getEditCampaignValues,
  (campaign) => campaign.audienceSummary || AudienceSummary.all,
);

const getAudiencesFilter = (state, filterName) =>
  getAudiences(state).find(({ filter }) => filter === filterName);
export const getUnusedItems = (items, used) =>
  items.filter(([id]) => !used.includes(id));
export const getDropdownItems = (items) =>
  items.map(([id, name]) => ({ id, name, selected: false }));

const searchCompareOptions = {
  usage: 'search',
  sensitivity: 'base',
  ignorePunctuation: true,
};
const searchCompareSplitter = /[\s,_.-]+/;
const searchCompareLanguage = 'pt-br';

const searchLocaleStartsWith = (value, reference) =>
  value
    .substring(0, reference.length)
    .localeCompare(reference, searchCompareLanguage, searchCompareOptions) ===
  0;

export const searchMatches = (value, reference) =>
  value
    .split(searchCompareSplitter)
    .some((part) => searchLocaleStartsWith(part, reference));

export const getSearchedItems = (items, word) => {
  if (!word) {
    return items;
  }
  const lowerCaseWord = word.toLowerCase();
  return items.filter(({ name }) => searchMatches(name, lowerCaseWord));
};

const cidsPartialMatchesLimit = 20;

// nothing to search, get first cidsPartialMatchesLimit that were not used (selected) yet
const getAvailableFilterCIDNoSearch = (used) => {
  const firstMatches = [];
  const { ordered } = cids;
  for (
    let i = 0;
    i < ordered.length && firstMatches.length < cidsPartialMatchesLimit;
    i += 1
  ) {
    const cid = ordered[i];
    const [id] = cid;
    if (!used.includes(id)) {
      firstMatches.push(cid);
    }
  }
  return firstMatches;
};

const addIterableElementsToSet = (iterable, destinationSet) =>
  iterable.forEach((value) => destinationSet.add(value));

const addAllPartialCollapsedIndexes = (partialIndexes, collapsedEntries) => {
  collapsedEntries.forEach(([collapsedText, collapsedValue]) => {
    if (collapsedText.endsWith('$')) {
      // contains indexes
      addIterableElementsToSet(collapsedValue, partialIndexes);
    } else {
      // nested objects, recursion
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      addAllPartialIndexes(partialIndexes, collapsedValue, false);
    }
  });
};

// recursively walk bucket and add all indexes to partial set
// if skipExactTermination == true, the '$' is skipped, usually
// because it was explicitly handled outside of this function
const addAllPartialIndexes = (partialIndexes, bucket, skipExactTermination) =>
  Object.entries(bucket).forEach(([key, value]) => {
    if (!skipExactTermination || key !== '$') {
      if (key === '_') {
        // collapsed entries array
        addAllPartialCollapsedIndexes(partialIndexes, value);
      } else if (key.endsWith('$')) {
        // contains the indexes
        addIterableElementsToSet(value, partialIndexes);
      } else {
        // nested objects, recursion
        addAllPartialIndexes(partialIndexes, value, false);
      }
    }
  });

const addCIDBucketIndexes = (results, bucket) => {
  const { $: finalIndexes } = bucket;
  if (finalIndexes) {
    addIterableElementsToSet(finalIndexes, results.exact);
  }
  addAllPartialIndexes(results.partial, bucket, true);
};

const addCIDTermPartialCollapsedPrefixMatch = (
  results,
  partialTermStr,
  collapsedText,
  collapsedValue,
) => {
  if (collapsedText.endsWith('$')) {
    // contains the indexes
    if (collapsedText.length === partialTermStr.length + 1) {
      addIterableElementsToSet(collapsedValue, results.exact);
    } else {
      addIterableElementsToSet(collapsedValue, results.partial);
    }
  } else {
    // nested objects, recursion
    addCIDBucketIndexes(results, collapsedValue);
  }
};

const findCIDTermPartialCollapsedIndexes = (
  results,
  collapsedEntries,
  partialTerm,
) => {
  const partialTermStr = partialTerm.join('');
  const { length: partialTermLength } = partialTermStr;

  collapsedEntries.forEach(([collapsedText, collapsedValue]) => {
    const { length: collapsedTextLength } = collapsedText;

    if (partialTermLength <= collapsedTextLength) {
      if (collapsedText.startsWith(partialTermStr)) {
        addCIDTermPartialCollapsedPrefixMatch(
          results,
          partialTermStr,
          collapsedText,
          collapsedValue,
        );
      }
    } else if (!collapsedText.endsWith('$')) {
      // collapsed is short, but contains nested objects. Must do recursion
      if (partialTermStr.startsWith(collapsedText)) {
        const rest = partialTermStr.substring(collapsedTextLength);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        findCIDTermPartialIndexes(results, collapsedValue, Array.from(rest));
      }
    }
  });
};

const findCIDTermPartialIndexes = (results, base, partialTerm) => {
  const [first] = partialTerm;
  const bucket = base[first];

  if (bucket) {
    if (partialTerm.length > 1) {
      findCIDTermPartialIndexes(results, bucket, partialTerm.slice(1));
      return;
    }

    addCIDBucketIndexes(results, bucket);
    return;
  }

  const { _: collapsedEntries } = base;
  if (!collapsedEntries) {
    return;
  }

  findCIDTermPartialCollapsedIndexes(results, collapsedEntries, partialTerm);
};

export const findCIDTermIndexes = (term) => {
  const { lookupTable } = cids;
  const results = { exact: new Set(), partial: new Set() };

  findCIDTermPartialIndexes(results, lookupTable, Array.from(term));

  return results;
};

export const findCIDIndexes = (terms) => {
  const exact = new Set();
  const partial = new Set();
  terms.forEach((term) => {
    const termResults = findCIDTermIndexes(term);
    addIterableElementsToSet(termResults.exact, exact);
    addIterableElementsToSet(termResults.partial, partial);
  });
  return { exact, partial };
};

// there are fewer lookup terms than cids, then use the cids.lookup structure:
// {[termFirstChar]: [ termLength, [[term1, [location1, location2], ...]]]}
const getAvailableFilterCIDWithSearch = (used, terms) => {
  const usedSet = new Set(used);
  const { ordered } = cids;
  const { exact, partial } = findCIDIndexes(terms);
  const exactIndexes = Array.from(exact)
    .filter((index) => !usedSet.has(ordered[index][0]))
    .sort();
  const partialIndexes = Array.from(partial)
    .filter((index) => !exact.has(index) && !usedSet.has(ordered[index][0]))
    .sort()
    .slice(0, cidsPartialMatchesLimit);
  return exactIndexes.concat(partialIndexes).map((index) => ordered[index]);
};

// CID is a special case as it's over 14k entries, we're never returning all the elements
// otherwise React and the browser will have a hard time rendering.
// We should just return the first ${cidsPartialMatchesLimit} elements,
// thus we can't use the helpers
export const getAvailableFilterCID = createSelector(
  getFilterCIDSearchState,
  (state) => getAudiencesFilter(state, AudienceFilter.cid),
  (searchValue, filterCID) => {
    let firstMatches;
    const lowerCaseWord = searchValue.toLowerCase();
    const used = (filterCID || {}).arguments || [];
    const terms = cids.normalizeAndSplitWords(lowerCaseWord);
    if (!terms.length) {
      firstMatches = getAvailableFilterCIDNoSearch(used);
    } else {
      firstMatches = getAvailableFilterCIDWithSearch(used, terms);
    }
    return getDropdownItems(firstMatches);
  },
);

export const getAllAvailableFilterSeenBy = createSelector(
  (state) => getAudiencesFilter(state, AudienceFilter.seenBy),
  getAllPhysicians,
  (filterSeenBy, { ordered }) => {
    if (!filterSeenBy || !filterSeenBy.arguments) {
      return getDropdownItems(ordered);
    }
    return getDropdownItems(getUnusedItems(ordered, filterSeenBy.arguments));
  },
);

export const getAvailableFilterSeenBy = createSelector(
  getFilterSeenBySearchState,
  getAllAvailableFilterSeenBy,
  (searchValue, availableFilterSeenBy) =>
    getSearchedItems(availableFilterSeenBy, searchValue),
);

export const getAllAvailableFilterPatientTag = createSelector(
  (state) => getAudiencesFilter(state, AudienceFilter.patientTag),
  getAllPatientTags,
  (filterPatientTag, { ordered }) => {
    if (!filterPatientTag || !filterPatientTag.arguments) {
      return getDropdownItems(ordered);
    }
    return getDropdownItems(
      getUnusedItems(ordered, filterPatientTag.arguments),
    );
  },
);

export const getAvailableFilterPatientTag = createSelector(
  getFilterPatientTagSearchState,
  getAllAvailableFilterPatientTag,
  (searchValue, availableFilterPatientTag) =>
    getSearchedItems(availableFilterPatientTag, searchValue),
);

export const getAllProcedures = createSelector(getProcedures, (procedures) =>
  getOrderedAndMap(procedures),
);

export const getAllAvailableFilterProcedure = createSelector(
  (state) => getAudiencesFilter(state, AudienceFilter.procedure),
  getAllProcedures,
  (filterProcedure, { ordered }) => {
    if (!filterProcedure || !filterProcedure.arguments) {
      return getDropdownItems(ordered);
    }
    return getDropdownItems(getUnusedItems(ordered, filterProcedure.arguments));
  },
);

export const getAvailableFilterProcedure = createSelector(
  getFilterProcedureSearchState,
  getAllAvailableFilterProcedure,
  (searchValue, availableFilterProcedure) =>
    getSearchedItems(availableFilterProcedure, searchValue),
);

export const getAvailableAddFilters = createSelector(
  getAudiences,
  (audiences) => {
    if (!audiences) {
      return getDropdownItems(sortedAddFilters);
    }
    const usedFilters = Array.from(
      new Set(audiences.map(({ filter }) => filter)),
    );
    return getDropdownItems(getUnusedItems(sortedAddFilters, usedFilters));
  },
);

export const getActiveCard = (state) => state.campaign.activeCard;

const allGenders = ['m', 'f'];
const allAgeTypes = ['ge', 'gt', 'le', 'lt', 'eq'];
const filterValidator = {
  [AudienceFilter.all]: () => true,
  [AudienceFilter.cid]: ({ arguments: args }) => !!args && args.length > 0,
  [AudienceFilter.seenBy]: ({ arguments: args }) => !!args && args.length > 0,
  [AudienceFilter.patientTag]: ({ arguments: args }) =>
    !!args && args.length > 0,
  [AudienceFilter.procedure]: ({ arguments: args }) =>
    !!args && args.length > 0,
  [AudienceFilter.gender]: ({ arguments: args }) =>
    allGenders.indexOf(args) >= 0,
  [AudienceFilter.age]: ({ arguments: args }) =>
    !!args && allAgeTypes.indexOf(args.type) >= 0 && args.value > 0,
};

const validateAudience = (audience) => {
  const validator = filterValidator[audience.filter];
  if (!validator) {
    return true;
  }
  return validator(audience);
};

export const isAudienceValid = createSelector(
  getAudiences,
  getEditCampaignValues,
  (audiences, summary) => {
    if (summary) {
      if (summary.audienceSummary === AudienceSummary.all) {
        return true;
      }
      const isAudiencesValid =
        summary.audienceSummary === AudienceSummary.segmented &&
        audiences.length > 0;
      if (isAudiencesValid) {
        return !!audiences.every(validateAudience);
      }
    }
    return false;
  },
);

export const getAudienceCardStatus = createSelector(
  isAudienceValid,
  (isCardValid) => (isCardValid ? STATUS.Success : STATUS.Alert),
);

export const splitter = ({ scheduleDate, scheduleTime }) => {
  const [day, month, year] = scheduleDate
    .split('/')
    .map((n) => parseInt(n, 10));
  const [hours, minutes] = scheduleTime.split(':').map((n) => parseInt(n, 10));
  return {
    year,
    month,
    day,
    hours,
    minutes,
  };
};

export const getDateScheduleEmail = createSelector(
  getEditCampaignValues,
  (campaign) => {
    if (!campaign.scheduleDate || !campaign.scheduleTime) {
      return undefined;
    }

    const { year, month, day, hours, minutes } = splitter(campaign);
    const givenDate = new Date(year, month - 1, day, hours, minutes);
    const now = new Date();
    if (now.getTime() < givenDate.getTime()) {
      if (
        givenDate.getFullYear() === year &&
        givenDate.getMonth() === month - 1 &&
        givenDate.getDate() === day &&
        givenDate.getHours() === hours &&
        givenDate.getMinutes() === minutes
      ) {
        return givenDate;
      }
    }
    return undefined;
  },
);

export const getFinalizeCardStatus = createSelector(
  getEditCampaignValues,
  getDateScheduleEmail,
  (schedule, dateSchedule) => {
    if (schedule.status !== CampaignStatus.scheduled) {
      return STATUS.Success;
    }
    return dateSchedule ? STATUS.Success : STATUS.Alert;
  },
);

export const getInitialFormData = createSelector(
  getDetailsCampaigns,
  getUserProfiles,
  (campaign, userProfile) => {
    const scheduleFields = {};
    const audienceFields = {};

    if (!campaign) {
      return {};
    }

    const { audiences, schedule, mail } = campaign;
    if (schedule) {
      const date = moment(schedule);
      scheduleFields.scheduleDate = date.format('DD/MM/YYYY');
      scheduleFields.scheduleTime = date.format('HH:mm');
    }

    if (
      !audiences ||
      audiences.length === 0 ||
      audiences[0].filter === AudienceFilter.all
    ) {
      audienceFields.audienceSummary = AudienceSummary.all;
    } else {
      audienceFields.audienceSummary = AudienceSummary.segmented;
    }

    const senderOverride = {};
    if (userProfile && userProfile.length) {
      const firstProfile = userProfile[0];
      const { email, name } = mail.sender;
      if (!email) {
        senderOverride.email = firstProfile.email;
      }
      if (!name) {
        senderOverride.name = firstProfile.name;
      }
    }

    return {
      ...campaign,
      ...audienceFields,
      ...scheduleFields,
      mail: { ...mail, sender: { ...mail.sender, ...senderOverride } },
    };
  },
);

const emptyMail = { body: '', draftJSState: {} };

export const getInitialEditor = createSelector(
  getDetailsCampaigns,
  (campaign = { mail: emptyMail }) => {
    const { mail = emptyMail } = campaign;

    if (Object.keys(mail.draftJSState).length) {
      const { blocks } = mail.draftJSState;
      if (blocks.length) {
        return EditorState.createWithContent(
          convertFromRaw(mail.draftJSState),
          decorator,
        );
      }
    }
    if (!mail.body) {
      return EditorState.createEmpty(decorator);
    }
    const blocksFromHTML = convertFromHTML(
      mail.body,
      getSafeBodyFromHTML,
      blockRender,
    );
    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );

    return EditorState.createWithContent(content, decorator);
  },
);

const emptyStats = {
  sent: 0,
  opened: 0,
  clicked: 0,
};

export const getCampaignListForDisplay = createSelector(
  (state) => state.campaign.campaigns,
  getCampaignSearchTerms,
  (campaigns, searchTerms) => {
    if (!campaigns) {
      return [];
    }

    const filtered = !searchTerms
      ? campaigns
      : campaigns.filter(
          ({ title }) => title.toLocaleLowerCase().indexOf(searchTerms) >= 0,
        );

    return filtered.map(
      ({
        id,
        title,
        status = 'draft',
        stats: { sent, opened, clicked } = emptyStats,
      }) => ({
        id,
        title,
        status,
        sent: ifSent(status, sent.toString(), '-'),
        clicked: ifSent(status, asPercentage(clicked, sent), '-'),
        opened: ifSent(status, asPercentage(opened, sent), '-'),
      }),
    );
  },
);

export const getAllSelected = createSelector(
  (state) => state.campaign.campaigns,
  getSelectedCampaigns,
  (campaigns, selected) => campaigns.every((item) => selected.has(item.id)),
);

const getAddCampaignModal = formValueSelector(FORM_ADD_CAMPAIGN_MODAL);
export const getAddCampaignModalTitle = (state) =>
  getAddCampaignModal(state, 'title') || '';

export const getEditor = (state) => state.campaign.editor;
export const getEditorState = (state) => getEditor(state).editorState;
export const getEditorSelection = (state) =>
  getEditorState(state).getSelection();
export const getEditorSelectionAnchorKey = (state) =>
  getEditorSelection(state).getAnchorKey();
export const getCurrentContentEditor = (state) =>
  getEditorState(state).getCurrentContent();
export const getEditorStateHTML = (state) =>
  stateToHTML(getCurrentContentEditor(state), optionsExport);
export const getCurrentBlock = (state) =>
  getCurrentContentEditor(state).getBlockForKey(
    getEditorSelectionAnchorKey(state),
  );
export const getCurrentEntityKey = (state) =>
  getCurrentBlock(state).getEntityAt(
    getEditorSelection(state).getStartOffset(),
  );
export const getStartOffset = (state) =>
  getEditorSelection(state).getStartOffset();
export const getEndOffset = (state) => getEditorSelection(state).getEndOffset();
export const getCurrentInlineStyles = (state) =>
  getEditorState(state).getCurrentInlineStyle();

export const getCurrentSelectionText = createSelector(
  (state) => getCurrentBlock(state).getText(),
  getStartOffset,
  getEndOffset,
  (text, startOffset, endOffset) => text.slice(startOffset, endOffset),
);

export const getCurrentEntity = (state) => {
  const entityKey = getCurrentEntityKey(state);
  return entityKey && getCurrentContentEditor(state).getEntity(entityKey);
};

const checkEntityRangeValid = ({ offset, length, key, keySelection }) => {
  if (offset <= keySelection && offset + length >= keySelection) {
    return { offset, length, key };
  }
  return undefined;
};

const getCurrentEntityDecoratedText = createSelector(
  getEditorSelection,
  getCurrentContentEditor,
  getCurrentBlock,
  (selection, currentContent, currentBlock) => {
    const block = convertToRaw(currentContent).blocks;
    const keySelection = selection.getStartOffset();

    let lastValidEntityRange = {};
    block.forEach(({ entityRanges }) => {
      entityRanges.forEach((entityRange) => {
        if (checkEntityRangeValid({ ...entityRange, keySelection })) {
          lastValidEntityRange = entityRange;
        }
      });
    });

    const { offset, length } = lastValidEntityRange;
    const end = offset + length;
    const text = currentBlock.getText().slice(offset, end);

    return { start: offset, end, text };
  },
);

export const getCurrentEntityDecoratedMerge = createSelector(
  getEditorSelection,
  getCurrentEntityDecoratedText,
  (selection, entityDecoratedText) => {
    const { start, end } = entityDecoratedText;
    return selection.merge({ anchorOffset: start, focusOffset: end });
  },
);

export const getEditorLink = createSelector(
  getCurrentEntity,
  getCurrentEntityDecoratedText,
  getCurrentSelectionText,
  (currentEntity, currentDecoratedText, currentSelection) => {
    if (currentEntity) {
      const { url } = currentEntity.getData();
      const { text } = currentDecoratedText;
      return { textSelected: text, inputUrl: url };
    }
    return { textSelected: currentSelection, inputUrl: '' };
  },
);

export const getEditorLinkModalInitialValues = (state) => {
  const { textSelected, inputUrl } = getEditorLink(state);
  return { textSelected, inputUrl };
};

export const getEditorButtonModalInitialValues = (state) => {
  const { textSelected, inputUrl } = getEditorLink(state);
  return { textSelected, inputUrl };
};

export const getEditorImageModalInitialValues = createSelector(
  getCurrentEntity,
  (currentEntity) => {
    if (currentEntity) {
      const { src } = currentEntity.getData();
      return { inputUrl: src };
    }
    return { inputUrl: '' };
  },
);

export const getEditorOptionsText = createSelector(
  (state) => getEditor(state).optionsFormatText,
  getCurrentInlineStyles,
  (editorOptionsText, currentInlineStyles) => {
    let selected = typeStyleEditor.normalID;

    if (currentInlineStyles.has(typeStyleEditor.title)) {
      selected = typeStyleEditor.titleID;
    }

    return editorOptionsText.map((item) => ({
      ...item,
      selected: item.id === selected,
    }));
  },
);

export const getEditorUpdatedMail = (state) => {
  const formFields = getEditCampaignValues(state);
  const body = getEditorStateHTML(state);
  const currentContent = getCurrentContentEditor(state);
  const draftJSState = convertToRaw(currentContent);
  return {
    ...formFields.mail,
    body,
    draftJSState,
  };
};

export const getMailCardStatus = createSelector(
  getCurrentContentEditor,
  getEditCampaignValues,
  (editor, header) => {
    if (editor && header.mail) {
      if (editor.hasText()) {
        if (
          header.mail.sender.email &&
          header.mail.sender.name &&
          header.mail.subject
        ) {
          return STATUS.Success;
        }
      }
    }
    return STATUS.Alert;
  },
);

export const getCurrentInfo = createSelector(
  getCurrentBlock,
  getCurrentInlineStyles,
  getCurrentEntity,
  getCurrentSelectionText,
  getCurrentEntityDecoratedText,
  (block, inlineStyles, entity, selectionText, entityDecoratedText) => ({
    block,
    blockType: block && block.getType(),
    inlineStyles,
    entity,
    entityType: entity && entity.getType(),
    entityData: entity && entity.getData(),
    selectionText,
    entityDecoratedText,
  }),
);

export const isFormValid = createSelector(
  getFinalizeCardStatus,
  getAudienceCardStatus,
  getMailCardStatus,
  (isFinalizeCardValid, isAudienceCardValid, isMailCardValid) => {
    if (
      isFinalizeCardValid === STATUS.Alert ||
      isAudienceCardValid === STATUS.Alert ||
      isMailCardValid === STATUS.Alert
    ) {
      return false;
    }

    return true;
  },
);

export const getInitialMail = createSelector(getUserProfiles, (userProfile) => {
  if (userProfile && userProfile.length) {
    const firstProfile = userProfile[0];
    const sender = {
      email: firstProfile.email,
      name: firstProfile.name,
    };
    return { ...emptyMail, sender };
  }

  return emptyMail;
});

export const getUserInfo = (state) => ({
  physicianId: state.userInfo.userData.physicianId,
  email: state.userInfo.userData.userEmail,
});
