import { Autocomplete, TextField } from '@iclinic/design-system';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAutocomplete } from 'features/tissInvoicing/state/autocomplete/selectors';
import { AppointmentGuideResultValues } from 'features/tissInvoicing/types/appointmentGuide';
import { actions as autocompleteActions } from 'features/tissInvoicing/state/autocomplete';
import { actions as appointmentGuideActions } from 'features/tissInvoicing/state/appointmentGuide';
import { AutocompleteInput } from 'features/tissInvoicing/types';
import { getAppointmentGuideState } from 'features/tissInvoicing/state/appointmentGuide/selectors';

export default ({
  label = '7 - Nome',
  required = false,
}: {
  label?: string;
  required?: boolean;
}) => {
  const dispatch = useDispatch();

  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext<AppointmentGuideResultValues>();

  const { patients } = useSelector(getAutocomplete);

  const { patient } = useSelector(getAppointmentGuideState);

  useEffect(() => {
    setFieldValue('patient_sus_code', patient?.cns ?? values?.patient_sus_code);
  }, [patient?.cns, values?.patient_sus_code]);

  const handleChange = (value: AutocompleteInput) => {
    setFieldValue('patient_civil_name', value.name);
    setFieldValue('patient_id', value.id);

    if (value.id) {
      dispatch(
        appointmentGuideActions.fetchPatientData({
          patientId: value.id,
        }),
      );
    }
  };

  const handleOpen = () => {
    dispatch(autocompleteActions.fetchPatientsOptions({}));
  };

  const findValuePatient = () =>
    patients.find((item) => item.name === values.patient_civil_name);

  return (
    <Autocomplete
      id="patient_civil_name"
      freeSolo
      fullWidth
      options={patients}
      getOptionLabel={(option: any) => option.name}
      value={findValuePatient()}
      clearOnBlur
      onBlur={handleBlur}
      onChange={(_, value: any) => handleChange(value)}
      onOpen={handleOpen}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name="patient_civil_name"
          data-ga="patient_civil_name"
          required={required}
          error={!!errors.patient_civil_name}
          helperText={touched.patient_civil_name && errors.patient_civil_name}
        />
      )}
    />
  );
};
