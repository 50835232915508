import React from 'react';

import { Body, Dialog, DialogHeader } from '@iclinic/design-system';
import AudioInputList from '../AudioInputList';
import AudioOutputList from '../AudioOutputList';
import VideoInputList from '../VideoInputList';
import {
  deviceSettings,
  buttonsTitle,
} from 'features/video-conference/constants';
import { DialogContent, ListSection } from './styles';

export default function DeviceSelectionDialog({
  open,
  onClose,
  location = '',
}: {
  open: boolean;
  onClose: () => void;
  location?: string;
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogHeader title={buttonsTitle.SETTINGS} onClose={onClose} />
      <DialogContent>
        <ListSection>
          <Body variant="xs" bold>
            {deviceSettings.AUDIO_INPUT_TITLE}
          </Body>
          <Body variant="xs">{deviceSettings.AUDIO_INPUT_INFO}</Body>
          <AudioInputList location={location} />
        </ListSection>
        <ListSection>
          <Body variant="xs" bold>
            {deviceSettings.AUDIO_OUTPUT_TITLE}
          </Body>
          <Body variant="xs">{deviceSettings.AUDIO_OUTPUT_INFO}</Body>
          <AudioOutputList location={location} />
        </ListSection>
        <ListSection>
          <Body variant="xs" bold>
            {deviceSettings.VIDEO_INPUT_TITLE}
          </Body>
          <Body variant="xs">{deviceSettings.VIDEO_INPUT_INFO}</Body>
          <VideoInputList location={location} />
        </ListSection>
      </DialogContent>
    </Dialog>
  );
}
