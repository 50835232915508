import { MaterialUICore, tokens } from '@iclinic/design-system';

import { CenterBox } from 'features/tissInvoicing/components/ui/Flexbox';

const { styled } = MaterialUICore;

export const LoaderContainer = styled(CenterBox)({
  flexDirection: 'column',
  padding: tokens.spacing.base,
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  background: 'rgba(255,255,255,0.6)',
  zIndex: 1,
});
