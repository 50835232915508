import {
  MaterialUICore,
  tokens,
  Heading,
  Paper as DSPaper,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { color, spacing } = tokens;

interface Colors {
  none: string;
  neutral: string;
  primary: string;
  success: string;
  error: string;
}

const colors: Colors = {
  none: color.neutral[700],
  neutral: color.neutral[300],
  primary: color.primary.light,
  success: color.feedback.success.main,
  error: color.feedback.error.main,
};

export const Paper = styled(DSPaper)(() => ({
  marginBottom: spacing.md,
  padding: spacing.sm,

  '& svg': {
    fontSize: '20px',
  },
}));

export const WrapContent = styled('div')(({ textColor, variant }) => ({
  margin: `0 ${spacing.nano}`,
  textAlign: 'center',
  '& h1, *': {
    color:
      variant === 0 ? color.neutral[700] : colors[textColor as keyof Colors],
  },
}));

export const Quantity = styled(Heading)(() => ({
  marginBottom: spacing.base,
}));
