import {
  MaterialUICore,
  FloatingActionButton as FloatingActionButtonDS,
  axiomTokens,
} from '@iclinic/design-system';

const {
  styled,
  ListItem: ListemMUI,
  ListItemIcon: ListItemIconMUI,
} = MaterialUICore;

export const FloatingActionButton = styled(FloatingActionButtonDS)({
  bottom: '76px',
  position: 'fixed',
  right: '20px',
});

export const ListContainer = styled('div')({
  backgroundColor: axiomTokens.color.background[1],
  borderRadius: axiomTokens.border.radius.sm,
  border: axiomTokens.color.border.subtle,

  boxShadow: axiomTokens.shadow.level[3],
});

export const ListItem = styled(ListemMUI)({
  padding: `${axiomTokens.spacing[4]} ${axiomTokens.spacing[3]}`,

  '& .MuiListItemText-root': {
    margin: 0,
  },
});

export const ListItemIcon = styled(ListItemIconMUI)({
  marginRight: axiomTokens.spacing[2],
  minWidth: 'initial',
});
