import { PlansSuccess } from '../../services/types';
import { RequestStatus } from 'shared/constants/State';
import { IStoreState } from 'state/rootReducer';

export const getPlan = ({ checkout }: IStoreState): PlansSuccess | undefined =>
  checkout.plans.products;

export const isFetchingPlans = ({ checkout }: IStoreState): boolean =>
  checkout.plans.status === RequestStatus.Pending;

export const getPlanStatus = ({ checkout }: IStoreState) =>
  checkout.plans.status;

export const getMostSeller = ({ checkout }: IStoreState) =>
  checkout.plans.mostSeller;

export const getBaseProducts = ({ checkout }: IStoreState) =>
  checkout.plans.products?.plans || [];

export const getAddons = ({ checkout }: IStoreState) =>
  checkout.plans.products?.addons || [];

export const getClinicId = ({ userInfo }: IStoreState) =>
  userInfo.userData.activeClinicId;

export const getRecurrence = ({ checkout }: IStoreState) =>
  checkout.plans.isAnnual;

export const getSelectedPlan = ({ checkout }: IStoreState) =>
  checkout.plans.selectedPlan;

export const getPhysicians = ({ checkout }: IStoreState) =>
  checkout.plans.physicians || [];

export const getSelectedAddons = ({ checkout }: IStoreState) =>
  checkout.plans.selectedAddons || [];

export const getPhysicianByAddon = ({ checkout }: IStoreState) =>
  checkout.plans.physiciansByAddon;

export const getSubscriptionStatus = ({ checkout }: IStoreState) =>
  checkout.plans.subscriptionStatus;

export const isSubscribing = ({ checkout }: IStoreState) =>
  checkout.plans.subscriptionStatus === RequestStatus.Pending;

export const getFunctionalities = ({ checkout }: IStoreState) =>
  checkout.plans.products?.functionalities || [];

export const isOffCanvasOpen = ({ checkout }: IStoreState): boolean =>
  !!checkout.plans.offCanvasOpen;

export const getFlipCard = ({ checkout }: IStoreState) =>
  checkout.plans.flipCard;

export const getSubscriptionStepMapper = ({ checkout }: IStoreState) =>
  checkout.plans.subscriptionStepMapper;

export const getCurrentSubscriptionStep = ({ checkout }: IStoreState) =>
  checkout.plans.currentSubscriptionStep;

export const getUpdatePermissionUrl = ({ shared }: IStoreState) =>
  shared.updatePermissionsUrl;

export const getSuccessUrl = ({ subscription }: IStoreState) =>
  subscription.successUrl;

export const getErrorsMessage = ({ checkout }: IStoreState) =>
  checkout.plans.errorsMessage || [];
