import {
  FileSignatureResponseCompletedState,
  FileSignatureAttachment,
} from 'state/records/signature/types';

export const removeUrlParameter = (url: string, paramKey: string) => {
  const baseUrl = new URL(url);
  baseUrl.searchParams.delete(paramKey);
  return baseUrl;
};

export const changeUrl = (url: URL, title: string) => {
  window.history.pushState('data', 'Title', url.pathname);
  document.title = title;
};

export const normalizeAttachment = (
  signedPdf: FileSignatureResponseCompletedState[],
) =>
  signedPdf.map((file: FileSignatureResponseCompletedState) => ({
    file_name: `${file.document_type}-${file.document_id}`,
    url: file.signed_pdf_url,
    document_type: file.document_type,
    selected: false,
  }));

export const normalizeSendEmail = (signedPdf: FileSignatureAttachment[]) =>
  signedPdf
    .filter((file: FileSignatureAttachment) => file.selected)
    .map((file: FileSignatureAttachment) => ({
      file_name: `${file.file_name}.pdf`,
      url: file.url,
    }));
