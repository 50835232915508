import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Body, Button, Heading } from '@iclinic/design-system';

import { formatDate } from 'shared/utils/date';
import {
  getPaymentSlipExpirationDate,
  getRequestReleaseTrustData,
} from '../../../state/selectors';
import { ModalType } from '../../../state/types';
import { toggleCloseModal, toggleOpenModal } from '../../../state';
import { ModalConfirmRequest } from './ModalConfirmRequest';
import { ModalSuccessRequestRelease } from './ModalSuccessRequestRelease';
import * as S from './CardRequestRelease.styles';

export const CardRequestRelease = () => {
  const dispatch = useDispatch();
  const expirationDate = useSelector(getPaymentSlipExpirationDate);
  const { isOpenModal, modalType } = useSelector(getRequestReleaseTrustData);
  const isOpenModalConfirm = isOpenModal && modalType === ModalType.CONFIRM;
  const isOpenModalSuccess = isOpenModal && modalType === ModalType.SUCCESS;

  const onClickRequestOpenHandler = () => {
    dispatch(toggleOpenModal(ModalType.CONFIRM));
  };

  const onCloseRequestHandler = () => {
    dispatch(toggleCloseModal());
  };

  return (
    <>
      <S.Card>
        <Heading variant="xs">Já efetuei o pagamento</Heading>

        <Body variant="xs">
          Caso já tenha efetuado o pagamento da fatura com{' '}
          <strong>
            vencimento em: {expirationDate ? formatDate(expirationDate) : ''},
          </strong>{' '}
          solicite a liberação da conta e siga utilizando o Afya iClinic
          normalmente.
        </Body>

        <S.ActionsWrapper>
          <Button
            color="secondary"
            onClick={onClickRequestOpenHandler}
            data-sp="payment_slip_request_release_trust_btn_click"
            data-ga="payment_slip_request_release_trust_btn_click"
          >
            Solicitar liberação
          </Button>
        </S.ActionsWrapper>
      </S.Card>

      <ModalConfirmRequest
        open={isOpenModalConfirm}
        onClose={onCloseRequestHandler}
      />

      <ModalSuccessRequestRelease open={isOpenModalSuccess} />
    </>
  );
};
