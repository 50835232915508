import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  EditLotPayloadRequest,
  FetchGuidesQuery,
  FetchGuidesRequestParams,
  Guide,
  Lot,
  LotConfig,
  CreateLotConfig,
  SnackbarProps,
  GuidesFilterOptions,
  FetchXMLFilter,
  LotViewActionKeys,
  GenericObjectDescription,
  NewLotConfigDataResponse,
  SimplifiedGuides,
  LotesFilterOptions,
  FetchLotListQuery,
  FetchLotListRequestParams,
  GuideTypeOptions,
} from 'features/tissInvoicing/types';
import { removeDuplicates } from 'features/tissInvoicing/utils/getGuidesIds';
import getSnackbarPayload from 'features/tissInvoicing/utils/getSnackbarPayload';

export type TISSLotState = {
  lotView: {
    configOptions: NewLotConfigDataResponse;
    config: LotConfig;
    guides: Guide[];
    deletedGuides: string[];
    hasMoreData: boolean;
    loading: {
      [key in LotViewActionKeys]: boolean;
    };
  };
  addGuides: {
    availableGuides: Guide[];
    selectedGuides: SimplifiedGuides[];
    simplifiedGuides: SimplifiedGuides[];
    hasSelectedAllGuides: boolean;
    hasMoreData: boolean;
    loading: boolean;
    search: {
      query: FetchGuidesQuery;
      filterOptions: GuidesFilterOptions;
      loadingPatients: boolean;
    };
  };
  listGuides: {
    availableGuides: Guide[];
    hasMoreData: boolean;
    loading: boolean;
  };
  lotData: {
    loading: boolean;
    lot: Lot;
  };
  listLot: {
    lots: Lot[];
    loading: boolean;
    hasMoreData: boolean;
    search: {
      query: FetchLotListQuery;
      filterOptions: LotesFilterOptions;
    };
    loadingInsurances: boolean;
    hasLots: boolean;
  };
  downloadXML: {
    query: {
      orderBy: FetchXMLFilter;
    };
  };
  snackbar: SnackbarProps;
};

const name = 'lot';

export const initialState: TISSLotState = {
  lotView: {
    configOptions: {
      clinic_health_insurances: [],
      physicians: [],
      clinics: [],
      versions: [],
    },
    config: {
      clinic_health_insurance_id: '',
      executant_type: null,
      executant_name: '',
      executant_cnes: '',
      executant_code: '',
      executant_code_type: '',
      next_lot_code: null,
      version: '',
    },
    guides: [],
    deletedGuides: [],
    hasMoreData: false,
    loading: {
      fetchGuides: false,
      fetchConfigOptions: false,
      createLot: false,
      patchLotData: false,
      downloadXML: false,
    },
  },
  addGuides: {
    availableGuides: [],
    selectedGuides: [],
    hasMoreData: false,
    hasSelectedAllGuides: false,
    loading: false,
    search: {
      query: {
        patient_id: 0,
        physician_id: 0,
        date_from: null,
        date_to: null,
      },
      filterOptions: {
        patients: [],
        physicians: [],
      },
      loadingPatients: false,
    },
    simplifiedGuides: [],
  },
  listGuides: {
    availableGuides: [],
    hasMoreData: false,
    loading: false,
  },
  lotData: {
    loading: false,
    lot: {} as Lot,
  },
  listLot: {
    lots: [],
    loading: true,
    hasMoreData: false,
    search: {
      query: {
        insurance_id: 0,
        professional_id: 0,
        date_from: '',
        date_to: '',
      },
      filterOptions: {
        insurances: [],
        physicians: [],
      },
    },
    loadingInsurances: false,
    hasLots: false,
  },
  downloadXML: {
    query: {
      orderBy: 'date',
    },
  },
  snackbar: getSnackbarPayload(),
};

const fetchLotList = createAction<FetchLotListRequestParams>(
  `${name}/fetchLotList`,
);
const fetchLotData = createAction<{ id: string }>(`${name}/fetchLotData`);
const fetchGuides = createAction<FetchGuidesRequestParams>(
  `${name}/fetchGuides`,
);
const fetchAvailableGuides = createAction<FetchGuidesRequestParams>(
  `${name}/fetchAvailableGuides`,
);
const fetchAvailableSimplifiedGuides = createAction<{
  id: string;
  insurance_id: number;
  guide_type?: GuideTypeOptions;
}>(`${name}/fetchAvailableSimplifiedGuides`);
const fetchLotGuides = createAction<{
  lot_id: string;
  page: number;
  ordering?: string;
}>(`${name}/fetchLotGuides`);
const fetchLotConfigOptions = createAction(`${name}/fetchLotConfigOptions`);
const fetchGuidesFilterPhysicians = createAction(
  `${name}/fetchGuidesFilterPhysicians`,
);
const fetchLotesFilterPhysicians = createAction(
  `${name}/fetchLotesFilterPhysicians`,
);
const fetchLotesFilterInsurance = createAction(
  `${name}/fetchLotesFilterInsurance`,
);
const fetchGuidesFilterPatients = createAction<{ query: string }>(
  `${name}/fetchGuidesFilterPatients`,
);
const fetchXMLFile = createAction<{
  id: string;
  lot_number: number;
  callback?: () => void;
}>(`${name}/fetchXMLFile`);

const createLot = createAction<CreateLotConfig>(`${name}/createLot`);
const patchLotData = createAction<EditLotPayloadRequest>(`${name}/editLotData`);

export const removeDuplicateGuides = (
  g: Guide[] | SimplifiedGuides[],
): Guide[] | SimplifiedGuides[] =>
  g.filter(
    (guide, index, guides) =>
      guides.findIndex((value) => value.id === guide.id) === index,
  );

export const removeDuplicateLots = (l: Lot[]): Lot[] =>
  l.filter(
    (lot, index, lots) =>
      lots.findIndex((value) => value.id === lot.id) === index,
  );

const TISSLotSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLotsListFetchStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      listLot: {
        ...state.listLot,
        loading: action.payload,
      },
    }),
    setLotList: (
      state,
      action: PayloadAction<{ lots: Lot[]; hasMoreData: boolean }>,
    ) => ({
      ...state,
      listLot: {
        ...state.listLot,
        lots: [...state.listLot.lots, ...action.payload.lots],
        hasMoreData: action.payload.hasMoreData,
      },
    }),
    setLotDataFetchStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      lotData: {
        ...state.lotData,
        loading: action.payload,
      },
    }),
    setLotData: (state, action: PayloadAction<Lot>) => ({
      ...state,
      lotData: {
        ...state.lotData,
        lot: action.payload,
      },
    }),
    setLotsClosed: (state, action: PayloadAction<boolean>) => ({
      ...state,
      lotData: {
        ...state.lotData,
        lot: {
          ...state.lotData.lot,
          is_closed: action.payload,
        },
      },
    }),
    setLotDataGuides: (state, action: PayloadAction<string[]>) => ({
      ...state,
      lotData: {
        ...state.lotData,
        lot: {
          ...state.lotData.lot,
          guides: removeDuplicates(action.payload),
        },
      },
    }),
    setLotViewLoadingStatus: (
      state,
      action: PayloadAction<{ key: LotViewActionKeys; value: boolean }>,
    ) => ({
      ...state,
      lotView: {
        ...state.lotView,
        loading: {
          ...state.lotView.loading,
          [action.payload.key]: action.payload.value,
        },
      },
    }),
    setLotConfigOptions: (
      state,
      action: PayloadAction<NewLotConfigDataResponse>,
    ) => ({
      ...state,
      lotView: {
        ...state.lotView,
        configOptions: action.payload,
      },
    }),
    setLotConfig: (state, action: PayloadAction<LotConfig>) => ({
      ...state,
      lotView: {
        ...state.lotView,
        config: action.payload,
      },
    }),
    setLotViewGuides: (
      state,
      action: PayloadAction<{
        guides: Guide[];
        hasMoreData: boolean;
      }>,
    ) => ({
      ...state,
      lotView: {
        ...state.lotView,
        guides: removeDuplicateGuides([
          ...state.lotView.guides,
          ...action.payload.guides,
        ]) as Guide[],
        hasMoreData: action.payload.hasMoreData,
      },
    }),
    setGuideToBeDeleted: (
      state,
      action: PayloadAction<{ guide: string; checked: boolean }>,
    ) => {
      const deletedGuides = [...state.lotView.deletedGuides];

      if (action.payload.checked) {
        deletedGuides.push(action.payload.guide);
      } else {
        const guideIdx = deletedGuides.indexOf(action.payload.guide);
        deletedGuides.splice(guideIdx, 1);
      }

      return {
        ...state,
        lotView: {
          ...state.lotView,
          deletedGuides: [...deletedGuides],
        },
      };
    },
    setAllGuidesToBeDeleted: (
      state,
      action: PayloadAction<{ checkedAll: boolean }>,
    ) => ({
      ...state,
      lotView: {
        ...state.lotView,
        deletedGuides: action.payload.checkedAll
          ? [...state.lotData.lot.guides]
          : [],
      },
    }),
    setLotXMLFileFilters: (
      state,
      action: PayloadAction<{ orderBy: FetchXMLFilter }>,
    ) => ({
      ...state,
      downloadXML: {
        ...state.downloadXML,
        query: action.payload,
      },
    }),

    setAvailableGuidesFetchStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      addGuides: {
        ...state.addGuides,
        loading: action.payload,
      },
    }),
    setGuidesFetchStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      listGuides: {
        ...state.listGuides,
        loading: action.payload,
      },
    }),
    setAvailableLotesFetchStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      listLot: {
        ...state.listLot,
        loading: action.payload,
      },
    }),
    setAvailableGuides: (
      state,
      action: PayloadAction<{
        guides: Guide[];
        hasMoreData: boolean;
      }>,
    ) => ({
      ...state,
      addGuides: {
        ...state.addGuides,
        availableGuides: removeDuplicateGuides(
          action.payload.guides,
        ) as Guide[],
        hasMoreData: action.payload.hasMoreData,
      },
    }),
    setGuides: (
      state,
      action: PayloadAction<{
        guides: Guide[];
        hasMoreData: boolean;
        loading: boolean;
      }>,
    ) => ({
      ...state,
      listGuides: {
        availableGuides: action.payload.guides,
        hasMoreData: action.payload.hasMoreData,
        loading: action.payload.loading,
      },
    }),
    setAvailableLotes: (
      state,
      action: PayloadAction<{
        lotes: Lot[];
        hasMoreData: boolean;
      }>,
    ) => ({
      ...state,
      listLot: {
        ...state.listLot,
        lots: removeDuplicateLots(action.payload.lotes),
        hasMoreData: action.payload.hasMoreData,
      },
    }),
    setAvailableSimplifiedGuides: (
      state,
      action: PayloadAction<SimplifiedGuides[]>,
    ) => ({
      ...state,
      addGuides: {
        ...state.addGuides,
        simplifiedGuides: removeDuplicateGuides(action.payload),
      },
    }),
    setSelectedGuides: (state, action: PayloadAction<SimplifiedGuides[]>) => ({
      ...state,
      addGuides: {
        ...state.addGuides,
        selectedGuides: removeDuplicateGuides([...action.payload]),
      },
    }),
    setAvailableGuidesQuery: (
      state,
      action: PayloadAction<FetchGuidesQuery>,
    ) => ({
      ...state,
      addGuides: {
        ...state.addGuides,
        search: {
          ...state.addGuides.search,
          query: action.payload,
        },
      },
    }),
    setFetchGuidesFilterOptions: (
      state,
      action: PayloadAction<{
        key: 'physicians' | 'patients';
        data: GenericObjectDescription[];
      }>,
    ) => ({
      ...state,
      addGuides: {
        ...state.addGuides,
        search: {
          ...state.addGuides.search,
          filterOptions: {
            ...state.addGuides.search.filterOptions,
            [action.payload.key]: action.payload.data,
          },
        },
      },
    }),
    setFetchLotesFilterOptions: (
      state,
      action: PayloadAction<{
        key: 'physicians' | 'insurances';
        data: GenericObjectDescription[];
      }>,
    ) => ({
      ...state,
      listLot: {
        ...state.listLot,
        search: {
          ...state.listLot.search,
          filterOptions: {
            ...state.listLot.search.filterOptions,
            [action.payload.key]: action.payload.data,
          },
        },
      },
    }),
    setFetchGuidesFilterPatients: (state, action: PayloadAction<boolean>) => ({
      ...state,
      addGuides: {
        ...state.addGuides,
        search: {
          ...state.addGuides.search,
          loadingPatients: action.payload,
        },
      },
    }),
    setSnackbarContent: (state, action: PayloadAction<SnackbarProps>) => ({
      ...state,
      snackbar: action.payload,
    }),
    handleDeletedGuides: (state, action: PayloadAction<string[]>) => ({
      ...state,
      lotView: {
        ...state.lotView,
        guides: state.lotView.guides.filter(
          (guide) => !action.payload.includes(guide.id),
        ),
        deletedGuides: [],
      },
    }),
    resetAddGuidesState: (state) => ({
      ...state,
      addGuides: initialState.addGuides,
    }),
    setLotsQuery: (state, action: PayloadAction<FetchLotListQuery>) => ({
      ...state,
      listLot: {
        ...state.listLot,
        search: {
          ...state.listLot.search,
          query: action.payload,
        },
      },
    }),
    setHasLotInLotList: (state) => ({
      ...state,
      listLot: {
        ...state.listLot,
        hasLots: true,
      },
    }),
  },
});

export default TISSLotSlice.reducer;
export const actions = {
  ...TISSLotSlice.actions,
  fetchLotData,
  fetchGuides,
  fetchAvailableGuides,
  fetchAvailableSimplifiedGuides,
  fetchLotList,
  fetchLotConfigOptions,
  fetchGuidesFilterPatients,
  createLot,
  patchLotData,
  fetchLotGuides,
  fetchGuidesFilterPhysicians,
  fetchXMLFile,
  fetchLotesFilterPhysicians,
  fetchLotesFilterInsurance,
};
