// External
import { EditorState, RichUtils, Modifier, AtomicBlockUtils } from 'draft-js';

// Internal
import notificationMessages from 'shared/constants/notificationMessages';
import * as types from './types';

export const fetchListCampaigns = () => ({
  type: types.FETCH_LIST.ACTION,
  payload: {
    isFetching: true,
  },
});

export const fetchListCampaignsSuccess = (campaigns) => ({
  type: types.FETCH_LIST.SUCCESS,
  payload: {
    isFetching: false,
    campaigns,
    errors: [],
  },
});

export const fetchListCampaignsFailure = (errors) => ({
  type: types.FETCH_LIST.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export const fetchUserProfiles = () => ({
  type: types.FETCH_USER_PROFILES.ACTION,
  payload: {
    userProfile: {
      isFetching: true,
    },
  },
});

export const fetchUserProfilesSuccess = (userProfiles) => ({
  type: types.FETCH_USER_PROFILES.SUCCESS,
  payload: {
    userProfile: {
      isFetching: false,
      userProfiles,
      errors: [],
    },
  },
});

export const fetchUserProfilesFailure = (errors) => ({
  type: types.FETCH_USER_PROFILES.FAILURE,
  payload: {
    userProfile: {
      isFetching: false,
      errors,
    },
  },
});

export const fetchPatientCount = (clinicId, audiences) => ({
  type: types.FETCH_PATIENT_COUNT.ACTION,
  params: {
    clinicId,
    audiences,
  },
  payload: {
    patientCount: {
      isFetching: true,
    },
  },
});

export const fetchPatientCountSuccess = (count) => ({
  type: types.FETCH_PATIENT_COUNT.SUCCESS,
  payload: {
    patientCount: {
      isFetching: false,
      count,
      errors: [],
    },
  },
});

export const fetchPatientCountFailure = (errors) => ({
  type: types.FETCH_PATIENT_COUNT.FAILURE,
  payload: {
    patientCount: {
      isFetching: false,
      errors,
    },
  },
});

export const fetchProcedures = () => ({
  type: types.FETCH_PROCEDURES.ACTION,
  payload: {
    procedures: {
      isFetching: true,
    },
  },
});

export const fetchProceduresSuccess = (procedures) => ({
  type: types.FETCH_PROCEDURES.SUCCESS,
  payload: {
    procedures: {
      isFetching: false,
      procedures,
      errors: [],
    },
  },
});

export const fetchProceduresFailure = (errors) => ({
  type: types.FETCH_PROCEDURES.FAILURE,
  payload: {
    procedures: {
      isFetching: false,
      errors,
    },
  },
});

export const fetchPatientTags = (clinicId) => ({
  type: types.FETCH_PATIENT_TAGS.ACTION,
  params: {
    clinicId,
  },
  payload: {
    patientTags: {
      isFetching: true,
    },
  },
});

export const fetchPatientTagsSuccess = (patientTags, params) => ({
  type: types.FETCH_PATIENT_TAGS.SUCCESS,
  params,
  payload: {
    patientTags: {
      isFetching: false,
      patientTags,
      errors: [],
    },
  },
});

export const fetchPatientTagsFailure = (errors, params) => ({
  type: types.FETCH_PATIENT_TAGS.FAILURE,
  params,
  payload: {
    patientTags: {
      isFetching: false,
      errors,
    },
  },
});

export const fetchCampaignDetailsClear = () => ({
  type: types.FETCH_DETAILS_CLEAR.ACTION,
  payload: {
    campaignDetails: {
      isFetching: false,
      campaign: undefined,
      errors: [],
    },
  },
});

export const fetchCampaignDetails = (campaignId) => ({
  type: types.FETCH_DETAILS.ACTION,
  params: {
    campaignId,
  },
  payload: {
    campaignDetails: {
      isFetching: true,
    },
  },
});

export const fetchCampaignDetailsSuccess = (campaign, params) => ({
  type: types.FETCH_DETAILS.SUCCESS,
  params,
  payload: {
    campaignDetails: {
      isFetching: false,
      campaign,
      errors: [],
    },
  },
});

export const fetchCampaignDetailsFailure = (errors, params) => ({
  type: types.FETCH_DETAILS.FAILURE,
  params,
  payload: {
    campaignDetails: {
      isFetching: false,
      errors,
    },
  },
});

export const fetchCreateCampaign = ({
  title,
  status,
  schedule,
  audiences,
  mail,
}) => ({
  type: types.FETCH_CREATE.ACTION,
  params: {
    title,
    status,
    schedule,
    audiences,
    mail,
  },
  payload: {
    campaignCreate: {
      isFetching: true,
    },
  },
});

export const fetchCreateCampaignSuccess = (campaign, params) => ({
  type: types.FETCH_CREATE.SUCCESS,
  params,
  payload: {
    campaignCreate: {
      isFetching: false,
      campaign,
      errors: [],
    },
  },
});

export const fetchCreateCampaignFailure = (errors, params) => ({
  type: types.FETCH_CREATE.FAILURE,
  params,
  payload: {
    campaignCreate: {
      isFetching: false,
      errors,
    },
  },
});

export const fetchUpdateCampaign = (campaign) => ({
  type: types.FETCH_UPDATE.ACTION,
  params: campaign,
  payload: {
    campaignUpdate: {
      isFetching: true,
    },
  },
});

export const fetchUpdateCampaignSuccess = (campaign, params, notification) => ({
  type: types.FETCH_UPDATE.SUCCESS,
  params,
  payload: {
    campaignUpdate: {
      isFetching: false,
      campaign,
      errors: [],
      notifications: notification,
    },
  },
});

export const fetchUpdateCampaignFailure = (errors, params) => ({
  type: types.FETCH_UPDATE.FAILURE,
  params,
  payload: {
    campaignUpdate: {
      isFetching: false,
      errors,
    },
  },
});

export const clearErrorsUpdateCampaign = () => ({
  type: types.CLEAR_ERRORS_UPDATE_CAMPAIGN.ACTION,
  payload: {
    campaignUpdate: {
      isFetching: false,
      errors: [],
    },
  },
});

export const clearErrorsCampaign = () => ({
  type: types.CLEAR_ERRORS_CAMPAIGN.ACTION,
  payload: {
    campaignCreate: {
      isFetching: false,
      errors: [],
    },
    campaignDelete: {
      isFetching: false,
      errors: [],
      notifications: [],
    },
    campaignUpdate: {
      isFetching: false,
      errors: [],
      notifications: [],
    },
    errors: [],
  },
});

export const fetchDeleteCampaign = (campaign) => ({
  type: types.FETCH_DELETE.ACTION,
  params: campaign,
  payload: {
    campaignDelete: {
      isFetching: true,
    },
  },
});

export const fetchDeleteCampaignSuccess = (params) => ({
  type: types.FETCH_DELETE.SUCCESS,
  params,
  payload: {
    campaignDelete: {
      isFetching: false,
      campaign: params,
      errors: [],
      notifications: [notificationMessages.CAMPAIGN_REMOVED_SUCCESS],
    },
  },
});

export const fetchDeleteCampaignFailure = (errors, params) => ({
  type: types.FETCH_DELETE.FAILURE,
  params,
  payload: {
    campaignDelete: {
      isFetching: false,
      errors,
    },
  },
});

export const fetchSendTestEmailSuccess = (campaign, params) => ({
  type: types.FETCH_SEND_TEST_EMAIL.SUCCESS,
  params,
  payload: {
    sendTestEmail: {
      isFetching: false,
      campaign,
      errors: [],
    },
  },
});

export const fetchSendTestEmailFailure = (errors, params) => ({
  type: types.FETCH_SEND_TEST_EMAIL.FAILURE,
  params,
  payload: {
    sendTestEmail: {
      isFetching: false,
      errors,
    },
  },
});

export const setSelected = (selected) => ({
  type: types.SET_SELECTED.ACTION,
  payload: {
    selected,
  },
});

export const addSelected = (items, newId) => {
  const selected = new Set(items);
  selected.add(newId);
  return setSelected(selected);
};

export const removeSelected = (items, removeId) => {
  const selected = new Set(items);
  selected.delete(removeId);
  return setSelected(selected);
};

export const addCampaign = () => ({
  type: types.ADD_CAMPAIGN.ACTION,
});

export const deleteCampaign = (id) => ({
  type: types.DELETE_CAMPAIGN.ACTION,
  payload: { id },
});

export const deleteSelectedCampaigns = (campaigns) => ({
  type: types.DELETE_SELECTED_CAMPAIGN.ACTION,
  payload: { campaigns },
});

export const saveEditedCampaign = () => ({
  type: types.EDIT_CAMPAIGN.SAVE,
});

export const cancelEditCampaign = () => ({
  type: types.EDIT_CAMPAIGN.CANCEL,
});

export const editCampaign = (id, status = null) => ({
  type: types.EDIT_CAMPAIGN.ACTION,
  payload: { id, status },
});

export const createCampaign = (id) => ({
  type: types.CREATE_CAMPAIGN.ACTION,
  payload: { id },
});

export const duplicateCampaign = (campaignId) => ({
  type: types.DUPLICATE_CAMPAIGN.ACTION,
  params: { campaignId },
});

export const setActiveCard = (activeCard) => ({
  type: types.EDIT_CAMPAIGN.SET_ACTIVE_CARD,
  payload: {
    activeCard,
  },
});

export const saveMail = () => ({ type: types.EDIT_CAMPAIGN.SAVE_MAIL });
export const finalize = () => ({ type: types.EDIT_CAMPAIGN.FINALIZE });
export const scheduleEmail = () => ({
  type: types.EDIT_CAMPAIGN.SCHEDULE_EMAIL,
});
export const sendEmailNow = () => ({
  type: types.EDIT_CAMPAIGN.SEND_EMAIL_NOW,
});

export const sendTestEmail = (physicianId, email) => ({
  type: types.EDIT_CAMPAIGN.SEND_TEST_EMAIL,
  payload: {
    sendTest: {
      physicianId,
      email,
    },
  },
});

export const saveAudience = () => ({ type: types.EDIT_CAMPAIGN.SAVE_AUDIENCE });

export const updateTextEditor = (editorState) => ({
  type: types.EDITOR_TEXT.UPDATE_EDITOR_STATE,
  payload: {
    editor: {
      editorState,
      dropdownState: false,
    },
  },
});

export const optionsTextFormat = (editorOptionsText) => ({
  type: types.EDITOR_TEXT.FORMAT_TEXT,
  payload: {
    editor: {
      optionsFormatText: editorOptionsText,
    },
  },
});

export const updateEntityLink = (editorState, contentUpgrade) =>
  updateTextEditor(
    EditorState.push(editorState, contentUpgrade, 'insert-characters'),
  );

export const forceSelection = (editorState) =>
  updateTextEditor(EditorState.moveFocusToEnd(editorState));

export const addEntityLinkTextSelected = (
  editorState,
  selection,
  contentWithEntity,
  entityKey,
) => {
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentWithEntity,
  });
  return updateTextEditor(
    RichUtils.toggleLink(newEditorState, selection, entityKey),
  );
};

export const addEntityLinkEmptyText = (
  editorState,
  selection,
  content,
  entityKey,
  textSelected,
) => {
  const contentWithText = Modifier.insertText(
    content,
    selection,
    textSelected,
    null,
    entityKey,
  );
  return updateTextEditor(
    EditorState.push(editorState, contentWithText, 'insert-characters'),
  );
};

const removeBeforeBlock = (editorState, contentStateWithEntity, entityKey) => {
  const currentContent = editorState.getCurrentContent();
  const blockMap = currentContent.getBlockMap();

  const currentBlock = blockMap.find(
    (block) => block.getEntityAt(0) === entityKey,
  );
  const currentAtomicKey = currentBlock.getKey();

  const blockBefore = currentContent.getBlockBefore(currentAtomicKey).getKey();
  const filteredBlockMap = blockMap.filter(
    (block) => block.getKey() !== blockBefore,
  );
  return EditorState.createWithContent(
    contentStateWithEntity.set('blockMap', filteredBlockMap),
  );
};

export const addEntityImage = (
  editorState,
  contentStateWithEntity,
  entityKey,
) => {
  const stateWithAtomic = AtomicBlockUtils.insertAtomicBlock(
    editorState,
    entityKey,
    ' ',
  );

  // DraftJS inserts an empty paragraph before each figure, remove it
  const cleanState = removeBeforeBlock(
    stateWithAtomic,
    contentStateWithEntity,
    entityKey,
  );

  return updateTextEditor(cleanState);
};

export const onModalEditorLinkSubmit = (textSelected, url) => ({
  type: types.EDITOR_TEXT.MODAL_LINK_SUBMIT,
  payload: {
    textSelected,
    url,
  },
});

export const onModalEditorButtonSubmit = (textSelected, url) => ({
  type: types.EDITOR_TEXT.MODAL_BUTTON_SUBMIT,
  payload: {
    textSelected,
    url,
  },
});

export const onModalEditorImageSubmit = (url) => ({
  type: types.EDITOR_TEXT.MODAL_IMAGE_SUBMIT,
  payload: {
    url,
  },
});

export const onAutomatedTagChosen = (automatedTag) => ({
  type: types.EDITOR_TEXT.AUTOMATED_TAG,
  payload: {
    automatedTag,
  },
});
