import React, { useEffect } from 'react';
import {
  Body,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from '@iclinic/design-system';

import { productViews } from 'shared/constants/views';

interface ModalInfoProps {
  currentModal: string;
  isOpen: boolean;
  onClose?: () => void;
  onConfirmClick?: () => void;
  customActions?: React.ReactNode;
}

export const ModalInfo = ({
  isOpen,
  currentModal,
  onClose,
  onConfirmClick,
  customActions,
}: ModalInfoProps) => {
  // When opening the modal offer for the whatsapp messaging service, send an event to userpilot
  useEffect(() => {
    if (isOpen && currentModal === 'message-whatsapp') {
      window.userpilot?.track('[Mensageria] Modal de apresentação');
    }
  }, [isOpen, currentModal]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs">
      <DialogHeader
        onClose={onClose}
        title={productViews[currentModal].title}
      />

      <DialogContent dividers>
        <Body variant="xs">{productViews[currentModal].text}</Body>

        {productViews[currentModal].youtubeUrl ? (
          <iframe
            title={`video-${productViews[currentModal].title}`}
            width="100%"
            height="258"
            src={`https://www.youtube.com/embed/${productViews[currentModal].youtubeUrl}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <img
            src={productViews[currentModal].imageUrl}
            alt={productViews[currentModal].alt}
          />
        )}
      </DialogContent>

      <DialogActions>
        {customActions || (
          <>
            <Button color="transparent" onClick={onClose}>
              Cancelar
            </Button>

            <Button
              onClick={onConfirmClick}
              data-testid={`know-more-${currentModal}`}
              data-ga={productViews[currentModal].trackId}
              data-sp={productViews[currentModal].trackId}
            >
              Quero contratar
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
