import * as Yup from 'yup';

export const requiredFieldText = 'Campo obrigatório';

export const expensesSchema = Yup.object().shape({
  expenses: Yup.array().of(
    Yup.object().shape({
      guide: Yup.string().required(requiredFieldText),
      id: Yup.string().required(requiredFieldText),
      name: Yup.string().required(requiredFieldText),
      quantity: Yup.string().required(requiredFieldText),
      tuss_code: Yup.string().required(requiredFieldText),
      tuss_table: Yup.string().required(requiredFieldText),
      type: Yup.string().required(requiredFieldText),
      unit_type: Yup.string().required(requiredFieldText),
      unity_type: Yup.string(),
      value: Yup.string().required(requiredFieldText),
    }),
  ),
});

export const expensesSectionValues = {
  expenses: [],
};
