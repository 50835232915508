import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Response } from '@types';
import moment from 'moment';

import { captureException } from 'shared/utils/handlerErrors';
import {
  AuthToken,
  FetchNotificationsRawData,
} from 'services/iclinic/notifications/types';
import * as api from 'services/iclinic/notifications';
import { Notifications, OverviewFilters } from './types';
import {
  fetchFinancialSellerData,
  fetchFinancialSellerDataFailure,
  fetchFinancialSellerDataSuccess,
  fetchNotifications,
  fetchNotificationsFailure,
  fetchNotificationsSuccess,
  fetchNotificationToken,
  fetchNotificationTokenFailure,
  fetchNotificationTokenSuccess,
  setUnreadNotificationsCount,
  setDateReadNotifications,
  setNotificationsList,
  setPageNumber,
  setNotificationsFetching,
} from '.';
import {
  getDateNotificationsRead,
  getNotifications,
  getNotificationsList,
  getNotificationToken,
  getOverviewFilters,
  getPageNumber,
  getUserByNotifications,
} from './selectors';
import { FinancialSellerRawData } from 'features/onlinePayment/services/panel/types';
import { getFinancialSellerData } from 'features/onlinePayment/services/panel';
import { normalizeFinancialSellerResponseData } from '../panel/normalizers';
import { normalizeNotificationsResponseData } from './normalizers';
import { FinancialSellerData } from '../panel/types';

export function* fetchFinancialSellerDataWorker() {
  try {
    const filters: OverviewFilters = yield select(getOverviewFilters);

    const { data }: Response<FinancialSellerRawData> = yield call(
      getFinancialSellerData,
      moment(filters.startDate).format('YYYY-MM-DD'),
      moment(filters.endDate).format('YYYY-MM-DD'),
      filters.sellerType,
    );

    const normalizeFinancialSeller: FinancialSellerData =
      !!data?.message && !data.error
        ? {
            averageTicketTransactionsApproved: 0,
            countTransactionsApproved: 0,
            totalAmountTransactionsApproved: 0,
            totalReceivablesValue: 0,
            valueToAntecipate: 0,
          }
        : normalizeFinancialSellerResponseData(data);

    yield put(fetchFinancialSellerDataSuccess(normalizeFinancialSeller));
  } catch (error) {
    captureException(error);
    yield put(fetchFinancialSellerDataFailure());
  }
}

export function* fetchNotificationsWorker() {
  try {
    const token: string = yield select(getNotificationToken);

    const pageNumber: number = yield select(getPageNumber);
    const notificationsList: [] = yield select(getNotificationsList);
    const offset = (pageNumber - 1) * 10;
    const { data }: Response<FetchNotificationsRawData> = yield call(
      api.fetchNotificationsByChannel,
      'payments',
      offset,
      token,
    );
    const normalizeNotifications = normalizeNotificationsResponseData(data);
    yield put(fetchNotificationsSuccess(normalizeNotifications));
    yield put(
      setNotificationsList([
        ...notificationsList,
        ...normalizeNotifications.results,
      ]),
    );
    yield put(
      setUnreadNotificationsCount(
        normalizeNotifications.unreadNotificationsCount,
      ),
    );
  } catch (error) {
    captureException(error);
    yield put(fetchNotificationsFailure());
  }
}

export function* fetchNotificationTokenWorker() {
  try {
    const { data }: Response<AuthToken> = yield call(
      api.fetchAuthTokenNotifications,
      'payments-token',
    );

    yield put(fetchNotificationTokenSuccess(data.access));
  } catch (error) {
    yield put(fetchNotificationTokenFailure());
    captureException(error);
  }
}

export function* updateNotificationsWorker() {
  try {
    const token: string = yield select(getNotificationToken);
    if (!token) {
      throw new Error(`missing token in header Authorization`);
    }

    const dateNotificationsRead: string = yield select(
      getDateNotificationsRead,
    );
    const userId: string = yield select(getUserByNotifications);
    const lastTimeRead = {
      last_time_read: dateNotificationsRead,
    };
    yield call(api.updateNotifications, token, userId, lastTimeRead);
    yield put(setUnreadNotificationsCount(0));
  } catch (error) {
    captureException(error);
    yield put(fetchNotificationsFailure());
  }
}

export function* paginationNotificationsWorker() {
  const pageNumber: number = yield select(getPageNumber);
  const notificationsData: Notifications = yield select(getNotifications);

  const { count } = notificationsData;

  if (!count) return;

  if (notificationsData && Math.ceil(notificationsData.count / 10) < pageNumber)
    return;

  yield put(setNotificationsFetching());
  yield call(fetchNotificationsWorker);
}

export default function* notificationsCenterSagas() {
  yield takeLatest(fetchFinancialSellerData, fetchFinancialSellerDataWorker);
  yield takeLatest(fetchNotifications, fetchNotificationsWorker);
  yield takeLatest(fetchNotificationToken, fetchNotificationTokenWorker);
  yield takeLatest(setDateReadNotifications, updateNotificationsWorker);
  yield takeLatest(setPageNumber, paginationNotificationsWorker);
}
