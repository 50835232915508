import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileSignatureAttachment } from 'state/records/signature/types';
import { sendEmailSignature } from 'state/shared/actions';
import { finalizeMedicalRecordFailure } from 'state/records/signature/actions';
import { normalizeAttachment, normalizeSendEmail } from './utils';

export default function usePreviewSignature() {
  const [attachments, setAttachment] = useState<FileSignatureAttachment[]>(
    [] as FileSignatureAttachment[],
  );

  const dispatch = useDispatch();

  const signedPdf = useSelector((state) => state.records.signature.signedPdf);

  const selectAttachment = (attachmentfileName: string) => {
    const attachmentSelected = attachments.map(
      (attachment: FileSignatureAttachment) => {
        if (attachment.file_name === attachmentfileName) {
          return { ...attachment, selected: !attachment.selected };
        }
        return attachment;
      },
    );
    setAttachment(attachmentSelected);
  };

  const sendEmail = (email: string) => {
    const normalizeData = normalizeSendEmail(attachments);
    const checksSelectedAttachments = normalizeData.length === 0;
    if (checksSelectedAttachments) {
      const error = {
        code: '129',
        message:
          'Escolha ao menos um documento a ser enviado para seu paciente.',
      };
      dispatch(finalizeMedicalRecordFailure([error]));
      return;
    }
    dispatch(sendEmailSignature(email, normalizeData));
  };

  useEffect(() => {
    if (signedPdf) {
      const filesAttachment = normalizeAttachment(signedPdf);
      setAttachment(filesAttachment);
    }
  }, [signedPdf]);

  return {
    attachments,
    selectAttachment,
    sendEmail,
  };
}
