import React from 'react';

import { NotificationType } from 'features/onlinePayment/state/constants';
import PaymentApproved from './notification-type/PaymentApproved';
import FreeText from './notification-type/FreeText';
import CreditCardAnticipationAvailable from './notification-type/CreditCardAnticipationAvailable';
import CreditCardAnticipationApproved from './notification-type/CreditCardAnticipationApproved';

type NotificationProps = {
  notificationType: NotificationType;
  notificationData: any;
};

export default function Notification({
  notificationType,
  notificationData,
}: NotificationProps): JSX.Element | null {
  const NotificationList: { [key: string]: JSX.Element } = {
    [NotificationType.PaymentApproved]: (
      <PaymentApproved notification={notificationData} />
    ),
    [NotificationType.CreditCardAnticipationApproved]: (
      <CreditCardAnticipationApproved notification={notificationData} />
    ),
    [NotificationType.CreditCardAnticipationAvailable]: (
      <CreditCardAnticipationAvailable notification={notificationData} />
    ),
    [NotificationType.FreeText]: <FreeText notification={notificationData} />,
  };

  return NotificationList[notificationType] || null;
}
