import React from 'react';
import { Box, MaterialUICore, Heading, Body } from '@iclinic/design-system';

import useStyles from './Header.styles';
import { SellerType } from 'features/onlinePayment/state/constants';

const { CircularProgress } = MaterialUICore;

type Props = {
  step?: number;
  totalSteps?: number;
  sellerType: SellerType;
};

const SIZE = 48;

export default function Header({
  step = 0,
  totalSteps = 2,
  sellerType,
}: Props): JSX.Element {
  const classes = useStyles();
  const value = (100 / totalSteps) * step;
  const sellerIsPJ = sellerType === SellerType.PJ;

  return (
    <Box display="flex" mb={4}>
      {!!step && (
        <Box position="relative" display="flex" mr={1}>
          <CircularProgress
            variant="static"
            value={value}
            size={SIZE}
            thickness={3}
          />
          <Box
            top={0}
            left={0}
            width={SIZE}
            height={SIZE}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Heading variant="sm" component="h5">
              {step}/{totalSteps}
            </Heading>
          </Box>
        </Box>
      )}
      <Box maxWidth="559px">
        <Heading variant="xl" component="h3" className={classes.title}>
          {sellerIsPJ ? 'Pessoa jurídica' : 'Pessoa física'}
        </Heading>
        <Body variant="sm" className={classes.subTitle}>
          Garanta a exatidão dos dados e a legibilidade de seus documentos, e
          controle seus pagamentos de forma rápida e segura.
        </Body>
      </Box>
    </Box>
  );
}
