import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'shared/constants/State';

export interface NewPasswordRequestState {
  status: RequestStatus;
  error: string | null;
}

export interface NewPasswordAction {
  uidb36: string;
  passToken: string;
  newPassword: string;
}

export const initialState: NewPasswordRequestState = {
  status: RequestStatus.Ready,
  error: null,
};

const name = '@webapp/auth2/new-password-request';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    pending: (state) => ({
      ...state,
      status: RequestStatus.Pending,
      error: null,
    }),
    error: (state, action: PayloadAction<string>) => ({
      ...state,
      status: RequestStatus.Error,
      error: action.payload || 'UnknownError',
    }),
    success: (state) => ({
      ...state,
      status: RequestStatus.Success,
      error: null,
    }),
  },
});

export const submit = createAction<NewPasswordAction>(`${name}/submit`);

export const { pending, error, success } = slice.actions;
export default slice.reducer;
