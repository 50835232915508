import {
  MaterialUICore,
  tokens,
  Box as DSBox,
  IconButton as DSIconButton,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const ActionContent = styled(DSBox)(({ theme }) => ({
  margin: `${tokens.spacing.xs} 0px`,
  paddingRight: tokens.spacing.sm,
  [theme.breakpoints.down('sm')]: {
    margin: `0px`,
    padding: `${tokens.spacing.nano} ${tokens.spacing.sm} ${tokens.spacing.base} ${tokens.spacing.sm}`,
  },
}));

export const ButtonBox = styled(DSBox)(({ theme }) => ({
  paddingLeft: tokens.spacing.xs,
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  borderLeft: `${tokens.border.width.hairline} solid ${tokens.color.neutral[50]}`,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: '0px',
    borderLeft: 'none',
    borderTop: `${tokens.border.width.hairline} solid ${tokens.color.neutral[50]}`,
  },
}));

export const IconButton = styled(DSIconButton)(({ theme }) => ({
  marginBottom: tokens.spacing.base,
  '&:last-child': {
    margin: '0px',
  },
  [theme.breakpoints.down('sm')]: {
    margin: `0px ${tokens.spacing.md} 0px 0px`,
  },
}));
