import { AppointmentGuideResultValues } from '../appointmentGuide';

export enum AppointmentGuideActionKeys {
  FetchAppointmentGuideList = 'fetchAppointmentGuideList',
}

export type GetAppointmentGuideApiResponse = PaginateGuideListParams & {
  results: AppointmentGuideResultValues[];
};

export interface AppointmentGuideDTO {
  id: string;
  appointmentDate: string;
  billedIn: string;
  completed: boolean;
  insuranceName: string;
  patientName: string;
  version: string;
}

export interface FetchAppointmentGuideRequestParams {
  limit: number;
  clinic?: string;
  physician_id?: string;
  page?: number;
}

export interface PaginateGuideListParams {
  next: string | null;
  previous: string | null;
  count: number;
  pageCurrent: number;
  totalPages: number;
}

export interface ResultApiTissInvoicing {
  id: string;
  event_id: number;
  date?: string;
  patient: Patient;
  physician: Physician;
  total_amount: string;
  lot_id?: string;
  guide_number: string;
  quantity: number;
  lot_number?: number;
  billed_in?: string;
  insurance: Insurance;
  procedures_total_amount: number;
  expenses_total_amount: number;
}

export interface Patient {
  id: number;
  name: string;
  cns?: string;
  civil_name?: string;
}

export interface Physician {
  id: number;
  name: string;
  cnes: string;
}

export interface Insurance {
  id: number;
  name: string;
  ans_register: string;
  tiss_version?: string;
  provider_code: string | null;
  provider_code_type: string | null;
  provider?: string;
  clinic_provider_id?: number;
  uses_sadt_team: boolean;
}
