import React, { useState, useCallback, useEffect } from 'react';
import { FormikErrors, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { StoreState } from 'state/rootReducer';
import { fetchUserInfo } from 'state/userInfo/actions';
import {
  getListPSC,
  setProvider,
  setLifeTime,
  getSignatureAccessTokenSuccess,
} from 'state/records/signature/actions';
import { getCookie } from 'shared/utils/cookie';
import validationSchema from '../components/validationSchema';
import { ProvidersPSC } from 'state/records/signature/types';

interface IUserStepCloud {
  checkHasToken: boolean;
  errors: FormikErrors<{ cpf: string }>;
  lifetime: string;
  isProvider: string;
  isLoading: boolean;
  providers: any;
  values: { cpf: string };
  handleChangeCpf: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  handleChangeLifeTime: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChangeProvider: (provider: ProvidersPSC) => void;
  setProviderSelected: (providerName: string, providerUrl: string) => void;
}

export const useStepCloud = (): IUserStepCloud => {
  const dispatch = useDispatch();
  const providers = useSelector((state) => state.records.signature.providers);
  const isLoading = useSelector((state) => state.records.signature.isLoading);
  const userDocumentInfo = useSelector(
    (state) => state.records.signature.userDocument,
  );
  const accessTokenState = useSelector(
    (state) => state.records.signature.accessToken,
  );
  const patientCpf = useSelector<StoreState, string | undefined>(
    (state) => state.userInfo.userData.cpf,
  );
  const providerName = useSelector(
    (state) => state.records.signature.providerName,
  );

  const checkHasToken = providers.length > 0 && !accessTokenState;
  const [firstOpen, setFirstOpen] = useState<boolean>(false);
  const [isProvider, setProviders] = useState<string>('');
  const [lifetime, setLifeTimeState] = useState<string>('');

  const setProviderSelected = useCallback(
    (name: string, url: string) => {
      const { number: cpfNumber } = userDocumentInfo;
      dispatch(setProvider(url, name));
      setProviders(name);
      const findCookie = getCookie(name + cpfNumber) || '';
      dispatch(getSignatureAccessTokenSuccess(findCookie));
    },
    [dispatch, userDocumentInfo],
  );

  const formatCpfNumber = (value: string = '') => value.replace(/\D+/g, '');

  const { handleSubmit, values, errors, submitForm, setFieldValue } =
    useFormik<{ cpf: string }>({
      initialValues: {
        cpf: patientCpf || '',
      },
      validationSchema,
      validateOnBlur: false,
      validateOnChange: false,
      enableReinitialize: true,
      onSubmit: () => {
        const userDocument = {
          doc_type: 'cpf',
          number: formatCpfNumber(values.cpf),
        };
        const urlRedirect = `${window.location.origin}/prontuarios/`;
        dispatch(getListPSC(userDocument, urlRedirect));
      },
    });

  useEffect(() => {
    dispatch(fetchUserInfo());
    const checkFirstProvider = providers.length > 0;
    if (checkFirstProvider) {
      const firstProvider = providers[0];
      setProviders(firstProvider.name);
      setProviderSelected(firstProvider.name, firstProvider.auth_url);
    }
  }, [dispatch, providers, setProviderSelected]);

  const handleChangeProvider = (provider: ProvidersPSC) => {
    if (providerName !== provider.name) {
      setProviderSelected(provider.name, provider.auth_url);
    }
  };

  const handleChangeLifeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLifeTimeState(event.target.value);
    dispatch(setLifeTime(event.target.value));
  };

  const handleChangeCpf = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const cpfNumber = formatCpfNumber(e.target.value);
      const checkFinalizeTyping = cpfNumber.length === 11;
      await setFieldValue('cpf', e.target.value);
      if (checkFinalizeTyping) submitForm();
    },
    [setFieldValue, submitForm],
  );

  useEffect(() => {
    if (!firstOpen && values.cpf.length === 11) {
      setFirstOpen(true);
      submitForm();
    }
  }, [values, firstOpen, submitForm]);

  return {
    handleSubmit,
    handleChangeCpf,
    values,
    errors,
    providers,
    isProvider,
    isLoading,
    setProviderSelected,
    checkHasToken,
    handleChangeLifeTime,
    lifetime,
    handleChangeProvider,
  };
};

export default useStepCloud;
