/**
 * Creates an object with all types of the actions given.
 *
 * @param action An object of all sliceReducer and createAction actions combined.
 * @example { a: ActionPayload<...>, b: ActionPayload<...>, ...}
 * @param storeName the same name given to your store or slicereducer .
 * @returns A key-value object as like `{ a: "[your-slicereducer-name]/a", ... }`
 * @example { setValue: "@webapp/payments/setValue", getMoneyBack: "@webapp/payments/getMoneyBack", ... }
 */
function getActionTypes<T extends object>(
  action: Record<keyof T, any>,
  storeName: string,
): Record<keyof T, string> {
  const types = {} as Record<keyof T, string>;
  Object.keys(action).forEach((key) => {
    types[key as keyof T] = `${storeName}/${key}`;
  });
  return types;
}

export { getActionTypes };
