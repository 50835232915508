import React, { useState } from 'react';

import { MaterialIcons, Box, Button } from '@iclinic/design-system';
import { GOT_IT } from 'features/video-conference/constants';
import { AlertDS } from './styles';
import { Severity } from '@iclinic/design-system/dist/components/Alert/types';

const { ErrorOutlineOutlined } = MaterialIcons;

type Props = {
  message: React.ReactNode;
  severity?: Severity;
  hideButton?: boolean;
};

export default function Alert({
  message,
  severity = 'info',
  hideButton = false,
}: Props) {
  const [isAlertVisible, setIsAlertVisible] = useState(true);

  return isAlertVisible ? (
    <Box>
      <AlertDS
        severity={severity}
        icon={<ErrorOutlineOutlined />}
        message={message}
        action={
          !hideButton && (
            <Button
              size="small"
              color="transparent"
              onClick={() => setIsAlertVisible(false)}
            >
              {GOT_IT}
            </Button>
          )
        }
      />
    </Box>
  ) : null;
}
