import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

import { SpaceBetweenBox } from 'features/tissInvoicing/components/ui/Flexbox';

const { makeStyles, styled } = MaterialUICore;

export const Title = styled(SpaceBetweenBox)({
  flex: 1,
  transition: 'margin-bottom 0.3s',
});

export const useStyles = makeStyles({
  root: {
    width: '100%',
    padding: axiomTokens.spacing['4'],
    margin: 0,
  },
});
