import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const HeaderContainer = styled('section')({
  width: '100%',
  fontFamily: tokens.font.family.base,
  padding: `${tokens.spacing.xs} ${tokens.spacing.md}`,
  paddingBottom: 0,
});
