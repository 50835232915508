import {
  Box,
  MaterialUICore,
  Select as MaterialSelect,
  Dialog as DSDialog,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Select = styled(MaterialSelect)(() => ({
  maxWidth: 112,

  '& .MuiFilledInput-root > .MuiInputAdornment-root': {
    marginTop: 0,
  },
  '& .MuiFilledInput-input': {
    paddingTop: tokens.spacing.base,
  },
}));

export const ImageWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const Dialog = styled(DSDialog)(() => ({
  '& .MuiSnackbarContent-root': {
    backgroundColor: tokens.color.feedback.info.light,
    boxShadow: 'none',
  },
}));
