// Internal
import { ApiErrors, ActionPayload, Action } from '@types';
import {
  IImageComparisonState,
  ItemImg,
} from './types';
import * as types from './constants';

export type OpenModal = ActionPayload<'isFetching' | 'itemImg' | 'patientId' | 'isMedicalCare' | 'eventId',
  typeof types.OPEN_MODAL.ACTION, IImageComparisonState>;
export const openModal = (
  itemImg: ItemImg,
  patientId: number,
  isMedicalCare: boolean,
  eventId: number = 0,
): OpenModal => ({
  type: types.OPEN_MODAL.ACTION,
  payload: {
    isFetching: true,
    itemImg,
    patientId,
    isMedicalCare,
    eventId,
  },
});

export type OpenModalSuccess = ActionPayload<'itemImg' | 'isOpenModal' | 'patientId' | 'isMedicalCare' | 'eventId' | 'isFetching' | 'notification',
typeof types.OPEN_MODAL.SUCCESS, IImageComparisonState>;
export const openModalSuccess = (
  isOpenModal: boolean,
  itemImg: ItemImg,
  patientId: number,
  isMedicalCare: boolean,
  eventId: number,
  isFetching: boolean,
): OpenModalSuccess => ({
  type: types.OPEN_MODAL.SUCCESS,
  payload: {
    isOpenModal,
    itemImg,
    patientId,
    isMedicalCare,
    eventId,
    isFetching,
    notification: {
      show: false,
      kind: '',
      title: '',
      description: '',
    },
  },
});

export type CloseModal = ActionPayload<'isOpenModal' | 'isSavingComparison' | 'isFetching' | 'selectedImages', typeof types.CLOSE_MODAL.ACTION, IImageComparisonState>;
export const closeModal = (): CloseModal => ({
  type: types.CLOSE_MODAL.ACTION,
  payload: {
    isOpenModal: false,
    isFetching: false,
    isSavingComparison: false,
    selectedImages: [],
  },
});

export type GetImages = ActionPayload<'isFetching' | 'errors',
typeof types.GET_IMAGES.ACTION, IImageComparisonState>;
export const getImages = (): GetImages => ({
  type: types.GET_IMAGES.ACTION,
  payload: {
    isFetching: true,
    errors: [],
  },
});

export type GetImagesSuccess = ActionPayload<'images' | 'isFetching' | 'errors',
typeof types.GET_IMAGES.SUCCESS, IImageComparisonState>;
export const getImagesSuccess = (images: ItemImg[]): GetImagesSuccess => ({
  type: types.GET_IMAGES.SUCCESS,
  payload: {
    images,
    isFetching: false,
    errors: [],
  },
});

export type GetImagesFailure = ActionPayload<'isFetching' | 'errors',
typeof types.GET_IMAGES.FAILURE, IImageComparisonState>;
export const getImagesFailure = (errors: ApiErrors[]): GetImagesFailure => ({
  type: types.GET_IMAGES.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export type SelectImage = ActionPayload<'imageId' | 'isFetching' | 'errors',
typeof types.SELECT_IMAGE.ACTION, IImageComparisonState>;
export const selectImage = (imageId: number): SelectImage => ({
  type: types.SELECT_IMAGE.ACTION,
  payload: {
    imageId,
    isFetching: false,
    errors: [],
  },
});

export type SelectImageSuccess = ActionPayload<'images' | 'isFetching' | 'errors',
typeof types.SELECT_IMAGE.SUCCESS, IImageComparisonState>;
export const selectImageSuccess = (images: ItemImg[]): SelectImageSuccess => ({
  type: types.SELECT_IMAGE.SUCCESS,
  payload: {
    images,
    isFetching: false,
    errors: [],
  },
});


export type SelectImageFailure = ActionPayload<'errorLimit' | 'isFetching' | 'errors',
typeof types.SELECT_IMAGE.FAILURE, IImageComparisonState>;
export const selectImageFailure = (errorLimit: boolean): SelectImageFailure => ({
  type: types.SELECT_IMAGE.FAILURE,
  payload: {
    errorLimit,
    isFetching: false,
    errors: [],
  },
});

export type SetMode = ActionPayload<'mode',
  typeof types.SET_MODE.ACTION, IImageComparisonState>;

export const setMode = (mode: string): SetMode => ({
  type: types.SET_MODE.ACTION,
  payload: {
    mode,
  },
});

export type SaveCompare = ActionPayload<'isSavingComparison'|'eventId' | 'eventAditionalId' | 'base64',
  typeof types.SAVE_COMPARE.ACTION, IImageComparisonState>;
export const saveCompare = (
  eventId: number,
  eventAditionalId: number,
  base64: string,
): SaveCompare => ({
  type: types.SAVE_COMPARE.ACTION,
  payload: {
    eventId,
    eventAditionalId,
    base64,
  },
});

export type CreateEventAdditional = ActionPayload<'isSavingComparison'| 'eventId' | 'base64',
  typeof types.CREATE_EVENT_ADDITIONAL.ACTION, IImageComparisonState>;
export const createAdditionalEvent = (eventId: number, base64: string): CreateEventAdditional => ({
  type: types.CREATE_EVENT_ADDITIONAL.ACTION,
  payload: {
    eventId,
    img: base64,
  },
});

export type CanvasImage = ActionPayload<'isSavingComparison'| 'canvas' | 'selectedImages',
  typeof types.CANVAS_IMAGE.ACTION, IImageComparisonState>;
export const canvasImage = (
  canvas,
  selectedImages: [],
  isSavingComparison: boolean,
): CanvasImage => ({
  type: types.CANVAS_IMAGE.ACTION,
  payload: {
    isSavingComparison,
    canvas,
    selectedImages,
  },
});

export const notificationSuccess = () => ({
  type: types.FETCH_NOTIFICATION.SUCCESS,
  payload: {
    notification: {
      show: true,
      kind: 'info',
      title: 'Prontuário',
      description: 'Alterações salvas com sucesso!',
    },
  },
});
export type Reload = Action<typeof types.RELOAD.ACTION>;
export const reload = (): Reload => ({ type: types.RELOAD.ACTION });

export type ImageComparisonActions =
| OpenModal
| CloseModal
| GetImages
| GetImagesSuccess
| GetImagesFailure
| SelectImage
| SelectImageSuccess
| SelectImageFailure
| SaveCompare
| CreateEventAdditional
| CanvasImage
| Reload;
