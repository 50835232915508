import React from 'react';

import { UpdateStepProps } from '../types';
import FormStepContainer from './FormStepContainer';
import ClinicForm from './ClinicForm';

const ClinicStep = ({ onClose }: UpdateStepProps) => {
  return (
    <FormStepContainer
      title="Atualização de cadastro da clínica"
      onClose={onClose}
    >
      <ClinicForm />
    </FormStepContainer>
  );
};

export default ClinicStep;
