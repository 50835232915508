import React from 'react';
import classnames from 'classnames/bind';

// Internal
import Dropdown from 'ui/new/dropdown';
import { Professional } from 'state/marketing/emailFlow/professional/types';

import style from './DropdownProfessionals.scss';

const classes = classnames.bind(style);

type DropdownProfessionalsProps = {
  selectedProfessional: Professional | null,
  name?: string,
  isOpen?: boolean,
  hasAddedFlows?: boolean,
  children: React.ReactElement,
  toggleOpen: () => void,
};

export default function DropdownProfessionals({
  selectedProfessional = null,
  isOpen = false,
  hasAddedFlows = false,
  name = '',
  children,
  toggleOpen,
}: DropdownProfessionalsProps): JSX.Element {
  return (
    <div
      className={classes('dropdown-professionals', { '-disabled': hasAddedFlows })}
    >
      <Dropdown
        icon={false}
        name={name}
        title={selectedProfessional ? selectedProfessional.name : 'Selecione o profissional de saúde'}
        isOpenProp={hasAddedFlows ? false : isOpen}
        setToggleMenuProp={toggleOpen}
      >
        {children}
      </Dropdown>
    </div>
  );
}
