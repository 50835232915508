import { MaterialUICore, axiomTokens, Body } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Container = styled('div')({
  alignItems: 'center',
  backgroundColor: axiomTokens.color.surface.accent.default,
  borderRadius: axiomTokens.border.radius.sm,
  display: 'flex',
  flexDirection: 'column',
  padding: axiomTokens.spacing[3],
  textAlign: 'center',
  margin: `${axiomTokens.spacing[2]} 0`,
  width: '100%',
  height: '100%',
  justifyContent: 'center',
});

export const MessageText = styled(Body)({
  color: axiomTokens.color.typeface.pure,
  marginBottom: axiomTokens.spacing[4],
});
