import React from 'react';

const ElectronicSignatureText = () => (
  <div>
    <p>
      Envie documentos e termos para seus pacientes assinarem eletronicamente
      com garantia e validade legal. Tudo isso integrado ao seu prontuário e sem
      limite de uso.
    </p>

    <p>
      <strong>
        Adicione essa funcionalidade no seu plano e aproveite o melhor preço do
        mercado!
      </strong>
    </p>
  </div>
);

export default ElectronicSignatureText;
