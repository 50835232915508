// External
import {
  put,
  takeLatest,
  all,
  call,
  select,
} from 'redux-saga/effects';

// Internal
import iclinic from 'services/iclinic';
import { handlerErrors } from 'state/shared/sagas';

import { mergeImage } from 'shared/utils/mergeImage';
import { StoreState } from 'state/rootReducer';
import MODE from 'features/records/attachments/ImageComparison/containers/constants';
import * as actions from './actions';
import * as types from './constants';
import {
  normalizeImages,
  selectImg,
  selectImgIndex,
  filterImages,
} from './utils';

const selectImageComparison = (state: StoreState) => state.records.attachments.imageComparison;

export function* workerOpenModal(action) {
  const {
    isOpenModal = true,
    itemImg,
    patientId,
    isMedicalCare,
    eventId,
    isFetching = true,
  } = action.payload;
  try {
    yield all([
      put(actions.openModalSuccess(
        isOpenModal,
        itemImg,
        patientId,
        isMedicalCare,
        eventId,
        isFetching,
      )),
      put(actions.getImages()),
    ]);
    yield put(actions.setMode(itemImg ? MODE.view : MODE.select));
  } catch (error) {
    yield call(handlerErrors, error, actions.openModal);
  }
}

export function* workerGetImages() {
  const { patientId, itemImg } = yield select(selectImageComparison);
  try {
    const { getResponseData, errors } = yield call(
      iclinic.records.getImagesByPatient,
      patientId,
    );

    if (errors) throw errors;
    const images = getResponseData();
    const results = filterImages(normalizeImages(images.objects));
    if (itemImg) {
      const index = selectImgIndex(itemImg, results);
      if (index !== -1) {
        results[index].checked = true;
      }
    }
    yield put(actions.getImagesSuccess(results));
  } catch (error) {
    yield call(handlerErrors, error, actions.selectImageFailure);
  }
}

export function* workerSelectImage(action) {
  try {
    const { imageId } = action.payload;
    const { images } = yield select(selectImageComparison);

    const imagesSelected = selectImg(imageId, images);
    yield put(actions.getImagesSuccess(imagesSelected));
  } catch (error) {
    yield call(handlerErrors, error, actions.getImagesFailure);
  }
}

export function* workerSaveImageCompare(action) {
  try {
    const { eventId, eventAditionalId, base64 } = action.payload;
    const { isMedicalCare } = yield select(selectImageComparison);

    const data = {
      event: eventId,
      image: {
        name: 'png',
        file: base64.substring(22, base64.length),
      },
      aditional: !isMedicalCare,
      event_aditional: isMedicalCare ? null : eventAditionalId,
      event_aditional_id: isMedicalCare ? null : eventAditionalId,
    };

    const { errors } = yield call(
      iclinic.records.saveImageCompare,
      data,
    );

    if (errors) throw errors;
    yield put(actions.closeModal());
    yield put(actions.notificationSuccess());
    yield put(actions.reload());
  } catch (error) {
    yield call(handlerErrors, error, actions.getImagesFailure);
  }
}

export function* workerCreateAdditionalEvent(action) {
  try {
    const { eventId, img } = action.payload;
    const { isMedicalCare, currentEvent } = yield select(selectImageComparison);

    if (!isMedicalCare) {
      const payloadEvent = {
        event: eventId,
        ordering: null,
      };

      const { getResponseData, errors } = yield call(
        iclinic.records.createAdditionalEvent,
        payloadEvent,
      );

      if (errors) throw errors;
      const responseEvent = getResponseData();

      yield put(actions.saveCompare(eventId, responseEvent.id, img));
    } else {
      yield put(actions.saveCompare(currentEvent, 0, img));
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.getImagesFailure);
  }
}

export function* workerCanvasImage(action) {
  try {
    const { canvas, selectedImages } = action.payload;
    yield call(mergeImage, canvas, selectedImages);
    const img = canvas.toDataURL();
    yield put(actions.createAdditionalEvent(selectedImages[0].event_id, img));
  } catch (error) {
    yield call(handlerErrors, error, actions.getImagesFailure);
  }
}

export function* workerReload() {
  const { currentEvent } = yield select(selectImageComparison);
  const url = window.location.href;

  if ((url.indexOf('#image') === -1) && (currentEvent > 0)) {
    window.location.href += '#image';
    window.location.reload();
  } else {
    window.location.reload();
  }
}

export default function* ImageComparisonSagas() {
  yield all([
    yield takeLatest(types.OPEN_MODAL.ACTION, workerOpenModal),
    yield takeLatest(types.GET_IMAGES.ACTION, workerGetImages),
    yield takeLatest(types.SELECT_IMAGE.ACTION, workerSelectImage),
    yield takeLatest(types.SAVE_COMPARE.ACTION, workerSaveImageCompare),
    yield takeLatest(types.CREATE_EVENT_ADDITIONAL.ACTION, workerCreateAdditionalEvent),
    yield takeLatest(types.CANVAS_IMAGE.ACTION, workerCanvasImage),
    yield takeLatest(types.RELOAD.ACTION, workerReload),
  ]);
}
