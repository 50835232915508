// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import styles from './VideoResponsive.scss';

export default function VideoResponsive({ children }) {
  return (
    <div className={styles.video}>{children(styles)}</div>
  );
}

VideoResponsive.propTypes = {
  children: PropTypes.node.isRequired,
};
