import { ApiErrors, ActionCreator } from '@types';

import {
  SAVE_PATIENT_FLOWS,
  FETCH_PATIENT_SUMMARY,
  ADD_PATIENT_FLOW,
  SET_SELECTED_RETURN_FLOW,
  REMOVE_PATIENT_FLOW,
  REMOVE_PATIENT,
  RESET_STATE,
  SET_PATIENT,
  initialState,
} from './constants';
import {
  PatientId,
  AvailableFlow,
  AddedFlow,
  ReturnFlow,
} from './types';

export type PatientState = Readonly<typeof initialState>;
export type EmailFlowActions = ActionCreator<string, Partial<PatientState>>;

export const savePatientFlows = (): EmailFlowActions => <const>{
  type: SAVE_PATIENT_FLOWS.ACTION,
  payload: {
    isFetching: true,
  },
};

export const savePatientFlowsSuccess = (): EmailFlowActions => <const>{
  type: SAVE_PATIENT_FLOWS.SUCCESS,
  payload: {
    isFetching: false,
  },
};

export const savePatientFlowsFailure = (errors: ApiErrors[]): EmailFlowActions => <const>{
  type: SAVE_PATIENT_FLOWS.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
};

export const fetchPatientSummary = (): EmailFlowActions => <const>{
  type: FETCH_PATIENT_SUMMARY.ACTION,
  payload: {
    isFetching: true,
  },
};

export const fetchPatientSummarySuccess = (
  availableFlows: AvailableFlow[],
  addedFlows: AddedFlow[],
): EmailFlowActions => <const>{
  type: FETCH_PATIENT_SUMMARY.SUCCESS,
  payload: {
    isFetching: false,
    availableFlows,
    addedFlows,
  },
};

export const fetchPatientSummaryFailure = (errors: ApiErrors[]): EmailFlowActions => (
  <const>{
    type: FETCH_PATIENT_SUMMARY.FAILURE,
    payload: {
      isFetching: false,
      errors,
    },
  }
);

export const addPatientFlow = (flow: AvailableFlow): EmailFlowActions => <const>{
  type: ADD_PATIENT_FLOW.ACTION,
  payload: {},
  meta: {
    flow,
  },
};

export const removePatientFlow = (flow: AddedFlow): EmailFlowActions => <const>{
  type: REMOVE_PATIENT_FLOW.ACTION,
  payload: {},
  meta: {
    flow,
  },
};

export const setSelectedReturnFlow = (selectedReturnFlow: ReturnFlow): EmailFlowActions => <const>{
  type: SET_SELECTED_RETURN_FLOW.ACTION,
  payload: {
    selectedReturnFlow,
  },
};

export const setPatient = (id: PatientId): EmailFlowActions => <const>{
  type: SET_PATIENT.ACTION,
  payload: {
    id,
  },
};

export const resetState = (): EmailFlowActions => <const>{
  type: RESET_STATE.ACTION,
  payload: initialState,
};

export const removePatient = (id: PatientId[]): EmailFlowActions => <const>{
  type: REMOVE_PATIENT.ACTION,
  payload: {
    patientIds: id,
  },
};

export const removePatientSuccess = (): EmailFlowActions => <const>{
  type: REMOVE_PATIENT.SUCCESS,
  payload: {
    isFetching: false,
  },
};

export const removePatientFailure = (errors: ApiErrors[]): EmailFlowActions => <const>{
  type: REMOVE_PATIENT.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
};
