// External
import { createSelector } from 'reselect';

import { IStoreState } from 'state/rootReducer';

interface CurrentEventAndPatientId {
  eventId: string;
  patientId: string;
}

export const patientInfo = (state: IStoreState) => state.records.root.patient;
export const currentEvent = (state: IStoreState) => state.records.root.eventId;

export const getCurrentEventAndPatientId = createSelector(
  [patientInfo, currentEvent],
  (patient, eventId): CurrentEventAndPatientId => {
    const patientId = patient.id;
    return {
      eventId,
      patientId,
    };
  },
);

export const getUserInfo = ({ userInfo }: IStoreState) => ({
  permissions: userInfo.userData.permissions,
});

export const getCanGenerateNewSummary = (state: IStoreState) =>
  state.records.root.canGenerateNewSummary;
