import React from 'react';
import { useSelector } from 'react-redux';

import {
  SuccessRegister,
  Configuring,
} from 'features/onlinePayment/components/config';
import {
  MaterialIcons,
  Typography,
  Box,
  Button,
  Chip,
} from '@iclinic/design-system';
import { useToggleBodyClass } from 'shared/hooks';
import { getSeller } from 'features/onlinePayment/state/config/register/selectors';
import { SellerStatus } from 'features/onlinePayment/state/constants';
import useStyles from './ConfigSuccessPage.styles';
import styles from '../../styles';

const { MoreHoriz, Done } = MaterialIcons;

const DataState = {
  Configuring: {
    image: <Configuring title="Configurando" />,
    title: 'Obrigada por finalizar seu cadastro!',
    status: <Chip icon={<MoreHoriz />} label="Configurando" />,
    text: (
      <>
        Em <strong>até 2 dias úteis</strong> nós vamos terminar de realizar as
        suas configurações.
        <br /> Se tiver alguma dúvida, entre em contato com a gente pelo chat :)
      </>
    ),
    button: (
      <Button color="secondary" href="/dashboard/">
        Ir para o painel
      </Button>
    ),
  },
  Approved: {
    image: <SuccessRegister title="Tudo certo!" />,
    title: 'Tudo certo!',
    status: <Chip icon={<Done />} label="Ativo" severity="success" />,
    text:
      'Você já pode começar a usar seu Pagamento Online! Acesse seus agendamentos e envie seu primeiro link de pagamento.',
    button: (
      <Button color="secondary" href="/agenda/">
        Ir para a agenda
      </Button>
    ),
  },
};

export default function ConfigSuccessPage(): JSX.Element | null {
  const classes = useStyles();
  const configClasses = styles();
  const seller = useSelector(getSeller);

  useToggleBodyClass(configClasses.whiteBody);

  const data =
    seller?.status === SellerStatus.Approved
      ? DataState.Approved
      : DataState.Configuring;

  if (!seller) {
    return null;
  }

  return (
    <Box textAlign="center" maxWidth={640} px={2.5} marginX="auto">
      <Box marginY={6} display="flex" justifyContent="center">
        {data.image}
      </Box>
      <Typography variant="h3" gutterBottom classes={{ h3: classes.title }}>
        {data.title}
      </Typography>
      <Box mb={2}>{data.status}</Box>
      <Typography variant="body1" classes={{ subtitle1: classes.subTitle }}>
        {data.text}
      </Typography>
      <Box mt={4}>{data.button}</Box>
    </Box>
  );
}
