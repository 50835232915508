// External
import React from 'react';
// Internal
import classnames from 'classnames/bind';

import { ItemImg } from 'state/records/attachments/imageComparison/types';
import styles from './ImageCompare.scss';

const cx = classnames.bind(styles);

type ImageCompareProps = {
  images: ItemImg[];
  directionH: boolean;
};

export default function ImageCompare({
  images,
  directionH = false,
}: ImageCompareProps): JSX.Element {
  return (
    <>
      <div
        className={cx(
          directionH ? styles['container-h'] : styles['container-v'],
        )}
      >
        {images.map((img) => (
          <div key={img.id} className={styles.div}>
            <img
              className={styles.image}
              src={img.max_size_image}
              alt={img.description}
            />
          </div>
        ))}
      </div>
    </>
  );
}
