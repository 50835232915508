import { createSelector } from 'reselect';
import { StoreState } from 'state/rootReducer';
import { Professional } from './types';

export const getSelectedProfessional = ({ marketing }: StoreState): Professional => (
  marketing.emailFlow.professional.selectedProfessional
);

export const getProfessionals = ({ marketing }: StoreState): Professional[] => (
  marketing.emailFlow.professional.professionals
);

export const getProfessionalSelected = createSelector(
  getSelectedProfessional,
  getProfessionals,
  (professional, professionals) => {
    const hasPhysicians = professionals && professionals.length;
    if (!professional && hasPhysicians) {
      return professionals[0];
    }
    return professional;
  },
);

export const getProfessionalSelectedId = createSelector(
  getSelectedProfessional,
  getProfessionals,
  (professional, professionals) => {
    const hasPhysicians = professionals && professionals.length;
    if (!professional && hasPhysicians) {
      return professionals[0].physician_id;
    }

    return professional && professional.physician_id;
  },
);
