// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import style from './Row.scss';

export default function Row({ children }) {
  return (
    <div className={style.row}>
      {children}
    </div>
  );
}

Row.propTypes = {
  children: PropTypes.node.isRequired,
};
