import {
  Button,
  CircularProgress,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikErrors, useFormikContext } from 'formik';

import PrintSADTModal from 'features/tissInvoicing/containers/components/ContainerFooterButtons/PrintModal';
import { AppointmentGuideFormValues } from 'features/tissInvoicing/types/appointmentGuide';
import history from 'routes/history';
import * as Styles from './styles';
import { validateAppointmentGuideForm } from '../../utils/validateAppointmentGuideForm';
import {
  loadingAppointmentGuideState,
  prrintAppointmentGuideState,
} from 'features/tissInvoicing/state/appointmentGuide/selectors';
import { actions } from 'features/tissInvoicing/state/appointmentGuide';

const { useMediaQuery, useTheme } = MaterialUICore;
const { ArrowBack, Print } = MaterialIcons;

type THandleSubmit = React.MouseEvent<HTMLButtonElement> & { print: boolean };

type ValidateFnProps<AppointmentGuideFormValues> = (
  errors: FormikErrors<AppointmentGuideFormValues>,
  sectionRef: React.RefObject<(HTMLButtonElement | null)[]>,
  callback?: (args: string) => void,
) => void;

const validateFunctions: {
  [key: string]: ValidateFnProps<AppointmentGuideFormValues>;
} = {
  validateAppointmentGuide: (errors, sectionRef) =>
    validateAppointmentGuideForm(errors, sectionRef),
};
enum ValidatorKeys {
  appointment = 'validateAppointmentGuide',
}

type ContainerFooterButtonsProps = {
  guideTypeValidator: 'appointment';
  sectionRef: React.RefObject<Array<HTMLButtonElement | null>>;
};

export default function ContainerFooterButtons({
  guideTypeValidator = 'appointment',
  sectionRef,
}: ContainerFooterButtonsProps): JSX.Element {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const { setFieldValue, validateForm } =
    useFormikContext<AppointmentGuideFormValues>();
  const { pdfURL, openModal } = useSelector(prrintAppointmentGuideState);

  const loading = useSelector(loadingAppointmentGuideState);

  const closeModal = () =>
    dispatch(actions.setGuidePDFUrl({ open: false, url: '' }));

  const handleSubmit = (event: THandleSubmit): void => {
    const { print } = event;

    setFieldValue('print', print, false);

    validateForm()
      .then((errors) => {
        validateFunctions[ValidatorKeys[guideTypeValidator]](
          errors,
          sectionRef,
        );
      })
      .catch(() => {});
  };

  return (
    <Styles.Container>
      <Button
        onClick={() => history.goBack()}
        color="transparent"
        startIcon={<ArrowBack />}
        data-testid="back_button"
      >
        Cancelar
      </Button>

      <Styles.Actions>
        {!isSmall && (
          <Button
            color="secondary"
            variant="outlined"
            type="submit"
            startIcon={
              loading.save ? <CircularProgress size="sm" /> : <Print />
            }
            onClick={(e) => handleSubmit({ ...e, print: true })}
            data-testid="print_button"
          >
            Imprimir
          </Button>
        )}

        <Button
          color="secondary"
          variant="outlined"
          type="submit"
          startIcon={loading.save ? <CircularProgress size="sm" /> : ''}
          data-testid="save_continue_button"
        >
          Salvar e adicionar outro
        </Button>

        <Button
          color="primary"
          type="submit"
          startIcon={loading.save ? <CircularProgress size="sm" /> : ''}
          onClick={(e) => handleSubmit({ ...e, print: false })}
          data-testid="save_button"
        >
          Salvar
        </Button>
      </Styles.Actions>

      <PrintSADTModal
        isOpen={openModal}
        handleClose={closeModal}
        url={pdfURL}
        title="Guia de Consulta"
      />
    </Styles.Container>
  );
}
