import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HighlightsInfoCards from '../HighlightsInfoCards';
import HighlightsInfoModal from '../HighlightsInfoModal';
import { ErrorBoundary } from '../../../errorBoundary';
import Skeleton from '../Skeleton';
import {
  getHighlightInfoIsError,
  getHighlightInfoLoading,
  getHighlightInfoShouldRefreshHighlightInfo,
  getHighlightsInfoList,
} from '../../state/selectors';
import { actions } from '../../state';
import * as S from './HighlightsInfoCardsContainer.styles';
import { getCurrentEventAndPatientId } from 'state/records/selectors';

const { getHighlightsInfo } = actions;

const HighlightsInfoCardsContainer = () => {
  const dispatch = useDispatch();
  const highlightsInfoList = useSelector(getHighlightsInfoList);
  const isGetHighlightError = useSelector(getHighlightInfoIsError);
  const isGetHighlightLoading = useSelector(getHighlightInfoLoading);
  const isShouldRefreshHighlightInfo = useSelector(
    getHighlightInfoShouldRefreshHighlightInfo,
  );

  const { patientId } = useSelector(getCurrentEventAndPatientId);

  const onRetry = useCallback(() => {
    dispatch(getHighlightsInfo(patientId));
  }, [dispatch, patientId]);

  const isRetryHighlightsInfo = useMemo(
    () => patientId && isShouldRefreshHighlightInfo,
    [isShouldRefreshHighlightInfo, patientId],
  );

  useEffect(() => {
    if (isRetryHighlightsInfo) {
      onRetry();
    }
  }, [dispatch, isRetryHighlightsInfo, onRetry]);

  return (
    <>
      {isGetHighlightLoading ? (
        <S.Container data-testid="skeleton">
          <S.CardContainerSkeleton>
            <Skeleton />
          </S.CardContainerSkeleton>

          <S.CardContainerSkeleton>
            <Skeleton />
          </S.CardContainerSkeleton>

          <S.CardContainerSkeleton>
            <Skeleton />
          </S.CardContainerSkeleton>

          <S.CardContainerSkeleton>
            <Skeleton />
          </S.CardContainerSkeleton>

          <S.CardContainerSkeleton>
            <Skeleton />
          </S.CardContainerSkeleton>

          <S.CardContainerSkeleton>
            <Skeleton />
          </S.CardContainerSkeleton>
        </S.Container>
      ) : (
        <ErrorBoundary
          isError={isGetHighlightError}
          message="Não conseguimos carregar a folha de rosto do paciente. Estamos trabalhando para a normalização."
          isButtonRetryVisible
          buttonText="Tentar novamente"
          onRetry={onRetry}
        >
          <HighlightsInfoCards highlightInfo={highlightsInfoList} />
        </ErrorBoundary>
      )}
      <HighlightsInfoModal />
    </>
  );
};

export default HighlightsInfoCardsContainer;
