import { createSlice, createAction, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';

export interface PrescriptionRequestState {
  token: string | null;
  status: RequestStatus;
  error: string | null;
}

export interface RecoverTokenAction {
  clinicId: string;
  physicianId: string;
}

export const initialState: PrescriptionRequestState = {
  token: null,
  status: RequestStatus.Ready,
  error: null,
};

const name = '@webapp/prescription';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    pending: (state) => ({
      ...state,
      status: RequestStatus.Pending,
      error: null,
    }),
    error: (state, action: PayloadAction<string>) => ({
      ...state,
      status: RequestStatus.Error,
      error: action.payload,
    }),
    success: (state, action: PayloadAction<string>) => ({
      ...state,
      status: RequestStatus.Success,
      token: action.payload,
      error: null,
    }),
  },
});

export enum TrackActivityClickEnum {
  MenuPep = 'menuPep',
}

export const getToken = createAction(`${name}/getToken`);
export const trackUserActivityClickType = `${name}/trackUserActivity`;
export const trackUserActivityClick = createAction<TrackActivityClickEnum>(
  trackUserActivityClickType,
);

export const { pending, error, success } = slice.actions;
export default slice.reducer;
