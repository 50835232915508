export const getOptionLabelFn = <OptionType extends {inputValue?: string, name?: string}>(
  option: OptionType,
) => {
  if (typeof option === 'string') return option;

  if (option?.inputValue) return option?.inputValue;

  return option?.name || '';
};

export const renderOptionFn = <OptionType extends {inputValue?: string, name?: string}>(
  option: OptionType
) => {
  if (option?.inputValue) return `"${option?.inputValue}"`;

  return option?.name || '';
};
