import React from 'react';
import PropTypes from 'prop-types';

import style from './FormActions.scss';

const FormActions = ({ children }) => (
  <div className={style.actions}>
    <div className={style.actions__button}>
      {children}
    </div>
  </div>
);

FormActions.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormActions;
