import { createSelector } from 'reselect';

import { RequestStatus } from 'shared/constants/State';
import { IStoreState } from 'state/rootReducer';
import { SampleBox, AvailableFilter } from '../../services/types';

export const isFetchingAvailable = ({ freeSamples }: IStoreState): boolean =>
  freeSamples.sampleBoxes.availableBoxes?.status === RequestStatus.Pending;

export const getAvailableBoxes = ({
  freeSamples,
}: IStoreState): SampleBox[] | undefined =>
  freeSamples.sampleBoxes.availableBoxes?.boxes;

export const getAvailableBoxesData = createSelector(
  [isFetchingAvailable, getAvailableBoxes],
  (isFetching, availableBoxes) => ({
    isFetchingAvailable: isFetching,
    availableBoxes,
  }),
);

export const isFetchingRequested = ({ freeSamples }: IStoreState): boolean =>
  freeSamples.sampleBoxes.requestedBoxes?.status === RequestStatus.Pending;

export const getRequestedBoxes = ({ freeSamples }: IStoreState): SampleBox[] =>
  freeSamples?.sampleBoxes?.requestedBoxes?.boxes || [];

export const getRequestedBoxesData = createSelector(
  [isFetchingRequested, getRequestedBoxes],
  (isFetching, requestedBoxes) => ({
    isFetchingRequested: isFetching,
    requestedBoxes,
  }),
);

export const getSelectedBox = ({
  freeSamples,
}: IStoreState): SampleBox | undefined => freeSamples.sampleBoxes.selectedBox;

export const isOffCanvasOpen = ({ freeSamples }: IStoreState): boolean =>
  !!freeSamples.sampleBoxes.offCanvasOpen;

export const isFetchingPharmaceuticals = ({
  freeSamples,
}: IStoreState): boolean =>
  freeSamples.sampleBoxes.filters?.statusPharmaceuticals ===
  RequestStatus.Pending;

export const getFilterAvailablePharmaceuticals = ({
  freeSamples,
}: IStoreState): AvailableFilter[] | undefined =>
  freeSamples.sampleBoxes.filters?.pharmaceuticals;

export const isFetchingActivePrinciples = ({
  freeSamples,
}: IStoreState): boolean =>
  freeSamples.sampleBoxes.filters?.statusActivePrinciples ===
  RequestStatus.Pending;

export const getFilterAvailableActivePrinciples = ({
  freeSamples,
}: IStoreState): AvailableFilter[] | undefined =>
  freeSamples.sampleBoxes.filters?.activePrinciples;

export const getFiltersData = createSelector(
  [
    isFetchingPharmaceuticals,
    getFilterAvailablePharmaceuticals,
    isFetchingActivePrinciples,
    getFilterAvailableActivePrinciples,
  ],
  (
    fetchingPharmaceuticals,
    pharmaceuticals,
    fetchingActivePrinciples,
    activePrinciples,
  ) => ({
    isFetchingPharmaceuticals: fetchingPharmaceuticals,
    pharmaceuticals,
    isFetchingActivePrinciples: fetchingActivePrinciples,
    activePrinciples,
  }),
);

export const isFetchingSuggestions = ({ freeSamples }: IStoreState): boolean =>
  freeSamples.sampleBoxes.suggestion?.status === RequestStatus.Pending;

export const getSuggestionList = ({
  freeSamples,
}: IStoreState): string[] | undefined =>
  freeSamples.sampleBoxes.suggestion?.suggestions;

export const getSuggestionData = createSelector(
  [isFetchingSuggestions, getSuggestionList],
  (isFetchingSuggestion, suggestions) => ({
    isFetchingSuggestion,
    suggestions,
  }),
);

export const getMessage = ({ freeSamples }: IStoreState) =>
  freeSamples.sampleBoxes.message;
