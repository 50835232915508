import React from 'react';
import { MaterialUICore, tokens } from '@iclinic/design-system';

const BREAKPOINT_SM = '@media (min-width: 600px)';
const { styled } = MaterialUICore;
const { color, font, line } = tokens;

type HTMLDivElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

type RootProps = {
  minWidth?: number | string;
} & HTMLDivElementProps;

type SwitchProps = {
  rightLabel: string;
} & HTMLDivElementProps;

export const Root = styled(({ minWidth, ...rest }: RootProps) => (
  <div {...rest} />
))({
  width: '100%',

  [BREAKPOINT_SM]: {
    maxWidth: (props: RootProps) => props.minWidth || 194,
  },
});

export const Switch = styled(({ rightLabel, ...rest }: SwitchProps) => (
  <div {...rest} />
))({
  background: color.primary.main,
  borderRadius: '24px',
  overflow: 'hidden',
  width: '100%',
  textAlign: 'center',
  fontSize: font.size.xxs,
  lineHeight: line.height.sm,
  fontWeight: font.weight.bold,
  color: color.neutral[0],
  transition: 'color 300ms',
  position: 'relative',
  paddingRight: 'calc(100%/2)',
  height: 38,

  '&:before': {
    content: (props: SwitchProps) => `"${props.rightLabel}"`,
    width: 'calc(100%/2)',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    zIndex: 3,
  },

  '&:has(input:checked)': {
    color: color.primary.main,
  },

  '@media (min-width: 600px)': {
    height: 32,
  },
});

export const Input = styled('input')({
  cursor: 'pointer',
  background: '#fff',
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
  zIndex: 2,

  '&:checked + label:before': {
    transform: 'translateX(100%)',
    transition: 'transform 300ms linear',
    color: color.neutral[0],
  },

  '&:checked + label': {
    color: color.neutral[0],
  },
});

export const Label = styled('label')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  userSelect: 'none',
  pointerEvents: 'none',
  height: '100%',
  color: color.primary.main,
  transition: 'color 300ms',

  '&:before': {
    content: '""',
    position: 'absolute',
    background: '#fff',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    borderRadius: '24px',
    border: `1px solid ${color.primary.main}`,
    transform: 'translateX(0)',
    transition: 'transform 300ms',
  },
});

export const Span = styled('span')({
  position: 'relative',
  fontSize: font.size.xxs,
  lineHeight: line.height.sm,
  fontWeight: font.weight.bold,
});
