import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Body, Box, Button } from '@iclinic/design-system';

import {
  isAnotherHealthProfessionalSelector,
  isDoctorSelector,
  professionalCountSelector,
  statusSelector,
} from '../state/selectors';
import * as S from './ChipSelectorContainers.styles';
import { RequestStatus } from 'shared/constants/State';
import {
  backStep,
  setProfessionalCount,
  submitProfessionalCount,
} from '../state/reducer';
import { ProfessionalCount, SignupSteps } from '../state/types';
import { ButtonSubmit } from '../components/Signup.styles';
import { Actions } from '../components/Actions/Actions';
import { useCheckStep } from '../hooks/useCheckStep';
import { trackSignupStep } from '../trackSignupUtils';
import { trackingStepNames } from '../constants';

const ProfessionalCountStepContainer = () => {
  const dispatch = useDispatch();
  const status = useSelector(statusSelector);
  const isAnotherHealthProfessional = useSelector(
    isAnotherHealthProfessionalSelector,
  );
  const isDoctor = useSelector(isDoctorSelector);
  const professionalCount = useSelector(professionalCountSelector);
  useCheckStep({ navigationStep: SignupSteps.ProfessionCount });

  const handleSubmit = () => {
    dispatch(submitProfessionalCount(professionalCount || null));
  };

  const handleBackStep = () => {
    trackSignupStep({
      name: trackingStepNames[SignupSteps.Job].back,
    });
    dispatch(backStep(SignupSteps.Job));
  };

  const handleChipSelection = (
    selectedProfessionalCount: ProfessionalCount,
  ) => {
    trackSignupStep({
      name: 'user_professionals_quantity',
      stepValue: selectedProfessionalCount,
    });

    dispatch(setProfessionalCount(selectedProfessionalCount));
  };

  const nextButtonMessage =
    isAnotherHealthProfessional || isDoctor ? 'Avançar' : 'Iniciar';

  return (
    <Box>
      <S.StepHeading variant="md">
        Quantos profissionais da saúde trabalham na clínica?
      </S.StepHeading>
      <Body variant="xs">
        Queremos entender mais sobre o momento de sua carreira para melhor lhe
        ajudar.
      </Body>
      <S.ChipsContainer>
        {Object.values(ProfessionalCount).map((count) => (
          <S.Chip
            key={count}
            label={isDoctor && count && count === '1' ? 'Apenas eu' : count}
            active={professionalCount === count}
            variant="outlined"
            onClick={() => handleChipSelection(count)}
          />
        ))}
      </S.ChipsContainer>
      <Actions>
        <Button
          variant="contained"
          color="transparent"
          size="medium"
          onClick={handleBackStep}
        >
          Voltar
        </Button>
        <ButtonSubmit
          color="primary"
          size="large"
          variant="contained"
          onClick={handleSubmit}
          isLoading={status === RequestStatus.Pending}
        >
          {nextButtonMessage}
        </ButtonSubmit>
      </Actions>
    </Box>
  );
};

export default ProfessionalCountStepContainer;
