import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const backgroundImage = (
  borderRadius: number,
  color: string,
  strokeWidth: number = 1,
) =>
  `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='${borderRadius}' ry='${borderRadius}' stroke='${color}FF' stroke-width='${strokeWidth}' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`;

export default makeStyles((theme) => ({
  upload: {
    height: '96px',
    borderRadius: theme.shape.borderRadius,
    position: 'relative',
    overflow: 'hidden',
    marginBottom: 0,

    '&::after': {
      backgroundImage: backgroundImage(
        theme.shape.borderRadius,
        encodeURIComponent(theme.palette.grey[100]),
      ),
      content: '""',
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
    '&:focus::after': {
      backgroundImage: backgroundImage(
        parseInt(tokens.border.radius.sm, 10),
        encodeURIComponent(tokens.color.primary.main),
        2,
      ),
    },
  },
  error: {
    '&::after': {
      backgroundImage: backgroundImage(
        theme.shape.borderRadius,
        encodeURIComponent(theme.palette.error.main),
        2,
      ),
    },
  },
  disabled: {
    backgroundColor: tokens.color.neutral[25],
    '& h5': {
      color: theme.palette.grey[300],
    },
    '&::after': {
      backgroundImage: 'none',
      borderRadius: tokens.border.radius.sm,
      border: `solid 1px ${tokens.color.neutral[100]}`,
      cursor: 'initial',
    },
  },
  image: {
    background: theme.palette.background.default,
    padding: '4px',
    '& > img': {
      width: '100%',
      height: '100%',
      'object-fit': 'cover',
    },
  },
  iconThumb: {
    fontSize: '52px',
    color: theme.palette.grey[100],
  },
  placeholder: {
    color: theme.palette.grey[500],
  },
  input: {
    '&[type="file"]': {
      display: 'none',
    },
  },
  helperText: {
    color: theme.palette.grey[300],
    fontWeight: 500,
    fontSize: '12px',
    marginLeft: '8px',
    marginRight: '8px',
  },
}));
