import { EmailsSettingsRawData } from 'features/onlinePayment/services/config/types';
import {
  SellerEmailsSettingsForm,
  SellerEmailsSettingsFormResume,
} from '../types';

export const normalizeSellerEmailsSettingsDataResponseData = (
  sellerEmailSettings: EmailsSettingsRawData,
): SellerEmailsSettingsFormResume => {
  if (
    sellerEmailSettings.payment_confirmation === null ||
    sellerEmailSettings.weekly_summary === null
  ) {
    return {
      paymentConfirmationEnabled: true,
      paymentConfirmationEmails: [],
      weeklySummaryEnabled: true,
      weeklySummaryEmails: [],
    };
  }

  return {
    paymentConfirmationEnabled:
      sellerEmailSettings.payment_confirmation.enabled,
    paymentConfirmationEmails: sellerEmailSettings.payment_confirmation.emails,
    weeklySummaryEnabled: sellerEmailSettings.weekly_summary.enabled,
    weeklySummaryEmails: sellerEmailSettings.weekly_summary.emails,
  };
};

export const normalizeSellerEmailsSettingsDataRequestBody = (
  sellerEmailsSettingsForm: SellerEmailsSettingsForm,
): EmailsSettingsRawData => {
  return {
    payment_confirmation: {
      emails: sellerEmailsSettingsForm.paymentConfirmationEmails,
      enabled: sellerEmailsSettingsForm.paymentConfirmationEnabled,
    },
    weekly_summary: {
      emails: sellerEmailsSettingsForm.weeklySummaryEmails,
      enabled: sellerEmailsSettingsForm.weeklySummaryEnabled,
    },
  };
};
