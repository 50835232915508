/* eslint-disable import/prefer-default-export */
export const errorsHandle = {
  documentNotFound: {
    code: '124',
    message: 'Nenhum documento encontrado para ser assinado',
  },
  certificatesNotFound: {
    code: '125',
    message:
      'Não encontramos nenhum certificado no seu CPF. Escolha outra opção para continuar.',
  },
};
