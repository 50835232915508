import React from 'react';

import { Grid, TextField } from '@iclinic/design-system';
import { SellerBankAccountForm } from 'features/onlinePayment/state/config/types';
import { cnpjFormatter, cpfFormatter } from 'shared/utils/formatters';
import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';

const AccountOwner = ({ values }: { values: SellerBankAccountForm }) => {
  if (values.type === SellerType.PF)
    return (
      <>
        <Grid item xs={12} sm={6}>
          <TextField
            id="name"
            name="name"
            label="Nome do titular"
            value={values.name}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="cpf"
            name="cpf"
            label="CPF do titular"
            value={cpfFormatter(values.cpf)}
            disabled
            fullWidth
          />
        </Grid>
      </>
    );

  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          id="businessName"
          name="businessName"
          label="Razão social do titular"
          value={values.businessName}
          disabled
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          id="businessCnpj"
          name="businessCnpj"
          label="CNPJ do titular"
          value={cnpjFormatter(values.businessCnpj)}
          disabled
          fullWidth
        />
      </Grid>
    </>
  );
};

export default AccountOwner;
