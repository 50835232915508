import { Box as BoxUI, MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles, styled } = MaterialUICore;

export const Container = styled(BoxUI)({
  display: 'flex',
  alignItems: 'center',
});

export const useStyles = makeStyles(() => ({
  button: {
    height: tokens.spacing.lg,
    '&:hover, &:focus': {
      outline: 'none',
    },
  },
  deleteButton: {
    height: tokens.spacing.lg,
    outline: 'none',
    border: `1px solid ${tokens.color.neutral[100]}`,
    borderRadius: tokens.border.radius.sm,
    color: tokens.color.neutral[700],
  },
}));
