import { Grid, Heading, MaterialUICore } from '@iclinic/design-system';
import React from 'react';
import { useSelector } from 'react-redux';

import { getAutocomplete } from 'features/tissInvoicing/state/autocomplete/selectors';
import { getEventState } from 'features/tissInvoicing//state/sadt/selectors';
import SelectFieldSADT from 'features/tissInvoicing/components/sadt/SelectFieldSADT';
import * as Styles from './styles';

const { MenuItem, useMediaQuery, useTheme } = MaterialUICore;

const HeaderSection = (): JSX.Element => {
  const classes = Styles.useStyles();

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const event = useSelector(getEventState);
  const { versions } = useSelector(getAutocomplete);

  if (!isSmall)
    return (
      <Grid classes={{ root: classes.gridContainer }} container item>
        <Grid container item direction="column" xs={isSmall ? 12 : 9}>
          <Heading component="h4" variant="xs">
            {event?.insurance?.name || ''}
          </Heading>
          <Heading component="h6" variant="overline">
            GUIA SP/SADT
          </Heading>
        </Grid>
        <Grid container item xs={isSmall ? 12 : 3}>
          <SelectFieldSADT
            label="Versão da guia"
            identifier="version_id"
            iconClass={classes.icon}
            required
          >
            <MenuItem value="">‏‏‎ ‎</MenuItem>
            {versions.map((version) => (
              <MenuItem value={version} key={version}>
                {version}
              </MenuItem>
            ))}
          </SelectFieldSADT>
        </Grid>
      </Grid>
    );

  return (
    <>
      <Grid container item direction="column" xs={isSmall ? 12 : 9}>
        <Heading component="h4" variant="xs">
          {event?.insurance?.name || ''}
        </Heading>
        <Heading component="h6" variant="overline">
          GUIA SP/SADT
        </Heading>
      </Grid>

      <Grid container item xs={isSmall ? 12 : 3}>
        <SelectFieldSADT
          label="Versão da guia"
          identifier="version_id"
          iconClass={classes.icon}
          required
        >
          <MenuItem value="">‏‏‎ ‎</MenuItem>
          {versions.map((version) => (
            <MenuItem value={version} key={version}>
              {version}
            </MenuItem>
          ))}
        </SelectFieldSADT>
      </Grid>
    </>
  );
};

export default HeaderSection;
