import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import { AxiosError } from 'axios';

import {
  sendNewPassword,
  getAuthToken,
  checkPasswordLink,
} from 'services/iclinic/auth';
import { handlerErrors } from 'state/shared/sagas';
import * as actions from '.';
import * as checkActions from './checkPasswordLink';
import { buildAutoLoginURL } from '../utils';
import { captureException } from 'shared/utils/handlerErrors';

type SubmitAction = ReturnType<typeof actions.submit>;

export const errorMessageDictionary = {
  'password-different': 'Sua senha deve ser diferente da anterior.',
  'invalid-link': 'Link de redefinição de senha expirado ou inválido.',
  'invalid-form': 'Ocorreu um erro ao redefinir a senha.',
} as const;

type ErrorMessageDictionaryKeys = keyof typeof errorMessageDictionary;
type ErrorMessageDictionaryValues =
  typeof errorMessageDictionary[ErrorMessageDictionaryKeys];

type ErrorsResponse = {
  errors: {
    code: ErrorMessageDictionaryKeys;
    message: ErrorMessageDictionaryValues;
  }[];
};

type AxiosResponseWithErrors = AxiosError<ErrorsResponse>;

const hasResponseDataErrors = (
  data: AxiosError | unknown,
): data is AxiosResponseWithErrors =>
  !!(data as AxiosResponseWithErrors)?.response?.data?.errors;

const getErrorCode = (
  data: AxiosResponseWithErrors,
): ErrorMessageDictionaryKeys | undefined =>
  data?.response?.data?.errors?.[0]?.code;

export const GENERIC_ERROR =
  'Ocorreu um erro inesperado. Aguarde e tente novamente.';

function* errorResponseHandler(error: unknown) {
  if (hasResponseDataErrors(error)) {
    const errorCode = getErrorCode(error);
    const errorMessage = errorCode
      ? errorMessageDictionary[errorCode]
      : GENERIC_ERROR;
    yield put(actions.error(errorMessage));
    return;
  }
  if (error instanceof Error) {
    captureException(error);
  }
  yield put(actions.error(GENERIC_ERROR));
}

export function* submitWorker({
  payload: { uidb36, passToken, newPassword },
}: SubmitAction) {
  try {
    yield put(actions.pending());
    const token = (yield call(getAuthToken)) as string;
    const { data } = yield call(
      sendNewPassword,
      uidb36,
      passToken,
      newPassword,
      token,
    );
    const { uid, auth_token: authToken } = data;

    yield put(actions.success());
    yield call([window.location, 'replace'], buildAutoLoginURL(authToken, uid));
  } catch (error: unknown) {
    yield call(errorResponseHandler, error);
  }
}

type GetCheckPasswordLinkAction = ReturnType<typeof checkActions.check>;

export function* checkPasswordLinkWorker({
  payload: { uidb36, passToken },
}: GetCheckPasswordLinkAction) {
  try {
    yield put(checkActions.pending());

    const {
      data: { validlink },
    } = yield call(checkPasswordLink, uidb36, passToken);
    yield put(checkActions.success(validlink));
  } catch (error) {
    yield put(checkActions.error(error.message));
    yield call(handlerErrors, error, checkActions.error);
  }
}

export function* watchNewPasswordFlow() {
  yield takeLatest(actions.submit, submitWorker);
}

export function* watchCheckPasswordLinkFlow() {
  yield takeLatest(checkActions.check, checkPasswordLinkWorker);
}

export default function* newPasswordSagas() {
  yield all([fork(watchNewPasswordFlow), fork(watchCheckPasswordLinkFlow)]);
}
