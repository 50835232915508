import { UpdateMessage } from './types';

export const MESSAGE_BLOCKED_ACCOUNT =
  'Sua conta está bloqueada por falha no pagamento. Verifique e atualize seu cartão para desbloquear.';
export const MESSAGE_PAYMENT_ATTEMPT = (finalNumber: number) =>
  `As tentativas de cobrança em seu cartão com final ${finalNumber} falharam. Atualize seu cartão para evitar o bloqueio da sua conta.`;

export const SUCCESS_PAYMENT_UPDATE_MESSAGE: UpdateMessage = {
  success: true,
  title: 'Cartao cadastrado com sucesso',
  message:
    'O pagamento foi realizado! Você precisa sair do sistema e logar novamente para voltar a ter acesso completo.',
  isBlocked: true,
};

export const FAILED_PAYMENT_UPDATE_MESSAGE: UpdateMessage = {
  success: false,
  title: 'Pagamento não foi aprovado',
  message:
    'Cartão foi cadastrado mas o pagamento não foi aprovado. Cadastre um novo cartão ou procure o suporte da iClinic.',
  isBlocked: true,
};

export const SUCCESS_CHANGE_CARD: UpdateMessage = {
  success: false,
  title: 'A troca do seu cartão de crédito foi concluída com sucesso!',
  isBlocked: false,
};
