import { SavedPrescriptionEventPayload } from '@afyadigital/rx-engine';

interface Drug {
  integration: {
    id: string;
    name: string;
  };
  name: string | null;
  description: string | null;
  posology: string | null;
  quantity: number;
  composition: string | null;
  drug_kind: 't' | 'm';
  laboratoryName: string;
  subtitle: string;
}

interface ExamItem {
  quantity: number;
  term: string;
  code: string;
  integration_id: string;
}

interface Exams {
  clinical_indication: string;
  exams_items: ExamItem[];
}

export interface IClinicPrescription {
  integration: { name: string; id: string };
  drugs: Drug[];
  exams: Exams[];
  pdfUrl: string;
  issuedAt: string;
}

export function getName(title: string, subtitle = '') {
  const nameComplement = subtitle ? `, ${subtitle}` : '';
  return `${title}${nameComplement}`;
}

export function convertAfyaToiClinicPrescription(
  prescription: SavedPrescriptionEventPayload,
) {
  const iclinicPrescription: Partial<IClinicPrescription> = {
    integration: { name: 'afya', id: prescription.id },
    pdfUrl: prescription.pdfUrl,
    issuedAt: prescription.issuedAt,
  };

  iclinicPrescription.drugs = prescription.items.map((item) => {
    const {
      type,
      title,
      id,
      patientInstruction,
      quantity,
      unit,
      description,
      laboratoryName,
      subtitle,
      maxPrice,
      category,
      available,
      listType,
      prescriptionType,
      printType,
    } = item;

    return {
      integration: { name: 'afya', id: id || null },
      name: getName(title, subtitle),
      description,
      posology: patientInstruction,
      quantity,
      composition: description,
      laboratoryName,
      category,
      unit,
      maxPrice,
      title,
      subtitle,
      drug_kind: type === 'freeText' ? 't' : 'm',
      available,
      listType,
      prescriptionType,
      printType,
    };
  });

  iclinicPrescription.exams = prescription.exams.map(
    ({ clinicalIndication, items }) => ({
      clinical_indication: clinicalIndication || '',
      exams_items: items.map(
        ({ quantity, exam: { term, code, id: integration_id } }) => ({
          quantity,
          term,
          code,
          integration_id,
        }),
      ),
    }),
  );

  return iclinicPrescription;
}

export const getFullAddress = (patient: Record<string, string | null>) => {
  const { address, number, neighborhood, city, state, zip_code } = patient;

  return [
    address ? `${address},` : '',
    number ? `${number}.` : '',
    neighborhood ? `${neighborhood}.` : '',
    city ? `${city} -` : '',
    state ? `${state}.` : '',
    zip_code ? `${zip_code}` : '',
  ]
    .filter(Boolean)
    .join(' ');
};
