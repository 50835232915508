import { ContentBlock, EntityInstance, DraftInlineStyle } from 'draft-js';
import { typeStyleEditor, optionsToExportClass, alignmentImage } from '../constants';

const blockStyleFnToStyle = {
  [typeStyleEditor.normal]: { attributes: { class: optionsToExportClass.unstyled } },
  [typeStyleEditor.alignCenter]: { attributes: { class: optionsToExportClass.center } },
};

export const entityStyleFnToStyle = {
  [typeStyleEditor.link]: { element: 'a', attributes: { class: optionsToExportClass.link } },
  [typeStyleEditor.button]: { element: 'button', attributes: { class: optionsToExportClass.button } },
  [typeStyleEditor.automatedTag]: { element: 'span', attributes: { class: optionsToExportClass.automatedTag } },
  [typeStyleEditor.image]: { element: 'img', attributes: { class: optionsToExportClass.image } },
};

export const imageAlignment = {
  [alignmentImage.center]: { display: 'block', 'margin-left': 'auto', 'margin-right': 'auto' },
  [alignmentImage.default]: { display: 'block', 'margin-right': 'auto' },
  [alignmentImage.right]: { display: 'block', 'margin-left': 'auto' },
};

const optionsExport = {
  inlineStyleFn: (styles: DraftInlineStyle) => {
    if (styles.has(typeStyleEditor.title)) {
      return {
        element: optionsToExportClass.headerElement,
        attributes: { class: optionsToExportClass.header },
      };
    }
    return '';
  },
  blockStyleFn: (block: ContentBlock) => blockStyleFnToStyle[block.getType()] || '',
  entityStyleFn: (entity: EntityInstance) => {
    const type = entity.getType();
    const data = entity.getData();
    switch (type) {
      case typeStyleEditor.link:
        return {
          element: 'a',
          attributes: {
            class: optionsToExportClass.link,
            href: data.url,
          },
        };
      case typeStyleEditor.button:
        return {
          element: 'div',
          attributes: {
            class: optionsToExportClass.button,
            url: data.url,
            type: 'div',
          },
          style: {
            margin: '0 auto',
            'text-align': 'center',
          },
        };
      case typeStyleEditor.automatedTag:
        return {
          element: 'span',
          attributes: {
            class: optionsToExportClass.automatedTag,
          },
        };
      case typeStyleEditor.image:
        return {
          element: 'img',
          attributes: {
            class: `${optionsToExportClass.image}-alignment-${data.alignment || 'default'}`,
            src: data.src,
            width: typeof data.width === 'number' ? `${data.width}%` : '100%',
          },
          style: imageAlignment[data.alignment === undefined ? 'default' : data.alignment],
        };
      default:
        break;
    }
    return '';
  },
};

export default optionsExport;
