import axios from 'axios';

import {
  interceptorConferenceApiRequestAuthentication,
  interceptorApiRequestAuthentication,
  interceptorResponseTransformData,
  interceptorResponseErrors,
  interceptorResponseRefreshTokenErrors,
} from 'features/video-conference/services/interceptors/';

export const apiTelemedicine = axios.create({
  baseURL: process.env.ICLINIC_TELEMEDICINE_URL,
  headers: {
    authorization: 'Bearer iclinic',
  },
});

apiTelemedicine.interceptors.request.use(
  interceptorConferenceApiRequestAuthentication,
);
apiTelemedicine.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseRefreshTokenErrors,
);

export const api = axios.create({
  baseURL: process.env.ICLINIC_API_URL,
});

api.interceptors.request.use(interceptorApiRequestAuthentication);
api.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrors,
);
