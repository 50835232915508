// External
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

// Internal
import Button from 'ui/new/button';
import Radio from 'ui/new/form/Radio';
import { STATUS } from 'ui/new/actionable-card/constants';
import { cardsName, AudienceSummary } from 'state/campaign/constants';
import ActionableCard from 'ui/new/actionable-card';
// Style
import style from './ChooseCampaignAudience.scss';

const ChooseCampaignAudience = ({
  activeCard,
  setActiveCard,
  audienceSummary,
  saveAudience,
  children,
  cardStatus,
  patientCount,
  message,
  audienceValid,
}) => {
  const collapsed = activeCard !== cardsName.audience;
  const onHeaderClick = React.useCallback(
    () => setActiveCard(collapsed ? cardsName.audience : cardsName.none),
    [collapsed, setActiveCard],
  );

  const patientCountInfo = React.useMemo(
    () => (
      <div className={style.chooseCampaignAudience__patientsCount}>
        <strong>{message.patientsCounting(patientCount)}</strong>
        {message.patientsSelection(patientCount)}
      </div>
    ),
    [message, patientCount],
  );

  return (
    <ActionableCard
      title="Escolha o público-alvo"
      status={cardStatus}
      collapsed={collapsed}
      onHeaderClick={onHeaderClick}
    >
      <ActionableCard.Body>
        <div className={style.chooseCampaignAudience__radioButtons}>
          <Field
            component={Radio}
            name="audienceSummary"
            value={AudienceSummary.all}
            type="radio"
          >
            Enviar para todos contatos
          </Field>
          <Field
            component={Radio}
            name="audienceSummary"
            value={AudienceSummary.segmented}
            type="radio"
          >
            Segmentar público alvo
          </Field>
        </div>
        {audienceSummary !== AudienceSummary.all && [children]}
        {audienceValid && patientCountInfo}
      </ActionableCard.Body>
      <ActionableCard.Footer>
        <Button kind="primary" onClick={saveAudience}>
          Salvar
        </Button>
      </ActionableCard.Footer>
    </ActionableCard>
  );
};

ChooseCampaignAudience.defaultProps = {
  children: undefined,
  cardStatus: STATUS.Regular,
};

ChooseCampaignAudience.propTypes = {
  activeCard: PropTypes.string.isRequired,
  audienceSummary: PropTypes.oneOf(Object.values(AudienceSummary)).isRequired,
  saveAudience: PropTypes.func.isRequired,
  setActiveCard: PropTypes.func.isRequired,
  children: PropTypes.node,
  cardStatus: PropTypes.oneOf(Object.values(STATUS)),
  patientCount: PropTypes.number.isRequired,
  message: PropTypes.shape({
    patientsCounting: PropTypes.func.isRequired,
    patientsSelection: PropTypes.func.isRequired,
  }).isRequired,
  audienceValid: PropTypes.bool.isRequired,
};

export default ChooseCampaignAudience;
