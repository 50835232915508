/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import {
  Heading,
  HeadingProps,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, line, spacing } = tokens;

type TierConnectionProps = {
  isLast: boolean;
  bgColor: 'neutral' | 'gradient' | 'warning';
};

type TierNumberProps = {
  active: boolean;
  highlighted: boolean;
};

export const Root = styled('div')(() => ({
  backgroundColor: color.primary.main,
}));

export const Content = styled('div')(({ theme }) => ({
  maxWidth: 840,
  margin: '0 auto',
  padding: `${spacing.xs} ${spacing.sm}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    columnGap: spacing.lg,
  },
}));

export const Header = styled(Heading)({
  whiteSpace: 'nowrap',
});

export const LoaderWrapper = styled('div')(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  padding: `${spacing.md} 0`,

  [theme.breakpoints.up('sm')]: {
    padding: 0,
  },
}));

export const TierWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'auto repeat(5, 1fr auto)',
  alignItems: 'center',
  padding: `${spacing.md} 0`,

  [theme.breakpoints.up('sm')]: {
    padding: 0,
  },
}));

export const TierNumber = styled(
  ({ active, highlighted, ...rest }: TierNumberProps & HeadingProps) => (
    <Heading {...rest} />
  ),
)({
  backgroundColor: ({ active }: TierNumberProps) =>
    active ? color.feedback.warning.main : color.neutral[0],
  width: ({ highlighted }: TierNumberProps) => (highlighted ? 44 : 32),
  lineHeight: ({ highlighted }: TierNumberProps) =>
    highlighted ? line.height.xl : line.height.lg,
  borderRadius: '100%',
  textAlign: 'center',
  userSelect: 'none',
  borderStyle: 'solid',
  borderColor: color.feedback.warning.main,
  borderWidth: ({ highlighted }: TierNumberProps) => (highlighted ? 2 : 0),
});

export const TierStarWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
});

export const TierStarNumber = styled(Heading)(() => ({
  position: 'absolute',
}));

const tierConnectionBackgroundMap = {
  neutral: color.neutral[0],
  gradient: `linear-gradient(
    to right,
    ${color.feedback.warning.main},
    ${color.neutral[0]},
    ${color.neutral[0]}
  )`,
  warning: color.feedback.warning.main,
};

export const TierConnection = styled(
  ({
    isLast,
    bgColor,
    ...rest
  }: TierConnectionProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >) => <div {...rest} />,
)({
  width: ({ isLast }: TierConnectionProps) =>
    isLast ? 'calc(100% + 10px)' : 'calc(100% + 2px)',
  marginLeft: '-1px',
  height: 8,
  background: ({ bgColor }: TierConnectionProps) =>
    tierConnectionBackgroundMap[bgColor],
});
