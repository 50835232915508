// External
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Internal
import style from './style.scss';

const Body = ({ children, classes = {} }) => <div className={cn(style.body, classes.body)}>{children}</div>;

Body.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.shape({
    body: PropTypes.string,
  }),
};

export default Body;
