import { combineReducers } from 'redux';

import patientReducer from './patient/reducer';
import { PatientState } from './patient/actions';

import professionalReducer from './professional/reducer';
import { ProfessionalState } from './professional/actions';

import rootReducer from './root/reducer';
import { RootState } from './root/actions';

import automatedEmailFlow, { AutomatedEmailFlowState } from './emailFlowManagement/reducer';

export type EmailFlowState = {
  patient: PatientState;
  professional: ProfessionalState;
  automatedEmailFlow: AutomatedEmailFlowState;
  root: RootState;
};

const emailFlowReducer = combineReducers<EmailFlowState>({
  patient: patientReducer,
  professional: professionalReducer,
  automatedEmailFlow,
  root: rootReducer,
});

export default emailFlowReducer;
