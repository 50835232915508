import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';
import { SSOTokenRawData } from './types';

export const getSSOToken = ({
  onlinePayment,
}: StoreState): SSOTokenRawData | undefined | null =>
  onlinePayment.iClinicPay.ssoToken?.data;

export const getSSOTokenStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.iClinicPay.ssoToken?.status;
