import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import GuideSADT from 'features/tissInvoicing/containers/sadt/GuideSADT';

const Root = () => (
  <Switch>
    <Route path="/faturamento-tiss/sadt/:eventId" component={GuideSADT} />
    <Redirect to="/404/" />
  </Switch>
);

export default Root;
