import React from 'react';

import { TextField, MaterialUICore } from '@iclinic/design-system';
import councils from 'shared/constants/councils';
import useStyles from './dialogStyles.style';
import { SelectProps } from '../types';

const { MenuItem } = MaterialUICore;

const renderOption = (council: { id: number; label: string }) => (
  <MenuItem key={council.id} value={council.label}>
    {council.label}
  </MenuItem>
);

function CouncilSelect({
  handleChange,
  error,
  value,
  disabled,
  helperText,
}: SelectProps) {
  const classes = useStyles();

  return (
    <TextField
      label="Conselho"
      id="council_name"
      select
      name="council_name"
      error={error}
      onChange={handleChange}
      value={value}
      disabled={disabled}
      data-ga="cfmupdate-council_name"
      SelectProps={{ native: false }}
      margin="normal"
      className={classes.inputWidth}
      helperText={helperText}
    >
      {councils.map(renderOption)}
    </TextField>
  );
}

export default CouncilSelect;
