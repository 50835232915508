import { StoreState } from 'state/rootReducer';

export const getUserSubscriptionPlan = ({ userInfo }: StoreState) =>
  userInfo.userData.subscriptionPlan;

export const getUserKind = ({ userInfo }: StoreState) =>
  userInfo.userData.userKind;

export const getTotalPayingUsers = ({ upgradeCall }: StoreState) =>
  upgradeCall.totalPayingUsers;

export const getLoadingStatus = ({ upgradeCall }: StoreState) =>
  upgradeCall.loading;
