import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  orderNumberText: {
    color: theme.palette.text.disabled,
  },
  copyOrder: {
    marginTop: '0.2em',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginTop: '1em',
    },
  },
}));
