import { call, put, select, takeLatest } from 'redux-saga/effects';

import { Response } from '@types';
import { gotoAppRoute } from 'shared/utils/appRoutes';
import * as actions from '.';
import { getDownsellReasons } from './selectors';
import * as services from '../../services/downsell-reason';
import {
  DownsellOptionsResponse,
  DownsellReasonResponse,
} from '../../services/types';

type FetchDownsellDataPayload = ReturnType<typeof actions.fetchDownsellData>;

type SubmitDownsellReasonPayload = ReturnType<
  typeof actions.submitDownsellReasons
>;

export function* fetchDownsellDataWorker({
  payload,
}: FetchDownsellDataPayload) {
  try {
    const {
      data: downsellReasons,
    }: Response<DownsellReasonResponse> = yield call(
      services.postDownsellReason,
      { deleted_users: payload },
    );

    const {
      data: downsellOptions,
    }: Response<DownsellOptionsResponse> = yield call(
      services.getDownsellOptions,
    );

    yield put(
      actions.fetchDownsellDataSuccess({
        downsellReasons: downsellReasons.reason_downsell_valids,
        downsellOptions: downsellOptions.objects,
      }),
    );
  } catch (e) {
    yield put(actions.fetchDownsellDataFailure());
  }
}

export function* submitDownsellReasonsWorker({
  payload,
}: SubmitDownsellReasonPayload) {
  try {
    yield put(actions.submitDownsellReasonsStart());

    const reasons_downsell: number[] = yield select(getDownsellReasons);

    yield call(services.updateDownsellReason, { reasons_downsell, ...payload });

    yield put(actions.submitDownsellReasonsSuccess());
  } catch (err) {
    yield put(actions.submitDownsellReasonsFailure());
  } finally {
    gotoAppRoute('/configuracoes/usuarios/');
  }
}

export default function* downsellReasonSagas() {
  yield takeLatest(actions.fetchDownsellData, fetchDownsellDataWorker);
  yield takeLatest(actions.submitDownsellReasons, submitDownsellReasonsWorker);
}
