import { image } from 'shared/utils/urlGetter';
import { Kind } from '../types';

export const imageSrc: Record<Kind, string> = {
  physician: image('referrals.banner'),
  receptionist: image('referrals.bannerReceptionist'),
};

export const imageAlt: Record<Kind, string> = {
  physician: 'Médico indicando iClinic para outro',
  receptionist: 'Recepcionista indicando iClinic para médico',
};
