// External
import React from 'react';
import { reduxForm, FieldArray, isInvalid } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';

// Internal
import { FORM_EDIT_CAMPAIGN } from 'shared/constants/forms';
import NotificationExpireMessage from 'shared/components/NotificationExpireMessage';
import {
  EDITOR_LINK_MODAL,
  EDITOR_BUTTON_MODAL,
  EDITOR_IMAGE_MODAL,
  SEND_EMAIL_TEST_MODAL,
  SCHEDULE_EMAIL_MODAL,
  SEND_EMAIL_NOW_MODAL,
} from 'features/campaign/modals';
import ModalRoot from 'features/modal/containers/ModalRoot';
import * as selectors from 'state/campaign/selectors';
import { open, close } from 'state/modal/actions';
import * as actions from 'state/campaign/actions';
import { STATUS } from 'ui/new/actionable-card/constants';
import {
  AudienceFilter,
  CampaignStatus,
  AudienceSummary,
} from 'state/campaign/constants';
import EditCampaignComponent from '../components/EditCampaign';
import TextEditor from '../components/mail/editor/TextEditor';
import { CampaignMailEditor } from '../components/mail';
import { FinalizeCampaign } from '../components/finalize';
import {
  CampaignAudienceFilterArray,
  ChooseCampaignAudience,
} from '../components/audiences';
import FilterSeenBy from './FilterSeenBy';
import FilterCID from './FilterCID';
import FilterPatientTag from './FilterPatientTag';
import FilterProcedure from './FilterProcedure';
import FilterAge from '../components/audiences/FilterAge';
import FilterGender from '../components/audiences/FilterGender';
import SegmentCampaignAudience from './SegmentCampaignAudience';
import style from './EditCampaign.scss';

const getComponentForFilter = ({ filter }) => {
  switch (filter) {
    case AudienceFilter.seenBy:
      return FilterSeenBy;
    case AudienceFilter.cid:
      return FilterCID;
    case AudienceFilter.patientTag:
      return FilterPatientTag;
    case AudienceFilter.procedure:
      return FilterProcedure;
    case AudienceFilter.age:
      return FilterAge;
    case AudienceFilter.gender:
      return FilterGender;
    case AudienceFilter.all:
      return null;
    default:
      return null;
  }
};

const EditCampaign = ({
  fetchCampaignDetails,
  match,
  setActiveCard,
  activeCard,
  saveAudience,
  saveMail,
  finalizeValues,
  finalize,
  audienceSummary,
  editor,
  updateTextEditor,
  openModalEditorLink,
  openModalEditorButton,
  openModalEditorImage,
  editorOptionsText,
  currentInfo,
  initialEditor,
  isFetchingDetails,
  onAutomatedTagChosen,
  campaignTitles,
  invalid,
  currentTitle,
  openModalSendEmailTest,
  audienceCardStatus,
  mailCardStatus,
  finalizeCardStatus,
  fetchUserProfiles,
  fetchPatientCount,
  senders,
  patientCount,
  audiences = [],
  fetchProcedures,
  openModalScheduleEmail,
  openModalSendEmailNow,
  fetchPatientTags,
  clinicId = undefined,
  errors = [],
  clearErrorsUpdateCampaign,
  audienceValid,
  isFormValid,
  userInfo,
}) => {
  React.useEffect(() => {
    fetchCampaignDetails(match.params.campaignId);
  }, [fetchCampaignDetails, match.params.campaignId]);

  React.useEffect(() => {
    updateTextEditor(initialEditor);
  }, [updateTextEditor, initialEditor]);

  React.useEffect(() => {
    fetchUserProfiles();
  }, [fetchUserProfiles]);

  React.useEffect(() => {
    if (clinicId && audienceValid && audiences.length > 0) {
      fetchPatientCount(clinicId, audiences);
    }
  }, [audiences, clinicId, fetchPatientCount, audienceValid]);

  React.useEffect(() => {
    fetchProcedures();
  }, [fetchProcedures]);

  React.useEffect(() => {
    if (clinicId) {
      fetchPatientTags(clinicId);
    }
  }, [fetchPatientTags, clinicId]);

  const openModalLink = React.useCallback(() => {
    openModalEditorLink(editor.editorState);
  }, [editor.editorState, openModalEditorLink]);

  const openModalSchedule = React.useCallback(() => {
    openModalScheduleEmail(patientCount, finalizeValues);
  }, [finalizeValues, openModalScheduleEmail, patientCount]);

  const openModalSendEmail = React.useCallback(() => {
    openModalSendEmailNow(patientCount);
  }, [openModalSendEmailNow, patientCount]);

  const openModalSendTest = React.useCallback(() => {
    openModalSendEmailTest(userInfo);
  }, [openModalSendEmailTest, userInfo]);

  const messagePatientCount = {
    patientsCounting: (count) =>
      `${count} ${count === 1 ? 'paciente' : 'pacientes'}`,
    patientsSelection: (count) =>
      count === 1 ? ' está selecionado.' : ' estão selecionados.',
  };

  const notificationErrors = React.useMemo(
    () =>
      errors.map((item) => (
        <NotificationExpireMessage
          kind="error"
          title="Campanha de e-mails"
          showCloseButton={false}
          key={item.id}
          onExpire={clearErrorsUpdateCampaign}
        >
          {item.message}
        </NotificationExpireMessage>
      )),
    [clearErrorsUpdateCampaign, errors],
  );

  if (isFetchingDetails) {
    return (
      <EditCampaignComponent
        currentTitle={currentTitle}
        campaignTitles={campaignTitles}
      >
        Carregando...
      </EditCampaignComponent>
    );
  }

  return (
    <div className={style.editCampaignContainer}>
      {notificationErrors}
      <EditCampaignComponent
        currentTitle={currentTitle}
        campaignTitles={campaignTitles}
      >
        <ChooseCampaignAudience
          setActiveCard={setActiveCard}
          activeCard={activeCard}
          audienceSummary={audienceSummary}
          saveAudience={saveAudience}
          cardStatus={audienceCardStatus}
          patientCount={patientCount}
          message={messagePatientCount}
          audienceValid={audienceValid}
        >
          <FieldArray
            name="audiences"
            component={CampaignAudienceFilterArray}
            getComponentForFilter={getComponentForFilter}
          />
          <FieldArray name="audiences" component={SegmentCampaignAudience} />
        </ChooseCampaignAudience>
        <CampaignMailEditor
          setActiveCard={setActiveCard}
          activeCard={activeCard}
          saveMail={saveMail}
          cardStatus={mailCardStatus}
          senders={senders}
        >
          <TextEditor
            editor={editor}
            editorOptionsText={editorOptionsText}
            updateTextEditor={updateTextEditor}
            openModalEditorLink={openModalLink}
            openModalEditorButton={openModalEditorButton}
            openModalEditorImage={openModalEditorImage}
            currentInfo={currentInfo}
            onAutomatedTagChosen={onAutomatedTagChosen}
          />
        </CampaignMailEditor>
        <FinalizeCampaign
          isInvalid={invalid}
          selectedStatus={finalizeValues.status}
          finalize={finalize}
          setActiveCard={setActiveCard}
          activeCard={activeCard}
          openModalSendEmailTest={openModalSendTest}
          cardStatus={finalizeCardStatus}
          openModalScheduleEmail={openModalSchedule}
          openModalSendEmailNow={openModalSendEmail}
          isFormValid={isFormValid}
        />
        <ModalRoot />
      </EditCampaignComponent>
    </div>
  );
};

EditCampaign.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  fetchCampaignDetails: PropTypes.func.isRequired,
  setActiveCard: PropTypes.func.isRequired,
  activeCard: PropTypes.string.isRequired,
  saveAudience: PropTypes.func.isRequired,
  saveMail: PropTypes.func.isRequired,
  finalizeValues: PropTypes.shape({
    status: PropTypes.oneOf(Object.values(CampaignStatus)),
  }).isRequired,
  finalize: PropTypes.func.isRequired,
  audienceSummary: PropTypes.oneOf(Object.values(AudienceSummary)).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired,
  updateTextEditor: PropTypes.func.isRequired,
  openModalEditorLink: PropTypes.func.isRequired,
  openModalEditorButton: PropTypes.func.isRequired,
  openModalEditorImage: PropTypes.func.isRequired,
  editorOptionsText: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.int,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  currentInfo: PropTypes.shape({
    inlineStyles: PropTypes.shape({
      has: PropTypes.func.isRequired,
    }).isRequired,
    blockType: PropTypes.string,
    entityType: PropTypes.string,
    entityData: PropTypes.shape({
      automatedTag: PropTypes.string,
    }),
    selectionText: PropTypes.string,
    entityDecoratedText: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialEditor: PropTypes.object.isRequired,
  isFetchingDetails: PropTypes.bool.isRequired,
  onAutomatedTagChosen: PropTypes.func.isRequired,
  campaignTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  invalid: PropTypes.bool.isRequired,
  currentTitle: PropTypes.string.isRequired,
  openModalSendEmailTest: PropTypes.func.isRequired,
  audienceCardStatus: PropTypes.oneOf(Object.values(STATUS)).isRequired,
  mailCardStatus: PropTypes.oneOf(Object.values(STATUS)).isRequired,
  finalizeCardStatus: PropTypes.oneOf(Object.values(STATUS)).isRequired,
  fetchUserProfiles: PropTypes.func.isRequired,
  fetchPatientCount: PropTypes.func.isRequired,
  senders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  patientCount: PropTypes.number.isRequired,
  audiences: PropTypes.arrayOf(
    PropTypes.shape({
      filter: PropTypes.string.isRequired,
      arguments: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          value: PropTypes.number,
          type: PropTypes.string,
        }),
      ]),
    }),
  ),
  fetchProcedures: PropTypes.func.isRequired,
  openModalScheduleEmail: PropTypes.func.isRequired,
  openModalSendEmailNow: PropTypes.func.isRequired,
  fetchPatientTags: PropTypes.func.isRequired,
  clinicId: PropTypes.number,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.number,
      message: PropTypes.string,
    }),
  ),
  clearErrorsUpdateCampaign: PropTypes.func.isRequired,
  audienceValid: PropTypes.bool.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  userInfo: PropTypes.shape({
    physicianId: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  initialValues: selectors.getInitialFormData(state),
  initialEditor: selectors.getInitialEditor(state),
  senders: selectors.getUserProfiles(state),
  activeCard: selectors.getActiveCard(state),
  finalizeValues: selectors.getEditCampaignValues(state),
  audienceSummary: selectors.getAudienceSummary(state),
  audiences: selectors.getAudiences(state),
  editor: selectors.getEditor(state),
  editorOptionsText: selectors.getEditorOptionsText(state),
  isFetchingDetails: selectors.getDetailsCampaignsIsFetching(state),
  currentInfo: selectors.getCurrentInfo(state),
  campaignTitles: selectors.getAllCampaignsTitles(state),
  invalid: isInvalid(FORM_EDIT_CAMPAIGN)(state),
  currentTitle: selectors.getCurrentTitle(state),
  audienceCardStatus: selectors.getAudienceCardStatus(state),
  mailCardStatus: selectors.getMailCardStatus(state),
  finalizeCardStatus: selectors.getFinalizeCardStatus(state),
  patientCount: selectors.getPatientCount(state),
  clinicId: selectors.getClinicId(state),
  errors: selectors.getListErrorsEditCampaign(state),
  audienceValid: selectors.isAudienceValid(state),
  isFormValid: selectors.isFormValid(state),
  userInfo: selectors.getUserInfo(state),
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(actions, dispatch),
  openModalEditorLink: () =>
    dispatch(
      open(EDITOR_LINK_MODAL, {
        onCloseClick: () => {
          dispatch(close());
        },
        onAddURL: (textSelected, url) => {
          dispatch(actions.onModalEditorLinkSubmit(textSelected, url));
        },
      }),
    ),
  openModalEditorButton: () =>
    dispatch(
      open(EDITOR_BUTTON_MODAL, {
        onCloseClick: () => {
          dispatch(close());
        },
        onAddURL: (textSelected, url) => {
          dispatch(actions.onModalEditorButtonSubmit(textSelected, url));
        },
      }),
    ),
  openModalEditorImage: () =>
    dispatch(
      open(EDITOR_IMAGE_MODAL, {
        onCloseClick: () => {
          dispatch(close());
        },
        onAddURL: (url) => {
          dispatch(actions.onModalEditorImageSubmit(url));
        },
      }),
    ),
  openModalSendEmailTest: (userInfo) =>
    dispatch(
      open(SEND_EMAIL_TEST_MODAL, {
        userInfo,
        onCloseClick: () => {
          dispatch(close());
        },
        onSendEmailTest: (physicianId, email) => {
          dispatch(actions.sendTestEmail(physicianId, email));
        },
      }),
    ),
  openModalScheduleEmail: (patientCount, schedule) =>
    dispatch(
      open(SCHEDULE_EMAIL_MODAL, {
        patientCount,
        schedule,
        onCloseClick: () => {
          dispatch(close());
        },
        onScheduleEmail: () => {
          dispatch(actions.scheduleEmail());
        },
      }),
    ),
  openModalSendEmailNow: (patientCount) =>
    dispatch(
      open(SEND_EMAIL_NOW_MODAL, {
        patientCount,
        onCloseClick: () => {
          dispatch(close());
        },
        onSendEmailNow: () => {
          dispatch(actions.sendEmailNow());
        },
      }),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: FORM_EDIT_CAMPAIGN,
    enableReinitialize: true,
  })(EditCampaign),
);
