import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Box, MaterialUICore } from '@iclinic/design-system';
import { RequestStatus } from 'shared/constants/State';
import {
  getBoxStatus,
  isFetchingBox,
} from '../../state/request-samples/selectors';
import { fetchSampleBox } from '../../state/request-samples';
import Form from './Form';

const { CircularProgress } = MaterialUICore;

type RequestSamplesFormParams = {
  boxId: string;
};

export default function RequestSamplesForm({
  match,
}: RouteComponentProps<RequestSamplesFormParams>) {
  const { boxId } = match.params;
  const dispatch = useDispatch();
  const isFetching = useSelector(isFetchingBox);
  const boxStatus = useSelector(getBoxStatus);

  useEffect(() => {
    dispatch(fetchSampleBox(Number(boxId)));
  }, [boxId, dispatch]);

  if (boxStatus === RequestStatus.Error) return null;

  if (isFetching)
    return (
      <Box display="flex" justifyContent="center" py={3}>
        <CircularProgress data-testid="loading-request-samples-form" />
      </Box>
    );

  return <Form boxId={boxId} />;
}
