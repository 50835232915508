import React from 'react';
import { formatDate } from 'shared/utils/date';

type CustomProps = {
  schedule: string,
};

export default function Custom({ schedule }: CustomProps): JSX.Element {
  return (
    <div>
      Será enviado a partir de:
      <strong>{` ${formatDate(schedule)}`}</strong>
    </div>
  );
}
