import { useCallback, useState } from 'react';
import Video, {
  LocalVideoTrack,
  LocalAudioTrack,
  CreateLocalTrackOptions,
  LocalDataTrack,
} from 'twilio-video';

import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_AUDIO_INPUT_KEY,
  SELECTED_VIDEO_INPUT_KEY,
} from 'features/video-conference/constants';
import {
  getDeviceInfo,
  isPermissionDenied,
} from 'features/video-conference/utils';

export default function useLocalTracks() {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(true);
  const dataTrack = new LocalDataTrack();

  const getLocalAudioTrack = useCallback(async (deviceId?: string) => {
    const options: CreateLocalTrackOptions = {};

    if (deviceId) {
      options.deviceId = { exact: deviceId };
    }

    const newTrack = await Video.createLocalAudioTrack(options);
    setAudioTrack(newTrack);
    return newTrack;
  }, []);

  const getLocalVideoTrack = useCallback(async () => {
    const selectedVideoDeviceId = window.localStorage.getItem(
      SELECTED_VIDEO_INPUT_KEY,
    );

    const { videoInputDevices } = await getDeviceInfo();

    const hasSelectedVideoDevice = videoInputDevices.some(
      (device) =>
        selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
    );

    const options: CreateLocalTrackOptions = {
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      name: `camera-${Date.now()}`,
      ...(hasSelectedVideoDevice && {
        deviceId: { exact: selectedVideoDeviceId! },
      }),
    };

    return Video.createLocalVideoTrack(options).then((newTrack) => {
      videoTrack?.stop();
      setVideoTrack(newTrack);
      return newTrack;
    });
  }, [videoTrack]);

  const getLocalTrackConstraints = useCallback(
    async (
      audioInputDevices,
      videoInputDevices,
      hasAudioInputDevices,
      hasVideoInputDevices,
    ) => {
      const selectedAudioDeviceId = window.localStorage.getItem(
        SELECTED_AUDIO_INPUT_KEY,
      );
      const selectedVideoDeviceId = window.localStorage.getItem(
        SELECTED_VIDEO_INPUT_KEY,
      );

      const hasSelectedAudioDevice = audioInputDevices.some(
        (device: { deviceId: string }) =>
          selectedAudioDeviceId && device.deviceId === selectedAudioDeviceId,
      );
      const hasSelectedVideoDevice = videoInputDevices.some(
        (device: { deviceId: string }) =>
          selectedVideoDeviceId && device.deviceId === selectedVideoDeviceId,
      );

      // In Chrome, it is possible to deny permissions to only audio or only video.
      // If that has happened, then we don't want to attempt to acquire the device.
      const isCameraPermissionDenied = await isPermissionDenied(
        'camera' as PermissionName,
      );
      const isMicrophonePermissionDenied = await isPermissionDenied(
        'microphone' as PermissionName,
      );

      const shouldAcquireVideo =
        hasVideoInputDevices && !isCameraPermissionDenied;
      const shouldAcquireAudio =
        hasAudioInputDevices && !isMicrophonePermissionDenied;

      const localTrackConstraints = {
        video: shouldAcquireVideo && {
          ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
          name: `camera-${Date.now()}`,
          ...(hasSelectedVideoDevice && {
            deviceId: { exact: selectedVideoDeviceId! },
          }),
        },
        audio:
          shouldAcquireAudio &&
          (hasSelectedAudioDevice
            ? { deviceId: { exact: selectedAudioDeviceId! } }
            : hasAudioInputDevices),
      };

      return {
        localTrackConstraints,
        isCameraPermissionDenied,
        isMicrophonePermissionDenied,
      };
    },
    [],
  );

  const findTrack = (tracks: Video.LocalTrack[], kind: string) => {
    return tracks.find((track) => track.kind === kind);
  };

  const handleCreateVideoTrack = useCallback(
    (tracks: Video.LocalTrack[]) => {
      videoTrack?.stop();
      const newVideoTrack = findTrack(tracks, 'video') as LocalVideoTrack;
      if (newVideoTrack) {
        setVideoTrack(newVideoTrack);
        // Save the deviceId so it can be picked up by the VideoInputList component. This only matters
        // in cases where the user's video is disabled.
        window.localStorage.setItem(
          SELECTED_VIDEO_INPUT_KEY,
          newVideoTrack.mediaStreamTrack.getSettings().deviceId ?? '',
        );
      }
    },
    [videoTrack],
  );

  const handleCreateAudioTrack = useCallback(
    (tracks: Video.LocalTrack[]) => {
      audioTrack?.stop();
      const newAudioTrack = findTrack(tracks, 'audio') as LocalAudioTrack;
      if (newAudioTrack) {
        setAudioTrack(newAudioTrack);
      }
    },
    [audioTrack],
  );

  const getAudioAndVideoTracks = useCallback(async () => {
    const {
      audioInputDevices,
      videoInputDevices,
      hasAudioInputDevices,
      hasVideoInputDevices,
    } = await getDeviceInfo();

    if (!hasAudioInputDevices && !hasVideoInputDevices)
      return Promise.resolve();
    if (audioTrack || videoTrack) return Promise.resolve();

    const {
      localTrackConstraints,
      isCameraPermissionDenied,
      isMicrophonePermissionDenied,
    } = await getLocalTrackConstraints(
      audioInputDevices,
      videoInputDevices,
      hasAudioInputDevices,
      hasVideoInputDevices,
    );

    return Video.createLocalTracks(localTrackConstraints)
      .then((tracks) => {
        handleCreateVideoTrack(tracks);
        handleCreateAudioTrack(tracks);

        if (isCameraPermissionDenied && isMicrophonePermissionDenied) {
          const error = new Error();
          error.name = 'NotAllowedError';
          throw error;
        }

        if (isCameraPermissionDenied)
          throw new Error('CameraPermissionsDenied');

        if (isMicrophonePermissionDenied)
          throw new Error('MicrophonePermissionsDenied');
      })
      .finally(() => setIsAcquiringLocalTracks(false));
  }, [
    audioTrack,
    videoTrack,
    getLocalTrackConstraints,
    handleCreateVideoTrack,
    handleCreateAudioTrack,
  ]);

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const localTracks = [audioTrack, videoTrack, dataTrack].filter(
    (track) => track !== undefined,
  ) as (LocalAudioTrack | LocalVideoTrack | LocalDataTrack)[];

  return {
    localTracks,
    isAcquiringLocalTracks,
    getLocalVideoTrack,
    getAudioAndVideoTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getLocalAudioTrack,
  };
}
