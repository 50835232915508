// Internal
import * as types from './constants';
import { ImageComparisonState } from './types';
import { ImageComparisonActions } from './actions';

export const initialState: ImageComparisonState = {
  isOpenModal: false,
  itemImg: null,
  images: [],
  errors: [],
  imageIdSelected: null,
  isFetching: false,
  isSavingComparison: false,
  patientId: null,
  errorLimit: false,
  mode: '',
  isMedicalCare: false,
  eventId: null,
  base64: '',
  imageId: null,
  eventAditionalId: null,
  selectedImages: [],
  canvas: null,
  notification: {
    show: false,
    kind: '',
    title: '',
    description: '',
  },
};

const imageComparison = (
  state = initialState,
  action: ImageComparisonActions,
): ImageComparisonState => {
  switch (action.type) {
    case types.FETCH_NOTIFICATION.SUCCESS:
      return {
        ...state,
        notification: action.payload.notification,
      };
    case types.CANVAS_IMAGE.ACTION:
      return {
        ...state,
        isSavingComparison: true,
      };
    case types.OPEN_MODAL.SUCCESS:
      return {
        ...state,
        isOpenModal: true,
        itemImg: action.payload.itemImg,
        patientId: action.payload.patientId,
        isMedicalCare: action.payload.isMedicalCare,
        currentEvent: action.payload.eventId,
        isFetching: true,
      };
    case types.CLOSE_MODAL.ACTION:
      return {
        ...state,
        isSavingComparison: false,
        isOpenModal: action.payload.isOpenModal,
      };
    case types.GET_IMAGES.SUCCESS:
      return {
        ...state,
        images: action.payload.images,
        isFetching: false,
      };
    case types.GET_IMAGES.FAILURE:
      return {
        ...state,
        errors: action.payload.errors,
        isFetching: false,
      };
    case types.SELECT_IMAGE.SUCCESS:
      return {
        ...state,
        images: action.payload.images,
      };
    case types.SET_MODE.ACTION:
      return {
        ...state,
        mode: action.payload.mode,
      };
    default:
      return state;
  }
};

export default imageComparison;
