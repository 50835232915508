import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, tokens } from '@iclinic/design-system';

import { UpgradeCallCard } from 'features/subscription/upgradeCall/components/UpgradeCallCard';
import { openUpgradeModal } from 'features/subscription/upgradeCall/state/actions';
import { getLoadingStatus } from 'features/subscription/upgradeCall/state/selectors';
import PhysicianContent from 'features/subscription/upgradeCall/components/PhysicianContent';
import { upgradeModalContent } from './constants';
import { getUserSubscriptionPlan } from 'features/reports/state/telemedicineReport/selectors';
import { UpgradeCallWrapper } from './UpgradeCall.styles';
import { dispatchGaEvent } from 'shared/utils/googleAnalytics';

export const UpgradeCall = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getLoadingStatus);
  const userPlan = useSelector(getUserSubscriptionPlan);

  const onClickHandler = () => {
    dispatch(openUpgradeModal('premium'));
    dispatchGaEvent('data_ga_click', {
      event_name: 'setup_teleconsultationreport_upgrade_click',
    });
  };

  const { title, imagePath } = upgradeModalContent;

  if (userPlan === 'premium') return null;

  return (
    <UpgradeCallWrapper>
      <UpgradeCallCard
        imagePath={imagePath}
        title={title}
        onImageClick={onClickHandler}
      >
        <PhysicianContent content={upgradeModalContent} />
        <Box mt={tokens.spacing.md}>
          <Button isLoading={isLoading} onClick={onClickHandler}>
            Quero mudar meu plano
          </Button>
        </Box>
      </UpgradeCallCard>
    </UpgradeCallWrapper>
  );
};
