import { PERIOD_SELECT } from '../../state/subscription/constants';

export const hasPromotionOnPeriod = (product, isMonthly) => {
  // checks if the product has monthly or annual promotions
  // expects the contract: product: null || product.promotion: null
  // or any annual or monthly frequency null or with value
  if (product && product.promotion) {
    const period = isMonthly ? PERIOD_SELECT.monthly : PERIOD_SELECT.annual;
    const promotionFrequency = product.promotion.frequencies[period];
    return !!(promotionFrequency && promotionFrequency.value !== null);
  }
  return false;
};

export default hasPromotionOnPeriod;
