import React from 'react';
import PropTypes from 'prop-types';

import SelectBox from '../selectBox/SelectBox';
import style from './SelectWorkHours.scss';

const SelectHours = ({ id, value, options, onChange, disabled }) => (
  <div className={style.workHour__inputsContainer}>
    <div className={style.inputCheckBoxContainer}>
      <SelectBox id={id} value={value} onChange={onChange} disabled={disabled}>
        <option> </option>
        {options.map((hour) => (
          <option value={hour.id} key={hour.id}>
            {hour.name}
          </option>
        ))}
      </SelectBox>
    </div>
  </div>
);

SelectHours.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default SelectHours;
