export enum COLOR {
  Black = '-black',
  White = '-white',
  Gray = '-gray',
}

export enum ALIGN {
  Center = '-center',
  Left = '-left',
}
