export type UserInfo = {
  profileId: string | null;
  subscriptionId: string | undefined;
  isSubscriber: boolean | null | undefined;
};

export type SubscriptionFormData = {
  cardCVV: string;
  cardExpirationDate: string;
  cardNumber: string;
  cardOwnerName: string;
  cpfCnpj: string;
  turnstileToken: string;
};

export type PhysiciansByAddon = {
  [key: string]: number[];
};

export enum StepTypes {
  PAYMENT = 'payment-form',
  SUCCESS = 'success',
}
