import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled, Drawer } = MaterialUICore;

export const Container = styled('div')({
  position: 'relative',
});

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  pointerEvents: 'none',

  '& .MuiDialog-container': {
    pointerEvents: 'none',
  },

  '& .MuiPaper-root': {
    width: '384px',
    pointerEvents: 'all',
  },

  [theme.breakpoints.down('md')]: {
    '& .MuiPaper-root': {
      width: '100%',
    },
  },
}));

export const Header = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: `${axiomTokens.spacing[4]}`,

  '& h2': {
    fontFamily: axiomTokens.font.family.base,
    fontWeight: axiomTokens.font.weight.medium,
    fontSize: axiomTokens.font.size.sm,
    lineHeight: axiomTokens.font.lineHeight.sm,
    color: axiomTokens.color.typeface.pure,
    margin: '0px',
  },
}));

export const Description = styled('span')(() => ({
  width: '100%',
  fontSize: axiomTokens.font.size.xs,
  fontFamily: axiomTokens.font.family.base,
  color: axiomTokens.color.typeface.pure,
  padding: `0 ${axiomTokens.spacing[4]}`,
  marginBottom: axiomTokens.spacing[4],
}));

export const Wrapper = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gridTemplateColumns: '1fr',
  gap: axiomTokens.spacing[4],
  width: '100%',
  height: '100%',
  overflowY: 'auto',
  marginBottom: axiomTokens.spacing[4],

  '&::-webkit-scrollbar': {
    width: axiomTokens.spacing[2],
  },

  '&::-webkit-scrollbar-thumb': {
    background: axiomTokens.color.others.divider,
    borderRadius: axiomTokens.border.radius.lg,
  },

  '&::-webkit-scrollbar-track': {
    background: axiomTokens.color.others.skeleton,
    width: axiomTokens.spacing[2],
    borderRadius: axiomTokens.border.radius.lg,
  },
});
