import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getSubscriptionDate } from '../state/selectors';
import { ShouldNotRender } from '../types';
import { checkedTrigger, threeMonthsFromToday } from '../utils';

type Props = {
  isDesktop: boolean;
  shouldNotRenderAt?: ShouldNotRender;
};

export const useAdditionalPlansTrigger = ({
  isDesktop,
  shouldNotRenderAt,
}: Props) => {
  const [isAvailable, setIsAvailable] = useState(false);

  const pathName = window.location.pathname;
  const alternative = !!pathName.match(/agenda/);
  const subscriptionDate = useSelector(getSubscriptionDate);
  const alreadyCompleted = checkedTrigger();

  const useAllowed =
    threeMonthsFromToday({ subscriptionDate }) && !alreadyCompleted;

  const shouldNotRender =
    (shouldNotRenderAt === 'mobile' && !isDesktop) ||
    (shouldNotRenderAt === 'desktop' && isDesktop);

  useEffect(() => {
    const shouldRender = useAllowed && !shouldNotRender;

    setIsAvailable(shouldRender);
  }, [useAllowed, shouldNotRender]);

  return { isAvailable, alternative };
};
