import React, { useState } from 'react';
import { LocalVideoTrack } from 'twilio-video';

import {
  MaterialUICore,
  Body,
  InputAdornment,
  MaterialIcons,
  Select,
} from '@iclinic/design-system';
import {
  DEFAULT_VIDEO_CONSTRAINTS,
  SELECTED_VIDEO_INPUT_KEY,
  selectDevices,
} from 'features/video-conference/constants';
import useDevices from 'features/video-conference/hooks/useDevices';
import useMediaStreamTrack from 'features/video-conference/hooks/useMediaStreamTrack';
import { useConference } from 'features/video-conference/state/conferenceContext';
import useStyles from '../styles';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';
import { useParticipantContext } from 'features/video-conference/state/participantContext';

const { MenuItem } = MaterialUICore;
const { Videocam } = MaterialIcons;

interface VideoInputListProps {
  location?: string;
}

export default function VideoInputList({ location }: VideoInputListProps) {
  const classes = useStyles({});
  const { videoInputDevices } = useDevices();
  const { localTracks, isPhysician, conferenceId } = useConference();
  const { localParticipant, remoteParticipant } = useParticipantContext();

  const localVideoTrack = localTracks.find(
    (track) => track.kind === 'video',
  ) as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY),
  );
  const localVideoInputDeviceId =
    mediaStreamTrack?.getSettings().deviceId ||
    storedLocalVideoDeviceId ||
    videoInputDevices[0]?.deviceId;

  function replaceTrack(newDeviceId: string) {
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'telemedicine_settings_update_device',
      buttonLocation: location,
      additionalProperties: 'video_input',
    });
  }

  return (
    <div>
      {videoInputDevices.length > 0 ? (
        <Select
          onChange={(e) => replaceTrack(e.target.value as string)}
          value={localVideoInputDeviceId || ''}
          data-testid="select"
          InputProps={{
            id: 'audio-input-list',
            startAdornment: (
              <InputAdornment position="start">
                <Videocam className={classes.iconColor} />
              </InputAdornment>
            ),
          }}
          label={selectDevices.VIDEO_INPUT}
        >
          {videoInputDevices.map((device) => {
            return (
              <MenuItem value={device.deviceId} key={device.deviceId} selected>
                {device.label}
              </MenuItem>
            );
          })}
        </Select>
      ) : (
        <Body variant="xs" bold>
          {selectDevices.VIDEO_NOT_FOUND}
        </Body>
      )}
    </div>
  );
}
