// External
import React, { useState } from 'react';
import Editor from 'draft-js-plugins-editor';
import Icon from '@material/react-material-icon';
import Immutable from 'immutable';
import PropTypes from 'prop-types';

// Internal
import { image } from 'shared/utils/urlGetter';
import { typeStyleEditor, optionsAutomatedTags } from 'ui/new/editor/constants';
import Button from 'ui/new/button';
import Dropdown from 'ui/new/dropdown';
import { Tooltip } from 'ui/overlay';
import AutomatedTagsContainer from 'ui/new/editor/AutomatedTagsContainer';
import useDraftJSCallbacks from 'ui/new/editor/useDraftJSCallbacks';
import createDraftJSPlugin from 'ui/new/editor/createDraftJSPlugin';
import { blockStyleFn, blockRender, styleMap } from 'ui/new/editor';
import style from './TextEditor.scss';
// disabled warn draftJS Immutable
Immutable.Iterable.noLengthWarning = true;

const getActiveEditorButtonStyle = (active) => (active ? 'active' : 'light');
const optionsAutomatedTagsKeys = Object.keys(optionsAutomatedTags);

const imageButton = {
  active: image('campaign.editorButtonActive'),
  light: image('campaign.editorButton'),
};

const iconsDropDownFormatText = () => (
  <div>
    <Icon className={style.iconFont} icon="format_size" />
  </div>
);
const { plugins, AlignmentTool } = createDraftJSPlugin();

const TextEditor = ({
  editor,
  updateTextEditor,
  openModalEditorLink,
  openModalEditorButton,
  openModalEditorImage,
  editorOptionsText,
  currentInfo,
  onAutomatedTagChosen,
}) => {
  const [isOpen, setToggleMenu] = useState(false);

  const { editorState } = editor;
  const {
    formatTextBold,
    formatTextItalic,
    handleKeyCommand,
    formatTextTitle,
    formatTextNormal,
    formatAlignCenter,
    formatAlignLeft,
    openFormatLink,
    openFormatButton,
    openFormatImage,
    keyBindingFn,
  } = useDraftJSCallbacks(
    editorState,
    updateTextEditor,
    openModalEditorLink,
    openModalEditorButton,
    openModalEditorImage,
    currentInfo,
  );

  const editorElement = React.useRef(null);

  const selectItem = React.useCallback(
    (id) => {
      if (id === typeStyleEditor.normalID) {
        formatTextNormal();
      } else {
        formatTextTitle();
      }
    },
    [formatTextNormal, formatTextTitle],
  );

  const handleFocus = React.useCallback(() => {
    if (editorElement.current) {
      editorElement.current.focus();
    }
  }, [editorElement]);

  const activeInlineStyle = (type) =>
    getActiveEditorButtonStyle(currentInfo.inlineStyles.has(type));
  const activeBlockStyle = (type) =>
    getActiveEditorButtonStyle(currentInfo.blockType === type);
  const activeEntityStyle = (type) =>
    getActiveEditorButtonStyle(currentInfo.entityType === type);

  return (
    <div>
      <div className={style.toolBarStyle}>
        <div className={style.dropdownFormatFont}>
          <Dropdown
            title={iconsDropDownFormatText()}
            isOpenProp={isOpen}
            setToggleMenuProp={setToggleMenu}
          >
            {editorOptionsText.map((item) => (
              <Dropdown.Item
                onClick={() => selectItem(item.id)}
                selected={item.selected}
                key={item.id}
                kind="exists"
              >
                {item.selected && (
                  <Icon
                    icon="done"
                    className={style.toolBarStyle__dropDownIcon}
                  />
                )}
                {item.name}
              </Dropdown.Item>
            ))}
          </Dropdown>
        </div>
        <Button
          type={activeInlineStyle(typeStyleEditor.bold)}
          onClick={formatTextBold}
        >
          <Icon icon="format_bold" />
        </Button>
        <Button
          type={activeInlineStyle(typeStyleEditor.italic)}
          onClick={formatTextItalic}
        >
          <Icon icon="format_italic" />
        </Button>
        <Button
          type={activeBlockStyle(typeStyleEditor.alignLeft)}
          onClick={formatAlignLeft}
        >
          <Icon icon="format_align_left" />
        </Button>
        <Button
          type={activeBlockStyle(typeStyleEditor.alignCenter)}
          onClick={formatAlignCenter}
        >
          <Icon icon="format_align_center" />
        </Button>
        <Button
          type={activeEntityStyle(typeStyleEditor.image)}
          onClick={openFormatImage}
        >
          <Icon icon="image" />
        </Button>
        <Button
          type={activeEntityStyle(typeStyleEditor.link)}
          onClick={openFormatLink}
        >
          <Icon icon="link" />
        </Button>
        <Button type="light" onClick={openFormatButton}>
          <img
            className={style.iconButton}
            alt=""
            src={imageButton[activeEntityStyle(typeStyleEditor.button)]}
          />
        </Button>
      </div>

      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        className={style.editorContainer}
        title="corpo do e-mail"
        onClick={handleFocus}
        onKeyPress={handleFocus}
        role="main"
      >
        <Editor
          ref={editorElement}
          customStyleMap={styleMap}
          editorState={editorState}
          onChange={updateTextEditor}
          keyBindingFn={keyBindingFn}
          handleKeyCommand={handleKeyCommand}
          blockRenderMap={blockRender}
          blockStyleFn={blockStyleFn}
          plugins={plugins}
        />
        <AlignmentTool />
      </div>
      <div className={style.automatedTags}>
        <div className={style.automatedTagsTooltip}>
          Tags automatizadas
          <Tooltip
            content="Clique nas tags para inseri-las no texto a ser enviado. Elas serão substituídas automaticamente pelos dados reais no momento do envio."
            align="top"
          >
            <Icon icon="info" className={style.infoIcon} />
          </Tooltip>
        </div>
        <AutomatedTagsContainer
          currentAutomatedTag={(currentInfo.entityData || {}).automatedTag}
          onAutomatedTagChosen={onAutomatedTagChosen}
          tags={optionsAutomatedTagsKeys}
        />
      </div>
    </div>
  );
};

TextEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editor: PropTypes.object.isRequired,
  updateTextEditor: PropTypes.func.isRequired,
  openModalEditorLink: PropTypes.func.isRequired,
  openModalEditorButton: PropTypes.func.isRequired,
  openModalEditorImage: PropTypes.func.isRequired,
  editorOptionsText: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.int,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    }),
  ).isRequired,
  currentInfo: PropTypes.shape({
    inlineStyles: PropTypes.shape({
      has: PropTypes.func.isRequired,
    }).isRequired,
    blockType: PropTypes.string,
    entityType: PropTypes.string,
    entityData: PropTypes.shape({
      automatedTag: PropTypes.string,
    }),
    selectionText: PropTypes.string,
    entityDecoratedText: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onAutomatedTagChosen: PropTypes.func.isRequired,
};

export default TextEditor;
