export type CalculatorMetadata = {
  categoria: string[];
  area: string[];
  especialidade: string[];
  ambiente?: string[];
  tema: string[];
  jornada: string[];
};

export type Calculator = {
  id: number;
  titulo: string;
  ultimaAtualizacao: string;
  metadados: CalculatorMetadata;
};

export type CalculatorItem = {
  id: number;
  titulo: string;
  ultimaAtualizacao: string;
  url: string;
};

export type CalculatorsByCategory = {
  [key: string]: Calculator[];
};

export type CalculatorsItemsByCategory = {
  idCategoria: number;
  nomeCategoria: string;
  conteudos: CalculatorItem[];
};

export enum CalculatorDrawerView {
  LIST,
  CALCULATOR,
}

export type CalculatorDrawerState = {
  isCalculatorDrawerOpen: boolean;
  isCalculatorFetchLoading: boolean;
  calculatorsByCategory: CalculatorsItemsByCategory[];
  getCalculatorError: boolean;
  calculatorView: CalculatorDrawerView;
  calculatorSelected?: CalculatorItem;
};

export type GetCalculatorsResponse = {
  offset: number;
  limit: number;
  count: number;
  totalPages: number;
  paginationLinks: {
    first: string;
    previous?: string;
    current: string;
    next: string;
    last: string;
  };
  data: Calculator[];
};

export type UserInfo = {
  profileId: string | null;
  subscriptionId?: string;
  isSubscriber?: boolean | null;
};

export type GetCalculatorsByCategoryResponse = CalculatorsItemsByCategory[];
