import { FormikErrors } from 'formik';

import { GuideSADTForm } from 'features/tissInvoicing/types';
import { formKeys, FormKeysEnum } from '../../sadt/GuideSADT/validationSchema';

type GuideSADTKeys = keyof GuideSADTForm;
type GuideSADTSections = keyof typeof formKeys;
type ValidateFormFnProps = (errors: FormikErrors<GuideSADTForm>, sectionRef: React.RefObject<(HTMLButtonElement | null)[]>, callback: (args1: string) => void) => void;

export const validateSADTForm: ValidateFormFnProps = (errors, sectionRef, callback) => {
  const scrollInto: HTMLButtonElement[] = [];
  Object.keys(formKeys).forEach((section) => {
    const hasError = formKeys[section as GuideSADTSections].some(
      (field) => !!errors[field as GuideSADTKeys],
    );

    const formKey = FormKeysEnum[section as keyof typeof FormKeysEnum];
    const element = sectionRef.current
      ? sectionRef.current[formKey]
      : null;

    if (hasError && element) {
      if (element.getAttribute('data-status') === 'closed')
        element.click();
      scrollInto.push(element);
      callback(section);
    }
  });

  if (scrollInto.length > 0)
    scrollInto[0].scrollIntoView({ behavior: 'smooth' });
};
