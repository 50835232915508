import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, Heading, Loader, tokens } from '@iclinic/design-system';

import { RequestStatus } from 'shared/constants/State';
import { fetchReportsWithNoDate } from 'features/reports/state/telemedicineReport/reducer';
import * as S from './TelemedicineReport.styles';
import { getStatus } from 'features/reports/state/telemedicineReport/selectors';
import {
  PeriodFilter,
  PhysicianFilter,
  QuickFilters,
  TablePagination,
  TelemedicineTable,
  UpgradeCall,
} from 'features/reports/components/telemedicineReport';

export const TelemedicineReport = () => {
  const dispatch = useDispatch();
  const status = useSelector(getStatus);

  useEffect(() => {
    dispatch(fetchReportsWithNoDate());
  }, [dispatch]);

  return (
    <S.TelemedicineReportWrapper>
      <Box mb={tokens.spacing.md}>
        <Heading variant="md">Teleconsultas realizadas</Heading>
      </Box>
      <Card variant="outlined">
        <S.ContentWrapper>
          <S.TableWrapper>
            <S.FiltersWrapper>
              <S.PeriodFiltersWrapper>
                <QuickFilters />
                <PeriodFilter />
              </S.PeriodFiltersWrapper>
              <PhysicianFilter />
            </S.FiltersWrapper>
            {status === RequestStatus.Pending ? (
              <S.LoaderWrapper>
                <Loader size={60} />
              </S.LoaderWrapper>
            ) : (
              <TelemedicineTable />
            )}
          </S.TableWrapper>
          <UpgradeCall />
          <TablePagination />
        </S.ContentWrapper>
      </Card>
    </S.TelemedicineReportWrapper>
  );
};
