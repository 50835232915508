import * as Yup from 'yup';

import {
  SellerStatus,
  SellerType,
} from 'features/onlinePayment/state/constants';

const emailInvalid = 'Endereço de e-mail inválido';
const configEmailLimit =
  'Limite de e-mails atingido, só é possível ter 2 emails cadastrados';
const required = 'Campo obrigatório';
const fileValidator = (value: File) => value != null;

export const sellerRefusedFields = Yup.object().shape({
  identity: Yup.mixed().when('status', {
    is: SellerStatus.Refused,
    then: Yup.mixed().test('fileRequired', required, fileValidator),
  }),
  residence: Yup.mixed().when('status', {
    is: SellerStatus.Refused,
    then: Yup.mixed().test('fileRequired', required, fileValidator),
  }),
  cnpj: Yup.mixed().when('status', {
    is: SellerStatus.Refused,
    then: Yup.mixed().when('type', {
      is: SellerType.PJ,
      then: Yup.mixed().test('fileRequired', required, fileValidator),
    }),
  }),
});

export const validationSellerEdit = Yup.object()
  .shape({
    fieldEmailPayConfirm: Yup.string().email(emailInvalid),
    fieldEmailWeeklySummary: Yup.string().email(emailInvalid),
    paymentConfirmationEmails: Yup.array().max(2, configEmailLimit),
    weeklySummaryEmails: Yup.array().max(2, configEmailLimit),
    bank: Yup.string().required(required),
    agency: Yup.string().required(required),
    account: Yup.string().required(required),
  })
  .concat(sellerRefusedFields);
