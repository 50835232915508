import { AxiosResponse } from 'axios';
import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  deleteTextTemplate as deleteTextTemplateSevice,
  getTextTemplates,
  saveTextTemplate as saveTextTemplateService,
} from 'services/iclinic/records';
import { actions } from '.';
import { getTemplate } from './selectors';
import { getTextTemplateResponse, TextTemplateState } from './types';
import { captureException } from 'shared/utils/handlerErrors';
import { getListItemCount } from './utils';

const {
  deleteTextTemplate,
  deleteTextTemplateSuccess,
  deleteTextTemplateFailed,
  openDrawerUseTemplateSuccess,
  openDrawerUseTemplateFailed,
  saveTextTemplateFailed,
  saveTextTemplateSuccess,
  saveTextTemplate,
  openDrawerUseTextTemplate,
  setCurrentPage,
  setCurrentPageSuccess,
  setCurrentPageFailed,
} = actions;

let itemsCount: number = 10;

export function* workerSaveTemplate(action: PayloadAction<string>) {
  const title = action.payload;
  const { text }: TextTemplateState = yield select(getTemplate);

  try {
    yield call(saveTextTemplateService, {
      title,
      text: text!,
    });

    yield put(saveTextTemplateSuccess());
  } catch (error) {
    yield call(captureException, error);
    yield put(saveTextTemplateFailed());
  }
}

export function* workerOpenDrawerUserTemplate() {
  try {
    itemsCount = getListItemCount();
    const { data }: AxiosResponse<getTextTemplateResponse> = yield call(
      getTextTemplates,
      1,
      itemsCount,
    );
    const lastPage = data.meta.range.pop() || 1;
    yield put(
      openDrawerUseTemplateSuccess({
        templateList: data.objects,
        pageCount: lastPage,
      }),
    );
  } catch (error) {
    yield call(captureException, error);
    yield put(openDrawerUseTemplateFailed());
  }
}

export function* workerDeleteTextTemplate(action: PayloadAction<number>) {
  const textTemplateId = action.payload;
  try {
    yield call(deleteTextTemplateSevice, textTemplateId!);
    yield put(deleteTextTemplateSuccess(textTemplateId));
  } catch (error) {
    captureException(error);
    yield put(deleteTextTemplateFailed());
  }
}

export function* workerUpdateTemplateList(action: PayloadAction<number>) {
  const currentPage = action.payload;
  try {
    const { data }: AxiosResponse<getTextTemplateResponse> = yield call(
      getTextTemplates,
      currentPage,
      itemsCount,
    );
    yield put(setCurrentPageSuccess(data.objects));
  } catch (error) {
    captureException(error);
    yield put(setCurrentPageFailed());
  }
}

export default function* recordSagas() {
  yield all([
    takeLatest(saveTextTemplate, workerSaveTemplate),
    takeLatest(openDrawerUseTextTemplate, workerOpenDrawerUserTemplate),
    takeLatest(deleteTextTemplate, workerDeleteTextTemplate),
    takeLatest(setCurrentPage, workerUpdateTemplateList),
  ]);
}
