import React from 'react';

const ThemeContext = React.createContext({
  isApplied: false,
  style: '',
});

export const UserThemeProvider = ThemeContext.Provider;
export const UserThemeConsumer = ThemeContext.Consumer;

export const UserThemeStyle = (style, name, isApplied = false) => (isApplied ? [{ [style[name]]: isApplied }] : '');

export default ThemeContext;
