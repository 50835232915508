// External
import { put, call, takeLatest, select, all } from 'redux-saga/effects';

// Internal
import { HIRING, ADDON, SLUGS } from 'shared/constants/upgradeAddons';
import { emitErrors } from 'shared/utils/emitErrors';
import { GENERIC_ERROR_MESSAGE } from 'shared/constants/errorMessages';
import { captureException } from 'shared/utils/handlerErrors';
import { removeQueryStringFromUrl } from 'shared/utils/strings';
import products from 'shared/constants/products';
import { getUrlParams } from 'shared/utils/url';
import { workerNotifyMarketingIntent } from 'state/marketing/emailFlow/root/sagas';
import iclinic from 'services/iclinic';
import * as actions from './actions';
import * as types from './types';
import {
  normalizeSummary,
  normalizeSubscription,
  normalizeCheck,
  normalizephysiciansData,
} from './utils';
import { getSuccessInfo, getIndividualUser, getPhysicians } from './selectors';
import { VIEWSTEPS, FLOW } from './constants';
import { URL_ICLINIC_ASSIST } from 'state/billing/constants';

const getProduct = (state) => state.upgradeAddon.currentModal;
const getTotalUsers = (state) => state.upgradeAddon.totalUsers;
const getHiring = (state) => state.upgradeAddon.hiring;
const selectPhysicians = (state) => state.upgradeAddon.physicians;
const getPhysiciansid = (state) => state.upgradeAddon.selectedPhysicians;
const selectUserInfo = (state) => state.userInfo.userData;
const selectAddons = (state) => state.upgradeAddon.addons;
const selectAddonsSlugs = (state) => state.upgradeAddon.selectedProducts;
const getSharedInfo = (state) => state.shared;
const getStepAddon = (state) => state.upgradeAddon.step;
const getCurrentModal = (state) => state.upgradeAddon.currentModal;

export function* getPhysiciansSagas() {
  const { subscriptionId } = yield select(selectUserInfo);
  const selectedAddon = yield select(getProduct);
  try {
    const { getResponseData } = yield call(
      iclinic.upgradeAddon.fetchPhysiciansBySubscription,
      subscriptionId,
      selectedAddon,
    );
    const physiciansData = getResponseData();
    yield put(
      actions.fetchPhysiciansSuccess(
        normalizephysiciansData(physiciansData, selectedAddon),
      ),
    );
  } catch (error) {
    captureException(error);
    yield put(actions.fetchPhysiciansFailure(GENERIC_ERROR_MESSAGE));
  }
}

export function* selectPhysicianSagas(action) {
  try {
    const { physicianId } = action.payload;
    const physiciansSelect = yield select(selectPhysicians);

    const physicians = physiciansSelect.map((physician) => {
      if (physician.id === physicianId) {
        return { ...physician, checked: !physician.checked };
      }
      return physician;
    });

    const physiciansIds = normalizeCheck(physicians, 'id');

    yield put(actions.selectPhysicianSuccess(physicians));
    yield put(actions.fetchCalculatePrice(physiciansIds));
  } catch (error) {
    captureException(error);
    yield put(actions.selectPhysicianFailure(emitErrors(error)));
  }
}

export function* workerCalculateUpgrade() {
  try {
    const [selectedProduct, selectedTotalUser] = yield all([
      select(getProduct),
      select(getTotalUsers),
    ]);
    const { getResponseData, errors } = yield call(
      iclinic.upgradeAddon.fetchUpgradeCalculate,
      selectedProduct,
      selectedTotalUser,
    );
    if (errors) {
      yield put(actions.fetchCalculatePriceFailure(emitErrors(errors)));
    } else {
      const calculateUpgrade = getResponseData();
      yield put(actions.fetchCalculatePriceSuccess(calculateUpgrade));
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerSummaryUpgrade() {
  try {
    const [selectedProduct, selectedTotalUser] = yield all([
      select(getProduct),
      select(getTotalUsers),
    ]);

    const { subscriptionId } = yield select(selectUserInfo);

    const normalizedSummaryPayload = normalizeSummary(
      selectedProduct,
      selectedTotalUser,
      FLOW.upgrade,
    );

    const { getResponseData, errors } = yield call(
      iclinic.upgradeAddon.fetchUpgradeSummary,
      normalizedSummaryPayload,
      subscriptionId,
    );

    if (errors) {
      yield put(actions.fetchSummaryFailure(emitErrors(errors)));
    } else {
      const summary = getResponseData();
      yield put(actions.fetchSummarySuccess(summary));
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerSummaryUpgradePerUser() {
  try {
    const selectSlugs = yield select(selectAddonsSlugs);

    const { subscriptionId } = yield select(selectUserInfo);

    const normalizedSummaryPayload = {
      products: selectSlugs,
      totalUsers: 1,
      typeHiring: FLOW.upgrade,
    };

    const { getResponseData, errors } = yield call(
      iclinic.upgradeAddon.fetchUpgradeSummary,
      normalizedSummaryPayload,
      subscriptionId,
    );

    if (errors) {
      yield put(actions.fetchSummaryFailure(emitErrors(errors)));
    } else {
      const summary = getResponseData();
      yield put(actions.fetchSummarySuccess(summary));
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerSummaryUpsell() {
  const selectSlugs = yield select(selectAddonsSlugs);
  try {
    const normalizedSummaryPayload = {
      products: selectSlugs,
      totalUsers: 1,
      typeHiring: FLOW.upsell,
    };

    const { subscriptionId } = yield select(selectUserInfo);

    const { getResponseData, errors } = yield call(
      iclinic.upgradeAddon.fetchUpgradeSummary,
      normalizedSummaryPayload,
      subscriptionId,
    );

    if (errors) {
      yield put(actions.fetchSummaryFailure(emitErrors(errors)));
    } else {
      const summary = getResponseData();
      yield put(actions.fetchSummarySuccess(summary));
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerSummary() {
  try {
    const hiring = yield select(getHiring);

    switch (hiring) {
      case FLOW.upgrade:
        yield workerSummaryUpgrade();
        break;
      case FLOW.upgradePerUser:
        yield workerSummaryUpgradePerUser();
        break;
      case FLOW.upsell:
        yield workerSummaryUpsell();
        break;
      default:
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerFetchAddons() {
  try {
    const { profileId } = yield select(selectUserInfo);
    const hiring = yield select(getHiring);

    const { getResponseData, errors } = yield call(
      iclinic.upgradeAddon.fetchAddonsForUpsell,
      hiring === FLOW.upgradePerUser && profileId,
    );

    if (errors) {
      yield put(actions.fetchAddonsFailure(emitErrors(errors)));
    } else {
      const addons = getResponseData();
      yield put(actions.fetchAddonsSuccess(addons));
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerSubscriptionUpgrade() {
  try {
    const [selectedProduct, selectedUserIds] = yield all([
      select(getProduct),
      select(getPhysiciansid),
    ]);

    const normalizeSubscriptionPayload = normalizeSubscription(
      selectedProduct,
      selectedUserIds,
    );

    const { errors } = yield call(
      iclinic.upgradeAddon.fetchUpgradeSubscription,
      normalizeSubscriptionPayload,
    );

    if (errors) {
      yield put(actions.fetchCreateFailure(emitErrors(errors)));
    } else {
      yield put(actions.reload());
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerSubscriptionUpgradePerUser() {
  const { profileId } = yield select(selectUserInfo);
  const selectSlugs = yield select(selectAddonsSlugs);

  try {
    const normalizedSummaryPayload = {
      products: selectSlugs,
      users: [profileId],
    };

    const { errors } = yield call(
      iclinic.upgradeAddon.fetchUpgradeSubscription,
      normalizedSummaryPayload,
    );

    if (errors) {
      yield put(actions.fetchCreateFailure(emitErrors(errors)));
    } else {
      yield put(actions.reload());
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerSubscriptionUpsell() {
  const selectSlugs = yield select(selectAddonsSlugs);
  try {
    const data = {
      products: selectSlugs,
      users: [],
    };

    const { errors } = yield call(
      iclinic.upgradeAddon.fetchUpsellSubscription,
      data,
    );

    if (errors) {
      yield put(actions.fetchCreateFailure(emitErrors(errors)));
    } else {
      yield put(actions.reload());
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerSubscription() {
  const hiring = yield select(getHiring);

  switch (hiring) {
    case FLOW.upgrade:
      yield workerSubscriptionUpgrade();
      break;
    case FLOW.upgradePerUser:
      yield workerSubscriptionUpgradePerUser();
      break;
    case FLOW.upsell:
      yield workerSubscriptionUpsell();
      break;
    default:
  }
}

export function* workerSelectProduct(action) {
  try {
    const { productId } = action.payload;
    const { products: addonProducts } = yield select(selectAddons);

    const checkedProducts = addonProducts.map((addonProduct) => {
      if (addonProduct.id === productId) {
        return { ...addonProduct, checked: !addonProduct.checked };
      }
      return addonProduct;
    });

    const slugs = normalizeCheck(checkedProducts, 'slug');

    yield put(actions.selectProductsSuccess(checkedProducts, slugs));
  } catch (error) {
    captureException(error);
    yield put(actions.selectProductsFailure(emitErrors(error)));
  }
}

export function* workerGetDetail() {
  try {
    const selectedAddon = yield select(getProduct);

    const { getResponseData, errors } = yield call(
      iclinic.product.fetchProduct,
      selectedAddon,
    );

    if (errors) {
      yield put(actions.fetchAddonsDetailFailure(emitErrors(errors)));
    } else {
      const payload = getResponseData();
      yield put(actions.fetchAddonsDetailSuccess(payload));
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerOpenModal() {
  const hiring = yield select(getHiring);
  const { isAdmin } = yield select(selectUserInfo);
  const product = yield select(getProduct);
  const currentModal = yield select(getCurrentModal);

  if (product === products.marketing) {
    yield call(workerNotifyMarketingIntent);
  }

  if (isAdmin && (hiring === FLOW.upsell || hiring === FLOW.upgradePerUser)) {
    yield workerFetchAddons();
  } else if (isAdmin && hiring === FLOW.upgrade) {
    yield workerGetDetail();
    yield getPhysiciansSagas();
  } else if (currentModal === 'message-whatsapp') {
    yield put(actions.justOpenModal());
  }
}

export function* workerNotification({ params }) {
  try {
    const { errors } = yield call(
      iclinic.upgradeAddon.fetchUpgradeNotification,
      params,
    );
    if (errors) {
      yield put(actions.fetchNotificationFailure(emitErrors(errors)));
    } else {
      yield put(actions.fetchNotificationSuccess());
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerCloseInstruction() {
  const { instructionsUrl } = yield select(getSuccessInfo);
  global.open(instructionsUrl, '_blank');
}

export function* workerInitialize() {
  try {
    const productSlug = getUrlParams('addon-upgrade');
    const hiring = global.localStorage.getItem(HIRING);
    const addon = global.localStorage.getItem(ADDON);
    const slugs = global.localStorage.getItem(SLUGS);

    if (productSlug || hiring) {
      yield put(
        actions.setStepSuccess(
          productSlug || hiring,
          addon,
          VIEWSTEPS.success,
          0,
          slugs?.split(','),
        ),
      );
      removeQueryStringFromUrl();
      global.localStorage.removeItem(ADDON);
      global.localStorage.removeItem(HIRING);
      global.localStorage.removeItem(SLUGS);
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerReload() {
  try {
    const { updatePermissionsUrl } = yield select(getSharedInfo);
    const hiring = yield select(getHiring);
    const selectedAddon = yield select(getProduct);
    const selectSlugs = yield select(selectAddonsSlugs);

    global.localStorage.setItem(HIRING, hiring);
    global.localStorage.setItem(ADDON, selectedAddon);
    global.localStorage.setItem(SLUGS, selectSlugs);

    const url = new URL(global.location.href);
    global.location.href = `${updatePermissionsUrl}${url.pathname}?addon-upgrade=${hiring}`;
  } catch (error) {
    captureException(error);
  }
}

export function* workerGoToIclinicAssist() {
  try {
    yield call([global, global.open], URL_ICLINIC_ASSIST, '_blank');
  } catch (error) {
    captureException(error);
  }
}

export function* workerStepCheckPhysicians() {
  try {
    const selectedStep = yield select(getStepAddon);
    const selectedAddon = yield select(getProduct);
    const hiring = yield select(getHiring);
    const individualUser = yield select(getIndividualUser);
    const isMarketingUpgradeFlow =
      selectedStep === VIEWSTEPS.user &&
      hiring === FLOW.upgrade &&
      selectedAddon === 'marketing';

    if (
      selectedStep === VIEWSTEPS.user &&
      hiring === FLOW.upgrade &&
      individualUser
    ) {
      yield put(
        actions.setStepSuccess(hiring, selectedAddon, VIEWSTEPS.resume, 1),
      );
    }

    if (isMarketingUpgradeFlow) {
      const allPhysicians = yield select(getPhysicians);
      const totalUsers = allPhysicians.length;
      yield put(
        actions.setStepSuccess(
          hiring,
          selectedAddon,
          VIEWSTEPS.resume,
          totalUsers,
        ),
      );
    }
  } catch (error) {
    captureException(error);
  }
}

export default function* upgradeAddonSagas() {
  yield all([
    yield takeLatest(
      types.FETCH_CALCULATE_PRICE.ACTION,
      workerCalculateUpgrade,
    ),
    yield takeLatest(types.FETCH_PHYSICIANS.ACTION, getPhysiciansSagas),
    yield takeLatest(types.SELECT_PHYSICIAN.ACTION, selectPhysicianSagas),
    yield takeLatest(types.FETCH_SUMMARY.ACTION, workerSummary),
    yield takeLatest(types.FETCH_ADDONS.ACTION, workerFetchAddons),
    yield takeLatest(types.SELECT_PRODUCT.ACTION, workerSelectProduct),
    yield takeLatest(types.FETCH_CREATE.ACTION, workerSubscription),
    yield takeLatest(types.DETAIL.OPEN, workerOpenModal),
    yield takeLatest(types.FETCH_NOTIFICATION.ACTION, workerNotification),
    yield takeLatest(types.INSTRUCTION.CLOSE, workerCloseInstruction),
    yield takeLatest(types.RELOAD.ACTION, workerReload),
    yield takeLatest(
      types.GO_TO_ICLINIC_ASSIST.ACTION,
      workerGoToIclinicAssist,
    ),
    yield takeLatest(types.INITIALIZE.ACTION, workerInitialize),
    yield takeLatest(types.SET_STEP.ACTION, workerStepCheckPhysicians),
  ]);
}
