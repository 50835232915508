import { Reducer } from 'redux';

import {
  FETCH_PROFESSIONALS,
  SET_SELECTED_PROFESSIONAL,
  RESET_STATE,
  initialState,
  SET_SELECTED_PROFESSIONAL_BY_ID,
} from './constants';

import { ProfessionalActions, ProfessionalState } from './actions';
import setSelectedProfessionalById from './utils';

const professionalsReducer: Reducer<ProfessionalState> = (
  state: ProfessionalState = initialState,
  action: ProfessionalActions,
) => {
  switch (action.type) {
    case FETCH_PROFESSIONALS.ACTION:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      };
    case FETCH_PROFESSIONALS.SUCCESS:
      return {
        ...state,
        isFetching: action.payload.isFetching,
        professionals: action.payload.professionals,
      };
    case FETCH_PROFESSIONALS.FAILURE:
      return {
        ...state,
        isFetching: action.payload.isFetching,
        errors: action.payload.errors,
      };
    case SET_SELECTED_PROFESSIONAL.ACTION:
      return {
        ...state,
        selectedProfessional: action.payload.selectedProfessional,
      };
    case RESET_STATE.ACTION:
      return action.payload;
    case SET_SELECTED_PROFESSIONAL_BY_ID.ACTION:
      return {
        ...state,
        selectedProfessional: setSelectedProfessionalById(
          state.professionals,
          action.meta.id,
        ),
      };
    default:
      return state;
  }
};

export default professionalsReducer;
