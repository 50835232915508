// External
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';

// Internal
import { StoreState } from 'state/rootReducer';
import Dropdown from 'ui/new/dropdown';
import InputSearch from 'ui/new/input-search';
import { FORM_STOCK_OUTPUT } from 'shared/constants/forms';
import { useDebounce } from 'shared/hooks';
import {
  LotChange,
  InventoryCreate,
  StockProduct,
} from 'features/stock/state/types';
import { getLotsBySelectedProductId } from '../state/selectors';
import * as actions from '../state/actions';
import { StockKind, MIN_CHARS_TO_SEARCH } from '../constants';

export type OutputRenderProps = {
  lotIsOpen: boolean;
  setLotIsOpen(isOpen: boolean): void;
  lotItems: JSX.Element | null;
  productItems: JSX.Element | null;
  onChangeProductDescription(value: string): void;
} & InjectedFormProps;

type EnhanceStockOutputProductFormsProps = {
  render(params: OutputRenderProps): JSX.Element;
};

function EnhanceStockOutputInventoryForms({
  render,
  change,
  handleSubmit,
}: EnhanceStockOutputProductFormsProps & InjectedFormProps): JSX.Element {
  const dispatch = useDispatch();

  const stock = useSelector((state: StoreState) => state.stock);
  const lots = useSelector<StoreState>(getLotsBySelectedProductId);

  const {
    productDescription,
    lotDescription,
    selectedProduct,
    selectedLot,
    products: stockProducts,
  } = stock;

  const debouncedProductDescription = useDebounce(productDescription, 500);

  const [lotIsOpen, setLotIsOpen] = useState(false);

  const handleClickSelectLot = useCallback(
    (lot: LotChange): void => {
      dispatch(actions.changeLot(lot));
      setLotIsOpen(false);
    },
    [dispatch],
  );

  const lotItems = useMemo(
    (): JSX.Element =>
      lots.length > 0 &&
      lots.map<JSX.Element>((lot: LotChange) => (
        <Dropdown.Item
          id={lot.id}
          key={lot.id}
          selected={lot.description === lotDescription}
          onClick={() => handleClickSelectLot(lot)}
        >
          {lot.description}
        </Dropdown.Item>
      )),
    [handleClickSelectLot, lotDescription, lots],
  );

  const productItems = useMemo(
    (): JSX.Element =>
      stockProducts.length > 0 &&
      stockProducts.map<JSX.Element>((product: StockProduct) => (
        <InputSearch.Item
          id={product.id}
          key={product.id}
          selected={product.description === productDescription}
          onClick={() => dispatch(actions.changeProduct(product))}
        >
          {product.description}
        </InputSearch.Item>
      )),
    [dispatch, stockProducts, productDescription],
  );

  const onChangeProductDescription = useCallback(
    (value: string): void => {
      dispatch(actions.changeProductDescription(value));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(actions.changeProductDescription(debouncedProductDescription));

    if (debouncedProductDescription.length >= MIN_CHARS_TO_SEARCH) {
      dispatch(
        actions.fetchProductsByName({
          description: debouncedProductDescription,
        }),
      );
    }
  }, [dispatch, debouncedProductDescription]);

  return render({
    lotIsOpen,
    setLotIsOpen,
    change,
    handleSubmit,
    lotItems,
    productItems,
    // actions
    onChangeProductDescription,
    productDescription,
    selectedProduct,
    // store
    lotDescription,
    selectedLot,
  });
}

export default reduxForm({
  form: FORM_STOCK_OUTPUT,
  touchOnBlur: false,
  enableReinitialize: true,
  onSubmitSuccess: (payload: InventoryCreate, dispatch) => {
    dispatch(
      actions.createInventory({
        ...payload,
        kind: StockKind.Output,
      }),
    );
  },
})(EnhanceStockOutputInventoryForms);
