import React from 'react';

import * as S from '../../components/lp-iclinic-pay/styles';
import { image } from 'shared/utils/urlGetter';

export default function CardFlags(): JSX.Element {
  return (
    <S.Grid container spacing={3} alignItems="center" justify="center">
      <S.CreditCardFlagWrapper>
        <img alt="Logo mastercard" src={image('onlinePayment.mastercard')} />
      </S.CreditCardFlagWrapper>
      <S.CreditCardFlagWrapper>
        <img alt="Logo Visa" src={image('onlinePayment.visa')} />
      </S.CreditCardFlagWrapper>
      <S.CreditCardFlagWrapper>
        <img alt="Logo Elo" src={image('onlinePayment.elo')} />
      </S.CreditCardFlagWrapper>
      <S.CreditCardFlagWrapper>
        <img
          alt="Logo American Express"
          src={image('onlinePayment.americanExpress')}
        />
      </S.CreditCardFlagWrapper>
      <S.CreditCardFlagWrapper>
        <img alt="Logo Hiper" src={image('onlinePayment.hiper')} />
      </S.CreditCardFlagWrapper>
      <S.CreditCardFlagWrapper>
        <img alt="Logo HiperCard" src={image('onlinePayment.hipercard')} />
      </S.CreditCardFlagWrapper>
      <S.CreditCardFlagWrapper>
        <img alt="Logo Pix" src={image('onlinePayment.pix')} />
      </S.CreditCardFlagWrapper>
    </S.Grid>
  );
}
