import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles(() => ({
  flexContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  checkDay: {
    width: '42px',
  },
  weekDay: {
    width: '80px',
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
