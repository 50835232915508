import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DialogHeader } from '@iclinic/design-system';
import {
  getDialogOpened,
  getHasDebit,
  getNotMonthly,
  getStep,
  getAlreadyChanged,
} from '../state/selectors';
import { DialogNotMonthly } from '../components/DialogNotMonthly';
import { DialogUnpaid } from '../components/DialogUnpaid';
import { toggleDialog } from '../state/reducer';
import { DialogRequestChange } from '../components/DialogRequestChange';
import { DialogSuccess } from '../components/DialogSucces';
import { EditPaymentStep } from '../types';
import { Dialog } from '../components/Dialog.styles';
import { DialogRecentlyChanged } from '../components/DialogRecentlyChanged';

export const DialogEditPayment = () => {
  const dispatch = useDispatch();
  const dialogOpened = useSelector(getDialogOpened);
  const notMonthly = useSelector(getNotMonthly);
  const hasDebit = useSelector(getHasDebit);
  const status = useSelector(getStep);
  const hasChangedRecently = useSelector(getAlreadyChanged);

  const isChangeSucceded = status === EditPaymentStep.SUCCEDED;

  const toggleHandler = () =>
    isChangeSucceded ? window.location.reload() : dispatch(toggleDialog());

  const getTitle = useCallback(() => {
    if (isChangeSucceded) return 'Data de vencimento alterada com sucesso!';
    if (hasChangedRecently)
      return 'Não é possível alterar a data de vencimento';

    return 'Alterar data de vencimento';
  }, [isChangeSucceded, hasChangedRecently]);

  const DialogContent = () => {
    if (notMonthly) return <DialogNotMonthly />;
    if (hasDebit) return <DialogUnpaid />;
    if (isChangeSucceded) return <DialogSuccess />;
    if (hasChangedRecently) return <DialogRecentlyChanged />;

    return <DialogRequestChange />;
  };

  return (
    <Dialog maxWidth="xs" open={dialogOpened} onClose={toggleHandler}>
      <DialogHeader onClose={toggleHandler} title={getTitle()} />
      <DialogContent />
    </Dialog>
  );
};
