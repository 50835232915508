import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { FinancialSellerData } from './types';
import { FINANCIAL_SELLER_DATA_FETCH_ERROR } from './errorMessages';

export interface PanelIClinicPayState {
  financialSeller?: {
    data?: FinancialSellerData | null;
    status?: RequestStatus;
    error?: string;
  };
}

export const initialState: PanelIClinicPayState = {};
const name = 'panelIClinicPay';

const PanelIClinicPaySlice = createSlice({
  name,
  initialState,
  reducers: {
    fetchFinancialSellerData: (state) => {
      state.financialSeller = { status: RequestStatus.Pending };
    },
    fetchFinancialSellerDataSuccess: (
      state,
      action: PayloadAction<FinancialSellerData>,
    ) => {
      state.financialSeller = {
        data: action.payload,
        status: RequestStatus.Success,
      };
    },
    fetchFinancialSellerDataFailure: (state) => {
      state.financialSeller = {
        status: RequestStatus.Error,
        error: FINANCIAL_SELLER_DATA_FETCH_ERROR,
      };
    },
  },
});

export default PanelIClinicPaySlice.reducer;

export const {
  fetchFinancialSellerData,
  fetchFinancialSellerDataSuccess,
  fetchFinancialSellerDataFailure,
} = PanelIClinicPaySlice.actions;
