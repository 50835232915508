import {
  Autocomplete,
  createFilterOptions,
  TextField,
} from '@iclinic/design-system';
import { useFormikContext } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as autocompleteActions } from 'features/tissInvoicing/state/autocomplete';
import {
  getAutocomplete,
  getHiredOptions,
} from 'features/tissInvoicing/state/autocomplete/selectors';
import {
  GuideSADTForm,
  HiredSolicitantAutocompleteEntry,
  HiredSolicitantCodeEntry,
} from 'features/tissInvoicing/types';
import { getOptionLabelFn, renderOptionFn } from '../utils/utils';
import { FilterOptionsState } from 'features/tissInvoicing/types/types.common';

export default ({
  insuranceId,
  label = '14 - Nome do contratado solicitante',
}: {
  insuranceId: number | undefined;
  label?: string;
}) => {
  const dispatch = useDispatch();

  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext<GuideSADTForm>();

  const hiredOptions = useSelector(getHiredOptions);
  const { hired_solicitants } = useSelector(getAutocomplete);

  const hiredFilter = createFilterOptions<HiredSolicitantAutocompleteEntry>();

  const setHiredSolicitants = ({
    code_type,
    code,
  }: HiredSolicitantCodeEntry) => {
    setFieldValue('hired_solicitant_code_type', code_type || '');
    setFieldValue('hired_solicitant_code', code || '');
  };

  const getHiredSolicitantCodes = (
    id: number,
    slug: string,
  ): HiredSolicitantCodeEntry | undefined => {
    if (!hired_solicitants) return undefined;

    return hired_solicitants?.find(
      (solicitant) =>
        String(solicitant.id) === String(id) && solicitant.type === slug,
    );
  };

  const handleChange = (option: HiredSolicitantAutocompleteEntry) => {
    setFieldValue('hired_solicitant_name', option?.name || '');

    if (option?.id && option?.slug) {
      const codeData = getHiredSolicitantCodes(option?.id, option?.slug);

      if (codeData) {
        setHiredSolicitants(codeData);
        return;
      }

      if (insuranceId) {
        dispatch(
          autocompleteActions.fetchHiredSolicitantData({
            insuranceId,
            solicitantId: option?.id,
            type: option?.slug,
            callback: setHiredSolicitants,
          }),
        );
      }

      return;
    }

    setFieldValue('hired_solicitant_code_type', '');
    setFieldValue('hired_solicitant_code', '');
  };

  const filterOptions = (
    options: HiredSolicitantAutocompleteEntry[],
    params: FilterOptionsState<HiredSolicitantAutocompleteEntry>,
  ) => {
    const filtered = hiredFilter(options, params);

    if (params.inputValue !== '') {
      filtered.push({
        inputValue: params.inputValue,
        name: params.inputValue,
        type: 'Não cadastrado',
      });
    }

    return filtered;
  };
  return (
    <Autocomplete
      id="hired_solicitant_name"
      fullWidth
      freeSolo
      clearOnBlur
      value={values.hired_solicitant_name}
      onBlur={handleBlur}
      options={hiredOptions}
      groupBy={(option) => option.type!}
      onChange={(_, value) =>
        handleChange(value as HiredSolicitantAutocompleteEntry)
      }
      getOptionLabel={(option: HiredSolicitantAutocompleteEntry) =>
        getOptionLabelFn(option)
      }
      renderOption={(option: HiredSolicitantAutocompleteEntry) =>
        renderOptionFn(option)
      }
      filterOptions={filterOptions}
      renderInput={(params) => (
        <TextField
          label={label}
          name="hired_solicitant_name"
          data-ga="hired_solicitant_name"
          error={
            !!(errors.hired_solicitant_name && touched.hired_solicitant_name)
          }
          helperText={
            touched.hired_solicitant_name && errors.hired_solicitant_name
          }
          {...params}
        />
      )}
    />
  );
};
