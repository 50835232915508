// External
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

// Internal
import style from './style.scss';
import { MODAL } from './constants';

const classes = classnames.bind(style);

const Header = ({ children, type }) => (
  <div className={classes('', {
    info_header: type === MODAL.Info,
    success_header: type === MODAL.Success,
    error_header: type === MODAL.Error,
    danger_header: type === MODAL.Danger,
  })}
  >
    <h1>{children}</h1>
  </div>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.values(MODAL)),
};

export default Header;
