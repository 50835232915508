import React from 'react';

import DefaultFeedback from 'features/video-conference/components/TeamComponents/Feedback/index';
import { loading } from 'features/video-conference/constants';

export default function Loading(): JSX.Element {
  return (
    <DefaultFeedback
      title={loading.title}
      message={loading.message}
      altText={loading.altText}
      imagePath={loading.imagePath}
      linkText={loading.linkText}
      linkUrl={loading.linkUrl}
    />
  );
}
