import { useCallback, useEffect } from 'react';
import { DataTrack as IDataTrack } from 'twilio-video';

import { useChatContext } from 'features/video-conference/state/chatContext';
import { useConference } from 'features/video-conference/state/conferenceContext';
import {
  CaptionData,
  Message,
} from 'features/video-conference/constants/types';
import { createCaptionData, parseDate } from 'features/video-conference/utils';
import { useCaptionContext } from 'features/video-conference/state/captionContext';
import { useCaption } from 'features/video-conference/hooks/useCaption';

interface DataTrackProps {
  track: IDataTrack;
  isLocal?: boolean;
}

export default function DataTrack({ track, isLocal }: DataTrackProps) {
  const { room } = useConference();
  const { setMessages } = useChatContext();
  const { setIsRemoteCaptionActive, isCaptionActive, setRemoteTranscript } =
    useCaptionContext();

  const { sendCaptionMessageToParticipant } = useCaption();

  useEffect(() => {
    if (isLocal) return;

    const captionData = createCaptionData({
      captionType: 'caption-action',
      isCaptionActive,
      transcript: '',
    });
    sendCaptionMessageToParticipant(captionData);
  }, [isCaptionActive, isLocal, sendCaptionMessageToParticipant]);

  const handleCaptionMessage = useCallback(
    (captionMessage: CaptionData) => {
      if (captionMessage.captionType === 'caption-action') {
        setIsRemoteCaptionActive(captionMessage.isCaptionActive);
        return;
      }

      if (captionMessage.captionType === 'caption-transcript') {
        setRemoteTranscript(captionMessage.transcript);
      }
    },
    [setIsRemoteCaptionActive, setRemoteTranscript],
  );

  const handleChatMessage = useCallback(
    (message: string) => {
      const newMessage = JSON.parse(message, parseDate) as Message;
      setMessages((prevMessages: Message[]) => {
        localStorage.setItem(
          `telemedicine-chat-${room?.name}`,
          JSON.stringify(prevMessages.concat(newMessage)),
        );
        return [...prevMessages, newMessage];
      });
    },
    [room?.name, setMessages],
  );

  useEffect(() => {
    const handleMessage = (message: string) => {
      const captionMessage = JSON.parse(message) as CaptionData;

      if (captionMessage.isCaptionMessage) {
        handleCaptionMessage(captionMessage);
        return;
      }

      handleChatMessage(message);
    };

    track.on('message', handleMessage);
    return () => {
      track.off('message', handleMessage);
    };
  }, [handleCaptionMessage, handleChatMessage, track]);

  return null;
}
