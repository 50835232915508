// External
import React from 'react';

// Internal
import style from './LoaderCircle.scss';
import {
  fnormalizedRadius,
  fcircumference,
  fstrokeDashoffset,
} from './utils';

type LoaderCircle = {
  radius: number;
  stroke: number;
  progress: number;
  colorStroke?: string,
  children?: React.ReactNode,
};

export default function LoaderCircle({
  radius,
  stroke,
  progress,
  colorStroke = '#2C97D1',
  children = '1 de 5',
}: LoaderCircle): JSX.Element {
  const normalizedRadius = fnormalizedRadius(radius, stroke);
  const circumference = fcircumference(normalizedRadius);
  const strokeDashoffset = fstrokeDashoffset(circumference, progress);

  return (
    <svg
      height={radius * 2}
      width={radius * 2}
      className={style['progress-ring']}
    >
      <circle
        stroke="#E6E6E6"
        strokeWidth={stroke}
        fill="none"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        className={style.circle}
        stroke={colorStroke}
        fill="transparent"
        strokeDasharray={`${circumference} ${circumference}`}
        style={
            {
              strokeDashoffset,
            }
          }
        strokeWidth={stroke}
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <text
        className={style.text}
        x={radius}
        y={radius + 7}
        textAnchor="middle"
        stroke="#676D76"
        strokeWidth="1px"
      >
        {children}
      </text>
    </svg>
  );
}
