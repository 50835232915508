// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import { Button } from '@iclinic/iclinic-ui';
import Header from './Header';
import List from './List';
import style from './Card.scss';

export default function Card({ product, openProductDetail, children }) {
  return (
    <div className={style.item}>
      {children}
      <Button block onClick={() => openProductDetail(product)}>
        ASSINE AGORA
      </Button>
      <List list={product.functionalities} />
    </div>
  );
}

Card.Header = Header;

Card.propTypes = {
  product: PropTypes.shape({
    functionalities: PropTypes.arrayOf([PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    promotion: PropTypes.shape(
      {
        frequencies: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.number.isRequired,
          }).isRequired,
        ),
      }.isRequired,
    ),
  }).isRequired,
  children: PropTypes.node.isRequired,
  openProductDetail: PropTypes.func.isRequired,
};
