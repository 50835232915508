import { axiomTokens, MaterialUICore, Select } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = axiomTokens;

export const SelectPhysician = styled(Select)({
  maxWidth: 264,
});

export const ConsumptionsWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: spacing[6],
});

export const TopActionsWrapper = styled('div')({
  display: 'grid',
  gap: spacing[6],
  gridAutoFlow: 'column',
});
