import { Response } from '@types';

export type ShortUrls = {
  copy: string;
  webshare: string;
  whatsapp: string;
};

export type ShortUrlResponse = {
  original_url: string;
  short_url: string;
};

export type ShortUrlResponses = {
  copy: Response<ShortUrlResponse>;
  share: Response<ShortUrlResponse>;
  whatsapp: Response<ShortUrlResponse>;
};

export type SaveInterestParams = {
  channel: string;
};

export type ObjectsResponse<T> = {
  objects: T[];
};

export type Award = {
  created_at: string;
  description: string;
  id: number;
  indicated: string;
  kind: string;
  pay_date: string;
  status: string;
  value: string;
};

export type AwardsReponse = ObjectsResponse<Award>;

export type WebShareDataParams = {
  url?: string;
  text?: string;
};

export type Kind = 'physician' | 'receptionist';

export interface IndicationAwardInfo {
  icon: React.ReactElement;
  text: string | React.ReactElement;
}

export interface HowItWorkCardInfo {
  title: string;
  imagePath: string;
  content: string | React.ReactElement;
  position: string;
}

export interface Question {
  identifier: string;
  question: string;
  answer: string;
}

export enum AwardKind {
  SYSTEM_CREDIT = 'cs',
  EXTERNAL_AWARD = 'ea',
}

export enum AwardDescription {
  SYSTEM_CREDIT = 'Créditos no iClinic',
  EXTERNAL_AWARD = 'Vale presente',
}

export type RedeemAwardData = {
  awardId: number;
  data: {
    kind: AwardKind;
    description: AwardDescription;
  };
};

export type AwardSelected = {
  id: number;
  indicated: string;
};

export enum TierStatus {
  INITIAL = 'initial',
  REACHED = 'reached',
  RESCUED = 'rescued',
}

export type TierItem = {
  status: TierStatus;
  tooltipActive: boolean;
  highlighted: boolean;
  message: string;
  tier: number;
};
