import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import fakeCreditCardInstallments from '../../utils/fakeCreditCardInstallments';
import {
  CustomCheckBox as ReceivedInstallmentCheckBox,
  DetailsHeader as InstallmentsDetailsHeader,
  DetailsItem as InstallmentsDetailsItem,
} from '../commonStyles';

type InstallmentItemProps = {
  order: string;
  dueDate: string;
  paymentStatus: boolean;
  value: string;
  dateOfReceiptOfInstallment: string;
};

const InstallmentItem: React.FC<{
  installmentDetails: InstallmentItemProps;
}> = ({ installmentDetails }) => {
  const { order, dueDate, paymentStatus, value, dateOfReceiptOfInstallment } =
    installmentDetails;

  const [isChecked, setIsChecked] = useState<boolean>(paymentStatus || false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <InstallmentsDetailsItem
      data-testid="single-installment-detail"
      key={uuidv4()}
    >
      <span>{order}</span>
      <span>{dueDate}</span>
      <span>
        <span className={`pill ${isChecked ? 'paid' : 'not-paid'}`}>
          {isChecked ? 'Recebido' : 'À receber'}
        </span>
      </span>
      <span>{value}</span>
      <span>
        <ReceivedInstallmentCheckBox
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
      </span>
      <span>{dateOfReceiptOfInstallment}</span>
    </InstallmentsDetailsItem>
  );
};

const InstallmentInfoTable = ({
  installmentNumber,
  paymentValue,
}: {
  installmentNumber: number;
  paymentValue: number;
}) => {
  const creditCardInstallmentList = fakeCreditCardInstallments.create(
    +installmentNumber,
    paymentValue,
  );

  return (
    <>
      <InstallmentsDetailsHeader>
        <span>Parcelas</span>
        <span>Vencimento</span>
        <span>Status</span>
        <span>Valor</span>
        <span>Recebido</span>
        <span>Data recebimento</span>
      </InstallmentsDetailsHeader>
      {!!creditCardInstallmentList.length &&
        creditCardInstallmentList.map((installment) => (
          <InstallmentItem key={uuidv4()} installmentDetails={installment} />
        ))}
    </>
  );
};

export default InstallmentInfoTable;
