import {
  DatePicker,
  Grid,
  Heading,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';
import { useFormikContext } from 'formik';
import React, { memo, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRifm } from 'rifm';

import { getProcedureOptions } from 'features/tissInvoicing/state/autocomplete/selectors';
import { getDomainTablesOptions } from 'features/tissInvoicing/state/domainTables/selectors';
import { GuideSADTForm, Procedure } from 'features/tissInvoicing/types';
import {
  getErrorText,
  SelectField,
  TextField,
} from 'features/tissInvoicing/utils/Inputs';
import { getProcedureTotalValue } from 'features/tissInvoicing/utils/procedureTotalValue';
import {
  decimalFormatter,
  monetaryFormatter,
  onlyNumbers,
  timeFormatter,
} from 'shared/utils/formatters';
import * as Styles from './styles';

const { IconButton, MenuItem } = MaterialUICore;
const { Delete } = MaterialIcons;

interface ProcedureContainerProps {
  index: number;
  procedure: Procedure;
  removeProcedure: (index: number) => void;
}

function ProcedureContainer({
  index,
  procedure,
  removeProcedure,
}: ProcedureContainerProps): JSX.Element {
  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext<GuideSADTForm>();

  const { accessWays, techniques } = useSelector(getDomainTablesOptions);
  const proceduresOptions = useSelector(getProcedureOptions);

  const { value: reductionFactor, onChange: onChangeProcedurePriceFactor } =
    useRifm({
      value: String(procedure.reduction_factor),
      onChange: (value: string) =>
        setFieldValue(`procedures.${index}.reduction_factor`, value),
      format: decimalFormatter,
    });

  const { value: unitPrice, onChange: onChangeUnitValue } = useRifm({
    value: String(procedure.unit_price),
    onChange: (value: string) =>
      setFieldValue(`procedures.${index}.unit_price`, value),
    format: monetaryFormatter,
  });

  const { value: procedureStart, onChange: onChangeProcedureStart } = useRifm({
    value: String(procedure.procedure_start),
    onChange: (value: string) =>
      setFieldValue(`procedures.${index}.procedure_start`, value),
    format: timeFormatter,
  });

  const { value: procedureEnd, onChange: onChangeProcedureEnd } = useRifm({
    value: String(procedure.procedure_end),
    onChange: (value: string) =>
      setFieldValue(`procedures.${index}.procedure_end`, value),
    format: timeFormatter,
  });

  const { value: quantity, onChange: onChangeProcedureQty } = useRifm({
    value: String(procedure.quantity),
    onChange: (value: string) =>
      setFieldValue(`procedures.${index}.quantity`, value),
    format: onlyNumbers,
  });

  const handleChangeProceduredate = (date: Date | null) => {
    setFieldValue(`procedures.${index}.procedure_date`, date);
  };

  const calculateTotalValue = useCallback(
    (price?: string) => {
      const procedureTotal = getProcedureTotalValue(
        reductionFactor,
        Number(quantity),
        unitPrice,
        price,
      );

      if (procedureTotal !== null) {
        setFieldValue(`procedures.${index}.total_value`, procedureTotal);
      }
    },
    [reductionFactor, quantity, unitPrice, index, setFieldValue],
  );

  const handleChangeProcedureId = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const selectedProcedure = proceduresOptions.find(
      (proc) => proc.id.toString() === e.target.value.toString(),
    );

    setFieldValue(
      `procedures.${index}.tuss_procedure_id`,
      selectedProcedure?.tuss_procedure_id,
    );

    setFieldValue(
      `procedures.${index}.unit_price`,
      selectedProcedure?.price.replace('.', ',') || '0,00',
    );

    calculateTotalValue(selectedProcedure?.price);
  };

  useEffect(() => {
    calculateTotalValue();
  }, [calculateTotalValue, procedure]);

  useEffect(() => {
    const selectedProcedure = proceduresOptions.find(
      (proc) => proc.id === procedure.procedure_id,
    );

    setFieldValue(
      `procedures.${index}.tuss_procedure_id`,
      selectedProcedure?.tuss_procedure_id,
    );
  }, [index, procedure, proceduresOptions, setFieldValue]);

  return (
    <Styles.Container data-testid={`procedure_section_${index}`}>
      <Styles.Header>
        <Heading component="h6" variant="xs">
          Procedimento
        </Heading>

        {values.procedures.length > 1 && (
          <IconButton onClick={() => removeProcedure(index)}>
            <Delete data-testid={`delete_procedure_${index}`} />
          </IconButton>
        )}
      </Styles.Header>

      <Grid container item spacing={2}>
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <SelectField
            label="Descrição"
            identifier="procedure_id"
            index={index}
            value={procedure.procedure_id}
            onChangeHandler={handleChangeProcedureId}
            required
          >
            {proceduresOptions.map((proc) => (
              <MenuItem
                value={proc.id}
                key={proc.id}
                disabled={
                  procedure.procedure_id !== proc.id &&
                  values.procedures.some((p) => p.procedure_id === proc.id)
                }
              >
                {proc.name}
              </MenuItem>
            ))}
          </SelectField>
          <Styles.HiddenTextField
            label="tuss_procedure_id"
            value=""
            index={index}
            identifier="tuss_procedure_id"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <DatePicker
            label="Data *"
            placeholder="DD/MM/AAAA"
            id={`procedures.${index}.procedure_date`}
            name={`procedures.${index}.procedure_date`}
            value={procedure.procedure_date}
            helperText={getErrorText(
              `procedures[${index}].procedure_date`,
              touched,
              errors,
            )}
            error={
              !!getErrorText(
                `procedures[${index}].procedure_date`,
                touched,
                errors,
              )
            }
            onChange={handleChangeProceduredate}
            onBlur={handleBlur}
            allowKeyboard
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            label="Início *"
            value={procedureStart}
            index={index}
            identifier="procedure_start"
            onChangeHandler={onChangeProcedureStart}
            placeholder="00:00"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            label="Final *"
            value={procedureEnd}
            index={index}
            identifier="procedure_end"
            onChangeHandler={onChangeProcedureEnd}
            placeholder="00:00"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <SelectField
            label="Via"
            identifier="access_way"
            index={index}
            value={procedure.access_way}
          >
            {accessWays.map((accessWay) => (
              <MenuItem value={accessWay.id} key={accessWay.id}>
                {accessWay.term}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <SelectField
            label="Técnica"
            identifier="technique_used"
            index={index}
            value={procedure.technique_used}
          >
            {techniques.map((techniqueOption) => (
              <MenuItem value={techniqueOption.id} key={techniqueOption.id}>
                {techniqueOption.term}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            label="Quantidade *"
            value={quantity}
            index={index}
            identifier="quantity"
            onChangeHandler={onChangeProcedureQty}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            label="Fator Red. / Acrésc. *"
            value={reductionFactor}
            index={index}
            identifier="reduction_factor"
            onChangeHandler={onChangeProcedurePriceFactor}
            placeholder="0,00"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            label="Valor Unitário *"
            value={unitPrice}
            index={index}
            identifier="unit_price"
            onChangeHandler={onChangeUnitValue}
            placeholder="0,00"
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            label="Valor Total *"
            value={procedure.total_value || '0,00'}
            index={index}
            identifier="total_value"
            placeholder="0,00"
            disabled
          />
        </Grid>
      </Grid>
    </Styles.Container>
  );
}
export default memo(ProcedureContainer);
