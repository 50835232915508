import { MaterialUICore, theme } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles(() => ({
  userCpf: {
    marginTop: '16px',
  },
  listChips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-6px',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  title: {
    '& .MuiTypography-subtitle1': {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      color: theme.palette.text.primary,
      marginBottom: '8px',
      marginTop: '20px',
    },
  },
  lifeTimeForm: {
    marginTop: '16px',
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      background: 'white',
      marginTop: '2px',
    },
    '& .MuiSelect-select': {
      width: '224px',
      fontSize: '16px',
    },
  },
  textTips: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.grey[500],
    marginTop: '3px',
  },
}));

export default useStyles;
