import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { ModalType, PaymentSlipData, PaymentSummary } from './types';

export interface PaymentSlipState {
  status: RequestStatus;
  paymentSlipData?: PaymentSlipData;
  paymentSummary?: PaymentSummary;
  isOpenModal: boolean;
  modalType?: ModalType;
  releaseStatus?: RequestStatus;
}

export const initialState: PaymentSlipState = {
  status: RequestStatus.Pending,
  isOpenModal: false,
};

export const nameStore = '@webapp/payment-slip';

export const fetchPaymentSlipData = createAction(
  `${nameStore}/fetchPaymentSlipData`,
);

export const requestPaymentSlipReleaseTrust = createAction(
  `${nameStore}/requestPaymentSlipReleaseTrust`,
);

const PaymentSlipSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchPaymentSlipDataSuccess: (
      state,
      action: PayloadAction<PaymentSlipData>,
    ) => ({
      ...state,
      status: RequestStatus.Success,
      paymentSlipData: action.payload,
    }),
    fetchPaymentSlipDataFailed: (state) => ({
      ...state,
      status: RequestStatus.Error,
    }),
    toggleOpenModal: (state, action: PayloadAction<ModalType>) => ({
      ...state,
      isOpenModal: true,
      modalType: action.payload,
    }),
    toggleCloseModal: (state) => ({
      ...state,
      isOpenModal: false,
      modalType: undefined,
    }),
    requestPaymentSlipReleaseTrustStart: (state) => ({
      ...state,
      releaseStatus: RequestStatus.Pending,
    }),
    requestPaymentSlipReleaseTrustSuccess: (state) => ({
      ...state,
      releaseStatus: RequestStatus.Success,
    }),
    requestPaymentSlipReleaseTrustFailed: (state) => ({
      ...state,
      releaseStatus: RequestStatus.Error,
    }),
    setPaymentSummaryData: (state, action: PayloadAction<PaymentSummary>) => ({
      ...state,
      paymentSummary: action.payload,
    }),
  },
});

export const {
  fetchPaymentSlipDataSuccess,
  fetchPaymentSlipDataFailed,
  toggleOpenModal,
  toggleCloseModal,
  requestPaymentSlipReleaseTrustStart,
  requestPaymentSlipReleaseTrustSuccess,
  requestPaymentSlipReleaseTrustFailed,
  setPaymentSummaryData,
} = PaymentSlipSlice.actions;

export default PaymentSlipSlice.reducer;
