// External
import * as React from 'react';
import cn from 'classnames';

// Internal
import style from './style/index.scss';

interface FooterProps {
  children: React.ReactNode;
  classes: {
    'card-footer'?: string;
  };
}

export default function Footer({
  children,
  classes = {},
}: FooterProps): JSX.Element {
  return (
    <div className={cn(style['default-footer'], classes['card-footer'])}>
      {children}
    </div>
  );
}
