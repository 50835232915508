import axios from 'axios';

import {
  interceptorRequestAuthenticationHeaders,
  interceptorResponseErrors,
  interceptorResponseTransformData,
} from 'services/iclinic/interceptors';
import {
  GetHighlightsInfosResponse,
  SaveHighlightsInfoListDeniedRequest,
  SaveHighlightsInfoListRequest,
  SaveHighlightsInfoListResponse,
  SaveHighlightsInfoTextRequest,
  SaveHighlightsInfoTextResponse,
  SearchDbHighlightsInfoListRequest,
  SearchDbHighlightsInfoListResponse,
} from './types';

export const highlightsInfoApi = axios.create({
  baseURL: process.env.ICLINIC_PEP_API_URL,
});

highlightsInfoApi.interceptors.request.use(
  interceptorRequestAuthenticationHeaders,
);
highlightsInfoApi.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrors,
);

export const getHighlightsInfos = (
  patientId: string,
): Promise<GetHighlightsInfosResponse> =>
  highlightsInfoApi.get(`/api/v1/highlight_info/${patientId}`);

export const saveHighlightsInfo = (
  highlightsInfo:
    | SaveHighlightsInfoTextRequest
    | SaveHighlightsInfoListRequest
    | SaveHighlightsInfoListDeniedRequest,
): Promise<SaveHighlightsInfoTextResponse | SaveHighlightsInfoListResponse> =>
  highlightsInfoApi.post(`/api/v1/highlight_info/`, highlightsInfo);

export const getHighlightsInfoSearchDb = (
  highlightsInfoSearchDb: SearchDbHighlightsInfoListRequest,
): Promise<SearchDbHighlightsInfoListResponse> =>
  highlightsInfoApi.get(`/api/v1/highlight_info/search`, {
    params: highlightsInfoSearchDb,
  });
