import { MODAL } from 'ui/new/modal/constants';

export enum Features {
  Stock = 'stock',
  InventoriesHistory = 'inventoriesHistory',
}

export enum StockKind {
  Input = 0,
  Output = 1,
}

export enum StockTabs {
  All = 'all',
  LowStock = 'low-stock',
  ExpiredInOneMonth = 'expire-in-one-month',
  Expired = 'expired',
}

export enum ModalType {
  Add = 'add',
  Edit = 'edit',
  Input = 'input',
  Output = 'output',
  Delete = 'delete',
}

export const MODAL_TYPE_STYLE = {
  [ModalType.Add]: MODAL.Info,
  [ModalType.Edit]: MODAL.Info,
  [ModalType.Input]: MODAL.Info,
  [ModalType.Output]: MODAL.Info,
  [ModalType.Delete]: MODAL.Error,
};

export const saveAndContinue = (data) => ({
  ...data,
  closeModal: false,
});

export const saveAndCloseModal = (data) => ({
  ...data,
  closeModal: true,
});

export const PAGE_SIZE = 20;

export const MIN_CHARS_TO_SEARCH = 3;

export const LAST_INVENTORY = '-last_inventory_date';

export const INITIAL_PAGE = 1;
