// External
import React from 'react';

/*
 * This is a React Hook to implement the collapse of HTML elements with
 * 'height: auto' as it's not possible with pure CSS.
 *
 * See https://css-tricks.com/using-css-transitions-auto-dimensions/#article-header-id-5
 *
 * NOTE: browser compatibility: seems `requestAnimationFrame()` is not
 * needed in Firefox, but it is in Chrome due its approach to apply the style
 * only when the JavaScript is done.
 */

const startCollapsed = (el, collapsedHeight) => {
  const element = el;
  const originalTransition = element.style.transition;

  // disable transition and let it be applied
  element.style.transition = '';
  requestAnimationFrame(() => {
    element.style.height = collapsedHeight;

    requestAnimationFrame(() => {
      // let it be applied and then shrink
      element.style.transition = originalTransition;
    });
  });
};

const collapseCard = (el, collapsedHeight, setIsAnimating) => {
  const element = el;
  const cardHeight = element.scrollHeight;
  const originalTransition = element.style.transition;
  setIsAnimating(true);

  // disable transition and let it be applied
  element.style.transition = '';
  element.style.overflow = 'hidden';
  requestAnimationFrame(() => {
    element.style.height = `${cardHeight}px`;
    element.style.transition = originalTransition;

    requestAnimationFrame(() => {
      // let it be applied and then shrink
      element.style.height = collapsedHeight;

      const onTransitionEnd = () => {
        element.removeEventListener('transitionend', onTransitionEnd);
        setIsAnimating(false);
      };
      element.addEventListener('transitionend', onTransitionEnd);
    });
  });
};

const expandCard = (el, setIsAnimating) => {
  const element = el;
  const cardHeight = element.scrollHeight;
  setIsAnimating(true);

  element.style.height = `${cardHeight}px`;
  const onTransitionEnd = () => {
    element.removeEventListener('transitionend', onTransitionEnd);
    element.style.height = null; // let it recalculate as needed
    setIsAnimating(false);
    element.style.overflow = 'visible';
  };
  element.addEventListener('transitionend', onTransitionEnd);
};

const useCollapseElement = (isCollapsed, collapsedHeight) => {
  const elementRef = React.useRef(null);
  const firstRef = React.useRef(true);
  const [isAnimating, setIsAnimating] = React.useState(false);

  React.useEffect(() => {
    const isFirst = firstRef.current;
    const element = elementRef.current;
    if (isFirst) {
      firstRef.current = false;
      if (isCollapsed) {
        startCollapsed(element, collapsedHeight);
      }
    } else if (isCollapsed) {
      collapseCard(element, collapsedHeight, setIsAnimating);
    } else {
      expandCard(element, setIsAnimating);
    }
  }, [isCollapsed, collapsedHeight]);

  return [elementRef, isAnimating];
};

export default useCollapseElement;
