import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Grid, MaterialIcons, DatePicker } from '@iclinic/design-system';

import * as S from './styles';
import { getOverviewFilters } from 'features/onlinePayment/state/notifications-center/selectors';
import { setOverviewFilters } from 'features/onlinePayment/state/notifications-center';
import { OverviewFilters } from 'features/onlinePayment/state/notifications-center/types';

const { CalendarToday } = MaterialIcons;

const FiltersByPeriod = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getOverviewFilters);
  const [chipActivated, setChipActivated] = useState<number | string>(0);

  const openCalendarRange = () => {
    setChipActivated('calendar');
  };

  const handleChipClick = (daysToSubtract: number) => {
    dispatch(
      setOverviewFilters({
        ...filters,
        startDate: moment().subtract(daysToSubtract, 'days').toDate(),
        endDate: new Date(),
      }),
    );
  };

  const handleFiltersChange = (newFilters: OverviewFilters) => {
    dispatch(setOverviewFilters({ ...filters, ...newFilters }));
  };

  useEffect(() => {
    const difference = moment().diff(moment(filters.startDate), 'day');
    const isEndDayToday = moment(filters.endDate).isSame(moment(), 'day');
    const chip =
      [0, 7, 30].includes(difference) && isEndDayToday
        ? difference
        : 'calendar';
    setChipActivated(chip);
  }, [filters]);

  const renderChip = (
    label: string | JSX.Element,
    isActive: boolean,
    onClick: Function,
  ) => (
    <Grid item xs={3}>
      <S.Chip
        label={label}
        onClick={() => onClick()}
        active={isActive}
        variant={isActive ? 'outlined' : 'default'}
      />
    </Grid>
  );

  return (
    <>
      <Grid container spacing={1}>
        {renderChip('30 dias', chipActivated === 30, () => handleChipClick(30))}
        {renderChip('7 dias', chipActivated === 7, () => handleChipClick(7))}
        {renderChip('hoje', chipActivated === 0, () => handleChipClick(0))}
        {renderChip(
          <CalendarToday fontSize="inherit" />,
          chipActivated === 'calendar',
          () => openCalendarRange(),
        )}
      </Grid>

      <S.CalendarBox opened={chipActivated === 'calendar'}>
        <DatePicker
          id="startDate"
          name="startDate"
          label="Data início"
          value={filters.startDate}
          placeholder="dd/mm/aaaa"
          disableFuture
          onChange={(value) =>
            handleFiltersChange({ startDate: value } as OverviewFilters)
          }
        />
        <DatePicker
          id="endDate"
          name="endDate"
          label="Data fim"
          value={filters.endDate}
          placeholder="dd/mm/aaaa"
          disableFuture
          onChange={(value) =>
            handleFiltersChange({ endDate: value } as OverviewFilters)
          }
        />
      </S.CalendarBox>
    </>
  );
};

export default FiltersByPeriod;
