// External
import { ContentBlock } from 'draft-js';

// Internal
import { typeStyleEditor } from '../constants';
import style from './blockStyleFn.scss';

const blockTypeToStyle = {
  [typeStyleEditor.normal]: style.paragraph,
  [typeStyleEditor.alignCenter]: style.alignCenter,
};

const blockStyleFn = (contentBlock: ContentBlock) => blockTypeToStyle[contentBlock.getType()] || '';

export default blockStyleFn;
