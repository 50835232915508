import {
  axiomTokens,
  Button,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Container = styled('div')({
  display: 'flex',
});

export const LinkButton = styled(Button)({
  '&:hover, &:focus': {
    color: axiomTokens.color.brand.interactive.accent.default,
    outline: 'none',
    textDecoration: 'none',
  },
});

export const ButtonList = styled(Button)({
  borderTopLeftRadius: '0px',
  borderBottomLeftRadius: '0px',
  borderLeft: `1px ${tokens.color.primary.light} solid`,
});
