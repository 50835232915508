import React from 'react';
import classnames from 'classnames';
import { Box } from '@iclinic/design-system';

import { isMobile } from 'features/video-conference/utils';
import ButtonEndCall from './Buttons/EndCallButton';
import { useConference } from 'features/video-conference/state/conferenceContext';
import CameraButton from './Buttons/CameraButton';
import MicrophoneButton from './Buttons/MicrophoneButton';
import AnnotationsButton from './Buttons/AnnotationsButton';
import ChatButton from './Buttons/ChatButton';
import GoToMedicalRecords from './Buttons/GoToMedicalRecordsButton';
import ScreenShareButton from './Buttons/ScreenShareButton';
import CaptionButton from './Buttons/CaptionButton';
import MobileToolbar from './MobileToolbar';
import Timer from './Timer';
import useStyles from './styles';
import Menu from './Menu';

const Toolbar = (): JSX.Element => {
  const { isPhysician } = useConference();
  const classes = useStyles();

  if (isMobile) return <MobileToolbar />;

  const divClass = isPhysician ? classes.largeDiv : classes.smallDiv;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={classnames(classes.root, {
        [classes.largeHeight]: isMobile,
      })}
    >
      <Box className={classnames(divClass, classes.buttonsContainer)}>
        <Timer />
      </Box>
      <Box className={classes.buttonsContainer} display="flex">
        <MicrophoneButton withLabel />
        <CameraButton withLabel />
        {isPhysician && <ScreenShareButton />}
        <CaptionButton withLabel />
        <Menu />
        {isPhysician && <ButtonEndCall />}
      </Box>
      <Box className={classes.buttonsContainer} display="flex">
        {isPhysician && (
          <>
            <GoToMedicalRecords />
            <AnnotationsButton />
          </>
        )}
        <ChatButton />
      </Box>
    </Box>
  );
};

export default Toolbar;
