import React, {
  DetailedHTMLProps,
  OptionHTMLAttributes,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  Button,
  DialogActions,
  DialogContent,
  InputAdornment,
  MaterialUICore,
  MaterialIcons,
  Alert,
} from '@iclinic/design-system';

import { changePaymentDate, toggleDialog } from '../state/reducer';
import { getPaymentDay, getStatus } from '../state/selectors';
import { generateAvailableDates, getDefaultPaymentDay } from '../utils';
import { Select } from './Dialog.styles';
import { RequestStatus } from 'shared/constants/State';

const { useTheme, useMediaQuery, MenuItem } = MaterialUICore;

const { TodayIcon, InfoOutlined } = MaterialIcons;

const NativeOption = (
  props: DetailedHTMLProps<
    OptionHTMLAttributes<HTMLOptionElement>,
    HTMLOptionElement
  >,
) => <option {...props} />;

export const DialogRequestChange = () => {
  const dispatch = useDispatch();
  const currentPaymentDay = useSelector(getPaymentDay);
  const defaultPaymentDay = getDefaultPaymentDay(currentPaymentDay);
  const status = useSelector(getStatus);
  const [selectedDay, setSelectedDay] = useState(defaultPaymentDay);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const OptionComponent = isXs ? NativeOption : MenuItem;
  const selectProps = { native: isXs };

  const availableDates = useMemo(() => generateAvailableDates(), []);

  const onClickHandler = () => {
    dispatch(changePaymentDate(selectedDay));
  };

  return (
    <>
      <DialogContent>
        <Body variant="xs">
          Atualmente, sua assinatura é cobrada no dia{' '}
          <strong>{currentPaymentDay} de cada mês.</strong> Deseja fazer a
          alteração para os próximos pagamentos?
        </Body>
        <Select
          SelectProps={selectProps}
          value={selectedDay}
          onChange={({ target: { value } }) => setSelectedDay(+value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TodayIcon />
              </InputAdornment>
            ),
          }}
        >
          {availableDates.map((item) => (
            <OptionComponent key={item.value} value={item.value}>
              {item.label}
            </OptionComponent>
          ))}
        </Select>
        <Alert
          elevation={0}
          icon={<InfoOutlined />}
          message="Após a confirmação, você só poderá realizar uma nova alteração após 40 dias."
          severity="info"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="transparent"
          onClick={() => dispatch(toggleDialog())}
        >
          Cancelar
        </Button>
        <Button
          onClick={onClickHandler}
          variant="contained"
          isLoading={status === RequestStatus.Pending}
          disabled={selectedDay === currentPaymentDay}
        >
          Confirmar alteração
        </Button>
      </DialogActions>
    </>
  );
};
