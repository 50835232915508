// Internal
import { SUCCESS, FAILURE, REQUEST } from 'shared/constants/actions';
import appTypes from 'shared/utils/actions';

const memed = appTypes.defineAction('memed');

export const FETCH_SDK = memed.defineAction('FETCH_SDK', [SUCCESS, FAILURE]);

export const SET_SDK_SETTINGS = memed.defineAction('SET_SDK_SETTINGS', [
  SUCCESS,
  FAILURE,
]);
export const SET_FEATURES = memed.defineAction('SET_FEATURES', [
  SUCCESS,
  FAILURE,
]);
export const SET_PATIENT = memed.defineAction('SET_PATIENT', [
  SUCCESS,
  FAILURE,
]);

export const PRESCRIPTION_CREATED = memed.defineAction('PRESCRIPTION_CREATED', [
  SUCCESS,
  FAILURE,
]);
export const PRESCRIPTION_FINISHED = memed.defineAction(
  'PRESCRIPTION_FINISHED',
  [SUCCESS, FAILURE],
);
export const PRESCRIPTION_UPDATE = memed.defineAction('PRESCRIPTION_UPDATE', [
  SUCCESS,
  FAILURE,
]);
export const PRESCRIPTION_ADD_ITEMS = memed.defineAction(
  'PRESCRIPTION_ADD_ITEMS',
  [SUCCESS, FAILURE],
);
export const PRESCRIPTION_ADD_ITEM = memed.defineAction(
  'PRESCRIPTION_ADD_ITEM',
  [SUCCESS, FAILURE],
);
export const PRESCRIPTION_REUSE = memed.defineAction('PRESCRIPTION_REUSE');
export const PRESCRIPTION_CLEAR = memed.defineAction('PRESCRIPTION_CLEAR', [
  SUCCESS,
  FAILURE,
]);
export const PRESCRIPTION_REMOVED = memed.defineAction('PRESCRIPTION_REMOVED', [
  REQUEST,
  SUCCESS,
  FAILURE,
]);
