import React from 'react';
import { Alert, Body, MaterialIcons } from '@iclinic/design-system';

import * as S from './SelectedPlan.styles';
import { Plan } from './Plan';
import { SelectedPlan as SelectedPlanType } from '../../types';

const { CheckCircleOutline } = MaterialIcons;

export const SelectedPlan = ({ plan }: { plan: SelectedPlanType }) => (
  <S.SelectedPlanWrapper>
    <Alert
      severity="success"
      icon={<CheckCircleOutline />}
      message="Obrigado por demonstrar interesse no WhatsApp Business do iClinic."
    />
    <S.Content>
      {plan !== 'other' && (
        <S.PlanWrapper>
          <Plan plan={plan} success />
        </S.PlanWrapper>
      )}

      <S.Description>
        <Body variant="sm">
          Ainda estamos construindo algumas melhorias para essa funcionalidade,
          mas não se preocupe, você será notificado assim que o serviço estiver
          disponível. Até breve!
        </Body>
      </S.Description>
    </S.Content>
  </S.SelectedPlanWrapper>
);
