import {
  MaterialUICore,
  Heading,
  Body,
  tokens,
  Box,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const BoxContent = styled(Box)(({ theme }) => ({
  width: '552px',
  height: '550px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    marginTop: tokens.spacing.md,
  },
}));

export const Image = styled('img')({
  width: '168px',
  height: '221px',
});

export const HeadingContent = styled(Heading)({
  paddingBottom: tokens.spacing.base,
});

export const BodyContent = styled(Body)({
  paddingBottom: tokens.spacing.sm,
});

export const ButtonContent = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
