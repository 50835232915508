import { StoreState } from 'state/rootReducer';
import { PatientProps, PaymentProps } from '../types/types';
import { ProceduresListType } from '../mocks';

export const getSchedulePatientSelector = (state: StoreState): PatientProps =>
  state.newFinance.patient;

export const getScheduleProceduresSelector = (
  state: StoreState,
): ProceduresListType => state.newFinance.procedures;

export const getSchedulePaymentsList = (
  state: StoreState,
): PaymentProps['paymentList'] => state.newFinance.payments.paymentList;

export const getSchedulePaymentsOtherPerson = (
  state: StoreState,
): PaymentProps['paidByOtherPerson'] =>
  state.newFinance.payments.paidByOtherPerson;

export const getTotalValuesSelector = (
  state: StoreState,
): PaymentProps['values'] => state.newFinance.payments.values;

export const getPaymentConfig = (
  state: StoreState,
): PaymentProps['paymentConfig'] => state.newFinance.payments.paymentConfig;
