import { ElectronicSignatureStep } from './enuns';

export enum ElectronicSignatureStatusEnum {
  PENDING = 'pending',
  DONE = 'done',
}

export type ElectronicSignatureStatusItem = {
  attest_id: number;
  status: ElectronicSignatureStatusEnum;
  access_link: string;
};

export type EletronicSignatureState = Partial<{
  isSignatureModalOpen: boolean;
  screenStep: ElectronicSignatureStep;
  pdfDownloadURL: string;
  phoneNumber: {
    value: string;
    hasError: boolean;
  };
  attestId: number;
  clinicId: number;
  signatureLink: string;
}>;

export type GetSignatureLinkRequestBody = {
  document: string;
  document_name: string;
  patient_name: string;
  patient_id: number;
  clinic_id: number;
  attest_id: number;
  physician_id: number;
};

export type GetSignatureLinkResponse = {
  data: { access_link: string };
};

export type GetSignatureStatusResponse = ElectronicSignatureStatusItem[];
