// External
import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { reset, change } from 'redux-form';
import { ApiErrors } from '@types';

// Internal
import { emitErrors } from 'shared/utils/emitErrors';
import {
  FORM_STOCK_ADD,
  FORM_STOCK_INPUT,
  FORM_STOCK_OUTPUT,
} from 'shared/constants/forms';
import { formatDate } from 'shared/utils/date';
import { reduceObjectNumbers } from 'shared/utils/reducer';
import { handlerErrors } from 'state/shared/sagas';
import { CLINIC_ID, PROFILE_ID } from 'shared/constants/services';
import { StoreState } from 'state/rootReducer';
import {
  StockTabs,
  MIN_CHARS_TO_SEARCH,
  Features,
} from 'features/stock/constants';
import * as services from '../services';
import * as actions from './actions';
import * as types from './constants';
import {
  getProductAndStockData,
  normalizeInventoriesHistory,
  normalizeListHistory,
  normalizeStockSearch,
  checkPagination,
  normalizeUserSearch,
} from './utils';
import {
  getClinicId,
  getProfileId,
  getInventoriesParams,
  getPageNumber,
  getProducts,
  getUsers,
} from './selectors';
import { Inventory } from 'features/stock/state/types';

const getSelectedList = ({ stock }: StoreState) => stock.selectedList;
const getSelectedProduct = ({ stock: { selectedProduct } }: StoreState) =>
  selectedProduct;
const getStock = ({ stock }: StoreState) => stock;

type FetchStockUnit = ReturnType<typeof actions.fetchStockUnit>;
export function* workerFetchStockUnit({ extra }: FetchStockUnit) {
  try {
    const { search } = extra || '';
    const page = yield select(getPageNumber);
    const selectedFilter = yield select((state) => state.stock.selectedFilter);

    const { getResponseData: getResponseDataUnit, errors: errorsUnit } =
      yield call(services.fetchUnit);

    const { getResponseData: getResponseDataStock, errors: errorsStock } =
      yield call(
        services.fetchStock,
        normalizeStockSearch(selectedFilter, search, page),
      );

    if (errorsUnit) {
      throw errorsUnit;
    } else if (errorsStock) {
      throw errorsStock;
    } else {
      const payloadUnit = getResponseDataUnit();
      const payloadStock = getResponseDataStock();
      const { results: stocks, count: totalCount } = payloadStock;
      const { results: units } = payloadUnit;
      const pageCurrent = checkPagination(search, totalCount, page);

      yield put(
        actions.fetchStockUnitSuccess(stocks, units, totalCount, pageCurrent),
      );
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

type FetchDetail = ReturnType<typeof actions.fetchDetail>;
export function* workerFetchDetail({ extra }: FetchDetail) {
  try {
    const { getResponseData, errors } = yield call(
      services.fetchProducts,
      extra.productId,
    );
    if (errors) {
      throw errors;
    } else {
      const payload = getResponseData();
      yield put(actions.fetchDetailSuccess(payload));
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

type FetchProductStock = ReturnType<typeof actions.fetchProductAndStock>;
export function* workerFetchProductStock({ extra }: FetchProductStock) {
  try {
    const { getResponseData: getResponseDataProduct, errorsProduct } =
      yield call(services.fetchProducts, extra.productId);

    const { getResponseData: getResponseDataStock, errorsStock } = yield call(
      services.fetchStock,
      normalizeStockSearch(StockTabs.All, null, null, extra.productId),
    );

    if (errorsProduct) {
      throw errorsProduct;
    } else if (errorsStock) {
      yield put(actions.fetchFailure(emitErrors(errorsStock)));
    } else {
      const payload = getProductAndStockData(
        getResponseDataProduct(),
        getResponseDataStock(),
      );
      yield put(actions.fetchProductAndStockSuccess(extra.nextModal, payload));
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

type CreateProduct = ReturnType<typeof actions.createProduct>;
export function* workerCreateProduct({ extra }: CreateProduct) {
  try {
    const { product } = extra;
    const clinicId = yield select(getClinicId);
    const profileId = yield select(getProfileId);

    const updatedProduct = {
      ...product,
      clinicId,
      profileId,
    };

    const { errors } = yield call(services.createProduct, updatedProduct);
    if (errors) {
      throw errors;
    } else {
      const { closeModal } = product;
      if (closeModal) yield put(actions.changeModal());

      yield put(actions.fetchStockUnit({ isFetching: false }, { search: '' }));
      yield put(reset(FORM_STOCK_ADD));
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

type UpdateProduct = ReturnType<typeof actions.updateProduct>;
export function* workerUpdateProduct({ extra }: UpdateProduct) {
  try {
    const { product } = extra;
    const clinicId = yield select(getClinicId);
    const profileId = yield select(getProfileId);

    const updatedProduct = {
      ...product,
      clinicId,
      profileId,
    };

    const { errors } = yield call(services.updateProduct, updatedProduct);
    if (errors) {
      throw errors;
    } else {
      yield put(actions.fetchStockUnit({ isFetching: false }));
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

export function* workerDeleteProduct() {
  try {
    const selectedList = yield select(getSelectedList);
    const selectedProduct = yield select(getSelectedProduct);
    const singleProduct = selectedProduct && selectedProduct.id;

    const updatedSelectedList = singleProduct
      ? selectedList.concat(singleProduct)
      : selectedList;

    const requestPromise = updatedSelectedList.map((id: number) =>
      call(services.deleteProduct, id),
    );
    const response = yield all(requestPromise);
    const responseError = response.reduce(
      (acc: ApiErrors[], current: any) =>
        current.errors ? acc.concat(current.errors) : acc,
      [],
    );

    if (responseError.length > 0) {
      throw responseError;
    } else {
      const payload = {
        isFetching: false,
        selectedList: [],
        selectedProduct: null,
      };
      yield put(actions.fetchStockUnit(payload));
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

type FetchEspecificStockURL = ReturnType<typeof actions.fetchEspecificStockURL>;
export function* workerFetchEspecificStockURL({
  extra,
}: FetchEspecificStockURL) {
  try {
    const { getResponseData, errors } =
      extra.feature === Features.InventoriesHistory
        ? yield call(services.fetchExpecificURLServerless, extra.url)
        : yield call(services.fetchExpecificURL, extra.url);

    if (errors) throw errors;

    const payload = getResponseData();
    const { results: stocks, count: totalCount } = payload;
    if (extra.feature === Features.InventoriesHistory) {
      const { results: inventoriesHistory, count } =
        normalizeListHistory(payload);
      yield put(
        actions.fetchInventoriesHistorySuccess(inventoriesHistory, count),
      );
    } else {
      yield put(actions.fetchEspecificStockURLSuccess(stocks, totalCount));
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

type FetchUpdateSelectedList = ReturnType<typeof actions.updateSelectedList>;
export function* workerUpdateSelectedList({ extra }: FetchUpdateSelectedList) {
  try {
    const { selectedId } = extra;
    const selectedList = yield select(getSelectedList);

    const alreadySelected = selectedList.find(
      (item: number) => item === selectedId,
    );

    if (alreadySelected !== undefined) {
      const updatedList = selectedList.filter(
        (item: number) => item !== selectedId,
      );
      yield put(actions.updateSelectedListSuccess(updatedList));
    } else {
      const updatedList = [...selectedList, selectedId];
      yield put(actions.updateSelectedListSuccess(updatedList));
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

export function* fetchProductFromNextPage(nextPage: string | null) {
  try {
    const currentProducts = yield select(getProducts);
    const currentPage = nextPage && nextPage.match(/(page=)(.*)(&)/)[2];

    if (nextPage) {
      const { getResponseData } = yield call(
        services.getNextPage,
        'products/?page=',
        currentPage,
      );
      const { next, results } = getResponseData();
      yield put(
        actions.fetchProductsByNameSuccess([...currentProducts, ...results]),
      );

      yield call(fetchProductFromNextPage, next);
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

type FetchProductByName = ReturnType<typeof actions.fetchProductsByName>;
export function* workerFetchProductByName({
  extra: { params },
}: FetchProductByName) {
  try {
    const { getResponseData, errors } = yield call(
      services.fetchProductsByName,
      params,
    );

    if (errors) {
      throw errors;
    }

    const payload = getResponseData();
    yield put(actions.fetchProductsByNameSuccess(payload.results));

    if (payload.next && !params.page_size) {
      yield call(fetchProductFromNextPage, payload.next);
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

export function* fetchUserFromNextPage(nextPage) {
  try {
    const currentUsers = yield select(getUsers);
    const currentPage = nextPage && nextPage.match(/(page=)(.*)(&)/)[2];

    if (nextPage) {
      const { getResponseData } = yield call(
        services.getNextPage,
        'users/?page=',
        currentPage,
      );
      const { next, results } = getResponseData();
      yield put(actions.fetchUserByNameSuccess([...currentUsers, ...results]));

      yield call(fetchUserFromNextPage, next);
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

type FetchUserByName = ReturnType<typeof actions.fetchUserByName>;
export function* workerFetchUserByName({
  extra: { userName },
}: FetchUserByName) {
  try {
    const clinicId = yield select(getClinicId);
    const { getResponseData, errors } = yield call(
      services.fetchUserByName,
      normalizeUserSearch(userName, clinicId),
    );

    if (errors) {
      throw errors;
    }

    const payload = getResponseData();
    yield put(actions.fetchUserByNameSuccess(payload.results));

    if (payload.next) {
      yield call(fetchUserFromNextPage, payload.next);
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

type FetchInventoriesByProductId = ReturnType<
  typeof actions.fetchInventoriesByProductId
>;
export function* workerFetchInventoriesByProductId({
  extra: { productId, pageSize },
}: FetchInventoriesByProductId) {
  try {
    let nextPage: string | null = null;
    let allInventories: Inventory[] = [];
    let keepFetching = true;
    let previousResults: Inventory[] = [];
    const maxIterations = 20;
    let iterationCount = 0;

    while (keepFetching && iterationCount < maxIterations) {
      const { getResponseData, errors } = yield call(
        services.fetchInventoriesByProductId,
        {
          productId,
          pageSize,
          url: nextPage,
        },
      );

      if (errors) {
        throw errors;
      } else {
        const payload = getResponseData();

        if (
          payload.next === nextPage &&
          JSON.stringify(payload.results) === JSON.stringify(previousResults)
        ) {
          break;
        }

        allInventories = [...allInventories, ...payload.results];
        previousResults = payload.results;
        nextPage = payload.next;
        keepFetching = !!nextPage;
      }
      iterationCount += 1;
    }

    yield put(actions.fetchInventoriesByProductIdSuccess(allInventories));
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

type CreateInventory = ReturnType<typeof actions.createInventory>;
export function* workerCreateInventory({ extra }: CreateInventory) {
  try {
    const { inventory } = extra;
    const clinicId = yield select(getClinicId);
    const profileId = yield select(getProfileId);
    const productId = yield select(({ stock }) => stock.selectedProduct.id);

    const updatedInventory = {
      ...inventory,
      expirationDate: inventory.expirationDate
        ? formatDate(inventory.expirationDate, 'DD/MM/YYYY', 'YYYY-MM-DD')
        : null,
      productId,
      clinicId,
      profileId,
    };

    const { errors } = yield call(services.createInventory, updatedInventory);
    if (errors) {
      yield put(actions.fetchFailure(emitErrors(errors)));
    } else {
      yield put(actions.createInventorySuccess());
      yield put(actions.fetchStockUnit({ isFetching: true }));
      yield put(actions.clearModal());
      const { closeModal } = inventory;
      if (closeModal) yield put(actions.changeModal());
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

type CreateLot = ReturnType<typeof actions.createLot>;
export function* workerCreateLot({ extra: { description } }: CreateLot) {
  try {
    const clinicId = yield select(getClinicId);
    const profileId = yield select(getProfileId);

    const updatedLot = {
      description,
      clinicId,
      profileId,
    };

    const { getResponseData, errors } = yield call(
      services.createLot,
      updatedLot,
    );
    if (errors) {
      yield put(actions.fetchFailure(emitErrors(errors)));
    } else {
      const payload = getResponseData();
      yield put(actions.createLotSuccess(payload));
      yield put(actions.setSelectedLot(payload));
      yield put(change(FORM_STOCK_INPUT, 'lotId', payload.id));
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

export function* workerChangeProductDescription() {
  const { productDescription, selectedProduct } = yield select(getStock);

  if (productDescription.length < MIN_CHARS_TO_SEARCH) return;

  if (selectedProduct && selectedProduct.description !== productDescription) {
    yield put(actions.setSelectedProduct(null));
  }
}

export function* workerSetSelectedProduct() {
  const selectedProduct = yield select(getSelectedProduct);

  if (selectedProduct) {
    const { id } = selectedProduct;

    yield put(actions.fetchDetail(id));
    yield put(actions.fetchInventoriesByProductId(id));
  }
}

type ChangeProduct = ReturnType<typeof actions.changeProduct>;
export function* workerChangeProduct({ params }: ChangeProduct) {
  const { product } = params;

  yield put(actions.setSelectedProduct(product));
  yield put(actions.changeProductDescription(product.description));
  yield put(change(FORM_STOCK_INPUT, 'productId', product.id));
  yield put(change(FORM_STOCK_OUTPUT, 'productId', product.id));
  yield put(
    change(FORM_STOCK_INPUT, 'productDescription', product.description),
  );
  yield put(
    change(FORM_STOCK_OUTPUT, 'productDescription', product.description),
  );
}

export function* workerChangeLotDescription() {
  const { selectedLot, lotDescription } = yield select(getStock);

  if (selectedLot && selectedLot.description !== lotDescription) {
    yield put(actions.setSelectedLot(null));
  }
}

type ChangeLot = ReturnType<typeof actions.changeLot>;
export function* workerChangeLot({ params }: ChangeLot) {
  const { lot } = params;

  yield put(actions.setSelectedLot(lot));
  yield put(actions.changeLotDescription(lot.description));
  yield put(change(FORM_STOCK_INPUT, 'lotId', lot.id));
  yield put(change(FORM_STOCK_OUTPUT, 'lotId', lot.id));
  yield put(change(FORM_STOCK_INPUT, 'lotDescription', lot.description));

  const expirationDate = formatDate(lot.expirationDate);
  yield put(change(FORM_STOCK_INPUT, 'expirationDate', expirationDate));
}

export function* workerClearModal() {
  yield put(reset(FORM_STOCK_INPUT));
  yield put(reset(FORM_STOCK_OUTPUT));
}

type ViewLotsByProductId = ReturnType<typeof actions.viewLotsByProductId>;
export function* workerViewLotsByProductId({
  params: { productId },
}: ViewLotsByProductId) {
  const PAGE_SIZE_TO_GET_LOTS = 1000;
  const openedProductId = yield select(({ stock }) => stock.openedProductId);

  const alreadyOpened = productId === openedProductId;
  if (alreadyOpened) {
    yield put(actions.changeOpenedProductId(null));
    return;
  }

  yield put(actions.changeOpenedProductId(productId));
  yield put(
    actions.fetchInventoriesByProductId(productId, PAGE_SIZE_TO_GET_LOTS),
  );
  yield put(actions.fetchStockByProductId(productId));
}

type FetchStockByProductId = ReturnType<typeof actions.fetchStockByProductId>;
export function* workerFetchStockByProductId({
  extra: { productId },
}: FetchStockByProductId) {
  try {
    const { getResponseData, errors } = yield call(
      services.fetchStockByProductId,
      productId,
    );

    if (errors) {
      yield put(actions.fetchFailure(emitErrors(errors)));
    } else {
      const payload = getResponseData();
      yield put(actions.fetchStockByProductIdSuccess(payload.results));
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

/**
 * Sets params to deprecated stock interceptor
 * @deprecated
 */
export function* workerGetProfileAndClinic() {
  const { clinicId, profileId } = yield select(getStock);
  localStorage.setItem(CLINIC_ID, clinicId);
  localStorage.setItem(PROFILE_ID, profileId);
}

export function* workerChangeModal() {
  const currentModal = yield select(({ stock }) => stock.currentModal);

  if (currentModal === null) {
    yield put(actions.clearModal());
  }
}

export function* workerSelectAllProducts() {
  try {
    const getStocks = ({ stock }: StoreState) => stock.stocks;
    const getTotalSelected = ({ stock }: StoreState) => stock.totalSelected;
    const stocks = yield select(getStocks);
    const currentSelectedList = yield select(getSelectedList);
    const currentTotalSelected = yield select(getTotalSelected);

    if (currentSelectedList.length === currentTotalSelected) {
      yield put(actions.selectAllProductsSuccess([], null));
    } else {
      const selectedList = reduceObjectNumbers(stocks);

      yield put(
        actions.selectAllProductsSuccess(selectedList, selectedList.length),
      );
    }
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

export function* workerFetchInventoriesHistory() {
  try {
    const history = yield select(getInventoriesParams);
    const clinicId = yield select(getClinicId);
    const { getResponseData, errors } = yield call(
      services.fetchInventoriesHistory,
      normalizeInventoriesHistory(clinicId, history),
    );
    if (errors) throw errors;
    const payload = getResponseData();
    const { results: inventoriesHistory, count: totalCount } =
      normalizeListHistory(payload);
    yield put(
      actions.fetchInventoriesHistorySuccess(inventoriesHistory, totalCount),
    );
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchFailure);
  }
}

export default function* stockSagas() {
  yield takeLatest(types.FETCH_STOCK_UNIT_ACTION, workerFetchStockUnit);
  yield takeLatest(types.FETCH_DETAIL_ACTION, workerFetchDetail);
  yield takeLatest(types.CREATE_PRODUCT_ACTION, workerCreateProduct);
  yield takeLatest(types.UPDATE_PRODUCT_ACTION, workerUpdateProduct);
  yield takeLatest(types.DELETE_PRODUCT_ACTION, workerDeleteProduct);
  yield takeLatest(
    types.FETCH_ESPECIFIC_STOCK_URL_ACTION,
    workerFetchEspecificStockURL,
  );
  yield takeLatest(types.FETCH_PRODUCT_STOCK_ACTION, workerFetchProductStock);
  yield takeLatest(types.SELECTED_LIST_ACTION, workerUpdateSelectedList);
  yield takeLatest(
    types.FETCH_PRODUCTS_BY_NAME_ACTION,
    workerFetchProductByName,
  );
  yield takeLatest(types.FETCH_USER_ACTION, workerFetchUserByName);
  yield takeLatest(
    types.FETCH_INVENTORIES_BY_PRODUCT_ID_ACTION,
    workerFetchInventoriesByProductId,
  );
  yield takeLatest(types.CREATE_INVENTORY_ACTION, workerCreateInventory);
  yield takeLatest(types.CREATE_LOT_ACTION, workerCreateLot);
  yield takeLatest(
    types.CHANGE_PRODUCT_DESCRIPTION_ACTION,
    workerChangeProductDescription,
  );
  yield takeLatest(types.SET_SELECTED_PRODUCT_ACTION, workerSetSelectedProduct);
  yield takeLatest(types.CHANGE_PRODUCT_ACTION, workerChangeProduct);
  yield takeLatest(
    types.CHANGE_LOT_DESCRIPTION_ACTION,
    workerChangeLotDescription,
  );
  yield takeLatest(types.CHANGE_LOT_ACTION, workerChangeLot);
  yield takeLatest(types.CLEAR_MODAL_ACTION, workerClearModal);
  yield takeLatest(
    types.VIEW_LOTS_BY_PRODUCT_ID_ACTION,
    workerViewLotsByProductId,
  );
  yield takeLatest(
    types.FETCH_STOCK_BY_PRODUCT_ID_ACTION,
    workerFetchStockByProductId,
  );
  yield takeLatest(
    types.GET_PROFILE_AND_CLINIC_ACTION,
    workerGetProfileAndClinic,
  );
  yield takeLatest(types.CURRENT_MODAL_ACTION, workerChangeModal);
  yield takeLatest(types.SELECT_ALL_PRODUCTS_ACTION, workerSelectAllProducts);
  yield takeLatest(types.FETCH_STOCK_FILTER_ACTION, workerFetchStockUnit);
  yield takeLatest(
    types.FETCH_INVENTORIES_HISTORY_ACTION,
    workerFetchInventoriesHistory,
  );
}
