import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  MaterialUICore,
  Paper,
  MaterialIcons,
  Grid,
  SectionTitle,
  Box,
  Heading,
} from '@iclinic/design-system';
import { init } from 'features/onlinePayment/state/checkout/infos/actions';
import {
  getPhysician,
  getClinic,
  getEvent,
  getBill,
  getFetchPhysicianInfos,
  getFetchEventInfos,
  getFetchError,
  getPaymentType,
} from 'features/onlinePayment/state/checkout/infos/selectors';
import PhysicianInfos from 'features/onlinePayment/components/checkout/infos/Physician';
import ClinicInfos from 'features/onlinePayment/components/checkout/infos/Clinic';
import EventInfos from 'features/onlinePayment/components/checkout/infos/Event';
import { NameContentLoader } from 'features/onlinePayment/components/checkout/infos/ContentLoaders';
import PaymentTypeOptions from 'features/onlinePayment/components/checkout/infos/PaymentTypeOptions';
import PaymentTypeInfos from 'features/onlinePayment/components/checkout/infos/PaymentType';
import { ErrorAlert } from 'shared/components';
import * as S from './CheckoutInfos.styles';

const { useMediaQuery, useTheme, styled } = MaterialUICore;
const { DateRange, AttachMoney } = MaterialIcons;

const PaperInfosPhysician = styled(Paper)(() => ({
  minHeight: '300px',
}));

export default function CheckoutInfos(): JSX.Element {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const [
    physician,
    clinic,
    event,
    bill,
    fetchPhysicianInfos,
    fetchEventInfos,
    fetchError,
  ] = [
    useSelector(getPhysician),
    useSelector(getClinic),
    useSelector(getEvent),
    useSelector(getBill),
    useSelector(getFetchPhysicianInfos),
    useSelector(getFetchEventInfos),
    useSelector(getFetchError),
  ];
  const paymentType = useSelector(getPaymentType);

  useEffect((): void => {
    dispatch(init());
  }, [dispatch]);

  const physicianName = useMemo(
    () =>
      fetchPhysicianInfos.isFetching ? <NameContentLoader /> : physician?.name,
    [fetchPhysicianInfos.isFetching, physician],
  );

  const patientName = useMemo(
    () =>
      fetchEventInfos.isFetching ? <NameContentLoader /> : event?.patient.name,
    [fetchEventInfos.isFetching, event],
  );

  if (fetchError) {
    return (
      <ErrorAlert
        message={<span dangerouslySetInnerHTML={{ __html: fetchError }} />}
        mt={3}
      />
    );
  }

  return (
    <Box data-testid="checkout-infos">
      <Box my={4}>
        <Heading variant="lg" inverseTextColor>
          Cobrança de {physicianName} → {patientName}
        </Heading>
      </Box>
      <Grid container spacing={3}>
        <Grid xs={12} md={5} item>
          <Box mb={3}>
            <Paper variant="outlined">
              <SectionTitle icon={<DateRange />} title="Agendamento" mb={3} />
              <EventInfos
                event={event}
                bill={bill}
                loading={fetchEventInfos.isFetching}
              />
            </Paper>
          </Box>
          <PaperInfosPhysician variant="outlined">
            <PhysicianInfos
              physician={physician}
              loading={fetchPhysicianInfos.isFetching}
            />
            <ClinicInfos
              clinic={clinic}
              loading={fetchPhysicianInfos.isFetching}
            />
          </PaperInfosPhysician>
        </Grid>
        <Grid xs={12} md={7} item>
          <Box position="relative" height="100%">
            <Paper variant="outlined" style={{ height: '100%' }}>
              <SectionTitle
                icon={<AttachMoney />}
                title="Forma de pagamento"
                mb={3}
              />
              <PaymentTypeOptions loading={fetchEventInfos.isFetching} />
            </Paper>
            {!!bill && (
              <S.AnimatedCard
                data-testid="form-pay-animated-card"
                variant="outlined"
                show={paymentType}
                aria-hidden={!paymentType}
                mobile={isSmall}
              >
                <PaymentTypeInfos payType={paymentType} bill={bill} />
              </S.AnimatedCard>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
