import React from 'react';
import { Button, Chip, MaterialIcons } from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';

import { socialMediaMessage } from 'features/referrals/constants/urls';
import { getShortUrls } from 'features/referrals/state/selectors';
import * as S from './ShareOptions.styles';
import { MoreOptions } from './MoreOptions';
import { saveInterestIndication } from 'features/referrals/state';

const { WhatsApp, ChevronRightRounded } = MaterialIcons;

type ShareOptionsProps = {
  isShareable: boolean;
  isMobile: boolean;
};

export const ShareOptions = ({ isShareable, isMobile }: ShareOptionsProps) => {
  const dispatch = useDispatch();
  const shortUrls = useSelector(getShortUrls);
  const whatsappUrl = shortUrls?.whatsapp || '';

  const whatsappTrackingId = 'referrals_whatsapp_click';

  const onClickHandler = () => {
    const message = encodeURI(`${socialMediaMessage}${whatsappUrl}`);
    dispatch(saveInterestIndication({ channel: 'copy_link' }));
    window.open(`https://wa.me/?text=${message}`, '_blank');
  };

  if (isMobile) {
    return (
      <ul>
        <S.ShareOptionWrapper
          data-sp={whatsappTrackingId}
          data-ga={whatsappTrackingId}
          onClick={onClickHandler}
        >
          <Button
            startIcon={<WhatsApp />}
            variant="text"
            color="transparent"
            size="small"
            disableRipple
            disableFocusRipple
          >
            Whatsapp
          </Button>
          <S.ChipWrapper>
            <Chip severity="success" size="small" label="Mais usado" />
            <ChevronRightRounded />
          </S.ChipWrapper>
        </S.ShareOptionWrapper>
        {isShareable && <MoreOptions />}
      </ul>
    );
  }

  return (
    <Button
      data-sp={whatsappTrackingId}
      data-ga={whatsappTrackingId}
      onClick={onClickHandler}
      size="small"
      startIcon={<WhatsApp />}
    >
      Whatsapp
    </Button>
  );
};
