import React from 'react';
import {
  tokens,
  MaterialUICore,
  Button,
  ButtonProps,
  TextField as DSTextField,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing, color } = tokens;

export const ShareLinkWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: spacing.xs,
  gridTemplateColumns: '1fr',

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: '1fr auto',
  },
}));

export const TextField = styled(DSTextField)({
  '& .MuiFilledInput-input': {
    paddingTop: spacing.xs,
  },
});

type GetColorProps = {
  copied: boolean;
  hover?: boolean;
};

const getButtonColor = ({ copied, hover = false }: GetColorProps) => {
  const gradient = hover
    ? '#FBA600 0%, #E05100 100%'
    : '#FFAF12 0%, #FF8410 100%';

  return copied
    ? color.feedback.success.main
    : `linear-gradient(180deg, ${gradient})`;
};

interface ButtonStatusProps extends ButtonProps {
  copied: boolean;
}

export const CopyButton = styled(({ copied, ...rest }: ButtonStatusProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Button {...rest} />
))({
  background: ({ copied }: ButtonStatusProps) => getButtonColor({ copied }),

  '&:hover': {
    background: ({ copied }: ButtonStatusProps) =>
      getButtonColor({ copied, hover: true }),
  },
});

export const InputButton = styled('button')({
  border: 'none',
  outline: 'none',
  width: '100%',
});
