import axios from 'axios';

import {
  interceptorRequestAuthentication,
  interceptorResponseTransformData,
  interceptorResponseErrorsPromise,
} from 'services/iclinic/interceptors';

const apiRecords = axios.create({
  baseURL: process.env.ICLINIC_API_URL,
});

apiRecords.interceptors.request.use(interceptorRequestAuthentication);
apiRecords.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrorsPromise,
);

export const getLatestDiagnoses = (patientId: string) =>
  apiRecords.get(`/patients/patient/${patientId}/last-diagnosis/`);
