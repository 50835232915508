import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FetchXMLFilter, SnackbarProps } from 'features/tissInvoicing/types';
import {
  MaterialUICore,
  MaterialIcons,
  Select,
  InputAdornment,
  Body,
} from '@iclinic/design-system';
import { actions } from 'features/tissInvoicing/state/lot';
import { getDownloadXMLState } from 'features/tissInvoicing/state/lot/selectors';
import * as Styles from './styles';

const { PlaylistAddCheck } = MaterialIcons;

const { MenuItem } = MaterialUICore;

export const DOWNLOAD_XML_MESSAGE: SnackbarProps = {
  message: 'Arquivo .XML baixado com sucesso',
  severity: 'success',
};

const DOWNLOAD_XML_OPTIONS: Array<{ label: string; value: FetchXMLFilter }> = [
  {
    label: 'Data do atendimento',
    value: 'date',
  },
  {
    label: 'Nome do paciente',
    value: 'patient',
  },
];

function DownloadXML() {
  const classes = Styles.useStyles();

  const dispatch = useDispatch();

  const { query } = useSelector(getDownloadXMLState);

  const handleChangeValue = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) =>
    dispatch(
      actions.setLotXMLFileFilters({
        orderBy: e.target.value as FetchXMLFilter,
      }),
    );

  return (
    <>
      <Body variant="xs">
        Selecione o tipo de ordenação abaixo para esse arquivo .XML:
      </Body>
      <Select
        classes={{ root: classes.select }}
        label="Selecione"
        id="order_by"
        name="order_by"
        value={query?.orderBy}
        defaultValue="date"
        onChange={handleChangeValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PlaylistAddCheck />
            </InputAdornment>
          ),
        }}
      >
        {DOWNLOAD_XML_OPTIONS.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export default DownloadXML;
