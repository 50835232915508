import * as Yup from 'yup';

export const requiredFieldText = 'Campo obrigatório';

export const hiredSectionSchema = Yup.object().shape({
  hired_executant_code: Yup.string().required(requiredFieldText),
  hired_executant_name: Yup.string().required(requiredFieldText),
  hired_executant_cnes: Yup.string().required(requiredFieldText),
  professional_executant_council: Yup.string().required(requiredFieldText),
  professional_executant_council_number:
    Yup.string().required(requiredFieldText),
  professional_executant_council_state:
    Yup.string().required(requiredFieldText),
  professional_executant_cbo: Yup.string().required(requiredFieldText),
  professional_executant_name: Yup.string().required(requiredFieldText),
});
