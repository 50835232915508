import { createAction, createSlice } from '@reduxjs/toolkit';

import { ProceduresListType } from '../mocks';

const nameStore = '@webapp/scheduleDetails';

export const initialState: ProceduresListType = [];

export const setProcedures = createAction(
  `${nameStore}/procedures/setProcedures`,
);

const ScheduleProcedureSlice = createSlice({
  name: `${nameStore}/procedures`,
  initialState,
  reducers: {
    setScheduleProceduresData: (_, action) => action.payload,
    updateScheduleProceduresData: (state, action) => [
      ...state,
      ...action.payload,
    ],
    updateScheduleProcedureById: (state, action) =>
      state.map((procedure) => {
        if (procedure.id === action.payload.id) return action.payload;
        return procedure;
      }),
    resetState: () => initialState,
  },
});

export const {
  setScheduleProceduresData,
  updateScheduleProcedureById,
  resetState,
} = ScheduleProcedureSlice.actions;

export default ScheduleProcedureSlice.reducer;
