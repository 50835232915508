import { AUTH_TOKEN_KEY } from 'shared/constants/auth';
import { CLINIC_ID } from 'shared/constants/services';

/**
 * Request authentication with qs params to Stock
 * @deprecated
 */
const interceptorRequestAuthenticationStock = (request) => {
  const tokenValue = window.localStorage.getItem(AUTH_TOKEN_KEY);
  const clinicId = window.localStorage.getItem(CLINIC_ID);

  if (!tokenValue) {
    return request;
  }

  const requestParams = {
    params: {
      token: tokenValue,
      clinic_id: clinicId,
      ...request.params,
    },
  };
  return Object.assign(request, requestParams);
};

export default interceptorRequestAuthenticationStock;
