export enum AccessWay {
  Empty = 0,
  Unique = 1,
  SameCopy = 2,
  Different = 3,
}

export const AccessWayOptions = [
  { value: AccessWay.Empty, label: '' },
  { value: AccessWay.Unique, label: '1 - Única' },
  { value: AccessWay.SameCopy, label: '2 - Mesma via' },
  { value: AccessWay.Different, label: '3 - Diferentes vias' },
];

export enum Technique {
  Empty = 0,
  Conventional = 1,
  Video = 2,
  Robotic = 3,
}

export const TechniqueOptions = [
  { value: Technique.Empty, label: '' },
  { value: Technique.Conventional, label: '1 - Convencional' },
  { value: Technique.Video, label: '2 - Vídeo' },
  { value: Technique.Robotic, label: '3 - Robótica' },
];

export enum CharacterService {
  Elective = 1,
  Urgent = 2,
}

export const CharacterServiceOptions = [
  { value: CharacterService.Elective, label: '1 - Eletivo' },
  { value: CharacterService.Urgent, label: '2 - Urgência/Emergência' },
];

export enum NBAppointment {
  Yes = 'S',
  No = 'N',
}

export const NBAppointmentOptions = [
  { value: NBAppointment.Yes, label: 'Sim' },
  { value: NBAppointment.No, label: 'Não' },
];

export enum DocumentType {
  CPF = '0',
  CNPJ = '1',
  Code = '2',
}

export const DocumentTypeOptions = [
  { value: DocumentType.CNPJ, label: 'CNPJ' },
  { value: DocumentType.CPF, label: 'CPF' },
  { value: DocumentType.Code, label: 'Código' },
];

export const RETRY_TIMES = 3;
export const RETRY_DELAY_MS = 70;

export const MAX_GUIDES_PER_LOT = 100;
