import { all, fork } from 'redux-saga/effects';

import exportListSagas from './exportList/sagas';
import exportHomeSagas from './exportHome/sagas';
import exportDownloadSagas from './exportDownload/sagas';
import exportRequestSagas from './exportRequest/sagas';

export default function* exportDataSagas() {
  yield all([
    fork(exportHomeSagas),
    fork(exportListSagas),
    fork(exportDownloadSagas),
    fork(exportRequestSagas),
  ]);
}
