import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled, Pagination } = MaterialUICore;

export const StyledPagination = styled(Pagination)({
  '& .MuiPaginationItem-page': {
    fontSize: '0.875rem',
    color: tokens.color.neutral[500],
    fontWeight: tokens.font.weight.medium,
  },
  '& .Mui-selected': {
    background: tokens.color.neutral[50],
  },
});
