import React, { ChangeEvent } from 'react';
import { TextArea } from '@iclinic/design-system';
import { useDispatch } from 'react-redux';

import { actions } from '../../../state';
import { HighlightsInfoTextCard } from '../../../types';

const { setHighlightsInfoContent } = actions;

const MESSAGE_IDLE = 'As informações serão automaticamente salvas';
const MESSAGE_SAVED = 'As informações foram salvas com sucesso';

interface CardTextContentProps {
  setUpSaveOnIdle: () => void;
  handleBlur: () => void;
  isSaved: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  highlightInfoSelected: HighlightsInfoTextCard;
}

export const CardTextContent = ({
  setUpSaveOnIdle,
  handleBlur,
  isSaved,
  inputRef,
  highlightInfoSelected,
}: CardTextContentProps) => {
  const dispatch = useDispatch();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setHighlightsInfoContent(event.target.value));
    setUpSaveOnIdle();
  };

  return (
    <TextArea
      label="Descrição"
      placeholder="Digite aqui"
      value={highlightInfoSelected?.content || ''}
      fullWidth
      helperText={isSaved ? MESSAGE_SAVED : MESSAGE_IDLE}
      onChange={handleChange}
      onBlur={handleBlur}
      inputRef={inputRef}
    />
  );
};

export default CardTextContent;
