import { Reducer } from 'redux';

import { ActionType } from '@types';
import { ScheduleState } from './types';
import * as types from './constants';
import * as actions from './actions';

export const initialState: ScheduleState = {
  isPaymentsControlDrawerOpen: false,
  isScheduleModalOpen: false,
  isPatientWaitingListOpen: false,
  isScheduleObservationOpen: false,
  startDate: '',
  endDate: '',
  isFetching: false,
  pdf: '',
  errors: [],
};

type ScheduleActions = ActionType<typeof actions>;

const scheduleReducer: Reducer<ScheduleState, ScheduleActions> = (
  state = initialState,
  action: ScheduleActions,
) => {
  switch (action.type) {
    case types.TOGGLE_TRANSACTIONS_DRAWER:
      return {
        ...state,
        isPaymentsControlDrawerOpen: !state.isPaymentsControlDrawerOpen,
      };

    case types.TOGGLE_SCHEDULE_MODAL:
      return {
        ...state,
        isScheduleModalOpen: action.payload.isScheduleModalOpen,
      };

    case types.TOGGLE_PATIENT_WAITING_LIST:
      return {
        ...state,
        isPatientWaitingListOpen: action.payload.isPatientWaitingListOpen,
      };

    case types.TOGGLE_SCHEDULE_OBSERVATION:
      return {
        ...state,
        isScheduleObservationOpen: action.payload.isScheduleObservationOpen,
      };

    case types.TOGGLE_PERIOD_PRINT_SCHEDULE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };

    case types.PRINT_SCHEDULE_ACTION:
      return {
        ...state,
        isFetching: action.payload.isFetching,
      };

    case types.PRINT_SCHEDULE_SUCCESS:
      return {
        ...state,
        pdf: action.payload.pdf,
        isFetching: action.payload.isFetching,
      };

    case types.PRINT_SCHEDULE_FAILURE:
      return {
        ...state,
        errors: action.payload.errors,
        isFetching: action.payload.isFetching,
      };

    case types.OPEN_SCHEDULE_SETTINGS:
    default:
      return state;
  }
};

export default scheduleReducer;
