import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import { Container } from '@iclinic/design-system';
import Header from 'features/onlinePayment/components/checkout/Header';
import CheckoutInfos from 'features/onlinePayment/containers/checkout/infos';
import CheckoutInvalid from 'features/onlinePayment/containers/checkout/invalid';
import CheckoutSuccess from 'features/onlinePayment/containers/checkout/success';
import useStyles from './Checkout.styles';

export default function Checkout({ match }: RouteComponentProps): JSX.Element {
  useStyles();

  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <Switch>
          <Route
            exact
            path={`${match.path}/pagamento`}
            component={CheckoutInfos}
          />
          <Route
            exact
            path={`${match.path}/pagamento-invalido`}
            component={CheckoutInvalid}
          />
          <Route
            exact
            path={`${match.path}/pagamento-efetivado`}
            component={CheckoutSuccess}
          />
        </Switch>
      </Container>
    </>
  );
}
