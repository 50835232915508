import React from 'react';
import { MaterialIcons } from '@iclinic/design-system';

import {
  CheckIcon,
  UncheckIcon,
} from '../components/OffCanvasPlansComparationTable/OffCanvasPlansComparationTable.styles';

export const staticFunctionalities = [
  {
    iconName: (
      <MaterialIcons.PermContactCalendar data-testid="online-scheduling" />
    ),
    slug: 'online-scheduling',
  },
  {
    iconName: <MaterialIcons.GroupAdd data-testid="cadastro-de-pacientes" />,
    slug: 'cadastro-de-pacientes',
  },
  {
    iconName: (
      <MaterialIcons.PersonPinRounded data-testid="prontuario-eletronico" />
    ),
    slug: 'prontuario-eletronico',
  },
  {
    iconName: <MaterialIcons.PlaylistAddCheck data-testid="faturamento-tiss" />,
    slug: 'faturamento-tiss',
  },
  {
    iconName: <MaterialIcons.TrendingUp data-testid="reports" />,
    slug: 'reports',
  },
  {
    iconName: <MaterialIcons.TrendingUp data-testid="starter-sms" />,
    slug: 'starter-sms',
  },
  {
    iconName: <MaterialIcons.ChatRounded data-testid="other-plans-sms" />,
    slug: 'other-plans-sms',
  },
  {
    iconName: (
      <MaterialIcons.VibrationRounded data-testid="100-sms-gratuitos-mes" />
    ),
    slug: '100-sms-gratuitos-mes',
  },
  {
    iconName: <MaterialIcons.Forum data-testid="sms-response" />,
    slug: 'sms-response',
  },
  {
    iconName: <MaterialIcons.HeadsetMicRounded data-testid="chat" />,
    slug: 'chat',
  },
  {
    iconName: (
      <MaterialIcons.WhatsApp data-testid="whatsapp-appointment-reminder" />
    ),
    slug: 'whatsapp-appointment-reminder',
  },
  {
    iconName: (
      <MaterialIcons.ThumbUp data-testid="whatsapp-appointment-confirmation" />
    ),
    slug: 'whatsapp-appointment-confirmation',
  },
  {
    iconName: <MaterialIcons.Edit data-testid="assinatura-digital" />,
    slug: 'assinatura-digital',
  },
  {
    iconName: <MaterialIcons.BrandingWatermarkRounded data-testid="logo" />,
    slug: 'logo',
  },
  {
    iconName: <MaterialIcons.CardGiftcard data-testid="birthdays" />,
    slug: 'birthdays',
  },
  {
    iconName: <MaterialIcons.MonetizationOn data-testid="finances" />,
    slug: 'finances',
  },
  {
    iconName: <MaterialIcons.CachedRounded data-testid="repasse-financeiro" />,
    slug: 'repasse-financeiro',
  },
  {
    iconName: <MaterialIcons.TrendingUp data-testid="finances-report" />,
    slug: 'finances-report',
  },
  {
    iconName: <MaterialIcons.ShoppingCartRounded data-testid="stock" />,
    slug: 'stock',
  },
  {
    iconName: <MaterialIcons.AlternateEmail data-testid="campanhas" />,
    slug: 'campanhas',
  },
  {
    iconName: (
      <MaterialIcons.Contacts data-testid="teleconsultas-gratuitas-ilimitadas" />
    ),
    slug: 'teleconsultas-gratuitas-ilimitadas',
  },
  {
    iconName: <MaterialIcons.CalendarToday data-testid="schedule" />,
    slug: 'schedule',
  },
  {
    iconName: <MaterialIcons.NotesRounded data-testid="prescriptions" />,
    slug: 'prescriptions',
  },
  {
    iconName: <MaterialIcons.MoodRounded data-testid="survey" />,
    slug: 'survey',
  },
];

export const ignoredFunctionalities = ['starter-sms'];

export const VerificationIcons = {
  checked: <CheckIcon />,
  unchecked: <UncheckIcon />,
};

type IconContent = {
  icon: string;
};

type IconContentBySlug = {
  [key: string]: {
    [key: string]: IconContent;
  };
};

export const exceptionFunctionalities: IconContentBySlug = {
  starter: {
    'other-plans-sms': {
      icon: 'R$0,10 por SMS',
    },
  },
  premium: {},
  plus: {},
  pro: {},
};
