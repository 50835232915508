// External
import { defineAction } from 'redux-define';

// Internal
import {
  ActionCreatorFailure,
  ActionCreatorParams,
  ActionCreatorPayload,
  ActionCreatorParamsAndPayload,
  ActionCreatorSimple,
  ApiErrors,
  Action,
  IActionCreatorFailure,
  IActionParams,
  IActionPayload,
  IActionParamsAndPayload,
} from '@types';

const appTypes = defineAction('@webapp');

// Helpers to avoid repetition and errors
export const createSimpleAction = <CreatedAction extends Action<string>>(
  type: CreatedAction['type'],
): ActionCreatorSimple<CreatedAction> => (): CreatedAction => <const>({ type }) as CreatedAction;

export const createParamsAction = <CreatedAction extends IActionParams<string, object>>(
  type: CreatedAction['type'],
): ActionCreatorParams<CreatedAction> => (params: CreatedAction['params']): CreatedAction => <const>({
  type,
  params,
}) as CreatedAction;

export const createFailureAction = <CreatedAction extends IActionCreatorFailure<string>>(
  type: CreatedAction['type'],
): ActionCreatorFailure<CreatedAction> => (errors: ApiErrors[]): CreatedAction => <const>({
  type,
  payload: { errors },
}) as CreatedAction;

export const createPayloadAction = <CreatedAction extends IActionPayload<string, object>>(
  type: CreatedAction['type'],
): ActionCreatorPayload<CreatedAction> => (payload: CreatedAction['payload']): CreatedAction => <const>({
  type,
  payload,
}) as CreatedAction;

export const createParamsAndPayloadAction = <
CreatedAction extends IActionParamsAndPayload<string, object, object>>(
    type: CreatedAction['type'],
  ): ActionCreatorParamsAndPayload<CreatedAction> => (
    params: CreatedAction['params'],
    payload: CreatedAction['payload'],
  ): CreatedAction => <const>({
    type,
    params,
    payload,
  }) as CreatedAction;

export default appTypes;
