import { all, fork } from 'redux-saga/effects';

import appointmentGuideListSagas from './appointmentGuideList/sagas';
import sadtSagas from './sadt/sagas';
import lotSagas from './lot/sagas';
import expenseSagas from './expense/sagas';
import domainTablesSagas from './domainTables/sagas';
import autocompleteSagas from './autocomplete/sagas';
import appointmentGuideSagas from './appointmentGuide/sagas';

export default function* configSagas() {
  yield all([fork(sadtSagas)]);
  yield all([fork(appointmentGuideListSagas)]);
  yield all([fork(lotSagas)]);
  yield all([fork(expenseSagas)]);
  yield all([fork(autocompleteSagas)]);
  yield all([fork(domainTablesSagas)]);
  yield all([fork(appointmentGuideSagas)]);
}
