import { IStoreState } from 'state/rootReducer';

export const getIsOpen = (state: IStoreState) =>
  state.records.speechToText.isOpen;

export const getTranscript = (state: IStoreState) =>
  state.records.speechToText.transcript;

export const getResumeMarkdown = (state: IStoreState) =>
  state.records.speechToText.resumeMarkdown;

export const getPatientId = (state: IStoreState) =>
  state.records.root.patient.id;

export const getLastRequestId = (state: IStoreState) =>
  state.records.speechToText.lastRequestId;
