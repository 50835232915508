import { Loader } from '@iclinic/design-system';
import React from 'react';

import { LoaderContainer } from './styles';

interface ExpenseLoaderProps {
  status: 'create' | 'edit';
}

const ExpenseLoader: React.FC<ExpenseLoaderProps> = ({ status }) => (
  <LoaderContainer>
    <Loader
      size={30}
      title={`${
        status === 'create' ? 'Salvando' : 'Carregando'
      } informações...`}
    />
  </LoaderContainer>
);

export default ExpenseLoader;
