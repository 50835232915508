import { formatCurrency } from 'shared/utils/strings';
import {
  FetchPhysicianInfosRawData,
  FetchEventInfosRawData,
  FetchBillInfosRawData,
} from 'features/onlinePayment/services/checkout/types';
import { Physician, Clinic, Event, Bill } from './types';
import {
  composePhysicianName,
  getFirstPhoneNumber,
  formatAddress,
  formatEventDay,
  formatEventTime,
} from './utils';

type NormalizeFetchPhysicianInfosResult = {
  physician: Physician;
};
export const normalizeFetchPhysicianInfos = (
  data: FetchPhysicianInfosRawData,
): NormalizeFetchPhysicianInfosResult => {
  return {
    physician: {
      name: composePhysicianName(data.name, data.title),
      email: data.email,
      gender: data.gender,
      photo: data.photo,
      speciality: data.speciality,
    },
  };
};

type NormalizeFetchEventInfosResult = {
  event: Event;
  clinic: Clinic;
};
export const normalizeFetchEventInfos = (
  data: FetchEventInfosRawData,
): NormalizeFetchEventInfosResult => {
  const event = {
    patient: {
      name: data.patient.name,
    },
    day: formatEventDay(data.date),
    time: formatEventTime(data.start_time),
    status: data.status,
  };

  const clinic = {
    name: data.clinic.name,
    address: formatAddress(
      data.clinic.street,
      data.clinic.street_number,
      data.clinic.complement,
    ),
    neighborhood: data.clinic.neighborhood,
    cityState: data.clinic.city
      ? `${data.clinic.city} - ${data.clinic.state}`
      : null,
    zipCode: data.clinic.cep,
    phoneNumber: getFirstPhoneNumber(data.clinic.phone_numbers),
  };

  return { event, clinic };
};

export const normalizeFetchBillInfos = (data: FetchBillInfosRawData): Bill => {
  const formatCurrencyInstallments = data.installments.map(
    ({ value, installments }) => ({
      value: formatCurrency(value),
      installments,
    }),
  );

  return {
    id: data.id,
    status: data.status,
    originalTotal: data.amount_charged,
    total: formatCurrency(data.amount_charged),
    installments: formatCurrencyInstallments,
    useIclinicPay: data.use_iclinic_pay,
    seller: {
      sellerId: data.seller.id,
      sellerIdFinancialServices: data.seller.financial_services_id,
      organizationId: data.seller.organization_id,
      sellerStatus: data.seller.status,
    },
  };
};
