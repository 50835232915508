/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable sonarjs/no-duplicate-string */

import {
  MaterialUICore,
  MaterialIcons,
  tokens,
  Box as DSBox,
  Body,
  Heading as DSHeading,
  Chip as DSChip,
  Button as DSButton,
  axiomTokens,
} from '@iclinic/design-system';

const { styled, Tab: DSTab, Tabs: DSTabs, Drawer: DSDrawer } = MaterialUICore;
const { ChevronRightRounded: DSChevronRightRoundedIcon } = MaterialIcons;

const { color, spacing, font, line, border } = tokens;

export const Drawer = styled(DSDrawer)(() => ({
  '& .MuiDrawer-paper': {
    maxWidth: '360px',
    minWidth: '360px',
  },
}));

export const DrawerHeader = styled('header')(() => ({
  borderBottom: `1px solid ${color.neutral[50]}`,
  marginBottom: spacing.xs,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: spacing.sm,
}));

export const Tabs = styled(DSTabs)(() => ({
  width: '100%',
}));

export const Tab = styled(DSTab)(() => ({
  fontSize: font.size.xxs,
  minWidth: 'auto',
  flex: '1',
}));

export const NotificationWrapper = styled(DSBox)(({ read }: any) => ({
  backgroundColor: read ? color.neutral[0] : color.neutral[25],
  padding: spacing.sm,
}));

export const TargetScroll = styled('div')(() => ({
  textIndent: '-9999px',
}));

export const Footer = styled('div')(() => ({
  borderTop: `1px solid ${color.neutral[50]}`,
  marginTop: spacing.xs,
  padding: spacing.sm,
}));

export const IconWrapper = styled('div')(() => ({
  '& svg': {
    color: color.neutral[500],
  },
}));

export const IconWrapperLighter = styled('div')(() => ({
  color: color.neutral[300],
  marginRight: spacing.base,
  '& svg': {
    color: 'currentColor',
  },
}));

export const Title = styled(Body)(() => ({
  color: color.neutral[500],
}));

export const Text = styled(Body)(({ color: selectedColor, mr }: any) => ({
  marginRight: mr,
  color: selectedColor || color.neutral[300],
  lineHeight: line.height.md,
}));

export const Price = styled(Body)(() => ({
  color: color.feedback.success.main,
}));

export const HeadingPrice = styled(DSHeading)(() => ({
  merginRight: 'auto',
  color: color.neutral[500],
}));

export const AntecipateLink = styled(DSButton)(() => ({
  width: '100%',
  height: line.height.lg,
  backgroundColor: color.feedback.success.main,

  '&:hover, &:focus': {
    color: color.neutral[0],
    backgroundColor: color.feedback.success.dark,
  },
}));

export const OverviewContainer = styled('div')(() => ({
  width: '100%',
  padding: spacing.sm,
  borderBottom: `1px solid ${color.neutral[50]}`,
}));

export const OverviewTabHeader = styled(DSButton)(() => ({
  width: '100%',

  '& .MuiButton-label': {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const OverviewContent = styled('div')(({ opened }: any) => ({
  maxHeight: opened ? '300px' : '0',
  marginTop: opened ? spacing.xs : '0',
  overflow: 'hidden',
  transition: 'all 0.3s ease-in-out',
}));

export const OverviewToggleIcon = styled(DSChevronRightRoundedIcon)(
  ({ opened }: any) => ({
    color: color.neutral[500],
    transform: opened ? 'rotate(-90deg)' : 'rotate(90deg)',
    transition: 'transform 0.3s ease-in-out',
  }),
);

export const OverviewItemValue = styled(DSHeading)(() => ({
  marginLeft: 'auto',
}));

export const Chip = styled(DSChip)(() => ({
  width: '100%',
  height: line.height.md,

  '& .MuiChip-label': {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
}));

export const NavIconWrapper = styled('div')(() => ({
  cursor: 'pointer',
  display: 'inline-block',
  height: '22px',
  position: 'relative',
  verticalAlign: 'middle',

  '& svg': {
    color: axiomTokens.color.icon.default,
  },
}));

export const Counter = styled('div')(() => ({
  background: color.feedback.error.main,
  borderRadius: border.radius.md,
  position: 'absolute',
  padding: '1px 3px',
  right: '-4px',
  top: '-4px',

  '& p': {
    fontSize: '10px',
    lineHeight: '11px',
  },
}));

export const LinkButton = styled(DSButton)(() => ({
  '&:focus': {
    outline: 'none',
    textDecoration: 'none',
    color: tokens.color.neutral[0],
  },

  '&:hover': {
    color: tokens.color.neutral[0],
  },
}));

export const LinkButtonSecondary = styled(LinkButton)(() => ({
  marginTop: spacing.base,

  '&:focus': {
    color: tokens.color.primary.dark,
  },

  '&:hover': {
    color: tokens.color.primary.dark,
  },
}));

export const CalendarBox = styled(DSBox)(({ opened }: any) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: tokens.spacing.nano,
  marginTop: tokens.spacing.base,
  maxHeight: opened ? '64px' : '0',
  overflow: 'hidden',
  transition: 'all 0.3s ease-in-out',
}));
