import { useEffect, useState, useCallback } from 'react';

import { useConference } from 'features/video-conference/state/conferenceContext';

export const useSound = (audioUrl: string) => {
  const { onError } = useConference();
  const [playing, setPlaying] = useState(false);
  const [audio, setAudio] = useState<HTMLAudioElement>();

  const play = useCallback(() => {
    audio!
      .play()
      .then(() => setPlaying(true))
      .catch(onError);
  }, [audio, onError]);

  const pause = useCallback(() => {
    audio!.pause();
    setPlaying(false);
  }, [audio]);

  useEffect(() => {
    const media = new Audio(audioUrl);
    setAudio(media);

    media.addEventListener('ended', () => setPlaying(false));

    return () => {
      setPlaying(false);
      media.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audioUrl]);

  return { audio, setAudio, play, pause, playing };
};
