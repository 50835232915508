import { MaterialUICore, tokens, Body } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { spacing, font, line, color } = tokens;

export const Container = styled('div')(() => ({
  padding: `${spacing.sm} 0`,
}));

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: spacing.md,
  width: '100%',
});

export const TextMessage = styled(Body)({
  fontSize: font.size.xxs,
  lineHeight: line.height.md,
  color: color.neutral[500],
});

export const GridListContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(1, 288px)',
  gridAutoColumns: 288,
  gap: spacing.md,
  justifyContent: 'center',

  [theme.breakpoints.up(400)]: {
    gridTemplateColumns: 'repeat(1, 320px)',
    gridAutoColumns: 320,
  },

  [theme.breakpoints.up(744)]: {
    gridTemplateColumns: 'repeat(2, 320px)',
  },

  [theme.breakpoints.up(1104)]: {
    gridTemplateColumns: 'repeat(3, 320px)',
  },

  [theme.breakpoints.up(1448)]: {
    gridTemplateColumns: 'repeat(4, 320px)',
  },

  [theme.breakpoints.up(1792)]: {
    gridTemplateColumns: 'repeat(5, 320px)',
  },

  [theme.breakpoints.up(2136)]: {
    gridTemplateColumns: 'repeat(6, 320px)',
  },
}));
