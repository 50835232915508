// External
import { connect } from 'react-redux';

// Internal
import { searchNameDropdownProcedure } from 'state/campaign/constants';
import * as selectors from 'state/campaign/selectors';
import FilterTags from '../components/audiences/FilterTags';

const title = 'Pacientes atendidos com determinado procedimento';

const mapStateToProps = (state) => {
  const procedures = selectors.getAllProcedures(state).map;
  const renderItemLabel = (procedure) => (procedures[procedure] || `desconhecido (${procedure})`);
  return {
    availableItems: selectors.getAvailableFilterProcedure(state),
    title,
    renderItemLabel,
    searchName: searchNameDropdownProcedure,
  };
};

export default connect(mapStateToProps)(FilterTags);
