import React from 'react';

import { MaterialIcons, MaterialUICore } from '@iclinic/design-system';
import {
  MediaMessageContainer,
  MessageBubble,
  MediaInfo,
  MediaType,
} from './styles';
import { Media } from 'features/video-conference/constants/types';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { setTelemedicineStatus } from 'features/video-conference/utils';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';
import { useParticipantContext } from 'features/video-conference/state/participantContext';

const { IconButton } = MaterialUICore;
const { GetApp } = MaterialIcons;

interface MediaMessageProps {
  media: Media;
  isLocalParticipant: boolean;
}

export default function MediaMessage({
  media,
  isLocalParticipant,
}: MediaMessageProps) {
  const { isPhysician, conferenceId } = useConference();
  const { localParticipant, remoteParticipant } = useParticipantContext();
  const extension = media.filename.split('.').pop();

  if (isPhysician) setTelemedicineStatus('fileUploaded');

  const formatFilename = (filename: string) => {
    if (filename.length < 27) return filename;
    const filenameCutted = filename.substring(0, 27);
    return `${filenameCutted}... .${extension}`;
  };

  const handleClick = async () => {
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'download_telemedicine_file',
    });

    const blob = await fetch(media.url)
      .then((response) => response.blob())
      .then((newBlob) => newBlob);

    const anchorEl = document.createElement('a');
    anchorEl.href = URL.createObjectURL(blob);
    anchorEl.target = '_blank';
    anchorEl.rel = 'noopener';
    anchorEl.download = media.filename;

    // setTimeout is needed in order to open files in iOS Safari.
    setTimeout(() => {
      anchorEl.click();
    });
  };

  return (
    <MediaMessageContainer>
      <MessageBubble
        isLocalParticipant={isLocalParticipant}
        data-testid="messageBubble"
      >
        <MediaType>
          <p>{extension}</p>
        </MediaType>
        <MediaInfo variant="xs">{formatFilename(media.filename)}</MediaInfo>
        <IconButton
          color="primary"
          title={`Baixar "${media.filename}"`}
          onClick={handleClick}
        >
          <GetApp />
        </IconButton>
      </MessageBubble>
    </MediaMessageContainer>
  );
}
