import React from 'react';
import { Participant } from 'twilio-video';

import Publication from './Publication';
import usePublications from 'features/video-conference/hooks/usePublication';

interface ParticipantTracksProps {
  participant: Participant;
  enableScreenShare?: boolean;
  videoOnly?: boolean;
  isLocalParticipant?: boolean;
}

export default function ParticipantTracks({
  participant,
  enableScreenShare,
  videoOnly,
  isLocalParticipant,
}: ParticipantTracksProps) {
  const publications = usePublications(participant);

  let filteredPublications;

  if (
    enableScreenShare &&
    publications.some((p) => p.trackName.includes('screen'))
  ) {
    filteredPublications = publications.filter(
      (p) => p.trackName.includes('screen') || p.kind !== 'video',
    );
  } else {
    filteredPublications = publications.filter(
      (p) => !p.trackName.includes('screen'),
    );
  }

  return (
    <>
      {filteredPublications.map((publication) => (
        <Publication
          key={publication.kind}
          publication={publication}
          videoOnly={videoOnly}
          isLocalParticipant={isLocalParticipant}
        />
      ))}
    </>
  );
}
