import {
  MaterialUICore,
  tokens,
  Heading,
  Button,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, line, font, spacing } = tokens;

export const Root = styled('div')(({ theme }) => ({
  maxWidth: '1226px',
  margin: '0 auto',
  display: 'grid',
  gridTemplateAreas: `
    "toggle-heading"
    "toggle-container"
    "form-container"
    "summary"
  `,
  alignItems: 'center',
  padding: `0 ${spacing.sm}`,

  [theme.breakpoints.up('sm')]: {
    justifyContent: 'space-between',
    gridTemplateColumns: 'minmax(auto, 492px) 440px',
    columnGap: spacing.sm,
    gridTemplateAreas: `
      "toggle-heading toggle-heading"
      "toggle-container toggle-container"
      "form-container summary"
    `,
    marginBottom: spacing.md,
  },
}));

export const ToggleHeading = styled(Heading)(({ theme }) => ({
  gridArea: 'toggle-heading',
  lineHeight: line.height.lg,
  color: color.neutral[900],
  marginBottom: tokens.spacing.sm,

  [theme.breakpoints.up('sm')]: {
    marginBottom: tokens.spacing.xl,
  },
}));

export const ToggleContainer = styled('div')(({ theme }) => ({
  gridArea: 'toggle-container',
  marginBottom: spacing.sm,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  columnGap: 0,

  [theme.breakpoints.up('sm')]: {
    marginBottom: spacing.md,
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: spacing.sm,
  },
}));

export const ComparationButton = styled(Button)(({ theme }) => ({
  fontWeight: 500,
  fontSize: font.size.xxs,
  lineHeight: line.height.sm,
  letterSpacing: '0.3px',
  color: color.primary.main,
  display: 'none',
  '& .MuiButton-startIcon': {
    display: 'inline',
    verticalAlign: 'middle',
  },
  '& .MuiSvgIcon-root': {
    transition: 'width ease-in-out .2s',
    width: 0,
    position: 'relative',
    right: 0,
    left: 0,
  },
  '& .MuiSvgIcon-root.visible': {
    width: 20,
  },
  [theme.breakpoints.up('sm')]: {
    display: 'block',
  },
}));

export const FormContainer = styled('div')({
  gridArea: 'form-container',
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.sm,
});

export const SummaryWrapper = styled('div')(({ theme }) => ({
  display: 'none',
  gridArea: 'summary',
  alignSelf: 'start',
  flexDirection: 'column',
  backgroundColor: color.neutral[25],
  border: `${border.width.hairline} solid ${color.neutral[100]}`,
  borderRadius: border.radius.md,
  padding: spacing.sm,
  rowGap: spacing.sm,

  [theme.breakpoints.up('sm')]: {
    display: 'flex',
  },
}));
