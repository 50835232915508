import { DefaultDraftBlockRenderMap } from 'draft-js';
import Immutable from 'immutable';

const blockRender = DefaultDraftBlockRenderMap.merge(Immutable.Map({
  center: {
    element: 'center',
  },
}));

export default blockRender;
