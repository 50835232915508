/* eslint-disable sonarjs/no-duplicate-string */
import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    maxWidth: '520px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px',
  },
  bodyText: {
    paddingTop: '8px',
    color: theme.palette.grey[500],
  },
  drawerPaper: {
    boxShadow: '-8px 0px 40px rgba(0, 0, 0, 0.12)',
  },
  mainContent: {
    margin: '0px 24px',
    height: '100%',
    '& .MuiTypography-body1': {
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '0.44px',
    },
    '& .MuiTypography-body2': {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.44px',
    },
    '& .MuiTypography-h3': {
      fontSize: '27px',
      fontWeight: 'bold',
      lineHeight: '34px',
    },
  },
  backButton: {
    margin: '8px 24px',
    width: '94px',
    color: theme.palette.text.secondary,
    '& .MuiTypography-overline': {
      fontSize: '16px',
      lineHeight: '40px',
      letterSpacing: '0.44px',
      textTransform: 'none',
    },
    '& .MuiButton-startIcon': {
      color: theme.palette.grey[500],
    },
  },
  footer: {
    height: '81px',
    justifyContent: 'space-between',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  footerSingle: {
    height: '81px',
    justifyContent: 'space-between',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  buttonProgress: {
    color: theme.palette.grey[300],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  footerButton: {
    height: '48px',
    margin: '16px 32px 16px 16px',
    justifyContent: 'flex-end',
    fontSize: '16px',
    letterSpacing: '0.3px',
    lineHeight: '24px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '16px',
      justifyContent: 'center',
    },
    backgroundColor: theme.palette.primary.main,
  },
  switchControl: {
    paddingTop: '38px',
  },
  permission: {
    '& .MuiTypography-body1': {
      fontSize: '21px',
      fontWeight: 'bold',
      color: theme.palette.text.secondary,
      marginBottom: '8px',
      marginTop: '32px',
    },
    '& .MuiTypography-subtitle1': {
      fontSize: '14px',
      lineHeight: '16px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      color: theme.palette.text.primary,
      marginBottom: '8px',
    },
    '& .Mui-checked': {
      color: theme.palette.primary.main,
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  snackbar: {
    marginTop: '16px',
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.grey[600],
  },
  displayMobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  hideMobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  icon: {
    color: theme.palette.warning.main,
    fontSize: '20px',
    marginRight: '10px',
  },
  snackbarError: {
    marginTop: '40px',
    marginBottom: '8px',
    backgroundColor: theme.palette.warning.main,
    '& .MuiSvgIcon-root': {
      color: theme.palette.warning.main,
    },
  },
  successImage: {
    width: '208px',
    margin: '0 auto',
  },
  title: {
    marginTop: '32px',
  },
  label: {
    margin: '0',
  },
  listChips: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-6px',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  closeButton: {
    color: theme.palette.grey[300],
    margin: '16px',
    maxWidth: '158px',
    '& .MuiTypography-body1': {
      fontSize: '14px',
      lineHeight: '32px',
      letterSpacing: '0.44px',
    },
  },
  actionError: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentModal: {
    display: 'flex',
    margin: '20px 24px',
    maxWidth: '381px',
  },
  footerModal: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
