import * as Yup from 'yup';

import { onlyNumbers } from 'shared/utils/formatters';
import { isValidCPF } from 'shared/utils/validation';

const maxCharactersError = 'Máximo de {max} caracteres';
const incompleteFullNameError = 'Nome completo inválido';
const invalidCpfError = 'CPF inválido';
const requiredFieldError = 'Campo obrigatório';

export const initialValues = {
  kinshipDegree: '',
  fullName: '',
  kinshipCPF: '',
};

export const validationSchema = Yup.object().shape({
  kinshipDegree: Yup.string()
    .required(requiredFieldError)
    .test('isValidKinship', requiredFieldError, (value) => value && !!value.trim()),
  fullName: Yup.string()
    .required(requiredFieldError)
    .test('isFullName', incompleteFullNameError, (value) => value && value.trim().split(' ').length >= 2),
  kinshipCPF: Yup.string()
    .required(requiredFieldError)
    .max(14, maxCharactersError)
    .test('isValidCPF', invalidCpfError, (value) => value && isValidCPF(onlyNumbers(value))),
});
