export const getInitials = (userName: string) => {
  const splitedString = userName.split(' ');
  const [nameInitial, lastnameInitial] = [
    splitedString[0],
    splitedString[splitedString.length - 1],
  ];
  return `${nameInitial[0]}${lastnameInitial[0]}`;
};

export const percentageUtils = {
  format(seedValue: number) {
    return `${seedValue}%`;
  },
  unformat(seedValue: string) {
    return +seedValue.replace(/%/, '');
  },
  calculateTotal(totalValue: number, percentage: string) {
    const percentageDiscount = this.unformat(percentage) / 100;
    const unformattedMoney = totalValue;
    const percentageValue = unformattedMoney * percentageDiscount;
    const newSubTotal = unformattedMoney - percentageValue;
    return [percentageValue, newSubTotal];
  },
};
