import { useEffect } from 'react';
import { FormikErrors, FormikTouched } from 'formik';

import { trackRequestSamplesFormStep } from '../trackRequestSamplesFormUtils';
import { RequestSamplesFormData } from '../containers/RequestSamplesForm/types';
import { UserInfo } from '../state/request-samples/types';

export const useAddressFormErrorTracking = (
  errors: FormikErrors<RequestSamplesFormData>,
  touched: FormikTouched<RequestSamplesFormData>,
  userInfo: UserInfo,
) => {
  useEffect(() => {
    if (errors.zipCode && touched.zipCode) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'user_address_postal_code',
        stepNum: 6,
        failureReason: errors.zipCode,
      });
    }
  }, [errors.zipCode, touched.zipCode, userInfo]);

  useEffect(() => {
    if (errors.address && touched.address) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'user_address_name',
        stepNum: 7,
        failureReason: errors.address,
      });
    }
  }, [errors.address, touched.address, userInfo]);

  useEffect(() => {
    if (errors.number && touched.number) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'user_address_number',
        stepNum: 8,
        failureReason: errors.number,
      });
    }
  }, [errors.number, touched.number, userInfo]);

  useEffect(() => {
    if (errors.complement && touched.complement) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'user_address_complement',
        stepNum: 9,
        failureReason: errors.complement,
      });
    }
  }, [errors.complement, touched.complement, userInfo]);

  useEffect(() => {
    if (errors.neighborhood && touched.neighborhood) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'user_address_neighborhood',
        stepNum: 10,
        failureReason: errors.neighborhood,
      });
    }
  }, [errors.neighborhood, touched.neighborhood, userInfo]);

  useEffect(() => {
    if (errors.state && touched.state) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'user_address_state',
        stepNum: 11,
        failureReason: errors.state,
      });
    }
  }, [errors.state, touched.state, userInfo]);

  useEffect(() => {
    if (errors.city && touched.city) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'user_address_city',
        stepNum: 12,
        failureReason: errors.city,
      });
    }
  }, [errors.city, touched.city, userInfo]);

  useEffect(() => {
    if (errors.isClinicAddress && touched.isClinicAddress) {
      trackRequestSamplesFormStep({
        userInfo,
        stepName: 'user_address_validation',
        stepNum: 13,
        failureReason: errors.isClinicAddress,
      });
    }
  }, [errors.isClinicAddress, touched.isClinicAddress, userInfo]);
};
