import { Box, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const BannerWrapper = styled(Box)(() => ({
  padding: `${tokens.spacing.sm} 20px`,
  display: 'flex',
  justifyContent: 'center',
}));

export const DesktopImage = styled('img')(({ theme }) => ({
  width: 1024,
  maxWidth: '100%',

  [theme.breakpoints.down(768)]: {
    display: 'none',
  },
}));

export const MobileImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  display: 'none',

  [theme.breakpoints.down(768)]: {
    display: 'initial',
  },
}));
