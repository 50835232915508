import Root from './Benefit';
import Item from '../selectableList/Item';

Root.Item = Item;

export default Root;

export const Benefit = {
  Root,
  Item,
};
