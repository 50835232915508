// External
import React, { useMemo, useCallback } from 'react';

// Internal
import Table from 'ui/new/table';
import { Switch } from 'ui/new/form';
import { ActionButton, ActionButtonGroup } from 'ui/new/action-buttons';
import Button from 'ui/new/button';
import { EmailFlow, EmailFlowTarget } from 'state/marketing/emailFlow/emailFlowManagement/emailFlowTypes';
import { Align } from 'state/marketing/emailFlow/emailFlowManagement/constants';
import { SIZE } from 'ui/new/button/constants';

import style from './ListEmailFlow.scss';
import { FlowType } from '../constants';

export interface DeleteModalParams {
  id: string;
  name: string;
}
export interface DuplicateParams extends DeleteModalParams {}

type ListEmailFlowItemProps = {
  emailFlow: EmailFlow;
  changeStatusEmailFlow: (e: EmailFlowTarget) => void;
  editEmailFlow: (emailFlow: EmailFlow) => void;
  duplicateEmailFlow?: ({ id, name }: DuplicateParams) => void;
  deleteEmailFlow?: ({ id, name }: DeleteModalParams) => void;
};

const ListEmailFlowItem = ({
  emailFlow,
  changeStatusEmailFlow,
  editEmailFlow,
  duplicateEmailFlow,
  deleteEmailFlow,
}: ListEmailFlowItemProps): JSX.Element => {
  const renderActionButton = useMemo(() => {
    if (duplicateEmailFlow || deleteEmailFlow) {
      return (
        <ActionButtonGroup
          target={emailFlow}
          onEdit={editEmailFlow}
          onDuplicate={duplicateEmailFlow}
          onDelete={deleteEmailFlow}
        />
      );
    }

    return (
      <ActionButton
        target={emailFlow}
        onClick={editEmailFlow}
        icon="edit"
        altText="Editar"
        size={SIZE.ActionButton}
      />
    );
  }, [duplicateEmailFlow, deleteEmailFlow, editEmailFlow, emailFlow]);

  const onClickEmailFlowName = useCallback(() => {
    if (editEmailFlow) {
      editEmailFlow(emailFlow);
    }
  }, [emailFlow, editEmailFlow]);

  return (
    <Table.Row>
      <Table.Column align={Align.left} classes={style.column__title}>
        <Switch
          id={emailFlow.id}
          checked={emailFlow.active}
          value={emailFlow.id}
          onChange={changeStatusEmailFlow}
          classes={style.columnSwitch}
        />
        <div className={style[`emailFlowTitleLink${!emailFlow.active ? '--disabled' : '--active'}`]}>
          <Button type="link" onClick={onClickEmailFlowName}>{emailFlow.flowType === FlowType.return ? `Retorno ${emailFlow.name}` : emailFlow.name}</Button>
        </div>
      </Table.Column>
      <Table.Column align={Align.right}>
        {emailFlow.stats ? emailFlow.stats.activePatients : '-'}
      </Table.Column>
      <Table.Column align={Align.right}>
        {emailFlow.stats ? emailFlow.stats.finishedPatients : '-'}
      </Table.Column>
      <Table.Column align={Align.right} classes={style.actionGroup}>
        {renderActionButton}
      </Table.Column>
    </Table.Row>
  );
};

export default ListEmailFlowItem;
