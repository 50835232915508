import moment from 'moment';

import {
  SellerBankAccountForm,
  SellerForm,
} from 'features/onlinePayment/state/config/types';
import {
  PhysicianRawData,
  SellerBankAccountRawData,
  SellerRawData,
} from 'features/onlinePayment/services/config/types';
import { cepFormatter } from 'shared/utils/formatters';
import { SellerType } from '../constants';

type ProfileFormInitialDataParams = {
  seller: SellerRawData | null | undefined;
  sellerForm: SellerForm | undefined;
  physician: PhysicianRawData | undefined;
  physicianId: number | undefined;
  sellerType?: SellerType;
};

/** TODO fix this */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getProfileFormInitialData({
  seller,
  sellerForm,
  physician,
  physicianId,
  sellerType,
}: ProfileFormInitialDataParams) {
  if (sellerForm) return sellerForm;
  const { cbo, council_name, council_number, council_region, profile } =
    physician || {};
  const { thumb, office_phone } = profile || {};

  const birthdate = seller?.birthdate
    ? moment(seller?.birthdate).toDate()
    : null;

  const {
    zip_code = '',
    street = '',
    number = '',
    neighborhood = '',
    city = '',
    state = '',
    complement = '',
  } = seller?.address || {};

  const type = seller?.type || sellerType || SellerType.PF;
  const name = seller?.name || profile?.name || '';
  const councilName = seller?.council_name || council_name || '';
  const councilNumber = seller?.council_number || council_number || '';
  const councilRegion = seller?.council_region || council_region || '';
  const email = seller?.email || profile?.email || '';
  const phone = seller?.phone || office_phone || '';
  const cpf = seller?.cpf || profile?.cpf || '';
  const businessName = seller?.business_name || '';
  const businessDescription = seller?.business_description || '';
  const businessOpeningDate = seller?.business_opening_date
    ? moment(seller?.business_opening_date).toDate()
    : null;
  const businessCnpj = seller?.business_cnpj || '';

  return {
    cbo,
    physicianId,
    type,
    initialPicture: thumb || '',
    picture: '',
    name,
    councilName,
    councilNumber,
    councilRegion,
    email,
    cpf,
    birthdate,
    phone,
    zipCode: cepFormatter(zip_code),
    street,
    number,
    neighborhood,
    city,
    state,
    complement,
    businessName,
    businessDescription,
    businessOpeningDate,
    businessCnpj,
    identity: undefined,
    residence: undefined,
    cnpj: undefined,
  } as SellerForm;
}

export function getBankAccountInitialData(
  data: SellerBankAccountForm | undefined,
  seller: SellerRawData | null | undefined,
  sellerBankAccount?: SellerBankAccountRawData,
) {
  if (data) return data;
  const {
    id,
    max_installments,
    min_installment_value,
    name,
    cpf,
    type,
    business_cnpj,
    business_name,
  } = seller || {};
  const { routing_number, bank_code, account_number, id: bankAccountId } =
    sellerBankAccount || {};

  const minValue = min_installment_value
    ? parseFloat(min_installment_value)
    : 0;
  return {
    type,
    name,
    cpf,
    businessName: business_name,
    businessCnpj: business_cnpj,
    sellerId: id,
    bank: bank_code || '',
    agency: routing_number || '',
    account: account_number || '',
    bankAccountId,
    installments: max_installments || '1',
    minValue,
    terms: false,
  } as SellerBankAccountForm;
}
