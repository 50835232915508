// External
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

// Internal
import { campaignStatusForDisplay, CampaignStatus } from 'state/campaign/constants';
import Table from 'ui/new/table';
import { ActionButtonGroup } from 'ui/new/action-buttons';
import CheckBox from 'ui/new/form/CheckBox';
import Button from 'ui/new/button';
import style from './ListCampaigns.scss';

const classes = classnames.bind(style);

const ListCampaignsItem = ({
  campaign,
  deleteCampaign,
  onChangeCheckBox,
  isSelected,
  editCampaign,
  duplicateCampaign,
}) => {
  const onClickTitleCampaign = React.useCallback(() => {
    if (editCampaign) {
      editCampaign(campaign);
    }
  }, [campaign, editCampaign]);

  return (
    <Table.Row style={style.campaigns__items}>
      <Table.Column align="left">
        <CheckBox selected={isSelected} id={`check-campaign-${campaign.id}`} name={campaign.id} value={campaign.id} onChange={onChangeCheckBox} />
      </Table.Column>
      <Table.Column align="left">
        <div className={style.campaignTitleLink}><Button type="link" onClick={onClickTitleCampaign}>{campaign.title}</Button></div>
      </Table.Column>
      <Table.Column align="left">
        <ActionButtonGroup
          target={campaign}
          onDelete={deleteCampaign}
          onEdit={campaign.status !== CampaignStatus.sent ? editCampaign : null}
          onDuplicate={duplicateCampaign}
        />
      </Table.Column>
      <Table.Column align="left">
        <span className={classes(`campaignTableItem__status--${campaign.status}`)}>{campaignStatusForDisplay[campaign.status]}</span>
      </Table.Column>
      <Table.Column align="right">
        {campaign.sent}
      </Table.Column>
      <Table.Column align="right">
        {campaign.opened}
      </Table.Column>
      <Table.Column align="right">
        <span className={style.tableClicked}>{campaign.clicked}</span>
      </Table.Column>
    </Table.Row>
  );
};

ListCampaignsItem.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    sent: PropTypes.string.isRequired,
    opened: PropTypes.string.isRequired,
    clicked: PropTypes.string.isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  deleteCampaign: PropTypes.func.isRequired,
  onChangeCheckBox: PropTypes.func.isRequired,
  editCampaign: PropTypes.func.isRequired,
  duplicateCampaign: PropTypes.func.isRequired,
};

export default ListCampaignsItem;
