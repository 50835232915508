import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useConference } from 'features/video-conference/state/conferenceContext';
import {
  Message,
  FileUploaded,
} from 'features/video-conference/constants/types';
import { parseDate } from 'features/video-conference/utils';

interface ChatProviderProps {
  children: React.ReactNode;
}

type ChatContextType = {
  isChatWindowOpen: boolean;
  filesUploaded: FileUploaded[];
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  hasUnreadMessages: boolean;
  messages: Message[];
  setMessages: (messages: Message[]) => void;
  setFilesUploaded: (files: FileUploaded[]) => void;
};

export const ChatContext = createContext<ChatContextType>(null!);

export const ChatProvider = ({ children }: ChatProviderProps) => {
  const { room } = useConference();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [filesUploaded, setFilesUploaded] = useState<FileUploaded[]>([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);

  useEffect(() => {
    if (!room) return;

    const storageMessages: Message[] = JSON.parse(
      localStorage.getItem(`telemedicine-chat-${room.name}`) || '[]',
      parseDate,
    );
    if (storageMessages.length > 0) setMessages(storageMessages);
  }, [room]);

  useEffect(() => {
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) setHasUnreadMessages(false);
  }, [isChatWindowOpen]);

  const contextValue = useMemo(
    () => ({
      isChatWindowOpen,
      setIsChatWindowOpen,
      hasUnreadMessages,
      messages,
      setMessages,
      filesUploaded,
      setFilesUploaded,
    }),
    [filesUploaded, hasUnreadMessages, isChatWindowOpen, messages],
  );

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};

export function useChatContext(): ChatContextType {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChatContext must be used within an ChatProvider');
  }
  return context;
}
