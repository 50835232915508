import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@iclinic/design-system';

import {
  composeTitleNotification,
  normalizeTransactionOrigin,
} from 'features/onlinePayment/utils';
import { CreditCardAnticipationApprovedNotification } from 'features/onlinePayment/state/notifications-center/types';
import { getUnreadNotifications } from 'features/onlinePayment/state/notifications-center/selectors';
import { currencyFormatter } from 'shared/utils/formatters';
import NotificationIcon from '../NotificationIcon';
import * as S from '../styles';

type CreditCardAnticipationApprovedProps = {
  notification: CreditCardAnticipationApprovedNotification;
};

const CreditCardAnticipationApproved = ({
  notification,
}: CreditCardAnticipationApprovedProps): JSX.Element => {
  const unreadNotifications = useSelector(getUnreadNotifications);
  const {
    id,
    read,
    messageType,
    createdAt,
    data: { antecipation_gross_value },
  } = notification;

  return (
    <S.NotificationWrapper
      display="flex"
      justifyContent="space-between"
      pb={2}
      key={id}
      read={read || !unreadNotifications}
    >
      <Box display="flex">
        <S.IconWrapper>
          <NotificationIcon notificationType={messageType} />
        </S.IconWrapper>
        <Box ml={1}>
          <S.Title variant="xxs" bold>
            {composeTitleNotification(messageType)}
          </S.Title>
          <S.Text variant="xxs">{createdAt}</S.Text>
          <S.Text variant="xxs">{normalizeTransactionOrigin(origin)}</S.Text>
        </Box>
      </Box>
      <Box>
        <S.Price variant="xxs" bold>
          {currencyFormatter(antecipation_gross_value)}
        </S.Price>
      </Box>
    </S.NotificationWrapper>
  );
};

export default CreditCardAnticipationApproved;
