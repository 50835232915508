import React from 'react';

import { MaterialIcons } from '@iclinic/design-system';
import * as S from './AppliedFilters.styles';

const { Close } = MaterialIcons;

type AppliedFiltersProps = {
  filter: string;
  onRemoveFilter?: () => void;
};

const AppliedFilters = ({ filter, onRemoveFilter }: AppliedFiltersProps) => {
  return (
    <S.Chip label={filter} deleteIcon={<Close />} onDelete={onRemoveFilter} />
  );
};

export default AppliedFilters;
