import { Body, Heading, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { font, line, spacing } = tokens;

export const Root = styled('div')(() => ({
  padding: spacing.md,
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.base,

  '& .MuiFormControlLabel-root': {
    marginLeft: 0,
    marginBottom: 0,
  },
}));

export const ModalTitle = styled(Heading)({
  fontWeight: font.weight.medium,
});

export const ModalDescription = styled(Body)({
  lineHeight: line.height.md,
});

export const ModalActions = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: spacing.base,
}));

export const ActionButtonsWrapper = styled('div')(() => ({
  display: 'flex',
  columnGap: spacing.sm,
}));
