import { call, put, takeLatest } from 'redux-saga/effects';

import { Response } from '@types';
import * as services from '../../services';
import {
  AvailableFilterResponse,
  SampleBoxesResponse,
  SuggestionsResponse,
} from '../../services/types';
import * as actions from '.';

type PayloadFetchSuggestions = ReturnType<typeof actions.fetchSuggestions>;

type PayloadFetchAvailableSampleBoxes = ReturnType<
  typeof actions.fetchAvailableSampleBoxes
>;

type PayloadFetchRequestedSampleBoxes = ReturnType<
  typeof actions.fetchRequestedSampleBoxes
>;

export function* fetchAvailableSampleBoxesWorker({
  payload,
}: PayloadFetchAvailableSampleBoxes) {
  try {
    yield put(actions.fetchAvailableSampleBoxesStart());

    const { data }: Response<SampleBoxesResponse> = yield call(
      services.fetchSampleBoxes,
      payload,
    );

    yield put(actions.fetchAvailableSampleBoxesSuccess(data.objects));
  } catch (error) {
    yield put(actions.fetchAvailableSampleBoxesFailure());
  }
}

export function* fetchRequestedSampleBoxesWorker({
  payload,
}: PayloadFetchRequestedSampleBoxes) {
  try {
    yield put(actions.fetchRequestedSampleBoxesStart());

    const { data }: Response<SampleBoxesResponse> = yield call(
      services.fetchSampleBoxes,
      payload,
    );

    yield put(actions.fetchRequestedSampleBoxesSuccess(data.objects));
  } catch (error) {
    yield put(actions.fetchRequestedSampleBoxesFailure());
  }
}

export function* fetchAvailablePharmaceuticalsWorker() {
  try {
    yield put(actions.fetchAvailablePharmaceuticalsStart());

    const { data }: Response<AvailableFilterResponse> = yield call(
      services.getAvailablePharmaceuticals,
    );

    yield put(actions.fetchAvailablePharmaceuticalsSuccess(data.objects));
  } catch (error) {
    yield put(actions.fetchAvailablePharmaceuticalsFailure());
  }
}

export function* fetchAvailableActivePrinciplesWorker() {
  try {
    yield put(actions.fetchAvailableActivePrinciplesStart());

    const { data }: Response<AvailableFilterResponse> = yield call(
      services.getAvailableActivePrinciples,
    );

    yield put(actions.fetchAvailableActivePrinciplesSuccess(data.objects));
  } catch (error) {
    yield put(actions.fetchAvailableActivePrinciplesFailure());
  }
}

export function* fetchSuggestionsWorker({ payload }: PayloadFetchSuggestions) {
  try {
    yield put(actions.fetchSuggestionsStart());

    const { data }: Response<SuggestionsResponse> = yield call(
      services.getSuggestions,
      payload,
    );

    yield put(actions.fetchSuggestionsSuccess(data.objects));
  } catch (error) {
    yield put(actions.fetchSuggestionsFailure());
  }
}

export default function* sampleBoxesSagas() {
  yield takeLatest(
    actions.fetchAvailableSampleBoxes,
    fetchAvailableSampleBoxesWorker,
  );
  yield takeLatest(
    actions.fetchRequestedSampleBoxes,
    fetchRequestedSampleBoxesWorker,
  );
  yield takeLatest(
    actions.fetchAvailablePharmaceuticals,
    fetchAvailablePharmaceuticalsWorker,
  );
  yield takeLatest(
    actions.fetchAvailableActivePrinciples,
    fetchAvailableActivePrinciplesWorker,
  );
  yield takeLatest(actions.fetchSuggestions, fetchSuggestionsWorker);
}
