// External
import React, { useEffect, useState, useCallback } from 'react';
import Icon from '@material/react-material-icon';
import classnames from 'classnames/bind';

// Internal
import style from './style.scss';
import {
  getNextPage,
  getRangeOfPages,
  getPreviousPage,
  getTotalPage,
} from './utils';

const classes = classnames.bind(style);

type PaginatorProps = {
  totalCount?: number
  itensPerPage?: number
  currentPage?: number
  onChangeSelectedPage: (actualPage: number) => void
};

export default function Paginator({
  totalCount = 0,
  itensPerPage = 20,
  currentPage = 1,
  onChangeSelectedPage,
}: PaginatorProps): JSX.Element {
  const [pagesArray, setPagesArray] = useState([]);
  const [actualPage, setActualPage] = useState(1);

  useEffect(() => {
    const pages = getRangeOfPages(actualPage, totalCount, itensPerPage);
    setPagesArray(pages);
    setActualPage(currentPage);
  }, [actualPage, currentPage, itensPerPage, totalCount]);

  const handleNextPage = useCallback(() => {
    const nextPage = getNextPage(totalCount, itensPerPage, actualPage);
    setActualPage(nextPage);
    onChangeSelectedPage(nextPage);
  }, [actualPage, itensPerPage, onChangeSelectedPage, totalCount]);

  const handlePreviousPage = useCallback(() => {
    const previousPage = getPreviousPage(actualPage);
    setActualPage(previousPage);
    onChangeSelectedPage(previousPage);
  }, [actualPage, onChangeSelectedPage]);

  const handleLastPage = useCallback(() => {
    const totalPages = getTotalPage(totalCount, itensPerPage);
    setActualPage(totalPages);
    onChangeSelectedPage(totalPages);
  }, [itensPerPage, onChangeSelectedPage, totalCount]);

  const handleFirstPage = () => {
    setActualPage(1);
    onChangeSelectedPage(1);
  };

  const handleSpecificPage = (pageNumber: number) => {
    const nextPage = Number(pageNumber);
    const totalPages = getTotalPage(totalCount, itensPerPage);

    if (nextPage > 1 && nextPage < totalPages) {
      setActualPage(nextPage);
      onChangeSelectedPage(nextPage);
    } else if (nextPage >= totalPages) {
      setActualPage(totalPages);
      onChangeSelectedPage(totalPages);
    } else if (nextPage <= 1) {
      setActualPage(1);
      onChangeSelectedPage(1);
    }
  };

  return (
    <div className={style.paginator}>
      <div className={style.paginator__item} role="button" tabIndex={0} onClick={handleFirstPage} onKeyDown={handleFirstPage}>
        <Icon icon="first_page" className={style.paginator__icon} />
      </div>
      <div className={style.paginator__item} role="button" tabIndex={0} onClick={handlePreviousPage} onKeyDown={handlePreviousPage}>
        <Icon icon="chevron_left" className={style.paginator__icon} />
      </div>
      {pagesArray && pagesArray.map(page => (
        <div
          key={page}
          id={page}
          role="button"
          tabIndex={0}
          onClick={({ target: { id } }) => handleSpecificPage(id)}
          onKeyDown={({ target: { id } }) => handleSpecificPage(id)}
          className={classes('paginator__item', { selected: actualPage === page })}
        >
          {page}
        </div>
      ))}
      <div className={style.paginator__item} role="button" tabIndex={0} onClick={handleNextPage} onKeyDown={handleNextPage}>
        <Icon icon="chevron_right" className={style.paginator__icon} />
      </div>
      <div className={style.paginator__item} role="button" tabIndex={0} onClick={handleLastPage} onKeyDown={handleLastPage}>
        <Icon icon="last_page" className={style.paginator__icon} />
      </div>
    </div>
  );
}
