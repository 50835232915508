import axios from 'axios';

import { interceptorRequestAuthentication } from 'services/iclinic/interceptors';

const apiTelemedicine = axios.create({
  baseURL: process.env.ICLINIC_TELEMEDICINE_URL,
  headers: {
    authorization: 'Bearer iclinic',
  },
});

apiTelemedicine.interceptors.request.use(interceptorRequestAuthentication);

export const getVideoTranscription = (
  conferenceId: number,
  physicianId: number,
  page: number,
  itemsPerPage: number = 14,
) =>
  apiTelemedicine.get(
    `conference/transcript?conferenceId=${conferenceId}&physicianId=${physicianId}&page=${page}&itemsPerPage=${itemsPerPage}`,
  );
