// External dependencies
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// Routes
import { ReportsTransactions } from 'features/onlinePayment';

const Root = () => (
  <Switch>
    <Route path="/pagamento" component={ReportsTransactions} />
    <Redirect to="/404/" />
  </Switch>
);

export default Root;
