export enum PLANS {
  Regular = 'regular',
  Fast = 'fast',
}

export const REGULAR_PLAN_FEES = [
  '1,65%',
  '2,10%',
  '2,82%',
  '2,82%',
  '2,82%',
  '2,82%',
  '2,82%',
  '3,27%',
  '3,27%',
  '3,27%',
  '3,27%',
  '3,27%',
  '3,27%',
];

export const FAST_PLAN_FEES = [
  '1,75%',
  '4,57%',
  '6,47%',
  '7,65%',
  '8,84%',
  '10,04%',
  '11,26%',
  '12,80%',
  '14,04%',
  '15,29%',
  '16,55%',
  '17,83%',
  '19,13%',
];
