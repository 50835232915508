import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

import { Typography, Box, MaterialIcons } from '@iclinic/design-system';
import Loader from 'features/onlinePayment/components/manage/Loader';
import {
  fetchBill,
  updateBill,
  toggleUpdatedBill,
  toggleChargeBackModal,
  createBill,
} from 'features/onlinePayment/state/manage/actions';
import { getManageSelector } from 'features/onlinePayment/state/manage/selectors';
import { ActionContentLoader } from 'features/onlinePayment/components/manage/ContentLoaders';
import AmountCharged from 'features/onlinePayment/components/manage/AmountCharged';
import ChargeBackModal from 'features/onlinePayment/components/manage/chargeBack/Modal';
import Actions from 'features/onlinePayment/components/manage/Actions';
import Status from 'features/onlinePayment/components/manage/Status';
import SuccessSnackbar from 'features/onlinePayment/components/manage/SuccessSnackbar';
import { ErrorAlert } from 'shared/components';
import useStyles from './Manage.styles';
import { BillStatus } from 'features/onlinePayment/state/constants';
import { validationSchema } from './validationSchema';
import { BillForm } from 'features/onlinePayment/state/manage/types';

const { MonetizationOn, SettingsBackupRestore } = MaterialIcons;

export default function Manage(): JSX.Element | null {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    isFetchingBill,
    bill,
    checkoutUrl,
    isGenerating,
    updatedBill,
    sellerIsApproved,
    eventId,
    showChargeBackModal,
    amountCharged,
    fetchBillError,
    createBillError,
  } = useSelector(getManageSelector);

  useEffect(() => {
    dispatch(fetchBill({ eventId }));
  }, [dispatch, eventId]);

  // Check with backends if the status is correct when is a chargeBack
  const canceledBill = bill?.status === BillStatus.Canceled;

  const handleSubmit = (form: BillForm) => {
    if (!bill?.status || canceledBill) {
      dispatch(createBill(form));
    } else if (bill?.status === BillStatus.Created) {
      dispatch(updateBill(form));
    }
  };
  if (!sellerIsApproved) {
    return null;
  }

  if (fetchBillError) {
    return (
      <ErrorAlert
        message={<span dangerouslySetInnerHTML={{ __html: fetchBillError }} />}
        mt={3}
      />
    );
  }

  const content = (
    <>
      {createBillError && (
        <ErrorAlert
          message={
            <span dangerouslySetInnerHTML={{ __html: createBillError }} />
          }
          mt={3}
        />
      )}

      <div className={classes.bar}>
        <Box display="flex" alignItems="center">
          <div className={classes.barIcon}>
            <MonetizationOn />
          </div>
          <Typography variant="overline" className={classes.title}>
            Cobrar Agendamento
          </Typography>
        </Box>
        {!!bill && <Status billId={bill.id} status={bill.status} />}
        <Box className={classes.wrapperAction}>
          <AmountCharged loading={isFetchingBill} status={bill?.status} />
          {isGenerating && (
            <Box display="flex" alignItems="center">
              <Loader />
              <Typography
                variant="body2"
                classes={{ body2: classes.generating }}
                bold
              >
                Gerando cobrança
              </Typography>
            </Box>
          )}
          {isFetchingBill && <ActionContentLoader />}
          {!isFetchingBill && !isGenerating && (
            <Actions status={bill?.status} checkoutUrl={checkoutUrl} />
          )}
          {canceledBill && (
            <Box display="flex" alignItems="center">
              <SettingsBackupRestore
                fontSize="small"
                className={classes.chargeBackIcon}
              />
              <Typography variant="body2">Pagamento estornado</Typography>
            </Box>
          )}
          {updatedBill && (
            <SuccessSnackbar
              message="Valor que será cobrado no link foi alterado com sucesso"
              onClose={() =>
                dispatch(toggleUpdatedBill({ updatedBill: false }))
              }
            />
          )}
        </Box>
        <ChargeBackModal
          open={showChargeBackModal}
          onClose={() => dispatch(toggleChargeBackModal())}
        />
      </div>
    </>
  );

  if (isFetchingBill) return content;

  return (
    <Formik
      initialValues={{ amountCharged }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>{content}</Form>
    </Formik>
  );
}
