// External
import React, { useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Dispatch } from 'redux';
import { EditorState } from 'draft-js';
import Icon from '@material/react-material-icon';

// Internal
import NotificationExpireMessage from 'shared/components/NotificationExpireMessage';
import ModalRoot from 'features/modal/containers/ModalRoot';
import { STATUS } from 'ui/new/actionable-card/constants';
import { AppContent } from 'features/layout';
import { StoreState } from 'state/rootReducer';
import * as selectors from 'state/marketing/emailFlow/emailFlowManagement/selectors';
import { open, close } from 'state/modal/actions';
import {
  EmailFlow,
  EmailFlowPhysician,
  EmailFlowActionsReturn,
  Physician,
  EmailFlowComponent,
  EditorOptionsText,
  EditorTextEditor,
  CurrentInfoEditor,
  EmailFlowError,
  UserInfo,
} from 'state/marketing/emailFlow/emailFlowManagement/emailFlowTypes';
import { emailFlowInformation } from 'state/marketing/emailFlow/emailFlowManagement/constants';
import { FORM_EDIT_EMAIL_FLOW_RETURN } from 'shared/constants/forms';
import {
  EDITOR_LINK_MODAL,
  EDITOR_BUTTON_MODAL,
  EDITOR_IMAGE_MODAL,
  CONFIRM_DELETE_COMPONENT_MODAL,
  ACTIVE_EMAIL_FLOW_MODAL,
} from 'features/marketing/modals';
import { SEND_EMAIL_TEST_MODAL } from 'features/campaign/modals';
import {
  cancelEmailFlow,
  fetchDetailsEmailFlow,
  editActiveEmailFlow,
  fetchListPhysicians,
  saveEmailFlow,
  setActiveComponentsCard,
  setCurrentComponent,
  updateTextEditor,
  onModalEditorLinkSubmit,
  onModalEditorButtonSubmit,
  onModalEditorImageSubmit,
  onAutomatedTagChosen,
  fetchDeleteComponent,
  saveEmailFlowComponent,
  fetchDetailsAddEmailFlow,
  fetchDetailsChangeEmailFlow,
  clearErrorsComponent,
  fetchSendTestEmail,
} from 'state/marketing/emailFlow/emailFlowManagement/actions';
import {
  descEmail,
  isValidTextEditor,
} from 'state/marketing/emailFlow/emailFlowManagement/utils';
import useWidthUpdater from 'shared/hooks/useWidthUpdater';
import EmailFlowSender from '../components/EmailFlowSender';
import EditTitleEmailFlow from '../components/EditTitleEmailFlow';
import EditEmailFlowComponents from '../components/EditEmailFlowComponents';
import style from './EditEmailFlowReturn.scss';

interface StateProps {
  emailFlow: EmailFlow;
  match: EmailFlowPhysician;
  senders: Physician[];
  activeCard: string;
  initialEditor: EditorState;
  editorOptionsText: EditorOptionsText[];
  editor: EditorTextEditor;
  currentInfo: CurrentInfoEditor;
  activeEmailFlow: boolean;
  errors: EmailFlowError[];
  userInfo: UserInfo;
}
interface DispatchProps {
  cancelEmailFlowReturn: () => void;
  fetchEmailFlow: (params: EmailFlowPhysician) => void;
  setActiveEmailFlow: (activeEmailFlow: boolean) => EmailFlowActionsReturn;
  listPhysicians: () => EmailFlowActionsReturn;
  save: (emailFlow: EmailFlow, active: boolean) => void;
  setActiveCard: (activeComponentsCard: string) => void;
  setComponent: (component: EmailFlowComponent) => void;
  updateEditor: (editorState: EditorState) => void;
  openModalLink: () => void;
  openModalButton: () => void;
  openModalImage: () => void;
  onEditorAutomatedTagChosen: (automatedTag: string) => void;
  deleteComponent: (
    componentId: string,
    physicianId: number,
    emailFlowId: string,
  ) => void;
  saveComponent: (physicianId: number) => void;
  addNewComponent: () => void;
  saveEditStateComponent: () => void;
  clearErrors: () => void;
  openModalSendEmailTest: (userInfo: UserInfo) => void;
}
interface FormData {
  name: string;
  flowType: string;
  physicianId: number;
  components: EmailFlowComponent[];
  activeEmailFlow: boolean;
}

interface EditEmailFlowReturnProps
  extends StateProps,
    DispatchProps,
    FormData {}

const EditEmailFlowReturn = ({
  match,
  emailFlow,
  cancelEmailFlowReturn,
  fetchEmailFlow,
  setActiveEmailFlow,
  senders,
  listPhysicians,
  save,
  activeCard,
  setActiveCard,
  setComponent,
  updateEditor,
  initialEditor,
  openModalLink,
  openModalButton,
  openModalImage,
  editorOptionsText,
  editor,
  currentInfo,
  onEditorAutomatedTagChosen,
  deleteComponent,
  saveComponent,
  addNewComponent,
  saveEditStateComponent,
  activeEmailFlow,
  errors,
  clearErrors,
  openModalSendEmailTest,
  userInfo,
}: EditEmailFlowReturnProps): JSX.Element => {
  useEffect(() => {
    listPhysicians();
  }, [listPhysicians]);

  useEffect(() => {
    fetchEmailFlow(match);
  }, [fetchEmailFlow, match]);

  useEffect(() => {
    updateEditor(initialEditor);
  }, [updateEditor, initialEditor]);

  const changeStatusEmailFlow = useCallback(
    (e) => {
      const { checked } = e.target;
      setActiveEmailFlow(checked);
    },
    [setActiveEmailFlow],
  );

  const validateCardComponents = useCallback(
    (component: EmailFlowComponent) => {
      if (component) {
        const { offset, subject, draftJSState } = component;
        const isValidComponent =
          isValidTextEditor(draftJSState) &&
          subject &&
          offset &&
          offset.value &&
          offset.unit;

        if (isValidComponent) {
          return STATUS.Success;
        }
      }
      return STATUS.Alert;
    },
    [],
  );

  const actionsTitleOrFooter = useCallback(
    (footer: boolean) => (
      <EditTitleEmailFlow
        footer={footer}
        onClickSave={() => save(emailFlow, activeEmailFlow)}
        onClickCancel={cancelEmailFlowReturn}
        changeStatusEmailFlow={changeStatusEmailFlow}
        emailFlow={emailFlow}
        invalid={false}
        activeEmailFlow={activeEmailFlow}
      />
    ),
    [
      activeEmailFlow,
      cancelEmailFlowReturn,
      changeStatusEmailFlow,
      emailFlow,
      save,
    ],
  );

  const openModalSendTest = useCallback(() => {
    openModalSendEmailTest(userInfo);
  }, [openModalSendEmailTest, userInfo]);

  const notificationErrors = useMemo(
    () =>
      errors.map((item) => (
        <NotificationExpireMessage
          kind="error"
          title="Sequência automáticas de e-mails"
          showCloseButton={false}
          key={item.id}
          onExpire={clearErrors}
          expireAfter="3000"
        >
          {item.message}
        </NotificationExpireMessage>
      )),
    [clearErrors, errors],
  );

  const cardsComponents = useMemo(() => {
    if (emailFlow && emailFlow.components) {
      const { components } = emailFlow;
      return components.map((item: EmailFlowComponent) => (
        <EditEmailFlowComponents
          component={item}
          activeCard={activeCard}
          titleCard={descEmail(item.offset)}
          setActiveCard={setActiveCard}
          setComponent={setComponent}
          flowType={emailFlow.flowType}
          editor={editor}
          editorOptionsText={editorOptionsText}
          updateEditor={updateEditor}
          openModalEditorLink={openModalLink}
          openModalEditorButton={openModalButton}
          openModalEditorImage={openModalImage}
          currentInfo={currentInfo}
          onAutomatedTagChosen={onEditorAutomatedTagChosen}
          key={item.id}
          deleteComponent={deleteComponent}
          physicianId={Number(match.physicianId)}
          emailFlowId={emailFlow.id}
          emailFlowName={emailFlow.name}
          saveComponent={saveComponent}
          saveEditStateComponent={saveEditStateComponent}
          isValidCard={validateCardComponents(item)}
          openModalSendEmailTest={openModalSendTest}
        />
      ));
    }

    return null;
  }, [
    emailFlow,
    activeCard,
    setActiveCard,
    setComponent,
    editor,
    editorOptionsText,
    updateEditor,
    openModalLink,
    openModalButton,
    openModalImage,
    currentInfo,
    onEditorAutomatedTagChosen,
    deleteComponent,
    match.physicianId,
    saveComponent,
    saveEditStateComponent,
    validateCardComponents,
    openModalSendTest,
  ]);

  const isMobile = useWidthUpdater();

  return (
    <AppContent
      contentTitle={actionsTitleOrFooter(false)}
      hideSidebar
      hideFooter
    >
      {notificationErrors}
      {!isMobile && (
        <div className={style.header}>
          <span>{emailFlowInformation.editEmailFlowReturn}</span>
        </div>
      )}
      <EmailFlowSender senders={senders} />
      <div className={style.components}>
        {cardsComponents}
        <div className={style.vl} />
        <div className={style.footerAddContainer}>
          <button
            className={style.addNewComponent}
            type="button"
            onClick={addNewComponent}
          >
            <Icon icon="add_circle" className={style.icon} />
            Adicionar outro e-mail ao fluxo
          </button>
        </div>
      </div>
      {actionsTitleOrFooter(true)}
      <ModalRoot />
    </AppContent>
  );
};

const mapStateToProps = (state: StoreState) => ({
  initialValues: selectors.getInitialFormData(state),
  emailFlow: selectors.getEmailFlow(state),
  senders: selectors.getUserProfile(state),
  activeCard: selectors.getActiveCard(state),
  initialEditor: selectors.getInitialEditor(state),
  editor: selectors.getEditor(state),
  editorOptionsText: selectors.getEditorOptionsText(state),
  currentInfo: selectors.getCurrentInfo(state),
  activeEmailFlow: selectors.getActiveEmailFlow(state),
  errors: selectors.getErrorsComponent(state),
  userInfo: selectors.getUserInfo(state),
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  cancelEmailFlowReturn: () => dispatch(cancelEmailFlow()),
  fetchEmailFlow: (params: EmailFlowPhysician) =>
    dispatch(fetchDetailsEmailFlow(params)),
  setActiveEmailFlow: (activeEmailFlow: boolean) =>
    dispatch(editActiveEmailFlow(activeEmailFlow)),
  listPhysicians: () => dispatch(fetchListPhysicians()),
  save: (emailFlow: EmailFlow, active: boolean) => {
    if (active && !emailFlow.active) {
      dispatch(
        open(ACTIVE_EMAIL_FLOW_MODAL, {
          onCloseClick: () => {
            dispatch(close());
          },
          onActiveEmailFlow: () => {
            dispatch(saveEmailFlow());
          },
        }),
      );
    } else {
      dispatch(saveEmailFlow());
    }
  },
  setActiveCard: (activeComponentsCard: string) =>
    dispatch(setActiveComponentsCard(activeComponentsCard)),
  setComponent: (component: EmailFlowComponent) =>
    dispatch(setCurrentComponent(component)),
  updateEditor: (editorState: EditorState) =>
    dispatch(updateTextEditor(editorState)),
  onEditorAutomatedTagChosen: (automatedTag: string) =>
    dispatch(onAutomatedTagChosen(automatedTag)),
  openModalLink: () =>
    dispatch(
      open(EDITOR_LINK_MODAL, {
        onCloseClick: () => {
          dispatch(close());
        },
        onAddURL: (textSelected: string, url: string) => {
          dispatch(onModalEditorLinkSubmit(textSelected, url));
        },
      }),
    ),
  openModalButton: () =>
    dispatch(
      open(EDITOR_BUTTON_MODAL, {
        onCloseClick: () => {
          dispatch(close());
        },
        onAddURL: (textSelected: string, url: string) => {
          dispatch(onModalEditorButtonSubmit(textSelected, url));
        },
      }),
    ),
  openModalImage: () =>
    dispatch(
      open(EDITOR_IMAGE_MODAL, {
        onCloseClick: () => {
          dispatch(close());
        },
        onAddURL: (url: string) => {
          dispatch(onModalEditorImageSubmit(url));
        },
      }),
    ),
  deleteComponent: (componentId, physicianId, emailFlowId) =>
    dispatch(
      open(CONFIRM_DELETE_COMPONENT_MODAL, {
        onCloseClick: () => {
          dispatch(close());
        },
        onDeleteComponent: () => {
          dispatch(
            fetchDeleteComponent({ componentId, physicianId, emailFlowId }),
          );
        },
      }),
    ),
  saveComponent: (physicianId: number) =>
    dispatch(
      saveEmailFlowComponent({
        physicianId,
      }),
    ),
  addNewComponent: () => dispatch(fetchDetailsAddEmailFlow()),
  saveEditStateComponent: () => dispatch(fetchDetailsChangeEmailFlow()),
  clearErrors: () => dispatch(clearErrorsComponent()),
  openModalSendEmailTest: (userInfo: UserInfo) =>
    dispatch(
      open(SEND_EMAIL_TEST_MODAL, {
        userInfo,
        onCloseClick: () => {
          dispatch(close());
        },
        onSendEmailTest: (physicianId: number, recipient: string) => {
          dispatch(fetchSendTestEmail({ physicianId, recipient }));
        },
      }),
    ),
});

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm({
    form: FORM_EDIT_EMAIL_FLOW_RETURN,
    enableReinitialize: true,
  })(EditEmailFlowReturn),
);
