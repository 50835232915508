import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Body,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogActions,
} from '@iclinic/design-system';

import { logoutManual } from 'features/new-auth/state/actions';

interface ModalSuccessRequestReleaseProps {
  open: boolean;
}

export const ModalSuccessRequestRelease = ({
  open,
}: ModalSuccessRequestReleaseProps) => {
  const dispatch = useDispatch();

  const onGotItClickHandler = () => {
    dispatch(logoutManual());
  };

  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogHeader
        title="Liberação solicitada"
        onClose={onGotItClickHandler}
      />

      <DialogContent>
        <Body variant="xs">Você será redirecionado à página de login</Body>

        <Body variant="xs">
          Se o pagamento da fatura não for identificado em até 3 dias úteis, o
          serviço volta a ficar bloqueado e o desbloqueio só será feito após a
          identicação do pagamento.
        </Body>
      </DialogContent>

      <DialogActions>
        <Button onClick={onGotItClickHandler}>Entendi</Button>
      </DialogActions>
    </Dialog>
  );
};
