// Extenal
import React from 'react';
import { connect } from 'react-redux';
import { FormikProps, FastField as Field } from 'formik';

// Internal
import { StoreState } from 'state/rootReducer';
import * as actions from 'state/billing/subscription/actions';
import {
  cardNumber, expirationDate, securityCode, personDocument,
  owner,
} from 'shared/utils/v2/formValidation';
import {
  CREDIT_CARD_NUMBER,
  CREDIT_CARD_VALIDITY,
  CREDIT_CARD_CVV,
  CPF_CNPJ,
} from 'shared/constants/v2/masks';
import InputEnhance from 'ui/form/v2/InputEnhance';
import FormWrapper from 'ui/new/form/v2';
import { ChangeEvent } from '@types';
import SubscriptionEnhance, { FormValues } from './SubscriptionEnhance';

const mapStateToProps = (state: StoreState) => ({
  checkout: state.billing.subscription.checkout,
  identityNumber: state.billing.subscription,
});

const mapDispatchToProps = actions;

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type MapDispatchToProps = typeof mapDispatchToProps;
type SubscriptionFormProps = MapStateToProps & MapDispatchToProps & FormikProps<FormValues>;

function SubscriptionForm({
  checkout,
  updateCredictCard,
  setFieldValue,
  flipCard,
}: SubscriptionFormProps): JSX.Element {
  const handleChange = (e: ChangeEvent) => {
    const { target: { value, name } } = e;
    setFieldValue(name, value);
    updateCredictCard(name, value);
  };
  return (
    <>
      <FormWrapper.Title content="Adicione um novo cartão" />
      <Field
        id="number"
        name="number"
        type="text"
        label="Número do cartão"
        placeholder="0000 0000 0000 0000"
        component={InputEnhance}
        mask={CREDIT_CARD_NUMBER}
        onChange={handleChange}
        validate={cardNumber}
        onFocus={() => flipCard(false)}
        autoFocus
      />
      <div>
        <Field
          id="owner"
          name="owner"
          type="text"
          label="Nome impresso no cartão"
          component={InputEnhance}
          onChange={handleChange}
          onKeyDown={owner}
          onPaste={owner}
          onFocus={() => flipCard(false)}
        />
      </div>
      <FormWrapper.Group>
        <Field
          id="expirationDate"
          name="expirationDate"
          type="text"
          label="Data de vencimento"
          placeholder="MM/AA"
          component={InputEnhance}
          size="md"
          onChange={handleChange}
          mask={CREDIT_CARD_VALIDITY}
          validate={expirationDate}
          onFocus={() => flipCard(false)}
        />
        <Field
          id="cvv"
          name="cvv"
          label="CVV"
          type="text"
          placeholder="000"
          component={InputEnhance}
          size="sm"
          onChange={handleChange}
          mask={CREDIT_CARD_CVV}
          validate={() => securityCode(checkout.cvv, checkout.number)}
          onFocus={() => flipCard(true)}
        />
      </FormWrapper.Group>
      {checkout && (
      <Field
        id="cpfCnpj"
        name="cpfCnpj"
        label="CPF ou CNPJ do titular do cartão"
        type="text"
        component={InputEnhance}
        onChange={handleChange}
        mask={CPF_CNPJ}
        validate={personDocument}
        onFocus={() => flipCard(false)}
      />
      )}
    </>
  );
}

export default connect<MapStateToProps, MapDispatchToProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionEnhance(SubscriptionForm));
