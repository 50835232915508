import { formatDate, formatTime } from 'shared/utils/date';
import { WHATSAPP_BASE_URL } from 'shared/constants/urls';
import {
  NotificationType,
  TransactionStatus,
  PaymentType,
} from 'features/onlinePayment/state/constants';
import { Event } from 'features/onlinePayment/state/manage/types';

export function getFixedNumber(value: number | string | undefined) {
  const number = Number(value) || 0;
  return number.toFixed(2);
}

export function composeTextMessageBill(
  event: Event | null,
  checkoutUrl: string | null,
): string {
  if (!event || !checkoutUrl) return '';

  const { patientName, physicianName, date, hour } = event;

  return `Olá ${patientName}, tudo bem? Você possui uma consulta com ${physicianName}, no dia ${formatDate(
    date,
  )}, às ${formatTime(
    hour,
  )} hrs/hr. Acesse este link: ${checkoutUrl} para realizar o pagamento. Qualquer dúvida, estou à disposição!`;
}

export function getWhatsAppLink(text: string, phone?: string) {
  return phone
    ? `${WHATSAPP_BASE_URL}/?phone=${phone}&text=${text}`
    : `${WHATSAPP_BASE_URL}/?&text=${text}`;
}

export function normalizeTransactionStatus(status: TransactionStatus): string {
  const transactionMap: Record<TransactionStatus, string> = {
    [TransactionStatus.Canceled]: 'Cancelado',
    [TransactionStatus.ChargeBack]: 'Estornado via Chargeback',
    [TransactionStatus.Created]: 'Aguardando pagamento',
    [TransactionStatus.Dispute]: 'Contestação',
    [TransactionStatus.Paid]: 'Pago',
    [TransactionStatus.PartialReversed]: 'Parcialmente estornado',
    [TransactionStatus.Pending]: 'Aguardando pagamento',
    [TransactionStatus.Processing]: 'Processando',
    [TransactionStatus.Rejected]: 'Rejeitada',
    [TransactionStatus.Reversed]: 'Estornado',
    [TransactionStatus.Success]: 'Pago',
  };

  return transactionMap[status] || 'Status Não Mapeado';
}

export const getChipColor = (status: TransactionStatus) => {
  const colorMap: Record<TransactionStatus, string> = {
    [TransactionStatus.Canceled]: 'error',
    [TransactionStatus.ChargeBack]: 'error',
    [TransactionStatus.Created]: 'default',
    [TransactionStatus.Dispute]: 'warning',
    [TransactionStatus.Paid]: 'default',
    [TransactionStatus.PartialReversed]: 'info',
    [TransactionStatus.Pending]: 'default',
    [TransactionStatus.Processing]: 'default',
    [TransactionStatus.Rejected]: 'error',
    [TransactionStatus.Reversed]: 'info',
    [TransactionStatus.Success]: 'success',
  };

  return colorMap[status] || 'default';
};

export function composeTextFormPayment(
  type: string | null,
  installments?: number,
) {
  if (!type) return '-';

  const transactionsType: { [key: string]: string } = {
    credit: 'Crédito',
    debit: 'Débito',
    pix: 'PIX',
  };

  if (type === PaymentType.Pix) return 'Pix';

  return `${transactionsType[type]} em ${installments ?? 1}x`;
}

export function composeTitleNotification(
  msgType: string | null | undefined,
): string | null {
  if (!msgType) return null;

  if (msgType === NotificationType.PaymentApproved) return 'Pagamento aprovado';
  if (msgType === NotificationType.CreditCardAnticipationApproved)
    return 'Antecipação cartão de crédito realizada';
  if (msgType === NotificationType.CreditCardAnticipationAvailable)
    return 'Antecipação cartão de crédito disponível';

  return 'Notificação';
}

export function normalizeTransactionOrigin(
  origin: string | null,
): string | null {
  if (!origin) return null;

  const transactionOrigin: { [key: string]: string } = {
    online: 'Online',
    presential: 'Presencial',
  };

  return transactionOrigin[origin];
}

export function downloadBlob(blob: Blob, fileName: string) {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(link.href);
}
