import React, { useState } from 'react';
import { MaterialIcons, MaterialUICore } from '@iclinic/design-system';
import { useDispatch } from 'react-redux';

import { showPrescriptionTab } from 'state/records/actions';
import {
  FloatingActionButton,
  ListItem,
  ListContainer,
  ListItemIcon,
} from './RecordsShortcuts.styles';
import { actions } from 'features/records/calculatorDrawer/state';

const { openCalculatorDrawer } = actions;

const { List, ListItemText, Popper } = MaterialUICore;

const { Add, Close, MedicationRounded, CalculateRounded } = MaterialIcons;

type RecordsShortcutsProps = {
  propsFromApp: {
    isPrescriptionTab: boolean;
    hasCalculatorsWhitebook: boolean;
  };
};

export const RecordsShortcuts = ({
  propsFromApp: { hasCalculatorsWhitebook },
}: RecordsShortcutsProps) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchor(e.currentTarget);
    setOpen(!open);
  };

  const onClickPrescription = () => {
    setOpen(false);
    setAnchor(null);
    dispatch(showPrescriptionTab());
  };

  const onClickCalculator = () => {
    setOpen(false);
    setAnchor(null);
    dispatch(openCalculatorDrawer());
  };

  return (
    <>
      <Popper placement="top-end" open={open} anchorEl={anchor} disablePortal>
        <ListContainer>
          <List component="nav" disablePadding>
            <ListItem
              button
              onClick={onClickPrescription}
              data-ga="medical_record_prescription_shortcut"
              data-sp="medical_record_prescription_shortcut"
            >
              <ListItemIcon>
                <MedicationRounded />
              </ListItemIcon>
              <ListItemText primary="Prescrição" />
            </ListItem>
            {hasCalculatorsWhitebook && (
              <ListItem
                button
                onClick={onClickCalculator}
                data-ga="medical_record_medical_calculators"
                data-sp="medical_record_medical_calculators"
              >
                <ListItemIcon>
                  <CalculateRounded />
                </ListItemIcon>
                <ListItemText primary="Calculadoras" />
              </ListItem>
            )}
          </List>
        </ListContainer>
      </Popper>
      <FloatingActionButton
        data-ga="medical_record_more_tools"
        data-sp="medical_record_more_tools"
        disableRipple
        disableFocusRipple
        disableTouchRipple
        variant="round"
        color="primary"
        onClick={onClick}
      >
        {open ? <Close /> : <Add />}
      </FloatingActionButton>
    </>
  );
};
