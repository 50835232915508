import {
  Box,
  MaterialUICore,
  tokens,
  Heading as DSHeading,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing, color, border } = tokens;

export const TitleWrapper = styled(Box)(({ theme }) => ({
  marginBottom: spacing.md,
  paddingTop: spacing.md,

  [theme.breakpoints.up('lg')]: {
    paddingTop: spacing.lg,
    textAlign: 'center',
  },
}));

export const PlansWrapper = styled(Box)({
  backgroundColor: color.secondary.main,
  borderRadius: border.radius.sm,
  padding: `${spacing.lg} ${spacing.md}`,
});

export const PlansGrid = styled('ul')(({ theme }) => ({
  maxWidth: 976,
  margin: '0 auto',
  display: 'grid',
  gridTemplateColumns: '1fr',
  columnGap: spacing.lg,
  rowGap: spacing.lg,
  listStyle: 'none',

  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
}));

export const Heading = styled(DSHeading)({
  color: color.neutral[700],
});

export const ListItem = styled('li')(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.base,
  justifyContent: 'space-between',
  padding: `0 ${spacing.base}`,

  '&:hover .MuiButton-outlined': {
    color: color.primary.dark,
    border: `${border.width.hairline} solid ${color.primary.dark}`,
  },

  [theme.breakpoints.up('md')]: {
    padding: `${spacing.md} 0 ${spacing.base}`,
  },
}));
