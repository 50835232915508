import React from 'react';
import { Heading, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, spacing } = tokens;

type HTMLElementProps<T> = React.DetailedHTMLProps<React.HTMLAttributes<T>, T>;

type DividerProps = {
  dividerColor: 'neutral' | 'secondary';
} & HTMLElementProps<HTMLHRElement>;

export const Card = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  rowGap: spacing.sm,
  width: '100%',
});

export const Divider = styled(({ dividerColor, ...rest }: DividerProps) => (
  <hr {...rest} />
))({
  borderWidth: border.width.hairline,
  borderStyle: 'solid',
  borderColor: ({ dividerColor }: DividerProps) =>
    dividerColor === 'neutral' ? color.neutral[100] : color.secondary.main,
  width: '100%',
  margin: 0,
});

export const PriceContainer = styled(Heading)({
  whiteSpace: 'nowrap',
});

export const DescriptionTitle = styled(Heading)({
  color: color.primary.main,
});

export const ProductSelectedWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
});

export const TotalWrapper = styled(ProductSelectedWrapper)({
  padding: `${spacing.base} 0`,
});

export const TotalAmount = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
});
