import { Checkbox, MaterialUICore, tokens } from '@iclinic/design-system';
import { CreateCSSProperties } from '@material-ui/styles';

const { styled } = MaterialUICore;

const justifyContentSpaceType = 'space-between';

const flexRowJustified: CreateCSSProperties = {
  display: 'flex',
  justifyContent: justifyContentSpaceType,
};

const listRowAlignment: CreateCSSProperties = {
  width: '100%',
  padding: `${tokens.spacing.base} 0`,
  color: tokens.color.neutral[700],
  '& > span': {
    width: '100%',
    textAlign: 'left',
  },
  '& > span:nth-child(4)': {
    textAlign: 'end',
  },
  '& > span:nth-child(5)': {
    textAlign: 'center',
    '& > label': {
      margin: 0,
    },
  },
};

export const CustomCheckBox = styled(Checkbox)({
  padding: 0,
});

export const DetailsHeader = styled('div')({
  ...flexRowJustified,
  ...listRowAlignment,
  fontWeight: tokens.font.weight.bold,
  backgroundColor: tokens.color.neutral[25],
  padding: `${tokens.spacing.sm} ${tokens.spacing.base}`,
});

export const DetailsItem = styled('div')({
  ...flexRowJustified,
  ...listRowAlignment,

  borderBottom: `${tokens.border.width.hairline} solid ${tokens.color.neutral[50]}`,

  '& span': {
    padding: `${tokens.spacing.nano} ${tokens.spacing.base}`,
    display: 'flex',
    alignItems: 'center',
  },
  '& > span:nth-child(4)': {
    justifyContent: 'flex-end',
  },
  '& > span:nth-child(5)': {
    justifyContent: 'center',
  },
  '&.pill': {
    borderRadius: tokens.border.radius.pill,
    height: 24,
  },
  '&.pill.not-paid': {
    backgroundColor: tokens.color.neutral[50],
    color: tokens.color.neutral[700],
  },
  '&.pill.paid': {
    color: tokens.color.feedback.success.dark,
    backgroundColor: tokens.color.feedback.success.light,
  },
  '& > span:not(last-of-type)': {
    borderRight: `${tokens.border.width.hairline} solid ${tokens.color.neutral[50]}`,
  },
});
