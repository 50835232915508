import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { TextField } from '@iclinic/design-system';
import { useDebounce, useDidUpdateEffect, usePrevious } from 'shared/hooks';
import useCurrencyMask, { format } from 'shared/hooks/useCurrencyMask';
import {
  BillStatus,
  DEBOUNCE_AMOUNT_CHARGED,
} from 'features/onlinePayment/state/constants';
import { getFixedNumber } from 'features/onlinePayment/utils';
import withContentLoader from 'shared/hoc/withContentLoader';
import { AmountChargedInputContentLoader } from './ContentLoaders';
import useStyles from './AmountCharged.styles';

type AmountChargedProps = {
  status?: BillStatus | null;
};

function AmountCharged({ status }: AmountChargedProps): JSX.Element | null {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    submitForm,
  } = useFormikContext<any>();

  const classes = useStyles();
  const { ref, onChange: onChangeValue, originalValue } = useCurrencyMask(
    values.amountCharged,
  );

  const debouncedValue = useDebounce(originalValue, DEBOUNCE_AMOUNT_CHARGED);
  const prevStatus = usePrevious(status);

  useDidUpdateEffect(() => {
    if (status === BillStatus.Created && status === prevStatus) {
      submitForm();
    }
  }, [status, debouncedValue, submitForm]);

  useEffect(() => {
    setFieldValue('amountCharged', originalValue);
  }, [originalValue, setFieldValue]);

  return (
    <TextField
      name="value"
      id="value"
      label="Valor"
      placeholder="R$ 0,00"
      defaultValue={format(getFixedNumber(values.amountCharged))}
      inputRef={ref}
      onChange={onChangeValue}
      className={classes.totalInput}
      error={!!(errors.amountCharged && touched.amountCharged)}
      helperText={touched.amountCharged && errors.amountCharged}
      InputProps={{
        readOnly: status === BillStatus.Paid,
      }}
    />
  );
}

export default withContentLoader<AmountChargedProps>(
  AmountChargedInputContentLoader,
)(AmountCharged);
