import React from 'react';

import { MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

type MessageGroupProps = {
  isLocalParticipant: boolean;
};

export const MessageGroup = styled(
  ({
    isLocalParticipant,
    ...rest
  }: MessageGroupProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >) => <div {...rest} />,
)({
  fontFamily: 'roboto, sans-serif',
  display: 'flex',
  flexDirection: 'column',
  alignItems: ({ isLocalParticipant }: MessageGroupProps) =>
    isLocalParticipant ? 'flex-end' : 'flex-start',
  padding: '0px',
  marginLeft: ({ isLocalParticipant }: MessageGroupProps) =>
    isLocalParticipant ? '31px' : '0px',
  marginRight: ({ isLocalParticipant }: MessageGroupProps) =>
    isLocalParticipant ? '0px' : '31px',
});
