import React from 'react';

import PersonalInfo from './components/PersonalInfo';
import * as S from './styles';
import PaidByOtherPerson from './components/PaidByOtherPerson';

const Header = (): JSX.Element => (
    <S.HeaderContainer>
      <PersonalInfo />
      <PaidByOtherPerson />
    </S.HeaderContainer>
  );

export default Header;
