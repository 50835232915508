import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Loader,
  MaterialUICore,
  MaterialIcons,
  Paper,
  Typography,
} from '@iclinic/design-system';

import { formatDate } from 'shared/utils/date';
import {
  setExportDrawer,
  setNewExport,
  setExportId,
} from '../../state/exportHome';
import { fetchExportList } from '../../state/exportList';
import useStyles from './index.styles';
import Paginate from './Paginate';
import { getExportedList } from 'features/exportClinicData/state/exportList/selectors';
import { downloadExportFile } from 'features/exportClinicData/state/exportDownload';
import { joinPhysiciansNames } from 'features/exportClinicData/utils';

const { CloudDownload, CloudQueue } = MaterialIcons;

const { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } =
  MaterialUICore;

export default function ExportListTable(): JSX.Element {
  const classes = useStyles();

  const dispatch = useDispatch();
  const exportedList = useSelector(getExportedList);
  useEffect(() => {
    dispatch(fetchExportList());
  }, []);

  const openDrawer = (id: string) => {
    dispatch(setExportId(id));
    dispatch(setNewExport(false));
    dispatch(setExportDrawer(true));
  };

  const handleClickDownload = (id: string) => {
    dispatch(setExportId(id));
    dispatch(downloadExportFile());
  };

  return (
    <Paper className={classes.paper}>
      <TableContainer>
        <Typography variant="h4" className={classes.sectionTitle}>
          Exportações realizadas
        </Typography>
        {!!exportedList && exportedList.length === 0 && (
          <Box m={4}>
            <Typography variant="body2">
              Nenhuma exportação de dados foi solicitada até o presente momento.
            </Typography>
          </Box>
        )}
        {!exportedList && (
          <Box m={4}>
            <Loader title="Carregando informações" />
          </Box>
        )}
        {!!exportedList && exportedList.length > 0 && (
          <Table
            className={classes.table}
            aria-label="Lista de exportações solicitadas"
          >
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Clínica</TableCell>
                <TableCell>Profissional de saúde</TableCell>
                <TableCell>Formato</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exportedList.map((row) => (
                <TableRow key={row.date} className={classes.downloadRow}>
                  <TableCell
                    className={classes.oddTextColor}
                    onClick={() => openDrawer(row.id)}
                  >
                    {formatDate(row.date)}
                  </TableCell>
                  <TableCell onClick={() => openDrawer(row.id)}>
                    {row.clinic_name}
                  </TableCell>
                  <TableCell
                    className={classes.oddTextColor}
                    onClick={() => openDrawer(row.id)}
                  >
                    {joinPhysiciansNames(row.physicians)}
                  </TableCell>
                  <TableCell
                    className={classes.upperText}
                    onClick={() => openDrawer(row.id)}
                  >
                    {row.file_type}
                  </TableCell>
                  <TableCell align="right">
                    {row.status === 'done' ? (
                      <Button
                        color="transparent"
                        onClick={() => handleClickDownload(row.id)}
                      >
                        <CloudDownload className={classes.buttonIcon} /> Baixar
                      </Button>
                    ) : (
                      <Button
                        color="transparent"
                        className={classes.disabledButton}
                        onClick={() => openDrawer(row.id)}
                      >
                        <CloudQueue className={classes.buttonIcon} />{' '}
                        Processando
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <Paginate />
    </Paper>
  );
}
