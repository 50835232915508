/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/default-param-last */
// External
import { combineReducers } from 'redux';

// Internal
import feedDataLayer from 'features/feedDataLayer/state';
import * as types from './types';
// Internal Reducers
import electronicSignature from 'features/records/electronicSignature/state';
import highlightsInfo from 'features/records/highlightsInfo/state';
import attachments from './attachments/reducer';
import memed from './memed/reducer';
import signature from './signature/reducer';
import template from './textTemplate';
import extractorEventsBlocksIds from './utils';
import transcriptionDrawer from 'features/records/transcriptionDrawer/state';
import calculatorDrawer from 'features/records/calculatorDrawer/state';
import prescription from 'features/records/prescription/state';
import latestDiagnoses from 'features/records/latestDiagnoses/state';
import cardPatient from 'features/records/cardPatient/state';
import summaryIA from 'features/records/summaryIA/state';
import speechToText from 'features/records/speechToText/state';

export const initialState = {
  eventId: null,
  entity: {},
  patient: {},
  physician: {},
  eventBlocksIds: [],
  tooglePatientSummaryModal: false,
  serviceInitialized: false,
  canGenerateNewSummary: true,
};

export const root = (
  state = initialState,
  action: any,
): typeof initialState => {
  const { payload } = action;
  switch (action.type) {
    case types.INITIALIZED:
      return {
        ...state,
        ...payload,
        serviceInitialized: true,
      };
    case types.ENDED:
      return {
        ...state,
        serviceInitialized: false,
      };
    case types.SET_PATIENT:
      return {
        ...state,
        ...payload,
      };
    case types.GET_EVENT_BLOCKS_IDS.SUCCESS:
      return {
        ...state,
        eventBlocksIds: extractorEventsBlocksIds(
          payload.eventBlocksIds,
          payload.patientId,
        ) as never[],
      };
    case types.saveHighlightsInfoSuccess:
    case types.RECORDS_FINISHED_SUCCESS:
      return {
        ...state,
        canGenerateNewSummary: true,
      };
    case types.NOT_ALLOW_GENERATE_NEW_SUMMARY:
      return {
        ...state,
        canGenerateNewSummary: false,
      };
    default:
      return state;
  }
};

export default combineReducers({
  root,
  memed,
  attachments,
  signature,
  template,
  highlightsInfo,
  feedDataLayer,
  electronicSignature,
  transcriptionDrawer,
  calculatorDrawer,
  prescription,
  latestDiagnoses,
  cardPatient,
  summaryIA,
  speechToText,
});
