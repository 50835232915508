import { DrawerCard } from '@iclinic/design-system';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from './state';
import {
  getIsCalculatorDrawerOpen,
  getCalculatorViewSelected,
} from './state/selectors';
import { CalculatorDrawerView } from './state/types';
import { CalculatorDrawerList } from './components/CalculatorDrawerList';
import { CalculatorDrawerSelected } from './components/CalculatorDrawerSelected';

const { closeCalculatorDrawer } = actions;

export const CalculatorDrawer = () => {
  const dispatch = useDispatch();
  const isCalculatorDrawerOpen = useSelector(getIsCalculatorDrawerOpen);
  const calculatorViewSelected = useSelector(getCalculatorViewSelected);

  const onDrawerClose = () => {
    dispatch(closeCalculatorDrawer());
  };

  return (
    <DrawerCard
      variant="persistent"
      open={isCalculatorDrawerOpen}
      onClose={onDrawerClose}
    >
      {calculatorViewSelected === CalculatorDrawerView.LIST ? (
        <CalculatorDrawerList />
      ) : (
        <CalculatorDrawerSelected />
      )}
    </DrawerCard>
  );
};
