// Internal
import * as types from './types';

export const submit = (payload) => ({
  type: types.SUBMIT.ACTION,
  payload,
});

export const openDetail = () => ({
  type: types.DETAIL.OPEN,
  payload: {
    isOpen: true,
    saveFeedback: null,
  },
});

export const closeDetail = () => ({
  type: types.DETAIL.CLOSE,
  payload: {
    isOpen: false,
    errors: [],
  },
});

export const fetchDetail = () => ({
  type: types.FETCH_DETAIL.ACTION,
  payload: {
    isFetching: true,
  },
});

export const fetchDetailSuccess = (payload) => ({
  type: types.FETCH_DETAIL.SUCCESS,
  payload: {
    isFetching: false,
    productsDetail: payload,
  },
});

export const setPaymentData = (
  flag,
  finalNumber,
  hasPaymentAttempt,
  isAccountBlocked,
) => ({
  type: types.SET_PAYMENT_DATA.ACTION,
  payload: {
    flag,
    finalNumber,
    hasPaymentAttempt,
    isAccountBlocked,
  },
});

export const fetchCreateSuccess = (saveFeedback) => ({
  type: types.FETCH_CREATE.SUCCESS,
  payload: {
    isFetching: false,
    isOpen: false,
    saveFeedback,
  },
});

export const fetchDetailFailure = () => ({ type: types.FETCH_DETAIL.FAILURE });

export const fetchCreate = () => ({
  type: types.FETCH_CREATE.ACTION,
  payload: {
    isFetching: true,
  },
});

export const fetchCreateFailure = (errors) => ({
  type: types.FETCH_CREATE.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});
export const setIsAccountBlocked = () => ({
  type: types.SET_IS_ACCOUNT_BLOCKED.ACTION,
});

export const clearSaveFeedback = () => ({
  type: types.CLEAR_FEEDBACK.ACTION,
  payload: {
    saveFeedback: null,
  },
});

export const setTurnstileToken = (turnstileToken) => ({
  type: types.SET_TURNSTILE_TOKEN.ACTION,
  payload: {
    turnstileToken,
  },
});
