import React from 'react';

import { TextField, MaterialUICore } from '@iclinic/design-system';
import states from 'shared/constants/statesOfCountry';
import useStyles from './dialogStyles.style';
import { StateSelectProps } from '../types';

const { MenuItem } = MaterialUICore;

const renderOption = (state: { acronym: string }) => (
  <MenuItem key={state.acronym} value={state.acronym}>
    {state.acronym}
  </MenuItem>
);

function StateSelect(props: StateSelectProps) {
  const {
    name,
    label,
    handleChange,
    error,
    value,
    disabled,
    helperText,
  } = props;
  const classes = useStyles();

  return (
    <TextField
      label={label}
      id={name}
      select
      name={name}
      error={error}
      onChange={handleChange}
      value={value}
      disabled={disabled}
      data-ga={`cfmupdate-${name}`}
      SelectProps={{ native: false }}
      margin="normal"
      className={classes.inputWidth}
      helperText={helperText}
    >
      {states.map(renderOption)}
    </TextField>
  );
}

export default StateSelect;
