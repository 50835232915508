import React from 'react';

import Modal from 'ui/new/modal';
import Button from 'ui/new/button';
import { FOOTER } from 'ui/new/modal/constants';
import { BUTTON } from 'ui/new/button/constants';

import style from './ConfirmActiveEmailFlowModal.scss';

type ConfirmActiveEmailFlowModalProps = {
  onActiveEmailFlow: () => void,
  onCloseClick: () => void;
};

const ConfirmActiveEmailFlowModal = ({
  onCloseClick,
  onActiveEmailFlow,
}: ConfirmActiveEmailFlowModalProps) => (
  <div className={style.modal}>
    <Modal
      show
      onClose={onCloseClick}
      classes={style}
      width={440}
    >
      <Modal.Body classes={style}>
        <p>Tem certeza que deseja ativar o envio de e-mails?</p>
      </Modal.Body>
      <Modal.Footer align={FOOTER.split}>
        <Button type={BUTTON.Light} onClick={onCloseClick}>CANCELAR</Button>
        <Button type={BUTTON.Success} onClick={onActiveEmailFlow}>SALVAR E ATIVAR</Button>
      </Modal.Footer>
    </Modal>
  </div>

);

export default ConfirmActiveEmailFlowModal;
