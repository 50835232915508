import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ListItem,
  IconButton,
  MaterialUICore,
  MaterialIcons,
  Heading,
  ListItemSecondaryAction,
} from '@iclinic/design-system';

import { actions } from 'state/records/textTemplate';
import ChoiceDeleteOverlay from '../ChoiceDeleteOverlay';

const { ListItemText } = MaterialUICore;
const { Delete } = MaterialIcons;
const {
  closeDrawerUseTextTemplate,
  deleteTextTemplate,
  setTextTemplateChosen,
} = actions;

type CustomListItemProps = {
  uniqueId: number;
  textTemplateId: number;
  title: string;
  text: string;
};

const CustomListItem = ({
  uniqueId,
  textTemplateId,
  text,
  title,
}: CustomListItemProps) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const dispatch = useDispatch();

  const handleItemClick = () => {
    dispatch(setTextTemplateChosen({ text, uniqueId }));
    dispatch(closeDrawerUseTextTemplate());
  };

  const showDeleteChoice = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDeleting(true);
  };

  const closeDeleteChoise = () => {
    setIsDeleting(false);
  };

  const handleOnActionYes = () => {
    dispatch(deleteTextTemplate(textTemplateId));
    closeDeleteChoise();
  };

  return (
    <ListItem onClick={handleItemClick}>
      <ListItemText primary={<Heading variant="xs">{title}</Heading>} />
      <ListItemSecondaryAction>
        <IconButton label="excluir" tooltip="left" onClick={showDeleteChoice}>
          <Delete />
        </IconButton>
      </ListItemSecondaryAction>
      {isDeleting && (
        <ChoiceDeleteOverlay
          title={<Heading variant="xs">Deseja excluir</Heading>}
          onActionYes={handleOnActionYes}
          onActionNo={closeDeleteChoise}
        />
      )}
    </ListItem>
  );
};

export default CustomListItem;
