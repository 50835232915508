import { eventChannel } from 'redux-saga';

export const settingsChannel = () => eventChannel((emit) => {
  global.MdSinapsePrescricao.event.add('core:moduleInit', (module) => {
    if (module.name === 'plataforma.prescricao') {
      emit({});
    }
  });

  return () => {};
});

export const addDrugFromPrescriptionChannel = () => eventChannel((emit) => {
  global.MdHub.event.add('medicamentoAdicionado', (data) => {
    emit(data);
  });

  return () => {
    global.MdHub.event.remove('medicamentoAdicionado');
  };
});

export const savePrescriptionChannel = () => eventChannel((emit) => {
  global.MdHub.event.add('prescricaoSalva', (id) => {
    emit(id);
  });

  return () => {
    global.MdHub.event.remove('prescricaoSalva');
  };
});
