import React from 'react';

import {
  Body,
  Heading,
  MaterialUICore,
  MaterialIcons,
} from '@iclinic/design-system';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { PHYSICIAN_PHOTO_ALT } from 'features/video-conference/constants';
import { formatDateWithDayOfTheWeek, extractTime } from 'shared/utils/date';
import {
  Divider,
  ScheduleInfoWrapper,
  ScheduleInfoContainer,
  ScheduleInfoContent,
  PhysicianInfoContainer,
  PhysicianInfoContent,
  HeadingContainer,
  SpinnerWrapper,
} from './styles';

const { Avatar, CircularProgress } = MaterialUICore;
const { AccessTime, DateRange, Person } = MaterialIcons;

interface Props {
  isWaitRoom?: boolean;
}

const ScheduleInformation = ({ isWaitRoom = false }: Props): JSX.Element => {
  const {
    patientInfo,
    physicianInfo,
    scheduleInfo,
    isFetchingInfo,
  } = useParticipantContext();

  const scheduleDate = formatDateWithDayOfTheWeek(scheduleInfo?.startDate);
  const scheduleHours = extractTime(scheduleInfo?.startDate);

  if (isFetchingInfo)
    return (
      <SpinnerWrapper>
        <CircularProgress data-testid="circular-progress-icon" />
      </SpinnerWrapper>
    );

  return (
    <ScheduleInfoWrapper>
      {!isWaitRoom && (
        <HeadingContainer>
          <Heading variant="xl" inverseTextColor>
            Olá,{` ${patientInfo?.name}`}
          </Heading>
        </HeadingContainer>
      )}

      <ScheduleInfoContainer>
        <ScheduleInfoContent>
          <DateRange />
          <Body variant="xs" inverseTextColor>
            {scheduleDate}
          </Body>
        </ScheduleInfoContent>
        <ScheduleInfoContent>
          <AccessTime />
          <Body variant="xs" inverseTextColor>
            {scheduleHours}
          </Body>
        </ScheduleInfoContent>
        <Divider />
        <PhysicianInfoContainer>
          {physicianInfo?.photo ? (
            <Avatar src={physicianInfo.photo} alt={PHYSICIAN_PHOTO_ALT} />
          ) : (
            <Avatar>
              <Person data-testid="person-icon" />
            </Avatar>
          )}
          <PhysicianInfoContent>
            <Body variant="xs" inverseTextColor>
              {physicianInfo?.name}
            </Body>
            {physicianInfo?.specialtyName && (
              <Body
                variant="sm"
                inverseTextColor
                data-testid="physician-speciality"
              >
                {physicianInfo?.specialtyName}
              </Body>
            )}
          </PhysicianInfoContent>
        </PhysicianInfoContainer>
      </ScheduleInfoContainer>
    </ScheduleInfoWrapper>
  );
};

export default ScheduleInformation;
