import React from 'react';
import { Body } from '@iclinic/design-system';

import { ProfessionalContent } from 'features/subscription/upgradeCall/types';
import {
  formatDateToString,
  getSevenDaysBefore,
  getThirtyDaysBefore,
} from 'features/reports/utils';

export const upgradeModalContent: ProfessionalContent = {
  title: 'Quer a liberdade de ter teleconsultas ilimitadas?',
  imagePath: 'upgrade.premium',
  planToUpgrade: 'premium',
  subtitle: (
    <Body variant="sm">
      Veja as vantagens do nosso <strong>plano Premium!</strong> Crie uma
      relação de <strong>fidelização</strong> com seus pacientes, e{' '}
      <strong>atenda mais, de onde estiver:</strong>
    </Body>
  ),
  list: [
    'Teleconsultas ilimitadas;',
    'E-mail Marketing personalizado;',
    'E-mail Marketing de Retorno automático e customizável.',
  ],
};

export const quickFilterOptions = [
  { value: '', label: 'Todos' },
  { value: formatDateToString(new Date()), label: 'Hoje' },
  { value: getSevenDaysBefore(), label: 'Últimos 7 dias' },
  { value: getThirtyDaysBefore(), label: 'Últimos 30 dias' },
];
