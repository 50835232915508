import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  Alert,
  MaterialIcons,
  Typography,
  Button,
} from '@iclinic/design-system';
import { StoreState } from 'state/rootReducer';
import {
  clearSignPDFErrors,
  finalizeMedicalRecordSuccess,
  signatureChangeStep,
  getSignatureAccessTokenSuccess,
} from 'state/records/signature/actions';
import { clearSharedErrors } from 'state/shared/actions';
import { getErrors } from 'state/records/signature/selectors';
import useStyles from '../containers/styleSignatureInitial';
import { STEP_SIGNATURE } from '../containers/constants';

export default function NotificationErrors(): JSX.Element {
  const dispatch = useDispatch();
  const classes = useStyles();
  const errors = useSelector((state) => getErrors(state));
  const stepper: STEP_SIGNATURE = useSelector<StoreState>(
    (state) => state.records.signature.stepper,
  );

  const OnBackButton = () => {
    dispatch(clearSignPDFErrors());
    dispatch(clearSharedErrors());
    dispatch(getSignatureAccessTokenSuccess(''));
    dispatch(signatureChangeStep(STEP_SIGNATURE.Initial));
  };
  return (
    <>
      {errors.length > 0 && (
        <>
          <Alert
            severity="error"
            icon={<MaterialIcons.Error />}
            message={errors.map((error) => (
              <>
                <span>{error.message}</span>
                <br />
              </>
            ))}
          />
          {stepper === STEP_SIGNATURE.Loading && errors.length > 0 && (
            <div className={classes.actionError}>
              <Button
                className={classes.closeButton}
                onClick={() => dispatch(finalizeMedicalRecordSuccess())}
                color="transparent"
              >
                <Typography variant="body1">Finalizar sem assinar</Typography>
              </Button>
              <Button
                className={classes.footerButton}
                variant="contained"
                color="primary"
                onClick={OnBackButton}
              >
                Tentar novamente
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
}
