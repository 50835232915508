import { useEffect, useRef } from 'react';

/**
 * This custom hook returns a `React.Ref` that indicates whether the component is mounted
 *
 * @returns RefObject
 */
export default function useMountedRef() {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return mounted;
}
