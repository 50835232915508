import { GenericObjectDescription } from '../misc';

export interface AutocompleteInput {
  id?: number;
  name?: string;
  type?: string;
  inputValue?: string;
}

export interface PhysicianProfile {
  name?: string;
}

export interface PhysicianAutocompleteEntry extends AutocompleteInput {
  cbo: string | null;
  cbo_code?: string;
  council_name: string | null;
  council_number: number | null;
  council_region: string | null;
  cnes?: string | null;

  profile?: PhysicianProfile;
}

export interface CBOAutocompleteEntry extends AutocompleteInput {
  code: string;
  term: string;
}

export interface ClinicAutocompleteEntry extends AutocompleteInput {
  cnes?: string | null;
}

export interface ProcedureAutocompleteEntry extends GenericObjectDescription {
  price: string;
  tuss_procedure_id?: number | null;
  code?: string | null;
  term?: string | null;
  search_name?: string | null;
}

export interface ProfessionCodeAutocompleteEntry extends AutocompleteInput {
  code: string | null;
  term: string;
}

export interface HiredSolicitantAutocompleteEntry extends AutocompleteInput {
  slug?: HiredSolicitantSlug;
  cbo?: number | null;
  council_name?: number | null;
  council_number?: number | null;
  council_region?: number | null;
}

export enum HiredSolicitantSlug {
  Physician = 'physician',
  Clinic = 'clinic',
}

export interface HiredSolicitantCodeEntry {
  id: number | string;
  type: HiredSolicitantSlug;
  code_type: string | null;
  code: string | null;
}

export interface GuideVersionData {
  version: string;
}

export interface AnsRegisterData {
  id?: number;
  health_insurance_name: string;
  health_insurance_register: string;
}

export interface PatientAutocompleteEntry {
  id?: number | null;
  civil_name?: string | null;
  social_name?: string | null;
  sus_code?: string | null;
}

export interface Autocomplete {
  physicians: PhysicianAutocompleteEntry[];
  executors: PhysicianAutocompleteEntry[];
  clinics: ClinicAutocompleteEntry[];
  procedures: ProcedureAutocompleteEntry[];
  hired_solicitants: HiredSolicitantCodeEntry[];
  versions: string[];
  guide_versions: GuideVersionData[];
  ans_registers: AnsRegisterData[];
  patients: AutocompleteInput[];
  patients_options: PatientAutocompleteEntry[];
  profession_codes: CBOAutocompleteEntry[];
}

export interface HiredProviderAutocompleteEntry extends AutocompleteInput {
  slug?: HiredSolicitantSlug;
  cbo?: string | null;
  council_name?: string | null;
  council_number?: number | null;
  council_region?: string | null;
  cnes?: string | null;
}
