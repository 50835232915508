// Internal
import { SuccessVideoView } from 'shared/constants/views';
import { ApiErrors, ActionPayload, Action } from '@types';
import {
  Product,
  UserInfo,
  PaymentInfo,
  AddonState,
} from './types';
import * as types from './constants';

export type OpenDetail = ActionPayload<'isOpen' | 'modal', typeof types.DETAIL_OPEN, AddonState>;
export const openDetail = (modal: SuccessVideoView): OpenDetail => ({
  type: types.DETAIL_OPEN,
  payload: {
    isOpen: true,
    modal,
  },
  track: {
    label: modal,
    action: 'open',
    category: 'upgrade',
  },
});

export type CloseDetail = ActionPayload<'step' | 'isOpen', typeof types.DETAIL_CLOSE, AddonState>;
export const closeDetail = (modal: SuccessVideoView): CloseDetail => ({
  type: types.DETAIL_CLOSE,
  payload: {
    step: 1,
    isOpen: false,
  },
  track: {
    label: modal,
    action: 'close',
    category: 'upgrade',
  },
});

export type CloseInstruction = Action<typeof types.INSTRUCTION_CLOSE>;
export const closeInstruction = (): CloseInstruction => ({ type: types.INSTRUCTION_CLOSE });

export type SetStep = ActionPayload<'step', typeof types.SET_STEP, AddonState>;
export const setStep = (step: number, modal: SuccessVideoView): SetStep => ({
  type: types.SET_STEP,
  payload: {
    step,
  },
  track: {
    label: modal,
    action: 'step',
    category: 'upgrade',
  },
});

export type SetStepSuccess = ActionPayload<'step' | 'isOpen', typeof types.SET_STEP_SUCCESS, AddonState>;
export const setStepSuccess = (modal: SuccessVideoView): SetStepSuccess => ({
  type: types.SET_STEP_SUCCESS,
  payload: {
    step: 3,
    isOpen: true,
  },
  track: {
    label: modal,
    action: 'success',
    category: 'upgrade',
  },
});

interface PayloadSuccess {
  userInfo: UserInfo,
  product: Product,
  paymentInfo: PaymentInfo,
  isAnnual: boolean,
}

export type FetchDetailSucess = ActionPayload<
'isFetching' | 'userInfo' | 'product' | 'paymentInfo' | 'isAnnual',
typeof types.FETCH_DETAIL_SUCCESS, AddonState
>;
export const fetchDetailSucess = (payload: PayloadSuccess): FetchDetailSucess => ({
  type: types.FETCH_DETAIL_SUCCESS,
  payload: {
    isFetching: false,
    userInfo: payload.userInfo,
    product: payload.product,
    paymentInfo: payload.paymentInfo,
    isAnnual: payload.isAnnual,
  },
});

export type FetchDetailFailure = ActionPayload<'isFetching' | 'errors', typeof types.FETCH_DETAIL_FAILURE, AddonState>;
export const fetchDetailFailure = (errors: ApiErrors[]): FetchDetailFailure => ({
  type: types.FETCH_DETAIL_FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export type FetchDetail = ActionPayload<'isFetching', typeof types.FETCH_DETAIL, AddonState>;
export const fetchDetail = (): FetchDetail => ({
  type: types.FETCH_DETAIL,
  payload: {
    isFetching: true,
  },
});

export type FetchCreate = ActionPayload<'isFetching', typeof types.FETCH_CREATE, AddonState>;
export const fetchCreate = (): FetchCreate => ({
  type: types.FETCH_CREATE,
  payload: {
    isFetching: true,
  },
});

export type FetchFeatureUser = ActionPayload<'isFetching', typeof types.FETCH_FEATURE_USER, AddonState>;
export const fetchFeatureUser = (): FetchFeatureUser => ({
  type: types.FETCH_FEATURE_USER,
  payload: {
    isFetching: true,
  },
});

export type FetchCreateSuccess = ActionPayload<'isFetching', typeof types.FETCH_CREATE_SUCCESS, AddonState>;
export const fetchCreateSuccess = (): FetchCreateSuccess => ({
  type: types.FETCH_CREATE_SUCCESS,
  payload: {
    isFetching: false,
  },
});

export type FetchCreateFailure = ActionPayload<'isFetching' | 'errors', typeof types.FETCH_CREATE_FAILURE, AddonState>;
export const fetchCreateFailure = (errors: ApiErrors[]): FetchCreateFailure => ({
  type: types.FETCH_CREATE_FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export type FetchNotification = ActionPayload<'isFetching', typeof types.FETCH_NOTIFICATION, AddonState>;
export const fetchNotification = (): FetchNotification => ({
  type: types.FETCH_NOTIFICATION,
  payload: {
    isFetching: true,
  },
});

export type FetchNotificationSuccess = ActionPayload<'isFetching', typeof types.FETCH_NOTIFICATION_SUCCESS, AddonState>;
export const fetchNotificationSuccess = (): FetchNotificationSuccess => ({
  type: types.FETCH_NOTIFICATION_SUCCESS,
  payload: {
    isFetching: false,
  },
});

export type FetchNotificationFailure = ActionPayload<'isFetching' | 'errors', typeof types.FETCH_NOTIFICATION_FAILURE, AddonState>;
export const fetchNotificationFailure = (errors: ApiErrors[]): FetchNotificationFailure => ({
  type: types.FETCH_NOTIFICATION_FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export type AddonConfirm = Action<typeof types.CONFIRM>;
export const addonConfirm = (): AddonConfirm => ({
  type: types.CONFIRM,
});

export type ClearErrors = ActionPayload<'errors', typeof types.CLEAR_ERRORS, AddonState>;
export const clearErrors = (): ClearErrors => ({
  type: types.CLEAR_ERRORS,
  payload: {
    errors: [],
  },
});

export type Reload = Action<typeof types.RELOAD>;
export const reload = (): Reload => ({ type: types.RELOAD });

export type Initialize = Action<typeof types.INITIALIZE>;
export const initialize = (): Initialize => ({ type: types.INITIALIZE });

export type AddonActions =
| OpenDetail
| CloseDetail
| SetStep
| SetStepSuccess
| FetchDetail
| FetchDetailSucess
| FetchDetailFailure
| FetchCreate
| FetchCreateSuccess
| FetchCreateFailure
| FetchFeatureUser
| FetchNotification
| FetchNotificationSuccess
| FetchNotificationFailure;
