import React, { useEffect, useState } from 'react';

import {
  checkAwsIncident,
  missingRecordsZipFiles,
} from 'features/banner-data-download/services';
import {
  Container,
  TextContent,
  ActionContent,
  OuterContainer,
  ShowOnlyScreenReader,
} from './BannerDataDownload.styles';

export default function BannerDataDownload() {
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    let ignore = false;
    setIsBannerVisible(false);
    checkAwsIncident()
      .then((result) => {
        const { data } = result;
        if (!ignore) {
          setIsBannerVisible(data.show_banner);
        }
      })
      .catch(() => {
        setIsBannerVisible(false);
      });

    return () => {
      ignore = true;
    };
  }, []);

  const redirectToBannerUrl = () => {
    if (isDownloading) return;

    setIsDownloading(true);
    missingRecordsZipFiles()
      .then((result) => {
        const { data } = result;
        window.location.href = data.url;
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  if (!isBannerVisible) {
    return null;
  }

  return (
    <OuterContainer>
      <Container onClick={() => redirectToBannerUrl()}>
        <TextContent>
          <ShowOnlyScreenReader>
            <h3>Sente falta de algum prontuário?</h3>
            <p>
              Baixe os prontuários em PDF para consulta. Em breve os dados do
              dia 15 a 18 vão voltar de forma automática.
            </p>
            <p>Clique para baixar prontuários</p>
          </ShowOnlyScreenReader>
        </TextContent>
        <ActionContent />
      </Container>
    </OuterContainer>
  );
}
