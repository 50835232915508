import React from 'react';

import Dropdown from 'ui/new/dropdown';
import { ReturnFlow } from 'state/marketing/emailFlow/patient/types';

interface Props {
  onClick: (flow: ReturnFlow) => void,
  flow: ReturnFlow,
}

export default function DropdownReturnFlowItems({
  onClick,
  flow,
}: Props): JSX.Element {
  return (
    <Dropdown.Item
      key={`${flow.id}-return-item`}
      id={flow.id}
      onClick={() => onClick(flow)}
    >
      {flow.name}
    </Dropdown.Item>
  );
}
