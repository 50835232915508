import { captureException } from '@sentry/browser';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  fetchClinicPhysicians,
  fetchTelemedicineReport,
} from 'features/reports/services/telemedicineReport';
import {
  FetchTelemedicineReportsParams,
  FilterParams,
  PhysiciansResponse,
  TelemedicineRecordResponse,
} from './types';
import * as actions from './reducer';
import { getClinicId, getFilter } from './selectors';

type PayloadChangePage = ReturnType<
  typeof actions.changePageTelemedicineReports
>;
type PayloadFetchPhysicians = ReturnType<typeof actions.fetchPhysicians>;
type PayloadFetchTelemedicineReports = ReturnType<
  typeof actions.fetchTelemedicineReports
>;

export function* fetchClinicPhysiciansWorker({
  payload,
}: PayloadFetchPhysicians) {
  try {
    yield put(actions.isFetchingPhysicians());
    const clinicId: number = yield select(getClinicId);
    const { data }: PhysiciansResponse = yield call(fetchClinicPhysicians, {
      clinicId,
      physicianName: payload.physicianName || '',
    });
    const physicians = data.objects.map(({ id, profile }) => ({
      value: id,
      name: profile.name,
    }));

    yield put(actions.fetchPhysiciansSuccess(physicians));
  } catch (e) {
    yield call(captureException, e);
    yield put(actions.fetchPhysiciansFailed());
  }
}

export function* filterTableWorker(params: FilterParams) {
  try {
    yield put(actions.isFetchingReports());
    const clinicId: number = yield select(getClinicId);
    const fetchParams: FetchTelemedicineReportsParams = { clinicId, ...params };

    const {
      data: { data },
    }: TelemedicineRecordResponse = yield call(
      fetchTelemedicineReport,
      fetchParams,
    );
    yield put(actions.fetchTelemedicinesReportsSuccess(data));
  } catch (e) {
    yield put(actions.fetchTelemedicinesReportsFailed());
  }
}

export function* fetchTelemedicineReportsWorker({
  payload,
}: PayloadFetchTelemedicineReports) {
  try {
    const currentFilter: FilterParams = yield select(getFilter);
    const newFilter = { ...currentFilter, ...payload, pageNumber: 1 };
    yield call(filterTableWorker, newFilter);

    yield put(actions.updateFilter(newFilter));
  } catch (e) {
    yield call(captureException, e);
  }
}

export function* resetDateFilterWorker() {
  try {
    const { physicianId }: FilterParams = yield select(getFilter);

    const newFilter: FilterParams = { pageNumber: 1 };

    if (physicianId) {
      newFilter.physicianId = physicianId;
    }

    yield call(filterTableWorker, newFilter);

    yield put(actions.updateFilter(newFilter));
  } catch (e) {
    yield call(captureException, e);
  }
}

export function* resetPhysicianFilterWorker() {
  try {
    const { toDate, fromDate }: FilterParams = yield select(getFilter);

    const newFilter: FilterParams = { pageNumber: 1 };

    if (toDate && fromDate) {
      newFilter.toDate = toDate;
      newFilter.fromDate = fromDate;
    }

    yield call(filterTableWorker, newFilter);

    yield put(actions.updateFilter(newFilter));
  } catch (e) {
    yield call(captureException, e);
  }
}

export function* changePageReportsWorker({ payload }: PayloadChangePage) {
  try {
    const currentFilter: FilterParams = yield select(getFilter);

    const newFilter = { ...currentFilter, pageNumber: payload };

    yield call(filterTableWorker, newFilter);
    yield put(actions.updateFilter(newFilter));
  } catch (e) {
    yield call(captureException, e);
  }
}

export function* telemedicineReportSagas() {
  yield all([
    takeLatest(
      actions.fetchTelemedicineReports,
      fetchTelemedicineReportsWorker,
    ),
    takeLatest(actions.changePageTelemedicineReports, changePageReportsWorker),
    takeLatest(actions.fetchReportsWithNoDate, resetDateFilterWorker),
    takeLatest(actions.fetchReportsWithNoPhysician, resetPhysicianFilterWorker),
    takeLatest(actions.fetchPhysicians, fetchClinicPhysiciansWorker),
  ]);
}
