import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Heading } from '@iclinic/design-system';

import { SpaceBetweenBox } from 'features/tissInvoicing/components/ui/Flexbox';
import { useInfiniteScroll } from 'features/tissInvoicing/hooks';
import { actions } from 'features/tissInvoicing/state/expense';
import { getExpenseListState } from 'features/tissInvoicing/state/expense/selectors';
import EmptyExpenses from './EmptyExpenses';
import * as Styles from './styles';
import Table from './Table';

function ExpenseList() {
  const { expenses, loading, hasMoreData } = useSelector(getExpenseListState);
  const dispatch = useDispatch();

  const { infiniteScrollRef, pageNum } = useInfiniteScroll({
    hasMoreData,
    loading,
  });

  const fetchExpenseList = useCallback(() => {
    dispatch(actions.fetchExpenseList({ page: pageNum }));
  }, [dispatch, pageNum]);

  useEffect(() => {
    fetchExpenseList();
  }, [fetchExpenseList]);

  return (
    <Styles.Container>
      <Styles.Header>
        <Heading variant="lg">Lista de Outras despesas</Heading>
      </Styles.Header>

      <Styles.Content>
        <SpaceBetweenBox paddingX={2}>
          {!expenses || (expenses.length === 0 && !loading) ? (
            <EmptyExpenses />
          ) : (
            <Table infiniteScrollRef={infiniteScrollRef} />
          )}
        </SpaceBetweenBox>
      </Styles.Content>
    </Styles.Container>
  );
}

export default ExpenseList;
