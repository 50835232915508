import {
  Box,
  DatePicker,
  MaterialUICore,
  Select,
  TextField,
  tokens,
} from '@iclinic/design-system';

const { styled, Modal } = MaterialUICore;

const defaultWidth = 464;

const flexRowJustified = {
  display: 'flex',
  justifyContent: 'space-between',
};

export const CustomModal = styled(Modal)({});

export const CustomModalContainer = styled(Box)({
  overflow: 'auto',
  height: '100%',
  background: 'rgba(0,0,0, 0.2)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  padding: tokens.spacing.sm,
});

export const CustomModalHeader = styled('div')({
  ...flexRowJustified,
  '& > h2': {
    marginTop: 0,
    color: `${tokens.color.neutral[700]} !important`,
    fontSize: `${tokens.font.size.sm} !important`,
    fontWeight: tokens.font.weight.bold,
  },
  '& > button': {
    border: 'none',
    color: tokens.color.neutral[500],
    fontWeight: tokens.font.weight.bold,
    fontSize: tokens.font.size.xxs,
    '&:hover': {
      background: 'none',
    },
  },
});

export const ModalContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: tokens.spacing.xs,
  padding: tokens.spacing.sm,
  backgroundColor: tokens.color.neutral['0'],
  borderRadius: tokens.border.radius.sm,
});

export const CustomTextField = styled(TextField)({
  width: defaultWidth,
  border: `${tokens.border.width.hairline} solid ${tokens.color.neutral[100]}`,
  '& > label': {
    color: tokens.color.primary.main,
  },
});

export const CustomSelect = styled(Select)({
  width: defaultWidth,
  border: `${tokens.border.width.hairline} solid ${tokens.color.neutral[100]}`,
  '&[data-paymenttype="paymentType"] > label': {
    color: tokens.color.primary.main,
  },
});

export const CustomDatePicker = styled(DatePicker)({
  width: defaultWidth,
  border: `${tokens.border.width.hairline} solid ${tokens.color.neutral[100]}`,
  '& > div': {
    paddingRight: tokens.spacing.base,
  },
  '& > div > svg': {
    color: tokens.color.neutral[500],
  },
});

export const TotalContainer = styled('div')({
  ...flexRowJustified,
  flexDirection: 'column',
  fontFamily: tokens.font.family.base,
  fontSize: tokens.font.size.xs,
  color: tokens.color.neutral[700],
  marginTop: tokens.spacing.md,
  '& > div': {
    ...flexRowJustified,
    ...flexRowJustified,
    marginBottom: tokens.spacing.xs,
  },
  '& > div:nth-child(1)': {
    fontWeight: tokens.font.weight.bold,
    fontSize: tokens.font.size.sm,
    marginBottom: tokens.spacing.sm,
  },
  '& > div.payment_remaining': {
    color: tokens.color.feedback.error.main,
    '& svg': {
      fontSize: tokens.font.size.xs,
    },
  },
});

export const CustomFooterContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: tokens.spacing.sm,
  marginTop: tokens.spacing.md,
});
