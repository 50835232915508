// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import style from './Message.scss';

const Message = ({
  img,
  title,
  message,
  alt,
}) => (
  <div className={style.message}>
    <div className={style.message__img}>
      <img src={img} alt={alt} />
    </div>
    <div className={style.message__description}>
      <p className={style.message__title}>{title}</p>
      <p className={style.message__content}>{message}</p>
    </div>
  </div>
);

Message.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Message;
