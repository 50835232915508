import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles(() => ({
  textField: {
    '& .MuiInputBase-input.MuiFilledInput-input': {
      padding: 0,
    },
  },
}));
