import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/new/modal';
import Button from 'ui/new/button';
import style from './ConfirmDeleteModal.scss';

const ConfirmDeleteSelectedModal = ({
  count,
  onCloseClick,
  onDeleteSelectedCampaign,
}) => (
  <div className={style.modal}>
    <Modal
      show
      type="danger"
      title={
        count > 1
          ? `Excluir ${count} campanhas selecionadas`
          : `Excluir ${count} campanha selecionada`
      }
      onClose={onCloseClick}
      classes={style}
      width={440}
    >
      <Modal.Body classes={style}>
        <p>Esta ação não poderá ser revertida.</p>
      </Modal.Body>
      <Modal.Footer align="right">
        <Button type="error" onClick={onDeleteSelectedCampaign}>
          EXCLUIR
        </Button>
        <Button type="light" onClick={onCloseClick}>
          {' '}
          CANCELAR{' '}
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
);

ConfirmDeleteSelectedModal.propTypes = {
  count: PropTypes.number.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onDeleteSelectedCampaign: PropTypes.func.isRequired,
};

export default ConfirmDeleteSelectedModal;
