import { ChangeEventHandler, FocusEventHandler } from 'react';

import { RequestStatus } from 'shared/constants/State';

export interface Clinic {
  address: string;
  number: number;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  zip_code: string;
}
export interface Professional {
  [key: string]: any;
  rqe: string;
  council_name: string;
  council_number: string;
  council_region: string;
  cbo: number;
  profile: {
    cpf: string;
  };
}

export interface CFMUpdatePayload {
  clinic: Clinic | null;
  professional: Professional | null;
}

export enum CFMUpdateSteps {
  none = '',
  start = 'start',
  clinic = 'clinic',
  professional = 'professional',
  done = 'done',
  close = 'close',
}

export interface CFMUpdateState extends CFMUpdatePayload {
  step: CFMUpdateSteps;
  status: RequestStatus;
  clinicUpdateDone: boolean;
  professionalUpdateDone: boolean;
  error: string;
}

export interface UpdateStepProps {
  onClose: () => void;
}

export interface FormStepContainerProps extends UpdateStepProps {
  title: React.ReactNode;
  children: React.ReactNode;
}

export interface SelectProps {
  handleChange: ChangeEventHandler;
  error: boolean;
  value: string | number;
  disabled: boolean;
  helperText?: string | boolean;
  handleBlur?: FocusEventHandler;
}

export interface StateSelectProps extends SelectProps {
  name: string;
  label: string;
}

export interface ProfessionalFormValues {
  cpf: string;
  rqe: string;
  council_name: string;
  council_number: string;
  council_region: string;
  cbo: string;
}

export interface ProfessionalPayload
  extends Omit<ProfessionalFormValues, 'cbo'> {
  cbo: number | string;
}

export interface ClinicFormValues extends Omit<Clinic, 'number'> {
  number: string;
}

export interface UpdateClinicService {
  (clinicId: string, values: ClinicFormValues): Promise<any>;
}
