import { all, call, put, takeLatest } from 'redux-saga/effects';
import { Response } from '@types';
import uuid from 'uuid';

import * as actions from '.';
import * as api from '../../../services';
import { ContractorsResponse } from 'features/messaging/services/types';
import { contractorUpdateMessages } from 'features/messaging/constants';

type PayloadFetchDetails = ReturnType<
  typeof actions.fetchMessagePackageDetails
>;
type PayloadFetchContractorDetails = ReturnType<
  typeof actions.fetchContractorTemplateDetails
>;
export type PayloadSetContractorTemplateEvents = ReturnType<
  typeof actions.setContractorTemplateEvents
>;

export function* fetchMessagePackageDetailsWorker({
  payload: physicianId,
}: PayloadFetchDetails) {
  try {
    yield put(actions.fetchMessagePackageDetailsStart());

    const { data: consumptions } = yield call(
      api.getConsumptionsDetails,
      physicianId,
    );

    const { data: paymentSummary } = yield call(
      api.getPaymentSummary,
      physicianId,
    );

    yield put(
      actions.fetchMessagePackageDetailsSuccess({
        consumptions,
        paymentSummary,
      }),
    );
  } catch {
    yield put(actions.fetchMessagePackageDetailsFailure());
  }
}

export function* fetchContractorTemplateDetailsWorker({
  payload,
}: PayloadFetchContractorDetails) {
  try {
    yield put(actions.fetchContractorTemplateDetailsStart());

    const { data }: Response<ContractorsResponse> = yield call(
      api.getContractorTemplates,
      payload,
    );

    yield put(
      actions.fetchContractorTemplateDetailsSuccess({
        meta: data.meta,
        contractorTemplates: data.objects,
      }),
    );
  } catch {
    yield put(actions.fetchContractorTemplateDetailsFailure());
  }
}

export function* setContractorTemplateEventsWorker({
  payload: eventStatus,
}: PayloadSetContractorTemplateEvents) {
  try {
    yield put(actions.setContractorTemplateEventsStart());

    yield call(api.setContractorTemplateEvents, eventStatus);

    yield put(actions.setContractorTemplateEventsSuccess());
    yield put(
      actions.addContractorUpdateMessage({
        id: uuid.v4(),
        message: contractorUpdateMessages.success,
        isSuccess: true,
      }),
    );
  } catch (error) {
    yield put(actions.setContractorTemplateEventsFailure());
    yield put(
      actions.addContractorUpdateMessage({
        id: uuid.v4(),
        message: contractorUpdateMessages.failed,
        isSuccess: false,
      }),
    );
  }
}

export default function* messagingSelectPhysiciansSagas() {
  yield all([
    takeLatest(
      actions.fetchMessagePackageDetails,
      fetchMessagePackageDetailsWorker,
    ),
    takeLatest(
      actions.fetchContractorTemplateDetails,
      fetchContractorTemplateDetailsWorker,
    ),
    takeLatest(
      actions.setContractorTemplateEvents,
      setContractorTemplateEventsWorker,
    ),
  ]);
}
