import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { MessageFreeSamplesResult } from '../types';
import {
  AvailableFilter,
  GetSampleBoxesParams,
  SampleBox,
} from '../../services/types';

export interface SampleBoxesState {
  availableBoxes?: {
    status?: RequestStatus;
    boxes?: SampleBox[];
  };
  requestedBoxes?: {
    status?: RequestStatus;
    boxes?: SampleBox[];
  };
  offCanvasOpen?: boolean;
  selectedBox?: SampleBox;
  filters?: {
    pharmaceuticals?: AvailableFilter[];
    statusPharmaceuticals?: RequestStatus;
    activePrinciples?: AvailableFilter[];
    statusActivePrinciples?: RequestStatus;
  };
  suggestion?: {
    status?: RequestStatus;
    suggestions?: string[];
  };
  message?: MessageFreeSamplesResult;
}

export const initialState: SampleBoxesState = {
  availableBoxes: {
    status: RequestStatus.Pending,
  },
  requestedBoxes: {
    status: RequestStatus.Pending,
  },
  filters: {
    statusPharmaceuticals: RequestStatus.Pending,
    statusActivePrinciples: RequestStatus.Pending,
  },
};

export const nameStore = '@webapp/sample-boxes';

export const fetchAvailableSampleBoxes = createAction<GetSampleBoxesParams>(
  `${nameStore}/fetchAvailableSampleBoxes`,
);

export const fetchRequestedSampleBoxes = createAction<GetSampleBoxesParams>(
  `${nameStore}/fetchRequestedSampleBoxes`,
);

export const fetchAvailablePharmaceuticals = createAction(
  `${nameStore}/fetchAvailablePharmaceuticals`,
);

export const fetchAvailableActivePrinciples = createAction(
  `${nameStore}/fetchAvailableActivePrinciples`,
);

export const fetchSuggestions = createAction<string>(
  `${nameStore}/fetchSuggestions`,
);

const SampleBoxesSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchAvailableSampleBoxesStart: (state): SampleBoxesState => ({
      ...state,
      availableBoxes: {
        ...state.availableBoxes,
        status: RequestStatus.Pending,
      },
    }),
    fetchAvailableSampleBoxesSuccess: (
      state,
      action: PayloadAction<SampleBox[]>,
    ): SampleBoxesState => ({
      ...state,
      availableBoxes: {
        status: RequestStatus.Success,
        boxes: action.payload,
      },
    }),
    fetchAvailableSampleBoxesFailure: (state): SampleBoxesState => ({
      ...state,
      availableBoxes: {
        ...state.availableBoxes,
        status: RequestStatus.Error,
      },
    }),
    fetchRequestedSampleBoxesStart: (state): SampleBoxesState => ({
      ...state,
      requestedBoxes: {
        ...state.requestedBoxes,
        status: RequestStatus.Pending,
      },
    }),
    fetchRequestedSampleBoxesSuccess: (
      state,
      action: PayloadAction<SampleBox[]>,
    ): SampleBoxesState => ({
      ...state,
      requestedBoxes: {
        status: RequestStatus.Success,
        boxes: action.payload,
      },
    }),
    fetchRequestedSampleBoxesFailure: (state): SampleBoxesState => ({
      ...state,
      requestedBoxes: {
        ...state.requestedBoxes,
        status: RequestStatus.Error,
      },
    }),
    selectSampleBox: (state, action: PayloadAction<SampleBox>) => ({
      ...state,
      offCanvasOpen: true,
      selectedBox: action.payload,
    }),
    unselectSampleBox: (state) => ({
      ...state,
      offCanvasOpen: false,
      selectedBox: undefined,
    }),
    fetchAvailablePharmaceuticalsStart: (state): SampleBoxesState => ({
      ...state,
      filters: {
        ...state.filters,
        statusPharmaceuticals: RequestStatus.Pending,
      },
    }),
    fetchAvailablePharmaceuticalsSuccess: (
      state,
      action: PayloadAction<AvailableFilter[]>,
    ): SampleBoxesState => ({
      ...state,
      filters: {
        ...state.filters,
        statusPharmaceuticals: RequestStatus.Success,
        pharmaceuticals: action.payload,
      },
    }),
    fetchAvailablePharmaceuticalsFailure: (state): SampleBoxesState => ({
      ...state,
      filters: {
        ...state.filters,
        statusPharmaceuticals: RequestStatus.Error,
      },
    }),
    fetchAvailableActivePrinciplesStart: (state): SampleBoxesState => ({
      ...state,
      filters: {
        ...state.filters,
        statusActivePrinciples: RequestStatus.Pending,
      },
    }),
    fetchAvailableActivePrinciplesSuccess: (
      state,
      action: PayloadAction<AvailableFilter[]>,
    ): SampleBoxesState => ({
      ...state,
      filters: {
        ...state.filters,
        statusActivePrinciples: RequestStatus.Success,
        activePrinciples: action.payload,
      },
    }),
    fetchAvailableActivePrinciplesFailure: (state): SampleBoxesState => ({
      ...state,
      filters: {
        ...state.filters,
        statusActivePrinciples: RequestStatus.Error,
      },
    }),
    fetchSuggestionsStart: (state): SampleBoxesState => ({
      ...state,
      suggestion: { status: RequestStatus.Pending },
    }),
    fetchSuggestionsSuccess: (
      state,
      action: PayloadAction<string[]>,
    ): SampleBoxesState => ({
      ...state,
      suggestion: {
        status: RequestStatus.Success,
        suggestions: action.payload,
      },
    }),
    fetchSuggestionsFailure: (state): SampleBoxesState => ({
      ...state,
      suggestion: {
        status: RequestStatus.Error,
        suggestions: undefined,
      },
    }),
    clearSuggestions: (state): SampleBoxesState => ({
      ...state,
      suggestion: undefined,
    }),
    updateResultMessage: (
      state,
      action: PayloadAction<MessageFreeSamplesResult>,
    ) => ({
      ...state,
      message: action.payload,
    }),
    clearResultMessage: (state) => ({
      ...state,
      message: undefined,
    }),
  },
});

export const {
  fetchAvailableSampleBoxesStart,
  fetchAvailableSampleBoxesSuccess,
  fetchAvailableSampleBoxesFailure,
  fetchRequestedSampleBoxesStart,
  fetchRequestedSampleBoxesSuccess,
  fetchRequestedSampleBoxesFailure,
  selectSampleBox,
  unselectSampleBox,
  fetchAvailablePharmaceuticalsStart,
  fetchAvailablePharmaceuticalsSuccess,
  fetchAvailablePharmaceuticalsFailure,
  fetchAvailableActivePrinciplesStart,
  fetchAvailableActivePrinciplesSuccess,
  fetchAvailableActivePrinciplesFailure,
  fetchSuggestionsStart,
  fetchSuggestionsSuccess,
  fetchSuggestionsFailure,
  clearSuggestions,
  updateResultMessage,
  clearResultMessage,
} = SampleBoxesSlice.actions;

export default SampleBoxesSlice.reducer;
