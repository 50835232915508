import * as Yup from 'yup';

import { isValidFullDate } from 'shared/utils/validation';

export const solicitorSchema = Yup.object().shape({
  hired_solicitant_name: Yup.string()
    .nullable()
    .max(70, 'Até 70 caracteres - Texto livre'),
  hired_solicitant_code_type: Yup.string().nullable(),
  hired_solicitant_code: Yup.string().nullable().max(14, 'Até 14 caracteres'),
  professional_solicitant_name: Yup.string()
    .nullable()
    .max(70, 'Até 70 caracteres - Texto livre'),
  professional_solicitant_council: Yup.string().nullable(),
  professional_solicitant_council_number: Yup.string()
    .nullable()
    .max(15, 'Até 15 caracteres'),
  professional_solicitant_council_state: Yup.string().nullable(),
  professional_solicitant_cbo: Yup.number().nullable(),
  solicitor_service_type: Yup.string().nullable(),
  request_date: Yup.mixed()
    .nullable()
    .test(
      'isValidFullDate',
      'Data inválida',
      (value) => !value || isValidFullDate(value),
    ),
  clinical_indication: Yup.string()
    .nullable()
    .max(150, 'Até 150 caracteres - Texto livre'),
});

export const solicitorSectionValues = {
  hired_solicitant_name: null,
  hired_solicitant_code: null,
  hired_solicitant_code_type: null,
  provider_code: null,
  professional_solicitant_name: null,
  professional_solicitant_council: '',
  professional_solicitant_council_number: null,
  professional_solicitant_council_state: '',
  professional_solicitant_cbo: 0,
  solicitor_service_type: 0,
  request_date: null,
  clinical_indication: null,
  special_coverage: null,
};
