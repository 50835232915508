import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import {
  Box,
  Button,
  DatePicker,
  Grid,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';

import {
  fetchFilterTransactions,
  fetchTransactionsXLS,
} from 'features/onlinePayment/state/reports/transactions';
import useStyles from './DateFilter.styles';
import { getTransactionsXLSSelector } from 'features/onlinePayment/state/reports/transactions/selectors';
import { ErrorAlert } from 'shared/components';

const { CircularProgress } = MaterialUICore;
const { GetApp } = MaterialIcons;

export default function DateFilter(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fetching: isExportingXLS, transactionsXLSFetchError } = useSelector(
    getTransactionsXLSSelector,
  );

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    validateForm,
    submitForm,
    resetForm,
  } = useFormikContext<any>();

  const handleChangeDate = (fieldName: string, value: Date | null) => {
    setFieldValue(fieldName, value, true);
  };

  const handleTouchedDate = (fieldName: string) => {
    if (values[fieldName] != null) {
      setFieldTouched(fieldName, true);
      return;
    }
    setFieldTouched(fieldName, false);
  };

  const handleSubmitForm = () => {
    validateForm()
      .then(() => submitForm())
      .catch(() => {});
  };

  const handleResetForm = () => {
    const filters = {
      startDate: null,
      endDate: null,
    };
    resetForm();
    dispatch(fetchFilterTransactions(filters));
  };

  const handleExportButton = () => {
    dispatch(fetchTransactionsXLS());
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-start"
      spacing={3}
    >
      <Grid container item xs={12} md={10} spacing={2}>
        <Grid item xs={12} md={4}>
          <DatePicker
            id="startDate"
            name="startDate"
            label="Data início"
            value={values.startDate}
            onChange={(value) => handleChangeDate('startDate', value)}
            onBlur={() => handleTouchedDate('startDate')}
            placeholder="Ex.: 01/08/2021"
            error={!!(errors.startDate && touched.startDate)}
            helperText={touched.startDate && errors.startDate}
            allowKeyboard
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            id="endDate"
            name="endDate"
            label="Data fim"
            value={values.endDate}
            onChange={(value) => handleChangeDate('endDate', value)}
            onBlur={() => handleTouchedDate('endDate')}
            placeholder="Ex.: 10/01/2022"
            error={!!(errors.endDate && touched.endDate)}
            helperText={touched.endDate && errors.endDate}
            allowKeyboard
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            className={classes.filtersButtons}
            color="secondary"
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            onClick={handleSubmitForm}
          >
            Buscar
          </Button>
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            className={classes.filtersButtons}
            size="small"
            color="transparent"
            onClick={handleResetForm}
          >
            Limpar
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={2}>
        <Button
          className={classes.filtersButtons}
          color="transparent"
          fullWidth
          size="large"
          startIcon={
            isExportingXLS ? <CircularProgress size={20} /> : <GetApp />
          }
          variant="outlined"
          disabled={isExportingXLS}
          onClick={handleExportButton}
        >
          Exportar
        </Button>
      </Grid>
      {transactionsXLSFetchError && (
        <Box display="flex" flexGrow={1} mt={3}>
          <ErrorAlert
            message={
              <span
                dangerouslySetInnerHTML={{
                  __html: transactionsXLSFetchError,
                }}
              />
            }
          />
        </Box>
      )}
    </Grid>
  );
}
