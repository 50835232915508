import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { PatientsByEventsRawData, PatientsByEventsRequestData } from './types';
import { PATIENTS_BY_EVENTS_FETCH_ERROR } from './errorMessages';

export interface PatientsByEventsState {
  patientsByEvents?: {
    filters?: PatientsByEventsRequestData | null;
    data?: PatientsByEventsRawData | null;
    status?: RequestStatus;
    error?: string;
  };
}

export const initialState: PatientsByEventsState = {};

const name = 'patientsByEvents';

const PatientsByEventsSlice = createSlice({
  name,
  initialState,
  reducers: {
    fetchPatientsByEvents: (
      state,
      action: PayloadAction<PatientsByEventsRequestData>,
    ): PatientsByEventsState => ({
      ...state,
      patientsByEvents: {
        filters: action.payload,
        status: RequestStatus.Pending,
      },
    }),
    fetchPatientsByEventsSuccess: (
      state,
      action: PayloadAction<PatientsByEventsRawData>,
    ): PatientsByEventsState => ({
      ...state,
      patientsByEvents: {
        data: action.payload,
        status: RequestStatus.Success,
      },
    }),
    fetchPatientsByEventsFailure: (state): PatientsByEventsState => ({
      ...state,
      patientsByEvents: {
        status: RequestStatus.Error,
        error: PATIENTS_BY_EVENTS_FETCH_ERROR,
      },
    }),
  },
});

const { reducer } = PatientsByEventsSlice;

export const {
  fetchPatientsByEvents,
  fetchPatientsByEventsSuccess,
  fetchPatientsByEventsFailure,
} = PatientsByEventsSlice.actions;

export default reducer;
