// External
import { connect } from 'react-redux';

// Internal
import { cidLabels, searchNameDropdownCID } from 'state/campaign/constants';
import * as selectors from 'state/campaign/selectors';
import FilterTags from '../components/audiences/FilterTags';

const title = 'Pacientes diagnosticados com CID';

const renderItemLabel = (cid) => (cidLabels[cid] || `desconhecido (${cid})`);

const mapStateToProps = (state) => ({
  availableItems: selectors.getAvailableFilterCID(state),
  title,
  renderItemLabel,
  searchName: searchNameDropdownCID,
});

export default connect(mapStateToProps)(FilterTags);
