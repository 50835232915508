import { useFormikContext } from 'formik';
import { animateScroll } from 'react-scroll';

import useDidUpdateEffect from './useDidUpdateEffect';

/**
 * This custom hook is useful for scrolling to the first field in error when a Formik is submitted.
 *
 * It must be used inside a Formik
 */
export const useScrollToErrorFormik = () => {
  const context = useFormikContext();
  if (!context) {
    console.warn('useScrollToErrorFormik must be used inside a Formik ');
  }

  const { isSubmitting, isValidating, errors } = context || {};
  const OFFSET = -100;

  useDidUpdateEffect(() => {
    const keys = Object.keys(errors);
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const bodyRect = document.body.getBoundingClientRect();
      const errorElement = document.querySelector('.Mui-error');
      if (!errorElement) return;

      const { top } = errorElement.getBoundingClientRect();
      animateScroll.scrollTo(top - bodyRect.top + OFFSET);
    }
  }, [isSubmitting, isValidating, errors]);
};

/**
 * This component is a wrapper to allow you to use the ** useScrollToErrorFormik ** hook as a component.
 *
 * It must be used inside a Formik
 *
 * @returns null
 */
export const ScrollToErrorFormik = () => {
  useScrollToErrorFormik();

  return null;
};
