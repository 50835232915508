// External
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Internal
import { Button } from '@iclinic/iclinic-ui';
import Modal from 'ui/new/modal';
import * as actions from 'state/video/actions';
import VideoResponsive from '../components/VideoResponsive';

const VideoContainer = ({
  // store
  isFetching,
  isOpen,
  // actions
  closeDetail,
}) => (
  <VideoResponsive>
    {(styles) => (
      <Modal
        classes={styles}
        title="Videoteca"
        show={isOpen}
        onClose={closeDetail}
        showCloseIcon
      >
        <Modal.Body classes={styles}>
          <iframe
            title="video"
            src="https://player.understand.com/iclinic/pt-BR"
            border="0"
            frameBorder="0"
            scrolling="no"
            allowFullScreen
            className={styles.iframe}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={closeDetail}
            kind="primary"
            loaderKind="gray-1"
            loader={isFetching}
            disabled={isFetching}
            type="submit"
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    )}
  </VideoResponsive>
);

VideoContainer.defaultProps = {
  isFetching: false,
  isOpen: false,
};

VideoContainer.propTypes = {
  isFetching: PropTypes.bool,
  isOpen: PropTypes.bool,
  closeDetail: PropTypes.func.isRequired,
};

export default connect((state) => state.video, actions)(VideoContainer);
