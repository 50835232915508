// Internal
import * as types from './types';
import { products } from './constants';

export const detailOpen = (currentModal, hiring) => ({
  type: types.DETAIL.OPEN,
  payload: {
    currentModal,
    hiring,
    selectedProducts: [],
  },
  track: {
    label: products[currentModal],
    action: 'open',
    category: 'addon',
  },
});

export const detailClose = (currentModal) => ({
  type: types.DETAIL.CLOSE,
  payload: {
    step: 1,
    errors: [],
    isOpen: false,
  },
  track: {
    label: products[currentModal],
    action: 'close',
    category: 'addon',
  },
});

export const setStep = (step, currentModal) => ({
  type: types.SET_STEP.ACTION,
  payload: {
    step,
  },
  track: {
    label: products[currentModal],
    action: 'step',
    category: 'addon',
  },
});

export const setStepSuccess = (
  hiring,
  addon,
  step,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  totalUsers = 0,
  selectedProducts,
) => ({
  type: types.SET_STEP.SUCCESS,
  payload: {
    step,
    isOpen: true,
    hiring,
    totalUsers,
    currentModal: addon,
    selectedProducts,
  },
});

export const fetchPhysicians = () => ({ type: types.FETCH_PHYSICIANS.ACTION });

export const fetchPhysiciansSuccess = (response) => ({
  type: types.FETCH_PHYSICIANS.SUCCESS,
  payload: {
    isOpen: true,
    subscriptionId: response.subscriptionId,
    physicians: response.physicians,
    selectedPhysicians: [],
    totalUsers: response.totalUsers,
    step: response.step,
    calculateUpgrade: null,
    error: false,
  },
});

export const fetchPhysiciansFailure = () => ({
  type: types.FETCH_PHYSICIANS.FAILURE,
  payload: {
    error: true,
  },
});

export const fetchCalculatePrice = (selectedUsers) => ({
  type: types.FETCH_CALCULATE_PRICE.ACTION,
  payload: {
    totalUsers: selectedUsers.length,
    selectedPhysicians: selectedUsers,
    isFetching: true,
  },
});

export const fetchCalculatePriceSuccess = (calculateUpgrade) => ({
  type: types.FETCH_CALCULATE_PRICE.SUCCESS,
  payload: {
    calculateUpgrade,
    isFetching: false,
  },
});

export const fetchCalculatePriceFailure = (errors) => ({
  type: types.FETCH_CALCULATE_PRICE.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export const selectPhysician = (physicianId) => ({
  type: types.SELECT_PHYSICIAN.ACTION,
  payload: {
    physicianId,
  },
});

export const selectPhysicianSuccess = (physicians) => ({
  type: types.SELECT_PHYSICIAN.SUCCESS,
  payload: {
    physicians,
  },
});

export const selectPhysicianFailure = (errors) => ({
  type: types.SELECT_PHYSICIAN.FAILURE,
  payload: {
    errors,
  },
});

export const fetchSummary = () => ({
  type: types.FETCH_SUMMARY.ACTION,
  payload: {
    isFetching: true,
  },
});

export const fetchSummarySuccess = (summary) => ({
  type: types.FETCH_SUMMARY.SUCCESS,
  payload: {
    summary,
    isFetching: false,
  },
});

export const fetchSummaryFailure = (errors) => ({
  type: types.FETCH_SUMMARY.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export const fetchAddons = () => ({
  type: types.FETCH_ADDONS.ACTION,
  payload: {
    isFetching: true,
  },
});

export const fetchAddonsSuccess = (addons) => ({
  type: types.FETCH_ADDONS.SUCCESS,
  payload: {
    isOpen: true,
    addons,
    isFetching: false,
  },
});

export const fetchCreate = () => ({
  type: types.FETCH_CREATE.ACTION,
  payload: {
    isFetching: true,
  },
});

export const fetchCreateSuccess = () => ({
  type: types.FETCH_CREATE.SUCCESS,
  payload: {
    isFetching: false,
  },
});

export const fetchAddonsFailure = (errors) => ({
  type: types.FETCH_ADDONS.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export const fetchCreateFailure = (errors) => ({
  type: types.FETCH_CREATE.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export const selectProducts = (productId) => ({
  type: types.SELECT_PRODUCT.ACTION,
  payload: {
    productId,
    isFetching: true,
  },
});

export const selectProductsSuccess = (addons, selectedProducts) => ({
  type: types.SELECT_PRODUCT.SUCCESS,
  payload: {
    addons: { products: addons },
    selectedProducts,
    isFetching: false,
  },
});

export const selectProductsFailure = (errors) => ({
  type: types.SELECT_PRODUCT.FAILURE,
  payload: {
    errors,
    isFetching: false,
  },
});

export const fetchNotification = (slug) => ({
  type: types.FETCH_NOTIFICATION.ACTION,
  payload: {
    isFetching: true,
  },
  params: {
    modal: slug,
    product: slug,
  },
});

export const fetchNotificationSuccess = () => ({
  type: types.FETCH_NOTIFICATION.SUCCESS,
  payload: {
    isFetching: false,
    step: 5,
    errors: [],
  },
});

export const fetchNotificationFailure = (errors) => ({
  type: types.FETCH_NOTIFICATION.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export const fetchAddonsDetail = () => ({
  type: types.FETCH_ADDONS_DETAIL.ACTION,
  payload: {
    isFetching: true,
  },
});

export const fetchAddonsDetailSuccess = (productDetail) => ({
  type: types.FETCH_ADDONS_DETAIL.SUCCESS,
  payload: {
    isFetching: false,
    productDetail,
  },
});

export const fetchAddonsDetailFailure = (errors) => ({
  type: types.FETCH_ADDONS_DETAIL.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export const closeInstruction = () => ({ type: types.INSTRUCTION.CLOSE });

export const reload = () => ({ type: types.RELOAD.ACTION });

export const initialize = () => ({ type: types.INITIALIZE.ACTION });

export const goToIclinicAssist = () => ({
  type: types.GO_TO_ICLINIC_ASSIST.ACTION,
});

export const justOpenModal = () => ({
  type: types.JUST_OPEN_MODAL.ACTION,
  payload: {
    isOpen: true,
  },
});
