import React from 'react';

import style from './Row.scss';

type RowProps = {
  children: React.ReactNode
};

function Row({
  children,
}: RowProps): JSX.Element {
  return (
    <tr className={style.row}>
      {children}
    </tr>
  );
}

export default Row;
