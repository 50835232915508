import { Guide, SimplifiedGuides } from 'features/tissInvoicing/types';

export const calculateTotalValue = (guides: Guide[] | SimplifiedGuides[]) => {
  const mapGuides = guides.map((guide) => guide.total_amount);

  return mapGuides.reduce((a, b) => {
    const value = Number(b.replace(',', '.'));
    if (Number.isNaN(value)) return +a;

    return +a + +value;
  }, 0);
};
