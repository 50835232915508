import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const { spacing } = tokens;

export default makeStyles((theme) => ({
  totalInput: {
    marginRight: spacing.xs,
    width: '132px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '1em 0',
    },
  },
}));
