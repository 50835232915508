import React, { useCallback, useEffect, useState } from 'react';
import { Alert, MaterialIcons, Snackbar } from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';

import { RequestStatus } from 'shared/constants/State';
import {
  getSubmitReminderTemplateMessageStatus,
  getSubmitReminderErrorMessage,
} from '../../../state/scheduleReminder/personalization/selectors';
import { cleanSubmitMessageReminder } from 'features/messaging/state/scheduleReminder/personalization';

const { Warning, CheckCircleOutline } = MaterialIcons;

const MessagesSnackbar = () => {
  const status = useSelector(getSubmitReminderTemplateMessageStatus);
  const messageReminder = useSelector(getSubmitReminderErrorMessage);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (messageReminder) {
      setIsSnackbarOpen(true);
    }

    return () => {
      if (messageReminder) {
        dispatch(cleanSubmitMessageReminder());
      }
    };
  }, [messageReminder, dispatch]);

  const handleCloseMessage = useCallback(() => {
    setIsSnackbarOpen(false);
    dispatch(cleanSubmitMessageReminder());
  }, [dispatch]);

  if (!messageReminder) return null;

  const isSubmitError = status === RequestStatus.Error;

  return (
    <Snackbar
      open={isSnackbarOpen}
      onClose={handleCloseMessage}
      autoHideDuration={6000}
      severity={isSubmitError ? 'error' : 'success'}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert
        severity={isSubmitError ? 'error' : 'success'}
        icon={isSubmitError ? <Warning /> : <CheckCircleOutline />}
        message={messageReminder}
      />
    </Snackbar>
  );
};

export default MessagesSnackbar;
