import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';

import { Alert, TextField, Box, MaterialIcons } from '@iclinic/design-system';
import { ChargeBackForm } from 'features/onlinePayment/state/manage/types';
import { initialValues } from './validationSchema';

const { ErrorOutline } = MaterialIcons;

const error = false;

export default function Fields(): JSX.Element {
  const { handleChange, values, errors, touched } = useFormikContext<
    ChargeBackForm
  >();

  const getErrorText = useCallback(
    (field: keyof typeof initialValues) =>
      touched[field] && errors[field] ? errors[field] : null,
    [touched, errors],
  );

  const verifyError = useCallback(
    (field: keyof typeof initialValues) => !!errors[field] && !!touched[field],
    [touched, errors],
  );

  return (
    <>
      {!!error && (
        <Box mb={2}>
          <Alert severity="error" icon={<ErrorOutline />} message={error} />
        </Box>
      )}
      <TextField
        id="chargeBackAmount"
        type="tel"
        name="chargeBackAmount"
        label="Valor do estorno"
        placeholder="R$ 0,00"
        value={values.chargeBackAmount}
        onChange={handleChange}
        error={verifyError('chargeBackAmount')}
        helperText={getErrorText('chargeBackAmount')}
        fullWidth
      />
    </>
  );
}
