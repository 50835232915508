import React from 'react';
import { useFormikContext } from 'formik';

import { DatePicker } from '@iclinic/design-system';
import { GuideSADTForm } from 'features/tissInvoicing/types';

type Identifiers =
  | 'patient_insurance_expiry_date'
  | 'authorization_date'
  | 'authorization_expiration_date'
  | 'request_date';

interface DatePickerSADTProps {
  label: string;
  identifier: Identifiers;
  required?: boolean;
}

function DatePickerSADT({
  label,
  identifier,
  required = false,
}: DatePickerSADTProps) {
  const {
    setFieldValue,
    values,
    errors,
    touched,
    handleBlur,
  } = useFormikContext<GuideSADTForm>();

  const handleChangeDate = (date: Date | null) => {
    setFieldValue(identifier, date);
  };

  return (
    <DatePicker
      label={label}
      id={identifier}
      name={identifier}
      placeholder="DD/MM/AAAA"
      value={values[identifier]}
      onChange={handleChangeDate}
      onBlur={handleBlur}
      error={!!(errors[identifier] && touched[identifier])}
      helperText={touched[identifier] && errors[identifier]}
      required={required}
      allowKeyboard
      fullWidth
    />
  );
}

export default DatePickerSADT;
