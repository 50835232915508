import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LatestDiagnosesSliceState } from '../types';
import { getActionTypes } from 'shared/utils/getActionTypes';

export const initialState: LatestDiagnosesSliceState = {
  latestDiagnosesError: false,
  latestDiagnosesLoading: false,
  latestDiagnoses: [],
};

const storeName = '@webapp/latestDiagnoses';

const latestDiagnosesSlice = createSlice({
  name: storeName,
  initialState,
  reducers: {
    getLatestDiagnoses: (state, action: PayloadAction<string>) => ({
      ...state,
      latestDiagnosesLoading: true,
      patientId: action.payload,
    }),
    getLatestDiagnosesSuccess: (state, action: PayloadAction<string[]>) => ({
      ...state,
      latestDiagnosesLoading: false,
      latestDiagnosesError: false,
      latestDiagnoses: action.payload,
    }),
    getLatestDiagnosesFailure: (state) => ({
      ...state,
      latestDiagnosesLoading: false,
      latestDiagnosesError: true,
      latestDiagnoses: [],
    }),
  },
});

export const actions = {
  ...latestDiagnosesSlice.actions,
};

export const actionTypes = getActionTypes(actions, storeName);

export default latestDiagnosesSlice.reducer;
