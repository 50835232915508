import {
  MaterialUICore,
  tokens,
  TextField as TextFieldDS,
  Box,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = tokens;

export const AnnotationContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}));

export const AlertContainer = styled(Box)(() => ({
  margin: spacing.sm,
}));

export const TextFieldContainer = styled(Box)(() => ({
  height: '100%',
}));

export const TextField = styled(TextFieldDS)(() => ({
  height: '100%',
  width: '100%',
  padding: spacing.sm,
  '& .MuiInputBase-root': {
    height: '100%',
    padding: 0,
    display: 'flex',
    alignItems: 'flex-start',
    border: 'none',
  },
  '& .MuiInputBase-input': {
    padding: spacing.base,
    height: '100%!important',
    overflow: 'auto!important',
  },
}));
