import axios, { AxiosResponse, AxiosError } from 'axios';
import { PromiseEnvelope } from '@types';

import { GENERIC_ERROR_MESSAGE } from 'features/video-conference/constants';
import {
  decodeJwtToken,
  getTelemedicineToken,
  isProd,
  updateTelemedicineStorage,
} from 'features/video-conference/utils';
import { captureException, captureMessage } from 'shared/utils/handlerErrors';
import { hasEnvelopedData } from 'features/video-conference/services/utils';
import { FetchRefreshTokenRawData } from 'features/video-conference/services/types';
import { interceptorConferenceApiRequestAuthentication } from 'features/video-conference/services/interceptors/request';

function unauthorizedInterceptor(error: AxiosError) {
  const { request, response } = error;

  if (isProd && response?.status === 401) {
    captureMessage(`Request: ${request?.responseURL} - forced logout`);
    return Promise.reject(response);
  }

  return null;
}

export const interceptorResponseTransformData = (response: AxiosResponse) => {
  try {
    const responseData = hasEnvelopedData(response)
      ? response.data.data
      : response.data;
    const getResponseData = {
      getResponseData: () => responseData,
    };
    return Object.assign(response, getResponseData);
  } catch (e) {
    captureException(e);
    return response;
  }
};

export const interceptorResponseErrors = async (error: AxiosError) => {
  if (!error.response) return error;
  try {
    await unauthorizedInterceptor(error);
  } catch (e) {
    captureException(e);
  }

  const responseErrors = error?.response?.data?.errors;
  const getResponseErrors = {
    errors: responseErrors || [{ message: GENERIC_ERROR_MESSAGE }],
  };

  return Object.assign(error.response, getResponseErrors);
};

const apiTelemedicineRefreshToken = axios.create({
  baseURL: process.env.ICLINIC_TELEMEDICINE_URL,
  headers: {
    authorization: 'Bearer iclinic',
  },
});

apiTelemedicineRefreshToken.interceptors.request.use(
  interceptorConferenceApiRequestAuthentication,
);
apiTelemedicineRefreshToken.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrors,
);

export const fetchRefreshToken =
  (): PromiseEnvelope<FetchRefreshTokenRawData> =>
    apiTelemedicineRefreshToken.get(`conference/refresh`);

export const interceptorResponseRefreshTokenErrors = async (
  error: AxiosError,
) => {
  const originalConfig = error.config;

  if (error.response?.status === 401) {
    try {
      const legacyToken = getTelemedicineToken();

      const responseRefreshToken = await fetchRefreshToken();

      const decodeToken = decodeJwtToken(legacyToken || '');

      const fetchRefreshTokenRawData = responseRefreshToken.getResponseData();

      updateTelemedicineStorage(
        fetchRefreshTokenRawData.physicianToken,
        fetchRefreshTokenRawData.patientToken,
        !!decodeToken?.isPhysician,
      );

      return await apiTelemedicineRefreshToken(originalConfig);
    } catch (_error) {
      return Promise.reject(_error);
    }
  }
  if (!error.response) return error;

  const responseErrors = error?.response?.data?.errors;
  const getResponseErrors = {
    errors: responseErrors || [{ message: GENERIC_ERROR_MESSAGE }],
  };

  return Object.assign(error.response, getResponseErrors);
};
