import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from '@iclinic/design-system';

import { getCurrentModal, isOpenModal } from 'state/upgrade/selectors';
import { closeUpgradeDetail, openProductsView } from 'state/upgrade/actions';
import { productViews } from 'shared/constants/views';
import { UPGRADE } from 'shared/constants/buttons';
import * as S from './InfoModal.styles';

export const InfoModal = () => {
  const currentModal = useSelector(getCurrentModal);
  const isOpen = useSelector(isOpenModal);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeUpgradeDetail(currentModal));
  };

  const handleOpenProductView = () => {
    dispatch(openProductsView());
  };

  return (
    <Dialog open={isOpen} onClose={handleCloseModal} maxWidth="xs">
      <DialogHeader
        onClose={handleCloseModal}
        title={productViews[currentModal].title}
      />

      <DialogContent dividers>
        <S.Text variant="xs">{productViews[currentModal].text}</S.Text>

        {productViews[currentModal].youtubeUrl ? (
          <iframe
            title={`video-${productViews[currentModal].title}`}
            width="100%"
            height="258"
            src={`https://www.youtube.com/embed/${productViews[currentModal].youtubeUrl}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ) : (
          <img
            src={productViews[currentModal].imageUrl}
            alt={productViews[currentModal].alt}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button color="transparent" onClick={handleCloseModal}>
          Cancelar
        </Button>

        <Button
          onClick={handleOpenProductView}
          data-testid={`know-more-${currentModal as string}`}
          data-ga={productViews[currentModal].trackId}
          data-sp={productViews[currentModal].trackId}
        >
          {UPGRADE.Info}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
