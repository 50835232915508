import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Box, Button, Alert, MaterialIcons } from '@iclinic/design-system';
import Divider from 'ui/divider/Divider';
import { SubTitle, TitleCard } from '..';
import DeleteIntegrationModal from './DeleteIntegrationModal';
import formatMessage from '../../content';
import {
  removeIntegration,
  showCreateIntegrationModal,
} from 'features/settings/scheduleOnline/state/actions';
import {
  getUserData,
  getHasScheduleIntegration,
  getSettingsIntegration,
} from 'features/settings/scheduleOnline/state/selectors';
import CreateIntegrationModal from '../CreateIntegrationModal';

const { Warning } = MaterialIcons;

interface ManageIntegrationSectionProps {
  isCurrentUser: boolean;
  selectedConfigUserName: string;
}

function ManageIntegrationSection({
  isCurrentUser,
  selectedConfigUserName,
}: ManageIntegrationSectionProps) {
  const [showRemoveIntegration, setShowRemoveIntegration] = useState(false);
  const hideIntegrationModal = () => setShowRemoveIntegration(false);
  const showIntegrationModal = () => setShowRemoveIntegration(true);
  const dispatch = useDispatch();
  const userData = useSelector(getUserData);
  const hasScheduleIntegration = useSelector(getHasScheduleIntegration);
  const { showModal } = useSelector(getSettingsIntegration);

  const handleCreateIntegration = () => {
    dispatch(showCreateIntegrationModal());
  };

  const handleRemoveIntegration = () => {
    dispatch(removeIntegration(userData.physicianId));
  };

  const getIntegrationButtons = () => (
    <>
      {hasScheduleIntegration ? (
        <>
          <Box mt={2}>
            <Button
              type="button"
              color="secondary"
              onClick={showIntegrationModal}
              data-ga="open-remove-integration-modal"
            >
              {formatMessage({
                id: '@online-scheduling:integration-remove',
              })}
            </Button>
          </Box>
          <DeleteIntegrationModal
            show={showRemoveIntegration}
            hideModal={hideIntegrationModal}
            removeIntegration={handleRemoveIntegration}
          />
        </>
      ) : (
        <>
          <Box mt={2}>
            <Button
              type="button"
              color="secondary"
              onClick={handleCreateIntegration}
            >
              {formatMessage({
                id: '@online-scheduling:integration-make-integration',
              })}
            </Button>
          </Box>
          <CreateIntegrationModal isShown={showModal} />
        </>
      )}
    </>
  );

  return (
    <>
      <Divider />
      <TitleCard
        label={formatMessage({ id: '@online-scheduling:integration-title' })}
      />
      <SubTitle
        label={formatMessage({ id: '@online-scheduling:integration-subtitle' })}
      />
      {isCurrentUser ? (
        getIntegrationButtons()
      ) : (
        <Box mt={2}>
          <Alert
            severity="warning"
            icon={<Warning />}
            message={
              <>
                É necessário estar logado na conta do profissional{' '}
                <b>{selectedConfigUserName}</b> para ativar seu agendamento
                online
              </>
            }
          />
        </Box>
      )}
    </>
  );
}

export default ManageIntegrationSection;
