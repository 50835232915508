import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

import { SpaceBetweenBox } from 'features/tissInvoicing/components/ui/Flexbox';

const { styled } = MaterialUICore;
const { spacing } = axiomTokens;

export const Container = styled(SpaceBetweenBox)({
  width: '100%',
});

export const Actions = styled(SpaceBetweenBox)({
  columnGap: spacing['4'],
});
