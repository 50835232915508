import { all, takeLatest, call, put } from 'redux-saga/effects';
import { captureException } from '@sentry/browser';

import { actions } from './index';
import { getCalculators as getCalculatorsService } from './services';
import { GetCalculatorsByCategoryResponse } from './types';

const { getCalculators, getCalculatorsError, getCalculatorsSuccess } = actions;

export function* workerGetCalculator() {
  try {
    const { getResponseData } = yield call(getCalculatorsService);

    const calculatorsResponse: GetCalculatorsByCategoryResponse =
      getResponseData();

    yield put(getCalculatorsSuccess(calculatorsResponse));
  } catch (error) {
    captureException(error);
    yield put(getCalculatorsError());
  }
}

export default function* calculatorDrawerSagas() {
  yield all([takeLatest(getCalculators, workerGetCalculator)]);
}
