import React from 'react';
import classnames from 'classnames';

import { Chip, ChipSeverity } from '@iclinic/design-system';
import { SellerStatus } from 'features/onlinePayment/state/constants';
import * as S from './styles';

type StatusProps = { sellerStatus?: SellerStatus };

const labels = {
  [SellerStatus.Approved]: 'Aprovado',
  [SellerStatus.Submitted]: 'Em análise',
  [SellerStatus.Pending]: 'Pendente',
  [SellerStatus.Refused]: 'Negado',
  [SellerStatus.Deleted]: 'Deletado',
};

const severityMap: Record<string, ChipSeverity> = {
  [SellerStatus.Refused]: 'error',
  [SellerStatus.Approved]: 'success',
};

export default function StatusChip({ sellerStatus }: StatusProps) {
  const label = sellerStatus ? labels[sellerStatus] : 'Inexistente';
  const classes = S.useStyles();
  const severity = sellerStatus ? severityMap[sellerStatus] : undefined;

  return (
    <Chip
      label={label}
      size="small"
      severity={severity}
      className={classnames({
        [classes.info]:
          sellerStatus === SellerStatus.Submitted ||
          sellerStatus === SellerStatus.Pending,
      })}
    />
  );
}
