import { MaterialUICore, Button, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const ButtonDelete = styled(Button)({
  height: axiomTokens.spacing[8],
  outline: 'none',
  border: `1px solid ${axiomTokens.color.typeface.onInteractive.subtle}`,
  borderRadius: axiomTokens.border.radius.sm,
  color: axiomTokens.color.interactive.accent.default,
  marginBottom: axiomTokens.spacing[4],
});
