import { Body, Heading, MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles, styled } = MaterialUICore;
const { color, spacing } = tokens;

export default makeStyles(() => ({
  rotateIcon: {
    transform: 'rotate(180deg)',
  },
  listItem: {
    padding: `${spacing.base} ${spacing.md}`,
  },
}));

export const Root = styled('div')(() => ({
  border: `1px solid ${color.neutral[100]}`,
  borderRadius: 4,
}));

export const Content = styled('div')(() => ({
  padding: `${spacing.sm} ${spacing.sm} ${spacing.sm} ${spacing.lg}`,
}));

export const SectionTitle = styled(Heading)({
  marginBottom: spacing.xs,
});

export const SectionDescription = styled(Body)({
  marginLeft: spacing.base,
  marginBottom: spacing.sm,
});

export const StreamingWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: 148,
  position: 'relative',
  marginBottom: spacing.sm,

  [theme.breakpoints.up('sm')]: {
    height: 234,
  },

  '& > iframe': {
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    position: 'absolute',
  },
}));

export const LinkWrapper = styled('div')(() => ({
  marginTop: spacing.sm,
}));
