import { combineReducers } from 'redux';

import personalizationReducer, {
  PersonalizationState,
} from './personalization';

export type ScheduleReminderState = {
  personalization: PersonalizationState;
};

const scheduleReminderReducer = combineReducers<ScheduleReminderState>({
  personalization: personalizationReducer,
});

export default scheduleReminderReducer;
