// External
import React from 'react';
import Icon from '@material/react-material-icon';

// Internal
import Group from 'ui/new/group';
import { Row } from 'ui/new/flex';
import style from './Filters.scss';

type FilterProps = {
  children: React.ReactNode;
};

export default function Filter({ children }: FilterProps): JSX.Element {
  return (
    <Row className={style['filter-group']}>
      <Icon icon="keyboard_arrow_left" className={style.icon} />
      <Group>{children}</Group>
      <Icon icon="keyboard_arrow_right" className={style.icon} />
    </Row>
  );
}

Filter.Item = Group.Item;
