export const BENEFITS = [
  {
    name: 'Funções Básicas',
    slug: 'basic-functions',
    products: ['starter', 'plus', 'pro', 'premium'],
  },
  {
    name: 'Agendamento online',
    slug: 'online-scheduling',
    products: ['starter', 'plus', 'pro', 'premium'],
  },
  {
    name: 'Multiprocedimentos',
    slug: 'scheduling-procedures',
    products: ['starter', 'plus', 'pro', 'premium'],
  },
  {
    name: 'Relatórios',
    slug: 'reports',
    products: ['starter', 'plus', 'pro', 'premium'],
  },
  {
    name: 'R$ 0,09 por SMS',
    slug: 'sms',
    products: ['plus', 'pro', 'premium'],
  },
  {
    name: 'Logo em documentos',
    slug: 'logo-print',
    products: ['plus', 'pro', 'premium'],
  },
  {
    name: '100 SMS gratuitos mês',
    slug: 'sms-free',
    products: ['plus', 'pro', 'premium'],
  },
  {
    name: 'SMS com resposta*',
    slug: 'reply-sms',
    products: ['plus', 'pro', 'premium'],
  },
  {
    name: 'Chat interno',
    slug: 'internal-chat',
    products: ['plus', 'pro', 'premium'],
  },
  {
    name: 'E-mail aniversariantes',
    slug: 'birthday-email',
    products: ['plus', 'pro', 'premium'],
  },
  {
    name: 'Assinatura digital',
    slug: 'assinatura-digital',
    products: ['plus', 'pro', 'premium'],
  },
  {
    name: 'Lembrete de consulta por Whatsapp',
    slug: 'whatsapp-reminder',
    products: ['plus', 'pro', 'premium'],
  },
  {
    name: 'Confirmação de consulta por Whatsapp',
    slug: 'whatsapp-confirmation',
    products: ['plus', 'pro', 'premium'],
  },
  {
    name: 'Faturamento TISS',
    slug: 'financeiro-tiss',
    products: ['plus', 'pro', 'premium'],
  },
  {
    name: 'Pesquisa de Satisfação',
    slug: 'survey',
    products: ['plus', 'pro', 'premium'],
  },
  {
    name: 'Controle de estoque',
    slug: 'stock',
    products: ['pro', 'premium'],
  },
  {
    name: 'Controle financeiro',
    slug: 'finances',
    products: ['pro', 'premium'],
  },
  {
    name: 'Repasse financeiro',
    slug: 'finances-payment',
    products: ['pro', 'premium'],
  },
  {
    name: 'Relatórios financeiros',
    slug: 'finances-report',
    products: ['pro', 'premium'],
  },
  { name: 'Fluxos de retorno', slug: 'fluxos-retorno', products: ['premium'] },
  {
    name: 'Fluxos para plano de cuidado',
    slug: 'fluxos-cuidados',
    products: ['premium'],
  },
  {
    name: 'Campanhas de e-mail',
    slug: 'campanha-email',
    products: ['premium'],
  },
  {
    name: 'Teleconsultas grátis ilimitadas',
    slug: 'teleconsultas-gratuitas-ilimitadas',
    products: ['premium'],
  },
];

export const getPlanFromSlug = (slug) =>
  BENEFITS.find((benefit) => benefit.slug === slug);

export default 'BENEFITS';
