// External
import React, { useCallback, useMemo } from 'react';
import Icon from '@material/react-material-icon';

// Internal
import ActionableCard from 'ui/new/actionable-card';
import { ActionButtonGroup } from 'ui/new/action-buttons';
import { Switch } from 'ui/new/form';
import { EmailFlow, EmailFlowTarget } from 'state/marketing/emailFlow/emailFlowManagement/emailFlowTypes';
import { DuplicateParams, DeleteModalParams } from './ListEmailFlowItem';
import style from './ListEmailFlowItemMobile.scss';
import listStyle from './ListEmailFlow.scss';

interface StateProps {
  emailFlow: EmailFlow;
  editEmailFlow: (emailFlow: EmailFlow) => void;
  duplicateEmailFlow?: ({ id, name }: DuplicateParams) => void;
  deleteEmailFlow?: ({ id, name }: DeleteModalParams) => void;
  activeEmailFlowCard: string | undefined;
  setActiveFlowCard: ((id: string) => void) | undefined;
  changeStatusEmailFlow: (e: EmailFlowTarget) => void;
}

const ListEmailFlowItemMobile = ({
  emailFlow,
  editEmailFlow,
  duplicateEmailFlow,
  deleteEmailFlow,
  activeEmailFlowCard,
  setActiveFlowCard,
  changeStatusEmailFlow,
}: StateProps): JSX.Element => {
  const collapsed = activeEmailFlowCard !== emailFlow.id;
  const onHeaderClick = useCallback(() => {
    setActiveFlowCard(collapsed ? emailFlow.id : '');
  }, [emailFlow.id, collapsed, setActiveFlowCard]);

  const cardTitle: JSX.Element = useMemo(() => (
    <div className={style[collapsed ? 'inactive-title' : 'active-title']}>
      {emailFlow.name}
      <Icon
        icon={collapsed ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}
      />
    </div>
  ), [collapsed, emailFlow.name]);

  return (
    <div>
      <ActionableCard title={cardTitle} collapsed={collapsed} onHeaderClick={onHeaderClick}>
        <ActionableCard.Body>
          <div className={style['email-flow-items']}>
            <span>Status</span>
            <span className={emailFlow.active ? style['active-flow'] : undefined}>
              {emailFlow.active ? 'Ativo' : 'Rascunho'}
            </span>
          </div>
          <div className={style['email-flow-items']}>
            <span>Pacientes ativos</span>
            <span className={style['stats-label']}>
              {emailFlow.stats ? emailFlow.stats.activePatients : '-'}
            </span>
          </div>
          <div className={style['email-flow-items']}>
            <span>Finalizaram o fluxo</span>
            <span className={style['stats-label']}>
              {emailFlow.stats ? emailFlow.stats.finishedPatients : '-'}
            </span>
          </div>
        </ActionableCard.Body>
        <ActionableCard.Footer classes={style}>
          <Switch
            id={emailFlow.id}
            checked={emailFlow.active}
            value={emailFlow.id}
            onChange={changeStatusEmailFlow}
            classes={listStyle.columnSwitch}
          />
          <ActionButtonGroup
            target={emailFlow}
            onDelete={deleteEmailFlow}
            onEdit={editEmailFlow}
            onDuplicate={duplicateEmailFlow}
          />
        </ActionableCard.Footer>
      </ActionableCard>
    </div>
  );
};

export default ListEmailFlowItemMobile;
