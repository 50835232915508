import {
  TextField as DSTextField,
  MaterialUICore,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const TextField = styled(DSTextField)({
  width: '490px',
});
