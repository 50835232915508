import { useCallback, useEffect, useState } from 'react';
import { LocalVideoTrack, Room } from 'twilio-video';
import {
  GaussianBlurBackgroundProcessor,
  VirtualBackgroundProcessor,
  ImageFit,
  isSupported,
} from '@twilio/video-processors';

import { BackgroundSettings } from 'features/video-conference/constants/types';
import { image } from 'shared/utils/urlGetter';
import { RAW_IMAGES_PATH } from 'features/video-conference/constants';

const imageElements = new Map();
const getImage = (index: number): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    if (imageElements.has(index)) return resolve(imageElements.get(index));
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = image(RAW_IMAGES_PATH[index]);
    img.onload = () => {
      imageElements.set(index, img);
      resolve(img);
    };
    img.onerror = reject;
    return img;
  });
};

const virtualBackgroundAssets = image('videoConference.virtualBackground');
let blurProcessor: GaussianBlurBackgroundProcessor;
let virtualBackgroundProcessor: VirtualBackgroundProcessor;

export function useBackgroundSettings(
  videoTrack: LocalVideoTrack | undefined,
  room?: Room | null,
) {
  const [backgroundSettings, setBackgroundSettings] = useState<
    BackgroundSettings
  >({ type: 'none', index: 0 });

  const removeProcessor = useCallback(() => {
    if (videoTrack && videoTrack.processor)
      videoTrack.removeProcessor(videoTrack.processor);
  }, [videoTrack]);

  const addProcessor = useCallback(
    (
      processor: GaussianBlurBackgroundProcessor | VirtualBackgroundProcessor,
    ) => {
      if (!videoTrack || videoTrack.processor === processor) return;
      removeProcessor();
      videoTrack.addProcessor(processor);
    },
    [videoTrack, removeProcessor],
  );

  const loadBlurProcessorModel = async () => {
    blurProcessor = new GaussianBlurBackgroundProcessor({
      assetsPath: virtualBackgroundAssets,
      maskBlurRadius: 10,
      blurFilterRadius: 5,
    });
    await blurProcessor.loadModel();
  };

  const loadBackgroundProcessorModel = async () => {
    virtualBackgroundProcessor = new VirtualBackgroundProcessor({
      assetsPath: virtualBackgroundAssets,
      backgroundImage: await getImage(0),
      fitType: ImageFit.Cover,
    });
    await virtualBackgroundProcessor.loadModel();
  };

  useEffect(() => {
    if (!isSupported) return;

    const handleProcessorChange = async () => {
      if (!blurProcessor) loadBlurProcessorModel();
      if (!virtualBackgroundProcessor) loadBackgroundProcessorModel();
      if (!room?.localParticipant) return;

      if (backgroundSettings.type === 'blur') {
        addProcessor(blurProcessor);
        return;
      }

      if (
        backgroundSettings.type === 'image' &&
        typeof backgroundSettings.index === 'number'
      ) {
        virtualBackgroundProcessor.backgroundImage = await getImage(
          backgroundSettings.index,
        );
        addProcessor(virtualBackgroundProcessor);
        return;
      }

      removeProcessor();
    };

    handleProcessorChange();
  }, [backgroundSettings, videoTrack, room, addProcessor, removeProcessor]);

  return { backgroundSettings, setBackgroundSettings };
}
