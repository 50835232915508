import React, { useEffect, useRef, useState } from 'react';

import {
  InputAdornment,
  MaterialUICore,
  MaterialIcons,
  Snackbar,
} from '@iclinic/design-system';
import {
  buttonsTitle,
  TYPE_HERE,
  ALLOWED_FILE_TYPES,
} from 'features/video-conference/constants';
import { MESSAGE_TYPE } from 'features/video-conference/constants/types';
import { isMobile } from 'features/video-conference/utils';
import { useHandleSendDataChat } from 'features/video-conference/hooks/useHandleSendDataChat';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { ChatInputContainer, IconButton, Input, TextField } from './styles';

const { CircularProgress } = MaterialUICore;
const { Send, AttachFile } = MaterialIcons;

interface ChatInputProps {
  isChatWindowOpen: boolean;
}

export default function ChatInput({ isChatWindowOpen }: ChatInputProps) {
  const {
    handleSendFile,
    isSendingFile,
    handleSend,
    fileSendError,
    setFileSendError,
    isValidFileForUpload,
  } = useHandleSendDataChat();
  const { isPhysician, conferenceId } = useConference();
  const { localParticipant, remoteParticipant } = useParticipantContext();
  const [messageBody, setMessageBody] = useState('');
  const isValidMessage = /\S/.test(messageBody);

  const textInputRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isChatWindowOpen) textInputRef.current?.focus();
  }, [isChatWindowOpen]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageBody(event.target.value);
  };

  const handleMessage = (message: string) => {
    if (!isValidMessage) return;
    handleSend(message, null, MESSAGE_TYPE.TEXT);
    setMessageBody('');
  };

  const handleFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    if (!files) return;

    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'telemedicine_share_file',
    });

    Array.from(files).forEach((file) => {
      if (!isValidFileForUpload(file)) return;
      handleSendFile(file);
    });
  };

  const handleReturnKeyPress = (event: React.KeyboardEvent) => {
    if (
      !isMobile &&
      !isSendingFile &&
      event.key === 'Enter' &&
      !event.shiftKey
    ) {
      event.preventDefault();
      handleMessage(messageBody);
    }
  };

  return (
    <ChatInputContainer>
      <Snackbar
        severity="error"
        open={Boolean(fileSendError)}
        message={fileSendError || ''}
        onClose={() => setFileSendError(null)}
        autoHideDuration={10000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        data-testid="snackbar"
      />

      <TextField
        aria-label="chat-input"
        placeholder={TYPE_HERE}
        onKeyPress={handleReturnKeyPress}
        onChange={handleChange}
        fullWidth
        multiline
        maxRows={4}
        value={messageBody}
        inputRef={textInputRef}
        disabled={isSendingFile}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" variant="standard">
              <IconButton
                onClick={() => fileInputRef.current?.click()}
                title={
                  isSendingFile ? buttonsTitle.ATTACHING : buttonsTitle.ATTACH
                }
              >
                {isSendingFile ? (
                  <CircularProgress size="1em" />
                ) : (
                  <AttachFile />
                )}
              </IconButton>
            </InputAdornment>
          ),

          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => handleMessage(messageBody)}
                title={buttonsTitle.SEND}
                color="primary"
                disabled={!isValidMessage}
              >
                <Send />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Input
        ref={fileInputRef}
        type="file"
        defaultValue=""
        title="file"
        accept={ALLOWED_FILE_TYPES}
        onChange={handleFile}
        multiple
      />
    </ChatInputContainer>
  );
}
