import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { TemplateMessage } from '../../../services/types';
import {
  PreviewActionParams,
  SubmitSingleScheduleTemplateData,
} from '../../types';

export interface PersonalizationState {
  fetcherTemplateStatus?: RequestStatus;
  templateMessage?: TemplateMessage;
  submitTemplateStatus?: RequestStatus;
  submitMessage?: string;
  fetcherPreviewStatus?: RequestStatus;
  previewMessage?: string;
}

export const initialState: PersonalizationState = {};

export const nameStore =
  '@webapp/messaging/scheduleConfirmation/personalization';

export const fetchConfirmationTemplateMessage = createAction<number>(
  `${nameStore}/fetchConfirmationTemplateMessage`,
);

export const submitConfirmationTemplateMessage =
  createAction<SubmitSingleScheduleTemplateData>(
    `${nameStore}/submitConfirmationTemplateMessage`,
  );

export const fetchConfirmationPreviewMessage =
  createAction<PreviewActionParams>(
    `${nameStore}/fetchConfirmationPreviewMessage`,
  );

const PersonalizationSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchConfirmationTemplateMessageStart: (state) => ({
      ...state,
      fetcherTemplateStatus: RequestStatus.Pending,
    }),
    fetchConfirmationTemplateMessageSuccess: (
      state,
      action: PayloadAction<TemplateMessage>,
    ) => ({
      ...state,
      fetcherTemplateStatus: RequestStatus.Success,
      templateMessage: action.payload,
    }),
    fetchConfirmationTemplateMessageFailure: (state) => ({
      ...state,
      fetcherTemplateStatus: RequestStatus.Error,
    }),
    submitConfirmationTemplateMessageStart: (state) => ({
      ...state,
      submitTemplateStatus: RequestStatus.Pending,
      submitMessage: undefined,
    }),
    submitConfirmationTemplateMessageSuccess: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      submitTemplateStatus: RequestStatus.Success,
      submitMessage: action.payload,
    }),
    submitConfirmationTemplateMessageFailure: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      submitTemplateStatus: RequestStatus.Error,
      submitMessage: action.payload,
    }),
    fetchConfirmationPreviewMessageStart: (state) => ({
      ...state,
      fetcherPreviewStatus: RequestStatus.Pending,
      previewMessage: undefined,
    }),
    fetchConfirmationPreviewMessageSuccess: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      fetcherPreviewStatus: RequestStatus.Success,
      previewMessage: action.payload,
    }),
    fetchConfirmationPreviewMessageFailure: (state) => ({
      ...state,
      fetcherPreviewStatus: RequestStatus.Error,
    }),
    cleanSubmitMessageConfirmation: (state) => ({
      ...state,
      submitMessage: undefined,
    }),
  },
});

export const {
  fetchConfirmationTemplateMessageStart,
  fetchConfirmationTemplateMessageFailure,
  fetchConfirmationTemplateMessageSuccess,
  submitConfirmationTemplateMessageFailure,
  submitConfirmationTemplateMessageStart,
  submitConfirmationTemplateMessageSuccess,
  fetchConfirmationPreviewMessageFailure,
  fetchConfirmationPreviewMessageStart,
  fetchConfirmationPreviewMessageSuccess,
  cleanSubmitMessageConfirmation,
} = PersonalizationSlice.actions;

export default PersonalizationSlice.reducer;
