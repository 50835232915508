import { Select } from '@iclinic/design-system';
import React, { PropsWithChildren } from 'react';
import { useFormikContext } from 'formik';

import { GuideSADTForm } from 'features/tissInvoicing/types';

type Identifiers = keyof GuideSADTForm;

interface SelectFieldSADTProps {
  identifier: Identifiers;
  iconClass?: string;
  label?: string;
}

function SelectFieldSADT({
  identifier,
  children,
  label,
  iconClass,
  ...restOfProps
}: PropsWithChildren<SelectFieldSADTProps>) {
  const { handleChange, values, errors, touched, handleBlur } =
    useFormikContext<GuideSADTForm>();

  return (
    <Select
      id={identifier}
      name={identifier}
      label={label}
      value={values[identifier]}
      onChange={handleChange}
      error={!!(errors[identifier] && touched[identifier])}
      helperText={touched[identifier] && errors[identifier]}
      onBlur={handleBlur}
      SelectProps={{
        classes: {
          icon: iconClass,
        },
      }}
      {...restOfProps}
    >
      {children}
    </Select>
  );
}

export default SelectFieldSADT;
