import { all, fork } from 'redux-saga/effects';

import checkoutInfosSagas from './infos/sagas';
import checkoutPaymentSagas from './payment/sagas';
import successPaymentSagas from './success/sagas';

export default function* checkoutSagas() {
  yield all([
    fork(checkoutInfosSagas),
    fork(checkoutPaymentSagas),
    fork(successPaymentSagas),
  ]);
}
