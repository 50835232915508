import React from 'react';

import style from './Head.scss';

type HeadProps = {
  children: React.ReactNode
};

function Head({
  children,
}: HeadProps): JSX.Element {
  return (
    <thead className={style.head}>
      {children}
    </thead>
  );
}

export default Head;
