import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import {
  RedeemReferralsAward,
  ReferralsAward,
} from '../../services/referrals/types';
import { ReferralsState } from './types';

export { ReferralsState } from './types';

export const initialState: ReferralsState = {
  awards: {
    status: RequestStatus.Ready,
  },
  redeemAward: {
    status: RequestStatus.Ready,
  },
};

const name = 'referrals';

export const fetchReferralsAwards = createAction(
  `${name}/fetchReferralsAwards`,
);

export const triggerRedeemReferralsAward = createAction<{
  awardId: number;
  data: RedeemReferralsAward;
}>(`${name}/redeemReferralsAward`);

const ReferralsSlice = createSlice({
  name,
  initialState,
  reducers: {
    fetchReferralsAwardsStart: (state): ReferralsState => ({
      ...state,
      awards: {
        ...state.awards,
        status: RequestStatus.Pending,
      },
    }),
    fetchReferralsAwardsSuccess: (
      state,
      action: PayloadAction<{
        available: ReferralsAward[];
        availableCount: number;
        extract: ReferralsAward[];
        extractCount: number;
      }>,
    ): ReferralsState => ({
      ...state,
      awards: {
        status: RequestStatus.Success,
        available: action.payload.available,
        availableCount: action.payload.availableCount,
        extract: action.payload.extract,
        extractCount: action.payload.extractCount,
      },
    }),
    fetchReferralsAwardsFailure: (state): ReferralsState => ({
      ...state,
      awards: {
        status: RequestStatus.Error,
      },
    }),
    redeemReferralsAwardStart: (
      state,
      action: PayloadAction<{
        awardId: number;
      }>,
    ): ReferralsState => ({
      ...state,
      redeemAward: {
        status: RequestStatus.Pending,
        awardIdRedeeming: action.payload.awardId,
      },
    }),
    redeemReferralsAwardSuccess: (state): ReferralsState => ({
      ...state,
      redeemAward: {
        status: RequestStatus.Success,
        awardIdRedeeming: undefined,
      },
    }),
    redeemReferralsAwardFailure: (state): ReferralsState => ({
      ...state,
      redeemAward: {
        status: RequestStatus.Error,
        awardIdRedeeming: undefined,
      },
    }),
  },
});

export default ReferralsSlice.reducer;

export const {
  fetchReferralsAwardsStart,
  fetchReferralsAwardsSuccess,
  fetchReferralsAwardsFailure,
  redeemReferralsAwardStart,
  redeemReferralsAwardSuccess,
  redeemReferralsAwardFailure,
} = ReferralsSlice.actions;
