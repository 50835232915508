// External
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

// Internal
import CampaignAudienceFilter from './CampaignAudienceFilter';
// Style
import style from './CampaignAudienceFilterArray.scss';

const CampaignAudienceFilterArray = ({ fields, getComponentForFilter }) => (
  <ul className={style.campaignAudienceFilterArray__listItems}>
    {fields.map((audience, index) => (
      <Field
        key={audience}
        component={CampaignAudienceFilter}
        name={audience}
        onDelete={() => fields.remove(index)}
        getComponentForFilter={getComponentForFilter}
      />
    ))}
  </ul>
);

CampaignAudienceFilterArray.propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
  getComponentForFilter: PropTypes.func.isRequired,
};

export default CampaignAudienceFilterArray;
