import React, { memo } from 'react';

import { MaterialUICore, Body } from '@iclinic/design-system';

const { TableCell } = MaterialUICore;

interface ColumnProps {
  name: string;
  align: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  size: 'small' | 'medium';
}

export const columns: ColumnProps[] = [
  { name: 'Lote', align: 'left', size: 'small' },
  { name: 'Convênio', align: 'left', size: 'small' },
  { name: 'Data', align: 'left', size: 'small' },
  { name: 'Executante', align: 'left', size: 'medium' },
  { name: 'Qtde.', align: 'left', size: 'small' },
  { name: 'Valor (R$)', align: 'left', size: 'small' },
  { name: 'Visualizar', align: 'center', size: 'small' },
];

const Columns = () => {
  return (
    <>
      {columns.map((c) => (
        <TableCell align={c.align} key={c.name} size={c.size}>
          <Body variant="xs">{c.name}</Body>
        </TableCell>
      ))}
    </>
  );
};

export default memo(Columns);
