import { call, put, select, takeLatest } from 'redux-saga/effects';
import { Response } from '@types';
import { AxiosError } from 'axios';

import { RequestStatus } from 'shared/constants/State';
import * as actions from '.';
import * as services from '../../services';
import * as selectors from './selectors';
import { Physician, PlansSuccess, Product } from '../../services/types';
import {
  normalizeSubscriptionData,
  setAddonsForSingleUser,
} from './normalizer';
import { PhysiciansByAddon, StepTypes } from './types';
import { getErrorsMessage, getResponseErrors } from 'features/checkout/utils';
import { dispatchGaEvent } from 'shared/utils/googleAnalytics';
import { resetTurnstile } from 'shared/utils/turnstile';
import { SIGNUP_PAYWALL_KEY } from 'features/new-auth/signup/constants';

type SelectAddonWorkerPayload = ReturnType<typeof actions.selectAddonTrigger>;
type SubscriptionWorkerPayload = ReturnType<typeof actions.triggerSubscription>;

export function* fetchCheckoutDataWorker() {
  try {
    const clinicId: number = yield select(selectors.getClinicId);

    const { data: products }: Response<PlansSuccess> = yield call(
      services.getPlans,
    );
    const { data: physicians } = yield call(
      services.getPhysiciansByClinic,
      clinicId,
    );

    yield put(actions.fetchCheckoutDataSuccess({ products, physicians }));
  } catch (error) {
    yield put(actions.fetchCheckoutDataFailure());
  }
}

export function* selectAddonWorker({
  payload: selectedAddon,
}: SelectAddonWorkerPayload) {
  const selectedAddons: Product[] = yield select(selectors.getSelectedAddons);
  const selectedAddonsCopy = [...selectedAddons];

  const index = selectedAddonsCopy.findIndex(
    (addon) => addon.id === selectedAddon.id,
  );

  if (index >= 0) {
    selectedAddonsCopy.splice(index, 1);
    yield put(actions.updateSelectedAddons(selectedAddonsCopy));
  } else {
    yield put(
      actions.updateSelectedAddons([...selectedAddonsCopy, selectedAddon]),
    );
  }
}

export function* subscriptionWorker({
  payload: subscriptionFormData,
}: SubscriptionWorkerPayload) {
  try {
    yield put(actions.updateSubscriptionStatus(RequestStatus.Pending));

    const isAnnual: boolean = yield select(selectors.getRecurrence);
    const selectedPlan: Product = yield select(selectors.getSelectedPlan);
    const physicians: Physician[] = yield select(selectors.getPhysicians);
    const physicianByAddon: PhysiciansByAddon | undefined = yield select(
      selectors.getPhysicianByAddon,
    );
    const selectedAddons: Product[] = yield select(selectors.getSelectedAddons);

    const hasSinglePhysician = physicians.length === 1;
    const addonsForSinglePhysician =
      hasSinglePhysician && selectedAddons.length > 0;
    const addons = addonsForSinglePhysician
      ? setAddonsForSingleUser({ selectedAddons, physicians })
      : physicianByAddon;

    const subscribeData = normalizeSubscriptionData({
      isAnnual,
      physicians,
      selectedPlan,
      subscriptionFormData,
      physicianByAddon: addons,
    });

    yield call(services.postSubscribe, subscribeData);

    yield put(actions.updateSubscriptionStatus(RequestStatus.Success));
    yield put(actions.changeSubscriptionStep());
    yield call([localStorage, 'removeItem'], SIGNUP_PAYWALL_KEY);
    yield call(dispatchGaEvent, 'checkout_complete');
  } catch (error) {
    resetTurnstile();
    const errors = getResponseErrors(error as AxiosError);
    const errorsMessage = getErrorsMessage(errors);
    yield put(actions.updateSubscriptionStatus(RequestStatus.Error));
    yield put(actions.setErrorsMessage(errorsMessage));
  }
}

const MIN_PHYSICIANS_COUNT = 2;

export function* verifyStepBeginWorker() {
  const physicians: Physician[] = yield select(selectors.getPhysicians);
  const selectedAddons: Product[] = yield select(selectors.getSelectedAddons);

  const stepMapper: string[] = [];

  if (selectedAddons?.length && physicians?.length >= MIN_PHYSICIANS_COUNT) {
    stepMapper.push(...selectedAddons.map((addon) => addon.slug));
  }

  stepMapper.push(StepTypes.PAYMENT, StepTypes.SUCCESS);

  const initialStep = stepMapper[0];

  yield put(actions.updateSubscriptionStepMapper(stepMapper));
  yield put(actions.updateCurrentSubscriptionStep(initialStep));
}

export function* changeSubscriptionStepWorker() {
  const stepMapper: string[] = yield select(
    selectors.getSubscriptionStepMapper,
  );
  const currentStep: string = yield select(
    selectors.getCurrentSubscriptionStep,
  );

  const indexOfCurrentStep = stepMapper.findIndex(
    (step) => step === currentStep,
  );
  const nextStep = stepMapper[indexOfCurrentStep + 1];

  yield put(actions.updateCurrentSubscriptionStep(nextStep));
}

export default function* plansSagas() {
  yield takeLatest(actions.fetchCheckoutData, fetchCheckoutDataWorker);
  yield takeLatest(actions.selectAddonTrigger, selectAddonWorker);
  yield takeLatest(actions.triggerSubscription, subscriptionWorker);
  yield takeLatest(actions.triggerVerifyInitialStep, verifyStepBeginWorker);
  yield takeLatest(
    actions.changeSubscriptionStep,
    changeSubscriptionStepWorker,
  );
}
