import React from 'react';
import classnames from 'classnames';
import { Box } from '@iclinic/design-system';

import { useChatContext } from 'features/video-conference/state/chatContext';
import { useWindowHeight, isMobile } from 'features/video-conference/utils';
import ParticipantTracks from '../ParticipantTrack';
import WaitingParticipant from '../Feedback/WaitingParticipant/index';
import useStyles from './styles';
import useMainParticipant from 'features/video-conference/hooks/useMainParticipant';
import RemoteParticipantInfo from '../RemoteParticipantInfo';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useAnnotationContext } from 'features/video-conference/state/annotationContext';
import useParticipantIsReconnecting from 'features/video-conference/hooks/useParticipantIsReconnecting';
import ReconnectingParticipant from '../Feedback/ReconnectingParticipant';

const RemoteParticipantVideo = (): JSX.Element => {
  const { room, isBackgroundSelectionOpen } = useConference();
  const { isChatWindowOpen } = useChatContext();
  const { isAnnotationWindowOpen } = useAnnotationContext();
  const mainParticipant = useMainParticipant();
  const height = useWindowHeight();
  const localParticipant = room?.localParticipant;
  const isParticipantReconnecting =
    useParticipantIsReconnecting(localParticipant);
  const classes = useStyles({ height });
  const isDrawerOpen =
    isChatWindowOpen || isAnnotationWindowOpen || isBackgroundSelectionOpen;

  const renderContent = () => {
    if (!mainParticipant && !isParticipantReconnecting)
      return <WaitingParticipant />;
    if (isParticipantReconnecting) return <ReconnectingParticipant />;

    return (
      <RemoteParticipantInfo
        participant={mainParticipant}
        isDrawerOpen={isDrawerOpen}
      >
        <ParticipantTracks
          participant={mainParticipant}
          enableScreenShare
          videoOnly
          isLocalParticipant={mainParticipant === localParticipant}
        />
      </RemoteParticipantInfo>
    );
  };

  return (
    <Box
      className={classnames(classes.container, {
        [classes.chatWindowOpen]: isDrawerOpen && isMobile,
      })}
      data-testid="remote-participant-container"
    >
      {renderContent()}
    </Box>
  );
};

export default RemoteParticipantVideo;
