import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Body,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Heading,
  MaterialIcons,
} from '@iclinic/design-system';
import { image } from 'shared/utils/urlGetter';
import { RequestStatus } from 'shared/constants/State';
import { AwardDescription, AwardKind } from '../../types';
import * as actions from '../../state';
import { getRedeemAwardStatus, getSelectedAward } from '../../state/selectors';
import * as S from './RedemptionDialog.styles';

interface RedemptionDialogProps {
  open: boolean;
  onClose?: () => void;
}

const { AttachMoney, CardGiftcard } = MaterialIcons;

const RedemptionDialog = ({ open, onClose }: RedemptionDialogProps) => {
  const dispatch = useDispatch();
  const awardSelected = useSelector(getSelectedAward);
  const redeemAwardStatus = useSelector(getRedeemAwardStatus);
  const [selectedOption, setSelectedOption] = useState<AwardKind | null>(null);

  const handleSelectOption = useCallback(
    (newSelected: AwardKind) => {
      setSelectedOption((previousSelected) => {
        if (newSelected === previousSelected) return null;

        return newSelected;
      });
    },
    [setSelectedOption],
  );

  const handleCloseRedemption = () => {
    setSelectedOption(null);
    dispatch(actions.updateAwardSelected());
    dispatch(actions.updateAwardRedeemStatus(RequestStatus.Ready));

    if (onClose) onClose();
  };

  const handleRedeemAward = () => {
    dispatch(
      actions.triggerReferralRedeemAwards({
        awardId: awardSelected!.id,
        data: {
          kind: selectedOption as AwardKind,
          description:
            selectedOption === AwardKind.SYSTEM_CREDIT
              ? AwardDescription.SYSTEM_CREDIT
              : AwardDescription.EXTERNAL_AWARD,
        },
      }),
    );
  };

  const isSuccessfulReddem = redeemAwardStatus === RequestStatus.Success;
  const isFetchingRedeemAward = redeemAwardStatus === RequestStatus.Pending;

  return (
    <Dialog open={open} onClose={handleCloseRedemption} maxWidth="xs">
      <DialogHeader
        title={isSuccessfulReddem ? 'Sucesso!' : 'Resgate de prêmio'}
        onClose={handleCloseRedemption}
      />
      <DialogContent>
        <S.ImageWrapper>
          <img
            src={image('referrals.rewardRedemption')}
            alt="Resgate de prêmio"
          />
        </S.ImageWrapper>

        {isSuccessfulReddem ? (
          <>
            <Heading variant="md" align="center">
              Resgate de prêmio solicitado
            </Heading>

            <Body variant="sm" align="center">
              Obrigado por participar do nosso programa de indicações. Estamos
              sempre buscando melhorar ainda mais o nosso programa e, para isso,
              gostaríamos de contar com a sua ajuda. Ao clicar em “Participar”,
              você será direcionado para nosso formulário de satisfação.
            </Body>
          </>
        ) : (
          <>
            <Heading variant="md" align="center">
              Agradecemos pela indicação!
            </Heading>

            <Body variant="sm" align="center">
              Como você gostaria de resgatar o prêmio pela indicação de{' '}
              <strong>{awardSelected?.indicated}?</strong>
            </Body>

            <S.OptionsWrapper>
              <S.OptionButton
                selected={selectedOption === AwardKind.SYSTEM_CREDIT}
                onClick={() => handleSelectOption(AwardKind.SYSTEM_CREDIT)}
              >
                <AttachMoney />

                <Heading variant="sm">Crédito no iClinic</Heading>
              </S.OptionButton>

              <S.OptionButton
                selected={selectedOption === AwardKind.EXTERNAL_AWARD}
                onClick={() => handleSelectOption(AwardKind.EXTERNAL_AWARD)}
              >
                <CardGiftcard />
                <Heading variant="sm">Vale presente</Heading>
              </S.OptionButton>
            </S.OptionsWrapper>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {isSuccessfulReddem ? (
          <>
            <Button color="transparent" onClick={handleCloseRedemption}>
              Voltar ao iClinic
            </Button>

            <Button
              href="https://forms.office.com/r/FHWnfLdxCC"
              target="_blank"
            >
              Participar
            </Button>
          </>
        ) : (
          <Button
            disabled={!selectedOption}
            isLoading={isFetchingRedeemAward}
            onClick={handleRedeemAward}
          >
            Confirmar
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RedemptionDialog;
