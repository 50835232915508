import { Reducer } from 'redux';

import { ActionType } from '@types';
import { TransactionStatus } from 'features/onlinePayment/state/constants';
import { SYNC_STATUS_FETCH_ERROR } from '../errorMessages';
import * as actions from './actions';
import {
  SYNC_STATUS_TRANSACTION_ACTION,
  SYNC_STATUS_TRANSACTION_FAILURE,
  SYNC_STATUS_TRANSACTION_SUCCESS,
  CHECKOUT_CREDIT_CARD,
  CHECKOUT_PIX,
  CHECKOUT_V2_FAILURE,
  CHECKOUT_V2_SUCCESS,
  PIX_QRCODE_VALIDITY,
} from './constants';
import { CheckoutV2Data } from './types';

export type CheckoutPaymentState = {
  checkoutV2: {
    isFetching?: boolean;
    data?: CheckoutV2Data;
    error?: string | null;
  };
  orderNumber: string | null;
  syncTransaction: {
    isFetching?: boolean;
    status?: TransactionStatus;
    error?: string | null;
  };
  isPixQRCodeValid: boolean;
};

export const initialState: CheckoutPaymentState = {
  checkoutV2: {
    isFetching: false,
  },
  orderNumber: null,
  syncTransaction: {
    isFetching: false,
    status: TransactionStatus.Created,
  },
  isPixQRCodeValid: true,
};

const checkoutPaymentReducer: Reducer<CheckoutPaymentState> = (
  state = initialState,
  action: ActionType<typeof actions>,
): CheckoutPaymentState => {
  switch (action.type) {
    case CHECKOUT_CREDIT_CARD:
    case CHECKOUT_PIX:
      return {
        ...state,
        checkoutV2: {
          isFetching: true,
        },
      };

    case PIX_QRCODE_VALIDITY:
      return {
        ...state,
        isPixQRCodeValid: action.payload.isPixQRCodeValid,
      };

    case CHECKOUT_V2_FAILURE:
      return {
        ...state,
        checkoutV2: {
          isFetching: false,
          error: action.params.error,
        },
      };

    case CHECKOUT_V2_SUCCESS:
      return {
        ...state,
        checkoutV2: {
          isFetching: false,
          data: action.payload.checkoutV2,
        },
      };

    case SYNC_STATUS_TRANSACTION_ACTION:
      return {
        ...state,
        syncTransaction: {
          isFetching: true,
        },
      };
    case SYNC_STATUS_TRANSACTION_FAILURE:
      return {
        ...state,
        syncTransaction: {
          isFetching: false,
          error: SYNC_STATUS_FETCH_ERROR,
        },
      };
    case SYNC_STATUS_TRANSACTION_SUCCESS:
      return {
        ...state,
        syncTransaction: {
          isFetching: false,
          status: action.payload.status,
        },
      };

    default:
      return state;
  }
};

export default checkoutPaymentReducer;
