import React from 'react';

import { Typography } from '@iclinic/design-system';
import useStyles from './Item.styles';

type ItemProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
};
export default function Item({ icon, children }: ItemProps): JSX.Element {
  const classes = useStyles();

  return (
    <Typography variant="body2" classes={{ body2: classes.item }} gutterBottom>
      {icon}
      {children}
    </Typography>
  );
}
