import * as types from './types';

export const initialized = (eventId, entity, patient, physician) => ({
  type: types.INITIALIZED,
  payload: {
    eventId,
    entity,
    patient,
    physician,
  },
});

export const setPatient = (patient) => ({
  type: types.SET_PATIENT,
  payload: {
    patient,
  },
});

export const ended = () => ({
  type: types.ENDED,
});

export const changeTab = () => ({
  type: types.CHANGED_TAB,
});

export const autoSave = () => ({
  type: types.AUTO_SAVE,
});

export const getEventBlocksIds = (eventId, patientId) => ({
  type: types.GET_EVENT_BLOCKS_IDS.ACTION,
  payload: {
    eventId,
    patientId,
  },
});

export const getEventBlocksIdsSuccess = (eventBlocksIds, patientId) => ({
  type: types.GET_EVENT_BLOCKS_IDS.SUCCESS,
  payload: {
    eventBlocksIds,
    patientId,
  },
});

export const getEventBlocksIdsFailure = (errors) => ({
  type: types.GET_EVENT_BLOCKS_IDS.FAILURE,
  payload: {
    errors,
  },
});

export const showPrescriptionTab = () => ({
  type: types.SHOW_PRESCRIPTION_TAB,
});

export const notAllowGeneretaNewSummary = () => ({
  type: types.NOT_ALLOW_GENERATE_NEW_SUMMARY,
});
