import React from 'react';

import { MaterialUICore } from '@iclinic/design-system';

const { Switch: SwitchMaterial, FormControlLabel } = MaterialUICore;

type Props = {
  value: boolean;
  label: React.ReactNode;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const Switch = ({ value, onChange, label }: Props) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <SwitchMaterial color="primary" checked={!!value} onChange={onChange} />
      }
    />
  );
};

export default Switch;
