import React from 'react';

import { Heading } from '@iclinic/design-system';
import BackgroundThumbnail from './BackgroundThumbnail';
import Drawer from 'features/video-conference/components/TeamComponents/Drawer';
import LocalVideoPreview from 'features/video-conference/components/WaitRoom/LocalVideoPreview';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { buttonsTitle } from 'features/video-conference/constants';
import { ThumbnailContent, DrawerContent, ThumbnailContainer } from './styles';
import { backgroundConfig } from 'features/video-conference/utils';

function BackgroundSelectionDialog() {
  const {
    isBackgroundSelectionOpen,
    setIsBackgroundSelectionOpen,
  } = useConference();

  const { BACKGROUND_IMAGES_NAME, THUMB_IMAGES } = backgroundConfig;

  return (
    <Drawer
      open={isBackgroundSelectionOpen}
      onClose={() => setIsBackgroundSelectionOpen(!isBackgroundSelectionOpen)}
      title={buttonsTitle.APPLY_FILTERS}
    >
      <DrawerContent>
        <LocalVideoPreview isFilterWindow />

        <ThumbnailContainer>
          <Heading variant="xs" gutterBottom>
            Desfocar
          </Heading>
          <ThumbnailContent>
            <BackgroundThumbnail
              thumbnail="none"
              name="Desativar efeitos visuais"
            />
            <BackgroundThumbnail
              thumbnail="blur"
              name="Desfoca seu plano de fundo"
            />
          </ThumbnailContent>
        </ThumbnailContainer>

        <ThumbnailContainer>
          <Heading variant="xs" gutterBottom>
            Planos de fundo
          </Heading>
          <ThumbnailContent>
            {THUMB_IMAGES.map((image, index) => (
              <BackgroundThumbnail
                thumbnail="image"
                name={BACKGROUND_IMAGES_NAME[index]}
                imagePath={image}
                key={image}
                index={index}
              />
            ))}
          </ThumbnailContent>
        </ThumbnailContainer>
      </DrawerContent>
    </Drawer>
  );
}

export default BackgroundSelectionDialog;
