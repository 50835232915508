import React from 'react';
import { useDispatch } from 'react-redux';

import { Grid, MaterialIcons, Box, Body } from '@iclinic/design-system';
import withContentLoader from 'shared/hoc/withContentLoader';
import { image } from 'shared/utils/urlGetter';
import { selectPaymentType } from 'features/onlinePayment/state/checkout/infos/actions';
import { PaymentType } from 'features/onlinePayment/state/constants';
import { PaymentTypeOptionsContentLoader } from './ContentLoaders';
import * as S from './PaymentTypeOptions.styles';

const { CreditCard: CreditCardIcon, NavigateNext } = MaterialIcons;

function PaymentTypeOptions(): JSX.Element {
  const dispatch = useDispatch();

  const paymentOptionsMap = [
    {
      type: PaymentType.Credit,
      icon: <CreditCardIcon color="primary" />,
      label: 'Cartão de crédito',
    },
    {
      type: PaymentType.Pix,
      icon: <img alt="Pix" src={image('onlinePayment.pixIcon')} />,
      label: 'Pix',
    },
  ];

  const handleSelectPaymentType = (paymentType: string) => {
    dispatch(selectPaymentType({ paymentType }));
  };

  return (
    <>
      {paymentOptionsMap.map(({ type, icon, label }) => (
        <S.FormPayment key={type} onClick={() => handleSelectPaymentType(type)}>
          <Grid container justify="space-between" alignItems="center">
            <Box>
              {icon}
              <Body variant="xs" bold>
                {label}
              </Body>
            </Box>
            <NavigateNext color="primary" />
          </Grid>
        </S.FormPayment>
      ))}
    </>
  );
}

export default withContentLoader(PaymentTypeOptionsContentLoader)(
  PaymentTypeOptions,
);
