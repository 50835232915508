import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { composeTextMessageBill } from 'features/onlinePayment/utils';
import { Button, MaterialIcons } from '@iclinic/design-system';
import { BillStatus } from 'features/onlinePayment/state/constants';
import { getEvent } from 'features/onlinePayment/state/manage/selectors';
import CheckoutLinkShare from './CheckoutLinkShare';
import { toggleChargeBackModal } from 'features/onlinePayment/state/manage/actions';
import Loader from './Loader';
import useStyles from './Actions.styles';

type ActionsProps = {
  status?: BillStatus | null;
  checkoutUrl: string | null;
};

const { AttachMoney, SettingsBackupRestore } = MaterialIcons;

export default function Actions({
  status,
  checkoutUrl,
}: ActionsProps): JSX.Element | null {
  const classes = useStyles();
  const dispatch = useDispatch();
  const event = useSelector(getEvent);
  const textMessageBill = composeTextMessageBill(event, checkoutUrl);

  function handleChargeBack() {
    dispatch(toggleChargeBackModal());
  }

  // todo: remove after beta release
  const CHARGE_BACK_ENABLE = false;
  if (status === BillStatus.Paid) {
    if (!CHARGE_BACK_ENABLE) {
      return null;
    }
    return (
      <Button
        color="transparent"
        size="small"
        startIcon={<SettingsBackupRestore />}
        onClick={handleChargeBack}
        data-ga="pagamento-online-estornar"
      >
        Estornar
      </Button>
    );
  }

  if (status === BillStatus.Created) {
    if (checkoutUrl) {
      return (
        <CheckoutLinkShare
          text={textMessageBill}
          phone={event?.patientMobilePhone}
        />
      );
    }
    return <Loader />;
  }

  return (
    <>
      {status !== BillStatus.Canceled ? (
        <Button
          color="transparent"
          size="small"
          startIcon={<AttachMoney />}
          className={classes.btnGenerateBill}
          type="submit"
          data-ga="pagamento-online-gerar-cobranca"
        >
          Gerar Cobrança
        </Button>
      ) : null}
    </>
  );
}
