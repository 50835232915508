import React from 'react';

import { image } from 'shared/utils/urlGetter';
import { HowItWorkCardInfo, Kind } from '../types';

const shareCardInfo = {
  title: 'Compartilhar',
  imagePath: image('referrals.share'),
  content: (
    <>
      Copie seu código exclusivo e <strong>compartilhe com amigos</strong>{' '}
      médicos ou em redes sociais, indicando os benefícios das soluções iClinic
    </>
  ),
  position: '1',
};

const winCardInfo = {
  title: 'Ganhar!',
  imagePath: image('referrals.trophy'),
  content: (
    <>
      Pronto! Agora, nossa equipe entrará em contato com você para te ajudar a
      escolher como <strong>receber seus prêmios acumulados</strong> :)
    </>
  ),
  position: '3',
};

export const howItWorkCards: Record<Kind, HowItWorkCardInfo[]> = {
  physician: [
    shareCardInfo,
    {
      title: 'Fechar',
      imagePath: image('referrals.code'),
      content: (
        <>
          <strong>Ganhe créditos</strong> em cada assinatura concluída com o seu
          código exclusivo de indicação
        </>
      ),
      position: '2',
    },
    winCardInfo,
  ],
  receptionist: [
    shareCardInfo,
    {
      title: 'Fechar',
      imagePath: image('referrals.code'),
      content: (
        <>
          <strong>Ganhe prêmios</strong> em vale presente para cada assinatura
          concluída com o seu código exclusivo de indicação
        </>
      ),
      position: '2',
    },
    winCardInfo,
  ],
};
