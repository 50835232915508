import React from 'react';

import { Chip } from '@iclinic/design-system';

type Props = {
  value: string;
  onDelete: () => void;
};

export default function EmailChip({ value, onDelete }: Props): JSX.Element {
  return <Chip label={value} variant="outlined" onDelete={onDelete} />;
}
