export default [
  { acronym: 'AC', fullname: 'Acre' },
  { acronym: 'AL', fullname: 'Alagoas' },
  { acronym: 'AP', fullname: 'Amapá' },
  { acronym: 'AM', fullname: 'Amazonas' },
  { acronym: 'BA', fullname: 'Bahia' },
  { acronym: 'CE', fullname: 'Ceará' },
  { acronym: 'DF', fullname: 'Distrito Federal' },
  { acronym: 'ES', fullname: 'Espírito Santo' },
  { acronym: 'GO', fullname: 'Goiás' },
  { acronym: 'MA', fullname: 'Maranhão' },
  { acronym: 'MT', fullname: 'Mato Grosso' },
  { acronym: 'MS', fullname: 'Mato Grosso do Sul' },
  { acronym: 'MG', fullname: 'Minas Gerais' },
  { acronym: 'PA', fullname: 'Pará' },
  { acronym: 'PB', fullname: 'Paraíba' },
  { acronym: 'PR', fullname: 'Paraná' },
  { acronym: 'PE', fullname: 'Pernambuco' },
  { acronym: 'PI', fullname: 'Piauí' },
  { acronym: 'RJ', fullname: 'Rio de Janeiro' },
  { acronym: 'RN', fullname: 'Rio Grande do Norte' },
  { acronym: 'RS', fullname: 'Rio Grande do Sul' },
  { acronym: 'RO', fullname: 'Rondônia' },
  { acronym: 'RR', fullname: 'Roraima' },
  { acronym: 'SC', fullname: 'Santa Catarina' },
  { acronym: 'SP', fullname: 'São Paulo' },
  { acronym: 'SE', fullname: 'Sergipe' },
  { acronym: 'TO', fullname: 'Tocantins' },
];
