import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, border } = tokens;

export const ThumbContainer = styled('div')({
  display: 'flex',
  width: '56px',
  height: '56px',
  justifyContent: 'center',
  alignItems: 'center',
  background: color.neutral[25],
  border: `${border.width.hairline}  solid ${color.neutral[100]}`,
  borderRadius: border.radius.md,
  '&:hover': {
    cursor: 'pointer',
    borderColor: `${color.primary.main}`,
    '& svg': {
      color: `${color.primary.main}`,
    },
  },
  '&.selected': {
    border: `${border.width.thin} solid ${color.primary.main}`,
    '& svg': {
      color: `${color.primary.main}`,
    },
  },
});

export const ThumbIconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '20px',
  height: '20px',
  color: color.neutral[500],
});

export const ThumbImage = styled('img')({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  borderRadius: border.radius.md,
});
