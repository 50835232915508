import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MaterialUICore,
  Checkbox,
  CircularProgress,
} from '@iclinic/design-system';

import { useInfiniteScroll } from 'features/tissInvoicing/hooks';
import { actions } from 'features/tissInvoicing/state/lot';
import {
  getLotDataState,
  getLotViewState,
} from 'features/tissInvoicing/state/lot/selectors';
import {
  CenterBox,
  SpaceBetweenBox,
} from 'features/tissInvoicing/components/ui/Flexbox';
import {
  Table,
  TableRow,
  TableCell,
} from 'features/tissInvoicing/components/ui/Table';
import Columns from './Columns';
import Rows from './Rows';
import * as Styles from './styles';
import { Guide } from 'features/tissInvoicing/types';

const { TableContainer, TableHead, TableBody } = MaterialUICore;

interface TableContentProps {
  lotId: string;
}

function TableContent({ lotId }: TableContentProps) {
  const classes = Styles.useStyles();
  const dispatch = useDispatch();

  const { lot } = useSelector(getLotDataState);
  const {
    hasMoreData,
    loading: { fetchGuides: loading },
    guides,
    deletedGuides,
  } = useSelector(getLotViewState);

  const { infiniteScrollRef, pageNum } = useInfiniteScroll({
    hasMoreData,
    loading,
  });

  useEffect(() => {
    dispatch(
      actions.fetchLotGuides({
        lot_id: lotId,
        page: pageNum,
        ordering: 'date',
      }),
    );
  }, [dispatch, lotId, pageNum]);

  const handleCheckRowChange =
    (guide: Guide) => (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        actions.setGuideToBeDeleted({
          guide: guide.id,
          checked: event.target.checked,
        }),
      );
    };

  const handleCheckAllChange =
    () => (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(
        actions.setAllGuidesToBeDeleted({
          checkedAll: event.target.checked,
        }),
      );
    };

  return (
    <SpaceBetweenBox>
      <TableContainer classes={{ root: classes.container }}>
        <Table aria-label="Guias do lote" stickyHeader>
          <TableHead>
            <TableRow>
              {!lot?.is_closed && (
                <TableCell align="center" size="small" padding="checkbox">
                  <Checkbox
                    disabled={guides.length === 0}
                    color="primary"
                    checked={lot?.guides?.length === deletedGuides.length}
                    onChange={handleCheckAllChange()}
                    data-testid="select-all-checkbox"
                  />
                </TableCell>
              )}
              <Columns isLotClosed={lot?.is_closed} />
            </TableRow>
          </TableHead>

          <TableBody>
            {guides.map((guide: Guide, index: number) => {
              const isLastElement = guides.length === index + 1;
              return (
                <TableRow
                  key={guide.id}
                  checked={+deletedGuides.includes(guide.id)}
                  stripped={+(deletedGuides.length === 0)}
                  ref={isLastElement ? infiniteScrollRef : undefined}
                  hover
                >
                  {!lot?.is_closed && (
                    <TableCell
                      scope="row"
                      align="center"
                      size="small"
                      padding="checkbox"
                    >
                      <Checkbox
                        color="primary"
                        checked={deletedGuides.includes(guide.id)}
                        onChange={handleCheckRowChange(guide)}
                      />
                    </TableCell>
                  )}
                  <Rows rowData={guide} isLotClosed={lot?.is_closed} />
                </TableRow>
              );
            })}

            {loading && (
              <TableRow hover>
                <TableCell scope="row" align="center" colSpan={7}>
                  <CenterBox>
                    <CircularProgress label="Carregando guias" />
                  </CenterBox>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </SpaceBetweenBox>
  );
}

export default TableContent;
