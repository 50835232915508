export const listTypeCodeExecutant = (): { name: string; code: number }[] => [
  {
    name: 'CPF',
    code: 0,
  },
  {
    name: 'CNPJ',
    code: 1,
  },
  {
    name: 'Código na Operadora',
    code: 2,
  },
];
