import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled, Menu } = MaterialUICore;
const { color } = tokens;

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginRight: '0.3em',
  color: color.neutral[500],
  alignSelf: 'baseline',
});

export const MenuContainer = styled(Menu)(() => ({
  width: '496px',
}));
