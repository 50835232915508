const compareVersions = (currentVersion: string) => {
  const isGreaterThan = (targetVersion: number) =>
    Number(currentVersion.charAt(0)) > targetVersion;

  const isLessThan = (targetVersion: number) =>
    Number(currentVersion.charAt(0)) < targetVersion;

  return {
    isGreaterThan,
    isLessThan,
  };
};

export default compareVersions;
