import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  MaterialIcons,
  Paper,
  Typography,
  Box,
  Grid,
  Button,
} from '@iclinic/design-system';
import { init } from 'features/onlinePayment/state/checkout/infos/actions';
import { getClinic } from 'features/onlinePayment/state/checkout/infos/selectors';
import BrokenCard from 'features/onlinePayment/components/checkout/invalid/BrokenCard';
import useStyles from './CheckoutInvalid.styles';

const { Phone } = MaterialIcons;

export default function CheckoutInvalid(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [clinic] = [useSelector(getClinic)];

  useEffect((): void => {
    dispatch(init());
  }, [dispatch]);

  return (
    <div data-testid="checkout-invalid">
      <Grid className={classes.container} container>
        <Grid sm={4} item>
          <Paper variant="outlined">
            <BrokenCard
              data-testid="broken-card"
              className={classes.card}
              title="Pagamento Inválido"
            />
            <Box textAlign="center">
              <Typography variant="h3" gutterBottom>
                Página de pagamento não encontrada
              </Typography>
              <Typography variant="body1">
                Entre em contato com a clínica para mais informações:
              </Typography>
              {clinic && clinic.phoneNumber && (
                <Box mt={4}>
                  <Button
                    color="secondary"
                    size="large"
                    startIcon={<Phone />}
                    onClick={() => {
                      window.location.href = `tel:${clinic.phoneNumber}`;
                    }}
                  >
                    {clinic.phoneNumber}
                  </Button>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
