import moment from 'moment';

import { FlowType, ScheduleOffsetUnit } from 'features/marketing/constants';
import {
  AvailableFlow,
  AddedFlow,
  ReturnFlow,
  Offset,
  FlowId,
} from './types';

export const getInternalReturnFlow = (): ReturnFlow => ({
  id: 'internal',
  name: 'Retorno',
  offset: { value: 0, unit: ScheduleOffsetUnit.day },
  flowType: FlowType.return,
  schedule: '',
});

export const calcOffsetDate = (offset: Offset) => (
  moment().add(offset.value, offset.unit).format('YYYY-MM-DD')
);

export const onAddFlow = (
  addedFlows: AddedFlow[],
  flow: AvailableFlow,
) => {
  const alreadyAdded = addedFlows.some(({ name }) => name === flow.name);

  if (alreadyAdded) return addedFlows;

  if (flow.flowType === FlowType.return) return [...addedFlows, flow];

  return [...addedFlows, {
    ...flow,
    schedule: calcOffsetDate(flow.offset),
  }];
};

export const onAddReturnFlow = (
  returnFlow: ReturnFlow,
  selectedReturnFlow: ReturnFlow | null,
  addedFlows: AddedFlow[],
) => {
  if (!selectedReturnFlow || !selectedReturnFlow.id) return [...addedFlows, returnFlow];

  return [
    ...addedFlows.filter(flow => flow.id !== selectedReturnFlow.id),
    returnFlow,
  ];
};

export const removeFlowFromAddedFlows = (
  addedFlows: AddedFlow[],
  flow: AddedFlow,
): AddedFlow[] => addedFlows.filter((addedFlow: AddedFlow) => {
  const isReturnFlow = flow.flowType === FlowType.return;

  if (isReturnFlow) {
    return (addedFlow.flowType !== FlowType.return) && (addedFlow.name !== flow.name);
  }

  return addedFlow.name !== flow.name;
});

export const hasReturnFlowInAddedFlows = (addedFlows: AddedFlow[]): boolean => (
  addedFlows.some(flow => flow.flowType === FlowType.return)
);

export const addInternalIdIntoAddedFlows = (addedFlows: AddedFlow[]): AddedFlow[] => {
  if (hasReturnFlowInAddedFlows(addedFlows)) {
    const internalReturnFlow = getInternalReturnFlow();
    return [...addedFlows, internalReturnFlow];
  }

  return addedFlows;
};

export const setSelectedReturnFlowByAddedFlow = (addedFlows: AddedFlow[]): ReturnFlow => {
  const [flow] = addedFlows.filter((addedFlow: AddedFlow) => (
    addedFlow.flowType === FlowType.return
  ));

  return flow;
};

export const extractFlowIds = (addedFlows: AddedFlow[]): FlowId[] => (
  addedFlows
    .map((flow: AddedFlow) => flow.id)
    .filter((flow: FlowId) => flow !== 'internal')
);
