// External
import React from 'react';
import Icon from '@material/react-material-icon';
import PropTypes from 'prop-types';

// Style
import style from './CampaignAudienceFilter.scss';

const CampaignAudienceFilter = ({ getComponentForFilter, input, onDelete }) => {
  const { name, value: audience } = input;
  const Component = getComponentForFilter(audience);

  const closeButton = React.useMemo(() => (
    <button type="button" onClick={onDelete} className={style.campaignAudienceFilter__button}>
      <Icon icon="close" />
    </button>
  ), [onDelete]);

  if (!Component) {
    return null;
  }
  return (
    <li className={style.campaignAudienceFilter__item}>
      <Component name={name}>
        {closeButton}
      </Component>
    </li>
  );
};

CampaignAudienceFilter.propTypes = {
  getComponentForFilter: PropTypes.func.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.shape({
      filter: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default CampaignAudienceFilter;
