import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { TemplateMessage } from '../../../services/types';
import {
  PreviewActionParams,
  SubmitMultiplesScheduleTemplateData,
} from '../../types';

export interface PersonalizationState {
  fetcherTemplateStatus?: RequestStatus;
  templateMessage?: TemplateMessage;
  submitTemplateStatus?: RequestStatus;
  submitMessage?: string;
  fetcherPreviewStatus?: RequestStatus;
  previewMessage?: string;
}

export const initialState: PersonalizationState = {};

export const nameStore = '@webapp/messaging/scheduleReminder/personalization';

export const fetchReminderTemplateMessage = createAction<number>(
  `${nameStore}/fetchReminderTemplateMessage`,
);

export const submitReminderTemplateMessage =
  createAction<SubmitMultiplesScheduleTemplateData>(
    `${nameStore}/submitReminderTemplateMessage`,
  );

export const fetchReminderPreviewMessage = createAction<PreviewActionParams>(
  `${nameStore}/fetchReminderPreviewMessage`,
);

const PersonalizationSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchReminderTemplateMessageStart: (state) => ({
      ...state,
      fetcherTemplateStatus: RequestStatus.Pending,
    }),
    fetchReminderTemplateMessageSuccess: (
      state,
      action: PayloadAction<TemplateMessage>,
    ) => ({
      ...state,
      fetcherTemplateStatus: RequestStatus.Success,
      templateMessage: action.payload,
    }),
    fetchReminderTemplateMessageFailure: (state) => ({
      ...state,
      fetcherTemplateStatus: RequestStatus.Error,
    }),
    submitReminderTemplateMessageStart: (state) => ({
      ...state,
      submitTemplateStatus: RequestStatus.Pending,
      submitMessage: undefined,
    }),
    submitReminderTemplateMessageSuccess: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      submitTemplateStatus: RequestStatus.Success,
      submitMessage: action.payload,
    }),
    submitReminderTemplateMessageFailure: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      submitTemplateStatus: RequestStatus.Error,
      submitMessage: action.payload,
    }),
    fetchReminderPreviewMessageStart: (state) => ({
      ...state,
      fetcherPreviewStatus: RequestStatus.Pending,
      previewMessage: undefined,
    }),
    fetchReminderPreviewMessageSuccess: (
      state,
      action: PayloadAction<string>,
    ) => ({
      ...state,
      fetcherPreviewStatus: RequestStatus.Success,
      previewMessage: action.payload,
    }),
    fetchReminderPreviewMessageFailure: (state) => ({
      ...state,
      fetcherPreviewStatus: RequestStatus.Error,
    }),
    cleanSubmitMessageReminder: (state) => ({
      ...state,
      submitMessage: undefined,
    }),
  },
});

export const {
  fetchReminderTemplateMessageStart,
  fetchReminderTemplateMessageFailure,
  fetchReminderTemplateMessageSuccess,
  submitReminderTemplateMessageFailure,
  submitReminderTemplateMessageStart,
  submitReminderTemplateMessageSuccess,
  fetchReminderPreviewMessageFailure,
  fetchReminderPreviewMessageStart,
  fetchReminderPreviewMessageSuccess,
  cleanSubmitMessageReminder,
} = PersonalizationSlice.actions;

export default PersonalizationSlice.reducer;
