export const exportHasValidDates = (
  selectedPeriod,
  selectedStartPeriod,
  selectedEndPeriod,
) => {
  const startDate = new Date(selectedStartPeriod);
  const endDate = new Date(selectedEndPeriod);
  return (
    selectedPeriod === 'all' ||
    (selectedPeriod !== 'all' && startDate.getTime() < endDate.getTime())
  );
};
