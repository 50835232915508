import React from 'react';

import { Heading } from '@iclinic/design-system';
import * as S from './ContentWrapper.styles';

interface ContentWrapperProps {
  children: React.ReactElement;
}

const ContentWrapper = ({ children }: ContentWrapperProps) => {
  return (
    <S.Content>
      <Heading variant="md" gutterBottom>
        Obrigado por participar!
      </Heading>

      <S.Subtitle variant="sm">
        Suas indicações deram resultado e aqui estão os seus prêmios:
      </S.Subtitle>

      {children}
    </S.Content>
  );
};

export default ContentWrapper;
