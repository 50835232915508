import {
  DocumentCategory,
  DocumentProofRawData,
  DocumentStatus,
  SellerBankAccountRawData,
  SellerRawData,
} from 'features/onlinePayment/services/config/types';
import {
  SellerEditForm,
  SellerEmailsSettingsFormResume,
} from 'features/onlinePayment/state/config/types';

type EditInitialDataParams = {
  sellerEmailsSettings: SellerEmailsSettingsFormResume | undefined;
  seller: SellerRawData;
  sellerBankAccount: SellerBankAccountRawData | undefined;
};

function sellerToInstallments(seller: SellerRawData | null | undefined) {
  const { id, max_installments, min_installment_value } = seller || {};
  const minValue = min_installment_value
    ? parseFloat(min_installment_value)
    : 0;
  return {
    sellerId: id,
    installments: max_installments || '1',
    minValue,
  };
}

export function getSellerEditInitialData({
  sellerEmailsSettings,
  seller,
  sellerBankAccount,
}: EditInitialDataParams) {
  const {
    paymentConfirmationEnabled,
    paymentConfirmationEmails,
    weeklySummaryEnabled,
    weeklySummaryEmails,
  } = sellerEmailsSettings || {};

  const { routing_number, bank_code, account_number, id: bankAccountId } =
    sellerBankAccount || {};
  const { name, cpf, type, business_cnpj, business_name } = seller || {};
  const installments = sellerToInstallments(seller);

  return {
    ...installments,
    agency: routing_number || '',
    bank: bank_code || '',
    account: account_number || '',
    status: seller?.status,
    type,
    name,
    cpf,
    businessCnpj: business_cnpj,
    businessName: business_name,
    paymentConfirmationEnabled,
    fieldEmailPayConfirm: '',
    paymentConfirmationEmails,
    weeklySummaryEnabled,
    fieldEmailWeeklySummary: '',
    weeklySummaryEmails,
    identity: undefined,
    residence: undefined,
    cnpj: undefined,
    bankAccountId,
  } as SellerEditForm;
}

export function getSellerEditInitialErrors(
  documents?: DocumentProofRawData[] | null,
) {
  const errorMessage = 'Documento rejeitado, envie novamente!';
  const DocStatus: Record<DocumentCategory, string | undefined> = {
    identity: undefined,
    activity: undefined,
    residence: undefined,
    cnpj: undefined,
  };
  documents?.forEach((document) => {
    if (document.status === DocumentStatus.REJECTED) {
      DocStatus[document.file_category] = errorMessage;
    }
  });
  return DocStatus;
}

export function getSellerEditInitialTouched(
  initialErrors: Record<DocumentCategory, string | undefined>,
) {
  const DocStatus: Record<DocumentCategory, boolean> = {
    identity: false,
    activity: false,
    residence: false,
    cnpj: false,
  };
  return Object.keys(initialErrors).reduce((acc, key) => {
    acc[key as DocumentCategory] = Boolean(
      initialErrors[key as DocumentCategory],
    );
    return acc;
  }, DocStatus);
}
