import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from '.';
import { getPaymentSlipLink } from '../services';

export function* fetchPaymentSlipLinkWorker() {
  try {
    const {
      data: { link },
    } = yield call(getPaymentSlipLink);

    yield put(actions.fetchPaymentSlipLinkSuccess(link));
  } catch {
    yield put(actions.fetchPaymentSlipLinkFailed());
  }
}

export default function* paymentSlipLinkSagas() {
  yield takeLatest(actions.fetchPaymentSlipLink, fetchPaymentSlipLinkWorker);
}
