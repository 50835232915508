import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CallToAction from '../components/CallToAction';
import { Container } from './SummaryIAContainer.styles';
import Popover from '../components/Popover';
import Sidebar from '../components/Sidebar';
import { getAcceptTermsIA, postSummaryIA } from 'features/records/services';
import { actions } from '../state';
import { getCanGenerateNewSummary } from 'state/records/selectors';
import { getPhysicianAndClinicId } from 'features/feedDataLayer/state/selectors';
import { captureException } from 'shared/utils/handlerErrors';

const { changeScreen, triggerSidebar, triggerIsWaiting, updateSummary } =
  actions;

const SummaryIAContainer = () => {
  const currentPatientId = useSelector(
    (state) => state.records.root.patient.id,
  );
  const { physicianId } = useSelector(getPhysicianAndClinicId);
  const canGenerateNewSummary = useSelector(getCanGenerateNewSummary);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const goToTermsScreen = () => {
    dispatch(changeScreen('terms'));
    dispatch(triggerSidebar(true));
  };

  const goToLoadingScreen = () => {
    dispatch(changeScreen('loading'));
    dispatch(triggerSidebar(true));
  };

  const handleGoToSummary = async () => {
    try {
      setIsLoading(true);
      const response = await getAcceptTermsIA();
      const { accept } = response.data;

      if (accept) {
        try {
          if (canGenerateNewSummary) {
            dispatch(triggerIsWaiting(true));
            await postSummaryIA(currentPatientId);
          }
          localStorage.setItem(
            'popover-ia-physician-id',
            physicianId?.toString() as string,
          );
          setIsLoading(false);
          goToLoadingScreen();
        } catch {
          setIsLoading(false);
          dispatch(changeScreen('integrationError'));
          dispatch(triggerSidebar(true));
        }
      } else {
        setIsLoading(false);
        goToTermsScreen();
      }
    } catch (error) {
      setIsLoading(false);
      captureException(error);
    }
  };

  const resetFeedback = () => {
    if (canGenerateNewSummary) {
      dispatch(
        updateSummary({
          isFeedbackSent: false,
        }),
      );
    }
  };

  useEffect(() => resetFeedback(), [dispatch, canGenerateNewSummary]);

  return (
    <Container>
      <Sidebar isLoading={isLoading} onClickTry={() => handleGoToSummary()} />
      <Popover isLoading={isLoading} onClickTry={() => handleGoToSummary()} />
      <CallToAction isLoading={isLoading} onClick={() => handleGoToSummary()} />
    </Container>
  );
};

export default SummaryIAContainer;
