// External
import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';

// Internal
import { Title } from '@iclinic/iclinic-ui';
import Radio from 'ui/new/form/Radio';
import Button from 'ui/new/button';
import { Input } from 'ui/new/form';
import {
  optionsFeedBack,
  feedBack,
} from 'state/marketing/unsubscribe/constants';
import style from './FeedBackUnsubscribe.scss';

const FeedBackUnsubscribe = ({ reason, otherReason, fetchUnsubscribe }) => {
  const onClickUnsubscribe = React.useCallback(() => {
    fetchUnsubscribe(reason, otherReason);
  }, [fetchUnsubscribe, reason, otherReason]);

  return (
    <div className={style.feedBack}>
      <Title>
        Nos ajude a melhorar nossos conteúdos deixando um comentário.
      </Title>
      {optionsFeedBack.map(({ key, label }) => (
        <Field
          component={Radio}
          name="reason"
          value={key}
          key={key}
          type="radio"
        >
          {label}
        </Field>
      ))}
      {reason === feedBack.other && (
        <Field
          type="text"
          name="otherReason"
          component={Input}
          placeholder="Deixe um comentário para nos ajudar"
        />
      )}
      <Button type="info" onClick={onClickUnsubscribe}>
        ENVIAR
      </Button>
    </div>
  );
};

FeedBackUnsubscribe.defaultProps = {
  reason: undefined,
  otherReason: undefined,
};

FeedBackUnsubscribe.propTypes = {
  reason: PropTypes.string,
  otherReason: PropTypes.string,
  fetchUnsubscribe: PropTypes.func.isRequired,
};

export default FeedBackUnsubscribe;
