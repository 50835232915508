import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Body, MaterialUICore, MaterialIcons } from '@iclinic/design-system';

import { formatCurrency } from 'features/tissInvoicing/utils/formatCurrency';
import { actions } from 'features/tissInvoicing/state/expense';
import { Expenses } from 'features/tissInvoicing/types';

const { TableCell, IconButton } = MaterialUICore;

const { PlaylistAddCheck, Delete } = MaterialIcons;

interface RowsProps {
  rowData: Expenses;
}

const Rows = ({ rowData }: RowsProps) => {
  const dispatch = useDispatch();

  const handleDelete = (id: string) => {
    dispatch(actions.deleteExpense({ id }));
  };

  return (
    <>
      <TableCell>
        <Body variant="sm">{`${String(rowData.tuss_procedure.code)} - ${String(
          rowData.tuss_procedure.term,
        )}`}</Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{rowData.insurance.name}</Body>
      </TableCell>
      <TableCell align="center" size="small">
        <Body variant="sm">{rowData.quantity}</Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{formatCurrency(rowData.total_value)}</Body>
      </TableCell>
      <TableCell align="center" size="small">
        <IconButton href={`/faturamento-tiss/despesas/${String(rowData.id)}`}>
          <PlaylistAddCheck />
        </IconButton>
      </TableCell>
      <TableCell align="center" size="small">
        <IconButton
          data-testid="expense-row-delete"
          onClick={() => handleDelete(rowData.id)}
        >
          <Delete />
        </IconButton>
      </TableCell>
    </>
  );
};

export default memo(Rows);
