import { Button, IconButton, MaterialIcons } from '@iclinic/design-system';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { deletePaymentMethod } from 'features/newFinance/state/payment';
import { IPayment } from 'features/newFinance/types/types';
import { formatCurrency } from 'shared/utils/strings';
import {
  PaymentItemInfo,
  PaymentItem as StyledPaymentItem,
  DetailsButtonWrapper,
  ReceivedDate,
} from '../styles';
import InstallmentInfoTable from '../components/InstallmentTable';
import PaymentDetailsTable from '../components/paymentDetailsTable';
import { getIcon, getPaymentMethod } from '../utils/utils';
import BankCheckInfo from '../BankCheckInfo';
import { formatDate } from 'shared/utils/date';

const { Delete, ArrowForward } = MaterialIcons;

type PaymentItemProps = {
  payment: IPayment;
};

enum PaymentType {
  CreditCard = 'cc',
  BankCheck = 'bc',
  PicPay = 'pp',
}

const DeleteButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <IconButton
    label=""
    size="sm"
    title="Deletar"
    onClick={onClick}
    tooltip="none"
  >
    <Delete />
  </IconButton>
);

const InstallmentDetailsButton: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => (
  <Button variant="text" color="primary" onClick={onClick}>
    detalhes do parcelamento <ArrowForward />
  </Button>
);

const PaymentItem: React.FC<PaymentItemProps> = ({ payment }) => {
  const {
    paymentType,
    value,
    date,
    defaultCreditCardInstallment,
    bankSlipDueDate,
  } = payment;
  const dispatch = useDispatch();

  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [hasInstallments, setHasInstallments] = useState<boolean>(false);

  useEffect(() => {
    const paymentTypes: PaymentType[] = [
      PaymentType.CreditCard,
      PaymentType.PicPay,
    ];
    const convertedPaymentType: PaymentType = paymentType as PaymentType;
    const bankCheckHasInstallment =
      convertedPaymentType === PaymentType.BankCheck &&
      payment.bankCheckIsInstallment;
    if (bankCheckHasInstallment) setHasInstallments(bankCheckHasInstallment);
    else setHasInstallments(paymentTypes.includes(convertedPaymentType));
  }, [paymentType, payment.bankCheckIsInstallment]);

  const handleDeletePayment = () => {
    dispatch(deletePaymentMethod(payment));
  };
  const installmentNumber = () => {
    if (
      paymentType === PaymentType.BankCheck &&
      payment.bankCheckIsInstallment
    ) {
      return payment.bankCheckInstallments.length.toString();
    }

    return defaultCreditCardInstallment;
  };
  return (
    <>
      <StyledPaymentItem data-testid="payment_item">
        <PaymentItemInfo>
          {getIcon(paymentType)}
          <span className="payment-item-description">
            Pagamento em {getPaymentMethod(paymentType)}
          </span>
          {hasInstallments ? (
            <span className="payment-item-value">
              {installmentNumber()}x -{' '}
              {formatCurrency(value / +installmentNumber())}
            </span>
          ) : (
            <span className="payment-item-value">{formatCurrency(value)}</span>
          )}
          <DeleteButton onClick={handleDeletePayment} />
        </PaymentItemInfo>
        {hasInstallments && (
          <DetailsButtonWrapper>
            <InstallmentDetailsButton
              onClick={() => setShowDetails((detailsState) => !detailsState)}
            />
          </DetailsButtonWrapper>
        )}
        {!hasInstallments && !bankSlipDueDate && (
          <ReceivedDate>
            Recebido em {formatDate(date.toISOString())}
          </ReceivedDate>
        )}
      </StyledPaymentItem>
      {!hasInstallments && bankSlipDueDate && (
        <PaymentDetailsTable
          dueDate={formatDate(bankSlipDueDate.toISOString())}
          value={formatCurrency(value)}
          dateOfReceipt={formatDate(date.toISOString())}
        />
      )}
      {showDetails && hasInstallments && (
        <>
          {paymentType === PaymentType.CreditCard && (
            <InstallmentInfoTable
              installmentNumber={+defaultCreditCardInstallment}
              paymentValue={+value}
            />
          )}
          {paymentType === PaymentType.BankCheck && (
            <BankCheckInfo payment={payment} />
          )}
        </>
      )}
    </>
  );
};

export default PaymentItem;
