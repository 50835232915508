/* eslint-disable sonarjs/prefer-single-boolean-return */
import { createSelector } from 'reselect';
import { FetchState } from '@types';

import { StoreState } from 'state/rootReducer';
import { Bill, Seller, Event } from './types';
import { SellerStatus } from '../constants';

export const getPhysicianId = ({ userInfo }: StoreState): number | undefined =>
  userInfo.userData.physicianId;

export const getPhysicianHasSellerApproved = ({
  userInfo,
}: StoreState): boolean | undefined => userInfo.userData.hasSellerApproved;

export const getEvent = ({ onlinePayment }: StoreState): Event | null =>
  onlinePayment.manage.event;

export const getEventId = ({ onlinePayment }: StoreState): number | null =>
  onlinePayment.manage.event?.id || null;

export const getEventTotalValue = ({
  onlinePayment,
}: StoreState): number | undefined => onlinePayment.manage.event?.totalValue;

export const getEventPatientId = ({
  onlinePayment,
}: StoreState): number | null => onlinePayment.manage.event?.patientId || null;

export const getEventPatientName = ({
  onlinePayment,
}: StoreState): string | null =>
  onlinePayment.manage.event?.patientName || null;

export const getSeller = ({ onlinePayment }: StoreState): Seller | null =>
  onlinePayment.manage.seller;

export const isApprovedSeller = createSelector(
  [getSeller],
  (seller) => seller?.status === SellerStatus.Approved,
);

export const getBill = ({ onlinePayment }: StoreState): Bill | null =>
  onlinePayment.manage.bill;

export const getFetchSellerInfo = ({ onlinePayment }: StoreState): FetchState =>
  onlinePayment.manage.fetchSellerInfo;

export const getFetchBill = ({ onlinePayment }: StoreState): FetchState =>
  onlinePayment.manage.fetchBill;

export const getCreateBill = ({ onlinePayment }: StoreState): FetchState =>
  onlinePayment.manage.createBill;

export const getAmountCharged = ({ onlinePayment }: StoreState): number =>
  onlinePayment.manage.bill?.amountCharged ||
  onlinePayment.manage.event?.totalValue ||
  0;

export const getCheckoutUrl = ({ onlinePayment }: StoreState): string | null =>
  onlinePayment.manage.url;

export const getIsGenerating = ({ onlinePayment }: StoreState): boolean =>
  onlinePayment.manage.isGenerating;

export const getUpdatedBill = ({ onlinePayment }: StoreState): boolean =>
  onlinePayment.manage.updatedBill;

export const getIsFetchingBill = createSelector(
  [getFetchSellerInfo, getFetchBill],
  (fetchSellerInfo, fetchBill) => {
    if (fetchSellerInfo.isFetching) return true;
    if (fetchBill.isFetching) return true;

    return false;
  },
);

export const getFetchBillError = createSelector(
  [getFetchBill],
  (fetchBill) => fetchBill.errors?.[0].message,
);

export const getCreateBillError = createSelector(
  [getCreateBill],
  (createBill) => createBill.errors?.[0].message,
);

export const getShowChargeBackModal = ({
  onlinePayment,
}: StoreState): boolean => onlinePayment.manage.showChargeBackModal;

export const getManageSelector = createSelector(
  [
    getIsFetchingBill,
    getBill,
    getCheckoutUrl,
    getIsGenerating,
    getUpdatedBill,
    isApprovedSeller,
    getEventId,
    getEventTotalValue,
    getShowChargeBackModal,
    getAmountCharged,
    getFetchBillError,
    getCreateBillError,
  ],
  (
    isFetchingBill,
    bill,
    checkoutUrl,
    isGenerating,
    updatedBill,
    sellerIsApproved,
    eventId,
    eventTotalValue,
    showChargeBackModal,
    amountCharged,
    fetchBillError,
    createBillError,
  ) => ({
    isFetchingBill,
    bill,
    checkoutUrl,
    isGenerating,
    updatedBill,
    sellerIsApproved,
    eventId,
    eventTotalValue,
    showChargeBackModal,
    amountCharged,
    fetchBillError,
    createBillError,
  }),
);
