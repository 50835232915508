import React, { useState } from 'react';
import { Body, Link, Box, Button, Checkbox } from '@iclinic/design-system';

import DefaultContainer from 'features/video-conference/components/WaitRoom/Container';
import ScheduleInformation from 'features/video-conference/components/WaitRoom/ScheduleInformation';
import { useAcceptTerms } from 'features/video-conference/hooks/useAcceptTerms';
import {
  TERM_OF_USE,
  POLICY,
  SERVICE_TERMS_URL,
  ACCEPT_TERM_OF_USE,
  CONSENT_FORM,
  AND_THE,
  ACCESS_SCHEDULE,
  BUTTON_ENTER_ROOM,
} from 'features/video-conference/constants';
import { TermsWrapper } from './styles';
import useGlobalStyles from 'features/video-conference/components/styles';

function ParticipantAcceptTerms(): JSX.Element {
  const classesGlobal = useGlobalStyles({});
  const { onSubmit } = useAcceptTerms();
  const [hasAcceptTerms, setHasAcceptTerms] = useState<boolean>(false);

  return (
    <Box className={classesGlobal.room}>
      <DefaultContainer title={ACCESS_SCHEDULE}>
        <ScheduleInformation />
        <TermsWrapper>
          <Checkbox
            id="term"
            name="term"
            onChange={() => setHasAcceptTerms(!hasAcceptTerms)}
          />
          <label htmlFor="term">
            <Body variant="xs" inverseTextColor>
              {ACCEPT_TERM_OF_USE}{' '}
              <Link
                href={SERVICE_TERMS_URL.termsOfConsent}
                target="_blank"
                variant="body2"
              >
                {CONSENT_FORM}
              </Link>
              {', '}
              <Link
                href={SERVICE_TERMS_URL.privacyPolicy}
                target="_blank"
                variant="body2"
              >
                {POLICY}
              </Link>
              {AND_THE}
              <Link
                role="presentation"
                href={SERVICE_TERMS_URL.termsOfUse}
                target="_blank"
                variant="body2"
              >
                {TERM_OF_USE}
              </Link>
            </Body>
          </label>
        </TermsWrapper>
        <Button
          type="button"
          fullWidth
          disabled={!hasAcceptTerms}
          onClick={onSubmit}
        >
          {BUTTON_ENTER_ROOM}
        </Button>
      </DefaultContainer>
    </Box>
  );
}

export default ParticipantAcceptTerms;
