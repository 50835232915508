// External
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

// Internal
import { Title } from '@iclinic/iclinic-ui';
import Radio from 'ui/new/form/Radio';
// Style
import style from './FilterGender.scss';

const FilterGender = ({ name, children }) => (
  <div className={style.filterGender__container}>
    <Title className={style.filterGender__text}>
      <div className={style.filterGender__radioGroup}>
        <Field
          component={Radio}
          name={`${name}.arguments`}
          value="f"
          type="radio"
        >
          Mulheres
        </Field>
        <Field
          component={Radio}
          name={`${name}.arguments`}
          value="m"
          type="radio"
        >
          Homens
        </Field>
      </div>
    </Title>
    {children}
  </div>
);

FilterGender.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FilterGender;
