// External
import React, { useContext } from 'react';

// Internal
import classnames from 'classnames/bind';
import ThemeContext, { UserThemeStyle } from 'shared/hooks/theme';
import ss from './Discount.scss';

const cx = classnames.bind(ss);

type PriceProps = {
  legend?: string;
  classes?: string;
  prefix?: string;
  suffix?: string;
};

export default function Discount(
  {
    legend = '',
    classes = '',
    prefix = '',
    suffix = '',
  }: PriceProps,
): JSX.Element {
  const { isApplied, style } = useContext(ThemeContext);

  return (
    <>
      <div className={cx(ss.discount, classes, UserThemeStyle(ss, style, isApplied))}>
        {prefix && suffix && (
          <p>
            <span className={cx(ss.prefix)}>{prefix}</span>
            <span className={cx(ss.separator)} />
            <span className={cx(ss.suffix)}>{suffix}</span>
            <span className={cx(ss.legend)}>{legend}</span>
          </p>
        )}
      </div>
    </>
  );
}
