import React, { useCallback, useEffect } from 'react';
import classnames from 'classnames';

import { useChatContext } from 'features/video-conference/state/chatContext';
import { useAnnotationContext } from 'features/video-conference/state/annotationContext';
import Chat from 'features/video-conference/components/Chat';
import Annotation from 'features/video-conference/components/Annotation';
import RemoteParticipantVideo from 'features/video-conference/components/RemoteParticipantVideo';
import ParticipantVideo from 'features/video-conference/components/ParticipantVideo';
import AttachVisibilityHandler from 'features/video-conference/components/AttachVisibilityHandler';
import Toolbar from 'features/video-conference/components/Toolbar';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { decodeJwtToken, isMobile } from 'features/video-conference/utils';
import useStyles from './styles';
import BackgroundSelectionDialog from 'features/video-conference/components/BackgroundSelectionDialog';
import {
  fetchSendLogs,
  logAudioAndVideoPermissions,
} from 'features/video-conference/services/sendLogs';
import { useConferenceTokens } from 'features/video-conference/hooks/useConferenceTokens';

const Room = (): JSX.Element | null => {
  const { room, isBackgroundSelectionOpen, isPhysician } = useConference();
  const { isChatWindowOpen } = useChatContext();
  const { isAnnotationWindowOpen } = useAnnotationContext();
  const classes = useStyles({ isMobile });
  const isDrawerOpen =
    isChatWindowOpen || isAnnotationWindowOpen || isBackgroundSelectionOpen;

  const { conferenceToken } = useConferenceTokens({ isPhysician });

  const handleLogAVPermisions = useCallback(() => {
    if (!conferenceToken) return;

    const token = decodeJwtToken(conferenceToken);

    if (!token) return;

    logAudioAndVideoPermissions({
      conferenceId: token?.conferenceId,
      clinicId: token?.clinicId,
      physicianId: token?.physicianId,
      patientId: token?.patientId,
      participantType: token?.isPhysician ? 'Physician' : 'Patient',
      logger: fetchSendLogs,
    });
  }, [conferenceToken]);

  useEffect(() => {
    handleLogAVPermisions();

    window.addEventListener('beforeunload', handleLogAVPermisions);
    return () => {
      window.removeEventListener('beforeunload', handleLogAVPermisions);
    };
  }, [handleLogAVPermisions]);

  if (!room) return null;

  return (
    <div className={classes.room}>
      <Chat />
      <Annotation />
      <BackgroundSelectionDialog />
      <main
        className={classnames(classes.content, {
          [classes.contentShift]: isDrawerOpen,
        })}
      >
        <RemoteParticipantVideo />
        <ParticipantVideo />
        <AttachVisibilityHandler />
      </main>
      <Toolbar />
    </div>
  );
};

export default Room;
