import { useCallback } from 'react';
import { ApiErrors } from '@types';

import { fetchFinishConference } from 'features/video-conference/services';
import { CONFERENCE_FINISHED_RESPONSE_CODE } from 'features/video-conference/constants';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { setTelemedicineStatus } from 'features/video-conference/utils';
import { FetchFinishConferenceBody } from 'features/video-conference/services/types';

export function useConferenceFinish() {
  const {
    room,
    setConferenceFinished,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    onError,
  } = useConference();

  const finishConference = (body: FetchFinishConferenceBody) => {
    fetchFinishConference(body)
      .then(() => {
        setTelemedicineStatus('review');
        localStorage.removeItem(`telemedicine-chat-${room?.name}`);
      })
      .catch(onError);
  };

  const handleErrorConferenceFinished = useCallback(
    (errors: ApiErrors[] | undefined) => {
      if (
        errors &&
        parseInt(errors[0].code, 10) === CONFERENCE_FINISHED_RESPONSE_CODE
      ) {
        setConferenceFinished(true);
        removeLocalVideoTrack();
        removeLocalAudioTrack();
        return true;
      }

      return false;
    },
    [removeLocalAudioTrack, removeLocalVideoTrack, setConferenceFinished],
  );

  return { finishConference, handleErrorConferenceFinished };
}
