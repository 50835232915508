import axios from 'axios';

import {
  headerTokenInterceptor,
  legacyAuthInterceptor,
} from 'features/tissInvoicing/services/interceptors';

export const apiTissInvoicing = axios.create({
  baseURL: process.env.ICLINIC_TISS_INVOICING_URL,
});

export const apiLegacy = axios.create({
  baseURL: process.env.ICLINIC_APP_URL,
});

export const iclinicApi = axios.create({
  baseURL: process.env.ICLINIC_API_URL,
});

apiTissInvoicing.interceptors.request.use(headerTokenInterceptor);

apiLegacy.interceptors.request.use(legacyAuthInterceptor);

iclinicApi.interceptors.request.use(legacyAuthInterceptor);
