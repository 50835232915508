import { DomainTables } from 'features/tissInvoicing/types';
import { StoreState } from 'state/rootReducer';

export const termCode = '‏‏‎ ‎';

export const blankGenericOptions = { id: 0, term: termCode };

export const formatDomainTablesOptions = ({
  access_ways,
  accident_indication,
  appointment_types,
  councils,
  degrees_participation,
  profession_code,
  reasons_termination,
  service_character,
  service_types,
  states,
  techniques_used,
  attendance_regimen,
  occupational_health,
  special_coverages,
}: DomainTables) => ({
  techniques: [{ ...blankGenericOptions }, ...techniques_used],
  accessWays: [{ ...blankGenericOptions }, ...access_ways],
  states: [{ ...blankGenericOptions, term_short: termCode }, ...states],
  councils: [{ ...blankGenericOptions, term_short: termCode }, ...councils],
  appointmentTypes: [{ ...blankGenericOptions }, ...appointment_types],
  accidentIndications: [{ ...blankGenericOptions }, ...accident_indication],
  serviceTypes: [{ ...blankGenericOptions }, ...service_types],
  serviceCharacters: [{ ...blankGenericOptions }, ...service_character],
  professionCodes: [{ ...blankGenericOptions }, ...profession_code],
  participationDegrees: [{ ...blankGenericOptions }, ...degrees_participation],
  terminationReasons: [{ ...blankGenericOptions }, ...reasons_termination],
  attendanceRegimens: [{ ...blankGenericOptions }, ...attendance_regimen],
  occupationalHealthList: [{ ...blankGenericOptions }, ...occupational_health],
  specialCoverages: [{ ...blankGenericOptions }, ...special_coverages],
});

export const getDomainTablesOptions = ({ tissInvoicing }: StoreState) => {
  const {
    domainTables: { domainTables },
  } = tissInvoicing;

  return formatDomainTablesOptions(domainTables);
};

export const getProfessionCodes = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.domainTables?.domainTables.profession_code;
