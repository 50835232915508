import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { DownsellOption, DownsellReasonUpdateData } from '../../services/types';

export interface DownsellReasonState {
  fetchStatus?: RequestStatus;
  downsellOptions?: DownsellOption[];
  submitStatus?: RequestStatus;
  downsellReasons?: number[];
}

export const initialState: DownsellReasonState = {
  fetchStatus: RequestStatus.Pending,
};

export const nameStore = '@webapp/downsell-reason';

export const fetchDownsellData = createAction<string[]>(
  `${nameStore}/fetchDownsellData`,
);

export const submitDownsellReasons = createAction<
  Omit<DownsellReasonUpdateData, 'reasons_downsell'>
>(`${nameStore}/submitDownsellReasons`);

const DownsellReasonSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    fetchDownsellDataSuccess: (
      state,
      action: PayloadAction<{
        downsellOptions: DownsellOption[];
        downsellReasons: number[];
      }>,
    ) => ({
      ...state,
      fetchStatus: RequestStatus.Success,
      downsellOptions: action.payload.downsellOptions,
      downsellReasons: action.payload.downsellReasons,
    }),
    fetchDownsellDataFailure: (state) => ({
      ...state,
      fetchStatus: RequestStatus.Error,
    }),
    submitDownsellReasonsStart: (state) => ({
      ...state,
      submitStatus: RequestStatus.Pending,
    }),
    submitDownsellReasonsSuccess: (state) => ({
      ...state,
      submitStatus: RequestStatus.Success,
    }),
    submitDownsellReasonsFailure: (state) => ({
      ...state,
      submitStatus: RequestStatus.Error,
    }),
  },
});

export const {
  fetchDownsellDataFailure,
  fetchDownsellDataSuccess,
  submitDownsellReasonsFailure,
  submitDownsellReasonsStart,
  submitDownsellReasonsSuccess,
} = DownsellReasonSlice.actions;

export default DownsellReasonSlice.reducer;
