/* eslint-disable react/jsx-props-no-spreading */
// External
import React from 'react';

// Internal
import InputSearch from 'ui/new/input-search';

type FieldInputSearchProductProps = {
  productItems: React.ReactNode | null;
  productDescription: string | null;
  selectedProduct: {};
  name: string | null;
};

export default function FieldInputSearchProduct({
  productItems = null,
  productDescription = null,
  selectedProduct = {},
  name = null,
  ...rest
}: FieldInputSearchProductProps): JSX.Element {
  return (
    <InputSearch
      value={productDescription}
      open={productItems && !selectedProduct}
      name={name}
      placeholder="Digite 3 ou mais letras para pesquisar"
      {...rest}
    >
      {productItems}
    </InputSearch>
  );
}
