import { tokens, MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export const useStyles = makeStyles(() => ({
  container: {
    height: '480px',
  },
  neutral: { color: tokens.color.neutral[500] },
  editButton: {
    outline: 'none',
    textDecoration: 'none',
    '&:hover, &:focus': {
      color: tokens.color.neutral[0],
      outline: 'none',
      textDecoration: 'none',
    },
  },
}));
