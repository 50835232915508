// External
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Icon from '@material/react-material-icon';

// Internal
import ActionableCard from 'ui/new/actionable-card';
import Button from 'ui/new/button';
import Radio from 'ui/new/form/Radio';
import { STATUS } from 'ui/new/actionable-card/constants';
import {
  CampaignStatus,
  campaignFinalizeAction,
  cardsName,
} from 'state/campaign/constants';
import DateHourSelector from './DateHourSelector';
// Style
import style from './FinalizeCampaign.scss';

const FinalizeCampaign = ({
  openModalSendEmailTest,
  finalize,
  openModalScheduleEmail,
  openModalSendEmailNow,
  selectedStatus,
  activeCard,
  setActiveCard,
  cardStatus,
  isInvalid,
  isFormValid,
}) => {
  const collapsed = activeCard !== cardsName.finalize;
  const onHeaderClick = React.useCallback(
    () => setActiveCard(collapsed ? cardsName.finalize : ''),
    [collapsed, setActiveCard],
  );

  const onClickFinalize = React.useCallback(() => {
    if (selectedStatus === CampaignStatus.scheduled) {
      openModalScheduleEmail();
    } else if (selectedStatus === CampaignStatus.sent) {
      openModalSendEmailNow();
    } else {
      finalize();
    }
  }, [finalize, openModalScheduleEmail, openModalSendEmailNow, selectedStatus]);

  const messageRequired = React.useMemo(() => {
    if (!isFormValid) {
      return (
        <div className={style['message-required']}>
          <Icon className={style['icon-alert']} icon="error" />
          <p>Preencha os campos obrigatórios para prosseguir.</p>
        </div>
      );
    }

    return null;
  }, [isFormValid]);

  const isInvalidForm =
    selectedStatus !== CampaignStatus.draft ? !isFormValid : isInvalid;

  return (
    <ActionableCard
      title="Finalizar"
      status={cardStatus}
      collapsed={collapsed}
      onHeaderClick={onHeaderClick}
    >
      <ActionableCard.Body classes={style}>
        <div className={style['mailing-panel-radio-buttons']}>
          <Field
            component={Radio}
            name="status"
            value={CampaignStatus.draft}
            type="radio"
          >
            Salvar e enviar mais tarde
          </Field>
          <Field
            component={Radio}
            name="status"
            value={CampaignStatus.scheduled}
            type="radio"
            className={style['mailing-panel-radio']}
          >
            Agendar e-mail
          </Field>
          <Field
            component={Radio}
            name="status"
            value={CampaignStatus.sent}
            type="radio"
            className={style['mailing-panel-radio']}
          >
            Enviar e-mail agora
          </Field>
        </div>
        {selectedStatus === CampaignStatus.scheduled && <DateHourSelector />}
        {selectedStatus !== CampaignStatus.draft && messageRequired}
      </ActionableCard.Body>
      <ActionableCard.Footer>
        <div className={style['mailing-panel-footer']}>
          <div className={style['mailing-panel-footer-button-send']}>
            <Button type="light" onClick={openModalSendEmailTest}>
              ENVIAR E-MAIL DE TESTE
            </Button>
          </div>
          <Button
            disabled={isInvalidForm}
            type="success"
            onClick={onClickFinalize}
          >
            {campaignFinalizeAction[selectedStatus] || ''}
          </Button>
        </div>
      </ActionableCard.Footer>
    </ActionableCard>
  );
};

FinalizeCampaign.defaultProps = {
  selectedStatus: undefined,
};

FinalizeCampaign.propTypes = {
  openModalSendEmailTest: PropTypes.func.isRequired,
  finalize: PropTypes.func.isRequired,
  openModalScheduleEmail: PropTypes.func.isRequired,
  openModalSendEmailNow: PropTypes.func.isRequired,
  selectedStatus: PropTypes.oneOf(Object.values(CampaignStatus)),
  setActiveCard: PropTypes.func.isRequired,
  activeCard: PropTypes.string.isRequired,
  cardStatus: PropTypes.oneOf(Object.values(STATUS)).isRequired,
  isInvalid: PropTypes.bool.isRequired,
  isFormValid: PropTypes.bool.isRequired,
};

export default FinalizeCampaign;
