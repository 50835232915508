import React from 'react';
import { useFormikContext } from 'formik';

import { TextField, MaterialUICore } from '@iclinic/design-system';
import statesList from 'shared/constants/statesOfCountry';
import { RequestSamplesFormData } from 'features/free-samples/containers/RequestSamplesForm/types';

const { useTheme, useMediaQuery, MenuItem } = MaterialUICore;

interface SelectStateFieldProps {
  id?: string;
  name?: string;
  label?: string;
  error?: boolean;
  value?: string;
  helperText?: string | boolean;
  onBlur?: () => void;
}

const NativeOption = (props: any) => <option {...props} />;

const SelectStateField = ({
  id,
  name,
  label,
  error,
  value,
  helperText,
  onBlur,
}: SelectStateFieldProps) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  const { handleChange } = useFormikContext<RequestSamplesFormData>();

  const OptionComponent = isXs ? NativeOption : MenuItem;
  const selectProps = { native: isXs };
  const selectOptions = isXs
    ? [{ fullname: '', acronym: '' }, ...statesList]
    : statesList;

  return (
    <TextField
      label={label}
      id={id}
      select
      name={name}
      fullWidth
      value={value}
      SelectProps={selectProps}
      error={error}
      helperText={helperText}
      onChange={handleChange}
      onBlur={onBlur}
    >
      {selectOptions.map((item) => (
        <OptionComponent key={item.fullname} value={item.acronym}>
          {item.acronym}
        </OptionComponent>
      ))}
    </TextField>
  );
};

export default SelectStateField;
