import { combineReducers } from 'redux';

import messagingCommonReducer, { MessagingCommonState } from './common';
import messagingMessageHistoryReducer, {
  MessageHistoryState,
} from './messageHistory';
import messagePackageReducer, {
  MessagePackageState,
} from './messagePackage/reducer';
import scheduleConfirmationReducer, {
  ScheduleConfirmationState,
} from './scheduleConfirmation/reducer';
import scheduleReminderReducer, {
  ScheduleReminderState,
} from './scheduleReminder/reducer';

export type MessagingState = {
  common: MessagingCommonState;
  messageHistory: MessageHistoryState;
  scheduleConfirmation: ScheduleConfirmationState;
  scheduleReminder: ScheduleReminderState;
  messagePackage: MessagePackageState;
};

const messagingReducer = combineReducers<MessagingState>({
  common: messagingCommonReducer,
  messageHistory: messagingMessageHistoryReducer,
  scheduleConfirmation: scheduleConfirmationReducer,
  scheduleReminder: scheduleReminderReducer,
  messagePackage: messagePackageReducer,
});

export default messagingReducer;
