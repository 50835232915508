import { PromiseResponse } from '@types';

import {
  AppointmentGuideFormValues,
  PatientData,
} from 'features/tissInvoicing/types/appointmentGuide';
import { iclinicApi, apiTissInvoicing } from '..';

export const fetchPatientData = (
  patientId: number,
): PromiseResponse<PatientData[]> =>
  iclinicApi(`/patients/patient/${patientId}/`);

export const saveGuideData = (data: AppointmentGuideFormValues) =>
  apiTissInvoicing.post(`/appointment-guide/`, data);

export const fetchAppointmentGuideData = (appointmentId: string) =>
  apiTissInvoicing.get(`/appointment-guide/${appointmentId}/`);

export const patchAppointmentGuideData = (
  appointmentId: string,
  data: AppointmentGuideFormValues,
) => apiTissInvoicing.patch(`/appointment-guide/${appointmentId}/`, data);

export const printAppointmentGuide = (appointmentId: string) =>
  apiTissInvoicing.get(`/appointment-guide/${appointmentId}/pdf/`);
