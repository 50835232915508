export const devicesPermission = {
  imagePath: 'videoConference.devices',
  altText: 'Ilustração de um microfone e uma câmera',
  title: 'Autorize o uso da câmera e microfone',
  message: 'Se estiver em dúvida veja',
  linkText: 'esse tutorial',
  linkUrl:
    'https://suporte.iclinic.com.br/pt-br/como-dar-permiss%C3%A3o-de-microfone-e-c%C3%A2mera-na-teleconsulta-da-iclinic',
};

export const devicesPermission2 = {
  imagePath: 'videoConference.devices',
  altText: 'Ilustração de um microfone e uma câmera',
  title: 'Autorize o uso da câmera e microfone no seu navegador.',
  linkText: 'Ver tutorial',
  linkUrl:
    'https://suporte.iclinic.com.br/pt-br/como-dar-permiss%C3%A3o-de-microfone-e-c%C3%A2mera-na-teleconsulta-da-iclinic',
};

export const waitingParticipant = {
  imagePath: 'videoConference.waitingParticipant',
  altText: 'Ilustração de um copo e uma planta',
  title: 'Aguardando outro participante...',
  message: 'Por hora só você está aqui',
  linkText: '',
  linkUrl: '',
};

export const tokenProblem = {
  imagePath: 'videoConference.tokenProblem',
  altText: 'Ilustração de um bilhete rasgado',
  title: 'Encontramos um problema no seu acesso',
  message: 'Entre em contato com o suporte e informe esse erro',
  alertMessage:
    'Encontramos um problema no seu acesso! Tente novamente, caso o erro persista entre em contato com o suporte',
  linkText: '',
  linkUrl: '',
};

export const callEnded = {
  imagePath: 'videoConference.phone',
  altText: 'Ilustração de telefone fixo',
  title: 'Teleconsulta encerrada',
  message: '',
  linkText: '',
  linkUrl: '',
};

export const review = {
  howWasYourExperience: 'Avalie a qualidade da chamada',
  yourOpinionHelpToImproveOurTool:
    'Sua opinião ajuda a melhorar nossa ferramenta',
  veryBad: 'Muito ruim',
  veryGood: 'Muito boa',
  whatIsYourFavoritePart: 'Qual a sua parte favorita?',
  whatCanWeDoToImprove: 'O que podemos fazer para melhorar?',
  writeHere: 'Escreva aqui',
  yourReviewHasBeenSuccessfullySent: 'Sua avaliação foi enviada com sucesso',
  imagePath: 'videoConference.iclinicAttendant',
  altText: 'Ilustração de uma pessoa atendente da iClinic',
  backToMedicalRecord: 'Voltar para o prontuário',
};

export const loading = {
  imagePath: 'videoConference.powerPlug',
  altText: 'Ilustração de uma tomada',
  title: 'Conectando...',
  message: '',
  linkText: '',
  linkUrl: '',
};

export const reconnectingParticipant = {
  imagePath: 'videoConference.reconnectingParticipant',
  altText: 'Ilustração de um celular e uma médica',
  title: 'Você perdeu sua conexão com a internet.',
  message:
    'Estamos tentando reconectar. Se o problema persistir, tente acessar o atendimento por outra rede wifi ou através da sua rede móvel.',
  linkText: '',
  linkUrl: '',
};

export const cameraBlockedIcon = {
  imagePath: 'videoConference.cameraBlockedIcon',
  altText: 'Ilustração de uma câmera com um x vermelho',
  title: '',
  message: '',
  linkText: '',
  linkUrl: '',
};

export const browserSuggestion = {
  imagePath: 'videoConference.browserSuggestion',
  altText: 'Ilustração de um médico apontando para a informação',
  title: 'Use a versão mais recente do',
  wordInContrast: 'Google Chrome',
  titleContinue: 'para ter a melhor experiência.',
  message:
    'Caso tenha um problema, acesse por outro Wi-Fi ou pela sua rede móvel.',
  linkText: '',
  linkUrl: '',
};

export const DEFAULT_DRAWER_CONSTRAINTS = {
  width: 375,
};

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  height: {
    min: 480,
    max: 720,
  },
  width: {
    min: 640,
    max: 1280,
  },
  frameRate: 24,
  facingMode: 'user',
};

export const VIDEO_CONFERENCE_CONTEXT_SCHEMA =
  'iglu:br.com.iclinic/video_conference/jsonschema/1-0-0';
export const TELEMEDICINE_FEATURE_USAGE_TRACK_SCHEMA =
  'iglu:br.com.iclinic/button_click/jsonschema/1-0-9';

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'videoConference-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'videoConference-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'videoConference-selectedVideoInput';

export const CONFERENCE_FINISHED_RESPONSE_CODE = 400746;
export const CONFERENCE_EXPIRED_TOKEN_CODE = 400811;
export const MICROPHONE_OFF = 'Microfone desligado';

export const buttonsTitle = {
  ENDCALL: 'Encerrar chamada',
  VIDEO_ENABLED: 'Ligar câmera',
  VIDEO_DISABLED: 'Desligar câmera',
  AUDIO_ENABLED: 'Desmutar microfone',
  AUDIO_DISABLED: 'Mutar microfone',
  SETTINGS: 'Configurações',
  CHAT: 'Chat',
  SEND: 'Enviar',
  ATTACH: 'Anexar',
  ATTACHING: 'Anexando',
  MEDICAL_RECORDS: 'Prontuário',
  ANNOTATIONS: 'Anotações',
  MORE_OPTIONS: 'Mais',
  WAITING_FOR_PARTICIPANT: 'Aguardando participante',
  TO_RECORD: 'Gravar',
  START_CONFERENCE: 'Entrar na teleconsulta',
  SCREEN_SHARE: 'Apresentar',
  SCREEN_SHARED: 'Parar apresentação',
  APPLY_FILTERS: 'Aplicar efeitos visuais',
  CAPTION_ENABLED: 'Ativar legendas',
  CAPTION_DISABLED: 'Desativar legendas',
};

export const tabsValue = {
  CLINIC_NOTE: 'clinic_note',
  PATIENT_NOTE: 'patient_note',
};

export const tabsTitle = {
  CLINIC_NOTE: 'Notas clínicas',
  PATIENT_NOTE: 'Orientações ao paciente',
};

export const CAMERA = 'Câmera';
export const MICROPHONE = 'Microfone';
export const CAMERA_OFF = 'Câmera desligada';
export const PHYSICIAN_PHOTO_ALT = 'Foto do profissional de saúde';
export const ACCESS_SCHEDULE = 'Acessar consulta';
export const WAITING_FOR_PHYSICIAN = 'Aguardando o médico';
export const WAIT_ROOM = 'Sala de espera';
export const GOT_IT = 'Entendi';
export const THANK_YOU = 'Obrigado!';
export const TYPE_HERE = 'Digite aqui';
export const YOU = 'Você';
export const AND_THE = ' e o ';
export const TERM_OF_USE = 'Termo de Consentimento';
export const ACCEPT_TERM_OF_USE = 'Li e aceito o';
export const POLICY = 'Política de Privacidade';
export const ACCEPT_POLICY = 'Li e aceito a';
export const BUTTON_ENTER_ROOM = 'Entrar';
export const CONSENT_FORM = 'Termo de uso';
export const TERM_REQUIRED = 'O termo é obrigatório';
export const POLICY_REQUIRED = 'A Política de Privacidade é obrigatória';
export const UNSUPPORTED_BROWSER_VERSION =
  'Seu navegador não é compatível para acessar a Teleconsulta. Para prosseguir, utilize o navegador nas versões mais recentes do Chrome, Firefox ou Safari 11';
export const UPLOAD_ERROR =
  'Ocorreu um problema ao carregar o arquivo. Por favor, tente novamente!';
export const FILE_PATIENT_WARNING_1 = 'Arquivos enviados no chat são ';
export const FILE_PATIENT_WARNING_2 = 'automaticamente anexados ';
export const FILE_PATIENT_WARNING_3 = 'em seu prontuário.';
export const FILE_PHYSICIAN_WARNING_1 =
  'Arquivos enviados e recebidos pelo chat serão adicionados a aba ';
export const FILE_PHYSICIAN_WARNING_2 = '“Imagens e anexos”';
export const FILE_PHYSICIAN_WARNING_3 =
  ' do Prontuário. Verifique quais arquivos salvar permanentemente antes de';
export const FILE_PHYSICIAN_WARNING_4 = ' finalizar o atendimento.';
export const FILE_TOO_LARGE =
  'O tamanho do arquivo é muito grande. O tamanho máximo do arquivo é 64 MB.';
export const PATIENT_NOTES_PHYSICIAN_WARNING = `As orientações digitadas serão enviadas ao paciente por e-mail no final da consulta`;
export const UNSUPPORTED_BROWSER_VERSION_SHARE_SCREEN =
  'Seu navegador não tem suporte ao compartilhamento de tela. Por favor, atualize a sua versão ou utilize outro navegador.';
export const READY_TO_JOIN_WARNING = 'Tudo pronto!';
export const READY_TO_JOIN_WARNING_2 =
  'Por favor, amplie a janela e entre na teleconsulta';

export const SERVICE_TERMS_URL = {
  termsOfUse: 'https://suporte.iclinic.com.br/iclinic-termos-de-uso',
  termsOfConsent:
    'https://suporte.iclinic.com.br/termo-de-consentimento-iclinic',
  privacyPolicy:
    'https://suporte.iclinic.com.br/politica-de-privacidade-iclinic',
};

export const initialTerm = false;
export const initialPolicy = false;

export const SERVICE_TERMS_OBJECT = [
  { title: 'terms', page: SERVICE_TERMS_URL.termsOfUse },
  { title: 'consent', page: SERVICE_TERMS_URL.termsOfConsent },
  { title: 'policy', page: SERVICE_TERMS_URL.privacyPolicy },
];

export const selectDevices = {
  AUDIO_INPUT: 'Dispositivo de entrada',
  AUDIO_OUTPUT: 'Dispositivo de saída',
  VIDEO_INPUT: 'Dispositivo de vídeo',
  AUDIO_NOT_FOUND: 'Dispositivo de áudio não encontrado',
  OUTPUT_DEFAULT: 'Saída de áudio padrão do sistema',
  VIDEO_NOT_FOUND: 'Dispositivo de vídeo não encontrado',
};

export const ALLOWED_FILE_TYPES = `.jpeg,.jpg,.gif,.png,.pdf,.tif,.tiff,.doc,.xslx,.docx,.txt,.xls,.xlsx,.csv,.numbers,.ppt,.pptx,.mp3,.wav`;

export const devicePermissionsMessages = {
  CAMERA_DENIED:
    'Por favor, conceda permissão ao navegador para acessar a câmera.',
  AUDIO_DENIED:
    'Por favor, conceda permissão ao navegador para acessar o microfone',
  CAMERA_AUDIO_DENIED:
    'Por favor, conceda permissão ao navegador para acessar o microfone e a câmera.',
  SYSTEM_DENIED:
    'O sistema operacional bloqueou o navegador de acessar o microfone ou a câmera. Por favor, verifique as configurações do seu sistema operacional.',
  DEVICES_NOT_FOUND:
    'O navegador não pode acessar o microfone ou a câmera. Certifique-se de que todos os dispositivos de entrada estejam conectados e habilitados',
  NO_CAMERA_AUDIO_DETECTED:
    'Nenhuma câmera ou microfone detectado: Outros participantes na sala não poderão ver e ouvir você.',
  NO_CAMERA_DETECTED:
    'Nenhuma câmera detectada: Outros participantes na sala não poderão vê-lo.',
  NO_AUDIO_DETECTED:
    'Nenhum microfone detectado: Outros participantes na sala não poderão ouvi-lo',
  DEFAULT_MESSAGE: 'Autorize o uso da câmera e microfone no seu navegador.',
};

const PRICE_FOR_VIDEO_RECORDING = 'R$1,50';
export const RECORD_PRICE_WARNING = `Consultas gravadas possuem um valor adicional de ${PRICE_FOR_VIDEO_RECORDING}`;
export const RECORD_WARNING_TOGGLE_DISABLED =
  'Essa configuração está desativada. A sala já foi criada anteriormente.';
export const RECORD_TOOLTIP = 'Essa consulta está sendo gravada';
export const GENERIC_ERROR_MESSAGE =
  'Ops, ocorreu um erro. Pedimos desculpas, por favor entre em contato com nosso suporte pelo chat.';
export const RECONNECTING_PARTICIPANT_TITLE = 'Perda de conexão:';
export const RECONNECTING_PARTICIPANT_MESSAGE =
  ' estamos tentando reconectar o outro participante.';
export const DEVICE_PERMISSION_DENY_MESSAGE = `A teleconsulta requer acesso à sua câmera e microfone. Clique no ícone de câmera bloqueada `;
export const DEVICE_PERMISSION_DENY_MESSAGE_2 = ` na barra de endereços do seu navegador.`;
export const DEVICE_PERMISSION_DENY_TITLE = `Câmera ou microfone estão bloqueados`;

export const servicesLabels = {
  CONFERENCE_TOKEN: 'conferenceToken',
  AUTH_TOKEN_KEY: 'authToken',
};

export const CONNECTION_FEEDBACK_WARNING =
  'O participante está com problemas de conexão';
export const CONNECTION_FEEDBACK_WARNING_MOBILE = 'Problemas de conexão';

export const networkQuality = {
  BROKEN: 'Sem conexão com a internet',
  BAD: 'A qualidade da conexão está ruim',
  AVERAGE: 'A qualidade da conexão está média',
  GOOD: 'A qualidade da conexão está boa',
  EXCELENT: 'A qualidade da conexão está excelente',
};

export const deviceSettings = {
  AUDIO_INPUT_TITLE: 'Por onde você irá falar',
  AUDIO_INPUT_INFO: 'escolha aqui a forma que você irá falar com seu paciente',
  AUDIO_OUTPUT_TITLE: 'Por onde você irá ouvir',
  AUDIO_OUTPUT_INFO: 'escolha aqui a forma que você irá ouvir seu paciente',
  VIDEO_INPUT_TITLE: 'Por onde você será visto',
  VIDEO_INPUT_INFO:
    'escolha aqui a forma que você irá aparecer para seu paciente',
};

export const menuInfo = {
  SETTINGS_INFO_1: 'Escolha aqui os dispositivos de áudio, microfone e vídeo',
  SETTINGS_INFO_2: 'que você irá utilizar durante sua teleconsulta.',
  FILTER_INFO_1: 'Aplique filtros na sua câmera, como planos de fundo',
  FILTER_INFO_2: 'personalizados e desfoque de fundo.',
};

export const BACKGROUND_IMAGES_NAME: string[] = [
  'Abstrato',
  'Casa no estilo Boho',
  'Estante',
  'Cafeteria',
  'Sala contemporânea',
  'Casa aconchegante',
  'Deserto',
  'Pescaria',
  'Flores',
  'Cozinha',
  'Casa moderna',
  'Natureza',
  'Oceano',
  'Pátio',
  'Planta',
  'São Francisco',
];

export const THUMB_IMAGES = [
  'videoConference.abstractThumb',
  'videoConference.bohoHomeThumb',
  'videoConference.bookshelfThumb',
  'videoConference.coffeeShopThumb',
  'videoConference.contemporaryThumb',
  'videoConference.cozyHomeThumb',
  'videoConference.desertThumb',
  'videoConference.fishingThumb',
  'videoConference.flowerThumb',
  'videoConference.kitchenThumb',
  'videoConference.modernHomeThumb',
  'videoConference.natureThumb',
  'videoConference.oceanThumb',
  'videoConference.patioThumb',
  'videoConference.plantThumb',
  'videoConference.sanFranciscoThumb',
];

export const RAW_IMAGES_PATH = [
  'videoConference.abstractBG',
  'videoConference.bohoHomeBG',
  'videoConference.bookshelfBG',
  'videoConference.coffeeShopBG',
  'videoConference.contemporaryBG',
  'videoConference.cozyHomeBG',
  'videoConference.desertBG',
  'videoConference.fishingBG',
  'videoConference.flowerBG',
  'videoConference.kitchenBG',
  'videoConference.modernHomeBG',
  'videoConference.natureBG',
  'videoConference.oceanBG',
  'videoConference.patioBG',
  'videoConference.plantBG',
  'videoConference.sanFranciscoBG',
];

export const TWILLIO_ERRORS_MESSAGE: { [code: string]: string } = {
  '20104': 'Signaling reconnection failed due to expired AccessToken!',
  '53000': 'Signaling reconnection attempts exhausted!',
  '53001': 'Reconnecting your signaling connection!',
  '53002': 'Signaling reconnection took too long!',
  '53205': 'Participant disconnected because of duplicate identity',
  '53405': 'Reconnecting your media connection!',
};
