import React from 'react';

import { Box, Typography } from '@iclinic/design-system';
import images from 'shared/paths/images';
import { useStyles } from './styles';
import BottomButtonContainer from '../BottomButtonContainer';

export const DefaultError = () => {
  const { image } = useStyles();

  const refreshBrowser = () => {
    window.location.reload();
  };
  return (
    <>
      <Box mb={2}>
        <img
          className={image}
          src={images.settings.professionalCartoon}
          alt="rosto feliz"
        />
      </Box>
      <Box mb={1}>
        <Typography data-testid="defaulerrorlabelid" align="center" bold>
          Ops, algo deu errado!
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography align="center" variant="body2">
          Ocorreu um problema interno que estamos corrigindo agora mesmo. Por
          favor, tente novamente mais tarde!
        </Typography>
      </Box>
      <BottomButtonContainer
        rightOnClickHandler={refreshBrowser}
        leftLabel="Sair"
        rightLabel="Tentar novamente"
      />
    </>
  );
};
