import React, { Fragment } from 'react';

import style from './AddedFlows.scss';

type AddedFlowsProps = {
  children: React.ReactNode,
};

export default function AddedFlows({
  children,
}: AddedFlowsProps): JSX.Element {
  return (
    <Fragment>
      <h4 className={style.title}>Fluxos adicionados</h4>
      <ul className={style.list}>
        {children}
      </ul>
    </Fragment>
  );
}
