import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getLastEventTodayBoolean } from '../../state/selectors';

interface IUseLastEventToday {
  isLastEventToday?: boolean;
}

export const useLastEventToday = (): IUseLastEventToday => {
  const isLastEventToday = useSelector(getLastEventTodayBoolean);

  useEffect(() => {
    if (isLastEventToday) {
      window.userpilot?.track('Finalizar última consulta do dia');
    }
  }, [isLastEventToday]);

  return { isLastEventToday };
};
