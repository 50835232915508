// External
import React from 'react';

// Internal
import AutomatedTag from './AutomatedTag';
import style from './AutomatedTagsContainer.scss';

type AutomatedTagsProps = {
  onAutomatedTagChosen: (tag: string) => void;
  currentAutomatedTag: string,
  tags: string[],
};

const AutomatedTagsContainer = ({
  currentAutomatedTag = '',
  onAutomatedTagChosen,
  tags,
}: AutomatedTagsProps): JSX.Element => (
  <div className={style.container}>
    {tags.map(tag => (
      <AutomatedTag
        key={tag}
        active={tag === currentAutomatedTag}
        onAutomatedTagChosen={onAutomatedTagChosen}
        tag={tag}
      />
    ))}
  </div>
);

export default AutomatedTagsContainer;
