import { Box as BoxUI, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Container = styled(BoxUI)({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${tokens.spacing.sm}`,
  margin: `0 ${tokens.spacing.sm} ${tokens.spacing.sm}`,
});

export const Header = styled(BoxUI)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: tokens.spacing.base,
  marginBottom: tokens.spacing.sm,
});

export const Content = styled(BoxUI)({
  display: 'flex',
  flexDirection: 'column',
  padding: tokens.spacing.sm,
  background: tokens.color.neutral[0],
  border: `1px solid ${tokens.color.neutral[50]}`,
  borderRadius: tokens.border.radius.md,
});
