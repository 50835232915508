import React from 'react';

import ProfessionalFormContainer from './ProfessionalForm';
import { UpdateStepProps } from '../types';
import FormStepContainer from './FormStepContainer';

const ProfessionalStep = ({ onClose }: UpdateStepProps) => {
  return (
    <FormStepContainer
      title="Atualização de cadastro profissional"
      onClose={onClose}
    >
      <ProfessionalFormContainer />
    </FormStepContainer>
  );
};

export default ProfessionalStep;
