import React from 'react';

import * as S from './PlanValues.styles';

type PlanValuesProps = {
  description?: string;
  prefix?: string;
  decimal?: string;
  value: string;
  discountValueInteger?: string;
  discountValueDecimal?: string;
  period: string;
  slug: string;
};

const PlanValues = ({
  description = 'por profissional da saúde',
  prefix = 'R$',
  decimal = '',
  period = 'mês',
  value,
  discountValueInteger = '',
  discountValueDecimal = '',
  slug = '',
}: PlanValuesProps) => (
  <S.PriceMain data-testid={`plan-${slug}-values`}>
    <S.PricePrefix variant="xxs" bold>
      {prefix}
    </S.PricePrefix>

    {discountValueInteger && (
      <S.PriceValueSmall variant="xxs" bold>
        <span>
          de R$ {value},{decimal}
        </span>{' '}
        por
      </S.PriceValueSmall>
    )}

    <S.PriceValueBigger variant="xxl">
      {discountValueInteger || value}
    </S.PriceValueBigger>
    <S.PriceDecimal variant="xxs" bold>
      ,{discountValueDecimal || decimal}
    </S.PriceDecimal>
    <S.PricePeriod variant="xxs" bold>
      {period}
    </S.PricePeriod>

    <S.PriceDescription variant="xxs">{description}</S.PriceDescription>
  </S.PriceMain>
);

export default PlanValues;
