import React, { useState } from 'react';
import { Body, Button, Heading } from '@iclinic/design-system';

import * as S from './Plans.styles';
import { SelectedPlan } from './SelectedPlan';
import { plansList } from '../../constants';
import { Plan } from './Plan';
import { SelectedPlan as SelectedPlanType } from '../../types';

export const Plans = () => {
  const [selectedPlan, selectPlan] = useState<SelectedPlanType>();
  const onClickHandler = () => {
    selectPlan('other');
  };
  return (
    <>
      {!selectedPlan && (
        <S.TitleWrapper>
          <Heading variant="md">
            Selecione o pacote de mensagens ideal para você e seu consultório
            abaixo.
          </Heading>
          <Body variant="sm">
            Não precisa inserir dados de cartão e nada será cobrado de você, não
            se preocupe.
          </Body>
        </S.TitleWrapper>
      )}
      <S.PlansWrapper>
        {selectedPlan ? (
          <SelectedPlan plan={selectedPlan} />
        ) : (
          <S.PlansGrid>
            {plansList.map((plan) => (
              <Plan
                key={plan.messagesAmount}
                plan={plan}
                onClick={selectPlan}
              />
            ))}
            <S.ListItem
              onClick={onClickHandler}
              data-ga="fkd_test_mar_2023_whatsapp_x"
            >
              <S.Heading variant="lg">
                Não sabe qual é o pacote de mensagens ideal para você?
              </S.Heading>
              <Body variant="sm">Nós te ajudamos a descobrir!</Body>
              <Button variant="outlined" size="large" fullWidth>
                Quero entender mais
              </Button>
            </S.ListItem>
          </S.PlansGrid>
        )}
      </S.PlansWrapper>
    </>
  );
};
