import { useEffect, useState } from 'react';

import { reconnectingParticipant } from 'features/video-conference/constants';
import { image } from 'shared/utils/urlGetter';
import { Callback } from 'features/video-conference/constants/types';

export function useHandleImages(onError: Callback) {
  const [reconnectingBlob, setReconnectingBlob] = useState<Blob>(null!!);

  useEffect(() => {
    if (reconnectingBlob) return;

    fetch(image(reconnectingParticipant.imagePath))
      .then((response) => response.blob())
      .then((newBlob) => setReconnectingBlob(newBlob))
      .catch(onError);
  }, [onError, reconnectingBlob]);

  return { reconnectingBlob };
}
