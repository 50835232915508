import React from 'react';
import { useDispatch } from 'react-redux';
import { Body } from '@iclinic/design-system';

import { monetaryFormatter } from 'shared/utils/formatters';
import { addonsContentByPlan } from '../../constants';
import { Product } from '../../services/types';
import { selectAddonTrigger } from '../../state/plans';
import * as S from './Addon.styles';

interface AddonProps {
  addon: Product;
  checked?: boolean;
  isAnnual?: boolean;
  plan: string;
}

const Addon = ({ addon, checked, isAnnual, plan }: AddonProps) => {
  const dispatch = useDispatch();

  const fullValue = isAnnual ? addon.annual_price : addon.monthly_price;
  const monetaryValue = monetaryFormatter(fullValue);
  const content = addonsContentByPlan[plan][addon.slug];
  const isPremium = plan === 'premium';
  const isTelemedicine = addon.slug === 'telemedicine';

  if (!content) return null;

  const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (isPremium && isTelemedicine) return;

    dispatch(selectAddonTrigger(addon));
  };

  return (
    <S.Root onClick={onClickHandler}>
      {content.icon}

      <Body variant="xxs" data-testid={addon.slug}>
        {content.description(monetaryValue, isAnnual ? 'ano' : 'mês')}
      </Body>

      {(!isPremium || !isTelemedicine) && (
        <S.StyledCheckbox
          checked={checked}
          name={`addon-${plan}-${addon.slug}`}
          inputProps={{
            'aria-label': `addon-${plan}-${addon.slug}`,
          }}
        />
      )}
    </S.Root>
  );
};

export default Addon;
