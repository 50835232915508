import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export const useStyles = makeStyles(() => ({
  gridContainer: { marginBottom: axiomTokens.spacing['2'] },
  icon: {
    marginRight: '14px',
  },
}));
