export const FILE_EXTENSIONS = {
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  PNG: 'image/png',
  PDF: 'application/pdf',
};

export function isValidExtension(file: File, extensions?: string[]) {
  return !(extensions && !extensions.includes(file.type));
}

export function readFile(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64 = reader.result?.toString();
      if (base64 != null) {
        resolve(base64);
      } else {
        reject(new Error('Empty file'));
      }
    };
    reader.readAsDataURL(file);
  });
}

export function splitBase64(base64: string) {
  const BASE_64 = ';base64,';
  if (!base64.includes(BASE_64)) {
    throw new Error('String is not a valid base64 file');
  }
  return base64.split(BASE_64);
}
