const UNICODE_NON_BREAKING_SPACE = String.fromCharCode(160);
const USUAL_SPACE = String.fromCharCode(32);

export const formatCurrency = (value: string | number) => {
  const numericValue =
    typeof value === 'string' ? Number(value.replace(',', '.')) : value;

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
    .format(Number.isNaN(numericValue) ? 0 : numericValue)
    .replace(UNICODE_NON_BREAKING_SPACE, USUAL_SPACE);
};
