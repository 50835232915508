import React from 'react';
import { Button } from '@iclinic/design-system';
import { useSelector } from 'react-redux';

import {
  Description,
  ResumeAreaContainer,
  TextContainer,
  Text,
} from './ResumeArea.style';
import { getResumeMarkdown } from '../../state/selector';

export type SpeechAreaProps = {
  onClickResume: () => Promise<void>;
  isLoading: boolean;
  isDisabled: boolean;
};

const SpeechArea = ({
  onClickResume,
  isLoading,
  isDisabled,
}: SpeechAreaProps) => {
  const resumeMarkdown = useSelector(getResumeMarkdown);
  return (
    <ResumeAreaContainer>
      <Description>Resumo da Anamnese</Description>
      <Button
        size="small"
        fullWidth
        isLoading={isLoading}
        onClick={onClickResume}
        disabled={isDisabled}
      >
        Resumir
      </Button>
      <TextContainer>
        <Text>{resumeMarkdown}</Text>
      </TextContainer>
    </ResumeAreaContainer>
  );
};

export default SpeechArea;
