import { tokens, MaterialUICore, TextField } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = tokens;

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  columnGap: spacing.sm,
  padding: `0 ${spacing.sm}`,

  [theme.breakpoints.up('sm')]: {
    padding: 0,
  },
}));

export const FieldsWrapper = styled('div')({
  flex: 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'auto',
  gap: spacing.sm,
});

export const TextFieldFull = styled(TextField)({
  gridColumn: '1 / span 3',
});

export const TextFieldDate = styled(TextField)({
  gridColumn: '1 / span 2',
});

export const PaymentCardWrapper = styled('div')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'initial',
  },
}));
