export const snowplowStepsMapByFields: Record<string, number> = {
  ufCrm: 1,
  crm: 2,
  fullName: 3,
  cpf: 4,
  phoneNumber: 5,
  zipCode: 6,
  address: 7,
  number: 8,
  complement: 9,
  neighborhood: 10,
  state: 11,
  city: 12,
};
