import { PromiseResponse } from '@types';
import { apiOnlinePayment } from 'features/onlinePayment/services';
import {
  FetchSellerInfoRawData,
  FetchBillByEventIdRawData,
  BillRawData,
  FetchCheckoutUrlRawData,
  UpdateBillRawData,
  CreateBillRequestProps,
  UpdateBillRequestProps,
} from './types';

export const fetchDefaultSellerFromPhysician = (
  physicianId: number,
): PromiseResponse<FetchSellerInfoRawData> =>
  apiOnlinePayment.get(
    `/sellers/?physician_id=${physicianId}&is_default=true&fields=id,status`,
  );

export const fetchBillByEventIdRequest = (
  eventId: number,
): PromiseResponse<FetchBillByEventIdRawData> =>
  apiOnlinePayment.get(`/billing/bills/?event_id=${eventId}`);

export const createBillRequest = ({
  eventId,
  seller,
  amountCharged,
  patientId,
  patientName,
}: CreateBillRequestProps): PromiseResponse<BillRawData> =>
  apiOnlinePayment.post(`/billing/bills/`, {
    event_id: eventId,
    amount_charged: amountCharged,
    seller,
    patient_id: patientId,
    patient_name: patientName,
  });

export const fetchCheckoutUrlRequest = (
  billId: string,
): PromiseResponse<FetchCheckoutUrlRawData> =>
  apiOnlinePayment.get(`/billing/bills/${billId}/checkout-link/`);

export const updateBillRequest = ({
  billId,
  amountCharged,
}: UpdateBillRequestProps): PromiseResponse<UpdateBillRawData> =>
  apiOnlinePayment.patch(`/billing/bills/${billId}/`, {
    amount_charged: amountCharged,
  });
