import { axiomTokens, MaterialUICore } from '@iclinic/design-system';
import { Form } from 'formik';

import { FlexColumnBox } from 'features/tissInvoicing/components/ui/Flexbox';

const { styled } = MaterialUICore;
const { spacing, border, color } = axiomTokens;
export const Container = styled(FlexColumnBox)({
  padding: `0 ${spacing['4']}`,
  margin: spacing['4'],
});

export const Content = styled(FlexColumnBox)({
  padding: spacing['8'],
  background: color.background['1'],
  border: `1px solid ${color.border.subtle}`,
  borderRadius: border.radius.md,
});

export const FormContainer = styled(Form)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column-reverse',
  gap: spacing['8'],

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
  },
}));

export const LoaderContainer = styled(Content)({
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '600px',
  marginTop: spacing['8'],
});
