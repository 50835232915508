import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, MaterialIcons } from '@iclinic/design-system';

import { getErrorsMessage } from '../../state/plans/selectors';
import { setErrorsMessage } from 'features/checkout/state/plans';

const { ErrorOutlineOutlined } = MaterialIcons;

const ErrorsMessage = () => {
  const dispatch = useDispatch();
  const errorsMessage = useSelector(getErrorsMessage);

  const onCloseMessageHandler = (toCloseMessage: string) => {
    const filteredErrorsMessageWithoutCurrent = errorsMessage.filter(
      (message) => message !== toCloseMessage,
    );

    dispatch(setErrorsMessage(filteredErrorsMessageWithoutCurrent));
  };

  return (
    <>
      {errorsMessage.map((message) => (
        <Alert
          key={message}
          severity="error"
          icon={<ErrorOutlineOutlined />}
          message={message}
          action={
            <Button
              variant="text"
              onClick={() => onCloseMessageHandler(message)}
            >
              Fechar
            </Button>
          }
        />
      ))}
    </>
  );
};

export default ErrorsMessage;
