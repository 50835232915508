import React from 'react';
import { MaterialIcons } from '@iclinic/design-system';

import { buttonsTitle } from 'features/video-conference/constants';
import ToggleButton from 'features/video-conference/components/TeamComponents/ToggleButton';

const { MoreVert } = MaterialIcons;

type Props = {
  onClickHandler: () => void;
  isOpen: boolean;
  innerRef?: React.RefObject<HTMLButtonElement>;
};

export default function GoToMedicalRecordsButton({
  onClickHandler,
  isOpen,
  innerRef,
}: Props) {
  return (
    <ToggleButton
      onClick={onClickHandler}
      switchedOn={!isOpen}
      variant="info"
      updateOn={false}
      title={buttonsTitle.MORE_OPTIONS}
      label={buttonsTitle.MORE_OPTIONS}
      icon={<MoreVert />}
      aria-controls={buttonsTitle.MORE_OPTIONS}
      innerRef={innerRef}
      dataGa="telemedicine_more_options"
      dataSp="telemedicine_more_options"
    />
  );
}
