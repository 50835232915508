import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { TransactionType } from 'features/onlinePayment/state/constants';
import {
  TransactionBase,
  TransactionsListPagination,
  TransactionDetail,
  AttemptTransactionBase,
  TransactionsFiltersForm,
} from './types';
import {
  TRANSACTIONS_LIST_FETCH_ERROR,
  TRANSACTION_DETAIL_FETCH_ERROR,
  TRANSACTION_EXPORT_FETCH_ERROR,
} from './errorMessages';

export interface TransactionsState {
  selectedTransactionType: TransactionType;
  isShowingWaitingPayments: boolean;
  transactionsList?: {
    data?: TransactionBase[] | null;
    fetchStatus?: RequestStatus;
    error?: string;
  };
  pagination?: TransactionsListPagination | null;
  pageNumber: number;
  transactionDetail?: {
    data?: TransactionDetail | null;
    fetchStatus?: RequestStatus;
    error?: string;
  };
  transactionDetailOpen: boolean;
  attemptTransactionsList?: {
    data?: AttemptTransactionBase[] | null;
    fetchStatus?: RequestStatus;
    error?: string;
  };
  attemptTransactionDetail?: {
    data?: AttemptTransactionBase | null;
    fetchStatus?: RequestStatus;
    error?: string;
  };
  transactionsFilters?: TransactionsFiltersForm;
  transactionsXLS?: {
    fetchStatus?: RequestStatus | null;
    error?: string | null;
  };
}

export const initialState: TransactionsState = {
  selectedTransactionType: TransactionType.Online,
  isShowingWaitingPayments: false,
  transactionsList: {
    fetchStatus: RequestStatus.Pending,
  },
  pageNumber: 1,
  transactionDetailOpen: false,
};

const name = 'transactions';

export const fetchTransactionDetail = createAction<string>(
  `${name}/fetchTransactionDetail`,
);

export const fetchAttemptTransactionDetail = createAction<string>(
  `${name}/fetchAttemptTransactionDetail`,
);

const TransactionsSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      fetchTransactionDetail,
      (state): TransactionsState => ({
        ...state,
        transactionDetail: {
          fetchStatus: RequestStatus.Pending,
        },
      }),
    );
    builder.addCase(
      fetchAttemptTransactionDetail,
      (state): TransactionsState => ({
        ...state,
        attemptTransactionDetail: {
          fetchStatus: RequestStatus.Pending,
        },
      }),
    );
  },
  reducers: {
    selectTransactionType: (
      state,
      action: PayloadAction<TransactionType>,
    ): TransactionsState => ({
      ...state,
      selectedTransactionType: action.payload,
    }),
    showWaitingPayments: (
      state,
      action: PayloadAction<boolean>,
    ): TransactionsState => ({
      ...state,
      isShowingWaitingPayments: action.payload,
    }),
    fetchTransactionsList: (state): TransactionsState => ({
      ...state,
      transactionsList: {
        fetchStatus: RequestStatus.Pending,
      },
    }),
    fetchTransactionsListSuccess: (
      state,
      action: PayloadAction<TransactionBase[] | null>,
    ): TransactionsState => ({
      ...state,
      transactionsList: {
        data: action.payload,
        fetchStatus: RequestStatus.Success,
        error: undefined,
      },
    }),
    fetchTransactionsListFailure: (state): TransactionsState => ({
      ...state,
      transactionsList: {
        fetchStatus: RequestStatus.Error,
        error: TRANSACTIONS_LIST_FETCH_ERROR,
      },
    }),
    transactionsPagination: (
      state,
      action: PayloadAction<TransactionsListPagination>,
    ): TransactionsState => ({
      ...state,
      pagination: action.payload,
    }),
    selectedPageNumber: (
      state,
      action: PayloadAction<number>,
    ): TransactionsState => ({
      ...state,
      transactionsList: {
        fetchStatus: RequestStatus.Pending,
      },
      attemptTransactionsList: {
        fetchStatus: RequestStatus.Pending,
      },
      pageNumber: action.payload,
    }),
    toggleTransactionDetailOpen: (
      state,
      action: PayloadAction<boolean>,
    ): TransactionsState => ({
      ...state,
      transactionDetailOpen: action.payload,
    }),
    fetchTransactionDetailSuccess: (
      state,
      action: PayloadAction<TransactionDetail | null>,
    ): TransactionsState => ({
      ...state,
      transactionDetail: {
        data: action.payload,
        fetchStatus: RequestStatus.Success,
        error: undefined,
      },
    }),
    fetchTransactionDetailFailure: (state): TransactionsState => ({
      ...state,
      transactionDetail: {
        fetchStatus: RequestStatus.Error,
        error: TRANSACTION_DETAIL_FETCH_ERROR,
      },
    }),
    fetchAttemptTransactionsList: (state): TransactionsState => ({
      ...state,
      attemptTransactionsList: {
        fetchStatus: RequestStatus.Pending,
      },
    }),
    fetchAttemptTransactionsListSuccess: (
      state,
      action: PayloadAction<AttemptTransactionBase[] | null>,
    ): TransactionsState => ({
      ...state,
      attemptTransactionsList: {
        data: action.payload,
        fetchStatus: RequestStatus.Success,
        error: undefined,
      },
    }),
    fetchAttemptTransactionsListFailure: (state): TransactionsState => ({
      ...state,
      attemptTransactionsList: {
        fetchStatus: RequestStatus.Error,
        error: TRANSACTIONS_LIST_FETCH_ERROR,
      },
    }),
    fetchAttemptTransactionDetailSuccess: (
      state,
      action: PayloadAction<AttemptTransactionBase | null>,
    ): TransactionsState => ({
      ...state,
      attemptTransactionDetail: {
        data: action.payload,
        fetchStatus: RequestStatus.Success,
        error: undefined,
      },
    }),
    fetchAttemptTransactionDetailFailure: (state): TransactionsState => ({
      ...state,
      attemptTransactionDetail: {
        fetchStatus: RequestStatus.Error,
        error: TRANSACTION_DETAIL_FETCH_ERROR,
      },
    }),
    fetchFilterTransactions: (
      state,
      action: PayloadAction<TransactionsFiltersForm>,
    ): TransactionsState => ({
      ...state,
      transactionsList: {
        fetchStatus: RequestStatus.Pending,
      },
      attemptTransactionsList: {
        fetchStatus: RequestStatus.Pending,
      },
      transactionsFilters: action.payload,
    }),
    fetchTransactionsXLS: (state): TransactionsState => ({
      ...state,
      transactionsXLS: {
        fetchStatus: RequestStatus.Pending,
        error: null,
      },
    }),
    fetchTransactionsXLSuccess: (state): TransactionsState => ({
      ...state,
      transactionsXLS: {
        fetchStatus: RequestStatus.Success,
        error: null,
      },
    }),
    fetchTransactionsXLSFailure: (state): TransactionsState => ({
      ...state,
      transactionsXLS: {
        fetchStatus: RequestStatus.Error,
        error: TRANSACTION_EXPORT_FETCH_ERROR,
      },
    }),
  },
});

export default TransactionsSlice.reducer;

export const {
  selectTransactionType,
  showWaitingPayments,
  fetchTransactionsList,
  fetchTransactionsListSuccess,
  fetchTransactionsListFailure,
  transactionsPagination,
  selectedPageNumber,
  toggleTransactionDetailOpen,
  fetchTransactionDetailSuccess,
  fetchTransactionDetailFailure,
  fetchAttemptTransactionsList,
  fetchAttemptTransactionsListSuccess,
  fetchAttemptTransactionsListFailure,
  fetchAttemptTransactionDetailSuccess,
  fetchAttemptTransactionDetailFailure,
  fetchFilterTransactions,
  fetchTransactionsXLSuccess,
  fetchTransactionsXLSFailure,
  fetchTransactionsXLS,
} = TransactionsSlice.actions;
