import { useState } from 'react';
import { TwilioError } from 'twilio-video';

import { ApiErrors } from '@types';

export function useHandleErrors() {
  const [error, setError] = useState<TwilioError | Error | ApiErrors | null>(
    null,
  );

  return { setError, error };
}
