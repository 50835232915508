/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Body, MaterialUICore, tokens } from '@iclinic/design-system';

const { ListItemIcon, styled } = MaterialUICore;
const { color, spacing } = tokens;

export const QuestionText = styled(Body)({
  color: color.primary.main,
});

export const AnswerText = styled(Body)({
  padding: `0 ${spacing.sm} ${spacing.sm}`,
});

interface Props {
  expanded: boolean;
}

export const Root = styled(
  ({
    expanded,
    ...rest
  }: Props &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >) => <div {...rest} />,
)({
  '& svg': {
    transform: (props: Props) =>
      props.expanded ? 'rotate(90deg)' : 'rotate(0deg)',
    transition: 'transform .3s ease-in-out',
  },
});

export const IconWrapper = styled(ListItemIcon)({
  minWidth: 'auto',
});
