import { AxiosRequestConfig } from 'axios';

import { AUTH_TOKEN_KEY } from 'shared/constants/auth';
import { CLINIC_ID } from 'shared/constants/services';

export const headerTokenInterceptor = (
  request: AxiosRequestConfig,
): AxiosRequestConfig => {
  const token = global.localStorage.getItem(AUTH_TOKEN_KEY);

  if (!token) throw new Error(`missing token in header Authorization`);

  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: `Token ${token}`,
    },
  };
};

export const legacyAuthInterceptor = (request: AxiosRequestConfig) => {
  const token = global.localStorage.getItem(AUTH_TOKEN_KEY);
  const clinic = global.localStorage.getItem(CLINIC_ID);

  if (!token || !clinic) throw new Error(`missing token or clinic data`);

  const params = { params: { token, clinic, ...request.params } };
  return Object.assign(request, params);
};
