export const STEPS = {
  selectUsers: {
    title: 'Seleção de usuários',
    value: 1,
  },
  summary: {
    title: 'Resumo',
    value: 2,
  },
  payment: {
    title: 'Novo cartão de crédito',
    value: 3,
  },
  success: {
    title: 'Sucesso',
    value: 4,
  },
  error: {
    title: '',
    value: 5,
  },
};

export const TITLE_MODAL = [
  null,
  STEPS.selectUsers.title,
  STEPS.summary.title,
  STEPS.payment.title,
  STEPS.success.title,
];

export const CREATE_STEPS_CONTROL = [
  STEPS.summary.title,
  STEPS.payment.title,
  STEPS.success.title,
];

export const CHECKOUT = 'checkout';
