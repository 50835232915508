import React from 'react';

import Dropdown from 'ui/new/dropdown';
import { AvailableFlow } from 'state/marketing/emailFlow/patient/types';

interface Props {
  flow: AvailableFlow,
  onClick: (flow: AvailableFlow) => void,
}

export default function DropdownAvailableFlowItems({
  flow,
  onClick,
}: Props): JSX.Element {
  return (
    <Dropdown.Item
      key={`${flow.id}-dropdown-item`}
      id={flow.id}
      onClick={() => onClick(flow)}
    >
      {flow.name}
    </Dropdown.Item>
  );
}
