import { MaterialUICore, Heading, Box, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing } = tokens;

export const MessageLabelContainer = styled(Box)(() => ({
  padding: 0,
  marginTop: spacing.nano,
  marginBottom: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
}));

export const MessageUser = styled(Heading)(() => ({
  display: 'flex',
  alignItems: 'center',
  textAlign: 'right',
  color: color.neutral[700],
}));

export const MessageTime = styled(Heading)(() => ({
  color: color.neutral[300],
}));
