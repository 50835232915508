import { PromiseResponse } from '@types';

import { apiOnlinePayment, apiOnlinePaymentExportData } from '..';
import {
  TransactionsListRawData,
  TransactionDetailRawData,
  PaymentAttemptsListRawData,
  PaymentAttemptsBaseRawData,
} from './types';
import { TransactionType } from 'features/onlinePayment/state/constants';

export const getTransactionsList = (
  pageNumber: number,
  filters = '',
): PromiseResponse<TransactionsListRawData> =>
  apiOnlinePayment.get(
    `/billing/bills/?page=${pageNumber}&page_size=10${filters}&status=created`,
  );

export const getTransactionDetail = (
  id: string,
): PromiseResponse<TransactionDetailRawData> =>
  apiOnlinePayment.get(`/billing/bills/${id}`);

export const getPaymentAttemptsList = (
  pageNumber: number = 1,
  filters = '',
  origin = TransactionType.Presential,
): PromiseResponse<PaymentAttemptsListRawData> =>
  apiOnlinePayment.get(
    `/payments/attempts/?expand=bill&origin=${origin}&page=${pageNumber}&page_size=10${filters}`,
  );

export const getPaymentAttemptDetail = (
  id: string,
): PromiseResponse<PaymentAttemptsBaseRawData> =>
  apiOnlinePayment.get(`/payments/attempts/${id}`);

export const exportTransactionsList = (filters = ''): PromiseResponse<Blob> =>
  apiOnlinePaymentExportData.get(`/billing/bills/?${filters}&status=created`);

export const exportPaymentAttemptsList = (
  filters = '',
  origin = TransactionType.Presential,
): PromiseResponse<Blob> =>
  apiOnlinePaymentExportData.get(
    `/payments/attempts/?expand=bill&origin=${origin}${filters}`,
  );
