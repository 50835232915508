import React from 'react';
import classnames from 'classnames';

import {
  MaterialUICore,
  MaterialIcons,
  Heading,
  Box,
} from '@iclinic/design-system';
import { isMobile } from 'features/video-conference/utils';
import useStyles from './styles';

const { Drawer, IconButton } = MaterialUICore;
const { Close } = MaterialIcons;

type Props = {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  title: string;
};

export default function ChatDrawer({ children, open, onClose, title }: Props) {
  const classes = useStyles();
  return (
    <Drawer
      anchor={isMobile ? 'bottom' : 'right'}
      variant="persistent"
      open={open}
      classes={{ paper: classes.drawerPaper }}
      className={classnames({
        [classes.bottomDrawer]: isMobile,
      })}
    >
      <Box py={1} px={2} display="flex" justifyContent="space-between">
        <Heading variant="md">{title}</Heading>
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      {children}
    </Drawer>
  );
}
