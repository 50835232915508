import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AddExpense, ExpenseList } from '../containers/expense';

const Root = () => (
  <Switch>
    <Route path="/faturamento-tiss/despesas/novo" component={AddExpense} />
    <Route path="/faturamento-tiss/despesas/:id" component={AddExpense} />
    <Route exact path="/faturamento-tiss/despesas" component={ExpenseList} />
    <Redirect to="/404/" />
  </Switch>
);

export default Root;
