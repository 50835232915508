import React, { useEffect } from 'react';

import { Snackbar, MaterialIcons } from '@iclinic/design-system';

const { CheckCircle } = MaterialIcons;

type Snackbar = {
  message: string;
  onClose?: () => void;
};
export default function SuccessSnackbar({
  message,
  onClose = () => {},
}: Snackbar): JSX.Element {
  const [open, setOpen] = React.useState(true);

  useEffect(() => {
    return () => {
      setOpen(false);
    };
  }, []);

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      severity="success"
      onClose={() => {
        setOpen(false);
        onClose();
      }}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      message={
        <>
          <CheckCircle />
          {message}
        </>
      }
    />
  );
}
