import { useCallback, useEffect, useRef, useState } from 'react';

import { fetchTranscript } from 'features/video-conference/services/transcript';
import { useCaptionContext } from 'features/video-conference/state/captionContext';
import { useConference } from 'features/video-conference/state/conferenceContext';

export const useTranscript = () => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [loading, setLoading] = useState(false);
  const [trascriptionFinished, setTrascriptionFinished] = useState(false);

  const { conferenceFinished } = useConference();
  const {
    elapsedTime,
    transcriptList,
    setTranscriptList,
  } = useCaptionContext();

  const clearSendTranscript = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };

  const sendTranscript = useCallback(() => {
    if (transcriptList.length && !loading) {
      const transcriptListCache = transcriptList;

      setLoading(true);

      fetchTranscript(transcriptList)
        .then(() => {
          setLoading(false);
          const transcriptListCacheMap = transcriptListCache.map(
            (transcriptItem) => transcriptItem.delta,
          );

          setTranscriptList((prevTranscriptList) =>
            prevTranscriptList.filter(
              (prevTranscriptListItem) =>
                transcriptListCacheMap.indexOf(prevTranscriptListItem.delta) ===
                -1,
            ),
          );

          clearSendTranscript();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [loading, setTranscriptList, transcriptList]);

  const setUpSendTranscript = useCallback(() => {
    clearSendTranscript();
    timerRef.current = setTimeout(sendTranscript, 5000);
  }, [sendTranscript]);

  const addTranscriptItem = useCallback(
    (transcript: string) => {
      if (!timerRef.current) {
        setUpSendTranscript();
      }

      const transcriptListMap = transcriptList.map(
        (transcriptItem) => transcriptItem.delta,
      );
      const canAddTranscriptItem =
        transcriptListMap.indexOf(elapsedTime) === -1;

      if (canAddTranscriptItem) {
        setTranscriptList((prevTranscriptList) => [
          ...prevTranscriptList,
          { transcript, delta: elapsedTime },
        ]);
      }
    },
    [elapsedTime, setTranscriptList, setUpSendTranscript, transcriptList],
  );

  useEffect(() => {
    if (!trascriptionFinished && conferenceFinished) {
      setTrascriptionFinished(true);
      clearSendTranscript();
      sendTranscript();
    }
  }, [conferenceFinished, sendTranscript, trascriptionFinished]);

  useEffect(
    () => () => {
      clearSendTranscript();
    },
    [],
  );

  return {
    addTranscriptItem,
    transcriptList,
    sendTranscript,
  };
};
