import {
  MaterialUICore,
  tokens,
  axiomTokens,
  Chip as ChipDS,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing } = tokens;

export const Chip = styled(ChipDS)(({ theme }) => ({
  backgroundColor: `rgba( 0, 0, 0, ${axiomTokens.opacity.level['6']})`,
  color: color.neutral[0],
  zIndex: 1,
  border: 'none',
  '& .MuiChip-icon': {
    color: color.neutral[0],
    marginRight: 0,
    [theme.breakpoints.down(320)]: {
      marginRight: spacing.nano,
    },
  },
  '& .MuiChip-label': {
    display: 'flex',
  },
}));
