import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  axiomTokens,
  Box,
  Body,
  Button,
  Container,
  Paper,
  Pagination,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogActions,
  MaterialIcons,
} from '@iclinic/design-system';

import * as Styles from './styles';
import {
  getAppointmentGuideList,
  getUser,
  getPhysiciansAndClinics,
} from 'features/tissInvoicing/state/appointmentGuideList/selectors';
import { actions as appointmentGuideListActions } from 'features/tissInvoicing/state/appointmentGuideList';
import TableGuideList, { AppointmentGuideDefinition } from './Table';
import HeaderGuideList from './Header';
import Snackbar from 'features/tissInvoicing/components/ui/Snackbar';
import getSnackbarPayload from 'features/tissInvoicing/utils/getSnackbarPayload';
import LoadingOrNotInfo from 'features/tissInvoicing/components/ui/LoadingOrNotInfo';
import FilterAppointmentGuide from './Filter';
import USER_KIND from 'features/schedule/constants';

const { Delete } = MaterialIcons;

type FetchAppointmentGuideData = {
  page?: number;
  physician_id?: string;
};

export default function GuideList() {
  const dispatch = useDispatch();

  const appointmentGuideList = useSelector(getAppointmentGuideList);
  const { userData, clinicId } = useSelector(getUser);
  const { physicians, clinics } = useSelector(getPhysiciansAndClinics);

  const [deletedItens, setDeletedItens] = useState<
    AppointmentGuideDefinition[]
  >([]);
  const [openModalDeleted, setOpenModalDeleted] = useState<boolean>(false);
  const [checkboxState, setCheckboxState] = useState<
    AppointmentGuideDefinition[]
  >(
    appointmentGuideList.list?.map((checkbox) => ({
      ...checkbox,
      checked: false,
    })) ?? [],
  );

  const handleCloseSnackbar = () => {
    dispatch(
      appointmentGuideListActions.setSnackbarContent(getSnackbarPayload()),
    );
  };

  const fetchAppointmentGuideData = useCallback(
    ({ page, physician_id }: FetchAppointmentGuideData) => {
      dispatch(
        appointmentGuideListActions.fetchAppointmentGuideList({
          limit: 10,
          page,
          physician_id,
        }),
      );
    },
    [dispatch],
  );

  const deleteAppointmentGuide = useCallback(
    (IDs: string[]) => {
      dispatch(
        appointmentGuideListActions.deleteAppointmentguide({
          IDs,
        }),
      );
    },
    [dispatch],
  );

  const handlePaginate = (_: React.ChangeEvent<unknown>, page: number) => {
    if (appointmentGuideList.paginate?.pageCurrent !== page) {
      fetchAppointmentGuideData({ page });
    }
  };

  const handleCheckAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setDeletedItens(checkboxState);
    } else {
      setDeletedItens([]);
    }

    setCheckboxState((prevCheckboxState) =>
      prevCheckboxState.map((checkbox) => ({
        ...checkbox,
        checked: isChecked,
      })),
    );
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    appointment: AppointmentGuideDefinition,
  ) => {
    const { id, checked } = event.target;

    setDeletedItens((prevDeletedItens) => {
      const deletedItensData = prevDeletedItens.find((item) => item.id === id);

      if (deletedItensData) {
        let itemRemoved = false;

        return prevDeletedItens.filter((item) => {
          if (item.id === appointment.id && !itemRemoved) {
            itemRemoved = true;
            return false;
          }
          return true;
        });
      }

      return [...prevDeletedItens, appointment];
    });

    setCheckboxState((prevCheckboxState) =>
      prevCheckboxState.map((checkbox) => {
        if (checkbox.id === id) {
          return { ...checkbox, checked };
        }
        return checkbox;
      }),
    );
  };

  const handleCloseModal = () => {
    setOpenModalDeleted(false);
    setDeletedItens([]);

    setCheckboxState((prevCheckboxState) =>
      prevCheckboxState.map((checkbox) => ({
        ...checkbox,
        checked: false,
      })),
    );
  };

  const handleOpenModal = () => {
    setOpenModalDeleted(true);
  };

  const handleDeleteItem = () => {
    deleteAppointmentGuide(deletedItens.map((item) => item.id));

    setCheckboxState((prevCheckboxState) =>
      prevCheckboxState.filter((checkbox) => !checkbox.checked),
    );

    setOpenModalDeleted(false);
  };

  const handleChangeFilter = (physician_id: string) => {
    fetchAppointmentGuideData({
      page: 1,
      physician_id,
    });
  };

  useEffect(() => {
    fetchAppointmentGuideData({
      physician_id: userData.physicianId?.toString(),
    });
    dispatch(appointmentGuideListActions.fetchTissVersions());
  }, [dispatch, fetchAppointmentGuideData, userData.physicianId]);

  useEffect(() => {
    setCheckboxState(
      appointmentGuideList.list?.map((checkbox) => ({
        ...checkbox,
        checked: false,
      })) ?? [],
    );
  }, [appointmentGuideList.list]);

  return (
    <Container maxWidth="xl">
      <Snackbar
        data={appointmentGuideList.snackbar}
        onClose={handleCloseSnackbar}
      />

      <HeaderGuideList />

      <Paper variant="outlined">
        {userData.userKind === USER_KIND.RECEPTIONIST && (
          <FilterAppointmentGuide
            onChange={handleChangeFilter}
            clinics={clinics}
            physicians={physicians}
            clinicId={Number(clinicId)}
            physicianId={userData.physicianId}
          />
        )}

        {checkboxState?.length ? (
          <>
            {deletedItens.length > 0 && (
              <Styles.ButtonDelete
                color="transparent"
                startIcon={<Delete />}
                onClick={handleOpenModal}
              >
                Deletar
              </Styles.ButtonDelete>
            )}

            <TableGuideList
              checkboxState={checkboxState}
              handleCheckAllChange={handleCheckAllChange}
              handleCheckboxChange={handleCheckboxChange}
            />

            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              margin={axiomTokens.spacing['4']}
            >
              <Pagination
                count={appointmentGuideList.paginate?.totalPages}
                onChange={handlePaginate}
              />
            </Box>
          </>
        ) : (
          <LoadingOrNotInfo
            titleLoading="Carregando informações"
            notInfo="Não há Guias de consulta."
            loading={appointmentGuideList.loading}
          />
        )}
      </Paper>
      <Dialog open={openModalDeleted}>
        <DialogHeader
          title="Deseja excluir as guias selecionadas?"
          onClose={handleCloseModal}
        />
        <DialogContent dividers>
          <Body bold={false} variant="sm" inverseTextColor={false}>
            Excluindo as guias selecionadas não será possível recuperá-las.
          </Body>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            variant="text"
            size="medium"
            fullWidth={false}
            endIcon={false}
            startIcon={false}
          >
            Cancelar
          </Button>
          <Button onClick={handleDeleteItem} size="medium">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
