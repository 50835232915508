import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Alert, Button, MaterialIcons, Snackbar } from '@iclinic/design-system';
import { ERROR_WAITLIST } from '../../constants';
import { getZipCodeAvailability } from '../../state/request-samples/selectors';

const { Warning } = MaterialIcons;

const SnackbarZipCodeNotAvailable = () => {
  const available = useSelector(getZipCodeAvailability);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (available !== undefined && !available) {
      setOpen(true);
    }
  }, [available]);

  const handleCloseMessage = () => {
    setOpen(false);
  };

  if (available === undefined) return null;

  return (
    <Snackbar
      open={open}
      onClose={handleCloseMessage}
      severity={ERROR_WAITLIST.severity}
      autoHideDuration={6000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert
        severity={ERROR_WAITLIST.severity}
        icon={<Warning />}
        title={ERROR_WAITLIST.title}
        message={ERROR_WAITLIST.content}
        action={
          <Button color="transparent" size="large" onClick={handleCloseMessage}>
            Fechar
          </Button>
        }
      />
    </Snackbar>
  );
};

export default SnackbarZipCodeNotAvailable;
