import { createSelector } from 'reselect';

import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';
import { ExportDownload } from './types';

export const getExportedDownload = ({
  exportClinicData,
}: StoreState): ExportDownload | null | undefined => exportClinicData.exportDownload.requestedExport?.data;

export const getExportedDownloadStatus = ({
  exportClinicData,
}: StoreState): RequestStatus | undefined => exportClinicData.exportDownload.requestedExport?.status;

export const isFetchingExportedList = createSelector(
    getExportedDownloadStatus,
  (exportDownloadFetchStatus) => exportDownloadFetchStatus === RequestStatus.Pending,
);
