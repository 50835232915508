import { Response } from '@types';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  createExpense,
  deleteExpense,
  fetchExpenseAutocomplete,
  fetchExpenseDetails,
  fetchExpenseListData,
  updateExpense,
} from 'features/tissInvoicing/services/expense';
import {
  ExpenseDetailPayload,
  FetchExpenseDetailResponse,
  FetchExpenseListResponse,
} from 'features/tissInvoicing/types';
import history from 'routes/history';
import { captureException } from 'shared/utils/handlerErrors';
import { actions } from '.';
import { formatAutocompleteItems } from '../../utils/formatAutocompleteItems';
import { getExpenseListCurrentPage } from './selectors';

type FetchExpenseListPayload = ReturnType<typeof actions.fetchExpenseList>;
type DeleteExpensePayload = ReturnType<typeof actions.deleteExpense>;
export type CreateExpensePayload = ReturnType<
  typeof actions.createExpenseFetch
>;
type FetchAutocompletePayload = ReturnType<any>;

export function* fetchExpenseListWorker({
  payload: { page },
}: FetchExpenseListPayload) {
  try {
    yield put(actions.setExpenseListFetchStatus(true));
    const { data }: Response<FetchExpenseListResponse> = yield call(
      fetchExpenseListData,
      {
        page,
      },
    );

    yield put(
      actions.setExpenseList({
        hasMoreData: data.next !== null,
        expenses: data.results,
        loading: false,
      }),
    );
  } catch (error) {
    captureException(error);
    yield put(actions.setExpenseListFetchStatus(false));
  }
}

export function* createExpenseWorker({ payload: body }: CreateExpensePayload) {
  try {
    yield put(actions.createExpenseStatus({ loading: true }));

    const formatBody = {
      id: body.id,
      tuss_procedure_id: body?.procedure?.id,
      insurance_id: body?.healthInsurance?.id,
      insurance_name: body?.healthInsurance?.title,
      expenses: body?.expenses?.map(
        ({
          type,
          tuss_code,
          quantity,
          unity_type,
          value,
          id,
          tuss_expense,
        }) => ({
          ...(Number.isNaN(id) && { id }),
          type: typeof type === 'object' ? type?.id : type,
          tuss_expense_id: tuss_expense?.id ?? Number(tuss_code?.id),
          unit_type: unity_type?.id,
          quantity,
          value,
        }),
      ),
    };

    let response: Response<unknown> = {
      data: '',
      status: 404,
      statusText: '404',
      headers: {},
      config: {},
    };
    let errorMessage: string = '';

    if (!body?.id) {
      delete formatBody.id;
      response = yield call(createExpense, formatBody);
      errorMessage = 'Não foi possível criar uma nova despesa';
    } else {
      response = yield call(updateExpense, body.id, formatBody);
      errorMessage = 'Não foi possível atualizar a despesa';
    }

    if (!response.data) throw new Error(errorMessage);

    history.push(`/faturamento-tiss/despesas`);
    yield put(actions.createExpenseSuccess(true));
  } catch (error) {
    yield call(captureException, error);
    yield put(
      actions.createExpenseFailure({
        loading: false,
        error: true,
      }),
    );
  }
}

export function* fetchAutocomplete({ payload }: FetchAutocompletePayload) {
  try {
    const { name, ...apiPayload } = payload;

    const { data }: Response<any> = yield call(
      fetchExpenseAutocomplete,
      apiPayload,
    );

    let items = data?.objects ?? data?.results ?? data;

    if (items) {
      items = items.map((item: any) => formatAutocompleteItems(item, name));

      yield put(actions.setExpenseAutocomplete({ name, items }));
    }
  } catch (error) {
    captureException(error);
  }
}

export function* getExpenseDetails({ payload }: ExpenseDetailPayload) {
  try {
    yield put(actions.getExpenseDetailStatus({ loading: true }));
    const { id } = payload;

    const { data }: Response<FetchExpenseDetailResponse> = yield call(
      fetchExpenseDetails,
      id,
    );

    if (!data) {
      throw new Error('Falha ao carregar dados do servidor');
    }

    const { insurance, tuss_procedure, expenses } = data;

    const result = {
      healthInsurance: {
        id: insurance.id,
        title: insurance.name,
      },
      procedure: {
        id: tuss_procedure.id,
        title: `${tuss_procedure.code} - ${tuss_procedure.term}`,
      },
      expenses,
    };

    yield put(actions.getExpenseDetailsSuccess(true));
    yield put(actions.setExpenseDetails({ createExpense: result }));
  } catch (error) {
    yield put(
      actions.getExpenseDetailsFailure({
        loading: false,
        error: true,
      }),
    );
    captureException(error);
  }
}

export function* deleteExpenseWorker({
  payload: { id },
}: DeleteExpensePayload) {
  try {
    yield call(deleteExpense, id);

    const currentPage: number = yield select((state) =>
      getExpenseListCurrentPage(state),
    );

    yield call(fetchExpenseListData, { page: currentPage });
  } catch (error) {
    captureException(error);
  }
}

export default function* watchExpenseWorker() {
  yield takeLatest(actions.fetchExpenseList, fetchExpenseListWorker);
  yield takeLatest(actions.createExpenseFetch, createExpenseWorker);
  yield takeEvery(actions.fetchExpenseAutocomplete, fetchAutocomplete);
  yield takeEvery(actions.getExpenseDetailsFetch, getExpenseDetails);
  yield takeEvery(actions.deleteExpense, deleteExpenseWorker);
}
