import {
  HighlightsInfoTextCardResponse,
  HighlightsInfoListCardResponse,
  HighlightsInfoListCardDeniedResponse,
  HighlightsInfoResponseType,
  SearchDbHighlightsInfoListResponse,
} from './state/services/types';
import {
  HighlightsInfoTextCard,
  TextCardType,
  HighlightsInfoListCard,
  ListCardType,
  HighlightsInfoListCardDenied,
  HighlightsInfoType,
  CardKind,
  CardTypeTracking,
} from './types';

export const TypeCardMap = {
  1: 'Antec. clínicos',
  2: 'Antec. cirúrgicos',
  3: 'Alergias',
  4: 'Antec. familiares',
  5: 'Medicamentos em uso',
  6: 'Hábitos',
  7: 'Últimos diagnósticos',
};

const parseHighlightsInfoTextCard = (
  highlightInfo: HighlightsInfoTextCardResponse,
): HighlightsInfoTextCard => ({
  content: highlightInfo.content,
  id: highlightInfo.id,
  eventId: highlightInfo.event_id,
  timeCreated: highlightInfo.time_created,
  typeCard: highlightInfo.type_card as TextCardType,
  kind: highlightInfo.kind,
});

const parseHighlightsInfoListCard = (
  highlightInfo: HighlightsInfoListCardResponse,
): HighlightsInfoListCard => ({
  items: highlightInfo.items.map((item) => ({
    description: item.description,
    itemId: item.item_id,
    isActive: item.is_active,
    subtitle: item.subtitle,
    timeCreated: item.time_created,
    title: item.title,
  })),
  typeCard: highlightInfo.type_card as ListCardType,
  kind: highlightInfo.kind,
});

const parseHighlightsInfoListCardDenied = (
  highlightInfo: HighlightsInfoListCardDeniedResponse,
): HighlightsInfoListCardDenied => ({
  typeCard: highlightInfo.type_card as ListCardType,
  kind: highlightInfo.kind,
  isDenied: highlightInfo.is_denied,
});

export const parseHighlightsInfo = (
  highlightInfo: HighlightsInfoResponseType,
): HighlightsInfoType => {
  if (highlightInfo.kind === CardKind.Text)
    return parseHighlightsInfoTextCard(
      highlightInfo as HighlightsInfoTextCardResponse,
    );
  if (
    typeof (highlightInfo as HighlightsInfoListCardDeniedResponse).is_denied ===
    'boolean'
  ) {
    return parseHighlightsInfoListCardDenied(
      highlightInfo as HighlightsInfoListCardDeniedResponse,
    );
  }
  return parseHighlightsInfoListCard(
    highlightInfo as HighlightsInfoListCardResponse,
  );
};

export const parseHighlightsInfoTextCardToApi = (
  highlightInfo: HighlightsInfoTextCard,
): HighlightsInfoTextCardResponse => ({
  content: highlightInfo.content,
  id: highlightInfo.id,
  event_id: highlightInfo.eventId,
  time_created: highlightInfo.timeCreated,
  type_card: highlightInfo.typeCard,
  kind: highlightInfo.kind,
});

export const parseHighlightsInfoListCardToApi = (
  highlightInfo: HighlightsInfoListCard,
): HighlightsInfoListCardResponse => ({
  items: highlightInfo.items.map((item) => ({
    description: item.description,
    item_id: item.itemId,
    is_active: item.isActive,
    subtitle: item.subtitle,
    time_created: item.timeCreated,
    title: item.title,
  })),
  type_card: highlightInfo.typeCard,
  kind: highlightInfo.kind,
  source: '1',
});

export const parseHighlightsInfoListCardDeniedToApi = (
  highlightInfo: HighlightsInfoListCardDenied,
): HighlightsInfoListCardDeniedResponse => ({
  is_denied: highlightInfo.isDenied,
  type_card: highlightInfo.typeCard,
  kind: highlightInfo.kind,
});

export const parseHighlightsInfoToApi = (
  highlightInfo: HighlightsInfoType,
): HighlightsInfoResponseType => {
  if (highlightInfo.kind === CardKind.Text)
    return parseHighlightsInfoTextCardToApi(
      highlightInfo as HighlightsInfoTextCard,
    );

  if (
    typeof (highlightInfo as HighlightsInfoListCardDenied).isDenied ===
    'boolean'
  ) {
    return parseHighlightsInfoListCardDeniedToApi(
      highlightInfo as HighlightsInfoListCardDenied,
    );
  }

  return parseHighlightsInfoListCardToApi(
    highlightInfo as HighlightsInfoListCard,
  );
};

export function getHighlightInfoClickedOnNavigation(
  direction: string,
  highlightsInfoList: HighlightsInfoType[],
  highlightInfoSelected: HighlightsInfoType,
) {
  const currentIndex = highlightsInfoList.findIndex(
    (card) => card.typeCard === highlightInfoSelected.typeCard,
  );

  const offset = direction === 'next' ? 1 : -1;
  const nextIndex =
    (currentIndex + offset + highlightsInfoList.length) %
    highlightsInfoList.length;

  return highlightsInfoList[nextIndex];
}

export function getPreviousAndNextHighlightInfoCardTypes(
  highlightsInfoList: HighlightsInfoType[],
  highlightInfoSelected: HighlightsInfoType,
) {
  const currentIndex = highlightsInfoList.findIndex(
    (card) => card.typeCard === highlightInfoSelected.typeCard,
  );
  const previousIndex =
    currentIndex === 0 ? highlightsInfoList.length - 1 : currentIndex - 1;
  const nextIndex =
    currentIndex === highlightsInfoList.length - 1 ? 0 : currentIndex + 1;

  const previousCardType =
    TypeCardMap[highlightsInfoList[previousIndex].typeCard];
  const nextCardType = TypeCardMap[highlightsInfoList[nextIndex].typeCard];

  return {
    previousCardType,
    nextCardType,
  };
}

export const getDeniedTitle = (highlightsInfo: HighlightsInfoType) =>
  highlightsInfo.typeCard === ListCardType.Alergies
    ? 'Paciente nega alergia'
    : 'Paciente nega usar medicações';

export const filterMedications = (
  searchDb: SearchDbHighlightsInfoListResponse,
  highlightsInfoCard: HighlightsInfoType,
) => {
  if (
    typeof (highlightsInfoCard as HighlightsInfoListCardDenied).isDenied ===
    'boolean'
  ) {
    return searchDb.medications;
  }

  const highlightsInfoCardItemsIds = (
    highlightsInfoCard as HighlightsInfoListCard
  ).items.map((item) => item.itemId);

  return searchDb.medications.filter(
    (medication) => highlightsInfoCardItemsIds.indexOf(medication.id) === -1,
  );
};

export function getCardTypeTracking(
  cardType: TextCardType | ListCardType,
): CardTypeTracking {
  const cardTypeMapping = {
    1: CardTypeTracking.medicalHistory,
    2: CardTypeTracking.surgicalHistory,
    3: CardTypeTracking.alergies,
    4: CardTypeTracking.familyHistory,
    5: CardTypeTracking.drugsInUse,
    6: CardTypeTracking.habits,
    7: CardTypeTracking.lastDiagnosis,
  };

  return cardTypeMapping[cardType] || CardTypeTracking.custom;
}
