import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'ui/new/modal';
import Button from 'ui/new/button';
import style from './ConfirmDeleteModal.scss';

const ConfirmDeleteModal = ({ title, onCloseClick, onDeleteCampaign }) => (
  <div className={style.modal}>
    <Modal
      show
      type="danger"
      title={`Excluir "${title}"`}
      onClose={onCloseClick}
      classes={style}
      width={440}
    >
      <Modal.Body classes={style}>
        <p>Esta ação não poderá ser revertida.</p>
      </Modal.Body>
      <Modal.Footer align="right">
        <Button type="error" onClick={onDeleteCampaign}>
          EXCLUIR
        </Button>
        <Button type="light" onClick={onCloseClick}>
          {' '}
          CANCELAR{' '}
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
);

ConfirmDeleteModal.propTypes = {
  title: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onDeleteCampaign: PropTypes.func.isRequired,
};

export default ConfirmDeleteModal;
