import React, { useState, useMemo, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CircularProgress,
  MaterialIcons,
} from '@iclinic/design-system';

import history from 'routes/history';
import {
  getAddGuidesState,
  getLotDataState,
  getLotViewState,
  getSnackbarState,
} from 'features/tissInvoicing/state/lot/selectors';
import { calculateTotalValue } from 'features/tissInvoicing/utils/guidesTotalValue';
import { actions } from 'features/tissInvoicing/state/lot';
import { getGuidesIds } from 'features/tissInvoicing/utils/getGuidesIds';
import { SpaceBetweenBox } from 'features/tissInvoicing/components/ui/Flexbox';
import Snackbar from 'features/tissInvoicing/components/ui/Snackbar';
import getSnackbarPayload from 'features/tissInvoicing/utils/getSnackbarPayload';
import Header from './Header';
import Subheader from './Subheader';
import Table from './Table';
import { MAX_GUIDES_PER_LOT } from 'features/tissInvoicing/state/constants';
import * as Styles from './styles';
import { GuideTypeOptions } from 'features/tissInvoicing/types';

const { Save } = MaterialIcons;

function AddGuidesToLot({
  match: { params },
}: RouteComponentProps<{ lotId: string }>) {
  const { lotId } = params;

  const dispatch = useDispatch();

  const [hasNotAddedNewGuides, setHasNotAddedNewGuides] =
    useState<boolean>(true);
  const [hasInteracted, setHasInteracted] = useState<boolean>(false);

  const { selectedGuides, simplifiedGuides, hasSelectedAllGuides } =
    useSelector(getAddGuidesState);

  const { loading: loadingLotData, lot } = useSelector(getLotDataState);

  const {
    loading: { patchLotData: loadingPatchData },
  } = useSelector(getLotViewState);

  const snackbar = useSelector(getSnackbarState);

  const totalValue = useMemo(() => {
    const filteredGuides = simplifiedGuides.filter((guide) =>
      lot.guides.includes(guide.id),
    );

    return String(calculateTotalValue(filteredGuides));
  }, [lot, simplifiedGuides]);

  useEffect(() => {
    dispatch(actions.fetchLotData({ id: lotId }));
  }, [dispatch, lotId]);

  useEffect(() => {
    if (selectedGuides.length) {
      setHasInteracted(true);
    }
  }, [selectedGuides]);

  const insurance_id = lot?.clinic_health_insurance?.id;

  useEffect(() => {
    if (lotId && insurance_id)
      dispatch(
        actions.fetchAvailableSimplifiedGuides({
          id: lotId,
          insurance_id,
          guide_type: GuideTypeOptions.SPSADT,
        }),
      );
  }, [dispatch, insurance_id, lotId]);

  useEffect(() => {
    dispatch(actions.fetchGuidesFilterPhysicians());
  }, [dispatch]);

  if (lot.is_closed) history.push(`/faturamento-tiss/lotes/${lotId}`);

  const handleCloseSnackbar = () =>
    dispatch(actions.setSnackbarContent(getSnackbarPayload()));

  const addToLotCallback = () => {
    history.push(`/faturamento-tiss/lotes/${lot?.id}`);

    dispatch(
      actions.setSnackbarContent({
        message: 'Guias adicionadas com sucesso',
        severity: 'success',
      }),
    );
  };

  const addToLot = () => {
    if (selectedGuides.length > MAX_GUIDES_PER_LOT) {
      dispatch(
        actions.setSnackbarContent(
          getSnackbarPayload(
            `Não é possível inserir mais de ${MAX_GUIDES_PER_LOT} guias no lote. Ajuste e tente novamente.`,
            'error',
          ),
        ),
      );

      return;
    }

    setHasNotAddedNewGuides(false);
    dispatch(
      actions.patchLotData({
        id: lot?.id,
        guides: getGuidesIds(selectedGuides, lot?.guides),
        callback: addToLotCallback,
      }),
    );
  };

  const setSubheaderAmount = (): number =>
    hasSelectedAllGuides ? simplifiedGuides.length : lot.guides?.length;

  return (
    <Styles.Container>
      <Snackbar data={snackbar} onClose={handleCloseSnackbar} />

      <Header
        lot_id={lotId}
        lot_number={lot?.lot_number}
        shouldShowModal={hasNotAddedNewGuides && hasInteracted}
      />

      {loadingLotData ? (
        <Styles.LoaderContainer>
          <CircularProgress label="Carregando informações do lote" />
        </Styles.LoaderContainer>
      ) : (
        <>
          <Subheader total={totalValue} guides={setSubheaderAmount()} />

          <Table />

          <SpaceBetweenBox padding={2}>
            <Button
              size="small"
              startIcon={loadingPatchData ? <CircularProgress /> : <Save />}
              onClick={addToLot}
              disabled={selectedGuides.length === 0 || loadingPatchData}
            >
              Salvar
            </Button>
          </SpaceBetweenBox>
        </>
      )}
    </Styles.Container>
  );
}

export default AddGuidesToLot;
