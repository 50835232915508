import React from 'react';

// Internal
import Dropdown from 'ui/new/dropdown';

type FlowDropdownProps = {
  description?: string,
  name?: string,
  isOpen?: boolean,
  children: React.ReactNode,
  toggleOpen: () => void,
};

export default function DropdownFlows({
  isOpen = false,
  description = 'Selecione o fluxo de e-mail automático',
  name = '',
  children,
  toggleOpen,
}: FlowDropdownProps): JSX.Element {
  return (
    <Dropdown
      icon={false}
      name={name}
      title={description}
      isOpenProp={isOpen}
      setToggleMenuProp={toggleOpen}
    >
      {children}
    </Dropdown>
  );
}
