import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, tokens } from '@iclinic/design-system';

import {
  getLoadingStatus,
  getUserKind,
  getUserSubscriptionPlan,
} from './state/selectors';
import { upgradeCallContent } from './data';
import { openUpgradeModal } from './state/actions';
import PhysicianContent from './components/PhysicianContent';
import { UserKinds } from './types';
import { getContentBySubscriptionPlan } from './utils';
import { getUrlParams } from 'shared/utils/url';
import { dispatchGaEvent } from 'shared/utils/googleAnalytics';
import { UpgradeCallCard } from './components/UpgradeCallCard';

const UpgradeCall = () => {
  const upgrade = getUrlParams('upgrade-modal');
  const subscriptionPlan = useSelector(getUserSubscriptionPlan);
  const isLoading = useSelector(getLoadingStatus);
  const userKind = useSelector(getUserKind);
  const dispatch = useDispatch();
  const isPhysician = userKind === UserKinds.physician;

  if (!userKind || !subscriptionPlan) return null;
  const contentByKindOfUser = upgradeCallContent.get(userKind);

  const content = getContentBySubscriptionPlan(
    contentByKindOfUser,
    subscriptionPlan,
  );

  if (!content) return null;

  const { title, imagePath, planToUpgrade } = content;

  if (upgrade) {
    dispatch(openUpgradeModal(planToUpgrade));
    dispatchGaEvent('data_ga_click', {
      event_name: 'setup_subscription_upgrade_click',
    });
  }

  const onClickHandler = () => {
    dispatch(openUpgradeModal(planToUpgrade));
    dispatchGaEvent('data_ga_click', {
      event_name: 'setup_subscription_upgrade_click',
    });
  };

  const buttonText = isPhysician ? 'Quero mudar de plano' : 'Quero saber mais';

  return (
    <UpgradeCallCard
      imagePath={imagePath}
      title={title}
      onImageClick={onClickHandler}
      imageAlt={`mude para o plano ${planToUpgrade}`}
    >
      {isPhysician ? (
        <PhysicianContent content={content} />
      ) : (
        <Box>{content.innerComponent}</Box>
      )}
      <Box mt={tokens.spacing.md}>
        <Button
          isLoading={isLoading}
          data-sp="setup_subscription_upgrade_click"
          onClick={onClickHandler}
        >
          {buttonText}
        </Button>
      </Box>
    </UpgradeCallCard>
  );
};

export default UpgradeCall;
