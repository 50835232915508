import {
  Body,
  Button,
  MaterialUICore,
  axiomTokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const TextSendMsgSignatory = styled(Body)({
  color: axiomTokens.color.typeface.pure,
});

export const ContainerChooseSendForm = styled('div')({
  display: 'flex',
  alignItems: 'center',
  alignContent: 'center',
});

export const LineDivider = styled('div')({
  height: 1,
  backgroundColor: axiomTokens.color.others.divider,
  flex: 1,
});

export const TextChooseSendForm = styled(Body)({
  padding: `0 ${axiomTokens.spacing[4]}`,
  color: axiomTokens.color.typeface.pure,
});

export const ButtonChooseSendForm = styled('div')(({ theme }) => ({
  flex: 1,

  [theme.breakpoints.down(768)]: {
    width: '100%',
  },
}));

export const ContainerButtons = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  alignContent: 'center',
  flexWrap: 'wrap',
  gap: axiomTokens.spacing[3],
  marginBottom: axiomTokens.spacing[4],
  marginTop: axiomTokens.spacing[5],

  [theme.breakpoints.down(768)]: {
    flexDirection: 'column',
  },
}));

export const ContainerButtonsAndMessage = styled('div')({
  width: '100%',
});

export const ButtonContent = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down(768)]: {},
}));
