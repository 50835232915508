import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import Plans from '../containers/plans';

const Routes = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route exact path={match.path} component={Plans} />
    <Redirect to="/404/" />
  </Switch>
);

export default Routes;
