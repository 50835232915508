import { combineReducers } from 'redux';

import transactions, {
  TransactionsState,
  initialState as initialStateTransactions,
} from './transactions';

export type ReportsState = {
  transactions: TransactionsState;
};

export const reporstInitialState = {
  transactions: initialStateTransactions,
};

const reportsReducer = combineReducers<ReportsState>({
  transactions,
});

export default reportsReducer;
