import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  Details,
  ScheduleConfirmation,
  SelectionOfProfessionals,
  PackageSelector,
  ScheduleReminder,
  Home,
} from '../pages';
import PermissedRoute from './PermissedRoute';
import { MenuSlug } from '../state/types';

const MessagingSignedRoutes = () => (
  <Switch>
    <Route exact path="/relacionamento" component={Home} />

    <PermissedRoute
      exact
      path="/relacionamento/confirmacao-de-agenda"
      component={ScheduleConfirmation}
      permission={MenuSlug.CONFIRMATION}
    />

    <PermissedRoute
      exact
      path="/relacionamento/pacote-de-mensagem"
      component={SelectionOfProfessionals}
      permission={MenuSlug.PACKAGE}
    />

      <PermissedRoute
      exact
      path="/relacionamento/lembrete-de-agenda"
      component={ScheduleReminder}
      permission={MenuSlug.REMINDER}
    />

    <Route
      exact
      path="/relacionamento/pacote-de-mensagem/escolher-pacote"
      component={PackageSelector}
      permission={MenuSlug.PACKAGE}
    />

    <Route
      exact
      path="/relacionamento/pacote-de-mensagem/detalhes"
      component={Details}
    />

    <Redirect to="/404" />
  </Switch>
);

export { MessagingSignedRoutes };
