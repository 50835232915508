import { captureException } from '@sentry/browser';

export const canShare = (data: Record<string, string>) =>
  window.navigator && navigator.canShare && navigator.canShare(data);

export const dispatchWebShare = async (data: Record<string, string>) => {
  if (!canShare(data)) return false;
  try {
    await navigator.share(data);
    return true;
  } catch (err) {
    captureException(err);
    return false;
  }
};
