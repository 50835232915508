import React from 'react';

import Modal from 'ui/new/modal';
import Button from 'ui/new/button';

import style from './ConfirmDeleteModal.scss';

interface StateProps {
  name: string;
}

interface DispatchProps {
  onCloseClick: () => void;
  onDeleteEmailFlow: () => void;
}

interface ConfirmDeleteModalProps extends StateProps, DispatchProps {}

const ConfirmDeleteModal = ({
  name,
  onCloseClick,
  onDeleteEmailFlow,
}: ConfirmDeleteModalProps): JSX.Element => (
  <div className={style.modal}>
    <Modal
      show
      type="danger"
      title={`Deseja excluir "${name}"`}
      onClose={onCloseClick}
      classes={style}
      width={440}
    >
      <Modal.Body classes={style}>
        <p>Esta ação não poderá ser revertida.</p>
      </Modal.Body>
      <Modal.Footer align="right">
        <Button type="light" onClick={onCloseClick}>CANCELAR</Button>
        <Button type="error" onClick={onDeleteEmailFlow}>EXCLUIR</Button>
      </Modal.Footer>
    </Modal>
  </div>

);

export default ConfirmDeleteModal;
