import React from 'react';

import { MaterialUICore, tokens, Body, Box } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, border, spacing } = tokens;

type MessageBubbleProps = {
  isLocalParticipant: boolean;
};

export const TextMessageContainer = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
}));

export const MessageBubble = styled(
  ({
    isLocalParticipant,
    ...rest
  }: MessageBubbleProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >) => <div {...rest} />,
)({
  display: 'flex',
  alignItems: 'flex-start',
  padding: `${spacing.nano} ${spacing.base}`,
  backgroundColor: ({ isLocalParticipant }: MessageBubbleProps) =>
    isLocalParticipant ? color.secondary.main : color.neutral[25],
  borderRadius: border.radius.md,
  margin: `${spacing.nano} 0`,
  wordBreak: 'break-word',
});

export const MessageText = styled(Body)(() => ({
  color: color.neutral[900],
  margin: `0 ${spacing.base}`,
}));
