import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from 'shared/constants/State';

export interface ResetPasswordRequestState {
  status: RequestStatus;
  error: string | null;
  email: string | null;
}

export const initialState: ResetPasswordRequestState = {
  status: RequestStatus.Ready,
  error: null,
  email: null,
};

const slice = createSlice({
  name: '@webapp/auth2/reset-password-request',
  initialState,
  reducers: {
    submit: (state, action: PayloadAction<string>) => ({
      ...state,
      status: RequestStatus.Pending,
      email: action.payload,
      error: null,
    }),
    error: (state, action: PayloadAction<string>) => ({
      ...state,
      status: RequestStatus.Error,
      error: action.payload || 'UnknownError',
    }),
    success: (state) => ({
      ...state,
      status: RequestStatus.Success,
      error: null,
    }),
  },
});

export const { submit, error, success } = slice.actions;
export default slice.reducer;
