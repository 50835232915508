import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles(() => ({
  email: {
    height: '48px',
    '& .MuiFilledInput-input': {
      padding: '27px 8px 0',
    },
  },
}));
