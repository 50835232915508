import { AxiosError, AxiosRequestConfig } from 'axios';

import { captureException } from 'shared/utils/handlerErrors';
import { getCheckoutTokenUrl } from 'features/onlinePayment/state/checkout/infos/utils';
import { GENERIC_ERROR_MESSAGE } from './constants';

export const checkoutTokenInterceptor = (
  request: AxiosRequestConfig,
): AxiosRequestConfig => {
  const token = getCheckoutTokenUrl();

  if (!token) {
    throw new Error(`missing token in URL query parameters`);
  }

  return {
    ...request,
    headers: {
      ...request.headers,
      Authorization: `JWT ${token}`,
    },
  };
};

export const genericResponseErrorsInterceptor = (error: AxiosError) => {
  try {
    if (!error.response) return Promise.reject(error);

    const { data } = error.response;

    const message =
      typeof data.error === 'string' ? data.error : GENERIC_ERROR_MESSAGE;

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      ...error.response,
      message,
    });
  } catch (err) {
    captureException(err);
    return error;
  }
};
