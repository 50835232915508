import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles(() => ({
  filtersButtons: {
    height: '56px',
  },
}));

export default useStyles;
