import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, MaterialIcons } from '@iclinic/design-system';

import { getCreditCard } from 'state/creditcard/selectors';
import * as S from './SuccessSnackbar.styles';

const { CheckCircleRounded, Close } = MaterialIcons;
const SNACKBAR_TIMEOUT = 3000;

export const SuccessSnackbar = () => {
  const [isOpened, setOpened] = useState(true);
  const { saveFeedback } = useSelector(getCreditCard);

  const { title } = saveFeedback;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOpened(false);
    }, SNACKBAR_TIMEOUT);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleClose = () => {
    setOpened(false);
  };

  return (
    <S.Snackbar
      open={isOpened}
      severity="success"
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      message={
        <Alert severity="success" icon={<CheckCircleRounded />} title={title} />
      }
      action={
        <S.IconButton
          label="fechar"
          name="fechar"
          tooltip="none"
          aria-label="fechar"
          size="sm"
          onClick={handleClose}
        >
          <Close />
        </S.IconButton>
      }
    />
  );
};
