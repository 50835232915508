import React from 'react';
import { Button, Heading, MaterialIcons } from '@iclinic/design-system';

import { Plan as PlanType } from '../../types';
import * as S from './Plan.styles';
import { markAsChecked } from '../../utils';

const { Check } = MaterialIcons;

type PlanProps = {
  plan: PlanType;
  onClick?: (plan: PlanType) => void;
  success?: boolean;
};

export const Plan = ({ plan, onClick, success = false }: PlanProps) => {
  const { messagesAmount, value } = plan;
  const buttonText = success ? 'Selecionado' : 'Selecionar';
  const testId = `fkd_test_mar_2023_whatsapp_${messagesAmount}`;

  const onClickHandler = () => {
    if (onClick) {
      markAsChecked();
      onClick(plan);
    }
  };
  return (
    <S.PlanWrapper id={testId} data-ga={testId} onClick={onClickHandler}>
      <S.PlanTitle variant="xxl">{messagesAmount}</S.PlanTitle>
      <Heading variant="sm">envios mensais</Heading>
      <S.PlanValue variant="md">R$ {value}/mês</S.PlanValue>
      <Button disabled={success} fullWidth startIcon={success && <Check />}>
        {buttonText}
      </Button>
    </S.PlanWrapper>
  );
};
