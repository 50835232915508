import React from 'react';
import classNames from 'classnames';

import { MaterialIcons } from '@iclinic/design-system';
import { image } from 'shared/utils/urlGetter';
import { ThumbContainer, ThumbIconContainer, ThumbImage } from './styles';
import { useConference } from 'features/video-conference/state/conferenceContext';

const { NotInterested, BlurOn } = MaterialIcons;

export type Thumbnail = 'none' | 'blur' | 'image';

interface BackgroundThumbnailProps {
  thumbnail: Thumbnail;
  imagePath?: string;
  name?: string;
  index?: number;
}

export default function BackgroundThumbnail({
  thumbnail,
  imagePath,
  name,
  index,
}: BackgroundThumbnailProps) {
  const { backgroundSettings, setBackgroundSettings } = useConference();

  const isImage = thumbnail === 'image';
  const thumbnailSelected = isImage
    ? backgroundSettings.index === index && backgroundSettings.type === 'image'
    : backgroundSettings.type === thumbnail;

  const icons = {
    none: NotInterested,
    blur: BlurOn,
    image: null,
  };
  const ThumbnailIcon = icons[thumbnail];

  return (
    <ThumbContainer
      title={name}
      className={classNames({ selected: thumbnailSelected })}
      onClick={() =>
        setBackgroundSettings({
          type: thumbnail,
          index,
        })
      }
    >
      {ThumbnailIcon ? (
        <ThumbIconContainer>
          <ThumbnailIcon />
        </ThumbIconContainer>
      ) : (
        <ThumbImage src={image(imagePath)} alt={name} />
      )}
    </ThumbContainer>
  );
}
