import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Typography,
  Box,
  Paper,
  Grid,
  Alert,
  MaterialIcons,
  Link,
} from '@iclinic/design-system';
import {
  getPhysician,
  getClinic,
  getEvent,
  getBill,
} from 'features/onlinePayment/state/checkout/infos/selectors';
import { init } from 'features/onlinePayment/state/checkout/infos/actions';
import EventSummary from 'features/onlinePayment/components/checkout/success/EventSummary';
import SuccessPayment from 'features/onlinePayment/components/checkout/success/SuccessPayment';
import { getEmailIntegration } from 'features/onlinePayment/state/checkout/success/selectors';
import { sendEmailReset } from 'features/onlinePayment/state/checkout/success/actions';
import EmailForm from 'features/onlinePayment/components/checkout/success/EmailForm';
import useStyles from './CheckoutSuccess.styles';

const { CheckCircleOutline, ErrorOutline } = MaterialIcons;

export default function CheckoutSuccess(): JSX.Element {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [physician, clinic, event, bill, emailIntegration] = [
    useSelector(getPhysician),
    useSelector(getClinic),
    useSelector(getEvent),
    useSelector(getBill),
    useSelector(getEmailIntegration),
  ];

  useEffect((): void => {
    dispatch(init());
  }, [dispatch]);

  return (
    <div data-testid="checkout-success">
      <Grid className={classes.container} container>
        <Grid sm={5} item>
          <Paper variant="outlined" className={classes.card}>
            <SuccessPayment
              className={classes.icon}
              title="Ícone pago com sucesso"
            />
            <Box textAlign="center">
              <Typography
                variant="h3"
                classes={{ h3: classes.title }}
                gutterBottom
              >
                Tudo certo!
              </Typography>
              <Typography
                variant="subtitle1"
                classes={{ subtitle1: classes.subTitle }}
              >
                Seu pagamento já está sendo enviado para a clínica.
              </Typography>
            </Box>

            {physician && clinic && event && (
              <Box mt={2}>
                <EventSummary
                  physician={physician}
                  clinic={clinic}
                  event={event}
                  orderNumber={bill?.id}
                />
              </Box>
            )}

            <Box mt={3}>
              <Typography variant="h5">
                Receba o comprovante por e-mail
              </Typography>
              {emailIntegration.done ? (
                <Alert
                  severity="success"
                  icon={<CheckCircleOutline />}
                  message={
                    <>
                      Certo! Em breve enviaremos o comprovante para esse e-mail.{' '}
                      <Link
                        variant="body2"
                        href="#sendto-another-email"
                        onClick={() => {
                          dispatch(sendEmailReset());
                        }}
                        bold
                      >
                        Enviar para outro e-mail
                      </Link>
                    </>
                  }
                />
              ) : (
                <EmailForm />
              )}
              {emailIntegration.error && (
                <Box mt={1}>
                  <Alert
                    severity="error"
                    icon={<ErrorOutline />}
                    message={emailIntegration.error}
                  />
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
