// External
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Internal
import { getPlural } from 'shared/utils/strings';
import style from './Header.scss';

export default function Header({
  name,
  value,
  discount,
  frequencyNumber,
}) {
  return (
    <div
      className={classnames({
        [style.header]: true,
        [style.delimiter__default]: !discount,
        [style.delimiter__discount]: discount > 0,
      })}
    >
      <p className={style.header__title}>{name}</p>
      {discount > 0 && (
        <p className={style.discount}>
          <span className={style.discount__value}>
            {`R$ ${value}`}
          </span>
        </p>
      )}
      <div className={style.product}>
        <p className={style.product__coin}>R$</p>
        <p className={style.product__price}>{discount > 0 ? discount : value}</p>
        <p className={style.product__period}>/mês</p>
      </div>
      <p className={style.header__footer}>por profissional de saúde</p>
      {discount > 0 && (
        <p className={style.discount}>
          {`por ${frequencyNumber} ${getPlural(frequencyNumber, 'meses', 'mês')} *`}
        </p>
      )}
    </div>
  );
}

Header.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  frequencyNumber: PropTypes.number.isRequired,
  discount: PropTypes.number,
};

Header.defaultProps = {
  discount: 0,
};
