// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import { Icon } from '@iclinic/iclinic-ui';
import { Tooltip } from 'ui/overlay';
import style from './Total.scss';

export default function Total({ value, period, content }) {
  return (
    <div className={style.total}>
      <p className={style.total__value}>{`Total R$ ${value}/${period}`}</p>
      <Tooltip content={content} align="top">
        <Icon className={style.price__info} name="info" size={15} />
      </Tooltip>
    </div>
  );
}

Total.propTypes = {
  value: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};
