import { Loader, tokens } from '@iclinic/design-system';
import { Formik } from 'formik';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { FlexColumnBox } from 'features/tissInvoicing/components/ui/Flexbox';
import Snackbar from 'features/tissInvoicing/components/ui/Snackbar';
import BeneficiarySection from 'features/tissInvoicing/containers/sadt/GuideSADT/BeneficiarySection';
import ContainerFooterButtons from 'features/tissInvoicing/containers/components/ContainerFooterButtons';
import GeneralSection from 'features/tissInvoicing/containers/sadt/GuideSADT/GeneralSection';
import ProceduresSection from 'features/tissInvoicing/containers/sadt/GuideSADT/ProceduresSection';
import SessionsSection from 'features/tissInvoicing/containers/sadt/GuideSADT/SessionsSection';
import SolicitorSection from 'features/tissInvoicing/containers/sadt/GuideSADT/SolicitorSection';
import {
  FormKeysEnum,
  validationSadtData,
} from 'features/tissInvoicing/containers/sadt/GuideSADT/validationSchema';
import { actions as domainTableActions } from 'features/tissInvoicing/state/domainTables';
import { actions as autocompleteActions } from 'features/tissInvoicing/state/autocomplete';
import { actions as expenseActions } from 'features/tissInvoicing/state/expense';
import { actions } from 'features/tissInvoicing/state/sadt';
import {
  getClinicHealth,
  getEventState,
  getGuideDataState,
  getLoadingState,
  getPatientState,
  getSadtFormInitalData,
  getSnackbarProps,
} from 'features/tissInvoicing/state/sadt/selectors';
import {
  FetchExpenseAutocompletePayload,
  GuideSADTForm,
} from 'features/tissInvoicing/types';
import ExpenseSection from './ExpenseSection';
import HeaderSection from './HeaderSection';
import * as Styles from './styles';

interface MatchParams {
  eventId?: string;
}

export default function GuideSADT({
  match,
}: RouteComponentProps<MatchParams>): JSX.Element {
  const dispatch = useDispatch();

  const [eventId] = useState(String(match.params?.eventId));

  const initialSadtvalues = useSelector(getSadtFormInitalData);

  const event = useSelector(getEventState);
  const guide = useSelector(getGuideDataState);
  const patient = useSelector(getPatientState);
  const loadingStatus = useSelector(getLoadingState);
  const snackbar = useSelector(getSnackbarProps);
  const clinicHealth = useSelector(getClinicHealth);
  const sectionRef = useRef<Array<HTMLButtonElement | null>>([]);

  const fetchListOfTables = useCallback(() => {
    const payload: FetchExpenseAutocompletePayload = {
      endpoint: '/domains/expensetype/',
      isTissApi: true,
      name: 'typeOptions',
      params: {},
    };
    const payloadUnits: FetchExpenseAutocompletePayload = {
      endpoint: '/domains/unit/',
      isTissApi: true,
      name: 'unitTypeOptions',
      params: {},
    };

    dispatch(expenseActions.fetchExpenseAutocomplete(payload));
    dispatch(expenseActions.fetchExpenseAutocomplete(payloadUnits));
  }, [dispatch]);

  useEffect(() => {
    if (eventId) {
      dispatch(domainTableActions.fetchDomainTables());
      dispatch(autocompleteActions.fetchAutocompleteOptions({ eventId }));
      dispatch(actions.fetchGuideData({ eventId }));
      dispatch(actions.fetchEventData({ eventId }));
      fetchListOfTables();
    }
  }, [dispatch, eventId, fetchListOfTables]);

  useEffect(() => {
    if (event?.physician?.id && event?.insurance?.id) {
      dispatch(
        actions.fetchClinicHealth({
          insurance_id: event.insurance.id,
          physician_id: event.physician.id,
        }),
      );
    }

    if (event?.insurance?.id) {
      dispatch(
        actions.fetchClinicHealthShow({ insurance_id: event.insurance.id }),
      );
    }

    if (event?.patient?.id) {
      dispatch(actions.getPatientData({ patient_id: event.patient.id }));
    }
  }, [dispatch, event]);

  const handleCloseSnackbar = () => {
    dispatch(actions.setSnackbarContent({}));
  };

  const loading = useMemo(
    () =>
      loadingStatus.fetchGuide ||
      loadingStatus.fetchDomainTables ||
      loadingStatus.fetchAutocomplete,
    [loadingStatus],
  );

  const handleSubmit = useCallback(
    (sadtForm: GuideSADTForm) => {
      const payload = {
        guideId: String(guide?.id),
        data: sadtForm,
      };

      if (sadtForm.print) {
        dispatch(
          actions.printGuide({
            ...payload,
            eventId,
            professionalExecutant: clinicHealth.professionalExecutant,
            hiredExecutant: clinicHealth.hiredExecutant,
          }),
        );
        return;
      }

      dispatch(
        actions.saveGuide({
          ...payload,
          professionalExecutant: clinicHealth.professionalExecutant,
          hiredExecutant: clinicHealth.hiredExecutant,
        }),
      );
    },
    [dispatch, eventId, clinicHealth, guide],
  );

  const setSectionRef = (
    element: HTMLButtonElement | null,
    formKey: number,
  ) => {
    if (element) sectionRef.current[formKey] = element;
  };

  const getPatientCivilName = () => {
    if (guide?.patient_civil_name) {
      return guide?.patient_civil_name;
    }

    return patient?.civil_name || patient?.name;
  };

  const getPatientSocialName = () => {
    if (guide?.patient_social_name) {
      return guide?.patient_social_name;
    }

    return patient?.civil_name ? patient?.name : '';
  };

  const getSusCode = () => {
    if (guide?.patient_sus_code) {
      return guide.patient_sus_code;
    }

    return patient?.cns || '';
  };

  return (
    <Styles.Container>
      <Snackbar data={snackbar} onClose={handleCloseSnackbar} />

      {loading ? (
        <Styles.LoaderContainer>
          <Loader size={20} title="Carregando informações da guia" />
        </Styles.LoaderContainer>
      ) : (
        <Styles.Content>
          <Formik
            initialValues={{
              ...initialSadtvalues,
              patient_civil_name: getPatientCivilName(),
              patient_social_name: getPatientSocialName(),
              patient_sus_code: getSusCode(),
              insurance_ans_register:
                clinicHealth?.show?.health_insurance_register,
              insurance_name: clinicHealth?.show?.health_insurance_name,
              patient_id: event?.patient?.id,
            }}
            validationSchema={validationSadtData}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            <Styles.FormContainer
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === 'NumpadEnter')
                  e.preventDefault();
              }}
            >
              <FlexColumnBox width="100%" gridGap={tokens.spacing.sm}>
                <HeaderSection />
                <GeneralSection
                  ref={(el) => {
                    setSectionRef(el, FormKeysEnum.sessionsData);
                  }}
                />
                <BeneficiarySection
                  ref={(el) => {
                    setSectionRef(el, FormKeysEnum.beneficiaryData);
                  }}
                />
                <SolicitorSection
                  ref={(el) => {
                    setSectionRef(el, FormKeysEnum.solicitorData);
                  }}
                  insuranceId={guide?.insurance_id}
                />
                <ProceduresSection
                  ref={(el) => {
                    setSectionRef(el, FormKeysEnum.proceduresData);
                  }}
                />
                <SessionsSection
                  ref={(el) => {
                    setSectionRef(el, FormKeysEnum.sessionsData);
                  }}
                />
                <ExpenseSection
                  ref={(el) => {
                    setSectionRef(el, FormKeysEnum.expensesData);
                  }}
                />
              </FlexColumnBox>
              <ContainerFooterButtons
                sectionRef={sectionRef}
                modalTitle="Guia SP/SADT"
                guideTypeValidator="sadt"
              />
            </Styles.FormContainer>
          </Formik>
        </Styles.Content>
      )}
    </Styles.Container>
  );
}
