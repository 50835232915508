import { AxiosRequestConfig } from 'axios';

import { servicesLabels } from 'features/video-conference/constants';
import { getTelemedicineToken } from 'features/video-conference/utils';

export const interceptorConferenceApiRequestAuthentication = (
  request: AxiosRequestConfig,
): AxiosRequestConfig => {
  const conferenceToken = getTelemedicineToken();

  if (!conferenceToken)
    throw new Error(
      `missing ${servicesLabels.CONFERENCE_TOKEN} in URL query parameters`,
    );

  return {
    ...request,
    params: {
      ...request.params,
      conferenceToken,
    },
  };
};

export const interceptorApiRequestAuthentication = (
  request: AxiosRequestConfig,
) => {
  const tokenValue = global.localStorage.getItem(servicesLabels.AUTH_TOKEN_KEY);

  if (!tokenValue) return request;

  const tokenParam = { params: { token: tokenValue, ...request.params } };
  return Object.assign(request, tokenParam);
};
