import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';

import { Box } from '@iclinic/design-system';
import { TransactionsFiltersForm } from 'features/onlinePayment/state/reports/transactions/types';
import { fetchFilterTransactions } from 'features/onlinePayment/state/reports/transactions';
import { DateFilter } from 'features/onlinePayment/components/reports/transactions';
import { validationTransactionsFilters } from './validationSchema';
import { getTransactionsFiltersInitialData } from './form.normalizer';

export default function TransactionsFilters(): JSX.Element {
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={getTransactionsFiltersInitialData()}
      validationSchema={validationTransactionsFilters}
      onSubmit={(filters: TransactionsFiltersForm) => {
        dispatch(fetchFilterTransactions(filters));
      }}
    >
      <Box mb={3}>
        <Form>
          <DateFilter />
        </Form>
      </Box>
    </Formik>
  );
}
