import omit from 'lodash.omit';

import { removeWhitespaces } from '../../shared/utils/strings';
import { getCreditCardNameByNumber } from '../../shared/utils/creditCard';

const normalizeAddCreditcardPayload = (creditcardPayload) => {
  const [expirationMonth, expirationYear] =
    creditcardPayload.expirationDate.split('/');
  const { number, ...cardProps } = creditcardPayload;
  const normalizedCardProps = omit(cardProps, 'expirationDate');
  const creditCardNumberWithoutWhitespace = removeWhitespaces(number);
  const flagName = getCreditCardNameByNumber(creditCardNumberWithoutWhitespace);
  const flag = flagName.toLowerCase();
  return {
    number: creditCardNumberWithoutWhitespace,
    expirationMonth,
    expirationYear,
    flag,
    ...normalizedCardProps,
  };
};

export { normalizeAddCreditcardPayload };
export default normalizeAddCreditcardPayload;
