import React from 'react';

import { Box, Typography } from '@iclinic/design-system';
import images from 'shared/paths/images';
import { useStyles } from './styles';
import BottomButtonContainer from '../BottomButtonContainer';

export const EmailAlreadyUsedError = () => {
  const { image } = useStyles();
  return (
    <>
      <Box mb={2}>
        <img
          className={image}
          src={images.settings.professionalCartoon}
          alt="rosto feliz"
        />
      </Box>
      <Box mb={1}>
        <Typography align="center" bold>
          Ops, esse email já esta em uso.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography align="center" variant="body2">
          Essa conta do AgendarConsulta já está ativa. Caso queira vincular ao
          novo email clique em Falar com suporte.
        </Typography>
      </Box>
      <BottomButtonContainer leftLabel="Sair" rightLabel="Falar com suporte" />
    </>
  );
};
