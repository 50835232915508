import { Award, TierItem, TierStatus } from '../types';
import { tierMessages } from '../constants';

const FIXED_TIERS_LENGTH = 6;

export function getBeginPositionOfMultipleFive(length: number): number {
  const multipleFiveResult = Math.floor(length / 5) * 5;

  return multipleFiveResult === 0 ? multipleFiveResult : multipleFiveResult - 1;
}

export function getLastAwardFromBeginPositionOfMultipleFive(
  awards: Award[],
  beginPositionOfMultipleFive: number,
) {
  return awards.splice(beginPositionOfMultipleFive);
}

export function generateBaseTiers() {
  return Array<TierItem>(FIXED_TIERS_LENGTH).fill({
    highlighted: false,
    message: '',
    status: TierStatus.INITIAL,
    tooltipActive: false,
    tier: 0,
  });
}

export function normalizeTier(
  tier: TierItem,
  index: number,
  beginPositionOfMultipleFive: number,
  awards: Award[],
) {
  const positionAtAwards =
    beginPositionOfMultipleFive === 0 ? index - 1 : index;
  const tierNumber = beginPositionOfMultipleFive + positionAtAwards + 1;

  const award = awards[positionAtAwards] || null;

  if (award) {
    const rescued = !!award.pay_date;
    const message = rescued ? tierMessages.rescued : tierMessages.reached;

    return {
      ...tier,
      highlighted: !awards[positionAtAwards + 1],
      message,
      status: rescued ? TierStatus.RESCUED : TierStatus.REACHED,
      tier: tierNumber,
      tooltipActive: !rescued,
    };
  }

  const highlighted = index === 0 && awards.length === 0;
  const isReachedStatus =
    (index === 0 && awards.length) || tierNumber < positionAtAwards;
  const valueByTier = tierNumber % 5 === 0 ? 400 : 100;
  const message =
    index === 0
      ? tierMessages.zero
      : tierMessages.youWin(tierNumber, valueByTier);
  const tooltipActive = !!awards && index === 0;

  return {
    ...tier,
    highlighted,
    status: isReachedStatus ? TierStatus.REACHED : TierStatus.INITIAL,
    message,
    tier: tierNumber,
    tooltipActive,
  };
}

export function getTiers(awards?: Award[]) {
  if (!awards) return [];

  const beginPositionOfMultipleFive = getBeginPositionOfMultipleFive(
    awards.length,
  );

  const lastAwards = getLastAwardFromBeginPositionOfMultipleFive(
    [...awards],
    beginPositionOfMultipleFive,
  );

  const baseTiers = generateBaseTiers();

  return baseTiers.map((tier, index) =>
    normalizeTier(tier, index, beginPositionOfMultipleFive, lastAwards),
  );
}

export function getTierConnectionBGColor(
  leftHighlighted: boolean,
  rightTierStatus: TierStatus,
) {
  if (leftHighlighted) return 'gradient';

  if (rightTierStatus !== TierStatus.INITIAL) return 'warning';

  return 'neutral';
}
