import { FlowType } from 'features/marketing/constants';

export const emailFlowInformation = {
  return: `Quando ativada, essa funcionalidade adiciona seus pacientes escolhidos em uma sequência de e-mails. Você pode
  personalizar os e-mails da forma que achar melhor, normalmente ele é usado para lembrar o paciente de marcar um retorno.`,
  custom: '',
  physicians: 'Você está alterando as sequências automáticas de e-mails do:',
  editEmailFlowReturn: `Personalize e configure abaixo a sequência de e-mail do seu fluxo de retorno. Você pode adicionar os
  pacientes neste fluxo e, quando ele tiver um atendimento agendado, ele automaticamente sairá do fluxo.`,
};

export const emailFlowOffset = {
  [FlowType.custom]: 'após paciente entrar no fluxo',
  [FlowType.return]: 'após paciente entrar no fluxo',
};

export const tableColumnKeys = {
  title: 'name',
  activePatients: 'activePatients',
  finishedPatients: 'finishedPatients',
  actions: 'actions',
};

export const tableColumnsCustom = [
  { key: tableColumnKeys.title, label: 'NOME DO E-MAIL PERSONALIZADO' },
  { key: tableColumnKeys.activePatients, label: 'PACIENTES ATIVOS' },
  { key: tableColumnKeys.finishedPatients, label: 'FINALIZARAM O FLUXO' },
  { key: tableColumnKeys.actions, label: '' },
];

export const tableColumns = [
  { key: tableColumnKeys.title, label: 'NOME DO LEMBRETE DE RETORNO' },
  { key: tableColumnKeys.activePatients, label: 'PACIENTES ATIVOS' },
  { key: tableColumnKeys.finishedPatients, label: 'FINALIZARAM O FLUXO' },
  { key: tableColumnKeys.actions, label: '' },
];

export const tableBodyEmptyText = 'Fluxo de e-mail de retorno vazio';
export const tableBodyEmptyTextCustom = 'Fluxo de e-mail personalizado vazio';

export enum Align {
  left = 'left',
  center = 'center',
  right = 'right',
}

export const searchEmailFlowName = 'search-terms';

export enum emailFlowActiveKey {
  active = 'active',
  disable = 'disable',
}

export const EmailFlowButtonDesc = {
  [emailFlowActiveKey.active]: 'SALVAR',
  [emailFlowActiveKey.disable]: 'SALVAR COMO RASCUNHO',
};

export const EmailFlowTitleDesc = {
  [emailFlowActiveKey.active]: 'Desativar envio de e-mails',
  [emailFlowActiveKey.disable]: 'Ativar envio de e-mails',
};
