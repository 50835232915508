import React from 'react';

import { Box, MaterialUICore, Typography } from '@iclinic/design-system';
import { IInsurances } from 'features/settings/scheduleOnline/state/types';

const { Switch } = MaterialUICore;

type InsuranceComponentProps = {
  insurance: IInsurances;
  handleOnChangeInPerson: () => void;
  handleOnChangeTelemedicine: () => void;
};

const InsuranceComponent = ({
  insurance,
  handleOnChangeInPerson,
  handleOnChangeTelemedicine,
}: InsuranceComponentProps) => (
  <Box display="flex" flexDirection="row" alignItems="flex-start" mb={1}>
    <Box width={110} pt={1}>
      <Typography variant="body2" bold>
        {insurance.name}
      </Typography>
    </Box>
    <Box mx={3}>
      <Switch
        color="primary"
        id={`inperson-insurance-${insurance.id}`}
        name={insurance.name}
        checked={insurance.has_online_schedule_active}
        onChange={handleOnChangeInPerson}
      />
    </Box>
    <Box mx={2}>
      <Switch
        color="primary"
        id={`telemedicine-insurance-${insurance.id}`}
        name={insurance.name}
        checked={insurance.allow_online_scheduling_telemedicine}
        onChange={handleOnChangeTelemedicine}
      />
    </Box>
  </Box>
);

export default InsuranceComponent;
