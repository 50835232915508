import {
  Autocomplete,
  Body,
  Button,
  MaterialIcons,
  TextField,
  tokens,
} from '@iclinic/design-system';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getFixedNumber } from 'features/onlinePayment/utils';
import { actions } from 'features/tissInvoicing/state/expense';
import { getExpenseAutocompleteState } from 'features/tissInvoicing/state/expense/selectors';
import { FetchExpenseAutocompletePayload } from 'features/tissInvoicing/types';
import { useDebounce } from 'shared/hooks/debounce';
import useCurrencyMask, { format } from 'shared/hooks/useCurrencyMask';
import { IChangeExpensePayload } from '../../interfaces/ChangeExpensePayload';
import { IExpenseItem } from '../../interfaces/ExpenseItem';
import { ItemContainer, ItemInputContainer, ItemInputGroup } from './styles';

interface AddExpenseExpensesItemProps {
  position: number;
  onClickDelete(): void;
  onChange(payload: IChangeExpensePayload): void;
  item: IExpenseItem;
  showErrors: boolean;
}

const { DeleteForever } = MaterialIcons;

const numberWithTwoDigits = (value: number) =>
  value < 10 ? `0${value}` : `${value}`;

const fetchTussExpense = (codeText?: string, tuss_table?: string) => ({
  endpoint: 'domains/tuss-expense/',
  name: 'codeOptions',
  isTissApi: true,
  params: {
    tuss_table: tuss_table ?? '',
    code__startswith: codeText ?? '',
  },
});

const AddExpenseExpensesItem = ({
  position,
  onClickDelete,
  onChange,
  item,
  showErrors = false,
}: AddExpenseExpensesItemProps) => {
  const dispatch = useDispatch();

  const {
    typeOptions = [],
    unitTypeOptions = [],
    codeOptions = [],
  } = useSelector(getExpenseAutocompleteState);

  const [unitTypeSearchText, setUnitTypeSearchText] = useState('');
  const [codeSearchText, setCodeSearchText] = useState('');

  const debounceUnitTypeText = useDebounce(unitTypeSearchText, 600);
  const debounceCodeText = useDebounce(codeSearchText, 600);

  const itemType = typeof item.type === 'object' ? item.type : undefined;

  const onChangeAutocompleteText = useCallback(
    (payload: FetchExpenseAutocompletePayload) => {
      dispatch(actions.fetchExpenseAutocomplete(payload));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!debounceUnitTypeText?.length) {
      return;
    }

    onChangeAutocompleteText({
      endpoint: '/domains/unit/',
      name: 'unitTypeOptions',
      isTissApi: true,
      params: {
        query: debounceUnitTypeText,
      },
    });
  }, [debounceUnitTypeText, onChangeAutocompleteText]);

  const tussTable = useMemo(() => {
    if (!itemType?.related_tuss_table) {
      return undefined;
    }

    const { code, term } = itemType?.related_tuss_table || {};

    const title = [code, term].filter(Boolean).join(' - ');

    return { term, code, title };
  }, [itemType]);

  useEffect(() => {
    onChangeAutocompleteText(
      fetchTussExpense(debounceCodeText, item.tuss_table),
    );
  }, [debounceCodeText, onChangeAutocompleteText, item.tuss_table]);

  const handleChange = useCallback(
    (fieldName: string, value: any) => {
      onChange({ position, fieldName, value });
      setUnitTypeSearchText(' ');
    },
    [onChange, position],
  );

  const {
    ref,
    onChange: onChangeMinValue,
    originalValue: valueUnformatted,
  } = useCurrencyMask(item?.value || 0);

  const oldValue = useRef<number>();

  useEffect(() => {
    if (oldValue.current === valueUnformatted) {
      return;
    }
    oldValue.current = valueUnformatted;

    handleChange('value', valueUnformatted);
  }, [valueUnformatted, handleChange]);

  const hasErrors = (name: string) => !(!showErrors || (item as any)[name]);

  const handleCodeOnOpen = () => {
    dispatch(
      actions.setExpenseAutocomplete({ name: 'codeOptions', items: [] }),
    );

    onChangeAutocompleteText(
      fetchTussExpense(debounceCodeText, item.tuss_table),
    );
  };

  return (
    <ItemContainer
      data-testid={`expense-form-${numberWithTwoDigits(position)}`}
    >
      <Body
        variant="sm"
        style={{
          marginRight: tokens.spacing.sm,
          padding: `0 ${tokens.spacing.xs}`,
        }}
      >
        {numberWithTwoDigits(position)}
      </Body>

      <ItemInputGroup>
        <ItemInputContainer>
          <Autocomplete
            getOptionLabel={(option: any) => option.title}
            renderInput={(params: any) => (
              <TextField label="Tipo" error={hasErrors('type')} {...params} />
            )}
            onChange={(_, value: any) => handleChange('type', value)}
            options={typeOptions}
            value={item?.type}
            fullWidth
            getOptionSelected={(option) => option.id === itemType?.id}
          />
        </ItemInputContainer>

        <ItemInputContainer>
          <TextField
            disabled
            id="tuss"
            name="tuss"
            type="text"
            label="TUSS"
            placeholder=""
            fullWidth
            value={tussTable?.title || ''}
            error={showErrors && Boolean(!tussTable?.title)}
          />
        </ItemInputContainer>

        <ItemInputContainer>
          <Autocomplete
            options={codeOptions}
            getOptionLabel={(option: any) => option.title}
            renderInput={(params: any) => (
              <TextField
                label="Código"
                error={hasErrors('tuss_code')}
                {...params}
              />
            )}
            onChange={(_, value: any) => handleChange('tuss_code', value)}
            value={item?.tuss_code}
            fullWidth
            onInputChange={(_, text: string, reason: string) => {
              const searchText = reason === 'input' ? text : ' ';
              setCodeSearchText(searchText);
            }}
            onOpen={handleCodeOnOpen}
            noOptionsText=" "
            disabled={!itemType?.id}
          />
        </ItemInputContainer>

        <ItemInputContainer>
          <TextField
            disabled
            id="name"
            name="name"
            type="text"
            label="Nome"
            placeholder=""
            fullWidth
            value={item?.tuss_code?.term || ''}
          />
        </ItemInputContainer>

        <ItemInputContainer>
          <Autocomplete
            options={unitTypeOptions}
            getOptionLabel={(option: any) => option.title}
            renderInput={(params: any) => (
              <TextField
                label="Unidade"
                error={hasErrors('unity_type')}
                {...params}
              />
            )}
            onChange={(_, value: any) => handleChange('unity_type', value)}
            value={item?.unity_type}
            fullWidth
            onInputChange={(_, text: string, reason: string) => {
              const searchText = reason === 'input' ? text : ' ';
              setUnitTypeSearchText(searchText);
            }}
            noOptionsText="Digite algo para buscar"
          />
        </ItemInputContainer>

        <ItemInputContainer>
          <TextField
            id="quantity"
            name="quantity"
            type="number"
            label="Quantidade"
            placeholder=""
            fullWidth
            onChange={(e) => handleChange('quantity', e?.target?.value)}
            value={item?.quantity}
            error={hasErrors('quantity')}
          />
        </ItemInputContainer>

        <ItemInputContainer>
          <TextField
            id="value"
            name="value"
            label="Valor Uni. (R$)"
            placeholder="R$ 0,00"
            defaultValue={format(getFixedNumber(item?.value))}
            inputRef={ref}
            onChange={onChangeMinValue}
            fullWidth
            error={hasErrors('value')}
          />
        </ItemInputContainer>
      </ItemInputGroup>

      <Button
        variant="text"
        color="primary"
        style={{ padding: `0 ${tokens.spacing.sm}` }}
        onClick={onClickDelete}
        data-testid={`expense-delete-${numberWithTwoDigits(position)}`}
      >
        <DeleteForever htmlColor={tokens.color.neutral[500]} />
      </Button>
    </ItemContainer>
  );
};

export default AddExpenseExpensesItem;
