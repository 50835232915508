// External
import { reduxForm, submit, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

export default (form, submitAction) => {
  const SubscriptionFormRedux = ({
    render,
    handleSubmit,
    pristine,
    submitting,
    submitForm,
    number,
  }) => render({
    handleSubmit,
    pristine,
    submitting,
    submitForm,
    number,
  });

  const selector = formValueSelector(form);

  const mapDispatchToProps = {
    submitForm: () => submit(form),
  };

  const mapStateToProps = (state) => ({
    number: selector(state, 'number'),
  });

  const ConnectedSubscriptionFormRedux = (
    connect(mapStateToProps, mapDispatchToProps)(SubscriptionFormRedux)
  );

  return reduxForm({
    form,
    onSubmit: (payload, dispatch) => {
      dispatch(submitAction(payload));
    },
  })(ConnectedSubscriptionFormRedux);
};
