import React from 'react';

import { MaterialIcons, Alert } from '@iclinic/design-system';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import useStyles from './styles';

const { Call } = MaterialIcons;

const PhysicianIsWaitingAlert = (): JSX.Element => {
  const { physicianInfo } = useParticipantContext();
  const classes = useStyles();

  return (
    <Alert
      severity="info"
      message={`${physicianInfo?.name} está esperando para iniciar o seu atendimento.`}
      icon={<Call className={classes.icon} />}
    />
  );
};

export default PhysicianIsWaitingAlert;
