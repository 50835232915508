import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from '@iclinic/design-system';

import {
  getItems,
  getPageCount,
  getPageNumber,
  getStatus,
} from 'features/reports/state/telemedicineReport/selectors';
import { changePageTelemedicineReports } from 'features/reports/state/telemedicineReport/reducer';
import { RequestStatus } from 'shared/constants/State';
import * as S from './TelemedicineTable.styles';

export const TablePagination = () => {
  const dispatch = useDispatch();
  const pageNumber = useSelector(getPageNumber);
  const pageCount = useSelector(getPageCount);
  const rows = useSelector(getItems);
  const status = useSelector(getStatus);

  const moreThanOnePage = pageCount && pageCount > 1;
  const requestSucceded = status === RequestStatus.Success;

  const shouldPaginate = rows.length > 0 && moreThanOnePage && requestSucceded;

  const onPageChangeHandler = (
    _e: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    dispatch(changePageTelemedicineReports(value));
  };

  if (!shouldPaginate) return null;

  return (
    <S.PaginationWrapper>
      <Pagination
        page={pageNumber}
        count={pageCount}
        onChange={onPageChangeHandler}
      />
    </S.PaginationWrapper>
  );
};
