import { createSelector } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { IStoreState } from 'state/rootReducer';

export const isFetchingPaymentSlip = ({ paymentSlip }: IStoreState) =>
  paymentSlip.status === RequestStatus.Pending;

export const getPaymentSlipLink = ({ paymentSlip }: IStoreState) =>
  paymentSlip.paymentSlipData?.link;

export const getPaymentSlipBarCode = ({ paymentSlip }: IStoreState) =>
  paymentSlip.paymentSlipData?.bar_code;

export const getPaymentSlipExpirationDate = ({ paymentSlip }: IStoreState) =>
  paymentSlip.paymentSlipData?.dt_expiration;

export const getPaymentSlipIsExpired = ({ paymentSlip }: IStoreState) =>
  paymentSlip.paymentSlipData?.expired;

export const getPaymentSlipCanRequestRelease = ({ paymentSlip }: IStoreState) =>
  paymentSlip.paymentSlipData?.can_request_release_trust;

export const getPaymentSlipData = createSelector(
  [
    getPaymentSlipLink,
    getPaymentSlipBarCode,
    getPaymentSlipExpirationDate,
    getPaymentSlipIsExpired,
    getPaymentSlipCanRequestRelease,
  ],
  (link, barCode, expirationDate, isExpired, canRequestReleaseTrust) => ({
    link,
    barCode,
    expirationDate,
    isExpired,
    canRequestReleaseTrust,
  }),
);

export const getPaymentSummaryProductsNames = ({ paymentSlip }: IStoreState) =>
  paymentSlip.paymentSummary?.productsNames;

export const getPaymentSummaryDueDate = ({ paymentSlip }: IStoreState) =>
  paymentSlip.paymentSummary?.dueDate;

export const getPaymentSummaryTotal = ({ paymentSlip }: IStoreState) =>
  paymentSlip.paymentSummary?.total;

export const getPaymentSummaryPlanName = ({ paymentSlip }: IStoreState) =>
  paymentSlip.paymentSummary?.planName;

export const getPaymentSummaryTotalUsers = ({ paymentSlip }: IStoreState) =>
  paymentSlip.paymentSummary?.totalUsers;

export const getPaymentSummaryAccountBlocked = ({ paymentSlip }: IStoreState) =>
  paymentSlip.paymentSummary?.isAccountBlocked;

export const getPaymentSummaryData = createSelector(
  [
    getPaymentSummaryProductsNames,
    getPaymentSummaryDueDate,
    getPaymentSummaryTotal,
    getPaymentSummaryPlanName,
    getPaymentSummaryTotalUsers,
    getPaymentSummaryAccountBlocked,
  ],
  (productsNames, dueDate, total, planName, totalUsers, isAccountBlocked) => ({
    productsNames,
    dueDate,
    total,
    planName,
    totalUsers,
    isAccountBlocked,
  }),
);

export const isOpenModal = ({ paymentSlip }: IStoreState) =>
  paymentSlip.isOpenModal;

export const getModalType = ({ paymentSlip }: IStoreState) =>
  paymentSlip.modalType;

export const isRequestingReleaseTrust = ({ paymentSlip }: IStoreState) =>
  paymentSlip.releaseStatus === RequestStatus.Pending;

export const getRequestReleaseTrustStatus = ({ paymentSlip }: IStoreState) =>
  paymentSlip.releaseStatus;

export const getRequestReleaseTrustData = createSelector(
  [
    isOpenModal,
    getModalType,
    isRequestingReleaseTrust,
    getRequestReleaseTrustStatus,
  ],
  (isOpen, modalType, isRequestingRelease, requestReleaseStatus) => ({
    isOpenModal: isOpen,
    modalType,
    isRequestingRelease,
    requestReleaseStatus,
  }),
);
