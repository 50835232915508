import React from 'react';
import { useSelector } from 'react-redux';
import { Body, Chip, Heading } from '@iclinic/design-system';

import { monetaryFormatter } from 'shared/utils/formatters';
import {
  getSelectedPlan,
  getSelectedAddons,
  getPhysicianByAddon,
  getPhysicians,
} from '../../state/plans/selectors';
import { getSummaryData } from '../../utils/summary';
import * as S from './PaymentSummary.styles';

interface PaymentSummaryProps {
  isAnnual: boolean;
  showSinglePrice?: boolean;
  dividersColor?: 'neutral' | 'secondary';
}

const PaymentSummary = ({
  isAnnual,
  showSinglePrice = false,
  dividersColor = 'neutral',
}: PaymentSummaryProps) => {
  const selectedAddons = useSelector(getSelectedAddons);
  const selectedPlan = useSelector(getSelectedPlan);
  const physicians = useSelector(getPhysicians);
  const physicianByAddon = useSelector(getPhysicianByAddon);

  const {
    planValue,
    planTextToAppend,
    discountValue,
    percentageValue,
    totalValue,
    addons,
  } = getSummaryData({
    isAnnual,
    addons: selectedAddons,
    plan: selectedPlan,
    physicianByAddon,
    physicians,
    showSinglePrice,
  });

  return (
    <S.Card>
      <Heading variant="sm" gutterBottom>
        Resumo de sua assinatura
      </Heading>

      <S.Divider dividerColor={dividersColor} />

      <S.ProductSelectedWrapper>
        <div>
          <S.DescriptionTitle variant="xs">
            {selectedPlan
              ? `Plano ${selectedPlan.name}`
              : 'Nenhum plano selecionado'}
          </S.DescriptionTitle>
          <Body variant="xs">
            Assinatura {isAnnual ? 'anual' : 'mensal'} {planTextToAppend}
          </Body>
        </div>

        <Heading variant="xs">R$ {monetaryFormatter(planValue)} </Heading>
      </S.ProductSelectedWrapper>

      {discountValue && (
        <S.ProductSelectedWrapper>
          <Chip
            label={`Cupom de ${percentageValue || ''}% aplicado`}
            severity="success"
            variant="default"
            size="small"
          />

          <Heading variant="xs">
            - R$ {monetaryFormatter(discountValue)}
          </Heading>
        </S.ProductSelectedWrapper>
      )}

      {addons.map((addon) => (
        <S.ProductSelectedWrapper key={addon.slug}>
          <div>
            <S.DescriptionTitle variant="xs">
              Pacote de {addon.name}
            </S.DescriptionTitle>
            <Body variant="xs">
              Assinatura {isAnnual ? 'anual' : 'mensal'} {addon.textToAppend}
            </Body>
          </div>

          <S.PriceContainer variant="xs">
            + R$ {monetaryFormatter(addon.price)}
          </S.PriceContainer>
        </S.ProductSelectedWrapper>
      ))}

      <S.Divider dividerColor={dividersColor} />

      <S.TotalWrapper>
        <S.DescriptionTitle variant="sm">Total</S.DescriptionTitle>

        <S.TotalAmount>
          <Heading variant="sm">R$ {monetaryFormatter(totalValue)}</Heading>
          <Body variant="xs"> por {isAnnual ? 'ano' : 'mês'}</Body>
        </S.TotalAmount>
      </S.TotalWrapper>
    </S.Card>
  );
};

export default PaymentSummary;
