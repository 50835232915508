import { GetSignatureLinkRequestBody } from './types';

export const removeUnecessaryCharacters = (phoneNumber: string) =>
  phoneNumber.replace(/[-\s()]/g, '');

export const isPhoneValid = (phoneNumber?: string) => {
  if (!phoneNumber) return false;

  const simplePhone = phoneNumber.replace(/[^0-9]/g, '');
  return simplePhone.length === 11;
};

export const jsonToFormData = (data: GetSignatureLinkRequestBody) => {
  const formData = new FormData();
  Object.keys(data).forEach((key) =>
    formData.append(
      key,
      String(data[key as keyof GetSignatureLinkRequestBody]),
    ),
  );
  return formData;
};
