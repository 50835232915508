// External
import React from 'react';

// Internal
import classnames from 'classnames/bind';
import ss from './SideMenu.scss';

const cx = classnames.bind(ss);

type SideMenuProps = {
  classes?: string;
  active?: boolean;
  onMouseEnter?: () => void;
  children: React.ReactNode;
};

export default function SideMenu(
  {
    classes = '',
    onMouseEnter = () => {},
    active = true,
    children,
  }: SideMenuProps,
): JSX.Element {
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseEnter}
      className={cx('side-menu', classes, { '-open': active })}
    >
      {children}
    </div>
  );
}
