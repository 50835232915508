import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme) => ({
  pagination: {
    '& .MuiPaginationItem-page': {
      fontSize: '0.875rem',
      color: theme.palette.text.secondary,
      fontWeight: 500,
    },
    '& .Mui-selected': {
      background: theme.palette.grey[50],
    },
  },
}));

export default useStyles;
