import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Body } from '@iclinic/design-system';

import {
  DateFilterOptions,
  MessageStatus,
  TemplateKind,
} from '../../../services/types';
import { fetchMessageHistory } from '../../../state/messageHistory';
import { getPhysicians } from '../../../state/common/selectors';
import FiltersHistory, { Filters } from './FiltersHistory';
import { PaginationHistory } from './PaginationHistory';
import { TableHistory } from './TableHistory';
import * as S from './ShippingHistory.styles';
import { getMessageHistoryData } from 'features/messaging/state/messageHistory/selectors';

interface ShippingHistoryProps {
  kind: TemplateKind;
  statusOptions: MessageStatus[];
}

export default function ShippingHistory({
  kind,
  statusOptions,
}: ShippingHistoryProps) {
  const dispatch = useDispatch();
  const physicians = useSelector(getPhysicians);
  const { meta } = useSelector(getMessageHistoryData);
  const limit = 10;
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<Filters>({
    physicianId: '',
    date: '',
    status: '',
  });

  useEffect(() => {
    dispatch(
      fetchMessageHistory({
        page,
        kind,
        limit,
        date: filters.date as DateFilterOptions,
        status:
          typeof filters.status === 'number'
            ? (filters.status as MessageStatus)
            : undefined,
        physicianId: Number(filters.physicianId) || undefined,
      }),
    );
  }, [dispatch, filters, kind, page]);

  const onChangePageHandler = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <S.ContentHistory>
      <S.BodyContent>
        <Body bold variant="sm">
          Histórico de envios
        </Body>

        <Body variant="xs">
          Acompanhe o status de todos os lembretes de agenda enviados para os
          seus pacientes.
        </Body>
      </S.BodyContent>

      <FiltersHistory
        physicians={physicians}
        filters={filters}
        setFilters={setFilters}
        statusOptions={statusOptions}
      />

      <S.TableContent>
        <TableHistory />

        {meta?.total_count ? (
          <PaginationHistory
            page={page}
            count={Math.ceil(meta.total_count / limit)}
            onChange={onChangePageHandler}
          />
        ) : null}
      </S.TableContent>
    </S.ContentHistory>
  );
}
