/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useFormikContext } from 'formik';

import {
  Autocomplete,
  TextField,
  createFilterOptions,
} from '@iclinic/design-system';
import { CBO } from '../types';

export interface CBOForm {
  cbo: string;
}

interface SearchBoxProps {
  nameField: string;
  optionsList: CBO[];
  onChangeCBO?: (value: string) => void;
  disabled?: boolean;
}

type PartialCBO = Partial<CBO>;

export const autocompleteFilter = createFilterOptions<PartialCBO>();

const getOption = (option: PartialCBO) => {
  if (typeof option === 'string') return option;

  return option?.term || '';
};

const CBOAutocomplete = ({
  nameField,
  optionsList,
  onChangeCBO = () => {},
  disabled = false,
}: SearchBoxProps) => {
  const {
    errors,
    touched,
    values,
    setFieldValue,
    setTouched,
  } = useFormikContext<CBOForm>();

  function getValue() {
    const { cbo } = values;
    if (typeof cbo === 'number') {
      return optionsList.find((obj: CBO) => obj.cbo === cbo);
    }
    return cbo;
  }

  function handleChange(
    _: React.ChangeEvent<{}>,
    newValue: string | PartialCBO | (string | PartialCBO)[] | null,
  ) {
    if (typeof newValue === 'string') {
      setFieldValue(nameField, '');
    } else if (Array.isArray(newValue)) {
      // not suported yet
    } else if (newValue && newValue.term) {
      setFieldValue(nameField, newValue);
      onChangeCBO(newValue.term);
    }
  }

  return (
    <Autocomplete
      id={nameField}
      options={optionsList}
      getOptionLabel={getOption}
      value={getValue()}
      onChange={handleChange}
      onBlur={() => setTouched({ cbo: true })}
      renderOption={(option) => option.term}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          name={nameField}
          data-ga="cbo"
          value={values.cbo}
          label="Especialização"
          error={!!(errors.cbo && touched.cbo)}
          helperText={touched.cbo && errors.cbo ? errors.cbo : ''}
        />
      )}
    />
  );
};

export default CBOAutocomplete;
