/* eslint-disable import/prefer-default-export */

import { PromiseEnvelope } from '@types';
import { apiExportManager } from 'services/iclinic/api';
import { FetchExportDownloadRawData, SignedUrlResponse } from './types';

export function getExportDownload(
  id: string,
): PromiseEnvelope<FetchExportDownloadRawData> {
  return apiExportManager.get(`/export/${id}`);
}

export function getSignedUrl(id: string): PromiseEnvelope<SignedUrlResponse> {
  return apiExportManager.get(`/export/${id}/url`);
}
