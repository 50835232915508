import React from 'react';
import classNames from 'classnames';

import { image } from 'shared/utils/urlGetter';
import { Box, Body, Heading, Link } from '@iclinic/design-system';
import useStyles from 'features/video-conference/components/styles';
import { isMobile } from 'features/video-conference/utils';

type FeedbackProps = {
  title: string;
  message?: string;
  altText: string;
  imagePath: string;
  linkText?: string;
  linkUrl?: string;
  isReconnectingFeedback?: boolean;
};

export default function Feedback({
  title,
  message,
  altText,
  imagePath,
  linkText,
  linkUrl,
  isReconnectingFeedback = false,
}: FeedbackProps): JSX.Element {
  const classes = useStyles({});

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      className={classNames(classes.smallContainer, {
        [classes.mediumContainer]: isReconnectingFeedback && !isMobile,
      })}
    >
      <Box>
        <img
          src={isReconnectingFeedback ? imagePath : image(imagePath)}
          alt={altText}
        />
      </Box>
      <Box mb={2} pt={2} className={classes.feedbackTitleBox}>
        <Heading align="center" variant="sm" className={classes.feedbackTitle}>
          {title}
        </Heading>
      </Box>
      <Body align="center" variant="sm" className={classes.feedbackMessage}>
        {message}
        {linkText && (
          <>
            {' '}
            <Link
              href={linkUrl}
              variant="body2"
              className={classes.linkColor}
              target="_blank"
              rel="noopener noreferrer"
            >
              {linkText}
            </Link>
          </>
        )}
      </Body>
    </Box>
  );
}
