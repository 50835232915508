import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

import style from './style.scss';

const classes = classnames.bind(style);

export default function Item({
  id,
  children,
  className,
  selected,
  onClick,
  ...rest
}) {
  return (
    <div id={id} onClick={onClick} onKeyPress={onClick} role="button" tabIndex={0} className={classes('item-search', { [className]: className, 'selected-search': selected })} {...rest}>
      {children}
    </div>
  );
}

Item.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

Item.defaultProps = {
  className: undefined,
  selected: false,
};
