// External
import { reducerMerge } from 'shared/utils/reducer';

// Internal
import * as types from './constants';
import { AddonActions } from './actions';
import { AddonState } from './types';

export const initialState: AddonState = {
  isOpen: false,
  isFetching: false,
  step: 1,
  modal: 'video',
  product: null,
  paymentInfo: null,
  userInfo: null,
  isAnnual: false,
  errors: [],
};

const addon = (
  state = initialState,
  action: AddonActions,
): AddonState => {
  switch (action.type) {
    case types.DETAIL_OPEN:
    case types.DETAIL_CLOSE:
    case types.SET_STEP:
    case types.SET_STEP_SUCCESS:
    case types.FETCH_DETAIL:
    case types.FETCH_DETAIL_SUCCESS:
    case types.FETCH_DETAIL_FAILURE:
    case types.FETCH_CREATE:
    case types.FETCH_CREATE_SUCCESS:
    case types.FETCH_CREATE_FAILURE:
    case types.FETCH_NOTIFICATION:
    case types.FETCH_NOTIFICATION_SUCCESS:
    case types.FETCH_NOTIFICATION_FAILURE:
      return reducerMerge({}, state, action.payload);
    default:
      return state;
  }
};

export default addon;
