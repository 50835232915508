import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';

export type PaymentSlipLinkState = {
  status: RequestStatus;
  url: string;
};

export const initialState: PaymentSlipLinkState = {
  status: RequestStatus.Pending,
  url: '',
};

const nameStore = '@webapp/paymentSlipLink';

export const fetchPaymentSlipLink = createAction(
  `${nameStore}/fetchPaymentSlipLink`,
);

const PaymentSlipLinkSlice = createSlice({
  initialState,
  name: nameStore,
  reducers: {
    fetchPaymentSlipLinkSuccess: (state, action: PayloadAction<string>) => ({
      ...state,
      status: RequestStatus.Success,
      url: action.payload,
    }),
    fetchPaymentSlipLinkFailed: (state) => ({
      ...state,
      status: RequestStatus.Error,
    }),
  },
});

export const {
  fetchPaymentSlipLinkSuccess,
  fetchPaymentSlipLinkFailed,
} = PaymentSlipLinkSlice.actions;

const { reducer } = PaymentSlipLinkSlice;

export default reducer;
