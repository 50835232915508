import { getCookie } from 'shared/utils/cookie';

const utmsMapKeys: Record<string, string> = {
  sc: 'source',
  md: 'medium',
  cp: 'campaign',
  tr: 'term',
  ct: 'content',
};

export function extractUtmsValueFromCookies() {
  const utmsCookie = getCookie('__utms');
  if (!utmsCookie) return {};

  const utms: Record<string, string> = {};
  utmsCookie.split('|').forEach((keyValue) => {
    const [key, value] = keyValue.split('=');
    const mappedKey = utmsMapKeys[key];

    if (mappedKey) {
      utms[mappedKey] = value;
    }
  });

  return utms;
}
