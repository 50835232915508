import React from 'react';
import PropTypes from 'prop-types';

import { Notification } from '@iclinic/iclinic-ui';
import { ExpireAfter } from 'shared/components';

const ErrorNotifications = ({ errors }) => (
  <Notification>
    {errors.length > 0 && errors.map((error) => (
      <ExpireAfter key={error.code} delay={3000}>
        <Notification.Message title="Atenção" showCloseButton={false}>
          {error.message}
        </Notification.Message>
      </ExpireAfter>
    ))}
  </Notification>
);

ErrorNotifications.defaultProps = {
  errors: [],
};

ErrorNotifications.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    message: PropTypes.string,
  })),
};

export default ErrorNotifications;
