/* eslint-disable react/jsx-props-no-spreading */
// External
import React, { useState, useEffect, useCallback } from 'react';
import { Field, WrappedFieldMetaProps } from 'redux-form';

// Internal
import Dropdown from 'ui/new/dropdown';
import SearchBar from 'ui/new/search';

type Units = {
  id: string;
  description: string;
};

type Input = {
  value: string;
};

type UnitsDropdownProps = {
  title: string;
  units: Units[];
  change: (field: string, value: string) => void;
  input: Input;
  meta: WrappedFieldMetaProps;
};

export default function UnitsDropdown({
  title,
  units,
  change,
  input: { value = '' },
  meta: { error, touched, invalid },
  ...rest
}: UnitsDropdownProps): JSX.Element {
  const [selectedUnity, setSelectedUnity] = useState<string | null>(null);
  const [isOpenToggle, setIsOpenToggle] = useState(false);

  const handleItemClick = useCallback(
    ({ target: { id, textContent } }) => {
      setSelectedUnity(textContent);
      change('unitId', id);
      setIsOpenToggle(false);
    },
    [change],
  );

  useEffect(() => {
    if (!value.length) {
      setSelectedUnity(title);
    }

    const findResult = units.find(({ id }) => value === id);

    if (findResult) {
      setSelectedUnity(findResult.description);
    }
  }, [handleItemClick, title, units, value]);

  const searchDropdown = (
    <Field
      type="text"
      name="unit_search"
      component={SearchBar}
      placeholder="Selecione"
    />
  );

  return (
    <Dropdown
      renderInternalSearch={searchDropdown}
      isOpenProp={isOpenToggle}
      setToggleMenuProp={setIsOpenToggle}
      title={selectedUnity || title}
      error={error}
      touched={touched}
      invalid={invalid}
      {...rest}
    >
      {units.map((item) => (
        <Dropdown.Item key={item.id} id={item.id} onClick={handleItemClick}>
          {item.description}
        </Dropdown.Item>
      ))}
    </Dropdown>
  );
}
