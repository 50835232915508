import React from 'react';
import { MaterialIcons } from '@iclinic/design-system';

import { NotificationType } from 'features/onlinePayment/state/constants';

const {
  MonetizationOn,
  CreditCard,
  Notifications: DSNotifications,
} = MaterialIcons;

type NotificationIconProps = {
  notificationType: string;
};

export default function NotificationIcon({
  notificationType,
}: NotificationIconProps): JSX.Element | null {
  const iconList: { [key: string]: JSX.Element } = {
    [NotificationType.New]: <MonetizationOn fontSize="small" />,
    [NotificationType.PaymentApproved]: <MonetizationOn fontSize="small" />,
    [NotificationType.CreditCardAnticipationApproved]: (
      <CreditCard fontSize="small" />
    ),
    [NotificationType.CreditCardAnticipationAvailable]: (
      <CreditCard fontSize="small" />
    ),
  };

  return iconList[notificationType] || <DSNotifications fontSize="small" />;
}
