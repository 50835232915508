import React from 'react';
import { Link, MaterialIcons } from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';

import Terms from '../Terms';
import {
  StyledDrawer,
  Header,
  Content,
  BackArea,
  BackButton,
} from './Sidebar.styles';
import { ScreenType } from '../../state/types';
import {
  getSelectedScreenState,
  getSidebarIsopenState,
} from '../../state/selector';
import { actions } from '../../state';
import Loading from '../Loading';
import Summary from '../Summary';
import IntegrationError from '../IntegrationError';

const { ArrowBack } = MaterialIcons;

const { triggerSidebar } = actions;

export type SidebarProps = {
  onClickTry: () => Promise<void>;
  isLoading: boolean;
};

const Sidebar = ({ onClickTry, isLoading }: SidebarProps) => {
  const selectedScreenState = useSelector(getSelectedScreenState);
  const isOpen = useSelector(getSidebarIsopenState);
  const dispatch = useDispatch();

  const content: Record<ScreenType, React.ReactNode> = {
    terms: <Terms />,
    loading: <Loading />,
    summary: <Summary />,
    integrationError: (
      <IntegrationError onClickTry={onClickTry} isLoading={isLoading} />
    ),
  };

  return (
    <StyledDrawer anchor="right" open={isOpen} data-testid="Sidebar">
      <Header>
        <h2>Prontuário otimizado</h2>
        <Link
          href="https://suporte.iclinic.com.br/pt-br/otimiza%C3%A7%C3%A3o-prontu%C3%A1rio-adendo-termo-e-condi%C3%A7%C3%B5es"
          target="_blank"
          rel="noreferrer"
        >
          Termos de uso
        </Link>
      </Header>
      <Content>{content[selectedScreenState]}</Content>
      <BackArea>
        <BackButton
          size="medium"
          variant="contained"
          color="transparent"
          startIcon={<ArrowBack />}
          onClick={() => dispatch(triggerSidebar(false))}
          data-ga={
            selectedScreenState === 'terms' ? 'resumoai_term_voltar' : ''
          }
        >
          Voltar
        </BackButton>
      </BackArea>
    </StyledDrawer>
  );
};

export default Sidebar;
