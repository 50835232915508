import { Grid, MaterialUICore, MaterialIcons } from '@iclinic/design-system';
import React, { forwardRef } from 'react';
import { useFormikContext } from 'formik';

import CollapsibleFormSection from 'features/tissInvoicing/components/ui/CollapsibleFormSection';
import { AppointmentGuideFormValues } from 'features/tissInvoicing/types/appointmentGuide';
import compareVersions from 'features/tissInvoicing/utils/compareVersions';
import PatientName from 'features/tissInvoicing/containers/components/CommonAutocompletes/PatientName';
import SelectField from 'features/tissInvoicing/components/appointmentGuide/SelectField';
import TextField from 'features/tissInvoicing/components/appointmentGuide/TextField';
import DatePicker from 'features/tissInvoicing/components/appointmentGuide/DatePicker';

const { MenuItem } = MaterialUICore;
const { Person } = MaterialIcons;

interface PatientSectionProps {}

const PatientSection = (
  _: PatientSectionProps,
  ref: React.Ref<HTMLButtonElement>,
) => {
  const { values } = useFormikContext<AppointmentGuideFormValues>();

  return (
    <CollapsibleFormSection
      sectionIcon={<Person />}
      sectionIdentifier="patientSection"
      sectionTitle="Dados do paciente"
      ref={ref}
    >
      <Grid container item spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            identifier="patient_insurance_code"
            label="4 - Número da carteira"
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            label="5 - Data de validade da carteira"
            identifier="patient_insurance_expiry_date"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectField label="6 - Atendimento RN" identifier="newborn" required>
            <MenuItem value="0">Não</MenuItem>
            <MenuItem value="1">Sim</MenuItem>
          </SelectField>
        </Grid>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item xs={12} md={4}>
          <PatientName required />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="26 - Nome social"
            identifier="patient_social_name"
            disabled={compareVersions(values.version_id).isLessThan(4)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="8 - Número no CNS"
            identifier="patient_sus_code"
            required
          />
        </Grid>
      </Grid>
    </CollapsibleFormSection>
  );
};
export default forwardRef(PatientSection);
