import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getSignatureAccessToken,
  toggleDrawer,
} from 'state/records/signature/actions';
import { MaterialUICore, Typography } from '@iclinic/design-system';
import { getUrlParams } from 'shared/utils/url';
import { StoreState } from 'state/rootReducer';
import useStyles from './styleSignatureInitial';
import SignatureGifLoader from '../components/SignatureGifLoader';
import { toggleState } from './constants';
import { removeUrlParameter, changeUrl } from '../hooks/utils';

const { Drawer } = MaterialUICore;

const SignatureLoader: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOpenDrawer = useSelector<StoreState>(
    (state) => state.records.signature.isOpenDrawer,
  );
  const { OPEN } = toggleState;

  useEffect(() => {
    dispatch(toggleDrawer(OPEN));
    const code = getUrlParams('code');
    const state = getUrlParams('state');
    if (code && state) {
      removeUrlParameter(window.location.href, 'code');
      const newUrl = removeUrlParameter(window.location.href, 'state');
      changeUrl(newUrl, 'Prontuários - iClinic');
      dispatch(getSignatureAccessToken(code, state));
    }
  }, [OPEN, dispatch]);

  return (
    <Drawer
      data-testid="signature-initial"
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="persistent"
      anchor="right"
      open={isOpenDrawer}
    >
      <div className={classes.content}>
        <div className={classes.mainContent}>
          <Typography variant="h3">Assinatura digital</Typography>
          <SignatureGifLoader />
        </div>
      </div>
    </Drawer>
  );
};

export default SignatureLoader;
