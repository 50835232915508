import { call, put, takeLatest, select } from 'redux-saga/effects';
import { Response } from '@types';

import {
  getTransactionsList,
  getTransactionDetail,
  getPaymentAttemptsList,
  getPaymentAttemptDetail,
  exportTransactionsList,
  exportPaymentAttemptsList,
} from 'features/onlinePayment/services/reports';
import {
  TransactionsListRawData,
  TransactionDetailRawData,
  PaymentAttemptsListRawData,
  PaymentAttemptsBaseRawData,
} from 'features/onlinePayment/services/reports/types';
import { TransactionType } from 'features/onlinePayment/state/constants';
import { captureException } from 'shared/utils/handlerErrors';
import {
  fetchTransactionsList,
  fetchTransactionsListSuccess,
  fetchTransactionsListFailure,
  transactionsPagination,
  selectedPageNumber,
  fetchTransactionDetail,
  fetchTransactionDetailSuccess,
  fetchTransactionDetailFailure,
  fetchAttemptTransactionsList,
  fetchAttemptTransactionDetail,
  fetchAttemptTransactionsListSuccess,
  fetchAttemptTransactionsListFailure,
  fetchAttemptTransactionDetailSuccess,
  fetchAttemptTransactionDetailFailure,
  fetchFilterTransactions,
  fetchTransactionsXLS,
  fetchTransactionsXLSFailure,
  fetchTransactionsXLSuccess,
} from '.';
import {
  normalizeTransactionsListResponseData,
  normalizeTransactionDetailResponseData,
  normalizeAttemptTransactionsListResponseData,
  normalizeAttemptTransactionDetailResponseData,
  normalizeTransactionsFiltersQueryParams,
} from './normalizers';
import {
  getSelectedTransactionType,
  getPageNumber,
  getTransactionsFilters,
  getIsAttemptTransactions,
} from './selectors';
import { TransactionsFiltersForm } from './types';
import { downloadBlob } from 'features/onlinePayment/utils';
import { formatDate } from 'shared/utils/date';

type FetchTransactionDetail = ReturnType<typeof fetchTransactionDetail>;

export function* fetchTransactionsListWorker() {
  try {
    const pageNumber: number = yield select(getPageNumber);
    const transactionsFilters: TransactionsFiltersForm = yield select(
      getTransactionsFilters,
    );
    const filters =
      normalizeTransactionsFiltersQueryParams(transactionsFilters);

    const { data: transactions }: Response<TransactionsListRawData> =
      yield call(getTransactionsList, pageNumber, filters);

    const transactionsList = normalizeTransactionsListResponseData(
      transactions.results,
    );

    const { count, next, previous } = transactions;

    const pagination = {
      count,
      next,
      previous,
    };

    yield put(fetchTransactionsListSuccess(transactionsList));
    yield put(transactionsPagination(pagination));
  } catch (error) {
    captureException(error);
    yield put(fetchTransactionsListFailure());
  }
}

export function* fetchTransactionDetailWorker({
  payload: transactionId,
}: FetchTransactionDetail) {
  try {
    const { data: transactionDetail }: Response<TransactionDetailRawData> =
      yield call(getTransactionDetail, transactionId);

    const normalizeTransaction =
      normalizeTransactionDetailResponseData(transactionDetail);

    yield put(fetchTransactionDetailSuccess(normalizeTransaction));
  } catch (error) {
    captureException(error);
    yield put(fetchTransactionDetailFailure());
  }
}

export function* fetchAttemptTransactionsListWorker() {
  try {
    const pageNumber: number = yield select(getPageNumber);
    const origin: TransactionType = yield select(getSelectedTransactionType);
    const transactionsFilters: TransactionsFiltersForm = yield select(
      getTransactionsFilters,
    );
    const filters =
      normalizeTransactionsFiltersQueryParams(transactionsFilters);

    const { data: transactions }: Response<PaymentAttemptsListRawData> =
      yield call(getPaymentAttemptsList, pageNumber, filters, origin);

    const { count, next, previous } = transactions;

    const pagination = {
      count,
      next,
      previous,
    };

    const attemptTransactionsList =
      normalizeAttemptTransactionsListResponseData(transactions.results);
    yield put(fetchAttemptTransactionsListSuccess(attemptTransactionsList));
    yield put(transactionsPagination(pagination));
  } catch (error) {
    captureException(error);
    yield put(fetchAttemptTransactionsListFailure());
  }
}

export function* fetchAttemptTransactionDetailWorker({
  payload: transactionId,
}: FetchTransactionDetail) {
  try {
    const { data: transactionDetail }: Response<PaymentAttemptsBaseRawData> =
      yield call(getPaymentAttemptDetail, transactionId);

    const normalizeTransaction =
      normalizeAttemptTransactionDetailResponseData(transactionDetail);

    yield put(fetchAttemptTransactionDetailSuccess(normalizeTransaction));
  } catch (error) {
    captureException(error);
    yield put(fetchAttemptTransactionDetailFailure());
  }
}

export function* paginationChangeWorker() {
  const isAttemptTransactions: boolean = yield select(getIsAttemptTransactions);

  if (isAttemptTransactions) {
    yield call(fetchAttemptTransactionsListWorker);
  } else {
    yield call(fetchTransactionsListWorker);
  }
}

export function* fetchFilterTransactionsWorker() {
  const pageNumber = 1;
  yield put(selectedPageNumber(pageNumber));

  const selectedTransactionType: TransactionType = yield select(
    getSelectedTransactionType,
  );

  if (selectedTransactionType === TransactionType.Online) {
    yield call(fetchTransactionsListWorker);
  } else {
    yield call(fetchAttemptTransactionsListWorker);
  }
}

export function* fetchTransactionsXLSWorker() {
  let blob: Blob;
  const isAttemptTransactions: boolean = yield select(getIsAttemptTransactions);
  const origin: TransactionType = yield select(getSelectedTransactionType);
  const transactionsFilters: TransactionsFiltersForm = yield select(
    getTransactionsFilters,
  );

  const { startDate, endDate } = transactionsFilters || {};
  const filters = normalizeTransactionsFiltersQueryParams(transactionsFilters);
  let tabName;
  let fileName;

  if (isAttemptTransactions) {
    tabName =
      origin === TransactionType.Online
        ? 'transacoes-efetuadas-online'
        : 'maquininha';
  } else {
    tabName = 'aguardando-pagamento-online';
  }

  try {
    if (isAttemptTransactions) {
      const { data }: Response<Blob> = yield call(
        exportPaymentAttemptsList,
        filters,
        origin,
      );
      blob = data;
    } else {
      const { data }: Response<Blob> = yield call(
        exportTransactionsList,
        filters,
      );
      blob = data;
    }

    fileName = tabName;
    if (startDate) {
      fileName += `_${formatDate(startDate, 'DD/MM/YYYY', 'YYYY-MM-DD')}`;
    }
    if (endDate) {
      fileName += `_${formatDate(endDate, 'DD/MM/YYYY', 'YYYY-MM-DD')}`;
    }

    downloadBlob(blob, `${fileName}.xlsx`);
    yield put(fetchTransactionsXLSuccess());
  } catch (error) {
    yield put(fetchTransactionsXLSFailure());
  }
}

export default function* transactionsSagas() {
  yield takeLatest(fetchTransactionsList, fetchTransactionsListWorker);
  yield takeLatest(selectedPageNumber, paginationChangeWorker);
  yield takeLatest(fetchTransactionDetail, fetchTransactionDetailWorker);
  yield takeLatest(
    fetchAttemptTransactionsList,
    fetchAttemptTransactionsListWorker,
  );
  yield takeLatest(
    fetchAttemptTransactionDetail,
    fetchAttemptTransactionDetailWorker,
  );
  yield takeLatest(fetchFilterTransactions, fetchFilterTransactionsWorker);
  yield takeLatest(fetchTransactionsXLS, fetchTransactionsXLSWorker);
}
