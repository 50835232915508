import { Reducer } from 'redux';

import { TOGGLE_MODAL, NOTIFY_INTENT, initialState } from './constants';
import { RootState, RootActions } from './actions';

const rootReducer: Reducer<RootState> = (
  state: RootState = initialState,
  action: RootActions,
) => {
  switch (action.type) {
    case TOGGLE_MODAL.ACTION:
      return {
        ...state,
        modalVisibility: !state.modalVisibility,
      };
    case NOTIFY_INTENT.FAILURE:
      return {
        ...state,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
};

export default rootReducer;
