export type PaymentSummary = {
  productsNames: string;
  dueDate: string;
  total: number;
  planName: string;
  totalUsers: number;
  isAccountBlocked: boolean;
};

export type PaymentSlipData = {
  bar_code?: string;
  dt_expiration: string;
  expired: boolean;
  link: string;
  can_request_release_trust: boolean;
};

export enum ModalType {
  CONFIRM = 'confirm',
  SUCCESS = 'success',
}
