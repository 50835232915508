import React from 'react';
import PropTypes from 'prop-types';

import style from './Title.scss';

export default function Title({ content }) {
  return <h1 className={style.title}>{content}</h1>;
}

Title.propTypes = {
  content: PropTypes.string.isRequired,
};
