// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import style from './PaymentInfo.scss';

export default function PaymentInfoNonAdmin({ productName, userName }) {
  return (
    <div className={style.payment}>
      <p className={style.payment__product}>{`Novo Plano: ${productName}`}</p>
      <span className={style.divider} />
      <p className={style.payment__nonadmin}>{`Olá ${userName}`}</p>
      <p className={style.payment__nonadmin}>
        Desculpe, mas somente o administrador da conta pode realizar a mudança de plano.
      </p>
    </div>
  );
}

PaymentInfoNonAdmin.propTypes = {
  productName: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
};
