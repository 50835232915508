// External
import React, { useContext } from 'react';
import Icon from '@material/react-material-icon';

// Internal
import classnames from 'classnames/bind';
import ThemeContext, { UserThemeStyle } from 'shared/hooks/theme';
import { COLOR, ALIGN } from './constants';
import ss from './Card.scss';

const cx = classnames.bind(ss);

type CardProps = {
  classes?: string;
  color?: COLOR;
  align?: ALIGN;
  title: string;
  selected: boolean;
  showSelectIcon: boolean;
  onClickCheck: () => void;
  children: React.ReactNode;
};

export default function Card(
  {
    classes = '',
    color = COLOR.Default,
    align = ALIGN.Center,
    title,
    selected,
    showSelectIcon,
    onClickCheck,
    children,
  }: CardProps,
): JSX.Element {
  const { isApplied, style } = useContext(ThemeContext);

  return (
    <fieldset
      className={
        cx(ss.card,
          [color,
            { [ss.active]: selected },
            classes,
          ],
          UserThemeStyle(ss, style, isApplied))
      }
    >
      {showSelectIcon && (
        <legend className={cx(ss.icon, align)}>
          <button
            type="button"
            className={
              cx(ss.button,
                { [ss.active]: selected })
            }
            onClick={onClickCheck}
          >
            {selected && <Icon className={ss.iconSelect} icon="check" />}
          </button>
        </legend>
      )}
      <h3>{title}</h3>
      {children}
    </fieldset>
  );
}
