// External
import React from 'react';
import { Field } from 'redux-form';

// Internal
import ActionableCard from 'ui/new/actionable-card';
import {
  Physician,
} from 'state/marketing/emailFlow/emailFlowManagement/emailFlowTypes';

import style from './EmailFlowSender.scss';

type EmailFlowSenderProps = {
  senders?: Physician[];
};

const EmailFlowSender = ({
  senders,
}: EmailFlowSenderProps): JSX.Element => (
  <div className={style.sender}>
    <ActionableCard title="Dados do Remetente" collapsed={false}>
      <ActionableCard.Body classes={style}>
        <div className={style.container}>
          <div className={style.row}>
            <div className={style.container__basisMiddleForm}>
              <p className={style.titleField}>Nome do remetente</p>
              <Field type="text" name="sender.name" label="name" component="select" className={style.container__filterSenderName}>
                {senders && senders.map(item => (
                  <option value={item.name} key={item.id}>{item.name}</option>
                ))}
              </Field>
            </div>
            <div className={style.container__basisMiddleForm}>
              <p className={style.titleField}>E-mail do remetente</p>
              <Field type="text" name="sender.email" label="email" component="select" className={style.container__filterSenderName}>
                {senders && senders.map(item => (
                  <option value={item.email} key={item.id}>{item.email}</option>
                ))}
              </Field>
            </div>
          </div>
        </div>
      </ActionableCard.Body>
    </ActionableCard>
  </div>
);

export default EmailFlowSender;
