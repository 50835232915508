import { Response } from '@types';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { captureException } from 'shared/utils/handlerErrors';
import {
  deleteAppointmentguideService,
  fetchGetTissVersionsApiLegacy,
  fetchAppointmentGuideListData,
} from 'features/tissInvoicing/services/guide';
import { actions } from '.';
import { createAppointmentGuideListDTO } from 'features/tissInvoicing/utils/appointmentGuideDto';
import { TissVersionParams } from 'features/tissInvoicing/types/guide/tissVersion';
import { GetAppointmentGuideApiResponse } from 'features/tissInvoicing/types';

type FetchAppointmentGuideListPayload = ReturnType<
  typeof actions.fetchAppointmentGuideList
>;
type DeleteAppointmentguideStatePayload = ReturnType<
  typeof actions.deleteAppointmentguide
>;

export function* fetchAppointmentGuideList({
  payload: { limit, page, clinic, physician_id },
}: FetchAppointmentGuideListPayload) {
  try {
    const getPage = page || 1;
    yield put(actions.setListFetchStatus(true));

    const { data }: Response<GetAppointmentGuideApiResponse> = yield call(
      fetchAppointmentGuideListData,
      {
        limit,
        clinic,
        physician_id,
        page,
      },
    );

    const result = createAppointmentGuideListDTO(data.results);

    yield put(
      actions.setGuides({
        list: result,
        paginate: {
          next: data.next,
          previous: data.previous,
          count: data.count,
          pageCurrent: getPage,
          totalPages: Math.ceil(data.count / limit),
        },
        loading: false,
      }),
    );
  } catch (error) {
    captureException(error);
  } finally {
    yield put(actions.setListFetchStatus(false));
  }
}

export function* deleteAppointmentGuideWorker({
  payload: { IDs },
}: DeleteAppointmentguideStatePayload) {
  try {
    yield put(actions.setListFetchStatus(true));

    yield all(IDs.map((id) => call(deleteAppointmentguideService, { id })));

    // yield call(fetchAppointmentGuideList, { payload: { limit: 10, page: 1 }, type: '' })

    yield put(
      actions.setSnackbarContent({
        message: 'Guia excluída com sucesso!',
        severity: 'success',
      }),
    );
  } catch (error) {
    captureException(error);
  } finally {
    yield put(actions.setListFetchStatus(false));
  }
}

export function* fetchTissVersionsWorker() {
  try {
    const { data }: Response<TissVersionParams> = yield call(
      fetchGetTissVersionsApiLegacy,
    );

    yield put(
      actions.setTissVersions(data.objects.map((item) => item.version)),
    );
  } catch (error) {
    captureException(error);
  }
}

export default function* watchAppointmentGuideListWorker() {
  yield takeLatest(
    actions.fetchAppointmentGuideList,
    fetchAppointmentGuideList,
  );
  yield takeLatest(
    actions.deleteAppointmentguide,
    deleteAppointmentGuideWorker,
  );
  yield takeLatest(actions.fetchTissVersions, fetchTissVersionsWorker);
}
