import { PromiseResponse } from '@types';

import { PatientsByEventsRawData } from 'features/patientsByEvents/state/types';
import iClinicApi from './iClinicApi';

export const getPatientsByEvents = (
  clinicId: number,
  physicianId: number,
  dateFrom: string,
  dateTo: string,
): PromiseResponse<PatientsByEventsRawData> =>
  iClinicApi.get(
    `/patients/patient/dashboard/${clinicId}/${physicianId}?date_from=${dateFrom}&date_to=${dateTo}`,
  );
