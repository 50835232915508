import { createSelector } from 'reselect';
import { PERIOD_SELECT, PRODUCT_SELECTOR } from 'state/subscription/constants';
import { formatToInteger } from 'shared/utils/strings';
import { hasPromotionOnPeriod } from 'shared/utils/productPromotion';

const getSubscribeDetail = (state) => state.subscription.subscribeDetail;
const getSelectedProductNode = (
  productNode,
) => (state) => state.subscription.subscribeDetail[productNode];
const selectedPeriodIsMonthly = (state) => state.subscription.subscribeDetail.isMonthly;

export const hasProductPromotion = (productNode = PRODUCT_SELECTOR.selected) => createSelector(
  [getSelectedProductNode(productNode), selectedPeriodIsMonthly],
  (product, isMonthly) => hasPromotionOnPeriod(product, isMonthly),
);

export const getProductListPrice = () => createSelector(
  [(product) => product],
  (product) => {
    const hasPromotion = hasPromotionOnPeriod(product, true);
    const monthlyFrequency = hasPromotion ? product.promotion.frequencies.monthly : false;
    return {
      productValue: formatToInteger(product.values.monthly),
      productDiscount: monthlyFrequency ? formatToInteger(monthlyFrequency.value) : 0,
      frequencyNumber: monthlyFrequency ? monthlyFrequency.frequencyNumber : 0,
    };
  },
);

const getPromotionFrequencies = (subscribeDetail, productNode, period) => {
  const frequency = subscribeDetail[productNode].promotion.frequencies[period];
  const hasFrequency = !!frequency;
  return hasFrequency ? frequency.value : '0';
};

export const getProductAnnualValue = (productNode = PRODUCT_SELECTOR.selected) => createSelector(
  [getSubscribeDetail, hasProductPromotion(productNode, PERIOD_SELECT.annual)],
  (subscribeDetail, hasPromotion) => (hasPromotion ? getPromotionFrequencies(subscribeDetail, productNode, PERIOD_SELECT.annual) : '0'),
);

export const getProductMonthlyValue = (productNode = PRODUCT_SELECTOR.selected) => createSelector(
  [getSubscribeDetail, hasProductPromotion(productNode, PERIOD_SELECT.monthly)],
  (subscribeDetail, hasPromotion) => (hasPromotion ? getPromotionFrequencies(subscribeDetail, productNode, PERIOD_SELECT.monthly) : '0'),
);

const extractSelectedProduct = (
  subscribeDetail, promotionalValueMonthly, promotionalAnnualValue, hasPromotion, productNode,
) => {
  const product = subscribeDetail[productNode];
  if (product !== null) {
    const {
      isMonthly,
      [productNode]: {
        name,
        values: { monthly: productValueMonthly, annual: productValueAnnual },
        annualDiscount,
      },
    } = subscribeDetail;

    const promotionalValue = isMonthly ? promotionalValueMonthly : promotionalAnnualValue;
    const productValue = isMonthly ? productValueMonthly : productValueAnnual;
    const productValueWithPromotion = hasPromotion ? promotionalValue : productValue;

    return {
      productName: name,
      promotionalValueMonthly: parseFloat(promotionalValueMonthly),
      promotionalValueAnnual: parseFloat(promotionalAnnualValue),
      productValueMonthly: parseFloat(productValueMonthly),
      productValueAnnual: parseFloat(productValueAnnual),
      productValueWithPromotion: parseFloat(productValueWithPromotion),
      annualDiscount,
      hasPromotion,
    };
  }
  return null;
};

export const getProductPrice = (productNode = PRODUCT_SELECTOR.selected) => createSelector(
  [
    getSubscribeDetail,
    getProductMonthlyValue(productNode),
    getProductAnnualValue(productNode),
    hasProductPromotion(productNode),
  ],
  (
    subscribeDetail, monthlyValue, annualValue, hasPromotion,
  ) => extractSelectedProduct(
    subscribeDetail, monthlyValue, annualValue, hasPromotion, productNode,
  ),
);
