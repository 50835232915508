import React from 'react';
import { useSelector } from 'react-redux';
import { Form as FormikForm, useFormikContext } from 'formik';

import { Body, MaterialUICore, Radio } from '@iclinic/design-system';
import {
  getDownsellOptions,
  isSubmitingDownsell,
} from '../../state/downsell-reason/selectors';
import { getUserInfo } from '../../state/delete-multiple-physician/selectors';
import { DownsellReasonFormData } from './types';
import BackButton from './BackButton';
import * as S from './FormDownsellReason.styles';
import ButtonWithLoading from '../../components/ButtonWithLoading';
import { trackDownsellReason } from '../../utils/trackDownsellEvents';

const { FormControl } = MaterialUICore;

const FormDownsellReason = () => {
  const downsellOptions = useSelector(getDownsellOptions);
  const isSubmiting = useSelector(isSubmitingDownsell);
  const userData = useSelector(getUserInfo);
  const { values, handleChange } = useFormikContext<DownsellReasonFormData>();

  return (
    <FormikForm>
      <S.FieldsWrapper>
        <FormControl component="fieldset">
          <Body variant="sm">
            Gostaria de compartilhar o motivo da exclusão?
          </Body>

          {downsellOptions && (
            <S.RadioGroup
              aria-label="Motivos"
              value={Number(values.reason_option)}
              name="reason_option"
              onChange={handleChange}
              onBlur={() => {
                trackDownsellReason({
                  userData,
                  stepName: 'reason_option',
                  stepNum: 1,
                  stepValue: downsellOptions.find(
                    ({ id }) => id === Number(values.reason_option),
                  )?.option,
                });
              }}
            >
              {downsellOptions
                .filter(({ active }) => active)
                .map(({ id, option }) => (
                  <Radio key={option} value={id} label={option} />
                ))}
            </S.RadioGroup>
          )}
        </FormControl>

        <S.CommentField
          name="report_message"
          value={values.report_message}
          onChange={handleChange}
          label="Fique a vontade para deixar comentários sobre esta ação"
          multiline
          onBlur={() => {
            trackDownsellReason({
              userData,
              stepName: 'reason_comment',
              stepNum: 2,
            });
          }}
        />

        <S.FormButtonWrapper>
          <BackButton
            onClick={() => {
              trackDownsellReason({
                userData,
                stepName: 'back_to_iclinic',
                stepNum: 0,
              });
            }}
          />

          <ButtonWithLoading type="submit" size="large" isLoading={isSubmiting}>
            Enviar motivo
          </ButtonWithLoading>
        </S.FormButtonWrapper>
      </S.FieldsWrapper>
    </FormikForm>
  );
};

export default FormDownsellReason;
