// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import style from './Extra.scss';

export default function Extra({
  name,
  value,
  period,
  isChecked,
  toggleStatus,
}) {
  return (
    <div className={style.extra}>
      <input type="checkbox" name="extraProduct" id="extraProduct" checked={isChecked} onChange={toggleStatus} />
      <div className={style.details}>
        <p className={style.details__title}>
          {`Adquirir ${name} por mais R$ ${value}/${period} por profissional de saúde.`}
        </p>
        <p className={style.details__content}>
          Mostre os horários disponíveis da sua agenda e deixe que seus pacientes
          marquem as consultas sozinhos.
        </p>
      </div>
    </div>
  );
}

Extra.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  toggleStatus: PropTypes.func.isRequired,
};
