import { useState, useCallback, useRef } from 'react';
import { LogLevels, Track, Room } from 'twilio-video';

import { ErrorCallback } from 'features/video-conference/constants/types';

interface MediaStreamTrackPublishOptions {
  name?: string;
  priority: Track.Priority;
  logLevel: LogLevels;
}

export default function useScreenShareToggle(
  room: Room | null,
  onError: ErrorCallback,
) {
  const [isSharing, setIsSharing] = useState(false);
  const stopScreenShareRef = useRef<() => void>(null!);

  const shareScreen = useCallback(() => {
    navigator.mediaDevices
      .getDisplayMedia({
        audio: false,
        video: {
          frameRate: 10,
          height: 1080,
          width: 1920,
        },
      })
      .then((stream) => {
        const track = stream.getTracks()[0];

        room!.localParticipant
          .publishTrack(track, {
            name: 'screen',
          } as MediaStreamTrackPublishOptions)
          .then((trackPublication) => {
            stopScreenShareRef.current = () => {
              room!.localParticipant.unpublishTrack(track);
              room!.localParticipant.emit('trackUnpublished', trackPublication);
              track.stop();
              setIsSharing(false);
            };

            track.onended = stopScreenShareRef.current;
            setIsSharing(true);
          })
          .catch((error) => onError(error, true));
      })
      .catch((error) => {
        if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
          onError(error);
        }
      });
  }, [onError, room]);

  const toggleScreenShare = useCallback(() => {
    if (room) {
      return !isSharing ? shareScreen() : stopScreenShareRef.current();
    }

    return null;
  }, [isSharing, shareScreen, room]);

  return [isSharing, toggleScreenShare] as const;
}
