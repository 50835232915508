import React from 'react';
import {
  Body,
  Chip,
  Heading,
  MaterialIcons as Icons,
} from '@iclinic/design-system';

import { PackagesSlug } from '../../../services/types';
import * as S from './PackageCard.styles';

interface PackageCardProps {
  packageName: string;
  sendQuantity: number;
  price: string;
  slug: PackagesSlug;
  selected?: boolean;
  onClick?: (slug: PackagesSlug) => void;
}

const PackageCard = ({
  packageName,
  price,
  sendQuantity,
  slug,
  onClick,
  selected,
}: PackageCardProps) => {
  const onClickHandler = () => {
    if (!onClick) return;

    onClick(slug);
  };

  return (
    <S.Card onClick={onClickHandler}>
      <S.HorizontalWrapperWithSpace>
        <Heading variant="md">{packageName}</Heading>

        <S.SimpleRadio
          name={slug}
          checked={selected}
          checkedIcon={<Icons.CheckCircle color="primary" />}
          inputProps={{
            'aria-label': packageName,
          }}
        />
      </S.HorizontalWrapperWithSpace>

      <S.Divider />

      <S.HorizontalWrapperWithSpace>
        <Body variant="xs">
          até <strong>{sendQuantity} envios</strong> ao mês
        </Body>

        <Chip variant="outlined" severity="info" label={price} size="small" />
      </S.HorizontalWrapperWithSpace>
    </S.Card>
  );
};

export { PackageCard };
