import { AudioTrack, LocalAudioTrack, RemoteAudioTrack } from 'twilio-video';
import { useEffect, useRef, useState } from 'react';

import useMediaStreamTrack from 'features/video-conference/hooks/useMediaStreamTrack';
import useIsTrackEnabled from 'features/video-conference/hooks//useIsTrackEnabled';
import { initializeAnalyser, isIOS } from 'features/video-conference/utils';

export function useAudioLevelIndicator(audioTrack?: AudioTrack) {
  const SVGRectRef = useRef<SVGRectElement>(null);
  const [analyser, setAnalyser] = useState<AnalyserNode>();
  const [volume, setVolume] = useState<number>(0);
  const mediaStreamTrack = useMediaStreamTrack(audioTrack);
  const isTrackEnabled = useIsTrackEnabled(
    audioTrack as LocalAudioTrack | RemoteAudioTrack,
  );

  useEffect(() => {
    if (audioTrack && mediaStreamTrack && isTrackEnabled) {
      let newMediaStream = new MediaStream([
        isIOS ? mediaStreamTrack.clone() : mediaStreamTrack,
      ]);

      const stopAllMediaStreamTracks = () => {
        if (isIOS) newMediaStream.getTracks().forEach((track) => track.stop());
        newMediaStream.dispatchEvent(new Event('cleanup'));
      };

      audioTrack.on('stopped', stopAllMediaStreamTracks);

      const reinitializeAnalyser = () => {
        stopAllMediaStreamTracks();
        newMediaStream = new MediaStream([
          isIOS ? mediaStreamTrack.clone() : mediaStreamTrack,
        ]);
        setAnalyser(initializeAnalyser(newMediaStream));
      };

      setAnalyser(initializeAnalyser(newMediaStream));
      window.addEventListener('focus', reinitializeAnalyser);

      return () => {
        stopAllMediaStreamTracks();
        window.removeEventListener('focus', reinitializeAnalyser);
        audioTrack.off('stopped', stopAllMediaStreamTracks);
      };
    }

    return () => null;
  }, [isTrackEnabled, mediaStreamTrack, audioTrack]);

  useEffect(() => {
    const SVGClipElement = SVGRectRef.current;

    if (isTrackEnabled && analyser) {
      const volumes = new Uint8Array(analyser.frequencyBinCount);

      const timer = setInterval(() => {
        analyser.getByteFrequencyData(volumes);

        let volumeSum = 0;
        const { length } = volumes;
        for (let count = 0; count < length; count += 1)
          volumeSum += volumes[count];

        const volumeAverage = Math.min(
          14,
          Math.max(0, Math.log10(volumeSum / length / 3) * 7),
        );
        setVolume(volumeAverage);

        SVGClipElement?.setAttribute('y', String(14 - volumeAverage));
      }, 100);

      return () => {
        SVGClipElement?.setAttribute('y', '14');
        clearInterval(timer);
      };
    }

    return () => null;
  }, [isTrackEnabled, analyser]);

  return { SVGRectRef, analyser, volume };
}
