import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BanksRawData,
  SellerBankAccountRawData,
} from 'features/onlinePayment/services/config/types';
import { RequestStatus } from 'shared/constants/State';
import { SellerBankAccountForm } from '../types';
import {
  BANK_ACCOUNT_ERROR,
  BANK_ACCOUNT_FETCH_ERROR,
  INSTALLMENTS_ERROR,
} from '../errorMessages';

export interface BankAccountState {
  banks?: {
    data?: BanksRawData[];
    status?: RequestStatus;
  };
  sellerBankAccountForm?: SellerBankAccountForm;
  sellerBankAccount?: {
    data?: SellerBankAccountRawData;
    fetchStatus?: RequestStatus;
    saveStatus?: RequestStatus;
    error?: string;
  };
  installments?: {
    status?: RequestStatus;
    error?: string;
  };
}

export const initialState: BankAccountState = {
  banks: {
    data: [],
    status: RequestStatus.Pending,
  },
};

const name = 'bankAccount';

export const fetchBankAccount = createAction<string | undefined>(
  `${name}/fetchBankAccount`,
);
export const saveBankCardInfos = createAction<SellerBankAccountForm>(
  `${name}/saveBankCardInfos`,
);

const SellerRegisterSlice = createSlice({
  name,
  initialState,
  reducers: {
    fetchBanks: (state) => {
      state.banks = {
        status: RequestStatus.Pending,
      };
    },
    fetchBanksSuccess: (state, action: PayloadAction<BanksRawData[]>) => {
      state.banks = {
        data: action.payload,
        status: RequestStatus.Success,
      };
    },
    fetchBanksFailure: (state) => {
      state.banks = {
        status: RequestStatus.Error,
      };
    },
    fetchBankAccountPending: (state) => {
      state.sellerBankAccount = {
        fetchStatus: RequestStatus.Pending,
      };
    },
    fetchBankAccountSuccess: (
      state,
      action: PayloadAction<SellerBankAccountRawData>,
    ) => {
      state.sellerBankAccount = {
        fetchStatus: RequestStatus.Success,
        data: action.payload,
      };
    },
    fetchBankAccountFailure: (state) => {
      state.sellerBankAccount = {
        fetchStatus: RequestStatus.Error,
        error: BANK_ACCOUNT_FETCH_ERROR,
      };
    },
    saveBankCardInfosPending: (
      state,
      action: PayloadAction<SellerBankAccountForm>,
    ) => {
      state.sellerBankAccountForm = action.payload;
      state.installments = {
        status: RequestStatus.Pending,
      };
      state.sellerBankAccount = {
        fetchStatus: RequestStatus.Pending,
      };
    },
    saveBankAccountSellerSuccess: (
      state,
      action: PayloadAction<SellerBankAccountRawData>,
    ) => {
      state.sellerBankAccount = {
        saveStatus: RequestStatus.Success,
        data: action.payload,
      };
    },
    saveBankAccountSellerFailure: (state) => {
      state.sellerBankAccount = {
        saveStatus: RequestStatus.Error,
        error: BANK_ACCOUNT_ERROR,
      };
    },
    saveInstallmentsSuccess: (state) => {
      state.installments = {
        status: RequestStatus.Success,
      };
    },
    saveInstallmentsFailure: (state) => {
      state.installments = {
        status: RequestStatus.Error,
        error: INSTALLMENTS_ERROR,
      };
    },
  },
});

export default SellerRegisterSlice.reducer;

export const {
  fetchBanks,
  fetchBanksSuccess,
  fetchBanksFailure,
  fetchBankAccountPending,
  fetchBankAccountSuccess,
  fetchBankAccountFailure,
  saveBankCardInfosPending,
  saveBankAccountSellerSuccess,
  saveBankAccountSellerFailure,
  saveInstallmentsSuccess,
  saveInstallmentsFailure,
} = SellerRegisterSlice.actions;
