// FORM NAME
export const FORM_SUBSCRIBE = 'subscribe';
export const FORM_CREDITCARD = 'creditcard';
export const FORM_LIST_CAMPAIGNS = 'listCampaigns';
export const FORM_ADD_CAMPAIGN_MODAL = 'addCampaignModal';
export const FORM_EDIT_CAMPAIGN = 'editCampaign';
export const FORM_EDITOR_LINK_MODAL = 'editorLinkModal';
export const FORM_EDITOR_BUTTON_MODAL = 'editorButtonModal';
export const FORM_EDITOR_IMAGE_MODAL = 'editorImageModal';
export const FORM_SEND_EMAIL_TEST = 'sendEmailTestModal';
export const FORM_STOCK_ADD = 'stockAdd';
export const FORM_STOCK_EDIT = 'stockEdit';
export const FORM_STOCK_DELETE = 'stockDelete';
export const FORM_STOCK_OUTPUT = 'stockOutput';
export const FORM_STOCK_INPUT = 'stockInput';
export const CAMPAIGN_EDITOR_LINK_MODAL = 'campaign_editor_link_modal';
export const CAMPAIGN_EDITOR_BUTTON_MODAL = 'campaign_editor_button_modal';
export const CAMPAIGN_EDITOR_IMAGE_MODAL = 'campaign_editor_image_modal';
export const CAMPAIGN_SEND_EMAIL_TEST = 'campaign_send_email_test';
export const MARKETING_UNSUBSCRIBE = 'marketing_unsubscribe';
export const FORM_LIST_EMAIL_FLOW_RETURN = 'emailFlows';
export const FORM_EDIT_EMAIL_FLOW = 'editEmailFlow';
export const FORM_ADD_EMAIL_FLOW_MODAL = 'addEmailFlowModal';
export const FORM_EDIT_EMAIL_FLOW_RETURN = 'editEmailFlowReturn';
export const EMAIL_FLOW_EDITOR_LINK_MODAL = 'campaign_editor_link_modal';
export const EMAIL_FLOW_EDITOR_BUTTON_MODAL = 'campaign_editor_button_modal';
export const EMAIL_FLOW_EDITOR_IMAGE_MODAL = 'campaign_editor_image_modal';
