export enum COLOR {
  Primary = 'primary',
  Default = 'default',
  Warning = 'warning',
  Danger = 'danger',
}

export enum ALIGN {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}
