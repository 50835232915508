import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Body,
  TextField,
  InputAdornment,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';
import useStyles from './SearchBarSampleBox.styles';
import { getSuggestionList } from 'features/free-samples/state/sample-boxes/selectors';
import {
  clearSuggestions,
  fetchSuggestions,
} from 'features/free-samples/state/sample-boxes';

const { SearchOutlined } = MaterialIcons;
const { ListItem, Popper } = MaterialUICore;

type SearchBarSampleBoxProps = {
  onApplyQueryFilter: (filter: string) => void;
};

const MINIMUM_CHARACTERS_TO_SUGGEST = 3;

const SearchBarSampleBox = ({
  onApplyQueryFilter,
}: SearchBarSampleBoxProps) => {
  const { form, popper, popperContent } = useStyles();
  const dispatch = useDispatch();
  const suggestions = useSelector(getSuggestionList);

  const [searchValue, setSearchValue] = useState('');
  const [
    anchorForSuggestion,
    setAnchorForSuggestion,
  ] = useState<HTMLInputElement | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchValue(value);

    if (value.length >= MINIMUM_CHARACTERS_TO_SUGGEST) {
      setAnchorForSuggestion(event.currentTarget);
      dispatch(fetchSuggestions(value));
    } else {
      setAnchorForSuggestion(null);
      dispatch(clearSuggestions());
    }
  };

  const applyFilter = (filter: string) => {
    onApplyQueryFilter(filter);
    setAnchorForSuggestion(null);
    setSearchValue('');
    dispatch(clearSuggestions());
  };

  const handleSuggestionClick = (suggestion: string) => {
    applyFilter(suggestion);
  };

  const handleSubmitQueryFilter = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (searchValue) {
      applyFilter(searchValue);
    }
  };

  return (
    <>
      <form className={form} onSubmit={handleSubmitQueryFilter}>
        <TextField
          type="text"
          label="O que você está procurando?"
          placeholder="ex: farmacêutica ou principio ativo"
          fullWidth
          value={searchValue}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
      </form>

      {suggestions && (
        <Popper
          open={!!anchorForSuggestion && !!suggestions}
          anchorEl={anchorForSuggestion}
          placement="bottom-start"
          className={popper}
        >
          <div className={popperContent}>
            {suggestions.map((suggestion) => (
              <ListItem
                key={suggestion}
                button
                onClick={() => {
                  handleSuggestionClick(suggestion);
                }}
              >
                <Body variant="xs">{suggestion}</Body>
              </ListItem>
            ))}
          </div>
        </Popper>
      )}
    </>
  );
};

export default SearchBarSampleBox;
