// External
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal
import { StoreState } from 'state/rootReducer';

type AuthenticatedRouteProps = {
  authorized: boolean,
} & RouteProps;

function AuthenticatedRoute({ ...otherProps }: AuthenticatedRouteProps) {
  const isAuthorized = useSelector(
    (state: StoreState) => state.authv2.authorized,
  );
  if (isAuthorized) {
    return <Route {...otherProps} />;
  }
  return null;
}

export default AuthenticatedRoute;
