import React from 'react';
import { Participant as IParticipant } from 'twilio-video';

import ParticipantInfo from './ParticipantInfo';
import ParticipantTracks from '../ParticipantTrack';

interface ParticipantProps {
  participant: IParticipant;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
}

export default function Participant({
  participant,
  isLocalParticipant,
  hideParticipant,
}: ParticipantProps) {
  return (
    <ParticipantInfo
      participant={participant}
      isLocalParticipant={isLocalParticipant}
      hideParticipant={hideParticipant}
    >
      <ParticipantTracks
        participant={participant}
        isLocalParticipant={isLocalParticipant}
      />
    </ParticipantInfo>
  );
}
