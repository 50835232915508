import React from 'react';
import ContentLoader from 'react-content-loader';

const values = {
  backgroundColor: '#ECEFF1',
  foregroundColor: '#F8F9FA',
  height: 16,
  speed: 1,
};

export const PhysicianInfosContentLoader = () => (
  <ContentLoader
    speed={values.speed}
    width={476}
    height={81}
    viewBox="0 0 476 81"
    backgroundColor={values.backgroundColor}
    foregroundColor={values.foregroundColor}
    data-testid="physician-infos-content-loader"
    uniqueKey="physician-infos"
  >
    <circle cx="41" cy="41" r="40" />
    <rect x="100" y="17" rx="2" ry="2" width="227" height={values.height} />
    <rect x="100" y="46.37" rx="2" ry="2" width="124" height={values.height} />
  </ContentLoader>
);

export const ClinicInfosContentLoader = () => (
  <ContentLoader
    speed={values.speed}
    width={476}
    height={150}
    viewBox="0 0 476 150"
    backgroundColor={values.backgroundColor}
    foregroundColor={values.foregroundColor}
    data-testid="clinic-infos-content-loader"
    uniqueKey="clinic-infos"
  >
    <rect x="1" y="15" rx="2" ry="2" width="227" height={values.height} />
    <rect x="1" y="44" rx="2" ry="2" width="185" height={values.height} />
    <rect x="1" y="73" rx="2" ry="2" width="208" height={values.height} />
    <rect x="1" y="102" rx="2" ry="942" width="161" height={values.height} />
    <rect x="1" y="131" rx="2" ry="2" width="120" height={values.height} />
  </ContentLoader>
);

export const EventInfosContentLoader = () => (
  <ContentLoader
    speed={values.speed}
    width={476}
    height={140}
    viewBox="0 0 476 140"
    backgroundColor={values.backgroundColor}
    foregroundColor={values.foregroundColor}
    data-testid="event-infos-content-loader"
    uniqueKey="event-infos"
  >
    <rect x="1" y="10" rx="2" ry="2" width="282" height={values.height} />
    <rect x="1" y="39" rx="2" ry="2" width="232" height={values.height} />
    <rect x="1" y="68" rx="2" ry="2" width="268" height={values.height} />
    <rect x="1" y="97" rx="2" ry="2" width="189" height={values.height} />
    <rect x="1" y="126" rx="2" ry="2" width="246" height={values.height} />
  </ContentLoader>
);

export const NameContentLoader = () => (
  <ContentLoader
    speed={values.speed}
    width={139}
    height={17}
    viewBox="0 0 139 17"
    backgroundColor={values.backgroundColor}
    foregroundColor={values.foregroundColor}
    data-testid="name-content-loader"
    uniqueKey="name"
  >
    <rect x="1" y="0" rx="2" ry="2" width="138" height={values.height} />
  </ContentLoader>
);

export const PaymentTypeOptionsContentLoader = () => (
  <ContentLoader
    speed={values.speed}
    width="100%"
    height={124}
    backgroundColor={values.backgroundColor}
    foregroundColor={values.foregroundColor}
    data-testid="payment-type-options-content-loader"
    uniqueKey="payment-type-options"
  >
    <rect x="1" y="0" rx="0" ry="2" width="100%" height="58" />
    <rect x="1" y="66" rx="0" ry="2" width="100%" height="58" />
  </ContentLoader>
);
