import React from 'react';

import { Box } from '@iclinic/design-system';
import AudioInputList from './AudioInputList';
import AudioOutputList from './AudioOutputList';
import VideoInputList from './VideoInputList';
import useStyles from './styles';

type ContainerSizes = 'small' | 'medium';

interface DeviceSelectionProps {
  containerSize?: ContainerSizes;
  location?: string;
}

export default function DeviceSelection({
  containerSize = 'small',
  location = '',
}: DeviceSelectionProps) {
  const classes = useStyles({ containerSize });

  return (
    <Box className={classes.container} data-testid="settings">
      <AudioInputList location={location} />
      <AudioOutputList location={location} />
      <VideoInputList location={location} />
    </Box>
  );
}
