// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import TagsContainer from './TagsContainer';
// Style
import style from './FilterTags.scss';

const FilterTags = ({
  name,
  availableItems,
  title,
  renderItemLabel,
  searchName,
  children,
}) => (
  <div className={style.filterTags__container}>
    <p className={style.filterTags__titleField}>{title}</p>
    <div className={style.filterTags__filters}>
      <TagsContainer
        name={name}
        renderLabel={renderItemLabel}
        availableItems={availableItems}
        searchName={searchName}
      />
      {children}
    </div>
  </div>
);

FilterTags.propTypes = {
  availableItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  renderItemLabel: PropTypes.func.isRequired,
  searchName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FilterTags;
