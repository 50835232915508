export const formatDate = (date: string): string | null => {
  if (Number.isNaN(Date.parse(date))) return null;

  return new Date(date).toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const formatTime = (date: string): string | null => {
  if (Number.isNaN(Date.parse(date))) return null;

  return new Date(date).toLocaleTimeString('pt-BR', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });
};
