import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';
import { PatientsByEventsRawData } from './types';

export const getPatientsByEventsData = ({
  dashboard,
}: StoreState): PatientsByEventsRawData | undefined | null =>
  dashboard.patientsByEvents?.data;

export const getPatientsByEventsDataStatus = ({
  dashboard,
}: StoreState): RequestStatus | undefined =>
  dashboard.patientsByEvents?.status;

export const getPatientsByEventsDataErrorMessage = ({
  dashboard,
}: StoreState): string | undefined => dashboard.patientsByEvents?.error;
