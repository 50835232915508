import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialUICore, Container, Grid } from '@iclinic/design-system';

import { useToggleBodyClass } from 'shared/hooks';
import { ErrorAlert } from 'shared/components';
import { trackSignupIClinicPayButtons } from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { SellerRawData } from 'features/onlinePayment/services/config/types';
import {
  getUserInfo,
  getSellerDocumentProof,
  getSellerProfiles,
  getUpdateDefaultSellerError,
} from 'features/onlinePayment/state/config/register/selectors';
import AccountCard from 'features/onlinePayment/components/config/AccountCard';
import { chooseSeller } from 'features/onlinePayment/state/config/register';
import styles from '../../styles';
import { SellerType } from '../constants';
import { SellerStatus } from 'features/onlinePayment/state/constants';
import { SELLER_REFUSED_INFO } from 'features/onlinePayment/state/config/errorMessages';

const { useTheme } = MaterialUICore;

const SellerProfile = () => {
  const theme = useTheme();
  const configClasses = styles();
  const dispatch = useDispatch();
  const userData = useSelector(getUserInfo);
  const [profiles, documentProofs] = [
    useSelector(getSellerProfiles),
    useSelector(getSellerDocumentProof),
  ];
  const error = useSelector(getUpdateDefaultSellerError);

  const hasSellerProfilesRefused =
    profiles?.individual?.status === SellerStatus.Refused ||
    profiles?.business?.status === SellerStatus.Refused;

  function handleClick(type: SellerType, profile?: SellerRawData | null) {
    dispatch(chooseSeller({ seller: profile, type }));
    const normalizerButtonName =
      type === SellerType.PJ
        ? 'pay_register_legal_person'
        : 'pay_register_individual_person';
    trackSignupIClinicPayButtons({
      userData,
      buttonName: normalizerButtonName,
      buttonLocation: 'onboarding_create_payment',
      additionalProperties: 'Minha conta',
    });
  }

  useToggleBodyClass(configClasses.whiteBody, theme.breakpoints.down('sm'));

  return (
    <Container maxWidth="md">
      <Grid container spacing={4} justify="center">
        {hasSellerProfilesRefused && (
          <Container maxWidth="md">
            <ErrorAlert
              message={
                <span
                  dangerouslySetInnerHTML={{
                    __html: SELLER_REFUSED_INFO,
                  }}
                />
              }
            />
          </Container>
        )}
        {error && (
          <ErrorAlert
            message={<span dangerouslySetInnerHTML={{ __html: error }} />}
            mb={3}
            name="update-default-seller-error"
            scrollToElement
          />
        )}
        <Grid item xs={12} sm={6}>
          <AccountCard
            type={SellerType.PF}
            profile={profiles?.individual}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleClick}
            documents={documentProofs?.individual}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AccountCard
            type={SellerType.PJ}
            profile={profiles?.business}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={handleClick}
            documents={documentProofs?.business}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SellerProfile;
