/* eslint-disable sonarjs/no-duplicate-string */
// Internal
import appTypes from 'shared/utils/actions';
import { SUCCESS, FAILURE } from 'shared/constants/actions';

const subscriptions = appTypes.defineAction('subscriptions');

export const RELOAD = subscriptions.defineAction('RELOAD');
export const FETCH_CONTRACTS = subscriptions.defineAction('FETCH_CONTRACTS', [
  SUCCESS,
  FAILURE,
]);
export const FETCH_SUBSCRIPTION = subscriptions.defineAction(
  'FETCH_SUBSCRIPTION',
  [SUCCESS, FAILURE],
);
export const SET_SUBSCRIPTION = subscriptions.defineAction('SET_SUBSCRIPTION', [
  SUCCESS,
  FAILURE,
]);
export const OPEN_MODAL = subscriptions.defineAction('OPEN_MODAL', [
  SUCCESS,
  FAILURE,
]);
export const CLOSE_MODAL = subscriptions.defineAction('CLOSE_MODAL', [
  SUCCESS,
  FAILURE,
]);
export const LIST_PHYSICIANS = subscriptions.defineAction('LIST_PHYSICIANS', [
  SUCCESS,
  FAILURE,
]);
export const SET_STEP = subscriptions.defineAction('SET_STEP', [
  SUCCESS,
  FAILURE,
]);
export const SELECT_PHYSICIAN = subscriptions.defineAction('SELECT_PHYSICIAN', [
  SUCCESS,
  FAILURE,
]);
export const VERIFY_SELECTED_ADDONS = subscriptions.defineAction(
  'VERIFY_SELECTED_ADDONS',
  [SUCCESS, FAILURE],
);
export const SET_SELECTED_ADDONS = subscriptions.defineAction(
  'SET_SELECTED_ADDONS',
  [SUCCESS, FAILURE],
);
export const SET_CURRENT_ADDON = subscriptions.defineAction(
  'SET_CURRENT_ADDON',
  [SUCCESS, FAILURE],
);
export const VERIFICATION_NEXT_STEP = subscriptions.defineAction(
  'VERIFICATION_NEXT_STEP',
  [SUCCESS, FAILURE],
);
export const VERIFICATION_BACK_STEP = subscriptions.defineAction(
  'VERIFICATION_BACK_STEP',
  [SUCCESS, FAILURE],
);
export const CLEAR_ERRORS_SUBSCRIPTIONS = subscriptions.defineAction(
  'CLEAR_ERRORS_SUBSCRIPTIONS',
);
export const UPDATE_CREDIT_CARD = subscriptions.defineAction(
  'UPDATE_CREDIT_CARD',
  [SUCCESS, FAILURE],
);
export const FLIP_CARD = subscriptions.defineAction('FLIP_CARD');
export const FETCH_CHECKOUT = subscriptions.defineAction('FETCH_CHECKOUT', [
  SUCCESS,
  FAILURE,
]);
export const GET_SUMMARY = subscriptions.defineAction('GET_SUMMARY', [
  SUCCESS,
  FAILURE,
]);
export const UNION_PRODUCT_TO_USER = subscriptions.defineAction(
  'UNION_PRODUCT_TO_USER',
);
export const UNION_PRODUCT_TO_SINGLE_USER = subscriptions.defineAction(
  'UNION_PRODUCT_TO_SINGLE_USER',
);
export const CREATE_STEPS_FLOW =
  subscriptions.defineAction('CREATE_STEPS_FLOW');
export const CONTROL_STEPS_FLOW =
  subscriptions.defineAction('CONTROL_STEPS_FLOW');
export const GO_TO_ICLINIC_ASSIST = subscriptions.defineAction(
  'GO_TO_ICLINIC_ASSIST',
);

export const CHANGE_TAB_RECURRENCE = {
  monthly: true,
  annual: false,
};

export const PERIOD_SELECT = {
  monthly: 'monthly',
  annual: 'annual',
};

export const PRODUCT_SELECTOR = {
  selected: 'product',
  extraProduct: 'extraProduct',
};

export const PRODUCT_SUBSCRIPTION = {
  base: 'base',
  addons: 'addons',
  extra: 'extra',
};

export const PRODUCTS_SUBSCRIPTION_GROUP = [
  {
    slug: 'starter',
    functionalities: [
      { name: 'Agenda', slug: 'schedule' },
      { name: 'Agendamento online', slug: 'online-scheduling' },
      { name: 'Cadastro de pacientes', slug: 'patients' },
      { name: 'Prontuário eletrônico', slug: 'records' },
      { name: 'Prescrição eletrônica', slug: 'prescriptions' },
      { name: 'Faturamento TISS', slug: 'tiss' },
      { name: 'Envio de lembretes', slug: 'reminders' },
      { name: 'Multi-procedimentos', slug: 'multiprocedure' },
      { name: 'Relatórios', slug: 'reports' },
      { name: 'R$ 0,10 por SMS', slug: 'sms-basic' },
    ],
  },
  {
    slug: 'plus',
    functionalities: [
      { name: 'Agenda', slug: 'schedule' },
      { name: 'Agendamento online', slug: 'online-scheduling' },
      { name: 'Cadastro de pacientes', slug: 'patients' },
      { name: 'Prontuário eletrônico', slug: 'records' },
      { name: 'Prescrição eletrônica', slug: 'prescriptions' },
      { name: 'Faturamento TISS', slug: 'tiss' },
      { name: 'Envio de lembretes', slug: 'reminders' },
      { name: 'Multi-procedimentos', slug: 'multiprocedure' },
      { name: 'Relatórios', slug: 'reports' },
      { name: 'R$ 0,09 por SMS', slug: 'sms' },
      { name: '100 SMS gratuitos mês', slug: 'sms-free' },
      { name: 'SMS com resposta', slug: 'reply-sms' },
      { name: 'Chat interno', slug: 'chat' },
      {
        name: 'Lembrete de consulta por Whatsapp',
        slug: 'whatsapp-appointment-reminder',
      },
      {
        name: 'Confirmação de consulta por Whatsapp',
        slug: 'whatsapp-appointment-confirmation',
      },
      { name: 'Assinatura Digital', slug: 'assinatura-digital' },
      { name: 'Logo em documentos', slug: 'logo' },
      { name: 'E-mail aniversariantes', slug: 'birthdays' },
    ],
  },
  {
    slug: 'pro',
    functionalities: [
      { name: 'Agenda', slug: 'schedule' },
      { name: 'Agendamento online', slug: 'online-scheduling' },
      { name: 'Cadastro de pacientes', slug: 'patients' },
      { name: 'Prontuário eletrônico', slug: 'records' },
      { name: 'Prescrição eletrônica', slug: 'prescriptions' },
      { name: 'Faturamento TISS', slug: 'tiss' },
      { name: 'Envio de lembretes', slug: 'reminders' },
      { name: 'Multi-procedimentos', slug: 'multiprocedure' },
      { name: 'Relatórios', slug: 'reports' },
      { name: 'R$ 0,09 por SMS', slug: 'sms' },
      { name: '100 SMS gratuitos mês', slug: 'sms-free' },
      { name: 'SMS com resposta', slug: 'reply-sms' },
      { name: 'Chat interno', slug: 'chat' },
      {
        name: 'Lembrete de consulta por Whatsapp',
        slug: 'whatsapp-appointment-reminder',
      },
      {
        name: 'Confirmação de consulta por Whatsapp',
        slug: 'whatsapp-appointment-confirmation',
      },
      { name: 'Assinatura Digital', slug: 'assinatura-digital' },
      { name: 'Logo em documentos', slug: 'logo' },
      { name: 'E-mail aniversariantes', slug: 'birthdays' },
      { name: 'Controle financeiro', slug: 'finances' },
      { name: 'Repasse financeiro', slug: 'finances-payment' },
      { name: 'Relatórios financeiros', slug: 'finances-report' },
      { name: 'Controle de estoque', slug: 'stock' },
    ],
  },
  {
    slug: 'premium',
    functionalities: [
      { name: 'Agenda', slug: 'schedule' },
      { name: 'Agendamento online', slug: 'online-scheduling' },
      { name: 'Cadastro de pacientes', slug: 'patients' },
      { name: 'Prontuário eletrônico', slug: 'records' },
      { name: 'Prescrição eletrônica', slug: 'prescriptions' },
      { name: 'Faturamento TISS', slug: 'tiss' },
      { name: 'Envio de lembretes', slug: 'reminders' },
      { name: 'Multi-procedimentos', slug: 'multiprocedure' },
      { name: 'Relatórios', slug: 'reports' },
      { name: 'R$ 0,09 por SMS', slug: 'sms' },
      { name: '100 SMS gratuitos mês', slug: 'sms-free' },
      { name: 'SMS com resposta', slug: 'reply-sms' },
      { name: 'Chat interno', slug: 'chat' },
      {
        name: 'Lembrete de consulta por Whatsapp',
        slug: 'whatsapp-appointment-reminder',
      },
      {
        name: 'Confirmação de consulta por Whatsapp',
        slug: 'whatsapp-appointment-confirmation',
      },
      { name: 'Assinatura Digital', slug: 'assinatura-digital' },
      { name: 'Logo em documentos', slug: 'logo' },
      { name: 'E-mail aniversariantes', slug: 'birthdays' },
      { name: 'Controle financeiro', slug: 'finances' },
      { name: 'Repasse financeiro', slug: 'finances-payment' },
      { name: 'Relatórios financeiros', slug: 'finances-report' },
      { name: 'Controle de estoque', slug: 'stock' },
      { name: 'Campanhas de e-mail', slug: 'campaign' },
      { name: 'Fluxos de retorno', slug: 'fluxos-retorno' },
      { name: 'Fluxos para plano de cuidado', slug: 'fluxos-cuidados' },
    ],
  },
];
