import axios from 'axios';

import {
  interceptorRequestAuthentication,
  interceptorResponseErrorsPromise,
} from 'services/iclinic/interceptors';
import {
  genericResponseErrorsInterceptor,
  checkoutTokenInterceptor,
} from './checkout/interceptors';
import {
  apiTokenInterceptor,
  extractBlobXLSXInterceptor,
} from './interceptors';

export const iClinicApi = axios.create({
  baseURL: process.env.ICLINIC_API_URL,
});

iClinicApi.interceptors.request.use(interceptorRequestAuthentication);
iClinicApi.interceptors.response.use(
  undefined,
  interceptorResponseErrorsPromise,
);

export const apiOnlinePaymentCheckout = axios.create({
  baseURL: process.env.ICLINIC_ONLINE_PAYMENT_URL,
});
apiOnlinePaymentCheckout.interceptors.request.use(checkoutTokenInterceptor);
apiOnlinePaymentCheckout.interceptors.response.use(
  undefined,
  interceptorResponseErrorsPromise,
);

export const apiOnlinePayment = axios.create({
  baseURL: process.env.ICLINIC_ONLINE_PAYMENT_URL,
});

apiOnlinePayment.interceptors.request.use(apiTokenInterceptor);
apiOnlinePayment.interceptors.response.use(
  undefined,
  interceptorResponseErrorsPromise,
);

export const apiOnlinePaymentService = axios.create({
  baseURL: `${process.env.ICLINIC_LAMBDA_ONLINE_PAYMENT_URL as string}/v1`,
});
apiOnlinePaymentService.interceptors.request.use(
  interceptorRequestAuthentication,
);

export const apiUpload = axios.create();
apiUpload.interceptors.response.use(
  undefined,
  interceptorResponseErrorsPromise,
);

export const lambdaCheckoutV2 = axios.create({
  baseURL: `${process.env.ICLINIC_LAMBDA_ONLINE_PAYMENT_URL as string}/v2`,
});
lambdaCheckoutV2.interceptors.response.use(
  undefined,
  genericResponseErrorsInterceptor,
);

export const apiOnlinePaymentExportData = axios.create({
  baseURL: process.env.ICLINIC_ONLINE_PAYMENT_URL,
  responseType: 'arraybuffer',
  headers: { Accept: 'application/xlsx' },
});

apiOnlinePaymentExportData.interceptors.request.use(apiTokenInterceptor);
apiOnlinePaymentExportData.interceptors.response.use(
  extractBlobXLSXInterceptor,
);
