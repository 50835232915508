// External
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

// Internal
import ActionableCard from 'ui/new/actionable-card';
import { ActionButtonGroup } from 'ui/new/action-buttons';
import {
  cardsName, mobileLines, campaignStatusForDisplay, mobileLinesKeys, CampaignStatus,
} from 'state/campaign/constants';
import style from './ListCampaignsItemMobile.scss';

const classes = classnames.bind(style);

const ListCampaignsItemMobile = ({
  campaign,
  activeCard,
  setActiveCard,
  deleteCampaign,
  editCampaign,
  duplicateCampaign,
}) => {
  const collapsed = activeCard !== campaign.id;
  const onHeaderClick = React.useCallback(() => {
    setActiveCard(collapsed ? campaign.id : cardsName.none);
  }, [campaign.id, collapsed, setActiveCard]);

  const campaignStatus = React.useCallback((key) => (
    key === mobileLinesKeys.status
      ? <span className={classes(`campaignTableItem__status--${campaign.status}`)}>{campaignStatusForDisplay[campaign[key]]}</span>
      : campaign[key]
  ), [campaign]);

  const bodyCard = React.useMemo(() => [
    mobileLines.map(({ key, label }) => (
      <div className={style.campaignsItems} key={key}>
        <span>{label}</span>
        <strong>{campaignStatus(key)}</strong>
      </div>
    )),
  ], [campaignStatus]);

  return (
    <div>
      <ActionableCard title={campaign.title} collapsed={collapsed} onHeaderClick={onHeaderClick}>
        <ActionableCard.Body>
          {bodyCard}
        </ActionableCard.Body>
        <ActionableCard.Footer>
          <ActionButtonGroup
            target={campaign}
            onDelete={deleteCampaign}
            onEdit={campaign.status !== CampaignStatus.sent ? editCampaign : null}
            onDuplicate={duplicateCampaign}
          />
        </ActionableCard.Footer>
      </ActionableCard>
    </div>
  );
};

ListCampaignsItemMobile.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    sent: PropTypes.string.isRequired,
    opened: PropTypes.string.isRequired,
    clicked: PropTypes.string.isRequired,
  }).isRequired,
  setActiveCard: PropTypes.func.isRequired,
  activeCard: PropTypes.string.isRequired,
  deleteCampaign: PropTypes.func.isRequired,
  editCampaign: PropTypes.func.isRequired,
  duplicateCampaign: PropTypes.func.isRequired,
};

export default ListCampaignsItemMobile;
