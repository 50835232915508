import React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';

import SampleBoxes from '../containers/sample-boxes';
import RequestSamplesForm from '../containers/RequestSamplesForm';

const Routes = ({ match }: RouteComponentProps) => (
  <Switch>
    <Route exact path={match.path} component={SampleBoxes} />
    <Route
      exact
      path={`${match.path}/solicitar/:boxId`}
      component={RequestSamplesForm}
    />
    <Redirect to="/404/" />
  </Switch>
);

export default Routes;
