import React from 'react';

import Table from './Table';
import { Heading } from '@iclinic/design-system';
import * as Styles from './styles';

function GuideList() {
  return (
    <Styles.Container>
      <Styles.Header>
        <Heading variant="lg">Guias SP/SADT</Heading>
      </Styles.Header>

      <Styles.Content>
        <Table />
      </Styles.Content>
    </Styles.Container>
  );
}

export default GuideList;
