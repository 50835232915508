import { useState } from 'react';

import {
  Media,
  Message,
  MESSAGE_TYPE,
} from 'features/video-conference/constants/types';
import { useChatContext } from 'features/video-conference/state/chatContext';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import {
  createChatMessagePayload,
  createMediaObject,
  getTelemedicineStorage,
  hasAllowedFileExtension,
  hasAllowedFileSize,
} from 'features/video-conference/utils';
import { uploadFile } from 'features/video-conference/services/uploadFiles';

export function useHandleSendDataChat() {
  const { room, onError, isPhysician } = useConference();
  const { setMessages, setFilesUploaded } = useChatContext();
  const { localParticipant } = useParticipantContext();
  const [isSendingFile, setIsSendingFile] = useState<boolean>(false);
  const [fileSendError, setFileSendError] = useState<string | null>(null);

  const handleSend = (message: string, file: Media | null, type: string) => {
    if (!room) return;
    if (!localParticipant) return;

    const [localDataTrackPublication] = Array.from(
      room.localParticipant.dataTracks.values(),
    );

    const newMessage = createChatMessagePayload(
      message,
      file,
      type,
      room,
      localParticipant,
    );

    setMessages((prevMessages: Message[]) => {
      localStorage.setItem(
        `telemedicine-chat-${room.name}`,
        JSON.stringify(prevMessages.concat(newMessage)),
      );
      return [...prevMessages, newMessage];
    });

    localDataTrackPublication.track?.send(JSON.stringify(newMessage));
    setIsSendingFile(false);
  };

  const handleSendFile = async (file: File) => {
    setIsSendingFile(true);
    const eventId = isPhysician && getTelemedicineStorage()?.eventId;

    const { responseImage, messageError } = await uploadFile(
      file,
      onError,
      isPhysician,
      eventId,
    );

    if (messageError) {
      setFileSendError(messageError);
      setIsSendingFile(false);
      return;
    }

    const { image: fileUrl, thumb } = responseImage;
    const fileObj = createMediaObject(file, fileUrl);
    setFilesUploaded({ ...fileObj, thumb });
    handleSend('', fileObj, MESSAGE_TYPE.MEDIA);
  };

  const isValidFileForUpload = (file: File) => {
    if (!hasAllowedFileSize(file.size)) {
      setFileSendError(`O arquivo ${file.name} é muito grande.
        O tamanho máximo permitido é 64 MB.`);
      return false;
    }

    if (!hasAllowedFileExtension(file.name)) {
      setFileSendError(
        `O arquivo ${file.name} tem uma extensão não permitida.`,
      );
      return false;
    }

    return true;
  };

  return {
    handleSend,
    handleSendFile,
    isSendingFile,
    fileSendError,
    setFileSendError,
    isValidFileForUpload,
  };
}
