import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Typography, Box, Button } from '@iclinic/design-system';
import validationSchema, { initialValues, InitialValues } from './schema';
import PasswordField from 'shared/components/PasswordField';
import BottomButtonContainer from '../BottomButtonContainer';
import { validatePasswordIntegration } from 'features/settings/scheduleOnline/state/actions';
import { getSettingsIntegration } from 'features/settings/scheduleOnline/state/selectors';

export const PasswordConfirmation = () => {
  const dispatch = useDispatch();
  const integrationData = useSelector(getSettingsIntegration);

  const handleFormSubmit = (values: InitialValues) => {
    dispatch(
      validatePasswordIntegration(integrationData.email, values.password),
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleFormSubmit,
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setErrors,
    values,
    errors,
    touched,
  } = formik;

  useEffect(() => {
    if (!integrationData.invalidPassword) {
      return;
    }
    setErrors({
      password: 'Senha inválida',
    });
  });

  return (
    <>
      <Typography variant="h4" id="integration-modal-title">
        Ativar agenda online
      </Typography>
      <Box mt={1}>
        <Typography variant="subtitle1">
          Encontramos seu perfil no Agendar Consulta, digite sua senha para
          continuar a ativação da sua agenda iClinic no seu perfil.
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box mt={3} mb={2}>
          <PasswordField
            label="Senha"
            placeholder="Digite sua senha"
            name="password"
            autoComplete="current-password"
            error={!!(errors.password && touched.password)}
            helperText={
              touched.password && errors.password ? errors.password : null
            }
            fullWidth
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
          />
        </Box>
        <Box mb={3}>
          <Button color="transparent">Esqueci minha senha</Button>
        </Box>
        <BottomButtonContainer
          isLoading={integrationData.isLoading}
          leftLabel="Cancelar"
          rightLabel="Avançar"
          submit
        />
      </form>
    </>
  );
};
