// External
import React from 'react';
import classnames from 'classnames/bind';

// Internal
import style from './AutomatedTag.scss';

const classes = classnames.bind(style);

type AutomatedTagsProps = {
  onAutomatedTagChosen: (tag: string) => void;
  active: boolean,
  tag: string,
};

const getActiveStyle = (active: boolean) => (active ? 'active' : 'light');

const AutomatedTags = ({
  active,
  onAutomatedTagChosen,
  tag,
}: AutomatedTagsProps): JSX.Element => {
  const onClick = React.useCallback(() => {
    onAutomatedTagChosen(tag);
  }, [onAutomatedTagChosen, tag]);
  return (
    <button
      className={classes('button', `button--${getActiveStyle(active)}`)}
      onClick={onClick}
      value={tag}
      key={tag}
      type="button"
    >
      {tag}
    </button>
  );
};

export default AutomatedTags;
