export const MODAL = {
  Success: 'success',
  Info: 'info',
  Error: 'error',
  Danger: 'danger',
};

export const FOOTER = {
  center: 'center',
  left: 'left',
  right: 'right',
  split: 'split',
};

export const FOOTER_DIRECTION = {
  column: 'column',
  row: 'row',
};
