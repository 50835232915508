import {
  Paper,
  Button as ButtonMaterial,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled, Divider: DSDivider } = MaterialUICore;

export const Header = styled('div')(({ theme }) => ({
  flexGrow: 1,
  marginRight: tokens.spacing.xl,
  marginBottom: theme.spacing(5),

  '& h2': {
    marginBottom: theme.spacing(1),
  },

  [theme.breakpoints.down('sm')]: {
    marginRight: 0,
    marginBottom: theme.spacing(3),

    '& h2': {
      marginBottom: theme.spacing(2),
    },
  },
}));

export const Button = styled(ButtonMaterial)(({ theme }) => ({
  flexShrink: 0,
  width: 206,

  '&:focus': {
    outline: 'none',
    textDecoration: 'none',
    color: tokens.color.neutral[0],
  },

  '&:hover': {
    color: tokens.color.neutral[0],
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: tokens.spacing.md,
    alignSelf: 'center',
  },
}));

export const Card = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  minHeight: '153px',
  marginBottom: tokens.spacing.xs,
  padding: `0 ${tokens.spacing.lg} 0 ${tokens.spacing.md}`,

  '& button': {
    flexShrink: 0,
    width: 206,
  },

  [theme.breakpoints.down('sm')]: {
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: `${tokens.spacing.md}`,
  },
}));

export const ImgWrapper = styled('div')(({ theme }) => ({
  width: '116px',
  display: 'flex',
  justifyContent: 'center',
  flexShrink: 0,
  marginRight: tokens.spacing.md,

  [theme.breakpoints.down('sm')]: {
    width: '74px',
    marginBottom: theme.spacing(2),
  },
}));

export const Content = styled('div')(({ theme }) => ({
  flexGrow: 1,
  marginRight: tokens.spacing.xl,

  [theme.breakpoints.down('sm')]: {
    marginRight: 0,

    '& h2': {
      marginBottom: theme.spacing(1),
    },
  },
}));

export const Divider = styled(DSDivider)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('sm')]: {
    display: 'block',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    color: tokens.color.neutral[100],
  },
}));
