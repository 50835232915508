export enum BillStatus {
  Created = 'created',
  Paid = 'paid',
  Canceled = 'canceled',
}

export enum EventStatus {
  Scheduled = 'sc',
  Canceled = 'ca',
}

export enum SellerStatus {
  Approved = 'approved',
  Pending = 'pending',
  Submitted = 'submitted',
  Refused = 'refused',
  Deleted = 'deleted',
}

export enum SellerType {
  PF = 'individual',
  PJ = 'business',
}

export const DEBOUNCE_AMOUNT_CHARGED = 1600; // ms

export enum TransactionStatus {
  Canceled = 'canceled',
  ChargeBack = 'charged_back',
  Created = 'created',
  Dispute = 'dispute',
  Paid = 'paid',
  PartialReversed = 'partial_reversed',
  Pending = 'pending',
  Processing = 'processing',
  Rejected = 'rejected',
  Reversed = 'reversed',
  Success = 'success',
}

export enum TransactionType {
  Online = 'online',
  Presential = 'presential',
}

export enum PaymentType {
  Credit = 'credit',
  Debit = 'debit',
  Pix = 'pix',
}

export enum NotificationType {
  New = 'new',
  PaymentApproved = 'payment-approved',
  CreditCardAnticipationApproved = 'tr-anticipation-approved',
  CreditCardAnticipationAvailable = 'tr-anticipation-available',
  FreeText = 'free-text',
}
