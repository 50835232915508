/* eslint-disable react/jsx-props-no-spreading */
// External
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';

// Internal
import style from './Radio.scss';

const classes = classnames.bind(style);

const Radio = ({
  name: givenName,
  children,
  disabled,
  selected: givenSelected,
  hasError,
  onChange,
  input,
  field,
  meta,
  form,
  ...otherProps
}) => {
  let name = givenName;
  if (!name && input && input.name) name = input.name;
  if (!name && field && field.name) name = field.name;
  let selected = givenSelected;
  // formik doesn't handle "selected" or "checked" automatically
  if (selected === null && field && form && field.value === form.values[name]) selected = true;
  if (selected === null) selected = false;
  return (
    <div className={style.wrapper}>
      <input
        type="radio"
        name={name}
        id={name}
        className={classes('radio', { disabled, error: hasError || (meta && meta.touched && meta.invalid) })}
        disabled={disabled}
        checked={selected}
        onChange={onChange}
        {...input}
        {...field}
        {...otherProps}
      />
      <label htmlFor={name} className={style.radio__text}>
        {children}
      </label>
    </div>
  );
};

Radio.defaultProps = {
  name: '',
  disabled: false,
  selected: null,
  hasError: false,
  onChange: null,
  input: null,
  field: null,
  form: null,
  meta: null,
};

Radio.propTypes = {
  name: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  hasError: PropTypes.bool,
  input: PropTypes.shape({ // redux-form
    name: PropTypes.string,
    checked: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string,
  }),
  field: PropTypes.shape({ // formik
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  form: PropTypes.shape({ // formik
    values: PropTypes.object,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    invalid: PropTypes.bool,
  }),
  onChange: PropTypes.func,
};

export default Radio;
