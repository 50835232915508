import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MaterialIcons, Snackbar } from '@iclinic/design-system';
import { getStatus } from '../state/selectors';
import { refreshStatus } from '../state/reducer';
import { RequestStatus } from 'shared/constants/State';

const { ErrorRounded } = MaterialIcons;

export const ChangeSnackBar = () => {
  const dispatch = useDispatch();
  const status = useSelector(getStatus);
  const feedbackTimeInMiliseconds = 3000;

  useEffect(() => {
    const feedbackTimeout = setTimeout(() => {
      dispatch(refreshStatus());
    }, feedbackTimeInMiliseconds);

    return () => clearTimeout(feedbackTimeout);
  }, [status, dispatch]);

  return (
    <>
      <Snackbar
        open={status === RequestStatus.Error}
        severity="error"
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        message={
          <>
            <ErrorRounded />
            Não foi possível alterar a data de pagamento
          </>
        }
      />
    </>
  );
};
