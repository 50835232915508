// External
import React from 'react';

// Internal
import classnames from 'classnames/bind';
import ss from './Title.scss';

const cx = classnames.bind(ss);

type HeaderProps = {
  children: React.ReactNode;
  classes?: string;
};

export default function Container({
  children,
  classes = '',
}: HeaderProps): JSX.Element {
  return (
    <div className={cx(ss['main-title'], classes)}>
      {children}
    </div>
  );
}
