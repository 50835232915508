import { IStoreState } from 'state/rootReducer';
import { UserInfo } from './types';

export const getUserInfo = ({ userInfo }: IStoreState): UserInfo => ({
  profileId: userInfo.profileId,
  subscriptionId: userInfo.userData.subscriptionId,
  isSubscriber: userInfo.userData.isSubscriber,
});

export const getIsCalculatorDrawerOpen = (state: IStoreState) =>
  state.records.calculatorDrawer.isCalculatorDrawerOpen;

export const getIsCalculatorSelected = (state: IStoreState) =>
  state.records.calculatorDrawer.calculatorSelected;

export const getIsCalculatorListByCategory = (state: IStoreState) =>
  state.records.calculatorDrawer.calculatorsByCategory;

export const getIsCalculatorLoading = (state: IStoreState) =>
  state.records.calculatorDrawer.isCalculatorFetchLoading;

export const getCalculatorError = (state: IStoreState) =>
  state.records.calculatorDrawer.getCalculatorError;

export const getCalculatorViewSelected = (state: IStoreState) =>
  state.records.calculatorDrawer.calculatorView;
