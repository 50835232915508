// External
import {
  put, call, takeLatest, select,
} from 'redux-saga/effects';
import { reset } from 'redux-form';

// Internal
import { emitErrors } from 'shared/utils/emitErrors';
import { captureException } from 'shared/utils/handlerErrors';
import { FORM_SUBSCRIBE } from 'shared/constants/forms';
import iclinic from 'services/iclinic';
import * as actions from './actions';
import * as types from './types';
import { getSelectedSlugs, normalizeSubscribePayload, replaceFunctionalitiesToConstants } from './utils';

const getSharedInfo = (state) => state.shared;
const getSubscriptionInfo = (state) => state.subscription;

export function* workerSubscribeSubmit({ payload: subscribePayload }) {
  try {
    // start loading
    yield put(actions.fetchSubscribe());
    const {
      subscribeDetail: {
        hasExtraProduct, extraProductSlug, product, isMonthly,
      }, successUrl,
    } = yield select(getSubscriptionInfo);
    const selectedProducts = getSelectedSlugs(hasExtraProduct, extraProductSlug, product);
    const normalizedSubscribePayload = normalizeSubscribePayload(
      subscribePayload, isMonthly, selectedProducts,
    );
    const { errors } = yield call(iclinic.subscription.fetchSubscribe, normalizedSubscribePayload);
    if (errors) {
      yield put(actions.fetchSubscribeFailure(emitErrors(errors)));
    } else {
      yield put(actions.fetchSubscribeSuccess());
      const { updatePermissionsUrl } = yield select(getSharedInfo);
      window.location = `${updatePermissionsUrl}${successUrl}`;
    }
    yield put(reset(FORM_SUBSCRIBE));
  } catch (error) {
    yield put(actions.fetchSubscribeFailure());
  }
}

export function* workerGetProducts() {
  try {
    const { getResponseData, errors } = yield call(iclinic.product.fetchProducts);
    if (errors) {
      yield put(actions.fetchGetProductsFailure(emitErrors(errors)));
    } else {
      const { totalUsers, products } = getResponseData();
      yield put(
        actions.fetchGetProductsSuccess(totalUsers, replaceFunctionalitiesToConstants(products)),
      );
    }
  } catch (error) {
    captureException(error);
  }
}

export function* workerGetProduct() {
  try {
    const { subscribeDetail: { extraProductSlug } } = yield select(getSubscriptionInfo);
    const { getResponseData, errors } = yield call(iclinic.product.fetchProduct, extraProductSlug);
    if (errors) {
      yield put(actions.fetchGetProductFailure(emitErrors(errors)));
    } else {
      const payload = getResponseData();
      yield put(actions.fetchGetProductSuccess(payload));
    }
  } catch (error) {
    captureException(error);
  }
}

export default function* subscriptionSagas() {
  yield takeLatest(types.SUBSCRIBE.SUBMIT, workerSubscribeSubmit);
  yield takeLatest(types.FETCH_GET_PRODUCT.ACTION, workerGetProduct);
  yield takeLatest(types.FETCH_GET_PRODUCTS.ACTION, workerGetProducts);
}
