import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialIcons } from '@iclinic/design-system';

import useStyles, {
  Info,
  MarkdownContainer,
  StyledMarkdown,
  Container,
  FeedbackContainer,
  FeedbackActions,
  FeedbackText,
  StyledIconButton,
  FeedbackSuccess,
} from './Summary.styles';
import { getSummaryDataState } from '../../state/selector';
import { postFeedbackIA } from 'features/records/services';
import { actions } from '../../state';
import { dispatchGaEvent } from 'shared/utils/googleAnalytics';

const { ThumbUpRounded, ThumbDownRounded } = MaterialIcons;

const { updateSummary } = actions;

const Summary = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { markdown, isFeedbackSent, requestId } =
    useSelector(getSummaryDataState);
  type FeedbackTypes = 'like' | 'dislike' | 'none';
  const [feedbackSelected, setFeedbackSelected] =
    useState<FeedbackTypes>('none');
  const [showFeedback, setShowFeedback] = useState(false);

  const getIsVisible = (feedback: FeedbackTypes) =>
    feedbackSelected === 'none' || feedbackSelected === feedback;

  const getFeedbackToData = (feedback: FeedbackTypes) => {
    if (feedback === 'dislike') return -1;
    if (feedback === 'like') return 1;
    return 0;
  };

  const handleFeedback = async (feedback: FeedbackTypes) => {
    try {
      setFeedbackSelected(feedback);
      await postFeedbackIA({
        requestId: requestId as string,
        feedback: getFeedbackToData(feedback),
      });

      dispatch(
        updateSummary({
          isFeedbackSent: true,
        }),
      );
    } catch {
      setFeedbackSelected('none');
    }
  };

  useEffect(() => {
    if (!isFeedbackSent) {
      setShowFeedback(true);
    }

    dispatchGaEvent('impression', {
      event_name: 'resumoai_sucess',
      component: 'popup',
    });
  }, []);

  return (
    <Container data-testid="summary-component">
      <MarkdownContainer>
        <StyledMarkdown options={{ wrapper: 'article' }}>
          {markdown}
        </StyledMarkdown>
      </MarkdownContainer>
      <Info>
        Recurso em fase de testes. Revise as informações antes de aplicar na sua
        decisão clínica.
      </Info>
      {showFeedback && (
        <FeedbackContainer
          className={isFeedbackSent ? classes.showFeedbackSuccess : ''}
        >
          {isFeedbackSent ? (
            <FeedbackSuccess>Feedback registrado, agradecemos.</FeedbackSuccess>
          ) : (
            <FeedbackActions>
              <FeedbackText>Avalie esse resumo</FeedbackText>
              <StyledIconButton
                label="gostei"
                tooltip="none"
                size="md"
                isVisible={getIsVisible('like')}
                onClick={() => handleFeedback('like')}
                disabled={feedbackSelected === 'like'}
                data-ga="resumoai_thumbsup"
              >
                <ThumbUpRounded />
              </StyledIconButton>
              <StyledIconButton
                label="não gostei"
                tooltip="none"
                size="md"
                isVisible={getIsVisible('dislike')}
                onClick={() => handleFeedback('dislike')}
                disabled={feedbackSelected === 'dislike'}
                data-ga="resumoai_thumbsdown"
              >
                <ThumbDownRounded />
              </StyledIconButton>
            </FeedbackActions>
          )}
        </FeedbackContainer>
      )}
    </Container>
  );
};

export default Summary;
