import { FinancialSellerRawData } from 'features/onlinePayment/services/panel/types';
import { FinancialSellerData } from './types';

export const normalizeFinancialSellerResponseData = (
  financialData: FinancialSellerRawData,
): FinancialSellerData => {
  const {
    count_transactions_approved,
    total_amount_transactions_approved,
    average_ticket_transactions_approved,
    value_to_antecipate,
    total_receivables_value,
  } = financialData.data;

  return {
    countTransactionsApproved: count_transactions_approved,
    totalAmountTransactionsApproved: total_amount_transactions_approved,
    averageTicketTransactionsApproved: average_ticket_transactions_approved,
    valueToAntecipate: value_to_antecipate,
    totalReceivablesValue: total_receivables_value,
  };
};
