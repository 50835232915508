import React, { useEffect } from 'react';
import { LocalAudioTrack, Participant, RemoteAudioTrack } from 'twilio-video';
import classnames from 'classnames';
import { Box, Body } from '@iclinic/design-system';

import FeedbackCameraOff from 'features/video-conference/components/Feedback/CameraOff';
import FeedbackMicOff from 'features/video-conference/components/Feedback/MicrophoneOff';
import RemoteParticipatInfo from 'features/video-conference/components/Feedback/RemoteParticipatInfo';
import AudioLevelIndicator from 'features/video-conference/components/Feedback/AudioLevelIndicator';
import useScreenShareParticipant from 'features/video-conference/hooks/useScreenShareParticipant';
import useParticipantIsReconnecting from 'features/video-conference/hooks/useParticipantIsReconnecting';
import usePublications from 'features/video-conference/hooks/usePublication';
import useTrack from 'features/video-conference/hooks/useTrack';
import useIsTrackEnabled from 'features/video-conference/hooks/useIsTrackEnabled';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import {
  isMobile,
  limitCaptionSizeAndGetLines,
} from 'features/video-conference/utils';
import NetworkQualityLevel from 'features/video-conference/components/Feedback/NetworkQualityLevel';
import {
  RECONNECTING_PARTICIPANT_TITLE,
  RECONNECTING_PARTICIPANT_MESSAGE,
} from 'features/video-conference/constants';
import useStyles from 'features/video-conference/components/RemoteParticipantVideo/styles';
import { CaptionContainer } from './styles';
import { useCaptionContext } from 'features/video-conference/state/captionContext';

interface RemoteParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  isDrawerOpen: boolean;
}

export default function RemoteParticipantInfo({
  participant,
  children,
  isDrawerOpen,
}: RemoteParticipantInfoProps) {
  const classes = useStyles({ height: 0 });

  const { isPhysician } = useConference();
  const { physicianInfo, patientInfo } = useParticipantContext();
  const publications = usePublications(participant);
  const screenShareParticipant = useScreenShareParticipant();
  const { isCaptionActive, remoteTranscript, setRemoteTranscript } =
    useCaptionContext();

  const remoteParticipantInfo = isPhysician ? patientInfo : physicianInfo;

  const audioPublication = publications.find((p) => p.kind === 'audio');
  const videoPublication = publications.find((p) => p.kind === 'video');

  const isVideoEnabled = Boolean(videoPublication);

  const audioTrack = useTrack(audioPublication) as
    | LocalAudioTrack
    | RemoteAudioTrack
    | undefined;

  const isAudioEnabled = useIsTrackEnabled(audioTrack);
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  useEffect(() => {
    if (!isAudioEnabled) setRemoteTranscript('');
  }, [isAudioEnabled, setRemoteTranscript]);

  return (
    <Box
      className={classnames(classes.participant, {
        [classes.fullWidth]: isMobile || isDrawerOpen || screenShareParticipant,
      })}
    >
      {(!screenShareParticipant || isMobile) && (
        <>
          {isAudioEnabled && (
            <AudioLevelIndicator audioTrack={audioTrack} isLocal={false} />
          )}

          {!isAudioEnabled && (
            <FeedbackMicOff
              isVideoEnabled={isVideoEnabled}
              isLocal={false}
              participant={participant}
            />
          )}

          <NetworkQualityLevel participant={participant} />

          {isCaptionActive && (
            <CaptionContainer
              data-testid="caption-container"
              remoteTranscript={remoteTranscript}
            >
              {limitCaptionSizeAndGetLines(remoteTranscript, isMobile).map(
                (value) => (
                  <Body variant="sm" inverseTextColor key={value}>
                    {value}
                  </Body>
                ),
              )}
            </CaptionContainer>
          )}

          {!isVideoEnabled && (
            <FeedbackCameraOff participant={remoteParticipantInfo} />
          )}

          {isVideoEnabled && (
            <RemoteParticipatInfo participant={remoteParticipantInfo} />
          )}

          {isParticipantReconnecting && (
            <Box className={classes.reconnectingContainer}>
              <Body variant="sm" inverseTextColor>
                <strong>{RECONNECTING_PARTICIPANT_TITLE}</strong>
                {RECONNECTING_PARTICIPANT_MESSAGE}
              </Body>
            </Box>
          )}
        </>
      )}
      {children}
    </Box>
  );
}
