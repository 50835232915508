import { FormikErrors } from 'formik';

import {
  formKeys,
  FormKeysEnum,
} from '../../appointmentGuide/createGuide/validationSchema';
import { AppointmentGuideFormValues } from 'features/tissInvoicing/types/appointmentGuide';

type AppointmentGuideKeys = keyof AppointmentGuideFormValues;
type AppointmentGiodeSections = keyof typeof formKeys;
type ValidateFormFnProps = (
  errors: FormikErrors<AppointmentGuideFormValues>,
  sectionRef: React.RefObject<(HTMLButtonElement | null)[]>,
) => void;

export const validateAppointmentGuideForm: ValidateFormFnProps = (
  errors,
  sectionRef,
) => {
  const scrollInto: HTMLButtonElement[] = [];
  Object.keys(formKeys).forEach((section) => {
    const hasError = formKeys[section as AppointmentGiodeSections].some(
      (field) => !!errors[field as AppointmentGuideKeys],
    );

    const formKey = FormKeysEnum[section as keyof typeof FormKeysEnum];
    const element = sectionRef.current ? sectionRef.current[formKey] : null;

    if (hasError && element) {
      if (element.getAttribute('data-status') === 'closed') element.click();
      scrollInto.push(element);
    }
  });

  if (scrollInto.length > 0)
    scrollInto[0].scrollIntoView({ behavior: 'smooth' });
};
