// Internal
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import Turnstile from 'react-turnstile';

// External
import {
  required,
  nameValid,
  requiredEmpty,
  cardNumber,
  expirationDate,
  securityCode,
  personDocument,
} from 'shared/utils/formValidation';
import {
  CREDIT_CARD_NUMBER,
  CREDIT_CARD_VALIDITY,
  CREDIT_CARD_CVV,
  CPF_CNPJ,
} from 'shared/constants/masks';
import { getCardImage } from 'shared/utils/creditCardValidation';
import InputLabel from 'ui/form/InputLabel';
import Form from './form';
import { setTurnstileToken } from 'state/creditcard/actions';

function SubscriptionModalForm({ handleSubmit, number = '', cardAdd = true }) {
  const dispatch = useDispatch();

  return (
    <div onSubmit={handleSubmit}>
      <Form.Title content="CARTÃO DE CRÉDITO" />
      <Field
        id="owner"
        name="owner"
        type="text"
        label="Nome impresso no cartão"
        component={InputLabel}
        validate={[required, nameValid]}
        autoFocus
      />
      <div>
        <Field
          id="number"
          name="number"
          type="text"
          label="Número do cartão"
          placeholder="0000 0000 0000 0000"
          component={InputLabel}
          mask={CREDIT_CARD_NUMBER}
          validate={[required, cardNumber]}
          append={
            <img
              alt="Bandeira do cartão de crédito"
              src={getCardImage(number)}
            />
          }
        />
      </div>
      <Form.Group>
        <Field
          id="expirationDate"
          name="expirationDate"
          type="text"
          label="Data de vencimento"
          placeholder="MM/AAAA"
          component={InputLabel}
          size="md"
          mask={CREDIT_CARD_VALIDITY}
          validate={[required, expirationDate]}
        />
        <Field
          id="cvv"
          name="cvv"
          label="CVV"
          type="text"
          placeholder="000"
          component={InputLabel}
          size="sm"
          mask={CREDIT_CARD_CVV}
          validate={[requiredEmpty, securityCode]}
        />
      </Form.Group>
      <Turnstile
        sitekey={process.env.CF_TURNSTILE_SITE_KEY || ''}
        onVerify={(token) => dispatch(setTurnstileToken(token))}
        retryInterval={300}
        theme="light"
        refreshExpired="auto"
      />
      {cardAdd && (
        <Field
          id="cpfCnpj"
          name="cpfCnpj"
          label="CPF ou CNPJ"
          type="text"
          component={InputLabel}
          size="md"
          validate={[required, personDocument]}
          mask={CPF_CNPJ}
        />
      )}
    </div>
  );
}

SubscriptionModalForm.propTypes = {
  cardAdd: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  number: PropTypes.string,
};

export default SubscriptionModalForm;
