import { createSelector } from '@reduxjs/toolkit';

import { emailMessageByErrorCode } from '../constants';
import { defaultErrorMessage } from '../../constants';
import { StoreState } from 'state/rootReducerLogin';
import { CBOList, Profession } from './types';

export const signupSelector = (state: StoreState) => state.stepSignup;
export const statusSelector = (state: StoreState) => state.stepSignup.status;
export const emailSelector = (state: StoreState) => state.stepSignup.email;
export const errorSelector = (state: StoreState) => state.stepSignup.error;
export const progressSelector = (state: StoreState) =>
  state.stepSignup.progressStep;
export const professionSelector = (state: StoreState) =>
  state.stepSignup.profession;
export const isAnotherHealthProfessionalSelector = (state: StoreState) =>
  state.stepSignup.profession === Profession.AnotherHealthProfessional;
export const isDoctorSelector = (state: StoreState) =>
  state.stepSignup.profession === Profession.Doctor;
export const professionalCountSelector = (state: StoreState) =>
  state.stepSignup.professionalCount;
export const passwordSelector = (state: StoreState) =>
  state.stepSignup.password;
export const nameSelector = (state: StoreState) => state.stepSignup.name;
export const billingPhoneSelector = (state: StoreState) =>
  state.stepSignup.billingPhone;
export const listCBOState = (state: StoreState) => state.stepSignup.listCBO;
export const listCBOSelector = createSelector(listCBOState, (cboData) =>
  cboData.map((item) => item.term),
);
export const getCboIdSelector = (state: StoreState, cbo: string) =>
  state.stepSignup.listCBO &&
  state.stepSignup.listCBO.find((cbos: CBOList) => cbos.term === cbo)?.cbo;

export const emailErrorSelector = createSelector(errorSelector, (error) => {
  const emailMessage = emailMessageByErrorCode[error];
  const messageDescription = emailMessage || defaultErrorMessage;
  const message = error ? messageDescription : '';

  return { emailError: !!emailMessage, message };
});

export const submitDataSelector = (state: StoreState) => ({
  email: state.stepSignup.email,
  name: state.stepSignup.name,
  billing_phone: state.stepSignup.billingPhone,
  password: state.stepSignup.password,
  professional_count: state.stepSignup.professionalCount,
  profession: state.stepSignup.profession,
  cbo: state.stepSignup.cbo,
});

export const isVariantSelector = (state: StoreState) =>
  state.stepSignup.isVariant;
