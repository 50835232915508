import {
  pdfUrls,
  documentsKind,
  PatientSummary,
  GetPatientHeaderResponse,
} from './types';

const extractorEventsBlocksIds = (eventBlocks: any, patientId: any) => {
  const eventBlocksIds = [];
  const eventId = eventBlocks.id;
  const physicianId = eventBlocks.physician_id;

  eventBlocksIds.push({
    id: eventId,
    documentType: 'record',
    url: `/patients/patient/${patientId}/records/pdf/?physician_id=${physicianId}&event_id=${eventId}&rgb_scale=true&demographic_data=true&add_images=true`,
  });

  eventBlocks.tabs.forEach((tabsItem: any) => {
    const documentType = tabsItem.id;
    const isEvents =
      documentType === documentsKind.attest ||
      documentType === documentsKind.prescription;
    tabsItem.eventblocks.forEach((eventblocks: any) => {
      const checkOphthalmic = eventblocks.kind === documentsKind.ophthalmic;
      const checkExamRequest = eventblocks.kind === documentsKind.exam_request;
      const checkBudget = eventblocks.kind === documentsKind.budget;

      const pathUrl = pdfUrls[documentType];
      const pathUrlKind = pdfUrls[eventblocks.kind];

      if (isEvents) {
        eventBlocksIds.push({
          id: eventblocks.id,
          documentType,
          url: `${pathUrl}${eventblocks.id}/pdf`,
        });
      }

      if (checkOphthalmic) {
        eventBlocksIds.push({
          id: eventblocks.id,
          documentType: eventblocks.kind,
          url: `${pathUrlKind}${eventblocks.id}/pdf`,
        });
      }

      if (checkExamRequest) {
        eventBlocksIds.push({
          id: eventblocks.id,
          documentType: eventblocks.kind,
          url: `${pathUrlKind}${eventblocks.id}/`,
        });
      }

      if (checkBudget) {
        eventBlocksIds.push({
          id: eventblocks.id,
          documentType: eventblocks.kind,
          url: `${pathUrlKind}${eventblocks.id}/pdf-budget`,
        });
      }
    });
  });
  return eventBlocksIds;
};

export default extractorEventsBlocksIds;

export const patientHeaderServiceResponseAdapter = (
  data: GetPatientHeaderResponse,
): PatientSummary => {
  const {
    age,
    civil_name: civilName,
    email,
    first_appointment: firstAppointment,
    insurance_name: insuranceName,
    mobile_phone: mobilePhone,
    home_phone: homePhone,
    name,
    patient_related,
    url_image: urlImage,
  } = data;

  return {
    age,
    civilName,
    email,
    firstAppointment,
    insuranceName,
    mobilePhone,
    homePhone,
    name,
    urlImage,
    patientRelated: patient_related?.map((item: any) => ({
      relatedPatientName: item.related_patient_name,
      relatedness: item.relatedness,
    })),
  };
};
