import { StoreState } from 'state/rootReducer';

export const getAppointmentGuideList = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.appointmentGuideList;

export const getTissVersionState = ({ tissInvoicing }: StoreState): string[] =>
  tissInvoicing.appointmentGuideList.tiss_versions;

export const getUser = ({ userInfo }: StoreState) => userInfo;

export const getPhysiciansAndClinics = ({ tissInvoicing }: StoreState) => ({
  physicians: tissInvoicing.appointmentGuideList.physicians,
  clinics: tissInvoicing.appointmentGuideList.clinics,
});
