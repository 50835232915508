import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AppointmentGuideDTO,
  FetchAppointmentGuideRequestParams,
  PaginateGuideListParams,
  SnackbarProps,
} from 'features/tissInvoicing/types';
import getSnackbarPayload from 'features/tissInvoicing/utils/getSnackbarPayload';

type PhysicianAndClinicsParams = {
  name: string;
  id: string;
};

export type AppointmentGuideListState = {
  list?: AppointmentGuideDTO[];
  paginate?: PaginateGuideListParams;
  loading: boolean;
  snackbar: SnackbarProps;
  tiss_versions: string[];
  physicians?: PhysicianAndClinicsParams[];
  clinics?: PhysicianAndClinicsParams[];
};

const name = '@webapp/appointmentGuideList';

export const initialState: AppointmentGuideListState = {
  loading: true,
  snackbar: getSnackbarPayload(),
  tiss_versions: [],
  physicians: [],
  clinics: [],
};

const fetchAppointmentGuideList =
  createAction<FetchAppointmentGuideRequestParams>(
    `${name}/fetchAppointmentGuideList`,
  );
const deleteAppointmentguide = createAction<{ IDs: string[] }>(
  `${name}/deleteAppointmentguideState`,
);
const fetchTissVersions = createAction(`${name}/fetchTissVersions`);

const AppointmentGuideListSlice = createSlice({
  name,
  initialState,
  reducers: {
    setListFetchStatus: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
    setGuides: (
      state,
      action: PayloadAction<
        Omit<AppointmentGuideListState, 'snackbar' | 'tiss_versions'>
      >,
    ) => ({
      ...state,
      list: action.payload.list,
      paginate: action.payload.paginate,
      loading: action.payload.loading,
    }),
    resetAppointmentGuideList: () => initialState,
    setSnackbarContent: (state, action: PayloadAction<SnackbarProps>) => ({
      ...state,
      snackbar: action.payload,
    }),
    setTissVersions: (state, action: PayloadAction<string[]>) => ({
      ...state,
      tiss_versions: action.payload,
    }),
    setPhysiciansAndClinics: (
      state,
      action: PayloadAction<{
        physicians: PhysicianAndClinicsParams[];
        clinics: PhysicianAndClinicsParams[];
      }>,
    ) => ({
      ...state,
      physicians: action.payload.physicians,
      clinics: action.payload.clinics,
    }),
  },
});

export default AppointmentGuideListSlice.reducer;
export const actions = {
  ...AppointmentGuideListSlice.actions,
  fetchAppointmentGuideList,
  deleteAppointmentguide,
  fetchTissVersions,
};
