import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';

import { Loader, MaterialIcons } from '@iclinic/design-system';
import { getUrlParams } from 'shared/utils/url';
import {
  fetchDownsellData,
  submitDownsellReasons,
} from '../../state/downsell-reason';
import { isFetchingDownsellOptions } from '../../state/downsell-reason/selectors';
import { getUserInfo } from '../../state/delete-multiple-physician/selectors';
import { DELETED_USERS_PARAM } from '../../constants';
import FormDownsellReason from './FormDownsellReason';
import { initialValues } from './form.normalizer';
import { DownsellReasonFormData } from './types';
import * as S from './DownsellReason.styles';
import { trackDownsellReason } from '../../utils/trackDownsellEvents';

const { CheckCircleOutline } = MaterialIcons;

const DownsellReason = () => {
  const dispatch = useDispatch();
  const isFetchingOptions = useSelector(isFetchingDownsellOptions);
  const userData = useSelector(getUserInfo);

  const deletedUsersParam = getUrlParams(DELETED_USERS_PARAM);

  useEffect(() => {
    dispatch(fetchDownsellData(deletedUsersParam.split(',')));
  }, [deletedUsersParam, dispatch]);

  const handleSubmit = (formData: DownsellReasonFormData) => {
    trackDownsellReason({
      userData,
      stepName: 'submit',
      stepNum: 3,
    });
    dispatch(submitDownsellReasons(formData));
  };

  return (
    <S.Root maxWidth="md">
      <S.Alert
        severity="success"
        icon={<CheckCircleOutline />}
        title="Profissional excluído"
        message="A cobrança foi ajustada de acordo com os profissionais que permanecem na conta"
      />

      {isFetchingOptions ? (
        <S.LoaderWrapper>
          <Loader />
        </S.LoaderWrapper>
      ) : (
        <S.Card>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <FormDownsellReason />
          </Formik>
        </S.Card>
      )}
    </S.Root>
  );
};

export default DownsellReason;
