import React from 'react';

import Modal from 'ui/new/modal';
import Button from 'ui/new/button';

import style from './ConfirmDeleteComponentModal.scss';

type ConfirmDeleteModalProps = {
  onDeleteComponent: () => void,
  onCloseClick: () => void;
};

const ConfirmDeleteComponentModal = ({
  onCloseClick,
  onDeleteComponent,
}: ConfirmDeleteModalProps) => (
  <div className={style.modal}>
    <Modal
      show
      type="danger"
      title="Deseja excluir este campo de e-mail?"
      onClose={onCloseClick}
      classes={style}
      width={440}
    >
      <Modal.Body classes={style}>
        <p>Esta ação não poderá ser revertida.</p>
      </Modal.Body>
      <Modal.Footer align="right">
        <Button type="light" onClick={onCloseClick}>CANCELAR</Button>
        <Button type="error" onClick={onDeleteComponent}>EXCLUIR</Button>
      </Modal.Footer>
    </Modal>
  </div>

);

export default ConfirmDeleteComponentModal;
