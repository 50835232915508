import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type upgradeCallState = {
  totalPayingUsers: number;
  loading: boolean;
};

export const initialState: upgradeCallState = {
  totalPayingUsers: 0,
  loading: false,
};

const nameStore = '@webapp/upgradeCall';

const upgradeCallSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    setTotalPayingUsers: (state, action: PayloadAction<number>) => ({
      ...state,
      totalPayingUsers: action.payload,
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      loading: action.payload,
    }),
  },
});

export const { setTotalPayingUsers, setLoading } = upgradeCallSlice.actions;

const { reducer } = upgradeCallSlice;

export default reducer;
