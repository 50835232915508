import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  title: {
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(2),
  },
  subTitle: {
    color: theme.palette.grey[500],
  },
}));
