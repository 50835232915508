import * as React from 'react';
import { SIZE } from 'ui/new/button/constants';

import ActionButton from './ActionButton';

import style from './style/base.scss';

type ActionButtonGroupProps = {
  onDelete: () => void;
  onEdit: () => void;
  onDuplicate: () => void;
  target: any;
};

const DummyItem = <div className={style.dummye} />;

export default function ActionButtonGroup({
  onDelete,
  onEdit,
  onDuplicate,
  target = null,
}: ActionButtonGroupProps) {
  return (
    <div className={style.actions_container}>
      {onEdit ? (
        <ActionButton
          target={target}
          onClick={onEdit}
          icon="edit"
          altText="Editar"
          size={SIZE.ActionButton}
        />
      ) : DummyItem}
      {onDuplicate && (
        <ActionButton
          target={target}
          onClick={onDuplicate}
          icon="filter_none"
          altText="Duplicar"
          size={SIZE.ActionButton}
        />
      )}

      {onDelete && (
        <ActionButton
          target={target}
          onClick={onDelete}
          icon="delete"
          altText="Excluir"
          size={SIZE.ActionButton}
        />
      )}

    </div>
  );
}
