import { Kind } from '../types';

export const title: Record<Kind, string> = {
  physician: 'Programa de Indicações',
  receptionist: 'Programa de Indicações para Recepcionistas',
};

export const intercomEventName = 'interest-indication-intercom';
export const promoterIntercomEventName = 'promoter-congratulations-intercom';

export * from './banner';
export * from './indicationAwards';
export * from './howToWorkCards';
export * from './questions';
export * from './rewardsRedemption';
export * from './rewardsContragulations';
