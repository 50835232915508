import {
  MaterialUICore,
  Snackbar as DSSnackbar,
  IconButton as DSIconButton,
  axiomTokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color } = axiomTokens;

export const Snackbar = styled(DSSnackbar)({
  '& .MuiSnackbarContent-action': {
    marginRight: 0,
    padding: 0,
  },
});

export const IconButton = styled(DSIconButton)({
  '& .MuiSvgIcon-root': {
    color: color.icon.default,
  },
});
