import React from 'react';
import { useSelector } from 'react-redux';

import { MaterialUICore, Paper } from '@iclinic/design-system';
import { EmailValidation } from './components/EmailValidaton';
import { useStyles } from './styles';
import { getSettingsIntegration } from 'features/settings/scheduleOnline/state/selectors';
import { IntegrationStep } from 'shared/constants/settings';
import { PasswordCreation } from './components/PasswordCreation';
import {
  CreateSuccess,
  EmailAlreadyUsedError,
  IntegrationSuccess,
  DefaultError,
} from './components/ErrorSuccessContent';
import { PasswordConfirmation } from './components/PasswordConfirmation';

const { Modal, Backdrop, Fade } = MaterialUICore;

type IntegrationModalProps = {
  isShown: boolean;
};

const IntegrationModal = ({ isShown }: IntegrationModalProps) => {
  const { step } = useSelector(getSettingsIntegration);
  const { paper } = useStyles();

  const renderStep = (currentStep: IntegrationStep) => {
    switch (currentStep) {
      case IntegrationStep.PasswordCreation:
        return <PasswordCreation />;
      case IntegrationStep.PasswordValidation:
        return <PasswordConfirmation />;
      case IntegrationStep.CreationSuccess:
        return <CreateSuccess />;
      case IntegrationStep.IntegrationSuccess:
        return <IntegrationSuccess />;
      case IntegrationStep.EmailAlreadyUsed:
        return <EmailAlreadyUsedError />;
      case IntegrationStep.DefaultError:
        return <DefaultError />;
      default:
        return <EmailValidation />;
    }
  };
  return (
    <Modal
      aria-labelledby="integration-modal-title"
      aria-describedby="integration-modal-description"
      open={isShown}
      disableBackdropClick
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isShown}>
        <Paper className={paper}>{renderStep(step)}</Paper>
      </Fade>
    </Modal>
  );
};

export default IntegrationModal;
