import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, MaterialIcons } from '@iclinic/design-system';

import { formatDateToIsoStandard } from 'shared/utils/date';
import { setDateReadNotifications } from 'features/onlinePayment/state/notifications-center';
import {
  getUnreadNotifications,
  getUnreadNotificationsCount,
} from 'features/onlinePayment/state/notifications-center/selectors';
import * as S from './styles';

const { ArrowForward, Notifications: NotificationsIcon } = MaterialIcons;

const MarkAsRead = (): JSX.Element => {
  const dispatch = useDispatch();
  const unreadNotificationsCount = useSelector(getUnreadNotificationsCount);
  const unreadNotifications = useSelector(getUnreadNotifications);

  const handleClick = () => {
    const readDate = formatDateToIsoStandard(new Date());
    dispatch(setDateReadNotifications(readDate));
  };

  return (
    <Box
      m={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <S.IconWrapperLighter>
          <NotificationsIcon fontSize="small" />
        </S.IconWrapperLighter>
        <S.Text variant="xxs">Notificações</S.Text>
      </Box>

      <Button
        variant="text"
        startIcon={<ArrowForward />}
        size="small"
        onClick={handleClick}
        color="primary"
        disabled={!unreadNotificationsCount || !unreadNotifications}
      >
        Marcar como lido
      </Button>
    </Box>
  );
};

export default MarkAsRead;
