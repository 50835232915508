import React from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import {
  Grid,
  MaterialIcons,
  MaterialUICore,
  TextField,
  InputAdornment,
  Box,
  SectionTitle,
} from '@iclinic/design-system';
import { ResponsiveCard } from 'features/onlinePayment/components';
import { getSellerEmailsSettingsSaveError } from 'features/onlinePayment/state/config/edit/selectors';
import { ErrorAlert } from 'shared/components';
import EmailChip from './EmailChip';
import Subtitle from '../Subtitle';
import useStyles from './CardEmails.styles';

const { FormControlLabel, Checkbox, IconButton } = MaterialUICore;
const { MonetizationOn, AddCircleOutline } = MaterialIcons;

export default function CardEmails(): JSX.Element {
  const classes = useStyles();
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldError,
  } = useFormikContext<any>();
  const error = useSelector(getSellerEmailsSettingsSaveError);

  const handleDeleteEmailChipConfirm = (value: string) => () => {
    const filteredEmails = values.paymentConfirmationEmails.filter(
      (email: string) => email !== value,
    );
    setFieldValue('paymentConfirmationEmails', [...filteredEmails]);
  };

  const handleDeleteEmailChipWeekly = (value: string) => () => {
    const filteredEmails = values.weeklySummaryEmails.filter(
      (email: string) => email !== value,
    );
    setFieldValue('weeklySummaryEmails', [...filteredEmails]);
  };

  const handleFieldEmailValidation = (
    emailList: string[],
    field: string,
    setField: string,
  ) => {
    if (values[field] === '') {
      setFieldError(field, 'Escreva um endereço de e-mail para adicionar');
      return;
    }

    if (!errors[field] && values[field]) {
      setFieldValue(setField, [...emailList, values[field]]);
      setFieldValue(setField, [...emailList, values[field]]);
      setFieldValue(field, '');
    }
  };

  return (
    <ResponsiveCard variant="outlined">
      <SectionTitle
        icon={<MonetizationOn />}
        title="E-mail de confirmação"
        mb={1}
      />
      <Grid direction="column" spacing={3} container>
        <Grid item>
          <Subtitle>
            Escolha quais e-mails irão receber seus comprovantes de pagamentos
          </Subtitle>
        </Grid>
        {error && (
          <Grid item>
            <ErrorAlert
              message={<span dangerouslySetInnerHTML={{ __html: error }} />}
              name="save-emails-error"
              scrollToElement
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="paymentConfirmationEnabled"
                name="paymentConfirmationEnabled"
                color="primary"
                checked={values.paymentConfirmationEnabled}
                onChange={handleChange}
              />
            }
            label="Confirmação de pagamento"
            classes={{
              label: classes.labelCheckbox,
            }}
          />
          <TextField
            id="fieldEmailPayConfirm"
            name="fieldEmailPayConfirm"
            label="E-mails que devem receber:"
            placeholder="Insira um novo e-mail"
            value={values.fieldEmailPayConfirm}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            disabled={!values.paymentConfirmationEnabled}
            error={
              !!(
                (errors.fieldEmailPayConfirm ||
                  errors.paymentConfirmationEmails) &&
                touched.fieldEmailPayConfirm
              )
            }
            helperText={
              touched.fieldEmailPayConfirm &&
              (errors.fieldEmailPayConfirm || errors.paymentConfirmationEmails)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    disabled={!values.paymentConfirmationEnabled}
                    onClick={() => {
                      handleFieldEmailValidation(
                        values.paymentConfirmationEmails,
                        'fieldEmailPayConfirm',
                        'paymentConfirmationEmails',
                      );
                    }}
                  >
                    <AddCircleOutline />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {values.paymentConfirmationEmails?.length > 0 && (
            <Box mt={1}>
              <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                {values.paymentConfirmationEmails.map((email: string) => (
                  <Grid item key={email}>
                    <EmailChip
                      value={email}
                      onDelete={handleDeleteEmailChipConfirm(email)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="weeklySummaryEnabled"
                name="weeklySummaryEnabled"
                color="primary"
                checked={values.weeklySummaryEnabled}
                onChange={handleChange}
              />
            }
            label="Relatório de pagamentos semanais"
            classes={{
              label: classes.labelCheckbox,
            }}
          />
          <TextField
            id="fieldEmailPayWeeklyReport"
            name="fieldEmailPayWeeklyReport"
            label="E-mails que devem receber:"
            placeholder="Insira um novo e-mail"
            value={values.fieldEmailPayWeeklyReport}
            onChange={handleChange}
            onBlur={handleBlur}
            fullWidth
            disabled={!values.weeklySummaryEnabled}
            error={
              !!(
                (errors.fieldEmailPayWeeklyReport ||
                  errors.weeklySummaryEmails) &&
                touched.fieldEmailPayWeeklyReport
              )
            }
            helperText={
              touched.fieldEmailPayWeeklyReport &&
              (errors.fieldEmailPayWeeklyReport || errors.weeklySummaryEmails)
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    disabled={!values.weeklySummaryEnabled}
                    onClick={() => {
                      handleFieldEmailValidation(
                        values.weeklySummaryEmails,
                        'fieldEmailPayWeeklyReport',
                        'weeklySummaryEmails',
                      );
                    }}
                  >
                    <AddCircleOutline />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {values.weeklySummaryEmails?.length > 0 && (
            <Box mt={1}>
              <Grid
                container
                justify="flex-start"
                alignItems="flex-start"
                spacing={1}
              >
                {values.weeklySummaryEmails.map((email: string) => (
                  <Grid item key={email}>
                    <EmailChip
                      value={email}
                      onDelete={handleDeleteEmailChipWeekly(email)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Grid>
      </Grid>
    </ResponsiveCard>
  );
}
