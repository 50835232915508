import * as Yup from 'yup';

export const requiredFieldText = 'Campo obrigatório';

export const patientSectionSchema = Yup.object().shape({
  patient_insurance_code: Yup.string().required(requiredFieldText),
  newborn: Yup.string().required(requiredFieldText),
  patient_sus_code: Yup.string().required(requiredFieldText),
  patient_civil_name: Yup.string().required(requiredFieldText),
});
