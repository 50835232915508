import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles, createStyles } = MaterialUICore;

const useStyles = makeStyles((theme) =>
  createStyles({
    drawerPaper: {
      boxShadow: '-8px 0px 40px rgba(0, 0, 0, 0.12)',
    },
    content: {
      height: '100%',
      maxWidth: '520px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    backButton: {
      margin: '8px 24px',
      width: '94px',
      color: theme.palette.grey[300],
      marginBottom: '24px',
      '& .MuiTypography-overline': {
        fontSize: '12px',
        lineHeight: '32px',
        letterSpacing: '0.44px',
      },
    },
    footer: {
      justifyContent: 'space-between',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      borderTop: `1px solid ${theme.palette.grey[100]}`,
    },
    closeButton: {
      color: theme.palette.grey[300],
      margin: '16px',
      width: '194px',
      '& .MuiTypography-body1': {
        fontSize: '14px',
        lineHeight: '32px',
        letterSpacing: '0.44px',
      },
    },
    buttonNext: {
      background: theme.palette.secondary.main,
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.primary.main,
      height: '32px',
      minWidth: '72px',
      '&:hover': {
        background: theme.palette.primary.light,
        color: theme.palette.primary.main,
      },
      '& .MuiTypography-body1': {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
    actionsContainer: {
      marginTop: '20px',
    },
    buttonBack: {
      color: theme.palette.grey[300],
      marginRight: '22px',
      '& .MuiTypography-body1': {
        fontSize: '14px',
        lineHeight: '32px',
        letterSpacing: '0.44px',
      },
    },
    stepperContent: {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.44px',
      color: theme.palette.grey[700],
    },
    mainContent: {
      margin: '0px 32px',
      height: '100%',
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
      height: '100px',

      '& .MuiTypography-body1': {
        fontSize: '16px',
        lineHeight: '28px',
        letterSpacing: '0.44px',
      },
      '& .MuiTypography-body2': {
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.44px',
      },
      '& .MuiTypography-h3': {
        fontSize: '27px',
        lineHeight: '34px',
      },
    },
    bodyText: {
      paddingTop: '8px',
      color: theme.palette.grey[500],
    },
    footerButton: {
      height: '48px',
      margin: '16px 32px 16px 16px',
      fontSize: '16px',
      letterSpacing: '0.3px',
      lineHeight: '24px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: '16px',
        justifyContent: 'center',
      },
      backgroundColor: theme.palette.primary.main,
    },
    stepper: {
      '& .MuiStepper-root': {
        padding: '24px 0',
      },
      '& .MuiStepLabel-label': {
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.44px',
      },
      '& .MuiStepIcon-root': {
        width: '24px',
        height: '24px',
        color: theme.palette.primary.main,
      },
      '& .MuiStepIcon-text': {
        fontSize: '14px',
        lineHeight: '24px',
      },
      '& .MuiStepLabel-completed': {
        color: theme.palette.grey[300],
      },
    },
  }),
);

export default useStyles;
