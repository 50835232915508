import { Grid, MaterialIcons, MaterialUICore } from '@iclinic/design-system';
import { useFormikContext } from 'formik';
import React, { forwardRef } from 'react';

import DatePickerSADT from 'features/tissInvoicing/components/sadt/DatePickerSADT';
import SelectFieldSADT from 'features/tissInvoicing/components/sadt/SelectFieldSADT';
import TextFieldSADT from 'features/tissInvoicing/components/sadt/TextFieldSADT';
import PatientCivilNameSADT from 'features/tissInvoicing/containers/components/CommonAutocompletes/PatientCivilNameSADT';
import CollapsibleFormSection from 'features/tissInvoicing/components/ui/CollapsibleFormSection';
import useVersionCompare from 'features/tissInvoicing/hooks/useVersionCompare';
import { NBAppointmentOptions } from 'features/tissInvoicing/state/constants';

const { MenuItem } = MaterialUICore;
const { Person } = MaterialIcons;

interface BeneficiarySectionProps {}

const BeneficiarySection = (
  _: BeneficiarySectionProps,
  ref: React.Ref<HTMLButtonElement>,
): JSX.Element => {
  const { values } = useFormikContext<{ version_id: string }>();
  const { isGreaterThan } = useVersionCompare(values.version_id);
  const versionTissIsGreaterThan3 = isGreaterThan(3);

  return (
    <CollapsibleFormSection
      sectionTitle="Beneficiário"
      sectionIcon={<Person />}
      sectionIdentifier="beneficiary_data"
      ref={ref}
    >
      <input type="hidden" name="patient_id" />

      <Grid container item spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <PatientCivilNameSADT label="10 - Nome do beneficiário" />
        </Grid>
        {versionTissIsGreaterThan3 ? (
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <TextFieldSADT
              label="89 - Nome Social"
              identifier="patient_social_name"
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <TextFieldSADT
              label="11 - Cartão nacional de saúde"
              identifier="patient_sus_code"
              maxLength={15}
            />
          </Grid>
        )}
      </Grid>

      <Grid container item spacing={2}>
        {versionTissIsGreaterThan3 && (
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <TextFieldSADT
              label="11 - Cartão nacional de saúde"
              identifier="patient_sus_code"
              maxLength={15}
            />
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          md={versionTissIsGreaterThan3 ? 6 : 4}
          lg={versionTissIsGreaterThan3 ? 3 : 4}
        >
          <TextFieldSADT
            label="8 - Nº da carteira *"
            identifier="patient_insurance_code"
            maxLength={20}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={versionTissIsGreaterThan3 ? 6 : 4}
          lg={versionTissIsGreaterThan3 ? 3 : 4}
        >
          <DatePickerSADT
            label="9 - Data de validade da carteira"
            identifier="patient_insurance_expiry_date"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={versionTissIsGreaterThan3 ? 6 : 4}
          lg={versionTissIsGreaterThan3 ? 3 : 4}
        >
          <SelectFieldSADT label="12 - Atendimento RN *" identifier="newborn">
            {NBAppointmentOptions.map((NBAppointmentOption) => (
              <MenuItem
                value={NBAppointmentOption.value}
                key={NBAppointmentOption.value}
              >
                {NBAppointmentOption.label}
              </MenuItem>
            ))}
          </SelectFieldSADT>
        </Grid>
      </Grid>
    </CollapsibleFormSection>
  );
};

export default forwardRef(BeneficiarySection);
