import { FAKEDOOR_STORAGE_ITEM } from './constants';

export const daysDiff = (date1: Date, date2: Date) => {
  const diffTime = date2.getTime() - date1.getTime();
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

type ShouldShowProps = {
  subscriptionDate?: string;
};

export const threeMonthsFromToday = ({ subscriptionDate }: ShouldShowProps) => {
  if (!subscriptionDate) return false;
  const today = new Date();
  return daysDiff(new Date(subscriptionDate), today) >= 90;
};

export const checkedTrigger = () =>
  global.localStorage.getItem(FAKEDOOR_STORAGE_ITEM);

export const markAsChecked = () =>
  global.localStorage.setItem(FAKEDOOR_STORAGE_ITEM, '1');
