import { v4 as uuidv4 } from 'uuid';

import { createCookie } from 'shared/utils/cookie';

export const createCookieByDay = (name: string, daysToExpire: number) => {
  const uuid = uuidv4();
  createCookie(name, uuid, daysToExpire * 24 * 60 * 60);
};

export const removeCookie = (name: string) => {
  const uuid = uuidv4();
  createCookie(name, uuid, -1 * 24 * 60 * 60);
};
