import React from 'react';
import { useSelector } from 'react-redux';

import { Box, Typography } from '@iclinic/design-system';
import images from 'shared/paths/images';
import { useStyles } from './styles';
import BottomButtonContainer from '../BottomButtonContainer';
import { getSettingsIntegration } from 'features/settings/scheduleOnline/state/selectors';

export const IntegrationSuccess = () => {
  const integrationData = useSelector(getSettingsIntegration);
  const { image } = useStyles();
  const goToAgendarConsulta = () => {
    window.open(
      `${process.env.ICLINIC_MKT_APP_BASE_URL}/perfil/${integrationData.profile_slug}`,
      '_blank',
    );
  };
  return (
    <>
      <Box mb={2}>
        <img
          className={image}
          src={images.settings.integrationSuccess}
          alt="rosto feliz"
        />
      </Box>
      <Box mb={1}>
        <Typography align="center" bold>
          Parabéns, agora você tem uma agenda online!
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography align="center" variant="body2">
          Compartilhe essa novidade com seus pacientes e mantenha seu perfil
          sempre atualizado para atrair novos pacientes. Acesse o seu clicando
          no botão ver perfil.
        </Typography>
      </Box>
      <BottomButtonContainer
        isIntegrated
        leftLabel="Sair"
        rightLabel="Ver Perfil"
        rightOnClickHandler={goToAgendarConsulta}
      />
    </>
  );
};
