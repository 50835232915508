import React from 'react';
import { DrawerCard, Body } from '@iclinic/design-system';
import { useSelector, useDispatch } from 'react-redux';

import { getTransactionDrawerState } from './state/selector';
import { actions } from './state';
import {
  DrawerHeader,
  TranscriptionWrapper,
} from './TranscriptionDrawer.styles';
import { DrawerContent } from './components/DrawerContent';

const { closeTranscriptionDrawer } = actions;

export const TranscriptionDrawer = () => {
  const { isTranscriptionModalOpen, transcription, isLoading } = useSelector(
    getTransactionDrawerState,
  );
  const dispatch = useDispatch();

  const onDrawerClose = () => {
    dispatch(closeTranscriptionDrawer());
  };

  return (
    <DrawerCard
      variant="persistent"
      open={isTranscriptionModalOpen}
      onClose={onDrawerClose}
    >
      <DrawerHeader
        title="Transcrição da teleconsulta"
        onClose={onDrawerClose}
      />
      <TranscriptionWrapper>
        {transcription.length === 0 && !isLoading ? (
          <Body variant="xs">
            Não existe transcrição para essa teleconsulta
          </Body>
        ) : (
          <DrawerContent transcription={transcription} />
        )}
      </TranscriptionWrapper>
    </DrawerCard>
  );
};
