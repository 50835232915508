// External
import React from 'react';
import { isThemeBlackFridayInterval } from 'shared/utils/validation';

// Internal
import SubscriptionProducts from 'features/billing/subscriptionProducts';
import { UserThemeProvider } from 'shared/hooks/theme';

export default function SubscriptionProductsWrapper(): JSX.Element {
  const theme = {
    isApplied: isThemeBlackFridayInterval(new Date()),
    style: 'blackFriday2019',
  };

  return (
    <UserThemeProvider value={theme}>
      <SubscriptionProducts />
    </UserThemeProvider>
  );
}
