// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import Modal from 'ui/new/modal';
import Button from 'ui/new/button';
import style from './ScheduleEmailModal.scss';

const ScheduleEmailModal = ({
  onCloseClick,
  onScheduleEmail,
  patientCount,
  schedule,
}) => {
  const onClickSave = React.useCallback(() => {
    onScheduleEmail();
  }, [onScheduleEmail]);

  const patientCountInfo = patientCount === 1 ? `${patientCount} paciente` : `${patientCount} pacientes`;
  const messageSchedule = `O e-mail será enviado para ${patientCountInfo} no dia ${schedule.scheduleDate} às ${schedule.scheduleTime}`;

  return (
    <div className={style.modal}>
      <Modal show title="Agendar e-mail" onClose={onCloseClick} classes={style} width={400}>
        <Modal.Body>
          <p className={style.scheduleConfirm}>
            {messageSchedule}
          </p>
        </Modal.Body>
        <Modal.Footer align="right">
          <Button type="light" onClick={onCloseClick}>CANCELAR</Button>
          <Button type="success" onClick={onClickSave}>AGENDAR ENVIO</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

ScheduleEmailModal.propTypes = {
  onCloseClick: PropTypes.func.isRequired,
  onScheduleEmail: PropTypes.func.isRequired,
  patientCount: PropTypes.number.isRequired,
  schedule: PropTypes.shape({
    scheduleDate: PropTypes.string.isRequired,
    scheduleTime: PropTypes.string.isRequired,
  }).isRequired,
};

export default ScheduleEmailModal;
