// External
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Icon from '@material/react-material-icon';

// Internal
import { StoreState } from 'state/rootReducer';
import Table from 'ui/new/table';
import Paginator from 'ui/new/paginator';
import { Row, Column } from 'ui/new/flex';
import { debounce } from 'shared/hooks';
import SearchBar from 'ui/new/search';

import * as actions from 'features/stock/state/actions';
import { normalizeStockSearch } from 'features/stock/state/utils';
import { getLotsFromOpenedProduct } from 'features/stock/state/selectors';
import {
  ModalType,
  StockTabs,
  MIN_CHARS_TO_SEARCH,
} from 'features/stock/constants';
import ActionButton from 'features/stock/components/ActionButton';
import InventoryTable from 'features/stock/components/InventoryTable';

import Header from './Header';
import Notifications from './Notifications';
import Tabs from './Tabs';
import TableHead from './TableHead';
import TableBody from './TableBody';
import Modal from './Modal';

import style from './MyStock.scss';

export default function MyStock(): JSX.Element {
  const [search, setSearch] = useState('');

  const dispatch = useDispatch();
  const {
    stocks,
    errors,
    selectedFilter,
    selectedList,
    totalSelected,
    openedProductId,
    currentModal,
    isFetching,
    page,
    totalCount,
  } = useSelector((state: StoreState) => state.stock);

  const lots = useSelector<StoreState>(getLotsFromOpenedProduct);

  const handleFetchSearch = debounce((value: string = ''): void => {
    dispatch(actions.fetchStockFilter(selectedFilter, value));
  }, 500);

  const handleChangeSearch = useCallback(
    (searchValue: string): void => {
      setSearch(searchValue);

      if (searchValue.length >= MIN_CHARS_TO_SEARCH || !searchValue) {
        handleFetchSearch(searchValue);
      }
    },
    [handleFetchSearch],
  );

  const handleSelectedElement = (id: number): boolean => {
    return !!selectedList.find((item: number) => item === id);
  };

  const handleSelectedPage = useCallback(
    (pageNumber: number): void => {
      dispatch(actions.selectedPageNumber(pageNumber));
      dispatch(
        actions.fetchEspecificStockURL(
          normalizeStockSearch(selectedFilter, search, pageNumber),
        ),
      );
    },
    [search, selectedFilter, dispatch],
  );

  useEffect(() => {
    dispatch(actions.fetchStockFilter(StockTabs.All));
  }, [dispatch]);

  const totalListItem = Object.keys(stocks).length;
  const totalSelectedItems = Object.keys(selectedList).length;

  return (
    <div className={style['container-stock']}>
      {!!errors && <Notifications errors={errors} />}
      <Header
        onClick={(modalType: ModalType) =>
          dispatch(actions.changeModal(modalType))
        }
      />
      <div className={style['content-stock']}>
        <Tabs
          selectedFilter={selectedFilter}
          onChangeTab={(filter: StockTabs) =>
            dispatch(actions.fetchStockFilter(filter))
          }
        />
        <Column className={style['table-stock']}>
          <Row className={style['search-stock']}>
            <SearchBar
              onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeSearch(target.value)
              }
              placeholder="Pesquise por nome ou código do produto"
              name="search"
              value={search}
              max={100}
            />
          </Row>
          <Row>
            <p className={style['total-stock']}>
              Exibindo: {totalListItem} produtos
            </p>
            <Row>
              {totalSelectedItems > 0 && (
                <ActionButton
                  onClick={() =>
                    dispatch(actions.changeModal(ModalType.Delete))
                  }
                >
                  <Icon icon="delete" className={style['print-stock']} />
                  Excluir Permanentemente
                </ActionButton>
              )}
            </Row>
          </Row>
          <InventoryTable>
            {(styles) => (
              <Table>
                <TableHead
                  tableStyles={styles}
                  onSelectAllProducts={() =>
                    dispatch(actions.selectAllProducts())
                  }
                  totalSelected={totalSelected}
                  selectedList={selectedList}
                />
                <TableBody
                  tableStyles={styles}
                  onSelectProduct={(id: number) =>
                    dispatch(actions.updateSelectedList(id))
                  }
                  onViewLots={(id: number) =>
                    dispatch(actions.viewLotsByProductId(id))
                  }
                  checkSelectedProduct={handleSelectedElement}
                  fetchProductAndStock={(modalType: ModalType, id: number) =>
                    dispatch(actions.fetchProductAndStock(modalType, id))
                  }
                  stocks={stocks}
                  lots={lots}
                  openedProductId={openedProductId}
                  totalSelected={totalSelected}
                />
              </Table>
            )}
          </InventoryTable>
          {!!totalCount && totalCount > 0 && (
            <Row className={style['paginator-stock']}>
              <Paginator
                currentPage={page}
                totalCount={totalCount}
                onChangeSelectedPage={handleSelectedPage}
              />
            </Row>
          )}
        </Column>
        {!!currentModal && (
          <Modal
            currentModal={currentModal}
            isFetching={isFetching}
            onClose={() => dispatch(actions.changeModal(null))}
            totalSelectedItems={totalSelectedItems}
          />
        )}
      </div>
    </div>
  );
}
