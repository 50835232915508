import { Box, tokens, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: tokens.spacing.md,
  background: tokens.color.neutral[0],
  borderRadius: tokens.border.radius.md,
  margin: `0 -${tokens.spacing.xs}`,
  marginBottom: tokens.spacing.sm,
});

export const ItemContainer = styled(Box)({
  display: 'flex',
  marginBottom: tokens.spacing.sm,
  alignItems: 'center',
});

export const ItemInputGroup = styled(Box)({
  flex: 1,
  display: 'flex',
});

export const ItemInputContainer = styled(Box)({
  display: 'flex',
  width: `calc(100% / 7)`,
  '&:not(:last-child)': {
    marginRight: 10,
  },
});
