import { combineReducers } from 'redux';

import sadt, { SadtFormState } from './sadt';
import lot, { TISSLotState } from './lot';
import expense, { ExpenseState } from './expense';
import domainTables, { DomainTablesState } from './domainTables';
import autocomplete, { AutocompleteState } from './autocomplete';
import appointmentGuide, {
  AppointmentGuideFormState,
} from './appointmentGuide';
import appointmentGuideList, {
  AppointmentGuideListState,
} from './appointmentGuideList';

export type TissState = {
  appointmentGuideList: AppointmentGuideListState;
  sadt: SadtFormState;
  lot: TISSLotState;
  expense: ExpenseState;
  domainTables: DomainTablesState;
  autocomplete: AutocompleteState;
  appointmentGuide: AppointmentGuideFormState;
};

const tissReducer = combineReducers<TissState>({
  appointmentGuideList,
  sadt,
  lot,
  expense,
  domainTables,
  autocomplete,
  appointmentGuide,
});

export default tissReducer;
