import { Campaign, Component } from 'state/campaignManager/types';

interface GetResourceProps {
  component: Component;
  slug: string;
}

interface GetCampaignsBySlugProps {
  campaigns: Campaign;
  slug: string;
}
export const getImageUrlFromSlug = ({ component, slug }: GetResourceProps) =>
  component.images?.filter((image) => image.slug === slug)[0];

export const getTextValueFromSlug = ({ component, slug }: GetResourceProps) =>
  component.texts?.filter((text) => text.slug === slug)[0];

export const getComponentBySlug = ({
  campaigns,
  slug,
}: GetCampaignsBySlugProps) =>
  campaigns.components.filter((campaign) => campaign.slug === slug)[0];
