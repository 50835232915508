import React from 'react';
import InputMask from 'react-input-mask';

import {
  MaterialUICore,
  Loader,
  ChipCustom,
  Typography,
  TextField,
} from '@iclinic/design-system';
import { ProvidersPSC } from 'state/records/signature/types';
import { ProvidersNames, LIFETIME_CERTIFICATES } from '../containers/constants';
import useStyles from './styleStepCloud';
import { useStepCloud } from '../hooks/useStepCloud';

const { InputLabel, FormControl, MenuItem, Select } = MaterialUICore;

export default function StepCloud(): JSX.Element {
  const classes = useStyles();

  const {
    handleSubmit,
    handleChangeCpf,
    values,
    errors,
    providers,
    isProvider,
    isLoading,
    checkHasToken,
    handleChangeLifeTime,
    lifetime,
    handleChangeProvider,
  } = useStepCloud();

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputMask
          mask="999.999.999-99"
          value={values.cpf as string}
          onChange={handleChangeCpf}
          name="CPF"
        >
          {() => (
            <TextField
              className={classes.userCpf}
              name="cpf"
              label="CPF"
              error={!!errors.cpf}
              helperText={errors.cpf}
            />
          )}
        </InputMask>
        {!errors.cpf && (
          <p className={classes.textTips}>
            Utilizamos para encontrar seus certificados
          </p>
        )}
        {providers.length > 0 && (
          <div className={classes.title}>
            <Typography variant="subtitle1">Escolha um certificado</Typography>
          </div>
        )}
        <div className={classes.listChips}>
          {isLoading ? (
            <Loader
              title="Buscando certificados neste CPF"
              size={30}
              thickness={6}
            />
          ) : (
            providers.length > 0 &&
            providers.map((provider: ProvidersPSC) => (
              <ChipCustom
                key={provider.name}
                label={ProvidersNames[provider.name]}
                selected={isProvider === provider.name}
                data-testid={`provider-${provider.name}`}
                onClick={() => handleChangeProvider(provider)}
              />
            ))
          )}
        </div>
        {checkHasToken && (
          <FormControl
            margin="normal"
            variant="outlined"
            className={classes.lifeTimeForm}
          >
            <InputLabel id="select-expire-password">
              Expiração de senha
            </InputLabel>
            <Select
              labelId="select-expire-password"
              id="expire-password"
              value={lifetime}
              onChange={handleChangeLifeTime}
            >
              {LIFETIME_CERTIFICATES.map((expireTime) => (
                <MenuItem key={expireTime.value} value={expireTime.value}>
                  {expireTime.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </form>
    </>
  );
}
