import { captureException } from 'shared/utils/handlerErrors';
import { CREDIT_CARD_AVAILABLE } from 'shared/constants/creditCard';
import { removeWhitespaces } from './strings';
import { image } from './urlGetter';

const CreditCard = require('creditcard.js');

const validate = new CreditCard();

const issuerIsValid = (issuer: string) => (issuer.indexOf('invalid') === -1
  && CREDIT_CARD_AVAILABLE.includes(issuer));

export const getCreditCardNameByNumber = (number: string) => {
  const issuer = validate.getCreditCardNameByNumber(removeWhitespaces(number));
  return issuerIsValid(issuer.toLowerCase()) ? issuer : null;
};

export const isCardNumberValid = (
  cardNumber: string,
) => validate.isValid(removeWhitespaces(cardNumber));

export const isSecurityCodeValid = (
  cvv: string, number: string,
) => {
  if (!number || !cvv) {
    return false;
  }
  return validate.isSecurityCodeValid(removeWhitespaces(number), cvv);
};

export const isSecurityCodeValidByCard = (
  cvv: string, fields: {
    owner: string,
    number: string,
    expirationDate: string,
    cvv: string,
  },
) => isSecurityCodeValid(cvv, fields.number);

export const isExpirationDateValid = (date: string) => {
  if (!date) {
    return false;
  }

  const month = date.split('/')[0];
  let year = date.split('/')[1];

  if (!month || !year) {
    return false;
  }

  if (year.length === 2) {
    year = `20${year}`;
  }

  if (parseInt(year, 10) < new Date().getFullYear()) {
    return false;
  }

  return validate.isExpirationDateValid(month, year);
};

export const getCardImagePath = (cardNumberString: string) => {
  const defaultImage = 'card';
  if (cardNumberString) {
    const cardName = getCreditCardNameByNumber(removeWhitespaces(cardNumberString));
    return cardName ? cardName.toLowerCase() : defaultImage;
  }
  return defaultImage;
};

export const getCardImage = (number: string, path = 'cards') => {
  try {
    return image(`${path}.${getCardImagePath(number)}`);
  } catch (err) {
    captureException(err);
    return image(`${path}.card`);
  }
};

export const getBoletoImage = () => image('boleto.logo');

export const getCardImageFlag = (flag = 'card', path = 'cards') => {
  if (!CREDIT_CARD_AVAILABLE.includes(flag)) {
    return image(`${path}.card`);
  }
  return image(`${path}.${flag}`);
};
export const isNameValid = (value: string) => (!/[0-9]|[^\w\s]/g.test(value));
