// External
import React from 'react';
import Icon from '@material/react-material-icon';

// Internal
import classnames from 'classnames/bind';
import styles from './SideMenu.scss';

const cx = classnames.bind(styles);

type SideButtonProps = {
  classes?: string;
  active?: boolean;
  onClick?: () => void;
  icon: string;
  label: string;
  idGa?: string,
};

export default function SideButton(
  {
    classes = '',
    active = false,
    idGa = '',
    onClick = () => {},
    icon,
    label,
  }: SideButtonProps,
): JSX.Element {
  return (
    <button
      onClick={onClick}
      type="button"
      data-ga={idGa}
      className={cx('button', classes, { '-active': active })}
    >
      <span className={styles.description}>
        {label}
      </span>
      <Icon
        className={styles.icon}
        icon={icon}
      />
    </button>
  );
}
