import { MaterialUICore, tokens, Body as DSBody } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const RelatedContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  padding: tokens.spacing.base,
  backgroundColor: tokens.color.neutral[0],
  borderRadius: tokens.border.radius.sm,
  border: `${tokens.border.width.hairline} solid ${tokens.color.neutral[50]}`,
  top: 0,
  zIndex: 10,
  maxWidth: '500px',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    right: 0,
    left: 0,
    margin: '0 auto',
  },
  [theme.breakpoints.down('xs')]: {
    maxWidth: '100vw',
    right: -20,
    left: -20,
  },
}));

export const Body = styled(DSBody)({
  marginBottom: tokens.spacing.nano,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});
