import { combineReducers } from 'redux';

import infos, {
  CheckoutInfosState,
  initialState as initialStateInfos,
} from './infos/reducer';
import payment, {
  CheckoutPaymentState,
  initialState as initialStatePayment,
} from './payment/reducer';
import successPayment, {
  SuccessPaymentState,
  initialState as initialStateSuccess,
} from './success/reducer';

export type CheckoutState = {
  infos: CheckoutInfosState;
  payment: CheckoutPaymentState;
  successPayment: SuccessPaymentState;
};

export const checkoutInitalState = {
  infos: initialStateInfos,
  payment: initialStatePayment,
  successPayment: initialStateSuccess,
};

const checkoutReducer = combineReducers<CheckoutState>({
  infos,
  payment,
  successPayment,
});

export default checkoutReducer;
