import { FetchListCBORequest } from './types';

export enum SignupStepsOrder {
  Email = 1,
  Account = 2,
  Password = 3,
}

export const termsErrorMsg =
  'Você precisa aceitar os termos acima para continuar';

type ErrorMessageTuple = [string, string, boolean];

export const defaultErrorCode = 'UnknownError';
export const defaultErrorMessage =
  'Não foi possível continuar o cadastro no momento. Tente novamente mais tarde.';

export const defaultEmailError: ErrorMessageTuple = [
  defaultErrorCode,
  defaultErrorMessage,
  false,
];
export const existingEmailCode = '409802';
export const existingEmailMessage = 'O e-mail já possui uma conta iClinic.';
export const invalidEmailMessage = 'O e-mail informado é inválido';

export enum PossibleSignupRoles {
  Doctor = 'Médico',
  Receptionist = 'Recepcionista',
  ClinicAdmin = 'Administrador(a) da clínica',
  BusinessITConsultant = 'Consultor(a) de TI/Negócios',
  AnotherHealthProfessional = 'Outro profissional de saúde',
  Patient = 'Paciente',
}

export const professionFilters = new Map<string, string>([
  [PossibleSignupRoles.Doctor, 'md'],
  [PossibleSignupRoles.AnotherHealthProfessional, 'ot'],
]);

export const emailErrorMessages: ErrorMessageTuple[] = [
  [existingEmailCode, existingEmailMessage, true],
  ['400801', invalidEmailMessage, true],
  defaultEmailError,
];

export const SIGNUP_STORAGE_KEY = '@iclinic/signup-state';
export const SIGNUP_PAYWALL_KEY = '@iclinic/signup-paywall';

export const availableProfessionalsCount = [
  {
    title: '1',
    value: '1',
  },
  {
    title: '2 a 4',
    value: '2 a 4',
  },
  {
    title: '5 a 10',
    value: '5 a 10',
  },
  {
    title: '11 a 20',
    value: '11 a 20',
  },
  {
    title: 'Mais que 20',
    value: 'Mais que 20',
  },
];

export interface AccountFormValues {
  name: string;
  billing_phone: string;
  profession: string;
  cbo: string;
  email: string;
  cbosList?: FetchListCBORequest;
  professionals_count: string;
}

export interface PasswordFormValues {
  password: string;
}

export const successMessage =
  'Seja bem-vindo(a) ao iClinic! Aguarde enquanto preparamos sua clínica.';

export enum RecaptchaEvents {
  Email = 'submit_signup_email',
  Account = 'submit_signup_account',
  Password = 'submit_signup_password',
}

export enum SocialProviders {
  Google = 'google',
}

export const singupBenefits = [
  'Prontuário eletrônico',
  'Agenda médica e agendamento online',
  'Gestão financeira',
  'Prescrição digital',
  'Marketing médico',
];
