import { PromiseEnvelope } from '@types';

import { apiTelemedicine } from '../api';
import {
  FetchPhysicianClinicData,
  FetchPhysicianClinicRawData,
  FetchPhysicianInfoData,
  FetchPhysicianInfoRawData,
} from '../types';
import { decodePromiseEnvelope } from '../utils';

export const convertPhysicianClinic = ({
  id,
  name,
  phone_numbers: phoneNumbers,
  cep,
  street,
  street_number: streetNumber,
  complement,
  city,
  state,
  neighborhood,
}: FetchPhysicianClinicRawData): FetchPhysicianClinicData => ({
  id,
  name,
  phoneNumbers,
  cep,
  street,
  streetNumber,
  complement,
  city,
  state,
  neighborhood,
});

export const fetchPhysicianInfo = (): PromiseEnvelope<FetchPhysicianInfoData> =>
  decodePromiseEnvelope(
    apiTelemedicine.get(
      'conference/physician-info',
    ) as PromiseEnvelope<FetchPhysicianInfoRawData>,
    ({
      id,
      name,
      mobile_phone: profileMobilePhone,
      council_name: councilName,
      council_number: councilNumber,
      speciality: specialtyName,
      cbo__term: cboTerm,
      photo,
      clinicId,
      clinics,
      email,
    }: FetchPhysicianInfoRawData): FetchPhysicianInfoData => ({
      physician: {
        id,
        name,
        email,
        councilName,
        councilNumber,
        specialtyName,
        cboTerm,
        profileMobilePhone,
        photo,
        clinics: clinics.map(convertPhysicianClinic),
      },
      clinicId,
    }),
  );
