import * as Yup from 'yup';

import { ChargeBackForm } from 'features/onlinePayment/state/manage/types';

export const validationSchema = Yup.object().shape({
  chargeBackAmount: Yup.string().required(
    'O valor do estorno tem que ser igual ou menor ao valor cobrado.',
  ),
});

export const initialValues: ChargeBackForm = {
  chargeBackAmount: '',
};
