import React from 'react';
import ClassNames from 'classnames/bind';

import style from './style.scss';

const classes = ClassNames.bind(style);

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  color?: 'white' | 'grey' | 'darkgrey';
}

export default function Loader({ color = 'grey', className, ...props }: Props) {
  return (
    <div
      className={classes(style['box-loader'], className, {
        '-white': color === 'white',
        '-grey': color === 'grey',
        '-darkgrey': color === 'darkgrey',
      })}
      {...props}
    >
      <div className={style.loader} />
    </div>
  );
}
