import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

import { SpaceBetweenBox } from 'features/tissInvoicing/components/ui/Flexbox';

const { styled, makeStyles } = MaterialUICore;
const { spacing, color } = axiomTokens;
export const Container = styled(SpaceBetweenBox)({
  width: '100%',
});

export const Actions = styled(SpaceBetweenBox)({
  columnGap: spacing['4'],
});

export const useStyles = makeStyles(() => ({
  cancelButton: {
    color: color.typeface.pure,
  },
}));
