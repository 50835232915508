import { createSelector } from 'reselect';

import { FetchState } from '@types';
import { StoreState } from 'state/rootReducer';
import { Physician, Clinic, Event, DecodedToken, Bill } from './types';
import { CHECKOUT_FETCH_ERROR } from '../errorMessages';

export const getPhysician = ({ onlinePayment }: StoreState): Physician | null =>
  onlinePayment.checkout.infos.physician;

export const getClinic = ({ onlinePayment }: StoreState): Clinic | null =>
  onlinePayment.checkout.infos.clinic;

export const getEvent = ({ onlinePayment }: StoreState): Event | null =>
  onlinePayment.checkout.infos.event;

export const getTokenData = ({
  onlinePayment,
}: StoreState): DecodedToken | null => onlinePayment.checkout.infos.tokenData;

export const getBill = ({ onlinePayment }: StoreState): Bill | null =>
  onlinePayment.checkout.infos.bill;

export const getFetchPhysicianInfos = ({
  onlinePayment,
}: StoreState): FetchState => onlinePayment.checkout.infos.fetchPhysicianInfos;

export const getFetchEventInfos = ({ onlinePayment }: StoreState): FetchState =>
  onlinePayment.checkout.infos.fetchEventInfos;

export const getFetchBillInfos = ({ onlinePayment }: StoreState): FetchState =>
  onlinePayment.checkout.infos.fetchBillInfos;

export const getPaymentType = ({ onlinePayment }: StoreState): string | null =>
  onlinePayment.checkout.infos.paymentType;

export const getIsPixTimerRunning = ({ onlinePayment }: StoreState): boolean =>
  onlinePayment.checkout.infos.isPixTimerRunning;

export const getFetchError = createSelector(
  [getFetchBillInfos, getFetchPhysicianInfos, getFetchEventInfos],
  (fetchBillInfos, fetchPhysicianInfos, fetchEventInfos) =>
    fetchBillInfos.errors?.[0].message ||
    fetchPhysicianInfos.errors?.[0].message ||
    fetchEventInfos.errors?.[0].message
      ? CHECKOUT_FETCH_ERROR
      : null,
);
