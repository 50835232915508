import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { captureException } from '@sentry/browser';

import { actions } from '.';
import { getPhysicianId, getTransactionDrawerState } from './selector';
import { getVideoTranscription } from './services';
import { TranscriptionDrawerState } from './types';

const {
  openTranscriptionDrawer,
  setTranscription,
  getMoreTranscription,
  setEndOfTranscription,
  setError,
} = actions;

export function* workerGetTranscription() {
  const { currentPage, conferenceId }: TranscriptionDrawerState = yield select(
    getTransactionDrawerState,
  );
  const physicianId: number = yield select(getPhysicianId);

  try {
    const { data: response } = yield call(
      getVideoTranscription,
      conferenceId,
      physicianId,
      currentPage + 1,
    );

    if (response.data.length === 0) {
      yield put(setEndOfTranscription());
      return;
    }

    yield put(setTranscription(response.data));
  } catch (error) {
    captureException(error);
    yield put(setError());
  }
}

export default function* transcriptionDrawerSagas() {
  yield all([
    takeLatest(openTranscriptionDrawer, workerGetTranscription),
    takeLatest(getMoreTranscription, workerGetTranscription),
  ]);
}
