import { ApiErrors } from '@types';

import {
  StockState,
  StockProduct,
  Inventory,
  ProductDetail,
  SelectedLot,
  ProductCreate,
  Unit,
  Stock,
  ProductUpdate,
  InventoryCreate,
  LotChange,
  User,
  InventoryHistory,
} from 'features/stock/state/types';
import { StockTabs, Features } from 'features/stock/constants';
import * as types from './constants';

export const fetchFailure = (errors: ApiErrors[]) =>
  ({
    type: types.FETCH_FAILURE,
    payload: {
      isFetching: false,
      errors,
    },
  } as const);

type FetchProductsByNameParams = {
  description?: string;
  page_size?: number;
  order_by?: string;
};

export const fetchProductsByName = (params?: FetchProductsByNameParams) =>
  ({
    type: types.FETCH_PRODUCTS_BY_NAME_ACTION,
    payload: {
      isFetching: true,
    },
    extra: {
      params,
    },
  } as const);

export const fetchProductsByNameSuccess = (products: StockProduct[]) =>
  ({
    type: types.FETCH_PRODUCTS_BY_NAME_SUCCESS,
    payload: {
      isFetching: false,
      products,
    },
  } as const);

export const fetchInventoriesByProductId = (
  productId: number,
  pageSize?: number,
  url?: string,
) =>
  ({
    type: types.FETCH_INVENTORIES_BY_PRODUCT_ID_ACTION,
    payload: {
      isFetching: true,
    },
    extra: {
      productId,
      pageSize,
      url,
    },
  } as const);

export const fetchInventoriesByProductIdSuccess = (inventories: Inventory[]) =>
  ({
    type: types.FETCH_INVENTORIES_BY_PRODUCT_ID_SUCCESS,
    payload: {
      isFetching: false,
      inventories,
    },
  } as const);

export const fetchDetail = (productId: number | null) =>
  ({
    type: types.FETCH_DETAIL_ACTION,
    payload: {
      isFetching: true,
    },
    extra: {
      productId,
    },
  } as const);

export const fetchDetailSuccess = (product: ProductDetail) =>
  ({
    type: types.FETCH_DETAIL_SUCCESS,
    payload: {
      isFetching: false,
      selectedProduct: product,
    },
  } as const);

interface CreateLotParams {
  description: string;
}
export const createLot = (params: CreateLotParams) =>
  ({
    type: types.CREATE_LOT_ACTION,
    payload: {
      isFetching: true,
    },
    extra: params,
  } as const);

export const createLotSuccess = (lot: SelectedLot) =>
  ({
    type: types.CREATE_LOT_SUCCESS,
    payload: {
      isFetching: false,
      lot,
    },
  } as const);

export const createProduct = (product: ProductCreate) =>
  ({
    type: types.CREATE_PRODUCT_ACTION,
    payload: {
      isFetching: true,
      errors: [],
    },
    extra: {
      product,
    },
  } as const);

export const updateProduct = (product: ProductUpdate) =>
  ({
    type: types.UPDATE_PRODUCT_ACTION,
    payload: {
      isFetching: true,
    },
    extra: {
      product,
    },
  } as const);

export const deleteProduct = () =>
  ({
    type: types.DELETE_PRODUCT_ACTION,
    payload: {
      isFetching: true,
    },
  } as const);

export const changeModal = (nextModal: StockState['currentModal'] = null) =>
  ({
    type: types.CURRENT_MODAL_ACTION,
    payload: {
      currentModal: nextModal,
    },
  } as const);

type FetchStockUnitPayload = {
  selectedFilter?: StockTabs | null;
  isFetching: boolean;
};

export const fetchStockUnit = (payload: FetchStockUnitPayload, extra?: any) =>
  ({
    type: types.FETCH_STOCK_UNIT_ACTION,
    payload,
    extra,
  } as const);

export const fetchStockUnitSuccess = (
  stocks: Stock[],
  units: Unit[],
  totalCount: number,
  page: number,
) =>
  ({
    type: types.FETCH_STOCK_UNIT_SUCCESS,
    payload: {
      isFetching: false,
      stocks,
      totalCount,
      units,
      page,
    },
  } as const);

export const fetchEspecificStockURL = (
  url: string,
  feature: Features = Features.Stock,
) =>
  ({
    type: types.FETCH_ESPECIFIC_STOCK_URL_ACTION,
    payload: {
      isFetching: true,
    },
    extra: {
      url,
      feature,
    },
  } as const);

export const fetchEspecificStockURLSuccess = (
  stocks: StockProduct,
  totalCount: number,
) =>
  ({
    type: types.FETCH_EXPECIFIC_STOCK_URL_SUCCESS,
    payload: {
      isFetching: false,
      stocks,
      totalCount,
    },
  } as const);

export const fetchProductAndStock = (
  nextModal: StockState['currentModal'],
  productId: number | null,
) =>
  ({
    type: types.FETCH_PRODUCT_STOCK_ACTION,
    payload: {
      isFetching: true,
    },
    extra: {
      nextModal,
      productId,
    },
  } as const);

export const fetchProductAndStockSuccess = (
  nextModal: StockState['currentModal'],
  product: ProductUpdate,
) =>
  ({
    type: types.FETCH_PRODUCT_STOCK_SUCCESS,
    payload: {
      isFetching: false,
      currentModal: nextModal,
      selectedProduct: product,
    },
  } as const);

export const getProfileAndClinic = (profileId: number, clinicId: number) =>
  ({
    type: types.GET_PROFILE_AND_CLINIC_ACTION,
    payload: {
      profileId,
      clinicId,
    },
  } as const);

export const updateSelectedList = (id: number) =>
  ({
    type: types.SELECTED_LIST_ACTION,
    extra: {
      selectedId: id,
    },
  } as const);

export const updateSelectedListSuccess = (updatedList: number[]) =>
  ({
    type: types.SELECTED_LIST_SUCCESS,
    payload: {
      selectedList: [...updatedList],
    },
  } as const);

export const createInventory = (inventory: InventoryCreate) =>
  ({
    type: types.CREATE_INVENTORY_ACTION,
    payload: {
      isFetching: true,
      errors: [],
    },
    extra: {
      inventory,
    },
  } as const);

export const createInventorySuccess = () =>
  ({
    type: types.CREATE_INVENTORY_SUCCESS,
    payload: {
      isFetching: false,
    },
  } as const);

export const deleteSelectedId = () =>
  ({
    type: types.SELECTED_ID_ACTION,
    payload: {
      isFetching: true,
    },
  } as const);

export const changeProductDescription = (productDescription: string | null) =>
  ({
    type: types.CHANGE_PRODUCT_DESCRIPTION_ACTION,
    payload: {
      productDescription,
    },
  } as const);

export const setSelectedProduct = (selectedProduct: StockProduct | null) =>
  ({
    type: types.SET_SELECTED_PRODUCT_ACTION,
    payload: {
      selectedProduct,
      errors: [],
    },
  } as const);

export const changeProduct = (product: StockProduct) =>
  ({
    type: types.CHANGE_PRODUCT_ACTION,
    params: {
      product,
    },
  } as const);

export const changeLotDescription = (lotDescription: string | null) =>
  ({
    type: types.CHANGE_LOT_DESCRIPTION_ACTION,
    payload: {
      lotDescription,
    },
  } as const);

export const setSelectedLot = (selectedLot: LotChange | null) =>
  ({
    type: types.SET_SELECTED_LOT_ACTION,
    payload: {
      selectedLot,
    },
  } as const);

export const changeLot = (lot: LotChange) =>
  ({
    type: types.CHANGE_LOT_ACTION,
    params: {
      lot,
    },
  } as const);

export const clearModal = () =>
  ({
    type: types.CLEAR_MODAL_ACTION,
    payload: {
      productDescription: '',
      selectedProduct: null,
      lotDescription: '',
      selectedLot: null,
      errors: [],
      lot: null,
    },
  } as const);

export const viewLotsByProductId = (productId: number) =>
  ({
    type: types.VIEW_LOTS_BY_PRODUCT_ID_ACTION,
    params: {
      productId,
    },
  } as const);

export const changeOpenedProductId = (openedProductId: number | null = null) =>
  ({
    type: types.OPENED_PRODUCT_ID_ACTION,
    payload: {
      openedProductId,
    },
  } as const);

export const fetchStockByProductId = (productId: number) =>
  ({
    type: types.FETCH_STOCK_BY_PRODUCT_ID_ACTION,
    payload: {
      isFetching: true,
    },
    extra: {
      productId,
    },
  } as const);

export const fetchStockByProductIdSuccess = (productStock: Stock) =>
  ({
    type: types.FETCH_STOCK_BY_PRODUCT_ID_SUCCESS,
    payload: {
      isFetching: false,
      productStock,
    },
  } as const);

export const fetchStockFilter = (
  filter: StockTabs,
  search: string | null = null,
) =>
  ({
    type: types.FETCH_STOCK_FILTER_ACTION,
    payload: {
      selectedFilter: filter,
    },
    extra: {
      search,
    },
  } as const);

export const selectAllProducts = () =>
  ({
    type: types.SELECT_ALL_PRODUCTS_ACTION,
  } as const);

export const selectAllProductsSuccess = (
  list: number[],
  totalSelected: number | null,
) =>
  ({
    type: types.SELECT_ALL_PRODUCTS_SUCCESS,
    payload: {
      selectedList: list,
      totalSelected,
    },
  } as const);

export const fetchInventoriesHistory = (name = 'period', value = 'all') =>
  ({
    type: types.FETCH_INVENTORIES_HISTORY_ACTION,
    payload: {
      name,
      value,
    },
  } as const);

export const fetchInventoriesHistorySuccess = (
  inventoriesHistory: InventoryHistory[],
  totalCount?: number,
) =>
  ({
    type: types.FETCH_INVENTORIES_HISTORY_SUCCESS,
    payload: {
      inventoriesHistory,
      totalCount,
    },
  } as const);

export const selectedPageNumber = (page = 1) =>
  ({
    type: types.SELECTED_PAGE_ACTION,
    payload: {
      page,
    },
  } as const);

export const fetchUserByName = (userName: string | null = null) =>
  ({
    type: types.FETCH_USER_ACTION,
    payload: {
      isFetching: true,
    },
    extra: {
      userName,
    },
  } as const);

export const fetchUserByNameSuccess = (users: User[]) =>
  ({
    type: types.FETCH_USER_SUCCESS,
    payload: {
      isFetching: false,
      users,
    },
  } as const);

export const setHistory = (params: object) =>
  ({
    type: types.SET_HISTORY_ACTION,
    payload: {
      history: {
        ...params,
      },
    },
  } as const);
