import React from 'react';
import PropTypes from 'prop-types';

import { productViews } from 'shared/constants/views';
import style from './ViewInfo.scss';

const ViewInfo = ({ slug }) => (
  <div className={style['view-info']}>
    <h2 className={style.title}>{productViews[slug].title}</h2>
    <div className={style.description}>{productViews[slug].text}</div>
    {productViews[slug].youtubeUrl ? (
      <iframe
        title={`video-${productViews[slug].title}`}
        width="512"
        height="288"
        src={`https://www.youtube.com/embed/${productViews[slug].youtubeUrl}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    ) : (
      <img
        className={style.image}
        src={productViews[slug].imageUrl}
        alt={productViews[slug].alt}
      />
    )}
  </div>
);

ViewInfo.propTypes = {
  slug: PropTypes.string.isRequired,
};

export default ViewInfo;
