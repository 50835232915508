import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { trackRequestSamplesFormStep } from '../trackRequestSamplesFormUtils';
import {
  AutoFillMapData,
  FillFieldData,
  RequestSamplesFormData,
} from '../containers/RequestSamplesForm/types';
import { UserInfo } from '../state/request-samples/types';
import { snowplowStepsMapByFields } from '../constants';

export const useAutoFillFields = (
  userInfo: UserInfo,
  data: AutoFillMapData,
) => {
  const { setFieldValue } = useFormikContext<RequestSamplesFormData>();

  useEffect(() => {
    const fillField = ({ value, fieldName, step }: FillFieldData) => {
      setFieldValue(fieldName, value, false);
      trackRequestSamplesFormStep({
        userInfo,
        stepName: `user_${fieldName}`,
        stepNum: step,
        automatic: true,
      });
    };

    Object.entries(data).forEach(([fieldName, value]) => {
      if (value) {
        fillField({
          fieldName,
          value,
          step: snowplowStepsMapByFields[fieldName],
        });
      }
    });
  }, [setFieldValue, userInfo, data]);
};
