import { Grid, MaterialIcons, MaterialUICore } from '@iclinic/design-system';
import { useFormikContext } from 'formik';
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import DatePickerSADT from 'features/tissInvoicing/components/sadt/DatePickerSADT';
import SelectFieldSADT from 'features/tissInvoicing/components/sadt/SelectFieldSADT';
import TextFieldSADT from 'features/tissInvoicing/components/sadt/TextFieldSADT';
import CollapsibleFormSection from 'features/tissInvoicing/components/ui/CollapsibleFormSection';
import useVersionCompare from 'features/tissInvoicing/hooks/useVersionCompare';
import { getDomainTablesOptions } from 'features/tissInvoicing/state/domainTables/selectors';

const { MenuItem } = MaterialUICore;
const { PlaylistAddCheck } = MaterialIcons;

interface GeneralSectionProps {}

const GeneralSection = (
  _: GeneralSectionProps,
  ref: React.Ref<HTMLButtonElement>,
): JSX.Element => {
  const {
    appointmentTypes,
    serviceTypes,
    terminationReasons,
    accidentIndications,
    participationDegrees,
    attendanceRegimens,
    occupationalHealthList,
  } = useSelector(getDomainTablesOptions);

  const { values } = useFormikContext();

  const { isGreaterThan } = useVersionCompare(values.version_id);
  const versionTissIsGreaterThan3 = isGreaterThan(3);

  return (
    <CollapsibleFormSection
      sectionTitle="Dados gerais"
      sectionIcon={<PlaylistAddCheck />}
      sectionIdentifier="general_data"
      ref={ref}
    >
      <Grid container item spacing={2}>
        <Grid item xs={12} md={4}>
          <TextFieldSADT
            label="2 - Número da guia do prestador"
            identifier="guide_number"
            maxLength={20}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldSADT
            label="3 - Nº da guia principal"
            identifier="main_guide_number"
            maxLength={20}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePickerSADT
            label="4 - Data de autorização"
            identifier="authorization_date"
          />
        </Grid>
      </Grid>

      <Grid container item spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextFieldSADT
            label="5 - Senha"
            identifier="authorization_number"
            maxLength={20}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <DatePickerSADT
            label="6 - Data de validade"
            identifier="authorization_expiration_date"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextFieldSADT
            label="7 - Nº da guia atribuido na operadora"
            identifier="operator_guide_number"
            maxLength={20}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <SelectFieldSADT
            label="49 - Grau de Participação"
            identifier="degree_participation"
          >
            {participationDegrees.map((participationDegree) => (
              <MenuItem
                value={participationDegree.id}
                key={participationDegree.id}
              >
                {participationDegree.term}
              </MenuItem>
            ))}
          </SelectFieldSADT>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3}>
          <SelectFieldSADT
            label="32 - Tipo de atendimento"
            identifier="service_type"
          >
            {serviceTypes.map((serviceType) => (
              <MenuItem value={serviceType.id} key={serviceType.id}>
                {serviceType.term}
              </MenuItem>
            ))}
          </SelectFieldSADT>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <SelectFieldSADT
            label="33 - Indicação de acidente"
            identifier="accident_indication"
          >
            {accidentIndications.map((accidentIndication) => (
              <MenuItem
                value={accidentIndication.id}
                key={accidentIndication.id}
              >
                {accidentIndication.term}
              </MenuItem>
            ))}
          </SelectFieldSADT>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <SelectFieldSADT
            label="34 - Tipo de consulta"
            identifier="appointment_type"
          >
            {appointmentTypes.map((appointmentType) => (
              <MenuItem value={appointmentType.id} key={appointmentType.id}>
                {appointmentType.term}
              </MenuItem>
            ))}
          </SelectFieldSADT>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <SelectFieldSADT
            label="35 - Motivo de encerramento"
            identifier="reason_termination"
          >
            {terminationReasons.map((terminationReason) => (
              <MenuItem value={terminationReason.id} key={terminationReason.id}>
                {terminationReason.term}
              </MenuItem>
            ))}
          </SelectFieldSADT>
        </Grid>
      </Grid>

      {versionTissIsGreaterThan3 && (
        <Grid container item spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <SelectFieldSADT
              label="91 - Regime de atendimento"
              identifier="attendance_regimen"
            >
              {attendanceRegimens.map((attendanceRegimen) => (
                <MenuItem
                  value={attendanceRegimen.id === 0 ? '' : attendanceRegimen.id}
                  key={attendanceRegimen.id}
                >
                  {attendanceRegimen.term}
                </MenuItem>
              ))}
            </SelectFieldSADT>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <SelectFieldSADT
              label="82 - Saúde ocupacional"
              identifier="occupational_health"
            >
              {occupationalHealthList.map((occupationalHealth) => (
                <MenuItem
                  value={
                    occupationalHealth.id === 0 ? '' : occupationalHealth.id
                  }
                  key={occupationalHealth.id}
                >
                  {occupationalHealth.term}
                </MenuItem>
              ))}
            </SelectFieldSADT>
          </Grid>
        </Grid>
      )}

      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <TextFieldSADT
            label="58 - Observação/Justificativa"
            identifier="observation"
            maxLength={150}
          />
        </Grid>
      </Grid>
    </CollapsibleFormSection>
  );
};

export default forwardRef(GeneralSection);
