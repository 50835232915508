import React from 'react';

import * as S from './styles';
import {
  FooterProps,
  CustomButtonProps,
} from 'features/newFinance/types/types';

export default ({
  handleCancel = () => null,
  handleConfirm = () => null,
  cancelTitle = 'Cancelar',
  confirmTitle = 'Confirmar',
  customActions = [],
  disabled = {},
}: FooterProps) => {
  const paymentDetailsButtonList: CustomButtonProps[] = [
    {
      title: cancelTitle,
      icon: null,
      onClick: handleCancel,
      variant: 'text',
      isDisabled: disabled?.cancel || false,
    },
    {
      title: confirmTitle,
      icon: null,
      onClick: handleConfirm,
      variant: 'contained',
      isDisabled: disabled?.confirm || false,
    },
  ];

  return (
    <S.Footer>
      <S.FooterActionsLine>
        {(
          (customActions.length && customActions) ||
          paymentDetailsButtonList
        ).map(({ title, icon, onClick, variant, isDisabled }) => (
          <S.ActionsButton
            key={title}
            variant={variant}
            onClick={onClick}
            startIcon={icon}
            disabled={isDisabled}
          >
            {title}
          </S.ActionsButton>
        ))}
      </S.FooterActionsLine>
    </S.Footer>
  );
};
