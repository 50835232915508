import React from 'react';
import { Loader, Heading } from '@iclinic/design-system';

import * as S from './styles';

type LoadingOrNotInfoProps = {
  loading: boolean;
  notInfo: string;
  titleLoading: string;
};

export default function LoadingOrNotInfo({
  loading,
  titleLoading,
  notInfo,
}: LoadingOrNotInfoProps) {
  if (loading) {
    return (
      <S.LoaderContainer>
        <Loader size={20} title={titleLoading} />
      </S.LoaderContainer>
    );
  }

  return (
    <Heading variant="md" align="center">
      {notInfo}
    </Heading>
  );
}
