import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';

import { useConference } from 'features/video-conference/state/conferenceContext';
import { useCaptionContext } from 'features/video-conference/state/captionContext';

export default function useParticipants() {
  const { room } = useConference();
  const { setIsRemoteCaptionActive } = useCaptionContext();
  const [participants, setParticipants] = useState(
    Array.from(room?.participants.values() ?? []),
  );

  useEffect(() => {
    if (room) {
      const participantConnected = (participant: RemoteParticipant) => {
        setParticipants((prevParticipants) => [
          ...prevParticipants,
          participant,
        ]);
      };
      const participantDisconnected = (participant: RemoteParticipant) => {
        setParticipants((prevParticipants) =>
          prevParticipants.filter((p) => p !== participant),
        );
        setIsRemoteCaptionActive(false);
      };
      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }

    return () => {};
  }, [room, setIsRemoteCaptionActive]);

  return participants;
}
