import React from 'react';
import PropTypes from 'prop-types';

import style from './Card.scss';

const Card = ({ children }) => (
  <div className={style.card}>
    <div id="content" className={style.card__divContent}>
      { children }
    </div>
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Card;
