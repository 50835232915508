import { combineReducers } from 'redux';

import emailFlow, { EmailFlowState } from './emailFlow/reducer';

export type MarketingState = {
  emailFlow: EmailFlowState;
};

const marketingReducer = combineReducers<MarketingState>({
  emailFlow,
});

export default marketingReducer;
