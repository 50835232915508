import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  drawerTitle: {
    padding: theme.spacing(4, 0, 0, 0),
  },
  mainBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'space-between',
    width: '550px',
    maxWidth: '80vw',
  },
  formBox: {
    padding: theme.spacing(0, 4),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '120px',
    maxWidth: '300px',
  },
  optionBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
  },
  fieldName: {
    marginTop: theme.spacing(4),
    color: theme.palette.text.secondary,
  },
  fieldValue: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.primary,
  },
  inlineRadioGroup: {
    flexDirection: 'row',
  },
  inlineRadio: {
    display: 'inline',
    maxWidth: '40%',
    '& span': {
      fontSize: '0.875rem',
    },
  },
  datePicker: {
    width: '200px',
    marginRight: theme.spacing(2),
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  backButton: {
    width: '94px',
    color: theme.palette.text.secondary,
    '& .MuiTypography-overline': {
      fontSize: '16px',
      letterSpacing: '0.44px',
      textTransform: 'none',
    },
    '& .MuiButton-startIcon': {
      color: theme.palette.grey[500],
    },
  },
  disabledButton: {
    color: theme.palette.text.secondary,
  },
  physiciansList: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
