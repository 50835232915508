import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Heading, Body } from '@iclinic/design-system';

import * as S from './InternReferrals.styles';
import {
  Banner,
  FAQ,
  HowItWorks,
  IndicationAwards,
  RangeBanner,
  Received,
  RewardsRedemption,
  RewardsCongratulations,
} from '../components';
import { fetchReferralsParticipant } from '../state';
import {
  howItWorkCards,
  indicationAwards,
  questions,
  title,
} from '../constants';
import { Kind } from '../types';

interface InternReferralsProps {
  kind: Kind;
}

const InternReferrals = ({ kind }: InternReferralsProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchReferralsParticipant());
  }, [dispatch]);

  return (
    <S.Root>
      <RewardsCongratulations />

      <S.HeaderWrapper>
        <Heading variant="lg">{title[kind]}</Heading>
      </S.HeaderWrapper>

      <RewardsRedemption />

      <Banner />

      <RangeBanner />

      <IndicationAwards info={indicationAwards[kind]} />

      <HowItWorks cardsInfo={howItWorkCards[kind]} />

      <Received />

      <FAQ questions={questions[kind]} />

      <S.SectionTerms>
        <Body variant="xs" align="center">
          <strong>Quer saber mais?</strong> Leia os{' '}
          <a
            href="https://suporte.iclinic.com.br/pt-br/termos-e-condicoes-do-programa-de-recompensas-iclinic"
            target="_blank"
            rel="noreferrer"
          >
            Termos e condições
          </a>{' '}
          do Programa de Indicações iClinic
        </Body>
      </S.SectionTerms>
    </S.Root>
  );
};

export default InternReferrals;
