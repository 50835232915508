import { useConference } from 'features/video-conference/state/conferenceContext';
import { useParticipantContext } from 'features/video-conference/state/participantContext';

interface CreateSendLogsProps {
  actionType: string;
  description?: string;
}

export function useHandleSendLogs() {
  const { isPhysician, conferenceId } = useConference();
  const { patientInfo, physicianInfo } = useParticipantContext();

  const createSendLogsBody = ({
    actionType,
    description = '',
  }: CreateSendLogsProps) => {
    return {
      conferenceId,
      clinicId: parseInt(physicianInfo?.clinic!, 10) || 0,
      physicianId: physicianInfo.id,
      patientId: patientInfo.id,
      participantType: isPhysician ? 'Physician' : 'Patient',
      actionType,
      description,
    };
  };

  return {
    createSendLogsBody,
  };
}
