import { GENERIC_ERROR_MESSAGE } from 'shared/constants/errorMessages';
import { captureException } from 'shared/utils/handlerErrors';

type KnownError = { data: { receipt_email: string[] } };

export default function handleErrors(error: KnownError) {
  if (error instanceof Error) {
    captureException(error);
  }

  const receiptEmailError = error.data?.receipt_email?.[0];
  return receiptEmailError || GENERIC_ERROR_MESSAGE;
}
