import React from 'react';

import { image } from 'shared/utils/urlGetter';
import { Box, Button, Alert, MaterialIcons } from '@iclinic/design-system';
import DefaultFeedback from 'features/video-conference/components/TeamComponents/Feedback/index';
import ButtonEndCall from 'features/video-conference/components/Toolbar/Buttons/EndCallButton';
import {
  devicesPermission,
  devicesPermission2,
} from 'features/video-conference/constants';
import useStyles from 'features/video-conference/components/Toolbar/styles';
import { useConference } from 'features/video-conference/state/conferenceContext';

const { ErrorOutlineOutlined } = MaterialIcons;
interface DevicePermissionsProps {
  waitRoom?: boolean;
  message?: string;
}

export default function DevicePermission({
  waitRoom,
  message,
}: DevicePermissionsProps): JSX.Element {
  const { isPhysician } = useConference();
  const classes = useStyles();
  const { imagePath, altText, linkText, linkUrl } = devicesPermission2;
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {waitRoom ? (
        <>
          <img src={image(imagePath)} alt={altText} />
          <Box mt={2}>
            <Alert
              message={message}
              severity="warning"
              icon={<ErrorOutlineOutlined />}
              action={
                <Button
                  href={linkUrl}
                  size="small"
                  color="transparent"
                  target="_blank"
                >
                  {linkText}
                </Button>
              }
            />
          </Box>
        </>
      ) : (
        <>
          <DefaultFeedback
            title={devicesPermission.title}
            message={devicesPermission.message}
            altText={devicesPermission.altText}
            imagePath={devicesPermission.imagePath}
            linkText={devicesPermission.linkText}
            linkUrl={devicesPermission.linkUrl}
          />
          {isPhysician && (
            <Box className={classes.iframeHidden}>
              <ButtonEndCall />
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
