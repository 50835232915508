import {
  axiomTokens,
  Body,
  Grid,
  MaterialUICore,
} from '@iclinic/design-system';

import { FlexColumnBox } from 'features/tissInvoicing/components/ui/Flexbox';

const { styled } = MaterialUICore;
const { spacing, border, color } = axiomTokens;
export const Container = styled(FlexColumnBox)({
  backgroundColor: color.background['1'],
  borderRadius: border.radius.sm,
  paddingTop: 0,
  paddingBottom: 0,
  paddingLeft: spacing['4'],
  paddingRight: spacing['4'],
  marginTop: spacing['4'],
});

export const Header = styled(Grid)({
  alignItems: 'center',
  paddingLeft: spacing['2'],
  paddingRight: spacing['2'],
});

export const SubHeader = styled(Body)({
  marginTop: spacing['2'],
  paddingLeft: spacing['2'],
  fontSize: spacing['4'],
});

export const TrashButton = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
});
