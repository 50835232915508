import { Autocomplete, TextField } from '@iclinic/design-system';
import { useFormikContext } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAutocomplete } from 'features/tissInvoicing/state/autocomplete/selectors';
import { actions as autocompleteActions } from 'features/tissInvoicing/state/autocomplete';
import {
  GuideSADTForm,
  PatientAutocompleteEntry,
} from 'features/tissInvoicing/types';

const renderOption = (option: PatientAutocompleteEntry) => {
  if (typeof option === 'string') return option;

  return option?.civil_name || '';
};

const renderOptionFn = (option: PatientAutocompleteEntry) =>
  `${option?.civil_name}`;

export default ({ label }: { label: string }) => {
  const dispatch = useDispatch();

  const { values, errors, touched, handleBlur, setFieldValue } =
    useFormikContext<GuideSADTForm>();

  const { patients_options } = useSelector(getAutocomplete);

  const handleChange = (value: PatientAutocompleteEntry) => {
    setFieldValue('patient_civil_name', value.civil_name || '');
    setFieldValue('patient_social_name', value?.social_name || '');
    setFieldValue('patient_sus_code', value?.sus_code || '');

    if (value?.id) {
      setFieldValue('patient_id', value.id);
    }
  };

  const handleInputChange = (_: React.ChangeEvent<{}>, value: string) => {
    if (value.length >= 3) {
      dispatch(autocompleteActions.searchPatients({ name: value }));
    }
  };

  const handleOpen = () => {
    dispatch(autocompleteActions.searchPatients({}));
  };

  return (
    <Autocomplete
      id="patient_civil_name"
      freeSolo
      fullWidth
      options={patients_options}
      value={values.patient_civil_name}
      clearOnBlur
      onBlur={handleBlur}
      onChange={(_, value) => handleChange(value as PatientAutocompleteEntry)}
      onInputChange={handleInputChange}
      getOptionLabel={renderOption}
      renderOption={renderOptionFn}
      onOpen={handleOpen}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name="patient_civil_name"
          data-ga="patient_civil_name"
          error={!!(errors.patient_civil_name && touched.patient_civil_name)}
          helperText={touched.patient_civil_name && errors.patient_civil_name}
        />
      )}
    />
  );
};
