import axios from 'axios';
import { PromiseResponse } from '@types';

import { interceptorResponseErrorsPromise } from 'services/iclinic/interceptors';
import { apiTokenInterceptor } from './interceptors';
import {
  AuthToken,
  FetchNotificationsRawData,
  UpdateNotificationsRawData,
  UpdateNotificationsRequestBody,
} from './types';

export const apiAuthNotifications = axios.create({
  baseURL: process.env.ICLINIC_NOTIFICATIONS_URL,
});

export const apiNotifications = axios.create({
  baseURL: process.env.ICLINIC_NOTIFICATIONS_URL,
});

apiAuthNotifications.interceptors.request.use(apiTokenInterceptor);
apiAuthNotifications.interceptors.response.use(
  undefined,
  interceptorResponseErrorsPromise,
);
apiNotifications.interceptors.response.use(
  undefined,
  interceptorResponseErrorsPromise,
);

export const fetchAuthTokenNotifications = (
  service: string,
): PromiseResponse<AuthToken> => apiAuthNotifications.post(`/auth/${service}/`);

export const fetchAntecipateValue = (
  accessToken: string,
): PromiseResponse<void> =>
  apiNotifications.post('/notifications/', {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

export const fetchNotifications = (
  accessToken: string,
): PromiseResponse<FetchNotificationsRawData> =>
  apiNotifications.get('/notifications/', {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

export const fetchNotificationsByChannel = (
  channel: string,
  offset: number,
  accessToken: string,
): PromiseResponse<FetchNotificationsRawData> =>
  apiNotifications.get(
    `/notifications/?channel=${channel}&limit=10&offset=${offset}`,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );

export const updateNotifications = (
  accessToken: string,
  userId: string,
  data: UpdateNotificationsRequestBody,
): PromiseResponse<UpdateNotificationsRawData> =>
  apiNotifications.patch(`/users/${userId}/`, data, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
