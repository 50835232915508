import { IStoreState } from 'state/rootReducer';
import { UserInfo, SearchDbMedications, HighlightsInfoType } from '../types';

export const getUserInfo = ({ userInfo }: IStoreState): UserInfo => ({
  profileId: userInfo.profileId,
  subscriptionId: userInfo.userData.subscriptionId,
  isSubscriber: userInfo.userData.isSubscriber,
  clinicId: userInfo.clinicId,
});

export const getIsModalHighlightsInfoOpen = (state: IStoreState): boolean =>
  state.records.highlightsInfo.isModalHighlightsInfoOpen;

export const getHighlightInfoSelected = (
  state: IStoreState,
): HighlightsInfoType => state.records.highlightsInfo.highlightInfoSelected!;

export const getHighlightsInfoList = (
  state: IStoreState,
): HighlightsInfoType[] => state.records.highlightsInfo.highlightInfos;

export const getHighlightInfoIsError = (state: IStoreState): boolean =>
  state.records.highlightsInfo.isGetHighlightError;

export const getHighlightInfoLoading = (state: IStoreState): boolean =>
  state.records.highlightsInfo.isGetHighlightLoading;

export const getHighlightInfoShouldRefreshHighlightInfo = (
  state: IStoreState,
): boolean => state.records.highlightsInfo.isShouldRefreshHighlightInfo;

export const getPhysicianAndClinicId = ({ userInfo }: IStoreState) => ({
  physicianId: userInfo.userData.physicianId,
  clinicId: userInfo.clinicId,
});

export const searchDbHighlightInfoLoading = (state: IStoreState): boolean =>
  state.records.highlightsInfo.isSearchDbLoading;

export const searchDbHighlightInfo = (
  state: IStoreState,
): SearchDbMedications[] => state.records.highlightsInfo.searchDb;
