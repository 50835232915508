import { StoreState } from 'state/rootReducer';

export const getCanEdit = ({ editPaymentDate }: StoreState) =>
  editPaymentDate.canEdit;

export const getNotMonthly = ({ editPaymentDate }: StoreState) =>
  editPaymentDate.notMonthly;

export const getDialogOpened = ({ editPaymentDate }: StoreState) =>
  editPaymentDate.dialogOpened;

export const getHasDebit = ({ editPaymentDate }: StoreState) =>
  editPaymentDate.hasDebit;

export const getPaymentDay = ({ editPaymentDate }: StoreState) =>
  editPaymentDate.billingInfo.paymentDay;

export const getStatus = ({ editPaymentDate }: StoreState) =>
  editPaymentDate.status;

export const getStep = ({ editPaymentDate }: StoreState) =>
  editPaymentDate.step;

export const getAlreadyChanged = ({ editPaymentDate }: StoreState) =>
  editPaymentDate.alreadyChanged;

export const getDaysUntilChange = ({ editPaymentDate }: StoreState) =>
  editPaymentDate.billingInfo.daysUntilNextChange;

export const getLastChangeDay = ({ editPaymentDate }: StoreState) =>
  editPaymentDate.billingInfo.lastChangeDay;
