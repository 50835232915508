/**
 * It applies String.trim to each string value in the given object
 *
 * @param obj T
 * @returns T
 */
// eslint-disable-next-line import/prefer-default-export
export function trimObject<T extends Record<string, any>>(obj: T): T {
  return Object.keys(obj).reduce((outputObj, key) => {
    // eslint-disable-next-line no-param-reassign
    outputObj[key] = typeof obj[key] === 'string' ? obj[key].trim() : obj[key];
    return outputObj;
  }, {} as Record<string, any>) as T;
}
