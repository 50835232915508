// External
import React from 'react';

// Internal
import styles from './Canvas.scss';

type CanvasProps = {
  canvasRef: React.MutableRefObject<null>;
};

export default function Canvas({
  canvasRef,
}: CanvasProps): JSX.Element {
  return (
    <canvas ref={canvasRef} width={1100} height={1100} className={styles['canvas-component']} />
  );
}
