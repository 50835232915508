// External
import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

// Internal
import { debounce } from 'shared/hooks';
import {
  getInventoryKind,
  getInventoryKindName,
  getInventoryMessage,
  getFilterParamsUrl,
} from 'features/stock/state/selectors';
import { Radio, Input } from 'ui/new/form';
import Dropdown from 'ui/new/dropdown';
import Button from 'ui/new/button';
import { SIZE } from 'ui/new/button/constants';
import { FULL_DATE } from 'shared/constants/masks';
import Paginator from 'ui/new/paginator';
import SearchBar from 'ui/new/search';
import Table from 'ui/new/table';
import { formatDate, BRAZIL, USA } from 'shared/utils/date';
import { isValidFullDate } from 'shared/utils/validation';
import { Row, Column } from 'ui/new/flex';
import { StoreState } from 'state/rootReducer';
import * as actions from '../state/actions';
import Field from '../components/Field';
import Header from '../components/Header';
import {
  MIN_CHARS_TO_SEARCH,
  LAST_INVENTORY,
  PAGE_SIZE,
  INITIAL_PAGE,
  StockKind,
  Features,
} from '../constants';

import style from './History.scss';

const cx = classnames.bind(style);
const tableColumn = 'table-column';

const mapStateToProps = (state: StoreState) => ({
  inventoriesHistory: state.stock.inventoriesHistory,
  totalCount: state.stock.totalCount,
  users: state.stock.users,
  products: state.stock.products,
  page: state.stock.page,
  kind: getInventoryKind(state),
  currentKind: getInventoryKindName(state),
  message: getInventoryMessage(state),
  inventoriesParams: getFilterParamsUrl(state),
});

const mapDispatchToProps = actions;

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type MapDispatchToProps = typeof mapDispatchToProps;

type HistoryProps = MapStateToProps & MapDispatchToProps;

// eslint-disable-next-line sonarjs/cognitive-complexity
function History({
  // store
  inventoriesHistory,
  totalCount,
  users,
  products,
  currentKind,
  page,
  // selector
  kind,
  message,
  inventoriesParams,
  // actions
  fetchInventoriesHistory,
  fetchEspecificStockURL,
  fetchUserByName,
  fetchProductsByName,
  setHistory,
  selectedPageNumber,
}: HistoryProps): JSX.Element {
  const [isOpenToggleUser, setIsOpenToggleUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [isOpenToggleProduct, setIsOpenToggleProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [isOpenTogglePeriod, setIsOpenTogglePeriod] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('');

  const [startDate, setStartDate] = useState('');
  const [startDateError, setStartDateError] = useState(false);
  const [endDate, setEndDate] = useState('');
  const [endDateError, setEndDateError] = useState(false);

  useEffect(() => {
    fetchInventoriesHistory('period', 'all');
    fetchUserByName();
  }, [fetchInventoriesHistory, fetchUserByName]);

  const handleItemClickUser = useCallback(
    (id, name) => {
      fetchInventoriesHistory('addedBy', id);
      setSelectedUser(name);
      setIsOpenToggleUser(false);
    },
    [fetchInventoriesHistory],
  );

  const handleItemClickPeriod = useCallback(
    (period, name) => {
      setHistory({ dateRange: null });
      fetchInventoriesHistory('period', period);
      setSelectedPeriod(name);
      setIsOpenTogglePeriod(false);
    },
    [fetchInventoriesHistory, setHistory],
  );

  const handleItemClickProduct = useCallback(
    (id, name) => {
      fetchInventoriesHistory('productId', id);
      setSelectedProduct(name);
      setIsOpenToggleProduct(false);
    },
    [fetchInventoriesHistory],
  );

  const handleCustomDateClick = () => {
    const start = formatDate(startDate, BRAZIL, USA);
    const end = formatDate(endDate, BRAZIL, USA);

    fetchInventoriesHistory('dateRange', `${start}T00:00:00,${end}T23:59:59`);
    setIsOpenTogglePeriod(false);
    setSelectedPeriod(`Período de ${startDate} até ${endDate}`);
    setStartDate('');
    setEndDate('');
  };

  const handleSelectedPage = (pageNumber: number) => {
    selectedPageNumber(pageNumber);

    if (pageNumber) {
      return fetchEspecificStockURL(
        `/inventories/?${inventoriesParams}&page=${pageNumber}&page_size=${PAGE_SIZE}`,
        Features.InventoriesHistory,
      );
    }

    return fetchEspecificStockURL(
      `/inventories/?${inventoriesParams}&page_size=${PAGE_SIZE}`,
      Features.InventoriesHistory,
    );
  };

  useEffect(() => {
    fetchProductsByName({
      order_by: LAST_INVENTORY,
      page_size: 5,
    });
  }, [fetchProductsByName]);

  const handleFetchUserSearch = debounce((value: string = '') => {
    fetchUserByName(value);
  }, 500);

  const handleFetchProductSearch = debounce((value: string = '') => {
    fetchProductsByName({ description: value });
  }, 500);

  const handleSearchUser = useCallback(
    ({ target: { value } }) => {
      if (value.length >= MIN_CHARS_TO_SEARCH || value.length === 0) {
        handleFetchUserSearch(value);
      }
    },
    [handleFetchUserSearch],
  );

  const handleSearchProduct = useCallback(
    ({ target: { value } }) => {
      if (value.length >= MIN_CHARS_TO_SEARCH || value.length === 0) {
        handleFetchProductSearch(value);
      }
    },
    [handleFetchProductSearch],
  );

  const handleSetOpenProduct = useCallback(
    (value) => {
      setIsOpenToggleProduct(value);
      fetchProductsByName();
    },
    [fetchProductsByName],
  );

  const handleSetOpenUser = useCallback(
    (value) => {
      setIsOpenToggleUser(value);
      fetchUserByName();
    },
    [fetchUserByName],
  );

  const handleKind = (kindValue: string) => {
    selectedPageNumber(INITIAL_PAGE);
    fetchInventoriesHistory('kind', kindValue);
  };
  const handleSetStartDate = useCallback(({ target: { value } }) => {
    const validate = isValidFullDate(value);
    setStartDateError(!validate);
    setStartDate(value);
  }, []);

  const handleSetEndDate = useCallback(({ target: { value } }) => {
    const validate = isValidFullDate(value);
    setEndDateError(!validate);
    setEndDate(value);
  }, []);

  const pluralizaKind = `${currentKind}s`;

  return (
    <div className={style.container}>
      <Header title="Histórico" />
      <div className={style.content}>
        <div className={style.filters}>
          <div className={style.radio}>
            <Field title="Filtrar por:">
              <Radio
                selected={kind === StockKind.Input}
                onChange={() => handleKind(StockKind.Input)}
              >
                Entradas
              </Radio>
              <Radio
                selected={kind === StockKind.Output}
                onChange={() => handleKind(StockKind.Output)}
              >
                Saídas
              </Radio>
            </Field>
          </div>

          <div className={style.period}>
            <Field title="Período">
              <Dropdown
                title={selectedPeriod || 'Este Mês'}
                isOpenProp={isOpenTogglePeriod}
                setToggleMenuProp={setIsOpenTogglePeriod}
              >
                <Dropdown.Item
                  onClick={() => handleItemClickPeriod('today', 'Hoje')}
                >
                  Hoje
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleItemClickPeriod('this_week', 'Esta semana')
                  }
                >
                  Esta semana
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleItemClickPeriod('this_month', 'Este mês')
                  }
                >
                  Este mês
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleItemClickPeriod('last_30_days', 'Últimos 30 dias')
                  }
                >
                  Últimos 30 dias
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleItemClickPeriod(null, 'Todos')}
                >
                  Todos
                </Dropdown.Item>
                <Dropdown.Item>
                  Período de
                  <Input
                    classes={style['content-input']}
                    mask={FULL_DATE}
                    value={startDate}
                    placeholder="__/__/____"
                    onChange={handleSetStartDate}
                  />
                  até
                  <Input
                    classes={style['content-input']}
                    mask={FULL_DATE}
                    value={endDate}
                    placeholder="__/__/____"
                    onChange={handleSetEndDate}
                  />
                  <Button
                    size={SIZE.Small}
                    disabled={startDateError || endDateError}
                    type="light"
                    onClick={handleCustomDateClick}
                  >
                    OK
                  </Button>
                </Dropdown.Item>
              </Dropdown>
            </Field>
          </div>

          <div className={style.product}>
            <Field title="Produtos">
              <Dropdown
                isOpenProp={isOpenToggleProduct}
                setToggleMenuProp={handleSetOpenProduct}
                title={selectedProduct || 'Todos'}
                renderInternalSearch={
                  <SearchBar onChange={handleSearchProduct} />
                }
              >
                <Dropdown.Item
                  onClick={() => handleItemClickProduct(null, 'Todos')}
                  kind="exists"
                >
                  Todos
                </Dropdown.Item>
                {products.length > 0 &&
                  products.map((product) => (
                    <Dropdown.Item
                      onClick={() =>
                        handleItemClickProduct(product.id, product.description)
                      }
                      key={product.id}
                      kind="exists"
                      value={product.id}
                    >
                      {product.description}
                    </Dropdown.Item>
                  ))}
              </Dropdown>
            </Field>
          </div>

          <div className={style.made}>
            <Field title="Feito por:">
              <Dropdown
                isOpenProp={isOpenToggleUser}
                setToggleMenuProp={handleSetOpenUser}
                title={selectedUser || 'Todos'}
                renderInternalSearch={<SearchBar onChange={handleSearchUser} />}
              >
                <Dropdown.Item
                  onClick={() => handleItemClickUser(null, 'Todos')}
                  kind="exists"
                >
                  Todos
                </Dropdown.Item>
                {users.length > 0 &&
                  users.map((user) => (
                    <Dropdown.Item
                      onClick={() => handleItemClickUser(user.id, user.name)}
                      key={user.id}
                      kind="exists"
                      value={user.id}
                    >
                      {user.name}
                    </Dropdown.Item>
                  ))}
              </Dropdown>
            </Field>
          </div>
        </div>
        <Column className={style.column}>
          <p className={style.title}>
            Exibindo: {inventoriesHistory.length} {pluralizaKind}
          </p>
          <Table>
            <Table.Head>
              <Table.Row>
                <Table.Item classes={style[tableColumn]}>Data</Table.Item>
                <Table.Item classes={cx(style[tableColumn], style['-medium'])}>
                  Produto
                </Table.Item>
                <Table.Item classes={cx(style[tableColumn], style['-disable'])}>
                  Lote
                </Table.Item>
                <Table.Item classes={cx(style[tableColumn], style['-disable'])}>
                  {currentKind}
                </Table.Item>
                <Table.Item classes={cx(style[tableColumn], style['-disable'])}>
                  Feito Por
                </Table.Item>
                <Table.Item classes={cx(style[tableColumn], style['-disable'])}>
                  Observações
                </Table.Item>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {inventoriesHistory.length > 0 ? (
                inventoriesHistory.map((history) => (
                  <Table.Row key={history.id}>
                    <Table.Column classes={style[tableColumn]}>
                      {history.date}
                    </Table.Column>
                    <Table.Column>
                      <div
                        className={cx(style[tableColumn], style['-compact'])}
                      >
                        {history.product}
                      </div>
                    </Table.Column>
                    <Table.Column
                      classes={cx(style[tableColumn], style['-disable'])}
                    >
                      <div
                        className={cx(style[tableColumn], style['-compact'])}
                      >
                        {history.lot}
                      </div>
                    </Table.Column>
                    <Table.Column
                      classes={cx(style[tableColumn], style['-disable'])}
                    >
                      {Math.abs(history.quantity)}
                    </Table.Column>
                    <Table.Column
                      classes={cx(style[tableColumn], style['-disable'])}
                    >
                      {history.profile}
                    </Table.Column>
                    <Table.Column
                      classes={cx(style[tableColumn], style['-disable'])}
                    >
                      <div
                        className={cx(style[tableColumn], style['-compact'])}
                      >
                        {history.observation}
                      </div>
                    </Table.Column>
                  </Table.Row>
                ))
              ) : (
                <p className={style['history-message']}>{message}</p>
              )}
            </Table.Body>
          </Table>
          {totalCount && totalCount > 0 && (
            <Row className={style.paginator}>
              <Paginator
                currentPage={page}
                totalCount={totalCount}
                onChangeSelectedPage={handleSelectedPage}
              />
            </Row>
          )}
        </Column>
      </div>
    </div>
  );
}

History.defaultProps = {
  users: null,
  inventoriesHistory: null,
  totalCount: 0,
  kind: StockKind.Input,
  products: null,
  page: 1,
  message: null,
  inventoriesParams: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(History);
