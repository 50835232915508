import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, spacing } = axiomTokens;

export const OutlinedCard = styled('div')({
  border: `${border.width.xs} solid ${color.border.subtle}`,
  borderRadius: border.radius.sm,
  padding: spacing[4],

  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[4],
});

export const Divider = styled('span')({
  width: '100%',
  height: 1,
  backgroundColor: color.border.subtle,
});
