import { put, select, takeLatest } from 'redux-saga/effects';

import { resetState as resetPatientState } from './patient';
import { resetState as resetProdecuresState } from './procedures';
import { PaymentProps } from '../types/types';
import {
  addNewPaymentMethod,
  deletePaymentMethod,
  resetState as resetPaymentState,
  updateValues,
} from './payment';
import { resetPostPaymentScheduleState } from './reducer';
import { getSchedulePaymentsList, getTotalValuesSelector } from './selectors';

export function* calculateNewTotal() {
  const { total } = yield select(getTotalValuesSelector);

  const paymentList: PaymentProps['paymentList'] = yield select(
    getSchedulePaymentsList,
  );

  const totalPaymentList = paymentList.reduce(
    (acc: number, curr) => acc + Number(curr.value),
    0,
  );

  yield put(
    updateValues({ remainingAmount: Number(total) - Number(totalPaymentList) }),
  );
}

export function* resetStateWorker() {
  yield put(resetProdecuresState());
  yield put(resetPaymentState());
  yield put(resetPatientState());
}

export default function* watchExpenseWorker() {
  yield takeLatest(addNewPaymentMethod, calculateNewTotal);
  yield takeLatest(deletePaymentMethod, calculateNewTotal);
  yield takeLatest(resetPostPaymentScheduleState, resetStateWorker);
}
