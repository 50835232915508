import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormikContext, Form } from 'formik';
import { useRifm } from 'rifm';

import { TextField, Box, Button, MaterialUICore } from '@iclinic/design-system';
import { cpfFormatter } from 'shared/utils/formatters';
import { createProfessionalValidation } from '../validation';
import CouncilSelect from './CouncilSelect';
import StateSelect from './StateSelect';
import useStyles from './dialogStyles.style';
import { close, submitProfessional } from '../state';
import CBOSelect from './CBOSelect';
import { ProfessionalFormValues } from '../types';
import {
  requestStatusDerivedSelector,
  professionalFormSelector,
  cboInputSelector,
} from '../state/selectors';
import ErrorMessage from './ErrorMessage';

const { CircularProgress } = MaterialUICore;

const ProfessionalForm = () => {
  const classes = useStyles();

  const {
    errors,
    touched,
    handleChange,
    handleBlur,
    values,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<ProfessionalFormValues>();

  const { value: cpf, onChange: handleChangeCpf } = useRifm({
    value: values.cpf,
    onChange: (value: string) => {
      setFieldTouched('cpf');
      setFieldValue('cpf', value);
    },
    format: cpfFormatter,
  });

  const dispatch = useDispatch();
  const { isPending, hasError } = useSelector(requestStatusDerivedSelector);

  const handleClose = () => dispatch(close());

  const submitButtonContent = isPending ? (
    <CircularProgress size={14} />
  ) : (
    'Atualizar'
  );

  return (
    <Form>
      <TextField
        label="CPF"
        id="cpf"
        name="cpf"
        type="text"
        placeholder="Ex: 014.688.190-70"
        autoComplete="cpf"
        margin="normal"
        error={!!(touched.cpf && errors.cpf)}
        helperText={touched.cpf && errors.cpf}
        onChange={handleChangeCpf}
        onBlur={handleBlur}
        value={cpf}
        disabled={isPending}
        data-ga="cfmupdate-cpf"
      />

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box mr={3} width="100%">
          <CBOSelect disabled={isPending} />
        </Box>

        <TextField
          label="RQE"
          id="rqe"
          name="rqe"
          type="text"
          placeholder="Ex: 1234567"
          autoComplete="rqe"
          margin="normal"
          error={!!(touched.rqe && errors.rqe)}
          helperText={touched.rqe && errors.rqe}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.rqe}
          disabled={isPending}
          data-ga="cfmupdate-rqe"
          inputProps={{ maxLength: 20 }}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        mb={2}
      >
        <CouncilSelect
          error={!!(touched.council_name && errors.council_name)}
          handleChange={handleChange}
          value={values.council_name}
          disabled={isPending}
          helperText={touched.council_name && errors.council_name}
        />
        <TextField
          label="Número do conselho"
          id="council_number"
          name="council_number"
          type="text"
          placeholder="Ex: 1234567"
          autoComplete="council_number"
          margin="normal"
          error={!!(touched.council_number && errors.council_number)}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.council_number}
          disabled={isPending}
          data-ga="cfmupdate-council_number"
          className={classes.inputWidth}
          helperText={touched.council_number && errors.council_number}
        />
        <StateSelect
          name="council_region"
          label="UF"
          error={!!(touched.council_region && errors.council_region)}
          handleChange={handleChange}
          value={values.council_region}
          disabled={isPending}
          helperText={touched.council_region && errors.council_region}
        />
      </Box>

      {hasError && <ErrorMessage />}

      <Box display="flex" justifyContent="space-between">
        <Button
          color="transparent"
          className={classes.exitButton}
          onClick={handleClose}
        >
          Sair
        </Button>
        <Button
          type="submit"
          disabled={isPending}
          className={classes.mainButton}
        >
          {submitButtonContent}
        </Button>
      </Box>
    </Form>
  );
};

const ProfessionalFormContainer = () => {
  const dispatch = useDispatch();
  const initialValues = useSelector(professionalFormSelector);
  const cboList = useSelector(cboInputSelector);

  const validationSchema = createProfessionalValidation(cboList);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        dispatch(submitProfessional(values));
      }}
    >
      <ProfessionalForm />
    </Formik>
  );
};

export default ProfessionalFormContainer;
