import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, MaterialIcons } from '@iclinic/design-system';

import { webShareData } from 'features/referrals/state';
import { socialMediaMessage } from 'features/referrals/constants/urls';
import { getShortUrls } from 'features/referrals/state/selectors';
import * as S from './ShareOptions.styles';

const { MoreVert, ChevronRightRounded } = MaterialIcons;

export const MoreOptions = () => {
  const dispatch = useDispatch();
  const shortUrls = useSelector(getShortUrls);

  const handleWebShareClick = () => {
    const text = `${socialMediaMessage}${shortUrls?.webshare || ''}`;
    dispatch(webShareData({ text }));
  };

  return (
    <S.ShareOptionWrapper
      data-sp="referrals_webshare_click"
      data-ga="referrals_more_options_click"
      onClick={handleWebShareClick}
    >
      <Button
        startIcon={<MoreVert />}
        variant="text"
        color="transparent"
        size="small"
        disableRipple
        disableFocusRipple
      >
        Mais opções
      </Button>
      <ChevronRightRounded />
    </S.ShareOptionWrapper>
  );
};
