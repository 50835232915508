// Internal
import { reducerMerge } from 'shared/utils/reducer';
import * as types from './types';

export const initialState = {
  isFetching: false,
  totalUsers: 1,
  products: [],
  errorsProducts: [],
  subscribeDetail: {
    isFetching: false,
    isOpen: false,
    product: null,
    hasExtraProduct: false,
    extraProduct: null,
    extraProductSlug: 'online-scheduling',
    isMonthly: true,
    errorsDetails: [],
    promotion: null,
  },
  successUrl: '',
};

// https://redux.js.org/recipes/structuring-reducers/refactoring-reducer-example
const subscription = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SUBSCRIBE.ACTION:
    case types.FETCH_SUBSCRIBE.FAILURE:
    case types.FETCH_SUBSCRIBE.SUCCESS:
    case types.FETCH_GET_PRODUCT.ACTION:
    case types.FETCH_GET_PRODUCT.SUCCESS:
    case types.FETCH_GET_PRODUCTS.ACTION:
    case types.FETCH_GET_PRODUCTS.FAILURE:
    case types.FETCH_GET_PRODUCT.FAILURE:
    case types.FETCH_GET_PRODUCTS.SUCCESS:
    case types.SET_SELECTED_PRODUCT.ACTION:
    case types.MODAL_CREDIT_CARD.OPEN:
    case types.MODAL_CREDIT_CARD.CLOSE:
    case types.TAB_RECURRENCE.CHANGE:
    case types.EXTRA_PRODUCT.CHANGE:
    case types.SET_SUCCESS_URL.ACTION:
      return reducerMerge({}, state, action.payload);
    default:
      return state;
  }
};

export default subscription;
