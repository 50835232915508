import React, { useState } from 'react';
import {
  TextField,
  MaterialUICore,
  MaterialIcons,
  InputAdornment,
} from '@iclinic/design-system';
import { FormikErrors } from 'formik';

const { IconButton } = MaterialUICore;
const { Visibility, VisibilityOff } = MaterialIcons;

interface PasswordFieldProps {
  autoComplete?: string;
  fullWidth: boolean;
  label: string;
  placeholder: string;
  id?: string;
  name: string;
  error: boolean;
  helperText:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | null;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  value: string;
  disabled?: boolean;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onVisibilityChange?: (visible: boolean) => void;
}

const PasswordField = ({
  onVisibilityChange,
  ...props
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => setShowPassword(!showPassword);

  const handlePasswordVisibilityClick = () => {
    handleTogglePassword();

    if (onVisibilityChange) onVisibilityChange(!showPassword);
  };

  const PasswordVisibility = showPassword ? <Visibility /> : <VisibilityOff />;

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="Muda a visibilidade da senha"
              onClick={handlePasswordVisibilityClick}
              edge="end"
              data-testid="password-visibility-button"
            >
              {PasswordVisibility}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default PasswordField;
