import {
  takeLatest,
  put,
  call,
  select,
} from 'redux-saga/effects';

import { handlerErrors } from 'state/shared/sagas';
import {
  fetchProfessionalsSuccess,
  fetchProfessionalsFailure,
} from 'state/marketing/emailFlow/professional/actions';
import iclinic from 'services/iclinic';

import { FETCH_PROFESSIONALS } from './constants';

import { getClinicId, getSubscriptionId } from '../root/selectors';

export function* workerFetchProfessionals() {
  try {
    const clinicId = yield select(getClinicId);
    const subscriptionId = yield select(getSubscriptionId);

    const {
      getResponseData,
      errors,
    } = yield call(iclinic.marketing.fetchProfessionals, subscriptionId, clinicId);
    if (errors) throw errors;
    const payload = getResponseData();
    yield put(fetchProfessionalsSuccess(payload));
  } catch (error) {
    yield call(handlerErrors, error, fetchProfessionalsFailure);
  }
}

export default function* professionalSagas() {
  yield takeLatest(FETCH_PROFESSIONALS.ACTION, workerFetchProfessionals);
}
