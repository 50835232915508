import { PromiseResponse } from '@types';

import { SellerEditForm } from 'features/onlinePayment/state/config/types';
import {
  apiOnlinePayment,
  apiOnlinePaymentService,
  apiUpload,
  iClinicApi,
} from '..';
import {
  FetchSellerRawData,
  PhysicianPatch,
  SellerRawData,
  SellerRequestBody,
  SendDocumentRequestBody,
  UploadDocumentRawData,
  SellerBankAccountRequestBody,
  SellerBankAccountRawData,
  InstallmentsRequestBody,
  EmailsSettingsRawData,
  PhysicianRawData,
  DocumentProofRawData,
  SendSellerHubspot,
  Address,
} from './types';

export function updatePhysicianRequest(id: number, data: PhysicianPatch) {
  return iClinicApi.patch(`/accounts/physician/${id}/`, data);
}

export function fetchPhysician(id: number): PromiseResponse<PhysicianRawData> {
  return iClinicApi.get(`/accounts/physician/${id}/`);
}

export const fetchSellerAccountsByPhysicianId = (
  physicianId: number,
): PromiseResponse<FetchSellerRawData> =>
  apiOnlinePayment.get(
    `/sellers/?physician_id=${physicianId}&expand=bank_account`,
  );

export const fetchSellerDocumentProof = (
  sellerId: string,
): PromiseResponse<DocumentProofRawData[]> =>
  apiOnlinePayment.get(`/documents/?seller=${sellerId}`);

export function createSellerRequest(
  seller: SellerRequestBody,
): PromiseResponse<SellerRawData> {
  return apiOnlinePayment.post('/sellers/', seller);
}

export function updateSellerRequest(
  seller: SellerEditForm,
  address: Address,
): PromiseResponse<SellerRawData> {
  return apiOnlinePayment.patch('/sellers/', {
    id: seller.sellerId,
    ...address,
  });
}

export function getUploadURL(
  document: File,
): PromiseResponse<UploadDocumentRawData> {
  return apiOnlinePaymentService.post('/upload/', {
    content_type: document.type,
  });
}

export function uploadDocument(url: string, document: File) {
  return apiUpload.put(url, document, {
    headers: {
      'Content-Type': document.type,
    },
  });
}

export function sendDocuments(id: string, data: SendDocumentRequestBody) {
  return apiOnlinePayment.post(`/sellers/${id}/send-documents/`, data);
}

export function createSellerBankAccount(
  sellerBankAccount: SellerBankAccountRequestBody,
): PromiseResponse<SellerBankAccountRawData> {
  return apiOnlinePayment.post('/bank-accounts/', sellerBankAccount);
}

export function updateSellerBankAccount(
  id: string,
  sellerBankAccount: SellerBankAccountRequestBody,
): PromiseResponse<SellerBankAccountRawData> {
  return apiOnlinePayment.put(`/bank-accounts/${id}/`, sellerBankAccount);
}

export function saveInstallments(
  sellerId: string,
  installments: InstallmentsRequestBody,
): PromiseResponse<InstallmentsRequestBody> {
  return apiOnlinePayment.put(
    `/sellers/${sellerId}/installments/`,
    installments,
  );
}

export function getEmailSettings(
  id: string,
): PromiseResponse<EmailsSettingsRawData> {
  return apiOnlinePayment.get(`/sellers/${id}/mail-settings/`);
}

export function saveEmailSettings(
  id: string,
  data: EmailsSettingsRawData,
): PromiseResponse<any> {
  return apiOnlinePayment.put(`/sellers/${id}/mail-settings/`, data);
}

export function updateDefaultSellerRequest(id: string): PromiseResponse<any> {
  return apiOnlinePayment.put(`/sellers/${id}/change-default`);
}

export function getBankAccount(
  id: string,
): PromiseResponse<SellerBankAccountRawData> {
  return apiOnlinePayment.get(`/bank-accounts/${id}`);
}

export const sendSellerHubspot = (): PromiseResponse<SendSellerHubspot> =>
  apiOnlinePayment.get(`/sellers/send-to-hubspot`);
