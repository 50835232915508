import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';

import {
  MaterialUICore,
  Alert,
  MaterialIcons,
  Grid,
  Button,
  Box,
} from '@iclinic/design-system';
import { Bill } from 'features/onlinePayment/state/checkout/infos/types';
import { selectPaymentType } from 'features/onlinePayment/state/checkout/infos/actions';
import { checkoutCreditCard } from 'features/onlinePayment/state/checkout/payment/actions';
import {
  getCheckoutV2Error,
  isFetchingCheckoutV2,
} from 'features/onlinePayment/state/checkout/payment/selectors';
import { PaymentForm } from 'features/onlinePayment/state/checkout/payment/types';
import { validationSchema, initialValues } from './form-utils';
import CreditCardFields from './CreditCardFields';

const { CircularProgress } = MaterialUICore;
const { Lock, ArrowBack } = MaterialIcons;

type CreditCardProps = {
  bill: Bill | null;
};

export default function CreditCard({
  bill,
}: CreditCardProps): JSX.Element | null {
  const dispatch = useDispatch();

  const [checkoutV2ErrorMessage, isFetching] = [
    useSelector(getCheckoutV2Error),
    useSelector(isFetchingCheckoutV2),
  ];

  const resetPaymentTypeSelected = () => {
    dispatch(selectPaymentType({ paymentType: null }));
  };

  if (!bill) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(paymentForm: PaymentForm) => {
        dispatch(checkoutCreditCard({ paymentForm }));
      }}
    >
      <Form style={{ height: '100%' }}>
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{ height: '100%' }}
        >
          <Grid item>
            <Box mb={2}>
              <Alert
                severity="info"
                icon={<Lock />}
                message="Para sua segurança, não armazenamos dados do seu cartão"
              />
            </Box>
            <CreditCardFields error={checkoutV2ErrorMessage} bill={bill} />
          </Grid>
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mt={2}
            >
              <Button
                onClick={() => resetPaymentTypeSelected()}
                variant="text"
                startIcon={<ArrowBack />}
                disabled={isFetching}
              >
                Voltar
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="large"
                disabled={isFetching}
                endIcon={isFetching ? <CircularProgress size={14} /> : null}
              >
                {isFetching ? 'Processando pagamento' : 'Realizar Pagamento'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  );
}
