import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  GetCalculatorsByCategoryResponse,
  CalculatorDrawerState,
  CalculatorDrawerView,
  CalculatorItem,
} from './types';

export const initialState: CalculatorDrawerState = {
  isCalculatorDrawerOpen: false,
  isCalculatorFetchLoading: false,
  calculatorsByCategory: [],
  getCalculatorError: false,
  calculatorView: CalculatorDrawerView.LIST,
};

const name = 'calculatorDrawer';

const CalculatorDrawerSlice = createSlice({
  name: `@webapp/${name}`,
  initialState,
  reducers: {
    openCalculatorDrawer: (state) => ({
      ...state,
      isCalculatorDrawerOpen: true,
    }),
    closeCalculatorDrawer: (state) => ({
      ...state,
      isCalculatorDrawerOpen: false,
    }),
    getCalculators: (state) => ({
      ...state,
      isCalculatorFetchLoading: true,
    }),
    getCalculatorsSuccess: (
      state,
      action: PayloadAction<GetCalculatorsByCategoryResponse>,
    ) => ({
      ...state,
      calculatorsByCategory: action.payload,
      getCalculatorError: false,
      isCalculatorFetchLoading: false,
    }),
    getCalculatorsError: (state) => ({
      ...state,
      getCalculatorError: true,
      isCalculatorFetchLoading: false,
    }),
    setCalculatorView: (state, action: PayloadAction<CalculatorItem>) => ({
      ...state,
      calculatorView: CalculatorDrawerView.CALCULATOR,
      calculatorSelected: action.payload,
    }),
    setListView: (state) => ({
      ...state,
      calculatorView: CalculatorDrawerView.LIST,
      calculatorSelected: undefined,
    }),
  },
});

export const actions = {
  ...CalculatorDrawerSlice.actions,
};

export default CalculatorDrawerSlice.reducer;
