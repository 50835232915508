// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import Icon from 'ui/new/icon';
import style from './SearchBar.scss';

const SearchBar = ({
  onChange,
  placeholder,
  disabled,
  value,
  name,
  input,
  max,
}) => (
  <div className={style.search}>
    <div className={style.search__icon}>
      <Icon name="search" color="#888888" />
    </div>
    <input name={name} id={name} className={style.search__input} type="text" maxLength={max} placeholder={placeholder} disabled={disabled} onChange={onChange} value={value} {...input} />
  </div>
);

SearchBar.defaultProps = {
  onChange: null,
  placeholder: null,
  disabled: false,
  input: null,
  name: '',
  value: null,
  max: 50,
};

SearchBar.propTypes = {
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string,
  }),
  name: PropTypes.string,
  value: PropTypes.string,
  max: PropTypes.number,
};

export default SearchBar;
