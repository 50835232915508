import React from 'react';

import { Body, Heading } from '@iclinic/design-system';
import { image } from 'shared/utils/urlGetter';
import { IndicationAwardInfo } from '../../types';
import * as S from './IndicationAwards.styles';

interface IndicationAwardsProps {
  info: IndicationAwardInfo[];
}

const IndicationAwards = ({ info }: IndicationAwardsProps) => {
  return (
    <S.Section>
      <S.Frame>
        <Heading variant="xl">Indicando, você ganha:</Heading>

        {info.map(({ icon, text }, index) => (
          <div key={index.toString()}>
            {icon}
            <Body variant="sm">{text}</Body>
          </div>
        ))}
      </S.Frame>

      <S.ImgWrapper>
        <img
          src={image('referrals.indicationGraph')}
          alt="Grafico de Indicação"
        />
      </S.ImgWrapper>
    </S.Section>
  );
};

export default IndicationAwards;
