import { PromiseResponse } from '@types';
import {
  apiOnlinePaymentCheckout,
  lambdaCheckoutV2,
} from 'features/onlinePayment/services';
import {
  FetchPhysicianInfosRawData,
  FetchEventInfosRawData,
  FetchBillInfosRawData,
  ReceiptEmailRaw,
  CheckoutRequestBodyV2,
  SyncStatusBody,
  SyncStatusBodyRawData,
  CheckoutV2RawData,
} from './types';

export const fetchPhysicianInfosRequest = (): PromiseResponse<FetchPhysicianInfosRawData> =>
  apiOnlinePaymentCheckout.get('/billing/bills/physician/');

export const fetchEventInfosRequest = (): PromiseResponse<FetchEventInfosRawData> =>
  apiOnlinePaymentCheckout.get('/billing/bills/event/');

export const fetchBillInfosRequest = (
  billId: string,
): PromiseResponse<FetchBillInfosRawData> =>
  apiOnlinePaymentCheckout.get(`/billing/bills/${billId}?expand=seller`);

export const sendReceiptEmailRequest = (
  data: ReceiptEmailRaw,
): PromiseResponse<ReceiptEmailRaw> =>
  apiOnlinePaymentCheckout.put('/billing/bills/receipt-email/', data);

export const checkoutRequestV2 = (
  data: CheckoutRequestBodyV2,
): PromiseResponse<CheckoutV2RawData> =>
  lambdaCheckoutV2.post('/checkout', data);

export const syncStatusTransactionRequest = (
  data: SyncStatusBody,
): PromiseResponse<SyncStatusBodyRawData> =>
  lambdaCheckoutV2.post('/sync-status', data);
