import * as Yup from 'yup';

export const requiredFieldText = 'Campo obrigatório';

export const headerSectionSchema = Yup.object().shape({
  version_id: Yup.string().required(requiredFieldText),
});

export const headerSectionValues = {
  version_id: '',
};
