import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DialogHeader } from '@iclinic/design-system';

import NavigationCardsModal from './NavigationCardsModal';
import {
  getIsModalHighlightsInfoOpen,
  getHighlightInfoSelected,
} from '../../state/selectors';
import { actions } from '../../state';
import {
  CardInteractionTracking,
  CardKind,
  HighlightsInfoListCard,
  HighlightsInfoTextCard,
  ListCardType,
  TextCardType,
} from '../../types';
import { TypeCardMap, getCardTypeTracking } from '../../utils';
import * as S from './HighlightsInfoModal.styles';
import { useTrackHighlightsInfoEvents } from '../../hooks/useTrackHighlightsInfoEvents';
import { CardTextContent } from './CardTextContent';
import { CardListContent } from './CardListContent';

const { closeHighlightsInfoModal, saveHighlightsInfo } = actions;

export const HighlightsInfoModal = () => {
  const [isSaved, setIsSaved] = useState(true);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();
  const isHighlightInfoModalOpen = useSelector(getIsModalHighlightsInfoOpen);
  const highlightInfoSelected = useSelector(getHighlightInfoSelected);
  const { trackEvents } = useTrackHighlightsInfoEvents();

  const trackEvent = (
    cardInteraction: CardInteractionTracking,
    typeCard: TextCardType | ListCardType,
  ) => {
    trackEvents({
      cardInteraction,
      cardType: getCardTypeTracking(typeCard),
      cardName: TypeCardMap[typeCard],
    });
  };

  const handleFocus = () => {
    if (!inputRef.current) return;
    const currentInput = inputRef.current;
    currentInput.focus();
    currentInput.selectionStart = currentInput.value.length;
  };

  const clearSaveOnIdle = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
  };

  const handleSave = () => {
    dispatch(saveHighlightsInfo());
    trackEvent(CardInteractionTracking.fill, highlightInfoSelected.typeCard);
  };

  const setUpSaveOnIdle = () => {
    setIsSaved(false);
    clearSaveOnIdle();
    timerRef.current = setTimeout(() => {
      handleSave();
      setIsSaved(true);
    }, 5000);
  };

  const handleClose = () => {
    if (!isSaved) {
      clearSaveOnIdle();
      handleSave();
      setIsSaved(true);
    }
    dispatch(closeHighlightsInfoModal());
    trackEvent(CardInteractionTracking.close, highlightInfoSelected.typeCard);
  };

  const handleBlur = () => {
    if (isSaved) return;
    clearSaveOnIdle();
    handleSave();
    setIsSaved(true);
  };

  const handleSaveOnNav = () => {
    handleBlur();
    handleFocus();
  };

  return (
    <S.Dialog
      open={isHighlightInfoModalOpen}
      onClose={handleClose}
      maxWidth="lg"
      innerRef={containerRef}
      onAnimationEnd={handleFocus}
    >
      <DialogHeader
        title={TypeCardMap[highlightInfoSelected?.typeCard]}
        onClose={handleClose}
      />
      <S.DialogContent>
        {highlightInfoSelected?.kind === CardKind.Text ? (
          <CardTextContent
            handleBlur={handleBlur}
            setUpSaveOnIdle={setUpSaveOnIdle}
            isSaved={isSaved}
            inputRef={inputRef}
            highlightInfoSelected={
              highlightInfoSelected as HighlightsInfoTextCard
            }
          />
        ) : (
          <CardListContent
            setUpSaveOnIdle={setUpSaveOnIdle}
            isSaved={isSaved}
            inputRef={inputRef}
            highlightInfoSelected={
              highlightInfoSelected as HighlightsInfoListCard
            }
          />
        )}
      </S.DialogContent>
      <S.DialogActions>
        {isHighlightInfoModalOpen && (
          <NavigationCardsModal
            highlightInfoSelected={highlightInfoSelected}
            containerRef={containerRef}
            trackEvent={trackEvent}
            onSave={handleSaveOnNav}
          />
        )}
      </S.DialogActions>
    </S.Dialog>
  );
};
