import { takeLatest, select, put, call, all } from 'redux-saga/effects';

import webPKI from 'features/records/recordSignature/webpkiConfig';
import {
  handlerErrors,
  workerGeneratePDF,
  workerFetchSendEmail,
} from 'state/shared/sagas';
import { clearPdfList, sendEmailSignature } from 'state/shared/actions';
import iclinic from 'services/iclinic';
import * as actions from 'state/records/signature/actions';
import * as constants from 'state/records/signature/constants';
import { getCurrentEventAndPatientId } from 'state/records/selectors';
// eslint-disable-next-line import/no-cycle
import { workerGetEventsBlocksIds } from 'state/records/sagas';
import { SEND_EMAIL_SIGNATURE } from 'state/shared/types';
import {
  FileSignatureRequest,
  FileSignatureCompletedRequest,
  ProvidersPSC,
} from 'state/records/signature/types';
import { createCookie } from 'shared/utils/cookie';
import { getUrlParams, updateURLParameter } from 'shared/utils/url';
import { getSignatureRequest, getTemplateEmailSignature } from './selectors';
import { errorsHandle } from './errors';
import { SIGNED_REDIRECT_USER_INFOS } from './constants';

export const eventsBlocks = (state) => state.records.root.eventBlocksIds;
export const encodedCert = (state) => state.records.signature.encodedCert;
export const listS3Pdf = (state) => state.records.signature.signedPdf;
export const thumbprintState = (state) => state.records.signature.thumbprint;
export const AccessTokenState = (state) => state.records.signature.accessToken;
export const emailErrors = (state) => state.shared.errors;
export const patientInfo = (state) => state.records.root.patient.id;

type GeneratePdfType = ReturnType<typeof actions.finalizeMedicalRecord>;
interface BlockItemType {
  id: string;
  documentType: string;
  url: string;
}

export function* workerFetchSignPDF(
  signatureResponse: FileSignatureCompletedRequest,
) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.signatures.getCompletedSignature,
      signatureResponse,
    );
    if (errors) throw errors;

    const { pdf_url, signed_pdf_url } = getResponseData();

    const { document_type, document_id } = signatureResponse;

    const pdfs = yield select(listS3Pdf);

    yield put(
      actions.fetchSignatureSuccess([
        ...pdfs,
        {
          document_id,
          document_type,
          pdf_url,
          signed_pdf_url,
        },
      ]),
    );
  } catch (error) {
    yield call(handlerErrors, error, actions.fetchSignatureFailure);
  }
}

function signHashPromise(
  document_hash: string,
  sign_algorithm: string,
  thumbprint: string,
) {
  return new Promise((resolve) => {
    webPKI
      .signHash({
        thumbprint,
        hash: document_hash,
        digestAlgorithm: sign_algorithm,
      })
      .success((signature: string) => {
        resolve(signature);
      });
  });
}

export function* workerSignaturePdfs(signPayload: FileSignatureRequest) {
  try {
    const thumbprint = yield select(thumbprintState);
    const { getResponseData, errors } = yield call(
      iclinic.signatures.fetchStartSignature,
      signPayload,
    );
    if (errors) throw errors;
    const { sign_algorithm, document_hash, transfer_id } = getResponseData();
    const { document_type, document_id } = signPayload;

    const signature = yield call(
      signHashPromise,
      document_hash,
      sign_algorithm,
      thumbprint,
    );

    const signatureResponse = {
      signed_hash: signature,
      transfer_id,
      document_type,
      document_id,
    };

    yield call(workerFetchSignPDF, signatureResponse);
  } catch (error) {
    yield call(handlerErrors, error, actions.finalizeMedicalRecordFailure);
  }
}

export function* workerComposePdfSignature() {
  try {
    const signatureRequestData = yield select(getSignatureRequest);
    yield all(
      signatureRequestData.map((fileSignature: FileSignatureRequest) =>
        call(workerSignaturePdfs, fileSignature),
      ),
    );
  } catch (error) {
    yield call(handlerErrors, error, actions.finalizeMedicalRecordFailure);
  }
}

export function* workerFetchUrlRecordsPdf({
  payload: { sign },
}: GeneratePdfType) {
  try {
    const eventBlocksIds = yield select(eventsBlocks);
    if (!sign) {
      yield put(actions.finalizeMedicalRecordSuccess());
      return;
    }

    if (eventBlocksIds.length === 0) {
      const error = {
        code: '124',
        message: 'Nenhum documento encontrado para ser assinado',
      };
      yield put(actions.finalizeMedicalRecordFailure([error]));
      return;
    }

    yield put(clearPdfList());
    yield all(
      eventBlocksIds.map((eventBlock: BlockItemType) =>
        call(
          workerGeneratePDF,
          eventBlock.id,
          eventBlock.documentType,
          eventBlock.url,
        ),
      ),
    );
    yield call(workerComposePdfSignature);
  } catch (error) {
    yield call(handlerErrors, error, actions.finalizeMedicalRecordFailure);
  }
}

type EmailSignaturePayload = ReturnType<typeof sendEmailSignature>;
export function* workerFetchEmailSignature({
  payload: { email, attachments },
}: EmailSignaturePayload) {
  try {
    const emailEmptyTemplate = yield select(getTemplateEmailSignature);
    const emailTemplate = {
      ...emailEmptyTemplate,
      email: {
        ...emailEmptyTemplate.email,
        recipients: [email],
        attachments,
      },
    };
    yield call(workerFetchSendEmail, emailTemplate);

    const errors = yield select(emailErrors);

    if (errors.length > 0) return;

    yield put(actions.finalizeMedicalRecordSuccess());
    yield put(actions.clearSignPDF());
  } catch (error) {
    yield call(handlerErrors, error, actions.finalizeMedicalRecordFailure);
  }
}

type GetListPSCPayload = ReturnType<typeof actions.getListPSC>;
export function* workerGetListPSCSignature({
  payload: { user_document, redirect_uri },
}: GetListPSCPayload) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.signatures.getListPsc,
      { user_document, redirect_uri },
    );
    if (errors) throw errors;

    const { providers } = getResponseData();
    if (providers.length === 0) {
      yield put(actions.getListPSCFailure([errorsHandle.certificatesNotFound]));
      return;
    }
    const patientID = yield select(patientInfo);

    const providersCloud = providers.map((provider: ProvidersPSC) => {
      const { auth_url, name } = provider;
      const state = getUrlParams('state', auth_url);
      const redirectUri = getUrlParams('redirect_uri', auth_url);
      const authUrlUpdateState = updateURLParameter(
        auth_url,
        'state',
        `${patientID}-${state}`,
      );
      const authUrlUpdateRedirect = updateURLParameter(
        authUrlUpdateState,
        'redirect_uri',
        redirectUri,
      );

      return {
        auth_url: authUrlUpdateRedirect,
        name,
      };
    });

    yield put(actions.getListPSCSuccess(providersCloud));
  } catch (error) {
    yield call(handlerErrors, error, actions.getListPSCFailure);
  }
}

export function* workerSignatureSignPadesCloud(
  signPayload: FileSignatureRequest,
) {
  try {
    const accessTokenCloud = yield select(AccessTokenState);
    const {
      s3_file_url,
      patient_id,
      physician_id,
      document_type,
      document_id,
      user_id,
    } = signPayload;

    const { getResponseData, errors } = yield call(
      iclinic.signatures.fetchSignatureCloud,
      {
        s3_file_url,
        access_token: accessTokenCloud,
        patient_id,
        physician_id,
        document_type,
        document_id,
        user_id,
      },
    );

    if (errors) throw errors;

    const { pdf_url, signed_pdf_url } = getResponseData();

    const pdfs = yield select(listS3Pdf);

    yield put(
      actions.fetchSignatureSuccess([
        ...pdfs,
        {
          document_id,
          document_type,
          pdf_url,
          signed_pdf_url,
        },
      ]),
    );
  } catch (error) {
    yield call(handlerErrors, error, actions.finalizeMedicalRecordFailure);
  }
}

export function* workerSignaturePdfsCloud() {
  try {
    const signatureRequestData = yield select(getSignatureRequest);
    yield all(
      signatureRequestData.map((fileSignature: FileSignatureRequest) =>
        call(workerSignatureSignPadesCloud, fileSignature),
      ),
    );
  } catch (error) {
    yield call(handlerErrors, error, actions.finalizeMedicalRecordFailure);
  }
}

export function* workerFetchUrlRecordsPdfCloud() {
  try {
    const localEventInfo = JSON.parse(
      localStorage.getItem(SIGNED_REDIRECT_USER_INFOS),
    );

    if (localEventInfo) localStorage.removeItem(SIGNED_REDIRECT_USER_INFOS);

    const { eventId: eventIdState, patientId: patientIdState } = yield select(
      getCurrentEventAndPatientId,
    );
    const eventId = localEventInfo ? localEventInfo.eventId : eventIdState;
    const patientId = localEventInfo
      ? localEventInfo.patientId
      : patientIdState;

    yield call(workerGetEventsBlocksIds, {
      payload: {
        eventId,
        patientId,
      },
    });

    const eventBlocksIds = yield select(eventsBlocks);

    if (eventBlocksIds.length === 0)
      yield put(
        actions.finalizeMedicalRecordFailure([errorsHandle.documentNotFound]),
      );

    yield put(clearPdfList());

    yield all(
      eventBlocksIds.map((eventBlock: BlockItemType) =>
        call(
          workerGeneratePDF,
          eventBlock.id,
          eventBlock.documentType,
          eventBlock.url,
        ),
      ),
    );
    yield call(workerSignaturePdfsCloud);
  } catch (error) {
    yield call(handlerErrors, error, actions.finalizeMedicalRecordFailure);
  }
}

type GetSignatureAccessTokenPayload = ReturnType<
  typeof actions.getSignatureAccessToken
>;
export function* workerGetSignatureAccessToken({
  payload: { auth_code, state },
}: GetSignatureAccessTokenPayload) {
  try {
    const { getResponseData, errors } = yield call(
      iclinic.signatures.getAccessTokenSignature,
      { auth_code, state },
    );
    if (errors) throw errors;

    const { access_token } = getResponseData();
    yield put(actions.getSignatureAccessTokenSuccess(access_token));

    const {
      lifeTime,
      providerName,
      userDocument: { number },
    } = JSON.parse(localStorage.getItem(SIGNED_REDIRECT_USER_INFOS));

    createCookie(String(providerName) + String(number), access_token, lifeTime);

    yield call(workerFetchUrlRecordsPdfCloud);
  } catch (error) {
    yield call(handlerErrors, error, actions.getSignatureAccessTokenFailure);
  }
}

export default function* signatureSagas() {
  yield all([
    takeLatest(constants.RECORDS_FINISHED, workerFetchUrlRecordsPdf),
    takeLatest(SEND_EMAIL_SIGNATURE, workerFetchEmailSignature),
    takeLatest(constants.GET_LIST_PSC, workerGetListPSCSignature),
    takeLatest(constants.GET_ACCESS_TOKEN, workerGetSignatureAccessToken),
    takeLatest(constants.FETCH_SIGNATURE_CLOUD, workerFetchUrlRecordsPdfCloud),
  ]);
}
