import { PromiseEnvelope } from '@types';
import { apiTelemedicine } from '../api';
import { decodePromiseEnvelope } from '../utils';
import { FetchPatientInfoData, FetchPatientInfoRawData } from '../types';

export const fetchPatientInfo = (): PromiseEnvelope<FetchPatientInfoData> =>
  decodePromiseEnvelope(
    apiTelemedicine.get('conference/patient-info') as PromiseEnvelope<
      FetchPatientInfoRawData
    >,
    ({
      id,
      name,
      schedule,
      email,
      photo,
      gender = '',
      birth_date: birthday = '',
      city = '',
      mobile_phone: mobilePhone = '',
      clinic = '',
    }: FetchPatientInfoRawData): FetchPatientInfoData => ({
      schedule,
      patient: {
        id,
        name,
        email,
        photo,
        gender,
        birthday,
        city,
        mobilePhone,
        clinic,
      },
    }),
  );
