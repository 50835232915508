import { useEffect } from 'react';

import { MaterialUICore } from '@iclinic/design-system';

const { useMediaQuery } = MaterialUICore;

/**
 * This custom hook is used to add/remove a given class in the body's classList.
 *
 * With 1 arg, the class will be added to the body's classList in the **mount** cycle and removed in the **unmount** cycle
 *
 * With a responsive breakpoint, it will be used to toggle the given class in the body's classList
 *
 * @param className class to toggle in the body's classList
 * @param breakpoint responsive parameter
 */
export default function useToggleBodyClass(
  className: string,
  breakpoint?: string,
) {
  const matches = useMediaQuery(breakpoint || '');

  useEffect(() => {
    const classListBody = document.body.classList;

    if (matches) {
      classListBody.add(className);
    }

    return () => {
      if (matches) {
        classListBody.remove(className);
      }
    };
  }, [className, matches]);
}
