// External
import * as React from 'react';
import classnames from 'classnames/bind';

// Internal
import Icon from 'ui/new/icon';
import style from './Select.scss';

const classes = classnames.bind(style);

type SelectProps = {
  icon?: string,
  color?: string,
  onKeyUp?: () => void,
  text: string,
  isOpen: boolean,
  onClick: () => void,
};

function Select({
  icon = 'menu',
  color = '#9C9C9C',
  text,
  isOpen,
  onClick,
  onKeyUp,
}: SelectProps) {
  return (
    <div className={classes('select', { open: isOpen })} onClick={onClick} onKeyUp={onKeyUp} role="button" tabIndex={0}>
      {icon && (
        <div className={style.select__icon}>
          <Icon name={icon} color={color} />
        </div>
      )}
      <p className={style.select__text}>{text}</p>
      <div className={style.select__icon}>
        <Icon name={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'} color={color} />
      </div>
    </div>
  );
}

export default Select;
