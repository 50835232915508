import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getClinicPhysicians,
  sendExportRequest,
} from 'services/iclinic/exportClinicData/exportRequest';
import { setExportDrawer } from '../exportHome';
import {
  ClinicPhysicians,
  SendExportRequestRawData,
} from 'services/iclinic/exportClinicData/exportRequest/types';
import {
  fetchClinicPhysicians,
  fetchClinicPhysiciansSuccess,
  fetchClinicPhysiciansFailure,
  setDuplicatedExport,
  sendNewExportRequest,
  sendNewExportRequestSuccess,
  sendNewExportRequestFailure,
} from '.';

export function* fetchClinicPhysiciansWorker(ids) {
  try {
    const { getResponseData, error } = yield call(
      getClinicPhysicians,
      ids.payload.subscriptionId,
      ids.payload.clinicId,
    );

    if (error) throw error;

    const clinicPhysiciansList: ClinicPhysicians = getResponseData();

    yield put(fetchClinicPhysiciansSuccess(clinicPhysiciansList));
  } catch (error) {
    yield put(fetchClinicPhysiciansFailure());
  }
}

export function* sendExportRequestWorker(data: SendExportRequestRawData) {
  try {
    const { payload } = data;
    const { data: responseData } = yield call(sendExportRequest, payload);
    if (
      responseData?.non_field_errors?.includes('Duplicated export request.')
    ) {
      yield put(setDuplicatedExport(true));
      yield put(sendNewExportRequestSuccess());
      return;
    }
    yield put(setExportDrawer(false));
    yield put(sendNewExportRequestSuccess());
  } catch (error) {
    yield put(sendNewExportRequestFailure());
  }
}

export default function* exportDownloadWorker() {
  yield takeLatest(sendNewExportRequest, sendExportRequestWorker);
  yield takeLatest(fetchClinicPhysicians, fetchClinicPhysiciansWorker);
}
