export const formatAutocompleteItems = (item: any, name: string) => {
  const {
    id,
    name: itemName,
    title,
    search_name,
    health_insurance_name,
    term,
    related_tuss_table,
    description,
    code,
  } = item;

  if (name === 'codeOptions') {
    return { id, title: code, term };
  }

  if (name === 'procedureOptions') {
    return { id, title: `${code} - ${term}` };
  }

  return {
    id,
    title:
      itemName ||
      title ||
      search_name ||
      health_insurance_name ||
      term ||
      description,
    ...(name === 'typeOptions' && { related_tuss_table }),
  };
};
