enum ONLINE_SCHEDULE_MESSAGES {
  '@online-scheduling:page-title' = 'Agendamento Online',
  '@online-scheduling:physician-select-label' = 'Você está configurando o agendamento online de:',
  '@online-scheduling:integration-title' = 'Integração gratuita com AgendarConsulta',
  '@online-scheduling:integration-subtitle' = 'Permita que seus pacientes marquem consultas pelo agendamento online de qualquer lugar e a qualquer momento.',
  '@online-scheduling:integration-remove' = 'Desativar agenda online',
  '@online-scheduling:integration-remove-cancel' = 'Cancelar',
  '@online-scheduling:integration-modal-text' = 'Deseja remover sua integração de agendamento online?',
  '@online-scheduling:integration-modal-remove' = 'Remover',
  '@online-scheduling:integration-make-integration' = 'Ativar agenda online',
  '@online-scheduling:antecedence-label' = 'Antecedência',
  '@online-scheduling:antecedence-text' = 'Escolha o tempo de antecedência necessário para agendar consulta na sua clínica',
  '@online-scheduling:antecedence-select-label' = 'Sem antecedência',
  '@online-scheduling:work-hours-label' = 'Horário',
  '@online-scheduling:work-hours-text' = 'Defina quais horários mostrar no agendamento online.',
  '@online-scheduling:insurances-label' = 'Convênios',
  '@online-scheduling:insurances-text-1' = 'Defina quais convênios você deseja exibir no seu agendamento online.',
  '@online-scheduling:insurances-text-2' = 'Ao habilitar convênios para teleconsulta, essa opção de agendamento ficará disponível em seu perfil.',
  '@online-scheduling:insurances-telemedicine' = 'Teleconsulta',
  '@online-scheduling:insurances-in-person' = 'Presencial',
  '@online-scheduling:insurances-enable-all' = 'Ativar todos',
  '@online-scheduling:insurances-disable-all' = 'Desativar todos',
}

export default ONLINE_SCHEDULE_MESSAGES;
