import React from 'react';

import { Container, Box, Typography } from '@iclinic/design-system';
import TransactionsFilters from '../filters/TransactionsFilters';
import TransactionsList from '../list/TransactionsList';
import TransactionDetail from '../detail/TransactionDetail';

const TransactionsHistoryPage = () => {
  return (
    <Box mb={3}>
      <Container maxWidth="md">
        <Box mb={2}>
          <Box maxWidth="510px" mb={5}>
            <Box display="flex" alignItems="flex-start" flexWrap="wrap">
              <Box mr={1}>
                <Typography variant="h3" gutterBottom>
                  Histórico de transações
                </Typography>
              </Box>
            </Box>
            <Typography variant="subtitle1">
              Acompanhe a movimentação dos pagamentos <br />
              que foram realizados pelos seus pacientes
            </Typography>
          </Box>
        </Box>
        <TransactionsFilters />
        <TransactionsList />
      </Container>
      <TransactionDetail />
    </Box>
  );
};

export default TransactionsHistoryPage;
