import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    height: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    height: '100%',
    maxWidth: '520px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  backButton: {
    margin: '8px 24px',
    width: '94px',
    color: theme.palette.grey[300],
    marginBottom: 24,
    '& .MuiTypography-overline': {
      fontSize: '12px',
      lineHeight: '32px',
      letterSpacing: '0.44px',
    },
  },
  mainContent: {
    height: '100%',
    margin: '0px 24px',
    '& .MuiTypography-body1': {
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '0.44px',
      marginLeft: '10px',
    },
    '& .MuiTypography-h3': {
      fontSize: '27px',
      lineHeight: '34px',
      marginLeft: '10px',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '288px',
  },
  footer: {
    justifyContent: 'space-between',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
  },
  closeButton: {
    color: theme.palette.grey[300],
    margin: '8px',
    width: '230px',
    '& .MuiTypography-body1': {
      fontSize: '14px',
      lineHeight: '32px',
      letterSpacing: '0.44px',
    },
  },
  buttonProgress: {
    color: theme.palette.grey[300],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  footerButton: {
    height: '48px',
    margin: '16px 32px 16px 16px',
    fontSize: '16px',
    letterSpacing: '0.3px',
    lineHeight: '24px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '16px',
      justifyContent: 'center',
    },
    backgroundColor: theme.palette.primary.main,
  },
  certificates: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px',
    '& .MuiTypography-body2': {
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.44px',
      marginLeft: '10px',
    },
  },
  bodyText: {
    paddingTop: '8px',
    color: theme.palette.grey[500],
  },
  radioLabel: {
    padding: '8px 6px',
    margin: '0',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '350px',
    whiteSpace: 'nowrap',
    color: theme.palette.grey[700],
  },
  radioLabelDisabled: {
    padding: '8px 6px',
    margin: '8px 0',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '350px',
    whiteSpace: 'nowrap',
    color: theme.palette.grey[300],
  },
  radio: {
    width: '20px',
    height: '20px',
    '& .MuiSvgIcon-root': {
      width: '20px',
      height: '20px',
    },
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  snackbarError: {
    marginTop: '40px',
    backgroundColor: theme.palette.error.light,
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
  },
  iconError: {
    color: theme.palette.error.main,
    fontSize: '20px',
    marginRight: '10px',
  },
  message: {
    alignItems: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    fontSize: '14px',
    '& .MuiTypography-root': {
      fontSize: '14px',
    },
  },
}));

export default useStyles;
