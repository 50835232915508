import React from 'react';
import {
  LocalTrackPublication,
  RemoteTrackPublication,
  AudioTrack as IAudioTrack,
} from 'twilio-video';

import useTrack from 'features/video-conference/hooks/useTrack';
import VideoTrack from '../../VideoTrack';
import { IVideoTrack } from 'features/video-conference/utils';
import AudioTrack from '../../AudioTrack';
import DataTrack from '../../DataTrack';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  videoOnly?: boolean;
  isLocalParticipant?: boolean;
}

export default function Publication({
  publication,
  videoOnly,
  isLocalParticipant,
}: PublicationProps) {
  const track = useTrack(publication);
  if (!track) return null;

  switch (track.kind) {
    case 'video':
      return (
        <VideoTrack
          track={track as IVideoTrack}
          isLocal={!track.name.includes('screen') && isLocalParticipant}
        />
      );
    case 'audio':
      return videoOnly ? null : <AudioTrack track={track as IAudioTrack} />;
    case 'data':
      return videoOnly ? null : (
        <DataTrack track={track} isLocal={isLocalParticipant} />
      );
    default:
      return null;
  }
}
