import * as Yup from 'yup';

import { isValidCPF } from 'shared/utils/validation';
import { onlyNumbers } from 'shared/utils/formatters';
import { validateBrPhone } from 'features/free-samples/utils';

const requiredMessage = 'Campo obrigatório';
// eslint-disable-next-line no-template-curly-in-string
const maxMessage = 'O campo aceita o máximo de ${max} caracteres';

const RequestSamplesPhysicianFields = Yup.object().shape({
  ufCrm: Yup.string().required(requiredMessage),
  crm: Yup.string().required(requiredMessage),
  fullName: Yup.string().required(requiredMessage),
  cpf: Yup.string()
    .required(requiredMessage)
    .test('isValidCPF', 'CPF inválido', (value) => {
      return value && isValidCPF(onlyNumbers(value));
    }),
  phoneNumber: Yup.string()
    .required(requiredMessage)
    .test('isValidPhone', 'Digite um telefone válido', (value) => {
      return value && validateBrPhone(value);
    }),
});

const RequestSamplesAddressFields = Yup.object().shape({
  address: Yup.string().required(requiredMessage),
  number: Yup.string().trim().max(8, maxMessage).required(requiredMessage),
  neighborhood: Yup.string().required(requiredMessage),
  zipCode: Yup.string()
    .required(requiredMessage)
    .length(9, 'O campo precisa ter um cep válido'),
  city: Yup.string().required(requiredMessage),
  state: Yup.string().required(requiredMessage),
  isClinicAddress: Yup.boolean().test(
    'mustBeTrue',
    'É necessário confirmar o endereço',
    (value) => value,
  ),
});

const RequestSamplesTermsField = Yup.object().shape({
  acceptTermDataProtection: Yup.boolean().test(
    'mustBeTrue',
    'É necessário aceitar o termo de Consentimento',
    (value) => value,
  ),
  acceptTerms: Yup.boolean().test(
    'mustBeTrue',
    'É necessário aceitar o termo de Adesão',
    (value) => value,
  ),
});

export const validationRequestSamples = RequestSamplesPhysicianFields.concat(
  RequestSamplesAddressFields,
).concat(RequestSamplesTermsField);
