import React from 'react';
import { useDispatch } from 'react-redux';

import { Box, Button, MaterialUICore } from '@iclinic/design-system';
import { hideCreateIntegrationModal } from 'features/settings/scheduleOnline/state/actions';

const { CircularProgress } = MaterialUICore;

interface BottomButtonContainer {
  leftLabel: string;
  rightLabel: string;
  rightOnClickHandler?: React.MouseEventHandler;
  submit?: boolean;
  isIntegrated?: boolean;
  isLoading?: boolean;
}

export default ({
  leftLabel,
  rightLabel,
  rightOnClickHandler,
  submit,
  isIntegrated,
  isLoading,
}: BottomButtonContainer) => {
  const dispatch = useDispatch();
  return (
    <Box display="flex" justifyContent="space-between">
      <Button
        color="transparent"
        onClick={() => {
          dispatch(hideCreateIntegrationModal(isIntegrated));
        }}
      >
        {leftLabel}
      </Button>
      <Button
        type={submit ? 'submit' : 'button'}
        onClick={(e) => {
          if (rightOnClickHandler) {
            rightOnClickHandler(e);
          }
        }}
      >
        {isLoading ? (
          <CircularProgress color="secondary" size={18} />
        ) : (
          rightLabel
        )}
      </Button>
    </Box>
  );
};
