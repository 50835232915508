import React from 'react';
import { useDispatch } from 'react-redux';

import { actions } from '../../state';

const { triggerIsOpen } = actions;

const LinkSpeechToText = () => {
  const dispatch = useDispatch();

  return (
    <span onClick={() => dispatch(triggerIsOpen())} aria-hidden="true">
      Transcrição da Anamnese
    </span>
  );
};

export default LinkSpeechToText;
