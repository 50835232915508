import { IntegrationStep } from 'shared/constants/settings';
import { reducerMerge } from 'shared/utils/reducer';
import * as types from './types';

export const initialState = {
  physicians: [],
  scheduleWorkHours: {},
  isFetching: false,
  errors: [],
  settings: {
    clinic: {
      hasOnlineScheduleActive: false,
    },
    settings: {
      insurances: [],
      service_periods: [],
      antecedence_period: 0,
    },
  },
  notification: {
    show: false,
    kind: '',
    title: '',
    description: '',
  },
  integration: {
    showModal: false,
    isLoading: false,
    step: IntegrationStep.EmailValidation,
    email: '',
    invalidPassword: false,
    profile_slug: '',
  },
};

const onlineSchedule = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_STEP_INTEGRATION.ACTION:
      return {
        ...state,
        integration: {
          ...state.integration,
          step: action.payload.step,
        },
      };
    case types.SHOW_CREATE_INTEGRATION_MODAL.ACTION:
      return {
        ...state,
        notification: {
          ...initialState.notification,
        },
        integration: {
          ...initialState.integration,
          showModal: true,
          step: IntegrationStep.EmailValidation,
        },
      };

    case types.HIDE_CREATE_INTEGRATION_MODAL.ACTION:
      return {
        ...state,
        settings: {
          ...state.settings,
          has_schedule_integration: action.payload.isIntegrated,
        },
        integration: {
          ...state.integration,
          showModal: false,
        },
      };
    case types.REMOVE_INTEGRATION_ONLINE_SCHEDULE.ACTION:
    case types.REMOVE_INTEGRATION_ONLINE_SCHEDULE.SUCCESS:
    case types.UPDATE_SETTINGS_ONLINE_SCHEDULE.ACTION:
    case types.UPDATE_SETTINGS_ONLINE_SCHEDULE.SUCCESS:
    case types.UPDATE_SETTINGS_ONLINE_SCHEDULE.FAILURE:
    case types.FETCH_SETTINGS_ONLINE_SCHEDULE.SUCCESS:
    case types.FETCH_SETTINGS_ONLINE_SCHEDULE.FAILURE:
    case types.FETCH_PHYSICIANS.SUCCESS:
    case types.FETCH_PHYSICIANS.FAILURE:
    case types.ON_CHANGE_CHECK_INSURANCE.ACTION:
    case types.ON_CHANGE_ANTECEDENCE_PERIOD.ACTION:
    case types.ON_CHANGE_ALL_CHECK_INSURANCE.ACTION:
    case types.GET_SCHEDULE_HOURS.SUCCESS:
    case types.GET_SCHEDULE_HOURS.FAILURE:
    case types.NOTIFY_BLOCKED_WEEKDAY.ACTION:
    case types.GET_ACTIVE_CLINIC.ACTION:
    case types.INVALIDATE_INSURANCES.ACTION:
    case types.ON_CHANGE_CHECK_DAY.SUCCESS:
    case types.ON_CHANGE_SELECT_HOUR.SUCCESS:
    case types.ADD_WORK_HOUR.SUCCESS:
    case types.REMOVE_WORK_HOUR.SUCCESS:
    case types.VALIDATE_DAYS_SCHEDULE.SUCCESS:
    case types.VALIDATE_DAYS_SCHEDULE.FAILURE:
    case types.SET_IS_LOADING_INTEGRATION.ACTION:
    case types.VALIDATE_EMAIL_ALREADY_USED.ACTION:
    case types.VALIDATE_EMAIL_INTEGRATION.ACTION:
    case types.VALIDATE_EMAIL_INTEGRATION.SUCCESS:
    case types.VALIDATE_EMAIL_INTEGRATION.FAILURE:
    case types.VALIDATE_PASSWORD_INTEGRATION.ACTION:
    case types.VALIDATE_PASSWORD_INTEGRATION.SUCCESS:
    case types.VALIDATE_PASSWORD_INTEGRATION.FAILURE:
    case types.CREATE_PASSWORD_INTEGRATION.ACTION:
    case types.CREATE_PASSWORD_INTEGRATION.SUCCESS:
      return reducerMerge({}, state, action.payload);
    default:
      return state;
  }
};

export default onlineSchedule;
