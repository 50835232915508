import { MaterialUICore, tokens } from '@iclinic/design-system';
import { DEFAULT_DRAWER_CONSTRAINTS } from 'features/video-conference/constants';

const { makeStyles } = MaterialUICore;
const drawerWidth = DEFAULT_DRAWER_CONSTRAINTS.width;

export default makeStyles((theme) => ({
  container: {
    position: 'absolute',
    top: tokens.spacing.sm,
    right: tokens.spacing.sm,
    width: '90px',
    height: '120px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '172px',
      height: '229px',
    },
    [theme.breakpoints.up('md')]: {
      top: 'unset',
      right: 'unset',
      bottom: '126px',
      left: tokens.spacing.md,
      width: '184px',
      height: '120px',
    },
    [theme.breakpoints.down(320)]: {
      display: 'none',
    },
  },
  remoteContainer: {
    bottom: '270px',
  },
  chatWindowOpen: {
    right: `calc(${tokens.spacing.sm} + ${drawerWidth}px)`,
  },
  hideParticipant: {
    display: 'none',
  },
}));
