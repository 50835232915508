import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, spacing } = axiomTokens;

export const Card = styled('div')({
  backgroundColor: color.background[2],
  border: `${border.width.xs} solid ${color.border.subtle}`,
  borderRadius: border.radius.sm,
  padding: spacing[4],

  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[4],
});

export const ActionsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 'auto',
});
