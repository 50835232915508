import { call, put, all, takeLatest } from 'redux-saga/effects';
import { Response } from '@types';

import {
  createSellerBankAccount,
  getBankAccount,
  saveInstallments,
  updateSellerBankAccount,
} from 'features/onlinePayment/services/config';
import {
  SellerBankAccountRawData,
  InstallmentsRequestBody,
} from 'features/onlinePayment/services/config/types';
import { captureException } from 'shared/utils/handlerErrors';
import {
  fetchBanks,
  fetchBanksSuccess,
  fetchBanksFailure,
  saveBankCardInfos,
  saveBankAccountSellerSuccess,
  saveBankAccountSellerFailure,
  saveInstallmentsFailure,
  saveInstallmentsSuccess,
  saveBankCardInfosPending,
  fetchBankAccount,
  fetchBankAccountPending,
  fetchBankAccountSuccess,
  fetchBankAccountFailure,
} from '.';
import {
  normalizeInstallmentsRequestBody,
  normalizeSellerBankAccountRequestBody,
} from './normalizers';
import { SellerBankAccountForm } from '../types';
import { configurationRoutes } from '../constants';
import { redirectTo } from '../register/sagas';

type SaveBankRequestAction = ReturnType<typeof saveBankCardInfos>;
type FetchBankAccountAction = ReturnType<typeof fetchBankAccount>;

export function* fetchBanksWorker() {
  try {
    const { default: banks } = yield call(() => import('./banks'));

    yield put(fetchBanksSuccess(banks));
  } catch (error) {
    yield put(fetchBanksFailure());
    captureException(error);
  }
}

export function* fetchBankAccountWorker({
  payload: bankAccountId,
}: FetchBankAccountAction) {
  if (!bankAccountId) return;
  yield put(fetchBankAccountPending());
  try {
    const { data } = yield call(getBankAccount, bankAccountId);

    yield put(fetchBankAccountSuccess(data));
  } catch (error) {
    yield put(fetchBankAccountFailure());
  }
}

export function* createSellerBankAccountWorker(
  sellerBankAccount: SellerBankAccountForm,
) {
  try {
    const normalizeForm = normalizeSellerBankAccountRequestBody(
      sellerBankAccount,
    );
    const {
      data: sellerBankAccountCreated,
    }: Response<SellerBankAccountRawData> = yield call(
      createSellerBankAccount,
      normalizeForm,
    );
    yield put(saveBankAccountSellerSuccess(sellerBankAccountCreated));
    return sellerBankAccountCreated;
  } catch (error) {
    yield put(saveBankAccountSellerFailure());
    captureException(error);
    return null;
  }
}

export function* updateSellerBankAccountWorker(
  sellerBankAccount: SellerBankAccountForm,
) {
  try {
    const { bankAccountId } = sellerBankAccount;
    if (!bankAccountId) return null;
    const normalizeForm = normalizeSellerBankAccountRequestBody(
      sellerBankAccount,
    );
    const { data }: Response<SellerBankAccountRawData> = yield call(
      updateSellerBankAccount,
      bankAccountId,
      normalizeForm,
    );
    yield put(saveBankAccountSellerSuccess(data));
    return data;
  } catch (error) {
    yield put(saveBankAccountSellerFailure());
    captureException(error);
    return null;
  }
}

export function* saveInstallmentsWorker(
  sellerBankAccount: SellerBankAccountForm,
) {
  try {
    const normalizeForm = normalizeInstallmentsRequestBody(sellerBankAccount);
    const { data }: Response<InstallmentsRequestBody> = yield call(
      saveInstallments,
      sellerBankAccount.sellerId,
      normalizeForm,
    );

    yield put(saveInstallmentsSuccess());
    return data;
  } catch (error) {
    yield put(saveInstallmentsFailure());
    captureException(error);
    return null;
  }
}

export function* saveBankCardInfosWorker({
  payload: sellerBankAccount,
}: SaveBankRequestAction) {
  yield put(saveBankCardInfosPending(sellerBankAccount));
  const [bankAccount, installments]: any[] = yield all([
    sellerBankAccount.bankAccountId
      ? call(updateSellerBankAccountWorker, sellerBankAccount)
      : call(createSellerBankAccountWorker, sellerBankAccount),
    call(saveInstallmentsWorker, sellerBankAccount),
  ]);
  if (bankAccount != null && installments != null)
    yield call(redirectTo, configurationRoutes.success);
}

export default function* watchSaveWorker() {
  yield takeLatest(fetchBanks, fetchBanksWorker);
  yield takeLatest(saveBankCardInfos, saveBankCardInfosWorker);
  yield takeLatest(fetchBankAccount, fetchBankAccountWorker);
}
