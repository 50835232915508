import * as Yup from 'yup';

import { Clinic, Professional } from './types';

export const requiredKeysForClinic = [
  'address',
  'number',
  'neighborhood',
  'city',
  'state',
  'zip_code',
];

export const requiredKeysForProfessional = [
  'council_name',
  'council_number',
  'council_region',
  'cbo',
];

const buildSchema = (keys: string[]) => {
  const shape: Record<string, any> = {};

  keys.forEach((key) => {
    shape[key] = Yup.string().required();
  });

  return Yup.object(shape);
};

const clinicSchema = buildSchema(requiredKeysForClinic);

export const isClinicIncomplete = (clinic: Clinic) =>
  !clinicSchema.isValidSync(clinic);

const professionalSchema = buildSchema(requiredKeysForProfessional).concat(
  Yup.object({
    profile: Yup.object({
      cpf: Yup.string().required(),
    }),
  }),
);

export const isProfessionalIncomplete = (professional: Professional) =>
  !professionalSchema.isValidSync(professional);
