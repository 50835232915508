import React, { useCallback, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Heading,
  MaterialIcons,
  MaterialUICore,
  Switch,
  TextField,
} from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormikProvider, useFormik } from 'formik';
import { useRifm } from 'rifm';

import { phoneFormatter } from 'shared/utils/formatters';
import { MessageContent } from '../../../components';
import {
  MessageByApproveStatusData,
  approveMessagesByStatus,
  defaultMessageReminder,
  tags,
} from '../../../constants';
import {
  getCommonData,
  getPartialUserInfo,
} from '../../../state/common/selectors';
import { getScheduleReminderData } from '../../../state/scheduleReminder/personalization/selectors';
import {
  fetchReminderTemplateMessage,
  submitReminderTemplateMessage,
} from '../../../state/scheduleReminder/personalization';
import { updateReadAlert } from '../../../state/common';
import { TemplateKind } from '../../../services/types';
import {
  FormValues,
  factoryTemplateMessageInitialValues,
} from './form.normalizer';
import { validationSchema } from './validation.schema';
import MessagesSnackbar from './MessagesSnackbar';
import { PreviewMessageModalReminder } from './PreviewMessageModal';
import { ShippingConfiguration } from './ShippingConfiguration';
import * as S from './PersonalizationTabPanel.styles';

const { WhatsApp } = MaterialIcons;
const { MenuItem } = MaterialUICore;

const PersonalizationTabPanelReminder = () => {
  const dispatch = useDispatch();

  const [addPhone, setAddPhone] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [initialTemplate, setInitialTemplate] = useState(
    defaultMessageReminder,
  );
  const [message, setMessage] = useState<MessageByApproveStatusData | null>(
    null,
  );
  const { isFetchingTemplate, templateMessage, isSubmittingTemplate } =
    useSelector(getScheduleReminderData);
  const { physicians } = useSelector(getCommonData);
  const { physicianId, userKind } = useSelector(getPartialUserInfo);

  const onSubmit = (formValues: FormValues) => {
    if (!formValues.physicianId) return;
    dispatch(
      submitReminderTemplateMessage({
        physicianId: formValues.physicianId,
        multiplesScheduleTemplateMessage: formValues,
      }),
    );
  };

  const formik = useFormik<FormValues>({
    initialValues: factoryTemplateMessageInitialValues(),
    onSubmit,
    validationSchema,
  });

  const { values, handleChange, setFieldValue, errors, touched } = formik;

  const onTemplateMessageChangeHandler = (newValue: string) => {
    setFieldValue('content', newValue);
  };

  const onClickAddPhoneHandler = useCallback(() => {
    setAddPhone(true);
  }, []);

  const { value: phoneValue, onChange: onChangePhone } = useRifm({
    value: values.contactPhone as string,
    onChange: (value) => setFieldValue('contactPhone', value),
    format: phoneFormatter,
  });

  const togglePreview = useCallback(() => {
    setPreviewOpen((prev) => !prev);
  }, []);

  const onCloseAlert = () => {
    setMessage(null);

    if (values.physicianId) {
      dispatch(
        updateReadAlert({
          kind: TemplateKind.REMINDER,
          physicianId: values.physicianId,
        }),
      );
    }
  };

  useEffect(() => {
    if (values.physicianId)
      dispatch(fetchReminderTemplateMessage(values.physicianId));
  }, [dispatch, values.physicianId]);

  useEffect(() => {
    if (templateMessage) {
      setFieldValue('isActive', templateMessage.is_active);

      if (templateMessage.content?.template) {
        setFieldValue('content', templateMessage.content.template);
        setInitialTemplate(templateMessage.content.template);
      }

      if (templateMessage.content?.approve_status) {
        const messageByStatus =
          approveMessagesByStatus[templateMessage.content.approve_status];
        setMessage(messageByStatus);
      }

      if (templateMessage.schedules.length) {
        setFieldValue(
          'schedules',
          templateMessage.schedules.map(({ id, days_advance, time_send }) => ({
            id,
            daysAdvance: days_advance,
            timeSend: time_send,
          })),
        );
      }

      if (templateMessage.contact_phone) {
        setFieldValue(
          'contactPhone',
          phoneFormatter(templateMessage.contact_phone),
        );
        setAddPhone(true);
      }
    }
  }, [setFieldValue, templateMessage]);

  useEffect(() => {
    if (physicians?.length && userKind === 'r') {
      const [physician] = physicians;
      setFieldValue('physicianId', physician.id);
    } else if (physicianId) {
      setFieldValue('physicianId', physicianId);
    }
  }, [physicianId, physicians, setFieldValue, userKind]);

  if (isFetchingTemplate) {
    return (
      <S.CardWrapper>
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      </S.CardWrapper>
    );
  }
  const filteredTags = tags.filter((tag) => tag !== '#linkdeconfirmação');

  return (
    <>
      <S.PanelWrapper>
        {message && (
          <Alert
            message={message.message}
            severity={message.isError ? 'error' : 'info'}
            icon={message.icon}
            action={
              message.isError ? (
                <Button variant="text" size="large" onClick={onCloseAlert}>
                  Fechar
                </Button>
              ) : undefined
            }
          />
        )}

        <FormikProvider value={formik}>
          <Form>
            <S.CardWrapper>
              {userKind === 'r' && physicians?.length ? (
                <S.PhysicianSelect
                  name="physicianId"
                  label="Profissionais de saúde"
                  value={values.physicianId}
                  onChange={handleChange}
                >
                  {physicians.map(({ id, profile_name }) => (
                    <MenuItem key={id.toString()} value={id}>
                      {profile_name}
                    </MenuItem>
                  ))}
                </S.PhysicianSelect>
              ) : null}

              <S.ContentMessageWrapper>
                <S.TitleAndSubtitleWrapper>
                  <Heading variant="sm">Conteúdo da mensagem</Heading>

                  <S.ContentSubtitle variant="xs">
                    Utilize as <em>tags</em> automáticas para personalizar o
                    conteúdo da mensagem como: nome do paciente, data da agenda,
                    procedimento.
                    <br />
                    As <em>tags</em> serão substituídas no momento do envio da
                    mensagem pelos dados reais da agenda do paciente.
                  </S.ContentSubtitle>
                </S.TitleAndSubtitleWrapper>

                <MessageContent
                  key={initialTemplate}
                  initialTemplate={initialTemplate}
                  currentTemplate={values.content}
                  tags={filteredTags}
                  onChange={onTemplateMessageChangeHandler}
                  onPreviewClick={togglePreview}
                />
              </S.ContentMessageWrapper>
              <S.PhoneWrapper>
                <S.TitleAndSubtitleWrapper>
                  <Heading variant="sm">Telefone para contato</Heading>

                  <S.ContentSubtitle variant="xs">
                    Insira o número que o paciente deve utilizar para conversar
                    com a clínica no Whatsapp, em casos de dúvidas ou
                    reagendamento.
                  </S.ContentSubtitle>
                </S.TitleAndSubtitleWrapper>

                {addPhone ? (
                  <TextField
                    id="contactPhone"
                    name="contactPhone"
                    label="Whatsapp da clínica"
                    value={phoneValue}
                    onChange={onChangePhone}
                    error={!!(errors.contactPhone && touched.contactPhone)}
                    helperText={touched.contactPhone && errors.contactPhone}
                    InputProps={{
                      endAdornment: <WhatsApp />,
                    }}
                  />
                ) : (
                  <Button
                    size="large"
                    variant="outlined"
                    onClick={onClickAddPhoneHandler}
                  >
                    Adicionar Whatsapp da clínica
                  </Button>
                )}
              </S.PhoneWrapper>
              <S.ConfirmTriggersWrapper>
                <S.TitleAndSubtitleWrapper>
                  <Heading variant="sm">Configurações de envio</Heading>

                  <S.ContentSubtitle variant="xs">
                    Defina os critérios de antecedência de envios automáticos
                    das mensagens de confirmação de agenda. Para agendamentos às
                    segundas-feiras, as mensagens serão enviadas sempre na
                    sexta-feira anterior.
                  </S.ContentSubtitle>
                </S.TitleAndSubtitleWrapper>

                <ShippingConfiguration />

                <div>
                  <Switch
                    id="isActive"
                    name="isActive"
                    defaultChecked
                    checked={values.isActive}
                    onChange={handleChange}
                    label="Envio automático com base na antecedência programada."
                  />

                  <S.ContentSubtitle variant="xs">
                    <em>
                      *Desabilitando o envio automático as comunicações não
                      serão enviadas e o seu pacote não será consumido.
                    </em>
                  </S.ContentSubtitle>
                </div>

                <S.ButtonsWrapper>
                  <Button disabled={isSubmittingTemplate} color="transparent">
                    Cancelar
                  </Button>

                  <Button isLoading={isSubmittingTemplate} type="submit">
                    Salvar
                  </Button>
                </S.ButtonsWrapper>
              </S.ConfirmTriggersWrapper>
            </S.CardWrapper>
          </Form>
        </FormikProvider>
      </S.PanelWrapper>

      <MessagesSnackbar />

      <PreviewMessageModalReminder
        physicianId={values.physicianId}
        open={previewOpen}
        onClose={togglePreview}
        content={values.content}
        contactPhone={values.contactPhone}
      />
    </>
  );
};

export { PersonalizationTabPanelReminder };
