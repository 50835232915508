import React from 'react';
import { useFormikContext } from 'formik';
import { useRifm } from 'rifm';
import { useSelector } from 'react-redux';

import {
  MaterialIcons,
  Grid,
  TextField,
  DatePicker,
} from '@iclinic/design-system';
import councils from 'shared/constants/councils';
import BRAZIL_STATES_INITIALS from 'shared/constants/brStates';
import { FILE_EXTENSIONS } from 'shared/utils/file';
import { isValidDate } from 'shared/utils/date';
import {
  phoneFormatter,
  cnpjFormatter,
  limitNumbers,
} from 'shared/utils/formatters';
import { trackSignupIClinicPayForm } from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { SellerForm } from 'features/onlinePayment/state/config/types';
import {
  isSellerAlreadyCreated,
  getUserInfo,
} from 'features/onlinePayment/state/config/register/selectors';
import useStyles from './CardProfile.styles';
import Upload, { UploadData } from '../Upload';

const { JPEG, JPG, PNG } = FILE_EXTENSIONS;
const pictureExtensions = [JPEG, JPG, PNG];

const { Person } = MaterialIcons;

/** TODO fix this */
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function CardProfilePJ(): JSX.Element {
  const classes = useStyles();
  const disabled = useSelector(isSellerAlreadyCreated);
  const userData = useSelector(getUserInfo);

  const {
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext<SellerForm>();

  const { value: phone, onChange: onChangePhone } = useRifm({
    value: values.phone,
    onChange: (value: string) => setFieldValue('phone', value),
    format: phoneFormatter,
  });

  const { value: councilNumber, onChange: onChangeCouncilNumber } = useRifm({
    value: values.councilNumber,
    onChange: (value: string) => setFieldValue('councilNumber', value),
    format: (str) => limitNumbers(str, 10),
  });

  const { value: businessCnpj, onChange: onChangeCnpj } = useRifm({
    value: values.businessCnpj,
    onChange: (value: string) => setFieldValue('businessCnpj', value),
    format: cnpjFormatter,
  });

  const handleUploadFile = (file: UploadData) => {
    setFieldValue('picture', file.base64);
    trackSignupIClinicPayForm({
      userData,
      flowType: 'legal_person',
      stepName: 'user_profile',
      stepNum: 1,
      stepValue: file.file.name,
    });
  };

  const handlebusinessOpeningDate = (date: Date | null) => {
    setFieldValue('businessOpeningDate', date);
    if (!date) return;
    if (!isValidDate(date)) return;

    trackSignupIClinicPayForm({
      userData,
      flowType: 'legal_person',
      stepName: 'user_open_clinic',
      stepNum: 4,
      stepValue: date.toISOString(),
    });
  };

  const handleBlur = (stepName: string, stepNum: number, stepValue: string) => {
    trackSignupIClinicPayForm({
      userData,
      flowType: 'legal_person',
      stepName,
      stepNum,
      stepValue,
    });
  };

  return (
    <Grid direction="column" spacing={3} container>
      <Grid item>
        <Upload
          icon={<Person className={classes.iconProfileImage} />}
          value={values.picture || values.initialPicture}
          placeholder="Adicione uma foto ao seu perfil"
          name="picture"
          onChange={handleUploadFile}
          extensions={pictureExtensions}
          error={!!(errors.picture && touched.picture)}
          helperText={touched.picture && errors.picture}
          disabled={disabled}
        />
      </Grid>
      <Grid direction="column" spacing={3} item container>
        <Grid item xs={12} sm={12}>
          <TextField
            id="businessName"
            name="businessName"
            label="Razão social"
            onChange={handleChange}
            onBlur={() => handleBlur('user_corp_name', 2, values.businessName)}
            value={values.businessName}
            error={!!(errors.businessName && touched.businessName)}
            helperText={touched.businessName && errors.businessName}
            disabled={disabled}
            fullWidth
          />
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={8}>
            <TextField
              id="businessDescription"
              name="businessDescription"
              label="Nome fantasia"
              onChange={handleChange}
              onBlur={() =>
                handleBlur('user_fantasy_name', 3, values.businessDescription)
              }
              value={values.businessDescription}
              error={
                !!(errors.businessDescription && touched.businessDescription)
              }
              helperText={
                touched.businessDescription && errors.businessDescription
              }
              disabled={disabled}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DatePicker
              id="businessOpeningDate"
              name="businessOpeningDate"
              label="Data de abertura da empresa"
              value={values.businessOpeningDate}
              onChange={handlebusinessOpeningDate}
              error={
                !!(errors.businessOpeningDate && touched.businessOpeningDate)
              }
              helperText={
                touched.businessOpeningDate && errors.businessOpeningDate
              }
              disabled={disabled}
              allowKeyboard
            />
          </Grid>
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={6}>
            <TextField
              id="email"
              name="email"
              label="E-mail"
              onChange={handleChange}
              onBlur={() => handleBlur('user_email', 5, btoa(values.email))}
              value={values.email}
              error={!!(errors.email && touched.email)}
              helperText={touched.email && errors.email}
              disabled={disabled}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="phone"
              name="phone"
              label="Telefone da clínica"
              placeholder="Somente números"
              onChange={onChangePhone}
              onBlur={() => handleBlur('user_phone', 6, phone)}
              value={phone}
              error={!!(errors.phone && touched.phone)}
              helperText={
                (touched.phone && errors.phone) || 'Sem traços ou separações'
              }
              disabled={disabled}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={4}>
            <TextField
              id="councilName"
              name="councilName"
              label="Conselho"
              placeholder="Escolha"
              onChange={handleChange}
              value={values.councilName}
              error={!!(errors.councilName && touched.councilName)}
              helperText={touched.councilName && errors.councilName}
              disabled={disabled}
              select
              fullWidth
            >
              <option value="">Escolha</option>
              {councils.map((council) => (
                <option key={council.id} value={council.label}>
                  {council.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="councilNumber"
              name="councilNumber"
              label="Registro"
              placeholder="Somente o nº"
              onChange={onChangeCouncilNumber}
              value={councilNumber}
              error={!!(errors.councilNumber && touched.councilNumber)}
              helperText={touched.councilNumber && errors.councilNumber}
              disabled={disabled}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="councilRegion"
              name="councilRegion"
              label="UF"
              placeholder="Escolha"
              onChange={handleChange}
              value={values.councilRegion}
              error={!!(errors.councilRegion && touched.councilRegion)}
              helperText={touched.councilRegion && errors.councilRegion}
              disabled={disabled}
              select
              fullWidth
            >
              <option value="">Escolha</option>
              {BRAZIL_STATES_INITIALS.map((state) => (
                <option value={state} key={state}>
                  {state}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="businessCnpj"
            name="businessCnpj"
            label="CNPJ"
            placeholder="Somente números"
            onChange={onChangeCnpj}
            onBlur={() => handleBlur('user_cnpj', 7, '')}
            value={businessCnpj}
            error={!!(errors.businessCnpj && touched.businessCnpj)}
            helperText={
              (touched.businessCnpj && errors.businessCnpj) ||
              'Esse dado não é mostrado aos pacientes'
            }
            disabled={disabled}
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
