// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import { Title, Content } from '@iclinic/iclinic-ui';
import Upgrade from 'features/upgrade/containers/Upgrade';
import UpgradeAddon from 'features/upgradeAddon/containers/UpgradeAddon';
import Video from 'features/video/containers/Video';
import Footer from './Footer';
// Styles
import ss from './AppContent.scss';

/**
 * Application main content wrapper
 * with optional sidebar
 */

const AppContent = ({
  children,
  containerClassName,
  hideSidebar,
  hideFooter,
  sidebarTitle,
  sidebarTitleBorder,
  sidebarContent,
  contentTitle,
  contentTitleBorder,
}) => (
  <div className={containerClassName}>
    {!hideSidebar && (
      <div className={ss.sidebar}>
        <Content
          titleElement={<Title size="lg">{sidebarTitle}</Title>}
          withBorder={contentTitleBorder}
        >
          {sidebarContent}
        </Content>
      </div>
    )}
    <div className={ss.content}>
      <div className={ss.inner}>
        <Content
          titleElement={<Title size="lg">{contentTitle}</Title>}
          withBorder={sidebarTitleBorder}
        >
          {children}
        </Content>
      </div>
      {!hideFooter && <Footer />}
    </div>
    <Video />
    <Upgrade />
    <UpgradeAddon />
  </div>
);
AppContent.defaultProps = {
  containerClassName: ss.container,
};
AppContent.propTypes = {
  children: PropTypes.node.isRequired,
  hideSidebar: PropTypes.bool,
  hideFooter: PropTypes.bool,
  sidebarTitle: PropTypes.node,
  sidebarTitleBorder: PropTypes.bool,
  sidebarContent: PropTypes.node,
  contentTitle: PropTypes.node,
  contentTitleBorder: PropTypes.bool,
  containerClassName: PropTypes.string,
};

AppContent.defaultProps = {
  hideSidebar: false,
  hideFooter: false,
  sidebarTitle: null,
  sidebarTitleBorder: false,
  sidebarContent: undefined,
  contentTitle: undefined,
  contentTitleBorder: false,
};

export default AppContent;
