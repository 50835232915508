import { Grid, MaterialIcons, MaterialUICore } from '@iclinic/design-system';
import { useFormikContext } from 'formik';
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import DatePickerSADT from 'features/tissInvoicing/components/sadt/DatePickerSADT';
import SelectFieldSADT from 'features/tissInvoicing/components/sadt/SelectFieldSADT';
import TextFieldSADT from 'features/tissInvoicing/components/sadt/TextFieldSADT';
import CollapsibleFormSection from 'features/tissInvoicing/components/ui/CollapsibleFormSection';
import HiredSolicitantName from 'features/tissInvoicing/containers/components/CommonAutocompletes/HiredSolicitantName';
import ProfessionalSolicitantCBO from 'features/tissInvoicing/containers/components/CommonAutocompletes/ProfessionalSolicitantCBO';
import ProfessionalSolicitantName from 'features/tissInvoicing/containers/components/CommonAutocompletes/ProfessionalSolicitantName';
import useVersionCompare from 'features/tissInvoicing/hooks/useVersionCompare';
import { DocumentTypeOptions } from 'features/tissInvoicing/state/constants';
import { getDomainTablesOptions } from 'features/tissInvoicing/state/domainTables/selectors';
import { GuideSADTForm } from 'features/tissInvoicing/types';

const { CardTravel } = MaterialIcons;
const { MenuItem } = MaterialUICore;

interface SolicitorSectionProps {
  insuranceId: number | undefined;
}

const SolicitorSection = (
  { insuranceId }: SolicitorSectionProps,
  ref: React.Ref<HTMLButtonElement>,
): JSX.Element => {
  const { values } = useFormikContext<GuideSADTForm>();

  const { states, councils, serviceCharacters, specialCoverages } = useSelector(
    getDomainTablesOptions,
  );

  const { isGreaterThan } = useVersionCompare(values.version_id);
  const versionTissIsGreaterThan3 = isGreaterThan(3);

  return (
    <CollapsibleFormSection
      sectionTitle="Solicitante e solicitação"
      sectionIcon={<CardTravel />}
      sectionIdentifier="solicitor_data"
      ref={ref}
    >
      <Grid container item spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={7}>
          <HiredSolicitantName insuranceId={insuranceId} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <SelectFieldSADT
            label="13 - Tipo"
            identifier="hired_solicitant_code_type"
          >
            {DocumentTypeOptions.map((docTypeOption) => (
              <MenuItem value={docTypeOption.value} key={docTypeOption.value}>
                {docTypeOption.label}
              </MenuItem>
            ))}
          </SelectFieldSADT>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextFieldSADT
            label="13 - Código na operadora"
            identifier="hired_solicitant_code"
            maxLength={14}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ProfessionalSolicitantName />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={versionTissIsGreaterThan3 ? 6 : 12}
          lg={versionTissIsGreaterThan3 ? 3 : 6}
        >
          <SelectFieldSADT
            label="16 - Conselho profissional"
            identifier="professional_solicitant_council"
          >
            {councils.map((council) => (
              <MenuItem value={council.term_short} key={council.id}>
                {council.term_short}
              </MenuItem>
            ))}
          </SelectFieldSADT>
        </Grid>

        {versionTissIsGreaterThan3 && (
          <Grid item xs={12} sm={12} md={6} lg={3}>
            <SelectFieldSADT
              label="90 - Cobertura especial"
              identifier="special_coverage"
              value={values.special_coverage}
            >
              {specialCoverages.map((specialCoverage) => (
                <MenuItem
                  value={specialCoverage.id === 0 ? '' : specialCoverage.id}
                  key={specialCoverage.id}
                >
                  {specialCoverage.term}
                </MenuItem>
              ))}
            </SelectFieldSADT>
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextFieldSADT
            label="17 - Nº no conselho"
            identifier="professional_solicitant_council_number"
            maxLength={15}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <SelectFieldSADT
            label="18 - UF"
            identifier="professional_solicitant_council_state"
          >
            {states.map((state) => (
              <MenuItem value={state.uf} key={state.id}>
                {state.term}
              </MenuItem>
            ))}
          </SelectFieldSADT>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={5}>
          <ProfessionalSolicitantCBO />
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={3}>
          <SelectFieldSADT
            label="21 - Caráter do atendimento"
            identifier="solicitor_service_type"
          >
            {serviceCharacters.map((serviceCharacter) => (
              <MenuItem value={serviceCharacter.id} key={serviceCharacter.id}>
                {serviceCharacter.term}
              </MenuItem>
            ))}
          </SelectFieldSADT>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <DatePickerSADT
            label="22 - Data da solicitação"
            identifier="request_date"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <TextFieldSADT
            label="23 - Indicação clínica"
            identifier="clinical_indication"
            maxLength={150}
          />
        </Grid>
      </Grid>
    </CollapsibleFormSection>
  );
};

export default forwardRef(SolicitorSection);
