export const INIT = '@webapp/onlinePayment/checkout/INIT';

export const DECODE_TOKEN_ACTION =
  '@webapp/onlinePayment/checkout/DECODE_TOKEN';
export const DECODE_TOKEN_SUCCESS =
  '@webapp/onlinePayment/checkout/DECODE_TOKEN_SUCCESS';
export const DECODE_TOKEN_FAILURE =
  '@webapp/onlinePayment/checkout/DECODE_TOKEN_FAILURE';

export const FETCH_PHYSICIAN_INFOS_ACTION =
  '@webapp/onlinePayment/checkout/FETCH_PHYSICIAN_INFOS';
export const FETCH_PHYSICIAN_INFOS_SUCCESS =
  '@webapp/onlinePayment/checkout/FETCH_PHYSICIAN_INFOS_SUCCESS';
export const FETCH_PHYSICIAN_INFOS_FAILURE =
  '@webapp/onlinePayment/checkout/FETCH_PHYSICIAN_INFOS_FAILURE';

export const FETCH_EVENT_INFOS_ACTION =
  '@webapp/onlinePayment/checkout/FETCH_EVENT_INFOS';
export const FETCH_EVENT_INFOS_SUCCESS =
  '@webapp/onlinePayment/checkout/FETCH_EVENT_INFOS_SUCCESS';
export const FETCH_EVENT_INFOS_FAILURE =
  '@webapp/onlinePayment/checkout/FETCH_EVENT_INFOS_FAILURE';

export const FETCH_BILL_INFOS_ACTION =
  '@webapp/onlinePayment/checkout/FETCH_BILL_INFOS';
export const FETCH_BILL_INFOS_SUCCESS =
  '@webapp/onlinePayment/checkout/FETCH_BILL_INFOS_SUCCESS';
export const FETCH_BILL_INFOS_FAILURE =
  '@webapp/onlinePayment/checkout/FETCH_BILL_INFOS_FAILURE';

export const SELECT_PAYMENT_TYPE =
  '@webapp/onlinePayment/checkout/SELECT_PAYMENT_TYPE';

export const PIX_TIMER_RUNNING =
  '@webapp/onlinePayment/checkout/PIX_TIMER_RUNNING';
