// Internal
import appTypes from 'shared/utils/actions';
import {
  SUCCESS,
  FAILURE,
} from 'shared/constants/actions';

const imageComparison = appTypes.defineAction('imageComparison');

export const OPEN_MODAL = imageComparison.defineAction('OPEN_MODAL', [SUCCESS, FAILURE]);
export const CLOSE_MODAL = imageComparison.defineAction('CLOSE_MODAL', [SUCCESS, FAILURE]);
export const SELECT_IMAGE = imageComparison.defineAction('SELECT_IMAGE', [SUCCESS, FAILURE]);
export const GET_IMAGES = imageComparison.defineAction('GET_IMAGES', [SUCCESS, FAILURE]);
export const SET_MODE = imageComparison.defineAction('SET_MODE', [SUCCESS, FAILURE]);
export const SAVE_COMPARE = imageComparison.defineAction('SAVE_COMPARE', [SUCCESS, FAILURE]);
export const CREATE_EVENT_ADDITIONAL = imageComparison.defineAction('CREATE_EVENT_ADDITIONAL', [SUCCESS, FAILURE]);
export const CANVAS_IMAGE = imageComparison.defineAction('CANVAS_IMAGE', [SUCCESS, FAILURE]);
export const FETCH_NOTIFICATION = imageComparison.defineAction('FETCH_NOTIFICATION', [SUCCESS]);
export const RELOAD = imageComparison.defineAction('RELOAD', [SUCCESS, FAILURE]);
