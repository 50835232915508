import {
  Body,
  Box,
  Button,
  Grid,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';
import { FieldArray, useFormikContext } from 'formik';
import React, { forwardRef } from 'react';

import CollapsibleFormSection from 'features/tissInvoicing/components/ui/CollapsibleFormSection';
import { GuideSADTForm } from 'features/tissInvoicing/types';
import Session from './Session';
import * as Styles from './Session/styles';
import { emptySessions } from './validationSchema';

const { PlaylistAdd, Add } = MaterialIcons;
const { useTheme, useMediaQuery } = MaterialUICore;

interface SessionsSectionProps {}

const SessionsSection = (
  _: SessionsSectionProps,
  ref: React.Ref<HTMLButtonElement>,
): JSX.Element => {
  const {
    values: { sessions },
  } = useFormikContext<GuideSADTForm>();

  const theme = useTheme();
  const isNotSmall = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <CollapsibleFormSection
      sectionTitle="Sessões"
      sectionIcon={<PlaylistAdd />}
      sectionIdentifier="sessions_data"
      ref={ref}
    >
      <Styles.Header>
        <Body variant="sm">Adicione manualmente as sessões necessárias</Body>

        {sessions?.length > 0 && (
          <Grid container item spacing={2}>
            <Grid item xs={2} sm={2} md={4} lg={4}>
              <Styles.SubHeader variant="sm">Sessão</Styles.SubHeader>
            </Grid>

            {isNotSmall && (
              <Grid item sm={4} md={2} lg={3}>
                <Styles.SubHeader variant="sm">Dia</Styles.SubHeader>
              </Grid>
            )}

            <Grid item xs={8} sm={5} md={3} lg={3}>
              <Styles.SubHeader variant="sm">Agendamento</Styles.SubHeader>
            </Grid>

            <Grid item xs={2} sm={2} md={2} lg={2} />
          </Grid>
        )}
      </Styles.Header>

      <FieldArray
        name="sessions"
        render={(arrayHelpers) => (
          <>
            {sessions.map((session, index) => (
              <Session
                session={session}
                index={index}
                key={session?.id || index}
                removeSession={(item) => {
                  arrayHelpers.remove(item);
                }}
              />
            ))}
            {sessions?.length < 10 && (
              <Box display="flex" justifyContent="flex-end" p={1}>
                <Button
                  color="secondary"
                  size="small"
                  startIcon={<Add />}
                  onClick={() => arrayHelpers.push(emptySessions)}
                >
                  Sessão
                </Button>
              </Box>
            )}
          </>
        )}
      />
    </CollapsibleFormSection>
  );
};

export default forwardRef(SessionsSection);
