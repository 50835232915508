import React from 'react';
import classnames from 'classnames/bind';

import { tokens, Button, MaterialUICore } from "@iclinic/design-system";
import styles from './CampaignTopBar.scss';

const { makeStyles } = MaterialUICore;
const cx = classnames.bind(styles);

const useStyles = makeStyles(() => ({
  subscribe: {
    marginLeft: tokens.spacing.sm
  },
  defaultBar: {
    backgroundColor: tokens.color.feedback.success.light
  },
  bannerText: {
    color: tokens.color.feedback.success.dark
  }
}));

type CampaignTopBarProps = {
  classes?: string;
  isDefault?: boolean;
  daysLeft?: number;
  link?: string;
  trialText?: string;
  imageUrlDesktop?: string;
  imageUrlLarge?: string;
  imageUrlTablet?: string;
  imageUrlSmall?: string;
  altText?: string;
};

export default function CampaignTopBar(
  {
    classes = '',
    isDefault = true,
    daysLeft = 15,
    link = '/configuracoes/assinatura/',
    trialText = 'Bem vindo ao iClinic. Seu período de avaliação termina em ',
    imageUrlDesktop = '',
    imageUrlTablet = '',
    imageUrlLarge = '',
    imageUrlSmall = '',
    altText = 'Imagem promocional',
  }: CampaignTopBarProps,
): JSX.Element {
  const dsClasses = useStyles();

  return (
    <>
      {isDefault && (
        <div className={cx(styles['default-bar'], classes, dsClasses.defaultBar)}>
          <p className={cx(styles['banner-text'], dsClasses.bannerText)}>
            {trialText}
            {daysLeft}
            {' '}
            {daysLeft > 1 ? 'dias' : 'dia'}
          </p>
          <a href={link} className={dsClasses.subscribe}>
            <Button type="button" size="small">Assine agora</Button>
          </a>
        </div>
      )}
      { !isDefault && (
        <a href={link} className={cx(styles['campaign-bar'], classes)}>
          <img alt={altText} src={imageUrlDesktop} className={styles['bar-desktop']} />
          <img alt={altText} src={imageUrlTablet} className={styles['bar-tablet']} />
          <img alt={altText} src={imageUrlLarge} className={styles['bar-large']} />
          <img alt={altText} src={imageUrlSmall} className={styles['bar-small']} />
        </a>
      )}
    </>
  );
}
