import { trackCustomEvent } from 'shared/utils/trackEvents';
import { UserInfo } from './state/request-samples/types';

type StepData = {
  userInfo: UserInfo;
  stepName: string;
  stepNum?: number | null;
  failureReason?: string | null;
  automatic?: boolean;
};

export const REQUEST_SAMPLES_FORM_SCHEMA =
  'iglu:br.com.iclinic/free-samples-form/jsonschema/1-0-0';
export const USER_CONTEXT_SCHEMA = 'iglu:br.com.iclinic/user/jsonschema/1-0-0';
export const SUBSCRIPTION_CONTEXT_SCHEMA =
  'iglu:br.com.iclinic/subscription/jsonschema/1-0-0';

export function trackRequestSamplesFormStep({
  userInfo,
  stepName,
  stepNum = null,
  failureReason = null,
  automatic = false,
}: StepData) {
  const userContext = {
    schema: USER_CONTEXT_SCHEMA,
    data: {
      user_id: userInfo.profileId,
      user_is_subscriber: userInfo.isSubscriber,
    },
  };

  const subscriptionContext = {
    schema: SUBSCRIPTION_CONTEXT_SCHEMA,
    data: {
      user_id: userInfo.profileId,
      subscription_id: userInfo.subscriptionId,
    },
  };

  trackCustomEvent(
    REQUEST_SAMPLES_FORM_SCHEMA,
    {
      step_name: stepName,
      step_num: stepNum,
      step_value: null,
      failure_reason: failureReason,
      automatic,
      pharmaceutica_box: 'Cellera Farma',
    },
    [userContext, subscriptionContext],
  );
}
