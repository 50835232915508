import React from 'react';

import Button from 'ui/new/button';
import { BUTTON, COLOR } from 'ui/new/button/constants';

type EditProps = {
  submitForm(): void;
  deleteForm(): void;
  isFetching: boolean;
};

export default function Edit({
  submitForm,
  deleteForm,
  isFetching,
}: EditProps) {
  return (
    <>
      <Button
        type={BUTTON.Light}
        onClick={deleteForm}
        loading={isFetching}
        disabled={isFetching}
      >
        Excluir
      </Button>
      <Button
        type={BUTTON.Success}
        onClick={submitForm}
        loading={isFetching}
        color={COLOR.White}
        disabled={isFetching}
      >
        Salvar
      </Button>
    </>
  );
}
