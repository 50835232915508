import React from 'react';
import { LocalAudioTrack } from 'twilio-video';

import {
  MaterialUICore,
  Body,
  InputAdornment,
  MaterialIcons,
  Select,
} from '@iclinic/design-system';
import {
  SELECTED_AUDIO_INPUT_KEY,
  selectDevices,
} from 'features/video-conference/constants';
import useDevices from 'features/video-conference/hooks/useDevices';
import useMediaStreamTrack from 'features/video-conference/hooks/useMediaStreamTrack';
import { useConference } from 'features/video-conference/state/conferenceContext';
import useStyles from '../styles';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';

const { MenuItem } = MaterialUICore;
const { Mic } = MaterialIcons;

interface AudioInputListProps {
  location?: string;
}

export default function AudioInputList({ location }: AudioInputListProps) {
  const classes = useStyles({});
  const { audioInputDevices } = useDevices();
  const { localTracks, isPhysician, conferenceId } = useConference();
  const { localParticipant, remoteParticipant } = useParticipantContext();

  const localAudioTrack = localTracks.find(
    (track) => track.kind === 'audio',
  ) as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'telemedicine_settings_update_device',
      buttonLocation: location,
      additionalProperties: 'audio_input',
    });
  }

  return (
    <div>
      {audioInputDevices.length > 0 ? (
        <Select
          onChange={(e) => replaceTrack(e.target.value as string)}
          value={localAudioInputDeviceId || ''}
          data-testid="select"
          InputProps={{
            id: 'audio-input-list',
            startAdornment: (
              <InputAdornment position="start">
                <Mic className={classes.iconColor} />
              </InputAdornment>
            ),
          }}
          label={selectDevices.AUDIO_INPUT}
        >
          {audioInputDevices.map((device) => (
            <MenuItem value={device.deviceId} key={device.deviceId}>
              {device.label}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Body variant="xs" bold>
          {selectDevices.AUDIO_NOT_FOUND}
        </Body>
      )}
    </div>
  );
}
