import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, DialogHeader, MaterialUICore } from '@iclinic/design-system';
import { Form, useFormik, FormikProvider } from 'formik';
import Turnstile from 'react-turnstile';

import {
  triggerSubscription,
  updateCurrentSubscriptionStep,
  updateSelectedPhysicians,
  updateSubscriptionStepMapper,
} from '../../state/plans';
import {
  getCurrentSubscriptionStep,
  getRecurrence,
  isSubscribing,
} from '../../state/plans/selectors';
import { StepTypes, SubscriptionFormData } from '../../state/plans/types';
import { PaymentSummary } from '../PaymentSummary';
import { validationForm } from './validations';
import { initialFormData } from './form.normalizer';
import PaymentForm from './PaymentForm';
import ErrorsMessage from './ErrorsMessage';
import * as S from './ModalPaymentForm.styles';

const { useMediaQuery, useTheme } = MaterialUICore;

const ModalPaymentForm = () => {
  const dispatch = useDispatch();
  const isAnnual = useSelector(getRecurrence);
  const currentStep = useSelector(getCurrentSubscriptionStep);
  const isLoading = useSelector(isSubscribing);

  const onSubmit = (formData: SubscriptionFormData) => {
    dispatch(triggerSubscription(formData));
  };

  const formik = useFormik({
    initialValues: initialFormData,
    validationSchema: validationForm,
    onSubmit,
  });

  const handleClose = () => {
    dispatch(updateSelectedPhysicians());
    dispatch(updateSubscriptionStepMapper());
    dispatch(updateCurrentSubscriptionStep());
    formik.resetForm();
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isOpen = currentStep === StepTypes.PAYMENT;

  return (
    <FormikProvider value={formik}>
      <Form>
        <S.PaymentModal
          open={isOpen}
          maxWidth="xl"
          onClose={handleClose}
          fullScreen={isMobile}
          scroll="paper"
        >
          <DialogHeader title="Dados de pagamento" onClose={handleClose} />

          <S.DialogContent>
            <ErrorsMessage />

            <PaymentForm />

            <S.SummaryWrapper>
              <PaymentSummary isAnnual={isAnnual} dividersColor="secondary" />
            </S.SummaryWrapper>
            <Turnstile
              sitekey={process.env.CF_TURNSTILE_SITE_KEY || ''}
              onVerify={(token) =>
                formik.setFieldValue('turnstileToken', token)
              }
              retryInterval={300}
              theme="light"
              refreshExpired="auto"
            />
          </S.DialogContent>
          <S.DialogActions>
            <Button
              disabled={isLoading}
              color="transparent"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => formik.handleSubmit()}
            >
              Completar assinatura
            </Button>
          </S.DialogActions>
        </S.PaymentModal>
      </Form>
    </FormikProvider>
  );
};

export default ModalPaymentForm;
