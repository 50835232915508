// External
import React from 'react';

// Internal
import Column from './Column';
import Head from './Head';
import Row from './Row';
import Body from './Body';
import Item from './Item';
import style from './Table.scss';

type TableProps = {
  children: React.ReactNode
};

function Table({
  children,
}: TableProps): JSX.Element {
  return (
    <table className={style.table}>
      {children}
    </table>
  );
}

Table.Column = Column;
Table.Body = Body;
Table.Head = Head;
Table.Row = Row;
Table.Item = Item;

export default Table;
