import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const { border } = tokens;

type Props = {
  shouldRotate: boolean | undefined;
  isContain: boolean;
};

export default makeStyles<{}, Props>(() => ({
  videoPlayer: {
    transform: ({ shouldRotate }) => (shouldRotate ? 'scaleX(-1)' : ''),
    objectFit: ({ isContain }) => (isContain ? 'contain' : 'cover'),
    height: '100%',
    width: ({ isContain }) => (!isContain ? '100%' : 'inherit'),
    borderRadius: border.radius.md,
    zIndex: 0,
  },
}));
