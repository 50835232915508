import { createSelector } from 'reselect';

import { RequestStatus } from 'shared/constants/State';
import { IStoreState } from 'state/rootReducer';

export const getKey = ({ settingsUser }: IStoreState) =>
  settingsUser.deleteMultiplePhysician.key;

export const getUserIds = ({ settingsUser }: IStoreState) =>
  settingsUser.deleteMultiplePhysician.userIds;

export const isDeleting = ({ settingsUser }: IStoreState) =>
  settingsUser.deleteMultiplePhysician.status === RequestStatus.Pending;

export const getErrorMessage = ({ settingsUser }: IStoreState) =>
  settingsUser.deleteMultiplePhysician.errorMessage;

export const getDeleteMultiplePhysicianData = createSelector(
  [getKey, getUserIds, isDeleting, getErrorMessage],
  (key, userIds, isDeletingStatus, errorMessage) => ({
    key,
    userIds,
    isDeleting: isDeletingStatus,
    errorMessage,
  }),
);

export const getPhysicianId = ({ userInfo }: IStoreState) =>
  userInfo.userData.physicianId;

export const getUserInfo = ({ userInfo }: IStoreState) => ({
  profileId: userInfo.profileId,
  isSubscriber: userInfo.userData.isSubscriber,
});
