import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',

    '& > svg': {
      width: 15,
      height: 15,
      marginRight: '0.2em',
      color: theme.palette.grey[500],
    },
  },
}));
