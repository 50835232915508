import iClinicApi from 'services/iclinic/iClinicApi';
import { RedeemAwardData } from '../types';

export const getReferralsParticipant = () =>
  iClinicApi.get('/referrals/participant/');

export const getSaveInterestingIndication = (channel: string) =>
  iClinicApi.get('/referrals/interest-share/save-interest-indication/', {
    params: { channel },
  });

export const getReferralAwards = () => iClinicApi.get('/referrals/award/');

export const postReferralsRedeemAward = ({ awardId, data }: RedeemAwardData) =>
  iClinicApi.post(`/referrals/award/${awardId}/redeem/`, data);

export const getCheckInterestingIndication = (event: string) =>
  iClinicApi.get(
    '/referrals/interest-share/check-intercom-interest-indication/',
    {
      params: {
        eventName: event,
      },
    },
  );

export const checkContactIsAPromoter = () =>
  iClinicApi.get('/referrals/contact-indication/check-contact-is-a-promoter/');
