import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import {
  SellerEditForm,
  SellerEmailsSettingsForm,
  SellerEmailsSettingsFormResume,
} from '../types';
import {
  ADDRESS_UPDATE_ERROR,
  EMAIL_FETCH_ERROR,
  EMAIL_SAVE_ERROR,
} from '../errorMessages';
import {
  Address,
  SellerRawData,
} from 'features/onlinePayment/services/config/types';

export interface SellerEditState {
  address?: {
    fetchStatus?: RequestStatus;
    saveStatus?: RequestStatus;
    data?: Address;
    error?: string;
  };
  emails?: {
    fetchStatus?: RequestStatus;
    saveStatus?: RequestStatus;
    data?: SellerEmailsSettingsFormResume;
    error?: string;
  };
}

export const initialState: SellerEditState = {
  emails: {
    fetchStatus: RequestStatus.Pending,
  },
};

const name = 'sellerEdit';

export const fetchEmailsSettings = createAction(`${name}/fetchEmailsSettings`);
export const saveEmailsSettings = createAction<SellerEmailsSettingsForm>(
  `${name}/saveEmailsSettings`,
);
export const saveSellerEditing = createAction<SellerEditForm>(
  `${name}/saveSellerEditing`,
);
export const initSettings = createAction<SellerRawData>(`${name}/initSettings`);

const SellerEditSlice = createSlice({
  name,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(
      saveEmailsSettings,
      (state): SellerEditState => ({
        ...state,
        emails: {
          ...state.emails,
          saveStatus: RequestStatus.Pending,
        },
      }),
    );
  },
  reducers: {
    fetchEmailsSettingsSuccess: (
      state,
      action: PayloadAction<SellerEmailsSettingsFormResume>,
    ): SellerEditState => ({
      ...state,
      emails: {
        fetchStatus: RequestStatus.Success,
        data: action.payload,
      },
    }),
    fetchEmailsSettingsFailure: (state): SellerEditState => ({
      ...state,
      emails: {
        fetchStatus: RequestStatus.Error,
        error: EMAIL_FETCH_ERROR,
      },
    }),
    saveEmailSettingsSuccess: (state): SellerEditState => ({
      ...state,
      emails: {
        ...state.emails,
        saveStatus: RequestStatus.Success,
      },
    }),
    saveEmailSettingsFailure: (state): SellerEditState => ({
      ...state,
      emails: {
        ...state.emails,
        saveStatus: RequestStatus.Error,
        error: EMAIL_SAVE_ERROR,
      },
    }),

    updateAddressSuccess: (
      state,
      action: PayloadAction<Address>,
    ): SellerEditState => ({
      ...state,
      address: {
        ...state.address,
        data: action.payload,
        saveStatus: RequestStatus.Success,
      },
    }),
    updateAddressFailure: (state): SellerEditState => ({
      ...state,
      address: {
        ...state.address,
        saveStatus: RequestStatus.Error,
        error: ADDRESS_UPDATE_ERROR,
      },
    }),
  },
});

export default SellerEditSlice.reducer;

export const {
  fetchEmailsSettingsSuccess,
  fetchEmailsSettingsFailure,
  saveEmailSettingsSuccess,
  saveEmailSettingsFailure,
  updateAddressSuccess,
  updateAddressFailure,
} = SellerEditSlice.actions;
