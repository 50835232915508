import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const { border, color, shadow, spacing } = tokens;

export default makeStyles((theme) => ({
  form: {
    width: '100%',
  },
  popper: {
    maxWidth: 300,
    width: '100%',
    left: spacing.sm,

    [theme.breakpoints.up('sm')]: {
      maxWidth: 700,
      width: '100%',
      left: 0,
    },
  },
  popperContent: {
    backgroundColor: color.neutral[0],
    borderRadius: border.radius.sm,
    boxShadow: shadow.level[5],
  },
}));
