import React from 'react';

import { gotoAppRoute } from 'shared/utils/appRoutes';
import * as S from './CardSpecialtyNotAvailable.style';
import { Box, Button } from '@iclinic/design-system';

const CardSpecialtyNotAvailable = () => {
  return (
    <S.Card>
      <div>
        <S.TextHeading variant="md">Facilidade não disponível</S.TextHeading>
        <S.Paragraph variant="sm">
          Infelizmente a solicitação de amostras ainda não está disponível para
          você.
        </S.Paragraph>
        <Box textAlign="center">
          <Button onClick={() => gotoAppRoute('/dashboard')}>
            Voltar ao iClinic
          </Button>
        </Box>
      </div>
    </S.Card>
  );
};

export default CardSpecialtyNotAvailable;
