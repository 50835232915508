import React from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { Link } from '@iclinic/design-system';
import { RequestSamplesFormData } from './types';
import { ControlledCheckbox } from 'features/free-samples/components';
import { useTermsFormErrorTracking } from 'features/free-samples/hooks';
import { trackRequestSamplesFormStep } from 'features/free-samples/trackRequestSamplesFormUtils';
import { getUserInfo } from 'features/free-samples/state/request-samples/selectors';
import * as S from './SectionTermsAndSubmit.styles';

type SectionTermsAndSubmitProps = {
  termDataProtectionUrl: string;
  termSampleUrl: string;
};

export default function SectionTermsAndSubmit({
  termDataProtectionUrl,
  termSampleUrl,
}: SectionTermsAndSubmitProps): JSX.Element {
  const userInfo = useSelector(getUserInfo);

  const { values, errors, handleChange, touched } = useFormikContext<
    RequestSamplesFormData
  >();

  useTermsFormErrorTracking(errors, touched, userInfo);

  return (
    <S.Root>
      <ControlledCheckbox
        name="isClinicAddress"
        label={
          <S.Label variant="xxs">
            Confirmo que os dados de endereço fornecido são da minha clínica
          </S.Label>
        }
        checked={values.isClinicAddress}
        onChange={handleChange}
        error={!!errors.isClinicAddress && touched.isClinicAddress}
        errorMessage={touched.isClinicAddress && errors.isClinicAddress}
        onBlur={() => {
          trackRequestSamplesFormStep({
            userInfo,
            stepName: 'user_address_validation',
            stepNum: 13,
          });
        }}
      />

      <ControlledCheckbox
        name="acceptTermDataProtection"
        checked={values.acceptTermDataProtection}
        onChange={handleChange}
        label={
          <S.Label variant="xxs">
            Aceito o{' '}
            <Link
              color="inherit"
              bold
              href={termDataProtectionUrl}
              target="_blank"
              onClick={() => {
                trackRequestSamplesFormStep({
                  userInfo,
                  stepName: 'link_check_data_consent_terms',
                  stepNum: 14,
                });
              }}
            >
              Termo de Consentimento de Proteção de Dados
            </Link>
          </S.Label>
        }
        error={
          !!errors.acceptTermDataProtection && touched.acceptTermDataProtection
        }
        errorMessage={
          touched.acceptTermDataProtection && errors.acceptTermDataProtection
        }
        onBlur={() => {
          trackRequestSamplesFormStep({
            userInfo,
            stepName: 'accept_data_consent_terms',
            stepNum: 14,
          });
        }}
      />

      <ControlledCheckbox
        name="acceptTerms"
        checked={values.acceptTerms}
        onChange={handleChange}
        label={
          <S.Label variant="xxs">
            Aceito o{' '}
            <Link
              color="inherit"
              bold
              href={termSampleUrl}
              target="_blank"
              onClick={() => {
                trackRequestSamplesFormStep({
                  userInfo,
                  stepName: 'link_check_adhesion_terms',
                  stepNum: 15,
                });
              }}
            >
              Termo de Adesão
            </Link>{' '}
            para o envio de amostras grátis
          </S.Label>
        }
        error={!!errors.acceptTerms && touched.acceptTerms}
        errorMessage={touched.acceptTerms && errors.acceptTerms}
        onBlur={() => {
          trackRequestSamplesFormStep({
            userInfo,
            stepName: 'accept_adhesion',
            stepNum: 15,
          });
        }}
      />
    </S.Root>
  );
}
