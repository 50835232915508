import { reducerMerge } from 'shared/utils/reducer';
// Internal
import * as types from './types';

export const initialState = {
  isOpen: false,
  isFetching: false,
  step: 1,
  totalUsers: 0,
  currentModal: 'video',
  hiring: null,
  calculateUpgrade: null,
  errors: [],
  physicians: [],
  selectedPhysicians: [],
  summary: null,
  addons: {
    products: [],
    subscription: { annual: false },
  },
  values: {
    monthly: null,
  },
  selectedProducts: [],
  productDetail: {
    values: { monthly: null },
    promotion: {
      frequencies: {
        monthly: null,
        annual: null,
      },
    },
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const upgradeAddon = (state = initialState, action) => {
  switch (action.type) {
    case types.DETAIL.OPEN:
    case types.DETAIL.CLOSE:
    case types.SET_STEP.ACTION:
    case types.SET_STEP.SUCCESS:
    case types.FETCH_PHYSICIANS.SUCCESS:
    case types.FETCH_PHYSICIANS.FAILURE:
    case types.FETCH_CALCULATE_PRICE.ACTION:
    case types.FETCH_CALCULATE_PRICE.SUCCESS:
    case types.FETCH_CALCULATE_PRICE.FAILURE:
    case types.FETCH_SUMMARY.ACTION:
    case types.FETCH_SUMMARY.SUCCESS:
    case types.FETCH_SUMMARY.FAILURE:
    case types.FETCH_CREATE.ACTION:
    case types.FETCH_CREATE.SUCCESS:
    case types.FETCH_CREATE.FAILURE:
    case types.SELECT_PHYSICIAN.SUCCESS:
    case types.SELECT_PHYSICIAN.FAILURE:
    case types.FETCH_ADDONS.SUCCESS:
    case types.FETCH_ADDONS.FAILURE:
    case types.SELECT_PRODUCT.SUCCESS:
    case types.SELECT_PRODUCT.FAILURE:
    case types.FETCH_NOTIFICATION.ACTION:
    case types.FETCH_NOTIFICATION.SUCCESS:
    case types.FETCH_NOTIFICATION.FAILURE:
    case types.FETCH_ADDONS_DETAIL.ACTION:
    case types.FETCH_ADDONS_DETAIL.SUCCESS:
    case types.FETCH_ADDONS_DETAIL.FAILURE:
    case types.JUST_OPEN_MODAL.ACTION:
      return reducerMerge({}, state, action.payload);
    default:
      return state;
  }
};

export default upgradeAddon;
