export enum TextCardType {
  MedicalHistory = 1,
  SurgicalHistory = 2,
  FamilyHistory = 4,
  Habits = 6,
}

export enum ListCardType {
  Alergies = 3,
  DrugsInUse = 5,
  LastDiagnosis = 7,
}

export enum CardKind {
  Text = 1,
  DB = 2,
}

export type HighlightsInfoCardBase = {
  typeCard: ListCardType | TextCardType;
  kind: CardKind;
};

export interface HighlightsInfoTextCard extends HighlightsInfoCardBase {
  content: string;
  eventId?: number;
  id?: number;
  timeCreated?: string;
}

export type HighlightsInfoListCardItems = {
  title: string;
  itemId: string;
  description?: string;
  isActive?: boolean;
  timeCreated?: string;
  subtitle?: string;
};

export interface HighlightsInfoListCard extends HighlightsInfoCardBase {
  items: HighlightsInfoListCardItems[];
}

export interface HighlightsInfoListCardDenied extends HighlightsInfoCardBase {
  isDenied: boolean;
}

export type SearchDbMedications = {
  description?: string;
  id: string;
  subtitle: string;
  title: string;
  isDenied?: boolean;
};

export type HighlightsInfoType =
  | HighlightsInfoTextCard
  | HighlightsInfoListCard
  | HighlightsInfoListCardDenied;

export type HighlightsInfoState = {
  isModalHighlightsInfoOpen: boolean;
  highlightInfoSelected?: HighlightsInfoType;
  highlightInfos: HighlightsInfoType[];
  isSavingLoading: boolean;
  isSavingError: boolean;
  isSearchDbLoading: boolean;
  isSearchDbError: boolean;
  isSearchDbSuccess: boolean;
  isShouldRefreshHighlightInfo: boolean;
  isGetHighlightError: boolean;
  isGetHighlightLoading: boolean;
  searchText?: string;
  searchDb: SearchDbMedications[];
};

export type SaveHighlightsInfoActionPayload = {
  content: string;
  patientId: string;
};

export type UserInfo = {
  profileId: string | null;
  subscriptionId: string | undefined;
  isSubscriber: boolean | null | undefined;
  clinicId: string | null;
};

export enum CardTypeTracking {
  medicalHistory = 'medical_history',
  surgicalHistory = 'surgical_history',
  alergies = 'alergies',
  familyHistory = 'family_history',
  drugsInUse = 'drugs_in_use',
  habits = 'habits',
  lastDiagnosis = 'last_diagnosis',
  custom = 'custom',
}

export enum CardInteractionTracking {
  open = 'open',
  view = 'view',
  fill = 'fill',
  close = 'close',
}

export type TrackHighlightsInfoEvents = {
  cardInteraction: CardInteractionTracking;
  cardType: CardTypeTracking;
  cardName: string;
};

export interface FilterOptionsState<OptionType> {
  inputValue: string;
  getOptionLabel: (option: OptionType) => string;
}
