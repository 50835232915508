import iClinicApi from 'services/iclinic/iClinicApi';
import { TemplateMessage } from '../types';

export const getConfirmationMessageTemplate = (physicianId: number) =>
  iClinicApi.get(`/messages/templates/confirmation_event/${physicianId}/`);

export const putConfirmationMessageTemplate = (
  physicianId: number,
  templateMessage: TemplateMessage,
) =>
  iClinicApi.put(
    `/messages/templates/confirmation_event/${physicianId}/`,
    templateMessage,
  );
