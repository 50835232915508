import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Container = styled('div')(({ theme }) => ({
  overflowY: 'hidden',
  [theme.breakpoints.down('md')]: {
    height: '100%',
  },
}));

export const Title = styled('h3')(() => ({
  margin: `0px 0px ${axiomTokens.spacing[6]}`,
  fontWeight: axiomTokens.font.weight.bold,
  fontSize: axiomTokens.font.size.sm,
  letterSpacing: axiomTokens.font.letterSpacing.sm,
  color: axiomTokens.color.typeface.pure,
}));

export const SkeletonContainer = styled('div')(() => ({
  marginBottom: axiomTokens.spacing[6],
}));
