import React, { useState } from 'react';
import { isSupported } from '@twilio/video-processors';
import classnames from 'classnames';
import { Box } from '@iclinic/design-system';

import ButtonEndCall from './Buttons/EndCallButton';
import CameraButton from './Buttons/CameraButton';
import MicrophoneButton from './Buttons/MicrophoneButton';
import SettingsButton from './Buttons/SettingsButton';
import ChatButton from './Buttons/ChatButton';
import MoreToolsButton from './Buttons/MoreToolsButton';
import AnnotationsButton from './Buttons/AnnotationsButton';
import GoToMedicalRecords from './Buttons/GoToMedicalRecordsButton';
import CaptionButton from './Buttons/CaptionButton';
import useStyles from './styles';
import FiltersButton from './Buttons/FiltersButton';
import { useConference } from 'features/video-conference/state/conferenceContext';

const MobileToolbar = (): JSX.Element => {
  const { isPhysician } = useConference();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();

  return (
    <Box
      className={classnames(classes.root, classes.mobileToolbar)}
      data-testid="mobile-toolbar"
    >
      <Box
        className={classnames(classes.secondaryTools, {
          [classes.drawerOpen]: isDrawerOpen,
        })}
      >
        {isPhysician && (
          <>
            <GoToMedicalRecords />
            <AnnotationsButton />
          </>
        )}
        <ChatButton />
        <SettingsButton />
        {isSupported && <FiltersButton />}
      </Box>
      <Box className={classes.mainTools}>
        <MicrophoneButton />
        <CameraButton />
        <CaptionButton />
        <MoreToolsButton
          isOpen={isDrawerOpen}
          onClickHandler={() => setDrawerOpen(!isDrawerOpen)}
        />
        {isPhysician && <ButtonEndCall />}
      </Box>
    </Box>
  );
};

export default MobileToolbar;
