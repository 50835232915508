// eslint-disable-next-line @typescript-eslint/naming-convention
enum SUBSCRIPTION_PERMISSIONS {
  Finances = 'finances',
  Reports = 'reports',
  Multiclinic = 'multiclinic',
  FinancesReport = 'finances-report',
  Chat = 'chat',
  Birthdays = 'birthdays',
  Logo = 'logo',
  Multiprocedure = 'multiprocedure',
  Prescriptions = 'prescriptions',
  SmsResposta = 'sms-resposta',
  Stock = 'stock',
  Campaign = 'campaign',
  EmailFlow = 'email-flow',
  Campanhas = 'campanhas',
  StockHistory = 'stock-history',
  Schedule = 'schedule',
  Telemedicine = 'telemedicine',
  CalendarIntegrator = 'calendar-integrator',
  AssinaturaDigital = 'assinatura-digital',
}

export default SUBSCRIPTION_PERMISSIONS;
