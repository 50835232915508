import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, TextField, MaterialIcons } from '@iclinic/design-system';

import {
  getIsFetchingPhysicians,
  getPhysicians,
} from 'features/reports/state/telemedicineReport/selectors';
import {
  fetchPhysicians,
  fetchReportsWithNoPhysician,
  fetchTelemedicineReports,
} from 'features/reports/state/telemedicineReport/reducer';
import { useDebounce } from 'shared/hooks';
import { PhysicianInfo } from 'features/reports/state/telemedicineReport/types';
import { AutocompleteOnChangeNewValue } from './types';
import { AutocompleteWrapper } from './Filters.styles';

const { KeyboardArrowDown } = MaterialIcons;

export const PhysicianFilter = () => {
  const options = useSelector(getPhysicians);
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState(options[0].name);

  const isFetching = useSelector(getIsFetchingPhysicians);
  const dispatch = useDispatch();
  const debouncedSelectedValue = useDebounce(inputValue, 500);

  useEffect(() => {
    const allSelected = debouncedSelectedValue === 'Todos';
    const physicianName = allSelected ? '' : debouncedSelectedValue;

    dispatch(fetchPhysicians({ physicianName }));
  }, [dispatch, debouncedSelectedValue]);

  const onChangeHandler = (
    event: ChangeEvent<{}>,
    newValue: AutocompleteOnChangeNewValue,
    reason: string,
  ) => {
    const physicianOption = newValue as PhysicianInfo;
    setValue(physicianOption);

    const physicianId = physicianOption?.value;

    if (reason === 'clear' && event?.nativeEvent.type !== 'input') {
      setValue(options[0]);
    }

    if (!physicianId) {
      dispatch(fetchReportsWithNoPhysician());
      return;
    }

    dispatch(fetchTelemedicineReports({ physicianId }));
  };

  const onChangeInputHandler = (
    _event: ChangeEvent<{}>,
    newValue: AutocompleteOnChangeNewValue,
  ) => {
    setInputValue(newValue as string);
  };

  return (
    <AutocompleteWrapper>
      <Autocomplete
        value={value}
        popupIcon={<KeyboardArrowDown />}
        loading={isFetching}
        loadingText="Pesquisando..."
        onChange={onChangeHandler}
        options={options}
        onInputChange={onChangeInputHandler}
        inputValue={inputValue}
        getOptionLabel={(option) => option.name}
        noOptionsText="Sem Opções"
        getOptionSelected={(option, selected) => option.name === selected.name}
        // eslint-disable-next-line react/jsx-props-no-spreading
        renderInput={(params) => <TextField {...params} label="Profissional" />}
      />
    </AutocompleteWrapper>
  );
};
