import React, { useEffect } from 'react';
import { MaterialIcons as Icons, MaterialUICore } from '@iclinic/design-system';
import { useSelector } from 'react-redux';

import { Wrapper, ButtonCTA } from './CallToAction.styles';
import { dispatchGaEvent } from 'shared/utils/googleAnalytics';

const { useMediaQuery, useTheme } = MaterialUICore;

export type CallToActionProps = {
  onClick: () => Promise<void>;
  isLoading: boolean;
};
const CallToAction = ({ onClick, isLoading }: CallToActionProps) => {
  const hasMoreTools = useSelector(
    (state) => state.records.root.serviceInitialized,
  );
  const theme = useTheme();
  const isLayoutMobile = useMediaQuery(theme.breakpoints.down(390));
  const buttonSize = isLayoutMobile ? 'small' : 'medium';

  useEffect(() => {
    dispatchGaEvent('impression', {
      event_name: 'resumoai',
      component: 'button',
    });
  }, []);

  return (
    <Wrapper
      key={hasMoreTools}
      serviceInitialized={hasMoreTools}
      data-testid="Wrapper"
    >
      <ButtonCTA
        color="secondary"
        size={buttonSize}
        endIcon={<Icons.DiamondIA data-testid="DiamondIA" />}
        onClick={onClick}
        isLoading={isLoading}
        data-ga="resumoai"
      >
        Prontuário otimizado
      </ButtonCTA>
    </Wrapper>
  );
};

export default CallToAction;
