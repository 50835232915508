// External
import React from 'react';
import PropTypes from 'prop-types';
// Internal
import { Button, Modal, Alert, List } from '@iclinic/iclinic-ui';

import { formatNumberToDecimal, totalUsersMessage } from 'shared/utils/strings';
import SubscriptionModalForm from './SubscriptionModalForm';
import Product from './product';
import Form from './form';

const ErrorItem = ({ data }) => data.message;

class SubscriptionModal extends React.Component {
  static getTooltipMessage(totalProductsPrice, totalUsers) {
    const totalValue = formatNumberToDecimal(totalProductsPrice);
    return totalUsersMessage(totalValue, totalUsers);
  }

  static totalProductValueUsers(totalProductsPrice, totalUsers) {
    return totalProductsPrice * totalUsers;
  }

  static totalProductsPrice(
    productValueWithPromotion,
    extraProductValueWithPromotion,
  ) {
    return productValueWithPromotion + extraProductValueWithPromotion;
  }

  render() {
    const {
      // Store
      extraProduct,
      title,
      isMonthly,
      totalUsers,
      errorsDetails,
      children,
      isFetching,
      hasExtraProduct,
      // Actions
      changeTabRecurrence,
      closeModalCreditCard,
      changeExtraProduct,
      // ReduxForm
      submitForm,
      // selectors
      selectedProductPrice,
      extraProductPrice,
    } = this.props;

    const { productName, productValueWithPromotion } = selectedProductPrice;

    const { productValueWithPromotion: extraProductValueWithPromotion } =
      extraProductPrice;

    const contentTooltipProductPrice = SubscriptionModal.getTooltipMessage(
      productValueWithPromotion,
      totalUsers,
    );
    const totalProductsPrice = SubscriptionModal.totalProductsPrice(
      productValueWithPromotion,
      extraProductValueWithPromotion,
    );
    const contentTooltipTotalProductsPrice =
      SubscriptionModal.getTooltipMessage(totalProductsPrice, totalUsers);
    const totalProductValuePlusUsers = SubscriptionModal.totalProductValueUsers(
      totalProductsPrice,
      totalUsers,
    );
    const formattedTotalProductValue = formatNumberToDecimal(
      totalProductValuePlusUsers,
    );
    const formattedExtraProductValue = formatNumberToDecimal(
      extraProductValueWithPromotion,
    );
    const selectedProductValue = productValueWithPromotion * totalUsers;
    const formattedSelectedProductValue =
      formatNumberToDecimal(selectedProductValue);
    const period = isMonthly ? 'mês' : 'ano';

    return (
      <Modal title={title} show onCloseClick={closeModalCreditCard} width={692}>
        <Modal.Body>
          {errorsDetails.length > 0 && (
            <Alert kind="danger">
              <List
                kind="type-circle"
                items={errorsDetails}
                renderItem={ErrorItem}
              />
            </Alert>
          )}
          <Form.Row>
            <Form.Column>
              <Form.Title content="ESCOLHA COMO PAGAR" />
              <Product.ButtonGroup
                isMonthly={isMonthly}
                toggleStatus={changeTabRecurrence}
              />
              <Product.Description
                productName={`Plano ${productName}`}
                users={totalUsers}
                value={formattedSelectedProductValue}
                period={period}
                content={contentTooltipProductPrice}
              />
              <Product.Extra
                name={extraProduct.name}
                value={formattedExtraProductValue}
                period={period}
                isChecked={hasExtraProduct}
                toggleStatus={changeExtraProduct}
              />
              {hasExtraProduct && (
                <Product.Total
                  value={formattedTotalProductValue}
                  period={period}
                  content={contentTooltipTotalProductsPrice}
                />
              )}
            </Form.Column>
            <Form.Column>{children}</Form.Column>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            kind="primary"
            loaderKind="gray-1"
            loader={isFetching}
            disabled={isFetching}
            onClick={submitForm}
            type="submit"
          >
            Assinar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

SubscriptionModal.Form = SubscriptionModalForm;

SubscriptionModal.propTypes = {
  changeTabRecurrence: PropTypes.func.isRequired,
  extraProduct: PropTypes.instanceOf(Object).isRequired,
  closeModalCreditCard: PropTypes.func.isRequired,
  isMonthly: PropTypes.bool.isRequired,
  totalUsers: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  isFetching: PropTypes.bool,
  hasExtraProduct: PropTypes.bool,
  errorsDetails: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ),
  changeExtraProduct: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  selectedProductPrice: PropTypes.shape({
    productName: PropTypes.string,
    productValueWithPromotion: PropTypes.number,
  }).isRequired,
  extraProductPrice: PropTypes.shape({
    productValueWithPromotion: PropTypes.number,
  }).isRequired,
};

SubscriptionModal.defaultProps = {
  title: 'Assinatura',
  isFetching: false,
  hasExtraProduct: false,
  errorsDetails: [],
};

export default SubscriptionModal;
