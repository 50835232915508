import React from 'react';

import { Box } from '@iclinic/design-system';
import CardProfileLoader from './profile/CardProfileLoader';
import CardDocumentsLoader from './documents/CardDocumentsLoader';

const SellerRegisterProfileLoader = () => {
  return (
    <>
      <Box mb={2}>
        <CardProfileLoader />
      </Box>
      <CardDocumentsLoader />
    </>
  );
};

export default SellerRegisterProfileLoader;
