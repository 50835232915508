import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { beneficiarySectionValues } from 'features/tissInvoicing/containers/sadt/GuideSADT/BeneficiarySection/validationSchema';
import { expensesSectionValues } from 'features/tissInvoicing/containers/sadt/GuideSADT/ExpenseSection/validationSchema';
import { generalSectionValues } from 'features/tissInvoicing/containers/sadt/GuideSADT/GeneralSection/validationSchema';
import { headerSectionValues } from 'features/tissInvoicing/containers/sadt/GuideSADT/HeaderSection/validationSchema';
import { proceduresSectionValues } from 'features/tissInvoicing/containers/sadt/GuideSADT/ProceduresSection/validationSchema';
import { sessionsSectionValues } from 'features/tissInvoicing/containers/sadt/GuideSADT/SessionsSection/validationSchema';
import { solicitorSectionValues } from 'features/tissInvoicing/containers/sadt/GuideSADT/SolicitorSection/validationSchema';
import {
  ActionKeys,
  ClinicHealthInsuranceHiredExecutant,
  ClinicHealthInsuranceProfessionalExecutant,
  ClinicHealthInsuranceprops,
  GuideSADT,
  GuideSADTForm,
  SnackbarProps,
} from 'features/tissInvoicing/types';
import getSnackbarPayload from 'features/tissInvoicing/utils/getSnackbarPayload';
import { normalizeGuideSADTResponse } from './normalizers';
import { PatientParams } from 'features/tissInvoicing/types/sadt/patients';
import { EventParams } from 'features/tissInvoicing/types/sadt/event';

type TypeClinicHealthData = 'professionalExecutant' | 'hiredExecutant' | 'show';

export type SadtFormState = {
  guide?: GuideSADT;
  form: {
    data: GuideSADTForm;
    expensesErrors: boolean;
  };
  clinicHealth: {
    professionalExecutant: ClinicHealthInsuranceProfessionalExecutant;
    hiredExecutant: ClinicHealthInsuranceHiredExecutant;
    show: ClinicHealthInsuranceprops;
  };
  patient: PatientParams;
  print: {
    pdfURL: string;
    openModal: boolean;
  };
  loading: {
    [key in ActionKeys]: boolean;
  };
  snackbar: SnackbarProps;
  event: EventParams;
};

const name = 'sadt';

export const initialState: SadtFormState = {
  guide: {} as GuideSADT,
  clinicHealth: {
    professionalExecutant: {} as ClinicHealthInsuranceProfessionalExecutant,
    hiredExecutant: {} as ClinicHealthInsuranceHiredExecutant,
    show: {} as ClinicHealthInsuranceprops,
  },
  patient: {} as PatientParams,
  form: {
    data: {
      ...headerSectionValues,
      ...generalSectionValues,
      ...beneficiarySectionValues,
      ...solicitorSectionValues,
      ...proceduresSectionValues,
      ...sessionsSectionValues,
      ...expensesSectionValues,
    },
    expensesErrors: false,
  },
  print: {
    pdfURL: '',
    openModal: false,
  },
  loading: {
    fetchGuide: true,
    save: false,
    print: false,
    fetchClinicHealthShow: true,
    fetchClinicHealthHiredExecutant: false,
    fetchAutocomplete: false,
    fetchDomainTables: false,
    getPatient: false,
  },
  snackbar: getSnackbarPayload(),
  event: {} as EventParams,
};

const getPatientData = createAction<{ patient_id: number }>(
  `${name}/getPatientData`,
);

const fetchGuideData = createAction<{ eventId: string }>(
  `${name}/fetchGuideData`,
);

const fetchEventData = createAction<{ eventId: string }>(
  `${name}/fetchEventData`,
);

const fetchClinicHealth = createAction<{
  insurance_id: number;
  physician_id: number;
}>(`${name}/fetchClinicHealth`);

const saveGuide = createAction<{
  guideId: string;
  data: GuideSADTForm;
  professionalExecutant?: ClinicHealthInsuranceProfessionalExecutant;
  hiredExecutant?: ClinicHealthInsuranceHiredExecutant;
}>(`${name}/saveGuide`);
const printGuide = createAction<{
  guideId: string;
  eventId: string;
  data: GuideSADTForm;
  professionalExecutant?: ClinicHealthInsuranceProfessionalExecutant;
  hiredExecutant?: ClinicHealthInsuranceHiredExecutant;
}>(`${name}/printGuide`);

const getClinicHealthHiredExecutant = createAction<{
  insurance_id: number;
  physician_id: number;
}>(`${name}/getClinicHealthHiredExecutant`);

const fetchClinicHealthShow = createAction<{
  insurance_id: number;
}>(`${name}/fetchClinicHealthShow`);

const sadtFormSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLoadingStatus: (
      state,
      action: PayloadAction<{ key: ActionKeys; value: boolean }>,
    ) => ({
      ...state,
      loading: {
        ...state.loading,
        [action.payload.key]: action.payload.value,
      },
    }),
    setClinicHealth: (
      state,
      action: PayloadAction<{
        professionalExecutant: ClinicHealthInsuranceProfessionalExecutant;
        hiredExecutant: ClinicHealthInsuranceHiredExecutant;
      }>,
    ) => ({
      ...state,
      clinicHealth: {
        ...state.clinicHealth,
        professionalExecutant: action.payload.professionalExecutant,
        hiredExecutant: action.payload.hiredExecutant,
      },
    }),
    resetClinicHealth: (state) => ({
      ...state,
      clinicHealth: {
        show: {} as ClinicHealthInsuranceprops,
        professionalExecutant: {} as ClinicHealthInsuranceProfessionalExecutant,
        hiredExecutant: {} as ClinicHealthInsuranceHiredExecutant,
      },
    }),
    setGuideData: (state, action: PayloadAction<GuideSADT>) => ({
      ...state,
      guide: action.payload,
      form: {
        ...state.form,
        data: normalizeGuideSADTResponse(action.payload),
      },
    }),
    setEventData: (state, action: PayloadAction<EventParams>) => ({
      ...state,
      event: action.payload,
    }),
    setGuidePDFUrl: (state, action) => ({
      ...state,
      print: {
        ...state.print,
        pdfURL: action.payload,
      },
    }),
    setPrintModalOpen: (state, action) => ({
      ...state,
      print: {
        ...state.print,
        openModal: action.payload,
      },
    }),
    setSnackbarContent: (state, action) => ({
      ...state,
      snackbar: {
        message: action.payload.message || null,
        severity: action.payload.severity || 'default',
      },
    }),
    setExpensesErrors: (state, action: PayloadAction<boolean>) => ({
      ...state,
      form: {
        ...state.form,
        expensesErrors: action.payload,
      },
    }),
    setClinicHealthWithType: (
      state,
      action: PayloadAction<{
        type: TypeClinicHealthData;
        data:
          | ClinicHealthInsuranceHiredExecutant
          | ClinicHealthInsuranceProfessionalExecutant
          | ClinicHealthInsuranceprops;
      }>,
    ) => ({
      ...state,
      clinicHealth: {
        ...state.clinicHealth,
        [action.payload.type]: action.payload.data,
      },
    }),
    setPatient: (state, action: PayloadAction<PatientParams>) => ({
      ...state,
      patient: action.payload,
    }),
  },
});

export default sadtFormSlice.reducer;
export const actions = {
  ...sadtFormSlice.actions,
  fetchGuideData,
  fetchClinicHealth,
  saveGuide,
  printGuide,
  fetchClinicHealthShow,
  getClinicHealthHiredExecutant,
  getPatientData,
  fetchEventData,
};
