export const toggleState = {
  OPEN: true,
  CLOSE: false,
};

export enum STEP_SIGNATURE {
  Initial = 'INITIAL',
  Setup = 'SETUP',
  Certificates = 'CERTIFICATES',
  Preview = 'PREVIEW',
  Loading = 'LOADING',
}

export const STEP_LINKS_INSTALL = {
  Certificate:
    'https://www.gov.br/iti/pt-br/assuntos/icp-brasil/autoridades-certificadoras',
  Install:
    'http://suporte.iclinic.com.br/como-instalar-o-certificado-digital-no-meu-computador',
  Webpki: `https://get.webpkiplugin.com/Setup?brand=&jslib=&browser=Chrome&returnUrl=${
    window.location.href.split('?')[0]
  }?webpkiInstall=signature`,
};

export const getSteps = () => [
  'Obtenha uma certificação digital',
  'Instale seu certificado digital neste computador',
  'Adicione a extensão no seu navegador',
];

export const getStepContent = (step: number) => {
  switch (step) {
    case 0:
      return `Para ter uma assinatura válida judicialmente, é necessário contar com uma certificação digital A1,
              A3 ou A5, que siga os padrões da ICP-Brasil (Infraestrutura de Chaves Públicas Brasileira).
              Caso não tenha, entre em contato com uma autoridade certificadora.`;
    case 1:
      return 'Após adquirir seu certificado, instale o software da sua certificadora neste computador. Para saber como baixá-lo, clique aqui.';
    case 2:
      return 'Para finalizar, instale a extensão Web PKI neste navegador clicando aqui. ';
    default:
      return 'Unknown step';
  }
};

export const SIGNED_RECORD = 'signedRecord';

export const SIGNED_REDIRECT_USER_INFOS = 'signedRedirectUserInfos';

export enum Providers {
  Not = 'not_signature',
  Local = 'local_signature',
  Cloud = 'cloud_signature',
}

export const ProvidersNames = {
  birdId: 'Bird ID',
  viDaaS: 'VIDaaS',
};

export const CHIPS_SIGNATURE_PROVIDERS = [
  {
    label: 'Não assinar',
    value: 'not_signature',
    visible: true,
  },
  {
    label: 'Certificado instalado',
    value: 'local_signature',
    visible: true,
  },
  {
    label: 'Certificado na nuvem',
    value: 'cloud_signature',
    visible: true,
  },
];

export const LIFETIME_CERTIFICATES = [
  {
    value: 3600,
    label: '1 hora',
  },
  {
    value: 7200,
    label: '2 horas',
  },
  {
    value: 14400,
    label: '4 horas',
  },
  {
    value: 28800,
    label: '8 horas',
  },
  {
    value: 43200,
    label: '12 horas',
  },
];
