import React from 'react';
import classnames from 'classnames/bind';

import Loader from 'ui/new/loader';
import { BUTTON, SIZE, COLOR } from './constants';
import style from './style/index.scss';

const cx = classnames.bind(style);

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
  color?: COLOR;
  type?: BUTTON;
  size?: SIZE;
  block?: boolean;
  disabled?: boolean;
  submit?: boolean;
  loading?: boolean;
  testId?: string;
  idGa?: string;
  classes?: string;
  idSnowplow?: string;
}

export default function Button({
  classes = '',
  children,
  onClick = () => {},
  type = BUTTON.Primary,
  size = SIZE.Medium,
  block = false,
  disabled = false,
  submit = false,
  loading = false,
  color = COLOR.Grey,
  testId = 'button',
  idGa,
  idSnowplow,
}: Props): JSX.Element {
  const className = cx('button-base', classes, {
    info: type === BUTTON.Info,
    link: type === BUTTON.Link,
    success: type === BUTTON.Success,
    light: type === BUTTON.Light,
    error: type === BUTTON.Error,
    danger: type === BUTTON.Danger,
    active: type === BUTTON.Active,
    primary: type === BUTTON.Primary,
    warning: type === BUTTON.Warning,
    primaryActive: type === BUTTON.PrimaryActive,
    warningActive: type === BUTTON.WarningActive,
    dark: type === BUTTON.Dark,
    small: size === SIZE.Small,
    medium: size === SIZE.Medium,
    large: size === SIZE.Large,
    actionButton: size === SIZE.ActionButton,
    block,
    disabled,
    loading,
  });

  return submit ? (
    <button
      className={className}
      type="submit"
      onClick={onClick}
      disabled={disabled}
      data-testid={testId}
      data-ga={idGa}
      data-sp={idSnowplow}
    >
      {loading && <Loader className={style.loader} color={color} />}
      {children}
    </button>
  ) : (
    <button
      className={className}
      type="button"
      onClick={onClick}
      disabled={disabled}
      data-testid={testId}
      data-ga={idGa}
      data-sp={idSnowplow}
    >
      {loading && <Loader className={style.loader} color={color} />}
      {children}
    </button>
  );
}
