// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import iconLibrary from './library';
import { setViewBox, getIconFromLibrary } from './helpers';

const Icon = ({ width, height, name, color }) => (
  <svg viewBox={setViewBox(width, height)} preserveAspectRatio="xMidYMid meet" style={{ width, height, fill: color }}>
    {getIconFromLibrary(iconLibrary, name)}
  </svg>
);

Icon.defaultProps = {
  width: 24,
  height: 24,
  color: '#000',
};

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default Icon;
