// Internal
import * as types from './types';

export const subscribeSubmit = (payload) => ({
  type: types.SUBSCRIBE.SUBMIT,
  payload,
  breadcrumb: {
    category: 'subscription',
    message: 'User submitted the subscription form',
    level: 'info',
  },
  track: {
    action: 'submit',
    category: 'subscription',
  },
});

export const fetchSubscribe = () => ({
  type: types.FETCH_SUBSCRIBE.ACTION,
  payload: {
    subscribeDetail: {
      isFetching: true,
    },
  },
});

export const fetchSubscribeSuccess = () => ({
  type: types.FETCH_SUBSCRIBE.SUCCESS,
  payload: {
    subscribeDetail: {
      isOpen: false,
      isFetching: false,
      product: null,
      extraProduct: null,
      isMonthly: true,
      errorsDetails: [],
    },
    isFetching: false,
  },
  breadcrumb: {
    category: 'subscription',
    message: 'User successful subscribe',
    level: 'info',
  },
  track: {
    action: 'success',
    category: 'subscription',
  },
});

export const fetchSubscribeFailure = (errors) => ({
  type: types.FETCH_SUBSCRIBE.FAILURE,
  payload: {
    subscribeDetail: {
      errorsDetails: errors,
      isFetching: false,
    },
  },
  breadcrumb: {
    category: 'subscription',
    message: 'Subscribe error',
    level: 'error',
    data: errors,
  },
  track: {
    action: 'failure',
    category: 'subscription',
  },
});

export const fetchGetProducts = () => ({
  type: types.FETCH_GET_PRODUCTS.ACTION,
  payload: {
    isFetching: true,
  },
});

export const fetchGetProductsSuccess = (totalUsers, products) => ({
  type: types.FETCH_GET_PRODUCTS.SUCCESS,
  payload: {
    totalUsers,
    products,
    isFetching: false,
    errorsProducts: [],
  },
});

export const fetchGetProductsFailure = (errors) => ({
  type: types.FETCH_GET_PRODUCTS.FAILURE,
  payload: {
    errorsProducts: errors,
  },
});

export const fetchGetProduct = (extraProductSlug) => ({
  type: types.FETCH_GET_PRODUCT.ACTION,
  payload: {
    subscribeDetail: {
      isFetching: true,
      extraProductSlug,
    },
  },
});

export const fetchGetProductSuccess = (payload) => ({
  type: types.FETCH_GET_PRODUCT.SUCCESS,
  payload: {
    subscribeDetail: {
      isFetching: false,
      extraProduct: payload,
    },
  },
});

export const fetchGetProductFailure = (errors) => ({
  type: types.FETCH_GET_PRODUCT.FAILURE,
  payload: {
    errorsProducts: errors,
    subscribeDetail: {
      isFetching: false,
    },
  },
});

export const openModalCreditCard = () => ({
  type: types.MODAL_CREDIT_CARD.OPEN,
  payload: {
    subscribeDetail: {
      isOpen: true,
      isMonthly: true,
    },
  },
  breadcrumb: {
    category: 'subscription',
    message: 'User opens the subscription modal',
    level: 'info',
  },
  track: {
    action: 'open',
    category: 'subscription',
  },
});
export const closeModalCreditCard = () => ({
  type: types.MODAL_CREDIT_CARD.CLOSE,
  payload: {
    subscribeDetail: {
      isOpen: false,
      errorsDetails: [],
    },
  },
  breadcrumb: {
    category: 'subscription',
    message: 'User closes the subscription modal',
    level: 'info',
  },
  track: {
    action: 'close',
    category: 'subscription',
  },
});

export const changeTabRecurrence = (isMonthly) => ({
  type: types.TAB_RECURRENCE.CHANGE,
  payload: {
    subscribeDetail: {
      isMonthly,
    },
  },
  breadcrumb: {
    category: 'subscription',
    message: `User changes recurrency to: ${isMonthly ? 'monthly' : 'annual'}`,
    level: 'info',
  },
});

export const changeExtraProduct = (hasExtraProduct) => ({
  type: types.EXTRA_PRODUCT.CHANGE,
  payload: {
    subscribeDetail: {
      hasExtraProduct,
    },
  },
  breadcrumb: {
    category: 'subscription',
    message: `User ${hasExtraProduct ? 'adds' : 'removes'} extra product`,
    level: 'info',
  },
});

export const setSelectedProduct = (product) => ({
  type: types.SET_SELECTED_PRODUCT.ACTION,
  payload: {
    subscribeDetail: {
      product: {
        ...product,
        promotion: product.promotion || null,
      },
    },
  },
  breadcrumb: {
    category: 'subscription',
    message: 'User sets the selected product',
    level: 'info',
    data: product,
  },
});

// initialize from app
export const setSuccessUrl = (successUrl) => ({
  type: types.SET_SUCCESS_URL.ACTION,
  payload: {
    successUrl,
  },
});
