import React from 'react';
import PropTypes from 'prop-types';

import style from './PageTitle.scss';

const PageTitle = ({ children }) => (
  <div>
    <p className={style.pageTitle}>{ children }</p>
  </div>
);

PageTitle.propTypes = {
  children: PropTypes.string,
};

PageTitle.defaultProps = {
  children: '',
};

export default PageTitle;
