import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MaterialIcons } from '@iclinic/design-system';

import { Button, Heading } from './PrescriptionShortcut.styles';
import { showPrescriptionTab } from 'state/records/actions';

const { PrescriptionShortcutDefault, PrescriptionShortcutInverse } =
  MaterialIcons;

const PrescriptionShortcut = (): JSX.Element => {
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const dispatch = useDispatch();
  return (
    <Button
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => dispatch(showPrescriptionTab())}
      data-ga="medical_record_prescription_shortcut"
      data-sp="medical_record_prescription_shortcut"
    >
      <Heading variant="overline">Prescrição</Heading>
      {isHovered ? (
        <PrescriptionShortcutInverse />
      ) : (
        <PrescriptionShortcutDefault />
      )}
    </Button>
  );
};

export default PrescriptionShortcut;
