import React, { memo } from 'react';

import Columns from 'features/tissInvoicing/components/Columns';
import { ColumnProps } from 'features/tissInvoicing/types/types.common';

export const columns: ColumnProps[] = [
  { name: 'Nº guia', align: 'left', size: 'small' },
  { name: 'Nº lote', align: 'left', size: 'small' },
  { name: 'Data', align: 'left', size: 'small' },
  { name: 'Convênio', align: 'left', size: 'small' },
  { name: 'Paciente', align: 'left', size: 'medium' },
  { name: 'Profissional', align: 'left', size: 'medium' },
  { name: 'Qtde.', align: 'left', size: 'small' },
  { name: 'Valor de procedimento', align: 'left', size: 'small' },
  { name: 'Outras despesas', align: 'left', size: 'small' },
  { name: 'Valor total', align: 'left', size: 'small' },
  { name: 'Editar', align: 'center', size: 'small' },
];

const GuideColumns = () => <Columns columns={columns} />;

export default memo(GuideColumns);
