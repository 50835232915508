import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import {
  TelemedicineReportedState,
  PhysicianInfo,
  FetchPhysicianPayload,
  FilterRecordsPayload,
  FilterParams,
  DateType,
  TelemedicineRecordData,
} from './types';

export const initialState: TelemedicineReportedState = {
  status: RequestStatus.Pending,
  items: [],
  physicians: {
    isFetching: true,
    items: [],
  },
  dateType: 'quick',
  filter: {
    pageNumber: 1,
  },
};

const nameStore = '@webapp/reports/TELEMEDICINE_REPORTS';

export const fetchTelemedicineReports = createAction<FilterRecordsPayload>(
  `${nameStore}/FETCH_TELEMEDICINE_REPORTS`,
);

export const fetchPhysicians = createAction<FetchPhysicianPayload>(
  `${nameStore}/FETCH_PHYSICIANS`,
);

export const changePageTelemedicineReports = createAction<number>(
  `${nameStore}/FETCH_TELEMEDICINE_REPORT_PAGE`,
);

export const fetchReportsWithNoDate = createAction<void>(
  `${nameStore}/RESET_DATE_FILTER`,
);

export const fetchReportsWithNoPhysician = createAction<void>(
  `${nameStore}/RESET_PHYSICIAN_FILTER`,
);

const telemedicineReportsSlice = createSlice({
  name: nameStore,
  initialState,
  reducers: {
    isFetchingReports: (state) => ({
      ...state,
      status: RequestStatus.Pending,
    }),
    fetchTelemedicinesReportsSuccess: (
      state,
      { payload }: PayloadAction<TelemedicineRecordData>,
    ) => ({
      ...state,
      status: RequestStatus.Success,
      items: payload.history,
      pageCount: payload.pagination.totalPages,
    }),
    fetchTelemedicinesReportsFailed: (state) => ({
      ...state,
      status: RequestStatus.Error,
    }),
    isFetchingPhysicians: (state) => ({
      ...state,
      physicians: {
        ...state.physicians,
        isFetching: true,
      },
    }),
    updateFilter: (state, action: PayloadAction<FilterParams>) => ({
      ...state,
      filter: action.payload,
    }),
    fetchPhysiciansSuccess: (
      state,
      action: PayloadAction<PhysicianInfo[]>,
    ) => ({
      ...state,
      physicians: {
        isFetching: false,
        items: action.payload,
      },
    }),
    fetchPhysiciansFailed: (state) => ({
      ...state,
      physicians: {
        ...state.physicians,
        isFetching: false,
      },
    }),
    changeDateFilterType: (state, action: PayloadAction<DateType>) => ({
      ...state,
      dateType: action.payload,
    }),
  },
});

export const {
  isFetchingReports,
  fetchTelemedicinesReportsSuccess,
  fetchTelemedicinesReportsFailed,
  fetchPhysiciansSuccess,
  fetchPhysiciansFailed,
  isFetchingPhysicians,
  updateFilter,
  changeDateFilterType,
} = telemedicineReportsSlice.actions;

export default telemedicineReportsSlice.reducer;
