import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox,
  CircularProgress,
  MaterialUICore,
} from '@iclinic/design-system';

import { useInfiniteScroll } from 'features/tissInvoicing/hooks';
import { getGuidesIds } from 'features/tissInvoicing/utils/getGuidesIds';
import { Guide, GuideTypeOptions } from 'features/tissInvoicing/types';
import {
  CenterBox,
  SpaceBetweenBox,
} from 'features/tissInvoicing/components/ui/Flexbox';
import { Table, TableRow } from 'features/tissInvoicing/components/ui/Table';
import Columns from './Columns';
import Rows from './Rows';
import * as Styles from './styles';
import {
  getAddGuidesState,
  getLotDataState,
} from 'features/tissInvoicing/state/lot/selectors';
import { actions } from 'features/tissInvoicing/state/lot';

const { TableContainer, TableCell, TableBody, TableHead } = MaterialUICore;

function TableContent() {
  const dispatch = useDispatch();
  const classes = Styles.useStyles();

  const {
    hasMoreData,
    loading,
    selectedGuides,
    availableGuides,
    simplifiedGuides,
  } = useSelector(getAddGuidesState);
  const { lot } = useSelector(getLotDataState);

  const { infiniteScrollRef, pageNum } = useInfiniteScroll({
    hasMoreData,
    loading,
  });

  const insurance_id = lot?.clinic_health_insurance?.id;

  const rowIsChecked = (id: string): boolean => lot?.guides?.includes(id);

  const isAllGuidesSelected = () =>
    simplifiedGuides.length === selectedGuides.length;

  const handleCheckAll = () => (event: React.MouseEvent) => {
    const guidesIds = getGuidesIds(simplifiedGuides, []);

    dispatch(
      actions.setSelectedGuides(isAllGuidesSelected() ? [] : simplifiedGuides),
    );
    dispatch(actions.setLotDataGuides(isAllGuidesSelected() ? [] : guidesIds));

    event.preventDefault();
  };

  const handleCheckRow = (guide: Guide) => (event: React.MouseEvent) => {
    /*
     * If the guide is already selected, we need to remove it from the
     * addData and lotData state
     */
    if (rowIsChecked(guide.id)) {
      const guides = selectedGuides.filter((g) => g.id !== guide.id);
      const lotGuidesIds = lot?.guides.filter((g) => g !== guide.id);

      dispatch(actions.setSelectedGuides(guides));
      dispatch(actions.setLotDataGuides(lotGuidesIds));
      return;
    }

    dispatch(actions.setSelectedGuides([...selectedGuides, guide]));
    dispatch(actions.setLotDataGuides([...lot.guides, guide.id]));
    dispatch(
      actions.setAvailableSimplifiedGuides([
        ...simplifiedGuides,
        { id: guide.id, total_amount: guide.total_amount },
      ]),
    );

    event.preventDefault();
  };

  useEffect(() => {
    if (insurance_id) {
      dispatch(
        actions.fetchAvailableGuides({
          lot_id: lot?.id,
          page: pageNum,
          insurance_id,
          ordering: 'lot_id,date',
          guide_type: GuideTypeOptions.SPSADT,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNum, insurance_id]);

  useEffect(() => {
    if (
      lot?.guides?.length === simplifiedGuides.length &&
      simplifiedGuides.length > 0
    ) {
      dispatch(actions.setSelectedGuides(simplifiedGuides));
    }
  }, [dispatch, simplifiedGuides, lot]);

  return (
    <SpaceBetweenBox paddingX={2}>
      <TableContainer classes={{ root: classes.container }}>
        <Table aria-label="Adicionar guias ao lote" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center" size="small" padding="checkbox">
                <Checkbox
                  disabled={availableGuides.length === 0}
                  color="primary"
                  checked={simplifiedGuides.length === selectedGuides.length}
                  onClick={handleCheckAll()}
                  data-testid="select-all-checkbox"
                />
              </TableCell>
              <Columns />
            </TableRow>
          </TableHead>

          <TableBody>
            {availableGuides.map((guide: Guide, index: number) => {
              const isChecked = rowIsChecked(guide.id);
              const isLastElement = availableGuides.length === index + 1;

              return (
                <TableRow
                  key={`guide-${guide.id}`}
                  checked={+isChecked}
                  stripped={+(!isChecked && selectedGuides.length === 0)}
                  ref={isLastElement ? infiniteScrollRef : undefined}
                  hover
                >
                  <TableCell
                    scope="row"
                    align="center"
                    size="small"
                    padding="checkbox"
                  >
                    <Checkbox
                      color="primary"
                      checked={isChecked}
                      onClick={handleCheckRow(guide)}
                    />
                  </TableCell>

                  <Rows rowData={guide} />
                </TableRow>
              );
            })}

            {loading && (
              <TableRow hover>
                <TableCell scope="row" align="center" colSpan={7}>
                  <CenterBox>
                    <CircularProgress label="Carregando guias" />
                  </CenterBox>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </SpaceBetweenBox>
  );
}

export default TableContent;
