import React, { useEffect, useState } from 'react';
import { Checkbox } from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';

import {
  TermsContainer,
  TermsTitle,
  TermsText,
  TermsAcceptArea,
  ContinueButton,
} from './Terms.styles';
import { postAcceptTermsIA, postSummaryIA } from 'features/records/services';
import { actions } from '../../state';
import { dispatchGaEvent } from 'shared/utils/googleAnalytics';

const { changeScreen } = actions;

const Terms = () => {
  const patientId = useSelector((state) => state.records.root.patient.id);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const sendAcceptTerms = async () => {
    if (isAccepted) {
      try {
        setIsLoading(true);
        await postAcceptTermsIA();
        await postSummaryIA(patientId);
        dispatch(changeScreen('loading'));
        setIsLoading(false);
      } catch {
        setIsLoading(false);
        dispatch(changeScreen('integrationError'));
      }
    }
  };

  useEffect(() => {
    dispatchGaEvent('impression', {
      event_name: 'resumoai_term',
      component: 'popup',
    });
  }, []);

  return (
    <TermsContainer data-testid="terms-component">
      <TermsTitle>Adendo Termo e Condições de Uso da iClinic</TermsTitle>
      <TermsText>
        <p>
          Este adendo é parte integrante e complementar ao Termo de Uso da
          plataforma iClinic (“Termo”), disponível em:{' '}
          <a
            href="https://iClinic.com.br/termos/"
            target="_blank"
            rel="noreferrer"
          >
            https://iClinic.com.br/termos/
          </a>{' '}
          e regulamenta o acesso e uso da funcionalidade{' '}
          <strong>Otimização de Prontuário</strong>, oferecida pela iClinic
          Desenvolvimento de Software Ltda., sociedade devidamente inscrita no
          CNPJ/MF sob o nº 20.432.039/0001-95 (“iClinic”), gestora da Plataforma
          iClinic, acessada pelo site{' '}
          <a href="https://iClinic.com.br/" target="_blank" rel="noreferrer">
            https://iClinic.com.br/
          </a>{' '}
          e seus subdomínios, cujos serviços contratados por PESSOA FÍSICA ou
          JURÍDICA, doravante denominado USUÁRIO MÉDICO, respeitarão os
          dispostos no presente Termo, bem como que tenha dado o
          &quot;aceite&quot; eletrônico de todas as cláusulas do presente e
          todas as demais políticas disponíveis no site.
        </p>
        <p>
          Ao utilizar a funcionalidade de{' '}
          <strong>Otimização de Prontuário</strong>, você concorda com os termos
          abaixo:
        </p>
        <ol>
          <li>
            A funcionalidade de <strong>Otimização de Prontuário</strong> visa
            fornecer um resumo organizado dos dados de saúde do paciente com
            base nas informações inseridas pelo profissional de saúde (usuário)
            no prontuário eletrônico. Este resumo é gerado automaticamente
            utilizando modelo de inteligência artificial (LLM - modelo de
            linguagem ampla de aprendizado de máquina que pode compreender e
            gerar textos em linguagem humana) e tem como objetivo auxiliar os
            médicos a terem uma visão rápida e compreensiva do histórico médico
            do paciente.
          </li>
          <li>
            A iClinic pode fornecer determinadas funcionalidades e capacidades
            como parte não essencial das soluções utilizando inteligência
            artificial, aprendizado de máquina ou tecnologias semelhantes. Essas
            funcionalidades podem ser desenvolvidas pela iClinic e/ou por
            provedores terceirizados da iClinic, conforme nossa Política de
            Privacidade disponível em{' '}
            <a
              href="https://suporte.iClinic.com.br/pt-br/politica-de-privacidade-iClinic"
              target="_blank"
              rel="noreferrer"
            >
              https://suporte.iClinic.com.br/pt-br/politica-de-privacidade-iClinic
            </a>
            . O USUÁRIO MÉDICO concorda que a iClinic pode usar
            sub-processadores para fornecer a funcionalidade de{' '}
            <strong>Otimização de Prontuário</strong>. A iClinic e seus
            licenciantes detêm exclusivamente todos os direitos, títulos e
            interesses, incluindo todos os direitos de propriedade intelectual
            associados.
          </li>
          <li>
            Ao utilizar a funcionalidade de{' '}
            <strong>Otimização de Prontuário</strong>, o USUÁRIO MÉDICO concede
            à iClinic e seus provedores terceirizados os direitos necessários
            para processar o conteúdo do Prontuário, mantendo os dados pessoais
            anonimizados, com o objetivo de fornecer a funcionalidade de{' '}
            <strong>Otimização de Prontuário</strong>.
          </li>
          <li>
            Para proteger a privacidade e a segurança dos pacientes, todos os
            dados que possam identificar diretamente um indivíduo (como nomes,
            endereços, números de identificação, etc.) serão anonimizados antes
            de serem processados pela funcionalidade de{' '}
            <strong>Otimização de Prontuário</strong>. Os dados do prontuário
            não serão utilizados para treinamento de modelos de inteligência
            artificial de parceiros ou da iClinic.
          </li>
          <li>
            Quando o USUÁRIO MÉDICO optar por interagir com a funcionalidade de{' '}
            <strong>Otimização de Prontuário</strong>, por meio de inteligência
            artificial a funcionalidade será capaz de reconhecer e apresentar
            dados resumidos do histórico do paciente.
          </li>
          <li>
            Ao utilizar a funcionalidade de{' '}
            <strong>Otimização de Prontuário</strong>, o USUÁRIO MÉDICO mantém a
            propriedade e continua sendo o único responsável por seu conteúdo.
          </li>
          <li>
            A funcionalidade de <strong>Otimização de Prontuário</strong> é uma
            versão de acesso antecipado (&quot;Beta&quot;) oferecida
            inicialmente de forma gratuita para um grupo controle.
          </li>
          <li>
            Por se tratar de uma versão Beta e gratuita, os serviços são
            oferecidos “como estão” para permitir testes e avaliação e estão
            excluídos de quaisquer obrigações de indenização que a iClinic possa
            ter perante o USUÁRIO MÉDICO.
          </li>
          <li>
            O USUÁRIO MÉDICO reconhece os resultados gerados por meio
            funcionalidade de <strong>Otimização de Prontuário</strong> devem
            ser conferidos cuidadosamente. A precisão e a utilidade do resultado
            dependem da completude dos dados inseridos no prontuário assim como
            da calibragem da LLM, e não substituem a análise profissional e o
            julgamento clínico do médico. O médico é integralmente responsável
            por verificar a acurácia do resultado e assegurar que todas as
            informações relevantes estejam corretas e completas antes de tomar
            qualquer decisão clínica baseada nos dados fornecidos.
          </li>
          <li>
            A iClinic não faz representações ou garantias para os serviços em
            versão Beta, incluindo qualquer garantia de que os serviços estarão
            disponíveis de forma geral, ininterrupta ou livre de erros, ou que o
            conteúdo será seguro ou não será perdido ou danificado. Exceto na
            medida que for proibida por lei, a iClinic expressamente renuncia a
            todas as garantias para os serviços em versão Beta, incluindo
            quaisquer garantias implícitas de comercialização, adequação de
            qualidade, adequação a um propósito específico, não violação ou
            desfrute tranquilo, e quaisquer garantias decorrentes de qualquer
            curso de negociação ou uso comercial.
          </li>
          <li>
            A iClinic pode atualizar este Adendo ou seus serviços de tempos em
            tempos, por exemplo, devido a: (i) mudanças na lei ou nos requisitos
            regulamentares, (ii) razões de segurança ou proteção, (iii)
            circunstâncias além do nosso controle razoável, (iv) alterações que
            fazemos no decorrer normal do desenvolvimento dos nossos serviços,
            (v) para se adaptar às novas tecnologias. Avisaremos aos USUÁRIOS
            com pelo menos 30 dias de antecedência sobre alterações nestes
            Termos que tenham impacto negativo, por e-mail ou por notificação no
            produto. Todas as outras alterações entrarão em vigor assim que
            forem publicadas em nosso site. Se você não concordar com as
            alterações, deverá interromper a utilização de nossos serviços.
          </li>
          <li>
            Havendo qualquer conflito entre este Adendo e o Termo Geral,
            prevalecerá o documento mais específico.
          </li>
        </ol>
        <p>Última atualização: 06 de junho de 2024.</p>
      </TermsText>
      <TermsAcceptArea>
        <Checkbox
          checked={isAccepted}
          label="Concordo com os termos de uso"
          onChange={() => setIsAccepted(!isAccepted)}
        />
        <ContinueButton
          fullWidth
          isLoading={isLoading}
          disabled={!isAccepted}
          onClick={() => sendAcceptTerms()}
          data-ga="resumoai_term_continuar"
        >
          Continuar
        </ContinueButton>
      </TermsAcceptArea>
    </TermsContainer>
  );
};

export default Terms;
