/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import { FLOW } from './Flow';

export const VIEWSTEPS = {
  info: 1,
  user: 2,
  resume: 3,
  success: 4,
  adminSuccess: 5,
};

export const TITLE_MODAL = {
  default: '',
  info: '',
  user: '',
  resume: 'Resumo da assinatura',
  success: '',
  adminSuccess: '',
  key(n, hiring) {
    this.getTitleModal(hiring);
    return this[Object.keys(this)[n]];
  },
  getTitleModal(hiring) {
    hiring === FLOW.upgrade
      ? (this.user = 'Seleção de usuários')
      : hiring === FLOW.upsell || hiring === FLOW.upgradePerUser
        ? (this.info = 'Recursos adicionais')
        : '';
  },
};
