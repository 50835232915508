// External
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Internal
import { formatNumberToDecimal, pluralize } from 'shared/utils/strings';
import Dropdown from 'ui/new/dropdown';
import ListDetail from 'ui/new/list-detail';
import Payment from 'ui/new/payment';
import Text from 'ui/new/text';
import { CUSTOM_TEXT } from '../summary/constants';
import style from './SelectUsers.scss';

const SelectUsers = ({
  placeholder,
  children,
  totalUsers,
  promotionTotalValue,
  totalValue,
  productValue,
  productName,
}) => {
  const [isOpen, setToggleMenu] = useState(false);
  return (
    <div className={style.select} id="container-select-physicians">
      <div className={style.select__user}>
        <p
          className={style.select__title}
        >{`Você está contratando ${productName} iClinic.`}</p>
        <p className={style.select__description}>
          {`Este recurso adicional acrescentará um valor de R$ ${
            productValue && formatNumberToDecimal(productValue)
          } por usuário à sua fatura. `}
          {`Para quais usuários você gostaria de assinar ${productName}?`}
        </p>
      </div>

      <div className={style.select__detail} id="container-select-physicians">
        <Dropdown
          title={placeholder}
          isOpenProp={isOpen}
          setToggleMenuProp={setToggleMenu}
        >
          <div className={style.select__detail__menu}>{children}</div>
        </Dropdown>
        <div className={style.select__detail__list}>
          {totalUsers > 0 && (
            <ListDetail.Row>
              <Text>{`${totalUsers} ${pluralize(
                totalUsers,
                'usuário selecionado',
                'usuários selecionados',
              )}`}</Text>
              <Payment.Price
                text={CUSTOM_TEXT.total}
                promotion={
                  promotionTotalValue &&
                  formatNumberToDecimal(promotionTotalValue)
                }
                total={formatNumberToDecimal(totalValue)}
              />
            </ListDetail.Row>
          )}
        </div>
      </div>
    </div>
  );
};

SelectUsers.defaultProps = {
  placeholder: 'Selecione os profissionais',
  totalUsers: 0,
  promotionTotalValue: null,
  totalValue: 0,
  productValue: '',
  productName: '',
};

SelectUsers.propTypes = {
  placeholder: PropTypes.string,
  children: PropTypes.node.isRequired,
  totalUsers: PropTypes.number,
  totalValue: PropTypes.number,
  productValue: PropTypes.string,
  productName: PropTypes.string,
  promotionTotalValue: PropTypes.number,
};

export default SelectUsers;
