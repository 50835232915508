import React from 'react';
import { Formik, Form } from 'formik';
import classnames from 'classnames';

import {
  MaterialUICore,
  Typography,
  Button,
  Grid,
  Dialog,
} from '@iclinic/design-system';
import { ChargeBackForm } from 'features/onlinePayment/state/manage/types';
import Fields from './Fields';
import { validationSchema, initialValues } from './validationSchema';
import useStyles from './Modal.styles';

const {
  DialogActions,
  DialogContentText,
  DialogContent,
  useMediaQuery,
  useTheme,
} = MaterialUICore;

type ChargeBackModalProps = {
  open: boolean;
  onClose(): void;
};

export default function ChargeBackModal({
  open = false,
  onClose,
}: ChargeBackModalProps): JSX.Element {
  const classes = useStyles();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      scroll="paper"
      fullScreen={isXs}
      fullWidth
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(chargeBackForm: ChargeBackForm) => {
          // TODO - dispatch form send
          console.log(chargeBackForm);
        }}
      >
        <Form>
          <Typography variant="h4" classes={{ root: classes.title }}>
            Confirme o estorno
          </Typography>
          <DialogContent>
            <DialogContentText variant="body2">
              Valor original da cobrança: <strong>R$ 100,00</strong>
            </DialogContentText>
            <Fields />
          </DialogContent>
          <DialogActions disableSpacing className={classes.actions}>
            <Grid
              xs={12}
              md="auto"
              item
              className={classnames(classes.itemAction, classes.actionConfirm)}
            >
              <Button type="submit" variant="contained" fullWidth>
                Confirmar estorno
              </Button>
            </Grid>
            <Grid
              xs="auto"
              item
              className={classnames(classes.itemAction, classes.actionCancel)}
            >
              <Button onClick={onClose} color="transparent">
                Cancelar
              </Button>
            </Grid>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
}
