import React from 'react';

import { STEP_LINKS_INSTALL } from './constants';
import styles from './steppers.scss';

const cssClass = 'link-stepper';
export const getSteps = () => [
  'Obtenha uma certificação digital',
  'Instale seu certificado digital neste computador',
  'Adicione a extensão no seu navegador',
];

export const getStepContent = (step: number) => {
  switch (step) {
    case 0:
      return (
        <div>
          Para ter uma assinatura válida judicialmente, é necessário contar com
          uma certificação digital A1, A3 ou A5, que siga os padrões da
          ICP-Brasil (Infraestrutura de Chaves Públicas Brasileira). Caso não
          tenha, entre em contato com uma
          <p>
            <a
              className={styles[cssClass]}
              target="_blank"
              rel="noopener noreferrer"
              href={STEP_LINKS_INSTALL.Certificate}
            >
              autoridade certificadora.
            </a>
          </p>
        </div>
      );
    case 1:
      return (
        <div>
          Após adquirir seu certificado, instale o software da sua certificadora
          neste computador. Para saber como baixá-lo,{' '}
          <a
            className={styles[cssClass]}
            target="_blank"
            rel="noopener noreferrer"
            href={STEP_LINKS_INSTALL.Install}
          >
            clique aqui.
          </a>
        </div>
      );
    case 2:
      return (
        <div>
          Para finalizar, instale a extensão Web PKI neste navegador{' '}
          <a className={styles[cssClass]} href={STEP_LINKS_INSTALL.Webpki}>
            clique aqui.
          </a>
        </div>
      );
    default:
      return 'Unknown step';
  }
};
