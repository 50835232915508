import merge from 'lodash.merge';

import * as types from './types';

export const initialState = {
  completed: false,
  fetching: false,
  hasSaved: true,
  prescription: {},
};

const memed = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SDK.ACTION:
    case types.FETCH_SDK.SUCCESS:
    case types.FETCH_SDK.FAILURE:
    case types.PRESCRIPTION_CREATED.SUCCESS:
    case types.PRESCRIPTION_UPDATE.SUCCESS:
    case types.PRESCRIPTION_FINISHED.SUCCESS:
    case types.PRESCRIPTION_ADD_ITEMS.SUCCESS:
    case types.PRESCRIPTION_ADD_ITEM.SUCCESS:
      return merge({}, state, action.payload);
    case types.PRESCRIPTION_CLEAR.SUCCESS:
      return {
        ...state,
        hasSaved: false,
        prescription: {},
      };
    default:
      return state;
  }
};

export default memed;
