import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalDataTrack,
  LocalVideoTrack,
  Room,
} from 'twilio-video';

import { captureException } from 'shared/utils/handlerErrors';
import useLocalTracks from 'features/video-conference/hooks/useLocalTracks';
import useRoom from 'features/video-conference/hooks/useRoom';
import useHandleRoomDisconnection from 'features/video-conference/hooks/useHandleRoomDisconnection';
import useActiveSinkId from 'features/video-conference/hooks/useActiveSinkId';
import useScreenShareToggle from 'features/video-conference/hooks/useScreenShareToggle';
import useHandleTrackPublicationFailed from 'features/video-conference/hooks/useHandleTrackPublicationFailed';
import useRestartAudioTrackOnDeviceChange from 'features/video-conference/hooks/useRestartAudioTrackOnDeviceChange';
import {
  RoomTypes,
  ErrorCallback,
  BackgroundSettings,
} from 'features/video-conference/constants/types';
import { useHandleImages } from 'features/video-conference/hooks/useHandleImages';
import { useBackgroundSettings } from 'features/video-conference/hooks/useBackgroundSettings';

interface ConferenceContextData {
  getAudioAndVideoTracks: () => Promise<void>;
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions,
  ) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  removeLocalVideoTrack: () => void;
  removeLocalAudioTrack: () => void;
  localTracks: (LocalAudioTrack | LocalVideoTrack | LocalDataTrack)[];
  isAcquiringLocalTracks: boolean;
  room: Room | null;
  isConnecting: boolean;
  setIsConnecting: (value: boolean) => void;
  connect: (token: string) => Promise<void>;
  setConferenceTokenExpired: (value: boolean) => void;
  conferenceFinished: boolean;
  setConferenceFinished: (value: boolean) => void;
  setIsPhysician: (value: boolean) => void;
  isPhysician: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  conferenceId: number;
  setConferenceId(conferenceId: number): void;
  isRoomCreated: boolean;
  setIsRoomCreated: (value: boolean) => void;
  isRecording: boolean;
  setIsRecording: (value: boolean) => void;
  roomType: RoomTypes;
  setRoomType: (value: RoomTypes) => void;
  isSharingScreen: boolean;
  toggleScreenShare: () => void;
  reconnectingBlob: Blob;
  onError: ErrorCallback;
  onErrorCallback: ErrorCallback;
  startedRoomTime: string;
  setStartedRoomTime: (value: string) => void;
  isBackgroundSelectionOpen: boolean;
  setIsBackgroundSelectionOpen: (value: boolean) => void;
  backgroundSettings: BackgroundSettings;
  setBackgroundSettings: (settings: BackgroundSettings) => void;
  isMicrophoneEnable: boolean;
  setIsMicrophoneEnable: (value: boolean) => void;
}
interface ConferenceProviderProps {
  children: React.ReactNode;
  onError: ErrorCallback;
}

export const ConferenceContext = createContext<ConferenceContextData>(
  {} as ConferenceContextData,
);

export const ConferenceProvider = ({
  children,
  onError = () => undefined,
}: ConferenceProviderProps) => {
  const [conferenceFinished, setConferenceFinished] = useState(false);
  const [isPhysician, setIsPhysician] = useState(false);
  const [conferenceId, setConferenceId] = useState<number>(null!);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [isRoomCreated, setIsRoomCreated] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [roomType, setRoomType] = useState<RoomTypes>(RoomTypes.p2p);
  const [startedRoomTime, setStartedRoomTime] = useState<string>(null!);
  const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] =
    useState(false);
  const [isMicrophoneEnable, setIsMicrophoneEnable] = useState<boolean>(false);
  const [isConnecting, setIsConnecting] = useState<boolean>(false);

  const onErrorCallback: ErrorCallback = useCallback(
    (err, isTwilioError = false) => {
      const message = isTwilioError
        ? 'video-conference - twilio error'
        : 'video-conference';
      onError(err);
      captureException(`${message} - ${err}`);
    },
    [onError],
  );

  const { reconnectingBlob } = useHandleImages(onErrorCallback);

  const {
    getAudioAndVideoTracks,
    localTracks,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getLocalVideoTrack,
    getLocalAudioTrack,
  } = useLocalTracks();

  const { connect, room } = useRoom(localTracks, onErrorCallback);
  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(
    room,
    onErrorCallback,
  );

  useHandleRoomDisconnection(
    room,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
    onErrorCallback,
  );
  useHandleTrackPublicationFailed(
    room,
    onErrorCallback,
    localTracks as (LocalAudioTrack | LocalVideoTrack)[],
  );
  useRestartAudioTrackOnDeviceChange(
    localTracks as (LocalAudioTrack | LocalVideoTrack)[],
  );

  const videoTrack = localTracks.find(
    (track) => !track.name.includes('screen') && track.kind === 'video',
  ) as LocalVideoTrack | undefined;
  const { backgroundSettings, setBackgroundSettings } = useBackgroundSettings(
    videoTrack,
    room,
  );

  const contextValue = useMemo(
    () => ({
      getAudioAndVideoTracks,
      getLocalVideoTrack,
      getLocalAudioTrack,
      removeLocalVideoTrack,
      removeLocalAudioTrack,
      isAcquiringLocalTracks,
      localTracks,
      connect,
      room,
      isConnecting,
      setIsConnecting,
      conferenceFinished,
      setConferenceFinished,
      setIsPhysician,
      isPhysician,
      activeSinkId,
      setActiveSinkId,
      conferenceId,
      setConferenceId,
      isRoomCreated,
      setIsRoomCreated,
      isRecording,
      setIsRecording,
      roomType,
      setRoomType,
      isSharingScreen,
      toggleScreenShare,
      reconnectingBlob,
      onError,
      onErrorCallback,
      startedRoomTime,
      setStartedRoomTime,
      isBackgroundSelectionOpen,
      setIsBackgroundSelectionOpen,
      backgroundSettings,
      setBackgroundSettings,
      isMicrophoneEnable,
      setIsMicrophoneEnable,
    }),
    [
      activeSinkId,
      backgroundSettings,
      conferenceFinished,
      conferenceId,
      connect,
      getAudioAndVideoTracks,
      getLocalAudioTrack,
      getLocalVideoTrack,
      isAcquiringLocalTracks,
      isBackgroundSelectionOpen,
      isConnecting,
      setIsConnecting,
      isMicrophoneEnable,
      isPhysician,
      isRecording,
      isRoomCreated,
      isSharingScreen,
      localTracks,
      onError,
      onErrorCallback,
      reconnectingBlob,
      removeLocalAudioTrack,
      removeLocalVideoTrack,
      room,
      roomType,
      setActiveSinkId,
      setBackgroundSettings,
      startedRoomTime,
      toggleScreenShare,
    ],
  ) as ConferenceContextData;

  return (
    <ConferenceContext.Provider value={contextValue}>
      {children}
    </ConferenceContext.Provider>
  );
};

export function useConference(): ConferenceContextData {
  const context = useContext(ConferenceContext);

  if (!context) {
    throw new Error('useConference must be used within an ConferenceProvider');
  }
  return context;
}
