import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  getSettingsInsurances,
  getUserPermissions,
} from 'features/settings/scheduleOnline/state/selectors';
import {
  onChangeAllCheckInsurances,
  onChangeCheckInsurance,
} from 'features/settings/scheduleOnline/state/actions';
import { detailOpen } from 'state/upgradeAddon/actions';
import SUBSCRIPTION_PERMISSIONS from 'shared/constants/subscriptionPermissions';
import {
  Box,
  MaterialUICore,
  Typography,
  Button,
} from '@iclinic/design-system';
import { insuranceAttendanceType } from 'features/settings/scheduleOnline/state/constants';
import { TitleCard, SubTitle } from '..';
import InsuranceComponent from './Insurance';
import { IInsurances } from 'features/settings/scheduleOnline/state/types';
import formatMessage from '../../content';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles(() => ({
  label: {
    fontSize: '10px',
    textDecoration: 'underline',
  },
}));

export default function InsuranceSection() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const permissions = useSelector(getUserPermissions);
  const hasTelemedicine = permissions.includes(
    SUBSCRIPTION_PERMISSIONS.Telemedicine,
  );
  const shouldOpenTelemedicineUpgradeModal = (insuranceAttendance: string) => {
    const shouldOpenUpgradeModal =
      insuranceAttendance.includes(insuranceAttendanceType.Telemedicine) &&
      !hasTelemedicine;

    if (shouldOpenUpgradeModal) detailOpen('telemedicine', 'upgrade');

    return shouldOpenUpgradeModal;
  };

  const handleOnChange = (insuranceId: number, insuranceAttendance: string) => {
    const shouldOpenUpgradeModal = shouldOpenTelemedicineUpgradeModal(
      insuranceAttendance,
    );
    if (!shouldOpenUpgradeModal)
      dispatch(onChangeCheckInsurance(insuranceId, insuranceAttendance));
  };

  const updateAllInsurances = (insuranceAttendance: string) => {
    const shouldOpenUpgradeModal = shouldOpenTelemedicineUpgradeModal(
      insuranceAttendance,
    );
    if (!shouldOpenUpgradeModal)
      dispatch(onChangeAllCheckInsurances(insuranceAttendance));
  };

  const insurances: IInsurances[] = useSelector(getSettingsInsurances);

  const insurancePresentialSelect = insurances.filter(
    (ins) => ins.has_online_schedule_active === true,
  );
  const insuranceTelemedicineSelect = insurances.filter(
    (ins) => ins.allow_online_scheduling_telemedicine === true,
  );

  const textUpdateAll = (collection: IInsurances[]) =>
    collection.length === insurances.length
      ? formatMessage({ id: '@online-scheduling:insurances-disable-all' })
      : formatMessage({ id: '@online-scheduling:insurances-enable-all' });

  return (
    <>
      <TitleCard
        label={formatMessage({ id: '@online-scheduling:insurances-label' })}
      />
      <Box mb={3}>
        <SubTitle
          label={formatMessage({ id: '@online-scheduling:insurances-text-1' })}
        />
        <SubTitle
          label={formatMessage({ id: '@online-scheduling:insurances-text-2' })}
        />
      </Box>
      <Box maxWidth={290}>
        <Box
          mb={2}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
        >
          <Box ml={1} width={75} textAlign="center">
            <Typography variant="caption" bold>
              {formatMessage({ id: '@online-scheduling:insurances-in-person' })}
            </Typography>
            <Button
              id="change-all-in-person"
              classes={{
                label: classes.label,
              }}
              color="transparent"
              size="small"
              onClick={() =>
                updateAllInsurances(insuranceAttendanceType.Presential)
              }
            >
              {textUpdateAll(insurancePresentialSelect)}
            </Button>
          </Box>
          <Box ml={3} mr={1} width={75} textAlign="center">
            <Typography variant="caption" bold>
              {formatMessage({
                id: '@online-scheduling:insurances-telemedicine',
              })}
            </Typography>
            <Button
              id="change-all-telemedicine"
              classes={{
                label: classes.label,
              }}
              color="transparent"
              size="small"
              onClick={() =>
                updateAllInsurances(insuranceAttendanceType.Telemedicine)
              }
            >
              {textUpdateAll(insuranceTelemedicineSelect)}
            </Button>
          </Box>
        </Box>
        {insurances.map((insurance) => (
          <InsuranceComponent
            key={`insurance-${insurance.id}`}
            insurance={insurance}
            handleOnChangeInPerson={() =>
              handleOnChange(insurance.id, insuranceAttendanceType.Presential)
            }
            handleOnChangeTelemedicine={() =>
              handleOnChange(insurance.id, insuranceAttendanceType.Telemedicine)
            }
          />
        ))}
      </Box>
    </>
  );
}
