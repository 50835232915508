import React from 'react';

import { Banner, Plans } from '../components';
import * as S from './AdditionalPlans.styles';

export const AdditionalPlans = () => (
  <S.PageWrapper variant="outlined">
    <S.Container>
      <Banner />
      <Plans />
    </S.Container>
  </S.PageWrapper>
);
