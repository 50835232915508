import {
  SELLER_CPF_SAVE_ERROR,
  SELLER_SAVE_ERROR,
  SELLER_CNPJ_SAVE_ERROR,
} from '../errorMessages';
import { captureException } from 'shared/utils/handlerErrors';

type KnownError = {
  data: {
    cpf: string[];
    business_cnpj: string[];
  };
};

export function handleCreateSellerErrors(error: KnownError) {
  if (error instanceof Error) {
    captureException(error);
  }

  const cpfError = error.data?.cpf?.[0];
  const cnpjError = error.data?.business_cnpj?.[0];

  if (cpfError) {
    return SELLER_CPF_SAVE_ERROR;
  }
  if (cnpjError) {
    return SELLER_CNPJ_SAVE_ERROR;
  }

  return SELLER_SAVE_ERROR;
}
