import React, { useState } from 'react';

import { Question as QuestionData } from '../../types';
import Question from './Question';
import * as S from './FAQ.styles';

interface FAQProps {
  questions: QuestionData[];
}

const FAQ = ({ questions }: FAQProps) => {
  const [expandedQuestion, setExpandedQuestion] = useState<string | null>(null);

  const handleQuestionExpanded = (name: string) => {
    setExpandedQuestion((prev) => {
      if (prev === name) return null;

      return name;
    });
  };

  return (
    <S.Section>
      <S.SectionContent>
        <S.SectionTitle variant="xl">Perguntas Frequentes</S.SectionTitle>

        {questions.map(({ identifier, question, answer }) => (
          <Question
            key={identifier}
            name={identifier}
            expanded={expandedQuestion === identifier}
            question={question}
            answer={answer}
            onClick={handleQuestionExpanded}
          />
        ))}
      </S.SectionContent>
    </S.Section>
  );
};

export default FAQ;
