import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Loader } from '@iclinic/design-system';

import getSnackbarPayload from 'features/tissInvoicing/utils/getSnackbarPayload';
import { actions } from 'features/tissInvoicing/state/lot';
import {
  getLotDataState,
  getLotViewState,
  getSnackbarState,
} from 'features/tissInvoicing/state/lot/selectors';
import Snackbar from 'features/tissInvoicing/components/ui/Snackbar';
import LotConfigOptions from './LotConfigOptions';
import Table from './Table';
import Header from './Header';
import NoGuidesMessage from './NoGuidesMessage';
import FooterButtons from './FooterButtons';
import * as Styles from './styles';
import Subheader from './Subheader';

interface MatchParams {
  lotId?: string;
  typeGuide?: string;
}

type GetTitle = { isGuideSadt: boolean, isNewLot: boolean, lot_number: number | null }

const getTitle = ({ isGuideSadt, isNewLot, lot_number }: GetTitle): string => {
  if (isNewLot) {
    return `Novo Lote (${isGuideSadt ? 'SP/SADT' : 'Consulta'})`
  }

  return `Lote Nº ${lot_number || ''}`
}

export default function CreateLot({
  match,
}: RouteComponentProps<MatchParams>): JSX.Element {
  const dispatch = useDispatch();

  const isGuideSadt = match.path.includes('sadt');
  const isNewLot = match.path.includes('novo');
  const lotId = String(match.params.lotId);

  const snackbar = useSelector(getSnackbarState);
  const { lot, loading: loadingLotData } = useSelector(getLotDataState);
  const { loading: loadingLotViewData } = useSelector(getLotViewState);

  const handleCloseSnackbar = () =>
    dispatch(actions.setSnackbarContent(getSnackbarPayload()));

  const loading = useMemo(
    () => loadingLotViewData.fetchConfigOptions || loadingLotData,
    [loadingLotData, loadingLotViewData],
  );

  useEffect(() => {
    dispatch(actions.fetchLotConfigOptions());
  }, [dispatch, isNewLot]);

  useEffect(() => {
    if (!isNewLot) {
      dispatch(actions.fetchLotData({ id: lotId }));
    }
  }, [dispatch, lotId, isNewLot]);

  if (loading) {
    return (
      <Styles.LoaderContainer>
        <Loader
          size={20}
          title={`Carregando informações${
            isNewLot ? ' de configuração' : ''
          } do lote`}
        />
      </Styles.LoaderContainer>
    );
  }

  return (
    <Styles.Container>
      <Snackbar data={snackbar} onClose={handleCloseSnackbar} />

      <Header
        title={getTitle({ isNewLot, isGuideSadt, lot_number: lot?.lot_number })}
        isNewLot={isNewLot}
      />

      <LotConfigOptions lotId={lotId} isNewLot={isNewLot} />

      {isNewLot ? (
        <NoGuidesMessage />
      ) : (
        <Styles.Content>
          <Subheader />

          <Table lotId={lotId} />

          <FooterButtons isClosed={lot?.is_closed} id={lotId} />
        </Styles.Content>
      )}
    </Styles.Container>
  );
}
