import {
  MaterialIcons,
  MaterialUICore,
  tokens,
  Box,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { LocalPhone } = MaterialIcons;
const { color } = tokens;

export const ButtonContainer = styled(Box)(() => ({
  '& .MuiButton-root': {
    backgroundColor: color.feedback.error.main,
    minWidth: '48px',
    height: '48px',
    padding: '0',
    '& .MuiButton-label': {
      width: '24px',
    },
    '&:hover, &:active': {
      backgroundColor: color.feedback.error.dark,
    },
  },
}));

export const LocalPhoneIcon = styled(LocalPhone)(() => ({
  transform: 'rotate(136deg)',
}));
