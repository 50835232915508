import { Body, Heading, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const { color, spacing } = tokens;

export const PriceMain = styled('div')({
  gridArea: 'description',
  display: 'grid',
  gridTemplateAreas: `
    "value-small value-small value-small value-small"
    "prefix value-bigger decimal description"
    "prefix value-bigger period description"
  `,
  gridTemplateColumns: 'auto auto auto 1fr',
  alignItems: 'center',
  columnGap: spacing.nano,

  '& .MuiTypography-root': {
    color: color.neutral[900],
  },
});

export const PricePrefix = styled(Body)({
  gridArea: 'prefix',
});

export const PriceValueSmall = styled(Body)({
  gridArea: 'value-small',
  marginBottom: spacing.base,
  '& span': {
    textDecoration: 'line-through',
  },
});

export const PriceValueBigger = styled(Heading)({
  gridArea: 'value-bigger',
});

export const PriceDecimal = styled(Body)(({ theme }) => ({
  gridArea: 'decimal',
  [theme.breakpoints.up('sm')]: {
    marginBottom: `-${spacing.nano}`,
  },
}));

export const PricePeriod = styled(Body)(({ theme }) => ({
  gridArea: 'period',
  [theme.breakpoints.up('sm')]: {
    marginTop: `-${spacing.nano}`,
  },
}));

export const PriceDescription = styled(Body)({
  gridArea: 'description',
  marginLeft: spacing.sm,
});
