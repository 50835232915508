import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SpeechToTextState } from './types';

export const initialState: SpeechToTextState = {
  isOpen: false,
  transcript: '',
  resumeMarkdown: '',
  lastRequestId: '',
};

const name = 'summaryIA';

const speechToTextSlice = createSlice({
  name: `@webapp/${name}`,
  initialState,
  reducers: {
    updateTranscript: (state, action: PayloadAction<string>) => ({
      ...state,
      transcript: action.payload,
    }),
    updateResumeMarkdown: (state, action: PayloadAction<string>) => ({
      ...state,
      resumeMarkdown: action.payload,
    }),
    triggerIsOpen: (state) => ({
      ...state,
      isOpen: !state.isOpen,
    }),
    updateLastRequestId: (state, action: PayloadAction<string>) => ({
      ...state,
      lastRequestId: action.payload,
    }),
  },
});

export const actions = {
  ...speechToTextSlice.actions,
};

export default speechToTextSlice.reducer;
