import React from 'react';
import ContentLoader from 'react-content-loader';

import { MaterialUICore } from '@iclinic/design-system';
import { ResponsiveCard } from '../..';

const { useMediaQuery, useTheme } = MaterialUICore;

const values = {
  backgroundColor: '#ECEFF1',
  foregroundColor: '#F8F9FA',
  speed: 1,
};

const CardCreditCardLoader = (): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <ResponsiveCard variant="outlined">
      <ContentLoader
        speed={values.speed}
        width="100%"
        height={matches ? 318 : 310}
        backgroundColor={values.backgroundColor}
        foregroundColor={values.foregroundColor}
        uniqueKey="credit-card-loader"
      >
        <rect x="0" y="0" rx="2" ry="2" width="274" height="40" />
        <rect x="0" y="48" rx="2" ry="2" width="654" height="16" />
        <rect
          x="0"
          y="84"
          rx="2"
          ry="2"
          width={matches ? '396' : '100%'}
          height="58"
        />
        <rect
          x="0"
          y="164"
          rx="2"
          ry="2"
          width={matches ? '396' : '100%'}
          height="58"
        />
        <rect x="0" y="250" rx="2" ry="2" width="240" height="16" />
        <rect x="0" y="298" rx="2" ry="2" width="515" height="16" />
      </ContentLoader>
    </ResponsiveCard>
  );
};

export default CardCreditCardLoader;
