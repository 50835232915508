import React, { useState } from 'react';
import CollapseButton from 'ui/new/collapse-button';
import { List, ListItem } from 'ui/new/list';
import { Functionalities } from 'state/billing/subscription/types';

type WrapperProps = {
  functionalities: Functionalities[];
};

export default function CollapseWrapper({
  functionalities = [],
}: WrapperProps): JSX.Element {
  const [isOpen, toogleIsOpen] = useState(false);
  return (
    <div>
      <CollapseButton
        open={isOpen}
        onClick={() => toogleIsOpen(!isOpen)}
        title="Ver Funcionalidades"
      >
        <List>
          {functionalities && functionalities.map((
            functionalitie: Functionalities,
          ) => (
            <ListItem
              key={functionalitie.slug}
              icon="check_circle_outline"
              title={functionalitie.name}
            />
          ))}
        </List>
      </CollapseButton>
    </div>
  );
}
