import { MaterialIcons } from '@iclinic/design-system';
import React, { forwardRef } from 'react';

import CollapsibleFormSection from 'features/tissInvoicing/components/ui/CollapsibleFormSection';
import ExpenseConfig from './components/ExpensesConfig/ExpenseConfig';
import ExpenseSectionList from './components/ExpenseSectionList/ExpenseSectionList';

const { AttachMoney } = MaterialIcons;

interface ExpenseSectionProps {}

const ExpenseSection = (
  _: ExpenseSectionProps,
  ref: React.Ref<HTMLButtonElement>,
): JSX.Element => (
  <CollapsibleFormSection
    sectionTitle="Despesas"
    sectionIcon={<AttachMoney />}
    sectionIdentifier="expenses_data"
    ref={ref}
  >
    <ExpenseConfig>
      <ExpenseSectionList />
    </ExpenseConfig>
  </CollapsibleFormSection>
);

export default forwardRef(ExpenseSection);
