import { tokens, MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export const useStyles = makeStyles(() => ({
  container: {
    height: '570px',
  },
  neutral: { color: tokens.color.neutral[500] },
  centerFlex: {
    flexDirection: 'column',
    width: '100%',
    height: '560px',
  },
  editButton: {
    outline: 'none',
    textDecoration: 'none',
    '& svg': {
      fill: tokens.color.neutral[500],
    },
    '&:hover, &:focus': {
      color: tokens.color.neutral[0],
      outline: 'none',
      textDecoration: 'none',
    },
  },
  button: {
    outline: 'none',
    border: `1px solid ${tokens.color.neutral[100]}`,
    borderRadius: tokens.border.radius.sm,
    color: tokens.color.neutral[700],
  },
  icon: {
    marginRight: tokens.spacing.nano,
    color: tokens.color.neutral[500],
    fontSize: tokens.font.size.sm,
  },
}));
