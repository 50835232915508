import React from 'react';
import ContentLoader from 'react-content-loader';
import { Grid } from '@iclinic/design-system';

const values = {
  backgroundColor: '#ECEFF1',
  foregroundColor: '#F8F9FA',
  speed: 1,
};

const Loader = () => (
  <ContentLoader
    speed={values.speed}
    width="100%"
    height={100}
    backgroundColor={values.backgroundColor}
    foregroundColor={values.foregroundColor}
    data-testid="amount-charged-input-content-loader"
    uniqueKey="amount-charged-input"
  >
    <rect x="25%" y="0" rx="0" ry="0" width="50%" height="100" />
  </ContentLoader>
);

export const LoaderColumnInfos = () => (
  <Grid spacing={3} container justifyContent="center" alignItems="center">
    <Grid item xs={12} md={3}>
      <Loader />
    </Grid>
    <Grid item xs={12} md={3}>
      <Loader />
    </Grid>
    <Grid item xs={12} md={3}>
      <Loader />
    </Grid>
    <Grid item xs={12} md={3}>
      <Loader />
    </Grid>
  </Grid>
);
