import React from 'react';

// Internal
import Dropdown from 'ui/new/dropdown';
import { ReturnFlow } from 'state/marketing/emailFlow/patient/types';

import style from './DropdownReturnFlows.scss';

type DropdownReturnFlowsProps = {
  name?: string,
  description?: string,
  isOpen?: boolean,
  children: React.ReactNode,
  toggleOpen: (isOpen: boolean) => void,
  selectedReturnFlow: ReturnFlow,
};

export default function DropdownReturnFlows({
  isOpen = false,
  name = '',
  description = 'Selecione',
  toggleOpen,
  children,
  selectedReturnFlow,
}: DropdownReturnFlowsProps): JSX.Element {
  const returnFlowId = !!selectedReturnFlow && selectedReturnFlow.id;
  return (
    <div className={style['dropdown-flows']}>
      <Dropdown
        icon={false}
        name={name}
        title={returnFlowId ? selectedReturnFlow.name : description}
        isOpenProp={isOpen}
        setToggleMenuProp={toggleOpen}
      >
        {children}
      </Dropdown>
    </div>
  );
}
