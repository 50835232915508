import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled, makeStyles, ListItemIcon: ListItemIconMUI } = MaterialUICore;

export const ListItemIcon = styled(ListItemIconMUI)({
  minWidth: 'auto',
  marginRight: tokens.spacing.base,
});

export const Footer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: tokens.spacing.xs,
});

export const useStyles = makeStyles({
  card: {
    minHeight: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  switch: {
    margin: 0,
    height: 40,
    paddingRight: tokens.spacing.base,
  },
  menu: {
    marginTop: tokens.spacing.sm,
    padding: 0,
  },
  info: {
    background: tokens.color.feedback.info.light,
    color: tokens.color.feedback.info.dark,
  },
});
