import { PlansToUpgrade, UpgradeCallContent } from './types';

export const getContentBySubscriptionPlan = (
  content: UpgradeCallContent | undefined,
  subscriptionPlan: string,
) => content && content[subscriptionPlan];

export const isWeekDay = (date: Date) => date.getDay() !== 6 && date.getDay() !== 0;

export const convertToBrazilianTime = (
  date: Date,
  timezone = 'America/Sao_Paulo',
) => new Date(
    date.toLocaleString('en-US', {
      timeZone: timezone,
    }),
  );

export const isOfficeHour = (date: Date) => {
  const convertedDate = convertToBrazilianTime(date);
  return convertedDate.getHours() >= 8 && convertedDate.getHours() <= 17;
};

export const shouldOpenChat = (
  totalPayingUsers: number,
  planToUpgrade: PlansToUpgrade,
) => {
  const date = new Date();
  const isOfficeTime = isWeekDay(date) && isOfficeHour(date);
  const isMiddleTouchAccount = totalPayingUsers >= 4;
  const isBasicUpgrade = planToUpgrade === 'plus';

  return isOfficeTime && isMiddleTouchAccount && !isBasicUpgrade;
};
