import React, { useEffect } from 'react';
import { LocalAudioTrack } from 'twilio-video';
import { MaterialIcons } from '@iclinic/design-system';

import useLocalAudioToggle from 'features/video-conference/hooks/useLocalAudioToggle';
import ToggleButton from 'features/video-conference/components/TeamComponents/ToggleButton';
import { buttonsTitle, MICROPHONE } from 'features/video-conference/constants';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useVerifyDevicePermission } from 'features/video-conference/hooks/useVerifyDevicePermission';

const { Mic, MicOff } = MaterialIcons;
interface Props {
  withLabel?: boolean;
}

export default function MicrophoneButton({ withLabel = false }: Props) {
  const {
    isPhysician,
    conferenceId,
    localTracks,
    onError,
    getLocalAudioTrack,
    room,
    setIsMicrophoneEnable,
  } = useConference();
  const { localParticipant, remoteParticipant } = useParticipantContext();
  const { isAudioEnabled, toggleAudioEnabled } = useLocalAudioToggle();
  const { hasPermission } = useVerifyDevicePermission({
    deviceType: 'microphone',
  });

  const twilioLocalParticipant = room?.localParticipant;
  const hasAudioTrack = localTracks.some((track) => track.kind === 'audio');

  useEffect(() => {
    if (withLabel) setIsMicrophoneEnable(isAudioEnabled);
  }, [isAudioEnabled, setIsMicrophoneEnable, withLabel]);

  const title = isAudioEnabled
    ? buttonsTitle.AUDIO_DISABLED
    : buttonsTitle.AUDIO_ENABLED;

  const handleToggleAudio = () => {
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'telemedicine_microphone',
      buttonLocation: withLabel ? 'toolbar' : 'wait_room',
      action: isAudioEnabled ? 'disabled' : 'enabled',
    });

    if (!hasAudioTrack || !hasPermission) {
      getLocalAudioTrack()
        .then((track: LocalAudioTrack) =>
          twilioLocalParticipant?.publishTrack(track),
        )
        .catch(onError);
      return;
    }

    toggleAudioEnabled();
  };

  return (
    <ToggleButton
      onClick={handleToggleAudio}
      switchedOn={isAudioEnabled && hasAudioTrack && hasPermission}
      variant="error"
      updateOn={false}
      label={withLabel ? MICROPHONE : ''}
      title={title}
      icon={
        isAudioEnabled && hasAudioTrack && hasPermission ? <Mic /> : <MicOff />
      }
      dataGa="telemedicine_microphone"
      dataSp="telemedicine_microphone"
    />
  );
}
