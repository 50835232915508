import React from 'react';

import * as Styles from './styles';

type PhysiciansAndClinicsParams = {
  name: string;
  id: string;
};

interface FilterAppointmentGuideProps {
  onChange(value: string): void;
  physicians?: PhysiciansAndClinicsParams[];
  clinics?: PhysiciansAndClinicsParams[];
  clinicId?: number;
  physicianId?: number;
}

function FilterAppointmentGuide(props: Readonly<FilterAppointmentGuideProps>) {
  const { onChange, clinics, physicians, clinicId, physicianId } = props;

  return (
    <Styles.Header>
      <Styles.Block>
        Clínica selecionada
        <Styles.Select id="clinic" disabled>
          {clinics?.map((c) => (
            <option
              key={c.name}
              value={c.id}
              selected={Number(c.id) === clinicId}
            >
              {c.name}
            </option>
          ))}
        </Styles.Select>
      </Styles.Block>
      <Styles.Block>
        Para o profissional
        <Styles.Select
          id="physician"
          onChange={(event) => onChange(event.target.value)}
        >
          {physicians?.map((p) => (
            <option
              key={p.name}
              value={p.id}
              selected={Number(p.id) === physicianId}
            >
              {p.name}
            </option>
          ))}
          <option value="">Todos</option>
        </Styles.Select>
      </Styles.Block>
    </Styles.Header>
  );
}

export default FilterAppointmentGuide;
