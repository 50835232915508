import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { SSOTokenRawData } from './types';

export interface IClinicPayState {
  ssoToken?: {
    data?: SSOTokenRawData | null;
    status?: RequestStatus;
  };
}

export const initialState: IClinicPayState = {};
const name = 'iclinic-pay';

const IClinicPaySlice = createSlice({
  name,
  initialState,
  reducers: {
    fetchSSOToken: (state) => {
      state.ssoToken = { status: RequestStatus.Pending };
    },
    fetchSSOTokenSuccess: (state, action: PayloadAction<SSOTokenRawData>) => {
      state.ssoToken = {
        status: RequestStatus.Success,
        data: action.payload,
      };
    },
    fetchSSOTokenFailure: (state) => {
      state.ssoToken = { status: RequestStatus.Error };
    },
  },
});

export default IClinicPaySlice.reducer;

export const {
  fetchSSOToken,
  fetchSSOTokenSuccess,
  fetchSSOTokenFailure,
} = IClinicPaySlice.actions;
