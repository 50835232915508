import {
  Body,
  Card as DSCard,
  Heading,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing } = tokens;

const BORDER_WIDTH = '3px';
const NUMBER_LINE_HEIGHT = '34px';

export const Section = styled('section')({
  background: `linear-gradient(270deg, ${color.feedback.info.main} 0%, ${color.primary.main} 100%)`,
});

export const Content = styled('div')(({ theme }) => ({
  padding: `${spacing.md} ${spacing.sm}`,
  maxWidth: 960,
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: spacing.md,

  [theme.breakpoints.up('sm')]: {
    padding: `${spacing.lg} ${spacing.sm}`,
    rowGap: spacing.lg,
  },
}));

export const CardsWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  columnGap: spacing.sm,
  overflowX: 'auto',
  padding: `${spacing.md} 0 ${spacing.base}`,

  '&::-webkit-scrollbar': {
    display: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    columnGap: spacing.lg,
  },
}));

export const Card = styled('div')({
  minWidth: 280,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
});

export const CardTitle = styled(Body)({
  color: color.primary.main,
});

export const CardContent = styled(DSCard)({
  padding: spacing.md,
  borderRadius: 20,
  boxShadow:
    '0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: spacing.sm,
});

export const Number = styled(Heading)({
  background: color.neutral[0],
  border: `${BORDER_WIDTH} solid ${color.feedback.warning.main}`,
  borderRadius: 60,
  width: 44,
  lineHeight: NUMBER_LINE_HEIGHT,
  textAlign: 'center',
  position: 'absolute',
  top: `calc(-${NUMBER_LINE_HEIGHT} / 2 - ${BORDER_WIDTH})`,
});

export const LoaderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
});

export const ShareWrapper = styled('div')({
  maxWidth: 564,
  margin: '0 auto',
  padding: `${spacing.sm} ${spacing.sm} ${spacing.xl}`,
});
