import React from 'react';

import { Paper, Box, Typography } from '@iclinic/design-system';

export default function NoTransactionsToView(): JSX.Element {
  return (
    <Paper variant="outlined">
      <Box display="flex" justifyContent="center">
        <Typography variant="body2">
          Você não tem transações para visualizar.
        </Typography>
      </Box>
    </Paper>
  );
}
