import React from 'react';
import { Body, Heading, MaterialIcons } from '@iclinic/design-system';

import * as S from './Received.styles';

const { TrendingUp, DiscountBalloon } = MaterialIcons;

const Received = () => (
  <S.Section>
    <S.SectionContent>
      <Heading variant="xl">E o médico indicado ganha:</Heading>

      <S.PointsWrapper>
        <S.Point>
          <DiscountBalloon />

          <Body variant="sm">
            <strong>25% de desconto</strong> no iClinic por 3 meses
          </Body>
        </S.Point>

        <S.Point>
          <TrendingUp />

          <Body variant="sm">
            Mais <strong>agilidade</strong>, <strong>segurança</strong> e{' '}
            <strong>qualidade</strong> para seu consultório
          </Body>
        </S.Point>
      </S.PointsWrapper>
    </S.SectionContent>
  </S.Section>
);

export default Received;
