import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Button, MaterialIcons, MaterialUICore } from '@iclinic/design-system';
import history from 'routes/history';
import {
  getZipCodeAvailability,
  isSubmitingRequest,
} from '../../state/request-samples/selectors';
import * as S from './ActionButtons.styles';

const { ArrowBack } = MaterialIcons;
const { CircularProgress } = MaterialUICore;

const ActionButtons = () => {
  const isSubmiting = useSelector(isSubmitingRequest);
  const available = useSelector(getZipCodeAvailability);

  const requestButtonText = useMemo(
    () =>
      available === undefined || available
        ? 'Solicitar amostras'
        : 'Entrar para a lista de espera',
    [available],
  );

  return (
    <S.Root>
      <Button
        color="transparent"
        size="large"
        startIcon={<ArrowBack />}
        onClick={() => history.push('/amostras-gratis')}
      >
        Voltar
      </Button>

      <Button type="submit" size="large" disabled={isSubmiting}>
        {isSubmiting ? (
          <CircularProgress data-testid="loading-submit" size={14} />
        ) : (
          requestButtonText
        )}
      </Button>
    </S.Root>
  );
};

export default ActionButtons;
