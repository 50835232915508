import { Reducer } from 'redux';

import { FetchState, IActionCreatorFailure, ActionType } from '@types';
import * as actions from './actions';
import {
  DECODE_TOKEN_ACTION,
  DECODE_TOKEN_FAILURE,
  DECODE_TOKEN_SUCCESS,
  FETCH_PHYSICIAN_INFOS_ACTION,
  FETCH_PHYSICIAN_INFOS_FAILURE,
  FETCH_PHYSICIAN_INFOS_SUCCESS,
  FETCH_EVENT_INFOS_ACTION,
  FETCH_EVENT_INFOS_FAILURE,
  FETCH_EVENT_INFOS_SUCCESS,
  FETCH_BILL_INFOS_ACTION,
  FETCH_BILL_INFOS_FAILURE,
  FETCH_BILL_INFOS_SUCCESS,
  SELECT_PAYMENT_TYPE,
  PIX_TIMER_RUNNING,
} from './constants';
import { Physician, Clinic, Event, DecodedToken, Bill } from './types';

const emptyFetchState: FetchState = { isFetching: false };
const fetchingState: FetchState = { isFetching: true };
const fetchErrorState = <T extends string>(
  action: IActionCreatorFailure<T>,
): FetchState => ({
  errors: action.payload.errors,
  isFetching: false,
});

export type CheckoutInfosState = {
  fetchPhysicianInfos: FetchState;
  fetchEventInfos: FetchState;
  decodeToken: FetchState;
  fetchBillInfos: FetchState;
  physician: Physician | null;
  clinic: Clinic | null;
  event: Event | null;
  tokenData: DecodedToken | null;
  bill: Bill | null;
  paymentType: string | null;
  isPixTimerRunning: boolean;
};

export const initialState: CheckoutInfosState = {
  fetchPhysicianInfos: fetchingState,
  fetchEventInfos: fetchingState,
  decodeToken: fetchingState,
  fetchBillInfos: fetchingState,
  physician: null,
  clinic: null,
  event: null,
  tokenData: null,
  bill: null,
  paymentType: null,
  isPixTimerRunning: false,
};

const checkoutInfosReducer: Reducer<CheckoutInfosState> = (
  state = initialState,
  action: ActionType<typeof actions>,
): CheckoutInfosState => {
  switch (action.type) {
    case DECODE_TOKEN_ACTION:
      return { ...state, decodeToken: fetchingState };
    case DECODE_TOKEN_FAILURE:
      return { ...state, decodeToken: emptyFetchState };
    case DECODE_TOKEN_SUCCESS:
      return {
        ...state,
        decodeToken: emptyFetchState,
        tokenData: action.payload.tokenData,
      };

    case FETCH_PHYSICIAN_INFOS_ACTION:
      return { ...state, fetchPhysicianInfos: fetchingState };
    case FETCH_PHYSICIAN_INFOS_FAILURE:
      return { ...state, fetchPhysicianInfos: fetchErrorState(action) };
    case FETCH_PHYSICIAN_INFOS_SUCCESS:
      return {
        ...state,
        fetchPhysicianInfos: emptyFetchState,
        physician: action.payload.physician,
      };

    case FETCH_EVENT_INFOS_ACTION:
      return { ...state, fetchEventInfos: fetchingState };
    case FETCH_EVENT_INFOS_FAILURE:
      return { ...state, fetchEventInfos: fetchErrorState(action) };
    case FETCH_EVENT_INFOS_SUCCESS:
      return {
        ...state,
        fetchEventInfos: emptyFetchState,
        event: action.payload.event,
        clinic: action.payload.clinic,
      };

    case FETCH_BILL_INFOS_ACTION:
      return { ...state, fetchBillInfos: fetchingState };
    case FETCH_BILL_INFOS_FAILURE:
      return { ...state, fetchBillInfos: fetchErrorState(action) };
    case FETCH_BILL_INFOS_SUCCESS:
      return {
        ...state,
        fetchBillInfos: emptyFetchState,
        bill: action.payload.bill,
      };

    case SELECT_PAYMENT_TYPE:
      return {
        ...state,
        paymentType: action.payload.paymentType,
      };

    case PIX_TIMER_RUNNING:
      return {
        ...state,
        isPixTimerRunning: action.payload.isPixTimerRunning,
      };

    default:
      return state;
  }
};

export default checkoutInfosReducer;
