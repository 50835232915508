import { useCallback, useState } from 'react';

function useToggle(defaultValue: boolean = false): [boolean, () => void] {
  const [value, setValue] = useState(defaultValue);

  const toggle = useCallback(() => setValue((previous) => !previous), []);

  return [value, toggle];
}

export default useToggle;
