// External
import {
  EditorState,
  ContentState,
  RichUtils,
  SelectionState,
  Modifier,
  AtomicBlockUtils,
} from 'draft-js';

// Internal
import { ApiErrors } from '@types';
import * as types from './types';

import {
  EmailFlow,
  Physician,
  EmailFlowPhysician,
  FetchListEmailFlowAction,
  FetchListEmailFlowSuccessAction,
  FetchListEmailFlowFailureAction,
  ActiveEmailFlowAction,
  EditActiveEmailFlowAction,
  FetchUpdateEmailFlowAction,
  FetchUpdateEmailFlowSuccessAction,
  FetchUpdateEmailFlowFailureAction,
  CancelEmailFlowAction,
  EditEmailFlowAction,
  FetchListPhysiciansAction,
  FetchListPhysiciansSuccessAction,
  FetchListPhysiciansFailureAction,
  FetchDetailsEmailFlowAction,
  FetchDetailsEmailFlowSuccessAction,
  FetchDetailsEmailFlowFailureAction,
  EmailFlowCreateInput,
  FetchCreateEmailFlowAction,
  FetchCreateEmailFlowFailureAction,
  FetchCreateEmailFlowSuccessAction,
  EmailFlowDeleteInput,
  FetchDeleteEmailFlowAction,
  FetchDeleteEmailFlowFailureAction,
  FetchDeleteEmailFlowSuccessAction,
  DeleteEmailFlowAction,
  DuplicateEmailFlowAction,
  AddEmailFlowAction,
  EmailFlowComponentCreateInput,
  SaveEmailFlowAction,
  SetActiveCardAction,
  EmailFlowComponent,
  SetCurrentComponentAction,
  UpdateTextEditorAction,
  OptionsTextFormatAction,
  OnModalEditorButtonSubmitAction,
  OnModalEditorLinkSubmitAction,
  OnModalEditorImageSubmitAction,
  OnAutomatedTagChosenAction,
  FetchDeleteComponentFailureAction,
  FetchDeleteComponentSuccessAction,
  FetchDeleteComponentAction,
  ComponentDelete,
  FetchUpdateComponentAction,
  FetchUpdateComponentFailureAction,
  FetchUpdateComponentSuccessAction,
  EmailFlowComponentUpdate,
  SaveEmailFlowComponentAction,
  PhysicianId,
  ClearCurrentComponentAction,
  FetchDetailsEmailFlowClearAction,
  FetchDetailsAddEmailFlowAction,
  EmailFlowComponentCreate,
  FetchCreateComponentFailureAction,
  FetchCreateComponentSuccessAction,
  FetchCreateComponentAction,
  FetchDetailsUpdateEmailFlowAction,
  ClearErrorsComponentAction,
  FetchSendTestEmailActionReturn,
  TestEmailInput,
  FetchSendTestEmailSuccessActionReturn,
  FetchSendTestEmailFailureActionReturn,
  SendTestEmailParams,
} from './emailFlowTypes';
import { Professional } from '../professional/types';

interface Sender {
  email: string;
  name: string;
}

interface CreateEmailFlowInput {
  name: string;
  active: boolean;
  sender?: Sender;
  components?: EmailFlowComponentCreateInput[];
}

export const fetchListEmailFlow = (professional: Professional): FetchListEmailFlowAction => ({
  type: types.FETCH_LIST.ACTION,
  params: professional,
  payload: {
    isFetching: true,
  },
});

export const fetchListEmailFlowSuccess = (
  emailFlows: EmailFlow[],
): FetchListEmailFlowSuccessAction => ({
  type: types.FETCH_LIST.SUCCESS,
  payload: {
    isFetching: false,
    emailFlows,
  },
});

export const fetchListEmailFlowFailure = (
  errors: ApiErrors[],
): FetchListEmailFlowFailureAction => ({
  type: types.FETCH_LIST.FAILURE,
  payload: {
    isFetching: false,
    errors,
  },
});

export const activeEmailFlow = (
  id: React.ReactText,
  active: boolean,
): ActiveEmailFlowAction => ({
  type: types.ACTIVE_EMAIL_FLOW.ACTION,
  payload: { id, active },
});

export const editActiveEmailFlow = (
  active: boolean,
): EditActiveEmailFlowAction => ({
  type: types.EDIT_ACTIVE_EMAIL_FLOW.ACTION,
  payload: {
    activeEmailFlow: active,
  },
});

export const fetchUpdateEmailFlow = (
  emailFlow: EmailFlow,
): FetchUpdateEmailFlowAction => ({
  type: types.FETCH_UPDATE.ACTION,
  params: emailFlow,
  payload: {
    emailFlowUpdate: {
      isFetching: true,
      emailFlow,
    },
  },
});

export const fetchUpdateEmailFlowSuccess = (
  emailFlow: EmailFlow,
): FetchUpdateEmailFlowSuccessAction => ({
  type: types.FETCH_UPDATE.SUCCESS,
  params: emailFlow,
  payload: {
    emailFlowUpdate: {
      isFetching: false,
      emailFlow,
    },
  },
});

export const fetchUpdateEmailFlowFailure = (
  errors: ApiErrors[], params: EmailFlow,
): FetchUpdateEmailFlowFailureAction => ({
  type: types.FETCH_UPDATE.FAILURE,
  params,
  payload: {
    emailFlowUpdate: {
      isFetching: false,
      emailFlow: params,
      errors,
    },
  },
});

export const cancelEmailFlow = (): CancelEmailFlowAction => ({
  type: types.CANCEL_EMAIL_FLOW.ACTION,
});

export const editEmailFlow = (
  { id, physicianId, flowType }: EmailFlow,
): EditEmailFlowAction => ({
  type: types.EDIT_EMAIL_FLOW.ACTION,
  payload: { id, physicianId, flowType },
});

export const fetchListPhysicians = (): FetchListPhysiciansAction => ({
  type: types.FETCH_PHYSICIANS.ACTION,
  payload: {
    physician: {
      isFetching: true,
    },
  },
});

export const fetchListPhysiciansSuccess = (
  physicians: Physician[],
): FetchListPhysiciansSuccessAction => ({
  type: types.FETCH_PHYSICIANS.SUCCESS,
  payload: {
    physician: {
      isFetching: false,
      physicians,
    },
  },
});

export const fetchListPhysiciansFailure = (
  errors: ApiErrors[],
): FetchListPhysiciansFailureAction => ({
  type: types.FETCH_PHYSICIANS.FAILURE,
  payload: {
    physician: {
      isFetching: false,
      errors,
    },
  },
});

export const fetchDetailsEmailFlow = (
  emailFlow: EmailFlowPhysician,
): FetchDetailsEmailFlowAction => ({
  type: types.FETCH_DETAILS.ACTION,
  params: emailFlow,
  payload: {
    emailFlowDetails: {
      isFetching: true,
    },
  },
});

export const fetchDetailsEmailFlowSuccess = (
  emailFlow: EmailFlow,
): FetchDetailsEmailFlowSuccessAction => ({
  type: types.FETCH_DETAILS.SUCCESS,
  payload: {
    emailFlowDetails: {
      isFetching: false,
      emailFlow,
    },
  },
});

export const fetchDetailsEmailFlowFailure = (
  errors: ApiErrors[],
): FetchDetailsEmailFlowFailureAction => ({
  type: types.FETCH_DETAILS.FAILURE,
  payload: {
    emailFlowDetails: {
      isFetching: false,
      errors,
    },
  },
});

export const fetchCreateEmailFlow = (
  params: CreateEmailFlowInput,
): FetchCreateEmailFlowAction => ({
  type: types.FETCH_CREATE.ACTION,
  params,
  payload: {
    emailFlowCreate: {
      isFetching: true,
    },
  },
});

export const fetchCreateEmailFlowSuccess = (
  emailFlow: EmailFlow,
  params: EmailFlowCreateInput,
): FetchCreateEmailFlowSuccessAction => ({
  type: types.FETCH_CREATE.SUCCESS,
  params,
  payload: {
    emailFlowCreate: {
      isFetching: false,
      emailFlow,
      errors: [],
    },
  },
});

export const fetchCreateEmailFlowFailure = (
  errors: ApiErrors[],
  params: EmailFlowCreateInput,
): FetchCreateEmailFlowFailureAction => ({
  type: types.FETCH_CREATE.FAILURE,
  params,
  payload: {
    emailFlowCreate: {
      isFetching: false,
      errors,
    },
  },
});

export const addEmailFlow = (): AddEmailFlowAction => ({
  type: types.ADD_EMAIL_FLOW.ACTION,
});

export const duplicateEmailFlow = (
  id: string,
): DuplicateEmailFlowAction => ({
  type: types.DUPLICATE_EMAIL_FLOW.ACTION,
  params: { id },
});

export const fetchDeleteEmailFlow = (
  params: EmailFlowDeleteInput,
): FetchDeleteEmailFlowAction => ({
  type: types.FETCH_DELETE.ACTION,
  params,
  payload: {
    emailFlowDelete: {
      isFetching: true,
    },
  },
});

export const fetchDeleteEmailFlowSuccess = (
  params: EmailFlowDeleteInput,
): FetchDeleteEmailFlowSuccessAction => ({
  type: types.FETCH_DELETE.SUCCESS,
  params,
  payload: {
    emailFlowDelete: {
      isFetching: false,
      errors: [],
    },
  },
});

export const fetchDetailsEmailFlowClear = (): FetchDetailsEmailFlowClearAction => ({
  type: types.FETCH_DETAILS_CLEAR.ACTION,
  payload: {
    emailFlowDetails: {
      isFetching: false,
      emailFlow: undefined,
      errors: [],
    },
  },
});

export const fetchDeleteEmailFlowFailure = (
  errors: ApiErrors[],
  params: EmailFlowDeleteInput,
): FetchDeleteEmailFlowFailureAction => ({
  type: types.FETCH_DELETE.FAILURE,
  params,
  payload: {
    emailFlowDelete: {
      isFetching: false,
      errors,
    },
  },
});

export const deleteEmailFlow = (
  id: string,
): DeleteEmailFlowAction => ({
  type: types.DELETE_EMAIL_FLOW.ACTION,
  params: { id },
});

export const fetchDetailsAddEmailFlow = (): FetchDetailsAddEmailFlowAction => ({
  type: types.FETCH_DETAILS_ADD_NEW_COMPONENT.ACTION,
});

export const saveEmailFlow = (): SaveEmailFlowAction => ({ type: types.EDIT_EMAIL_FLOW.SAVE });

export const setActiveComponentsCard = (activeComponentsCard: string): SetActiveCardAction => ({
  type: types.EDIT_EMAIL_FLOW.SET_ACTIVE_CARD,
  payload: {
    activeComponentsCard,
  },
});

export const setCurrentComponent = (component: EmailFlowComponent): SetCurrentComponentAction => ({
  type: types.EDIT_EMAIL_FLOW.SET_CURRENT_COMPONENT,
  payload: {
    currentComponent: component,
  },
});

export const clearCurrentComponent = (): ClearCurrentComponentAction => ({
  type: types.EDIT_EMAIL_FLOW.SET_CURRENT_COMPONENT,
  payload: {
    currentComponent: undefined,
  },
});

export const setActiveEmailFlowCard = (activeEmailFlowCard: string) => ({
  type: types.SET_ACTIVE_EMAIL_FLOW_CARD,
  payload: {
    activeEmailFlowCard,
  },
});

export const fetchSendTestEmail = (
  meta: SendTestEmailParams,
): FetchSendTestEmailActionReturn => ({
  type: types.FETCH_SEND_TEST_EMAIL.ACTION,
  meta,
  payload: {
    sendTestEmail: {
      isFetching: true,
      errors: [],
    },
  },
});

export const fetchSendTestEmailSuccess = (
  meta: TestEmailInput,
): FetchSendTestEmailSuccessActionReturn => ({
  type: types.FETCH_SEND_TEST_EMAIL.SUCCESS,
  meta,
  payload: {
    sendTestEmail: {
      isFetching: false,
      errors: [],
    },
  },
});

export const fetchSendTestEmailFailure = (
  errors: ApiErrors[],
  meta: TestEmailInput,
): FetchSendTestEmailFailureActionReturn => ({
  type: types.FETCH_SEND_TEST_EMAIL.FAILURE,
  meta,
  payload: {
    sendTestEmail: {
      isFetching: false,
      errors,
    },
  },
});

export const updateTextEditor = (editorState: EditorState): UpdateTextEditorAction => ({
  type: types.EDITOR_TEXT.UPDATE_EDITOR_STATE,
  payload: {
    editor: {
      editorState,
    },
  },
});

export const optionsTextFormat = (editorOptionsText: string): OptionsTextFormatAction => ({
  type: types.EDITOR_TEXT.FORMAT_TEXT,
  payload: {
    editor: {
      optionsFormatText: editorOptionsText,
    },
  },
});

export const updateEntityLink = (
  editorState: EditorState,
  contentUpgrade: ContentState,
) => updateTextEditor(
  EditorState.push(
    editorState,
    contentUpgrade,
    'insert-characters',
  ),
);

export const addEntityLinkTextSelected = (
  editorState: EditorState,
  selection: SelectionState,
  contentWithEntity: ContentState,
  entityKey: string,
) => {
  const newEditorState = EditorState.set(editorState, { currentContent: contentWithEntity });
  return updateTextEditor(RichUtils.toggleLink(newEditorState, selection, entityKey));
};

export const addEntityLinkEmptyText = (
  editorState: EditorState,
  selection: SelectionState,
  content: ContentState,
  entityKey: string,
  textSelected: string,
) => {
  const contentWithText = Modifier.insertText(
    content,
    selection,
    textSelected,
    undefined,
    entityKey,
  );
  return updateTextEditor(
    EditorState.push(
      editorState,
      contentWithText,
      'insert-characters',
    ),
  );
};

export const addEntityImage = (
  editorState: EditorState,
  contentStateWithEntity: ContentState,
  entityKey: string,
) => {
  const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
  return updateTextEditor(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
};

export const onModalEditorLinkSubmit = (
  textSelected: string,
  url: string,
): OnModalEditorLinkSubmitAction => ({
  type: types.EDITOR_TEXT.MODAL_LINK_SUBMIT,
  payload: {
    textSelected,
    url,
  },
});

export const onModalEditorButtonSubmit = (
  textSelected: string,
  url: string,
): OnModalEditorButtonSubmitAction => ({
  type: types.EDITOR_TEXT.MODAL_BUTTON_SUBMIT,
  payload: {
    textSelected,
    url,
  },
});

export const onModalEditorImageSubmit = (url: string): OnModalEditorImageSubmitAction => ({
  type: types.EDITOR_TEXT.MODAL_IMAGE_SUBMIT,
  payload: {
    url,
  },
});

export const onAutomatedTagChosen = (automatedTag: string): OnAutomatedTagChosenAction => ({
  type: types.EDITOR_TEXT.AUTOMATED_TAG,
  payload: {
    automatedTag,
  },
});

export const fetchDeleteComponent = (component: ComponentDelete): FetchDeleteComponentAction => ({
  type: types.FETCH_DELETE_COMPONENT.ACTION,
  params: component,
  payload: {
    componentDelete: {
      isFetching: true,
      component,
    },
  },
});

export const fetchDeleteComponentSuccess = (
  component: ComponentDelete,
): FetchDeleteComponentSuccessAction => ({
  type: types.FETCH_DELETE_COMPONENT.SUCCESS,
  params: component,
  payload: {
    componentDelete: {
      isFetching: false,
      component,
    },
  },
});

export const fetchDeleteComponentFailure = (
  errors: ApiErrors[], params: ComponentDelete,
): FetchDeleteComponentFailureAction => ({
  type: types.FETCH_DELETE_COMPONENT.FAILURE,
  params,
  payload: {
    componentDelete: {
      isFetching: false,
      component: params,
      errors,
    },
  },
});

export const fetchUpdateComponent = (
  component: EmailFlowComponentUpdate,
): FetchUpdateComponentAction => ({
  type: types.FETCH_UPDATE_COMPONENT.ACTION,
  params: component,
  payload: {
    componentUpdate: {
      isFetching: true,
      component,
    },
  },
});

export const fetchUpdateComponentSuccess = (
  component: EmailFlowComponentUpdate,
): FetchUpdateComponentSuccessAction => ({
  type: types.FETCH_UPDATE_COMPONENT.SUCCESS,
  params: component,
  payload: {
    componentUpdate: {
      isFetching: false,
      component,
    },
  },
});

export const fetchUpdateComponentFailure = (
  errors: ApiErrors[], params: EmailFlowComponentUpdate,
): FetchUpdateComponentFailureAction => ({
  type: types.FETCH_UPDATE_COMPONENT.FAILURE,
  params,
  payload: {
    componentUpdate: {
      isFetching: false,
      component: params,
      errors,
    },
  },
});

export const saveEmailFlowComponent = (physicianId: PhysicianId): SaveEmailFlowComponentAction => ({
  params: physicianId,
  type: types.EDIT_EMAIL_FLOW_COMPONENT.ACTION,
});

export const fetchCreateComponent = (
  component: EmailFlowComponentCreate,
): FetchCreateComponentAction => ({
  type: types.FETCH_CREATE_COMPONENT.ACTION,
  params: component,
  payload: {
    componentCreate: {
      isFetching: true,
      component,
    },
  },
});

export const fetchCreateComponentSuccess = (
  component: EmailFlowComponentCreate,
): FetchCreateComponentSuccessAction => ({
  type: types.FETCH_CREATE_COMPONENT.SUCCESS,
  payload: {
    componentCreate: {
      isFetching: false,
      component,
    },
  },
});

export const fetchCreateComponentFailure = (
  errors: ApiErrors[],
  component: EmailFlowComponentCreate,
): FetchCreateComponentFailureAction => ({
  type: types.FETCH_CREATE_COMPONENT.FAILURE,
  params: component,
  payload: {
    componentCreate: {
      isFetching: false,
      errors,
      component,
    },
  },
});

export const fetchDetailsChangeEmailFlow = (): FetchDetailsUpdateEmailFlowAction => ({
  type: types.FETCH_DETAILS_UPDATE_COMPONENT.ACTION,
});

export const clearErrorsComponent = (): ClearErrorsComponentAction => ({
  type: types.CLEAR_ERRORS_COMPONENT.ACTION,
  payload: {
    componentCreate: {
      isFetching: false,
      errors: [],
    },
    componentUpdate: {
      isFetching: false,
      errors: [],
    },
    componentDelete: {
      isFetching: false,
      errors: [],
    },
  },
});

export const forceSelection = (editorState: EditorState) => (
  updateTextEditor(EditorState.moveFocusToEnd(editorState))
);
