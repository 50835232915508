// External
import React from 'react';

// Internal
import Button from 'ui/new/button';
import { BUTTON, COLOR } from 'ui/new/button/constants';

type DeleteProps = {
  cancelForm(): void;
  submitForm(): void;
  isFetching: boolean;
};

export default function Delete({
  cancelForm,
  submitForm,
  isFetching,
}: DeleteProps) {
  return (
    <>
      <Button
        type={BUTTON.Light}
        onClick={cancelForm}
        loading={isFetching}
        disabled={isFetching}
      >
        Cancelar
      </Button>
      <Button
        type={BUTTON.Error}
        onClick={submitForm}
        loading={isFetching}
        color={COLOR.White}
        disabled={isFetching}
      >
        Excluir
      </Button>
    </>
  );
}
