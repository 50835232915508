import { createSelector } from 'reselect';

import {
  PhysicianRawData,
  SellerRawData,
  DocumentCategory,
  DocumentProofRawData,
} from 'features/onlinePayment/services/config/types';
import { ZipCodeRawData } from 'services/iclinic/easyZipCode/types';
import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';
import { SellerType } from 'features/onlinePayment/containers/config/seller/constants';
import { SellerStatus } from '../../constants';
import { SellerForm, SellerProfiles, DocumentProofs } from '../types';

export const getUserInfo = ({ userInfo }: StoreState) => ({
  profileId: userInfo.profileId,
  isSubscriber: userInfo.userData.isSubscriber,
});

export const getPhysicianId = ({ userInfo }: StoreState): number | undefined =>
  userInfo.userData.physicianId;

export const getSubscriberUser = ({
  userInfo,
}: StoreState): boolean | null | undefined => userInfo.userData.isSubscriber;

export const getPhysician = ({
  onlinePayment,
}: StoreState): PhysicianRawData | undefined =>
  onlinePayment.config.register.physician?.data;

export const getPhysicianFetchStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.config.register.physician?.fetchStatus;

export const getSeller = ({
  onlinePayment,
}: StoreState): SellerRawData | null | undefined =>
  onlinePayment.config.register.seller?.data;

export const getSellerType = ({
  onlinePayment,
}: StoreState): SellerType | undefined =>
  onlinePayment.config.register.seller?.type;

export const getSellerProfiles = ({
  onlinePayment,
}: StoreState): SellerProfiles | null | undefined =>
  onlinePayment.config.register.sellerProfiles?.data;

export const getSellerDefault = createSelector(
  getSellerProfiles,
  (profiles) => {
    if (profiles?.individual?.is_default) {
      return profiles?.individual;
    }
    return profiles?.business;
  },
);

export const getSellerDocumentProof = ({
  onlinePayment,
}: StoreState): DocumentProofs | undefined =>
  onlinePayment.config.register.documents?.documentProofs;

export const getSelectedSellerDocumentProof = createSelector(
  getSellerType,
  getSellerDocumentProof,
  (type, documents) => {
    return type ? documents?.[type] : null;
  },
);

export const getMappedDocumentProof = createSelector(
  getSelectedSellerDocumentProof,
  (documents) => {
    const DocStatus: Record<
      DocumentCategory,
      DocumentProofRawData | undefined
    > = {
      identity: undefined,
      activity: undefined,
      residence: undefined,
      cnpj: undefined,
    };
    documents?.forEach((document) => {
      DocStatus[document.file_category] = document;
    });
    return DocStatus;
  },
);

export const getSellerErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.config.register.seller?.error;

export const getSellerProfilesErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.config.register.sellerProfiles?.error;

export const getSellerForm = ({
  onlinePayment,
}: StoreState): SellerForm | undefined =>
  onlinePayment.config.register.sellerForm;

export const getSellerFetchStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.config.register.sellerProfiles?.fetchStatus;

export const getSellerSaveStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.config.register.seller?.saveStatus;

export const getUpdateDefaultSellerStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.config.register.sellerProfiles?.fetchStatus;

export const getPhysicianSaveStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.config.register.physician?.saveStatus;

export const getDocumentsStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.config.register.documents?.status;

const getDocumentsErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.config.register.documents?.error;

export const getDocumentsError = createSelector(
  getDocumentsStatus,
  getDocumentsErrorMessage,
  (status, message) => {
    return status === RequestStatus.Error ? message : null;
  },
);

export const getSellerSaveError = createSelector(
  getSellerSaveStatus,
  getSellerErrorMessage,
  (status, message) => {
    return status === RequestStatus.Error ? message : null;
  },
);

export const getSellerFetchError = createSelector(
  getSellerFetchStatus,
  getSellerProfilesErrorMessage,
  (status, message) => {
    return status === RequestStatus.Error ? message : null;
  },
);

export const getUpdateDefaultSellerError = createSelector(
  getUpdateDefaultSellerStatus,
  getSellerProfilesErrorMessage,
  (status, message) => {
    return status === RequestStatus.Error ? message : null;
  },
);

export const getProfileId = ({ userInfo }: StoreState): string | null =>
  userInfo.profileId;

export const isFetching = createSelector(
  getPhysicianFetchStatus,
  getSellerFetchStatus,
  (physicianStatus, sellerFetchStatus) =>
    physicianStatus === RequestStatus.Pending ||
    sellerFetchStatus === RequestStatus.Pending,
);

export const isSavingProfile = createSelector(
  getSellerSaveStatus,
  getPhysicianSaveStatus,
  getDocumentsStatus,
  (sellerSaveStatus, physicianSaveStatus, documentsStatus) =>
    sellerSaveStatus === RequestStatus.Pending ||
    physicianSaveStatus === RequestStatus.Pending ||
    documentsStatus === RequestStatus.Pending,
);

export const isSellerAlreadyCreated = createSelector(
  getSeller,
  getSellerFetchStatus,
  (seller, sellerStatus) =>
    sellerStatus === RequestStatus.Success && seller != null,
);

export const isSellerCreationDone = createSelector(
  getSeller,
  (seller) =>
    seller?.status === SellerStatus.Approved ||
    seller?.status === SellerStatus.Submitted ||
    seller?.status === SellerStatus.Refused ||
    seller?.status === SellerStatus.Deleted,
);

export const getSellerProfileSelector = createSelector(
  [
    getPhysician,
    getPhysicianId,
    isFetching,
    isSavingProfile,
    isSellerCreationDone,
    getSellerForm,
    getSeller,
    getSellerFetchError,
    getSellerType,
  ],
  (
    physician,
    physicianId,
    fetching,
    saving,
    fullyCreatedSeller,
    sellerForm,
    seller,
    sellerFetchError,
    sellerType,
  ) => ({
    physician,
    physicianId,
    fetching,
    saving,
    fullyCreatedSeller,
    sellerForm,
    seller,
    sellerFetchError,
    sellerType,
  }),
);

export const getZipCode = ({
  onlinePayment,
}: StoreState): ZipCodeRawData | undefined =>
  onlinePayment.config.register.zipCode?.data;

export const getZipCodeFetchStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.config.register.zipCode?.fetchStatus;

export const getZipCodeSelector = createSelector(
  [getZipCode, getZipCodeFetchStatus],
  (zipCodeInfo, status) => ({
    zipCodeInfo,
    status,
  }),
);

export const getSellerConfigSelector = createSelector(
  [getPhysician, getSeller],
  (physician, seller) => ({
    physician,
    seller,
  }),
);
