// External
import React from 'react';

// Internal
import classnames from 'classnames/bind';
import style from './Group.scss';

const cx = classnames.bind(style);

type HeaderProps = {
  children: React.ReactNode;
  title?: string,
  classes?: string,
  description?: string,
};

export default function Header({
  title = '',
  description = '',
  classes = '',
  children,
}: HeaderProps): JSX.Element {
  return (
    <div className={cx(style.group, classes)}>
      {title && (
        <div className={cx(style.title, classes)}>
          <h2>{title}</h2>
          { description && (
            <p className={style.description}>{description}</p>
          )}
        </div>
      )}
      <div className={style.children}>
        {children}
      </div>
    </div>
  );
}
