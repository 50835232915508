import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { gotoAppRoute } from 'shared/utils/appRoutes';
import * as actions from '.';
import { DELETED_USERS_PARAM } from '../../constants';
import * as services from '../../services';
import {
  UserData,
  trackDownsellConfirmation,
} from '../../utils/trackDownsellEvents';
import { getKey, getPhysicianId, getUserIds, getUserInfo } from './selectors';

export function* deleteMultiplePhysicianWorker() {
  const userData: UserData = yield select(getUserInfo);

  try {
    yield put(actions.deleteMultiplePhysiciansStart());

    const physicianId: number = yield select(getPhysicianId);
    const userIds: string[] = yield select(getUserIds);
    const key: string = yield select(getKey);

    if (userIds.includes(String(physicianId))) {
      yield put(
        actions.deleteMultiplePhysiciansFailure(
          'Você não pode excluir seu próprio usuário!',
        ),
      );
    } else {
      yield all(
        userIds.map((userId) =>
          call(services.deletePhysician, Number(userId), key),
        ),
      );

      yield put(actions.deleteMultiplePhysiciansSuccess());
      trackDownsellConfirmation({
        userData,
        stepName: 'success',
        stepNum: 3,
      });

      gotoAppRoute(
        `configuracoes/usuarios/motivo-exclusao/?${DELETED_USERS_PARAM}=${userIds.join(
          ',',
        )}`,
      );
    }
  } catch (error) {
    trackDownsellConfirmation({
      userData,
      stepName: 'error',
      stepNum: 3,
    });

    yield put(
      actions.deleteMultiplePhysiciansFailure(
        'Algum erro ocorreu, tente novamente mais tarde!',
      ),
    );
  }
}

export default function* deleteMultiplePhysicianSagas() {
  yield takeLatest(
    actions.deleteMultiplePhysicians,
    deleteMultiplePhysicianWorker,
  );
}
