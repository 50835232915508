import { Guide, SimplifiedGuides } from '../types';

export const removeDuplicates = (array: any[]) => [
  ...Array.from(new Set(array)),
];

export const getGuidesIds = (
  guides: Guide[] | SimplifiedGuides[],
  ids: string[],
): string[] => {
  const guidesIds = guides.map((guide) => guide.id);

  return removeDuplicates([...guidesIds, ...ids]);
};
