import React from 'react';
import { MaterialIcons } from '@iclinic/design-system';

import { buttonsTitle } from 'features/video-conference/constants';
import ToggleButton from 'features/video-conference/components/TeamComponents/ToggleButton';
import { useChatContext } from 'features/video-conference/state/chatContext';
import { useAnnotationContext } from 'features/video-conference/state/annotationContext';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';

const { EditNote } = MaterialIcons;

export default function AnnotationsButton() {
  const { isPhysician, conferenceId, setIsBackgroundSelectionOpen } =
    useConference();
  const { localParticipant, remoteParticipant } = useParticipantContext();
  const { setIsChatWindowOpen } = useChatContext();

  const { isAnnotationWindowOpen, setIsAnnotationWindowOpen } =
    useAnnotationContext();

  const toggleAnnotationWindow = () => {
    setIsChatWindowOpen(false);
    setIsBackgroundSelectionOpen(false);
    setIsAnnotationWindowOpen(!isAnnotationWindowOpen);
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'telemedicine_annotations',
      buttonLocation: 'toolbar',
      action: isAnnotationWindowOpen ? 'disabled' : 'enabled',
    });
  };

  return (
    <ToggleButton
      onClick={toggleAnnotationWindow}
      switchedOn
      variant="info"
      updateOn={false}
      title={buttonsTitle.ANNOTATIONS}
      label={buttonsTitle.ANNOTATIONS}
      icon={<EditNote />}
      aria-controls={buttonsTitle.ANNOTATIONS}
      dataGa="telemedicine_annotations"
      dataSp="telemedicine_annotations"
    />
  );
}
