import { Checkbox, Chip, Link } from '@iclinic/design-system';
import React from 'react';

import { formatDate } from 'features/tissInvoicing/utils/formatDateTime';
import { AppointmentGuideDTO } from 'features/tissInvoicing/types';
import * as Styles from './styles';

function checkIfGuideIsComplete(status: boolean) {
  return status ? (
    <Chip label="Completa" severity="success" size="small" />
  ) : (
    <Chip label="Incompleta" severity="error" size="small" />
  );
}

const tableCellWidths = {
  date: '50',
  patient: '120',
  tissVersion: '50',
  invoice: '50',
  status: '30',
};

export type AppointmentGuideDefinition = AppointmentGuideDTO & {
  checked: boolean;
};

type TableGuideListProps = {
  checkboxState: AppointmentGuideDefinition[];
  handleCheckAllChange(event: React.ChangeEvent<HTMLInputElement>): void;
  handleCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    item: AppointmentGuideDefinition,
  ): void;
};

export default function TableGuideList({
  checkboxState,
  handleCheckAllChange,
  handleCheckboxChange,
}: TableGuideListProps) {
  const checkBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: AppointmentGuideDefinition,
  ) => {
    handleCheckboxChange(event, item);
  };

  return (
    <>
      <Styles.HeaderTable display="flex" alignItems="center">
        <Checkbox
          checked={checkboxState.every((checkbox) => checkbox.checked)}
          onChange={handleCheckAllChange}
        />
        <Styles.TableCell customwidth={tableCellWidths.date} variant="xs" bold>
          Data
        </Styles.TableCell>
        <Styles.TableCell
          customwidth={tableCellWidths.patient}
          variant="xs"
          bold
        >
          Paciente
        </Styles.TableCell>
        <Styles.TableCell variant="xs" bold>
          Convênio
        </Styles.TableCell>
        <Styles.TableCell
          customwidth={tableCellWidths.tissVersion}
          variant="xs"
          bold
        >
          Versão TISS
        </Styles.TableCell>
        <Styles.TableCell
          customwidth={tableCellWidths.invoice}
          variant="xs"
          bold
        >
          Faturado em
        </Styles.TableCell>
        <Styles.TableCell
          customwidth={tableCellWidths.status}
          variant="xs"
          bold
        >
          Status
        </Styles.TableCell>
      </Styles.HeaderTable>

      {checkboxState.map((item, index) => (
        <Styles.TableRow
          key={`${item.appointmentDate}${String(index)}`}
          display="flex"
          alignItems="center"
          isodd={index % 2 === 1}
        >
          <Checkbox
            id={item.id}
            checked={item.checked}
            onChange={(event) => checkBoxChange(event, item)}
          />
          <Styles.TableCell customwidth={tableCellWidths.date} variant="xs">
            {formatDate(item.appointmentDate)}
          </Styles.TableCell>
          <Styles.TableCell customwidth={tableCellWidths.patient} variant="xs">
            <Link href={`/tiss/guias-consulta/${item.id}/`} underline="none">
              {item.patientName}
            </Link>
          </Styles.TableCell>
          <Styles.TableCell variant="xs">{item.insuranceName}</Styles.TableCell>
          <Styles.TableCell
            customwidth={tableCellWidths.tissVersion}
            variant="xs"
          >
            {item.version}
          </Styles.TableCell>
          <Styles.TableCell customwidth={tableCellWidths.invoice} variant="xs">
            {item.billedIn ? formatDate(item.billedIn) : '--'}
          </Styles.TableCell>
          <Styles.TableCell customwidth={tableCellWidths.status} variant="xs">
            {checkIfGuideIsComplete(item.completed)}
          </Styles.TableCell>
        </Styles.TableRow>
      ))}
    </>
  );
}
