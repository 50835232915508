import { put, takeLatest, call, select, all } from 'redux-saga/effects';

import { GENERIC_ERROR_MESSAGE } from 'shared/constants/errorMessages';
import { weekDays as translateWeekDays } from 'shared/constants/weekDays';
import { captureException } from 'shared/utils/handlerErrors';
import * as onlineScheduleApi from 'features/settings/scheduleOnline/services';
import { insuranceAttendanceType } from './constants';
import {
  fetchSettingsSucces,
  updateSettingsSuccess,
  fetchPhysiciansSuccess,
  fetchSettingsFailure,
  fetchPhysiciansFailure,
  updateSettingsFailure,
  updateInsurancesOnlineScheduleFailure,
  getScheduleWorkHoursFailure,
  getScheduleWorkHoursSuccess,
  notifyBlockedWeekDay,
  invalidateInsurances,
  onChangeCheckDaySuccess,
  addHourSuccess,
  removeHourSuccess,
  validateDaysScheduleSuccess,
  validateDaysScheduleFailure,
  removeIntegrationSuccess,
  validateEmailIntegrationSuccess,
  validateEmailIntegrationFailure,
  validatePasswordIntegrationSuccess,
  validatePasswordIntegrationFailure,
  createPasswordIntegrationSuccess,
  setIsLoadingIntegration,
  validateEmailAlreadyIntegrated,
} from './actions';
import * as types from './types';
import { IntegrationStep } from 'shared/constants/settings';

const selectScheduleWorkHours = (state) =>
  state.onlineSchedule.scheduleWorkHours;
export const selectSettings = (state) => state.onlineSchedule.settings;
const selectServicePeriod = (state) =>
  state.onlineSchedule.settings.settings.service_periods;
export const selectUserInfo = (state) => state.userInfo.userData;

export function* getSettingsScheduleHoursSelect() {
  return yield select(selectScheduleWorkHours);
}

export function normalizeScheduleHoursSelect({
  service_days: serviceDays,
  start_service: startService,
  end_service: endService,
  lunch_start: lunchStart,
  lunch_end: lunchEnd,
}) {
  return {
    serviceDays,
    startService,
    endService,
    lunchStart: lunchStart && lunchStart.substring(0, 5),
    lunchEnd: lunchEnd && lunchEnd.substring(0, 5),
  };
}

export function* compareHoursScheduleToSettings(weekDay) {
  const scheduleWorkHours = yield select(selectScheduleWorkHours);
  const { serviceDays } = scheduleWorkHours;
  const weekDayObject = translateWeekDays.find(
    (w) => w.en === weekDay.toUpperCase(),
  );
  return serviceDays.find((w) => w === String(weekDayObject.index));
}

export function* validateDaysSchedule() {
  try {
    const servicePeriods = yield select(selectServicePeriod);
    const servicePeriodsNewObject = Object.create(servicePeriods);
    const weekDays = Object.keys(servicePeriodsNewObject);

    const validateWorkDays = yield weekDays.map(function* f(weekday) {
      if (weekday) {
        const isWorkDay = yield compareHoursScheduleToSettings(weekday);
        if (!isWorkDay) {
          return { [weekday]: servicePeriodsNewObject[weekday] === [] };
        }
      }
      return { [weekday]: servicePeriodsNewObject[weekday] };
    });

    const newServicePeriods = Object.assign({}, ...validateWorkDays);
    yield put(validateDaysScheduleSuccess(newServicePeriods));
  } catch (error) {
    captureException(error);
    yield put(validateDaysScheduleFailure(GENERIC_ERROR_MESSAGE));
  }
}

export function* getPhysiciansSagas() {
  const { activeClinicId, subscriptionId } = yield select(selectUserInfo);
  try {
    const responseGetPhysicians = yield call(
      onlineScheduleApi.getPhysicians,
      subscriptionId,
      activeClinicId,
    );
    const physiciansResponse = responseGetPhysicians.data;
    const physicians = physiciansResponse.map((physician) => ({
      id: physician.physician_id,
      name: physician.name,
    }));
    yield put(fetchPhysiciansSuccess(physicians));
  } catch (e) {
    captureException(e);
    yield put(fetchPhysiciansFailure(GENERIC_ERROR_MESSAGE));
  }
}

export function* getScheduleHoursSaga() {
  const {
    settings: { clinic, physician },
  } = yield select(selectSettings);
  try {
    const res = yield call(
      onlineScheduleApi.getScheduleHours,
      clinic,
      physician,
    );
    yield put(
      getScheduleWorkHoursSuccess(normalizeScheduleHoursSelect(res.data)),
    );
    yield getSettingsScheduleHoursSelect();
  } catch (e) {
    captureException(e);
    yield put(getScheduleWorkHoursFailure(GENERIC_ERROR_MESSAGE));
  }
}

export function* changeOnlineScheduleSettings(action) {
  const { physicianId } = action.payload;
  const { activeClinicId } = yield select(selectUserInfo);
  try {
    const responseGetSettingsId = yield call(
      onlineScheduleApi.getSettingsId,
      physicianId,
      activeClinicId,
    );
    const { id } = responseGetSettingsId.data;
    const responseGetSettings = yield call(
      onlineScheduleApi.getSettingsDetail,
      id,
    );
    const settings = responseGetSettings.data;

    yield put(fetchSettingsSucces(settings));
    yield getScheduleHoursSaga();
    yield validateDaysSchedule();
  } catch (e) {
    captureException(e);
    yield put(fetchSettingsFailure(GENERIC_ERROR_MESSAGE));
  }
}

export function* getWeekDayServiceDays(weekday) {
  const weekdayWorkHours = yield select(selectServicePeriod);
  return weekdayWorkHours[weekday];
}

export function* addHour(action) {
  const { weekDay } = action.payload;
  const settings = yield select(selectSettings);
  const hoursWeekDay = yield getWeekDayServiceDays(weekDay);
  const { service_periods: servicePeriods } = settings.settings;
  const isWorkDay = yield compareHoursScheduleToSettings(weekDay);
  const { startService, endService } = yield getSettingsScheduleHoursSelect();

  if (isWorkDay) {
    const hoursWeekDayNewObject = hoursWeekDay.slice(0).concat({
      start_time: startService,
      end_time: endService,
    });
    const weekDaysHoursChanged = {
      ...servicePeriods,
      [weekDay]: hoursWeekDayNewObject,
    };

    yield put(addHourSuccess(weekDaysHoursChanged));
  } else {
    yield put(notifyBlockedWeekDay());
  }
}

export function* removeHour(action) {
  const { weekDay, index } = action.payload;
  const settings = yield select(selectSettings);
  const hoursWeekDay = yield getWeekDayServiceDays(weekDay);
  const { service_periods: servicePeriods } = settings.settings;

  const hoursWeekDayNewObject = hoursWeekDay.slice(0);

  if (hoursWeekDayNewObject.length > 1) {
    hoursWeekDayNewObject.splice(index, 1);
  } else {
    hoursWeekDayNewObject.splice(index, hoursWeekDayNewObject.length);
  }

  const weekDaysHoursChanged = {
    ...servicePeriods,
    [weekDay]: hoursWeekDayNewObject,
  };

  yield put(removeHourSuccess(weekDaysHoursChanged));
}

export function* watchFetchSettings() {
  try {
    yield getPhysiciansSagas();

    const getPhysiciansObject = (state) =>
      state.onlineSchedule.physicians[0].id;
    const physicianId = yield select(getPhysiciansObject);
    yield changeOnlineScheduleSettings({ payload: { physicianId } });
    yield getScheduleHoursSaga();
    yield validateDaysSchedule();
  } catch (e) {
    captureException(e);
    yield put(fetchSettingsFailure(GENERIC_ERROR_MESSAGE));
  }
}

export function* updateIsShowOnlineSchedule() {
  const settings = yield select(selectSettings);
  const {
    profileclinic_id: profileClinicId,
    profile_id: profileId,
    has_online_schedule_active: hasOnlineScheduleActive,
  } = settings.clinic;
  const { clinic } = settings.settings;
  try {
    yield call(
      onlineScheduleApi.updadeSettingsHeader,
      profileClinicId,
      profileId,
      hasOnlineScheduleActive,
      clinic,
    );
  } catch (e) {
    captureException(e);
    yield put(fetchSettingsFailure());
  }
}

export function* onChangeCheckInsurance(action) {
  const { insuranceId, insuranceAttendance } = action.payload;
  const settings = yield select(selectSettings);
  const { insurances } = settings;

  const modifiedInsurances = insurances.map((ins: types.IInsurances) => {
    if (
      ins.id === insuranceId &&
      insuranceAttendance === insuranceAttendanceType.Presential
    ) {
      return {
        ...ins,
        has_online_schedule_active: !ins.has_online_schedule_active,
      };
    }

    if (
      ins.id === insuranceId &&
      insuranceAttendance === insuranceAttendanceType.Telemedicine
    ) {
      return {
        ...ins,
        allow_online_scheduling_telemedicine: !ins.allow_online_scheduling_telemedicine,
      };
    }

    return ins;
  });

  const newSettings = { ...settings, insurances: modifiedInsurances };

  yield put(fetchSettingsSucces(newSettings));
}

export function* onChangeAllCheckInsurances(action) {
  const { insuranceAttendance } = action.payload;
  const settings = yield select(selectSettings);
  const { insurances } = settings;

  const insurancePresentialSelected = insurances.filter(
    (ins: types.IInsurances) => ins.has_online_schedule_active === true,
  );
  const insuranceTelemedicineSelected = insurances.filter(
    (ins: types.IInsurances) =>
      ins.allow_online_scheduling_telemedicine === true,
  );

  const modifiedInsurances = insurances.map((ins: types.IInsurances) => {
    if (insuranceAttendance === insuranceAttendanceType.Presential) {
      if (insurancePresentialSelected.length === insurances.length) {
        return {
          ...ins,
          has_online_schedule_active: false,
        };
      }
      return {
        ...ins,
        has_online_schedule_active: true,
      };
    }

    if (insuranceAttendance === insuranceAttendanceType.Telemedicine) {
      if (insuranceTelemedicineSelected.length === insurances.length) {
        return {
          ...ins,
          allow_online_scheduling_telemedicine: false,
        };
      }
      return {
        ...ins,
        allow_online_scheduling_telemedicine: true,
      };
    }

    return ins;
  });

  const newSettings = { ...settings, insurances: modifiedInsurances };

  yield put(fetchSettingsSucces(newSettings));
}

export function* onChangeCheckDay(action) {
  try {
    const { weekDay } = action.payload;
    const settings = yield select(selectSettings);
    const hoursWeekDay = yield getWeekDayServiceDays(weekDay);
    const { service_periods: servicePeriods } = settings.settings;
    const isWorkDay = yield compareHoursScheduleToSettings(weekDay);
    const { startService, endService } = yield getSettingsScheduleHoursSelect();

    if (isWorkDay) {
      if (hoursWeekDay.length === 0) {
        const newSettings = {
          ...servicePeriods,
          [weekDay]: [{ start_time: startService, end_time: endService }],
        };
        yield put(onChangeCheckDaySuccess(newSettings));
      } else {
        const newSettings = { ...servicePeriods, [weekDay]: [] };
        yield put(onChangeCheckDaySuccess(newSettings));
      }
    } else {
      yield put(notifyBlockedWeekDay());
    }
  } catch (e) {
    captureException(e);
  }
}

export function* onChangeSwitchIsShowOnlineSchedule() {
  const settings = yield select(selectSettings);
  const {
    clinic: { has_online_schedule_active: hasOnlineScheduleActive },
  } = settings;
  const modifiedSwitch = !hasOnlineScheduleActive;

  const newSettings = {
    ...settings,
    clinic: { has_online_schedule_active: modifiedSwitch },
  };

  yield put(fetchSettingsSucces(newSettings));
}

export function* sendInsurancesSchedule() {
  const settings = yield select(selectSettings);
  const { insurances } = settings;
  try {
    const newInsurances = insurances.map((insurance) => ({
      has_online_schedule_active: insurance.has_online_schedule_active,
      allow_online_scheduling_telemedicine:
        insurance.allow_online_scheduling_telemedicine,
      id: insurance.id,
      insurance: insurance.insurance,
      physician: insurance.physician,
    }));
    const objInsurances = { deleted_objects: [], objects: newInsurances };
    const newSettings = { ...settings, insurances };
    yield call(onlineScheduleApi.updateInsurancesSchedule, objInsurances);
    yield put(fetchSettingsSucces(newSettings));
  } catch (e) {
    captureException(e);
    yield put(updateInsurancesOnlineScheduleFailure(GENERIC_ERROR_MESSAGE));
  }
}

export function* onChangeSelectHour(action) {
  const { index, id, value, weekDay } = action.payload;
  const weekdayWorkHours = yield select(selectServicePeriod);
  const hoursWeekDay = weekdayWorkHours[weekDay];

  const hoursWeekDayNewObject = Object.create(hoursWeekDay);

  const hourAlter = hoursWeekDayNewObject[index];
  hourAlter[id] = value;

  const weekDaysHoursChanged = {
    ...weekdayWorkHours,
    [weekDay]: [
      {
        ...hoursWeekDay,
      },
    ],
  };

  yield put(fetchSettingsSucces(weekDaysHoursChanged));
}

export function verifyEmptyDay(servicePeriods) {
  const weekDays = Object.keys(servicePeriods);
  let isValidHours = false;

  weekDays.forEach((weekday) => {
    if (weekday && servicePeriods[weekday].length > 0) {
      isValidHours = true;
    }
  });
  return isValidHours;
}

export function* updateSettingsSagas() {
  try {
    const settings = yield select(selectSettings);
    const {
      insurances,
      settings: { antecedence_period },
    } = settings;
    const {
      settings: { id, service_periods: servicePeriods, physician, clinic },
    } = settings;
    const insurancesPresential = insurances.filter(
      (ins: types.IInsurances) => ins.has_online_schedule_active === true,
    );
    const insurancesTelemedicine = insurances.filter(
      (ins: types.IInsurances) =>
        ins.allow_online_scheduling_telemedicine === true,
    );
    const responsePutSettings = yield call(
      onlineScheduleApi.updateSettingsDetail,
      id,
      clinic,
      servicePeriods,
      physician,
      antecedence_period,
    );

    if (!verifyEmptyDay(servicePeriods)) {
      yield put(invalidateInsurances('Coloque pelo menos um dia e horário.'));
      return;
    }

    if (
      insurancesPresential.length === 0 &&
      insurancesTelemedicine.length === 0
    ) {
      yield put(invalidateInsurances('Selecione pelo menos um convênio.'));
      return;
    }

    yield all([
      updateIsShowOnlineSchedule(),
      sendInsurancesSchedule(),
      changeOnlineScheduleSettings({ payload: { physicianId: physician } }),
    ]);

    yield put(updateSettingsSuccess({ responsePutSettings }));
  } catch (e) {
    captureException(e);
    yield put(updateSettingsFailure(GENERIC_ERROR_MESSAGE));
  }
}

export function* removeIntegrationSaga(action) {
  const { physicianId } = action.payload;
  try {
    yield call(onlineScheduleApi.removeIntegration, physicianId);
    yield call(watchFetchSettings);
    yield put(removeIntegrationSuccess());
  } catch (error) {
    captureException(error);
    yield put(updateSettingsFailure('Não foi possível remover integração'));
  }
}

export function* validateEmailIntegration(action) {
  const { email } = action.payload;
  yield put(setIsLoadingIntegration(true));
  try {
    const response = yield call(
      onlineScheduleApi.validateEmailIntegration,
      email,
    );
    const { integrated, exists } = response.data;

    if (integrated) {
      yield put(validateEmailAlreadyIntegrated());
      return;
    }

    const nextStep = exists
      ? IntegrationStep.PasswordValidation
      : IntegrationStep.PasswordCreation;

    yield put(validateEmailIntegrationSuccess(nextStep, email));
  } catch (_errorResponse) {
    yield put(validateEmailIntegrationFailure());
  }
  yield put(setIsLoadingIntegration(false));
}

export function* validatePasswordIntegration(action) {
  const { email, password } = action.payload;
  yield put(setIsLoadingIntegration(true));
  try {
    const response = yield call(
      onlineScheduleApi.createValidatePasswordIntegration,
      email,
      password,
      true,
    );
    const { token, profile_slug } = response.data;
    yield put(validatePasswordIntegrationSuccess(token, profile_slug));
  } catch (errorResponse) {
    if (errorResponse.status === 411) {
      yield put(validatePasswordIntegrationFailure());
    } else {
      yield put(validateEmailIntegrationFailure());
    }
  }
  yield put(setIsLoadingIntegration(false));
}

export function* createPasswordIntegration(action) {
  const { email, password, hasAcceptedTerms } = action.payload;
  yield put(setIsLoadingIntegration(true));
  try {
    const response = yield call(
      onlineScheduleApi.createValidatePasswordIntegration,
      email,
      password,
      hasAcceptedTerms,
    );
    const { token } = response.data;
    yield put(createPasswordIntegrationSuccess(token));
  } catch (_errorResponse) {
    yield put(validateEmailIntegrationFailure());
  }
  yield put(setIsLoadingIntegration(false));
}

export default function* settingsOnlineSagas() {
  yield all([
    takeLatest(
      types.REMOVE_INTEGRATION_ONLINE_SCHEDULE.ACTION,
      removeIntegrationSaga,
    ),

    takeLatest(types.FETCH_PHYSICIANS.ACTION, getPhysiciansSagas),
    takeLatest(types.FETCH_SETTINGS_INITIALIZED.ACTION, watchFetchSettings),
    takeLatest(
      types.FETCH_SETTINGS_ONLINE_SCHEDULE.ACTION,
      changeOnlineScheduleSettings,
    ),
    takeLatest(types.ON_CHANGE_CHECK_INSURANCE.ACTION, onChangeCheckInsurance),
    takeLatest(
      types.ON_CHANGE_ALL_CHECK_INSURANCE.ACTION,
      onChangeAllCheckInsurances,
    ),
    takeLatest(types.ON_CHANGE_CHECK_DAY.ACTION, onChangeCheckDay),
    takeLatest(types.ON_CHANGE_SELECT_HOUR.ACTION, onChangeSelectHour),
    takeLatest(types.ADD_WORK_HOUR.ACTION, addHour),
    takeLatest(types.REMOVE_WORK_HOUR.ACTION, removeHour),
    takeLatest(
      types.UPDATE_SETTINGS_ONLINE_SCHEDULE.ACTION,
      updateSettingsSagas,
    ),
    takeLatest(
      types.ON_CHANGE_SWITCH_IS_SHOW_SCHEDULE.ACTION,
      onChangeSwitchIsShowOnlineSchedule,
    ),
    takeLatest(
      types.VALIDATE_EMAIL_INTEGRATION.ACTION,
      validateEmailIntegration,
    ),
    takeLatest(
      types.VALIDATE_PASSWORD_INTEGRATION.ACTION,
      validatePasswordIntegration,
    ),
    takeLatest(
      types.CREATE_PASSWORD_INTEGRATION.ACTION,
      createPasswordIntegration,
    ),
  ]);
}
