import { Button, MaterialIcons, tokens } from '@iclinic/design-system';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getTotalValuesSelector } from 'features/newFinance/state/selectors';
import PaymentList from './PaymentList';
import PaymentsModal from './PaymentsModals';
import * as S from './styles';

const { Add } = MaterialIcons;

const PaymentInfo = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { remainingAmount, total } = useSelector(getTotalValuesSelector);
  const totalValueIsEqualZero = !Number(total);

  const handleOpenModal = () => setIsOpen(true);
  return (
    <S.PaymentInfoContainer>
      <div className="payment-info-header">
        <span className="payment-info-title">Informações do pagamentos</span>
        <Button
          size="small"
          data-testId="addPaymentButton"
          variant="outlined"
          onClick={handleOpenModal}
          disabled={remainingAmount === 0 || totalValueIsEqualZero}
        >
          <Add />
          Formas de pagamento
        </Button>
      </div>
      <span className="payment-method-title">Formas de pagamento</span>
      <span className="payment-method-info">
        Escolha as formas de pagamento que <br /> deseja lançar os pagamentos
        recebidos
      </span>

      <PaymentList />

      <S.PaymentInfoObservationsArea
        label="Observações"
        placeholder="Insira as observações sobre o pagamento"
        style={{
          width: '100%',
          marginTop: tokens.spacing.lg,
        }}
      />

      {isOpen && (
        <PaymentsModal handleClose={() => setIsOpen(false)} show={isOpen} />
      )}
    </S.PaymentInfoContainer>
  );
};

export default PaymentInfo;
