import React from 'react';
import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

import history from 'routes/history';
import * as S from './SideBar.styles';

const { ListItemText } = MaterialUICore;

const { color } = axiomTokens;

const options = [
  {
    id: 'dashboard',
    text: 'Dashboard',
    background: color.surface.selected.default,
    color: color.surface.feedback.info.accent,
    path: '/pesquisa-de-satisfacao/',
  },
  {
    id: 'envio da pesquisa',
    text: 'Envio da pesquisa',
    background: color.surface.selected.default,
    color: color.surface.feedback.info.accent,
    path: '/pesquisa-de-satisfacao/envio-de-pesquisa/',
  },
];

export default function SurveySideBar() {
  const pathName = window.location.pathname;

  const handleOptionClick = (path: string) => {
    if (pathName === path) {
      return;
    }
    history.push(path);
  };

  return (
    <S.SideBar>
      <S.Menu>
        <ListItemText>Pesquisa</ListItemText>
      </S.Menu>

      <S.OptionsMenu>
        {options.map((option) => (
          <S.MenuItem
            className={pathName === option.path ? 'selected' : ''}
            key={option.id}
            onClick={() => handleOptionClick(option.path)}
          >
            <S.ListItemText
              className={pathName === option.path ? 'selected' : ''}
              disableTypography
              primary={option.text}
            />
          </S.MenuItem>
        ))}
      </S.OptionsMenu>
    </S.SideBar>
  );
}
