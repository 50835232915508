import { PromiseEnvelope } from '@types';

import { FetchSendLogsData } from '../types';
import { apiTelemedicine } from '../api';
import { checkAudioAndVideoPermissions } from 'features/video-conference/utils';

type LogAudioAndVideoPermissionsData = {
  conferenceId: number;
  clinicId: number;
  physicianId: number;
  patientId: number;
  participantType: string;
  logger: Function;
};

export const fetchSendLogs = (body: FetchSendLogsData): PromiseEnvelope<void> =>
  apiTelemedicine.post('conference/logs', body);

export const logAudioAndVideoPermissions = async ({
  conferenceId,
  clinicId,
  physicianId,
  patientId,
  participantType,
  logger,
}: LogAudioAndVideoPermissionsData) => {
  const { audio, video } = await checkAudioAndVideoPermissions();

  await logger({
    conferenceId,
    clinicId,
    physicianId,
    patientId,
    participantType,
    actionType: 'multimedia_permissions',
    description: `audio: ${String(audio)}, video: ${String(video)}`,
  });
};
