import {
  Grid,
  MaterialIcons,
  MaterialUICore,
  axiomTokens,
  TextField as TextFieldValue,
} from '@iclinic/design-system';
import React, { forwardRef, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import CollapsibleFormSection from 'features/tissInvoicing/components/ui/CollapsibleFormSection';
import { AppointmentGuideFormValues } from 'features/tissInvoicing/types/appointmentGuide';
import compareVersions from 'features/tissInvoicing/utils/compareVersions';
import { getDomainTablesOptions } from 'features/tissInvoicing/state/domainTables/selectors';
import ProcedureName from 'features/tissInvoicing/containers/components/CommonAutocompletes/ProcedureName';
import SelectField from 'features/tissInvoicing/components/appointmentGuide/SelectField';
import TextField from 'features/tissInvoicing/components/appointmentGuide/TextField';
import DatePicker from 'features/tissInvoicing/components/appointmentGuide/DatePicker';
import useCurrencyMask, { format } from 'shared/hooks/useCurrencyMask';
import { getFixedNumber } from 'features/onlinePayment/utils';

const { MenuItem } = MaterialUICore;
const { ContentPaste } = MaterialIcons;

interface AppointmentSectionProps {}

const AppointmentSection = (
  _: AppointmentSectionProps,
  ref: React.Ref<HTMLButtonElement>,
) => {
  const { values, setFieldValue } =
    useFormikContext<AppointmentGuideFormValues>();

  const {
    appointmentTypes,
    accidentIndications,
    attendanceRegimens,
    specialCoverages,
    occupationalHealthList,
  } = useSelector(getDomainTablesOptions);

  const {
    ref: refPrice,
    onChange: onChangeMinValue,
    originalValue: valueUnformatted,
  } = useCurrencyMask(values?.total_amount ?? 0);

  useEffect(() => {
    setFieldValue('total_amount', valueUnformatted);
  }, [valueUnformatted, setFieldValue]);

  const isLessThanV4 = compareVersions(values.version_id).isLessThan(4);

  return (
    <CollapsibleFormSection
      sectionIdentifier="appointmentSection"
      sectionTitle="Consulta"
      sectionIcon={
        <ContentPaste color={axiomTokens.color.interactive.accent.default} />
      }
      ref={ref}
    >
      <Grid container item spacing={2}>
        <Grid item xs={12} md={3}>
          <SelectField
            identifier="accident_indication"
            label="17 - Indicação do acidente"
            required
          >
            {accidentIndications.map((accidentIndication) => (
              <MenuItem
                value={accidentIndication.id}
                key={accidentIndication.id}
              >
                {accidentIndication.term}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectField
            identifier="special_coverage"
            label="27 - Indicador cobertura especial"
            disabled={isLessThanV4}
          >
            {specialCoverages.map((specialCoverage) => (
              <MenuItem value={specialCoverage.id} key={specialCoverage.id}>
                {specialCoverage.term}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectField
            identifier="attendance_regimen"
            label="28 - Regime de atendimento"
            disabled={isLessThanV4}
            required={!isLessThanV4}
          >
            {attendanceRegimens.map((attendanceRegimen) => (
              <MenuItem value={attendanceRegimen.id} key={attendanceRegimen.id}>
                {attendanceRegimen.term}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} md={3}>
          <SelectField
            identifier="occupational_health"
            label="29 - Saúde ocupacional"
            disabled={isLessThanV4}
          >
            {occupationalHealthList.map((occupationalHealth) => (
              <MenuItem
                value={occupationalHealth.id}
                key={occupationalHealth.id}
              >
                {occupationalHealth.term}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            identifier="request_date"
            label="18 - Data da consulta"
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectField
            identifier="appointment_type"
            label="19 - Tipo da consulta"
            required
          >
            {appointmentTypes.map((appointmentType) => (
              <MenuItem value={appointmentType.id} key={appointmentType.id}>
                {appointmentType.term}
              </MenuItem>
            ))}
          </SelectField>
        </Grid>
        <Grid item xs={12} md={4}>
          <SelectField
            identifier="tuss_table_code"
            label="20 - Código da tabela"
            required
          >
            <MenuItem value="22" key="22">
              22 - Terminologia de procedimentos e eventos em saúde
            </MenuItem>
            <MenuItem value="00" key="00">
              00 - Tabela própria das operadoras
            </MenuItem>
            <MenuItem value="98" key="98">
              98 - Tabela própria de pacotes
            </MenuItem>
          </SelectField>
        </Grid>
        <Grid item xs={12} md={4}>
          <ProcedureName required />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextFieldValue
            id="total_amount"
            name="total_amount"
            label="22 - Valor do procedimento"
            placeholder="R$ 0,00"
            defaultValue={format(getFixedNumber(String(values.total_amount)))}
            inputRef={refPrice}
            onChange={onChangeMinValue}
            fullWidth
            required
          />
        </Grid>
      </Grid>
      <Grid container item spacing={2} direction="column" zeroMinWidth>
        <Grid item xs={12} md={12}>
          <TextField identifier="observation" label="23 - Observações" />
        </Grid>
      </Grid>
    </CollapsibleFormSection>
  );
};

export default forwardRef(AppointmentSection);
