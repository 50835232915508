import { MaterialUICore } from '@iclinic/design-system';
import { HEADER_HEIGHT } from 'features/onlinePayment/components/checkout/Header.styles';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  container: {
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    margin: '0 auto 2.5em auto',
    [theme.breakpoints.down('xs')]: {
      margin: '1em 0',
    },
  },
  title: {
    color: theme.palette.grey[700],
  },
  subTitle: {
    color: theme.palette.grey[500],
  },
  icon: {
    display: 'block',
    margin: '1em auto',
  },
  email: {
    height: '48px',
    '& .MuiFilledInput-input': {
      padding: '27px 8px 0',
    },
  },
}));
