import React, { useMemo } from 'react';
import { useRifm } from 'rifm';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import {
  SectionTitle,
  MaterialIcons,
  Grid,
  TextField,
} from '@iclinic/design-system';
import {
  cpfFormatter,
  onlyNumbers,
  phoneFormatter,
} from 'shared/utils/formatters';
import { RequestSamplesFormData, AutoFillMapData } from './types';
import { SelectStateField } from 'features/free-samples/components';
import { trackRequestSamplesFormStep } from 'features/free-samples/trackRequestSamplesFormUtils';
import {
  useAutoFillFields,
  usePhysicianFormErrorTracking,
} from 'features/free-samples/hooks';
import {
  getUserInfo,
  getAddress,
  getPersonVUCData,
} from 'features/free-samples/state/request-samples/selectors';
import { fetchPersonVUCData } from 'features/free-samples/state/request-samples';

const { Person } = MaterialIcons;

export default function SectionPhysician(): JSX.Element {
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserInfo);
  const addressData = useSelector(getAddress);
  const personVUCData = useSelector(getPersonVUCData);

  const {
    handleChange,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext<RequestSamplesFormData>();

  const personValues = useMemo<AutoFillMapData>(
    () => ({
      ufCrm: personVUCData?.crm.state,
      crm: personVUCData?.crm.council_num,
      fullName: personVUCData?.full_name,
      phoneNumber: phoneFormatter(personVUCData?.phone_number || ''),
      cpf: cpfFormatter(personVUCData?.cpf || ''),
    }),
    [personVUCData],
  );

  const addressValues = useMemo<AutoFillMapData>(
    () => ({
      ufCrm: addressData?.uf_crm,
      crm: addressData?.crm,
      fullName: addressData?.full_name,
      phoneNumber: phoneFormatter(
        `${addressData?.phone_area} ${addressData?.phone_number}`,
      ),
      cpf: cpfFormatter(addressData?.cpf || ''),
    }),
    [addressData],
  );

  useAutoFillFields(userInfo, personValues);
  useAutoFillFields(userInfo, addressValues);

  const { value: cpfValue, onChange: onChangeCpf } = useRifm({
    value: values.cpf,
    onChange: (value: string) => setFieldValue('cpf', value),
    format: cpfFormatter,
  });

  const { value: phoneValue, onChange: onChangePhone } = useRifm({
    value: values.phoneNumber,
    onChange: (value: string) => setFieldValue('phoneNumber', value),
    format: phoneFormatter,
  });

  usePhysicianFormErrorTracking(errors, touched, userInfo);

  const handleBlurFields = () => {
    const { ufCrm, crm, cpf } = values;

    if (ufCrm && crm) {
      dispatch(fetchPersonVUCData({ uf_crm: ufCrm, crm }));
      return;
    }

    if (cpf) {
      dispatch(fetchPersonVUCData({ cpf: onlyNumbers(cpf) }));
    }
  };

  return (
    <div>
      <SectionTitle icon={<Person />} title="Médico" />

      <Grid container spacing={2}>
        <Grid item xs={5} sm={2} lg={2}>
          <SelectStateField
            id="ufCrm"
            name="ufCrm"
            label="Estado *"
            value={values.ufCrm}
            error={!!(errors.ufCrm && touched.ufCrm)}
            helperText={(touched.ufCrm && errors.ufCrm) || 'Estado do CRM'}
            onBlur={() => {
              handleBlurFields();
              trackRequestSamplesFormStep({
                userInfo,
                stepName: 'user_crm_state',
                stepNum: 1,
              });
            }}
          />
        </Grid>
        <Grid item xs={7} sm={2} lg={2}>
          <TextField
            id="crm"
            name="crm"
            label="CRM *"
            value={values.crm}
            onChange={handleChange}
            error={!!(errors.crm && touched.crm)}
            helperText={(touched.crm && errors.crm) || 'CRM sem Estado'}
            fullWidth
            onBlur={() => {
              handleBlurFields();
              trackRequestSamplesFormStep({
                userInfo,
                stepName: 'user_crm_number',
                stepNum: 2,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8} lg={8}>
          <TextField
            id="fullName"
            name="fullName"
            label="Nome completo *"
            value={values.fullName}
            onChange={handleChange}
            error={!!(errors.fullName && touched.fullName)}
            helperText={touched.fullName && errors.fullName}
            fullWidth
            onBlur={() => {
              trackRequestSamplesFormStep({
                userInfo,
                stepName: 'user_full_name',
                stepNum: 3,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
          <TextField
            id="cpf"
            name="cpf"
            label="CPF *"
            value={cpfValue}
            onChange={onChangeCpf}
            error={!!(errors.cpf && touched.cpf)}
            helperText={touched.cpf && errors.cpf}
            fullWidth
            onBlur={() => {
              handleBlurFields();
              trackRequestSamplesFormStep({
                userInfo,
                stepName: 'user_cpf',
                stepNum: 4,
              });
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={4}>
          <TextField
            id="phoneNumber"
            name="phoneNumber"
            label="Telefone / Celular *"
            value={phoneValue}
            onChange={onChangePhone}
            error={!!(errors.phoneNumber && touched.phoneNumber)}
            helperText={
              (touched.phoneNumber && errors.phoneNumber) || 'DDD + número'
            }
            fullWidth
            onBlur={() => {
              trackRequestSamplesFormStep({
                userInfo,
                stepName: 'user_phone',
                stepNum: 5,
              });
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}
