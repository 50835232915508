import { Box, Button, MaterialIcons, tokens } from '@iclinic/design-system';
import React from 'react';

import { IChangeExpensePayload } from '../../interfaces/ChangeExpensePayload';
import { IExpenseItem } from '../../interfaces/ExpenseItem';
import Item from './item';
import { Container } from './styles';

interface AddExpenseExpensesProps {
  items: IExpenseItem[];
  onClickAdd(): void;
  onClickDelete(id: number | string): void;
  onChange(payload: IChangeExpensePayload): void;
  showErrors: boolean;
  showAddButton?: boolean;
  addButtonPosition?: 'top' | 'bottom';
}

const { Add } = MaterialIcons;

const AddExpenseExpenses = ({
  items = [],
  onClickAdd,
  onClickDelete,
  onChange,
  showErrors,
  showAddButton = true,
  addButtonPosition = 'top',
}: AddExpenseExpensesProps) => (
  <Container>
    {showAddButton && addButtonPosition === 'top' && (
      <Button
        disabled={items.length === 10}
        color="secondary"
        startIcon={<Add />}
        style={{ marginRight: 'auto', marginBottom: tokens.spacing.md }}
        onClick={onClickAdd}
      >
        Despesa
      </Button>
    )}

    {items.map((item, index) => (
      <Item
        key={item.id}
        position={index + 1}
        onClickDelete={() => onClickDelete(item.id)}
        onChange={onChange}
        item={item}
        showErrors={showErrors}
      />
    ))}

    {showAddButton && addButtonPosition === 'bottom' && (
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          color="secondary"
          size="small"
          startIcon={<Add />}
          onClick={onClickAdd}
        >
          Despesa
        </Button>
      </Box>
    )}
  </Container>
);

export default AddExpenseExpenses;
