import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Body,
  Button,
  DialogActions,
  DialogContent,
} from '@iclinic/design-system';
import { image } from 'shared/utils/urlGetter';
import { toggleDialog } from '../state/reducer';
import { getDaysUntilChange, getLastChangeDay } from '../state/selectors';
import { ImageWrapper } from './Dialog.styles';
import { formatDateToDayMonthPresentation } from '../utils';
import { pluralize } from 'shared/utils/strings';

export const DialogRecentlyChanged = () => {
  const dispatch = useDispatch();
  const lastChangeDay = useSelector(getLastChangeDay);
  const daysUntilNextChange = useSelector(getDaysUntilChange);

  const daysToChange = useMemo(() => daysUntilNextChange.replace(/\D/g, ''), [
    daysUntilNextChange,
  ]);

  const dayMonthDate = useMemo(
    () => formatDateToDayMonthPresentation(lastChangeDay),
    [lastChangeDay],
  );

  return (
    <>
      <DialogContent>
        <ImageWrapper>
          <img
            width={160}
            src={image('subscription.forbiddenChangeDate')}
            alt="Venha ganhar mais"
          />
        </ImageWrapper>
        <Body variant="xs">
          Você alterou o seu dia de vencimento em {dayMonthDate}. A próxima
          alteração poderá ser feita em {daysToChange}{' '}
          {pluralize(daysToChange, 'dia', 'dias')}.
        </Body>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => dispatch(toggleDialog())}>
          Entendi
        </Button>
      </DialogActions>
    </>
  );
};
