import React from 'react';
import { useSelector } from 'react-redux';

import { getSchedulePaymentsList } from 'features/newFinance/state/selectors';
import PaymentItem from './PaymentItems';
import { PaymentList as StyledPaymentList } from './styles';

const PaymentList = () => {
  const paymentList = useSelector(getSchedulePaymentsList);
  return (
    <StyledPaymentList>
      {paymentList.map((payment, index) => (
        <PaymentItem
          key={`${payment.paymentType}-${String(index)}`}
          payment={payment}
        />
      ))}
    </StyledPaymentList>
  );
};

export default PaymentList;
