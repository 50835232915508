import React from 'react';
import { useDispatch } from 'react-redux';
import { SwipeableContainer, MaterialUICore } from '@iclinic/design-system';

import { actions } from '../../state';
import {
  CardInteractionTracking,
  CardKind,
  HighlightsInfoListCard,
  HighlightsInfoTextCard,
  HighlightsInfoListCardDenied,
  HighlightsInfoType,
} from '../../types';
import { TypeCardMap, getCardTypeTracking, getDeniedTitle } from '../../utils';
import * as S from './HighlightsInfoCards.styles';
import { useTrackHighlightsInfoEvents } from '../../hooks/useTrackHighlightsInfoEvents';
import CardList from '../CardList';

const { openHighlightsInfoModal } = actions;
const { useTheme, useMediaQuery } = MaterialUICore;

interface HighlightInfoCardPropsArray {
  highlightInfo: HighlightsInfoType[];
}

const HighlightsInfoCards = ({
  highlightInfo,
}: HighlightInfoCardPropsArray) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { trackEvents } = useTrackHighlightsInfoEvents();

  const trackEvent = (
    cardInteraction: CardInteractionTracking,
    card: HighlightsInfoType,
  ) => {
    trackEvents({
      cardInteraction,
      cardType: getCardTypeTracking(card.typeCard),
      cardName: TypeCardMap[card.typeCard],
    });
  };

  const handleCardClick = (card: HighlightsInfoType) => {
    dispatch(openHighlightsInfoModal(card));
    trackEvent(CardInteractionTracking.open, card);
    trackEvent(CardInteractionTracking.view, card);
  };

  const renderListCard = (
    cardList: HighlightsInfoListCard | HighlightsInfoListCardDenied,
  ) => {
    const item = { ...cardList } as HighlightsInfoListCard;
    if ((cardList as HighlightsInfoListCardDenied).isDenied) {
      item.items = [
        {
          title: getDeniedTitle(item),
          itemId: `item-denied-${item.typeCard}`,
        },
      ];
    }
    if (item.items?.length) {
      return <CardList item={item} />;
    }
    return <S.BodyTextEmpty>Inserir informação</S.BodyTextEmpty>;
  };

  const renderTextCard = (cardText: HighlightsInfoTextCard) => {
    if (cardText.content) {
      return <S.BodyTextFilled>{cardText.content}</S.BodyTextFilled>;
    }
    return <S.BodyTextEmpty>Inserir informação</S.BodyTextEmpty>;
  };

  return (
    <SwipeableContainer navigation={!isMobile} swipeBlockWidth={500}>
      <S.Container>
        {highlightInfo.map((card) => (
          <S.CardContainer
            key={`highlightInfoCardsMap-${card.typeCard}`}
            onClick={() => handleCardClick(card)}
            data-testid="card-container"
          >
            <S.WrapperContent>
              <S.ContainerHeaderAndIcon>
                <S.HeadingText>{TypeCardMap[card.typeCard]}</S.HeadingText>
                <S.ContainerEdit>
                  <S.EditRoundedStyled />
                </S.ContainerEdit>
              </S.ContainerHeaderAndIcon>
              {card.kind === CardKind.Text
                ? renderTextCard(card as HighlightsInfoTextCard)
                : renderListCard(card as HighlightsInfoListCard)}
            </S.WrapperContent>
          </S.CardContainer>
        ))}
      </S.Container>
    </SwipeableContainer>
  );
};

export default HighlightsInfoCards;
