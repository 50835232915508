import { createSelector } from 'reselect';
import moment from 'moment';

import { TEXT_MULTICLINIC, TEXT_ONE_CLINIC } from './constants';

export const getUserData = ({ userInfo }) => userInfo.userData;

export const getUserPermissions = createSelector(
  getUserData,
  ({ permissions }) => permissions,
);

const getOnlineSchedule = (state) => state.onlineSchedule;

const weekdays = moment.weekdays(true).map((day) => day.toLowerCase());

export const getOnlineScheduleSettings = createSelector(
  getOnlineSchedule,
  (onlineSchedule) => {
    const { settings, physicians, error, notification } = onlineSchedule;
    const { has_schedule_integration: hasScheduleIntegration } = settings;

    let contentLabelSwitch = null;
    let servicePeriods = [];
    let insurances = [];
    let antecedencePeriod = 0;

    if (settings && settings.insurances) {
      const physicianName = physicians.find(
        (id) => id.id === settings.settings.physician,
      );
      insurances = settings.insurances;
      contentLabelSwitch = settings.is_multiclinic
        ? TEXT_MULTICLINIC(settings.clinic.clinic_name, physicianName.name)
        : TEXT_ONE_CLINIC(physicianName.name);
      servicePeriods = settings.settings.service_periods;
      antecedencePeriod = settings.settings.antecedence_period;
    }
    return {
      servicePeriods,
      weekdays,
      contentLabelSwitch,
      insurances,
      error,
      notification,
      hasScheduleIntegration,
      antecedencePeriod,
    };
  },
);

export const getSettingsInsurances = createSelector(
  getOnlineScheduleSettings,
  ({ insurances }) => insurances,
);

export const getHasScheduleIntegration = createSelector(
  getOnlineScheduleSettings,
  ({ hasScheduleIntegration }) => hasScheduleIntegration,
);

export const getSettingsAntecedencePeriod = createSelector(
  getOnlineScheduleSettings,
  ({ antecedencePeriod }) => antecedencePeriod,
);

export const getIsShowOnlineSchedule = createSelector(
  getOnlineSchedule,
  (onlineSchedule) => {
    const {
      settings: { clinic },
    } = onlineSchedule;
    return clinic;
  },
);

export const getSettingsScheduleHours = createSelector(
  getOnlineSchedule,
  (onlineSchedule) => {
    const { scheduleWorkHours } = onlineSchedule;
    return scheduleWorkHours;
  },
);

export const getSettingsIntegration = createSelector(
  getOnlineSchedule,
  (onlineSchedule) => {
    const { integration } = onlineSchedule;
    return integration;
  },
);
