import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInfiniteScroll } from 'features/tissInvoicing/hooks';
import { actions } from 'features/tissInvoicing/state/lot';
import { getGuides } from 'features/tissInvoicing/state/lot/selectors';
import Columns from './Columns';
import Rows from './Rows';
import * as Styles from './styles';
import { MaterialUICore } from '@iclinic/design-system';
import { Table, TableRow } from 'features/tissInvoicing/components/ui/Table';

const { TableContainer, TableBody, TableHead } = MaterialUICore;

function TableContent() {
  const dispatch = useDispatch();
  const classes = Styles.useStyles();

  const { availableGuides, loading, hasMoreData } = useSelector(getGuides);

  const { infiniteScrollRef, pageNum } = useInfiniteScroll({
    hasMoreData,
    loading,
  });

  useEffect(() => {
    dispatch(actions.fetchGuides({ page: pageNum }));
  }, [dispatch, pageNum]);

  return (
    <TableContainer classes={{ root: classes.container }}>
      <Table aria-label="Lista de guias" stickyHeader>
        <TableHead>
          <TableRow>
            <Columns />
          </TableRow>
        </TableHead>

        <TableBody>
          {availableGuides.map((row, i) => {
            const isLastElement = availableGuides.length === i + 1;
            return (
              <TableRow
                key={row.id}
                hover
                stripped={+true}
                ref={isLastElement ? infiniteScrollRef : undefined}
              >
                <Rows rowData={row} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TableContent;
