import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialIcons, Grid, Box, Tooltip } from '@iclinic/design-system';

import { ErrorAlert } from 'shared/components';
import { formatCurrency } from 'shared/utils/strings';
import { RequestStatus } from 'shared/constants/State';
import { fetchSSOToken } from 'features/onlinePayment/state/iclinic-pay';
import { getSSOToken } from 'features/onlinePayment/state/iclinic-pay/selectors';
import {
  createLink,
  ICLINIC_PAY_URLS,
} from 'features/onlinePayment/containers/iclinic-pay/iClinicPayIntegration';
import {
  getFinancialSellerData,
  getFinancialSellerDataErrorMessage,
  getFinancialSellerDataStatus,
} from 'features/onlinePayment/state/panel/selectors';
import { LoaderColumnInfos } from './Loader';
import * as S from './styles';

const { Info, Receipt, LocalAtm, MonetizationOn } = MaterialIcons;

export const Panel = () => {
  const dispatch = useDispatch();
  const financialSellerData = useSelector(getFinancialSellerData);
  const fetchStatus = useSelector(getFinancialSellerDataStatus);
  const fetchError = useSelector(getFinancialSellerDataErrorMessage);
  const ssoToken = useSelector(getSSOToken);

  useEffect(() => {
    dispatch(fetchSSOToken());
  }, [dispatch, financialSellerData]);

  if (fetchStatus === RequestStatus.Pending) {
    return (
      <S.Paper variant="outlined">
        <S.Title variant="xxs">iClinic Pay</S.Title>
        <LoaderColumnInfos />
      </S.Paper>
    );
  }

  if (fetchError || !financialSellerData) {
    return (
      <S.Paper variant="outlined">
        <ErrorAlert
          message={
            <span dangerouslySetInnerHTML={{ __html: fetchError || '' }} />
          }
        />
      </S.Paper>
    );
  }

  const {
    countTransactionsApproved,
    totalAmountTransactionsApproved,
    averageTicketTransactionsApproved,
    valueToAntecipate,
  } = financialSellerData;

  return (
    <S.Paper variant="outlined">
      <S.Title variant="xxs">iClinic Pay</S.Title>
      <Grid spacing={3} item container justifyContent="center">
        <Grid item xs={12} md={3}>
          <Box textAlign="center">
            <S.Amount variant="md">{countTransactionsApproved}</S.Amount>
            <Box mb={2}>
              <S.Text variant="xxs">Número de transações</S.Text>
            </Box>
            <Receipt fontSize="small" />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box textAlign="center">
            <S.Amount variant="md">
              {formatCurrency(averageTicketTransactionsApproved)}
            </S.Amount>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
            >
              <S.Text variant="xxs">Ticket médio</S.Text>
              <S.WrapIcon>
                <Tooltip
                  placement="top"
                  title="O ticket médio é o somatório de faturas + transações de pagamento, dividido pela quantidade de transações/faturas."
                >
                  <Info color="primary" fontSize="small" />
                </Tooltip>
              </S.WrapIcon>
            </Box>
            <LocalAtm fontSize="small" />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box textAlign="center">
            <S.Amount variant="md">
              {formatCurrency(totalAmountTransactionsApproved)}
            </S.Amount>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mb={2}
            >
              <S.Text variant="xxs">Faturamento</S.Text>
              <S.WrapIcon>
                <Tooltip
                  placement="top"
                  title="O faturamento é a soma de todos os valores obtidos com os atendimentos em um determinado período."
                >
                  <Info color="primary" fontSize="small" />
                </Tooltip>
              </S.WrapIcon>
            </Box>
            <MonetizationOn fontSize="small" />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box textAlign="center">
            <S.Amount variant="md">
              {formatCurrency(valueToAntecipate)}
            </S.Amount>
            <Box mb={2}>
              <S.Text variant="xxs">Disponível para antecipar</S.Text>
            </Box>
            <S.LinkButton
              href={
                ssoToken
                  ? createLink(ssoToken, ICLINIC_PAY_URLS.ANTECIPACAO_RECEBIVES)
                  : ''
              }
              target="_blank"
              variant="contained"
              color="primary"
              size="small"
              disabled={!valueToAntecipate}
            >
              Antecipar agora
            </S.LinkButton>
          </Box>
        </Grid>
      </Grid>
    </S.Paper>
  );
};
