import * as Yup from 'yup';

import { isValidFullDate } from 'shared/utils/validation';

const characters20 = 'Até 20 caracteres';
export const requiredFieldText = 'Campo obrigatório';

export const generalSchema = Yup.object().shape({
  insurance_ans_register: Yup.string(),
  guide_number: Yup.string()
    .nullable()
    .required(requiredFieldText)
    .max(20, characters20),
  main_guide_number: Yup.string().nullable().max(20, characters20),
  authorization_date: Yup.mixed()
    .nullable()
    .test(
      'isValidFullDate',
      'Data inválida',
      (value) => !value || isValidFullDate(value),
    ),
  authorization_number: Yup.string().nullable().max(20, characters20),
  authorization_expiration_date: Yup.mixed()
    .nullable()
    .test(
      'isValidFullDate',
      'Data inválida',
      (value) => !value || isValidFullDate(value),
    ),
  operator_guide_number: Yup.string().nullable().max(20, characters20),
  service_type: Yup.number(),
  accident_indication: Yup.number(),
  appointment_type: Yup.number(),
  degree_participation: Yup.number(),
  reason_termination: Yup.number(),
  attendance_regimen: Yup.string(),
  occupational_health: Yup.string(),
  observation: Yup.string()
    .nullable()
    .max(150, 'Até 150 caracteres - Texto livre'),
});

export const generalSectionValues = {
  insurance_ans_register: null,
  insurance_name: '',
  guide_number: null,
  main_guide_number: null,
  authorization_date: null,
  authorization_number: null,
  authorization_expiration_date: null,
  operator_guide_number: null,
  degree_participation: 0,
  service_type: 0,
  accident_indication: 0,
  appointment_type: 0,
  reason_termination: 0,
  attendance_regimen: null,
  occupational_health: null,
  observation: null,
};
