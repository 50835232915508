import { Box, Heading, axiomTokens } from '@iclinic/design-system';
import React from 'react';

import * as Styles from './styles';

export default function HeaderGuideList() {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginBottom={axiomTokens.spacing['4']}
    >
      <Heading variant="lg">Guias de consulta</Heading>

      <Styles.Container>
        <Styles.LinkButton
          href="/tiss/guias-consulta/adicionar"
          size="medium"
          color="primary"
          variant="contained"
        >
          Adicionar guia
        </Styles.LinkButton>
      </Styles.Container>
    </Box>
  );
}
