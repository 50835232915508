export const SET_EVENT = '@webapp/onlinePayment/manage/SET_EVENT';

export const FETCH_SELLER_INFO_ACTION =
  '@webapp/onlinePayment/manage/FETCH_SELLER_INFO';
export const FETCH_SELLER_INFO_FAILURE =
  '@webapp/onlinePayment/manage/FETCH_SELLER_INFO_FAILURE';
export const FETCH_SELLER_INFO_SUCCESS =
  '@webapp/onlinePayment/manage/FETCH_SELLER_INFO_SUCCESS';

export const FETCH_BILL_ACTION = '@webapp/onlinePayment/manage/FETCH_BILL';
export const FETCH_BILL_FAILURE =
  '@webapp/onlinePayment/manage/FETCH_BILL_FAILURE';
export const FETCH_BILL_SUCCESS =
  '@webapp/onlinePayment/manage/FETCH_BILL_SUCCESS';

export const CREATE_BILL_ACTION = '@webapp/onlinePayment/manage/CREATE_BILL';
export const CREATE_BILL_FAILURE =
  '@webapp/onlinePayment/manage/CREATE_BILL_FAILURE';
export const CREATE_BILL_SUCCESS =
  '@webapp/onlinePayment/manage/CREATE_BILL_SUCCESS';

export const CREATE_BILL_ON_SCHEDULE_ACTION =
  '@webapp/onlinePayment/manage/CREATE_BILL_ON_SCHEDULE_ACTION';

export const FETCH_CHECKOUT_URL_ACTION =
  '@webapp/onlinePayment/manage/FETCH_CHECKOUT_URL';
export const FETCH_CHECKOUT_URL_FAILURE =
  '@webapp/onlinePayment/manage/FETCH_CHECKOUT_URL_FAILURE';
export const FETCH_CHECKOUT_URL_SUCCESS =
  '@webapp/onlinePayment/manage/FETCH_CHECKOUT_URL_SUCCESS';

export const UPDATE_BILL = '@webapp/onlinePayment/manage/UPDATE_BILL';

export const UPDATE_BILL_FAILURE =
  '@webapp/onlinePayment/manage/UPDATE_BILL_FAILURE';

export const UPDATE_BILL_SUCCESS =
  '@webapp/onlinePayment/manage/UPDATE_BILL_SUCCESS';

export const TOGGLE_IS_GENERATING =
  '@webapp/onlinePayment/manage/TOGGLE_IS_GENERATING';

export const TOGGLE_UPDATED_BILL =
  '@webapp/onlinePayment/manage/TOGGLE_UPDATED_BILL';

export const TOGGLE_CHARGEBACK_MODAL =
  '@webapp/onlinePayment/manage/TOGGLE_CHARGEBACK_MODAL';
