// External
import React from 'react';
import PropTypes from 'prop-types';

// Style
import style from './Footer.scss';

export default function Footer({ children }) {
  return <div className={style.summary_footer}>{children}</div>;
}

Footer.propTypes = {
  children: PropTypes.node.isRequired,
};
