import {
  Dialog,
  DialogContent as DSDialogContent,
  DialogActions as DSDialogActions,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, spacing } = tokens;

export const PaymentModal = styled(Dialog)(() => ({
  '& .MuiPaper-root': {
    maxWidth: '666px',
    width: '100%',
  },
}));

export const DialogContent = styled(DSDialogContent)(({ theme }) => ({
  padding: `${spacing.base} 0 0`,

  [theme.breakpoints.up('sm')]: {
    padding: `${spacing.base} ${spacing.sm} ${spacing.sm}`,
  },
}));

export const DialogActions = styled(DSDialogActions)(({ theme }) => ({
  backgroundColor: color.secondary.main,

  [theme.breakpoints.up('sm')]: {
    backgroundColor: color.neutral[0],
  },
}));

export const SummaryWrapper = styled('div')(({ theme }) => ({
  backgroundColor: color.secondary.light,
  marginTop: spacing.sm,
  padding: spacing.sm,
  flex: 1,

  [theme.breakpoints.up('sm')]: {
    border: `${border.width.hairline} solid ${color.secondary.main}`,
    borderRadius: border.radius.md,
    flex: 0,
  },
}));

export const ButtonMobileActions = styled('div')(({ theme }) => ({
  backgroundColor: color.secondary.light,

  [theme.breakpoints.up('sm')]: {
    backgroundColor: color.neutral[0],
  },
}));
