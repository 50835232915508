import {
  takeLatest,
  put,
  call,
  select,
  all,
} from 'redux-saga/effects';

import { handlerErrors } from 'state/shared/sagas';
import {
  savePatientFlowsSuccess,
  savePatientFlowsFailure,
  fetchPatientSummarySuccess,
  fetchPatientSummaryFailure,
  removePatientSuccess,
  removePatientFailure,
} from 'state/marketing/emailFlow/patient/actions';
import { toggleModal } from 'state/marketing/emailFlow/root/actions';
import iclinic from 'services/iclinic';

import {
  SAVE_PATIENT_FLOWS,
  FETCH_PATIENT_SUMMARY,
  REMOVE_PATIENT,
} from './constants';
import { getAddedFlows, getPatientId, getPatientListIds } from './selectors';
import { getSelectedProfessional } from '../professional/selectors';

import { AddedFlow, PatientId, FlowId } from './types';
import { Professional } from '../professional/types';
import { extractFlowIds } from './utils';

export function* workerSavePatientFlows() {
  try {
    const addedFlows: AddedFlow[] = yield select(({
      marketing: { emailFlow },
    }) => getAddedFlows(emailFlow.patient));

    const patientId: PatientId = yield select(({
      marketing: { emailFlow },
    }) => getPatientId(emailFlow.patient));

    const selectedProfessional: Professional | null = yield select(getSelectedProfessional);
    const physicianId = selectedProfessional && selectedProfessional.physician_id;

    const flows: FlowId[] = extractFlowIds(addedFlows);

    const { errors } = yield call(
      iclinic.marketing.savePatientFlows,
      flows,
      patientId,
      physicianId,
    );

    if (errors) throw errors;

    yield all([
      put(savePatientFlowsSuccess()),
      put(toggleModal()),
    ]);
  } catch (error) {
    yield call(handlerErrors, error, savePatientFlowsFailure);
  }
}

export function* workerFetchPatientSummary() {
  try {
    const patientId: PatientId | null = yield select(({
      marketing: { emailFlow },
    }) => getPatientId(emailFlow.patient));

    const selectedProfessional: Professional | null = yield select(getSelectedProfessional);

    const physicianId = selectedProfessional && selectedProfessional.physician_id;

    const { getResponseData, errors } = yield call(
      iclinic.marketing.fetchPatientSummary,
      patientId,
      physicianId,
    );

    if (errors) throw errors;
    const { availableFlows, addedFlows } = getResponseData();
    yield put(fetchPatientSummarySuccess(availableFlows, addedFlows));
  } catch (error) {
    yield call(handlerErrors, error, fetchPatientSummaryFailure);
  }
}

export function* workerDeletePatientFlows() {
  try {
    const patientIds: PatientId[] = yield select(({
      marketing: { emailFlow },
    }) => getPatientListIds(emailFlow.patient));

    const { errors } = yield call(
      iclinic.marketing.deletePatientFlows,
      patientIds,
    );

    if (errors) throw errors;

    yield put(removePatientSuccess());
  } catch (error) {
    yield call(handlerErrors, error, removePatientFailure);
  }
}

export default function* patientSagas() {
  yield takeLatest(SAVE_PATIENT_FLOWS.ACTION, workerSavePatientFlows);
  yield takeLatest(FETCH_PATIENT_SUMMARY.ACTION, workerFetchPatientSummary);
  yield takeLatest(REMOVE_PATIENT.ACTION, workerDeletePatientFlows);
}
