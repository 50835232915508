import React from 'react';

import { MaterialIcons } from '@iclinic/design-system';
import { StepStatus } from './sellerStatus';

const { Check, Close } = MaterialIcons;

type StatusIconProps = {
  status: StepStatus | null;
  icon: React.ReactElement;
};

export default function StatusIcon({ status, icon }: StatusIconProps) {
  if (status === StepStatus.Success) return <Check />;
  if (status === StepStatus.Error) return <Close color="error" />;
  return icon;
}
