import React, { useEffect, useRef } from 'react';
import { FormikProvider, useFormik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { Paper } from 'features/patientsByEvents/styles';
import { Grid } from 'features/onlinePayment/components/lp-iclinic-pay/styles';
import Snackbar from 'features/tissInvoicing/components/ui/Snackbar';
import ContainerFooterButtons from '../../components/appointmentGuide/ContainerFooterButtons';
import AppointmentSection from './appointmentSection';
import { actions as domainTablesActions } from 'features/tissInvoicing/state/domainTables';
import { actions as autocompleteActions } from 'features/tissInvoicing/state/autocomplete';
import GeneralSection from './generalSection';
import HeaderSection from './headerSection';
import HiredSection from './hiredSection';
import PatientSection from './patientSection';
import { actions as apointmentGuideActions } from 'features/tissInvoicing/state/appointmentGuide';
import {
  FormKeysEnum,
  validationAppointmentGuideData,
} from './validationSchema';
import { getAutocomplete } from 'features/tissInvoicing/state/autocomplete/selectors';
import {
  getAppointmentGuideFormDataState,
  getSnackbarState,
  getUserData,
} from 'features/tissInvoicing/state/appointmentGuide/selectors';

interface MatchParams {
  id?: string;
}

const AppointmentGuide = ({ match }: RouteComponentProps<MatchParams>) => {
  const appointmentGuideId =
    match.params?.id !== 'adicionar' ? match.params.id : '';

  const dispatch = useDispatch();
  const sectionRef = useRef<Array<HTMLButtonElement | null>>([]);
  const { guide_versions } = useSelector(getAutocomplete);
  const snackbar = useSelector(getSnackbarState);
  const appointmentGuideFormData = useSelector(
    getAppointmentGuideFormDataState,
  );
  const { physicianId } = useSelector(getUserData);

  useEffect(() => {
    if (!appointmentGuideFormData?.version_id)
      dispatch(
        apointmentGuideActions.setVersion(
          guide_versions[guide_versions.length - 1]?.version ?? '',
        ),
      );
  }, [dispatch, guide_versions, appointmentGuideFormData?.version_id]);

  useEffect(() => {
    if (appointmentGuideId) {
      dispatch(
        apointmentGuideActions.fetchAppointmentGuideData({
          appointmentId: appointmentGuideId,
        }),
      );
    }
  }, [appointmentGuideId, dispatch]);

  useEffect(() => {
    dispatch(domainTablesActions.fetchDomainTables());
    dispatch(autocompleteActions.fetchGuideVersionsOptions());
    dispatch(autocompleteActions.fetchAnsRegistersOptions());
    dispatch(autocompleteActions.fetchPatientsOptions({}));
    dispatch(autocompleteActions.fetchCboOptions());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: appointmentGuideFormData,
    onSubmit: (data) => {
      const { print, ...rest } = data;

      if (appointmentGuideFormData?.id) {
        if (print) {
          dispatch(
            apointmentGuideActions.printGuide({
              id: appointmentGuideFormData?.id,
            }),
          );
        } else {
          dispatch(
            apointmentGuideActions.patchGuideData({
              data: rest,
              id: appointmentGuideFormData?.id,
            }),
          );
        }

        return;
      }

      dispatch(
        apointmentGuideActions.saveGuideData({
          data: { ...rest, physician_id: physicianId },
        }),
      );
    },
    validationSchema: validationAppointmentGuideData(
      appointmentGuideFormData.version_id,
    ),
    enableReinitialize: true,
  });

  const setSectionRef = (
    element: HTMLButtonElement | null,
    formKey: number,
  ) => {
    if (element) sectionRef.current[formKey] = element;
  };

  const handleCloseSnackbar = () => {
    dispatch(apointmentGuideActions.setSnackbarContent({}));
  };

  return (
    <>
      <Snackbar data={snackbar} onClose={handleCloseSnackbar} />

      <FormikProvider value={formik}>
        <Form data-testid="form_component">
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <HeaderSection />
            </Grid>
          </Grid>

          <Paper elevation={1}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <GeneralSection
                  ref={(el) => {
                    setSectionRef(el, FormKeysEnum.generalSectionData);
                  }}
                />
              </Grid>
              <Grid item>
                <PatientSection
                  ref={(el) => {
                    setSectionRef(el, FormKeysEnum.patientSectionData);
                  }}
                />
              </Grid>
              <Grid item>
                <HiredSection
                  ref={(el) => {
                    setSectionRef(el, FormKeysEnum.hiredSectionData);
                  }}
                />
              </Grid>
              <Grid item>
                <AppointmentSection
                  ref={(el) => {
                    setSectionRef(el, FormKeysEnum.appointmentSectionData);
                  }}
                />
              </Grid>
            </Grid>
          </Paper>

          <Grid container direction="column" spacing={3}>
            <Grid item>
              <ContainerFooterButtons
                sectionRef={sectionRef}
                guideTypeValidator="appointment"
              />
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
};

export default AppointmentGuide;
