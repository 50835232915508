export enum BUTTON {
  Dark = 'dark',
  Success = 'success',
  Info = 'info',
  Light = 'light',
  Error = 'error',
  Danger = 'danger',
  Link = 'link',
  Active = 'active',
  Primary = 'primary',
  PrimaryActive = 'primary-active',
  Warning = 'warning',
  WarningActive = 'warning-active'
}

export enum TYPES {
  Submit = 'submit',
  Button = 'button'
}

export enum SIZE {
  Medium = 'medium',
  Small = 'small',
  Large = 'large',
  ActionButton = 'actionButton'
}

export enum COLOR {
  White = 'white',
  Grey = 'grey',
}
