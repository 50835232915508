import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  TextField,
  MaterialIcons,
  Chip,
} from '@iclinic/design-system';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import {
  HighlightsInfoListCard,
  HighlightsInfoListCardDenied,
  HighlightsInfoListCardItems,
  ListCardType,
  SearchDbMedications,
  FilterOptionsState,
} from '../../../types';
import { CardListContainer, ContainerChips } from './CardListContent.styles';
import {
  searchDbHighlightInfo,
  searchDbHighlightInfoLoading,
} from '../../../state/selectors';
import { actions } from '../../../state';
import { useDebounce } from 'shared/hooks';
import { getDeniedTitle } from '../../../utils';

const { SearchOutlined, Close } = MaterialIcons;

const MESSAGE_IDLE = 'As informações serão automaticamente salvas';
const MESSAGE_SAVED = 'As informações foram salvas com sucesso';

interface CardListContentProps {
  setUpSaveOnIdle: () => void;
  isSaved: boolean;
  inputRef: React.RefObject<HTMLInputElement>;
  highlightInfoSelected: HighlightsInfoListCard | HighlightsInfoListCardDenied;
}

const {
  searchDbHighlightsInfo,
  setHighlightsInfoItems,
  setHighlightsInfoDenied,
  clearSearchDb,
} = actions;

const DEBOUNCE_VALUE = 1000;

type AutocompleteChangeValue =
  | NonNullable<string | SearchDbMedications>
  | (string | SearchDbMedications)[]
  | null;

export const CardListContent = ({
  setUpSaveOnIdle,
  isSaved,
  inputRef,
  highlightInfoSelected,
}: CardListContentProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedMedicament, setSelectedMedicament] =
    useState<SearchDbMedications | null>(null);

  const deniedTitle = useMemo(
    () => getDeniedTitle(highlightInfoSelected),
    [highlightInfoSelected],
  );

  const dispatch = useDispatch();
  const debouncedValue = useDebounce(inputValue, DEBOUNCE_VALUE);

  const hasSearchText = useCallback(
    () => inputValue.length && debouncedValue.length > 2,
    [debouncedValue.length, inputValue.length],
  );

  const searchDb = useSelector(searchDbHighlightInfo);
  const isSearchDbLoading = useSelector(searchDbHighlightInfoLoading);

  const noOptionsText = hasSearchText()
    ? 'Sem opções'
    : 'Digite as três primeiras letras';

  const handleInputChange = (_event: React.ChangeEvent<{}>, value: string) => {
    setInputValue(value);
  };

  const handleChange = (
    _event: React.ChangeEvent<{}>,
    value: AutocompleteChangeValue,
  ) => {
    const medicationData = value as SearchDbMedications;
    setInputValue('');
    setSelectedMedicament(null);
    dispatch(clearSearchDb());

    setUpSaveOnIdle();
    if (medicationData.isDenied) {
      dispatch(setHighlightsInfoDenied(true));
    } else {
      const item = {
        ...medicationData,
        isActive: true,
        timeCreated: '',
        itemId: medicationData.id,
      };

      const payload = (highlightInfoSelected as HighlightsInfoListCardDenied)
        .isDenied
        ? [item]
        : [...(highlightInfoSelected as HighlightsInfoListCard).items, item];

      dispatch(setHighlightsInfoItems(payload));
    }
  };

  const handleDeleteMedication = (item?: HighlightsInfoListCardItems) => {
    setUpSaveOnIdle();
    if (item) {
      dispatch(
        setHighlightsInfoItems([
          ...(highlightInfoSelected as HighlightsInfoListCard).items.filter(
            (highlightInfoSelectedItem) =>
              highlightInfoSelectedItem.itemId !== item.itemId,
          ),
        ]),
      );
    } else {
      dispatch(setHighlightsInfoDenied(false));
    }
  };

  const renderLabelName = (option: SearchDbMedications) =>
    highlightInfoSelected.typeCard === ListCardType.DrugsInUse &&
    !option.isDenied
      ? `${option.title} (${option.subtitle})`
      : option.title;

  useEffect(() => {
    if (hasSearchText()) {
      dispatch(
        searchDbHighlightsInfo({
          q: debouncedValue,
          type_card: highlightInfoSelected.typeCard as ListCardType,
        }),
      );
    }
  }, [debouncedValue, dispatch, hasSearchText, highlightInfoSelected.typeCard]);

  const onFilterOptions = (
    options: SearchDbMedications[],
    state: FilterOptionsState<SearchDbMedications>,
  ) => {
    if (state.inputValue.match(/(pac|neg|n[ãa]o)/gi)) {
      options.unshift({
        id: v4(),
        title: deniedTitle,
        subtitle: '-',
        isDenied: true,
      });
    }
    return options;
  };

  return (
    <CardListContainer>
      <Autocomplete
        onInputChange={handleInputChange}
        inputValue={inputValue}
        value={selectedMedicament}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchOutlined />,
              placeholder: 'Busque por substância ou medicamento',
            }}
            hiddenLabel
            helperText={isSaved ? MESSAGE_SAVED : MESSAGE_IDLE}
            inputRef={inputRef}
          />
        )}
        filterOptions={onFilterOptions}
        onChange={handleChange}
        loading={isSearchDbLoading}
        renderOption={renderLabelName}
        options={searchDb}
        getOptionLabel={(option) => option.title}
        noOptionsText={noOptionsText}
        loadingText="Carregando..."
        data-testid="autocomplete-pills"
      />
      <ContainerChips>
        {(highlightInfoSelected as HighlightsInfoListCardDenied).isDenied ? (
          <Chip
            label={deniedTitle}
            variant="outlined"
            onDelete={() => {
              handleDeleteMedication();
            }}
            deleteIcon={<Close data-testid="delete-icon" />}
          />
        ) : (
          (highlightInfoSelected as HighlightsInfoListCard)?.items?.map(
            (item) => (
              <Chip
                key={item.itemId}
                label={item.title}
                variant="outlined"
                onDelete={() => {
                  handleDeleteMedication(item);
                }}
                deleteIcon={<Close data-testid="delete-icon" />}
              />
            ),
          )
        )}
      </ContainerChips>
    </CardListContainer>
  );
};
