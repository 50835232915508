import { axiomTokens, Heading, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing } = axiomTokens;

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[4],
});

export const ContainerTitle = styled(Heading)({
  color: color.typeface.feedback.info,
});

export const CardsWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: spacing[4],
});
