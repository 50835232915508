import { createSelector } from 'reselect';

import { RequestStatus } from 'shared/constants/State';
import { StoreState } from 'state/rootReducer';
import { SellerEmailsSettingsFormResume } from '../types';
import { getSeller } from '../register/selectors';
import {
  getInstallmentsStatus,
  getSellerBankAccount,
  getSellerBankAccountFetchError,
  getSellerBankAccountSaveStatus,
  isFetchingBankAccount,
  isFetchingBanks,
  isSavingBankAccount,
} from '../bankAccount/selectors';

export const getSellerEmailsSettingsErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined => onlinePayment.config.edit.emails?.error;

export const getSellerEmailsSettings = ({
  onlinePayment,
}: StoreState): SellerEmailsSettingsFormResume | undefined =>
  onlinePayment.config.edit.emails?.data;

export const getSellerEmailsSettingsFetchStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.config.edit.emails?.fetchStatus;

export const getSellerEmailsSettingsSaveStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.config.edit.emails?.saveStatus;

export const isFetchingEmailConfig = createSelector(
  getSellerEmailsSettingsFetchStatus,
  (status) => status === RequestStatus.Pending,
);

export const isSavingEmailConfig = createSelector(
  getSellerEmailsSettingsSaveStatus,
  (status) => status === RequestStatus.Pending,
);

export const isSavingSettings = createSelector(
  [isSavingEmailConfig, isSavingBankAccount],
  (savingEmail, savingBankAccount) => savingEmail || savingBankAccount,
);

export const isFetchingSettings = createSelector(
  [isFetchingEmailConfig, isFetchingBankAccount, isFetchingBanks],
  (fetchingEmail, fetchingBankAccount, fetchingBanks) =>
    fetchingEmail || fetchingBankAccount || fetchingBanks,
);

export const isSettingsSavedSuccessfully = createSelector(
  [
    getSellerEmailsSettingsSaveStatus,
    getSellerBankAccountSaveStatus,
    getInstallmentsStatus,
  ],
  (emailSaveStatus, bankAccountSaveStatus, installmentsStatus) =>
    emailSaveStatus === RequestStatus.Success &&
    (bankAccountSaveStatus === undefined ||
      bankAccountSaveStatus === RequestStatus.Success) &&
    installmentsStatus === RequestStatus.Success,
);

export const getSellerEmailsSettingsFetchError = createSelector(
  getSellerEmailsSettingsFetchStatus,
  getSellerEmailsSettingsErrorMessage,
  (status, message) => {
    return status === RequestStatus.Error ? message : null;
  },
);

export const getSellerEditSelector = createSelector(
  [
    isFetchingSettings,
    isSavingSettings,
    getSellerEmailsSettings,
    getSeller,
    getSellerBankAccount,
    isSettingsSavedSuccessfully,
    getSellerEmailsSettingsFetchError,
    getSellerBankAccountFetchError,
  ],
  (
    fetching,
    saving,
    sellerEmailsSettings,
    seller,
    sellerBankAccount,
    settingsSavedSuccessfully,
    emailFetchError,
    bankAccountFetchError,
  ) => ({
    fetching,
    saving,
    sellerEmailsSettings,
    seller,
    sellerBankAccount,
    settingsSavedSuccessfully,
    emailFetchError,
    bankAccountFetchError,
  }),
);

export const getSellerEmailsSettingsSaveError = createSelector(
  getSellerEmailsSettingsSaveStatus,
  getSellerEmailsSettingsErrorMessage,
  (status, message) => {
    return status === RequestStatus.Error ? message : null;
  },
);
