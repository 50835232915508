import React from 'react';
import { MaterialUICore, theme } from '@iclinic/design-system';

import {
  MessageContainer,
  Container,
  Heading,
  Body,
  ImageContainer,
} from './DataMigrationBanner.styles';
import images from 'shared/paths/images';

const { useMediaQuery } = MaterialUICore;

export const DataMigrationBanner = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Container>
      <MessageContainer>
        <Heading variant="sm">Aviso importante</Heading>
        <Body variant="xs">
          Nas próximas semanas, os dados indisponíveis, registrados entre 15 e
          18 de abril, vão voltar automaticamente no sistema. Caso você não
          queira a volta automática,{' '}
          <b>entre em contato hoje pelo chat dentro do sistema.</b>
        </Body>
      </MessageContainer>
      {!isMobile && (
        <ImageContainer
          src={images.common.dataMigrationBanner}
          alt="ilustrações do sistema"
        />
      )}
    </Container>
  );
};
