import React from 'react';
import Icon from '@material/react-material-icon';

import { AddedFlow, ReturnFlow } from 'state/marketing/emailFlow/patient/types';
import { Professional } from 'state/marketing/emailFlow/professional/types';
import CustomItem from './CustomItem';
import ReturnItem from './ReturnItem';
import { FlowType } from '../constants';

import style from './AddedFlowItems.scss';

interface Props {
  onRemove: (flow: AddedFlow) => void,
  flow: AddedFlow,
  professional: Professional,
  returnFlowItems: React.ReactNode,
  returnFlowIsOpen: boolean,
  setReturnFlowIsOpen: (isOpen: boolean) => void,
  selectedReturnFlow: ReturnFlow,
}

export default function AddedFlowItems({
  onRemove,
  flow,
  professional,
  returnFlowItems,
  returnFlowIsOpen,
  setReturnFlowIsOpen,
  selectedReturnFlow,
}: Props): JSX.Element {
  return (
    <li
      key={`${flow.id}-added-item`}
      id={flow.id}
    >
      <div className={style.addedFlowItem}>
        <span>{`${flow.name} - ${professional.name}`}</span>
        {flow.flowType === FlowType.custom
          ? <CustomItem schedule={flow.schedule} />
          : (
            <ReturnItem
              isOpen={returnFlowIsOpen}
              toggleOpen={setReturnFlowIsOpen}
              selectedReturnFlow={selectedReturnFlow}
            >
              {returnFlowItems}
            </ReturnItem>
          )}
      </div>
      <button type="button" className={style.removeFlow} onClick={() => onRemove(flow)}>
        <Icon icon="close" />
      </button>
    </li>
  );
}
