import { call, put, takeLatest } from 'redux-saga/effects';
import { Response } from '@types';

import { captureException } from 'shared/utils/handlerErrors';
import { getPatientsByEvents } from 'services/iclinic/dashboard';
import {
  fetchPatientsByEvents,
  fetchPatientsByEventsSuccess,
  fetchPatientsByEventsFailure,
} from '.';
import { PatientsByEventsRawData } from './types';

type FetchPatientsByEventsPayload = ReturnType<typeof fetchPatientsByEvents>;

export function* fetchPatientsByEventsWorker({
  payload: { clinic_id, physician, date_from, date_to },
}: FetchPatientsByEventsPayload) {
  try {
    const { data }: Response<PatientsByEventsRawData> = yield call(
      getPatientsByEvents,
      clinic_id,
      physician,
      date_from,
      date_to,
    );
    yield put(fetchPatientsByEventsSuccess(data));
  } catch (error) {
    captureException(error);
    yield put(fetchPatientsByEventsFailure());
  }
}

export default function* patientsByEventsSagas() {
  yield takeLatest(fetchPatientsByEvents, fetchPatientsByEventsWorker);
}
