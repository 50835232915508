// Internal
import appTypes from 'shared/utils/actions';
import { SUCCESS, FAILURE, OPEN, CLOSE } from 'shared/constants/actions';

const upgradeAddon = appTypes.defineAction('upgradeAddon');

export const INITIALIZE = upgradeAddon.defineAction('INITIALIZE');
export const RELOAD = upgradeAddon.defineAction('RELOAD');
export const DETAIL = upgradeAddon.defineAction('DETAIL', [OPEN, CLOSE]);
export const SET_STEP = upgradeAddon.defineAction('SET_STEP', [
  SUCCESS,
  FAILURE,
]);
export const FETCH_PHYSICIANS = upgradeAddon.defineAction('FETCH_PHYSICIANS', [
  SUCCESS,
  FAILURE,
]);
export const FETCH_CALCULATE_PRICE = upgradeAddon.defineAction(
  'FETCH_CALCULATE_PRICE',
  [SUCCESS, FAILURE],
);
export const FETCH_SUMMARY = upgradeAddon.defineAction('FETCH_SUMMARY', [
  SUCCESS,
  FAILURE,
]);
export const FETCH_CREATE = upgradeAddon.defineAction('FETCH_CREATE', [
  SUCCESS,
  FAILURE,
]);
export const SELECT_PHYSICIAN = upgradeAddon.defineAction('SELECT_PHYSICIAN', [
  SUCCESS,
  FAILURE,
]);
export const FETCH_ADDONS = upgradeAddon.defineAction('FETCH_ADDONS', [
  SUCCESS,
  FAILURE,
]);
export const SELECT_PRODUCT = upgradeAddon.defineAction('SELECT_PRODUCT', [
  SUCCESS,
  FAILURE,
]);
export const FETCH_NOTIFICATION = upgradeAddon.defineAction(
  'FETCH_NOTIFICATION',
  [SUCCESS, FAILURE],
);
export const FETCH_ADDONS_DETAIL = upgradeAddon.defineAction(
  'FETCH_ADDONS_DETAIL',
  [SUCCESS, FAILURE],
);
export const INSTRUCTION = upgradeAddon.defineAction('INSTRUCTION', [CLOSE]);
export const GO_TO_ICLINIC_ASSIST = upgradeAddon.defineAction(
  'GO_TO_ICLINIC_ASSIST',
);
export const JUST_OPEN_MODAL = upgradeAddon.defineAction('JUST_OPEN_MODAL');
