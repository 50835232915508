import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import Seller from './seller';

export default function Config({ match }: RouteComponentProps): JSX.Element {
  return (
    <Switch>
      <Route path={`${match.path}/configuracoes`} component={Seller} />
    </Switch>
  );
}
