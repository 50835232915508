// External
import React from 'react';

// Internal
import style from './decorator.scss';

type AutomatedTagProps = {
  offsetKey: string,
  children: React.ReactNode,
};

const AutomatedTag = ({
  offsetKey,
  children,
}: AutomatedTagProps): JSX.Element => (
  <span
    className={style.automatedTag}
    data-offset-key={offsetKey}
  >
    {children}
  </span>
);

export default AutomatedTag;
