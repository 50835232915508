// External
import {
  all,
  fork,
  put,
  take,
  takeLatest,
  call,
  select,
  retry,
} from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

// Internal
import {
  MEMED_MILLISECONDS_TO_GET_DRUGS,
  MEMED_MAX_TRIES_TO_GET_DRUGS,
} from 'shared/constants/services';
import iclinic from 'services/iclinic';
import { captureException } from 'shared/sentryClient';
import {
  loadSdk,
  getPrescription,
  getDrugsFromPrescription,
  setPrescription,
} from 'services/memed';
import {
  FETCH_SDK,
  SET_SDK_SETTINGS,
  PRESCRIPTION_CREATED,
  PRESCRIPTION_REUSE,
  PRESCRIPTION_FINISHED,
  PRESCRIPTION_ADD_ITEM,
} from './types';
import * as actions from './actions';
import * as channels from './channels';
import { buildMemedSetPatient } from './utils';
import {
  INITIALIZED as RECORD_INITIALIZED,
  ENDED as RECORD_ENDED,
  CHANGED_TAB as RECORD_CHANGED_TAB,
  AUTO_SAVE as RECORD_AUTO_SAVE,
} from '../types';

const getRecordsSettings = (state) => ({
  eventId: state.records.root.entity.today_event.id,
  patientId: state.records.root.patient.id,
  physicianId: state.records.root.physician.id,
  clinicId: state.records.root.physician.clinicId,
});

const memedModule = 'plataforma.prescricao';

export function* workerLoadSdk() {
  try {
    const { completed } = yield select((state) => state.records.memed);
    if (completed && global.MdHub) {
      const scriptsMemed = document.querySelectorAll(
        '[src*="plataforma.sinapse-prescricao"], [src*="plataforma.hub"]',
      );
      global.MdHub.server.unbindEvents();
      delete global.window.MdHub;
      scriptsMemed.forEach((script) => {
        script.remove();
      });
    }
    const { id, clinicId } = yield select(
      (state) => state.records.root.physician,
    );
    const token = yield call(iclinic.prescription.fetchSettings, id, clinicId);
    yield call(loadSdk, token);
    yield put(actions.fetchSdkSuccess());
  } catch (error) {
    captureException(error);
    yield put(actions.fetchSdkdFailure());
  }
}

export function* workerFeaturesToggle() {
  try {
    global.MdHub.command.send(memedModule, 'setFeatureToggle', {
      alwaysSendSMS: false,
      deletePatient: false,
      historyPrescription: true,
      optionsPrescription: false,
      removePatient: false,
      editPatient: false,
      copyMedicalRecords: false,
      buttonClose: false,
      autocompleteExams: false,
      autocompletePeripherals: false,
      removePrescription: true,
    });
    yield put(actions.setFeaturesSuccess());
  } catch (error) {
    captureException(error);
    yield put(actions.setFeaturesFailure());
  }
}

export function* workerPatient() {
  try {
    const patientOfStore = yield select((state) => state.records.root.patient);
    const patientDataFromMemed = buildMemedSetPatient(patientOfStore);

    global.MdHub.command.send(memedModule, 'setPaciente', patientDataFromMemed);
    yield put(actions.setPatientSuccess());
  } catch (error) {
    captureException(error);
    yield put(actions.setPatientFailure());
  }
}

export function* workerSetItemsInPrescription(drugs) {
  try {
    yield call(setPrescription, drugs);
    yield put(actions.prescriptionAddItemsSuccess());
  } catch (e) {
    yield put(actions.prescriptionAddItemsFailure());
    captureException(e);
  }
}

export function* workerRecoveringOpenedPrescription() {
  try {
    const { eventId, physicianId, patientId, clinicId } = yield select(
      getRecordsSettings,
    );

    const params = {
      event: {
        id: eventId,
      },
      clinic: {
        id: clinicId,
      },
      physician: {
        id: physicianId,
      },
      patient: {
        id: patientId,
      },
      integration: {
        id_isnull: true,
      },
    };

    const {
      data: { data: prescriptions },
    } = yield call(iclinic.prescription.fetchPrescriptions, params);
    const [lastPrescription] = prescriptions;
    if (!lastPrescription) {
      return;
    }
    const { drugs } = lastPrescription;
    yield call(workerSetItemsInPrescription, drugs);
  } catch (e) {
    captureException(e);
  }
}

export function* workerInitializeSdk() {
  try {
    yield all([
      call(workerFeaturesToggle),
      call(workerPatient),
      call(workerRecoveringOpenedPrescription),
    ]);
    global.MdHub.module.show(memedModule);
  } catch (e) {
    captureException(e);
  }
}

export function* workerSettings() {
  const onMemedInitialize = yield call(channels.settingsChannel);
  try {
    if (!global.MdHub) {
      yield take(onMemedInitialize);
    }
    yield call(workerInitializeSdk);
    yield put(actions.sdkSettingsSuccess());
  } catch (error) {
    captureException(error);
    yield put(actions.sdkSettingsFailure());
  }
}

export function* workerCreatedPrescription() {
  try {
    const { eventId, physicianId, patientId, clinicId } = yield select(
      getRecordsSettings,
    );
    const {
      data: { data: responseData },
    } = yield call(
      iclinic.prescription.createPrescription,
      eventId,
      physicianId,
      patientId,
      clinicId,
    );
    yield put(actions.prescriptionCreatedSuccess(responseData.id));
  } catch (error) {
    captureException(error);
    yield put(actions.prescriptionCreatedFailure());
  }
}

export function* watchAddedDrug() {
  const onDrugAdded = yield call(channels.addDrugFromPrescriptionChannel);
  let prescriptionMemedUuid;

  while (true) {
    try {
      yield take(onDrugAdded);
      const { id: prescriptionId } = yield select((state) => ({
        id: state.records.memed.prescription.id,
      }));

      const {
        data: {
          attributes: { prescriptionUuid: memedUuid },
        },
      } = yield call(getPrescription);

      if (
        !prescriptionId ||
        (prescriptionMemedUuid && prescriptionMemedUuid !== memedUuid)
      ) {
        yield call(workerCreatedPrescription);
        prescriptionMemedUuid = memedUuid;
      }
      yield put(actions.prescriptionAddItemSuccess());
    } catch (error) {
      captureException(error);
      yield put(actions.prescriptionAddItemFailure());
      onDrugAdded.close();
    }
  }
}

export function* callUpdatePrescription() {
  try {
    const { id: prescriptionId, memedId: prescriptionMemedId } = yield select(
      (state) => state.records.memed.prescription,
    );
    const {
      data: {
        attributes: { medicamentos: allDrugs },
      },
    } = yield retry(
      MEMED_MAX_TRIES_TO_GET_DRUGS,
      MEMED_MILLISECONDS_TO_GET_DRUGS,
      getDrugsFromPrescription,
    );

    if (prescriptionId) {
      yield call(
        iclinic.prescription.updatePrescription,
        prescriptionId,
        allDrugs,
        prescriptionMemedId,
      );
      yield put(actions.updatePrescriptionSuccess());
    }
  } catch (error) {
    yield put(actions.updatePrescriptionFailure());
    captureException(error);
  }
}

export function* workerFinishPrescription() {
  const onPrescriptionFinished = yield call(channels.savePrescriptionChannel);

  while (true) {
    try {
      const prescriptionMemedId = yield take(onPrescriptionFinished);
      yield put(actions.prescriptionFinishedSuccess(prescriptionMemedId));
    } catch (error) {
      captureException(error);
      yield put(actions.prescriptionFinishedFailure());
      onPrescriptionFinished.close();
    }
  }
}

export function* watchReusePrescription({ payload: { drugs } }) {
  try {
    yield call(workerSetItemsInPrescription, drugs);
  } catch (e) {
    captureException(e);
  }
}

export function* workerClearPrescription() {
  try {
    yield put(actions.prescriptionClearSuccess());
  } catch (error) {
    captureException(error);
    yield put(actions.prescriptionClearFailure());
  }
}

export function* removePrescriptionWorker(prescriptionMemedId) {
  yield put(actions.prescriptionRemoved(prescriptionMemedId));

  try {
    yield call(iclinic.prescription.markAsRemoved, prescriptionMemedId);
    yield put(actions.prescriptionRemovedSuccess(prescriptionMemedId));
  } catch (error) {
    yield call(captureException, error);
    yield put(actions.prescriptionRemovedFailure(prescriptionMemedId));
  }
}

export function* removePrescriptionWatcher() {
  const channel = yield call(eventChannel, (emit) => {
    global.MdHub.event.add('prescricaoExcluida', (prescriptionMemedId) =>
      emit(prescriptionMemedId),
    );

    return () => global.MdHub.event.remove('prescricaoExcluida');
  });

  yield takeLatest(channel, removePrescriptionWorker);
}

export function* watchMemed() {
  while (true) {
    try {
      yield all([take(RECORD_INITIALIZED), take(FETCH_SDK.ACTION)]);
      yield fork(workerLoadSdk);
      yield takeLatest(FETCH_SDK.SUCCESS, workerSettings);
      yield take(SET_SDK_SETTINGS.SUCCESS);
      yield all([
        fork(watchAddedDrug),
        fork(workerFinishPrescription),
        fork(removePrescriptionWatcher),
      ]);
      yield all([
        take(PRESCRIPTION_CREATED.SUCCESS),
        take(PRESCRIPTION_ADD_ITEM.SUCCESS),
      ]);
      yield all([
        takeLatest(PRESCRIPTION_FINISHED.SUCCESS, callUpdatePrescription),
        takeLatest(RECORD_ENDED, workerClearPrescription),
        takeLatest(RECORD_CHANGED_TAB, callUpdatePrescription),
        takeLatest(RECORD_AUTO_SAVE, callUpdatePrescription),
      ]);
    } catch (error) {
      captureException(error);
    }
  }
}

export default function* recordSagas() {
  yield all([fork(watchMemed)]);
  yield takeLatest(PRESCRIPTION_REUSE.ACTION, watchReusePrescription);
}
