import React from 'react';

import { Typography } from '@iclinic/design-system';
import useStyles from './styles';

type Props = {
  label: React.ReactNode;
};
const TitleCard = ({ label }: Props) => {
  const classes = useStyles();
  return (
    <Typography variant="h5" className={classes.title}>
      {label}
    </Typography>
  );
};

export default TitleCard;
