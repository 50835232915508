// External
import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';

// Internal
import { EditEmailFlowReturnContainer } from 'features/marketing';

const EditEmailFlowReturn = ({ match }) => <EditEmailFlowReturnContainer match={match.params} />;

EditEmailFlowReturn.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default EditEmailFlowReturn;
