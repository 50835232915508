/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { ButtonProps } from '@iclinic/design-system';
import * as S from './ButtonStatus.styles';

interface ButtonStatusProps extends ButtonProps {
  icon?: React.ReactElement;
  disabled: boolean;
  redeemed: boolean;
}

const ButtonStatus = ({
  children,
  icon,
  disabled,
  redeemed,
  ...rest
}: ButtonStatusProps) => {
  return (
    <S.Button
      size="small"
      startIcon={icon}
      disabled={disabled}
      redeemed={redeemed}
      {...rest}
    >
      {children}
    </S.Button>
  );
};

export default ButtonStatus;
