import { combineReducers } from 'redux';

import downsellReason, { DownsellReasonState } from './downsell-reason';
import deleteMultiplePhysician, {
  DeleteMultiplePhysicianState,
} from './delete-multiple-physician';

export type SettingUsersState = {
  deleteMultiplePhysician: DeleteMultiplePhysicianState;
  downsellReason: DownsellReasonState;
};

const settingUsersReducer = combineReducers<SettingUsersState>({
  deleteMultiplePhysician,
  downsellReason,
});

export default settingUsersReducer;
