import * as React from 'react';

import { Body, SemiBold, Container } from './index.styles';

type InfoRowProps = {
  label?: string;
  value?: React.ReactNode;
};

const InfoRow = ({ label, value }: InfoRowProps) => (
    <Container>
      <Body variant="xxs">
        <SemiBold>{label ? `${label}: ` : ''}</SemiBold>
        {value}
      </Body>
    </Container>
  );

export default InfoRow;
