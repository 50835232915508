import React from 'react';
import { Logo, Box, Heading, tokens } from '@iclinic/design-system';

import ScheduleInformation from '../ScheduleInformation';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useWaitRoom } from 'features/video-conference/hooks/useWaitRoom';
import { useWindowHeight, isMobile } from 'features/video-conference/utils';
import styles from '../../styles';
import PhysicianIsWaitingAlert from '../PhysicianIsWaitingAlert';
import BrowserSuggestion from '../../Feedback/BrowserSuggestion';

const { spacing } = tokens;

type Props = {
  children: React.ReactNode;
  title: string;
  isWaitRoom?: boolean;
};

const DefaultContainer = ({
  children,
  title,
  isWaitRoom = false,
}: Props): JSX.Element => {
  const { isPhysician } = useConference();
  const { isReadyToJoin } = useWaitRoom({ isWaitRoom: false });
  const height = useWindowHeight();
  const isPatientWaitRoom = !isPhysician && isWaitRoom;
  const classes = styles({ height });

  const renderAlert = isWaitRoom && !isPhysician && isReadyToJoin && (
    <PhysicianIsWaitingAlert />
  );

  const renderHeader = (
    <Box display="flex" justifyContent="space-between" mb={spacing.sm}>
      <Logo width={110} />
      <Heading variant="overline" className={classes.neutral50}>
        {title}
      </Heading>
    </Box>
  );

  return (
    <Box display="flex" width="100%" justifyContent="center">
      {!isMobile && (
        <Box display="flex" flexDirection="column" mr="32px">
          {isPatientWaitRoom && !isPhysician && (
            <Box className={classes.waitRoomContainer} alignSelf="center">
              {renderHeader}
              <ScheduleInformation isWaitRoom />
            </Box>
          )}
          {isWaitRoom && <BrowserSuggestion />}
          {renderAlert}
        </Box>
      )}

      <Box className={classes.waitRoomContainer}>
        {!isPatientWaitRoom && (
          <>
            {renderHeader}
            {isMobile && <BrowserSuggestion />}
          </>
        )}
        {isPatientWaitRoom && isMobile && (
          <>
            {renderHeader}
            <ScheduleInformation isWaitRoom />
            <BrowserSuggestion />
            {renderAlert}
          </>
        )}
        {children}
      </Box>
    </Box>
  );
};

export default DefaultContainer;
