import React, { useState, useEffect, memo } from 'react';

import { MaterialUICore, Body } from '@iclinic/design-system';

const { TableCell } = MaterialUICore;

interface ColumnProps {
  name: string;
  align: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  size: 'small' | 'medium';
}

export const baseColumns: ColumnProps[] = [
  { name: 'Guia', align: 'left', size: 'medium' },
  { name: 'Data', align: 'left', size: 'medium' },
  { name: 'Paciente', align: 'left', size: 'medium' },
  { name: 'Profissional', align: 'left', size: 'medium' },
  { name: 'Qtde.', align: 'left', size: 'medium' },
  { name: 'Valor (R$)', align: 'left', size: 'medium' },
];

interface ColumnsProps {
  isLotClosed: boolean;
}

const Columns = ({ isLotClosed }: ColumnsProps) => {
  const [columns, setColumns] = useState<ColumnProps[]>(baseColumns);

  useEffect(() => {
    if (isLotClosed) {
      setColumns((state) => state.filter((column) => column.name !== 'Editar'));
      return;
    }

    setColumns((state) => {
      if (!state.some((column) => column.name === 'Editar')) {
        return [...state, { name: 'Editar', align: 'center', size: 'small' }];
      }

      return state;
    });
  }, [isLotClosed]);

  return (
    <>
      {columns.map((c) => (
        <TableCell align={c.align} key={c.name} size={c.size}>
          <Body variant="xs">{c.name}</Body>
        </TableCell>
      ))}
    </>
  );
};

export default memo(Columns);
