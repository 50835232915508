import React from 'react';
import { useSelector } from 'react-redux';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactRouterProps,
  Redirect,
} from 'react-router-dom';

import { getAvailableMenus } from '../state/common/selectors';
import { MenuSlug } from '../state/types';

interface PermissedRouteProps extends ReactRouterProps {
  permission: MenuSlug;
  component: React.ComponentType;
}

const PermissedRoute = ({
  permission,
  component: Component,
  ...rest
}: PermissedRouteProps) => {
  const availableMenus = useSelector(getAvailableMenus);

  const hasPermission = availableMenus?.includes(permission);

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        if (hasPermission) {
          return <Component />;
        }

        if (!hasPermission && availableMenus?.length === 0) {
          return <Redirect to="/404" />;
        }

        return <Redirect to="/relacionamento" />;
      }}
    />
  );
};

export default PermissedRoute;
