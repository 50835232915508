import { UPGRADE } from 'shared/constants/buttons';
import { PaymentInfo, UserInfo } from './types';

const getAdminMessage = (paymentInfo: PaymentInfo) => {
  if (paymentInfo && paymentInfo.paymentSlip) return UPGRADE.Slip;
  return UPGRADE.Contract;
};

export const getButtonMessage = (
  userInfo: UserInfo | null,
  paymentInfo: PaymentInfo,
) => {
  if (userInfo === null) return '';
  if (userInfo.isAdmin) return getAdminMessage(paymentInfo);
  return UPGRADE.NonAdmin;
};
