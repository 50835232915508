import { call, put, takeLatest } from 'redux-saga/effects';

import { sendAuditReportRequest } from 'services/iclinic/exportClinicData/exportHome/';
import { AuditEvent } from 'services/iclinic/exportClinicData/exportHome/types';
import {
  sendAuditReport,
  sendAuditReportSuccess,
  sendAuditReportFailure,
} from '.';

export function* sendAuditReportWorker(data: AuditEvent) {
  try {
    const { payload } = data;
    const { error } = yield call(sendAuditReportRequest, payload);

    if (error) throw error;

    yield put(sendAuditReportSuccess());
  } catch (error) {
    yield put(sendAuditReportFailure());
  }
}

export default function* exportHomeWorker() {
  yield takeLatest(sendAuditReport, sendAuditReportWorker);
}
