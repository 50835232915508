import { MaterialUICore, axiomTokens, Card } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const CardContainerSkeleton = styled(Card)(({ theme }) => ({
  width: '216px',
  height: '88px',
  backgroundColor: axiomTokens.color.others.skeleton,
  border: `${axiomTokens.border.width.xs} solid ${axiomTokens.color.border.subtle}`,
  borderRadius: axiomTokens.border.radius.sm,
  boxShadow: 'none',
  margin: `${axiomTokens.spacing[2]} ${axiomTokens.spacing[2]} ${axiomTokens.spacing[2]} 0`,

  [theme.breakpoints.down('md')]: {
    width: '224px',
    height: '96px',
  },
}));

export const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: `repeat(6, 1fr)`,
  overflowX: 'scroll',
  width: '100%',
});
