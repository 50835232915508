import React from 'react';
import { Renderer } from 'react-dom';
import { MaterialUICore, theme } from '@iclinic/design-system';
import { Store } from 'redux';
import { Provider } from 'react-redux';

const { ThemeProvider } = MaterialUICore;

export interface CreateRenderWithPropsProps {
  Element: React.ComponentType<{ propsFromApp: object }>;
  target: HTMLElement;
  boundary?: string;
  props?: object;
}

const createRenderWithProps =
  <RenderProps extends Renderer>(store: Store, render: RenderProps) =>
  (
    Element: CreateRenderWithPropsProps['Element'],
    target: CreateRenderWithPropsProps['target'],
    props: CreateRenderWithPropsProps['props'] = {},
  ) =>
    render(
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Element propsFromApp={{ ...props }} />
        </ThemeProvider>
      </Provider>,
      target,
    );

export default createRenderWithProps;
