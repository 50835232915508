import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const { spacing } = tokens;

export default makeStyles(() => ({
  title: {
    marginBottom: spacing.nano,
  },
  subTitle: {
    maxWidth: '550px',
  },
}));
