import React from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Typography, Box, MaterialUICore } from '@iclinic/design-system';
import validationSchema, { initialValues, InitialValues } from './schema';
import PasswordCreationFields from 'shared/components/PasswordCreationFields';
import BottomButtonContainer from '../BottomButtonContainer';
import { createPasswordIntegration } from 'features/settings/scheduleOnline/state/actions';
import { getSettingsIntegration } from 'features/settings/scheduleOnline/state/selectors';

const { FormControlLabel, Checkbox, FormHelperText } = MaterialUICore;
const TERMS_URL =
  'https://suporte.iclinic.com.br/termos-e-condicoes-de-uso-do-agendarconsulta';
const POLICY_URL =
  'https://suporte.iclinic.com.br/politica-de-privacidade-do-agendarconsulta';

const passwordValidation = (password: string) => {
  const upperCaseChar = /[A-Z]/;
  const numericChar = /[\d]/;

  return {
    hasMin8Chars: password.length >= 8,
    hasUpperChar: upperCaseChar.test(password),
    hasNumber: numericChar.test(password),
  };
};

export const PasswordCreation = () => {
  const dispatch = useDispatch();
  const integrationData = useSelector(getSettingsIntegration);
  const handleFormSubmit = (values: InitialValues) => {
    dispatch(
      createPasswordIntegration(
        integrationData.email,
        values.password,
        values.hasAcceptedTerms,
      ),
    );
  };

  const getPolicyLink = (url: string, text: string) => {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        {text}
      </a>
    );
  };

  return (
    <>
      <Typography data-testid="passwordCreationTitleId" variant="h4">
        Criação da conta
      </Typography>
      <Box mt={1}>
        <Typography variant="subtitle1">
          Defina sua senha para continuar a criação do seu perfil no Agendar
          Consulta.
        </Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ values, handleBlur, handleChange, errors, touched }) => {
          const passwordErrors = passwordValidation(values.password);
          const requirements = {
            eightChars: passwordErrors.hasMin8Chars,
            atLeastOneChar: passwordErrors.hasUpperChar,
            atLeastOneNumber: passwordErrors.hasNumber,
          };
          return (
            <Form>
              <Box mt={3} mb={3} width={328}>
                <PasswordCreationFields
                  passwordLabel="Senha"
                  passwordPlaceHolder="Digite sua senha"
                  requirements={requirements}
                  passwordConfirmation
                />
              </Box>

              <Box mb={3} paddingLeft={1}>
                <FormControlLabel
                  label={
                    <span>
                      Li e concordo com a{' '}
                      {getPolicyLink(POLICY_URL, 'Política de Privacidade')} e{' '}
                      {getPolicyLink(TERMS_URL, 'Termo de Uso')}
                    </span>
                  }
                  control={
                    <Checkbox
                      name="hasAcceptedTerms"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="primary"
                    />
                  }
                />
                {touched.hasAcceptedTerms && (
                  <FormHelperText error>
                    {errors.hasAcceptedTerms}
                  </FormHelperText>
                )}
              </Box>
              <BottomButtonContainer
                isLoading={integrationData.isLoading}
                leftLabel="Cancelar"
                rightLabel="Avançar"
                submit
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
