/* eslint-disable no-await-in-loop */
import loadScript from 'simple-load-script';

import { captureException } from 'shared/utils/handlerErrors';
import { MEMED_TARGET_ID, MEMED_COLOR } from 'shared/constants/services';

const memedPrescricao = 'plataforma.prescricao';

export const loadSdk = (token) =>
  new Promise((resolve, reject) => {
    loadScript({
      url: process.env.MEMED_API_SCRIPT_URL,
      attrs: {
        'data-color': MEMED_COLOR,
        'data-container': MEMED_TARGET_ID,
        'data-token': token,
      },
    })
      .then(resolve)
      .catch((error) => {
        captureException('Error loading Memed API script', error);
        reject(error);
      });
  });

export const getPrescriptionData = () =>
  global.MdHub.command.send(memedPrescricao, 'getPrescricao');

export const getPrescription = () =>
  new Promise((resolve, reject) => {
    try {
      getPrescriptionData().then((data) => resolve(data));
    } catch (e) {
      captureException(e);
      reject();
    }
  });

export const getDrugsFromPrescription = () =>
  new Promise((resolve, reject) => {
    try {
      getPrescriptionData().then((data) =>
        data.data.attributes.medicamentos ? resolve(data) : reject(),
      );
    } catch (e) {
      captureException(e);
      reject();
    }
  });

export const setPrescription = async (drugs) => {
  try {
    if (!drugs || drugs.lenght === 0) {
      return;
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const drug of drugs) {
      const {
        name,
        posology,
        quantity,
        integration: { id },
        drugKind,
      } = drug;
      if (drugKind !== 't') {
        await window.MdHub.command.send(memedPrescricao, 'addItem', {
          id,
          nome: name,
          posologia: posology,
          quantidade: quantity,
        });
      }  else {
        await window.MdHub.command.send(memedPrescricao, 'addItem', {
          nome: name,
          posologia: posology,
          quantidade: quantity,
        });
      }
    }
  } catch (e) {
    captureException(e);
  }
};
