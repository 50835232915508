// vendors styles
import '@iclinic/iclinic-ui/build/iclinic-ui.min.css';

import configureStore from 'state/configureStore';
import {
  sentryEnvironment,
  sentryDsn,
  sentryRelease,
} from './getSentryOptions';
import createMigrationModule from './migration/migration';
import { enableTrackOutOfTheBoxEvents } from 'shared/utils/trackEvents';
import { init, setTag } from 'shared/sentryClient';
import { initRumTracker } from 'shared/rumTracker';

enableTrackOutOfTheBoxEvents();

init(sentryEnvironment, sentryDsn, sentryRelease!);
setTag('origem_chamada', 'legado');

initRumTracker();

export const store = configureStore();
export const webapp = createMigrationModule(store);

window.webapp = webapp;
