import React, { useState } from 'react';
import {
  Body,
  Dialog,
  MaterialIcons as Icons,
  Heading,
  Button,
} from '@iclinic/design-system';

import * as S from './index.styles';
import { SIGNUP_PAYWALL_KEY } from 'features/new-auth/signup/constants';
import { gotoAppRoute } from 'shared/utils/appRoutes';
import { readLocalStorage } from 'shared/utils/readFromLocalStorage';

const { localStorage } = window;

const shouldPaywallShown = () => {
  const paywallData = readLocalStorage(SIGNUP_PAYWALL_KEY, {
    show_paywall: false,
  });

  if (paywallData.show_paywall) {
    paywallData.show_paywall = false;
    localStorage.setItem(
      SIGNUP_PAYWALL_KEY,
      JSON.stringify({ show_paywall: false }),
    );
    return true;
  }

  return false;
};

const OnboardingPaywall = () => {
  const [isOpen, setIsOpen] = useState(false);

  const isPaywallVisible = shouldPaywallShown();

  if (isPaywallVisible) {
    setIsOpen(true);

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'paywall_pageview',
        page_location: window.location.href,
      });
    }
  }

  const [selectedRecurrence, setSelectedRecurrence] = useState('');

  const handleSelectedPlan = (plan: string) => {
    setSelectedRecurrence(plan);
  };

  const handleStartTrial = () => {
    setIsOpen(false);
  };

  const handleStartCheckout = () => {
    setIsOpen(false);
    const checkoutPlan = {
      selected_plan: 'pro',
      recurrence: selectedRecurrence,
    };
    localStorage.setItem(SIGNUP_PAYWALL_KEY, JSON.stringify(checkoutPlan));
    gotoAppRoute('/configuracoes/assinatura/');
  };

  return (
    <Dialog open={isOpen}>
      <S.StyledDialogContent>
        <Heading variant="md">Conta gratuita criada com sucesso!</Heading>
        <S.BodyDescription variant="xs">
          Você acaba de dar um passo importante na evolução do seu consultório.
          <br />
          Assine agora e garanta os melhores recursos para a gestão do seu
          consultório, com um desconto especial.
        </S.BodyDescription>

        <Body variant="xxs">Conheça os planos sugeridos para você:</Body>

        <S.CardContainer>
          <S.Card onClick={() => handleSelectedPlan('annual')}>
            <S.RecommendedChip
              label="recomendado"
              severity="info"
              variant="default"
              size="small"
              icon={<Icons.StarBorder />}
            />
            <S.CardHeader>
              <Heading variant="sm"> Plano Pro - Anual </Heading>
              <S.SimpleRadio
                checked={selectedRecurrence === 'annual'}
                checkedIcon={<Icons.CheckCircle color="primary" />}
              />
            </S.CardHeader>

            <S.PriceContainer>
              <Heading variant="sm"> R$ </Heading>
              <Heading variant="lg"> 1749 </Heading>

              <S.Chip
                label="R$159 OFF"
                severity="success"
                variant="default"
                size="small"
              />
            </S.PriceContainer>

            <S.BodyContainer>
              <Body variant="xxs" bold>
                Por profissional de saúde
              </Body>
              <Body variant="xxs" style={{ marginTop: '4px' }}>
                À vista 1x por ano <br /> Renovação automática
              </Body>
            </S.BodyContainer>
          </S.Card>

          <S.Card onClick={() => handleSelectedPlan('monthly')}>
            <S.CardHeader>
              <Heading variant="sm"> Plano Pro - Mensal </Heading>
              <S.SimpleRadio
                checked={selectedRecurrence === 'monthly'}
                checkedIcon={<Icons.CheckCircle color="primary" />}
              />
            </S.CardHeader>

            <S.PriceContainer>
              <Heading variant="sm"> R$ </Heading>
              <Heading variant="lg"> 159 </Heading>
            </S.PriceContainer>

            <S.BodyContainer>
              <Body variant="xxs" bold>
                Por profissional de saúde
              </Body>
              <Body variant="xxs" style={{ marginTop: '4px' }}>
                Pagamentos mensais <br />
                Renovação automática
              </Body>
            </S.BodyContainer>
          </S.Card>
        </S.CardContainer>

        <S.Body variant="xxs">
          Gostaria de ajuda para conhecer o iClinic?
          <S.StyledLink
            target="_blank"
            href="https://api.whatsapp.com/send?phone=5511975902426&text=Ol%C3%A1,%20Vitor!%20Tenho%20algumas%20d%C3%BAvidas%20sobre%20o%20iClinic.%20Voc%C3%AA%20pode%20me%20ajudar"
            data-ga="paywall_talk_to_sales_click"
          >
            Converse com um especialista
          </S.StyledLink>
        </S.Body>
        <S.TrialOfferText variant="xxs">
          Você pode iniciar o
          <S.BoldSpan style={{ marginLeft: '4px', marginRight: '4px' }}>
            teste gratuito por 5 dias
          </S.BoldSpan>
          ou assinar agora com desconto.
        </S.TrialOfferText>

        <S.ButtonContainer>
          <Button
            variant="outlined"
            onClick={handleStartTrial}
            data-ga="paywall_start_trial_click"
          >
            Iniciar teste gratuito
          </Button>
          <S.SubscribeButton
            onClick={handleStartCheckout}
            data-ga="paywall_checkout_click"
          >
            Assinar agora
          </S.SubscribeButton>
        </S.ButtonContainer>
      </S.StyledDialogContent>
    </Dialog>
  );
};

export default OnboardingPaywall;
