import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextField, MaterialUICore } from '@iclinic/design-system';
import { TitleCard, SubTitle } from '..';
import formatMessage from '../../content';
import useStyles from './styles';
import { onChangeAntecedencePeriod } from 'features/settings/scheduleOnline/state/actions';
import { getSettingsAntecedencePeriod } from 'features/settings/scheduleOnline/state/selectors';

const { MenuItem } = MaterialUICore;

const antecedences = [
  { id: 12, text: '12 horas' },
  { id: 18, text: '18 horas' },
  { id: 24, text: '24 horas' },
  { id: 48, text: '48 horas' },
  { id: 72, text: '72 horas' },
];

export default function AntecedenceSection() {
  const dispatch = useDispatch();
  const antecedence = useSelector(getSettingsAntecedencePeriod) || 0;
  const classes = useStyles();

  const handleChange = (period: string) => {
    dispatch(onChangeAntecedencePeriod(Number(period)));
  };

  return (
    <>
      <TitleCard
        label={formatMessage({ id: '@online-scheduling:antecedence-label' })}
      />
      <SubTitle
        label={formatMessage({ id: '@online-scheduling:antecedence-text' })}
      />
      <TextField
        name="antecedence_period"
        select
        SelectProps={{
          native: false,
        }}
        label={formatMessage({ id: '@online-scheduling:antecedence-label' })}
        defaultValue="Escolha a antecedência"
        value={antecedence}
        onChange={(ev) => {
          handleChange(ev.target.value);
        }}
        className={classes.select}
      >
        <MenuItem key="antecedence-0" value="0">
          {formatMessage({ id: '@online-scheduling:antecedence-select-label' })}
        </MenuItem>
        {antecedences.map(({ id, text }) => (
          <MenuItem key={`antecedence-${id}`} value={`${id}`}>
            {text}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
}
