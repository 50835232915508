import { tokens, MaterialUICore } from '@iclinic/design-system';

import { CenterBox } from 'features/tissInvoicing/components/ui/Flexbox';

const { styled } = MaterialUICore;

export const LoaderContainer = styled(CenterBox)({
  flexDirection: 'column',
  padding: tokens.spacing.base,
  minHeight: '860px',
});
