import React, { memo } from 'react';

import { ColumnProps } from 'features/tissInvoicing/types/types.common';
import Columns from 'features/tissInvoicing/components/Columns';

export const columns: ColumnProps[] = [
  { name: 'Procedimento', align: 'left', size: 'small' },
  { name: 'Convênio', align: 'left', size: 'small' },
  { name: 'Quantidade', align: 'left', size: 'small' },
  { name: 'Valor Total (R$)', align: 'left', size: 'medium' },
  { name: 'Visualizar/Editar', align: 'center', size: 'small' },
  { name: 'Deletar', align: 'center', size: 'small' },
];

const ExpenseColumns = () => <Columns columns={columns} />;

export default memo(ExpenseColumns);
