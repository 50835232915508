import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import classnames from 'classnames/bind';

import styles from './Switch.scss';

const cx = classnames.bind(styles);

const Switch = ({
  checked,
  value,
  onChange,
  id,
  classes,
}) => (
  <div className={cn(styles['switch-container'], (classes['switch-custom']))}>
    <label
      className={cx(
        { 'switch-label-active': checked },
        { 'switch-label': !checked },
      )}
      htmlFor={id}
    >
      <input
        checked={checked}
        onChange={onChange}
        className={styles['switch-checkbox']}
        id={id}
        value={value}
        type="checkbox"
      />
      <span className={styles['switch-button']} />
    </label>
  </div>
);

Switch.defaultProps = {
  classes: {},
};

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    'switch-custom': PropTypes.string,
  }),
};

export default Switch;
