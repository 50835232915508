import { Select } from '@iclinic/design-system';
import React, { ChangeEvent, PropsWithChildren } from 'react';
import { useFormikContext } from 'formik';

import { AppointmentGuideFormValues } from 'features/tissInvoicing/types/appointmentGuide';

type Identifiers = keyof AppointmentGuideFormValues;
type EventTargetValue = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

interface SelectFieldProps {
  identifier: Identifiers;
  required?: boolean;
  disabled?: boolean;
  iconClass?: string;
  handleChangeInput?(event: string): void;
  label?: string;
}

function SelectField({
  identifier,
  required,
  disabled,
  children,
  label,
  iconClass,
  handleChangeInput,
  ...restOfProps
}: PropsWithChildren<SelectFieldProps>) {
  const { handleChange, values, errors, touched, handleBlur } =
    useFormikContext<AppointmentGuideFormValues>();

  const change = (event: EventTargetValue) => {
    if (handleChangeInput) {
      handleChangeInput(event.target.value);
    }

    handleChange(event);
  };

  return (
    <Select
      id={identifier}
      name={identifier}
      label={label}
      value={values[identifier]}
      onChange={change}
      error={!!errors[identifier]}
      helperText={touched[identifier] && errors[identifier]}
      onBlur={handleBlur}
      disabled={!!disabled}
      required={!!required}
      SelectProps={{
        classes: {
          icon: iconClass,
        },
      }}
      {...restOfProps}
    >
      {children}
    </Select>
  );
}

export default SelectField;
