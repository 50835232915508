import React, { useEffect, useState } from 'react';
import { Button, MaterialIcons, MaterialUICore } from '@iclinic/design-system';

import { TriggerProps } from '../../types';
import {
  dispatchGaClick,
  dispatchGaEvent,
  findEventWithComponent,
} from 'shared/utils/googleAnalytics';
import { TriggerBox } from './TriggerBox';
import { ICLINIC_APP_BREAKPOINT } from '../../constants';
import * as S from './Trigger.styles';
import { useAdditionalPlansTrigger } from '../../hooks/useAdditionalPlansTrigger';
import { markAsChecked } from '../../utils';

const { InfoOutlined } = MaterialIcons;
const { useMediaQuery } = MaterialUICore;

export function Trigger({ shouldNotRenderAt, modal = false }: TriggerProps) {
  const isDesktop = useMediaQuery(ICLINIC_APP_BREAKPOINT, { noSsr: true });
  const [opened, setOpened] = useState(true);
  const { alternative, isAvailable } = useAdditionalPlansTrigger({
    isDesktop,
    shouldNotRenderAt,
  });
  const scheduleParam = modal ? 'agenda_modal' : 'agenda';
  const trackingParam = alternative ? scheduleParam : 'dash';
  const componentParam = `fkd_test_mar_2023_whatsapp_${trackingParam}`;
  const eventName = 'component_view';

  const mainSchedule = !alternative && !shouldNotRenderAt;

  const isHidden = !isAvailable || mainSchedule;

  useEffect(() => {
    const isEventDispatched = findEventWithComponent({
      eventName,
      componentParam,
    });

    if (!isEventDispatched && !isHidden) {
      dispatchGaEvent(eventName, {
        component: componentParam,
      });
    }
  }, [componentParam, isHidden]);

  const onClose = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.stopPropagation();
    setOpened(false);
    markAsChecked();
  };

  const onClick = () => {
    dispatchGaClick({ eventName: `${componentParam}_click` });
    window.location.assign('/pacotes-adicionais');
  };

  if (isHidden || !opened) return null;

  return modal && isDesktop ? (
    <S.Alert
      severity="info"
      icon={<InfoOutlined />}
      title="Reduza em até 70% a taxa de faltas dos seus pacientes. "
      onClick={onClick}
      action={<Button variant="text">Veja como</Button>}
    />
  ) : (
    <TriggerBox alternative={alternative} onClick={onClick} onClose={onClose} />
  );
}
