import { MaterialUICore, axiomTokens } from '@iclinic/design-system';
import Markdown from 'markdown-to-jsx';

const { styled } = MaterialUICore;

export const ResumeAreaContainer = styled('div')(() => ({
  margin: `0 ${axiomTokens.spacing[4]}`,
  padding: axiomTokens.spacing[4],
  border: `${axiomTokens.border.width.xs} solid ${axiomTokens.color.border.subtle}`,
  borderRadius: axiomTokens.border.radius.sm,
}));

export const Description = styled('div')(() => ({
  fontFamily: axiomTokens.font.family.base,
  fontSize: axiomTokens.font.size.xs,
  fontWeight: axiomTokens.font.weight.medium,
  color: axiomTokens.color.typeface.pure,
  marginBottom: axiomTokens.spacing[4],
}));

export const TextContainer = styled('div')(() => ({
  width: '100%',
  borderTop: `${axiomTokens.border.width.xs} solid ${axiomTokens.color.border.subtle}`,
  marginTop: axiomTokens.spacing[2],
}));

export const Text = styled(Markdown)(() => ({
  width: '100%',
  fontFamily: axiomTokens.font.family.base,
  fontSize: axiomTokens.font.size.xs,
  color: axiomTokens.color.typeface.pure,
  minHeight: '288px',
  paddingTop: axiomTokens.spacing[2],

  '& li': {
    marginLeft: axiomTokens.spacing[5],
  },

  '& ul': {
    listStyleType: 'disc',
  },
}));
