import React from 'react';

import * as S from './Toggle.styles';

interface ToggleProps {
  leftLabel: string;
  rightLabel: string;
  value?: boolean;
  onChange?: (checked: boolean, activeLabel?: string) => void;
  minWidth?: number | string;
}

export default function Toggle({
  leftLabel,
  rightLabel,
  value,
  onChange,
  minWidth,
}: ToggleProps) {
  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;

    const { checked } = event.target;

    onChange(checked, checked ? rightLabel : leftLabel);
  };

  return (
    <S.Root minWidth={minWidth}>
      <S.Switch rightLabel={rightLabel}>
        <S.Input
          id="toggle"
          name="toggle"
          type="checkbox"
          onChange={changeHandler}
          checked={value}
        />

        <S.Label htmlFor="toggle">
          <S.Span>{leftLabel}</S.Span>
        </S.Label>
      </S.Switch>
    </S.Root>
  );
}
