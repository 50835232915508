import { Box, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = tokens;

export const SelectedPlanWrapper = styled(Box)({
  maxWidth: 912,
  margin: '0 auto',
});

export const PlanWrapper = styled('ul')({
  listStyle: 'none',
  pointerEvents: 'none',
  flex: 1,
  minWidth: 283,
});

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: spacing.sm,
  gap: spacing.sm,
  flexDirection: 'column',

  [theme.breakpoints.up('md')]: {
    marginTop: spacing.lg,
    flexDirection: 'row',
    gap: spacing.xxxl,
  },
}));

export const Description = styled(Box)({
  margin: '0 auto',
});
