import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Box,
  Button,
  Chip,
  ChipSeverity,
  MaterialIcons,
  Tooltip,
} from '@iclinic/design-system';
import { BillStatus } from 'features/onlinePayment/state/constants';
import SuccessSnackbar from './SuccessSnackbar';
import useStyle from './Status.styles';

const { Check, Link: LinkIcon } = MaterialIcons;

type StatusProps = {
  billId: string;
  status?: BillStatus | null;
};

const statusData = {
  [BillStatus.Created]: {
    chipIcon: undefined,
    label: 'Aguardando pagamento',
    severity: 'default' as ChipSeverity,
  },
  [BillStatus.Paid]: {
    chipIcon: <Check />,
    label: 'Pagamento confirmado',
    severity: 'success' as ChipSeverity,
  },
  [BillStatus.Canceled]: {
    chipIcon: undefined,
    label: 'Pagamento cancelado',
    severity: 'error' as ChipSeverity,
  },
};

export default function Status({
  billId,
  status,
}: StatusProps): JSX.Element | null {
  const classes = useStyle();
  const [copied, setCopied] = useState(false);

  if (!status) return null;

  return (
    <Box className={classes.status}>
      <Chip
        size="small"
        icon={statusData[status].chipIcon}
        label={statusData[status].label}
        severity={statusData[status].severity}
      />
      <Tooltip title={billId}>
        <CopyToClipboard text={billId} onCopy={() => setCopied(true)}>
          <Button
            color="transparent"
            size="small"
            startIcon={<LinkIcon />}
            className={classes.copyOrder}
          >
            Copiar Nº do pedido
          </Button>
        </CopyToClipboard>
      </Tooltip>
      {copied && (
        <SuccessSnackbar
          message="Nº do pedido copiado"
          onClose={() => setCopied(false)}
        />
      )}
    </Box>
  );
}
