import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Heading,
  Body,
  Dialog,
  DialogHeader,
  DialogContent,
  DialogActions,
} from '@iclinic/design-system';

import {
  getPaymentSlipData,
  getPaymentSummaryData,
  isRequestingReleaseTrust,
} from '../../../state/selectors';
import { formatDate } from 'shared/utils/date';
import { requestPaymentSlipReleaseTrust } from 'features/paymentSlip/state';
import { pluralize } from 'shared/utils/strings';

interface ModalConfirmRequestProps {
  open: boolean;
  onClose: () => void;
}

export const ModalConfirmRequest = ({
  open,
  onClose,
}: ModalConfirmRequestProps) => {
  const dispatch = useDispatch();
  const { link, expirationDate } = useSelector(getPaymentSlipData);
  const { planName, totalUsers } = useSelector(getPaymentSummaryData);
  const isRequestingRelease = useSelector(isRequestingReleaseTrust);

  const onClickGenerateSecondCopyHandler = () => {
    if (!link) return;

    window.open(link, '__blank');
  };

  const onCloseHandler = () => {
    if (isRequestingRelease) return;

    onClose();
  };

  const onReleaseTrustClickHandler = () => {
    dispatch(requestPaymentSlipReleaseTrust());
  };

  return (
    <Dialog maxWidth="sm" open={open} onClose={onCloseHandler}>
      <DialogHeader title="Solicitação de liberação" onClose={onCloseHandler} />

      <DialogContent>
        <Body variant="xs">
          Confirme abaixo e volte a usar a iClinic normalmente caso já tenha
          efetuado o pagamento.
        </Body>

        <Body variant="xs">
          Se o pagamento da fatura não for identificado em até 3 dias úteis, o
          serviço volta a ficar bloqueado e o desbloqueio só será feito após a
          identicação do pagamento.
        </Body>

        <div>
          <Heading variant="overline">Fatura pendente</Heading>

          <div>
            <Body variant="xs">
              Plano: <strong>{planName}</strong>
            </Body>

            <Body variant="xs">
              Vencimento:{' '}
              <strong>
                {expirationDate ? formatDate(expirationDate) : '-'}
              </strong>
            </Body>

            <Body variant="xs">
              Usuários:{' '}
              <strong>
                {totalUsers}{' '}
                {pluralize(totalUsers, 'profissional', 'profissionais')} de
                saúde
              </strong>
            </Body>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={isRequestingRelease}
          color="transparent"
          onClick={onClickGenerateSecondCopyHandler}
        >
          Gerar 2º via do boleto
        </Button>

        <Button
          isLoading={isRequestingRelease}
          onClick={onReleaseTrustClickHandler}
        >
          Já efetuei o pagamento
        </Button>
      </DialogActions>
    </Dialog>
  );
};
