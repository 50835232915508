import { ApiErrors } from '@types';
import {
  FileSignatureResponseCompletedState,
  Stepper,
  ProvidersPSC,
  UserDocument,
} from './types';

import * as types from './constants';

export const toggleDrawer = (isOpenDrawer: boolean) =>
  ({
    type: types.TOGGLE_DRAWER,
    payload: {
      isOpenDrawer,
    },
  } as const);

export const finalizeMedicalRecord = (sign: boolean, encodedCert: string) =>
  ({
    type: types.RECORDS_FINISHED,
    payload: {
      isLoading: true,
      encodedCert,
      sign,
    },
  } as const);

export const finalizeMedicalRecordFailure = (errors: ApiErrors[]) =>
  ({
    type: types.RECORDS_FINISHED_FAILURE,
    payload: {
      isLoading: false,
      errors,
    },
  } as const);

export const clearSignPDF = () =>
  ({
    type: types.CLEAR_SIGNED_PDF,
  } as const);

export const clearSignPDFErrors = () =>
  ({
    type: types.CLEAR_SIGNED_PDF_ERRORS,
  } as const);

export const finalizeMedicalRecordSuccess = () =>
  ({
    type: types.RECORDS_FINISHED_SUCCESS,
    payload: {
      isOpenDrawer: false,
      isLoading: false,
      stepper: Stepper.Initial,
    },
  } as const);

export const readSignature = (encodedCert: string) =>
  ({
    type: types.READ_SIGNATURE,
    payload: {
      encodedCert,
    },
  } as const);

export const fetchSignatureFailure = (errors: ApiErrors[]) =>
  ({
    type: types.FETCH_SIGNATURE_FAILURE,
    payload: {
      errors,
      isLoading: false,
    },
  } as const);

export const fetchSignatureSuccess = (
  signedPdf: FileSignatureResponseCompletedState[],
) =>
  ({
    type: types.FETCH_SIGNATURE_SUCCESS,
    payload: {
      signedPdf,
      stepper: Stepper.Preview,
    },
  } as const);

export const getThumbprint = (thumbprint: string) =>
  ({
    type: types.GET_SIGNATURE_THUMBPRINT,
    payload: {
      thumbprint,
    },
  } as const);

export const signatureChangeStep = (stepper: string) =>
  ({
    type: types.CHANGE_SIGNATURE_STEPPER,
    payload: {
      stepper,
    },
  } as const);

export const getListPSC = (user_document: UserDocument, redirect_uri: string) =>
  ({
    type: types.GET_LIST_PSC,
    payload: {
      user_document,
      redirect_uri,
    },
  } as const);

export const getListPSCSuccess = (providers: ProvidersPSC[]) =>
  ({
    type: types.GET_LIST_PSC_SUCCESS,
    payload: {
      providers,
    },
  } as const);

export const getListPSCFailure = (errors: ApiErrors[]) =>
  ({
    type: types.GET_LIST_PSC_FAILURE,
    payload: {
      errors,
      isLoading: false,
    },
  } as const);

export const getSignatureAccessToken = (auth_code: string, state: string) =>
  ({
    type: types.GET_ACCESS_TOKEN,
    payload: {
      auth_code,
      state,
    },
  } as const);

export const getSignatureAccessTokenSuccess = (accessToken: string) =>
  ({
    type: types.GET_ACCESS_TOKEN_SUCCESS,
    payload: {
      accessToken,
    },
  } as const);

export const getSignatureAccessTokenFailure = (errors: ApiErrors[]) =>
  ({
    type: types.GET_ACCESS_TOKEN_FAILURE,
    payload: {
      errors,
      isLoading: false,
    },
  } as const);

export const setProvider = (
  providersUrlSelected: string,
  providerName: string,
) =>
  ({
    type: types.SET_PROVIDER,
    payload: {
      providersUrlSelected,
      providerName,
    },
  } as const);

export const setLifeTime = (lifeTime: number) =>
  ({
    type: types.SET_LIFETIME,
    payload: {
      lifeTime,
    },
  } as const);

export const fetchSignatureCloud = () =>
  ({
    type: types.FETCH_SIGNATURE_CLOUD,
    payload: {
      stepper: Stepper.Loading,
    },
  } as const);
