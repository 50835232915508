// Internal
import { SUCCESS, FAILURE } from 'shared/constants/actions';
import appTypes from 'shared/utils/actions';

const campaign = appTypes.defineAction('campaign');

export const FETCH_LIST = campaign.defineAction('FETCH_LIST', [SUCCESS, FAILURE]);
export const FETCH_DETAILS = campaign.defineAction('FETCH_DETAILS', [SUCCESS, FAILURE]);
export const FETCH_CREATE = campaign.defineAction('FETCH_CREATE', [SUCCESS, FAILURE]);
export const FETCH_UPDATE = campaign.defineAction('FETCH_UPDATE', [SUCCESS, FAILURE]);
export const FETCH_DELETE = campaign.defineAction('FETCH_DELETE', [SUCCESS, FAILURE]);
export const FETCH_USER_PROFILES = campaign.defineAction('FETCH_USER_PROFILES', [SUCCESS, FAILURE]);
export const FETCH_PATIENT_COUNT = campaign.defineAction('FETCH_PATIENT_COUNT', [SUCCESS, FAILURE]);
export const DUPLICATE_CAMPAIGN = campaign.defineAction('DUPLICATE_CAMPAIGN');
export const SET_SELECTED = campaign.defineAction('SET_SELECTED');
export const ADD_CAMPAIGN = campaign.defineAction('ADD_CAMPAIGN');
export const DELETE_CAMPAIGN = campaign.defineAction('DELETE_CAMPAIGN');
export const DELETE_SELECTED_CAMPAIGN = campaign.defineAction('DELETE_SELECTED_CAMPAIGN');
export const EDIT_CAMPAIGN = campaign.defineAction('EDIT_CAMPAIGN',
  [
    'SAVE',
    'CANCEL',
    'SET_ACTIVE_CARD',
    'SAVE_AUDIENCE',
    'SAVE_MAIL',
    'FINALIZE',
    'SEND_TEST_EMAIL',
    'SCHEDULE_EMAIL',
    'SEND_EMAIL_NOW',
  ]);
export const EDITOR_TEXT = campaign.defineAction('EDITOR_TEXT',
  [
    'FORMAT_TEXT',
    'UPDATE_EDITOR_STATE',
    'MODAL_LINK_SUBMIT',
    'MODAL_BUTTON_SUBMIT',
    'MODAL_IMAGE_SUBMIT',
    'AUTOMATED_TAG',
  ]);
export const FETCH_PROCEDURES = campaign.defineAction('FETCH_PROCEDURES', [SUCCESS, FAILURE]);
export const FETCH_PATIENT_TAGS = campaign.defineAction('FETCH_PATIENT_TAGS', [SUCCESS, FAILURE]);
export const FETCH_SEND_TEST_EMAIL = campaign.defineAction('FETCH_SEND_TEST_EMAIL', [SUCCESS, FAILURE]);
export const CLEAR_ERRORS_UPDATE_CAMPAIGN = campaign.defineAction('CLEAR_ERRORS_UPDATE_CAMPAIGN');
export const CLEAR_ERRORS_CAMPAIGN = campaign.defineAction('CLEAR_ERRORS_CAMPAIGN');
export const CREATE_CAMPAIGN = campaign.defineAction('CREATE_CAMPAIGN');
export const FETCH_DETAILS_CLEAR = campaign.defineAction('FETCH_DETAILS_CLEAR');
