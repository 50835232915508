import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles((theme) => ({
  drawer: {
    '& .MuiDrawer-paper': {
      justifyContent: 'space-between',
    },
  },
  drawerContent: {
    width: '420px',
  },
  backButton: {
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
