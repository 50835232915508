import { useEffect, useState } from 'react';
import { Participant, TrackPublication } from 'twilio-video';

import { useConference } from 'features/video-conference/state/conferenceContext';

export default function useScreenShareParticipant() {
  const { room } = useConference();
  const [screenShareParticipant, setScreenShareParticipant] = useState<
    Participant
  >();

  useEffect(() => {
    if (room) {
      const updateScreenShareParticipant = () => {
        setScreenShareParticipant(
          Array.from<Participant>(room.participants.values())
            .concat(room.localParticipant)
            .find((participant: Participant) =>
              Array.from<TrackPublication>(
                participant.tracks.values(),
              ).find((track) => track.trackName.includes('screen')),
            ),
        );
      };

      updateScreenShareParticipant();

      room.on('trackPublished', updateScreenShareParticipant);
      room.on('trackUnpublished', updateScreenShareParticipant);
      room.on('participantDisconnected', updateScreenShareParticipant);

      room.localParticipant.on('trackPublished', updateScreenShareParticipant);
      room.localParticipant.on(
        'trackUnpublished',
        updateScreenShareParticipant,
      );
      return () => {
        room.off('trackPublished', updateScreenShareParticipant);
        room.off('trackUnpublished', updateScreenShareParticipant);
        room.off('participantDisconnected', updateScreenShareParticipant);

        room.localParticipant.off(
          'trackPublished',
          updateScreenShareParticipant,
        );
        room.localParticipant.off(
          'trackUnpublished',
          updateScreenShareParticipant,
        );
      };
    }

    return () => null;
  }, [room]);

  return screenShareParticipant;
}
