import { Chip as DSChip, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing } = tokens;

export const Chip = styled(DSChip)(({ theme }) => ({
  marginTop: spacing.base,

  [theme.breakpoints.up('sm')]: {
    marginTop: spacing.xs,
  },
}));
