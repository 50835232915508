import React, { useState } from 'react';

import {
  CustomCheckBox as ReceivedPaymentCheckBox,
  DetailsHeader as PaymentDetailsHeader,
  DetailsItem as PaymentDetailsItem,
} from '../commonStyles';

type PaymentDetailsTableProps = {
  dueDate: string;
  value: string;
  dateOfReceipt?: string;
};

function PaymentDetailsTable({
  dueDate,
  value,
  dateOfReceipt,
}: PaymentDetailsTableProps) {
  const [isChecked, setIsChecked] = useState<boolean>(!!dateOfReceipt || false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <>
      <PaymentDetailsHeader>
        <span>Vencimento</span>
        <span>Valor</span>
        <span>Recebido</span>
        <span>Data recebimento</span>
      </PaymentDetailsHeader>
      <PaymentDetailsItem>
        <span>{dueDate}</span>
        <span>{value}</span>
        <span>
          <ReceivedPaymentCheckBox
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </span>
        <span>{isChecked ? dateOfReceipt : 'Não faturado'}</span>
      </PaymentDetailsItem>
    </>
  );
}

export default PaymentDetailsTable;
