import React from 'react';
import { axiomTokens } from '@iclinic/design-system';

const SkeletonSvg = () => (
  <svg
    width="100%"
    height="40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMin meet"
  >
    <rect width="100%" height="16" rx="4" fill="url(#gradient)" />
    <rect y="24" width="100%" height="16" rx="4" fill="url(#gradient)" />
    <defs>
      <linearGradient id="gradient">
        <stop
          offset="0.599964"
          stopColor={axiomTokens.color.others.skeleton}
          stopOpacity="1"
        >
          <animate
            attributeName="offset"
            values="-2; -2; 1"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop
          offset="1.59996"
          stopColor={axiomTokens.color.others.skeleton}
          stopOpacity="0.8"
        >
          <animate
            attributeName="offset"
            values="-1; -1; 2"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
        <stop
          offset="2.59996"
          stopColor={axiomTokens.color.others.skeleton}
          stopOpacity="1"
        >
          <animate
            attributeName="offset"
            values="0; 0; 3"
            keyTimes="0; 0.25; 1"
            dur="2s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  </svg>
);

export default SkeletonSvg;
