// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import style from './Column.scss';

export default function Column({ children }) {
  return <div className={style.column}>{children}</div>;
}

Column.propTypes = {
  children: PropTypes.node.isRequired,
};
