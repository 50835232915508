import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, spacing } = tokens;

export const Root = styled('div')(({ theme }) => ({
  margin: `${spacing.sm} ${spacing.sm} ${spacing.xl}`,
  background: color.neutral[0],
  border: `1px solid ${color.neutral[100]}`,
  borderRadius: `${border.radius.sm} ${border.radius.sm} 0px 0px`,

  [theme.breakpoints.up('sm')]: {
    margin: `${spacing.md} ${spacing.lg} ${spacing.xl}`,
  },
}));

export const HeaderWrapper = styled('div')({
  borderBottom: `1px solid ${color.neutral[100]}`,
  padding: `${spacing.sm} ${spacing.lg}`,
});

export const SectionTerms = styled('section')(({ theme }) => ({
  padding: `${spacing.md} ${spacing.sm} ${spacing.lg}`,

  '& a': {
    textDecoration: 'underline',
    fontWeight: 'bold',
    color: 'inherit',
  },

  [theme.breakpoints.up('sm')]: {
    padding: `${spacing.xl} ${spacing.md}`,
  },
}));

export const LoaderAwardsWrapper = styled('div')({
  padding: `${spacing.xl} ${spacing.sm}`,
  display: 'flex',
  justifyContent: 'center',
});
