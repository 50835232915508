// External
import axios from 'axios';
import qs from 'qs';

// Internal
import { StockKind, PAGE_SIZE } from 'features/stock/constants';
import {
  interceptorResponseTransformData,
  interceptorResponseErrors,
} from 'services/iclinic/interceptors';
import { InventoryCreate, ProductCreate } from 'features/stock/state/types';
import interceptorRequestAuthenticationStock from './interceptors';

// api stock
export const apiStock = axios.create({
  baseURL: process.env.ICLINIC_STOCK_URL,
});

apiStock.interceptors.request.use(interceptorRequestAuthenticationStock);
apiStock.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrors,
);

// api stock serverless
export const apiStockServerless = axios.create({
  baseURL: process.env.ICLINIC_STOCK_URL_SERVERLESS,
});

apiStockServerless.interceptors.request.use(
  interceptorRequestAuthenticationStock,
);
apiStockServerless.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrors,
);

export const createProduct = ({
  description,
  clinicId,
  profileId,
  minimum,
  observation,
  code,
  unitId,
  hasLotControl,
}: ProductCreate) => {
  const bodyRequest = {
    description,
    clinic: {
      id: clinicId,
    },
    profile: {
      id: profileId,
    },
    minimum,
    observation,
    code,
    unit: {
      id: unitId,
    },
    hasLotControl,
  };

  return apiStock.post('/products/', bodyRequest);
};

export const fetchProducts = (productID: number | null = null) => {
  if (!productID) {
    return apiStock.get('/products/');
  }

  return apiStock.get(`/products/${productID}`);
};

export const updateProduct = ({
  id,
  description,
  clinicId,
  profileId,
  minimum,
  observation,
  code,
  unitId,
  hasLotControl,
}) => {
  const bodyRequest = {
    description,
    clinic: {
      id: clinicId,
    },
    profile: {
      id: profileId,
    },
    minimum,
    observation,
    code,
    unit: {
      id: unitId,
    },
    hasLotControl,
  };

  return apiStock.patch(`/products/${id}/`, bodyRequest);
};

export const deleteProduct = (productId) =>
  apiStock.delete(`/products/${productId}/`);

export const createLot = ({ clinicId, profileId, description }) => {
  const bodyRequest = {
    description,
    clinic: {
      id: clinicId,
    },
    profile: {
      id: profileId,
    },
  };

  return apiStock.post('/lots/', bodyRequest);
};

type CreateInventoryUpdate = {
  clinicId: number;
  profileId: number;
} & InventoryCreate;

export const createInventory = ({
  clinicId,
  profileId,
  productId,
  lotId,
  quantity,
  kind,
  expirationDate,
  observation,
}: CreateInventoryUpdate) => {
  const bodyRequest = {
    clinic: {
      id: clinicId,
    },
    profile: {
      id: profileId,
    },
    product: {
      id: productId,
    },
    lot: {
      id: lotId || null,
    },
    expiration_date: expirationDate || null,
    quantity:
      kind === StockKind.Output ? -Math.abs(quantity) : Math.abs(quantity),
    kind,
    observation,
  };

  return apiStock.post('/inventories/', bodyRequest);
};

export const updateInventorie = ({
  clinicId,
  profileId,
  productId,
  quantity,
  kind,
}) => {
  const bodyRequest = {
    clinic: {
      id: clinicId,
    },
    profile: {
      id: profileId,
    },
    product: {
      id: productId,
    },
    quantity,
    kind,
  };

  return apiStock.patch(`/inventories/${productId}`, bodyRequest);
};

export const fetchUnit = () => apiStock.get('/units/?page_size=70');

export const fetchStock = (url: string) => apiStock.get(url);

export const fetchExpecificURL = (url: string) => apiStock.get(url);
export const fetchExpecificURLServerless = (url: string) =>
  apiStockServerless.get(url);

export const deleteInventorie = (productId: number) =>
  apiStock.delete(`/inventories/${productId}`);

export const fetchProductsByName = (params) => {
  const queryStringParams = qs.stringify(params, {
    allowDots: true,
    skipNulls: true,
  });
  return apiStock.get(`/search/product/?${queryStringParams}`);
};

export const fetchUserByName = (params) => {
  const queryStringParams = qs.stringify(params, {
    allowDots: true,
    skipNulls: true,
  });
  return apiStockServerless.get(`/search/users/?${queryStringParams}`);
};

export const fetchInventoriesByProductId = ({
  productId,
  pageSize = 1000,
  url = null,
}) => {
  const endpoint =
    url ?? `/inventories/?product_id=${productId}&page_size=${pageSize}`;
  return apiStock.get(endpoint);
};

export const fetchInventoriesHistory = (params, pageSize = PAGE_SIZE) => {
  const queryStringParams = qs.stringify(params, {
    allowDots: true,
    skipNulls: true,
  });
  return apiStockServerless.get(
    `/inventories/?${queryStringParams}&page_size=${pageSize}`,
  );
};

export const fetchStockByProductId = (productId: number) =>
  apiStock.get(`/stocks/?product_id=${productId}`);

export const getNextPage = (url: string, number: number) =>
  apiStock.get(`${url}${number}`);
