import React from 'react';

import { Box, Dialog } from '@iclinic/design-system';

type VideoModalProps = {
  open: boolean;
  onClose: (val: boolean) => void;
};

export default function VideoModal({
  open,
  onClose,
}: VideoModalProps): JSX.Element {
  const closeModal = () => {
    onClose(false);
  };

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      role="dialog"
      title="our-video-modal"
    >
      <Box p={1} display="flex" justifyContent="center">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/kb8VaX3GTtU"
          title="Vídeo explicativo iClinic Pay"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </Box>
    </Dialog>
  );
}
