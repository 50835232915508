import { all, call, put, takeLatest } from 'redux-saga/effects';

import { Response } from '@types';
import * as api from '../../services/referrals';
import * as actions from '.';
import { ReferralsAwardResponse } from '../../services/referrals/types';

type RedeemAwardTriggerPayload = ReturnType<
  typeof actions.triggerRedeemReferralsAward
>;

export function* fetchReferralsAwardsWorker() {
  try {
    yield put(actions.fetchReferralsAwardsStart());

    const [availableAwardsResponse, awardsExtractResponse]: Response<
      ReferralsAwardResponse
    >[] = yield all([
      call(api.getReferralsAvailableAwards),
      call(api.getReferralsAwardsExtract),
    ]);

    yield put(
      actions.fetchReferralsAwardsSuccess({
        available: availableAwardsResponse.data.objects,
        availableCount: availableAwardsResponse.data.meta.total_count,
        extract: awardsExtractResponse.data.objects,
        extractCount: awardsExtractResponse.data.meta.total_count,
      }),
    );
  } catch (error) {
    yield put(actions.fetchReferralsAwardsFailure());
  }
}

export function* reedemReferralsAwardWorker({
  payload,
}: RedeemAwardTriggerPayload) {
  try {
    yield put(actions.redeemReferralsAwardStart({ awardId: payload.awardId }));

    yield call(api.reedemReferralsAward, payload.awardId, payload.data);

    yield put(actions.redeemReferralsAwardSuccess());
    yield put(actions.fetchReferralsAwards());
  } catch (error) {
    yield put(actions.redeemReferralsAwardFailure());
  }
}

export default function* referralsSagas() {
  yield takeLatest(actions.fetchReferralsAwards, fetchReferralsAwardsWorker);
  yield takeLatest(
    actions.triggerRedeemReferralsAward,
    reedemReferralsAwardWorker,
  );
}
