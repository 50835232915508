import React, { useState } from 'react';

import {
  Body,
  Box,
  Link,
  MaterialIcons,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';
import { Medicament } from '../../services/types';
import { getNamesFromArray } from '../../utils';
import useStyles, * as S from './MedicamentsDetail.styles';

const { Collapse, ListItem, ListItemText, Divider } = MaterialUICore;
const { ExpandMore } = MaterialIcons;

interface MedicamentsDetailProps {
  medicaments?: Medicament[];
}

const MedicamentsDetail = ({ medicaments }: MedicamentsDetailProps) => {
  const classes = useStyles();
  const [medicamentOpened, setMedicamentOpened] = useState('');

  const handleMedicamentClick = (name: string) => {
    setMedicamentOpened((previousName) => (previousName === name ? '' : name));
  };

  return (
    <S.Root>
      {medicaments &&
        medicaments.map(
          ({
            active_principle,
            indication,
            medicine_leaflet,
            name,
            offprint,
            presentation,
            therapeutic_class,
            url_streaming_id,
            properties_extras,
          }) => (
            <div key={name}>
              <ListItem
                className={classes.listItem}
                onClick={() => handleMedicamentClick(name)}
              >
                <ListItemText>
                  <Body variant="sm" bold>
                    {name}
                    {properties_extras.length
                      ? ` - ${properties_extras[0].property_description}`
                      : ''}
                  </Body>
                </ListItemText>
                <ExpandMore
                  className={
                    medicamentOpened === name ? classes.rotateIcon : ''
                  }
                />
              </ListItem>

              <Collapse in={medicamentOpened === name}>
                <S.Content>
                  {active_principle && (
                    <>
                      <S.SectionTitle variant="sm">
                        Princípio ativo
                      </S.SectionTitle>
                      <S.SectionDescription variant="sm">
                        {getNamesFromArray(active_principle)}
                      </S.SectionDescription>{' '}
                    </>
                  )}

                  {url_streaming_id && (
                    <S.StreamingWrapper>
                      <iframe
                        title={name}
                        width="100%"
                        height="240"
                        src={`https://www.youtube.com/embed/${url_streaming_id}`}
                        frameBorder="0"
                        allowFullScreen
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      />
                    </S.StreamingWrapper>
                  )}

                  {therapeutic_class && (
                    <>
                      <S.SectionTitle variant="sm">
                        Classe Terapêutica
                      </S.SectionTitle>

                      <S.SectionDescription variant="sm">
                        {getNamesFromArray(therapeutic_class)}
                      </S.SectionDescription>
                    </>
                  )}

                  {indication && (
                    <>
                      <S.SectionTitle variant="sm">Indicação</S.SectionTitle>
                      <S.SectionDescription variant="sm">
                        {indication}
                      </S.SectionDescription>{' '}
                    </>
                  )}

                  {presentation && (
                    <>
                      <S.SectionTitle variant="sm">Apresentação</S.SectionTitle>

                      <S.SectionDescription variant="sm">
                        {presentation}
                      </S.SectionDescription>
                    </>
                  )}

                  {properties_extras && !!properties_extras.length && (
                    <>
                      <S.SectionTitle variant="sm">
                        Quantidade na box de amostra
                      </S.SectionTitle>

                      {properties_extras.map(({ property_description }) => (
                        <Box
                          key={property_description}
                          ml={tokens.spacing.base}
                        >
                          <Body variant="sm" key={property_description}>
                            {property_description}
                          </Body>
                        </Box>
                      ))}
                    </>
                  )}

                  {medicine_leaflet && (
                    <S.LinkWrapper>
                      <Link
                        target="_blank"
                        variant="body2"
                        href={medicine_leaflet}
                        bold
                      >
                        Baixar bula (PDF)
                      </Link>
                    </S.LinkWrapper>
                  )}

                  {offprint && (
                    <S.LinkWrapper>
                      <Link
                        target="_blank"
                        variant="body2"
                        href={offprint}
                        bold
                      >
                        Baixar separata (PDF)
                      </Link>
                    </S.LinkWrapper>
                  )}
                </S.Content>
              </Collapse>

              <Divider />
            </div>
          ),
        )}
    </S.Root>
  );
};

export default MedicamentsDetail;
