import React from 'react';

import { MaterialIcons, Typography } from '@iclinic/design-system';
import {
  Physician,
  Clinic,
  Event,
} from 'features/onlinePayment/state/checkout/infos/types';
import Item from './Item';
import useStyles from './EventSummary.styles';

const {
  LocalHospital,
  DateRange,
  Place,
  Check,
  LabelOutlined,
  Phone,
} = MaterialIcons;

const getFullAddress = (clinic: Clinic) => {
  if (clinic.address && clinic.cityState) {
    return `${clinic.address} - ${clinic.cityState}`;
  }

  return clinic.address || clinic.cityState;
};

type EventSummary = {
  physician: Physician;
  clinic: Clinic;
  event: Event;
  orderNumber?: string | null;
};
export default function EventSummary({
  physician,
  clinic,
  event,
  orderNumber = '',
}: EventSummary): JSX.Element {
  const classes = useStyles();

  const fullAddress = getFullAddress(clinic);

  return (
    <div className={classes.box} data-testid="event-summary">
      <Typography variant="h5" classes={{ h5: classes.title }} gutterBottom>
        Seu agendamento
      </Typography>
      <Item icon={<LocalHospital />}>{physician.name}</Item>
      <Item icon={<DateRange />}>{`${event.day} - às ${event.time}`}</Item>
      {!!fullAddress && <Item icon={<Place />}>{fullAddress}</Item>}
      {!!clinic.phoneNumber && (
        <Item icon={<Phone />}>{clinic.phoneNumber}</Item>
      )}
      <Item icon={<Check />}>Pago</Item>
      <div>
        <Item icon={<LabelOutlined />}>Nº do Pedido:</Item>
        <p className={classes.orderNumber}>{orderNumber}</p>
      </div>
    </div>
  );
}
