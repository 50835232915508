import React from 'react';

import {
  Button,
  Dialog,
  MaterialUICore,
  Heading,
  Body,
} from '@iclinic/design-system';
import * as Styles from './styles';

const { DialogContent, DialogTitle, DialogActions } = MaterialUICore;

interface NotAddedGuidesModalProps {
  redirectUrl: string;
  isOpen: boolean;
  handleClose: () => void;
}

function NotAddedGuidesModal({
  redirectUrl,
  isOpen,
  handleClose,
}: NotAddedGuidesModalProps) {
  const classes = Styles.useStyles();

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs">
      <DialogTitle disableTypography>
        <Heading variant="md">As guias não foram adicionadas.</Heading>
      </DialogTitle>

      <DialogContent classes={{ root: classes.dialogContent }}>
        <Body variant="xs">
          Para adicionar guias, você precisa selecionar todas que gostaria, e
          clicar no botão “Salvar” para inseri-las.
        </Body>
      </DialogContent>

      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button
          onClick={handleClose}
          color="transparent"
          classes={{ root: classes.cancelButton }}
        >
          Não, quero adicionar agora.
        </Button>

        <Button
          href={redirectUrl}
          onClick={handleClose}
          classes={{ root: classes.primaryButton }}
        >
          Entendi!
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NotAddedGuidesModal;
