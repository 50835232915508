import React from 'react';
import { Route } from 'react-router-dom';

import VideoConferenceRoutes from './index';

const Root = () => (
  <Route path="/teleconsulta" component={VideoConferenceRoutes} />
);

export default Root;
