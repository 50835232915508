import { Paper, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const ResponsiveCard = styled(Paper)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    border: 'none',
    padding: '0',
    paddingBottom: theme.spacing(4),
  },
}));

export default ResponsiveCard;
