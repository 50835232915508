import React from 'react';

import ss from './styles.scss';
import NotificationErrors from './NotificationErrors';

export default function SignatureGifLoader(): JSX.Element {
  return (
    <div className={ss['gif-loader']}>
      <svg
        width="160"
        height="160"
        viewBox="0 0 160 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Assinando">
          <rect width="160" height="160" />
          <g id="blob">
            <path
              d="M51.5568 40.5755C51.5568 48.0313 45.5126 54.0755 38.0568 54.0755C30.6009 54.0755 24.5568 48.0313 24.5568 40.5755C24.5568 33.1196 30.6009 27.0755 38.0568 27.0755C45.5126 27.0755 51.5568 33.1196 51.5568 40.5755Z"
              fill="#CCBAFD"
            />
            <path
              d="M131.793 115.683C96.3041 157.928 14.0951 150.495 15.0075 104.623C15.4176 84.0084 30.538 73.6974 59.1599 67.8147C89.1265 61.6557 75.9568 18.4354 103.517 16.0608C130.751 13.7143 161.885 79.8628 131.793 115.683Z"
              fill="#CCBAFD"
            />
          </g>
          <rect
            className={ss.doc3}
            x="26"
            y="51.8594"
            width="74"
            height="97"
            rx="4"
            transform="rotate(-15 26 51.8594)"
            fill="#E3F2FD"
          />
          <rect
            className={ss.doc2}
            x="28.8633"
            y="47.8242"
            width="74"
            height="97"
            rx="4"
            transform="rotate(-15 28.8633 47.8242)"
            fill="#BBDEFB"
          />
          <g className={ss.doc1}>
            <g id="maindoc">
              <rect
                id="Rectangle 408"
                x="32.1758"
                y="41.7004"
                width="76.1165"
                height="98"
                rx="4"
                transform="rotate(-15 32.1758 41.7004)"
                fill="#E3F2FD"
              />
              <g id="Group 91">
                <rect
                  id="Rectangle 409"
                  x="48.0635"
                  y="53.2037"
                  width="50.4272"
                  height="1.90291"
                  rx="0.951456"
                  transform="rotate(-15 48.0635 53.2037)"
                  fill="#90CAF9"
                />
                <rect
                  id="Rectangle 410"
                  x="49.7871"
                  y="59.6369"
                  width="50.4272"
                  height="1.90291"
                  rx="0.951456"
                  transform="rotate(-15 49.7871 59.6369)"
                  fill="#90CAF9"
                />
                <rect
                  id="Rectangle 411"
                  x="51.5107"
                  y="66.0702"
                  width="50.4272"
                  height="1.90291"
                  rx="0.951456"
                  transform="rotate(-15 51.5107 66.0702)"
                  fill="#90CAF9"
                />
                <rect
                  id="Rectangle 412"
                  x="53.2344"
                  y="72.5034"
                  width="50.4272"
                  height="1.90291"
                  rx="0.951456"
                  transform="rotate(-15 53.2344 72.5034)"
                  fill="#90CAF9"
                />
                <rect
                  id="Rectangle 413"
                  x="54.959"
                  y="78.9367"
                  width="50.4272"
                  height="1.90291"
                  rx="0.951456"
                  transform="rotate(-15 54.959 78.9367)"
                  fill="#90CAF9"
                />
                <rect
                  id="Rectangle 414"
                  x="56.6826"
                  y="85.3699"
                  width="50.4272"
                  height="1.90291"
                  rx="0.951456"
                  transform="rotate(-15 56.6826 85.3699)"
                  fill="#90CAF9"
                />
                <rect
                  id="Rectangle 415"
                  x="58.4062"
                  y="91.8032"
                  width="50.4272"
                  height="1.90291"
                  rx="0.951456"
                  transform="rotate(-15 58.4062 91.8032)"
                  fill="#90CAF9"
                />
                <rect
                  id="Rectangle 416"
                  x="60.1299"
                  y="98.2365"
                  width="50.4272"
                  height="1.90291"
                  rx="0.951456"
                  transform="rotate(-15 60.1299 98.2365)"
                  fill="#90CAF9"
                />
                <rect
                  id="Rectangle 417"
                  x="61.8535"
                  y="104.67"
                  width="31.3981"
                  height="1.90291"
                  rx="0.951456"
                  transform="rotate(-15 61.8535 104.67)"
                  fill="#90CAF9"
                />
              </g>
              <path
                id="Vector 50"
                d="M115.857 111.377L84.6191 119.774"
                stroke="#42A5F5"
              />
            </g>
            <path
              className={ss.signature}
              d="M84.4775 119.01C91.4604 115.661 90.3523 111.526 94.9475 110.294C99.5427 109.063 100.589 112.969 103.195 113.502C105.8 114.035 115.626 104.754 110.571 106.108C103.572 107.984 108.529 115.028 115.815 109.136"
              stroke="#9469F0"
            />
          </g>
          <g className={ss.pen}>
            <path
              id="Rectangle 418"
              d="M135.342 68.3138C136.398 66.4837 138.739 65.8566 140.569 66.9132V66.9132C142.399 67.9699 143.026 70.3101 141.969 72.1403L123.22 104.616L116.592 100.789L135.342 68.3138Z"
              fill="#42A5F5"
            />
            <rect
              id="Rectangle 419"
              x="133.812"
              y="70.9649"
              width="7.65297"
              height="1.53059"
              transform="rotate(30 133.812 70.9649)"
              fill="#1E88E5"
            />
            <path
              id="Vector 52"
              d="M115.622 108.276L116.592 100.789L123.219 104.616L117.221 109.199C116.515 109.738 115.508 109.157 115.622 108.276Z"
              fill="#90CAF9"
            />
            <path
              id="Subtract"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M115.634 108.189L115.623 108.276C115.508 109.157 116.515 109.738 117.221 109.199L117.291 109.146L115.634 108.189Z"
              fill="#2196F3"
            />
          </g>
        </g>
      </svg>
      <p className={ss.description}>Assinando seus documentos...</p>
      <NotificationErrors />
    </div>
  );
}
