// External
import React from 'react';
import classnames from 'classnames/bind';

// Internal
import styles from './SubscriptionBanner.scss';

const cx = classnames.bind(styles);

type SubscriptionBannerProps = {
  classes?: string;
  imageUrlDesktop?: string;
  imageUrlLarge?: string;
  imageUrlTablet?: string;
  imageUrlSmall?: string;
  altText?: string;
};

export default function SubscriptionBanner({
  classes = '',
  imageUrlDesktop = '',
  imageUrlTablet = '',
  imageUrlLarge = '',
  imageUrlSmall = '',
  altText = 'Imagem promocional',
}: SubscriptionBannerProps): JSX.Element {
  const desktopBackground = {
    background: `url(${imageUrlDesktop}) no-repeat center center / cover`,
  };
  return (
    <div className={cx(styles['subscription-bar'], classes)}>
      <div className={styles['bar-desktop']} style={desktopBackground} />
      <img
        alt={altText}
        src={imageUrlTablet}
        className={styles['bar-tablet']}
      />
      <img alt={altText} src={imageUrlLarge} className={styles['bar-large']} />
      <img alt={altText} src={imageUrlSmall} className={styles['bar-small']} />
    </div>
  );
}
