import { AxiosError } from 'axios';

import { api, apiTelemedicine } from 'features/video-conference/services/api';
import {
  createFileUploadPayload,
  getUploadMessageError,
} from 'features/video-conference/utils';

export const uploadFile = async (
  file: File,
  onError: ErrorCallback,
  isPhysician: boolean,
  eventId?: number | undefined,
) => {
  const image = await createFileUploadPayload(file);
  let payload = {};
  if (isPhysician) payload = { event: eventId, image };

  try {
    const { data } = isPhysician
      ? await api.post(`/records/eventvideoconference/physician/file/`, payload)
      : await apiTelemedicine.post(`conference/schedule/upload`, image);

    const { image: responseImage } = isPhysician ? data : data.data;
    return { responseImage };
  } catch (error) {
    onError(error as any);
    const messageError = getUploadMessageError(error as AxiosError);
    return { messageError };
  }
};
