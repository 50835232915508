import {
  MaterialUICore,
  MaterialIcons,
  axiomTokens,
  Box,
  Button as DSButton,
} from '@iclinic/design-system';

const { styled, Divider: DSDivider } = MaterialUICore;
const {
  KeyboardArrowLeftRounded: KeyboardArrowLeftRoundedDS,
  KeyboardArrowRightRounded: KeyboardArrowRightRoundedDS,
} = MaterialIcons;

const { color, spacing, font, border } = axiomTokens;

export const KeyboardInfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: spacing[1],

  '& span': {
    fontFamily: font.family.base,
    fontSize: font.size.xxxs,
    fontWeight: font.weight.regular,
    lineHeight: font.lineHeight.sm,
    color: color.typeface.smooth,
    letterSpacing: font.letterSpacing.md,
    whiteSpace: 'nowrap',
  },

  [theme.breakpoints.down('md')]: {
    display: 'none',
    width: '0px',
  },
}));

export const Button = styled(DSButton)(({ theme }) => ({
  minWidth: '160px',

  [theme.breakpoints.down('md')]: {
    width: '100%!important',
    minWidth: '0px',
  },
}));

export const Divider = styled(DSDivider)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.down('md')]: {
    display: 'flex',
    color: color.others.divider,
  },
}));

export const NavigationText = styled('span')({
  margin: `0px ${spacing[2]}`,
});

export const CommandBackground = styled('span')({
  fontSize: font.size.sm,
  color: color.icon.default,
  backgroundColor: color.surface.accent.default,
  borderRadius: border.radius.sm,
  padding: spacing[1],
  margin: `0px`,
  marginRight: spacing[1],
  lineHeight: font.lineHeight.xxs,
});

const iconStyle = {
  fontSize: font.size.md,
  color: color.icon.default,
  backgroundColor: color.surface.accent.default,
  borderRadius: border.radius.sm,
  marginLeft: spacing[1],
};

export const KeyboardArrowRightRounded = styled(KeyboardArrowRightRoundedDS)({
  ...iconStyle,
});

export const KeyboardArrowLeftRounded = styled(KeyboardArrowLeftRoundedDS)({
  ...iconStyle,
});
