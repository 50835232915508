import React from 'react';

import classnames from 'classnames/bind';

import Align from './constants';
import style from './Item.scss';

const cn = classnames.bind(style);

type ItemProps = {
  children: React.ReactNode;
  align?: Align;
  classes: string;
};

function Item({
  children,
  align = Align.right,
  classes = '',
}: ItemProps): JSX.Element {
  return (
    <th className={cn(style.item, classes)} align={align}>
      {children}
    </th>
  );
}

export default Item;
