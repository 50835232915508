import { MaterialUICore, tokens, Body as DSBody } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Container = styled('div')(({ theme }) => ({
  marginBottom: tokens.spacing.nano,
  lineHeight: 0,
  '&:last-child': {
    margin: '0px',
  },
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

export const Body = styled(DSBody)({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

export const SemiBold = styled('span')({
  fontWeight: tokens.font.weight.medium,
});
