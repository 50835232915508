import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialUICore } from '@iclinic/design-system';

import useCurrencyMask, {
  format,
  unformat,
} from 'shared/hooks/useCurrencyMask';
import { getFixedNumber } from 'features/onlinePayment/utils';
import { percentageUtils } from 'features/newFinance/utils';
import {
  CustomDiscountSelect,
  CustomDiscountTextField,
  DiscountContainer,
} from './styles';
import {
  addDiscountValue,
  updateValues,
} from 'features/newFinance/state/payment';
import { getTotalValuesSelector } from 'features/newFinance/state/selectors';

const { MenuItem } = MaterialUICore;

const options = [
  {
    label: 'R$',
    value: 'cash',
  },
  {
    label: '%',
    value: 'percentage',
  },
];

const Discount = (): JSX.Element => {
  const dispatch = useDispatch();
  const { subTotal } = useSelector(getTotalValuesSelector);

  const [discountMode, setDiscountMode] = useState('cash');
  const [discountError, setDiscountError] = useState({
    hasError: false,
    message: 'Valor inválido',
  });

  const [discountWasApplied, setAppliedDiscount] = useState(false);

  const {
    ref,
    onChange: onChangeValue,
    originalValue: discountValueWithoutFormat,
  } = useCurrencyMask(0);

  const [percentageDiscount, setPercentageDiscount] = useState(0);

  const handleChangeDiscountMode = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDiscountMode(event.target.value);
    setAppliedDiscount(false);
  };

  const inputRef = useRef<({ oldValue: string } & HTMLInputElement) | null>(
    null,
  );

  const handleChangePercentage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const percentageValue = percentageUtils.unformat(event.target.value);

    if (percentageValue > 100) {
      inputRef.current!.value = inputRef.current?.oldValue || '0%';
      return;
    }
    inputRef.current!.oldValue = percentageUtils.format(percentageValue);
    setPercentageDiscount(percentageValue);
  };

  const handleBlurCashDiscount = (e: React.FocusEvent<HTMLInputElement>) => {
    if (discountWasApplied) return;

    const discountValueIsEqualZero = unformat(e.target.value) === 0;

    if (discountValueWithoutFormat > subTotal) {
      setDiscountError((state) => ({ ...state, hasError: true }));
      return;
    }

    dispatch(
      addDiscountValue({
        discountMethod: discountMode,
        discountValue: discountValueWithoutFormat,
      }),
    );

    dispatch(
      updateValues({
        total: discountValueIsEqualZero
          ? subTotal
          : subTotal - discountValueWithoutFormat,
        discountedValue: discountValueWithoutFormat,
      }),
    );

    setAppliedDiscount(true);
  };

  const handleBlurPercentageDiscount = () => {
    if (discountWasApplied) return;
    const { value } = inputRef.current!;

    const [discountedValue, total] = percentageUtils.calculateTotal(
      subTotal,
      value,
    );

    dispatch(
      addDiscountValue({
        discountMethod: discountMode,
        discountValue: value,
      }),
    );

    dispatch(
      updateValues({
        total,
        discountedValue,
      }),
    );
    setAppliedDiscount(true);
  };

  return (
    <DiscountContainer hasError={discountError.hasError}>
      <span>Desconto</span>
      <CustomDiscountSelect
        defaultValue={discountMode}
        value={discountMode}
        onChange={handleChangeDiscountMode}
        data-testid="discount-select"
        disabled={!subTotal}
      >
        {options.map(({ label, value }) => (
          <MenuItem key={value} value={value} title={label}>
            {label}
          </MenuItem>
        ))}
      </CustomDiscountSelect>
      {discountMode === 'cash' ? (
        <CustomDiscountTextField
          inputRef={ref}
          defaultValue={format(getFixedNumber(discountValueWithoutFormat))}
          placeholder="RS 00,00"
          onChange={() => {
            setAppliedDiscount(false);
            onChangeValue();
          }}
          onBlur={handleBlurCashDiscount}
          error={discountError.hasError}
          helperText={discountError.hasError && discountError.message}
          disabled={!subTotal}
        />
      ) : null}
      {discountMode === 'percentage' ? (
        <CustomDiscountTextField
          inputRef={inputRef}
          onFocus={() => {
            if (!inputRef.current) return;
            inputRef.current.oldValue = inputRef.current?.value;
          }}
          defaultValue={percentageUtils.format(percentageDiscount)}
          placeholder="0%"
          onChange={(e) => {
            setAppliedDiscount(false);
            handleChangePercentage(e);
          }}
          onBlur={handleBlurPercentageDiscount}
          disabled={!subTotal}
        />
      ) : null}
    </DiscountContainer>
  );
};

export default Discount;
