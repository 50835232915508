import React, { ChangeEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
} from '@iclinic/design-system';

import { getIsTemplateModalOpen } from 'state/records/textTemplate/selectors';
import { actions } from 'state/records/textTemplate';
import { TextField } from './RichTextTemplateModal.styles';

const { closeTextTemplateModal, saveTextTemplate } = actions;

const RichTextTemplateModal = () => {
  const [templateTitle, setTemplateTitle] = useState('');
  const isTemplateModalOpen = useSelector(getIsTemplateModalOpen);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeTextTemplateModal());
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTemplateTitle(e.target.value);
  };

  const handleSave = () => {
    dispatch(saveTextTemplate(templateTitle));
    setTemplateTitle('');
  };

  const isSaveDisabled = templateTitle.length === 0;

  return (
    <Dialog open={isTemplateModalOpen} onClose={handleClose}>
      <DialogHeader title="Salvar Modelo" onClose={handleClose} />
      <DialogContent>
        <Body variant="xs">Adicione um título ao seu modelo de texto.</Body>
        <TextField
          label="Título"
          placeholder="Digite o título aqui"
          value={templateTitle}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button color="transparent" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={isSaveDisabled}
          data-ga="medical_record_text_templates_save_title"
          data-sp="medical_record_text_templates_save_title"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RichTextTemplateModal;
