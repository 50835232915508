import React from 'react';

import {
  MaterialUICore,
  MaterialIcons,
  tokens,
  Body,
  Box,
  Button,
} from '@iclinic/design-system';
import withContentLoader from 'shared/hoc/withContentLoader';
import { GOOGLE_MAPS_BASE_URL } from 'shared/constants/urls';
import { Clinic } from 'features/onlinePayment/state/checkout/infos/types';
import { ClinicInfosContentLoader } from './ContentLoaders';

const { Phone: PhoneDS, Room: RoomDS } = MaterialIcons;
const { styled } = MaterialUICore;
const { color, spacing } = tokens;

const Phone = styled(PhoneDS)(() => ({
  color: color.neutral[500],
  marginRight: spacing.base,
}));

const Room = styled(RoomDS)(() => ({
  color: color.neutral[500],
  marginRight: spacing.base,
}));

type ClinicInfosProps = {
  clinic: Clinic | null;
};

function ClinicInfos({ clinic }: ClinicInfosProps): JSX.Element | null {
  if (!clinic) return null;

  const { address, neighborhood, cityState, zipCode, phoneNumber } = clinic;

  const linkAddressOnMaps = `${GOOGLE_MAPS_BASE_URL}/?q=${`${address}, ${neighborhood}, ${cityState} - ${zipCode}`}`;

  return (
    <Box mt={3} data-testid="checkout-clinic-infos">
      {!!phoneNumber && (
        <Box display="flex" alignItems="center" mb={2}>
          <Phone />
          <Body variant="xs">{phoneNumber}</Body>
        </Box>
      )}
      <Box display="flex" alignItems="flex-start">
        {!!address && <Room />}
        <Box>
          {!!address && <Body variant="xs">{address}</Body>}
          {!!neighborhood && <Body variant="xs">{neighborhood}</Body>}
          {!!cityState && <Body variant="xs">{cityState}</Body>}
          {!!zipCode && <Body variant="xs">{zipCode}</Body>}
          {!!address && (
            <Box mt={1}>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => {
                  window.open(linkAddressOnMaps, '_blank');
                }}
              >
                Ver no mapa
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default withContentLoader<ClinicInfosProps>(ClinicInfosContentLoader)(
  ClinicInfos,
);
