import React from 'react';

import { Bill } from 'features/onlinePayment/state/checkout/infos/types';
import { PaymentType } from 'features/onlinePayment/state/constants';
import CreditCard from 'features/onlinePayment/components/checkout/payment/CreditCard';
import Pix from 'features/onlinePayment/components/checkout/payment/Pix';

type PaymentTypeInfosProps = {
  payType: string | null;
  bill: Bill | null;
};

export default function PaymentTypeInfos({
  payType,
  bill,
}: PaymentTypeInfosProps): JSX.Element | null {
  if (!payType) return null;

  const paymentTypeMap = {
    [PaymentType.Credit]: <CreditCard bill={bill} />,
    [PaymentType.Pix]: <Pix />,
  };

  return paymentTypeMap[payType as keyof typeof paymentTypeMap];
}
