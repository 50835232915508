import { MaterialUICore, FloatingActionButton } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Fab = styled(FloatingActionButton)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(10),
  'z-index': 1,
  [theme.breakpoints.up('md')]: {
    bottom: theme.spacing(6),
  },
  '& span > svg': {
    marginRight: theme.spacing(0.5),
  },
  '& .MuiCircularProgress-root': {
    marginRight: theme.spacing(1),
  },
}));

export const FloatingWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  height: theme.spacing(6),
  [theme.breakpoints.up('md')]: {
    height: theme.spacing(12),
  },
}));
