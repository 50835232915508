import { TITLE_MODAL } from 'shared/constants/Billing';

export function showTextNextSteper(
  selectedAddons: string[],
  currentAddon: string,
  step: number,
) : string {
  const index = selectedAddons.findIndex(i => i === currentAddon);
  if (index < (selectedAddons.length - 1) && index !== -1) {
    return TITLE_MODAL[step];
  }
  return TITLE_MODAL[step + 1];
}

export default showTextNextSteper;
