import { PromiseEnvelope } from '@types';
import { useLayoutEffect, useState } from 'react';
import { LocalVideoTrack, RemoteVideoTrack, Room } from 'twilio-video';
import {
  browserVersion,
  isChrome,
  isEdge,
  isFirefox,
  isSafari,
} from 'react-device-detect';
import uuid from 'uuid';
import { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';

import { captureException } from 'shared/utils/handlerErrors';
import {
  Annotation,
  CaptionData,
  LogError,
  Media,
  Message,
  Participant,
} from 'features/video-conference/constants/types';
import {
  ALLOWED_FILE_TYPES,
  DEFAULT_VIDEO_CONSTRAINTS,
  devicePermissionsMessages,
  FILE_TOO_LARGE,
  UPLOAD_ERROR,
  BACKGROUND_IMAGES_NAME,
  THUMB_IMAGES,
  servicesLabels,
  TWILLIO_ERRORS_MESSAGE,
} from 'features/video-conference/constants';
import { FetchSendLogsData, JWTBaseToken } from '../services/types';

export const isProd = process.env.NODE_ENV === 'production';

export const isMobile = (() => {
  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent !== 'string'
  ) {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export type TUseMedia = 'audio' | 'video';

export type IVideoTrack = LocalVideoTrack | RemoteVideoTrack;

export async function getUserMedia(
  constraint: TUseMedia,
): Promise<MediaStream> {
  return navigator.mediaDevices?.getUserMedia({ [constraint]: true });
}

export async function getAudioAndVideoPermissions(
  audioConstraints: MediaStreamConstraints['audio'] = true,
  videoConstraints: MediaStreamConstraints['video'] = DEFAULT_VIDEO_CONSTRAINTS,
): Promise<MediaStream> {
  return navigator.mediaDevices.getUserMedia({
    audio: audioConstraints,
    video: videoConstraints,
  });
}

export const checkAudioPermisions = async (): Promise<boolean> => {
  const { active } = await getUserMedia('audio');
  return active;
};

export const checkVideoPermisions = async (): Promise<boolean> => {
  const { active } = await getUserMedia('video');
  return active;
};

export const checkAudioAndVideoPermissions = async (): Promise<{
  audio: boolean;
  video: boolean;
}> => {
  try {
    const [audioPermision, videoPermision] = await Promise.allSettled([
      checkAudioPermisions(),
      checkVideoPermisions(),
    ]);

    return {
      audio: audioPermision.status === 'fulfilled' && audioPermision.value,
      video: videoPermision.status === 'fulfilled' && videoPermision.value,
    };
  } catch (error) {
    return {
      audio: false,
      video: false,
    };
  }
};

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices();

  return {
    audioInputDevices: devices.filter((device) => device.kind === 'audioinput'),
    videoInputDevices: devices.filter((device) => device.kind === 'videoinput'),
    audioOutputDevices: devices.filter(
      (device) => device.kind === 'audiooutput',
    ),
    hasAudioInputDevices: devices.some(
      (device) => device.kind === 'audioinput',
    ),
    hasVideoInputDevices: devices.some(
      (device) => device.kind === 'videoinput',
    ),
  };
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: PermissionName) {
  if (navigator.permissions) {
    try {
      const result = await navigator.permissions.query({ name });
      return result.state === 'denied';
    } catch {
      return false;
    }
  } else {
    return false;
  }
}

export const getInitials = (name: string) => {
  if (!name) return '';
  const initials = name.split(' ');
  if (initials.length > 1) {
    return (
      (initials.shift() || '').charAt(0) + (initials.pop() || '').charAt(0)
    );
  }
  return name.substring(0, 2);
};

export const supportedBrowserTelemedicine = (): boolean => {
  const isVersionSupported = parseInt(browserVersion, 10) > 11;
  return (isSafari || isFirefox || isChrome || isEdge) && isVersionSupported;
};

export const TELEMEDICINE_STORAGE_KEY = 'telemedicine';

export const getTelemedicineStorage = () => {
  const telemedicineStorage = localStorage.getItem(TELEMEDICINE_STORAGE_KEY);

  if (!telemedicineStorage) return null;

  return JSON.parse(telemedicineStorage);
};

export const createTelemedicineStorage = () => {
  const telemedicineStorage = localStorage.getItem(TELEMEDICINE_STORAGE_KEY);
  if (telemedicineStorage) return;
  localStorage.setItem(TELEMEDICINE_STORAGE_KEY, JSON.stringify({}));
};

export const setTelemedicineOptions = (option: string, value: string): void => {
  const telemedicineOptions = getTelemedicineStorage();

  if (telemedicineOptions) {
    telemedicineOptions[option] = value;
    const newOptions = JSON.stringify(telemedicineOptions);
    localStorage.setItem(TELEMEDICINE_STORAGE_KEY, newOptions);
  }
};

export function setTelemedicineStatus(statusName: string) {
  setTelemedicineOptions('status', statusName);
}

export const parseDate = (key: string, value: string) => {
  if (key === 'dateCreated') return new Date(value);
  return value;
};

export const useWindowHeight = () => {
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return height;
};

export const getChatMessagesByAuthor = (messages: Message[], author: string) =>
  messages.filter((message) => message.author === author);

export const hasNewChatMessages = (
  prevMessages: Message[],
  messages: Message[],
  author: string,
) => {
  const messagesFiltered = getChatMessagesByAuthor(messages, author);
  const prevMessagesFiltered = getChatMessagesByAuthor(prevMessages, author);

  return messagesFiltered.length !== prevMessagesFiltered.length;
};

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (!reader.result) return;

      let encoded = reader.result?.toString().replace(/^data:(.*,)?/, '');
      if (encoded.length % 4 > 0)
        encoded += '='.repeat(4 - (encoded.length % 4));
      resolve(encoded);
    };
    reader.onerror = (error) => reject(error);
  });

export const createChatMessagePayload = (
  body: string,
  file: Media | null,
  type: string,
  room: Room,
  localParticipant: Participant,
) =>
  ({
    sid: uuid(),
    identity: room.localParticipant.identity,
    author: localParticipant?.name,
    dateCreated: new Date(),
    type,
    body,
    file,
  } as Message);

export const createFileUploadPayload = async (file: File) => ({
  name: file.name,
  file: await fileToBase64(file),
});

export const createMediaObject = (file: File, url: string) =>
  ({
    filename: file.name,
    filetype: file.type,
    url,
  } as Media);

export const getUploadMessageError = (error: AxiosError) =>
  error.code === '413' ? FILE_TOO_LARGE : UPLOAD_ERROR;

export const hasAllowedFileSize = (size: number) => {
  const maxSizeAllowed = 64;
  const filesize = Number.parseFloat((size / (1024 * 1024)).toFixed(2));
  return filesize <= maxSizeAllowed;
};

export const hasAllowedFileExtension = (name: string) => {
  const allowedFilesList = ALLOWED_FILE_TYPES.split(',');
  const extension = name.split('.').pop() || '';
  return allowedFilesList.indexOf(`.${extension}`) > -1;
};

/*
 * This function formats the clinic/patient notes as expected by
 *  conference/finish endpoint request.
 *  It split in different objects when found a line broke and add the
 *  at! field into this news objects. After it filters empty lines.
 * input: [
    { at: 13456754467, message: 'example\nwith broken line\n""' },
  ]
 * output: [
    { at: 13456754467, message: 'example\n' },
    { at: 13456754467, message: 'with broken line\n' },
  ]
*/
export const formatNotes = (notes: Annotation | null) => {
  if (!notes) return [];

  const messagesBrokelineArray = notes.message.split(/\r?\n/);
  let sum = 0;
  const notesArray = messagesBrokelineArray.map((message: string) => {
    sum += 1;
    return { at: Number(notes.at) + sum, message: `${message}\n` };
  });

  return notesArray.filter((note) => /\S/.test(note.message));
};

export function getMediaErrorMessage(
  hasAudio: boolean,
  hasVideo: boolean,
  error?: Error,
) {
  let message = '';

  switch (true) {
    // These custom errors are thrown by the useLocalTracks hook. They are thrown when the user explicitly denies
    // permission to only their camera, or only their microphone.
    case error?.message === 'CameraPermissionsDenied':
      message = devicePermissionsMessages.CAMERA_DENIED;
      break;
    case error?.message === 'MicrophonePermissionsDenied':
      message = devicePermissionsMessages.AUDIO_DENIED;
      break;

    // This error is emitted when the user or the user's system has denied permission to use the media devices
    case error?.name === 'NotAllowedError':
      if (error!.message === 'Permission denied by system') {
        // Chrome only
        message = devicePermissionsMessages.SYSTEM_DENIED;
      } else {
        message = devicePermissionsMessages.CAMERA_AUDIO_DENIED;
      }
      break;

    // This error is emitted when input devices are not connected or disabled in the OS settings
    case error?.name === 'NotFoundError':
      message = devicePermissionsMessages.DEVICES_NOT_FOUND;
      break;

    // Other getUserMedia errors are less likely to happen in this app. Here we will display
    // the system's error message directly to the user.
    case Boolean(error):
      message = `${error!.name} ${error!.message}`;
      break;

    case !hasAudio && !hasVideo:
      message = devicePermissionsMessages.NO_CAMERA_AUDIO_DETECTED;
      break;

    case !hasVideo:
      message = devicePermissionsMessages.NO_CAMERA_DETECTED;
      break;

    case !hasAudio:
      message = devicePermissionsMessages.NO_AUDIO_DETECTED;
      break;

    default:
      message = devicePermissionsMessages.DEFAULT_MESSAGE;
      break;
  }

  return message;
}

export const calculateAge = (birthday: string) => {
  const birthDate = new Date(birthday);
  const currentDate = new Date();

  const difference = currentDate.getTime() - birthDate.getTime();
  return Math.floor(difference / 31536000000);
};

export const getMicrophoneFeedbackLeftSize = (
  isLocal: boolean,
  isShowingNetworkQuality: boolean,
  isVideoEnabled: boolean,
  isMobileParam?: boolean,
) => {
  if (isLocal) return null;
  if (!isLocal && isShowingNetworkQuality && isVideoEnabled) {
    if (isMobile || isMobileParam) return 'calc(50% - 130px)';
    return 'calc(50% - 205px)';
  }
  return 'unset';
};

export function initializeAnalyser(stream: MediaStream) {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const audioContext = new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.2;
  analyser.fftSize = 256;

  audioSource.connect(analyser);

  stream.addEventListener('cleanup', () => {
    if (audioContext.state !== 'closed')
      audioContext.close().catch(captureException);
  });

  return analyser;
}

export const isIOS = /iPhone|iPad/.test(navigator.userAgent);

export const backgroundConfig = {
  BACKGROUND_IMAGES_NAME,
  THUMB_IMAGES,
};

export const createCaptionData = ({
  isCaptionMessage = true,
  captionType,
  isCaptionActive,
  transcript,
}: CaptionData) => ({
  isCaptionMessage,
  captionType,
  isCaptionActive,
  transcript,
});

const isLastLine = (lastLine: string, word: string, maxWidth: number) =>
  !lastLine || lastLine.length + word.length + 1 > maxWidth;

const getCaptionLinesByLimit = (caption: string, maxWidth: number): string[] =>
  caption.split(/\s+/).reduce((acc: string[], word: string) => {
    const currentIndex = acc.length - 1;
    const lastLine = acc[currentIndex];
    if (isLastLine(lastLine, word, maxWidth)) {
      acc.push(word);
    } else {
      // eslint-disable-next-line no-param-reassign
      acc[currentIndex] = `${lastLine} ${word}`;
    }
    return acc;
  }, []);

export const limitCaptionSizeAndGetLines = (
  caption: string,
  isMobileDevice: boolean,
): string[] => {
  const isIframeMinimized =
    getTelemedicineStorage()?.iframe_status === 'minimized';
  const maxWidth = isMobileDevice || isIframeMinimized ? 35 : 88;
  if (caption.length <= maxWidth) return [caption];
  const lines = getCaptionLinesByLimit(caption, maxWidth);
  return lines.slice(-2);
};

export const getTelemedicineTokenFromUrl = () => {
  const { searchParams } = new URL(window.location.href);
  return searchParams.get(servicesLabels.CONFERENCE_TOKEN);
};

export const getTelemedicineToken = () => {
  const conferenceToken = getTelemedicineTokenFromUrl();

  const conferenceTokenLocalStore = global.localStorage.getItem(
    servicesLabels.CONFERENCE_TOKEN,
  );

  return conferenceTokenLocalStore === 'undefined' || !conferenceTokenLocalStore
    ? conferenceToken
    : conferenceTokenLocalStore;
};

export const updateTelemedicineStorage = (
  physicianToken: string,
  patientToken: string,
  isPhysician: boolean,
) => {
  const telemedicineStorage = localStorage.getItem('telemedicine');
  if (telemedicineStorage) {
    const telemedicineOptions = JSON.parse(telemedicineStorage);
    telemedicineOptions.physicianRoomURL = `${window.location.origin}/teleconsulta/medico/consulta/?conferenceToken=${physicianToken}`;
    telemedicineOptions.conference.physician_token = physicianToken;
    telemedicineOptions.conference.patient_token = patientToken;
    const newOptions = JSON.stringify(telemedicineOptions);
    localStorage.setItem('telemedicine', newOptions);
  }

  localStorage.setItem(
    servicesLabels.CONFERENCE_TOKEN,
    isPhysician ? physicianToken : patientToken,
  );
};

export const decodeJwtToken = (token: string): JWTBaseToken | null => {
  if (!token) return null;

  const decodedToken: JWTBaseToken = jwtDecode(token);

  return decodedToken;
};
export const handleTwilioErrorLog = async ({
  logError,
  fetchSendLogs,
}: {
  logError: LogError;
  fetchSendLogs: (body: FetchSendLogsData) => PromiseEnvelope<void>;
}) => {
  const { actionType: type, code, message } = logError;
  const logMessage = TWILLIO_ERRORS_MESSAGE[code] || message;

  const { searchParams } = new URL(window.location.href);
  const conferenceToken = searchParams.get(servicesLabels.CONFERENCE_TOKEN);

  if (!conferenceToken) {
    return;
  }

  const token = decodeJwtToken(conferenceToken);

  if (!token) {
    return;
  }

  await fetchSendLogs({
    conferenceId: token?.conferenceId,
    clinicId: token?.clinicId,
    physicianId: token?.physicianId,
    patientId: token?.patientId,
    participantType: token?.isPhysician ? 'Physician' : 'Patient',
    actionType: `${type} participant`,
    description: `${code} - ${logMessage}`,
  });
};
