import React from 'react';
import { Heading } from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';
import * as S from '../UpgradeCall.styles';

export type UpgradeCallCardProps = {
  children: React.ReactNode;
  title: string;
  onImageClick?: () => void;
  imagePath: string;
  imageAlt?: string;
};

export const UpgradeCallCard = ({
  children,
  title,
  onImageClick,
  imagePath,
  imageAlt,
}: UpgradeCallCardProps) => {
  const onClickHandler = () => {
    if (onImageClick) onImageClick();
  };

  return (
    <S.Wrapper>
      <S.UpgradeCallCard variant="outlined">
        <Heading variant="sm">{title}</Heading>
        <S.ImageWrapper onClick={onClickHandler}>
          <img src={image(imagePath)} alt={imageAlt || ''} />
        </S.ImageWrapper>
        {children}
      </S.UpgradeCallCard>
    </S.Wrapper>
  );
};
