import {
  makeValidation,
  haveMinLength,
  hasValue,
  isEmail,
  isValidUrlHttp,
  isNumber,
  isValidFullDate,
  isPositive,
  isValidDocument,
  isValidHour,
  isValidName,
} from '../validation';
import { isSecurityCodeValid, isCardNumberValid, isExpirationDateValid } from '../creditCardValidation';

export const required = makeValidation(hasValue, 'Campo obrigatório');
export const requiredEmpty = makeValidation(hasValue, ' ');
export const email = makeValidation(isEmail, 'E-mail inválido');
export const minLength3 = makeValidation(haveMinLength(3), 'O valor deve ter no minímo 3 caracteres');
export const cardNumber = makeValidation(isCardNumberValid, 'Número do cartão inválido');
export const expirationDate = makeValidation(isExpirationDateValid, 'Data inválida');
export const securityCode = makeValidation(isSecurityCodeValid, ' ');
export const url = makeValidation(isValidUrlHttp, 'O endereço deve ser válido: https://www.site.com');
export const number = makeValidation(isNumber, 'Este campo precisa ser numérico');
export const fullDate = makeValidation(isValidFullDate, 'Esta data não é válida');
export const fullDateEmpty = makeValidation(isValidFullDate, ' ');
export const fullHoursEmpty = makeValidation(isValidHour, ' ');
export const positive = makeValidation(isPositive, 'Este valor informado precisa ser positivo');
export const personDocument = makeValidation(isValidDocument, 'Campo deve conter um documento válido');
export const owner = makeValidation(isValidName, 'Este campo não deve ser Alfanumérico');
