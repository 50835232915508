import { Button, MaterialIcons, MaterialUICore } from '@iclinic/design-system';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  getPaymentConfig,
  getTotalValuesSelector,
} from 'features/newFinance/state/selectors';
import { getFixedNumber } from 'features/onlinePayment/utils';
import useCurrencyMask, { format } from 'shared/hooks/useCurrencyMask';
import { formatCurrency } from 'shared/utils/strings';
import { getPaymentMethod } from '../utils/utils';
import {
  CustomFooterContainer,
  CustomSelect,
  CustomTextField,
  TotalContainer,
} from './styles';
import { PaymentFormProps } from './utils/FormValidate';

const { MenuItem } = MaterialUICore;
const { Warning } = MaterialIcons;

type DefaultFormProps = {
  children: React.ReactNode;
  handleClose: () => void;
};
const DefaultForm = ({ children, handleClose }: DefaultFormProps) => {
  const { remainingAmount } = useSelector(getTotalValuesSelector);

  const {
    setFieldValue,
    setFieldTouched,
    values,
    errors,
    handleBlur,
    touched,
  } = useFormikContext<PaymentFormProps>();

  const defaultValue = remainingAmount ?? values.total;

  const { originalValue, ref, onChange } = useCurrencyMask(defaultValue);

  const diff = defaultValue - originalValue;

  const { paymentFormOptions, defaultPaymentForm } =
    useSelector(getPaymentConfig);

  useEffect(() => {
    setFieldValue('value', originalValue);
  }, [originalValue, setFieldValue]);

  useEffect(() => {
    setFieldValue('paymentType', defaultPaymentForm);
  }, [setFieldValue, defaultPaymentForm]);

  const handleChange = (
    fieldName: string,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setFieldValue(fieldName, event?.target.value);

  return (
    <>
      <CustomTextField
        name="value"
        defaultValue={format(getFixedNumber(defaultValue))}
        label="Qual é o valor"
        inputRef={ref}
        inputProps={{
          'data-testid': 'input_value',
        }}
        onChange={onChange}
        onBlur={handleBlur}
        onClick={() => setFieldTouched('value', true, false)}
        error={!!(touched.value && errors.value)}
        helperText={
          touched.value && errors.value
            ? 'O valor dever ser menor ou igual ao total do procedimento.'
            : null
        }
      />

      <CustomSelect
        name="paymentType"
        data-paymenttype="paymentType"
        label="Forma de pagamento"
        value={values.paymentType}
        onChange={(event) => handleChange('paymentType', event)}
        onBlur={handleBlur}
        error={!!(touched.paymentType && errors.paymentType)}
        helperText={
          touched.paymentType && errors.paymentType ? errors.paymentType : null
        }
        InputProps={{
          'data-testid': 'payment-type',
        }}
      >
        {paymentFormOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            title={option.label}
          >
            {option.label}
          </MenuItem>
        ))}
      </CustomSelect>

      {children}

      <TotalContainer>
        <div>
          <span> Valor total </span>
          <span> {formatCurrency(values.total)}</span>
        </div>
        <div>
          <span>Valor em {getPaymentMethod(values.paymentType)}</span>
          <span>{formatCurrency(values.value)}</span>
        </div>
        {diff ? (
          <div className="payment_remaining">
            <span>Valor restante para pagamento</span>
            <span>
              <Warning /> {formatCurrency(diff)}
            </span>
          </div>
        ) : null}
      </TotalContainer>
      <CustomFooterContainer>
        <Button variant="text" onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          disabled={!values.paymentType}
          variant="contained"
          type="submit"
          data-testid="payment_add"
        >
          Adicionar
        </Button>
      </CustomFooterContainer>
    </>
  );
};
export default DefaultForm;
