import React from 'react';

const SvgStar = () => (
  <svg
    width="44"
    height="43"
    viewBox="0 0 44 43"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.1402 1.70156C20.8071 0.0157888 23.1929 0.0157897 23.8598 1.70157L28.2186 12.7208C28.498 13.4272 29.1535 13.9143 29.9104 13.9781L41.3976 14.9464C43.1427 15.0935 43.8694 17.2534 42.5681 18.4254L33.6714 26.4386C33.1333 26.9233 32.8992 27.6609 33.0595 28.3672L35.7515 40.2335C36.1475 41.9792 34.2265 43.3261 32.7203 42.3588L23.0808 36.168C22.4223 35.7452 21.5777 35.7452 20.9192 36.168L11.2797 42.3588C9.77351 43.3261 7.85248 41.9792 8.24852 40.2335L10.9405 28.3672C11.1008 27.6609 10.8667 26.9233 10.3286 26.4386L1.43187 18.4254C0.13065 17.2534 0.857347 15.0935 2.60237 14.9464L14.0896 13.9781C14.8465 13.9143 15.502 13.4272 15.7814 12.7208L20.1402 1.70156Z"
      fill="#F8F9FA"
    />
  </svg>
);

export default SvgStar;
