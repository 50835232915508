import {
  MaterialUICore,
  tokens,
  Heading as HeadingDS,
  Body as BodyDS,
  Box,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Container = styled(Box)({
  marginTop: tokens.spacing.sm,
});

export const HeadContainer = styled(Box)({
  paddingLeft: tokens.spacing.sm,
  paddingRight: tokens.spacing.sm,
});

export const Heading = styled(HeadingDS)({
  marginBottom: tokens.spacing.base,
});

export const Body = styled(BodyDS)({
  marginBottom: tokens.spacing.base,
});
