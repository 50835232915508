import React from 'react';
import { MaterialIcons } from '@iclinic/design-system';

import { useChatContext } from 'features/video-conference/state/chatContext';
import { buttonsTitle } from 'features/video-conference/constants';
import ToggleButton from 'features/video-conference/components/TeamComponents/ToggleButton';
import { useAnnotationContext } from 'features/video-conference/state/annotationContext';
import { useParticipantContext } from 'features/video-conference/state/participantContext';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';

const { QuestionAnswerOutlined } = MaterialIcons;

export default function ChatButton() {
  const { isPhysician, conferenceId, setIsBackgroundSelectionOpen } =
    useConference();
  const { localParticipant, remoteParticipant } = useParticipantContext();
  const { isChatWindowOpen, setIsChatWindowOpen, hasUnreadMessages } =
    useChatContext();

  const { setIsAnnotationWindowOpen } = useAnnotationContext();

  const toggleChatWindow = () => {
    setIsChatWindowOpen(!isChatWindowOpen);
    setIsAnnotationWindowOpen(false);
    setIsBackgroundSelectionOpen(false);
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'telemedicine_chat',
      buttonLocation: 'toolbar',
      action: isChatWindowOpen ? 'disabled' : 'enabled',
    });
  };

  return (
    <ToggleButton
      onClick={toggleChatWindow}
      switchedOn={!isChatWindowOpen}
      variant="info"
      updateOn={hasUnreadMessages}
      title={buttonsTitle.CHAT}
      label={buttonsTitle.CHAT}
      icon={<QuestionAnswerOutlined />}
      aria-controls="chat"
      dataGa="telemedicine_chat"
      dataSp="telemedicine_chat"
    />
  );
}
