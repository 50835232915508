import { MaterialUICore, tokens } from '@iclinic/design-system';

const {
  Divider: DSDivider,
  Drawer: DSDrawer,
  IconButton,
  styled,
} = MaterialUICore;
const { spacing } = tokens;

export const Drawer = styled(DSDrawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 480,
    },

    [theme.breakpoints.up('md')]: {
      width: 580,
    },
  },
}));

export const Content = styled('div')(({ theme }) => ({
  padding: spacing.sm,
  overflow: 'auto',
  height: '100%',

  [theme.breakpoints.up('sm')]: {
    padding: `${spacing.lg} ${spacing.lg} ${spacing.sm}`,
  },
}));

export const BackIconButton = styled(IconButton)({
  marginLeft: spacing.base,
  marginTop: spacing.base,
  alignSelf: 'flex-start',
});

export const Header = styled('div')(({ theme }) => ({
  marginBottom: spacing.md,
  display: 'flex',
  alignItems: 'center',

  '& > img': {
    marginRight: spacing.sm,
  },

  [theme.breakpoints.up('sm')]: {
    marginBottom: spacing.lg,
  },
}));

export const Divider = styled(DSDivider)(({ theme }) => ({
  marginBottom: spacing.md,

  [theme.breakpoints.up('sm')]: {
    marginBottom: spacing.lg,
  },
}));

export const FoorterWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `0 ${spacing.sm} ${spacing.sm}`,

  [theme.breakpoints.up('sm')]: {
    padding: `0 ${spacing.lg} ${spacing.lg}`,
  },
}));
