import { call, put, takeLatest } from 'redux-saga/effects';

import * as actions from '.';
import * as api from '../../services/messageHistory';

type PayloadFetchMessageHistory = ReturnType<
  typeof actions.fetchMessageHistory
>;

export function* fetchMessageHistoryWorker({
  payload,
}: PayloadFetchMessageHistory) {
  try {
    yield put(actions.fetchMessageHistoryStart());

    const { data: messageHistoryResponse } = yield call(
      api.getMessageHistory,
      payload,
    );

    yield put(actions.fetchMessageHistorySuccess(messageHistoryResponse));
  } catch {
    yield put(actions.fetchMessageHistoryFailure());
  }
}

export default function* messagingMessageHistorySagas() {
  yield takeLatest(actions.fetchMessageHistory, fetchMessageHistoryWorker);
}
