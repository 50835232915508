import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';

import { RequestStatus } from 'shared/constants/State';

export interface Response<R> {
  status: number;
  data: R;
}

export interface Options<R, P = void> {
  stateSelector: (state: any) => any;
  requestFn: (param: P) => Promise<Response<R>>;
  name?: string;
}

export interface RequestState<R, P> {
  data: R | null;
  status: RequestStatus;
  error: string;
  params: P | null;
}

export interface HookOptions {
  lazy: boolean;
}

const defaultHookOpts = {
  lazy: false,
};

const isHookOptions = (opts: any) => typeof opts?.lazy === 'boolean';

export function createRequestApi<R, P = void>(options: Options<R, P>) {
  const { stateSelector, requestFn, name } = options;

  const initialState: RequestState<R, P> = {
    data: null,
    status: RequestStatus.Ready,
    error: '',
    params: null,
  };

  const slice = createSlice({
    name: `restApi/${name || requestFn.name}`,
    initialState,
    reducers: {
      initiate: (_state, action: PayloadAction<P>) => {
        return {
          data: null,
          status: RequestStatus.Pending,
          error: '',
          params: action.payload,
        };
      },
      success: (state, action: PayloadAction<R>) => {
        return {
          ...state,
          status: RequestStatus.Success,
          data: action.payload,
          error: '',
        };
      },
      error: (state, action: PayloadAction<string>) => {
        return {
          ...state,
          status: RequestStatus.Error,
          error: action.payload,
          data: null,
        };
      },
    },
  });

  const { actions, reducer } = slice;

  function* workerSaga({ payload }: { payload: P }) {
    try {
      const { data }: Response<R> = yield call(requestFn, payload);
      yield put(actions.success(data));
    } catch (err: any) {
      yield put(actions.error(err.message));
    }
  }

  function* takeLatestSaga() {
    yield takeLatest(actions.initiate, workerSaga);
  }

  const componentSelector = createSelector(
    stateSelector,
    ({ params, ...rest }: typeof initialState) => {
      const isLoading = rest.status === RequestStatus.Pending;
      return { ...rest, isLoading };
    },
  );

  function useRequestApi(param?: P | HookOptions, hookOptions?: HookOptions) {
    let hookOpts = hookOptions || defaultHookOpts;
    if (!hookOptions && isHookOptions(param)) {
      hookOpts = param as HookOptions;
    }

    const dispatch = useDispatch();
    const request = useCallback((arg?: P) => dispatch(actions.initiate(arg)), [
      dispatch,
    ]);
    const componentState = useSelector(componentSelector);

    useEffect(() => {
      if (!hookOpts.lazy) {
        dispatch(actions.initiate(param));
      }
    }, [param, dispatch, hookOpts.lazy]);

    return { ...componentState, request };
  }

  return {
    useRequestApi,
    reducer,
    takeLatestSaga,
    workerSaga,
    actions,
    initialState,
    componentSelector,
  };
}

export default createRequestApi;
