import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { useRifm } from 'rifm';
import {
  Grid,
  SectionTitle,
  MaterialIcons,
  TextField,
  Box,
} from '@iclinic/design-system';

import { useDidUpdateEffect } from 'shared/hooks';
import BRAZIL_STATES_INITIALS from 'shared/constants/brStates';
import { cepFormatter } from 'shared/utils/formatters';
import { SellerForm } from 'features/onlinePayment/state/config/types';
import { trackSignupIClinicPayForm } from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { fetchZipCode } from 'features/onlinePayment/state/config/register';
import {
  getUserInfo,
  getZipCodeSelector,
  isSellerAlreadyCreated,
} from 'features/onlinePayment/state/config/register/selectors';
import { ResponsiveCard } from 'features/onlinePayment/components';

const { Room } = MaterialIcons;

const errorsZipCode = (errors: boolean, zipCode: boolean) => errors || zipCode;

const helperTextZipCode = (
  touched: boolean | undefined,
  errors: string | undefined,
  zipCode: string | undefined,
) => (touched && errors) || zipCode;

// eslint-disable-next-line sonarjs/cognitive-complexity
export default function CardAddress(): JSX.Element {
  const dispatch = useDispatch();
  const userData = useSelector(getUserInfo);
  const { zipCodeInfo } = useSelector(getZipCodeSelector);
  const disabled = useSelector(isSellerAlreadyCreated);

  const { handleChange, values, errors, touched, setFieldValue } =
    useFormikContext<SellerForm>();

  useDidUpdateEffect(() => {
    if (zipCodeInfo && !zipCodeInfo.status) {
      setFieldValue('city', zipCodeInfo.city || '');
      setFieldValue('street', zipCodeInfo.address || '');
      setFieldValue('state', zipCodeInfo.state || '');
      setFieldValue('neighborhood', zipCodeInfo.neighborhood || '');
    }
  }, [dispatch, zipCodeInfo, setFieldValue]);

  const { value: zipCode, onChange: onChangeZipCode } = useRifm({
    value: values.zipCode,
    onChange: (value: string) => {
      setFieldValue('zipCode', value);
      if (value.length === 9) {
        dispatch(fetchZipCode(value));
      }
    },
    format: cepFormatter,
  });

  const handleBlur = (
    flowType: string,
    stepName: string,
    stepNum: number,
    stepValue: string,
  ) => {
    trackSignupIClinicPayForm({
      userData,
      flowType,
      stepName,
      stepNum,
      stepValue,
    });
  };

  return (
    <ResponsiveCard variant="outlined">
      <Box mb={2}>
        <SectionTitle icon={<Room />} title="Endereço" mb={1} />
      </Box>
      <Grid direction="column" spacing={3} container>
        <Grid item xs={12} md={2}>
          <TextField
            id="zipCode"
            name="zipCode"
            label="Cep"
            value={zipCode}
            onChange={onChangeZipCode}
            onBlur={() => handleBlur(values.type, 'user_cep', 8, zipCode)}
            error={errorsZipCode(
              !!(errors.zipCode && touched.zipCode),
              !!zipCodeInfo?.msg,
            )}
            helperText={helperTextZipCode(
              touched.zipCode,
              errors.zipCode,
              zipCodeInfo?.msg,
            )}
            autoComplete="none"
            disabled={disabled}
            fullWidth
          />
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={10}>
            <TextField
              id="street"
              name="street"
              label="Endereço"
              value={values.street}
              onChange={handleChange}
              onBlur={() =>
                handleBlur(values.type, 'user_address', 9, values.street)
              }
              error={!!(errors.street && touched.street)}
              helperText={touched.street && errors.street}
              disabled={disabled || !zipCodeInfo}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="number"
              name="number"
              label="Nº"
              value={values.number}
              onChange={handleChange}
              onBlur={() =>
                handleBlur(values.type, 'user_h_number', 10, values.number)
              }
              error={!!(errors.number && touched.number)}
              helperText={touched.number && errors.number}
              disabled={disabled || !zipCodeInfo}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={5}>
            <TextField
              id="neighborhood"
              name="neighborhood"
              label="Bairro"
              value={values.neighborhood}
              onChange={handleChange}
              onBlur={() =>
                handleBlur(
                  values.type,
                  'user_neighbor',
                  11,
                  values.neighborhood,
                )
              }
              error={!!(errors.neighborhood && touched.neighborhood)}
              helperText={touched.neighborhood && errors.neighborhood}
              disabled={disabled || !zipCodeInfo}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              id="city"
              name="city"
              label="Cidade"
              value={values.city}
              onChange={handleChange}
              onBlur={() =>
                handleBlur(values.type, 'user_city', 12, values.city)
              }
              error={!!(errors.city && touched.city)}
              helperText={touched.city && errors.city}
              disabled={disabled || !zipCodeInfo}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              id="state"
              name="state"
              label="Estado"
              select
              value={values.state}
              onChange={handleChange}
              onBlur={() =>
                handleBlur(values.type, 'user_state', 13, values.state)
              }
              error={!!(errors.state && touched.state)}
              helperText={touched.state && errors.state}
              disabled={disabled || !zipCodeInfo}
              fullWidth
            >
              <option value="">Escolha</option>
              {BRAZIL_STATES_INITIALS.map((state) => (
                <option value={state} key={state}>
                  {state}
                </option>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={12}>
            <TextField
              id="complement"
              name="complement"
              label="Complemento"
              value={values.complement}
              onChange={handleChange}
              onBlur={() =>
                handleBlur(
                  values.type,
                  'user_complement',
                  14,
                  values.complement,
                )
              }
              error={!!(errors.complement && touched.complement)}
              helperText={touched.complement && errors.complement}
              disabled={disabled || !zipCodeInfo}
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
    </ResponsiveCard>
  );
}
