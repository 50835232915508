// External
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Form, FormikProps } from 'formik';

// Internal
import { StoreState } from 'state/rootReducer';
import Dropdown from 'ui/new/dropdown';
import Button from 'ui/new/button';
import * as actions from 'state/billing/subscription/actions';
import {
  getSelectedProducts,
  getCheckoutValidation,
  displayBackButton,
  getProductDetail,
} from 'state/billing/subscription/selectors';
import { showTextNextSteper } from 'features/billing/utils';
import Modal from 'ui/new/modal';
import LoaderCircle from 'ui/new/loader-circle/LoaderCircle';
import Steper from 'ui/new/steper/Steper';
import { STEPS, TITLE_MODAL } from 'shared/constants/Billing';
import { PRODUCT_SUBSCRIPTION } from 'state/subscription/constants';
import { normalizeCheck } from 'state/billing/subscription/utils';
import SelectUsers from '../../components/selectUsers/SelectUsers';
import CheckSelectUsers from '../../components/checkSelectUsers/CheckSelectUsers';
import SubscriptionSummary from './Summary';
import SubscriptionCardWrapper from '../../components/subscriptionCardWrapper';
import SubscriptionEnhance, { FormValues } from './SubscriptionEnhance';
import style from './SubscriptionFlowModal.scss';
import SuccessModal from '../components/SuccessModal';

const mapStateToProps = (state: StoreState) => ({
  selectedUsersWithProduct: state.billing.subscription.selectedUsersWithProduct,
  physicians: state.billing.subscription.physicians,
  step: state.billing.subscription.step,
  currentAddon: state.billing.subscription.currentAddon,
  isMonthly: state.billing.subscription.isMonthly,
  addonsProducts: getSelectedProducts(state, PRODUCT_SUBSCRIPTION.addons),
  checkout: state.billing.subscription.checkout,
  isFlipped: state.billing.subscription.isFlipped,
  // eslint-disable-next-line react/no-unused-prop-types
  hasError: state.billing.subscription.hasError,
  isFetching: state.billing.subscription.isFetching,
  isValidate: getCheckoutValidation(state),
  selectedAddons: state.billing.subscription.selectedAddons,
  steps: state.billing.subscription.steps,
  progressStep: state.billing.subscription.progressStep,
  isButtonAvailable: displayBackButton(state),
});

const mapDispatchToProps = actions;
const iconPath = 'whiteCards';

type MapStateToProps = ReturnType<typeof mapStateToProps>;
type MapDispatchToProps = typeof mapDispatchToProps;
type SubscriptionProps = MapStateToProps &
  MapDispatchToProps &
  FormikProps<FormValues>;

// eslint-disable-next-line sonarjs/cognitive-complexity
function SubscriptionFlowModal({
  // store
  physicians,
  step,
  currentAddon,
  isMonthly,
  selectedAddons,
  checkout,
  isFlipped,
  isFetching,
  steps,
  selectedUsersWithProduct,
  progressStep,
  // actions
  closeModal,
  setStep,
  selectPhysician,
  verificationNextStep,
  verificationBackStep,
  fetchCheckout,
  // selectors
  addonsProducts,
  isValidate,
  isButtonAvailable,
  // props
  isOpenModal,
}: SubscriptionProps): JSX.Element {
  const [addonDetail, setAddonDetail] = useState({
    name: '',
    productValue: '',
    productDiscount: '',
    productDiscountTotal: null,
    productValueTotal: null,
  });

  const [userWithProductsIds, setuserWithProductsIds] = useState([]);

  const checkPhysicianStep = useCallback(() => {
    if (selectedUsersWithProduct && selectedUsersWithProduct[currentAddon]) {
      setuserWithProductsIds(selectedUsersWithProduct[currentAddon]);
      userWithProductsIds.forEach((id) => selectPhysician(id));
    }
  }, [
    currentAddon,
    selectPhysician,
    selectedUsersWithProduct,
    userWithProductsIds,
  ]);

  useEffect(() => {
    if (currentAddon) {
      const addonsValues = getProductDetail(
        isMonthly,
        currentAddon,
        physicians,
      )(addonsProducts);
      setAddonDetail(addonsValues);
    }
  }, [addonsProducts, currentAddon, isMonthly, physicians]);

  useEffect(() => {
    if (!userWithProductsIds) checkPhysicianStep();
  }, [checkPhysicianStep, userWithProductsIds]);

  const physiciansName = useMemo(
    () => normalizeCheck(physicians, 'name', false),
    [physicians],
  );

  const physiciansSize = useMemo(
    () => normalizeCheck(physicians, 'profile_id', false).length,
    [physicians],
  );

  const physiciansDrop = useMemo(
    () =>
      physicians.map((item) => (
        <Dropdown.Item
          key={item.profile_id}
          kind="exists"
          value={item.profile_id}
          onClick={() => selectPhysician(item.profile_id)}
        >
          <CheckSelectUsers
            name={item.name}
            checked={item.checked}
            onChange={() => selectPhysician(item.profile_id)}
          />
        </Dropdown.Item>
      )),
    [physicians, selectPhysician],
  );

  const modalContents = {
    [STEPS.selectUsers.value]: (
      <SelectUsers
        placeholder={
          physiciansName.length > 0
            ? physiciansName.toString()
            : 'Seleção de usuários'
        }
        totalUsers={physiciansSize}
        productValue={addonDetail.productDiscount || addonDetail.productValue}
        productName={addonDetail.name}
        promotionTotalValue={addonDetail.productDiscountTotal}
        totalValue={addonDetail.productValueTotal}
      >
        {physiciansDrop}
      </SelectUsers>
    ),
    [STEPS.summary.value]: <SubscriptionSummary />,
    [STEPS.payment.value]: (
      <SubscriptionCardWrapper
        checkout={checkout}
        iconPath={iconPath}
        isFlipped={isFlipped}
      />
    ),
  };

  if (step === STEPS.success.value) {
    return (
      <Modal width={534} show={isOpenModal} onClose={closeModal}>
        <SuccessModal />
      </Modal>
    );
  }

  return (
    <Form>
      <Modal show={isOpenModal} onClose={closeModal}>
        <Modal.Body>
          {step !== STEPS.success.value && (
            <div className={style['step-loader']}>
              <LoaderCircle
                radius={37}
                stroke={3}
                progress={(100 / (steps - 1)) * progressStep}
              >
                {`${progressStep} de ${steps - 1}`}
              </LoaderCircle>
              <Steper
                currentStep={TITLE_MODAL[step]}
                nextStep={showTextNextSteper(
                  selectedAddons as string[],
                  currentAddon,
                  step,
                )}
              />
            </div>
          )}

          {modalContents[step]}
        </Modal.Body>
        {step === STEPS.selectUsers.value && (
          <Modal.Footer
            align={
              isButtonAvailable &&
              physicians.length > 1 &&
              selectedAddons.length >= 1
                ? 'split'
                : 'right'
            }
          >
            {isButtonAvailable &&
              physicians.length > 1 &&
              selectedAddons.length >= 1 && (
                <Button type="link" href="#" onClick={verificationBackStep}>
                  Voltar
                </Button>
              )}
            <Button
              onClick={verificationNextStep}
              disabled={physiciansSize === 0}
            >
              PROSSEGUIR
            </Button>
          </Modal.Footer>
        )}
        {step === STEPS.summary.value && (
          <Modal.Footer
            align={
              physicians.length > 1 && selectedAddons.length >= 1
                ? 'split'
                : 'right'
            }
          >
            {physicians.length > 1 && selectedAddons.length >= 1 && (
              <Button
                type="link"
                href="#"
                onClick={() => setStep(step - 1, 'prev')}
              >
                Voltar
              </Button>
            )}
            <Button onClick={() => setStep(step + 1, 'next')}>
              PROSSEGUIR
            </Button>
          </Modal.Footer>
        )}
        {step === STEPS.payment.value && (
          <Modal.Footer align="split">
            <Button
              type="link"
              href="#"
              onClick={() => setStep(step - 1, 'prev')}
            >
              Voltar
            </Button>
            <Button
              disabled={!isValidate}
              onClick={fetchCheckout}
              loading={isFetching}
              submit
            >
              CONFIRMAR ASSINATURA
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </Form>
  );
}

export default connect<MapStateToProps, MapDispatchToProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SubscriptionEnhance(SubscriptionFlowModal));
