import React, { useEffect } from 'react';

import {
  Snackbar as SnackbarDS,
  MaterialIcons,
  MaterialUICore,
  Body,
} from '@iclinic/design-system';
import { SnackbarProps } from 'features/tissInvoicing/types';
import * as Styles from './styles';

const { CheckCircle, Info, Warning, Error, Close } = MaterialIcons;
const { IconButton } = MaterialUICore;

interface Message extends Omit<SnackbarProps, 'severity'> {
  key: number | string;
}

interface ConsecutiveSnackbarProps {
  data: SnackbarProps;
  onClose?: () => void;
}

export default function ConsecutiveSnackbar({
  data: { severity, message },
  onClose = () => {},
}: ConsecutiveSnackbarProps): JSX.Element {
  const classes = Styles.useStyles();

  const [snackPack, setSnackPack] = React.useState<Message[]>([]);
  const [open, setOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState<Message | undefined>(
    undefined,
  );

  const Icon = () => {
    switch (severity) {
      case 'success':
        return <CheckCircle />;
      case 'error':
        return <Error />;
      case 'info':
        return <Info />;
      case 'warning':
        return <Warning />;
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (!message) {
      setSnackPack([]);
      return;
    }

    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
  }, [message]);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleClose = () => {
    setOpen(false);
    setMessageInfo(undefined);
    onClose();
  };

  if (!messageInfo) return <></>;

  return (
    <SnackbarDS
      key={messageInfo?.key || undefined}
      open={open}
      severity={severity}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      autoHideDuration={6000}
      onClose={handleClose}
      data-testid="snackbar-component"
      message={
        <>
          <Icon />
          <Body variant="xs" bold>
            {messageInfo?.message}
          </Body>
        </>
      }
      action={[
        <IconButton key="close" aria-label="close" onClick={handleClose}>
          <Close classes={{ root: classes.closeButton }} />
        </IconButton>,
      ]}
    />
  );
}
