import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'center',
});

export const Title = styled('h3')({
  fontWeight: axiomTokens.font.weight.bold,
  fontSize: axiomTokens.font.size.md,
  letterSpacing: axiomTokens.font.letterSpacing.sm,
  color: axiomTokens.color.typeface.pure,
  margin: `0 0 ${axiomTokens.spacing[2]}`,
});

export const Text = styled('p')({
  fontWeight: axiomTokens.font.weight.regular,
  fontSize: axiomTokens.font.size.sm,
  letterSpacing: axiomTokens.font.letterSpacing.sm,
  color: axiomTokens.color.typeface.pure,
  marginBottom: axiomTokens.spacing[4],
});
