import React from 'react';
import { Body, Heading, Loader, MaterialUICore } from '@iclinic/design-system';
import { useSelector } from 'react-redux';

import { getShortUrls, isFetching } from 'features/referrals/state/selectors';
import { HowItWorkCardInfo } from '../../types';
import * as S from './HowItWorks.styles';
import { canShare } from 'shared/utils/webShare';
import ShareUrl from '../ShareUrl/ShareUrl';

const { useTheme, useMediaQuery } = MaterialUICore;
interface HowItWorksProps {
  cardsInfo: HowItWorkCardInfo[];
}

const HowItWorks = ({ cardsInfo }: HowItWorksProps) => {
  const isFetchingShortUrl = useSelector(isFetching);
  const shortUrls = useSelector(getShortUrls);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const whatsappUrl = shortUrls?.whatsapp || '';
  const isShareable = isMobile && canShare({ url: whatsappUrl });

  return (
    <S.Section>
      <S.Content>
        <Heading variant="lg" inverseTextColor align="center">
          Ganhar os benefícios é muito fácil
        </Heading>

        <S.CardsWrapper>
          {cardsInfo.map(({ title, imagePath, content, position }) => (
            <S.Card key={title}>
              <S.CardContent>
                <S.CardTitle variant="sm" bold>
                  {title}
                </S.CardTitle>

                <img src={imagePath} alt={title} />

                <Body variant="xs" align="center">
                  {content}
                </Body>
              </S.CardContent>

              <S.Number variant="sm">{position}</S.Number>
            </S.Card>
          ))}
        </S.CardsWrapper>
      </S.Content>
      <Heading align="center" inverseTextColor variant="md">
        Convide seus amigos médicos!
      </Heading>
      <S.ShareWrapper>
        {isFetchingShortUrl ? (
          <S.LoaderWrapper>
            <Loader data-testid="share-loader" />
          </S.LoaderWrapper>
        ) : (
          <ShareUrl
            dataGa="referrals_detail_cta_bottom_click"
            isShareable={isShareable}
          />
        )}
      </S.ShareWrapper>
    </S.Section>
  );
};

export default HowItWorks;
