import { Autocomplete, Grid, TextField, tokens } from '@iclinic/design-system';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'features/tissInvoicing/state/expense';
import { getExpenseAutocompleteState } from 'features/tissInvoicing/state/expense/selectors';
import { FetchExpenseAutocompletePayload } from 'features/tissInvoicing/types';
import { useDebounce } from 'shared/hooks/debounce';
import { IAutocompleteOption } from '../../interfaces/AutocompleteOption';

interface IProps {
  onChange(name: string, value: IAutocompleteOption): void;
  healthInsurance?: IAutocompleteOption;
  procedure?: IAutocompleteOption;
  showErrors: boolean;
}

const AddExpenseHeaderInputs = ({
  onChange,
  procedure,
  healthInsurance,
  showErrors = false,
}: IProps) => {
  const [procedureSearchText, setProcedureSearchText] = useState('');

  const debounceProcedureText = useDebounce(procedureSearchText, 600);

  const { procedureOptions = [], healthInsuranceOptions = [] } = useSelector(
    getExpenseAutocompleteState,
  );

  const dispatch = useDispatch();

  const onChangeAutocompleteText = useCallback(
    (payload: FetchExpenseAutocompletePayload) => {
      dispatch(actions.fetchExpenseAutocomplete(payload));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!debounceProcedureText?.length) {
      return;
    }

    onChangeAutocompleteText({
      endpoint: '/domains/tuss-procedure',
      name: 'procedureOptions',
      isTissApi: true,
      params: {
        representation: debounceProcedureText,
      },
    });
  }, [debounceProcedureText, onChangeAutocompleteText]);

  const hasErrors = (name: string): boolean => {
    if (!showErrors) {
      return false;
    }

    const state: any = { procedure, healthInsurance };

    return !state[name]?.title;
  };

  return (
    <Grid container spacing={2} style={{ marginBottom: tokens.spacing.xs }}>
      <Grid item xs={12} md={6}>
        <Autocomplete
          options={procedureOptions}
          getOptionLabel={(option: any) => option.title}
          renderInput={(params: any) => (
            <TextField
              label="Procedimento*"
              error={hasErrors('procedure')}
              {...params}  
            />
          )}
          onChange={(_, value: any) => onChange('procedure', value)}
          value={procedure}
          onInputChange={(_, text: string, reason: string) => {
            const searchText = reason === 'input' ? text : ' ';
            setProcedureSearchText(searchText);
          }}
          noOptionsText="Nenhum resultado encontrado"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Autocomplete
          options={healthInsuranceOptions}
          getOptionLabel={(option: any) => option.title}
          renderInput={(params: any) => (
            <TextField
              label="Convênio"
              error={hasErrors('healthInsurance')}
              {...params}  
            />
          )}
          onChange={(_, value: any) => onChange('healthInsurance', value)}
          value={healthInsurance}
          noOptionsText="Nenhum resultado encontrado"
        />
      </Grid>
    </Grid>
  );
};

export default AddExpenseHeaderInputs;
