import { Button, MaterialIcons } from '@iclinic/design-system';
import React from 'react';
import { useSelector } from 'react-redux';

import { patientInfo } from 'state/records/selectors';
import {
  getPhoneNumber,
  getUserInfo,
  getElectronicSignatureState,
} from '../../../state/selectors';
import { removeUnecessaryCharacters } from '../../../utils';
import * as S from './SendToPatientContent.styles';

const { WhatsApp, ContentCopyRounded } = MaterialIcons;

type SnackbarProps = {
  onOpenSnackbar: () => void;
};

export const SendToPatientContent = ({ onOpenSnackbar }: SnackbarProps) => {
  const phoneNumber = useSelector(getPhoneNumber);
  const patient = useSelector(patientInfo);
  const { userName } = useSelector(getUserInfo);
  const { signatureLink } = useSelector(getElectronicSignatureState);

  const msgWhatsApp = `Olá, ${patient.name}. Segue abaixo o link do documento emitido por ${userName} a ser lido e assinado por você: ${signatureLink}.`;

  const handleClickWhatsApp = () => {
    window.open(
      `https://wa.me/55${removeUnecessaryCharacters(
        phoneNumber,
      )}?text=${encodeURIComponent(msgWhatsApp)}`,
      '_blank',
    );
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(signatureLink || '')
      .finally(() => onOpenSnackbar());
  };

  return (
    <>
      <S.TextSendMsgSignatory variant="xs" align="center">
        Tudo certo! Agora é só enviar o link para o signatário.
      </S.TextSendMsgSignatory>
      <S.ContainerButtonsAndMessage>
        <S.ContainerChooseSendForm>
          <S.LineDivider />
          <S.TextChooseSendForm variant="xxs">
            Escolha a forma de envio
          </S.TextChooseSendForm>
          <S.LineDivider />
        </S.ContainerChooseSendForm>
        <S.ContainerButtons>
          <S.ButtonChooseSendForm>
            <Button
              variant="contained"
              size="small"
              fullWidth
              color="secondary"
              startIcon={<ContentCopyRounded />}
              onClick={copyToClipboard}
              data-ga="medical_record_electronic_signature_copy_link"
              data-sp="medical_record_electronic_signature_copy_link"
            >
              Copiar link
            </Button>
          </S.ButtonChooseSendForm>
          <S.ButtonChooseSendForm>
            <Button
              variant="contained"
              size="small"
              fullWidth
              color="secondary"
              startIcon={<WhatsApp />}
              onClick={handleClickWhatsApp}
              data-ga="medical_record_electronic_signature_send_whatsapp"
              data-sp="medical_record_electronic_signature_send_whatsapp"
            >
              Enviar via whatsapp
            </Button>
          </S.ButtonChooseSendForm>
        </S.ContainerButtons>
      </S.ContainerButtonsAndMessage>
    </>
  );
};
