import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StoreState } from 'state/rootReducer';
import { togglePaymentsControlDrawer } from 'state/schedule/actions';
import TransactionsList from 'features/onlinePayment/containers/reports/transactions/list/TransactionsList';
import { InfoDrawer } from 'features/onlinePayment/components';

const ControlPaymentsMedicalAgenda = (): JSX.Element => {
  const isOpen = useSelector(
    (state: StoreState) => state.schedule.isPaymentsControlDrawerOpen,
  );
  const dispatch = useDispatch();

  const closeDrawer = () => {
    dispatch(togglePaymentsControlDrawer());
  };

  return (
    <InfoDrawer
      title="Controle de pagamento"
      open={isOpen}
      onClose={closeDrawer}
      width="568px"
    >
      <TransactionsList isShowDetailButton={false} />
    </InfoDrawer>
  );
};

export default ControlPaymentsMedicalAgenda;
