import React from 'react';
import classnames from 'classnames/bind';

import style from './Column.scss';

const classes = classnames.bind(style);

type ColumnProps = {
  children: React.ReactNode;
  className: string;
};

export default function Column({
  children,
  className = '',
  ...rest
}: ColumnProps): JSX.Element {
  return (
    <div className={classes('column', className)} {...rest}>
      {children}
    </div>
  );
}
