import iClinicApi from 'services/iclinic/iClinicApi';
import { SubscribeData } from '../types';

export const getPlans = () => iClinicApi.get(`/products/products-checkout/`);

export const getPhysiciansByClinic = (clinicId: number) =>
  iClinicApi.get(`/clinics/clinic/physicians-by-clinic/${clinicId}`);

export const postSubscribe = (subscribeData: SubscribeData) =>
  iClinicApi.post('/subscriptions/subscription/subscribe/', subscribeData);
