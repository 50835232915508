import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useRifm } from 'rifm';
import { useSelector } from 'react-redux';
import {
  MaterialIcons,
  Grid,
  TextField,
  DatePicker,
} from '@iclinic/design-system';

import councils from 'shared/constants/councils';
import BRAZIL_STATES_INITIALS from 'shared/constants/brStates';
import { FILE_EXTENSIONS } from 'shared/utils/file';
import { isValidDate } from 'shared/utils/date';
import {
  phoneFormatter,
  cpfFormatter,
  limitNumbers,
} from 'shared/utils/formatters';
import CBOAutocomplete from 'features/tuss/cbo/components/CBOAutocomplete';
import { trackSignupIClinicPayForm } from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { SellerForm } from 'features/onlinePayment/state/config/types';
import { listCBOSelector } from 'features/tuss/cbo/state/selectors';
import {
  isSellerAlreadyCreated,
  getUserInfo,
} from 'features/onlinePayment/state/config/register/selectors';
import useStyles from './CardProfile.styles';
import Upload, { UploadData } from '../Upload';

const { JPEG, JPG, PNG } = FILE_EXTENSIONS;
const pictureExtensions = [JPEG, JPG, PNG];

const { Person } = MaterialIcons;

const stateOptions = () => (
  <>
    {BRAZIL_STATES_INITIALS.map((state) => (
      <option value={state} key={state}>
        {state}
      </option>
    ))}
  </>
);

const councilNameOptions = () => (
  <>
    {councils.map((council) => (
      <option key={council.id} value={council.label}>
        {council.label}
      </option>
    ))}
  </>
);

/** TODO fix this */
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function CardProfilePF(): JSX.Element {
  const classes = useStyles();
  const isSellerCreated = useSelector(isSellerAlreadyCreated);
  const userData = useSelector(getUserInfo);
  const { objects: cboList } = useSelector(listCBOSelector);

  const { handleChange, values, errors, touched, setFieldValue } =
    useFormikContext<SellerForm>();

  const renderStateOptions = stateOptions();
  const renderCouncilNameOptions = councilNameOptions();

  const { value: phone, onChange: onChangePhone } = useRifm({
    value: values.phone,
    onChange: (value: string) => setFieldValue('phone', value),
    format: phoneFormatter,
  });

  const { value: cpf, onChange: onChangeCpf } = useRifm({
    value: values.cpf,
    onChange: (value: string) => setFieldValue('cpf', value),
    format: cpfFormatter,
  });

  const { value: councilNumber, onChange: onChangeCouncilNumber } = useRifm({
    value: values.councilNumber,
    onChange: (value: string) => setFieldValue('councilNumber', value),
    format: (str) => limitNumbers(str, 10),
  });

  const handleChangeBirthdate = (date: Date | null) => {
    setFieldValue('birthdate', date);
    if (!date) return;
    if (!isValidDate(date)) return;

    trackSignupIClinicPayForm({
      userData,
      flowType: 'physical_person',
      stepName: 'user_birth',
      stepNum: 6,
      stepValue: date.toISOString(),
    });
  };

  const handleUploadFile = (file: UploadData) => {
    setFieldValue('picture', file.base64);
    trackSignupIClinicPayForm({
      userData,
      flowType: 'physical_person',
      stepName: 'user_profile',
      stepNum: 1,
      stepValue: file.file.name,
    });
  };

  const handleChangeCBO = (stepValue: string) => {
    trackSignupIClinicPayForm({
      userData,
      flowType: 'physical_person',
      stepName: 'user_specialty',
      stepNum: 4,
      stepValue,
    });
  };

  const handleBlur = (stepName: string, stepNum: number, stepValue: string) => {
    trackSignupIClinicPayForm({
      userData,
      flowType: 'physical_person',
      stepName,
      stepNum,
      stepValue,
    });
  };

  useEffect(() => {
    if (!isSellerCreated && values.name) {
      setFieldValue('name', '');
    }
  }, [isSellerCreated]);

  return (
    <Grid direction="column" spacing={3} container>
      <Grid item>
        <Upload
          icon={<Person className={classes.iconProfileImage} />}
          value={values.picture || values.initialPicture}
          placeholder="Adicione uma foto ao seu perfil"
          name="picture"
          onChange={handleUploadFile}
          extensions={pictureExtensions}
          error={!!(errors.picture && touched.picture)}
          helperText={touched.picture && errors.picture}
          disabled={isSellerCreated}
        />
      </Grid>
      <Grid direction="column" spacing={3} item container>
        <Grid item xs={12} sm={12}>
          <TextField
            id="name"
            name="name"
            label="Nome completo"
            placeholder="Igual ao documento"
            onChange={handleChange}
            onBlur={() => handleBlur('user_full_name', 2, values.name)}
            value={values.name}
            error={!!(errors.name && touched.name)}
            helperText={touched.name && errors.name}
            disabled={isSellerCreated}
            fullWidth
          />
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={8}>
            <TextField
              id="email"
              name="email"
              label="E-mail"
              onChange={handleChange}
              onBlur={() => handleBlur('user_email', 3, btoa(values.email))}
              value={values.email}
              error={!!(errors.email && touched.email)}
              helperText={touched.email && errors.email}
              disabled={isSellerCreated}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="cpf"
              name="cpf"
              label="CPF"
              placeholder="Somente números"
              onChange={onChangeCpf}
              onBlur={() => handleBlur('user_cpf', 5, '')}
              value={cpf}
              error={!!(errors.cpf && touched.cpf)}
              helperText={
                (touched.cpf && errors.cpf) ||
                'Esse dado não é mostrado aos pacientes'
              }
              disabled={isSellerCreated}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={6}>
            <DatePicker
              id="birthdate"
              name="birthdate"
              label="Data de Nascimento"
              value={values.birthdate}
              onChange={handleChangeBirthdate}
              error={!!(errors.birthdate && touched.birthdate)}
              helperText={touched.birthdate && errors.birthdate}
              disabled={isSellerCreated}
              allowKeyboard
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="phone"
              name="phone"
              label="Telefone da clínica"
              placeholder="Somente números"
              onChange={onChangePhone}
              onBlur={() => handleBlur('user_phone', 7, phone)}
              value={phone}
              error={!!(errors.phone && touched.phone)}
              helperText={
                (touched.phone && errors.phone) || 'Sem traços ou separações'
              }
              disabled={isSellerCreated}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={4}>
            <TextField
              id="councilName"
              name="councilName"
              label="Conselho"
              placeholder="Escolha"
              onChange={handleChange}
              value={values.councilName}
              error={!!(errors.councilName && touched.councilName)}
              helperText={touched.councilName && errors.councilName}
              disabled={isSellerCreated}
              select
              fullWidth
            >
              <option value="">Escolha</option>
              {renderCouncilNameOptions}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="councilNumber"
              name="councilNumber"
              label="Registro"
              placeholder="Somente o nº"
              onChange={onChangeCouncilNumber}
              value={councilNumber}
              error={!!(errors.councilNumber && touched.councilNumber)}
              helperText={touched.councilNumber && errors.councilNumber}
              disabled={isSellerCreated}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="councilRegion"
              name="councilRegion"
              label="UF"
              placeholder="Escolha"
              onChange={handleChange}
              value={values.councilRegion}
              error={!!(errors.councilRegion && touched.councilRegion)}
              helperText={touched.councilRegion && errors.councilRegion}
              disabled={isSellerCreated}
              select
              fullWidth
            >
              <option value="">Escolha</option>
              {renderStateOptions}
            </TextField>
          </Grid>
        </Grid>
        <Grid spacing={3} item container>
          <Grid item xs={12} sm={6}>
            <CBOAutocomplete
              nameField="cbo"
              optionsList={cboList}
              onChangeCBO={handleChangeCBO}
              disabled={isSellerCreated}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
