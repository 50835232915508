import React from 'react';
import { Button } from '@iclinic/design-system';

import { Container, Title, Text } from './IntegrationError.styles';

export type IntegrationErrorProps = {
  onClickTry: () => Promise<void>;
  isLoading: boolean;
};

const IntegrationError = ({ onClickTry, isLoading }: IntegrationErrorProps) => (
  <Container data-testid="integration-error-component">
    <Title>Houve uma falha de integração com a IA.</Title>
    <Text>Não foi possível efetuar a análise. Tente novamente mais tarde.</Text>
    <Button onClick={onClickTry} isLoading={isLoading}>
      Tentar novamente
    </Button>
  </Container>
);

export default IntegrationError;
