import React from 'react';
import { Typography } from '@iclinic/design-system';

import useStyles from './Subtitle.styles';

type Props = {
  children: React.ReactNode;
};

export default function Subtitle({ children }: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Typography variant="subtitle2" classes={{ subtitle2: classes.subTitle }}>
      {children}
    </Typography>
  );
}
