export const configurationRoutes = {
  default: '/pagamento/configuracoes',
  register: '/pagamento/configuracoes/cadastro',
  edit: '/pagamento/configuracoes/edit',
  success: '/pagamento/configuracoes/sucesso',
  emails: '/pagamento/configuracoes/emails',
  profile: '/pagamento/configuracoes/profile',
};

export const CBO_MEDICO_CLINICO = {
  term: 'Médico clínico',
  cbo: 46,
};
