import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Heading } from '@iclinic/design-system';

import { monetaryFormatter } from 'shared/utils/formatters';
import { getSummaryData } from '../../utils/summary';
import {
  getSelectedPlan,
  getSelectedAddons,
  getPhysicians,
  getPhysicianByAddon,
} from '../../state/plans/selectors';
import * as S from './MobileFooterSummary.styles';

interface MobileFooterSummaryProps {
  isAnnual: boolean;
  onPaymentClick?: () => void;
}

const MobileFooterSummary = ({
  isAnnual,
  onPaymentClick,
}: MobileFooterSummaryProps) => {
  const selectedPlan = useSelector(getSelectedPlan);
  const selectedAddons = useSelector(getSelectedAddons);
  const physicians = useSelector(getPhysicians);
  const physicianByAddon = useSelector(getPhysicianByAddon);

  const { totalValue } = getSummaryData({
    isAnnual,
    addons: selectedAddons,
    plan: selectedPlan,
    physicians,
    physicianByAddon,
  });

  return (
    <S.MobileCard>
      <div>
        <S.DescriptionTitle variant="sm"> Total </S.DescriptionTitle>
        <Heading variant="sm">
          R$ {monetaryFormatter(totalValue)}/{isAnnual ? 'ano' : 'mês'}
        </Heading>
      </div>

      <Button
        color="primary"
        onClick={onPaymentClick}
        size="medium"
        disabled={!selectedPlan}
        data-ga="checkout_payment_modal_open_click"
      >
        Ir para pagamento
      </Button>
    </S.MobileCard>
  );
};

export default MobileFooterSummary;
