// External
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Internal
import { MEMED_TARGET_ID } from 'shared/constants/services';
import { fetchSdk as fetchSdkAction } from 'state/records/memed/actions';
import style from './TabPrescription.scss';

class TabPrescription extends React.Component {
  componentDidMount() {
    const { fetchSdk } = this.props;

    fetchSdk();
  }

  render() {
    const { completed } = this.props;

    return (
      completed && (
        <div className={style['memed-container']} id={MEMED_TARGET_ID} />
      )
    );
  }
}

TabPrescription.propTypes = {
  fetchSdk: PropTypes.func.isRequired,
  completed: PropTypes.bool.isRequired,
};

const mapStateToProps = ({
  records: {
    memed: { completed },
  },
}) => ({ completed });

export default connect(mapStateToProps, { fetchSdk: fetchSdkAction })(
  TabPrescription,
);
