import { createSelector } from '@reduxjs/toolkit';

import { StoreState } from 'state/rootReducer';

export const listCBOState = (state: StoreState) => state.cbo.listCBO;

export const listCBOSelector = createSelector(listCBOState, (cboData) => {
  return {
    ...cboData,
    objects: [{ active: false, term: '', id: 0, cbo: 0 }, ...cboData.objects],
  };
});
