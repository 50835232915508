import axios from 'axios';

import { PromiseResponse } from '@types';
import { ZipCodeRawData } from './types';
import { interceptorResponseErrorsPromise } from '../interceptors';

export const apiEasyZipCode = axios.create({
  baseURL: process.env.ICLINIC_APP_URL,
});

apiEasyZipCode.interceptors.response.use(
  undefined,
  interceptorResponseErrorsPromise,
);

export const fetchInfosZipCode = (
  cep: string,
): PromiseResponse<ZipCodeRawData> =>
  apiEasyZipCode.get(`/easyzipcode/?zip_code=${cep}`);
