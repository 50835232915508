import React from 'react';
import { useFormikContext } from 'formik';
import { DatePicker } from '@iclinic/design-system';

import { AppointmentGuideFormValues } from 'features/tissInvoicing/types/appointmentGuide';

type Identifiers = keyof AppointmentGuideFormValues;

interface DatePickerProps {
  label: string;
  identifier: Identifiers;
  required?: boolean;
}

function DatePickerAppointment({
  label,
  identifier,
  required = false,
}: DatePickerProps) {
  const { setFieldValue, values, errors, touched, handleBlur } =
    useFormikContext<AppointmentGuideFormValues>();

  const handleChangeDate = (date: Date | null) => {
    setFieldValue(identifier, date);
  };

  return (
    <DatePicker
      label={label}
      id={identifier}
      name={identifier}
      placeholder="DD/MM/AAAA"
      value={values[identifier]}
      onChange={handleChangeDate}
      onBlur={handleBlur}
      error={!!errors[identifier]}
      helperText={touched[identifier] && errors[identifier]}
      required={required}
      allowKeyboard
      fullWidth
    />
  );
}

export default DatePickerAppointment;
