import { combineReducers } from 'redux';

import plans, { PlansState } from './plans';

export type CheckoutState = {
  plans: PlansState;
};

const checkoutReducer = combineReducers<CheckoutState>({
  plans,
});

export default checkoutReducer;
