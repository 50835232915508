import { combineReducers, createAction } from '@reduxjs/toolkit';

import ScheduleProcedures from './procedures';
import SchedulePatient from './patient';
import SchedulePayment from './payment';

export const closePostPaymentScheduleModal = createAction(
  `closePostPaymentScheduleModal`,
);
export const resetPostPaymentScheduleState = createAction(
  `resetPostPaymentScheduleState`,
);

const ScheduleReducer = combineReducers({
  procedures: ScheduleProcedures,
  patient: SchedulePatient,
  payments: SchedulePayment,
});

export type ScheduleDetailsState = ReturnType<typeof ScheduleReducer>;

export default ScheduleReducer;
