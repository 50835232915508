import * as Yup from 'yup';

import { isValidFullDate } from 'shared/utils/validation';
import { Session } from 'features/tissInvoicing/types';

// const onlyNumbers = 'Somente números';
const requiredFieldText = 'Campo obrigatório';

export const sessionSchema = Yup.object().shape({
  sessions: Yup.array().of(
    Yup.object().shape({
      sessionDate: Yup.mixed()
        .nullable()
        .required(requiredFieldText)
        .test('isValidFullDate', 'Data inválida', (value) => {
          return !value || isValidFullDate(value);
        }),
    }),
  ),
});

export const emptySessions: Session = {
  sessionDate: null,
};

export const sessionsSectionValues = {
  sessions: [emptySessions],
};
