import { MaterialUICore, axiomTokens } from '@iclinic/design-system';

import { image } from 'shared/utils/urlGetter';

const { styled, ButtonBase } = MaterialUICore;

export const ShowOnlyScreenReader = styled('div')(() => ({
  textIndent: '-30000px',
}));

export const OuterContainer = styled('div')(() => ({
  margin: '20px',
}));

export const Container = styled(ButtonBase)(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  background: `url(${image(
    'downloadDataImages.backgroundIllustration',
  )}) no-repeat top 0 right 200px, linear-gradient(0deg, ${
    axiomTokens.color.brand.background[3]
  } 0%, ${axiomTokens.color.brand.background[1]} 100%) repeat-x`,
  minHeight: '136px',
  marginBottom: '20px',
  borderRadius: axiomTokens.border.radius.sm,
  '@media (max-width: 699px)': {
    display: 'none',
  },
}));

export const TextContent = styled('div')(() => ({
  backgroundImage: `url(${image('downloadDataImages.boxText')})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: `center left ${axiomTokens.spacing[5]}`,
  width: '353px',
  minWidth: '390px',
  height: '136px',
  flexGrow: 1,
}));

export const BackgroundIllustration = styled('div')(() => ({
  display: 'flex',
  width: '375px',
  minWidth: '395px',
  height: '136px',
  backgroundPosition: `center right ${axiomTokens.spacing[5]}`,
  backgroundRepeat: 'no-repeat',
  flexGrow: 0,
}));

export const ActionContent = styled('div')(() => ({
  backgroundImage: `url(${image('downloadDataImages.background')})`,
  display: 'flex',
  width: '234px',
  height: '136px',
  padding: '20px',
  backgroundPosition: `center right ${axiomTokens.spacing[5]}`,
  backgroundRepeat: 'no-repeat',
  flexGrow: 0,
  '@media (max-width: 1378px)': {
    flexGrow: 1,
  },
}));
