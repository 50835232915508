import React from 'react';

import { MaterialIcons } from '@iclinic/design-system';

const { CheckCircleOutline, ArrowForward, AccessTime } = MaterialIcons;

export const statusContent: Map<
  string,
  { label: string; icon: React.ReactElement }
> = new Map([
  [
    'Aguardando Escolha',
    { label: 'Pronto para resgate', icon: <ArrowForward /> },
  ],
  ['Pago', { label: 'Prêmio já resgatado', icon: <CheckCircleOutline /> }],
  ['Aguardando Pagamento', { label: 'Aguardando', icon: <AccessTime /> }],
]);

export const kindContent: Map<string, string> = new Map([
  ['Crédito no Sistema', 'Crédito no iClinic'],
  ['Prêmio externo', 'Vale presente'],
]);

export const tierMessages = {
  zero: 'Indique o iClinic e ganhe prêmios',
  youWin: (tierNumber: number, value: number) =>
    `${tierNumber}º indicação você ganha R$ ${value}`,
  rescued: 'Você já resgatou este prêmio',
  reached: 'Você tem esse prêmio para resgatar!',
};
