import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;
const colorShadow = 'rgba(52, 172, 224, 0)';

const useStyles = makeStyles(() => ({
  icon: {
    webkitAnimation: '$pulse-black 2s infinite',
    animation: '$pulse-black 2s infinite',
    transform: 'scale(1)',
    boxShadow: '0 0 0 0 rgba(52, 172, 224, 1)',
    borderRadius: '50%',
  },
  '@keyframes pulse-black': {
    '0%': {
      webkitTransform: 'scale(1)',
      transform: 'scale(1)',
      boxShadow: '0 0 0 0 rgba(52, 172, 224, 0.7)',
      webkitBoxShadow: '0 0 0 0 rgba(52, 172, 224, 0.7)',
    },
    '70%': {
      webkitTransform: 'scale(1.2)',
      transform: 'scale(1.2)',
      boxShadow: `0 0 0 10px ${colorShadow}`,
      webkitBoxShadow: `0 0 0 10px ${colorShadow}`,
    },
    '100%': {
      webkitTransform: 'scale(1)',
      transform: 'scale(1)',
      boxShadow: `0 0 0 0 ${colorShadow}`,
      webkitBoxShadow: `0 0 0 10px ${colorShadow}`,
    },
  },
}));

export default useStyles;
