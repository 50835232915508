
export const editorOptionsText = [{
  id: 1,
  name: 'Normal',
  selected: false,
},
{
  id: 2,
  name: 'Título',
  selected: false,
}];

export const typeStyleEditor = {
  bold: 'BOLD',
  italic: 'ITALIC',
  title: 'HEADER',
  titleID: 2,
  titleName: 'Título',
  normal: 'unstyled',
  normalID: 1,
  normalName: 'Normal',
  alignLeft: 'unstyled',
  alignCenter: 'center',
  link: 'LINK',
  linkCommand: 75, // Ctrl + K
  image: 'IMAGE',
  button: 'BUTTON',
  mutable: 'MUTABLE',
  buttonCommand: 79, // Ctrl + o
  automatedTag: 'AUTOMATED_TAG',
};

export const optionsToExportClass = {
  header: 'inlineTitle',
  headerElement: 'span',
  unstyled: 'blockUnstyled',
  center: 'blockCenter',
  link: 'entityLink',
  button: 'entityButton',
  automatedTag: 'entityAutomatedTag',
  image: 'entityImage',
};

// presentation name => replacement tag
export const optionsAutomatedTags = {
  'nome do paciente': 'nomeDoPaciente',
  'responsável pelo paciente': 'responsavelPeloPaciente',
  'CPF do paciente': 'CPFdoPaciente',
  'nome da clínica': 'nomeDaClinica',
  'endereço da clínica': 'enderecoDaClinica',
  'telefone da clínica': 'telefoneDaClinica',
};

export const alignmentImage = {
  center: 'center',
  right: 'right',
  default: 'default',
};
