import { AxiosRequestConfig } from 'axios';

import { AUTH_TOKEN_KEY } from 'shared/constants/auth';

export const apiTokenInterceptor = (
  request: AxiosRequestConfig,
): AxiosRequestConfig => {
  const token = global.localStorage.getItem(AUTH_TOKEN_KEY);

  if (!token) {
    throw new Error(`missing token in header Authorization`);
  }

  return {
    ...request,
    data: {
      iclinic_token: token,
    },
  };
};
