import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Container = styled('div')({
  position: 'absolute',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  background: tokens.color.feedback.error.light,
  alignItems: 'center',
  left: 0,
  padding: `0px ${tokens.spacing.xs}`,
  height: '100%',
  zIndex: 100,
  cursor: 'default',
});

export const ActionButtons = styled('div')({
  display: 'flex',
});
