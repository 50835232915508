import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Link } from '@iclinic/design-system';
import { getActiveCampaign } from 'state/campaignManager/actions';
import { getAllCampaigns } from 'state/campaignManager/selectors';
import {
  getComponentBySlug,
  getImageUrlFromSlug,
  getTextValueFromSlug,
} from '../utils';
import {
  BannerWrapper,
  DesktopImage,
  MobileImage,
} from './DashboardBannerStyles';

const DashboardBannerContainer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActiveCampaign());
  }, [dispatch]);

  const campaigns = useSelector(getAllCampaigns);
  const component = getComponentBySlug({ campaigns, slug: 'dashboard-banner' });

  if (!component) return null;

  const getText = (slug: string) =>
    getTextValueFromSlug({ component, slug })?.resource_value;

  const getImage = (slug: string) =>
    getImageUrlFromSlug({ component, slug })?.image;

  const desktopImage = getImage('desktop-dashboard-banner-img');
  const mobileImage = getImage('mobile-dashboard-banner-img');

  const hasCampaignImages = desktopImage && mobileImage;

  if (!hasCampaignImages) return null;

  const altText = getText('dashboard-banner-alt-text');

  return (
    <BannerWrapper>
      <Link
        title={getText('dashboard-banner-title-text')}
        href={getText('dashboard-banner-link')}
        data-ga={getText('dashboard-banner-data-ga')}
        data-campaign-component={component.name}
      >
        <DesktopImage src={desktopImage} alt={altText} />
        <MobileImage src={mobileImage} alt={altText} />
      </Link>
    </BannerWrapper>
  );
};

export default DashboardBannerContainer;
