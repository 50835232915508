import { IStoreState } from 'state/rootReducer';

export const getUserInfo = ({ userInfo }: IStoreState) => ({
  userName: userInfo.userData.userName,
  physicianId: userInfo.userData.physicianId,
});

export const getElectronicSignatureState = (state: IStoreState) =>
  state.records.electronicSignature;

export const getIsSignatureModalOpen = (state: IStoreState) =>
  state.records.electronicSignature.isSignatureModalOpen;

export const getScreenStep = (state: IStoreState) =>
  state.records.electronicSignature.screenStep;

export const getIsPhoneNumberValid = (state: IStoreState) =>
  state.records.electronicSignature.phoneNumber?.hasError;

export const getPhoneNumber = (state: IStoreState) =>
  state.records.electronicSignature.phoneNumber!.value;

export const getClinicId = (state: IStoreState) => state.userInfo.clinicId;
