import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

const {
  styled,
  MenuItem: DSMenuItem,
  ListItemText: DSListItemText,
} = MaterialUICore;

const { color, border, font, spacing } = axiomTokens;

export const SideBar = styled('div')({
  position: 'absolute',
  width: '100%',
});

export const Menu = styled('div')({
  padding: `${spacing['6']} 0 0 ${spacing['4']}`,
});

export const OptionsMenu = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 10,
  justifyContent: 'flex-start',
});

export const MenuItem = styled(DSMenuItem)({
  padding: spacing['3'],
  borderRadius: border.radius.md,
  fontSize: font.size.xs,

  '&.selected': {
    backgroundColor: color.surface.selected.default,
  },
});

export const ListItemText = styled(DSListItemText)({
  fontWeight: font.weight.medium,

  '&.selected': {
    color: color.interactive.accent.default,
    fontWeight: font.weight.bold,
  },
});
