import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

const useStyles = makeStyles(() => ({
  drawer: {
    '& .MuiDrawer-paper': {
      justifyContent: 'space-between',
    },
  },
  backButton: {
    color: tokens.color.neutral[700],
  },
}));

export default useStyles;
