// External
import React, { useMemo } from 'react';
// Internal
import Button from 'ui/new/button';
import {
  EmailFlowTarget,
  EmailFlow,
} from 'state/marketing/emailFlow/emailFlowManagement/emailFlowTypes';
import {
  EmailFlowButtonDesc,
  EmailFlowTitleDesc,
  emailFlowInformation,
  emailFlowActiveKey,
} from 'state/marketing/emailFlow/emailFlowManagement/constants';
import { Switch } from 'ui/new/form';
import { BUTTON } from 'ui/new/button/constants';

import useWidthUpdater from 'shared/hooks/useWidthUpdater';
import { FlowType } from '../constants';
import style from './EditTitleEmailFlow.scss';

type EditTitleEmailFlowProps = {
  onClickSave: () => void;
  onClickCancel: () => void;
  invalid: boolean;
  emailFlow: EmailFlow;
  changeStatusEmailFlow: (e: EmailFlowTarget) => void;
  activeEmailFlow: boolean;
  footer?: boolean;
};
const EditTitleEmailFlow = ({
  onClickSave,
  onClickCancel,
  invalid,
  emailFlow,
  changeStatusEmailFlow,
  activeEmailFlow,
  footer = false,
}: EditTitleEmailFlowProps): JSX.Element => {
  const activeDesc: emailFlowActiveKey =
    emailFlow && emailFlow.active
      ? emailFlowActiveKey.active
      : emailFlowActiveKey.disable;
  const buttonCallBack = (
    <div className={style['button-edit-title']}>
      <Button onClick={onClickCancel} type={BUTTON.Link}>
        Voltar
      </Button>
    </div>
  );

  const title = useMemo(() => {
    if (emailFlow) {
      const titleHeader =
        emailFlow.flowType === FlowType.return
          ? `Retorno ${emailFlow.name}`
          : emailFlow.name;
      return footer ? buttonCallBack : <span>{titleHeader}</span>;
    }
    return '';
  }, [footer, buttonCallBack, emailFlow]);

  const isMobile = useWidthUpdater();

  return (
    <div className={footer ? style['footer-email-flow'] : undefined}>
      {emailFlow && (
        <div className={style['header-email-flow']}>
          {title}
          {isMobile && !footer && (
            <div className={style['mobile-header']}>
              <span>{emailFlowInformation.editEmailFlowReturn}</span>
            </div>
          )}
          <div className={style['header-email-flow-actions']}>
            <div className={style['header-email-flow-active']}>
              <Switch
                id={emailFlow.id}
                checked={activeEmailFlow}
                value={emailFlow.id}
                onChange={changeStatusEmailFlow}
                classes={style}
              />
              <span className={style['header-email-flow-active-title']}>
                {EmailFlowTitleDesc[activeDesc]}
              </span>
            </div>
            <Button onClick={onClickCancel} type={BUTTON.Light}>
              CANCELAR
            </Button>
            <div className={style['header-email-flow-actions-button']}>
              <Button
                onClick={onClickSave}
                disabled={invalid}
                type={BUTTON.Success}
              >
                {EmailFlowButtonDesc[activeDesc]}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditTitleEmailFlow;
