import { axiomTokens, MaterialUICore } from '@iclinic/design-system';

import {
  FlexColumnBox,
  SpaceBetweenBox,
} from 'features/tissInvoicing/components/ui/Flexbox';
import { TextField } from 'features/tissInvoicing/utils/Inputs';

const { styled } = MaterialUICore;
const { spacing, border, color } = axiomTokens;

export const Container = styled(FlexColumnBox)({
  backgroundColor: color.background['1'],
  border: `1px solid ${color.border.subtle}`,
  borderRadius: border.radius.sm,
  padding: spacing['4'],
  margin: spacing['3'],
});

export const Header = styled(SpaceBetweenBox)({
  marginBottom: `${spacing['4']}`,
});

export const HiddenTextField = styled(TextField)({
  display: 'none',
});
