import React, { RefObject, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MaterialUICore } from '@iclinic/design-system';

import { getHighlightsInfoList } from '../../../state/selectors';
import { actions } from '../../../state/index';
import {
  CardInteractionTracking,
  HighlightsInfoType,
  ListCardType,
  TextCardType,
} from '../../../types';
import {
  getHighlightInfoClickedOnNavigation,
  getPreviousAndNextHighlightInfoCardTypes,
} from '../../../utils';
import * as S from './NavigationCardsModal.styles';

const { addHighlightInfoSelected } = actions;
const { useTheme, useMediaQuery } = MaterialUICore;

interface NavigationCardsModalProps {
  highlightInfoSelected: HighlightsInfoType;
  containerRef: RefObject<HTMLDivElement>;
  onSave: () => void;
  trackEvent: (
    cardInteraction: CardInteractionTracking,
    typeCard: TextCardType | ListCardType,
  ) => void;
}

export const NavigationCardsModal = ({
  highlightInfoSelected,
  containerRef,
  onSave,
  trackEvent,
}: NavigationCardsModalProps) => {
  const dispatch = useDispatch();
  const highlightsInfoList = useSelector(getHighlightsInfoList);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleNavigate = useCallback(
    (direction: string) => {
      onSave();

      const nextCard = getHighlightInfoClickedOnNavigation(
        direction,
        highlightsInfoList,
        highlightInfoSelected,
      );
      dispatch(addHighlightInfoSelected(nextCard));
      trackEvent(CardInteractionTracking.view, nextCard.typeCard);
    },
    [onSave, highlightsInfoList, highlightInfoSelected, dispatch, trackEvent],
  );

  const handleKeyboardNavigation = useCallback(
    (event: KeyboardEvent) => {
      if (!event.altKey) return;
      event.preventDefault();

      switch (event.key) {
        case 'ArrowLeft':
          handleNavigate('previous');
          break;
        case 'ArrowRight':
          handleNavigate('next');
          break;
        default:
          break;
      }
    },
    [handleNavigate],
  );

  useEffect(() => {
    const currentContainerRef = containerRef.current;
    if (!currentContainerRef) return () => null;

    currentContainerRef.addEventListener('keydown', handleKeyboardNavigation);
    return () => {
      currentContainerRef.removeEventListener(
        'keydown',
        handleKeyboardNavigation,
      );
    };
  }, [containerRef, handleKeyboardNavigation]);

  const { previousCardType, nextCardType } =
    getPreviousAndNextHighlightInfoCardTypes(
      highlightsInfoList,
      highlightInfoSelected,
    );

  return (
    <>
      <S.Button
        variant={isMobile ? 'contained' : 'outlined'}
        size={isMobile ? 'medium' : 'small'}
        color="transparent"
        fullWidth={isMobile}
        onClick={() => handleNavigate('previous')}
      >
        {previousCardType}
      </S.Button>
      <S.KeyboardInfoContainer>
        <S.CommandBackground>alt</S.CommandBackground>+
        <S.KeyboardArrowLeftRounded />
        <S.NavigationText>Navegue pelas teclas</S.NavigationText>
        <S.CommandBackground>alt</S.CommandBackground>+
        <S.KeyboardArrowRightRounded />
      </S.KeyboardInfoContainer>
      <S.Divider orientation="vertical" variant="fullWidth" />
      <S.Button
        variant={isMobile ? 'contained' : 'outlined'}
        size={isMobile ? 'medium' : 'small'}
        color="transparent"
        fullWidth={isMobile}
        onClick={() => handleNavigate('next')}
      >
        {nextCardType}
      </S.Button>
    </>
  );
};
