import { ActionType } from '@types';
import * as types from './constants';
import * as actions from './actions';
import { SignatureState, Stepper } from './types';

export const initialState: SignatureState = {
  isOpenDrawer: false,
  isLoading: false,
  errors: [],
  encodedCert: '',
  thumbprint: '',
  signedPdf: [],
  stepper: Stepper.Initial,
  providers: [],
  accessToken: '',
  providersUrlSelected: '',
  lifeTime: 0,
  userDocument: null,
  providerName: '',
};

type SignatureActions = ActionType<typeof actions>;

const Signature = (state = initialState, action: SignatureActions) => {
  switch (action.type) {
    case types.TOGGLE_DRAWER:
      return {
        ...state,
        isOpenDrawer: action.payload.isOpenDrawer,
      };
    case types.RECORDS_FINISHED:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        encodedCert: action.payload.encodedCert,
      };
    case types.RECORDS_FINISHED_SUCCESS:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        isOpenDrawer: action.payload.isOpenDrawer,
        stepper: action.payload.stepper,
        signedPdf: [],
      };
    case types.FETCH_SIGNATURE_FAILURE:
    case types.RECORDS_FINISHED_FAILURE:
    case types.GET_ACCESS_TOKEN_FAILURE:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        errors: action.payload.errors,
      };

    case types.GET_LIST_PSC_FAILURE:
      return {
        ...state,
        isLoading: false,
        errors: action.payload.errors,
        providers: [],
      };
    case types.FETCH_SIGNATURE_SUCCESS:
      return {
        ...state,
        signedPdf: action.payload.signedPdf,
        stepper: action.payload.stepper,
        isLoading: false,
      };
    case types.GET_SIGNATURE_THUMBPRINT:
      return {
        ...state,
        thumbprint: action.payload.thumbprint,
      };
    case types.CLEAR_SIGNED_PDF:
      return {
        ...state,
        signedPdf: [],
      };
    case types.CLEAR_SIGNED_PDF_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case types.FETCH_SIGNATURE_CLOUD:
    case types.CHANGE_SIGNATURE_STEPPER:
      return {
        ...state,
        stepper: action.payload.stepper,
      };
    case types.GET_LIST_PSC_SUCCESS:
      return {
        ...state,
        providers: action.payload.providers,
        isLoading: false,
        errors: [],
      };
    case types.GET_LIST_PSC:
      return {
        ...state,
        isLoading: true,
        userDocument: action.payload.user_document,
      };
    case types.SET_PROVIDER:
      return {
        ...state,
        providersUrlSelected: action.payload.providersUrlSelected,
        providerName: action.payload.providerName,
      };
    case types.GET_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: action.payload.accessToken,
      };
    case types.SET_LIFETIME:
      return {
        ...state,
        lifeTime: action.payload.lifeTime,
      };
    default:
      return state;
  }
};

export default Signature;
