import { Body, MaterialUICore, MaterialIcons } from '@iclinic/design-system';
import React, { memo } from 'react';

import { formatDate } from 'features/tissInvoicing/utils/formatDateTime';
import { formatCurrency } from 'features/tissInvoicing/utils/formatCurrency';
import { Lot } from 'features/tissInvoicing/types';
import * as Styles from './styles';

const { TableCell, IconButton } = MaterialUICore;

const { Visibility } = MaterialIcons;

interface RowsProps {
  rowData: Lot;
}

const Rows = ({ rowData }: RowsProps) => {
  const classes = Styles.useStyles();

  return (
    <>
      <TableCell>
        <Body variant="sm">{rowData.lot_number}</Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{rowData.clinic_health_insurance.name}</Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">
          {formatDate(`${rowData.closed_date}T12:00:00`)}
        </Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{rowData?.hired_executant?.name}</Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{rowData.guides.length}</Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{formatCurrency(rowData.total_amount)}</Body>
      </TableCell>
      <TableCell align="center" size="small">
        <IconButton
          href={`/faturamento-tiss/lotes/${rowData.id}`}
          classes={{ root: classes.editButton }}
        >
          <Visibility />
        </IconButton>
      </TableCell>
    </>
  );
};

export default memo(Rows);
