import {
  Body,
  MaterialUICore,
  MaterialIcons as Icons,
  axiomTokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing } = axiomTokens;

export const SendWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[3],
  gridColumn: '1',
  gridRow: '1',
});

export const BodySmooth = styled(Body)({
  color: color.typeface.smooth,
});

export const PackagesSectionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[3],
});

export const PackageCardsWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: spacing[6],
});

export const FooterSectionWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing[4],
  alignItems: 'flex-end',
});

export const ActionsWrapper = styled('div')({
  display: 'flex',
  gap: spacing[4],
});

export const HorizontalWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: spacing[1],
});

export const CheckIcon = styled(Icons.CheckCircleOutline)({
  color: color.highlight.teal[1],
});
