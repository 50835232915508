import React from 'react';
import { useSelector } from 'react-redux';

import { MaterialIcons, Chip, SectionTitle } from '@iclinic/design-system';
import { getSellerConfigSelector } from 'features/onlinePayment/state/config/register/selectors';
import {
  SellerStatus,
  SellerType,
} from 'features/onlinePayment/state/constants';
import { ResponsiveCard } from 'features/onlinePayment/components';
import ReadOnlyCardProfilePF from './ReadOnlyCardProfilePF';
import ReadOnlyCardProfilePJ from './ReadOnlyCardProfilePJ';

const { Person, Check, MoreHoriz } = MaterialIcons;

export default function ReadOnlyCardProfile(): JSX.Element | null {
  const { seller } = useSelector(getSellerConfigSelector);

  const status =
    seller?.status === SellerStatus.Approved ? (
      <Chip icon={<Check />} label="Ativo" severity="success" size="small" />
    ) : (
      <Chip icon={<MoreHoriz />} label="Configurando" size="small" />
    );

  return (
    <ResponsiveCard variant="outlined">
      <SectionTitle
        icon={<Person />}
        title="Perfil"
        mb={3}
        secondary={status}
      />
      {seller?.type === SellerType.PJ ? (
        <ReadOnlyCardProfilePJ />
      ) : (
        <ReadOnlyCardProfilePF />
      )}
    </ResponsiveCard>
  );
}
