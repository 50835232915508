import { FieldArray, useFormikContext } from 'formik';
import React, { forwardRef } from 'react';
import { Box, Button, MaterialIcons } from '@iclinic/design-system';

import CollapsibleFormSection from 'features/tissInvoicing/components/ui/CollapsibleFormSection';
import { GuideSADTForm } from 'features/tissInvoicing/types';
import Procedure from './Procedure';
import { emptyProcedure } from './validationSchema';

const { PieChart, Add } = MaterialIcons;

interface ProceduresSectionProps {}

const ProceduresSection = (
  _: ProceduresSectionProps,
  ref: React.Ref<HTMLButtonElement>,
): JSX.Element => {
  const {
    values: { procedures },
  } = useFormikContext<GuideSADTForm>();

  return (
    <CollapsibleFormSection
      sectionTitle="Procedimentos e exames"
      sectionIcon={<PieChart />}
      sectionIdentifier="procedures_data"
      ref={ref}
    >
      <FieldArray
        name="procedures"
        render={(arrayHelpers) => (
          <>
            {procedures.map((procedure, index) => (
              <Procedure
                procedure={procedure}
                index={index}
                key={procedure?.id || index}
                removeProcedure={(item) => {
                  if (procedures?.length > 1) arrayHelpers.remove(item);
                }}
              />
            ))}

            <Box display="flex" justifyContent="flex-end" p={1}>
              <Button
                color="secondary"
                size="small"
                startIcon={<Add />}
                onClick={() => arrayHelpers.push(emptyProcedure)}
              >
                Novo procedimento
              </Button>
            </Box>
          </>
        )}
      />
    </CollapsibleFormSection>
  );
};

export default forwardRef(ProceduresSection);
