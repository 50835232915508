import {
  MaterialUICore,
  tokens,
  Chip,
  Body,
  Heading,
  Button,
  IconButton,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color } = tokens;

export const CardLabelButton = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const CardContent = styled('div')({
  borderBottom: `${border.width.hairline} solid ${color.neutral[50]}`,
  alignItems: 'center',
  maxHeight: 400,
  overflowY: 'auto',
  marginTop: tokens.spacing.sm,
});

export const ChipOutContent = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
}));

export const ChipInContent = styled(Chip)(() => ({
  marginRight: tokens.spacing.base,
  marginBottom: tokens.spacing.base,
}));

export const NoDiagnosesText = styled(Body)(({ theme }) => ({
  marginRight: tokens.spacing.base,
  marginLeft: tokens.spacing.nano,
  marginBottom: tokens.spacing.base,

  [theme.breakpoints.down('md')]: {
    marginBottom: 0,
  },
}));

export const LatestDiagnosesText = styled(Heading)(() => ({
  marginLeft: tokens.spacing.nano,
  marginBottom: tokens.spacing.nano,
}));

export const RedirectButton = styled(Button)(({ theme }) => ({
  marginBottom: tokens.spacing.base,

  [theme.breakpoints.down('md')]: {
    marginTop: tokens.spacing.sm,
    marginLeft: tokens.spacing.nano,
  },
}));

export const ExpandIconButton = styled(IconButton)(() => ({
  marginBottom: tokens.spacing.base,
}));

export const LinkRedirect = styled('a')(() => ({
  textDecoration: 'none',
}));

export const TextAndLink = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
  },
}));
