// Internal
import appTypes from 'shared/utils/actions';
import { SUCCESS, FAILURE } from 'shared/constants/actions';
import { RECORDS_FINISHED_SUCCESS } from 'state/records/signature/constants';
import { actionTypes as highlightInfosTypes } from 'features/records/highlightsInfo/state';

const { saveHighlightsInfoSuccess } = highlightInfosTypes;

const records = appTypes.defineAction('records');

export const INITIALIZED = records.defineAction('INITIALIZED').toString();
export const SET_PATIENT = records.defineAction('SET_PATIENT').toString();
export const ENDED = records.defineAction('ENDED').toString();
export const CHANGED_TAB = records.defineAction('CHANGED_TAB').toString();
export const AUTO_SAVE = records.defineAction('AUTO_SAVE').toString();
export const GET_EVENT_BLOCKS_IDS = records.defineAction(
  'GET_EVENT_BLOCKS_IDS',
  [SUCCESS, FAILURE],
);
export const NOT_ALLOW_GENERATE_NEW_SUMMARY = records
  .defineAction('NOT_ALLOW_GENERATE_NEW_SUMMARY')
  .toString();
export { RECORDS_FINISHED_SUCCESS, saveHighlightsInfoSuccess };

export const SHOW_PRESCRIPTION_TAB = 'SHOW_PRESCRIPTION_TAB';

export const pdfUrls = {
  prescription: '/prescriptions/prescription/',
  attest: '/attests/attest/',
  ophthalmic: '/records/eventblock/',
  exam_request: '/exams/examrequest/pdf/private/',
  budget: '/records/eventblock/',
};

export const documentsKind = {
  ophthalmic: 'ophthalmic',
  prescription: 'prescription',
  attest: 'attest',
  exam_request: 'exam_request',
  budget: 'budget',
};
