/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ContentLoader from 'react-content-loader';

const withContentLoader = <ComponentProps extends any>(
  Skeleton: typeof ContentLoader,
) => (Component: React.ComponentType<ComponentProps>) => (
  props: ComponentProps & { loading: boolean },
) => {
  const { loading } = props;
  return loading ? <Skeleton /> : <Component {...props} />;
};

export default withContentLoader;
