import React from 'react';

import style from './Field.scss';

type FieldProps = {
  title: String;
  children: React.ReactNode;
};

export default function Field({
  title = '',
  children,
}: FieldProps): JSX.Element {
  return (
    <div className={style['content-field']}>
      <p className={style.title}>{title}</p>
      <div className={style.children}>
        {children}
      </div>
    </div>
  );
}
