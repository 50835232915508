import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, MaterialIcons, MaterialUICore } from '@iclinic/design-system';

import useWidthUpdater from 'shared/hooks/useWidthUpdater';
import { getAwards } from '../../state/selectors';
import * as actions from '../../state';
import ContentWrapper from './ContentWrapper';
import MobileAwardsList from './MobileAwardsList';
import * as S from './RewardsRedemption.styles';
import { useToggle } from 'shared/hooks';
import { kindContent, statusContent } from 'features/referrals/constants';
import { formatDate } from 'shared/utils/date';
import ButtonStatus from './ButtonStatus';
import RedemptionDialog from './RedemptionDialog';
import IndicationsTier from './IndicationsTier';

const { useTheme, Slide, Table, TableBody, TableHead, TableRow } =
  MaterialUICore;
const { Close } = MaterialIcons;

const Transition = React.forwardRef(
  (props: MaterialUICore.TransitionProps, ref: React.Ref<unknown>) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Slide direction="right" ref={ref} {...props} />
  ),
);

const RewardsRedemption = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const awards = useSelector(getAwards);
  const [mobileOpen, toggleMobileOpen] = useToggle(false);
  const [redemptionOpen, toggleRedemptionOpen] = useToggle(false);

  const mobileWidth = theme.breakpoints.values.sm;
  const isMobile = useWidthUpdater(mobileWidth);

  useEffect(() => {
    dispatch(actions.fetchReferralAwards());
  }, [dispatch]);

  if (isMobile) {
    return (
      <div>
        <IndicationsTier onMobileAwardsClick={toggleMobileOpen} />

        <Dialog
          fullScreen
          open={mobileOpen}
          onClose={toggleMobileOpen}
          TransitionComponent={Transition}
        >
          <S.IconButtonClose
            tooltip="none"
            label="Fechar"
            onClick={toggleMobileOpen}
          >
            <Close />
          </S.IconButtonClose>

          <ContentWrapper>
            <MobileAwardsList onRedeemAwardClick={toggleRedemptionOpen} />
          </ContentWrapper>
        </Dialog>

        <RedemptionDialog
          open={redemptionOpen}
          onClose={toggleRedemptionOpen}
        />
      </div>
    );
  }

  return (
    <div>
      <IndicationsTier />

      {!!awards?.length && (
        <S.Root>
          <ContentWrapper>
            <S.TableWrapper>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <S.TableCell align="center">Nº</S.TableCell>
                    <S.TableCell align="center">Indicação</S.TableCell>
                    <S.TableCell align="center">Status</S.TableCell>
                    <S.TableCell align="center">Data de resgate</S.TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {awards.map(
                    (
                      { id, indicated, status, pay_date, kind, created_at },
                      index,
                    ) => (
                      <TableRow key={created_at}>
                        <S.TableCellSmall align="center">
                          {(Number(index) + 1).toString()}
                        </S.TableCellSmall>
                        <S.TableCellSmall align="center">
                          {indicated}
                        </S.TableCellSmall>
                        <S.TableCellSmall align="center">
                          <ButtonStatus
                            disabled={status !== 'Aguardando Escolha'}
                            redeemed={status !== 'Aguardando Pagamento'}
                            icon={statusContent.get(status)?.icon}
                            onClick={() => {
                              const { localStorage } = window;
                              localStorage.setItem(
                                'referralsCongratulationsRewards',
                                'true',
                              );
                              dispatch(
                                actions.updateAwardSelected({
                                  id,
                                  indicated,
                                }),
                              );
                              toggleRedemptionOpen();
                            }}
                          >
                            {statusContent.get(status)?.label}
                          </ButtonStatus>
                        </S.TableCellSmall>
                        <S.TableCellSmall align="center">
                          {pay_date
                            ? `${kindContent.get(kind)} ${formatDate(pay_date)}`
                            : '-'}
                        </S.TableCellSmall>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </S.TableWrapper>
          </ContentWrapper>

          <RedemptionDialog
            open={redemptionOpen}
            onClose={toggleRedemptionOpen}
          />
        </S.Root>
      )}
    </div>
  );
};

export default RewardsRedemption;
