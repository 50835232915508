// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import { Title } from '@iclinic/iclinic-ui';
import style from './CardUnsubscribeMessage.scss';

const CardUnsubscribeMessage = ({ didProvideReason, hasErrors }) => (
  <div>
    <div className={style.card}>
      <h1>Assinatura Cancelada com sucesso.</h1>
      <p>Você ainda receberá lembretes de consulta médica.</p>
    </div>
    {didProvideReason && (
      <div className={style.feedbackSent}>
        <Title>Sua mensagem foi enviada com sucesso.</Title>
      </div>
    )}
    {hasErrors && (
      <div className={style.feedbackErrors}>
        <Title>Problemas no envio, tente novamente.</Title>
      </div>
    )}
  </div>
);

CardUnsubscribeMessage.propTypes = {
  didProvideReason: PropTypes.bool.isRequired,
  hasErrors: PropTypes.bool.isRequired,
};

export default CardUnsubscribeMessage;
