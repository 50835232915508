import React, { useState, useRef } from 'react';
import { isSupported } from '@twilio/video-processors';
import {
  MaterialUICore,
  MaterialIcons,
  Body,
  Box,
} from '@iclinic/design-system';

import MoreToolsButton from '../Buttons/MoreToolsButton';
import { IconContainer, MenuContainer } from './styles';
import DeviceSelectionDialog from '../../DeviceSelection/Dialog';
import { buttonsTitle, menuInfo } from 'features/video-conference/constants';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useChatContext } from 'features/video-conference/state/chatContext';
import { useAnnotationContext } from 'features/video-conference/state/annotationContext';

const { MenuItem } = MaterialUICore;
const { Settings, MovieFilter } = MaterialIcons;

export default function Menu() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const { setIsChatWindowOpen } = useChatContext();
  const { setIsAnnotationWindowOpen } = useAnnotationContext();
  const { setIsBackgroundSelectionOpen } = useConference();

  const handleClickApplyFilterMenu = () => {
    setIsBackgroundSelectionOpen(true);
    setIsChatWindowOpen(false);
    setIsAnnotationWindowOpen(false);
    setMenuOpen(false);
  };

  return (
    <>
      <MoreToolsButton
        isOpen={menuOpen}
        onClickHandler={() => setMenuOpen((isOpen) => !isOpen)}
        innerRef={anchorRef}
      />
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen((isOpen) => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={() => setSettingsOpen(true)}
          data-ga="telemedicine_settings"
          data-sp="telemedicine_settings"
        >
          <IconContainer>
            <Settings />
          </IconContainer>
          <Box display="block">
            <Body variant="xs" bold>
              {buttonsTitle.SETTINGS}
            </Body>
            <Body variant="xs">{menuInfo.SETTINGS_INFO_1}</Body>
            <Body variant="xs">{menuInfo.SETTINGS_INFO_2}</Body>
          </Box>
        </MenuItem>

        {isSupported && (
          <MenuItem
            onClick={handleClickApplyFilterMenu}
            data-ga="telemedicine_apply_video_effects"
            data-sp="telemedicine_apply_video_effects"
          >
            <IconContainer>
              <MovieFilter />
            </IconContainer>
            <Box display="block">
              <Body variant="xs" bold>
                {buttonsTitle.APPLY_FILTERS}
              </Body>
              <Body variant="xs">{menuInfo.FILTER_INFO_1}</Body>
              <Body variant="xs">{menuInfo.FILTER_INFO_2}</Body>
            </Box>
          </MenuItem>
        )}
      </MenuContainer>

      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
        location="toolbar"
      />
    </>
  );
}
