import { ApiErrors, ActionCreator } from '@types';
import {
  FETCH_PROFESSIONALS,
  SET_SELECTED_PROFESSIONAL,
  RESET_STATE,
  SET_SELECTED_PROFESSIONAL_BY_ID,
  initialState,
} from './constants';
import {
  Professional,
  PhysicianId,
} from './types';

export type ProfessionalState = Readonly<typeof initialState>;
export type ProfessionalActions = ActionCreator<string, Partial<ProfessionalState>>;

export const fetchProfessionals = (): ProfessionalActions => <const>{
  type: FETCH_PROFESSIONALS.ACTION,
  payload: {
    isFetching: true,
  },
};

export const fetchProfessionalsSuccess = (data: Professional[]): ProfessionalActions => (
  <const>{
    type: FETCH_PROFESSIONALS.SUCCESS,
    payload: {
      isFetching: false,
      professionals: data,
    },
  }
);

export const fetchProfessionalsFailure = (errors: ApiErrors[]): ProfessionalActions => (
  <const>{
    type: FETCH_PROFESSIONALS.FAILURE,
    payload: {
      isFetching: false,
      errors,
    },
  }
);

export const setSelectedProfessional = (
  selectedProfessional: Professional,
): ProfessionalActions => <const>{
  type: SET_SELECTED_PROFESSIONAL.ACTION,
  payload: {
    selectedProfessional,
  },
};

export const resetState = (): ProfessionalActions => <const>{
  type: RESET_STATE.ACTION,
  payload: initialState,
};

export const setSelectedProfessionalById = (id: PhysicianId): ProfessionalActions => <const>{
  type: SET_SELECTED_PROFESSIONAL_BY_ID.ACTION,
  payload: {},
  meta: {
    id,
  },
};
