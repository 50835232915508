import axios from 'axios';

import {
  interceptorRequestAuthentication,
  interceptorResponseTransformData,
} from 'services/iclinic/interceptors';

export const apiTelemedicine = axios.create({
  baseURL: process.env.ICLINIC_TELEMEDICINE_URL,
  headers: {
    authorization: 'Bearer iclinic',
  },
});

apiTelemedicine.interceptors.request.use(interceptorRequestAuthentication);
apiTelemedicine.interceptors.response.use(interceptorResponseTransformData);
