import * as Yup from 'yup';

const required = 'Campo obrigatório';
const CPF_REGEX = /([\d]{2}[.]?[\d]{3}[.]?[\d]{3}[/]?[\d]{4}[-]?[\d]{2})|([\d]{3}[.]?[\d]{3}[.]?[\d]{3}[-]?[\d]{2})/;

export default Yup.object({
  cpf: Yup.string()
    .matches(CPF_REGEX, 'Número de CPF inválido')
    .required(required),
});
