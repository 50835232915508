// External
import React from 'react';
import PropTypes from 'prop-types';

// Internal
import style from './Tooltip.scss';

const Tooltip = ({ content, align, children }) => (
  <div className={style.tooltip} data-content={content} data-position={align}>
    {children}
  </div>
);

Tooltip.defaultProps = {
  align: 'top',
};

Tooltip.propTypes = {
  content: PropTypes.string.isRequired,
  align: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
  children: PropTypes.node.isRequired,
};

export default Tooltip;
