import { createAction, createSlice } from '@reduxjs/toolkit';

import { PatientProps } from '../types/types';

const nameStore = '@webapp/scheduleDetails';

export const initialState: PatientProps = {
  date: {} as Date,
  name: '',
  phoneNumber: '',
  email: '',
  healthInsurance: '',
  age: 0,
  lastSchedule: '',
  timeUnit: '',
  observations: '',
  picture: '',
  code: 0,
  gender: '',
  age_full_described: '',
  mobile_phone: '',
  last_appointment_date: '',
  home_phone: '',
  birth_date: '',
  id: 0,
  scheduleTime: '',
};

export const setSchedulePatient = createAction(
  `${nameStore}/patient/setScheduleData`,
);

const ScheduleDataSlice = createSlice({
  name: `${nameStore}/patient`,
  initialState,
  reducers: {
    setSchedulePatientData: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    resetState: () => initialState,
  },
});

export type ScheduleDetailState = ReturnType<typeof ScheduleDataSlice.reducer>;

export const { setSchedulePatientData, resetState } = ScheduleDataSlice.actions;

export default ScheduleDataSlice.reducer;
