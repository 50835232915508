// External
import React from 'react';
import PropTypes from 'prop-types';

const ListCampaignsMobile = ({ children, searchCampaign }) => (
  <div>
    {searchCampaign}
    {children}
  </div>
);

ListCampaignsMobile.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  searchCampaign: PropTypes.node.isRequired,
};

export default ListCampaignsMobile;
