import { useEffect, useState } from 'react';
import { Participant } from 'twilio-video';

import { fetchSendLogs } from 'features/video-conference/services/sendLogs';
import { useHandleSendLogs } from 'features/video-conference/hooks/useHandleSendLogs';

export default function useParticipantIsReconnecting(
  participant?: Participant,
) {
  const [isReconnecting, setIsReconnecting] = useState(false);
  const { createSendLogsBody } = useHandleSendLogs();

  useEffect(() => {
    if (!isReconnecting) return;

    const body = createSendLogsBody({ actionType: 'reconnecting participant' });
    fetchSendLogs(body);
  }, [createSendLogsBody, isReconnecting]);

  useEffect(() => {
    const handleReconnecting = () => {
      setIsReconnecting(true);
      participant?.emit('participantDisconnected');
    };

    const handleReconnected = () => setIsReconnecting(false);

    handleReconnected();

    participant?.on('reconnecting', handleReconnecting);
    participant?.on('reconnected', handleReconnected);
    return () => {
      participant?.off('reconnecting', handleReconnecting);
      participant?.off('reconnected', handleReconnected);
    };
  }, [participant]);

  return isReconnecting;
}
