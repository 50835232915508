import { combineReducers } from 'redux';

import exportDownload, { DownloadState } from './exportDownload';
import exportHome, { ExportHomeState } from './exportHome';
import exportList, { ListState } from './exportList';
import exportRequest, { ExportRequestState } from './exportRequest';

export type ExportClinicDataState = {
  exportDownload: DownloadState;
  exportHome: ExportHomeState;
  exportList: ListState;
  exportRequest: ExportRequestState;
};

const exportClinicDataReducer = combineReducers<ExportClinicDataState>({
  exportDownload,
  exportHome,
  exportList,
  exportRequest,
});

export default exportClinicDataReducer;
