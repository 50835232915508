import { tokens, MaterialUICore } from '@iclinic/design-system';

const { styled } = MaterialUICore;

const white = tokens.color.neutral[0];
const { light } = tokens.color.secondary;

export const Table = styled(MaterialUICore.Table)({
  '& th': {
    backgroundColor: white,
    borderColor: white,
  },
});

interface TableRowProps {
  checked?: boolean | number;
  stripped?: boolean | number;
}

export const TableRow = styled(MaterialUICore.TableRow)(
  ({ checked = false, stripped = false }: TableRowProps) => ({
    backgroundColor: `${checked ? light : white}`,
    '&:hover': {
      backgroundColor: `${light} !important`,
    },
    '&:nth-of-type(odd)': {
      backgroundColor: `${stripped || checked ? light : white}`,
    },
  }),
);

export const TableCell = styled(MaterialUICore.TableCell)({
  maxWidth: '248px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'break-spaces',
});
