/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Body,
  BodyProps,
  MaterialUICore,
  tokens,
} from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing } = tokens;

type HTMLElementProps<T> = React.DetailedHTMLProps<React.HTMLAttributes<T>, T>;

type FlipProps = {
  flipped: boolean;
} & HTMLElementProps<HTMLDivElement>;

type CardTextProps = {
  active?: boolean;
} & BodyProps;

export const Card = styled('div')({
  display: 'grid',
  backgroundColor: 'transparent',
  width: 240,
  height: 140,
  perspective: 1000,
});

export const CardContent = styled(({ flipped, ...rest }: FlipProps) => (
  <div {...rest} />
))({
  position: 'relative',
  width: '100%',
  height: '100%',
  '-webkit-backface-visibility': 'hidden',
  backfaceVisibility: 'hidden',
  transition: 'transform 0.8s',
  transformStyle: 'preserve-3d',
  userSelect: 'none',

  transform: ({ flipped }: FlipProps) => (flipped ? 'rotateY(180deg)' : 'none'),
});

export const CardSide = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  '-webkit-backface-visibility': 'hidden',
  backfaceVisibility: 'hidden',
  borderRadius: 12,
  background: 'linear-gradient(to right, #2c97d1, #47c1bf)',
});

export const CardFront = styled(CardSide)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: spacing.md,
});

export const CardFrontContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const CardBack = styled(CardSide)({
  transform: 'rotateY(180deg)',

  '&:before': {
    display: 'block',
    content: '""',
    width: '100%',
    height: spacing.lg,
    backgroundColor: color.neutral[700],
    marginTop: spacing.md,
  },
});

export const CardBackContent = styled('div')({
  padding: `${spacing.sm} ${spacing.md}`,
});

export const CardText = styled(({ active, ...rest }: CardTextProps) => (
  <Body {...rest} />
))({
  color: ({ active }: CardTextProps) =>
    active ? color.neutral[0] : color.secondary.main,
});
