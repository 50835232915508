import { MaterialUICore, tokens } from '@iclinic/design-system';
import { image } from 'shared/utils/urlGetter';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  '@global': {
    html: {
      background: `url(${image(
        'onlinePayment.bgCheckout',
      )}) no-repeat right center / contain ${tokens.color.primary.main}`,
      height: '100vh',
      fontSize: '16px',
      [theme.breakpoints.down('xs')]: {
        fontSize: '14px',
        backgroundImage: `initial`,
      },
    },
  },
}));
