// Internal
import { SUCCESS, FAILURE } from 'shared/constants/actions';
import appTypes from 'shared/utils/actions';

const emailFlow = appTypes.defineAction('emailFlow/management');

export const FETCH_LIST = emailFlow.defineAction('FETCH_LIST', [SUCCESS, FAILURE]);
export const FETCH_UPDATE = emailFlow.defineAction('FETCH_UPDATE', [SUCCESS, FAILURE]);
export const ACTIVE_EMAIL_FLOW = emailFlow.defineAction('ACTIVE_EMAIL_FLOW');
export const EDIT_EMAIL_FLOW = emailFlow.defineAction('EDIT_EMAIL_FLOW', [
  'SAVE',
  'SET_ACTIVE_CARD',
  'SET_CURRENT_COMPONENT',
]);
export const CANCEL_EMAIL_FLOW = emailFlow.defineAction('CANCEL_EMAIL_FLOW');
export const SET_SELECTED_PHYSICIANS = emailFlow.defineAction('SET_SELECTED_PHYSICIANS');
export const FETCH_PHYSICIANS = emailFlow.defineAction('FETCH_PHYSICIANS', [SUCCESS, FAILURE]);
export const FETCH_DETAILS = emailFlow.defineAction('FETCH_DETAILS', [SUCCESS, FAILURE]);
export const EDIT_ACTIVE_EMAIL_FLOW = emailFlow.defineAction('EDIT_ACTIVE_EMAIL_FLOW');
export const EDITOR_TEXT = emailFlow.defineAction('EDITOR_TEXT',
  [
    'FORMAT_TEXT',
    'UPDATE_EDITOR_STATE',
    'MODAL_LINK_SUBMIT',
    'MODAL_BUTTON_SUBMIT',
    'MODAL_IMAGE_SUBMIT',
    'AUTOMATED_TAG',
  ]);
export const ADD_EMAIL_FLOW = emailFlow.defineAction('ADD_EMAIL_FLOW');
export const FETCH_CREATE = emailFlow.defineAction('FETCH_CREATE', [SUCCESS, FAILURE]);
export const DELETE_EMAIL_FLOW = emailFlow.defineAction('DELETE_EMAIL_FLOW');
export const FETCH_DELETE = emailFlow.defineAction('FETCH_DELETE', [SUCCESS, FAILURE]);
export const DUPLICATE_EMAIL_FLOW = emailFlow.defineAction('DUPLICATE_EMAIL_FLOW');
export const FETCH_DELETE_COMPONENT = emailFlow.defineAction('FETCH_DELETE_COMPONENT', [SUCCESS, FAILURE]);
export const FETCH_UPDATE_COMPONENT = emailFlow.defineAction('FETCH_UPDATE_COMPONENT', [SUCCESS, FAILURE]);
export const EDIT_EMAIL_FLOW_COMPONENT = emailFlow.defineAction('EDIT_EMAIL_FLOW_COMPONENT');
export const FETCH_DETAILS_CLEAR = emailFlow.defineAction('FETCH_DETAILS_CLEAR');
export const FETCH_DETAILS_ADD_NEW_COMPONENT = emailFlow.defineAction('FETCH_DETAILS_ADD_NEW_COMPONENT');
export const FETCH_CREATE_COMPONENT = emailFlow.defineAction('FETCH_CREATE_COMPONENT', [SUCCESS, FAILURE]);
export const FETCH_DETAILS_UPDATE_COMPONENT = emailFlow.defineAction('FETCH_DETAILS_UPDATE_COMPONENT');
export const CLEAR_ERRORS_COMPONENT = emailFlow.defineAction('CLEAR_ERRORS_COMPONENT');
export const SET_ACTIVE_EMAIL_FLOW_CARD = emailFlow.defineAction('SET_ACTIVE_EMAIL_FLOW_CARD');
export const FETCH_SEND_TEST_EMAIL = emailFlow.defineAction('FETCH_SEND_TEST_EMAIL', [SUCCESS, FAILURE]);
