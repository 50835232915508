import React from 'react';
import classnames from 'classnames/bind';

import Align from './constants';

import style from './Column.scss';

const cn = classnames.bind(style);

type ColumnProps = {
  children?: React.ReactNode | null;
  align?: Align;
  classes?: string;
  onClick?: () => void;
};

function Column({
  children = null,
  align = Align.left,
  classes,
  onClick,
}: ColumnProps): JSX.Element {
  return (
    <td
      className={cn(style.column, classes)}
      align={Align[align]}
      onClick={onClick}
      onKeyPress={onClick}
      role="gridcell"
    >
      {children}
    </td>
  );
}

export default Column;
