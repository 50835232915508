import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    backgroundColor: theme.palette.background.default,
  },
  fallback: {
    color: theme.palette.grey[100],
  },
  hint: {
    color: theme.palette.text.hint,
  },
  businessName: {
    wordBreak: 'break-all',
  },
}));
