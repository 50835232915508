import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Body,
  Button,
  CircularProgress,
  MaterialUICore,
  MaterialIcons,
} from '@iclinic/design-system';

import { useInfiniteScroll } from 'features/tissInvoicing/hooks';
import { getLotListState } from 'features/tissInvoicing/state/lot/selectors';
import { actions } from 'features/tissInvoicing/state/lot';
import { image } from 'shared/utils/urlGetter';
import {
  SpaceBetweenBox,
  CenterBox,
} from 'features/tissInvoicing/components/ui/Flexbox';
import {
  Table,
  TableCell,
  TableRow,
} from 'features/tissInvoicing/components/ui/Table';
import Filter from './Filter';
import Rows from './Rows';
import Columns from './Columns';
import * as Styles from './styles';
import { Lot } from 'features/tissInvoicing/types';

const { TableContainer, TableBody, TableHead } = MaterialUICore;

const { FilterList } = MaterialIcons;

function TableContent() {
  const dispatch = useDispatch();
  const { lots, loading, hasMoreData, hasLots } = useSelector(getLotListState);
  const classes = Styles.useStyles();

  const { infiniteScrollRef, pageNum } = useInfiniteScroll({
    hasMoreData,
    loading,
  });

  const [openFilterDrawer, setOpenFilterDrawer] = useState<boolean>(false);

  const handleFilterDrawer = useCallback(
    () => setOpenFilterDrawer((state) => !state),
    [setOpenFilterDrawer],
  );

  useEffect(() => {
    dispatch(actions.fetchLotesFilterPhysicians());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.fetchLotesFilterInsurance());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actions.fetchLotList({ page: pageNum }));
  }, [dispatch, pageNum]);

  return (
    <SpaceBetweenBox paddingX={2}>
      {lots.length === 0 && !loading && !hasLots ? (
        <CenterBox classes={{ root: classes.centerFlex }}>
          <img
            src={image('tissInvoicing.listLotArt')}
            alt="Ainda não existem lotes fechados."
          />
          <Body variant="xs">Ainda não existem lotes fechados.</Body>
        </CenterBox>
      ) : (
        <TableContainer classes={{ root: classes.container }}>
          <Button
            classes={{ root: classes.button }}
            color="transparent"
            startIcon={<FilterList />}
            onClick={handleFilterDrawer}
          >
            Filtrar
          </Button>
          <Table aria-label="Lista de lotes fechados" stickyHeader>
            <TableHead>
              <TableRow>
                <Columns />
              </TableRow>
            </TableHead>

            <TableBody>
              {lots.map((lot: Lot, idx: number) => {
                const isLastElement = lots.length === idx + 1;
                return (
                  <TableRow
                    key={lot.id}
                    hover
                    stripped={+true}
                    ref={isLastElement ? infiniteScrollRef : undefined}
                  >
                    <Rows rowData={lot} />
                  </TableRow>
                );
              })}

              {loading && (
                <TableRow hover>
                  <TableCell scope="row" align="center" colSpan={7}>
                    <CenterBox>
                      <CircularProgress label="Carregando lotes" />
                    </CenterBox>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>

          <Filter open={openFilterDrawer} handleClose={handleFilterDrawer} />
        </TableContainer>
      )}
    </SpaceBetweenBox>
  );
}

export default TableContent;
