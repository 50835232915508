import React from 'react';

import { MaterialUICore, Typography, Button } from '@iclinic/design-system';
import useStyles from './DeleteIntegrationModalStyles';
import formatMessage from '../../content';

const { Modal, Backdrop, Fade } = MaterialUICore;

type DeleteIntegrationModalProps = {
  show: boolean;
  hideModal: () => void;
  removeIntegration: () => void;
};

function DeleteIntegrationModal({
  show,
  hideModal,
  removeIntegration,
}: DeleteIntegrationModalProps) {
  const classes = useStyles();

  return (
    <Modal
      data-testid="remove-integration-modal"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={show}
      onClose={hideModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={show}>
        <div className={classes.paper}>
          <Typography
            variant="h2"
            className={classes.blockedMediasText}
            id="transition-modal-title"
            data-ga="remove-integration-confirmation"
          >
            {formatMessage({
              id: '@online-scheduling:integration-remove',
            })}
          </Typography>
          <Typography
            className={classes.bodyTextAcceptMedia}
            id="transition-modal-description"
          >
            {formatMessage({ id: '@online-scheduling:integration-modal-text' })}
          </Typography>
          <div className={classes.buttonsContainer}>
            <Button
              color="transparent"
              onClick={hideModal}
              data-ga="cancel-remove-integration"
            >
              {formatMessage({
                id: '@online-scheduling:integration-remove-cancel',
              })}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                removeIntegration();
                hideModal();
              }}
              data-ga="confirm-remove-integration"
            >
              {formatMessage({
                id: '@online-scheduling:integration-modal-remove',
              })}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default DeleteIntegrationModal;
