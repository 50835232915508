import { fork } from 'redux-saga/effects';

import messagingPackageCheckoutSagas from './checkout/sagas';
import messagingPackageDetailsSagas from './details/sagas';

const messagePackageSagas = [
  fork(messagingPackageCheckoutSagas),
  fork(messagingPackageDetailsSagas),
];

export default messagePackageSagas;
