import { IconButton, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled, TableCell: MuiTableCell } = MaterialUICore;
const { border, color, spacing } = tokens;

export const Root = styled('div')({
  maxWidth: 912,
  margin: '0 auto',
});

export const IconButtonClose = styled(IconButton)({
  position: 'absolute',
  top: spacing.md,
  right: spacing.md,
});

export const TableWrapper = styled('div')({
  maxHeight: 256,
  overflow: 'auto',
  border: `1px solid ${color.neutral[50]}`,
  borderRadius: border.radius.sm,
  alignSelf: 'stretch',
});

export const TableCell = styled(MuiTableCell)({
  '&:not(:last-child)': {
    borderRight: `1px solid ${color.neutral[50]}`,
  },

  '&.MuiTableCell-head': {
    backgroundColor: color.secondary.light,
  },

  '&.MuiTableCell-root': {
    borderBottom: `1px solid ${color.neutral[50]}`,
  },
});

export const TableCellSmall = styled(TableCell)({
  '&.MuiTableCell-root': {
    padding: `${spacing.xs} ${spacing.sm}`,
  },
});
