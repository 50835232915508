import { FormikErrors, FormikTouched, getIn } from 'formik';

import { GuideSADTForm } from 'features/tissInvoicing/types';

const getErrorText = (
  path: string,
  touched: FormikTouched<GuideSADTForm>,
  errors: FormikErrors<GuideSADTForm>,
) => getIn(touched, path) && getIn(errors, path);

export default getErrorText;
