import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => {
  return {
    container: {
      width: 244,
      flexShrink: 0,
      marginRight: tokens.spacing.sm,

      display: 'none',

      [theme.breakpoints.up('md')]: {
        display: 'initial',
      },
    },
    menuItem: {
      cursor: 'pointer',
      borderRadius: tokens.border.radius.sm,
      background: tokens.color.neutral[25],

      '&.Mui-selected': {
        background: tokens.color.secondary.main,

        '& .MuiListItemIcon-root': {
          color: tokens.color.primary.main,
        },

        '&:hover': {
          background: tokens.color.secondary.main,
        },
      },

      '& .MuiListItemIcon-root': {
        minWidth: 'auto',
        marginRight: tokens.spacing.sm,
      },

      '&:hover': {
        background: tokens.color.secondary.main,
      },
    },
    menu: {
      position: 'sticky',
      top: tokens.spacing.xxxl,
    },
  };
});
