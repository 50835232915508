import React from 'react';
import classnames from 'classnames/bind';

import style from './Row.scss';

const classes = classnames.bind(style);

type RowProps = {
  children: React.ReactNode;
  className?: string;
};

export default function Row({
  children,
  className = '',
  ...rest
}: RowProps): JSX.Element {
  return (
    <div className={classes('row', className)} {...rest}>
      {children}
    </div>
  );
}
