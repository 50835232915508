import React, { useEffect, useRef, useState } from 'react';

import Alert from 'features/video-conference/components/TeamComponents/Alert';
import { useAnnotationContext } from 'features/video-conference/state/annotationContext';
import { usePrevious } from 'shared/hooks';
import { Annotation } from 'features/video-conference/constants/types';
import {
  TYPE_HERE,
  PATIENT_NOTES_PHYSICIAN_WARNING,
  tabsValue,
} from 'features/video-conference/constants';
import {
  AnnotationContainer,
  AlertContainer,
  TextFieldContainer,
  TextField,
} from './styles';

export default function NotesPanel() {
  const {
    isAnnotationWindowOpen,
    activeTab,
    handleSaveNotes,
    clinicNotes,
    patientNotes,
    handleFetchUpdateAnnotation,
  } = useAnnotationContext();
  const prevActiveTab = usePrevious(activeTab);
  const [notes, setNotes] = useState<string>('');

  const textInputRef = useRef<HTMLDivElement>(null);
  const message = <span> {PATIENT_NOTES_PHYSICIAN_WARNING} </span>;

  useEffect(() => {
    setNotes('');

    if (clinicNotes && activeTab === tabsValue.CLINIC_NOTE)
      setNotes(clinicNotes.message);
    if (patientNotes && activeTab === tabsValue.PATIENT_NOTE)
      setNotes(patientNotes.message);
  }, [activeTab, clinicNotes, patientNotes, setNotes]);

  useEffect(() => {
    const tabChanged = activeTab !== prevActiveTab;
    if (isAnnotationWindowOpen || tabChanged) textInputRef.current?.focus();
  }, [activeTab, isAnnotationWindowOpen, prevActiveTab]);

  const createAnnotationObject = (note = notes) => {
    return {
      at: new Date().getTime(),
      message: note,
    } as Annotation;
  };

  const handleChange = (event: any) => {
    const note = event.currentTarget.value;
    setNotes(note);
    const annotationObject = createAnnotationObject(note);
    handleFetchUpdateAnnotation(annotationObject);
  };

  const handleBlur = () => {
    const annotationObject = createAnnotationObject();
    handleSaveNotes(annotationObject);
  };

  const handleFocus = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    event.currentTarget.setSelectionRange(
      event.currentTarget.value.length,
      event.currentTarget.value.length,
    );
  };

  return (
    <AnnotationContainer>
      {activeTab === tabsValue.PATIENT_NOTE && (
        <AlertContainer>
          <Alert message={message} />
        </AlertContainer>
      )}
      <TextFieldContainer>
        <TextField
          aria-label="notes-input"
          placeholder={TYPE_HERE}
          multiline
          inputRef={textInputRef}
          value={notes}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={(event) => handleFocus(event)}
        />
      </TextFieldContainer>
    </AnnotationContainer>
  );
}
