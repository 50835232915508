import { useState } from 'react';

/**
 *  Used to reset SVG animations with a delay in ms
 *
 * @param resetTime number
 * @returns resetAnimation, handleAnimationEnd
 */
export default function useResetAnimation(resetTime?: number) {
  const [resetAnimation, setResetAnimation] = useState<boolean>(false);

  function handleAnimationEnd() {
    if (!resetTime) return;
    const timer = setTimeout(() => {
      setResetAnimation(true);
      requestAnimationFrame(() => {
        setResetAnimation(false);
      });
      clearTimeout(timer);
    }, resetTime);
  }

  return { resetAnimation, handleAnimationEnd };
}
