import React from 'react';
import { useSelector } from 'react-redux';
import { MaterialIcons, Grid, Box, Body } from '@iclinic/design-system';

import { ErrorAlert } from 'shared/components';
import { RequestStatus } from 'shared/constants/State';
import {
  getPatientsByEventsData,
  getPatientsByEventsDataErrorMessage,
  getPatientsByEventsDataStatus
} from './state/selectors';
import { LoaderColumnInfos } from './Loader';
import * as S from './styles';

const {
  DateRange,
  CheckCircleOutlineOutlined,
  Block,
} = MaterialIcons;

export default function PatientsByEvents() {
  const patientsByEventsData = useSelector(getPatientsByEventsData);
  const fetchStatus = useSelector(getPatientsByEventsDataStatus);
  const fetchError = useSelector(getPatientsByEventsDataErrorMessage);

  if (fetchStatus === RequestStatus.Pending) {
    return (
      <S.Paper variant="outlined">
        <LoaderColumnInfos />
      </S.Paper>
    );
  }

  if (fetchError || !patientsByEventsData) {
    return (
      <S.Paper variant="outlined">
        <ErrorAlert
          message={
            <span dangerouslySetInnerHTML={{ __html: fetchError || '' }} />
          }
        />
      </S.Paper>
    );
  }

  const {
    scheduled,
    confirmed,
    attended,
    absences,
  } = patientsByEventsData.events;

  return (
    <S.Paper variant="outlined">
      <Grid spacing={3} item container justifyContent="center">
        <Grid item xs={12} md={3}>
          <S.WrapContent textColor="neutral" variant={scheduled}>
            <S.Quantity variant="xxl">{scheduled}</S.Quantity>
            <Box mb={2}>
              <Body variant="xxs">Pacientes agendados</Body>
            </Box>
            <DateRange />
          </S.WrapContent>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box textAlign="center">
            <S.WrapContent textColor="primary" variant={confirmed}>
              <S.Quantity variant="xxl">{confirmed}</S.Quantity>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <Body variant="xxs">Pacientes confirmados</Body>
              </Box>
              <CheckCircleOutlineOutlined />
            </S.WrapContent>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box textAlign="center">
            <S.WrapContent textColor="success" variant={attended}>
              <S.Quantity variant="xxl">{attended}</S.Quantity>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <Body variant="xxs">Pacientes atendidos</Body>
              </Box>
              <CheckCircleOutlineOutlined />
            </S.WrapContent>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box textAlign="center">
            <S.WrapContent textColor="error" variant={absences}>
              <S.Quantity variant="xxl">{absences}</S.Quantity>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={2}
              >
                <Body variant="xxs">Pacientes que faltaram</Body>
              </Box>
              <Block />
            </S.WrapContent>
          </Box>
        </Grid>
      </Grid>
    </S.Paper>
  );
}
