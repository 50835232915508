import { useEffect, useRef } from 'react';

import { isMobile } from 'features/video-conference/utils';
import useLocalVideoToggle from 'features/video-conference/hooks/useLocalVideoToggle';

export default function AttachVisibilityHandler() {
  const { isVideoEnabled, toggleVideoEnabled } = useLocalVideoToggle();
  const shouldRepublishVideoOnForeground = useRef(false);

  useEffect(() => {
    if (!isMobile) return () => null;

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && isVideoEnabled) {
        shouldRepublishVideoOnForeground.current = true;
        toggleVideoEnabled();
      } else if (shouldRepublishVideoOnForeground.current) {
        shouldRepublishVideoOnForeground.current = false;
        toggleVideoEnabled();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isVideoEnabled, toggleVideoEnabled]);

  return null;
}
