import { MaterialUICore, axiomTokens, Button } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const TermsContainer = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'grid',
  gridTemplateRows: '40px minmax(0px, 79.9%) 1fr',

  [theme.breakpoints.down('xs')]: {
    gridTemplateRows: '64px minmax(0px, 79.9%) 1fr',
  },
}));

export const TermsTitle = styled('h3')(() => ({
  margin: '0px',
  fontWeight: axiomTokens.font.weight.bold,
  fontSize: axiomTokens.font.size.sm,
  lineHeight: '23.76px',
  letterSpacing: axiomTokens.font.letterSpacing.sm,
  color: axiomTokens.color.typeface.pure,
  marginBottom: '16px',
}));

export const TermsText = styled('div')(() => ({
  height: '100%',
  overflowY: 'auto',
  paddingRight: axiomTokens.spacing[2],
  fontWeight: axiomTokens.font.weight.regular,
  fontSize: axiomTokens.font.size.xs,
  letterSpacing: axiomTokens.font.letterSpacing.sm,
  color: axiomTokens.color.typeface.pure,

  '& a': {
    color: axiomTokens.color.typeface.pure,
    textDecoration: 'underline',
  },

  '& ol': {
    listStylePosition: 'inside',
  },

  '& li': {
    marginBottom: '10px',
  },

  '&::-webkit-scrollbar': {
    width: '8px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: axiomTokens.color.others.divider,
    borderRadius: axiomTokens.border.radius.lg,
  },

  '&::-webkit-scrollbar-track': {
    background: axiomTokens.color.others.skeleton,
    width: '8px',
    borderRadius: axiomTokens.border.radius.lg,
  },
}));

export const TermsAcceptArea = styled('div')(() => ({
  marginTop: axiomTokens.spacing[4],
  paddingTop: axiomTokens.spacing[2],
  borderTop: `${axiomTokens.border.width.xs} solid ${axiomTokens.color.others.divider}`,

  '& .PrivateSwitchBase-root': {
    padding: '0px',
  },

  '& .MuiButtonBase-root': {
    padding: '0px',
  },

  '& input[type="checkbox"]': {
    margin: '0px',
  },

  '& .MuiFormControlLabel-root': {
    margin: '0px',
  },

  '& .MuiTypography-root': {
    fontSize: axiomTokens.font.size.xxs,
    fontWeight: axiomTokens.font.weight.medium,
    color: axiomTokens.color.typeface.pure,
    letterSpacing: axiomTokens.font.letterSpacing.sm,
  },
}));

export const ContinueButton = styled(Button)(() => ({
  padding: `${axiomTokens.spacing[2]} 0px`,
  marginTop: axiomTokens.spacing[4],
}));
