/* eslint-disable react/no-array-index-key */
import React from 'react';

import { Box, MaterialUICore } from '@iclinic/design-system';
import {
  UserData,
  trackSignupIClinicPayButtons,
} from 'features/onlinePayment/trackEvents/trackSignupIClinicPayEvents';
import { PLANS, REGULAR_PLAN_FEES, FAST_PLAN_FEES } from './constants';
import * as S from '../../components/lp-iclinic-pay/styles';

const { TableContainer, TableRow, TableCell, TableBody } = MaterialUICore;

type FeeTableProps = {
  userData: UserData;
  plan: string;
  setPlan: (val: PLANS) => void;
};

export default function FeeTable({
  userData,
  plan,
  setPlan,
}: FeeTableProps): JSX.Element {
  const feeSByPlan =
    plan === PLANS.Regular ? REGULAR_PLAN_FEES : FAST_PLAN_FEES;

  const handleClickPlanChange = () => {
    trackSignupIClinicPayButtons({
      userData,
      buttonName: 'pay_way_receive_money',
      buttonLocation: 'create_payment',
      additionalProperties: 'LP',
    });
    if (plan === PLANS.Fast) {
      setPlan(PLANS.Regular);
      return;
    }
    setPlan(PLANS.Fast);
  };

  return (
    <>
      <Box
        mb={{ xs: 2, md: 5 }}
        display="flex"
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="center"
      >
        <S.SwitchLabel variant="sm" plan={PLANS.Regular} selectedplan={plan}>
          Receba a partir de 30 dias
        </S.SwitchLabel>
        <S.SwitchWrapper>
          <S.SwitchTrack>
            <S.SwitchInnerText
              variant="sm"
              plan={PLANS.Regular}
              selectedplan={plan}
            >
              Regular
            </S.SwitchInnerText>
            <S.SwitchInnerText
              variant="sm"
              plan={PLANS.Fast}
              selectedplan={plan}
            >
              Fast
            </S.SwitchInnerText>
          </S.SwitchTrack>
          <S.SwitchThumb plan={plan} />
          <S.SwitchButton onClick={handleClickPlanChange} />
        </S.SwitchWrapper>
        <S.SwitchLabel variant="sm" plan={PLANS.Fast} selectedplan={plan}>
          Receba no 1º dia útil
        </S.SwitchLabel>
      </Box>
      <S.Paper variant="outlined">
        <TableContainer>
          <S.Table aria-label="Tabela de taxas por bandeira de cartão">
            <S.TableHead>
              <TableRow>
                <TableCell align="center">Débito</TableCell>
                <TableCell align="center">Crédito à vista</TableCell>
                <TableCell align="center">2x</TableCell>
                <TableCell align="center">3x</TableCell>
                <TableCell align="center">4x</TableCell>
                <TableCell align="center">5x</TableCell>
                <TableCell align="center">6x</TableCell>
                <TableCell align="center">7x</TableCell>
                <TableCell align="center">8x</TableCell>
                <TableCell align="center">9x</TableCell>
                <TableCell align="center">10x</TableCell>
                <TableCell align="center">11x</TableCell>
                <TableCell align="center">12x</TableCell>
              </TableRow>
            </S.TableHead>
            <TableBody>
              <TableRow>
                {feeSByPlan.map((fee, index) => (
                  <TableCell align="center" key={index}>
                    {fee}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </S.Table>
        </TableContainer>
      </S.Paper>
    </>
  );
}
