import moment from 'moment';
import { ItemImg, ImageFormat } from './types';

export const normalizeImages = (images: ImageFormat[]) => images.reduce((items, item) => {
  item.files.map((img) => items.push(img));
  return items;
}, []);

export const filterImages = (images: ItemImg[]) => {
  const avaiableExtensions = ['jpeg', 'png', 'gif', 'jpg'];
  const results = images.filter((
    img: ItemImg,
  ) => avaiableExtensions.includes(img.extension.toLowerCase()));
  return results;
};

export const groupImages = (
  images: ItemImg[],
) => images.reduce((acc, val) => {
  const date = val.date_added.substr(0, 10);
  const group = acc.find((obj) => obj.date === date);
  if (group) {
    group.images.push(val);
  } else {
    acc.push({ date, images: [val] });
  }
  return acc;
}, []);

export const getFormatedDate = (date: string) => {
  moment.locale('pt-br');
  const dt = moment(date, 'YYYY-MM-DD');
  return `${dt.format('DD')} de ${[dt.format('MMMM')]} de ${dt.format('YYYY')}`;
};

export const selectImg = (
  id: number,
  images: ItemImg[],
) => images.map(
  (image) => {
    if (image.id === id) {
      return { ...image, checked: !image.checked };
    }
    return image;
  },
);

export const selectImgIndex = (
  itemImg: ItemImg,
  images: ItemImg[],
) => images.findIndex((i) => i.id === itemImg.id);
