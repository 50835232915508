/* eslint-disable import/prefer-default-export */
import { ModalType } from 'features/stock/constants';

export const getModalTitle = (amount: number, modalType: ModalType): string =>
  ({
    [ModalType.Add]: 'Novo Produto',
    [ModalType.Edit]: 'Editar Produto',
    [ModalType.Input]: 'Entrada',
    [ModalType.Output]: 'Saída',
    [ModalType.Delete]: `Excluir Produto${amount > 1 ? 's' : ''}`,
  }[modalType]);
