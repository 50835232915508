import { MaterialUICore, Box as DSBox, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const PatientParents = styled(DSBox)(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  width: `calc(50% - ${tokens.spacing.md})`,
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginBottom: tokens.spacing.base,
    margin: '0 auto',
  },
}));

export const ButtonContainer = styled(DSBox)(({ theme }) => ({
  position: 'absolute',
  top: -4,
  right: 0,
  left: 0,
  margin: '0px auto 0px 100px',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    position: 'static',
    margin: '0px',
    padding: `${tokens.spacing.base} 0px`,
  },
}));
