import React from 'react';
import ContentLoader from 'react-content-loader';

import { ResponsiveCard } from '../..';

const values = {
  backgroundColor: '#ECEFF1',
  foregroundColor: '#F8F9FA',
  speed: 1,
};

const CardDocumentsLoader = () => (
  <ResponsiveCard variant="outlined">
    <ContentLoader
      speed={values.speed}
      width="100%"
      height={321}
      backgroundColor={values.backgroundColor}
      foregroundColor={values.foregroundColor}
      uniqueKey="documents-loader"
    >
      <rect x="0" y="0" rx="2" ry="2" width="200" height="40" />
      <rect x="0" y="48" rx="2" ry="2" width="500" height="16" />
      <rect x="0" y="88" rx="2" ry="2" width="100%" height="96" />
      <rect x="0" y="208" rx="2" ry="2" width="100%" height="96" />
    </ContentLoader>
  </ResponsiveCard>
);

export default CardDocumentsLoader;
