import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { color, spacing, border } = tokens;

export const FormPayment = styled('button')(() => ({
  backgroundColor: color.neutral[0],
  border: `1px solid ${color.primary.main}`,
  borderRadius: border.radius.md,
  cursor: 'pointer',
  marginBottom: spacing.sm,
  padding: spacing.sm,
  width: '100%',

  '&:hover': {
    backgroundColor: color.secondary.main,
  },

  '& svg, & img, & p': {
    display: 'inline-block',
    verticalAlign: 'middle',
  },

  '& p': {
    marginLeft: spacing.xs,
  },
}));
