import { Button, MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;

export const Footer = styled('footer')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  paddingLeft: tokens.spacing.sm,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column-reverse',
    paddingLeft: 0,
    alignItems: 'flex-end',
    paddingBottom: '20px',
  },
}));

export const FooterActionsLine = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: tokens.spacing.sm,
  flexGrow: 1,

  padding: tokens.spacing.sm,

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    width: '100%',
    '& > div': {
      width: '100%',
    },
  },
}));

export const ActionsButton = styled(Button)(({ theme }) => ({
  fontWeight: tokens.font.weight.medium,

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
