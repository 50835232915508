// External
import React from 'react';

// Internal
import style from './Container.scss';

type HeaderProps = {
  children: React.ReactNode;
};

export default function Container({
  children,
}: HeaderProps): JSX.Element {
  return (
    <div className={style.wrapper}>
      {children}
    </div>
  );
}
