import { MaterialUICore, tokens } from '@iclinic/design-system';

const { makeStyles, createStyles } = MaterialUICore;
const { color, spacing } = tokens;

type Props = {
  containerSize?: string;
};

export default makeStyles<{}, Props>(() =>
  createStyles({
    container: {
      width: ({ containerSize }) =>
        containerSize === 'small' ? '304px' : '100%',
      padding: ({ containerSize }) =>
        containerSize === 'small' ? spacing.sm : 0,
      height: '255px',
      '& > *': {
        marginBottom: spacing.md,
      },
    },
    iconColor: {
      color: color.neutral['500'],
    },
  }),
);
