import axios from 'axios';

import {
  interceptorRequestAuthentication,
  interceptorResponseErrorsPromise,
  interceptorResponseTransformData,
} from 'services/iclinic/interceptors';

const apiOnlineSchedule = axios.create({
  baseURL: process.env.ICLINIC_API_URL,
});

const apiAgendarConsulta = axios.create({
  baseURL: process.env.ICLINIC_API_BASE_URL,
});

apiOnlineSchedule.interceptors.request.use(interceptorRequestAuthentication);
apiOnlineSchedule.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrorsPromise,
);

apiAgendarConsulta.interceptors.request.use(interceptorRequestAuthentication);
apiAgendarConsulta.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrorsPromise,
);

export const getPhysicians = (subscriptionId, clinicId) =>
  apiOnlineSchedule.get(
    `/subscriptions/subscription/${subscriptionId}/physicians-by-clinic-with-functionality/?clinic=${clinicId}&funcionality=online-scheduling`,
  );

export const getSettingsId = (physicianId, activeclinic) =>
  apiOnlineSchedule.get(
    `/accounts/onlineschedulingsettings/physician-settings/${physicianId}/${activeclinic}/`,
  );

export const getSettingsDetail = (id) =>
  apiOnlineSchedule.get(`/accounts/onlineschedulingsettings/${id}/settings/`);

export const updadeSettingsHeader = (
  profileclinicId: number,
  profile: number,
  hasOnlineScheduleActive: boolean,
  clinic: number,
) =>
  apiOnlineSchedule.put(`/accounts/userprofileclinic/${profileclinicId}/`, {
    profile,
    has_online_schedule_active: hasOnlineScheduleActive,
    clinic,
  });

export const updateSettingsDetail = (
  id,
  clinic,
  service_periods,
  physician,
  antecedence_period,
) =>
  new Promise((resolve, reject) => {
    apiOnlineSchedule
      .put(`/accounts/onlineschedulingsettings/${id}/`, {
        clinic,
        physician,
        service_periods,
        antecedence_period,
      })
      .then((response) => {
        if (response.status === 400) {
          reject(response);
        }
        resolve(response);
      })
      .catch(reject);
  });

export const updateInsurancesSchedule = (batchInsurances) =>
  apiOnlineSchedule.patch('/accounts/physicianinsurance/', batchInsurances);

export const getScheduleHours = (clinicId, physicianId) =>
  apiOnlineSchedule.get(
    `/schedule/schedule/schedule-physician/${clinicId}/${physicianId}/`,
  );

export const removeIntegration = (physicianId: number) =>
  apiAgendarConsulta.delete(
    `/agendar-consulta/remove-online-scheduling/physicians/${physicianId}/`,
  );

export const validateEmailIntegration = (email: string) =>
  apiAgendarConsulta.post('/agendar-consulta/auth/validate-email/', {
    email,
  });

export const createValidatePasswordIntegration = (
  email: string,
  password: string,
  hasAcceptedTerns?: boolean | null,
) =>
  apiAgendarConsulta.post('/agendar-consulta/auth/signup/', {
    email,
    password,
    has_accepted_terms: hasAcceptedTerns,
  });
