import React, { useState } from 'react';
import { Tooltip, MaterialIcons } from '@iclinic/design-system';

import ToggleButton from 'features/video-conference/components/TeamComponents/ToggleButton';
import { buttonsTitle } from 'features/video-conference/constants';
import DeviceSelectionDialog from 'features/video-conference/components/DeviceSelection';
import useStyles from './styles';
import { trackFeatureUsage } from 'features/video-conference/utils/eventTracking';
import { useConference } from 'features/video-conference/state/conferenceContext';
import { useParticipantContext } from 'features/video-conference/state/participantContext';

const { Settings } = MaterialIcons;

export default function SettingsButton() {
  const classes = useStyles();
  const { isPhysician, conferenceId } = useConference();
  const { localParticipant, remoteParticipant } = useParticipantContext();
  const [openSettings, setOpenSettings] = useState(false);

  const handleOpenSettings = () => {
    trackFeatureUsage({
      localParticipant,
      remoteParticipant,
      conferenceId,
      isPhysician,
      buttonName: 'telemedicine_settings',
      buttonLocation: 'toolbar',
      action: openSettings ? 'disabled' : 'enabled',
    });
    setOpenSettings(!openSettings);
  };

  return (
    <div className={classes.root}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        classes={{ tooltip: classes.whiteTooltip }}
        open={openSettings}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={<DeviceSelectionDialog location="toolbar" />}
        arrow
        placement="top"
      >
        <div>
          <ToggleButton
            onClick={handleOpenSettings}
            switchedOn={!openSettings}
            variant="info"
            updateOn={false}
            title={buttonsTitle.SETTINGS}
            label={buttonsTitle.SETTINGS}
            icon={<Settings />}
            aria-controls="devices-settings"
            dataGa="telemedicine_settings"
            dataSp="telemedicine_settings"
          />
        </div>
      </Tooltip>
    </div>
  );
}
