import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';

export function useRestartAudioVideoTrack(
  localTracks: (LocalAudioTrack | LocalVideoTrack)[],
) {
  const handleRestartTracks = () => {
    const audioTrack = localTracks.find((track) => track.kind === 'audio');
    const videoTrack = localTracks.find((track) => track.kind === 'video');

    audioTrack?.restart({});
    videoTrack?.restart({});
  };

  return { handleRestartTracks };
}
