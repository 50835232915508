import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  toggleDrawer,
  signatureChangeStep,
  finalizeMedicalRecordSuccess,
  clearSignPDFErrors,
} from 'state/records/signature/actions';
import { clearSharedErrors } from 'state/shared/actions';
import {
  MaterialUICore,
  MaterialIcons,
  Button,
  Typography,
} from '@iclinic/design-system';
import useStyles from './styleSignatureSetup';
import { toggleState, STEP_SIGNATURE } from './constants';
import { getSteps, getStepContent } from './steppers';
import useSignature from '../hooks/useSignature';
import StepButtons from '../components/StepButtons';

const { Drawer, Stepper, Step, StepLabel, StepContent } = MaterialUICore;

const SignatureSetup: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { webpkiInstall, listCertificates } = useSignature();
  const isOpenDrawer = useSelector(
    (state) => state.records.signature.isOpenDrawer,
  );
  const { CLOSE } = toggleState;

  const onClickToggleDrawer = useCallback(
    (drawerState: boolean) => {
      dispatch(toggleDrawer(drawerState));
    },
    [dispatch],
  );

  const [activeStep, setActiveStep] = useState(0);
  const checkFinalStep = activeStep === 2;
  const steps = getSteps();

  const onClickFinalize = useCallback(() => {
    dispatch(clearSignPDFErrors());
    const checkWebpkiInstall = !webpkiInstall;
    const checkCertificatesInstall =
      listCertificates.certificates?.length === 0;

    if (checkWebpkiInstall || checkCertificatesInstall) {
      setActiveStep(0);
      return;
    }

    dispatch(signatureChangeStep(STEP_SIGNATURE.Certificates));
  }, [dispatch, listCertificates.certificates, webpkiInstall]);

  const onBackButton = () => {
    dispatch(clearSignPDFErrors());
    dispatch(clearSharedErrors());
    dispatch(toggleDrawer(CLOSE));
  };

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="persistent"
      anchor="right"
      open={isOpenDrawer}
      onClose={() => onClickToggleDrawer(CLOSE)}
      data-testid="signature-setup"
    >
      <div className={classes.content}>
        <Button
          className={classes.backButton}
          startIcon={<MaterialIcons.ArrowBack />}
          onClick={onBackButton}
          color="transparent"
        >
          <Typography variant="overline">Voltar</Typography>
        </Button>
        <div className={classes.mainContent}>
          <div className={classes.title}>
            <Typography variant="h3">
              Não conseguimos encontrar seu certificado digital :(
            </Typography>
            <Typography variant="body2" className={classes.bodyText}>
              Para assinar digitalmente, basta seguir os 3 passos abaixo:
            </Typography>
          </div>
          <div className={classes.stepper}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    <Typography className={classes.stepperContent}>
                      {getStepContent(index)}
                    </Typography>
                    <div className={classes.actionsContainer}>
                      <StepButtons
                        setActiveStep={setActiveStep}
                        activeStep={activeStep}
                      />
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <Button
          className={classes.closeButton}
          data-ga="assinatura_digital-setup-prosseguir_sem_assinar"
          onClick={() => dispatch(finalizeMedicalRecordSuccess())}
          color="transparent"
        >
          <Typography variant="body1">Prosseguir sem assinar</Typography>
        </Button>
        {checkFinalStep && (
          <Button
            className={classes.footerButton}
            variant="contained"
            color="primary"
            type="submit"
            data-ga="assinatura_digital-setup-finalizar_instalacao"
            onClick={onClickFinalize}
          >
            Finalizar instalação
          </Button>
        )}
      </div>
    </Drawer>
  );
};

export default SignatureSetup;
