/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';

import { Box, Typography, MaterialUICore } from '@iclinic/design-system';
import { readFile, isValidExtension } from 'shared/utils/file';
import { useMountedRef } from 'shared/hooks';
import useStyles from './styles';
import Thumb from './Thumb';
import UploadHelperText from './UploadHelperText';
import { EVENT_KEY } from 'shared/constants/keysCode';

const { FormControl } = MaterialUICore;

export type UploadData = {
  base64?: string;
  file: File;
};

type Props = {
  icon: React.ReactElement;
  placeholder: string;
  name?: string;
  value?: File | string;
  extensions?: string[];
  error?: boolean;
  disabled?: boolean;
  helperText?: React.ReactNode;
  onChange(data: UploadData): void;
};

const useThumb = (value?: File | string) => {
  const [thumb, setThumb] = useState<string | undefined>();
  const mounted = useMountedRef();

  useEffect(() => {
    if (typeof value === 'string') {
      setThumb(value);
    } else if (value instanceof File) {
      readFile(value).then((base64?: string) => {
        if (mounted.current) {
          setThumb(base64);
        }
      });
    }
  }, [value, mounted]);

  return thumb;
};

export default function Upload({
  icon,
  placeholder,
  name,
  value,
  extensions,
  disabled = false,
  error = false,
  helperText,
  onChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const thumb = useThumb(value);
  const [internalError, setInternalError] = useState<string | null>(null);
  const input = useRef<HTMLInputElement>(null);
  const hasError = internalError != null || error;

  const classNames = classnames(classes.upload, {
    [`${classes.error} Mui-error`]: hasError,
    [classes.disabled]: disabled,
  });

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInternalError(null);
      const file = e?.target?.files?.[0];
      if (file) {
        if (isValidExtension(file, extensions)) {
          readFile(file).then((base64?: string) => {
            onChange({
              file,
              base64,
            });
          });
        } else {
          setInternalError('Formato de arquivo não suportado');
        }
      }
    },
    [extensions, onChange],
  );

  const handlekeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if ([EVENT_KEY.ENTER, EVENT_KEY.SPACE].includes(e.key)) {
        input?.current?.click();
      }
    },
    [input],
  );

  return (
    <FormControl variant="outlined" error={hasError} fullWidth>
      <Box
        className={classNames}
        display="flex"
        component="label"
        role="button"
        tabIndex={0}
        onKeyDown={handlekeyDown}
        aria-describedby={name}
        aria-invalid={!!hasError}
      >
        <input
          ref={input}
          name={name}
          type="file"
          className={classes.input}
          disabled={disabled}
          onChange={handleChange}
          accept={extensions?.join(',')}
        />
        <Box
          className={classes.image}
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="80px"
          flexShrink={0}
        >
          {thumb ? <Thumb value={thumb} /> : icon}
        </Box>
        <Box display="flex" alignItems="center" p={2}>
          <Typography variant="h5" classes={{ h5: classes.placeholder }}>
            {placeholder}
          </Typography>
        </Box>
      </Box>
      <UploadHelperText
        id={name}
        error={internalError}
        helperText={helperText}
      />
    </FormControl>
  );
}
