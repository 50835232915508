import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RequestStatus } from 'shared/constants/State';
import { ExportDownload } from './types';

export interface DownloadState {
  requestedExport: {
    data?: ExportDownload | null;
    status: RequestStatus;
  };
  downloadExportFile?: {
    status?: RequestStatus;
  };
}

export const initialState: DownloadState = {
  requestedExport: {
    data: null,
    status: RequestStatus.Pending,
  },
  downloadExportFile: {
    status: RequestStatus.Pending,
  },
};

const name = 'exportDownload';

export const init = createAction(`${name}/init`);

const DownloadSlice = createSlice({
  name,
  initialState,
  reducers: {
    fetchExportDownload: (state) => ({
      ...state,
      requestedExport: {
        status: RequestStatus.Pending,
      },
    }),
    fetchExportDownloadSuccess: (
      state,
      action: PayloadAction<ExportDownload>,
    ) => ({
      ...state,
      requestedExport: {
        data: action.payload,
        status: RequestStatus.Success,
      },
    }),
    fetchExportDownloadFailure: (state) => ({
      ...state,
      requestedExport: {
        status: RequestStatus.Error,
      },
    }),
    downloadExportFile: (state) => ({
      ...state,
      downloadExportFile: {
        status: RequestStatus.Pending,
      },
    }),
    downloadExportFileSuccess: (state) => ({
      ...state,
      downloadExportFile: {
        status: RequestStatus.Success,
      },
    }),
    downloadExportFileFailure: (state) => ({
      ...state,
      downloadExportFile: {
        status: RequestStatus.Error,
      },
    }),
  },
});

export default DownloadSlice.reducer;

export const {
  fetchExportDownload,
  fetchExportDownloadSuccess,
  fetchExportDownloadFailure,
  downloadExportFile,
  downloadExportFileSuccess,
  downloadExportFileFailure,
} = DownloadSlice.actions;
