import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, MaterialIcons } from '@iclinic/design-system';

import { getInitials } from 'features/video-conference/utils';
import * as S from './styles';
import { getSchedulePatientSelector } from 'features/newFinance/state/selectors';
import { FlexContainer } from 'features/newFinance/components/styles';

const { Info } = MaterialIcons;

const PersonalInfo = (): JSX.Element => {
  const {
    name,
    mobile_phone: mobilePhone,
    email,
    age_full_described: ageFullDescribed,
    healthInsurance,
    picture,
    scheduleTime,
  } = useSelector(getSchedulePatientSelector);

  return (
    <>
      <S.DateInfo className="only_desktop">
        {scheduleTime}
        <Info />
      </S.DateInfo>
      <S.PersonalInfo className="personal_info">
        <div>
          <Avatar size="md" alt="name" src={picture}>
            {getInitials(name)}
          </Avatar>

          <S.DateInfo className="only_mobile">
            {scheduleTime}
            <Info />
          </S.DateInfo>
        </div>
        <FlexContainer orientation="column">
          <span className="personal_name">{name}</span>
          <FlexContainer>
            <S.LeftSubContainer orientation="column">
              <span>{mobilePhone}</span>
              <span>{email}</span>
              <span>
                Convênio: <b>{healthInsurance}</b>
              </span>
            </S.LeftSubContainer>
            <S.RightSubContainer orientation="column">
              <span>{ageFullDescribed}</span>
              <span>
                Última consulta: <b>há 0 dias</b>
              </span>
            </S.RightSubContainer>
          </FlexContainer>
        </FlexContainer>
      </S.PersonalInfo>
    </>
  );
};

export default PersonalInfo;
