import { createAction, createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

import { IPaidByOtherPersonState, PaymentProps } from '../types/types';

const nameStore = '@webapp/schedulePayments';

export const initialState: PaymentProps = {
  paidByOtherPerson: {
    fullName: '',
    kinshipCPF: '',
    kinshipDegree: '',
    hasPaidByOtherPerson: false,
  },
  paymentList: [],
  discount: {
    discountMethod: 'cash',
    discountValue: 0,
  },
  values: {
    total: 0,
    subTotal: 0,
    discountedValue: 0,
    remainingAmount: null,
  },
  paymentConfig: {
    defaultBankAccount: '',
    bankAccountOptions: [],
    defaultCash: '',
    cashOptions: [],
    defaultPaymentForm: '',
    paymentFormOptions: [],
    defaultCreditCardInstallment: '',
    creditCardPaymentOptions: [],
  },
};

export const setSchedulePayment = createAction(
  `${nameStore}/payment/setPayment`,
);

const SchedulePaymentSlice = createSlice({
  name: `${nameStore}/payment`,
  initialState,
  reducers: {
    setPaymentData: (_, action) => action.payload,
    setPaidByOtherPerson: (
      state,
      action: { payload: IPaidByOtherPersonState },
    ) => ({
      ...state,
      paidByOtherPerson: action.payload,
    }),
    addNewPaymentMethod: (state, action) => ({
      ...state,
      paymentList: [...state.paymentList, action.payload],
    }),
    deletePaymentMethod: (state, action) => ({
      ...state,
      paymentList: state.paymentList.filter(
        (paymentMethod) => !isEqual(paymentMethod, action.payload),
      ),
    }),
    addDiscountValue: (state, action) => ({
      ...state,
      discount: action.payload,
    }),
    updateValues: (state, action) => ({
      ...state,
      values: {
        ...state.values,
        ...action.payload,
      },
    }),
    setPaymentConfig: (state, action) => ({
      ...state,
      paymentConfig: action.payload,
    }),
    resetState: () => initialState,
    setCashMock: (state, action) => ({
      ...state,
      paymentConfig: {
        ...state.paymentConfig,
        ...action.payload,
      },
    }),
  },
});

export const {
  addNewPaymentMethod,
  deletePaymentMethod,
  setPaidByOtherPerson,
  setPaymentData,
  addDiscountValue,
  updateValues,
  setPaymentConfig,
  resetState,
  setCashMock,
} = SchedulePaymentSlice.actions;

export default SchedulePaymentSlice.reducer;
