import {
  takeLatest,
  put,
  select,
  all,
  call,
} from 'redux-saga/effects';

import { resetState as patientReset } from 'state/marketing/emailFlow/patient/actions';
import { resetState as professionalReset } from 'state/marketing/emailFlow/professional/actions';
import iclinic from 'services/iclinic';
import { handlerErrors } from 'state/shared/sagas';

import { TOGGLE_MODAL, NOTIFY_INTENT } from './constants';
import { getModalVisibility, getSubscriptionId } from './selectors';
import { notifyIntentFailure, notifyIntentSuccess } from './actions';

export function* workerToggleModal() {
  const modalVisibility = yield select(({
    marketing: { emailFlow },
  }) => getModalVisibility(emailFlow.root));

  if (!modalVisibility) {
    yield all([
      put(patientReset()),
      put(professionalReset()),
    ]);
  }
}

export function* workerNotifyMarketingIntent() {
  try {
    const subscriptionId = yield select(getSubscriptionId);

    const { errors } = yield call(iclinic.marketing.notifyIntent, subscriptionId);

    if (errors) throw errors;

    yield put(notifyIntentSuccess());
  } catch (error) {
    yield call(handlerErrors, error, notifyIntentFailure);
  }
}

export default function* rootSagas() {
  yield takeLatest(TOGGLE_MODAL.ACTION, workerToggleModal);
  yield takeLatest(NOTIFY_INTENT.ACTION, workerNotifyMarketingIntent);
}
