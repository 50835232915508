// Extenal
import { withFormik, FormikErrors } from 'formik';

// Internal
import { Checkout } from 'state/billing/subscription/types';

export type FormValues = {
  checkout: Checkout;
};

type FormProps = {
  errors?: FormikErrors<FormValues>;
  checkout: Checkout;
  values: FormValues;
};

export default (Component: JSX.Element) => withFormik<FormProps, FormValues>({
  enableReinitialize: true,
  mapPropsToValues: ({
    checkout: {
      number,
      owner,
      expirationDate,
      cvv,
      cpfCnpj,
    },
  }) => ({
    number,
    owner,
    expirationDate,
    cvv,
    cpfCnpj,
  }),
  handleSubmit: (_, { setStatus }) => setStatus('submit'),
})(Component);
