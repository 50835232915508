export type ProfessionalContent = {
  title: string;
  imagePath: string;
  innerComponent?: JSX.Element;
  subtitle?: JSX.Element;
  list?: string[];
  planToUpgrade: PlansToUpgrade;
};

export type UpgradeCallContent = {
  [planName: string]: ProfessionalContent;
};

export enum UserKinds {
  physician = 'p',
  receptionist = 'r',
}

export type PlansToUpgrade = 'plus' | 'pro' | 'premium';
