import React from 'react';

import { MaterialUICore, tokens } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { border, color, spacing } = tokens;

type ListItemRowProps = {
  isHeader?: boolean;
};

export const List = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.sm,
});

export const ListItem = styled('div')({
  border: `1px solid ${color.neutral[100]}`,
  borderRadius: border.radius.sm,
  display: 'flex',
  flexDirection: 'column',
  rowGap: spacing.xs,

  '& > div:first-child': {
    borderTopLeftRadius: border.radius.sm,
    borderTopRightRadius: border.radius.sm,
  },
});

export const ListItemRow = styled(
  ({
    isHeader,
    ...rest
  }: ListItemRowProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >) => <div {...rest} />,
)({
  display: 'flex',
  backgroundColor: ({ isHeader }: ListItemRowProps) =>
    isHeader ? color.secondary.light : 'none',
});

export const ListItemCell = styled('div')({
  flex: 1,
  padding: spacing.base,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ListItemHeaderDivider = styled('div')({
  width: 1,
  minHeight: '100%',
  margin: `${spacing.base} 0`,
  backgroundColor: color.neutral[50],
});
