export const getPatientRelativeName = (
  relatedness: string,
  relatedPatientName: string,
) => `(${relatedness}) ${relatedPatientName}`;

export const getEveryFirstLetter = (name: string) => {
  if (!name) return '';

  return name
    .split(' ')
    .map((item) => {
      if (item.length < 3) {
        return '';
      }
      return item[0];
    })
    .join('');
};
