import { MaterialUICore } from '@iclinic/design-system';

const { makeStyles } = MaterialUICore;

export default makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.background.default,
    padding: '1em',
    borderRadius: theme.shape.borderRadius,
  },
  title: {
    color: theme.palette.grey[700],
  },
  orderNumber: {
    paddingLeft: '20px',
  },
}));
