import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';

import {
  MaterialUICore,
  MaterialIcons,
  Typography,
  Grid,
  TextField,
  Button,
} from '@iclinic/design-system';
import {
  toggleDrawer,
  finalizeMedicalRecordSuccess,
} from 'state/records/signature/actions';
import { FileSignatureAttachment } from 'state/records/signature/types';
import { image } from 'shared/utils/urlGetter';
import NotificationErrors from '../components/NotificationErrors';
import { toggleState } from './constants';
import useStyles from './styleSignaturePreviewDownload';
import LABELS from '../hooks/constants';
import usePreviewSignature from '../hooks/usePreviewSignature';
import validationSchema from '../hooks/validationSchema';

const { FormControlLabel, Checkbox, Drawer, CircularProgress } = MaterialUICore;

const successImage = image('digitalSignature.successImage');

const SignaturePreviewDownload: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { CLOSE } = toggleState;

  const { attachments, selectAttachment, sendEmail } = usePreviewSignature();
  const isOpenDrawer = useSelector(
    (state) => state.records.signature.isOpenDrawer,
  );
  const isSending = useSelector((state) => state.shared.isSending);
  const patientEmail = useSelector((state) => state.records.root.patient.email);

  const onClickToggleDrawer = useCallback(
    (drawerState: boolean) => {
      dispatch(toggleDrawer(drawerState));
    },
    [dispatch],
  );

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      email: patientEmail || '',
    },
    validationSchema,
    onSubmit: () => {
      sendEmail(values.email);
    },
  });

  return (
    <Drawer
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="persistent"
      anchor="right"
      open={isOpenDrawer}
      onClose={() => onClickToggleDrawer(CLOSE)}
      data-testid="signature-preview-download"
    >
      <form onSubmit={handleSubmit} className={classes.content}>
        <Button
          disabled={isSending}
          className={classes.backButton}
          startIcon={<MaterialIcons.ArrowBack />}
          onClick={() => dispatch(finalizeMedicalRecordSuccess())}
          color="transparent"
        >
          <Typography variant="overline">Voltar</Typography>
        </Button>
        <div className={classes.mainContent}>
          <img
            width="350px"
            src={successImage}
            className={classes.successImage}
            alt="Documentos assinados com sucesso!"
          />
          <Typography variant="h3" className={classes.title}>
            Documentos assinados com sucesso!
          </Typography>
          <Typography variant="body2" className={classes.bodyText}>
            Escolha quais arquivos você deseja enviar para seu paciente:
          </Typography>
          <Grid container className={classes.gridContainer}>
            {attachments &&
              attachments.map((attachment: FileSignatureAttachment) => (
                <Grid
                  item
                  key={attachment.file_name}
                  className={classes.gridItem}
                >
                  <FormControlLabel
                    className={classes.checkboxLabel}
                    control={
                      <Checkbox
                        className={classes.checkbox}
                        checked={attachment.selected}
                        onChange={() => selectAttachment(attachment.file_name)}
                        name={attachment.document_type}
                        color="primary"
                      />
                    }
                    label={LABELS[attachment.document_type]}
                  />
                  <a
                    href={attachment.url}
                    download
                    className={classes.grayButton}
                    data-ga="assinatura_digital-sucesso-download"
                  >
                    <Typography variant="body1">
                      <MaterialIcons.GetApp />
                      Download
                    </Typography>
                  </a>
                </Grid>
              ))}
          </Grid>
          <NotificationErrors />
          <TextField
            className={classes.userEmail}
            value={values.email}
            name="email"
            onChange={handleChange}
            label="E-mail do paciente"
            error={errors.email}
            helperText={errors.email}
          />
        </div>
        <div className={classes.footer}>
          <Button
            disabled={isSending}
            className={classes.closeButton}
            onClick={() => dispatch(finalizeMedicalRecordSuccess())}
            data-ga="assinatura_digital-sucesso-sair_sem_enviar"
            color="transparent"
          >
            <Typography variant="body1">Sair sem enviar</Typography>
          </Button>
          <Button
            disabled={isSending}
            className={classes.footerButton}
            variant="contained"
            color="primary"
            type="submit"
            data-ga="assinatura_digital-sucesso-enviar_finalizar"
          >
            {isSending && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
            Enviar e finalizar
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

export default SignaturePreviewDownload;
