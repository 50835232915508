import React from 'react';

import { Body, Box, Dialog, Button } from '@iclinic/design-system';
import { image } from 'shared/utils/urlGetter';
import * as S from '../../components/lp-iclinic-pay/styles';

type FormsReceiptsModalProps = {
  open: boolean;
  onClose: (val: boolean) => void;
};

export default function FormsReceiptsModal({
  open,
  onClose,
}: FormsReceiptsModalProps): JSX.Element {
  const closeModal = () => {
    onClose(false);
  };

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={closeModal}
      title="forms-receipt-modal"
    >
      <Box px={3} py={4} display="flex" flexDirection="column">
        <Box textAlign="center">
          <S.SectionTitle variant="lg">Formas de recebimento</S.SectionTitle>
        </Box>
        <S.Grid container justify="space-around">
          <S.Grid item xs={12} md={5}>
            <Box textAlign="center" mt={1} mb={3}>
              <S.ImgWrapper>
                <img
                  alt="Plano Regular"
                  src={image('onlinePayment.regularPlan')}
                />
              </S.ImgWrapper>
              <Box textAlign="left" mt={2}>
                <Body variant="xs" bold>
                  Receba a partir de 30 dias
                </Body>
                <Body variant="xs">
                  Esqueça a burocracia, taxas abusivas e também a inadimplência
                  do cheque, disponibilizando o cartão de débito ou crédito para
                  os seus clientes.
                </Body>
                <S.Grid container>
                  <S.Grid item>
                    <Box mr={2}>
                      <Body variant="xs">
                        Débito em 2 dias úteis <br />
                        Crédito em 30 dias
                      </Body>
                    </Box>
                  </S.Grid>
                  <S.Grid item>
                    <Body variant="xs" bold>
                      à vista 1,65% <br />
                      à vista 2,1% <br />
                      parcelado a partir de 2,82%
                    </Body>
                  </S.Grid>
                </S.Grid>
              </Box>
            </Box>
          </S.Grid>
          <S.Grid item xs={12} md={5}>
            <Box textAlign="center" mt={1} mb={3}>
              <S.ImgWrapper>
                <img alt="Plano Fast" src={image('onlinePayment.fastPlan')} />
              </S.ImgWrapper>
              <Box textAlign="left" mt={2}>
                <Body variant="xs" bold>
                  Receba no 1º dia útil
                </Body>
                <Body variant="xs">
                  Além de toda a comodidade do plano Regular, receba todos os
                  seus pagamento no dia seguinte!
                </Body>
                <S.Grid container>
                  <S.Grid item>
                    <Box mr={2}>
                      <Body variant="xs">
                        Débito no dia seguinte <br />
                        Crédito no dia seguinte
                      </Body>
                    </Box>
                  </S.Grid>
                  <S.Grid item>
                    <Body variant="xs" bold>
                      à vista 1,75% <br />
                      à vista 4,57% <br />
                      parcelado a partir de 6,47%
                    </Body>
                  </S.Grid>
                </S.Grid>
              </Box>
            </Box>
          </S.Grid>
          <S.Grid item xs={12}>
            <Box textAlign="center">
              <Button variant="contained" onClick={closeModal}>
                Fechar
              </Button>
            </Box>
          </S.Grid>
        </S.Grid>
      </Box>
    </Dialog>
  );
}
