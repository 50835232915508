import React from 'react';

import Button from 'ui/new/button';
import { BUTTON } from 'ui/new/button/constants';
import Header from 'features/stock/components/Header';
import { ModalType } from 'features/stock/constants';

type MyStockHeaderProps = {
  onClick(modal: ModalType): void;
};
export default function MyStockHeader({
  onClick,
}: MyStockHeaderProps): JSX.Element {
  return (
    <Header title="Meu Estoque">
      <Button type={BUTTON.Light} onClick={() => onClick(ModalType.Add)}>
        Adicionar Produto
      </Button>
      <Button type={BUTTON.Primary} onClick={() => onClick(ModalType.Input)}>
        Entrada
      </Button>
      <Button type={BUTTON.Error} onClick={() => onClick(ModalType.Output)}>
        Saída
      </Button>
    </Header>
  );
}
