import { createSelector } from 'reselect';

import { RequestStatus } from 'shared/constants/State';
import {
  TransactionBase,
  TransactionsListPagination,
  TransactionDetail,
  AttemptTransactionBase,
  TransactionsFiltersForm,
} from './types';
import { StoreState } from 'state/rootReducer';
import { TransactionType } from 'features/onlinePayment/state/constants';

export const getSelectedTransactionType = ({
  onlinePayment,
}: StoreState): TransactionType =>
  onlinePayment.reports.transactions.selectedTransactionType;

export const getIsShowingWaitingPayments = ({
  onlinePayment,
}: StoreState): boolean =>
  onlinePayment.reports.transactions.isShowingWaitingPayments;

export const getIsAttemptTransactions = ({
  onlinePayment,
}: StoreState): boolean => {
  const { selectedTransactionType, isShowingWaitingPayments } =
    onlinePayment.reports.transactions;

  return (
    selectedTransactionType === TransactionType.Presential ||
    !isShowingWaitingPayments
  );
};

export const getTransactionsListPagination = ({
  onlinePayment,
}: StoreState): TransactionsListPagination | null | undefined =>
  onlinePayment.reports.transactions.pagination;

export const getPageNumber = ({ onlinePayment }: StoreState): number =>
  onlinePayment.reports.transactions.pageNumber;

export const getTransactionsList = ({
  onlinePayment,
}: StoreState): TransactionBase[] | null | undefined =>
  onlinePayment.reports.transactions.transactionsList?.data;

export const getTransactionsListFetchStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.reports.transactions.transactionsList?.fetchStatus;

export const isFetchingTransactionsList = createSelector(
  getTransactionsListFetchStatus,
  (transactionsListFetchStatus) =>
    transactionsListFetchStatus === RequestStatus.Pending,
);

export const getTransactionsListErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.reports.transactions.transactionsList?.error;

export const getTransactionsListFetchError = createSelector(
  getTransactionsListFetchStatus,
  getTransactionsListErrorMessage,
  (status, message) => (status === RequestStatus.Error ? message : null),
);

export const getTransactionsListSelector = createSelector(
  [
    isFetchingTransactionsList,
    getTransactionsList,
    getTransactionsListFetchError,
  ],
  (fetching, transactionsList, transactionsListFetchError) => ({
    fetching,
    transactionsList,
    transactionsListFetchError,
  }),
);

export const getTransactionDetailOpen = ({
  onlinePayment,
}: StoreState): boolean =>
  onlinePayment.reports.transactions.transactionDetailOpen;

export const isTransactionDetailOpen = createSelector(
  getTransactionDetailOpen,
  (transactionDetailOpen) => transactionDetailOpen === true,
);

export const getTransactionDetail = ({
  onlinePayment,
}: StoreState): TransactionDetail | null | undefined =>
  onlinePayment.reports.transactions.transactionDetail?.data;

export const getTransactionDetailFetchStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.reports.transactions.transactionDetail?.fetchStatus;

export const isFetchingTransactionDetail = createSelector(
  getTransactionDetailFetchStatus,
  (transactionsFetchStatus) =>
    transactionsFetchStatus === RequestStatus.Pending,
);

export const getTransactionDetailErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.reports.transactions.transactionDetail?.error;

export const getTransactionDetailFetchError = createSelector(
  getTransactionDetailFetchStatus,
  getTransactionDetailErrorMessage,
  (status, message) => (status === RequestStatus.Error ? message : null),
);

export const getTransactionDetailSelector = createSelector(
  [
    isFetchingTransactionDetail,
    getTransactionDetail,
    getTransactionDetailFetchError,
  ],
  (fetching, transactionDetail, transactionDetailFetchError) => ({
    fetching,
    transactionDetail,
    transactionDetailFetchError,
  }),
);

export const getAttemptTransactionsList = ({
  onlinePayment,
}: StoreState): AttemptTransactionBase[] | null | undefined =>
  onlinePayment.reports.transactions.attemptTransactionsList?.data;

export const getAttemptTransactionsListFetchStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.reports.transactions.attemptTransactionsList?.fetchStatus;

export const isFetchingAttemptTransactionsList = createSelector(
  getAttemptTransactionsListFetchStatus,
  (transactionsListFetchStatus) =>
    transactionsListFetchStatus === RequestStatus.Pending,
);

export const getAttemptTransactionsListErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.reports.transactions.attemptTransactionsList?.error;

export const getAttemptTransactionsListFetchError = createSelector(
  getAttemptTransactionsListFetchStatus,
  getAttemptTransactionsListErrorMessage,
  (status, message) => (status === RequestStatus.Error ? message : null),
);

export const getAttemptTransactionsListSelector = createSelector(
  [
    isFetchingAttemptTransactionsList,
    getAttemptTransactionsList,
    getAttemptTransactionsListFetchError,
  ],
  (fetching, attemptTransactionsList, attemptTransactionsListFetchError) => ({
    fetching,
    attemptTransactionsList,
    attemptTransactionsListFetchError,
  }),
);

export const getAttemptTransactionDetail = ({
  onlinePayment,
}: StoreState): AttemptTransactionBase | null | undefined =>
  onlinePayment.reports.transactions.attemptTransactionDetail?.data;

export const getAttemptTransactionDetailFetchStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined =>
  onlinePayment.reports.transactions.attemptTransactionDetail?.fetchStatus;

export const isFetchingAttemptTransactionDetail = createSelector(
  getAttemptTransactionDetailFetchStatus,
  (transactionsFetchStatus) =>
    transactionsFetchStatus === RequestStatus.Pending,
);

export const getAttemptTransactionDetailErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined =>
  onlinePayment.reports.transactions.attemptTransactionDetail?.error;

export const getAttemptTransactionDetailFetchError = createSelector(
  getAttemptTransactionDetailFetchStatus,
  getAttemptTransactionDetailErrorMessage,
  (status, message) => (status === RequestStatus.Error ? message : null),
);

export const getAttemptTransactionDetailSelector = createSelector(
  [
    isFetchingAttemptTransactionDetail,
    getAttemptTransactionDetail,
    getAttemptTransactionDetailFetchError,
  ],
  (fetching, attemptTransactionDetail, attemptTransactionDetailFetchError) => ({
    fetching,
    attemptTransactionDetail,
    attemptTransactionDetailFetchError,
  }),
);

export const getTransactionsFilters = ({
  onlinePayment,
}: StoreState): TransactionsFiltersForm | undefined =>
  onlinePayment.reports.transactions.transactionsFilters;

export const getTransactionsXLSFetchStatus = ({
  onlinePayment,
}: StoreState): RequestStatus | undefined | null =>
  onlinePayment.reports.transactions.transactionsXLS?.fetchStatus;

export const isFetchingTransactionsXLS = createSelector(
  getTransactionsXLSFetchStatus,
  (transactionsFetchStatus) =>
    transactionsFetchStatus === RequestStatus.Pending,
);

export const getTransactionsXLSErrorMessage = ({
  onlinePayment,
}: StoreState): string | undefined | null =>
  onlinePayment.reports.transactions.transactionsXLS?.error;

export const getTransactionsXLSFetchError = createSelector(
  getTransactionsXLSFetchStatus,
  getTransactionsXLSErrorMessage,
  (status, message) => (status === RequestStatus.Error ? message : null),
);

export const getTransactionsXLSSelector = createSelector(
  [isFetchingTransactionsXLS, getTransactionsXLSFetchError],
  (fetching, transactionsXLSFetchError) => ({
    fetching,
    transactionsXLSFetchError,
  }),
);
