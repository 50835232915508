import React from 'react';

import { MaterialUICore, tokens, Box, Body } from '@iclinic/design-system';

const { styled } = MaterialUICore;
const { spacing, border, color } = tokens;

type MessageBubbleProps = {
  isLocalParticipant: boolean;
};

export const MediaMessageContainer = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'stretch',
}));

export const MessageBubble = styled(
  ({
    isLocalParticipant,
    ...rest
  }: MessageBubbleProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >) => <div {...rest} />,
)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `${spacing.nano} ${spacing.base}`,
  backgroundColor: ({ isLocalParticipant }: MessageBubbleProps) =>
    isLocalParticipant ? color.secondary.main : color.neutral[25],
  borderRadius: border.radius.md,
  margin: `${spacing.nano} 0`,
  wordBreak: 'break-word',
});

export const MediaInfo = styled(Body)(() => ({
  color: color.neutral[900],
  fontWeight: 700,
  margin: `0px ${spacing.sm}`,
  maxWidth: '60%',
  minWidth: '60%',
}));

export const MediaType = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  minWidth: '24px',
  minHeight: '32px',
  background: 'linear-gradient(246.82deg, #FFFFFF -30.53%, #A0CFF8 94.79%)',
  border: '1px solid rgba(0, 0, 0, 0.04)',
  borderRadius: '2px',
  padding: '0px 1px',

  '& p': {
    fontWeight: 900,
    fontSize: '7.125px',
    lineHeight: '19px',
    letterSpacing: '0.59375px',
    textTransform: 'uppercase',
    color: color.primary.light,
  },
}));
