import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';

import { Body, Box, MaterialIcons, tokens } from '@iclinic/design-system';
import {
  checkoutPix,
  pixQRCodeValidity,
} from 'features/onlinePayment/state/checkout/payment/actions';
import { pixTimerRunning } from 'features/onlinePayment/state/checkout/infos/actions';
import { pixTimer } from 'features/onlinePayment/state/checkout/infos/utils';
import * as S from './PixQRCodeTimer.styles';
import { getIsPixQRCodeValid } from 'features/onlinePayment/state/checkout/payment/selectors';

const { Refresh } = MaterialIcons;
const { color } = tokens;

type PixQRCodeTimerProps = {
  expirationDate: string;
  qrCode: string;
};

export default function PixQRCodeTimer({
  expirationDate,
  qrCode,
}: PixQRCodeTimerProps): JSX.Element {
  const [time, setTime] = useState('');
  const [refresh, setRefresh] = useState(false);
  const isPixQRCodeValid = useSelector(getIsPixQRCodeValid);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isPixQRCodeValid) {
      setTime(pixTimer(expirationDate));
    }

    if (time === 'Gerar novo QRCode') {
      dispatch(pixQRCodeValidity(false));
      dispatch(pixTimerRunning(false));
      setRefresh(true);
      return;
    }

    const interval = setInterval(() => {
      setTime(pixTimer(expirationDate));
      dispatch(pixTimerRunning(true));
    }, 1000);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(interval);
  }, [time]);

  const handleRefresh = () => {
    dispatch(pixQRCodeValidity(true));
    dispatch(pixTimerRunning(false));
    dispatch(checkoutPix());
    setRefresh(false);
  };

  return (
    <>
      <S.QRCodeTimerWrapper>
        <QRCodeSVG fgColor={color.primary.main} size={168} value={qrCode} />
        {refresh && (
          <S.Overlay onClick={() => handleRefresh()}>
            <>
              <Refresh />
              <Body variant="xxs">Atualizar</Body>
            </>
          </S.Overlay>
        )}
      </S.QRCodeTimerWrapper>
      <Box mb={1}>
        <Body variant="xs" bold>
          {time}
        </Body>
      </Box>
    </>
  );
}
