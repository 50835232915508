import { StoreState } from 'state/rootReducer';
import { ExpenseState } from '.';

export const getExpenseListState = ({
  tissInvoicing,
}: StoreState): ExpenseState['expenseList'] =>
  tissInvoicing.expense.expenseList;

export const getExpenseListCurrentPage = ({
  tissInvoicing,
}: StoreState): ExpenseState['expenseList']['pageNum'] =>
  tissInvoicing.expense.expenseList.pageNum;

export const getExpenseAutocompleteState = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.expense.createExpense.autocompletes;

export const getExpenseCreationLoading = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.expense.createExpense.loading;

export const hasExpenseError = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.expense.createExpense.error;

export const getExpensesDetails = ({ tissInvoicing }: StoreState) =>
  tissInvoicing.expense.createExpense.expenseDetail;
