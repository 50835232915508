// External
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

// Internal
import { StockState } from 'features/stock/state/types';
import onlinePayment, {
  OnlinePaymentState,
} from 'features/onlinePayment/state/reducer';
import authv2 from 'features/new-auth/state/reducer';
import { AuthState } from 'features/new-auth/state/types';
import resetPasswordRequest from 'features/new-auth/reset-password/state';
import newPassword from 'features/new-auth/new-password/state';
import checkPasswordLink from 'features/new-auth/new-password/state/checkPasswordLink';
import signup from 'features/new-auth/signup/state';
import cbo from 'features/tuss/cbo/state';
import modal from './modal/reducer';
import billing, { BillingState } from './billing/reducer';
import campaign from './campaign/reducer';
import google from './google/reducer';
import records from './records/reducer';
import subscription from './subscription/reducer';
import upgrade, { UpgradeState } from './upgrade/reducer';
import upgradeAddon from './upgradeAddon/reducer';
import onlineSchedule from '../features/settings/scheduleOnline/state/reducer';
import shared from './shared/reducer';
import creditcard from './creditcard/reducer';
import video from './video/reducer';
import stock from '../features/stock/state/reducer';
import userInfo from './userInfo/reducer';
import marketingUnsubscribe from './marketing/unsubscribe/reducer';
import marketing, { MarketingState } from './marketing/reducer';
import marketingReferrals from 'features/marketing/state/reducer';
import { UserInfoState } from './userInfo/types';
import { ScheduleState } from './schedule/types';
import campaignManager from './campaignManager/reducer';
import { CampaignManagerState } from './campaignManager/types';
import schedule from './schedule/reducer';
import exportClinicData, {
  ExportClinicDataState,
} from 'features/exportClinicData/state/reducer';
import { CBOState } from 'features/tuss/cbo/types';
import freeSamples from 'features/free-samples/state/reducer';
import tissInvoicing, { TissState } from 'features/tissInvoicing/state/reducer';
import cfmUpdate from 'features/CFMUpdate/state';
import settingsUserReducer from 'features/settings/users/state/reducer';
import checkout from 'features/checkout/state/reducer';
import referrals from 'features/referrals/state';
import paymentSlipLink, {
  PaymentSlipLinkState,
} from 'features/paymentSlipLink/state';
import upgradeCall, {
  upgradeCallState,
} from 'features/subscription/upgradeCall/state/reducer';
import editPaymentDate from 'features/subscription/editPaymentDate/state/reducer';
import { EditPaymentState } from 'features/subscription/editPaymentDate/types';
import reports, { ReportsState } from 'features/reports/state/reducer';
import ScheduleDetailsReducer, {
  ScheduleDetailsState,
} from 'features/newFinance/state/reducer';
import survey, { SurveyState } from 'features/survey/state/reducer';
import patientsByEvents, {
  PatientsByEventsState,
} from 'features/patientsByEvents/state';
import messaging, { MessagingState } from 'features/messaging/state/reducer';
import stepSignup from 'features/new-auth/signup/stepSignup/state/reducer';
import { SignupState } from 'features/new-auth/signup/stepSignup/state/types';
import { GoogleState } from './google/types';
import paymentSlip, { PaymentSlipState } from 'features/paymentSlip/state';

export type StoreState = {
  stock: StockState;
  upgrade: UpgradeState;
  marketing: MarketingState;
  billing: BillingState;
  authv2: AuthState;
  userInfo: UserInfoState;
  schedule: ScheduleState;
  campaignManager: CampaignManagerState;
  onlinePayment: OnlinePaymentState;
  exportClinicData: ExportClinicDataState;
  cbo: CBOState;
  tissInvoicing: TissState;
  paymentSlipLink: PaymentSlipLinkState;
  upgradeCall: upgradeCallState;
  editPaymentDate: EditPaymentState;
  reports: ReportsState;
  newFinance: ScheduleDetailsState;
  survey: SurveyState;
  dashboard: PatientsByEventsState;
  messaging: MessagingState;
  stepSignup: SignupState;
  google: GoogleState;
  paymentSlip: PaymentSlipState;
};

export const reducersMap = {
  // modals
  modal,

  // billing
  billing,

  // new auth
  authv2,

  // campaign
  campaign,

  // google apis
  google,

  // records
  records,

  // subscription
  subscription,

  // upgrade
  upgrade,

  // upgradeAddon
  upgradeAddon,

  // upgrade
  shared,

  // credit card
  creditcard,

  // stock control
  stock,

  // redux forms
  form: formReducer,

  // online Schedule Settings
  onlineSchedule,

  // video
  video,

  // userInfo app
  userInfo,

  // marketing Unsubscribe
  marketingUnsubscribe,

  // marketing
  marketing,

  // marketing referrals
  marketingReferrals,
  // campaignManager
  campaignManager,
  // Schedule
  schedule,
  onlinePayment,
  resetPasswordRequest,
  newPassword,
  checkPasswordLink,
  signup,
  exportClinicData,
  cbo,
  freeSamples,
  tissInvoicing,
  cfmUpdate,
  settingsUser: settingsUserReducer,
  checkout,
  referrals,
  paymentSlipLink,
  upgradeCall,
  editPaymentDate,
  reports,
  newFinance: ScheduleDetailsReducer,
  survey,
  dashboard: patientsByEvents,
  messaging,
  stepSignup,
  paymentSlip,
};

const rootReducer = combineReducers(reducersMap);

export default rootReducer;

export type IStoreState = ReturnType<typeof rootReducer>;
