import AddEmailFlowModal from './containers/AddEmailFlowModal';
import ConfirmDeleteModal from './containers/ConfirmDeleteModal';
import EditorLinkModal from './containers/modal/EditorLinkModal';
import EditorButtonModal from './containers/modal/EditorButtonModal';
import EditorImageModal from './containers/modal/EditorImageModal';
import ConfirmDeleteComponentModal from './components/ConfirmDeleteComponentModal';
import ConfirmActiveEmailFlowModal from './components/ConfirmActiveEmailFlowModal';

export const ADD_EMAIL_FLOW_MODAL = 'modal/emailFlow/ADD_EMAIL_FLOW_MODAL';
export const CONFIRM_DELETE_MODAL = 'modal/emailFlow/CONFIRM_DELETE_MODAL';
export const EDITOR_LINK_MODAL = 'modal/marketing/EDITOR_LINK_MODAL';
export const EDITOR_BUTTON_MODAL = 'modal/marketing/EDITOR_BUTTON_MODAL';
export const EDITOR_IMAGE_MODAL = 'modal/marketing/EDITOR_IMAGE_MODAL';
export const CONFIRM_DELETE_COMPONENT_MODAL = 'modal/marketing/CONFIRM_DELETE_COMPONENT_MODAL';
export const ACTIVE_EMAIL_FLOW_MODAL = 'modal/marketing/ACTIVE_EMAIL_FLOW_MODAL';

export default {
  [ADD_EMAIL_FLOW_MODAL]: AddEmailFlowModal,
  [CONFIRM_DELETE_MODAL]: ConfirmDeleteModal,
  [EDITOR_LINK_MODAL]: EditorLinkModal,
  [EDITOR_BUTTON_MODAL]: EditorButtonModal,
  [EDITOR_IMAGE_MODAL]: EditorImageModal,
  [CONFIRM_DELETE_COMPONENT_MODAL]: ConfirmDeleteComponentModal,
  [ACTIVE_EMAIL_FLOW_MODAL]: ConfirmActiveEmailFlowModal,
};
