import axios from 'axios';

import {
  interceptorRequestAuthentication,
  interceptorResponseTransformData,
  interceptorResponseErrorsPromise,
} from 'services/iclinic/interceptors';

const apiRecords = axios.create({
  baseURL: process.env.ICLINIC_API_URL,
});

apiRecords.interceptors.request.use(interceptorRequestAuthentication);
apiRecords.interceptors.response.use(
  interceptorResponseTransformData,
  interceptorResponseErrorsPromise,
);

export const getLatestDiagnoses = (patientId: string) =>
  apiRecords.get(`/patients/patient/${patientId}/last-diagnosis/`);

export const getAcceptTermsIA = () =>
  apiRecords.get('/ai_integration/accept-terms/');

export const postAcceptTermsIA = () =>
  apiRecords.post('/ai_integration/accept-terms/');

export const postSummaryIA = (patientId: string) =>
  apiRecords.post(`/ai_integration/summary/${patientId}/`);

export const getSummaryIA = (patientId: string) =>
  apiRecords.get(`/ai_integration/summary/${patientId}/`);

export const postFeedbackIA = (data: { requestId: string; feedback: number }) =>
  apiRecords.post('/ai_integration/feedback/', {
    request_id: data.requestId,
    feedback: data.feedback,
  });

export const postTranscriptionToResume = (patientId: string, data: string) =>
  apiRecords.post('/ai_integration/anamnesis/', {
    patient: patientId,
    data,
  });

export const getAnamnesisResume = (patientId: string) =>
  apiRecords.get(`/ai_integration/anamnesis/${patientId}/`);
