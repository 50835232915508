import {
  theme as themeUI,
  MaterialUICore,
  tokens,
  axiomTokens,
} from '@iclinic/design-system';

const { makeStyles, createMuiTheme } = MaterialUICore;
const { border, font, color } = tokens;

type Props = {
  height?: number;
};

const theme = createMuiTheme(themeUI);

export default makeStyles<{}, Props>(() => ({
  container: {
    position: 'relative',
    top: '16px',
    bottom: '80px',
    right: '8px',
    left: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'calc(100% - 16px)',
    overflowY: 'auto',
    height: ({ height }) => height,
    [theme.breakpoints.up('sm')]: {
      top: '24px',
      right: '24px',
      left: '24px',
      width: 'calc(100% - 48px)',
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      height: ({ height }) => `calc(${height}px - 120px)`,
    },
    [theme.breakpoints.down(320)]: {
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      height: '100%',
      '& $participant': {
        aspectRatio: '1',
      },
    },
  },
  participant: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: border.radius.md,
    position: 'relative',
    overflow: 'none',
    aspectRatio: 'auto',
    height: '100%',
    width: '85%',
    backgroundColor: `rgba( 0, 0, 0, ${axiomTokens.opacity.level['6']})`,
    [theme.breakpoints.down(320)]: {
      width: '100%',
    },
  },
  fullWidth: {
    width: '100%',
  },
  chatWindowOpen: {
    top: '8px',
    overflowY: 'hidden',
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    height: ({ height }) => `calc(${height}px / 100 * 30 - 16px`,
    '& $participant': {
      height: '100%',
    },
  },
  reconnectingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: `rgba(40, 42, 43, 0.9)`,
    fontSize: font.size.md,
    color: color.feedback.info.light,
    zIndex: 1,
  },
}));
