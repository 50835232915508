import { MaterialUICore, tokens } from '@iclinic/design-system';

const { Checkbox, styled } = MaterialUICore;
const { border, color, spacing } = tokens;

export const Root = styled('div')(({ theme }) => ({
  backgroundColor: color.secondary.main,
  padding: `${spacing.base} 0 ${spacing.base} ${spacing.base}`,
  borderRadius: border.radius.md,
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  alignItems: 'center',
  columnGap: spacing.xs,
  minHeight: 56,

  '& > .MuiTypography-root': {
    color: color.neutral[900],
  },

  '& > .MuiSvgIcon-root': {
    fill: color.neutral[500],
    display: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    gridTemplateColumns: 'auto 1fr auto',
    padding: `${spacing.base} ${spacing.md}`,

    '& > .MuiSvgIcon-root': {
      display: 'inline-block',
    },
  },
}));

export const StyledCheckbox = styled(Checkbox)({
  '&.MuiCheckbox-root': {
    padding: spacing.base,
    color: color.neutral[300],
  },

  '&.Mui-checked': {
    color: color.primary.main,
  },
});
