import { IExpenseItem } from 'features/tissInvoicing/containers/expense/interfaces/ExpenseItem';

export const patchNormalizeExpenses = (expenses: IExpenseItem[]) =>
  expenses.map((exp) => ({
    ...exp,
    tuss_code: exp.tuss_code?.title,
    tuss_expense: {
      ...exp.tuss_expense,
      tuss_table: exp.tuss_table,
    },
    type: exp.type?.id,
    name: exp.tuss_code?.term,
  }));
