import { Professional, PhysicianId } from './types';

const setSelectedProfessionalById = (
  professionals: Professional[],
  physicianId: PhysicianId,
) => (
  professionals.find(({ physician_id: InternalPhysicianId }) => InternalPhysicianId === physicianId)
);

export default setSelectedProfessionalById;
