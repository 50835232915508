import React from 'react';

import { Typography } from '@iclinic/design-system';
import useStyles from './styles';

type Props = {
  label: React.ReactNode;
};

const SubTitle = ({ label }: Props) => {
  const classes = useStyles();

  return (
    <Typography variant="body2" className={classes.subTitle}>
      {label}
    </Typography>
  );
};

export default SubTitle;
