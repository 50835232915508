import React from 'react';
import { MaterialUICore } from '@iclinic/design-system';

import * as S from './CardList.styles';
import {
  HighlightsInfoListCardItems,
  HighlightsInfoListCard,
} from '../../types';

const { useTheme, useMediaQuery } = MaterialUICore;

const WIDTH_AUTO = 'auto';
const WIDTH_ONE_HUNDRED = '100%';
const WIDTH_THIRTY = '30%';
const WIDTH_THIRTY_SIX = '36%';
const WIDTH_FORTY_SEVEN = '47%';

export type ChoosingWidthChipsProps = {
  index: number;
  title: string;
  itemsLength: number;
  isMd: boolean;
};

export const chosingWidthChipByScreenSize = ({
  index,
  isMd,
  itemsLength,
  title,
}: ChoosingWidthChipsProps) => {
  const itemsMoreThanFour = itemsLength > 4;
  const isLastItem = index === 3;
  const isMobileAndSecondLine = (index === 2 || isLastItem) && isMd;
  const isShortTitle = (title.length < 21 && isMd) || title.length < 26;

  if (itemsLength === 1) {
    if (isShortTitle) {
      return WIDTH_AUTO;
    }
    return WIDTH_ONE_HUNDRED;
  }
  if (isMobileAndSecondLine && itemsMoreThanFour) {
    return WIDTH_THIRTY;
  }
  if ((index === 2 || isLastItem) && itemsMoreThanFour) {
    return WIDTH_THIRTY_SIX;
  }
  return WIDTH_FORTY_SEVEN;
};

export interface CardListProps {
  item: HighlightsInfoListCard;
}

export const CardList = ({ item }: CardListProps) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down('md'));

  const { items } = item;
  let qtItems = 0;

  const filteredChips = items?.map(
    (contentItem: HighlightsInfoListCardItems, index) => {
      const { title } = contentItem;

      if (index >= 4) {
        qtItems += 1;
        return null;
      }

      return (
        <S.EachChipContent
          widthCustomized={chosingWidthChipByScreenSize({
            index,
            title,
            itemsLength: items.length,
            isMd,
          })}
          key={contentItem.itemId.toString()}
          size="small"
          label={title}
          variant="outlined"
          severity="default"
        />
      );
    },
  );

  const textItems = qtItems === 1 ? 'item' : 'itens';
  return (
    <div>
      <S.ContentAllChips>
        {filteredChips}
        {qtItems > 0 && (
          <S.MoreItems>
            +{qtItems} {textItems}
          </S.MoreItems>
        )}
      </S.ContentAllChips>
    </div>
  );
};
