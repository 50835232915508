import { trackCustomEvent } from 'shared/utils/trackEvents';

export const USER_CONTEXT_SCHEMA = 'iglu:br.com.iclinic/user/jsonschema/1-0-0';
export const BUTTONCLICK_CONTEXT_SCHEMA =
  'iglu:br.com.iclinic/button_click/jsonschema/1-0-15';

export const trackEvent = (
  buttonName: string,
  profileId: string,
  subcriberId: string,
  isSubscriber: boolean,
  additionalProperties: string,
) => {
  const userContext = {
    schema: USER_CONTEXT_SCHEMA,
    data: {
      user_id: profileId,
      user_is_subscriber: isSubscriber,
      user_profile: profileId,
      subscription_id: subcriberId,
    },
  };

  trackCustomEvent(
    BUTTONCLICK_CONTEXT_SCHEMA,
    {
      button_name: buttonName,
      additional_properties: additionalProperties,
    },
    [userContext],
  );
};
