import {
  axiomTokens,
  Chip,
  MaterialIcons,
  MaterialUICore,
} from '@iclinic/design-system';

import { ElectronicSignatureStatusEnum } from '../../types';

const { styled } = MaterialUICore;

type Theme = MaterialUICore.Theme;

type StatusChipProps = {
  theme: Theme;
  status?: ElectronicSignatureStatusEnum;
};

const { AssignmentTurnedInRounded, RefreshRounded } = MaterialIcons;

export const StatusContainer = styled('div')(({ theme }) => ({
  marginTop: axiomTokens.spacing[3],

  [theme.breakpoints.up(768)]: {
    display: 'flex',
  },

  [theme.breakpoints.down(768)]: {
    '& > div:first-child': {
      marginBottom: axiomTokens.spacing[2],
    },
  },
}));

export const StatusPendingIcon = styled(RefreshRounded)({
  '&&': { color: axiomTokens.color.icon.feedback.warning },
});

export const StatusSuccessIcon = styled(AssignmentTurnedInRounded)({
  '&&': { color: axiomTokens.color.icon.feedback.success },
});

export const StatusChip = styled(Chip)(({ status }: StatusChipProps) => ({
  '&&': {
    backgroundColor:
      status === ElectronicSignatureStatusEnum.DONE
        ? axiomTokens.color.surface.feedback.success.subtle
        : axiomTokens.color.surface.feedback.warning.subtle,
    color:
      status === ElectronicSignatureStatusEnum.DONE
        ? axiomTokens.color.typeface.feedback.success
        : axiomTokens.color.typeface.feedback.warning,
    borderColor: 'transparent',
    '&&:hover': {
      backgroundColor:
        status === ElectronicSignatureStatusEnum.DONE
          ? axiomTokens.color.surface.feedback.success.subtle
          : axiomTokens.color.surface.feedback.warning.subtle,
      borderColor:
        status === ElectronicSignatureStatusEnum.DONE
          ? 'transparent'
          : axiomTokens.color.border.feedback.warning,
      borderWidth: 1,
      borderStyle: 'solid',
    },
  },
}));
