import React from 'react';
import { useSelector } from 'react-redux';
import { tokens, MaterialUICore, MaterialIcons } from '@iclinic/design-system';

import {
  getScheduleProceduresSelector,
  getTotalValuesSelector,
} from 'features/newFinance/state/selectors';
import ProcedureItem from './ProcedureItem';
import * as S from './styles';
import { FlexContainer } from 'features/newFinance/components/styles';
import { format } from 'shared/hooks/useCurrencyMask';
import { getFixedNumber } from 'features/onlinePayment/utils';
import { formatCurrency } from 'shared/utils/strings';

const { Divider } = MaterialUICore;
const { Warning } = MaterialIcons;
const ProceduresList = ({ children }: { children: React.ReactNode }) => {
  const procedures = useSelector(getScheduleProceduresSelector);
  const { subTotal, total, remainingAmount } = useSelector(
    getTotalValuesSelector,
  );

  return (
    <FlexContainer
      orientation="column"
      style={{ width: '100%', padding: tokens.spacing.md }}
    >
      <S.ProcedureContainer>
        <span className="procedure_list_title">Procedimentos</span>
        <S.ProcedureList>
          {procedures.map((procedure) => (
            <ProcedureItem
              key={procedure.id}
              item={procedure}
              id={procedure.id.toString()}
            />
          ))}
        </S.ProcedureList>
        <Divider />
        <S.SubTotalBox>
          <span>Subtotal:</span>
          <span>{format(getFixedNumber(subTotal))}</span>
        </S.SubTotalBox>
        {children}
        {!!remainingAmount && (
          <S.RemainingAmount>
            <Warning />
            <span>Faltam:</span>
            <span>{formatCurrency(remainingAmount)}</span>
          </S.RemainingAmount>
        )}
        <S.TotalBox>
          <span>Total:</span>
          <span>{format(getFixedNumber(total))}</span>
        </S.TotalBox>
      </S.ProcedureContainer>
    </FlexContainer>
  );
};

export default ProceduresList;
