import React, { memo } from 'react';

import {
  formatDate,
  formatTime,
} from 'features/tissInvoicing/utils/formatDateTime';
import { formatCurrency } from 'features/tissInvoicing/utils/formatCurrency';
import { Guide } from 'features/tissInvoicing/types';
import { Body } from '@iclinic/design-system';
import { TableCell } from 'features/tissInvoicing/components/ui/Table';
import * as Styles from './styles';

interface RowsProps {
  rowData: Guide;
}

const Rows = ({ rowData }: RowsProps) => {
  const classes = Styles.useStyles();

  return (
    <>
      <TableCell>
        <Body variant="sm">{rowData.guide_number}</Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{formatDate(rowData.date)}</Body>
        <Body variant="xs" classes={{ root: classes.neutral }}>
          {formatTime(rowData.date)}
        </Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{rowData.patient.name}</Body>
        <Body variant="xs" classes={{ root: classes.neutral }}>
          {rowData.patient.id}
        </Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{rowData.physician.name}</Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{rowData?.quantity}</Body>
      </TableCell>
      <TableCell>
        <Body variant="sm">{formatCurrency(rowData.total_amount)}</Body>
      </TableCell>
    </>
  );
};

export default memo(Rows);
